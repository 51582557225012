<avi-data-grid
    [config]="geoDbMappingGridConfig"
    [rows]="geoDbMappings.config"
    class="geo-db-mapping-grid"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addGeoDbMappingList()"
        >
            {{ l10nKeys.geoDbMappingAddButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>
