/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createAction, props } from '@ngrx/store';
import { IControllerInitialDataApiResponse } from './controller-initial-data.state';

const CONTROLLER_INITIAL_DATA_SOURCE = '[Controller Initial Data]';

export const loadControllerInitialData = createAction(
    `${CONTROLLER_INITIAL_DATA_SOURCE} Load Data`,
);

export const setControllerInitialData = createAction(
    `${CONTROLLER_INITIAL_DATA_SOURCE} Set Controller Initial Data`,
    props<{ controllerInitialData: IControllerInitialDataApiResponse }>(),
);
