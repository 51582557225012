/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'custom-params-grid';
const prefix = `${moduleName}.${componentName}`;

export const columntitleSensitive = `${prefix}.columntitleSensitive`;
export const columntitleDynamic = `${prefix}.columntitleDynamic`;

export const ENGLISH = {
    [columntitleSensitive]: 'Sensitive',
    [columntitleDynamic]: 'Dynamic',
};
