/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { IJWTValidationVsConfig } from 'generated-types';
import { SSOPolicyTypes } from 'ajs/modules/sso-policy/factories';
import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './jwt-vs-validation-config.l10n';

import template from './jwt-vs-validation-config.component.html';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Component for Jwt Vs Validation Config.
 */
class JwtVsValidationConfigController {
    /**
     * Collection instance for SSOPolicy.
     */
    public ssoPolicyCollection;

    /**
     * Config object for JWT access config.
     */
    public jwtConfig: IJWTValidationVsConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        SSOPolicyCollection: any,
        l10nService: L10nService,
    ) {
        /**
         * @type {SSOPolicyCollection}
         */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: SSOPolicyTypes.JWT,
            },
            defaults: {
                type: SSOPolicyTypes.JWT,
            },
        });

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when Token Location type is changed.
     */
    public handleJwtLocationChange(): void {
        delete this.jwtConfig.jwt_name;
    }

    /** @override */
    public $onDestroy(): void {
        this.ssoPolicyCollection.destroy();
    }
}

JwtVsValidationConfigController.$inject = [
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
];

export const JwtVsValidationConfigOptions = {
    bindings: {
        ssoPolicyRef: '=',
        jwtConfig: '=',
    },
    controller: JwtVsValidationConfigController,
    template,
};
