/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { ServerAutoScalePolicy } from 'object-types';

import {
    AviPermissionResource,
    IServerAutoScalePolicy,
} from 'generated-types';

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    Component,
    Type,
} from '@angular/core';

import * as l10n from '../auto-scale.l10n';
import { ScheduledScalingConfigItem } from './scheduled-scaling-config.item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const SCHEDULED_SCALINGS = 'scheduled_scalings';

/**
 * @description
 *
 *   AutoScale policy Item
 *
 * @author Ram Pal, Nisar Nadaf, Harmeet Kaur
 */

type TAutoScalePolicyPartial = Omit<IServerAutoScalePolicy, 'scheduled_scalings'>;

interface IExtendedAutoScalePolicy extends TAutoScalePolicyPartial {
    scheduled_scalings?: RepeatedMessageItem<ScheduledScalingConfigItem>,
}

export class AutoScalePolicy extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Override return type of getConfig().
     */
    public getConfig: () => IExtendedAutoScalePolicy;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'serverautoscalepolicy',
            objectType: ServerAutoScalePolicy,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_AUTOSCALE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public dataToSave(): IServerAutoScalePolicy {
        const config: IServerAutoScalePolicy = super.dataToSave();

        if (!config.intelligent_autoscale) {
            delete config.intelligent_scaleout_margin;
            delete config.intelligent_scalein_margin;
        }

        return config;
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AutoScalePolicyModalComponent,
        } = await import(
            /* webpackChunkName: "auto-scale-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/autoscale-policy-modal/autoscale-policy-modal.component'
        );

        return AutoScalePolicyModalComponent as Type<Component>;
    }

    /**
     * Getter for scheduled scalings.
     */
    public get schedulers(): RepeatedMessageItem<ScheduledScalingConfigItem> {
        const { scheduled_scalings: schedulers } = this.getConfig();

        return schedulers;
    }

    /**
     * Adds a new scheduler to config.scheduled_scalings.
     */
    public addScheduledScaling(): void {
        this.addChildMessageItem({
            field: SCHEDULED_SCALINGS,
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Method to edit the scaling scheduler.
     */
    public editScheduledScaling(scheduler: ScheduledScalingConfigItem): void {
        this.editChildMessageItem({
            field: SCHEDULED_SCALINGS,
            messageItem: scheduler,
            modalBindings: {
                isEditing: true,
            },
        });
    }

    /**
     * Method to delete the scaling scheduler.
     */
    public deleteScheduledScaling(scheduler: ScheduledScalingConfigItem): void {
        this.schedulers.removeByMessageItem(scheduler);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.autoScalePolicyModalBreadcrumbTitle);
    }

    /**
     * Getter for intelligent_autoscale.
     */
    public get isIntelligentAutoscaleEnabled(): boolean {
        return this.getConfig().intelligent_autoscale;
    }
}
