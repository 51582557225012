/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { HostHdrMatchConfigItem } from 'ajs/modules/match';
import { HdrMatchOperation } from 'generated-types';
import * as l10n from './host-hdr-match.l10n';
import './host-hdr-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const preventConfigureValueSet = new Set([
    HdrMatchOperation.HDR_EXISTS,
    HdrMatchOperation.HDR_DOES_NOT_EXIST,
]);

/**
 * @description HostHdrMatch component.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'host-hdr-match',
    templateUrl: './host-hdr-match.component.html',
})
export class HostHdrMatchComponent {
    /**
     * Method Match message item instance
     */
    @Input()
    public editable: HostHdrMatchConfigItem;

    /**
     * Index of the match within the MatchAdder component
     */
    @Input()
    public matchIndex = 0;

    /**
     * Match label passed to the MatchWrapperComponent
     */
    @Input()
    public label: string;

    /**
     * fieldName passed to the MatchWrapperComponent
     */
    @Input()
    public fieldName: string;

    /**
     * objectType passed to the MatchWrapperComponent
     */
    @Input()
    public objectType: string;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    public readonly l10nKeys = l10nKeys;

    /**
     * Dropdown options for the match operation.
     */
    public criteriaOptions: IAviDropdownOption[];

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.setCriteriaOptions();
    }

    /**
     * Returns true if the value input field should be shown.
     */
    public showValueField(): boolean {
        return this.editable.matchCriteria && !this.preventConfigureValue();
    }

    /**
     * Called when the match criteria changes.
     */
    public handleCriteriaChange(): void {
        if (this.preventConfigureValue()) {
            delete this.editable.config.value;
        }
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * True if the value field should not be configurable.
     */
    private preventConfigureValue(): boolean {
        return preventConfigureValueSet.has(this.editable.matchCriteria);
    }

    /**
     * Sets criteriaOptions
     */
    private setCriteriaOptions(): void {
        const criterion = this.schemaService.getEnumValues('HdrMatchOperation');

        this.criteriaOptions = createOptionsFromEnumProps(criterion);
    }
}
