<cds-modal
    class="credentials-verification-dialog"
    size="{{ size }}"
    (closeChange)="handleClose($event)"
>
    <cds-modal-header>
        <ng-content select="credentials-verification-dialog_header"></ng-content>
    </cds-modal-header>
    <cds-modal-content>
        <div class="credentials-verification-dialog__form-container">
            <avi-alert-group
                *ngIf="errors"
                alertType="danger"
            >
                {{ errors }}
            </avi-alert-group>
            <ng-content select="credentials-verification-dialog_form"></ng-content>
        </div>
    </cds-modal-content>
    <cds-modal-actions>
        <cds-button
            class="sel-cancel"
            (click)="handleCancel()"
            action="flat"
        >
            {{ l10nKeys.cancelButtonLabel | vtranslate }}
        </cds-button>
        <cds-button
            class="sel-connect"
            (click)="handleSubmit()"
            [loadingState]="loadingState"
            [disabled]="disableSubmit"
        >
            {{ getSubmitButtonLabel() }}
        </cds-button>
    </cds-modal-actions>
</cds-modal>
