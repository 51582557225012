/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { compact } from 'underscore';

import * as l10n from './authentication-tenant-role-mapping.controller.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export function authenticationTenantRoleMappingController(
    $scope,
    AviModal,
    TenantCollection,
    RoleCollection,
    systemConfig,
    schemaService,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
     * Get keys from source bundles for template usage
     */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const vm = this;

    vm.tenantCollection = new TenantCollection({ isStatic: true });
    vm.roleCollection = new RoleCollection();

    vm.ui = {
        busy: false,
        errors: null,
    };

    /**
     * Init function to be called when modal opens.
     */
    $scope.init = function() {
        vm.rule = $scope.rule ? angular.copy($scope.rule) : {};

        $scope.authMatchOperationEnumValues = schemaService.getEnumValues('AuthMatchOperation');

        if (!$scope.authProfileType) {
            console.warn('Modal requires `authProfileType` to be passed into it\'s scope.');
        }

        vm.rule.attribute_match = vm.rule.attribute_match || { criteria: null };
        vm.rule.group_match = vm.rule.group_match || { criteria: null };
    };

    /**
     * Clears groups or values of authorization based on type. Called on ng-change.
     * @param  {String} type  - 'attribute' or 'group'.
     * @param  {Number} index - Index of authorization match.
     */
    vm.changeCriteria = function(type, index) {
        const matches = `${type}_match`;
        const match = $scope.rule[matches][index];

        if (type === 'attribute') {
            match.values = [''];
        } else if (type === 'group') {
            match.groups = [''];
        }
    };

    /**
     * Clears selected tenants or refs based on type. Called on ng-change.
     * @param  {String} type - 'tenant' or 'role'.
     */
    vm.changeAssignment = function(type) {
        const assign = `assign_${type}`;
        const refs = `${type}_refs`;
        const attribute = `${type}_attribute_name`;

        if (vm.rule[assign] !== 'ASSIGN_FROM_SELECT_LIST') {
            vm.rule[refs] = undefined;
        }

        if (vm.rule[assign] !== 'ASSIGN_MATCHING_ATTRIBUTE_VALUE') {
            vm.rule[attribute] = undefined;
        }
    };

    /**
     * Saves mapping rule. Overwrites if editing rule and inserts new rule of creating.
     * @param  {Object} rule - Mapping rule.
     */
    vm.saveRule = function() {
        const rule = dataToSave();
        const settings = setSystemSettings(rule);

        vm.ui.busy = true;
        vm.ui.errors = null;

        systemConfig.legacySave(settings)
            .then(() => {
                $scope.onSuccess();
                this.closeModal();
            }).catch(() => {
                this.ui.errors = systemConfig.errors;
            }).finally(() => {
                this.ui.busy = false;
            });
    };

    /**
     * Reset groups to empty when 'Any' is selected in LDAP Group mapping.
     */
    vm.ldapGroupAnyClickHandler = () => {
        vm.rule.group_match.groups.length = 0;
    };

    /**
     * Reset Attribute name and value to empty when 'Any' is selected in Attribute mapping.
     */
    vm.attributeAnyClickHandler = () => {
        vm.rule.attribute_match.name = '';
        vm.rule.attribute_match.values.length = 0;
    };

    /**
     * Modifies rule to be saved. Removes group_match and attribute_match if criteria is null.
     * @return {Object} Mapping rule.
     */
    function dataToSave() {
        const rule = angular.copy(vm.rule);
        const {
            attribute_match: attributeMatch,
            group_match: groupMatch,
        } = rule;

        if (groupMatch) {
            const { criteria } = groupMatch;

            if (!criteria) {
                rule.group_match = undefined;
            } else if (groupMatch.groups) {
                const { groups } = groupMatch;

                // Drop falsy values which can be
                // added using repeated-inputs.
                groupMatch.groups = compact(groups);
            }
        }

        if (attributeMatch) {
            const { criteria } = attributeMatch;

            if (!criteria) {
                rule.attribute_match = undefined;
            } else if (attributeMatch.values) {
                const { values } = attributeMatch;

                // Drop falsy values which can be
                // added using repeated-inputs.
                attributeMatch.values = compact(values);
            }
        }

        if (rule.is_superuser) {
            rule.assign_tenant = undefined;
            rule.tenant_attribute_name = undefined;
            rule.tenant_refs = undefined;
            rule.assign_role = undefined;
            rule.role_attribute_name = undefined;
            rule.role_refs = undefined;
        }

        return rule;
    }

    /**
     * Modifies system settings object to be saved based on whether mapping rule is new or being
     * edited.
     * @param {Object} rule - Mapping rule.
     * @return {Object} System settings object.
     */
    function setSystemSettings(rule) {
        const settings = angular.copy($scope.settings);
        const adminAuthConfig = settings.admin_auth_configuration;

        if (adminAuthConfig) {
            if (!_.isUndefined(rule.index)) {
                const ruleIndex = _.findIndex(adminAuthConfig.mapping_rules, function(mappingRule) {
                    return mappingRule.index === rule.index;
                });

                adminAuthConfig.mapping_rules[ruleIndex] = rule;
            } else {
                setRuleIndex(rule);
                adminAuthConfig.mapping_rules.push(rule);
            }
        }

        return settings;
    }

    /**
     * Sets the index of new rule.
     * @param {Object} rule - Mapping rule.
     */
    function setRuleIndex(rule) {
        const adminAuthConfig = $scope.settings.admin_auth_configuration;

        if (Array.isArray(adminAuthConfig.mapping_rules) && adminAuthConfig.mapping_rules.length) {
            const maxIndex = _.max(adminAuthConfig.mapping_rules, function(mappingRule) {
                return mappingRule.index;
            }).index;

            rule.index = maxIndex + 1;
        } else {
            adminAuthConfig.mapping_rules = [];
            rule.index = 0;
        }
    }

    /**
     * Closes modal.
     */
    vm.closeModal = function() {
        AviModal.destroy('adm-authentication-tenant-role-mapping-create');
    };

    $scope.$on('$destroy', function() {
        vm.tenantCollection.destroy();
        vm.roleCollection.destroy();
    });
}

authenticationTenantRoleMappingController.$inject = [
    '$scope',
    'AviModal',
    'TenantCollection',
    'RoleCollection',
    'systemConfigService',
    'schemaService',
    'l10nService',
];
