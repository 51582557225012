/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'alert';
const componentName = 'alert-config-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleAlertAction = `${prefix}.columnTitleAlertAction`;
export const columnTitleAlertGroup = `${prefix}.columnTitleAlertGroup`;
export const columnTitleObject = `${prefix}.columnTitleObject`;
export const allInstancesObjectName = `${prefix}.allInstancesObjectName`;

export const ENGLISH = {
    [headerLabel]: 'Alert Config',
    [columnTitleAlertAction]: 'Alert Action',
    [columnTitleAlertGroup]: 'Alert Group',
    [columnTitleObject]: 'Object',
    [allInstancesObjectName]: 'All Instances',
};
