/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './copyright.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @description
 *     Component used to display copyright information.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'copyright',
    templateUrl: './copyright.component.html',
})

export class CopyrightComponent {
    public message = '';

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        const currentYear = new Date().getFullYear();

        this.message = l10nService.getMessage(l10nKeys.message, [currentYear]);
    }
}
