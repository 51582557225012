/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
    defaultPositionsPriority,
    positionMap,
} from '../../directives/avi-tooltip';
import './avi-tooltip-icon.less';

/**
 * @description Component for an tooltip icon that shows transcluded content on hover.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-icon',
    templateUrl: './avi-tooltip-icon.component.html',
})
export class AviTooltipIconComponent {
    /**
     * Classname for the Clarity icon.
     */
    @Input()
    public shape = 'info-circle';

    /**
     * Size of the icon.
     */
    @Input()
    public size = '20';

    /**
     * Delay for the tooltip content to appear.
     */
    @Input()
    public delay = 250;

    /**
     * True to show the tooltip content when the icon is clicked.
     */
    @Input()
    public showOnClick = false;

    /**
     * List of positions to prioritize for rendering the tooltip content.
     */
    @Input()
    public positionsPriority = defaultPositionsPriority;

    /**
     * Classes to be set on the cds-icon element.
     */
    @Input()
    public iconClassName: string;

    /**
     * Color of the icon will be displayed from status attribute.
     */
    @Input()
    public status = '';

    /**
     * If true, icon will be displayed as solid.
     */
    @Input()
    public solid = false;

    /**
     * Called when the tooltip render position has changed.
     */
    @Output()
    public onPositionChange = new EventEmitter();

    /**
     * Handler for the position change of the tooltip content. Used to reposition the caret.
     */
    public handlePositionChange(connectedPosition: ConnectedPosition): void {
        this.onPositionChange.emit(positionMap.get(connectedPosition));
    }
}
