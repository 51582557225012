/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'gslb';

export const gslbPoolMemberUnavailableOperStatus = `${prefix}.gslbPoolMemberUnavailableOperStatus`;
export const federationCheckpointModalBreadcrumbTitle = `${prefix}.federationCheckpointModalBreadcrumbTitle`;
export const geoProfileModalBreadcrumbTitle = `${prefix}.geoProfileModalBreadcrumbTitle`;
export const gslbServicePoolModalBreadcrumbTitle = `${prefix}.gslbServicePoolModalBreadcrumbTitle`;
export const gslbServiceModalBreadcrumbTitle = `${prefix}.gslbServiceModalBreadcrumbTitle`;
export const gslbServicePoolMemberModalBreadcrumbTitle = `${prefix}.gslbServicePoolMemberModalBreadcrumbTitle`;

export const ENGLISH = {
    [gslbPoolMemberUnavailableOperStatus]: 'This site is not monitoring the GSLB service pool ' +
        'member',
    [federationCheckpointModalBreadcrumbTitle]: 'Federation Checkpoint',
    [geoProfileModalBreadcrumbTitle]: 'Geo Profile',
    [gslbServiceModalBreadcrumbTitle]: 'GSLB Service',
};
