/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-application-rules-list-header';
const prefix = `${moduleName}.${componentName}`;

export const applicationRulesTitle = `${prefix}.applicationRulesTitle`;
export const registerControllerMessage = `${prefix}.registerControllerMessage`;
export const registerControllerButtonLabel = `${prefix}.registerControllerButtonLabel`;
export const enableAppRulesOptInMessage = `${prefix}.enableAppRulesOptInMessage`;
export const editCloudServicesSettingsButtonLabel = `${prefix}.editCloudServicesSettingsButtonLabel`;
export const deprecationWarningLabel = `${prefix}.deprecationWarningLabel`;

export const ENGLISH = {
    [applicationRulesTitle]: 'Application Rules',
    [registerControllerMessage]: 'Register your Controller and easily stay current with Application Rules.',
    [registerControllerButtonLabel]: 'Register Controller',
    [enableAppRulesOptInMessage]: 'Keep Application Rules up-to-date by enabling Application Signature opt-in in Cloud Services.',
    [editCloudServicesSettingsButtonLabel]: 'Edit Cloud Services Settings',
    [deprecationWarningLabel]: 'Following the discontinuation of the Trustwave commercial rule-set, the application rules feature will be deprecated on 2024-06-30. Any rules published before the date can be used.',
};
