/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'internal-dns-service-domains-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleDomainName = `${prefix}.columnTitleDomainName`;
export const columnTitleRecordTtl = `${prefix}.columnTitleRecordTtl`;
export const domainNameInputPlaceholder = `${prefix}.domainNameInputPlaceholder`;
export const recordTtlInputPlaceholder = `${prefix}.recordTtlInputPlaceholder`;
export const dnsServiceDomainsGridHeader = `${prefix}.dnsServiceDomainsGridHeader`;
export const keyDuplicationErrorMsg = `${prefix}.keyDuplicationErrorMsg`;
export const duplicateValuesErrorMsg = `${prefix}.duplicateValuesErrorMsg`;
export const rangeLabel = `${prefix}.rangeLabel`;

export const ENGLISH = {
    [columnTitleDomainName]: 'Domain Name',
    [columnTitleRecordTtl]: 'Override Record TTL',
    [domainNameInputPlaceholder]: 'Enter Domain Name',
    [recordTtlInputPlaceholder]: 'Enter Record TTL (Seconds)',
    [dnsServiceDomainsGridHeader]: 'DNS Service Domains ({0})',
    [keyDuplicationErrorMsg]: 'System does not support duplicate values. Please update or delete.',
    [duplicateValuesErrorMsg]: 'Duplicate values: {0}.',
    [rangeLabel]: 'Range',
};
