<div class="ssl-certificate-signing-request">
    <avi-textarea-container>
        <label
            aviLabelWithTooltip
            for="signing-request"
            objectType="{{ objectType }}"
            fieldName="certificate_signing_request"
        >
            {{ l10nKeys.certificateSigningRequestLabel | vtranslate }}
        </label>
        <textarea
            clrTextarea
            id="certificate-signing-request"
            name="signing-request"
            [(ngModel)]="signingRequest"
            placeholder="{{ l10nKeys.signingRequestPlaceholder | vtranslate }}"
            disabled
            rows="5"
        ></textarea>
    </avi-textarea-container>
    <div class="ssl-certificate-signing-request__copy-button">
        <cds-button
            size="sm"
            [disabled]="!signingRequest"
            (click)="copyToClipboard()"
        >
            {{ globalL10nKeys.copyToClipboardLabel | vtranslate }}
        </cds-button>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="certificate"
                for="certificate"
            >
                {{ l10nKeys.certificateInputLabel | vtranslate }}
            </label>
            <file-upload-textarea
                [(ngModel)]="certificate"
                (onUploadTypeChange)="setCertificateBase64($event)"
                (ngModelChange)="handleCertificateChange()"
                placeholder="{{ l10nKeys.certificateFileInputPlaceholder | vtranslate }}"
                name="certificate"
                [base64]="true"
            ></file-upload-textarea>
        </div>
    </div>
</div>
