/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'data-grid';
const componentName = 'avi-data-grid-base';
const prefix = `${moduleName}.${componentName}`;

export const numberOfItemsLabel = `${prefix}.numberOfItemsLabel`;
export const itemsPerPageLabel = `${prefix}.itemsPerPageLabel`;
export const defaultEmptyListPlaceholderMessage = `${prefix}.defaultEmptyListPlaceholderMessage`;
export const columnSelectAllButton = `${prefix}.columnSelectAllButton`;
export const showColumnsHeader = `${prefix}.showColumnsHeader`;

export const ENGLISH = {
    [numberOfItemsLabel]: '{0} - {1} of {2} items',
    [itemsPerPageLabel]: 'Items per page',
    [defaultEmptyListPlaceholderMessage]: 'We couldn’t find any objects!',
    [columnSelectAllButton]: 'Select All',
    [showColumnsHeader]: 'Show Columns',
};
