/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    IUIProperty,
    IUserPreferences,
    IUserPreferencesState,
    SHOW_NEW_VS_LOGS_PAGE,
} from './user-preferences.state';

export const featureKey = 'userPreferences';

const selectFeature = createFeatureSelector<IUserPreferencesState>(
    featureKey,
);

export const selectLoading = createSelector(
    selectFeature,
    (state: IUserPreferencesState) => state.loading,
);

export const selectLoaded = createSelector(
    selectFeature,
    (state: IUserPreferencesState) => state.loaded,
);

export const selectUserPreferences = createSelector(
    selectFeature,
    (state: IUserPreferencesState) => state.userPreferences,
);

export const selectUIProperty = createSelector(
    selectUserPreferences,
    (state: IUserPreferences) => state.ui_property,
);

export const selectUtcTime = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.useUTCTime,
);

export const selectLanguage = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.language,
);

export const selectValuesToDisplay = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.valuesToDisplay,
);

export const selectTimeframe = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.timeframe,
);

export const selectAppDashboard = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.appDashboard,
);

export const selectLogs = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.logs,
);

export const selectGrid = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty.grid,
);

export const selectShowNewLogsPage = createSelector(
    selectUIProperty,
    (uiProperty: IUIProperty) => uiProperty[SHOW_NEW_VS_LOGS_PAGE],
);
