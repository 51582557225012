/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    FailActionEnum,
    IFailAction,
} from 'generated-types';
import { FailAction } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import type {
    FailActionHttpRedirectConfigItem,
} from './fail-action-http-redirect.config-item.factory';

import type {
    FailActionHttpLocalResponseConfigItem,
} from './fail-action-http-local-response.config-item.factory';

type TFailAction = Omit<IFailAction, 'redirect' | 'local_rsp'>;

interface IFailActionConfig extends TFailAction {
    local_rsp?: FailActionHttpLocalResponseConfigItem,
    redirect?: FailActionHttpRedirectConfigItem,
}

/**
 * @description FailAction ConfigItem.
 *
 * @author Rachit Aggarwal
 */
export class FailActionConfigItem extends MessageItem<IFailActionConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: FailAction,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns Local Response config item.
     */
    public get localResponse(): FailActionHttpLocalResponseConfigItem {
        return this.config.local_rsp;
    }

    /**
     * Returns redirect config item.
     */
    public get httpRedirect(): FailActionHttpRedirectConfigItem {
        return this.config.redirect;
    }

    /**
     * Triggered when Fail action type changes.
     */
    public onTypeChange(): void {
        if (this.config.type === FailActionEnum.FAIL_ACTION_HTTP_REDIRECT) {
            this.safeSetNewChildByField('redirect');
            delete this.config.local_rsp;
        } else if (this.config.type === FailActionEnum.FAIL_ACTION_HTTP_LOCAL_RSP) {
            this.safeSetNewChildByField('local_rsp');
            delete this.config.redirect;
        } else {
            delete this.config.local_rsp;
            delete this.config.redirect;
        }
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        if (this.config.type === FailActionEnum.FAIL_ACTION_HTTP_REDIRECT) {
            delete this.config.local_rsp;
        } else if (this.config.type === FailActionEnum.FAIL_ACTION_HTTP_LOCAL_RSP) {
            delete this.config.redirect;
        } else {
            delete this.config.local_rsp;
            delete this.config.redirect;
        }
    }
}
