/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UpgradeFsmState } from 'generated-types';
import { UpdateService } from 'ng/modules/update/services/update.service';

import {
    Observable,
    Subject,
} from 'rxjs';

import { ITUpgradeNode } from 'ajs/modules/upgrade/factories/upgrade-node.item.factory';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';

import {
    IUserSelectedOptions,
    UpgradeType,
} from '../update.types';

interface IUpgradeState {
    isSegUpgrade: boolean;
    preChecksTriggered: boolean;
    preChecksCompleted: boolean;
    upgradeCollectionLoaded: boolean;
    isPreCheckFlow: boolean;
    isSystemUpdateInProgress: boolean;
    isUpgradeTriggeredFromSystemUpdatePage: boolean;
    overallPrechecksState?: UpgradeFsmState;
    precheckNodes?: ITUpgradeNode[];
    userSelectedOptions: IUserSelectedOptions;
    rollbackVersion: string;
    selectedSegs?: ITSEGroup[];
    selectedSegUpgradeImages?: Image[];
}

const INITIAL_USER_SELECTED_OPTIONS: IUserSelectedOptions = {};

const INITIAL_UPGRADE_STATE: IUpgradeState = {
    isSegUpgrade: false,
    isPreCheckFlow: false,
    preChecksTriggered: false,
    preChecksCompleted: false,
    precheckNodes: [],
    upgradeCollectionLoaded: false,
    userSelectedOptions: INITIAL_USER_SELECTED_OPTIONS,
    rollbackVersion: '',
    selectedSegs: [],
    selectedSegUpgradeImages: [],
    isUpgradeTriggeredFromSystemUpdatePage: false,
    isSystemUpdateInProgress: false,
};

interface IPreChecksState {
    preChecksTriggered: boolean,
    preChecksCompleted: boolean,
    upgradeCollectionLoaded?: boolean,
    overallPrechecksState?: UpgradeFsmState,
}

/**
 * Type for config required to open precheck progress modal.
 */
export interface IPrecheckModalConfig {
    readonly: boolean,
    nodeIds: string[],
}

/**
 * @description The Update store manages states commonly used by all the
 *  components in the Update module.
 * @author Sarthak Kapoor, Nisar Nadaf
 */
@Injectable()
export class UpdateStore extends ComponentStore<IUpgradeState> {
    /**
     * Return if prechecks triggered from state.
     */
    public readonly preChecksTriggered$ = this.select(state => state.preChecksTriggered);

    /**
     * Return if prechecks completed from state.
     */
    public readonly preChecksCompleted$ = this.select(state => state.preChecksCompleted);

    /**
     * Return true on successful load of upgrade node collection.
     */
    public readonly onUpgradeCollectionLoad$ = this.select(state => state.upgradeCollectionLoaded);

    /**
     * Return if Service Engine Groups will be upgraded/rollbacked as well along with Controller.
     */
    public readonly isSegUpgrade$ = this.select(state => state.isSegUpgrade);

    /**
     * Return if the last opration was the precheck flow or actual upgrade/rollback.
     */
    public readonly isPreCheckFlow$ = this.select(state => state.isPreCheckFlow);

    /**
     * Hold the latest values, user selected in the update modal.
     */
    public readonly userSelectedOptions$ = this.select(state => state.userSelectedOptions);

    /**
     * Hold the latest value of overall status of prechecks.
     */
    public readonly overallPrechecksState$ = this.select(state => state.overallPrechecksState);

    /**
     * Hold the updated list of nodes received on completion of prechecks.
     */
    public readonly precheckNodes$ = this.select(state => state.precheckNodes);

    /**
     * Hold the selected rollback version.
     */
    public readonly rollbackVersion$ = this.select(state => state.rollbackVersion);

    /**
     * Hold the updated list of selected segs for upgrade.
     */
    public readonly selectedSegs$ = this.select(state => state.selectedSegs);

    /**
     * Hold the updated list of selected Seg upgrade images.
     */
    public readonly selectedSegUpgradeImages$ = this.select(
        state => state.selectedSegUpgradeImages,
    );

    /**
     * To identify the page from where the latest update operation was triggered.
     */
    public readonly isUpgradeTriggeredFromSystemUpdatePage$ = this.select(
        state => state.isUpgradeTriggeredFromSystemUpdatePage,
    );

    /**
     * To identify if the system update is in progress.
     */
    public readonly isSystemUpdateInProgress$ = this.select(
        state => state.isSystemUpdateInProgress,
    );

    /**
     * Subject to update the precheck list on successful load.
     */
    private preChecksUpdate$ = new Subject<void>();

    /**
     * Open the precheck transcript modal from right rail card.
     */
    private openPreCheckTranscript$ = new Subject<IPrecheckModalConfig>();

    /**
     * Set Prechecks state on trigger/completion of prechecks.
     */
    private setPreChecksState = this.updater((state, preChecksState: IPreChecksState) => {
        return {
            ...state,
            ...preChecksState,
        };
    });

    /**
     * Set Upgrade Node Collection load state.
     */
    private setUpgradeCollectionLoadedState = this.updater(
        (state, upgradeCollectionLoaded: boolean) => {
            return {
                ...state,
                upgradeCollectionLoaded,
            };
        },
    );

    /**
     * Set isSegUpgrade state.
     */
    private setIsSegUpgrade = this.updater(
        (state, isSegUpgrade: boolean) => {
            return {
                ...state,
                isSegUpgrade,
            };
        },
    );

    /**
     * Set isPreCheckFlow state.
     */
    private setIsPreCheckFlowState = this.updater(
        (state, isPreCheckFlow: boolean) => {
            return {
                ...state,
                isPreCheckFlow,
            };
        },
    );

    /**
     * Update state with user selected options from update modal.
     */
    private setUserSelectedOptionsState = this.updater(
        (state, userSelectedOptions: IUserSelectedOptions) => {
            return {
                ...state,
                userSelectedOptions,
            };
        },
    );

    /**
     * Reset prechecks triggered state.
     */
    private resetPrechecksTriggeredState = this.updater(
        (state, preChecksTriggered: boolean) => {
            return {
                ...state,
                preChecksTriggered,
            };
        },
    );

    /**
     * Update precheckNodes array with latest values.
     */
    private setPrecheckNodesState = this.updater(
        (state, precheckNodes: ITUpgradeNode[]) => {
            return {
                ...state,
                precheckNodes: [...precheckNodes],
            };
        },
    );

    /**
     * Reset prechecksCompleted state to false.
     */
    private resetPrechecksCompletedState = this.updater(
        (state, preChecksCompleted: boolean) => {
            return {
                ...state,
                preChecksCompleted,
            };
        },
    );

    private updateRollbackVersion = this.updater(
        (state, rollbackVersion: string) => {
            return {
                ...state,
                rollbackVersion,
            };
        },
    );

    /**
     * Set selected segs list state.
     */
    private setSelectedSegsListState = this.updater(
        (state, selectedSegsList: ITSEGroup[]) => {
            return {
                ...state,
                selectedSegs: [...selectedSegsList],
            };
        },
    );

    /**
     * Set selected seg upgrade images state.
     */
    private setSelectedSegUpgradeImagesState = this.updater(
        (state, selectedSegUpgradeImages: Image[]) => {
            return {
                ...state,
                selectedSegUpgradeImages: [...selectedSegUpgradeImages],
            };
        },
    );

    /**
     * Set the isUpgradeTriggeredFromSystemUpdatePage state.
     */
    private setIsSystemUpdatePageState = this.updater(
        (state, isSystemUpdatePage: boolean) => {
            return {
                ...state,
                isUpgradeTriggeredFromSystemUpdatePage: isSystemUpdatePage,
            };
        },
    );

    /**
     * Set the overallPrechecks state.
     */
    private setOverallPrechecksState = this.updater(
        (state, overallPrechecksState?: UpgradeFsmState) => {
            return {
                ...state,
                overallPrechecksState,
            };
        },
    );

    /**
     * Reset overallPrechecksState.
     */
    private resetOverallPrechecksState = this.updater(
        state => {
            return {
                ...state,
                overallPrechecksState: undefined,
            };
        },
    );

    /**
     * Set the isSystemUpdateInProgress state.
     */
    private setIsSystemUpdateInProgressState = this.updater(
        (state, isSystemUpdateInProgress: boolean) => {
            return {
                ...state,
                isSystemUpdateInProgress,
            };
        },
    );

    constructor(
        private readonly updateService: UpdateService,
    ) {
        super(INITIAL_UPGRADE_STATE);
    }

    /**
     * Set Prechecks state on trigger of prechecks.
     */
    public onPreChecksTrigger(): void {
        this.setPreChecksState({
            preChecksTriggered: true,
            preChecksCompleted: false,
            upgradeCollectionLoaded: false,
        });
    }

    /**
     * Set Prechecks state on completion of prechecks.
     */
    public onPrechecksComplete(overallPrechecksState: UpgradeFsmState): void {
        this.setPreChecksState({
            preChecksTriggered: false,
            preChecksCompleted: true,
            upgradeCollectionLoaded: false,
            overallPrechecksState,
        });
    }

    /**
     * Set upgradeCollectionLoaded state on successful load for the first time
     * after prechecks are completed.
     */
    public onUpgradeCollectionLoadSuccess(): void {
        this.setUpgradeCollectionLoadedState(true);
    }

    /**
     * Set isSegUpgrade state.
     */
    public setIsSegUpgradeState(isSegUpgradeSelected = false): void {
        this.setIsSegUpgrade(isSegUpgradeSelected);
    }

    /**
     * Returns an observable to allow subscriptions to prechecks change.
     */
    public get preChecksUpdate(): Observable<void> {
        return this.preChecksUpdate$.asObservable();
    }

    /**
     * Called when the Precheck status response has loaded.
     */
    public onPreChecksLoad(): void {
        this.preChecksUpdate$.next();
    }

    /**
     * Return an observable to allow subscriptions to precheck transcript modal open event.
     */
    public get precheckTranscriptOpen$(): Observable<IPrecheckModalConfig> {
        return this.openPreCheckTranscript$.asObservable();
    }

    /**
     * Open precheck transcript modal.
     */
    public openPreCheckTranscript(precheckConfig: IPrecheckModalConfig): void {
        this.openPreCheckTranscript$.next(precheckConfig);
    }

    /**
     * Set isPreCheckFlow state.
     */
    public setIsPreCheckFlow(isPreCheckFlow: boolean): void {
        this.setIsPreCheckFlowState(isPreCheckFlow);
    }

    /**
     * Set User selected options.
     */
    public setUserSelectedOptions(options: IUserSelectedOptions): void {
        this.setUserSelectedOptionsState(options);
    }

    /**
     * Reset prechecks triggered state.
     */
    public resetPreChecksTriggered(): void {
        this.resetPrechecksTriggeredState(false);
    }

    public setPrecheckNodes(nodes: ITUpgradeNode[]): void {
        this.setPrecheckNodesState(nodes);
    }

    public resetPrecheckNodes(): void {
        this.setPrecheckNodesState([] as ITUpgradeNode[]);
    }

    public resetPrechecksCompleted(): void {
        this.resetPrechecksCompletedState(false);
    }

    /**
     * Trigger prechecks for upgrade/rollback operation.
     */
    public triggerPrechecks(
        precheckOptions: IUserSelectedOptions,
        skipWarnings: boolean,
    ): Promise<void> {
        const {
            UPGRADE_TYPE_SYSTEM_UPGRADE,
            UPGRADE_TYPE_SEG_UPGRADE,
            UPGRADE_TYPE_SYSTEM_ROLLBACK,
        } = UpgradeType;

        const {
            upgradeConfig,
            applyToSystem = true,
            actionOnSegFailure,
            upgradeType,
        } = precheckOptions;

        switch (upgradeType) {
            case UPGRADE_TYPE_SYSTEM_UPGRADE:
                return this.updateService.startSystemUpgrade(
                    upgradeConfig,
                    skipWarnings,
                    applyToSystem,
                    actionOnSegFailure,
                );
            case UPGRADE_TYPE_SEG_UPGRADE:
                return this.updateService.startSegUpgrade(
                    upgradeConfig,
                    skipWarnings,
                    actionOnSegFailure,
                );
            case UPGRADE_TYPE_SYSTEM_ROLLBACK:
                return this.updateService.startSystemRollback(
                    upgradeConfig,
                    skipWarnings,
                    applyToSystem,
                );
            default: return Promise.reject();
        }
    }

    public setRollbackVersion(version = ''): void {
        this.updateRollbackVersion(version);
    }

    /**
     * Invoke the updater method to set the list of selected segs state.
     */
    public setSelectedSegsList(selectedSegsList: ITSEGroup[] = []): void {
        this.setSelectedSegsListState(selectedSegsList);
    }

    /**
     * Invoke the state updater method to set the list of selected SEG upgrade images.
     */
    public setSelectedSegUpgradeImages(selectedSegUpgradeImages: Image[] = []): void {
        this.setSelectedSegUpgradeImagesState(selectedSegUpgradeImages);
    }

    /**
     * Invoke the state updater method to set if upgrade is triggered from System update page.
     */
    public setIsSystemUpdatePage(isSystemUpdatePage: boolean): void {
        this.setIsSystemUpdatePageState(isSystemUpdatePage);
    }

    /**
     * Invoke the state updater method to the overallPrechecks state.
     */
    public updateOverallPrechecksState(overallPreChecksState: UpgradeFsmState): void {
        this.setOverallPrechecksState(overallPreChecksState);
    }

    /**
     * Invoke the state updater method to reset the overallPrechecks state.
     */
    public resetOverallPrechecksStatusState(): void {
        this.resetOverallPrechecksState();
    }

    /**
     * Invoke the state updater method to update the isSystemUpdateInProgress state.
     */
    public updateIsSystemUpdateInProgress(isSystemUpdateInProgress = false): void {
        this.setIsSystemUpdateInProgressState(isSystemUpdateInProgress);
    }
}
