<item-full-modal
    modalTitle="{{ l10nKeys.modalHeader | vtranslate }}"
    [item]="editable"
    [form]="form"
    (onSubmit)="submit()"
>
    <form 
        clrForm 
        [clrLayout]="verticalLayout" 
        #form="ngForm"
    >
        <!-- General -->
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="replication_policy"
                        >
                            {{ l10nKeys.replicationModeLabel | vtranslate }}
                        </label>
                        <enum-radio-buttons
                            name="gslb-replication-mode"
                            id="gslb-replication-mode"
                            [(ngModel)]="editable.config.replication_policy.config.replication_mode"
                            enum="ReplicationMode"
                            [hiddenEnumValues]="hiddenReplicationModeTypes"
                            noMarginTop
                        ></enum-radio-buttons>
                    </div>
                </div>
                <gslb-client-ip-addr-group-config
                    [clientIpAddrGroup]="editable.config.client_ip_addr_group"
                ></gslb-client-ip-addr-group-config>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
