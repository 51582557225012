<credentials-verification-dialog
    [disableSubmit]="form.invalid"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="errors"
    [busy]="busy"
    [submitButtonLabel]="l10nKeys.submitButtonLabel | vtranslate"
>
    <credentials-verification-dialog_header>
        <h3>{{ credentialsDialogHeader | vtranslate }}</h3>
    </credentials-verification-dialog_header>
    <credentials-verification-dialog_form>
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
        >
            <avi-alert-group alertType="info">
                {{ credentialsDialogAlert | vtranslate }}
            </avi-alert-group>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ messageType }}"
                        fieldName="{{ cloudRefFieldName }}"
                        for="{{ cloudRefFieldName }}"
                        required
                    >
                        {{ globalL10nKeys.cloudLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        [collection]="cloudCollection"
                        [(ngModel)]="config.cloud_ref"
                        placeholder="{{ globalL10nKeys.cloudPlaceholder | vtranslate }}"
                        name="{{ cloudRefFieldName }}"
                        [disableCreate]="true"
                        [disableEdit]="true"
                        (ngModelChange)="onCloudSelect()"
                        required
                    ></avi-collection-dropdown>
                </div>
            </div>
            <div
                *ngIf="!disallowVrfContextSelection"
                class="full-modal-config__form-control-row"
            >
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ messageType }}"
                        fieldName="{{ vrfRefFieldName }}"
                        for="{{ vrfRefFieldName }}"
                        required
                    >
                        {{ l10nKeys.vrfContextSelectLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        [collection]="vrfContextCollection"
                        [(ngModel)]="config.vrf_ref"
                        placeholder="{{ l10nKeys.vrfContextSelectPlaceholder | vtranslate }}"
                        name="{{ vrfRefFieldName }}"
                        [disableCreate]="true"
                        [disableEdit]="true"
                        [disabled]="!config.cloud_ref"
                        required
                    ></avi-collection-dropdown>
                </div>
            </div>
        </form>
    </credentials-verification-dialog_form>
</credentials-verification-dialog>
