/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'roles-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [headerLabel]: 'Roles',
};
