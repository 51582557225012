/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    findIndex,
    identity,
    isEmpty,
    isUndefined,
    max,
    pick,
} from 'underscore';

import { IHttpResponse } from 'angular';

import {
    IBaseRequestPromise,
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { L10nService } from '@vmw/ngx-vip';
import { WafPolicy } from 'object-types';

import {
    AviPermissionResource,
    IBotClassification,
    IBotDetectionMatch,
    IWafCRS,
    IWafExcludeListEntry,
    IWafPolicy,
    IWafRuleGroup,
} from 'generated-types';

import * as globalL10n from 'global-l10n';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { WafPolicyPsmGroup } from 'ajs/modules/waf/factories/waf-policy-psm-group.item.factory';
import { WafCrs } from '../waf-crs.item.factory';
import { WAF_CRS_COLLECTION_TOKEN } from '../waf-crs.collection.factory';
import * as l10n from '../../waf.l10n';
import { WafRuleConfigItem } from '../waf-rule.config-item.factory';
import { WafRuleGroupConfigItem } from '../waf-rule-group.config-item.factory';
import { WafRuleGroupOverridesConfigItem } from '../waf-rule-group-overrides.config-item.factory';
import { WafPolicyAllowlistConfigItem } from '../waf-policy-allowlist.config-item.factory';

import {
    WafApplicationSignaturesConfigItem,
} from '../waf-application-signatures.config-item.factory';

import { WafPolicyAllowlistRuleConfigItem } from '../waf-policy-allowlist-rule.config-item.factory';

import {
    WafPositiveSecurityModelConfigItem,
} from '../waf-positive-security-model.config-item.factory';

import { WafRuleOverridesConfigItem } from '../waf-rule-overrides.config-item.factory';
import { WafExcludeListEntryConfigItem } from '../waf-exclude-list-entry.config-item.factory';
import { AppLearningParamsConfigItem } from '../app-learning-params.config-item.factory';

export const PRE_CRS_GROUPS_FIELD = 'pre_crs_groups';
export const POST_CRS_GROUPS_FIELD = 'post_crs_groups';

const USER_DEFINED_BOT = 'USER_DEFINED_BOT';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Waf policy feilds to be omited.
 */
type TWafPolicyOmitFields = 'crs_overrides' |
'pre_crs_groups' |
'crs_groups' |
'post_crs_groups' |
'allowlist' |
'application_signatures' |
'positive_security_model' |
'learning_params'
;

/**
 * Bot Detection fields to be omitted.
 */
type TBotDetectionMatchFields = 'classifications';

/**
 * Custom Bot Detection Type.
 */
type TBotDetectionPartial = Omit<IBotDetectionMatch, TBotDetectionMatchFields>;

/**
 * Interface for IBotDetectionMatchConfig.
 */
interface IBotDetectionMatchConfig extends TBotDetectionPartial {
    classifications: RepeatedMessageItem<MessageItem<IBotClassification>>;
}

/**
 * Custom Waf Policy Type.
 */
type TWafPolicyPartial = Omit<IWafPolicy, TWafPolicyOmitFields>;

/**
 * Interface for IWafPolicyConfig
 */
export interface IWafPolicyConfig extends TWafPolicyPartial {
    crs_overrides?: RepeatedMessageItem<WafRuleGroupOverridesConfigItem>;
    pre_crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    post_crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    allowlist?: MessageItem<WafPolicyAllowlistConfigItem>;
    application_signatures?: MessageItem<WafApplicationSignaturesConfigItem>;
    positive_security_model?: MessageItem<WafPositiveSecurityModelConfigItem>;
    learning_params?: AppLearningParamsConfigItem,
}

interface IWafPolicySaveRequestPayload {
    model_name: string;
    data: IWafPolicyConfig;
}

/**
 * @description
 * Waf Policy Item class.
 *
 * Previously, there were 3 repeated fields - crs_groups, pre_crs_groups, and post_crs_groups.
 * crs_groups has been deprecated, and those objects now come from waf_crs_ref, loaded with join
 * into waf_crs_ref_data.
 *
 * Objects in waf_crs_ref_data (CRS groups) are immutable, so if a user wants to make changes,
 * that's done with crs_overrides instead. pre_crs_groups and post_crs_groups can still be modified.
 *
 * @author Hitesh Mandav, alextsg, Shahab Hashmi
 */
export class WafPolicyItem extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static ajsDependencies = [
        'WafRuleGroupConfigItem',
        '$q',
        'WafPolicyPsmGroupCollection',
        'WafPolicyPsmGroup',
        WAF_CRS_COLLECTION_TOKEN,
        'l10nService',
    ];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    /**
     * Map of Rule IDs to Rules. Contains only Pre and Post CRS Rules.
     */
    private rulesMap: Map<string, WafRuleConfigItem>;

    /**
     * Map of Group names to Groups. Contains only Pre and Post CRS Groups.
     */
    private groupsMap: Map<string, WafRuleGroupConfigItem>;

    /**
     * Map for CRS group overide name to group override config.
     */
    private crsGroupOverridesMap: Map<string, WafRuleGroupOverridesConfigItem>;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpolicy',
            windowElement: 'lazy-load',
            params: {
                include_name: true,
                join: [
                    'wafpolicypsmgroup:positive_security_model.group_refs',
                    'waf_crs_ref',
                ],
            },
            permissionName: AviPermissionResource.PERMISSION_WAFPOLICY,
            objectType: WafPolicy,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        if (this.getConfig()) {
            this.setWafCrsData();

            [this.groupsMap, this.rulesMap] = this.getNonCrsMaps();
            this.crsGroupOverridesMap = this.createCrsGroupOverridesMap();
        }
    }

    /**
     * @override
     */
    public urlToSave(): string {
        return '/api/macro';
    }

    /**
     * After save, PSM groups_refs_data objects are returned outside of the WafPolicy data as
     * part of the rsp.data array. We need to merge them back into config.
     * @override
     */
    public transformDataAfterSave(rsp: IHttpResponse<any>): IWafPolicyConfig {
        const { data: responses } = rsp;

        // If response contains array of objects, the last object is the one that we need
        if (!Array.isArray(responses)) {
            return responses;
        }

        const { length: responseLength } = responses;
        const wafPolicyData = responses[responseLength - 1];

        // No groups_refs_data.
        if (responseLength === 1) {
            return wafPolicyData;
        }

        const { positive_security_model: psm = {} } = wafPolicyData;
        const { group_refs: psmGroupRefs = [] } = psm;
        const groupRefDataHash = responses.reduce((acc, groupRefData, index) => {
            if (index === responseLength - 1) {
                return acc;
            }

            // _last_modified is returned in the rsp. If we send _last_modified within the
            // groupRefData, the backend will complain.
            /* eslint no-underscore-dangle: 0 */
            delete groupRefData._last_modified;
            acc[groupRefData.url] = { ...groupRefData };

            return acc;
        }, {});

        if (psmGroupRefs.length) {
            psm.group_refs_data =
                psmGroupRefs.map((groupRef: string) => groupRefDataHash[groupRef]);
        }

        return wafPolicyData;
    }

    /**
     * @override
     */
    public transformAfterLoad(): void {
        this.setWafCrsData();

        [this.groupsMap, this.rulesMap] = this.getNonCrsMaps();
        this.crsGroupOverridesMap = this.createCrsGroupOverridesMap();
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();
        const {
            allowlist,
            positive_security_model: psm,
        } = config;

        if (isEmpty(allowlist)) {
            this.setNewChildByField('allowlist');
        }

        if (isEmpty(psm)) {
            this.setNewChildByField('positive_security_model');
        }
    }

    /**
     * @override
     */
    public dataToSave(): IWafPolicyConfig {
        const config = super.dataToSave();

        // Delete WAF CRS data, used only as a reference for the user to see the originally
        // configured data.
        delete config.waf_crs_ref_data;

        return config;
    }

    /**
     * Returns true if any PSM Groups are learning groups.
     */
    public hasLearningGroup(): boolean {
        return this.config.positive_security_model?.hasLearningGroup();
    }

    /**
     * Returns a WafRuleGroupConfig ConfigItem given the group name.
     */
    public getNonCrsGroupByGroupName(groupName: string): WafRuleGroupConfigItem {
        return this.groupsMap.get(groupName);
    }

    /**
     * Returns a WafRuleConfig ConfigItem given a rule ID.
     */
    public getNonCrsRuleByRuleId(ruleId: string): WafRuleConfigItem {
        return this.rulesMap.get(ruleId);
    }

    /**
     * Returns the name of a rule given a rule ID.
     */
    public getRuleNameByRuleId(ruleId: string, fullName: boolean): string {
        if (this.rulesMap.has(ruleId)) {
            // Is Pre or Post CRS Rule.
            const rule = this.getNonCrsRuleByRuleId(ruleId);

            return rule.getRuleName(fullName);
        } else if (this.wafCrs.hasRule(ruleId)) {
            // Is CRS Rule.
            return this.wafCrs.getRuleName(ruleId, fullName);
        } else {
            return '';
        }
    }

    /**
     * Returns the max index of all the groups within config[groupsProperty].
     */
    public getMaxGroupIndex(groupsProperty: string): number {
        const config = this.getConfig();
        const maxIndexGroup = max(config[groupsProperty].config, group => group.getIndex());

        return !isEmpty(maxIndexGroup) && maxIndexGroup.getIndex() + 1 || 0;
    }

    /**
     * Removes a group.
     */
    public removeGroup(groupsProperty: string, group: WafRuleGroupConfigItem): void {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        repeatedGroup.removeByMessageItem(group);
    }

    /**
     * Returns the array position index from the group.index property.
     */
    public getArrayIndexFromGroupIndex(groupsProperty: string, groupIndex: number): number {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        return findIndex(repeatedGroup.config, group => group.getIndex() === groupIndex);
    }

    /**
     * Moves group to a new index. All groups in-between need to have their indices shifted.
     */
    public moveGroup(groupsProperty: string, oldIndex: number, newIndex: number): void {
        let newIndexCounter = newIndex;
        /**
         * newIndex moves towards the direction of oldIndex
         */
        const increment = oldIndex < newIndex ? -1 : 1;

        while (oldIndex !== newIndexCounter) {
            this.swapGroup(groupsProperty, oldIndex, newIndexCounter);
            newIndexCounter += increment;
        }
    }

    /**
     * Given two indices of groups, swaps positions in the rules array along with the index
     * property in the rule.
     */
    public swapGroup(groupsProperty: string, oldIndex: number, newIndex: number): void {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        const oldGroup = repeatedGroup.at(oldIndex);
        const newGroup = repeatedGroup.at(newIndex);

        repeatedGroup.config[oldIndex] = newGroup;
        repeatedGroup.config[newIndex] = oldGroup;

        /**
         * Actual 'index' property of the rule.
         */
        const oldIndexValue = oldGroup.getIndex();
        const newIndexValue = newGroup.getIndex();

        oldGroup.setIndex(newIndexValue);
        newGroup.setIndex(oldIndexValue);
    }

    /**
     * Adds a group to the WafPolicy. If the group is a new group and does not contain an
     * index, add it to the end of the list. Otherwise, replace an existing group with the
     * group as it has been edited.
     */
    public saveGroup(newGroup: WafRuleGroupConfigItem): void {
        const fieldName = newGroup.getFieldName();
        const { [fieldName]: repeatedGroup } = this.getConfig();

        if (isUndefined(newGroup.getIndex())) {
            const maxIndexGroup = max(repeatedGroup.config, group => group.getIndex());
            const newIndex = !isEmpty(maxIndexGroup) ? maxIndexGroup.getIndex() + 1 : 0;

            newGroup.setIndex(newIndex);
            repeatedGroup.add(newGroup);
        } else {
            const newIndex = newGroup.getIndex();
            const oldIndex = findIndex(repeatedGroup.config, group => {
                return group.getIndex() === newIndex;
            });

            // Index exists, so rule is being edited.
            if (oldIndex !== -1) {
                repeatedGroup.config[oldIndex] = newGroup;
            } else {
                repeatedGroup.add(newGroup);
            }
        }
    }

    /**
     * Returns the ConfigItem based on the type and identifier.
     */
    public getConfigItemById(
        type: 'rule' | 'group',
        id: string,
    ): WafRuleConfigItem | WafRuleGroupConfigItem {
        return type === 'rule' ?
            this.getNonCrsRuleByRuleId(id) :
            this.getNonCrsGroupByGroupName(id);
    }

    /**
     * Returns true if the group or rule already contains the exception.
     */
    public hasMatchingException(type: 'rule' | 'group', id: string, exception: any): boolean {
        const configItem = this.getConfigItemById(type, id);

        return configItem?.hasMatchingException(exception) || false;
    }

    /**
     * Adds an exception to the group or rule.
     */
    public addException(
        type: 'rule' | 'group',
        id: string,
        exception: WafExcludeListEntryConfigItem | IWafExcludeListEntry,
    ): void {
        // If CRS group
        if (this.wafCrs.hasGroup(id)) {
            const groupOverride = this.getOrCreateCrsGroupOverride(id);

            groupOverride.excludeList.add(exception);
        // If CRS rule
        } else if (this.wafCrs.hasRule(id)) {
            const ruleOverride = this.getOrCreateCrsRuleOverride(id);

            ruleOverride.excludeList.add(exception);
        // If Pre-CRS or Post-CRS group/rule.
        } else {
            const configItem = this.getConfigItemById(type, id);

            configItem.addExcludeListEntry(pick(exception, identity));
        }
    }

    /**
     * Returns true if policy mode delegation is allowed.
     */
    public modeDelegationIsAllowed(): boolean {
        return Boolean(this.getConfig().allow_mode_delegation);
    }

    /**
     * Returns policy mode.
     */
    public getMode(): string {
        return this.getConfig().mode;
    }

    /**
     * Update the WafPolicy with a set of CRS rules.
     * setError is used to hide error on waf modal in case the error is already handled
     * and displayed on waf crs version change dialog.
     */
    public updateCrsRules(
        wafCrsUuid: string,
        commit = false,
        setError = true,
    ): IBaseRequestPromise<any> {
        const $q = this.getAjsDependency_('$q');
        const wafPolicyUuid = this.id;
        const api = `/api/wafpolicy/${wafPolicyUuid}/update-crs-rules`;
        const payload = {
            waf_crs_ref: wafCrsUuid,
            commit,
        };

        this.busy = true;
        this.errors = null;

        return this.request('PUT', api, payload)
            .catch(({ data }) => {
                if (setError) {
                    this.errors = data.error;
                }

                return $q.reject(data.error);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Getter for the allowlist property.
     */
    public get allowlist(): WafPolicyAllowlistConfigItem {
        return this.getConfig().allowlist;
    }

    /**
     * Getter for Application signature object.
     */
    public get applicationSignatures(): WafApplicationSignaturesConfigItem {
        return this.getConfig().application_signatures;
    }

    /**
     * Returns the number of configured allowlist rules.
     */
    public getAllowlistRulesCount(): number {
        return this.allowlist.getRulesCount();
    }

    /**
     * Returns a new WafPolicyAllowlistRule instance.
     */
    public createNewAllowlistRule(): WafPolicyAllowlistRuleConfigItem {
        return this.allowlist.createNewRule();
    }

    /**
     * Adds an allowlist rule to the config.
     */
    public addAllowlistRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.allowlist.addRule(rule);
    }

    /**
     * Getter for the positive_security_model config object.
     */
    public get psm(): WafPositiveSecurityModelConfigItem {
        return this.getConfig().positive_security_model;
    }

    /**
     * Getter for the 'enable_app_learning' flag.
     */
    public get appLearning(): boolean {
        return this.getConfig().enable_app_learning;
    }

    /**
     * Setter for the 'enable_app_learning` flag.
     */
    public set appLearning(enableAppLearning: boolean) {
        this.getConfig().enable_app_learning = enableAppLearning;
    }

    /**
     * Setter for the 'allow_mode_delegation` flag.
     */
    public set allowModeDelegation(allowModeDelegationValue: boolean) {
        this.getConfig().allow_mode_delegation = allowModeDelegationValue;
    }

    /**
     * Resets the mode for all rules of all CRS groups.
     */
    public resetModeOverrideForCrsRules(): void {
        this.crsOverrides.config.forEach((groupOverride: WafRuleGroupOverridesConfigItem) => {
            groupOverride.ruleOverrides.config
                .forEach((ruleOverride: WafRuleOverridesConfigItem) => {
                    ruleOverride.mode = undefined;
                });
        });
    }

    /**
     * Getter function for an enum to be used for the 'min_confidence' value.
     */
    public get minConfidence(): string {
        return this.getConfig().min_confidence;
    }

    /**
     * Setter function to set the 'min_confidence' enum value.
     */
    public set minConfidence(enumValue: string) {
        this.getConfig().min_confidence = enumValue;
    }

    /**
     * Sets the learning_params object to its default values in this.data.config.config. (Not a
     * typo)
     */
    public setDefaultAppLearningParams(): void {
        this.safeSetNewChildByField('learning_params');
    }

    /**
     * Returns learning data.
     */
    public fetchLearningData(vsId: string): IBaseRequestPromise<any> {
        const requestConfig = {
            method: 'GET',
            url: '/api/analytics/learning/virtualservice',
            params: {
                app_id: `${vsId}:${this.id}`,
                stats: true,
                confidence: 'certain',
            },
        };

        return this.request(requestConfig);
    }

    /**
     * Adds a new Pre-CRS WAF rule group.
     */
    public addPreCrsWafRuleGroup(modalBindings: Record<string, any>): void {
        this.addWafRuleGroup(PRE_CRS_GROUPS_FIELD, modalBindings);
    }

    /**
     * Adds a new Post-CRS WAF rule group.
     */
    public addPostCrsWafRuleGroup(modalBindings: Record<string, any>): void {
        this.addWafRuleGroup(POST_CRS_GROUPS_FIELD, modalBindings);
    }

    /**
     * Edits an existing rule group. Only applies to Pre-CRS and Post-CRS as CRS Groups are not
     * directly editable.
     */
    public editWafRuleGroup(
        wafRuleGroup: WafRuleGroupConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        const field = wafRuleGroup.getFieldName();

        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.editPreCrsGroupModalHeader :
            l10nKeys.editPostCrsGroupModalHeader;

        this.editChildMessageItem({
            field,
            messageItem: wafRuleGroup,
            modalBindings: {
                modalHeaderKey,
                ...modalBindings,
            },
        });
    }

    /**
     * Creates a map of CRS group override names to the group override.
     */
    public createCrsGroupOverridesMap(): Map<string, WafRuleGroupOverridesConfigItem> {
        const map = new Map();
        const { crs_overrides: overrides } = this.getConfig();

        overrides.config.forEach((override: WafRuleGroupOverridesConfigItem) => {
            map.set(override.getName(), override);
        });

        return map;
    }

    /**
     * Returns the CRS group overrides RepeatedMessageItem.
     */
    public get crsOverrides(): RepeatedMessageItem<WafRuleGroupOverridesConfigItem> {
        return this.config.crs_overrides;
    }

    /**
     * Returns the configured CRS group override by the original group's name.
     */
    public getCrsGroupOverride(groupName: string) : WafRuleGroupOverridesConfigItem | undefined {
        return this.crsGroupOverridesMap.get(groupName);
    }

    /**
     * Return the configured CRS rule override by the ruleId.
     */
    public getCrsRuleOverride(ruleId: string): WafRuleOverridesConfigItem | undefined {
        const groupName = this.wafCrs.getGroupName(ruleId);
        const groupOverride = this.getCrsGroupOverride(groupName);

        return groupOverride?.getRuleOverrideByID(ruleId);
    }

    /**
     * Creates a new WafRuleGroupOverridesConfigItem instance.
     */
    public createCrsGroupOverride(
        config: Record<string, any> = null,
    ): WafRuleGroupOverridesConfigItem {
        return this.createChildByField(
            'crs_overrides',
            config,
            true,
        ) as WafRuleGroupOverridesConfigItem;
    }

    /**
     * Create a clone of the waf-policy by deep cloning psm groups also.
     */
    public clone(wafPolicyName: string): WafPolicyItem {
        const WafPolicyPsmGroup = this.getAjsDependency_('WafPolicyPsmGroup');
        const config = this.flattenConfig();
        const configFieldsToBeDeleted = ['uuid', 'tenant_ref', 'url'];

        configFieldsToBeDeleted.forEach(fieldName => delete config[fieldName]);

        // eslint-disable-next-line no-extra-parens
        const clone = new (this.constructor as typeof WafPolicyItem)({
            data: {
                config,
            },
        });

        clone.getConfig().name = wafPolicyName;

        if (clone.getConfig().positive_security_model) {
            const { config: psmConfig } = clone.getConfig().positive_security_model.data;
            const { group_refs_data: psmGroups } = psmConfig;

            delete psmConfig.group_refs;

            psmGroups.forEach((item: WafPolicyPsmGroup, index: number) => {
                const psmFlattenedConfigData = item.flattenConfig();

                configFieldsToBeDeleted.forEach(
                    fieldName => delete psmFlattenedConfigData[fieldName],
                );

                psmFlattenedConfigData.name += ' (copy)';

                psmGroups[index] = new WafPolicyPsmGroup({
                    data: {
                        config: psmFlattenedConfigData,
                    },
                });
            });
        }

        return clone;
    }

    /**
     * Called to add a new group override.
     */
    public addCrsGroupOverride(
        group: IWafRuleGroup,
        modalBindings?: Record<string, any>,
    ): void {
        const { name, enable } = group;
        const newConfig = {
            name,
            enable,
        };

        this.editCrsGroupOverride(
            this.createCrsGroupOverride(newConfig),
            group,
            modalBindings,
        );
    }

    /**
     * Called to edit an existing group override.
     */
    public editCrsGroupOverride(
        groupOverride: WafRuleGroupOverridesConfigItem,
        originalGroup: IWafRuleGroup,
        modalBindings?: Record<string, any>,
    ): void {
        const { enable } = originalGroup;

        // If the override's enable flag is undefined, set it to the original rule's enable value.
        if (isUndefined(groupOverride.enable)) {
            groupOverride.enable = enable;
        }

        this.editChildMessageItem({
            field: 'crs_overrides',
            messageItem: groupOverride,
            modalBindings: {
                modalHeaderKey: l10nKeys.editCrsGroupModalHeader,
                crsRules: originalGroup.rules,
                ...modalBindings,
            },
        }).then((groupOverride: WafRuleGroupOverridesConfigItem) => {
            this.crsGroupOverridesMap.set(groupOverride.getName(), groupOverride);
        });
    }

    /**
     * Called to remove an existing group override.
     */
    public removeCrsGroupOverride(groupOverride: WafRuleGroupOverridesConfigItem): void {
        this.crsOverrides.removeByMessageItem(groupOverride);
        this.crsGroupOverridesMap.delete(groupOverride.getName());
    }

    /**
     * Called to add a new CRS rule override. If a group override doesn't already exist for the
     * group, it needs to be created first.
     */
    public addCrsRuleOverride(
        group: WafRuleGroupConfigItem,
        rule: WafRuleConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        const groupOverride = this.getCrsGroupOverride(group.getName());

        if (groupOverride) {
            groupOverride.addRuleOverride(rule, modalBindings);
        } else {
            const { name } = group.config;
            const newGroupOverride: WafRuleGroupOverridesConfigItem =
                this.createChildByField(
                    'crs_overrides',
                    { name },
                    true,
                ) as WafRuleGroupOverridesConfigItem;

            newGroupOverride.addRuleOverride(rule, modalBindings).then(() => {
                this.crsOverrides.add(newGroupOverride);
                this.crsGroupOverridesMap.set(newGroupOverride.getName(), newGroupOverride);
            });
        }
    }

    /**
     * Called to edit an allowlist rule.
     */
    public editAllowlistRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.config.allowlist.editRule(rule);
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-policy-modal.component'
        );

        return WafPolicyModalComponent as Type<Component>;
    }

    /**
     * Get the enable state of a Pre/Post CRS Group, Group Override, or CRS Group.
     */
    public getGroupEnabledState(groupName: string): boolean {
        const groupOverride = this.getCrsGroupOverride(groupName);

        if (groupOverride?.enable !== undefined) {
            return groupOverride.enable;
        } else if (this.wafCrs.hasGroup(groupName)) {
            const crsGroupConfig = this.wafCrs.getGroupConfig(groupName);

            return crsGroupConfig.enable;
        } else if (this.hasGroup(groupName)) {
            const group = this.getConfigItemById('group', groupName) as WafRuleGroupConfigItem;

            return group.isEnabled();
        } else {
            throw new Error(`Group ${groupName} does not exist in this WAF Policy.`);
        }
    }

    /**
     * Get the enable state of a Pre/Post CRS Rule, Rule Override, or CRS Rule.
     */
    public getRuleEnabledState(ruleId: string): boolean {
        const ruleOverride = this.getCrsRuleOverride(ruleId);

        if (ruleOverride?.enable !== undefined) {
            return ruleOverride.enable;
        } else if (this.wafCrs.hasRule(ruleId)) {
            const crsRuleConfig = this.wafCrs.getRuleConfig(ruleId);

            return crsRuleConfig.enable;
        } else if (this.hasRule(ruleId)) {
            const rule = this.getConfigItemById('rule', ruleId) as WafRuleConfigItem;

            return rule.isEnabled();
        } else {
            throw new Error(`Rule ${ruleId} does not exist in this WAF Policy.`);
        }
    }

    /**
     * Set the enable flag of a Pre/Post CRS Group or Group Override.
     */
    public setGroupEnabledState(groupName: string, enable = false): void {
        try {
            const groupOrGroupOverride = this.wafCrs.hasGroup(groupName) ?
                this.getOrCreateCrsGroupOverride(groupName) :
                this.getNonCrsGroupByGroupName(groupName);

            groupOrGroupOverride.enable = enable;
        } catch (error) {
            // Group not found.
            throw new Error(`Group ${groupName} does not exist in this WAF Policy.`);
        }
    }

    /**
     * Set the enable flag of a Pre/Post CRS Rule or Rule Override.
     */
    public setRuleEnabledState(ruleId: string, enable = false): void {
        try {
            const ruleOrRuleOverride = this.wafCrs.hasRule(ruleId) ?
                this.getOrCreateCrsRuleOverride(ruleId) :
                this.getNonCrsRuleByRuleId(ruleId);

            ruleOrRuleOverride.enable = enable;
        } catch (error) {
            // Rule not found.
            throw new Error(`Rule ${ruleId} does not exist in this WAF Policy.`);
        }
    }

    /**
     * Get the WAF Crs Item.
     */
    public get wafCrs(): WafCrs {
        return this.config.waf_crs_ref_data;
    }

    /**
     * Check if a group name exists in CRS, Pre CRS, or Post CRS Groups.
     */
    public hasGroup(groupName: string): boolean {
        return this.groupsMap.has(groupName) || this.wafCrs.hasGroup(groupName);
    }

    /**
     * Check if a rule ID exists in CRS, Pre CRS, or Post CRS Rules.
     */
    public hasRule(ruleId: string): boolean {
        return this.rulesMap.has(ruleId) || this.wafCrs.hasRule(ruleId);
    }

    /**
     * Return the exclude list RepeatedMessageItem. If the exclude list is for a CRS group or rule,
     * and an override has not yet been created for that group or rule, then one will be created
     * first before returning the override's exclude list RepeatedMessageItem.
     */
    public getExcludeList(
        groupName: string,
        ruleId: string,
    ): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        if (ruleId) {
            if (this.wafCrs.hasRule(ruleId)) {
                // Rule is CRS rule.
                const ruleOverride = this.getOrCreateCrsRuleOverride(ruleId);

                return ruleOverride.excludeList;
            } else {
                // Rule is Pre/Post CRS rule.
                const wafRule = this.getNonCrsRuleByRuleId(ruleId);

                return wafRule.excludeList;
            }
        } else if (this.wafCrs.hasGroup(groupName)) {
            // Group is CRS group.
            const groupOverride = this.getOrCreateCrsGroupOverride(groupName);

            return groupOverride.excludeList;
        } else {
            // Group is Pre/Post CRS group.
            const wafGroup = this.getNonCrsGroupByGroupName(groupName);

            return wafGroup.excludeList;
        }
    }

    /**
     * Get learn_from_bots properties.
     */
    public get learnFromBots(): MessageItem<IBotDetectionMatchConfig> {
        return this.getConfig().learning_params.config.learn_from_bots;
    }

    /**
     * Return Classifications.
     */
    public get classifications(): RepeatedMessageItem<MessageItem<IBotClassification>> {
        return this.learnFromBots.config.classifications;
    }

    /**
     * Add a classification.
     */
    public addClassification(): void {
        this.classifications.add();
    }

    /**
     * Delete a classfication.
     */
    public removeClassification(classification: MessageItem<IBotClassification>): void {
        this.classifications.removeByMessageItem(classification);
    }

    /**
     * Set user_defined_type to undefined when the type of bot isn't User-defined bot.
     */
    public onClassificationTypeChange(classification: MessageItem<IBotClassification>): void {
        if (classification.config.type !== USER_DEFINED_BOT) {
            delete classification.config.user_defined_type;
        }
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'application_signatures',
            'learning_params',
        ];
    }

    /**
     * @override
     */
    protected createSaveRequestPayload_(dataToSave: IWafPolicyConfig)
        : IWafPolicySaveRequestPayload {
        return {
            model_name: 'wafpolicy',
            data: dataToSave,
        };
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WafProfile.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.wafPolicyLabel);
    }

    /**
     * Creates a new WafCrs instance.
     */
    private createWafCrs(config: IWafCRS): WafCrs {
        const WafCrsCollection = this.getAjsDependency_(WAF_CRS_COLLECTION_TOKEN);
        const wafCrsCollection = new WafCrsCollection();
        const wafCrsItem = wafCrsCollection.createNewItem({
            data: {
                config,
            },
        }, true);

        wafCrsCollection.destroy();

        return wafCrsItem;
    }

    /**
     * Return maps of groupName to WafRuleGroupConfigItem and ruleId to WafRuleConfigItem for Pre
     * and Post CRS groups and rules.
     */
    private getNonCrsMaps(): [
        Map<string, WafRuleGroupConfigItem>,
        Map<string, WafRuleConfigItem>,
    ] {
        const groupsMap = new Map<string, WafRuleGroupConfigItem>();
        const rulesMap = new Map<string, WafRuleConfigItem>();
        const config = this.getConfig();

        [PRE_CRS_GROUPS_FIELD, POST_CRS_GROUPS_FIELD].forEach(field => {
            const groupRepeatedMessageItem = config[field];

            groupRepeatedMessageItem.config.forEach((group: WafRuleGroupConfigItem) => {
                groupsMap.set(group.getName(), group);

                group.config.rules.config.forEach((rule: WafRuleConfigItem) => {
                    rulesMap.set(rule.getId(), rule);
                });
            });
        });

        return [groupsMap, rulesMap];
    }

    /**
     * Adds a new rule group. Only applies to Pre-CRS and Post-CRS as CRS Groups are not allowed to
     * be added.
     */
    private addWafRuleGroup(field: string, modalBindings: Record<string, any>): void {
        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.createPreCrsGroupModalHeader :
            l10nKeys.createPostCrsGroupModalHeader;

        this.addChildMessageItem({
            field,
            modalBindings: {
                modalHeaderKey,
                ...modalBindings,
            },
        });
    }

    /**
     * Return a CRS Group Override object for a given CRS group name. If it doesn't already exist,
     * create a new one and return that.
     */
    private getOrCreateCrsGroupOverride(groupName: string): WafRuleGroupOverridesConfigItem {
        let groupOverride = this.getCrsGroupOverride(groupName);

        if (!groupOverride) {
            groupOverride = this.createCrsGroupOverride({ name: groupName });
            this.config.crs_overrides.add(groupOverride);
            this.crsGroupOverridesMap.set(groupOverride.getName(), groupOverride);
        }

        return groupOverride;
    }

    /**
     * Return a CRS Rule Override object for a given CRS rule ID. If it doesn't already exist,
     * create a new one and return that.
     */
    private getOrCreateCrsRuleOverride(ruleId: string): WafRuleOverridesConfigItem {
        let ruleOverride = this.getCrsRuleOverride(ruleId);

        if (!ruleOverride) {
            const groupName = this.wafCrs.getGroupName(ruleId);
            const groupOverride = this.getOrCreateCrsGroupOverride(groupName);

            ruleOverride = groupOverride.createRuleOverride({ rule_id: ruleId });
            groupOverride.ruleOverrides.add(ruleOverride);
            groupOverride.addRuleOverridesMap(ruleOverride.getId(), ruleOverride);
        }

        return ruleOverride;
    }

    /**
     * Set WafCrsItem if the data is a plain object, else transforms the Item's data.
     */
    private setWafCrsData(): void {
        const config = this.getConfig();
        const { waf_crs_ref_data: wafCrsRefData } = config;

        if (wafCrsRefData) {
            if (!(wafCrsRefData instanceof WafCrs)) {
                config.waf_crs_ref_data = this.createWafCrs(wafCrsRefData);
            } else {
                // This is called because wafCrsRefData.transformAfterLoad recreates the maps
                // used in wafCrsRefData. When creating a new WafPolicy, the existing WafCrs
                // instance, including its es6 Maps, gets angular.copy'ed, and es6 Maps break
                // when that happens due to issues with 'this' context.
                wafCrsRefData.transformAfterLoad();
            }
        }
    }
}
