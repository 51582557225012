<div provideParentForm>
    <avi-radio-container [noMarginTop]="true">
        <avi-radio
            name="{{ 'address-type-' + fieldName + '-' + index }}"
            value="{{ AddressType.IP_GROUP }}"
            [(ngModel)]="addressType"
            (ngModelChange)="handleAddressTypeChange()"
        >
            {{ l10nKeys.selectFromAvailableLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            name="{{ 'address-type-' + fieldName + '-' + index }}"
            value="{{ AddressType.CUSTOM_VALUE }}"
            [(ngModel)]="addressType"
            (ngModelChange)="handleAddressTypeChange()"
        >
            {{ l10nKeys.enterCustomValueLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>
    <div *ngIf="addressType === AddressType.IP_GROUP">
        <avi-collection-dropdown
            [disableCreate]="true"
            [disableEdit]="true"
            [collection]="ipAddrGroupCollection"
            [(ngModel)]="address"
            (ngModelChange)="handleAddressChange()"
            placeholder="{{ l10nKeys.selectIpGroupPlaceholder | vtranslate }}"
            name="{{ 'ip-group-uuid-' + fieldName + '-'+ index }}"
            required
        ></avi-collection-dropdown>
    </div>
    <div *ngIf="addressType === AddressType.CUSTOM_VALUE">
        <avi-input-container noMarginTop>
            <input
                clrInput
                placeholder="{{ l10nKeys.enterCustomValueLabel | vtranslate }}"
                name="{{ 'address-' +  fieldName + '-' + index }}"
                [(ngModel)]="address"
                (ngModelChange)="handleAddressChange()"
                regexPattern="anyIPIPSubnetIPv4Range"
                required
            />
        </avi-input-container>
    </div>
</div>
