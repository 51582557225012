<div class="ssl-certificate-imported-information">
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>
                {{ l10nKeys.importedInformationHeader | vtranslate }}
            </h6>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="SSLCertificateDescription"
            fieldName="common_name"
            for="common_name"
        >
            {{ l10nKeys.commonNameInputLabel | vtranslate }}
        </label>
        <input
            id="common_name"
            name="common_name"
            [ngModel]="certificate.config.subject.config.common_name"
            clrInput
            readonly
            placeholder="-"
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="SSLCertificateDescription"
            fieldName="email_address"
            for="email_address"
        >
            {{ l10nKeys.emailInputLabel | vtranslate }}
        </label>
        <input
            id="email_address"
            name="email_address"
            [ngModel]="certificate.config.subject.config.email_address"
            readonly
            clrInput
            placeholder="-"
        />
    </avi-input-container>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="SSLCertificateDescription"
                    fieldName="organization"
                    for="organization"
                >
                    {{ l10nKeys.organizationInputLabel | vtranslate }}
                </label>
                <input
                    id="organization"
                    name="organization"
                    [ngModel]="certificate.config.subject.config.organization"
                    readonly
                    clrInput
                    placeholder="-"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="SSLCertificateDescription"
                    fieldName="organization_unit"
                    for="organization_unit"
                >
                    {{ l10nKeys.organizationUnitInputLabel | vtranslate }}
                </label>
                <input
                    id="organization_unit"
                    name="organization_unit"
                    [ngModel]="certificate.config.subject.config.organization_unit"
                    readonly
                    clrInput
                    placeholder="-"
                />
            </avi-input-container>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="SSLKeyParams"
                fieldName="algorithm"
                for="algorithm"
            >
                {{ l10nKeys.algorithmInputLabel | vtranslate }}
            </label>
            <avi-dropdown
                class="sel-select-algorithm"
                name="algorithm"
                [ngModel]="certificate.config.key_params.config.algorithm"
                [options]="sslKeyAlgorithmOptions"
                placeholder="-"
                [readonly]="true"
            ></avi-dropdown>
        </div>
        <div 
            class="full-modal-config__form-control"
            *ngIf="certificate.config.key_params.algorithm === sslKeyAlgorithmTypeHash.SSL_KEY_ALGORITHM_RSA"
        >
            <label
                aviLabelWithTooltip
                objectType="SSLKeyRSAParams"
                fieldName="key_size"
                for="key_size"
            >
                {{ l10nKeys.keySizeInputLabel | vtranslate }}
            </label>
            <avi-dropdown
                class="sel-select-keySize"
                name="key_size"
                [ngModel]="certificate.config.key_params.config.rsa_params.config.key_size"
                [options]="sslRsaKeySizeOptions"
                placeholder="-"
                [readonly]="true"
            ></avi-dropdown>
        </div>
        <div 
            class="full-modal-config__form-control"
            *ngIf="certificate.config.key_params.config.ec_params && 
                certificate.config.key_params.algorithm === sslKeyAlgorithmTypeHash.SSL_KEY_ALGORITHM_EC"
        >
            <label
                aviLabelWithTooltip
                objectType="SSLKeyECParams"
                fieldName="curve"
                for="curve"
            >
                {{ l10nKeys.keySizeInputLabel | vtranslate }}
            </label>
            <avi-dropdown
                class="sel-select-curve"
                name="curve"
                [ngModel]="certificate.config.key_params.config.ec_params.config.curve"
                [options]="sslKeyECCurveOptions"
                placeholder="-"
                [readonly]="true"
            ></avi-dropdown>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="SSLCertificate"
            fieldName="not_after"
            for="not_after"
        >
            {{ l10nKeys.validUntilInputLabel | vtranslate }}
        </label>
        <input
            id="not_after"
            name="not_after"
            [ngModel]="certificate.config.not_after"
            readonly
            clrInput
            placeholder="-"
        />
    </avi-input-container>
</div>
