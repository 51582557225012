/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/* For toggling details (drawers) in tables. Mostly for logs, events, alerts.
* Can work with disabled details views for certain rows. @am */
angular.module('aviApp').factory('detailsExpander', function() {
    return function() {
        let openedQ = 0,
            disabledQ = 0;

        this.items = [];
        this.allOpened = false;
        this.isEmpty = false;//true when we have no expandable items

        this.setLength = function(len, disLen) {
            openedQ = 0;
            disabledQ = disLen || 0;//no expander for disLen elements of len
            this.allOpened = len === disabledQ;
            this.isEmpty = this.allOpened;
            this.items.length = 0;

            for (let i = 0; i < len; i++) {
                this.items[i] = false;
            }
        };

        this.toggle = function(id) {
            if (this.items.length === disabledQ) { return; }

            if (typeof id === 'undefined') {
                this.allOpened = !this.allOpened;

                for (let i = 0; i < this.items.length; i++) {
                    this.items[i] = this.allOpened;
                }

                openedQ = (this.items.length - disabledQ) * this.allOpened;
            } else {
                openedQ += 1 - 2 * this.items[id];
                this.items[id] = !this.items[id];
                this.allOpened = openedQ + disabledQ === this.items.length;
            }
        };
    };
});
