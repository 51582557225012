/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { Injector } from '@angular/core';

import {
    ApplicationProfileCollection,
} from 'ajs/modules/application-profile/factories/application-profile.collection.factory';

import {
    APPLICATION_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/application-profile/application-profile.tokens';

const $injector = '$injector';

export const applicationProfileCollectionProvider = {
    deps: [$injector],
    provide: ApplicationProfileCollection,
    useFactory(injector: Injector): typeof ApplicationProfileCollection {
        return injector.get(APPLICATION_PROFILE_COLLECTION_TOKEN);
    },
};
