/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** Timing Item -- place to store filtered/base end to end, navigation, and resource timing
  * This is used as a property on topListItems
  * Has a ResourceTiming subclass
    * Has values array, loadTime, url, initiator type, and is kept
    * in a resourceTiming object that has other properties
  * endToEnd.base, and endToEnd.filtered take care of timing
  *     both have endToEnd and pageDownloadTime
*/
angular.module('aviApp').factory('TimingItem', ['$timeout', 'Base', function($timeout, Base) {
    // -------------------Constants ------------------------//
    // Titles
    const timingTitles = {
        startTime: 'Start Time',
        blocking_time: 'Blocking',
        redirection_time: 'Redirecting',
        dns_lookup_time: 'DNS',
        connection_time: 'Connecting',
        waiting_time: 'Waiting',
        page_download_time: 'Receiving',
        url: 'URL',
        page_load_time: 'Page Load Time',
        dom_content_load_time: 'DOM Load Time',
        page_downloading_time: 'Page Downloading Time',
    };

    const orderingFunction = function(a, b) {
        const correctOrder = [
            'blocking_time',
            'redirection_time',
            'dns_lookup_time',
            'connection_time',
            'waiting_time',
            'page_download_time',
        ];

        return _.indexOf(correctOrder, a.metric) - _.indexOf(correctOrder, b.metric);
    };

    // Sets of data
    const pageDownloadMetrics = {
        blocking_time: 1,
        redirection_time: 1,
        dns_lookup_time: 1,
        connection_time: 1,
        waiting_time: 1,
        page_download_time: 1,
    };
    const endToEndMetrics = {
        page_load_time: 1,
        dom_content_load_time: 1,
    };

    // -------------- Helpers -----------------//
    function makeTimingValues(args) {
        return _.map(args, function(d, k) {
            return {
                title: timingTitles[k] || k,
                value: d,
                timing: k,
                metric: k,
                units: 'ms',
            };
        }).sort(orderingFunction);
    }

    // ------------ Resource Timing Item class ----------------//
    function ResourceTimingItem(args) {
        this.initiatorType = args.initiatorType;
        this.url = args.url;
        this.title = args.url;
        this.duration = args.duration;
        this.entryType = args.entryType;
        this.startTime = args.startTime;

        // Similar, but not the same as the above pageDownloadMetrics
        const timingValueTypes = {
            blocking_time: 1,
            connection_time: 1,
            dns_lookup_time: 1,
            page_download_time: 1,
            waiting_time: 1,
        };

        this.values = [];

        // For each of the things in args, if it is one of the resource timing types
        // put it in values.
        const self = this;

        _.each(args, function(v, k) {
            if (timingValueTypes[k]) {
                self.values.push({
                    title: timingTitles[k],
                    value: v || 0,
                    metric: k,
                    units: 'ms',
                });
            }
        });

        self.values.sort(orderingFunction);

        // If there aren't any suitable values, make a total, and stick it in there
        if (!_.some(self.values, function(v) { return v.value; }) || !self.values.length) {
            self.values.push({
                title: 'Total',
                value: self.duration || 0,
                metric: 'total',
                units: 'ms',
            });
        }

        this.loadTime = _.reduce(this.values, function(memo, r) {
            return memo + (r.value || 0);
        }, 0) || 0;
    }

    // ------------ Resource Timing Items Class ---------------//
    function makeResourceTiming(timing) {
        const result = {};

        result.values = _.map(timing, function(obj) {
            return new ResourceTimingItem(obj);
        });

        // Sortint by start time
        result.values.sort(function(a, b) { return a.startTime - b.startTime; });

        return result;
    }

    // --------------------------Timing Item Class ------------------------------//
    class TimingItem extends Base {
        constructor(url) {
            super();

            this.url = url;
            this.filtered = {};
            this.base = {};
            this.currentData = this.base;
        }
    }

    TimingItem.prototype.filterBy = function(array, obj) {
        return _.filter(array, function(v) {
            return obj[v.timing];
        });
    };

    // Does this non-destructively
    TimingItem.prototype.updateEndToEndWithPltAndDlt = function(curr) {
        const plt = curr.endToEnd;
        const dlt = curr.pageDownloadTime;

        const findBar = function(array, metric) {
            return _.find(array, function(bar) {
                return bar.metric === metric;
            });
        };

        let array;

        if (curr.endToEndWithPltAndDlt) {
            array = curr.endToEndWithPltAndDlt;
            _.each(plt, function(pltBar) {
                const b = findBar(curr.endToEndWithPltAndDlt, pltBar.metric);

                if (b) { _.extend(b, pltBar); } else { curr.endToEndWithPltAndDlt.push(pltBar); }
            });
        } else {
            array = angular.copy(plt);
            array.unshift({
                metric: 'page_download_time_total',
                timing: 'page_download_time_total',
                title: 'Page Download Time',
                units: 'ms',
            });
        }

        const bar = findBar(array, 'page_download_time_total');

        bar.value = _.reduce(dlt, function(memo, d) { return memo + (d.value || 0); }, 0);

        let prev = 0;

        array.sort(function(a, b) {
            return a.value - b.value;
        });
        _.each(array, function(bar) {
            bar.diff = bar.value - prev;
            prev = bar.value || prev;
        });

        curr.endToEndWithPltAndDlt = array;
    };

    TimingItem.prototype.getEndToEnd = function(array) {
        const result = this.filterBy(array, endToEndMetrics).sort(function(a, b) {
            return b.value - a.value;
        });

        return result;
    };

    TimingItem.prototype.getPageDownloadTime = function(array) {
        return this.filterBy(array, pageDownloadMetrics);
    };

    // Showing data
    function show(type) {
        this.currentData = this[type];
        this.trigger('timing-change');
    }

    TimingItem.prototype.showBaseData = _.partial(show, 'base');
    TimingItem.prototype.showFilteredData = _.partial(show, 'filtered');

    // Setting Data (is on the prototype)
    function setEndToEnd(baseOrFiltered, timing) {
        const curr = this[baseOrFiltered];

        // TODO -- add reset function
        if (!timing) {
            curr.values = [];
            curr.header = {};
            curr.endToEnd = [];
            curr.pageDownloadTime = [];
            curr.endToEndTotal = null;
            curr.pageDownloadTimeTotal = null;

            return;
        }

        if (!timing.data || timing.data.length !== 1) {
            console.warn('Data not of expected format.');
        }

        curr.values = makeTimingValues(timing.data[0]);
        curr.header = timing.header;
        curr.endToEnd = this.getEndToEnd(curr.values);

        // adding up some totals
        const addUp = function(memo, bar) {
            return (bar.value || bar.y || 0) + memo;
        };

        curr.pageDownloadTime = this.getPageDownloadTime(curr.values);

        if (curr.pageDownloadTime) {
            curr.pageDownloadTimeTotal = _.reduce(curr.pageDownloadTime, addUp, 0);
        }

        if (curr.endToEnd && curr.endToEnd.length) {
            const plt = _.find(curr.endToEnd, function(t) {
                return t.timing === 'page_load_time';
            });

            curr.endToEndTotal = plt && plt.value;
        }

        if (curr.endToEnd && curr.pageDownloadTime) {
            this.updateEndToEndWithPltAndDlt(curr);
        }

        this.trigger('timing-change');
    }

    TimingItem.prototype.setBaseEndToEnd = _.partial(setEndToEnd, 'base');
    TimingItem.prototype.setFilteredEndToEnd = _.partial(setEndToEnd, 'filtered');

    TimingItem.prototype.clearFiltered = function() {
        const currentlyPointingAtFiltered = this.currentData === this.filtered;

        this.filtered = {};

        if (currentlyPointingAtFiltered) { this.currentData = this.filtered; }
    };

    TimingItem.prototype.noFilteredData = function() {
        const self = this;

        $timeout(function() {
            self.filtered.noFilteredData = true;
        });
    };

    TimingItem.prototype.setBaseResourceTiming = _.partial(setResourceTiming, 'base');
    TimingItem.prototype.setFilteredResourceTiming = _.partial(setResourceTiming, 'filtered');

    function setResourceTiming(baseOrFiltered, series) {
        this[baseOrFiltered] = this[baseOrFiltered] || {};

        const curr = this[baseOrFiltered];

        curr.resourceTiming = makeResourceTiming(series);
        curr.resourceTiming.waterfallValues = curr.resourceTiming.values.slice();
        curr.resourceTiming.waterfallValues.unshift({
            values: this.base.pageDownloadTime,
            startTime: 0,
            url: this.url,
            loadTime: _.reduce(this.base.pageDownloadTime,
                function(memo, v) {
                    return memo + (v.value || 0);
                },
                0),
        });

        curr.resourceTiming.domLoadTime = _.find(this.base.endToEnd,
            function(v) {
                return v.metric === 'dom_content_load_time';
            });
        curr.resourceTiming.pageLoadTime = _.find(this.base.endToEnd,
            function(v) {
                return v.metric === 'page_load_time';
            });
    }

    return TimingItem;
}]);
