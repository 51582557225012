<div
    [ngClass]="topLevelClassNames"
    [hidden]="hideInput"
>
    <ng-content></ng-content>
    <div class="clr-control-container">
        <div class="clr-input-wrapper">
            <ng-content select="input"></ng-content>
        </div>
        <div
            *ngIf="helperText"
            [hidden]="invalid"
            class="clr-subtext"
        >
            {{ helperText }}
        </div>
        <avi-input-container-error
            class="avi-input-container__error-text clr-subtext"
            customError="{{ errorText }}"
            [validationErrors]="validationErrors"
            [hidden]="!invalid"
        ></avi-input-container-error>
        <div
            *ngIf="successText && !invalid "
            class="avi-input-container__success-text clr-subtext"
        >
            {{ successText }}
        </div>
    </div>
</div>
