/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorUdp,
} from 'object-types';

import { IHealthMonitorUdp } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './udp-health-monitor-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Config Component for UDP Health Monitor Type.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'udp-health-monitor-config',
    templateUrl: './udp-health-monitor-config.component.html',
})
export class UdpHealthMonitorConfigComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * True if the UDP Health Monitor Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorUdp,
    };

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.isEditing = Boolean(itemId);
    }

    /**
     * Getter for UDP Monitor message item.
     */
    public get udpMonitor(): MessageItem<IHealthMonitorUdp> {
        const { udpMonitor } = this.editable;

        return udpMonitor;
    }
}
