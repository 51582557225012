/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

import { Injector } from '@angular/core';

import {
    CaseCollection,
    CASE_COLLECTION_TOKEN,
} from 'ajs/modules/cportal/factories/case.collection.factory';

const $injector = '$injector';

export const caseListProvider = {
    deps: [$injector],
    provide: CaseCollection,
    useFactory(injector: Injector): typeof CaseCollection {
        return injector.get(CASE_COLLECTION_TOKEN);
    },
};
