/** @module L4PolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import {
    L4RuleProtocolMatchConfigItem,
} from 'ajs/modules/l4-policy/factories/matches/l4-rule-protocol-match.config-item.factory';
import * as l10n from './l4-rule-protocol-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for L4RuleProtocolMatch.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'l4-rule-protocol-match-readonly',
    templateUrl: './l4-rule-protocol-match-readonly.component.html',
})
export class L4RuleProtocolMatchReadonlyComponent {
    @Input()
    public editable: L4RuleProtocolMatchConfigItem;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get text label for selected match criteria.
     */
    public get matchCriteria(): string {
        return this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.match_criteria,
        );
    }
}
