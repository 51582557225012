/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'jwt-server-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const issuerInputLabel = `${prefix}.issuerInputLabel`;
export const issuerInputPlaceholder = `${prefix}.issuerInputPlaceholder`;
export const jwtFileInputLabel = `${prefix}.jwtFileInputLabel`;
export const jwtFileInputPlaceholder = `${prefix}.jwtFileInputPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit JWT Server Profile',
    [sectionTitleGeneral]: 'General',
    [modalTitleNew]: 'New JWT Server Profile',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [issuerInputLabel]: 'Issuer',
    [issuerInputPlaceholder]: 'Enter Issuer',
    [jwtFileInputLabel]: 'Upload or Paste JWT File',
    [jwtFileInputPlaceholder]: 'Paste JWT contents',
};
