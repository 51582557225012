/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'role-permission-setter';
const prefix = `${moduleName}.${componentName}`;

export const assortedLabel = `${prefix}.assortedLabel`;
export const writeLabel = `${prefix}.writeLabel`;
export const readLabel = `${prefix}.readLabel`;
export const noAccessLabel = `${prefix}.noAccessLabel`;
export const permissionHintLabel = `${prefix}.permissionHintLabel`;

export const ENGLISH = {
    [assortedLabel]: 'Assorted',
    [writeLabel]: 'Write',
    [readLabel]: 'Read',
    [noAccessLabel]: 'No Access',
    [permissionHintLabel]: '{0} is required',
};
