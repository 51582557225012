<div
    class="avi-checkbox"
    [ngClass]="{ 'avi-checkbox--no-margin-top': noMarginTop }"
>
    <clr-checkbox-container class="avi-checkbox__clr-checkbox-container">
        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                clrCheckbox
                [name]="name"
                [(ngModel)]="value"
                (ngModelChange)="handleModelChange($event)"
                [attr.id]="name"
                [disabled]="disabled"
                [class]="checkboxClassName"
            />
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                [fieldName]="fieldName"
                [attr.for]="name"
                [tooltipText]="tooltipText"
                [noBold]="true"
                [class]="labelClassName"
            >
                <ng-content></ng-content>
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</div>
