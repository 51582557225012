/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-score';
const componentName = 'avi-health-score-error';
const prefix = `${moduleName}.${componentName}`;

export const defaultErrorMessage = `${prefix}.defaultErrorMessage`;

export const ENGLISH = {
    [defaultErrorMessage]: 'A problem occurred while loading the health score, please retry.',
};
