<div class="reset-password">
    <form #recoveryForm="ngForm">
        <div class="login-group">
            <div class="hint reset-password__hint">
                <h5 class="reset-password__hint-header">
                    {{ l10nKeys.forgotPassword | vtranslate }}
                </h5>
                <h6>{{ l10nKeys.enterEmailAddress | vtranslate }}</h6>
            </div>
            <avi-input-container>
                <input
                    type="email"
                    name="recoveryEmail"
                    class="sel-email"
                    clrInput
                    placeholder="john@doe.com"
                    [(ngModel)]="recoveryEmail"
                    regexPattern="email"
                    required
                />
            </avi-input-container>
            <span class="reset-password__goto-login">
                <a
                    href=""
                    (click)="goToSignIn()"
                >
                    {{ l10nKeys.signIn | vtranslate }}
                </a>
            </span>
            <div
                class="error"
                [ngClass]="{ active: errorMessage }"
            >
                {{ errorMessage }}
            </div>
            <div
                button-loader
                [isLoading]="mailingInProgress"
            >
                <button
                    type="submit"
                    class="btn btn-primary sel-skip-infra-button"
                    (click)="sendRecoveryMail()"
                    [disabled]="recoveryForm.invalid"
                >
                    {{ l10nKeys.sendInstructions | vtranslate }}
                </button>
            </div>
        </div>
    </form>
</div>
