<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    modalSubTitle="{{ name }}"
    (onCancel)="dismissModal()"
    (onSubmit)="dismissModal()"
    submitButtonText="{{ l10nKeys.submitButtonLabel | vtranslate }}"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <h6>
                {{ l10nKeys.clientIpAddressesHeader | vtranslate }}
            </h6>
            <avi-data-grid
                [config]="clientInfoCertificateGridConfig"
                [rows]="clientInfo.config"
            ></avi-data-grid>
        </div>
    </form> 
</full-modal-config>
