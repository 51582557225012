/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import { LIST_COLL_DATA_SOURCE_WITH_NAME_CONTAINS_SEARCH_PARAM }
    from 'ajs/modules/data-model/data-model.tokens';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { USER_ITEM_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

const allDataSources = {
    list: {
        source: LIST_COLL_DATA_SOURCE_WITH_NAME_CONTAINS_SEARCH_PARAM,
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
    'keystone-auth': {
        source: 'KeystoneIsEnabledCollDataSource',
        transformer: 'RevisedDataTransformer',
        transport: 'KeystoneIsEnabledDataTransport',
        fields: ['keystone'],
        dependsOn: 'config',
    },
};

/**
 * @description User collection.
 * @author Nitesh Kesarkar
 */
export class UserCollection extends Collection {
    public static ajsDependencies = [
        USER_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'user',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_USER,
            allDataSources,
            defaultDataSources: ['list', 'keystone-auth'],
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(USER_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            require_password_confirmation: false,
            is_active: true,
        };
    }
}

Object.assign(UserCollection.prototype, {
    isStatic_: false,
});

export type TUserCollection = typeof UserCollection;
