<async-file-upload
    [maxFileSize]="maxFileSize"
    [disabled]="disabled || pendingUpload"
    [(ngModel)]="file"
    (ngModelChange)="handleFileSelectChange($event)"
    (onFileUpload)="handleFileUpload()"
    (fileNameChange)="handleFileNameChange($event)"
    [fileName]="fileName"
    [showProgress]="showProgress"
    progressDisplayValue="{{ progressDisplayValue }}"
    [uploadPercentage]="uploadPercentage"
></async-file-upload>
