/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'policy-grid-expander';
const prefix = `${moduleName}.${componentName}`;

export const matchInformationHeader = `${prefix}.matchInformationHeader`;
export const anyLabel = `${prefix}.anyLabel`;
export const actionInformationHeader = `${prefix}.actionInformationHeader`;

export const ENGLISH = {
    [matchInformationHeader]: 'Match Information',
    [anyLabel]: 'Any',
    [actionInformationHeader]: 'Action Information',
};
