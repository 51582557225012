/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MessageItemsModule */

import {
    Component,
    Type,
} from '@angular/core';

import { vNIC } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { IvNIC } from 'generated-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    IVlanInterfaceData,
} from 'ng/modules/items/factories/service-engine.item.factory';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { VNICNetworksConfigItem } from './vnic-networks.config-item.factory';

import * as l10n from './vnic.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IVinicPrefix {
    list: string,
    prefixes: string[],
}

/**
 * List of all the modes required to create vincHash.
 */
const ipModes = [
    'STATIC_IPv4',
    'STATIC_IPv6',
    'DHCP',
    'VIP',
    'DOCKER_HOST',
] as const;

const UNKNOWN = 'Unknown';

type TVnicHash = { [K in typeof ipModes[number]]?: IVinicPrefix };
interface IVnicInfo extends IvNIC {
    state?: string;
    name?: string;
    vnicHash?: TVnicHash;
    mgmtType?: boolean;
}

type TvNICPartial = Omit<IVnicInfo, 'vnic_networks' | 'vlan_interfaces'>;

export interface IVNICNetworksPartialConfig extends TvNICPartial {
    vnic_networks: RepeatedMessageItem<VNICNetworksConfigItem>;
    vlan_interfaces: RepeatedMessageItem<MessageItem<IVlanInterfaceData>>;
    mgmtType?: boolean;
}

/**
 * @description
 *  VNIC config item.
 * @author Vinay Jadhav
 */
export class VNICConfigItem extends withFullModalMixin(MessageItem)<IVNICNetworksPartialConfig> {
    public static ngDependencies = [
        'l10nService',
    ];
    public mgmtType: boolean;
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: vNIC,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    public getInterfaceName(): string {
        return this.config.adapter === UNKNOWN ? this.config.if_name : this.config.adapter;
    }

    public getNetworkName(): string {
        return this.config.network_name || this.config.linux_name;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(
            this.config.is_mgmt ?
                l10nKeys.managementNetworksModalBreadcrumbTitle :
                l10nKeys.dataNetworksModalBreadcrumbTitle,
        );
    }

    /** @override */
    public getModalBreadcrumbDescription(): string {
        return this.getInterfaceName();
    }

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { EditDataNetworkComponent } = await import(
            /* webpackChunkName: "service-engine-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/service-engine-modal/data-network-grid/edit-data-network/edit-data-network.component'
        );

        return EditDataNetworkComponent as Type<Component>;
    }
}
