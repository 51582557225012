<div
    avi-tooltip
    [hideOnMouseLeave]="false"
    [positionsPriority]="signpostPositionPriorities"
    [tooltipControl$]="signpostCloseControl$"
    (mouseenter)="fetchHeathScoreData()"
    (mouseleave)="handleHealthScoreMouseLeave($event)"
    *ngIf="operationStatus; else loader"
    class="sel-avi-healthscore avi-health-score__outer {{ healthScoreClass }}"
>
    <span
        *ngIf="isHealthScoreValueAvailable" 
        class="avi-health-score__health-score-count"
    >
        {{ editable.data.health_score.health_score || 0 }}
    </span>

    <span
        *ngIf="showDeactivatedIcon"
        class="avi-health-score__icon--deactivated"
    ></span>

    <cds-icon
        *ngIf="showExclamation"
        shape="exclamation-circle"
        size="34"
        class="avi-health-score__icon--exclamation"
        status="danger"
        solid="false"
    ></cds-icon>

    <ng-template #aviTooltipContent>
        <signpost
            class="avi-health-score__signpost"
            [signpostPosition]="signpostPosition"
            (onCloseButtonClick)="closeSignpost()"
            (mouseleave)="closeSignpost()"
        >
            <!-- Health Score Title -->
            <h3 class="avi-health-score__title">{{ title }}</h3>

            <!-- Health Score Body -->
            <div class="avi-health-score__signpost--body">

                <!-- Health Score Loader -->
                <div class="avi-health-score__spinner" *ngIf="currentViewToRender === healthScoreView.LOADING">
                    <ng-container *ngTemplateOutlet="loader"></ng-container>
                </div>

                <!-- Health Score Error -->
                <ng-container *ngIf="currentViewToRender === healthScoreView.ERROR">
                    <avi-health-score-error (onRetryClick)="fetchHeathScoreData()"></avi-health-score-error>
                </ng-container>

                <!-- Health Score Body Content -->
                <ng-container *ngIf="currentViewToRender === healthScoreView.UP || currentViewToRender === healthScoreView.DOWN">
                    <div class="avi-health-score__health-title">
                        <div>
                            <cds-icon
                                shape="heart"
                                size="18"
                                class="avi-health-score__health-title--icon"
                            ></cds-icon>
                            <span class="avi-health-score__health-title--label">
                                {{ l10nKeys.healthBreakdownLabel | vtranslate }}
                            </span>
                        </div>
                        <div
                            *ngIf="currentViewToRender === healthScoreView.DOWN"
                            class="avi-health-score__health-title--not-available-label"
                        >
                            {{ globalL10nKeys.notAvailableLabel | vtranslate }}
                        </div>
                    </div>

                    <ng-container *ngIf="currentViewToRender === healthScoreView.UP">
                        <!-- Pool Health Score Body Content -->
                        <pool-health-score-body
                            *ngIf="objectName === poolObjectName"
                            [editable]="editable"
                        ></pool-health-score-body>

                        <!-- Avi Health Score Footer -->
                        <avi-health-score-footer (onViewHealthClick)="handleViewHealthButtonClick()"></avi-health-score-footer>
                    </ng-container>

                    <ng-container *ngIf="currentViewToRender === healthScoreView.DOWN">
                        <!-- Avi Health Score Down Status -->
                        <avi-health-score-down
                            [stateLabel]="stateLabel"
                            [reasons]="reasons"
                        ></avi-health-score-down>
                    </ng-container>
                </ng-container>
            </div>
        </signpost>
    </ng-template>
</div>

<ng-template #loader>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</ng-template>