<h6>
    {{ l10nKeys.customTagLabelWithCount | vtranslate : customTagsCount }}
</h6>
<avi-data-grid
    class="case-custom-tags-grid"
    [config]="caseCustomTagsGridConfig"
    [rows]="tagRows"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            [disabled]="disableGridControls"
            (click)="addTagRow()"
        >
            {{ l10nKeys.addTagButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>

<ng-template
    #systemTagTypeFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-dropdown
        name="{{ 'system-tag-type_' + index }}"
        id="{{ 'system-tag-type_' + index }}"
        [(ngModel)]="row.type"
        [options]="systemTagsTypeDropdownOptions"
        [hiddenValues]="selectedSystemTags"
        (ngModelChange)="onSystemTagTypeChange(row)"
        placeholder="{{ l10nKeys.systemTagTypePlaceholder | vtranslate }}"
        [disabled]="disableGridControls"
        required
    ></avi-dropdown>
</ng-template>

<ng-template
    #systemTagValueFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-dropdown
        name="{{ 'system-tag-value_' + index }}"
        id="{{ 'system-tag-value_' + index }}"
        [(ngModel)]="row.value"
        [options]="getSystemTagValueDropdownOptions(row.type)"
        (ngModelChange)="updateCustomTagConfigField()"
        placeholder="{{ l10nKeys.systemTagValuePlaceholder | vtranslate }}"
        [disabled]="disableGridControls"
        required
    ></avi-dropdown>
</ng-template>

<ng-template
    #customTagTypeFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-input-container noMarginTop>
        <input
            name="{{ 'custom-tag-type_' + index }}"
            id="{{ 'custom-tag-type_' + index }}"
            [(ngModel)]="row.type"
            (ngModelChange)="updateCustomTagConfigField()"
            placeholder="{{ l10nKeys.customTagTypePlaceholder | vtranslate }}"
            [disabled]="disableGridControls"
            clrInput
            required
        />
    </avi-input-container>
</ng-template>

<ng-template
    #customTagValueFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-input-container noMarginTop>
        <input
            name="{{ 'custom-tag-value_' + index }}"
            id="{{ 'custom-tag-value_' + index }}"
            [(ngModel)]="row.value"
            (ngModelChange)="updateCustomTagConfigField()"
            placeholder="{{ l10nKeys.customTagValuePlaceholder | vtranslate }}"
            [disabled]="disableGridControls"
            clrInput
            required
        />
    </avi-input-container>
</ng-template>
