/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import {
    IItemData,
    Item,
} from 'ajs/modules/data-model/factories/item.factory';

/**
 * @description Item for File Service
 *
 * @author Shahab Hashmi
 */
export class FileServiceItem extends Item {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'fileservice',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle, class-methods-use-this
    public getIdFromData_(data: IItemData<any>): string {
        const { config } = data;

        if (!config || !config.url) {
            console.error('missing config or url');

            return '';
        }

        const { url } = config;
        const apiIndex = url.indexOf('?');

        return url.slice(apiIndex);
    }
}
