/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'confirm';
const prefix = `${moduleName}.${componentName}`;

export const discardChangesMessage = `${prefix}.discardChangesMessage`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const discardChangesButtonLabel = `${prefix}.discardChangesButtonLabel`;
export const wantSaveChangesMessage = `${prefix}.wantSaveChangesMessage`;
export const saveChangesButtonLabel = `${prefix}.saveChangesButtonLabel`;
export const virtualServiceTextPlural = `${prefix}.virtualServiceTextPlural`;
export const poolTextPlural = `${prefix}.poolTextPlural`;
export const usedByVsAndPoolMessage = `${prefix}.usedByVsAndPoolMessage`;

export const ENGLISH = {
    [discardChangesMessage]: 'Recent changes have been made, are you sure you want to discard?',
    [cancelButtonLabel]: 'Cancel',
    [discardChangesButtonLabel]: 'Yes, discard changes',
    [wantSaveChangesMessage]: 'Are you sure you want to save changes?',
    [saveChangesButtonLabel]: 'Yes, save changes',
    [virtualServiceTextPlural]: '{0, plural,one {Virtual Service}other {Virtual Services}}',
    [poolTextPlural]: '{0, plural,one {Pool}other {Pools}}',
    [usedByVsAndPoolMessage]: 'This profile is being used by##0{0}##1and##2{1}',
};
