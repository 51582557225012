/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-timeframes';
const prefix = `${moduleName}.${componentName}`;

export const past15minutesLabel = `${prefix}.past15minutesLabel`;
export const pastHourLabel = `${prefix}.pastHourLabel`;
export const past3hoursLabel = `${prefix}.past3hoursLabel`;
export const past6hoursLabel = `${prefix}.past6hoursLabel`;
export const pastDayLabel = `${prefix}.pastDayLabel`;
export const pastWeekLabel = `${prefix}.pastWeekLabel`;
export const pastMonthLabel = `${prefix}.pastMonthLabel`;

export const ENGLISH = {
    [past15minutesLabel]: 'Past 15 Minutes',
    [pastHourLabel]: 'Past Hour',
    [past3hoursLabel]: 'Past 3 Hours',
    [past6hoursLabel]: 'Past 6 Hours',
    [pastDayLabel]: 'Past Day',
    [pastWeekLabel]: 'Past Week',
    [pastMonthLabel]: 'Past Month',
};
