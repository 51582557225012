<div class="waf-application-rules-list clr-wrapper">
    <waf-application-rules-list-header
        [applicationSignatureProvider]="applicationSignatureProvider"
        (onEditPulseSettings)="redirectToCloudServices()"
    ></waf-application-rules-list-header>
    <avi-card>
        <avi-card_header>
            {{ l10nKeys.applicationRulesDBHeader | vtranslate }}
        </avi-card_header>
        <avi-card_body>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="WafApplicationSignatureProvider"
                    fieldName="name"
                    for="name"
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="name"
                    placeholder="{{ globalL10nKeys.nameLabel | vtranslate }}"
                    name="name"
                    [ngModel]="applicationSignatureProvider.getName()"
                    readonly
                />
            </avi-input-container>
            <avi-data-grid
                [config]="applicationsGridConfig"
                [rows]="applications"
            ></avi-data-grid>
        </avi-card_body>
    </avi-card>

</div>
