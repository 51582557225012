/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module CollectionsModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { PoolGroupDeploymentPolicy } from 'object-types';
import { PoolGroupDeploymentPolicyItem } from 'items/pool-group-deployment-policy.item.factory';

/**
 * @description Pool Group Deployment Policy collection.
 * @author Nisar Nadaf
 */
export class PoolGroupDeploymentPolicyCollection extends Collection {
    protected static ngDependencies = [PoolGroupDeploymentPolicyItem];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'poolgroupdeploymentpolicy',
            objectType: PoolGroupDeploymentPolicy,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUPDEPLOYMENTPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(PoolGroupDeploymentPolicyItem);
    }
}
