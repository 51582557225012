/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

/**
 * @description
 *     Component to display an error message in the VS logs view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-logs-errors',
    templateUrl: './vs-logs-errors.component.html',
})
export class VsLogsErrorsComponent {
    @Input()
    public errorMessage: string;

    @Input()
    public actionLabel ?= '';

    @Input()
    public closable ?= false;

    @Output()
    public onActionClick ?= new EventEmitter<void>();

    @Output()
    public onClose ?= new EventEmitter<void>();

    public handleActionClick(): void {
        this.onActionClick.emit();
    }

    public handleCloseClick(): void {
        this.onClose.emit();
    }
}
