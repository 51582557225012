/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AviNotificationType } from 'ng/modules/notification/avi-notification.types';
import './pki-crl-upload-success-notification.component.less';
import * as l10n from './pki-crl-upload-success-notification.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying the toaster message on successful CRL upload.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'pki-crl-upload-success-notification',
    templateUrl: './pki-crl-upload-success-notification.component.html',
})
export class PkiCrlUploadSuccessNotificationComponent {
    /**
     * Header to be displayed in the dialog.
     */
    @Input()
    public header: string;

    /**
     * Emit to close the notification.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Type of notification.
     */
    public readonly notificationType = AviNotificationType.SUCCESS;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handle close button click.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
