/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';

import {
    PortalConfiguration,
    SecureChannelConfiguration,
    SystemConfiguration,
} from 'object-types';

import * as l10n from './system-settings-access-ssl-card.l10n';
import './system-settings-access-ssl-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Presentational Component for Access Card - SSL, in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-ssl-card',
    templateUrl: './system-settings-access-ssl-card.component.html',
})
export class SystemSettingsAccessSystemSslComponent {
    /**
     * Ssl profile ref to display name in template.
     */
    @Input()
    public sslProfileRef = '';

    /**
     * Ssl key certificate refs in portal configuration.
     */
    @Input()
    public sslKeyCertificateRefs: string[];

    /**
     * Secure channel ssl key certificate refs in portal configuration.
     */
    @Input()
    public secureChannelSslKeyCertificateRefs: string[];

    /**
     * Allowed ciphers.
     */
    @Input()
    public allowedCiphers: string[];

    /**
     * Allowed Hmacs.
     */
    @Input()
    public allowedHmacs: string[];

    /**
     * SSL profile name.
     */
    public sslProfileName: string;

    /**
     * SSL TLS certificate names.
     */
    public sslTlsCertificatesNamesList: string[];

    /**
     * secure channel SSL TLS certificate names.
     */
    public secureChannelSslTlsCertificateNamesList: string[];

    /**
     * Hmacs count.
     */
    public hmacsCount: number;

    /**
     * Boolean flag for allowed ciphers list being empty.
     */
    public isAllowedCiphersEmpty: boolean;

    /**
     * Boolean flag for SSL/TLS certificates list being empty.
     */
    public isSslTlsCertificatesNamesListEmpty: boolean;

    /**
     * Boolean flag for secure channel SSL/TLS certificates list being empty.
     */
    public isSecureChannelSslTlsCertificateNamesListEmpty: boolean;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object Type for Template usage.
     */
    public readonly objectType = {
        PortalConfiguration,
        SecureChannelConfiguration,
        SystemConfiguration,
    };

    constructor(
        l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnInit(): void {
        this.setSslProfileName();
        this.setHmacsCount();
        this.setIsAllowedCiphersEmpty();
        this.setSslTlsCertificatesNamesList();
        this.setIsSslTlsCertificatesNamesListEmpty();
        this.setSecureChannelSslTlsCertificateNamesList();
        this.setIsSecureChannelSslTlsCertificateNamesListEmpty();
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        const {
            sslProfileRef,
            sslKeyCertificateRefs,
            secureChannelSslKeyCertificateRefs,
            allowedHmacs,
            allowedCiphers,
        } = simpleChanges;

        if (sslProfileRef && !sslProfileRef?.isFirstChange()) {
            this.setSslProfileName();
        }

        if (allowedHmacs && !allowedHmacs?.isFirstChange()) {
            this.setHmacsCount();
        }

        if (allowedCiphers && !allowedCiphers?.isFirstChange()) {
            this.setIsAllowedCiphersEmpty();
        }

        if (sslKeyCertificateRefs && !sslKeyCertificateRefs?.isFirstChange()) {
            this.setSslTlsCertificatesNamesList();
            this.setIsSslTlsCertificatesNamesListEmpty();
        }

        if (
            secureChannelSslKeyCertificateRefs &&
            !secureChannelSslKeyCertificateRefs?.isFirstChange()
        ) {
            this.setSecureChannelSslTlsCertificateNamesList();
            this.setIsSecureChannelSslTlsCertificateNamesListEmpty();
        }
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Sets SSL profile name.
     */
    private setSslProfileName(): void {
        this.sslProfileName = this.stringService.name(this.sslProfileRef);
    }

    /**
     * Sets Hmacs count.
     */
    private setHmacsCount(): void {
        this.hmacsCount = this.allowedHmacs?.length || 0;
    }

    /**
     * Sets flag for allowed ciphers list being empty.
     */
    private setIsAllowedCiphersEmpty(): void {
        this.isAllowedCiphersEmpty = !this.allowedCiphers?.length;
    }

    /**
     * Sets flag for SSL/TLS certificates list being empty.
     */
    private setIsSslTlsCertificatesNamesListEmpty(): void {
        this.isSslTlsCertificatesNamesListEmpty = !this.sslTlsCertificatesNamesList?.length;
    }

    /**
     * Sets flag for secure channel SSL/TLS certificates list being empty.
     */
    private setIsSecureChannelSslTlsCertificateNamesListEmpty(): void {
        this.isSecureChannelSslTlsCertificateNamesListEmpty =
        !this.secureChannelSslTlsCertificateNamesList?.length;
    }

    /**
     * Sets SSL TLS certificate names.
     */
    private setSslTlsCertificatesNamesList(): void {
        const sslTlsCertificatesNamesList = this.sslKeyCertificateRefs?.map(
            (sslKeyCertRef: string): string => this.stringService.name(sslKeyCertRef),
        );

        this.sslTlsCertificatesNamesList = sslTlsCertificatesNamesList;
    }

    /**
     * Sets secure channel SSL TLS certificate names.
     */
    private setSecureChannelSslTlsCertificateNamesList(): void {
        const secureChannelSslTlsCertificateNamesList = this.secureChannelSslKeyCertificateRefs
            ?.map(
                (secureChannelSslKeyCertRef: string): string => this.stringService.name(
                    secureChannelSslKeyCertRef,
                ),
            );

        this.secureChannelSslTlsCertificateNamesList = secureChannelSslTlsCertificateNamesList;
    }
}
