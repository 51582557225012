/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

class GslbServiceDetailFqdnListController {
    constructor(
        GSLBServiceFQDNCollection,
        getGridMetricFieldConfig,
    ) {
        this.GSLBServiceFQDNCollection = GSLBServiceFQDNCollection;
        this.getGridMetricFieldConfig = getGridMetricFieldConfig;
        this.isBusy = true;
    }

    $onInit() {
        this.createFqdnCollectionAndConfig_();
    }

    /**
     * Creates Collection and gridConfig.
     * @protected
     */
    createFqdnCollectionAndConfig_() {
        this.collection_ = new this.GSLBServiceFQDNCollection({
            gslb: this.gslbService_.getGSLB(),
            gslbService: this.gslbService_,
        });

        this.gslbService_.getGSLB().getSiteDNSVSNames().finally(() => {
            this.isBusy = false;
        });

        this.GSLBServiceFQDNListGridConfig = {
            collection: this.collection_,
            id: componentTag,
            fields: [
                {
                    name: 'name',
                    title: 'Name',
                    template: '{{row.getName()}}',
                },
                this.getGridMetricFieldConfig('dns_client.avg_udp_queries'),
                this.getGridMetricFieldConfig('dns_client.avg_errored_queries'),
                this.getGridMetricFieldConfig('dns_client.pct_errored_queries'),
            ],
            layout: {
                hideSearch: true,
            },
        };
    }

    $onDestroy() {
        if (this.collection_) {
            this.collection_.destroy();
        }
    }
}

GslbServiceDetailFqdnListController.$inject = [
    'GSLBServiceFQDNCollection',
    'getGridMetricFieldConfig',
];

const componentTag = 'gslb-service-detail-fqdn-list';
const templateUrl =
    'src/components/pages/application/gslb/gslb-service-detail/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @ngdoc component
 * @name gslbServiceDetailFqdnList
 * @param {Object} gslbService - gslbService object
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Regular grid view. Ready to be shown after DNS VS names of GSLB has been loaded.
 */
angular.module('avi/gslb').component('gslbServiceDetailFqdnList', {
    bindings: {
        gslbService_: '<resolveGslbService',
    },
    controller: GslbServiceDetailFqdnListController,
    templateUrl,
});
