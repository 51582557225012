/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { PoolGroupMember } from 'object-types';
import { StringService } from 'string-service';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    IPoolGroupMember,
    PoolDeploymentState,
} from 'generated-types';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pool-group-member.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description PoolGroup Member config item.
 *
 * @author sghare
 */
export class PoolGroupMemberConfigItem extends withFullModalMixin(MessageItem)<IPoolGroupMember> {
    public static ngDependencies = [
        'l10nService',
        StringService,
    ];

    /**
     * L10nService instance for translation.
     */
    private readonly l10nService: L10nService;

    /**
     * StringService instance to work with string values.
     */
    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: PoolGroupMember,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
        this.stringService = this.getNgDependency(StringService);
    }

    /**
     * Getter for Pool name.
     */
    public get poolName(): string {
        const { pool_ref: poolRef } = this.config;

        return poolRef ? this.stringService.name(poolRef) : '';
    }

    /**
     * Getter for PoolDeploymentState.
     */
    public get deploymentState(): PoolDeploymentState {
        return this.config.deployment_state;
    }

    /**
     * Looks up description field for allow rule's selected PoolDeploymentState.
     */
    public get deploymentStateText(): string {
        const schemaService = this.getNgDependency('schemaService');

        return this.deploymentState ?
            schemaService.getEnumValueLabel('PoolDeploymentState', this.deploymentState) : '';
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            PoolGroupMembersModalComponent,
        } = await import(
            /* webpackChunkName: "pool-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/pool-group-modal/pool-group-members-modal/pool-group-members-modal.component'
        );

        return PoolGroupMembersModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.poolGroupMembersModalBreadcrumbTitle);
    }

    /** @override */
    protected getModalBreadcrumbDescription(params: IItemParams): string {
        const editable = params.editable as PoolGroupMemberConfigItem;

        return editable.poolName;
    }
}
