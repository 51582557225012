/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import {
    Actions,
    concatLatestFrom,
    createEffect,
    ofType,
} from '@ngrx/effects';

import {
    catchError,
    map,
    switchMap,
} from 'rxjs/operators';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { selectUIProperty } from './user-preferences.selectors';
import { UserPreferencesService } from './user-preferences.service';
import * as UserPreferencesActions from './user-preferences.actions';

@Injectable()
export class UserPreferencesEffects {
    /**
     * Fetch user preferences info to store.
     */
    public fetchUserPreferences$ = createEffect(() => this.actions$.pipe(
        ofType(UserPreferencesActions.fetchUserPreferences),
        switchMap(() => this.userPreferencesService.fetchUserPreferences()
            .pipe(
                map(userPreferences => {
                    return UserPreferencesActions.fetchUserPreferencesSuccess({
                        payload: {
                            ...userPreferences,
                        },
                    });
                }),
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(UserPreferencesActions.fetchUserPreferencesError());
                }),
            )),
    ));

    /**
     * Save UIProperty and set updated UIProperty to store.
     */
    public updateUIProperty$ = createEffect(() => this.actions$.pipe(
        ofType(UserPreferencesActions.updateUIProperty),
        map(action => action.payload),
        concatLatestFrom(() => this.store.select(selectUIProperty)),
        switchMap(([updatedUIProperty, cachedUIProperty]) => {
            const uiProperty = {
                ...cachedUIProperty,
                ...updatedUIProperty,
            };

            return this.userPreferencesService.saveUIProperty(uiProperty)
                .pipe(
                    map(() => {
                        return UserPreferencesActions.saveUserPreferencesSuccess({
                            payload: {
                                ui_property: {
                                    ...uiProperty,
                                },
                            },
                        });
                    }),
                    catchError(error => {
                        this.devLoggerService.error(error.data);

                        return of(UserPreferencesActions.saveUserPreferencesError());
                    }),
                );
        }),
    ));

    /**
     * Save UIProperty.logs and set updated UIProperty to store.
     */
    public updateLogs$ = createEffect(() => this.actions$.pipe(
        ofType(UserPreferencesActions.updateLogs),
        map(action => action.payload),
        concatLatestFrom(() => this.store.select(selectUIProperty)),
        map(([logs, cachedUIProperty]) => {
            return UserPreferencesActions.updateUIProperty({
                payload: {
                    logs: {
                        ...cachedUIProperty.logs,
                        ...logs,
                    },
                },
            });
        }),
    ));

    /**
     * Save UIProperty.appDashboard and set updated UIProperty to store.
     */
    public updateAppDashboard$ = createEffect(() => this.actions$.pipe(
        ofType(UserPreferencesActions.updateAppDashboard),
        map(action => action.payload),
        concatLatestFrom(() => this.store.select(selectUIProperty)),
        map(([appDashboard, cachedUIProperty]) => {
            return UserPreferencesActions.updateUIProperty({
                payload: {
                    appDashboard: {
                        ...cachedUIProperty.appDashboard,
                        ...appDashboard,
                    },
                },
            });
        }),
    ));

    /**
     * Saves UIProperty.grid and set updated UIProperty to store.
     */
    public updateGrid$ = createEffect(() => this.actions$.pipe(
        ofType(UserPreferencesActions.updateGrid),
        map(action => action.payload),
        concatLatestFrom(() => this.store.select(selectUIProperty)),
        map(([grid, cachedUIProperty]) => {
            return UserPreferencesActions.updateUIProperty({
                payload: {
                    grid: {
                        ...cachedUIProperty.grid,
                        ...grid,
                    },
                },
            });
        }),
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly devLoggerService: DevLoggerService,
        private readonly userPreferencesService: UserPreferencesService,
        private readonly store: Store,
    ) {}
}
