/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'icap-profile';
const componentName = 'icap-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const poolGroupSelectPlaceholder = `${prefix}.poolGroupSelectPlaceholder`;
export const statusUrlInputLabel = `${prefix}.statusUrlInputLabel`;
export const statusUrlInputPlaceholder = `${prefix}.statusUrlInputPlaceholder`;
export const serviceUrlInputLabel = `${prefix}.serviceUrlInputLabel`;
export const serverUrlInputPlaceholder = `${prefix}.serverUrlInputPlaceholder`;
export const bufferSizeInputLabel = `${prefix}.bufferSizeInputLabel`;
export const bufferSizeInputPlaceholder = `${prefix}.bufferSizeInputPlaceholder`;
export const enablePreviewInputLabel = `${prefix}.enablePreviewInputLabel`;
export const allow204ResponseLabel = `${prefix}.allow204ResponseLabel`;
export const previewSizeInputLabel = `${prefix}.previewSizeInputLabel`;
export const previewSizeInputPlaceholder = `${prefix}.previewSizeInputPlaceholder`;
export const responseTimeoutInputLabel = `${prefix}.responseTimeoutInputLabel`;
export const responseTimeoutInputPlaceholder = `${prefix}.responseTimeoutInputPlaceholder`;
export const responseThresholdInputLabel = `${prefix}.responseThresholdInputLabel`;
export const responseThresholdInputPlaceholder = `${prefix}.responseThresholdInputPlaceholder`;
export const subSectionTitleFailureAction = `${prefix}.subSectionTitleFailureAction`;
export const subSectionTitleLargeUploadFailureAction = `${prefix}.subSectionTitleLargeUploadFailureAction`;
export const kilobytesDataLabel = `${prefix}.kilobytesDataLabel`;
export const millisecondsDataLabel = `${prefix}.millisecondsDataLabel`;
export const bytesDataLabel = `${prefix}.bytesDataLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit ICAP Profile',
    [modalTitleNew]: 'New ICAP Profile',
    [poolGroupSelectPlaceholder]: 'Select Pool Group',
    [statusUrlInputLabel]: 'Status URL',
    [statusUrlInputPlaceholder]: 'Enter Status URL',
    [serviceUrlInputLabel]: 'Service URL',
    [serverUrlInputPlaceholder]: 'Enter URL',
    [bufferSizeInputLabel]: 'Request Buffer Size',
    [bufferSizeInputPlaceholder]: 'Enter Size',
    [enablePreviewInputLabel]: 'Enable ICAP Preview',
    [allow204ResponseLabel]: 'Allow 204 Response',
    [previewSizeInputLabel]: 'Preview Size',
    [previewSizeInputPlaceholder]: 'Enter Size',
    [responseTimeoutInputLabel]: 'Response Timeout',
    [responseTimeoutInputPlaceholder]: 'Enter Timeout',
    [responseThresholdInputLabel]: 'Slow Response warning Threshold',
    [responseThresholdInputPlaceholder]: 'Enter Threshold',
    [subSectionTitleFailureAction]: 'Failure Action',
    [subSectionTitleLargeUploadFailureAction]: 'Large Upload Failure Action',
    [kilobytesDataLabel]: 'kilobytes',
    [bytesDataLabel]: 'bytes',
};
