/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { WafAddExceptionDialogComponent } from 'ng/modules/waf/components/waf-add-exception-dialog';
import './nsm-log.less';
import '../../waf-rules-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * ID for vs logs waf exception dialog.
 */
const WAF_ADD_EXCEPTION_DIALOG_ID = 'waf-add-exception-dialog';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.nsmLogComponentBindings
 * @see {@link module:avi/logs.nsmLogComponent}
 */
class NsmLogController {
    constructor(aviAlertService, AviConfirmService, Auth, dialogService) {
        this.aviAlertService_ = aviAlertService;
        this.AviConfirmService_ = AviConfirmService;
        this.Auth_ = Auth;
        this.dialogService_ = dialogService;

        /**
         * Used to show the Add Rule exception button.
         * @type {boolean}
         */
        this.hoverRule = false;
        this.expanderActions = [];
    }

    /**
     * @override
     */
    $onInit() {
        if (this.showAddException()) {
            this.expanderActions = [
                {
                    iconClassName: 'sl-icon-plus',
                    title: 'Add Group Exceptions',
                    onClick: () => this.addGroupException(),
                    hidden: () => !this.showAddException(),
                },
            ];
        }
    }

    /**
     * Handler for the mouse hover on the rule.
     * @param {boolean} [hover=false] - True if the mouse is hovering over the rule. False
     *     otherwise.
     */
    handleRuleHover(hover = false) {
        this.hoverRule = hover;
    }

    /**
     * Determines if the buttons to add exceptions should be shown.
     * @returns {boolean}
     */
    showAddException() {
        return this.Auth_.isAllowed('wafpolicy', 'w') &&
            this.wafPolicyRef &&
            this.getGroupName() !== 'N/A';
    }

    /**
     * Opens the exceptions dialog to add group exceptions.
     */
    addGroupException() {
        this.openExceptionsDialog_(this.rules);
    }

    /**
     * Opens the exceptions dialog to add a rule exception.
     * @param {angular.$event} $event
     * @param {WafRule} rule
     * @param {string} ruleId - WAF Rule ID.
     */
    addRuleException($event, rule, ruleId) {
        $event.stopPropagation();

        this.openExceptionsDialog_([rule], ruleId);
    }

    /**
     * Opens the exceptions dialog to add group or rule exceptions.
     * @param {WafRules[]} ruleLogs
     * @param {string} ruleId - WAF Rule ID.
     * @protected
     */
    openExceptionsDialog_(ruleLogs, ruleId) {
        this.dialogService_.add({
            id: WAF_ADD_EXCEPTION_DIALOG_ID,
            component: WafAddExceptionDialogComponent,
            componentProps: {
                onClose: () => this.dialogService_.remove(WAF_ADD_EXCEPTION_DIALOG_ID),
                wafPolicyRef: this.wafPolicyRef,
                ruleLogs,
                groupName: this.groupName,
                ruleId,
                uriPath: this.uriPath,
            },
        });
    }

    /**
     * Some WAF groups don't have group names, and this.groupName will be in the format of
     * '__N/A:<number>' (where the number is an index) so that groups remain unique in the parent
     * hash. For those groups 'N/A' will be shown as the group name.
     * @see {@link module:avi/logs.WafLogsService.ruleGroupReducer_}
     * @returns {string}
     */
    getGroupName() {
        return this.groupName.indexOf('__N/A:') === 0 ? 'N/A' : this.groupName;
    }
}

NsmLogController.$inject = [
    'aviAlertService',
    'AviConfirmService',
    'Auth',
    'dialogService',
];

/**
 * @name nsmLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.NsmLogController} controller
 * @property {module:avi/logs.nsmLogComponentBindings} bindings
 * @description Component for an NSM Log.
 * @author alextsg
 */
angular.module('avi/logs').component('nsmLog', {
    /**
     * @mixin nsmLogComponentBindings
     * @memberOf module:avi/logs
     * @property {string} groupName - Name of the WAF Group.
     * @property {WafRule[]} rules - Rules belonging to the WAF Group.
     * @property {string} [wafPolicyRef=] - Optional because the Virtual Service may no
     *      longer have the WafPolicy.
     * @property {string} clientIp - Client IP of the log.
     * @property {string} uriPath - URI Path of the log.
     * @property {boolean} expanded - Boolean to expand/collapse all.
     */
    bindings: {
        groupName: '@',
        rules: '<',
        wafPolicyRef: '@?',
        uriPath: '@',
        expanded: '<',
    },
    controller: NsmLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'nsm-logs-list/nsm-log/nsm-log.component.html',
});
