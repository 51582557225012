<div
    class="gslb-service-pool-members-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="poolMembersGridConfig"
        [rows]="poolMembers.config"
        [invalidateForm]="!poolMembers.count"
    >
        <avi-data-grid_header>
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="members"
            >
                <h6>
                    {{ l10nKeys.poolMembersGridLabel | vtranslate : poolMembers.count }}
                    <span class="gslb-service-pool-members-grid__mandatory-indicator">*</span>
                </h6>
            </label>
        </avi-data-grid_header>

        <avi-data-grid_actions>
            <cds-button
                (click)="addPoolMember()"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>

    <ng-template
        #poolMemberEnabledTemplateRef
        let-poolMember
        let-index="index"
    >
        <avi-checkbox
            [noMarginTop]="true"
            name="gslb-pool-member-enabled"
            [(ngModel)]="poolMember.config.enabled"
        >
        </avi-checkbox>
    </ng-template>
</div>
