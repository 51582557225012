/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'accounts';

export const userProfileModalBreadcrumbTitle = `${prefix}.userProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [userProfileModalBreadcrumbTitle]: 'User Profile',
};
