/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-profile-preview';
const prefix = `${moduleName}.${componentName}`;

export const errorPageIndexPosition = `${prefix}.errorPageIndexPosition`;

export const ENGLISH = {
    [errorPageIndexPosition]: 'Error Page Index Position',
};
