/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing match components.
 * @preferred
 * @module MatchModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    AuthAttributeMatchComponent,
    AuthAttributeMatchReadonlyComponent,
    AuthMatchAttributeReadonlyComponent,
    AuthMatchGroupMembershipReadonlyComponent,
    BotClassMatcherComponent,
    BotClassMatcherReadonlyComponent,
    BotTypeMatcherComponent,
    BotTypeMatcherReadonlyComponent,
    GenericHdrMatchComponent,
    HdrMatchComponent,
    HdrMatchReadonlyComponent,
    HostHdrMatchComponent,
    HostHdrMatchReadonlyComponent,
    HttpMethodMatchComponent,
    HttpMethodMatchReadonlyComponent,
    HttpVersionMatchComponent,
    HttpVersionMatchReadonlyComponent,
    IpAddrMatchComponent,
    IpAddrMatchEntryComponent,
    IpAddrMatchReadonlyComponent,
    IpReputationTypeMatchComponent,
    JwtClaimMatchConfigComponent,
    JwtMatchComponent,
    JwtMatchReadonlyComponent,
    MatchAdderComponent,
    MatchAndActionDisplayWrapperComponent,
    MatchDisplayWrapperComponent,
    MatchWrapperComponent,
    PathOrQueryMatchComponent,
    PathOrQueryMatchReadonlyComponent,
    PortMatchComponent,
    PortMatchReadonlyComponent,
    ProtocolMatchReadonlyComponent,
    ProtocolTypeMatchComponent,
    ServiceMatchComponent,
    StringMatchComponent,
    StringMatchConfigComponent,
    StringMatchReadonlyComponent,
} from './components';

import {
    ipAddrGroupCollectionProvider,
    rangeParserProvider,
} from './ajs-upgraded-providers';

const matchComponents = [
    AuthAttributeMatchComponent,
    AuthAttributeMatchReadonlyComponent,
    AuthMatchAttributeReadonlyComponent,
    AuthMatchGroupMembershipReadonlyComponent,
    BotClassMatcherComponent,
    BotClassMatcherReadonlyComponent,
    BotTypeMatcherComponent,
    BotTypeMatcherReadonlyComponent,
    GenericHdrMatchComponent,
    HdrMatchComponent,
    HdrMatchReadonlyComponent,
    HostHdrMatchComponent,
    HostHdrMatchReadonlyComponent,
    HttpMethodMatchComponent,
    HttpMethodMatchReadonlyComponent,
    HttpVersionMatchComponent,
    HttpVersionMatchReadonlyComponent,
    IpAddrMatchComponent,
    IpAddrMatchEntryComponent,
    IpAddrMatchReadonlyComponent,
    IpReputationTypeMatchComponent,
    JwtClaimMatchConfigComponent,
    JwtMatchComponent,
    JwtMatchReadonlyComponent,
    MatchAdderComponent,
    MatchAndActionDisplayWrapperComponent,
    MatchDisplayWrapperComponent,
    MatchWrapperComponent,
    MatchWrapperComponent,
    PathOrQueryMatchComponent,
    PathOrQueryMatchReadonlyComponent,
    PortMatchComponent,
    PortMatchReadonlyComponent,
    ProtocolMatchReadonlyComponent,
    ProtocolTypeMatchComponent,
    StringMatchConfigComponent,
    ServiceMatchComponent,
    StringMatchComponent,
    StringMatchReadonlyComponent,
];

@NgModule({
    declarations: [
        ...matchComponents,
    ],
    exports: [
        AuthAttributeMatchReadonlyComponent,
        BotClassMatcherComponent,
        BotClassMatcherReadonlyComponent,
        BotTypeMatcherComponent,
        BotTypeMatcherReadonlyComponent,
        HdrMatchComponent,
        HdrMatchReadonlyComponent,
        HostHdrMatchComponent,
        HostHdrMatchReadonlyComponent,
        HttpMethodMatchComponent,
        HttpMethodMatchReadonlyComponent,
        IpAddrMatchComponent,
        IpAddrMatchReadonlyComponent,
        JwtMatchReadonlyComponent,
        MatchAdderComponent,
        MatchAndActionDisplayWrapperComponent,
        MatchDisplayWrapperComponent,
        MatchWrapperComponent,
        IpAddrMatchComponent,
        PathOrQueryMatchComponent,
        PathOrQueryMatchReadonlyComponent,
        PortMatchComponent,
        PortMatchReadonlyComponent,
        HttpVersionMatchReadonlyComponent,
        AuthMatchAttributeReadonlyComponent,
        AuthMatchGroupMembershipReadonlyComponent,
        StringMatchComponent,
        StringMatchReadonlyComponent,
        ProtocolMatchReadonlyComponent,
    ],
    imports: [
        CommonModule,
        VIPModule,
        SharedModule,
        AviFormsModule,
    ],
    providers: [
        ipAddrGroupCollectionProvider,
        rangeParserProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class MatchModule {}
