/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AuthMappingProfileCollection,
    AuthMappingProfileItem,
    AuthMappingRuleConfigItem,
    AuthMatchAttributeConfigItem,
    AuthMatchGroupMembershipConfigItem,
} from '.';

import {
    AUTH_MAPPING_PROFILE_COLLECTION_TOKEN,
    AUTH_MAPPING_PROFILE_ITEM_TOKEN,
    AUTH_MAPPING_RULE_CONFIG_ITEM_TOKEN,
    AUTH_MATCH_ATTRIBUTE_CONFIG_ITEM_TOKEN,
    AUTH_MATCH_GROUP_MEMBERSHIP_CONFIG_ITEM_TOKEN,
} from './auth-settings.tokens';

const authSettingsModule = angular.module('avi/auth-settings');

const factories = [
    {
        factory: AuthMappingProfileCollection,
        name: AUTH_MAPPING_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: AuthMappingProfileItem,
        name: AUTH_MAPPING_PROFILE_ITEM_TOKEN,
    },
    {
        factory: AuthMappingRuleConfigItem,
        name: AUTH_MAPPING_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: AuthMatchAttributeConfigItem,
        name: AUTH_MATCH_ATTRIBUTE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: AuthMatchGroupMembershipConfigItem,
        name: AUTH_MATCH_GROUP_MEMBERSHIP_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(authSettingsModule, 'factory', name, factory);
});
