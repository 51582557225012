/**
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as globalL10n from 'global-l10n';

import * as l10n from './move-data-grid-row.l10n';
import './move-data-grid-row.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export enum Placement {
    ABOVE = 'above',
    BELOW = 'below',
}

export interface IMoveItemData {
    placement: Placement;
    index: number;
}

/**
 * @description Dialog Component used when a user wants to move a rule at a specific index.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'move-data-grid-row',
    templateUrl: './move-data-grid-row.component.html',
})
export class MoveDataGridRowComponent implements OnInit {
    /**
     * Item type used in header.
     * Possible Values can be rule/Policy/Profile.
     */
    @Input()
    public type: string;

    /**
     * Name of the selected Item.
     */
    @Input()
    public selectedItemName: string;

    /**
     * List of all MessageItems.
     */
    @Input()
    public configItems: MessageItem[];

    /**
     * fires on Move button click.
     */
    @Output()
    public onMoveItem = new EventEmitter<IMoveItemData>();

    /**
     * Called on dialog close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Placement enum to be used in template.
     */
    public readonly placementEnum = Placement;

    /**
     * Model value for placement radio buttons.
     */
    public placement = Placement.ABOVE;

    /**
     * Model value for index dropdown.
     */
    public index: number;

    /**
     * Index dropdown options.
     */
    public indexDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {}

    /** @override */
    public ngOnInit(): void {
        this.indexDropdownOptions = this.configItems.map(item => {
            const index = item.getIndex();
            const itemName = item.getName();
            let label = `${index}`;

            if (itemName) {
                label += ` (${itemName})`;
            }

            return createDropdownOption(index, label);
        });
    }

    /**
     * Emits moveItem event with selected placement & index.
     */
    public moveItem(): void {
        this.onMoveItem.emit({
            placement: this.placement,
            index: this.index,
        });
    }

    /**
     * Called if the usercloses dialog.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Getter for item type which is shown in dialog header.
     */
    public get itemType(): string {
        return this.type || this.l10nService.getMessage(l10nKeys.defaultItemType);
    }
}
