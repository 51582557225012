/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'nodes-page';
const componentName = 'nodes-page';
const prefix = `${moduleName}.${componentName}`;

export const nodesPageHeader = `${prefix}.nodesPageHeader`;
export const columnTitleNodeIpv4 = `${prefix}.columnTitleNodeIpv4`;
export const columnTitleNodeIpv6 = `${prefix}.columnTitleNodeIpv6`;
export const columnTitleHostname = `${prefix}.columnTitleHostname`;
export const columnTitleRole = `${prefix}.columnTitleRole`;
export const controllerClusterHeader = `${prefix}.controllerClusterHeader`;
export const clusterIpLabel = `${prefix}.clusterIpLabel`;
export const nodesTitle = `${prefix}.nodesTitle`;

export const clusterLeaderTitle = `${prefix}.clusterLeaderTitle`;
export const clusterFollowerTitle = `${prefix}.clusterFollowerTitle`;
export const statusUpHaActiveLabel = `${prefix}.statusUpHaActiveLabel`;
export const statusUpHaCompromisedLabel = `${prefix}.statusUpHaCompromisedLabel`;
export const statusUpHaNotReadyLabel = `${prefix}.statusUpHaNotReadyLabel`;
export const statusUpNoHaLabel = `${prefix}.statusUpNoHaLabel`;
export const statusStartingLabel = `${prefix}.statusStartingLabel`;
export const statusRebootingLabel = `${prefix}.statusRebootingLabel`;
export const statusProcessingLabel = `${prefix}.statusProcessingLabel`;

export const stateStartingLabel = `${prefix}.stateStartingLabel`;
export const stateInactiveLabel = `${prefix}.stateInactiveLabel`;
export const stateFailureLabel = `${prefix}.stateFailureLabel`;
export const stateColdRebootLabel = `${prefix}.stateColdRebootLabel`;
export const stateWarmRebootLabel = `${prefix}.stateWarmRebootLabel`;
export const stateCleanRebootLabel = `${prefix}.stateCleanRebootLabel`;
export const stateWaitingLabel = `${prefix}.stateWaitingLabel`;
export const stateConfigLabel = `${prefix}.stateConfigLabel`;

export const ENGLISH = {
    [nodesPageHeader]: 'Nodes',
    [columnTitleNodeIpv4]: 'Node IPv4',
    [columnTitleNodeIpv6]: 'Node IPv6',
    [columnTitleHostname]: 'Hostname',
    [columnTitleRole]: 'Role',
    [controllerClusterHeader]: 'Controller Cluster',
    [clusterIpLabel]: 'Cluster IP',
    [nodesTitle]: 'Nodes ({0})',

    [clusterLeaderTitle]: 'Leader',
    [clusterFollowerTitle]: 'Follower',
    [statusUpHaActiveLabel]: 'Up(HA Active)',
    [statusUpHaCompromisedLabel]: 'Up(HA Compromised)',
    [statusUpHaNotReadyLabel]: 'Up(HA Not Ready)',
    [statusUpNoHaLabel]: 'Up(No HA)',
    [statusStartingLabel]: 'Starting',
    [statusRebootingLabel]: 'Rebooting',
    [statusProcessingLabel]: 'Processing',

    [stateStartingLabel]: 'Starting',
    [stateInactiveLabel]: 'Inactive',
    [stateFailureLabel]: 'Failure',
    [stateColdRebootLabel]: 'Cold Reboot',
    [stateWarmRebootLabel]: 'Warm Reboot',
    [stateCleanRebootLabel]: 'Clean Reboot',
    [stateWaitingLabel]: 'Waiting',
    [stateConfigLabel]: 'Config',
};
