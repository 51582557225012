/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAuthProfileConfig } from 'ajs/modules/security';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import {
    AuthProfileVerifySamlModalComponent,
} from '../components';

import * as l10n from './auth-profile-verify-saml-modal.service.I10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service to fetch data in modal and open/close the modal.
 *
 * @author Suraj Kumar
 */
@Injectable()
export class AuthProfileVerifySamlModalService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to open verify SAML modal.
     */
    public openModal(config: IAuthProfileConfig): void {
        this.fullModalService.addModal(this.getVerifyProfileModalProps(config));
    }

    /**
     * Method to close verify SAML modal.
     */
    public closeModal(): void {
        this.fullModalService
            .removeModalByComponent(AuthProfileVerifySamlModalComponent as Type<Component>);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getVerifyProfileModalProps(
        config: IAuthProfileConfig,
    ): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: AuthProfileVerifySamlModalComponent as Type<Component>,
            componentProps: {
                authProfileConfig: config,
            },
            getName: () => l10nService.getMessage(l10nKeys.verifyProfileModalName),
            getDescription: () => config.name,
        };
    }
}
