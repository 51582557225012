/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'internal-ipam-usable-networks-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const columnTitleNetwork = `${prefix}.columnTitleNetwork`;
export const networkSelectPlaceholder = `${prefix}.networkSelectPlaceholder`;
export const usableNetworksGridHeader = `${prefix}.usableNetworksGridHeader`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [columnTitleNetwork]: 'Network',
    [networkSelectPlaceholder]: 'Select Network',
    [usableNetworksGridHeader]: 'Usable Networks ({0})',
};
