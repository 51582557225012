/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    FactoryProvider,
    Injector,
    ProviderToken,
} from '@angular/core';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Constructor } from '../../../../declarations/globals.d';
import { NgDependencyInjected } from '../factories/ng-dependency-injected.factory';

export type TDependencyToken = ProviderToken<any>;

/**
 * @description
 * Injects dependencies into a provider class. Dependencies are accessible through the
 * getNgDependency instance method and static method. Similar to AjsDependency.
 *
 * This is backwards compatible with AjsDependency, meaning if you want to register a constructor
 * in Angular that extends an AjsDependency constructor registered in AngularJS, all dependencies
 * should still be retrievable.
 *
 * Example:
 * When registering an Item or Collection in the providers array:
 *
 * @NgModule({
 *     providers: [
 *         withInjectedDependencies(WebhookItem),
 *         withInjectedDependencies(WebhookCollection),
 *     ],
 * })
 * export class WebhookModule {}
 *
 * @author alextsg
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function injectNgDependencies<T extends Constructor<NgDependencyInjected>>(BaseClass: T) {
    return function(injector: Injector, $injector: ng.auto.IInjectorService) {
        /**
         * Map of dependencies.
         */
        const dependencyMap: Map<TDependencyToken | string, any> = new Map();

        if (typeof BaseClass !== 'function') {
            throw new Error('please pass class or constructor function');
        }

        /**
         * Adds dependencies to the dependencies map. Backwards compatible with AngularJS
         * dependencies as well.
         */
        function addDependenciesToMap(dependencies: TDependencyToken[] | string[] = []): void {
            dependencies.forEach(dependency => {
                if (!dependencyMap.has(dependency)) {
                    let injected;

                    // Wrapped in a try/catch block because if the dependency is not found with the
                    // Angular injector, an error is thrown.
                    try {
                        injected = injector.get<typeof dependency>(dependency as TDependencyToken);
                    } catch (e) {
                        // If provider not found in Angular, try getting it from AngularJS's
                        // injector.
                        injected = $injector.get(dependency as string);
                    }

                    dependencyMap.set(dependency, injected);
                }
            });
        }

        /**
         * Looks up dependency by its name in the dependencies map.
         */
        function getNgDependency(token: TDependencyToken): T | null {
            return dependencyMap.get(token) || null;
        }

        for (
            let CurrentClass = BaseClass;
            CurrentClass !== Object.prototype;
            CurrentClass = Object.getPrototypeOf(CurrentClass)
        ) {
            // @ts-expect-error
            addDependenciesToMap(CurrentClass.ngDependencies);

            // @ts-expect-error
            addDependenciesToMap(CurrentClass.ajsDependencies);
        }

        return class ProviderWrapper extends BaseClass {
            /**
             * Performs instanceof check against "pure" class form. Works fine when "wrapped"
             * class is passed since it is wrapped on top of the original class anyway.
             * Falls back to default behavior when called by descendant classes. Later is needed
             * cause static methods and properties ARE inherited via ES6 class syntax.
             */
            public static [Symbol.hasInstance](object: any): boolean {
                return this === ProviderWrapper ?
                    object instanceof BaseClass :
                    super[Symbol.hasInstance](object);
            }

            protected static getNgDependency(token: TDependencyToken): T {
                return getNgDependency(token);
            }

            /**
             * For backwards compatibility with classes extending AjsDependency. Provides AJS
             * dependencies for static methods of the class.
             */
            // eslint-disable-next-line no-underscore-dangle
            protected static getAjsDependency_(token: TDependencyToken): T {
                return getNgDependency(token);
            }

            protected getNgDependency(token: TDependencyToken): T {
                return getNgDependency(token);
            }

            /**
             * For backwards compatibility with classes extending AjsDependency.
             */
            // eslint-disable-next-line no-underscore-dangle
            protected getAjsDependency_(token: TDependencyToken): T {
                return getNgDependency(token);
            }
        };
    };
}

/**
 * Returns a factoryProvider with the injectNgDependencies dependencies injected into the provider.
 * This is no longer exported and exposed since createNgDependencyProviders is the preferred way of
 * registering providers as it includes checks for MessageItems.
 */
function withInjectedDependencies(
    provider: Constructor<NgDependencyInjected>,
    token?: TDependencyToken | string,
): FactoryProvider {
    return {
        provide: token || provider,
        useFactory: injectNgDependencies(provider),
        deps: [Injector, '$injector'],
    };
}

type TNgConstructor = Constructor<NgDependencyInjected>;

/**
 * Given an array of providers or provider tuples, return an array of FactoryProviders.
 * MessageItems need to be registered with a string token via a tuple, (because of how MessageItems
 * are dynamically injected with string tokens generated from the objectType in the
 * messageMapService) so there's a check which throws an error if a MessageItem is provided without
 * a string token.
 *
 * @example
 * const providers = createNgDependencyProviders([
 *     Pool,
 *     PoolGroup,
 *     [ServerConfigItem, 'ServerConfigItem'],
 * ])
 *
 * @NgModule({
 *     providers,
 * })
 * class SomeModule {}
 */
export function createNgDependencyProviders(
    providers: Array<(TNgConstructor | [TNgConstructor, string])>,
): FactoryProvider[] {
    return providers.map(provider => {
        // provider is a duple, where the 2nd value is the provider token.
        if (Array.isArray(provider)) {
            return withInjectedDependencies(provider[0], provider[1]);
        } else {
            if (provider.prototype instanceof MessageItem) {
                throw new Error(
                    'Called createNgDependencyProviders on a MessageItem without providing a ' +
                    'string token.',
                );
            }

            return withInjectedDependencies(provider);
        }
    });
}
