/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { IIcapNSXDefenderLog } from 'generated-types';
import * as l10n from './icap-nsx-defender-logs.l10n';
import template from './icap-nsx-defender-logs.component.html';
import './icap-nsx-defender-logs.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for icapNsxDefenderLogs component.
 */
export const ICAP_NSX_DEFENDER_LOGS_COMPONENT_TOKEN = 'icapNsxDefenderLogs';

/** Formatted object containing values needed for ICAP NSX Defender Logs. */
export interface IIcapNSXDefenderLogsData {
    score: {
        display: string;
        val: IIcapNSXDefenderLog['score'];
    };
    status_url : {
        display: string;
        val: IIcapNSXDefenderLog['status_url'];
    };
    task_uuid: {
        display: string;
        val: IIcapNSXDefenderLog['task_uuid'];
    };
}

/**
 * @author Satish Pednekar
 * @desc Component representing ICAP NSX Defender log details.
 */
class IcapNsxDefenderLogsComponentController {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Transformed NsxDefender logs data which is displayed
     * in vendor specific section in ICAP logs grid.
     */
    public readonly nsxDefenderLog: IIcapNSXDefenderLogsData;

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: { [str: string]: string }) => void;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }
}

IcapNsxDefenderLogsComponentController.$inject = [
    'l10nService',
];

export const IcapNsxDefenderLogsComponentOptions = {
    bindings: {
        nsxDefenderLog: '<',
        updateSearch: '&',
    },
    controller: IcapNsxDefenderLogsComponentController,
    template,
};
