/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'application-profile';

export const applicationProfileBreadcrumbTitle = `${prefix}.applicationProfileBreadcrumbTitle`;
export const compressionFilterleBreadcrumbTitle = `${prefix}.compressionFilterleBreadcrumbTitle`;

export const ENGLISH = {
    [applicationProfileBreadcrumbTitle]: 'Application Profile',
    [compressionFilterleBreadcrumbTitle]: 'Compression Filter',
};
