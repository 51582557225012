/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-pool-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const enableGslbServicePoolCheckboxLabel = `${prefix}.enableGslbServicePoolCheckboxLabel`;
export const priorityInputPlaceholder = `${prefix}.priorityInputPlaceholder`;
export const minHealthMonitorsUpInputLabel = `${prefix}.minHealthMonitorsUpInputLabel`;
export const minHealthMonitorsUpInputPlaceholder = `${prefix}.minHealthMonitorsUpInputPlaceholder`;
export const loadBalancingAlgorithmDropdownLabel = `${prefix}.loadBalancingAlgorithmDropdownLabel`;
export const fallbackAlgorithmSelectionLabel = `${prefix}.fallbackAlgorithmSelectionLabel`;
export const consistentHashMaskInputLbel = `${prefix}.consistentHashMaskInputLbel`;
export const consistentHashMaskInputPlaceholder = `${prefix}.consistentHashMaskInputPlaceholder`;
export const poolMemberSectionHeader = `${prefix}.poolMemberSectionHeader`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit GSLB Pool',
    [modalTitleNew]: 'Create GSLB Pool',
    [enableGslbServicePoolCheckboxLabel]: 'Enable GSLB Pool',
    [priorityInputPlaceholder]: 'Enter Priority',
    [minHealthMonitorsUpInputLabel]: 'Min Health Monitors to consider server \'up\'',
    [minHealthMonitorsUpInputPlaceholder]: 'Enter Min Health Monitors',
    [loadBalancingAlgorithmDropdownLabel]: 'Pool Members Load Balancing Algorithm',
    [fallbackAlgorithmSelectionLabel]: 'Fallback Algorithm',
    [consistentHashMaskInputLbel]: 'Fallback Load Balancing Algorithm Mask',
    [consistentHashMaskInputPlaceholder]: 'Enter Fallback Load Balancing Algorithm Mask',
    [poolMemberSectionHeader]: 'Pool Member',
};
