/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb-geodb-profile';
const componentName = 'gslb-geodb-profile-entries-grid';
const prefix = `${moduleName}.${componentName}`;

export const gridTitleEntries = `${prefix}.gridTitleEntries`;
export const filenameDropdownLabel = `${prefix}.filenameDropdownLabel`;
export const priorityInputLabel = `${prefix}.priorityInputLabel`;
export const formatDropdownLabel = `${prefix}.formatDropdownLabel`;
export const filenameDropdownPlaceholder = `${prefix}.filenameDropdownPlaceholder`;
export const priorityInputPlaceholder = `${prefix}.priorityInputPlaceholder`;
export const formatDropdownPlaceholder = `${prefix}.formatDropdownPlaceholder`;
export const addEntryButtonLabel = `${prefix}.addEntryButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [gridTitleEntries]: 'GeoDB Entries ({0})',
    [filenameDropdownLabel]: 'File Name',
    [priorityInputLabel]: 'Priority',
    [formatDropdownLabel]: 'Format',
    [filenameDropdownPlaceholder]: 'Select File',
    [priorityInputPlaceholder]: 'Enter Priority',
    [formatDropdownPlaceholder]: 'Select Format',
    [addEntryButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
};
