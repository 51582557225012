/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    scale as d3Scale,
    svg as d3Svg,
    format as d3Format,
    select as d3Select,
    min as d3Min,
    max as d3Max,
} from 'd3v3';

import { formatBytes } from 'ng/shared/utils/math.utils';

const d3 = {
    scale: d3Scale,
    svg: d3Svg,
    format: d3Format,
    select: d3Select,
    min: d3Min,
    max: d3Max,
};

/* chart for e2e timings */
angular.module('aviApp').directive('logChart', ['$filter', function($filter) {
    function link(scope, elem) {
        let popover,
            carat,
            xLabel = '',
            format,
            hideTimeout = false;

        const
            label = 'Log',
            margin = {
                top: 20,
                right: 35,
                bottom: 20,
                left: 50,
            },
            width = $(elem[0]).width() - margin.left - margin.right - 2,
            height = $(elem[0]).height() - margin.top - margin.bottom;

        const
            hideTooltip = function() {
                if (popover) {
                    popover.hide();
                }

                if (carat) {
                    carat.hide();
                }
            },
            removeTooltip = function() {
                if (popover) {
                    popover.remove();
                }

                if (carat) {
                    carat.remove();
                }
            };

        if (scope.type === 'duration') {
            format = function(val) {
                return $filter('ms2str')(val, true);
            };

            xLabel = 'ms';
        } else if (scope.type === 'bytes') {
            format = function(val) {
                return formatBytes(val);
            };

            xLabel = 'bytes';
        } else {
            format = function(val) {
                return val;
            };
        }

        const x = d3.scale.log()
            .rangeRound([0, width]);

        const y = d3.scale.linear()
            .range([height, 0]);

        const xAxis = d3.svg.axis()
            .scale(x)
            .orient('bottom')
            .ticks(7, d3.format(',.0f'))
            .tickSize(0)
            .tickPadding(7);

        const yAxis = d3.svg.axis()
            .scale(y)
            .orient('left')
            .ticks(5)
            .tickSize(4)
            .tickPadding(2);

        const svg = d3.select(elem[0]).append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('class', 'chart')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        scope.$watch('data', function(val) {
            const data = val;
            const step = data[0].value.end - data[0].value.start;

            let min = d3.min(data, function(d) {
                return d.value.start;
            });

            const max = d3.max(data, function(d) {
                return d.value.end;
            });

            if (min === 0) {
                min = Math.max(5, step / 2);
            }

            svg.selectAll('*').remove();

            x.domain([min, max + 1]);

            y.domain([0, d3.max(data, function(d) {
                return d.count;
            }) * 1.1]);

            svg.append('g')
                .attr('class', 'x axis')
                .attr('transform', `translate(-1,${height})`)
                .call(xAxis)
                .append('text')
                .attr('y', 0)
                .attr('x', width + 5)
                .attr('dy', '.25em')
                .style('text-anchor', 'start')
                .text(xLabel);

            svg.append('g')
                .attr('class', 'y axis')
                .call(yAxis)
                .attr('transform', 'translate(-1,0)')
                .append('text')
                .attr('x', -7)
                .attr('y', -7)
                .style('text-anchor', 'end')
                .text('# Logs');

            svg.selectAll('.bar')
                .data(data)
                .enter().append('rect')
                .attr('class', 'bar')
                .attr('x', function(d) {
                    return x(Math.max(min, d.value.start));
                })
                .attr('width', function(d) {
                    return x(d.value.end) - x(Math.max(min, d.value.start));
                })
                .attr('y', function(d) {
                    return y(d.count);
                })
                .attr('height', function(d) {
                    return height - y(d.count);
                })
                .on('mouseover', function(d) {
                    const popoverLeftPos = function(width) {
                        let left;

                        width = width || popover.outerWidth();
                        left = $(self).offset().left + $(self).attr('width') / 2 - width / 2;
                        left = Math.min($(window).width() - width - 15, Math.max(15, left));

                        return left;
                    };

                    const self = this;
                    const popoverHTML =
                        `<div class="tooltip-date">${format(d.value.start)
                        } - ${format(d.value.end)}</div><span class="tooltip-legend"` +
                        ' style="width:100%"><div class="chart-color tooltip-legend-color">' +
                        `</div><span class="legend-value"><div class="value">${d.count
                        } <span class="units">${label}${d.count > 1 ? 's' : ''
                        }</span></div></span></span>`;

                    if (hideTimeout) {
                        clearTimeout(hideTimeout);
                        hideTimeout = false;
                    }

                    if (!popover) {
                        popover = $('<div/>')
                            .addClass('graph-tooltip logBarChart inCallout')
                            .appendTo('body');

                        carat = $('<div/>')
                            .addClass('logBarchartCarat inCallout')
                            .appendTo('body');
                    }

                    popover
                        .html(popoverHTML)
                        .css({
                            left: popoverLeftPos(),
                            bottom: $(window).height() - $(this).offset().top +
                                $(window).scrollTop() + 20,
                        });

                    carat.css({
                        left: $(this).offset().left + $(this).attr('width') / 2 - 10,
                        bottom: $(window).height() - $(this).offset().top +
                            $(window).scrollTop() + 20,
                    });

                    if (!popover.is(':visible')) {
                        popover.show();
                        carat.show();
                    }
                })
                .on('mouseleave', function() {
                    if (hideTimeout) {
                        clearTimeout(hideTimeout);
                        hideTimeout = false;
                    }

                    hideTimeout = setTimeout(function() {
                        hideTooltip();
                    }, 49);
                });
        });

        scope.$on('$destroy', removeTooltip);
    }

    return {
        restrict: 'A',
        scope: {
            data: '=',
            type: '@',
        },
        link,
    };
}]);
