/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import { Injectable } from '@angular/core';

/**
 * @description Service to copy text to the user's clipboard.
 * @author alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class ClipboardService {
    public copy(text: string): void {
        // Added to overwrite copy event to support
        // modern clipboard API methods.
        const copyHandler = (event: ClipboardEvent): void => {
            event.clipboardData.setData('text/plain', text);
            event.preventDefault();
        };

        document.addEventListener('copy', copyHandler);

        const textarea = document.createElement('textarea');
        const { body } = document;

        textarea.value = text;
        textarea.style.opacity = '0';
        body.appendChild(textarea);

        const selection = document.getSelection();
        const priorSelection = selection.rangeCount > 0 ?
            selection.getRangeAt(0) : false;

        textarea.select();
        document.execCommand('copy');
        body.removeChild(textarea);

        if (priorSelection) {
            selection.removeAllRanges();
            selection.addRange(priorSelection);
        }

        document.removeEventListener('copy', copyHandler);
    }
}
