/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/pages/application/virtualservice-analytics.less';
import * as l10n from './VirtualServiceAnalyticsController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('VirtualServiceAnalyticsController', [
    '$scope', '$stateParams', 'ChartConfig', 'myAccount', 'PoolGroupCollection',
    'licenseBasedFeaturesService', 'l10nService',
    function($scope, $stateParams, ChartConfig, myAccount, PoolGroupCollection,
        licenseBasedFeaturesService, l10nService) {
        $scope.myAccount = myAccount;
        $scope.l10nKeys = l10nKeys;

        $scope.ui = {
            hidePools: false,
        };

        l10nService.registerSourceBundles(dictionary);

        const metricSeriesSubscriber = 'vsAnalyticsController';

        const extraVSFields = [
            'config_events',
            'system_events',
            'alerts',
        ];

        const throughtputMetricConfig = {
            name: 'l4_client.avg_bandwidth',
            series: 'l4_client.avg_bandwidth',
        };

        const openConnsMetricConfig = {
            name: 'l4_client.max_open_conns',
            series: 'l4_client.max_open_conns',
        };

        const botManagementMetricConfig = {
            name: 'bot.avg_all_bots',
            series: 'bot.avg_all_bots',
        };

        const dnsPassthroughSeriesId = 'dns_client.avg_udp_passthrough_resp_time';

        $scope.dnsPassthroughSeriesId = dnsPassthroughSeriesId;

        const metrics = [{
            name: 'end_to_end',
        },
        throughtputMetricConfig,
        openConnsMetricConfig,
        {
            name: 'l4_client.avg_complete_conns',
            series: 'l4_client.avg_complete_conns',
            errorsTotal: 'l4_client.pct_connection_errors',
            errorsSeries: [
                'l4_client.avg_lossy_connections',
                'l4_client.avg_errored_connections',
            ],
        }, {
            name: 'l7_client.avg_complete_responses',
            series: 'l7_client.avg_complete_responses',
            errorsTotal: 'l7_client.pct_response_errors',
            errorsSeries: [
                'l7_server.avg_resp_4xx_errors',
                'l7_server.avg_resp_5xx_errors',
                'l7_client.avg_resp_4xx_avi_errors',
                'l7_client.avg_resp_5xx_avi_errors',
            ],
        }, {
            name: 'http_1x_vs_2',
            series: 'l7_client.avg_complete_responses',
            errorsSeries: [
                'l7_client.avg_total_http2_requests', // not an error but plotted like one
            ],
        }, {
            name: 'avg_ssl_connections',
            series: 'l7_client.avg_ssl_connections',
            errorsTotal: 'l7_client.pct_ssl_failed_connections',
            errorsSeries: [
                'l7_client.avg_ssl_handshakes_new',
                'l7_client.avg_ssl_handshakes_reused',
                'l7_client.avg_ssl_handshake_protocol_errors',
                'l7_client.avg_ssl_handshake_network_errors',
                'l7_client.avg_ssl_handshakes_timedout',
            ],
        },
        botManagementMetricConfig,
        ];

        const dnsMetrics = [{
            name: 'end_to_end',
        }, {
            name: dnsPassthroughSeriesId,
            series: dnsPassthroughSeriesId,
        }, {
            name: 'dns_client_avg_complete_queries',
            series: [
                'dns_client.avg_resp_type_a',
                'dns_client.avg_resp_type_aaaa',
                'dns_client.avg_resp_type_ns',
                'dns_client.avg_resp_type_srv',
                'dns_client.avg_resp_type_mx',
                'dns_client.avg_resp_type_other',
            ],
        }, {
            name: 'dns_client.avg_complete_queries',
            series: 'dns_client.avg_complete_queries',
            errorsTotal: 'dns_client.pct_errored_queries',
            errorsSeries: [
                'dns_client.avg_invalid_queries',
                'dns_client.avg_domain_lookup_failures',
                'dns_client.avg_unsupported_queries',
                'dns_client.avg_gslbpool_member_not_available',
                'dns_client.avg_tcp_passthrough_errors',
                'dns_client.avg_udp_passthrough_errors',
            ],
        }, {
            name: 'dns_client_avg_queries_by_type',
            series: [
                'dns_client.avg_tcp_queries',
                'dns_client.avg_udp_queries',
            ],
        },
        throughtputMetricConfig,
        openConnsMetricConfig,
        {
            name: 'l4_client.avg_l4_client_latency',
            series: 'l4_client.avg_l4_client_latency',
        }, {
            name: 'dns_pass_through_or_locally',
            series: [
                'dns_client.avg_udp_passthrough_queries',
                'dns_client.avg_tcp_passthrough_queries',
                'dns_client.avg_local_responses',
            ],
        }];

        const icapMetrics = [{
            name: 'icap_stats.avg_requests',
            series: [
                'icap_stats.avg_passed',
                'icap_stats.avg_modified',
                'icap_stats.avg_blocked',
                'icap_stats.avg_internal_error',
                'icap_stats.avg_server_error',
                'icap_stats.avg_timedout',
            ],
            errorsTotal: 'icap_stats.pct_error',
        }];

        metrics
            .concat(dnsMetrics)
            .concat(icapMetrics)
            .forEach(metric => metric.subscriber = metricSeriesSubscriber);

        //need this ugly part to avoid exceptions on initial load TODO remove @am
        $scope.config = new ChartConfig([{
            id: '',
            series: [],
        }]);

        /**
         * Set to true if end_to_end metric is available.
         * @type {boolean}
         */
        $scope.e2eSeriesAvailable = licenseBasedFeaturesService.isMetricAvailable('end_to_end');

        /**
         * Sets $scope.poolIdsHash to filter out pools already displayed in poolgroups.
         */
        const setPoolIdsHash = function() {
            const { items } = $scope.vs.poolgroups;

            $scope.poolIdsHash = PoolGroupCollection.getPoolIdsHash(items);
        };

        $scope.vs.poolgroups.bind('collectionLoadSuccess', setPoolIdsHash);

        function init() {
            const { vs } = $scope;
            const { pools, poolgroups } = vs;

            pools.load().then(() => {
                pools.subscribe(['health', 'alert', 'runtime']);
                pools.updateItemsVisibility();//set all as visible
            });

            poolgroups.load();

            const mFilterList = []; //to filter out irrelevant metrics depending VS app and net type

            if (vs.isDNS()) {
                //VS has no pool or pool group
                if (!vs.hasPoolRef()) {
                    mFilterList.push(
                        'end_to_end',
                        'l4_client.max_open_conns',
                        'dns_pass_through_or_locally',
                        'l4_client.avg_l4_client_latency',
                        'dns_client.avg_udp_passthrough_resp_time',
                    );
                }

                if (!vs.hasUDPNetProfile()) {
                    mFilterList.push('dns_client.avg_udp_passthrough_resp_time');
                }
            } else {
                if (!vs.isHTTP()) {
                    mFilterList.push(
                        'l7_client.avg_complete_responses',
                        'http_1x_vs_2',
                    );
                }

                if (vs.appType() !== 'ssl') {
                    mFilterList.push('avg_ssl_connections');
                }

                if (vs.isEnhancedVHChild()) {
                    mFilterList.push(
                        'l4_client.avg_bandwidth',
                        'l4_client.avg_complete_conns',
                        'l4_client.max_open_conns',
                    );
                }
            }

            // applicable for DNS and non DNS
            if (!vs.hasTCPProxyNetProfile()) {
                mFilterList.push('end_to_end');
            }

            const fullMetricsList = vs.isDNS() ? dnsMetrics : metrics;
            const filteredMetricsHash = _.invert(mFilterList);

            const selectedMetrics = fullMetricsList
                .filter(({ name }) => !(name in filteredMetricsHash));

            if (vs.hasIcapProfile()) {
                selectedMetrics.push(...icapMetrics);
            }

            if (vs.hasBotPolicy()) {
                selectedMetrics.push(botManagementMetricConfig);
            }

            return vs.collMetricsSubscribe(
                angular.copy(selectedMetrics),
            )
                .then(() => {
                    const config = vs.createChartsConfig();

                    $scope.config = new ChartConfig(config);
                    $scope.config.setActiveCard($stateParams.metric);

                    return vs.startCollMetricsAsync();
                });
        }

        // icons for charts
        $scope.vs.addLoad(extraVSFields.concat());

        init();

        function repaint() {
            $scope.vs.async.stop(true);

            init().then(() => {
                //to redraw the charts
                $scope.$broadcast('repaint');
            });
        }

        $scope.toggleHidePools = () => {
            $scope.ui.hidePools = !$scope.ui.hidePools;
        };

        //reload page after item edit through modal
        $scope.vs.on('itemSaveSuccess', repaint);

        $scope.$on('$destroy', () => {
            const { vs } = $scope;

            vs.async.stop(true);
            vs.removeLoad(extraVSFields);
            vs.unbind('itemSaveSuccess', repaint);

            vs.pools.unSubscribe(['health', 'alert', 'runtime']);

            vs.poolgroups.unbind('collectionLoadSuccess', setPoolIdsHash);
        });
    },
]);
