/**
 * @module UserProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { UserProfileItem } from 'ajs/modules/accounts';
import * as l10n from './user-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for User Profile Modal.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'user-profile-modal',
    templateUrl: './user-profile-modal.component.html',
})
export class UserProfileModalComponent implements OnInit {
    /**
     * UserProfileItem ObjectTypeItem Instance
     */
    @Input()
    public editable: UserProfileItem;

    /**
     * UserProfileItem Object Type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for User Profile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
