/**
 * Module for ICAP Profile related services and components
 * @module IcapProfileModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    IcapNsxDefenderConfigConfigItem,
    IcapProfileCollection,
    IcapProfileItem,
} from '.';

import {
    ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN,
    ICAP_PROFILE_COLLECTION_TOKEN,
    ICAP_PROFILE_ITEM_TOKEN,
} from './icap-profile.tokens';

const icapProfileModule = angular.module('avi/icap-profile');

const factories = [
    {
        dependency: IcapProfileItem,
        name: ICAP_PROFILE_ITEM_TOKEN,
    }, {
        dependency: IcapProfileCollection,
        name: ICAP_PROFILE_COLLECTION_TOKEN,
    }, {
        dependency: IcapNsxDefenderConfigConfigItem,
        name: ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        icapProfileModule,
        'factory',
        name,
        dependency,
    );
});
