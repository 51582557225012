<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="protocol-parser-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ l10nKeys.nameInputLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    class="sel-name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                    regexPattern="objName"
                    required
                />
            </avi-input-container>
            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="description"
                    for="description"
                >
                    {{ l10nKeys.descriptionInputLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    id="description"
                    class="sel-description"
                    [(ngModel)]="editable.config.description"
                    name="description"
                    placeholder="{{ l10nKeys.descriptionInputPlaceholder | vtranslate }}"
                ></textarea>
            </avi-textarea-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="parser_code"
                        for="parser-code"
                        required
                    >
                        {{ l10nKeys.parserCodeInputLabel | vtranslate }}
                    </label>
                    <file-upload-textarea
                        [(ngModel)]="editable.config.parser_code"
                        name="parser-code"
                        placeholder="{{ l10nKeys.fileUploadInputPlaceholder | vtranslate }}"
                        required
                    ></file-upload-textarea>
                </div>
            </div>
        </div>
    </form>
</full-modal-config>
