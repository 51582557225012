/**
 * @module CoreModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { I18nService } from '@vmw/ngx-vip';

import { MyAccountService } from 'ng/modules/core/services/my-account.service';

export const i18nDateFormat = 'short';

/**
 * @description Service for transforming given date.
 * @author SongJiang
 */
@Pipe({
    name: 'i18nDate',
})
export class I18nDatePipe implements PipeTransform {
    constructor(
        private readonly myAccount: MyAccountService,
        private readonly i18nService: I18nService,
    ) {}

    public transform(
        input: Date | string | number,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null;
    public transform(
        input: null | undefined,
        format?: string,
        timezone?: string,
        locale?: string,
    ): null;
    public transform(
        input: string,
        format: string = i18nDateFormat,
    ): string {
        const timezone = this.myAccount.useUtcTime ? 'UTC' : null;

        return this.i18nService.formatDate(input, format, null, timezone);
    }
}
