/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'imaps-health-monitor.config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const userInputLabel = `${prefix}.userInputLabel`;
export const sectionTitleForImaps = `${prefix}.sectionTitleForImaps`;
export const sectionTitleForImap = `${prefix}.sectionTitleForImap`;
export const authenticationTitle = `${prefix}.authenticationTitle`;
export const directoryInformationLabel = `${prefix}.directoryInformationLabel`;
export const folderNameLabel = `${prefix}.folderNameLabel`;

export const ENGLISH = {
    [sectionTitle]: 'IMAPS',
    [userInputLabel]: 'User Input',
    [sectionTitleForImaps]: 'IMAPS',
    [sectionTitleForImap]: 'IMAP',
    [authenticationTitle]: 'Authentication',
    [directoryInformationLabel]: 'Directory information',
    [folderNameLabel]: 'Folder name',
};
