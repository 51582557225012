/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'vh-match-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const rulesLabel = `${prefix}.rulesLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Host',
    [modalTitleNew]: 'New Host',
    [rulesLabel]: 'Rules ({0})',
};
