/**************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ServiceEngineItem } from 'ng/modules/items/factories/service-engine.item.factory';

const allDataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'health',
            'runtime',
            'alert',
            'faults',
        ],
    },
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'se_if.avg_bandwidth',
            'se_stats.avg_cpu_usage',
            'se_stats.max_num_vs',
            'se_stats.avg_mem_usage',
            'se_stats.avg_disk1_usage',
            'se_stats.avg_packet_buffer_usage',
            'se_stats.avg_persistent_table_usage',
            'se_stats.avg_ssl_session_cache_usage',
            'se_stats.pct_syn_cache_usage',
            'se_stats.avg_connections',
            'se_stats.pct_connections_dropped',
            'se_if.avg_rx_bytes',
            'se_if.avg_rx_pkts',
            'se_stats.avg_rx_bytes_dropped',
            'se_stats.avg_rx_pkts_dropped',
            'se_stats.avg_tx_bandwidth',
            'se_if.avg_tx_bytes',
            'se_if.avg_tx_pkts',
            'se_stats.avg_connection_mem_usage',
            'se_stats.avg_dynamic_mem_usage',
        ],
        dependsOn: 'config',
    },
};

/**
 * @description Service Engine Collection Factory
 * @author Vinay Jadhav
 */
export class ServiceEngineCollection extends Collection {
    public static ngDependencies = [ServiceEngineItem];

    constructor(args = {}) {
        const extendedArguments = {
            objectName: 'serviceengine-inventory',
            windowElement: 'lazy-load',
            isStatic: false,
            defaultDataSources: 'inventory',
            allDataSources,
            ...args,
        };

        super(extendedArguments);
        this.itemClass_ = this.getNgDependency(ServiceEngineItem);
    }

    public isCreatable(): boolean {
        return false;
    }
}

export type TServiceEngineCollection = typeof ServiceEngineCollection;
