/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterPollingProgressBarModule
 */

import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { IHttpResponse } from 'angular';
import { LocationService } from 'ng/modules/core/services/location.service';

import {
    Observable,
    Subject,
} from 'rxjs';

import {
    Inject,
    Injectable,
} from '@angular/core';

import { UpgradeNodeCollection } from 'ajs/modules/upgrade/factories';
import { nodeTypesHash } from 'ng/modules/update/update.types';
import { UPGRADE_STATE_POLLING_INTERVAL } from 'ng/modules/update/services/update.service';
import { AsyncFactory } from 'ajs/modules/core/factories/async-factory/async.factory';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories';

import {
    IClusterOperationalStatus,
    IClusterState,
} from 'generated-types';

const { NODE_CONTROLLER_CLUSTER } = nodeTypesHash;

const CLUSTER_RUNTIME_API = '/api/cluster/runtime';

type TUpgradeNodeCollection = typeof UpgradeNodeCollection;
type THttpWrapper = typeof HttpWrapper;
type TAsyncFactory = typeof AsyncFactory;

/**
 * @desc Service to poll for controller/upgrade state when controller is down.
 *
 * @author Aravindh Nagarajan, Guru Prasad H K, alextsg
 */
@Injectable()
export class ClusterPollingProgressBarService {
    /**
     * Async factory instance to poll for cluster(controller) state.
     */
    private clusterStateAsyncFactory: AsyncFactory;

    /**
     * UpgradeNodeCollection instance - to poll for upgrade state.
     */
    private upgradeNodeCollection: UpgradeNodeCollection = null;

    /**
     * ClusterState object.
     */
    private clusterState: IClusterOperationalStatus = null;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * Subject to subscripe cluster load/up event.
     */
    private readonly clusterLoadComplete = new Subject<void>();

    constructor(
        private readonly authService: Auth,
        @Inject('AsyncFactory')
        private readonly AsyncFactory: TAsyncFactory,
        @Inject('UpgradeNodeCollection')
        private readonly UpgradeNodeCollection: TUpgradeNodeCollection,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
        private readonly locationService: LocationService,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Initiates polling for cluster state and upgrade state.
     */
    public initPolling(): void {
        this.startPollingClusterState();

        if (this.authService.isLoggedIn()) {
            this.startPollingUpgradeState();
        }
    }

    /**
     * Stops polling for the cluster and upgrade state.
     */
    public stopPolling(): void {
        this.stopPollingUpgradeState();
        this.stopPollingClusterState();
    }

    /**
     * Returns true if the controller upgrade process is active.
     */
    public get upgradeActive(): boolean {
        if (this.upgradeNodeCollection && this.upgradeNodeCollection.hasControllerNode()) {
            const controllerNode = this.upgradeNodeCollection.getControllerNode();

            return controllerNode.isUpgradeInProgress();
        }

        return false;
    }

    /**
     * Getter for upgrade progress.
     */
    public get upgradeProgress(): number {
        const controllerNode = this.upgradeNodeCollection?.getControllerNode();

        return controllerNode?.progressPercentage;
    }

    /**
     * Getter for cluster polling progress.
     */
    public get clusterPollingProgress(): number {
        return this.clusterState?.progress;
    }

    /**
     * Return an observable to allow subscriptions to clusterLoadComplete event.
     */
    public get clusterLoadComplete$(): Observable<void> {
        return this.clusterLoadComplete.asObservable();
    }

    /**
     * Clears #50x hash and reload page.
     */
    public reload(): void {
        this.locationService.reloadWithoutHash(true);
    }

    /**
     * Start polling for the upgrade state.
     */
    private startPollingUpgradeState(): void {
        this.upgradeNodeCollection = new this.UpgradeNodeCollection(
            {
                updateInterval: UPGRADE_STATE_POLLING_INTERVAL,
                params: {
                    node_type: NODE_CONTROLLER_CLUSTER,
                },
            },
        );

        this.upgradeNodeCollection.load();
    }

    /**
     * Stop polling for the upgrade state.
     */
    private stopPollingUpgradeState(): void {
        this.upgradeNodeCollection?.destroy();
    }

    /**
     * Begins polling for the cluster state.
     */
    private startPollingClusterState(): void {
        if (this.pollingActive) {
            this.stopPollingClusterState();
        }

        this.clusterStateAsyncFactory = new this.AsyncFactory(
            () => this.loadClusterState(),
            {
                maxSkipped: 1,
                callback: () => this.httpWrapper.cancelAllRequests(),
            },
        );

        this.clusterStateAsyncFactory.start(9999);
    }

    /**
     * Stops polling for the cluster state.
     */
    private stopPollingClusterState(): void {
        if (this.clusterStateAsyncFactory) {
            this.clusterStateAsyncFactory.stop();
        }

        this.httpWrapper.cancelAllRequests();
    }

    /**
     * Returns true if we are currently polling for the cluster state.
     */
    private get pollingActive(): boolean {
        return this.clusterStateAsyncFactory && this.clusterStateAsyncFactory.isActive();
    }

    /**
     * Request for the cluster state.
     */
    private loadClusterState = (): Promise<void> => {
        const requestConfig = {
            method: 'GET' as HttpMethod,
            url: this.authService.isLoggedIn() ? CLUSTER_RUNTIME_API :
                `${CLUSTER_RUNTIME_API}?treat_expired_session_as_unauthenticated=True`,
        };

        return this.httpWrapper.request(requestConfig)
            .then(({ data = {} }: IHttpResponse<IClusterState>) => {
                const { cluster_state: clusterState } = data;

                this.clusterState = clusterState;

                const { state } = clusterState;

                if (state && typeof state === 'string' && !state.indexOf('CLUSTER_UP')) {
                    this.stopPolling();

                    this.clusterLoadComplete.next();
                }
            });
    };
}
