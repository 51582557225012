/**
 * @module accountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource, ITenantConfiguration } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { merge } from 'angular';
import { isObject } from 'underscore';
import { TenantModalComponent } from 'ng/modules/accounts';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';

import {
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/openstack-keystone/openstack-keystone.service';

import { TENANT_ITEM_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

const allDataSources = {
    inventory: {
        source: 'ListCollDataSource',
        transformer: 'TenantInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
    'keystone-auth': {
        source: 'KeystoneIsEnabledCollDataSource',
        transformer: 'RevisedDataTransformer',
        transport: 'KeystoneIsEnabledDataTransport',
        fields: ['keystone'],
        dependsOn: 'config',
    },
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'tenant_stats.avg_num_backend_servers',
            'tenant_stats.avg_num_se_cores',
            'tenant_stats.max_total_se_throughput',
            'tenant_stats.sum_total_vs_client_bytes',
            'tenant_stats.max_num_ses',
            'tenant_stats.avg_num_ses',
            'tenant_stats.max_num_sockets',
            'tenant_stats.avg_num_sockets',
        ],
        dependsOn: 'config',
    },
};

const defaultDataSources = [
    'inventory',
    'keystone-auth',
];

export type TTenantCollection = typeof TenantCollection;

/**
 * @description Tenant Collection.
 * @author Suraj Kumar
 */
export class TenantCollection extends Collection {
    public static ajsDependencies = [
        TENANT_ITEM_TOKEN,
        'defaultValues',
        OPENSTACK_KEYSTONE_SERVICE_TOKEN,
    ];

    private readonly openstackKeystoneService: OpenstackKeystoneService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'tenant-inventory',
            windowElement: TenantModalComponent,
            allDataSources,
            isStatic: false,
            defaultDataSources,
            permissionName: AviPermissionResource.PERMISSION_TENANT,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(TENANT_ITEM_TOKEN);
        this.openstackKeystoneService = this.getAjsDependency_(OPENSTACK_KEYSTONE_SERVICE_TOKEN);
    }

    /**
     * @override
     */
    public isCreatable(): boolean {
        // Enforce to disable editing remote users if keystone auth is enabled.
        if (this.openstackKeystoneService.keystoneAuthEnabled) {
            return false;
        }

        return super.isCreatable();
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public getDefaultItemConfig_(): ITenantConfiguration {
        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = merge({
            config_settings: defaultValues.getDefaultItemConfigByType('tenantconfiguration'),
        }, this.serverDefaultsOverride_);

        // Then override it with customizable defaults.
        if (isObject(this.defaults_)) {
            merge(defaultConfig, this.defaults_);
        }

        return defaultConfig;
    }
}
