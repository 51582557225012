/**
 * @module accountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { Role } from 'object-types';
import { RoleModalComponent } from 'ng/modules/accounts/components/role-modal';
import { ROLE_ITEM_TOKEN } from '../../accounts.tokens';

const allDataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'RoleListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * @description Role collection.
 * @author Nisar Nadaf
 */
export class RoleCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'role',
            objectType: Role,
            windowElement: RoleModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ROLE,
            allDataSources,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ROLE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            name: '',
        };
    }
}

RoleCollection.ajsDependencies = [
    ROLE_ITEM_TOKEN,
];
