/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import './avi-ajs-confirm.component.less';
import template from './avi-ajs-confirm.component.html';
import * as l10n from './avi-ajs-confirm.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Component that serves as a generic dialog window when users need to be prompted whether they
 *     confirm to proceed with the current operation, with header and body message to be customized.
 *     Different from the ts component avi-confirmation, which provides the dialog prompt
 *     functionality in a broader manner by taking ng-content to give full customization, this
 *     component is powered by avi-confirmation yet with restriction of only allowing header and
 *     message texts to be determined by the parent.
 *
 * @author alextsg, Akul Aggarwal, Zhiqian Liu
 */
class AviAjsConfirmController {
    /**
     * Close modal through AviModal.
     */
    public closeModal: () => void;

    /**
     * Called if user clicks Cancel or closes Modal.
     */
    public onCancel: () => void;

    /**
     * Called if user clicks Confirm.
     */
    public onConfirm: () => void;

    /**
     * Message to be displayed for confirmation.
     */
    public message: string;

    /**
     * Message to be displayed in header.
     */
    public headerText?: string;

    /**
     * Inform if modal is a Warning modal.
     */
    public isWarning?: boolean = false;

    /**
     * Text for Cancel button.
     */
    public cancelButtonText?: string;

    /**
     * Text for OK/Confirm button.
     */
    public confirmButtonText?: string;

    /**
     * Confirmation text.
     */
    public confirmationText?: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
        this.confirmationText = l10nService.getMessage(l10nKeys.confirmationText);
        this.cancelButtonText = l10nService.getMessage(l10nKeys.defaultCancelButtonText);
        this.confirmButtonText = l10nService.getMessage(l10nKeys.defaultConfirmButtonText);
        this.headerText = l10nService.getMessage(l10nKeys.headerText);
    }

    /**
     * Confirm the behavior and close the modal when the Confirm button is clicked.
     */
    public confirm(): void {
        this.onConfirm();
        this.closeModal();
    }

    /**
     * Cancel the behavior and close the modal when the Cancel button is clicked.
     */
    public cancel(): void {
        this.onCancel();
        this.closeModal();
    }
}

AviAjsConfirmController.$inject = ['l10nService'];

export const aviAjsConfirmOptions = {
    bindings: {
        closeModal: '&',
        headerText: '<?',
        isWarning: '<?',
        message: '@',
        onCancel: '&',
        onConfirm: '&',
        cancelButtonText: '<?',
        confirmButtonText: '<?',
        confirmationText: '<?',
    },
    controller: AviAjsConfirmController,
    template,
};
