<div class="cinematic-header">
    <div class="cinematic-header__main">
        <h2
            *ngIf="headerText"
            class="cinematic-header__header-text"
        >
            {{ headerText }}
        </h2>
        <ng-content select="cinematic-header_custom-header-section"></ng-content>
        <cds-icon
            class="cinematic-header__close-button"
            shape="times"
            size="lg"
            (click)="handleClose()"
        ></cds-icon>
    </div>
    <ng-content></ng-content>
</div>
