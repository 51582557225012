/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module TooltipModule
 */

import { ConnectedPosition } from '@angular/cdk/overlay';

/**
 * HoverOverlayPosition defines corner side-aligned relations between the original element and its
 * overlay, different from the definition of TooltipPosition that holds corner point-connected
 * relations.
 */
export enum HoverOverlayPosition {
    TOP_POSITION = 'TOP_POSITION', // top center
    BOTTOM_POSITION = 'BOTTOM_POSITION', // bottom center
    BOTTOM_RIGHT_ALIGNED_POSITION = 'BOTTOM_RIGHT_ALIGNED_POSITION',
    BOTTOM_LEFT_ALIGNED_POSITION = 'BOTTOM_LEFT_ALIGNED_POSITION',
    TOP_RIGHT_ALIGNED_POSITION = 'TOP_RIGHT_ALIGNED_POSITION',
    TOP_LEFT_ALIGNED_POSITION = 'TOP_LEFT_ALIGNED_POSITION',
}

const CENTER = 'center';
const TOP = 'top';
const BOTTOM = 'bottom';
const START = 'start';
const END = 'end';

export const HOVER_OVERLAY_TOP_POSITION: ConnectedPosition = {
    offsetY: -5,
    originX: CENTER,
    originY: TOP,
    overlayX: CENTER,
    overlayY: BOTTOM,
};

export const HOVER_OVERLAY_BOTTOM_POSITION: ConnectedPosition = {
    offsetY: 5,
    originX: CENTER,
    originY: BOTTOM,
    overlayX: CENTER,
    overlayY: TOP,
};

export const HOVER_OVERLAY_TOP_LEFT_ALIGNED_POSITION: ConnectedPosition = {
    offsetY: -5,
    originX: START,
    originY: TOP,
    overlayX: START,
    overlayY: BOTTOM,
};

export const HOVER_OVERLAY_BOTTOM_LEFT_ALIGNED_POSITION: ConnectedPosition = {
    offsetY: 5,
    originX: START,
    originY: BOTTOM,
    overlayX: START,
    overlayY: TOP,
};

export const HOVER_OVERLAY_TOP_RIGHT_ALIGNED_POSITION: ConnectedPosition = {
    offsetY: -5,
    originX: END,
    originY: TOP,
    overlayX: END,
    overlayY: BOTTOM,
};

export const HOVER_OVERLAY_BOTTOM_RIGHT_ALIGNED_POSITION: ConnectedPosition = {
    offsetY: 5,
    originX: END,
    originY: BOTTOM,
    overlayX: END,
    overlayY: TOP,
};

/**
 * Map built using ConnectedPostion. Positions are usually used as position priorities passed to
 * avi-tooltip, to HoverOverlayPostions, which are passed to AviHoverOverlay component to decide its
 * real position.
 */
export const hoverOverlayPositionMap = new Map([
    [HOVER_OVERLAY_TOP_POSITION, HoverOverlayPosition.TOP_POSITION],
    [HOVER_OVERLAY_BOTTOM_POSITION, HoverOverlayPosition.BOTTOM_POSITION],
    [HOVER_OVERLAY_TOP_LEFT_ALIGNED_POSITION, HoverOverlayPosition.TOP_LEFT_ALIGNED_POSITION],
    [HOVER_OVERLAY_BOTTOM_LEFT_ALIGNED_POSITION, HoverOverlayPosition.BOTTOM_LEFT_ALIGNED_POSITION],
    [HOVER_OVERLAY_TOP_RIGHT_ALIGNED_POSITION, HoverOverlayPosition.TOP_RIGHT_ALIGNED_POSITION],
    [
        HOVER_OVERLAY_BOTTOM_RIGHT_ALIGNED_POSITION,
        HoverOverlayPosition.BOTTOM_RIGHT_ALIGNED_POSITION,
    ],
]);
