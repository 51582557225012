/**
 * @module SystemUpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './system-about-card.l10n';
import './system-about-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component containing information about the system.
 * @author alextsg, Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'system-about-card',
    templateUrl: './system-about-card.component.html',
})
export class SystemAboutCardComponent {
    /**
     * Input for showing avi-card footer.
     */
    @Input()
    public readonly showFooter: boolean = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
