/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'ftp-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleFtp = `${prefix}.sectionTitleFtp`;
export const sectionTitleFtps = `${prefix}.sectionTitleFtps`;
export const fileInformationLabel = `${prefix}.fileInformationLabel`;
export const fileNameLabel = `${prefix}.fileNameLabel`;
export const fileNamePlaceholder = `${prefix}.fileNamePlaceholder`;
export const transferModeLabel = `${prefix}.transferModeLabel`;
export const transferModePlaceholder = `${prefix}.transferModePlaceholder`;
export const tlsSniServerPlaceholder = `${prefix}.tlsSniServerPlaceholder`;

export const ENGLISH = {
    [sectionTitleFtp]: 'FTP',
    [sectionTitleFtps]: 'FTPS',
    [fileInformationLabel]: 'File Information',
    [fileNameLabel]: 'File Name',
    [fileNamePlaceholder]: 'Enter File Name',
    [transferModeLabel]: 'Transfer Mode',
    [transferModePlaceholder]: 'Select Transfer Mode',
    [tlsSniServerPlaceholder]: 'Enter TLS SNI Server Name',
};
