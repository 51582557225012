/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    IInfobloxCredentialsConfig,
    IInfobloxUsableSubnetHash,
    InfobloxSubnetConfigItem,
    IpamDnsInfobloxProfileConfigItem,
} from 'ajs/modules/ipam/factories';

import { IpamDnsType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { Observable } from 'rxjs';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import { IEditCredentialsConfig } from
    'ng/modules/avi-forms/components/credentials-verification/credentials-verification.component';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { TStringRow } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './ipam-dns-infoblox-profile-config.l10n';
import { IpamDnsInfobloxCredentialsDialogComponent } from './ipam-dns-infoblox-credentials-dialog';
import './ipam-dns-infoblox-profile-config.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description IpamDnsInfobloxProfile configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-infoblox-profile-config',
    templateUrl: './ipam-dns-infoblox-profile-config.component.html',
})
export class IpamDnsInfobloxProfileConfigComponent implements OnInit {
    /**
     * IpamDnsInfobloxProfile ConfigItem instance.
     */
    @Input()
    public editable: IpamDnsInfobloxProfileConfigItem;

    /**
     * Type of IpamDnsProviderProfile (IPAMDNS_TYPE_INFOBLOX / IPAMDNS_TYPE_INFOBLOX_DNS).
     */
    @Input()
    public type: IpamDnsType;

    /**
     * ObjectType of IpamDnsInfobloxProfile.
     */
    public objectType: string;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * True if ipam profile is being edited.
     */
    public readonly isEditing: boolean;

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editInfobloxProfileCredentialsConfig: IEditCredentialsConfig;

    /**
     * True if credentials is set by the user.
     */
    public connected = false;

    /**
     * Hash of available usable subnets and their type (v4/v6).
     */
    public availableSubnetHash: IInfobloxUsableSubnetHash;

    /**
     * List of available domains from configured Infoblox.
     */
    public domainList: string[] = [];

    /**
     * Flag to invalidate IPAM/DNS Profile modal in case of Inflobox type
     * if usable subnet is invalid.
     */
    public isUsableSubnetInvalid = false;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    constructor(
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        private readonly ipamProfileId: string,
    ) {
        l10nService.registerSourceBundles(ENGLISH);

        this.isEditing = Boolean(ipamProfileId);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.setEditInfobloxProfileCredentialsConfig();

        if (this.isEditing) {
            this.connected = true;

            if (this.type === IpamDnsType.IPAMDNS_TYPE_INFOBLOX) {
                this.setAvailableSubnets();
            } else if (this.type === IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS) {
                this.setAvailableDomains();
            }
        }
    }

    /**
     * Adds a usableSubnet row.
     */
    public addUsableSubnet(): void {
        this.editable.addUsableSubnet();
        this.setIsUsableSubnetInvalid(true);
    }

    /**
     * Remove usableSubnet from editable.
     * Invalidate Form in case we have any empty usable subnet row in the grid.
     */
    public removeUsableSubnet(usableSubnet: InfobloxSubnetConfigItem): void {
        this.editable.removeUsableSubnetByMessageItem(usableSubnet);

        const { usable_alloc_subnets: usableAllocSubnets } = this.editable.config;

        if (usableAllocSubnets?.count) {
            const isUsableAllocSubnetInvalid = usableAllocSubnets.config.some(
                (usableAllocSubnet: InfobloxSubnetConfigItem) => {
                    const {
                        subnet,
                        subnet6,
                    } = usableAllocSubnet.config;

                    return !subnet.subnet && !subnet6.subnet;
                },
            );

            this.setIsUsableSubnetInvalid(isUsableAllocSubnetInvalid);
        } else {
            this.setIsUsableSubnetInvalid(false);
        }
    }

    /**
     * Adds a usable domain row.
     */
    public addUsableDomain(): void {
        this.editable.addUsableDomain();
    }

    /**
     * Removes usable domain from editable.
     */
    public removeUsableDomain(domain: TStringRow): void {
        this.editable.removeUsableDomain(domain);
    }

    /**
     * Set isUsableSubnetInvalid falg to invalidate the form based on the flag value passed.
     */
    public setIsUsableSubnetInvalid(isInvalid = false): void {
        this.isUsableSubnetInvalid = isInvalid;
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing
     * credentials.
     */
    private setEditInfobloxProfileCredentialsConfig(): void {
        const { config } = this.editable;

        const isInfobloxIpamProfile = this.type === IpamDnsType.IPAMDNS_TYPE_INFOBLOX;

        const editCredentialsConfig: IInfobloxCredentialsConfig = {
            password: '',
            ip_address: config.ip_address.address,
            ip6_address: config.ip6_address.address,
            username: config.username,
            type: this.type,
        };

        if (isInfobloxIpamProfile) {
            editCredentialsConfig.network_view = config.network_view;
        } else {
            editCredentialsConfig.dns_view = config.dns_view;
        }

        this.editInfobloxProfileCredentialsConfig = {
            editCredentialsDialog: IpamDnsInfobloxCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                isEditing: this.isEditing,
                type: this.type,
                submit$: new Observable<void>(subscriber => {
                    this.editable.verifyInfobloxLoginCredentials(editCredentialsConfig)
                        .then(() => {
                            subscriber.next();
                            subscriber.complete();

                            this.editable.setInfobloxLoginCredentials(editCredentialsConfig);
                            this.setEditInfobloxProfileCredentialsConfig();

                            this.editable.clearUsableSubnetList();
                            this.editable.clearUsableDomainList();
                            this.connected = true;

                            return isInfobloxIpamProfile ? this.setAvailableSubnets() :
                                this.setAvailableDomains();
                        }).catch(error => subscriber.error(error));
                }),
                onCancel: () => {
                    this.editable.cancelInfobloxVerifyCredentials();

                    this.setEditInfobloxProfileCredentialsConfig();
                },
            },
        };
    }

    /**
     * Sets available usable subnet hash.
     */
    private async setAvailableSubnets(): Promise<void> {
        this.availableSubnetHash = {
            v4: [],
            v6: [],
        };

        try {
            this.availableSubnetHash = await
            this.editable.getAvailableSubnets(this.ipamProfileId);
        } catch (e: any) {
            Promise.reject(e);
        }
    }

    /**
     * Sets available domain list.
     */
    private async setAvailableDomains(): Promise<void> {
        this.domainList = [];

        try {
            this.domainList = await this.editable.getDomainList(this.ipamProfileId);
        } catch (e: any) {
            Promise.reject(e);
        }
    }
}
