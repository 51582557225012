/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'dns-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleDns = `${prefix}.sectionTitleDns`;
export const requestLabel = `${prefix}.requestLabel`;
export const responseLabel = `${prefix}.responseLabel`;
export const requestNameInputLabel = `${prefix}.requestNameInputLabel`;
export const requestNameInputPlaceholder = `${prefix}.requestNameInputPlaceholder`;
export const responseMatchesInputLabel = `${prefix}.responseMatchesInputLabel`;
export const responseCodeInputLabel = `${prefix}.responseCodeInputLabel`;
export const responseStringInputLabel = `${prefix}.responseStringInputLabel`;
export const responseStringInputPlaceholder = `${prefix}.responseStringInputPlaceholder`;
export const recordTypeInputLabel = `${prefix}.recordTypeInputLabel`;

export const ENGLISH = {
    [sectionTitleDns]: 'DNS',
    [requestLabel]: 'Request',
    [responseLabel]: 'Response',
    [requestNameInputLabel]: 'Request Name',
    [requestNameInputPlaceholder]: 'Enter Request Name',
    [responseMatchesInputLabel]: 'Response Matches',
    [responseCodeInputLabel]: 'Response Code',
    [responseStringInputLabel]: 'Response String',
    [responseStringInputPlaceholder]: 'Enter Response String',
    [recordTypeInputLabel]: 'Record Type',
};
