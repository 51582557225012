/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { GslbPool } from 'object-types';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import './gslb-service-pool-members-grid.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-pool-members-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *      Grid component for GSLB service Pool Members, display all the PoolMembers configured.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-pool-members-grid',
    templateUrl: './gslb-service-pool-members-grid.component.html',
})
export class GslbServicePoolMembersGridComponent {
    @Input()
    public poolMembers: RepeatedMessageItem<GslbPoolMemberConfigItem>;

    /**
     * Emit when the user add Pool Member.
     */
    @Output()
    public onAddPoolMember = new EventEmitter<void>();

    /**
     * Emit when the user edit Pool Member.
     */
    @Output()
    public onEditPoolMember = new EventEmitter<GslbPoolMemberConfigItem>();

    /**
     * Emit when the user delete/deleteAll pool member(s).
     */
    @Output()
    public onDeletePoolMember = new EventEmitter<GslbPoolMemberConfigItem>();

    /**
     * TemplateRef for pool enabled.
     */
    @ViewChild('poolMemberEnabledTemplateRef')
    public poolMemberEnabledTemplateRef: TemplateRef<HTMLElement>;

    public readonly objectType = GslbPool;

    public poolMembersGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.poolMembersGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.privateAddressColumnHeader),
                    id: 'private-address',
                    transform: (poolMember: GslbPoolMemberConfigItem) => {
                        const { vs_uuid: vsUuid, fqdn, ip } = poolMember.config;

                        return vsUuid || fqdn || ip?.address || '-';
                    },
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.typeLabel),
                    id: 'type',
                    transform: (poolMember: GslbPoolMemberConfigItem) => {
                        const configuredByType = poolMember.isConfiguredByTypeIp ?
                            globalL10nKeys.ipAddressLabel : globalL10nKeys.virtualServicesLabel;

                        return l10nService.getMessage(configuredByType);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.publicIpLabel),
                    id: 'public-ip',
                    transform: (
                        poolMember: GslbPoolMemberConfigItem,
                    ) => poolMember.config.public_ip.config.ip.address || '-',
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.ratioLabel),
                    id: 'ratio',
                    transform: (
                        poolMember: GslbPoolMemberConfigItem,
                    ) => poolMember.config.ratio || '-',
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.enableBtnLabel),
                    id: 'enable',
                    templateRef: this.poolMemberEnabledTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (poolMembers: GslbPoolMemberConfigItem[]) => {
                    poolMembers.forEach((poolMember: GslbPoolMemberConfigItem) =>
                        this.deletePoolMember(poolMember));
                },
            }],
            singleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (poolMember: GslbPoolMemberConfigItem) =>
                        this.editPoolMember(poolMember),
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (poolMember: GslbPoolMemberConfigItem) =>
                        this.deletePoolMember(poolMember),
                },
            ],
            layout: {
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * Handle Add Pool Member operation. Emit onAddPoolMember EventEmitter.
     */
    public addPoolMember(): void {
        this.onAddPoolMember.emit();
    }

    /**
     * Handle edit Pool Member operation. Emit onEditPoolMember EventEmitter.
     */
    private editPoolMember(poolMember: GslbPoolMemberConfigItem): void {
        this.onEditPoolMember.emit(poolMember);
    }

    /**
     * Handle delete Pool Member(s) operation. Emit onDeletePoolMember EventEmitter.
     */
    private deletePoolMember(poolMember: GslbPoolMemberConfigItem): void {
        this.onDeletePoolMember.emit(poolMember);
    }
}
