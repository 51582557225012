/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as globalL10n from 'global-l10n';
import * as l10n from './PoolServerListController.l10n';
import '../../../less/pages/application/pool-server-summary.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

angular.module('aviApp').controller('PoolServerListController', [
'$scope', 'ServerCollection', 'Server', '$templateCache',
'getGridMetricFieldConfig', 'l10nService',
function($scope, ServerCollection, Server, $templateCache, getGridMetricFieldConfig, l10nService) {
    $scope.l10nKeys = l10nKeys;
    $scope.globalL10nKeys = globalL10nKeys;
    l10nService.registerSourceBundles(dictionary);

    const gridMetricFields = [
        {
            require: 'l7_server.avg_complete_responses',
            title: 'RPS',
            fullTitle: l10nService.getMessage(l10nKeys.requestsPerSecondFullTitle),
            visibility: 'd',
        }, {
            require: 'l4_server.max_open_conns',
            visibility: 'd',
        }, {
            require: 'l4_server.avg_bandwidth',
            visibility: 'd',
        },
        'l4_server.avg_total_rtt',
        'l7_server.avg_error_responses',
        'l7_server.pct_response_errors',
    ];

    const serverCollection = new ServerCollection({ poolId: $scope.Pool.id });

    /**
     * Creates a hash from array of {@link Server} items. Used by {@link collectionGrid}
     * multiple actions.
     * @param {Server[]} servers
     * @returns {{string:boolean}} Where string is a {@link Server.id}.
     * @inner
     */
    function selectedServersHash(servers) {
        return _.reduce(servers, function(base, server) {
            base[server.id] = true;

            return base;
        }, {});
    }

    /**
     * Returns the string of the format '<port> (<label>)'.
     * @param {number} port - Port number.
     * @param {string} label - Label to be appended to the port number.
     * @returns {string}
     */
    const getServerPortString = (port, label) => `${port} (${label})`;

    /**
     * Returns a comma-separated string of ports and labels.
     * @param {Server} server - Server item.
     * @returns {string}
     */
    const getServerPortsList = server => {
        const portsList = [];
        const { horizon_internal_port_info: horizonPortInfo } = server.data;
        const config = server.getConfig();
        const { port, default_server_port: defaultServerPort } = config;

        if (port) {
            portsList.push(String(port));
        } else {
            const defaultPortLabel = l10nService.getMessage(l10nKeys.gridDefaultPortLabel);

            portsList.push(getServerPortString(defaultServerPort, defaultPortLabel));
        }

        if (horizonPortInfo) {
            const {
                horizon_fe_blast_port: blastPort,
                horizon_fe_pcoip_port: pcoipPort,
                horizon_fe_l7_port: tunnelPort,
            } = horizonPortInfo;

            const blastPortLabel = l10nService.getMessage(l10nKeys.blastPortLabel);
            const pcoipPortLabel = l10nService.getMessage(l10nKeys.pcoipPortLabel);
            const tunnelPortLabel = l10nService.getMessage(l10nKeys.tunnelPortLabel);

            const portsMap = new Map([
                [blastPort, getServerPortString(blastPort, blastPortLabel)],
                [pcoipPort, getServerPortString(pcoipPort, pcoipPortLabel)],
                [tunnelPort, getServerPortString(tunnelPort, tunnelPortLabel)],
            ]);

            const ports = [];

            if (blastPort) {
                ports.push(blastPort);
            }

            if (pcoipPort) {
                ports.push(pcoipPort);
            }

            if (tunnelPort) {
                ports.push(tunnelPort);
            }

            ports
                .sort((a, b) => a - b)
                .forEach(port => portsList.push(portsMap.get(port)));
        }

        return portsList.join(', ');
    };

    $scope.serversGrid = buildGridConfig();
    $scope.serversGrid.props = {
        l10nKeys,
        getServerPortsList,
    };
    $scope.gracefulDisableTimeout = 1;

    /**
     * Returns fields for the gridConfig based on the ipGroupOrEPG boolean.
     * @param  {boolean} ipGroupOrEPG - True if server created by IP Group or EPG, false otherwise.
     * @return {Object[]} Array of fields to display in grid.
     */
    function getFields(ipGroupOrEPG) {
        const fields = [{
            name: 'hostname',
            title: l10nService.getMessage(l10nKeys.columnTitleServerName),
            // ng-if or ui-sref gets evaluated before id assignment
            template:
                `<a ui-sref="^.server-detail.analytics({serverId: '{{ row.id }}'})">
                    {{ row.getConfig()['hostname'] }}
                </a>`,
            visibility: 'm',
        }, {
            name: 'data.config.ip.addr',
            title: l10nService.getMessage(globalL10nKeys.ipAddressLabel),
            visibility: 'm',
        }, {
            name: 'description',
            title: l10nService.getMessage(globalL10nKeys.descriptionLabel),
            template: '{{ row.getConfig().description }}',
        }, {
            require: 'health,runtime',
            name: 'health',
            title: l10nService.getMessage(globalL10nKeys.healthLabel),
            template: '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
            visibility: 'm',
        }];

        if (!ipGroupOrEPG) {
            fields.unshift({
                name: 'port',
                title: l10nService.getMessage(globalL10nKeys.portLabel),
                template: `
                    <div
                        class="hide-text-overflow"
                        title="{{ config.props.getServerPortsList(row) }}"
                    >
                        {{ config.props.getServerPortsList(row) }}
                    </div>
                `,
                visibility: 'm',
            });

            fields.push({
                name: 'data.config.ratio',
                title: l10nService.getMessage(l10nKeys.columnTitleRatio),
                visibility: 'm',
            },
            ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)));
        }

        return fields;
    }

    /**
     * Get server action buttons disabled status.
     * @return {boolean} is Server action disabled.
     */
    function isServerActionDisabled() {
        return !$scope.Pool.isEditable() || $scope.Pool.hasNsxSecurityGroup();
    }

    /**
     * Returns the multipleactions array for the grid based on ipGroupOrEPG boolean.
     * @param  {boolean} ipGroupOrEPG - True if server created by IP Group or EPG, false otherwise.
     * @return {Object[]} Array of multipleactions in grid.
     */
    function getMultipleactions(ipGroupOrEPG) {
        const multipleactions = [{
            title: l10nService.getMessage(l10nKeys.actionTitleEnable),
            className: 'sel-enable-server icon-check',
            disabled: servers => !$scope.Pool.isEditable() ||
                _.every(servers, server => server.getConfig()['enabled']),
            do: servers => {
                const
                    selectedHash = selectedServersHash(servers),
                    defaultServerPort = $scope.Pool.getDefaultServerPort(),
                    { servers: serversList } = $scope.Pool.getConfig();

                _.each(serversList.config, server => {
                    const serverId = Server.getServerUuid(server.flattenConfig(),
                        defaultServerPort);

                    if (serverId in selectedHash) {
                        server.config.enabled = true;
                    }
                });

                $scope.Pool.save();

                return true;
            },
        }, {
            title: l10nService.getMessage(l10nKeys.actionTitleDisable),
            className: 'sel-disable-server icon-minus',
            disabled: servers => !$scope.Pool.isEditable() ||
                    _.every(servers, server => !server.getConfig()['enabled']),
            do: servers => {
                const
                    selectedHash = selectedServersHash(servers),
                    defaultServerPort = $scope.Pool.getDefaultServerPort(),
                    config = $scope.Pool.getConfig(),
                    { servers: serverList } = config;

                _.each(serverList.config, server => {
                    const serverId = Server.getServerUuid(server.flattenConfig(),
                        defaultServerPort);

                    if (serverId in selectedHash) {
                        server.config.enabled = false;
                    }
                });

                $scope.Pool.save();

                return true;
            },
        }];

        if (!ipGroupOrEPG) {
            multipleactions.unshift({
                title: l10nService.getMessage(globalL10nKeys.deleteLabel),
                disabled: () => isServerActionDisabled(),
                do: servers => {
                    //servers - array of Items while in Pool.config we keep an array of objects
                    const
                        poolConfig = $scope.Pool.getConfig(),
                        selectedHash = selectedServersHash(servers),
                        defaultServerPort = $scope.Pool.getDefaultServerPort();

                    const serversToRemove = [];

                    poolConfig.servers.config.forEach(server => {
                        const serverId = Server.getServerUuid(server.flattenConfig(),
                            defaultServerPort);

                        if (serverId in selectedHash) {
                            serversToRemove.push(server);
                        }
                    });
                    serversToRemove.forEach(server => {
                        poolConfig.servers.removeByMessageItem(server);
                    });

                    $scope.Pool.save();

                    return true;
                },
            });
        }

        return multipleactions;
    }

    /**
     * Returns a gridConfig object.
     * @return {Object} gridConfig for Servers Collection.
     */
    function buildGridConfig() {
        const
            ipGroupOrEPG = $scope.Pool.autoPopulatedServers(),
            gridConfig = {
                id: 'pool-server',
                collection: serverCollection,
                layout: {
                    hideSearch: true,
                    lengthAsTotal: true,
                },
                checkboxDisable() {
                    return !$scope.Pool.isEditable();
                },
            };

        gridConfig.fields = getFields(ipGroupOrEPG);

        if (!$scope.Pool.hasAutoscaleGroups()) {
            gridConfig.multipleactions = getMultipleactions(ipGroupOrEPG);
        }

        return gridConfig;
    }

    $scope.Pool.addLoad(['health', 'alert']);

    function itemChangeHandler() {
        serverCollection.load();
    }

    $scope.Pool.on('itemChange', itemChangeHandler);

    $scope.$on('$destroy', () => {
        serverCollection.destroy();
        $scope.Pool.async.stop(true);
        $scope.Pool.unbind('itemChange', itemChangeHandler);
    });
}]);
