/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const dnsPolicyRuleRateProfileActionConfigFactory = (ActionConfigItem, schemaService) => {
    class DnsPolicyRuleRateProfileActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const { rate_limiter_object: rateLimiterObject } = this.getConfig();
            const {
                count,
                burst_sz: bustSize,
                period,
            } = rateLimiterObject;

            const outputChunks = [];

            const { type: valueKey } = this.getConfig().action;

            if (valueKey) {
                const { label } = schemaService.getEnumValue('DnsRuleRLActionType', valueKey);

                outputChunks.push(`Type: "${label}"`);
            }

            if (!_.isUndefined(count)) {
                outputChunks.push(`Count: ${count}`);
            }

            if (!_.isUndefined(bustSize)) {
                outputChunks.push(`Burst Size: ${bustSize}`);
            }

            if (!_.isUndefined(period)) {
                outputChunks.push(`Period: ${period}s`);
            }

            return outputChunks.join('; ');
        }
    }

    Object.assign(DnsPolicyRuleRateProfileActionConfig.prototype, {
        type: 'DnsRateProfile',
        name: 'Rate Limit',
        defaultPath: 'dnsrule.action.dns_rate_limiter',
    });

    return DnsPolicyRuleRateProfileActionConfig;
};

dnsPolicyRuleRateProfileActionConfigFactory.$inject = [
    'ActionConfigItem',
    'schemaService',
];

/**
 * @ngdoc factory
 * @name DnsPolicyRuleRateProfileActionConfig
 * @description DnsPolicyRuleRateProfileActionConfig,
 *      maps to DnsRateProfile in protobof.
 * @module action/DnsPolicyRuleRateProfileActionConfig
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory(
    'DnsPolicyRuleRateProfileActionConfig', dnsPolicyRuleRateProfileActionConfigFactory,
);
