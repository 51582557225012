/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    Inject,
    Input,
} from '@angular/core';

import classnames from 'classnames';
import { any } from 'underscore';
import { StateService } from '@uirouter/core';
import $ from 'jquery';
import './state-button.component.less';

const componentTag = 'state-button';
const MAIN_MENU_TYPE = 'MAIN_MENU';
const CATEGORY_TYPE = 'CATEGORY';

/**
 * Hash to get the classname for state-button based on type.
 */
const typeToClassName = {
    [MAIN_MENU_TYPE]: `${componentTag}--main-menu`,
    [CATEGORY_TYPE]: `${componentTag}--category`,
};

interface IStateRefObject {
    state: string;
    paramExpr: string;
}

/**
 * @description
 *     A button used to navigate to a particular state, and adds an active class if its
 *     state is active. An optional activeStates binding can be passed if the state is considered
 *     active based on other states besides its own. For example, the Virtual Services button in the
 *     header should be shown as active if the user is on both the Virtual Services List page and
 *     the Virtual Service detail page of a specific Virtual Service.
 * @author alextsg, Aravindh Nagarajan
 */
@Component({
    selector: 'state-button',
    templateUrl: './state-button.component.html',
})
export class StateButtonComponent {
    /**
     * Type of the state button (mainmenu/category).
     * Used for styling purposes.
     */
    @Input() public type: string;

    /**
     * An array of state names to be checked if currently active
     * If passed in, overrides checking if the "state" is active.
     */
    @Input() public activeStates?: string[];

    /**
     * State to navigate to if the button is clicked,
     * and used to check if currently active.
     */
    @Input() public state: string;

    public constructor(
        @Inject('$state') private $state: StateService,
        private elementRef: ElementRef,
    ) {}

    /**
     * Basically parses the state string and splits up the state name from the params. This logic is
     * duplicated from ui-router.
     * @param stateRef - State string
     * @returns State reference object.
     */
    private static getStateRefObject(stateRef: string): IStateRefObject {
        let ref = stateRef;
        const paramsOnly = stateRef.match(/^\s*({[^}]*})\s*$/);

        if (paramsOnly) {
            ref = `(${paramsOnly[1]})`;
        }

        const parsed = ref.replace(/\n/g, ' ').match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);

        if (!parsed || parsed.length !== 4) {
            throw new Error(`Invalid state ref ${ref}`);
        }

        return {
            state: parsed[1] || null,
            paramExpr: parsed[3] || null,
        };
    }

    /**
     * Returns a string of classnames.
     */
    public getClassName(): string {
        const typeClassName = typeToClassName[this.type];

        return classnames(
            componentTag,
            typeClassName,
            this.isActive() && `${typeClassName}--active`,
        );
    }

    /**
     * Returns the context or parent level of the state. This logic is duplicated from ui-router.
     */
    private getStateContext(): string {
        const $uiView = $(this.elementRef.nativeElement)
            .parent().inheritedData('$uiView');

        const path = $uiView && $uiView.$cfg && $uiView.$cfg.path;

        return Array.isArray(path) && path[path.length - 1].state.name;
    }

    /**
     * Returns true if any of the activeStates or the current state is active.
     */
    private isActive(): boolean {
        if (Array.isArray(this.activeStates)) {
            return any(this.activeStates, state => this.$state.includes(state));
        } else {
            const stateRefObject = StateButtonComponent.getStateRefObject(this.state);
            const stateObject = this.$state.get(stateRefObject.state, this.getStateContext());

            return this.$state.includes(stateObject.name);
        }
    }
}

