/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-security-bot';
const prefix = `${moduleName}.${componentName}`;

export const classificationLabel = `${prefix}.classificationLabel`;
export const botClientTypeLabel = `${prefix}.botClientTypeLabel`;
export const identifierLabel = `${prefix}.identifierLabel`;
export const componentGridColumnLabel = `${prefix}.componentGridColumnLabel`;
export const botClientClassGridColumnLabel = `${prefix}.botClientClassGridColumnLabel`;
export const confidenceGridColumnLabel = `${prefix}.confidenceGridColumnLabel`;
export const notesLabel = `${prefix}.notesLabel`;
export const hideComponentsButtonLabel = `${prefix}.hideComponentsButtonLabel`;
export const showComponentsButtonLabel = `${prefix}.showComponentsButtonLabel`;

export const ENGLISH = {
    [classificationLabel]: 'Classification',
    [botClientTypeLabel]: 'Bot Client Type',
    [identifierLabel]: 'Identifier',
    [componentGridColumnLabel]: 'Component',
    [botClientClassGridColumnLabel]: 'Bot Client Class',
    [confidenceGridColumnLabel]: 'Confidence',
    [notesLabel]: 'Notes',
    [hideComponentsButtonLabel]: 'Hide Components',
    [showComponentsButtonLabel]: 'Show Components',
};
