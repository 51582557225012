/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { Gslb } from 'object-types';

import {
    IAviDataGridConfig, TStringRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    GslbClientIpAddrGroupConfigItem,
} from 'message-items/gslb-client-ip-add-group.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-client-ip-addr-group-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to config client ip address group.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-client-ip-addr-group-config',
    templateUrl: './gslb-client-ip-addr-group-config.component.html',
})
export class GslbClientIpAddrGroupConfigComponent implements AfterViewInit {
    @Input()
    public clientIpAddrGroup: GslbClientIpAddrGroupConfigItem;

    /**
     * TemplateRef for ip address input element.
     */
    @ViewChild('ipAddrTemplateRef')
    public ipAddrTemplateRef: TemplateRef<HTMLElement>;

    public ipAddrGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public readonly objectType = Gslb;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.ipAddrGridConfig = {
            fields: [{
                label: l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                id: 'ip-addr',
                templateRef: this.ipAddrTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (ipList: TStringRow[]) => {
                    ipList.forEach(ip => this.deleteIpAddr(ip));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (ipAddr: TStringRow) => {
                    this.deleteIpAddr(ipAddr);
                },
            }],
        };
    }

    /**
     * Add client group IP Address.
     */
    public addIpAddress(): void {
        this.clientIpAddrGroup.addAddress();
    }

    /**
     * Delete client group IP Address.
     */
    private deleteIpAddr(ipAddr: TStringRow): void {
        this.clientIpAddrGroup.removeAddress(ipAddr);
    }
}
