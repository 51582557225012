/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';

import {
    Observable,
    Subject,
} from 'rxjs';

import * as globalL10n from 'global-l10n';
import { IUploadParams } from 'generated-types';
import * as l10n from './pki-crl-server-url-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Type of config received as props.
 */
interface IConfig {
    name: string;
    description: string;
    is_federated: boolean;
    url: string;
    update_interval: number;
}

/**
 * @description Component for displaying the Add By Server URL modal to add a CRL.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'pki-crl-server-url-modal',
    templateUrl: './pki-crl-server-url-modal.component.html',
})
export class PkiCrlServerUrlModalComponent {
    @Input()
    public config: IConfig;

    /**
     * Disable the "Is Federeated" Checkbox in the modal if
     * the PKI Profile is federated.
     */
    @Input()
    public isPkiProfileFederated = false;

    /**
     * Observable to display the CRL name in the description of the modal breadcrumb.
     */
    @Input()
    public description$: Subject<string>;

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * Emit on click of close/cancel button.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Emit on click of save button.
     */
    @Output()
    public onSave = new EventEmitter<IUploadParams>();

    /**
     * Minimum update interval value.
     */
    public minimumUpdateInterval = 30;

    /**
     * Maximum update interval value.
     */
    public maximumUpdateInterval = 525600;

    /**
     * Errors to be displayed if one, occurs on save.
     */
    public errors: string;

    /**
     * Busy flag to render a spinner in the Save button.
     */
    public busy = false;

    /**
     * Range value to be displayed in the tooltip for Update Interval field.
     */
    public updateIntervalRangeText = '30-525600';

    /**
     * Error Message to be displayed in case user enters a value out of range.
     */
    public updateIntervalError = '';

    /**
     * Layout for Add By Server URl modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Update the description in the modal breadcrum with updated name value.
     */
    public handleNameChange(): void {
        this.description$.next(this.config.name);
    }

    /**
     * Handle close/cancel button click.
     */
    public dismiss(): void {
        this.onClose.emit();
    }

    /**
     * Handle Update Interval field change.
     */
    public handleUpdateIntervalChange(): void {
        const {
            l10nKeys,
            l10nService,
            maximumUpdateInterval,
            minimumUpdateInterval,
        } = this;

        if (
            this.config.update_interval < minimumUpdateInterval ||
            this.config.update_interval > maximumUpdateInterval
        ) {
            this.updateIntervalError = l10nService.getMessage(l10nKeys.updateIntervalErrorText);
        } else {
            this.updateIntervalError = '';
        }
    }

    /**
     * Handle Save button click.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = '';

        const subscription = this.submit$.subscribe(
            () => this.dismiss(),
            error => this.errors = error,
        ).add(() => {
            this.busy = false;

            subscription.unsubscribe();
        });
    }
}
