/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-pool-member-domain-name';
const prefix = `${moduleName}.${componentName}`;

export const ipOrFqdnInputLabel = `${prefix}.ipOrFqdnInputLabel`;
export const ipOrFqdnInputPlaceholder = `${prefix}.ipOrFqdnInputPlaceholder`;
export const resolvedFqdnDropdownLabel = `${prefix}.resolvedFqdnDropdownLabel`;
export const resolvedFqdnDropdownPlaceholder = `${prefix}.resolvedFqdnDropdownPlaceholder`;

export const ENGLISH = {
    [ipOrFqdnInputLabel]: 'IP(v4/v6) Address or FQDN',
    [ipOrFqdnInputPlaceholder]: 'Enter IP(v4/v6) Address or FQDN',
    [resolvedFqdnDropdownLabel]: 'Resolved IP(v4/v6) Address',
    [resolvedFqdnDropdownPlaceholder]: 'Select Resolved IP(v4/v6) Address',
};
