/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud-connector-user-module';
const componentName = 'user-credentials-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleCredentialType = `${prefix}.columnTitleCredentialType`;
export const verifyHostBtnLabel = `${prefix}.verifyHostBtnLabel`;
export const downloadPublicKeyBtnLabel = `${prefix}.downloadPublicKeyBtnLabel`;
export const headerLabel = `${prefix}.headerLabel`;
export const buttonLabel = `${prefix}.buttonLabel`;

export const ENGLISH = {
    [columnTitleCredentialType]: 'Credentials Type',
    [verifyHostBtnLabel]: 'Verify Host',
    [downloadPublicKeyBtnLabel]: 'Download Public Key',
    [headerLabel]: 'User Credentials',
};
