/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'core';
const componentName = 'avi-alert';
const prefix = `${moduleName}.${componentName}`;

export const confirmButtonText = `${prefix}.confirmButtonText`;
export const headerText = `${prefix}.headerText`;
export const alertMessage = `${prefix}.alertMessage`;

export const ENGLISH = {
    [confirmButtonText]: 'Dismiss',
    [headerText]: 'Warning',
    [alertMessage]: 'We have encountered a problem during your request:',
};
