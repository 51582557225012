<avi-bar-graph
    [dataset]="vsLogsGraphStore.graphBarData$ | async"
    [graphEndTimestamp]="vsLogsGraphStore.end$ | async"
    [graphStartTimestamp]="vsLogsGraphStore.start$ | async"
    [onUserInteractionWithGraphOptionsSubject]="vsLogsGraphStore.startLoadingSubject"
    (onOverlayCreationOrModificationOrDeletion)="vsLogsStore.setTableCustomTimeframe($event)"
    (clickOverlayZoom)="vsLogsStore.setGraphCustomTimeframe()"
    [colorLegend$]="vsLogsGraphStore.currentGraphColorsLegend$"
    tooltipTotalLabelAddendum="{{ tooltipLogsLabel }}"
    [isLoading]="vsLogsGraphStore.isLoading$ | async"
    [hasError]="vsLogsGraphStore.hasError$ | async"
    [isGraphApiLoopInProgress]="vsLogsStore.isGraphApiLoopInProgress$ | async"
></avi-bar-graph>
