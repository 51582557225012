/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRootStoreState } from './root-store.state';

export const rootKey = 'root';

const selectRoot = createFeatureSelector<IRootStoreState>(rootKey);

export const selectLoading = createSelector(
    selectRoot,
    ({ loading }) => loading,
);
