<avi-confirmation
    class="vs-logs-save-search-modal clr-wrapper"
    headerText="{{ l10nKeys.saveSearchLabel | vtranslate }}"
    [closeOnBackdropClick]="true"
    (onClose)="closeSaveSearchModal()"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <div class="vs-logs-save-search-modal__content">
            <cds-alert-group
                *ngIf="hasError"
                status="danger"
            >
                <cds-alert closable="false">
                    {{ l10nKeys.nameAlreadyExistsErrorLabel | vtranslate }}
                </cds-alert>
            </cds-alert-group>

            <avi-input-container>
                <label class="vs-logs-save-search-modal__label">{{ globalL10nKeys.nameLabel | vtranslate }}</label>
                <input
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    [(ngModel)]="searchName"
                    (ngModelChange)="resetError()"
                    required
                />
            </avi-input-container>
        </div>
    </ng-container>

    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <button
            class="btn btn-outline"
            type="button"
            (click)="closeSaveSearchModal()"
        >
            {{ globalL10nKeys.cancelLabel | vtranslate }}
        </button>
        <button
            class="btn btn-primary"
            type="button"
            (click)="saveSearch()"
        >
            {{ globalL10nKeys.saveLabel | vtranslate }}
        </button>
    </ng-container>
</avi-confirmation>
