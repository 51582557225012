/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { GslbSiteDnsVs } from 'object-types';
import { ApplicationProfileType } from 'generated-types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { StringService } from 'ng/modules/core/services/string/string.service';

import {
    GslbSiteDnsVsConfigItem,
} from 'message-items/gslb-site-dns-vs.config-item.factory';

import {
    GSLBVSCollection,
    TGSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-dns-virtual-service-modal.l10n';
import './gslb-dns-virtual-service-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal to select the DNS VS for gslb avi sites that are acitve.
 * Modal also allows to configure subdomians for the DNS VS.
 * If no subdomain is explicitly selected then the dns vs will host all the available subdomains.
 *
 * @author Hitesh Mandav
 */

@Component({
    selector: 'gslb-dns-virtual-service-modal',
    templateUrl: './gslb-dns-virtual-service-modal.component.html',
})
export class GslbSiteDnsVsModalComponent implements OnInit {
    @ViewChild('subdomainTemplateRef', { static: true })
    public subdomainTemplateRef: TemplateRef<HTMLElement>;

    @Input()
    public editable: GslbSiteDnsVsConfigItem;

    /**
     * Cluster uuid of gslb site.
     */
    @Input()
    public clusterUuid: string;

    /**
     * Tenant id of the gslb site.
     */
    @Input()
    public tenantId: string;

    /**
     * All the domains configured for the gslb.
     */
    @Input()
    public configuredDomains: string[];

    /**
     * Uuid of all the vses selected for gslb site.
     */
    @Input()
    public selectedVsId: string[];

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * If no subdomain is selected all the subdomains are hosted by the selected dnsVs.
     */
    public hostAllSubdomain: boolean;

    /**
     * Subdomains dropdown options.
     */
    public availableDomainsOptions: IAviDropdownOption[] = [];

    /**
     * Data Grid Config for subdomains.
     */
    public subdomainsGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public dnsVsCollection: GSLBVSCollection;

    public readonly objectType = GslbSiteDnsVs;

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
        @Inject(GSLBVSCollection)
        private readonly GSLBVSCollection: TGSLBVSCollection,
        private readonly stringService: StringService,
        @Inject('systemInfoService')
        private readonly systemInfoService: SystemInfoService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.hostAllSubdomain = !this.editable.config.domain_names?.length;
        this.initializeDnsVsCollection();
        this.setAvailableDomainsOptions();
        this.updateDnsVsCollectionFilter(this.selectedVsId);
    }

    /**
     * Trigger on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Trigger on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Remove all the selected subdomains and set the available subdomains options.
     */
    public resetSubdomains(): void {
        this.editable.deleteAllSubdomains();
        this.setAvailableDomainsOptions();
    }

    /**
     * Update the dnVsCollection filter to exclude the currently selected dnsVs.
     * Update the dns_vs_uuid.
     */
    public handleDnsVsChange(dnsRef: string): void {
        const currentDnsVsIndex = this.selectedVsId.indexOf(this.editable.config.dns_vs_uuid);

        if (currentDnsVsIndex !== -1) {
            this.selectedVsId.splice(currentDnsVsIndex, 1);
        }

        this.editable.config.dns_vs_uuid = this.stringService.slug(dnsRef);

        const idsToExclude = [...this.selectedVsId, this.editable.config.dns_vs_uuid];

        this.updateDnsVsCollectionFilter(idsToExclude);
    }

    /**
     * Set the availableDomainOptions from configuredDomins.
     * Exclude any domain that is already configured for the dns.
     */
    public setAvailableDomainsOptions(): void {
        const domainsToExclude: { [key: string]: boolean } = {};

        this.availableDomainsOptions = [];
        this.editable.config.domain_names?.forEach(domain => {
            domainsToExclude[domain] = true;
        });

        this.configuredDomains.forEach(
            domain => {
                if (!domainsToExclude[domain]) {
                    this.availableDomainsOptions.push(createDropdownOption(domain));
                }
            },
        );
    }

    /**
     * Initialize the dnsVsCollection.
     */
    private initializeDnsVsCollection(): void {
        this.dnsVsCollection = new this.GSLBVSCollection({
            gslbSiteId: this.clusterUuid,
            gslbTenant: this.tenantId,
            params: {
                fields: 'name,tenant_ref',
                'application_profile_ref.type': ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS,
                headers_: { 'X-Avi-Internal-All-Tenants': true },
            },
        });
    }

    /**
     * Updates parameters for collection to avoid displaying already selected VS's.
     */
    private updateDnsVsCollectionFilter(idList: string[]): void {
        this.dnsVsCollection.setParams({
            exclude: 'uuid.in',
            'uuid.in': idList.join(),
        });
    }
}
