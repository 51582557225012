<avi-card class="update-node-status-card">
    <avi-card_header class="update-node-status-card__main-header">
        <div class="update-node-status-card__main-header__tile update-node-status-card__main-header__tile--with-icon">
            <div class="update-node-status-card__main-header__tile-icon">
                <cds-icon
                    shape="display"
                    size="sm"
                ></cds-icon>
            </div>

            <div class="update-node-status-card__main-header__tile-content">
                <div class="update-node-status-card__main-header__tile-title">{{ nodeName }}</div>
                <div>{{ currentNodeVersion }}</div>
            </div>

            <div class="update-node-status-card__main-header__tile-action-icons-container">
                <div class="update-node-status-card__main-header__tile-action-icon">
                    <cds-icon
                        *ngIf="showUpgradeResumeButton"
                        class="sel-resume-update-button"
                        shape="play"
                        size="sm"
                        status="info"
                        (click)="openSuppressWarningDialog()"
                    ></cds-icon>
                </div>
                <div
                    class="update-node-status-card__main-header__tile-action-icon"
                    title="{{ l10nKeys.viewTranscriptTooltipLabel | vtranslate }}"
                >
                    <cds-icon
                        shape="chat-bubble"
                        class="sel-view-transcript-button"
                        size="sm"
                        status="info"
                        (click)="openTranscriptModal()"
                    ></cds-icon>
                </div>

                <div class="update-node-status-card__main-header__tile-action-icon">
                    <cds-icon
                        shape="angle"
                        class="sel-update-details-toggle-button"
                        [direction]="showExpanded ? 'down' : 'up'"
                        (click)="toggleExpansion()"
                    ></cds-icon>
                </div>
            </div>
        </div>
    </avi-card_header>
    <avi-card_body>
        <div
            class="update-node-status-card__main-header__tile update-node-status-card__main-header__tile--body"
            *ngIf="showExpanded"
        >
            <ng-container *ngIf="!isPrecheckFlow; else precheckNode">
                <div class="update-node-status-card__main-header__tile-title">{{ l10nKeys.updateOperationLabel | vtranslate }}</div>
                <div>{{ nodeUpgradeOps }}</div>

                <div class="update-node-status-card__main-header__tile-title update-node-status-card__main-header__tile-title--started">{{ l10nKeys.updateOperationStartTimeLabel | vtranslate }}</div>
                <div>{{ nodeUpgradeStartTime | aviDate }}</div>
            </ng-container>
            <ng-template #precheckNode>
                <div class="update-node-status-card__main-header__tile-title">{{ l10nKeys.updateOperationLabel | vtranslate }}</div>
                <div>{{ preChecksUpgradeOps }}</div>
                <div class="update-node-status-card__main-header__tile-title update-node-status-card__main-header__tile-title--started">{{ l10nKeys.updateOperationStartTimeLabel | vtranslate }}</div>
                <div>{{ prechecksStartTime | aviDate }}</div>
            </ng-template>
        </div>
    </avi-card_body>
    <avi-card_footer>
        <div class="update-node-status-card__main-header__tile update-node-status-card__main-header__tile--with-icon update-node-status-card__main-header__tile--footer">
            <div
                title="{{ nodeStatusDescription }}"
                class="update-node-status-card__main-header__tile-icon-container"
            >
                <cds-icon
                    shape="{{ statusIconInfo.shape }}"
                    status="{{ statusIconInfo.status }}"
                    size="md"
                ></cds-icon>
            </div>
            <div>{{ nodeStateGroupLabel }}</div>
        </div>
    </avi-card_footer>
</avi-card>
