/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { MgmtIpAccessControl } from 'object-types';
import * as globalL10n from 'global-l10n';

import {
    IpAddrGroupCollection,
} from 'ajs/modules/groups/factories/ip-addr-group/ip-addr-group.collection.factory';

import {
    AllowedClientTypesEnumValues,
    IAllowedClientsConfig,
    IAllowedValue,
} from '../system-settings-client-management-access-config-container.types';

import * as l10n from './system-settings-client-management-access-config.l10n';
import './system-settings-client-management-access-config.component.less';

type TIpAddrGroupCollection = typeof IpAddrGroupCollection;

const defaultAllowedValue: IAllowedValue = { isGroupRef: true };
const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for Client Management Access Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-client-management-access-config',
    templateUrl: './system-settings-client-management-access-config.component.html',
})
export class SystemSettingsClientManagementAccessConfigComponent {
    /**
     * Allowed clients config used in template.
     */
    @Input()
    public allowedClientsConfig: IAllowedClientsConfig;

    /**
     * Field name used in template.
     */
    @Input()
    public clientType: AllowedClientTypesEnumValues;

    /**
     * Client label used in template.
     */
    @Input()
    public clientLabel: string;

    /**
     * Emit on allowed clients config changed.
     */
    @Output()
    public allowedClientsConfigChange = new EventEmitter<void>();

    /**
     * Field names for template usage.
     */
    public readonly fieldNames = {
        [AllowedClientTypesEnumValues.ssh]: 'ssh_access',
        [AllowedClientTypesEnumValues.cli]: 'shell_server_access',
        [AllowedClientTypesEnumValues.https]: 'api_access',
        [AllowedClientTypesEnumValues.snmp]: 'snmp_access',
    };

    /**
     * Object type string for template usage.
     */
    public readonly objectType = MgmtIpAccessControl;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Collection object to store the IpAddrGroup collection.
     */
    public readonly ipAddrGroupCollection: IpAddrGroupCollection;

    constructor(
        l10nService: L10nService,
        @Inject(IpAddrGroupCollection)
        IpAddrGroupCollection: TIpAddrGroupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.ipAddrGroupCollection = new IpAddrGroupCollection();
    }

    /**
     * Delete IAllowedValue to clean up state management.
     */
    public onValueTypeRadioButtonSwitch(allowedValue: IAllowedValue): void {
        delete allowedValue.value;
    }

    /**
     * Clear allowed values array to have a clean state management.
     * If any values are not allowed ie. only specific clients are allowed,
     * an empty field is added by default.
     */
    public onClientTypeRadioButtonSwitch(allowedClientsConfig: IAllowedClientsConfig): void {
        allowedClientsConfig.allowedValues.length = 0;

        if (!allowedClientsConfig.areAnyValuesAllowed) {
            this.addIpAddr();
        }

        this.onAllowedClientsConfigChanged();
    }

    /**
     * Set default allowed value on Add Item click.
     */
    public addIpAddr(): void {
        this.allowedClientsConfig.allowedValues.push({
            ...defaultAllowedValue,
        });
    }

    /**
     * Remove allowed value from allowed values list in config.
     */
    public removeIpAddr(index: number): void {
        this.allowedClientsConfig.allowedValues.splice(index, 1);
        this.onAllowedClientsConfigChanged();
    }

    /**
     * Emit event when allowed clients config changed.
     */
    public onAllowedClientsConfigChanged(): void {
        this.allowedClientsConfigChange.emit();
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
