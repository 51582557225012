/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { isEmpty } from 'underscore';
import * as l10n from './string-group-or-custom-value.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum StringType {
    STRING_GROUP = 'STRING_GROUP',
    CUSTOM_VALUE = 'CUSTOM_VALUE',
}

/**
 * @description Component for entering string group refs or custom values.
 * @author alextsg
 */
@Component({
    selector: 'string-group-or-custom-value',
    templateUrl: './string-group-or-custom-value.component.html',
})
export class StringGroupOrCustomValueComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * List of custom value strings.
     */
    @Input()
    public customValues: string[] | undefined;

    /**
     * List of string group refs.
     */
    @Input()
    public stringGroupRefs: string[] | undefined;

    /**
     * EventEmitter for custom value strings.
     */
    @Output()
    public customValuesChange = new EventEmitter<string[]>();

    /**
     * EventEmitter for string group refs.
     */
    @Output()
    public stringGroupRefsChange = new EventEmitter<string[]>();

    /**
     * This component can be used repeatedly in a match, so to ensure the ngModel has a unique name
     * the index can be passed in as a binding.
     */
    @Input()
    public index: string | number = 0;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * If true, removes the margin-top from clr-radio-container.
     */
    public noMarginTop = false;

    public readonly l10nKeys = l10nKeys;

    /**
     * stringGroupCollection instance for selecting a String Group.
     */
    public readonly stringGroupCollection: any;

    /**
     * Custom StringType enum. Used for selecting between the radio options for String Group or
     * custom value.
     */
    public StringType = StringType;

    /**
     * ngModel value of the radio buttons to select between a String Group or custom value.
     */
    public stringType: StringType = StringType.STRING_GROUP;

    constructor(
        l10nService: L10nService,
        @Inject('StringGroupCollection')
        StringGroupCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.stringGroupCollection = new StringGroupCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (!isEmpty(this.customValues)) {
            this.stringType = StringType.CUSTOM_VALUE;
        }
    }

    /**
     * @override
     * Sets this.stringType according to changes in the model value.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const { customValues, stringGroupRefs } = changes;

        if (customValues && !customValues.firstChange && !isEmpty(customValues.currentValue)) {
            this.stringType = StringType.CUSTOM_VALUE;
        } else if (stringGroupRefs && !stringGroupRefs.firstChange &&
            !isEmpty(stringGroupRefs.currentValue)) {
            this.stringType = StringType.STRING_GROUP;
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.stringGroupCollection.destroy();
    }

    /**
     * Called when the custom values list has changed.
     */
    public handleCustomValuesChange(): void {
        this.customValuesChange.emit(this.customValues);
    }

    /**
     * Called when the string group refs list has changed.
     */
    public handleStringGroupRefsChange(): void {
        this.stringGroupRefsChange.emit(this.stringGroupRefs);
    }

    /**
     * Called when the radio button options have changed.
     */
    public handleStringTypeChange(): void {
        this.customValues = undefined;
        this.stringGroupRefs = undefined;
        this.handleCustomValuesChange();
        this.handleStringGroupRefsChange();
    }
}
