/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IIpAddr,
    IpAddressVersions,
    IpAddrType,
    IVirtualService,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { VirtualService } from 'object-types';
import * as globalL10n from 'global-l10n';
import {
    AviDataGridFieldVisibility,
    IAviDataGridConfig,
    IAviDataGridConfigField,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './vs-snat-ip-addresses.l10n';

/**
 * Interface for Snat ip grid row.
 */
interface ISnatIpGridRow {
    [IpAddrType.V4]: string,
    [IpAddrType.V6]: string,
}

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Renders info about vs snat ips, in advanced vs creation modal.
 *
 * @author Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'vs-snat-ip-addresses',
    templateUrl: './vs-snat-ip-addresses.component.html',
})
export class VsSnatIpAddressesComponent {
    @Input()
    public config: IVirtualService;

    @ViewChild('snatIpV4AddressTemplateRef')
    public readonly snatIpV4AddressTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('snatIpV6AddressTemplateRef')
    public readonly snatIpV6AddressTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Holds the snat ip version selected by user.
     */
    public snatIpAddressVersion: IpAddressVersions;

    public snatIpCommonGridConfig: IAviDataGridConfig;
    public snatIpV4GridConfig: IAviDataGridConfig;
    public snatIpV6GridConfig: IAviDataGridConfig;
    public snatIpV4AndV6GridConfig: IAviDataGridConfig;

    public snatIpGridRows: ISnatIpGridRow[];

    public readonly ipAddrVersion = {
        [IpAddressVersions.V4_ONLY]: IpAddressVersions.V4_ONLY,
        [IpAddressVersions.V4_V6]: IpAddressVersions.V4_V6,
        [IpAddressVersions.V6_ONLY]: IpAddressVersions.V6_ONLY,
    };

    public readonly ipAddrType = {
        [IpAddrType.V4]: IpAddrType.V4,
        [IpAddrType.V6]: IpAddrType.V6,
    };

    public readonly objectType = VirtualService;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Reference of snat ip grid config ip4 field, for conditional render.
     */
    private snatIpGridIpV4ConfigField: IAviDataGridConfigField;

    /**
     * Reference of snat ip grid config ip6 field, for conditional render.
     */
    private snatIpGridIpV6ConfigField: IAviDataGridConfigField;

    private get snatIpv4Addresses(): IIpAddr[] {
        return this.config.snat_ip;
    }

    private set snatIpv4Addresses(snatIpv4Addresses: IIpAddr[]) {
        this.config.snat_ip = snatIpv4Addresses;
    }

    private get snatIpv6Addresses(): IIpAddr[] {
        return this.config.snat_ip6_addresses;
    }

    private set snatIpv6Addresses(snatIpv6Addresses: IIpAddr[]) {
        this.config.snat_ip6_addresses = snatIpv6Addresses;
    }

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Evaluate and set snat ip version from config.
     */
    public ngOnInit(): void {
        const isSnatIpV4 = this.snatIpv4Addresses?.length;
        const isSnatIpV6 = this.snatIpv6Addresses?.length;

        if (isSnatIpV4 && isSnatIpV6) {
            this.snatIpAddressVersion = this.ipAddrVersion.V4_V6;
        } else if (isSnatIpV4) {
            this.snatIpAddressVersion = this.ipAddrVersion.V4_ONLY;
            this.snatIpv6Addresses = [];
        } else if (isSnatIpV6) {
            this.snatIpAddressVersion = this.ipAddrVersion.V6_ONLY;
            this.snatIpv4Addresses = [];
        } else {
            this.snatIpAddressVersion = this.ipAddrVersion.V4_V6;
            this.snatIpv4Addresses = [];
            this.snatIpv6Addresses = [];
        }

        this.createSnatIpGridRows();
    }

    /**
     * @override
     * Set snat ip grid config.
     */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.snatIpGridIpV4ConfigField = {
            label: l10nService.getMessage(globalL10nKeys.ipv4AddressInputLabel),
            visibility: AviDataGridFieldVisibility.MANDATORY,
            templateRef: this.snatIpV4AddressTemplateRef,
            id: 'snat-ip-v4-address',
        };

        this.snatIpGridIpV6ConfigField = {
            label: l10nService.getMessage(globalL10nKeys.ipv6AddressInputLabel),
            visibility: AviDataGridFieldVisibility.MANDATORY,
            templateRef: this.snatIpV6AddressTemplateRef,
            id: 'snat-ip-v6-address',
        };

        this.snatIpCommonGridConfig = {
            layout: {
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                showFooter: true,
                hideCheckboxes: false,
            },
            fields: [],
            singleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (row: ISnatIpGridRow) => {
                        this.deleteSnatIpGridRows(row);
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (snatIpRows: ISnatIpGridRow[]) => {
                        snatIpRows.forEach((row: ISnatIpGridRow) => {
                            this.deleteSnatIpGridRows(row);
                        });
                    },
                },
            ],
        };

        this.snatIpV4GridConfig = {
            ...this.snatIpCommonGridConfig,
            fields: [
                this.snatIpGridIpV4ConfigField,
            ],
        };

        this.snatIpV6GridConfig = {
            ...this.snatIpCommonGridConfig,
            fields: [
                this.snatIpGridIpV6ConfigField,
            ],
        };

        this.snatIpV4AndV6GridConfig = {
            ...this.snatIpCommonGridConfig,
            fields: [
                this.snatIpGridIpV4ConfigField,
                this.snatIpGridIpV6ConfigField,
            ],
        };
    }

    /**
     * Render columns in snat ip grid conditionally, based on snat ip address version.
     */
    public onSnatIpVersionChange(): void {
        this.snatIpv4Addresses.length = 0;
        this.snatIpv6Addresses.length = 0;

        this.createSnatIpGridRows();
    }

    public addNewSnatIpAddress(): void {
        const emptyIpV4Addr: IIpAddr = {
            addr: undefined,
            type: IpAddrType.V4,
        };

        const emptyIpV6Addr: IIpAddr = {
            addr: undefined,
            type: IpAddrType.V6,
        };

        switch (this.snatIpAddressVersion) {
            case this.ipAddrVersion.V4_V6: {
                this.snatIpv4Addresses.push(emptyIpV4Addr);
                this.snatIpv6Addresses.push(emptyIpV6Addr);

                break;
            }

            case this.ipAddrVersion.V4_ONLY: {
                this.snatIpv4Addresses.push(emptyIpV4Addr);

                break;
            }

            case this.ipAddrVersion.V6_ONLY: {
                this.snatIpv6Addresses.push(emptyIpV6Addr);

                break;
            }
        }

        this.createSnatIpGridRows();
    }

    /**
     * Update vs config on snat ip address update.
     * @param index - Row index of ip address.
     * @param ipAddrType - Ip address type of the field that user edits.
     */
    public onSnatIpAddressUpdate(index: number, ipAddrType: IpAddrType): void {
        if (ipAddrType === IpAddrType.V4) {
            this.snatIpv4Addresses[index].addr = this.snatIpGridRows[index].V4;
        } else if (ipAddrType === IpAddrType.V6) {
            this.snatIpv6Addresses[index].addr = this.snatIpGridRows[index].V6;
        }
    }

    /**
     * Create snat ip address rows from vs config.
     */
    private createSnatIpGridRows(): void {
        const maxLen = Math.max(this.snatIpv4Addresses?.length, this.snatIpv6Addresses?.length);

        this.snatIpGridRows = [];

        for (let ipIndex = 0; ipIndex < maxLen; ipIndex++) {
            this.snatIpGridRows.push({
                [IpAddrType.V4]: this.snatIpv4Addresses[ipIndex]?.addr,
                [IpAddrType.V6]: this.snatIpv6Addresses[ipIndex]?.addr,
            });
        }
    }

    /**
     * Delete SNAT IP Grid row.
     */
    private deleteSnatIpGridRows(row: ISnatIpGridRow): void {
        this.snatIpGridRows.find((snatIpGridRow: ISnatIpGridRow, index: number) => {
            const isRowFound = snatIpGridRow.V4 === row.V4 && snatIpGridRow.V6 === row.V6;

            if (isRowFound) {
                this.snatIpv4Addresses.splice(index, 1);
                this.snatIpv6Addresses.splice(index, 1);
            }

            return isRowFound;
        });

        this.createSnatIpGridRows();
    }
}
