/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-infoblox-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setInfobloxCredentialsHeader = `${prefix}.setInfobloxCredentialsHeader`;
export const ipv4AddressOrFqdnLabel = `${prefix}.ipv4AddressOrFqdnLabel`;
export const ipv4AddressOrFqdnPlaceholder = `${prefix}.ipv4AddressOrFqdnPlaceholder`;
export const networkViewInputLabel = `${prefix}.networkViewInputLabel`;
export const networkViewInputPlaceholder = `${prefix}.networkViewInputPlaceholder`;
export const dnsViewInputLabel = `${prefix}.dnsViewInputLabel`;
export const dnsViewInputPlaceholder = `${prefix}.dnsViewInputPlaceholder`;

export const ENGLISH = {
    [setInfobloxCredentialsHeader]: 'Set Infoblox Credentials',
    [ipv4AddressOrFqdnLabel]: 'IPv4 Address or FQDN',
    [ipv4AddressOrFqdnPlaceholder]: 'Enter IPv4 Address or FQDN',
    [networkViewInputLabel]: 'Network View',
    [dnsViewInputLabel]: 'DNS View',
    [dnsViewInputPlaceholder]: 'Enter DNS View',
    [networkViewInputPlaceholder]: 'Enter Network View',
};
