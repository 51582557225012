/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/toplist.less';
import '../../less/components/url-top-list-styling.less';
import * as l10n from './TopList.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** List Helpers
 * List Helpers holds most of the controller logic + helper functions for mapTopList, urlTopList,
 * and topList (the below directives)
 */
angular.module('aviApp').service('listHelpers', function() {
    const instance = {};

    instance.topList = function(scope) {
        scope.l10nKeys = l10nKeys;
        // --------- Member Variables -------------//
        scope.display = {};
        scope.display.hasEndToEnd = false;

        // ----------Listeners -------------//
        scope.$on('new-filtered-data', function() {
            if (scope.list) {
                scope.list.showFilteredData();
            }
        });

        scope.$on('show-base-data', function() {
            if (scope.list) {
                scope.list.showBaseData();
            }
        });

        const findNavigationTiming = function(parent) {
            return parent.$eval('data.maxNavigationTiming');
        };

        scope.computeTimingSectionWidth = function(item) {
            if (!scope.list || !item) {
                return;
            }

            if (scope.list.dim === 'url') {
                return { width: '100%' };
            }

            const maxNavigationTiming = findNavigationTiming(scope.$parent);

            if (maxNavigationTiming && item.timing &&
                item.timing.base && item.timing.base.endToEndTotal) {
                const width = Math.min(100, item.timing.base.endToEndTotal /
                    maxNavigationTiming * 100);

                return { width: `${width}%` };
            }
        };

        scope.computePageDownloadTimeWidth = function(item) {
            if (!scope.list || !item) {
                return;
            }

            const maxNavigationTiming = findNavigationTiming(scope.$parent);

            if (maxNavigationTiming && item.timing &&
                item.timing.base && item.timing.base.endToEndTotal) {
                const width = Math.min(100, item.timing.base.pageDownloadTimeTotal /
                    maxNavigationTiming * 100);

                return { width: `${width}%` };
            }
        };

        function toggleTimingGraphs(bool) {
            const { list } = scope;

            if (list && list.items && list.items.length) {
                _.each(list.items, function(item) {
                    item.display.showingTimingGraphs = bool;
                });
            }
        }

        scope.$on('repaint', calcWidths);

        function calcWidths() {
            scope.computeTimingSectionWidth();
            scope.computePageDownloadTimeWidth();
        }

        // ----------- Communicating with scope ---------------//
        scope.showTimingGraphs = function() {
            scope.display.showingTimingGraphs = true;
            toggleTimingGraphs(true);
        };

        scope.hideTimingGraphs = function() {
            scope.display.showingTimingGraphs = false;
            toggleTimingGraphs(false);
        };

        scope.filterBy = function(item) {
            scope.$emit('filter-by-item', item);
        };

        scope.showResourceTiming = function(item) {
            scope.$emit('show-resource-timing', item);
        };

        scope.hasEndToEnd = function(list) {
            if (list && list.items && list.items.length) {
                return _.some(list.items, function(item) {
                    return item && item.timing &&
                        item.timing.currentData &&
                        item.timing.currentData.endToEnd &&
                        item.timing.currentData.endToEnd.length;
                });
            } else {
                return false;
            }
        };
    };

    return instance;
});

angular.module('aviApp').directive('topList', ['listHelpers', function(listHelpers) {
    return {
        templateUrl: 'src/views/components/TopList.html',
        restrict: 'A',
        scope: {
            list: '=',
            showingTopNum: '=',
            title: '=',
        },
        link: listHelpers.topList,
    };
}]);

angular.module('aviApp').directive('urlTopList', ['listHelpers', 'l10nService',
function(listHelpers, l10nService) {
    return {
        templateUrl: 'src/views/components/url-top-list.html',
        restrict: 'A',
        scope: {
            list: '=',
            showingTopNum: '=',
            title: '=',
        },
        link(scope, elm, attr) {
            listHelpers.topList(scope, elm, attr);

            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            scope.computeWidth = function(item) {
                if (item && item.timing && scope.maxPageDownloadTimeTotal) {
                    const width = Math.min(100, item.timing.currentData.pageDownloadTimeTotal /
                    scope.maxPageDownloadTimeTotal * 100);

                    return { width: `${width}%` };
                }
            };

            function calcMax() {
                if (scope.list && scope.list.items) {
                    scope.maxPageDownloadTimeTotal =
                        _.reduce(scope.list.items, function(memo, item) {
                            const t = item.timing &&
                                item.timing.currentData &&
                                item.timing.currentData.pageDownloadTimeTotal;

                            return t && t > memo ? t : memo;
                        }, 0);
                }
            }

            scope.computeTimingSectionWidth = function(item) {
                if (!scope.list || !item) {
                    return;
                }

                const maxNavigationTiming = scope.list.getMaxNavigationTiming();

                if (maxNavigationTiming && item.timing &&
                    item.timing.base && item.timing.base.endToEndTotal) {
                    const width = Math.min(100, item.timing.base.endToEndTotal /
                    maxNavigationTiming * 100);

                    return { width: `${width}%` };
                }
            };

            scope.computePageDownloadTimeWidth = function(item) {
                if (!scope.list || !item) {
                    return;
                }

                const maxNavigationTiming = scope.list.getMaxNavigationTiming();

                if (maxNavigationTiming && item.timing &&
                    item.timing.base && item.timing.base.endToEndTotal) {
                    const width = Math.min(100, item.timing.base.pageDownloadTimeTotal /
                    maxNavigationTiming * 100);

                    return { width: `${width}%` };
                }
            };

            scope.$watch('list', function() {
                if (scope.list && scope.list.items) {
                    _.each(scope.list.items, function(item) {
                        if (item.timing) {
                            item.timing.on('timing-change', function() {
                                calcMax();
                            });
                        }
                    });
                }
            });
        },
    };
}]);

angular.module('aviApp').directive('mapTopList', ['listHelpers', 'l10nService',
function(listHelpers, l10nService) {
    return {
        templateUrl: 'src/views/components/mapTopList.html',
        restrict: 'A',
        scope: {
            list: '=',
            showingTopNum: '=',
            title: '=',
        },
        link(scope, elm, attr) {
            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            // Adding on a few additional functions because we need to communicate with the globe
            listHelpers.topList(scope, elm, attr);

            const oldFilterby = scope.filterBy;

            scope.filterBy = function(item) {
                oldFilterby(item);
                scope.$broadcast('selectingCountry', item);
            };
        },
    };
}]);
