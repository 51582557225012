/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Guru Prasad H K
 */

/**
 *  Ajs dependency token for AlertEmailConfigItem factory.
 */
export const ALERT_EMAIL_CONFIG_ITEM_TOKEN = 'AlertEmailConfigItem';

/**
 * Ajs dependency token for AlertEmailConfig Collection.
 */
export const ALERT_EMAIL_CONFIG_COLLECTION_TOKEN = 'AlertEmailConfigCollection';

/**
 *  Ajs dependency token for SnmpTrapProfileItem factory.
 */
export const SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN = 'SnmpTrapProfileItem';

/**
 * Ajs dependency token for SnmpTrapProfile Collection.
 */
export const SNMP_TRAP_PROFILE_COLLECTION_TOKEN = 'SnmpTrapProfileCollection';

/**
 * Ajs dependency token for SnmpTrapServerConfigItem factory.
 */
export const SNMP_TRAP_SERVER_CONFIG_ITEM_TOKEN = 'SnmpTrapServerConfigItem';

/**
 * Ajs dependency token for SnmpV3UserParamsConfigItem factory.
 */
export const SNMP_V3_USER_PARAMS_CONFIG_ITEM = 'SnmpV3UserParamsConfigItem';

/**
 * Ajs dependency token for AlertSyslogConfigItem factory.
 */
export const ALERT_SYSLOG_CONFIG_ITEM_TOKEN = 'AlertSyslogConfigItem';

/**
 * Ajs dependency token for AlertSyslogConfig Collection.
 */
export const ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN = 'AlertSyslogConfigCollection';

/**
 * Ajs dependency token for AlertSyslogServerConfigItem factory.
 */
export const ALERT_SYSLOG_SERVER_CONFIG_ITEM_TOKEN = 'AlertSyslogServerConfigItem';

/**
 * Ajs dependency token for ActionGroupConfig Collection.
 */
export const ACTION_GROUP_CONFIG_COLLECTION_TOKEN = 'ActionGroupConfigCollection';

/**
 * Ajs dependency token for ActionGroupConfigItem factory.
 */
export const ACTION_GROUP_CONFIG_ITEM_TOKEN = 'ActionGroupConfigItem';

/**
 * Ajs dependency token for AlertConfig Collection.
 */
export const ALERT_CONFIG_COLLECTION_TOKEN = 'AlertConfigCollection';

/**
 * Ajs dependency token for AlertConfigItem factory.
 */
export const ALERT_CONFIG_ITEM_TOKEN = 'AlertConfigItem';

/**
 * Ajs dependency token for AlertMetricsCollection factory.
 */
export const ALERT_METRICS_COLLECTION_TOKEN = 'AlertMetricsCollection';

/**
 * Ajs dependency token for AlertMetricsItem factory.
 */
export const ALERT_METRICS_ITEM_TOKEN = 'AlertMetricsItem';
