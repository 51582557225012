/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'tenancy-mode-config';
const prefix = `${moduleName}.${componentName}`;

export const ipRouteDomainLabel = `${prefix}.ipRouteDomainLabel`;
export const ipRouteDomainPerTenantLabel = `${prefix}.ipRouteDomainPerTenantLabel`;
export const ipRouteDomainShareAcrossTenantsLabel = `${prefix}.ipRouteDomainShareAcrossTenantsLabel`;
export const seContextLabel = `${prefix}.seContextLabel`;
export const seTenantContextLabel = `${prefix}.seTenantContextLabel`;
export const seProviderContextSharedLabel = `${prefix}.seProviderContextSharedLabel`;
export const tenantSeAccessLabel = `${prefix}.tenantSeAccessLabel`;
export const tenantSeNoAccessLabel = `${prefix}.tenantSeNoAccessLabel`;

export const ENGLISH = {
    [ipRouteDomainLabel]: 'IP Route Domain',
    [ipRouteDomainPerTenantLabel]: 'Per Tenant',
    [ipRouteDomainShareAcrossTenantsLabel]: 'Share Across Tenants',
    [seContextLabel]: 'Service Engine Context',
    [seTenantContextLabel]: 'Tenant Context',
    [seProviderContextSharedLabel]: 'Provider Context (Shared)',
    [tenantSeAccessLabel]: 'Tenant Service Engine Access',
    [tenantSeNoAccessLabel]: 'No Access',
};
