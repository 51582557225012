<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleSctp | vtranslate }}</h3>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.clientRequestHeaderInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="sctp-request"
                objectType="{{ objectTypes.HealthMonitorSctp }}"
                fieldName="sctp_request"
                [(ngModel)]="editable.config.sctp_monitor.config.sctp_request"
                placeholder="{{ l10nKeys.clientRequestHeaderInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.serverResponseDataInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="sctp-response"
                objectType="{{ objectTypes.HealthMonitorSctp }}"
                fieldName="sctp_response"
                [(ngModel)]="editable.config.sctp_monitor.config.sctp_response"
                placeholder="{{ l10nKeys.serverResponseDataInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
</div>
