/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'policy-match-config-item-factory';
const prefix = `${moduleName}.${componentName}`;

export const sourceIpAddressLabel = `${prefix}.sourceIpAddressLabel`;
export const destinationIpAddressLabel = `${prefix}.destinationIpAddressLabel`;
export const sourcePortLabel = `${prefix}.sourcePortLabel`;
export const destinationPortLabel = `${prefix}.destinationPortLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const servicePortsLabel = `${prefix}.servicePortsLabel`;
export const ipAddrMatchLabel = `${prefix}.ipAddrMatchLabel`;
export const ipReputationMatchLabel = `${prefix}.ipReputationMatchLabel`;
export const portMatchLabel = `${prefix}.portMatchLabel`;
export const protocolMatchLabel = `${prefix}.protocolMatchLabel`;
export const methodMatchLabel = `${prefix}.methodMatchLabel`;
export const httpVersionMatchLabel = `${prefix}.httpVersionMatchLabel`;
export const pathMatchLabel = `${prefix}.pathMatchLabel`;
export const queryMatchLabel = `${prefix}.queryMatchLabel`;
export const headerMatchLabel = `${prefix}.headerMatchLabel`;
export const cookieMatchLabel = `${prefix}.cookieMatchLabel`;
export const hostHeaderMatchLabel = `${prefix}.hostHeaderMatchLabel`;
export const l4RulePortMatchLabel = `${prefix}.l4RulePortMatchLabel`;
export const l4RuleProtocolMatchLabel = `${prefix}.l4RuleProtocolMatchLabel`;

export const ENGLISH = {
    [sourceIpAddressLabel]: 'Source IP Address',
    [destinationIpAddressLabel]: 'Destination IP Address',
    [sourcePortLabel]: 'Source Port',
    [destinationPortLabel]: 'Destination Port',
    [protocolLabel]: 'Protocol',
    [servicePortsLabel]: 'Service Ports',
    [ipAddrMatchLabel]: 'Client IP',
    [ipReputationMatchLabel]: 'IP Reputation',
    [portMatchLabel]: 'Service Port',
    [protocolMatchLabel]: 'Protocol Type',
    [methodMatchLabel]: 'HTTP Method',
    [httpVersionMatchLabel]: 'HTTP Version',
    [pathMatchLabel]: 'Path',
    [queryMatchLabel]: 'Query',
    [headerMatchLabel]: 'Headers',
    [cookieMatchLabel]: 'Cookie',
    [hostHeaderMatchLabel]: 'Host Header',
    [l4RulePortMatchLabel]: 'Listening Ports',
    [l4RuleProtocolMatchLabel]: 'L4 Protocol',
};
