/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function listCollDataSourceWithISearchParamFactory(ListCollDataSource) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.ListCollDataSource
     * @author Kondiparthi Shanmukha Sarath.
     * @desc
     *     ListCollDataSource with 'isearch' as a search param name. Used for custom
     *     APIs which don't support the usual "name.icontains".
     */
    class ListCollDataSourceWithISearchParam extends ListCollDataSource {}

    ListCollDataSourceWithISearchParam.prototype.defaultSearchParamName_ = 'isearch';

    return ListCollDataSourceWithISearchParam;
}

listCollDataSourceWithISearchParamFactory.$inject = [
    'ListCollDataSource',
];

angular.module('avi/dataModel').factory(
    'ListCollDataSourceWithISearchParam',
    listCollDataSourceWithISearchParamFactory,
);
