/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function textareaFactory() {
    function textareaLink(scope, element, attrs, ngModel) {
        if (_.isUndefined(ngModel)) {
            return;
        }

        /**
         * User deleting input results in empty string model. API expects no value (undefined).
         * This function handles that.
         * @alias module:directives/textarea.handleEmptyString
         */
        function handleEmptyString() {
            if (ngModel.$modelValue === '') {
                ngModel.$setViewValue(undefined);
            }
        }

        ngModel.$viewChangeListeners.push(handleEmptyString);
    }

    return {
        require: '?ngModel',
        restrict: 'E',
        link: textareaLink,
    };
}

/**
 * @ngdoc directive
 * @name  textarea
 * @module directives/textarea
 * @author Akul Aggarwal
 * @description
 *
 *      Wrapper on top of textarea, to add custom functionality.
 *
 */
angular.module('avi/component-kit').directive('textarea', textareaFactory);
