/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
// @ts-expect-error
import * as d3 from 'd3';
import { DevLoggerService } from 'dev-logger-service';
import { L10nService } from '@vmw/ngx-vip';
import { isDistinct } from 'ng/shared/utils';
import {
    IAviBarGraphColors,
    IAviBarGraphDataPoint,
    IAviBarGraphDataPointValueInput,
    TTimestampFormatFromApi,
    TTimestampFormatFromApiWithMicroS,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';
import {
    IVsLogsGraphResponseData,
    IVsLogsGraphResponseResultData,
    TVsLogsGraphStateParams,
} from '../../vs-logs.types';
import { VsLogsStore } from '../../services/vs-logs.store';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';
import { VsLogsApiRequestService } from '../../services/vs-logs-api-request.service';
import * as l10n from './vs-logs-graph.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IStateTypes {
    end: TTimestampFormatFromApiWithMicroS;
    start: TTimestampFormatFromApiWithMicroS;
    isLoading: boolean;
    hasError: boolean;
    legendList: IAviBarGraphColors,
    vsLogsGraphResults: IVsLogsGraphResponseResultData[];
}

/**
 * Needed to handle cancellation of request if previous request hangs incomplete.
 */
const VS_LOGS_GRAPH_REQUEST_ID = 'VS_LOGS_GRAPH_REQUEST';

const initialState: IStateTypes = {
    end: String(),
    start: String(),
    hasError: false,
    isLoading: true,
    legendList: {},
    vsLogsGraphResults: [],
};

/**
 * Converts timestamp returned by api for individual data-points to standard time-format.
 */
function parseTime(input: TTimestampFormatFromApiWithMicroS | TTimestampFormatFromApiWithMicroS):
TTimestampFormatFromApi {
    switch (input.length) {
        case 32:
            return d3.timeParse('%Y-%m-%dT%H:%M:%S.%f%Z')(input);
        case 25:
            return d3.timeParse('%Y-%m-%dT%H:%M:%S%Z')(input);
    }

    return d3.timeParse('%Y-%m-%dT%H:%M:%S.%f%Z')(input);
}

/**
 * Only values which when altered, must prompt a new api request.
 */
export type TValuesAffectingGraph = Omit<IStateTypes, 'isLoading'>;

const vsLogsGraphColorLegendMap: IAviBarGraphColors = {
    nonSignificant: {
        color: 'var(--avi-clr-prussian-blue)',
        label: '',
    },
    significant: {
        color: 'var(--avi-clr-viking-blue)',
        label: '',
    },
};

/**
 * @description State and Effect management for VsLogsGraphComponent.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Injectable()
export class VsLogsGraphStore extends ComponentStore<IStateTypes> implements OnDestroy {
    // ********************************** Selectors **********************************

    public readonly graphBarData$: Observable<IAviBarGraphDataPoint[]> =
    this.select(({ vsLogsGraphResults }) => {
        return vsLogsGraphResults.map(
            (result: IVsLogsGraphResponseResultData): IAviBarGraphDataPoint => {
                const values: IAviBarGraphDataPointValueInput[] = [];

                const {
                    adf,
                    end,
                    start,
                    nf,
                    udf,
                    value: totalValueSummation,
                } = result;

                if (adf) {
                    values.push({
                        color: vsLogsGraphColorLegendMap.significant.color,
                        count: adf,
                        type: vsLogsGraphColorLegendMap.significant.label,
                    });
                }

                if (nf || udf) {
                    values.push({
                        color: vsLogsGraphColorLegendMap.nonSignificant.color,
                        count: (nf || 0) + (udf || 0),
                        type: vsLogsGraphColorLegendMap.nonSignificant.label,
                    });
                }

                return {
                    end,
                    start,
                    totalValueSummation,
                    values,
                };
            },
        );
    })
        .pipe(
            isDistinct(),
        );

    public readonly end$: Observable<TTimestampFormatFromApi> =
    this.select(({ end }) => parseTime(end));

    public readonly start$: Observable<TTimestampFormatFromApi> =
    this.select(({ start }) => parseTime(start));

    public readonly isLoading$ =
    this.select(({ isLoading }) => isLoading);

    public readonly hasError$ =
    this.select(({ hasError }) => hasError);

    public readonly currentGraphColorsLegend$ =
    this.select(({ legendList }) => legendList);

    // ********************************** Effects **********************************

    /**
     * Call logs API for logs graph data until all data is returned or timeout.
     * Upon each API return, it updates the state and makes a new request if needed.
     */
    public readonly getVsLogsGraphData: (apiParams: TVsLogsGraphStateParams) => void;

    /**
     * Fetch updated logs graph data.
     */
    public readonly refreshGraph: () => void;

    // **************************** Updaters ******************************

    /**
     * Notify subscribers on graph reload, when user interacts with
     * significant/non-significant checkboxes, timeframe, and logs filter.
     */
    public readonly startLoadingSubject = new Subject<void>();

    /**
     * Set the isLoading flag.
     */
    private readonly setIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    /**
     * Update the state with the graph data.
     */
    private readonly setGraphData = this.updater((state, data: IVsLogsGraphResponseData) => ({
        ...state,
        end: data.end,
        start: data.start,
        vsLogsGraphResults: data.results,
    }));

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        vsLogsEffectsService: VsLogsEffectsService,
        private readonly vsLogApiRequestService: VsLogsApiRequestService,
        private readonly devLoggerService: DevLoggerService,
        l10nService: L10nService,
    ) {
        super(initialState);

        l10nService.registerSourceBundles(dictionary);

        // Initialize request and refresh effects
        const requestVsLogsGraphDataEffect =
            vsLogsEffectsService.createVsLogsRecursiveRequestEffect(
                this,
                this.startLoading,
                this.stopLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiLoopComplete,
            );

        this.getVsLogsGraphData = (apiParams: TVsLogsGraphStateParams): void => {
            requestVsLogsGraphDataEffect({
                apiParams,
                config: {
                    requestId: VS_LOGS_GRAPH_REQUEST_ID,
                },
            });
        };

        this.refreshGraph = this.effect(
            VsLogsEffectsService.createRefreshEffect<TVsLogsGraphStateParams>(
                vsLogsStore.vsLogsGraphApiParams$,
                this.getVsLogsGraphData,
            ),
        );

        const {
            nonSignificantLabel,
            significantLabel,
        } = l10nKeys;

        vsLogsGraphColorLegendMap.significant.label = l10nService.getMessage(significantLabel);
        vsLogsGraphColorLegendMap.nonSignificant.label =
            l10nService.getMessage(nonSignificantLabel);

        this.subscribeToBooleanRequestParamsChange();
    }

    // ********************************** Methods **********************************

    /** @override */
    public ngOnDestroy(): void {
        this.vsLogApiRequestService.cancelRequest(VS_LOGS_GRAPH_REQUEST_ID);
    }

    /**
     * Subscribe to significant/non-significant boolean changes from vs-logs store.
     */
    private subscribeToBooleanRequestParamsChange = (): void => {
        this.vsLogsStore.logParamRequestTypesSelected$.subscribe(types => {
            this.patchState(() => {
                const legendList = types.reduce((result, type) => {
                    return {
                        ...result,
                        [type]: vsLogsGraphColorLegendMap[type],
                    };
                }, {});

                return { legendList };
            });
        });
    };

    /**
     * Clear error and timeout states and set loading states.
     */
    private startLoading = (): void => {
        this.setErrorState(false);
        this.vsLogsStore.setIsTimedOut(false);
        this.vsLogsStore.setisErrorBannerEnabled(true);
        this.vsLogsStore.setisTimeoutBannerEnabled(true);
        this.setIsLoading(true);
        this.vsLogsStore.setIsGraphApiLoopInProgress(true);
        this.startLoadingSubject.next();
    };

    /**
     * Set isLoading to false.
     */
    private stopLoading = (): void => {
        this.setIsLoading(false);
    };

    /**
     * Handles response from API once retrieved.
     */
    private handleApiData = (data: IVsLogsGraphResponseData): void => {
        this.setGraphData(data);
    };

    /**
     * Handles instance of API returning error.
     */
    private handleApiError = (err: any): void => {
        this.devLoggerService.error(err);
        this.setErrorState(true);
        this.stopLoading();
        this.vsLogsStore.setIsGraphApiLoopInProgress(false);
    };

    /**
     * Set API loop state upon loop completion.
     */
    private handleApiLoopComplete = (isTimedOut: boolean): void => {
        this.vsLogsStore.setIsGraphApiLoopInProgress(false);

        if (isTimedOut) {
            this.vsLogsStore.setIsTimedOut(true);
        }
    };

    /**
     * Set error state on local component store and module global store
     * (for component-level and page-level error messages).
     */
    private setErrorState = (hasError: boolean): void => {
        this.vsLogsStore.setHasError(hasError);
        this.patchState({ hasError });
    };
}
