/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import moment from 'moment';
import {
    Inject,
    Injectable,
    OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ControllerInitialDataActions } from 'ng/root-store/controller-initial-data-store';
import {
    IControllerInitialDataApiResponse,
} from 'ng/root-store/controller-initial-data-store/controller-initial-data.state';

import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';

import { OauthProvider } from 'generated-types';

const INITIAL_DATA_URL =
    '/api/initial-data?include_name&treat_expired_session_as_unauthenticated=true';

const INITIAL_DATA_REQUEST_ID = 'initial-data-request';
const SESSION_REFRESH_HEADER = 'X-Avi-Sessionrefresh';

interface IVersionDataApiResponse {
    build: number;
    Date: string;
    patch?: string;
    Tag: string;
    Version: string;
}

interface ICommonInitialData {
    banner: string;
    current_time: string;
    email_configuration_is_set: boolean;
    error_message: string;
    is_aws_cloud: boolean;
    oauth_provider: OauthProvider;
    redirect_to_oauth_provider: boolean;
    redirect_uri: string;
    setup_failed: boolean;
    sso: boolean;
    sso_logged_in: boolean;
    user_initial_setup: boolean;
    welcome_workflow_complete: boolean;
}

interface IInitialDataApiResponse extends ICommonInitialData {
    version: IVersionDataApiResponse;
}

interface IVersionData {
    build: number;
    date: string;
    patch?: string;
    tag: string;
    version: string;
}

interface IRequestOptions {
    headers?: {
        Authorization: string,
        [SESSION_REFRESH_HEADER]: string,
    },
}

interface IInitialData extends ICommonInitialData {
    timeDifference: number;
    version: IVersionData;
}

type THttpWrapper = typeof HttpWrapper;

/**
 * @description
 *     Service responsible for fetching application's initial data hash and
 *     has specific public methods to return the properties from initial data.
 * @author chitra, Alex Malitsky, Zhiqian Liu
 */
@Injectable({
    providedIn: 'root',
})
export class InitialDataService implements OnDestroy {
    /** Initial Data fetched from initial-data API. */
    private data: IInitialData = null;

    /**
     * Flag to indicate whether initial-data API request is in process.
     */
    private loadPromise: Promise<void> = null;

    private readonly requestOptions: IRequestOptions = {};

    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly store: Store,
        // need @Inject since HttpWrapper is injected as a class, not an instance
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelRequest(INITIAL_DATA_REQUEST_ID);
    }

    /**
     * Returns HTTP Promise that is to be resolved with undefined.
     * @param force - Flag to force the initial data load request
     */
    public loadData(force = false): Promise<void> {
        if (this.loadPromise) {
            return this.loadPromise;
        }

        if (!force && this.hasData()) {
            return Promise.resolve();
        }

        this.setSessionRefreshHeader();
        this.setAccessTokenInRequestOptions();

        const requestConfig = {
            url: INITIAL_DATA_URL,
            method: HttpMethod.GET,
            requestId: INITIAL_DATA_REQUEST_ID,
            ...this.requestOptions,
        };

        const promise = this.httpWrapper.request(requestConfig)
            .then(({ data }: { data?: IInitialDataApiResponse }) => {
                this.loadDataToStore({ ...data as IControllerInitialDataApiResponse });
                this.processData(data);
            })
            .catch(error => Promise.reject(error.data))
            .finally(() => this.loadPromise = null) as Promise<void>;

        this.loadPromise = promise;

        return promise;
    }

    /**
     * Checks if application already has initial data.
     */
    public hasData(): boolean {
        return this.data !== null;
    }

    /**
     * Returns the controller's patch version.
     */
    public get controllerPatch(): string {
        return this.data?.version.patch || '';
    }

    /**
     * Returns version of the controller.
     */
    public get controllerVersion(): string {
        return this.data?.version.version || '';
    }

    /**
     * Returns version build of the controller.
     */
    public get controllerBuild(): number {
        return this.data?.version.build || NaN;
    }

    /**
     * Returns version date of the controller.
     */
    public get controllerBuildDate(): string {
        return this.data?.version.date || '';
    }

    /**
     * Returns timeDifference between client and server(controller) in seconds.
     */
    public get controllerTimeDifference(): number {
        return this.data?.timeDifference;
    }

    /**
     * Returns error message if present in initialData.
     */
    public get controllerErrorMessage(): string {
        return this.data?.error_message || '';
    }

    /**
     * Returns banner if present in initialData.
     */
    public get controllerBanner(): string {
        return this.data?.banner || '';
    }

    /**
     * Returns true if initial set up is failed.
     */
    public get isSetupFailed(): boolean {
        return this.data?.setup_failed || false;
    }

    /**
     * Returns true if administrator user account setup not yet done.
     */
    public get isAdminUserSetupRequired(): boolean {
        return this.data?.user_initial_setup || false;
    }

    /**
     * Returns true if the controller created in AWS cloud.
     */
    public get isAwsCloud(): boolean {
        return this.data?.is_aws_cloud || false;
    }

    /**
     * Returns true if sso flag is enabled.
     */
    public get isSsoEnabled(): boolean {
        return this.data?.sso || false;
    }

    /**
     * Returns true if sso_logged_in enabled.
     */
    public get isSsoLoggedIn(): boolean {
        return this.data?.sso_logged_in || false;
    }

    /**
     * Returns true if email_configuration_is_set is set.
     */
    public get isEmailConfigurationSet(): boolean {
        return this.data?.email_configuration_is_set || false;
    }

    /**
     * Returns true if the controller set up done.
     */
    public get isWelcomeWorkflowCompleted(): boolean {
        return this.data?.welcome_workflow_complete || false;
    }

    /**
     * Evaluate condition for OAuth redirection.
     */
    public get isOauthRedirection(): boolean {
        return Boolean(this.data?.redirect_to_oauth_provider && this.data?.oauth_provider);
    }

    /**
     * Return OAuth provider for the controller.
     */
    public get oauthProvider(): OauthProvider | undefined {
        return this.data?.oauth_provider;
    }

    public get redirectUri(): string | undefined {
        return this.data?.redirect_uri;
    }

    /**
     * Use regex to get access token from cookie.
     * access token is stored in cookie as 'avi_access_token=<access-token>'.
     */
    private get aviAccessToken(): string {
        const accessToken = document.cookie.split(/(?:avi_access_token=)(\S*)/s)[1];

        return accessToken?.replace(';', '');
    }

    /**
     * Load the data returned from the Initial-Data API into the
     * controller-initial-data NgRx store.
     */
    private loadDataToStore(controllerInitialData: IControllerInitialDataApiResponse): void {
        this.store.dispatch(
            ControllerInitialDataActions.setControllerInitialData({ controllerInitialData }),
        );
    }

    /**
     * Processes the initial Data received from API response.
     * @param data
     */
    private processData(data: IInitialDataApiResponse): void {
        const initialData = {} as unknown as IInitialData;

        // time difference between client and server(controller) in seconds
        // current_time comes with timezone info
        initialData.timeDifference = moment().diff(moment(data.current_time), 's');

        // Backend exposes version information only when user is authenticated.
        // So version can be undefined when we call initial-data API before login.
        const { version = {} as unknown as IVersionDataApiResponse } = data;

        const {
            Version: versionStr,
            Date: buildDate,
            Tag: tag,
            build,
            patch,
        } = version;

        initialData.version = {
            version: versionStr,
            date: buildDate,
            build,
            tag,
            patch,
        };

        this.data = Object.assign(data, initialData);
    }

    /**
     * Get request options for initial-data api get call.
     */
    private setAccessTokenInRequestOptions(): void {
        const { aviAccessToken } = this;

        if (aviAccessToken) {
            this.requestOptions.headers = {
                ...this.requestOptions.headers,
                Authorization: `Bearer ${aviAccessToken}`,
            };
        }
    }

    /**
     * Set session refresh header, on initial-data api to start/refresh an UI session.
     */
    private setSessionRefreshHeader(): void {
        this.requestOptions.headers = {
            ...this.requestOptions.headers,
            [SESSION_REFRESH_HEADER]: new Date().toJSON(),
        };
    }
}
