/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name HealthScoreMetric
 * @description
 *
 *     Health score series is fetched through GET API requests, therefore we don't extend
 *     CollMetric here. The only difference besides that is the fact that it doesn't support
 *     realtime timeframe, meaning step can't be smaller than five minutes.
 *
 */
angular.module('aviApp').factory('HealthScoreMetric', [
'Metric', 'Timeframe',
function(Metric, Timeframe) {
    return class HealthScoreMetric extends Metric {
        constructor(args = {}) {
            const seriesName = 'health_score';

            args.name = args.name || seriesName;
            args.series = args.series || [seriesName];

            super(args);
        }

        /** @override */
        beforeCall() {
            const reqConfigs = super.beforeCall();

            if (!reqConfigs) {
                return null;
            }

            const
                { series: config } = reqConfigs,
                { key: tfKey } = Timeframe.selected();

            // real time is not supported by health score API, still getting 30 minutes
            if (tfKey === 'rt') {
                const { params } = this;
                const step = 300;
                const limit = 6;

                params.step = step;
                config.step = step;
                params.limit = limit;
                config.limit = limit;
            }

            return reqConfigs;
        }

        /** @override */
        requestConfig() {
            return true;
        }

        /** @override */
        afterCall(resp) {
            return super.afterCall(resp && resp['series']);
        }
    };
}]);
