/**
 * @module VerticalNavModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { any } from 'underscore';
import {
    ElementRef,
    Inject,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { IAppState } from 'ajs/js/services/appStates.types';

/**
 * Object generated by parsing the full name of a state. Logic duplicated from ui-router.
 */
interface IStateRefObject {
    state: string;
    paramExpr: string;
}

/**
 * Parses the state string and splits up the state name from the params. This logic is
 * duplicated from ui-router.
 */
const getStateRefObject = (stateRef: string): IStateRefObject => {
    let ref = stateRef;
    const paramsOnly = stateRef.match(/^\s*({[^}]*})\s*$/);

    if (paramsOnly) {
        ref = `(${paramsOnly[1]})`;
    }

    const parsed = ref.replace(/\n/g, ' ').match(/^\s*([^(]*?)\s*(\((.*)\))?\s*$/);

    if (!parsed || parsed.length !== 4) {
        throw new Error(`Invalid state ref ${ref}`);
    }

    return {
        state: parsed[1] || null,
        paramExpr: parsed[3] || null,
    };
};

/**
 * @description Pipe to check if a navigation state is active.
 * @author alextsg
 */
@Pipe({
    name: 'isActiveState',
})
export class IsActiveStatePipe implements PipeTransform {
    constructor(
        @Inject('$state')
        private readonly $state: StateService,
        private elementRef: ElementRef,
    ) {}

    /**
     * Transform function needed to implement PipeTransform. currentStateName is passed in for a
     * lean state comparison, but more importantly it's used to rerun the pipe when the current
     * state changes since pipes are pure functions.
     */
    public transform(state: IAppState, currentStateName: string): boolean {
        return state.fullName === currentStateName || this.isActive(state);
    }

    /**
     * Returns true if the state is active, i.e. the page the user is currently on.
     */
    private isActive(state: IAppState): boolean {
        if (Array.isArray(state.activeStates)) {
            return any(state.activeStates, activeState => this.$state.includes(activeState));
        } else if (Array.isArray(state.children)) {
            return any(state.children, childState => this.isActive(childState));
        } else {
            const stateRefObject = getStateRefObject(state.fullName);
            const stateObject = this.$state.get(stateRefObject.state, this.getStateContext());

            return this.$state.includes(stateObject.name);
        }
    }

    /**
     * Returns the context or parent level of the state. This logic is duplicated from ui-router.
     */
    private getStateContext(): string {
        const $uiView = $(this.elementRef.nativeElement).parent().inheritedData('$uiView');
        const path = $uiView && $uiView.$cfg && $uiView.$cfg.path;

        return Array.isArray(path) && path[path.length - 1].state.name;
    }
}
