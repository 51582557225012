/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { L10nService } from '@vmw/ngx-vip';
import { IDNSConfig } from 'generated-types';
import { DNSConfig } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    GslbSubdomainModalComponent,
} from 'ng/modules/gslb/components/gslb-subdomain-modal/gslb-subdomain-modal.component';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import * as l10n from './dns-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description DNSConfig ConfigItem class.
 *
 * @author Nisar Nadaf
 */
export class DNSConfigConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IDNSConfig, typeof MessageItem>(MessageItem),
)<IDNSConfig> {
    public static ngDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: DNSConfig,
            windowElement: GslbSubdomainModalComponent,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.dnsConfigModalBreadcrumbTitle);
    }
}
