/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { AlertEmailConfigItem } from 'ajs/modules/alert';
import { L10nService } from '@vmw/ngx-vip';
import { AlertEmailConfig } from 'object-types';

import * as globalL10n from 'global-l10n';
import * as l10n from './alert-email-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'alert-email-modal',
    templateUrl: './alert-email-modal.component.html',
})
export class AlertEmailModalComponent {
    @Input()
    public editable: AlertEmailConfigItem;

    public readonly objectType = AlertEmailConfig;

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
