/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'login';
const componentName = 'password-change';
const prefix = `${moduleName}.${componentName}`;

export const changePasswordLabel = `${prefix}.changePasswordLabel`;
export const newPasswordPlaceholder = `${prefix}.newPasswordPlaceholder`;
export const confirmPasswordPlaceholder = `${prefix}.confirmPasswordPlaceholder`;
export const passwordsUnmatchedErrorMessage = `${prefix}.passwordsUnmatchedErrorMessage`;
export const passwordChangeSuccessMessage = `${prefix}.passwordChangeSuccessMessage`;

export const ENGLISH = {
    [changePasswordLabel]: 'Change Password',
    [newPasswordPlaceholder]: 'New Password',
    [confirmPasswordPlaceholder]: 'Confirm Password',
    [passwordsUnmatchedErrorMessage]: 'Passwords don\'t match',
    [passwordChangeSuccessMessage]: 'Password updated Successfully. You\'ll be redirected to login.',
};
