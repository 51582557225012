/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    HealthMonitorCollection,
    THealthMonitorCollection,
} from 'ajs/modules/healthmonitor/factories/health-monitor.collection.factory';

import { GSLBService } from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { GslbService } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-health-monitors-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Health Monitors Grid component.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-health-monitors-grid',
    templateUrl: './gslb-service-health-monitors-grid.component.html',
})
export class GslbServiceHealthMonitorsGridComponent implements OnInit, OnDestroy {
    @Input()
    public editable: GSLBService;

    /**
     * TemplateRef for Health monitor dropdown.
     */
    @ViewChild('healthMonitorTemplateRef')
    public healthMonitorTemplateRef: TemplateRef<HTMLElement>;

    public healthMonitorCollection: HealthMonitorCollection;

    public readonly objectType = GslbService;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Maximum number of Health monitors that can be added.
     */
    public maxHealthMonitors: number;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(HealthMonitorCollection)
        public HealthMonitorCollection: THealthMonitorCollection,
        private readonly schemaService: SchemaService,
    ) {
        const params = { is_federated: true };

        this.healthMonitorCollection = new HealthMonitorCollection({
            params,
            defaults: params,
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.maxHealthMonitors = this.schemaService.getFieldMaxElements(
            this.editable.messageType,
            'health_monitor_uuids',
        );
    }

    /** @override */
    public ngOnDestroy(): void {
        this.healthMonitorCollection.destroy();
    }

    /**
     * Return true if more Health monitors can be added.
     */
    public allowAddNewHealthMonitor(): boolean {
        return this.editable.config.health_monitor_refs.length < this.maxHealthMonitors;
    }
}
