/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { IDnsServiceDomain } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { DnsServiceDomain } from 'object-types';
import { SchemaService } from 'ajs/modules/core/services';

import './internal-dns-service-domains-grid.component.less';
import * as globalL10n from 'global-l10n';
import * as l10n from './internal-dns-service-domains-grid.l10n';

const { ENGLISH, ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for internal profile dns_service_domain configuration.
 * *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'internal-dns-service-domains-grid',
    templateUrl: './internal-dns-service-domains-grid.component.html',
})
export class InternalDnsServiceDomainsGrid implements AfterViewInit {
    /**
     * Binding for ipam#internal_profile#dns_service_domain.
     */
    @Input()
    public dnsServiceDomains: RepeatedMessageItem<MessageItem<IDnsServiceDomain>>;

    /**
     * Fires on Add Dns Service Domain.
     */
    @Output()
    public onAddDnsServiceDomain = new EventEmitter<void>();

    /**
     * Fires on Delete Dns Service Domain.
     */
    @Output()
    public onDeleteDnsServiceDomain = new EventEmitter<MessageItem<IDnsServiceDomain>>();

    /**
     * TemplateRef for domain name input field.
     */
    @ViewChild('domainNameTemplateRef')
    public domainNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for override TTL input field.
     */
    @ViewChild('recordTtlTemplateRef')
    public recordTtlTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Dns service domain grid config.
     */
    public internalDnsServiceDomainsGrid: IAviDataGridConfig;

    /**
     * Prefix for domain name input id & name.
     */
    public readonly internalDnsServiceDomainNamePrefix =
    'internal_dns_service_domain_name_';

    /**
     * Prefix for record TTL input id & name.
     */
    public readonly internalDnsServiceDomainRecordTtlPrefix =
    'internal_dns_service_domain_record_ttl_';

    /**
     * Contains duplicate value of the AviDataGrid.
     */
    public duplicateValues: string[] = [];

    /**
     * Flag for whether there are duplicate values.
     */
    public hasDuplicateError = false;

    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * ObjectType of IpamDnsInternalProfile.
     */
    public readonly objectType = DnsServiceDomain;

    public readonly recordTtlRange: string;

    constructor(
        private readonly l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const range = l10nService.getMessage(l10nKeys.rangeLabel);
        const rangeValue = schemaService.getFieldRangeAsText(this.objectType, 'record_ttl');

        this.recordTtlRange = `${range} ${rangeValue}`;
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.internalDnsServiceDomainsGrid = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleDomainName),
                id: 'domain_name',
                templateRef: this.domainNameTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleRecordTtl),
                id: 'record_ttl',
                templateRef: this.recordTtlTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (domains: Array<MessageItem<IDnsServiceDomain>>) => {
                    domains.forEach((domain: MessageItem<IDnsServiceDomain>) => {
                        this.deleteDnsServiceDomain(domain);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (domain: MessageItem<IDnsServiceDomain>) => {
                    this.deleteDnsServiceDomain(domain);
                },
            }],
        };
    }

    /**
     * To find duplicates values in grid.
     */
    public findDuplicateValues(): void {
        const uniqueElements = new Set();

        this.duplicateValues = [];

        this.dnsServiceDomains.config.forEach(
            (dnsServiceDomainConfigItem: MessageItem<IDnsServiceDomain>) => {
                const { domain_name: domainName } = dnsServiceDomainConfigItem.config;

                if (domainName) {
                    if (uniqueElements.has(domainName)) {
                        this.duplicateValues.push(domainName);

                        return;
                    }

                    uniqueElements.add(domainName);
                }
            },
        );

        this.hasDuplicateError = this.duplicateValues.length > 0;
    }

    /**
     * Emits add dns service domain.
     */
    public addDnsServiceDomain(): void {
        this.onAddDnsServiceDomain.emit();
    }

    /**
     * Deletes add dns service domain.
     */
    private deleteDnsServiceDomain(domain: MessageItem<IDnsServiceDomain>): void {
        this.onDeleteDnsServiceDomain.emit(domain);
        this.findDuplicateValues();
    }
}
