/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-file-grid';
const prefix = `${moduleName}.${componentName}`;

export const geoDbFileAddButtonLabel = `${prefix}.geoDbFileAddButtonLabel`;
export const geoDbFileColTitleName = `${prefix}.geoDbFileColTitleName`;
export const geoDbFileColTitleEnabled = `${prefix}.geoDbFileColTitleEnabled`;
export const geoDbFileColTitleVendor = `${prefix}.geoDbFileColTitleVendor`;
export const geoDbFileColTitleGeoDbFile = `${prefix}.geoDbFileColTitleGeoDbFile`;
export const geoDbFileRemoveButtonLabel = `${prefix}.geoDbFileRemoveButtonLabel`;
export const geoDbFileEditButtonLabel = `${prefix}.geoDbFileEditButtonLabel`;

export const ENGLISH = {
    [geoDbFileAddButtonLabel]: 'Add',
    [geoDbFileColTitleName]: 'Name',
    [geoDbFileColTitleEnabled]: 'Enabled',
    [geoDbFileColTitleVendor]: 'Vendor',
    [geoDbFileColTitleGeoDbFile]: 'GeoDB File',
    [geoDbFileRemoveButtonLabel]: 'Remove',
    [geoDbFileEditButtonLabel]: 'Edit',
};
