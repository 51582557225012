/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'infrastructure';
const componentName = 'custom-tags';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitle = `${prefix}.sectionTitle`;
export const customTagsLabel = `${prefix}.customTagsLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnKeyPlaceholder = `${prefix}.columnKeyPlaceholder`;
export const columnValuePlaceholder = `${prefix}.columnValuePlaceholder`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [sectionTitle]: 'Tags',
    [customTagsLabel]: 'Key & Value(s) ({0})',
    [addButtonLabel]: 'Add',
    [columnKeyPlaceholder]: 'Enter Key',
    [columnValuePlaceholder]: 'Enter Value',
    [removeActionLabel]: 'Remove',
};
