<div class="right-rail-vertical-expander">
    <div
        class="right-rail-vertical-expander__header"
        (click)="toggleExpand()"
        *ngIf="showHeaderSection"
    >
        <cds-icon
            class="right-rail-vertical-expander__header-leading-icon"
            *ngIf="icon"
            shape="{{ icon }}"
            size="16"
        ></cds-icon>
        <div class="right-rail-vertical-expander__header-text">
            {{ headerText }}
        </div>
        <cds-icon
            class="right-rail-vertical-expander__header-expander"
            shape="angle"
            direction="{{ expanded ? 'down' : 'right'}}"
            size="16"
        ></cds-icon>
    </div>
    <div
        class="right-rail-vertical-expander__body"
        [hidden]="!expanded"
    >
        <ng-content></ng-content>
    </div>
</div>
