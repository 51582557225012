<h6 class="matching-rule-grid-header">
    <label
        aviLabelWithTooltip
        objectType="BotManagementLog"
        fieldName="classification"
    >
        {{ l10nKeys.classificationsLabelWithCount | vtranslate : classificationsCount }}
    </label>
</h6>
<avi-data-grid
    [config]="botClassificationsGridConfig"
    [rows]="classificationRows"
>
    <avi-data-grid_actions>
        <cds-button
            (click)="addClassificationRow()"
            size="sm"
        >
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>

<ng-template
    #classificationFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-dropdown
        name="{{ 'bot_detection_classification_' + index }}"
        id="{{ 'bot_detection_classification_' + index }}"
        [(ngModel)]="row.type"
        (ngModelChange)="updateClassificationType(row)"
        [options]="botClassificationDropdownOptions"
        placeholder="{{ l10nKeys.classificationPlaceHolder | vtranslate }}"
        required
    ></avi-dropdown>
</ng-template>

<ng-template
    #classificationValueFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-input-container
        noMarginTop
        *ngIf="row.type === userDefinedBot"
    >
        <input
            name="{{ 'bot_detection_classification_value_' + index }}"
            id="{{ 'bot_detection_classification_value_' + index }}"
            [(ngModel)]="row.user_defined_type"
            clrInput
            placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
            required
        />
    </avi-input-container>
</ng-template>
