/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    CertificateRevocationReason,
    CertificateStatus,
} from 'generated-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import type {
    Certificate,
} from 'ajs/modules/security/factories/certificates/certificate.item.factory';

import * as l10n from './ssl-certificate-expander.l10n';
import './ssl-certificate-expander.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description SSL Certificate Expander.
 *
 * @author Manideep Kotha
 */

@Component({
    selector: 'ssl-certificate-expander',
    templateUrl: './ssl-certificate-expander.component.html',
})

export class SslCertificateExpander implements OnInit {
    /**
     * Certificate Item.
     */
    @Input()
    public certificate: Certificate;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Label for the revocationReason.
     */
    public revocationReasonLabel: string;

    /**
     * Label for the certStatus.
     */
    public certStatusLabel: string;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { ocspResponseInfo } = this.certificate;
        const { config } = ocspResponseInfo;

        this.revocationReasonLabel = this.getRevocationReasonLabel(config.revocation_reason);
        this.certStatusLabel = this.getCertificateStatusLabel(config.cert_status);
    }

    /**
     * Returns the revocation reason label.
     */
    private getRevocationReasonLabel(
        revocationReason: CertificateRevocationReason,
    ): string {
        let revocationReasonLabel = '';

        if (revocationReason) {
            revocationReasonLabel = this.schemaService.getEnumValueLabel(
                'CertificateRevocationReason',
                revocationReason,
            );
        }

        return revocationReasonLabel;
    }

    /**
     * Returns the certificate status label.
     */
    private getCertificateStatusLabel(
        certStatus: CertificateStatus,
    ): string {
        let certStatusLabel = '';

        if (certStatus) {
            certStatusLabel = this.schemaService.getEnumValueLabel(
                'CertificateStatus',
                certStatus,
            );
        }

        return certStatusLabel;
    }
}
