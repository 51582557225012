/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'traffic-capture-modal';
const prefix = `${moduleName}.${componentName}`;

export const submitBtnLabel = `${prefix}.submitBtnLabel`;
export const emptyCardPlaceholder = `${prefix}.emptyCardPlaceholder`;
export const modalHeader = `${prefix}.modalHeader`;
export const allTrafficRadioLabel = `${prefix}.allTrafficRadioLabel`;
export const chooseIpRadioLabel = `${prefix}.chooseIpRadioLabel`;
export const clientIpInputPlaceholder = `${prefix}.clientIpInputPlaceholder`;
export const clientIpInputLabel = `${prefix}.clientIpInputLabel`;
export const clientIpHelperText = `${prefix}.clientIpHelperText`;
export const packetCaptureTypePlaceholder = `${prefix}.packetCaptureTypePlaceholder`;
export const packetCaptureTypeLabel = `${prefix}.packetCaptureTypeLabel`;
export const srcPortInputLabel = `${prefix}.srcPortInputLabel`;
export const portInputPlaceholder = `${prefix}.portInputPlaceholder`;
export const dstPortStartInputLabel = `${prefix}.dstPortStartInputLabel`;
export const dstPortEndInputLabel = `${prefix}.dstPortEndInputLabel`;
export const packetSectionHeader = `${prefix}.packetSectionHeader`;
export const pktSizeInputLabel = `${prefix}.pktSizeInputLabel`;
export const pktSizeInputPlaceholder = `${prefix}.pktSizeInputPlaceholder`;
export const pktSizeHelperText = `${prefix}.pktSizeHelperText`;
export const captureFileSizeHelperText = `${prefix}.captureFileSizeHelperText`;
export const captureFileSizeInputLabel = `${prefix}.captureFileSizeInputLabel`;
export const captureFileSizeInputPlaceholder = `${prefix}.captureFileSizeInputPlaceholder`;
export const fileCountInputLabel = `${prefix}.fileCountInputLabel`;
export const fileCountInputPlaceholder = `${prefix}.fileCountInputPlaceholder`;

export const ENGLISH = {
    [submitBtnLabel]: 'Start Capture',
    [modalHeader]: 'Start Traffic Capture: {0}',
    [allTrafficRadioLabel]: 'All Traffic',
    [chooseIpRadioLabel]: 'Enter Client IP Addresses',
    [clientIpInputPlaceholder]: 'Enter IP Addresses',
    [clientIpInputLabel]: 'Client IP Addresses',
    [clientIpHelperText]: 'Accepts single address, ranges and subnet masks',
    [packetCaptureTypePlaceholder]: 'Select Types of Packets Capture',
    [packetCaptureTypeLabel]: 'Types of Packets Capture',
    [srcPortInputLabel]: 'Source Port',
    [portInputPlaceholder]: 'Enter Port',
    [dstPortStartInputLabel]: 'Destination Start Port',
    [dstPortEndInputLabel]: 'Destination End Port',
    [packetSectionHeader]: 'Packet',
    [pktSizeInputLabel]: 'Packet Size',
    [pktSizeInputPlaceholder]: 'Enter Packet Size',
    [pktSizeHelperText]: 'Bytes',
    [captureFileSizeHelperText]: 'Megabytes',
    [captureFileSizeInputLabel]: 'Capture File Size',
    [captureFileSizeInputPlaceholder]: 'Enter Capture File Size',
    [fileCountInputLabel]: 'File Count',
    [fileCountInputPlaceholder]: 'Enter File Count',
};
