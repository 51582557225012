/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'seg-update-gird-container';
const prefix = `${moduleName}.${componentName}`;

export const haModeLabel = `${prefix}.haModeLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const updatedOnLabel = `${prefix}.updatedOnLabel`;

export const ENGLISH = {
    [haModeLabel]: 'HA Mode',
    [patchLabel]: 'Patch',
    [updatedOnLabel]: 'Updated on',
};
