<h6>
    {{ l10nKeys.attachmentGridHeaderLabel | vtranslate : caseAttachmentsCount }}
</h6>
<avi-data-grid
    [config]="caseAttachmentsGridConfig"
    [rows]="caseAttachments"
></avi-data-grid>

<ng-template
    #statusFieldTemplateRef
    let-row
>
    <div [ngSwitch]="row.status">
        <div *ngSwitchCase="uploadStatusHash.SYSERR_ALBSVC_FILE_UPLOAD_SUCCESS">
            <cds-icon
                status="success"
                shape="check-circle"
                size="18"
            ></cds-icon>
            {{ l10nKeys.uploadSuccessLabel | vtranslate }}
        </div>
        <div *ngSwitchCase="uploadStatusHash.SYSERR_ALBSVC_FILE_UPLOAD_FAILED">
            <avi-tooltip-icon-with-content-wrapper
                shape="exclamation-circle"
                class="case-attachments-grid__icon"
                size="18"
                status="danger"
            >
                {{ getFileUploadErrorMessage(row) }}
            </avi-tooltip-icon-with-content-wrapper>
            {{ l10nKeys.uploadFailedLabel | vtranslate }}
        </div>
        <div *ngSwitchDefault>
            <clr-spinner clrInline></clr-spinner>
            {{ l10nKeys.uploadInProgressLabel | vtranslate }}
        </div>
    </div>
</ng-template>
