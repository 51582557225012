<div provideParentForm>
    <avi-radio-container>
        <avi-radio
            name="ip-address"
            [(ngModel)]="isConfiguredByTypeIp"
            (ngModelChange)="handleMemberConfigurationTypeChange()"
            [value]="true"
        >
            {{ globalL10nKeys.ipAddressLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            name="virtual-service"
            [(ngModel)]="isConfiguredByTypeIp"
            (ngModelChange)="handleMemberConfigurationTypeChange()"
            [value]="false"
        >
            {{ l10nKeys.virtualServiceLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>

    <gslb-pool-member-domain-name
        *ngIf="isConfiguredByTypeIp"
        [editable]="editable"
        [resolvedFqdnDropdownOptions]="resolvedFqdnDropdownOptions"
        [ipOrFqdnInputValue]="ipOrFqdnInputValue"
        (onIpOrFqdnChange)="handleIpOrFqdnChange($event)"
    ></gslb-pool-member-domain-name>

    <gslb-pool-member-virtual-service
        *ngIf="!isConfiguredByTypeIp"
        [editable]="editable"
        [siteClusterControllerDropdownOptions]="siteClusterControllerDropdownOptions"
        [selectedSiteVsCollection]="selectedSiteVsCollection"
        [resolvedVsVipIpDropdownOptions]="resolvedVsVipIpDropdownOptions"
        (onSiteClusterControllerChange)="handleSiteClusterControllerChange()"
        (onVsSelectionChange)="handleVsSelectionChange()"
        (onResolvedVsVipIpChange)="handleResolvedVsVipIpChange()"
    ></gslb-pool-member-virtual-service>
</div>
