/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-psm-group-modal-preview';
const prefix = `${moduleName}.${componentName}`;

export const notesHeader = `${prefix}.notesHeader`;
export const missActionNoOperationLabel = `${prefix}.missActionNoOperationLabel`;
export const missActionNoOperationMessage = `${prefix}.missActionNoOperationMessage`;

export const ENGLISH = {
    [notesHeader]: 'Notes',
    [missActionNoOperationLabel]: 'Miss Action: No Operation',
    [missActionNoOperationMessage]: 'Parameters not matching PSM rule will be sent to Signatures for evaluation.',
};
