/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export const enum Timeframe {
    REAL_TIME = 'rt',
    PAST_SIX_HOURS = '6h',
    PAST_DAY = '1d',
    PAST_WEEK = '1w',
    PAST_MONTH = '1m',
    PAST_QUARTER = '1q',
    PAST_YEAR = '1y',
}

export interface IGridInfo {
    columnSortOrder?: string;
    displayFields?: string[];
    columnWidths?: Record<string, number>;
}

type TGridData = {
    [key: string]: IGridInfo;
} & {
    pageSize?: number;
};

export interface ISavedSearch {
    name: string;
    search: string[];
}

interface ILogData {
    savedSearch?: ISavedSearch[],
    hideLogSummaries?: boolean;
}

interface IAppDashboard {
    viewType?: 'tree' | 'list' | 'gslbservice';
}

/**
 * Values for timeframe data.
 */
export type TTimeframe = Timeframe.REAL_TIME | Timeframe.PAST_SIX_HOURS | Timeframe.PAST_DAY |
Timeframe.PAST_WEEK | Timeframe.PAST_MONTH | Timeframe.PAST_QUARTER | Timeframe.PAST_YEAR;

/**
 * Values for metrics data.
 */
export type TValuesToDisplay = 'avg' | 'current' | 'max' | 'sum';

/**
 * Prop to determine shown version of vs-logs page.
 */
export const SHOW_NEW_VS_LOGS_PAGE = 'showNewVsLogsPage';

/**
 * Prop to determine shown version of vs-logs page.
 */
export interface IUIProperty {
    appDashboard?: IAppDashboard;
    timeframe?: TTimeframe;
    grid?: TGridData;
    language?: 'en_US' | 'zh-Hans' | 'ja';
    logs?: ILogData;
    performanceControls?: Record<string, boolean>;
    showMetricsLogs?: boolean; // deprecated, for prior 16.3v
    [SHOW_NEW_VS_LOGS_PAGE]?: boolean;
    useUTCTime?: boolean;
    valuesToDisplay?: TValuesToDisplay;
}

export interface IUserPreferences {
    ui_property: IUIProperty;
}

export interface IUserPreferencesState {
    loaded: boolean;
    loading: boolean;
    userPreferences: IUserPreferences;
}

export const initialState: IUserPreferencesState = {
    loaded: false,
    loading: false,
    userPreferences: {
        ui_property: {
            appDashboard: {},
            grid: {},
            logs: {},
            performanceControls: {},
        },
    },
};
