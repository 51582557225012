/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { WafPSMRule } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import * as l10n from 'ajs/modules/waf/waf.l10n';

import {
    IWafPSMRule,
    WafMode,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { WafPSMMatchElementConfigItem } from './waf-psm-match-element.config-item.factory';
import { ValueType } from '../waf.types';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Custom Waf PSM Rule Type.
 */
type TIWafPSMRulePartial = Omit<IWafPSMRule, 'match_elements'>;

/**
 * Interface for Waf PSM Rule.
 */
export interface IWafPSMRuleConfigItem extends TIWafPSMRulePartial {
    match_elements: RepeatedMessageItem<WafPSMMatchElementConfigItem>;
}

/**
 * @description WAF Policy PSM Rule Class.
 *
 * @author Alex Tseung, Nitesh Kesarkar
 */
export class WafPSMRuleConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IWafPSMRuleConfigItem, typeof MessageItem>(MessageItem),
)<IWafPSMRuleConfigItem> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpsmrule',
            objectType: WafPSMRule,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the rule_id.
     */
    public get ruleId(): string {
        return this.config.rule_id;
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled) {
        this.config.enable = enabled;
    }

    /**
     * Returns the mode.
     */
    public get mode(): WafMode {
        return this.config.mode;
    }

    /**
     * Sets the mode.
     */
    public set mode(wafMode: WafMode) {
        this.config.mode = wafMode;
    }

    /**
     * Returns match elements from config item.
     */
    public get matchElements(): RepeatedMessageItem<WafPSMMatchElementConfigItem> {
        return this.config.match_elements;
    }

    /**
     * Returns the number of configured match elements.
     */
    public get matchElementsCount(): number {
        return this.config.match_elements.count;
    }

    /**
     * Adds a WafPSMMatchElement instance to the list of Waf PSM match_elements.
     */
    public addMatchElement(preventEdit: boolean): void {
        this.addChildMessageItem({
            field: 'match_elements',
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Returns the name of the location.
     */
    public get name(): string {
        return this.getName();
    }

    /**
     * Edits a selected match element and opens the modal to edit it.
     */
    public editMatchElement(matchElement: WafPSMMatchElementConfigItem, preventEdit: boolean)
        : void {
        this.editChildMessageItem({
            field: 'match_elements',
            messageItem: matchElement,
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Deletes a selected match element.
     */
    public deleteMatchElement(matchElement: WafPSMMatchElementConfigItem): void {
        this.matchElements.removeByMessageItem(matchElement);
    }

    /**
     * Called when the value type has been changed. Deletes non-applicable fields.
     */
    public onMatchValueTypeChange(type: ValueType): void {
        switch (type) {
            case ValueType.PATTERN:
                delete this.config.match_value_string_group_ref;
                delete this.config.match_value_string_group_key;
                break;

            case ValueType.STRING_GROUP:
                delete this.config.match_value_pattern;
                break;
        }
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyPsmRuleModalComponent } = await import(
            /* webpackChunkName: "waf-policy-psm-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-psm-group-modal/waf-policy-psm-location-modal/waf-policy-psm-rule-grid/waf-policy-psm-rule-modal/waf-policy-psm-rule-modal.component'
        );

        return WafPolicyPsmRuleModalComponent as Type<Component>;
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WAF Policy PSG Rule Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafPsmRuleModalBreadcrumbTitle);
    }
}
