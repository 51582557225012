/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'se-engine-group';

export const vCenterModalBreadcrumbTitle = `${prefix}.vCenterModalBreadcrumbTitle`;
export const updateSuccessfulLabel = `${prefix}.updateSuccessfulLabel`;
export const updateFailedLabel = `${prefix}.updateFailedLabel`;
export const inProgressLabel = `${prefix}.inProgressLabel`;
export const updateStoppedLabel = `${prefix}.updateStoppedLabel`;
export const runningPrechecksLabel = `${prefix}.runningPrechecksLabel`;

export const ENGLISH = {
    [vCenterModalBreadcrumbTitle]: 'vCenter',
    [updateSuccessfulLabel]: 'Update Successful',
    [updateFailedLabel]: 'Update Failed',
    [inProgressLabel]: 'In Progress',
    [updateStoppedLabel]: 'Update Stopped',
    [runningPrechecksLabel]: 'Running Pre-checks',
};
