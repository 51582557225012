/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-email-settings';
const prefix = `${moduleName}.${componentName}`;

export const noneLabel = `${prefix}.noneLabel`;
export const localHostLabel = `${prefix}.localHostLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const anonymousServerLabel = `${prefix}.anonymousServerLabel`;

export const ENGLISH = {
    [noneLabel]: 'None',
    [localHostLabel]: 'Local Host',
    [smtpServerLabel]: 'SMTP Server',
    [anonymousServerLabel]: 'Anonymous Server',
};
