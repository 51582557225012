/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module LogsModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { IWafRuleLog } from 'generated-types';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { WafAddExceptionDialogComponent } from 'ng/modules/waf/components/waf-add-exception-dialog';

/**
 * ID for vs logs waf exception dialog.
 */
const WAF_ADD_EXCEPTION_DIALOG_ID = 'waf-add-exception-dialog';

/**
 * @description Service to open/close the Waf Exceptions dialog.
 * @author Shanmukha Sarath Kondiparthi, alextsg
 */
@Injectable()
export class VsLogsWafExceptionDialogService {
    constructor(private readonly dialogService: DialogService) {}

    /**
     * Method to open add-exception dialog.
     */
    public openDialog(
        wafPolicyRef: string,
        ruleLogs: IWafRuleLog[],
        groupName: string,
        ruleId?: number,
        uriPath?: string,
    ): void {
        this.dialogService.add({
            id: WAF_ADD_EXCEPTION_DIALOG_ID,
            component: WafAddExceptionDialogComponent as Type<Component>,
            componentProps: {
                onClose: () => this.closeDialog(),
                wafPolicyRef,
                ruleLogs,
                groupName,
                ruleId,
                uriPath,
            },
        });
    }

    /**
     * Method to close add-exception dialog.
     */
    private closeDialog(): void {
        this.dialogService.remove(WAF_ADD_EXCEPTION_DIALOG_ID);
    }
}
