<avi-data-grid
    [config]="customParamsGridConfig"
    [rows]="scriptParamsList.config"
    gridTitle="{{ l10nKeys.customScriptParamsLabel | vtranslate : customScriptParamsCount }}"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addScriptParam()"
        >
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>

<ng-template
    #sensitiveFieldTemplateRef
    let-row
>
    <cds-icon
        *ngIf="row.isSensitive; else noData"
        status="success"
        shape="check-circle"
        size="18"
    ></cds-icon>
</ng-template>

<ng-template
    #dynamicFieldTemplateRef
    let-row
>
    <cds-icon
        *ngIf="row.isDynamic; else noData"
        status="success"
        shape="check-circle"
        size="18"
    ></cds-icon>
</ng-template>

<ng-template #noData>
    <span>-</span>
</ng-template>
