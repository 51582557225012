/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ClientLogFilters.l10n';

import '../../less/components/client-log-filters.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('clientLogFilters', [
'$timeout',
'schemaService',
'l10nService',
function(
    $timeout,
    schemaService,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    function link(scope, elm) {
        /**
         * Get keys from source bundles for template usage
         */
        scope.l10nKeys = l10nKeys;

        //need to filter enums to exclude regexp options which are not supported
        scope.matchCriteriaOptions = [];

        _.each(schemaService.getEnumValueLabelsHash('StringOperation'), function(value, key) {
            if (key.indexOf('REGEX_') === -1) {
                scope.matchCriteriaOptions.push({
                    key,
                    label: value,
                });
            }
        });

        /**
         * Removes the filter from the list
         * @param {Object} r - Rule.
         */
        scope.remove = function(r) {
            if (scope.current && scope.current == r) {
                scope.current = null;
            }

            scope.data = _.filter(scope.data, function(v, i) {
                return v != r;
            });
        };

        /**
         * Sets indexes according to it's current position in the array
         */
        scope.updateIndexes = function() {
            _.each(scope.data, function(item, index) {
                item.index = index;
            });
        };

        /**
         * Creates new rule and puts it into edit mode
         */
        scope.addRule = function() {
            scope.current = {
                enabled: true,
                duration: schemaService.getFieldDefaultValue('ClientLogFilter', 'duration'),
                name: `Filter ${scope.data ? scope.data.length + 1 : 1}`,
                matchEdit: [],
            };
            scope.rule2EditMode(scope.current);
        };

        /**
         * Edit the rule
         * @param rule {object}
         */
        scope.editRule = function(rule) {
            scope.current = angular.copy(rule);
            scope.rule2EditMode(scope.current);
        };

        /**
         * Saves the current rule
         */
        scope.saveRule = function() {
            const { current } = scope;

            if (!current) {
                return;
            }

            if (!Array.isArray(scope.data)) {
                scope.data = [];
            }

            // Make sure there is no rule with the same name
            scope.error = null;

            const
                { data } = scope,
                { name, index } = current;

            const haveOtherRuleWithSameName = _.any(
                data,
                ({ name: ruleName, index: ruleIndex }) => ruleName === name && ruleIndex !== index,
            );

            if (haveOtherRuleWithSameName) {
                scope.error = l10nService.getMessage(l10nKeys.ruleNameAlreadyInUseMessage);

                return;
            }

            scope.rule2ReadMode(current);

            if (!angular.isUndefined(index)) {
                const ruleToUpdate = _.findWhere(data, { index });

                angular.extend(ruleToUpdate, current);
            } else {
                let maxIndex = 0;

                data.forEach(
                    ({ index }) => maxIndex = Math.max(maxIndex, index),
                );

                current.index = maxIndex + 1;

                data.push(current);
            }

            scope.current = null;
        };

        /**
         * Transforms the rule to edit mode. Mainly putting options into matches
         * array so that it is manageable in the template.
         * @param rule {object}
         */
        scope.rule2EditMode = function(rule) {
            // Convert matches
            rule.matchEdit = [];
            _.each(scope.matchKeys, function(matchId) {
                if (rule[matchId]) {
                    if (scope.matches[matchId].init) {
                        scope.matches[matchId].init(rule[matchId]);
                    }

                    rule.matchEdit.push({
                        id: matchId,
                        value: rule[matchId],
                    });
                }
            });

            rule.save = function() {
                scope.saveRule();
            };
        };

        /**
         * Transforms the rule back to read mode so that it becomes server friendly
         * @param rule {object}
         */
        scope.rule2ReadMode = function(rule) {
            // Convert matches back
            _.each(scope.matchKeys, function(matchId) {
                delete rule[matchId];
            });

            _.map(rule.matchEdit, function(match) {
                if (scope.matches[match.id].onSubmit) {
                    scope.matches[match.id].onSubmit(match.value);
                }

                rule[match.id] = match.value;
            });

            delete rule.matchEdit;
            delete rule.save;
        };

        /**
         * Deletes the match from the current rule
         * @param {Object} matchOrMatchValue - the type of the match
         */
        scope.deleteMatch = function(matchOrMatchValue) {
            const index = _.findIndex(scope.current.matchEdit,
                item => item === matchOrMatchValue || item.value === matchOrMatchValue);

            if (index !== -1) {
                scope.current.matchEdit.splice(index, 1);
            }
        };

        /**
         * Appends a match to the current rule
         * @param type - Match type
         */
        scope.addMatch = function(type) {
            if (!scope.current || !scope.matches[type]) {
                return;
            }

            scope.current.matchEdit.push({
                id: type,
                value: angular.copy(scope.matches[type].default),
            });

            // Scroll down
            const curOffsetTop = $('.new-match-list').offset().top;

            $timeout(function() {
                if (!$('.new-match-list').length) {
                    return;
                }

                const scrollable = $(elm).closest('.scrollable');

                scrollable.animate({
                    scrollTop: $(scrollable).scrollTop() +
                    ($('.new-match-list').offset().top - curOffsetTop),
                });
            });
        };

        /**
         * Match definition
         * @type {object}
         */
        scope.matches = {
            client_ip: {
                name: l10nService.getMessage(l10nKeys.clientIpMatchLabel),
                default: {
                    match_criteria: 'IS_IN',
                    addrs: [],
                    ranges: [],
                    prefixes: [],
                    group_refs: [],
                },
                stringify(m) {
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, function(item) {
                            val.push(item.addr);
                        });
                    }

                    if (m.ranges) {
                        _.each(m.ranges, function(item) {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, function(item) {
                            val.push(`${item.ip_addr.addr}/${item.mask}`);
                        });
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });
                    }

                    return `${schemaService.getEnumValue('MatchOperation', m.match_criteria)
                        .label} (${val.join(', ')})`;
                },
            },
            uri: {
                name: l10nService.getMessage(l10nKeys.pathMatchLabel),
                default: {
                    match_criteria: 'CONTAINS',
                    match_str: [],
                },
                stringify(m) {
                    const val = [];

                    Array.prototype.push.apply(val, m.match_str);

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `${schemaService.getEnumValue('StringOperation', m.match_criteria)
                        .label} (${val.join(', ')})`;
                },
            },
        };

        // Need to keep match keys as array so that we can show it in dropdown
        scope.matchKeys = Object.keys(scope.matches);

        /**
         * Used to filter out the rules that exist in detail.rules
         * @param match - Match key
         * @return {boolean}
         */
        scope.matchNotUsed = function(match) {
            if (scope.current && scope.current.matchEdit) {
                const found = _.find(scope.current.matchEdit, function(item) {
                    return item.id == match;
                });

                if (found) {
                    return false;
                }
            }

            return true;
        };

        /**
         * Grid configuration
         * @type {object}
         */
        scope.gridConfig = {
            id: 'client-log-filters-list',
            fields: [{
                name: 'enabled',
                title: l10nService.getMessage(l10nKeys.columnTitleEnabled),
                template: '<i class="icon-check" ng-show="row.enabled"/>',
            }, {
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
            }],
            searchFields: ['name', 'client_ip', 'uri'],
            rowId: 'name',
            multipleactions: [{
                title: l10nService.getMessage(l10nKeys.removeButtonLabel),
                do(rules) {
                    _.each(rules, function(rule) {
                        scope.remove(rule);
                    });

                    return true;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.enableButtonLabel),
                disabled(servers) {
                    return !_.find(servers, function(server) {
                        return !server.enabled;
                    });
                },
                do(rows) {
                    _.each(rows, function(row) {
                        row.enabled = true;
                    });

                    return true;
                },
            }, {
                title: l10nService.getMessage(l10nKeys.disableButtonLabel),
                disabled(rows) {
                    return !_.find(rows, function(row) {
                        return row.enabled;
                    });
                },
                do(rows) {
                    _.each(rows, function(row) {
                        row.enabled = false;
                    });

                    return true;
                },
            }],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.editActionTitle),
                class: 'icon-pencil-4',
                do: row => scope.editRule(row),
            }],
            rowClass(row) {
                return !row.enabled ? 'disabled' : '';
            },
            stringifyIps(m) {
                const val = [];

                if (m.addrs) {
                    _.each(m.addrs, function(item) {
                        val.push(item.addr);
                    });
                }

                if (m.ranges) {
                    _.each(m.ranges, function(item) {
                        val.push(`${item.begin.addr}-${item.end.addr}`);
                    });
                }

                if (m.prefixes) {
                    _.each(m.prefixes, function(item) {
                        val.push(`${item.ip_addr.addr}/${item.mask}`);
                    });
                }

                if (m.group_refs) {
                    _.each(m.group_refs, function(item) {
                        val.push(item.name() || item.slug());
                    });
                }

                return val.join(', ');
            },
            stringifyStrings(m) {
                const val = [];

                _.each(m.match_str, function(str) {
                    val.push(`"${str}"`);
                });

                _.each(m.string_group_refs, function(item) {
                    val.push(item.name() || item.slug());
                });

                return val.join(', ');
            },
            expandedContainerTemplate:
                `<div class="rule-details">
                    <div class="column">
                        <h3>${l10nService.getMessage(l10nKeys.matchingRulesHeader)}</h3>
                        <div class="item" ng-if="row.client_ip">
                            ${l10nService.getMessage(l10nKeys.clientIpLabel)}
                            <div>{{ config.stringifyIps(row.client_ip) }}</div>
                        </div>
                        <div class="item" ng-if="row.uri">
                            URI
                            <div>{{ config.stringifyStrings(row.uri) }}</div>
                        </div>
                    </div>
                </div>`,
            withReordering: true,
        };

        /**
         * Swaps rules within original list.
         * @param {number} from
         * @param {number} to
         */
        scope.onRowMove = (from, to) => {
            scope.current = null;

            const
                { data: rules } = scope,
                targetRule = rules[from],
                swappedRule = rules[to],
                { index: targetIndex } = targetRule,
                { index: swapIndex } = swappedRule;

            targetRule.index = swapIndex;
            swappedRule.index = targetIndex;

            rules[from] = swappedRule;
            rules[to] = targetRule;
        };
    }

    return {
        scope: {
            analyticsPolicy: '<',
            data: '=',
            fullClientLogs: '=',
            isHTTPVS: '&isHttpVs',
            current: '=',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/client-log-filters.html',
        compile() {
            return {
                post: link,
            };
        },
    };
}]);
