/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    Input,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Observable } from 'rxjs';
import { GslbGeoLocationSource } from 'generated-types';

import {
    Gslb,
    GslbSite,
} from 'object-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

import {
    IEditCredentialsConfig,
    // eslint-disable-next-line max-len
} from 'ng/modules/avi-forms/components/credentials-verification/credentials-verification.component';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import * as globalL10n from 'global-l10n';
import { GslbSiteEditCredentialsDialogComponent } from '../gslb-site-edit-credentials-dialog';
import * as l10n from './gslb-avi-site-config.l10n';
import './gslb-avi-site-config.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for gslb avi site config.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-avi-site-config',
    templateUrl: './gslb-avi-site-config.component.html',
})
export class GslbAviSiteConfigComponent {
    @Input()
    public editable: GslbSiteConfigItem;

    /**
     * List of active gslb Sites options available.
     */
    @Input()
    public activeGslbSiteOptions: IAviDropdownOption[] = [];

    /**
     * Object types for gslb avi site.
     */
    public objectType = {
        GslbSite,
        Gslb,
    };

    /**
     * Credentials config passed to the credentials verification component.
     */
    public editGslbSiteCredentialsConfig: IEditCredentialsConfig;

    /**
     * Store all IP addresses in modal's grid in single array format.
     */
    public ipAddresses: RepeatedMessageItem<IpAddrConfigItem>;

    /**
     * True if the gslb site geo location is configured by user.
     */
    public isLocationConfigured: boolean;

    /**
     * True if gslb Site is being edited.
     */
    public readonly isEditing: boolean;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.ipAddresses = this.editable.config.ip_addresses;
        this.setLocationConfigurationStatus();
        this.setEditGslbSiteCredentialsConfig();
    }

    /**
     * Set geo location source.
     * In case the location is user configured initialize the location object.
     * In case the location is not configured delete location field.
     */
    public setGeoLocationSource(isLocationChecked: boolean): void {
        if (isLocationChecked) {
            this.editable.onConfigureUserLocation();
            this.editable.config.location
                .setSource(GslbGeoLocationSource.GSLB_LOCATION_SRC_USER_CONFIGURED);
            this.isLocationConfigured = true;
        } else {
            delete this.editable.config.location;
            this.isLocationConfigured = false;
        }
    }

    /**
     * Set isLocationConfigured to true if gslb site location is present.
     */
    private setLocationConfigurationStatus(): void {
        if (this.editable.config.location) {
            this.isLocationConfigured = true;
        } else {
            this.isLocationConfigured = false;
        }
    }

    /**
     * Create the config object for credentials verification component.
     */
    private setEditGslbSiteCredentialsConfig(): void {
        const editCredentialsConfig = {
            username: this.editable.config.username,
            password: this.editable.config.password,
        };

        this.editGslbSiteCredentialsConfig = {
            editCredentialsDialog: GslbSiteEditCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                submit$: new Observable<void>(
                    subscriber => {
                        subscriber.next();
                        this.editable.config.username = editCredentialsConfig.username;
                        this.editable.config.password = editCredentialsConfig.password;
                        this.editable.siteVerificationRequired = true;
                    },
                ),
                onCancel: () => {
                    this.setEditGslbSiteCredentialsConfig();
                },
            },
        };
    }
}
