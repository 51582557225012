<div class="avi-dynamic-data-grid">
    <h6
        *ngIf="gridTitle"
        class="avi-dynamic-data-grid__grid-title"
    >
        {{ gridTitle }}
    </h6>
    <div class="avi-data-grid__header">
        <ng-content select="avi-data-grid_header"></ng-content>
    </div>
    <avi-data-grid-base
        [config]="config"
        [rows]="rows"
        [rowsTotal]="rowsTotal"
        [isLoading]="isLoading"
        (onSelectionChange)="onRowSelectionChange($event)"
        (onDataGridStateChange)="handleDataGridStateChange($event)"
    >
        <ng-container ngProjectAs="avi-data-grid_actions">
            <ng-content select="avi-data-grid_actions"></ng-content>
        </ng-container>
    </avi-data-grid-base>
</div>
