<avi-data-grid
    [config]="pkiCertificateAuthorityGridConfig"
    [rows]="pkiCertificateAuthority.config"
    [invalidateForm]="pkiCertificateAuthority.isEmpty()"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addCertificateAuthority()"
        >
            {{ l10nKeys.certAuthorityAddButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>
