/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ControlContainer,
    NgForm,
} from '@angular/forms';

import {
    SubnetListNetwork,
    SubnetListNetworkCollection,
} from 'ajs/modules/network/factories';

import { IExtendedCloneServer }
    from 'ajs/modules/traffic-clone-profile/factories/clone-server-config.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';
import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';
import * as l10n from './network-and-subnet.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetworkCollection = typeof SubnetListNetworkCollection;
type TSubnetListNetwork = typeof SubnetListNetwork;

/**
 * @description Component for Network and Subnet selection.
 * @author Harmeet Kaur
 */
@Component({
    selector: 'network-and-subnet',
    templateUrl: './network-and-subnet.component.html',
    viewProviders: [{
        provide: ControlContainer,
        useExisting: NgForm,
    }],
})
export class NetworkAndSubnetComponent implements OnInit, OnDestroy {
    /**
     * CloneServerConfigItem instance.
     */
    @Input()
    public editable: IExtendedCloneServer;

    /**
     * Selected cloudRef.
     */
    @Input()
    public cloudRef: string;

    /**
     * CloneServer object type.
     */
    @Input()
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SubnetListNetworkCollection instance.
     */
    public subnetListNetworkCollection: SubnetListNetworkCollection;

    /**
     * Subnets List
     */
    public subnetList: string[] = [];

    constructor(
        l10nService: L10nService,
        @Inject(SubnetListNetworkCollection)
        SubnetListNetworkCollection: TSubnetListNetworkCollection,
        @Inject(SubnetListNetwork)
        private readonly SubnetListNetwork: TSubnetListNetwork,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.subnetListNetworkCollection.setParams({
            cloud_uuid: this.stringService.slug(this.cloudRef),
        });

        this.loadNetwork();
    }

    public ngOnDestroy(): void {
        this.subnetListNetworkCollection.destroy();
    }

    /**
     * Handler for Network dropdown change event.
     */
    public handleNetworkChange(): void {
        this.editable.subnet.subnet = '';

        const networkId = this.stringService.slug(this.editable.network_ref);

        const selectedNetwork = this.subnetListNetworkCollection.getItemById(networkId);

        this.setSubnets(selectedNetwork);
    }

    /**
     * Loads the pre-selected network instance and its subnets
     */
    private async loadNetwork(): Promise<void> {
        if (this.editable.network_ref) {
            const network = new this.SubnetListNetwork({
                id: this.stringService.slug(this.editable.network_ref),
                cloudId: this.stringService.slug(this.cloudRef),
            });

            await network.load();

            this.setSubnets(network);
        }
    }

    /**
     * Given a network object, sets this.subnetList to its list of subnets.
     */
    private setSubnets(network: SubnetListNetwork): void {
        this.subnetList.length = 0;

        if (network) {
            const subnets = network.getSubnets();

            this.subnetList =
                subnets.map(({ prefix }) => getSubnetString(prefix));
        }
    }
}
