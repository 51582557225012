/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'license-summary-card';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;
export const cloudServicesAvailableLicensesLabel = `${prefix}.cloudServicesAvailableLicensesLabel`;
export const cloudServicesAvailableLicensesTooltipText = `${prefix}.cloudServicesAvailableLicensesTooltipText`;
export const controllerMaxAllowedLabel = `${prefix}.controllerMaxAllowedLabel`;
export const reservedLicensesLabel = `${prefix}.reservedLicensesLabel`;
export const organizationIdLabel = `${prefix}.organizationIdLabel`;

export const ENGLISH = {
    [title]: 'License',
    [controllerMaxAllowedLabel]: 'Controller Max Allowed',
    [reservedLicensesLabel]: 'Reserved Licenses',
    [cloudServicesAvailableLicensesLabel]: 'Cloud Services Available Licenses',
    [cloudServicesAvailableLicensesTooltipText]: 'Available Service Units in the Org.',
    [organizationIdLabel]: 'Organization ID',
};
