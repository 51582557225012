/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import { Injectable } from '@angular/core';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { Observable } from 'rxjs';

import {
    FileType,
    IFileObject,
} from 'generated-types';

/**
 * API url where the file needs to be uploaded.
 */
const FILE_UPLOAD_URL = '/api/fileobject/upload';

/**
 * Type of File params to be uploaded.
 */
interface IFileUploadParams {
    crlFileContent: string;
    isFederated: boolean;
}

/**
 * @description Service to upload the CRL file in PKI Profile modal.
 *
 * @author Sarthak Kapoor
 */
@Injectable()
export class PkiCrlFileUploadService {
    constructor(private readonly httpClient: AlbHttpClient) {}

    /**
     * Make API call to upload the CRL file.
     */
    public uploadCrlFile(fileUploadParams: IFileUploadParams): Observable<IFileObject> {
        const formData = new FormData();
        const {
            crlFileContent,
            isFederated,
        } = fileUploadParams;

        formData.append('file', crlFileContent);
        formData.append('type', FileType.CRL_DATA);

        /**
         * Form Data doesn't support appending boolean values directly. Thus,
         * need to convert this to string.
         */
        formData.append('is_federated', isFederated.toString());

        return this.httpClient.post(FILE_UPLOAD_URL, formData);
    }
}
