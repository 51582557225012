/** @module SharedModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { IItemGenericConfig } from 'ajs/modules/data-model/factories/item.factory';
import { IGenericMessageItemConfig } from 'ajs/modules/data-model/factories/message-item.factory';
import './item-preview-wrapper.component.less';

/* tslint:disable:jsdoc-format */
/**
 * Takes config of an Item (or MessageItem or ObjectTypeItem), displays default values
 * in full-modal's preview (right-side) using item-preview-defaults.component,
 * and allows projection of custom preview component.
 *
 * Used by with-full-modal.mixin to display only default preview values for all Items.
 *
 * Can be used directly by any Item's (or MessageItem's or ObjectTypeItem's) custom preview
 * component.
 *
 * Recommended use:
   // example-item.component.ts:
   Class ExampleItemComponent {
       protected async getFullModalProps(
           params: IItemParams,
           modalComponent?: Type<Component>,
       ): IFullModalLayout {
           const props = await super.getFullModalProps(params, modalComponent);

           return {
               ...props,
               previewComponent: ExampleItemPreviewComponent as Type<Component>,
               previewComponentProps: {
                   config: params.editable.getConfig(),
                   isItem: Boolean(params.editable instanceof Item),
               },
           };
       }
   }

   // example-item-preview.component.html:
    <item-preview-wrapper
        class="example-item-preview"
        [config]="config"
        [isItem]="isItem"
    >
        <item-preview-wrapper_custom-content>
            <preview-field
                class="clr-wrapper item-preview-wrapper__preview-field"
                *ngFor="let field of previewFields; trackBy: trackByVal"
            >
                <preview-field_label class="item-preview-wrapper__preview-field-content">
                    {{ field.label }}
                </preview-field_label>
                <preview-field_val class="item-preview-wrapper__preview-field-content">
                    {{ field.val }}
                </preview-field_val>
            </preview-field>

            <!--  Any other custom content  -->
        </item-preview-wrapper_custom-content>

        <!--  Any other custom content  -->

   </item-preview-wrapper>
 *
 * Customize above as needed.
 *
 * @author Akul Aggarwal
 */
/* tslint:enable:jsdoc-format */
@Component({
    selector: 'item-preview-wrapper',
    templateUrl: './item-preview-wrapper.component.html',
})
export class ItemPreviewWrapperComponent {
    @Input()
    public readonly config: IItemGenericConfig | IGenericMessageItemConfig;

    /**
     * Flag that tells if editable is an item or a message item.
     */
    @Input()
    public readonly isItem: boolean;
}
