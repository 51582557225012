<div
    class="full-modal-config__section"
    provideParentForm
    [invalidateForm]="disableSave"
>
    <h3 *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_CUSTOM_DNS; then customDnsTitle else customIpamTitle"></h3>
    <div class="full-modal-config__form-control-row">
        <div 
            *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_CUSTOM_DNS" 
            class="full-modal-config__form-control"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="custom_ipam_dns_profile_uuid"
                for="custom-dns-profile-uuid"
                required
            >
                {{ l10nKeys.customIpamDnsProfileInputLabel | vtranslate }}
            </label>
            <avi-collection-dropdown
                id="custom-dns-profile-uuid"
                name="custom-dns-profile-uuid"
                [collection]="customIpamDnsCollection"
                [(ngModel)]="editable.config.custom_ipam_dns_profile_ref"
                placeholder="{{ l10nKeys.customIpamDnsProfileInputPlaceholder | vtranslate }}"
                required
            ></avi-collection-dropdown>
        </div>
        <div 
            *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_CUSTOM" 
            class="full-modal-config__form-control"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="custom_ipam_dns_profile_uuid"
                for="custom-ipam-profile-uuid"
                required
            >
                {{ l10nKeys.customIpamProfileInputLabel | vtranslate }}
            </label>
            <avi-collection-dropdown
                id="custom-ipam-profile-uuid"
                name="custom-ipam-profile-uuid"
                [collection]="customIpamDnsCollection"
                [(ngModel)]="editable.config.custom_ipam_dns_profile_ref"
                (ngModelChange)="onCustomIpamProfileChange($event)"
                placeholder="{{ l10nKeys.customIpamProfileInputPlaceholder | vtranslate }}"
                required
                [actions]="ipamDropdownActions"
            ></avi-collection-dropdown>
            <div
                class="avi-dropdown__success-text"
                *ngIf="connected"
            >
                {{ l10nKeys.loginSuccessMessage | vtranslate }}
            </div>
            <div
                class="avi-dropdown__error-text"
                *ngIf="testLoginError"
            >
                {{ testLoginError }}
            </div>
        </div>
    </div>
    <ng-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_CUSTOM_DNS">
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-repeated-strings-grid
                    name="usable_domains"
                    [(ngModel)]="editable.config.usable_domains"
                    columnLabel="{{ l10nKeys.usableDomainLabel | vtranslate }}"
                    gridTitle="{{ l10nKeys.usableDomainsGridLabel | vtranslate : editable.usableDomainsCount }}"
                ></avi-repeated-strings-grid>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <custom-params-grid
                    [paramsList]="editable.config.dynamic_params"
                    addButtonLabel="{{ l10nKeys.addScriptParamsLabel | vtranslate }}"
                    gridTitle="{{ l10nKeys.scriptParamsGridLabel | vtranslate : editable.config.dynamic_params.count }}"
                    hasSensitiveColumn
                    hasDynamicColumn
                ></custom-params-grid>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_CUSTOM">
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <custom-usable-subnets-grid
                    [usableSubnets]="editable.config.usable_alloc_subnets"
                    [connected]="connected"
                    (onAddUsableSubnet)="addUsableSubnet()"
                    (onDeleteUsableSubnet)="removeUsableSubnet($event)"
                    (onEditUsableAllocSubnet)="editUsableAllocSubnet($event)"
                    (onTestLogin)="testLogin()"
                ></custom-usable-subnets-grid>
            </div>
        </div>
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <custom-params-grid
                    [paramsList]="editable.config.dynamic_params"
                    gridTitle="{{ l10nKeys.dynamicParamsGridLabel | vtranslate : editable.config.dynamic_params.count }}"
                    hasSensitiveColumn
                    hasDynamicColumn
                ></custom-params-grid>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #customDnsTitle>{{ l10nKeys.sectionTitleCustom | vtranslate }}</ng-template>
<ng-template #customIpamTitle>{{ l10nKeys.sectionTitleCustomIPAM | vtranslate }}</ng-template>
