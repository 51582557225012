/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { finalize } from 'rxjs/operators';
import * as globalL10n from 'global-l10n';
import { LoadingState } from 'ng/shared/components/async-file-upload/async-file-upload.component';
import { PkiCrlFileUploadService } from '../services/pki-crl-file-upload.service';
import * as l10n from './pki-crl-file-upload-dialog.l10n';
import './pki-crl-file-upload-dialog.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying the file upload dialog for PKI Profile.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'pki-crl-file-upload-dialog',
    templateUrl: './pki-crl-file-upload-dialog.component.html',
    providers: [PkiCrlFileUploadService],
})
export class PkiCrlFileUploadDialogComponent implements OnInit {
    /**
     * Disable the "Is Federated" checkbox in the dialog if
     * the PKI Profile is federated.
     */
    @Input()
    public isPkiProfileFederated = false;

    /**
     * Fire on click of close button.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Fire on successful file upload.
     */
    @Output()
    public onSuccessfulUpload = new EventEmitter<void>();

    /**
     * Selected Certificate Revocation List(CRL) file content.
     */
    public crlFileContent: string;

    /**
     * Is Federated model value.
     */
    public isFederated = false;

    /**
     * Error message received from backend in case of file upload failure.
     */
    public errorMessage: string;

    /**
     * Hold the loading state value for upload button.
     */
    public loadingState = LoadingState.DEFAULT;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly pkiCrlFileUploadService : PkiCrlFileUploadService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.isFederated = this.isPkiProfileFederated;
    }

    /**
     * Close button handler.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Remove the existing error, if any, on file selection change.
     */
    public handleFileSelectChange(): void {
        this.errorMessage = '';
    }

    /**
     * Handle file upload button click.
     */
    public onCrlFileUpload(): void {
        this.loadingState = LoadingState.LOADING;

        const {
            crlFileContent,
            isFederated,
        } = this;

        this.pkiCrlFileUploadService.uploadCrlFile({
            crlFileContent,
            isFederated,
        }).pipe(
            finalize(() => this.loadingState = LoadingState.DEFAULT),
        ).subscribe(
            () => {
                this.errorMessage = '';
                this.onSuccessfulUpload.emit();
            }, error => {
                this.errorMessage = error?.error?.error ||
                    this.l10nService.getMessage(l10nKeys.genericErrorMessage);
            },
        );
    }
}
