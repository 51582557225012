/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'dns-config';

export const dnsConfigModalBreadcrumbTitle = `${prefix}.dnsConfigModalBreadcrumbTitle`;

export const ENGLISH = {
    [dnsConfigModalBreadcrumbTitle]: 'Subdomain',
};
