/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name wafTopHitsContainer
 * @param {WafPolicy=} wafPolicy
 * @param {Object[]} data - Object containing 'name' and 'count' properties.
 * @param {Function=} onEnable - Function called when the switch button is clicked.
 * @param {string} containerTitle - Title to be shown in the container box.
 * @param {string=} type - Either 'group', 'rule', or undefined.
 * @param {boolean=} showTotal - True to show the total at the bottom of the container.
 */
angular.module('aviApp').component('wafTopHitsContainer', {
    bindings: {
        wafPolicy: '<',
        topHitsList: '<',
        onEnable: '&',
        onSelectFilter: '&',
        filterIsSelected: '&',
        containerTitle: '<',
        isLoading: '<',
        type: '<',
        showTotal: '<',
    },
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/' +
            'waf-top-hits-container/waf-top-hits-container.html',
});
