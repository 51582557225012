/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const componentName = 'administration-dashboard-page';
const prefix = `${moduleName}.${componentName}`;

export const administrationOverviewHeader = `${prefix}.administrationOverviewHeader`;

export const ENGLISH = {
    [administrationOverviewHeader]: 'Administration Overview',
};
