<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="generic-hdr-match"
        provideParentForm
    >
        <avi-input-container 
            noMarginTop
            *ngIf="showNameField()"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="{{ matchObjectNameField }}"
                for="{{ matchObjectNameField }}"
                required
            >
                {{ l10nKeys.nameInputLabel | vtranslate }}
            </label>
            <input
                name="{{ matchObjectNameField + '_' + matchIndex }}"
                [(ngModel)]="editable.config[matchObjectNameField]"
                clrInput
                placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                regexPattern="objName"
                required
            />
        </avi-input-container>
        <div [ngClass]="{ 'generic-hdr-match__input-container': showNameField() }">
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="match_criteria"
                for="match_criteria"
                required
            >
                {{ l10nKeys.criteriaLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="{{ 'match-criteria-' + matchIndex }}"
                [(ngModel)]="editable.config.match_criteria"
                enum="HdrMatchOperation"
                (ngModelChange)="handleCriteriaChange()"
                placeholder="{{ l10nKeys.criteriaPlaceholder | vtranslate }}"
                required
            ></avi-enum-dropdown>
        </div>
        <div
            *ngIf="showValueField() && !isCookieMatchObjectType()"
            class="generic-hdr-match__input-container generic-hdr-match__input-container--value"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="value"
                for="value"
                required
            >
                {{ l10nKeys.valueInputLabel | vtranslate }}
            </label>
            <avi-repeated-strings
                name="{{ 'value-' + matchIndex }}"
                placeholder="{{ l10nKeys.valuesInputPlaceholder | vtranslate }}"
                objectType="{{ matchObjectType }}"
                fieldName="value"
                [(ngModel)]="editable.config.value"
                required
            ></avi-repeated-strings>
        </div>
        <div class="generic-hdr-match__input-container-single-value">
            <avi-input-container 
                noMarginTop
                *ngIf="showValueField() && isCookieMatchObjectType()"
            >
                <label
                    aviLabelWithTooltip
                    objectType="{{ matchObjectType }}"
                    fieldName="value"
                    for="value"
                    required
                >
                    {{ l10nKeys.valueInputLabel | vtranslate }}
                </label>
                <input
                    name="{{ 'value_single_string' + matchIndex }}"
                    placeholder="{{ l10nKeys.valueInputPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.value"
                    required
                    clrInput
                />
            </avi-input-container>
        </div>
    </div>
</match-wrapper>
