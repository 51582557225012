/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { wafAddExceptionDialogFormValidator } from '../../validators';

/**
 * @description
 *     Directive used to validate the waf-add-exception-dialog form. Checks that there are no empty
 *     exclude list entries.
 * @author alextsg
 */
@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: WafAddExceptionDialogFormDirective,
    }],
    selector: '[wafAddExceptionDialogForm]',
})
export class WafAddExceptionDialogFormDirective implements Validator {
    /**
     * Number of entries to be added to the WAF exclude list.
     */
    @Input('wafAddExceptionDialogForm')
    public count: number;

    /**
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return wafAddExceptionDialogFormValidator(control, this.count);
    }
}
