<div
    class="system-about-info"
    *ngIf="(initialVersionData$ | async) as versionData"
>
    <div class="system-about-info__row">
        <div class="system-about-info__control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    for="controller-version"
                >
                    {{ l10nKeys.versionLabel | vtranslate }}
                </label>
                <input
                    class="sel-version-in-update-card"
                    name="controller-version"
                    clrInput
                    placeholder="-"
                    [ngModel]="versionData.version"
                    readonly
                />
            </avi-input-container>
        </div>
        <div class="system-about-info__control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    for="controller-patch"
                >
                    {{ l10nKeys.patchLabel | vtranslate }}
                </label>
                <input
                    class="sel-patch-in-update-card"
                    name="controller-patch"
                    clrInput
                    placeholder="-"
                    [ngModel]="versionData.patch"
                    readonly
                />
            </avi-input-container>
        </div>
    </div>
    <div class="system-about-info__row">
        <div class="system-about-info__control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    for="controller-build"
                >
                    {{ l10nKeys.buildLabel | vtranslate }}
                </label>
                <input
                    class="sel-build-in-update-card"
                    name="controller-build"
                    clrInput
                    placeholder="-"
                    [ngModel]="versionData.build"
                    readonly
                />
            </avi-input-container>
        </div>
    </div>
    <div class="system-about-info__row">
        <div class="system-about-info__control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    for="controller-builddate"
                >
                    {{ l10nKeys.dateLabel | vtranslate }}
                </label>
                <input
                    class="sel-builddate-in-update-card"
                    name="controller-builddate"
                    clrInput
                    placeholder="-"
                    [ngModel]="versionData.date | i18nDate : 'medium'"
                    readonly
                />
            </avi-input-container>
        </div>
    </div>
</div>
