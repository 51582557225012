/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('performanceChartTitle', function() {
    return {
        restrict: 'A',
        templateUrl: 'src/views/components/performance-chart-title.html',
    };
});
