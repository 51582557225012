/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { TEnumValueHash } from 'ajs/modules/core/services/schema-service/schema.types';

import {
    MethodMatchConfigItem,
} from 'ajs/modules/match/factories/method-match.config-item.factory';

import * as l10n from './http-method-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for http-method-match.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'http-method-match-readonly',
    templateUrl: './http-method-match-readonly.component.html',
})
export class HttpMethodMatchReadonlyComponent implements OnInit {
    @Input()
    public editable: MethodMatchConfigItem;

    public readonly l10nKeys = l10nKeys;

    /**
     * Used to display text label for selected match criteria in template.
     */
    public matchCriteria: string;

    /**
     * Enum Value Hash to show 'description' for specified HTTPMethod.
     */
    public httpMethodsEnumValueHash: TEnumValueHash;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.httpMethodsEnumValueHash = this.schemaService.getEnumValuesHash('HTTPMethod');
        this.matchCriteria = this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.match_criteria,
        );
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
