/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoliciesModule
 */

import {
    HostHdrMatchConfigItem,
    IpAddrMatchConfigItem,
    MethodMatchConfigItem,
    PathMatchConfigItem,
    PortMatchConfigItem,
} from 'ajs/modules/match';
import { IMatchTarget } from 'generated-types';

import { MatchTarget } from 'object-types';
import { PolicyMatchConfigItem } from './policy-match.config-item.factory';

type TMatchTarget = Omit<
IMatchTarget,
'client_ip' |
'vs_port' |
'host_hdr' |
'method' |
'path'
>;
interface IMatchTargetConfigItem extends TMatchTarget {
    client_ip?: IpAddrMatchConfigItem;
    vs_port?: PortMatchConfigItem,
    method?: MethodMatchConfigItem,
    path?: PathMatchConfigItem,
    host_hdr?: HostHdrMatchConfigItem,
}

export const MATCH_TARGET_CONFIG_ITEM_TOKEN = 'MatchTargetConfigItem';

export class MatchTargetConfigItem extends PolicyMatchConfigItem<IMatchTargetConfigItem> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: MatchTarget,
            ...args,
        };

        super(extendedArgs);
    }
}
