/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    ConfiguredNetwork,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import './network-list-expander.component.less';

/**
 * @description
 *     Network Grid Expander Component.
 *
 *     Lists all the configured subnets and its static IP Pools and
 *     their types.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'network-list-expander',
    templateUrl: './network-list-expander.component.html',
})
export class NetworkListExpanderComponent {
    /**
     * ConfiguredNetwork instance.
     */
    @Input()
    public network: ConfiguredNetwork;

    /**
     * Callback to ngFor-track by
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}
