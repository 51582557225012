<ng-container *ngrxLet="dnsResponseInfo$ as dnsResponseInfo">
    <div class="vs-log-cinematic-sub-section-dns-response__column-group">
        <!-- 1st column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ globalL10nKeys.responseCodeLabel | vtranslate }}
            </div>
            <span
                vsLogFilter
                property="dns_response.response_code"
                [value]="responseCode"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.response_code as responseCode; else na"
            >
                {{ enumLabelHash[responseCode] }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.recordSourceLabel | vtranslate }}</div>
            <span
                *ngIf="(connectionLog$ | async).dns_etype as dnsEtype; else na"
                vsLogFilter
                property="dns_etype"
                [value]="dnsEtype"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ enumLabelHash[dnsEtype] }}
            </span>

            <div vsLogCinematicFieldTitle>
                {{ l10nKeys.operationCodeLabel | vtranslate:(l10nKeys.opCodeLabel | vtranslate) }}
            </div>
            <span
                vsLogFilter
                property="dns_response.opcode"
                [value]="opCode"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.opcode as opCode; else na"
            >
                {{ enumLabelHash[opCode] }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.truncatedLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.truncation"
                [value]="dnsResponseInfo.truncation"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.truncation !== undefined; else na"
            >
                {{ dnsResponseInfo.truncation }}
            </span>
        </div>

        <!-- 2nd column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.questionCountLabel | vtranslate }}
            </div>
            <span
                vsLogFilter
                property="dns_response.question_count"
                [value]="dnsResponseInfo.question_count"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.question_count !== undefined; else na"
            >
                {{ dnsResponseInfo.question_count }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.answerRecordCountLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.answer_records_count"
                [value]="dnsResponseInfo.answer_records_count"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.answer_records_count !== undefined; else na"
            >
                {{ dnsResponseInfo.answer_records_count }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.nameServerRecordCountLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.nameserver_records_count"
                [value]="dnsResponseInfo.nameserver_records_count"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.nameserver_records_count !== undefined; else na"
            >
                {{ dnsResponseInfo.nameserver_records_count }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.additionalRecordCountLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.additional_records_count"
                [value]="dnsResponseInfo.additional_records_count"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.additional_records_count !== undefined; else na"
            >
                {{ dnsResponseInfo.additional_records_count }}
            </span>
        </div>

        <!-- 3rd column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.authoritativeLabel | vtranslate }}
            </div>
            <span
                vsLogFilter
                property="dns_response.authoritative_answer"
                [value]="dnsResponseInfo.authoritative_answer"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.authoritative_answer !== undefined else na"
            >
                {{ dnsResponseInfo.authoritative_answer }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.recursionAvailableLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.recursion_available"
                [value]="dnsResponseInfo.recursion_available"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.recursion_available !== undefined; else na"
            >
                {{ dnsResponseInfo.recursion_available }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.recursionDesiredLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.recursion_desired"
                [value]="dnsResponseInfo.recursion_desired"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.recursion_desired !== undefined; else na"
            >
                {{ dnsResponseInfo.recursion_desired }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.wildcardLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.is_wildcard"
                [value]="dnsResponseInfo.is_wildcard"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.is_wildcard !== undefined; else na"
            >
                {{ dnsResponseInfo.is_wildcard }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.fallbackAlgorithmUsedLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.fallback_algorithm_used"
                [value]="dnsResponseInfo.fallback_algorithm_used"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsResponseInfo.fallback_algorithm_used !== undefined; else na"
            >
                {{ dnsResponseInfo.fallback_algorithm_used }}
            </span>
        </div>
    </div>

    <!-- DNS Opt Record -->
    <vs-log-cinematic-dns-opt-record
        *ngIf="dnsResponseInfo.opt_record as optRecord"
        [dnsOptRecord]="optRecord"
        [dnsMessageType]="dnsMessageType"
    ></vs-log-cinematic-dns-opt-record>

    <!-- Resource Records grid -->
    <ng-container *ngIf="dnsResponseInfo.records as records">
        <h6>{{ l10nKeys.resourceRecordsLabel | vtranslate:records.length }}</h6>
        <avi-data-grid
            [config]="resourceRecordsGridConfig"
            [rows]="records"
        ></avi-data-grid>
    </ng-container>
</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template
    #domainNameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="dns_response.records.name"
        [value]="name"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.name as name; else na"
    >
        {{ name }}
    </span>
</ng-template>

<ng-template
    #dnsRecordTypeFieldTemplateRef
    let-row
    let-index="index"
>
    <span *ngIf="row.type as type; else na">{{ enumLabelHash[type] }}</span>
</ng-template>

<ng-template
    #resolvedVirtualServiceNameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="dns_response.records.vs_name"
        [value]="name"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.vs_name as name; else na"
    >
        {{ name }}
    </span>
</ng-template>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div class="vs-log-cinematic-sub-section-dns-response__grid-expanded-content">
        <!-- left column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.memberLocationLabel | vtranslate }}
            </div>
            <ng-container *ngIf="row.location?.name as location; else na">
                <div
                    vsLogNationalFlag
                    [countryCode]="location"
                ></div>
                <span
                    vsLogFilter
                    property="dns_response.records.location.name"
                    [value]="location"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ location | countryCodeToName }}
                </span>
            </ng-container>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.ttlLabel | vtranslate }}</div>
            <span
                *ngIf="row.ttl !== undefined; else na"
                vsLogFilterList
                property="dns_response.records.ttl"
                [value]="row.ttl"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ row.ttl }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.nsNameLabel | vtranslate }}</div>
            <span
                *ngIf="row.nsname as nsName; else na"
                vsLogFilter
                property="dns_response.records.nsname"
                [value]="nsName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ nsName }}
            </span>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.ipAddressLabel | vtranslate }}</div>
            <span
                *ngIf="(row.addr6_ip_str || row.addr_ip) as ip; else na"
                vsLogFilter
                property="dns_response.records.{{ row.addr6_ip_str ? 'addr6_ip_str' : 'addr_ip' }}"
                [value]="ip"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ip }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.cnameLabel | vtranslate }}</div>
            <span
                *ngIf="row.cname as cName; else na"
                vsLogFilter
                property="dns_response.records.cname"
                [value]="cName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ cName }}
            </span>
        </div>

        <!-- right column -->
        <div
            vsLogCinematicSectionColumn
            [expandTwoColumns]="true"
        >
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.mailServerLabel | vtranslate }}
            </div>
            <span
                vsLogFilter
                property="dns_response.records.mail_server"
                [value]="mailServer"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="row.mail_server as mailServer; else na"
            >
                {{ mailServer }}
            </span>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.portLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.records.port"
                [value]="row.port"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="row.port !== undefined; else na"
            >
                {{ row.port }}
            </span>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.priorityLabel | vtranslate }}</div>
            <span
                vsLogFilterList
                property="dns_response.records.priority"
                [value]="row.priority"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="row.priority !== undefined; else na"
            >
                {{ row.priority }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.textResourceDataLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_response.records.text_rdata"
                [value]="trData"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="row.text_rdata as trData; else na"
            >
                {{ trData }}
            </span>
        </div>
    </div>
</ng-template>
