/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const
    componentName = 'c-grid-table-row-expander',
    template = '<div ng-click="$ctrl.onClick($event)"></div>';

class GridTableRowExpanderController {
    constructor($rootScope, $scope, $elem, $compile) {
        this.$rootScope = $rootScope;
        this.$scope = $scope;
        this.$elem = $elem;
        this.$compile = $compile;
    }

    $onInit() {
        const { $elem, row } = this;

        $elem.addClass(componentName);

        if (this.onInit) {
            this.onInit({ row });
        }

        if (!this.template) {
            return;
        }

        const $scope = this.$rootScope.$new(true, this.$scope);

        $scope.row = row;

        //legacy, deprecated
        $scope.config = this.config;

        this.$compile(this.template)($scope)
            .appendTo($elem.find('> div'));
    }

    $onDestroy() {
        if (this.onDestroy) {
            this.onDestroy({ row: this.row });
        }
    }

    /**
     * Prevents event propagation.
     * @param {Object} event
     */
    onClick(event) {
        event.stopPropagation();
    }
}

GridTableRowExpanderController.$inject = [
    '$rootScope',
    '$scope',
    '$element',
    '$compile',
];

/**
 * @ngdoc component
 * @name gridTableRowExpander
 * @param {string} template
 * @param {*} row
 * @param {gridConfig} config - Passed so that expander template could use it (deprecated).
 * @param {Function=} onInit - Function to be called before template rendering.
 * @param {Function=} onDestroy - Function to be called after template rendering.
 * @desc
 *
 *     Wrapper component for grid table row expander.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridTableRowExpander', {
    bindings: {
        template: '<',
        config: '<',
        row: '<',
        onInit: '&?',
        onDestroy: '&?',
    },
    controller: GridTableRowExpanderController,
    template,
});
