/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHttpPromise } from 'angular';

import {
    Inject,
    Injectable,
} from '@angular/core';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import { FileType } from '../components/add-attachments-grid/add-attachments-grid.types';
import { IFileServiceApiResponse } from './case-attachments.service.types';

type THttpWrapper = typeof HttpWrapper;

/**
 * @description
 *      Service which is used fetch available attachments present on the controller
 *      for the given file type.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class CaseAttachmentsService {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(@Inject(HttpWrapper) HttpWrapper: THttpWrapper) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Load available attachments list for the given file type present on the controller.
     */
    public loadAvailableAttachmentsList(fileType: FileType): IHttpPromise<IFileServiceApiResponse> {
        const requestConfig = {
            url: `api/fileservice?uri=controller://${fileType}`,
            method: HttpMethod.GET,
        };

        return this.httpWrapper.request(requestConfig);
    }
}

