<div class="license-add-panel clr-wrapper">
    <avi-card>
        <avi-card_header>
            <div class="license-add-panel__header">
                <div class="license-add-panel__title">
                    <div class="license-add-panel__title-text">
                        {{ l10nKeys.title | vtranslate }}
                    </div>
                </div>
            </div>
        </avi-card_header>
        <avi-card_body>
            <div class="license-add-panel__body">
                <avi-radio-container [noMarginTop]="true">
                    <avi-radio
                        class="sel-enter-license-key"
                        [value]="LicenseAddType.LICENSE_KEY"
                        [(ngModel)]="selectedLicenseAddType"
                        (ngModelChange)="resetInputs()"
                    >
                        {{ l10nKeys.licenseKeyFieldLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        class="sel-upload-license-file"
                        [value]="LicenseAddType.UPLOAD_KEY"
                        [(ngModel)]="selectedLicenseAddType"
                        (ngModelChange)="resetInputs()"
                    >
                        {{ l10nKeys.uploadLicenseFileFieldLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
                <div *ngIf="selectedLicenseAddType === LicenseAddType.LICENSE_KEY">
                    <form
                        clrForm
                        #licenseKeyForm="ngForm"
                        [clrLayout]="verticalLayout"
                    >
                        <div class="license-add-panel__license-key">
                            <div class="full-modal-config__form-control">
                                <avi-input-container>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="SingleLicense"
                                        fieldName="serial_key"
                                        for="serial_key"
                                        required
                                    >
                                        {{ l10nKeys.licenseKeyLabel | vtranslate }}
                                    </label>
                                    <input
                                        class="sel-license-key"
                                        clrInput
                                        name="serial_key"
                                        id="serial_key"
                                        placeholder="{{ l10nKeys.licenseKeyPlaceholder | vtranslate }}"
                                        [(ngModel)]="newLicenseKey"
                                        regexPattern="licenseSerialKey"
                                    />
                                </avi-input-container>
                            </div>
                            <cds-button
                                class="sel-apply-key"
                                *ngIf="!busy"
                                size="sm"
                                (click)="applyNewLicenseKey()"
                                [disabled]="!newLicenseKey || licenseKeyForm.invalid"
                            >
                                {{ l10nKeys.applyKeyLabel | vtranslate }}
                            </cds-button>
                            <cds-button
                                *ngIf="busy"
                                size="sm"
                                [loadingState]="loadingState"
                            >
                                {{ l10nKeys.applyKeyLabel | vtranslate }}
                            </cds-button>
                        </div>
                    </form>
                </div>
                <div *ngIf="selectedLicenseAddType === LicenseAddType.UPLOAD_KEY">
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                required
                            >
                                {{ l10nKeys.uploadLicenseLabel | vtranslate }}
                            </label>
                            <async-file-upload
                                [(ngModel)]="licenseFileContent"
                                [fileName]="selectedLicenseFileName"
                                (onFileUpload)="uploadLicenseFile()"
                                (fileNameChange)="selectedLicenseFileName = $event"
                                [decodeFile]="true"
                                [loadingState]="loadingState"
                                required
                            ></async-file-upload>
                        </div>
                    </div>
                </div>
            </div>
        </avi-card_body>
    </avi-card>
</div>
