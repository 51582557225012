/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'ipam';

export const customIpamDnsProfileModalBreadcrumbTitle = `${prefix}.customIpamDnsProfileModalBreadcrumbTitle`;
export const ipamDnsProfileModalBreadcrumbTitle = `${prefix}.ipamDnsProfileModalBreadcrumbTitle`;
export const usableSubnetModalBreadcrumbTitle = `${prefix}.usableSubnetModalBreadcrumbTitle`;

export const ENGLISH = {
    [customIpamDnsProfileModalBreadcrumbTitle]: 'Custom IPAM/DNS Profile',
    [ipamDnsProfileModalBreadcrumbTitle]: 'IPAM/DNS Profile',
    [usableSubnetModalBreadcrumbTitle]: 'Usable Subnet',
};
