/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import {
    UpgradeFsmState,
    UpgradeFsmUiState,
} from 'generated-types';

import { intersection } from 'underscore';
import { Subscription } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';

import { UpgradeNodeCollection } from
    'ajs/modules/upgrade/factories/upgrade-node-collection.factory';

import { ITUpgradeNode } from 'ajs/modules/upgrade/factories/upgrade-node.item.factory';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { UPGRADE_STATE_POLLING_INTERVAL } from '../../../services/update.service';

import {
    IPrecheckModalConfig,
    UpdateStore,
} from '../../update.store';

import {
    PrechecksProgressModalComponent,
} from '../../prechecks-progress-modal/prechecks-progress-modal.component';

import {
    UpgradeTranscriptModalComponent,
} from '../../upgrade-transcript-modal/upgrade-transcript-modal.component';

import {
    fipsOperationalHash,
    IUserSelectedOptions,
    nodeTypesHash,
    UpgradeStateGroupTypesHash,
} from '../../../update.types';

import * as l10n from './update-progress-card.l10n';
import './update-progress-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TUpgradeNodeCollection = typeof UpgradeNodeCollection;

const UPGRADE_TRANSCRIPT_DIALOG_ID = 'UPGRADE_TRANSCRIPT_DIALOG_ID';
const PRECHECKS_PROGRESS_DIALOG_ID = 'PRECHECKS_PROGRESS_DIALOG_ID';

/**
 * Type of params passed to instantiate UpgradeNodeCollection.
 */
interface IUpgradeNodeCollectionParams {
    state?: UpgradeFsmUiState | UpgradeFsmState,
    node_type?: string,
    pre_check_info?: boolean,
}

/**
 * @description Component to show pre checks and actual upgrade progress.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'update-progress-card',
    templateUrl: './update-progress-card.component.html',
})
export class UpdateProgressCardComponent implements OnInit, OnDestroy {
    /**
     * FIPS operation flag.
     */
    @Input()
    public fipsOperational?: string;

    @Input()
    public isControllerUpgrade = false;

    @Input()
    public selectedSegIds: string[] = [];

    public readonly fipsOperationalHash = fipsOperationalHash;

    public seGroupLeftLabel = '';

    /**
     * Version, controller is upgrading to.
     */
    public controllerVersion = '';

    /**
     * Version, SEG is upgrading to.
     */
    public segVersion: string;

    public controllerUpgradePercentage = 0;

    public isControllerUpgradeInProgress = false;

    public isSeGroupUpgradeInProgress = false;

    public isNoUpgradeInProgress = true;

    /**
     * Count of SE Groups upgrade in progress.
     */
    public seGroupCount = 0;

    public preChecksInProgress = false;

    public isSegUpgradeSelected = false;

    public readonly l10nKeys = l10nKeys;

    private upgradeNodeCollection: UpgradeNodeCollection = null;

    private isPreCheckFlowSubscription: Subscription;

    /**
     * Subcription to get updates on successful trigger of Prechecks.
     */
    private preChecksTriggeredSubscription: Subscription;

    /**
     * Subscription to get the latest values selected by the user in System update modal.
     */
    private userSelectedOptionsSubscription: Subscription;

    /**
     * Subscription to open precheck transcript modal.
     */
    private openPrecheckTranscriptSubscription: Subscription;

    /**
     * Options selected while triggering upgrade operation.
     */
    private userSelectedOptions: IUserSelectedOptions;

    /**
     * Version, controller is being rollbacked to.
     * In case of rollback from a patch upgrade to a controller version, backend doesn't
     * send any imageRef.
     */
    private rollbackVersion: string;

    private rollbackVersionSubscription: Subscription;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('UpgradeNodeCollection')
        private readonly UpgradeNodeCollectionClass: TUpgradeNodeCollection,
        private readonly dialogService: DialogService,
        private readonly updateStore: UpdateStore,
    ) {
        this.initUpgradeNodeCollection();
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { updateStore } = this;

        this.triggerUpgradeStatusInfoPolling();

        this.userSelectedOptionsSubscription = updateStore.userSelectedOptions$.subscribe(
            (userSelectedOptions: IUserSelectedOptions) => {
                const { applyToSystem } = userSelectedOptions;

                this.userSelectedOptions = userSelectedOptions;
                this.isSegUpgradeSelected = applyToSystem;
            },
        );

        this.isPreCheckFlowSubscription = updateStore.isPreCheckFlow$.subscribe(
            async(isPrecheckFlow: boolean) => {
                const { upgradeNodeCollection } = this;

                if (isPrecheckFlow) {
                    // Don't retrigger polling if its already in progress.
                    if (!this.preChecksInProgress) {
                        await this.triggerUpgradeStatusInfoPolling(isPrecheckFlow);
                        this.preChecksInProgress = upgradeNodeCollection.arePreChecksInProgress();
                    }

                    if (this.preChecksInProgress) {
                        this.trackPreChecksProgress();
                    } else {
                        this.updateOverallPrechecksState();
                        this.setPrecheckNodes();
                    }
                }
            },
        );

        this.preChecksTriggeredSubscription = updateStore.preChecksTriggered$.subscribe(
            (prechecksTriggered: boolean) => {
                if (prechecksTriggered) {
                    this.onPrechecksTrigger();
                }
            },
        );

        this.rollbackVersionSubscription = updateStore.rollbackVersion$.subscribe(
            version => this.rollbackVersion = version,
        );

        this.openPrecheckTranscriptSubscription = updateStore.precheckTranscriptOpen$
            .subscribe((config: IPrecheckModalConfig) => {
                this.openPrechecksProgressModal(config.readonly, config.nodeIds);
            });
    }

    /**
     * @override
     * Reset the prechecks triggered state in case they are still in progress
     * and user navigates away from the page.
     */
    public ngOnDestroy(): void {
        this.upgradeNodeCollection.destroy();
        this.preChecksTriggeredSubscription.unsubscribe();
        this.isPreCheckFlowSubscription.unsubscribe();
        this.userSelectedOptionsSubscription.unsubscribe();
        this.rollbackVersionSubscription.unsubscribe();
        this.openPrecheckTranscriptSubscription.unsubscribe();
        this.updateStore.updateIsSystemUpdateInProgress(false);
    }

    public openProgressModal(): void {
        if (this.preChecksInProgress) {
            this.openPrechecksProgressModal(false, this.selectedSegIds);
        } else {
            this.openUpgradeTranscriptModal();
        }
    }

    /**
     * Return if the 'View Progress' button needs to be disabled.
     */
    public get disableViewProgressButton(): boolean {
        const {
            isControllerUpgrade,
            isControllerUpgradeInProgress,
            isNoUpgradeInProgress,
            isSeGroupUpgradeInProgress,
            preChecksInProgress,
        } = this;

        return isNoUpgradeInProgress ||
            isControllerUpgrade && !isControllerUpgradeInProgress && !preChecksInProgress ||
            !isControllerUpgrade && isSeGroupUpgradeInProgress;
    }

    /**
     * Open modal to display the progress of upgrade/rollback operation.
     */
    private openUpgradeTranscriptModal(): void {
        const node = this.upgradeNodeCollection.getControllerNode();
        const { id, nodeType } = node;

        this.dialogService.add({
            id: UPGRADE_TRANSCRIPT_DIALOG_ID,
            component: UpgradeTranscriptModalComponent as Type<Component>,
            componentProps: {
                nodeId: id,
                nodeType,
                onClose: () => this.dialogService.remove(UPGRADE_TRANSCRIPT_DIALOG_ID),
            },
        });
    }

    /**
     * Open modal to display the progress of pre checks.
     */
    private async openPrechecksProgressModal(
        readonly = false,
        selectedSegIds?: string[],
    ): Promise<void> {
        let upgradeNodeCollection;

        /**
         * Handle case where few SEGs are in upgrade state and few in precheck state.
         * Class level upgradeNodeCollection contains information about nodes in upgrade state so
         * we need to fetch information for precheck state nodes.
         */
        if (selectedSegIds &&
            intersection(
                this.upgradeNodeCollection.getItemIds(),
                selectedSegIds,
            ).length !== selectedSegIds.length) {
            upgradeNodeCollection = new this.UpgradeNodeCollectionClass();
            upgradeNodeCollection.setParams({
                pre_check_info: true,
                node_type: nodeTypesHash.NODE_SE_GROUP,
            });

            await upgradeNodeCollection.load();
            upgradeNodeCollection.stopUpdates();
        } else {
            upgradeNodeCollection = this.upgradeNodeCollection;
        }

        this.dialogService.add({
            id: PRECHECKS_PROGRESS_DIALOG_ID,
            component: PrechecksProgressModalComponent as Type<Component>,
            componentProps: {
                upgradeNodeCollection,
                readonly,
                selectedSegIds,
                onClose: () => {
                    this.dialogService.remove(PRECHECKS_PROGRESS_DIALOG_ID);
                },
                onUpdate: async(isWarning: boolean) => {
                    /**
                     * Restart polling on warning state of prechecks.
                     * Success case is already handled by trackPreChecksProgress method.
                     */
                    if (isWarning) {
                        await this.updateStore
                            .triggerPrechecks(this.userSelectedOptions, true);
                        this.updateStore.onPreChecksTrigger();
                        await this.restartUpgradePolling(isWarning);
                        this.setIsNoUpgradeInProgress();
                    }

                    this.dialogService.remove(PRECHECKS_PROGRESS_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Create instance of upgradeNodeCollection and set polling interval.
     */
    private initUpgradeNodeCollection(): void {
        this.upgradeNodeCollection = new this.UpgradeNodeCollectionClass({
            bind: {
                collectionLoadSuccess: this.updateProgressFlags,
            },
        });
        this.upgradeNodeCollection.setUpdateInterval(UPGRADE_STATE_POLLING_INTERVAL);
    }

    /**
     * Restart polling of upgrade status.
     */
    private async restartUpgradePolling(isWarning: boolean): Promise<void> {
        this.stopUpgradeStatusPolling();
        await this.triggerUpgradeStatusInfoPolling(isWarning);
    }

    /**
     * Stop polling of upgrade status api.
     */
    private stopUpgradeStatusPolling(): void {
        if (this.upgradeNodeCollection) {
            this.upgradeNodeCollection.stopUpdates();
        }
    }

    /**
     * Update all the progress related flags to be used to update the template.
     */
    private updateProgressFlags = (): void => {
        if (this.preChecksInProgress) {
            this.trackPreChecksProgress();
        } else {
            this.setControllerUpdateIsInProgress();
            this.setSeGroupsAreInProgress();
        }
    };

    /**
     * Set controller upgrade percentage.
     */
    private setControllerUpgradePercentage(): void {
        this.controllerUpgradePercentage =
            this.upgradeNodeCollection.getControllerProgressPercentage() || 0;
    }

    /**
     * Set controller node's version. During upgrade in-progress operation, currentVersion
     * is updated to be the 'target version'.
     */
    private setControllerVersion(): void {
        const controllerNode = this.upgradeNodeCollection.getControllerNode();

        if (controllerNode) {
            const { preChecksInProgress } = this;

            if (preChecksInProgress) {
                this.controllerVersion = controllerNode.getImageRefVersion() ||
                    this.rollbackVersion;
            } else {
                this.controllerVersion = controllerNode.getVersion();
            }
        }
    }

    /**
     * Set isControllerUpgradeInProgress flag.
     */
    private setControllerUpdateIsInProgress(): void {
        this.isControllerUpgradeInProgress =
            this.upgradeNodeCollection.isControllerUpdateInProgress();

        this.updateStore.updateIsSystemUpdateInProgress(this.isControllerUpgradeInProgress);

        if (this.isControllerUpgradeInProgress) {
            this.setControllerVersion();
            this.setControllerUpgradePercentage();
        }

        this.setIsNoUpgradeInProgress();
    }

    /**
     * Set isSeGroupUpgradeInProgress flag.
     */
    private setSeGroupsAreInProgress(): void {
        const { upgradeNodeCollection } = this;

        this.isSeGroupUpgradeInProgress = upgradeNodeCollection.isSeGroupUpdateInProgress();

        if (this.isSeGroupUpgradeInProgress) {
            this.seGroupCount = this.getSeGroupCount();
            this.setSegVersion();
        }

        this.setIsNoUpgradeInProgress();
    }

    /**
     * Return number of seGroups currently in-progress state.
     */
    private getSeGroupCount(): number {
        return this.upgradeNodeCollection.getSeGroupCount();
    }

    /**
     * Set isNoUpgradeInProgress flag.
     */
    private setIsNoUpgradeInProgress(): void {
        const {
            isControllerUpgradeInProgress,
            isSeGroupUpgradeInProgress,
            fipsOperational,
            fipsOperationalHash,
            preChecksInProgress,
        } = this;

        this.isNoUpgradeInProgress = !isControllerUpgradeInProgress &&
            !isSeGroupUpgradeInProgress &&
            fipsOperational !== fipsOperationalHash.FIPS_UNKNOWN &&
            !preChecksInProgress;
    }

    /**
     * Start polling on trigger of pre checks.
     */
    private onPrechecksTrigger(): void {
        this.preChecksInProgress = true;
        this.setIsNoUpgradeInProgress();

        this.triggerUpgradeStatusInfoPolling(true);
    }

    private async triggerUpgradeStatusInfoPolling(isPreCheckFlow = false): Promise<void> {
        const { UPGRADE_STATE_GROUP_IN_PROGRESS } = UpgradeStateGroupTypesHash;
        const {
            NODE_CONTROLLER_CLUSTER,
            NODE_SE_GROUP,
        } = nodeTypesHash;
        const params: IUpgradeNodeCollectionParams = {
            pre_check_info: null,
            state: null,
            node_type: null,
        };

        if (isPreCheckFlow) {
            params.pre_check_info = true;
        } else {
            params.state = UPGRADE_STATE_GROUP_IN_PROGRESS;
        }

        if (!this.isControllerUpgrade) {
            params.node_type = NODE_SE_GROUP;
        } else if (this.isControllerUpgrade && (this.isSegUpgradeSelected || !isPreCheckFlow)) {
            params.node_type = `${NODE_CONTROLLER_CLUSTER},${NODE_SE_GROUP}`;
        } else {
            params.node_type = NODE_CONTROLLER_CLUSTER;
        }

        this.upgradeNodeCollection.setParams(params);
        await this.upgradeNodeCollection.load();

        if (isPreCheckFlow) {
            this.updateStore.setIsPreCheckFlow(true);
        }
    }

    /**
     * Continuously track the progress of prechecks and update the flags accordingly.
     */
    private async trackPreChecksProgress(): Promise<void> {
        const {
            isControllerUpgrade,
            isSegUpgradeSelected,
            updateStore,
            upgradeNodeCollection,
        } = this;

        this.preChecksInProgress = upgradeNodeCollection.arePreChecksInProgress();

        this.updateStore.onPreChecksLoad();

        if (this.preChecksInProgress) {
            if (isControllerUpgrade) {
                this.setControllerVersion();
            }

            if (isSegUpgradeSelected || !isControllerUpgrade) {
                this.setSegVersion();
            }
        } else {
            const overallPrechecksState = this.getOverllPrechecksState();

            updateStore.onPrechecksComplete(overallPrechecksState);
            this.setPrecheckNodes();

            if (overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS) {
                await this.restartUpgradePolling(false);
                updateStore.setIsPreCheckFlow(false);
            }
        }

        this.setIsNoUpgradeInProgress();
    }

    /**
     * Set precheck Nodes.
     */
    private setPrecheckNodes(): void {
        const {
            isControllerUpgrade,
            selectedSegIds,
            updateStore,
            upgradeNodeCollection,
        } = this;

        let precheckNodes: ITUpgradeNode[];

        if (isControllerUpgrade) {
            precheckNodes = [upgradeNodeCollection.getControllerNode()];
        } else {
            precheckNodes = upgradeNodeCollection.getSelectedSegNodes(selectedSegIds);
        }

        updateStore.setPrecheckNodes(precheckNodes);
    }

    /**
     * Set the version SEG is upgrading to.
     * In case of prechecks, the version received from backend is with image_ref key while,
     * in case of an actual upgrade, the version is picked up from the complete version hash.
     */
    private setSegVersion(): void {
        const {
            preChecksInProgress,
            selectedSegIds,
            upgradeNodeCollection,
        } = this;

        let seGroupList: ITUpgradeNode[];

        if (selectedSegIds.length) {
            seGroupList = upgradeNodeCollection.getSelectedSegNodes(selectedSegIds);
        } else {
            seGroupList = upgradeNodeCollection.itemList as ITUpgradeNode[];
        }

        if (seGroupList?.length) {
            this.segVersion = preChecksInProgress ? seGroupList[0]?.getImageRefVersion() :
                seGroupList[0]?.getVersion();
        }
    }

    /**
     * Return the overall prechecks status for all the nodes with latest prechecks operation.
     */
    private getOverllPrechecksState(): UpgradeFsmState {
        const { upgradeNodeCollection } = this;

        return this.isControllerUpgrade ? upgradeNodeCollection.getOverallPreCheckState() :
            upgradeNodeCollection.getSelectedSegsOverallState(this.selectedSegIds);
    }

    /**
     * Update the overallPrechecks state on initial load.
     */
    private updateOverallPrechecksState(): void {
        this.updateStore.updateOverallPrechecksState(this.getOverllPrechecksState());
    }
}
