/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AwsConfigurationConfigItem,
    AzureConfigurationConfigItem,
    Cloud,
    CloudCollection,
    ContentLibConfigConfigItem,
    DataNetworkConfigConfigItem,
    DATA_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    DnsResolverConfigItem,
    DNS_RESOLVER_CONFIG_ITEM_TOKEN,
    GCPConfigurationConfigItem,
    GCPNetworkConfigConfigItem,
    GCPVipAllocationConfigItem,
    LinuxServerConfigurationConfigItem,
    LinuxServerHostConfigItem,
    ManagementNetworkConfigConfigItem,
    MANAGEMENT_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    NsxtConfigurationConfigItem,
    NsxtTier1SegmentConfigConfigItem,
    NsxtTier1SegmentManualModeConfigItem,
    OpenStackConfigurationConfigItem,
    OpenstackNetworkCollection,
    OpenstackNetworkItem,
    OpenStackVipNetworkConfigItem,
    Tier1LogicalRouterInfoConfigItem,
    VCenterConfigurationConfigItem,
    VCenterServer,
    VCenterServerCollection,
} from '.';

import {
    AWS_CONFIGURATION_CONFIG_ITEM_TOKEN,
    AZURE_CONFIGURATION_CONFIG_ITEM_TOKEN,
    CLOUD_ITEM_TOKEN,
    GCP_CONFIGURATION_CONFIG_ITEM_TOKEN,
    GCP_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    GCP_VIP_ALLOCATION_CONFIG_ITEM_TOKEN,
    LINUX_SERVER_CONFIGURATION_CONFIG_ITEM_TOKEN,
    LINUX_SERVER_HOST_CONFIG_ITEM_TOKEN,
    OPEN_STACK_CONFIGURATION_CONFIG_ITEM_TOKEN,
    OPEN_STACK_NETWORK_COLLECTION_TOKEN,
    OPEN_STACK_NETWORK_ITEM_TOKEN,
    OPEN_STACK_VIP_NETWORK_CONFIG_ITEM_TOKEN,
    VCENTER_CONFIGURATION_CONFIG_ITEM_TOKEN,
} from './cloud.tokens';

const cloudModule = angular.module('avi/cloud');
const factories = [
    {
        dependency: NsxtConfigurationConfigItem,
        name: 'NsxtConfigurationConfigItem',
    },
    {
        dependency: Tier1LogicalRouterInfoConfigItem,
        name: 'Tier1LogicalRouterInfoConfigItem',
    },
    {
        dependency: NsxtTier1SegmentConfigConfigItem,
        name: 'NsxtTier1SegmentConfigConfigItem',
    },
    {
        dependency: NsxtTier1SegmentManualModeConfigItem,
        name: 'NsxtTier1SegmentManualModeConfigItem',
    },
    {
        dependency: CloudCollection,
        name: 'CloudCollection',
    },
    {
        dependency: ContentLibConfigConfigItem,
        name: 'ContentLibConfigConfigItem',
    },
    {
        dependency: VCenterServer,
        name: 'VCenterServer',
    },
    {
        dependency: VCenterServerCollection,
        name: 'VCenterServerCollection',
    },
    {
        dependency: DataNetworkConfigConfigItem,
        name: DATA_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: ManagementNetworkConfigConfigItem,
        name: MANAGEMENT_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: DnsResolverConfigItem,
        name: DNS_RESOLVER_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: LinuxServerHostConfigItem,
        name: LINUX_SERVER_HOST_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: LinuxServerConfigurationConfigItem,
        name: LINUX_SERVER_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AzureConfigurationConfigItem,
        name: AZURE_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: AwsConfigurationConfigItem,
        name: AWS_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VCenterConfigurationConfigItem,
        name: VCENTER_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: GCPConfigurationConfigItem,
        name: GCP_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: GCPNetworkConfigConfigItem,
        name: GCP_NETWORK_CONFIG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: GCPVipAllocationConfigItem,
        name: GCP_VIP_ALLOCATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: OpenStackConfigurationConfigItem,
        name: OPEN_STACK_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: OpenstackNetworkItem,
        name: OPEN_STACK_NETWORK_ITEM_TOKEN,
    },
    {
        dependency: OpenstackNetworkCollection,
        name: OPEN_STACK_NETWORK_COLLECTION_TOKEN,
    },
    {
        dependency: OpenStackVipNetworkConfigItem,
        name: OPEN_STACK_VIP_NETWORK_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: Cloud,
        name: CLOUD_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(cloudModule, 'factory', name, dependency);
});
