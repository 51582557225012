<avi-state-page class="l4-policy">
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="l4PolicySetGridConfig">
            <ng-template
                #expandedContentTemplateRef
                let-row
            >
                <strong>{{ globalL10nKeys.descriptionLabel | vtranslate }}</strong>
                <span class="l4-policy__hyphen-space-separator">-</span>
                <span>{{ row.getConfig().description || (globalL10nKeys.notApplicableLabel) | vtranslate }}</span>
            </ng-template>
        </avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
