/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function classMixin(Class, mixin) {
    angular.extend(Class.prototype, mixin);

    return Class;
}

/**
 * @ngdoc constant
 * @name classMixin
 * @type {Function}
 * @param {Function} Class
 * @param {Object} mixin
 * @author Alex Malitsky
 * @desc
 *
 *     Extends passed class prototype with enumerable mixin properties.
 */
angular.module('avi/utils')
    .constant('classMixin', classMixin);
