<div
    class="user-card-menu__item"
    avi-tooltip
    [showOnClick]="true"
    [tooltipControl$]="userMenuControl$"
    [positionsPriority]="menuPositions"
>
    <clr-spinner
        clrInline
        *ngIf="!userName"
    ></clr-spinner>

    <div
        class="sel-user-menu"
        *ngIf="userName"
    >
        <cds-icon shape="user"></cds-icon>
    </div>

    <ng-template #aviTooltipContent>
        <ul class="user-card-menu__list user-card-menu__list--user-card-menu-content">
            <li class="user-card-menu__list-item user-card-menu__list-item--border user-card-menu__list-item--user-card">
                <user-card
                    [lastLoginTimestamp]="lastLoginTimestamp"
                    (onTooltipClose)="handleTooltipClose()"
                ></user-card>
            </li>
            <li
                class="sel-about-avi user-card-menu__list-item user-card-menu__list-item--border user-card-menu__list-item--with-hover"
                (click)="handleOpenAbout()"
            >
                <div class="user-card-menu__list-item--label">
                    {{ l10nKeys.aboutLabel | vtranslate }}
                </div>
            </li>
            <li
                class="sel-logout user-card-menu__list-item user-card-menu__list-item--with-hover"
                (click)="handleLogout()"
            >
                <div class="user-card-menu__list-item--label">
                    {{ l10nKeys.signOutLabel | vtranslate }}
                </div>
            </li>
        </ul>
    </ng-template>
</div>
