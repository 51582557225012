<div class="in-use-interface-list-expanded-content">
    <div
        *ngIf="loading"
        class="in-use-interface-list-expanded-content__spinner"
    >
        <clr-spinner clrMedium></clr-spinner>
    </div>
    <avi-alert-group
        alertType="danger"
        *ngIf="errors"
        class="in-use-interface-list-expanded-content__alert-group"
    >
        <span>{{ errors }}</span>
    </avi-alert-group>
    <avi-data-grid
        *ngIf="!loading"
        class="in-use-interface-list-expanded-content__ip-address-grid"
        gridTitle="{{ l10nKeys.floatingIpAddressesLabel | vtranslate : ipInfo.length }}"
        [config]="ipAddressesGridConfig"
        [rows]="ipInfo"
    ></avi-data-grid>
</div>
