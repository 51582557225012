/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IAuthenticationMatch } from 'generated-types';
import { AuthenticationMatch } from 'object-types';

import { PolicyMatchConfigItem }
    from 'ajs/modules/policies/factories/config-items/policy-match.config-item.factory';

import {
    HostHdrMatchConfigItem,
    IpAddrMatchConfigItem,
    PathMatchConfigItem,
} from 'ajs/modules/match/factories';

type TAuthenticationMatchPartial = Omit<IAuthenticationMatch, 'client_ip' | 'host_hdr' | 'path'>;

interface IAuthenticationMatchConfig extends TAuthenticationMatchPartial {
    client_ip?: IpAddrMatchConfigItem;
    host_hdr?: HostHdrMatchConfigItem;
    path?: PathMatchConfigItem;
}

/**
 * @description AuthenticationMatch Config item.
 *
 * @author Aravindh Nagarajan
 */
export class AuthenticationMatchConfigItem extends
    PolicyMatchConfigItem<IAuthenticationMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthenticationMatch,
            ...args,
        };

        super(extendedArgs);
    }
}
