/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-top-info';
const prefix = `${moduleName}.${componentName}`;

export const endToEndTimingLabel = `${prefix}.endToEndTimingLabel`;
export const clientRttLabel = `${prefix}.clientRttLabel`;
export const serverRttLabel = `${prefix}.serverRttLabel`;
export const appResponseLabel = `${prefix}.appResponseLabelLabel`;
export const dataTransferLabel = `${prefix}.dataTransferLabel`;
export const virtualServiceIpLabel = `${prefix}.virtualServiceIpLabel`;
export const sourceClientIpLabel = `${prefix}.sourceClientIpLabel`;
export const serverConnectionIpLabel = `${prefix}.serverConnectionIpLabel`;
export const dnsServerLabel = `${prefix}.dnsServerLabel`;

export const ENGLISH = {
    [endToEndTimingLabel]: 'End-to-End Timing',
    [clientRttLabel]: 'Client RTT',
    [serverRttLabel]: 'Server RTT',
    [appResponseLabel]: 'App Response',
    [dataTransferLabel]: 'Data Transfer',
    [virtualServiceIpLabel]: 'Virtual Service IP',
    [sourceClientIpLabel]: 'Source Client IP',
    [serverConnectionIpLabel]: 'Server Connection IP',
    [dnsServerLabel]: 'DNS Server',
};
