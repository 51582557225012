/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-protocol';
const prefix = `${moduleName}.${componentName}`;

export const grpcLabel = `${prefix}.grpcLabel`;
export const grpcServiceNameLabel = `${prefix}.grpcServiceNameLabel`;
export const grpcMethodNameLabel = `${prefix}.grpcMethodNameLabel`;
export const grpcStatusReasonPhraseLabel = `${prefix}.grpcStatusReasonPhraseLabel`;

export const ENGLISH = {
    [grpcLabel]: 'GRPC',
    [grpcServiceNameLabel]: 'GRPC Service Name',
    [grpcMethodNameLabel]: 'GRPC Method Name',
    [grpcStatusReasonPhraseLabel]: 'GRPC Status Reason Phrase',
};
