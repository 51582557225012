<div
    provideParentForm
    class="system-settings-client-management-access-config-container"
>
    <h6 class="full-modal__section-header">
        {{ l10nKeys.clientManagementAccessLabel | vtranslate }}
    </h6>

    <div class="system-settings-client-management-access-config-container__access-config">
        <system-settings-client-management-access-config
            id="client-management-ssh-access-config"
            name="client-management-ssh-access-config"
            [allowedClientsConfig]="allowedSshClientsConfig"
            (allowedClientsConfigChange)="setMgmtIpAccessControl()"
            [clientType]="allowedClientTypesEnumValues.ssh"
            [clientLabel]="l10nKeys.allowedSshClientsLabel | vtranslate"
        ></system-settings-client-management-access-config>
    </div>

    <div class="system-settings-client-management-access-config-container__access-config">
        <system-settings-client-management-access-config
            id="client-management-cli-access-config"
            name="client-management-cli-access-config"
            [allowedClientsConfig]="allowedCliShellClientsConfig"
            (allowedClientsConfigChange)="setMgmtIpAccessControl()"
            [clientType]="allowedClientTypesEnumValues.cli"
            [clientLabel]="l10nKeys.allowedCliShellClientsLabel | vtranslate"
        ></system-settings-client-management-access-config>
    </div>

    <div class="system-settings-client-management-access-config-container__access-config">
        <system-settings-client-management-access-config
            id="client-management-https-access-config"
            name="client-management-https-access-config"
            [allowedClientsConfig]="allowedExternalHttpsClientsConfig"
            (allowedClientsConfigChange)="setMgmtIpAccessControl()"
            [clientType]="allowedClientTypesEnumValues.https"
            [clientLabel]="l10nKeys.allowedExternalHttpsClientsLabel | vtranslate"
        ></system-settings-client-management-access-config>
    </div>

    <div class="system-settings-client-management-access-config-container__access-config">
        <system-settings-client-management-access-config
            id="client-management-snmp-access-config"
            name="client-management-snmp-access-config"
            [allowedClientsConfig]="allowedExternalSnmpClientsConfig"
            (allowedClientsConfigChange)="setMgmtIpAccessControl()"
            [clientType]="allowedClientTypesEnumValues.snmp"
            [clientLabel]="l10nKeys.allowedExternalSnmpClientsLabel | vtranslate"
        ></system-settings-client-management-access-config>
    </div>
</div>
