/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { RevisedDataTransformer } from './revised-data-transformer.factory';

/**
 * @constructor
 * @memberof module:avi/dataModel
 * @extends RevisedDataTransformer
 * @author Alex Malitsky, Ashish Verma, Ram Pal
 * @desc
 *     Can be used for all normal list APIs.
 *
 *     Transforms `limit_` and `offset_` to `page` and `page_size`
 *     properties on request preparation.
 *
 *     Wraps results objects into `config` property when it is not present.
 *  DataSource of {@link module:avi/dataModel.ListDataTransformer ListDataTransformer}.
 */
export class ListDataTransformer extends RevisedDataTransformer {
    constructor(args) {
        super(args);
        /**
         * API limits the number of Items which can be loaded in one call.
         * @type {number}
         * @memberOf ListDataTransformer
         * @protected
         */
        this.apiPageSizeLimit_ = 200;
    }

    /**
     * Prepares request config to be passed to DataTransport.
     * @param {ListDataTransportRequestParams} requestParams - With special properties
     *     `offset_` and `limit_` to be transformed into `page` and `page_size`.
     * @returns {ListDataTransportRequestParams}
     * @memberOf ListDataTransformer
     * @override
     */
    getRequestConfig(requestParams) {
        const params = angular.copy(requestParams);

        if ('offset_' in params && 'limit_' in params) {
            if (params['limit_'] > this.apiPageSizeLimit_) {
                console.warn(
                    `Page size (and therefore limit) can't be greater
                    than API limit which is ${this.apiPageSizeLimit_},
                    asked for ${params['limit_']}`,
                );
                params['limit_'] = this.apiPageSizeLimit_;
            }

            const limitAndOffset2pageSizeAndPage = this
                .getAjsDependency_('limitAndOffset2pageSizeAndPage');

            const [pageSize, page] =
            limitAndOffset2pageSizeAndPage(
                params['limit_'],
                params['offset_'],
            );

            params['page_size'] = pageSize;
            params['page'] = page;
        }

        return params;
    }

    /**
     * Since offset and limit aren't supported by list APIs we can get offset lower than
     * requested. Here we establish offset guarantee, limit still can be larger then requested -
     * see no harm.
     * @param {ListDataTransportResponse} resp
     * @param {ListDataTransportRequestParams} reqParams
     * @memberOf ListDataTransformer
     * @returns {ListDataTransportResponse}
     * @protected
     */
    responseListOffsetControl_(resp, reqParams) {
        let offsetShift;

        if ('results' in resp.data && Array.isArray(resp.data.results) &&
        resp.data.results.length) {
            if ('offset_' in reqParams && 'limit_' in reqParams) {
                if ((offsetShift =
                reqParams['offset_'] - (reqParams['page'] - 1) * reqParams['page_size'])
                ) {
                    if (offsetShift > 0) {
                        resp.data.results.splice(0, offsetShift);
                    } else {
                        console.error(
                            `Returned list of results has an offset: %d higher
                            than requested: %d`,
                            (reqParams['page'] - 1) * reqParams['page_size'],
                            reqParams['offset_'],
                        );
                    }
                }
            }
        }

        return resp;
    }

    /**
     * Guarantees list's offset and wraps Item's data into `config` property.
     * Last option is used for non-inventory list APIs.
     * @param {ListDataTransportResponse} resp
     * @param {ListDataTransportRequestParams} reqParams
     * @returns {ListDataTransportResponse}
     * @memberOf ListDataTransformer
     * @override
     */
    processResponse(resp, reqParams) {
        let sampleRow,
            results;

        resp = this.responseListOffsetControl_(resp, reqParams);

        if ('results' in resp.data && Array.isArray(resp.data.results) &&
        resp.data.results.length) {
            results = resp.data.results;

            sampleRow = _.sample(results);

            if (!('config' in sampleRow) || 'url' in sampleRow) {
                resp.data.results = results.map(function(row) {
                    return { config: row };
                });
            }
        }

        return resp;
    }
}

ListDataTransformer.ajsDependencies = [
    'limitAndOffset2pageSizeAndPage',
];
