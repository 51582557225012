<div class="vs-logs-signpost-custom-significance clr-wrapper">
    <ng-content></ng-content>

    <avi-dynamic-data-grid
        *ngIf="vsLogsSignpostStore.vsLogsSignificanceSignpostData$ | async as vsLogsSignificanceSignpostData"
        class="vs-logs-signpost-custom-significance__data-grid"
        [config]="significanceGroupsGridConfig"
        [rows]="vsLogsSignificanceSignpostData"
        [rowsTotal]="vsLogsSignificanceSignpostData.length"
    ></avi-dynamic-data-grid>
</div>

<div
    class="vs-logs-signpost-custom-significance__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        class="vs-logs-signpost-custom-significance__hide-overflow-content"
        *ngIf="row.value.isValid"
        vsLogFilter
        [property]="row.value.param"
        [value]="row.value.value"
        [operator]="row.value?.contains ? FilterOperatorType.CONTAINS : FilterOperatorType.EQUAL_TO"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
    >
        {{ row.value.display || row.value.value }}
    </span>
    <span
        *ngIf="!row.value.isValid"
        vsLogFilterableEmptyField
        [property]="row.value.param"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        class="vs-logs-signpost-custom-significance__empty-field"
    ></span>
</ng-template>

<ng-template
    #progressBarFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-progress-bar
        [percentage]="row.percentage"
        [hidePercentage]="true"
    ></avi-progress-bar>
</ng-template>
