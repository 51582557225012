/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'add-attachments-grid';
const prefix = `${moduleName}.${componentName}`;

export const addAttachmentsGridHeaderLabel = `${prefix}.addAttachmentsGridHeaderLabel`;
export const addTechSupportActionLabel = `${prefix}.addTechSupportActionLabel`;
export const addTrafficCaptureActionLabel = `${prefix}.addTrafficCaptureActionLabel`;
export const addCoreFilesActionLabel = `${prefix}.addCoreFilesActionLabel`;
export const addUploadsActionLabel = `${prefix}.addUploadsActionLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const nameColumnLabel = `${prefix}.nameColumnLabel`;
export const statusColumnLabel = `${prefix}.statusColumnLabel`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const emptyGridPlaceholder = `${prefix}.emptyGridPlaceholder`;
export const techSupportAttachmentsSelectionDialogHeader = `${prefix}.techSupportAttachmentsSelectionDialogHeader`;
export const trafficCaptureAttachmentsSelectionDialogHeader = `${prefix}.trafficCaptureAttachmentsSelectionDialogHeader`;

export const ENGLISH = {
    [addAttachmentsGridHeaderLabel]: 'Files to be uploaded ({0})',
    [addButtonLabel]: 'Add',
    [addTechSupportActionLabel]: 'Tech Support',
    [addTrafficCaptureActionLabel]: 'Traffic Capture',
    [addCoreFilesActionLabel]: 'Core',
    [addUploadsActionLabel]: 'Uploads',
    [nameColumnLabel]: 'Name',
    [statusColumnLabel]: 'Status',
    [removeActionLabel]: 'Remove',
    [emptyGridPlaceholder]: 'No new files have been added',
    [techSupportAttachmentsSelectionDialogHeader]: 'Tech Support Files',
    [trafficCaptureAttachmentsSelectionDialogHeader]: 'Traffic Capture Files',
};
