/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name header
 * @restrict E
 */
angular.module('aviApp').directive('header', [
'$state', '$filter',
function($state, $filter) {
    function headerLink(scope) {
        const
            stateIncludesFilter = $filter('currentStateIncludes'),
            hasAlerts = stateIncludesFilter('alerts'),
            hasBgpPeers = stateIncludesFilter('bgp-peers'),
            hasEvents = stateIncludesFilter('events'),
            hasLogs = stateIncludesFilter('logs'),
            isInsights = stateIncludesFilter('hs-insights'),
            stateContext = $filter('stateContext')($state.$current);

        scope.showAsSE = showAs('serviceengine-detail');
        scope.showAsVS = showAs('virtualservice-detail');
        scope.showAsPool = showAs('pool-detail');
        scope.hasBgpPeers = hasBgpPeers;

        scope.hideMetricValueTypeSelector =
            hasLogs || hasEvents || hasAlerts || isInsights || hasBgpPeers;
        scope.hideTimeframeSelector = isInsights || hasBgpPeers;

        function showAs(context) {
            return stateContext === context || $state.includes(`**.${context}.**`);
        }
    }

    return {
        restrict: 'E',
        scope: true,
        link: headerLink,
        template: require('../../views/infrastructure/header.partial.html'),
    };
}]);
