/** @module AnalyticsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @ngdoc component
 * @name timingTile
 * @param {timingTileConfig} config
 * @description
 *
 *     Timing tile for timingTiles component. Can have either icon or value, not both (for
 *     sizing and grouping purposes).
 * @author Rajawant Prajapati
 *
 */

import classnames from 'classnames';
import { isUndefined } from 'underscore';
import { StringService } from 'string-service';
import { MyAccountService } from 'ng/modules/core/services/my-account.service';

import clientImgPath from '../../../../../img/timing/client.svg';
import serverImgPath from '../../../../../img/timing/server2.svg';
import lbImgPath from '../../../../../img/timing/loadbalancer.png';
import appImgPath from '../../../../../img/timing/app.svg';

import template from './timing-tile.component.html';
import { ITimingTileConfig } from '../timing-tiles.component';

const componentName = 'timing-tile';

const iconsImgHash = {
    client: clientImgPath,
    server: serverImgPath,
    lb: lbImgPath,
    app: appImgPath,
};

class TimingTileController {
    /**
     * Tile config with metric values or icons
     */
    public config: ITimingTileConfig;

    constructor(
        private readonly $element: JQuery,
        private readonly graphSync: any,
        private readonly myAccount: MyAccountService,
        private readonly stringService: StringService,
    ) {}

    /**
     * Returns tile icon image path
     */
    public get iconImgPath(): string {
        return iconsImgHash[this.config.icon];
    }

    /** @override */
    public $onInit(): void {
        const { $element: $elem, config } = this;

        const classNames = classnames(
            componentName,
            `sel-${componentName}__${this.stringService.camelCaseToDash(config.id)}`,
            config.highlighted && `${componentName}--highlighted`,
            'icon' in config && `${componentName}--icon`,
        );

        $elem.addClass(classNames);
    }

    /**
     * Returns actual value to be passed to aviValue component.
     * @returns {number}
     */
    // TODO switch to events
    public get value(): number {
        const { series, value } = this.config;

        if (!isUndefined(value)) {
            return value;
        }

        if (series) {
            let seriesValue: number;

            if (this.graphSync.mouseIsOnGraph()) {
                seriesValue = series.getValue('exact', this.graphSync.mouseIsOnGraph());
            } else {
                seriesValue = series.getValue(this.myAccount.valuesToDisplay);
            }

            return isUndefined(seriesValue) ? NaN : seriesValue;
        }

        return NaN;
    }

    /**
     * Returns true/false based on value of icon and value
     * @returns {boolean}
     */
    public get showTimingTileValueTitle(): boolean {
        return !this.config.icon && !isUndefined(this.value);
    }
}

TimingTileController.$inject = [
    '$element',
    'GraphSync',
    'myAccount',
    'stringService',
];

export const timingTileComponentOptions = {
    bindings: {
        config: '<',
    },
    controller: TimingTileController,
    template,
};
