/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { StringService } from 'string-service';

const stringService = new StringService();

String.prototype.slug = function() {
    return stringService.slug(this);
};

/**
 * Returns name part of the ref.
 * @return {string}
 */
String.prototype.name = function() {
    return stringService.name(this);
};

String.prototype.enumeration = function(prefix) {
    return stringService.enumeration(this, prefix);
};
