<div class="vs-logs-page">
    <avi-state-page [hasSidebar]="true">
        <avi-state-page_top-bar>
            <div class="vs-logs-page__top-bar">
                <div class="vs-logs-page__top-bar-container">
                    <ng-container *ngIf="vsLogsStore.hasOverrideL4AppProfile$ | async">
                        <vs-logs-type-dropdown></vs-logs-type-dropdown>
                    </ng-container>

                    <vs-logs-search-bar class="vs-logs-page__search-bar"></vs-logs-search-bar>
                </div>

                <div class="vs-logs-page__below-search-bar">
                    <vs-logs-significance-options class="vs-logs-page__significance-options"></vs-logs-significance-options>

                    <div
                        class="vs-logs-page__current-timeframe"
                    >
                        {{ vsLogsStore.currentTimeframe$ | async }}
                    </div>
                </div>

            </div>
        </avi-state-page_top-bar>

        <avi-state-page_main-content>
            <!-- Message Banners -->
            <!-- Is set up in following order of importance so only one is shown at a time: -->
            <!-- If loading, show loading until complete -->
            <!-- If both error and timeout, show error -->

            <!--  Loading Message -->
            <ng-container *ngrxLet="vsLogsStore.apiLoopsInProgressStatus$ as inProgressStatus">
                <avi-alert-group
                    alertType="loading"
                    class="vs-logs-page__alert-container"
                    *ngIf="vsLogsStore.isAnApiLoopInProgress$ | async"
                >
                    <span
                        class="vs-logs-page__alert-text"
                        *ngIf="inProgressStatus.isGraphApiLoopInProgress && inProgressStatus.isTableApiLoopInProgress"
                    >
                        {{ l10nKeys.loadingMessageLabel | vtranslate }}
                    </span>
                    <span
                        class="vs-logs-page__alert-text"
                        *ngIf="inProgressStatus.isGraphApiLoopInProgress && !inProgressStatus.isTableApiLoopInProgress"
                    >
                        {{ l10nKeys.graphLoadingMessageLabel | vtranslate }}
                    </span>
                    <span
                        class="vs-logs-page__alert-text"
                        *ngIf="!inProgressStatus.isGraphApiLoopInProgress && inProgressStatus.isTableApiLoopInProgress"
                    >
                        {{ l10nKeys.tableLoadingMessageLabel | vtranslate }}
                    </span>
                </avi-alert-group>
            </ng-container>


            <!--  Timeout Message -->
            <avi-alert-group
                alertType="warning"
                class="vs-logs-page__alert-container"
                [closable]="true"
                *ngIf="vsLogsStore.showTimeoutMessage$ | async"
                (onAlertClose)="hideTimeoutBanner()"
            >
                <span class="vs-logs-page__alert-text">{{ l10nKeys.timeoutMessageLabel | vtranslate }}</span>
                <cds-alert-actions>
                    <cds-button>
                        <a
                            [href]="timeoutsDocLink"
                            target="_blank"
                        >
                            {{ l10nKeys.learnMoreLink | vtranslate }}
                        </a>
                    </cds-button>
                    <cds-button (click)="vsLogsRefreshService.refresh()">
                        {{ l10nKeys.refreshLogsLabel | vtranslate }}
                    </cds-button>
                </cds-alert-actions>
            </avi-alert-group>

            <!--  Error Message  -->
            <avi-alert-group
                alertType="danger"
                class="vs-logs-page__alert-container"
                [closable]="true"
                *ngIf="vsLogsStore.showErrorMessage$ | async"
                (onAlertClose)="hideErrorBanner()"
            >
                <span class="vs-logs-page__alert-text">{{ l10nKeys.errMessageLabel | vtranslate }}</span>
                <cds-alert-actions>
                    <cds-button (click)="reloadPage()">
                        {{ l10nKeys.reloadPageLabel | vtranslate }}
                    </cds-button>
                </cds-alert-actions>
            </avi-alert-group>

            <!-- TODO: 164618 Delete when rollback removed -->
            <!--  Tech Preview Message  -->
            <avi-alert-group
                class="vs-logs-page__alert-container"
                *ngIf="showRevertPageBanner"
                alertType="warning"
                [closable]="true"
                (onAlertClose)="hideRevertPageBanner()"
            >
                <span class="vs-logs-page__alert-text">
                    {{ l10nKeys.legacyViewBannerDialog | vtranslate }}
                </span>
                <cds-alert-actions>
                    <cds-button>
                        <a
                            class="vs-logs-page__alert-link"
                            href="mailto:{{ feedbackEmailAddress }}" target="_blank"
                            title="{{ feedbackEmailAddress }}"
                        >
                            {{ l10nKeys.provideFeedbackLabel | vtranslate}}
                        </a>
                    </cds-button>
                </cds-alert-actions>
            </avi-alert-group>

            <div class="vs-logs-page__main-content">
                <vs-logs-graph></vs-logs-graph>

                <vs-logs-actions></vs-logs-actions>

                <vs-log-list></vs-log-list>
            </div>
        </avi-state-page_main-content>

        <avi-state-page_sidebar>
            <vs-logs-sidebar></vs-logs-sidebar>
        </avi-state-page_sidebar>
        <avi-state-page_sidebar-collapsed>
            <vs-logs-collapsed-sidebar></vs-logs-collapsed-sidebar>
        </avi-state-page_sidebar-collapsed>
    </avi-state-page>
</div>
