/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name logDetailsClientLocation
 * @param {logRecordConfig} record
 * @param {Function} onClick - Function passed by reference accepting only string param 'str'.
 * @description
 *
 *    Log entry might have up to two client locations fields. One is
 *    of string type - LogRecordConfig.client_location and another one can be populated for DNS
 *    VS - LogRecordConfig.dns_request.client_location of GeoLocation protobuf type.
 *
 */
const propName = 'client_location';

class LogDetailsClientLocation {
    $onInit() {
        this.propName = propName;
        this.isDNSType = this.getDNSRequestLocation() || false;
    }

    /**
     * Returns human readable location field.
     * @returns {string}
     * @public
     */
    getLocation() {
        return this.record[propName];
    }

    /**
     * Returns true if regular location is unknown.
     * @returns {boolean}
     * @public
     */
    locationIsUnknown() {
        return this.getLocation() === 'Unknown';
    }

    /**
     * Returns DNS request location object.
     * @returns {GeoLocation|null}
     * @public
     */
    getDNSRequestLocation() {
        const { dns_request: dnsRequest } = this.record;

        return dnsRequest && dnsRequest[propName] || null;
    }
}

angular.module('aviApp').component('logDetailsClientLocation', {
    bindings: {
        record: '<',
        onClick: '&',
    },
    controller: LogDetailsClientLocation,
    templateUrl:
            'src/components/common/logs/log-details/log-details-client-location/' +
            'log-details-client-location.html',
});
