<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid && !this.hasDuplicateVsIp"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <avi-alert-group
                    *ngIf="this.hasDuplicateVsIp"
                    alertType="danger"
                >
                    <span>{{ l10nKeys.vsIpAlreadyPresent | vtranslate }}</span>
                </avi-alert-group>
                
                <avi-checkbox
                    name="gslb-pool-member-enabled"
                    objectType="{{ objectType }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                >
                    {{ l10nKeys.enablePoolMemberCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        objectType="{{ objectType }}"
                        fieldName="description"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <!-- Either IP or VS configuration fields -->
                <gslb-pool-member-type
                    [gslbService]="gslbService"
                    [editable]="editable"
                    [(isConfiguredByTypeIp)]="isConfiguredByTypeIp"
                    (onVsSelectionChange)="checkSelectedVsIp($event)"
                ></gslb-pool-member-type>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="public_ip"
                        for="public-ip"
                    >
                        {{ l10nKeys.publicAddressInputLabel | vtranslate }}
                    </label>
                    <input
                        name="public-ip"
                        id="public-ip"
                        type="text"
                        clrInput
                        [readonly]="editable.config.fqdn"
                        regexPattern="anyIP"
                        placeholder="{{ l10nKeys.publicAddressInputPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.public_ip.config.ip.address"
                    />
                </avi-input-container>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="hostname"
                        for="hostname"
                    >
                        {{ l10nKeys.hostNameInputLabel | vtranslate }}
                    </label>
                    <input
                        name="hostname"
                        id="hostname"
                        type="text"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="hostname"
                        clrInput
                        placeholder="{{ l10nKeys.hostNameInputPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.hostname"
                    />
                </avi-input-container>

                <div
                    *ngIf="isConfiguredByTypeIp"
                    class="full-modal-config__form-control-row"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="cluster_uuid"
                            for="cluster-uuid"
                        >
                            {{ l10nKeys.thirdPartySiteClusterControllerDropdownLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="cluster-uuid"
                            id="cluster-uuid"
                            [options]="nonAviSitesDropdownOptions"
                            [(ngModel)]="editable.config.cluster_uuid"
                            placeholder="{{ l10nKeys.thirdPartySiteClusterControllerDropdownPlaceholder | vtranslate }}"
                        ></avi-dropdown>
                    </div>
                </div>

                <avi-input-container *ngIf="isGslbAlgorithmPreferenceOrder">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="preference_order"
                        for="preference-order"
                    >
                        {{ l10nKeys.preferenceOrderLabel | vtranslate }}
                    </label>
                    <input
                        name="preference-order"
                        id="preference-order"
                        type="number"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="preference_order"
                        clrInput
                        placeholder="{{ l10nKeys.preferenceOrderInputPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.preference_order"
                    />
                </avi-input-container>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="ratio"
                        for="ratio"
                    >
                        {{ globalL10nKeys.ratioLabel | vtranslate }}
                    </label>
                    <input
                        name="ratio"
                        id="ratio"
                        type="number"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="ratio"
                        clrInput
                        placeholder="{{ l10nKeys.ratioInputPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.ratio"
                    />
                </avi-input-container>

                <!-- Geo location section -->
                <gslb-service-geo-location
                    [gslbGeoLocation]="editable.config.location"
                    [isPoolMember]="true"
                ></gslb-service-geo-location>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
