/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import { VirtualService as VirtualServiceObjectType } from 'object-types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { naturalSort } from 'ng/shared/utils/natural-sort.utils';

export const VS_TYPE_VH_CHILD = 'VS_TYPE_VH_CHILD';
export const VS_TYPE_VH_PARENT = 'VS_TYPE_VH_PARENT';
export const VS_TYPE_NORMAL = 'VS_TYPE_NORMAL';
export const VS_TYPE_VH_ENHANCED = 'VS_TYPE_VH_ENHANCED';

const VS_TYPE_VH_SNI = 'VS_TYPE_VH_SNI';
const VH_MATCHES = 'vh_matches';

/**
 * Module for VirtualService and related stuff.
 * @module avi/vs
 */

function virtualServiceFactory(
    $q,
    ObjectTypeItem,
    RangeParser,
    Pool,
    PoolCollection,
    HTTPRedirectAction,
    PoolGroupCollection,
    HttpPolicySet,
    NetworkSecurityPolicy,
    VsVip,
    DnsPolicyContainerConfig,
    createItemByRef,
    DnsRecordConfig,
    Item,
    WafPolicyItem,
    ApplicationProfile,
    defaultValues,
    systemInfoService,
    VsVipCollection,
) {
    /**
     * @constructor
     * @memberOf module:avi/vs
     * @extends ObjectTypeItem
     * @author Ashish Verma
     */
    class VirtualService extends withEditChildMessageItemMixin(ObjectTypeItem) {
        constructor(args) {
            const extendedArgs = {
                ...args,
                objectName: 'virtualservice',
                objectType: VirtualServiceObjectType,
                permissionName: 'PERMISSION_VIRTUALSERVICE',
                whitelistedFields: [
                    VH_MATCHES,
                ],
            };

            super(extendedArgs);

            if (this.id) {
                const referredBy = `virtualservice:${this.id}`;

                this.pools = new PoolCollection({
                    objectName: 'pool-inventory',
                    isStatic: true,
                    params: {
                        referred_by: referredBy,
                    },
                });

                this.poolgroups = new PoolGroupCollection({
                    isStatic: true,
                    limit: 1000,
                    params: {
                        referred_by: referredBy,
                    },
                });
            }

            this.httpPolicySets = [new HttpPolicySet()];
            this.networkSecurityPolicy = new NetworkSecurityPolicy();

            if (this.getConfig()) {
                this.transformAfterLoad();
            }
        }

        /**
         * Prepare data to be used as the payload for the macro API.
         * @param dataToSave - Config data to save.
         * @returns Object to be used as the payload for the save request.
         * @override
         */
        // eslint-disable-next-line class-methods-use-this
        createSaveRequestPayload_(dataToSave) {
            const payload = {
                data: dataToSave,
                uri_path: dataToSave.uri_path,
            };

            delete payload.data.uri_path;

            return payload;
        }

        /**
         * Generic dataToSave method for all DnsPolicies properties.
         */
        dnsPoliciesDataToSave(config, propertyName) {
            if (config[propertyName] && config[propertyName].length > 0) {
                const policies = [];

                config[propertyName].forEach((dnsPolicyContainerConfig, i) => {
                    const index = 11 + i;

                    // set index if it's not set yet
                    if (!dnsPolicyContainerConfig.hasIndex()) {
                        dnsPolicyContainerConfig.setIndex(index);
                    }

                    // set name if it's not set yet
                    if (!dnsPolicyContainerConfig.getPolicyName()) {
                        let policyPrefix = '';

                        switch (propertyName) {
                            case 'dns_policies':
                                policyPrefix = 'DNSPolicy';
                                break;
                            case 'topology_policies':
                                policyPrefix = 'TopologyPolicy';
                                break;
                        }

                        const name =
                            `${this.getName()}-${policyPrefix}-${i}`;

                        dnsPolicyContainerConfig.setPolicyName(name);
                    }

                    const policiesObj = dnsPolicyContainerConfig.dataToSave();

                    policies.push(policiesObj);
                });

                config[propertyName] = policies;
            }
        }

        /**
         * Generic transformAfterLoad method for all DnsPolicies properties.
         */
        dnsPoliciesTransformAfterLoad(config, propertyName) {
            const { [propertyName]: policiesData } = config;

            if (policiesData) {
                if (Array.isArray(policiesData) && policiesData.length) {
                    config[propertyName] = policiesData.map(dnsPolicyObj => {
                        let policyContainer = dnsPolicyObj;

                        if (!(dnsPolicyObj instanceof DnsPolicyContainerConfig)) {
                            policyContainer = new DnsPolicyContainerConfig({
                                data: { config: dnsPolicyObj },
                            });
                        }

                        return policyContainer;
                    });
                }
            } else {
                config[propertyName] = [new DnsPolicyContainerConfig()];
            }
        }

        dataToSave() {
            const data = super.dataToSave();

            const refDataToDrop = [
                'application_profile_ref_data',
                'network_profile_ref_data',
                'server_network_profile_ref_data',
                'pool_group_ref_data',
                'ssl_profile_ref_data',
                'ssl_key_and_certificate_refs_data',
                'cloud_ref_data',
            ];

            refDataToDrop.forEach(propName => delete data[propName]);

            // basic create uses pool_ref_data to create Pool for the new VS
            if (this.id) {
                delete data['pool_ref_data'];
            }

            // Filter out services with no port number, removes layout parameters
            data.services = _.reject(data.services, function(service) {
                const portIsNotDefined = !service.port;

                if (!portIsNotDefined) {
                    service.port = +service.port;

                    delete service.nwOverride;
                    delete service.override_network_profile_ref_data;
                }

                return portIsNotDefined;
            });

            // If SSL is not enabled in any of the services (ports) and if the type is not
            // Child, need to remove ssl_profile_ref & ssl_key_and_certificate_refs.
            if (!this.sslEnabled() && data.type !== 'VS_TYPE_VH_CHILD') {
                delete data.ssl_profile_ref;

                if (data.type !== 'VS_TYPE_VH_CHILD') {
                    delete data.ssl_key_and_certificate_refs;
                }
            }

            // transform sample_uris
            if (data.analytics_policy && data.analytics_policy.client_insights_sampling) {
                const { client_insights_sampling: clientInsightsSampling } = data.analytics_policy;
                const { sample_uris: sampleUris } = clientInsightsSampling;

                if (sampleUris) {
                    sampleUris.string_group_refs = [];
                    sampleUris.match_str = [];

                    if (sampleUris.tmp.data) {
                        if (sampleUris.tmp.type == 'group') {
                            sampleUris.string_group_refs.push(sampleUris.tmp.data);
                        } else if (sampleUris.tmp.type == 'custom') {
                            _.each(sampleUris.tmp.data.split(','), function(item) {
                                sampleUris.match_str.push(item.trim());
                            });
                        }

                        delete sampleUris.tmp;
                    }

                    if (!sampleUris.string_group_refs.length && !sampleUris.match_str.length) {
                        delete clientInsightsSampling.sampleUris;
                    }
                }

                // transform skip_uris
                const { skip_uris: skipUris } = clientInsightsSampling;

                if (skipUris) {
                    skipUris.string_group_refs = [];
                    skipUris.match_str = [];

                    if (skipUris.tmp && skipUris.tmp.data) {
                        if (skipUris.tmp.type == 'group') {
                            skipUris.string_group_refs.push(skipUris.tmp.data);
                        } else if (skipUris.tmp.type == 'custom') {
                            _.each(skipUris.tmp.data.split(','), function(item) {
                                skipUris.match_str.push(item.trim());
                            });
                        }

                        delete skipUris.tmp;
                    }

                    if (!skipUris.string_group_refs.length && !skipUris.match_str.length) {
                        delete clientInsightsSampling.skip_uris;
                    }
                }

                // transform client_ip
                const { client_ip: clientIp } = clientInsightsSampling;

                if (clientIp) {
                    clientIp.match_criteria = 'IS_IN';
                    clientIp.group_refs = [];
                    clientIp.addrs = [];
                    clientIp.ranges = [];
                    clientIp.prefixes = [];

                    if (clientIp.tmp.data) {
                        if (clientIp.tmp.type == 'group') {
                            clientIp.group_refs.push(clientIp.tmp.data);
                        } else if (clientIp.tmp.type == 'custom') {
                            _.each(clientIp.tmp.data.split(','), function(item) {
                                const rangeOrIp = RangeParser.ipRange2Json(item.trim());

                                if (rangeOrIp) {
                                    if (rangeOrIp.begin) { // assume this is range
                                        clientIp.ranges.push(rangeOrIp);
                                        // mask can be 0
                                    } else if (rangeOrIp.mask !== undefined) { // assume prefix.
                                        clientIp.prefixes.push(rangeOrIp);
                                    } else { // otherwise ip
                                        clientIp.addrs.push(rangeOrIp);
                                    }
                                }
                            });
                        }

                        delete clientIp.tmp;
                    }

                    if (!clientIp.group_refs.length && !clientIp.addrs.length &&
                        !clientIp.ranges.length && !clientIp.prefixes.length) {
                        delete clientInsightsSampling.clientIp;
                    }
                }
            }

            //QoS block, no Limit for DOS attacks when no limit was ser for max
            //connections per clientIP
            if (!data.max_cps_per_client) {
                data.limit_doser = false;
            }

            const appType = this.appType();

            if (this.httpPolicySets.length > 0 && appType !== 'dns') {
                const policySets = [];

                this.httpPolicySets.forEach((policySet, i) => {
                    let index = 11 + i;

                    if (Array.isArray(data.http_policies)) {
                        const prevConfig = data.http_policies[i];

                        if ('index' in prevConfig) {
                            index = prevConfig.index;
                        }
                    }

                    const httpPolicySetData = angular.copy(policySet.dataToSave());

                    if (httpPolicySetData) {
                        const policy = {
                            http_policy_set_ref_data: {},
                            index,
                        };

                        if (!httpPolicySetData.name) {
                            policy.http_policy_set_ref_data.name =
                                `${this.getName()}-HTTPPolicySet-${i}`;
                        }

                        angular.extend(policy.http_policy_set_ref_data, httpPolicySetData);
                        policySets.push(policy);
                    }
                });

                data.http_policies = policySets;
            }

            const networkSecurityPolicyData = this.networkSecurityPolicy.dataToSave();

            if (networkSecurityPolicyData) {
                let policyConfigData = data.network_security_policy_ref_data;

                if (!policyConfigData) {
                    data.network_security_policy_ref_data = {};
                    policyConfigData = data.network_security_policy_ref_data;
                }

                //creation
                if (!networkSecurityPolicyData.name) {
                    policyConfigData.name = `${this.getName()}-NetworkSecurityPolicy`;
                }

                angular.extend(policyConfigData, networkSecurityPolicyData);
            }

            if (this.isDNS()) {
                this.dnsPoliciesPropNames.forEach(propName => {
                    this.dnsPoliciesDataToSave(data, propName);
                });
            } else {
                this.dnsPoliciesPropNames.forEach(propName => {
                    delete data[propName];
                });
            }

            /**
             * If the config has 'vsvip_ref',
             * that means this VS has vsvip modal2.0 / sharing a VsVip object.
             * so 'vsvip_ref_data' needs to be deleted or a new VsVip will be created.
             */
            if (!isUndefined(data.vsvip_ref)) {
                delete data.vsvip_ref_data;
            } else {
                data.vsvip_ref_data.setName(`${this.getName()}-VsVip`);
                data.vsvip_ref_data = data.vsvip_ref_data.dataToSave();
            }

            delete data.vip;
            delete data.dns_info;
            delete data.fqdn;
            delete data.east_west_placement;

            // virtualservice of type VH_APP shouldn't have ip_address
            if (data.type === 'VS_TYPE_VH_CHILD') {
                delete data.vsvip_ref_data;
                delete data.services;
            } else {
                delete data.vh_domain_name;
                delete data.vh_parent_vs_ref;
            }

            //removes l7 rate limiter for all types of VS but http
            if (data.requests_rate_limit && !this.isHTTP()) {
                data.requests_rate_limit = undefined;
            }

            //removes invalid null from optional field 'period' when 'count' is 0
            if (data.connections_rate_limit && !data.connections_rate_limit.count &&
                data.connections_rate_limit.period === null) {
                data.connections_rate_limit.period = undefined;
            }

            if (data.requests_rate_limit) {
                if (!data.requests_rate_limit.count && data.requests_rate_limit.period === null) {
                    data.requests_rate_limit.period = undefined;
                }

                if (data.requests_rate_limit.action && data.requests_rate_limit.action.redirect &&
                    data.requests_rate_limit.action.type === 'RL_ACTION_REDIRECT') {
                    HTTPRedirectAction.beforeSave(data.requests_rate_limit.action.redirect);
                }
            }

            if (this.pool && this.pool.autoPopulatedServers()) {
                delete data.pool_ref_data.servers;
            }

            if (this.getDefaultNetProfileType() === 'PROTOCOL_TYPE_TCP_FAST_PATH') {
                delete data['server_network_profile_ref'];
            }

            if (appType !== 'dns') {
                delete data['static_dns_records'];
                delete data['dns_policies'];
            } else if (angular.isArray(data.static_dns_records)) {
                data.static_dns_records = Item.filterRepeatedInstances(data.static_dns_records);
            }

            if (appType === 'l4') {
                delete data.http_policies;
            }

            if (!this.isHTTP()) {
                delete data['sso_policy_ref'];
            }

            if (!this.hasDataScriptSupport()) {
                delete data.vs_datascripts;
            }

            let url = '/api/virtualservice';

            if (this.id) {
                url += `/${this.id}`;
            }

            if (data.sharedVipVS) {
                delete data.sharedVipVS;
            }

            const { markers } = data;

            if (markers) {
                // filter out RBAC entries with an empty key
                data.markers = markers.filter(({ key }) => key);

                // delete empty RABC label list
                if (markers.length === 0) {
                    delete data.markers;
                }
            }

            return {
                uri_path: url,
                ...data,
            };
        }

        urlToSave() {
            return '/api/macro';
        }

        /**
         * @override
         */
        beforeEdit() {
            const
                data = this.getConfig(),
                promises = [];

            if (!('analytics_policy' in data)) {
                data.analytics_policy = this.getDefaultConfig_()['analytics_policy'];
            }

            const { isEnterpriseOrEnterpriseWithCloudServicesTier } = systemInfoService;

            if (!this.id && isEnterpriseOrEnterpriseWithCloudServicesTier) {
                VirtualService.overrideAnalyticsPolicyDefaults_(data.analytics_policy);
            }

            // compile sample_uris in client_insights
            if (data.analytics_policy.client_insights_sampling) {
                const { client_insights_sampling: clientInsightsSampling } = data.analytics_policy;
                const { sample_uris: sampleUris } = clientInsightsSampling;

                if (sampleUris) {
                    if (sampleUris.string_group_refs && sampleUris.string_group_refs.length) {
                        sampleUris.tmp = {
                            type: 'group',
                            data: sampleUris.string_group_refs[0],
                        };
                    } else {
                        sampleUris.tmp = {
                            type: 'custom',
                            data: sampleUris.match_str ? sampleUris.match_str.join(', ') : '',
                        };
                    }
                }

                const { skip_uris: skipUris } = clientInsightsSampling;

                if (skipUris) {
                    if (skipUris.string_group_refs && skipUris.string_group_refs.length) {
                        skipUris.tmp = {
                            type: 'group',
                            data: skipUris.string_group_refs[0],
                        };
                    } else {
                        skipUris.tmp = {
                            type: 'custom',
                            data: skipUris.match_str ? skipUris.match_str.join(', ') : '',
                        };
                    }
                }

                const { client_ip: clientIp } = clientInsightsSampling;

                if (clientIp) {
                    if (clientIp.group_refs && clientIp.group_refs.length) {
                        clientIp.tmp = {
                            type: 'group',
                            data: clientIp.group_refs[0],
                        };
                    } else {
                        clientIp.tmp = {
                            type: 'custom',
                            data: _.map(clientIp.addrs, function(o) { return o.addr; })
                                .concat(_.map(clientIp.ranges, function(o) {
                                    return `${o.begin.addr}-${o.end.addr}`;
                                }))
                                .concat(_.map(clientIp.prefixes, function(o) {
                                    return `${o.ip_addr.addr}/${o.mask}`;
                                }))
                                .join(', '),
                        };
                    }
                }
            } else {
                data.analytics_policy.client_insights_sampling = {};
            }

            // init datascripts
            if (!data.vs_datascripts) {
                data.vs_datascripts = [];
            }

            //Untockenize the host and path fields for the redirect action
            if (data.requests_rate_limit) {
                if (data.requests_rate_limit.action && data.requests_rate_limit.action.redirect &&
                    data.requests_rate_limit.action.type === 'RL_ACTION_REDIRECT') {
                    HTTPRedirectAction.beforeEdit(data.requests_rate_limit.action.redirect);
                }
            }

            if ('services' in data) {
                data.services.forEach(service => {
                    // plainly UI property for the form checkbox "NW profile override"
                    service.nwOverride = !!service.override_network_profile_ref;

                    if (!('port_range_end' in service)) {
                        service.port_range_end = service.port;
                    }

                    // we assume hasXXXNetProfile method is not used within edit mode
                    delete service.override_network_profile_ref_data;
                });
            } else {
                data.services = [];
            }

            data.vsvip_ref_data.beforeEdit();

            if (angular.isArray(data.static_dns_records)) {
                data.static_dns_records.forEach(dnsRecord => dnsRecord.beforeEdit());
            }

            if (this.isVHChild()) {
                this.storeParentVsServicePorts_();
            }

            $q.all(promises)
                .finally(this.setPristine.bind(this));
        }

        /**
         * Returns true for HTTP(S) VSes only.
         * @returns {boolean}
         * @public
         */
        isHTTP() {
            return this.appType() === 'http';
        }

        /**
         * Returns true for HTTPS VSes. Requires appProfile data loaded.
         * @returns {boolean}
         */
        isHTTPS() {
            if (this.isHTTP()) {
                const { http_profile: httpProfile } = this.getAppProfileConfig();

                return ApplicationProfile
                    .getHttpProfileSslEverywhereEnabledValue(httpProfile);
            }

            return false;
        }

        /**
         * Returns true for VS having DNS application profile.
         * @returns {boolean}
         * @public
         */
        isDNS() {
            return this.appType() === 'dns';
        }

        /**
         * Determines what kind of VS is it. Usually returns meaningful part of application profile
         * type, but for app types 'ssl' and 'l4' returns part of the main network profile type.
         * @returns {string} - Empty string if not set/loaded.
         * @public
         */
        appType() {
            //provided by inventory API only
            const
                { app_profile_type: appProfileTypeFromInventory } = this.data,
                appProfile = this.getAppProfileConfig(),
                appProfileType = appProfile ? appProfile['type'] : appProfileTypeFromInventory;

            if (!appProfileType) {
                return '';
            }

            return appProfileType.slice('APPLICATION_PROFILE_TYPE_'.length).toLowerCase();
        }

        /**
         * Informs if icap logs are present.
         * @returns {boolean}
         */
        hasIcapProfile() {
            return Boolean(this.getConfig().icap_request_profile_refs?.length);
        }

        /**
         * Informs if bot policy logs are present.
         * @returns {boolean}
         */
        hasBotPolicy() {
            return Boolean(this.getConfig().bot_policy_ref);
        }

        /**
         * Returns true if dataScripts can be attached to a virtual service.
         * @return {boolean}
         */
        hasDataScriptSupport() {
            const appProfileType = this.appType();

            return appProfileType !== 'sip';
        }

        /**
         * Returns true for VSes with SSL app profile type or HTTP VS with at least one service
         * using SSL.
         * @returns {boolean}
         * @public
         */
        //FIXME doesn't work for VS_TYPE_VH_CHILD VSes since they don't have services list
        sslEnabled() {
            const config = this.getConfig();

            if (config) {
                if (this.appType() === 'ssl') {
                    return true;
                } else if (this.isHTTP()) {
                    return _.some(config.services, service => service['enable_ssl']);
                }
            }

            return false;
        }

        policyEnabled() {
            return _.some(this.httpPolicySets, policySet => policySet.hasRules());
        }

        /**
         * Ignores response argument and tangles with Item.data.config.
         * @override
         */
        transformAfterLoad() {
            this.transformAfterLoad_(this.getConfig());
        }

        /**
         * Updates passed object as expected and returns it back.
         * @params {Item.data.config}
         * @returns {Item.data.config}
         * @protected
         */
        transformAfterLoad_(config) {
            if (!('services' in config)) {
                config['services'] = [];
            }

            const { http_policies: httpPolicies } = config;

            if (Array.isArray(httpPolicies)) {
                this.httpPolicySets = httpPolicies.map(httpPolicy => {
                    const { http_policy_set_ref_data: httpPolicyData } = httpPolicy;
                    const httpPolicySet = new HttpPolicySet();

                    httpPolicySet.updateItemData(
                        angular.copy({
                            config: httpPolicyData,
                        }),
                    );

                    return httpPolicySet;
                });
            }

            const { network_security_policy_ref_data: netSecurityPolicyData } = config;

            if (netSecurityPolicyData) {
                this.networkSecurityPolicy.updateItemData(
                    angular.copy({ config: netSecurityPolicyData }),
                );
            }

            this.dnsPoliciesPropNames.forEach(propName => {
                this.dnsPoliciesTransformAfterLoad(config, propName);
            });

            const { static_dns_records: dnsRecords } = config;

            if (dnsRecords) {
                config['static_dns_records'] = dnsRecords.map(dnsRecord => {
                    if (dnsRecord instanceof DnsRecordConfig) {
                        return dnsRecord;
                    }

                    return new DnsRecordConfig({ data: { config: dnsRecord } });
                });
            }

            if (!(config['vsvip_ref_data'] instanceof VsVip)) {
                const vsVipCollection = new VsVipCollection();
                const vsVipConfig = config['vsvip_ref_data'];
                let vsVipArgs;

                if (vsVipConfig) {
                    vsVipArgs = {
                        data: {
                            config: vsVipConfig,
                        },
                    };
                }

                config['vsvip_ref_data'] = vsVipCollection.createNewItem(vsVipArgs, true);

                vsVipCollection.destroy();
            }

            return config;
        }

        saveRequest() {
            return super.saveRequest()
                .then(({ data }) => {
                    const action = this.id ? 'edit' : 'create';

                    if (action === 'create') {
                        //get id from the last object if it's missing
                        this.id = data.slice(-1)[0].uuid;
                    }

                    return this.load()
                        .then(([configRsp]) => {
                            if (action === 'create') {
                                this.id = null;
                            }

                            return configRsp;
                        });
                });
        }

        /**
         * Makes request to get the lists of candidate service engines and hosts.
         * @param  {string} vipId - vip_id of the VIP to scale out.
         * @return {Object} Object containing service engines and hosts.
         */
        getCandidateServiceEnginesAndHosts(vipId) {
            const api =
                // eslint-disable-next-line max-len
                `/api/${this.objectName}/${this.id}/candidatesehostlist?include_name&vip_id=${vipId}`;

            return this.request('GET', api)
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length) {
                        return data[0];
                    }

                    return {};
                });
        }

        /**
         * Makes a request to scale out the VIP address.
         * @param  {Object} payload - Contains the params for scaling out the VIP.
         * @return {ng.$q.promise}
         */
        scaleOut(payload) {
            return this.request('POST', `/api/virtualservice/${this.id}/scaleout`, payload);
        }

        /**
         * Makes a request to scale in the VIP address.
         * @param  {Object} payload - Contains the params for scaling in the VIP.
         * @return {ng.$q.promise}
         */
        scaleIn(payload) {
            return this.request('POST', `/api/virtualservice/${this.id}/scalein`, payload);
        }

        /**
         * Makes a request to migrate the VIP address.
         * @param  {Object} payload - Contains the params for migrating the VIP.
         * @return {ng.$q.promise}
         */
        migrate(payload) {
            return this.request('POST', `/api/virtualservice/${this.id}/migrate`, payload);
        }

        /**
         * Deletes children associated with VS being deleted.
         * @param  {string[]} refs - Array of reference URLs used for deletion.
         * @return {ng.$q.promise}
         * @protected
         */
        dropChildren_(refs) {
            const deleteRequests = refs.map(ref => this.request('delete', ref));

            return $q.all(deleteRequests).catch(({ data: errors }) => console.error(errors));
        }

        /** @override */
        drop(force, params) {
            let promise = super.drop(force, params);

            if (angular.isObject(params) && params.withChildren) {
                const deletionGroups = [];
                const { poolgroups, pools } = this.data;
                const { vsvip_ref: vsVipRef } = this.getConfig();

                if (vsVipRef) {
                    deletionGroups.push([vsVipRef]);
                }

                if (angular.isArray(poolgroups) && poolgroups.length) {
                    deletionGroups.push(poolgroups);
                }

                if (angular.isArray(pools) && pools.length) {
                    deletionGroups.push(pools);
                }

                deletionGroups.forEach(childRefs => {
                    promise = promise.then(() => this.dropChildren_(childRefs));
                });
            }

            return promise;
        }

        /**
         * This function emulates a call to the server and giving the promise
         * It is making multiple calls periodically to continuously update the object
         * that was delivered into resolve
         *
         * @param fields - Array of fields that has to be loaded
         * @return {*} - Promise
         */
        loadRequest(aFields) {
            return $q.all([
                this.loadConfig(aFields),
                this.loadEventsAndAlerts(aFields),
                this.loadMetrics(aFields),
            ]);
        }

        /**
         * Transforming the response of loadConfig right away, not waiting for loadRequest to go
         * through.
         * @override
         **/
        onConfigLoad_(rsp) {
            const res = super.onConfigLoad_(rsp);

            this.transformAfterLoad();

            return res;
        }

        /**
         * Checks if this VS has specific Network Security Policy rules called 'secure application'.
         * @param {boolean=} isEnabled - When true we should check verify rules to be enabled.
         * @param {boolean=} canBePartial - When true we will return true even if only one of two
         *     rules has been found.
         * @returns {boolean} - True if rules (or rule when canBePartial is true) with
         *     certain {@link NetworkSecurityPolicy._secAppRuleIndexes index values and properties}
         *     are present for this VS.
         */
        hasSecureAppRule(isEnabled, canBePartial) {
            return this.networkSecurityPolicy.hasSecureAppRule(isEnabled, canBePartial);
        }

        /**
         * Checks whether we have network security rules other then SecureApp created through UI
         * Modal window with certain indexes and property values.
         * @returns {boolean} True when we have other rules.
         */
        hasRuleBesidesSecureApp() {
            return this.networkSecurityPolicy.hasRuleBesidesSecureApp();
        }

        /**
         * Removes specific Network Security Policy rules called 'secure app'. Before removal will
         * check if at least one of them is present for this VS. Deletes the referenced microservice
         * group also (if it is not used anywhere else in a database).
         * @returns {ng.$q.promise}
         */
        removeSecureAppRule() {
            const msGroupUuid = this.networkSecurityPolicy.removeSecureAppRule();
            let promise;

            if (msGroupUuid) {
                promise = this.save();

                //no need to return promise - we don't care about delete failure
                promise.then(() => {
                    this.request('DELETE', `/api/microservicegroup/${msGroupUuid.slug()}`);
                });
            } else {
                promise = $q.reject('Can\'t remove the Secure App rules which VS doesn\'t have');
            }

            return promise;
        }

        /**
         * Sets and saves an enabled state of VS.
         * @param  {boolean} enabled - Value to set for 'enabled' property.
         * @return {ng.$q.promise}
         */
        setEnabledState(enabled) {
            return this.patch({ replace: { enabled } });
        }

        /**
         * VS uses macro API to save, let's use direct VS api for patch requests.
         * @override
         **/
        patchRequest_(payload) {
            return this.request(
                'patch',
                `/api/virtualservice/${this.id}?include_name`,
                payload,
                null,
                'patch',
            );
        }

        /**
         * Replaces all existent services with the only one of default configuration.
         * @param {number=} port - Service port. If not passed default for the application profile
         *     will be selected.
         */
        setDefaultService(port = this.getDefaultServicePort()) {
            const { services } = this.getConfig();

            services.length = 0;

            services.push(
                VirtualService.getDefaultServiceConfig({ port }),
            );
        }

        /**
         * Returns default service port for this VS. It depends on application profile type.
         * @returns {number}
         */
        getDefaultServicePort() {
            const appProfileType = this.appType();

            //HTTPS and HTTP application profiles have the same type of HTTP
            if (this.isHTTPS()) {
                return 443;
            } else {
                return ApplicationProfile.getDefaultServicePort(appProfileType);
            }
        }

        /**
         * Returns the vip_summary of the Virtual Service where a scaling/migrating process
         * is occuring.
         * @return {Object} - Virtual Service vip_summary object.
         */
        getActiveVipSummary() {
            const runtime = this.getRuntimeData();

            if (angular.isUndefined(runtime)) {
                return;
            }

            return _.find(runtime.vip_summary, summary => {
                return summary.scaleout_in_progress || summary.scalein_in_progress ||
                    summary.migrate_in_progress;
            });
        }

        /**
         * Returns the status of the Virtual Service runtime.
         * @return {string|undefined}
         */
        getRuntimeStatus() {
            let status;
            const activeVipSummary = this.getActiveVipSummary();

            if (angular.isUndefined(activeVipSummary)) {
                return;
            }

            if (activeVipSummary.scaleout_in_progress) {
                return status = 'SCALE_OUT';
            } else if (activeVipSummary.scalein_in_progress) {
                return status = 'SCALE_IN';
            } else if (activeVipSummary.migrate_in_progress) {
                return status = 'MIGRATE';
            }

            return status;
        }

        /**
         * Returns true if any policies or datascripts are present in the Virtual Service.
         * @return {boolean}
         */
        hasPolicies() {
            const config = this.getConfig();

            if (!config) {
                return false;
            }

            if (angular.isArray(config.vs_datascripts) && config.vs_datascripts.length) {
                return true;
            }

            return this.policyEnabled() || this.networkSecurityPolicy.hasRules();
        }

        /**
         * Goes through all VIPs and returns a list of IP addresses VS is configured with. Undefined
         * when not ready (for one-time bindings).
         * @param {string=} format - Only `floatOrReg`, `allV4`, 'allIPs' and `hash`
         *  (default) are supported.
         * @returns {IpAddr.addr[]|Object[]}
         * @public
         */
        getIPAddresses(format = 'hash') {
            const
                vsVip = this.getVSVip(),
                hashes = vsVip.getVipAddressHashes();

            switch (format) {
                case 'floatOrReg':
                    return hashes.map(
                        ({ floating_ip: floatingIp, ip_address: ip }) => (floatingIp || ip)['addr'],
                    );

                //flat list of floating and regular IPs across all VIPs
                case 'allV4':
                    return hashes.reduce(
                        (acc, { floating_ip: floatingIp, ip_address: ip }) => {
                            if (floatingIp?.addr) {
                                acc.push(floatingIp.addr);
                            }

                            if (ip?.addr) {
                                acc.push(ip.addr);
                            }

                            return acc;
                        }, [],
                    );

                //flat list of floating and allIPs across all VIPs
                case 'allIPs': {
                    const allIPs = hashes.reduce(
                        (acc, {
                            floating_ip: floatingIp,
                            ip_address: ip,
                            ip6_address: ip6,
                        }) => {
                            if (floatingIp?.addr) {
                                acc.push(floatingIp.addr);
                            }

                            if (ip?.addr) {
                                acc.push(ip.addr);
                            }

                            if (ip6?.addr) {
                                acc.push(ip6.addr);
                            }

                            return acc;
                        }, [],
                    );

                    return this.sortIps_(allIPs);
                }

                default:
                    return hashes;
            }
        }

        /**
         * Function to sort ip list using natural sort.
         * @param {Object[]} ipList - list of ip's.
         * @return {Object[]}
         * @protected
         */
        sortIps_(ipList) {
            return ipList.sort((ipaddressA, ipaddressB) => naturalSort(ipaddressA, ipaddressB));
        }

        /**
         * Returns the DnsInfo object from VsVip based on the index. Index defaults to 0.
         * @param {number=} index - Index of the DnsInfo object from the dns_info list.
         * @param {Object} DnsInfo object.
         */
        getDnsInfo(index = 0) {
            return this.getVSVip().getDnsInfo(index);
        }

        /**
         * Returns the Vip object from VsVip based on the index. Index defaults to 0.
         * @param {number=} index - Index of the Vip object from the vip list.
         * @return {Object} Vip object.
         */
        getVip(index = 0) {
            return this.getVSVip().getVip(index);
        }

        /**
         * Returns true if VirtualService or its ConfigItems are busy.
         * @return {boolean}
         */
        isBusy() {
            const config = this.getConfig();

            return this.busy || this.pool && this.pool.busy || config && this.getVSVip().isBusy();
        }

        /**
         * Returns the cloud_ref configured on the VirtualService.
         * @return {string}
         */
        getCloudRef() {
            return this.getConfig()['cloud_ref'];
        }

        /**
         * Returns the VRF context ref configured for this VS.
         * @returns {string}
         */
        getVRFContextRef() {
            return this.getConfig()['vrf_context_ref'] || '';
        }

        /**
         * Returns an application profile configuration of this VS.
         * @returns {ApplicationProfileConfig}
         * @public
         */
        getAppProfileConfig() {
            return this.getConfig()['application_profile_ref_data'];
        }

        /**
         * Returns a network profile configuration of this VS.
         * @returns {NetworkProfileConfig}
         * @public
         */
        getNetProfileConfig() {
            return this.getConfig()['network_profile_ref_data'];
        }

        /**
         * Loads network profile by 'network_profile_ref' and puts it into
         * 'network_profile_ref_data` of VS config.
         * @returns {ng.$q.promise}
         * @public
         */
        loadNetProfileData() {
            return this.loadConfigRefData_('network_profile_ref');
        }

        /**
         * Loads application profile by 'application_profile_ref' and puts it into
         * 'application_profile_ref_data` of VS config.
         * @returns {ng.$q.promise}
         * @public
         */
        loadAppProfileData() {
            return this.loadConfigRefData_('application_profile_ref');
        }

        /**
         * Sets application profile ref and data with values passed. Also executes certain
         * transformations after these two got updated. Transformations might require loading of
         * extra data, that's why promise is being returned. Not called on init.
         * @param {Item#data#config#url} ref
         * @param {ApplicationProfile#data#config} appProfileConfig - When not passed will
         *     make an API call to load it.
         * @returns {ng.$q.promise} - Rejects only if {@link VirtualService.onAppProfileChange_}
         *     fails or application profile data fails to load.
         */
        setAppProfile(ref, appProfileConfig = null) {
            const
                config = this.getConfig(),
                newId = ref.slug(),
                id = config['application_profile_ref'] ?
                    config['application_profile_ref'].slug() :
                    '',
                prevAppProfileType = this.appType();

            //comparing uuids instead of refs
            if (id === newId) {
                if (!config['application_profile_ref_data'] && appProfileConfig) {
                    config['application_profile_ref_data'] = appProfileConfig;
                }

                return $q.when(true);
            }

            config['application_profile_ref'] = ref;

            if (appProfileConfig) {
                config['application_profile_ref_data'] = appProfileConfig;

                return this.onAppProfileChange_(prevAppProfileType);
            } else {
                return this.loadAppProfileData()
                    .then(() => this.onAppProfileChange_(prevAppProfileType));
            }
        }

        /**
         * Takes care of changing certain properties on application profile change event.
         * Such changes are quite disruptive, hence this is NOT an init or beforeEdit. If
         * newly selected application profile type equals to the previously selected profile
         * type (or previously selected profile type is not passed) this method doesn't do a thing.
         * @param {string} prevAppProfileType - App profile type we had before the recent change.
         * @returns {ng.$q.promise} - Rejected only if previous profile wasn't passed ot current
         *     profile data is not available.
         * @protected
         */
        onAppProfileChange_(prevAppProfileType) {
            const appProfileType = this.appType();

            if (!appProfileType) {
                return $q.reject('current application profile data is not available');
            }

            if (!prevAppProfileType) {
                return $q.reject('no transformation is made since prev profile type is not passed');
            }

            // when switching within one app profile type we don't want to transform anything
            if (appProfileType === prevAppProfileType) {
                return $q.when('no application profile type change');
            }

            const
                config = this.getConfig(),
                defaultNetProfileRef = ApplicationProfile.getDefaultNetProfileRef(appProfileType);

            let promise = $q.when(true);

            //setting default network profile for the newly selected application profile
            if (!config['network_profile_ref'] ||
                defaultNetProfileRef.slug() !== config['network_profile_ref'].slug()) {
                config['network_profile_ref'] = defaultNetProfileRef;
                promise = this.loadNetProfileData();
            }

            //virtual hosting is supported by HTTP type only, hence not carried over
            config['type'] = 'VS_TYPE_NORMAL';

            //setting defaults for creation only
            if (!this.id && (appProfileType === 'ssl' || this.isHTTPS())) {
                //TODO need public method to set these
                config['ssl_key_and_certificate_refs'] = [
                    defaultValues.getSystemObjectRefByName(
                        'sslkeyandcertificate',
                        'System-Default-Cert',
                    ),
                ];

                config['ssl_profile_ref'] =
                    defaultValues.getSystemObjectRefByName(
                        'sslprofile',
                        'System-Standard',
                    );
            }

            const noSSL = appProfileType !== 'ssl' && appProfileType !== 'http';

            //dropping these on edit as well cause they aren't compatible
            if (noSSL) {
                config['ssl_key_and_certificate_refs'] = [];
                config['ssl_profile_ref'] = undefined;
            }

            //dropping waf policy & ICAP profile for application profiles other than http
            if (appProfileType !== 'http') {
                delete config['waf_policy_ref'];
                delete config['icap_request_profile_refs'];
                delete config['csrf_policy_ref'];
            }

            //TODO drop irrelevant policies
            const analyticsPolicy = this.getAnalyticsPolicy();

            // leaving bare minimum for analytics policy
            // TODO can use defaults here (with beforeEdit transformations)
            if (analyticsPolicy) {
                if ('client_log_filters' in analyticsPolicy) {
                    analyticsPolicy['client_log_filters'].length = 0;
                }

                analyticsPolicy['client_insights'] = 'NO_INSIGHTS';
                //TODO take care of client_insights_sampling
            }

            //creation only
            if (!this.id) {
                this.setDefaultService();
            } else if (noSSL) {
                this.setServicesSSL(false);
            }

            if (!this.isHTTP()) {
                delete config['sso_policy_ref'];
                config.services.forEach(service => service.enable_http2 = false);
            }

            return promise;
        }

        /**
         * Returns stripped names of all network profile types used by this VS.
         * @returns {{ShortAppProfileType: true}}
         * @public
         */
        getNetProfileTypesHash() {
            const
                typesHash = {},
                { services } = this.getConfig(),
                sliceStartPos = 'PROTOCOL_TYPE_'.length;

            typesHash[
                this.getDefaultNetProfileType().slice(sliceStartPos)
            ] = true;

            if (services) {
                services.forEach(
                    ({ override_network_profile_ref_data: netProfileConfig }) => {
                        if (netProfileConfig) {
                            typesHash[
                                netProfileConfig['profile']['type'].slice(sliceStartPos)
                            ] = true;
                        }
                    },
                );
            }

            return typesHash;
        }

        /**
         * Returns `main` network profile used by this VS. Services might use network profile
         * override feature. Has nothing to do with "default" values.
         * @returns {string} - ProtocolType enum value
         */
        getDefaultNetProfileType() {
            const netProfileConfig = this.getNetProfileConfig();

            if (!netProfileConfig) {
                return '';
            }

            const { profile } = netProfileConfig;

            // read profile.type right after loading
            // read profile.getConfig().type when profile has been changed and ConfigItem kicked in
            return profile.type || profile.getConfig().type;
        }

        /**
         * Checks whether VS is using passed network profile type.
         * @param {ShortAppProfileType} type
         * @param {boolean?} only - When passed `type` should be the only netProfileType used by VS
         *     to return true.
         * @returns {boolean}
         * @public
         */
        hasNetProfileType(type, only) {
            const typesHash = this.getNetProfileTypesHash();

            if (!(type in typesHash)) {
                return false;
            }

            return !only || Object.keys(typesHash).length === 1;
        }

        /**
         * Returns true if VS is using "TCP proxy" network profile type.
         * @param {boolean?} only - When passed has to be only profile used by VS to return true.
         * @returns {boolean}
         * @public
         */
        hasTCPProxyNetProfile(only) {
            return this.hasNetProfileType('TCP_PROXY', only);
        }

        /**
         * Returns true if VS has TCPFastPath network profile (and only it when `only` param has
         * been passed).
         * @param {boolean?} only
         * @returns {boolean}
         * @public
         */
        hasTCPFastPathNetProfile(only) {
            return this.hasNetProfileType('TCP_FAST_PATH', only);
        }

        /**
         * Returns true if VS is using "UDP fast path" network profile type.
         * @returns {boolean}
         * @public
         */
        hasUDPNetProfile() {
            return this.hasNetProfileType('UDP_FAST_PATH');
        }

        /**
         * Loads "_data" of a "_ref" config property and puts it into VS config.
         * @param {string} refPropertyName - Top level config property name.
         * @returns {ng.$q.promise} - To be resolved with a loaded Item config.
         * @protected
         */
        //TODO make sure only one at a time
        //FIXME setting .busy this way doesn't work anymore
        loadConfigRefData_(refPropertyName) {
            const ref = this.getConfig()[refPropertyName];

            let errMsg = '';

            if (ref) {
                const item = createItemByRef(ref);

                this.busy = true;

                return item.load()
                    .then(() => this.getConfig()[`${refPropertyName}_data`] = item.getConfig())
                    .catch(({ data }) => {
                        this.errors = data;

                        return $q.reject(data);
                    })
                    .finally(() => {
                        item.destroy();
                        this.busy = false;
                    });
            } else {
                errMsg = `${refPropertyName} is not set on VS config`;
            }

            console.error(errMsg);

            return $q.reject(errMsg);
        }

        /**
         * Function to check whether VS is referencing a pool or pool group.
         * @returns {boolean}
         * @public
         */
        hasPoolRef() {
            const { pool_ref: poolRef, pool_group_ref: poolGroupRef } = this.getConfig();

            return !!(poolRef || poolGroupRef);
        }

        /**
         * Returns default VS.Service (protobuf message Service) config extended by passed config.
         * @param {VSServiceConfig=} config
         * @returns {VSServiceConfig}
         */
        static getDefaultServiceConfig(config = null) {
            if (config) {
                //set SSL on for 443
                if (config['port'] === 443 && !('enable_ssl' in config)) {
                    config['enable_ssl'] = true;
                }

                //set port_range_end to port by default
                if (!('port_range_end' in config) && 'port' in config) {
                    config['port_range_end'] = config['port'];
                }
            }

            return angular.extend({
                port: 80,
                port_range_end: 80,
                enable_ssl: false,
                nwOverride: false,
            }, config);
        }

        /** @override */
        getDetailsPageStateParams_() {
            return { vsId: this.id };
        }

        /**
         * Adds a DNS Record to config.static_dns_records.
         * @param {DnsRecordConfig} record - DnsRecordConfig Config Item.
         */
        addDnsRecord(record) {
            const config = this.getConfig();

            config.static_dns_records = config.static_dns_records || [];
            config.static_dns_records.push(record);
        }

        /**
         * Replaces an existing DnsRecordConfig with a new one, used when editing a DnsRecordConfig.
         * @param {DnsRecordConfig} oldDnsRecord - DnsRecordConfig to be replaced.
         * @param {DnsRecordConfig} newDnsRecord - Modified DnsRecordConfig.
         */
        replaceDnsRecord(oldDnsRecord, newDnsRecord) {
            const index = this.getConfig().static_dns_records.indexOf(oldDnsRecord);
            const { static_dns_records: dnsRecords } = this.getConfig();

            dnsRecords[index] = newDnsRecord;
        }

        /**
         * Removes an existing DnsRecordConfig from config.static_dns_records.
         * @param {DnsRecordConfig} dnsRecord - Record to be removed.
         */
        removeDnsRecord(dnsRecord) {
            const index = this.getConfig().static_dns_records.indexOf(dnsRecord);
            const { static_dns_records: dnsRecords } = this.getConfig();

            dnsRecords.splice(index, 1);
        }

        /**
         * Returns true if VS has WAF configured. HTTP(S) VS only.
         * @returns {boolean}
         * @public
         */
        hasWAFPolicy() {
            return !!this.getConfig()['waf_policy_ref'];
        }

        /**
         * Return the waf_policy_ref config field.
         */
        getWafPolicyRef() {
            return this.getConfig().waf_policy_ref;
        }

        /**
         * Returns cloud type this VS belongs to.
         * @returns {string|undefined}
         * @public
         */
        getCloudType() {
            return this.getConfig()['cloud_type'];
        }

        /**
         * Returns VSVip instance when set.
         * @returns {VSVip|null}
         * @public
         */
        getVSVip() {
            return this.getConfig()['vsvip_ref_data'] || null;
        }

        /** @override */
        destroy() {
            const gotDestroyed = super.destroy();

            if (gotDestroyed) {
                [this.pools, this.poolgroups]
                    .forEach(collection => collection && collection.destroy());
            }

            return gotDestroyed;
        }

        /**
         * Returns configuration of analytics policy attached to this vs.
         * @returns {Object|null}
         * @public
         */
        getAnalyticsPolicy() {
            return this.getConfig()['analytics_policy'] || null;
        }

        /**
         * Returns true when real time metrics is turned on for the VS and one of the following
         * is true:
         *  - at least one of the pools attached has real time metrics turned on
         *  - VS has no pools/poolgroups attached
         * When data is not available falls back to false.
         * @returns {boolean}
         * @public
         */
        hasRealTimeMetrics() {
            const {
                pools: poolsList,
                poolgroups: poolGroupsList,
                has_pool_with_realtime_metrics: hasPoolWithRealtimeMetrics,
            } = this.data;

            // inventory data hasn't been loaded yet
            if (!poolsList) {
                return false;
            }

            const policy = this.getAnalyticsPolicy();

            if (!policy || !('metrics_realtime_update' in policy)) {
                return false;
            }

            const hasNoPools = !poolsList.length && !poolGroupsList.length;

            return policy.metrics_realtime_update.enabled &&
                (hasNoPools || hasPoolWithRealtimeMetrics) || false;
        }

        /**
         * Returns type of VS, either with virtual hosting or normal, assuming type was returned by
         * BE API.
         * @returns {string} - child, parent, or normal; value of VirtualServiceType enum | ''
         */
        getType() {
            return this.getConfig().type || '';
        }

        /**
         * Sets the Virtual Service type.
         * @param {string} type - VirtualServieType
         */
        setType(type) {
            this.getConfig().type = type;
        }

        /**
         * Returns the type of virtual hosting for VS.
         * @returns {string} - SNI or SNI Enhanced, enum name VHType.
         */
        getVHType() {
            return this.getConfig().vh_type || '';
        }

        /**
         * Checks if VS is a virtual hosting child.
         * @returns {boolean}
         */
        isVHChild() {
            return this.getType() === VS_TYPE_VH_CHILD;
        }

        /**
         * Checks if VS is a virtual hosting parent.
         * @returns {boolean}
         */
        isVHParent() {
            return this.getType() === VS_TYPE_VH_PARENT;
        }

        /**
         * Returns true if the VH type is SNI Enhanced.
         * @returns {boolean}
         */
        isEnhancedVH() {
            return this.getVHType() === VS_TYPE_VH_ENHANCED;
        }

        /**
         * Returns true if the VH type is SNI.
         * @returns {boolean}
         */
        isSniVH() {
            return this.getVHType() === VS_TYPE_VH_SNI;
        }

        /**
         * Returns true if the VS is a VH Child and is SNI type.
         * @returns {boolean}
         */
        isSniVHChild() {
            return this.isVHChild() && this.isSniVH();
        }

        /**
         * Returns true if the VS is a VH Child and is SNI Enhanced type.
         * @returns {boolean}
         */
        isEnhancedVHChild() {
            return this.isVHChild() && this.isEnhancedVH();
        }

        /**
         * Returns true if the VS is a VH parent and is SNI Enhanced type.
         * @returns {boolean}
         */
        isEnhancedVHParent() {
            return this.isVHParent() && this.isEnhancedVH();
        }

        /**
         * Returns parentVS ref for virtual hosting child.
         * @returns {string} - parent ref or empty str
         */
        getVHParentRef() {
            return this.getConfig().vh_parent_vs_ref || '';
        }

        /**
         * Returns IP addresses of parent VS for VH child.
         * @return {ng.$q.promise}
         */
        getVHParentIPs(format = 'hash') {
            return $q((resolve, reject) => {
                this.busy = true;

                this.loadVhParent_()
                    .then(parentVS => {
                        const parentIPs = parentVS.getIPAddresses(format);

                        resolve(parentIPs);
                    })
                    .finally(() => this.busy = false);
            });
        }

        /**
         * Update data when parent virtual host VS has changed.
         */
        onVhParentVsChange() {
            this.storeParentVsServicePorts_();
        }

        /**
         * Fetch and store service ports from the parent virtual host VS.
         * @protected
         */
        storeParentVsServicePorts_() {
            this.getVHParentServicePorts_()
                .then(servicePorts => this.parentVsServicePorts = servicePorts || [])
                .catch(err => console.error(err));
        }

        /**
         * Load the virtual host parent VS and return it.
         * To be called only when this is a child VS.
         * @return {ng.$q.promise}
         * @protected
         */
        loadVhParent_() {
            return $q((resolve, reject) => {
                if (!this.isVHChild()) {
                    return reject('Not a VH child VS.');
                }

                const parentRef = this.getVHParentRef();

                // API virtualservice-inventory does not provide vh_parent_vs_ref field.
                // In case of VS collection grid we do not need parent reference
                // as we are not showing parent Service Port or IPs.
                if (!parentRef) {
                    return reject('VH parent reference not available.');
                }

                const parentVS = new VirtualService({ id: parentRef.slug() });
                const headerParam = this.getLoadHeaders_();

                parentVS.addParams({ headers_: headerParam });
                parentVS.load()
                    .then(() => {
                        resolve(parentVS);
                    })
                    .finally(() => parentVS.destroy());
            });
        }

        /**
         * Get service ports from the parent VS for VH child as a child vs doens't contain
         * any service ports.
         * @return {ng.$q.promise}
         * @protected
         */
        getVHParentServicePorts_() {
            return $q((resolve, reject) => {
                this.busy = true;

                this.loadVhParent_()
                    .then(parentVS => {
                        const { services } = parentVS.getConfig();

                        resolve(services);
                    })
                    .finally(() => this.busy = false);
            });
        }

        /** @override */
        hasCustomTimeFrameSettings() {
            return !this.collection && this.hasRealTimeMetrics();
        }

        /** @override */
        getCustomTimeFrameSettings(tfLabel) {
            if (this.hasCustomTimeFrameSettings() && tfLabel === 'rt') {
                return {
                    step: 5,
                    limit: 360,
                };
            }

            return null;
        }

        /**
         * Sets the VS services "enable_ssl" flag.
         * @param {boolean=} enabled
         */
        setServicesSSL(enabled = false) {
            const config = this.getConfig();

            //boolean type only
            enabled = !!enabled;

            config['services'].forEach(service => service['enable_ssl'] = enabled);
        }

        /**
         * Called when the VH Type has changed.
         */
        onVHTypeChange() {
            this.resetSslCertificates();
            this.resetEnhancedVHMatches();
        }

        /**
         * Called to reset the ssl_key_and_certificates field based on the Virtual Service type.
         */
        resetSslCertificates() {
            const config = this.getConfig();

            if (this.isVHChild()) {
                config.ssl_key_and_certificate_refs = [];
            } else if (this.isVHParent()) {
                config.ssl_key_and_certificate_refs = [
                    defaultValues.getSystemObjectRefByName(
                        'sslkeyandcertificate',
                        'System-Default-Cert',
                    ),
                ];
            }
        }

        /**
         * Sets the default vh_matches property, based on the VS type. If the VS is an SNI
         * enhanced child, sets a default VHMatch criteria object.
         */
        resetEnhancedVHMatches() {
            const config = this.getConfig();

            if (this.isEnhancedVHChild()) {
                this.safeSetNewChildByField('vh_matches');
            } else {
                delete config.vh_matches;
            }
        }

        /**
         * Overrides analytics policy config for
         * ENTERPRISE/ENTERPRISE_WITH_CLOUD_SERVICES license tier.
         * @param {Object} analyticsPolicy
         * @private
         */
        static overrideAnalyticsPolicyDefaults_(analyticsPolicy) {
            const fieldsToBeEnabled = [
                'full_client_logs',
                'metrics_realtime_update',
            ];

            fieldsToBeEnabled.forEach(field => analyticsPolicy[field].enabled = true);
        }

        /**
         * Checks and returns if Bot Analytics section has to be shown in side rail.
         * @returns {boolean}
         */
        hasBotDetectionPolicy() {
            return Boolean(this.getConfig().bot_policy_ref);
        }

        /**
         * Returns configured VHMatch host names
         * @returns {Array<String>} String array of VHMatch host names
         */
        getVHMatchesHostNames() {
            return this.getConfig().vh_matches.getConfig()
                .map(match => match.getConfig().host);
        }

        /**
         * Add Handler for VH Match.
         */
        addVHMatch() {
            this.addChildMessageItem({
                field: 'vh_matches',
                modalBindings: {
                    isEditing: false,
                    hostNames: this.getVHMatchesHostNames(),
                    hostIndex: this.getConfig().vh_matches.count,
                },
            });
        }

        /**
         * Edit Handler for VH Match.
         * @param {VHMatchConfigItem} vhMatch - VHMatchConfigItem instance to edit.
         */
        editVHMatch(vhMatch) {
            this.editChildMessageItem({
                field: 'vh_matches',
                messageItem: vhMatch,
                modalBindings: {
                    isEditing: true,
                    hostNames: this.getVHMatchesHostNames(),
                    hostIndex: this.getConfig().vh_matches.getArrayIndexWithMessageItem(vhMatch),
                },
            });
        }

        /**
         * Delete Handler for VH Match.
         * @param {VHMatchConfigItem} vhMatch - VHMatchConfigItem instance to edit.
         */
        deleteVHMatch(vhMatch) {
            this.getConfig().vh_matches.removeByMessageItem(vhMatch);
        }

        /**
         * Open VsVip edit modal.
         */
        openEditVsVipModal() {
            this.getConfig().vsvip_ref_data.edit();
        }

        /**
         * Open pool edit modal.
         */
        openEditPoolModal() {
            new Pool({
                data: {
                    config: this.getConfig().pool_ref_data,
                },
            }).edit();
        }
    }

    const params = {
        include_name: true,
        join: [
            'network_profile_ref',
            'server_network_profile_ref',
            'application_profile_ref',
            'pool_ref',
            'pool_group_ref',
            'cloud_ref',
            'network_security_policy_ref',
            'ssl_profile_ref',
            'ssl_key_and_certificate_refs',
            'http_policies.http_policy_set_ref',
            'vsvip_ref',
            'dns_policies.dns_policy_ref',
            'topology_policies.dns_policy_ref',
            'networkprofile:services.override_network_profile_ref',
            'bot_policy_ref',
        ].join(),
    };

    /**
     * Names of property in #data.config with the same type DnsPolicies in protobuf.
     * @type {string[]}
     */
    const dnsPoliciesPropNames = ['dns_policies', 'topology_policies'];

    Object.assign(VirtualService.prototype, {
        objectName: 'virtualservice',
        windowElement: 'app-vs-create',
        detailsStateName_: 'authenticated.application.virtualservice-detail',
        params,
        dnsPoliciesPropNames,
    });

    return VirtualService;
}

virtualServiceFactory.$inject = [
    '$q',
    'ObjectTypeItem',
    'RangeParser',
    'Pool',
    'PoolCollection',
    'HTTPRedirectAction',
    'PoolGroupCollection',
    'HttpPolicySet',
    'NetworkSecurityPolicy',
    'VsVip',
    'DnsPolicyContainerConfig',
    'createItemByRef',
    'DnsRecordConfig',
    'Item',
    'WafPolicyItem',
    'ApplicationProfile',
    'defaultValues',
    'systemInfoService',
    'VsVipCollection',
];

angular.module('avi/vs').factory('VirtualService', virtualServiceFactory);
