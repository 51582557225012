/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Rounds value to desired number of digits after decimal point. Math.prototype.toFixed normally
 * returns a string but this is a wrapper that returns a number.
 */
export function toFixed(value: number, digits: number): number {
    return +value.toFixed(digits);
}

/**
 * Syntactic sugar for the toFixed method returning not a string but number with three digits after
 * decimal point. Mostly used for SVG attributes.
 */
export function toFixed3(value: number): number {
    return toFixed(value, 3);
}

/**
 * Returns the value itself if it is within boundaries or the closest boundary value when out.
 */
export function clipValue(value: number, min: number, max: number): number {
    if (max < min) {
        console.error(`clipValue: max=${toFixed3(max)} < min=${toFixed3(min)}`);
        max = min;
    }

    return Math.min(max, Math.max(min, value));
}

/**
 * Converts a large number to a human-readable string by abbreviating and adding a 'K' or 'M'
 * suffix.
 */
export function num2abbrStr(value: number, precision: number): string {
    const regExp = /\.0+$/;

    let str;
    let postfix = '';

    if (value > 9999) {
        if (value < 1000000) {
            postfix = 'K';
            str = (value / 1000).toFixed(precision);
        } else {
            postfix = 'M';
            str = (value / 1000000).toFixed(precision);
        }
    } else {
        str = value.toFixed(precision);
    }

    return str.replace(regExp, '') + postfix;
}

/**
 * Converts number bytes to a human-readable string by abbreviating and adding a 'B', 'KB', 'MB', or
 * 'GB' suffix with 1 decimal place rounding.
 */
export function formatBytes(bytes: number): string {
    let v = bytes;

    if (v === 0) {
        return '0';
    }

    if (!Math.floor(v / 1024)) {
        return `${v} B`;
    }

    v /= 1024;

    if (!Math.floor(v / 1024)) {
        return `${v.toFixed(1)} KB`;
    }

    v /= 1024;

    if (!Math.floor(v / 1024)) {
        return `${v.toFixed(1)} MB`;
    }

    v /= 1024;

    return `${v.toFixed(1)} GB`;
}
