<span class="vs-log-cinematic-ip-field">
    <ng-container *ngIf="version === 'ipv6'">
        [
    </ng-container>
    <span
        vsLogPartialSelectionFilter
        [property]="property"
        [value]="ip"
        [noSearchIcon]="noIpSearchIcon"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    ></span>
    <ng-container *ngIf="version === 'ipv6'">
        ]
    </ng-container>
    <ng-container *ngIf="ip !== undefined && port !== undefined">
        :<span
            vsLogPartialSelectionFilter
            [property]="portProperty"
            [value]="port"
            [noSearchIcon]="noPortSearchIcon"
            position="right"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        ></span>
    </ng-container>
</span>
