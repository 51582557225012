<div
    *ngIf="activeTemplateConfig.unique"
    class="vs-logs-signpost-default-header__wrapper"
>
    <div
        *ngIf="vsLogsSignpostStore.vsLogsDefaultSignpostData$ | async as vsLogsSignpostData"
        class="vs-logs-signpost-default-header__content"
    >
        <span class="vs-logs-signpost-default-header__content-number">
            {{  vsLogsSignpostData.length }}
        </span>
        <span class="vs-logs-signpost-default-header__content-contentText">
            {{ activeTemplateConfig.unique + (vsLogsSignpostData.length !== 1 && (activeTemplateConfig.uniquePluralEnd || 's') || '') }}
        </span>
        <div class="vs-logs-signpost-default-header__content-line"></div>
    </div>
</div>
