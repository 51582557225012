<div class="grid-container ssl-certificate-expander">
    <div
        class="grid-container__cell--w--4"
        *ngIf="certificate.isRevoked"
    >
        <strong>{{ l10nKeys.revocationReasonLabel | vtranslate }}</strong>
        <div>{{ revocationReasonLabel || l10nKeys.hyphenLabel | vtranslate }}</div>
    </div>
    <div class="grid-container__cell--w--4">
        <strong>{{ l10nKeys.ocspResponseFromResponderUrlLabel | vtranslate }}</strong>
        <div>{{ certificate.ocspResponseInfo?.config.ocsp_resp_from_responder_url || l10nKeys.hyphenLabel | vtranslate }}</div>
    </div>
    <div class="grid-container__cell--w--4">
        <strong>{{ l10nKeys.nextUpdateLabel | vtranslate }}</strong>
        <div>{{ certificate.ocspResponseInfo?.config.next_update || l10nKeys.hyphenLabel | vtranslate }}</div>
    </div>
    <div class="grid-container__cell--w--4">
        <strong>{{ l10nKeys.certStatusLabel | vtranslate }}</strong>
        <div>{{ certStatusLabel || l10nKeys.hyphenLabel | vtranslate }} </div>
    </div>
    <div
        class="grid-container__cell--w--4"
        *ngIf="certificate.isRevoked"
    >
        <strong>{{ l10nKeys.revocationTimeLabel | vtranslate }}</strong>
        <div>{{ certificate.ocspResponseInfo?.config.revocation_time || l10nKeys.hyphenLabel | vtranslate }}</div>
    </div>
    <div class="grid-container__cell--w--4">
        <strong>{{ l10nKeys.thisUpdateLabel | vtranslate }}</strong>
        <div>{{ certificate.ocspResponseInfo?.config.this_update || l10nKeys.hyphenLabel | vtranslate }}</div>
    </div>
</div>
