<div 
    class="role-permission-setter"
    cds-layout="horizontal gap:sm"
>
    <cds-icon-button
        *ngIf="showAssortedIcon"
        ariaLabel="{{ l10nKeys.assortedLabel | vtranslate }}"
        action="flat"
        disabled
        class="role-permission-setter__icon-btn sel-assorted"
        size="sm"
    >
        <avi-tooltip-icon-with-content-wrapper
            shape="circle"
            size="18"
            class="role-permission-setter__icon"
            [ngClass]="{ active: role.assorted }"
        >
            {{ l10nKeys.assortedLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>
    </cds-icon-button>
    <cds-icon-button
        (click)="updateRolePermission($event, PrivilegeEnumHash.WRITE_ACCESS, writeBtnRef.disabled)"
        #writeBtnRef
        ariaLabel="{{ l10nKeys.writeLabel | vtranslate }}"
        [disabled]="isRoleDisabled(permission.CAN_WRITE)"
        action="flat"
        class="role-permission-setter__icon-btn sel-write"
        size="sm"
    >
        <avi-tooltip-icon-with-content-wrapper
            shape="pencil"
            size="18"
            class="role-permission-setter__icon"
            [ngClass]="{ active: role.type === PrivilegeEnumHash.WRITE_ACCESS }"
        >
            {{ role.hint || l10nKeys.writeLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>
    </cds-icon-button>
    <cds-icon-button
        (click)="updateRolePermission($event, PrivilegeEnumHash.READ_ACCESS, readBtnRef.disabled)"
        #readBtnRef
        action="flat"
        ariaLabel="{{ l10nKeys.readLabel | vtranslate }}"
        class="role-permission-setter__icon-btn sel-read"
        size="sm"
        [disabled]="isRoleDisabled(permission.CAN_READ)"
    >
        <avi-tooltip-icon-with-content-wrapper
            shape="eye"
            size="18"
            class="role-permission-setter__icon"
            [ngClass]="{ active: role.type === PrivilegeEnumHash.READ_ACCESS }"
        >
            {{ role.hint || l10nKeys.readLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>
    </cds-icon-button>
    <cds-icon-button
        (click)="updateRolePermission($event, PrivilegeEnumHash.NO_ACCESS)"
        ariaLabel="{{ l10nKeys.noAccessLabel | vtranslate }}"
        action="flat"
        class="role-permission-setter__icon-btn sel-no-access"
        size="sm"
    >
        <avi-tooltip-icon-with-content-wrapper
            shape="no-access"
            size="18"
            class="role-permission-setter__icon"
            [ngClass]="{ active: role.type === PrivilegeEnumHash.NO_ACCESS }"
        >
            {{ l10nKeys.noAccessLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>
    </cds-icon-button>
</div>
