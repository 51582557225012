/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { take, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';
import {
    DropdownModelSingleValue,
    IAviDropdownOption,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import {
    getOptionsHeight,
    isFiltered,
    isFilteredCustom,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.utils';
import {
    MAX_OPTIONS_LIST_HEIGHT,
    OPTION_HEIGHT,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.constants';
import { VsLogsStore } from '../../../../services/vs-logs.store';
import {
    VsLogsSearchBarDropdownService,
} from '../../../../services/vs-logs-search-bar-dropdown.service';
import { VsLogsType } from '../../../../vs-logs.types';
import {
    getFilterOperatorOptionsFromProperty,
    getFilterPropertyOptionsFromType,
    getFilterPropertyType,
} from '../../../../utils/vs-logs-filters.utils';
import {
    DropdownOptionType,
    VsLogsSearchBarStore,
} from '../vs-logs-search-bar.store';
import { IVsLogsOperatorDesc } from '../../../../constants/vs-logs-filters.constants';

import * as l10n from './vs-logs-search-bar-dropdown.l10n';
import './vs-logs-search-bar-dropdown.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const {
    PROPERTY,
    OPERATOR,
    VALUE,
} = DropdownOptionType;

/**
 * Type of dropdown option value for the groubpy value stage.
 */
interface IGroupbyObj {
    value: string;
    count: number;
    percentage: number;
}

/**
 * Type of options that go into the dropdown.
 * Each stage has a different type.
 */
type TVsLogsDropdownOption =
    IAviDropdownOption<DropdownModelSingleValue | IVsLogsOperatorDesc | IGroupbyObj>;

/**
 * @description
 *      Component for the VS Logs search bar dropdown.
 *
 *      The dropdown can be thought of as having 3 "stages".
 *      When first opened, the dropdown suggests filter property values (e.g. 'method').
 *      Once a property is selected, operator values are shown (e.g. '=').
 *      Once an operator is selected, a groupby request is made for that property,
 *      and the resulting values are displayed as suggestions (e.g., GET 80%, POST 20%).
 *
 *      This component displays all 3 stages,
 *      and the state is determined by the VsLogsSearchBarStore.
 *
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-search-bar-dropdown',
    templateUrl: './vs-logs-search-bar-dropdown.component.html',
})
export class VsLogsSearchBarDropdownComponent implements AfterViewInit, OnDestroy {
    @Input()
    public dropdownWidth: number;

    /**
     * Custom option templates for operator and value dropdowns.
     */
    @ViewChild('operatorOptionTemplateRef')
    public operatorOptionTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('valueOptionTemplateRef')
    public valueOptionTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    /**
     * Option template ref of current dropdown (i.e., one of the two above based on dropdown type).
     */
    public optionTemplateRef?: TemplateRef<HTMLElement>;

    /**
     * Height of the dropdown in pixels.
     */
    public dropdownHeight = 0;

    /**
     * Flag to hide dropdown if there are no options.
     */
    public hideDropdownIfNoOptions = true;

    /**
     * Message to display in the dropdown if there are no values returned by groupby request.
     * Only relevant for stage 2 values dropdown.
     */
    public noGroupbyOptionsMessage: string;

    public optionType: DropdownOptionType;

    /**
     * Set of options displayed in the dropdown.
     */
    public options: TVsLogsDropdownOption[] = [];

    /**
     * Full set of possible options.
     */
    private fullOptionsList: TVsLogsDropdownOption[] = [];

    private vsLogsType: VsLogsType;

    /**
     * Possible options stored as a Set for quick lookup.
     */
    private fullOptionsSet = new Set<string>();

    /**
     * Current property in the input when in dropdown operator or value stage.
     */
    private inputFilterProperty: string;

    /**
     * Current operator in the input when in dropdown value stage.
     */
    private inputFilterOperator: string;

    /**
     * Subscriptions to respective values from the search bar store.
     */
    private inputFilterValueSubscription: Subscription;

    private groupbyValuesSubscription: Subscription;

    constructor(
        l10nService: L10nService,
        private readonly vsLogsStore: VsLogsStore,
        public readonly vsLogsSearchBarStore: VsLogsSearchBarStore,
        private readonly vsLogsSearchBarDropdownService: VsLogsSearchBarDropdownService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.initDropdown();
    }

    /** @override */
    public ngAfterViewInit(): void {
        switch (this.optionType) {
            case DropdownOptionType.OPERATOR:
                this.optionTemplateRef = this.operatorOptionTemplateRef;
                break;

            case DropdownOptionType.VALUE:
                this.optionTemplateRef = this.valueOptionTemplateRef;
                break;
        }
    }

    /**
     * @override
     * Manually unsubscribe where necessary.
     */
    public ngOnDestroy(): void {
        this.inputFilterValueSubscription.unsubscribe();
        this.groupbyValuesSubscription?.unsubscribe();
    }

    /**
     * Handle dropdown option selection based on dropdown option type.
     */
    public handleOptionSelect({ value }: TVsLogsDropdownOption): void {
        const { setInputFilterValue } = this.vsLogsSearchBarStore;

        switch (this.optionType) {
            case PROPERTY:
                setInputFilterValue(value as string);
                break;

            case OPERATOR: {
                const { symbol } = value as IVsLogsOperatorDesc;

                setInputFilterValue(
                    `${this.inputFilterProperty}${symbol}`,
                );
                break;
            }

            case VALUE: {
                const { value: groupbyValue } = value as IGroupbyObj;

                const propertyType = getFilterPropertyType(this.inputFilterProperty);
                let filter = `${this.inputFilterProperty}${this.inputFilterOperator}`;

                switch (propertyType) {
                    case 'string':
                    case 'boolean':
                        filter += `"${groupbyValue}"`;

                        break;

                    case 'number':
                        filter += groupbyValue;
                        break;
                }

                setInputFilterValue(filter);

                // Since this is the last stage, submit upon user selection
                this.vsLogsSearchBarDropdownService.submit();
                break;
            }
        }
    }

    /**
     * Handle submission events from the dropdown.
     */
    public handleSubmit(): void {
        this.vsLogsSearchBarDropdownService.submit();
    }

    /**
     * Sets the height of the list of options.
     */
    private setDropdownHeight(): void {
        const { length: optionsLength } = this.options;

        this.dropdownHeight = getOptionsHeight(
            optionsLength,
            OPTION_HEIGHT,
            MAX_OPTIONS_LIST_HEIGHT,
        );
    }

    /**
     * Initialize the list and set of full possible options.
     */
    private initOptions(options: IAviDropdownOption[]): void {
        this.fullOptionsList = options;
        this.fullOptionsList.forEach(
            option => this.fullOptionsSet.add(option.value as string),
        );
    }

    /**
     * Update the list of options by filtering with the current search term.
     */
    private setOptions(searchTerm: string): void {
        this.options = this.fullOptionsList.filter(
            (option: IAviDropdownOption) => isFiltered(option, searchTerm),
        );
        this.setDropdownHeight();
    }

    /**
     * Initialize the dropdown based on the dropdown type.
     */
    private initDropdown(): void {
        this.vsLogsStore.vsLogsType$.pipe(take(1)).subscribe(type => this.vsLogsType = type);

        this.vsLogsSearchBarStore.dropdownOptionType$.pipe(take(1)).subscribe(
            type => this.optionType = type,
        );

        switch (this.optionType) {
            case PROPERTY:
                this.initPropertyDropdown();
                break;

            case OPERATOR:
                this.initOperatorDropdown();
                break;

            case VALUE:
                this.initValueDropdown();
                break;
        }
    }

    /**
     * Stage 0 - Initialize dropdown when the options are properties.
     */
    private initPropertyDropdown(): void {
        this.initOptions(getFilterPropertyOptionsFromType(this.vsLogsType));

        // Subscribe to changes in the input value as user types and set options accordingly
        this.inputFilterValueSubscription = this.vsLogsSearchBarStore.inputFilterValue$.subscribe(
            inputFilterValue => {
                // Increment from property to operator stage if input value matches an option
                // stage 0 -> 1
                if (this.fullOptionsSet.has(inputFilterValue)) {
                    this.vsLogsSearchBarStore.setInputFilterProperty(inputFilterValue);
                    this.vsLogsSearchBarStore.incrementDropdownStage();
                } else {
                    this.setOptions(inputFilterValue);
                }
            },
        );
    }

    /**
     * Stage 1 - Initialize the dropdown when the options are operators.
     */
    private initOperatorDropdown(): void {
        this.vsLogsSearchBarStore.inputFilterProperty$.pipe(take(1)).subscribe(property => {
            this.inputFilterProperty = property;

            this.fullOptionsList = getFilterOperatorOptionsFromProperty(property);
            this.fullOptionsList.forEach((option: IAviDropdownOption<IVsLogsOperatorDesc>) => {
                this.fullOptionsSet.add(option.value.symbol);
            });
            this.options = this.fullOptionsList;
        });

        // Subscribe to changes in the input value as user types and set options accordingly
        this.inputFilterValueSubscription = this.vsLogsSearchBarStore.inputFilterValue$.subscribe(
            inputFilterValue => {
                // If user starts modifying property, revert back to property stage
                // stage 1 -> 0
                if (inputFilterValue.length < this.inputFilterProperty.length) {
                    this.vsLogsSearchBarStore.resetInputFilterProperty();
                    this.vsLogsSearchBarStore.decrementDropdownStage();

                    return;
                }

                const operatorValue = inputFilterValue.split(this.inputFilterProperty)[1];

                // Increment from operator to value stage if input value matches an option
                // stage 1 -> 2
                if (this.fullOptionsSet.has(operatorValue)) {
                    this.vsLogsSearchBarStore.setInputFilterOperator(operatorValue);
                    this.vsLogsSearchBarStore.incrementDropdownStage();
                } else {
                    this.options = this.fullOptionsList.filter(
                        ({ value }: IAviDropdownOption<IVsLogsOperatorDesc>) => {
                            return isFilteredCustom(value.symbol, operatorValue);
                        },
                    );
                    this.setDropdownHeight();
                }
            },
        );
    }

    /**
     * Stage 2 - Initialize the dropdown when the options are values.
     */
    private initValueDropdown(): void {
        this.noGroupbyOptionsMessage = l10nKeys.noOptionsMessage;

        this.vsLogsSearchBarStore.inputFilterProperty$.pipe(take(1)).subscribe(property => {
            this.inputFilterProperty = property;
        });

        this.vsLogsSearchBarStore.inputFilterOperator$.pipe(take(1)).subscribe(operator => {
            this.inputFilterOperator = operator;
        });

        // Subscribe to groupby values requested from API and set them to options
        this.groupbyValuesSubscription = this.vsLogsSearchBarStore.groupbyValues$.subscribe(
            values => {
                // due to API calls coming in async, confirm this is for the right property
                if (values.length > 0 && values[0][this.inputFilterProperty] !== undefined) {
                    values.forEach(groupbyValue => {
                        const value = groupbyValue[this.inputFilterProperty]?.toString();

                        // Don't include "Other" as we can't filter by it
                        if (value === undefined) {
                            return;
                        }

                        const groubyObj: IGroupbyObj = {
                            value,
                            count: groupbyValue.count,
                            percentage: groupbyValue.percentage,
                        };

                        this.fullOptionsList.push({ value: groubyObj });
                    });
                    this.options = this.fullOptionsList;
                }

                // remove this flag so that we can display messages
                // if no groupby values are returned
                if (!values.length) {
                    this.hideDropdownIfNoOptions = false;
                }

                this.setDropdownHeight();
            },
        );

        // Subscribe to changes in the input value as user types and set options accordingly
        this.inputFilterValueSubscription = this.vsLogsSearchBarStore.inputFilterValue$.pipe(
            withLatestFrom(this.vsLogsSearchBarStore.inputFilterOperator$),
        ).subscribe(([inputFilterValue, operator]) => {
            this.hideDropdownIfNoOptions = true;

            // If user starts modifying operator, revert back to operator stage
            // stage 2 -> 1
            if (inputFilterValue.length < this.inputFilterProperty.length + operator.length) {
                this.vsLogsSearchBarStore.resetInputFilterOperator();
                this.vsLogsSearchBarStore.resetGroupbyValues();
                this.vsLogsSearchBarStore.decrementDropdownStage();

                return;
            }

            const inputValue = inputFilterValue.split(`${this.inputFilterProperty}${operator}`)[1];

            this.options = this.fullOptionsList.filter(
                ({ value: option }: IAviDropdownOption<IGroupbyObj>) => {
                    return isFilteredCustom(option.value, inputValue);
                },
            );
            this.setDropdownHeight();
        });

        this.vsLogsSearchBarStore.requestGroupbyValues();
    }
}
