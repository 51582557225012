/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './geo-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  geoProfileModal
 * @description
 *     Component for Geo Profile configuration modal. Configuring a Geo Profile basically starts a
 *     process, in which a file that is "staging" becomes "staged". A request is made to the
 *     back-end to get a list of staging files, which is displayed in dropdowns for the user to
 *     select. If editing, previously selected files are no longer "staging" and are now "staged",
 *     so those dropdowns become disabled, since it would be weird for the selected value to not
 *     be in the list of options in the dropdown.
 * @param  {GeoProfile} editable
 * @param  {Function} closeModal - Closes modal.
 */
class GeoProfileModalController {
    constructor(Regex, l10nService) {
        this.Regex = Regex;
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.stagingFiles = undefined;
        this.selectedStagingFiles = [];

        this.setSelectedStagingFiles();
        this.editable.getStagingFiles()
            .then(files => this.stagingFiles = files);
    }

    /**
     * Sets this.selectedStagingFiles array to be passed as hiddenOptions to dropdowns, so that
     * previously selected options aren't shown.
     */
    setSelectedStagingFiles() {
        const { entries } = this.editable.getConfig();

        this.selectedStagingFiles = entries.map(entry => entry.file.filename);
    }

    /**
     * Removes a selected entry object from config.files and sets this.selectedStagingFiles.
     * @param  {number} index - Index of the file object to remove.
     */
    removeEntry(index) {
        this.editable.removeEntry(index);
        this.setSelectedStagingFiles();
    }
}

GeoProfileModalController.$inject = [
    'Regex',
    'l10nService',
];

angular.module('aviApp').component('geoProfileModal', {
    controller: GeoProfileModalController,
    bindings: {
        editable: '<',
        closeModal: '&',
    },
    templateUrl: 'src/components/infrastructure/gslb/geo-profile-modal/' +
            'geo-profile-modal.html',
});
