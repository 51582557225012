/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    StringGroupItem,
    STRING_GROUP_ITEM_TOKEN,
} from 'ajs/modules/groups';
import {
    IpAddrGroupCollection,
} from 'ajs/modules/groups/factories/ip-addr-group/ip-addr-group.collection.factory';

import { IP_ADDRESS_GROUP_COLLECTION_TOKEN } from 'ajs/modules/groups/groups.tokens';

const $injector = '$injector';

export const StringGroupItemProvider = {
    deps: [$injector],
    provide: StringGroupItem,
    useFactory(injector: Injector): typeof StringGroupItem {
        return injector.get(STRING_GROUP_ITEM_TOKEN);
    },
};

export const ipAddrGroupCollectionProvider = {
    deps: [$injector],
    provide: IpAddrGroupCollection,
    useFactory(injector: Injector): typeof IpAddrGroupCollection {
        return injector.get(IP_ADDRESS_GROUP_COLLECTION_TOKEN);
    },
};
