/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export enum AllowedClientTypesEnumValues {
    ssh = 'ssh',
    cli = 'cli',
    https = 'https',
    snmp = 'snmp',
}

export interface IAllowedClientsConfig {
    areAnyValuesAllowed: boolean,
    allowedValues: IAllowedValue[],
}

export interface IAllowedValue {
    value?: string,
    isGroupRef: boolean,
}
