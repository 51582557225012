/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import './match-and-action-display-wrapper.component.less';
import * as l10n from './match-and-action-display-wrapper.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Wrapper component to be used to display set of selected matches and action(s).
 * @author Akul Aggarwal
 */
@Component({
    selector: 'match-and-action-display-wrapper',
    templateUrl: './match-and-action-display-wrapper.component.html',
})
export class MatchAndActionDisplayWrapperComponent implements OnInit {
    public l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly elementRef: ElementRef,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.elementRef.nativeElement.classList.add('match-and-action-display-wrapper');
    }
}
