<div provideParentForm>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="VCenterCredentials"
            fieldName="username"
            for="username"
            required
        >
            {{ l10nKeys.usernameFieldLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="username"
            placeholder="{{ l10nKeys.usernameFieldPlaceholder | vtranslate }}"
            name="username"
            [(ngModel)]="editable.config.username"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="VCenterCredentials"
            fieldName="password"
            for="password"
            required
        >
            {{ l10nKeys.passwordFieldLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="password"
            id="password"
            class="sel-password"
            placeholder="{{ l10nKeys.passwordFieldPlaceholder | vtranslate }}"
            name="password"
            [(ngModel)]="editable.config.password"
            required
        />
    </avi-input-container>
</div>
