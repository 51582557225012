/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'data-script-set-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const l4EventsHeader = `${prefix}.l4EventsHeader`;
export const fileUploadInputPlaceholder = `${prefix}.fileUploadInputPlaceholder`;
export const dnsEventsHeader = `${prefix}.dnsEventsHeader`;
export const eventsHeader = `${prefix}.eventsHeader`;
export const sslEventsHeader = `${prefix}.sslEventsHeader`;
export const httpEventsHeader = `${prefix}.httpEventsHeader`;
export const poolsInputLabel = `${prefix}.poolsInputLabel`;
export const poolsInputPlaceholder = `${prefix}.poolsInputPlaceholder`;
export const poolGroupsInputLabel = `${prefix}.poolGroupsInputLabel`;
export const poolGroupsInputPlaceholder = `${prefix}.poolGroupsInputPlaceholder`;
export const ipGroupsInputLabel = `${prefix}.ipGroupsInputLabel`;
export const ipGroupsInputPlaceholder = `${prefix}.ipGroupsInputPlaceholder`;
export const stringGroupsInputLabel = `${prefix}.stringGroupsInputLabel`;
export const stringGroupsInputPlaceholder = `${prefix}.stringGroupsInputPlaceholder`;
export const protocolParserInputLabel = `${prefix}.protocolParserInputLabel`;
export const protocolParserInputPlaceholder = `${prefix}.protocolParserInputPlaceholder`;
export const createProtocolParserBtnLabel = `${prefix}.createProtocolParserBtnLabel`;
export const ipReputationDbInputLabel = `${prefix}.ipReputationDbInputLabel`;
export const ipReputationDbInputPlaceholder = `${prefix}.ipReputationDbInputPlaceholder`;
export const geoDbInputLabel = `${prefix}.geoDbInputLabel`;
export const geoDbInputPlaceholder = `${prefix}.geoDbInputPlaceholder`;
export const rateLimitersHeader = `${prefix}.rateLimitersHeader`;
export const fileUploadInputLabel = `${prefix}.fileUploadInputLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const sslProfileInputLabel = `${prefix}.sslProfileInputLabel`;
export const sslProfileInputPlaceholder = `${prefix}.sslProfileInputPlaceholder`;
export const sslKeyCertificateLabel = `${prefix}.sslKeyCertificateLabel`;
export const sslKeyCertificatePlaceholder = `${prefix}.sslKeyCertificatePlaceholder`;
export const pkiProfileInputLabel = `${prefix}.pkiProfileInputLabel`;
export const pkiProfileInputPlaceholder = `${prefix}.pkiProfileInputPlaceholder`;
export const btnLabelRootIntermediateCaCert = `${prefix}.btnLabelRootIntermediateCaCert`;
export const btnLabelApplicationCert = `${prefix}.btnLabelApplicationCert`;
export const btnLabelControllerCert = `${prefix}.btnLabelControllerCert`;
export const configureEventsWarning = `${prefix}.configureEventsWarning`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit DataScript Set',
    [modalTitleNew]: 'New DataScript Set',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [eventsHeader]: 'Events',
    [l4EventsHeader]: 'L4 Events',
    [dnsEventsHeader]: 'DNS Events',
    [sslEventsHeader]: 'SSL Events',
    [httpEventsHeader]: 'HTTP Events',
    [poolsInputLabel]: 'Pools',
    [poolsInputPlaceholder]: 'Select Pools',
    [poolGroupsInputLabel]: 'Pool Groups',
    [poolGroupsInputPlaceholder]: 'Select Pool Groups',
    [ipGroupsInputLabel]: 'IP Groups',
    [ipGroupsInputPlaceholder]: 'Select IP Groups',
    [stringGroupsInputLabel]: 'String Groups',
    [stringGroupsInputPlaceholder]: 'Select String Groups',
    [protocolParserInputLabel]: 'Protocol Parser',
    [protocolParserInputPlaceholder]: 'Select Protocol Parser',
    [createProtocolParserBtnLabel]: 'Create Protocol Parser',
    [ipReputationDbInputLabel]: 'IP Reputation DB',
    [ipReputationDbInputPlaceholder]: 'Select IP Reputation DB',
    [geoDbInputLabel]: 'Geo DB',
    [geoDbInputPlaceholder]: 'Select Geo DB',
    [rateLimitersHeader]: 'Rate Limiters',
    [addButtonLabel]: 'Add',
    [sslProfileInputLabel]: 'SSL/TLS Profiles',
    [sslProfileInputPlaceholder]: 'Select SSL/TLS Profiles',
    [sslKeyCertificateLabel]: 'SSL/TLS Certificates',
    [sslKeyCertificatePlaceholder]: 'Select SSL/TLS Certificates',
    [pkiProfileInputLabel]: 'PKI Profiles',
    [pkiProfileInputPlaceholder]: 'Select PKI Profiles',
    [btnLabelApplicationCert]: 'Create Application Certificate',
    [btnLabelControllerCert]: 'Create Controller Certificate',
    [btnLabelRootIntermediateCaCert]: 'Create Root/Intermediate CA Certificate',
    [configureEventsWarning]: 'At least one event needs to be configured to continue.',
};
