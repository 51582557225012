/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './vs-log-cinematic-sub-section-saml.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-saml.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the SAML sub-section in the Authentication section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-saml',
    templateUrl: './vs-log-cinematic-sub-section-saml.component.html',
})
export class VsLogCinematicSubSectionSamlComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the SAML attribute name field.
     */
    @ViewChild('samlAttributeNameFieldTemplateRef')
    public readonly samlAttributeNameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the SAML attribute values field.
     */
    @ViewChild('samlAttributeValuesFieldTemplateRef')
    public readonly samlAttributeValuesFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly samlLog$ = this.vsLogCinematicStore.samlLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public samlAttributeListGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.samlAttributeListGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.samlAttributeListGridConfig.fields = [
            {
                label: this.l10nService.getMessage(l10nKeys.attributeNameLabel),
                id: 'attribute-name',
                templateRef: this.samlAttributeNameFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.attributeValuesLabel),
                id: 'attribute-values',
                templateRef: this.samlAttributeValuesFieldTemplateRef,
            },
        ];
    }
}
