/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-profiles-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const btnLabelIpamProfile = `${prefix}.btnLabelIpamProfile`;
export const btnLabelDnsProfile = `${prefix}.btnLabelDnsProfile`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [btnLabelIpamProfile]: 'IPAM Profile',
    [btnLabelDnsProfile]: 'DNS Profile',
    [headerLabel]: 'IPAM/DNS Profiles',
};
