/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'login';
const componentName = 'alb-login';
const prefix = `${moduleName}.${componentName}`;

export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const forgotPassword = `${prefix}.forgotPassword`;
export const logIn = `${prefix}.logIn`;

export const ENGLISH = {
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [forgotPassword]: 'Forgot your password?',
    [logIn]: 'Log In',
};
