/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import * as UserPreferences from 'ng/root-store/user-preferences';
import 'ajs/less/pages/dashboard.less';
import * as l10n from './AppDashboardController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name AppDashboardController
 * @description
 *
 *     Application dashboard controller provides three types of layout: list, tree and application.
 *
 */
angular.module('aviApp').controller('AppDashboardController', [
'$scope',
'$state',
'myAccount',
'$q',
'InventoryMapCollection',
'VirtualServiceCollection',
'Base',
'GSLBServiceCollection',
'systemInfoService',
'Auth',
'l10nService',
STORE_TOKEN,
function(
    $scope,
    $state,
    myAccount,
    $q,
    InventoryMapCollection,
    VirtualServiceCollection,
    Base,
    GSLBServiceCollection,
    systemInfo,
    Auth,
    l10nService,
    store,
) {
    const base = new Base();

    $scope.dashLegendCollapsed = true;

    l10nService.registerSourceBundles(dictionary);

    $scope.l10nKeys = l10nKeys;

    $scope.vsCollection = new VirtualServiceCollection({
        isStatic: true,
        bind: {
            collItemCreate(item) {
                $state.go('^.virtualservice-detail.analytics', { vsId: item.id });
            },
        },
    });

    $scope.createVirtualServiceActions = [
        {
            label: l10nService.getMessage(l10nKeys.basicVsCreateActionLabel),
            onClick: () => $scope.vsCollection.create('app-vs-create-basic'),
        },
        {
            label: l10nService.getMessage(l10nKeys.advancedVsCreateActionLabel),
            onClick: () => $scope.vsCollection.create(),
        },
    ];

    $scope.ui = {
        viewType: 'list',
    };

    /**
     * Sets accessible view type. ie if viewType is Tree/list and
     * if the user do not have access to VS, view type will be reset.
     */
    function setViewType() {
        const showVS = $scope.hasAccessToVS();
        const showGSLB = $scope.showGSLBDashboardOption();

        const { viewType } = myAccount.uiProperty.appDashboard;
        const vsViewTypes = ['tree', 'list'];

        $scope.ui.viewType = viewType;

        let accessibleViewType = '';

        // Assuming User has access to atleast one of VS/GSLB to
        // reach this state.
        switch (true) {
            case vsViewTypes.includes(viewType) && !showVS:
                accessibleViewType = 'gslbservice';
                break;

            case viewType === 'gslbservice' && !showGSLB:
                accessibleViewType = 'list';
                break;
        }

        if (accessibleViewType) {
            $scope.ui.viewType = accessibleViewType;

            store.dispatch(UserPreferences.updateAppDashboard({
                payload: {
                    viewType: accessibleViewType,
                },
            }));
        }
    }

    /**
     * Returns true if User has to virtual service.
     * @returns {boolean}
     */
    $scope.hasAccessToVS = () => {
        return Auth.isAllowed('virtualservice');
    };

    /**
     * Expands or collapses all items in tree view.
     * @param {boolean} expand - True to expand, false to collapse all items.
     */
    $scope.expandTree = (expand = true) => {
        if ($scope.ui.viewType === 'tree') {
            $scope.collection.items.forEach(item => item._viewExpanded = expand);
        }
    };

    /**
     * Returns true if all items are expanded in tree view.
     * @returns {boolean}
     */
    $scope.isTreeExpanded = () => {
        if ($scope.ui.viewType === 'tree') {
            return _.all($scope.collection.items, item => item._viewExpanded === true);
        }
    };

    function init() {
        const options = {
            params: {
                page_size: 10,
            },
            sortBy: $scope.sortBy.current,
        };

        switch ($scope.ui.viewType) {
            case 'gslbservice':
                $scope.collection = new GSLBServiceCollection({
                    objectName: 'gslbservice-inventory',
                    dataFields: ['config', 'runtime'],
                });

                $scope.collection.load();
                break;

            case 'tree':
                options.sortBy = 'name';
                $scope.collection = new InventoryMapCollection(options);
                $scope.collection.load();
                break;

            default:
                $scope.collection = new VirtualServiceCollection(options);
                $scope.collection.subscribe(['health', 'alert', 'runtime', 'faults']);
                break;
        }

        if ($scope.filters.search) {
            $scope.collection.search($scope.filters.search);
        }
    }

    $scope.handleViewTypeChange = () => {
        const { viewType } = $scope.ui;

        store.dispatch(UserPreferences.updateAppDashboard({
            payload: {
                viewType,
            },
        }));

        $scope.collection && $scope.collection.destroy();

        init();
    };

    $scope.filters = { search: '' };
    $scope.sortBy = { current: 'name' };

    $scope.sort = function() {
        $scope.collection.sort($scope.sortBy.current);
    };

    /**
     * Search function
    */
    function search() {
        $scope.collection.search($scope.filters.search);
    }

    $scope.search = _.debounce(search, 250);//evoked by input

    /**
     * Returns true if the "View" dropdown on the dashboard should show the GSLB Service option.
     * @return {boolean}
     */
    $scope.showGSLBDashboardOption = function() {
        return Auth.isAllowed('PERMISSION_GSLBSERVICE', 'rw') && systemInfo.haveGSLBConfig();
    };

    /**
     * Returns true if user has sufficient permission for VirtualService or GslbService.
     * @return {boolean}
     */
    $scope.haveSufficientPermissions = function() {
        return $scope.hasAccessToVS() || $scope.showGSLBDashboardOption();
    };

    setViewType();

    init();

    $scope.$on('$destroy', function() {
        base.cancelRequests();
        $scope.collection && $scope.collection.destroy();
        $scope.vsCollection && $scope.vsCollection.destroy();
    });
}]);
