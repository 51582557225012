<cds-modal
    class="attachments-selection clr-wrapper"
    size="xl"
    (closeChange)="handleCancel()"
>
    <cds-modal-header>
        <h3>{{ attachmentsSelectionDialogHeader }}</h3>
    </cds-modal-header>
    <cds-modal-content>
       <div class="attachments-selection__grid-container">
           <avi-alert-group
                *ngIf="error"
                alertType="danger"
            >
            {{ error }}
           </avi-alert-group>
            <div
                *ngIf="!isAttachmentsLoading; else loader"
                class="attachments-selection__grid"
            >
                <h6>
                    {{ l10nKeys.gridHeaderLabel  | vtranslate : availableAttachmentsCount }}
                </h6>
                <avi-data-grid
                    [config]="attachmentsGridConfig"
                    [rows]="availableAttachments"
                    (onSelectionChange)="onAttachmentsSelectionChange($event)"
                ></avi-data-grid>
            </div>
        </div>
    </cds-modal-content>
    <cds-modal-actions>
        <cds-button
            action="outline"
            (click)="handleCancel()"
        >
            {{ l10nKeys.cancelButtonLabel | vtranslate }}
        </cds-button>
        <cds-button (click)="handleAddFilesAttachments()">
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </cds-button>
    </cds-modal-actions>
</cds-modal>

<ng-template #loader>
    <div class="attachments-selection__spinner">
        <clr-spinner [clrMedium]="true"></clr-spinner>
    </div>
</ng-template>
