/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'auth-mapping-rules-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleRule = `${prefix}.columnTitleRule`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const authMappingRulesLabel = `${prefix}.authMappingRulesLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const mappingLabel = `${prefix}.mappingLabel`;
export const superUserLabel = `${prefix}.superUserLabel`;
export const customLabel = `${prefix}.customLabel`;

export const ENGLISH = {
    [columnTitleRule]: 'Rule',
    [removeButtonLabel]: 'Remove',
    [authMappingRulesLabel]: 'Mapping Rules ({0})',
    [addButtonLabel]: 'Add',
    [mappingLabel]: 'Mapping',
    [superUserLabel]: 'Super User',
    [customLabel]: 'Custom',
};
