/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleGsGroupSelectionActionConfig
 * @desc DnsPolicyRuleGsGroupSelectionActionConfig maps to DnsRuleActionGsGroupSelection object.
 */
const dnsPolicyRuleGsGroupSelectionActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRuleGsGroupSelectionActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const { group_name: groupName } = this.getConfig();

            return groupName;
        }
    }

    angular.extend(DnsPolicyRuleGsGroupSelectionActionConfig.prototype, {
        type: 'DnsRuleActionGsGroupSelection',
        name: 'GSLB Service Group',
        defaultPath: 'dnsrule.action.gs_group_selection',
    });

    return DnsPolicyRuleGsGroupSelectionActionConfig;
};

dnsPolicyRuleGsGroupSelectionActionConfigFactory.$inject = [
    'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleGsGroupSelectionActionConfig',
    dnsPolicyRuleGsGroupSelectionActionConfigFactory,
);
