<avi-card>
    <avi-card_header>
        <div class="waf-learning-link__header">
            <span class="waf-learning-link__title">
                {{ l10nKeys.title | vtranslate }}
            </span>
            <span
                *ngIf="wafPolicy.config.enable_app_learning"
                class="waf-learning-link__learning-status--enabled"
            >
                {{ l10nKeys.enabledLearningStatusLabel | vtranslate }}
            </span>
            <span
                *ngIf="!wafPolicy.config.enable_app_learning"
                class="waf-learning-link__learning-status--disabled"
            >
                {{ l10nKeys.disabledLearningStatusLabel | vtranslate }}
            </span>
        </div>
    </avi-card_header>
    <avi-card_body>
        <div
            *ngIf="wafPolicy.config.enable_app_learning"
            class="waf-learning-link__body"
        >
            <div class="waf-learning-link__info">
                <div class="waf-learning-link__setting">
                    {{ getUpdateInterval() }}
                </div>
                <div class="waf-learning-link__setting-label">
                    {{ l10nKeys.updateIntervalSettingLabel | vtranslate }}
                </div>
            </div>
            <div class="waf-learning-link__info">
                <div class="waf-learning-link__setting">
                    {{ getSamplingPercentage() }}
                </div>
                <div class="waf-learning-link__setting-label">
                    {{ l10nKeys.samplingPercentageSettingLabel | vtranslate }}
                </div>
            </div>
        </div>
        <div
            *ngIf="!wafPolicy.config.enable_app_learning"
            class="waf-learning-link__body"
        >
            <div class="waf-learning-link__disabled-body-text">
                {{ l10nKeys.enableWafLearningWarningText | vtranslate }}
            </div>
        </div>
    </avi-card_body>
    <avi-card_footer *ngIf="wafPolicy.config.enable_app_learning">
        <button
            class="btn btn-sm btn-link"
            (click)="viewLearningData()"
        >
            {{ l10nKeys.viewLearningDataButtonLabel | vtranslate }}
        </button>
    </avi-card_footer>
</avi-card>
