/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { SslCertificateCreateApplicationModalComponent } from 'ng/modules/security';
import { CERTIFICATE_ITEM_TOKEN } from './certificate.item.factory';

const allDataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * Ajs dependency token for CertificateCollection.
 */
export const CERTIFICATE_COLLECTION_TOKEN = 'CertificateCollection';

/**
 * @description Certificate collection
 * @author Ram Pal, Nisar Nadaf
 */
export class CertificateCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'sslkeyandcertificate',
            windowElement: SslCertificateCreateApplicationModalComponent,
            permissionName: AviPermissionResource.PERMISSION_SSLKEYANDCERTIFICATE,
            defaultDataSources: 'list',
            restrictCreateOnEssentialLicense: false,
            allDataSources,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(CERTIFICATE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            certificate: {
                self_signed: true,
            },
        };
    }
}

CertificateCollection.ajsDependencies = [
    CERTIFICATE_ITEM_TOKEN,
];
