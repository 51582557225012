/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing notification components
 * @preferred
 * @module NotificationModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrAlertModule } from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';
import {
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    AviToastComponent,
    CloudStatusComponent,
    UtcMismatchNotificationComponent,
} from '.';

const notificationComponents = [
    AviNotificationComponent,
    AviNotificationsPortalComponent,
    AviToastComponent,
    CloudStatusComponent,
    UtcMismatchNotificationComponent,
];

@NgModule({
    declarations: [
        ...notificationComponents,
    ],
    imports: [
        ClrAlertModule,
        CommonModule,
        VIPModule,
    ],
    exports: [AviToastComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NotificationModule { }
