/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'role-filter-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const enabledFileLabel = `${prefix}.enabledFileLabel`;
export const keyInputPlaceholder = `${prefix}.keyInputPlaceholder`;
export const criteriaLabel = `${prefix}.criteriaLabel`;
export const valuesLabel = `${prefix}.valuesLabel`;
export const valuesInputPlaceholder = `${prefix}.valuesInputPlaceholder`;

export const ENGLISH = {
    [modalHeader]: 'Label Filter',
    [enabledFileLabel]: 'Enable Label Filter',
    [keyInputPlaceholder]: 'Enter Key',
    [criteriaLabel]: 'Criteria',
    [valuesLabel]: 'Values ({0})',
    [valuesInputPlaceholder]: 'Enter Value',
};
