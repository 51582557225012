/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-significance-options';
const prefix = `${moduleName}.${componentName}`;

export const nonSignificantLabel = `${prefix}.nonSignificantLabel`;
export const significantLabel = `${prefix}.significantLabel`;
export const enableNonSignificantLogsMessage = `${prefix}.enableNonSignificantLogsMessage`;

export const ENGLISH = {
    [nonSignificantLabel]: 'Non-Significant',
    [significantLabel]: 'Significant',
    [enableNonSignificantLogsMessage]: 'Please enable Non-Significant logging in Virtual Service Settings.',
};
