/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'email-smtp-config';
const prefix = `${moduleName}.${componentName}`;

export const smtpSourceLabel = `${prefix}.smtpSourceLabel`;
export const anonymousSmtpLabel = `${prefix}.anonymousSmtpLabel`;
export const smtpLabel = `${prefix}.smtpLabel`;
export const emailAddressInputLabel = `${prefix}.emailAddressInputLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const smtpServerPlaceholder = `${prefix}.smtpServerPlaceholder`;
export const smtpPortLabel = `${prefix}.smtpPortLabel`;
export const smtpPortPlaceholder = `${prefix}.smtpPortPlaceholder`;
export const doNotUseTlsLabel = `${prefix}.doNotUseTlsLabel`;

export const ENGLISH = {
    [smtpSourceLabel]: 'SMTP Source',
    [anonymousSmtpLabel]: 'Anonymous SMTP',
    [smtpLabel]: 'SMTP',
    [emailAddressInputLabel]: 'From Address',
    [smtpServerLabel]: 'SMTP Server',
    [smtpServerPlaceholder]: 'Enter SMTP Server',
    [smtpPortLabel]: 'SMTP Port',
    [smtpPortPlaceholder]: 'Enter SMTP Port',
    [doNotUseTlsLabel]: 'Do not use TLS',
};
