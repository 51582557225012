<match-display-wrapper
    class="path-or-query-match-readonly"
    matchLabel="{{ matchLabel }}"
    matchCriteria="{{ matchCriteria }}"
>
    <div class="path-or-query-match-readonly__values">
        <div *ngFor="let str of editable.matchStr; trackBy: trackByIndex">
            {{ str }}
        </div>
        <div *ngFor="let str of editable.stringGroupNames; trackBy: trackByIndex">
            {{ str }}
        </div>
    </div>
</match-display-wrapper>
