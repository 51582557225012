/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import {
    IHTTPRedirectAction,
    IURIParam,
    IURIParamToken,
    URIParamType,
} from 'generated-types';

import {
    isEmpty,
    reduce,
} from 'underscore';

import { HTTPRedirectAction } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

type THTTPRedirectActionPartial = Omit<IHTTPRedirectAction, 'host' | 'path'>;

type TURIParamPartial = Omit<IURIParam, 'tokens'>;

/**
 * Interface to update tokens as repeatedMessageItem.
 */
interface IURIParamConfig extends TURIParamPartial {
    tokens: RepeatedMessageItem<MessageItem<IURIParamToken>>,
}

/**
 * Extended IHTTPRedirectAction Interface to add UI only fields.
 */
interface IHTTPRedirectActionConfig extends THTTPRedirectActionPartial {
    host?: MessageItem<IURIParamConfig>,
    path?: MessageItem<IURIParamConfig>,
    hostStr?: string,
    pathStr?: string,
}

/**
 * @description HTTPRedirectAction ConfigItem.
 *
 * @author Nisar Nadaf
 */
export class HTTPRedirectActionConfigItem extends MessageItem<IHTTPRedirectActionConfig> {
    public static ajsDependencies = [
        'RangeParser',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectType: HTTPRedirectAction,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     * Convert host and path tokens to string values required on UI.
     */
    public modifyConfigDataAfterLoad(): void {
        const { host, path } = this.config;

        super.modifyConfigDataAfterLoad();

        if (!host) {
            this.safeSetNewChildByField('host');
        } else if (host && host.config.tokens) {
            this.config.hostStr = this.extractStringValue(host.config.tokens, '.');
        }

        if (!path) {
            this.safeSetNewChildByField('path');
        } else if (path && path.config.tokens) {
            const value = this.extractStringValue(path.config.tokens, '/');

            this.config.pathStr = !isEmpty(value) && value || '/';
        }
    }

    /**
     * @override
     * Convert host and path string values to tokenised params.
     */
    public modifyConfigDataBeforeSave(): void {
        const { hostStr, pathStr } = this.config;

        const rangeParser = this.getAjsDependency_('RangeParser');

        super.modifyConfigDataBeforeSave();

        if (hostStr) {
            this.config.host.updateConfig({
                type: URIParamType.URI_PARAM_TYPE_TOKENIZED,
                tokens: rangeParser.tokensStr2tokens(hostStr, '.'),
            });

            delete this.config.hostStr;
        } else {
            delete this.config.host;
        }

        if (pathStr) {
            this.config.path.updateConfig({
                type: URIParamType.URI_PARAM_TYPE_TOKENIZED,
                tokens: rangeParser.tokensStr2tokens(pathStr, '/'),
            });

            delete this.config.pathStr;
        } else {
            delete this.config.path;
        }
    }

    /**
     * Convert host and path tokens to formatted string value.
     */
    private extractStringValue(
        tokens: RepeatedMessageItem<MessageItem<IURIParamToken>>,
        separator: string,
    ): string {
        const rangeParser = this.getAjsDependency_('RangeParser');

        return reduce(tokens.config,
            function(base, token) {
                return `${base + rangeParser.token2str(token.config)}${separator}`;
            }, '').slice(0, -1);
    }
}
