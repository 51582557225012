<avi-dropdown-single-selected-value *ngIf="!multiple">
    <ng-template [ngTemplateOutlet]="prependTemplateRef"></ng-template>
    <span>{{ getName(value) }}</span>
</avi-dropdown-single-selected-value>
<div
    *ngIf="multiple"
    class="avi-collection-dropdown-selected-values-container__multiple-selected-value-container"
>
    <ng-template [ngTemplateOutlet]="prependTemplateRef"></ng-template>
    <cds-tag
        *ngFor="let selectedValue of $any(value); trackBy: trackByValue"
        class="avi-collection-dropdown-selected-values-container__multiple-selected-value"
        status="info"
        closable
        (click)="handleRemove($event, selectedValue)"
    >
        {{ getName(selectedValue) }}
    </cds-tag>
</div>
<ng-template #prependTemplateRef >
    <span
        *ngIf="hasPrepend"
        class="avi-dropdown-selected-values-container__selected-value-prepend"
    >
        {{ prepend }}
    </span>
</ng-template>
