/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module AviRadioModule
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrCheckboxModule, ClrRadioModule } from '@clr/angular';
import { TooltipModule } from 'ng/modules/tooltip/tooltip.module';

import {
    AviRadioComponent,
    AviRadioContainerComponent,
} from './components';

@NgModule({
    declarations: [
        AviRadioComponent,
        AviRadioContainerComponent,
    ],
    exports: [
        AviRadioComponent,
        AviRadioContainerComponent,
    ],
    imports: [
        ClrCheckboxModule,
        CommonModule,
        FormsModule,
        TooltipModule,
        ClrRadioModule,
    ],
})
export class AviRadioModule {}
