/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Policies module
 * @module PoliciesModule
 * @preferred
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    BotMappingRuleMatchTargetConfigItem,
    dnsMxRecordsComponentOptions,
    dnsTxtRecordsComponentOptions,
    MatchTargetConfigItem,
    MATCH_TARGET_CONFIG_ITEM_TOKEN,
    NatAddrInfoConfigItem,
    NatMatchTargetConfigItem,
    NatPolicyActionConfigItem,
    NatPolicyCollection,
    NatPolicyItem,
    NatRuleConfigItem,
    PolicyActionConfigItem,
    PolicyExtendableConfigItem,
    PolicyMatchConfigItem,
    PolicyMatchOrActionWrapperConfigItem,
    PolicyRuleExtendableConfigItem,
    POLICY_ACTION_CONFIG_ITEM_TOKEN,
    POLICY_EXTENDABLE_CONFIG_ITEM_TOKEN,
    POLICY_MATCH_CONFIG_ITEM_TOKEN,
    POLICY_MATCH_OR_ACTION_WRAPPER_EXTENDABLE_CONFIG_ITEM_TOKEN,
    POLICY_RULE_EXTENDABLE_CONFIG_ITEM_TOKEN,
    PortRangeConfigItem,
    PORT_RANGE_CONFIG_ITEM_TOKEN,
} from '.';

import {
    BOT_MAPPING_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN,
    NAT_POLICY_COLLECTION_TOKEN,
} from './policies.tokens';

const policiesModuleName = 'avi/policies';
const policiesModule = angular.module(policiesModuleName);
const natModule = angular.module(`${policiesModuleName}/nat`);

const policyFactories = [
    {
        factory: BotMappingRuleMatchTargetConfigItem,
        name: BOT_MAPPING_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PolicyActionConfigItem,
        name: POLICY_ACTION_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PolicyMatchConfigItem,
        name: POLICY_MATCH_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PolicyMatchOrActionWrapperConfigItem,
        name: POLICY_MATCH_OR_ACTION_WRAPPER_EXTENDABLE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PolicyExtendableConfigItem,
        name: POLICY_EXTENDABLE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PolicyRuleExtendableConfigItem,
        name: POLICY_RULE_EXTENDABLE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: MatchTargetConfigItem,
        name: MATCH_TARGET_CONFIG_ITEM_TOKEN,
    },
    {
        factory: PortRangeConfigItem,
        name: PORT_RANGE_CONFIG_ITEM_TOKEN,
    },
];

const natPolicyFactories = [
    {
        factory: NatPolicyItem,
        name: 'NatPolicyItem',
    },
    {
        factory: NatPolicyCollection,
        name: NAT_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: NatRuleConfigItem,
        name: 'NatRuleConfigItem',
    },
    {
        factory: NatMatchTargetConfigItem,
        name: 'NatMatchTargetConfigItem',
    },
    {
        factory: NatPolicyActionConfigItem,
        name: 'NatPolicyActionConfigItem',
    },
    {
        factory: NatAddrInfoConfigItem,
        name: 'NatAddrInfoConfigItem',
    },
];

policyFactories.forEach(({ name, factory }) => {
    initAjsDependency(policiesModule, 'factory', name, factory);
});

const dnsRecordsComponents = [{
    name: 'dnsMxRecords',
    options: dnsMxRecordsComponentOptions,
}, {
    name: 'dnsTxtRecords',
    options: dnsTxtRecordsComponentOptions,
}];

natPolicyFactories.forEach(({ name, factory }) => {
    initAjsDependency(natModule, 'factory', name, factory);
});

dnsRecordsComponents.forEach(({ name, options }) => policiesModule.component(name, options));
