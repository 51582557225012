/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    IDNSConfiguration,
    IEmailConfiguration,
    ILinuxConfiguration,
    IMgmtIpAccessControl,
    INTPConfiguration,
    IPortalConfiguration,
    ISecureChannelConfiguration,
    ISnmpConfiguration,
    ITenantConfiguration,
} from 'generated-types';

import {
    IVsRefPosition,
// eslint-disable-next-line max-len
} from 'ng/modules/system/components/system-settings-modal/dns-services-grid/dns-services-grid.component';

import {
    IDnsVsRefObj,
    SystemConfig,
} from 'ajs/modules/system/factories/system-config.item.factory';

import * as l10n from './system-settings-modal.l10n';
import { SystemSettingsPageStore } from '../system-settings-page/system-settings-page.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-modal',
    templateUrl: './system-settings-modal.component.html',
})
export class SystemSettingsModalComponent {
    /**
     * System config instance.
     */
    @Input()
    public editable: SystemConfig;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Dns config.
     */
    public dnsConfig: IDNSConfiguration;

    /**
     * Ntp config.
     */
    public ntpConfig: INTPConfiguration;

    /**
     * Tenant config.
     */
    public tenantConfig: ITenantConfiguration;

    /**
     * Dns virtual service Refs.
     */
    public dnsVirtualServiceRefs: IDnsVsRefObj[];

    /**
     * Portal Configuration for controller.
     */
    public portalConfig: IPortalConfiguration;

    /**
     * Secure channel configuration for controller.
     */
    public secureChannelConfig: ISecureChannelConfiguration;

    /**
     * Allowed HMACs in system configuration.
     */
    public allowedSshHmacs: string[];

    /**
     * Allowed Ciphers in system configuration.
     */
    public allowedSshCiphers: string[];

    /**
     * Email SMTP config.
     */
    public emailSmtpConfig: IEmailConfiguration;

    public defaultEmailSmtpConfig: IEmailConfiguration;

    /**
     * Linux configuration of controller.
     */
    public linuxConfig: ILinuxConfiguration;

    /**
     * Snmp config for system settings.
     */
    public snmpConfig: ISnmpConfiguration;

    /**
     * Management IP access control configuration.
     */
    public mgmtIPAccessControl: IMgmtIpAccessControl;

    constructor(
        l10nService: L10nService,
        private readonly systemSettingsPageStore: SystemSettingsPageStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Initialize dns vs refs.
     */
    public ngOnInit(): void {
        this.setDnsVirtualServiceRefs();
        this.dnsConfig = this.editable.getDNSConfig();
        this.ntpConfig = this.editable.getNTPConfig();
        this.tenantConfig = this.editable.getTenantConfig();
        this.emailSmtpConfig = this.editable.getEmailConfig();
        this.defaultEmailSmtpConfig = this.editable.getDefaultEmailConfig();
        this.linuxConfig = this.editable.getLinuxConfiguration();
        this.snmpConfig = this.editable.getSnmpConfig();
        this.portalConfig = this.editable.getPortalConfig();
        this.secureChannelConfig = this.editable.getSecureChannelConfig();
        this.allowedSshHmacs = this.editable.getSshHmacs();
        this.allowedSshCiphers = this.editable.getSshCiphers();
        this.mgmtIPAccessControl = this.editable.getMgmtIPAccessControl();
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.editable.submit().then(() => {
            this.systemSettingsPageStore.reloadStore();
        });
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.editable.dismiss(true);
    }

    /**
     * Add dns vs in dns services.
     */
    public addDnsVs(): void {
        this.editable.addDnsVs();
        this.setDnsVirtualServiceRefs();
    }

    /**
     * Remove dns vs from dns services.
     */
    public removeDnsVs(dnsVs: IDnsVsRefObj): void {
        this.editable.removeDnsService(dnsVs);
        this.setDnsVirtualServiceRefs();
    }

    /**
     * Update dns vs in dns services.
     */
    public updateDnsVs(vsRefPosition: IVsRefPosition): void {
        this.editable.updateDnsVs(vsRefPosition.vsRef, vsRefPosition.index);
        this.setDnsVirtualServiceRefs();
    }

    /**
     * Add ntp authentication key.
     */
    public addNtpAuthenticationKey(): void {
        this.editable.addEmptyNtpAuthenticationKey();
    }

    /**
     * Add ntp server.
     */
    public addNtpServer(): void {
        this.editable.addEmptyNtpServer();
    }

    /**
     * Get dns vs list in dns services.
     */
    private setDnsVirtualServiceRefs(): void {
        this.dnsVirtualServiceRefs = this.editable.getDnsVirtualServiceRefs();
    }
}
