/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { Component, Type } from '@angular/core';
import { GeoDbMappingModalComponent } from 'ng/modules/security';
import { IGeoDBMapping } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './geo-db.l10n';
import { GeoDbMappingElementConfigItem } from '.';

/**
 * Ajs dependency token for GeoDBMappingConfigItem.
 */
export const GEO_DB_MAPPING_CONFIG_ITEM_TOKEN = 'GeoDBMappingConfigItem';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TGeoDbMappingPartial = Omit<IGeoDBMapping, 'elements'>;

interface IGeoDbMappingConfig extends TGeoDbMappingPartial {
    elements: RepeatedMessageItem<GeoDbMappingElementConfigItem>;
}

/**
 * @description
 *
 *   Geo DB Mapping Message Item.
 *
 * @author Rachit Aggarwal
 */
export class GeoDbMappingConfigItem
    extends withFullModalMixin(
        withEditChildMessageItemMixin<IGeoDbMappingConfig, typeof MessageItem>(MessageItem),
    )<IGeoDbMappingConfig> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'GeoDBMapping',
            windowElement: GeoDbMappingModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns mapping Elements list.
     */
    public get elements(): RepeatedMessageItem<GeoDbMappingElementConfigItem> {
        return this.config.elements;
    }

    /**
     * Method to add Geo Db mapping Element.
     */
    public addGeoDbMappingElement(): void {
        this.addChildMessageItem({
            field: 'elements',
        });
    }

    /**
     * Method to edit Geo Db mapping Element.
     */
    public editGeoDbMappingElement(
        geoDbMappingElementConfigItem: GeoDbMappingElementConfigItem,
    ): void {
        this.editChildMessageItem({
            field: 'elements',
            messageItem: geoDbMappingElementConfigItem,
        });
    }

    /**
     * Method to remove Geo Db mapping Element.
     */
    public removeGeoDbMappingElement(
        geoDbMappingElementConfigItem: GeoDbMappingElementConfigItem,
    ): void {
        this.elements.removeByMessageItem(geoDbMappingElementConfigItem);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addGeoDbMappingModalBreadcrumbTitle);
    }
}
