<!-- DNS Request -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="dnsRequestInfo$ | async"
    header="{{ l10nKeys.dnsRequestLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-dns-request></vs-log-cinematic-sub-section-dns-request>
</vs-log-cinematic-sub-section-wrapper>

<!-- DNS Response -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="dnsResponseInfo$ | async"
    header="{{ l10nKeys.dnsResponseLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-dns-response></vs-log-cinematic-sub-section-dns-response>
</vs-log-cinematic-sub-section-wrapper>
