/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { IEnumValue } from 'ajs/modules/core/services/schema-service/schema.types';
import { IDebugSeDataplane } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { ITrafficCaptureCard } from 'ng/modules/service-engine/types/service-engine.types';
import './traffic-capture-page-card.component.less';
import * as l10n from './traffic-capture-page-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Card component for SE Traffic capture.
 * @author Suraj Kumar
 */
@Component({
    selector: 'traffic-capture-page-card',
    templateUrl: './traffic-capture-page-card.component.html',
})
export class TrafficCapturePageComponentCard implements OnInit, OnChanges {
    /**
     * Event emitter for stop capture.
     */
    @Output()
    public onStopCapture = new EventEmitter<void>();

    /**
     * Event emitter for start capture.
     */
    @Output()
    public onStartCapture = new EventEmitter<void>();

    /**
     * Traffic capture configurations.
     */
    @Input()
    public configuration: ITrafficCaptureCard;

    /**
     * Check if traffic capture currently running.
     */
    @Input()
    public captureInProgress: boolean;

    /**
     * Check if stop capture process started for current capture.
     */
    @Input()
    public stopCaptureInProgress: boolean;

    /**
     * Selected types of capture string value.
     */
    public selectedTypesForCaptureTextValue: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly schemaService: SchemaService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setSelectedTypesToCaptureTextValue();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        this.setSelectedTypesToCaptureTextValue();
    }
    /**
     * Opens the traffic capture modal.
     */
    public onStartTrafficCapture(): void {
        this.onStartCapture.emit();
    }

    /**
     * It emits onStop for starting stop capture.
     */
    public onStopTrafficCapture(): void {
        this.onStopCapture.emit();
    }

    /**
     * Setter for string value of selected types to capture.
     */
    private setSelectedTypesToCaptureTextValue(): void {
        let selectedPacketCaptureTypesString = '';

        if (this.configuration.typesToCapture) {
            const selectedPacketCaptureTypesEnum =
                this.getSelectedPacketCaptureTypesEnum(
                    this.configuration.typesToCapture,
                );

            selectedPacketCaptureTypesEnum.forEach((element, index) => {
                const appendingSymbol =
                    index === selectedPacketCaptureTypesEnum.length - 1 ? '.' : ', ';

                selectedPacketCaptureTypesString += element.label + appendingSymbol;
            });
        }

        this.selectedTypesForCaptureTextValue = selectedPacketCaptureTypesString;
    }

    /**
     * Returns seleted Packet Capture Types enum.
     */
    private getSelectedPacketCaptureTypesEnum(
        selectedDebugSeDataplane: IDebugSeDataplane[],
    ): IEnumValue[] {
        const packetCaptureTypes = this.schemaService.getEnumValues('DebugSeDataplaneFlags');
        const selectedCaptureTypes: string[] = selectedDebugSeDataplane.map(({ flag }) => flag);

        const selectedPacketCaptureTypesEnum: IEnumValue[] = packetCaptureTypes.filter(
            ({ value }) => selectedCaptureTypes.includes(value),
        );

        return selectedPacketCaptureTypesEnum;
    }
}
