/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import { Injectable } from '@angular/core';

/**
 * @desc location related stateless service.
 *
 * @author Aravindh Nagarajan, Guru Prasad H K
 */
@Injectable({
    providedIn: 'root',
})
export class LocationService {
    /**
     * Reload page after removing characters after `#` in url.
     */
    public reloadWithoutHash(withSearch = false): void {
        const { location } = window;

        location.replace(this.getLocationWithoutHash(withSearch));
    }

    /**
     * Return location without hash.
     */
    private getLocationWithoutHash(withSearch = false): string {
        const { location } = window;

        const {
            origin,
            pathname,
            search,
        } = location;

        const url = `${origin}${pathname}`;

        if (withSearch && search) {
            return `${url}${search}`;
        }

        return url;
    }
}
