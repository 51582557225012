<full-modal-config
    modalTitle="{{ l10nKeys.geoDbMappingElementModalTitle | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="geo-db-mapping-element-modal"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ editable.messageType }}"
                            fieldName="attribute"
                            for="attribute"
                            required
                        >
                            {{ l10nKeys.attributeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="attribute"
                            [options]="attributeOptions"
                            [(ngModel)]="editable.config.attribute"
                            placeholder="{{ l10nKeys.attributePlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="values"
                        for="values"
                        required
                    >
                        {{ l10nKeys.valuesLabel | vtranslate }}
                    </label>
                    <avi-repeated-strings
                        id="values"
                        name="values"
                        placeholder="{{ l10nKeys.valuesPlaceholder | vtranslate }}"
                        objectType="{{ objectType }}"
                        fieldName="values"
                        [(ngModel)]="editable.config.values"
                        required
                    ></avi-repeated-strings>
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form> 
</full-modal-config>
