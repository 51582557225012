<div
    class="welcome-system-settings"
    provideParentForm
>
    <backup-passphrase
        name="backupPassphrase"
        [(ngModel)]="backupConfig.backupPassphrase"
    ></backup-passphrase>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="DNSConfiguration"
            fieldName="server_list"
            for="server_list"
        >
            {{ l10nKeys.dnsResolverLabel | vtranslate }}
        </label>
        <input
            id="server_list"
            class="sel-dns-address"
            name="server_list"
            [(ngModel)]="systemConfig.getDNSConfig().server_list"
            clrInput
            placeholder="000.000.000.000"
            parseIpList
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="DNSConfiguration"
            fieldName="search_domain"
            for="search_domain"
        >
            {{ l10nKeys.dnsSearchDomainLabel | vtranslate }}
        </label>
        <input
            id="search_domain"
            class="sel-dns-search-domain"
            name="search_domain"
            [(ngModel)]="systemConfig.getDNSConfig().search_domain"
            clrInput
            placeholder="{{ l10nKeys.dnsSearchDomainFieldPlaceholder | vtranslate }}"
            regexPattern="listOfHostnamesWithSpaces"
        />
    </avi-input-container>
</div>
