/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'performance-chart-legend';
const prefix = `${moduleName}.${componentName}`;

export const showHideLegendTitle = `${prefix}.showHideLegendTitle`;

export const ENGLISH = {
    [showHideLegendTitle]: 'Show/Hide Legend',
};
