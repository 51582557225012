/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DataGridModule
 */

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { some } from 'underscore';

import { L10nService } from '@vmw/ngx-vip';

import type {
    IAviDiffGridConfig,
    IAviDiffGridRow,
    TDiffType,
} from './avi-diff-grid.types';
import './avi-diff-grid.component.less';

import * as l10n from './avi-diff-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Grid component handling text diffs. The left and right fields of each row reprensents
 *     properties that are aligned with diff comparison info between them.
 *
 *     Typically it needs two pipes in between from the raw data to producing diff rows:
 *         1. A pipe that transforms the raw data to a diff source pair objects that contains two
 *            source objects with their properties ready for diff checking. And this custom pipe
 *            must implements IAviDiffGridSourcePairTransform.
 *         2. aviDiffGridRows pipe that transforms the source pair data to presentational diff rows.
 *            The rows contain final results of the obejct comparison and is ready to be used by the
 *            diff grid to show diffs with different background colors that reflecting diff types
 *            (new, edit, deleted.)
 *
 *     This component is presentational only. It doesn't carry any raw data comparison process to
 *     recognize diffs.
 *
 * @example
 *
 *     <avi-diff-grid
 *         [config]='responseDiffGridConfig'
 *         [rows]='httpResponseHeaders$ |
 *                 async |
 *                 logHttpHeadersDiffSourcePair:'response' |
 *                 aviDiffGridRows'
 *     ></avi-diff-grid>
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-diff-grid',
    templateUrl: './avi-diff-grid.component.html',
})
export class AviDiffGridComponent implements OnInit, OnChanges {
    /**
     * Grid configuration object containing left and right field info, getUniqueRowId, and etc.
     */
    @Input()
    public config: IAviDiffGridConfig;

    @Input()
    public rows: IAviDiffGridRow[] = [];

    /**
     * Indicator to decide grid expansion. The grid is expanded by default.
     */
    public isGridExpanded = true;

    /**
     * If true, show the expander icon for grid content.
     * The grid is not opted for shrinking by default.
     */
    public showGridExpander = false;

    /**
     * Message to be shown when data is empty.
     */
    public placeHolderMessage = '';

    /**
     * Width in pixels to be used for icon cells.
     */
    public readonly iconWidth = 40;

    /**
     * Indicate whether the left part of the grid has no data.
     */
    public leftColumnEmpty = false;

    /**
     * Indicate whether the right part of the grid has no data.
     */
    public rightColumnEmpty = false;

    /**
     * Indicate whether there's no data being passed to the grid.
     */
    public isGridEmpty = true;

    public constructor(private l10nService: L10nService) {
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setPlaceholderMessage();
        this.showGridExpander = Boolean(this.config.isGridExpandable);
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { rows } = changes;

        if (rows) {
            this.isGridEmpty = !this.rows || this.rows.length === 0;
            this.leftColumnEmpty = !this.rows || !some(this.rows, ({ left }) => Boolean(left));
            this.rightColumnEmpty = !this.rows || !some(this.rows, ({ right }) => Boolean(right));
        }
    }

    /**
     * trackBy function for row data.
     */
    public trackByRowIndex = (index: number, row: IAviDiffGridRow): number => {
        return index;
    };

    /**
     * Toggle grid expansion state.
     */
    public toggleGridExpanded(): void {
        this.isGridExpanded = !this.isGridExpanded;
    }

    /**
     * Dynamically generate style class with modifier according to cell position and row type.
     * Left side cell and right side cell background colors of the same row can differ based on what
     * type the row is.
     */
    // eslint-disable-next-line class-methods-use-this
    public getCellClass(cellPosition: 'left' | 'right', rowType: TDiffType): string {
        // no need to add any styles for a non-diff row, and the following block serves as a default
        // guard since this function won't be called under that circumstance
        if (rowType === 'no-diff') {
            return '';
        }

        return `avi-diff-grid__${cellPosition}-cell--${rowType}`;
    }

    /**
     * Returns a placeholder message to be displayed if there are no items to display.
     */
    private setPlaceholderMessage(): void {
        const { l10nService } = this;

        this.placeHolderMessage = this.config.layout?.placeholderMessage ||
            l10nService.getMessage(l10nKeys.defaultEmptyListPlaceholderMessage);
    }
}
