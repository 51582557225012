<div
    class="full-modal-config__section ipam-dns-infoblox-profile-config"
    provideParentForm
    [invalidateForm]="!connected"
>
    <h3>{{ l10nKeys.infobloxHeader | vtranslate }}</h3>
    <fieldset class="full-modal-config__fieldset">
        <h6 class="full-modal__section-header">{{ globalL10nKeys.credentialsLabel | vtranslate }}</h6>
        <credentials-verification
            [editCredentialsConfig]="editInfobloxProfileCredentialsConfig"
            [isEditing]="isEditing"
        >
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="ip_address"
                    for="ip_address"
                >
                    {{ l10nKeys.ipv4AddressOrFqdnLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="ip_address"
                    placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                    name="ip_address"
                    [(ngModel)]="editable.config.ip_address.address"
                    readonly
                />
            </avi-input-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="ip6_address"
                    for="ip6_address"
                >
                    {{ globalL10nKeys.ipv6AddressInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="ip6_address"
                    placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                    name="ip6_address"
                    [(ngModel)]="editable.config.ip6_address.address"
                    readonly
                />
            </avi-input-container>
            <avi-input-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="network_view"
                    for="network_view"
                >
                    {{ l10nKeys.networkViewInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="network_view"
                    placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                    name="network_view"
                    [(ngModel)]="editable.config.network_view"
                    readonly
                />
            </avi-input-container>
            <avi-input-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX_DNS">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="dns_view"
                    for="dns_view"
                >
                    {{ l10nKeys.dnsViewInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="dns_view"
                    placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                    name="dns_view"
                    [(ngModel)]="editable.config.dns_view"
                    readonly
                />
            </avi-input-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="username"
                            for="username"
                        >
                            {{ globalL10nKeys.usernameLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="username"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="username"
                            [(ngModel)]="editable.config.username"
                            readonly
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="password"
                            for="password"
                        >
                            {{ globalL10nKeys.passwordLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="password"
                            type="password"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="password"
                            [(ngModel)]="editable.config.password"
                            readonly
                        />
                    </avi-input-container>
                </div>
            </div>
        </credentials-verification>
    </fieldset>

    <h6 class="full-modal__section-header">{{ l10nKeys.settingsSectionHeader | vtranslate }}</h6>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="wapi_version"
            for="wapi_version"
            required
        >
            {{ l10nKeys.wapiVersionInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="wapi_version"
            placeholder="{{ l10nKeys.wapiVersionInputPlaceholder | vtranslate }}"
            name="wapi_version"
            [(ngModel)]="editable.config.wapi_version"
            required
        />
    </avi-input-container>

    <div
        class="full-modal-config__form-control-row"
        *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX"
    >
        <div class="full-modal-config__form-control">
            <infoblox-usable-subnets-grid
                [usableSubnets]="editable.config.usable_alloc_subnets"
                [connected]="connected"
                [availableSubnetHash]="availableSubnetHash"
                (onAddUsableSubnet)="addUsableSubnet()"
                (onDeleteUsableSubnet)="removeUsableSubnet($event)"
                (onUsableSubnetChange)="setIsUsableSubnetInvalid($event)"
                [invalidateForm]="isUsableSubnetInvalid"
            ></infoblox-usable-subnets-grid>
        </div>
    </div>

    <div
        class="full-modal-config__form-control-row"
        *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX_DNS"
    >
        <div class="full-modal-config__form-control">
            <infoblox-usable-domains-grid
                [usableDomains]="editable.usableDomains"
                [connected]="connected"
                [domainList]="domainList"
                (onAddUsableDomain)="addUsableDomain()"
                (onDeleteUsableDomain)="removeUsableDomain($event)"
            ></infoblox-usable-domains-grid>
        </div>
    </div>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <custom-params-grid
                [paramsList]="editable.config.extensible_attributes"
                gridTitle="{{ l10nKeys.extensibleAttributesGridHeader | vtranslate : editable.config.extensible_attributes.count }}"
            ></custom-params-grid>
        </div>
    </div>
</div>
