/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing health score related components.
 * @module HealthScoreModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { VIPModule } from '@vmw/ngx-vip';
import { ClrSpinnerModule } from '@clr/angular';
import { TooltipModule } from 'ng/modules/tooltip/tooltip.module';
import { SharedModule } from 'ng/shared/shared.module';

import {
    AviAreaChartComponent,
    AviHealthScoreBarComponent,
    AviHealthScoreBarsComponent,
    AviHealthScoreComponent,
    AviHealthScoreDownComponent,
    AviHealthScoreErrorComponent,
    AviHealthScoreFooterComponent,
    HealthScoreClassPipe,
    PoolHealthScoreBodyComponent,
} from '.';

const components = [
    AviAreaChartComponent,
    AviHealthScoreComponent,
    AviHealthScoreBarComponent,
    AviHealthScoreBarsComponent,
    AviHealthScoreDownComponent,
    AviHealthScoreErrorComponent,
    PoolHealthScoreBodyComponent,
    AviHealthScoreFooterComponent,
];

const pipes = [
    HealthScoreClassPipe,
];

@NgModule({
    declarations: [
        ...components,
        ...pipes,
    ],
    exports: [
        ...components,
        HealthScoreClassPipe,
    ],
    imports: [
        CommonModule,
        ClrSpinnerModule,
        SharedModule,
        OverlayModule,
        TooltipModule,
        VIPModule.forChild(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HealthScoreModule {}
