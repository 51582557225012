<div class="vs-log-cinematic-section-application">
    <ng-container *ngrxLet="applicationInfo$ as applicationInfo">
        <!-- Left column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.http2StreamIDLabel | vtranslate }}
            </div>
            <span
                *ngIf="applicationInfo.http2_stream_id as streamId; else na"
                vsLogFilter
                property="http2_stream_id"
                [value]="streamId"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ streamId }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.serverPushInitiatedLabel | vtranslate }}</div>
            <span
                *ngIf="applicationInfo.server_push_initiated !== undefined; else na"
                vsLogFilter
                property="server_push_initiated"
                [value]="applicationInfo.server_push_initiated"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ applicationInfo.server_push_initiated }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.serverPushedRequestLabel | vtranslate }}</div>
                <span
                    *ngIf="applicationInfo.server_pushed_request !== undefined; else na"
                    vsLogFilter
                    property="server_pushed_request"
                    [value]="applicationInfo.server_pushed_request"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ applicationInfo.server_pushed_request }}
                </span>
        </div>

        <!-- Middle column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.cacheHitLabel | vtranslate }}
            </div>
            <ng-container *ngIf="applicationInfo.cache_hit !== undefined; else na">
                <span
                    vsLogFilter
                    property="cache_hit"
                    [value]="applicationInfo.cache_hit"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ applicationInfo.cache_hit }}
                </span>
            </ng-container>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.compressionLabel | vtranslate }}</div>
            <ng-container *ngIf="(applicationInfo$ | async | logResponseCompressionInfo) as compressionInfo; else na">
                <span
                    *ngIf="compressionInfo.compressed"
                    vsLogFilter
                    property="compression"
                    [value]="applicationInfo.compression"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ compressionInfo.compressed }}
                    <span
                        vsLogFilterList
                        property="compression_percentage"
                        [value]="compressionInfo.compressionPercentage"
                        displayValue="{{ compressionInfo.compressionPercentage }} %"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        ({{ l10nKeys.compressedPercentageLabel | vtranslate : compressionInfo.compressionPercentage }})
                    </span>
                </span>
                <span
                    *ngIf="!compressionInfo.compressed"
                    vsLogFilter
                    property="compression"
                    [value]="applicationInfo.compression"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ compressionInfo.noCompressionReason }}
                </span>
            </ng-container>
        </div>

        <!-- Right column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.numberOfServersTriedLabel | vtranslate }}
            </div>
            <span
                *ngIf="applicationInfo.servers_tried !== undefined; else na"
                vsLogFilterList
                property="servers_tried"
                [value]="applicationInfo.servers_tried"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ applicationInfo.servers_tried }}
            </span>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.requestLabel | vtranslate }}</div>
            <span
                *ngIf="applicationInfo.method as method; else na"
                vsLogFilter
                property="method"
                [value]="method"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ method }}
            </span>
        </div>
    </ng-container>
</div>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
