<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleRadius | vtranslate }}</h3>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorRadius }}"
                    required
                    for="username"
                    fieldName="username"
                >
                    {{ globalL10nKeys.usernameLabel | vtranslate }}
                </label>
                <input
                    id="username"
                    name="username"
                    clrInput
                    required
                    placeholder="{{ globalL10nKeys.usernamePlaceholder | vtranslate }}"
                    [(ngModel)]="radiusMonitor.config.username"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorRadius }}"
                    required
                    for="password"
                    fieldName="password"
                >
                    {{ globalL10nKeys.passwordLabel | vtranslate }}
                </label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    clrInput
                    required
                    placeholder="{{ globalL10nKeys.passwordPlaceholder | vtranslate }}"
                    [(ngModel)]="radiusMonitor.config.password"
                />
            </avi-input-container>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorRadius }}"
                    required
                    for="shared-secret"
                    fieldName="shared_secret"
                >
                    {{ l10nKeys.sharedSecretInputLabel | vtranslate }}
                </label>
                <input
                    type="password"
                    id="shared-secret"
                    name="shared-secret"
                    clrInput
                    required
                    placeholder="{{ l10nKeys.sharedSecretInputPlaceholder | vtranslate }}"
                    [(ngModel)]="radiusMonitor.config.shared_secret"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    for="monitor-port"
                    [required]="editable.config.is_federated"
                >
                    {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    type="number"
                    id="monitor-port"
                    name="monitor-port"
                    [(ngModel)]="editable.config.monitor_port"
                    configFieldInputValidation
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
                    [disabled]="!!editable.id"
                    [required]="editable.config.is_federated"
                />
            </avi-input-container>
        </div>
    </div>
</div>
