/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const componentName = 'c-grid-table-header-cell-resize-handle';

class GridColumnResizeHandleController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        const { $element: $elem } = this;

        $elem.addClass(componentName);

        //to prevent column sorting on click
        $elem[0].addEventListener('click', e => e.stopPropagation(), true);

        $elem.on('mousedown', e => this.gridTableHeaderCellCtrl.startCollResize(e));
    }
}

GridColumnResizeHandleController.$inject = [
       '$element',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCellResizeHandle
 * @description
 *
 *     Forwards mousedown event to the {@link gridTableHeaderCell} component controller.
 *
 * @author Alex Malitsky
 **/
angular.module('avi/component-kit/grid').component('gridTableHeaderCellResizeHandle', {
    require: {
        gridTableHeaderCellCtrl: '^^gridTableHeaderCell',
    },
    controller: GridColumnResizeHandleController,
});
