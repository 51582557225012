<div class="waf-application-rules-list-header"> 
    <div class="waf-application-rules-list-header__container">
        <cds-icon
            [hidden]="status !== ApplicationRulesDBDisplayStatus.ACTIVE"
            class="waf-application-rules-list-header__icon"
            shape="circle"
            size="24"
            status="success"
            solid
        ></cds-icon>
        <avi-tooltip-icon-with-content-wrapper
            shape="circle"
            size="24"
            class="waf-application-rules-list-header__icon"
            [hidden]="status !== ApplicationRulesDBDisplayStatus.ERROR"
            status="danger"
            [solid]="true"
        >
            <div class="waf-application-rules-list-header__error-container clr-wrapper">
                <span class="waf-application-rules-list-header__error-message">
                    {{ error | aviBackendErrorMsg }}
                </span>
            </div>
        </avi-tooltip-icon-with-content-wrapper>
        <h2>
            {{ l10nKeys.applicationRulesTitle | vtranslate }}
        </h2>
    </div>
    <avi-alert-group 
        alertType="lightweight"
        [hyperlink]="LINK"
        deprecationLabel="{{ l10nKeys.deprecationWarningLabel | vtranslate }}"
    ></avi-alert-group>
    <!-- <br> -->
    <avi-alert-group
        class="waf-application-rules-list-header__alert"
        *ngIf="status === ApplicationRulesDBDisplayStatus.UNREGISTERED"
        alertType="info"
    >
        <span>{{ l10nKeys.registerControllerMessage | vtranslate }}</span>
        <cds-alert-actions>
            <cds-button (click)="onEditPulseSettingsHandler()">
                {{ l10nKeys.registerControllerButtonLabel | vtranslate }}
            </cds-button>
        </cds-alert-actions>
    </avi-alert-group>
    <avi-alert-group
        class="waf-application-rules-list-header__alert"
        *ngIf="status === ApplicationRulesDBDisplayStatus.DISABLED"
        alertType="info"
    >
        <span>{{ l10nKeys.enableAppRulesOptInMessage | vtranslate }}</span>
        <cds-alert-actions>
            <cds-button (click)="onEditPulseSettingsHandler()">
                {{ l10nKeys.editCloudServicesSettingsButtonLabel | vtranslate }}
            </cds-button>
        </cds-alert-actions>
    </avi-alert-group>
</div>
