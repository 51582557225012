/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CloudConnectorUserModule
 */

import { Injector } from '@angular/core';

import {
    CloudConnectorUserCollection,
    CLOUD_CONNECTOR_USER_COLLECTION,
} from 'ajs/modules/cloud-connector-user/factories/cloud-connector-user.collection.factory';

const $injector = '$injector';

export const cloudConnectorUserCollectionProvider = {
    deps: [$injector],
    provide: CloudConnectorUserCollection,
    useFactory(injector: Injector): typeof CloudConnectorUserCollection {
        return injector.get(CLOUD_CONNECTOR_USER_COLLECTION);
    },
};
