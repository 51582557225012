<full-modal-preview>
    <full-modal-preview_container>
        <div class="my-account-preview clr-wrapper">
            <preview-field>
                <preview-field_label>{{ l10nKeys.tenantLabel | vtranslate }}</preview-field_label>
                <preview-field_val>{{ tenantName }}</preview-field_val>
            </preview-field>
        </div>
    </full-modal-preview_container>
</full-modal-preview>
