/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isNaN, isString } from 'underscore';

interface IRange {
    value: number,
    dimension: 'h' | 'm' | 's' | 'ms'
}

export interface IMsToStringChunk {
    value: IRange['value'] | 'N/A',
    dimension: IRange['dimension'] | ''
}

const commaRegex = /,/g;

const ranges: IRange[] = [
    {
        value: 60 * 60 * 1000,
        dimension: 'h',
    },
    {
        value: 60 * 1000,
        dimension: 'm',
    },
    {
        value: 1000,
        dimension: 's',
    },
    {
        value: 1,
        dimension: 'ms',
    },
];

/**
 * Converts number of ms to human readable string like "1h 25min 4s 709ms" represented in chunks
 * (objects) with "value" and "dimension" properties.
 * @param timeInMs - Either number of ms or string like '3,000,005'.
 * @param omitRem - If true is passed will return only two most significant values.
 */
export function msToStringChunks(timeInMs: string | number, omitRem = false): IMsToStringChunk[] {
    if (isString(timeInMs)) {
        if (timeInMs.indexOf(',') !== -1) {
            timeInMs = timeInMs.replace(commaRegex, '');
        }

        timeInMs = parseFloat(timeInMs);
    }

    timeInMs = Math.round(timeInMs);

    const chunks: IMsToStringChunk[] = [];

    // special case for zero
    if (isNaN(timeInMs)) {
        chunks.push({
            value: 'N/A',
            dimension: '',
        });
    } else if (!timeInMs) {
        chunks.push({
            value: 0,
            dimension: 'ms',
        });
    } else {
        let i = 0;

        while (i < ranges.length && (!omitRem || chunks.length < 2)) {
            const {
                value: range,
                dimension,
            } = ranges[i];

            i++;

            if (timeInMs >= range) {
                chunks.push({
                    value: Math.floor(timeInMs / range),
                    dimension,
                });

                timeInMs %= range;
                // doesn't skip zero values once first match is found
            } else if (chunks.length) {
                chunks.push({
                    value: 0,
                    dimension,
                });
            }
        }
    }

    return chunks;
}
