<div
    provideParentForm
    class="auth-rule-custom-mapping"
>
    <avi-dropdown-button
        [actions]="addDropdownActions"
        [position]="actionPosition"
        size="sm"
    >
        {{ l10nKeys.addButtonLabel | vtranslate }}
    </avi-dropdown-button>
    <avi-fieldset
        [hidden]="editable.hasAnyCustomMapping()"
        [hideContent]="true"
        class="auth-rule-custom-mapping__fieldset"
    >
        <avi-fieldset_header class="auth-rule-custom-mapping__header">
            <div class="auth-rule-custom-mapping__header-text">
                {{ l10nKeys.noEntriesLabel | vtranslate }}
            </div>
        </avi-fieldset_header>
    </avi-fieldset>
    <div class="auth-rule-custom-mapping__mapping-list-container">
        <ng-template #mappingListContainerRef></ng-template>
    </div>
</div>
