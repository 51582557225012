<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    [item]="editable"
    [form]="form"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        [objectType]="objectType"
                        fieldName="description"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        name="description"
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.description"
                    ></textarea>
                </avi-textarea-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ globalL10nKeys.typeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="authProfileTypeOptions"
                            [(ngModel)]="editable.config.type"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.typeDropdownPlaceholder | vtranslate }}"
                            [disabled]="editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleRules | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleRules | vtranslate }}</h3>

                <auth-mapping-rules-grid
                    [authMappingRules]="editable.mappingRules"
                    [disableAddButton]="!editable.type"
                    (onAddAuthMappingRule)="addAuthMappingRule()"
                    (onEditAuthMappingRule)="editAuthMappingRule($event)"
                    (onDeleteAuthMappingRule)="deleteAuthMappingRule($event)"
                    (onRowOrderChange)="handleAuthMappingRuleGridRowReorder($event)"
                ></auth-mapping-rules-grid>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
