/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CinematicModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';
import { Auth } from 'ajs/modules/core/services';
import { ObservableList } from 'ng/shared/utils/observable-list.utils';

export interface IAviCinematicProps {
    id: string;
    component: Type<Component>;
    componentProps?: Record<string, any>;
}

// Token used when registering as a downgraded service in AJS.
export const CINEMATIC_SERVICE_TOKEN = 'cinematicService';

/**
 * Service for adding and removing cinematic views.
 * @author Zhiqian Liu
 */
@Injectable({
    providedIn: 'root',
})
export class CinematicService extends ObservableList<IAviCinematicProps, string> {
    constructor(private auth: Auth) {
        super();

        // remove all cinematic views when logging out
        auth.triggerLogout$.subscribe(() => this.removeAll());
    }

    protected getID(item: IAviCinematicProps): string {
        return item.id;
    }
}
