/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-group-unit-cards';
const prefix = `${moduleName}.${componentName}`;

export const poolGroupNameHeader = `${prefix}.poolGroupNameHeader`;
export const poolGroupPriorityHeader = `${prefix}.poolGroupPriorityHeader`;
export const emptyPoolListMessage = `${prefix}.emptyPoolListMessage`;

export const ENGLISH = {
    [poolGroupNameHeader]: 'Pool Group: <strong>{0}</strong>',
    [poolGroupPriorityHeader]: 'Priority: <strong>{0}</strong>',
    [emptyPoolListMessage]: 'No Pools configured.',
};
