<div class="ssl-certificate-grid-name-column__missing-certificate-issuer-icon-wrapper">
    <span>
        <missing-cert-issuer-icon
            *ngIf="missingCertIssuerName"
            [missingCertIssuerName]="missingCertIssuerName"
        >
        </missing-cert-issuer-icon>
    </span>
    <span
        avi-tooltip
        [positionsPriority]="tooltipPositions"
    >
        {{ certificate.config.name }} 
        <ng-template #aviTooltipContent>
            <avi-tooltip-content>
                <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
            </avi-tooltip-content> 
        </ng-template>
    </span>
</div>

<ng-template #tooltip>
    <h3>{{ l10nKeys.sslCertChainTooltipLabel | vtranslate }}</h3>
    <ng-container>
        {{ certificate.certificateAvailability }}
        <ng-container>
            <div 
                *ngFor="let cert of certificate.config.ca_certs?.config; let i = index;"
                style="margin-left:{{ i * 35 + 30 }}px"
            >
                <cds-icon shape="child-arrow"></cds-icon>
                {{ cert.config?.name }}
            </div>
        </ng-container>
    </ng-container>
</ng-template>
