/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    AuthenticationRuleConfigItem,
    AuthorizationRuleConfigItem,
    AuthProfileTypeBySsoPolicy,
    SsoPolicyItem,
    SSOPolicyTypes,
} from 'ajs/modules/sso-policy/factories';

import {
    AuthenticationPolicy,
    SSOPolicy,
} from 'object-types';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { IRowReorder } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import * as l10n from './sso-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for SSOPolicy Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'sso-policy-modal',
    templateUrl: './sso-policy-modal.component.html',
})
export class SsoPolicyModalComponent implements OnInit, OnDestroy {
    /**
     * SsoPolicyItem instance.
     */
    @Input()
    public editable: SsoPolicyItem;

    /**
     * Enum SSOPolicyTypes to access in template.
     */
    public readonly ssoPolicyTypes = SSOPolicyTypes;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectTypes used in template.
     */
    public readonly objectTypes = {
        [SSOPolicy]: SSOPolicy,
        [AuthenticationPolicy]: AuthenticationPolicy,
    };

    /**
     * Type dropdown options.
     */
    public ssoPolicyTypeOptions: IAviDropdownOption[] = [];

    /**
     * AuthProfileCollection collection instance.
     */
    // TODO: Once AuthProfileCollection is converted to TS, update the type.
    public readonly authProfileCollection: Collection;

    constructor(
        schemaService: SchemaService,
        l10nService: L10nService,
        @Inject('AuthProfileCollection')
        AuthProfileCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const ssoPolicyTypes = schemaService.getEnumValues('SSOPolicyType');

        this.ssoPolicyTypeOptions = createOptionsFromEnumProps(ssoPolicyTypes);

        this.authProfileCollection = new AuthProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.onTypeChange(false);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.authProfileCollection.destroy();
    }

    /**
     * Fires on SSO-Policy Type change.
     * Sets params in authProfileCollection according to the type.
     * clearAuthProfile should be false for the first invocation (from OnInit).
     */
    public onTypeChange(clearAuthProfile = true): void {
        const authProfileType = AuthProfileTypeBySsoPolicy[this.editable.config.type];
        const { authProfileCollection } = this;

        authProfileCollection.setParams({
            type: authProfileType,
        });

        authProfileCollection.setDefaultItemConfigProps({
            type: authProfileType,
        });

        if (clearAuthProfile) {
            this.editable.authenticationPolicy.clearDefaultAuthProfile();
        }
    }

    /**
     * Adds new authentication rule.
     */
    public addAuthenticationRule(): void {
        this.editable.authenticationPolicy.addAuthenticationRule();
    }

    /**
     * Edits an authentication rule.
     */
    public editAuthenticationRule(rule: AuthenticationRuleConfigItem): void {
        this.editable.authenticationPolicy.editAuthenticationRule(rule);
    }

    /**
     * Deletes an authentication rule.
     */
    public deleteAuthenticationRule(rule: AuthenticationRuleConfigItem): void {
        this.editable.authenticationPolicy.authRules.removeByMessageItem(rule);
    }

    /**
     * Handles authn_grid reorder event.
     * Update array index as well as `index` property.
     */
    public handleAuthnRuleGridRowReorder({ previousIndex, currentIndex }: IRowReorder): void {
        this.editable.authenticationPolicy.authRules.moveByIndex(previousIndex, currentIndex);
    }

    /**
     * Adds new Authorization rule.
     */
    public addAuthorizationRule(): void {
        this.editable.authorizationPolicy.addAuthorizationRule(this.editable.type);
    }

    /**
     * Edits an Authorization rule.
     */
    public editAuthorizationRule(rule: AuthorizationRuleConfigItem): void {
        this.editable.authorizationPolicy.editAuthorizationRule(rule, this.editable.type);
    }

    /**
     * Deletes an authorization rule.
     */
    public deleteAuthorizationRule(rule: AuthorizationRuleConfigItem): void {
        this.editable.authorizationPolicy.authRules.removeByMessageItem(rule);
    }

    /**
     * Handles authz_grid reorder event.
     * Update array index as well as `index` property.
     */
    public handleAuthzRuleGridRowReorder({ previousIndex, currentIndex }: IRowReorder): void {
        this.editable.authorizationPolicy.authRules.moveByIndex(previousIndex, currentIndex);
    }
}
