/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { OCSPResponderUrlAction } from 'generated-types';

import { Certificate } from 'ajs/modules/security';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './ssl-tls-certificate-ocsp.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for SSL TLS Certificate OCSP section.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-tls-certificate-ocsp',
    templateUrl: './ssl-tls-certificate-ocsp.component.html',
})
export class SslTlsCertificateOcspComponent implements AfterViewInit {
    /**
     * Certificate Config item.
     */
    @Input()
    public editable: Certificate;

    /**
     * Template ref for URL grid row.
     */
    @ViewChild('responderUrlFromCertificateTemplateRef')
    public responderUrlFromCertificateTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of OCSPResponderUrlAction enum.
     */
    public readonly ocspResponderUrlActionEnumHash = {
        OCSP_RESPONDER_URL_FAILOVER: OCSPResponderUrlAction.OCSP_RESPONDER_URL_FAILOVER,
        OCSP_RESPONDER_URL_OVERRIDE: OCSPResponderUrlAction.OCSP_RESPONDER_URL_OVERRIDE,
    };

    /**
     * OCSPConfig object type.
     */
    public readonly objectType = 'OCSPConfig';

    /**
     * Grid config of OCSP Responder URL list from certificate.
     */
    public ocspResponderUrlListFromCertsGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.ocspResponderUrlListFromCertsGridConfig = {
            fields: [{
                id: 'ocsp-responder-url-from-certs',
                label: this.l10nService.getMessage(l10nKeys.columnTitleUrl),
                transform(url: string) {
                    return url;
                },
            }],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Initializes an OCSP Config Message Item if none is found.
     */
    public handleToggleEnablingOcspStapling(): void {
        if (this.editable.enableOcspStapling) {
            this.editable.setOcspConfig();
        }
    }
}
