/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Inject,
    Injectable,
    Injector,
    ProviderToken,
} from '@angular/core';
import { pick, reduce } from 'underscore';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { SchemaService, TPbMessageName } from 'ajs/modules/core/services/schema-service';

type TMessageItem = typeof MessageItem;

export interface IMessageMapProps {
    objectType: TPbMessageName;
    ConfigItemClass: TMessageItem;
    isRepeated: boolean;
}

export type IMessageMap = Record<string, IMessageMapProps>;

/**
 * Filters out a messageMap by a list of fields.
 */
export function filterMessageMap(messageMap: IMessageMap, fields: string[]): IMessageMap {
    return pick(messageMap, fields);
}

/**
 * @constructor
 * @memberOf module:avi/core
 * @description
 *     Service for creating a messageMap for MessageItems/ObjectTypeItems, which contains fields
 *     that are messages and their objectType and constructor functions.
 */
@Injectable({
    providedIn: 'root',
})
export class MessageMapService {
    constructor(
        private readonly injector: Injector,
        @Inject('$injector')
        private readonly $injector: ng.auto.IInjectorService,
        private readonly schemaService: SchemaService,
    ) {
    }

    /**
     * Creates a messageMap for a MessageItem, which is a hash of the properties within the
     * MessageItem's config that are messages to their MessageMapProps.
     */
    public createMessageMap(objectType: string): IMessageMap {
        /**
         * To store fields of message.
         */
        let messageFields;

        try {
            messageFields = this.schemaService.getMessageFields(objectType);
        } catch (error) {
            messageFields = {};
        }

        const InjectedMessageItem = this.$injector.get('MessageItem');

        return reduce(messageFields, (messageMap, messageFieldProp, field) => {
            const { objectType: childObjectType } = messageFieldProp;

            messageMap[field] = {
                ...messageFieldProp,
                ConfigItemClass: this.getConfigItemClass(childObjectType) || InjectedMessageItem,
            };

            return messageMap;
        }, {});
    }

    /**
     * Gets the MessageItem constructor function based on the `${objectType}ConfigItem` naming
     * scheme.
     */
    private getConfigItemClass(objectType: string): TMessageItem | undefined {
        const configItemClassName = `${objectType}ConfigItem`;
        let injected;

        // Wrapped in a try/catch block because if the dependency is not found with the
        // Angular injector, an error is thrown.
        try {
            injected = this.injector.get<TMessageItem>(
                configItemClassName as unknown as ProviderToken<any>,
            );
        } catch (e) {
            // If provider not found in Angular, try getting it from AngularJS's
            // injector.
            try {
                injected = this.$injector.get(configItemClassName as string) as TMessageItem;
            } catch (error) {
                // empty block (eslint)
            }
        }

        return injected;
    }
}
