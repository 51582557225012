/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { BotIpReputationTypeMappingModalComponent } from 'ng/modules/bot';
import { BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN } from '../bot-detection-policy.tokens';

/**
 * @author Akul Aggarwal
 */
export class BotIpReputationTypeMappingCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botipreputationtypemapping',
            objectType: 'BotIPReputationTypeMapping',
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotIpReputationTypeMappingModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN);
    }
}

BotIpReputationTypeMappingCollection.ajsDependencies = [
    BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN,
];

export type TBotIpReputationTypeMappingCollection = typeof BotIpReputationTypeMappingCollection;
