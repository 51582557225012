<ng-container *ngrxLet="samlLog$ as samlLog">
    <div class="vs-log-cinematic-sub-section-saml__column-group">
        <!-- 1st column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.samlAuthStatusLabel | vtranslate }}
            </div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_auth_status as authStatus; else na"
                property="saml_log.saml_auth_status"
                [value]="authStatus"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ authStatus }}
            </div>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.sessionCookieExpiredLabel | vtranslate }}</div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_session_cookie_expired !== undefined; else na"
                property="saml_log.saml_session_cookie_expired"
                [value]="samlLog.saml_session_cookie_expired"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ samlLog.saml_session_cookie_expired }}
            </div>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.userIdLabel | vtranslate }}</div>
            <div
                vsLogFilter
                *ngIf="samlLog.userid as id; else na"
                property="saml_log.userid"
                [value]="id"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ id }}
            </div>
        </div>

        <!-- 2nd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authorizationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_authz_rule_match?.saml_authz_matched_rule_name as ruleName; else na"
                property="saml_log.saml_authz_rule_match.saml_authz_matched_rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_authz_rule_match?.saml_authz_matched_rule_action as action; else na"
                property="saml_log.saml_authz_rule_match.saml_authz_matched_rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>

        <!-- 3rd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authenticationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_authn_rule_match?.saml_authn_matched_rule_name as ruleName; else na"
                property="saml_log.saml_authn_rule_match.saml_authn_matched_rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                vsLogFilter
                *ngIf="samlLog.saml_authn_rule_match?.saml_authn_matched_rule_action as action; else na"
                property="saml_log.saml_authn_rule_match.saml_authn_matched_rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>
    </div>

    <!-- Attribute List grid -->
    <avi-data-grid
        *ngIf="samlLog.saml_attribute_lists?.length"
        [config]="samlAttributeListGridConfig"
        [rows]="samlLog.saml_attribute_lists"
    ></avi-data-grid>
</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template #emDash>
    <span
        vsLogEmptyField
        [useDash]="true"
    ></span>
</ng-template>

<ng-template
    #samlAttributeNameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="saml_log.saml_attribute_lists.attr_name"
        [value]="row.attr_name"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.attr_name }}
    </span>
</ng-template>

<ng-template
    #samlAttributeValuesFieldTemplateRef
    let-row
    let-index="index"
>
    <span *ngIf="row.attr_values?.length && row.attr_values.join(',') as values; else emDash">
        {{ values }}
    </span>
</ng-template>
