/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Checkbox directive
 * Example of usage:
 *
 *   <checkbox
 *      ng-model="variableName"
 *      ng-change="expression to be called"
 *      ng-click="expression to be called"
 *      ng-disabled="true|false"
 *      ng-true-value="ValueThatSupposeToBeTrue"
 *      ng-false-value="ValueThatSupposeToBeFalse"
 *      >
 *   </checkbox>
 */
//TODO support static disabled attribute
//FIXME use ngModelCtrl
angular.module('aviApp').directive('checkbox', [
'$compile', '$parse', '$timeout',
function($compile, $parse, $timeout) {
    function checkboxLink(scope, elm, attr, parent) {
        if (parent) {
            parent.addCheckbox(attr.ngModel, scope);
        }

        const
            ngModelRef = _.isUndefined(attr.ngModel) ? 'ngModelLocal' : 'ngModel',
            ngTrueValue = _.isUndefined(scope.ngTrueValue) ?
                '' : 'ng-true-value="{{ngTrueValue}}"',
            ngFalseValue = _.isUndefined(scope.ngFalseValue) ?
                '' : 'ng-false-value="{{ngFalseValue}}"';

        const template =
            `<input
                type="checkbox"
                class="sel-avi-checkbox"
                ng-model="${ngModelRef}"
                ng-change="change()"
                ng-click="onClick($event)"
                ng-disabled="ngDisabled"
                ${ngTrueValue}
                ${ngFalseValue}>`;

        elm.append($compile(template)(scope));

        scope.change = function() {
            $timeout(() => {
                scope.ngChange();

                if (parent) {
                    parent.onChange();
                }
            });
        };

        scope.onClick = function(e) {
            //ng-model holds previous value with no $timeout
            $timeout(() => scope.ngClick({ $event: e }));
            e.stopPropagation();
        };

        // Put the class when it's disabled
        scope.$watch('ngDisabled', isDisabled => {
            elm.toggleClass('disabled', !!isDisabled);
        });

        // If ngModel attribute missing, then need to work with ngModelLocal,
        // otherwise it's gonna generate errors
        if (!_.isUndefined(attr.ngModel)) {
            // Put the class when it's checked
            scope.$watch('ngModel', function() {
                if (scope.ngModel &&
                    (_.isUndefined(scope.ngTrueValue) ||
                        $parse(scope.ngTrueValue)(scope) === scope.ngModel)) {
                    elm.addClass('checked');
                } else {
                    elm.removeClass('checked');
                }
            });

            if (!_.isUndefined(attr.ngChecked)) {
                scope.$watch('ngChecked', function() {
                    if (scope.ngChecked) {
                        scope.ngModel = !_.isUndefined(scope.ngTrueValue) ?
                            $parse(scope.ngTrueValue)(scope) : true;
                    } else {
                        scope.ngModel = !_.isUndefined(scope.ngFalseValue) ?
                            $parse(scope.ngFalseValue)(scope) : false;
                    }
                });
            }
        } else {
            scope.$watch('ngModelLocal', function() {
                if (scope.ngModelLocal &&
                    (_.isUndefined(scope.ngTrueValue) ||
                        $parse(scope.ngTrueValue)(scope) === scope.ngModelLocal)) {
                    elm.addClass('checked');
                } else {
                    elm.removeClass('checked');
                }
            });

            if (!_.isUndefined(attr.ngChecked)) {
                scope.$watch('ngChecked', function() {
                    if (scope.ngChecked) {
                        scope.ngModelLocal = !_.isUndefined(scope.ngTrueValue) ?
                            $parse(scope.ngTrueValue)(scope) : true;
                    } else {
                        scope.ngModelLocal = !_.isUndefined(scope.ngFalseValue) ?
                            $parse(scope.ngFalseValue)(scope) : false;
                    }
                });
            }
        }

        // Catch space click on <checkbox> element in case if it was focused
        elm.on('keyup', event => {
            if (event.which === 32) {
                elm.find('input').trigger('click');
            }
        });

        // If for some reason (by automatic test for example) click event triggered on the
        // actual checkbox element then we have to trigger that click on the
        // <input type='checkbox'>
        elm.on('click', () => {
            elm.find('input').trigger('click');
        });
    }

    return {
        scope: {
            ngModel: '=',
            ngChange: '&',
            ngClick: '&',
            ngDisabled: '=',
            ngChecked: '=',
            ngTrueValue: '@',
            ngFalseValue: '@',
        },
        require: '?^triCheckboxSet',
        restrict: 'E',
        template: '<i class="icon-check"/>',
        link: checkboxLink,
    };
}]);
