/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';

import './vs-log-cinematic-field-title.directive.less';

/**
 * @description
 *     Direcitive used to set styles for the equal-width columns in VS log C-view sections.
 * @author Zhiqian Liu
 */
@Directive({
    selector: '[vsLogCinematicFieldTitle]',
})
export class VsLogCinematicFieldTitleDirective implements OnInit {
    /**
     * Add a top margin if set to true or remove it otherwise.
     */
    @Input()
    public withMarginTop ?= true;

    /**
     * The native HTML element that the directive is bound to.
     */
    private domElement: HTMLElement;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
    ) {
        this.domElement = this.elementRef.nativeElement;
        this.renderer.addClass(this.domElement, 'vs-log-cinematic-field-title');
    }

    /** @override */
    public ngOnInit(): void {
        if (this.withMarginTop) {
            this.renderer.addClass(
                this.domElement,
                'vs-log-cinematic-field-title__with-margin-top',
            );
        }
    }
}
