/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-score';
const componentName = 'avi-health-score';
const prefix = `${moduleName}.${componentName}`;

export const healthBreakdownLabel = `${prefix}.healthBreakdownLabel`;
export const failedToDeactivateLabel = `${prefix}.failedToDeactivateLabel`;

export const ENGLISH = {
    [healthBreakdownLabel]: 'Health Breakdown',
    [failedToDeactivateLabel]: 'Failed to Deactivate',
};
