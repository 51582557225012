<full-modal-config 
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
    class="user-profile-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label 
                        aviLabelWithTooltip
                        for="name"
                        [objectType]="objectType"
                        fieldName="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        class="sel-name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                        regexPattern="objName"
                        [(ngModel)]="editable.config.name"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="max_password_history_count"
                        [objectType]="objectType"
                        fieldName="max_password_history_count"
                    >
                        {{ l10nKeys.maxPasswordHistoryCountInputLabel | vtranslate }}
                    </label>
                    <input
                        id="max_password_history_count"
                        name="max_password_history_count"
                        class="sel-max-passwd-history"
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.maxPasswordHistoryCountInputPlaceholder | vtranslate }}"
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="max_password_history_count"
                        [(ngModel)]="editable.config.max_password_history_count"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label 
                        aviLabelWithTooltip 
                        for="max_login_failure_count" 
                        [objectType]="objectType"
                        fieldName="max_login_failure_count"
                    >
                        {{ l10nKeys.maxLoginFailureCountInputLabel | vtranslate }}
                    </label>
                    <input 
                        id="max_login_failure_count" 
                        name="max_login_failure_count" 
                        class="sel-max-login-failure"
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.maxLoginFailureCountInputPlaceholder | vtranslate }}" 
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="max_login_failure_count"
                        [(ngModel)]="editable.config.max_login_failure_count"
                    />
                </avi-input-container>
                <avi-input-container helperText="{{ l10nKeys.accountLockTimeoutInnerText | vtranslate }}">
                    <label 
                        aviLabelWithTooltip 
                        for="account_lock_timeout" 
                        [objectType]="objectType"
                        fieldName="account_lock_timeout"
                    >
                        {{ l10nKeys.accountLockTimeoutInputLabel | vtranslate }}
                    </label>
                    <input 
                        id="account_lock_timeout" 
                        name="account_lock_timeout" 
                        class="sel-account-lock-timeout"
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.accountLockTimeoutInputPlaceholder | vtranslate }}" 
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="account_lock_timeout"
                        [(ngModel)]="editable.config.account_lock_timeout" 
                    />
                </avi-input-container>
                <avi-input-container helperText="{{ l10nKeys.loginFailureCountExpiryWindowInnerText | vtranslate }}">
                    <label 
                        aviLabelWithTooltip 
                        for="login-failure-count-expiry-window" 
                        [objectType]="objectType"
                        fieldName="login_failure_count_expiry_window"
                    >
                        {{ l10nKeys.loginFailureCountExpiryWindowInputLabel | vtranslate }}
                    </label>
                    <input 
                        id="login-failure-count-expiry-window" 
                        name="login-failure-count-expiry-window" 
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.loginFailureCountExpiryWindowInputPlaceholder | vtranslate }}" 
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="login_failure_count_expiry_window"
                        [(ngModel)]="editable.config.login_failure_count_expiry_window"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label 
                        aviLabelWithTooltip 
                        for="max_concurrent_sessions" 
                        [objectType]="objectType"
                        fieldName="max_concurrent_sessions"
                    >
                        {{ l10nKeys.maxConcurrentSessionsInputLabel | vtranslate }}
                    </label>
                    <input 
                        id="max_concurrent_sessions"
                        name="max_concurrent_sessions"
                        class="sel-max-concurrent-sessions"
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.maxConcurrentSessionsInputPlaceholder | vtranslate }}"
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="max_concurrent_sessions"
                        [(ngModel)]="editable.config.max_concurrent_sessions"
                    />
                </avi-input-container>
                <avi-input-container helperText="{{ l10nKeys.credentialsTimeoutThresholdInnerText | vtranslate }}">
                    <label 
                        aviLabelWithTooltip 
                        for="credentials_timeout_threshold" 
                        [objectType]="objectType"
                        fieldName="credentials_timeout_threshold"
                    >
                        {{ l10nKeys.credentialsTimeoutThresholdInputLabel | vtranslate }}
                    </label>
                    <input 
                        id="credentials_timeout_threshold" 
                        name="credentials_timeout_threshold" 
                        class="sel-creds-timeout"
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.credentialsTimeoutThresholdInputPlaceholder | vtranslate }}"
                        configFieldInputValidation
                        [objectType]="objectType"
                        fieldName="credentials_timeout_threshold"
                        [(ngModel)]="editable.config.credentials_timeout_threshold"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
