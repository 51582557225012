<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleServerMaintenanceMode | vtranslate }}</h3>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container
                noMarginTop
                helperText="{{ l10nKeys.maintenanceResponseCodeInputHelperText | vtranslate }}"
            >
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="maintenance_code"
                    for="maintenance-code"
                >
                    {{ l10nKeys.maintenanceResponseCodeInputLabel | vtranslate }}
                </label>
                <avi-repeated-numbers
                    id="maintenance-code"
                    name="maintenance-code"
                    placeholder="{{ l10nKeys.maintenanceResponseCodeInputPlaceholder | vtranslate }}"
                    objectType="{{ objectType }}"
                    fieldName="maintenance_code"
                    [(ngModel)]="healthMonitorTypeConfig.config.maintenance_code"
                    [allowDuplicates]="true"
                ></avi-repeated-numbers>
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="maintenance_response"
                    for="http-maintenance-response"
                >
                    {{ l10nKeys.maintenanceResponseDataInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    type="text"
                    id="http-maintenance-response"
                    name="http-maintenance-response"
                    [(ngModel)]="healthMonitorTypeConfig.config.maintenance_response"
                    placeholder="{{ l10nKeys.maintenanceResponseDataInputPlaceholder | vtranslate }}"
                />
            </avi-input-container>
        </div>
    </div>
</div>
