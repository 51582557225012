<div class="user-card user-card__wrapper grid-container grid-container--gap--none">
    <div class="grid-container__cell--w--10">
        <div class="user-card__item">
            <div class="user-card__username">
                {{ (fullName$ | async) || (username$ | async) }}
            </div>
            <div
                class="user-card__email"
                *ngIf="email$ | async as email"
            >
                {{ email }}
            </div>
        </div>
        <div class="user-card__item">
            <div
                class="user-card__my-account"
                (click)="openMyAccountModal()"
            >
                {{ l10nKeys.myAccountLabel | vtranslate }}
            </div>
            <div
                class="user-card__last-login"
                *ngIf="lastLogin"
            >
                {{ l10nKeys.lastLoginLabel | vtranslate : lastLogin }}
            </div>
        </div>
    </div>
</div>
