/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorHttp,
    HealthMonitorImap,
    HealthMonitorSSLAttributes,
} from 'object-types';

import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
} from 'ajs/modules/security';

import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { HealthMonitorImapConfigItem } from 'ajs/modules/healthmonitor/factories';

import * as globalL10n from 'global-l10n';
import * as l10n from './imaps-health-monitor-config.l10';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * SSLProfileCollection Type.
 */
type TSslProfileCollection = typeof SSLProfileCollection;

/**
 * CertificateCollection Type.
 */
type TCertificateCollection = typeof CertificateCollection;

/**
 * PKIProfileCollection Type.
 */
type TPkiProfileCollection = typeof PKIProfileCollection;

@Component({
    selector: 'imaps-health-monitor-config',
    templateUrl: './imaps-health-monitor-config.component.html',
})
export class ImapsHealthMonitorConfigComponent implements OnChanges, OnInit, OnDestroy {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Passed as a param to ssl, pki and certificate profile collections.
     */
    @Input()
    public isFederated = false;

    /**
     * Health Monitor config type. This can be either imap_monitor or imaps_monitor.
     */
    public imapConfigItem: HealthMonitorImapConfigItem;

    /**
     * Flag to show fields applicable only for IMAPS type.
     */
    public isImapsType = false;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorHttp,
        HealthMonitorImap,
        HealthMonitorSSLAttributes,
    };

    /**
     * SSLProfileCollection instance.
     */
    public readonly sslProfileCollection: SSLProfileCollection;

    /**
     * CertificateCollection instance.
     */
    public readonly certificateCollection: CertificateCollection;

    /**
     * PKIProfileCollection instance.
     */
    public readonly pkiProfileCollection: PKIProfileCollection;

    constructor(
        l10nService: L10nService,
        @Inject(SSLProfileCollection)
        SSLProfileCollection: TSslProfileCollection,
        @Inject(CertificateCollection)
        CertificateCollection: TCertificateCollection,
        @Inject(PKIProfileCollection)
        PKIProfileCollection: TPkiProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.sslProfileCollection = new SSLProfileCollection();
        this.certificateCollection = new CertificateCollection();
        this.pkiProfileCollection = new PKIProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editable.isImapsType()) {
            this.isImapsType = true;
            this.imapConfigItem = this.editable.config.imaps_monitor;
        } else {
            this.imapConfigItem = this.editable.config.imap_monitor;
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { isFederated } = changes;
        const params = {
            is_federated: isFederated?.currentValue,
        };

        this.sslProfileCollection.setParams(params);
        this.pkiProfileCollection.setParams(params);
        this.certificateCollection.setParams(params);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
        this.certificateCollection.destroy();
        this.pkiProfileCollection.destroy();
    }
}
