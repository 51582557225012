<div class="avi-repeated-strings">
    <div
        class="avi-repeated-strings__input-container"
        [ngClass]="{'avi-repeated-strings__input-container--disabled' : disabled }"
    >
        <span
            class="avi-repeated-strings__item"
            *ngFor="let item of items; index as i; trackBy: trackByIndex"
            [title]="item"
        >
            <cds-tag
                *ngIf="editingIndex !== i; else editTemplate"
                status="info"
                [id]="'item-' + i"
                class="avi-repeated-strings__item-tag"
                [disabled]="disabled"
                (click)="openItemForEditing(i, item)"
            >
                <div class="avi-repeated-strings__item-container">
                    <div class="avi-repeated-strings__item-content">{{ item }}</div>
                    <cds-icon
                        *ngIf="!disabled"
                        class="avi-repeated-strings__item-close-icon"
                        shape="times"
                        size="16"
                        (click)="removeItem(i)"
                    ></cds-icon>
                </div>
            </cds-tag>
        </span>

        <!-- Must be outside of ngFor so that there's only one templateRef -->
        <ng-template #editTemplate>
            <input
                #editInput
                class="avi-repeated-strings__item-edit-input"
                [(ngModel)]="currentEditable"
                (ngModelChange)="setInputSize()"
                (keydown.enter)="handleEditEnter($event)"
                (focusout)="handleEditFocusout()"
            />
        </ng-template>
        <input
            #mainInput
            class="avi-repeated-strings__main-input sel-avi-repeated-strings"
            [(ngModel)]="isEditing ? '' : currentEditable"
            name="current-editable"
            placeholder="{{ !items || !items.length ? placeholder : '' }}"
            [disabled]="disabled"
            (keydown.enter)="handleMainInputEnter($event)"
            (focusout)="handleMainInputFocusout()"
            (keydown.backspace)="handleMainInputBackspace()"
        />
        <cds-icon
            class="avi-repeated-strings__delete-icon"
            shape="times-circle"
            (click)="removeAllItems()"
            [hidden]="!items || !items.length || disabled"
        ></cds-icon>
    </div>
</div>

