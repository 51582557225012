<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleExternal | vtranslate }}</h3>
    <health-monitor-authentication 
        [editable]="editable"
        [showAuthTypeDropdown]="false"
    ></health-monitor-authentication>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorExternal }}"
                fieldName="command_code"
                for="command-code"
            >
                {{ l10nKeys.scriptCodeInputLabel | vtranslate }}
            </label>
            <file-upload-textarea
                name="command-code"
                [(ngModel)]="externalMonitor.config.command_code"
                placeholder="{{ l10nKeys.scriptCodeInputPlaceholder | vtranslate }}"
            ></file-upload-textarea>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorExternal }}"
            fieldName="command_parameters"
            for="command-parameters"
        >
            {{ l10nKeys.scriptParametersInputLabel | vtranslate }}
        </label>
        <input
            id="command-parameters"
            name="command-parameters"
            class="sel-external-args"
            clrInput
            placeholder="{{ l10nKeys.scriptParametersInputPlaceholder | vtranslate }}"
            [(ngModel)]="externalMonitor.config.command_parameters"
        />
    </avi-input-container>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    for="monitor-port"
                    [required]="editable.config.is_federated"
                >
                    {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    type="number"
                    id="monitor-port"
                    name="monitor-port"
                    class="sel-failed-checks"
                    [(ngModel)]="editable.config.monitor_port"
                    configFieldInputValidation
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
                    [disabled]="!!editable.id"
                    [required]="editable.config.is_federated"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorExternal }}"
                    fieldName="command_variables"
                    for="command-variables"
                >
                    {{ l10nKeys.scriptVariablesInputLabel | vtranslate }}
                </label>
                <input
                    id="command-variables"
                    name="command-variables"
                    class="sel-external-vars"
                    clrInput
                    placeholder="{{ l10nKeys.scriptVariablesInputPlaceholder | vtranslate }}"
                    [(ngModel)]="externalMonitor.config.command_variables"
                />
            </avi-input-container>
        </div>
    </div>
</div>
