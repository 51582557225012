<div provideParentForm>
    <avi-data-grid
        [config]="hsmIpGridConfig"
        [rows]="hsmIpStrings"
        [invalidateForm]="hsmIpStrings.length < 1"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="onAddHsmIp.emit()"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #hsmIpTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    type="text"
                    name="{{ 'hsm-ip_' + index }}"
                    clrInput
                    placeholder="{{ l10nKeys.ipAddressPlaceholder | vtranslate }}"
                    [(ngModel)]="row.value"
                    regexPattern="ip"
                    required
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
