<cds-modal
    size="xl"
    (closeChange)="handleClose()"
    class="json-viewer clr-wrapper"
>
    <cds-modal-header>
        <h3>{{ l10nKeys.dialogTitle | vtranslate : config.name || '' }}</h3>
    </cds-modal-header>

    <avi-alert-group
        *ngIf="modified"
        alertType="warning"
    >
        <span> {{ l10nKeys.configPreviewedNotSavedAlertMessage | vtranslate }} </span>
    </avi-alert-group>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                clrCheckbox
                name="show-defaults"
                [ngModel]="showDefaults"
                (ngModelChange)="onShowDefaultsChange()"
                id="show-defaults"
            />
            <label for="show-defaults">
                {{ l10nKeys.showDefaultsLabel | vtranslate }}
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <cds-modal-content>
        <div class="json-viewer__modal-content">
            <div class="json-viewer__actions-wrapper">
                <cds-icon
                    class="json-viewer__action-icon"
                    shape="download"
                    size="24"
                    title="{{ l10nKeys.downloadConfigTitle | vtranslate }}"
                    (click)="downloadJsonFile()"
                ></cds-icon>
                <cds-icon
                    class="json-viewer__action-icon"
                    shape="copy-to-clipboard"
                    size="24"
                    title="{{ l10nKeys.copyConfigToClipboardTitle | vtranslate }}"
                    (click)="copyToClipboard()"
                ></cds-icon>
            </div>
            <pre
                class="json-viewer__modal-content-config"
                [innerHTML]="displayConfig | formatJson"
            ></pre>
        </div>
    </cds-modal-content>
</cds-modal>
