/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'sso-policy';
const componentName = 'sso-policy-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeSelectPlaceholder = `${prefix}.typeSelectPlaceholder`;
export const defaultAuthProfileDropdownLabel = `${prefix}.defaultAuthProfileDropdownLabel`;
export const defaultAuthProfileDropdownPlaceholder = `${prefix}.defaultAuthProfileDropdownPlaceholder`;
export const sectionTitleAuthentication = `${prefix}.sectionTitleAuthentication`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit SSO Policy',
    [modalTitleNew]: 'Create SSO Policy',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [typeInputLabel]: 'Type',
    [typeSelectPlaceholder]: 'Select Type',
    [defaultAuthProfileDropdownLabel]: 'Default Auth Profile',
    [defaultAuthProfileDropdownPlaceholder]: 'Select Default Auth Profile',
    [sectionTitleAuthentication]: 'Authentication',
};
