/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IAviDropdownOption } from 'ng/shared/components';
import { L10nService } from '@vmw/ngx-vip';
import { L4RuleProtocolMatchConfigItem }
    from '../../factories/matches/l4-rule-protocol-match.config-item.factory';

import template from './l4-rule-protocol-match.component.html';

import * as l10n from './l4-rule-protocol-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc L4 Rule Protocol Match component.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
class L4RuleProtocolMatchComponentController {
    /**
     * L4 Protocol Match to be configured.
     */
    public l4RuleProtocolMatch: L4RuleProtocolMatchConfigItem;

    /**
     * Prevent changing protocol type. Edit can be prohibited when a match is read only.
     */
    public preventEdit: boolean;

    /**
     * List of options for L4RuleProtocol dropdown.
     */
    public readonly l4RuleProtocols: IAviDropdownOption[];

    /**
     * l10n keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        dropDownUtils: any,
        l10nService: L10nService,
    ) {
        this.l4RuleProtocols =
            dropDownUtils.getEnumDropdownOptions('Protocol') as IAviDropdownOption[];

        l10nService.registerSourceBundles(dictionary);
    }
}

L4RuleProtocolMatchComponentController.$inject = [
    'dropDownUtils',
    'l10nService',
];

export const l4RuleProtocolMatchComponentOptions = {
    bindings: {
        l4RuleProtocolMatch: '<',
        preventEdit: '<',
    },
    controller: L4RuleProtocolMatchComponentController,
    template,
};
