/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
    IRowReorder,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { AuthMappingRuleConfigItem } from
    'ajs/modules/auth-settings/factories/auth-mapping-rule.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './auth-mapping-rules-grid.l10n';
import './auth-mapping-rules-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH, ...globalL10nKeys } = globalL10n;

/**
 * @desc Auth Mapping Rules Grid component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-mapping-rules-grid',
    templateUrl: './auth-mapping-rules-grid.component.html',
})
export class AuthMappingRulesGridComponent implements AfterViewInit {
    /**
     * AuthMappingRule RepeatedMessageItem instance.
     */
    @Input()
    public authMappingRules: RepeatedMessageItem<AuthMappingRuleConfigItem>;

    /**
     * If true, Add button will disabled.
     */
    @Input()
    public disableAddButton = false;

    /**
     * Fires on new AuthMappingRule.
     */
    @Output()
    public onAddAuthMappingRule: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit an AuthMappingRule.
     */
    @Output()
    public onEditAuthMappingRule = new EventEmitter<AuthMappingRuleConfigItem>();

    /**
     * Fires on delete/deleteAll AuthMappingRule(s).
     */
    @Output()
    public onDeleteAuthMappingRule = new EventEmitter<AuthMappingRuleConfigItem>();

    /**
     * Fires on drag-drop of a rule.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<IRowReorder>();

    /**
     * Match Expander templateRef.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * AuthMappingRules Grid config.
     */
    public authMappingRuleGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            l10nKeys,
        } = this;

        this.authMappingRuleGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleRule),
                id: 'rule',
                transform(rule: AuthMappingRuleConfigItem) {
                    const ruleLabel = l10nService.getMessage(l10nKeys.columnTitleRule);

                    return `${ruleLabel} ${rule.config.index + 1}`;
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (rules: AuthMappingRuleConfigItem[]) => {
                    rules.forEach((rule: AuthMappingRuleConfigItem) => {
                        this.deleteAuthMappingRule(rule);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (rule: AuthMappingRuleConfigItem) => this.editAuthMappingRule(rule),
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (rule: AuthMappingRuleConfigItem) => this.deleteAuthMappingRule(rule),
            }],
            layout: {
                withReordering: true,
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /**
     * Emits Add new auth mapping rule event.
     */
    public addAuthMappingRule(): void {
        this.onAddAuthMappingRule.emit();
    }

    /**
     * Emits rule reorder event.
     */
    public handleRowReorder(reorderInfo: IRowReorder): void {
        this.onRowOrderChange.emit(reorderInfo);
    }

    /**
     * Returns mapping label.
     */
    public getMappingLabel(rule: AuthMappingRuleConfigItem): string {
        const { l10nService } = this;

        return rule.isSuperUser ? l10nService.getMessage(l10nKeys.superUserLabel) :
            l10nService.getMessage(l10nKeys.customLabel);
    }

    /**
     * Emits Edit mapping rule event.
     */
    private deleteAuthMappingRule(rule: AuthMappingRuleConfigItem): void {
        this.onDeleteAuthMappingRule.emit(rule);
    }

    /**
     * Emits Delete auth mapping rule event.
     */
    private editAuthMappingRule(rule: AuthMappingRuleConfigItem): void {
        this.onEditAuthMappingRule.emit(rule);
    }
}
