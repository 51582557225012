/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-site-modal';
const prefix = `${moduleName}.${componentName}`;

export const editGslbSiteModalHeader = `${prefix}.editGslbSiteModalHeader`;
export const newGslbSiteModalHeader = `${prefix}.newGslbSiteModalHeader`;
export const editThirdPartySiteModalHeader = `${prefix}.editThirdPartySiteModalHeader`;
export const newThirdPartySiteModalHeader = `${prefix}.newThirdPartySiteModalHeader`;
export const enableGslbSiteLabel = `${prefix}.enableGslbSiteLabel`;
export const activeMemberCheckboxLabel = `${prefix}.activeMemberCheckboxLabel`;
export const siteTypeLabel = `${prefix}.siteTypeLabel`;
export const newSiteOptionLabel = `${prefix}.newSiteOptionLabel`;
export const thirdpartySiteOptionLabel = `${prefix}.thirdpartySiteOptionLabel`;
export const leaderLabel = `${prefix}.leaderLabel`;
export const followerLabel = `${prefix}.followerLabel`;
export const siteLabel = `${prefix}.siteLabel`;
export const thirdPartySiteLabel = `${prefix}.thirdPartySiteLabel`;
export const replicationModeLabel = `${prefix}.replicationModeLabel`;
export const clientGroupsLabel = `${prefix}.clientGroupsLabel`;
export const placementLabel = `${prefix}.placementLabel`;
export const subdomainsLabel = `${prefix}.subdomainsLabel`;
export const setupDnsVsAfterLabel = `${prefix}.setupDnsVsAfterLabel`;

export const ENGLISH = {
    [editGslbSiteModalHeader]: 'Edit GSLB Site',
    [newGslbSiteModalHeader]: 'New GSLB Site',
    [editThirdPartySiteModalHeader]: 'Edit GSLB Third Party Site',
    [newThirdPartySiteModalHeader]: 'New GSLB Third Party Site',
    [enableGslbSiteLabel]: 'Enable GSLB Site',
    [activeMemberCheckboxLabel]: 'Active Member ({0})',
    [siteTypeLabel]: 'Site Type',
    [newSiteOptionLabel]: 'New Site',
    [thirdpartySiteOptionLabel]: 'Third-Party Site',
    [leaderLabel]: 'Leader',
    [followerLabel]: 'Follower',
    [siteLabel]: 'Site',
    [thirdPartySiteLabel]: 'Third-party Site',
    [replicationModeLabel]: 'Replication Mode',
    [clientGroupsLabel]: 'Client Groups',
    [placementLabel]: 'Placement',
    [subdomainsLabel]: 'Subdomains',
    [setupDnsVsAfterLabel]: 'Setup DNS Virtual Serivices After',
};
