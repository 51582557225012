/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'jwt-match';
const prefix = `${moduleName}.${componentName}`;

export const tokenNameLabel = `${prefix}.tokenNameLabel`;
export const tokenNamePlaceholder = `${prefix}.tokenNamePlaceholder`;
export const addClaimNameButtonLabel = `${prefix}.addClaimNameButtonLabel`;

export const ENGLISH = {
    [tokenNameLabel]: 'Token Name',
    [tokenNamePlaceholder]: 'Enter Token Name',
    [addClaimNameButtonLabel]: 'Add Claim Name',
};
