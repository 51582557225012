/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IGenericMessageItemConfig,
    MessageItem,
} from 'ajs/modules/data-model/factories';

import { HdrMatchOperation } from 'generated-types';

interface IGenericHdrMatchItemConfig extends IGenericMessageItemConfig {
    match_criteria?: HdrMatchOperation;
}

/**
 * @description  Generic header match ConfigItem class.
 * @author Abhinesh Gour
 */
export class GenericHdrMatchBaseConfigItem<T extends IGenericHdrMatchItemConfig>
    extends MessageItem<T> {
    /**
     * Getter for match_criteria
     */
    public get matchCriteria(): IGenericHdrMatchItemConfig['match_criteria'] {
        return this.config.match_criteria;
    }

    /**
     * Called when the match criteria changes.
     * To delete the value field.
     */
    public handleCriteriaChange(): void {
        delete this.config.value;
    }
}
