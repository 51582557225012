/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    HealthMonitor,
    HealthMonitorSmtp,
    HealthMonitorSSLAttributes,
} from 'object-types';

import {
    CertificateCollection,
} from 'ajs/modules/security/factories/certificates/certificate.collection.factory';

import {
    PKIProfileCollection,
} from 'ajs/modules/security/factories/pki-profile/pki-profile.collection.factory';

import {
    SSLProfileCollection,
} from 'ajs/modules/security/factories/ssl-profile/ssl-profile.collection.factory';

import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';

import type {
    HealthMonitorSmtpConfigItem,
} from 'ajs/modules/healthmonitor/factories/health-monitor-smtp.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './smtp-health-monitor-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * CertificateCollection Type.
 */
type TCertificateCollection = typeof CertificateCollection;

/**
 * PKIProfileCollection Type.
 */
type TPkiProfileCollection = typeof PKIProfileCollection;

/**
 * SSLProfileCollection Type.
 */
type TSslProfileCollection = typeof SSLProfileCollection;

/**
 * @description Component to display the SMTP/SMTPS section in the Health Monitor modal.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'smtp-health-monitor-config',
    templateUrl: './smtp-health-monitor-config.component.html',
})
export class SmtpHealthMonitorConfigComponent implements OnChanges, OnInit, OnDestroy {
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Passed as a param to ssl, pki and certificate profile collections.
     */
    @Input()
    public isFederated = false;

    /**
     * Health Monitor config type. This can be either smtp_monitor or smtps_monitor.
     */
    public smtpConfigItem: HealthMonitorSmtpConfigItem;

    /**
     * Flag to show fields applicable only for SMTPS type.
     */
    public isSmtpsType = false;

    public readonly certificateCollection: CertificateCollection;

    public readonly pkiProfileCollection: PKIProfileCollection;

    public readonly sslProfileCollection: SSLProfileCollection;

    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorSmtp,
        HealthMonitorSSLAttributes,
    };

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(CertificateCollection)
        CertificateCollection: TCertificateCollection,
        @Inject(PKIProfileCollection)
        PKIProfileCollection: TPkiProfileCollection,
        @Inject(SSLProfileCollection)
        SSLProfileCollection: TSslProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.certificateCollection = new CertificateCollection();
        this.pkiProfileCollection = new PKIProfileCollection();
        this.sslProfileCollection = new SSLProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editable.isSmtpsType()) {
            this.smtpConfigItem = this.editable.config.smtps_monitor;
            this.isSmtpsType = true;
        } else {
            this.smtpConfigItem = this.editable.config.smtp_monitor;
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { isFederated } = changes;
        const params = {
            is_federated: isFederated?.currentValue,
        };

        this.sslProfileCollection.setParams(params);
        this.pkiProfileCollection.setParams(params);
        this.certificateCollection.setParams(params);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
        this.pkiProfileCollection.destroy();
        this.certificateCollection.destroy();
    }
}
