/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    TWafPolicyCollection,
    WafPolicyCollection,
} from 'ajs/modules/waf/factories/waf-policy/waf-policy.collection.factory';

import {
    TWafPolicyPsmGroupCollection,
    WafPolicyPsmGroupCollection,
} from 'ajs/modules/waf/factories/waf-policy-psm-group.collection.factory';

import { WafPolicyItem } from 'ajs/modules/waf/factories/waf-policy/waf-policy.item.factory';
import { NotificationService } from 'ng/modules/core/services//notification.service';
import { DialogService } from 'dialog-service';
import * as globalL10n from 'global-l10n';
import {
    WafPolicyCloneDialogComponent,
} from '../waf-policy-clone-dialog/waf-policy-clone-dialog.component';

import * as l10n from './waf-policy-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

export const WAF_POLICY_CLONE_DIALOG_ID = 'WAF_POLICY_CLONE_DIALOG_ID';

/**
 * @description Waf Policy list page.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'waf-policy-list-page',
    templateUrl: './waf-policy-list-page.component.html',
})
export class WafPolicyListPageComponent implements OnInit, OnDestroy {
    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Waf Policy Collection Object.
     */
    public readonly wafPolicyCollection: WafPolicyCollection;

    /**
     * Waf Policy grid config object.
     */
    public wafPolicyGridConfig: IAviCollectionDataGridConfig;

    public readonly wafPolicyPsmGroupCollection: WafPolicyPsmGroupCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly notificationService: NotificationService,
        private readonly dialogService: DialogService,
        @Inject(WafPolicyCollection)
        WafPolicyCollection: TWafPolicyCollection,
        @Inject(WafPolicyPsmGroupCollection)
        WafPolicyPsmGroupCollection: TWafPolicyPsmGroupCollection,
    ) {
        this.wafPolicyCollection = new WafPolicyCollection();
        this.wafPolicyPsmGroupCollection = new WafPolicyPsmGroupCollection();
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting wafPolicyGridConfig for Waf Policy List.
     */
    public ngOnInit(): void {
        const { objectName } = this.wafPolicyCollection;

        this.wafPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.wafPolicyCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: IAviCollectionDataGridRow) => row.getName(),
            }],
            singleactions: [{
                id: 'clone',
                label: this.l10nService.getMessage(globalL10nKeys.cloneLabel),
                shape: 'clone',
                onClick: (row: WafPolicyItem) => {
                    this.dialogService.add({
                        id: WAF_POLICY_CLONE_DIALOG_ID,
                        component: WafPolicyCloneDialogComponent as Type<Component>,
                        componentProps: {
                            wafPolicy: row,
                            onSubmit: () => {
                                this.notificationService.addSimpleNotification(
                                    this.l10nService.getMessage(l10nKeys.successfulCloneHeader),
                                    this.l10nService.getMessage(l10nKeys.successfulCloneMessage),
                                );
                                this.dialogService.remove(WAF_POLICY_CLONE_DIALOG_ID);
                                this.wafPolicyCollection.load();
                            },
                            onClose: () => {
                                this.dialogService.remove(WAF_POLICY_CLONE_DIALOG_ID);
                            },
                        },
                    });
                },
                disabled: (row): boolean => {
                    if (row.getConfig().positive_security_model) {
                        return !row.isEditable() || !this.wafPolicyPsmGroupCollection.isCreatable();
                    } else {
                        return !row.isEditable() && !row.isClonable();
                    }
                },
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * @override
     * Destroying wafPolicy Collection.
     */
    public ngOnDestroy(): void {
        this.wafPolicyCollection.destroy();
        this.wafPolicyPsmGroupCollection.destroy();
    }
}
