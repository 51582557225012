/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-clr-server-url-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleLabel = `${prefix}.modalTitleLabel`;
export const serverUrlLabel = `${prefix}.serverUrlLabel`;
export const serverUrlPlaceholder = `${prefix}.serverUrlPlaceholder`;
export const updateIntervalLabel = `${prefix}.updateIntervalLabel`;
export const updateIntervalPlaceholder = `${prefix}.updateIntervalPlaceholder`;
export const nameTooltipText = `${prefix}.nameFieldTooltipText`;
export const descriptionTooltipText = `${prefix}.descriptionTooltipText`;
export const serverUrlTooltipText = `${prefix}.serverUrlTooltipText`;
export const updateIntervalTooltipText = `${prefix}.updateIntervalTooltipText`;
export const updateIntervalErrorText = `${prefix}.updateIntervalErrorText`;

export const ENGLISH = {
    [modalTitleLabel]: 'ADD BY SERVER URL',
    [serverUrlLabel]: 'Server URL',
    [serverUrlPlaceholder]: 'Enter Server URL',
    [updateIntervalLabel]: 'Update Interval',
    [updateIntervalPlaceholder]: 'Enter Update Interval',
    [nameTooltipText]: 'Name of the file.',
    [descriptionTooltipText]: 'A short user-friendly description related to the Uploaded CRL.',
    [serverUrlTooltipText]: 'URL to download the file',
    [updateIntervalTooltipText]: 'Interval in minutes to check for update. If not specified, interval will be 1 day',
    [updateIntervalErrorText]: 'The value entered is outside of the valid range.',
};
