/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { VRFContext } from 'ajs/modules/vrf-context/factories/vrf-context.item.factory';
import {
    VRFContextCollection,
} from 'ajs/modules/vrf-context/factories/vrf-context.collection.factory';
import { CloudCollection } from 'ajs/modules/cloud/factories/cloud.collection.factory';
import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import {
    IEditCredentialsConfig,
} from 'ng/modules/avi-forms/components/credentials-verification';
import { Observable } from 'rxjs';
import {
    CloudVrfContextDialogComponent,
} from '../cloud-vrf-context-dialog/cloud-vrf-context-dialog.component';
import * as l10n from './cloud-vrf-context.l10n';
import './cloud-vrf-context.component.less';

type TVRFContextCollection = typeof VRFContextCollection;
type TCloudCollection = typeof CloudCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Cloud and Vrf Context.
 * If only 1 cloud is present then fetch Vrf refs, If only 1 Vrf is present,
 * then set both the values. Don’t show Edit button.
 * If 1 Cloud but multiple VRF context, keep default cloud ref. Show Edit button.
 * If Multiple Clouds are present, Don’t even need to fetch vrf list. Just unset both of them.
 * If there are multiple clouds, and the user selects one cloud,
 * we want to load the vrf collection for that cloud. if there’s only 1 vrf, then we auto-select it.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'cloud-vrf-context',
    templateUrl: './cloud-vrf-context.component.html',
})
export class CloudVrfContextComponent implements OnDestroy, OnInit {
    /**
     * CLoud ref.
     */
    @Input()
    public cloudRef: string;

    /**
     * Event emitter for CloudRef change.
     */
    @Output()
    public cloudRefChange = new EventEmitter<string>();

    /**
     * Optional field name for Cloud Ref.
     */
    @Input()
    public cloudRefFieldName?: string = 'cloud_uuid';

    /**
     * Flag to remove VRF selection
     */
    @Input()
    public disallowVrfContextSelection = false;

    /**
     * VRF Ref.
     */
    @Input()
    public vrfRef: string;

    /**
     * Event emitter for vrfRef change.
     */
    @Output()
    public vrfRefChange = new EventEmitter<string>();

    /**
     * Optional field name for Vrf Ref.
     */
    @Input()
    public vrfRefFieldName?: string = 'vrf_uuid';

    /**
     * Message Type.
     */
    @Input()
    public messageType: string;

    /**
     * Flag to set if button needs to be hidden.
     */
    public hideChangeCredentialsButton = true;

    /**
     * VRFContextCollection instance.
     */
    public vrfContextCollection: VRFContextCollection;

    /**
     * CloudCollection instance.
     */
    public cloudCollection: CloudCollection;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Config Object for Cloud and VRF.
     */
    public editCloudVrfConfig: IEditCredentialsConfig;

    public constructor(
        l10nService: L10nService,
        @Inject(VRFContextCollection)
        VrfContextCollection: TVRFContextCollection,
        @Inject('CloudCollection')
        CloudCollection: TCloudCollection,
    ) {
        this.vrfContextCollection = new VrfContextCollection({
            params: {
                fields: 'name,tenant_ref',
            },
        });
        this.cloudCollection = new CloudCollection({
            isStatic: true,
            params: {
                fields: 'name,tenant_ref',
            },
        });
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        await this.cloudCollection.load();

        if (!this.cloudRef || !this.disallowVrfContextSelection && !this.vrfRef) {
            if (this.cloudCollection.getNumberOfItems() === 1) {
                const [defaultCloud] = this.cloudCollection.itemList as Cloud[];

                this.setCloudRef(defaultCloud.config.url);

                if (this.disallowVrfContextSelection) {
                    return;
                }

                await this.vrfContextCollection.load();

                if (this.vrfContextCollection.getNumberOfItems() === 1) {
                    const [vrfContextItem] = this.vrfContextCollection.itemList as VRFContext[];

                    this.setVrfRef(vrfContextItem.config.url);
                }
            } else {
                // Clear default ref when there are multiple clouds present.
                this.cloudRef = '';
            }
        }

        this.setCredentialsButtonVisibility(this.cloudRef, this.vrfRef);
        this.setEditCloudVrfConfig();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.vrfContextCollection.destroy();
        this.cloudCollection.destroy();
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing.
     */
    private setEditCloudVrfConfig(): void {
        const editCredentialsConfig = {
            vrf_ref: this.vrfRef,
            cloud_ref: this.cloudRef,
        };

        this.editCloudVrfConfig = {
            editCredentialsDialog: CloudVrfContextDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                disallowVrfContextSelection: this.disallowVrfContextSelection,
                cloudRefFieldName: this.cloudRefFieldName,
                vrfRefFieldName: this.vrfRefFieldName,
                messageType: this.messageType,
                submit$: new Observable<void>(subscriber => {
                    subscriber.next();
                    this.setCloudRef(editCredentialsConfig.cloud_ref);

                    if (!this.disallowVrfContextSelection) {
                        this.setVrfRef(editCredentialsConfig.vrf_ref);
                    }

                    this.setCredentialsButtonVisibility(
                        editCredentialsConfig.cloud_ref,
                        editCredentialsConfig.vrf_ref,
                    );
                }),
                onCancel: () => {
                    this.setEditCloudVrfConfig();
                },
            },
        };
    }

    /**
     * Sets cloud ref and emits to parent.
     */
    private setCloudRef(ref: string): void {
        this.cloudRef = ref;
        this.cloudRefChange.emit(ref);
    }

    /**
     * Sets VRF ref and emits to parent.
     */
    private setVrfRef(ref: string): void {
        this.vrfRef = ref;
        this.vrfRefChange.emit(ref);
    }

    /**
     * Sets visibility of Change credentials button.
     */
    private setCredentialsButtonVisibility(cloudRef: string, vrfRef: string): void {
        if (cloudRef && (this.disallowVrfContextSelection || vrfRef)) {
            this.hideChangeCredentialsButton = true;
        } else {
            this.hideChangeCredentialsButton = false;
        }
    }
}
