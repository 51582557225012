/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module RightRailModule
 * @preferred
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { ClrFormsModule, ClrInputModule } from '@clr/angular';

import {
    AviRightRailComponent,
    CollapsedSidebarIconListComponent,
    RightRailExpanderTextChildComponent,
    RightRailVerticalExpanderComponent,
} from './components';

const rightRailComponents = [
    AviRightRailComponent,
    CollapsedSidebarIconListComponent,
    RightRailExpanderTextChildComponent,
    RightRailVerticalExpanderComponent,
];

/**
 * @description
 *
 *     Module for the right side view equipped for some sub pages.
 *     Different from the left vertical nagivation, this view doesn't support routing and it's not
 *     extending through the whole app height.
 *
 * @author Zhiqian Liu
 */
@NgModule({
    declarations: [
        ...rightRailComponents,
    ],
    exports: [
        AviRightRailComponent,
        CollapsedSidebarIconListComponent,
        RightRailExpanderTextChildComponent,
        RightRailVerticalExpanderComponent,
    ],
    imports: [
        CommonModule,
        ClrFormsModule,
        ClrInputModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class RightRailModule {}
