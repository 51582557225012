/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './policy-grid-expander.less';
import * as l10n from './policy-grid-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name policyGridExpander
 * @description
 *     Component for displaying the match and action configuration in an expander in the
 *     OrderedGrid.
 * @param {LegacyPolicyRuleConfigItem} rule
 * @param {Object} config - OrderedGrid config.
 */
class PolicyGridExpanderController {
    constructor(PolicyGridService, ConfigItem, LegacyPolicyRuleConfigItem, l10nService) {
        this.PolicyGridService_ = PolicyGridService;
        this.LegacyPolicyRuleConfigItem_ = LegacyPolicyRuleConfigItem;
        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.isLegacyPolicyRuleConfigItem = this.row instanceof this.LegacyPolicyRuleConfigItem_;

        if (!this.isLegacyPolicyRuleConfigItem) {
            this.matches = this.config.collection.matches;
            this.actions = this.config.collection.actions;
        }
    }

    /**
     * Returns the name of the match property to be displayed.
     * @param {string} property - Match property.
     * @return {string}
     */
    getMatchName(property) {
        return this.PolicyGridService_.getMatchName(property);
    }

    /**
     * Returns a string representation of the match configuration.
     * @param {string} property - Match property.
     * @return {string}
     */
    getMatchString(property) {
        const matchConfig = this.getMatchConfig(property);

        return this.PolicyGridService_.getMatchString(property, matchConfig);
    }

    /**
     * Returns the config of the match property.
     * @param {string} property - Match property.
     * @return {MatchConfigItem}
     */
    getMatchConfig(property) {
        const match = this.getMatch();

        return this.getConfig(match[property]);
    }

    /**
     * Returns the name of the action property to be displayed.
     * @param {string} property - Action property.
     */
    getActionName(property) {
        const action = this.getAction();

        return this.PolicyGridService_.getActionName(property, action);
    }

    /**
     * Returns a string representation of the action configuration.
     * @param {string} property - Action property.
     */
    getActionString(property) {
        const actionConfig = this.getActionConfig(property);

        return this.PolicyGridService_.getActionString(property, actionConfig, this.row);
    }

    /**
     * Returns true if the rule has matches, since matches are optional.
     * @return {boolean}
     */
    hasMatches() {
        if (this.isLegacyPolicyRuleConfigItem) {
            return this.row.hasMatches();
        } else {
            return !_.isEmpty(this.getMatch());
        }
    }

    /**
     * Returns all matches in this row of rule.
     * @return {MatchConfigItem[]}
     */
    getMatches() {
        return this.row.getMatches();
    }

    /**
     * Returns all actions in this row of rule.
     * @return {ActionConfigItem[]}
     */
    getActions() {
        return this.row.getActions();
    }

    /**
     * Returns the config object from the item. If the item is an instance of a ConfigItem,
     * return item.getConfig(), else return the item.
     * @param {LegacyPolicyRuleConfigItem|Object} item - ConfigItem instance of regular object
     * @return {Object}
     */
    getConfig(item) {
        return item instanceof this.LegacyPolicyRuleConfigItem_ ? item.getConfig() : item;
    }

    /**
     * Returns the match object.
     * @return {Object}
     */
    getMatch() {
        const config = this.getConfig(this.row);

        return config.match;
    }

    /**
     * Returns true if match configuration contains a specific match.
     * @param {string} matchKey - Match property.
     * @return {boolean}
     */
    hasMatch(matchKey) {
        const match = this.getMatch();

        return this.hasMatches() && Boolean(match[matchKey]);
    }

    /**
     * Returns the action config object.
     * @return {Object}
     */
    getAction() {
        return this.row;
    }

    /**v
     * Returns the config of the action property.
     * @param {string} property - Action property.
     * @return {ActionConfigItem}
     */
    getActionConfig(property) {
        const action = this.getAction();

        return this.getConfig(action[property]);
    }

    /**
     * Returns true if action configuration contains a specific action.
     * @param {string} actionKey - Action property.
     * @return {boolean}
     */
    hasAction(actionKey) {
        if (!this.isLegacyPolicyRuleConfigItem) {
            return Boolean(this.row[actionKey]);
        }

        return false;
    }
}

PolicyGridExpanderController.$inject = [
    'PolicyGridService',
    'ConfigItem',
    'LegacyPolicyRuleConfigItem',
    'l10nService',
];

angular.module('aviApp').component('policyGridExpander', {
    bindings: {
        row: '<',
        config: '<',
    },
    controller: PolicyGridExpanderController,
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-expander/policy-grid-expander.html',
});
