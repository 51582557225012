<div class="avi-page-header__header-container">
    <ng-content></ng-content>
    <h2 class="avi-page-header__header-text">
        {{ headerText }}
    </h2>
    <ng-content select="avi-page-header_edit-button"></ng-content>
    <cds-button
        *ngIf="showEditButton"
        action="flat"
        title="{{ buttonToolTip }}"
        [disabled]="disableClick"
        (click)="onClick()"
        class="avi-page-header__edit-button"
    >
        {{ buttonLabelText }}
    </cds-button>
</div>
