/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { ClusterItem } from 'items/cluster.item.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid';

import {
    ClusterObjState,
    ClusterRole,
    ClusterStateValue,
    IClusterVipRuntime,
} from 'generated-types';

import * as globalL10n from 'global-l10n';
import * as l10n from './nodes-page.l10n';

import './nodes-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCluster = typeof ClusterItem;

/**
 * @description
 *
 * Nodes Page component.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'nodes-page',
    templateUrl: './nodes-page.component.html',
})
export class NodesPageComponent implements OnInit, AfterViewInit {
    /**
     * Instance of Cluster Item.
     */
    @Input('resolveClusterInfo')
    public cluster: ClusterItem = null;

    /**
     * TemplateRef for node state field.
     */
    @ViewChild('stateFieldTemplateRef')
    public stateFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for node role field.
     */
    @ViewChild('roleFieldTemplateRef')
    public roleFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the cluster node collection.
     */
    public clusterNodeCollection: any;

    /**
     * Configuration object to display nodes collection.
     */
    public nodesGridConfig: IAviCollectionDataGridConfig;

    /**
     * Cluster status tooltip message.
     */
    public clusterTooltipStatusMessage : string;

    /**
     * Map of node state and label to display.
     */
    private nodeStateLabelMap = {
        CLUSTER_STARTING: l10nKeys.stateStartingLabel,
        CLUSTER_ACTIVE: globalL10nKeys.activeLabel,
        CLUSTER_INACTIVE: l10nKeys.stateInactiveLabel,
        CLUSTER_FAILURE: l10nKeys.stateFailureLabel,
        CLUSTER_COLD_REBOOT: l10nKeys.stateColdRebootLabel,
        CLUSTER_WARM_REBOOT: l10nKeys.stateWarmRebootLabel,
        CLUSTER_CLEAN_REBOOT: l10nKeys.stateCleanRebootLabel,
        CLUSTER_WAITING: l10nKeys.stateWaitingLabel,
        CLUSTER_CONFIG: l10nKeys.stateConfigLabel,
    };

    constructor(
        private readonly l10nService: L10nService,
        @Inject('ClusterNodeCollection')
        private readonly ClusterNodeCollection: any,
        @Inject(ClusterItem)
        Cluster: TCluster,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.clusterNodeCollection = new this.ClusterNodeCollection({
            defaultDataSources: ['list', 'runtime-list'],
        });

        this.cluster = new Cluster({
            id: 'default',
            bind: {
                itemSaveSuccess: () => {
                    this.cluster.load();
                    this.clusterNodeCollection.load();
                },
            },
        });
    }

    /**
     * Edit button click handler.
     */
    public async updateCluster(): Promise<void> {
        await this.cluster.edit();
    }

    /**
     * Return Cluster VIP Runtime Status.
     */
    public get clusterVipRuntimeStatus(): IClusterVipRuntime | undefined {
        return this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_vip_runtime_status;
    }

    /**
     * Returns true if cluster state is Active.
     */
    public isClusterStateActive(state: string): boolean {
        return state === ClusterObjState.CLUSTER_ACTIVE;
    }

    /**
     * Returns true if role is Leader.
     */
    public isClusterRoleLeader(role: string): boolean {
        return role === ClusterRole.CLUSTER_LEADER;
    }

    /**
     * Returns cluster State.
     */
    public get clusterState(): ClusterStateValue {
        return this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_state;
    }

    /**
     * Returns node state label.
     */
    public nodeStateLabel(state: string): string {
        return this.l10nService.getMessage(this.nodeStateLabelMap[state]);
    }

    /** @override */
    public ngOnInit(): void {
        this.nodesGridConfig = {
            id: 'node-list',
            collection: this.clusterNodeCollection,
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                hideDelete: true,
                hideEdit: true,
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.nodesGridConfig = {
            ...this.nodesGridConfig,
            fields: [
                {
                    id: 'node-ipv4',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleNodeIpv4),
                    transform: (row: IAviCollectionDataGridRow): string => {
                        return row.getConfig().ip || '-';
                    },
                },
                {
                    id: 'node-ipv6',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleNodeIpv6),
                    transform: (row: IAviCollectionDataGridRow): string => {
                        return row.getConfig().ip6 || '-';
                    },
                },
                {
                    id: 'hostname',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleHostname),
                    transform: (row: IAviCollectionDataGridRow): string => {
                        return row.getConfig().name;
                    },
                },
                {
                    id: 'role',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleRole),
                    templateRef: this.roleFieldTemplateRef,
                },
                {
                    id: 'state',
                    label: this.l10nService.getMessage(globalL10nKeys.stateLabel),
                    templateRef: this.stateFieldTemplateRef,
                },
            ],
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.clusterNodeCollection.destroy();
        this.cluster.destroy();
    }
}
