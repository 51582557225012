/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * @desc Collection for L4PolicySet items.
 * @author Zhiqian Liu
 */
export class L4PolicySetCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'l4policyset',
            windowElement: 'l4-policy-set-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('L4PolicySet');
    }
}

L4PolicySetCollection.ajsDependencies = [
    'L4PolicySet',
];
