/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    IApplicationLog,
    IConnectionLog,
    ILogQuery,
} from 'generated-types';
import {
    TTimestampFormatFromApi,
    TTimestampFormatFromApiWithMicroS,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';

// -------------------------------------- Enums --------------------------------------

/**
 * Possible VS application profile types. Matches strings in VirtualService item.
 */
export enum VsAppProfileType {
    L4 = 'l4',
    L7 = 'http',
    DNS = 'dns',
}

/**
 * Types of operators a filter can have.
 */
export enum FilterOperatorType {
    EQUAL_TO = 'EQUAL_TO',
    NOT_EQUAL_TO = 'NOT_EQUAL_TO',
    GREATER_THAN = 'GREATER_THAN',
    GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
    LESS_THAN = 'LESS_THAN',
    LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
    CONTAINS = 'CONTAINS',
    DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
    STARTS_WITH = 'STARTS_WITH',
}

// ------------------ API Request Params Types as required by api/analytics/logs ------------------

/**
 * Logs API Params which stay constant.
 */
export type TVsLogsConstantParams = Pick<ILogQuery, 'js_compat'>;

type TVsLogsUpdatableParamsKeys =
    'adf' | 'page' | 'page_size' | 'timeout' | 'duration' | 'groupby' | 'orderby' |
    'step' | 'udf' | 'nf' | 'virtualservice' | 'download' | 'filter';

/**
 * Types of VS logs as expected by API,
 * L4 and DNS VS's return Connection logs. L7 VS's return Application logs.
 */
export enum VsLogsType {
    CONNECTION = 0,
    APPLICATION = 1,
}

/**
 * Logs API params that get modified (updatable).
 * Parameters are optional since different components use different combinations of properties.
 */
export interface IVsLogsUpdatableParams extends Pick<ILogQuery, TVsLogsUpdatableParamsKeys> {
    end: TTimestampFormatFromApiWithMicroS,
    type?: VsLogsType;
}

/**
 * Full set of API parameters that logs page utilizes for requests.
 */
export interface IVsLogsParams extends IVsLogsUpdatableParams, TVsLogsConstantParams {}

// ---------------------- API Request Params Types as stored in state ----------------------

type TFilterValues = number | string | boolean;

/**
 * Format of a filter object as passed to VsLogsStore.
 */
export type TFilterObj = {
    property: string;
    operator: FilterOperatorType;
    value: TFilterValues | TFilterValues[];
};

/**
 * Format of a filter range object as passed to VsLogsStore.
 */
export type TFilterRangeObj = {
    property: string;
    start: number;
    end: number;
};

/**
 * Filters are passed to store as objects, but are stored in state as a Set.
 * This is to enable reuse of old code; to be updated when filter service is upgraded.
 */
export type TStateFilters = string[];

/**
 * Properties needed to define a unique log.
 */
export type TLogEntrySignatureParams = {
    logId: number;
    seId: string;
};

/**
 * Params stored as state in the VS Logs module.
 */
export interface IVsLogsStateParams extends Omit<IVsLogsUpdatableParams,
'filter' | 'download' | 'groupby'> {
    filters?: TStateFilters;
}

// TODO end of logs: confirm that all properties have been omitted for these next three types.
/**
 * State API params that reflect overall page params, i.e. are not component-specific.
 */
export type TVsLogsPageStateParams =
    Omit<IVsLogsStateParams, 'page' | 'page_size' | 'step' | 'orderby'>;

/**
 * State API parameters that the Log List multi-entry download needs.
 */
export type TVsLogListBulkDownloadStateParams =
    Omit<IVsLogsStateParams, 'page' | 'page_size' | 'step'>;

/**
 * State API parameters that the Log List Component needs.
 */
export type TVsLogListStateParams = Omit<IVsLogsStateParams, 'step'>;

/**
 * State API parameters that the Logs Graph Component needs.
 */
// TODO Omit page_size as well after 150205
export type TVsLogsGraphStateParams = Omit<IVsLogsStateParams, 'page' | 'orderby'>;

/**
 * State API parameters that the Logs Cinematic component needs.
 */
export type TVsLogStateParams = Omit<IVsLogsStateParams, 'page' | 'page_size' | 'step' | 'orderby'>;

/**
 * Specific params needed for groupby requests.
 */
export interface IVsLogsGroupbyParams {
    groupby: string;
    orderby?: string;
}

/**
 * Full params needed for groupby requests.
 */
export interface IVsLogsFullGroupbyParams extends IVsLogsStateParams, IVsLogsGroupbyParams {}

/**
 * Logs signpost requests API params that get modified (updatable).
 */
export interface IVsLogsSignpostUpdatableParams extends IVsLogsGroupbyParams {
    page: number;
    page_size: number;
    step?: string,
    expstep?: boolean,
    cols?: string,
}

/**
 * Logs signpost combined requests API params that get modified (updatable).
 */
export interface IVsLogsCombinedRequestUpdatableParams {
    requestId: string;
    requestParams: IVsLogsSignpostUpdatableParams;
}

/**
 * Signpost request params for combined requests.
 * It sends multiple requests together and return combined response.
 */
export interface IVsLogsRequest{
    requestId: string;
    requestParams: IVsLogsFullGroupbyParams;
}

/**
 * Object for recursive request effects including params and config.
 */
export type TVsLogsRecursiveRequest = {
    apiParams: IVsLogsUpdatableParams;
    config: {
        requestId: string;
        requestCount?: number;
    }
};

/**
 * Object for recursive combined request effects including requests and config.
 */
export type TVsLogsCombinedRecursiveRequest = {
    requests: IVsLogsRequest[];
    config?: {
        requestCount?: number;
        lastReceivedData?: TVsLogsCombinedApiResponseData,
    }
};

// --------------------------------- API Response Types ---------------------------------

/**
 * Type of a single log: either an application log or a connection log.
 * This is also a Discriminating Union type, which guaratees, in the TVsLog, the existence of common
 * fields that IApplicationLog and IConnectionLog share.
 */
export type TVsLog = IApplicationLog | IConnectionLog;

// TODO 150210 replace once backend team defines these in proto
/**
 * Data returned from api/analytics/logs.
 * Needed for 1 bar in bar graph.
 */
export interface IVsLogsGraphResponseResultData {
    adf?: number,
    end: TTimestampFormatFromApi;
    extra_info: string;
    missing_adf?: number;
    nf?: number;
    missing_count: number;
    start: TTimestampFormatFromApi;
    timestamp: TTimestampFormatFromApi;
    udf?: number;
    value: number;
}

/**
 * Data returned for a specific groupby value.
 * (e.g., count + percentage of logs that match method="GET").
 */
export interface IVsLogsGroupbyResponseResultData {
    [key: string]: any;
    count: number;
    percentage: number;
}

/**
 * Response properties shared between graph api call and table api call.
 */
interface IVsLogsGraphAndListCommonResponseData {
    count: number;
    end: TTimestampFormatFromApiWithMicroS;
    indexer_query_time: number;
    js_compat: boolean;
    logmgr_resp_time: number;
    missing_ses: [];
    next: string;
    note: string;
    page: number;
    page_size: number;
    percent_remaining: number;
    responding_host: string;
    se_status: string;
    start: TTimestampFormatFromApiWithMicroS;
}

/**
 * Full set of response parameters from API, when request param 'step' not included.
 * See description of this file above.
 */
export interface IVsLogListResponseData extends IVsLogsGraphAndListCommonResponseData {
    more: string;
    results: TVsLog[];
}

/**
 * Full set of response parameters from API, when request param 'step' is included.
 * See description of this file above.
 */
export interface IVsLogsGraphResponseData extends IVsLogsGraphAndListCommonResponseData {
    results: IVsLogsGraphResponseResultData[];
}

/**
 * Full set of response parameters from groupby API requests.
 */
export interface IVsLogsGroupbyResponseData extends IVsLogsGraphAndListCommonResponseData {
    category_results?: IVsLogsSslCategoryResultsResponseData |
    IVsLogsSignificanceCategoryResultsResponseData[];
    results: IVsLogsGroupbyResponseResultData[];
}

/**
 * Single log entry. Is returned by API as single entry in list.
 */
export type TVsLogEntryResponseData = Omit<IVsLogListResponseData, 'more' | 'next'>;

/**
 * A generic type for the responses from various single log api calls.
 */
export type TVsLogsApiResponseData =
    IVsLogListResponseData | IVsLogsGraphResponseData | TVsLogEntryResponseData |
    IVsLogsGroupbyResponseData;

/**
 * A generic type for the responses from various combined log api calls.
 */
export type TVsLogsCombinedApiResponseData =
    IVsLogsWafLatencyResponseData | IVsLogsSslGroupsResponseData | IVsLogsPolicyGroupsResponseData |
    IVsLogsEndToEndTimingResponseData;

/**
 * A generic type for the single request response and combined requests response.
 *
 * We have two kinds of responses
 * TVsLogsApiResponseData for single request response.
 * TVsLogsCombinedApiResponseData for combined responses from multiple requests of signpost.
 */
export type TVsLogsSignpostApiResponseData =
    TVsLogsApiResponseData | TVsLogsCombinedApiResponseData;

/**
 * Hash type for multiple headers, header name as the key and header content as the value.
 */
export type TVsLogHttpHeadersHash = Record<string, string>;

// --------------------------------- Signpost ---------------------------------

/**
 * Selected signpost configuration stored in state.
 */
export interface IVsLogsSelectedSignpostConfig {
    displayText: string;
    configKey: string;
    hidden: boolean;
}

/**
 * A generic type for results stored in signpost ngrx store.
 */
export type TVsLogsSignpostStoreData =
    IVsLogsDefaultSignpostData[] | IVsLogsWafRuleSignpostData[] | IVsLogsWafLatencySignpostData |
    IVsLogsWafRuleGroupsSignpostData[] | IVsLogsGroupsSignpostData[] |
    IVsLogsSignificanceSignpostData[] | IVsLogsEndToEndTimingSignpostData;

/**
 * Properties to define object containg value and param.
 */
export interface IVsLogsValueParam {
    value: string,
    param: string;
}

/**
 * Properties to define range.
 */
export type IVsLogsSignpostRange = {
    start: number;
    end: number;
};

// --------------------------------- Default signpost ---------------------------------

/**
 * Default signpost state.
 */
export interface IVsLogsDefaultSignpostData extends IVsLogsGroupbyResponseResultData {
    value?: IVsLogsSignpostTransformedResult;
}

/**
 * Data state for each default signpost log entry after transformatiion performed.
 *
 * Validity property isValid depends on both display property and value property.
 * If value property is empty and we have some value in display property. We will show display
 * property value on UI and isValid will be true as we have some value for displaying.
 *
 * When clicked for filtering, value property will be sent and not the display property of signpost.
 */
export interface IVsLogsSignpostTransformedResult extends IVsLogsValueParam {
    isValid: boolean;
    contains?: boolean;
    display?: string;
}

// --------------------------------- WAF Latency Signpost ---------------------------------

/**
 * Signpost final state for waf latency.
 */
export interface IVsLogsWafLatencySignpostData {
    requestHeader: IVsLogsWafLatencySignpostInfo[];
    requestBody: IVsLogsWafLatencySignpostInfo[];
    responseHeader: IVsLogsWafLatencySignpostInfo[];
    responseBody: IVsLogsWafLatencySignpostInfo[];
}

/**
 * Response of combined request for waf latency signpost.
 */
export interface IVsLogsWafLatencyResponseData {
    requestHeader: IVsLogsGroupbyResponseData;
    requestBody: IVsLogsGroupbyResponseData;
    responseHeader: IVsLogsGroupbyResponseData;
    responseBody: IVsLogsGroupbyResponseData;
}

/**
 * Data state for each waf latency signpost log entry after transformatiion performed.
 */
export interface IVsLogsWafLatencySignpostInfo {
    value: IVsLogsSignpostRange;
    isValid: boolean;
    param?: string;
    count: number;
    percentage: number;
    phase: string;
}

// --------------------------------- WAF Rule Signpost ---------------------------------

/**
 * Signpost final state for WAF Rule.
 */
export interface IVsLogsWafRuleSignpostData extends IVsLogsGroupbyResponseResultData {
    values?: IVsLogsSignpostTransformedResult[];
}

// --------------------------------- WAF Rule Group Signpost ---------------------------------

/**
 * Signpost final state for WAF Rule Group.
 */
export interface IVsLogsWafRuleGroupsSignpostData {
    count: number;
    name: string;
    children: IVsLogsWafRuleGroupsRules[];
    value: IVsLogsValueParam;
}

/**
 * Data state for each waf rules groups signpost log entry when expanded.
 */
export interface IVsLogsWafRuleGroupsRules {
    count: number,
    percentage: number,
    ruleId: IVsLogsValueParam,
    ruleName: IVsLogsValueParam;
}

// --------------------------------- Groups Signpost ---------------------------------

/**
 * Signpost final state for custom groups.
 * Groups template is used for policy and ssl signpost
 */
export interface IVsLogsGroupsSignpostData {
    name: string;
    children: IVsLogsDefaultSignpostData[];
}

/**
 * Response of combined request for policy signpost.
 */
export interface IVsLogsPolicyGroupsResponseData {
    network?: IVsLogsGroupbyResponseData,
    http?: IVsLogsGroupbyResponseData,
    http_req?: IVsLogsGroupbyResponseData,
    http_resp?: IVsLogsGroupbyResponseData,
}

/**
 * Type of category_results for SSL signpost.
 */
export interface IVsLogsSslCategoryResultsResponseData {
    pfs?: IVsLogsDefaultSignpostData[],
    ap?: IVsLogsDefaultSignpostData[],
    ep?: IVsLogsDefaultSignpostData[],
}

/**
 * Response of combined request for SSL signpost.
 */
export interface IVsLogsSslGroupsResponseData {
    ssl: IVsLogsGroupbyResponseData;
    other: IVsLogsGroupbyResponseData;
}

// --------------------------------- Significance Signpost ---------------------------------

/**
 * Signpost final state for significance signpost.
 */
export interface IVsLogsSignificanceSignpostData extends IVsLogsDefaultSignpostData{
    children: IVsLogsDefaultSignpostData[];
}

/**
 * Type of category_results for significance signpost.
 */
export interface IVsLogsSignificanceCategoryResultsResponseData {
    count: number;
    percentage: number;
    primary_reason: string;
    secondary_reasons: IVsLogsDefaultSignpostData[];
    significance: string;
}

// --------------------------------- End To End Timing Signpost ---------------------------------

/**
 * Signpost final state for end to end timing signpost.
 */
export interface IVsLogsEndToEndTimingSignpostData {
    average?: IApplicationLog;
    e2eResult?: IVsLogsDefaultSignpostData[];
}

/**
 * Response of combined request for end to end timing signpost.
 */
export interface IVsLogsEndToEndTimingResponseData {
    average: IVsLogsGroupbyResponseData;
    e2eResult: IVsLogsGroupbyResponseData;
}

// --------------------------------- Signpost Template Config ---------------------------------
/**
 * Signpost Templates Constant configuration properties, used to calculate request params and
 * template type.
 */
export interface IVsLogsSignpostTemplateConfig {
    header: string;
    name: string;
    params: string[];
    queryParams: string[];
    phases?: IVsLogsSignpostPhasesConfig[];
    page_size?: number;
    unique?: string,
    uniquePluralEnd?: string,
}

/**
 * Signpost Templates phase configuration.
 */
export interface IVsLogsSignpostPhasesConfig {
    name: string;
    groupby: string;
}
