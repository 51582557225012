/** @module NotificationModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';

import './avi-confirmation.component.less';

const BACKDROP_CLICK_EVENT = 'backdrop-click';
const ESCAPE_KEYPRESS_EVENT = 'escape-keypress';
const ESCAPE_KEY_CODE = 27;

/**
 * @description
 *      Confirmation modal component.
 *      content and buttons are to be transcluded.
 *
 * @author Alex Tseung, Zhiqian Liu, Aravindh Nagarajan
 */
@Component({
    selector: 'avi-confirmation',
    templateUrl: './avi-confirmation.component.html',
})
export class AviConfirmationComponent {
    /**
     * Title for the confirmation popup.
     */
    @Input()
    public headerText = '';

    /**
     * Size of the confirmation modal.
     */
    @Input()
    public size = 'default';

    /**
     * If true, emits the onClose event when the backdrop is clicked.
     */
    @Input()
    public closeOnBackdropClick = false;

    /**
     * If true, emits the onClose event when the escape key is pressed.
     */
    @Input()
    public closeOnEscapeKeypress = false;

    /**
     * Function to be called when the modal-close button is clicked.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Listener for escape keydown event.
     * Close confirmation modal and prevent the escape event
     * from bubbling to parent full-modal-config component.
     */
    @HostListener('keydown.esc', ['$event'])
    private onEscKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === ESCAPE_KEY_CODE) {
            this.onClose.emit();
            event.stopPropagation();
        }
    }

    /**
     * Calls the onClose binding, based on whether or not closing by backdrop click or escape key
     * press is allowed.
     */
    public handleClose($event: Event): void {
        const { detail } = $event as CustomEvent;

        switch (detail) {
            case BACKDROP_CLICK_EVENT: {
                if (this.closeOnBackdropClick) {
                    this.onClose.emit();
                }

                break;
            }

            case ESCAPE_KEYPRESS_EVENT: {
                if (this.closeOnEscapeKeypress) {
                    this.onClose.emit();
                }

                break;
            }

            default:
                this.onClose.emit();
        }
    }
}
