<div
    class="full-modal-config__section ipam-dns-aws-profile-config"
    provideParentForm
    [invalidateForm]="iamAssumeRolesLoading || regionsLoading"
>
    <h3>{{ l10nKeys.accessLabel | vtranslate }}</h3>

    <div
        class="ipam-dns-aws-profile-config__loader ipam-dns-aws-profile-config__loader--with-margin"
        [hidden]="!regionsLoading"
    >
        <clr-spinner [clrSmall]="true"></clr-spinner>
    </div>

    <fieldset
        class="full-modal-config__fieldset"
        [hidden]="regionsLoading"
    >
        <h6 class="full-modal__section-header">
            <ng-container *ngIf="!editable.config.use_iam_roles">{{ l10nKeys.accessLabel | vtranslate }}</ng-container>
            <ng-container *ngIf="editable.config.use_iam_roles">{{ l10nKeys.iamRolesLabel | vtranslate }}</ng-container>
        </h6>

        <credentials-verification
            [editCredentialsConfig]="editAwsProfileCredentialsConfig"
            [isEditing]="isEditing"
        >
            <div
                class="full-modal-config__form-control-row"
                *ngIf="!editable.config.use_iam_roles"
            >
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                            fieldName="access_key_id"
                            for="access_key_id"
                        >
                            {{ l10nKeys.accessKeyIdInputLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="access_key_id"
                            type="password"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="access_key_id"
                            [(ngModel)]="editable.config.access_key_id"
                            readonly
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                            fieldName="secret_access_key"
                            for="secret_access_key"
                        >
                            {{ l10nKeys.secretAccessKeyInputLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="secret_access_key"
                            type="password"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="secret_access_key"
                            [(ngModel)]="editable.config.secret_access_key"
                            readonly
                        />
                    </avi-input-container>
                </div>
            </div>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                    fieldName="region"
                    for="region"
                >
                    {{ l10nKeys.regionInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="region"
                    placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                    name="region"
                    [(ngModel)]="editable.config.region"
                    readonly
                />
            </avi-input-container>
            <div
                class="ipam-dns-aws-profile-config__proxy-config"
                *ngIf="useProxy"
            >
                <h6 class="full-modal__section-header">{{ l10nKeys.proxyLabel | vtranslate }}</h6>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.ProxyConfiguration }}"
                                fieldName="host"
                                for="host"
                            >
                                {{ l10nKeys.hostIpAddressInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="host"
                                placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                                name="host"
                                [(ngModel)]="proxyConfigItem.config.host"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.ProxyConfiguration }}"
                                fieldName="port"
                                for="port"
                            >
                                {{ globalL10nKeys.portLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="port"
                                placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                                name="port"
                                [(ngModel)]="proxyConfigItem.config.port"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.ProxyConfiguration }}"
                                fieldName="username"
                                for="host_username"
                            >
                                {{ globalL10nKeys.usernameLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="host_username"
                                placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                                name="host_username"
                                [(ngModel)]="proxyConfigItem.config.username"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.ProxyConfiguration }}"
                                fieldName="password"
                                for="host_password"
                            >
                                {{ globalL10nKeys.passwordLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="host_password"
                                type="password"
                                placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                                name="host_password"
                                [(ngModel)]="proxyConfigItem.config.password"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </credentials-verification>
    </fieldset>

    <avi-fieldset [hideContent]="!useIamAssumeRole">
        <avi-fieldset_header>
            <clr-checkbox-container class="ipam-dns-aws-profile-config__fieldset-header">
                <clr-checkbox-wrapper>
                    <input
                        clrCheckbox
                        type="checkbox"
                        name="useIamAssumeRole"
                        id="useIamAssumeRole"
                        [(ngModel)]="useIamAssumeRole"
                        (ngModelChange)="handleUseAssumeRoleChange()"
                        [disabled]="!connected"
                    />
                    <label
                        aviLabelWithTooltip
                        for="useIamAssumeRole"
                    >
                        {{ l10nKeys.useIamAssumeRoleLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </avi-fieldset_header>
        <avi-fieldset_content *ngIf="useIamAssumeRole">
            <div
                class="ipam-dns-aws-profile-config__loader"
                [hidden]="!iamAssumeRolesLoading"
            >
                <clr-spinner [clrSmall]="true"></clr-spinner>
            </div>
            <div
                class="full-modal-config__form-control-row ipam-dns-aws-profile-config__fieldset-header"
                [hidden]="iamAssumeRolesLoading"
            >
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                        fieldName="iam_assume_role"
                        for="iam_assume_role"
                        required
                    >
                        {{ l10nKeys.iamAssumeRoleDropdownLabel | vtranslate }}
                    </label>
                    <avi-dropdown
                        name="iam_assume_role"
                        [options]="iamRolesDropdownOptions"
                        [(ngModel)]="editable.config.iam_assume_role"
                        [hideSearch]="true"
                        placeholder="{{ l10nKeys.iamAssumeRoleDropdownPlaceholder | vtranslate }}"
                        [disabled]="!connected"
                        required
                    ></avi-dropdown>
                </div>
            </div>
        </avi-fieldset_content>
    </avi-fieldset>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                fieldName="vpc_id"
                for="vpc_id"
                required
            >
                {{ l10nKeys.vpcDropdownLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="vpc_id"
                [options]="vpcDropdownOptions"
                [(ngModel)]="editable.config.vpc_id"
                placeholder="{{ l10nKeys.vpcDropdownPlaceholder | vtranslate }}"
                (ngModelChange)="onVpcChange()"
                [disabled]="!connected"
                required
            ></avi-dropdown>
        </div>
    </div>

    <div
        class="full-modal-config__form-control-row"
        *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_AWS"
    >
        <div class="full-modal-config__form-control">
            <ipam-dns-aws-usable-networks-grid
                [zones]="editable.config.zones"
                [availablilityZoneList]="availabiltyZones"
                [connected]="connected"
                (onAddZone)="addAvailabilityZone()"
                (onDeleteZone)="deleteAvailabilityZone($event)"
            ></ipam-dns-aws-usable-networks-grid>
        </div>
    </div>

    <div
        class="full-modal-config__form-control-row"
        *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_AWS_DNS"
    >
        <div class="full-modal-config__form-control">
            <avi-repeated-strings-grid
                name="usable_domains"
                [(ngModel)]="editable.config.usable_domains"
                [autoCompleteOptions]="domains"
                columnLabel="{{ l10nKeys.usableDomainLabel | vtranslate }}"
                gridTitle="{{ l10nKeys.usableDomainsGridLabel | vtranslate : editable.usableDomainsCount }}"
                placeholder="{{ l10nKeys.domainInputPlaceholder | vtranslate }}"
            ></avi-repeated-strings-grid>
        </div>
    </div>
</div>
