/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @WafModule */

import { Component, Type } from '@angular/core';
import {
    any,
    findIndex,
    identity,
    isEqual,
    isUndefined,
    pick,
} from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { withFullModalMixin } from 'ajs/utils/mixins';
import {
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { IWafExcludeListEntry } from 'generated-types';
import { WafExclusionTypeConfigItem } from './waf-exclusion-type.config-item.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafExcludeListEntryConfigPartial = Omit<
IWafExcludeListEntry,
'uri_match_criteria' | 'match_element_criteria'
>;

interface IWafExcludeListEntryConfig extends TWafExcludeListEntryConfigPartial {
    // TODO: Remove in 21.1.1 with MessageItem generic.
    uri_match_criteria?: WafExclusionTypeConfigItem;
    match_element_criteria?: WafExclusionTypeConfigItem;
    client_subnet?: IpAddrPrefixConfigItem;
}

export const WAF_EXCLUDE_LIST_ENTRY_CONFIG_ITEM_TOKEN = 'WafExcludeListEntryConfigItem';

export class WafExcludeListEntryConfigItem
    extends withFullModalMixin(MessageItem)<IWafExcludeListEntryConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafExcludeListEntry',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Checks to see if an exception alredy exists within a list of exceptions. Returns an
     * index >= 0 if exists, otherwise returns -1.
     * @param exception - contains client_subnet, uri_path, and match_element properties.
     * @param exceptions - Either a single exception or a list of
     *     exceptions.
     */
    public static hasMatchingException(
        exception: IWafExcludeListEntryConfig,
        exceptions: WafExcludeListEntryConfigItem | WafExcludeListEntryConfigItem[] |
        IWafExcludeListEntryConfig | IWafExcludeListEntryConfig[] = [],
    ): number {
        const exceptionsList = Array.isArray(exceptions) ? exceptions : [exceptions];
        const strippedException = pick(exception, identity);

        return findIndex(exceptionsList, configuredException => {
            const strippedConfiguredException = configuredException instanceof MessageItem ?
                configuredException.getDataToSave() :
                pick(configuredException, identity);

            return isEqual(strippedConfiguredException, strippedException);
        });
    }

    /** @override */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /**
     * Returns the client_subnet IpAddrPrefixConfigItem.
     */
    public get clientSubnet(): IpAddrPrefixConfigItem {
        return this.config.client_subnet;
    }

    /**
     * Returns the uri_match_criteria WafExclusionTypeConfigItem.
     */
    public get pathMatchCriteria(): WafExclusionTypeConfigItem {
        return this.config.uri_match_criteria;
    }

    /**
     * Returns the match_element_criteria WafExclusionTypeConfigItem.
     */
    public get matchElementCriteria(): WafExclusionTypeConfigItem {
        return this.config.match_element_criteria;
    }

    /**
     * Returns a string representation of the subnet.
     */
    public get subnet(): string | undefined {
        return this.config.client_subnet?.subnet;
    }

    /**
     * Returns a string representation of the path.
     */
    public get path(): string | undefined {
        return this.config.uri_path;
    }

    /**
     * Returns a string representation of the match element.
     * @return {string}
     */
    public get matchElement(): string | undefined {
        return this.config.match_element;
    }

    /**
     * Returns true if the exclude list entry has any field populated.
     */
    public isValid(): boolean {
        const { client_subnet: clientSubnet } = this.config;
        const fields = [
            'uri_path',
            'match_element',
        ];

        return any(fields, field => !isUndefined(this.config[field])) ||
            clientSubnet && clientSubnet.isValid();
    }

    /**
     * Returns true if an exception matches the configured exception.
     * @param {Object} exception - Exception containing subnet, path, and match element.
     * @return {boolean}
     */
    public hasMatchingException(exception: IWafExcludeListEntryConfig): boolean {
        const { config } = this;

        return WafExcludeListEntryConfigItem.hasMatchingException(exception, config) > -1;
    }

    /**
     * Returns the uri_match_criteria configItem config object.
     */
    public getUriMatchCriteriaConfig(): Record<string, any> {
        return this.config.uri_match_criteria && this.config.uri_match_criteria.config;
    }

    /**
     * Returns the match_element_criteria configItem config object.
     */
    public getMatchElementCriteriaConfig(): Record<string, any> {
        return this.config.match_element_criteria && this.config.match_element_criteria.config;
    }

    /**
     * Returns a string representation of the configured subnet.
     */
    public getSubnet(): string {
        return this.config.client_subnet.subnet;
    }

    /**
     * Clear the configured subnet value.
     */
    public clearSubnet(): void {
        this.config.client_subnet.clearSubnet();
    }

    /**
     * Clear the configured match element and reset to defaults.
     */
    public clearMatchElement(): void {
        delete this.config.match_element;
        this.config.match_element_criteria.resetDefaults();
    }

    /**
     * Clear the configured path and reset to defaults.
     */
    public clearPath(): void {
        delete this.config.uri_path;
        this.config.uri_match_criteria.resetDefaults();
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafExcludeListEntryBreadcrumbTitle);
    }

    /**
     * @override
     * Import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            WafExcludeListEntryModalComponent,
        } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-exclude-list-entry-modal/waf-exclude-list-entry-modal.component'
        );

        return WafExcludeListEntryModalComponent as Type<Component>;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'client_subnet',
            'uri_match_criteria',
            'match_element_criteria',
        ];
    }
}
