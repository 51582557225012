/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    isNull,
    isUndefined,
} from 'underscore';

import cron, { CronData } from 'cron-validate';

import {
    CronFields,
    DayOfTheWeekRange,
    fieldsToExpression,
    HourRange,
    MonthRange,
    parseExpression,
    SixtyRange,
} from 'cron-parser';

import {
    cronValidatePreset,
    isValidCronExpression,
} from 'ng/modules/avi-forms/validators/cron-expression-validation';

import {
    daysOfWeek,
    daysOfWeekMapping,
    Recurrence,
} from './scheduled-scaling.types';

import { IExtendedScheduledScaling } from './scheduled-scaling-config.item.factory';

/**
 * This base cron expression is used to get default CronFields values.
 * It helps simplify generation of cron expressions from schedule picker.
 * There are cases (based on selected recurrence), where '*' is used.
 * In those scenarios, instead of generating arrays with all possible value range,
 * this base expression can be used to generate those arrays,
 * thus eliminating the chances of errors relating to value ranges supported by cron-parser library.
 * Also, this gives us the array for 'seconds' field, which is required by library.
 */
const baseCronExpression = '0 0 0 * * *';

/**
 * Converts a numeric value to alias label for days of week.
 */
export function convertNumberToAlias(value: number): string {
    const dayOfWeekObj = Object.values(daysOfWeek).find(
        obj => obj.code === value,
    );

    return dayOfWeekObj ? dayOfWeekObj.label : value.toString();
}

/**
 * Parses cron expression into UI elements form based on certain criteria.
 * If expression is parsable, schedule picker will be shown.
 * Otherwise cron expression input will be shown.
 */
export function parseCronExpression(config: IExtendedScheduledScaling): void {
    const { cron_expression: expression } = config;

    // If cron Expression is missing, use schedule picker.
    if (!expression || expression.length === 0) {
        config.useSchedulePicker = true;
        config.recurrence = '';
        config.desiredTime = '';
        config.occursOn = [];

        return;
    }

    if (!isCronExpressionParsable(expression)) {
        config.useSchedulePicker = false;

        return;
    }

    config.useSchedulePicker = true;

    const {
        recurrence,
        occursOn,
        desiredTime,
    } = determineScheduleFrequency(expression);

    config.desiredTime = desiredTime;
    config.recurrence = recurrence;
    config.occursOn = occursOn;
}

/**
 * Generates cron expression string from schedule picker values, if present.
 * Uses cron-parser library for conversion to string.
 */
export function generateCronExpression(config: IExtendedScheduledScaling): void {
    const { useSchedulePicker } = config;

    if (!useSchedulePicker) {
        return;
    }

    const {
        recurrence,
        occursOn,
        desiredTime,
    } = config;

    const cronFields: CronFields =
        convertPickerValuesToCronFields(recurrence, desiredTime, occursOn);

    if (isUndefined(cronFields)) {
        return;
    }

    const cronObj = fieldsToExpression(cronFields);

    config.cron_expression = cronObj.stringify();
}

/**
 * Method to test if cron expression is parsable, based on the following criteria:
 *  1. Passes basic validation.
 *  2. No Step Values in minutes, hours, daysOfMonth and months.
 *  3. No Ranges/Lists in minutes, hours, daysOfMonth and months.
 *  4. No '*' for minutes and hours.
 *  5. Only '*' allowed for months.
 *  6. Recurence should not be ambiguous, that is,
 *      definite numeric values for both daysOfMonth and daysOfWeek.
 */
function isCronExpressionParsable(expression: string): boolean {
    const isValid = isValidCronExpression(expression);

    // Cron Expression should be valid.
    if (!isValid) {
        return false;
    }

    const cronObj = cron(expression, cronValidatePreset);

    const {
        minutes,
        hours,
        daysOfMonth,
        months,
        daysOfWeek,
    } = cronObj.getValue() as CronData;

    // Step values should not be present.
    if (isStepValuePresent(minutes, hours, daysOfMonth, months)) {
        return false;
    }

    // Ranges or lists should not be present.
    if (isRangeOrListPresent(minutes, hours, daysOfMonth, months)) {
        return false;
    }

    // Time values should not be 'all'(*).
    if (isAllOrAnyPresent(minutes, hours)) {
        return false;
    }

    // Month value should be 'all'(*).
    if (!isAllOrAnyPresent(months)) {
        return false;
    }

    /**
     * Both daysOfMonth and daysOfWeek should not have specific values.
     * If both have specific values, it creates ambiguity about the recurrence.
     */
    if (!isAllOrAnyPresent(daysOfMonth, daysOfWeek)) {
        return false;
    }

    return true;
}

/**
 * Checks if 'step' (/) values are present in any of the given arguments.
 * Returns true if found, otherwise returns false.
 */
function isStepValuePresent(...args: string[]): boolean {
    return args.some(
        value => value.includes('/'),
    );
}

/**
 * Checks if 'range' (-) or 'list' (,) of values are present in any of the given arguments.
 * Returns true if found, otherwise returns false.
 */
function isRangeOrListPresent(...args: string[]): boolean {
    return args.some(
        value => value.includes('-') || value.includes(','),
    );
}

/**
 * Checks if 'all' (*) or 'any' (?) values are present in any of the given arguments.
 * Returns true if found, otherwise returns false.
 */
function isAllOrAnyPresent(...args: string[]): boolean {
    return args.some(
        fieldVal => fieldVal === '*' || fieldVal === '?',
    );
}

/**
 * Determines the recuurence, desired time and occurs on value,
 * for parsable cron expressions.
 */
function determineScheduleFrequency(expression: string):
{recurrence: string, occursOn: number[], desiredTime: string} {
    const result = {
        recurrence: '',
        occursOn: [] as number[],
        desiredTime: '',
    };

    const cronObj = cron(expression, cronValidatePreset);

    const {
        minutes,
        hours,
        daysOfMonth,
    } = cronObj.getValue() as CronData;

    let { daysOfWeek } = cronObj.getValue() as CronData;

    result.desiredTime = getFormattedTimeString(hours, minutes);

    if (isAllOrAnyPresent(daysOfMonth)) {
        if (isAllOrAnyPresent(daysOfWeek)) {
            result.recurrence = Recurrence.Daily;
        } else {
            result.recurrence = Recurrence.Weekly;

            daysOfWeek = convertDayOfWeekAliases(daysOfWeek);

            result.occursOn = getOccursOnForWeeklyRecurrence(daysOfWeek);
        }
    } else if (isAllOrAnyPresent(daysOfWeek)) {
        result.recurrence = Recurrence.Monthly;
        result.occursOn = [+daysOfMonth];
    }

    return result;
}

/**
 * Creates time string in the format hh:mm.
 */
function getFormattedTimeString(hours: string, minutes: string): string {
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Converts all aliases to numeric value for days of week string.
 *
 * Input: 'sun-tue,thu'
 * Output: '0-2,4'
 */
function convertDayOfWeekAliases(daysOfWeek: string): string {
    let splitArr: any[] = daysOfWeek.split(/([-,]+)/);

    splitArr = splitArr.map(val => convertAliasToNumber(val));

    return splitArr.join('');
}

/**
 * Converts an alias to numeric value for days of week.
 */
function convertAliasToNumber(value: string): string {
    const upperCaseValue = value.toUpperCase().trim();
    const isAlias = upperCaseValue.match('^[A-Z]{3}$');

    return !isNull(isAlias) ?
        daysOfWeekMapping[upperCaseValue] :
        value;
}

/**
 * Converts steps, ranges and lists for days of week into flat numeric array.
 *
 * Input: '0-2,4-5'
 * Output: [0,1,2,4,5]
 *
 * Input: '0,1-5/2'
 * Output: [0,1,3,5]
 */
function getOccursOnForWeeklyRecurrence(daysOfWeek: string): number[] {
    if (daysOfWeek.includes('/')) {
        const { fields: { dayOfWeek } } = parseExpression(`* * * * ${daysOfWeek}`);
        const daysOfWeekSet = new Set(dayOfWeek);

        // Since both 0 and 7 represent Sunday, we need to ensure only '0' is used
        if (daysOfWeekSet.has(0) || daysOfWeekSet.has(7)) {
            daysOfWeekSet.add(0);
            daysOfWeekSet.delete(7);
        }

        return [...daysOfWeekSet];
    }

    const occursOnArr: number[] = [];

    daysOfWeek.split(',').forEach(val => {
        if (val.includes('-')) {
            // Convert range into a flat list.
            const arrLen = +val[2] - +val[0] + 1;

            for (let i = 0; i < arrLen; i++) {
                occursOnArr.push(i + +val[0]);
            }
        } else {
            occursOnArr.push(+val);
        }
    });

    return occursOnArr;
}

/**
 * Converts the values from schedule picker into CronFields format,
 * from cron-parser library.
 */
function convertPickerValuesToCronFields(
    recurrence: string,
    desiredTime: string,
    occursOn: number[],
): CronFields {
    const { fields: baseFields } = parseExpression(baseCronExpression);

    const selectedTimeValue: string[] = desiredTime.split(':');

    let result: CronFields;

    switch (recurrence) {
        case Recurrence.Daily:
            result = {
                dayOfWeek: baseFields.dayOfWeek,
                month: baseFields.month,
                dayOfMonth: baseFields.dayOfMonth,
                hour: [+selectedTimeValue[0]] as HourRange[],
                minute: [+selectedTimeValue[1]] as SixtyRange[],
                second: baseFields.second,
            };
            break;

        case Recurrence.Weekly:
            result = {
                dayOfWeek: occursOn as DayOfTheWeekRange[],
                month: baseFields.month,
                dayOfMonth: baseFields.dayOfMonth,
                hour: [+selectedTimeValue[0]] as HourRange[],
                minute: [+selectedTimeValue[1]] as SixtyRange[],
                second: baseFields.second,
            };
            break;

        case Recurrence.Monthly:
            result = {
                dayOfWeek: baseFields.dayOfWeek,
                month: baseFields.month,
                dayOfMonth: occursOn as MonthRange[],
                hour: [+selectedTimeValue[0]] as HourRange[],
                minute: [+selectedTimeValue[1]] as SixtyRange[],
                second: baseFields.second,
            };
            break;

        default:
    }

    return result;
}
