/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'bgp-peers-page';
const prefix = `${moduleName}.${componentName}`;

export const peersGridHeaderLabel = `${prefix}.peersGridHeaderLabel`;
export const peerIpColumnHeader = `${prefix}.peerIpColumnHeader`;
export const upOrDownTimeColumnHeader = `${prefix}.upOrDownTimeColumnHeader`;
export const vrfColumnHeader = `${prefix}.vrfColumnHeader`;
export const bgpPeerIdleStateLabel = `${prefix}.bgpPeerIdleStateLabel`;
export const bgpPeerIdleStateReason = `${prefix}.bgpPeerIdleStateReason`;
export const bgpPeerNotApplicableStateLabel = `${prefix}.bgpPeerNotApplicableStateLabel`;
export const bgpPeerNotApplicableStateReason = `${prefix}.bgpPeerNotApplicableStateReason`;
export const bgpPeerNotEstablishedStateLabel = `${prefix}.bgpPeerNotEstablishedStateLabel`;
export const bgpPeerNotEstablishedStateReason = `${prefix}.bgpPeerNotEstablishedStateReason`;
export const bgpPeerPrefixExceededStateLabel = `${prefix}.bgpPeerPrefixExceededStateLabel`;
export const bgpPeerPrefixExceededStateReason = `${prefix}.bgpPeerPrefixExceededStateReason`;
export const bgpPeerEstablishedStateLabel = `${prefix}.bgpPeerEstablishedStateLabel`;

export const ENGLISH = {
    [peersGridHeaderLabel]: 'Peers ({0})',
    [peerIpColumnHeader]: 'Peer IP',
    [upOrDownTimeColumnHeader]: 'Up/Down Time',
    [vrfColumnHeader]: 'VRF',
    [bgpPeerIdleStateLabel]: 'Idle',
    [bgpPeerIdleStateReason]: 'It\'s the Initial state, please refresh to update. / Not tracking due to Peer/BGP shutdown',
    [bgpPeerNotApplicableStateLabel]: 'Not Applicable',
    [bgpPeerNotApplicableStateReason]: 'On the SE, in {0} vrf, no Interface is configured with Peer\'s reachability network.',
    [bgpPeerNotEstablishedStateLabel]: 'Not Established',
    [bgpPeerNotEstablishedStateReason]: 'BGP session Down since the configuration of Peer.',
    [bgpPeerPrefixExceededStateLabel]: 'Prefix Exceeded',
    [bgpPeerPrefixExceededStateReason]: 'Prefixes learnt from the peer exceeded the Max Limit, please check {0} VRF\'s config.',
    [bgpPeerEstablishedStateLabel]: 'Established',
};
