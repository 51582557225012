/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';

import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';

import { of } from 'rxjs';

import {
    catchError,
    map,
    switchMap,
} from 'rxjs/operators';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { UserAccountService } from './user-account.service';
import * as UserAccountActions from './user-account.actions';

@Injectable()
export class UserAccountEffects {
    /**
     * Fetches User Account information to store.
     */
    public fetchUserAccount$ = createEffect(() => this.actions$.pipe(
        ofType(UserAccountActions.fetchUserAccount),
        switchMap(() => this.userAccountService.fetchUserAccount()
            .pipe(
                map(userAccount => {
                    return UserAccountActions.fetchUserAccountSuccess({ payload: userAccount });
                }),
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(UserAccountActions.fetchUserAccountError());
                }),
            )),
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly devLoggerService: DevLoggerService,
        private readonly userAccountService: UserAccountService,
    ) {}
}
