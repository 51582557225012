/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { Tier1LogicalRouterInfoConfigItem } from './tier1-logical-router-info.config-item.factory';

interface INsxtTier1SegmentManualModeConfig {
    tier1_lrs: RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem>;
}

export class NsxtTier1SegmentManualModeConfigItem
    extends MessageItem<INsxtTier1SegmentManualModeConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentManualMode',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     * Remove empty config-items from repeated-message-item and update its config.
     * If vpc_mode is true, 'tier1_lrs' is optional and the user can add empty entries to the grid.
     * UI needs to filter empty entries/config-items and then pass to API call.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { tier1LrsConfig } = this;

        tier1LrsConfig.updateConfig(tier1LrsConfig.getDataToSave());

        if (!tier1LrsConfig.count) {
            delete this.config.tier1_lrs;
        }
    }

    /**
     * Removes all configured Tier1 LRs.
     */
    public removeTier1LRs(): void {
        this.tier1LrsConfig.removeAll();
    }

    /**
     * Return 'tier1_lrs' configuration.
     */
    public get tier1LrsConfig(): RepeatedMessageItem<Tier1LogicalRouterInfoConfigItem> {
        return this.config.tier1_lrs;
    }
}
