/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const groupsLoadBalancingAlgorithmDropdownLabel = `${prefix}.groupsLoadBalancingAlgorithmDropdownLabel`;
export const enableGslbServiceCheckboxLabel = `${prefix}.enableGslbServiceCheckboxLabel`;
export const topologyPolicyEnabledCheckboxLabel = `${prefix}.topologyPolicyEnabledCheckboxLabel`;
export const resolveCnameCheckboxLabel = `${prefix}.resolveCnameCheckboxLabel`;
export const wildcardMatchCheckboxLabel = `${prefix}.wildcardMatchCheckboxLabel`;
export const ednsClientSubnetCheckboxLabel = `${prefix}.ednsClientSubnetCheckboxLabel`;
export const sitePersistenceEnabledCheckboxLabel = `${prefix}.sitePersistenceEnabledCheckboxLabel`;
export const persistenceProfileDropdownPlaceholder = `${prefix}.persistenceProfileDropdownPlaceholder`;
export const persistenceProfileDropdownLabel = `${prefix}.persistenceProfileDropdownLabel`;
export const pkiProfileDropdownLabel = `${prefix}.pkiProfileDropdownLabel`;
export const pkiProfileDropdownPlaceholder = `${prefix}.pkiProfileDropdownPlaceholder`;
export const controllerHealthStatusCheckboxLabel = `${prefix}.controllerHealthStatusCheckboxLabel`;
export const ipsReturnedByDnsInputLabel = `${prefix}.ipsReturnedByDnsInputLabel`;
export const ttlServedByDnsServiceInputLabel = `${prefix}.ttlServedByDnsServiceInputLabel`;
export const defaultDnsServiceInputPlaceholder = `${prefix}.defaultDnsServiceInputPlaceholder`;
export const minimumServersInputLabel = `${prefix}.minimumServersInputLabel`;
export const minimumServersInputPlaceholder = `${prefix}.minimumServersInputPlaceholder`;
export const healthMonitorScopeInputLabel = `${prefix}.healthMonitorScopeInputLabel`;
export const downResponseTypeInputLabel = `${prefix}.downResponseTypeInputLabel`;
export const fallbackIpInputLabel = `${prefix}.fallbackIpInputLabel`;
export const fallbackIpInputPlaceholder = `${prefix}.fallbackIpInputPlaceholder`;
export const domainSectionHeader = `${prefix}.domainSectionHeader`;
export const poolSectionHeader = `${prefix}.poolSectionHeader`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit GSLB Service',
    [modalTitleCreate]: 'Create GSLB Service',
    [groupsLoadBalancingAlgorithmDropdownLabel]: 'Groups Load Balancing Algorithm',
    [enableGslbServiceCheckboxLabel]: 'Enable GSLB Service',
    [topologyPolicyEnabledCheckboxLabel]: 'Topology Policy',
    [resolveCnameCheckboxLabel]: 'Resolve CNAME',
    [wildcardMatchCheckboxLabel]: 'Wildcard Match',
    [ednsClientSubnetCheckboxLabel]: 'EDNS Client Subnet',
    [sitePersistenceEnabledCheckboxLabel]: 'Site Persistence',
    [persistenceProfileDropdownPlaceholder]: 'Select Persistence Profile',
    [persistenceProfileDropdownLabel]: 'Site Cookie Application Persistence Profile',
    [pkiProfileDropdownLabel]: 'PKI Profile',
    [pkiProfileDropdownPlaceholder]: 'Select PKI Profile',
    [controllerHealthStatusCheckboxLabel]: 'Controller Health Status',
    [ipsReturnedByDnsInputLabel]: 'Number of IPs returned by DNS Server',
    [ttlServedByDnsServiceInputLabel]: 'TTL served by DNS Service',
    [defaultDnsServiceInputPlaceholder]: 'Default from DNS Service',
    [minimumServersInputLabel]: 'Minimum number of Servers',
    [minimumServersInputPlaceholder]: 'Enter Minimum number of servers',
    [healthMonitorScopeInputLabel]: 'Health Monitor Scope',
    [downResponseTypeInputLabel]: 'Down Response',
    [fallbackIpInputLabel]: 'Fallback IP',
    [fallbackIpInputPlaceholder]: 'Enter IP(0.0.0.0)',
    [domainSectionHeader]: 'Domain',
    [poolSectionHeader]: 'GSLB Pool',
};
