/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { VipAutoscaleGroup } from 'object-types';
import { StringService } from 'string-service';

import {
    IVipAutoscaleGroup,
    IVipAutoscalePolicy,
    IVipAutoscaleZones,
} from 'generated-types';

import {
    VipAutoscaleConfigurationConfigItem,
} from './vip-autoscale-configuration.config-item.factory';

type TVipAutoscaleGroupPartial = Omit<IVipAutoscaleGroup, 'configuration' | 'policy'>;

interface IVipAutoscaleGroupConfig extends TVipAutoscaleGroupPartial {
    configuration?: VipAutoscaleConfigurationConfigItem;
    policy?: MessageItem<IVipAutoscalePolicy>;
}

/**
 * @description VIP AutoScale Group Message Item.
 * @author vgohil
 */
export class VipAutoscaleGroupConfigItem extends MessageItem<IVipAutoscaleGroupConfig> {
    public static ajsDependencies = [
        'stringService',
    ];

    /**
     * Ref. to StringService instance.
     */
    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: VipAutoscaleGroup,
            ...args,
        };

        super(extendedArgs);

        this.stringService = this.getAjsDependency_('stringService');
    }

    /**
     * Returns the configuration (VipAutoscaleConfiguration) of Vip Autoscale Group.
     */
    public get configuration(): VipAutoscaleConfigurationConfigItem {
        return this.config.configuration;
    }

    /**
     * @override
     * Delete keys which are not set and,
     * Set field with required value from modal before sending API request.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { zones } = this.configuration;

        if (zones.count) {
            zones.config.forEach((zone: MessageItem<IVipAutoscaleZones>) => {
                if (zone.config.subnet_uuid) {
                    zone.config.subnet_uuid = this.stringService.slug(zone.config.subnet_uuid);
                }
            });
        } else {
            delete this.config.configuration;
            delete this.config.policy;
        }
    }

    /** @override */
    // eslint-disable-next-line class-methods-use-this
    protected requiredFields(): string[] {
        return [
            'configuration',
            'policy',
        ];
    }
}
