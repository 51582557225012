<div class="selected-seg-list">
    <expander
        class="selected-seg-list__expander"
        openerClassName="selected-seg-list__header"
        [expanded]="true"
    >
        <opener>
           <span> 
                <b>{{ l10nKeys.seGroupPluralLabel | vtranslate : segUpgradeNodes.length }}</b>
                {{ l10nKeys.toBeUpdatedLabel | vtranslate }}
            </span>
        </opener>
        <content>
            <div class="selected-seg-list__content">
                <avi-data-grid
                    [config]="selectedSegGridConfig"
                    [rows]="segUpgradeNodes"
                    gridTitle="{{ l10nKeys.selectedSegHeadersLabel | vtranslate : segUpgradeNodes.length }}"
                ></avi-data-grid>
            </div>
        </content>
    </expander>
</div>
