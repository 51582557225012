/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    GslbAviSiteConfigComponent,
    GslbCheckpointsModalComponent,
    GslbClientIpAddrGroupConfigComponent,
    GslbDnsVirtualServicesGridComponent,
    GslbFederationCheckpointsListComponent,
    GslbGeoDbProfileEntriesGridComponent,
    GslbGeoDbProfileModalComponent,
    GslbGeoLocationConfigComponent,
    GslbGeoProfileListComponent,
    GslbHealthMonitorProxyGridComponent,
    GslbIpAddressGridComponent,
    GslbPlacementGridComponent,
    GslbPlacementModalComponent,
    GslbPoolMemberDomainNameComponent,
    GslbPoolMemberTypeComponent,
    GslbPoolMemberVirtualServiceComponent,
    GslbServiceDomainNamesGridComponent,
    GslbServiceGeoLocationComponent,
    GslbServiceHealthMonitorsGridComponent,
    GslbServiceModalComponent,
    GslbServicePoolMemberModalComponent,
    GslbServicePoolMembersGridComponent,
    GslbServicePoolModalComponent,
    GslbServicePoolsGridComponent,
    GslbSettingModalComponent,
    GslbSiteDnsVsModalComponent,
    GslbSiteEditCredentialsDialogComponent,
    GslbSiteModalComponent,
    GslbSitePreviewComponent,
    GslbSubdomainModalComponent,
    GslbSubdomainsGridComponent,
    GslbThirdPartySiteConfigComponent,
} from '.';

import {
    federationCheckpointItemProvider,
    gslbGeoDbProfileCollectionProvider,
    gslbVSCollectionProvider,
} from './ajs-upgraded-providers';

const gslbComponents = [
    GslbCheckpointsModalComponent,
    GslbClientIpAddrGroupConfigComponent,
    GslbDnsVirtualServicesGridComponent,
    GslbFederationCheckpointsListComponent,
    GslbGeoDbProfileEntriesGridComponent,
    GslbGeoDbProfileModalComponent,
    GslbGeoProfileListComponent,
    GslbSettingModalComponent,
    GslbSiteDnsVsModalComponent,
    GslbSiteModalComponent,
    GslbPoolMemberDomainNameComponent,
    GslbPoolMemberTypeComponent,
    GslbPoolMemberVirtualServiceComponent,
    GslbServiceDomainNamesGridComponent,
    GslbServiceGeoLocationComponent,
    GslbServiceHealthMonitorsGridComponent,
    GslbServiceModalComponent,
    GslbServicePoolMemberModalComponent,
    GslbServicePoolMembersGridComponent,
    GslbServicePoolModalComponent,
    GslbServicePoolsGridComponent,
    GslbSitePreviewComponent,
    GslbSiteEditCredentialsDialogComponent,
    GslbHealthMonitorProxyGridComponent,
    GslbIpAddressGridComponent,
    GslbAviSiteConfigComponent,
    GslbGeoLocationConfigComponent,
    GslbThirdPartySiteConfigComponent,
    GslbSubdomainsGridComponent,
    GslbSubdomainModalComponent,
    GslbPlacementGridComponent,
    GslbPlacementModalComponent,
];

@NgModule({
    declarations: [
        ...gslbComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        CoreModule,
        DataGridModule,
        SharedModule,
    ],
    providers: [
        gslbGeoDbProfileCollectionProvider,
        gslbVSCollectionProvider,
        federationCheckpointItemProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class GslbModule {}
