<div class="avi-slider">   
    <div class="avi-slider__container">
        <div
            class="avi-slider__input-container"
            *ngIf="!hideInput"
        >
            <input
                class="avi-slider__input"
                type="number"
                [name]="name"
                [placeholder]="placeholder"
                [step]="stepValue"
                [(ngModel)]="value"
                [disabled]="disabled"
                aria-label="avi-slider-input"
            />
        </div>
        <div
            class="avi-slider__range-container"
            [ngClass]="{'avi-slider__range-container--fullwidth': hideInput}"
        >
            <cds-range
                #cdsRangeRef
                layout="vertical" 
                [status]="status"
            >
                <input 
                    type="range"
                    [min]="minLimit"
                    [max]="maxLimit"
                    [step]="stepValue"
                    [(ngModel)]="value"
                    [aria-valuemin]="minLimit"
                    [aria-valuemax]="maxLimit"
                    [aria-valuenow]="value"
                    [disabled]="disabled"
                    aria-label="avi-slider"
                />
            </cds-range>
        </div>
    </div>
    <div
        *ngIf="helperText"
        [hidden]="invalid"
        class="clr-subtext"
    >
        {{ helperText }}
    </div>
    <avi-input-container-error
        class="avi-slider__error-text clr-subtext"
        customError="{{ errorText }}"
        [validationErrors]="validationErrors"
        [hidden]="!invalid"
    ></avi-input-container-error>
</div>
