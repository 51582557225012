/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'csrf-policy-list-page-module';
const componentName = 'csrf-policy-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleRules = `${prefix}.columnTitleRules`;

export const ENGLISH = {
    [headerLabel]: 'CSRF Policies',
    [columnTitleRules]: 'Rules',
};
