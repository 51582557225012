/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createReducer, on } from '@ngrx/store';
import * as UserPreferencesActions from './user-preferences.actions';
import { initialState, IUserPreferencesState } from './user-preferences.state';

export const userPreferencesReducer = createReducer(
    initialState,
    on(
        UserPreferencesActions.fetchUserPreferences,
        (state: IUserPreferencesState): IUserPreferencesState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        UserPreferencesActions.fetchUserPreferencesSuccess,
        (state: IUserPreferencesState, { payload }): IUserPreferencesState => ({
            ...state,
            userPreferences: {
                ...payload,
            },
            loading: false,
            loaded: true,
        }),
    ),
    on(
        UserPreferencesActions.saveUserPreferencesSuccess,
        (state: IUserPreferencesState, { payload }): IUserPreferencesState => ({
            ...state,
            userPreferences: {
                ...payload,
            },
            loading: false,
        }),
    ),
    on(
        UserPreferencesActions.fetchUserPreferencesError,
        UserPreferencesActions.saveUserPreferencesError,
        (state: IUserPreferencesState): IUserPreferencesState => ({
            ...state,
            loading: false,
        }),
    ),
);
