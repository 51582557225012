/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'gslb-pool';

export const gslbServicePoolModalBreadcrumbTitle = `${prefix}.gslbServicePoolModalBreadcrumbTitle`;
export const gslbServicePoolMemberModalBreadcrumbTitle = `${prefix}.gslbServicePoolMemberModalBreadcrumbTitle`;

export const ENGLISH = {
    [gslbServicePoolModalBreadcrumbTitle]: 'GSLB Pool',
    [gslbServicePoolMemberModalBreadcrumbTitle]: 'Pool Member',
};
