/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'avi-ajs-confirm';
const prefix = `${moduleName}.${componentName}`;

export const confirmationText = `${prefix}.confirmationText`;
export const defaultCancelButtonText = `${prefix}.defaultCancelButtonText`;
export const defaultConfirmButtonText = `${prefix}.defaultConfirmButtonText`;
export const headerText = `${prefix}.headerText`;

export const ENGLISH = {
    [confirmationText]: 'Would you like to continue?',
    [defaultCancelButtonText]: 'Cancel',
    [defaultConfirmButtonText]: 'Ok',
    [headerText]: 'Please Confirm',
};
