/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-sites-list-page';

const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const passiveLabel = `${prefix}.passiveLabel`;
export const leaderLabel = `${prefix}.leaderLabel`;
export const thirdPartyLabel = `${prefix}.thirdPartyLabel`;
export const thirdPartySiteLabel = `${prefix}.thirdPartySiteLabel`;
export const newSiteLabel = `${prefix}.newSiteLabel`;
export const editGslbSetting = `${prefix}.editGslbSetting`;
export const replicationLabel = `${prefix}.replicationLabel`;
export const dnsVsLabel = `${prefix}.dnsVsLabel`;
export const deleteGSLBConfigurationsMessage = `${prefix}.deleteGSLBConfigurationsMessage`;
export const columnValueSuffix = `${prefix}.columnValueSuffix`;
export const enableLabel = `${prefix}.enableLabel`;
export const deactivateLabel = `${prefix}.deactivateLabel`;
export const continuousReplicationLabel = `${prefix}.continuousReplicationLabel`;
export const adaptiveReplicationLabel = `${prefix}.adaptiveReplicationLabel`;
export const gridHeader = `${prefix}.gridHeader`;

export const ENGLISH = {
    [headerLabel]: 'Sites',
    [passiveLabel]: 'Passive',
    [leaderLabel]: 'Leader',
    [thirdPartyLabel]: 'Third-Party',
    [thirdPartySiteLabel]: 'Third-Party Site',
    [newSiteLabel]: 'New Site',
    [editGslbSetting]: 'Edit GSLB Setting',
    [replicationLabel]: 'Replication',
    [dnsVsLabel]: 'DNS VS',
    [deleteGSLBConfigurationsMessage]: 'All GSLB Site configuration will be removed.',
    [columnValueSuffix]: '(+{0} More)',
    [enableLabel]: 'Enable',
    [deactivateLabel]: 'Deactivate',
    [continuousReplicationLabel]: 'Continuous Replication',
    [adaptiveReplicationLabel]: 'Adaptive Replication',
    [gridHeader]: 'Sites <span class="gslb-sites-list-page__replication-mode-value">{0}</span> ({1})',
};
