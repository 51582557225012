<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate :
        editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="network_id"
                    for="network-id"
                    required
                >
                    {{ globalL10nKeys.networkLabel | vtranslate }}
                </label>
                <avi-dropdown
                    name="network-id"
                    id="network-id"
                    [disableCreate]="true"
                    [disableEdit]="true"
                    [options]="availableSubnetOptions"
                    [(ngModel)]="editable.config.network_id"
                    (ngModelChange)="handleSelectSubnet($event)"
                    placeholder="{{ globalL10nKeys.networkPlaceholderLabel | vtranslate }}"
                    required
                ></avi-dropdown>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="subnet"
                        for="subnet"
                    >
                        {{ globalL10nKeys.ipv4AddressInputLabel | vtranslate }}
                    </label>
                    <input
                        id="subnet"
                        name="subnet"
                        type="text"
                        [(ngModel)]="this.editable.config.subnet.subnet"
                        regexPattern="subnet"
                        clrInput
                        disabled
                        placeholder="{{ globalL10nKeys.ipv4AddressPlaceholderLabel | vtranslate }}"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="subnet6"
                        for="subnet6"
                    >
                        {{ globalL10nKeys.ipv6AddressInputLabel | vtranslate }}
                    </label>
                    <input
                        id="subnet6"
                        name="subnet6"
                        type="text"
                        regexPattern="ipv6Prefix"
                        [(ngModel)]="this.editable.config.subnet6.subnet"
                        clrInput
                        disabled
                        placeholder="{{ globalL10nKeys.ipv6AddressPlaceholderLabel | vtranslate }}"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
