/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'update-node-status-card';
const prefix = `${moduleName}.${componentName}`;

export const updateOperationLabel = `${prefix}.updateOperationLabel`;
export const updateOperationStartTimeLabel = `${prefix}.updateOperationStartTimeLabel`;
export const updateSuccessfulLabel = `${prefix}.updateSuccessfulLabel`;
export const updateStoppedLabel = `${prefix}.updateStoppedLabel`;
export const updateFailedLabel = `${prefix}.updateFailedLabel`;
export const suppressWarningHeader = `${prefix}.suppressWarningHeader`;
export const warningMessage = `${prefix}.warningMessage`;
export const viewTranscriptTooltipLabel = `${prefix}.viewTranscriptTooltipLabel`;

export const ENGLISH = {
    [updateOperationLabel]: 'Operation',
    [updateOperationStartTimeLabel]: 'Started',
    [updateSuccessfulLabel]: 'Update Successful',
    [updateFailedLabel]: 'Update Failed',
    [updateStoppedLabel]: 'Update Stopped',
    [suppressWarningHeader]: 'Suppress Warning',
    [viewTranscriptTooltipLabel]: 'View Transcript',
    [warningMessage]: 'Some Prechecks completed with warnings. You can suppress them and continue with Update.',
};
