<avi-tooltip-icon
    shape="{{ shape }}"
    size="{{ size }}"
    [delay]="delay"
    [showOnClick]="showOnClick"
    [positionsPriority]="positionsPriority"
    iconClassName="{{ iconClassName }}"
    (onPositionChange)="handlePositionChange($event)"
    [solid]="solid"
    [status]="status"
>
    <avi-tooltip-icon_content>
        <avi-tooltip-content [tooltipPosition]="tooltipPosition">
            <ng-content></ng-content>
        </avi-tooltip-content>
    </avi-tooltip-icon_content>
</avi-tooltip-icon>
