/**
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { L10nService } from '@vmw/ngx-vip';

import { ControllerInitialDataSelectors } from 'ng/root-store/controller-initial-data-store';

import * as l10n from './system-about-info.l10n';
import './system-about-info.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component containing information about the system,
 *     like controllerBuild, controllerVersion, controllerPatch and controllerBuildDate.
 * @author alextsg, Kondiparthi Shanmukha Sarath.
 */
@Component({
    selector: 'system-about-info',
    templateUrl: './system-about-info.component.html',
})
export class SystemAboutInfoComponent {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Version data returned by initial-data api.
     */
    public initialVersionData$ = this.store.select(
        ControllerInitialDataSelectors.selectAboutAppData,
    );

    constructor(
        l10nService: L10nService,
        private readonly store: Store,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
