/**
 * Module containing Authentication/Authorization Settings related components.
 * @preferred
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { SystemModule } from 'ng/modules/system/system.module';
import { MatchModule } from '../match/match.module';
import { DataGridModule } from '../data-grid/data-grid.module';

import {
    authMappingProfileCollectionProvider,
    authProfileCollectionProvider,
    roleCollectionProvider,
    systemConfigServiceProvider,
    tenantCollectionProvider,
    userProfileCollectionProvider,
} from './ajs-upgraded-providers';

import {
    AuthMappingProfileModalComponent,
    AuthMappingRuleModalComponent,
    AuthMappingRulesGridComponent,
    AuthRuleCustomMappingComponent,
    MappingRuleAttributeMatchComponent,
    MappingRuleGroupMatchComponent,
    UserAccountProfileMappingComponent,
    UserRoleMappingComponent,
    UserTenantMappingComponent,
} from '.';

const authSettingsComponents = [
    AuthMappingProfileModalComponent,
    AuthMappingRuleModalComponent,
    AuthMappingRulesGridComponent,
    AuthRuleCustomMappingComponent,
    MappingRuleAttributeMatchComponent,
    MappingRuleGroupMatchComponent,
    UserAccountProfileMappingComponent,
    UserRoleMappingComponent,
    UserTenantMappingComponent,
];

/**
 * @description Module containing Authentication/Authorization Settings related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...authSettingsComponents,
    ],
    imports: [
        AviFormsModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrSpinnerModule,
        DataGridModule,
        FormsModule,
        MatchModule,
        SharedModule,
        SystemModule,
    ],
    providers: [
        authMappingProfileCollectionProvider,
        authProfileCollectionProvider,
        roleCollectionProvider,
        systemConfigServiceProvider,
        tenantCollectionProvider,
        userProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AuthSettingsModule {}
