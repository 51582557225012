/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    copy,
    IHttpResponse,
    IPromise,
    IQService,
} from 'angular';

import { filter, some } from 'underscore';
import { Base } from 'ajs/modules/data-model/factories/base.factory';

type objectRef = string;

/**
 * @constructor
 * @memberOf module:avi/core
 * @alias DefaultValues
 * @extends Base
 * @desc
 *     Service loads default objects settings (property values) as well as list of 'system'
 *     objects and provides some convenience methods to work with em.
 *     Generally loaded only once as ui-router dependency in non-blocking fashion.
 * @author Ram Pal
 */
export class DefaultValues extends Base {
    private propLookup: any;
    private defaults: Record<string, objectRef[]>;
    private systemObjects: Record<string, any>;
    private isLoaded: boolean;
    private loadPromise: IPromise<boolean> | null;

    constructor(args = {}) {
        super(args);

        this.propLookup = this.getAjsDependency_('propLookup');

        /**
         * Hash of default object configurations by object type.
         */
        this.defaults = {};

        /**
         * Hash of 'system' object refs by object type. Important details:
         *
         * Object types belonging to cloud context have name duplicates (combination per
         * every cloud), examples are vrfcontext and serviceengineggroup.
         *
         * Doesn't contain tenant's 'system' objects, such as "cloned-written" profiles.
         * Only top level 'system' objects are present.
         */
        this.systemObjects = {};

        /**
         * Flag to figure whether defaults have been loaded.
         */
        this.isLoaded = false;

        /**
         * Promise set on default-values load event. Set to null once loaded.
         */
        this.loadPromise = null;
    }

    /**
     * Fetches default object/item settings and system objects list from the server.
     * If already loaded will resolve the promise right away.
     */
    public load(force = false): IPromise<boolean> {
        const $q: IQService = this.getAjsDependency_('$q');

        if (this.loadPromise) {
            return this.loadPromise;
        }

        if (!force && this.isLoaded) {
            return $q.when(true);
        }

        const promise =
            this.request('get', '/api/default-values?include_name')
                .then((rsp: IHttpResponse<any>) => {
                    this.transformAfterLoad(rsp.data);

                    return true;
                });

        this.loadPromise = promise;

        promise
            .catch(() => this.removeData())
            .finally((): null => this.loadPromise = null);

        return promise;
    }

    /**
     * Method to get a system object ref by object type and name.
     * Throws an error if full duplicates are found. See {@link this.systemObjects} for
     * details.
     */
    // TODO introduce the black list of types with possible name duplicates: vrfcontext and
    // serviceenginegroup for sure
    public getSystemObjectRefByName(type: string, name: string): string {
        const { systemObjects: hash } = this;

        if (name && type in hash) {
            const list = filter(hash[type], (ref: any) => ref.name() === name);
            const { length } = list;

            if (length === 1) {
                return list[0];
            }

            if (length > 1) {
                throw new Error(
                    `"${type}" with name "${name}" has duplicates in system objects list`,
                );
            }
        }

        return '';
    }

    /**
     * Checks whether passed object type and id combination is representing a
     * 'system' object. Doesn't work for system objects 'nested' under the tenant, see
     * {@link this.systemObjects} for details.
     */
    public isSystemObject(type: string, id: string): boolean {
        const { systemObjects: hash } = this;

        if (id && type in hash) {
            return some(hash[type], (ref: any) => ref.slug() === id);
        }

        return false;
    }

    /**
     * Returns default object configuration by object type.
     */
    public getDefaultItemConfigByType(type: string): any {
        type = type && type.toLowerCase();

        if (type && type in this.defaults) {
            return copy(this.defaults[type]);
        }

        return null;
    }

    /**
     * Returns default configuration by property path starting with object type.
     */
    public getDefaultItemConfig(path: string): any {
        const config = this.propLookup(path, this.defaults);

        return copy(config);
    }

    /**
     * Parses the initial-data API response and stashes it into right places.
     */
    private transformAfterLoad(data: any): void {
        const { cloud: cloudDefaultConfig } = data;

        delete cloudDefaultConfig.cloudstack_configuration;
        delete cloudDefaultConfig.mesos_configuration;

        this.defaults = data;
        this.systemObjects = data.default_refs;

        delete data.default_refs;
        delete data.default; // same as above, but names only

        this.isLoaded = true;
    }

    /**
     * Removes data from the service.
     */
    private removeData(): void {
        this.cancelRequests();
        this.defaults = {};
        this.systemObjects = {};
        this.isLoaded = false;
        this.loadPromise = null;
    }
}

DefaultValues.ajsDependencies = [
    '$q',
    'propLookup',
];
