/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'events-list-sidebar';
const prefix = `${moduleName}.${componentName}`;

export const eventsAnalyticsLabel = `${prefix}.eventsAnalyticsLabel`;
export const objectTypePopoverLabel = `${prefix}.objectTypePopoverLabel`;
export const topObjectTypesPopoverHeaderLabel = `${prefix}.topObjectTypesPopoverHeaderLabel`;
export const objectNamePopoverLabel = `${prefix}.objectNamePopoverLabel`;
export const topNamesPopoverHeaderLabel = `${prefix}.topNamesPopoverHeaderLabel`;
export const eventIdPopoverLabel = `${prefix}.eventIdPopoverLabel`;
export const topEventIdsPopoverHeaderLabel = `${prefix}.topEventIdsPopoverHeaderLabel`;
export const modulePopoverLabel = `${prefix}.modulePopoverLabel`;
export const topModulesPopoverHeaderLabel = `${prefix}.topModulesPopoverHeaderLabel`;

export const ENGLISH = {
    [eventsAnalyticsLabel]: 'Events Analytics',
    [objectTypePopoverLabel]: 'Object Type',
    [topObjectTypesPopoverHeaderLabel]: 'Top Object Types',
    [objectNamePopoverLabel]: 'Object Name',
    [topNamesPopoverHeaderLabel]: 'Top Object Names',
    [eventIdPopoverLabel]: 'Event Id',
    [topEventIdsPopoverHeaderLabel]: 'Top Event Ids',
    [modulePopoverLabel]: 'Module',
    [topModulesPopoverHeaderLabel]: 'Top Modules',
};
