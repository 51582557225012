/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name CollMetric
 * @description
 *
 *     {@link Metric} received through collection metrics API.
 */

/**
 * @typedef {Object} ItemMetricTuple - Set of filters for timeseries API calls.
 * @property {string=} entity_uuid
 * @property {string=} pool_uuid
 * @property {string=} obj_id
 */
//TODO move some Collection API specific stuff from the Metric Service to here
angular.module('avi/metrics').service('CollMetric', ['Metric',
function(Metric) {
    /**
     * @class Analytics Collection API specific methods.
     * @extends Metric
     */
    return class CollMetric extends Metric {
        /**
         * Analytics Collection API requires sending a POST request with a payload
         * as an array of subrequest objects. Each subrequest should contain a unique id which
         * will be used as key in server's response hash, unitId properties (to filter
         * response appropriately) and metric_id as a list of comma separated metric names, step
         * and limit.
         * @override
         */
        requestConfig() {
            const
                tuple = this.item.getMetricsTuple(),
                objIds = this.getObjIdList_().join(),
                config = {
                    id: this.getSubrequestId_(),
                    metric_id: this.seriesId_ || this.getSeriesIdList().join(),
                };

            if (objIds) {
                config['obj_id'] = objIds;
            }

            if (this.aggregation) {
                config['dimension_aggregation'] = this.aggregation;

                if (this.dimensionLimit) {
                    config['dimension_limit'] = this.dimensionLimit;
                }
            }

            if (this.dimensions) {
                config.dimensions = this.dimensions;
            }

            if (this.filters_) {
                angular.extend(config, this.filters_);
            }

            // skipping the call in this case
            if (!config['metric_id']) {
                return null;
            }

            return angular.extend(tuple, config);
        }

        /**
         * Given a list of full ids filters em to ones which are expected to come in response.
         * Handles * for entity_uuid or obj_id appropriately (will accept any ID there).
         * @param {string[]} fullUnitIds
         * @returns {string[]}
         * @private
         */
        filterByFullUnitId_(fullUnitIds) {
            const
                separator = ',',
                itemIdKeys = [
                    'entity_uuid',
                    'pool_uuid',
                    'obj_id',
                ],
                itemIdAggKeys = [
                    'aggregate_entity',
                    '', // not yet supported
                    'aggregate_obj_id',
                ],
                request = this.requestConfig(),
                idsHash = {};

            // for each itemId creates an entry in hash (by id type) with another hash of expected
            // IDs and anyId boolean flag set to true when * is provided as item uuid
            itemIdKeys.forEach((propName, index) => {
                if (propName in request) {
                    const
                        hash = {},
                        { [propName]: idList } = request,
                        aggKey = itemIdAggKeys[index];

                    let anyId = false;

                    if (aggKey && request[aggKey] && idList !== '*') {
                        hash['AGGREGATED'] = true;
                    } else if (idList === '*') {
                        anyId = true;
                    } else if (idList) {
                        idList
                            .split(separator)
                            .forEach(id => hash[id] = true);
                    }

                    idsHash[propName] = {
                        hash,
                        anyId,
                    };
                }
            });

            // split fullId to separate ids and look for them in a appropriate hashes
            return fullUnitIds.filter(fullUnitId => {
                const
                    idList = fullUnitId.split(separator),
                    { length } = idList;

                return idList.reduce((acc, id, index) => {
                    // special case for situation when pool_uuid is not present but obj_id is
                    const idKey = index === 1 && length === 2 && !idsHash['pool_uuid'] ?
                        'obj_id' : itemIdKeys[index];

                    const { [idKey]: hash } = idsHash;

                    return acc && (hash.anyId || id in hash.hash);
                }, true);
            });
        }

        /**
         * Filters by request config id and then tries to find response object with
         * series by searching for unit id in a keys of a hash gotten from the back-end.
         * @returns {MetricSeries[]|{string: MetricSeries[]}}
         * @override
         **/
        filterResponse(rsp, anomRsp) {
            const { id: subrequestId } = this.requestConfig();

            // filters by id property provided in requestConfig. Since subrequests responses can
            // be combined there might be more than one and ids look like "id1,id2"
            const rsps = _.filter(rsp, (seriesListByFullUnidIdHash, id) =>
                id === subrequestId ||
                id.indexOf(',') !== -1 && _.contains(id.split(','), subrequestId));

            const fullSeriesListByFullUnitIdHash = {};

            // Creates a hash of all series by fullItemId. Since subrequest responses can get
            // combined by the back-end we might get more than one bucket. We gonna go through
            // all filtered buckets creating a hash of fullUnitId to the full list of series it
            // had in all subrequest responses combined. This introduces some redundancy
            // (responses for another subrequest might get included) to be filtered out here
            // later on and also by the Series instance.
            rsps.reduce(
                CollMetric.hashMerge_,
                fullSeriesListByFullUnitIdHash,
            );

            let sRsp = [];

            if (!_.isEmpty(fullSeriesListByFullUnitIdHash)) {
                const expected =
                    this.filterByFullUnitId_(Object.keys(fullSeriesListByFullUnitIdHash));

                const metricSeriesList = [];

                expected.forEach(fullUnitId =>
                    metricSeriesList.push(...fullSeriesListByFullUnitIdHash[fullUnitId]));

                if (metricSeriesList.length) {
                    sRsp = metricSeriesList;
                } else {
                    //DEPRECATED, legacy
                    console.warn(
                        `"${this.name}" metric for "${this.item.id}" filtering has failed, ` +
                        'nothing was found in response',
                        fullSeriesListByFullUnitIdHash,
                        expected,
                    );
                    // for redefined filterResponse methods only
                    sRsp = fullSeriesListByFullUnitIdHash;
                }
            }

            return {
                series: sRsp,
                anomalies: anomRsp,
            };
        }

        /**
         * Returns a unique within one API call subrequest id.
         * @returns {string}
         * @private
         */
        getSubrequestId_() {
            return this.name;
        }

        /**
         * Function to be passed into reduce to go over many series hashes making a single hash
         * with all the series. All arrays of same keys will get concatenated.
         * @param {{string: Object[]}} hash - Key is unitId, list elements are series.
         * @param {{string: Object[]}} series - Key is unitId, list elements are series.
         * @private
         * @static
         */
        static hashMerge_(hash, series) {
            //go through different IDs
            _.each(series, (seriesOfUnit, fullUnitId) => {
                if (!(fullUnitId in hash)) {
                    hash[fullUnitId] = [];
                }

                hash[fullUnitId].push(...seriesOfUnit);
            });

            return hash;
        }
    };
}]);
