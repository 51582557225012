/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { VsLogsStore } from './vs-logs.store';

/**
 * @description
 *      Connects Refresh button to subscribing components that will
 *      fetch new data upon button press.
 *
 *      Only needed for custom timeframes; with timeframes that end at present time
 *      (such as "last 6 hours"), the 'end' API param is simply updated in state, which
 *      automatically invokes the API requests.
 *
 * @author Alex Klyuev
 */
@Injectable()
export class VsLogsRefreshService implements OnDestroy {
    /**
     * Observable for when a refresh should take place.
     */
    public readonly refreshBase$ = new Subject<void>();

    /**
     * Piped Observable to provide state data to subscribers.
     */
    public readonly refresh$ = this.refreshBase$.pipe(
        withLatestFrom(
            this.vsLogsStore.isPageCustomTimeframe$,
            this.vsLogsStore.isTableCustomTimeframe$,
        ),
        map(valuesContainer => ({
            isPageCustomTimeframe: valuesContainer[1],
            isTableCustomTimeframe: valuesContainer[2],
        })),
    );

    constructor(private readonly vsLogsStore: VsLogsStore) {
        // For timeframes that end with present time (like "Past 6 hours")
        // we simply need to update the "end" parameter in the API params store
        this.refresh$.subscribe(({ isPageCustomTimeframe }) => {
            if (!isPageCustomTimeframe) {
                this.vsLogsStore.refreshTimeframe();
            }
        });
    }

    /**
     * Emit the refresh Subject to indicate that a refresh should take place.
     */
    public refresh(): void {
        this.refreshBase$.next();
    }

    /**
     * @override
     * Complete the Subject to automatically end all subscriptions.
     */
    public ngOnDestroy(): void {
        this.refreshBase$.complete();
    }
}
