/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import {
    PathMatch,
    StringMatch,
} from 'object-types';
import {
    PathMatchConfigItem,
    QueryMatchConfigItem,
    StringMatchConfigItem,
} from 'ajs/modules/match/factories';
import * as l10n from './path-or-query-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// TODO: Change name, to reflect addition of StringMatch.
/**
 * @description Component to show readonly information for path-match, query-match, or string-match.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'path-or-query-match-readonly',
    templateUrl: './path-or-query-match-readonly.component.html',
})
export class PathOrQueryMatchReadonlyComponent {
    /**
     * Just like path-or-query-match.component, can be either of below, since they are similar.
     */
    @Input()
    public editable: PathMatchConfigItem | StringMatchConfigItem | QueryMatchConfigItem;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get text label for selected match criteria.
     */
    public get matchCriteria(): string {
        const { match_criteria: matchCriteria } = this.editable.config;
        const enumName = this.isPathMatchType() || this.isStringMatchType() ?
            'StringOperation' :
            'QueryMatchOperation';

        return this.schemaService.getEnumValueLabel(enumName, matchCriteria);
    }

    /**
     * Gets the correct match label.
     */
    public get matchLabel(): string {
        const { pathLabel, stringLabel, queryLabel } = l10nKeys;
        let key = queryLabel;

        if (this.isPathMatchType()) {
            key = pathLabel;
        } else if (this.isStringMatchType()) {
            key = stringLabel;
        }

        return this.l10nService.getMessage(key);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Gets path match type.
     */
    private get matchType(): string {
        return this.editable.messageType;
    }

    /**
     * Determines if match type is of type Path.
     */
    private isPathMatchType(): boolean {
        return this.matchType === PathMatch;
    }

    /**
     * Determines if match type is of type String.
     */
    private isStringMatchType(): boolean {
        return this.matchType === StringMatch;
    }
}
