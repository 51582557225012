<div class="match-display-wrapper">
    <div class="match-display-wrapper__criteria-wrapper">
        <div class="match-display-wrapper__label">{{ matchLabel }}</div>
        <div
            *ngIf="!subheader"
            class="match-display-wrapper__criteria"
        >
            &nbsp;({{ matchCriteria }})
        </div>
    </div>

    <div
        *ngIf="subheader"
        class="match-display-wrapper__criteria-wrapper match-display-wrapper__criteria-wrapper--subheader"
    >
        {{ matchTitle }}
    </div>

    <div
        *ngIf="matchValuesExist"
        class="match-display-wrapper__section match-display-wrapper__section--content"
        [ngClass]="{'match-display-wrapper__section--content-with-subheader' : subheader }"
    >
        <ng-content></ng-content>
    </div>
</div>
