<avi-card class="backup-overview-card">
    <avi-card_header>
        <div class="backup-overview-card__header">
            <div class="backup-overview-card__title">
                <cds-icon
                    shape="backup"
                    size="24"
                    class="backup-overview-card__backup-icon"
                ></cds-icon>
                {{ l10nKeys.configurationBackupLabel | vtranslate }}
            </div>
            <div *ngIf="enabled; else elseBlock">
                <div class="backup-overview-card__enabled-label">
                    {{ l10nKeys.enabledDataLabel | vtranslate }}
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="backup-overview-card__deactivated-label">
                    {{ l10nKeys.deactivatedDataLabel | vtranslate }}
                </div>
            </ng-template>
        </div>
    </avi-card_header>
    <avi-card_body>
        <div class="backup-overview-card__row">
            <div class="backup-overview-card__control">
                <avi-input-container noMarginTop>
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.frequencyLabel | vtranslate }}
                    </label>
                    <input
                        class="sel-version-in-update-card"
                        clrInput
                        [ngModel]="frequency"
                        readonly
                    />
                </avi-input-container>
            </div>
        </div>
        <div class="backup-overview-card__row">
            <div class="backup-overview-card__control">
                <avi-input-container noMarginTop>
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.maximumBackupsStoredLabel | vtranslate }}
                    </label>
                    <input
                        class="sel-version-in-update-card"
                        clrInput
                        [ngModel]="maximumBackupsStored"
                        readonly
                    />
                </avi-input-container>
            </div>
        </div>
    </avi-card_body>
</avi-card>
