/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleGeoLocationMatchConfig
 * @description DnsPolicyRuleGeoLocationMatch MatchConfigItem class.
 */
const MatchConfigController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleGeoLocationMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
        }

        /** @override */
        toString() {
            const
                { match_criteria: matchCriteria } = this.getConfig(),
                value = schemaService.getEnumValue('MatchOperation', matchCriteria).label,
                modifiedValue = value.replace(' in', '').toLowerCase();

            let {
                geolocation_name: name,
                geolocation_tag: tag,
            } = this.getConfig();

            name = name || 'N/A';
            tag = tag || 'N/A';

            return `${modifiedValue} Name: ${name}; Tag: ${tag}`;
        }
    }

    angular.extend(DnsPolicyRuleGeoLocationMatchConfig.prototype, {
        type: 'DnsGeoLocationMatch',
        name: 'Geographical Location',
        defaultPath: 'dnsrule.match.geo_location',
    });

    return DnsPolicyRuleGeoLocationMatchConfig;
};

MatchConfigController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('DnsPolicyRuleGeoLocationMatchConfig', MatchConfigController);
