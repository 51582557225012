/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  metricValuesSelector
 * @description  Displays traffic capture status.
 */

import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import * as UserPreferencesActions from 'ng/root-store/user-preferences/user-preferences.actions';
import * as l10n from './metricValueSelector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

function Controller(myAccount, l10nService, store) {
    this.$onInit = function() {
        this.displayValue = myAccount.uiProperty.valuesToDisplay;
    };

    this.l10nKeys = l10nKeys;
    l10nService.registerSourceBundles(dictionary);

    this.onDisplayValueChange = function() {
        store.dispatch(UserPreferencesActions.updateUIProperty({
            payload: {
                valuesToDisplay: this.displayValue,
            },
        }));
    };
}

Controller.$inject = [
    'myAccount',
    'l10nService',
    STORE_TOKEN,
];

angular.module('aviApp').component('metricValuesSelector', {
    controller: Controller,
    template: require('./metrics-display-values.partial.html'),
});
