<div
    class="welcome-email-localhost-settings"
    provideParentForm
>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            fieldName="from_email"
            for="from_email"
            objectType="EmailConfiguration"
            required
        >
            {{ l10nKeys.fromAddressLabel | vtranslate }}
        </label>
        <input
            id="from_email"
            name="from_email"
            type="email"
            [(ngModel)]="emailConfig.from_email"
            regexPattern="email"
            clrInput
            placeholder="admin@avicontroller.net"
            required
        />
    </avi-input-container>
</div>
