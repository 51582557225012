/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'scheduler-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const generalHeader = `${prefix}.generalHeader`;
export const schedulerHeader = `${prefix}.schedulerHeader`;
export const backupDestinationHeader = `${prefix}.backupDestinationHeader`;
export const enableConfigurationBackupLabel = `${prefix}.enableConfigurationBackupLabel`;
export const frequencyLabel = `${prefix}.frequencyLabel`;
export const frequencyUnitLabel = `${prefix}.frequencyUnitLabel`;
export const enableLocalBackupLabel = `${prefix}.enableLocalBackupLabel`;
export const filePrefixLabel = `${prefix}.filePrefixLabel`;
export const numberOfBackupsLabel = `${prefix}.numberOfBackupsLabel`;
export const numberPlaceholderLabel = `${prefix}.numberPlaceholderLabel`;
export const enableRemoteServerLabel = `${prefix}.enableRemoteServerLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const frequencyUnitInputPlaceholder = `${prefix}.frequencyUnitInputPlaceholder`;
export const serverAddressInputLabel = `${prefix}.serverAddressInputLabel`;
export const serverAddressInputPlaceholder = `${prefix}.serverAddressInputPlaceholder`;
export const remoteDirectoryInputLabel = `${prefix}.remoteDirectoryInputLabel`;
export const remoteDirectoryInputPlaceholder = `${prefix}.remoteDirectoryInputPlaceholder`;
export const remoteDefaultDirectoryLabel = `${prefix}.remoteDefaultDirectoryLabel`;
export const userCredentialsInputLabel = `${prefix}.userCredentialsInputLabel`;
export const userCredentialsInputPlaceholder = `${prefix}.userCredentialsInputPlaceholder`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const protocolPlaceholder = `${prefix}.protocolPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Configuration Backup',
    [modalTitleNew]: 'Create Configuration Backup',
    [generalHeader]: 'General',
    [schedulerHeader]: 'Scheduler',
    [backupDestinationHeader]: 'Backup Destination',
    [enableConfigurationBackupLabel]: 'Enable Configuration Backup',
    [frequencyLabel]: 'Frequency',
    [nameLabel]: 'Name',
    [frequencyUnitLabel]: 'Frequency Unit',
    [frequencyUnitInputPlaceholder]: 'Select Units',
    [enableLocalBackupLabel]: 'Enable Local Backup (On Controller)',
    [filePrefixLabel]: 'File Prefix',
    [numberOfBackupsLabel]: 'Number of backups to store',
    [numberPlaceholderLabel]: 'Enter Number',
    [enableRemoteServerLabel]: 'Enable Remote Server Backup',
    [serverAddressInputLabel]: 'Server Address',
    [serverAddressInputPlaceholder]: 'Enter Server Address',
    [remoteDirectoryInputLabel]: 'Home Directory',
    [remoteDirectoryInputPlaceholder]: 'Enter Home Directory',
    [remoteDefaultDirectoryLabel]: 'Defaults to the home directory',
    [userCredentialsInputLabel]: 'User Credentials',
    [userCredentialsInputPlaceholder]: 'Select SSH User',
    [protocolLabel]: 'Protocol',
    [protocolPlaceholder]: 'Select File Transfer Protocol',
};
