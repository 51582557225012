/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    HealthMonitorType,
    IHealthMonitor,
    IHealthMonitorAuthInfo,
    IHealthMonitorDNS,
    IHealthMonitorExternal,
    IHealthMonitorHttp,
    IHealthMonitorRadius,
    IHealthMonitorSIP,
    IHealthMonitorSSLAttributes,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
    IRoleFilterMatchLabel,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    HealthMonitorImapConfigItem,
} from './factories/health-monitor-imap.config-item.factory';

import { HealthMonitorPop3ConfigItem } from './factories/health-monitor-pop3.config-item.factory';
import { HealthMonitorHttpConfigItem } from './factories/health-monitor-http.config.item.factory';
import { HealthMonitorSmtpConfigItem } from './factories/health-monitor-smtp.config-item.factory';
import { HealthMonitorLdapConfigItem } from './factories/health-monitor-ldap.config-item.factory';
import { HealthMonitorFtpConfigItem } from './factories/health-monitor-ftp.config-item.factory';

/**
 * Health Monitor HTTP Partial type.
 */
type THealthMonitorHttpPartial = Omit<IHealthMonitorHttp, 'ssl_attributes'>;

/**
 * Interface for Health Monitor HTTP Config.
 */
export interface IHealthMonitorHttpConfig extends THealthMonitorHttpPartial {
    ssl_attributes?: MessageItem<IHealthMonitorSSLAttributes>
}

/**
 * Child message fields for which we need to override types.
 */
type TChildMessageFields = 'authentication' |
'dns_monitor' |
'external_monitor' |
'http_monitor' |
'https_monitor' |
'markers' |
'radius_monitor' |
'sip_monitor' |
'tcp_monitor' |
'udp_monitor' |
'smtp_monitor' |
'smtps_monitor' |
'pop3_monitor' |
'pop3s_monitor' |
'imaps_monitor' |
'ldap_monitor' |
'ldaps_monitor' |
'ftp_monitor' |
'ftps_monitor';

/**
 * Health Monitor Partial type.
 */
type THealthMonitorPartial = Omit<IHealthMonitor, TChildMessageFields>;

/**
 * Interface for Health Monitor Config.
 */
export interface IHealthMonitorConfig extends THealthMonitorPartial {
    authentication: MessageItem<IHealthMonitorAuthInfo>;
    dns_monitor: MessageItem<IHealthMonitorDNS>;
    external_monitor: MessageItem<IHealthMonitorExternal>;
    http_monitor: HealthMonitorHttpConfigItem;
    https_monitor: HealthMonitorHttpConfigItem;
    markers: RepeatedMessageItem<MessageItem<IRoleFilterMatchLabel>>;
    radius_monitor: MessageItem<IHealthMonitorRadius>;
    sip_monitor: MessageItem<IHealthMonitorSIP>;
    tcp_monitor: MessageItem<IHealthMonitorTcp>;
    pop3_monitor: HealthMonitorPop3ConfigItem;
    pop3s_monitor: HealthMonitorPop3ConfigItem;
    udp_monitor: MessageItem<IHealthMonitorUdp>;
    smtp_monitor: HealthMonitorSmtpConfigItem;
    smtps_monitor: HealthMonitorSmtpConfigItem;
    imaps_monitor: HealthMonitorImapConfigItem;
    imap_monitor: HealthMonitorImapConfigItem;
    ldap_monitor: HealthMonitorLdapConfigItem;
    ldaps_monitor: HealthMonitorLdapConfigItem;
    ftp_monitor: HealthMonitorFtpConfigItem;
    ftps_monitor: HealthMonitorFtpConfigItem;
    folder: string // ui only
}

/**
 * Interface for Health Monitor data.
 */
export interface IHealthMonitorData {
    config: IHealthMonitorConfig;
}

/**
 * Child field name constants.
 */
export const AUTHENTICATION = 'authentication';

/**
 * Set of Health Monitor Types for which we need to show Authentication field.
 */
export const HmTypesWithAuthenticationField = new Set([
    HealthMonitorType.HEALTH_MONITOR_HTTP,
    HealthMonitorType.HEALTH_MONITOR_HTTPS,
    HealthMonitorType.HEALTH_MONITOR_EXTERNAL,
    HealthMonitorType.HEALTH_MONITOR_IMAPS,
    HealthMonitorType.HEALTH_MONITOR_POP3,
    HealthMonitorType.HEALTH_MONITOR_POP3S,
    HealthMonitorType.HEALTH_MONITOR_SMTP,
    HealthMonitorType.HEALTH_MONITOR_IMAP,
    HealthMonitorType.HEALTH_MONITOR_SMTPS,
    HealthMonitorType.HEALTH_MONITOR_LDAP,
    HealthMonitorType.HEALTH_MONITOR_LDAPS,
    HealthMonitorType.HEALTH_MONITOR_FTP,
    HealthMonitorType.HEALTH_MONITOR_FTPS,
]);
