<div class="node-vitals-usage-info">
    <div class="node-vitals-usage-info__name-container">
        <cds-icon
            shape="circle"
            size="14"
            [status]="nodeVitals.state | clusterObjStateIconStatus"
            solid
        ></cds-icon>
        <div class="node-vitals-usage-info__name">
            {{ nodeVitals.name }}
        </div>
        <div
            *ngIf="nodeVitals.isLeader"
            class="node-vitals-usage-info__leader-label"
        >
            {{ l10nKeys.leaderLabel | vtranslate }}
        </div>
    </div>
    <div class="node-vitals-usage-info__vitals-container">
        <div class="node-vitals-usage-info__cpu">
            <div>
                <span class="node-vitals-usage-info__vitals-label">
                    {{ l10nKeys.cpuLabel | vtranslate }}
                </span>
                <span>({{ l10nKeys.numberOfCores | vtranslate : nodeVitals.cpuUsage.numCores }})</span>
            </div>
            <node-vitals-usage-bar
                [percentage]="nodeVitals.cpuUsage.cpuPercent"
                [color]="color"
            ></node-vitals-usage-bar>
            <div class="node-vitals-usage-info__cpu-percent">
                {{ nodeVitals.cpuUsage.cpuPercent | number: '1.0-0' }}%
            </div>
        </div>
        <div class="node-vitals-usage-info__memory">
            <div class="node-vitals-usage-info__vitals-label">
                {{ l10nKeys.memoryLabel | vtranslate }}
            </div>
            <node-vitals-usage-bar
                [percentage]="memoryUsage.used / memoryUsage.total * 100"
                [color]="color"
            ></node-vitals-usage-bar>
            <div class="node-vitals-usage-info__memory-values">
                <div>
                    <div>{{ memoryUsage.used }}/{{ memoryUsage.total }}</div>
                    <div class="node-vitals-usage-info__units-label">
                        {{ globalL10nKeys.gigabytesLabel | vtranslate }}
                    </div>
                </div>
                <div>{{ memoryUsage.used / memoryUsage.total | percent }}</div>
            </div>
        </div>
        <div class="node-vitals-usage-info__disk">
            <div class="node-vitals-usage-info__vitals-label">
                {{ l10nKeys.diskLabel | vtranslate }}
            </div>
            <node-vitals-usage-bar
                [percentage]="diskUsage.used/diskUsage.total * 100"
                [color]="color"
            ></node-vitals-usage-bar>
            <div class="node-vitals-usage-info__disk-values">
                <div>
                    <div>{{ usedDiskUsage }}/{{ totalDiskUsage }}</div>
                    <div class="node-vitals-usage-info__units-label">
                        {{ diskUsageUnits }}
                    </div>
                </div>
                <div>{{ diskUsage.used / diskUsage.total | percent }}</div>
            </div>
        </div>
    </div>
</div>
