/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-access-snmp-card';
const prefix = `${moduleName}.${componentName}`;

export const snmpHeader = `${prefix}.snmpHeader`;
export const versionLabel = `${prefix}.versionLabel`;

export const ENGLISH = {
    [snmpHeader]: 'SNMP',
    [versionLabel]: 'Version',
};
