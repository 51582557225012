<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="errorPageProfileGridConfig"></avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #errorPagesTemplateRef
    let-row
>
    <div>{{ l10nKeys.totalPagesLabel | vtranslate : row.config.error_pages.count }}</div>
    <div>{{ l10nKeys.statusCodesLabel | vtranslate : getStatusCodes(row) }}</div>
</ng-template>
