<ng-container *ngFor="let dataScript of dataScripts; let i = index; trackBy: trackByIndex">
    <div
        provideParentForm
        class="full-modal-config__form-control-row data-script-config-fieldset"
        *ngIf="dataScriptEvents.has(dataScript.evt)"
    >
        <div class="full-modal-config__form-control">
            <avi-fieldset (onRemove)="onEventRemove.emit(dataScript.evt)">
                <avi-fieldset_header>
                    <label
                        aviLabelWithTooltip
                        tooltipText="{{ getDSEventDescription(dataScript.evt) }}"
                        for="{{ dataScript.evt }}"
                        required
                    >
                        {{ l10nKeys.fileUploadInputLabel | vtranslate : (getDSEventLabel(dataScript.evt)) }}
                    </label>
                </avi-fieldset_header>
                <avi-fieldset_content>
                    <file-upload-textarea
                        [(ngModel)]="dataScript.script"
                        name="{{ dataScript.evt }}"
                        placeholder="{{ l10nKeys.fileUploadInputPlaceholder | vtranslate }}"
                        [rows]="textAreaRows"
                        required
                    ></file-upload-textarea>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</ng-container>
