/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name compileTemplate
 * @element any
 * @restrict A
 * @description
 *     Alternative to ngBindHtml. Inserts template string inside the element, but does not
 *     sanitize it or escapes the content. Only use with trusted HTML string.
 * @example
 *      <div compile-template="<span class="foo" some-custom-directive>foobar</span>"></div>
 */
angular.module('aviApp').directive('compileTemplate', ['$compile', function($compile) {
    return {
        restrict: 'A',
        link(scope, element, attributes) {
            scope.$watch(attributes.compileTemplate, function(newValue) {
                element.html($compile(newValue)(scope) || '');
            });
        },
    };
}]);
