/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { IPromise } from 'angular';
import { waitFor } from 'ajs/js/utilities/timer';
import { AviBackendErrorMsgPipe } from 'nsx-alb-ui-components';
import { L10nService } from '@vmw/ngx-vip';

import {
    IALBServicesConfig,
    IALBServicesStatus,
} from 'generated-types';

import { AviConfirmService } from 'ajs/modules/core/services/avi-confirm.service';
import { CportalService } from 'ajs/modules/cportal/services';
import { AsyncFactory } from 'ajs/modules/core/factories/async-factory/async.factory';
import { PortalInfo } from 'ajs/modules/cportal/factories/portal-info.item.factory';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';

import {
    configUpdateDuration,
    connectionPollingDuration,
    defaultPortalUrl,
    maxConnectionPollingCount,
    portalStatus,
    statusPollingDuration,
} from 'ajs/modules/cportal/constants';

import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { ADMIN_TENANT_NAME } from 'ajs/modules/core/core.constants';
import { IError } from 'ajs/modules/cportal/factories/case.item.types';
import { CentralLicenseAlertsService } from 'ng/modules/core/services/central-license-alerts';
import { ClusterItem } from 'items/cluster.item.factory';
import { LicensingService } from 'ajs/modules/licensing';
import * as globalL10n from 'global-l10n';
import * as l10n from './cportal-page.l10n';
import './cportal-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAsyncFactory = typeof AsyncFactory;
type TPortalInfo = typeof PortalInfo;
type TCluster = typeof ClusterItem;

enum AlertType {
    success = 'success',
    danger = 'danger',
    default = 'default',
    info = 'info',
}

/**
 * @description
 *      Component used to represent Cportal page.
 * @author Ram Pal, Navneet Sharma, Rajawant Prajapti, Ratan Kumar
 */
@Component({
    selector: 'cportal-page',
    templateUrl: './cportal-page.component.html',
})
export class CPortalPageComponent implements OnInit, OnDestroy {
    /**
     * Default Avi cloud connected status.
     * Used to check if controller is connected with the pulse.
     */
    public aviCloudConnected = false;

    /**
     * Default controller registration status.
     * Used to check if controller is Registered with the pulse.
     */
    public isControllerRegistered = false;

    /**
     * Default variable for error message.
     */
    public error = '';

    /**
     * Error message specific to controller connectivity.
     */
    public connectivityError = '';

    /**
     * Name of existing controller.
     */
    public controllerName = '';

    /**
     * This Url represent Portal which is connected to controller.
     */
    public portalUrl = '';

    /**
     * Set to true when controller registration and de-registration is in progress.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Property holding AlertType.
     */
    public readonly AlertType = AlertType;

    /**
     * Hide Alert message on Unregistered button click.
     */
    public showAlertMessage = false;

    /**
     * Holds value of org ID from License Service.
     */
    public orgId: string;

    /**
     * To indicate whether the tenant is an Admin tenant.
     */
    public isAdminTenant = true;

    /**
     * Instance of portalInfo Item.
     */
    private portalInfo: PortalInfo = null;

    /**
     * Instance of Cluster Item
     */
    private cluster: ClusterItem = null;

    /**
     * Use to check if component is destroyed
     */
    private isDestroyed = false;

    /**
     * Instance of AsyncFactory when we have an ongoing polling.
     */
    private pollingPulseStatus: AsyncFactory = null;

    constructor(
        private readonly cportalService: CportalService,
        private readonly aviConfirmService: AviConfirmService,
        @Inject('Cluster')
        private readonly Cluster: TCluster,
        @Inject('PortalInfo')
        private readonly PortalInfo: TPortalInfo,
        @Inject('AsyncFactory')
        private readonly AsyncFactory: TAsyncFactory,
        private readonly l10nService: L10nService,
        @Inject('systemInfoService')
        private readonly systemInfoService: SystemInfoService,
        private readonly aviBackendErrorMsgPipe: AviBackendErrorMsgPipe,
        private readonly centralLicenseAlertsService: CentralLicenseAlertsService,
        private readonly licensingService: LicensingService,
        private readonly authService: Auth,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        this.isAdminTenant = this.authService.getTenantName() === ADMIN_TENANT_NAME;
        this.controllerName = this.systemInfoService.localClusterName;
        this.setPortalUrl();
        this.setOrgId();
        this.refreshAviCloudStatus();
        this.cluster = new this.Cluster({ id: 'default' });
        this.pollingPulseStatus = new this.AsyncFactory(
            async() => {
                await this.cportalService.loadRegistrationStatus();

                const albserviceStatus = this.cportalService.getRegistrationStatusDetails();

                if (!albserviceStatus.error) {
                    this.hideErrorMessage();
                }

                this.setStatus(albserviceStatus);
                this.centralLicenseAlertsService.refreshPulseRegistrationAlert(albserviceStatus);

                // Update controller registration status in centralLicenseAlertService and
                // call method to show/hide license subscription failed alert.
                const { registration_status: registrationStatus } = albserviceStatus;

                this.centralLicenseAlertsService.controllerRegistrationStatus = registrationStatus;
                this.centralLicenseAlertsService.setSaasStatusAndRefreshLicenseFailAlert();
            },
            {
                maxSkipped: 5,
                callback: () => this.systemInfoService.cancelRequests(),
            },
        );

        this.pollingPulseStatus.start(statusPollingDuration);
    }

    /**
     * Function to de-register Controller with Portal
     */
    public deregisterController(): void {
        const { l10nService } = this;
        const message = l10nService.getMessage(l10nKeys.isDeregisterControllerMessage);

        this.showAlertMessage = false;

        this.aviConfirmService.confirm(message)
            .then(() => {
                this.busy = true;

                this.cportalService.deregisterController()
                    .then(() => waitFor(configUpdateDuration))
                    .then(() => this.updateStatus())
                    .catch((error: string) => this.error = error)
                    .finally(() => this.busy = false);
            });
    }

    /**
     * This function authenticates user with the portal and then
     * it connects existing controller with Portal.
     */
    public connectAndRegisterController(): IPromise<string | void> {
        this.showAlertMessage = false;

        return this.connectAviCloud()
            .then(() => this.hideErrorMessage())
            .then(() => this.updateCtrlAndRegistrationDetails(
                connectionPollingDuration,
                maxConnectionPollingCount,
            ))
            .then(() => this.openRegistrationModal())
            .catch((error: string) => this.error = error);
    }

    /**
     * This function is used to reconnect controller with portal in case
     * when controller is registered but connection is lost.
     */
    public reconnectController(): void {
        this.busy = true;
        this.showAlertMessage = false;

        this.connectAviCloud()
            .then(() => waitFor(configUpdateDuration))
            .then(() => this.updateStatus())
            .catch((error: string) => this.error = error)
            .finally(() => this.busy = false);
    }

    /**
     * Open dialog modal for registration with opt-in features.
     */
    public async openRegistrationModal(editMode = false): Promise<void> {
        const { l10nService } = this;

        this.showAlertMessage = true;

        if (!editMode) {
            if (!this.aviCloudConnected) {
                const errorMessage =
                    l10nService.getMessage(l10nKeys.cloudServicesRegisterControllerMessage);

                return Promise.reject(errorMessage);
            }
        }

        this.portalInfo = new this.PortalInfo();
        await this.portalInfo.load();

        return this.portalInfo.edit(null, {
            controllerName: this.controllerName,
            onComplete: () => this.updateCtrlAndRegistrationDetails(),
            editMode,
        })
            .catch((error: IError) => Promise.reject(error.data));
    }

    /**
     * Open modal dialog for controller edit.
     */
    public async openControllerEditModal(): Promise<void> {
        await this.cluster.load();

        this.cluster.edit()
            .then(() => this.updateCtrlAndRegistrationDetails());
    }

    /**
     * Hide error message.
     */
    public hideErrorMessage(): void {
        this.error = '';
    }

    public ngOnDestroy(): void {
        this.isDestroyed = true;
        this.cluster.destroy();
        this.cportalService.clearLoginWindowInterval();

        if (this.portalInfo) {
            this.portalInfo.destroy();
        }

        // Stop polling of pulse status.
        this.pollingPulseStatus.stop();
    }

    /**
     * Get connectivity Status based on aviCloudConnected and connectivityError.
     */
    public get aviCloudConnectedStatus(): string {
        if (!this.aviCloudConnected && this.unregisteredAndDisconnected) {
            return this.AlertType.default;
        }

        if (this.aviCloudConnected && !this.busy) {
            return this.AlertType.success;
        } else if (this.hasConnectivityError) {
            return this.AlertType.danger;
        }

        return this.AlertType.default;
    }

    /**
     * Getter will return true if any error occurs.
     */
    public get hasConnectivityError(): boolean {
        return Boolean(this.error || this.connectivityError);
    }

    /**
     * If controller is unregistered and Avi cloud Connected getter will return true.
     */
    public get unregisteredAndConnected(): boolean {
        return !this.isControllerRegistered && this.aviCloudConnected;
    }

    /**
     * If controller is unregistered and Avi cloud is disconnected getter will return true.
     */
    public get unregisteredAndDisconnected(): boolean {
        return !this.isControllerRegistered && !this.aviCloudConnected;
    }

    /**
     * Getter will return message based on connectivity and registered status.
     */
    public get connectivityAndRegisteredMessage(): string | null {
        const { l10nService } = this;

        if (this.busy && !this.showAlertMessage) {
            return null;
        }

        if (this.hasConnectivityError) {
            return this.isControllerRegistered ?
                this.aviBackendErrorMsgPipe.transform(this.error) || this.connectivityError : null;
        } else if (this.unregisteredAndConnected && !this.busy) {
            return l10nService.getMessage(l10nKeys.notConnectedErrorMessage);
        } else if (!this.hasConnectivityError && this.unregisteredAndDisconnected && !this.busy) {
            return l10nService.getMessage(l10nKeys.notRegisteredAndConnectedErrorMessage);
        } else if (this.busy && this.showAlertMessage) {
            return l10nService.getMessage(l10nKeys.attemptingToConnectMessage);
        }

        return null;
    }

    /**
     * Getter will return true if based on connectivity and registered status.
     */
    public get showFooterButtons(): boolean {
        return !this.busy && (
            this.unregisteredAndDisconnected ||
            this.isControllerRegistered ||
            this.unregisteredAndConnected
        );
    }

    /**
     * Function to set Portal Url.
     */
    private setPortalUrl(): void {
        this.cportalService.getPortalInfo()
            .then(({ portal_url: portalUrl }: IALBServicesConfig) => this.portalUrl =
                portalUrl || defaultPortalUrl)
            .catch((error: string) => this.error = error);
    }

    /**
     * Function to set org id.
     */
    private async setOrgId(): Promise<void> {
        const { service_update: serviceUpdate } = await this.licensingService.loadLicenseStatus();

        this.orgId = serviceUpdate?.service_units?.org_id;
    }

    /**
     * Updates Controller registration and Avi cloud connectivity status
     */
    private setStatus(albserviceStatus: IALBServicesStatus): void {
        const { l10nService } = this;
        const {
            registration_status: registrationStatus,

            connectivity_status: connectivityStatus,
            error: connectivityError,
        } = albserviceStatus;
        const { ALBSERVICES_REGISTERED, ALBSERVICES_CONNECTED } = portalStatus;

        this.isControllerRegistered =
            registrationStatus === ALBSERVICES_REGISTERED;
        this.aviCloudConnected =
            connectivityStatus === ALBSERVICES_CONNECTED;

        this.connectivityError = connectivityError || '';

        // If connectivity is lost and api does not return any error message
        // then following standard error message will be displayed.
        if (
            !this.aviCloudConnected &&
            !connectivityError &&
            this.isControllerRegistered
        ) {
            this.connectivityError =
                l10nService.getMessage(l10nKeys.connectionErrorMessage);
        }
    }

    /**
     * Function gets the latest registration status
     * and send the status to setStatus function.
     */
    private async updateStatus(): Promise<void> {
        if (this.isDestroyed) {
            return;
        }

        await this.cportalService.loadRegistrationStatus();

        const albserviceStatus = this.cportalService.getRegistrationStatusDetails();

        return Promise.resolve(this.setStatus(albserviceStatus));
    }

    /**
     * Function to authenticate user with Portal.
     */
    private connectAviCloud(): IPromise<void> {
        if (this.aviCloudConnected) {
            // If controller is already connected with portal then promise will
            // be resolved and returned.
            return Promise.resolve();
        } else {
            return this.cportalService.openLoginWindow();
        }
    }

    /**
     * Updates controller and registration details. If pollingTimeout and maxPollingCount
     * params are passed then Pulse connection status is polled after interval defined by
     * pollingTimeout for maximum number of times defined by maxPollingCount.
     * [pollingTimeout=configUpdateDuration] - timeout after which the status
     * will be polled.
     * [maxPollingCount=1] - maximum number of attempts for polling pulse
     * connection status.
     */
    private updateCtrlAndRegistrationDetails(
        pollingTimeout = configUpdateDuration,
        maxPollingCount = 1,
    ): IPromise<void> {
        this.busy = true;

        return waitFor(pollingTimeout)
            .then(() => this.systemInfoService.load(true))
            .then(() => {
                const { localClusterName } = this.systemInfoService;

                this.controllerName = localClusterName;

                return this.updateStatus();
            })
            .then(() => {
                if (!this.aviCloudConnected && maxPollingCount > 1) {
                    return this
                        .updateCtrlAndRegistrationDetails(pollingTimeout, maxPollingCount - 1);
                }
            })
            .finally(() => this.busy = false);
    }

    /**
     * This function returns updated cloud status.
     */
    private refreshAviCloudStatus(): void {
        this.busy = true;

        this.cportalService.refreshStatus()
            .then(() => waitFor(configUpdateDuration))
            .then(() => this.updateStatus())
            .catch((error: string) => this.error = error)
            .finally(() => this.busy = false);
    }
}
