/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-headers';
const prefix = `${moduleName}.${componentName}`;

export const allRequestLabel = `${prefix}.allRequestLabel`;
export const allResponseLabel = `${prefix}.allResponseLabel`;
export const headersReceivedFromClientLabel = `${prefix}.headersReceivedFromClientLabel`;
export const headersSentToServerLabel = `${prefix}.headersSentToServerLabel`;
export const headersSentToClientLabel = `${prefix}.headersSentToClientLabel`;
export const headersReceivedFromServerLabel = `${prefix}.headersReceivedFromServerLabel`;
export const emptyListPlaceholderLabel = `${prefix}.emptyListPlaceholderLabel`;

export const ENGLISH = {
    [allRequestLabel]: 'All Request',
    [allResponseLabel]: 'All Response',
    [headersReceivedFromClientLabel]: 'Headers received from client',
    [headersSentToServerLabel]: 'Headers sent to server',
    [headersSentToClientLabel]: 'Headers sent to client',
    [headersReceivedFromServerLabel]: 'Headers received from server',
    [emptyListPlaceholderLabel]: 'No HTTP headers to show!',
};
