/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const componentName = 'node-vitals-usage-info';
const prefix = `${moduleName}.${componentName}`;

export const leaderLabel = `${prefix}.leaderLabel`;
export const cpuLabel = `${prefix}.cpuLabel`;
export const numberOfCores = `${prefix}.numberOfCores`;
export const memoryLabel = `${prefix}.memoryLabel`;
export const diskLabel = `${prefix}.diskLabel`;

export const ENGLISH = {
    [leaderLabel]: 'Leader',
    [cpuLabel]: 'CPU',
    [numberOfCores]: '{0, plural, one{# Core} other{# Cores}}',
    [memoryLabel]: 'Memory',
    [diskLabel]: 'Disk',
};
