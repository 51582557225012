/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphMetric
 * @description
 *
 *     HSGraphMetric always participates in updates.
 */
angular.module('aviApp').factory('HSGraphMetric', [
'Metric', function(Metric) {
    return class HSGraphMetric extends Metric {
        /**
         * HS metrics always participates in the coming update.
         * @override
         **/
        requestConfig() {
            return true;
        }
    };
}]);
