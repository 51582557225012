/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Cloud as CloudObjectType } from 'object-types';
import { CloudType, IpamDnsType } from 'generated-types';

import { IpamProfileCollection } from 'ajs/modules/ipam/factories/ipam-profile.collection.factory';
import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';

import './ipam-dns-configuration.component.less';
import * as l10n from './ipam-dns-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIpamProfileCollection = typeof IpamProfileCollection;

const DNS_PROFILE_TYPES = [
    IpamDnsType.IPAMDNS_TYPE_AWS_DNS,
    IpamDnsType.IPAMDNS_TYPE_AZURE_DNS,
    IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS,
    IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS,
    IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
].join();

const cloudsWithDnsResolver = new Set([
    CloudType.CLOUD_NSXT,
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_VCENTER,
]);

const cloudsWithStateBasedDnsRegistration = new Set([
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_LINUXSERVER,
    CloudType.CLOUD_VCENTER,
    CloudType.CLOUD_GCP,
]);

/**
 * @description Component for configuring IPAM/DNS settings in the Cloud modal.
 * @author alextsg
 */
@Component({
    selector: 'ipam-dns-configuration',
    templateUrl: './ipam-dns-configuration.component.html',
})
export class IpamDnsConfigurationComponent implements OnInit, OnDestroy {
    /**
     * Cloud Item.
     */
    @Input()
    public editable: Cloud;

    /**
     * Flag to show overlay in case of VCenter Cloud.
     */
    @Input()
    public showOverlay = false;

    /**
     * IpamProfileCollection instance, for the IPAM profile AviCollectionDropdown options.
     */
    public ipamProfileCollection: IpamProfileCollection;

    /**
     * IpamProfileCollection instance, for the DNS profile AviCollectionDropdown options.
     */
    public dnsIpamProfileCollection: IpamProfileCollection;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to display State Based DNS Registration field.
     */
    public showStateBasedDnsRegistration = false;

    /**
     * Flag to display DNS Reolver Grid for applicable clouds.
     */
    public showDnsResolverGrid = false;

    /**
     * objectType property to be used in template for tooltip.
     */
    public readonly objectType = CloudObjectType;

    public constructor(
    // eslint-disable-next-line @typescript-eslint/indent
        @Inject(IpamProfileCollection)
        IpamProfileCollection: TIpamProfileCollection,
        l10nService: L10nService,
    ) {
        this.ipamProfileCollection = new IpamProfileCollection({
            params: {
                type: DNS_PROFILE_TYPES,
                exclude: 'type',
            },
            defaults: {
                type: IpamDnsType.IPAMDNS_TYPE_INTERNAL,
            },
        });

        this.dnsIpamProfileCollection = new IpamProfileCollection({
            params: {
                type: DNS_PROFILE_TYPES,
            },
            defaults: {
                type: IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
            },
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngOnInit(): void {
        this.showStateBasedDnsRegistration = cloudsWithStateBasedDnsRegistration.has(
            this.editable.getVtype() as CloudType,
        );

        this.showDnsResolverGrid = cloudsWithDnsResolver.has(this.editable.getVtype() as CloudType);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ipamProfileCollection.destroy();
        this.dnsIpamProfileCollection.destroy();
    }
}
