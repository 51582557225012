/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-site-preview';
const prefix = `${moduleName}.${componentName}`;

export const siteTypeLabel = `${prefix}.siteTypeLabel`;
export const activeMemberLabel = `${prefix}.activeMemberLabel`;
export const passiveMemberLabel = `${prefix}.passiveMemberLabel`;
export const leaderLabel = `${prefix}.leaderLabel`;
export const followerLabel = `${prefix}.followerLabel`;
export const thirdPartySiteLabel = `${prefix}.thirdPartySiteLabel`;

export const ENGLISH = {
    [siteTypeLabel]: 'Site Type',
    [activeMemberLabel]: 'Active Member',
    [passiveMemberLabel]: 'Passive Member',
    [leaderLabel]: 'Leader',
    [followerLabel]: 'Follower',
    [thirdPartySiteLabel]: 'Third-Party Site',
};
