/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

// @ts-expect-error
import * as d3 from 'd3';

import {
    FocusLevel,
    GSLB_SUBDOMAINS_TREE_NODE_SELECTOR,
    ITreeNodeData,
    NodeType,
} from './gslb-subdomains-tree-node/gslb-subdomains-tree-node.component';

import './gslb-subdomains-tree.component.less';

const GSLB_SUBDOMAIN_TREE_SELECTOR = 'gslb-subdomains-tree';

/**
 * Represent tree node.
 */
export interface ITreeNode {
    data: ITreeNodeData,
    children?: ITreeNode[],
}

/**
 * @description GSLB subdomains tree showing subdomain,gslb sites and dnsVses relations.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gslb-subdomains-tree',
    templateUrl: './gslb-subdomains-tree.component.html',
})
export class GslbSubdomainsTreeComponent implements AfterViewInit {
    /**
     * Template ref for background div element.
     */
    @ViewChild('treeBackground', { static: true })
    public treeBackground: ElementRef;

    /**
     * Data required to render tree structure.
     */
    @Input()
    public treeData: ITreeNode;

    /**
     * Index number appends to container class as unique identifier.
     */
    @Input()
    public index = 0;

    /**
     * Name of tree node to be highlighted.
     */
    @Input()
    public highlightNode: string;

    /**
     * Show actions if true.
     */
    @Input()
    public isGslbEditable: boolean;

    /**
     * Fire on edit subdomain.
     */
    @Output()
    public onEditSubdomain = new EventEmitter<string>();

    /**
     * Fire on Remove subdomains.
     */
    @Output()
    public onRemoveSubdomain = new EventEmitter<string>();

    /**
     * Fire on hover over tree node.
     */
    @Output()
    public onHoverTreeNode = new EventEmitter<string | undefined>();

    public readonly nodeType = NodeType;

    public nodeFocusLevel = FocusLevel;

    /**
     * List of tree nodes which need to be highlighted on hover.
     */
    public highlightedBranchNodes: string[] = [];

    /**
     * Reference of background SVG element selected using D3 selector.
     */
    private backgroundSvg: d3.Selection<SVGGElement>;

    /** @Override */
    public ngAfterViewInit(): void {
        this.renderTreeChart();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { highlightNode } = changes;

        if (highlightNode && !highlightNode.firstChange) {
            this.calculateBranchToHighlight(highlightNode.currentValue);
        }
    }

    /**
     * Emit edit event for subdomain edit action.
     */
    public editSubdomain(subdomain: string): void {
        this.onEditSubdomain.emit(subdomain);
    }

    /**
     * Emit remove event for subdomain delete action.
     */
    public deleteSubdomain(subdomain: string): void {
        this.onRemoveSubdomain.emit(subdomain);
    }

    /**
     * Emit hover event for tree node hover action.
     */
    public handleHoverOnNode(name: string): void {
        this.onHoverTreeNode.emit(name);
    }

    /**
     * Emit hover event to clear hover effect.
     */
    public clearHighlight(): void {
        this.onHoverTreeNode.emit(undefined);
    }

    /**
     * Return Focus level for tree node based on hovered node relation.
     */
    public calculateFocusLevel(name: string): FocusLevel {
        if (!this.highlightedBranchNodes?.length) {
            return FocusLevel.NORMAL;
        }

        if (this.highlightedBranchNodes.includes(name)) {
            return FocusLevel.HIGH;
        } else {
            return FocusLevel.LOW;
        }
    }

    /**
     * Plot SVG to render the connecting nodes.
     */
    private renderTreeChart(): void {
        this.backgroundSvg = d3.select(this.treeBackground.nativeElement)
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%');

        const vsNode = d3
            .select(`.${GSLB_SUBDOMAIN_TREE_SELECTOR}__${this.index} ` +
                `.${GSLB_SUBDOMAINS_TREE_NODE_SELECTOR}__${NodeType.ROOT_NODE}`)
            .node();

        const siteNodes = d3
            .selectAll(`.${GSLB_SUBDOMAIN_TREE_SELECTOR}__${this.index} ` +
                `.${GSLB_SUBDOMAINS_TREE_NODE_SELECTOR}__${NodeType.PARENT_NODE}`)
            .nodes();

        siteNodes.forEach((siteNode: d3.Selection<SVGGElement>) => {
            this.drawLine(vsNode, siteNode);

            const dnsVsNodes = siteNode.parentNode.parentNode
                .querySelectorAll(`.${GSLB_SUBDOMAIN_TREE_SELECTOR}__${this.index} ` +
                    `.${GSLB_SUBDOMAINS_TREE_NODE_SELECTOR}__${NodeType.LEAF_NODE}`);

            dnsVsNodes.forEach((dnsVsNode: d3.Selection<SVGGElement>) => {
                this.drawLine(siteNode, dnsVsNode);
            });
        });
    }

    /**
     * Draw line between given nodes using D3 Path.
     */
    private drawLine(
        node1: d3.Selection<SVGGElement>,
        node2: d3.Selection<SVGGElement>,
    ): void {
        const lineGroup = this.backgroundSvg.append('g');

        const point1 = {
            x: node1.offsetLeft + node1.offsetWidth,
            y: node1.offsetTop + node1.offsetHeight / 2,
        };

        const point2 = {
            x: node2.offsetLeft,
            y: node2.offsetTop + node2.offsetHeight / 2,
        };

        const controlPoint1 = {
            x: point1.x + (point2.x - point1.x),
            y: point1.y,
        };

        const controlPoint2 = {
            x: point1.x,
            y: point1.y + (point2.y - point1.y),
        };

        if (point1.y === point2.y) { // If its a straight+horizontal line.
            controlPoint1.x = point1.x;
            controlPoint2.y = point1.y;
        }

        const path = d3.path();

        path.moveTo(point1.x, point1.y);
        path.bezierCurveTo(
            controlPoint1.x, controlPoint1.y, // 1st control point
            controlPoint2.x, controlPoint2.y, // 2nd control point
            point2.x, point2.y, // end coordinates
        );

        lineGroup.append('path')
            .attr('d', path.toString());
    }

    /**
     * Return list of nodes which need to be highlighted.
     */
    private calculateBranchToHighlight(highlightNode: string): void {
        this.highlightedBranchNodes = highlightNode ? [highlightNode] : [];

        this.treeData.children
            .forEach((siteName: ITreeNode) => {
                if (siteName.data.name === highlightNode) {
                    this.highlightedBranchNodes.push(this.treeData.data.name);
                } else {
                    const dnsVsNode = siteName.children
                        .find((dnsVs: ITreeNode) => dnsVs.data.name === highlightNode);

                    if (dnsVsNode) {
                        this.highlightedBranchNodes.push(
                            this.treeData.data.name,
                            siteName.data.name,
                        );
                    }
                }
            });
    }
}
