/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NatModule
 */

import { Component, Type } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';

import { NatPolicy } from 'object-types';
import { AviPermissionResource } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { NatRuleConfigItem } from './nat-rule.config-item.factory';
import * as l10n from '../../policies.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const NAT_RULE = 'rules';

interface INatPolicyConfig {
    rules: RepeatedMessageItem<NatRuleConfigItem>;
}

export class NatPolicyItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    // overriding getConfig()'s return type
    public getConfig: () => INatPolicyConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'natpolicy',
            objectType: NatPolicy,
            permissionName: AviPermissionResource.PERMISSION_NATPOLICY,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to add Nat rule item.
     */
    public addNatRule(): void {
        this.addChildMessageItem({
            field: NAT_RULE,
        });
    }

    /**
     * Method to edit Syslog server server item.
     */
    public editSyslogServer(natRule: NatRuleConfigItem): void {
        this.addChildMessageItem({
            field: NAT_RULE,
            messageItem: natRule,
        });
    }

    /**
     * Import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            NatPolicyModalComponent,
        } = await import(
            /* webpackChunkName: "nat-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/nat-policy-modal/nat-policy-modal.component'
        );

        return NatPolicyModalComponent as Type<Component>;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.natPolicyBreadcrumbTitle);
    }
}
