/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';
import { VsLogsStore } from '../../../services';
import { VsAppProfileType } from '../../../vs-logs.types';

import * as l10n from './vs-logs-type-dropdown.l10n';
import './vs-logs-type-dropdown.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Dropdown used to pick App Profile Type for VS's that can have both L4 and L7.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-type-dropdown',
    templateUrl: './vs-logs-type-dropdown.component.html',
})
export class VsLogsTypeDropdownComponent {
    public readonly l10nKeys = l10nKeys;

    public vsAppProfileTypeEnum = VsAppProfileType;

    /**
     * Currently selected log type.
     */
    public selectedLogType: VsAppProfileType;

    constructor(
        l10nService: L10nService,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        l10nService.registerSourceBundles(dictionary);

        vsLogsStore.vsAppProfileType$.pipe(take(1)).subscribe(type => this.selectedLogType = type);
    }

    /**
     * Update the selected log type in the store.
     */
    public setLogType(type: VsAppProfileType): void {
        this.vsLogsStore.setVsAppProfileType(type);
    }
}
