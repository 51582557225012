/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-clr-upload-successful-notification';
const prefix = `${moduleName}.${componentName}`;

export const fileAvailableMessage = `${prefix}.fileAvailableMessage`;

export const ENGLISH = {
    [fileAvailableMessage]: 'The file is now available in the Certificate Revocation List(CRL)',
};
