<avi-data-grid
    [config]="oobDsRequestLogGridConfig"
    [rows]="oobDsRequestLogs$ | async"
></avi-data-grid>

<ng-template
    #dsNameFieldTemplateRef
    let-row
>
    <ng-container *ngIf="row.ds_name as dsName; else na">
        <span
            vsLogFilter
            property="oob_log.ds_req_logs.ds_name"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            [value]="dsName"
        >
            {{ dsName }}
        </span>
    </ng-container>
</ng-template>

<ng-template
    #vsDsEventFieldTemplateRef
    let-row
>
    <ng-container *ngIf="row.event as dsEvent; else na">
        <span
            vsLogFilter
            property="oob_log.ds_req_logs.event"
            [value]="dsEvent"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        >
            {{ dsEvent }}
        </span>
    </ng-container>
</ng-template>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div class="vs-log-cinematic-section-out-of-band__grid-expanded-content">
        <!-- Summary -->
        <div class="vs-log-cinematic-section-out-of-band__grid-expanded-content-section">
            <!-- left column -->
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ globalL10nKeys.summaryLabel | vtranslate }}
                </h5>
                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.poolNameLabel | vtranslate }}</span>
                <ng-container *ngIf="row.pool_name?.val as poolName; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.pool_name"
                        [value]="poolName"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ poolName }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.sourcePortLabel | vtranslate }}</span>
                <ng-container *ngIf="row.source_port !== undefined; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.source_port"
                        [value]="row.source_port"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.source_port }}
                    </span>
                </ng-container>
                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.totalTimeLabel | vtranslate }}</span>
                <ng-container *ngIf="row.total_time !== undefined; else na">
                    <span
                        vsLogFilterList
                        property="oob_log.ds_req_logs.total_time"
                        [value]="row.total_time"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.total_time }}
                    </span>
                    {{ globalL10nKeys.msLabel | vtranslate }}
                </ng-container>
            </div>

            <!-- right column -->
            <div
                vsLogCinematicSectionColumn
                [withDivider]="false"
            >
                <span
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ globalL10nKeys.serverNameLabel | vtranslate }}
                </span>
                <ng-container *ngIf="row.server_name as serverName; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.server_name"
                        [value]="serverName"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ serverName }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>
                    {{ globalL10nKeys.serverIpLabel | vtranslate }}:{{ globalL10nKeys.portLabel | vtranslate }}
                </span>
                <ng-container *ngIf="row.server_ip !== undefined; else na">
                    <span
                        vsLogFilter
                    >
                        {{ row.server_ip }}
                    </span>
                    <ng-container *ngIf="row.source_port !== undefined">
                        :<span
                            vsLogFilter
                            property="oob_log.ds_req_logs.source_port"
                            [value]="row.source_port"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        >
                            {{ row.source_port }}
                        </span>
                    </ng-container>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.serversTriedLabel | vtranslate }}</span>
                <ng-container *ngIf="row.servers_tried !== undefined; else na">
                    <span
                        vsLogFilterList
                        property="oob_log.ds_req_logs.servers_tried"
                        [value]="row.servers_tried"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.servers_tried }}
                    </span>
                </ng-container>
            </div>
        </div>

        <!-- Request / Response -->
        <div class="vs-log-cinematic-section-out-of-band__grid-expanded-content-section">
            <!-- left column -->
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ globalL10nKeys.requestLabel | vtranslate }}
                </h5>
                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.httpMethodLabel | vtranslate }}</span>
                <ng-container *ngIf="row.method as httpMethod; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.method"
                        [value]="httpMethod"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ httpMethod }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.httpVersionLabel | vtranslate }}</span>
                <ng-container *ngIf="row.http_version?.val as httpVersion; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.http_version"
                        [value]="httpVersion"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ httpVersion }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.uriPathLabel | vtranslate }}</span>
                <ng-container *ngIf="row.uri_path as uriPath; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.uri_path"
                        [value]="uriPath"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ uriPath }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.uriQueryLabel | vtranslate }}</span>
                <ng-container *ngIf="row.uri_query as uriQuery; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.uri_query"
                        [value]="uriQuery"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ uriQuery }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.requestLengthLabel | vtranslate }}</span>
                <ng-container *ngIf="row.request_length !== undefined; else na">
                    <span
                        vsLogFilterList
                        property="oob_log.ds_req_logs.request_length"
                        [value]="row.request_length"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.request_length }}
                    </span>
                    {{ globalL10nKeys.lowerCaseBytesLabel }}
                </ng-container>
            </div>

            <!-- right column -->
            <div
                vsLogCinematicSectionColumn
                [withDivider]="false"
                class="vs-log-cinematic-section-out-of-band__response-column"
            >
                <h5
                    class="vs-log-cinematic-section__column-title vs-log-cinematic-section-out-of-band__grid-expanded-content-response-title"
                >
                    {{ globalL10nKeys.responseLabel | vtranslate }}
                </h5>

                <span
                    vsLogCinematicFieldTitle
                >
                    {{ l10nKeys.httpResponseCodeLabel | vtranslate }}
                </span>
                <ng-container *ngIf="row.http_response_code !== undefined; else na">
                    <span
                        vsLogFilter
                        property="oob_log.ds_req_logs.http_response_code"
                        [value]="row.http_response_code"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.http_response_code }}
                    </span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.responseLengthLabel | vtranslate }}</span>
                <ng-container *ngIf="row.response_length !== undefined; else na">
                    <span
                        vsLogFilterList
                        property="oob_log.ds_req_logs.response_length"
                        [value]="row.response_length"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.response_length }}
                    </span>
                </ng-container>
            </div>
        </div>

        <!-- Headers -->
        <div class="vs-log-cinematic-section-out-of-band__grid-expanded-content-section">
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ l10nKeys.dsHeaders | vtranslate }}
                </h5>
                <avi-diff-grid
                    [config]="headersDiffGridConfig"
                    [rows]="row | subLogHeadersDiffSourcePair : 'headers_sent_to_server' : 'headers_received_from_server' | aviDiffGridRows"
                ></avi-diff-grid>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
