/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'tenant-settings-page-list';
const prefix = `${moduleName}.${componentName}`;

export const dnsVirtualServicesLabel = `${prefix}.dnsVirtualServicesLabel`;

export const ENGLISH = {
    [dnsVirtualServicesLabel]: 'DNS Virtual Services',
};
