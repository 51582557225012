/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'snmp-trap';
const componentName = 'snmp-trap-profile-list-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const trapServerAddressLabel = `${prefix}.trapServerAddressLabel`;
export const trapServerAddressPlaceholder = `${prefix}.trapServerAddressPlaceholder`;
export const snmpCommunityLabel = `${prefix}.snmpCommunityLabel`;
export const snmpCommunityPlaceholder = `${prefix}.snmpCommunityPlaceholder`;
export const snmpVersionLabel = `${prefix}.snmpVersionLabel`;
export const snmpVersionPlaceholder = `${prefix}.snmpVersionPlaceholder`;
export const authPassphraseLabel = `${prefix}.authPassphraseLabel`;
export const authPassphrasePlaceholder = `${prefix}.authPassphrasePlaceholder`;
export const privacySettingsLabel = `${prefix}.privacySettingsLabel`;
export const privacySettingsPlaceholder = `${prefix}.privacySettingsPlaceholder`;
export const privacyPassphraseLabel = `${prefix}.privacyPassphraseLabel`;
export const privacyPassphrasePlaceholder = `${prefix}.privacyPassphrasePlaceholder`;
export const authTypeLabel = `${prefix}.authTypeLabel`;
export const authTypePlaceholder = `${prefix}.authTypePlaceholder`;

export const ENGLISH = {
    [modalHeader]: 'SNMP Trap Profile',
    [trapServerAddressLabel]: 'Trap Server IP(v4/v6) Address or FQDN',
    [trapServerAddressPlaceholder]: 'Enter Trap Server IP(v4/v6) Address or FQDN',
    [snmpCommunityLabel]: 'SNMP Community',
    [snmpCommunityPlaceholder]: 'Enter SNMP Community',
    [snmpVersionLabel]: 'SNMP Version',
    [snmpVersionPlaceholder]: 'Select SNMP Version',
    [authPassphraseLabel]: 'Auth Passphrase',
    [authPassphrasePlaceholder]: 'Enter Auth Passphrase',
    [privacySettingsLabel]: 'Privacy Settings',
    [privacySettingsPlaceholder]: 'Select Privacy Settings',
    [privacyPassphraseLabel]: 'Privacy Passphrase',
    [privacyPassphrasePlaceholder]: 'Enter Privacy Passphrase',
    [authTypeLabel]: 'Auth Type',
    [authTypePlaceholder]: 'Select Auth Type',
};
