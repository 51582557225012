/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { CustomIpamDnsProfile }
    from 'ajs/modules/ipam/factories/custom-ipam-dns-profile.item.factory';

import * as l10n from './custom-ipam-dns-profile-modal.l10n';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description Custom IPAM/DNS Profile full- modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'custom-ipam-dns-profile-modal',
    templateUrl: './custom-ipam-dns-profile-modal.component.html',
})
export class CustomIpamDnsProfileModalComponent {
    /**
     * CustomIpamDnsProfile instance.
     */
    @Input()
    public editable: CustomIpamDnsProfile;

    /**
     * CustomIpamDnsProfile object type.
     */
    public readonly objectType = 'CustomIpamDnsProfile';

    /**
     * Path of uploaded scripts.
     */
    public readonly filePath = 'controller://ipamdnsscripts';

    /**
     * API to upload scripts.
     */
    public readonly fileApi = '/api/fileservice/ipamdnsscripts';

    /**
     * Layout for ICAP modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    /**
     * Separate from editable.errors, this holds errors other than those on the Item.
     */
    private modalErrors: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    /**
     * Decides which type (item or modal) error to show.
     */
    public get errors(): CustomIpamDnsProfile['errors'] | string {
        return this.modalErrors || this.editable.errors;
    }

    /**
     * Handles population of error when backend returns upload error.
     */
    public handleUploadError(error: string): void {
        if (error) {
            this.modalErrors = error;
        } else {
            this.handleDeleteModalErrors();
        }
    }

    /**
     * Handles clearing of modal errors.
     */
    private handleDeleteModalErrors(): void {
        delete this.modalErrors;
    }
}
