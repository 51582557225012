/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createAction, props } from '@ngrx/store';
import { IUIProperty, IUserPreferences } from './user-preferences.state';

const USER_PREFERENCES_SOURCE = '[User Preferences]';

export const fetchUserPreferences = createAction(
    `${USER_PREFERENCES_SOURCE} Fetch User Preferences`,
);

export const fetchUserPreferencesSuccess = createAction(
    `${USER_PREFERENCES_SOURCE} Fetch User Preferences Success`,
    props<{ payload: IUserPreferences }>(),
);

export const fetchUserPreferencesError = createAction(
    `${USER_PREFERENCES_SOURCE} Fetch User Preferences Error`,
);

export const saveUserPreferencesSuccess = createAction(
    `${USER_PREFERENCES_SOURCE} Save User Preferences Success`,
    props<{ payload: IUserPreferences }>(),
);

export const saveUserPreferencesError = createAction(
    `${USER_PREFERENCES_SOURCE} Save User Preferences Error`,
);

export const updateUIProperty = createAction(
    `${USER_PREFERENCES_SOURCE} Update UIProperty and Save`,
    props<{ payload: IUIProperty }>(),
);

export const updateAppDashboard = createAction(
    `${USER_PREFERENCES_SOURCE} Update UIProperty.appDashboard and Save`,
    props<{ payload: IUIProperty['appDashboard'] }>(),
);

export const updateLogs = createAction(
    `${USER_PREFERENCES_SOURCE} Update UIProperty.logs and Save`,
    props<{ payload: IUIProperty['logs'] }>(),
);

export const updateGrid = createAction(
    `${USER_PREFERENCES_SOURCE} Update UIProperty.grid and Save`,
    props<{ payload: IUIProperty['grid'] }>(),
);
