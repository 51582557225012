<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            name="enable"
                            [(ngModel)]="editable.config.enable"
                            id="enable"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.AuthenticationRule }}"
                            fieldName="enable"
                            for="enable"
                        >
                            {{ l10nKeys.enableRuleLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{  objectTypes.AuthenticationRule }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleMatch | vtranslate }}">
            <div
                class="full-modal-config__section"
                [invalidateForm]="!editable.config.match.hasAnyEntry()"
            >
                <label
                    aviLabelWithTooltip
                    objectType="{{  objectTypes.AuthenticationRule }}"
                    fieldName="match"
                >
                    <h3>{{ l10nKeys.sectionTitleMatch | vtranslate }}</h3>
                </label>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <match-adder
                            [match]="editable.config.match"
                            [matchOptions]="matchOptions"
                        ></match-adder>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleAction | vtranslate }}">
            <div class="full-modal-config__section">
                <label
                    aviLabelWithTooltip
                    objectType="{{  objectTypes.AuthenticationRule }}"
                    fieldName="action"
                >
                    <h3>{{ l10nKeys.sectionTitleAction | vtranslate }}</h3>
                </label>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.AuthenticationAction }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="authenticationActionDropdownOptions"
                            [(ngModel)]="editable.config.action.config.type"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.typeSelectPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
