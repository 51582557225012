<item-preview-wrapper 
    class="cportal-info-preview"
    [config]="config"
    [isItem]="isItem"
>
    <item-preview-wrapper_custom-content class="cportal-info-preview__custom-content-section">
        <preview-field class="item-preview-wrapper__preview-field">
            <preview-field_label class="item-preview-wrapper__preview-field-content">
                {{ l10nKeys.portalUrlLabel | vtranslate }}
            </preview-field_label>
            <preview-field_val class="item-preview-wrapper__preview-field-content">
                {{ config.portal_url }}
            </preview-field_val>
        </preview-field>
        <preview-field
            class="item-preview-wrapper__preview-field"
            *ngIf="orgId"
        >
            <preview-field_label class="item-preview-wrapper__preview-field-content">
                {{ l10nKeys.organizationIdLabel | vtranslate }}
            </preview-field_label>
            <preview-field_val class="item-preview-wrapper__preview-field-content">
                {{ orgId }}
            </preview-field_val>
        </preview-field>
    </item-preview-wrapper_custom-content>
</item-preview-wrapper>
