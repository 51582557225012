/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { filter, take } from 'rxjs/operators';
import {
    AdminUserSetupComponent,
} from 'ng/modules/login/components/admin-user-setup/admin-user-setup.component';
import {
    LoginPageComponent,
} from 'ng/modules/login/components/login-page/login-page.component';
import {
    WelcomeModalContainerComponent,
} from 'ng/modules/welcome/components/welcome-modal-container/welcome-modal-container.component';

import {
    PasswordChangeComponent,
} from 'ng/modules/login/components/password-change/password-change.component';

import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import * as UserAccount from 'ng/root-store/user-account';
import * as UserPreferences from 'ng/root-store/user-preferences';
import { schema } from './services/schema';

const subModules = [
    ['avi/accounts'],
    ['avi/alerts'],
    ['avi/app'],
    ['avi/analytics'],
    ['avi/cloud'],
    ['avi/component-kit'],
    ['avi/component-kit/grid'],
    ['avi/core'],
    ['avi/cportal'],
    ['avi/cloud-connector-user'],
    ['avi/dataModel'],
    ['avi/deps'],
    ['avi/events'],
    ['avi/groups'],
    ['avi/gslb'],
    ['avi/healthmonitor'],
    ['avi/icap-profile'],
    ['avi/launch-config'],
    ['avi/licensing'],
    ['avi/login'],
    ['avi/logs'],
    ['avi/metrics'],
    ['avi/navigation'],
    ['avi/network'],
    ['avi/network-service'],
    ['avi/notifications'],
    ['avi/policies'],
    ['avi/policies/nat'],
    ['avi/l4-policy'],
    ['avi/policies/sso'],
    ['avi/policies/network-security'],
    ['avi/pool'],
    ['avi/profiles/application'],
    ['avi/ipam'],
    ['avi/router'],
    ['avi/scripts'],
    ['avi/security'],
    ['avi/serviceengine'],
    ['avi/support'],
    ['avi/system'],
    ['avi/traffic-clone-profile'],
    ['avi/upgrade'],
    ['avi/utils'],
    ['avi/vrf'],
    ['avi/vs'],
    ['avi/vs/security'],
    ['avi/waf'],
    ['charts'],
    ['avi/ip-reputation-db'],
    ['avi/service-engine-group'],
    ['avi/auto-scale'],
    ['avi/error-page'],
    ['avi/cluster'],
    ['avi/dns'],
    ['avi/persistence-profile'],
    ['avi/bot-detection-policy'],
    ['avi/vs-logs-wrapper'],
    ['avi/geo-db'],
    ['avi/match'],
    ['avi/alert'],
    ['avi/auth-settings'],
    ['avi/application-profile'],
    ['avi/service-engine'],
];

//submodules init
subModules.forEach(
    ([moduleName, deps = []]) => angular.module(moduleName, deps),
);

angular.module('aviApp', [
    'ngSanitize',
    'ui.router',
    'ui.router.upgrade',
    'dndLists',
    'vs-repeat',
].concat(
    subModules.map(([moduleName]) => moduleName),
));

// This exposes the internalStateObj from ui-router that allows us to match URLs with state
// names.
function stateProvider($stateProvider) {
    // This fn is called by StateBuilder each time a state is registered
    $stateProvider.decorator('parent', function(internalStateObj, parentFn) {
        // The first arg is the internal state. Capture it and add an accessor to public state
        // object.
        internalStateObj.self.$$state = function() {
            return internalStateObj;
        };

        // pass through to default .parent() function
        return parentFn(internalStateObj);
    });
}

stateProvider.$inject = [
    '$stateProvider',
];

angular.module('avi/core').config(stateProvider);

function appConfig(
    $stateProvider,
    $urlRouterProvider,
    $httpProvider,
    $compileProvider,
    $qProvider,
    appDefaultState,
    appStateTree,
) {
    let debugMode = true;

    const stateRoutes = angular.copy(appStateTree);

    // This config runs only on production environments to switch off some debug data (like
    // angular.scope) from class names and DOM nodes.
    if (process.env.NODE_ENV === 'production') {
        $compileProvider.debugInfoEnabled(false);

        debugMode = false;
    }

    // Suppresses Promise-unhandled errors on both dev and prod modes.
    $qProvider.errorOnUnhandledRejections(false);

    //true for dev builds, false otherwise
    window.avi.debugMode = debugMode;

    //need this wrapper $location > $state to avoid circular changes of state & location
    //causing endless digest loop from when and otherwise
    function goToState(toStatename = appDefaultState) {
        return ['$match', '$state', '$stateParams', function($match, $state, $stateParams) {
            return $state.go(toStatename, $stateParams);
        }];
    }

    const loginParams = [
        'csrf_token',
        'session_id',
        'tenant_name',
        'permissions',
        'read_only',
        'redirect',
    ];

    const loadedSystemInfoService = {
        token: 'loadedSystemInfoService',
        resolveFn: systemInfo => systemInfo.load().then(() => systemInfo),
        deps: ['systemInfoService'],
    };

    // Loads local data (language pack) if language from user account settings
    // doesn't match the default (or current) language.
    // myAccount data has to be loaded at this point.
    // Not being injected anywhere but needs to be loaded for proper translations rendering.
    const loadLocaleDataAndSwitchLanguage = {
        token: 'loadLocaleDataAndSwitchLanguage',
        resolveFn: (myAccount, localeService, vipService) => {
            localeService.init(myAccount.language);

            return vipService.loadLocaleData();
        },
        deps: [
            'myAccount',
            'localeService',
            'vipService',
            'fetchedUserPreferences', // to wait till myAccount is loaded
        ],
    };

    const loadedDefaultValues = {
        token: 'loadedDefaultValues',
        resolveFn: defaultValues => defaultValues.load(),
        deps: ['defaultValues'],
    };

    /**
     * Dispatches the Fetch User Account action and checks that it's been loaded.
     */
    const fetchUserAccount = {
        token: 'fetchedUserAccount',
        resolveFn: store => {
            store.dispatch(UserAccount.fetchUserAccount());

            return store.select(UserAccount.selectLoaded)
                .pipe(filter(loaded => loaded), take(1))
                .toPromise();
        },
        deps: [STORE_TOKEN],
    };

    /**
     * Dispatches the Fetch User Preferences action and checks that it's been loaded.
     */
    const fetchUserPreferences = {
        token: 'fetchedUserPreferences',
        resolveFn: store => {
            store.dispatch(UserPreferences.fetchUserPreferences());

            return store.select(UserPreferences.selectLoaded)
                .pipe(filter(loaded => loaded), take(1))
                .toPromise();
        },
        deps: [STORE_TOKEN],
    };

    $stateProvider
        .state('login', {
            url: `/login?${loginParams.join('&')}`,
            component: LoginPageComponent,
            params: {
                statusCode: '',
            },
        })
        .state('admin-user-setup', {
            url: '/admin-user-setup',
            component: AdminUserSetupComponent,
        })
        .state('welcome', {
            url: '/welcome',
            component: WelcomeModalContainerComponent,
            resolve: [
                // although resolved injections only works for components, by resolving it under
                // Welcome modal can make sure defaultValues used in WelcomeService is fully loaded
                // since the service is in-turn used by this component
                loadedDefaultValues,
            ],
        })
        .state('pre-welcome-aws', {
            url: '/pre-welcome-aws',
            component: 'preAwsWelcomePage',
        })
        .state('404.**', {
            url: '/404',
            loadChildren: () => {
                return import('ng/modules/alb-error-page/alb-error-page.module').then(mod => {
                    return mod.AlbErrorPageModule;
                });
            },
        })
        .state('503', { // Server error
            url: '/503',
            component: 'errorPage',
            data: {
                statusCode: 503,
            },
        })
        .state('520', { // Server unknown error
            url: '/520',
            component: 'errorPage',
            data: {
                statusCode: 520,
            },
        })
        .state('500.**', {
            url: '/500',
            loadChildren: () => {
                return import('ng/modules/alb-error-page/alb-error-page.module').then(mod => {
                    return mod.AlbErrorPageModule;
                });
            },
        })
        .state('401', {
            url: '/401',
            component: 'errorPage',
            data: {
                statusCode: 401,
            },
        })
        .state('logged-out', {
            url: '/logged-out',
            component: 'loggedOutPage',
        })
        .state('passwordchange', {
            url: '/passwordchange/:username/:token',
            component: PasswordChangeComponent,
        })
        .state('authenticated', {
            component: 'aviRoot',
            url: '/:tenantName',
            params: {
                tenantName: '',
            },
            resolve: [
                {
                    ...loadedDefaultValues,
                    policy: { async: 'NOWAIT' },
                },
                angular.extend(
                    {},
                    loadedSystemInfoService,
                    { policy: { async: 'NOWAIT' } },
                ),
                fetchUserAccount,
                fetchUserPreferences,
                loadLocaleDataAndSwitchLanguage,
            ],
        })
        // logout when used inside an iframe (OS horizon mode)
        .state('please-reload', {
            url: '/please-reload',
            templateUrl: 'src/views/please-reload.html',
        });

    function generateStateRoutes(routes, parentStateName) {
        let previousState;

        for (let i = 0; i < routes.length; i++) {
            const state = routes[i];
            const path = parentStateName ? `${parentStateName}.` : '';

            state.data = state.data || {};

            const nextState = routes[i + 1];

            if (parentStateName) {
                state.data.parentState = parentStateName;
                state.name = path + state.name;
            }

            const stateName = state.name;

            if (nextState) {
                state.data.nextState = path + nextState.name;
            }

            if (previousState) {
                state.data.previousState = previousState;
            }

            const stateCopy = angular.extend({}, state);

            $stateProvider.state(stateName, stateCopy);

            if (state.children && state.children.length) {
                stateCopy.data.nextChildState = `${stateName}.${state.children[0].name}`;
                generateStateRoutes(state.children, stateName);
            }

            previousState = stateName;
        }
    }

    generateStateRoutes(stateRoutes);

    //----------------------------------------------------------------------------------------
    // Redirection to avoid abstract states. For manual url updates and links from outer world
    //----------------------------------------------------------------------------------------
    $urlRouterProvider
        .when('', goToState())
        .when('/', goToState())
        .when('/authenticated', goToState())
        .when('/authenticated/applications', goToState())
        .when('/authenticated/applications/pool/',
            goToState('authenticated.application.pool-list'))
        .when('/authenticated/applications/virtualservice/{vsId}',
            goToState('authenticated.application.virtualservice-detail.analytics'))
        .when('/authenticated/applications/pool/{poolId}/{vsId}',
            goToState('authenticated.application.pool-detail.analytics'))
        .when('/authenticated/infrastructure',
            goToState('authenticated.infrastructure.dashboard'))
        .when('/authenticated/template',
            goToState('authenticated.profile.profiles.applicationprofile-list'))
        .when('/authenticated/template/profile',
            goToState('authenticated.profile.profiles.applicationprofile-list'))
        .when('/authenticated/template/policy',
            goToState('authenticated.profile.policy.nat-policy-list'))
        .when('/authenticated/template/group',
            goToState('authenticated.profile.group.ipaddrgroup-list'))
        .when('/authenticated/template/ssl',
            goToState('authenticated.profile.ssl.certificate-list'))
        .when('/authenticated/template/scripts',
            goToState('authenticated.profile.scripts.datascriptset-list'))
        .when('/authenticated/template/autoscale',
            goToState('authenticated.profile.autoscale.server-autoscale'))
        .when('/authenticated/infrastructure/cloud/{cloudId}/serviceengine/{seId}',
            goToState('authenticated.infrastructure.cloud.serviceengine-detail.summary'))
        .when('/authenticated/administration/controller/{controllerId}/',
            goToState('authenticated.administration.controller-detail.events'))
        .otherwise(($injector, $location) => {
            // Only invalid routes end up here.
            const url = $location.path();

            // Child states of authenticated.infrastructure has place for cloudId in URL.
            // ex: /admin/infrastructure/serviceengine/{cloudId}.
            // but cloudId is not always present (in case of defaultCloud)
            // so UI-Router expect at least an ending `/` in their URL (empty cloudId).

            // ie `/admin/infrastructure/serviceengine/` is valid,
            // but `/admin/infrastructure/serviceengine` is invalid.
            // This regex is to match for such invalid states and append a slash at the end.
            const infraStatesWithOptionalCloudIdRegex = /^\/[^/]+\/infrastructure\/[^/]+$/;

            // Most of the URLs doesnt expect ending slashes, so
            // we are removing it.
            if (url.endsWith('/')) {
                return url.slice(0, url.length - 1);
            } else if (infraStatesWithOptionalCloudIdRegex.test(url)) {
                // This block is for states that expects ending `/`.
                // so adding `/` (ie empty cloudId) manually to avoid re-routing.
                return `${url}/`;
            }

            const $state = $injector.get('$state');

            return $state.go(appDefaultState);
        });

    const
        { defaults } = $httpProvider,
        { common: headers } = defaults.headers;

    defaults.xsrfCookieName = 'csrftoken';
    defaults.xsrfHeaderName = 'X-CSRFToken';

    headers['X-Avi-UserAgent'] = 'UI';
    headers['X-Avi-Version'] = schema['version'];

    $httpProvider.interceptors.push('httpInterceptor');

    $compileProvider
        .commentDirectivesEnabled(false)
        .cssClassDirectivesEnabled(false);
}

appConfig.$inject = [
    '$stateProvider',
    '$urlRouterProvider',
    '$httpProvider',
    '$compileProvider',
    '$qProvider',
    'appDefaultState',
    'appStateTree',
];

// setup routes
angular.module('aviApp').config(appConfig);
