<div class="ng-file-upload clr-wrapper">
    <div class="ng-file-upload__file-label-container">
        <span
            class="ng-file-upload__file-label"
            [ngClass]="{'ng-file-upload__file-label--without-value': !fileName}"
        >
            {{ fileLabel }}
        </span>
        <cds-icon
            *ngIf="fileName"
            class="ng-file-upload__clear-icon"
            shape="times-circle"
            size="20"
            (click)="removeUploadedFile()"
        ></cds-icon>
    </div>

    <button
        class="{{ getButtonClassName() }}"
        [disabled]="disabled"
        (click)="invokeFileUpload()"
    >
        {{ buttonLabel }}
    </button>

    <input
        class="ng-file-upload__input sel-file-upload"
        type="file"
        (change)="startFileLoad($event)"
        [disabled]="busy || disabled"
        #fileUploadElementRef
    />
</div>
