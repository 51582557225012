/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'vh-match-rule-grid';
const prefix = `${moduleName}.${componentName}`;

export const rulesLabel = `${prefix}.rulesLabel`;

export const ENGLISH = {
    [rulesLabel]: 'Rules ({0})',
};
