/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'health-monitor-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const sendIntervalLabel = `${prefix}.sendIntervalLabel`;
export const receiveTimeoutLabel = `${prefix}.receiveTimeoutLabel`;
export const successfulChecksLabel = `${prefix}.successfulChecksLabel`;
export const failedChecksLabel = `${prefix}.failedChecksLabel`;

export const ENGLISH = {
    [headerLabel]: 'Health Monitors',
    [sendIntervalLabel]: 'Send Interval',
    [receiveTimeoutLabel]: 'Receive Timeout',
    [successfulChecksLabel]: 'Successful Checks',
    [failedChecksLabel]: 'Failed Checks',
};

