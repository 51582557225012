<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
    [showRemoveIcon]="!disabled"
>
    <div
        class="http-method-match"
        [invalidateForm]="!editable.config.methods?.length"
    >
        <enum-radio-buttons
            [noMarginTop]="true"
            enum="MatchOperation"
            name="match-operation-{{ matchIndex }}"
            [(ngModel)]="editable.config.match_criteria"
            [disabled]="disabled"
        ></enum-radio-buttons>
        <div class="http-method-match__dropdown-wrapper">
            <label
                aviLabelWithTooltip
                objectType="{{ httpMethodMatchObjectType }}"
                fieldName="methods"
                for="methods"
                required
            >
                {{ l10nKeys.methodLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="methods"
                id="methods"
                [(ngModel)]="editable.config.methods"
                [options]="httpMethodOptions"
                placeholder="{{ l10nKeys.methodPlaceholder | vtranslate }}"
                [multiple]="true"
                [disabled]="disabled"
                required
            ></avi-dropdown>
        </div>
    </div>
</match-wrapper>
