<avi-state-page class="cportal-page clr-wrapper">
    <avi-state-page_main-content>
        <div class="cportal-page__header-container">
            <div class="cportal-page__status-icon-and-text">
                <avi-tooltip-icon-with-content-wrapper
                    shape="circle"
                    size="28"
                    status="{{ aviCloudConnectedStatus }}"
                    [solid]="true"
                    [hidden]="busy"
                    class="cportal-page__pulse-status"
                >
                    {{ portalUrl }}
                </avi-tooltip-icon-with-content-wrapper>
                <div
                    class="cportal-page__pulse-spinner"
                    *ngIf="busy"
                >
                    <clr-spinner [clrMedium]="true" ></clr-spinner>
                </div>
                <h2 class="cportal-page__pulse-header">
                    {{ l10nKeys.cloudServicesLabel | vtranslate }}
                </h2>
            </div>
            <cds-button
                action="flat"
                (click)="openRegistrationModal(true)"
                [disabled]="busy || !isAdminTenant"
                title="{{ l10nKeys.editCloudServicesSettingsTooltip | vtranslate }}"
            >
                {{ globalL10nKeys.editLabel | vtranslate }}
            </cds-button>
        </div>
        <div
            *ngIf="connectivityAndRegisteredMessage"
            class="cportal-page__alert"
        >
            <avi-alert-group alertType="{{ hasConnectivityError ? AlertType.danger : AlertType.info }}">
                    {{ connectivityAndRegisteredMessage }}
                    <cds-alert-actions>
                        <cds-button
                            (click)="openRegistrationModal()"
                            *ngIf="!hasConnectivityError && unregisteredAndConnected && !busy"
                        >
                            {{ l10nKeys.controllerRegisteredControllerBtnLabel | vtranslate }}
                        </cds-button>
                        <cds-button
                            (click)="reconnectController()"
                            *ngIf="hasConnectivityError && !unregisteredAndConnected && !busy"
                        >
                            {{ l10nKeys.reconnectNowLabel | vtranslate }}
                        </cds-button>
                    </cds-alert-actions>
            </avi-alert-group>
        </div>
        <div class="cportal-page__cards">
            <avi-card class="cportal-page__cloud-services-card">
                <avi-card_header>
                    <div class="cportal-page__cloud-services-card-header">
                        <cds-icon
                            shape="internet-of-things"
                            size="24"
                            status="success"
                        ></cds-icon>
                        <div class="cportal-page__cloud-services-title-text">
                            {{ l10nKeys.cloudServicesLabel | vtranslate }}
                        </div>
                    </div>
                </avi-card_header>
                <avi-card_body>
                    <div class="cportal-page__portal-url">
                        <label aviLabelWithTooltip>
                            {{ l10nKeys.portalUrlLabel | vtranslate }}
                        </label>
                        <div>{{ portalUrl || '-' }}</div>
                    </div>
                    <div>
                        <label aviLabelWithTooltip>
                            {{ l10nKeys.organizationIdLabel | vtranslate }}
                        </label>
                        <div>{{ orgId || '-' }}</div>
                    </div>
                </avi-card_body>
            </avi-card>
            <avi-card class="cportal-page__controller-card">
                <avi-card_header>
                    <div class="cportal-page__header">
                        <div class="cportal-page__controller-title-text">
                            {{ l10nKeys.controllerLabel | vtranslate }}
                        </div>
                        <div class="cportal-page__registered-title-text">
                            <ng-container *ngIf="isControllerRegistered; else elseTemplate">
                                {{ l10nKeys.registeredLabel | vtranslate }}
                            </ng-container>
                            <ng-template #elseTemplate>
                                {{ l10nKeys.unregisteredLabel | vtranslate }}
                            </ng-template>
                        </div>
                    </div>
                </avi-card_header>
                <avi-card_body>
                    <div class="cportal-page__body">
                        <div class="cportal-page__controller-edit">
                            <avi-input-container noMarginTop>
                                <input
                                    type="text"
                                    clrInput
                                    name="placement"
                                    [value]="controllerName"
                                    disabled
                                />
                            </avi-input-container>
                        </div>
                        <avi-tooltip-icon-with-content-wrapper
                            shape="pencil"
                            size="18"
                            status="info"
                            (click)="openControllerEditModal()"
                            [hidden]="busy || !isAdminTenant"
                        >
                            {{ l10nKeys.editControllerSettingsTooltip | vtranslate }}
                        </avi-tooltip-icon-with-content-wrapper>
                    </div>
                </avi-card_body>
                <avi-card_footer *ngIf="showFooterButtons">
                    <cds-button
                        action="flat"
                        status="info"
                        (click)="connectAndRegisterController()"
                        *ngIf="unregisteredAndDisconnected"
                        [disabled]="!isAdminTenant"
                        size="sm"
                    >
                        {{ l10nKeys.registerControllerBtnLabel | vtranslate }}
                    </cds-button>
                    <cds-button
                        status="danger"
                        action="flat"
                        (click)="deregisterController()"
                        [disabled]="!isAdminTenant"
                        *ngIf="isControllerRegistered"
                        size="sm"
                    >
                        {{ l10nKeys.deregisterControllerBtnLabel | vtranslate }}
                    </cds-button>
                    <cds-button
                        action="flat"
                        status="info"
                        (click)="openRegistrationModal()"
                        [disabled]="!isAdminTenant"
                        *ngIf="unregisteredAndConnected"
                        size="sm"
                    >
                        {{ l10nKeys.controllerRegisteredControllerBtnLabel | vtranslate }}
                    </cds-button>
                </avi-card_footer>
            </avi-card>
        </div>
        
    </avi-state-page_main-content>
</avi-state-page>
