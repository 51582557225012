/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserAccountEffects } from './user-account.effects';
import { featureKey } from './user-account.selectors';
import { userAccountReducer } from './user-account.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(featureKey, userAccountReducer),
        EffectsModule.forFeature([UserAccountEffects]),
    ],
})
export class UserAccountModule {}
