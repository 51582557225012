/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name PermissionCheckDirective
 * @description Directive for rendering elements based on tenant permissions. Works similarly to the
 *     AngularJS version, except that the template will be rendered or not rendered based on the
 *     permission instead of being simply hidden, and the binding is a one-way binding instead of a
 *     string binding, so the privilege will need to be wrapped in quotes. This is because this
 *     directive is a structural directive, noted by the *.
 * @example
 *     <div *permissionCheck="'PERMISSION_VIRTUALSERVICE'">VirtualService</div>
 *     <div *permissionCheck="'PERMISSION_POOL:WRITE_ACCESS'">Pool</div>
 * @author alextsg
 */

import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';

import { Privilege } from 'generated-types';
import { Subscription } from 'rxjs';
import { Auth } from 'ajs/modules/core/services/auth';
import { TenantService } from 'ng/modules/core/services/tenant.service';

@Directive({ selector: '[permissionCheck]' })
export class PermissionCheckDirective {
    /**
     * The privilege resource and type for checking permissions.
     */
    @Input() public permissionCheck: string;

    /**
     * True if the user currently has permissions and the view has been rendered.
     * True if the user currently has no permissions been passed and the view has been rendered.
     * Used to compare with an incoming value.
     */
    private allowed = false;

    private tenantRefSubscription: Subscription;

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private tenantService: TenantService,
        private authService: Auth,
    ) { }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.setView();
        this.tenantRefSubscription = this.tenantService.currentTenantRef$.subscribe(this.setView);
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.tenantRefSubscription.unsubscribe();
    }

    /**
     * Will either add the element this directive is attached to to the DOM or removes it, based on
     * whether the permission is allowed or not.
     */
    private setView = (): void => {
        if (this.permissionCheck) {
            const privilegeParts = this.permissionCheck.split(':');
            const [privilegeResource, privilegeType] = privilegeParts;

            const isAllowed = this.authService.isPrivilegeAllowed(
                privilegeResource,
                privilegeType as Privilege,
            );

            if (isAllowed && !this.allowed) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.allowed = true;
            } else if (!isAllowed && this.allowed) {
                this.viewContainer.clear();
                this.allowed = false;
            }
        } else if (!this.allowed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.allowed = true;
        }
    };
}
