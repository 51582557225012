/**
 * @module NetworkServiceModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { NETWORK_SERVICE_TOKEN } from './network-service.item';

/**
 * @description Collection for network_service.
 * @author Akul Aggarwal
 */
export class NetworkServiceCollection extends Collection {
    public static ajsDependencies = [
        NETWORK_SERVICE_TOKEN,
    ];

    constructor(args = {}) {
        super({
            objectName: 'networkservice',
            ...args,
        });

        this.itemClass_ = this.getAjsDependency_(NETWORK_SERVICE_TOKEN);
    }
}

export const NETWORK_SERVICE_COLLECTION_TOKEN = 'NetworkServiceCollection';
