<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-dropdown
                    name="vs-vip-filter"
                    [options]="vsvipFilterOptions"
                    [(ngModel)]="vsvipFilter"
                    (ngModelChange)="onFilterChange()"
                    [hideSearch]="true"
                    [prepend]="l10nKeys.showPrependLabel | vtranslate"
                ></avi-dropdown>
            </div>
        </div>
        <avi-collection-data-grid [config]="vsVipGridConfig"></avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
<ng-template
    #vsVipListExpanderTemplate
    let-row
>
    <div>
        <h5>{{ globalL10nKeys.virtualServicesLabel | vtranslate }}</h5>
        <span *ngFor="let vsRef of row.getConfig().vs_refs; first as first; trackBy: trackByIndex">
            {{ first ? "" : ", " }}
            <ng-container *ngIf="vsRef;else emptyLabel">
                <a
                    class="vs-vip-list-page__link"
                    (click)="navigate(vsRef)"
                >
                    {{ vsRef | name }}
                </a>
            </ng-container>
        </span>
        <span *ngIf="!row.getConfig().vs_refs.length;then emptyLabel"></span>
    </div>
</ng-template>
<ng-template
    #vsAddressGridCellTemplate
    let-row
>
    <vs-vip-address-grid-cell [vsVip]="row"></vs-vip-address-grid-cell>
</ng-template>
<ng-template #emptyLabel>
    {{ globalL10nKeys.emptyLabel | vtranslate }}
</ng-template>
