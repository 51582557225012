<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
    [showRemoveIcon]="!disabled"
>
    <div
        class="host-hdr-match"
        provideParentForm
        [invalidateForm]="!editable.config.value?.length"
    >
        <div class="host-hdr-match__input-container">
            <label
                aviLabelWithTooltip
                objectType="HostHdrMatch"
                fieldName="match_criteria"
                required
            >
                {{ l10nKeys.criteriaLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="host-hdr"
                [(ngModel)]="editable.config.match_criteria"
                [options]="criteriaOptions"
                (ngModelChange)="handleCriteriaChange()"
                placeholder="{{ l10nKeys.enterCriteriaLabel | vtranslate }}"
                [disabled]="disabled"
                required
            ></avi-dropdown>
        </div>
    </div>

    <div
        class="host-hdr-match__input-container host-hdr-match__input-container--value"
        *ngIf="showValueField()"
    >
        <label
            aviLabelWithTooltip
            objectType="HostHdrMatch"
            fieldName="value"
            for="value"
            required
        >
            {{ l10nKeys.valueLabel | vtranslate }}
        </label>
        <avi-repeated-strings
            name="{{ 'host_hdr_value_' + matchIndex }}"
            id="value"
            placeholder="{{ l10nKeys.enterValuesLabel | vtranslate }}"
            objectType="HostHdrMatch"
            fieldName="value"
            [(ngModel)]="editable.config.value"
            [disabled]="disabled"
            required
        ></avi-repeated-strings>
    </div>
</match-wrapper>
