/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'state-group';
const prefix = `${moduleName}.${componentName}`;

export const application = `${prefix}.application`;
export const templates = `${prefix}.templates`;
export const infrastructureOption = `${prefix}.infrastructureOption`;
export const administrationOption = `${prefix}.administrationOption`;
export const operationsOption = `${prefix}.operationsOption`;

export const ENGLISH = {
    [application]: 'Applications',
    [templates]: 'Templates',
    [infrastructureOption]: 'Infrastructure',
    [administrationOption]: 'Administration',
    [operationsOption]: 'Operations',
};
