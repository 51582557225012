/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/pages/infrastructure/serviceengine-analytics.less';
import * as l10n from './SEAnalyticsController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name SEAnalyticsController
 */
angular.module('aviApp').controller('SEAnalyticsController', [
'$scope', '$stateParams', '$q', 'ChartConfig', 'myAccount', 'l10nService',
function($scope, $stateParams, $q, ChartConfig, myAccount, l10nService) {
    $scope.myAccount = myAccount;
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const metrics = [
        {
            name: 'se_if.avg_bandwidth',
        }, {
            name: 'se_stats.avg_cpu_usage',
        }, {
            name: 'se_stats.avg_mem_usage',
        }, {
            name: 'rx',
            seriesId: 'se_if.avg_rx_pkts',
            metricClassName: 'SingleSeriesPerEveryObjectMetric',
        }, {
            name: 'tx',
            seriesId: 'se_if.avg_tx_pkts',
            metricClassName: 'SingleSeriesPerEveryObjectMetric',
        }, {
            name: 'se_throughput_by_interface',
            seriesId: 'se_if.avg_bandwidth',
            metricClassName: 'SingleSeriesPerEveryObjectMetric',
        }, {
            name: 'se_throughput_by_vs',
            metricClassName: 'SeThroughputByVsMetric',
        }, {
            name: 'se_stats.avg_connection_mem_usage',
        }, {
            name: 'se_stats.avg_dynamic_mem_usage',
        }, {
            name: 'se_stats.avg_ssl_session_cache_usage',
        }, {
            name: 'se_stats.avg_persistent_table_usage',
        }, {
            name: 'se_stats.avg_packet_buffer_usage',
        },
    ];

    metrics.forEach(metric =>
        metric.subscriber = 'seAnalyticsController');

    //need this ugly part to avoid exceptions on initial load TODO remove
    $scope.config = new ChartConfig([{
        id: '',
        series: [],
    }]);

    const { se } = $scope;

    $q.when(se.getConfig() || se.load(['config']))
        .then(() => {
            se.addLoad([
                'health',
                'alert',
                'config_events',
                'system_events',
                'alerts',
            ]);

            se.collMetricsSubscribe(metrics)
                .then(() => {
                    const config = se.createChartsConfig();

                    $scope.config = new ChartConfig(config);
                    $scope.config.setActiveCard($stateParams.metric);
                    se.startCollMetricsAsync();
                });
        });

    /**
     * State message to be displayed next to SE Health Score icon.
     * @return {string} - State string.
     */
    $scope.getSEState = function() {
        let res = '';

        if (!se || !se.getRuntimeData()) {
            return res;
        }

        const { state } = se.getRuntimeData()['oper_status'];

        if (state === 'OPER_DISABLING') {
            res = l10nService.getMessage(l10nKeys.disableInProgressState);
        } else if (state === 'OPER_ERROR_DISABLED') {
            res = l10nService.getMessage(l10nKeys.errorDisabledState);
        } else if (se.isDisabledForPlacement()) {
            res = l10nService.getMessage(l10nKeys.disabledForPlacementState);
        }

        return res;
    };

    $scope.$on('$destroy', () => {
        se.async.stop(true);
        se.removeLoad('config_events', 'system_events', 'alerts');
    });
}]);
