/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'prechecks-progress-modal';
const prefix = `${moduleName}.${componentName}`;

export const patchLabel = `${prefix}.patchLabel`;
export const rollbackLabel = `${prefix}.rollbackLabel`;
export const rollbackPatchLabel = `${prefix}.rollbackPatchLabel`;
export const headerLabel = `${prefix}.headerLabel`;
export const nodeNameLabel = `${prefix}.nodeNameLabel`;
export const transcriptLabel = `${prefix}.transcriptLabel`;
export const precheckProgressLabel = `${prefix}.precheckProgressLabel`;
export const precheckCompletedLabel = `${prefix}.precheckCompletedLabel`;
export const precheckPendingLabel = `${prefix}.precheckPendingLabel`;
export const suppressWarningHeader = `${prefix}.suppressWarningHeader`;
export const precheckSuccessStatusLabel = `${prefix}.precheckSuccessStatusLabel`;
export const precheckErrorStatusLabel = `${prefix}.precheckErrorStatusLabel`;
export const precheckWarningStatusLabel = `${prefix}.precheckWarningStatusLabel`;
export const precheckInProgressStatusLabel = `${prefix}.precheckInProgressStatusLabel`;

export const ENGLISH = {
    [patchLabel]: 'Patch',
    [rollbackLabel]: 'Upgrade (Rollback)',
    [rollbackPatchLabel]: 'Patch (Rollback)',
    [headerLabel]: '{0} Transcript',
    [nodeNameLabel]: 'Source',
    [transcriptLabel]: 'Transcript',
    [precheckProgressLabel]: 'Precheck Progress',
    [precheckCompletedLabel]: 'Precheck Completed',
    [precheckPendingLabel]: 'Pending',
    [suppressWarningHeader]: 'Suppress Warnings',
    [precheckSuccessStatusLabel]: 'Success',
    [precheckErrorStatusLabel]: 'Error',
    [precheckWarningStatusLabel]: 'Warning',
    [precheckInProgressStatusLabel]: 'In Progress',
};
