<avi-state-page class="crash-reports-page">
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.coreFilesHeader | vtranslate }}"></avi-page-header>
        <avi-data-grid
            [config]="crashReportsGridConfig"
            [rows]="rows$ | async"
            [isLoading]="isLoading"
        ></avi-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
<ng-template
    #expandedContentTemplateRef
    let-row
    let-index="index"
>
    <pre class="crash-reports-page__expanded-content">{{ row.stack_trace }}</pre>
</ng-template>
