/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module Angular-Core
 */

/**
 * @fileoverview Angular services need to be specified here to be downgraded and used in
 *     AngularJS.
 */
import angular from 'angular';

import { NgZone } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';
import {
    I18nContext,
    I18nService,
    L10nService,
    LocaleService,
    VIPService,
} from '@vmw/ngx-vip';

import { Store } from '@ngrx/store';
import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import { InitialDataService } from 'ng/modules/core/services/initial-data/initial-data.service';
import { MyAccountService } from 'ng/modules/core/services/my-account.service';
import { ControllerFaultsService } from 'ng/modules/core/services/controller-faults.service';
import { CSRFPolicyCollection } from 'collections/csrf-policy.collection.factory';
import { UpdateService } from 'ng/modules/update/services/update.service';

import {
    AviAlertService,
    DevLoggerService,
    DialogService,
    DIALOG_SERVICE_TOKEN,
    FullModalService,
    FULL_MODAL_SERVICE_TOKEN,
    NotificationService,
    OverlayRemovalService,
    OVERLAY_REMOVAL_SERVICE_TOKEN,
    StatePermissionTreeService,
    TenantService,
} from './angular/modules/core/services';

import { AppLevelAlertsService } from './angular/modules/core/services/app-level-alerts.service';

import {
    CentralLicenseAlertsService,
} from './angular/modules/core/services/central-license-alerts';

import { WelcomeService } from './angular/modules/welcome';
import { VerifyCloudConnectorUserHostModalService }
    from './angular/modules/cloud/services/verify-cloud-connector-user-host-modal.service';
import { RecommendationDialogService }
    from './angular/modules/logs/services/recommendation-dialog.service';

import {
    CERTIFICATE_EXPORT_MODAL_SERVICE_TOKEN,
    HsmGroupDownloadCertificateModalService,
    SslCertificateExportModalService,
} from './angular/modules/security';
import { VirtualServiceStateService } from './angular/modules/virtual-service/services';

import {
    APP_LEVEL_ALERTS_SERVICE_TOKEN,
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
    CONTROLLER_FAULTS_SERVICE_TOKEN,
    CSRF_POLICY_COLLECTION_TOKEN,
    HSM_GROUP_DOWNLOAD_CERTIFICATE_MODAL_SERVICE_TOKEN,
    RECOMMENDATION_DIALOG_SERVICE_TOKEN,
    UPDATE_SERVICE_TOKEN,
    VERIFY_CLOUD_CONNECTOR_USER_HOST_MODAL_SERVICE_TOKEN,
    VIRTUAL_SERVICE_STATE_SERVICE_TOKEN,
} from './downgrade-services.tokens';

const downgradeServices = [
    {
        ajsName: 'StatePermissionTreeService',
        service: StatePermissionTreeService,
    },
    {
        ajsName: 'TenantService',
        service: TenantService,
    },
    {
        ajsName: 'notificationService',
        service: NotificationService,
    },
    {
        ajsName: FULL_MODAL_SERVICE_TOKEN,
        service: FullModalService,
    },
    {
        ajsName: 'l10nService',
        service: L10nService,
    },
    {
        ajsName: 'i18nService',
        service: I18nService,
    },
    {
        ajsName: 'welcomeService',
        service: WelcomeService,
    },
    {
        ajsName: 'localeService',
        service: LocaleService,
    },
    {
        ajsName: 'vipService',
        service: VIPService,
    },
    {
        ajsName: 'i18nContext',
        service: I18nContext,
    },
    {
        ajsName: 'devLoggerService',
        service: DevLoggerService,
    },
    {
        ajsName: DIALOG_SERVICE_TOKEN,
        service: DialogService,
    },
    {
        ajsName: CERTIFICATE_EXPORT_MODAL_SERVICE_TOKEN,
        service: SslCertificateExportModalService,
    },
    {
        ajsName: 'aviAlertService',
        service: AviAlertService,
    },
    {
        ajsName: HSM_GROUP_DOWNLOAD_CERTIFICATE_MODAL_SERVICE_TOKEN,
        service: HsmGroupDownloadCertificateModalService,
    },
    {
        ajsName: APP_LEVEL_ALERTS_SERVICE_TOKEN,
        service: AppLevelAlertsService,
    },
    {
        ajsName: CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
        service: CentralLicenseAlertsService,
    },
    {
        ajsName: VERIFY_CLOUD_CONNECTOR_USER_HOST_MODAL_SERVICE_TOKEN,
        service: VerifyCloudConnectorUserHostModalService,
    },
    {
        ajsName: RECOMMENDATION_DIALOG_SERVICE_TOKEN,
        service: RecommendationDialogService,
    },
    {
        ajsName: STORE_TOKEN,
        service: Store,
    },
    {
        ajsName: 'NgZone',
        service: NgZone,
    },
    {
        ajsName: VIRTUAL_SERVICE_STATE_SERVICE_TOKEN,
        service: VirtualServiceStateService,
    },
    // Todo AV-177044: Remove once modal is migrated to angular.
    {
        ajsName: 'initialDataService',
        service: InitialDataService,
    },
    {
        ajsName: 'myAccount',
        service: MyAccountService,
    },
    {
        ajsName: CONTROLLER_FAULTS_SERVICE_TOKEN,
        service: ControllerFaultsService,
    },
    {
        ajsName: CSRF_POLICY_COLLECTION_TOKEN,
        service: CSRFPolicyCollection,
    },
    {
        ajsName: OVERLAY_REMOVAL_SERVICE_TOKEN,
        service: OverlayRemovalService,
    },
    {
        ajsName: UPDATE_SERVICE_TOKEN,
        service: UpdateService,
    },
];

downgradeServices.forEach((serviceData: any): void => {
    const { ajsName, service } = serviceData;

    if (!ajsName) {
        throw new Error(`Angular JS name needed for ${service}`);
    }

    angular.module('aviApp').service(ajsName, downgradeInjectable(service) as any);
});
