/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import {
    ALBServicesRegistrationStatus,
    AviPermissionResource,
    IALBServicesConfig,
} from 'generated-types';
import { CportalService } from 'ajs/modules/cportal/services';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

export enum IPReputationDBRegistrationStatus {
    UNREGISTERED = 'UNREGISTERED',
    DISABLED = 'DISABLED',
    ENABLED = 'ENABLED',
    UNKNOWN = 'UNKNOWN',
}

@Injectable()
export class IPReputationDBListPageContainerService {
    constructor(
        private readonly cportalService: CportalService,
        private readonly authService: Auth,
        private readonly devLoggerService: DevLoggerService,
    ) {}

    /**
     * Returns the IP Reputation status from pulse settings. If the controller is not registered
     * with pulse, returns UNREGISTERED. If registered, but the feature has not been opted-in, then
     * the status is DISABLED. If registered and opted-in, returns ENABLED.
     */
    public async getRegistrationStatus(): Promise<IPReputationDBRegistrationStatus> {
        const {
            registration_status: registrationStatus,
        } = this.cportalService.getRegistrationStatusDetails();

        if (registrationStatus !== ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED) {
            return IPReputationDBRegistrationStatus.UNREGISTERED;
        }

        let info: IALBServicesConfig;
        const hasAlbServicesConfigPrivilege =
            this.authService.isAllowed(AviPermissionResource.PERMISSION_ALBSERVICESCONFIG);

        if (hasAlbServicesConfigPrivilege) {
            try {
                info = await this.cportalService.getPortalInfo();
            } catch (error) {
                this.devLoggerService.error(error);

                return IPReputationDBRegistrationStatus.UNKNOWN;
            }
        } else {
            return IPReputationDBRegistrationStatus.UNKNOWN;
        }

        const { feature_opt_in_status: optInStatus } = info;

        if (!optInStatus.enable_ip_reputation) {
            return IPReputationDBRegistrationStatus.DISABLED;
        }

        return IPReputationDBRegistrationStatus.ENABLED;
    }
}
