<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="jwt-server-profile-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ editable.messageType }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="issuer"
                        for="issuer"
                        objectType="{{ editable.messageType }}"
                        required
                    >
                        {{ l10nKeys.issuerInputLabel | vtranslate }}
                    </label>
                    <input
                        id="issuer"
                        name="issuer"
                        [(ngModel)]="editable.config.issuer"
                        clrInput
                        placeholder="{{ l10nKeys.issuerInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ editable.messageType }}"
                            fieldName="jwks_keys"
                            for="jwks_keys"
                            required
                        >
                            {{ l10nKeys.jwtFileInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            [(ngModel)]="editable.config.jwks_keys"
                            placeholder="{{ l10nKeys.jwtFileInputPlaceholder | vtranslate }}"
                            name="jwks_keys"
                            required
                        >
                        </file-upload-textarea>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
