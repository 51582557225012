/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Inject, Injectable } from '@angular/core';
import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';
import { IHttpPromise } from 'angular';
import { IPReputationType } from 'generated-types';

type THttpWrapper = typeof HttpWrapper;

const IP_REPUTATION_SEARCH_DATA_URL = 'api/ipreputationdb/';

export interface IIPReputationSearchApiResult {
    count: number;
    results: IPReputationType[];
}

@Injectable()
export class IPReputationSearchRecordCardService {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(@Inject(HttpWrapper) HttpWrapper: THttpWrapper) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Returns the result with matching threat types and count for given IP address.
     */
    public search(searchString: string, dbID: string): IHttpPromise<IIPReputationSearchApiResult> {
        const requestConfig = {
            url: `${IP_REPUTATION_SEARCH_DATA_URL + dbID}/data/?ip_addr=${searchString}`,
            method: HttpMethod.GET,
        };

        return this.httpWrapper.request(requestConfig);
    }
}

