/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'alert-grid-config';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleResourceName = `${prefix}.columnTitleResourceName`;
export const columnTitleLevel = `${prefix}.columnTitleLevel`;
export const columnTitleSummary = `${prefix}.columnTitleSummary`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const actionTitleExpand = `${prefix}.actionTitleExpand`;
export const columnTitleMetric = `${prefix}.columnTitleMetric`;
export const columnTitleValue = `${prefix}.columnTitleValue`;
export const columnTitleReportTime = `${prefix}.columnTitleReportTime`;
export const columnTitleEndTime = `${prefix}.columnTitleEndTime`;
export const columnTitleStep = `${prefix}.columnTitleStep`;
export const editAlertConfigBtnLabel = `${prefix}.editAlertConfigBtnLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const throttleCountInputLabel = `${prefix}.throttleCountInputLabel`;
export const actionScriptOutputInputLabel = `${prefix}.actionScriptOutputInputLabel`;
export const relatedEventInputLabel = `${prefix}.relatedEventInputLabel`;

export const ENGLISH = {
    [columnTitleMetric]: 'Metric',
    [columnTitleValue]: 'Value',
    [columnTitleReportTime]: 'Report Time',
    [columnTitleEndTime]: 'End Time',
    [columnTitleStep]: 'Step',
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleResourceName]: 'Resource Name',
    [columnTitleLevel]: 'Level',
    [columnTitleSummary]: 'Summary',
    [actionTitleDelete]: 'Delete',
    [actionTitleExpand]: 'Expand',
    [editAlertConfigBtnLabel]: 'Edit Alert Config',
    [descriptionInputLabel]: '<span class="key">Description</span>: {0}',
    [throttleCountInputLabel]: 'Throttle Count: {0}',
    [actionScriptOutputInputLabel]: '<strong>Action Script Output:</strong><div>{0}</div>',
    [relatedEventInputLabel]: '{0, plural, one{# Related Event} other{# Related Events}}',
};
