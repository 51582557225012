<div
    provideParentForm
    [invalidateForm]="remoteAuthConfig && !remoteAuthConfig.length"
>
    <avi-data-grid
        [config]="remoteAuthConfigGridConfig"
        [rows]="remoteAuthConfig"
        (onRowOrderChange)="handleRowReorder($event)"
    >   
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addRemoteAuthConfig()"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #authProfileTemplateRef
            let-row
            let-index="index"
        >
            <avi-collection-dropdown
                name="{{ authProfileRowPrefix + index }}"
                id="{{ authProfileRowPrefix + index }}"
                [collection]="authProfileCollection"
                [(ngModel)]="row.auth_profile_ref"
                (ngModelChange)="updateSelectedAuthProfileFilter()"
                placeholder="{{ l10nKeys.authProfileSelectPlaceholder | vtranslate }}"
                required
            ></avi-collection-dropdown>
        </ng-template>

        <ng-template
            #authMappingProfileTemplateRef
            let-row
            let-index="index"
        >
            <avi-collection-dropdown
                name="{{ authMappingProfileRowPrefix + index }}"
                id="{{ authMappingProfileRowPrefix + index }}"
                [collection]="authMappingProfileCollection"
                [(ngModel)]="row.auth_mapping_profile_ref"
                placeholder="{{ l10nKeys.authMappingProfileSelectPlaceholder | vtranslate }}"
                required
            ></avi-collection-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
