<avi-dynamic-data-grid
    *ngIf="vsLogsSignpostStore.vsLogsEndToEndTimingSignpostData$ | async as vsLogsEndToEndTimingSignpostData"
    class="vs-logs-signpost-custom-e2e-timing-data-grid"
    [config]="logListGridConfig"
    [rows]="vsLogsEndToEndTimingSignpostData.e2eResult"
    [rowsTotal]="vsLogsEndToEndTimingSignpostData.e2eResult?.length"
    [isLoading]="vsLogsSignpostStore.isLoading$ | async"
></avi-dynamic-data-grid>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <div
        *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
        class="vs-logs-signpost-custom-e2e-timing-data-grid__content"
    >
        <span
            *ngIf="row.value.isValid"
            vsLogFilterRange
            [property]="row.value.param"
            [start]="row.value.value.start"
            [end]="row.value.value.end"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        >
            {{ row.value.value | signpostLogDataTransform: configKey }}
        </span>
        <span
            *ngIf="!row.value.isValid"
            vsLogFilterableEmptyField
            [property]="row.value.param"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        ></span>
    </div>
</ng-template>

<ng-template
    #progressBarFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-progress-bar
        [percentage]="row.percentage"
        [hidePercentage]="true"
    ></avi-progress-bar>
</ng-template>

<ng-template
    #percentageFieldTemplateRef
    let-row
    let-index="index"
>
    <span> {{ row.percentage + '%' }} </span>
</ng-template>
