/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    Component,
    Type,
} from '@angular/core';

import {
    GslbSiteType,
    IGslbHealthMonitorProxy,
    IGslbSite,
    SiteMemberType,
} from 'generated-types';

import { GslbSite } from 'object-types';
import { StringService } from 'string-service';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

import {
    GslbSiteModalComponent,
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-site-modal.component';

import {
    GslbSitePreviewComponent,
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-site-preview/gslb-site-preview.component';

import { GSLB } from 'ng/modules/items/factories/gslb.item.factory';
import { IFullModalLayout } from 'ng/modules/core/services/full-modal/full-modal.service';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import { GslbGeoLocationConfigItem } from 'message-items/gslb-geo-location.config-item.factory';
import { GslbSiteDnsVsConfigItem } from 'message-items/gslb-site-dns-vs.config-item.factory';

const DNS_VSES = 'dns_vses';

type TGslbSitePartial = Omit<IGslbSite, 'ip_addresses' | 'hm_proxies' | 'location' | 'dns_vses'>;

interface IGslbSiteConfig extends TGslbSitePartial {
    ip_addresses: RepeatedMessageItem<IpAddrConfigItem>;
    hm_proxies: RepeatedMessageItem<MessageItem<IGslbHealthMonitorProxy>>;
    dns_vses: RepeatedMessageItem<GslbSiteDnsVsConfigItem>;
    location: GslbGeoLocationConfigItem;
}

interface IGslbSiteItemParams extends IItemParams {
    gslb: GSLB;
}

const GSLB_SITE_REPLICATION_URL = '/api/gslbsiteops/replicationpull';
const GSLB_SITE_VERIFICATION_URL = '/api/gslbsiteops/verify';

/**
 * @description GslbSite ConfigItem class.
 *
 * @author Hitesh Mandav
 */
export class GslbSiteConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IGslbSiteConfig, typeof MessageItem>(MessageItem),
)<IGslbSiteConfig> {
    public static ngDependencies = [
        'HttpWrapper',
        'devLoggerService',
        StringService,
    ];

    /**
     * Do site verification in case the credentials has been updated.
     */
    public siteVerificationRequired: boolean;

    private readonly httpWrapper: HttpWrapper;

    private readonly devLoggerService: DevLoggerService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbsite',
            objectType: GslbSite,
            windowElement: GslbSiteModalComponent,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getNgDependency('HttpWrapper');

        this.httpWrapper = new HttpWrapper();
        this.devLoggerService = this.getNgDependency('devLoggerService');
        this.siteVerificationRequired = false;
    }

    /**
     * Return true if member is `active`.
     */
    public isActiveMember() : boolean {
        const { member_type: memberType } = this.getConfig();

        return memberType === SiteMemberType.GSLB_ACTIVE_MEMBER;
    }

    /**
     * Return array of IP Address.
     */
    public ipAddress(): string[] {
        const { ip_addresses: ipAddresses } = this.getConfig();

        const ipAddress = ipAddresses.config
            .map(ip => ip.toString());

        return ipAddress;
    }

    /**
     * Return GslbSite enabled/disabled state.
     */
    public isEnabled(): boolean {
        return this.getConfig().enabled;
    }

    /**
     * Initialize the location feild.
     */
    public onConfigureUserLocation(): void {
        this.safeSetNewChildByField('location');
    }

    /**
     * Call checkpoint replication api to bring active site to 'active' point.
     */
    public kickoffReplication(): IPromise<IHttpResponse<void>> {
        const payload = {
            site_id: this.config.cluster_uuid || '',
        };

        return this.httpWrapper.request(
            {
                method: HttpMethod.POST,
                data: payload,
                url: GSLB_SITE_REPLICATION_URL,
            },
        ).catch((errorObject: any) => {
            const { data: error } = errorObject;

            this.devLoggerService.error(error);

            return Promise.reject(error);
        });
    }

    /**
     * Make an API call to verify whether this GslbSite is configured to participate in GSLB
     * configuration.
     */
    public async verifySite(): Promise<void> {
        this.busy = true;
        this.errors = null;

        const payload = {
            username: this.config.username,
            password: this.config.password,
            port: this.config.port,
            ip_addresses: this.config.ip_addresses.flattenConfig(),
        };
        const requestConfig = {
            data: payload,
            method: HttpMethod.POST,
            url: GSLB_SITE_VERIFICATION_URL,
        };

        try {
            const resp = await this.httpWrapper.request(requestConfig);

            this.setConfigAfterVerification(resp.data.rx_uuid);
        } catch (error) {
            this.errors = error.data.error;
            throw error;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Open modal to add a new dns vs.
     */
    public addDnsVs(
        tenantId: string,
        configuredDomains: string[],
        selectedVsId: string[],
    ): void {
        this.addChildMessageItem({
            field: DNS_VSES,
            modalBindings: {
                clusterUuid: this.config.cluster_uuid,
                tenantId,
                configuredDomains,
                selectedVsId,
            },
        });
    }

    /**
     * Open Modal to edit dns vs.
     */
    public editDnsVs(
        dnsVs: GslbSiteDnsVsConfigItem,
        tenantId: string,
        configuredDomains: string[],
        selectedVsId: string[],
    ): void {
        this.editChildMessageItem({
            field: DNS_VSES,
            messageItem: dnsVs,
            modalBindings: {
                clusterUuid: this.config.cluster_uuid,
                tenantId,
                configuredDomains,
                selectedVsId,
            },
        });
    }

    /**
     * Delete selected dns vs.
     */
    public deleteDnsVs(dnsVs: GslbSiteDnsVsConfigItem): void {
        this.config.dns_vses.removeByMessageItem(dnsVs);
    }

    /**
     * Initialize the dns_vses feild.
     */
    public initializeDnsVses(): void {
        this.safeSetNewChildByField('dns_vses');
    }

    /**
     * Delete all dns vs.
     */
    public deleteDnsVses(): void {
        delete this.config.dns_vses;
    }

    /**
     * Remove subdomain from DnsVses of site.
     */
    public removeSubdomainFromSite(subdomain: string): void {
        const { dns_vses: dnsVses } = this.config;

        dnsVses.config.forEach((dnsVs: GslbSiteDnsVsConfigItem) => {
            dnsVs.removeSubdomain(subdomain);
        });
    }

    /**
     * Add subdomain in DnsVses of site.
     */
    public addSubdomainInDnsVses(dnsVsNames: string[], subdomain: string): void {
        const { dns_vses: dnsVses } = this.config;
        const stringService = this.getNgDependency(StringService);

        dnsVses.config.forEach((dnsVs: GslbSiteDnsVsConfigItem) => {
            if (dnsVsNames.includes(stringService.name(dnsVs.config.dnsVsRef))) {
                dnsVs.addSubdomain(subdomain);
            }
        });
    }

    /**
     * Get all DNS VS refs which satisfy below conditions
     * 1. If hostAllSites is true and it does not contain any
     * domain name i.e. domain_names is empty or undefined.
     * 2. If it contain domain names matching with provided name.
     */
    public getDnsVsRefsByDomain(domain: string, hostAllSites = true): string[] {
        const { dns_vses: dnsVses } = this.config;

        return dnsVses.config.filter((dnsVs: GslbSiteDnsVsConfigItem) => {
            if (dnsVs.config.domain_names?.length) {
                return dnsVs.config.domain_names.find(domainName => domainName === domain);
            }

            return hostAllSites;
        }).map(vsDns => vsDns.config.dnsVsRef);
    }

    /**
     * @override
     * Override the default preview component with custom preview component.
     */
    public async getFullModalProps(
        params: IGslbSiteItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);
        const siteId = params.editable.getConfig().cluster_uuid;
        const isLeader = params.gslb.isLeaderSiteId(siteId);

        return {
            ...props,
            previewComponent: GslbSitePreviewComponent as Type<Component>,
            previewComponentProps: {
                config: params.editable.getConfig(),
                type: GslbSiteType.GSLB_AVI_SITE,
                isLeader,
            },
        };
    }

    /**
     * Set the new cluster UUID
     * If the cluster UUID has changed, remove the old dns_vses.
     */
    private setConfigAfterVerification(clusterId: string): void {
        // if cluster_uuid has changed we remove old DNS VSs
        if (this.config.cluster_uuid !== clusterId) {
            this.config.dns_vses.removeAll();
        }

        this.config.cluster_uuid = clusterId;
    }
}
