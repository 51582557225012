<match-display-wrapper 
    matchLabel="{{ label }}"
    matchCriteria="{{ editable.matchCriteriaLabel }}"
>
    <div *ngIf="editable.matchStrings">
        {{editable.matchStrings}}
    </div>
    <div *ngIf="editable.stringGroupNames.length">
        {{ stringGroupNames }}
    </div>
</match-display-wrapper>
