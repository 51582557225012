/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'path-or-query-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const pathLabel = `${prefix}.pathLabel`;
export const stringLabel = `${prefix}.stringLabel`;
export const queryLabel = `${prefix}.queryLabel`;

export const ENGLISH = {
    [pathLabel]: 'Path',
    [stringLabel]: 'String',
    [queryLabel]: 'Query',
};
