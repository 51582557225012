/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IAzureServicePrincipalCredentials,
    IAzureUserPassCredentials,
    IIpamDnsAzureProfile,
} from 'generated-types';

import {
    extend,
    isEmpty,
} from 'underscore';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IpamDnsAzureProfile } from 'object-types';

type TIIpamDnsAzureProfilePartial = Omit<IIpamDnsAzureProfile,
'azure_userpass' | 'azure_serviceprincipal'>;

interface IIpamDnsAzureProfileConfig extends TIIpamDnsAzureProfilePartial {
    azure_userpass?: MessageItem<IAzureUserPassCredentials>;
    azure_serviceprincipal?: MessageItem<IAzureServicePrincipalCredentials>;
}

export enum AzureCredentialsType {
    USERPASS,
    SERVICE_PRINCIPAL,
}

export interface IAzureCredentialsConfig {
    subscription_id?: string;
    azure_serviceprincipal?: IAzureServicePrincipalCredentials;
    azure_userpass?: IAzureUserPassCredentials;
    type: AzureCredentialsType;
}

/**
 * Verify Azure credentials request ID.
 */
const VERIFY_AZURE_LOGIN_CREDENTIALS = 'verify-azure-login';

/**
 * @description IpamDnsAzureProfile ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamDnsAzureProfileConfigItem extends MessageItem<IIpamDnsAzureProfileConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'secretStubStr',
    ];

    private readonly httpWrapper: HttpWrapper;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IpamDnsAzureProfile,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Getter for azure_userpass.
     */
    public get azureUserpass(): MessageItem<IAzureUserPassCredentials> {
        return this.config.azure_userpass;
    }

    /**
     * Getter for azure_serviceprincipal.
     */
    public get azureServicePrincipal(): MessageItem<IAzureServicePrincipalCredentials> {
        return this.config.azure_serviceprincipal;
    }

    /**
     * Called on change when user selects between credentials used for Azure configuration.
     */
    public clearAzureCredentials(): void {
        const { config } = this;

        if (!isEmpty(config)) {
            delete config.azure_userpass;
            delete config.azure_serviceprincipal;
        }
    }

    /**
     * Clear password for the profile.
     */
    public clearProfilePassword(): void {
        const { azure_userpass: azureUserpass } = this.config;

        delete azureUserpass.config.password;
    }

    /**
     * Check azure credentials for validation.
     */
    public verifyAzureCredentials(
        config: IAzureCredentialsConfig,
        ipamProfileId?: string,
    ): ng.IPromise<any> {
        this.busy = true;

        return this.getAzureVirtualNetworks(config, ipamProfileId)
            .then(rsp => rsp)
            .catch(({ data }) => Promise.reject(data.error))
            .finally(() => this.busy = false);
    }

    /**
     * Cancels verify credentials request.
     */
    public cancelAzureVerifyCredentials(): void {
        this.httpWrapper.cancelRequest(VERIFY_AZURE_LOGIN_CREDENTIALS);
    }

    /**
     * Sets azure login credentials.
     */
    public setAzureLoginCredentials(config: IAzureCredentialsConfig): void {
        const {
            azure_userpass: azureUserPass,
            azure_serviceprincipal: azureServicePrincipal,
            subscription_id: subscriptionId,
            type,
        } = config;

        if (type === AzureCredentialsType.USERPASS) {
            this.config.azure_userpass.updateConfig(azureUserPass);

            this.config.azure_serviceprincipal.updateConfig({});
        } else {
            this.config.azure_serviceprincipal.updateConfig(azureServicePrincipal);

            this.config.azure_userpass.updateConfig({});
        }

        this.config.subscription_id = subscriptionId;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'azure_userpass',
            'azure_serviceprincipal',
        ];
    }

    /**
     * Makes request for Virtual Networks.
     */
    private getAzureVirtualNetworks(
        config: IAzureCredentialsConfig,
        ipamProfileId?: string,
    ): ng.IPromise<any> {
        const api = '/api/azure-get-virtual-networks';
        const params = this.getAzureParams(config, ipamProfileId);

        delete params.vnet_id;

        return this.httpWrapper.request({
            method: HttpMethod.POST,
            url: api,
            data: params,
            requestId: VERIFY_AZURE_LOGIN_CREDENTIALS,
        });
    }

    /**
     * Returns an object of params used for Azure requests. If editing, includes the profile
     * ID.
     */
    private getAzureParams(
        config: IAzureCredentialsConfig,
        ipamProfileId?: string,
    ): Record<string, any> {
        const {
            azure_userpass: azureUserPass,
            azure_serviceprincipal: azureServicePrincipal,
            subscription_id: subscriptionId,
            type,
        } = config;

        const secretStubStr = this.getAjsDependency_('secretStubStr');

        const params: Record<string, string | boolean> = {
            subscription_id: subscriptionId,
        };

        if (!isEmpty(this.config.virtual_network_ids)) {
            [params.vnet_id] = this.config.virtual_network_ids;
        }

        if (type === AzureCredentialsType.USERPASS) {
            extend(params, azureUserPass);

            if (params.password === secretStubStr) {
                params.ipamdnsprovider_uuid = ipamProfileId;
                delete params.password;
            }
        } else if (type === AzureCredentialsType.SERVICE_PRINCIPAL) {
            extend(params, azureServicePrincipal);

            if (params.authentication_token === secretStubStr) {
                params.ipamdnsprovider_uuid = ipamProfileId;
                delete params.authentication_token;
            }

            // Workaround for inconsistent API for now.
            params.authentication_key = params.authentication_token;
        }

        return params;
    }
}
