/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 */

import { TenantSystemConfiguration } from 'object-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { TenantSettingsItem } from 'items/tenant-settings.item.factory';

export type TTenantSettingsCollection = typeof TenantSettingsCollection;

/**
 * @description TenantSettings Collection Class.
 * @author Nitesh Kesarkar
 */
export class TenantSettingsCollection extends Collection {
    public static ngDependencies = [TenantSettingsItem];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'tenantsystemconfiguration',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_TENANT_SYSTEM_CONFIGURATION,
            objectType: TenantSystemConfiguration,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(TenantSettingsItem);
    }

    /** @override */
    public isCreatable(): boolean {
        return Boolean(!this.itemList.length);
    }
}
