/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createAction, props } from '@ngrx/store';
import { TVirtualServiceFaults } from './virtual-service.state';

/**
 * Contains the VS uuid and its faults.
 */
interface IVirtualServiceFaultsResponse {
    vsId: string;
    faults: TVirtualServiceFaults;
}

const VIRTUAL_SERVICE_SOURCE = '[Virtual Service]';

export const setFaults = createAction(
    `${VIRTUAL_SERVICE_SOURCE} Set Faults`,
    props<{ payload: IVirtualServiceFaultsResponse }>(),
);

export const dismissFaults = createAction(
    `${VIRTUAL_SERVICE_SOURCE} Dismiss Fault`,
    props<{ payload: string }>(),
);
