/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'smtp-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleSmtp = `${prefix}.sectionTitleSmtp`;
export const sectionTitleSmtps = `${prefix}.sectionTitleSmtps`;
export const emailInformationLabel = `${prefix}.emailInformationLabel`;
export const senderIdLabel = `${prefix}.senderIdLabel`;
export const senderIdPlaceholder = `${prefix}.senderIdPlaceholder`;
export const recipientsIdLabel = `${prefix}.recipientsIdLabel`;
export const recipientsIdPlaceholder = `${prefix}.recipientsIdPlaceholder`;
export const recipientIdsHelperText = `${prefix}.recipientIdsHelperText`;
export const mailDataLabel = `${prefix}.mailDataLabel`;
export const mailDataPlaceholder = `${prefix}.mailDataPlaceholder`;
export const domainNameLabel = `${prefix}.domainNameLabel`;
export const domainNamePlaceholder = `${prefix}.domainNamePlaceholder`;
export const tlsSniServerPlaceholder = `${prefix}.tlsSniServerPlaceholder`;

export const ENGLISH = {
    [sectionTitleSmtp]: 'SMTP',
    [sectionTitleSmtps]: 'SMTPS',
    [emailInformationLabel]: 'Email Information',
    [senderIdLabel]: 'Sender ID',
    [senderIdPlaceholder]: 'Enter Sender ID',
    [recipientsIdLabel]: 'Recipients ID',
    [recipientsIdPlaceholder]: 'Enter Recipients ID',
    [recipientIdsHelperText]: 'Press enter to add multiple recipient IDs',
    [mailDataLabel]: 'Mail Data',
    [mailDataPlaceholder]: 'Enter Mail Data',
    [domainNameLabel]: 'Domain Name',
    [domainNamePlaceholder]: 'Enter Domain Name',
    [tlsSniServerPlaceholder]: 'Enter TLS SNI Server Name',
};
