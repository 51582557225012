/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * @description IP Reputation DB Collection.
 * @author Aravindh Nagarajan, alextsg
 */
export class IPReputationDBCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'ipreputationdb',
            permissionName: 'PERMISSION_IPREPUTATIONDB',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('IPReputationDB');
    }
}

IPReputationDBCollection.ajsDependencies = [
    'IPReputationDB',
];

export type TIpReputationDbCollection = typeof IPReputationDBCollection;
