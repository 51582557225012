/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'message-items';
const componentName = 'vlan-interface-network';
const prefix = `${moduleName}.${componentName}`;

export const vlanInterfaceNetworkBreadcrumbTitle = `${prefix}.vlanInterfaceNetworkBreadcrumbTitle`;

export const ENGLISH = {
    [vlanInterfaceNetworkBreadcrumbTitle]: 'VLAN Interface',
};
