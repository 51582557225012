/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import {
    crsUpdateEventDetailsComponentOptions,
} from './components';

const eventsModule = angular.module('avi/events');

const components = [
    {
        name: 'crsUpdateEventDetails',
        options: crsUpdateEventDetailsComponentOptions,
    },
];

components.forEach(({ name, options }) => eventsModule.component(name, options));
