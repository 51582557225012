<full-modal-config
    modalTitle="{{ l10nKeys.modalTitleEdit | vtranslate }}"
    modalSubTitle="{{ accountData.full_name }}"
    [modified]="form.touched && form.dirty"
    [valid]="form.valid"
    [busy]="busy || (busy$ | async)"
    (onCancel)="dismiss()"
    (onSubmit)="submit()"
    [errors]="errors"
    class="my-account-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}"
            *ngIf="accountData"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="accountData.full_name"
                        (ngModelChange)="handleNameChange()"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                        [disabled]="!accountData.local"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="username"
                    >
                        {{ l10nKeys.userNameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="username"
                        name="username"
                        [(ngModel)]="accountData.username"
                        clrInput
                        placeholder="-"
                        disabled
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="email"
                    >
                        {{ l10nKeys.emailInputLabel | vtranslate }}
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        [(ngModel)]="accountData.email"
                        clrInput
                        placeholder="{{ l10nKeys.emailInputPlaceholder | vtranslate }}"
                        email
                    />
                </avi-input-container>
                <div class="my-account-modal__change-password-header">
                    <h6>
                        {{ l10nKeys.changePasswordHeader | vtranslate }}
                    </h6>
                </div>
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        for="old_password"
                        [required]="!!accountData.password"
                    >
                        {{ l10nKeys.oldPasswordInputLabel | vtranslate }}
                    </label>
                    <input
                        id="old_password"
                        name="old_password"
                        type="password"
                        [(ngModel)]="accountData.old_password"
                        clrInput
                        placeholder="{{ l10nKeys.oldPasswordInputPlaceholder | vtranslate }}"
                        [disabled]="!accountData.local"
                        [required]="!!accountData.password"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            noMarginTop
                            *ngIf="passwordStrengthCheckEnabled"
                            errorText="{{ l10nKeys.newPasswordErrorText | vtranslate : minimumPasswordLength }}"
                        >
                            <label
                                aviLabelWithTooltip
                                for="new_password"
                            >
                                {{ l10nKeys.newPasswordInputLabel | vtranslate }}
                            </label>
                            <input
                                id="new_password_with_validation"
                                name="new_password"
                                type="password"
                                [(ngModel)]="accountData.password"
                                clrInput
                                placeholder="{{ l10nKeys.newPasswordInputPlaceholder | vtranslate }}"
                                [disabled]="!accountData.local"
                                (keyup)="comparePasswords()"
                                [minlength]="minimumPasswordLength"
                                userAccountPasswordStrength
                            />
                        </avi-input-container>
                        <avi-input-container
                            noMarginTop
                            *ngIf="!passwordStrengthCheckEnabled"
                        >
                            <label
                                aviLabelWithTooltip
                                for="new_password"
                            >
                                {{ l10nKeys.newPasswordInputLabel | vtranslate }}
                            </label>
                            <input
                                id="new_password"
                                name="new_password"
                                type="password"
                                [(ngModel)]="accountData.password"
                                clrInput
                                placeholder="{{ l10nKeys.newPasswordInputPlaceholder | vtranslate }}"
                                [disabled]="!accountData.local"
                                (keyup)="comparePasswords()"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop errorText="{{ l10nKeys.passwordMismatchError | vtranslate }}">
                            <label
                                aviLabelWithTooltip
                                for="confirm_password"
                                [required]="!!accountData.password"
                            >
                                {{ l10nKeys.confirmNewPasswordInputLabel | vtranslate }}
                            </label>
                            <input
                                id="confirm_password"
                                name="confirm_password"
                                type="password"
                                [(ngModel)]="accountData.confirm_password"
                                (keyup)="comparePasswords()"
                                clrInput
                                placeholder="{{ l10nKeys.newPasswordInputPlaceholder | vtranslate }}"
                                [disabled]="!accountData.local"
                                [required]="!!accountData.password"
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleDisplay | vtranslate }}"
            *ngIf="uiProperty"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleDisplay | vtranslate }}</h3>
                <avi-radio-container>
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.timeZoneLabel | vtranslate }}
                    </label>
                    <avi-radio
                        name="timeZone"
                        [(ngModel)]="uiProperty.useUTCTime"
                        [value]="true"
                    >
                        {{ l10nKeys.utcTimeLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="timeZone"
                        [(ngModel)]="uiProperty.useUTCTime"
                        [value]="false"
                    >
                        {{ l10nKeys.localTimeLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="language"
                        >
                            {{ l10nKeys.languageLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="language"
                            [options]="languageOptions"
                            [(ngModel)]="uiProperty.language"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.languageLabel | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>

                <!--  TODO AV-171346 Delete if removing old logs and tech-preview  -->
                <!--  Tech Preview Toggle  -->
                <avi-input-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            [(ngModel)]="uiProperty.showNewVsLogsPage"
                            id="show_new_vs_logs_page"
                            name="show_new_vs_logs_page"
                        />
                        <label
                            aviLabelWithTooltip
                            tooltipText="{{ l10nKeys.techPreviewHoverInfo | vtranslate }}"
                            for="show_new_vs_logs_page"
                        >
                            {{ l10nKeys.techPreviewLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </avi-input-container>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            *permissionCheck="'PERMISSION_CONTROLLER:WRITE_ACCESS'"
            tabTitle="{{ l10nKeys.sectionTitleControllerSettings | vtranslate }}"
        >
            <div
                class="full-modal-config__section"
                *ngIf="hasControllerProperties"
            >
                <h3>{{ l10nKeys.sectionTitleControllerSettings | vtranslate }}</h3>
                <avi-input-container helperText="{{ l10nKeys.minutesHelperText | vtranslate }}">
                    <label
                        aviLabelWithTooltip
                        objectType="ControllerProperties"
                        fieldName="api_idle_timeout"
                        for="api_idle_timeout"
                        required
                    >
                        {{ l10nKeys.sessionTimeoutLabel | vtranslate }}
                    </label>
                    <input
                        id="api_idle_timeout"
                        name="api_idle_timeout"
                        type="number"
                        [(ngModel)]="controllerProperties.api_idle_timeout"
                        configFieldInputValidation
                        objectType="ControllerProperties"
                        fieldName="api_idle_timeout"
                        clrInput
                        placeholder="{{ l10nKeys.sessionTimeoutInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>

                <avi-input-container  helperText="{{ l10nKeys.minutesHelperText | vtranslate }}">
                    <label
                        aviLabelWithTooltip
                        objectType="ControllerProperties"
                        fieldName="dns_refresh_period"
                        for="dns_refresh_period"
                        required
                    >
                        {{ l10nKeys.dnsRefreshPeriodInputLabel | vtranslate }}
                    </label>
                    <input
                        id="dns_refresh_period"
                        name="dns_refresh_period"
                        type="number"
                        [(ngModel)]="controllerProperties.dns_refresh_period"
                        configFieldInputValidation
                        objectType="ControllerProperties"
                        fieldName="dns_refresh_period"
                        clrInput
                        placeholder="{{ l10nKeys.dnsRefreshPeriodInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
