/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Aravindh Nagarajan
 */

/**
 * Ajs Dependency token for IcapProfile item.
 */
export const ICAP_PROFILE_ITEM_TOKEN = 'IcapProfileItem';

/**
 * Ajs Dependency token for IcapProfile collection.
 */
export const ICAP_PROFILE_COLLECTION_TOKEN = 'IcapProfileCollection';

/**
 * Ajs dependency token for IcapNsxDefenderConfigConfigItem.
 */
export const ICAP_NSX_DEFENDER_CONFIG_CONFIG_ITEM_TOKEN = 'IcapNsxDefenderConfigConfigItem';
