/**
 * @module Angular-Core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ComponentRef,
    EventEmitter,
} from '@angular/core';
import { each } from 'underscore';

/**
 * Attaches bindings to a componentRef instance. Used for rendering dynamic components.
 */
export function attachComponentBindings(
    componentRef: ComponentRef<Component>,
    componentBindings: {} = {},
): void {
    each(componentBindings, (prop, key): void => {
        if (componentRef.instance[key] instanceof EventEmitter) {
            componentRef.instance[key].subscribe(($event: any) => {
                if (typeof prop === 'function') {
                    prop($event);
                }
            });
        } else {
            componentRef.instance[key] = prop;
        }
    });
}
