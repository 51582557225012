/**
 * @module CloudSharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import {
    DnsResolverConfigItem,
} from 'ajs/modules/cloud/factories/dns-resolver.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './dns-resolver-modal.l10n';

const { ...globalL10nKeys } = globalL10n;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Modal component to configure DNS Resolvers on cloud object.
 *
 * @author Satish Pednekar
 */
@Component({
    selector: 'dns-resolver-modal',
    templateUrl: './dns-resolver-modal.component.html',
})
export class DnsResolverModalComponent implements OnInit {
    /**
     * DnsResolverConfigItem messageItem instance.
     */
    @Input()
    public editable: DnsResolverConfigItem;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * DnsResolver object type.
     */
    public objectType: string;

    /**
     * Layout for DNS Resolver modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
