/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine-group';
const componentName = 'se-group-list-page';
const prefix = `${moduleName}.${componentName}`;

export const cloudContextLabel = `${prefix}.cloudContextLabel`;
export const columnTitleServiceEnginesCount = `${prefix}.columnTitleServiceEnginesCount`;
export const columnTitleMaxNoOfServiceEngines = `${prefix}.columnTitleMaxNoOfServiceEngines`;
export const columnTitleVirtualServicesCount = `${prefix}.columnTitleVirtualServicesCount`;
export const columnTitleMaxNoOfVsPerSe = `${prefix}.columnTitleMaxNoOfVsPerSe`;
export const columnTitleHaMode = `${prefix}.columnTitleHaMode`;
export const activeStandbyValue = `${prefix}.activeStandbyValue`;
export const activeValue = `${prefix}.activeValue`;
export const nmBufferValue = `${prefix}.nmBufferValue`;

export const ENGLISH = {
    [cloudContextLabel]: 'Cloud Context',
    [columnTitleServiceEnginesCount]: '# Service Engines',
    [columnTitleMaxNoOfServiceEngines]: 'Maximum Number of Service Engines',
    [columnTitleVirtualServicesCount]: '# Virtual Services',
    [columnTitleMaxNoOfVsPerSe]: 'Maximum Number of Virtual Services per Service Engine',
    [columnTitleHaMode]: 'HA Mode',
    [activeStandbyValue]: 'Active/Standby',
    [activeValue]: 'Active/Active',
    [nmBufferValue]: 'N + M (buffer)',
};
