/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-ip-address-grid.l10n';
import './gslb-ip-address-grid.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for IP addresses in gslb Site modal.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-ip-address-grid',
    templateUrl: './gslb-ip-address-grid.component.html',
})
export class GslbIpAddressGridComponent implements AfterViewInit {
    /**
     * List of IP Addresses in gslb Site.
     */
    @Input()
    public ipAddr: RepeatedMessageItem<IpAddrConfigItem>;

    /**
     * TemplateRef for ip address input element.
     */
    @ViewChild('ipAddrTemplateRef')
    public ipAddrTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Grid config for IP Address.
     */
    public ipAddrGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.ipAddrGridConfig = {
            fields: [{
                label: l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                id: 'ip-addr',
                templateRef: this.ipAddrTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (ipList: IpAddrConfigItem[]) => {
                    ipList.forEach(ip => this.deleteIpAddr(ip));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (ipAddr: IpAddrConfigItem) => {
                    this.deleteIpAddr(ipAddr);
                },
            }],
        };
    }

    /**
     * Add Server IP Address.
     */
    public addIpAddress(): void {
        this.ipAddr.add();
    }

    /**
     * Delete Server IP Address.
     */
    private deleteIpAddr(ipAddr: IpAddrConfigItem): void {
        this.ipAddr.removeByMessageItem(ipAddr);
    }
}
