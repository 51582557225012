/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cluster-polling-progress-bar';
const componentName = 'cluster-polling-progress-bar';
const prefix = `${moduleName}.${componentName}`;

export const errorMsg503Description = `${prefix}.errorMsg503Description`;

export const ENGLISH = {
    [errorMsg503Description]: 'The service is temporarily unavailable while Controller Initializes. This may take several minutes. ({0}%)',
};
