/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import classnames from 'classnames';
import './avi-form-label.less';

/**
 * @ngdoc directive
 * @name aviFormLabel
 * @restrict A
 * @desc
 *
 *     Places an (i) icon inside the element.
 *
 *     On hover event this icon displays a {@link aviFormLabelTooltip tooltip}.
 *
 *     Mostly used on the form <label> elements.
 *
 * @param {string|Object=} aviFormLabel - Tooltip string or reference to object field
 *     definition object (deprecated).
 * @param {string=} objectType - Object type this field belongs to
 * @param {string=} fieldName - Field name
 * @param {string=} iconPosition - Modification of the default (will become a last child) icon
 *     placement. Values: "before-first-child".
 * @param {string=} hideRange - Any string will be evaluated to true.
 * @param {string} [iconClassName=] - Class name to be added to the help icon.
 */
//FIXME rename and convert to component
angular.module('aviApp').directive('aviFormLabel', function() {
    const componentName = 'avi-form-label';
    const helpIconClassName = `${componentName}__help-icon`;

    /**
     * Local variable keeping FormLabelTooltip to avoid unnecessary instantiations.
     * @type {null|FormLabelTooltip}
     * @inner
     */
    let FormLabelTooltip = null;

    /**
     * Returns class derived of popoverFactory.
     * @param {popoverFactory} PopoverFactory
     * @return {FormLabelTooltip}
     * @inner
     */
    function getFormLabelTooltipClass(PopoverFactory) {
        if (FormLabelTooltip) {
            return FormLabelTooltip;
        }

        FormLabelTooltip = class FormLabelTooltip extends PopoverFactory {
            constructor(config = {}) {
                super(config);
                this.$icon = config.$icon;
            }

            /** @override */
            show(...args) {
                super.show(...args);
                // so that icon remains blue even when user is hovering over the tooltip
                this.$icon.addClass(`${helpIconClassName}--active`);
            }

            /** override */
            hide(...args) {
                super.hide(...args);
                this.$icon.removeClass(`${helpIconClassName}--active`);
            }
        };

        return FormLabelTooltip;
    }

    /** @alias aviFormLabel */
    class FormLabelController {
        constructor(
            $scope,
            $element,
            $compile,
            schemaService,
            PopoverFactory,
        ) {
            this.$scope = $scope;
            this.$element = $element;
            this.$compile = $compile;
            this.schemaService = schemaService;
            this.FormLabelTooltip = getFormLabelTooltipClass(PopoverFactory);
        }

        $onInit() {
            /**
             * Arbitrary text passed though the binding or object field description
             * from {@link schemaService}.
             * @type {string}
             * @see schemaService.getFieldDescription
             */
            this.description = '';

            /**
             * Field range as a string. For ex: "1 - 100"
             * @type {string}
             * @see schemaService.getFieldRangeAsText
             */
            this.range = '';

            /**
             * Field special values as a string. For ex: "0: Automatic, -1: Unlimited"
             * @type {string}
             * @see schemaService.getFieldSpecialValuesAsText
             */
            this.specialValues = '';

            /** @type {null|jQuery} */
            this.$icon = null;

            /**
             * Instance of {@link PopoverFactory}
             * @type {null|Object}
             **/
            this.tooltip = null;

            this.setTooltipValues_();

            // nothing to render in the tooltip - no point in placing the icon
            if (!this.description) {
                return;
            }

            const helpIconClassNames = classnames(helpIconClassName, this.iconClassName);

            this.componentTemplate_ = `<i class="${helpIconClassNames} icon-help-circled"/>`;

            this.appendIcon_();
        }

        /**
         * Figures out "description", "range" and "special values" based on value passed
         * through bindings.
         * @protected
         */
        setTooltipValues_() {
            const { textOrFieldData } = this;

            if (angular.isString(textOrFieldData)) {
                this.description = textOrFieldData;

                return;
            }

            const {
                schemaService,
                objectType,
                fieldName,
                hideRange,
            } = this;

            const args = [];

            if (objectType && fieldName) {
                args.push(objectType, fieldName);
            } else if (schemaService.isFieldDefinitionObject(textOrFieldData)) {
                args.push(textOrFieldData);
            } else { // all undefined or wrong object passed
                return;
            }

            this.description = schemaService.getFieldDescription(...args);

            if (!hideRange) {
                this.range = schemaService.getFieldRangeAsText(...args);
                this.specialValues = schemaService.getFieldSpecialValuesAsText(...args);
            }
        }

        /**
         * Appends icon into the list of child nodes. Takes iconPosition into account.
         * @protected
         */
        appendIcon_() {
            const $icon = $(this.componentTemplate_);

            $icon.on('mouseover', this.mouseOverEventHandler_.bind(this));

            this.$icon = $icon;

            const {
                iconPosition,
                $element,
            } = this;

            if (iconPosition) {
                const elmChildren = $element.children();

                if (iconPosition === 'before-first-child' && elmChildren.length) {
                    $icon.insertBefore(elmChildren[0]);
                } else {
                    $icon.appendTo($element);
                }
            } else {
                $icon.appendTo($element);
            }
        }

        /**
         * Returns tooltip template with attribute values (bindings) set.
         * Can't use angular bindings here since directive is using parent $scope.
         * @return {string}
         * @protected
         */
        getTooltipTemplate_() {
            return `<avi-form-label-tooltip
                description="${_.escape(this.description)}"
                range="${_.escape(this.range)}"
                special-values="${_.escape(this.specialValues)}"/>`;
        }

        /**
         * Creates tooltip instance.
         * @protected
         */
        tooltipInit_() {
            const {
                $icon,
                $scope,
                $compile,
            } = this;

            const tooltipHtml = $compile(this.getTooltipTemplate_())($scope);

            this.tooltip = new this.FormLabelTooltip({
                position: 'top',
                className: `${componentName}__tooltip`,
                repositioning: true,
                removeAfterHide: true,
                carat: true,
                html: tooltipHtml,
                hide: {
                    mouseOut: true,
                    onWinResize: true,
                    parentMouseOut: true,
                },
                $icon,
            });
        }

        /**
         * Initializes and shows the tooltip.
         * @protected
         */
        mouseOverEventHandler_() {
            if (!this.tooltip) {
                this.tooltipInit_();
            }

            this.tooltip.show(this.$icon);
        }

        /** @override */
        $onDestroy() {
            if (this.tooltip) {
                this.tooltip.remove();
            }
        }
    }

    FormLabelController.$inject = [
        '$scope', // parent scope, don't mutate
        '$element',
        '$compile',
        'schemaService',
        'popoverFactory',
    ];

    return {
        bindToController: {
            fieldName: '@?',
            hideRange: '@?',
            iconPosition: '@?',
            textOrFieldData: '<?aviFormLabel',
            objectType: '@?',
            iconClassName: '@?',
        },
        restrict: 'A',
        //FIXME the way it is currently used we can not create any scope for this component
        controller: FormLabelController,
    };
});
