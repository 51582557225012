<ng-container *ngIf="(icapLog$ | async) as icapLog">
    <vs-log-cinematic-sub-section-wrapper
        [header]="l10nKeys.icapLabel | vtranslate: (icapLog.action ? (l10nKeys.oneActionLabel | vtranslate) : (l10nKeys.actionsLabel | vtranslate:0)):(totalNumberOfIcapViolations === 1 ? (l10nKeys.oneViolationLabel | vtranslate) : (l10nKeys.violationsLabel | vtranslate:totalNumberOfIcapViolations))"
    >
        <vs-log-cinematic-sub-section-icap></vs-log-cinematic-sub-section-icap>
    </vs-log-cinematic-sub-section-wrapper>
</ng-container>

<vs-log-cinematic-sub-section-wrapper
    [header]="l10nKeys.wafLabel | vtranslate"
    *ngIf="wafLog$ | async"
>
    <vs-log-cinematic-section-security-waf></vs-log-cinematic-section-security-waf>
</vs-log-cinematic-sub-section-wrapper>

<vs-log-cinematic-sub-section-wrapper
    [header]="globalL10nKeys.botLabel | vtranslate"
    *ngIf="botManagementLog$ | async"
>
    <vs-log-cinematic-section-security-bot></vs-log-cinematic-section-security-bot>
</vs-log-cinematic-sub-section-wrapper>
