/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { VsLogsSearchBarStore } from '../vs-logs-search-bar.store';
import './vs-logs-editable-filter.component.less';

/**
 * @description
 *      Component for an editable filter. Filters are represented as clarity tags.
 *      Clicking on the filter text opens it up for editing.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-editable-filter',
    templateUrl: './vs-logs-editable-filter.component.html',
})
export class VsLogsEditableFilterComponent {
    /**
     * Filter value.
     */
    @Input()
    public filter: string;

    /**
     * Event emitter for when the filter is opened for editing.
     */
    @Output()
    public onFilterOpen = new EventEmitter<void>();

    /**
     * Event emitter for filter updates.
     */
    @Output()
    public onFilterUpdate = new EventEmitter<void>();

    /**
     * Event emitter for removing a filter.
     */
    @Output()
    public onFilterRemove = new EventEmitter<void>();

    /**
     * Flag to track whether filter is being edited or not.
     */
    public isEditing = false;

    constructor(
        private readonly vsLogsSearchBarStore: VsLogsSearchBarStore,
    ) {}

    /**
     * Turn the filter into an input for editing and set the value in the store.
     * This sets the value in the input field that is created for editing.
     */
    public openFilter(): void {
        this.isEditing = true;
        this.vsLogsSearchBarStore.setInputFilterValue(this.filter);
        this.onFilterOpen.emit();
    }

    /**
     * Update and emit the new filter value.
     */
    public updateFilter(): void {
        this.onFilterUpdate.emit();
        this.isEditing = false;
    }
}
