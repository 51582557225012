/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/slider.less';

angular.module('aviApp').directive('slider', ['$timeout', '$document', '$window',
    function($timeout, $document, $window) {
        return {
            scope: {
                ngModel: '=',
                ngChange: '&',
                ngDisabled: '=',
                ngRequired: '=',
                min: '=',
                max: '=',
                step: '=',
                labels: '=',
            },
            restrict: 'E',
            templateUrl: 'src/views/components/slider.html',
            require: 'ngModel',
            transclude: true,
            link(scope, elm) {
                scope.minVal = function() {
                    return scope.min !== undefined ? scope.min * 1 : 0;
                };

                scope.maxVal = function() {
                    return scope.max !== undefined ? scope.max * 1 : 100;
                };

                scope.stepVal = function() {
                    return scope.step !== undefined ? scope.step * 1 : 1;
                };

                const value2percentage = function(value) {
                // Calculate percentage
                    const percentage = (value - scope.minVal()) /
                    ((scope.maxVal() - scope.minVal()) / 100);

                    // Make sure percentage is not out of range
                    if (percentage < 0) {
                        return 0;
                    } else if (percentage > 100) {
                        return 100;
                    } else {
                        return percentage;
                    }
                };

                const percentage2position = function(percentage) {
                    const totalPX = elm.width();

                    return totalPX * percentage / 100;
                };

                const position2percentage = function(position) {
                    const totalPX = elm.width();
                    // Calculate percentage
                    const percentage = position / (totalPX / 100);

                    if (percentage < 0) {
                        return 0;
                    } else if (percentage > 100) {
                        return 100;
                    } else {
                        return percentage;
                    }
                };

                const percentage2value = function(percentage) {
                // Calculate value
                    let newValue = scope.minVal() + percentage *
                    (scope.maxVal() - scope.minVal()) / 100;

                    newValue = Math.round(newValue / scope.stepVal()) * scope.stepVal();

                    // Make sure new value is not out of range
                    if (newValue > scope.maxVal()) {
                        return scope.maxVal();
                    } else if (newValue < scope.minVal()) {
                        return scope.minVal();
                    } else {
                        return newValue;
                    }
                };

                const renderLabels = function() {
                    const points = elm.find('.points');

                    points.empty();
                    _.each(scope.labels, function(label, value) {
                        $(`${'<div class="point">' +
                            '<div class="pointer"></div>' +
                            '<label>'}${label}</label>` +
                        '</div>')
                            .css({ left: percentage2position(value2percentage(value)) })
                            .appendTo(points);
                    });
                };

                scope.$watch('labels', function() {
                    renderLabels();
                });
                $($window).on('resize', function() {
                    renderLabels();
                });

                /**
                 * Set up events
                 */
                // Watch for mouse position
                let mouseX,
                    mouseStartX,
                    drag,
                    dist,
                    initialPosition;

                $($document)
                    .on('mousemove', function(event) {
                        mouseX = event.pageX;

                        if (drag) {
                            dist = mouseX - mouseStartX;
                            scope.percentage = position2percentage(initialPosition + dist);
                            scope.ngModel = percentage2value(scope.percentage);
                            scope.$apply();
                            $timeout(function() {
                                scope.ngChange();
                            });
                        }
                    })
                    .on('mouseup', function() {
                        if (drag) {
                            drag = false;
                            $($document[0].body).removeClass('unselectable');
                        }
                    });
                // Start dragging on mousedown
                elm.find('.trigger')
                    .on('mousedown', function(event) {
                    // offsetX is missing in firefox, need to calculate it
                        if (!event.offsetX) {
                            event.offsetX = event.pageX - $(event.target).offset().left;
                        }

                        initialPosition = event.offsetX;
                        scope.percentage = position2percentage(event.offsetX);
                        scope.ngModel = percentage2value(scope.percentage);
                        mouseStartX = mouseX;
                        drag = true;
                        $($document[0].body).addClass('unselectable');
                        scope.$apply();
                        $timeout(function() {
                            scope.ngChange();
                        });
                    })
                    .on('mouseup', function() {
                        scope.percentage = value2percentage(scope.ngModel);
                        scope.$apply();
                    })
                    .on('mouseout', function() {
                        scope.percentage = value2percentage(scope.ngModel);
                        scope.$apply();
                    });
                // If ngModel changed outsize then need to update the slider position
                scope.$watch('ngModel', function() {
                    if (!drag) {
                        scope.percentage = value2percentage(scope.ngModel);
                    }
                });
                // This is the workaround for IE, setting manually the width for the slider element
                scope.$watch('percentage', function() {
                    if (!_.isNaN(+scope.percentage)) {
                        elm.find('.position').css('width', `${scope.percentage.toPrecision(4)}%`);
                    }
                });
            },
        };
    }]);
