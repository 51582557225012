/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'message-items';
const componentName = 'vnic';
const prefix = `${moduleName}.${componentName}`;

export const dataNetworksModalBreadcrumbTitle = `${prefix}.dataNetworksModalBreadcrumbTitle`;
export const managementNetworksModalBreadcrumbTitle = `${prefix}.managementNetworksModalBreadcrumbTitle`;

export const ENGLISH = {
    [dataNetworksModalBreadcrumbTitle]: 'Data Network',
    [managementNetworksModalBreadcrumbTitle]: 'Management Network',
};
