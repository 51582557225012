/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'license-usage-card';
const prefix = `${moduleName}.${componentName}`;

export const serviceUnitsUsageHeader = `${prefix}.serviceUnitsUsageHeader`;
export const usedServiceUnitsLabel = `${prefix}.usedServiceUnitsLabel`;
export const unlicensedLabel = `${prefix}.unlicensedLabel`;

export const ENGLISH = {
    [serviceUnitsUsageHeader]: 'Service Units Usage',
    [usedServiceUnitsLabel]: 'Used Service Units',
    [unlicensedLabel]: 'Unlicensed',
};
