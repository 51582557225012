/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { CinematicService } from 'ng/modules/cinematic/services/cinematic.service';
import { VsLogCinematicComponent }
    from '../components/vs-log-cinematic/vs-log-cinematic.component';
import { TLogEntrySignatureParams } from '../vs-logs.types';

/**
 * ID for Log Recommendation Dialog.
 */
const VS_LOG_CINEMATIC_ID = 'vs-log-cinematic';

/**
 * @description
 *     Service handling cinematic log functionality for the single entry log cinematic view.
 * @author Zhiqian Liu
 */
@Injectable()
export class VsLogCinematicService {
    constructor(
        private readonly cinematicService: CinematicService,
    ) {}

    /**
     * Open a log cinematic.
     */
    public openCinematic(logEntryParams: TLogEntrySignatureParams): void {
        const { logId, seId } = logEntryParams;

        this.cinematicService.add({
            id: VS_LOG_CINEMATIC_ID,
            component: VsLogCinematicComponent as Type<Component>,
            componentProps: {
                logId,
                seId,
                onClose: () => this.closeCinematic(),
            },
        });
    }

    /**
     * Close the log cinematic view.
     */
    public closeCinematic(): void {
        this.cinematicService.remove(VS_LOG_CINEMATIC_ID);
    }

    /**
     * Check if the log cinematic view is open.
     */
    public isOpen(): boolean {
        return this.cinematicService.has(VS_LOG_CINEMATIC_ID);
    }
}
