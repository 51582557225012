/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function licenseUsageFactory(UpdatableBase) {
    /**
     * @constructor
     * @memberOf module:avi/licensing
     * @extends UpdatableBase
     * @author Akul Aggarwal
     * @description License usage information for summary table.
     */
    class LicenseUsage extends UpdatableBase {
        constructor(args = {}) {
            const allDataSources = {
                list: {
                    source: 'LicenseUsageDataSource',
                    transformer: 'LicenseUsageDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config'],
                },
            };

            angular.extend(args, {
                defaultDataSources: 'list',
                isStatic: true,
                allDataSources,
            });

            super(args);

            this.objectName_ = 'licenseusage';
        }
    }

    return LicenseUsage;
}

licenseUsageFactory.$inject = ['UpdatableBase'];

angular.module('avi/licensing').factory('LicenseUsage', licenseUsageFactory);
