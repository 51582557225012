/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './log-connection-grid-rows.l10n';
import {
    IL4ConnectionInfo,
    L4ConnectionInfoType,
} from '../../components/vs-log-cinematic/vs-log-cinematic.types';
import { L4ConnectionType } from '../../components/vs-log-cinematic/vs-log-cinematic.component';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Grid rows for packets, transfers and connection issues grids.
 */
interface INetworkDataLogRows {
    displayValue: string | number
    label: string,
    property: string,
    value: number,
}

/**
 * @description
 *     Pipe for transforming Host connections of VS logs to list of rows.
 * @author Kondiparthi Shanmukha Sarath
 */
@Pipe({
    name: 'logHostConnectionGridRows',
})
export class LogConnectionGridRowsPipe implements PipeTransform {
    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    // eslint-disable-next-line class-methods-use-this
    public transform(
        connectionInfoObject: IL4ConnectionInfo,
        connectionInfoType: L4ConnectionInfoType,
        l4ConnectionType: L4ConnectionType,
    ): INetworkDataLogRows[] {
        const propertyPrefix = l4ConnectionType === L4ConnectionType.SERVER ? 'server_' : '';

        switch (connectionInfoType) {
            case L4ConnectionInfoType.PACKETS: {
                const {
                    transmittedPackets = 0,
                    recievedPackets = 0,
                    totalPackets = 0,
                } = connectionInfoObject;

                return [
                    {
                        displayValue: transmittedPackets,
                        label: this.l10nService.getMessage(l10nKeys.transmittedLabel),
                        property: `${propertyPrefix}tx_pkts`,
                        value: transmittedPackets,
                    },
                    {
                        displayValue: recievedPackets,
                        label: this.l10nService.getMessage(l10nKeys.recievedLabel),
                        property: `${propertyPrefix}rx_pkts`,
                        value: recievedPackets,
                    },
                    {
                        displayValue: totalPackets,
                        label: this.l10nService.getMessage(globalL10nKeys.totalLabel),
                        property: `${propertyPrefix}total_pkts`,
                        value: totalPackets,
                    },
                ];
            }

            case L4ConnectionInfoType.TRANSFERS: {
                const {
                    transmittedBytes = 0,
                    recievedBytes = 0,
                    totalBytes = 0,
                } = connectionInfoObject;

                const bytesLabel = this.l10nService.getMessage(globalL10nKeys.bytesLabel);

                return [
                    {
                        displayValue: `${transmittedBytes} ${bytesLabel}`,
                        label: this.l10nService.getMessage(l10nKeys.transmittedLabel),
                        property: `${propertyPrefix}tx_bytes`,
                        value: transmittedBytes,
                    },
                    {
                        displayValue: `${recievedBytes} ${bytesLabel}`,
                        label: this.l10nService.getMessage(l10nKeys.recievedLabel),
                        property: `${propertyPrefix}rx_bytes`,
                        value: recievedBytes,
                    },
                    {
                        displayValue: `${totalBytes} ${bytesLabel}`,
                        label: this.l10nService.getMessage(globalL10nKeys.totalLabel),
                        property: `${propertyPrefix}total_bytes`,
                        value: totalBytes,
                    },
                ];
            }

            case L4ConnectionInfoType.CONNECTION_ISSUES: {
                const {
                    outOfOrders = 0,
                    timeouts = 0,
                    retransmits = 0,
                } = connectionInfoObject;

                return [
                    {
                        displayValue: outOfOrders,
                        label: this.l10nService.getMessage(l10nKeys.outOfOrdersLabel),
                        property: `${propertyPrefix}out_of_orders`,
                        value: outOfOrders,
                    },
                    {
                        displayValue: timeouts,
                        label: this.l10nService.getMessage(l10nKeys.timeoutsLabel),
                        property: `${propertyPrefix}timeouts`,
                        value: timeouts,
                    },
                    {
                        displayValue: retransmits,
                        label: this.l10nService.getMessage(l10nKeys.retransmitsLabel),
                        property: `${propertyPrefix}retransmits`,
                        value: retransmits,
                    },
                ];
            }

            default: {
                return [];
            }
        }
    }
}
