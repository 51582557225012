/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'remote-backup-card';
const prefix = `${moduleName}.${componentName}`;

export const remoteBackupLabel = `${prefix}.remoteBackupLabel`;
export const enabledDataLabel = `${prefix}.enabledDataLabel`;
export const deactivatedDataLabel = `${prefix}.deactivatedDataLabel`;
export const serverLabel = `${prefix}.serverLabel`;
export const userCredentialsLabel = `${prefix}.userCredentialsLabel`;
export const directoryLabel = `${prefix}.directoryLabel`;

export const ENGLISH = {
    [remoteBackupLabel]: 'Remote Backup',
    [enabledDataLabel]: 'Enabled',
    [deactivatedDataLabel]: 'Deactivated',
    [serverLabel]: 'Server',
    [userCredentialsLabel]: 'User Credentials',
    [directoryLabel]: 'Directory',
};
