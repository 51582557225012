/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './path-match-config.component.less';
import * as l10n from './path-match-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:match/pathMatchConfig
 * @private
 */
class PathMatchConfigComponent {
    constructor(
        dropDownUtils,
        l10nService,
    ) {
        this.criteria = dropDownUtils.getEnumDropdownOptions('StringOperation');

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

PathMatchConfigComponent.$inject = [
    'dropDownUtils',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  pathMatchConfig
 * @module match/pathMatchConfig
 * @description Component for configuring a PathMatch Config Item.
 * @param {PathMatchConfigItem} pathMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @param {boolean=} disallowStringGroups - True if string groups should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('pathMatchConfig', {
    bindings: {
        pathMatch: '<',
        preventEdit: '<',
        disallowStringGroups: '<?',
    },
    controller: PathMatchConfigComponent,
    templateUrl: 'src/components/forms/match-config/matches/path-match-config/' +
        'path-match-config.component.html',
});
