/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'vrf-context';

export const vrfContextModalBreadcrumbTitle = `${prefix}.vrfContextModalBreadcrumbTitle`;
export const routingOptionsLabel = `${prefix}.routingOptionsLabel`;
export const bgpPeerLabel = `${prefix}.bgpPeerLabel`;

export const ENGLISH = {
    [vrfContextModalBreadcrumbTitle]: 'Vrf Context',
    [routingOptionsLabel]: 'Routing Options',
    [bgpPeerLabel]: 'Peer ({0})',
};
