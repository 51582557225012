/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Sarthak Kapoor
 */

/**
 * Dependency token for ConfiguredNetwork Item.
 */
export const CONFIGURED_NETWORK_ITEM_TOKEN = 'ConfiguredNetwork';

/**
 * Dependency token for ConfiguredNetwork Collection.
 */
export const CONFIGURED_NETWORK_COLLECTION_TOKEN = 'ConfiguredNetworkCollection';

export const SUBNET_ITEM_TOKEN = 'SubnetConfigItem';
