/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-server-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const requestsPerSecondFullTitle = `${prefix}.requestsPerSecondFullTitle`;
export const columnTitleServerName = `${prefix}.columnTitleServerName`;
export const columnTitleRatio = `${prefix}.columnTitleRatio`;
export const actionTitleEnable = `${prefix}.actionTitleEnable`;
export const actionTitleDisable = `${prefix}.actionTitleDisable`;
export const gridDefaultPortLabel = `${prefix}.gridDefaultPortLabel`;
export const blastPortLabel = `${prefix}.blastPortLabel`;
export const pcoipPortLabel = `${prefix}.pcoipPortLabel`;
export const tunnelPortLabel = `${prefix}.tunnelPortLabel`;

export const ENGLISH = {
    [requestsPerSecondFullTitle]: 'Requests per second',
    [columnTitleServerName]: 'Server Name',
    [columnTitleRatio]: 'Ratio',
    [actionTitleEnable]: 'Enable',
    [actionTitleDisable]: 'Disable',
    [gridDefaultPortLabel]: 'Default Port',
    [blastPortLabel]: 'Blast',
    [pcoipPortLabel]: 'PCoIP',
    [tunnelPortLabel]: 'Tunnel',
};
