/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import './button-loader.component.less';

/**
 * Component to show spinner inside button.
 * @author Aravindh Nagarajan
 */
@Component({
    selector: '[button-loader]',
    templateUrl: './button-loader.component.html',
})
export class ButtonLoaderComponent {
    /**
     * To control the spinner.
     */
    @Input() public isLoading: boolean;
}
