/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-health-monitor-proxy-grid';
const prefix = `${moduleName}.${componentName}`;

export const healthMonitorProxyLabel = `${prefix}.healthMonitorProxyLabel`;
export const selectControllerPlaceholder = `${prefix}.selectControllerPlaceholder`;

export const ENGLISH = {
    [healthMonitorProxyLabel]: 'Health Monitor Proxy ({0})',
    [selectControllerPlaceholder]: 'Select Controller',
};
