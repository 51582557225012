<full-modal-config
    class="license-tier-switch-modal"
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    [modified]="isFormModified"
    (onCancel)="dismiss()"
    (onSubmit)="handleSubmit()"
    submitButtonText="{{ globalL10nKeys.saveLabel | vtranslate }}"
    [busy]="isBusy()"
    [errors]="editable.errors || error"
    [valid]="form.valid && isFormModified"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <div
                class="license-tier-switch-modal__select-area"
                *ngIf="portalInfo"
            >
            <div *ngFor="let tierType of licensetTierTypeList; trackBy: trackByTierType">
                <license-tier-select-card
                    [tierType]="tierType"
                    [isSelected]="isSelected(tierType)"
                    [tierUsageFraction]="getTierUsageFraction(tierType)"
                    [disabled]="!isTierAvailable(tierType)"
                    (onSelect)="selectTier($event)"
                >
                    <div
                        *ngIf="showTierSwitchAlert(tierType)"
                        class="license-tier-switch-modal__card-alert-msg sel-license-switch-alert-msg"
                    >
                        <avi-alert-group alertType="{{ getTierSwitchAlertStatus(tierType) }}">
                            <span class="license-tier-switch-modal__card-alert-msg-text sel-license-switch-alert-msg-text">
                                {{ getTierSwitchAlertMsg(tierType) }}
                            </span>
                        </avi-alert-group>
                    </div>
                    <div *ngIf="isSelected(enterpriseWithCloudServicesTier) && tierType === enterpriseWithCloudServicesTier">
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        for="cache-service-units"
                                        fieldName="reserve_service_units"
                                        objectType="{{ objectType }}"
                                        aviLabelWithTooltip
                                    >
                                        {{ l10nKeys.reserveServiceUnitsLabel | vtranslate }}
                                    </label>
                                    <input
                                        type="input"
                                        id="cache-service-units"
                                        [(ngModel)]="portalInfo.saas_licensing_config.reserve_service_units"
                                        name="cache-service-units"
                                        placeholder="{{ l10nKeys.reserveServiceUnitsPlaceholder | vtranslate }}"
                                        clrInput
                                        configFieldInputValidation
                                        objectType="{{ objectType }}"
                                        fieldName="reserve_service_units"
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        for="max-service-units"
                                        fieldName="max_service_units"
                                        objectType="{{ objectType }}"
                                        aviLabelWithTooltip
                                    >
                                        {{ l10nKeys.maxServiceUnitsLabel | vtranslate }}
                                    </label>
                                    <input
                                        type="input"
                                        id="max-service-units"
                                        [(ngModel)]="portalInfo.saas_licensing_config.max_service_units"
                                        name="max-service-units"
                                        placeholder="{{ l10nKeys.maxServiceUnitsPlaceholder | vtranslate }}"
                                        clrInput
                                        configFieldInputValidation
                                        objectType="{{ objectType }}"
                                        fieldName="max_service_units"
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                    </div>
                </license-tier-select-card>
                </div>
            </div>
        </div>
    </form>
</full-modal-config>
