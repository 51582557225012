/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { VSDataScriptEvent } from 'generated-types';

// Supported Datascript events
export const l4EventTypes = new Set([
    VSDataScriptEvent.VS_DATASCRIPT_EVT_L4_REQUEST,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_L4_RESPONSE,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_TCP_CLIENT_ACCEPT,
]);

export const dnsEventTypes = new Set([
    VSDataScriptEvent.VS_DATASCRIPT_EVT_DNS_REQ,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_DNS_RESP,
]);

export const sslEventTypes = new Set([
    VSDataScriptEvent.VS_DATASCRIPT_EVT_SSL_HANDSHAKE_DONE,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_CLIENT_SSL_PRE_CONNECT,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_CLIENT_SSL_CLIENT_HELLO,
]);

export const httpEventTypes = new Set([
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_AUTH,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_POST_AUTH,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_REQ,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_REQ_DATA,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_LB_DONE,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_LB_FAILED,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP_DATA,
    VSDataScriptEvent.VS_DATASCRIPT_EVT_HTTP_RESP_FAILED,
]);
