/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import {
    defaultPositionsPriority,
    TooltipPosition,
} from '../../directives/avi-tooltip';

/**
 * @description Component for an tooltip icon that shows transcluded content wrapped in the
 *     AviTooltipContentComponent. This component handles setting the tooltipPosition to be passed
 *     to AviTooltipContentComponent.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-icon-with-content-wrapper',
    templateUrl: './avi-tooltip-icon-with-content-wrapper.component.html',
})
export class AviTooltipIconWithContentWrapperComponent {
    /**
     * Classname for the Clarity icon.
     */
    @Input()
    public shape = 'info-circle';

    /**
     * Size of the icon.
     */
    @Input()
    public size = '20';

    /**
     * Delay for the tooltip content to appear.
     */
    @Input()
    public delay = 250;

    /**
     * True to show the tooltip content when the icon is clicked.
     */
    @Input()
    public showOnClick = false;

    /**
     * List of positions to prioritize for rendering the tooltip content.
     */
    @Input()
    public positionsPriority = defaultPositionsPriority;

    /**
     * Classes to be set on the cds-icon element.
     */
    @Input()
    public iconClassName: string;

    /**
     * Color of the icon will be displayed from status.
     */
    @Input()
    public status = '';

    /**
     * If true, icon will be displayed solid.
     */
    @Input()
    public solid = false;

    /**
     * Position of the tooltip, passed to the tooltip-icon component. Used to render the caret which
     * changes based on the position.
     */
    public tooltipPosition: TooltipPosition = TooltipPosition.TOP_RIGHT_POSITION;

    /**
     * Handler called to change the tooltipPosition.
     */
    public handlePositionChange(tooltipPosition: TooltipPosition): void {
        this.tooltipPosition = tooltipPosition;
    }
}
