<div class="cinematic-header-tabs">
    <ul class="cinematic-header-tabs__tabs-list">
        <li
            *ngFor="let tab of tabs; trackBy: trackByTabId"
            class="cinematic-header-tabs__tab"
            [ngClass]="getTabClassName(tab)"
            (click)="handleSelect(tab)"
        >
            {{ tab.title }}
        </li>
    </ul>
</div>
