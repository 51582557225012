/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-security-ssl-patches';
const prefix = `${moduleName}.${componentName}`;

export const openSSLPatchesHeader = `${prefix}.openSSLPatchesHeader`;
export const doneBtnLabel = `${prefix}.doneBtnLabel`;

export const ENGLISH = {
    [openSSLPatchesHeader]: 'OpenSSL Patches',
    [doneBtnLabel]: 'Done',
};
