/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @constant
 * @name itemAlertDataTransform
 * @param {{string: number}} alertsHash - Level to number of alerts hash.
 * @description
 *
 *     Sets highestAlertLevel property on alertsHash with the highest severity level of alerts
 *     present.
 */
const alertLevels = ['high', 'medium', 'low'];

function setHighestAlertLevel(alertsHash) {
    let highestAlertLevel = '';

    _.find(alertLevels, level => {
        if (alertsHash[level]) {
            highestAlertLevel = level;

            return true;
        }
    });

    alertsHash.highestAlertLevel = highestAlertLevel;
}

setHighestAlertLevel.alertLevels = alertLevels;

angular.module('aviApp').constant('itemAlertDataTransform', setHighestAlertLevel);
