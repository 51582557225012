/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { AviCompressionStatus } from 'generated-types';

import { TVsLogApplicationInfo } from '../components/vs-log-cinematic/vs-log-cinematic.types';

interface ILogResponseCompressionInfo {
    compressionInfoEmpty: boolean;
    compressed?: boolean;
    compressionPercentage?: number;
    noCompressionReason?: string;
}

/**
 * @description
 *     Pipe to extract response compression details from log application info and transform them to
 *     a more user-friendly representation.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'logResponseCompressionInfo',
})
export class LogResponseCompressionInfoPipe implements PipeTransform {
    constructor(private schemaService: SchemaService) {}

    /** @override */
    public transform(logApplicationInfo: TVsLogApplicationInfo): ILogResponseCompressionInfo {
        const {
            compression: comprenssionStatus,
            compression_percentage: percentage,
        } = logApplicationInfo;

        const logResponseCompressionInfo: ILogResponseCompressionInfo = {
            compressionInfoEmpty: true,
        };

        if (comprenssionStatus) {
            logResponseCompressionInfo.compressionInfoEmpty = false;

            const compressed = comprenssionStatus === AviCompressionStatus.COMPRESSED;

            logResponseCompressionInfo.compressed = compressed;

            if (compressed) {
                logResponseCompressionInfo.compressionPercentage = percentage;
            } else {
                const noCompressionEnumText = this.schemaService.getEnumValueLabel(
                    'AviCompressionStatus',
                    comprenssionStatus,
                );

                logResponseCompressionInfo.noCompressionReason = noCompressionEnumText;
            }
        }

        return logResponseCompressionInfo;
    }
}
