/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import { VcenterClusters } from 'object-types';
import { IVcenterClusters } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description Vcenter Clusters Message Item.
 * @author vgohil
 */
export class VcenterClustersConfigItem extends MessageItem<IVcenterClusters> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: VcenterClusters,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns the configured cluster refs.
     */
    public get clusterRefs(): string[] {
        return this.config.cluster_refs;
    }

    /**
     * Set the cluster refs.
     */
    public set clusterRefs(refs: string[]) {
        this.config.cluster_refs = refs;
    }

    /**
     * Returns the current include mode.
     */
    public get includeMode(): boolean {
        return this.config.include;
    }

    /**
     * Method to reset the Vcenter clusters info.
     */
    public resetClustersInfo(): void {
        const defaultConfig = this.getDefaultConfig();

        this.updateConfig(defaultConfig);
    }

    /**
     * Method to revert the mode change.
     */
    public revertModeChange(): void {
        const { config } = this;

        config.include = !config.include;
    }
}
