/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { contains, isEmpty } from 'underscore';
import {
    IpAddrMatchConfigItem,
    ServiceMatchConfigItem,
} from 'ajs/modules/match';

import {
    PolicyMatchConfigItem,
} from '../config-items/policy-match.config-item.factory';

/**
 * Supported match types for NatMatch message item.
 */
const supportedMatches = [
    'source_ip',
    'destination_ip',
    'source_port',
    'destination_port',
    'protocol',
];

/**
 * Match fields under serviceMatch.
 */
const serviceMatchFields = [
    'source_port',
    'destination_port',
    'protocol',
];

interface INatMatchConfig {
    services: ServiceMatchConfigItem;
    destination_ip: IpAddrMatchConfigItem;
    source_ip: IpAddrMatchConfigItem;
}

/**
 * @desc NatMatchTarget MessageItem class.
 * @author Aravindh Nagarajan
 */
export class NatMatchTargetConfigItem extends PolicyMatchConfigItem<INatMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatMatchTarget',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for the supported matches.
     * @override
     */
    public get supportedMatches(): string[] {
        return supportedMatches;
    }

    /**
     * @override
     */
    public get matchCount(): number {
        return this.supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * @override
     * @param matchFieldName - Name of the field of the match to remove.
     */
    public removeMatch(matchFieldName: string): void {
        if (contains(serviceMatchFields, matchFieldName)) {
            const { services: serviceMatch } = this.config;

            delete serviceMatch.config[matchFieldName];
            delete this.config[matchFieldName];

            if (isEmpty(serviceMatch.config)) {
                delete this.config.services;
            }
        } else {
            delete this.config[matchFieldName];
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { destination_ip: destinationIp, source_ip: sourceIp } = this.config;

        if (destinationIp) {
            destinationIp.clearFlatProps();
        }

        if (sourceIp) {
            sourceIp.clearFlatProps();
        }
    }
}
