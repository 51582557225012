/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    SsoPolicyCollection,
} from 'ajs/modules/sso-policy/factories/sso-policy.collection.factory';
import { StringService } from 'string-service';
import {
    SsoPolicyItem,
} from 'ajs/modules/sso-policy/factories/sso-policy.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './sso-policy-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TSsoPolicyCollection = typeof SsoPolicyCollection;

/**
 * @description SSO Policy list page.
 * @author Vijay
 */
@Component({
    selector: 'sso-policy-list',
    templateUrl: './sso-policy-list.component.html',
})
export class SSOPolicyListComponent implements OnInit, OnDestroy {
    /**
     * Configuration to display SSO Policy list.
     */
    public ssoPolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SsoPolicyCollection instance.
     */
    private readonly ssoPolicyListCollection: SsoPolicyCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject('SsoPolicyCollection')
        SsoPolicyListCollection: TSsoPolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.ssoPolicyListCollection = new SsoPolicyListCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.ssoPolicyListCollection;

        this.ssoPolicyGridConfig = {
            id: `${objectName}-page`,
            collection: this.ssoPolicyListCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: SsoPolicyItem): string => row.getName(),
                },
                {
                    id: 'default-auth-profile',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAuthProfile),
                    transform: (row: SsoPolicyItem): string => this.stringService.name(
                        row.getDefaultAuthProfileRef(),
                    ),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ssoPolicyListCollection.destroy();
    }
}
