/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const metricDataCollectionProvider = {
    deps: [$injector],
    provide: 'MetricDataCollection',
    useFactory(injector: Injector): any {
        return injector.get('MetricDataCollection');
    },
};

export const dnsQueryCollectionProvider = {
    deps: [$injector],
    provide: 'DNSQueryCollection',
    useFactory(injector: Injector): any {
        return injector.get('DNSQueryCollection');
    },
};
