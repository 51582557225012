<div class="alert-config-grid-expander">
    <div class="alert-config-grid-expander__container">
        <p>{{ globalL10nKeys.typeLabel | vtranslate }}</p>
        <hr>
        <p>{{ typeLabel || (globalL10nKeys.notApplicableLabel) | vtranslate }}</p>
    </div>
    <div 
        class="alert-config-grid-expander__container"
        *ngIf="!!(alertConfig.sysEventRules.count || alertConfig.metricRules.count)"
    >
        <div>
            <span *ngIf="alertConfig.sysEventRules.count">{{ l10nKeys.columnTitleEvents | vtranslate }}</span>
            <span *ngIf="alertConfig.metricRules.count">{{ l10nKeys.columnTitleMetrics | vtranslate }}</span>
        </div>
        <hr>
        <div class="alert-config-grid-expander__grid-events-list">
            {{ constructEventsString() }}
        </div>
    </div>
</div>
