<div 
    class="gslb-subdomains-tree"
    [ngClass]="'gslb-subdomains-tree__'+ index"
>
    <div class="gslb-subdomains-tree__container">
        <gslb-subdomains-tree-node 
            [data]="treeData.data" 
            [nodeType]="nodeType.ROOT_NODE"
            [isGslbEditable]="isGslbEditable"
            (onEditSubdomain)="editSubdomain($event)"
            (onRemoveSubdomain)="deleteSubdomain($event)"
            [focusLevel]="calculateFocusLevel(treeData.data.name)"
        ></gslb-subdomains-tree-node>

        <ul class="gslb-subdomains-tree__node-list">
            <li 
                class="gslb-subdomains-tree__node-list-item"
                *ngFor="let siteName of treeData.children"
            >
                <gslb-subdomains-tree-node 
                    [data]="siteName.data" 
                    [nodeType]="nodeType.PARENT_NODE"
                    (mouseenter)="handleHoverOnNode(siteName.data.name)"
                    (mouseleave)="clearHighlight()"
                    [focusLevel]="calculateFocusLevel(siteName.data.name)"
                ></gslb-subdomains-tree-node>

                <ul class="gslb-subdomains-tree__node-list">
                    <li 
                        class="gslb-subdomains-tree__node-list-item"
                        *ngFor="let dnsVsName of siteName.children"
                    >
                        <gslb-subdomains-tree-node 
                            [data]="dnsVsName.data" 
                            [nodeType]="nodeType.LEAF_NODE"
                            (mouseenter)="handleHoverOnNode(dnsVsName.data.name)"
                            (mouseleave)="clearHighlight()"
                            [focusLevel]="calculateFocusLevel(dnsVsName.data.name)"
                        ></gslb-subdomains-tree-node>
                    </li>
                </ul>
            </li>
        </ul>
        <!-- Container for SVG element to draw connecting lines. -->
        <div 
            #treeBackground 
            class="gslb-subdomains-tree__background-container"
        ></div>
    </div>
</div>
