/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { StringService } from 'string-service';

/**
 * @description Pipe to get slug value from the url.
 *
 * @author Hitesh Mandav
 */

@Pipe({
    name: 'slug',
})
export class SlugPipe implements PipeTransform {
    constructor(public readonly stringService: StringService) {}
    /**
     * Transforms URL to Slug.
     */
    public transform(val: string): string {
        return this.stringService.slug(val || '');
    }
}
