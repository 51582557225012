/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import * as l10n from './system-settings-access-system-access-card.l10n';
import './system-settings-access-system-access-card.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const DEFAULT_HTTP_PORT = 80;
const DEFAULT_HTTPS_PORT = 443;

/**
 * @description Component for Access Card - System Access, in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-system-access-card',
    templateUrl: './system-settings-access-system-access-card.component.html',
})
export class SystemSettingsAccessSystemAccessCardComponent {
    /**
     * Http Access flag.
     */
    @Input()
    public httpAccess = false;

    /**
     * Https Access flag.
     */
    @Input()
    public httpsAccess = false;

    /**
     * Http to Https Redirect flag.
     */
    @Input()
    public httpToHttpsRedirect = false;

    /**
     * Basic Authentication flag.
     */
    @Input()
    public basicAuthentication = false;

    /**
     * Class input variables with default values accept undefined values from parent
     * as they cannot have definite assignment assertions. Hence http default port
     * value is not provided here and it assumes default value of '80' in template.
     */
    @Input()
    public httpPort: number;

    /**
     * Class input variables with default values accept undefined values from parent
     * as they cannot have definite assignment assertions. Hence https default port
     * value is not provided here and it assumes default value of '443' in template.
     */
    @Input()
    public httpsPort: number;

    /**
     * http access status display value.
     */
    public httpAccessValue: string;

    /**
     * https access status display value.
     */
    public httpsAccessValue: string;

    /**
     * http to https redirect status display value.
     */
    public httpToHttpsRedirectValue: string;

    /**
     * basic authentication status display value.
     */
    public basicAuthenticationValue: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Object Type for Template usage.
     */
    public readonly objectType = 'PortalConfiguration';

    public readonly defaultHttpsPort = DEFAULT_HTTPS_PORT;
    public readonly defaultHttpPort = DEFAULT_HTTP_PORT;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnInit(): void {
        this.setBasicAuthenticationValue();
        this.setHttpAccessValue();
        this.setHttpsAccessValue();
        this.setHttpToHttpsRedirectValue();
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        const {
            basicAuthentication,
            httpAccess,
            httpsAccess,
            httpToHttpsRedirect,
        } = simpleChanges;

        if (basicAuthentication && !basicAuthentication.isFirstChange()) {
            this.setBasicAuthenticationValue();
        }

        if (httpAccess && !httpAccess.isFirstChange()) {
            this.setHttpAccessValue();
        }

        if (httpsAccess && !httpsAccess.isFirstChange()) {
            this.setHttpsAccessValue();
        }

        if (httpToHttpsRedirect && !httpToHttpsRedirect.isFirstChange()) {
            this.setHttpToHttpsRedirectValue();
        }
    }

    /**
     * Sets basic authentication status display value.
     */
    private setBasicAuthenticationValue(): void {
        const basicAuthenticationValue = this.basicAuthentication ?
            this.l10nKeys.allowedLabel : '';

        this.basicAuthenticationValue = this.l10nService.getMessage(basicAuthenticationValue);
    }

    /**
     * Getter for http to https redirect status display value.
     */
    private setHttpToHttpsRedirectValue(): void {
        const httpToHttpsRedirectValue = this.httpToHttpsRedirect ?
            this.l10nKeys.enabledLabel : this.l10nKeys.disabledLabel;

        this.httpToHttpsRedirectValue = this.l10nService.getMessage(httpToHttpsRedirectValue);
    }

    /**
     * Sets https access status display value.
     */
    private setHttpsAccessValue(): void {
        const httpsAccessValue = this.httpsAccess ?
            this.l10nKeys.enabledLabel : this.l10nKeys.disabledLabel;

        this.httpsAccessValue = this.l10nService.getMessage(httpsAccessValue);
    }

    /**
     * Sets http access status display value.
     */
    private setHttpAccessValue(): void {
        const httpAccessValue = this.httpAccess ?
            this.l10nKeys.enabledLabel : this.l10nKeys.disabledLabel;

        this.httpAccessValue = this.l10nService.getMessage(httpAccessValue);
    }
}
