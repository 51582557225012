<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitlePing | vtranslate }}</h3>

    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="monitor_port"
            for="monitor-port"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectType }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
        />
    </avi-input-container>
</div>
