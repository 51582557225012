<avi-tooltip-icon-with-content-wrapper
    shape="exclamation-circle"
    size="20"
    status="warning"
    [solid]="true"
>
    <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
</avi-tooltip-icon-with-content-wrapper>

<ng-template #tooltip>
    <h3>{{ l10nKeys.certificateMissingTooltipLabel | vtranslate }}</h3>
    <span>{{ missingCertIssuerName }}</span>
</ng-template>
