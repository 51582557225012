<avi-data-grid
    [config]="geoDbFileGridConfig"
    [rows]="geoDbFiles.config"
    class="geo-db-file-grid"
    [invalidateForm]="geoDbFiles.config.length < 1"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addGeoDbFileList()"
        >
            {{ l10nKeys.geoDbFileAddButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
    <ng-template
        #geoDbFileEnabledTemplateRef
        let-row
        let-index="index"
    >
        <span *ngIf="row.getConfig().enabled">
            <cds-icon
                shape="check-circle"
                size="22"
                class="geo-db-file-grid__check-icon"
            ></cds-icon>
        </span>
    </ng-template>
</avi-data-grid>
