/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { RbacModule } from 'ng/modules/rbac/rbac.module';
import { NotificationModule } from 'ng/modules/notification/notification.module';

import {
    authProfileCollectionProvider,
    certificateManagementCollectionProvider,
    controlScriptCollectionProvider,
    fileObjectCollectionProvider,
    geoDbFileConfigItemProvider,
    geoDbMappingConfigItemProvider,
    geoDbMappingElementConfigItemProvider,
    geoDbProvider,
    hsmGroupCollectionProvider,
    jwtServerProfileCollectionProvider,
    jwtServerProfileProvider,
} from './ajs-upgraded-providers';

import {
    AuthMappingProfilePageComponent,
    AuthProfileListPageComponent,
    AuthProfileVerifyLdapModalComponent,
    AuthProfileVerifySamlModalComponent,
    CertificateManagementCustomParamsGridComponent,
    CertificateManagementListPageComponent,
    CertificateManagementModalComponent,
    CustomParamsModalComponent,
    GeoDbFileGridComponent,
    GeoDbFileModalComponent,
    GeoDbListComponent,
    GeoDbMappingElementGridComponent,
    GeoDbMappingElementModalComponent,
    GeoDbMappingGridComponent,
    GeoDbMappingModalComponent,
    GeoDbModalComponent,
    HsmGroupCloudhsmHsmIpGridComponent,
    HsmGroupDownloadCertificateModalComponent,
    HsmGroupDownloadCertificateModalService,
    HsmGroupListComponent,
    HSMGroupModalComponent,
    HsmGroupSafenetClientInfoGridComponent,
    HsmGroupSafenetLunaServerGridComponent,
    HsmGroupSafenetLunaServerModalComponent,
    JwtServerProfileComponent,
    JWTServerProfileModalComponent,
    MissingCertIssuerIcon,
    PkiCertificateAuthorityModalComponent,
    PkiCrlFileUploadDialogComponent,
    PkiCrlServerUrlModalComponent,
    PkiCrlUploadSuccessNotificationComponent,
    PkiProfileCertificateAuthorityGridComponent,
    PkiProfileListPageComponent,
    PkiProfileModalComponent,
    SslCertificateCreateApplicationModalComponent,
    SslCertificateCreateRootModalComponent,
    SslCertificateExpander,
    SslCertificateExportModalComponent,
    SslCertificateExportModalService,
    SslCertificateGridNameColumnComponent,
    SslCertificateGridStatusColumnComponent,
    SSLCertificateImportedInformationComponent,
    SslCertificateListComponent,
    SslCertificateSigningRequestComponent,
    SslCiphersGridComponent,
    SslProfileListComponent,
    SslRatingPreviewComponent,
    SslRatingPreviewContainerComponent,
    SslTlsCertificateOcspComponent,
    SslTlsProfileModalComponent,
} from '.';

const securityComponents = [
    AuthMappingProfilePageComponent,
    AuthProfileListPageComponent,
    AuthProfileVerifyLdapModalComponent,
    AuthProfileVerifySamlModalComponent,
    CertificateManagementListPageComponent,
    CertificateManagementCustomParamsGridComponent,
    CertificateManagementModalComponent,
    CustomParamsModalComponent,
    GeoDbModalComponent,
    GeoDbFileGridComponent,
    GeoDbFileModalComponent,
    GeoDbListComponent,
    GeoDbMappingElementGridComponent,
    GeoDbMappingElementModalComponent,
    GeoDbMappingGridComponent,
    GeoDbMappingModalComponent,
    HsmGroupCloudhsmHsmIpGridComponent,
    HsmGroupDownloadCertificateModalComponent,
    HsmGroupListComponent,
    HSMGroupModalComponent,
    HsmGroupSafenetClientInfoGridComponent,
    HsmGroupSafenetLunaServerGridComponent,
    HsmGroupSafenetLunaServerModalComponent,
    JwtServerProfileComponent,
    JWTServerProfileModalComponent,
    MissingCertIssuerIcon,
    PkiCertificateAuthorityModalComponent,
    PkiCrlFileUploadDialogComponent,
    PkiCrlServerUrlModalComponent,
    PkiCrlUploadSuccessNotificationComponent,
    PkiProfileCertificateAuthorityGridComponent,
    PkiProfileModalComponent,
    PkiProfileListPageComponent,
    SslCiphersGridComponent,
    SslRatingPreviewComponent,
    SslRatingPreviewContainerComponent,
    SslTlsProfileModalComponent,
    SslCertificateCreateRootModalComponent,
    SslCertificateExpander,
    SslCertificateExportModalComponent,
    SslCertificateGridNameColumnComponent,
    SslCertificateGridStatusColumnComponent,
    SSLCertificateImportedInformationComponent,
    SslCertificateListComponent,
    SslTlsCertificateOcspComponent,
    SslCertificateSigningRequestComponent,
    SslCertificateCreateApplicationModalComponent,
    SslProfileListComponent,
];

/**
 * @description
 *
 *      Angular Module for Security (JWT, SSL Profile/ Certificate..) related components.
 *
 * @author Rachit Aggarwal, Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...securityComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        BrowserAnimationsModule,
        ClrFormsModule,
        ClrInputModule,
        ClrSpinnerModule,
        DialogModule,
        FormsModule,
        SharedModule,
        DataGridModule,
        RbacModule,
        NotificationModule,
    ],
    providers: [
        authProfileCollectionProvider,
        controlScriptCollectionProvider,
        fileObjectCollectionProvider,
        geoDbFileConfigItemProvider,
        geoDbMappingConfigItemProvider,
        geoDbMappingElementConfigItemProvider,
        geoDbProvider,
        jwtServerProfileCollectionProvider,
        jwtServerProfileProvider,
        hsmGroupCollectionProvider,
        certificateManagementCollectionProvider,
        HsmGroupDownloadCertificateModalService,
        SslCertificateExportModalService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SecurityModule {}
