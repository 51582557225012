<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.editModalTitle : l10nKeys.addModalTitle) | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="remote_ip"
                    objectType="{{ objectType }}"
                    for="remote-ip-addr"
                    required
                >
                    {{ l10nKeys.hsmAddressInputLabel | vtranslate }}
                </label>
                <input
                    id="remote-ip-addr"
                    name="remote-ip-addr"
                    clrInput
                    placeholder="{{ l10nKeys.hsmAddressPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.remote_ip"
                    regexPattern="hostname"
                    required
                />    
            </avi-input-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="partition_serial_number"
                    objectType="{{ objectType }}"
                    for="partition-serial-number"
                >
                    {{ l10nKeys.serialNumberInputLabel | vtranslate }}
                </label>
                <input
                    id="partition-serial-number"
                    name="partition-serial-number"
                    clrInput
                    placeholder="{{ l10nKeys.serialNumberPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.partition_serial_number"
                />    
            </avi-input-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="partition_passwd"
                    objectType="{{ objectType }}"
                    for="partition-passwd"
                >
                    {{ l10nKeys.passwordInputLabel | vtranslate }}
                </label>
                <input
                    id="partition-passwd"
                    name="partition-passwd"
                    type="password"
                    clrInput
                    placeholder="{{ l10nKeys.passwordPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.partition_passwd"
                />    
            </avi-input-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        fieldName="server_cert"
                        objectType="{{ objectType }}"
                        for="server-cert"
                        required
                    >
                        {{ l10nKeys.serverCertificateInputLabel | vtranslate }}
                    </label>
                    <file-upload-textarea
                        name="server-cert"
                        placeholder="{{ l10nKeys.serverCertificatePlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.server_cert"
                        required
                    ></file-upload-textarea>
                </div>
            </div>
        </div>
    </form> 
</full-modal-config>
