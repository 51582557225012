/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-subdomains-tree-node';

const prefix = `${moduleName}.${componentName}`;

export const hostAllSubdomainLabel = `${prefix}.hostAllSubdomainLabel`;

export const ENGLISH = {
    [hostAllSubdomainLabel]: 'Host All Subdomains',
};
