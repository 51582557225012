/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createReducer, on } from '@ngrx/store';
import * as RootStoreActions from './root-store.actions';
import { initialState, IRootStoreState } from './root-store.state';

export const rootStoreReducer = createReducer(
    initialState,
    on(
        RootStoreActions.setLoading,
        (state: IRootStoreState, { loading }): IRootStoreState => ({
            ...state,
            loading,
        }),
    ),
);
