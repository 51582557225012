<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="value"
                        for="value"
                    >
                        {{ l10nKeys.valueInputLabel | vtranslate }}
                    </label>
                    <textarea
                        id="value"
                        name="value"
                        rows="5"
                        clrTextarea
                        [(ngModel)]="editable.config.value"
                        placeholder="{{ l10nKeys.valueInputPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>
                <avi-checkbox-container-group>
                    <avi-checkbox
                        objectType="{{ objectType }}"
                        fieldName="is_sensitive"
                        [(ngModel)]="editable.config.is_sensitive"
                        name="is-sensitive"
                    >
                        {{ l10nKeys.sensitiveCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    <avi-checkbox
                        objectType="{{ objectType }}"
                        fieldName="is_dynamic"
                        [(ngModel)]="editable.config.is_dynamic"
                        name="is-dynamic"
                    >
                        {{ l10nKeys.dynamicCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                </avi-checkbox-container-group>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
