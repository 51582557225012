<avi-state-page class="vrf-context-page">
    <avi-state-page_main-content>
        <div class="vrf-context-page__header-container">
            <avi-page-header
                headerText="{{ l10nKeys.vrfContextHeader | vtranslate }}"
            ></avi-page-header>
        </div>
        <div class="vrf-context-page__collection-grid">
            <avi-collection-data-grid
                class="sel-segroup-list"
                [config]="vrfContextGridConfig"
            ></avi-collection-data-grid>

            <ng-template
                #bgpPeeringTemplateRef
                let-row
            >
                <cds-icon
                    *ngIf="row.bgpProfile; else elseBlock"
                    status="success"
                    shape="check-circle"
                    size="18"
                ></cds-icon>
                <ng-template #elseBlock>
                    <div>-</div>
                </ng-template>
            </ng-template>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
