<avi-card class="ip-reputation-search-record-card">
    <avi-card_header>
        <div class="ip-reputation-search-record-card__header">
            {{ l10nKeys.header | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <div class="ip-reputation-search-record-card__search-container">
            <form
                clrForm
                #searchForm="ngForm"
                (ngSubmit)="searchForm.valid && search(searchValue)"
            >
                <cds-search
                    layout="vertical"
                    validate
                    [status]="searchForm.valid ? 'neutral' : 'error'"
                >
                    <label></label> <!-- Empty label Required by cds-search component -->
                    <input
                        type="search"
                        id="search-input"
                        placeholder="{{ l10nKeys.searchInputPlaceholder | vtranslate }}"
                        name="searchValue"
                        [(ngModel)]="searchValue"
                        regexPattern="ip"
                    />
                    <cds-control-message
                        status="error"
                        *ngIf="!searchForm.valid"
                    >
                        {{ l10nKeys.invalidInputText | vtranslate }}
                    </cds-control-message>
                </cds-search>
            </form>
            <cds-icon
                *ngIf="searchValue"
                class="ip-reputation-search-record-card__search-clear-icon"
                [ngClass]="{'ip-reputation-search-record-card__search-clear-icon--offset-right': !searchForm.valid}"
                shape="times-circle"
                size="20"
                (click)="clearInput()"
            >
            </cds-icon>
        </div>
        <div
            class="ip-reputation-search-record-card__loader"
            [hidden]="!busy"
        >
            <clr-spinner clrSmall></clr-spinner>
        </div>
        <avi-alert-group
            *ngIf="errors"
            alertType="danger"
        >
            {{ errors | aviBackendErrorMsg }}
        </avi-alert-group>
        <div
            class="ip-reputation-search-record-card__result-description"
            *ngIf="searchResult?.results.length && searchForm.valid"
            [innerHTML]="l10nKeys.matchFoundMessage | vtranslate: this.ipReputationDB.config.name"
        ></div>

        <div
            class="ip-reputation-search-record-card__empty-result-description"
            *ngIf="!busy && !searchResult && !errors"
            [innerHTML]="l10nKeys.searchRecordMessage | vtranslate: this.ipReputationDB.config.name"
        ></div>

        <div
            class="ip-reputation-search-record-card__empty-result-description"
            *ngIf="!busy && searchResult?.results.length === 0 && !errors"
        >
            <b>{{ l10nKeys.noRecordFoundMessage | vtranslate : searchedIPAddress }}</b>
        </div>

        <div
            class="ip-reputation-search-record-card__search-result"
            *ngIf="searchResult?.results.length > 0 && searchForm.valid"
        >
            <div class="ip-reputation-search-record-card__search-result-label">
                <label aviLabelWithTooltip>
                    {{ l10nKeys.threatTypeLabel | vtranslate : searchResult.results.length }}
                </label>
            </div>
            <div cds-layout="vertical gap:sm">
                <cds-tag
                    readonly
                    status="info"
                    *ngFor="let result of searchResult.results; trackBy: trackbyIndex"
                    class="ip-reputation-search-record-card__search-result-tags"
                >
                    {{ getIPReputationType(result) }}
                </cds-tag>
            </div>
        </div>
    </avi-card_body>
</avi-card>
