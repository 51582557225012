<avi-confirmation
    class="clr-wrapper"
    (onClose)="handleCancel()"
    headerText="{{ header }}"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <div *ngIf="warning">{{ warning }}</div>
        <b>{{ l10nKeys.continueQuestionWarning | vtranslate }}</b>
    </ng-container>

    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button
            action="outline"
            (click)="handleCancel()"
        >
            {{ l10nKeys.cancelBtnLabel | vtranslate }}
        </cds-button>

        <cds-button
            (click)="handleContinue()"
            status="danger"
        >
            {{ l10nKeys.yesContinueBtnLabel | vtranslate }}
        </cds-button>
    </ng-container>
</avi-confirmation>
