/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'user-menu-additional-menu';
const prefix = `${moduleName}.${componentName}`;

export const customerPortalLabel = `${prefix}.customerPortalLabel`;
export const aviApiDocumentationLabel = `${prefix}.aviApiDocumentationLabel`;
export const generateTokenLabel = `${prefix}.generateTokenLabel`;

export const ENGLISH = {
    [customerPortalLabel]: 'Customer Portal',
    [aviApiDocumentationLabel]: 'Avi API Documentation',
    [generateTokenLabel]: 'Generate Token',
};
