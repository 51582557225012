/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'alert';
const componentName = 'alert-config-grid-expander';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleEvents = `${prefix}.columnTitleEvents`;
export const columnTitleMetrics = `${prefix}.columnTitleMetrics`;

export const ENGLISH = {
    [columnTitleEvents]: 'Event(s)',
    [columnTitleMetrics]: 'Metric(s)',
};
