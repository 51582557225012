<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="jwt-match"
        provideParentForm
    >
        <div class="jwt-match__name-container">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ matchObjectType }}"
                    fieldName="token_name"
                    for="token_name"
                    required
                >
                    {{ l10nKeys.tokenNameLabel | vtranslate }}
                </label>
                <input
                    id="{{ 'token-name-' + matchIndex }}"
                    name="{{ 'token-name-' + matchIndex }}"
                    [(ngModel)]="editable.config.token_name"
                    clrInput
                    placeholder="{{ l10nKeys.tokenNamePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>

        <div>
            <div
                *ngFor="let match of editable.matches.config; index as i; trackBy: trackByIndex"
                class="jwt-match__claim-container"
            >
                <jwt-claim-match-config
                    class="jwt-match__claim-match-config"
                    [editable]="match"
                    id="{{ matchIndex + '-' + i }}"
                    [showDeleteIcon]="editable.matches.count > 1"
                    (onDeleteClaim)="deleteClaim($event)"
                ></jwt-claim-match-config>
            </div>
        </div>
        
        <cds-button
            class="jwt-match__add-claim-name-btn"
            action="flat"
            size="sm"
            (click)="addClaimName()"
        >
            <cds-icon shape="plus-circle"></cds-icon>
            {{ l10nKeys.addClaimNameButtonLabel | vtranslate }}
        </cds-button>
    </div>
</match-wrapper>
