/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import {
    AlertSyslogConfigModalComponent,
} from 'ng/modules/alert/components/alert-syslog-modal/alert-syslog-modal.component';
import { ALERT_SYSLOG_CONFIG_ITEM_TOKEN } from '../../alert.tokens';

/**
 * @description
 *
 *   AlertSyslogConfig collection
 *
 * @author Guru Prasad H K
 */
export class AlertSyslogConfigCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'alertsyslogconfig',
            permissionName: AviPermissionResource.PERMISSION_ALERTSYSLOGCONFIG,
            windowElement: AlertSyslogConfigModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ALERT_SYSLOG_CONFIG_ITEM_TOKEN);
    }
}

AlertSyslogConfigCollection.ajsDependencies = [
    ALERT_SYSLOG_CONFIG_ITEM_TOKEN,
];
