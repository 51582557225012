/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ListCollDataSource } from './list-coll-data-source.factory';

/**
 * @description
 *  ListCollDataSource with defaultSearchParamName as 'name.icontains'.
 *
 *  Used for APIs which don't support the standard "isearch". ex. /api/user
 *
 * @author Aravindh Nagarajan
 */
export class ListCollDataSourceWithNameContainsSearchParam extends ListCollDataSource {}

Object.assign(ListCollDataSourceWithNameContainsSearchParam.prototype, {
    defaultSearchParamName_: 'name.icontains',
});
