/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';

import {
    isEqual,
    isObject,
} from 'underscore';

/**
 * @ngdoc service
 * @name  ObjectDiffService
 * @description Service for getting Diff between objects.
 * @author Hitesh Mandav
 */
@Injectable()
export class ObjectDiffService {
    /**
     * Returns a object of values in obj different from comparatorObj.
     */
    public getDiff(
        obj: Record<string, any>,
        comparatorObj: Record<string, any>,
    ): Record<string, any> {
        const allConfigKeys = Object.keys(obj);
        const diff = {};

        allConfigKeys.forEach(key => {
            // to check if default comparatorObj does not have the key as its own property
            if (!Object.prototype.hasOwnProperty.call(comparatorObj, key)) {
                diff[key] = obj[key];
            } else if (!isEqual(obj[key], comparatorObj[key])) {
                /**
                 * to check if the value for key in obj is an object and not an array.
                 * in case of array that has different values, all the values of the obj[key] array
                 * are copied in the diff[Key] in else section rather that just coping just
                 * the values that are different.
                 */
                if (isObject(obj[key]) && !Array.isArray(obj[key])) {
                    diff[key] = this.getDiff(obj[key], comparatorObj[key]);
                } else {
                    diff[key] = obj[key];
                }
            }
        });

        return diff;
    }
}
