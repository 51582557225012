/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import * as l10n from './avi-custom-collection-dropdown.l10n';

import './avi-custom-collection-dropdown.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component that renders collection-dropdown or input field based on user selection.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'avi-custom-collection-dropdown',
    templateUrl: './avi-custom-collection-dropdown.component.html',
})
export class AviCustomCollectionDropdownComponent implements OnInit, OnChanges {
    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * Model value for collection-dropdown (two-way binding).
     */
    @Input()
    public collectionRef: string;

    /**
     * Model value for custom values input (two-way binding).
     */
    @Input()
    public customValues: string[];

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * This component can be used repeatedly, so to ensure the ngModel has a unique name
     * the index can be passed in as a binding.
     */
    @Input()
    public index: string | number = 0;

    /**
     * Name attribute to be passed along with index property for unique names in repeated case.
     */
    @Input()
    public name: string;

    /**
     * Required - True if either dropdown-ref or custom-value is a required.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Set through 'disabled' binding. Disables dropdown and custom input field.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * Params to be passed to collection.create (Optional).
     */
    @Input()
    public createParams = {};

    /**
     * Collection-dropdown placeholder (Optional).
     */
    @Input()
    public dropdownPlaceholder: string;

    /**
     * Custom values input placeholder (Optional).
     */
    @Input()
    public inputPlaceholder: string;

    /**
     * True to disallow creating a new Item from collectionDropdown (Optional).
     */
    @Input()
    public disableCreate = false;

    /**
     * True to disallow editing a selected Item from collectionDropdown (Optional).
     */
    @Input()
    public disableEdit = false;

    /**
     * Shows/Hides repeated input field for custom values.
     */
    @Input()
    public allowMultipleCustomValues = false;

    /**
     * Name of regex from regex.utils to apply to input field.
     */
    @Input()
    public readonly regex?: string;

    /**
     * Helper text for input field.
     */
    @Input()
    public inputHelperText?: string;

    /**
     * Emitter for collectionRef change.
     * Required for two-way binding.
     */
    @Output()
    public collectionRefChange = new EventEmitter<string>();

    /**
     * Emitter for customValues change.
     * Required for two-way binding.
     */
    @Output()
    public customValuesChange = new EventEmitter<string[]>();

    /**
     * If true, removes the margin-top from clr-radio-container.
     */
    public noMarginTop = false;

    /**
     * Controls Radio buttons.
     * If True, Collection-dropdown will be show & input will be hidden,
     * else viceversa.
     */
    public showCollectionDropdown = true;

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.dropdownPlaceholder =
            l10nService.getMessage(l10nKeys.collectionDropdownDefaultPlaceholder);

        this.inputPlaceholder =
            l10nService.getMessage(l10nKeys.customInputPlaceholder);
    }

    /** @override */
    public ngOnInit(): void {
        this.showCollectionDropdown = !this.hasCustomValues();
    }

    /**
     * @override
     * Sets this.showCollectionDropdown according to changes in the customValue.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const { customValue } = changes;

        if (customValue && !customValue.firstChange) {
            this.showCollectionDropdown = !this.hasCustomValues();
        }
    }

    /**
     * Handler for type (dropdown/input) radio button change.
     */
    public onTypeChange(): void {
        if (this.showCollectionDropdown) {
            this.customValues = [];

            this.onCustomValueChange();
        } else {
            this.collectionRef = undefined;

            this.onCollectionRefChange();
        }
    }

    /**
     * Emits collectionRef change event.
     */
    public onCollectionRefChange(): void {
        this.collectionRefChange.emit(this.collectionRef);
    }

    /**
     * Emits customValues change event.
     */
    public onCustomValueChange(): void {
        this.customValuesChange.emit(this.customValues);
    }

    /**
     * Check if customValues present.
     */
    private hasCustomValues(): boolean {
        return Boolean(this.customValues?.length);
    }
}
