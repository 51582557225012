/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './PoolNavbars.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('poolSubheader', function() {
    return {
        restrict: 'E',
        templateUrl: 'src/views/components/application/pool-subheader.html',
        link: {
            pre(scope, $element) {
                $element.addClass('avi-subheader');
            },
        },
    };
});

angular.module('aviApp').directive('poolNavbar', function() {
    class PoolNavbarDirective {
        constructor(
            $stateParams,
            l10nService,
        ) {
            this.$stateParams = $stateParams;

            /**
             * Get keys from source bundles for template usage.
             */
            this.l10nKeys = l10nKeys;
            l10nService.registerSourceBundles(dictionary);
        }
    }

    PoolNavbarDirective.$inject = [
        '$stateParams',
        'l10nService',
    ];

    return {
        restrict: 'E',
        templateUrl: 'src/views/components/application/pool-navbar.html',
        link: {
            pre(scope, $element) {
                $element.addClass('navbar');
            },
        },
        controller: PoolNavbarDirective,
        controllerAs: '$ctrl',
    };
});
