/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './TenantListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('TenantListController', [
'$scope', 'CRUDGridConfig', 'TenantCollection', 'getGridMetricFieldConfig', 'l10nService',
function($scope, CRUDGridConfig, TenantCollection, getGridMetricFieldConfig, l10nService) {
    const
        seriesNamePrefix = 'tenant_stats.',
        gridMetricFields = [
            'avg_num_backend_servers',
            'avg_num_se_cores',
            'max_total_se_throughput',
            'sum_total_vs_client_bytes',
            'max_num_ses',
            'avg_num_ses',
            'max_num_sockets',
            'avg_num_sockets',
        ];

    l10nService.registerSourceBundles(dictionary);

    $scope.l10nKeys = l10nKeys;

    $scope.gridConfig = new CRUDGridConfig();

    $scope.gridConfig.collection = new TenantCollection();

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.layout.includeTimeframeSelector = true;
    $scope.gridConfig.layout.includeMetricsValueSelector = true;

    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.gridConfigNameFieldTitle),
        sortBy: 'name',
        visibility: 'm',
    }, {
        name: 'data.inventory.users',
        title: l10nService.getMessage(l10nKeys.gridConfigNumUsersFieldTitle),
        visibility: 'm',
    }, {
        name: 'tenant-admin-users',
        title: l10nService.getMessage(l10nKeys.gridConfigAdminUsersFieldTitle),
        visibility: 'm',
        transform(row) {
            return row.data.inventory && row.data.inventory['tenant-admin'] &&
                    row.data.inventory['tenant-admin'].join(', ') || '';
        },
    }, {
        name: 'other-users',
        title: l10nService.getMessage(l10nKeys.gridConfigOtherUsersFieldTitle),
        visibility: 'm',
        transform(row) {
            if (row.data.inventory && row.data.inventory['non-admin']) {
                let nonAdmin = row.data.inventory['non-admin'];

                nonAdmin = _.uniq(nonAdmin);

                return nonAdmin.join(', ');
            }

            return '';
        },
    },
    ];

    const { fields } = $scope.gridConfig;

    //let's add metric series fields
    Array.prototype.push.apply(fields,
        gridMetricFields.map(seriesName =>
            getGridMetricFieldConfig(seriesNamePrefix + seriesName)));

    $scope.$on('$destroy', function() {
        $scope.gridConfig.collection.destroy();
    });
}]);
