/**
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import {
    ITUpgradeNode,
    UpgradeNodeCollection,
} from 'ajs/modules/upgrade';

import {
    IUpgradeStatusInfo,
    NodeType,
    UpgradeOpsType,
} from 'generated-types';

import { UpgradeStateGroup } from 'ng/modules/update/update.types';
import { L10nService } from '@vmw/ngx-vip';
import { UPGRADE_STATE_POLLING_INTERVAL } from '../../services/update.service';

import * as l10n from './upgrade-transcript-modal.l10n';
import './upgrade-transcript-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *    Modal Component to display Controller/SE-group upgrade progress/status and transcript.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'upgrade-transcript-modal',
    templateUrl: './upgrade-transcript-modal.component.html',
})
export class UpgradeTranscriptModalComponent implements OnInit, OnDestroy {
    /**
     * UUID of the node.
     */
    @Input()
    public nodeId: string;

    /**
     * Upgrade node type : controller/SE-group
     */
    @Input()
    public nodeType: NodeType;

    /**
     * To be fired on modal close.
     */
    @Output()
    public onClose: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Upgrade Node version.
     */
    public version: string;

    /**
     * Controller/SE-group upgradeNode instance.
     */
    public node: ITUpgradeNode;

    /**
     * Name of the node.
     */
    public nodeName: string;

    /**
     * upgrade operation (upgrade/patch/rollback) label's l10nKey.
     */
    public upgradeOperationLabel: string;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Upgrade status hash.
     */
    public readonly upgradeStateHash = {
        ERROR: UpgradeStateGroup.GROUP_ERROR,
        SUCCESS: UpgradeStateGroup.GROUP_COMPLETED,
    };

    /**
     * Hash of UpgradeOpsType and its l10n keys.
     */
    private readonly upgradeOpsHash = {
        [UpgradeOpsType.PATCH]: this.l10nKeys.patchLabel,
        [UpgradeOpsType.ROLLBACK]: this.l10nKeys.rollbackLabel,
        [UpgradeOpsType.ROLLBACKPATCH]: this.l10nKeys.rollbackPatchLabel,
        [UpgradeOpsType.UPGRADE]: this.l10nKeys.upgradeLabel,
    };

    /**
     * UpgradeNodeCollection instance.
     * Used to load upgrade Node.
     */
    private upgradeNodeCollection: UpgradeNodeCollection | null = null;

    public constructor(
        private readonly l10nService: L10nService,
        @Inject('UpgradeNodeCollection')
        private readonly UpgradeNodeCollectionClass: typeof UpgradeNodeCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        // When called from seGroupCollection grid in se-page-update.component
        // nodeType will not be there.
        this.nodeType = this.nodeType || NodeType.NODE_SE_GROUP;

        this.getUpgradeNodeInstance()
            .then(() => {
                this.nodeName = this.node.getName();
                this.version = this.node.getVersion();
                this.setUpgradeOpsLabel();

                // If upgrade operation is not in terminal state (ie update is still in-progress)
                // set UpgradeNodeCollection instance with updateInterval to keep polling.
                // Once collection is loaded, update this.node with the latest.
                if (!this.isInTerminalState) {
                    this.setUpgradeNodePolling();
                }
            });
    }

    /**
     * Returns whether upgrade completed or not.
     */
    public get isInTerminalState(): boolean {
        return !this.node.isUpgradeInProgress();
    }

    /**
     * Getter for node type label.
     */
    public get nodeTypeLabel(): string {
        return this.nodeType === NodeType.NODE_CONTROLLER_CLUSTER ?
            this.l10nService.getMessage(this.l10nKeys.controllerLabel) :
            this.l10nService.getMessage(this.l10nKeys.seGroupLabel);
    }

    /**
     * Getter for node's progress.
     */
    public get upgradeProgress(): number {
        return this.node.progressPercentage;
    }

    /**
     * Getter for upgrade end time.
     */
    public get upgradeEndTime(): string {
        return this.node.upgradeEndTime;
    }

    /**
     * Getter for modal header text.
     *
     * If this.node is loaded, returns internationalized headerText
     * returns empty string otherwise.
     */
    public get modalHeaderText(): string {
        if (this.node) {
            const headerLabel = this.l10nService.getMessage(this.l10nKeys.headerLabel, [
                this.version,
            ]);

            const upgradeOperationLabel = this.l10nService.getMessage(this.upgradeOperationLabel);

            return `${upgradeOperationLabel} ${headerLabel}`;
        } else {
            return '';
        }
    }
    /**
     * Getter for upgrade state.
     */
    public get upgradeState(): string {
        const { stateGroup } = this.node;

        switch (stateGroup) {
            case UpgradeStateGroup.GROUP_ERROR:
                return this.upgradeStateHash.ERROR;

            case UpgradeStateGroup.GROUP_COMPLETED:
                return this.upgradeStateHash.SUCCESS;
        }
    }

    /**
     * Returns upgrade transcript as string.
     */
    public get transcript(): string {
        const { upgradeEvents } = this.node;

        return JSON.stringify(upgradeEvents, null, 4);
    }

    /**
     * Handle modal close.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.upgradeNodeCollection) {
            this.upgradeNodeCollection.destroy();
        }
    }

    /**
     * Sets Upgrade operation UI Label.
     */
    private setUpgradeOpsLabel(): void {
        const { upgrade_ops: upgradeOps } = this.node.getConfig() as IUpgradeStatusInfo;

        this.upgradeOperationLabel = this.upgradeOpsHash[upgradeOps];
    }

    /**
     * Loads upgradeNode collection and sets this.node.
     */
    private async getUpgradeNodeInstance(): Promise<void> {
        await this.loadUpgradeNodeCollection();

        // Set this.node from the response from collection.
        this.setUpgradeNode();
    }

    /**
     * Loads upgradeNodeCollection with id & node_type.
     */
    private loadUpgradeNodeCollection(): Promise<void> {
        const { nodeId, nodeType } = this;

        this.upgradeNodeCollection = new this.UpgradeNodeCollectionClass({
            params: {
                id: nodeId,
                node_type: nodeType,
            },
        });

        return this.upgradeNodeCollection.load();
    }

    /**
     * Sets this.node from upgradeNodeCollection.
     */
    private setUpgradeNode = (): void => {
        this.node = this.upgradeNodeCollection.getItemById(this.nodeId);
    };

    /**
     * Sets updateInterval to poll for latest upgradeNode for in-progress state nodes.
     */
    private setUpgradeNodePolling = (): void => {
        this.upgradeNodeCollection.setUpdateInterval(UPGRADE_STATE_POLLING_INTERVAL);
    };
}
