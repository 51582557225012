/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IDnsOptRecord } from 'generated-types';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { DnsMessageType } from '../../vs-log-cinematic.types';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';
import * as l10n from './vs-log-cinematic-dns-opt-record.l10n';
import './vs-log-cinematic-dns-opt-record.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the DNS Opt Record info in log c-view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-dns-opt-record',
    templateUrl: './vs-log-cinematic-dns-opt-record.component.html',
})
export class VsLogCinematicDnsOptRecordComponent implements OnInit, AfterViewInit {
    @Input()
    public dnsOptRecord: IDnsOptRecord;

    /**
     * Dns opt record can be either of response or request message type.
     */
    @Input()
    public dnsMessageType: DnsMessageType;

    /**
     * Datagrid template for the EDNS Option Code field.
     */
    @ViewChild('ednsOptionCodeFieldTemplateRef')
    public readonly ednsOptionCodeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Source Prefix Length field.
     */
    @ViewChild('sourcePrefixLengthFieldTemplateRef')
    public readonly sourcePrefixLengthFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Scope Prefix Length field.
     */
    @ViewChild('scopePrefixLengthLabel')
    public readonly scopePrefixLengthLabel: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Subnet field.
     */
    @ViewChild('subnetFieldTemplateRef')
    public readonly subnetFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public endsRecordsGridConfig: IAviDataGridConfig;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('DnsEdnsOptionCode'),
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,

    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.endsRecordsGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.endsRecordsGridConfig.fields = [
            {
                label: this.l10nService.getMessage(l10nKeys.ednsOptionCodeLabel),
                id: 'edns-option-code',
                templateRef: this.ednsOptionCodeFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.sourcePrefixLengthLabel),
                id: 'source-prefix-length',
                templateRef: this.sourcePrefixLengthFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.scopePrefixLengthLabel),
                id: 'scope-prefix-length',
                templateRef: this.scopePrefixLengthLabel,
            },
            {
                label: this.l10nService.getMessage(globalL10nKeys.subnetLabel),
                id: 'subnet',
                templateRef: this.subnetFieldTemplateRef,
            },
        ];
    }

    /**
     * Create subnet filter property based on IP version V4/V6 and dns response/request.
     */
    public getSubnetFilterProperty(subnetIpv6: string | undefined): string {
        const subProperty = subnetIpv6 ? 'subnet_ip6' : 'subnet_ip';

        return `dns_${this.dnsMessageType}.opt_record.options.${subProperty}`;
    }
}
