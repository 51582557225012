/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { NgModule } from '@angular/core';

import {
    AlbHttpClient,
    ALB_HTTP_INTERCEPTORS,
    EncodeQueryParamsInterceptor,
    HttpErrorHandlingInterceptor,
    ProcessReloadPermissionHeaderInterceptor,
    SetRequestHeadersInterceptor,
} from '.';

const albHttpInterceptorProviders = [{
    provide: ALB_HTTP_INTERCEPTORS,
    useClass: EncodeQueryParamsInterceptor,
    multi: true,
}, {
    provide: ALB_HTTP_INTERCEPTORS,
    useClass: SetRequestHeadersInterceptor,
    multi: true,
}, {
    provide: ALB_HTTP_INTERCEPTORS,
    useClass: ProcessReloadPermissionHeaderInterceptor,
    multi: true,
}, {
    provide: ALB_HTTP_INTERCEPTORS,
    useClass: HttpErrorHandlingInterceptor,
    multi: true,
}];

/**
 * @description Module to use AlbHttpClient.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    providers: [
        AlbHttpClient,
        albHttpInterceptorProviders,
    ],
})
export class HttpModule {}
