/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'path-match-config';
const prefix = `${moduleName}.${componentName}`;

export const criteriaInputLabel = `${prefix}.criteriaInputLabel`;
export const criteriaInputPlaceholder = `${prefix}.criteriaInputPlaceholder`;
export const stringGroupInputLabel = `${prefix}.stringGroupInputLabel`;
export const customStringInputLabel = `${prefix}.customStringInputLabel`;
export const matchCaseInputLabel = `${prefix}.matchCaseInputLabel`;

export const ENGLISH = {
    [criteriaInputLabel]: 'Criteria',
    [criteriaInputPlaceholder]: 'Select Criteria',
    [stringGroupInputLabel]: 'String Group or Custom String',
    [customStringInputLabel]: 'Custom String',
    [matchCaseInputLabel]: 'Match Case',
};
