/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-service-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleAppDomainName = `${prefix}.columnTitleAppDomainName`;
export const columnTitleEnableState = `${prefix}.columnTitleEnableState`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnSitePersistenceStatus = `${prefix}.columnSitePersistenceStatus`;
export const gslbSiteLeaderMessage = `${prefix}.gslbSiteLeaderMessage`;
export const gslbConfigPermissionsMessage = `${prefix}.gslbConfigPermissionsMessage`;
export const enableTitle = `${prefix}.enableTitle`;
export const disableTitle = `${prefix}.disableTitle`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleAppDomainName]: 'App Domain Name',
    [columnTitleEnableState]: 'Enable State',
    [columnTitleStatus]: 'Status',
    [columnSitePersistenceStatus]: 'Site Persistence Status',
    [gslbSiteLeaderMessage]: 'GSLB Site "{0}" is the leader',
    [gslbConfigPermissionsMessage]: 'GSLB Config permissions must be set to read or write to create a GSLB Service',
    [enableTitle]: 'Enable',
    [disableTitle]: 'Deactivate',
};
