<avi-dropdown
    [name]="name"
    [placeholder]="placeholder"
    [options]="enumValues"
    [(ngModel)]="modelValue"
    (ngModelChange)="handleChange()"
    [hiddenValues]="hiddenEnumValues"
    [disabled]="disabled"
    [required]="required"
    [prepend]="prepend"
    [multiple]="multiple"
    [maxSelectableOptions]="maxSelectableOptions"
    [readonly]="readonly"
></avi-dropdown>
