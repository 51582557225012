/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name ChartSync
 * @description
 *      Angular Factory for ChartSync class.
 */
//TODO @m ChartSync should keep hovered timestamp and emit appropriate events
angular.module('charts').factory('ChartSync', [
'EventEmitter2', 'Chart', function(EventEmitter2, Chart) {
    /**
     * @typedef {Chart|Axis} ISync
     */

    /**
     * Utility class helps to sync chart states across multiple charts.
     */
    class ChartSync extends EventEmitter2 {
        constructor() {
            super();

            /** @type {ISync[]} */
            this.syncs = [];

            this.syncValue = this.syncValue.bind(this);
            this.onMouseExit = this.onMouseExit.bind(this);
            this.onPause = this.onPause.bind(this);
            this.onResume = this.onResume.bind(this);
        }

        /**
         * Syncs values across all syncable instances.
         * @param {number|string} value
         */
        syncValue(value) {
            this.syncs.forEach(sc => sc.syncValue(value));
        }

        /**
         * Handles "mouseout" event.
         */
        onMouseExit() {
            this.syncs.forEach(sc => sc.hideSync());
        }

        /**
         * Handles pause event.
         */
        onPause() {
            this.syncs.forEach(sc => sc.pauseSync());
        }

        /**
         * Handles resume event.
         */
        onResume() {
            this.syncs.forEach(sc => sc.resumeSync(false));
        }

        /**
         * Adds chart to be synced with other charts.
         * @param {ISync} sync
         */
        add(sync) {
            if (sync instanceof Chart) {
                sync.on(Chart.GUIDE_UPDATE, this.syncValue);
                sync.on(Chart.MOUSE_EXIT, this.onMouseExit);
                sync.on(Chart.PAUSED, this.onPause);
                sync.on(Chart.RESUMED, this.onResume);
            }

            this.syncs.push(sync);
        }

        /**
         * Removes chart from syncing with other charts.
         * @param {ISync} sync
         * @returns {boolean} - True if chart was removed.
         */
        remove(sync) {
            const i = this.syncs.indexOf(sync);

            if (i > -1) {
                const sc = this.syncs.splice(i, 1)[0];

                if (sc instanceof Chart) {
                    sc.off(Chart.GUIDE_UPDATE, this.syncValue);
                    sc.off(Chart.MOUSE_EXIT, this.onMouseExit);
                    sc.off(Chart.PAUSED, this.onPause);
                    sc.off(Chart.RESUMED, this.onResume);
                }

                return true;
            }

            return false;
        }

        /**
         * Removes all charts from sync.
         */
        destroy() {
            for (let i = 0; i < this.syncs.length; i++) {
                if (this.remove(this.syncs[i])) {
                    i--;
                }
            }
        }
    }

    return ChartSync;
}]);
