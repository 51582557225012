/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AzureServicePrincipalCredentialsConfigItem,
    AzureUserPassCredentialsConfigItem,
    CloudConnectorUser,
    CloudConnectorUserCollection,
    GCPCredentialsConfigItem,
    NsxtCredentialsConfigItem,
    VCenterCredentialsConfigItem,
} from '.';

import { CLOUD_CONNECTOR_USER_COLLECTION_TOKEN } from './cloud-connector-user.tokens';

const cloudConnectorUserModule = angular.module('avi/cloud-connector-user');
const factories = [
    {
        dependency: AzureUserPassCredentialsConfigItem,
        name: 'AzureUserPassCredentialsConfigItem',
    },
    {
        dependency: AzureServicePrincipalCredentialsConfigItem,
        name: 'AzureServicePrincipalCredentialsConfigItem',
    },
    {
        dependency: GCPCredentialsConfigItem,
        name: 'GCPCredentialsConfigItem',
    },
    {
        dependency: NsxtCredentialsConfigItem,
        name: 'NsxtCredentialsConfigItem',
    },
    {
        dependency: VCenterCredentialsConfigItem,
        name: 'VCenterCredentialsConfigItem',
    },
    {
        dependency: CloudConnectorUser,
        name: 'CloudConnectorUser',
    },
    {
        dependency: CloudConnectorUserCollection,
        name: CLOUD_CONNECTOR_USER_COLLECTION_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(cloudConnectorUserModule, 'factory', name, dependency);
});
