/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'controller-interface';

export const controllerInterfaceBreadcrumbTitle = `${prefix}.controllerInterfaceBreadcrumbTitle`;

export const ENGLISH = {
    [controllerInterfaceBreadcrumbTitle]: 'Controller Interface',
};
