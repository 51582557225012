/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Escape specific characters in requests to the backend.
 * @param {string|number} query - Query (or any string) that needs to have characters escaped.
 * @param {string} escapeChar - Character to escape.
 * @example
 * Double-quotes fail if used in a request for logs:
 * /api/analytics/logs?filter=co(all,"""") will fail. These double-quotes need to be escaped:
 * /api/analytics/logs?filter=co(all,\"\"\"\")
 */
angular.module('aviApp').constant('escapeCharacter', (query, escapeChar) => {
    const escapeRegex = new RegExp(escapeChar, 'g');

    return query.toString().replace(escapeRegex, `\\${escapeChar}`);
});
