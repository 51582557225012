/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    GSLB,
} from 'items/gslb.item.factory';

export type TGSLBCollection = typeof GSLBCollection;

/**
 * @description
 *
 *     Collection which is supposed to have at most one item. Used to load it or create a new
 *     one using default configuration.
 *
 * @author Alex Malitsky, Ram Pal, Hitesh Mandav
 */
export class GSLBCollection extends Collection {
    public static ngDependencies = [
        GSLB,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslb',
            defaultViewportSize: 1,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(GSLB);

        this.serverDefaultsOverride_ = {
            name: 'Default',
        };
    }
}
