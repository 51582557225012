<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid && !(this.editable.config.crl_check && !this.editable.config.crl_file_refs?.length)"
    [modified]="form.touched && form.dirty"
    class="pki-profile-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ globalL10nKeys.nameLabel | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-checkbox-container-group>
                    <avi-checkbox
                        objectType="{{ objectType }}"
                        fieldName="ignore_peer_chain"
                        [(ngModel)]="editable.config.ignore_peer_chain"
                        name="ignore-peer-chain"
                    >
                        {{ l10nKeys.ignorePeerChainCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    
                    <avi-checkbox
                        [disabled]="!!editable.id"
                        objectType="{{ objectType }}"
                        fieldName="is_federated"
                        [(ngModel)]="editable.config.is_federated"
                        (ngModelChange)="onIsFederatedChange()"
                        name="is-federated"
                    >
                        {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                    </avi-checkbox>
                </avi-checkbox-container-group>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.certificateAuthorityHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.certificateAuthorityHeader | vtranslate }}</h3>
                <avi-alert-group alertType="warning">
                    <span>{{ l10nKeys.caWarningInfo | vtranslate }}</span>
                </avi-alert-group>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>{{ l10nKeys.certificateAuthoritiesLabel | vtranslate : editable.pkiCertificateAuthority.config.length }}</h6>
                        <pki-profile-certificate-authority-grid
                            [pkiCertificateAuthority]="editable.pkiCertificateAuthority"
                            (onAddCertificateAuthority)="onAddCertificateAuthority()"
                            (onRemoveCertificateAuthority)="removeCertificateAuthority($event)"
                        ></pki-profile-certificate-authority-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.certificateRevocationListLabel | vtranslate }}"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.certificateRevocationListLabel | vtranslate }}</h3>

                <avi-checkbox
                    objectType="{{ objectType }}"
                    fieldName="crl_check"
                    [(ngModel)]="editable.config.crl_check"
                    name="crl-check"
                >
                    {{ l10nKeys.enableCrlCheckCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-checkbox
                    objectType="{{ objectType }}"
                    fieldName="validate_only_leaf_crl"
                    [(ngModel)]="editable.config.validate_only_leaf_crl"
                    name="validate-only-leaf-crl"
                >
                    {{ l10nKeys.leafValidationCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="crl_file_uuids"
                            for="crl-file-uuids"
                            [required]="editable.config.crl_check"
                        >
                            {{ l10nKeys.certificateRevocationListLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            id="crl-file-uuids"
                            [collection]="fileObjectCollection"
                            [(ngModel)]="editable.config.crl_file_refs"
                            [actions]="crlDropdownActions"
                            [multiple]="true"
                            placeholder="{{ l10nKeys.certificateRevocationListPlaceholder | vtranslate }}"
                            name="crl-file-uuids"
                            helperText="{{ l10nKeys.crlHelperText | vtranslate }}"
                            [required]="editable.config.crl_check"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="allow_pki_errors"
                            for="allow-pki-errors"
                        >
                            {{ l10nKeys.allowedPKIErrors | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="allow-pki-errors"
                            enum="AllowedPKIErrors"
                            [(ngModel)]="editable.config.allow_pki_errors"
                            [multiple]="true"
                            [maxSelectableOptions]="1"
                        ></avi-enum-dropdown>
                    </div>
                </div>

            </div>
        </full-modal-tab-section>

    </form>
</full-modal-config>
