/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    ActionGroupConfigCollection,
} from 'ajs/modules/alert/factories/action-group-config/action-group-config.collection.factory';

import { SchemaService } from 'ajs/modules/core/services';
import { StringService } from 'string-service';
import * as globalL10n from 'global-l10n';
import * as l10n from './alert-actions-list-page.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TActionGroupConfigCollection = typeof ActionGroupConfigCollection;

/**
 * @description Alert actions list page component.
 *
 * @author Hitesh Mandav.
 */
@Component({
    selector: 'alert-actions-list-page',
    templateUrl: './alert-actions-list-page.component.html',
})
export class AlertActionsListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Configuration object to display alert actions
     */
    public alertActionsGridConfig: IAviCollectionDataGridConfig;

    /**
     * ActionGroupConfigCollection instance.
     */
    private readonly actionGroupConfigCollection: ActionGroupConfigCollection;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly schemaService: SchemaService,
        @Inject(ActionGroupConfigCollection)
        ActionGroupConfigCollection: TActionGroupConfigCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.actionGroupConfigCollection = new ActionGroupConfigCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.actionGroupConfigCollection;

        this.alertActionsGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.actionGroupConfigCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
                {
                    id: 'level',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAlertLevel),
                    transform: (row: IAviCollectionDataGridRow) =>
                        this.schemaService.getEnumValueLabel(
                            'AlertLevel',
                            row.getConfig().level,
                        ),
                },
                {
                    id: 'syslog_config_ref',
                    label: this.l10nService.getMessage(globalL10nKeys.syslogLabel),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.getConfig().syslog_config_ref ?
                            this.stringService.name(row.getConfig().syslog_config_ref) :
                            this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    },
                },
                {
                    id: 'email_config_ref',
                    label: this.l10nService.getMessage(globalL10nKeys.emailLabel),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.getConfig().email_config_ref ?
                            this.stringService.name(row.getConfig().email_config_ref) :
                            this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    },
                },
                {
                    id: 'snmp_trap_profile_ref',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleSNMPTrap),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.getConfig().snmp_trap_profile_ref ?
                            this.stringService.name(row.getConfig().snmp_trap_profile_ref) :
                            this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    },
                },
                {
                    id: 'action_script_config_ref',
                    label: this.l10nService.getMessage(globalL10nKeys.controlScriptLabel),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.getConfig().action_script_config_ref ?
                            this.stringService.name(row.getConfig().action_script_config_ref) :
                            this.l10nService.getMessage(globalL10nKeys.emptyLabel);
                    },
                },
                {
                    id: 'external_only',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleExternalOnly),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.getConfig().external_only ?
                            this.l10nService.getMessage(globalL10nKeys.yesValueLabel) :
                            this.l10nService.getMessage(globalL10nKeys.noValueLabel);
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.actionGroupConfigCollection.destroy();
    }
}
