/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleQueryTypeMatchConfig
 * @description DnsPolicyRuleQueryTypeMatch ConfigItem class.
 */
const MatchConfigController = (MatchConfigItem, schemaService) => {
    class DnsPolicyRuleQueryTypeMatchConfig extends MatchConfigItem {
        /** @override */
        beforeEdit() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || 'IS_IN';
            config.query_type = config.query_type || [];
        }

        /** @override */
        toString() {
            const
                {
                    query_type: queryType,
                    match_criteria: matchCriteria,
                } = this.getConfig(),
                value = schemaService.getEnumValue('MatchOperation', matchCriteria).label,
                modifiedValue = value.replace(' in', '').toLowerCase(),
                queries = queryType.map(query => query.enumeration('DNS_RECORD_'));

            return `${modifiedValue} "${queries.join('", "').toUpperCase()}"`;
        }
    }

    angular.extend(DnsPolicyRuleQueryTypeMatchConfig.prototype, {
        type: 'DnsQueryTypeMatch',
        name: 'Query Type',
        defaultPath: 'dnsrule.match.query_type',
    });

    return DnsPolicyRuleQueryTypeMatchConfig;
};

MatchConfigController.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('DnsPolicyRuleQueryTypeMatchConfig', MatchConfigController);
