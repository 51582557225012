<div class="avi-dropdown-options clr-wrapper">
    <cdk-virtual-scroll-viewport
        class="avi-dropdown-options__virtual-scroll-container"
        [hidden]="hideIfNoOptions && !options.length && !busy && !errorMessage"
        [style.height.px]="height"
        [style.width.px]="width"
        itemSize="{{ optionHeight }}"
        minBufferPx="{{ optionHeight * 4 }}"
        maxBufferPx="{{ optionHeight * 7 }}"
        (scrolledIndexChange)="handleScrollIndexChange($event)"
    >
        <avi-dropdown-option
            *cdkVirtualFor="let option of options; index as i; trackBy: trackByValue"
            class="avi-dropdown-options__option"
            (click)="handleSelect(option)"
            [value]="option.value"
            label="{{ getOptionLabel(option) }}"
            tooltipText="{{ option.tooltip }}"
            [optionTemplateRef]="optionTemplateRef"
            (onMouseEnter)="handleMouseEnter(i)"
            (onMouseMove)="handleMouseMove(i)"
            [ngClass]="{ 'avi-dropdown-options__option--selected': isSelected(option),
                'avi-dropdown-options__option--selectable': !isSelected(option),
                'avi-dropdown-options__option--highlighted': isHighlighted(i) }"
        ></avi-dropdown-option>
        <div
            class="avi-dropdown-options__option avi-dropdown-options__option--centered"
            *ngIf="busy"
        >
            <clr-spinner clrInline></clr-spinner>
        </div>
        <div
            class="avi-dropdown-options__option avi-dropdown-options__option--centered"
            [hidden]="options.length || errorMessage || busy"
        >
            -- {{ (noEntriesMessage || l10nKeys.noValidEntriesLabel) | vtranslate }} --
        </div>
        <div
            class="avi-dropdown-options__option avi-dropdown-options__option--centered"
            *ngIf="errorMessage"
        >
            -- {{ errorMessage | vtranslate }} --
        </div>
    </cdk-virtual-scroll-viewport>
</div>
