<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderCreate) | vtranslate }}"
    modalSubTitle="{{ tenantName }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="busy"
    [errors]="errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="tenant-ref"
                            required
                        >
                            {{ globalL10nKeys.tenantLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="tenantCollection"
                            [disableCreate]="true"
                            [disableEdit]="true"
                            [(ngModel)]="tenantRoleConfig.tenant_ref"
                            [readonly]="isEditing"
                            placeholder="{{ l10nKeys.tenantPlaceholder | vtranslate }}"
                            name="tenant-ref"
                            id="tenant-ref"
                            required
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label>
                            <avi-data-grid_header>
                                <h6>
                                    {{ l10nKeys.gridTitleRoles | vtranslate : tenantRoleConfig.role_refs?.length || 0 }}
                                </h6>
                            </avi-data-grid_header>
                        </label>
                        <avi-repeated-collection-dropdowns-grid
                            [excludeSelected]="true"
                            [collection]="roleCollection"
                            [(ngModel)]="tenantRoleConfig.role_refs"
                            columnLabel="{{ globalL10nKeys.rolesLabel | vtranslate }}"
                            placeholder="{{ globalL10nKeys.rolesPlaceholder | vtranslate }}"
                            name="role-refs"
                            required
                        ></avi-repeated-collection-dropdowns-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
