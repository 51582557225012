<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        #form="ngForm"
        [clrLayout]="verticalLayout"
    >
        <div class="full-modal-config__section">
            <avi-input-container *ngIf="editable.id">
                <label
                    aviLabelWithTooltip
                    fieldName="date"
                    for="date"
                    objectType="{{ objectType }}"
                >
                    {{ l10nKeys.timestampLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    readonly
                    id="date"
                    name="date"
                    value="{{ editable.getConfig().date | date:'MMM d, y h:mm a' }}"
                />
            </avi-input-container>

            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="name"
                    for="name"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ l10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    [(ngModel)]="editable.getConfig().name"
                    clrInput
                    id="name"
                    class="sel-name"
                    name="name"
                    placeholder="{{ l10nKeys.nameLabel | vtranslate }}"
                    regexPattern="objNameExcludeSpaces"
                    required
                />
            </avi-input-container>

            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    fieldName="description"
                    for="description"
                    objectType="{{ objectType }}"
                >
                    {{ l10nKeys.descriptionLabel | vtranslate }}
                </label>
                <textarea
                    [(ngModel)]="editable.getConfig().description"
                    id="description"
                    name="description"
                    placeholder="{{ l10nKeys.descriptionLabel | vtranslate }}"
                ></textarea>
            </avi-textarea-container>
        </div>
    </form>
</full-modal-config>
