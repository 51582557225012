<avi-state-page class="backup-page">
    <avi-state-page_main-content>
        <avi-page-header
            headerText="{{ l10nKeys.configurationBackupLabel | vtranslate }}"
            [showEditButton]="false"
        >
            <avi-page-header_edit-button>
                <cds-button
                    action="flat"
                    (click)="scheduler.edit()"
                    *permissionCheck="'PERMISSION_CONTROLLER:WRITE_ACCESS'"
                >
                    {{ l10nKeys.editButtonLabel | vtranslate }}
                </cds-button>
            </avi-page-header_edit-button>
        </avi-page-header>
        <div class="backup-page__card-container">
            <backup-overview-card
                class="backup-page__backup-overview-card"
                [scheduler]="scheduler"
            ></backup-overview-card>
            <local-backup-card
                class="backup-page__local-backup-card"
                [scheduler]="scheduler"
            ></local-backup-card>
            <remote-backup-card
                class="backup-page__remote-backup-card"
                [scheduler]="scheduler"
            ></remote-backup-card>
        </div>
        <div class="backup-page__collection-grid">
            <avi-collection-data-grid
                [config]="backupsGridConfig"
            ></avi-collection-data-grid>
        </div>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #localBackupFieldTemplateRef
    let-row
>
    <a
        *ngIf="row.getConfig().local_file_url; else elseBlock"
        (click)="row.downloadBackup('local')"
    >
        {{ row.getConfig().local_file_url }}
    </a>
    <ng-template #elseBlock>
        <span> {{ l10nKeys.emptyDataObject | vtranslate }} </span>
    </ng-template>
</ng-template>
