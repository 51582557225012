/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import {
    Inject,
    Injectable,
    OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
    IUIProperty,
    Timeframe,
    TTimeframe,
} from 'ng/root-store/user-preferences/user-preferences.state';

import {
    selectLanguage,
    selectTimeframe,
    selectUIProperty,
    selectUtcTime,
    selectValuesToDisplay,
} from 'ng/root-store/user-preferences/user-preferences.selectors';

/**
 * @description
 * This service is mainly used for the deprecation of the original MyAccount service. Older
 * components and services can use this service to retrieve uiProperty properties, while new
 * components should use ngrx to access uiProperty.
 * @author alextsg
 */
@Injectable()
export class MyAccountService implements OnDestroy {
    public uiProperty: IUIProperty = null;

    public useUtcTime: IUIProperty['useUTCTime'] = false;

    public language: IUIProperty['language'] = 'en_US';

    public valuesToDisplay: IUIProperty['valuesToDisplay'] = 'avg';

    public grid: IUIProperty['grid'] = null;

    public timeframe: TTimeframe = Timeframe.PAST_SIX_HOURS;

    public sideRailOpen = true;

    private ngDestroyed$ = new Subject();

    constructor(
        private readonly store: Store,
        // Using any instead of importing Timeframe because importing Timeframe causes circular
        // dependency issues as initAjsDependency is called directly in Timeframe. Timeframe will
        // be migrated with AV-179217
        @Inject('Timeframe')
        private readonly timeframeService: any,
    ) {
        this.store.select(selectLanguage)
            .pipe(skip(1), takeUntil(this.ngDestroyed$))
            .subscribe(language => this.language = language);

        this.store.select(selectValuesToDisplay)
            .pipe(skip(1), takeUntil(this.ngDestroyed$))
            .subscribe(valuesToDisplay => this.valuesToDisplay = valuesToDisplay);

        this.store.select(selectUIProperty)
            .pipe(skip(1), takeUntil(this.ngDestroyed$))
            .subscribe(uiProperty => {
                this.uiProperty = uiProperty;
                this.grid = uiProperty.grid;
            });

        this.store.select(selectUtcTime)
            .pipe(skip(1), takeUntil(this.ngDestroyed$))
            .subscribe(useUtcTime => this.useUtcTime = useUtcTime);

        this.store.select(selectTimeframe)
            .pipe(skip(1), takeUntil(this.ngDestroyed$))
            .subscribe(timeframe => {
                this.timeframe = timeframe;

                this.timeframeService.setDefaultValue(this.uiProperty.timeframe);
                this.timeframeService.set(this.uiProperty.timeframe);
            });
    }

    /**
     * @override
     * Completes the ngDestroyed$ stream to destroy existing streams.
     */
    public ngOnDestroy(): void {
        this.ngDestroyed$.next();
        this.ngDestroyed$.complete();
    }

    /**
     * Sets the side rail by value.
     */
    public setSideRailOpen(open = false): void {
        this.sideRailOpen = open;
    }

    /**
     * Toggles the side rail by existing value.
     */
    public toggleSidebar(): void {
        this.sideRailOpen = !this.sideRailOpen;
    }
}
