/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    GslbGeoLocationSource,
    IGeoLocation,
    IGslbGeoLocation,
} from 'generated-types';

import { GslbGeoLocation } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

type TGslbGeoLocationPartial = Omit<IGslbGeoLocation, 'location'>;

interface IGslbGeoLocationConfig extends TGslbGeoLocationPartial {
    location: MessageItem<IGeoLocation>;
}

/**
 * @description GslbGeoLocation ConfigItem class.
 *
 * @author Hitesh Mandav
 */
export class GslbGeoLocationConfigItem extends MessageItem<IGslbGeoLocationConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbgeolocation',
            objectType: GslbGeoLocation,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Set geo location source.
     */
    public setSource(source: GslbGeoLocationSource): void {
        this.mergeConfig({
            source,
        });
    }

    /**
     * Return true if atleast one of the location configuration is defined.
     */
    public isLocationConfigured(): boolean {
        return Object.values(this.config.location.config).some(value => value !== undefined);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'location',
        ];
    }
}
