/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auto-scale';
const componentName = 'pool-group-deployment-policy-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleTargetTrafficRatio = `${prefix}.columnTitleTargetTrafficRatio`;
export const columnTitleDuration = `${prefix}.columnTitleDuration`;
export const columnTitleRampupRatio = `${prefix}.columnTitleRampupRatio`;
export const columnTitleRules = `${prefix}.columnTitleRules`;

export const ENGLISH = {
    [headerLabel]: 'PoolGroup Deployment',
    [columnTitleTargetTrafficRatio]: 'Target Traffic Ratio',
    [columnTitleDuration]: 'Duration',
    [columnTitleRampupRatio]: 'Rampup Ratio',
    [columnTitleRules]: 'Rules',
};
