/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    PortMatchConfigItem,
} from 'ajs/modules/match/factories/port-match.config-item.factory';

import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component to show readonly information for Port Match.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'port-match-readonly',
    templateUrl: './port-match-readonly.component.html',
})
export class PortMatchReadonlyComponent implements OnInit {
    @Input()
    public editable: PortMatchConfigItem;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Used to display text label for selected match criteria in template.
     */
    public matchCriteria: string;

    constructor(private readonly schemaService: SchemaService) {}

    /** @override */
    public ngOnInit(): void {
        this.matchCriteria = this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.match_criteria,
        );
    }
}
