/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ip-or-group-list';
const prefix = `${moduleName}.${componentName}`;

export const addItemBtnLabel = `${prefix}.addItemBtnLabel`;
export const orDataLabel = `${prefix}.orDataLabel`;

export const ENGLISH = {
    [addItemBtnLabel]: '+ Add Item',
    [orDataLabel]: 'or',
};
