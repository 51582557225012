/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import './avi-alert-group.component.less';
import * as globalL10n from 'global-l10n';

const { ENGLISH: dictionary, ...globalL10nKeys } = globalL10n;

/**
 * @description
 *    Component can be used for displaying the alert messages
 *    Alert Type can be set as danger, warning, success, info
 *    Alert message can be set as string
 *
 *    If closable, the alert will show an "x" button.
 *    This component exposes onAlertClose Output for when that button is clicked.
 *    Parent component must close this component.
 *
 * @example
 *     <avi-alert-group alertType = "danger">
 *          <p>
 *               Some msg here
 *          </p>
 *           <p>
 *              Some msg here
 *           </p>
 *      </avi-alert-group>
 *
 * @author Shahab Hashmi, Alex Klyuev
 */

@Component({
    selector: 'avi-alert-group',
    templateUrl: './avi-alert-group.component.html',
})

export class AviAlertGroupComponent {
    /**
     * Input for deprecation message.
     */
    @Input()
    public deprecationLabel?: string;

    /**
     * Binding for hyperlink.
     */
    @Input()
    public hyperlink?: string;

    /**
     * Input for alert type
     */
    @Input()
    public alertType: string;

    /**
     * Boolean Input for close button
     */
    @Input()
    public closable = false;

    /**
     * Fires on alert closing.
     */
    @Output()
    public onAlertClose = new EventEmitter();

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Handle user closing the alert.
     */
    public closeAlert(): void {
        this.onAlertClose.emit();
    }

    /**
     * Open hyperlink in new tab.
     */
    public redirectToExternalLink(): void {
        window.open(this.hyperlink, '_blank');
    }
}
