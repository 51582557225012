<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        #form="ngForm"
        [clrLayout]="verticalLayout"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ l10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        [(ngModel)]="editable.config.name"
                        clrInput
                        id="name"
                        name="name"
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="description"
                        for="description"
                    >
                        {{ l10nKeys.descriptionInputLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ l10nKeys.descriptionInputPlaceholder | vtranslate }}"
                     ></textarea>
                </avi-textarea-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="level"
                            for="level"
                        >
                            {{ l10nKeys.levelInputLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="level"
                            [(ngModel)]="editable.config.level"
                            enum="AlertLevel"
                            placeholder="{{ l10nKeys.levelInputPlaceholder | vtranslate }}"
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="email_config_uuid"
                            for="email-config-uuid"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.emailInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="alertEmailConfigCollection"
                            [(ngModel)]="editable.config.email_config_ref"
                            placeholder="{{ l10nKeys.emailInputPlaceholder | vtranslate }}"
                            name="email_config_uuid"
                        ></avi-collection-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="syslog_config_uuid"
                            for="syslog-config-uuid"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.syslogLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="alertSyslogConfigCollection"
                            [(ngModel)]="editable.config.syslog_config_ref"
                            placeholder="{{ l10nKeys.syslogPlaceholder | vtranslate }}"
                            name="syslog_config_uuid"
                        ></avi-collection-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="snmp_trap_profile_uuid"
                            for="snmp-trap-profile-uuid"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.snmpTrapInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="snmpTrapProfileCollection"
                            [(ngModel)]="editable.config.snmp_trap_profile_ref"
                            placeholder="{{ l10nKeys.snmpTrapInputPlaceholder | vtranslate }}"
                            name="snmp_trap_profile_uuid"
                        ></avi-collection-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="action_script_config_uuid"
                            for="action-script-config-uuid"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.controlScriptInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="controlScriptCollection"
                            [(ngModel)]="editable.config.action_script_config_ref"
                            placeholder="{{ l10nKeys.controlScriptInputPlaceholder | vtranslate }}"
                            name="action_script_config_uuid"
                        ></avi-collection-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
