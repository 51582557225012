/**
 * @module Upgrade
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';
import {
    Image,
    ImageCollection,
    softwarePageOptions,
    softwareUploadCancelConfirmationOptions,
    UpgradeNode,
    UpgradeNodeCollection,
    UpgradeNodeCollectionDataSource,
    withUpgradeStatusMixin,
} from '.';

const upgradeModule = angular.module('avi/upgrade');

const components = [
    {
        name: 'softwarePage',
        options: softwarePageOptions,
    }, {
        name: 'softwareUploadCancelConfirmation',
        options: softwareUploadCancelConfirmationOptions,
    },
];

const factories = [
    {
        factory: Image,
        name: 'Image',
    }, {
        factory: ImageCollection,
        name: 'ImageCollection',
    }, {
        factory: UpgradeNode,
        name: 'UpgradeNode',
    }, {
        factory: UpgradeNodeCollection,
        name: 'UpgradeNodeCollection',
    }, {
        factory: UpgradeNodeCollectionDataSource,
        name: 'UpgradeNodeCollectionDataSource',
    },
];

const mixins = [
    {
        mixin: withUpgradeStatusMixin,
        name: 'withUpgradeStatusMixin',
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(upgradeModule, 'factory', name, factory);
});

mixins.forEach(({ name, mixin }) => {
    initAjsDependency(upgradeModule, 'factory', name, mixin);
});

components.forEach(({ name, options }) => upgradeModule.component(name, options));
