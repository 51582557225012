/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { skip, takeWhile } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';

import * as globalL10n from 'global-l10n';
import { ControllerInitialDataSelectors } from 'ng/root-store/controller-initial-data-store';

import { ControllerWidgetStore } from './controller-widget.store';
import * as l10n from './controller-widget.l10n';
import * as moduleL10n from '../../administration-dashboard.l10n';
import './controller-widget.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH: moduleDictionary, ...moduleL10nKeys } = moduleL10n;

/**
 * @description
 * Widget displaying Controller information. Contains both cluster data as well as controller
 * version data.
 *
 * @author alextsg
 */
@Component({
    selector: 'controller-widget',
    templateUrl: './controller-widget.component.html',
    providers: [ControllerWidgetStore],
})
export class ControllerWidgetComponent implements OnInit, OnDestroy {
    /**
     * Passed from DashboardWidgetsComponent. Call to redraw the dashboard layout once the widget
     * is done loading data.
     */
    @Output()
    public redrawLayout = new EventEmitter();

    public readonly loading$ = this.controllerWidgetStore.loading$;

    public readonly name$ = this.controllerWidgetStore.name$;

    public readonly clusterState$ = this.controllerWidgetStore.clusterState$;

    public readonly clusterVipAddressV4$ = this.controllerWidgetStore.clusterVipAddressV4$;

    public readonly clusterVipAddressV6$ = this.controllerWidgetStore.clusterVipAddressV6$;

    public readonly clusterVipRuntimeStatus$ = this.controllerWidgetStore.clusterVipRuntimeStatus$;

    public initialVersionData$ = this.rootStore.select(
        ControllerInitialDataSelectors.selectAboutAppData,
    );

    public initialLoading = false;

    public readonly l10nKeys = l10nKeys;

    public readonly moduleL10nKeys = moduleL10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly rootStore: Store,
        private readonly controllerWidgetStore: ControllerWidgetStore,
    ) {
        l10nService.registerSourceBundles({
            ...dictionary,
            ...moduleDictionary,
        });
    }

    /**
     * @override
     * Fetch controller data and subscribe to controllerWidgetStore.loading$ in order to track the
     * initial load. On initial load we display the loading message for the widget, while subsequent
     * loads just show a progress bar.
     */
    public ngOnInit(): void {
        this.controllerWidgetStore.loading$
            .pipe(
                // Skip the initial value of loading, which is false, so that any subsequent false
                // will be after a true value.
                skip(1),
                takeWhile((loading: boolean) => {
                    if (loading && !this.initialLoading) {
                        this.initialLoading = true;
                    }

                    if (!loading && this.initialLoading) {
                        this.initialLoading = false;
                        this.redrawLayout.emit();

                        return false;
                    }

                    return true;
                }),
            )
            .subscribe();

        this.controllerWidgetStore.fetchControllerData();
    }

    /**
     * @override
     * Cancel any ongoing requests for controller data.
     */
    public ngOnDestroy(): void {
        this.controllerWidgetStore.cancelFetchControllerData();
    }
}
