/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsModule
 */

import { L10nService } from '@vmw/ngx-vip';
import { IVHMatch } from 'generated-types';
import { VHMatch } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { VHMatchRuleConfigItem } from 'ajs/modules/vs/factories/vh-match-rule.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    VHMatchModalComponent,
} from 'ng/modules/virtual-service/components/vh-match-modal/vh-match-modal.component';

import * as l10n from '../vs.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IVHMatchConfig extends Omit<IVHMatch, 'rules'> {
    rules: RepeatedMessageItem<VHMatchRuleConfigItem>,
}

/**
 * @desc VHMatch MessageItem class.
 * @author Abhinesh Gour
 */
export class VHMatchConfigItem extends
    withEditChildMessageItemMixin<IVHMatchConfig, typeof MessageItem>(
        withFullModalMixin(MessageItem),
    )<IVHMatchConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: VHMatch,
            windowElement: VHMatchModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns rules from the config item
     */
    public get rules(): RepeatedMessageItem<VHMatchRuleConfigItem> {
        return this.config.rules;
    }

    /**
     * Returns configured rule names
     */
    public getRuleNames(): string[] {
        return this.rules.getConfig()
            .map((rule: VHMatchRuleConfigItem) => rule.getConfig().name) || [];
    }

    /**
     * Add a VHMatchRuleConfigItem.
     */
    public addVHMatchRule(): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                isEditing: false,
                ruleNames: this.getRuleNames(),
                ruleIndex: this.rules.count,
            },
        });
    }

    /**
     * Edit a VHMatchRuleConfigItem.
     */
    public editVHMatchRule(rule: VHMatchRuleConfigItem): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                isEditing: true,
                ruleNames: this.getRuleNames(),
                ruleIndex: this.rules.getArrayIndexWithMessageItem(rule),
            },
        });
    }

    /**
     * Delete a VHMatchRuleConfigItem.
     */
    public deleteVHMatchRule(rule: VHMatchRuleConfigItem): void {
        this.rules.removeByMessageItem(rule);
    }

    /**
     * Empty the Rules array as for add scenario, user should not see any rules set
     * for the newly created VHMatch.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IVHMatchConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            rules: [],
        };
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.virtualHostingModalBreadcrumbTitle);
    }
}
