/**
 * @module MatchModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Abhinesh Gour
 */

export const BOT_CLASS_MATCHER_CONFIG_ITEM_TOKEN = 'BotClassMatcherConfigItem';

export const BOT_TYPE_MATCHER_CONFIG_ITEM_TOKEN = 'BotTypeMatcherConfigItem';

/**
 *  Dependency token for MethodMatchConfigItem factory.
 */
export const METHOD_MATCH_CONFIG_ITEM_TOKEN = 'MethodMatchConfigItem';

/**
 * Dependency token for HTTPVersionMatchConfigItem factory.
 */
export const HTTP_VERSION_MATCH_CONFIG_ITEM_TOKEN = 'HTTPVersionMatchConfigItem';

/**
 * Dependency token for HostHeaderMatchConfigItem factory.
 */
export const HOST_HDR_MATCH_CONFIG_ITEM_TOKEN = 'HostHdrMatchConfigItem';

/**
 * Dependency token for PathOrQueryMatchBaseConfigItem base class for
 * PathMatchConfigItem & QueryMatchConfigItem factories.
 */
export const PATH_OR_QUERY_MATCH_BASE_CONFIG_ITEM_TOKEN = 'PathOrQueryMatchBaseConfigItem';

/**
 * Dependency token for PathMatchConfigItem factory.
 */
export const PATH_MATCH_CONFIG_ITEM_TOKEN = 'PathMatchConfigItem';

/**
 * Dependency token for QueryMatchConfigItem factory.
 */
export const QUERY_MATCH_CONFIG_ITEM_TOKEN = 'QueryMatchConfigItem';

/**
 * Dependency token for PortMatchConfigItem factory.
 */
export const PORT_MATCH_CONFIG_ITEM_TOKEN = 'PortMatchConfigItem';

/**
 * Dependency token for GenericHdrMatchBaseConfigItem base class for
 * HdrMatchConfigItem, HostHdrMatchConfigItem & CookieMatchConfigItem factories.
 */
export const GENERIC_HDR_MATCH_BASE_CONFIG_ITEM_TOKEN = 'GenericHdrMatchBaseConfigItem';

/**
 * Dependency token for HdrMatchConfigItem factory.
 */
export const HDR_MATCH_CONFIG_ITEM_TOKEN = 'HdrMatchConfigItem';

/**
 * Dependency token for CookieMatchConfigItem factory.
 */
export const COOKIE_MATCH_CONFIG_ITEM_TOKEN = 'CookieMatchConfigItem';

/**
 * Dependency token for StringMatchConfigItem factory.
 */
export const STRING_MATCH_CONFIG_ITEM_TOKEN = 'StringMatchConfigItem';

/**
 * Dependency token for AuthAttributeMatchConfigItem factory.
 */
export const AUTH_ATTRIBUTE_MATCH_CONFIG_ITEM_TOKEN = 'AuthAttributeMatchConfigItem';

/**
 * Dependency token for JwtMatchConfigItem factory.
 */
export const JWT_MATCH_CONFIG_ITEM_TOKEN = 'JWTMatchConfigItem';

/**
 * Dependency token for JwtClaimMatchConfigItem factory.
 */
export const JWT_CLAIM_MATCH_CONFIG_ITEM_TOKEN = 'JWTClaimMatchConfigItem';
