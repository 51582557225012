/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-pool-member-virtual-service';
const prefix = `${moduleName}.${componentName}`;

export const siteClusterControllerDropdownLabel = `${prefix}.siteClusterControllerDropdownLabel`;
export const siteClusterControllerDropdownPlaceholder = `${prefix}.siteClusterControllerDropdownPlaceholder`;
export const virtualServiceDropdownLabel = `${prefix}.virtualServiceDropdownLabel`;
export const virtualServiceDropdownPlaceholder = `${prefix}.virtualServiceDropdownPlaceholder`;
export const resolvedVsVipIPLabel = `${prefix}.resolvedVsVipIPLabel`;
export const resolvedAddressDropdownPlaceholder = `${prefix}.resolvedAddressDropdownPlaceholder`;

export const ENGLISH = {
    [siteClusterControllerDropdownLabel]: 'Site Cluster Controller',
    [siteClusterControllerDropdownPlaceholder]: 'Enter IP(v4/v6) Address or FQDN',
    [virtualServiceDropdownLabel]: 'Virtual Service',
    [virtualServiceDropdownPlaceholder]: 'Enter Virtual Service',
    [resolvedVsVipIPLabel]: 'Resolved IP(v4/v6) Address',
    [resolvedAddressDropdownPlaceholder]: 'Select Resolved IP(v4/v6) Address',
};
