/**
 * @module LogsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for logs-related components and factories.
 * @author alextsg
 */

export const L4_DATASCRIPT_LOG_COMPONENT_TOKEN = 'l4DatascriptLog';
