/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'pool-group-deployment-policy';

export const poolGroupDeploymentPolicyModalBreadcrumbTitle =
    `${prefix}.poolGroupDeploymentPolicyModalBreadcrumbTitle`;

export const ENGLISH = {
    [poolGroupDeploymentPolicyModalBreadcrumbTitle]: 'Pool Group Deployment Policy',
};
