/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN } from 'ajs/modules/alert';
import * as l10n from './syslog.controller.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export function syslogController(
    $scope,
    Collection,
    Regex,
    CRUDGridConfig,
    AviModal,
    l10nService,
    AlertSyslogConfigCollection,
) {
    $scope.Regex = Regex;

    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Get keys from source bundles for template usage
     */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new AlertSyslogConfigCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.columnTitleName),
            sortBy: 'name',
        },
        {
            name: 'data.config.syslog_server',
            title: l10nService.getMessage(l10nKeys.columnTitleSyslogServer),
            transform(row) {
                let ret = '';
                const cfg = row.data.config;

                if (cfg.syslog_servers) {
                    cfg.syslog_servers.config.forEach(function(server, index) {
                        if (index > 0) {
                            ret += ', ';
                        }

                        ret += `${server.getConfig().syslog_server}:` +
                        `${server.getConfig().syslog_server_port}`;
                    });
                } else {
                    ret = l10nService.getMessage(l10nKeys.emptySyslogServersLabel);
                }

                return ret;
            },
        },
    ];

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.singleactions.unshift({
        title: l10nService.getMessage(l10nKeys.actionBtnValidateSettings),
        class: 'icon-lostlog',
        do(row) {
            AviModal.open('adm-email-validate', {
                uuid: row.data.config.uuid,
                type: 'syslog',
            });
        },
    });

    $scope.addSyslogServer = function() {
        const cfg = $scope.editable.data.config;

        cfg.syslog_servers = cfg.syslog_servers || [];
        cfg.syslog_servers.config.push({});
    };

    $scope.removeSyslogServer = function(index) {
        $scope.editable.syslogServers.remove(index);
    };
}

syslogController.$inject = [
    '$scope',
    'Collection',
    'Regex',
    'CRUDGridConfig',
    'AviModal',
    'l10nService',
    ALERT_SYSLOG_CONFIG_COLLECTION_TOKEN,
];
