/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { NgForm, ValidationErrors } from '@angular/forms';

const INVALIDATE_FORM_ERROR = 'invalidateForm';

/**
 * @description
 *     This directive allows for invalidating a form based on a boolean. In Angular forms, disabled
 *     inputs are taken out of form validation checking, which might not be what we want. Ex. in
 *     cases where dropdown options are populated based on another field, the dropdown is
 *     disabled until that field has been filled in. Without this directive, the form would be valid
 *     even if the dropdown were required.
 * @example
 *     <form
 *         #myForm="ngForm"
 *         [invalidateForm]="connected"
 *     >
 *         <input
 *             type="text"
 *             [(ngModel)]="val"
 *             name="val"
 *         />
 *     </form>
 * @author alextsg
 */
@Directive({
    selector: '[invalidateForm]',
})
export class InvalidateFormDirective implements OnInit, OnChanges, OnDestroy {
    /**
     * If this condition is true, the form is invalidated.
     */
    @Input()
    public invalidateForm = false;

    constructor(private ngForm: NgForm) {}

    /** @override */
    public ngOnInit(): void {
        const { form } = this.ngForm;

        form.addValidators(this.validate);
        form.updateValueAndValidity();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { invalidateForm } = changes;

        if (invalidateForm && !invalidateForm.isFirstChange()) {
            this.ngForm.form.updateValueAndValidity();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        const { form } = this.ngForm;

        form.removeValidators(this.validate);
        form.updateValueAndValidity();
    }

    /**
     * Return an error object if the invalidateForm condition is true.
     */
    private validate = (): ValidationErrors | null => {
        return this.invalidateForm ? { [INVALIDATE_FORM_ERROR]: true } : null;
    };
}
