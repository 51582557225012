<div
    provideParentForm
    class="authentication-config"
>
    <avi-fieldset [hideContent]="!isRemoteAuthType">
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <avi-radio
                    name="auth-type"
                    id="local"
                    [value]="false"
                    [(ngModel)]="isRemoteAuthType"
                    (change)="setAdminAuthConfig()"
                >
                    {{ l10nKeys.localAuthType | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="auth-type"
                    id="remote"
                    [value]="true"
                    [(ngModel)]="isRemoteAuthType"
                    (change)="setAdminAuthConfig()"
                >
                    {{ l10nKeys.remoteAuthType | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>
        <avi-fieldset_content *ngIf="isRemoteAuthType">
            <clr-checkbox-container class="authentication-config__no-margin-top">
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        id="allow-local-user-login"
                        name="allow-local-user-login"
                        [(ngModel)]="adminAuthConfig.allow_local_user_login"
                        clrCheckbox
                    />
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectType"
                        fieldName="allow_local_user_login"
                        for="allow-local-user-login"
                    >
                        {{ l10nKeys.enableLocalUserLoginLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-data-grid_header>
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectType"
                            fieldName="remote_auth_configurations"
                            for="remote-auth-configurations-grid"
                        >
                            <h6>
                                {{ l10nKeys.mappingRulesGridHeader | vtranslate : adminAuthConfig.remote_auth_configurations.length }}
                            </h6>
                        </label>
                    </avi-data-grid_header>
                    <remote-auth-configurations-grid
                        id="remote-auth-configurations-grid"
                        [remoteAuthConfig]="remoteAuthConfig"
                        (onAddRemoteAuthConfig)="addRemoteAuthConfig()"
                        (onDeleteRemoteAuthConfig)="deleteRemoteAuthConfig($event)"
                        (onRowOrderChange)="handleRemoteAuthConfigGridRowReorder($event)"
                    ></remote-auth-configurations-grid>
                </div>
            </div>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
