/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'health-monitor-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleNew = `${prefix}.modalTitleNew`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const sendIntervalInputLabel = `${prefix}.sendIntervalInputLabel`;
export const sendIntervalInputPlaceholder = `${prefix}.sendIntervalInputPlaceholder`;
export const receiveTimeoutInputLabel = `${prefix}.receiveTimeoutInputLabel`;
export const receiveTimeoutInputPlaceholder = `${prefix}.receiveTimeoutInputPlaceholder`;
export const successfulChecksInputLabel = `${prefix}.successfulChecksInputLabel`;
export const successfulChecksInputPlaceholder = `${prefix}.successfulChecksInputPlaceholder`;
export const failedChecksInputLabel = `${prefix}.failedChecksInputLabel`;
export const failedChecksInputPlaceholder = `${prefix}.failedChecksInputPlaceholder`;
export const sectionTitleExternal = `${prefix}.sectionTitleExternal`;
export const sectionTitleRadius = `${prefix}.sectionTitleRadius`;
export const sectionTitleSip = `${prefix}.sectionTitleSip`;
export const sectionTitleTcp = `${prefix}.sectionTitleTcp`;
export const sectionTitleUdp = `${prefix}.sectionTitleUdp`;
export const sectionTitleImaps = `${prefix}.sectionTitleImaps`;
export const sectionTitleImap = `${prefix}.sectionTitleImap`;
export const sectionTitleServerMaintenanceMode = `${prefix}.sectionTitleServerMaintenanceMode`;
export const maintenanceResponseDataInputLabel = `${prefix}.maintenanceResponseDataInputLabel`;
export const maintenanceResponseDataInputPlaceholder = `${prefix}.maintenanceResponseDataInputPlaceholder`;
export const sectionTitleSctp = `${prefix}.sectionTitleSctp`;
export const tabTitleSmtp = `${prefix}.tabTitleSmtp`;
export const tabTitleSmtps = `${prefix}.tabTitleSmtps`;
export const sectionTitlePop3 = `${prefix}.sectionTitlePop3`;
export const tabTitlePop3s = `${prefix}.tabTitlePop3s`;
export const tabTitleLdap = `${prefix}.tabTitleLdap`;
export const tabTitleLdaps = `${prefix}.tabTitleLdaps`;
export const tabTitleFtp = `${prefix}.tabTitleFtp`;
export const tabTitleFtps = `${prefix}.tabTitleFtps`;
export const tabTitlePing = `${prefix}.tabTitlePing`;

export const ENGLISH = {
    [modalTitleNew]: 'Create Health Monitor',
    [modalTitleEdit]: 'Edit Health Monitor',
    [typeInputPlaceholder]: 'Select Type',
    [sendIntervalInputLabel]: 'Send Interval',
    [sendIntervalInputPlaceholder]: 'Enter Send Interval',
    [receiveTimeoutInputLabel]: 'Receive Timeout',
    [receiveTimeoutInputPlaceholder]: 'Enter Receive Timeout',
    [successfulChecksInputLabel]: 'Successful Checks',
    [successfulChecksInputPlaceholder]: 'Enter Successful Checks',
    [failedChecksInputLabel]: 'Failed Checks',
    [failedChecksInputPlaceholder]: 'Enter Failed Checks',
    [sectionTitleExternal]: 'External',
    [sectionTitleRadius]: 'Radius',
    [sectionTitleSip]: 'SIP',
    [sectionTitleTcp]: 'TCP',
    [sectionTitleUdp]: 'UDP',
    [sectionTitleImaps]: 'IMAPS',
    [sectionTitleImap]: 'IMAP',
    [sectionTitleServerMaintenanceMode]: 'Server Maintenance Mode',
    [maintenanceResponseDataInputLabel]: 'Maintenance Server Response Data',
    [maintenanceResponseDataInputPlaceholder]: 'Enter Response Data',
    [sectionTitleSctp]: 'SCTP',
    [tabTitleSmtp]: 'SMTP',
    [tabTitleSmtps]: 'SMTPS',
    [sectionTitlePop3]: 'POP3',
    [tabTitlePop3s]: 'POP3S',
    [tabTitleLdap]: 'LDAP',
    [tabTitleLdaps]: 'LDAPS',
    [tabTitleFtp]: 'FTP',
    [tabTitleFtps]: 'FTPS',
    [tabTitlePing]: 'Ping',
};
