/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ConnectedPosition } from '@angular/cdk/overlay';

const TOP = 'top';
const BOTTOM = 'bottom';
const START = 'start';
const END = 'end';

export enum AviDropdownButtonPosition {
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
}

// Valid positions for the dropdown menu.
export const BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION: ConnectedPosition = {
    originX: END,
    originY: BOTTOM,
    overlayX: END,
    overlayY: TOP,
};

export const BOTTOM_LEFT_ALIGNED_CONNECTED_POSITION: ConnectedPosition = {
    originX: START,
    originY: BOTTOM,
    overlayX: START,
    overlayY: TOP,
};

export const TOP_RIGHT_ALIGNED_CONNECTED_POSITION: ConnectedPosition = {
    originX: END,
    originY: TOP,
    overlayX: END,
    overlayY: BOTTOM,
};

export const TOP_LEFT_ALIGNED_CONNECTED_POSITION: ConnectedPosition = {
    originX: START,
    originY: TOP,
    overlayX: START,
    overlayY: BOTTOM,
};
