/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

export const WAF_ADD_EXCEPTION_DIALOG_FORM_VALIDATOR_KEY = 'wafAddExceptionDialogForm';

const errorObject = {
    [WAF_ADD_EXCEPTION_DIALOG_FORM_VALIDATOR_KEY]: true,
};

/**
 * @description
 *     Validator function to check that there are no empty exclude list entries in
 *     waf-add-exception-dialog.
 * @author alextsg
 */
export function wafAddExceptionDialogFormValidator(
    control: AbstractControl,
    count: number,
): ValidationErrors | null {
    let error = null;

    for (let i = 0; i < count; i++) {
        const formControls = [
            control.get(`exception-subnet-${i}`),
            control.get(`match-element-${i}`),
            control.get(`uri-path-${i}`),
        ];

        // If form elements are not ready yet, break out of the loop.
        if (formControls.some(formControl => !formControl)) {
            break;
        }

        // If every input in the row is undefined, return the error object to invalidate the form,
        // plus add an error on each of the input form controls. We don't want to overwrite any
        // existing errors.
        if (formControls.every(formControl => !formControl.value)) {
            error = errorObject;

            formControls.forEach(formControl => {
                const errors = formControl.errors || {};

                formControl.setErrors({
                    ...errors,
                    [WAF_ADD_EXCEPTION_DIALOG_FORM_VALIDATOR_KEY]: true,
                });
            });
        // If there's at least one populated input, return null as the form's error object, and the
        // existing error (if it exists) is removed from each input's form control.
        } else {
            formControls.forEach(formControl => {
                if (!formControl.errors) {
                    return;
                }

                const {
                    [WAF_ADD_EXCEPTION_DIALOG_FORM_VALIDATOR_KEY]: wafAddExceptionDialogForm,
                    ...errors
                } = formControl.errors;

                if (Object.keys(errors).length) {
                    formControl.setErrors(errors);
                } else {
                    formControl.setErrors(null);
                }
            });
        }
    }

    return error;
}
