/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IJWTServerProfile } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { JWTServerProfileModalComponent } from 'ng/modules/security';

export const jwtServerProfileToken = 'JWTServerProfile';

interface IJWTServerProfileData {
    config: IJWTServerProfile;
}

/**
 * @description
 *
 *   JWT Server Profile item.
 *
 * @author Rachit Aggarwal
 */
export class JWTServerProfile extends withFullModalMixin(ObjectTypeItem) {
    public data: IJWTServerProfileData;
    public getConfig: () => IJWTServerProfile;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'jwtserverprofile',
            objectType: jwtServerProfileToken,
            permissionName: 'PERMISSION_JWTSERVERPROFILE',
            windowElement: JWTServerProfileModalComponent,
        };

        super(extendedArgs);
    }

    /**
     * Returns type of the item object.
     */
    public get messageType(): string {
        return this.objectType;
    }
}
