/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'pool-group';
const componentName = 'pool-group-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const numberOfPoolsLabel = `${prefix}.numberOfPoolsLabel`;
export const poolNotUsedLabel = `${prefix}.poolNotUsedLabel`;

export const ENGLISH = {
    [headerLabel]: 'Pool Groups',
    [numberOfPoolsLabel]: 'Number of Pools',
    [poolNotUsedLabel]: 'Pool is not used by any VS',
};
