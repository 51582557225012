/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @desc
 *      Parses Subnet Base Ip values.
 *      Updates provided ip address with the base IP for passed network mask.
 *
 * @author Sarthak Kapoor
 */

import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Provider,
    Renderer2,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { parseSubnetBaseIp } from 'ng/modules/avi-forms/utils/subnet-base-ip.parser.utils';

const SUBNET_BASE_IP_DIRECTIVE_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SubnetBaseIpDirective),
};

@Directive({
    selector: `[subnetBaseIp][formControlName],
        [subnetBaseIp][formControl],
        [subnetBaseIp][ngModel]`,
    providers: [
        SUBNET_BASE_IP_DIRECTIVE_ACCESSOR,
    ],
})
export class SubnetBaseIpDirective implements ControlValueAccessor {
    public constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    /**
     * Listener for the input change event.
     * Parses Input value and calls onChange to proceed with the new model value.
     */
    @HostListener('focusout', ['$event.target.value'])
    private onInputChange(value: string): void {
        const parsedSubnet = parseSubnetBaseIp(value);

        this.onChange(parsedSubnet);
    }

    /**
     * **************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE.
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        if (value) {
            const { nativeElement: hostElement } = this.elementRef;

            this.renderer.setProperty(hostElement, 'value', value);
        }
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange: (value: string) => void = (value: string) => {};

    /**
     *  Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch: () => void = () => {};

    /**
     * **************************************************************************
     * ControlValueAccessor INTERFACE implementation ends.
     */
}
