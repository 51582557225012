<div
    class="full-modal-config__section ipam-dns-azure-profile-config"
    provideParentForm
    [invalidateForm]="!connected"
>
    <h3>{{ l10nKeys.azureSectionHeader | vtranslate }}</h3>
    <fieldset class="full-modal-config__fieldset">
        <h6 class="full-modal__section-header">{{ l10nKeys.credentialSectionHeader | vtranslate }}</h6>
        <credentials-verification
            [editCredentialsConfig]="editAzureProfileCredentialsConfig"
            [isEditing]="isEditing"
        >
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.IpamDnsAzureProfile }}"
                    fieldName="subscription_id"
                    for="subscription_id"
                >
                    {{ l10nKeys.subscriptionIdInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="subscription_id"
                    name="subscription_id"
                    [(ngModel)]="editable.config.subscription_id"
                    placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                    readonly
                />
            </avi-input-container>

            <ng-container *ngIf="azureCredentialsType === AzureCredentialsTypeEnum.USERPASS">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureUserPassCredentials }}"
                                fieldName="username"
                                for="username"
                            >
                                {{ l10nKeys.usernameInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="username"
                                name="username"
                                [(ngModel)]="editable.azureUserpass.config.username"
                                placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureUserPassCredentials }}"
                                fieldName="password"
                                for="password"
                            >
                                {{ l10nKeys.passwordInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="password"
                                type="password"
                                name="password"
                                [(ngModel)]="editable.azureUserpass.config.password"
                                placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AzureUserPassCredentials }}"
                        fieldName="tenant_name"
                        for="tenant_name"
                    >
                        {{ l10nKeys.tenantNameInputLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_name"
                        name="tenant_name"
                        [(ngModel)]="editable.azureUserpass.config.tenant_name"
                        placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
            
            <ng-container *ngIf="azureCredentialsType === AzureCredentialsTypeEnum.SERVICE_PRINCIPAL">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                                fieldName="application_id"
                                for="application_id"
                            >
                                {{ l10nKeys.applicationIdInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="application_id"
                                name="application_id"
                                [(ngModel)]="editable.azureServicePrincipal.config.application_id"
                                placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                                fieldName="authentication_token"
                                for="authentication_token"
                            >
                                {{ l10nKeys.authenticationTokenInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="password"
                                id="authentication_token"
                                name="authentication_token"
                                [(ngModel)]="editable.azureServicePrincipal.config.authentication_token"
                                placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                        fieldName="tenant_id"
                        for="tenant_id"
                    >
                        {{ l10nKeys.tenantIdInputLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_id"
                        name="tenant_id"
                        [(ngModel)]="editable.azureServicePrincipal.config.tenant_id"
                        placeholder="{{ l10nKeys.valueNotSetPlaceholder | vtranslate }}"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
        </credentials-verification>
    </fieldset>
</div>
