/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';
import {
    IpAddrGroupCollection,
} from 'ajs/modules/groups/factories/ip-addr-group/ip-addr-group.collection.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import {
    IpAddrGroupItem,
} from 'ajs/modules/groups/factories/ip-addr-group/ip-addr-group.item.factory';
import * as l10n from './ip-addr-group-page.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIpAddrGroupCollection = typeof IpAddrGroupCollection;

/**
 * @description Ip Group's list page.
 *
 * @author Pratik Pattanayak
 */
@Component({
    selector: 'ip-addr-group-page',
    templateUrl: './ip-addr-group-page.component.html',
})
export class IpAddrGroupListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display ip group collection.
     */
    public ipAddrGroupGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the IpAddrGroup collection.
     */
    private readonly ipAddrGroupCollection: IpAddrGroupCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        @Inject(IpAddrGroupCollection)
        IpAddrGroupCollection: TIpAddrGroupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.ipAddrGroupCollection = new IpAddrGroupCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.ipAddrGroupCollection;

        this.ipAddrGroupGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.ipAddrGroupCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }, {
                id: 'column-title-ip_addresses-or-ranges',
                label: this.l10nService.getMessage(l10nKeys.columnTitleIpAddressesOrRanges),
                transform: (row: IpAddrGroupItem) => {
                    return row.hasCountryCodes ?
                        this.l10nService.getMessage(l10nKeys.countryCodeDataLabel) :
                        row.ipAddrCount.toString();
                },
            }, {
                id: 'country-code',
                label: this.l10nService.getMessage(l10nKeys.columnTitleCountryCodesOrEpg),
                transform: (row: IpAddrGroupItem) => {
                    return row.hasCountryCodes ? row.getConfig().country_codes.join(', ') : '';
                },
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.emptyGridPlaceholder),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ipAddrGroupCollection.destroy();
    }
}
