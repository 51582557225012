/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const btnLabelRootIntermediateCaCert = `${prefix}.btnLabelRootIntermediateCaCert`;
export const btnLabelApplicationCert = `${prefix}.btnLabelApplicationCert`;
export const btnLabelControllerCert = `${prefix}.btnLabelControllerCert`;
export const columnTitleCommonName = `${prefix}.columnTitleCommonName`;
export const columnTitleIssuerName = `${prefix}.columnTitleIssuerName`;
export const columnTitleAlgorithm = `${prefix}.columnTitleAlgorithm`;
export const columnTitleSelfSigned = `${prefix}.columnTitleSelfSigned`;
export const columnTitleValidUntil = `${prefix}.columnTitleValidUntil`;
export const columnTitleSanDomainNames = `${prefix}.columnTitleSanDomainNames`;
export const actionBtnExport = `${prefix}.actionBtnExport`;
export const actionBtnRenew = `${prefix}.actionBtnRenew`;
export const sslTlsCertificatesHeader = `${prefix}.sslTlsCertificatesHeader`;
export const rootIntermediateCaHeader = `${prefix}.rootIntermediateCaHeader`;
export const awaitingCertificateValue = `${prefix}.awaitingCertificateValue`;
export const renewCertificateConfirmMessage = `${prefix}.renewCertificateConfirmMessage`;
export const renewCertificateHeader = `${prefix}.renewCertificateHeader`;
export const renewalSuccessMessage = `${prefix}.renewalSuccessMessage`;

export const ENGLISH = {
    [btnLabelRootIntermediateCaCert]: 'Root/Intermediate CA Certificate',
    [btnLabelApplicationCert]: 'Application Certificate',
    [btnLabelControllerCert]: 'Controller Certificate',
    [columnTitleCommonName]: 'Common Name',
    [columnTitleIssuerName]: 'Issuer Name',
    [columnTitleAlgorithm]: 'Algorithm',
    [columnTitleSelfSigned]: 'Self Signed',
    [columnTitleValidUntil]: 'Valid Until',
    [columnTitleSanDomainNames]: 'SAN Domain Names',
    [actionBtnExport]: 'Export',
    [actionBtnRenew]: 'Renew',
    [sslTlsCertificatesHeader]: 'SSL/TLS Certificates',
    [rootIntermediateCaHeader]: 'Root/Intermediate CA',
    [awaitingCertificateValue]: 'Awaiting Certificate',
    [renewCertificateConfirmMessage]: 'You have selected to renew certificate: {0}',
    [renewCertificateHeader]: 'Renew {0}',
    [renewalSuccessMessage]: 'Certificate {0} renewal completed successfully',
};
