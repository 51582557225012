<div
    class="internal-dns-service-domains-grid"
    provideParentForm
>
    <div 
        *ngIf="hasDuplicateError" 
        class="internal-dns-service-domains-grid__error-msg"
    >
        <avi-alert-group alertType="danger">
            <span>
                {{ l10nKeys.keyDuplicationErrorMsg | vtranslate }} <br>
                {{ l10nKeys.duplicateValuesErrorMsg | vtranslate : duplicateValues.join(', ')}}
            </span>
        </avi-alert-group>
    </div> 
    <avi-data-grid
        [config]="internalDnsServiceDomainsGrid"
        [rows]="dnsServiceDomains.config"
        [invalidateForm]="hasDuplicateError"
        gridTitle="{{ l10nKeys.dnsServiceDomainsGridHeader | vtranslate : dnsServiceDomains.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addDnsServiceDomain()"
                [disabled]="hasDuplicateError"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #domainNameTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    name="{{ internalDnsServiceDomainNamePrefix + index }}"
                    id="{{ internalDnsServiceDomainNamePrefix + index }}"
                    type="text"
                    clrInput
                    placeholder="{{ l10nKeys.domainNameInputPlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.domain_name"
                    (ngModelChange)="findDuplicateValues()"
                    regexPattern="dnsName"
                    required
                />
            </avi-input-container>
        </ng-template>
        <ng-template
            #recordTtlTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container
                noMarginTop
                helperText="{{ recordTtlRange }}"
            >
                <input
                    name="{{ internalDnsServiceDomainRecordTtlPrefix + index }}"
                    id="{{ internalDnsServiceDomainRecordTtlPrefix + index }}"
                    clrInput
                    type="number"
                    placeholder="{{ l10nKeys.recordTtlInputPlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.record_ttl"
                    configFieldInputValidation
                    objectType="{{ objectType }}"
                    fieldName="record_ttl"
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
