/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/***
 * Injected to Basic and Advanced VS Create Controllers
 */
export function virtualServiceCreateCommonController(
    $scope,
    Regex,
    ApplicationProfileCollection,
    NetworkProfileCollection,
    PoolCollection,
    Collection,
    CertificateCollection,
    Auth,
    CloudCollection,
    SubnetListNetworkCollection,
    IpAddrGroupCollection,
) {
    $scope.Auth = Auth;
    $scope.Regex = Regex;

    $scope.$parent.modalScope = $scope;//AviModal thing

    //references to the forms, to aviod ng-form names to be set only on it's own scope
    $scope.forms = {};

    $scope.cloudCollection = new CloudCollection({ isStatic: true });

    $scope.applicationProfileCollection = new ApplicationProfileCollection();
    $scope.networkProfileCollection = new NetworkProfileCollection();

    $scope.ipAddrGroupCollection = new IpAddrGroupCollection();

    // Pool collection that contains only free pools (not connected to any VS)
    $scope.poolCollection = new PoolCollection({
        isStatic: true,
        params: {
            referred_by: 'poolgroup:none',
        },
    });

    $scope.certificateCollection = new CertificateCollection({
        params: {
            type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
        },
    });

    // Used in select servers panel as a dropdown field
    $scope.networks = new SubnetListNetworkCollection({
        params: {
            discovered_only: true,
        },
    });

    /**
     * Collection for the network panel when 'Select Servers by Network' is selected.
     * Almost identical to networks collection except that it is never filtered by the
     * Elastic/Floating IP address option.
     * @type {Collection}
     */
    $scope.poolNetworks = new SubnetListNetworkCollection({
        overLimitCoeff: 0.5,
        params: {
            discovered_only: true,
        },
    });
}

virtualServiceCreateCommonController.$inject = [
    '$scope',
    'Regex',
    'ApplicationProfileCollection',
    'NetworkProfileCollection',
    'PoolCollection',
    'Collection',
    'CertificateCollection',
    'Auth',
    'CloudCollection',
    'SubnetListNetworkCollection',
    'IpAddrGroupCollection',
];
