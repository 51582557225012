/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import './avi-health-score-bar.component.less';

/**
 * @description Component for single health score.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'avi-health-score-bar',
    templateUrl: './avi-health-score-bar.component.html',
})
export class AviHealthScoreBarComponent {
    /**
     * Health score value for bar fill and to display the value.
     */
    @Input()
    public healthScore: number;

    /**
     * Health score Label.
     */
    @Input()
    public healthScoreLabel: string;

    /**
     * True if the label needs to be bold.
     */
    @Input()
    public isLabelBold = false;

    /**
     * True if the value is of type 'penalty'
     * Used to set the value as negetive and set penalty color.
     */
    @Input()
    public isPenalty = false;

    /**
     * Left margin for penalty progress bar.
     */
    @Input()
    public penaltyMargin = 0;
}
