<full-modal-config
    modalTitle="{{ l10nKeys.modalTitleAddCa | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="editable.config.text && form.valid"
    class="pki-certificate-authority-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            required
                        >
                            {{ l10nKeys.uploadCaFileInputLabel | vtranslate }}
                        </label>
                        <async-file-upload
                            name="certificate-authority-file"
                            [(ngModel)]="certificateAuthorityFileContent"
                            (ngModelChange)="handleFileSelectChange()"
                            (onFileUpload)="onCertificateAuthorityFileUpload()"
                            [maxFileSize]="10"
                            [decodeFile]="true"
                            required
                        ></async-file-upload>
                    </div>
                </div>
                <div
                    *ngIf="editable.config.text"
                    class="full-modal-config__form-control-row"
                >
                    <div class="full-modal-config__form-control">
                        <avi-textarea-container>
                            <textarea
                                clrTextarea
                                name="certificate-authority-text"
                                [(ngModel)]="editable.config.text"
                                rows="26"
                                disabled
                            ></textarea>
                        </avi-textarea-container>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
