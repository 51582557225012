/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export const L4_CONNECTION_POLICY_CONFIG_ITEM_TOKEN = 'L4ConnectionPolicyConfigItem';
export const L4_POLICY_SET_COLLECTION_TOKEN = 'L4PolicySetCollection';
export const L4_POLICY_SET_TOKEN = 'L4PolicySet';
export const L4_RULE_ACTION_CONFIG_ITEM_TOKEN = 'L4RuleActionConfigItem';
export const L4_RULE_ACTION_SELECT_POOL_CONFIG_ITEM_TOKEN = 'L4RuleActionSelectPoolConfigItem';
export const L4_RULE_CONFIG_ITEM_TOKEN = 'L4RuleConfigItem';
export const L4_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN = 'L4RuleMatchTargetConfigItem';
export const L4_RULE_PORT_MATCH_CONFIG_ITEM_TOKEN = 'L4RulePortMatchConfigItem';
export const L4_RULE_PROTOCOL_MATCH_CONFIG_ITEM_TOKEN = 'L4RuleProtocolMatchConfigItem';
