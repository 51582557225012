/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Component, Type } from '@angular/core';
import { GeoDbMappingElementModalComponent } from 'ng/modules/security';
import {
    IGeoDBMappingElement,
} from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './geo-db.l10n';

/**
 * Ajs dependency token for GeoDBMappingElementConfigItem.
 */
export const GEO_DB_MAPPING_ELEMENT_CONFIG_ITEM_TOKEN = 'GeoDBMappingElementConfigItem';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   Geo DB Mapping Element Message Item.
 *
 * @author Rachit Aggarwal
 */
export class GeoDbMappingElementConfigItem extends
    withFullModalMixin(MessageItem)<IGeoDBMappingElement> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'GeoDBMappingElement',
            windowElement: GeoDbMappingElementModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addGeoDbMappingElementModalBreadcrumbTitle);
    }
}

