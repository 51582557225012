/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'async-file-submit';
const prefix = `${moduleName}.${componentName}`;

export const uploadFileMessage = `${prefix}.uploadFileMessage`;
export const fileUploadSuccessMessage = `${prefix}.fileUploadSuccessMessage`;
export const selectText = `${prefix}.selectText`;
export const fileUploadError = `${prefix}.fileUploadError`;

export const ENGLISH = {
    [uploadFileMessage]: 'Uploading file',
    [fileUploadSuccessMessage]: 'The file was successfully uploaded.',
    [selectText]: 'Select From Computer',
    [fileUploadError]: 'File upload failed.',
};
