/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import {
    AviPermissionResource,
    ICSRFPolicy,
} from 'generated-types';
import { CSRFPolicy } from 'object-types';
import { ObjectTypeItem } from 'object-type-item';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    CSRFRuleConfigItem,
} from 'ng/modules/message-items/factories/csrf-rule.config-item.factory';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import * as l10n from './csrf-policy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type ICSRFPolicyPartial = Omit<ICSRFPolicy, 'rules'>;

interface ICSRFPolicyConfig extends ICSRFPolicyPartial {
    rules?: RepeatedMessageItem<CSRFRuleConfigItem>
}

interface ICSRFPolicyData {
    config: ICSRFPolicyConfig;
}

/**
 * @description CSRF Policy Item.
 * @author alextsg
 */
export class CSRFPolicyItem extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static ngDependencies = [L10nService];

    public data: ICSRFPolicyData;

    public l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: CSRFPolicy,
            objectName: 'csrfpolicy',
            permissionName: AviPermissionResource.PERMISSION_CSRFPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getNgDependency(L10nService);
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            CsrfPolicyModalComponent,
        } = await import(
            /* webpackChunkName: "csrf-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/csrf-policy-modal/csrf-policy-modal.component'
        );

        return CsrfPolicyModalComponent as Type<Component>;
    }

    /**
     * Remove CSRF rule from config.
     */
    public removeRule(rule: CSRFRuleConfigItem): void {
        this.config.rules.removeByMessageItem(rule);
    }

    /**
     * Add CSRF rule and open CSRF rule modal.
     */
    public addRule(): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                editMode: false,
            },
        });
    }

    /**
     * Edit CSRF rule and open CSRF rule modal.
     */
    public editRule(rule: CSRFRuleConfigItem): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                editMode: true,
            },
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.csrfPolicyModalBreadcrumbTitle);
    }
}
