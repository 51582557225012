/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-openstack-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleCustom = `${prefix}.sectionTitleCustom`;
export const customIpamDnsProfileInputLabel = `${prefix}.customIpamDnsProfileInputLabel`;
export const customIpamDnsProfileInputPlaceholder = `${prefix}.customIpamDnsProfileInputPlaceholder`;
export const customIpamProfileInputLabel = `${prefix}.customIpamProfileInputLabel`;
export const customIpamProfileInputPlaceholder = `${prefix}.customIpamProfileInputPlaceholder`;
export const usableDomainsGridLabel = `${prefix}.usableDomainsGridLabel`;
export const usableDomainLabel = `${prefix}.usableDomainLabel`;
export const scriptParamsGridLabel = `${prefix}.scriptParamsGridLabel`;
export const addScriptParamsLabel = `${prefix}.addScriptParamsLabel`;
export const sectionTitleCustomIPAM = `${prefix}.sectionTitleCustomIPAM`;
export const dynamicParamsGridLabel = `${prefix}.dynamicParamsGridLabel`;
export const testLoginLabel = `${prefix}.testLoginLabel`;
export const loginSuccessMessage = `${prefix}.loginSuccessMessage`;

export const ENGLISH = {
    [sectionTitleCustom]: 'Custom',
    [customIpamDnsProfileInputLabel]: 'Custom IPAM/DNS Profile',
    [customIpamDnsProfileInputPlaceholder]: 'Select Custom IPAM/DNS Profile',
    [customIpamProfileInputLabel]: 'Custom IPAM Profile',
    [customIpamProfileInputPlaceholder]: 'Select Custom IPAM Profile',
    [usableDomainsGridLabel]: 'Usable Domains ({0})',
    [usableDomainLabel]: 'Usable Domain',
    [scriptParamsGridLabel]: 'Script Params ({0})',
    [addScriptParamsLabel]: 'Add Script Params',
    [sectionTitleCustomIPAM]: 'Custom IPAM',
    [dynamicParamsGridLabel]: 'Dynamic Params ({0})',
    [testLoginLabel]: 'Test Login',
    [loginSuccessMessage]: 'Test Login Successful',
};
