<div class="vs-log-cinematic-top-info">
    <avi-card class="vs-log-cinematic-top-info__top-card">
        <avi-card_header class="vs-log-cinematic-top-info__top-card-header">
            <cds-icon
                shape="switch"
                size="30"
                status="success"
            ></cds-icon>
            <span class="vs-log-cinematic-top-info__top-card-header-text">
                {{ l10nKeys.endToEndTimingLabel | vtranslate }}
            </span>
        </avi-card_header>

        <avi-card_body class="vs-log-cinematic-top-info__top-card-body">
            <!-- L7 -->
            <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L7">
                <!--         Client -> Client RTT -> Security        -->
                <vs-log-cinematic-end-to-end-knot
                    headerText="{{ globalL10nKeys.clientLabel | vtranslate }}"
                    iconShape="user"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Bridge  -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--medium"
                    topHeader="{{ l10nKeys.clientRttLabel | vtranslate }}"
                    arrowColor="blue"
                    [endToEndTime]="clientRtt$ | async"
                    [endToEndTimeFilterProp]="'client_rtt'"
                    [statusCode]="responseCode$ | async"
                    [statusCodeFilterProp]="'response_code'"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <vs-log-cinematic-end-to-end-knot
                    class="vs-log-cinematic-top-info__top-card-knot--security"
                    headerText="{{ globalL10nKeys.securityLabel | vtranslate }}"
                    topHeaderColor="blue"
                    iconShape="shield"
                    iconStatus="info"
                    iconBadge="warning-triangle"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Load Balancer -->
                <vs-log-cinematic-end-to-end-knot
                    headerText="{{ globalL10nKeys.loadBalancerLabel | vtranslate }}"
                    iconShape="load-balancer"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Bridge  -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--medium"
                    topHeader="{{ l10nKeys.serverRttLabel | vtranslate }}"
                    arrowColor="green"
                    [endToEndTime]="serverRtt$ | async"
                    [endToEndTimeFilterProp]="'server_rtt'"
                    [statusCode]="serverResponseCode$ | async"
                    [statusCodeFilterProp]="'server_response_code'"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <!--      Server -> App Response -> App       -->
                <div class="vs-log-cinematic-top-info__top-card-box-section vs-log-cinematic-top-info__top-card-server-app-box">
                    <vs-log-cinematic-end-to-end-knot
                        headerText="{{ globalL10nKeys.serverLabel | vtranslate }}"
                        iconShape="rack-server"
                    ></vs-log-cinematic-end-to-end-knot>

                    <!-- Bridge  -->
                    <vs-log-cinematic-end-to-end-bridge
                        class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--long"
                        topHeader="{{ l10nKeys.appResponseLabel | vtranslate }}"
                        arrowColor="yellow"
                        [endToEndTime]="appResponseTime$ | async"
                        [endToEndTimeFilterProp]="'app_response_time'"
                        [noStatusCode]="true"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    ></vs-log-cinematic-end-to-end-bridge>

                    <vs-log-cinematic-end-to-end-knot
                        headerText="{{ globalL10nKeys.appLabel | vtranslate }}"
                        iconShape="application"
                    ></vs-log-cinematic-end-to-end-knot>
                </div>

                <!-- Data Transfer -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge"
                    topHeader="{{ l10nKeys.dataTransferLabel | vtranslate }}"
                    [endToEndTime]="dataTransferTime$ | async"
                    [endToEndTimeFilterProp]="'data_transfer_time'"
                    [noArrow]="true"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <!-- Total Time -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge"
                    topHeader="{{ globalL10nKeys.totalTimeLabel | vtranslate }}"
                    [endToEndTime]="totalTime$ | async"
                    [endToEndTimeFilterProp]="'total_time'"
                    [noArrow]="true"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>
            </ng-container>

            <!-- DNS -->
            <div
                *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.DNS"
                class="vs-log-cinematic-top-info__top-card-dns-end-to-end"
            >
                <!-- Load Balancer -->
                <vs-log-cinematic-end-to-end-knot
                    headerText="{{ globalL10nKeys.loadBalancerLabel | vtranslate }}"
                    iconShape="load-balancer"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Bridge  -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--long"
                    topHeader="{{ l10nKeys.serverRttLabel | vtranslate }}"
                    arrowColor="green"
                    [endToEndTime]="serverRtt$ | async"
                    [endToEndTimeFilterProp]="'server_rtt'"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <!-- DNS Server -->
                <vs-log-cinematic-end-to-end-knot
                    class="vs-log-cinematic-top-info__top-card-box-section"
                    headerText="{{ l10nKeys.dnsServerLabel | vtranslate }}"
                    iconShape="rack-server"
                ></vs-log-cinematic-end-to-end-knot>
            </div>

            <!-- L4 -->
            <div
                *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L4"
                class="vs-log-cinematic-top-info__top-card-l4-end-to-end"
            >
                <!-- Client -->
                <vs-log-cinematic-end-to-end-knot
                    headerText="{{ globalL10nKeys.clientLabel | vtranslate }}"
                    iconShape="user"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Bridge  -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--long"
                    topHeader="{{ l10nKeys.clientRttLabel | vtranslate }}"
                    arrowColor="blue"
                    [endToEndTime]="clientRtt$ | async"
                    endToEndTimeFilterProp="client_rtt"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <!-- Load Balancer -->
                <vs-log-cinematic-end-to-end-knot
                    headerText="{{ globalL10nKeys.loadBalancerLabel | vtranslate }}"
                    iconShape="load-balancer"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Bridge  -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge vs-log-cinematic-top-info__bridge--long"
                    topHeader="{{ l10nKeys.serverRttLabel | vtranslate }}"
                    arrowColor="green"
                    [endToEndTime]="serverRtt$ | async"
                    endToEndTimeFilterProp="server_rtt"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>

                <!-- Server -->
                <vs-log-cinematic-end-to-end-knot
                    class="vs-log-cinematic-top-info__top-card-box-section"
                    headerText="{{ globalL10nKeys.serverLabel | vtranslate }}"
                    iconShape="rack-server"
                ></vs-log-cinematic-end-to-end-knot>

                <!-- Total Time -->
                <vs-log-cinematic-end-to-end-bridge
                    class="vs-log-cinematic-top-info__bridge"
                    topHeader="{{ globalL10nKeys.totalTimeLabel | vtranslate }}"
                    [endToEndTime]="totalTime$ | async"
                    endToEndTimeFilterProp="total_time"
                    [noArrow]="true"
                    [noStatusCode]="true"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></vs-log-cinematic-end-to-end-bridge>
            </div>
        </avi-card_body>
    </avi-card>


    <!-- IP Section -->
    <div class="vs-log-cinematic-top-info__communicating-ips">
    <!-- 1st row -->
        <!-- IP field titles -->
        <div class="vs-log-cinematic-top-info__top-info-section-row">
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <div vsLogCinematicFieldTitle>
                    {{ globalL10nKeys.clientIpLabel | vtranslate }}
                </div>
                <double-arrow
                    class="vs-log-cinematic-top-info__connection-arrow vs-log-cinematic-top-info__bridge--long"
                    color="green"
                ></double-arrow>
            </div>
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <div vsLogCinematicFieldTitle>
                    {{ l10nKeys.virtualServiceIpLabel | vtranslate }}
                </div>
            </div>
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>
        </div>

        <!-- IP data -->
        <div class="vs-log-cinematic-top-info__top-info-section-row">
            <!-- Client IP -->
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <ng-container *ngrxLet="clientIps$ as clientIps">
                    <ng-container *ngIf="clientIps.client_ip || clientIps.client_ip6; else noData">
                        <vs-log-cinematic-ip-field
                            *ngIf="clientIps.client_ip && !clientIps.client_ip6"
                            property="client_ip"
                            [ip]="clientIps.client_ip"
                            portProperty="client_src_port"
                            [port]="clientSrcPort$ | async"
                        ></vs-log-cinematic-ip-field>

                        <vs-log-cinematic-ip-field
                            *ngIf="clientIps.client_ip6"
                            version="ipv6"
                            property="client_ip6"
                            [ip]="clientIps.client_ip6"
                            portProperty="client_src_port"
                            [port]="clientSrcPort$ | async"
                        ></vs-log-cinematic-ip-field>
                    </ng-container>
                </ng-container>
            </div>

            <!-- VS IP -->
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <ng-container *ngrxLet="vsIps$ as vsIps">
                    <ng-container *ngIf="vsIps.vs_ip || vsIps.vs_ip6">
                        <vs-log-cinematic-ip-field
                            *ngIf="vsIps.vs_ip && !vsIps.vs_ip6"
                            property="vs_ip"
                            [ip]="vsIps.vs_ip"
                            portProperty="client_dest_port"
                            [port]="clientDestPort$ | async"
                        ></vs-log-cinematic-ip-field>
                        <vs-log-cinematic-ip-field
                            *ngIf="vsIps.vs_ip6"
                            version="ipv6"
                            property="vs_ip6"
                            [ip]="vsIps.vs_ip6"
                            portProperty="client_dest_port"
                            [port]="clientDestPort$ | async"
                        ></vs-log-cinematic-ip-field>
                    </ng-container>
                    <span *ngIf="!vsIps.vs_ip && !vsIps.vs_ip6">
                        <!-- no filter here -->
                        {{ vs.getIPAddresses('floatOrReg')[0] }}
                    </span>
                </ng-container>
            </div>

            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>
        </div>

        <!-- 2nd row -->
        <ng-container *ngrxLet="vsAppProfileType$ as vsAppProfileType">
            <ng-container *ngrxLet="pool$ as hasPool">
                <!-- IP field titles -->
                <div class="vs-log-cinematic-top-info__top-info-section-row">
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <div
                            vsLogCinematicFieldTitle
                            *ngIf="vsAppProfileType === VsAppProfileType.L7"
                        >
                            {{ l10nKeys.sourceClientIpLabel | vtranslate }}
                        </div>
                    </div>
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <ng-container *ngIf="vsAppProfileType !== VsAppProfileType.DNS || hasPool">
                            <div vsLogCinematicFieldTitle>
                                {{ l10nKeys.serverConnectionIpLabel | vtranslate }}
                            </div>
                            <double-arrow
                                class="vs-log-cinematic-top-info__connection-arrow vs-log-cinematic-top-info__bridge--long"
                                color="green"
                            ></double-arrow>
                        </ng-container>
                    </div>
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <div
                            vsLogCinematicFieldTitle
                            *ngIf="vsAppProfileType !== VsAppProfileType.DNS || hasPool"
                        >
                            {{ globalL10nKeys.serverIpLabel | vtranslate }}
                        </div>
                    </div>
                </div>

                <!-- IP data -->
                <div class="vs-log-cinematic-top-info__top-info-section-row">
                    <!-- Source Client IP -->
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <ng-container *ngIf="vsAppProfileType === VsAppProfileType.L7">
                            <ng-container *ngrxLet="sourceIps$ as sourceIps">
                                <ng-container *ngIf="sourceIps.source_ip || sourceIps.source_ip6; else noData">
                                    <vs-log-cinematic-ip-field
                                        *ngIf="sourceIps.source_ip && !sourceIps.source_ip6"
                                        property="source_ip"
                                        [ip]="sourceIps.source_ip"
                                    ></vs-log-cinematic-ip-field>
                                    <vs-log-cinematic-ip-field
                                        *ngIf="sourceIps.source_ip6"
                                        version="ipv6"
                                        property="source_ip6"
                                        [ip]="sourceIps.source_ip6"
                                    ></vs-log-cinematic-ip-field>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <!-- Server Connection IP -->
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <ng-container *ngIf="vsAppProfileType !== VsAppProfileType.DNS || hasPool">
                            <ng-container *ngrxLet="serverConnectionSrcIps$ as serverConnSrcIps">
                                <ng-container *ngIf="serverConnSrcIps.server_conn_src_ip || serverConnSrcIps.server_conn_src_ip6; else noData">
                                    <vs-log-cinematic-ip-field
                                        *ngIf="serverConnSrcIps.server_conn_src_ip && !serverConnSrcIps.server_conn_src_ip6"
                                        property="server_conn_src_ip"
                                        [ip]="serverConnSrcIps.server_conn_src_ip"
                                        portProperty="server_src_port"
                                        [port]="serverSrcPort$ | async"
                                    ></vs-log-cinematic-ip-field>
                                    <vs-log-cinematic-ip-field
                                        *ngIf="serverConnSrcIps.server_conn_src_ip6 as ip6"
                                        version="ipv6"
                                        property="server_conn_src_ip6"
                                        [ip]="ip6"
                                        portProperty="server_src_port"
                                        [port]="serverSrcPort$ | async"
                                    ></vs-log-cinematic-ip-field>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <!-- Server IP -->
                    <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                        <ng-container *ngIf="vsAppProfileType !== VsAppProfileType.DNS || hasPool">
                            <ng-container *ngrxLet="serverIps$ as serverIps">
                                <span
                                    *ngIf="(poolName$ | async) as poolName"
                                    vsLogPartialSelectionFilter
                                    property="pool_name"
                                    [value]="poolName"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                ></span>
                                <ng-container *ngrxLet="serverName$ as serverName">
                                    <ng-container *ngIf="serverName && serverName !== (serverIps.server_ip | toString)">
                                        :<span
                                            vsLogPartialSelectionFilter
                                            property="server_name"
                                            [value]="serverName"
                                            [noSearchIcon]="true"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                        ></span>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="serverIps.server_ip || serverIps.server_ip6 else noData">
                                    (<vs-log-cinematic-ip-field
                                            *ngIf="serverIps.server_ip && !serverIps.server_ip6"
                                            property="server_ip"
                                            [ip]="serverIps.server_ip"
                                            portProperty="server_dest_port"
                                            [port]="serverDestPort$ | async"
                                            [noIpSearchIcon]="true"
                                        ></vs-log-cinematic-ip-field>
                                        <vs-log-cinematic-ip-field
                                            *ngIf="serverIps.server_ip6 as ip6"
                                            version="ipv6"
                                            property="server_ip6"
                                            [ip]="ip6"
                                            portProperty="server_dest_port"
                                            [port]="serverDestPort$ | async"
                                            [noIpSearchIcon]="true"
                                        ></vs-log-cinematic-ip-field>)
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!-- 3rd row -->
        <!-- IP field titles -->
        <div class="vs-log-cinematic-top-info__top-info-section-row">
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <div vsLogCinematicFieldTitle>
                    {{ globalL10nKeys.serviceEngineLabel | vtranslate }}
                </div>
                <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                    <!-- intentionally left blank as a placeholder -->
                </div>
            </div>
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>
        </div>

        <!-- IP data -->
        <div class="vs-log-cinematic-top-info__top-info-section-row">
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>

            <!-- Service Engine -->
            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <div
                    *ngrxLet="serviceEngine$ as serviceEngine"
                    vsLogPartialSelectionFilter
                    property="service_engine"
                    [value]="serviceEngine"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></div>
                &nbsp;(vcpu:
                <span
                    *ngrxLet="vcpuId$ as vcpuId"
                    vsLogFilter
                    [property]="'vcpu_id'"
                    [value]="vcpuId"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ vcpuId }}
                </span>)
            </div>

            <div class="vs-log-cinematic-top-info__top-info-section-row-col">
                <!-- intentionally left blank as a placeholder -->
            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <span vsLogEmptyField></span>
</ng-template>
