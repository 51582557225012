/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './vs-log-cinematic-section-authentication.l10n';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying Authentication section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-authentication',
    templateUrl: './vs-log-cinematic-section-authentication.component.html',
})
export class VsLogCinematicSectionAuthenticationComponent {
    public readonly samlLog$ = this.vsLogCinematicStore.samlLog$;

    public readonly oAuthLog$ = this.vsLogCinematicStore.oAuthLog$;

    public readonly jwtLog$ = this.vsLogCinematicStore.jwtLog$;

    public readonly ntlmLog$ = this.vsLogCinematicStore.ntlmLog$;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }
}
