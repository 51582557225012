<full-modal-config
    modalTitle="{{ l10nKeys.modalHeader | vtranslate : serviceEngineName }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    submitButtonText="{{ l10nKeys.submitBtnLabel | vtranslate }}"
    [busy]="busy"
    [errors]="error"
    [valid]="form.valid"
    class="traffic-capture-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                    >
                        {{ globalL10nKeys.serviceEngineLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [value]="serviceEngineName"
                        clrInput
                        placeholder="-"
                        readonly
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="!(debugIpFilterType === debugIpFilter.CHOOSE_IP)">
                            <avi-fieldset_header>
                                <avi-radio-container [noMarginTop]="true">
                                    <avi-radio
                                        name="{{'type-'+ debugIpFilter.ALL}}"
                                        [(ngModel)]="debugIpFilterType"
                                        (ngModelChange)=" onDebugIpFilterChange()"
                                        value="{{ debugIpFilter.ALL }}"
                                    >
                                        {{ l10nKeys.allTrafficRadioLabel | vtranslate }}
                                    </avi-radio>
                                    <avi-radio
                                        name="{{'type-'+ debugIpFilter.CHOOSE_IP}}"
                                        [(ngModel)]="debugIpFilterType"
                                        (ngModelChange)=" onDebugIpFilterChange()"
                                        value="{{ debugIpFilter.CHOOSE_IP }}"
                                    >
                                        {{ l10nKeys.chooseIpRadioLabel | vtranslate }}
                                    </avi-radio>
                                </avi-radio-container>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <avi-input-container
                                    noMarginTop
                                    helperText="{{ l10nKeys.clientIpHelperText | vtranslate }}"
                                    *ngIf="debugIpFilterType === debugIpFilter.CHOOSE_IP"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.debugServiceEngine }}"
                                        fieldName="debug_ip"
                                        for="debug-ip"
                                        required
                                    >
                                        {{ l10nKeys.clientIpInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        name="debug-ip"
                                        id="debug-ip"
                                        clrInput
                                        placeholder="{{ l10nKeys.clientIpInputPlaceholder | vtranslate }}"
                                        [(ngModel)]="debugIpAddrString"
                                        regexPattern="anyIPRangeOrSubnetList"
                                        required
                                    />
                                </avi-input-container>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.debugServiceEngine }}"
                            fieldName="flags"
                            for="flags"
                            required
                        >
                            {{ l10nKeys.packetCaptureTypeLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="flags"
                            id="flags"
                            enum="DebugSeDataplaneFlags"
                            [(ngModel)]="selectedPacketCaptureTypesList"
                            placeholder="{{ l10nKeys.packetCaptureTypePlaceholder | vtranslate }}"
                            [hiddenEnumValues]="hiddenTypes"
                            [multiple]="true"
                            required
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ globalL10nKeys.portLabel | vtranslate }}
                        </h6>
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.captureFilters }}"
                                fieldName="src_port"
                                for="src-port"
                            >
                                {{ l10nKeys.srcPortInputLabel | vtranslate }}
                            </label>
                            <input
                                id="src-port"
                                name="src-port"
                                type="number"
                                [(ngModel)]="trafficCaptureConfiguration.capture_filters.src_port"
                                configFieldInputValidation
                                objectType="{{ objectType.captureFilters }}"
                                fieldName="src_port"
                                clrInput
                                placeholder="{{ l10nKeys.portInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.captureFilters }}"
                                        fieldName="dst_port_start"
                                        for="dst-port-start"
                                    >
                                        {{ l10nKeys.dstPortStartInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="dst-port-start"
                                        name="dst-port-start"
                                        type="number"
                                        [(ngModel)]="trafficCaptureConfiguration.capture_filters.dst_port_start"
                                        configFieldInputValidation
                                        objectType="{{ objectType.captureFilters }}"
                                        fieldName="dst_port_start"
                                        clrInput
                                        placeholder="{{ l10nKeys.portInputPlaceholder | vtranslate }}"
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.captureFilters }}"
                                        fieldName="dst_port_end"
                                        for="dst-port-end"
                                    >
                                        {{ l10nKeys.dstPortEndInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="dst-port-end"
                                        name="dst-port-end"
                                        type="number"
                                        [(ngModel)]="trafficCaptureConfiguration.capture_filters.dst_port_end"
                                        configFieldInputValidation
                                        objectType="{{ objectType.captureFilters }}"
                                        fieldName="dst_port_end"
                                        clrInput
                                        placeholder="{{ l10nKeys.portInputPlaceholder | vtranslate }}"
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.packetSectionHeader | vtranslate }}
                        </h6>
                        <div class="full-modal-config__form-control-row traffic-capture-modal__no-margin-top ">
                            <div class="full-modal-config__form-control">
                                <avi-input-container
                                    noMarginTop
                                    helperText="{{ l10nKeys.pktSizeHelperText | vtranslate }}"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.debugVirtualServiceCapture }}"
                                        fieldName="pkt_size"
                                        for="pkt-size"
                                        required
                                    >
                                        {{ l10nKeys.pktSizeInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="pkt-size"
                                        name="pkt-size"
                                        type="number"
                                        [(ngModel)]="trafficCaptureConfiguration.capture_params.pkt_size"
                                        configFieldInputValidation
                                        objectType="{{ objectType.debugVirtualServiceCapture }}"
                                        fieldName="pkt_size"
                                        clrInput
                                        placeholder="{{ l10nKeys.pktSizeInputPlaceholder | vtranslate }}"
                                        required
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <div class="full-modal-config__form-control">
                                    <avi-input-container
                                        noMarginTop
                                        helperText="{{ l10nKeys.captureFileSizeHelperText | vtranslate }}"
                                    >
                                        <label
                                            aviLabelWithTooltip
                                            objectType="{{ objectType.captureFileSize }}"
                                            fieldName="absolute_size"
                                            for="absolute-size"
                                            required
                                        >
                                            {{ l10nKeys.captureFileSizeInputLabel | vtranslate }}
                                        </label>
                                        <input
                                            id="absolute-size"
                                            name="absolute-size"
                                            type="number"
                                            [(ngModel)]="trafficCaptureConfiguration.capture_params.capture_file_size.absolute_size"
                                            configFieldInputValidation
                                            objectType="{{ objectType.captureFileSize }}"
                                            fieldName="absolute_size"
                                            clrInput
                                            placeholder="{{ l10nKeys.captureFileSizeInputPlaceholder | vtranslate }}"
                                            required
                                        />
                                    </avi-input-container>
                                </div>
                            </div>
                        </div>
                        <avi-input-container>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.debugVirtualServiceCapture }}"
                                fieldName="file_count"
                                for="file-count"
                                required
                            >
                                {{ l10nKeys.fileCountInputLabel | vtranslate }}
                            </label>
                            <input
                                id="file-count"
                                name="file-count"
                                type="number"
                                [(ngModel)]="trafficCaptureConfiguration.capture_params.file_count"
                                configFieldInputValidation
                                objectType="{{ objectType.debugVirtualServiceCapture }}"
                                fieldName="file_count"
                                clrInput
                                placeholder="{{ l10nKeys.fileCountInputPlaceholder | vtranslate }}"
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
