/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-profile-verify-saml-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const verifyProfileModalName = `${prefix}.verifyProfileModalName`;

export const ENGLISH = {
    [verifyProfileModalName]: 'Auth Profile',
};
