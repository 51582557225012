/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview For HTTPRedirectAction type of the protobuf we need to tokenize the path and host
 * values from strings to the specific objects. Used by {@link requestPolicy} and
 * {@link ApplicationProfile}.
 */
angular.module('aviApp').service('HTTPRedirectAction', ['RangeParser', function(RangeParser) {
    /**
     * Mutates the Action object for UI presentation. Creates a value property having the whole
     * concatenated strings for the path and host properties.
     * @param {Object<host, path, value>} action - HTTPRedirectAction typed object.
     */
    this.beforeEdit = function(action) {
        if (action.host && action.host.tokens) {
            action.host.value = _.reduce(action.host.tokens, function(base, token) {
                return `${base + RangeParser.token2str(token)}.`;
            }, '').slice(0, -1);
        }

        if (action.path && action.path.tokens) {
            const value = _.reduce(action.path.tokens, function(base, token) {
                return `${base + RangeParser.token2str(token)}/`;
            }, '').slice(0, -1);

            action.path.value = !_.isEmpty(value) && value || '/';
        }
    };

    /**
     * Mutates the Action Object from UI representation to HTTPRedirectAction protobuf type.
     * @param {Object<host, path, value>} action - Action object provided by modal forms.
     */
    this.beforeSave = function(action) {
        if (action.host && action.host.value) {
            action.host = {
                type: 'URI_PARAM_TYPE_TOKENIZED',
                tokens: RangeParser.tokensStr2tokens(action.host.value, '.'),
            };
        } else {
            delete action.host;
        }

        if (action.path && action.path.value) {
            action.path = {
                type: 'URI_PARAM_TYPE_TOKENIZED',
                tokens: RangeParser.tokensStr2tokens(action.path.value, '/'),
            };
        } else {
            delete action.path;
        }
    };
}]);
