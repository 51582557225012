<div class="seg-update-grid-expander grid-container">
    <div class="grid-container__cell--w--6">
        <div class="seg-update-grid-expander__label">{{ globalL10nKeys.versionLabel | vtranslate }}</div>
        <div class="seg-update-grid-expander__data">{{ segVersion }}</div>
    </div>
    <div class="grid-container__cell--w--6">
        <div class="seg-update-grid-expander__label">{{ l10nKeys.haModeLabel | vtranslate }}</div>
        <div class="seg-update-grid-expander__data">{{ haModeLabel }}</div>
    </div>
    <div class="grid-container__cell--w--6">
        <div class="seg-update-grid-expander__label">{{ l10nKeys.patchLabel | vtranslate }}</div>
        <div class="seg-update-grid-expander__data">{{ segPatchVersion }}</div>
    </div>
    <div class="grid-container__cell--w--6">
        <div class="seg-update-grid-expander__label">{{ l10nKeys.updatedOnLabel | vtranslate }}</div>
        <div class="seg-update-grid-expander__data">{{ updatedOn }}</div>
    </div>
</div>
