/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ImageType,
    ISeGroupOptions,
    IUpgradeStatusInfo,
    NodeType,
    RollbackType,
    SeGroupErrorRecovery,
    UpgradeFsmState,
    UpgradeFsmUiState,
} from 'generated-types';

export interface IUpgradeNodeVersionHash {
    // Version of the node. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '20.1.1' is the version.
    version?: string;

    // Build of the version. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '5884' is the build.
    build?: string;

    // Date of the build. A raw version data is denoted with format as
    // '20.1.1-5884-20200316.231632', '20200316.231632' is the date.
    date?: string;

    // Patch version of the node with format as '2p1'.
    patchVersion?: string;

    // Major version of the patch. If a given patch version is
    // '2p1', then the '2' before the 'p' is the major patch version.
    majorPatchVersion?: string;

    // Minor version of the patch. If a given patch version is
    // '2p1', then the '1' after the 'p' is the minor patch version.
    minorPatchVersion?: string;
}

export interface IRollbackOption {
    title: string;
    value: RollbackType;
    fullVersion?: string;
    fullPreviousVersion?: string;
}

export interface ISystemRollbackInfo {
    version?: string;
    patchVersion?: string;
    previousVersion?: string;
    previousPatchVersion?: string;
    enableImageRollback?: boolean;
    enablePatchRollback?: boolean;
}

/**
 * Represents update configuration needed for System/SEG update operations.
 */
export interface IUpdateConfig {
    upgradeType?: UpgradeType,
    rollbackType?: RollbackType;
    systemImageId?: string,
    controllerPatchImageId?: string,
    sePatchImageId?: string,
    targetVersion?: string,
    targetMajorVersion?: string,
    targetPatchVersion?: string
    segUuids?: string[],
}

/**
 * Type for options user selected in Upgrade Modal.
 */
export interface IUserSelectedOptions {
    upgradeType?: UpgradeType;
    upgradeConfig?: IUpdateConfig;
    actionOnSegFailure?: SeGroupErrorRecovery;
    applyToSystem?: boolean;
}

export enum UpgradeNodeType {
    CONTROLLER = 'NODE_CONTROLLER_CLUSTER',
    SE_GROUP = 'NODE_SE_GROUP',
    SE = 'NODE_SE_TYPE',
}

// Upgrade node state type, belonging to enum 'UpgradeFsmState'.
export const UpgradeState = {
    INIT: UpgradeFsmState.UPGRADE_FSM_INIT,
    STARTED: UpgradeFsmState.UPGRADE_FSM_STARTED,
    WAITING: UpgradeFsmState.UPGRADE_FSM_WAITING,
    IN_PROGRESS: UpgradeFsmState.UPGRADE_FSM_IN_PROGRESS,
    ENQUEUED: UpgradeFsmState.UPGRADE_FSM_ENQUEUED,
    ERROR: UpgradeFsmState.UPGRADE_FSM_ERROR,
    SUSPENDED: UpgradeFsmState.UPGRADE_FSM_SUSPENDED,
    ENQUEUE_FAILED: UpgradeFsmState.UPGRADE_FSM_ENQUEUE_FAILED,
    COMPLETED: UpgradeFsmState.UPGRADE_FSM_COMPLETED,
    ABORT_IN_PROGRESS: UpgradeFsmState.UPGRADE_FSM_ABORT_IN_PROGRESS,
    ABORTED: UpgradeFsmState.UPGRADE_FSM_ABORTED,
};

export enum UpgradeStateGroup {
    GROUP_INIT = 'UPGRADE_FSM_UI_INIT',
    GROUP_IN_PROGRESS = 'UPGRADE_FSM_UI_IN_PROGRESS',
    GROUP_COMPLETED = 'UPGRADE_FSM_UI_COMPLETED',
    GROUP_ERROR = 'UPGRADE_FSM_UI_ERROR',
}

/**
 * Hash of Upgrade StateGroups Types.
 */
export const UpgradeStateGroupTypesHash = {
    UPGRADE_STATE_GROUP_INIT: UpgradeFsmUiState.UPGRADE_FSM_UI_INIT,
    UPGRADE_STATE_GROUP_IN_PROGRESS: UpgradeFsmUiState.UPGRADE_FSM_UI_IN_PROGRESS,
    UPGRADE_STATE_GROUP_COMPLETED: UpgradeFsmUiState.UPGRADE_FSM_UI_COMPLETED,
    UPGRADE_STATE_GROUP_ERROR: UpgradeFsmUiState.UPGRADE_FSM_UI_ERROR,
};

/**
 * Hash of Upgrade PreChecks status types.
 */
export const UpgradePrechecksStatusTypeHash = {
    UPGRADE_PRE_CHECK_IN_PROGRESS: UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS,
    UPGRADE_PRE_CHECK_ERROR: UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR,
    UPGRADE_PRE_CHECK_STARTED: UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED,
    UPGRADE_PRE_CHECK_SUCCESS: UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS,
    UPGRADE_PRE_CHECK_WARNING: UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING,
};

/**
 * Hash of Node Types.
 */
export const nodeTypesHash = {
    NODE_CONTROLLER_CLUSTER: NodeType.NODE_CONTROLLER_CLUSTER,
    NODE_SE_GROUP: NodeType.NODE_SE_GROUP,
    NODE_SE_TYPE: NodeType.NODE_SE_TYPE,
};

/**
 * Hash of fips_operational flag.
 */
export const fipsOperationalHash = {
    FIPS_UNKNOWN: 'FIPS_UNKNOWN',
    FIPS_FALSE: 'FIPS_FALSE',
    FIPS_TRUE: 'FIPS_TRUE',
};

export enum UpgradeType {
    UPGRADE_TYPE_SYSTEM_UPGRADE = 'UPGRADE_TYPE_SYSTEM_UPGRADE',
    UPGRADE_TYPE_SYSTEM_ROLLBACK = 'UPGRADE_TYPE_SYSTEM_ROLLBACK',
    UPGRADE_TYPE_SEG_UPGRADE = 'UPGRADE_TYPE_SEG_UPGRADE',
    UPGRADE_TYPE_SEG_ROLLBACK = 'UPGRADE_TYPE_SEG_ROLLBACK',
}

export interface IUpgradeStatusResponse {
    count: number,
    results: IUpgradeStatusInfo[],
}

/**
 * Param hash as the request body to make upgrade API calls. Maps to UpgradeParams in protobuf.
 */
export interface IUpgradeApiPayload {
    /**
     * Image uuid for identifying base image.
     */
    image_uuid?: string,
    /**
     * Image uuid for identifying Controller patch.
     */
    controller_patch_uuid?: string,
    /**
     * Image uuid for identifying Service Engine patch.
     */
    se_patch_uuid?: string,
    /**
     * Apply upgrade operations such as Upgrade/Patch to Controller and ALL SE groups.
     */
    system?: boolean,
    /**
     * Identify SE group options that need to be applied during the upgrade operations.
     */
    se_group_options?: ISeGroupOptions,
    /**
     * Identify the list of SE groups for which the upgrade operations are applicable.
     * This field is ignored if the 'system' is enabled.
     */
    se_group_uuids?: string[],
    /**
     * Flag to skip warnings returned by must-checks.
     */
    skip_warnings?: boolean,

}

/**
 * Param hash as the request body to make rollback API calls. Maps to RollbackParams in protobuf.
 */
export interface IRollbackApiPayload {
    /**
     * Enum identifying to rollback the current applied patch or rollback to previous major version.
     */
    rollback_type?: string,
    /**
     * Apply upgrade operations such as Upgrade/Patch to Controller and ALL SE groups.
     */
    system?: boolean,
    /**
     * Identify the list of SE groups for which the rollback operations are applicable.
     * This field is ignored if the 'system' is enabled.
     */
    se_group_uuids?: string[],
    /**
     * Flag to skip warnings returned by must-checks.
     */
    skip_warnings?: boolean,
}

/**
 * Hash for image type enum.
 */
export const imageTypeHash = {
    IMAGE_TYPE_SYSTEM: ImageType.IMAGE_TYPE_SYSTEM,
    IMAGE_TYPE_PATCH: ImageType.IMAGE_TYPE_PATCH,
};

/**
 * Hash of different image categories.
 */
export const imageCategoryHash = {
    IMAGE_CATEGORY_CONTROLLER: 'Controller',
    IMAGE_CATEGORY_SE: 'Service Engine',
    IMAGE_CATEGORY_HYBRID: 'Controller & Service Engine',
};

/**
 * Hash of Upgrade PreChecks status types without the success state.
 */
export const UpgradePrechecksErrorStatusTypeHash = {
    UPGRADE_PRE_CHECK_IN_PROGRESS: UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS,
    UPGRADE_PRE_CHECK_ERROR: UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR,
    UPGRADE_PRE_CHECK_STARTED: UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED,
    UPGRADE_PRE_CHECK_WARNING: UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING,
};
