/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-management-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const customSectionLabel = `${prefix}.customSectionLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const namePlaceholder = `${prefix}.namePlaceholder`;
export const controlScriptInputLabel = `${prefix}.controlScriptInputLabel`;
export const controlScriptPlaceholder = `${prefix}.controlScriptPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Certificate Management',
    [modalTitleNew]: 'New Certificate Management',
    [sectionTitleGeneral]: 'General',
    [customSectionLabel]: 'Custom',
    [nameInputLabel]: 'Name',
    [namePlaceholder]: 'Enter Name',
    [controlScriptInputLabel]: 'Control Script',
    [controlScriptPlaceholder]: 'Select Script',
};
