<div
    provideParentForm
    class="custom-tags"
>
    <div [ngClass]="{'custom-tags__disabled': showOverlay}"></div>
    <h3>{{ l10nKeys.sectionTitle | vtranslate }}</h3>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-data-grid
                [config]="tagsGridConfig"
                [rows]="customTags.config"
                gridTitle="{{ l10nKeys.customTagsLabel | vtranslate : customTags.count }}"
            >
                <avi-data-grid_actions>
                    <cds-button
                        (click)="addCustomTags()"
                        size="sm"
                    >
                        {{ l10nKeys.addButtonLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
            </avi-data-grid>
            <ng-template
                #keyTemplateRef
                let-row
                let-index="index"
            >
                <avi-input-container noMarginTop>
                    <input
                        name="{{ 'tags-key-' + index }}"
                        id="{{ 'tags-key-' + index }}"
                        [(ngModel)]="row.config.tag_key"
                        clrInput
                        placeholder="{{ l10nKeys.columnKeyPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
            </ng-template>
            <ng-template
                #valueTemplateRef
                let-row
                let-index="index"
            >
                <avi-input-container noMarginTop>
                    <input
                        name="{{ 'tags-value-' + index }}"
                        id="{{ 'tags-value-' + index }}"
                        [(ngModel)]="row.config.tag_val"
                        clrInput
                        placeholder="{{ l10nKeys.columnValuePlaceholder | vtranslate }}"
                    />
                </avi-input-container>
            </ng-template>
        </div>
    </div>
</div>
