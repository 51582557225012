<avi-data-grid
    [config]="subdomainsGridConfig"
    [rows]="subdomains"
    [isLoading]="isLoading"
></avi-data-grid>

<ng-template
    #siteFieldTemplateRef
    let-row
>
    <div *ngIf="row.siteNames.length">
        {{ row.siteNames[0] }} 
        <!-- To show '+ more' message and tooltip for remaining values -->
        <span
            *ngIf="row.siteNames.length > 1"
            aviTitle="{{ row.siteNames.slice(1) }}"
        >
            {{ l10nKeys.columnValueSuffix | vtranslate : row.siteNames.length - 1  }}
        </span>
    </div>
</ng-template>

<ng-template
    #dnsVsFieldTemplateRef
    let-row
>
    <div *ngIf="row.dnsVsNames.length">
        {{ row.dnsVsNames[0] }} 
        <!-- To show '+ more' message and tooltip for remaining values -->
        <span
            *ngIf="row.dnsVsNames.length > 1"
            aviTitle="{{ row.dnsVsNames.slice(1) }}"
        >
            {{ l10nKeys.columnValueSuffix | vtranslate : row.dnsVsNames.length - 1  }}
        </span>
    </div>
</ng-template>
