<div provideParentForm>
    <avi-data-grid
        [config]="internalIpamUsableNetworkGridConfig"
        [rows]="usableNetworks.config"
        gridTitle="{{ l10nKeys.usableNetworksGridHeader | vtranslate : usableNetworks.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addUsableNetwork()"
                [disabled]="!cloudRef"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #usableNetworkTemplateRef
            let-row
            let-index="index"
        >
            <avi-collection-dropdown
                name="{{ usableNetworkRowPrefix + index }}"
                id="{{ usableNetworkRowPrefix + index }}"
                [disableCreate]="true"
                [disableEdit]="true"
                [collection]="subnetListNetworkCollection"
                [(ngModel)]="row.config.nw_ref"
                placeholder="{{ l10nKeys.networkSelectPlaceholder | vtranslate }}"
                [disabled]="!cloudRef"
                required
            ></avi-collection-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
