/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ItemsModule
 * @preferred
 */

import { NgModule } from '@angular/core';
import {
    createNgDependencyProviders,
} from 'ng/modules/data-model/utils/inject-ng-dependencies.utils';

import {
    ClusterItem,
    ControllerFault,
    CSRFPolicyItem,
    GSLB,
    Pool,
    PoolGroup,
    PoolGroupDeploymentPolicyItem,
    ServiceEngineItem,
    TenantSettingsItem,
} from './factories';

const providers = createNgDependencyProviders([
    ClusterItem,
    GSLB,
    ControllerFault,
    CSRFPolicyItem,
    Pool,
    PoolGroupDeploymentPolicyItem,
    PoolGroup,
    ServiceEngineItem,
    TenantSettingsItem,
]);

/**
 * @description
 * Module for registering Item files. Items can be imported by the 'items/*' path which points to
 * 'items/factories'. This module should contain providers only.
 * @author alextsg
 */
@NgModule({ providers })
export class ItemsModule {}
