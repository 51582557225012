/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @desc Ip address validator
 * Checks if entered IPs and/or ranges are within the subnet.
 * Depends on the library (https://github.com/LouisT/inSubnet).
 *
 * @example
 *      <input type="text" [inSubnet]="'1.1.1.1/24'">
 *          OR
 *      <input type="text" [inSubnet]="subnetStringVariable">
 *
 * @author Sarthak Kapoor
 */

import {
    Directive,
    forwardRef,
    Input,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    validateIpInSubnet,
} from 'ng/modules/avi-forms/validators/in-subnet/in-subnet-validation.validator';

import {
    isNull,
    isUndefined,
} from 'underscore';

@Directive({
    selector: `[inSubnet][formControlName],
        [inSubnet][formControl],
        [inSubnet][ngModel]`,
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => InSubnetDirective),
    }],
})
export class InSubnetDirective implements Validator {
    /**
     * Sets the subnet String passed from the template.
     */
    @Input('inSubnet')
    public set setInSubnet(subnet: string) {
        this.inSubnet = subnet;

        if (this.onChange) {
            // Notify validate method the input subnet has changed and validation needs to rerun.
            this.onChange();
        }
    }

    /**
     * Holds the subnet value passed from the template.
     */
    private inSubnet: string;

    /**
     * Placeholder for ValidatorChange Callback function.
     */
    private onChange: () => void;

    /**
     * @override
     * Checks if the ips entered are within the subnet.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) && !isNull(control.value) ?
            validateIpInSubnet(this.inSubnet)(control) : null;
    }

    /**
     * @override
     */
    public registerOnValidatorChange(fn: () => void): void {
        this.onChange = fn;
    }
}
