/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { L4PolicySetCollection }
    from 'ajs/modules/l4-policy/factories/l4-policy-set-collection.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './l4-policy.l10n';
import './l4-policy.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TL4PolicySetCollection = typeof L4PolicySetCollection;

/**
 * @description L4 policy set list page.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'l4-policy',
    templateUrl: './l4-policy.component.html',
})
export class L4PolicyComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Expanded content template for datagrid row.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Collection object to store the l4 policy set collection.
     */
    public readonly l4PolicySetCollection: L4PolicySetCollection;

    /**
     * Configuration object to display l4 policy set collection.
     */
    public l4PolicySetGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(L4PolicySetCollection)
        L4PolicySetCollection: TL4PolicySetCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.l4PolicySetCollection = new L4PolicySetCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.l4PolicySetCollection;

        this.l4PolicySetGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.l4PolicySetCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
                {
                    id: 'rules',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleNumberRules),
                    transform: (row: IAviCollectionDataGridRow) =>
                        row.getConfig().l4_connection_policy?.rulesCount,
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.l4PolicySetGridConfig = {
            ...this.l4PolicySetGridConfig,
            expandedContentTemplateRef: this.expandedContentTemplateRef,
            expanderDisabled: (): boolean => false,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.l4PolicySetCollection.destroy();
    }
}
