<div
    class="gslb-service-domain-names-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="domainNamesGridConfig"
        [rows]="splitDomainNames"
        [invalidateForm]="!splitDomainNames.length"
    >
        <avi-data-grid_header>
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="domain_names"
            >
                <h6>
                    {{ l10nKeys.domainNamesGridLabel | vtranslate : splitDomainNames.length }}
                    <span class="gslb-service-domain-names-grid__mandatory-indicator">*</span>
                </h6>
            </label>
        </avi-data-grid_header>

        <avi-data-grid_actions>
            <cds-button
                (click)="addDomainName()"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #applicationNameTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    name="application-name-{{ index }}"
                    id="application-name-{{ index }}"
                    type="text"
                    clrInput
                    placeholder="{{ l10nKeys.applicationNameInputPlaceholder | vtranslate }}"
                    [(ngModel)]="row.appDomainName"
                    regex="dnsName"
                    required
                />
            </avi-input-container>
        </ng-template>

        <ng-template
            #subdomainTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="subdomain-{{ index }}"
                [options]="gslbSubDomainNameDropdownOptions"
                [(ngModel)]="row.subdomain"
                [hideSearch]="true"
                placeholder="{{ l10nKeys.subdomainInputPlaceholder | vtranslate }}"
                required
            ></avi-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
