/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import moment from 'moment';
import { IControllerInitialDataState } from './controller-initial-data.state';

export const controllerInitialDataStateFeatureKey = 'controllerInitialData';

const controllerSelectInitialDataFeature =
    createFeatureSelector<IControllerInitialDataState>(controllerInitialDataStateFeatureKey);

// --------------- Individual selectors ---------------
export const selectIsAwsCloud = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => controllerInitialData.is_aws_cloud,
);

export const selectControllerTimeDifference = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => {
        const timeDifference = moment().diff(moment(controllerInitialData.current_time), 's');

        return timeDifference;
    },
);

export const selectPatch = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => controllerInitialData.patch,
);

export const selectDate = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => controllerInitialData.date,
);

export const selectVersion = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => controllerInitialData.version,
);

export const selectBuild = createSelector(
    controllerSelectInitialDataFeature,
    (controllerInitialData: IControllerInitialDataState) => controllerInitialData.build,
);

// --------------- Combined selectors ---------------
/**
 * Selector returning About App data. Used in SystemAboutInfoComponent.
 */
export const selectAboutAppData = createSelector(
    selectPatch,
    selectDate,
    selectVersion,
    selectBuild,
    (patch, date, version, build) => ({
        patch,
        date,
        version,
        build,
    }),
);
