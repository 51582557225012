/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    compact,
    isEmpty,
    unique,
} from 'underscore';
import { xor } from 'lodash';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { ISavedSearch } from 'ng/root-store/user-preferences/user-preferences.state';
import {
    FilterOperatorType,
    TFilterObj,
    TLogEntrySignatureParams,
    TStateFilters,
    VsLogsType,
} from '../vs-logs.types';
import { QueryParserService } from './vs-logs-parser';
import { TSavedSearchHash } from '../services/vs-logs.store';
import {
    boolFields,
    fieldsWithOrLogic,
    IVsLogsOperatorDesc,
    l4Fields,
    l7Fields,
    numberFields,
    stringFields,
    TPropertyTypeKeys,
    typeToOperatorMapping,
} from '../constants/vs-logs-filters.constants';

/**
 * Filter Representation Types
 *
 * There are 3 ways a filter is represented internally.
 *
 * Object: see TFilterObj. Components will interact with the filter service
 * via VsLogsStore.addFilter() by passing it objects in this format.
 * Example: { property: 'method', operator: FilterOperatorType.EQUALTO, value: 'GET' }
 *
 * Query String: how a query looks in the search bar.
 * This is the same format that the parser expects.
 * Example: method="GET"
 *
 * API String: how the API expects the query to be formatted.
 * The job of the parser is to take a query string and return an object that
 * represents the API string, which we then format into the string.
 * Example: eq(method,"GET")
 *
 * Presently the filters are stored in state as query strings.
 * This is to maximize code reuse from old logs code.
 * If the filter service is revamped from the ground up,
 * we should store filters as objects in state.
 */

/**
 * Filter Workflow
 *
 * 1. A component calls VsLogsStore.addFilter() and passes in a filter object.
 * 2. convertFilterObjectToQueryString() converts the object to a query string.
 * 3. squashFilters() merges the query string to the existing filters stored in state.
 * 4. convertStateToApiParams() and convertQueryStringtoApiString() convert the query strings
 *    into the strings expected by the API.
 */

const { QueryParser } = QueryParserService;

type TParsedQuery = {
    quoted?: string;
    wildcard?: boolean;
    query?: string | number | Array<string | number>;
    array?: boolean;
    op?: string;
    field?: string;
};

/**
 * Mapping of filter operator enum to corresponding query string.
 */
const filterOperatorMapping: Record<FilterOperatorType, string> = {
    EQUAL_TO: '=',
    NOT_EQUAL_TO: '!=',
    GREATER_THAN: '>',
    GREATER_THAN_OR_EQUAL_TO: '>=',
    LESS_THAN: '<',
    LESS_THAN_OR_EQUAL_TO: '<=',
    CONTAINS: '~=',
    DOES_NOT_CONTAIN: '!~=',
    STARTS_WITH: '^=',
};

/**
 * Figure out filter property type (number, boolean, or string).
 * This corresponds to the type found for the field in protobuf.
 * For example, "method" is a string in both IApplicationLog and IConnectionLog.
 *
 * We do this because filter query strings are serialized differently based on whether
 * the property is a string or boolean (method="GET") or a number (response_code=403).
 */
export const getFilterPropertyType = (property: string): TPropertyTypeKeys => {
    if (numberFields.has(property)) {
        return 'number';
    }

    if (stringFields.has(property)) {
        return 'string';
    }

    if (boolFields.has(property)) {
        return 'boolean';
    }
};

/**
 * Convert filter object into query string format (see above for definitions).
 */
export const convertFilterObjectToQueryString = (filter: TFilterObj): string => {
    let queryString = `${filter.property}${filterOperatorMapping[filter.operator]}`;

    const propertyType = getFilterPropertyType(filter.property);

    switch (propertyType) {
        case 'string':
        case 'boolean':
            queryString += `"${filter.value}"`;

            break;

        case 'number':
            queryString += filter.value;
            break;
    }

    return queryString;
};

/**
 * Current parser only supports multiple values for strings;
 * handle log_id separately until parser is revamped.
 */
export const convertLogsFilterObjectToQueryString = (filter: TFilterObj): string => {
    let queryString = `${filter.property}${filterOperatorMapping[filter.operator]}`;

    if (Array.isArray(filter.value)) {
        queryString += `[${filter.value.join(',')}]`;
    } else {
        queryString += filter.value;
    }

    return queryString;
};

/**
 * Convert the params needed to query for a single log into the respective query strings array.
 */
export const convertLogEntryParamsToQueryStrings = (
    logEntryParams: TLogEntrySignatureParams,
): [string, string] => {
    const logFilter: TFilterObj = {
        property: 'log_id',
        operator: FilterOperatorType.EQUAL_TO,
        value: logEntryParams.logId,
    };

    const logQueryString = convertLogsFilterObjectToQueryString(logFilter);

    const seFilter: TFilterObj = {
        property: 'service_engine',
        operator: FilterOperatorType.EQUAL_TO,
        value: logEntryParams.seId,
    };

    const seQueryString = convertFilterObjectToQueryString(seFilter);

    return [logQueryString, seQueryString];
};

/**
 * Helper function for squashFilters.
 */
const combine = (
    item1: TParsedQuery, item2: TParsedQuery,
): { parsed: TParsedQuery, str: string } => {
    const quotesTrim = /^['"](.*?)['"]$/g;
    const parsed = item1;

    const removeQuotes = (val: string): string => {
        return val.replace(quotesTrim, '$1');
    };

    const quote = (type: string): string => {
        return type === 'double' ? '"' : '\'';
    };

    const ops = {
        eq: '=',
        ne: '!=',
        ge: '>=',
        gt: '>',
        le: '<=',
        lt: '<',
        co: '~=',
        nc: '!~=',
        sw: '^=',
    };

    if (typeof item1.array === 'undefined') { // if only one value
        item1.query = [quote(item1.quoted) +
            item1.query + quote(item1.quoted)];
    }

    if (typeof item2.array === 'undefined') {
        item2.query = [quote(item2.quoted) +
            item2.query + quote(item2.quoted)];
    }

    const item1Query = item1.query as Array<string | number>;
    const item2Query = item2.query as Array<string | number>;

    // remove duplicates inside arrays
    parsed.query = unique(item1Query.concat(item2Query),
        false, removeQuotes);
    parsed.array = true;

    const str = `${parsed.field + ops[parsed.op]}[${parsed.query.join(',')}]`;

    return {
        parsed,
        str,
    };
};

/**
 * Removes duplicates.
 * Combines filters that have OR logic.
 * Ex: adding method="POST" when method="GET" exists returns method=["GET","POST"]
 */
export const squashFilters = (filters: TStateFilters, vsLogsType: VsLogsType): TStateFilters => {
    let search = filters;

    // remove duplicates
    search = unique(search);

    const res: string[] = [];
    const dict = {}; // two dimensions array: [field][operator]
    const parsed = {}; // search string parsed to object

    search.forEach((str, key) => {
        // current parser fails to support this field for some reason.
        // handle as normal ip until parser is revamped
        let isEdnsSubnetIp6 = false;

        if (str.slice(0, 41) === 'dns_request.opt_record.options.subnet_ip6') {
            str = str.replace(
                'dns_request.opt_record.options.subnet_ip6',
                'dns_request.opt_record.options.subnet_ip',
            );

            isEdnsSubnetIp6 = true;
        }

        let item: TParsedQuery = {};
        let gotSquashed = false;

        // Filters are only squashed if the field is supported by the parser,
        // and if the field supports OR logic.
        try {
            const phrase =
                `l${vsLogsType ? 7 : 4}: ${str}`;

            [item] = QueryParser.parse(phrase);

            if (isEdnsSubnetIp6) {
                str = str.replace(
                    'dns_request.opt_record.options.subnet_ip',
                    'dns_request.opt_record.options.subnet_ip6',
                );

                item.field = 'dns_request.opt_record.options.subnet_ip6';
            }
        } catch (e) {
            // Parser throws error if unsupported field; ignore
        }

        if (!isEmpty(item)) {
            const {
                wildcard: isWildcard,
                field: fieldName,
                op: operator,
            } = item;

            if (!isWildcard && fieldsWithOrLogic.has(fieldName) && operator !== 'ne') {
                parsed[key] = item;

                if (!(fieldName in dict)) {
                    dict[fieldName] = {};
                    dict[fieldName][operator] = key;
                } else if (!(operator in dict[fieldName])) {
                    dict[fieldName][operator] = key;
                } else {
                    const keyToCombineWith = dict[fieldName][operator];

                    gotSquashed = true;

                    const { str, parsed: parsedElem } = combine(
                        parsed[keyToCombineWith], item,
                    );

                    res[keyToCombineWith] = str;
                    parsed[keyToCombineWith] = parsedElem;
                }
            }
        }

        if (!gotSquashed) {
            res[key] = str;
        }
    });

    search = compact(res);

    return search;
};

/**
 * Convert a filter in query string format to API string format (see above for definitions).
 */
export const convertQueryStringToApiString = (query: string, type: VsLogsType): string => {
    // current parser only supports multiple values for strings;
    // handle log_id manually until parser is revamped
    if (query.slice(0, 6) === 'log_id') {
        const value = query.split('=')[1];

        return `eq(log_id,${value})`;
    }

    // current parser fails to support this field for some reason.
    // handle as normal ip until parser is revamped
    let isEdnsSubnetIp6 = false;

    if (query.slice(0, 41) === 'dns_request.opt_record.options.subnet_ip6') {
        query = query.replace(
            'dns_request.opt_record.options.subnet_ip6',
            'dns_request.opt_record.options.subnet_ip',
        );

        isEdnsSubnetIp6 = true;
    }

    const prefix = type ? 'l7_all' : 'l4_all';

    // query string -> api object
    let parsedApiObj: TParsedQuery;

    try {
        parsedApiObj = QueryParser.parse(`${prefix}: ${query}`)[0];

        if (isEdnsSubnetIp6) {
            query = query.replace(
                'dns_request.opt_record.options.subnet_ip6',
                'dns_request.opt_record.options.subnet_ip',
            );

            parsedApiObj.field = 'dns_request.opt_record.options.subnet_ip6';
        }
    } catch (e) {
        // Parser throws error for certain filters.
        // co(all,"<query>") is used as a catch-all for un-parseable filters.
        // Put into that format here
        return `co(all,"${query}")`;
    }

    // api object -> api string
    const {
        quoted,
        wildcard,
        array,
        op,
        field,
    } = parsedApiObj;

    let quote = '';
    let { query: val } = parsedApiObj;

    if (quoted === 'double' || quoted === 'single' || wildcard) {
        quote = '"';
    }

    // handle multiple values (for one property, e.g. client_os=["Chrome","Mac OS X"])
    if (array) {
        val = val as Array<string | number>;
        val = val.join();
        val = `[${val}]`;
    }

    return `${op}(${field},${quote}${val}${quote})`;
};

/**
 * Get the list of possible filter properties for selected VS type
 * (Application or Connection).
 */
export const getFilterPropertyOptionsFromType = (type: VsLogsType): IAviDropdownOption[] => {
    const options: IAviDropdownOption[] = [];

    switch (type) {
        case VsLogsType.APPLICATION:
            l7Fields.forEach(field => options.push({ value: field }));
            break;

        case VsLogsType.CONNECTION:
            l4Fields.forEach(field => options.push({ value: field }));
    }

    return options;
};

/**
 * Get the list of possible operator options given the current property.
 * E.g., property 'method' is a string, so we can use string operators
 * such as contains '~='.
 */
export const getFilterOperatorOptionsFromProperty = (
    property: string,
): Array<IAviDropdownOption<IVsLogsOperatorDesc>> => {
    const type = getFilterPropertyType(property);

    return typeToOperatorMapping[type].map(
        ({ symbol, desc }: IVsLogsOperatorDesc) => ({
            value: {
                symbol,
                desc,
            },
        }),
    );
};

/**
 * Compare the current search to a list of previous searches.
 * If it has already been saved, return the name of the saved search.
 */
export const findSavedSearch = (
    curSearch: TStateFilters,
    savedSearches: TSavedSearchHash,
): string => {
    let savedName = '';

    Object.entries(savedSearches).forEach(([name, search]) => {
        if (!xor(curSearch, search).length) {
            savedName = name;
        }
    });

    return savedName;
};

/**
 * Transform saved searches data from a hash to an array.
 */
export const transformSavedSearches = (savedSearches: TSavedSearchHash): ISavedSearch[] => {
    return Object.entries(savedSearches).map(([name, search]) => ({
        name,
        search,
    }));
};
