<div
    provideParentForm
    class="email-smtp-config"
>
    <avi-fieldset [hideContent]="emailSmtpType.isSmtpTypeNone">
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="smtp_type"
                >
                    {{ l10nKeys.smtpSourceLabel | vtranslate }}
                </label>
                <avi-radio
                    name="email-smtp-source"
                    [value]="smtpType.SMTP_NONE"
                    [(ngModel)]="emailSmtpConfig.smtp_type"
                    (change)="onSmtpTypeChange()"
                >
                    {{ globalL10nKeys.emptyLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="email-smtp-source"
                    [value]="smtpType.SMTP_LOCAL_HOST"
                    [(ngModel)]="emailSmtpConfig.smtp_type"
                    (change)="onSmtpTypeChange()"
                >
                    {{ globalL10nKeys.localLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="email-smtp-source"
                    [value]="smtpType.SMTP_ANONYMOUS_SERVER"
                    [(ngModel)]="emailSmtpConfig.smtp_type"
                    (change)="onSmtpTypeChange()"
                >
                    {{ l10nKeys.anonymousSmtpLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="email-smtp-source"
                    [value]="smtpType.SMTP_SERVER"
                    [(ngModel)]="emailSmtpConfig.smtp_type"
                    (change)="onSmtpTypeChange()"
                >
                    {{ l10nKeys.smtpLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>

        <avi-fieldset_content>
            <ng-container *ngIf="emailSmtpType.isSmtpTypeServer || emailSmtpType.isSmtpTypeAnonymousServer">
                <div class="full-modal-config__form-control-row email-smtp-config__no-margin-top">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                [objectType]="objectType"
                                fieldName="mail_server_name"
                                for="mail-server-name"
                                required
                            >
                                {{ l10nKeys.smtpServerLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="mail-server-name"
                                name="mail-server-name"
                                [placeholder]="l10nKeys.smtpServerPlaceholder | vtranslate"
                                [(ngModel)]="emailSmtpConfig.mail_server_name"
                                regexPattern="anyIPHostname"
                                required
                            />
                        </avi-input-container>
                    </div>

                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                [objectType]="objectType"
                                fieldName="mail_server_port"
                                for="mail-server-port"
                                required
                            >
                                {{ l10nKeys.smtpPortLabel | vtranslate }}
                            </label>
                            <input
                                id="mail-server-port"
                                type="number"
                                [placeholder]="l10nKeys.smtpPortPlaceholder | vtranslate"
                                name="mail-server-port"
                                [(ngModel)]="emailSmtpConfig.mail_server_port"
                                configFieldInputValidation
                                [objectType]="objectType"
                                fieldName="mail_server_port"
                                clrInput
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
            </ng-container>

            <avi-input-container *ngIf="emailSmtpType.isSmtpTypeServer">
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="auth_username"
                    for="auth-username"
                    required
                >
                    {{ globalL10nKeys.usernameLabel | vtranslate }}
                </label>
                <input
                    id="auth-username"
                    name="auth-username"
                    [placeholder]="globalL10nKeys.usernamePlaceholder | vtranslate"
                    [(ngModel)]="emailSmtpConfig.auth_username"
                    clrInput
                    required
                />
            </avi-input-container>

            <avi-input-container *ngIf="emailSmtpType.isSmtpTypeServer">
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="auth_password"
                    for="auth-password"
                    required
                >
                    {{ globalL10nKeys.passwordLabel | vtranslate }}
                </label>
                <input
                    id="auth-password"
                    name="auth-password"
                    type="password"
                    [placeholder]="globalL10nKeys.passwordPlaceholder | vtranslate"
                    [(ngModel)]="emailSmtpConfig.auth_password"
                    clrInput
                    required
                />
            </avi-input-container>

            <avi-input-container [noMarginTop]="emailSmtpType.isSmtpTypeLocal">
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="from_email"
                    for="from-email"
                >
                    {{ l10nKeys.emailAddressInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="from-email"
                    name="from-email"
                    type="email"
                    class="sel-from-email"
                    [placeholder]="l10nKeys.emailAddressInputLabel | vtranslate"
                    [(ngModel)]="emailSmtpConfig.from_email"
                    regexPattern="email"
                />
            </avi-input-container>

            <clr-checkbox-container *ngIf="emailSmtpType.isSmtpTypeServer || emailSmtpType.isSmtpTypeAnonymousServer">
                <clr-checkbox-wrapper>
                    <input
                        clrCheckbox
                        type="checkbox"
                        name="disable-tls"
                        id="disable-tls"
                        [(ngModel)]="emailSmtpConfig.disable_tls"
                    />
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectType"
                        fieldName="disable_tls"
                        for="disable-tls"
                    >
                        {{ l10nKeys.doNotUseTlsLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
