<span class="clr-wrapper avi-tooltip-icon">
    <cds-icon
        [attr.shape]="shape"
        [attr.size]="size"
        class="avi-tooltip-icon__icon"
        [ngClass]="iconClassName"
        avi-tooltip
        (onPositionChange)="handlePositionChange($event)"
        [delay]="delay"
        [showOnClick]="showOnClick"
        backdropClass="avi-tooltip-icon__backdrop"
        [positionsPriority]="positionsPriority"
        [attr.solid]="solid"
        [attr.status]="status"
    >
        <ng-template #aviTooltipContent>
            <ng-content select="avi-tooltip-icon_content"></ng-content>
        </ng-template>
    </cds-icon>
</span>
