<div
    provideParentForm
    class="backup-passphrase-form"
>
    <div [ngClass]="{'full-modal-config__form-control-row': singleRow}">
        <div class="full-modal-config__form-control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="BackupConfiguration"
                    fieldName="backup_passphrase"
                    for="passphrase"
                    required
                >
                    {{ l10nKeys.passphraseLabel | vtranslate }}
                </label>
                <input
                    id="passphrase"
                    class="sel-avi-welcome-passphrase"
                    name="backup-passphrase-passphrase"
                    type="password"
                    [(ngModel)]="value"
                    clrInput
                    placeholder="{{ l10nKeys.passphrasePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    tooltipText="{{ l10nKeys.confirmPassphraseLabel | vtranslate }}"
                    for="confirmPassphrase"
                    required
                >
                    {{ l10nKeys.confirmPassphraseLabel | vtranslate }}
                </label>
                <input
                    id="confirmPassphrase"
                    class="sel-avi-welcome-confirm-passphrase"
                    name="backup-passphrase-confirm-passphrase"
                    type="password"
                    [(ngModel)]="confirmPassphrase"
                    clrInput
                    placeholder="{{ l10nKeys.confirmPassphrasePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>
    </div>
</div>
