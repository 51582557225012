/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection, Item } from 'ajs/modules/data-model/factories';

const dataSources = {
    list: {
        source: 'ListCollDataSourceWithISearchParam',
        transformer: 'SubnetListNetworkDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

interface IArgs {
    cloudId: string;
}

/**
 * @alias SubnetListNetworkCollection
 * @description
 *     Collection of {@link SubnetListNetwork} items.
 * @author Alex Malitsky, Ram Pal
 */
export class SubnetListNetworkCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'networksubnetlist',
            defaultDataSources: 'list',
            allDataSources: dataSources,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('SubnetListNetwork');
    }

    /** @override */
    public isCreatable(): boolean {
        return false;
    }

    /**
     * Need to pass 'cloudId' property to SubnetListNetwork constructor.
     * @override
     */
    public getNewItem(args: IArgs): Item {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const stringService = this.getAjsDependency_('stringService');
        let cloudId = this.getParams('cloud_uuid');

        if (!cloudId) {
            // assuming here that networksubnetlist is using default cloud when
            // cloud_uuid param is not set
            const cloudRef = defaultValues.getSystemObjectRefByName(
                'cloud',
                'Default-Cloud',
            );

            cloudId = stringService.slug(cloudRef);

            console.warn(
                'SubnetListNetwork item requested wo cloudId passed',
            );
        }

        args.cloudId = cloudId;

        return super.getNewItem(args);
    }
}

SubnetListNetworkCollection.ajsDependencies = [
    'SubnetListNetwork',
    'defaultValues',
    'stringService',
];
