/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { GslbThirdPartySite } from 'object-types';

import {
    GslbGeoLocationSource,
} from 'generated-types';

import {
    GslbThirdPartySiteConfigItem,
} from 'message-items/gslb-third-party-site.config-item.factory';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-third-party-site-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Handles the configuration of geo location and health monitor proxy
 * for third party sites within the GSLB site modal.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-third-party-site-config',
    templateUrl: './gslb-third-party-site-config.component.html',
})
export class GslbThirdPartySiteConfigComponent implements OnInit {
    @Input()
    public editable: GslbThirdPartySiteConfigItem;

    /**
     * List of active gslb Sites options available.
     */
    @Input()
    public activeGslbSiteOptions: IAviDropdownOption[] = [];

    /**
     * Shows the geo location section if the user has configured the location.
     */
    public isLocationConfigured: boolean;

    public readonly objectType = GslbThirdPartySite;

    /**
     * Informs if current type mapping is being edited, or new one created.
     */
    public readonly isEditing: boolean;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setLocationConfigurationStatus();
    }

    /**
     * Set geo location source.
     * In case the location is user configured, initialize the location object.
     * In case the location is not configured, delete location field.
     */
    public setGeoLocationSource(isLocationChecked: boolean): void {
        if (isLocationChecked) {
            this.editable.onConfigureUserLocation();
            this.editable.config.location
                .setSource(GslbGeoLocationSource.GSLB_LOCATION_SRC_USER_CONFIGURED);
            this.isLocationConfigured = true;
        } else {
            delete this.editable.config.location;
            this.isLocationConfigured = false;
        }
    }

    /**
     * Set isLocationConfigured to true if gslb site location is present.
     */
    private setLocationConfigurationStatus(): void {
        this.isLocationConfigured = !!this.editable.config.location;
    }
}
