/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for policy related things.
 * @author Akul Aggarwal
 */

export const BOT_MAPPING_RULE_MATCH_TARGET_CONFIG_ITEM_TOKEN =
    'BotMappingRuleMatchTargetConfigItem';

/**
 * Ajs dependency token for NatPolicyCollection.
 */
export const NAT_POLICY_COLLECTION_TOKEN = 'NatPolicyCollection';
