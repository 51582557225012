/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

import { CountryFlagImageSourcePipe } from '../../pipes/country-flag-image-source.pipe';

import './vs-log-national-flag.directive.less';

/**
 * @description
 *     Direcitive used to set .png national flag icon style.
 * @author Zhiqian Liu
 */
@Directive({
    selector: '[vsLogNationalFlag]',
})
export class VsLogNationalFlagDirective implements OnInit, OnChanges {
    /**
     * ISO country code. Can be a two-letter or three-letter code.
     *     ex. France can be represented by 'FR' or 'FRA'.
     * The code for an undocumented country is 'Unknown'.
     */
    @Input()
    public countryCode: string;

    /**
     * The native HTML element that the directive is bound to.
     */
    private domElement: HTMLElement;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
        private readonly countryFlagImageSourcePipe: CountryFlagImageSourcePipe,
    ) {
        this.domElement = this.elementRef.nativeElement;
        this.renderer.addClass(this.domElement, 'vs-log-national-flag');
    }

    /** @override */
    public ngOnInit(): void {
        this.setFlag();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { countryCode } = changes;

        if (countryCode && !countryCode.firstChange) {
            this.setFlag();
        }
    }

    /**
     * Set styles for adding the flag.
     */
    private setFlag(): void {
        if (this.countryCode === 'Unknown') {
            // add a location pin icon as a flag placeholder
            this.renderer.addClass(this.domElement, 'icon-location');
        } else {
            const flagImgSrc = this.countryFlagImageSourcePipe.transform(this.countryCode);

            if (flagImgSrc) {
                const srcUrl = `url(${flagImgSrc})`;

                this.renderer.setStyle(this.domElement, 'background-image', srcUrl);
            }
        }
    }
}
