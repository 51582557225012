/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    AviPermissionResource,
    HealthMonitorType,
    IHealthMonitor,
    IHealthMonitorAuthInfo,
    IHealthMonitorDNS,
    IHealthMonitorExternal,
    IHealthMonitorRadius,
    IHealthMonitorSIP,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
} from 'generated-types';

import { HealthMonitor } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorModalComponent }
    from 'ng/modules/health-monitor/components/health-monitor-modal/health-monitor-modal.component';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { HealthMonitorHttpConfigItem } from './health-monitor-http.config.item.factory';
import { HealthMonitorImapConfigItem } from './health-monitor-imap.config-item.factory';
import { HealthMonitorPop3ConfigItem } from './health-monitor-pop3.config-item.factory';

import {
    AUTHENTICATION,
    HmTypesWithAuthenticationField,
    IHealthMonitorConfig,
    IHealthMonitorData,
} from '../healthmonitor.types';

import * as l10n from '../healthmonitor.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Item.
 * @author Ashish Verma, Rajawant Prajapati
 */
export class HealthMonitorItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Correspondence hash between HMon type and it's config property.
     */
    private static typeToConfigProp = {
        HEALTH_MONITOR_PING: '', // no fields needed
        HEALTH_MONITOR_TCP: 'tcp_monitor',
        HEALTH_MONITOR_UDP: 'udp_monitor',
        HEALTH_MONITOR_HTTP: 'http_monitor',
        HEALTH_MONITOR_HTTPS: 'https_monitor',
        HEALTH_MONITOR_IMAPS: 'imaps_monitor',
        HEALTH_MONITOR_POP3S: 'pop3s_monitor',
        HEALTH_MONITOR_POP3: 'pop3_monitor',
        HEALTH_MONITOR_IMAP: 'imap_monitor',
        HEALTH_MONITOR_DNS: 'dns_monitor',
        HEALTH_MONITOR_EXTERNAL: 'external_monitor',
        HEALTH_MONITOR_SIP: 'sip_monitor',
        HEALTH_MONITOR_RADIUS: 'radius_monitor',
        HEALTH_MONITOR_SCTP: 'sctp_monitor',
        HEALTH_MONITOR_SMTP: 'smtp_monitor',
        HEALTH_MONITOR_SMTPS: 'smtps_monitor',
        HEALTH_MONITOR_LDAP: 'ldap_monitor',
        HEALTH_MONITOR_LDAPS: 'ldaps_monitor',
        HEALTH_MONITOR_FTP: 'ftp_monitor',
        HEALTH_MONITOR_FTPS: 'ftps_monitor',
    };

    /**
     * Health monitor data.
     */
    public data: IHealthMonitorData;

    /**
     * Function to get health monitor config.
     */
    public getConfig: () => IHealthMonitorConfig;

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'healthmonitor',
            objectType: HealthMonitor,
            windowElement: HealthMonitorModalComponent,
            permissionName: AviPermissionResource.PERMISSION_HEALTHMONITOR,
            params: {
                include_name: true,
            },
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Get sub config for HTTP/HTTPS Health Monitor Types.
     */
    public get healthMonitorTypeConfig(): HealthMonitorHttpConfigItem {
        const config = this.getConfig();
        const type = this.getType();

        return config[HealthMonitorItem.typeToConfigProp[type]];
    }

    /**
     * Get sub config for IMAPS Health Monitor Types.
     */
    public get healthMonitorImapTypeConfig(): HealthMonitorImapConfigItem {
        const config = this.getConfig();
        const type = this.getType();

        return config[HealthMonitorItem.typeToConfigProp[type]];
    }

    /**
     * Get sub config for POP3 Health Monitor Types.
     */
    public get healthMonitorPop3TypeConfig(): HealthMonitorPop3ConfigItem {
        const config = this.getConfig();
        const type = this.getType();

        return config[HealthMonitorItem.typeToConfigProp[type]];
    }

    /**
     * Reset http/https authentication config.
     */
    public clearHttpAuthentication(): void {
        this.getConfig().authentication.updateConfig({});
    }

    /**
     * Reset exact_http_request
     */
    public resetExactHttpRequest(): void {
        this.healthMonitorTypeConfig.config.exact_http_request = false;
    }

    /**
     * Handles Health Monitor type change.
     * Only works on HM creation.
     */
    public onTypeChange(): void {
        const config = this.getConfig();

        // Note: Need to delete authentication here instead of in 'clearIrrelevantMonitorSubObjects'
        // method as that one is also called in beforeEdit and dont want
        // to clear the auth details if those are already present in any type HM.(AV-164633)
        if (config.authentication) {
            delete config.authentication;
        }

        delete config.monitor_port;
        this.clearIrrelevantMonitorSubObjects();
        this.resetMonitorConfigIfUnset();
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        this.clearIrrelevantMonitorSubObjects();
        // Note: Following needed only because currently existing HTTPS Health Monitors may not
        // have the HTTPS_MONITOR sub-object, which may cause error in UI
        this.resetMonitorConfigIfUnset();
        config.folder = '';

        if (this.getType() === HealthMonitorType.HEALTH_MONITOR_IMAPS) {
            config.folder = config.imaps_monitor.config.folder;
        } else if (this.getType() === HealthMonitorType.HEALTH_MONITOR_IMAP) {
            config.folder = this.config.imap_monitor.config.folder;
        }
    }

    /**
     * @override
     */
    public dataToSave(): IHealthMonitor {
        const config = super.dataToSave();

        if (this.isDnsType() && 'monitor_port' in config && !config.is_federated) {
            delete config.is_federated;
        }

        if (this.getType() === HealthMonitorType.HEALTH_MONITOR_IMAPS) {
            config.imaps_monitor.folder = config.folder;
        } else if (this.getType() === HealthMonitorType.HEALTH_MONITOR_IMAP) {
            config.imap_monitor.folder = config.folder;
        }

        if (config.folder) {
            delete config.folder; // delete ui only field.
        }

        return config;
    }

    /**
     * Returns DNS Health Monitor message item.
     */
    public get dnsMonitor(): MessageItem<IHealthMonitorDNS> {
        const { dns_monitor: dnsMonitor } = this.config;

        return dnsMonitor;
    }

    /**
     * Returns External Health Monitor message item.
     */
    public get externalMonitor(): MessageItem<IHealthMonitorExternal> {
        const { external_monitor: externalMonitor } = this.config;

        return externalMonitor;
    }

    /**
     * Returns Radius Health Monitor message item.
     */
    public get radiusMonitor(): MessageItem<IHealthMonitorRadius> {
        const { radius_monitor: radiusMonitor } = this.config;

        return radiusMonitor;
    }

    /**
     * Returns SIP Health Monitor message item.
     */
    public get sipMonitor(): MessageItem<IHealthMonitorSIP> {
        const { sip_monitor: sipMonitor } = this.config;

        return sipMonitor;
    }

    /**
     * Returns authentication message item.
     */
    public get authentication(): MessageItem<IHealthMonitorAuthInfo> {
        const { authentication } = this.config;

        return authentication;
    }

    /**
     * Returns TCP Monitor message item.
     */
    public get tcpMonitor(): MessageItem<IHealthMonitorTcp> {
        const { tcp_monitor: tcpMonitor } = this.config;

        return tcpMonitor;
    }

    /**
     * Returns UDP Monitor message item.
     */
    public get udpMonitor(): MessageItem<IHealthMonitorUdp> {
        const { udp_monitor: udpMonitor } = this.config;

        return udpMonitor;
    }

    /**
     * Returns Health Monitor type.
     */
    public getType(): HealthMonitorType {
        const config = this.getConfig();

        return config?.type;
    }

    /**
     * Checks if Health Monitor item is HTTPS type.
     */
    public isHttpsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_HTTPS;
    }

    /**
     * Checks if Health Monitor item is IMAPS type.
     */
    public isImapsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_IMAPS;
    }

    /**
     * Checks if Health Monitor item is POP3S type.
     */
    public isPop3sType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_POP3S;
    }

    /**
     * Checks if Health Monitor item is DNS type.
     */
    public isDnsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_DNS;
    }

    /**
     * Return if the selected health monitor type is SMTPS type.
     */
    public isSmtpsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_SMTPS;
    }

    /**
     * Return if the selected health monitor type is LDAPS type.
     */
    public isLdapsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_LDAPS;
    }

    /**
     * Return if the selected health monitor type is FTPS type.
     */
    public isFtpsType(): boolean {
        return this.getType() === HealthMonitorType.HEALTH_MONITOR_FTPS;
    }

    /**
     * Called when TCP Half-Open checkbox is selected. Removes properties that cannot be
     * configured when enabled.
     */
    public onSelectTcpHalfOpen(): void {
        const { tcp_monitor: { config } } = this.config;
        const { tcp_half_open: tcpHalfOpen } = config;

        if (tcpHalfOpen) {
            delete config.maintenance_response;
            delete config.tcp_request;
            delete config.tcp_response;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.healthMonitorBreadcrumbTitle);
    }

    /**
     * Clears all existing and non-relevant monitor sub-objects (typeConfigProps).
     */
    private clearIrrelevantMonitorSubObjects(): void {
        const config = this.getConfig();
        const propNameToPreserve = HealthMonitorItem.typeToConfigProp[config.type];

        /**
         * Removes propName if exists, and if is not propNameToPreserve.
         */
        function deleteMonitor(propName: string): void {
            if (!(propName in config)) {
                return;
            }

            if (propNameToPreserve !== propName) {
                delete config[propName];
            }
        }

        const typeConfigProps = Object.values(HealthMonitorItem.typeToConfigProp)
            .filter(property => property !== '');

        typeConfigProps.forEach(deleteMonitor);
    }

    /**
     * Initializes default or empty monitor object (of type typeConfigProps), if applicable,
     * and if doesn't exist.
     */
    private resetMonitorConfigIfUnset(): void {
        const config = this.getConfig();

        const monitorConfig = HealthMonitorItem.typeToConfigProp[config.type];

        // Add 'authentication' field if selected Health Monitor type is HTTP/HTTPS/EXTERNAL.
        if (!config.authentication && HmTypesWithAuthenticationField.has(config.type)) {
            this.safeSetNewChildByField(AUTHENTICATION);
        }

        // Note: Following only needed because resetMonitorConfigIfUnset_
        // is used in beforeEdit,otherwise resetMonitorConfigIfUnset_ would override
        // existing sub-object with empty one.
        if (config[monitorConfig]) {
            return;
        }

        if (monitorConfig) {
            config[monitorConfig] = this.createChildByField(monitorConfig);
        }
    }
}
