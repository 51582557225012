/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    Type,
    ViewChild,
} from '@angular/core';
import { debounce, each } from 'underscore';
import { Subscription } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';
import classnames from 'classnames';
import {
    AviDismissChangesConfirmationComponent,
    AviDismissChangesWarningType,
} from 'ng/modules/dialog/components/avi-dismiss-changes-confirmation';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import * as globalL10n from 'global-l10n';
import { FullModalTabSectionComponent } from './full-modal-tab-section';
import './full-modal-config.component.less';

const { ...globalL10nKeys } = globalL10n;
const ESCAPE_KEY_CODE = 27;

export interface IFullModalTab {
    title: string;
    id: string;
    tabComponent: FullModalTabSectionComponent;
}

const BODY_CONTENT_BASE_CLASSNAME = 'full-modal-config__body-content';

/**
 * @description Component for displaying a configuration modal.
 *
 * This component is also responsible for setting the min-height of the last tab section to the
 * height of the modal body. This is done to prevent the case where both the second-to-last and last
 * tab sections are fully viewable. In that scenario, the second-to-last tab will always be the one
 * highlighted, which can cause confusion for the user.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    selector: 'full-modal-config',
    templateUrl: './full-modal-config.component.html',
})
export class FullModalConfigComponent implements OnInit, OnDestroy, AfterViewInit {
    public static aviDismissChangesConfirmationId = 'avi-dismiss-changes-id';

    /**
     * Title of the modal, typically `${objectType}`.
     */
    @Input()
    public modalTitle: string;

    /**
     * Sub title of modal, typically `${name}`.
     */
    @Input()
    public modalSubTitle: string;

    /**
     * Text to be shown on the Cancel button.
     */
    @Input()
    public cancelButtonText: string;

    /**
     * Text to be shown on the Submit button.
     */
    @Input()
    public submitButtonText: string;

    /**
     * True if the data from the parent form has been modified. Used to determine whether the
     * 'Discard changes' prompt should be shown.
     */
    @Input()
    public modified = false;

    /**
     * True if the spinner should be shown in the footer, disabling the action buttons.
     */
    @Input()
    public busy = false;

    /**
     * Errors to be shown on top of the modal.
     */
    @Input()
    public errors: string | object;

    /**
     * If false, will disable the Submit button.
     */
    @Input()
    public valid = true;

    /**
     * Optional flag to disable tabs in full-modal.
     */
    @Input()
    public disableTabs ?= false;

    /**
     * If true, will hide the submit button.
     */
    @Input()
    public hideSubmit ?= false;

    /**
     * Optional class name for full-modal.
     */
    @Input()
    public modalClassName ?= '';

    /**
     * Optional querylist of full modal tab sections.
     * This will be used when rendering through item-full-modal component.
     */
    @Input()
    public tabSectionsQueryList?: QueryList<FullModalTabSectionComponent>;

    /**
     * Called when the user wants to cancel or close the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Called when the user clicks the Submit button.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Gets the list of projected FullModalTabSection components.
     */
    @ContentChildren(FullModalTabSectionComponent, { descendants: true })
    private tabSections: QueryList<FullModalTabSectionComponent>;

    /**
     * Reference to the body div, used to retrieve the height for setting the min-height of the last
     * tab section.
     */
    @ViewChild('fullModalConfigBody', { read: ElementRef })
    private readonly fullModalConfigBody: ElementRef;

    /**
     * List of tabs to be rendered by the FullModalConfigHeaderTabsComponent.
     */
    public tabs: IFullModalTab[] = [];

    /**
     * Tab ID of the active tab.
     */
    public activeTabId: IFullModalTab['id'] | undefined;

    /**
     * True if the modal contains tabs. Used for setting classNames.
     */
    public hasTabs = false;

    /**
     * Observer to track intersections of tab sections. This is used to change the active navigation
     * tab as the user scrolls through the modal.
     */
    private tabObserver: IntersectionObserver;

    /**
     * Used to keep track of the intersection ratios of each tab section. We want the tab section
     * with the highest intersection ratio to be set as the active navigation tab.
     */
    private tabsIntersectionRatioHash: Record<string, number> = {};

    /**
     * Subscription to changes in the contentChildren, the list of FullModalTabSection components.
     */
    private tabChangesSubscription: Subscription;

    constructor(
        private elementRef: ElementRef,
        private readonly dialogService: DialogService,
        l10nService: L10nService,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
    ) {
        this.cancelButtonText = l10nService.getMessage(globalL10nKeys.cancelLabel);
        this.submitButtonText = l10nService.getMessage(globalL10nKeys.saveLabel);

        this.debouncedSetMinHeightOnLastTab = debounce(this.setMinHeightOnLastTab, 250);
    }

    /**
     * Getter for querylist of full modal tab sections.
     * Returns tab sections list if passed via item-full-modal component,
     * else via content children in full-modal-config component.
     */
    private get tabSectionsList(): QueryList<FullModalTabSectionComponent> {
        return this.tabSectionsQueryList || this.tabSections;
    }

    /**
     * Returns the native HTML element given a tab component.
     */
    public static getTabNativeElement(tab: FullModalTabSectionComponent): HTMLElement {
        return tab.elementRef.nativeElement;
    }

    /**
     * Listens for keydown events.
     * With FullModal, we might have a stack of modals where only the last modal is attached to the
     * DOM, so we check the isConnected property of the nativeElement to know if this modal is the
     * one attached.
     * If the Escape key is pressed, cancels the current modal.
     */
    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (!this.elementRef.nativeElement.isConnected) {
            return;
        }

        if (event.which === ESCAPE_KEY_CODE) {
            this.handleCancelAttempt();
        }
    }

    /**
     * Listens for window resize in order to reset the height of the last tab section.
     */
    @HostListener('window:resize')
    private onResize(): void {
        this.debouncedSetMinHeightOnLastTab();
    }

    /** @override */
    public ngOnInit(): void {
        // Puts focus on the modal, so that if a button was clicked to open this modal, pressing
        // the enter key does not trigger that button.
        this.elementRef.nativeElement.querySelector('.full-modal-config').focus();
    }

    /**
     * @override
     * Creates a new IntersectionObserver which tracks tabs in the viewport to set the active tab.
     * Also checks for changes in the tabs, as some tabs may be rendered/un-rendered based on modal
     * configuration.
     */
    public ngAfterViewInit(): void {
        const { nativeElement } = this.elementRef;

        // The handler registration is performed outside NgZone to avoid excessive change detections
        // caused by listening to scrolling events.
        this.zone.runOutsideAngular(() => {
            this.tabObserver = new IntersectionObserver(this.registerIntersectionObserver, {
                root: nativeElement.querySelector('.full-modal-config__body'),
                threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
            });
        });

        this.observeTabs(this.tabSectionsList);

        this.tabChangesSubscription = this.tabSectionsList.changes.subscribe(this.handleTabsChange);

        setTimeout(() => this.setMinHeightOnLastTab());
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.tabObserver) {
            this.tabObserver.disconnect();
        }

        if (this.tabChangesSubscription) {
            this.tabChangesSubscription.unsubscribe();
        }
    }

    /**
     * Called when the user tries to cancel or exit out of the modal. If the data has been modified,
     * we show a 'Confirm discard' confirmation in case the user wants to save changes. Otherwise,
     * the cancel handler is called.
     */
    public handleCancelAttempt(): void {
        const { aviDismissChangesConfirmationId } = FullModalConfigComponent;

        if (this.modified) {
            this.dialogService.add({
                id: aviDismissChangesConfirmationId,
                component: AviDismissChangesConfirmationComponent as Type<Component>,
                componentProps: {
                    warningType: AviDismissChangesWarningType.UNSAVED_CHANGES,
                    onConfirm: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                        this.handleCancel();
                    },
                    onClose: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                    },
                },
            });
        } else {
            this.handleCancel();
        }
    }

    /**
     * Handler for cancelling or exiting out of the modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Handler for submitting or saving the modal.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Called when selecting a tab. Scrolls to the DOM element with the tab's ID.
     */
    public handleSelectTab(tab: IFullModalTab): void {
        const tabElementRef = FullModalConfigComponent.getTabNativeElement(tab.tabComponent);

        tabElementRef.scrollIntoView({ behavior: 'instant' as ScrollBehavior });
    }

    /**
     * Return list of classnames set on the parent div.
     */
    public get fullModalConfigClassnames(): string {
        return classnames(this.modalClassName, this.hasTabs && 'full-modal-config--with-tabs');
    }

    /**
     * Return list of classnames set on the body content div.
     */
    public get bodyContentClassnames(): string {
        return classnames(
            BODY_CONTENT_BASE_CLASSNAME,
            this.hasTabs && `${BODY_CONTENT_BASE_CLASSNAME}--with-tabs`,
        );
    }

    /**
     * Returns the ID of the tab that should be marked as active. If no tabs have an intersection
     * ratio greater than 0, returns undefined.
     */
    private getActiveTabId(): string | undefined {
        let highestIntersectionRatio = 0;
        let activeId: string;

        each(this.tabsIntersectionRatioHash, (ratio: number, id: string) => {
            if (ratio > highestIntersectionRatio) {
                highestIntersectionRatio = ratio;
                activeId = id;
            }
        });

        return activeId;
    }

    /**
     * Method passed to the IntersectionObserver as the callback.
     * This handler is registerted outside NgZone to avoid excessive change detection cost.
     */
    private registerIntersectionObserver = (entries: IntersectionObserverEntry[]): void => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id') || '';

            this.tabsIntersectionRatioHash[id] = entry.intersectionRatio;
        });

        const activeId = this.getActiveTabId();

        // update this.activeTabId only when it changes, performed within NgZone to properly trigger
        // Angular change detection as it's bound in template
        if (this.activeTabId !== activeId) {
            this.zone.run(() => this.activeTabId = activeId);
        }
    };

    /**
     * Handler called when the number of tabs has changed. Recreates the list of tabs passed to the
     * FullModalHeaderTabs component and resets the observer that tracks tab scrolling.
     */
    private observeTabs = (tabSectionsList: QueryList<FullModalTabSectionComponent>): void => {
        this.tabObserver.disconnect();

        const tabs: IFullModalTab[] = [];

        tabSectionsList.forEach(tab => {
            const { tabId, tabTitle } = tab;
            const tabElement = FullModalConfigComponent.getTabNativeElement(tab);

            this.tabObserver.observe(tabElement);

            tabs.push({
                title: tabTitle,
                id: tabId,
                tabComponent: tab,
            });
        });

        this.tabsIntersectionRatioHash = {};
        this.tabs = tabs;
        this.hasTabs = Boolean(tabs.length);
    };

    /**
     * Handle changes to the number of tabs.
     */
    private handleTabsChange = (tabSectionsList: QueryList<FullModalTabSectionComponent>): void => {
        this.observeTabs(tabSectionsList);
        this.setMinHeightOnLastTab();
    };

    /**
     * Remove the min-height style from all tab sections.
     */
    private resetMinHeightsOnTabs(): void {
        this.tabSectionsList.forEach(tab => {
            this.renderer.setStyle(tab.elementRef.nativeElement, 'min-height', '');
        });
    }

    /**
     * Set the min-height on the last tab section.
     */
    private setMinHeightOnLastTab = (): void => {
        if (!this.tabSectionsList.length) {
            return;
        }

        this.resetMinHeightsOnTabs();

        const { last: lastTab } = this.tabSectionsList;
        const { nativeElement: bodyElement } = this.fullModalConfigBody;
        const { offsetHeight } = bodyElement;

        this.renderer.setStyle(
            lastTab.elementRef.nativeElement,
            'min-height',
            `${offsetHeight}px`,
        );
    };

    /**
     * Debounced version of this.setMinHeightOnLastTab. Set in the constructor.
     */
    private debouncedSetMinHeightOnLastTab = (): void => {};
}
