<div class="licensing-page-header clr-wrapper">
    <div class="licensing-page-header__header">
        <div class="licensing-page-header__header-text">
            <div
                class="licensing-page-header__status-container"
                *ngIf="isEnterpriseWithCloudServicesTier"
            >
                <avi-tooltip-icon-with-content-wrapper
                    shape="circle"
                    size="28"
                    [status]="licenseStatusAlertType"
                    [solid]="true"
                    *ngIf="enterpriseWithCloudServicesLicenseStatus"
                    class="licensing-page-header__saas-status"
                >
                    {{ enterpriseWithCloudServicesLicenseStatus.message }}
                </avi-tooltip-icon-with-content-wrapper>
                <div
                    class="licensing-page-header__spinner"
                    *ngIf="!enterpriseWithCloudServicesLicenseStatus"
                >
                    <clr-spinner [clrMedium]="true"></clr-spinner>
                </div>
            </div>
            <h2>{{ l10nKeys.licensingLabel | vtranslate }}</h2>
        </div>
        <div
            (click)="onCogClick()"
            class="licensing-page-header__header-icon"
        >
            <cds-icon
                shape="cog"
                status="info"
                size="17"
            ></cds-icon>
        </div>
    </div>
    <avi-alert-group
        *ngIf="showLicenseInfoAlertMessage"
        alertType="info"
    >
        {{ licenseInfoAlertMessage }}
    </avi-alert-group>
</div>
