<div
    provideParentForm
    [invalidateForm]="!action.config.pool_ref && !action.config.pool_group_ref"
>
    <avi-fieldset>
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="action_type"
                >
                    {{ l10nKeys.actionHeader | vtranslate }}
                </label>
                <avi-radio
                    name="select-pool"
                    [(ngModel)]="action.type"
                    [value]="L4RuleActionSelectPoolTypeEnum.L4_RULE_ACTION_SELECT_POOL"
                    (ngModelChange)="action.onActionTypeChange()"
                >
                    {{ l10nKeys.actionTypeRadioLabelPool | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="select-pool"
                    [(ngModel)]="action.type"
                    [value]="L4RuleActionSelectPoolTypeEnum.L4_RULE_ACTION_SELECT_POOLGROUP"
                    (ngModelChange)="action.onActionTypeChange()"
                >
                    {{ l10nKeys.actionTypeRadioLabelPoolGroup | vtranslate }}
                </avi-radio>
            </avi-radio-container>

        </avi-fieldset_header>
        <avi-fieldset_content>
            <ng-container *ngIf="isActionSelectPool()">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="pool_uuid"
                    for="pool_uuid"
                >
                    {{ l10nKeys.poolLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    [disableCreate]="true"
                    [disableEdit]="true"
                    [collection]="poolCollection"
                    [(ngModel)]="action.config.pool_ref"
                    placeholder="{{ l10nKeys.poolInputPlaceholder | vtranslate }}"
                    name="pool_uuid"
                ></avi-collection-dropdown>
            </ng-container>
            <ng-container *ngIf="isActionSelectPoolGroup()">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="pool_group_uuid"
                    for="pool_group_uuid"
                >
                    {{ l10nKeys.poolGroupLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    [disableCreate]="true"
                    [disableEdit]="true"
                    [collection]="poolGroupCollection"
                    [(ngModel)]="action.config.pool_group_ref"
                    placeholder="{{ l10nKeys.poolGroupInputPlaceholder | vtranslate }}"
                    name="pool_group_uuid"
                ></avi-collection-dropdown>
            </ng-container>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
