/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'message-item';
import { IVlanInterfaceData } from 'items/service-engine.item.factory';
import { VNICConfigItem } from 'message-items/vnic.config-item.factory';
import * as globalL10n from 'global-l10n';
import type {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import * as l10n from './in-use-interface-list-dialog.l10n';
import './in-use-interface-list-dialog.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Dialog to display in-use interfaces of a Service Engine.
 * @author alextsg
 */
@Component({
    selector: 'in-use-interface-list-dialog',
    templateUrl: './in-use-interface-list-dialog.component.html',
})
export class InUseInterfaceListDialogComponent implements AfterViewInit, OnInit {
    /**
     * List of in-use vNIC interfaces.
     */
    @Input()
    public activeVnicInterfaces: VNICConfigItem[] = [];

    /**
     * List of in-use VLAN interfaces.
     */
    @Input()
    public activeVlanInterfaces: Array<MessageItem<IVlanInterfaceData>> = [];

    /**
     * Service Engine UUID. Passed to the InUseInterfaceListExpandedContentComponent for retrieving
     * Floating IP addresses for a particular interface.
     */
    @Input()
    public seId: string;

    @Output()
    public onClose = new EventEmitter<void>();

    @ViewChild('interfaceSubnets')
    public interfaceSubnets: TemplateRef<HTMLElement>;

    @ViewChild('interfaceExpandedContentTemplateRef')
    public interfaceExpandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public activeVnicInterfacesGridConfig: IAviDataGridConfig;

    public activeVlanInterfacesGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.activeVnicInterfacesGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };

        this.activeVlanInterfacesGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        // Using a setTimeout here so that Clarity's datagrid resizes automatically with the data.
        setTimeout(() => {
            this.activeVnicInterfacesGridConfig = {
                ...this.activeVnicInterfacesGridConfig,
                fields: [
                    {
                        label: this.l10nService.getMessage(l10nKeys.interfaceColumnHeader),
                        id: 'interface',
                        transform: (row: VNICConfigItem) => row.config.if_name,
                    },
                    {
                        label: this.l10nService.getMessage(l10nKeys.networkNameColumnHeader),
                        id: 'network-name',
                        transform: (row: VNICConfigItem) => {
                            const { config } = row;

                            return config.network_name || config.linux_name;
                        },
                    },
                    {
                        label: this.l10nService.getMessage(globalL10nKeys.macAddressLabel),
                        id: 'mac-address',
                        transform: (row: VNICConfigItem) => row.config.mac_address,
                    },
                    {
                        label: this.l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                        id: 'ip-address',
                        templateRef: this.interfaceSubnets,
                    },
                ],
                expandedContentTemplateRef: this.interfaceExpandedContentTemplateRef,
            };

            this.activeVlanInterfacesGridConfig = {
                ...this.activeVlanInterfacesGridConfig,
                fields: [
                    {
                        label: this.l10nService.getMessage(l10nKeys.interfaceColumnHeader),
                        id: 'interface',
                        transform: (row: MessageItem<IVlanInterfaceData>) => row.config.if_name,
                    },
                    {
                        label: this.l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                        id: 'ip-address',
                        templateRef: this.interfaceSubnets,
                    },
                ],
                expandedContentTemplateRef: this.interfaceExpandedContentTemplateRef,
            };
        });
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Emit event to close the dialog.
     */
    public dismiss(): void {
        this.onClose.emit();
    }
}
