/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'licensing-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleDescription = `${prefix}.columnTitleDescription`;
export const columnTitleLicenseId = `${prefix}.columnTitleLicenseId`;
export const columnTitleTier = `${prefix}.columnTitleTier`;
export const columnTitleServiceCores = `${prefix}.columnTitleServiceCores`;
export const columnTitleSeSockets = `${prefix}.columnTitleSeSockets`;
export const columnTitleExpiry = `${prefix}.columnTitleExpiry`;
export const expiredLabel = `${prefix}.expiredLabel`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const licensesHeader = `${prefix}.licensesHeader`;

export const ENGLISH = {
    [columnTitleDescription]: 'Description',
    [columnTitleLicenseId]: 'License ID',
    [columnTitleTier]: 'Tier',
    [columnTitleServiceCores]: 'Service Cores',
    [columnTitleSeSockets]: 'Service Engine Sockets',
    [columnTitleExpiry]: 'Expiry',
    [expiredLabel]: 'Expired',
    [actionTitleDelete]: 'Delete',
    [licensesHeader]: 'Licenses',
};
