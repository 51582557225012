<full-modal-config
    modalTitle="{{ (editable.getName() ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="dns-resolver-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="resolver_name"
                        for="resolver_name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="resolver_name"
                        name="resolver_name"
                        [(ngModel)]="editable.config.resolver_name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-checkbox
                    objectType="{{ objectType }}"
                    fieldName="use_mgmt"
                    [(ngModel)]="editable.config.use_mgmt"
                    name="use-mgmt"
                >
                    {{ l10nKeys.useManagementCheckboxLabel | vtranslate }}
                </avi-checkbox>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            helperText="{{ globalL10nKeys.secondsLabel | vtranslate }}"
                            noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="fixed_ttl"
                                for="fixed_ttl"
                            >
                                {{ l10nKeys.fixedTtlInputLabel | vtranslate }}
                            </label>
                            <input
                                id="fixed_ttl"
                                name="fixed_ttl"
                                type="number"
                                [(ngModel)]="editable.config.fixed_ttl"
                                clrInput
                                placeholder="{{ l10nKeys.fixedTtlInputPlaceholderText | vtranslate }}"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="fixed_ttl"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            helperText="{{ globalL10nKeys.secondsLabel | vtranslate }}"
                            noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="min_ttl"
                                for="min_ttl"
                            >
                                {{ l10nKeys.minimumTtlInputLabel | vtranslate }}
                            </label>
                            <input
                                id="min_ttl"
                                name="min_ttl"
                                type="number"
                                [(ngModel)]="editable.config.min_ttl"
                                clrInput
                                placeholder="{{ l10nKeys.minimumTtlInputPlaceholderText | vtranslate }}"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="min_ttl"
                            />
                        </avi-input-container>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.nameServerHeaderWithCount | vtranslate : editable.nameServersCount }}
                        </h6>
                        <dns-resolver-nameservers-grid [nameServerIps]="editable.nameServerIps"></dns-resolver-nameservers-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
