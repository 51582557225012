/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CloudModule */

import {
    Component,
    Type,
} from '@angular/core';

import {
    IHttpResponse,
    IPromise,
    ITimeoutService,
} from 'angular';

import { each } from 'underscore';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';

import { withEditChildMessageItemMixin } from
    'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    AviPermissionResource,
    CloudType,
    GCPNetworkConfigMode,
    ICloud,
    ICustomTag,
    INsxtGroup,
    INsxtTier1,
    IpamDnsType,
    IvCloudAirConfiguration,
    IVrfContext,
    Privilege,
} from 'generated-types';

import { Cloud as CloudObject } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';
import * as l10n from 'ajs/modules/cloud/cloud.l10n';
import { MacroStackFactory } from 'ajs/modules/data-model/factories/macro-stack.factory';
import { IFullModalLayout } from 'ng/modules/core/services/full-modal/full-modal.service';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { VCenterServer } from './vcenter-server.item.factory';
import { VCenterServerCollection } from './vcenter-server.collection.factory';

import {
    DefaultSeUsageValues,
    LinuxServerConfigurationConfigItem,
} from './linux-server-configuration.config-item.factory';

import {
    AwsConfigurationConfigItem,
    AzureConfigurationConfigItem,
    DnsResolverConfigItem,
    GCPConfigurationConfigItem,
    NsxtConfigurationConfigItem,
    OpenStackConfigurationConfigItem,
    VCenterConfigurationConfigItem,
} from '.';

import {
    cloudsAllowingCustomVrfContext,
    cloudsAllowingNetworkCreation,
    cloudsWithCustomTags,
    configItemFieldsHash,
    DNS_RESOLVERS,
    IPAM_PROVIDER_REF_DATA,
    macroStackTypes,
    PROXY_CONFIGURATION,
    TYPE,
    vtypeConfigHash,
} from '../cloud.constants';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const VCENTER_REDISCOVER_API = '/api/vimgrvcenterruntime/initiate/rediscover';
const NSXT_GROUPS_API = '/api/nsxt/groups';
const NSXT_TIERS_API = '/api/nsxt/tier1s';
const VCA_GET_NETWORKS_API = '/api/vca-get-networks';

type TVCenterServerCollection = typeof VCenterServerCollection;
type TVCenterServer = typeof VCenterServer;
type TMacroStackFactory = typeof MacroStackFactory;

type TCloudPartial = Omit<ICloud,
'aws_configuration' |
'azure_configuration' |
'custom_tags' |
'dns_resolvers' |
'gcp_configuration' |
'linuxserver_configuration' |
'nsxt_configuration' |
'openstack_configuration' |
'proxy_configuration' |
'vca_configuration' |
'vcenter_configuration'
>;

interface ICloudConfig extends TCloudPartial {
    aws_configuration?: AwsConfigurationConfigItem;
    azure_configuration?: AzureConfigurationConfigItem;
    custom_tags?: RepeatedMessageItem<MessageItem<ICustomTag>>;
    dns_resolvers?: RepeatedMessageItem<DnsResolverConfigItem>;
    gcp_configuration?: GCPConfigurationConfigItem;
    linuxserver_configuration?: LinuxServerConfigurationConfigItem;
    nsxt_configuration?: NsxtConfigurationConfigItem;
    openstack_configuration?: OpenStackConfigurationConfigItem;
    vca_configuration?: MessageItem<IvCloudAirConfiguration>;
    vcenter_configuration?: VCenterConfigurationConfigItem;
}

interface ICloudState {
    state: string;
}

interface ICloudData {
    config: ICloudConfig;
    status?: ICloudState;
    mvrf?: IVrfContext;
}

/**
 * Type of response returned by vcenter rediscover api.
 */
interface IVcenterRediscover {
    rpc_status: number;
}

/**
 * @description Cloud Item.
 * @author Sarthak Kapoor
 */

export class Cloud extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
        '$timeout',
        'MacroStackFactory',
        'VCenterServerCollection',
        'VCenterServer',
        'stringService',
        'Auth',
        'NsxtConfigurationConfigItem',
        'aviAlertService',
        'schemaService',
        'ConfigItem',
        'MessageItem',
    ];

    /**
     * Set of cloud types providing a list of subnetworks.
     */
    private static subnetListCloudTypes = new Set([
        CloudType.CLOUD_OPENSTACK,
        CloudType.CLOUD_VCENTER,
        CloudType.CLOUD_AWS,
    ]);

    public data: ICloudData;

    /**
     * Directory Path value for Service Engine Usage for Linux Server Cloud.
     */
    public seUsagePath: string;

    /**
     * List of Subnets for VCA Cloud.
     */
    public vcaNwList: string[];

    /**
     * VCenter Datacenter polling timeout.
     */
    protected vcenterDCTimeout: IPromise<void> | null;

    /**
     * VCenter Networks polling timeout.
     */
    protected vcenterNetworkTimeout: IPromise<void> | null;

    protected readonly stringService: StringService;

    /**
     * MacroStack Factory Class to be instantiated inside separate methods.
     */
    private readonly MacroStackFactory: TMacroStackFactory;

    /**
     * Stores the macroStackFactory instance to be used for NSX cloud.
     */
    private readonly macroStackFactory: MacroStackFactory;

    private VCenterServerCollection: TVCenterServerCollection;

    private readonly VCenterServer: TVCenterServer;

    private readonly l10nService: L10nService;

    private readonly $timeout: ITimeoutService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'cloud',
            objectType: CloudObject,
            permissionName: AviPermissionResource.PERMISSION_CLOUD,
            windowElement: 'lazy-load',
            restrictEditOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.vcenterDCTimeout = null;
        this.vcenterNetworkTimeout = null;
        this.vcaNwList = [];
        this.stringService = this.getAjsDependency_('stringService');

        const MacroStackFactoryClass = this.getAjsDependency_('MacroStackFactory');

        this.macroStackFactory = new MacroStackFactoryClass();
        this.MacroStackFactory = MacroStackFactoryClass;

        this.VCenterServerCollection = this.getAjsDependency_('VCenterServerCollection');
        this.VCenterServer = this.getAjsDependency_('VCenterServer');
        this.$timeout = this.getAjsDependency_('$timeout');
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Return if the selected cloud type provides an endpoint to fetch subnet list.
     */
    public static hasSubnetList(cloudType: string): boolean {
        return Cloud.subnetListCloudTypes.has(cloudType as CloudType);
    }

    /**
     * Return if selected cloud type allows network creation
     */
    public static allowsNetCreation(cloudType: string): boolean {
        return cloudsAllowingNetworkCreation.has(cloudType as CloudType);
    }

    /**
     * Return the cloud name based on selected cloud type.
     */
    public static getCloudTypeName(cloudType: string): string {
        const schemaService = this.getAjsDependency_('schemaService');
        const { description } = schemaService.getEnumValue('CloudType', cloudType);

        return description || '';
    }

    /** @override */
    public beforeEdit(): void {
        const vtype = this.getVtype();

        this.macroStackFactory.add(this);

        switch (vtype) {
            case CloudType.CLOUD_LINUXSERVER:
                this.setLinuxSEUsagePathRadio();

                break;

            case CloudType.CLOUD_VCENTER: {
                const cloudConfig = this.getCloudConfig() as VCenterConfigurationConfigItem;

                const { management_network: managementNetwork } = cloudConfig.config;

                if (managementNetwork) {
                    const name = this.stringService.name(managementNetwork);
                    const uuid = this.stringService.slug(managementNetwork);

                    cloudConfig.config.management_network = `${uuid}#${name}`;
                }

                break;
            }
        }
    }

    /**
     * Return the cloud type.
     */
    public getVtype(): string {
        const { config } = this;

        return config?.vtype;
    }

    /**
     * Return cloud configuration object by vtype.
     */
    public getCloudConfig(vtype?: string): AwsConfigurationConfigItem |
    AzureConfigurationConfigItem |
    GCPConfigurationConfigItem |
    LinuxServerConfigurationConfigItem |
    NsxtConfigurationConfigItem |
    OpenStackConfigurationConfigItem |
    VCenterConfigurationConfigItem {
        const { config } = this;
        const cloudType = vtype || this.getVtype();

        return config[vtypeConfigHash[cloudType]];
    }

    /**
     * Set Directory Path for Service Engine Usage in Linux Server Cloud.
     */
    public setLinuxSEUsagePathRadio(): void {
        const { config } = this;
        const { linuxserver_configuration: linuxServerConfiguration } = config;

        if (!linuxServerConfiguration) {
            return;
        }

        const { config: linuxServerConfig } = linuxServerConfiguration;

        if (linuxServerConfig.se_sys_disk_path && linuxServerConfig.se_log_disk_path) {
            this.seUsagePath = DefaultSeUsageValues.BOTH;
        } else if (linuxServerConfig.se_sys_disk_path) {
            this.seUsagePath = DefaultSeUsageValues.SINGLE;
        } else {
            this.seUsagePath = DefaultSeUsageValues.AUTO;
        }
    }

    /**
     * Return GCP configuration object.
     */
    public getGcpConfigurationConfig(): GCPConfigurationConfigItem {
        const { gcp_configuration: gcpConfiguration = {} } = this.getConfig();

        return gcpConfiguration;
    }

    /**
     * Return NSX configuration object.
     */
    public getNsxtConfigurationConfig(): NsxtConfigurationConfigItem {
        const { nsxt_configuration: nsxtConfiguration = {} } = this.getConfig();

        return nsxtConfiguration;
    }

    /**
     * Return AWS configuration object.
     */
    public getAwsConfigurationConfig(): AwsConfigurationConfigItem {
        const { aws_configuration: awsConfiguration = {} } = this.getConfig();

        return awsConfiguration;
    }

    /** @override */
    public dataToSave(): ICloud {
        const config = super.dataToSave();
        const vtype = this.getVtype();

        each(vtypeConfigHash, (vtypeConfigProp: string, currVtype: string) => {
            if (currVtype !== vtype) {
                delete config[vtypeConfigProp];
            }
        });

        switch (vtype) {
            case CloudType.CLOUD_OPENSTACK: {
                config.dhcp_enabled = true;
                config.ipam_provider_ref = undefined;

                break;
            }

            case CloudType.CLOUD_VCA: {
                config.vca_configuration.privilege = Privilege.WRITE_ACCESS;
                config.dhcp_enabled = true;
                config.ipam_provider_ref = undefined;

                break;
            }
        }

        return config;
    }

    /**
     * Safeset config properties based on newly selected cloud type.
     * Remove the previously set configurations, if any.
     */
    public onCloudTypeChange(cloudType: string): void {
        const { config } = this;

        this.removeCloudConfig();

        if (cloudType) {
            config.vtype = cloudType;
        }

        const vtype = this.getVtype();
        const defaultCloudConfig = this.getDefaultConfig_();

        if (vtype in configItemFieldsHash) {
            this.safeSetNewChildByField(configItemFieldsHash[vtype]);
        } else {
            const configName = vtypeConfigHash[vtype];

            config[configName] = defaultCloudConfig[configName];
        }

        if (!cloudsWithCustomTags.has(vtype as CloudType)) {
            delete config.custom_tags;
        } else {
            this.safeSetNewChildByField('custom_tags');
        }

        if (vtype === CloudType.CLOUD_LINUXSERVER) {
            this.setLinuxSEUsagePathRadio();
        }

        this.resetLicenseProps();
    }

    /**
     * Save Cloud without any particularly set Cloud type.
     */
    public setCloudNone(): IPromise<IHttpResponse<ICloud>> {
        this.onCloudTypeChange('CLOUD_NONE');

        return this.save(false);
    }

    /**
     * Send rediscover command to server if discovery or credentials fail.
     */
    public vCenterRediscover(): IPromise<IHttpResponse<IVcenterRediscover>> {
        const payload = { cloud: this.id };

        return this.request(
            'post',
            VCENTER_REDISCOVER_API,
            payload,
        );
    }

    /**
     * @override
     * Cancel any pending requests when modal is closed.
     */
    public dismiss(silent: boolean): void {
        super.dismiss(silent);
        this.cancelTimeouts();
    }

    /**
     * @override
     * Override since standard API response with 'runtime' is not present.
     * Return the status for each cloud.
     */
    public getRuntimeData(): ICloudState | null {
        return this.data?.status || null;
    }

    /**
     * Remove proxy_configuration object from cloud config.
     */
    public resetProxyConfigFields(useConfig: boolean): void {
        if (!useConfig) {
            const { config } = this;

            delete config.proxy_configuration;
        } else {
            this.setNewChildByField(PROXY_CONFIGURATION);
        }
    }

    /**
     * Return button to Select Servers by Network should be displayed.
     */
    public allowSelectServersByNetwork(): boolean {
        const auth = this.getAjsDependency_('Auth');

        if (!auth.isCategoryAllowed('UI_INFRASTRUCTURE', 'rw')) {
            return false;
        }

        if (!this.allowNetworkCreate()) {
            return true;
        }

        if (!this.hasIpamProviderProfile()) {
            return false;
        }

        return this.hasAwsIpamProfile();
    }

    /**
     * Return if the Cloud has a configured IPAM provider profile.
     */
    public hasIpamProviderProfile(): boolean {
        const { config } = this;

        return Boolean(config.ipam_provider_ref);
    }

    /**
     * Return if the selected cloud type supports custom VRF context.
     */
    public allowCustomVRFContext(): boolean {
        return cloudsAllowingCustomVrfContext.has(this.getVtype() as CloudType);
    }

    /**
     * Return if the configured vtype matches the specified vtype.
     */
    public isVtype(vtype: string): boolean {
        return this.getVtype() === vtype;
    }

    /**
     * Add new DNS Resolver to current cloud config.
     */
    public addDnsResolver(): void {
        this.addChildMessageItem({
            field: DNS_RESOLVERS,
        });
    }

    /**
     * Edit DNS Resolver Config.
     */
    public editDnsResolver(dnsResolverConfigItem: DnsResolverConfigItem): void {
        this.editChildMessageItem({
            field: DNS_RESOLVERS,
            messageItem: dnsResolverConfigItem,
        });
    }

    /**
     * Return if cloud config or child configs are busy.
     */
    public isBusy(): boolean {
        const ConfigItem = this.getAjsDependency_('ConfigItem');
        const MessageItem = this.getAjsDependency_('MessageItem');
        const cloudConfig = this.getCloudConfig();

        return this.busy ||
            cloudConfig instanceof ConfigItem && cloudConfig.busy ||
            cloudConfig instanceof MessageItem && cloudConfig.busy ||
            false;
    }

    /**
     * Return the error from Cloud item or child config items.
     */
    public getErrors(): string | Record<string, any> {
        const ConfigItem = this.getAjsDependency_('ConfigItem');
        const MessageItem = this.getAjsDependency_('MessageItem');
        const cloudConfig = this.getCloudConfig();

        return this.errors ||
            cloudConfig instanceof ConfigItem && cloudConfig.errors ||
            cloudConfig instanceof MessageItem && cloudConfig.errors;
    }

    /**
     * Return if the selected cloud type provides an endpoint to fetch subnet list.
     */
    public hasSubnetList(): boolean {
        if (!Cloud.subnetListCloudTypes.has(this.getVtype() as CloudType)) {
            return false;
        }

        if (this.hasIpamProviderProfile()) {
            // Certain IPAM profiles also affect that API endpoint.
            const type = this.getIpamProfileType();

            return type.indexOf(IpamDnsType.IPAMDNS_TYPE_INFOBLOX) === -1;
        }

        return true;
    }

    /**
     * Destroy NSXT Cloud Network instance and NSXT Config instance.
     * @override
     */
    public destroy(): boolean {
        const NsxtConfigurationConfigItem = this.getAjsDependency_('NsxtConfigurationConfigItem');
        const cloudConfig = this.getCloudConfig();
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            if (cloudConfig instanceof NsxtConfigurationConfigItem) {
                cloudConfig.destroy();
            }
        }

        return gotDestroyed;
    }

    /**
     * Return if autoscale functionality is available for the selected cloud type.
     */
    public isServerAutoScaleAvailable(): boolean {
        const cloudType = this.getVtype();

        return cloudType === CloudType.CLOUD_AWS || cloudType === CloudType.CLOUD_AZURE;
    }

    /**
     * Add an item as a child of cloud to MacroStack.
     */
    public addChildToMacroStack(item: Item): void {
        this.macroStackFactory.add(item, this);
    }

    /**
     * Delete an Item from the MacroStack.
     */
    public deleteChildFromMacroStack(item: Item): void {
        this.macroStackFactory.delete(item);
    }

    /**
     * Return list of NSX security groups used in pool configuration.
     */
    public getNsxtNSGroupsByID(vrfcontext: string): IPromise<INsxtGroup[]> {
        if (this.getVtype() !== CloudType.CLOUD_NSXT) {
            throw new Error('Not applicable for non-NSX type clouds.');
        }

        if (!this.id) {
            return Promise.resolve([]);
        }

        this.busy = true;
        this.errors = null;

        const payload = {
            cloud_uuid: this.id,
            vrf_context: vrfcontext,
        };

        return this.request('POST', NSXT_GROUPS_API, payload)
            .then(({ data }) => data.resource.nsxt_groups)
            .catch(({ data }) => {
                this.errors = data;

                return Promise.reject(data);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Return list of NSX Tier1 Logical Routers used in VsVip configuration.
     */
    public getNsxtTier1LogicalRoutersByID(vrfcontext: string): IPromise<INsxtTier1[]> {
        if (this.getVtype() !== CloudType.CLOUD_NSXT) {
            throw new Error('Not applicable for non-NSX type clouds.');
        }

        if (!this.id) {
            return Promise.resolve([]);
        }

        this.busy = true;
        this.errors = null;

        const payload = {
            cloud_uuid: this.id,
            vrf_context: vrfcontext,
        };

        return this.request('POST', NSXT_TIERS_API, payload)
            .then(({ data }) => data.resource.nsxt_tier1routers)
            .catch(({ data }) => {
                this.errors = data;

                return Promise.reject(data);
            })
            .finally(() => this.busy = false);
    }

    /**
     * In case of NSX cloud remove the VCenterServers first.
     * @override
     */
    public drop(...args: any[]): IPromise<IHttpResponse<ICloud>> {
        if (this.getVtype() === CloudType.CLOUD_NSXT && this.isDroppable()) {
            return this.dropNsxtCloud() as unknown as IPromise<IHttpResponse<ICloud>>;
        }

        return super.drop(...args);
    }

    /**
     * Return if selected cloud type supports DNS in VS-VIP config.
     */
    public hasDnsConfigurationSupport(): boolean {
        return this.hasDnsProviderProfile() ||
            this.useAzureDns() ||
            this.hasAwsRoute53Integration();
    }

    /**
     * Return count of DNS Resolvers.
     */
    public get dnsResolversCount(): number {
        const { dns_resolvers: dnsResolvers } = this.config;

        return dnsResolvers.count;
    }

    /**
     * Remove dns_provider_ref from Azure cloud config.
     */
    public removeDnsProviderRef(): void {
        const { config } = this;

        delete config.dns_provider_ref;
    }

    /**
     * Add a new row to Custom Tags grid.
     */
    public addCustomTagRow(): void {
        const { custom_tags: customTags } = this.config;

        customTags.add();
    }

    /**
     * Remove the selected custom tag.
     */
    public deleteCustomTagRow(customTag: MessageItem<ICustomTag>): void {
        const { custom_tags: customTags } = this.config;

        customTags.removeByMessageItem(customTag);
    }

    /**
     * Return the custom tags count.
     */
    public get customTagsCount(): number {
        const { custom_tags: customTags } = this.config;

        return customTags.count;
    }

    /**
     * Return if network config mode in gcp cloud is 'INBAND_MANAGEMET'.
     */
    public isInbandManagementConfig(): boolean {
        const gcpConfig = this.getCloudConfig() as GCPConfigurationConfigItem;
        const { network_config: networkConfig } = gcpConfig.config;

        return networkConfig.config.config === GCPNetworkConfigMode.INBAND_MANAGEMENT;
    }

    /**
     * Return if network config mode in gcp cloud is 'ONE_ARM_MODE'.
     */
    public isOneArmManagementConfig(): boolean {
        const gcpConfig = this.getCloudConfig() as GCPConfigurationConfigItem;
        const { network_config: networkConfig } = gcpConfig.config;

        return networkConfig.config.config === GCPNetworkConfigMode.ONE_ARM_MODE;
    }

    /**
     * Return if network config mode in gcp cloud is 'TWO_ARM_MODE'.
     */
    public isTwoArmManagementConfig(): boolean {
        const gcpConfig = this.getCloudConfig() as GCPConfigurationConfigItem;
        const { network_config: networkConfig } = gcpConfig.config;

        return networkConfig.config.config === GCPNetworkConfigMode.TWO_ARM_MODE;
    }

    /**
     * Return if network creation is allowed for the selected cloud type.
     */
    public allowNetworkCreate(): boolean {
        const vtype = this.getVtype();

        return cloudsAllowingNetworkCreation.has(vtype as CloudType);
    }

    /**
     * Return list of vCenterServer refs used by NSX cloud.
     */
    public getNsxtVCenterServerRefs(): string[] {
        if (this.getVtype() !== CloudType.CLOUD_NSXT) {
            throw new Error('Not applicable for non-NSX type clouds.');
        }

        const { id } = this;

        const serverCollection = new this.VCenterServerCollection({
            isStatic: true,
            limit: 200, // max allowed by API.
            params: {
                fields: 'name,tenant_ref',
                'cloud_ref.uuid': id,
            },
        });

        return serverCollection
            .load()
            .then(() => serverCollection.itemList.map((server: Item) => server.getRef()))
            .catch(() => [] as string[])
            .finally(() => serverCollection.destroy());
    }

    /**
     * TODO: Remove this code once confirmed it is not used anymore.
     * VCA Cloud Login call.
     */
    public async VCALogin(): Promise<void> {
        const { config } = this;

        this.errors = null;
        this.busy = true;

        const payload = {
            username: config.vca_configuration.config.vca_username,
            password: config.vca_configuration.config.vca_password,
            instance: config.vca_configuration.config.vca_instance,
            vdc: config.vca_configuration.config.vca_vdc,
        };

        try {
            const response = await this.request('POST', VCA_GET_NETWORKS_API, payload);

            if (response.data?.networks?.length) {
                this.vcaNwList = response.data.networks[0].subnets;
            } else {
                this.vcaNwList = [];
            }
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        if (this.getVtype() === CloudType.CLOUD_NSXT && this.isVmcDeployed()) {
            const {
                VmcDeploymentNsxtCloudModalComponent,
            } = await import(
                /* webpackChunkName: "vmc-deployment-nsxt-cloud-modal" */
                // eslint-disable-next-line max-len
                'ng/lazy-loaded-components/modals/vmc-deployment-nsxt-cloud-modal/vmc-deployment-nsxt-cloud-modal.component'
            );

            return VmcDeploymentNsxtCloudModalComponent as Type<Component>;
        }

        const {
            CloudModalComponent,
        } = await import(
            /* webpackChunkName: "cloud-modal" */
            'ng/lazy-loaded-components/modals/cloud-modal/cloud-modal.component'
        );

        return CloudModalComponent as Type<Component>;
    }

    /**
     * Return true if the cloud is VMC deployed. Used to determine if we should render the VMC NSX
     * cloud modal and show the tier1 dropdown in the VsVip modal.
     */
    public isVmcDeployed(): boolean {
        return this.getConfig().vmc_deployment;
    }

    /**
     * Return true if the cloud is type AWS and has route53_integration set to true.
     */
    public hasAwsRoute53Integration(): boolean {
        if (this.isVtype(CloudType.CLOUD_AWS)) {
            const { aws_configuration: awsConfiguration } = this.config;
            const { route53_integration: route53Integration } = awsConfiguration.config;

            return Boolean(route53Integration);
        }

        return false;
    }

    /**
     * Return true if the cloud is type Azure and has use_azure_dns set to true.
     */
    public useAzureDns(): boolean {
        if (this.isVtype(CloudType.CLOUD_AZURE)) {
            const { azure_configuration: azureConfiguration } = this.config;
            const { use_azure_dns: useAzureDns } = azureConfiguration.config;

            return Boolean(useAzureDns);
        }

        return false;
    }

    /**
     * Return if the cloud has configured DNS provider profile.
     */
    public hasDnsProviderProfile(): boolean {
        return Boolean(this.config.dns_provider_ref);
    }

    /**
     * Return if the macrostack api should be used.
     */
    protected useMacroStack(): boolean {
        return macroStackTypes.has(this.getVtype() as CloudType);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.cloudModalBreadcrumbTitle);
    }

    /**
     * Invoke mackrostack factory submit method in case the selected cloud type is VCenter.
     * Invoke item factory's save method for all other cloud types.
     * @override
     */
    protected saveRequest(): IPromise<IHttpResponse<ICloud>> {
        return !this.useMacroStack() ? super.saveRequest() :
            this.macroStackFactory.submit() as unknown as IPromise<IHttpResponse<ICloud>>;
    }

    /**
     * Macrostack api returns data in a different format.
     * Return the first object in case of macrostack as cloud object would be the first
     * object in this case.
     * @override
     */
    protected transformDataAfterSave(rsp: IHttpResponse<ICloud>): ICloud {
        return !this.useMacroStack() ? super.transformDataAfterSave(rsp) : rsp.data[0];
    }

    /**
     * Return custom preview component in case of OpenStack Cloud.
     * @override
     */
    /** eslint-disable-next-line class-methods-use-this */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        const { OpenstackPreviewComponent } = await import(
            /* webpackChunkName: "cloud-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/cloud-modal/openstack-configuration/openstack-preview/openstack-preview.component'
        );

        const previewComponentObject = {
            ...props,
            previewComponent: this.isVtype(CloudType.CLOUD_OPENSTACK) ?
                OpenstackPreviewComponent as Type<Component> : props.previewComponent,
            previewComponentProps: this.isVtype(CloudType.CLOUD_OPENSTACK) ? {
                config: params.editable.getConfig(),
            } : {
                ...props.previewComponentProps,
            },
        };

        return previewComponentObject;
    }

    /**
     * Remove/Update cloud config for consistency of cloud state.
     */
    private removeCloudConfig(): void {
        const { config } = this;

        each(vtypeConfigHash, (vtypeConfig: string) => delete config[vtypeConfig]);
    }

    /**
     * Reset current license props.
     */
    private resetLicenseProps(): void {
        const { config } = this;

        delete config.license_type;
        delete config.license_tier;
    }

    /**
     * Cancel any pending timeouts.
     */
    private cancelTimeouts(): void {
        const $timeout = this.getAjsDependency_('$timeout');

        if (this.vcenterDCTimeout) {
            $timeout.cancel(this.vcenterDCTimeout);
            this.vcenterDCTimeout = null;

            if (this.busy) {
                this.busy = false;
            }
        }

        if (this.vcenterNetworkTimeout) {
            $timeout.cancel(this.vcenterNetworkTimeout);
            this.vcenterNetworkTimeout = null;

            if (this.busy) {
                this.busy = false;
            }
        }
    }

    /**
     * Return config object of IpamDnsProviderProfile protobuf message (if set and loaded).
     */
    private getIpamProviderProfileConfig(): Record<string, any> | null {
        return this.getConfig()[IPAM_PROVIDER_REF_DATA] || null;
    }

    /**
     * Return actual IPAM's profile (nested in IpamDnsProviderProfile) config type.
     */
    private getIpamProfileType(): string {
        return this.getIpamProviderProfileConfig()[TYPE];
    }

    /**
     * Return if cloud instance has an IPAM provider profile with "IPAMDNS_TYPE_AWS" type.
     */
    private hasAwsIpamProfile(): boolean {
        if (this.hasIpamProviderProfile()) {
            return this.getIpamProfileType() === IpamDnsType.IPAMDNS_TYPE_AWS;
        }

        return false;
    }

    /**
     * Remove VCenterServers used by NSX cloud along with the cloud via macrostack service.
     */
    private async dropNsxtCloud(): Promise<void> {
        const cloudType = this.getVtype();

        if (cloudType !== CloudType.CLOUD_NSXT) {
            throw new Error('Applicable for NSX type only.');
        }

        const serverRefs = await this.getNsxtVCenterServerRefs();
        const macroStack = new this.MacroStackFactory();
        const { VCenterServer } = this;

        const servers = serverRefs.map((serverRef: string) => {
            const server = new VCenterServer({ id: this.stringService_.slug(serverRef) });

            macroStack.delete(server);

            return server;
        });

        macroStack.delete(this);

        try {
            return await macroStack.submit();
        } catch (errors) {
            const aviAlertService = this.getAjsDependency_('aviAlertService');

            aviAlertService.throw(errors.data);
        } finally {
            servers.forEach(server => server.destroy());
        }
    }
}
