<ul class="prechecks-list">
    <li
        *ngFor="let check of prechecks; trackBy: trackByIndex"
        class="prechecks-list__item"
    >
        <avi-tooltip-icon-with-content-wrapper
            *ngIf="check.state === upgradeFsmState.SUCCESS"
            status="success"
            shape="check-circle"
            size="24"
        >
            {{ l10nKeys.precheckPassSuccessLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>

        <avi-tooltip-icon-with-content-wrapper
            *ngIf="check.state === upgradeFsmState.ERROR"
            status="danger"
            shape="exclamation-circle"
            size="24"
        >
            {{ l10nKeys.precheckErrorLabel | vtranslate : (check.details ? check.details.join(): '') }}
        </avi-tooltip-icon-with-content-wrapper>

        <avi-tooltip-icon-with-content-wrapper
            *ngIf="check.state === upgradeFsmState.WARNING"
            status="warning"
            shape="exclamation-circle"
            size="24"
        >
            {{ l10nKeys.precheckPassWarningLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>

        <avi-tooltip-icon-with-content-wrapper
            *ngIf="check.state === upgradeFsmState.STARTED"
            status="info"
            shape="circle"
            size="24"
        >
            {{ l10nKeys.precheckPendingLabel | vtranslate }}
        </avi-tooltip-icon-with-content-wrapper>

        <clr-spinner 
            *ngIf="check.state === upgradeFsmState.IN_PROGRESS"
            class="prechecks-list__spinner"
            clrInline
        ></clr-spinner>

        <span class="prechecks-list__label">{{ check.description | trimSingleQuote }}</span>
    </li>
</ul>
