/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { IIpamUsableNetwork } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { StringService } from 'string-service';
import { SubnetListNetworkCollection }
    from 'ajs/modules/network/factories/subnet-list-network.collection.factory';
import * as l10n from './internal-ipam-usable-networks-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSubnetListNetworkCollection = typeof SubnetListNetworkCollection;

/**
 * @description Grid component for internal profile usable networks.
 * *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'internal-ipam-usable-networks-grid',
    templateUrl: './internal-ipam-usable-networks-grid.component.html',
})
export class InternalIpamUsableNetworksGridComponent
implements OnInit, OnChanges, AfterViewInit {
    /**
     * Binding for ipam#internal_profile#usable_networks.
     */
    @Input()
    public usableNetworks: RepeatedMessageItem<MessageItem<IIpamUsableNetwork>>;

    /**
     * Selected cloudRef.
     */
    @Input()
    public cloudRef: string;

    /**
     * Fires on Add usable network.
     */
    @Output()
    public onAddUsableNetwork = new EventEmitter<void>();

    /**
     * Fires on Delete usable network.
     */
    @Output()
    public onDeleteUsableNetwork = new EventEmitter<MessageItem<IIpamUsableNetwork>>();

    /**
     * UsableNetwork collection dropdown ref.
     */
    @ViewChild('usableNetworkTemplateRef')
    public usableNetworkTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Usable network grid config.
     */
    public internalIpamUsableNetworkGridConfig: IAviDataGridConfig;

    /**
     * Prefix for network dropdown id & name.
     */
    public readonly usableNetworkRowPrefix = 'usable_network_';

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SubnetListNetworkCollection instance.
     */
    public readonly subnetListNetworkCollection: SubnetListNetworkCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(SubnetListNetworkCollection)
        SubnetListNetworkCollection: TSubnetListNetworkCollection,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.subnetListNetworkCollection = new SubnetListNetworkCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.onCloudChange();
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { cloudRef } = changes;

        if (cloudRef && !cloudRef.firstChange) {
            this.onCloudChange();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.internalIpamUsableNetworkGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleNetwork),
                id: 'nw_ref',
                templateRef: this.usableNetworkTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (usableNetworks: Array<MessageItem<IIpamUsableNetwork>>) => {
                    usableNetworks.forEach((usableNetwork: MessageItem<IIpamUsableNetwork>) => {
                        this.deleteUsableNetwork(usableNetwork);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (usableNetwork: MessageItem<IIpamUsableNetwork>) => {
                    this.deleteUsableNetwork(usableNetwork);
                },
            }],
        };
    }

    /**
     * Emits add usable network event.
     */
    public addUsableNetwork(): void {
        this.onAddUsableNetwork.emit();
    }

    /**
     * Deletes add usable network event.
     */
    private deleteUsableNetwork(usableNetwork: MessageItem<IIpamUsableNetwork>): void {
        this.onDeleteUsableNetwork.emit(usableNetwork);
    }

    /**
     * Fires on cloudRef change.
     */
    private onCloudChange(): void {
        if (this.cloudRef) {
            this.subnetListNetworkCollection.setParams({
                cloud_uuid: this.stringService.slug(this.cloudRef),
            });
        }
    }
}
