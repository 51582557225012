/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'system-update-about-card';
const prefix = `${moduleName}.${componentName}`;

export const rollbackBtnLabel = `${prefix}.rollbackBtnLabel`;
export const fipsLabel = `${prefix}.fipsLabel`;
export const commonCriteriaLabel = `${prefix}.commonCriteriaLabel`;
export const complianceModeBtnLabel = `${prefix}.complianceModeBtnLabel`;
export const inProgressLabel = `${prefix}.inProgressLabel`;

export const ENGLISH = {
    [rollbackBtnLabel]: 'Rollback',
    [fipsLabel]: 'FIPS',
    [commonCriteriaLabel]: 'Common Criteria',
    [complianceModeBtnLabel]: 'Compliance Mode',
    [inProgressLabel]: 'In Progress',
};
