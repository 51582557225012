/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IBotAllowRule, IMatchTarget } from 'generated-types';
import { BotAllowRule } from 'object-types';
import { BotAllowRuleModalComponent } from
    'ng/modules/bot/components/bot-allow-rule-modal';
import { withFullModalMixin } from 'ajs/utils/mixins';
import {
    MatchTargetConfigItem,
    TMatchField,
} from 'ajs/modules/policies';
import { reduce } from 'underscore';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ip-reputation-type-mapping.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBotAllowRulePartial = Omit<IBotAllowRule, 'condition'>;

interface IBotAllowRuleConfig extends TBotAllowRulePartial {
    condition: MatchTargetConfigItem,
}

/**
 * config-item for BotAllowRule, which has matches, held in 'condition'
 * @author Akul Aggarwal
 */
export class BotAllowRuleConfigItem extends
    withFullModalMixin(MessageItem)<IBotAllowRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
        'schemaService',
    ];

    private readonly l10nService: L10nService;

    private readonly schemaService: SchemaService;

    /**
     * List of all supported matches (conditions) for BotAllowRule.config.condition
     */
    private readonly botAllowListSupportedMatches = [
        'client_ip',
        'method',
        'path',
        'host_hdr',
        'hdrs',
    ];

    constructor(args = {}) {
        super({
            objectType: BotAllowRule,
            windowElement: BotAllowRuleModalComponent,
            ...args,
        });

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IBotAllowRuleConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { condition, ...defaults } = defaultConfig;

        return {
            ...defaults,
            condition: null,
        };
    }

    /**
     * Returns list of types of matches configured.
     */
    private get configuredMatchList(): string[] {
        return this.botAllowListSupportedMatches.filter(this.hasMatchByField);
    }

    /**
     * Returns the number of matches configured.
     * TODO: refactor to use Schema when "match_target_options" is available.
     */
    public get matchCount(): number {
        const { config } = this.config.condition;

        return reduce(config, (acc, match) => {
            const { config: matchConfig } = match as MessageItem<IMatchTarget>;

            return acc + (Array.isArray(matchConfig) ? matchConfig.length : 1);
        }, 0);
    }

    /**
     * Looks up description field for allow rule's selected action.
     */
    public get botActionText(): string {
        const schemaService = this.getAjsDependency_('schemaService');

        return schemaService.getEnumValueDescription('BotAction', this.config.action);
    }

    /**
     * Returns true if the match exists in this.config.match.
     */
    public hasMatchByField = (matchFieldName: TMatchField): boolean => {
        return this.config.condition ?
            this.config.condition.hasMatchByField(matchFieldName) :
            false;
    };

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { condition } = this.config;

        if (condition) {
            const { client_ip: clientIp } = condition.config;

            if (clientIp) {
                clientIp.clearFlatProps();
            }
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.allowRuleTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['condition'];
    }
}
