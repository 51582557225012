/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PersistenceProfileModule
 */
import { Injector } from '@angular/core';
import {
    PersistenceProfileCollection,
} from 'ajs/modules/persistence-profile/factories/persistence-profile.collection.factory';
import {
    PERSISTENCE_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/persistence-profile/persistence-profile.tokens';

const $injector = '$injector';

export const persistenceProfileCollectionProvider = {
    deps: [$injector],
    provide: PersistenceProfileCollection,
    useFactory(injector: Injector): typeof PersistenceProfileCollection {
        return injector.get(PERSISTENCE_PROFILE_COLLECTION_TOKEN);
    },
};
