<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="!form.invalid && (certificateCreateType === certificateCreateTypeEnum.IMPORT ? validated : true)"
    class="ssl-certificate-create-application-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        class="sel-name"
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        [disabled]="editable.isProtected()"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label for="type">
                            {{ globalL10nKeys.typeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-ssl-certificate-type"
                            name="type"
                            [hideSearch]="true"
                            [(ngModel)]="certificateCreateType"
                            [options]="certificateCreateTypeOptions"
                            placeholder="{{ l10nKeys.typeSelectPlaceholder | vtranslate }}"
                            [disabled]="editable.id || validated"
                            (ngModelChange)="onCertCreationTypeChange(certificateCreateType)"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="certificateCreateType === certificateCreateTypeEnum.CA_SIGNED"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="certificate_management_profile_uuid"
                            for="certificate_management_profile_uuid"
                        >
                            {{ l10nKeys.certificateManagementProfileInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="certificateManagementCollection"
                            [(ngModel)]="editable.getConfig().certificate_management_profile_ref"
                            placeholder="{{ l10nKeys.certificateManagementProfileInputPlaceholder | vtranslate }}"
                            name="certificate_management_profile_uuid"
                            (ngModelChange)="editable.loadCertificateManagementProfile()"
                            (onCollectionItemEditSubmit)="editable.loadCertificateManagementProfile()"
                        ></avi-collection-dropdown>
                    </div>
                </div>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="!editable.config.dynamic_params.isEmpty()"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="CustomParams"
                            fieldName="name"
                        >
                            {{ l10nKeys.dynamicParamsLabel | vtranslate }}
                        </label>
                        <dynamic-params-grid
                            [paramsList]="editable.config.dynamic_params"
                        ></dynamic-params-grid>
                    </div>
                </div>
                <div
                    class="ssl-certificate-create-application-modal__spinner-container"
                    *ngIf="editable.busy"
                >
                    <clr-spinner
                        class="ssl-certificate-create-application-modal__spinner"
                        [clrMedium]="true"
                    ></clr-spinner>
                </div>
                <ng-container *ngIf="editable.config.type === SSLCertificateTypeHash.SSL_CERTIFICATE_TYPE_VIRTUALSERVICE">
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="hardwaresecuritymodulegroup_uuid"
                                for="hardwaresecuritymodulegroup_uuid"
                            >
                                {{ l10nKeys.hsmGroupInputLabel | vtranslate }}
                            </label>
                            <avi-collection-dropdown
                                [disableCreate]="true"
                                [disableEdit]="true"
                                [collection]="hsmGroupCollection"
                                [(ngModel)]="editable.config.hardwaresecuritymodulegroup_ref"
                                (ngModelChange)="handleHSMGroupChange($event)"
                                placeholder="{{ l10nKeys.hsmGroupInputPlaceholder | vtranslate }}"
                                name="hardwaresecuritymodulegroup_uuid"
                                [disabled]="!!editable.id"
                            ></avi-collection-dropdown>
                        </div>
                    </div>
                    <div
                        class="full-modal-config__form-control-row"
                        *ngIf="showHSMEncryptedPrivateKey"
                    >
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                objectType="SSLKeyAndCertificateImport"
                                fieldName="enckey_base64"
                            >
                                {{ l10nKeys.encryptedPrivateKeyInputLabel | vtranslate }}
                            </label>
                            <file-upload
                                [(ngModel)]="editable.config.enckey_base64"
                                [(fileName)]="editable.config.enckey_name"
                                [maxFileSize]="10"
                                [base64]="true"
                                name="enckey_base64"
                            ></file-upload>
                            <div *ngIf="editable.config.enckey_base64">
                                <avi-textarea-container>
                                    <textarea
                                        name="enckey_base64"
                                        clrTextarea
                                        id="enckey_base64"
                                        [(ngModel)]="editable.config.enckey_base64"
                                        disabled
                                        rows="5"
                                    ></textarea>
                                </avi-textarea-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <avi-checkbox
                    [disabled]="!!editable.id"
                    objectType="{{ objectType }}"
                    fieldName="is_federated"
                    [(ngModel)]="editable.config.is_federated"
                    name="is-federated"
                >
                    {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                </avi-checkbox>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.certificateInformationHeader | vtranslate }}"
            *ngIf="editable.id && certificateCreateType === certificateCreateTypeEnum.CA_SIGNED"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.certificateInformationHeader | vtranslate }}</h3>
                <ssl-certificate-signing-request
                    [signingRequest]="editable.certificate.config.certificate_signing_request"
                    (onCertificateDataChange)="handleCertificateSigningRequestChange($event)"
                ></ssl-certificate-signing-request>
            </div>
        </full-modal-tab-section>
        <!-- Self Signed and CSR -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.certificateSectionHeader | vtranslate }}"
            *ngIf="certificateCreateType !== certificateCreateTypeEnum.IMPORT"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.certificateSectionHeader | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="SSLCertificateDescription"
                        fieldName="common_name"
                        for="common_name"
                        required
                    >
                        {{ l10nKeys.commonNameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="common_name"
                        name="common_name"
                        [(ngModel)]="editable.subject.config.common_name"
                        clrInput
                        [disabled]="!!editable.id"
                        required
                        placeholder="{{ l10nKeys.commonNameInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="SSLCertificateDescription"
                        fieldName="email_address"
                        for="email_address"
                    >
                        {{ globalL10nKeys.emailLabel | vtranslate }}
                    </label>
                    <input
                        id="email_address"
                        name="email_address"
                        [(ngModel)]="editable.subject.config.email_address"
                        clrInput
                        [disabled]="!!editable.id"
                        regexPattern="email"
                        placeholder="{{ l10nKeys.emailInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="SSLCertificateDescription"
                                fieldName="organization"
                                for="organization"
                            >
                                {{ l10nKeys.organizationInputLabel | vtranslate }}
                            </label>
                            <input
                                id="organization"
                                name="organization"
                                [(ngModel)]="editable.subject.config.organization"
                                clrInput
                                [disabled]="!!editable.id"
                                placeholder="{{ l10nKeys.organizationInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="SSLCertificateDescription"
                                fieldName="organization_unit"
                                for="organization_unit"
                            >
                                {{ l10nKeys.organizationUnitInputLabel | vtranslate }}
                            </label>
                            <input
                                id="organization_unit"
                                name="organization_unit"
                                [(ngModel)]="editable.subject.config.organization_unit"
                                clrInput
                                [disabled]="!!editable.id"
                                placeholder="{{ l10nKeys.organizationUnitInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="SSLCertificateDescription"
                        fieldName="locality"
                        for="locality"
                    >
                        {{ l10nKeys.localityInputLabel | vtranslate }}
                    </label>
                    <input
                        id="locality"
                        name="locality"
                        [(ngModel)]="editable.subject.config.locality"
                        clrInput
                        [disabled]="!!editable.id"
                        placeholder="{{ l10nKeys.localityInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="SSLCertificateDescription"
                                fieldName="state"
                                for="state"
                            >
                                {{ l10nKeys.stateNameInputLabel | vtranslate }}
                            </label>
                            <input
                                id="state"
                                name="state"
                                [(ngModel)]="editable.subject.config.state"
                                clrInput
                                [disabled]="!!editable.id"
                                placeholder="{{ l10nKeys.stateNameInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            noMarginTop
                            helperText="{{ l10nKeys.countryInputHelperText | vtranslate }}"
                        >
                            <label
                                aviLabelWithTooltip
                                objectType="SSLCertificateDescription"
                                fieldName="country"
                                for="country"
                            >
                                {{ l10nKeys.countryInputLabel | vtranslate }}
                            </label>
                            <input
                                id="country"
                                name="country"
                                [(ngModel)]="editable.subject.config.country"
                                clrInput
                                [disabled]="!!editable.id"
                                placeholder="{{ l10nKeys.countryInputPlaceholder | vtranslate }}"
                                regexPattern="twoLetterCountryCode"
                            />
                        </avi-input-container>
                    </div>
                </div>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="editable.certificate?.config?.key_params"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="SSLKeyParams"
                            fieldName="algorithm"
                            for="algorithm"
                        >
                            {{ l10nKeys.algorithmInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-select-algorithm"
                            name="algorithm"
                            [(ngModel)]="editable.certificate.config.key_params.config.algorithm"
                            [options]="sslKeyAlgorithmOptions"
                            placeholder="{{ l10nKeys.algorithmSelectPlaceholder | vtranslate }}"
                            [disabled]="!!editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                    <div
                        class="full-modal-config__form-control"
                        *ngIf="editable.certificate.config.key_params.algorithm === sslKeyAlgorithmTypeHash.SSL_KEY_ALGORITHM_RSA"
                    >
                        <label
                            aviLabelWithTooltip
                            objectType="SSLKeyRSAParams"
                            fieldName="key_size"
                            for="key_size"
                        >
                            {{ l10nKeys.keySizeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-select-keySize"
                            name="key_size"
                            [(ngModel)]="editable.certificate.config.key_params.config.rsa_params.config.key_size"
                            [options]="sslRsaKeySizeOptions"
                            placeholder="{{ l10nKeys.keySizeSelectPlaceholder | vtranslate }}"
                            [disabled]="!!editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                    <div
                        class="full-modal-config__form-control"
                        *ngIf="editable.certificate.config.key_params.algorithm === sslKeyAlgorithmTypeHash.SSL_KEY_ALGORITHM_EC"
                    >
                        <label
                            aviLabelWithTooltip
                            objectType="SSLKeyECParams"
                            fieldName="curve"
                            for="curve"
                        >
                            {{ l10nKeys.keySizeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-select-curve"
                            name="curve"
                            [(ngModel)]="editable.certificate.config.key_params.config.ec_params.config.curve"
                            [options]="sslKeyECCurveOptions"
                            placeholder="{{ l10nKeys.keySizeSelectPlaceholder | vtranslate }}"
                            [disabled]="!!editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <avi-input-container *ngIf="certificateCreateType !== certificateCreateTypeEnum.CA_SIGNED">
                    <label
                        aviLabelWithTooltip
                        objectType="SSLCertificate"
                        fieldName="days_until_expire"
                        for="days_until_expire"
                    >
                        {{ l10nKeys.daysUntilExpirationInputLabel | vtranslate }}
                    </label>
                    <input
                        id="days_until_expire"
                        name="days_until_expire"
                        [(ngModel)]="editable.certificate.config.days_until_expire"
                        type="number"
                        clrInput
                        [disabled]="!!editable.id"
                        placeholder="{{ l10nKeys.daysUntilExpirationInputLabel | vtranslate }}"
                        objectType="SSLCertificate"
                        fieldName="days_until_expire"
                        configFieldInputValidation
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-data-grid_header>
                            <label
                                aviLabelWithTooltip
                                objectType="SSLCertificateRequest"
                                fieldName="subject_alt_names"
                            >
                                <h6>
                                    {{ l10nKeys.subjectAlternateNameInputLabel | vtranslate : editable.subjectAltNamesCount }}
                                </h6>
                            </label>
                        </avi-data-grid_header>
                        <avi-repeated-strings-grid
                            name="subject-alt-names-grid"
                            placeholder="{{ l10nKeys.subjectAltNamesGridPlaceholder | vtranslate }}"
                            columnLabel="{{ globalL10nKeys.nameLabel | vtranslate }}"
                            [(ngModel)]="editable.certificateConfig.subject_alt_names"
                            [allowDuplicates]="true"
                        ></avi-repeated-strings-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <!-- Import -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.certificateSectionHeader | vtranslate }}"
            *ngIf="certificateCreateType === certificateCreateTypeEnum.IMPORT"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.certificateSectionHeader | vtranslate }}</h3>
                <avi-checkbox
                    [disabled]="!!editable.id"
                    objectType="{{ objectType }}"
                    fieldName="import_key_to_hsm"
                    [(ngModel)]="editable.config.import_key_to_hsm"
                    name="import-key-to-hsm"
                >
                    {{ l10nKeys.privateKeyHsmCheckboxLabel | vtranslate }}
                </avi-checkbox>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="certificate"
                            for="certificate"
                            [required]="validated"
                        >
                            {{ l10nKeys.certificateFileInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            [(ngModel)]="editable.certificate.config.certificate"
                            (onUploadTypeChange)="setCertificateBase64($event)"
                            placeholder="{{ l10nKeys.certificateFileInputPlaceholder | vtranslate }}"
                            name="certificate"
                            [base64]="true"
                            [maxFileSize]="10"
                            [required]="validated"
                        ></file-upload-textarea>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="key"
                            for="key"
                            [required]="validated"
                        >
                            {{ l10nKeys.keyPemInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            [(ngModel)]="editable.config.key"
                            (onUploadTypeChange)="setKeyBase64($event)"
                            placeholder="{{ l10nKeys.KeyInputPlaceholder | vtranslate }}"
                            name="key"
                            [base64]="true"
                            [maxFileSize]="10"
                            [required]="validated"
                        ></file-upload-textarea>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="key_passphrase"
                        for="key_passphrase"
                    >
                        {{ l10nKeys.keyPassphraseInputLabel | vtranslate }}
                    </label>
                    <input
                        id="key_passphrase"
                        type="password"
                        name="key_passphrase"
                        [(ngModel)]="editable.config.key_passphrase"
                        clrInput
                        placeholder="{{ l10nKeys.keyPassphraseInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
                <ssl-certificate-imported-information
                    [certificate]="editable.certificate"
                    [objectType]="objectType"
                ></ssl-certificate-imported-information>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleOcspSampling | vtranslate }}"
            *ngIf="certificateCreateType !== certificateCreateTypeEnum.SELF_SIGNED && editable.config.type === SSLCertificateTypeHash.SSL_CERTIFICATE_TYPE_VIRTUALSERVICE"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleOcspSampling | vtranslate }}</h3>
                <ssl-tls-certificate-ocsp [editable]="editable" ></ssl-tls-certificate-ocsp>
            </div>
        </full-modal-tab-section>
    </form>
    <full-modal-config_footer
        class="ssl-certificate-create-application-modal__validate-button"
        [hidden]="editable.isBusy() || certificateCreateType !== certificateCreateTypeEnum.IMPORT"
    >
        <cds-button
            [disabled]="validated || form.invalid || !hasCertOrKey"
            (click)="validateCert()"
            action="flat"
        >
            {{ l10nKeys.validateButtonLabel | vtranslate }}
        </cds-button>
    </full-modal-config_footer>
</full-modal-config>
