/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './license-tier-switch-modal.component.less';

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    Type,
    ViewChild,
} from '@angular/core';

import {
    AviConfirmService,
    DefaultValues,
    SchemaService,
} from 'ajs/modules/core/services';

import {
    ALBServicesRegistrationStatus,
    IALBServicesConfig,
    LicenseTierType,
} from 'generated-types';

import { IPromise } from 'angular';
import { NgForm } from '@angular/forms';
import { ClrFormLayout } from '@clr/angular';
import { StateService } from '@uirouter/core';
import { DialogService } from 'ng/modules/core/services';
import { CentralLicenseAlertsService }
    from 'ng/modules/core/services/central-license-alerts/central-license-alerts.service';
import { AviContinueConfirmationComponent }
    from 'ng/modules/dialog/components/avi-continue-confirmation';
import { SystemConfig } from 'ajs/modules/system';

import {
    CportalService,
    IRegisterControllerApiResponse,
} from 'ajs/modules/cportal';

import { LicensingService } from 'ajs/modules/licensing';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './license-tier-switch-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;
const ObjectType = 'SaasLicensingInfo';

interface ILicenseTierUsageData {
    total: number;
    used: number;
}

interface ILicenseTiersUsageData {
    [LicenseTierType.ENTERPRISE]: ILicenseTierUsageData;
    [LicenseTierType.BASIC]: ILicenseTierUsageData;
    [LicenseTierType.ESSENTIALS]?: ILicenseTierUsageData;
}
enum AlertType {
    NONE = 'none',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'danger',
}

interface IAlertInfo {
    status: AlertType;
    message: string;
}

/**
 * Set of License Tiers for which we need to show dialog for de-registration warning message.
 */
const LICENSE_TIER_SHOWING_CONFIRMATION_DIALOG = new Set([
    LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES,
    LicenseTierType.ENTERPRISE,
]);

/**
 * Id for License Tier change warning dialog.
 */
const LICENSE_TIER_CHANGE_WARNING_DIALOG_ID = 'LICENSE_TIER_CHANGE_WARNING_DIALOG_ID';

/**
 * Cloud services page ui-router state.
 */
const CLOUD_SERVICES_PAGE_STATE = 'authenticated.administration.cloud-services';

/**
 * @description
 *
 *     License tier switch modal.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'license-tier-switch-modal',
    templateUrl: './license-tier-switch-modal.component.html',
})
export class LicenseTierSwitchModalComponent implements OnInit {
    /**
     * SystemConfig editable object to change licensing tier on.
     */
    @Input()
    public editable: SystemConfig;

    /**
     * Usage data of license tiers, containing used and total service cores of each tier type.
     */
    @Input()
    public licenseTiersUsageData: ILicenseTiersUsageData;

    /**
     * Fire when save succeeds.
     */
    @Output()
    public onSaveSuccess = new EventEmitter<void>();

    /**
     * Template ref form element.
     */
    @ViewChild('form')
    public form: NgForm;

    /**
     * Holds the customer portal information.
     */
    public portalInfo: IALBServicesConfig;

    /**
     * Holds the value of ENTERPRISE_WITH_CLOUD_SERVICES license tier.
     */
    public readonly enterpriseWithCloudServicesTier = LicenseTierType
        .ENTERPRISE_WITH_CLOUD_SERVICES;

    /**
     * List of all LicenseTierType enum values.
     */
    public licensetTierTypeList = [
        LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES,
        LicenseTierType.ENTERPRISE,
        LicenseTierType.BASIC,
        LicenseTierType.ESSENTIALS,
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Getter for the selected license tier.
     */
    public get selectedTier(): LicenseTierType {
        return this.editable.defaultLicenseTier;
    }

    /**
     * Object type.
     */
    public objectType: string;

    /**
     * Holds error message if any.
     */
    public error: string;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Hold component busy state.
     */
    private busy = false;

    /**
     * Currently in-use license tier type.
     */
    private tierInUse: LicenseTierType;

    constructor(
        @Inject(AviConfirmService) private aviConfirmService: AviConfirmService,
        @Inject(LicensingService) private licensingService: LicensingService,
        @Inject(SchemaService) private schemaService: SchemaService,
        private l10nService: L10nService,
        private readonly cportalService: CportalService,
        private readonly defaultValues: DefaultValues,
        private readonly dialogService: DialogService,
        private readonly $state: StateService,
        private readonly centralLicenseAlertsService: CentralLicenseAlertsService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = ObjectType;
        this.tierInUse =
            this.editable.getConfig().default_license_tier as LicenseTierType;

        this.busy = true;
        this.cportalService.getPortalInfo().then((portalData: IALBServicesConfig) => {
            this.portalInfo = portalData;
            this.busy = false;

            if (!this.portalInfo.saas_licensing_config) {
                this.portalInfo.saas_licensing_config = this.defaultValues
                    .getDefaultItemConfig('albservicesconfig.saas_licensing_config');
            }
        });
    }

    /**
     * Check busy state, decided by editable busy state and component busy state.
     */
    public isBusy(): boolean {
        return this.editable.isBusy() || this.busy;
    }

    /**
     * Called when the user clicks the "save" button.
     * 1. When clicked if the switch is from ENTERPRISE/ENTERPRISE_WITH_CLOUD_SERVICES to any
     *    other tiers, a confirmation dialog will be brought up before triggering saving procedure.
     * 2. When clicked if the switch is from other tiers
     *    to ENTERPRISE/ENTERPRISE_WITH_CLOUD_SERVICES,
     *    the saving procedure will be automatically triggered without confirmations.
     */
    public async handleSubmit(): Promise<void> {
        const shouldDeregisterController = await this.shouldDeregisterController();

        if (shouldDeregisterController) {
            this.openControllerDeregistrationWarningDialog();
        } else {
            this.saveLicenseTierChange();
        }
    }

    /**
     * Called when the user wants to cancel or to close the modal.
     */
    public dismiss(): void {
        this.editable.closeLicenseTierModal();
        this.busy = false;
    }

    /**
     * trackBy function for tier selection cards.
     */
    public trackByTierType(index: number, tierType: LicenseTierType): LicenseTierType {
        return tierType;
    }

    /**
     * Get usage fraction string by tier type.
     */
    public getTierUsageFraction(tierType: LicenseTierType): string {
        const isInUse = this.isTierInUse(tierType);
        const { used, total } = this.licenseTiersUsageData[tierType];

        if (isInUse) {
            // only show used cores for the Essentials type
            if (tierType === LicenseTierType.ESSENTIALS) {
                return `${used}`;
            }

            // show the usage fraction for an active type
            return `${used}/${total}`;
        }

        // only show the total number of cores for an in-active type
        return `${total}`;
    }

    /**
     * By tier type, get the status value for the selection card cds alert directive.
     */
    public getTierSwitchAlertStatus(tierType: LicenseTierType): AlertType {
        return this.getTierSwitchAlertInfo(tierType).status;
    }

    /**
     * Get the alert message for a tier type select block.
     */
    public getTierSwitchAlertMsg(tierType: LicenseTierType): string {
        return this.getTierSwitchAlertInfo(tierType).message;
    }

    /**
     * Show the alert message only when the alert status is not NONE.
     */
    public showTierSwitchAlert(tierType: LicenseTierType): boolean {
        return this.getTierSwitchAlertStatus(tierType) !== AlertType.NONE;
    }

    /**
     * Decide if switching to a tier is allowed.
     */
    public isTierAvailable(tierType: LicenseTierType): boolean {
        // Switching to ESSENTIALS/ENTERPRISE_WITH_CLOUD_SERVICES is always available
        // because it doesn't consume any service core.
        if (
            tierType === LicenseTierType.ESSENTIALS ||
            tierType === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES
        ) {
            return true;
        }

        // Switching from ENTERPRISE_WITH_CLOUD_SERVICES to ENTERPRISE
        // is always available. Does not require used service core check.
        if (
            this.tierInUse === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES &&
            tierType === LicenseTierType.ENTERPRISE
        ) {
            return true;
        }

        const { used: currentlyUsedCores } = this.licenseTiersUsageData[this.tierInUse];
        const { total: allowedServiceCores } = this.licenseTiersUsageData[tierType];

        // tier won't be available if it doesn't have enough service cores to hold the number of
        // current ones in use
        if (currentlyUsedCores > allowedServiceCores) {
            return false;
        }

        return true;
    }

    /**
     * Set selected tier to the respect value when the selection card is clicked.
     */
    public selectTier(tierType: LicenseTierType): void {
        this.editable.defaultLicenseTier = tierType;
    }

    /**
     * Decide whether a tier is selected.
     */
    public isSelected(tierType: LicenseTierType): boolean {
        return tierType === this.selectedTier;
    }

    /**
     * Used to track if the form is modified.
     * If True, enable Save button.
     */
    public get isFormModified(): boolean {
        return this.tierInUse !== this.selectedTier ||
            this.tierInUse === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES && this.form?.dirty;
    }

    /**
     * 1. Enable/disable the enterprise with cloud services licensing feature opt in.
     * 2. Updates the enterprise with cloud services licensing configuration.
     */
    private async updateEnterpriseWithCloudServicesConfig(): Promise<void> {
        await this.cportalService.updatePortalInfo(this.portalInfo);
    }

    /**
     * Method to open dialog to show controller de-registration warning message.
     */
    private openControllerDeregistrationWarningDialog(): void {
        const { l10nService, l10nKeys } = this;
        const { label } = this.schemaService.getEnumValue('LicenseTierType', this.selectedTier);

        this.dialogService.add({
            id: LICENSE_TIER_CHANGE_WARNING_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                customHeader: l10nService.getMessage(l10nKeys.switchLicenseHeader),
                warning: l10nService
                    .getMessage(l10nKeys.controllerDeregistrationMessage, [label]),
                onConfirm: async() => {
                    this.dialogService.remove(LICENSE_TIER_CHANGE_WARNING_DIALOG_ID);
                    // De-register the controller if controller is registered to Cloud Services.

                    try {
                        await this.deregisterController();
                        await this.saveLicenseTierChange();

                        // If we are switching to ENTERPRISE_WITH_CLOUD_SERVICES tier,
                        // redirect user to Cloud Services page after license tier change is saved.
                        if (this.selectedTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES) {
                            this.redirectToCloudServices();
                        }
                    } catch (error) {
                        this.busy = false;
                        this.error = error;
                    }
                },
                onClose: () => {
                    this.dialogService.remove(LICENSE_TIER_CHANGE_WARNING_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Returns true if controller is registered,
     * currently in-use license tier type is ENTERPRISE_WITH_CLOUD_SERVICES/Enterprise
     * and selected license tier type is not same as in-use license tier type.
     */
    private async shouldDeregisterController(): Promise<boolean> {
        const isControllerRegistered = await this.isControllerRegistered();

        return isControllerRegistered &&
            LICENSE_TIER_SHOWING_CONFIRMATION_DIALOG.has(this.tierInUse) &&
            this.selectedTier !== this.tierInUse;
    }

    /**
     * Returns controller registration status.
     */
    private async getControllerRegistrationStatus(): Promise<ALBServicesRegistrationStatus> {
        await this.cportalService.loadRegistrationStatus();

        const {
            registration_status: registrationStatus,
        } = this.cportalService.getRegistrationStatusDetails();

        return registrationStatus;
    }

    /**
     * Returns Promise of boolean value indicating controller is registered to Pulse or not.
     */
    private async isControllerRegistered(): Promise<boolean> {
        const registrationStatus = await this.getControllerRegistrationStatus();

        return registrationStatus === ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED;
    }

    /**
     * Function to de-register Controller with Portal.
     */
    private deregisterController(): IPromise<IRegisterControllerApiResponse> {
        return this.cportalService.deregisterController();
    }

    /**
     * Function to redirect user to the Cloud services page.
     */
    private redirectToCloudServices = (): void => {
        this.$state.go(CLOUD_SERVICES_PAGE_STATE);
    };

    /**
     * Trigger saving for license tier type.
     */
    private saveLicenseTierChange = async(): Promise<void> => {
        this.busy = true;

        try {
            await this.editable.saveDefaultLicenseTierType();

            if (this.selectedTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES ||
                this.tierInUse === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES) {
                // Update controller registration status in centralLicenseAlertsService
                const registrationStatus = await this.getControllerRegistrationStatus();

                this.centralLicenseAlertsService.controllerRegistrationStatus = registrationStatus;
            }

            // We need to update enterprise with cloud services config data only when
            // we are changing number of reserved/maximum allowed licenses
            // in ENTERPRISE_WITH_CLOUD_SERVICES license tier.
            // We do not need to update enterprise with cloud services config data
            // while changing license tier.
            if (
                this.tierInUse === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES &&
                this.tierInUse === this.selectedTier
            ) {
                await this.updateEnterpriseWithCloudServicesConfig();
            }

            this.onSaveSuccess.emit();

            this.dismiss();
        } catch (error) {
            this.busy = false;
            this.error = error;
        }
    };

    /**
     * Decide if a tier type is the current one in use.
     */
    private isTierInUse(tierType: LicenseTierType): boolean {
        return tierType === this.tierInUse;
    }

    /**
     * Generate a hash containing alert status and alert message for a particular tier type.
     */
    private getTierSwitchAlertInfo(tierType: LicenseTierType): IAlertInfo {
        const { label: tierLabel } = this.schemaService.getEnumValue(
            'LicenseTierType',
            tierType,
        );

        const { l10nService, l10nKeys } = this;

        // alert info for use when no alert should be shown
        const noneStatusAlertInfo = {
            status: AlertType.NONE,
            message: '',
        };

        // no alert for a tier in use
        if (this.isTierInUse(tierType)) {
            return noneStatusAlertInfo;
        }

        // error alert when not available for selection
        if (!this.isTierAvailable(tierType)) {
            return {
                status: AlertType.ERROR,
                message: l10nService.getMessage(l10nKeys.insufficientLicensesMessage, [tierLabel]),
            };
        }

        // show info for ENTERPRISE_WITH_CLOUD_SERVICES if other tier type is in use
        // (switching from any other tier to ENTERPRISE_WITH_CLOUD_SERVICES)
        if (tierType === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES) {
            return {
                status: AlertType.INFO,
                message: l10nService.getMessage(l10nKeys.unlockMoreFeaturesMessage, [tierLabel]),
            };
        }

        // show warning if:
        // 1. Enterprise is in use (switching from Enterprise to other tiers)
        // 2. for Essentials if Basic is in use (switching from Basic to Essentials)
        if (this.isTierInUse(LicenseTierType.ENTERPRISE) ||
            this.isTierInUse(LicenseTierType.BASIC) && tierType === LicenseTierType.ESSENTIALS
        ) {
            const { label: inUseTierLabel } = this.schemaService.getEnumValue(
                'LicenseTierType',
                this.tierInUse,
            );

            return {
                status: AlertType.WARNING,
                message: l10nService.getMessage(l10nKeys.someFeaturesOnlyAvailableMessage,
                    [inUseTierLabel, tierLabel]),
            };
        }

        return noneStatusAlertInfo;
    }
}
