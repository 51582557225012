/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/scatter-plot-and-table.less';

/**
 * @ngdoc directive
 * @name scatterPlotAndTable
 * @restrict A
 * @param {Pool} item
 * @param {PoolServersHsScatterMetric} metrics
 */
angular.module('aviApp').directive('scatterPlotAndTable', ['ServerCollection',
'getGridMetricFieldConfig',
function(ServerCollection, getGridMetricFieldConfig) {
    function link(scope) {
        scope.serverCollection = new ServerCollection({
            poolId: scope.item.id,
            HSScatterPlotMetric: scope.metrics,
        });

        scope.serversGrid = {
            collection: scope.serverCollection,
            fields: [{
                name: 'hostname',
                title: 'Server Name',
                // ng-if or ui-sref gets evaluated before id assignment
                template: '<a ui-sref = "^.server-detail.analytics({serverId :' +
                    ' \'{{row.id}}\'})">{{row.data.config.hostname}}</a>',
            }, {
                name: 'data.config.ip.addr',
                title: 'IP Address',
            }, {
                name: 'port',
                title: 'Port',
                template: '{{row.data.config.port || "Default Port: " +' +
                    'row.data.config.default_server_port}}',
            }, {
                require: 'health, runtime',
                name: 'health',
                title: 'Health',
                template: '<avi-healthscore item = "row"' +
                    'stop-async-on-hide="true"></avi-healthscore>',
            }, {
                name: 'data.config.ratio',
                title: 'Ratio',
            },
                getGridMetricFieldConfig('l4_server.avg_bandwidth'),
            ],
            layout: {
                lengthAsTotal: true,
                hideSearch: true,
            },
        };

        scope.clearScatterPlotBrush = function() {
            scope.$broadcast('clear-scatter-plot-brush');
            scope.serverCollection.setWhiteList();
            scope.serverCollection.reset();
        };

        scope.$on('scatterPlotCurrentlySelected', function(e, currentlySelected) {
            scope.serverCollection.setWhiteList(currentlySelected);

            if (currentlySelected.length) {
                scope.serverCollection.load(['health', 'runtime', 'l4_server.avg_bandwidth'], true);
            } else {
                scope.serverCollection.reset();
            }
        });

        scope.$on('$destroy', function() {
            scope.serverCollection.destroy();
        });
    }

    return {
        scope: {
            metrics: '=',
            item: '=',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/scatter-plot-and-table.html',
        link,
    };
}]);
