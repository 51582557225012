/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoolGroupModule
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { StateService } from '@uirouter/core';
import { StringService } from 'string-service';
import { Pool } from 'items/pool.item.factory';

import {
    PoolCollection,
    TPoolCollection,
} from 'collections/pool.collection.factory';

/**
 * @description Pool health score component.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'pool-health-score',
    templateUrl: './pool-health-score.component.html',
})
export class PoolHealthScoreComponent implements OnInit {
    @Input()
    public poolRef: string;

    public pool: Pool;

    public readonly poolCollection: PoolCollection;

    constructor(
        private readonly $state: StateService,
        private readonly stringService: StringService,
        @Inject(PoolCollection)
        private readonly PoolCollection: TPoolCollection,
    ) {
        this.poolCollection = new this.PoolCollection({ objectName: 'pool-inventory' });
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        const {
            stringService,
            poolCollection,
        } = this;

        const poolId = stringService.slug(this.poolRef);

        poolCollection.setParams({
            'uuid.in': [poolId],
        });

        await poolCollection.load();

        this.pool = poolCollection.getItemById(poolId);
    }

    /**
     * Navigate to the health page for current vs and pool.
     */
    public navigateToHealthPage(): void {
        const {
            $state,
            pool,
        } = this;

        const POOL_DETAIL_HS_INSIGHTS_PATH =
            'authenticated.application.pool-detail.hs-insights.default';

        $state.go(POOL_DETAIL_HS_INSIGHTS_PATH, {
            vsId: pool.getVSId(),
            poolId: pool.id,
        });
    }
}
