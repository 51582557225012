/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-grid-status-column';
const prefix = `${moduleName}.${componentName}`;

export const certificateStatusTooltipLabel = `${prefix}.certificateStatusTooltipLabel`;
export const expiringOnLabel = `${prefix}.expiringOnLabel`;

export const ENGLISH = {
    [certificateStatusTooltipLabel]: 'Status',
    [expiringOnLabel]: 'Expiring on',
};
