/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import './full-modal-config-header.component.less';

/**
 * @description Component to display a header in the config modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-header',
    templateUrl: './full-modal-config-header.component.html',
})
export class FullModalConfigHeaderComponent {
    /**
     * Text to show in the header, ex. the title of the modal.
     */
    @Input() public headerText = '';

    /**
     * Text to show in the sub header, ex. the name of the modal.
     */
    @Input() public subHeaderText = '';

    /**
     * Called when the user clicks the close button.
     */
    @Output() public onClose = new EventEmitter();

    /**
     * Template ref for sub header container.
     */
    @ViewChild('subHeaderContainer')
    public subHeaderContainerRef: ElementRef;

    /**
     * Template ref for sub header element,
     * Content of container.
     */
    @ViewChild('subHeader')
    public subHeaderRef: ElementRef;

    /**
     * Toggle flag to expand and collapse.
     */
    public isExpanded = false;

    /**
     * Handler for clicking the close button.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Toggles isExpanded flag.
     */
    public toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    /**
     * Finds if actual text is larger than the container.
     * 24px is the space for three dots container.
     */
    public get showEllipses(): boolean {
        return this.subHeaderContainerRef?.nativeElement.offsetWidth - 24 <=
            this.subHeaderRef?.nativeElement.offsetWidth;
    }
}
