<div class="full-modal-config-header">
    <div class="full-modal-config-header__main">
        <div class="full-modal-config-header__header-text sel-full-modal-config-header-text">
            {{ headerText }}
        </div>
        <ng-content select="full-modal-config-header_config-viewer"></ng-content>
        <cds-icon
            class="full-modal-config-header__close-button"
            shape="times"
            size="24"
            (click)="handleClose()"
        ></cds-icon>
    </div>
    <h3
        #subHeaderContainer
        class="full-modal-config-header__header-name"
        [class.full-modal-config-header__overflow]="!isExpanded"
        [class.full-modal-config-header__expand]="isExpanded"
    >
        <span
            #subHeader
            class="full-modal-config-header__header-content"
        >
            {{ subHeaderText }}
        </span>
        <a
            *ngIf="(!isExpanded && showEllipses)"
            class="full-modal-config-header__ellipsis"
            (click)="toggleExpand()"
        >
            ...
        </a>
        <a
            *ngIf="isExpanded && showEllipses"
            class="full-modal-config-header__ellipsis"
            (click)="toggleExpand()"
        >
            [...]
        </a>
    </h3>
    
    <ng-content></ng-content>
</div>
