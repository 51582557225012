/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'hsm-group-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const actionBtnOpenWindowForClientCertDownload = `${prefix}.actionBtnOpenWindowForClientCertDownload`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [actionBtnOpenWindowForClientCertDownload]: 'Open window for client certificate download',
    [headerLabel]: 'HSM Groups',
};
