/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { WAF_CRS_ITEM_TOKEN } from './waf-crs.item.factory';

export const WAF_CRS_COLLECTION_TOKEN = 'WafCrsCollection';

/**
 * Type for WafCrsCollection.
 */
export type TWafCrsCollection = typeof WafCrsCollection;

/**
 * @description WafCrs Collection
 * @author alextsg
 */
export class WafCrsCollection extends Collection {
    public static ajsDependencies = [
        WAF_CRS_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            permissionName: AviPermissionResource.PERMISSION_WAFCRS,
            objectName: 'wafcrs',
            sortBy: 'name',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(WAF_CRS_ITEM_TOKEN);
    }

    /**
     * Sends a POST request to upload new CRS versions of WAF CRS rules.
     * @param file - JSON stringified content to upload.
     */
    public uploadWafCrs(file: string): IPromise<IHttpResponse<void>> {
        let data;

        try {
            data = JSON.parse(file);
        } catch (e) {
            return Promise.reject(new Error('Not a valid JSON file.'));
        }

        const api = `/api/${this.objectName_}`;

        return this.request('POST', api, data, null, 'wafCrsUpload')
            .catch(rsp => Promise.reject(rsp.data && rsp.data.error));
    }
}
