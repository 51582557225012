/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import { Component, Input } from '@angular/core';
import './avi-radio-container.component.less';

/**
 * @description Wrapper around the Clarity radio container element.
 * @author alextsg
 */
@Component({
    selector: 'avi-radio-container',
    templateUrl: './avi-radio-container.component.html',
})
export class AviRadioContainerComponent {
    /**
     * By default there is a margin-top on this element, which is consistent with Clarity elements.
     * Set this to true to remove the margin-top.
     */
    @Input()
    public noMarginTop?: boolean;
}
