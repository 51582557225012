/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function roleListDataTransformerFactory(ListDataTransformer, roleService) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransformer
     * @author Ashish Verma
     * @desc ListDataTransformer of {@link module:avi/app.RoleList RoleList}.
     */
    class RoleListDataTransformer extends ListDataTransformer {
        /**
     * Transforms loaded roles data.
     * @override
     */
        processResponse(resp, request) {
            resp = super.processResponse(resp, request);

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results.forEach(function(object) {
                    object.config.transformedPrivileges = roleService.processRoles(
                        angular.copy(roleService.getRoleCategories()),
                        roleService.createPrivilegeHashMap(object.config.privileges),
                    );
                });
            }

            return resp;
        }
    }

    return RoleListDataTransformer;
}

roleListDataTransformerFactory.$inject = [
    'ListDataTransformer',
    'roleService',
];

angular.module('avi/app').factory('RoleListDataTransformer', roleListDataTransformerFactory);
