/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import * as globalL10n from 'global-l10n';
import { AviDatePipe } from 'ng/modules/core/pipes';
import { UpgradePrechecksErrorStatusTypeHash } from '../../../update.types';
import './seg-update-grid-expander.less';
import * as l10n from './seg-update-grid-expander.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const preCheckStateSet = new Set(
    Object.values(UpgradePrechecksErrorStatusTypeHash),
);

/**
 * @description Component for SEG Update page Grid Expander.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'seg-update-grid-expander',
    templateUrl: './seg-update-grid-expander.component.html',
})
export class SegUpdateGridExpanderComponent implements OnInit {
    @Input()
    public seGroup: ITSEGroup;

    public haModeLabel: string;

    public segVersion: string;

    public segPatchVersion: string;

    /**
     * Timestamp when the latest operatin on node ended.
     */
    public updatedOn: string;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
        private readonly aviDatePipe: AviDatePipe,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setHaModeLabel();
        this.setSegVersion();
        this.setSegPatchVersion();
        this.setUpdatedOn();
    }

    private setHaModeLabel(): void {
        const { haMode } = this.seGroup;

        this.haModeLabel = haMode ? this.schemaService.getEnumValue('HaMode', haMode).label : '-';
    }

    private setSegVersion(): void {
        this.segVersion = this.seGroup.getVersion();
    }

    private setSegPatchVersion(): void {
        this.segPatchVersion = this.seGroup.patchVersion || '-';
    }

    private setUpdatedOn(): void {
        const { upgradeReadiness = {} } = this.seGroup;
        const { state = {} } = upgradeReadiness;

        this.updatedOn = preCheckStateSet.has(state.state) ?
            this.aviDatePipe.transform(`${upgradeReadiness.end_time}+00:00`) :
            this.aviDatePipe.transform(this.seGroup.upgradeEndTime) || '-';
    }
}
