/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'cloud-vrf-context';
const prefix = `${moduleName}.${componentName}`;

export const setCloudVrfContextHeader = `${prefix}.setCloudVrfContextHeader`;
export const setCloudHeader = `${prefix}.setCloudHeader`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const vrfContextLabel = `${prefix}.vrfContextLabel`;
export const notSetLabel = `${prefix}.notSetLabel`;
export const credentialsButtonForCloudVrfContextLabel = `${prefix}.credentialsButtonForCloudVrfContextLabel`;
export const credentialsButtonForCloudLabel = `${prefix}.credentialsButtonForCloudLabel`;

export const ENGLISH = {
    [setCloudVrfContextHeader]: 'Set Cloud & VRF',
    [setCloudHeader]: 'Set Cloud',
    [cloudLabel]: 'Cloud',
    [vrfContextLabel]: 'VRF Context',
    [notSetLabel]: 'Not Set',
    [credentialsButtonForCloudVrfContextLabel]: 'Set Cloud & VRF',
    [credentialsButtonForCloudLabel]: 'Set Cloud',
};
