/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vrf-context';
const componentName = 'vrf-context-page';
const prefix = `${moduleName}.${componentName}`;

export const vrfContextHeader = `${prefix}.vrfContextHeader`;
export const bgpPeeringColumnLabel = `${prefix}.bgpPeeringColumnLabel`;
export const staticRouteColumnLabel = `${prefix}.staticRouteColumnLabel`;
export const gatewayMonitorColumnLabel = `${prefix}.gatewayMonitorColumnLabel`;

export const ENGLISH = {
    [vrfContextHeader]: 'Virtual Routing & Forwarding (VRF) Context',
    [bgpPeeringColumnLabel]: 'BGP Peering',
    [staticRouteColumnLabel]: 'Static Route',
    [gatewayMonitorColumnLabel]: 'Gateway Monitor',
};
