/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    MessageItem,
} from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { VipAutoscaleConfiguration } from 'object-types';
import { SubnetListNetwork } from 'ajs/modules/network/factories/subnet-list-network.item.factory';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

import {
    IVipAutoscaleConfiguration,
    IVipAutoscaleZones,
} from 'generated-types';

type TVipAutoscaleConfigurationPartial = Omit<IVipAutoscaleConfiguration, 'zones'>;

interface IVipAutoscaleConfigurationConfig extends TVipAutoscaleConfigurationPartial {
    zones?: RepeatedMessageItem<MessageItem<IVipAutoscaleZones>>;
}

/**
 * @description VIP AutoScale Configuration Message Item.
 * @author vgohil
 */
export class VipAutoscaleConfigurationConfigItem extends
    MessageItem<IVipAutoscaleConfigurationConfig> {
    public static ajsDependencies = [
        'SubnetListNetwork',
        'devLoggerService',
    ];

    /**
     * Ref. to SubnetListNetwork item class.
     */
    private readonly SubnetListNetwork: typeof SubnetListNetwork;

    /**
     * Ref. to DevLoggerService instance.
     */
    private readonly devLoggerService: DevLoggerService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: VipAutoscaleConfiguration,
            ...args,
        };

        super(extendedArgs);

        this.SubnetListNetwork = this.getAjsDependency_('SubnetListNetwork');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');
    }

    /**
     * Returns the list of zones (VipAutoscaleZones) available under Vip Autoscale configuration.
     */
    public get zones(): RepeatedMessageItem<MessageItem<IVipAutoscaleZones>> {
        return this.config.zones;
    }

    /**
     * Method to add new message item to list of zones under Vip Autoscale Configuration.
     */
    public addZone(): void {
        this.zones.add();
    }

    /**
     * Method to remove message item from list of zones under Vip Autoscale Configuration.
     */
    public removeZone(zone: MessageItem<IVipAutoscaleZones>): void {
        this.zones.removeByMessageItem(zone);
    }

    /**
     * Method to reset the zones when Auto Rebalance is deselected.
     */
    public resetZones(): void {
        this.zones.updateConfig([]);
    }

    /**
     * Set the subnet ref using configured subnet uuid and cloud uuid.
     * To display properly in SubnetListNetwork collection dropdown.
     */
    public async setSubnetRef(
        subnet: MessageItem<IVipAutoscaleZones>,
        cloudId: string,
    ): Promise<void> {
        const { subnet_uuid: subnetUuid } = subnet.config;

        const network = new this.SubnetListNetwork({
            id: subnetUuid,
            cloudId,
        });

        try {
            await network.load();
            subnet.config.subnet_uuid = network.getRef();
        } catch (err) {
            this.devLoggerService.warn(`Could not load network with id "${subnetUuid}"`);
        } finally {
            network.destroy();
        }
    }

    /** @override */
    // eslint-disable-next-line class-methods-use-this
    protected requiredFields(): string[] {
        return [
            'zones',
        ];
    }
}
