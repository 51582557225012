<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="sso-policy-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectTypes.SSOPolicy }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.SSOPolicy }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="ssoPolicyTypeOptions"
                            [(ngModel)]="editable.config.type"
                            (ngModelChange)="onTypeChange()"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.typeSelectPlaceholder | vtranslate }}"
                            [disabled]="editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleAuthentication | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleAuthentication | vtranslate }}</h3>
                <div
                    *ngIf="!(editable.config.type === ssoPolicyTypes.OAUTH)"
                    class="full-modal-config__form-control-row"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.AuthenticationPolicy }}"
                            fieldName="default_auth_profile_uuid"
                            for="default-auth-profile-uuid"
                            required
                        >
                            {{ l10nKeys.defaultAuthProfileDropdownLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [(ngModel)]="editable.authenticationPolicy.config.default_auth_profile_ref"
                            [collection]="authProfileCollection"
                            placeholder="{{ l10nKeys.defaultAuthProfileDropdownPlaceholder | vtranslate }}"
                            name="default-auth-profile-uuid"
                            required
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <authentication-rule-grid
                            [authRules]="editable.authenticationPolicy.authRules"
                            (onAddAuthRule)="addAuthenticationRule()"
                            (onEditAuthRule)="editAuthenticationRule($event)"
                            (onDeleteAuthRule)="deleteAuthenticationRule($event)"
                            (onRowOrderChange)="handleAuthnRuleGridRowReorder($event)"
                        ></authentication-rule-grid>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <authorization-rule-grid
                            [authRules]="editable.authorizationPolicy.authRules"
                            (onAddAuthRule)="addAuthorizationRule()"
                            (onEditAuthRule)="editAuthorizationRule($event)"
                            (onDeleteAuthRule)="deleteAuthorizationRule($event)"
                            (onRowOrderChange)="handleAuthzRuleGridRowReorder($event)"
                        ></authorization-rule-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
