/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from 'ng/shared/components/avi-dropdown-button';

import {
    AuthMappingRuleConfigItem,
    AuthMappingRuleCustomMappingFields,
} from 'ajs/modules/auth-settings/factories/auth-mapping-rule.config-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import { attachComponentBindings } from 'ng/shared/utils/component-rendering.utils';

import {
    UserAccountProfileMappingComponent,
    UserRoleMappingComponent,
    UserTenantMappingComponent,
} from '..';

import * as l10n from './auth-rule-custom-mapping.l10n';
import './auth-rule-custom-mapping.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface IMappingOption {
    label: string;
    fieldName: string;
    component: Type<Component>;
}

/**
 * @description Component to configure AuthMappingRule CustomMapping.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-rule-custom-mapping',
    templateUrl: './auth-rule-custom-mapping.component.html',
})
export class AuthRuleCustomMappingComponent implements OnInit, OnDestroy {
    /**
     * AuthMappingRule ConfigItem instance.
     */
    @Input()
    public editable: AuthMappingRuleConfigItem;

    /**
     * ViewContainerRef for rendering mapping components.
     */
    @ViewChild('mappingListContainerRef', {
        read: ViewContainerRef,
        static: true,
    })
    public readonly mappingListContainerRef: ViewContainerRef;

    /**
     * List of options and components to be rendered when selecting each option.
     */
    public readonly mappingOptions: IMappingOption[] = [];

    /**
     * For templates.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * AviDropdownButton options for selecting a mapping to add.
     */
    public addDropdownActions: IAviDropdownButtonAction[] = [];

    /**
     * Position of the add attachments actions menu tooltip.
     */
    public readonly actionPosition = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * Set of selected mapping options.
     */
    public readonly selectedMappingOptions = new Set<IMappingOption>();

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.mappingOptions = [
            {
                label: this.l10nService.getMessage(l10nKeys.userTenantLabel),
                fieldName: AuthMappingRuleCustomMappingFields.ASSIGN_TENANT,
                component: UserTenantMappingComponent as Type<Component>,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.userRoleLabel),
                fieldName: AuthMappingRuleCustomMappingFields.ASSIGN_ROLE,
                component: UserRoleMappingComponent as Type<Component>,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.userAccountProfileLabel),
                fieldName: AuthMappingRuleCustomMappingFields.ASSIGN_USERPROFILE,
                component: UserAccountProfileMappingComponent as Type<Component>,
            },
        ];
    }

    /** @override */
    public ngOnInit(): void {
        this.updateSelectedMappingsAndOptions();

        this.selectedMappingOptions.forEach((mappingOption: IMappingOption): void => {
            this.renderMapping(mappingOption);
        });
    }

    /**
     * Removes the rendered component and clears config from editable.
     */
    public removeMapping(
        componentRef: ComponentRef<Component>,
        mappingOption: IMappingOption,
    ): void {
        const index = this.mappingListContainerRef.indexOf(componentRef.hostView);
        const { fieldName } = mappingOption;

        this.mappingListContainerRef.remove(index);
        componentRef.destroy();
        this.editable.clearMappingByFieldName(fieldName);
        this.selectedMappingOptions.delete(mappingOption);
        this.setAddDropdownActions();
    }

    /**
     * @override
     * Destroys all rendered components.
     */
    public ngOnDestroy(): void {
        this.mappingListContainerRef.clear();
    }

    /**
     * Sets the AviDropdownButton options.
     * If no options are available to select, shows a disabled
     * "No available mapping to add" option.
     */
    private setAddDropdownActions(): void {
        this.addDropdownActions = this.mappingOptions
            .filter(option => this.showDropdownAction(option))
            .map(option => ({
                label: option.label,
                onClick: () => this.addMapping(option),
            }));

        if (!this.addDropdownActions.length) {
            this.addDropdownActions = [{
                label: this.l10nService.getMessage(l10nKeys.noAvailableMappingLabel),
                onClick: () => {},
                disabled: () => true,
            }];
        }
    }

    /**
     * Returns true if the dropdown option should be selectable.
     */
    private showDropdownAction(mappingOption: IMappingOption): boolean {
        return !this.selectedMappingOptions.has(mappingOption);
    }

    /**
     * Renders a mapping component.
     */
    private renderMapping(mappingOption: IMappingOption): void {
        const {
            component,
            fieldName,
        } = mappingOption;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            component as Type<Component>,
        );

        const componentRef = componentFactory.create(this.mappingListContainerRef.injector);

        attachComponentBindings(componentRef, {
            editable: this.editable,
            objectName: this.editable.messageType,
            fieldName,
            onRemoveMapping: () => this.removeMapping(componentRef, mappingOption),
        });

        this.mappingListContainerRef.insert(componentRef.hostView);
    }

    /**
     * Adds a mapping config and renders its associated component.
     */
    private addMapping(mappingOption: IMappingOption): void {
        this.selectedMappingOptions.add(mappingOption);
        this.editable.setMappingByFieldName(mappingOption.fieldName);
        this.renderMapping(mappingOption);
        this.setAddDropdownActions();
    }

    /**
     * Updates selectedMappingOptions and dropdown.
     */
    private updateSelectedMappingsAndOptions(): void {
        this.selectedMappingOptions.clear();

        this.mappingOptions.forEach((mappingOption: IMappingOption): void => {
            if (this.editable.config[mappingOption.fieldName]) {
                this.selectedMappingOptions.add(mappingOption);
            }
        });

        this.setAddDropdownActions();
    }
}
