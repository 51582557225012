<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="hdr-match"
        provideParentForm
    >
        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                objectType="HdrMatch"
                fieldName="hdr"
                for="hdr"
                required
            >
                {{ l10nKeys.nameInputLabel | vtranslate }}
            </label>
            <input
                name="{{ 'hdr_' + matchIndex }}"
                [(ngModel)]="editable.config.hdr"
                clrInput
                placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                regexPattern="objName"
                required
            />
        </avi-input-container>
        <div class="hdr-match__input-container">
            <label
                aviLabelWithTooltip
                objectType="HdrMatch"
                fieldName="match_criteria"
                for="match_criteria"
                required
            >
                {{ l10nKeys.criteriaLabel | vtranslate }}
            </label>
            <avi-dropdown
                id="match_criteria"
                name="{{ 'match_criteria_' + matchIndex }}"
                [options]="matchOperationOptions"
                [(ngModel)]="editable.config.match_criteria"
                (ngModelChange)="handleCriteriaChange()"
                placeholder="{{ l10nKeys.criteriaPlaceholder | vtranslate }}"
                required
            ></avi-dropdown>
        </div>
        <div
            *ngIf="showValueField()"
            class="hdr-match__input-container hdr-match__input-container--value"
        >
            <label
                aviLabelWithTooltip
                objectType="HdrMatch"
                fieldName="value"
                for="value"
                required
            >
                {{ l10nKeys.valueInputLabel | vtranslate }}
            </label>
            <avi-repeated-strings
                name="{{ 'value_' + matchIndex }}"
                placeholder="{{ l10nKeys.valueInputPlaceholder | vtranslate }}"
                objectType="HdrMatch"
                fieldName="value"
                [(ngModel)]="editable.config.value"
                required
            ></avi-repeated-strings>
        </div>
    </div>
</match-wrapper>
