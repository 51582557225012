/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../waf-log-entry.less';
import '../waf-logs-list.less';
import { WafLogsService } from '../waf-logs.service';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.nsmLogsListBindings
 * @see {@link  module:avi/logs.nsmLogsListComponent}
 */
class NsmLogsListController {
    constructor() {
        /**
         * Expanded state. Used for expanding/collapsing all.
         */
        this.expanded = false;
    }

    $onInit() {
        this.ruleGroupsHash = this.getRuleGroupsHash_();
    }

    /**
     * Click handler for expanding/collapsing all.
     */
    toggleExpand() {
        this.expanded = !this.expanded;
    }

    /**
     * Creates a hash of rule group names to the rule group's rules.
     * @protected
     * @returns {Object}
     */
    getRuleGroupsHash_() {
        const { logs = [] } = this;

        return logs.reduce(WafLogsService.ruleGroupReducer_, {});
    }
}

/**
 * @name nsmLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.NsmLogsListController} controller
 * @property {module:avi/logs.nsmLogsListBindings} bindings
 * @description Component for a list of NSM Logs.
 * @author alextsg
 */
angular.module('avi/logs').component('nsmLogsList', {
    /**
     * @mixin nsmLogsListBindings
     * @memberOf module:avi/logs
     * @property {Object[]} logs - List of NSM Logs.
     * @property {WafPolicy} [wafPolicy=] - Optional because Virtual Service may no longer have the
     *      WafPolicy.
     * @property {string} clientIp - Client IP of the log.
     * @property {string} uriPath - URI Path of the log.
     */
    bindings: {
        logs: '<',
        wafPolicyRef: '@',
        uriPath: '@',
    },
    controller: NsmLogsListController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'nsm-logs-list/nsm-logs-list.component.html',
});
