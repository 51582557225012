/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { copy } from 'angular';
import { pluck } from 'underscore';
import * as l10n from './ModalIpAddrListEdit.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Local subnetwork type. Placeholder for now, might be generic as well.
 * @typedef {Object} module:avi/network.modalIpAddrListEditDirectiveSubnet
 * @property {string} subnet - String representation, i.e. 192.168.0.1/24
 * @property {Object[]} static_ipaddr_tmp - Optional list of static ip addr pools
 * @property {boolean} [isDiscovered] - Set to true for "discovered" subnets. Not defined otherwise.
 */

/**
 * @name modalIpAddrListEditDirective
 * @memberOf module:avi/network
 * @mixes module:avi/network.modalIpAddrListEditDirectiveBindings
 * @desc
 *
 *     Allows to edit network subnets, add and remove them from the list.
 *     Uses regular grid inside.
 *     Very old and poorly written.
 *
 * @author Alex Malitsky
 */
function modalIpAddrListEditFactory(
    Regex,
    l10nService,
) {
    /**
     * Removes empty values and duplicates. Used for static ip address pools of subnet.
     * @param {string[]} [list]
     * @return {string[]}
     * @inner
     */
    function ipAddPoolFilter(list = []) {
        return _.chain(list)
            .compact()
            .uniq('range')
            .value();
    }

    function modalIpAddrListEditLink(scope, elem) {
        scope.Regex = Regex;
        scope.ui = {};
        /**
        * Get keys from source bundles for template usage.
        */
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Currently editable or new object. False when not applicable.
         * @type {null|module:avi/network.modalIpAddrListEditDirectiveSubnet}
         * @inner
         */
        scope.current = null;

        /**
         * Index of the configured subnet which is currently being edited.
         * `-1` when creating new subnet or creating configured from the discovered.
         * @type {number}
         * @inner
         */
        scope.editIndex = -1;

        /**
         * List of unique configured subnets (in string form) used for validation on
         * addition or editing.
         * @type {module:avi/network.modalIpAddrListEditDirectiveSubnet.subnet[]}
         * @inner
         */
        scope.uniqueSubnetValues = [];

        /**
         * Resulting list has to show or hide discovered subnets based on excludeDiscovered
         * flag value. Also if we have discovered and configured we will render configured only.
         * @inner
         */
        function filterData() {
            const {
                excludeDiscovered,
                config: configuredSubnets = [],
            } = scope;

            const list = configuredSubnets.concat();

            if (!excludeDiscovered) {
                const {
                    discovery: discoveredSubnets = [],
                } = scope;

                /**
                 * @type {{string: boolean}}
                 * @inner
                 */
                const configuredSubnetDict = {};

                configuredSubnets.forEach(({ subnet }) => configuredSubnetDict[subnet] = true);

                // don't show discovered subnet if we have same subnet as configured
                const discoveredSubnetsWoConfigured = discoveredSubnets
                    .filter(({ subnet }) => !(subnet in configuredSubnetDict));

                discoveredSubnetsWoConfigured.forEach(subnet => subnet.isDiscovered = true);

                list.push(
                    ...discoveredSubnetsWoConfigured,
                );
            }

            scope.filteredData = list;

            updateUniqueSubnetList();
        }

        /**
         * Maintains the list of unique configured subnetworks for the purpose of validation.
         * In active editing mode, filters the value being edited from the list.
         * @inner
         */
        function updateUniqueSubnetList() {
            const {
                current,
                uniqueSubnetValues,
                filteredData,
            } = scope;

            uniqueSubnetValues.length = 0;

            // we don't care about discovered networks here
            let configuredSubnets = filteredData.filter(subnet => !subnet.isDiscovered);

            configuredSubnets = _.pluck(configuredSubnets, 'subnet');

            if (current) {
                const { subnet: currentSubnetValue } = current;

                configuredSubnets =
                    configuredSubnets.filter(subnet => subnet !== currentSubnetValue);
            }

            uniqueSubnetValues.push(
                ...configuredSubnets,
            );
        }

        /**
         * Focus on subnet field when "edit" or "add" is clicked.
         */
        function focusInput() {
            elem.find('input[ng-model="current.subnet"]').trigger('focus');
        }

        scope.gridConfig = {
            id: 'network-modal-subnet-list',
            fields: [{
                name: 'ip_subnet',
                title: l10nService.getMessage(l10nKeys.columnTitleIpSubnet),
                transform(row) {
                    return row.subnet;
                },
            }, {
                name: 'type',
                title: l10nService.getMessage(l10nKeys.columnTitleType),
                transform(row) {
                    return row.isDiscovered ? l10nService.getMessage(l10nKeys.discoveredLabel) :
                        l10nService.getMessage(l10nKeys.configuredLabel);
                },
            }, {
                name: 'ip_addr_pool',
                title: l10nService.getMessage(l10nKeys.columnTitleIpAddressPool),
                transform({ static_ipaddr_tmp: staticIpAddrTmp }) {
                    return staticIpAddrTmp ? pluck(staticIpAddrTmp, 'range').join(', ') : '';
                },
            }],
            rowId: 'subnet',
            searchFields: [
                'subnet',
                'uiType',
                'prefix.ip_addr.addr',
                'prefix.mask',
            ],
            multipleactions: [{
                title: l10nService.getMessage(l10nKeys.actionBtnDelete),
                disabled(rows) {
                    return Boolean(scope.current) || _.every(rows, row => row.isDiscovered);
                },
                do(rows) {
                    const { config: configuredSubnets } = scope;

                    rows.forEach(row => {
                        if (row.isDiscovered) {
                            return;
                        }

                        const index = configuredSubnets.indexOf(row);

                        configuredSubnets.splice(index, 1);
                    });

                    return true;
                },
            }],
            singleactions: [{
                title: l10nService.getMessage(l10nKeys.actionBtnEdit),
                class: 'sel-edit-btn icon-pencil-4',
                do: row => {
                    scope.edit(row);
                },
            }],
            checkboxDisable(row) {
                return Boolean(scope.current || row.isDiscovered);
            },
            expandedContainerTemplate:
               `
                   <configured-network-subnet-expander
                       [subnet]="row"
                       [from-network-edit]="true"
                    ></configured-network-subnet-expander>
                `,
            expanderDisabled: row => {
                const { static_ip_ranges: staticIpRanges = [] } = row;

                return !staticIpRanges.length;
            },
        };

        /**
         * Opens up edit form to add new subnet to the list.
         */
        scope.add = function() {
            scope.current = {
                subnet: '',
                static_ipaddr_tmp: [],
                useStaticIpForSeAndVip: true,
            };

            scope.editIndex = -1;
            setTimeout(focusInput);
        };

        /**
         * Opens up edit form based on the existent subnet. Supports discovered to configured
         * conversion.
         * @param {module:avi/network.modalIpAddrListEditDirectiveSubnet} subnet
         * @inner
         */
        scope.edit = function(subnet) {
            const { isDiscovered: editingDiscovered } = subnet;

            // Clone subnet before pass it to edit
            // So on cancel, original object will not be corrupted.
            if (editingDiscovered) {
                scope.current = {
                    subnet: subnet.subnet,
                };
            } else {
                scope.current = copy(subnet);

                scope.editIndex = scope.config.indexOf(subnet);
            }

            const { current } = scope;
            const { static_ipaddr_tmp: staticIpAddrTmp } = current;

            // need array to show input for IP range inside ng-repeat
            if (!staticIpAddrTmp) {
                current.static_ipaddr_tmp = [];
            }

            updateUniqueSubnetList();

            setTimeout(focusInput);
        };

        // save and cancel methods are passed to outer controller to enable cancel,
        // save and close(x) buttons
        /**
         * Turns off edit mode. Scenarios:
         *  - upon creation of the new subnet: adds new subnet to the list of configured
         *  - upon edit: either updates in-place or adds to the list of configured in case of
         *    discovered to configured conversion.
         * @inner
         */
        scope.save = function() {
            const { current, editIndex } = scope;
            const { static_ipaddr_tmp: staticIpAddrTmp } = current;

            current.static_ipaddr_tmp = ipAddPoolFilter(staticIpAddrTmp);

            if (scope.editIndex === -1) {
                scope.config.push(current);
            } else {
                scope.config.splice(editIndex, 1, current);
            }

            scope.current = null;
            scope.editIndex = -1;
        };

        /**
         * Turns off edit mode wo any changes to the list of configured subnets.
         * @inner
         */
        scope.cancel = function() {
            scope.current = null;
            scope.editIndex = -1;
        };

        /* need to use this as we can modify list in multiple ways and different scopes */

        // after adding the new subnet in the list
        scope.$watchCollection('config', filterData);

        scope.$watch('discovery', filterData);

        // checkbox 'exclude discovered'
        scope.$watch('excludeDiscovered', filterData);
    }

    /**
     * Really bad patterns here, hello from 2014!
     * @mixin modalIpAddrListEditDirectiveBindings
     * @memberOf module:avi/network
     * @property {module:avi/network.modalIpAddrListEditDirectiveSubnet[]} config -
     *     List of "configured" networks, gets modified here.
     * @property {module:avi/network.modalIpAddrListEditDirectiveSubnet[]} discovery -
     *     List of "discovered" networks. Read only.
     * @property {boolean} excludeDiscovered - Boolean flag to filter out discovered networks
     *     from the list. Read only.
     * @property {module:avi/network.modalIpAddrListEditDirectiveSubnet[]|null|undefined}
     *     current - Passing subnetwork to the parent controller.
     * @property {Function} cancel - Used to pass onCancel function to parent controller.
     * @property {Function} save - Used to pass onCancel function to parent controller.
     * @see module:avi/network.modalIpAddrListEditDirective
     */
    const bindings = {
        config: '=',
        discovery: '<',
        excludeDiscovered: '<',
        current: '=',
        cancel: '=onCancel',
        save: '=onSave',
    };

    return {
        scope: bindings,
        link: modalIpAddrListEditLink,
        restrict: 'E',
        templateUrl: 'src/views/components/modal-ip-addr-list-edit.html',
    };
}

modalIpAddrListEditFactory.$inject = [
    'Regex',
    'l10nService',
];

angular.module('avi/network')
    .directive('modalIpAddrListEdit', modalIpAddrListEditFactory);
