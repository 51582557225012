/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { UpgradeOperation } from 'generated-types';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';

import {
    imageTypeHash,
    UpgradeType,
} from 'ng/modules/update/update.types';

import { UpdateService } from 'ng/modules/update/services/update.service';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    ImageCollection,
    TImageCollection,
} from 'ajs/modules/upgrade/factories/image-collection.factory';

import './seg-image-selection-modal.component.less';
import * as l10n from './seg-image-selection-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for image selection modal of SEG update page.
 * @author Nisar Nadaf
 */
@Component({
    selector: 'seg-image-selection-modal',
    templateUrl: './seg-image-selection-modal.component.html',
})
export class SegImageSelectionModalComponent implements OnInit, AfterViewInit {
    @Input()
    public segUpgradeNodeList: ITSEGroup[];

    /**
     * Fire on click of Continue button on the dialog.
     */
    @Output()
    public onConfirm = new EventEmitter<Image[]>();

    /**
     * Fire on click of cancel button on the dialog.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * TemplateRef for Image#type column.
     */
    @ViewChild('imageTypeTemplateRef')
    public readonly imageTypeTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Available updates grid config.
     */
    public availableUpdatesGridConfig: IAviCollectionDataGridConfig;

    /**
     * List of images selected for upgrade operation.
     */
    public selectedImagesToUpgrade: Image[] = [];

    public imageCollection: ImageCollection;

    /**
     * Enable continue button for valid images selection.
     */
    public isSelectedImagesValidForUpgrade = false;

    /**
     * Store selected images.
     * At most one system image and one patch image can be stored at the same time.
     */
    private selectedImageHash: Record<string, Image | null> = {
        systemImage: null,
        patchImage: null,
    };

    constructor(
        private readonly l10nService: L10nService,
        @Inject(ImageCollection)
        private readonly ImageCollectionFactory: TImageCollection,
        private readonly updateService: UpdateService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.imageCollection = new this.ImageCollectionFactory({
            objectName: 'image-raw-inventory',
            params: {
                base_image_version: this.getRequestBaseImageVersion(),
                upgrade_operation: UpgradeOperation.SE_UPGRADE_OP,
            },
        });

        this.initImageCollectionGridConfig();
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.initImageCollectionGridConfig();
    }

    public initImageCollectionGridConfig(): void {
        const { l10nService } = this;

        this.availableUpdatesGridConfig = {
            layout: {
                hideCreate: true,
                hideDelete: true,
                hideEdit: true,
                hideSearch: true,
            },
            id: `${this.imageCollection?.objectName}-seg-list`,
            collection: this.imageCollection,
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleType),
                    id: 'type',
                    templateRef: this.imageTypeTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleVersion),
                    id: 'version',
                    transform: (row: Image): string => {
                        return row.isUsedByController() ?
                            this.l10nService.getMessage(
                                l10nKeys.currentVersionLabel,
                                [row.version],
                            ) : row.version;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleAttributes),
                    id: 'attributes',
                    transform: (row: Image) => row.category,
                },
            ],
            rowSelectionDisabled: (row: Image): boolean => {
                return !this.enableImageCheckbox(row);
            },
        };
    }

    /**
     * Trigger when there is change in image selection from grid.
     */
    public onImageSelectionChange(servers: IAviCollectionDataGridRow[]): void {
        this.selectedImagesToUpgrade = servers as Image[];
        this.isSelectedImagesValidForUpgrade = this.isImageSelectionValid();
        this.updateSelectedImageHash();
    }

    /**
     * Handle submit.
     */
    public handleSubmit(): void {
        this.onConfirm.emit(this.selectedImagesToUpgrade);
    }

    /**
     * Handle modal close.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Get base image version used to fetch compatible images.
     */
    private getRequestBaseImageVersion(): string {
        return UpdateService.getSegUpgradeListBaseImageVersion(this.segUpgradeNodeList);
    }

    /**
     * Update the selected images hash with selected image list.
     */
    private updateSelectedImageHash(): void {
        this.selectedImageHash = {
            systemImage: null,
            patchImage: null,
        };

        if (!this.isSelectedImagesValidForUpgrade) {
            return;
        }

        this.selectedImagesToUpgrade.forEach(image => {
            if (image.isType(imageTypeHash.IMAGE_TYPE_SYSTEM)) {
                this.selectedImageHash.systemImage = image;
            } else if (image.isType(imageTypeHash.IMAGE_TYPE_PATCH)) {
                this.selectedImageHash.patchImage = image;
            }
        });
    }

    /**
     * Check if image selection is valid for upgrade.
     */
    private isImageSelectionValid(): boolean {
        return this.updateService.isImageListValidForUpgrade(
            this.selectedImagesToUpgrade as any,
            UpgradeType.UPGRADE_TYPE_SEG_UPGRADE,
        );
    }

    /**
     * Enable an image selection checkbox when:
     * 1. No image has been selected yet.
     * 2. It's a selected image.
     * 3. It's a patch image with the same major version as the ONLY selected system image.
     * 4. It's a system image with the same major version as the ONLY selected patch image.
     */
    private enableImageCheckbox(image: Image): boolean {
        const { id } = image;

        const {
            systemImage: selectedSystemImage,
            patchImage: selectedPatchImage,
        } = this.selectedImageHash;

        if (!selectedSystemImage && !selectedPatchImage) {
            return true;
        }

        if (selectedSystemImage && id === selectedSystemImage.id ||
            selectedPatchImage && id === selectedPatchImage.id
        ) {
            return true;
        }

        // Only a system image has been selected.
        if (selectedSystemImage && !selectedPatchImage) {
            if (image.isType(imageTypeHash.IMAGE_TYPE_PATCH) &&
                image.majorVersion === selectedSystemImage.majorVersion
            ) {
                return true;
            }
        }

        // Only a patch image has been selected.
        if (selectedPatchImage && !selectedSystemImage) {
            if (image.isType(imageTypeHash.IMAGE_TYPE_SYSTEM) &&
                image.majorVersion === selectedPatchImage.majorVersion
            ) {
                return true;
            }
        }

        return false;
    }
}
