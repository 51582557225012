/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'vs-list-virtual-hosting-cell';
const prefix = `${moduleName}.${componentName}`;

export const parentLabel = `${prefix}.parentLabel`;
export const childLabel = `${prefix}.childLabel`;
export const enhancedVHLabel = `${prefix}.enhancedVHLabel`;
export const sniVHLabel = `${prefix}.sniVHLabel`;

export const ENGLISH = {
    [parentLabel]: 'Parent',
    [childLabel]: 'Child',
    [enhancedVHLabel]: 'Enhanced Virtual Hosting',
    [sniVHLabel]: 'SNI',
};
