/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ObjectTypeItem } from 'object-type-item';
import { AviPermissionResource, IBackup } from 'generated-types';
import { Backup } from 'object-types';

interface IBackupData {
    config: IBackup;
}

/**
 * @description Backup Item class
 * @author Alex Klyuev
 */
export class BackupItem extends ObjectTypeItem {
    public static ajsDependencies = [
        '$window',
    ];

    public data: IBackupData;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'backup',
            objectType: Backup,
            windowElement: 'backup-create',
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
        };

        super(extendedArgs);
    }

    /**
     * Makes request to download backup configuration file.
     * @param type - Used to specify local backup or remote backup. Local by default.
     */
    public downloadBackup(type: string): void {
        let location = this.config.local_file_url;

        if (type === 'remote') {
            location = this.config.remote_file_url;
        }

        const $window = this.getAjsDependency_('$window');

        $window.location.assign(`/api/fileservice?uri=${location}`);
    }
}
