/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Type } from '@angular/core';

import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    ISnmpTrapServer,
    SNMP_VER,
} from 'generated-types';

import {
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    SnmpTrapProfileListModalComponent,
} from 'ng/modules/snmp-trap-profile/components/snmp-trap-server-modal';

import { L10nService } from '@vmw/ngx-vip';
import { SnmpTrapServer } from 'object-types';
import { SnmpV3UserParamsConfigItem } from './snmp-v3-user-params.config-item.factory';
import * as l10n from '../../alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type SnmpTrapServerPartial = Omit<ISnmpTrapServer, 'user' | 'ip_addr'>;

interface ISnmpTrapServerConfig extends SnmpTrapServerPartial {
    user?: SnmpV3UserParamsConfigItem;
    ip_addr?: IpAddrConfigItem;
}

/**
 * @description SnmpTrapServers config Item.
 *
 * @author Guru Prasad
 */
export class SnmpTrapServerConfigItem extends
    withFullModalMixin(MessageItem)<ISnmpTrapServerConfig> {
    public static ajsDependencies = ['l10nService'];

    /**
     * Indicates if this SNMP Trap Server has previously been saved in the backend, in which case
     * the ip_addr and version fields are immutable.
     */
    public hasBeenSaved: boolean;

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: SnmpTrapServer,
            windowElement: SnmpTrapProfileListModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.hasBeenSaved = false;
    }

    /**
     * Getter for user.
     */
    public get user(): SnmpV3UserParamsConfigItem {
        return this.config.user;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertSnmpTrapServereBreadcrumbTitle);
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        if (!this.config.user) {
            this.safeSetNewChildByField('user');
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { version } = this.config;

        if (version === SNMP_VER.SNMP_VER2) {
            delete this.config.user;
        } else if (version === SNMP_VER.SNMP_VER3) {
            delete this.config.community;
        }
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ISnmpTrapServerConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const { user, ...defaults } = defaultConfig;

        return {
            ...defaults,
            user: {
                ...user,
                priv_passphrase: '',
                auth_passphrase: '',
            },
        };
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'ip_addr',
        ];
    }
}
