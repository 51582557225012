<match-wrapper
    class="bot-class-matcher"
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="bot-class-matcher"
        provideParentForm
    >
        <enum-radio-buttons
            [noMarginTop]="true"
            enum="MatchOperation"
            name="bot-type-matcher-op-{{ matchIndex }}"
            [(ngModel)]="editable.config.op"
            required
        ></enum-radio-buttons>

        <div class="bot-class-matcher__sub-field">
            <label
                aviLabelWithTooltip
                for="client-classes"
                objectType="{{ botClassMatcherObjectType }}"
                fieldName="client_classes"
                required
            >
                {{ l10nKeys.classTypesLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="client-classes"
                id="client-classes"
                [(ngModel)]="editable.config.client_classes"
                [options]="botClientClassOptions"
                placeholder="{{ l10nKeys.selectClientTypePlaceholder | vtranslate }}"
                [multiple]="true"
                required
            ></avi-dropdown>
        </div>
    </div>
</match-wrapper>
