/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { intersection } from 'underscore';
import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';
import { DialogService } from 'dialog-service';
import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { GslbSiteDnsVsConfigItem } from 'message-items/gslb-site-dns-vs.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation';

import { ISiteDataRow } from '../gslb-placement-grid/gslb-placement-grid.component';
import * as l10n from './gslb-placement-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * ID for 'host all subdomains' DnsVs confirmation dialog.
 */
const HOST_ALL_SUBDOMAINS_CHANGE_CONFIRMATION_DIALOG_ID =
    'host-all-subdomains-change-confirmation-dialog';

/**
 * @description Modal component for Site configuration in placement
 * section of GSLB.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gslb-placement-modal',
    templateUrl: './gslb-placement-modal.component.html',
})
export class GslbPlacementModalComponent implements OnInit {
    @Input()
    public gslbSites: RepeatedMessageItem<GslbSiteConfigItem>;

    @Input()
    public editMode = false;

    /**
     * Site and DnsVs information selected for placement.
     */
    @Input()
    public selectedSiteAndDnsVs: ISiteDataRow = {
        siteName: undefined,
        dnsVsNames: [],
    };

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<ISiteDataRow>();

    /**
     * Selected site for placement of subdomain.
     */
    @Input()
    public selectedGslbSite: GslbSiteConfigItem;

    public verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public gslbSitesOptions: IAviDropdownOption[] = [];

    public availableDnsVsOptions: IAviDropdownOption[] = [];

    private dnsVsWithHostAllSubdomains: string[];

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly dialogService: DialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.gslbSitesOptions =
            this.gslbSites.config.map((site: GslbSiteConfigItem) => {
                return createDropdownOption(site.config.name);
            });

        // For edit mode. Set DnsVs dropdown options based on selected site name.
        if (this.selectedSiteAndDnsVs.siteName) {
            this.selectedGslbSite = this.gslbSites.config.find((site: GslbSiteConfigItem) => {
                return site.config.name === this.selectedSiteAndDnsVs.siteName;
            });

            this.setAvailableDnsVsOptions();
        }
    }

    /**
     * Fire on submit. Open Confirmation dialog in case of host all subdomains DnsVs selected.
     */
    public submit(): void {
        if (intersection(this.selectedSiteAndDnsVs.dnsVsNames,
            this.dnsVsWithHostAllSubdomains).length) {
            this.dialogService.add({
                id: HOST_ALL_SUBDOMAINS_CHANGE_CONFIRMATION_DIALOG_ID,
                component: AviContinueConfirmationComponent as Type<Component>,
                componentProps: {
                    customHeader: this.l10nService.getMessage(l10nKeys.hostAllSubdomainLabel),
                    warning: this.l10nService
                        .getMessage(l10nKeys.selectHostAllSubdomainsDnsVsWarning),
                    onConfirm: () => {
                        this.dialogService
                            .remove(HOST_ALL_SUBDOMAINS_CHANGE_CONFIRMATION_DIALOG_ID);

                        this.onSubmit.emit(this.selectedSiteAndDnsVs);
                    },
                    onClose: () => {
                        this.dialogService
                            .remove(HOST_ALL_SUBDOMAINS_CHANGE_CONFIRMATION_DIALOG_ID);
                    },
                },
            });
        } else {
            this.onSubmit.emit(this.selectedSiteAndDnsVs);
        }
    }

    /**
     * Fire on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Update selected GSLBSite and set respective DnsVs options.
     */
    public onSelectedGslbSiteChange(siteName: string): void {
        this.selectedGslbSite = this.gslbSites.config.find((site: GslbSiteConfigItem) => {
            return site.config.name === siteName;
        });

        this.selectedSiteAndDnsVs.siteName = this.selectedGslbSite.config.name;
        this.selectedSiteAndDnsVs.dnsVsNames = [];

        this.setAvailableDnsVsOptions();
    }

    /**
     * Populate DnsVs dropdown options based on selected site.
     */
    private setAvailableDnsVsOptions(): void {
        const { dns_vses: dnsVses } = this.selectedGslbSite.config;

        this.dnsVsWithHostAllSubdomains = [];
        this.availableDnsVsOptions = [];

        dnsVses?.config
            .forEach(
                (dnsVs: GslbSiteDnsVsConfigItem) => {
                    let label;

                    if (dnsVs.config.domain_names?.length) {
                        label = this.stringService.name(dnsVs.config.dnsVsRef);
                    } else {
                        label = `${this.stringService.name(dnsVs.config.dnsVsRef)}
                        (${this.l10nService.getMessage(l10nKeys.hostAllSubdomainLabel)})`;

                        this.dnsVsWithHostAllSubdomains
                            .push(this.stringService.name(dnsVs.config.dnsVsRef));
                    }

                    this.availableDnsVsOptions.push(createDropdownOption(
                        this.stringService.name(dnsVs.config.dnsVsRef),
                        label,
                    ));
                },
            );
    }
}
