/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Action enums from the protobuf.
 */
export const WAF_ACTION = 'WafAction';
export const WAF_POLICY_ALLOWLIST_ACTION = 'WafPolicyAllowlistAction';

/**
 * @alias waf/wafLogsService
 * @private
 */
export class WafLogsService {
    constructor(schemaService) {
        const actionEnums = [WAF_ACTION, WAF_POLICY_ALLOWLIST_ACTION];

        /**
         * @type {Object<string, Object<string, string>>}
         */
        this.valueLabelsHashes_ = actionEnums.reduce((acc, actionEnum) => {
            acc[actionEnum] = schemaService.getEnumValueLabelsHash(actionEnum);

            return acc;
        }, {});
    }

    /**
     * Returns text to display based on an enum group and action enum.
     * @param {string} actionEnum - Group of action enums.
     * @param {string} value - Action enum.
     * @returns {string} Text to display from the enum.
     */
    getActionLabel_(actionEnum, value) {
        if (!(actionEnum in this.valueLabelsHashes_)) {
            throw new Error(`Enum ${actionEnum} does not exist in this service.`);
        }

        return this.valueLabelsHashes_[actionEnum][value];
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @param {string} actionEnum - WAF action enum.
     * @param {string[]} [actions=[]] - List of action enum values.
     * @returns {string} List of text to display from the enums.
     */
    getActionLabels(actionEnum, actions = []) {
        return actions.map(action => this.getActionLabel_(actionEnum, action)).join(', ');
    }

    /**
     * Reducer to populate a hash with a ruleGroup's rules. For a rule that does not have a group
     * name, it will be given a name in the format '__N/A:<index>' to prevent a duplication of names
     * in the hash.
     * @param {Object} hash - Object to populate rules with.
     * @param {WafRule} rule
     * @param {number} index
     * @protected
     * @returns {Object}
     */
    static ruleGroupReducer_(hash, rule, index) {
        const { rule_group: ruleGroup = `__N/A:${index}` } = rule;

        hash[ruleGroup] = hash[ruleGroup] || [];
        hash[ruleGroup].push(rule);

        return hash;
    }
}

WafLogsService.$inject = [
    'schemaService',
];

/**
 * @ngdoc service
 * @name wafLogsService
 * @author alextsg
 * @module waf/wafLogsService
 * @desc Stateless service for WAF logs. Used to create lookup hash of labels for WAF actions.
 */
angular.module('avi/waf').service('wafLogsService', WafLogsService);
