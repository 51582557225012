/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-access-system-access-card';
const prefix = `${moduleName}.${componentName}`;

export const systemAccessHeader = `${prefix}.systemAccessHeader`;
export const httpAccessLabel = `${prefix}.httpAccessLabel`;
export const httpsAccessLabel = `${prefix}.httpsAccessLabel`;
export const httpLabel = `${prefix}.httpLabel`;
export const httpsRedirectLabel = `${prefix}.httpsRedirectLabel`;
export const basicAuthenticationLabel = `${prefix}.basicAuthenticationLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const allowedLabel = `${prefix}.allowedLabel`;
export const disallowedLabel = `${prefix}.disallowedLabel`;

export const ENGLISH = {
    [systemAccessHeader]: 'System Access',
    [httpAccessLabel]: 'HTTP Access',
    [httpsAccessLabel]: 'HTTPS Access',
    [httpLabel]: 'HTTP',
    [httpsRedirectLabel]: 'HTTPS Redirect',
    [basicAuthenticationLabel]: 'Basic Authentication',
    [enabledLabel]: 'Enabled',
    [disabledLabel]: 'Disabled',
    [allowedLabel]: 'Allowed',
    [disallowedLabel]: 'Disallowed',
};
