/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';

import {
    WebhookCollection,
    WebhookItem,
} from '../../factories';

import * as l10n from './webhook-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TWebhookCollection = typeof WebhookCollection;

/**
 * @description Webhook list page.
 * @author sghare, Nitesh kesarkar, alextsg
 */
@Component({
    selector: 'webhook-list-page',
    templateUrl: './webhook-list-page.component.html',
})
export class WebhookListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display webhookGridConfig grid.
     */
    public webhookGridConfig: IAviCollectionDataGridConfig;

    /**
     * webhook Collection instance.
     */
    public readonly webhookCollection: WebhookCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(WebhookCollection)
        WebhookCollection: TWebhookCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.webhookCollection = new WebhookCollection();
    }

    /**
     * @override
     * Set grid config options.
     */
    public ngOnInit(): void {
        const { objectName } = this.webhookCollection;

        this.webhookGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.webhookCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: WebhookItem) => row.getName(),
            }, {
                id: 'callback-url',
                label: this.l10nService.getMessage(l10nKeys.columnTitleCallbackUrl),
                transform: (row: WebhookItem) => row.config.callback_url || '-',
            }, {
                id: 'verification-token',
                label: this.l10nService.getMessage(l10nKeys.columnTitleVerificationToken),
                transform: (row: WebhookItem) => row.config.verification_token || '-',
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.webhookCollection.destroy();
    }
}
