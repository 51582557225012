/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './software-upload-cancel-confirmation.component.less';

import template from './software-upload-cancel-confirmation.component.html';
import * as l10n from './software-upload-cancel-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @description
 *
 *   Component for confirmation dialog showed when software upload is getting canceled.
 *
 * @author Zhiqian Liu, Ram Pal
 */
class SoftwareUploadCancelConfirmationController {
    constructor(l10nService) {
        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called if the user confirms cancelling upload.
     */
    handleSubmit() {
        this.onSubmit();
        this.closeModal();
    }

    /**
     * Called if the user decides not cancel upload.
     */
    handleCancel() {
        this.onCancel();
        this.closeModal();
    }
}

export const softwareUploadCancelConfirmationOptions = {
    bindings: {
        onSubmit: '&',
        onCancel: '&',
        closeModal: '&',
        promptText: '@',
    },
    controller: SoftwareUploadCancelConfirmationController,
    template,
};
