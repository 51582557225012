/**
 * @module NatModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { NatAddrInfoConfigItem } from './nat-addr-info.config-item.factory';

interface INatPolicyActionConfig {
    nat_info: RepeatedMessageItem<NatAddrInfoConfigItem>;
    type: string;
}

/**
 * @desc NatPolicyAction MessageItem class.
 * @author Aravindh Nagarajan
 */
export class NatPolicyActionConfigItem extends MessageItem<INatPolicyActionConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatPolicyAction',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for nat_info.
     */
    public get natInfo(): RepeatedMessageItem<NatAddrInfoConfigItem> {
        return this.config.nat_info;
    }

    /**
     * Getter for action type
     */
    public get type(): string {
        return this.config.type;
    }
}
