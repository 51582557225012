/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    IGslbClientIpAddrGroup,
} from 'generated-types';

import {
    anyIP,
    anySubnet,
    ipAddrRange,
} from 'ng/utils/regex.utils';

import { GslbClientIpAddrGroup } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IpAddrRangeConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-range.config-item.factory';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';

import {
    IpAddrConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';

import { TStringRow } from 'ng/modules/data-grid';

type TGslbClientIpAddrGroupPartial = Omit<IGslbClientIpAddrGroup, 'addrs' | 'prefixes' | 'ranges'>;

interface IGslbClientIpAddrGroupConfig extends TGslbClientIpAddrGroupPartial {
    prefixes: RepeatedMessageItem<IpAddrPrefixConfigItem>;
    ranges: RepeatedMessageItem<IpAddrRangeConfigItem>;
    addrs: RepeatedMessageItem<IpAddrConfigItem>;
}

/**
 * @description GslbClientIpAddrGroup ConfigItem class.
 * @author Hitesh Mandav
 */
export class GslbClientIpAddrGroupConfigItem extends MessageItem<IGslbClientIpAddrGroupConfig> {
    public static ajsDependencies = [
        'RangeParser',
    ];

    /**
     * Ip address list containing all address in string format to display in grid.
     */
    public addressList: TStringRow[];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbclientipaddrgroup',
            objectType: GslbClientIpAddrGroup,
            ...args,
        };

        super(extendedArgs);

        this.addressList = this.getAddressList();
    }

    /**
     * Return an array of all addresses in this.config.
     */
    public getAddressList(): TStringRow[] {
        const { addrs, ranges, prefixes } = this.config;
        const repeatedConfigItems = [addrs, ranges, prefixes];

        const addressList = repeatedConfigItems.reduce((
            acc,
            // eslint-disable-next-line max-len
            base: RepeatedMessageItem<IpAddrPrefixConfigItem | IpAddrRangeConfigItem | IpAddrConfigItem>,
        ) => {
            const labels = base.config.map(configItem => {
                return { value: configItem.toString() } || [];
            });

            return acc.concat(labels);
        }, []);

        return addressList;
    }

    /**
     * Add empty address to address list.
     */
    public addAddress(): void {
        this.addressList.push({
            value: '',
        });
    }

    /**
     * Remove selected address from addres list.
     */
    public removeAddress(ipAdderes: TStringRow): void {
        const { addressList } = this;
        const index = addressList.indexOf(ipAdderes);

        addressList.splice(index, 1);
    }

    /**
     * Parse the address list and add to appropriate address, prefix, or range.
     * Called on form submit.
     */
    public parseAddressConfig(): void {
        const rangeParser = this.getAjsDependency_('RangeParser');

        this.removeAllAddress();

        if (!this.addressList.length) {
            delete this.config.type;

            return;
        }

        this.addressList.forEach(address => {
            const { value } = address;
            const addr = rangeParser.ipRange2Json(value);

            if (ipAddrRange.test(value)) {
                this.config.ranges.add(addr);

                return;
            }

            if (anyIP.test(value)) {
                this.config.addrs.add(addr);

                return;
            }

            if (anySubnet.test(value)) {
                this.config.prefixes.add(addr);
            }
        });
    }

    /**
     * Remove all addresses, prefixes, and ranges from configuration.
     */
    private removeAllAddress(): void {
        const {
            addrs,
            prefixes,
            ranges,
        } = this.config;

        const repeatedMessageItems = [addrs, prefixes, ranges];

        repeatedMessageItems.forEach(repeatedMessageItem => repeatedMessageItem.removeAll());
    }
}
