<div
    class="gslb-avi-site-config"
    provideParentForm
>
    <!-- Credentials -->
    <fieldset class="full-modal-config__fieldset">
        <h6 class="full-modal__section-header">{{ globalL10nKeys.credentialsLabel | vtranslate }}</h6>
        <credentials-verification
            [editCredentialsConfig]="editGslbSiteCredentialsConfig"
            [isEditing]="isEditing"
        >
            <div class="full-modal-config__form-control-row gslb-avi-site-config__no-margin-top">
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.GslbSite }}"
                            fieldName="username"
                            for="username"
                            required
                        >
                            {{ globalL10nKeys.usernameLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="username"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="username"
                            [(ngModel)]="editable.config.username"
                            readonly
                            required
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.GslbSite }}"
                            fieldName="password"
                            for="password"
                            required
                        >
                            {{ globalL10nKeys.passwordLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="password"
                            type="password"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="password"
                            [(ngModel)]="editable.config.password"
                            readonly
                            required
                        />
                    </avi-input-container>
                </div>
            </div>
        </credentials-verification>
    </fieldset>
    <!-- Site Controller Grid -->
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <gslb-ip-address-grid
                [ipAddr]="ipAddresses"
            ></gslb-ip-address-grid>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType.GslbSite }}"
                    fieldName="port"
                    for="port"
                    required
                >
                    {{ globalL10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    id="port"
                    name="port"
                    [(ngModel)]="editable.config.port"
                    clrInput
                    placeholder="{{ globalL10nKeys.portPlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>
    </div>
    <!-- Geo Location -->
    <gslb-geo-location-config
        [editable]="editable.config.location"
        [isLocationConfigured]="isLocationConfigured"
        (onSetGeoLocationSource)="setGeoLocationSource($event)"
    ></gslb-geo-location-config>
    <!-- Health Monitor proxy Grid -->
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <gslb-health-monitor-proxy-grid
                [activeGslbSiteOptions]="activeGslbSiteOptions"
                [healthMonitorProxies]="editable.config.hm_proxies"
            ></gslb-health-monitor-proxy-grid>
        </div>
    </div>
    <avi-checkbox
        name="hm-shard-enabled"
        [(ngModel)]="editable.config.hm_shard_enabled"
        objectType="{{ objectType.GslbSite }}"
        fieldName="hm_shard_enabled"
    >
        {{ l10nKeys.enableHealthMonitorShardingLabel | vtranslate }}
    </avi-checkbox>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType.GslbSite }}"
            fieldName="ratio"
            for="ratio"
        >
            {{ l10nKeys.overridePoolMemberRatioLabel | vtranslate }}
        </label>
        <input
            id="ratio"
            name="ratio"
            fieldName="ratio"
            objectType="{{ objectType.GslbSite }}"
            configFieldInputValidation
            [(ngModel)]="editable.config.ratio"
            clrInput
            placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
        />
    </avi-input-container>
</div>
