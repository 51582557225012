/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { HealthMonitorHttp } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IHealthMonitorHttpConfig } from '../healthmonitor.types';

/**
 * @description Health Monitor Http Config Item.
 * @author Rajawant Prajapati
 */
export class HealthMonitorHttpConfigItem extends MessageItem<IHealthMonitorHttpConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorHttp,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ssl_attributes'];
    }
}
