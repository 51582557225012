/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import {
    IUIProperty,
    IUserPreferences,
    Timeframe,
} from './user-preferences.state';

interface IUserPreferencesPayload {
    ui_property: string;
}

const USER_PREFERENCES_URL = '/api/userpreferences';

@Injectable({
    providedIn: 'root',
})
export class UserPreferencesService {
    constructor(
        private readonly http: AlbHttpClient,
        private readonly devLoggerService: DevLoggerService,
    ) {}

    /**
     * Transforms the uiProperty object and populates it with default values if needed.
     */
    public static transformUIProperty(uiProperty: IUIProperty): IUIProperty {
        const uiPropertyClone = cloneDeep(uiProperty) || {};
        const defaultUiProperties = {
            timeframe: Timeframe.PAST_SIX_HOURS,
            valuesToDisplay: 'avg',
            showNewVsLogsPage: false,
            useUTCTime: false,
            language: 'en_US',
        };

        for (const property in defaultUiProperties) {
            if (!(property in uiPropertyClone)) {
                uiPropertyClone[property] = defaultUiProperties[property];
            }
        }

        uiPropertyClone.logs = uiPropertyClone.logs || {};

        const { logs } = uiPropertyClone;

        if (!('savedSearch' in logs)) {
            logs.savedSearch = [];
        }

        // migration for pre 16.3 versions
        if ('showMetricsLogs' in uiPropertyClone) {
            if (!('hideLogSummaries' in logs)) {
                logs.hideLogSummaries = !uiPropertyClone.showMetricsLogs;
            }

            delete uiPropertyClone.showMetricsLogs;
        }

        uiPropertyClone.appDashboard = uiPropertyClone.appDashboard || {};

        const { appDashboard } = uiPropertyClone;

        if (!('viewType' in appDashboard)) {
            appDashboard.viewType = 'list';
        }

        uiPropertyClone.grid = uiPropertyClone.grid || {};

        const { grid } = uiPropertyClone;

        if (!('pageSize' in grid)) {
            grid.pageSize = 30;
        }

        return uiPropertyClone;
    }

    /**
     * Loads controller properties.
     */
    public fetchUserPreferences(): Observable<IUserPreferences> {
        return this.http.get<IUserPreferencesPayload>(USER_PREFERENCES_URL)
            .pipe(
                map(userPreferences => {
                    const { ui_property: uiPropertyString } = userPreferences;
                    const uiProperty = JSON.parse(uiPropertyString) || {};

                    return {
                        ui_property: UserPreferencesService.transformUIProperty(uiProperty),
                    };
                }),
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(error);
                }),
            );
    }

    /**
     * Saves controller properties.
     */
    public saveUserPreferences(
        userPreferences: IUserPreferencesPayload,
    ): Observable<IUserPreferences> {
        return this.http.put<IUserPreferences>(USER_PREFERENCES_URL, userPreferences)
            .pipe(
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(error);
                }),
            );
    }

    /**
     * Saves UIProperty. Internally calls saveUserPreferences as UIProperty is a child property of
     * the UserPreferences payload.
     */
    public saveUIProperty(uiProperty: IUIProperty): Observable<IUIProperty> {
        const payload: IUserPreferencesPayload = {
            ui_property: JSON.stringify(uiProperty),
        };

        return this.saveUserPreferences(payload)
            .pipe(
                map(() => uiProperty),
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(error);
                }),
            );
    }
}
