/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';
import { IVipPlacementNetwork } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { L10nService } from '@vmw/ngx-vip';

import {
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';

import { StringService } from 'string-service';
import * as l10n from '../vs.l10n';

type TVipPlacementNetwork = Omit<IVipPlacementNetwork, 'subnet' | 'subnet6'>;

interface IVipPlacementNetworkConfig extends TVipPlacementNetwork {
    subnet: IpAddrPrefixConfigItem;
    subnet6: IpAddrPrefixConfigItem;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for VipPlacementNetworkConfigItem.
 */
export const VIP_PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN = 'VipPlacementNetworkConfigItem';

/**
 * @description VipPlacementNetwork ConfigItem class.
 * @author alextsg
 */
export class VipPlacementNetworkConfigItem extends
    withFullModalMixin(MessageItem)<IVipPlacementNetworkConfig> {
    public static ajsDependencies = [
        'stringService',
        'l10nService',
    ];

    private readonly l10nService: L10nService;
    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'VipPlacementNetwork',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.stringService = this.getAjsDependency_('stringService');
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Reset subnet fields.
     */
    public resetSubnets(): void {
        this.setNewChildByField('subnet');
        this.setNewChildByField('subnet6');
    }

    /**
     * Getter for network name.
     */
    public get network(): string {
        return this.config.network_ref ?
            this.stringService.name(this.config.network_ref) : '';
    }

    /**
     * Getter for network uuid.
     */
    public get networkId(): string {
        return this.config.network_ref ?
            this.stringService.slug(this.config.network_ref) : '';
    }

    /**
     * @override
     * Import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { VipPlacementNetworkModalComponent } = await import(
            /* webpackChunkName: "vs-vip-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/vs-vip-modal/vip-placement-network-modal/vip-placement-network-modal.component'
        );

        return VipPlacementNetworkModalComponent as Type<Component>;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'subnet',
            'subnet6',
        ];
    }

    /**
     * @override
     */
    protected canFlatten(): boolean {
        const { subnet, subnet6 } = this.config;

        return subnet.canFlatten() || subnet6.canFlatten();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.placementNetworkModalBreadcrumbTitle);
    }

    /** @override */
    protected getModalBreadcrumbDescription(): string {
        return this.network;
    }
}
