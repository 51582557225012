/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'mapping-rule-group-match';
const prefix = `${moduleName}.${componentName}`;

export const groupMatchLabel = `${prefix}.groupMatchLabel`;
export const memberLabel = `${prefix}.memberLabel`;
export const notMemberLabel = `${prefix}.notMemberLabel`;
export const groupLabel = `${prefix}.groupLabel`;
export const groupNamePlaceholder = `${prefix}.groupNamePlaceholder`;
export const groupNameHelperText = `${prefix}.groupNameHelperText`;

export const ENGLISH = {
    [groupMatchLabel]: 'LDAP Group Match',
    [memberLabel]: 'Member',
    [notMemberLabel]: 'Not a Member',
    [groupLabel]: 'Group',
    [groupNamePlaceholder]: 'Enter Group Name',
    [groupNameHelperText]: 'Press enter to add multiple group names',
};
