/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'item-preview-defaults';
const prefix = `${moduleName}.${componentName}`;

export const tenantLabel = `${prefix}.tenantLabel`;
export const lastModifiedLabel = `${prefix}.lastModifiedLabel`;

export const ENGLISH = {
    [tenantLabel]: 'Tenant',
    [lastModifiedLabel]: 'Last Modified',
};
