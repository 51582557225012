<div
    class="avi-radio-container"
    [ngClass]="{ 'avi-radio-container--no-margin-top': noMarginTop }"
>
    <clr-radio-container
        class="avi-radio-container__clr-radio-container"
        clrInline
    >
        <ng-container ngProjectAs="label">
            <ng-content></ng-content>
        </ng-container>
        <ng-container ngProjectAs="clr-radio-wrapper">
            <ng-content select="avi-radio"></ng-content>
        </ng-container>
    </clr-radio-container>
</div>
