<ng-container *ngrxLet="oAuthLog$ as oAuthLog">
    <div class="vs-log-cinematic-sub-section-o-auth__column-group">
        <!-- 1st column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.stateLabel | vtranslate }}
            </div>
            <div
                *ngIf="oAuthLog.state as state; else na"
                vsLogFilter
                property="oauth_log.state"
                [value]="state"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ state }}
            </div>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.sessionCookieExpiredLabel | vtranslate }}</div>
            <div
                *ngIf="oAuthLog.is_session_cookie_expired !== undefined; else na"
                vsLogFilter
                property="oauth_log.is_session_cookie_expired"
                [value]="oAuthLog.is_session_cookie_expired"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ oAuthLog.is_session_cookie_expired }}
            </div>
        </div>

        <!-- 2nd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authorizationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                *ngIf="oAuthLog.authz_rule_match?.rule_name as ruleName; else na"
                vsLogFilter
                property="oauth_log.authz_rule_match.rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                *ngIf="oAuthLog.authz_rule_match?.rule_action as action; else na"
                vsLogFilter
                property="oauth_log.authz_rule_match.rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>

        <!-- 3rd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authenticationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                *ngIf="oAuthLog.authn_rule_match?.rule_name as ruleName; else na"
                vsLogFilter
                property="oauth_log.authn_rule_match.rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                *ngIf="oAuthLog.authn_rule_match?.rule_action as action; else na"
                vsLogFilter
                property="oauth_log.authn_rule_match.rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>
    </div>

    <!-- Expandable sub-request logs -->
    <ng-container *ngFor="let oAuthSubRequestField of oAuthSubRequestFields; trackBy: trackByIndex">
        <expander
            *ngIf="oAuthLog[oAuthSubRequestField.fieldName] as oAuthSubRequestLog"
            openerClassName="vs-log-cinematic-sub-section-o-auth__expander-opener"
        >
            <opener class="vs-log-cinematic-sub-section-o-auth__expander-opener-text">
                {{ oAuthSubRequestField.fieldLabel }}
            </opener>
            <content class="vs-log-cinematic-sub-section-o-auth__expander-content">
                <!-- a workaround of setting "fieldName" for the contained scope -->
                <ng-container *ngIf="oAuthSubRequestField.fieldName as fieldName">
                    <!-- Summary section -->
                    <div class="vs-log-cinematic-sub-section-o-auth__expander-content-section vs-log-cinematic-sub-section-o-auth__column-group">
                        <!-- left column -->
                        <div vsLogCinematicSectionColumn>
                            <h5 class="vs-log-cinematic-section__column-title">
                                {{ globalL10nKeys.summaryLabel | vtranslate }}
                            </h5>

                            <ng-container *ngIf="oAuthSubRequestLog.sub_request_log as subRequestLog">
                                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.poolLabel | vtranslate }}</div>
                                <div
                                    *ngIf="subRequestLog.pool_name as poolName; else na"
                                    vsLogFilter
                                    [property]="subRequestLogFilterPropertyHash[fieldName]['pool_name']"
                                    [value]="poolName"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ poolName }}
                                </div>

                                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.sourcePortLabel | vtranslate }}</div>
                                <div
                                    *ngIf="subRequestLog.source_port !== undefined; else na"
                                    vsLogFilter
                                    [property]="subRequestLogFilterPropertyHash[fieldName]['source_port']"
                                    [value]="subRequestLog.source_port"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ subRequestLog.source_port }}
                                </div>

                                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.totalTimeLabel | vtranslate }}</div>
                                <ng-container *ngIf="subRequestLog.total_time !== undefined; else na">
                                    <span
                                        vsLogFilterList
                                        [property]="subRequestLogFilterPropertyHash[fieldName]['total_time']"
                                        [value]="subRequestLog.total_time"
                                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    >
                                        {{ subRequestLog.total_time }}
                                    </span>
                                    {{ globalL10nKeys.msLabel | vtranslate }}
                                </ng-container>

                                <div vsLogCinematicFieldTitle>{{ l10nKeys.errorCodeLabel | vtranslate }}</div>
                                <div
                                    *ngIf="oAuthSubRequestLog.error_code !== undefined; else na"
                                    vsLogFilter
                                    [property]="subRequestLogFilterPropertyHash[fieldName]['error_code']"
                                    [value]="subRequestLog.error_code"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ oAuthSubRequestLog.error_code }}
                                </div>
                            </ng-container>
                        </div>

                        <!-- right column -->
                        <div
                            vsLogCinematicSectionColumn
                            [withDivider]="false"
                            *ngIf="oAuthSubRequestLog.sub_request_log as subRequestLog"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        >
                            <div
                                vsLogCinematicFieldTitle
                                [withMarginTop]="false"
                            >
                                {{ globalL10nKeys.descriptionLabel | vtranslate }}
                            </div>
                            {{ schemaService.getFieldDescription('OauthLog', fieldName) }}

                            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.serverNameLabel | vtranslate }}</div>
                            <div
                                *ngIf="subRequestLog.server_name as serverName; else na"
                                vsLogFilter
                                [property]="subRequestLogFilterPropertyHash[fieldName]['server_name']"
                                [value]="serverName"
                                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            >
                                {{ serverName }}
                            </div>

                            <div vsLogCinematicFieldTitle>
                                {{ globalL10nKeys.serverIpLabel | vtranslate }}:{{ globalL10nKeys.portLabel | vtranslate }}
                            </div>
                            <vs-log-cinematic-ip-field
                                *ngIf="subRequestLog.server_ip !== undefined; else na"
                                [property]="subRequestLogFilterPropertyHash[fieldName]['server_ip']"
                                [ip]="subRequestLog.server_ip"
                                [portProperty]="subRequestLogFilterPropertyHash[fieldName]['server_port']"
                                [port]="subRequestLog.server_port"
                            ></vs-log-cinematic-ip-field>

                            <h5 class="vs-log-cinematic-section__column-title">
                                {{ globalL10nKeys.responseLabel | vtranslate }}
                            </h5>

                            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.responseCodeLabel | vtranslate }}</div>
                            <div
                                *ngIf="subRequestLog.http_response_code !== undefined; else na"
                                vsLogFilter
                                [property]="subRequestLogFilterPropertyHash[fieldName]['http_response_code']"
                                [value]="subRequestLog.http_response_code"
                                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            >
                                {{ subRequestLog.http_response_code }}
                            </div>
                        </div>
                    </div>

                    <!-- Headers section -->
                    <div
                        class="vs-log-cinematic-sub-section-o-auth__expander-content-section"
                        *ngIf="oAuthSubRequestLog.sub_request_log as subRequestLog"
                    >
                        <div vsLogCinematicSectionColumn>
                            <h5 class="vs-log-cinematic-section__column-title">
                                {{ l10nKeys.oAuthHeadersLabel | vtranslate }}
                            </h5>
                            <avi-diff-grid
                                [config]="headersDiffGridConfig"
                                [rows]="subRequestLog | subLogHeadersDiffSourcePair : 'headers_sent_to_server' : 'headers_received_from_server' | aviDiffGridRows"
                            ></avi-diff-grid>
                        </div>
                    </div>
                </ng-container>
            </content>
        </expander>
    </ng-container>
</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
