/**
 * Module for HealthMonitor
 * @module HealthmonitorModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    HealthMonitorCollection,
    HealthMonitorFtpConfigItem,
    HealthMonitorHttpConfigItem,
    HealthMonitorImapConfigItem,
    HealthMonitorItem,
    HealthMonitorLdapConfigItem,
    HealthMonitorPop3ConfigItem,
    HealthMonitorSmtpConfigItem,
} from '.';

import {
    HEALTH_MONITOR_COLLECTION_TOKEN,
    HEALTH_MONITOR_FTP_CONFIG_TOKEN,
    HEALTH_MONITOR_HTTP_CONFIG_TOKEN,
    HEALTH_MONITOR_ITEM_TOKEN,
    HEALTH_MONITOR_LDAP_CONFIG_TOKEN,
    HEALTH_MONITOR_POP3_CONFIG_TOKEN,
    HEALTH_MONITOR_SMTP_CONFIG_TOKEN,
    IMAPS_MONITOR_CONFIG_TOKEN,
} from './healthmonitor.tokens';

const healthmonitorModule = angular.module('avi/healthmonitor');

const factories = [
    {
        factory: HealthMonitorImapConfigItem,
        name: IMAPS_MONITOR_CONFIG_TOKEN,
    },
    {
        factory: HealthMonitorItem,
        name: HEALTH_MONITOR_ITEM_TOKEN,
    },
    {
        factory: HealthMonitorCollection,
        name: HEALTH_MONITOR_COLLECTION_TOKEN,
    },
    {
        factory: HealthMonitorHttpConfigItem,
        name: HEALTH_MONITOR_HTTP_CONFIG_TOKEN,
    },
    {
        factory: HealthMonitorSmtpConfigItem,
        name: HEALTH_MONITOR_SMTP_CONFIG_TOKEN,
    },
    {
        factory: HealthMonitorPop3ConfigItem,
        name: HEALTH_MONITOR_POP3_CONFIG_TOKEN,
    },
    {
        factory: HealthMonitorLdapConfigItem,
        name: HEALTH_MONITOR_LDAP_CONFIG_TOKEN,
    },
    {
        factory: HealthMonitorFtpConfigItem,
        name: HEALTH_MONITOR_FTP_CONFIG_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        healthmonitorModule,
        'factory',
        name,
        factory,
    );
});
