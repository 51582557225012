<div class="role-access-expander sel-role-permission">
    <div class="role-access-expander__header">
        <div class="role-access-expander__legend-container">
            <div class="role-access-expander__legend">
                <cds-icon shape="circle"></cds-icon>
                <span class="role-access-expander__legend-label">{{ l10nKeys.assortedLabel | vtranslate }}</span>
            </div>
            <div class="role-access-expander__legend">
                <cds-icon shape="pencil"></cds-icon>
                <span class="role-access-expander__legend-label">{{ l10nKeys.writeLabel | vtranslate }}</span>
            </div>
            <div class="role-access-expander__legend">
                <cds-icon shape="eye"></cds-icon>
                <span class="role-access-expander__legend-label">{{ l10nKeys.readLabel | vtranslate }}</span>
            </div>
            <div class="role-access-expander__legend">
                <cds-icon shape="no-access"></cds-icon>
                <span class="role-access-expander__legend-label">{{ l10nKeys.noAccessLabel | vtranslate }}</span>
            </div>
        </div>
      <div class="role-access-expander__filter-container">
            <avi-search 
                (onSearch)="handleSearch($event)"
            ></avi-search>
        </div>
    </div>

    <clr-accordion 
        class="role-access-expander__accordian"
        [clrAccordionMultiPanel]="true"
    >
        <clr-accordion-panel
            #clrPanel
            [clrAccordionPanelOpen]="true"
            *ngFor="let item of filteredPrivileges; trackBy: trackByIndex"
        >
            <clr-accordion-title>
                <div class="role-access-expander__accordian-title-container">
                    <div class="role-access-expander__accordian-title sel-name">
                        {{item.name}}
                    </div>
                <!-- clrAccordianPanel provide observable for panel object. -->
                    <ng-container *ngIf="clrPanel.panel | async; let panel">
                        <div class="role-access-expander__permissions-header">
                            <role-permission-setter
                                [role]="item"
                                [showAssortedIcon]="true"
                            ></role-permission-setter>
                        </div>
                        <clr-icon
                            class="sel-clr-icon"
                            shape="angle"
                            direction="{{ panel.open ? 'up' : 'down'}}"
                        ></clr-icon>
                    </ng-container>
                </div>
            </clr-accordion-title>
            <clr-accordion-content *clrIfExpanded>
                <ul class="role-access-expander__permission-list sel-full_permission_name_list">
                    <li 
                        class="role-access-expander__permission-item sel-name"
                        *ngFor="let child of item.children; trackBy: trackByIndex"
                    >
                        <p>{{ child.name }}</p>
                        <div>
                            <role-permission-setter
                                [role]="child"
                            ></role-permission-setter>
                        </div>
                    </li>
                </ul>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>
