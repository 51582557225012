/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IFullModalTab } from '../full-modal-config.component';
import './full-modal-config-header-tabs.component.less';

/**
 * Constants used in the class.
 */
const BASE_CLASS_NAME = 'full-modal-config-header-tabs__tab';

/**
 * @description
 * Component to display tabs in the config modal header. Adding tabs dynamically is not supported -
 * tabs are added when the full-modal is initialized.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-header-tabs',
    templateUrl: './full-modal-config-header-tabs.component.html',
})
export class FullModalConfigHeaderTabsComponent {
    /**
     * List of navigation tabs to render.
     */
    @Input()
    public tabs: IFullModalTab[] = [];

    /**
     * ID of the active tab.
     */
    @Input()
    public activeTabId: string;

    /**
     * Flag to disable tabs.
     */
    @Input()
    public disableTabs: boolean;

    /**
     * Emitted when the user clicks on a navigation tab.
     */
    @Output()
    public onSelect = new EventEmitter<IFullModalTab>();

    /**
     * Handler for clicking a navigation tab.
     */
    public handleSelect(tab: IFullModalTab): void {
        // Allow scrolling if tabs are not disabled.
        if (!this.disableTabs) {
            this.onSelect.emit(tab);
        }
    }

    /**
     * Returns the tab class name based on input property.
     */
    public getTabClassName(tab: IFullModalTab): string | null {
        if (this.disableTabs) {
            return `${BASE_CLASS_NAME}--disable`;
        }

        return this.isActive(tab) ? `${BASE_CLASS_NAME}--active` : null;
    }

    /**
     * TrackBy function for the list of tabs.
     */
    public trackByTabId(index: number, tab: IFullModalTab): string {
        return tab.id;
    }

    /**
     * Returns true if the tab ID matches the active tab ID.
     */
    private isActive(tab: IFullModalTab): boolean {
        return tab.id === this.activeTabId;
    }
}
