<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ globalL10nKeys.pkiProfileLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="pkiProfileListPageGridConfig"></avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div *ngIf="!row.busy" class="avi-data-grid__childGrid">
        <h3>{{ l10nKeys.caCertificationsHeader | vtranslate }}</h3>
        <avi-data-grid
            [config]="caGridConfig"
            [rows]="row.pkiCertificateAuthority.config"
        ></avi-data-grid>
    </div>

    <div
        *ngIf="row.busy"
        class="avi-data-grid__loader"
    >
        <clr-spinner clrMedium></clr-spinner>
    </div>
    
</ng-template>

<ng-template
    #certTextTemplateRef
    let-row
>
    <pre class="cert-text__expanded">{{row.config.text}}</pre>
</ng-template>
