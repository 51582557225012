/**
 * Module containing reusable components for Infrastructure related modals.
 * @module InfrastructureModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClrInputModule } from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { CustomTagsComponent } from '.';

const infrastructureComponents = [
    CustomTagsComponent,
];

/**
 *  Module for Infrastructure.
 *  @author Sarthak Kapoor
 */
@NgModule({
    declarations: [
        ...infrastructureComponents,
    ],
    imports: [
        ClrInputModule,
        CommonModule,
        DataGridModule,
        FormsModule,
        SharedModule,
    ],
    exports: [
        ...infrastructureComponents,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class InfrastructureModule {}
