/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import {
    pluck,
    unique,
} from 'underscore';

import {
    AviPermissionResource,
    IVSDataScript,
    IVSDataScriptSet,
    VSDataScriptEvent,
} from 'generated-types';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { StringService } from 'string-service';
import { DataScriptSetModalComponent } from 'ng/modules/scripts';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { RateLimiterConfigItem } from '.';

import {
    dnsEventTypes,
    httpEventTypes,
    l4EventTypes,
    sslEventTypes,
} from '../../constants';

type TDataScriptTypesHash = Record<VSDataScriptEvent, IVSDataScript>;

/**
 * Common prefix for all Datascript event names.
 */
const VSDataScriptEventPrefix = 'VS_DATASCRIPT_EVT_';

export type TDataScriptSet = typeof DataScriptSet;

type TVSDataScriptSetPartial = Omit<IVSDataScriptSet, 'rate_limiters'>;
interface IExtendedVSDataScriptSet extends TVSDataScriptSetPartial {
    l4Scripts: IVSDataScript[];
    dnsScripts: IVSDataScript[];
    sslScripts: IVSDataScript[];
    httpScripts: IVSDataScript[];
    rate_limiters: RepeatedMessageItem<RateLimiterConfigItem>;
}

interface IVSDataScriptSetData {
    config: IExtendedVSDataScriptSet;
}

/**
 * @description VS DatascriptSet item.
 *
 * @author Aravindh Nagarajan
 */
export class DataScriptSet extends withFullModalMixin(ObjectTypeItem) {
    private static readonly supportedEventTypes = [
        ...l4EventTypes,
        ...dnsEventTypes,
        ...sslEventTypes,
        ...httpEventTypes,
    ];

    public data: IVSDataScriptSetData;
    public getConfig: () => IExtendedVSDataScriptSet;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'vsdatascriptset',
            objectType: 'VSDataScriptSet',
            windowElement: DataScriptSetModalComponent,
            permissionName: AviPermissionResource.PERMISSION_VSDATASCRIPTSET,
            whitelistedFields: [
               'rate_limiters',
            ],
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns human readable string out of VSDataScriptEvent enum value.
     */
    public static getDSEventLabel(eventName: VSDataScriptEvent): string {
        const stringService: StringService = this.getAjsDependency_('stringService');

        return stringService.enumeration(eventName, VSDataScriptEventPrefix);
    }

    /**
     * Static method to filter VSDataScript based on types.
     */
    private static filterFrom(
        dataScriptList: IVSDataScript[],
        eventTypes: Set<VSDataScriptEvent>,
    ): IVSDataScript[] {
        return dataScriptList
            .filter(({ evt: evtType }: IVSDataScript) => eventTypes.has(evtType));
    }

    /**
     * Returns basic configuration of VSDataScript.
     */
    private static getDefaultDSConfig(eventType: VSDataScriptEvent): IVSDataScript {
        return {
            evt: eventType,
            script: '',
        };
    }

    /** @override */
    public beforeEdit(): void {
        this.transformAfterConfigLoad(this.getConfig());

        const typesHash = {} as unknown as TDataScriptTypesHash;
        const config = this.getConfig();
        const { datascript: list } = config;
        const dataScriptList: IVSDataScript[] = [];

        list.forEach(({ evt }, index) => typesHash[evt] = list[index]);

        DataScriptSet.supportedEventTypes.forEach((eventType: VSDataScriptEvent) => {
            const eventConfig = typesHash[eventType];

            if (!eventConfig) {
                dataScriptList.push(DataScriptSet.getDefaultDSConfig(eventType));
            } else {
                dataScriptList.push(eventConfig);
            }
        });

        this.getConfig().datascript = dataScriptList;

        // UI Only fields, added for DataScriptSet create/edit modal.
        this.getConfig().l4Scripts = DataScriptSet.filterFrom(dataScriptList, l4EventTypes);
        this.getConfig().dnsScripts = DataScriptSet.filterFrom(dataScriptList, dnsEventTypes);
        this.getConfig().sslScripts = DataScriptSet.filterFrom(dataScriptList, sslEventTypes);
        this.getConfig().httpScripts = DataScriptSet.filterFrom(dataScriptList, httpEventTypes);
    }

    /** @override */
    public transformAfterLoad(): void {
        this.transformAfterConfigLoad(this.getConfig());
    }

    /** @override */
    public dataToSave(): IVSDataScriptSet {
        const config = super.dataToSave();

        const {
            l4Scripts,
            sslScripts,
            dnsScripts,
            httpScripts,
        } = config;

        const datascriptList = [
            ...l4Scripts,
            ...sslScripts,
            ...dnsScripts,
            ...httpScripts,
        ];

        config.datascript = datascriptList
            .filter((datascript: IVSDataScript) => {
                const { script } = datascript;

                if (script) {
                    datascript.script = script
                        .replace(/[\u2018\u2019]/g, '\'')
                        .replace(/[\u201C\u201D]/g, '"');

                    return true;
                }

                return false;
            });

        // Deleting UI Only fields.
        delete config.l4Scripts;
        delete config.sslScripts;
        delete config.dnsScripts;
        delete config.httpScripts;

        return config;
    }

    /**
     * Checks whether current config can be saved.
     */
    public hasValidConfig(): boolean {
        const { datascript: datascriptList } = this.getConfig();

        return datascriptList.some(({ script }) => !!script);
    }

    /**
     * Returns a list of event types used by this set as list of enums.
     */
    public getListOfEventTypes(): VSDataScriptEvent[] {
        const { datascript: list } = this.getConfig();
        const types = pluck(list, 'evt');

        return unique(types);
    }

    /**
     * Getter for rate_limiters.
     */
    public get rateLimiters(): RepeatedMessageItem<RateLimiterConfigItem> {
        return this.config.rate_limiters;
    }

    /**
     * Reset the data script for removed event.
     */
    public resetDataScript(dataScriptEvent: VSDataScriptEvent): void {
        const { config } = this;
        const {
            l4Scripts,
            dnsScripts,
            sslScripts,
            httpScripts,
        } = config;

        const dataScripts = [
            ...l4Scripts,
            ...dnsScripts,
            ...sslScripts,
            ...httpScripts,
        ];

        dataScripts.forEach(dataScript => {
            if (dataScript.evt === dataScriptEvent) {
                dataScript.script = undefined;
            }
        });
    }
    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return 'DataScript';
    }

    /**
     * Modifies Item's configuration to be consistent.
     */
    private transformAfterConfigLoad(config: IExtendedVSDataScriptSet): IExtendedVSDataScriptSet {
        if (!('datascript' in config)) {
            config.datascript = [];
        }

        return config;
    }
}

DataScriptSet.ajsDependencies = [
    'stringService',
];
