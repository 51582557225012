/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const prefix = `${moduleName}`;

export const widgetLoadingMessage = `${prefix}.widgetLoadingMessage`;

export const ENGLISH = {
    [widgetLoadingMessage]: 'Loading Widget',
};
