/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import { HealthMonitorPop3 } from 'object-types';

import {
    IHealthMonitorPop3,
    IHealthMonitorSSLAttributes,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Health Monitor Pop3 Partial type.
 */
type THealthMonitorPop3Partial = Omit<IHealthMonitorPop3, 'ssl_attributes'>;

/**
 * Interface for Health Monitor Pop3 Config.
 */
export interface IHealthMonitorPop3Config extends THealthMonitorPop3Partial {
    ssl_attributes?: MessageItem<IHealthMonitorSSLAttributes>
}

/**
 * @description Health Monitor Pop3 Config Item.
 * @author Vinay Jadhav
 */
export class HealthMonitorPop3ConfigItem extends MessageItem<IHealthMonitorPop3Config> {
    public folder: string;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorPop3,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ssl_attributes'];
    }
}
