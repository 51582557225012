/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    AutoScalePolicyCollection,
} from 'ajs/modules/auto-scale/factories/auto-scale-policy.collection.factory';

import {
    AutoScalePolicy,
} from 'ajs/modules/auto-scale/factories/auto-scale-policy.item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './server-auto-scale-policy-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TAutoScalePolicyCollection = typeof AutoScalePolicyCollection;

/**
 * @description server-auto-scale-policy list page.
 * @author Nitesh Kesakar
 */
@Component({
    selector: 'server-auto-scale-policy-list-page',
    templateUrl: './server-auto-scale-policy-list-page.component.html',
})
export class ServerAutoScalePolicyListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display serverAutoScalePolicy grid.
     */
    public serverAutoScalePolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * serverAutoScalePolicy Collection instance.
     */
    public readonly serverAutoScalePolicyCollection: AutoScalePolicyCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(AutoScalePolicyCollection)
        AutoScalePolicyCollection: TAutoScalePolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.serverAutoScalePolicyCollection = new AutoScalePolicyCollection();
    }

    /**
     * @override
     * Set grid config options.
     */
    public ngOnInit(): void {
        const { objectName } = this.serverAutoScalePolicyCollection;

        this.serverAutoScalePolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.serverAutoScalePolicyCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: AutoScalePolicy) => row.getName(),
            }, {
                id: 'min-size',
                label: this.l10nService.getMessage(l10nKeys.columnTitleMinSize),
                transform: (row: AutoScalePolicy) => row.config.min_size,
            }, {
                id: 'max-size',
                label: this.l10nService.getMessage(l10nKeys.columnTitleMaxSize),
                transform: (row: AutoScalePolicy) => row.config.max_size,
            }, {
                id: 'intelligent-auto-scale',
                label: this.l10nService.getMessage(l10nKeys.columnTitleIntelligentAutoScale),
                transform: (row: AutoScalePolicy) => {
                    return row.config.intelligent_autoscale ?
                        this.l10nService.getMessage(globalL10nKeys.enabledLabel) :
                        this.l10nService.getMessage(globalL10nKeys.deactivatedLabel);
                },
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.serverAutoScalePolicyCollection.destroy();
    }
}
