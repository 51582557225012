/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    IAwsCredentialsConfig,
    IAwsRegion,
} from 'ajs/modules/ipam/factories/ipam-dns-aws-profile.config-item.factory';

import {
    IpamDnsAwsProfile,
    ProxyConfiguration,
} from 'object-types';

import { Observable } from 'rxjs';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { Store } from '@ngrx/store';
import { ControllerInitialDataSelectors } from 'ng/root-store/controller-initial-data-store';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import * as l10n from './ipam-dns-aws-credentials-dialog.l10n';
import './ipam-dns-aws-credentials-dialog.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for editing AWS IPAM/DNS credentials.
 *
 * @author Aravindh Nagarajan, Alex Klyuev
 */
@Component({
    selector: 'ipam-dns-aws-credentials-dialog',
    templateUrl: './ipam-dns-aws-credentials-dialog.component.html',
})
export class IpamDnsAwsCredentialsDialogComponent implements OnInit {
    /**
     * True if the credentials are being edited.
     */
    @Input()
    public isEditing: boolean;

    /**
     * AWS Credentials Config.
     */
    @Input()
    public config: IAwsCredentialsConfig;

    /**
     * List of AWS Regions.
     */
    @Input()
    public regions: IAwsRegion[];

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * EventEmitter passed in by the parent. Called when cancelling this dialog.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component.
     * Used to close this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Errors to be displayed if an error occurs after a connect attempt.
     */
    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Connect button.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    /**
     * Region dropdown options.
     */
    public regionDropdownOptions: IAviDropdownOption[];

    /**
     * Object-types used in template.
     */
    public readonly objectTypes = {
        [IpamDnsAwsProfile]: IpamDnsAwsProfile,
        [ProxyConfiguration]: ProxyConfiguration,
    };

    /**
     * AWS Cloud data returned by initial-data API.
     */
    public readonly isAwsSetup$ = this.store.select(
        ControllerInitialDataSelectors.selectIsAwsCloud,
    );

    constructor(
        l10nService: L10nService,
        private readonly store: Store,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.regionDropdownOptions = this.regions
            .map(({ name, description }) => createDropdownOption(name, description));
    }

    /**
     * Reset username & password on useIamRoles change.
     */
    public onUseIamRolesChange(): void {
        const { config } = this;

        config.username = undefined;
        config.password = undefined;
    }

    /**
     * Clear proxy configuration if useProxy is set to false.
     */
    public onUseProxyChange(): void {
        if (!this.config.useProxy) {
            this.config.proxy = {};
        }
    }

    /**
     * Handles the cancel operation. Emits the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Called when the Connect button is clicked. Subscribes to the submit$ observable, and closes
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }
}
