/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    IAviDiffGridConfig,
} from 'ng/modules/data-grid/components/avi-diff-grid/avi-diff-grid.types';

import * as l10n from './vs-log-cinematic-sub-section-icap.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-icap.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the ICAP sub-section in the Security section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-icap',
    templateUrl: './vs-log-cinematic-sub-section-icap.component.html',
})
export class VsLogCinematicSubSectionIcapComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the Requestion Action field.
     */
    @ViewChild('requestActionTemplateRef')
    public readonly requestActionTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Reason field.
     */
    @ViewChild('reasonTemplateRef')
    public readonly reasonTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the Violations field.
     */
    @ViewChild('violationsTemplateRef')
    public readonly violationsTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the expanded content of each ICAP request log.
     */
    @ViewChild('expandedContentTemplateRef')
    public readonly expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly icapLog$ = this.vsLogCinematicStore.icapLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public icapRequestLogsGridConfig: IAviDataGridConfig;

    /**
     * Config for the diff grid showing ICAP "sent to server" and "received from server" headers.
     */
    public headersDiffGridConfig: IAviDiffGridConfig;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('IcapAction'),
        ...this.schemaService.getEnumValueLabelsHash('IcapViolationResolution'),
        ...this.schemaService.getEnumValueLabelsHash('HTTPMethod'),
        ...this.schemaService.getEnumValueLabelsHash('ICAPMethod'),
    };

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.icapRequestLogsGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };

        this.headersDiffGridConfig = {
            leftField: {
                label: this.l10nService.getMessage(globalL10nKeys.sentToServerLabel),
            },
            rightField: {
                label: this.l10nService.getMessage(globalL10nKeys.receivedFromServerLabel),
            },
            layout: {
                placeholderMessage:
                    this.l10nService.getMessage(globalL10nKeys.noHttpHeadersToShowPlaceholder),
            },
            isGridExpandable: true,
            noDiff: true,
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.icapRequestLogsGridConfig = {
            ...this.icapRequestLogsGridConfig,
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.requestActionLabel),
                    id: 'request-action',
                    templateRef: this.requestActionTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.reasonLabel),
                    id: 'reason',
                    templateRef: this.reasonTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.violationsLabel),
                    id: 'violations',
                    templateRef: this.violationsTemplateRef,
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
