<clr-vertical-nav
    [clrVerticalNavCollapsible]="true"
    class="vertical-nav sel-vertical-nav"
>
    <div
        *ngFor="let state of states; trackBy: trackByState"
        class="sel-nav-group-content"
    >
        <ng-container *ngIf="state | isNavGroup">
            <ng-container *ngIf="!(state | isHiddenState)">
                <clr-vertical-nav-group
                    class="sel-secondary-nav-{{ state.title | vtranslate }}"
                    *permissionCheck="state.data?.permission"
                >
                    <clr-icon
                        class="sel-clr-icon"
                        shape="{{ state.icon }}"
                        clrVerticalNavIcon
                        *ngIf="state.icon"
                    ></clr-icon>
                    {{ state.title | vtranslate }}
                    <clr-vertical-nav-group-children
                        class="sel-{{ state.title | vtranslate }}-list-subhdr"
                        *clrIfExpanded="state | isActiveState : activeStateName"
                    >
                        <ng-container *ngFor="let childState of state.children; trackBy: trackByState">
                            <ng-container *ngIf="!(childState | isHiddenState)">
                                <button
                                    class="sel-link-text vertical-nav__nav-button"
                                    [class.active]="childState | isActiveState: activeStateName"
                                    *permissionCheck="childState.data?.permission"
                                    clrVerticalNavLink
                                    (click)="redirectTo(childState.fullName)"
                                >
                                    {{ childState.title | vtranslate }}
                                </button>
                            </ng-container>
                        </ng-container>
                    </clr-vertical-nav-group-children>
                </clr-vertical-nav-group>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="state | isNavLink">
            <div *ngIf="!(state | isHiddenState)">
                <button
                    class="sel-link-text vertical-nav__nav-button"
                    [class.active]="state | isActiveState: activeStateName"
                    clrVerticalNavLink
                    *permissionCheck="state.data?.permission"
                    (click)="redirectTo(state.fullName)"
                >
                    <clr-icon
                        class="sel-clr-icon"
                        shape="{{ state.icon }}"
                        clrVerticalNavIcon
                        *ngIf="state.icon"
                    ></clr-icon>
                    {{ state.title | vtranslate }}
                </button>
            </div>
        </ng-container>
    </div>
</clr-vertical-nav>
