/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isNull } from 'underscore';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Item } from './item.factory';

interface IMacroStackData {
    model_name: string;
    data: any;
    method: string;
}

interface INode {
    item: Item;
    children: Item[];
    parent: Item | null;
}

type ref = string;

const MACROSTACK_API = '/api/macrostack';

/**
 * Returns a default empty node.
 */
const createNode = (item: Item): INode => ({
    children: [],
    item,
    parent: null,
});

/**
 * Returns the property on the child that the parent ref should be set on.
 */
const getNodeRefField = (item: Item): ref => `${item.getItemType()}_ref`;

/**
 * Returns the ref URL used in the macro request. If ItemA references ItemB, ItemB's uuid does
 * not yet exist since it still needs to be created. This ref acts as a placeholder that the
 * backend will interpret and convert into a real system ref.
 */
const getNodeRef = (item: Item): string => {
    const itemType = item.getItemType();
    const name = item.getName();

    return `/api/${itemType}?name=${name}`;
};

/**
 * Creates the payload for an individual node.
 */
const createNodePayload = (item: Item, parent?: Item, toDelete = false): IMacroStackData => {
    const modelName = item.getItemType();
    let data = {} as any;
    let method = item.id ? 'PUT' : 'POST';

    if (toDelete) {
        if (method === 'POST') {
            throw new Error('Trying to system-delete a new Item.');
        }

        method = 'DELETE';

        data.uuid = item.id;

        // might need to add cloud_ref for proper scoping of objects "under" cloud
    } else {
        data = item.dataToSave();
    }

    if (parent) {
        const parentRef = getNodeRef(parent);
        const parentRefField = getNodeRefField(parent);

        data[parentRefField] = parentRef;
    }

    return {
        data,
        method,
        model_name: modelName,
    };
};

/**
 * Used for creating objects using the macrostack API, where multiple system-level objects that
 * reference each other need to be created with a single request.
 */
export class MacroStackFactory extends AjsDependency {
    /**
     * Busy flag. Set to true when making an $http request.
     */
    public busy = false;

    /**
     * Errors from $http request.
     */
    public errors: string;

    /**
     * Map of all nodes in the tree.
     */
    private objectsMap: Map<Item, INode> = new Map();

    /**
     * List of objects to be deleted with the macrostack API. Items in this list do not exist in the
     * objectsMap since they should not be iterated over.
     */
    private objectsToDelete: Item[] = [];

    /**
     * Adds an object to this.objectsMap. If a parent is specified, adds the object as a child to
     * the parent. If the object already exists in the Map, then it gets updated.
     */
    public add(item: Item, parent?: Item): void {
        if (parent) {
            this.addChild(item, parent);
        } else {
            this.objectsMap.set(item, createNode(item));
        }
    }

    /**
     * Removes an object and its children from this.objectMap recursively.
     */
    public remove(item: Item): void {
        if (!this.objectsMap.has(item)) {
            throw new Error('Item does not exist.');
        }

        const { children, parent } = this.objectsMap.get(item);

        if (parent) {
            this.removeChild(item, parent);
        }

        children.forEach(child => this.remove(child));
        this.objectsMap.delete(item);
    }

    /**
     * Removes an object, but also makes a request to delete the object from the system. Only
     * deletes the specified item, but removes all children from the objectsMap.
     */
    public delete(item: Item): void {
        if (this.objectsMap.has(item)) {
            this.remove(item);
        }

        /**
         * If the item is an existing object, add it to the objectsToDelete list to be removed.
         */
        if (item.id) {
            if (this.objectsToDelete.includes(item)) {
                throw new Error('Item is already being deleted.');
            }

            this.objectsToDelete = this.objectsToDelete.concat(item);
        }
    }

    /**
     * Makes a POST request to the macrostack API.
     */
    public async submit(): Promise<void> {
        const $http = this.getAjsDependency_('$http');
        const payloadData = [
            ...this.createPayloadData(),
            ...this.createDeletionPayloadData(),
        ];

        this.busy = true;
        this.errors = null;

        try {
            return await $http.post(MACROSTACK_API, { data: payloadData });
        } catch (errors) {
            this.errors = errors.data;

            return Promise.reject(errors);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Adds an object to an existing node in this.objectsMap.
     */
    private addChild(item: Item, parent: Item): void {
        const { objectsMap } = this;

        if (!objectsMap.has(parent)) {
            throw new Error('Parent does not exist.');
        }

        const childNode = {
            ...createNode(item),
            parent,
        };

        objectsMap.set(item, childNode);

        const parentNode = objectsMap.get(parent);
        const { children } = parentNode;

        // Parent already has the item as a child.
        if (children.includes(item)) {
            return;
        }

        const updatedParentNode = {
            ...parentNode,
            children: [
                ...children,
                item,
            ],
        };

        objectsMap.set(parent, updatedParentNode);
    }

    /**
     * Removes the item from its parent's children list.
     */
    private removeChild(item: Item, parent: Item): void {
        const parentNode = this.objectsMap.get(parent);
        const { children } = parentNode;
        const indexToRemove = children.indexOf(item);
        const updatedParentNode = {
            ...parentNode,
            children: [
                ...children.slice(0, indexToRemove),
                ...children.slice(indexToRemove + 1, children.length),
            ],
        };

        this.objectsMap.set(parent, updatedParentNode);
    }

    /**
     * Returns a list of root Items, as in Items without parents.
     */
    private getRootItems(): Item[] {
        const rootItems: Item[] = [];

        this.objectsMap.forEach(node => {
            if (isNull(node.parent)) {
                rootItems.push(node.item);
            }
        });

        return rootItems;
    }

    /**
     * Creates a payload from a list of Items. Recursively goes through each node's children and
     * adds them as well. Adds all items to be deleted at the end of the list.
     * Ordering is important here, as the macrostack API will create objects in order of the stack,
     * so if ItemA references ItemB, ItemB needs to be created first.
     */
    private createPayloadData(items: Item[] = this.getRootItems()): IMacroStackData[] {
        const payload: IMacroStackData[] = [];

        items.forEach((item: Item) => {
            const { children, parent } = this.objectsMap.get(item);

            payload.push(createNodePayload(item, parent));

            if (children.length) {
                payload.push(...this.createPayloadData(children));
            }
        });

        return payload;
    }

    /**
     * Creates a payload from the list of Items to delete.
     */
    private createDeletionPayloadData(): IMacroStackData[] {
        return this.objectsToDelete.reduce((payload, item) => {
            return payload.concat(createNodePayload(item, undefined, true));
        }, []);
    }
}

MacroStackFactory.ajsDependencies = [
    '$http',
];
