/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import {
    MyAccountModalComponent,
    MyAccountPreviewComponent,
    RoleAccessExpanderComponent,
    RoleFilterGridComponent,
    RoleFilterModalComponent,
    RoleListPageComponent,
    RoleModalComponent,
    RolePermissionSetterComponent,
    TenantModalComponent,
    TenantSettingsPageListComponent,
    UserAccountPasswordStrengthDirective,
    UserPageListComponent,
    UserProfileListComponent,
    UserProfileModalComponent,
} from '.';

import {
    roleCollectionProvider,
    roleServiceProvider,
    systemConfigServiceProvider,
    tenantCollectionProvider,
    userCollectionProvider,
    userProfileCollectionProvider,
} from './ajs-upgraded-providers';

const accountComponents = [
    MyAccountModalComponent,
    MyAccountPreviewComponent,
    RoleAccessExpanderComponent,
    RoleFilterGridComponent,
    RoleFilterModalComponent,
    RoleListPageComponent,
    RoleModalComponent,
    RolePermissionSetterComponent,
    TenantModalComponent,
    UserProfileListComponent,
    UserProfileModalComponent,
    UserPageListComponent,
    TenantSettingsPageListComponent,
];

const accountDirectives = [
    UserAccountPasswordStrengthDirective,
];

/**
 * @description
 *      Angular Module for Accounts related components.
 * @author Suraj Kumar, Kondiparthi Shanmukha Sarath
 */
@NgModule({
    declarations: [
        ...accountComponents,
        ...accountDirectives,
    ],
    imports: [
        CommonModule,
        AviFormsModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        DataGridModule,
        FormsModule,
        SharedModule,
    ],
    providers: [
        systemConfigServiceProvider,
        userCollectionProvider,
        userProfileCollectionProvider,
        roleServiceProvider,
        roleCollectionProvider,
        tenantCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AccountsModule {}
