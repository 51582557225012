/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrLoadingState } from '@cds/core/button';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { WafPolicyItem } from 'ajs/modules/waf/factories/waf-policy/waf-policy.item.factory';
import * as l10n from './waf-policy-clone-dialog.l10n';
import './waf-policy-clone-dialog.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author gprasadhk
 * @desc
 *     Dialog component allowing the user to clone WAF policy.
 */
@Component({
    selector: 'waf-policy-clone-dialog',
    templateUrl: './waf-policy-clone-dialog.component.html',
})
export class WafPolicyCloneDialogComponent implements OnInit {
    @Input()
    public wafPolicy: WafPolicyItem;

    /**
     * Called on dialog close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Called on dialog submit.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Loading state of the submit button. Set to 'loading' when submitting the request.
     */
    public loadingState: ClrLoadingState = ClrLoadingState.default;

    /**
     * Error state of the component. Set to a backend error when the request to generate token
     * fails.
     */
    public error: string | null = null;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    /**
     * Name of the WAF policy clone.
     */
    public wafPolicyCloneName: string;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Create a new cloned WAF Policy item and saves it.
     */
    public async cloneWafPolicy(): Promise<void> {
        try {
            this.loadingState = ClrLoadingState.loading;
            this.error = null;
            await this.wafPolicy.load();

            const clonedWaf = this.wafPolicy.clone(this.wafPolicyCloneName);

            await clonedWaf.submit();
            this.onSubmit.emit();
        } catch ({ data }) {
            this.error = data?.error;
        } finally {
            this.loadingState = ClrLoadingState.default;
        }
    }

    /** @override */
    public ngOnInit(): void {
        this.wafPolicyCloneName = `${this.wafPolicy.getName()} (copy)`;
    }

    /**
     * Called to close the dialog.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
