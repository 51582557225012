<cds-tag
    status="info"
    class="waf-user-overrides-badge"
    readonly
>
    <cds-icon
        shape="slider"
        size="16"
        status="info"
        readonly
    ></cds-icon>
    {{ l10nKeys.userOverridesLabel | vtranslate }}
</cds-tag>
