/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { AuthMappingProfileCollection }
    from 'ajs/modules/auth-settings/factories/auth-mapping-profile.collection.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './auth-mapping-profile-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TAuthMappingProfileCollection = typeof AuthMappingProfileCollection;

/**
 * @description Auth Mapping Profile's list page.
 *
 * @author Shahab Hashmi
 */
@Component({
    selector: 'auth-mapping-profile-page',
    templateUrl: './auth-mapping-profile-page.component.html',
})
export class AuthMappingProfilePageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * * Configuration object to display authmappingprofile collection.
     */
    public authMappingProfilePageGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the auth profile collection.
     */
    private readonly authMappingProfileCollection: AuthMappingProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        @Inject(AuthMappingProfileCollection)
        AuthMappingProfileCollection: TAuthMappingProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.authMappingProfileCollection = new AuthMappingProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.authMappingProfileCollection;

        this.authMappingProfilePageGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.authMappingProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }, {
                id: 'type',
                label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                transform: row => this.schemaService.getEnumValueLabel(
                    'AuthProfileType',
                    row.getConfig().type,
                ),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.authMappingProfileCollection.destroy();
    }
}
