/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IIPReputationTypeMatch,
    MatchOperation,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Token for IPReputationTypeMatchConfigItem class.
 */
export const IP_REPUTATION_TYPE_MATCH_CONFIG_ITEM_TOKEN = 'IPReputationTypeMatchConfigItem';

/**
 * @description  IPReputationTypeMatch ConfigItem class.
 * @author Sarthak Kapoor
 */
export class IPReputationTypeMatchConfigItem extends MessageItem<IIPReputationTypeMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IPReputationTypeMatch',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IIPReputationTypeMatch> {
        return {
            match_operation: MatchOperation.IS_IN,
        };
    }
}
