/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import './full-modal-config-errors.component.less';

/**
 * @description Component to display an error message in the config modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-errors',
    templateUrl: './full-modal-config-errors.component.html',
})
export class FullModalConfigErrorsComponent implements OnChanges {
    /**
     * Error message to be displayed.
     */
    @Input()
    public errorMessage = '';

    /**
     * Reference to the alert group component, used to retrieve and set the height of the parent
     * div.
     */
    @ViewChild('alertGroup', { read: ElementRef })
    private readonly alertGroup: ElementRef;

    public constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    /**
     * @override
     * When the error message changes, use a setTimeout to get the alert group height and set it as
     * the parent container height. This will render a solid background with the same size as the
     * alert, so that the alert doesn't overlap with config fields.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const { errorMessage } = changes;

        if (errorMessage && !errorMessage.isFirstChange()) {
            setTimeout(() => {
                const alertGroupHeight = this.alertGroup.nativeElement.offsetHeight;

                this.renderer.setStyle(
                    this.elementRef.nativeElement,
                    'height',
                    `${alertGroupHeight}px`,
                );
            });
        }
    }
}
