/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Base } from 'ajs/modules/data-model/factories/base.factory';
import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';
import { CLOUD_ITEM_TOKEN } from 'ajs/modules/cloud/cloud.tokens';
import { StringService } from 'string-service';
import { CloudType } from 'generated-types';
import { VRFContextCollection } from
    'ajs/modules/vrf-context/factories/vrf-context.collection.factory';
import { VRF_CONTEXT_COLLECTION_TOKEN } from '../vrf-context.tokens';

interface ICloudState {
    cloud: Cloud,
    vrfContextCollection: VRFContextCollection,
}

export const vrfContextCollectionChangeEvent = 'vrfContextCollectionChange';
export const cloudChangeEvent = 'cloudChange';

export class InfraCloudState extends Base {
    private static readonly showGatewayMonitor = {
        CLOUD_VCENTER: true,
        CLOUD_NONE: true,
        CLOUD_LINUXSERVER: true,
    };

    public busy = false;
    private readonly cloudState: ICloudState;

    constructor() {
        super();

        const VRFContextCollection = this.getAjsDependency_(VRF_CONTEXT_COLLECTION_TOKEN);
        const Cloud = this.getAjsDependency_(CLOUD_ITEM_TOKEN);

        this.cloudState = {
            cloud: new Cloud(),
            vrfContextCollection: new VRFContextCollection({
                overLimitCoeff: 0,
                sortBy: 'name',
            }),
        };
    }

    /**
     * Returns the cloudState object.
     */
    public getCloudState(): ICloudState {
        return this.cloudState;
    }

    /**
     * Returns the stored cloud object.
     */
    public getCloud(): Cloud {
        return this.cloudState.cloud;
    }

    /**
     * Returns the url of the cloud object.
     */
    public getCloudRef(): string {
        const cloud = this.getCloud();

        return cloud && cloud.getRef() || '';
    }

    /**
     * Returns currently selected cloud id.
     */
    public getCloudId(): string {
        const { id } = this.getCloud();

        return id || '';
    }

    /**
     * Returns currently selected cloud type.
     */
    public getCloudType(): CloudType | undefined {
        const cloud = this.getCloud();

        return cloud?.getVtype() as unknown as CloudType;
    }

    /**
     * Returns the VRF Context collection.
     */
    public getVrfContextCollection(): VRFContextCollection {
        return this.cloudState.vrfContextCollection;
    }

    /**
     * Checks if Custom VRF Context exists.
     */
    public hasCustomVRFContext(): boolean {
        return this.cloudState.vrfContextCollection.hasCustomVRFContext();
    }

    /**
     * Sets the VRF Context collection on the cloudState object and triggers the
     * 'vrfContextCollectionChange' event.
     */
    public setVRFContextCollection(vrfContextCollection: VRFContextCollection): void {
        this.cloudState.vrfContextCollection = vrfContextCollection;
        this.trigger(vrfContextCollectionChangeEvent);
    }

    /**
     * Returns true if custom VRF Contexts are allowed. Dependent on the cloud type.
     */
    public allowCustomVrfContext(): boolean {
        const cloud = this.getCloud();

        return cloud.allowCustomVRFContext();
    }

    /**
     * Returns true if the Gateway Monitor tab should be shown. Dependent on the number of
     * VRF Contexts within a cloud and the cloud type.
     */
    public showGatewayMonitorTab(): boolean {
        const { vrfContextCollection, cloud } = this.getCloudState();

        return vrfContextCollection.getNumberOfItems() > 0 ||
            !vrfContextCollection.hasCustomVRFContext() ||
            cloud.getVtype() in InfraCloudState.showGatewayMonitor;
    }

    /**
     * Loads the VRF Context collection given a cloud uuid.
     */
    public loadVrfContextCollection(cloudRef: string): Promise<VRFContextCollection> {
        const VRFContextCollection = this.getAjsDependency_(VRF_CONTEXT_COLLECTION_TOKEN);
        const stringService: StringService = this.getAjsDependency_('stringService');
        const cloudId = stringService.slug(cloudRef);

        const vrfContextCollection = new VRFContextCollection({
            params: {
                'cloud_ref.uuid': cloudId,
            },
            defaults: {
                cloud_ref: cloudRef,
            },
            overLimitCoeff: 0,
            sortBy: 'name',
        });

        this.busy = true;

        return vrfContextCollection.load()
            .then(() => vrfContextCollection)
            .finally(() => this.busy = false);
    }

    /**
     * Sets the cloud on the cloudState object and triggers the 'cloudChange' event.
     */
    public setCloud(cloud: Cloud): void {
        this.cloudState.cloud = cloud;
        this.trigger(cloudChangeEvent);
        this.reloadVRFContextCollection();
    }

    /**
     * Reloads VRF context on cloud change.
     * So that vrfContext will never be outdated.
     */
    private reloadVRFContextCollection(): void {
        const cloudRef = this.getCloudRef();

        this.cloudState.vrfContextCollection.reset();

        this.loadVrfContextCollection(cloudRef)
            .then((response: VRFContextCollection) => {
                this.setVRFContextCollection(response);
            });
    }
}

InfraCloudState.ajsDependencies = [
    CLOUD_ITEM_TOKEN,
    'stringService',
    VRF_CONTEXT_COLLECTION_TOKEN,
];
