/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'my-account-preview';
const prefix = `${moduleName}.${componentName}`;

export const tenantLabel = `${prefix}.tenantLabel`;

export const ENGLISH = {
    [tenantLabel]: 'Tenant',
};
