/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { DNSConfigConfigItem } from 'message-items/dns-config.config-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-subdomains-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for subdomains in gslb site modal.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-subdomains-grid',
    templateUrl: './gslb-subdomains-grid.component.html',
})
export class GslbSubdomainsGridComponent implements AfterViewInit {
    @Input()
    public subdomains: RepeatedMessageItem<DNSConfigConfigItem>;

    /**
     * TemplateRef for subdomain input element.
     */
    @ViewChild('subdomainTemplateRef')
    public subdomainTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Grid config for Subdomain.
     */
    public subdomainsGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.subdomainsGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.subdomainLabel),
                id: 'subdomain',
                templateRef: this.subdomainTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (subdomainList: DNSConfigConfigItem[]) => {
                    subdomainList.forEach(subdomain => this.deleteSubdomains(subdomain));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (subdomain: DNSConfigConfigItem) => {
                    this.deleteSubdomains(subdomain);
                },
            }],
        };
    }

    /**
     * Add Server subdomain.
     */
    public addSubdomain(): void {
        this.subdomains.add();
    }

    /**
     * Delete subdomain.
     */
    private deleteSubdomains(subdomain: DNSConfigConfigItem): void {
        this.subdomains.removeByMessageItem(subdomain);
    }
}
