<div class="configured-network-subnet-expander grid-container grid-container--gap--none">
    <div class="grid-container__cell--w--2 configured-network-subnet-expander__label">
        <strong>{{ l10nKeys.ipSubnetLabel | vtranslate }}</strong>
    </div>
    <div class="grid-container__cell--w--4">
        {{ getFormattedSubnet(subnet) }}
    </div>
    <div class="grid-container__cell--w--2 configured-network-subnet-expander__label">
        <strong>{{ l10nKeys.staticIpPoolsLabel | vtranslate }}</strong>
    </div>
    <div class="grid-container__cell--w--4">
        <div [ngSwitch]="fromNetworkEdit">
            <ng-container *ngSwitchCase="true">
                <div
                    class="configured-network-subnet-expander__static-ip-pool"
                    *ngFor="let staticIpRange of subnet.static_ipaddr_tmp; trackBy: trackbyIndex"
                >
                    {{ staticIpRange.range }} ({{ getSubnetTypeLabel(staticIpRange.type) }})
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="false">
                <div
                    class="configured-network-subnet-expander__static-ip-pool"
                    *ngFor="let staticIpRange of subnet.static_ip_ranges; trackBy: trackbyIndex"
                >
                    {{ getFormattedStaticIpRange(staticIpRange.range) }} ({{ getSubnetTypeLabel(staticIpRange.type) }})
                </div>
                <div *ngIf="!subnet.static_ip_ranges">-</div>
            </ng-container>
        </div>
    </div>
</div>
