/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ALERT_EMAIL_CONFIG_COLLECTION_TOKEN } from 'ajs/modules/alert';
import * as l10n from './EmailController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('EmailController', [
'$scope', 'Collection', 'Regex', 'CRUDGridConfig', 'AviModal', 'l10nService',
ALERT_EMAIL_CONFIG_COLLECTION_TOKEN,

function($scope, Collection, Regex, CRUDGridConfig, AviModal, l10nService,
AlertEmailConfigCollection) {
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new AlertEmailConfigCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;

    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'data.config.to_emails',
        title: l10nService.getMessage(l10nKeys.columnTitleToAddress),
    }, {
        name: 'data.config.cc_emails',
        title: l10nService.getMessage(l10nKeys.columnTitleCCAddress),
    }];

    $scope.gridConfig.singleactions.unshift({
        title: l10nService.getMessage(l10nKeys.columnTitleValidateSettings),
        class: 'icon-lostlog',
        do(row) {
            const { config } = row.data;

            AviModal.open('adm-email-validate', {
                config: {
                    to_emails: config.to_emails,
                    cc_emails: config.cc_emails,
                },
                uuid: config.uuid,
                type: 'email',
            });
        },
    });

    $scope.Regex = Regex;
}]);
