/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name OnePoolServerInventoryCollDataSource
 * @author Alex Malitsky
 * @description
 *
 *     DataSource to get all servers within one pool. Faster than
 *     {@link PoolServerInventoryCollDataSource} API.
 */
angular.module('aviApp').factory('OnePoolServerInventoryCollDataSource', [
'InventoryCollDataSource',
function(InventoryCollDataSource) {
    return class OnePoolServerInventoryCollDataSource extends InventoryCollDataSource {
        constructor(args = {}) {
            if (!('defaultParams' in args)) {
                args.defaultParams = {};
            }

            args.defaultParams.all_se = true;

            super(args);

            this.hasSearch = false;
            this.hasSorting = false;
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_(this.params_);

            params['objectName_'] = ['pool-inventory', this.owner_.poolId, 'server'];

            // Note: Used to add 'horizon_internal_port_info' to the 'include' param. Probably not
            // the best way to do this.
            params.fields_ = params.fields_.concat('horizon_internal_port_info');

            return params;
        }
    };
}]);
