/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    IMetricsEventThreshold,
    ISeGroupAnalyticsPolicy,
} from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { SeGroupAnalyticsPolicy } from 'object-types';

type TSeGroupAnalyticsPolicyPartial = Omit<ISeGroupAnalyticsPolicy, 'metrics_event_thresholds'>;

interface ISeGroupAnalyticsPolicyConfig extends TSeGroupAnalyticsPolicyPartial {
    metrics_event_thresholds?: RepeatedMessageItem<MessageItem<IMetricsEventThreshold>>;
}

/**
 * @description SE Group Analytics Policy Message Item.
 * @author vgohil
 */
export class SeGroupAnalyticsPolicyConfigItem extends MessageItem<ISeGroupAnalyticsPolicyConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: SeGroupAnalyticsPolicy,
            ...args,
        };

        super(extendedArgs);
    }
}
