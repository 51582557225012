<div class="label-color-optional">
    <cds-icon
        #colorIcon
        *ngIf="labelData.hasLeftColor"
        shape="stop"
        class="label-color-optional__color-icon"
        size="12"
    ></cds-icon>
    <div>{{ labelData.label }}</div>
    <ng-content></ng-content>
</div>
