/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ICloud } from 'generated-types';

import { IInfraCloudState } from './infra-cloud.state';

export const infraCloudStateFeatureKey = 'infraCloudState';

export const selectInfraCloudState =
    createFeatureSelector<IInfraCloudState>(infraCloudStateFeatureKey);

const selectCloud = createSelector(
    selectInfraCloudState,
    (infraCloudState: IInfraCloudState) => infraCloudState.cloud,
);

const selectCloudName = createSelector(
    selectCloud,
    (cloud: ICloud) => cloud.name,
);

const selectCloudId = createSelector(
    selectCloud,
    (cloud: ICloud) => cloud.uuid,
);

const selectCloudVtype = createSelector(
    selectCloud,
    (cloud: ICloud) => cloud.vtype,
);

export const InfraCloudSelectors = {
    selectCloud,
    selectCloudName,
    selectCloudId,
    selectCloudVtype,
};
