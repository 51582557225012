/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';
import {
    CloneServerConfigItem,
    TrafficCloneProfileCollection,
    TrafficCloneProfileItem,
} from '.';
import {
    CLONE_SERVER_CONFIG_ITEM_TOKEN,
    TRAFFIC_CLONE_PROFILE_COLLECTION_TOKEN,
    TRAFFIC_CLONE_PROFILE_ITEM_TOKEN,
} from './traffic-clone-profile.tokens';

const trafficCloneProfileModule = angular.module('avi/traffic-clone-profile');

const factories = [
    {
        factory: TrafficCloneProfileCollection,
        name: TRAFFIC_CLONE_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: TrafficCloneProfileItem,
        name: TRAFFIC_CLONE_PROFILE_ITEM_TOKEN,
    },
    {
        factory: CloneServerConfigItem,
        name: CLONE_SERVER_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(trafficCloneProfileModule, 'factory', name, factory);
});
