<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.config.host }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid && !!(editable.config?.rules?.count)"
    class="vh-match-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="host"
                        objectType="{{ objectType }}"
                        fieldName="host"
                        required
                    >
                        {{ globalL10nKeys.hostLabel | vtranslate }}
                    </label>
                    <input
                        id="host"
                        name="host"
                        clrInput
                        placeholder="{{ globalL10nKeys.hostLabel | vtranslate }}"
                        [(ngModel)]="editable.config.host"
                        required
                        [uniqueValue]="hostNames"
                        [currentIndex]="hostIndex"
                    />
                </avi-input-container>
                <div class="vh-match-modal__rules-grid">
                    <vh-match-rule-grid [editable]="editable"></vh-match-rule-grid>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
