/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'cloud-vrf-context-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setCloudVrfContextHeader = `${prefix}.setCloudVrfContextHeader`;
export const setCloudHeader = `${prefix}.setCloudHeader`;
export const vrfContextSelectLabel = `${prefix}.vrfContextSelectLabel`;
export const vrfContextSelectPlaceholder = `${prefix}.vrfContextSelectPlaceholder`;
export const cloudVrfContextMessage = `${prefix}.cloudVrfContextMessage`;
export const cloudMessage = `${prefix}.cloudMessage`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [setCloudVrfContextHeader]: 'Set Cloud & VRF',
    [setCloudHeader]: 'Set Cloud',
    [vrfContextSelectLabel]: 'VRF Context',
    [vrfContextSelectPlaceholder]: 'Select VRF Context',
    [cloudVrfContextMessage]: 'Once set Cloud and VRF Context cannot be changed',
    [cloudMessage]: 'Once set Cloud cannot be changed',
    [submitButtonLabel]: 'Set',
};
