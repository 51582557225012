<match-wrapper
    label="{{ l10nKeys.userRoleLabel | vtranslate }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMapping()"
>
    <div
        provideParentForm
        class="user-role-mapping"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="assign_role"
            for="assign-role"
            required
        >
            {{ globalL10nKeys.typeLabel | vtranslate }}
        </label>
        <avi-enum-dropdown
            name="assign-role"
            enum="AuthRuleAssignment"
            [(ngModel)]="editable.config.assign_role"
            (ngModelChange)="onAssignmentTypeChange()"
            placeholder="{{ l10nKeys.typeDropdownPlaceholder | vtranslate }}"
            required
        ></avi-enum-dropdown>

        <div class="user-role-mapping__roles">
            <div
                class="full-modal-config__form-control-row"
                *ngIf="showRoleList"
            >
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="role_uuids"
                        for="role-uuids"
                    >
                        {{ l10nKeys.rolesLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        [collection]="roleCollection"
                        [(ngModel)]="editable.config.role_refs"
                        placeholder="{{ l10nKeys.rolesPlaceholderLabel | vtranslate }}"
                        name="role-uuids"
                        [multiple]="true"
                    ></avi-collection-dropdown>
                </div>
            </div>

            <avi-input-container *ngIf="showRoleAttributeName">
                <label
                    aviLabelWithTooltip
                    fieldName="role_attribute_name"
                    for="role-attribute-name"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ l10nKeys.roleAttributeNameLabel | vtranslate }}
                </label>
                <input
                    name="role-attribute-name"
                    [(ngModel)]="editable.config.role_attribute_name"
                    clrInput
                    placeholder="{{ l10nKeys.roleAttributeNamePlaceholderLabel | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>
    </div>
</match-wrapper>
