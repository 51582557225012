/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    NgDependencyInjected,
} from '../../angular/modules/data-model/factories/ng-dependency-injected.factory';
import { getAjsDependencyInjector } from './ajsDependencyInjector';

/**
 * @constructor
 * @memberOf module:avi/core
 * @abstract
 * @desc
 *
 *     More of the interface to annotate and retrieve angularJs dependencies
 *     in the runtime wo the need to follow common angularJs DI syntax.
 *
 *     Alternative aJs DI API is quite simple:
 *     - class can have static property "ajsDependencies" with a list of angular js dependency
 *       names it's instance needs access to.
 *     - method "getAjsDependency_" is defined to retrieve those dependencies in the runtime.
 *       Subclass doesn't need to implement the method, it will be added in the runtime upon
 *       {@link module:avi/core.initAjsDependency class registration} with angularJS.
 *
 *     Not to be used directly, only as an argument for 'extends'.
 *
 * @author Alex Malitsky
 * @see module:avi/core.initAjsDependency
 * @see module:avi/core.ajsDependencyInjector
 * @alias AjsDependency
 */
class AjsDependency extends NgDependencyInjected {
    /**
     * Returns angularJs dependency by its name.
     * @param {string} name - dependency name
     * @return {*|null}
     * @protected
     * @abstract
     */
    static getAjsDependency_(name) {
        return null;
    }

    /**
     * Returns angularJs dependency by its name.
     * @param {string} name - dependency name
     * @return {*|null}
     * @protected
     * @abstract
     */
    getAjsDependency_(name) {
        return null;
    }
}

/**
 * Optional list of angularJs dependency names to be registered on the class with
 * {@link module:avi/core.initAjsDependency initAjsDependency} before registering the class
 * on angularJs module.
 * @type {string[]}
 * @abstract
 */
AjsDependency.ajsDependencies = [];

/**
 * @function
 * @memberOf module:avi/core
 * @param {angular.IModule} ajsModule - Reference to angularJs module.
 * @param {'factory'|'service'} depType - Type of angularJs dependency passed.
 * @param {string} depName - Name of dependency to be used for registration.
 * @param {Class|function} Dependency - "Pure" class or constructor function.
 * @desc
 *
 *     Injects AngularJs dependencies into "Dependency" passed via
 *     {@link module:avi/core.ajsDependencyInjector ajsDependencyInjector}
 *     and registers it on the angularJs module passed.
 *
 *     As of now only classes (both ES5 and ES6) are supported.
 *
 * @see module:avi/core.ajsDependencyInjector
 * @see module:avi/core.AjsDependency
 * @author Alex Malitsky, Alex Tseung
 */
function initAjsDependency(
    ajsModule,
    depType,
    depName,
    Dependency,
) {
    // .service gonna call this with "new" and needs an instance, .factory - wo and needs a class
    function dependencyFactory($injector) {
        const InitiatedDependency = getAjsDependencyInjector($injector)(Dependency);

        if (depType === 'service') {
            // no arguments here - angularJs .service recipe doesn't allow for passing em anyway
            return new InitiatedDependency();
        }

        return InitiatedDependency;
    }

    dependencyFactory.$inject = [
        '$injector',
    ];

    const register = ajsModule[depType];

    register(depName, dependencyFactory);
}

export {
    AjsDependency,
    initAjsDependency,
};
