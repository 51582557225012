/** @module AnalyticsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import './timing-tiles.component.less';
import template from './timing-tiles.component.html';

import * as l10n from './timing-tiles.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface ITimingTileConfig {
    highlighted?: boolean;
    icon: string;
    iconTitle?: string;
    id: string;
    title?: string;
    series?: any;
    value: number;
}

interface IWrapperStyle {
    'min-width': string;
    'max-width': string;
}

/**
 * @ngdoc component
 * @name timingTiles
 * @param {boolean} e2eSeriesAvailable - When false, render license upgrade message on top of
 *     regular tiles.
 * @param {Metric} metric
 * @description
 *
 *     List of tiles with metric values or icons to illustrate them.
 * @author Rajawant Prajapati
 */

const componentName = 'timing-tiles';

const iconTileWidth = 3.2; // em, minimal

const valueTileWidth = iconTileWidth * 1.75;

const maxScale = 1.6; // max coefficient (x times) of the icon tile width

class TimingTilesController {
    /**
     * Timing Tiles Header.
     */
    public header: string;

    /**
     * List of tiles with metric values or icons
     */
    public tiles: ITimingTileConfig[];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Metric name to be used to check if metric is end_to_end.
     */
    private metricId: string | undefined;

    /**
     * When false, render license upgrade message on top of regular tiles.
     */
    private readonly e2eSeriesAvailable: boolean;

    constructor(
        private readonly $element: JQuery,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.$element.addClass(componentName);
    }

    /** @override */
    public $onChanges({ metric: metricChange }: ng.IOnChangesObject): void {
        const { currentValue: metric } = metricChange;

        if (metric) {
            const { header, tiles } = metric.getTimingTilesConfig();

            this.header = header;
            this.tiles = tiles;
            this.metricId = metric.name;
        } else {
            this.header = undefined;
            this.tiles = undefined;
            this.metricId = undefined;
        }
    }

    /**
     * Returns wrapper width based on number of tiles and their types. We don't want to be
     * too wide and too narrow. That's what wrapper is for.
     * @returns {IWrapperStyle | null}
     */
    // can't use flex for equal margins cause we need ability to highlight neighbours with
    // same background color
    public get wrapperStyle(): IWrapperStyle | null {
        if (this.tiles) {
            const width = this.tiles.reduce(
                (base, tileConfig) =>
                    base += 'icon' in tileConfig ? iconTileWidth : valueTileWidth,
                0,
            );

            return {
                'min-width': `${width}em`,
                'max-width': `${Math.ceil(width * maxScale)}em`,
            };
        }

        return null;
    }

    /**
     * Retruns true if e2eseries is not available and current metric is 'end_to_end'.
     * Used to render upgrade license message if returns true
     * @returns {boolean}
     */
    public get showUpgradeLicense(): boolean {
        return !(this.e2eSeriesAvailable || this.metricId !== 'end_to_end');
    }
}

TimingTilesController.$inject = [
    '$element',
    'l10nService',
];

export const timingTilesComponentOptions = {
    bindings: {
        metric: '<',
        e2eSeriesAvailable: '<?',
    },
    controller: TimingTilesController,
    template,
};
