/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function serverReselectHttpRespCodeDirectiveFactory(
    regex,
    schemaService,
) {
    const enumHash = buildEnumHash();

    /**
     * Directive Link method
     */
    function serverReselectHttpRespCodeDirectiveLink(scope, elm, attr, ngModel) {
        const min = attr.min ? +attr.min : 101;
        const max = attr.max ? +attr.max : 599;

        ngModel.$parsers.push(parseRespCodes);
        ngModel.$formatters.push(formatRespCodes);

        /**
         * Parses string into an object of response code objects.
         * @param  {string} val - Comma-separated response codes, code ranges, or code blocks.
         * @return {Object} Object containing ranges, codes, and resp_code_block properties.
         */
        function parseRespCodes(val) {
            if (!val) {
                return '';
            }

            // Maintains this Hash to invalidate the field incase of duplication
            const existingValues = {};

            const codes = val.replace(/\s+/g, '').split(',');
            const outputHash = {};

            const check = _.any(codes, function(code) {
                if (code === '') {
                    return;
                }

                const parsedValue = respCodeParser(code, existingValues);

                if (parsedValue === null) {
                    return true;
                } else {
                    populateOutputHash(parsedValue, outputHash);
                }
            });

            return check ? undefined : outputHash;
        }

        /**
         * Populates the output hash.
         * @param  {Object} code - Response code object.
         * @param  {Object} hash - Output hash.
         */
        function populateOutputHash(code, hash) {
            hash[code.type] = hash[code.type] || [];
            hash[code.type].push(code.value);
        }

        /**
         * Parses each comma-separated part of the input string and
         * returns value based on whether it's a single code, code-range, or code block.
         * @param  {string} code - Response code string.
         * @param {Object} existingValues - Hash of already processed status codes
         * @return {Object|null} - Returns object if valid response code string, null otherwise.
         */
        function respCodeParser(code, existingValues) {
            // If matches, code is either a single code or a range.
            if (regex.listOfStatusCodeOrRanges.test(code)) {
                // Range
                if (/[-]/.test(code)) {
                    const
                        range = code.split('-'),
                        begin = +range[0],
                        end = +range[1];

                    if (begin >= min && end <= max &&
                        !(begin in existingValues) && !(end in existingValues)) {
                        existingValues[begin] = true;
                        existingValues[end] = true;

                        return {
                            type: 'ranges',
                            value: {
                                begin: +range[0],
                                end: +range[1],
                            },
                        };
                    } else {
                        return null;
                    }
                // Single code
                } else if (+code >= min && +code <= max && !(+code in existingValues)) {
                    existingValues[+code] = true;

                    return {
                        type: 'codes',
                        value: +code,
                    };
                } else {
                    return null;
                }
            // Code block
            } else if (code.toUpperCase() in enumHash && !(code.toUpperCase() in existingValues)) {
                existingValues[code.toUpperCase()] = true;

                return {
                    type: 'resp_code_block',
                    value: enumHash[code.toUpperCase()],
                };
            } else {
                return null;
            }
        }
    }

    /**
     * Builds hash of HttpReselectRespCodeBlock enum values.
     * @return {Object} - Hash of code block values to enum values.
     */
    function buildEnumHash() {
        return schemaService.getEnumValues('HttpReselectRespCodeBlock')
            .reduce((hash, { value, label }) => {
                hash[label] = value;

                return hash;
            }, {});
    }

    /**
     * Formats response code object into string of comma-separated status codes.
     * @param  {Object} val - Object containing ranges, codes, and resp_code_block properties.
     * @return {string} Comma-separated response codes, code ranges, or code blocks.
     */
    function formatRespCodes(val) {
        if (_.isUndefined(val) || _.size(val) === 0) {
            return '';
        }

        const output = [];

        if (Array.isArray(val.codes)) {
            val.codes.forEach(function(code) {
                output.push(code);
            });
        }

        if (Array.isArray(val.ranges)) {
            val.ranges.forEach(function(range) {
                output.push(`${range.begin}-${range.end}`);
            });
        }

        if (Array.isArray(val.resp_code_block)) {
            val.resp_code_block.forEach(function(block) {
                output.push(block.enumeration('HTTP_RSP_').toUpperCase());
            });
        }

        return output.join(', ');
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: serverReselectHttpRespCodeDirectiveLink,
    };
}

serverReselectHttpRespCodeDirectiveFactory.$inject = [
    'Regex',
    'schemaService',
];

/**
 * @name serverReselectHttpRespCodeDirective
 * @memberOf module:avi/pool
 * @description
 *
 *  Attribute type directive.
 *
 *  To be used in,
 *      Pool create/edit -> Advanced -> Other settings -> Server Reselect -> HTTP Response codes
 *
 *  Parses and formats comma-separated string of
 *      - Individual Response Codes (400, 401,..)
 *      - Response Code Ranges (400-410, ...)
 *      - Response Code blocks (4XX, 5XX).
 * @author  Alex Tseung, Aravindh Nagarajan
 */
angular.module('avi/pool')
    .directive('serverReselectHttpRespCode', serverReselectHttpRespCodeDirectiveFactory);
