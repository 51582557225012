/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HeaderModule
 */

import { Injector } from '@angular/core';

import {
    ActiveUserProfileService,
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/active-user-profile';

import {
    TUserItem,
    UserItem,
} from 'ajs/modules/accounts/factories/user/user.item.factory';

import { USER_ITEM_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

const $injector = '$injector';

export const activeUserProfileServiceProvider = {
    deps: [$injector],
    provide: ActiveUserProfileService,
    useFactory(injector: Injector): ActiveUserProfileService {
        return injector.get(ACTIVE_USER_PROFILE_SERVICE_TOKEN);
    },
};

export const userItemProvider = {
    deps: [$injector],
    provide: UserItem,
    useFactory(injector: Injector): TUserItem {
        return injector.get(USER_ITEM_TOKEN);
    },
};
