<div class="vs-log-cinematic-section-summary">
    <!-- L7 log -->
    <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L7">
        <!-- Client -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.clientLabel | vtranslate }}
            </h5>
            <ng-container *ngrxLet="l7ClientInfo$ as clientInfo">
                <!-- User ID -->
                <div vsLogCinematicFieldTitle>{{ l10nKeys.userIdLabel | vtranslate }}</div>
                <div
                    *ngIf="clientInfo.user_id as userId; else na"
                    vsLogFilter
                    property="user_id"
                    [value]="userId"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ userId }}
                </div>

                <!-- Location -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.locationLabel | vtranslate }}</span>
                <div
                    vsLogNationalFlag
                    [countryCode]="clientInfo.client_location"
                ></div>
                <span
                    *ngIf="clientInfo.client_location as location; else na"
                    vsLogFilter
                    [property]="'client_location'"
                    [value]="location"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ location | countryCodeToName }}
                </span>

                <!-- OS -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.operatingSystemLabel | vtranslate }}</span>
                <i
                    *ngIf="(clientInfo.client_os | logClientEnvToIconClass:'os') !== 'unknown'; else clrUnknownIcon"
                    class="icon-{{ clientInfo.client_os | logClientEnvToIconClass:'os' }}"
                ></i>
                <span
                    *ngIf="clientInfo.client_os as os; else na"
                    vsLogFilter
                    [property]="'client_os'"
                    [value]="os"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ os }}
                </span>

                <!-- Device -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.deviceLabel | vtranslate }}</span>
                <cds-icon
                    *ngIf="(clientInfo.client_device | logClientEnvToIconClass:'device') === 'desktop'"
                    class="vs-log-cinematic-section-summary__clr-icon-with-margin-right"
                    shape="display"
                ></cds-icon>
                <cds-icon
                    *ngIf="(clientInfo.client_device | logClientEnvToIconClass:'device') === 'tablet'"
                    class="vs-log-cinematic-section-summary__clr-icon-with-margin-right"
                    shape="tablet"
                    size="14"
                ></cds-icon>
                <span
                    *ngIf="clientInfo.client_device as device; else na"
                    vsLogFilter
                    [property]="'client_device'"
                    [value]="device"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ device }}
                </span>

                <!-- Perfect Forward Secrecy -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.perfectForwardSecrecyLabel | vtranslate }}</span>
                <span
                    *ngIf="clientInfo.pfs as pfs; else na"
                    vsLogFilter
                    property="ssl_cipher"
                    [operator]="FilterOperatorType.CONTAINS"
                    [value]="pfs"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ pfs }}
                </span>

                <!-- Browser -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.browserLabel | vtranslate }}</span>
                <i
                    *ngIf="(clientInfo.client_browser | logClientEnvToIconClass:'device') !== 'globe'; else clrWorldIcon"
                    class="icon-{{ clientInfo.client_browser | logClientEnvToIconClass:'browser' }}"
                ></i>
                <span
                    *ngIf="clientInfo.client_browser as browser; else na"
                    vsLogFilter
                    [property]="'client_browser'"
                    [value]="browser"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ browser }}
                </span>

                <!-- Start Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.startTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.startTime; else na">{{ clientInfo.startTime | aviDate:timeFormat }}</span>

                <!-- End Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.endTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.endTime; else na">{{ clientInfo.endTime | aviDate:timeFormat }}</span>
            </ng-container>
        </div>

        <!-- Avi + Rules and Policies -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ l10nKeys.nsxAndAlbLabel | vtranslate }}
            </h5>

            <ng-container *ngrxLet="nsxAlbInfo$ as nsxAlbInfo">
                <span vsLogCinematicFieldTitle>{{ l10nKeys.requestIdLabel | vtranslate }}</span>
                <span
                    *ngIf="nsxAlbInfo.request_id; else na"
                    vsLogFilter
                    property="request_id"
                    [value]="nsxAlbInfo.request_id"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ nsxAlbInfo.request_id }}
                </span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.redirectUriLabel | vtranslate }}</span>
                <span
                    *ngIf="nsxAlbInfo.redirected_uri as redirectedUri; else na"
                    vsLogFilter
                    property="redirected_uri"
                    [value]="redirectedUri"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ redirectedUri }}
                </span>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.responseLengthLabel | vtranslate }}</span>
                <span
                    *ngIf="nsxAlbInfo.response_length !== undefined; else na"
                    vsLogFilterList
                    property="server_response_length"
                    [value]="nsxAlbInfo.response_length"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ nsxAlbInfo.response_length }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}
                </span>
            </ng-container>

            <h5 class="vs-log-cinematic-section__column-title">
                {{ l10nKeys.rulesAndPoliciesLabel | vtranslate }}
            </h5>
            <ng-container *ngrxLet="policyRules$ as policyRules">
                <span vsLogCinematicFieldTitle>{{ l10nKeys.persistenceIdLabel | vtranslate }}</span>
                <span
                    *ngIf="policyRules.persistent_session_id !== undefined; else na"
                    vsLogFilter
                    property="persistent_session_id"
                    [value]="policyRules.persistent_session_id"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ policyRules.persistent_session_id }}
                </span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.virtualHostRuleNameLabel | vtranslate }}</span>
                <span
                    *ngIf="policyRules.vh_match_rule as rule; else na"
                    vsLogPartialSelectionFilter
                    property="vh_match_rule"
                    [value]="rule"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.networkSecurityPolicyRuleLabel | vtranslate }}</span>
                <span
                    *ngIf="policyRules.networkSecurityPolicyRule as rule; else na"
                    vsLogPartialSelectionFilter
                    property="network_security_policy_rule_name"
                    [value]="rule"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.httpSecurityPolicyRuleLabel | vtranslate }}</span>
                <span
                    *ngIf="policyRules.httpSecurityPolicyRule as rule; else na"
                    vsLogPartialSelectionFilter
                    property="http_security_policy_rule_name"
                    [value]="rule"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.httpRequestPolicyRuleLabel | vtranslate }}</span>
                <span
                    *ngIf="policyRules.httpRequestPolicyRule as rule; else na"
                    vsLogPartialSelectionFilter
                    property="http_request_policy_rule_name"
                    [value]="rule"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.httpResponsePolicyRuleLabel | vtranslate }}</span>
                <ng-container >
                    <span
                        *ngIf="policyRules.httpResponsePolicyRule as rule; else na"
                        vsLogPartialSelectionFilter
                        property="http_response_policy_rule_name"
                        [value]="rule"
                        position="right"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    ></span>
                </ng-container>
            </ng-container>
            <avi-data-grid
                [config]="policyGridConfig"
                [rows]="policyGridRows"
            >
                <ng-template
                    #policiesAppliedFieldTemplateRef
                    let-row
                    let-index="index"
                >
                    <div class="vs-log-cinematic-section-summary__policy-grid-row">
                        <div class="vs-log-cinematic-section-summary__policy-grid-row-name">
                            {{ row.label }}
                        </div>
                        <div class="vs-log-cinematic-section-summary__policy-grid-row-value">
                            {{ Boolean(row.selector | async) }}
                        </div>
                    </div>
                </ng-template>
            </avi-data-grid>
        </div>

        <!-- Client Request Info & Server Response Infomation -->
        <div vsLogCinematicSectionColumn>
            <!-- Client Request Infomation -->
            <h5 class="vs-log-cinematic-section__column-title">
                {{ l10nKeys.clientRequestInfoLabel | vtranslate }}
            </h5>
            <ng-container *ngrxLet="requestInfo$ as requestInfo">
                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.hostLabel | vtranslate }}</span>
                <span
                    *ngIf="requestInfo.host; else na"
                    vsLogPartialSelectionFilter
                    [property]="'host'"
                    [value]="requestInfo.host"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ globalL10nKeys.requestLabel | vtranslate }}</span>
                <ng-container *ngIf="(requestDetails$ | async) as requestDetails; else na">
                    <span
                        *ngIf="requestDetails.method as method"
                        vsLogFilter
                        property="method"
                        [value]="method"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ method }}
                    </span>

                    <span
                        *ngIf="requestDetails.http_version as httpVersion"
                        vsLogFilter
                        property="http_version"
                        [value]="httpVersion"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ httpVersion | logHttpVersion }}
                    </span>

                    <ng-container *ngIf="requestDetails.request_length !== undefined">
                        (<span
                            vsLogFilterList
                            property="request_length"
                            [value]="requestDetails.request_length"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        >
                            {{ requestDetails.request_length }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}
                        </span>)
                    </ng-container>

                    <span
                        *ngIf="requestDetails.request_content_type as contentType"
                        vsLogPartialSelectionFilter
                        property="request_content_type"
                        [value]="contentType"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    ></span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.unparsedUriLabel | vtranslate }}</span>
                <span
                    *ngIf="requestInfo.orig_uri as uri; else na"
                    vsLogPartialSelectionFilter
                    property="orig_uri"
                    [value]="uri"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.uriLabel | vtranslate }}</span>
                <span
                    *ngIf="requestInfo.uri as uri; else na"
                    vsLogPartialSelectionFilter
                    property="uri_path"
                    [value]="uri"
                    [noSearchIcon]="!!requestInfo.uriQuery"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>
                <ng-container *ngIf="requestInfo.uriQuery as uriQuery">
                    <span class="vs-log-cinematic-section-summary__uri-query-question-mark">
                        &nbsp;?&nbsp;
                    </span>
                    <span
                        vsLogPartialSelectionFilter
                        property="uri_query"
                        [value]="uriQuery"
                        position="right"
                    ></span>
                </ng-container>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.userAgentLabel | vtranslate }}</span>
                <span
                    *ngIf="requestInfo.userAgent; else na"
                    vsLogPartialSelectionFilter
                    property="user_agent"
                    [value]="requestInfo.userAgent"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>
            </ng-container>

            <!-- Server Response Information -->
            <h5 class="vs-log-cinematic-section__column-title">
                {{ l10nKeys.serverResponseInfoLabel | vtranslate }}
            </h5>
            <ng-container *ngrxLet="responseInfo$ as responseInfo">
                <span vsLogCinematicFieldTitle>{{ l10nKeys.contentTypeLabel | vtranslate }}</span>
                <span
                    *ngIf="responseInfo.response_content_type as contentType; else na"
                    vsLogPartialSelectionFilter
                    property="response_content_type"
                    [value]="contentType"
                    position="right"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                ></span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.serverRedirectUriLabel | vtranslate }}</span>
                <span
                    *ngIf="responseInfo.server_side_redirect_uri as redirectedUri; else na"
                    vsLogFilter
                    property="server_side_redirect_uri"
                    [value]="redirectedUri"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ redirectedUri }}
                </span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.serverResponseLengthLabel | vtranslate }}</span>
                <span
                    *ngIf="responseInfo.serverResponseLength !== undefined; else na"
                    vsLogFilter
                    property="server_response_length"
                    [value]="responseInfo.serverResponseLength"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ responseInfo.serverResponseLength }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}
                </span>

                <span vsLogCinematicFieldTitle>{{ l10nKeys.grpcStatusLabel | vtranslate }}</span>
                <ng-container *ngIf="responseInfo.grpcStatus !== undefined; else na">
                    <span
                        vsLogFilter
                        [property]="'grpc_status'"
                        [value]="responseInfo.grpcStatus"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ responseInfo.grpcStatus }}
                    </span>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <!-- L4 log -->
    <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L4">
        <!-- Location -->
        <div vsLogCinematicSectionColumn>
            <ng-container *ngrxLet="l4ClientInfo$ as clientInfo">
                <span
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.locationLabel | vtranslate }}
                </span>
                <div
                    vsLogNationalFlag
                    [countryCode]="clientInfo.client_location"
                ></div>
                <span
                    *ngIf="clientInfo.client_location as location; else na"
                    vsLogFilter
                    property="client_location"
                    [value]="location"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ location | countryCodeToName }}
                </span>
                <!-- Start Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.startTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.startTime; else na">{{ clientInfo.startTime | aviDate:timeFormat }}</span>

                <!-- End Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.endTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.endTime; else na">{{ clientInfo.endTime | aviDate:timeFormat }}</span>
            </ng-container>
        </div>
    </ng-container>

    <!-- DNS log -->
    <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.DNS">
        <!-- Two Client columns -->
        <ng-container *ngrxLet="dnsClientInfo$ as clientInfo">
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ globalL10nKeys.clientLabel | vtranslate }}
                </h5>

                <!-- Location -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.locationLabel | vtranslate }}</span>
                <div
                    vsLogNationalFlag
                    [countryCode]="clientInfo.client_location"
                ></div>
                <span
                    *ngIf="clientInfo.client_location as location; else na"
                    vsLogFilter
                    property="client_location"
                    [value]="location"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ location | countryCodeToName }}
                </span>

                <!-- Start Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.startTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.startTime; else na">{{ clientInfo.startTime | aviDate:timeFormat }}</span>

                <!-- End Time -->
                <span vsLogCinematicFieldTitle>{{ l10nKeys.endTimeLabel | vtranslate }}</span>
                <span *ngIf="clientInfo.endTime; else na">{{ clientInfo.endTime | aviDate:timeFormat }}</span>
            </div>

            <!-- DNS Query Type -->
            <div
                vsLogCinematicSectionColumn
                [withDivider]="false"
                [withContentTopPadding]="true"
            >
                <div vsLogCinematicFieldTitle>{{ l10nKeys.dnsQueryTypeLabel | vtranslate }}</div>
                <span
                    *ngIf="clientInfo.dns_qtype as queryType; else na"
                    vsLogFilter
                    property="dns_qtype"
                    [value]="queryType"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ enumLabelHash[queryType] }}
                </span>

                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.protocolLabel | vtranslate }}</div>
                <span
                    *ngIf="clientInfo.protocol as protocol; else na"
                    vsLogFilter
                    property="protocol"
                    [value]="protocol"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ enumLabelHash[protocol] }}
                </span>
            </div>
        </ng-container>

        <!-- Load Balancer column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.loadBalancerLabel | vtranslate }}
            </h5>

            <ng-container *ngrxLet="dnsLoadBalancerInfo$ as dnsLoadBalancerInfo">
                <div vsLogCinematicFieldTitle>{{ l10nKeys.gslbServiceLabel | vtranslate }}</div>
                <span
                    *ngIf="dnsLoadBalancerInfo.gslbservice as gslbservice; else na"
                    vsLogFilter
                    property="gslbservice"
                    [value]="gslbservice"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ gslbservice }}
                </span>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.gslbPoolLabel | vtranslate }}</div>
                <div
                    *ngIf="dnsLoadBalancerInfo.gslbpool_name as gslbPoolName; else na"
                    vsLogFilter
                    property="gslbpool_name"
                    [value]="gslbPoolName"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ gslbPoolName }}
                </div>

                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.queryLabel | vtranslate }}</div>
                <div
                    *ngIf="dnsLoadBalancerInfo.query_or_response !== undefined; else na"
                    vsLogFilter
                    property="query_or_response"
                    [value]="dnsLoadBalancerInfo.query_or_response"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ dnsLoadBalancerInfo.query_or_response }}
                </div>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.recursionAvailableLabel | vtranslate }}</div>
                <span
                    *ngIf="dnsLoadBalancerInfo.recursion_available !== undefined; else na"
                    vsLogFilter
                    property="dns_response.recursion_available"
                    [value]="dnsLoadBalancerInfo.recursion_available"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ dnsLoadBalancerInfo.recursion_available }}
                </span>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template #clrUnknownIcon>
    <cds-icon shape="unknown-status"></cds-icon>
</ng-template>

<ng-template #clrWorldIcon>
    <cds-icon
        class="vs-log-cinematic-section-summary__clr-icon-with-margin-right"
        shape="world"
    ></cds-icon>
</ng-template>
