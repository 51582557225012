/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';

import {
    Injectable,
    Injector,
} from '@angular/core';
import { Observable } from 'rxjs';

import { schema } from 'ajs/js/services/schema';
import { Auth } from 'ajs/modules/core/services/auth';

const cookieHeaderName = 'X-CSRFToken';

/**
 * @description
 *
 *     HTTP Interceptor to set following headers in HttpRequest.
 *        - X-Avi-UserAgent
 *        - X-Avi-Version
 *        - X-Avi-Tenant
 *
 * @author Aravindh Nagrajan
 */
@Injectable()
export class SetRequestHeadersInterceptor implements HttpInterceptor {
    /**
     * ALB App version. Importing directly from schema instead of using SchemaService to avoid
     * Angular circular dependencies.
     */
    public readonly appVersion = schema.version;

    /**
     * UserAgent value for requests originated from UI.
     */
    public readonly userAgent = 'UI';

    constructor(
        /**
         * Using Injector here instead of directly injecting Auth to avoid Angular circular
         * dependencies.
         */
        private readonly injector: Injector,
        private tokenExtractor: HttpXsrfTokenExtractor,
    ) {}

    /** @override */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let { headers } = req;

        headers = headers.set('X-Avi-UserAgent', this.userAgent);
        headers = headers.set('X-Avi-Version', this.appVersion);

        const csrfToken = this.tokenExtractor.getToken() as string;

        if (csrfToken !== null && !headers.has(cookieHeaderName)) {
            headers = headers.set(cookieHeaderName, csrfToken);
        }

        const authService = this.injector.get(Auth);

        if (authService.isLoggedIn()) {
            headers = headers.set('X-Avi-Tenant', authService.getTenantName());
        }

        const clonedRequest = req.clone({ headers });

        return next.handle(clonedRequest);
    }
}
