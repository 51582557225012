<div
    class="mapping-rule-group-match"
    provideParentForm
>
    <avi-fieldset [hideContent]="!groupMatch.config.criteria">
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.AuthMappingRule }}"
                    fieldName="group_match"
                >
                    {{ l10nKeys.groupMatchLabel | vtranslate }}
                </label>
                <avi-radio
                    name="auth-match-criteria"
                    [value]="null"
                    [(ngModel)]="groupMatch.config.criteria"
                    (ngModelChange)="onClearCriteria()"
                >
                    {{ globalL10nKeys.anyLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="auth-match-criteria"
                    [value]="authMatchOperationEnumHash.AUTH_MATCH_CONTAINS"
                    [(ngModel)]="groupMatch.config.criteria"
                    tooltipText="{{ getTooltip(authMatchOperationEnumHash.AUTH_MATCH_CONTAINS) }}"
                >
                    {{ l10nKeys.memberLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="auth-match-criteria"
                    [value]="authMatchOperationEnumHash.AUTH_MATCH_DOES_NOT_CONTAIN"
                    [(ngModel)]="groupMatch.config.criteria"
                    tooltipText="{{ getTooltip(authMatchOperationEnumHash.AUTH_MATCH_DOES_NOT_CONTAIN) }}"
                >
                    {{ l10nKeys.notMemberLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>

        <avi-fieldset_content>
            <div *ngIf="groupMatch.hasCriteria">
                <avi-input-container
                    helperText="{{ l10nKeys.groupNameHelperText | vtranslate }}"
                    noMarginTop
                >
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AuthMatchGroupMembership }}"
                        fieldName="groups"
                        required
                    >
                        {{ l10nKeys.groupLabel | vtranslate }}
                    </label>

                    <avi-repeated-strings
                        name="auth-match-groups"
                        placeholder="{{ l10nKeys.groupNamePlaceholder | vtranslate }}"
                        [(ngModel)]="groupMatch.config.groups"
                        required
                    ></avi-repeated-strings>
                </avi-input-container>
            </div>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
