/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export const LIST_COLL_DATA_SOURCE_WITH_NAME_CONTAINS_SEARCH_PARAM =
    'ListCollDataSourceWithNameContainsSearchParam';
