/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    IMustChecksInfo,
    UpgradeFsmState,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import './prechecks-list-expander.component.less';

import * as l10n from './prechecks-list-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to display prechecks list grouped as Success, error,
 * warning state.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'prechecks-list-expander',
    templateUrl: './prechecks-list-expander.component.html',
})
export class PrechecksListExpanderComponent implements OnInit {
    /**
     * Prechecks which need to be shown in accordian.
     */
    @Input()
    public prechecks: IMustChecksInfo[];

    public groupedPrechecks: Record<string, IMustChecksInfo[]> = {};

    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of precheck status and its l10n keys.
     */
    public readonly precheckGroupHash = {
        error: this.l10nKeys.errorLabel,
        warning: this.l10nKeys.warningLabel,
        success: this.l10nKeys.successLabel,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.groupedPrechecks = this.prechecks
            .reduce((groupedChecks: Record<string, IMustChecksInfo[]>, check: IMustChecksInfo) => {
                switch (check.state) {
                    case UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS:
                        groupedChecks.success.push(check);
                        break;
                    case UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR:
                        groupedChecks.error.push(check);
                        break;
                    case UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING:
                        groupedChecks.warning.push(check);
                        break;
                }

                return groupedChecks;
            }, {
                error: [],
                warning: [],
                success: [],
            });
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Empty method for keyValue pipe to unsort keys.
     */
    public unsorted(): number {
        return 0;
    }
}
