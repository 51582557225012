<div class="vs-logs-signpost-custom-groups">
    <ng-content></ng-content>

    <div
        *ngIf="vsLogsSignpostStore.vsLogsGroupsSignpostData$ | async as vsLogsGroupsSignpostData"
        class="vs-logs-signpost-custom-groups__grids-section"
    >
        <ng-container *ngFor="let group of vsLogsGroupsSignpostData; trackBy: trackbyIndex">
            <avi-dynamic-data-grid
                *ngIf="group.children.length"
                class="vs-logs-signpost-custom-groups__data-grid"
                [config]="groupsGridConfig"
                [rows]="group.children"
                [rowsTotal]="group.children.length"
                [gridTitle]="group.name"
            ></avi-dynamic-data-grid>
        </ng-container>
    </div>
</div>

<div
    class="vs-logs-signpost-custom-groups__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        class="vs-logs-signpost-custom-groups__name-column"
        *ngIf="row.value.isValid"
        vsLogFilter
        [property]="row.value.param"
        [value]="row.value.value"
        [operator]="row.value?.contains ? FilterOperatorType.CONTAINS : FilterOperatorType.EQUAL_TO"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
    >
        {{ row.value.display || row.value.value }}
    </span>
    <span
        *ngIf="!row.value.isValid"
        vsLogFilterableEmptyField
        [property]="row.value.param"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        class="vs-logs-signpost-custom-groups__empty-field"
    ></span>
</ng-template>

<ng-template
    #progressBarFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-progress-bar
        [percentage]="row.percentage"
        [hidePercentage]="true"
    ></avi-progress-bar>
</ng-template>
