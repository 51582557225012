/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import * as globalL10n from 'global-l10n';
import { FullModalComponentService } from '../../full-modal-component.service';
import './full-modal-config-footer.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component to display a footer in the config modal.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-config-footer',
    templateUrl: './full-modal-config-footer.component.html',
})
export class FullModalConfigFooterComponent {
    /**
     * If true, disables the buttons.
     */
    @Input() public disabled: boolean;

    /**
     * If true, shows a spinner and hides the buttons.
     */
    @Input() public busy: boolean;

    /**
     * Text to show for the Cancel button.
     */
    @Input() public cancelButtonText = 'Cancel';

    /**
     * Text to show for the Submit button.
     */
    @Input() public submitButtonText = 'Save';

    /**
     * If true, will disable the submit button.
     */
    @Input() public disableSubmit: boolean;

    /**
     * If true, will hide the submit button.
     */
    @Input()
    public hideSubmit: boolean;

    /**
     * Called when the user clicks the Cancel button.
     */
    @Output() public onCancel = new EventEmitter();

    /**
     * Called when the user clicks the Submit button.
     */
    @Output() public onSubmit = new EventEmitter();

    /**
     * Reference to the fullModalComponentService.viewMode$ observable.
     */
    public readonly viewMode$: Observable<boolean>;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(fullModalComponentService: FullModalComponentService) {
        this.viewMode$ = fullModalComponentService.viewMode$;
    }

    /**
     * Handler for clicking the Submit button.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Handler for clicking the cancel button.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }
}
