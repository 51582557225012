/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    FullModalComponentService,
} from 'ng/shared/components/full-modal/full-modal-component.service';
import { Subscription } from 'rxjs';
import './avi-radio.component.less';

/**
 * @description Wrapper around the Clarity radio input element.
 * @author alextsg
 */
@Component({
    selector: 'avi-radio',
    templateUrl: './avi-radio.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRadioComponent),
        },
    ],
})
export class AviRadioComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
    /**
     * Name bound to the ngModel.
     */
    @Input()
    public name: string;

    /**
     * Value set as the model value when this radio option is set. 'any' is used as a type here for
     * cases where this is an enum value.
     */
    @Input()
    public value: string | any;

    @Input()
    public objectType?: string;

    @Input()
    public fieldName?: string;

    @Input()
    public tooltipText?: string;

    @Input()
    public enumType?: string;

    @Input()
    public enumValue?: string;

    @Input()
    public required?: boolean;

    /**
     * Sets the disabled state of the radio input element.
     */
    @Input()
    public disabled?: boolean;

    /**
     * Model value passed to the checkbox element.
     */
    public modelValue: string;

    /**
     * If false, show the radio input element. If true, show the readonly view of the selected
     * option.
     */
    public viewMode = false;

    /**
     * Unique value to set as the id of the radio input element. The name by itself cannot be used
     * as the id because all radio buttons in a radio button group have the same name but need to
     * have different ids.
     */
    public uniqueIdentifier = '';

    /**
     * Subscription to the viewMode value.
     */
    private viewModeSubscription: Subscription;

    constructor(
        @Optional()
        private readonly fullModalComponentService: FullModalComponentService,
    ) {}

    public ngOnInit(): void {
        if (this.fullModalComponentService) {
            this.viewModeSubscription = this.fullModalComponentService.viewMode$
                .subscribe(viewMode => this.viewMode = viewMode);
        }
    }

    public ngOnDestroy(): void {
        if (this.viewModeSubscription) {
            this.viewModeSubscription.unsubscribe();
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { name, value } = changes;

        if (name || value) {
            this.uniqueIdentifier = this.getUniqueIdentifier();
        }
    }

    public get isSelectedValue(): boolean {
        return this.value === this.modelValue;
    }

    /**
     * Change handler when the value is changed by the checkbox element.
     */
    public handleModelChange(value: string): void {
        this.setValue(value);
    }

    /***********************************************************
     * Implementing ControlValueAccessor Interface
     */

    /**
     * Write the model value.
     */
    public writeValue(value: string): void {
        this.modelValue = value;
    }

    /**
     * Set the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Set the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***********************************************************/

    /**
     * Set the model value and calls onChange.
     */
    private setValue(value: string): void {
        this.modelValue = value;

        this.onChange(this.modelValue);
        this.onTouched();
    }

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /***********************************************************/

    /**
     * Return unique id for this radio element.
     */
    private getUniqueIdentifier(): string {
        return `${this.name}-${this.value}`;
    }
}
