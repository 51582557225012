/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import { Inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { forkJoin, pipe } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { IHttpResponse } from 'angular';

import {
    ALBServicesConnectivityStatus,
    ALBServicesRegistrationStatus,
    IALBServicesConfig,
} from 'generated-types';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

import {
    HttpMethod,
    HttpWrapper,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

/**
 * Properties fetched from api/albservices/status.
 */
interface IAlbServicesStatus {
    isRegistered: boolean;
    isConnected: boolean;
}

/**
 * Properties fetched from api/albservicesconfig.
 */
interface IAlbServicesConfig {
    ipReputationEnabled: boolean;
    botManagementEnabled: boolean;
    crsSignaturesEnabled: boolean;
    portalUrl: string;
}

interface ICloudServicesWidgetStoreState extends IAlbServicesStatus, IAlbServicesConfig {
    loading: boolean;
}

const initialState: ICloudServicesWidgetStoreState = {
    isRegistered: false,
    isConnected: false,
    ipReputationEnabled: false,
    botManagementEnabled: false,
    crsSignaturesEnabled: false,
    portalUrl: '',
    loading: false,
};

const ALB_SERVICES_STATUS_URL = '/api/albservices/status';
const ALB_SERVICES_CONFIG_URL = '/api/albservicesconfig';

/**
 * @description
 * Store for the CloudServicesWidgetComponent. Fetches information about registration/connectivity
 * and configuration values.
 *
 * @author alextsg
 */
@Injectable()
export class CloudServicesWidgetStore extends ComponentStore<ICloudServicesWidgetStoreState> {
    public readonly loading$ = this.select(state => state.loading);

    public readonly isRegistered$ = this.select(state => state.isRegistered);

    public readonly isConnected$ = this.select(state => state.isConnected);

    public readonly ipReputationEnabled$ = this.select(state => state.ipReputationEnabled);

    public readonly botManagementEnabled$ = this.select(store => store.botManagementEnabled);

    public readonly crsSignaturesEnabled$ = this.select(store => store.crsSignaturesEnabled);

    public readonly portalUrl$ = this.select(store => store.portalUrl);

    /**
     * Combines both cloud services requests and sets the loading state.
     */
    public readonly fetchCloudServiceStatus = this.effect<void>(pipe(
        tap(() => this.patchState({ loading: true })),
        switchMap(
            () => forkJoin({
                albServicesStatus: this.fetchAlbServicesStatus(),
                albServicesConfig: this.fetchAlbServicesConfig(),
            }).pipe(
                tapResponse(
                    ({ albServicesStatus, albServicesConfig }) => {
                        this.patchState({ ...albServicesStatus });
                        this.patchState({ ...albServicesConfig });
                    },
                    // TODO: Add error handling when UX is available.
                    error => this.devLoggerService.error(error),
                    () => this.patchState({ loading: false }),
                ),
            ),
        ),
    ));

    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly devLoggerService: DevLoggerService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        super(initialState);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Allows component to cancel ongoing requests, ex. when the user leaves the page.
     */
    public cancelFetchCloudServicesStatus(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /**
     * Fetches for registration and connectivity information.
     */
    private async fetchAlbServicesStatus(): Promise<IAlbServicesStatus> {
        const requestConfig = {
            url: ALB_SERVICES_STATUS_URL,
            method: HttpMethod.GET,
            requestId: 'cloud-widgets-alb-services-status',
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const {
                registration_status: registrationStatus,
                connectivity_status: connectivityStatus,
            } = data;

            const isRegistered = registrationStatus ===
                ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED;

            const isConnected = connectivityStatus ===
                ALBServicesConnectivityStatus.ALBSERVICES_CONNECTED;

            return {
                isRegistered,
                isConnected,
            };
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Fetches for configuration information along with the portal endpoint.
     */
    private async fetchAlbServicesConfig(): Promise<IAlbServicesConfig> {
        const requestConfig = {
            url: ALB_SERVICES_CONFIG_URL,
            method: HttpMethod.GET,
            requestId: 'cloud-widgets-alb-services-config',
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const {
                feature_opt_in_status: featureOptInStatus,
                waf_config: wafConfig,
                portal_url: portalUrl,
            } = data as IALBServicesConfig;

            const {
                enable_ip_reputation: ipReputationEnabled,
                enable_user_agent_db_sync: botManagementEnabled,
            } = featureOptInStatus;

            return {
                portalUrl,
                ipReputationEnabled,
                botManagementEnabled,
                crsSignaturesEnabled: wafConfig.enable_auto_download_waf_signatures,
            };
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Handler for errors during a request. If the request was simply canceled, we don't need to
     * throw an error.
     */
    private handleRequestError(error: IHttpResponse<any>): IHttpResponse<any> | void {
        if (error?.xhrStatus === 'abort') {
            return;
        }

        this.devLoggerService.error(error);

        return error;
    }
}
