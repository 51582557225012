/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing tooltip directives and components.
 * @preferred
 * @module TooltipModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { VIPModule } from '@vmw/ngx-vip';

import {
    AviHoverOverlayComponent,
    AviLabelWithTooltipComponent,
    AviTooltipContentComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    AviTooltipTextComponent,
    HelperTextTooltipIconComponent,
    SignpostComponent,
} from './components';

import {
    AviTitleDirective,
    AviTooltipDirective,
} from './directives';

const components = [
    AviHoverOverlayComponent,
    AviLabelWithTooltipComponent,
    AviTooltipContentComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    AviTooltipTextComponent,
    HelperTextTooltipIconComponent,
    SignpostComponent,
];

const directives = [
    AviTitleDirective,
    AviTooltipDirective,
];

@NgModule({
    declarations: [
        ...components,
        ...directives,
    ],
    exports: [
        AviHoverOverlayComponent,
        AviLabelWithTooltipComponent,
        AviTitleDirective,
        AviTooltipContentComponent,
        AviTooltipDirective,
        AviTooltipIconComponent,
        AviTooltipIconWithContentWrapperComponent,
        HelperTextTooltipIconComponent,
        SignpostComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
        VIPModule.forChild(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TooltipModule {}
