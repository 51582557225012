/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-signpost-custom-e2e-timing-header';
const prefix = `${moduleName}.${componentName}`;

export const dataTransferLabel = `${prefix}.dataTransferLabel`;
export const clientRttLabel = `${prefix}.clientRttLabel`;
export const serverRttLabel = `${prefix}.serverRttLabel`;
export const appResponseLabel = `${prefix}.appResponseLabel`;

export const ENGLISH = {
    [dataTransferLabel]: 'Data Transfer',
    [clientRttLabel]: 'Client RTT',
    [serverRttLabel]: 'Server RTT',
    [appResponseLabel]: 'App Response',
};
