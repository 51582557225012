/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { AppLevelAlertsService } from 'ng/modules/core/services/app-level-alerts.service';
import {
    ControllerFaultCollection,
    TControllerFaultCollection,
} from 'collections/controller-fault.collection.factory';

const CONTROLLER_FAULTS_ALERT_ID = 'controller-faults';
const CONTROLLER_FAULTS_LOCAL_STORAGE_KEY = 'controllerFaults';

/**
 * @description
 * Service to fetch and show/hide controller faults.
 * @author alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class ControllerFaultsService {
    private controllerFaultCollection: ControllerFaultCollection | null = null;

    constructor(
        private readonly appLevelAlertsService: AppLevelAlertsService,
        @Inject(ControllerFaultCollection)
        private readonly ControllerFaultCollection: TControllerFaultCollection,
    ) {}

    /**
     * Set localStorage.controllerFaults and poll controller faults. Called when user is logged in
     * to the application. Called both when the user logs in and when user refreshes the page.
     */
    public start(): void {
        this.stop();

        if (!localStorage.getItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY)) {
            localStorage.setItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY, '');
        }

        this.pollControllerFaults();
    }

    /**
     * Destroy controllerFaultCollection to stop polling for data.
     */
    public stop(): void {
        localStorage.removeItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY);

        if (this.controllerFaultCollection) {
            this.controllerFaultCollection.destroy();

            this.controllerFaultCollection = null;
        }
    }

    /**
     * Refresh controller fault notifications. Called from the welcome component after submit so
     * that notifications get refreshed.
     */
    public refreshControllerFaultsAlert(): void {
        const cachedFaultDescriptions = localStorage.getItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY);

        // If no controller fault present then no need to fetch data again.
        if (!cachedFaultDescriptions) {
            return;
        }

        this.hideControllerFaultsAlert();
        this.pollControllerFaults();
    }

    /**
     * Poll for controller faults.
     */
    private pollControllerFaults(): void {
        if (!this.controllerFaultCollection) {
            this.controllerFaultCollection = new this.ControllerFaultCollection({
                bind: {
                    collectionLoadSuccess: this.onCollectionLoadSuccess,
                },
            });
        }

        this.controllerFaultCollection.load();
    }

    /**
     * Handle the ControllerFaultCollection load.
     */
    private onCollectionLoadSuccess = (): void => {
        const faultDescriptionList = this.controllerFaultCollection.faultDescriptions;
        const faultDescriptions = faultDescriptionList.sort().join();
        const cachedFaultDescriptions = localStorage.getItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY);

        if (cachedFaultDescriptions !== faultDescriptions) {
            if (faultDescriptions) {
                this.hideControllerFaultsAlert();
                this.showControllerFaultsAlert();
            }

            localStorage.setItem(CONTROLLER_FAULTS_LOCAL_STORAGE_KEY, faultDescriptions);
        }
    };

    /**
     * Add controller fault as an app-level alert.
     */
    private showControllerFaultsAlert(): void {
        this.appLevelAlertsService.add({
            id: CONTROLLER_FAULTS_ALERT_ID,
            componentProps: {
                messages: this.controllerFaultCollection.faultDescriptions,
                status: 'danger',
            },
        });
    }

    /**
     * Remove fault from app-level alerts.
     */
    private hideControllerFaultsAlert(): void {
        if (this.appLevelAlertsService.has(CONTROLLER_FAULTS_ALERT_ID)) {
            this.appLevelAlertsService.remove(CONTROLLER_FAULTS_ALERT_ID);
        }
    }
}
