/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'pki-profile';

export const pkiProfileModalBreadcrumbTitle = `${prefix}.pkiProfileModalBreadcrumbTitle`;
export const addCaModalBreadcrumbTitle = `${prefix}.addCaModalBreadcrumbTitle`;
export const addCrlModalBreadcrumbTitle = `${prefix}.addCrlModalBreadcrumbTitle`;

export const ENGLISH = {
    [pkiProfileModalBreadcrumbTitle]: 'PKI Profile',
    [addCaModalBreadcrumbTitle]: 'Add CA',
    [addCrlModalBreadcrumbTitle]: 'Add CRL',
};
