/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vip-list-popover';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [headerLabel]: 'Addresses {0} ({1})',
};
