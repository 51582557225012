/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Component, Type } from '@angular/core';
import { GeoDbFileModalComponent } from 'ng/modules/security';
import {
    GeoDBFileVendor,
    IGeoDBFile,
} from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './geo-db.l10n';

/**
 * Ajs dependency token for GeoDBFileConfigItem.
 */
export const GEO_DB_FILE_CONFIG_ITEM_TOKEN = 'GeoDBFileConfigItem';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   Geo DB file Message Item.
 *
 * @author Rachit Aggarwal
 */
export class GeoDbFileConfigItem extends withFullModalMixin(MessageItem)<IGeoDBFile> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'GeoDBFile',
            windowElement: GeoDbFileModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IGeoDBFile> {
        const { objectType: type } = this;
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            vendor: GeoDBFileVendor.VENDOR_AVI_DEFINED,
        };
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addGeoDbFileModalBreadcrumbTitle);
    }
}
