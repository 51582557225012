/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { DataSource } from './data-source.factory';

//TODO figure out what to do with page_size
//TODO manual setup (construction run) is a mess. Nobody gonna use it
//TODO After params/sort/search update items list becomes invalid until the next load and
//TODO furthermore - wo flush next update will make state even more inconsistent!

/**
 * @constructor
 * @memberof module:avi/dataModel
 * @extends DataSource
 * @author Alex Malitsky, Ashish Verma, Ram Pal
 * @desc
 *
 *     Collection Data Source is a layer between Collection and DataTransformer responsible for
 *     initial preparation of request payload for API call and processing received and
 *     pre-processed by DataTransformer data. Tightly bound to Collection by Collection#items
 *     and uses many of it's methods such as updateItemData, appendItem, removeItem on data
 *     processing.
 *
 *     Collection users need to interact with Collection's methods and properties such as
 *     sorting, search string, ordering, viewportSizeChange and some more generic ones - such as
 *     setting up referred_by filter with have to be taken into consideration while making
 *     actual API calls. Collection notifies all it's DataSources when such updates are being
 *     made and each CollDataSource is responsible for setting corresponding internal state
 *     which would be represented by request parameters object.
 *
 *     Other duty of CollDataSource is to deliver new data updates to Collection#items. In case
 *     of `config` list source CollDataSource will figure out where each of Items it got should
 *     be landed as updated/created/moved or be removed at all since it had been removed on the
 *     backend side. In case of any other update types each Item will get a data object to
 *     update itself with received data (list and it's order won't change).
 *
 *     DataSource of {@link module:avi/dataModel.CollDataSource CollDataSource}.
 */
export class CollDataSource extends DataSource {
    constructor(args) {
        super(args);

        this.Timeframe = this.getAjsDependency_('Timeframe');

        this.$q_ = this.getAjsDependency_('$q');

        //extends default API/DS features with ones from args
        _.each(['hasSearch', 'hasSorting', 'hasPagination'], function(propertyName) {
            this[propertyName] = !_.isUndefined(args[propertyName]) ? !!args[propertyName] :
                this[propertyName];
        }, this);

        this.onTimeframeChange_ = this.onTimeframeChange_.bind(this);
        this.Timeframe.on('change', this.onTimeframeChange_);

        this.onItemDrop_ = this.onItemDrop_.bind(this);
        this.owner_.on('collectionItemDropSuccess', this.onItemDrop_);
    }

    /**
     * Event listener for {@link Timeframe} change event.
     * @protected
     */
    onTimeframeChange_() {
        this.setUpdateInterval(this.Timeframe.selected().interval);
    }

    /**
     * Kinda event-handler for offset change to be called by Collection.
     * @param {number} offset - How many rows are hidden above the top of viewport.
     * @param {string[]} visibleItemIds - List of visible Item ids.
     * @param {boolean=} noEvent - When truthy no onOffsetChange event will be fired.
     * @returns {ng.$q.promise}
     * @abstract
     */
    setOffset(offset, visibleItemIds, noEvent) {
        return this.$q.reject('Abstract `sefOffset` method of `CollDataSource` has been called.');
    }

    /**
     * Kinda event handler for viewport size change event to be called by viewport directive
     * through Collection.
     * @param {number} limit - How many items can we show at once.
     * @param {boolean} noEvent - When truthy no onOffsetChange event will be fired.
     * @returns {ng.$q.promise}
     * @abstract
     * @public
     */
    setLimit(limit, noEvent) {
        return this.$q.reject('Abstract `setLimit` method of `CollDataSource` has been called.');
    }

    /**
     * Sets a search keyword when search is available.
     * @param {string|Array.<string>=} fieldNames - List of searchable fields or just one.
     * @param {string=} keyword - Undefined or empty string will switch search feature off.
     * @returns {boolean} - true when new keyword has been set, false otherwise.
     * @public
     * @abstract
     */
    setSearchParam(fieldNames, keyword) { return false; }

    /**
     * Sets a sorting parameter when available.
     * @param {string=} propertyName. Sorting will be switched off when undefined or empty
     *     string has been provided.
     * @returns {boolean} - True when new sorting has been set, false otherwise.
     * @public
     * @abstract
     */
    setSortParam(propertyName) { return false; }

    /**
     * Returns true when API used by this DS provides a total items quantity on data load.
     * @type {boolean}
     * @abstract
     * @public
     */
    hasRealTotalNumberOfItems() {
        return false;
    }

    /**
     * Sometimes we want to have DataSource notified of Item drop event for consistency
     * purposes. For example to update DS#itemsTotal_ value before we make next load.
     * @param {string=} droppedItemId - Item#id
     * @abstract
     * @protected
     */
    onItemDrop_(droppedItemId) {}

    /** @override */
    reset() {
        this.setSearchParam();
        this.setSortParam();

        super.reset();
    }

    /**
     * Destroys the data source. Main point is to stop async factories, cancel all pending calls
     * and remove event listeners.
     * @param {boolean=} force - When true is passed will destroy CollDataSource even if it is
     *     `preserved`.
     */
    destroy(force) {
        const gotDestroyed = super.destroy(force);

        if (gotDestroyed) {
            this.Timeframe.unbind('change', this.onTimeframeChange_);
            this.owner_.unbind('collectionItemDropSuccess', this.onItemDrop_);
        }

        return gotDestroyed;
    }

    /**
     * Returns a field name which is selected for sorting. Can have '-' prefix.
     * @returns {string|undefined} - Undefined when no sorting is set.
     * @public
     * @abstract
     */
    getSortParam() { return undefined; }

    /**
     * Some dataSources know how many Items are available on the back-end side even when we have
     * loaded handful. Aka `count`.
     * @returns {number|undefined} - Undefined if not supported.
     * @abstract
     * @public
     */
    getTotalNumberOfItems() { return undefined; }
}

Object.assign(CollDataSource.prototype, {
    hasSearch: false,
    hasSorting: false,
    hasPagination: false,
    hasTotalNumberOfItems: false,
    defaultSearchParamName_: '',
    setOffset_: CollDataSource.prototype.setOffset,
    setLimit_: CollDataSource.prototype.setLimit,
});

CollDataSource.ajsDependencies = [
    '$q',
    'Timeframe',
];
