/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { IpamProfileCollection } from 'ajs/modules/ipam/factories/ipam-profile.collection.factory';
import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { typesWithoutExpander } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import {
    IPAMDNS_TYPE_INTERNAL,
    IPAMDNS_TYPE_INTERNAL_DNS,
} from 'ajs/js/constants/ipam-dns.constants';

import * as globalL10n from 'global-l10n';
import * as l10n from './ipam-dns-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const { ...globalL10nKeys } = globalL10n;

type TIpamProfileCollection = typeof IpamProfileCollection;

/**
 * @description IPAM/DNS Profile page component.
 *
 * @author Chetana Padmane
 */
@Component({
    selector: 'ipam-dns-profile-list-page',
    templateUrl: './ipam-dns-profile-list-page.component.html',
})
export class IpamDnsProfileListPageComponent implements OnInit, OnDestroy {
    /**
     * Template Ref for WafProfile Filedata.
     */
    @ViewChild('ipamDnsProfilesExpanderTemplateRef')
    public ipamDnsProfilesExpanderTemplateRef: TemplateRef<HTMLElement>;

    /**
     * IpamDns-profile collection-grid config.
     */
    public ipamDnsProfilesGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IPAM profile collection instance.
     */
    private readonly ipamProfileCollection: IpamProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        public readonly schemaService: SchemaService,
        @Inject(IpamProfileCollection) IpamProfileCollection: TIpamProfileCollection,
    ) {
        /**
         * Get keys from source bundles for template usage.
         */
        l10nService.registerSourceBundles(dictionary);

        this.ipamProfileCollection = new IpamProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.ipamProfileCollection;

        this.ipamDnsProfilesGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.ipamProfileCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                }, {
                    id: 'type',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleType),
                    transform: (row: IAviCollectionDataGridRow) =>
                        this.schemaService.getEnumValueLabel(
                            'IpamDnsType',
                            row.getConfig().type,
                        ),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.ipamDnsProfilesGridConfig = {
            ...this.ipamDnsProfilesGridConfig,
            expandedContentTemplateRef: this.ipamDnsProfilesExpanderTemplateRef,
            expanderDisabled: ipamProfile => {
                const { type } = ipamProfile;

                return !typesWithoutExpander.has(type);
            },
            createActions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.btnLabelIpamProfile),
                    onClick: () => {
                        this.ipamProfileCollection.setDefaultItemConfigProps({
                            type: IPAMDNS_TYPE_INTERNAL,
                        });
                        this.ipamProfileCollection.create();
                    },
                }, {
                    label: this.l10nService.getMessage(l10nKeys.btnLabelDnsProfile),
                    onClick: () => {
                        this.ipamProfileCollection.setDefaultItemConfigProps({
                            type: IPAMDNS_TYPE_INTERNAL_DNS,
                        });
                        this.ipamProfileCollection.create();
                    },
                },
            ],
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ipamProfileCollection.destroy();
    }
}
