/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import * as globalL10n from 'global-l10n';
import './avi-health-score-down.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for rendering the state and reason when item's operational status is down.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'avi-health-score-down',
    templateUrl: './avi-health-score-down.component.html',
})
export class AviHealthScoreDownComponent {
    /**
     * Hold item's current state label.
     */
    @Input()
    public stateLabel: string;

    /**
     * Hold item's current state reasons.
     */
    @Input()
    public reasons: string[] = [];

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(public readonly l10nService: L10nService) {}

    /**
     * trackBy function for reasons.
     */
    public trackByReason(index: number, reason: string): string {
        return reason;
    }
}
