<full-modal-config
    modalTitle="{{ modalHeader }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <!-- General -->
        <full-modal-tab-section
            [invalidateForm]="!editable.matchConfigItem.meetsMatchMinimumConfiguration()"
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>

            <div>
                <label
                    aviLabelWithTooltip
                    objectType="BotMappingRuleMatchTarget"
                    fieldName="component_matcher"
                    for="component-matcher"
                >
                    {{ l10nKeys.componentMatcherLabel | vtranslate }}
                </label>
                <avi-dropdown
                    id="component-matcher"
                    name="component-matcher"
                    [(ngModel)]="editable.config.match.config.component_matcher"
                    [options]="componentMatcherOptions"
                    placeholder="{{ l10nKeys.selectComponentMatcherPlaceholder | vtranslate }}"
                ></avi-dropdown>
            </div>
        </full-modal-tab-section>

        <!-- Match -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.matchSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.matchSectionTitle | vtranslate }}</h3>

            <match-adder
                [match]="editable.config.match"
                [matchOptions]="matchOptions"
            ></match-adder>
        </full-modal-tab-section>

        <!-- Action -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.actionLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.actionLabel | vtranslate }}</h3>

            <avi-fieldset>
                <avi-fieldset_header>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="classification"
                    >
                        {{ l10nKeys.botClassificationLabel | vtranslate }}
                    </label>
                </avi-fieldset_header>

                <avi-fieldset_content>
                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotClassification"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.clientTypeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            id="type"
                            name="type"
                            [options]="botClassificationTypeOptions"
                            [(ngModel)]="editable.config.classification.config.type"
                            placeholder="{{ l10nKeys.selectBotClassificationPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>

                    <avi-input-container *ngIf="editable.config.classification.config.type === 'USER_DEFINED_BOT'">
                        <label
                            aviLabelWithTooltip
                            objectType="BotClassification"
                            fieldName="user_defined_type"
                            for="user-defined-type"
                            required
                        >
                            {{ l10nKeys.stringLabel | vtranslate }}
                        </label>
                        <input
                            id="user-defined-type"
                            name="user-defined-type"
                            [(ngModel)]="editable.config.classification.config.user_defined_type"
                            clrInput
                            placeholder="{{ l10nKeys.enterStringPlaceholder | vtranslate }}"
                        />
                    </avi-input-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </full-modal-tab-section>
    </form>
</full-modal-config>
