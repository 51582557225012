/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { TEnumValueHash } from 'ajs/modules/core/services/schema-service/schema.types';

import {
    HTTPVersionMatchConfigItem,
} from 'ajs/modules/match/factories/http-version-match.config-item.factory';

import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component to show readonly information for HTTP Version Match.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'http-version-match-readonly',
    templateUrl: './http-version-match-readonly.component.html',
})
export class HttpVersionMatchReadonlyComponent implements OnInit {
    @Input()
    public editable: HTTPVersionMatchConfigItem;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Enum Value Hash to show 'description' for specified HTTPVersion.
     */
    public httpVersionsEnumValueHash: TEnumValueHash;

    /**
     * Used to display text label for selected match criteria in template.
     */
    public matchCriteria: string;

    constructor(private readonly schemaService: SchemaService) {}

    /** @override */
    public ngOnInit(): void {
        this.httpVersionsEnumValueHash = this.schemaService.getEnumValuesHash('HTTPVersion');
        this.matchCriteria = this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.match_criteria,
        );
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
