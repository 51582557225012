/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'launch-config';

export const launchConfigModalBreadcrumbTitle = `${prefix}.launchConfigModalBreadcrumbTitle`;

export const ENGLISH = {
    [launchConfigModalBreadcrumbTitle]: 'Launch Config',
};
