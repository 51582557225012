/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NatModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * @desc NAT policy Collection.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
export class NatPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'natpolicy',
            windowElement: 'lazy-load',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('NatPolicyItem');
    }
}

NatPolicyCollection.ajsDependencies = [
    'NatPolicyItem',
];
