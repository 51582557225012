<div class="full-modal-footer clr-wrapper">
    <div
        [hidden]="busy"
        class="full-modal-footer__buttons-container"
    >
        <button
            class="btn btn-inverse sel-cancel"
            (click)="handleCancel()"
            [disabled]="disabled"
        >
            {{ cancelButtonText }}
        </button>
        <div
            class="full-modal-footer__footer-container"
            *ngrxLet="viewMode$ as viewMode"
        >
            <ng-content select="full-modal-config-footer_footer"></ng-content>
            <button
                class="btn btn-success sel-save"
                (click)="handleSubmit()"
                [disabled]="disabled || disableSubmit"
                *ngIf="!hideSubmit && !viewMode"
            >
                {{ submitButtonText }}
            </button>
            <button
                class="btn btn-success"
                (click)="handleCancel()"
                [disabled]="disabled"
                *ngIf="viewMode"
            >
                {{ globalL10nKeys.doneLabel | vtranslate }}
            </button>
        </div>
    </div>

    <div
        [hidden]="!busy"
        class="full-modal-footer__spinner-container"
    >
        <clr-spinner clrSmall></clr-spinner>
    </div>
</div>
