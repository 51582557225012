/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import { Component } from '@angular/core';
import { customTemplates } from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';
import { VsLogsSignpostStore } from '../vs-logs-signpost.store';
import './vs-logs-signpost-custom.component.less';

/**
 * @description
 *     Component for displaying custom signpost templates.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom',
    templateUrl: './vs-logs-signpost-custom.component.html',
})
export class VsLogsSignpostCustomComponent {
    public readonly customTemplates = customTemplates;

    constructor(public readonly vsLogsSignpostStore: VsLogsSignpostStore) {}
}
