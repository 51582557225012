<avi-card class="system-settings-auth-card">
    <avi-card_header class="system-settings-auth-card__row">
        <div class="system-settings-auth-card__header">
            <cds-icon
                shape="id-badge"
                size="24"
                status="success"
            ></cds-icon>
            {{ l10nKeys.authHeader | vtranslate }}
        </div>
        <div class="system-settings-auth-card__type">
            {{ (remoteAuthentication ? l10nKeys.authTypeRemoteLabel : l10nKeys.authTypeLocalLabel) | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="auth_profile_uuid"
                for="auth-profile"
            >
                {{ l10nKeys.authProfileLabel | vtranslate }}
            </label>
            <ng-container *ngIf="authProfiles?.length; else emptyTemplate">
                <avi-input-container
                    noMarginTop
                    *ngFor="let profile of authProfiles; index as i; trackBy: trackByIndex"
                >
                    <input
                        name="auth-profile-{{ i }}"
                        clrInput
                        placeholder="-"
                        [value]="profile"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
        </avi-input-container>
        <avi-input-container>
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="auth_mapping_profile_uuid"
                for="auth-mapping-profile"
            >
                {{ l10nKeys.authMappingProfileLabel | vtranslate }}
            </label>
            <ng-container *ngIf="authMappingProfiles?.length; else emptyTemplate">
                <avi-input-container
                    noMarginTop
                    *ngFor="let profile of authMappingProfiles; index as i; trackBy: trackByIndex"
                >
                    <input
                        name="auth-mapping-profile-{{ i }}"
                        clrInput
                        placeholder="-"
                        [value]="profile"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
        </avi-input-container>
    </avi-card_body>
</avi-card>

<ng-template #emptyTemplate>
    <avi-input-container noMarginTop>
        <input
            name="empty-input"
            clrInput
            placeholder="-"
            readonly
        />
    </avi-input-container>
</ng-template>
