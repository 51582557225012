/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/**
 * @description Remove single qoutes from both ends of string.
 * @author Nisar Nadaf
 */
@Pipe({
    name: 'trimSingleQuote',
})
export class TrimSingleQuotePipe implements PipeTransform {
    /**
     * Remove single qoutes from both end of string.
     */
    public transform(val: string): string {
        return val?.replace(/(^'|'$)/g, '') || '';
    }
}
