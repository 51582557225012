/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Inject,
    Injectable,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import {
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { isDistinct } from 'ng/shared/utils/ngrx-store.utils';
import { Timeframe } from 'ajs/js/services/Timeframe';
import {
    IAviBarGraphOverlayTimeframe,
    TTimestampFormatFromApiWithMicroS,
} from
    'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';
import {
    calculateStep,
    convertTimeToTTimestampFormatFromApi,
    getUnixTimeInMilliseconds,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.utils';
import { AviDatePipe } from 'ng/modules/core/pipes/avi-date.pipe';
import * as UserPreferences from 'ng/root-store/user-preferences';
import { VsLogsApiRequestService } from './vs-logs-api-request.service';

import {
    FilterOperatorType,
    IVsLogsStateParams,
    TFilterObj,
    TFilterRangeObj,
    TLogEntrySignatureParams,
    TStateFilters,
    TVsLogListBulkDownloadStateParams,
    TVsLogListStateParams,
    TVsLogsGraphStateParams,
    TVsLogsPageStateParams,
    TVsLogStateParams,
    VsAppProfileType,
    VsLogsType,
} from '../vs-logs.types';

import { VsLogsEffectsService } from './vs-logs-effects.service';
import {
    convertFilterObjectToQueryString,
    convertLogEntryParamsToQueryStrings,
    findSavedSearch,
    squashFilters,
    transformSavedSearches,
} from '../utils/vs-logs-filters.utils';
import { getVsConfig } from '../utils/vs-logs.utils';
import { LogsTimeframeOptions } from './vs-logs-timeframes.service';

/**
 * @param customDuration -
 *  custom duration when graph has an overlay and graph-table timeframe do not match.
 * @param customEndTime -
 *  custom end time when graph has an overlay and graph-table timeframe do not match.
 * @param isLoading - flag to indicate whether a request is in progress.
 * @param selectedLogs - log_ids of the logs selected in the table by the user.
 */
type TTableProps = {
    customDuration: number;
    customEndTime: TTimestampFormatFromApiWithMicroS;
    isLoading: boolean;
    selectedLogs: number[];
};

/**
 * @param vsName - name of the currently selected Virtual Service.
 * @param vsAppProfileType - app profile type of the currently selected Virtual Service.
 * @param vsLogsType - type of logs for picked VS.
 * @param hasOverrideL4AppProfile - flag to indicate if VS has an override application profile.
 * @param nonSignificantLogsEnabled - flag to indicate whether the VS enables non-significant logs.
 * @param hasWafPolicy - whether the VS has WAF logs or not.
 */
export type TVsConfig = {
    vsName: string;
    vsUuid: string;
    vsAppProfileType: VsAppProfileType;
    vsLogsType: VsLogsType;
    hasOverrideL4AppProfile: boolean;
    nonSignificantLogsEnabled: boolean;
    hasWafPolicy: boolean;
    wafPolicyRef: string;
    hasTcpProxyNetProfile: boolean; // for L4 use only
};

/**
 * Hash of saved search name to its corresponding filters.
 */
export type TSavedSearchHash = {
    [name: string]: TStateFilters;
};

/**
 * @param hasFilters - flag to indicate whether the API params have filters or not.
 * @param savedSearches - searches the user has previously saved.
 * @param isCurrentFilterSaved - whether the current search filter has already been saved or not.
 */
type TFilterProps = {
    hasFilters: boolean;
    savedSearches: TSavedSearchHash;
    isCurrentFilterSaved: boolean;
};

/**
 * Parameters indicating whether the respective component's API loop is in progress;
 * this refers to data requests that repeat until all data is returned or
 * a max number of requests is reached (timeout).
 */
type TApiLoopsInProgressStatus = {
    isGraphApiLoopInProgress: boolean;
    isTableApiLoopInProgress: boolean;
};

/**
 * @param isPageCustomTimeframe - refers to whether the entire page is on a custom timeframe.
 * @param isTableCustomTimeframe
 *      refers to whether the table is on a custom timeframe.
 *      The table may be on a custom timeframe even if the page isn't (when overlay is drawn).
 */
type TCustomTimeframeStatus = {
    isPageCustomTimeframe: boolean;
    isTableCustomTimeframe: boolean;
};

/**
 * @param apiParams - full set of API params that components on the page subscribe to.
 * @param hasError - informs when page has an error.
 * @param isErrorBannerEnabled
 *      flag to indicate whether error banner should be shown, toggled by user closing the banners.
 * @param isTimedOut - inform if one of the requests has timed out.
 * @param isTimeoutBannerEnabled
 *      flag to indicate whether timeout banner should be shown, toggled by user closing the banner.
 */
type TErrorStates = {
    hasError: boolean;
    isErrorBannerEnabled: boolean;
    isTimedOut: boolean;
    isTimeoutBannerEnabled: boolean;
};

/**
 * @param apiParams - full set of API params that components on the page subscribe to.
 * @param totalLogsCount - total number of logs returned.
 * @param tableProps - properties pertaining to the table.
 * @param vsConfig - properties of the currently selected Virtual Service.
 * @param filterProps - properties pertaining to the filters.
 * @param apiLoopsInProgressStatus
 *      object containing info on whether component API loops are in progress.
 * @param customTimeframeStatus - info on which components are under a custom timeframe.
 * @param errorStates - state properties pertaining to errors and timeouts.
 */
export type TVsLogsStateTypes = {
    apiParams: IVsLogsStateParams;
    totalLogsCount: number;
    tableProps: TTableProps;
    vsConfig: TVsConfig;
    filterProps: TFilterProps;
    apiLoopsInProgressStatus: TApiLoopsInProgressStatus;
    customTimeframeStatus: TCustomTimeframeStatus;
    errorStates: TErrorStates;
};

/**
 * @description
 *  The VS Logs Store manages states that are commonly used by all the
 *  components in the VsLogs module.
 * @author Alex Klyuev, Akul Aggarwal, Zhiqian Liu
 */
@Injectable()
export class VsLogsStore extends ComponentStore<TVsLogsStateTypes> {
    // ********************************** Selectors **********************************

    public readonly nonSignificantLogsEnabled$ = this.select(
        state => state.vsConfig.nonSignificantLogsEnabled,
    );

    public readonly logParamRequestTypesSelected$: Observable<string[]> = this.select(
        ({ apiParams }) => {
            const {
                adf,
                udf,
                nf,
            } = apiParams;

            const types: string[] = [];

            if (adf) {
                types.push('significant');
            }

            if (nf || udf) {
                types.push('nonSignificant');
            }

            return types;
        },
    ).pipe(isDistinct());

    public readonly selectedLogs$ = this.select(state => state.tableProps.selectedLogs);

    public readonly tableIsLoading$ = this.select(state => state.tableProps.isLoading);

    public readonly hasError$ = this.select(state => state.errorStates.hasError);

    public readonly isErrorBannerEnabled$ = this.select(
        state => state.errorStates.isErrorBannerEnabled,
    );

    public readonly isTimedOut$ = this.select(state => state.errorStates.isTimedOut);

    public readonly isTimeoutBannerEnabled$ = this.select(
        state => state.errorStates.isTimeoutBannerEnabled,
    );

    public readonly totalLogsCount$ = this.select(state => state.totalLogsCount);

    public readonly vsName$ = this.select(({ vsConfig: { vsName } }) => vsName);

    public readonly vsUuid$ = this.select(({ vsConfig: { vsUuid } }) => vsUuid);

    public readonly vsAppProfileType$ = this.select(
        ({ vsConfig: { vsAppProfileType } }) => vsAppProfileType,
    );

    public readonly vsLogsType$ = this.select(state => state.vsConfig.vsLogsType);

    public readonly hasOverrideL4AppProfile$ = this.select(
        state => state.vsConfig.hasOverrideL4AppProfile,
    );

    public readonly hasWafPolicy$ = this.select(state => state.vsConfig.hasWafPolicy);

    public readonly wafPolicyRef$ = this.select(state => state.vsConfig.wafPolicyRef);

    public readonly hasTcpProxyNetProfile$ = this.select(
        ({ vsConfig: { hasTcpProxyNetProfile } }) => hasTcpProxyNetProfile,
    );

    public readonly filters$ = this.select(state => state.apiParams.filters);

    public readonly hasFilters$ = this.select(state => state.filterProps.hasFilters);

    public readonly savedSearches$ = this.select(state => state.filterProps.savedSearches);

    public readonly isCurrentFilterSaved$ = this.select(
        state => state.filterProps.isCurrentFilterSaved,
    );

    public readonly isGraphApiLoopInProgress$ = this.select(
        state => state.apiLoopsInProgressStatus.isGraphApiLoopInProgress,
    );

    /**
     * Selector indicating that either the graph or table is in an API loop.
     */
    public readonly isAnApiLoopInProgress$ = this.select(({ apiLoopsInProgressStatus: {
        isGraphApiLoopInProgress,
        isTableApiLoopInProgress,
    } }) => isGraphApiLoopInProgress || isTableApiLoopInProgress);

    /**
     * Selector indicating the log API in-progress status.
     */
    public readonly apiLoopsInProgressStatus$ = this.select(
        state => state.apiLoopsInProgressStatus,
    );

    /**
     * Selector for circumstances under which to show the error message banner.
     */
    public readonly showErrorMessage$ = this.select(
        this.hasError$,
        this.isErrorBannerEnabled$,
        this.isAnApiLoopInProgress$,
        (hasError, isErrorBannerEnabled, isAnApiLoopInProgress) => {
            return hasError && isErrorBannerEnabled && !isAnApiLoopInProgress;
        },
    );

    /**
     * Selector for circumstances under which to show the timeout message banner.
     */
    public readonly showTimeoutMessage$ = this.select(
        this.isTimedOut$,
        this.isTimeoutBannerEnabled$,
        this.isAnApiLoopInProgress$,
        this.hasError$,
        (isTimedOut, isTimeoutBannerEnabled, isAnApiLoopInProgress, hasError) => {
            return isTimedOut && isTimeoutBannerEnabled && !isAnApiLoopInProgress && !hasError;
        },
    );

    public readonly currentTimeframe$ = this.select(
        state => {
            const {
                duration,
                end,
            } = state.apiParams;
            const start = getUnixTimeInMilliseconds(end) - duration * 1000;
            const { aviDatePipe } = this;

            return `${aviDatePipe.transform(start)} - ${aviDatePipe.transform(end)}`;
        },
    );

    public readonly isPageCustomTimeframe$ = this.select(
        state => state.customTimeframeStatus.isPageCustomTimeframe,
    );

    public readonly isTableCustomTimeframe$ = this.select(
        state => state.customTimeframeStatus.isTableCustomTimeframe,
    );

    /**
     * Selector returning params needed for vs-log-list component.
     */
    public readonly vsLogListApiParams$: Observable<TVsLogListStateParams> = this.select(
        state => {
            const {
                adf,
                duration,
                end,
                page,
                page_size: pageSize,
                udf,
                nf,
                type,
                virtualservice,
                filters,
                orderby,
            } = state.apiParams;

            return {
                adf,
                duration: state.tableProps.customDuration || duration,
                end: state.tableProps.customEndTime || end,
                page,
                page_size: pageSize,
                udf,
                nf,
                type,
                virtualservice,
                filters,
                orderby,
            };
        },
    ).pipe(
        isDistinct(),
    );

    /**
     * Selector returning params needed for fetching individual logs.
     */
    public readonly vsLogApiParams$: Observable<TVsLogStateParams> = this.select(state => {
        const {
            adf,
            duration,
            udf,
            nf,
            type,
            virtualservice,
        } = state.apiParams;

        return {
            adf,
            duration,
            udf,
            nf,
            type,
            virtualservice,
        };
    })
        .pipe(
            isDistinct(),
        );

    /**
     * Selector returning params needed for vs-logs-graph component.
     */
    public readonly vsLogsGraphApiParams$: Observable<TVsLogsGraphStateParams> = this.select(
        state => {
            // TODO 150205 modify accordingly based on findings about page_size request param;
            // must wait for backend to resolve
            const {
                adf,
                duration,
                end,
                nf,
                step,
                udf,
                type,
                virtualservice,
                filters,
            } = state.apiParams;

            return {
                adf,
                duration,
                end,
                nf,
                // TODO Remove page_size after 150205
                page_size: 10000,
                step,
                udf,
                type,
                virtualservice,
                filters,
            };
        },
    ).pipe(
        isDistinct(),
    );

    /**
     * Selector returning params reflecting overall page params.
     */
    public readonly vsLogsPageParams$: Observable<TVsLogsPageStateParams> = this.select(
        state => {
            const {
                adf,
                duration,
                end,
                nf,
                udf,
                type,
                virtualservice,
                filters,
            } = state.apiParams;

            return {
                adf,
                duration,
                end,
                nf,
                udf,
                type,
                virtualservice,
                filters,
            };
        },
    ).pipe(
        isDistinct(),
    );

    // ****************************** Effects **********************************

    /**
     * Download all logs with the current set of parameters.
     */
    public readonly downloadAllLogs = this.effect(
        VsLogsEffectsService.createSelectorTapEffect<TVsLogListBulkDownloadStateParams>(
            this.vsLogsPageParams$,
            VsLogsApiRequestService.downloadLogs,
        ),
    );

    /**
     * Download one log entry, given log ID and service engine ID.
     */
    public readonly downloadLogEntry = this.effect<TLogEntrySignatureParams>(
        logEntryParams$ => logEntryParams$.pipe(
            withLatestFrom(this.vsLogApiParams$),
            tap(([logEntryParams, apiParams]: [TLogEntrySignatureParams, TVsLogStateParams]) => {
                const newApiParams: TVsLogStateParams = {
                    ...apiParams,
                    filters: convertLogEntryParamsToQueryStrings(logEntryParams),
                };

                VsLogsApiRequestService.downloadLogs(newApiParams);
            }),
        ),
    );

    // **************************** Constructor ******************************

    constructor(
        @Inject('Timeframe')
        private readonly timeframe: Timeframe,
        private readonly aviDatePipe: AviDatePipe,
        private readonly store: Store,
    ) {
        super();
    }

    // **************************** Updaters ******************************

    /**
     * Use lazy initialization to initialize the state.
     */
    public initVsLogsState(initState: TVsLogsStateTypes): void {
        this.setState(initState);
        this.initSavedSearches();
    }

    /**
     * Update state if VS props change.
     * TODO: change "any" to the VirtualService class once it's been converted to Typescript.
     */
    public setVsProps(virtualService: any): void {
        const vsConfig = getVsConfig(virtualService);

        const {
            vsLogsType,
            nonSignificantLogsEnabled,
        } = vsConfig;

        this.setState(state => ({
            ...state,
            apiParams: {
                ...state.apiParams,
                type: vsLogsType,
                udf: nonSignificantLogsEnabled ? state.apiParams.udf : false,
                nf: nonSignificantLogsEnabled ? state.apiParams.nf : false,
            },
            vsConfig,
        }));
    }

    /**
     * Update the VS App Profile Type.
     * Applies only to VS's that have multiple App profile types.
     */
    public setVsAppProfileType(vsAppProfileType: VsAppProfileType): void {
        // type is set to 1 for L7 and 0 for L4 and DNS
        const vsLogsType = vsAppProfileType === VsAppProfileType.L7 ?
            VsLogsType.APPLICATION :
            VsLogsType.CONNECTION;

        this.setState(state => ({
            ...state,
            apiParams: {
                ...state.apiParams,
                type: vsLogsType,
            },
            vsConfig: {
                ...state.vsConfig,
                vsAppProfileType,
                vsLogsType,
            },
        }));
    }

    /**
     * Update API params for significant logs.
     */
    public setSignificantParams(value: boolean): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                adf: value,
            },
        }));
    }

    /**
     * Update API params for non-significant logs.
     */
    public setNonSignificantParams(value: boolean): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                udf: value,
                nf: value,
            },
        }));
    }

    /**
     * Update timeframe API params.
     */
    public setDurationAndStepApiParams(
        duration: number,
        step: string,
        end = convertTimeToTTimestampFormatFromApi(Date.now()),
    ): void {
        this.setState(state => ({
            ...state,
            apiParams: {
                ...state.apiParams,
                duration,
                end,
                step,
            },
            tableProps: {
                ...state.tableProps,
                customDuration: Number(),
                customEndTime: String(),
            },
            customTimeframeStatus: {
                isPageCustomTimeframe: false,
                isTableCustomTimeframe: false,
            },
        }));
    }

    /**
     * Refresh timeframe with current time. Causes component refresh from state change.
     */
    public refreshTimeframe(): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                end: convertTimeToTTimestampFormatFromApi(Date.now()),
            },
        }));
    }

    /**
     * Sets end time and duration for table upon overlay change to trigger api refresh and reload.
     */
    public setTableCustomTimeframe(overlayCustomTimeframe: IAviBarGraphOverlayTimeframe): void {
        this.setState(state => ({
            ...state,
            tableProps: {
                ...state.tableProps,
                customDuration: overlayCustomTimeframe.duration,
                customEndTime: overlayCustomTimeframe.end,
            },
            customTimeframeStatus: {
                ...state.customTimeframeStatus,
                isTableCustomTimeframe: true,
            },
        }));
    }

    /**
     * Sets graph's timeframe to match table's custom timeframe.
     */
    public setGraphCustomTimeframe(): void {
        this.setState(state => ({
            ...state,
            apiParams: {
                ...state.apiParams,
                duration: state.tableProps.customDuration,
                step: calculateStep(state.tableProps.customDuration),
                end: state.tableProps.customEndTime,
            },
            customTimeframeStatus: {
                ...state.customTimeframeStatus,
                isPageCustomTimeframe: true,
            },
        }));

        this.timeframe.set(LogsTimeframeOptions.CUSTOM);
    }

    /**
     * Update table isLoading flag.
     */
    public setTableIsLoading(isLoading: boolean): void {
        this.patchState(state => ({
            tableProps: {
                ...state.tableProps,
                isLoading,
            },
        }));
    }

    /**
     * Add one or more filter query strings to state.
     */
    public addFilterQueryStrings(...queryStrings: string[]): void {
        this.setState(state => {
            const { filters } = state.apiParams;

            // create new object to trigger selector change detection
            let newFilters = [...filters];

            newFilters.push(...queryStrings);
            newFilters = squashFilters(newFilters, state.vsConfig.vsLogsType);

            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    filters: newFilters,
                },
                filterProps: {
                    ...state.filterProps,
                    hasFilters: true,
                    isCurrentFilterSaved: Boolean(findSavedSearch(
                        newFilters,
                        state.filterProps.savedSearches,
                    )),
                },
            };
        });
    }

    /**
     * Replace the current filters with a new set of query strings.
     */
    public replaceFilterQueryStrings(queryStrings: TStateFilters): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                filters: queryStrings,
            },
            filterProps: {
                ...state.filterProps,
                hasFilters: true,
                isCurrentFilterSaved: Boolean(findSavedSearch(
                    queryStrings,
                    state.filterProps.savedSearches,
                )),
            },
        }));
    }

    /**
     * Replace a filter with a new query string.
     */
    public editFilterQueryString(index: number, queryString: string): void {
        this.setState(state => {
            const { filters } = state.apiParams;

            // create new object to trigger selector change detection
            let newFilters = [...filters];

            newFilters[index] = queryString;
            newFilters = squashFilters(newFilters, state.vsConfig.vsLogsType);

            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    filters: newFilters,
                },
                filterProps: {
                    ...state.filterProps,
                    hasFilters: true,
                    isCurrentFilterSaved: Boolean(findSavedSearch(
                        newFilters,
                        state.filterProps.savedSearches,
                    )),
                },
            };
        });
    }

    /**
     * Add a filter object to state.
     */
    public addFilter(filter: TFilterObj): void {
        const queryString = convertFilterObjectToQueryString(filter);

        this.addFilterQueryStrings(queryString);
    }

    /**
     * Add a filter range object to state.
     */
    public addFilterRange(filter: TFilterRangeObj): void {
        const { property, start, end } = filter;

        const lowerBoundFilter = convertFilterObjectToQueryString({
            property,
            operator: start === 0 ?
                FilterOperatorType.GREATER_THAN_OR_EQUAL_TO :
                FilterOperatorType.GREATER_THAN,
            value: start,
        });

        const upperBoundFilter = convertFilterObjectToQueryString({
            property,
            operator: FilterOperatorType.LESS_THAN_OR_EQUAL_TO,
            value: end,
        });

        this.addFilterQueryStrings(lowerBoundFilter, upperBoundFilter);
    }

    /**
     * Remove a filter from state.
     */
    public removeFilter(index: number): void {
        this.setState(state => {
            const { filters } = state.apiParams;

            // create new object to trigger selector change detection
            const newFilters = [...filters];

            // remove specified filter
            newFilters.splice(index, 1);

            return {
                ...state,
                apiParams: {
                    ...state.apiParams,
                    filters: newFilters,
                },
                filterProps: {
                    ...state.filterProps,
                    hasFilters: newFilters.length > 0,
                    isCurrentFilterSaved: Boolean(findSavedSearch(
                        newFilters,
                        state.filterProps.savedSearches,
                    )),
                },
            };
        });
    }

    /**
     * Remove all filters from state.
     */
    public removeAllFilters(): void {
        this.setState(state => ({
            ...state,
            apiParams: {
                ...state.apiParams,
                filters: [],
            },
            filterProps: {
                ...state.filterProps,
                hasFilters: false,
                isCurrentFilterSaved: false,
            },
        }));
    }

    /**
     * Update table pagination API params.
     */
    public setTablePaginationProps(page: number, pageSize: number): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                page,
                page_size: pageSize,
            },
        }));
    }

    /**
     * Update table sorting API params.
     */
    public setTableSortingProps(orderby: string): void {
        this.patchState(state => ({
            apiParams: {
                ...state.apiParams,
                orderby,
            },
        }));
    }

    /**
     * Update user-selected logs.
     */
    public setSelectedLogs(selectedLogs: number[]): void {
        this.patchState(state => ({
            tableProps: {
                ...state.tableProps,
                selectedLogs,
            },
        }));
    }

    /**
     * Update total logs count.
     */
    public setTotalLogsCount(totalLogsCount: number): void {
        this.patchState({ totalLogsCount });
    }

    /**
     * Save the current filters with the given name.
     */
    public addSavedSearch(name: string): void {
        let newSavedSearches: TSavedSearchHash;

        this.setState(state => {
            // create new object to trigger selector change detection
            newSavedSearches = { ...state.filterProps.savedSearches };

            newSavedSearches[name] = state.apiParams.filters;

            return {
                ...state,
                filterProps: {
                    ...state.filterProps,
                    savedSearches: newSavedSearches,
                    isCurrentFilterSaved: true,
                },
            };
        });

        this.store.dispatch(UserPreferences.updateLogs({
            payload: {
                savedSearch: transformSavedSearches(newSavedSearches),
            },
        }));
    }

    /**
     * Edit a given saved search.
     */
    public editSavedSearch(newName: string, nameToChange: string): void {
        let newSavedSearches: TSavedSearchHash;

        this.setState(state => {
            // create new object to trigger selector change detection
            newSavedSearches = { ...state.filterProps.savedSearches };

            const search = newSavedSearches[nameToChange];

            delete newSavedSearches[nameToChange];
            newSavedSearches[newName] = search;

            return {
                ...state,
                filterProps: {
                    ...state.filterProps,
                    savedSearches: newSavedSearches,
                },
            };
        });

        this.store.dispatch(UserPreferences.updateLogs({
            payload: {
                savedSearch: transformSavedSearches(newSavedSearches),
            },
        }));
    }

    /**
     * Remove a given saved search.
     */
    public removeSavedSearch(name: string): void {
        let newSavedSearches: TSavedSearchHash;

        this.setState(state => {
            // create new object to trigger selector change detection
            newSavedSearches = { ...state.filterProps.savedSearches };

            delete newSavedSearches[name];

            return {
                ...state,
                filterProps: {
                    ...state.filterProps,
                    savedSearches: newSavedSearches,
                    isCurrentFilterSaved: Boolean(findSavedSearch(
                        state.apiParams.filters,
                        newSavedSearches,
                    )),
                },
            };
        });

        this.store.dispatch(UserPreferences.updateLogs({
            payload: {
                savedSearch: transformSavedSearches(newSavedSearches),
            },
        }));
    }

    /**
     * Below methods need to be setState instead of patchState to avoid an ngrx issue
     * where the state is outdated in patchState when multiple patchState calls to the same
     * property are invoked around the same time.
     */

    /**
     * Set the state of whether the graph is in an API loop.
     */
    public setIsGraphApiLoopInProgress(isGraphApiLoopInProgress: boolean): void {
        this.setState(state => {
            return {
                ...state,
                apiLoopsInProgressStatus: {
                    ...state.apiLoopsInProgressStatus,
                    isGraphApiLoopInProgress,
                },
            };
        });
    }

    /**
     * Set the state of whether the table is in an API loop.
     */
    public setIsTableApiLoopInProgress(isTableApiLoopInProgress: boolean): void {
        this.setState(state => ({
            ...state,
            apiLoopsInProgressStatus: {
                ...state.apiLoopsInProgressStatus,
                isTableApiLoopInProgress,
            },
        }));
    }

    /**
     * Set error state of the page.
     */
    public setHasError(hasError: boolean): void {
        this.setState(state => ({
            ...state,
            errorStates: {
                ...state.errorStates,
                hasError,
            },
        }));
    }

    /**
     * Set isTimedOut state of the page.
     */
    public setIsTimedOut(isTimedOut: boolean): void {
        this.setState(state => ({
            ...state,
            errorStates: {
                ...state.errorStates,
                isTimedOut,
            },
        }));
    }

    /**
     * Set banner state for error.
     */
    public setisErrorBannerEnabled(isErrorBannerEnabled: boolean): void {
        this.setState(state => ({
            ...state,
            errorStates: {
                ...state.errorStates,
                isErrorBannerEnabled,
            },
        }));
    }

    /**
     * Set banner state for timeout.
     */
    public setisTimeoutBannerEnabled(isTimeoutBannerEnabled: boolean): void {
        this.setState(state => ({
            ...state,
            errorStates: {
                ...state.errorStates,
                isTimeoutBannerEnabled,
            },
        }));
    }

    /**
     * Get the saved searches from backend using MyAccount service.
     */
    private initSavedSearches(): void {
        const savedSearches: TSavedSearchHash = {};

        this.store.select(UserPreferences.selectLogs)
            .pipe(take(1))
            .subscribe(({ savedSearch }) => {
                savedSearch.forEach(
                    ({ name, search }) => savedSearches[name] = search,
                );

                this.patchState(state => ({
                    filterProps: {
                        ...state.filterProps,
                        savedSearches,
                        isCurrentFilterSaved: Boolean(findSavedSearch(
                            state.apiParams.filters,
                            savedSearches,
                        )),
                    },
                }));
            });
    }
}
