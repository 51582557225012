<div class="avi-health-score-down">
    <div class="avi-health-score-down__row">
        <div class="avi-health-score-down__row-label">
            {{ globalL10nKeys.stateLabel | vtranslate }}
        </div>
        <div class="avi-health-score-down__row-value">
            {{ stateLabel }}
        </div>
    </div>
    <div class="avi-health-score-down__row">
        <div class="avi-health-score-down__row-label">
            {{ globalL10nKeys.reasonLabel | vtranslate }}
        </div>
        <div class="avi-health-score-down__row-value">
            <div *ngFor="let reason of reasons; trackBy: trackByReason">{{ reason }}</div>
        </div>
    </div>
</div>
