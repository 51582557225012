<div class="enum-radio-buttons">
    <avi-radio-container [noMarginTop]="noMarginTop">
        <ng-content></ng-content>
        <avi-radio
            *ngFor="let enumValue of enumValues; trackBy: trackByEnumValue"
            [name]="name"
            [value]="enumValue.value"
            [(ngModel)]="modelValue"
            (ngModelChange)="handleChange()"
            [disabled]="disabled"
            tooltipText="{{ enumValue.description }}"
        >
            {{ enumValue.label }}
        </avi-radio>
    </avi-radio-container>
</div>
