<div class="avi-collection-dropdown clr-wrapper">
    <avi-dropdown
        class="avi-collection-dropdown__dropdown sel-avi-collection-dropdown"
        [options]="options"
        [(ngModel)]="value"
        (ngModelChange)="handleSelect()"
        [required]="required"
        [disabled]="disabled || busy"
        [hideSearch]="hideSearch"
        [helperText]="helperText"
        placeholder="{{ placeholder }}"
        (optionsOpenedChange)="handleOptionsOpenedChange($event)"
        (onScrollEnd)="handleScrollEnd($event)"
        [busy]="collection.busy"
        [multiple]="multiple"
        (onSearch)="handleSearch($event)"
        [readonly]="readonly"
    >
        <avi-collection-dropdown-selected-values-container
            aviDropdown_selectedValuesContainer
            [value]="value"
            [multiple]="multiple"
            [selectedItemsMap]="selectedItemsMap"
            [prepend]="prepend"
            (onRemoveValue)="handleRemoveValue($event)"
        ></avi-collection-dropdown-selected-values-container>
    </avi-dropdown>
    <div
        [hidden]="!busy"
        class="avi-collection-dropdown__spinner"
    >
        <clr-spinner [clrSmall]="true"></clr-spinner>
    </div>
    <avi-dropdown-button
        class="avi-collection-dropdown__menu"
        [hideCaret]="true"
        [useIcon]="true"
        [actions]="actions"
        [position]="actionPosition"
        [disabled]="disabled && !allowActionsWhileDisabled"
        [hidden]="hideActions()"
    >
        <cds-icon
            class="avi-collection-dropdown__vertical-ellipsis"
            shape="ellipsis-vertical"
            size="24"
        ></cds-icon>
    </avi-dropdown-button>
</div>
