/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-openstack-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const openStackHeader = `${prefix}.openStackHeader`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const tenantInputLabel = `${prefix}.tenantInputLabel`;
export const tenantInputPlaceholder = `${prefix}.tenantInputPlaceholder`;
export const keystoneHostInputLabel = `${prefix}.keystoneHostInputLabel`;
export const keystoneHostInputPlaceholder = `${prefix}.keystoneHostInputPlaceholder`;
export const vipNetworkNameInputLabel = `${prefix}.vipNetworkNameInputLabel`;
export const vipNetworkNameInputPlaceholder = `${prefix}.vipNetworkNameInputPlaceholder`;
export const regionInputLabel = `${prefix}.regionInputLabel`;
export const regionInputPlaceholder = `${prefix}.regionInputPlaceholder`;

export const ENGLISH = {
    [openStackHeader]: 'OpenStack',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
    [tenantInputLabel]: 'Tenant',
    [tenantInputPlaceholder]: 'Enter Tenant',
    [keystoneHostInputLabel]: 'Keystone Host',
    [keystoneHostInputPlaceholder]: 'Enter Keystone Host',
    [vipNetworkNameInputLabel]: 'Network',
    [vipNetworkNameInputPlaceholder]: 'Enter Network',
    [regionInputLabel]: 'Region',
    [regionInputPlaceholder]: 'Enter Region',
};
