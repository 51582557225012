/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import {
    infraCloudStateFeatureKey,
    infraCloudStateReducer,
} from '.';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(infraCloudStateFeatureKey, infraCloudStateReducer),
    ],
    declarations: [],
})
export class IInfraCloudStateModule {}
