/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { VsLogsStore } from '../../services/vs-logs.store';

import './vs-log-filter-range.directive.less';

/**
 * @description
 *      Directive to style a clickable filter range and add it to store upon click.
 * @author Alex Klyuev
 */
@Directive({
    selector: '[vsLogFilterRange]',
})
export class VsLogFilterRangeDirective {
    /**
     * Property, start, and end for a filter range object.
     * See TFilterRangeObj for reference.
     */
    @Input()
    public property: string;

    @Input()
    public start: number;

    @Input()
    public end: number;

    /**
     * Event emitter for when filter is added.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        renderer.addClass(elementRef.nativeElement, 'vs-log-filter-range');
    }

    /**
     * Add filter to store upon click.
     */
    @HostListener('click')
    public addFilterRange(): void {
        this.vsLogsStore.addFilterRange({
            property: this.property,
            start: this.start,
            end: this.end,
        });

        this.onFilterAdd.emit();
    }
}
