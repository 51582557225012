/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'full-modal';
const componentName = 'referenced-objects-dialog';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const warningMessage = `${prefix}.warningMessage`;
export const referenceObjectsGridHeaderLabel = `${prefix}.referenceObjectsGridHeaderLabel`;
export const continueQuestionWarning = `${prefix}.continueQuestionWarning`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [headerLabel]: 'Referenced Object',
    [warningMessage]: 'Changes will be reflected across all instances referencing <strong>{0}</strong>. <strong>{0}</strong> is being referenced in <strong>{1}</strong> other places.',
    [referenceObjectsGridHeaderLabel]: 'Referenced Objects ({0})',
    [continueQuestionWarning]: 'Are you sure you want to continue?',
    [saveButtonLabel]: 'Yes, Continue',
};
