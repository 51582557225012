/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-list-page';
const prefix = `${moduleName}.${componentName}`;

export const successfulCloneHeader = `${prefix}.successfulCloneHeader`;
export const successfulCloneMessage = `${prefix}.successfulCloneMessage`;

export const ENGLISH = {
    [successfulCloneHeader]: 'Successfully cloned',
    [successfulCloneMessage]: 'The policy should appear in the table shortly.',
};
