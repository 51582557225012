/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import {
    Observable,
    Subject,
} from 'rxjs';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';
import { IIndividualTenantRole } from 'ajs/modules/accounts/factories/user/user.types';

import {
    UserModalIndividualTenantSettingModalComponent,
} from 'ng/lazy-loaded-components/modals/user-modal/user-modal-individual-tenant-setting-modal';

import * as l10n from './individual-tenant-setting-modal.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service to open/close the individual tenant setting modal.
 *
 * @author Nitesh Kesarkar
 */
@Injectable({
    providedIn: 'root',
})
export class IndividualTenantSettingModalService {
    /**
     * Individual tenant role submit observable.
     */
    public readonly individualTenantRoleSubmit$: Observable<IIndividualTenantRole | null>;

    /**
     * individualTenantRole object
     */
    private individualTenantRole: IIndividualTenantRole;

    /**
     * Informs if action is create or edit.
     */
    private isEditing: boolean;

    /**
     * Array of already selected IndividualTenantRef string.
     */
    private selectedIndividualTenantRefs: string[] = [];

    /**
     * Subject for individual tenant role submit.
     */
    private readonly individualTenantRoleSubmitSubject =
    new Subject<IIndividualTenantRole | null>();

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.individualTenantRoleSubmit$ = this.individualTenantRoleSubmitSubject.asObservable();
    }

    /**
     * Method to open individual tenant setting modal.
     */
    public openModal(selectedIndividualTenantRefs: string[],
                     individualTenantRole: IIndividualTenantRole, isEditing: boolean): void {
        this.selectedIndividualTenantRefs = selectedIndividualTenantRefs;
        this.individualTenantRole = individualTenantRole;
        this.isEditing = isEditing;

        this.fullModalService.addModal(this.getIndividualTenantModalProps());
    }

    /**
     * Method to close individual tenant setting modal.
     */
    public closeModal(individualTenantRole: IIndividualTenantRole | null): void {
        this.individualTenantRoleSubmitSubject.next(individualTenantRole);

        this.fullModalService
            .removeModalByComponent(
                UserModalIndividualTenantSettingModalComponent as Type<Component>,
            );
    }

    /**
     * Method to check if individual tenant setting modal is open.
     */
    public isOpen(): boolean {
        return this.fullModalService
            .isOpen(UserModalIndividualTenantSettingModalComponent as Type<Component>);
    }

    /**
     * Method to get description for full modal which is tenant name.
     */
    public getDescription(): string {
        return this.individualTenantRole.tenant_ref ?
            this.stringService.name(this.individualTenantRole.tenant_ref) : '';
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getIndividualTenantModalProps(): IFullModalLayout {
        return {
            component: UserModalIndividualTenantSettingModalComponent as Type<Component>,
            componentProps: {
                selectedIndividualTenantRefs: this.selectedIndividualTenantRefs,
                individualTenantRole: this.individualTenantRole,
                isEditing: this.isEditing,
            },
            getName: () => this.l10nService.getMessage(l10nKeys.individualTenantsLabel),
            getDescription: () => this.getDescription(),
        };
    }
}
