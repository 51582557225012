/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
    TStringRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-cloudhsm-hsm-ip-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description CloudHSM HSM IP grid component.
 * @author vgohil
 */
@Component({
    selector: 'hsm-group-cloudhsm-hsm-ip-grid',
    templateUrl: './hsm-group-cloudhsm-hsm-ip-grid.component.html',
})
export class HsmGroupCloudhsmHsmIpGridComponent implements AfterViewInit {
    /**
     * List of HSM IP Addresses.
     */
    @Input()
    public hsmIpStrings: TStringRow[];

    /**
     * Fires on Add HSM IP address.
     */
    @Output()
    public onAddHsmIp = new EventEmitter();

    /**
     * Fires on Remove HSM IP addresses.
     */
    @Output()
    public onRemoveHsmIps = new EventEmitter<TStringRow[]>();

    /**
     * TemplateRef for HSM#ip input element.
     */
    @ViewChild('hsmIpTemplateRef')
    public hsmIpTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Cloudhsm HSM IP grid config.
     */
    public hsmIpGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.hsmIpGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleIpAddress),
                    id: 'hsm-ip',
                    templateRef: this.hsmIpTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (hsmIpList: TStringRow[]) => {
                    this.onRemoveHsmIps.emit(hsmIpList);
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (hsmIp: TStringRow) => {
                    this.onRemoveHsmIps.emit([hsmIp]);
                },
            }],
        };
    }
}
