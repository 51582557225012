/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HeaderModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';
import { Subject } from 'rxjs';
import { FullModalService, IFullModalLayout } from 'full-modal-service';
import { L10nService } from '@vmw/ngx-vip';

import {
    MyAccountModalComponent,
    MyAccountPreviewComponent,
} from 'ng/modules/accounts/components/my-account-modal';

import * as l10n from './user-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service for the UserCard component.
 *
 * @author Nisar Nadaf, alextsg
 */
@Injectable()
export class UserCardService {
    /**
     * Observable used to display the User Account full name in the breadcrumb description. User can
     * modify the full name in the modal.
     */
    public description$: Subject<string>;

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to open my account modal.
     */
    public openModal(): void {
        this.description$ = new Subject<string>();
        this.fullModalService.addModal(this.getMyAccountModalProps());
    }

    /**
     * Method to close my account modal.
     */
    public closeModal(): void {
        this.description$.complete();
        this.fullModalService.removeModalByComponent(MyAccountModalComponent as Type<Component>);
    }

    /**
     * Method to check if my account modal is open.
     */
    public isOpen(): boolean {
        return this.fullModalService.isOpen(MyAccountModalComponent as Type<Component>);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getMyAccountModalProps(): IFullModalLayout {
        let description: string;
        const descriptionSubscription = this.description$.subscribe(desc => description = desc);

        return {
            component: MyAccountModalComponent as Type<Component>,
            componentProps: {
                description$: this.description$,
                onClose: () => {
                    this.closeModal();
                    descriptionSubscription.unsubscribe();
                },
            },
            previewComponent: MyAccountPreviewComponent as Type<Component>,
            getName: () => this.l10nService.getMessage(l10nKeys.myAccountLabel),
            getDescription: () => description,
        };
    }
}
