<div class="bgp-peers-page">
    <ng-content select="header"></ng-content>

    <div
        class="bgp-peers-page__peers-grid"
        *ngIf="!isLoading; else spinnerTemplate"
    >
        <avi-data-grid
            [config]="bgpPeersGridConfig"
            [rows]="bgpPeersStateList"
            gridTitle="{{ l10nKeys.peersGridHeaderLabel | vtranslate : bgpPeersCount }}"
        ></avi-data-grid>
    </div>
    <ng-container *ngIf="errors">
        <avi-alert-group alertType="danger">
            {{ errors | aviBackendErrorMsg }}
        </avi-alert-group>
    </ng-container>
</div>

<ng-template #spinnerTemplate>
    <div class="bgp-peers-page__spinner">
        <clr-spinner></clr-spinner>
    </div>
</ng-template>

<ng-template
    #statusFieldTemplateRef
    let-row
>
    <ng-container *ngIf="!isIdleOrNotApplicableState(row); else idleState">
        <cds-icon
            [status]="bgpPeerStatusHash[row.state]"
            shape="circle"
            size="18"
            solid
            avi-tooltip
            [positionsPriority]="signpostPositionPriorities"
            [tooltipControl$]="signpostCloseButtonControl$"
        >
            <ng-template #aviTooltipContent>
                <signpost
                    [signpostPosition]="signpostPosition"
                    (onCloseButtonClick)="closeSignPost()"
                >
                    <ng-template [ngTemplateOutlet]="signPostContentTemplateRef"></ng-template>
                </signpost>
            </ng-template>
        </cds-icon>
    </ng-container>

    <ng-template #idleState>
        <cds-icon
            class="bgp-peers-page__idle-bgp-peer-state"
            shape="circle"
            size="18"
            solid
            avi-tooltip
            [positionsPriority]="signpostPositionPriorities"
            [tooltipControl$]="signpostCloseButtonControl$"
        >
            <ng-template #aviTooltipContent>
                <signpost
                    [signpostPosition]="signpostPosition"
                    (onCloseButtonClick)="closeSignPost()"
                >
                    <ng-template [ngTemplateOutlet]="signPostContentTemplateRef"></ng-template>
                </signpost>
            </ng-template>
        </cds-icon>
    </ng-template>

    <ng-template #signPostContentTemplateRef>
        <h1>
            {{ bgpPeersStatesLabelAndReasonHash[row.state].label | vtranslate }}
        </h1>
        <div class="bgp-peers-page__reason">
            <div class="bgp-peers-page__reason-label">
                {{ globalL10nKeys.reasonLabel | vtranslate }}
            </div>
            {{ bgpPeersStatesLabelAndReasonHash[row.state].reason | vtranslate : row.vrfName }}
        </div>
    </ng-template>
</ng-template>
