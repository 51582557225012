<full-modal-config
    modalTitle="{{ modalHeader }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>

            <avi-data-grid
                [config]="botMappingGridConfig"
                [rows]="editable.botMappingRulesConfig"
                [invalidateForm]="editable.botMappingRulesConfig.length < 1"
            >
                <avi-data-grid_header>
                    <label
                        aviLabelWithTooltip
                        required
                    >
                        <h6>{{ l10nKeys.botMappingRulesLabel | vtranslate : editable.getRulesCount() }}</h6>
                    </label>
                </avi-data-grid_header>
                <avi-data-grid_actions>
                    <cds-button
                        size="sm"
                        type="button"
                        (click)="createBotMappingRule()"
                    >
                        {{ l10nKeys.addButtonLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>

                <ng-template
                    #expandedContentTemplateRef
                    let-row
                >
                    <match-and-action-display-wrapper>
                        <match-and-action-display-wrapper_match-content>
                            <bot-class-matcher-readonly
                                *ngIf="row.matchConfigItem.config.class_matcher"
                                [editable]="row.matchConfigItem.config.class_matcher"
                            ></bot-class-matcher-readonly>

                            <bot-type-matcher-readonly
                                *ngIf="row.matchConfigItem.config.type_matcher"
                                [editable]="row.matchConfigItem.config.type_matcher"
                            ></bot-type-matcher-readonly>

                            <path-or-query-match-readonly
                                *ngIf="row.matchConfigItem.config.identifier_matcher"
                                [editable]="row.matchConfigItem.config.identifier_matcher"
                            ></path-or-query-match-readonly>

                            <ip-addr-match-readonly
                                *ngIf="row.matchConfigItem.config.client_ip"
                                [editable]="row.matchConfigItem.config.client_ip"
                            ></ip-addr-match-readonly>

                            <http-method-match-readonly
                                *ngIf="row.matchConfigItem.config.method"
                                [editable]="row.matchConfigItem.config.method"
                            ></http-method-match-readonly>

                            <path-or-query-match-readonly
                                *ngIf="row.matchConfigItem.config.path"
                                [editable]="row.matchConfigItem.config.path"
                            ></path-or-query-match-readonly>

                            <hdr-match-readonly
                                *ngIf="row.matchConfigItem.config.hdrs"
                                [editable]="row.matchConfigItem.config.hdrs"
                            ></hdr-match-readonly>

                            <host-hdr-match-readonly
                                *ngIf="row.matchConfigItem.config.host_hdr"
                                [editable]="row.matchConfigItem.config.host_hdr"
                            ></host-hdr-match-readonly>
                        </match-and-action-display-wrapper_match-content>

                        <match-and-action-display-wrapper_action-content>
                            {{ row.classificationTypeText }}
                        </match-and-action-display-wrapper_action-content>
                    </match-and-action-display-wrapper>
                </ng-template>
            </avi-data-grid>
        </full-modal-tab-section>
    </form>
</full-modal-config>

<ng-template
    #actionTemplateRef
    let-row
>
    <div title="{{ row.classificationTypeText }}">
        {{ (l10nKeys.classifyAsLabel | vtranslate) + row.classificationTypeText }}
    </div>
</ng-template>
