/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

// Grid constants
const GRID_MIN_COLUMN_WIDTH = 40;
const GRID_TABLE_HEADER_SELECTOR = '.c-grid-table-header-cell';
const GRID_CHECKBOX_SELECTOR = `${GRID_TABLE_HEADER_SELECTOR}--field-name--select-checkbox`;
const GRID_ROWACTION_SELECTOR = `${GRID_TABLE_HEADER_SELECTOR}--field-name--single-action`;

export {
    GRID_MIN_COLUMN_WIDTH,
    GRID_TABLE_HEADER_SELECTOR,
    GRID_CHECKBOX_SELECTOR,
    GRID_ROWACTION_SELECTOR,
};
