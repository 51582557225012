/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { IOpenStackVipNetwork } from 'generated-types';
import { OpenStackVipNetwork } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

/**
 * @description
 *
 *   OpenStack VIP Network MessageItem.
 *
 * @author Sarthak kapoor
 */
export class OpenStackVipNetworkConfigItem extends
    withFullModalMixin(MessageItem)<IOpenStackVipNetwork> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: OpenStackVipNetwork,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Import lazy loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            OpenStackProviderVipNetworksModalComponent,
        } = await import(
            /* webpackChunkName: "cloud-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/cloud-modal/openstack-configuration/openstack-provider-vip-networks-modal/openstack-provider-vip-networks-modal.component'
        );

        return OpenStackProviderVipNetworksModalComponent as Type<Component>;
    }

    /**
     * In case the user enters a custom value for Provider Vip Network name,
     * we need to map it to os_network_uuid.
     */
    public setOsNetworkUuid(networkName: string): void {
        const { config } = this;

        config.os_network_uuid = networkName;
    }
}
