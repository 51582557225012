/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-client-ip-addr-group-config';
const prefix = `${moduleName}.${componentName}`;

export const clientGroupTypeLabel = `${prefix}.clientGroupTypeLabel`;
export const clientGroupIpAddressLabel = `${prefix}.clientGroupIpAddressLabel`;
export const ipAdressPrefixRangeInputPlaceholder = `${prefix}.ipAdressPrefixRangeInputPlaceholder`;

export const ENGLISH = {
    [clientGroupTypeLabel]: 'Client Group Type',
    [clientGroupIpAddressLabel]: 'Client Group IP Address ({0})',
    [ipAdressPrefixRangeInputPlaceholder]: 'IP Address or Prefix or Range',
};
