/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-access-banners-card';
const prefix = `${moduleName}.${componentName}`;

export const bannersHeader = `${prefix}.bannersHeader`;
export const messageOfTheDayLabel = `${prefix}.messageOfTheDayLabel`;
export const loginBannerLabel = `${prefix}.loginBannerLabel`;
export const messageOfTheDayDeactivatedPlaceholderValue = `${prefix}.messageOfTheDayDeactivatedPlaceholderValue`;

export const ENGLISH = {
    [bannersHeader]: 'Banners',
    [messageOfTheDayLabel]: 'Message of the Day',
    [loginBannerLabel]: 'Login Banner',
    [messageOfTheDayDeactivatedPlaceholderValue]: 'Deactivated',
};
