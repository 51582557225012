/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-and-subnet';
const prefix = `${moduleName}.${componentName}`;

export const networkSelectLabel = `${prefix}.networkSelectLabel`;
export const networkSelectPlaceholder = `${prefix}.networkSelectPlaceholder`;
export const subnetSelectLabel = `${prefix}.subnetSelectLabel`;
export const subnetDropdownPlaceholder = `${prefix}.subnetDropdownPlaceholder`;
export const subnetInputPlaceholder = `${prefix}.subnetInputPlaceholder`;

export const ENGLISH = {
    [networkSelectLabel]: 'Network',
    [networkSelectPlaceholder]: 'Select Network',
    [subnetSelectLabel]: 'Subnet',
    [subnetDropdownPlaceholder]: 'Select Subnet',
    [subnetInputPlaceholder]: 'Enter Subnet',
};
