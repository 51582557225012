/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import { IHttpPromise } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';
import { IAuthProfileConfig } from 'ajs/modules/security';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import { IAuthProfileVerifyLdapConfig } from './auth-profile-verify-ldap-modal.service.types';
import { AuthProfileVerifyLdapModalComponent } from '../components';
import * as l10n from './auth-profile-verify-ldap-modal.service.I10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const AUTH_PROFILE_LDAP_TEST_REQUEST_ID = 'authProfilLdapTest';

type THttpWrapper = typeof HttpWrapper;

/**
 * @description Service to fetch data in modal and open/close the modal.
 *
 * @author Suraj Kumar
 */
@Injectable()
export class AuthProfileVerifyLdapModalService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Returns the verify LDAP result of the profile.
     */
    public fetch(config: IAuthProfileVerifyLdapConfig, url: string): IHttpPromise<any> {
        const requestConfig = {
            url,
            method: HttpMethod.POST,
            data: config,
            requestId: AUTH_PROFILE_LDAP_TEST_REQUEST_ID,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Method to open verify LDAP modal.
     */
    public openModal(config: IAuthProfileConfig): void {
        this.fullModalService.addModal(this.getVerifyProfileModalProps(config));
    }

    /**
     * Method to close verify LDAP modal.
     */
    public closeModal(): void {
        this.fullModalService
            .removeModalByComponent(AuthProfileVerifyLdapModalComponent as Type<Component>);
    }

    /**
     * Called to destroy and cancel the pending request.
     * @override
     */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelRequest(AUTH_PROFILE_LDAP_TEST_REQUEST_ID);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getVerifyProfileModalProps(config: IAuthProfileConfig): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: AuthProfileVerifyLdapModalComponent as Type<Component>,
            componentProps: {
                authProfileConfig: config,
            },
            getName: () => l10nService.getMessage(l10nKeys.verifyProfileModalName),
            getDescription: () => config.name,
        };
    }
}
