<full-modal-config
    modalTitle="{{ l10nKeys.modalHeader | vtranslate }}"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="ip-addr"
                        objectType="SnmpTrapServer"
                        fieldName="ip_addr"
                        required
                    >
                        {{ l10nKeys.trapServerAddressLabel | vtranslate }}
                    </label>
                    <input
                        type="text"
                        id="ip-addr"
                        name="ip-addr"
                        class="sel-ip-addr"
                        clrInput
                        placeholder="{{ l10nKeys.trapServerAddressPlaceholder | vtranslate }}"
                        required
                        regexPattern="anyIPHostname"
                        [(ngModel)]="editable.config.ip_addr.address"
                        [readonly]="hasBeenSaved"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="SnmpTrapServer"
                            fieldName="version"
                            for="version"
                        >
                            {{ l10nKeys.snmpVersionLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="version"
                            [(ngModel)]="editable.config.version"
                            enum="SNMP_VER"
                            placeholder="{{ l10nKeys.snmpVersionPlaceholder | vtranslate }}"
                            [readonly]="hasBeenSaved"
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <avi-input-container *ngIf="editable.getConfig().version === 'SNMP_VER2'">
                    <label
                        aviLabelWithTooltip
                        fieldName="community"
                        objectType="SnmpTrapServer"
                    >
                        {{ l10nKeys.snmpCommunityLabel | vtranslate }}
                    </label>
                    <input
                        id="community"
                        name="community"
                        type="password"
                        clrInput
                        [(ngModel)]="editable.config.community"
                        placeholder="{{ l10nKeys.snmpCommunityPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
                <div *ngIf="editable.getConfig().version === 'SNMP_VER3'">
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            for="username"
                            objectType="SnmpV3UserParams"
                            fieldName="username"
                        >
                            {{ globalL10nKeys.usernameLabel | vtranslate }}
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            class="sel-username"
                            clrInput
                            placeholder="{{ globalL10nKeys.usernamePlaceholder | vtranslate }}"
                            [(ngModel)]="editable.user.config.username"
                        />
                    </avi-input-container>
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            fieldName="auth_passphrase"
                            objectType="SnmpV3UserParams"
                        >
                            {{ l10nKeys.authPassphraseLabel | vtranslate }}
                        </label>
                        <input
                            id="auth-passphrase"
                            name="auth-passphrase"
                            type="password"
                            clrInput
                            [(ngModel)]="editable.user.config.auth_passphrase"
                            placeholder="{{ l10nKeys.authPassphrasePlaceholder | vtranslate }}"
                        />
                    </avi-input-container>
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                objectType="SnmpV3UserParams"
                                fieldName="priv_type"
                                for="priv-type"
                            >
                                {{ l10nKeys.privacySettingsLabel | vtranslate }}
                            </label>
                            <avi-enum-dropdown
                                name="priv-type"
                                [(ngModel)]="editable.user.config.priv_type"
                                enum="SNMP_V3_PRIV"
                                placeholder="{{ l10nKeys.privacySettingsPlaceholder | vtranslate }}"
                            ></avi-enum-dropdown>
                        </div>
                    </div>
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            fieldName="priv_passphrase"
                            objectType="SnmpV3UserParams"
                        >
                            {{ l10nKeys.privacyPassphraseLabel | vtranslate }}
                        </label>
                        <input
                            id="priv-passphrase"
                            name="priv-passphrase"
                            type="password"
                            clrInput
                            placeholder="{{ l10nKeys.privacyPassphrasePlaceholder | vtranslate }}"
                            [(ngModel)]="editable.user.config.priv_passphrase"
                        />
                    </avi-input-container>
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                objectType="SnmpV3UserParams"
                                fieldName="auth_type"
                                for="auth-type"
                            >
                                {{ l10nKeys.authTypeLabel | vtranslate }}
                            </label>
                            <avi-enum-dropdown
                                name="auth-type"
                                [(ngModel)]="editable.user.config.auth_type"
                                enum="SNMP_V3_AUTH"
                                placeholder="{{ l10nKeys.authTypePlaceholder | vtranslate }}"
                            ></avi-enum-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
