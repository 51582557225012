/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'seg-update-page';
const prefix = `${moduleName}.${componentName}`;

export const segUpdateLabel = `${prefix}.segUpdateLabel`;
export const seGroupsLabel = `${prefix}.seGroupsLabel`;
export const updateSuccessfulLabel = `${prefix}.updateSuccessfulLabel`;
export const updateFailedLabel = `${prefix}.updateFailedLabel`;
export const inProgressLabel = `${prefix}.inProgressLabel`;
export const viewTranscriptLabel = `${prefix}.viewTranscriptLabel`;

export const ENGLISH = {
    [segUpdateLabel]: 'SEG Update',
    [seGroupsLabel]: 'Service Engine Groups ({0})',
    [updateSuccessfulLabel]: 'Update Successful',
    [updateFailedLabel]: 'Update Failed',
    [inProgressLabel]: 'In Progress',
    [viewTranscriptLabel]: 'View Transcript',
};
