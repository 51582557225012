/**
 * @module NetworkServicesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    createCrudCollectionGridConfig,
    ICollectionGridConfig,
} from 'ajs/js/utilities/create-crud-collection-grid-config.factory';
import {
    NetworkServiceCollection,
    NETWORK_SERVICE_COLLECTION_TOKEN,
} from '../factories/network-service.collection';
import template from './network-services-page.component.html';
import * as l10n from './network-services-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type NetworkServiceCollectionType = typeof NetworkServiceCollection;

/**
 * @description List page for Network Services.
 * @author Akul Aggarwal.
 */
class NetworkServicesPageController {
    public readonly l10nKeys = l10nKeys;
    public gridConfig: ICollectionGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        private readonly NetworkServiceCollection: NetworkServiceCollectionType,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.gridConfig = createCrudCollectionGridConfig();
        this.gridConfig.id = 'network-services-page-list-id';
        this.gridConfig.collection = new this.NetworkServiceCollection();
        this.gridConfig.fields = [{
            name: 'getConfig().name',
            title: this.l10nService.getMessage(l10nKeys.columnTitleName),
        }];
    }

    /** @override */
    public $onDestroy(): void {
        this.gridConfig.collection.destroy();
    }
}

NetworkServicesPageController.$inject = [
    'l10nService',
    NETWORK_SERVICE_COLLECTION_TOKEN,
];

export const networkServicePageOptions = {
    controller: NetworkServicesPageController,
    template,
};

export const NETWORK_SERVICE_PAGE_OPTIONS_TOKEN = 'networkServicesPage';
