/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'case-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const caseSectionLabel = `${prefix}.caseSectionLabel`;
export const subjectLabel = `${prefix}.subjectLabel`;
export const subjectPlaceholder = `${prefix}.subjectPlaceholder`;
export const contactLabel = `${prefix}.contactLabel`;
export const contactPlaceholder = `${prefix}.contactPlaceholder`;
export const typeLabel = `${prefix}.typeLabel`;
export const severityLabel = `${prefix}.severityLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const environmentLabel = `${prefix}.environmentLabel`;
export const environmentPlaceholder = `${prefix}.environmentPlaceholder`;
export const useCasesLabel = `${prefix}.useCasesLabel`;
export const useCasesPlaceholder = `${prefix}.useCasesPlaceholder`;
export const businessJustificationLabel = `${prefix}.businessJustificationLabel`;
export const businessJustificationPlaceholder = `${prefix}.businessJustificationPlaceholder`;
export const currentSolutionLabel = `${prefix}.currentSolutionLabel`;
export const currentSolutionPlaceholder = `${prefix}.currentSolutionPlaceholder`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const descriptionPlaceholder = `${prefix}.descriptionPlaceholder`;
export const customTagLabel = `${prefix}.customTagLabel`;
export const customTagPlaceholder = `${prefix}.customTagPlaceholder`;
export const attachmentsLabel = `${prefix}.attachmentsLabel`;
export const activityLabel = `${prefix}.activityLabel`;
export const criticalSeverityLabel = `${prefix}.criticalSeverityLabel`;
export const highSeverityLabel = `${prefix}.highSeverityLabel`;
export const mediumSeverityLabel = `${prefix}.mediumSeverityLabel`;
export const lowSeverityLabel = `${prefix}.lowSeverityLabel`;
export const defaultSeverityLabel = `${prefix}.defaultSeverityLabel`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Case',
    [modalTitleNew]: 'New Case',
    [caseSectionLabel]: 'Case',
    [subjectLabel]: 'Subject',
    [subjectPlaceholder]: 'Enter Subject',
    [contactLabel]: 'Contact',
    [contactPlaceholder]: 'Select Contact',
    [typeLabel]: 'Type',
    [severityLabel]: 'Severity',
    [versionLabel]: 'Version',
    [environmentLabel]: 'Ecosystem',
    [environmentPlaceholder]: 'Select Ecosystem',
    [useCasesLabel]: 'Use Cases',
    [useCasesPlaceholder]: 'Enter Use Cases',
    [businessJustificationLabel]: 'Business Justification',
    [businessJustificationPlaceholder]: 'Enter Business Justification',
    [currentSolutionLabel]: 'Current Solution',
    [currentSolutionPlaceholder]: 'Enter Current Solution',
    [descriptionLabel]: 'Description',
    [descriptionPlaceholder]: 'Enter Description',
    [customTagLabel]: 'Custom Tag',
    [customTagPlaceholder]: 'Enter Custom Tag',
    [attachmentsLabel]: 'Attachments',
    [activityLabel]: 'Activity',
    [criticalSeverityLabel]: 'Critical',
    [highSeverityLabel]: 'High',
    [mediumSeverityLabel]: 'Medium',
    [lowSeverityLabel]: 'Low',
    [defaultSeverityLabel]: 'Default',
    [submitButtonLabel]: 'Submit',
};
