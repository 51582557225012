/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Ajs dependency token for the Root Store Service.
 */
export const STORE_TOKEN = 'ngrxStoreService';
