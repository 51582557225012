/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { HSMGroupModalComponent } from 'ng/modules/security';
import { HSM_GROUP_ITEM_TOKEN } from 'ajs/modules/security/security.tokens';

/**
 * @description  Collection of HSMGroup items.
 *
 * @author vgohil
 */
export class HSMGroupCollection extends Collection {
    public static ajsDependencies = [
        HSM_GROUP_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'hardwaresecuritymodulegroup',
            windowElement: HSMGroupModalComponent,
            permissionName: AviPermissionResource.PERMISSION_HARDWARESECURITYMODULEGROUP,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(HSM_GROUP_ITEM_TOKEN);
    }
}
