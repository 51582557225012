/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AuthProfileType,
    AviPermissionResource,
    IAuthProfile,
    IAuthProfileHTTPClientParams,
    IAuthTacacsPlusAttributeValuePair,
    IOAuthProfile,
    ITacacsPlusAuthSettings,
} from 'generated-types';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { AuthProfile } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { Component, Type } from '@angular/core';
import { LdapAuthSettingsConfigItem } from './ldap-auth-settings.config-item.factory';
import { SamlSettingsConfigItem } from './saml-settings.config-item.factory';
import * as l10n from './auth-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthProfilePartial = Omit<
IAuthProfile, 'saml' | 'tacacs_plus' | 'ldap' | 'http' | 'oauth_profile'
>;
type TAuthProfileTacacsPlusPartial = Omit<ITacacsPlusAuthSettings, 'authorization_attrs'>;

export interface IAuthProfileTacacsPlusConfig extends TAuthProfileTacacsPlusPartial {
    authorization_attrs?: RepeatedMessageItem<MessageItem<IAuthTacacsPlusAttributeValuePair>>,
}

export interface IAuthProfileConfig extends TAuthProfilePartial {
    saml?: SamlSettingsConfigItem,
    tacacs_plus?: MessageItem<IAuthProfileTacacsPlusConfig>,
    ldap?: LdapAuthSettingsConfigItem,
    http?: MessageItem<IAuthProfileHTTPClientParams>,
    oauth_profile?: MessageItem<IOAuthProfile>,
}

interface IAuthProfileData {
    config: IAuthProfileConfig,
}

const OBJECT_NAME = 'authprofile';

/**
 * Hash mapping Auth Profile type to Message Item property.
 */
const typeToMessageItems = {
    [AuthProfileType.AUTH_PROFILE_TACACS_PLUS]: ['tacacs_plus'],
    [AuthProfileType.AUTH_PROFILE_SAML]: ['saml'],
    [AuthProfileType.AUTH_PROFILE_LDAP]: ['ldap', 'http'],
    [AuthProfileType.AUTH_PROFILE_OAUTH]: ['oauth_profile'],
};

/**
 * Configurable Message Item fields, corresponds to the Auth Profile type selected.
 */
const configurableMessageItemFields = [].concat(
    ...Object.values(typeToMessageItems), // Flatten string[][] into string[]
);

/**
 * @description Auth Profile Item.
 * @author Rajawant Prajapati, Suraj Kumar
 */
export class AuthProfileItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
    ];

    public data: IAuthProfileData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: OBJECT_NAME,
            objectType: AuthProfile,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_AUTHPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Set type and reset configurations on Auth Profile type change.
     */
    public setType(selectedAuthProfileType: AuthProfileType): void {
        const config = this.getConfig();

        config.type = selectedAuthProfileType;

        this.resetSubConfigurations();
    }

    /**
     * Return Auth Profile type.
     * AuthProfileType protobuf enum. Returns empty string if not set.
     */
    public getType(): AuthProfileType | '' {
        const config = this.getConfig();

        return config.type;
    }

    /** @override */
    public dataToSave(): IAuthProfile {
        const config = super.dataToSave();

        return config;
    }

    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AuthProfileModalComponent,
        } = await import(
            /* webpackChunkName: "auth-profile-modal" */
            'ng/lazy-loaded-components/modals/auth-profile-modal/auth-profile-modal.component'
        );

        return AuthProfileModalComponent as Type<Component>;
    }

    /** @override */
    public beforeEdit(): void {
        const config = this.getConfig();
        const { type } = config;
        const relevantFields = typeToMessageItems[type] || [];

        relevantFields.forEach((field: string) => this.safeSetNewChildByField(field));

        configurableMessageItemFields.forEach(field => {
            if (!relevantFields.includes(field)) {
                delete config[field];
            }
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authProfileModalBreadcrumbTitle);
    }

    /**
     * Delete irrelevant configurations and set default configuration for relevant fields on
     * Auth Profile type change.
     */
    private resetSubConfigurations(): void {
        const config = this.getConfig();
        const { type } = config;
        const relevantFields = typeToMessageItems[type] || [];

        delete config.jwt_profile_ref;
        delete config.pa_agent_ref;

        configurableMessageItemFields.forEach(field => delete config[field]);
        relevantFields.forEach((field: string) => this.setNewChildByField(field));
    }
}
