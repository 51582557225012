<div provideParentForm>

    <avi-alert-group
        *ngIf="!canAddDnsVs"
        alertType="info"
    >
        <span>{{ l10nKeys.registerControllerInfoMessage | vtranslate }}</span>
    </avi-alert-group>
    <avi-data-grid
        [config]="dnsVsGridConfig"
        [rows]="dnsVses.config"
        [isLoading]="isLoading"
        gridTitle="{{ l10nKeys.dnsVirtualServicesLabel | vtranslate : dnsVses.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                (click)="addDnsVs()"
                [disabled]="!canAddDnsVs"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #allSubdomainTemplateRef
            let-row
        >
            <div *ngIf="!row.config.domain_names">
                <cds-icon
                    status="success"
                    shape="check-circle"
                    size="18"
                ></cds-icon>
            </div>
        </ng-template>
    </avi-data-grid>
</div>
