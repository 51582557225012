/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Aravindh Nagarajan
 */

/**
 * Ajs Dependency token for IpamProfile item.
 */
export const IPAM_PROFILE_ITEM_TOKEN = 'IpamProfileItem';

/**
 * Ajs Dependency token for IpamProfile collection.
 */
export const IPAM_PROFILE_COLLECTION_TOKEN = 'IpamProfileCollection';

/**
 * Ajs Dependency token for CustomIpamProfile item.
 */
export const CUSTOM_IPAM_PROFILE_ITEM_TOKEN = 'CustomIpamDnsProfile';

/**
 * Ajs Dependency token for CustomIpamProfile collection.
 */
export const CUSTOM_IPAM_PROFILE_COLLECTION_TOKEN = 'CustomIpamDnsCollection';

/**
 * Ajs Dependency token for IpamDnsInfobloxProfileConfigItem.
 */
export const IPAM_DNS_INFOBLOX_PROFILE_CONFIG_ITEM_TOKEN = 'IpamDnsInfobloxProfileConfigItem';

/**
 * Ajs Dependency token for InfobloxSubnetConfigItem.
 */
export const INFOBLOX_SUBNET_CONFIG_ITEM_TOKEN = 'InfobloxSubnetConfigItem';

/**
 * Ajs Dependency token for IpamDnsInternalProfileConfigItem.
 */
export const IPAM_DNS_INTERNAL_PROFILE_CONFIG_ITEM_TOKEN = 'IpamDnsInternalProfileConfigItem';

/**
 * Ajs dependency token for IpamDnsAwsProfileConfigItem.
 */
export const IPAM_DNS_AWS_PROFILE_CONFIG_ITEM_TOKEN = 'IpamDnsAwsProfileConfigItem';

/**
 * Ajs dependency token for IpamDnsAzureProfileConfigItem.
 */
export const IPAM_DNS_AZURE_PROFILE_CONFIG_ITEM_TOKEN = 'IpamDnsAzureProfileConfigItem';

/**
 * Ajs dependency token for IpamDnsCustomProfileConfigItem.
 */
export const IPAM_DNS_CUSTOM_PROFILE_CONFIG_ITEM_TOKEN = 'IpamDnsCustomProfileConfigItem';

/**
 * Ajs Dependency token for CustomIpamSubnetConfigItem.
 */
export const CUSTOM_IPAM_SUBNET_CONFIG_ITEM_TOKEN = 'CustomIpamSubnetConfigItem';
