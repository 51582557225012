/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { AuthAttributeMatchConfigItem }
    from 'ajs/modules/match/factories/auth-attribute-match.config-item.factory';
import * as l10n from './auth-attribute-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for auth-attribute-match.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-attribute-match-readonly',
    templateUrl: './auth-attribute-match-readonly.component.html',
})
export class AuthAttributeMatchReadonlyComponent {
    /**
     * Binding for AuthAttributeMatch.
     */
    @Input()
    public editable: AuthAttributeMatchConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for authAttributeMatch#attribute_name.
     */
    public get attributeName(): string {
        return this.editable.config.attribute_name;
    }

    /**
     * Getter for authAttributeMatch#attribute_value_list.
     */
    public get attributeValues(): string[] {
        const {
            matchStr = [],
            stringGroupNames = [],
        } = this.editable.attributeValueList;

        return matchStr.concat(stringGroupNames);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
