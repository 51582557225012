/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviPermissionResource } from 'generated-types';
import { SsoPolicyModalComponent }
    from 'ng/modules/sso-policy/components/sso-policy-modal/sso-policy-modal.component';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { SSO_POLICY_ITEM_TOKEN } from '../sso-policy.tokens';

/**
 * @description  Collection of SsoPolicy items.
 *
 * @author Aravindh Nagarajan
 */
export class SsoPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'ssopolicy',
            windowElement: SsoPolicyModalComponent,
            permissionName: AviPermissionResource.PERMISSION_SSOPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(SSO_POLICY_ITEM_TOKEN);
    }
}

SsoPolicyCollection.ajsDependencies = [
    SSO_POLICY_ITEM_TOKEN,
];
