/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

/**
 * @description
 *      Grid component for dynamicParams instances with editable value cell.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'dynamic-params-grid',
    templateUrl: './dynamic-params-grid.component.html',
})
export class DynamicParamsGridComponent {
    /**
     * List of dynamicParams ConfigItems.
     */
    @Input()
    public paramsList: RepeatedMessageItem<MessageItem>;
}
