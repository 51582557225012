<cds-modal
    size="xl"
    (closeChange)="handleClose()"
    class="waf-learning-data clr-wrapper"
>
    <cds-modal-header>
        <h3>{{ l10nKeys.wafLearningDataHeader | vtranslate : wafPolicyName || '' }}</h3>
    </cds-modal-header>

    <avi-alert-group
        *ngIf="errors"
        alertType="warning"
    >
        <span> {{ errors | aviBackendErrorMsg }} </span>
    </avi-alert-group>
    
    <cds-modal-content>
        <div
            *ngIf="!isLearningDataLoading; else spinner"
            class="waf-learning-data__content"
        >
            <pre
                class="waf-learning-data__content-data"
                [innerHTML]="learningData | formatJson"
            ></pre>
        </div>
    </cds-modal-content>
</cds-modal>

<ng-template #spinner>
    <div class="waf-learning-data__spinner">
        <clr-spinner [clrMedium]="true"></clr-spinner>
    </div>
</ng-template>
