/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { templatesConfiguration } from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';
import { IVsLogsSignpostTemplateConfig } from 'ng/modules/vs-logs/vs-logs.types';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-default-header.component.less';

/**
 * @description Signpost header component, displays unique entries count and its respective details.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-default-header',
    templateUrl: './vs-logs-signpost-default-header.component.html',
})
export class VsLogsSignpostDefaultHeaderComponent implements OnInit {
    /**
     * Selected signpost config key.
     */
    @Input()
    public configKey: string;

    /**
     * Selected signpost respective configuration in templatesConfiguration.
     */
    public activeTemplateConfig: IVsLogsSignpostTemplateConfig;

    constructor(public readonly vsLogsSignpostStore: VsLogsSignpostStore) {}

    /** @override */
    public ngOnInit(): void {
        this.activeTemplateConfig = templatesConfiguration[this.configKey];
    }
}
