/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'tech-support';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleCreationDate = `${prefix}.columnTitleCreationDate`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleResourceName = `${prefix}.columnTitleResourceName`;
export const columnTitleDuration = `${prefix}.columnTitleDuration`;
export const columnTitleSize = `${prefix}.columnTitleSize`;
export const columnTitleResults = `${prefix}.columnTitleResults`;
export const columnTitleSupportCase = `${prefix}.columnTitleSupportCase`;
export const deleteBtnLabel = `${prefix}.deleteBtnLabel`;
export const downloadBtnLabel = `${prefix}.downloadBtnLabel`;
export const fileNameLabel = `${prefix}.fileNameLabel`;
export const errorLogLabel = `${prefix}.errorLogLabel`;
export const generateTechSupportBtnLabel = `${prefix}.generateTechSupportBtnLabel`;
export const generatingTechSupportStatusMessage = `${prefix}.generatingTechSupportStatusMessage`;
export const generatingTechSupportStatusWithTypeMessage = `${prefix}.generatingTechSupportStatusWithTypeMessage`;
export const generatingTechSupportStatusWithResourceMessage = `${prefix}.generatingTechSupportStatusWithResourceMessage`;
export const pleaseWaitStatusMessage = `${prefix}.pleaseWaitStatusMessage`;
export const techSupportCollectionCompleteLabel = `${prefix}.techSupportCollectionCompleteLabel`;
export const errorOccuredDuringTechSupportGenerationLabel = `${prefix}.errorOccuredDuringTechSupportGenerationLabel`;
export const insufficeintPermissionsHeader = `${prefix}.insufficeintPermissionsHeader`;
export const insufficeintPermissionsMessageLabel = `${prefix}.insufficeintPermissionsMessageLabel`;

export const ENGLISH = {
    [columnTitleCreationDate]: 'Creation Date',
    [columnTitleType]: 'Type',
    [columnTitleResourceName]: 'Resource Name',
    [columnTitleDuration]: 'Duration',
    [columnTitleSize]: 'Size',
    [columnTitleResults]: 'Results',
    [columnTitleSupportCase]: 'Support Case',
    [deleteBtnLabel]: 'Delete',
    [downloadBtnLabel]: 'Download',
    [fileNameLabel]: '<strong class="tech-support-expander__file-name-label">File Name</strong><span>{0}</span>',
    [errorLogLabel]: 'Error Log',
    [generateTechSupportBtnLabel]: 'Generate Tech Support',
    [generatingTechSupportStatusMessage]: 'Generating Tech Support',
    [generatingTechSupportStatusWithTypeMessage]: 'Generating {0} Tech Support',
    [generatingTechSupportStatusWithResourceMessage]: 'Generating {0} Tech Support for {1}',
    [pleaseWaitStatusMessage]: 'Please wait for completion before initiating new Tech Support collection.',
    [techSupportCollectionCompleteLabel]: '<span class="tech-support__capture-section-complete-message"><span>{0}</span> <span>Tech Support Collection Complete</span></span><div>{1}</div>',
    [errorOccuredDuringTechSupportGenerationLabel]: 'An error has occurred while generating {0} Tech Support logs, please try again.',
    [insufficeintPermissionsHeader]: 'Insufficient Permissions',
    [insufficeintPermissionsMessageLabel]: 'You do not have permission to view Tech Support files.',
};
