/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    FederationCheckpointCollection,
    TFederationCheckpointCollection,
} from 'ajs/modules/gslb/factories/federation-checkpoint.collection.factory';

import {
    FederationCheckpoint,
    TFederationCheckpoint,
} from 'ajs/modules/gslb/factories/federation-checkpoint.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    FEDERATION_CHECKPOINT_COLLECTION_TOKEN,
    FEDERATION_CHECKPOINT_TOKEN,
} from 'ajs/modules/gslb/gslb.tokens';

import { AviConfirmService } from 'ajs/modules/core/services/avi-confirm.service';
import { L10nService } from '@vmw/ngx-vip';
import { IFederationCheckpoint } from 'generated-types';
import { naturalSort } from 'ng/shared/utils/natural-sort.utils';
import { AviDatePipe } from 'ng/modules/core/pipes/avi-date.pipe';
import type { GSLB } from 'items/gslb.item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-federation-checkpoints-list.l10n';
import './gslb-federation-checkpoints-list.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description FederationCheckpoint list page.
 * @author sghare
 */
@Component({
    selector: 'gslb-federation-checkpoints-list',
    templateUrl: './gslb-federation-checkpoints-list.component.html',
})
export class GslbFederationCheckpointsListComponent implements AfterViewInit, OnInit, OnDestroy {
    /**
     * TemplateRef for activeCheckpoint field.
     */
    @ViewChild('activeCheckpointTemplateRef')
    public activeCheckpointTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Default GSLB item, resolved by UI Router.
     */
    @Input('resolveGslb')
    private gslb: GSLB;

    /**
     * Configuration object to display FederationCheckpoint collection.
     */
    public federationCheckpointGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Global Keys from source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Collection object to store the FederationCheckpoint items.
     */
    private readonly federationCheckpointCollection: FederationCheckpointCollection;

    /**
     * Creation date/time of currently active checkpoint.
     */
    private activeCheckpointCreationTime: string;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(FEDERATION_CHECKPOINT_COLLECTION_TOKEN)
        FederationCheckpointCollection: TFederationCheckpointCollection,
        @Inject(FEDERATION_CHECKPOINT_TOKEN)
        private readonly FederationCheckpoint: TFederationCheckpoint,
        private readonly aviConfirmService: AviConfirmService,
        private readonly aviDatePipe: AviDatePipe,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.federationCheckpointCollection = new FederationCheckpointCollection();
        this.federationCheckpointCollection.bind('collectionLoadSuccess', () => {
            this.setActiveFederationCheckpoint();
        });
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.federationCheckpointCollection;

        this.federationCheckpointGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.federationCheckpointCollection,
            fields: [],
            defaultSorting: 'name',
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.federationCheckpointGridConfig = {
            ...this.federationCheckpointGridConfig,
            fields: [
                {
                    id: 'timestamp',
                    sortBy: 'date',
                    label: this.l10nService.getMessage(l10nKeys.timestampLabel),
                    transform: (row: FederationCheckpoint): string =>
                        this.aviDatePipe.transform(row.date),
                },
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: FederationCheckpoint): string => row.getName(),
                },
                {
                    id: 'active-checkpoint',
                    label: this.l10nService.getMessage(l10nKeys.activeCheckpointLabel),
                    templateRef: this.activeCheckpointTemplateRef,
                },
            ],
        };
        this.setActiveCheckpointCreationTime();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.federationCheckpointCollection.destroy();
    }

    /**
     * Invoked when user tries to set checkpoint as active.
     */
    public onSetActiveCheckpoint(row: FederationCheckpoint): void {
        if (row.isActive) {
            return;
        }

        const { config: { uuid: id } } = row;
        const name = row.getName();

        this.confirmMakeCheckpointActive(name, id);
    }

    /**
     * Returns true, if checkpoint is Active or can be made Active.
     */
    public showStarIcon(row: FederationCheckpoint): boolean {
        const {
            activeCheckpointCreationTime,
            isLessRecentThanActiveCheckpoint,
        } = this;

        if (!activeCheckpointCreationTime) {
            return true;
        }

        return isLessRecentThanActiveCheckpoint(
            activeCheckpointCreationTime,
            row.date,
        ) || row.isActive;
    }

    /**
     * Set isActive flag true for active Federation Checkpoint.
     */
    public setActiveFederationCheckpoint(): void {
        this.federationCheckpointCollection.items.forEach(item => {
            item.isActive = item.id === this.gslb.activeCheckpointId;
        });
    }

    /**
     * Returns true, if checkpoint is more recent than active.
     */
    private isLessRecentThanActiveCheckpoint(
        activeCheckpointCreationTime: string,
        checkpointTime: IFederationCheckpoint['date'],
    ): boolean {
        return naturalSort(activeCheckpointCreationTime, checkpointTime) < 0;
    }

    /**
     * Sets activeCheckpointCreationTime with creation date/time of active checkpoint.
     */
    private async setActiveCheckpointCreationTime(): Promise<void> {
        const { activeCheckpointId } = this.gslb;

        if (!activeCheckpointId) {
            this.activeCheckpointCreationTime = '';

            return;
        }

        const activeCheckpoint = new this.FederationCheckpoint({
            id: activeCheckpointId,
        });

        try {
            await activeCheckpoint.load();
            this.activeCheckpointCreationTime = activeCheckpoint.date;
        } catch (e) {
            console.error(e);
        } finally {
            activeCheckpoint.destroy();
        }
    }

    /**
     * Provides confirmation dialog if user tries to make checkpoint as active.
     */
    private async confirmMakeCheckpointActive(
        name: IFederationCheckpoint['name'],
        id: IFederationCheckpoint['uuid'],
    ): Promise<void> {
        const message = this.l10nService.getMessage(l10nKeys.confirmMessage, [name]);

        try {
            await this.aviConfirmService.confirm(message);
            this.updateGslbCheckpoint(id);
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Updates gslb item to mark checkpoint as active.
     */
    private async updateGslbCheckpoint(id: IFederationCheckpoint['uuid']): Promise<void> {
        await this.gslb.setActiveReplicationCheckpoint(id);
        // Upon successful patch, gslbSite is updated, but cp table has no idea, thus:
        this.updateCheckpointsTable();
        this.setActiveCheckpointCreationTime();
    }

    /**
     * Forces update of checkpoint table.
     */
    private updateCheckpointsTable(): void {
        this.federationCheckpointCollection.load();
    }
}
