/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc controller
 * @name PoolDetailController
 * @description
 *
 *     Controller above all pool details page components.
 */
angular.module('aviApp').controller('PoolDetailController', [
'$scope', '$state', 'myAccount', 'resolvePool', 'resolveVS',
function($scope, $state, myAccount, pool, vs) {
    $scope.myAccount = myAccount;
    $scope.$state = $state;

    $scope.pool = pool;
    /** @deprecated */
    $scope.Pool = pool;

    pool.vs_ = vs;
    pool.vsId_ = vs.id;

    $scope.vs = vs;
    /** @deprecated */
    $scope.VirtualService = vs;

    $scope.$on('$destroy', () => {
        vs.destroy();
        pool.destroy();
    });
}]);
