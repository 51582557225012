/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Base } from './base.factory';

/**
 * @constructor
 * @memberOf module:avi/dataModel
 * @extends Base
 * @author Alex Malitsky, Ashish Verma, Ram Pal
 * @desc
 *     Solely data transformation layer above DataTransport. Provides just two methods - one to
 *     transform request parameters and another one to transform backend response. Should not
 *     use reference to owner_ since everything needed for processing must be passed as request
 *     parameters.
 * Base of {@link module:avi/dataModel.Revised Revised}.
 */
export class RevisedDataTransformer extends Base {
    constructor(args) {
        super(args);
        this.id = args.id;
        // USE OF THIS PROPERTY IS A WORKAROUND IN 96% AND STRONGLY DISCOURAGED,
        // pass all needed for processing data through request params and use DataSource for it
        this.owner_ = args.owner;
    }

    /**
     * Makes some preparations on request parameters before passing them to DataTransport.
     * @param {*=} params
     * @returns {*}
     * @abstract
     */
    getRequestConfig(params) {
        return params;
    }

    /**
     * Makes some basic transformation over the backend response before passing it to the
     * DataSource.
     * @param {*} resp - Backend response pre-proccessed by DataTransport.
     * @param {*} request - Request object prepared by DataTransformer#getRequestConfig for the
     *     API call we've got response for.
     * @returns {*}
     * @abstract
     */
    processResponse(resp, request) {
        return resp;
    }
}
