/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'log-timeframes';
const prefix = `${moduleName}.${componentName}`;

export const past15minutesOptionLabel = `${prefix}.past15minutesOptionLabel`;
export const pastHourOptionLabel = `${prefix}.pastHourOptionLabel`;
export const past3hoursOptionLabel = `${prefix}.past3hoursOptionLabel`;
export const past6hoursOptionLabel = `${prefix}.past6hoursOptionLabel`;
export const pastDayOptionLabel = `${prefix}.pastDayOptionLabel`;
export const pastWeekOptionLabel = `${prefix}.pastWeekOptionLabel`;
export const pastMonthOptionLabel = `${prefix}.pastMonthOptionLabel`;
export const pastQuarterOptionLabel = `${prefix}.pastQuarterOptionLabel`;
export const pastYearOptionLabel = `${prefix}.pastYearOptionLabel`;
export const allTimeOptionLabel = `${prefix}.allTimeOptionLabel`;
export const customOptionLabel = `${prefix}.customOptionLabel`;

export const ENGLISH = {
    [past15minutesOptionLabel]: 'Past 15 Minutes',
    [pastHourOptionLabel]: 'Past Hour',
    [past3hoursOptionLabel]: 'Past 3 Hours',
    [past6hoursOptionLabel]: 'Past 6 Hours',
    [pastDayOptionLabel]: 'Past Day',
    [pastWeekOptionLabel]: 'Past Week',
    [pastMonthOptionLabel]: 'Past Month',
    [pastQuarterOptionLabel]: 'Past Quarter',
    [pastYearOptionLabel]: 'Past Year',
    [allTimeOptionLabel]: 'All Time',
    [customOptionLabel]: 'Custom',
};
