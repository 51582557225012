/**
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    forwardRef,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { debounce } from 'underscore';

import { IRoleFilterMatchLabel } from 'generated-types';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './rbac-label-grid.component.l10n';

import './rbac-label-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Generic grid component for displaying object RBAC labels.
 * @author Zhiqian Liu
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RbacLabelGridComponent),
        },
    ],
    selector: 'rbac-label-grid',
    templateUrl: './rbac-label-grid.component.html',
})
export class RbacLabelGridComponent implements AfterViewInit, ControlValueAccessor {
    /**
     * Datagrid template for input field of the key in the label pair.
     */
    @ViewChild('keyFieldTemplateRef')
    public keyFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for input field of the value in the label pair.
     */
    @ViewChild('valueFieldTemplateRef')
    public valueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Contains duplicate value of the AviDataGrid.
     */
    public duplicateValues: string[] = [];

    /**
     * DataGrid config for the object list.
     */
    public listDataGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: IRoleFilterMatchLabel[] | undefined;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.findDuplicates = debounce(this.findDuplicates, 200);
    }

    /**
     * Getter for the modelValue.
     */
    public get rbacEntryList(): IRoleFilterMatchLabel[] | undefined {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. Meantime register model value change.
     */
    public set rbacEntryList(val: IRoleFilterMatchLabel[] | undefined) {
        this.modelValue = val;
        this.onChange(this.modelValue);
        this.onTouched();
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.listDataGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(this.l10nKeys.keyColumnLabel),
                    id: 'key',
                    templateRef: this.keyFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(this.l10nKeys.valuesColumnLabel),
                    id: 'values',
                    templateRef: this.valueFieldTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                onClick: (rows: IRoleFilterMatchLabel[]) => this.deleteEntries(rows),
            }],
            singleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (row: IRoleFilterMatchLabel) => this.deleteEntry(row),
            }],
        };
    }

    /**
     * Checks for duplicate error.
     */
    public get hasDuplicateError(): boolean {
        return this.duplicateValues.length > 0;
    }

    /**
     * Add a new RBAC entry.
     */
    public addEntry(): void {
        // handle undefined list from the parent
        if (!this.rbacEntryList) {
            this.rbacEntryList = [];
        }

        this.rbacEntryList = this.rbacEntryList.concat({
            key: '',
            values: [],
        });
        this.findDuplicates();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Write the modelValue when programmatic changes come.
     */
    public writeValue(rbacEntryList: IRoleFilterMatchLabel[]): void {
        this.modelValue = rbacEntryList;
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: IRoleFilterMatchLabel[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Handle and sets model changes.
     */
    public handleChange(): void {
        this.onChange(this.rbacEntryList);
        this.findDuplicates();
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: IRoleFilterMatchLabel[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /***************************************************************************/

    /**
     * To find duplicates values in grid.
     */
    private findDuplicates(): boolean {
        const uniqueElements = new Set();

        this.duplicateValues = [];

        if (!this.rbacEntryList) {
            return;
        }

        this.rbacEntryList.forEach(item => {
            if (uniqueElements.has(item.key)) {
                this.duplicateValues.push(item.key);
            } else {
                uniqueElements.add(item.key);
            }
        });
    }

    /**
     * Delete a group of RBAC entries from the list.
     */
    private deleteEntries(entries: IRoleFilterMatchLabel[]): void {
        entries.forEach(entry => this.deleteEntry(entry));
    }

    /**
     * Delete a single RBAC entry from the list.
     */
    private deleteEntry(entry: IRoleFilterMatchLabel): void {
        const copiedEntryList = this.rbacEntryList.concat();
        const index = copiedEntryList.indexOf(entry);

        if (index !== -1) {
            copiedEntryList.splice(index, 1);
        }

        this.rbacEntryList = copiedEntryList.length ? copiedEntryList : undefined;
        this.findDuplicates();
    }
}
