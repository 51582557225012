/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CloudModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { OPEN_STACK_NETWORK_ITEM_TOKEN } from '../cloud.tokens';

const dataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'OpenstackNetworkDataTransformer',
        transport: 'DataTransportByPost',
        fields: ['config'],
    },
};

/**
 * @description OpenStack Network collection.
 * @author Sarthak Kapoor
 */
export class OpenstackNetworkCollection extends Collection {
    public static ajsDependencies = [
        OPEN_STACK_NETWORK_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'openstack-get-tenant-networks',
            allDataSources: dataSources,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(OPEN_STACK_NETWORK_ITEM_TOKEN);
    }
}
