/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { CertificateManagementModalComponent } from 'ng/modules/security';
import { CERTIFICATE_MANAGEMENT_ITEM_TOKEN } from './certificate-management.item.factory';

/**
 * Ajs dependency token for CertificateManagementCollection
 */
export const CERTIFICATE_MANAGEMENT_COLLECTION_TOKEN = 'CertificateManagementCollection';

export type TCertificateManagementCollection = typeof CertificateManagementCollection;

/**
 * @description Certificate Management Collection
 * @author Ram Pal, Rajawant Prajapati
 */
export class CertificateManagementCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'certificatemanagementprofile',
            windowElement: CertificateManagementModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CERTIFICATEMANAGEMENTPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(CERTIFICATE_MANAGEMENT_ITEM_TOKEN);
    }
}

CertificateManagementCollection.ajsDependencies = [
    CERTIFICATE_MANAGEMENT_ITEM_TOKEN,
];
