/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { filter } from 'underscore';
import { VsLogsStore } from '../../services/vs-logs.store';
import { FilterOperatorType } from '../../vs-logs.types';

import './vs-log-partial-selection-filter.directive.less';

const baseClass = 'vs-log-partial-selection-filter';

/**
 * @description
 *      Directive to turn a field into a filter with the ability to select parts of the field.
 * @author Alex Klyuev
 */
@Directive({
    selector: '[vsLogPartialSelectionFilter]',
})
export class VsLogPartialSelectionFilterDirective {
    /**
     * Property, operator, and value for a filter object.
     * See TFilterObj for reference.
     */
    @Input()
    public property: string;

    @Input()
    public value: string | number | boolean;

    /**
     * Indicator of whether showing a search icon to the side when hovering over.
     */
    @Input()
    public noSearchIcon = false;

    /**
     * Position of the search icon relative to the filter.
     */
    @Input()
    public position: 'left' | 'right' = 'left';

    /**
     * Event emitter for when filter is added.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    /**
     * Sections of the filter that can be individually selected.
     */
    private filterSections: string[] = [];

    /**
     * DOM Element of the component where the directive is applied.
     */
    private domElement: HTMLElement;

    /**
     * DOM Element of a container for the filter sections.
     */
    private sectionContainer: HTMLElement;

    /**
     * DOM Element of the search icon.
     */
    private searchIcon: HTMLElement;

    /**
     * Flag to indicate whether the search icon has been added to the DOM.
     */
    private searchIconAdded = false;

    constructor(
        private readonly renderer: Renderer2,
        elementRef: ElementRef,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        this.domElement = elementRef.nativeElement;
    }

    /**
     * As the mouse moves from section to section, apply the selected styles and search icon.
     */
    @HostListener('mousemove', ['$event.target'])
    public applySelectedStyles(selectedElement: HTMLElement): void {
        const selectedIndex = this.calculateSelectedSectionIndex(selectedElement);

        // remove styles if mouse leaves filter sections
        if (selectedIndex === undefined) {
            this.removeSelectedStyles();

            return;
        }

        this.sectionContainer.childNodes.forEach((node, index) => {
            if (index <= selectedIndex) {
                this.renderer.addClass(node, `${baseClass}__selected`);
            } else {
                this.renderer.removeClass(node, `${baseClass}__selected`);
            }
        });

        if (!this.searchIconAdded && !this.noSearchIcon) {
            this.addSearchIcon();
        }
    }

    /**
     * Remove selected styles and search icon when the mouse leaves the filter.
     */
    @HostListener('mouseleave')
    public removeSelectedStyles(): void {
        this.sectionContainer.childNodes.forEach(node => {
            this.renderer.removeClass(node, `${baseClass}__selected`);
        });

        if (this.searchIconAdded) {
            this.removeSearchIcon();
        }
    }

    /**
     * Add the selected section as a filter to the store upon click.
     */
    @HostListener('click', ['$event.target'])
    public addFilter(selectedElement: HTMLElement): void {
        const selectedIndex = this.calculateSelectedSectionIndex(selectedElement);

        if (selectedIndex === undefined) {
            return;
        }

        // If section is the last one, use equal to; otherwise use starts with for the operator.
        const operator = selectedIndex === this.filterSections.length - 1 ?
            FilterOperatorType.EQUAL_TO :
            FilterOperatorType.STARTS_WITH;

        let { value } = this;

        if (typeof this.value === 'string') {
            value = '';

            for (let i = 0; i <= selectedIndex; i++) {
                value += this.filterSections[i];
            }
        }

        this.vsLogsStore.addFilter({
            property: this.property,
            operator,
            value,
        });

        this.onFilterAdd.emit();
    }

    /** @override */
    public ngOnInit(): void {
        this.renderer.addClass(this.domElement, baseClass);
        this.sectionContainer = this.renderer.createElement('span');
        this.renderer.addClass(this.sectionContainer, `${baseClass}__container`);

        this.renderer.appendChild(this.domElement, this.sectionContainer);

        let value = this.value?.toString() ?? '';

        const parts = filter(
            value.split(/[^a-zA-Z0-9]/g),
            (p: string) => Boolean(p.length),
        );

        parts.forEach(part => {
            const fragment = value.substring(0, value.indexOf(part) + part.length);

            this.filterSections.push(fragment);
            value = value.slice(fragment.length);
        });

        if (value.length) {
            this.filterSections.push(value);
        }

        this.filterSections.forEach(section => {
            const spanElement: HTMLElement = this.renderer.createElement('span');
            const textElement: HTMLElement = this.renderer.createText(section);

            this.renderer.appendChild(spanElement, textElement);
            this.renderer.appendChild(this.sectionContainer, spanElement);
        });
    }

    /**
     * Calculate the index of the currently selected section of the filter.
     */
    private calculateSelectedSectionIndex(selectedElement: HTMLElement): number | void {
        let selectedIndex;

        this.sectionContainer.childNodes.forEach((node, index) => {
            if (node === selectedElement) {
                selectedIndex = index;
            }
        });

        return selectedIndex;
    }

    /**
     * Add a search icon in the correct position.
     */
    private addSearchIcon(): void {
        this.searchIcon = this.renderer.createElement('cds-icon');
        this.renderer.setAttribute(this.searchIcon, 'shape', 'search');
        this.renderer.setAttribute(this.searchIcon, 'size', '16');
        this.renderer.addClass(
            this.searchIcon,
            `${baseClass}__search-icon-${this.position}`,
        );

        if (this.position === 'left') {
            this.renderer.insertBefore(this.domElement, this.searchIcon, this.sectionContainer);
        } else {
            this.renderer.appendChild(this.domElement, this.searchIcon);
        }

        this.searchIconAdded = true;
    }

    /**
     * Remove the search icon.
     */
    private removeSearchIcon(): void {
        this.renderer.removeChild(this.domElement, this.searchIcon);
        this.searchIconAdded = false;
    }
}
