/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { IPlacementNetwork } from 'generated-types';
import { PlacementNetwork } from 'object-types';
import { IpAddrPrefixConfigItem } from 'ajs/modules/data-model/factories';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

type TPlacementNetwork = Omit<IPlacementNetwork, 'subnet'>;

interface IPlacementNetworkConfig extends TPlacementNetwork {
    subnet: IpAddrPrefixConfigItem;
}

/**
 * @description PlacementNetwork ConfigItem.
 *
 * @author Rachit Aggarwal
 */
export class PlacementNetworkConfigItem extends MessageItem<IPlacementNetworkConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: PlacementNetwork,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Reset subnet fields.
     */
    public resetSubnet(): void {
        this.setNewChildByField('subnet');
    }

    /**
     * @override
     */
    protected canFlatten(): boolean {
        const { subnet } = this.config;

        return subnet.canFlatten();
    }
}
