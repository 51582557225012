/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-dns';
const prefix = `${moduleName}.${componentName}`;

export const dnsRequestLabel = `${prefix}.dnsRequestLabel`;
export const dnsResponseLabel = `${prefix}.dnsResponseLabel`;

export const ENGLISH = {
    [dnsRequestLabel]: 'DNS Request',
    [dnsResponseLabel]: 'DNS Response',
};
