/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpgradeModule */

import {
    IEventMap,
    IMustChecksInfo,
    ISeUpgradeEvents,
    IUpgradeStatusInfo,
    UpgradeFsmState,
} from 'generated-types';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { nodeTypesHash } from 'ng/modules/update/update.types';
import { ITUpgradeNode } from './upgrade-node.item.factory';
import { Item } from '../../data-model/factories/item.factory';
import { UPGRADE_NODE_TOKEN } from '../upgrade.tokens';

const {
    NODE_CONTROLLER_CLUSTER,
    NODE_SE_GROUP,
} = nodeTypesHash;

const allDataSources = {
    list: {
        source: 'UpgradeNodeCollectionDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * Hash to decide the precheck state priority. 1 denotes highest priority.
 */
const preCheckPriorityHash = {
    [UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS]: 1,
    [UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR]: 2,
    [UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING]: 3,
    [UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED]: 4,
    [UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS]: 5,
};

export class UpgradeNodeCollection extends Collection {
    public static ajsDependencies = [
        UPGRADE_NODE_TOKEN,
    ];
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            defaultDataFields: ['config'],
            isStatic: false,
            allDataSources,
        };

        super(extendedArgs);
        this.itemClass_ = this.getAjsDependency_(UPGRADE_NODE_TOKEN);
        this.objectName_ = 'upgradestatusinfo';
    }

    /**
     * Check if controller node is present or not.
     */
    public hasControllerNode(): boolean {
        const node = this.getControllerNode();

        return Boolean(node);
    }

    /**
     * Return a controller node.
     */
    public getControllerNode(): ITUpgradeNode {
        const node = this.items.find(
            (eachItem: ITUpgradeNode) => eachItem.isNodeType(NODE_CONTROLLER_CLUSTER),
        );

        return node;
    }

    /**
     * Return SE group node list.
     */
    public getSeGroupNodeList(): Item[] {
        return this.items.filter(node => node.isNodeType(NODE_SE_GROUP));
    }

    /**
     * Get count of se group node.
     */
    public getSeGroupCount(): number {
        return this.getSeGroupNodeList().length;
    }

    /**
     * Get controller progress percentage.
     */
    public getControllerProgressPercentage(): number {
        const node = this.getControllerNode();

        return node?.progressPercentage || NaN;
    }

    /**
     * Get upgrade events
     */
    public getUpgradeEvents(): IEventMap[] | ISeUpgradeEvents[] {
        const node = this.getControllerNode();

        return node?.upgradeEvents || [];
    }

    /**
     * Check if controller upgrade in inprogress.
     */
    public isControllerUpdateInProgress(): boolean {
        const controllerNode = this.getControllerNode();

        return controllerNode?.isUpgradeInProgress();
    }

    /**
     * Check if se group upgrade in inprogress.
     */
    public isSeGroupUpdateInProgress(): boolean {
        const seGroupNodeList = this.getSeGroupNodeList();

        return seGroupNodeList?.some(
            (seGroupNode: ITUpgradeNode) => seGroupNode?.isUpgradeInProgress(),
        );
    }

    /**
     * Check if prechecks are in progress for any of the nodes received from the backend.
     */
    public arePreChecksInProgress(): boolean {
        return this.items.some(
            node => node.arePrechecksForNodeInProgress(),
        );
    }

    /**
     * Get config of all nodes.
     */
    public getConfig(): IUpgradeStatusInfo[] {
        const configs = this.items.map(
            (node: ITUpgradeNode) => node.getUpgradeStatusConfig(),
        ) || [];

        return configs;
    }

    /**
     * Get consolidated prechecks for all the nodes.
     */
    public getConsolidatedPreChecks(nodeIds: string[]): IMustChecksInfo[] {
        const preChecks: IMustChecksInfo[] = [];

        const selectedNodes = new Set(nodeIds);

        this.items.forEach((node: ITUpgradeNode) => {
            if (selectedNodes.has(node.id)) {
                const { precheckList } = node;

                precheckList.forEach((check: IMustChecksInfo) => {
                    const index = preChecks.findIndex(
                        (preCheck: IMustChecksInfo) => preCheck.check_code === check.check_code,
                    );

                    if (index === -1) {
                        preChecks.push(check);
                    } else if (this.isHigherPriority(preChecks[index].state, check.state)) {
                        preChecks[index] = check;
                    }
                });
            }
        });

        return preChecks;
    }

    /**
     * Get overall state of prechecks for all nodes.
     */
    public getOverallPreCheckState(): UpgradeFsmState {
        let preCheckState = UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS;

        this.items.forEach((node: ITUpgradeNode) => {
            const { upgradeReadiness } = node;

            const {
                state: { state: upgradeState },
            } = upgradeReadiness;

            if (this.isHigherPriority(preCheckState, upgradeState)) {
                preCheckState = upgradeState;
            }
        });

        return preCheckState;
    }

    /**
     * Return overall state of prechecks for selected Segs.
     */
    public getSelectedSegsOverallState(segIds: string[]): UpgradeFsmState {
        let preCheckState = UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS;

        const selectedSegIds = new Set(segIds);

        this.items.forEach((node: ITUpgradeNode) => {
            if (selectedSegIds.has(node.id)) {
                const { upgradeReadiness } = node;

                const {
                    state: { state: upgradeState },
                } = upgradeReadiness;

                if (this.isHigherPriority(preCheckState, upgradeState)) {
                    preCheckState = upgradeState;
                }
            }
        });

        return preCheckState;
    }

    /**
     * Return seg nodes with only selected ids.
     */
    public getSelectedSegNodes(segIds: string[]): ITUpgradeNode[] {
        const selectedSegIds = new Set(segIds);

        return this.itemList.filter(
            (item: ITUpgradeNode) => selectedSegIds.has(item.id),
        ) as ITUpgradeNode[];
    }

    /**
     * Decide if new state has higher priority than old state.
     */
    // eslint-disable-next-line class-methods-use-this
    private isHigherPriority(oldState: UpgradeFsmState, newState: UpgradeFsmState): boolean {
        if (preCheckPriorityHash[oldState] > preCheckPriorityHash[newState]) {
            return true;
        }

        return false;
    }
}
