/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  DnsPolicyContainerConfig
 * @description  DnsPolicyContainerConfig, abstraction class for DnsPolicies in protobuf.
 */
const DnsPolicyContainerConfigFactory = (ConfigItem, DnsPolicyConfig) => {
    class DnsPolicyContainerConfig extends ConfigItem {
        constructor(args = {}) {
            super(args);

            this.transformAfterLoad_();
        }

        /**
         * Getter function to return a reference to config#policy.
         * @return {LegacyPolicyRuleConfigItem} config#policy
         */
        get policy() {
            return this.getConfig()['policy'];
        }

        /**
         * Gets the name of the ContainerConfig object.
         * @return {string|undefined}
         */
        getPolicyName() {
            return this.policy.getName();
        }

        /**
         * Sets the name of the ContainerConfig object.
         * @param {string} name - Name to set.
         */
        setPolicyName(name) {
            this.policy.setName(name);
        }

        /**
         * Has index been set or not.
         * @return {boolean}
         */
        hasIndex() {
            return 'index' in this.getConfig();
        }

        /**
         * Gets the index of the ContainerConfig object.
         * @return {number|undefined}
         */
        getIndex() {
            return this.getConfig().index;
        }

        /**
         * Sets the index of the ContainerConfig object.
         * @param {number} index
         */
        setIndex(index) {
            this.getConfig()['index'] = index;
        }

        /**
         * Transform data for use.
         */
        transformAfterLoad_() {
            const
                config = this.getConfig(),
                { dns_policy_ref_data: policyData } = config;

            if (policyData) {
                config['policy'] = new DnsPolicyConfig({
                    data: { config: policyData },
                });
            } else {
                config['policy'] = new DnsPolicyConfig();
            }

            delete config['dns_policy_ref_data'];
        }

        /** @override */
        dataToSave() {
            const
                config = angular.copy(this.getConfig()),
                { policy } = config;

            config['dns_policy_ref_data'] = policy.dataToSave();

            delete config['policy'];

            return config;
        }
    }

    return DnsPolicyContainerConfig;
};

DnsPolicyContainerConfigFactory.$inject = [
    'ConfigItem',
    'DnsPolicyConfig',
];

angular.module('aviApp').factory(
    'DnsPolicyContainerConfig', DnsPolicyContainerConfigFactory,
);
