<full-modal-config
    modalTitle="{{ l10nKeys.modalHeader | vtranslate }}"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
    class="gslb-dns-virtual-service-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="dns_vs_uuid"
                            for="dns-vs-uuid"
                            required
                        >
                            {{ l10nKeys.dnsVirtualServiceLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="dns-vs-uuid"
                            [(ngModel)]="editable.config.dnsVsRef"
                            (ngModelChange)="handleDnsVsChange($event)"
                            [collection]="dnsVsCollection"
                            [disableCreate]="true"
                            [disableEdit]="true"
                            placeholder="{{ l10nKeys.dnsVirtualServicePlaceholder | vtranslate }}"
                            required
                        ></avi-collection-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="hostAllSubdomain">
                            <avi-fieldset_header>
                                <avi-checkbox
                                    class="gslb-dns-virtual-service-modal__no-margin-top"
                                    name="host-all-subdomain"
                                    [noMarginTop]="true"
                                    [(ngModel)]="hostAllSubdomain"
                                    (ngModelChange)="resetSubdomains()"
                                >
                                    {{ l10nKeys.hostAllSubdomainsOptionLabel | vtranslate }}
                                </avi-checkbox>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <avi-repeated-dropdowns-grid
                                    [gridTitle]="l10nKeys.subdomainsLabel | vtranslate : editable.config.domain_names?.length || 0"
                                    name="domain-names"
                                    [options]="availableDomainsOptions"
                                    [(ngModel)]="editable.config.domain_names"
                                    (ngModelChange)="setAvailableDomainsOptions()"
                                ></avi-repeated-dropdowns-grid>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
