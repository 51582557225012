/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';

/**
 * @description Service for downloading file.
 * @author Abhinesh Gour
 */
@Injectable({
    providedIn: 'root',
})
export class DownloadFileService {
    /**
     * Downloads the file as per the given fileName and mimeType.
     * Default mimeType is 'text/json'.
     */
    public downloadFile(
        data: Record<string, string> | string,
        fileName: string,
        mimeType = 'text/json',
    ): void {
        if (!data) {
            throw new Error('Unable to Download, data not available');
        }

        if (typeof data === 'object') {
            data = JSON.stringify(data, undefined, 4);
        }

        const blob = new Blob([data], { type: mimeType });
        const a = document.createElement('a');

        a.download = fileName || 'file';
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = [mimeType, a.download, a.href].join(':');
        a.click();
    }
}
