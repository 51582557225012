/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import type { TEnumValueLabelsHash } from 'ajs/modules/core/services/schema-service/schema.types';
import * as l10n from './vs-log-cinematic-sub-section-ntlm.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-ntlm.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying the NTLM sub-section in the Authentication section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-ntlm',
    templateUrl: './vs-log-cinematic-sub-section-ntlm.component.html',
})
export class VsLogCinematicSubSectionNtlmComponent {
    public readonly ntlmLog$ = this.vsLogCinematicStore.ntlmLog$;

    public readonly l10nKeys = l10nKeys;

    /**
     * Key-Value map for 'NtlmStatus' enum values and display values.
     */
    public readonly ntlmStatusHash: TEnumValueLabelsHash;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.ntlmStatusHash = schemaService.getEnumValueLabelsHash('NtlmStatus');
    }
}
