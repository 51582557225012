/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name GroupCollMetric
 * @author Alex Malitsky
 * @description
 *
 *     Used by Collection Metric updates processed by {@link Collection}. Differs from {@link
 *     CollMetric} by subrequestIds and fact that Collection uses
 *     {@link CollMetricsDataTransformer} for request and response transformations.
 *
 */
angular.module('aviApp').factory('GroupCollMetric', ['CollMetric',
function(CollMetric) {
    /**
     * @param {Object} args
     * @constructor
     * @extends CollMetric
     */
    return class GroupCollMetric extends CollMetric {
        /** @override */
        getSubrequestId_() {
            const { item, name } = this;

            return `${item.getGroupCollMetricsRequestId()}:${name}`;
        }
    };
}]);
