/**
 * @module NetworkModule
 */

/***************************************************************************
* ========================================================================
* Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
* ========================================================================
*/

import {
    IDsrProfile,
    IUDPFastPathProfile,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { UDPFastPathProfile } from 'object-types';

/**
 * Interface for UDP Fast Path Profile Partial.
 */
type TUDPFastPathProfilePartial = Omit<IUDPFastPathProfile, 'dsr_profile'>;

/**
 * Interface for Extended UDP Fast Path Profile.
 */
interface IUdpFastPathProfileConfig extends TUDPFastPathProfilePartial {
    dsr_profile?: MessageItem<IDsrProfile>;
}

/**
 * @description
 *      UDP Fast Path Message Item class for setting up DSR profile.
 * @author Ratan Kumar
 */

export class UDPFastPathProfileConfigItem extends MessageItem<IUdpFastPathProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: UDPFastPathProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Remove Dsr profile on type change.
     */
    public removeDsrProfile(): void {
        delete this.config.dsr_profile;
    }

    /**
     * Create Dsr profile on type change
     */
    public createDsrProfile(): void {
        this.safeSetNewChildByField('dsr_profile');
    }
}
