/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { skip, takeWhile } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';

import * as globalL10n from 'global-l10n';
import * as moduleL10n from '../../administration-dashboard.l10n';

import { CloudServicesWidgetStore } from './cloud-services-widget.store';

import * as l10n from './cloud-services-widget.l10n';
import './cloud-services-widget.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH: moduleDictionary, ...moduleL10nKeys } = moduleL10n;

/**
 * @description
 * Widget displaying Cloud Services information. This widget has 3 views:
 *
 * Loading: widget is loading data and shows a progress bar with "Loading Widget" message.
 *
 * Unregistered: controller is not registered and user can click a "Register Now" link which
 * redirects to the Cloud Services page.
 *
 * Registered: Shows connectivity status (Up/Down) and configuration settings for IP Reputation, Bot
 * Management, and CRS Signatures.
 *
 * @author alextsg
 */
@Component({
    selector: 'cloud-services-widget',
    templateUrl: './cloud-services-widget.component.html',
    providers: [CloudServicesWidgetStore],
})
export class CloudServicesWidgetComponent implements OnInit, OnDestroy {
    /**
     * Passed from DashboardWidgetsComponent. Call to redraw the dashboard layout once the widget
     * is done loading data.
     */
    @Output()
    public redrawLayout = new EventEmitter();

    public readonly ipReputationEnabled$ = this.cloudServicesWidgetStore.ipReputationEnabled$;

    public readonly botManagementEnabled$ = this.cloudServicesWidgetStore.botManagementEnabled$;

    public readonly crsSignaturesEnabled$ = this.cloudServicesWidgetStore.crsSignaturesEnabled$;

    public readonly portalUrl$ = this.cloudServicesWidgetStore.portalUrl$;

    public readonly isRegistered$ = this.cloudServicesWidgetStore.isRegistered$;

    public readonly loading$ = this.cloudServicesWidgetStore.loading$;

    /**
     * Shows 'Up' or 'Down' based on cloud services connectivity status.
     */
    public connectivityLabel = this.l10nService.getMessage(l10nKeys.downLabel);

    /**
     * Shows a green (success) or red (danger) icon based on cloud services connectivity status.
     */
    public connectivityIconStatus = 'danger';

    public readonly l10nKeys = l10nKeys;

    public readonly moduleL10nKeys = moduleL10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly cloudServicesWidgetStore: CloudServicesWidgetStore,
        private readonly $state: StateService,
    ) {
        l10nService.registerSourceBundles({
            ...dictionary,
            ...moduleDictionary,
        });
    }

    /**
     * @override
     * Fetch the cloud services status and subscribe to 1) cloudServicesWidgetStore.loading$ in
     * order to track when the request is in progress and completed to show a loading state and 2)
     * cloudServicesWidgetStore.isConnected$ to set the connected icon and label.
     */
    public ngOnInit(): void {
        this.cloudServicesWidgetStore.loading$
            .pipe(
                // Skip the initial value of loading, which is false, so that any subsequent false
                // will be after a true value.
                skip(1),
                takeWhile((loading: boolean) => {
                    if (!loading) {
                        this.redrawLayout.emit();

                        return false;
                    }

                    return true;
                }),
            )
            .subscribe();

        this.cloudServicesWidgetStore.isConnected$
            .subscribe((isConnected: boolean) => {
                if (isConnected) {
                    this.connectivityLabel = this.l10nService.getMessage(l10nKeys.upLabel);
                    this.connectivityIconStatus = 'success';
                } else {
                    this.connectivityLabel = this.l10nService.getMessage(l10nKeys.downLabel);
                    this.connectivityIconStatus = 'danger';
                }
            });

        this.cloudServicesWidgetStore.fetchCloudServiceStatus();
    }

    /**
     * @override
     * Cancel any ongoing requests for cloud services status.
     */
    public ngOnDestroy(): void {
        this.cloudServicesWidgetStore.cancelFetchCloudServicesStatus();
    }

    /**
     * Redirect to the Cloud Services page. If the controller is unregistered then the user can
     * click a link to be redirected for registration.
     */
    public redirectToCloudServicesPage(event: Event): void {
        event.preventDefault();
        this.$state.go('authenticated.administration.cloud-services');
    }
}
