/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-email-anonymous-server-settings';
const prefix = `${moduleName}.${componentName}`;

export const fromAddressLabel = `${prefix}.fromAddressLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const portLabel = `${prefix}.portLabel`;
export const disableTlsLabel = `${prefix}.disableTlsLabel`;

export const ENGLISH = {
    [fromAddressLabel]: 'From Address',
    [smtpServerLabel]: 'SMTP Server',
    [portLabel]: 'Port',
    [disableTlsLabel]: 'Do not use TLS',
};
