/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { Scheduler } from 'ajs/modules/system/factories/scheduler.item.factory';
import { TItemConfig } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { IBackupConfiguration } from 'generated-types';
import * as l10n from './remote-backup-card.l10n';
import './remote-backup-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Card component containing an overview of the remote backup settings.
 * @author Alex Klyuev
 */

@Component({
    selector: 'remote-backup-card',
    templateUrl: './remote-backup-card.component.html',
})
export class RemoteBackupCardComponent {
    /**
     * Scheduler Item instance. Used to get backup configuration properties.
     */
    @Input()
    public scheduler: Scheduler;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for backup scheduler config object.
     */
    private get schedulerConfig(): TItemConfig {
        return this.scheduler.config;
    }

    /**
     * Getter for backup enabled property.
     */
    private get enabled(): boolean {
        return this.schedulerConfig.enabled;
    }

    /**
     * Getter for backup config object.
     */
    private get backupConfigRefData(): IBackupConfiguration {
        return this.schedulerConfig.backup_config_ref_data;
    }

    /**
     * If backups are enabled, shows whether remote backups are enabled.
     */
    public get remoteBackupEnabled(): boolean {
        return this.enabled && this.backupConfigRefData.upload_to_remote_host;
    }

    /**
     * If remote backups are configured, returns the remote backup server.
     */
    public get remoteBackupServer(): string {
        return this.backupConfigRefData.remote_hostname ?? '-';
    }

    /**
     * If remote backups are configured, returns the remote backup user credentials.
     */
    public get remoteBackupUserCredentials(): string {
        const { ssh_user_ref: sshUserRef } = this.backupConfigRefData;

        return sshUserRef ? sshUserRef.name() : '-';
    }

    /**
     * If remote backups are configured, returns the remote backup directory.
     */
    public get remoteBackupDirectory(): string {
        return this.backupConfigRefData.remote_directory ?? '-';
    }
}
