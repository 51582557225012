/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module persistenceProfileModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 * @author Suraj Kumar
 */

export const PERSISTENCE_PROFILE_ITEM_TOKEN = 'PersistenceProfile';

export const PERSISTENCE_PROFILE_COLLECTION_TOKEN = 'PersistenceProfileCollection';
