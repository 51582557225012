/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IpamDnsType } from 'generated-types';
import { Observable } from 'rxjs';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IpamDnsInfobloxProfile } from 'object-types';
import { IInfobloxCredentialsConfig }
    from 'ajs/modules/ipam/factories/ipam-dns-infoblox-profile.config-item.factory';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './ipam-dns-infoblox-credentials-dialog.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for editing Infoblox credentials.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-infoblox-credentials-dialog',
    templateUrl: './ipam-dns-infoblox-credentials-dialog.component.html',
})
export class IpamDnsInfobloxCredentialsDialogComponent {
    /**
     * True if the credentials are being edited.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Infoblox login Config.
     */
    @Input()
    public config: IInfobloxCredentialsConfig;

    /**
     * Type of IpamDnsProviderProfile (IPAMDNS_TYPE_INFOBLOX / IPAMDNS_TYPE_INFOBLOX_DNS).
     */
    @Input()
    public type: IpamDnsType;

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * EventEmitter passed in by the parent. Called when cancelling this dialog.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component.
     * Used to close this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter();

    /**
     * IpamDnsInfobloxProfile objectType.
     */
    public readonly objectType = IpamDnsInfobloxProfile;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Errors to be displayed if an error occurs after a connect attempt.
     */
    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Connect button.
     */
    public busy = false;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handles the cancel operation. Emits the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Called when the Connect button is clicked. Subscribes to the submit$ observable, and closes
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }
}
