/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-profiles-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleAutoLearn = `${prefix}.columnTitleAutoLearn`;
export const yesLabel = `${prefix}.yesLabel`;

export const ENGLISH = {
    [headerLabel]: 'Network Profiles',
    [columnTitleAutoLearn]: 'Auto Learn',
};
