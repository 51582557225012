/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './software-page.component.less';
import { UploadState } from 'ajs/modules/core/factories/upload/upload.factory';
import template from './software-page.component.html';
import * as l10n from './software-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { UPLOAD_STATE_IN_PROGRESS, UPLOAD_STATE_COMPLETE } = UploadState;

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @description
 *
 *   Component for controller software page.
 *
 * @author Zhiqian Liu, Ram Pal
 */
class SoftwarePageController {
    constructor(
        $transitions,
        Upload,
        AviConfirmService,
        ImageCollection,
        l10nService,
    ) {
        this.$transitions_ = $transitions;
        this.aviConfirmService_ = AviConfirmService;

        this.upload_ = new Upload(
            {
                destination: '/api/image/?',
            },
        );

        this.ImageCollection_ = ImageCollection;

        /**
         * Name of the file to be uploaded.
         * @type {string}
         * @protected
         */
        this.fileName_ = '';

        /**
         * Collection to hold software image object.
         * @type {module:avi/upgrade.ImageCollection|null}
         * @protected
         */
        this.imageCollection_ = null;

        /**
         * Config object for grid listing images.
         * @type {Object|null}
         * @protected
         */
        this.imageCollectionGridConfig = null;

        /**
         * Function for removing transition hook.
         * Called at component destroy.
         * @type {Function|null}
         * @protected
         */
        this.unbindTransitionStateChange_ = null;

        /**
        * Get keys from source bundles for template usage
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        const { l10nService_: l10nService } = this;

        this.imageCollection_ = new this.ImageCollection_({
            objectName: 'image-raw-inventory',
            searchFields: ['type', 'version'],
        });
        this.initImageCollectionGridConfig_();

        this.unbindTransitionStateChange_ = this.$transitions_.onBefore({}, () => {
            if (this.upload_.isInProgress()) {
                return this.aviConfirmService_
                    .prompt('software-upload-cancel-confirmation', {
                        promptText: l10nService
                            .getMessage(l10nKeys.leavingPageUploadMessage, [this.fileName_]),
                    });
            }

            return true;
        });
    }

    /**
     * Decide operations after a file is selected from local machine.
     * @param {File} file
     * @return {ng.$q.promise}
     */
    onFileSelect(file) {
        const { name: fileName } = file;

        this.fileName_ = fileName;

        return this.upload_.send(file, fileName)
            .then(rsp => {
                this.imageCollection_.load();

                return true;
            });
    }

    /**
     * Called when the close button of upload section notification is clicked.
     * @return {number}
     */
    getUploadPecentage() {
        if (this.upload_.isState(UPLOAD_STATE_COMPLETE)) {
            return 100;
        }

        // percentage suspends at 90% for the window time before the whole upload process is done
        return Math.round(this.upload_.getUploadPercentage() * 0.9);
    }

    /**
     * Decide message to be shown in notification when uploading succeeds.
     * @return {string}
     */
    getSuccessMessage() {
        const { l10nService_: l10nService } = this;

        return l10nService.getMessage(l10nKeys.uploadCompleteMessage, [this.fileName_]);
    }

    /**
     * Decide message to be shown under progress bar.
     * @return {string}
     */
    getProgressMessage() {
        const { l10nService_: l10nService } = this;

        const { state } = this.upload_;
        let progressOperation = '';

        switch (state) {
            case UPLOAD_STATE_IN_PROGRESS:
                progressOperation =
                    l10nService.getMessage(l10nKeys.uploadingStatusMessage);
                break;

            case UPLOAD_STATE_COMPLETE:
                progressOperation =
                    l10nService.getMessage(l10nKeys.processingCompleteStatusMessage);
                break;
        }

        return `${progressOperation} ${this.fileName_}`;
    }

    /**
     * Initiate grid config for image list.
     * @protected
     */
    initImageCollectionGridConfig_() {
        const { l10nService_: l10nService } = this;

        const fields = [{
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            name: 'type',
            template: require('../image-type.partial.html'),
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleVersion),
            name: 'version',
            template: require('../image-version.partial.html'),
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleAttributes),
            name: 'attributes',
            template: '{{ row.category }}',
        }];

        const singleactions = [{
            title: l10nService.getMessage(l10nKeys.deleteButtonLabel),
            class: 'icon-trash',
            do: row => this.imageCollection_.dropItems(row),
            disabled: row => row.isUsedByController(),
        }];

        const layout = {
            hideEditColumns: true,
        };

        const checkboxDisable = row => row.isUsedByController();

        const { objectName } = this.imageCollection_;

        this.imageCollectionGridConfig = {
            id: `${objectName}-list-page`,
            props: { l10nKeys },
            collection: this.imageCollection_,
            fields,
            singleactions,
            layout,
            checkboxDisable,
            permission: 'PERMISSION_IMAGE',
        };
    }

    /** @override */
    $onDestroy() {
        this.imageCollection_.destroy();

        if (this.upload_.isInProgress()) {
            this.upload_.cancelUpload();
        }

        this.unbindTransitionStateChange_();
    }
}

SoftwarePageController.$inject = [
    '$transitions',
    'Upload',
    'AviConfirmService',
    'ImageCollection',
    'l10nService',
];

export const softwarePageOptions = {
    controller: SoftwarePageController,
    template,
};
