/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DashboardModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { IWidgetConfig } from 'nsx-alb-ui-components';

import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { IWidgetConfigWithPermissions } from '../../dashboard.types';

/**
 * @description
 *     Pipe that filters a list of IWidgetConfigWithPermissions objects and returns a list of
 *     IWidgetConfig objects that the user has READ_ACCESS permissions to.
 * @example
 *     <avi-dashboard-widgets [widgetConfigs]="widgetConfigs | permittedWidgets">
 *     </avi-dashboard-widgets>
 * @author alextsg
 */

@Pipe({
    name: 'permittedWidgets',
})
export class PermittedWidgetsPipe implements PipeTransform {
    constructor(private readonly authService: Auth) {}

    public transform(widgetConfigs: IWidgetConfigWithPermissions[] = []): IWidgetConfig[] {
        return widgetConfigs
            .filter(({ permissions }) => {
                if (!permissions) {
                    return true;
                }

                return permissions.every(permission => this.authService.isAllowed(permission));
            }).map(({ permissions, ...config }) => {
                return {
                    ...config,
                };
            });
    }
}
