/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import { HealthMonitorImap } from 'object-types';

import {
    IHealthMonitorImap,
    IHealthMonitorSSLAttributes,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Health Monitor IMAPS Partial type.
 */
type THealthMonitorImapsPartial = Omit<IHealthMonitorImap, 'ssl_attributes'>;

/**
 * Interface for Health Monitor IMAPS Config.
 */
export interface IHealthMonitorImapsConfig extends THealthMonitorImapsPartial {
    ssl_attributes?: MessageItem<IHealthMonitorSSLAttributes>
}

/**
 * @description Health Monitor Imap Config Item.
 * @author Vinay Jadhav
 */
export class HealthMonitorImapConfigItem extends MessageItem<IHealthMonitorImapsConfig> {
    public folder: string;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorImap,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ssl_attributes'];
    }
}
