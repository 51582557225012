<div
    provideParentForm
    class="system-settings-banners-config"
>
    <h6 class="full-modal__section-header">
        {{ l10nKeys.bannersHeader | vtranslate }}
    </h6>

    <avi-textarea-container>
        <label
            aviLabelWithTooltip
            for="system-access-login-banner"
            [objectType]="objectType"
            fieldName="banner"
        >
            {{ l10nKeys.loginBannerLabel | vtranslate }}
        </label>
        <textarea
            clrTextarea
            id="system-access-login-banner"
            name="system-access-login-banner"
            [(ngModel)]="linuxConfig.banner"
            [placeholder]="l10nKeys.loginBannerPlaceholder | vtranslate"
        ></textarea>
    </avi-textarea-container>

    <avi-textarea-container>
        <label
            aviLabelWithTooltip
            for="system-access-message-of-the-day"
            [objectType]="objectType"
            fieldName="motd"
        >
            {{ l10nKeys.messageOfTheDayLabel | vtranslate }}
        </label>
        <textarea
            clrTextarea
            id="system-access-message-of-the-day"
            name="system-access-message-of-the-day"
            [(ngModel)]="linuxConfig.motd"
            [placeholder]="l10nKeys.messageOfTheDayPlaceholder | vtranslate"
        ></textarea>
    </avi-textarea-container>
</div>
