/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './events-list-default-summary.less';

const componentName = 'events-list-default-summary';

/**
 * @class
 * @constructor
 * @memberOf module:avi/events
 * @mixes {module:avi/events.eventsListDefaultSummaryComponentBindings}
 */
class EventsListDefaultSummaryComponentController {
    constructor(AggEventCollection, eventsListPageStatus) {
        this.AggEventCollection_ = AggEventCollection;
        this.eventsListPageStatus_ = eventsListPageStatus;
    }

    /** @override */
    $onInit() {
        const collParams = angular.extend(
            this.eventsListPageStatus_.getPageStateFilters(), {
                groupby: this.summaryConfig.id,
            },
        );

        const collection = new this.AggEventCollection_({
            limit: 10,
            params: collParams,
            sortBy: '-count',
            bind: {
                collectionLoadSuccess: this.onSizeChange,
            },
        });

        this.gridConfig = {
            collection,
            id: componentName,
            fields: [{
                title: this.summaryConfig.name,
                name: 'data.config.value',
                sortBy: this.summaryConfig.id,
                template: `
                    <span
                        class="clickable"
                        ng-show="::row.getConfig().value"
                        ng-click="config.addSearchFilter(row.getConfig())"
                    >
                      {{ ::row.getConfig().value }}
                    </span>
                    <span ng-hide="::row.getConfig().value">Other</span>`,
            }, {
                name: 'data.config.count',
                title: '#',
                sortBy: 'count',
            }, {
                name: 'data.config.percentage',
                title: '%',
            }, {
                name: 'percentage_graph',
                title: '',
                template: `
                    <div class="event-summary__progress-bar-wrapper">
                        <div
                            class="event-summary__progress-bar"
                            role="progressbar"
                            aria-valuenow="{{ ::row.getConfig().percentage }}"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style="width: {{ ::row.getConfig().percentage }}%"
                        ></div>
                    </div>`,
            }],
            addSearchFilter: config => {
                const { value, groupby } = config;

                let search = '';

                if (groupby) {
                    search += `${groupby}=`;
                }

                search += value;

                this.eventsListPageStatus_.addSearchFilter(search);

                if (angular.isFunction(this.onClose)) {
                    this.onClose();
                }
            },
            layout: {
                lengthAsTotal: true,
                hideSearch: true,
                hideEditColumns: true,
                hideTableNavigation: true,
            },
        };
    }

    /** @override */
    $onDestroy() {
        const { collection } = this.gridConfig;

        collection.destroy();
    }
}

EventsListDefaultSummaryComponentController.$inject = [
    'AggEventCollection',
    'eventsListPageStatus',
];

/**
 * @mixin eventsListDefaultSummaryComponentBindings
 * @memberOf module:avi/events
 * @property {Function} onClose
 * @property {Function} onSizeChange - Called on the collection load.
 * @property {{id: string, name: string}} summaryConfig
 */
const bindings = {
    onClose: '&',
    onSizeChange: '&',
    summaryConfig: '<',
};

/**
 * @name eventsListDefaultSummaryComponent
 * @memberOf module:avi/events
 * @property {module:avi/events.eventsListDefaultSummaryComponentBindings} bindings
 * @property {module:avi/events.EventsListDefaultSummaryComponentController} controller
 * @desc
 *
 *     Default component for Events List Summary popover. Uses collection grid and
 *     eventListPageStatus to get collection parameters and pass an updated search filter to
 *     EventsListController.
 *
 * @author Alex Malitsky
 */

angular.module('avi/events')
    .component('eventsListDefaultSummary', {
        bindings,
        controller: EventsListDefaultSummaryComponentController,
        templateUrl: `src/components/applications/${componentName}/${componentName}.html`,
    });
