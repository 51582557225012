<avi-card class="license-card">
    <avi-card_header>
        <div class="license-card__header">
            <div class="license-card__title">
                <cds-icon 
                    shape="certificate" 
                    status="success" 
                    size="24"
                ></cds-icon>
                {{ l10nKeys.licenseTitle | vtranslate }}
            </div>
            {{ licenseTierTitle }}
        </div>   
  </avi-card_header>
  <avi-card_body>
    <div 
      class="license-card__license-body" 
      *ngIf="!isBusy; else loaderTemplate"
    >
        <ng-container>
            <div class="license-card__controller-usage">
                <div class="license-card__controller-header">{{ globalL10nKeys.controllerLabel | vtranslate }}</div>
                <avi-usage-bar
                    [usedValue]="usedServiceCores"
                    [maxAllowedValue]="maxControllerUnitsValue"
                    [reservedValue]="reservedControllerUnitsValue"
                    [isLeftIndicatorRequired]="true"
                    [isHeaderRequired]="true"
                    [isFooterRequired]="true"
                    [usedUnitsLabel]="l10nKeys.usedLabel | vtranslate"
                    [maxAllowedUnitsLabel]="l10nKeys.maxLabel | vtranslate"
                    [reservedUnitsLabel]="l10nKeys.reservedLabel | vtranslate"
                ></avi-usage-bar>
                <license-card-usage
                    [controllerUsageLabel]="l10nKeys.controllerUsedLabel | vtranslate"
                    [controllerUsageValue]="usedServiceCores"
                    [controllerTooltipText]="l10nKeys.controllersInUseTooltipText | vtranslate"
                ></license-card-usage>
                <license-card-usage
                    [controllerUsageLabel]="l10nKeys.controllerReservedLabel | vtranslate"
                    [controllerUsageValue]="reservedControllerUnitsValue"
                    [controllerTooltipText]="l10nKeys.controllersInReserveTooltipText | vtranslate"
                ></license-card-usage>
                <license-card-usage
                    [controllerUsageLabel]="l10nKeys.controllerMaxAllowedTooltipText | vtranslate"
                    [controllerUsageValue]="maxControllerUnitsValue"
                    [controllerTooltipText]="l10nKeys.maximumControllersLabel | vtranslate"
                ></license-card-usage>   
            </div>
            <div>
                <div class="license-card__organization-header">{{ l10nKeys.organizationTitle | vtranslate }}</div>
                <avi-usage-bar
                    [usedValue]="usedOrgUnitsValue"
                    [maxAllowedValue]="maxOrgUnitsValue"
                    [isLeftIndicatorRequired]="false"
                    [isHeaderRequired]="true"
                    [isFooterRequired]="false"
                    [usedUnitsLabel]="l10nKeys.usedLabel | vtranslate"
                    [maxAllowedUnitsLabel]="l10nKeys.maxLabel | vtranslate"
                    [reservedUnitsLabel]="l10nKeys.reservedLabel | vtranslate"
                ></avi-usage-bar>
                <div class="license-card__organization-usage">
                    <label class="license-card__usage-label">{{ l10nKeys.organizationUsedLabel | vtranslate }}</label>
                    <label 
                        aviLabelWithTooltip 
                        tooltipText="{{ l10nKeys.organizationsInUseLabel | vtranslate }}"
                        class="license-card__tooltip"
                    ></label>
                    <div>
                        <span class="license-card__usage-count">{{ orgServiceUnits.used_service_units }}</span>
                        <span class="license-card__organization-max-count">/{{ orgServiceUnits.available_service_units }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #loaderTemplate>
        <div class="license-card__spinner">
            <clr-spinner clrMedium></clr-spinner>
        </div>
    </ng-template>
  </avi-card_body>
</avi-card>
