<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="auth-attribute-match"
        provideParentForm
    >
        <div class="auth-attribute-match__name-container">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ matchObjectType }}"
                    fieldName="attribute_name"
                    for="attribute_name"
                    required
                >
                    {{ l10nKeys.attributeNameLabel | vtranslate }}
                </label>
                <input
                    id="{{ 'attribute-name-' + matchIndex }}"
                    name="{{ 'attribute-name-' + matchIndex }}"
                    [(ngModel)]="editable.config.attribute_name"
                    clrInput
                    placeholder="{{ l10nKeys.attributeNamePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>

        <div class="auth-attribute-match__string-container">
            <string-match-config
                [editable]="editable.attributeValueList"
                id="{{ 'auth-string-match-' + matchIndex }}"
                
            ></string-match-config>
        </div>
    </div>
</match-wrapper>
