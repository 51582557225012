/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    Type,
} from '@angular/core';
import { DialogService } from 'dialog-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './credentials-verification.l10n';
import './credentials-verification.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const CREDENTIALS_VERIFICATION_DIALOG_ID = 'CREDENTIALS_VERIFICATION_DIALOG_ID';

export interface IEditCredentialsConfig {
    editCredentialsDialog: Type<Component>;
    editCredentialsDialogProps: Record<string, any>;
}

/**
 * @description
 *     Component for verifying a set of fields related to credentials. Renders a "Connect" button to
 *     open up a dialog modal to modify those fields. The readonly form is passed via transclusion
 *     while the actual form for modification needs to be defined in a separate component and passed
 *     to the config.
 * @author alextsg
 */
@Component({
    selector: 'credentials-verification',
    templateUrl: './credentials-verification.component.html',
})
export class CredentialsVerificationComponent {
    /**
     * If true, sets the state of the button to "connect" onInit.
     */
    @Input()
    public isEditing = false;

    /**
     * If true, remove the button to open Modal.
     * Can be Used when Credentials are editable only once.
     */
    @Input()
    public hideChangeCredentialsButton = false;

    /**
     * Label for credentials button, if Set use it else fallback to default.
     */
    @Input()
    public credentialsButtonLabel: string;

    /**
     * Config object containing the dialog component and props to passed to the dialog component.
     */
    @Input()
    public editCredentialsConfig: IEditCredentialsConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly dialogService: DialogService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the connect button is clicked. Opens up the dialog component. Provides the
     * closeDialog method to the dialog component to close itself.
     */
    public handleConnect(): void {
        const {
            editCredentialsDialog,
            editCredentialsDialogProps,
        } = this.editCredentialsConfig;

        this.dialogService.add({
            id: CREDENTIALS_VERIFICATION_DIALOG_ID,
            component: editCredentialsDialog,
            componentProps: {
                closeDialog: () => this.dialogService.remove(CREDENTIALS_VERIFICATION_DIALOG_ID),
                ...editCredentialsDialogProps,
            },
        });
    }

    /**
     * Returns label for submit button.
     */
    public getCredentialsButtonLabel(): string {
        return this.credentialsButtonLabel ||
            this.l10nService.getMessage(this.l10nKeys.changeCredentialsButtonLabel);
    }
}
