/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * For generic VS faults, the common denominator between faults is the description field.
 */
export interface IFault {
    description: string;
}

/**
 * Server faults have a pool_ref to indicate which pool the servers with faults belong to.
 */
export interface IServerFault {
    pool_ref: string;
    servers: IFault[];
}

/**
 * Hash of the type of fault to the list of faults.
 */
export type TVirtualServiceFaults = {
    [type: string]: IServerFault[] | IFault[];
};

/**
 * Fault state for each VS uuid.
 */
interface IVirtualServiceFaultsState {
    dismissed: boolean;
    faults: TVirtualServiceFaults;
}

/**
 * Virtual Service feature store.
 */
export interface IVirtualServiceState {
    faults: { [vsId: string]: IVirtualServiceFaultsState };
}

export const initialState: IVirtualServiceState = {
    faults: {},
};
