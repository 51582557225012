/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @file
 *
 * Constants used in core modules
 *
 * @author Aravindh Nagarajan
 */
export const PASS_CHANGE_URL = '/api/passwordchange';
export const ADMIN_TENANT_NAME = 'admin';
export const ADDRESSED_RELOAD_REQUESTS = 'ADDRESSED_RELOAD_REQUESTS';
export const LOGIN_URL = '/login?include_name=true';
export const LOGOUT_URL = '/logout';
export const PASS_CHANGE_REQ_URL = `${PASS_CHANGE_URL}request`;
export const SET_TENANT_URL = '/api/switch-to-tenant';
export const USER_PROFILE_URL = '/api/user-tenant-list?include_name';
export const SESSION_REFRESH_URL = '/api/refresh-session';
export const STORAGE_LAST_ACTIVE_STATE = 'STORAGE_LAST_ACTIVE_STATE';
export const ADMIN_USER_SETUP_URL = '/api/useraccount';
export const ALL_TENANTS = '*';

// localStorage props
export const STORAGE_LAST_USER_ACTIVITY = 'STORAGE_LAST_USER_ACTIVITY';
export const STORAGE_USER_PROFILE = 'STORAGE_USER_PROFILE';
export const STORAGE_USERNAME_BEFORE_LOGOUT = 'STORAGE_USERNAME_BEFORE_LOGOUT';
export const STORAGE_USER_PROFILE_LOCAL_STORAGE = 'STORAGE_USER_PROFILE_LOCAL_STORAGE';

export const USER_ACTIVITY_STEP = 10 * 1000; // ms
