/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorHttp,
    HealthMonitorSSLAttributes,
} from 'object-types';

import { HealthMonitorAuthType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';

import {
    HealthMonitorHttpConfigItem,
    HealthMonitorItem,
} from 'ajs/modules/healthmonitor/factories';

import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
    TPkiProfileCollection,
} from 'ajs/modules/security';

import * as globalL10n from 'global-l10n';
import * as l10n from './http-health-monitor-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * SSLProfileCollection Type.
 */
type TSslProfileCollection = typeof SSLProfileCollection;

/**
 * CertificateCollection Type.
 */
type TCertificateCollection = typeof CertificateCollection;

/**
 * @description Health Monitor Config Component for HTTP and HTTPS Health Monitor Types.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'http-health-monitor-config',
    templateUrl: './http-health-monitor-config.component.html',
})
export class HttpHealthMonitorConfigComponent implements OnInit, OnDestroy {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    @Input()
    public isFederated = false;

    /**
     * Health Monitor config type. This can be either http_monitor or https_monitor.
     */
    public httpConfigItem: HealthMonitorHttpConfigItem;

    /**
     * Flag to show fields applicable only for HTTPS type.
     */
    public isHttpsType = false;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorHttp,
        HealthMonitorSSLAttributes,
    };

    /**
     * SSLProfileCollection instance.
     */
    public readonly sslProfileCollection: SSLProfileCollection;

    /**
     * CertificateCollection instance.
     */
    public readonly certificateCollection: CertificateCollection;

    /**
     * PKIProfileCollection instance.
     */
    public readonly pkiProfileCollection: PKIProfileCollection;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get global keys from source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(SSLProfileCollection)
        SSLProfileCollection: TSslProfileCollection,
        @Inject(CertificateCollection)
        CertificateCollection: TCertificateCollection,
        @Inject(PKIProfileCollection)
        PKIProfileCollection: TPkiProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.sslProfileCollection = new SSLProfileCollection();
        this.certificateCollection = new CertificateCollection();
        this.pkiProfileCollection = new PKIProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editable.isHttpsType()) {
            this.isHttpsType = true;
        }

        this.httpConfigItem = this.editable.healthMonitorTypeConfig;
    }

    /**
     * Used to disable Use Exact Request checkbox if returns true.
     */
    public get disableUseExactRequestCheckbox(): boolean {
        const { config } = this.httpConfigItem;
        const { auth_type: authType } = config;

        return authType === HealthMonitorAuthType.AUTH_NTLM;
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { isFederated } = changes;
        const params = {
            is_federated: isFederated?.currentValue,
        };

        this.pkiProfileCollection.setParams(params);
        this.sslProfileCollection.setParams(params);
        this.certificateCollection.setParams(params);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
        this.certificateCollection.destroy();
        this.pkiProfileCollection.destroy();
    }
}
