<div
    class="gslb-ip-address-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="ipAddrGridConfig"
        [rows]="ipAddr.config"
        [invalidateForm]="ipAddr.count < 1"
    >
        <avi-data-grid_header>
            <h6>
                {{ l10nKeys.siteControllerLabel | vtranslate : ipAddr.count }}
                <span class="gslb-ip-address-grid__mandatory-indicator">*</span>
            </h6>
        </avi-data-grid_header>
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addIpAddress()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #ipAddrTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    clrInput
                    [(ngModel)]="row.address"
                    name="{{ 'ip-addr-' + index }}"
                    placeholder="{{ globalL10nKeys.ipAddressPlaceholder | vtranslate }}"
                    regexPattern="ip"
                    required
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
