/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ALBServicesConnectivityStatus,
    ALBServicesRegistrationStatus,
    IALBServicesStatus,
} from 'generated-types';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { StateService } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';
import { CportalService } from 'ajs/modules/cportal/services/cportal.service';

import {
    Case,
    CaseCollection,
} from 'ajs/modules/cportal/factories';

import { AviDatePipe } from 'ng/modules/core/pipes/avi-date.pipe';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

import * as globalL10n from 'global-l10n';
import * as l10n from './case-list-page.l10n';

type TCaseCollection = typeof CaseCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Cloud services page ui-router state.
 */
const CLOUD_SERVICES_PAGE_STATE = 'authenticated.administration.cloud-services';

/**
 * @description Component for case list.
 * @author Shahab Hashmi
 */
@Component({
    selector: 'case-list-page',
    templateUrl: './case-list-page.component.html',
})
export class CaseListPageComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display cases grid.
     */
    public caseListGridConfig: IAviCollectionDataGridConfig;

    /**
     * Store the controller's registration status.
     */
    public isControllerRegistered = false;

    /**
     * Store the avi cloud connectivity status.
     */
    public isAviCloudConnected = false;

    /**
     * Store pulse feature opt-in for case management.
     */
    public isPulseCaseManagementEnabled = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Collection object to store the cases collection.
     */
    private readonly caseListCollection: CaseCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(CaseCollection)
        CaseListCollection: TCaseCollection,
        private readonly aviDate: AviDatePipe,
        private readonly cPortalService: CportalService,
        private readonly $state: StateService,
        private readonly devLoggerService: DevLoggerService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.caseListCollection = new CaseListCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.fetchPulseFeatureOptInDetails();

        const { cPortalService } = this;

        this.updateControllerStatus(cPortalService.getRegistrationStatusDetails());

        if (this.isAviCloudConnected && this.isControllerRegistered) {
            this.setCaseListGridConfig();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.caseListCollection?.destroy();
    }

    /**
     * Redirect to Cloud Services Page.
     */
    public redirectToCloudServicesPage(): void {
        this.$state.go(CLOUD_SERVICES_PAGE_STATE);
    }

    /**
     * Fetch the portal feature opt-in details from api/albservicesconfig endpoint.
     */
    private async fetchPulseFeatureOptInDetails(): Promise<void> {
        try {
            const portalInfo = await this.cPortalService.getPortalInfo();

            this.isPulseCaseManagementEnabled =
                portalInfo?.feature_opt_in_status?.enable_pulse_case_management;
        } catch (error) {
            this.devLoggerService.error(error.data?.error);
        }
    }

    /**
     * Set isControllerRegistered and isAviCloudConnected flags.
     */
    private updateControllerStatus(albServiceStatus: IALBServicesStatus): void {
        const {
            connectivity_status: connectivityStatus,
            registration_status: registationStatus,
        } = albServiceStatus;

        this.isControllerRegistered = registationStatus ===
            ALBServicesRegistrationStatus.ALBSERVICES_REGISTERED;

        this.isAviCloudConnected = connectivityStatus ===
            ALBServicesConnectivityStatus.ALBSERVICES_CONNECTED;
    }

    /**
     * Set Case List Grid Config.
     */
    private setCaseListGridConfig(): void {
        const { objectName } = this.caseListCollection;

        this.caseListGridConfig = {
            id: `${objectName}-grid`,
            collection: this.caseListCollection,
            fields: [
                {
                    id: 'created-date',
                    label: this.l10nService.getMessage(this.l10nKeys.columnTitleDateOpened),
                    transform: row => this.aviDate.transform(row.getConfig().created_date),
                },
                {
                    id: 'case-number',
                    label: this.l10nService.getMessage(this.l10nKeys.columnTitleCaseNumber),
                    transform: row => row.getConfig().case_number,
                },
                {
                    id: 'subject',
                    label: this.l10nService.getMessage(this.l10nKeys.columnTitleSubject),
                    transform: row => row.getConfig().subject,
                },
                {
                    id: 'type',
                    label: this.l10nService.getMessage(this.globalL10nKeys.typeLabel),
                    transform: row => (row ? row.getConfig().type : '-'),
                },
                {
                    id: 'last-modified-date',
                    label: this.l10nService.getMessage(this.l10nKeys.columnTitleLastUpdated),
                    transform: row => this.aviDate.transform(row.getConfig().last_modified_date),
                },
                {
                    id: 'status',
                    label: this.l10nService.getMessage(this.globalL10nKeys.statusLabel),
                    transform: row => row.getConfig().status,
                },
            ],
            singleactions: [{
                id: 'attachment',
                label: this.l10nService.getMessage(this.l10nKeys.attachmentBtnLabel),
                shape: 'paperclip',
                onClick: (row: Case) => row.getConfig().case_attachments,
                hidden: (row: Case) => !row.getConfig().case_attachments,
            }, {
                id: 'edit',
                label: this.l10nService.getMessage(this.globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (row: Case) => row.edit(),
            }],
            layout: {
                // eslint-disable-next-line max-len
                placeholderMessage: this.l10nService.getMessage(this.globalL10nKeys.noItemsFoundLabel),
                hideCheckboxes: true,
            },
        };
    }
}
