/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name SupplMetricDataEventsDataSource
 * @author Alex Malitsky
 * @description
 *
 *     DataSource for {@link SupplMetricData} to get and update a time series of aggregated events,
 *     both config and non-config.
 *
 */
angular.module('aviApp').factory('SupplMetricDataEventsDataSource', [
'DataSource', 'eventsContext',
function(DataSource, eventsContext) {
    /**
     * @class
     * @extends DataSource
     */
    class MetricEventsDataSource extends DataSource {
        constructor(args = {}) {
            angular.extend(args, {
                defaultFields: [
                    {
                        id: MetricEventsDataSource.eventTypes[args.id].fieldName,
                        preserved: true,
                        subscribers: ['__default_field'],
                    },
                ],
                defaultParams: {
                    type: 2,
                },
            });

            super(args);
        }

        /**
         * No calls when SupplMetricData is in disabled state.
         * @override
         **/
        isInactive() {
            return super.isInactive() || !this.owner_.isActive();
        }

        /** @override */
        getRequestParams_() {
            const
                { owner_: owner } = this,
                { step, limit } = owner.getMetricParams(),
                params = super.getRequestParams_();

            angular.extend(params, {
                objectName_: [
                    'analytics',
                    'logs',
                ],
                step,
                limit,
            });

            const filters = [
                `co(all,'${owner.getMetricItemId()}')`,
                'ne(internal,EVENT_INTERNAL)',
                `co(event_pages,${eventsContext()})`,
            ];

            const { filter, seriesPropName } = this.getEventTypeProps_();

            filters.push(filter);

            params['filter'] = filters;

            const start = owner[seriesPropName] &&
                owner[seriesPropName].getLatestPointTime(true);

            if (start) {
                params['start'] = moment.utc(start).toISOString();
            }

            return params;
        }

        /**
         * Since this dataSource is processing two event types we need some kind of config to
         * know what params are supposed to be passed in API call and also where do put data
         * updates.
         * @returns {{string: string}}
         * @private
         */
        getEventTypeProps_() {
            return MetricEventsDataSource.eventTypes[this.id];
        }

        /** @override */
        processResponse_({ data }, { step, limit }) {
            const
                { owner_: owner } = this,
                { seriesPropName } = this.getEventTypeProps_();

            const series = owner[seriesPropName];

            data.series.header.name = series.id;
            series.process(data.series, undefined, step, limit);
        }
    }

    //settings to process different event types
    MetricEventsDataSource.eventTypes = {
        config_events_ds: {
            filter: 'eq(module,CONFIG)',
            fieldName: 'config_events', //fieldNames should be unique within one UpdatableItem
            seriesPropName: 'aggConfigEventSeries', //SupplMetricData prop name with the Series
        },
        non_config_events_ds: {
            filter: 'ne(module,CONFIG)',
            fieldName: 'non_config_events',
            seriesPropName: 'aggNonConfigEventSeries',
        },
    };

    return MetricEventsDataSource;
}]);
