<div
    provideParentForm
    class="ipam-dns-configuration"
>
    <div [ngClass]="{'ipam-dns-configuration__disabled': showOverlay}"></div>
    <h3>{{ l10nKeys.sectionTitle | vtranslate }}</h3>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="ipam_provider_uuid"
                for="ipam-provider-uuid"
            >
                {{ l10nKeys.ipamProfileSelectLabel | vtranslate }}
            </label>
            <avi-collection-dropdown
                [collection]="ipamProfileCollection"
                [(ngModel)]="editable.config.ipam_provider_ref"
                placeholder="{{ l10nKeys.ipamProfileSelectPlaceholder | vtranslate }}"
                name="ipam-provider-uuid"
            ></avi-collection-dropdown>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="dns_provider_uuid"
                for="dns-provider-uuid"
            >
                {{ l10nKeys.dnsProfileSelectLabel | vtranslate }}
            </label>
            <avi-collection-dropdown
                [collection]="dnsIpamProfileCollection"
                [(ngModel)]="editable.config.dns_provider_ref"
                placeholder="{{ l10nKeys.dnsProfileSelectPlaceholder | vtranslate }}"
                name="dns-provider-uuid"
            ></avi-collection-dropdown>
        </div>
    </div>
    <avi-checkbox
        *ngIf="showStateBasedDnsRegistration"
        objectType="{{ objectType }}"
        fieldName="state_based_dns_registration"
        [(ngModel)]="editable.config.state_based_dns_registration"
        name="state-based-dns-registration"
    >
        {{ l10nKeys.stateBasedDnsRegistrationCheckboxLabel | vtranslate }}
    </avi-checkbox>
    <div
        class="full-modal-config__form-control-row"
        *ngIf="showDnsResolverGrid"
    >
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="dns_resolvers"
            >
                <avi-data-grid_header>
                    <h6>
                        {{ l10nKeys.dnsResolverLabel | vtranslate : editable.dnsResolversCount }}
                    </h6>
                </avi-data-grid_header>
            </label>
            <dns-resolver-grid
                [dnsResolvers]="editable.getConfig().dns_resolvers"
                (onAddDnsResolver)="editable.addDnsResolver()"
                (onEditDnsResolver)="editable.editDnsResolver($event)"
            ></dns-resolver-grid>
        </div>
    </div>
</div>
