/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { TenantConfiguration } from 'object-types';

import * as l10n from './system-settings-tenancy-mode-card.l10n';
import './system-settings-tenancy-mode-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Tenancy Mode Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-tenancy-mode-card',
    templateUrl: './system-settings-tenancy-mode-card.component.html',
})
export class SystemSettingsTenancyModeCardComponent {
    /**
     * Tenant VRF, used to evaluate IP Route Domain value.
     */
    @Input()
    public tenantVrf: boolean;

    /**
     * Boolean flag for if SE in Provider Context,
     * used to evaluate SE Management Context.
     */
    @Input()
    public seInProviderContext: boolean;

    /**
     * Boolean flag for tenant access to Provider SE.
     */
    @Input()
    public tenantAccessToProviderSe: boolean;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type for template usage.
     */
    public readonly objectType = TenantConfiguration;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * IP Route Domain Value.
     */
    public get ipRouteDomain(): string {
        const ipRouteDomain = this.tenantVrf ?
            this.l10nKeys.ipRouteDomainPerTenant :
            this.l10nKeys.ipRouteDomainSharedAcrossTenant;

        return this.l10nService.getMessage(ipRouteDomain);
    }

    /**
     * SE Managment Context.
     */
    public get seManagementContext(): string {
        const seManagementContext = this.seInProviderContext ?
            this.l10nKeys.seManagementProviderContext :
            this.l10nKeys.seManagementTenantContext;

        return this.l10nService.getMessage(seManagementContext);
    }

    /**
     * Tenant Access To Provider SE.
     */
    public get tenantAccessToSe(): string {
        const tenantAccessToSe = this.tenantAccessToProviderSe ?
            this.l10nKeys.tenantSeAccessReadOnly :
            this.l10nKeys.tenantSeAccessNoAccess;

        return this.l10nService.getMessage(tenantAccessToSe);
    }
}
