/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { eventPageObjectTypesHash } from '../../services/EventsContext';
import * as l10n from './EventsListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { EVENT_PAGE_CNTLR } = eventPageObjectTypesHash;

/**
 * @class
 * @constructor
 * @memberOf module:avi/events
 */
function EventsListController(
    scope,
    $location,
    $state,
    $stateParams,
    $q,
    $http,
    $timeout,
    myAccount,
    logTimeframes,
    eventsContext,
    EventCollection,
    eventGridConfig,
    Timeframe,
    eventsListPageStatus,
    eventSearchParser,
    l10nService,
) {
    scope.myAccount = myAccount;
    scope.$state = $state;
    scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    scope.reloadMoreAvailableEventsLinkList =
        l10nService.getSplitedMessage(l10nKeys.reloadMoreAvailableEventsLink);

    const defaults = {
            timeframe: '15m', //for use instead of custom
            allTimeStart: '2014-01-01 00:00 +0000',
            barsPerPage: 120,
            orderby: '-report_timestamp',
        },
        ordersBy = {
            report_timestamp: true,
            event_id: true,
            obj_type: true,
        },
        moment2str = function(moment) {
            return encodeURI(moment.toISOString());
        };

    function Params() {
        //duration - timeframe string, start&end - dates in 'moment' type
        this.updateTimeframe = function(duration, start, end) {
            if (typeof duration === 'undefined') { //update on forceRefresh
                if (this.start && this.end && endIsNow && this.duration &&
                    this.timeframe !== 'custom') {
                    this.end = moment();
                    this.start = this.end.clone().subtract(this.duration, 's');
                    checkBrushBoundaries();
                }
            } else if (duration !== 'custom' && typeof duration === 'string' &&
                logTimeframes[duration]) {
                this.end = moment();
                endIsNow = true;

                if (duration === 'all') {
                    this.start = moment(defaults.allTimeStart, 'YYYY-MM-DD HH:mm Z');
                    this.duration = this.end.diff(this.start, 's');
                } else {
                    this.duration = logTimeframes[duration].range;
                    this.start = this.end.clone().subtract(this.duration, 's');
                }

                this.timeframe = duration;
                this.step = Math.round(this.duration / defaults.barsPerPage);
                this.brush.end = false;
                this.brush.start = false;
                logTimeframes.custom.hiddenInLayout = true;
            } else if (duration === 'custom') {
                if (typeof start === 'object') {
                    this.start = start;

                    if (typeof end === 'object') {
                        this.end = end;
                        endIsNow = false;
                    } else if (endIsNow || !this.end) {
                        if (!this.end) {
                            console.warn('params.updateTimeframe: unexpected behaviour: ' +
                                'end is false');
                        }

                        this.end = moment();
                        endIsNow = true;
                    }

                    this.duration = this.end.diff(this.start, 's');

                    if (this.duration === 0) { //not less then one second
                        this.duration = 1;
                        this.end = this.start.clone().add(1, 's');
                    }

                    this.timeframe = 'custom';
                    this.step = Math.round(this.duration / defaults.barsPerPage) || 1;
                    this.brush.end = false;
                    this.brush.start = false;
                    logTimeframes.custom.hiddenInLayout = false;
                    this.updateStParamsAndURI();
                } else {
                    console.warn('params.updateTimeframe: custom timeframe given without ' +
                        'start object');
                    this.updateTimeframe(defaults.timeframe);

                    return false;
                }
            }
        };

        const systemGeneratedConfigEventsUser = 'avisystemuser';

        this.queryString = function() { //for chart only
            let query = '/api/analytics/logs?type=2&page_size=10000&';
            const filters = this.setFilters();

            filters.forEach(f => {
                query += `filter=${f}&`;
            });

            if (this.search) {
                const filters = eventSearchParser.parse(this.search);

                filters.forEach(f => {
                    query += `filter=${f}&`;
                });
            }

            query += `end=${moment2str(this.end)}&duration=${this.duration}` +
                `&step=${this.step}&groupby=report_timestamp`;

            return query;
        };

        this.setFilters = function() {
            const filters = [];

            if (this.slug) { //default for controller, temporary
                filters.push(`co(all,"${this.slug}")`);
            }

            if (this.portalSection) {
                filters.push(`co(event_pages,${this.portalSection})`);
            }

            if (!this.inclInternal) {
                filters.push('ne(internal,EVENT_INTERNAL)');
            }

            if (!this.inclSystemEvents) {
                filters.push(`nc(event_details,'user=${systemGeneratedConfigEventsUser}')`);
            }

            return filters;
        };

        this.setCollectionParams = function() { //for list - collection grid
            let duration,
                end;

            if (this.brush.start && this.brush.end) {
                duration = this.brush.end.diff(this.brush.start, 's');
                end = moment2str(this.brush.end);
            } else {
                duration = this.duration;
                end = moment2str(this.end);
            }

            scope.collection.setParams({
                duration,
                end,
            });

            let filters = this.setFilters();

            if (this.search) {
                filters = filters.concat(eventSearchParser.parse(this.search));
            }

            if (filters.length) {
                scope.collection.setParams({ filter: filters });
            }
        };

        this.start = false;
        this.end = false;
        this.brush = {
            start: 0,
            end: 0,
        };
        this.search = '';//plain string from input

        const self = this;
        let endIsNow = true;

        this.updateStParamsAndURI = function() { //updates stateParams and URI
            const systemEventsKey = 'inclSystemEvents';

            _.each($state.current.params, (prevValue, key) => {
                const value = this[key];
                let stateParamValue = null;

                if (value && (angular.isUndefined(defaults[key]) || value !== defaults[key])) {
                    if (key === 'start' || key === 'end') {
                        if (this.timeframe === 'custom') {
                            stateParamValue = moment2str(value);
                        }
                    } else {
                        stateParamValue = `${value}`;
                    }
                } else if (key === systemEventsKey) {
                    stateParamValue = `${value}`;
                }

                $location.search(key, stateParamValue);
            });
        };

        const checkBrushBoundaries = function() {
            if (self.brush.start && +self.brush.start < +self.start ||
                self.brush.end && +self.brush.end > +self.end) {
                self.brush.end = false;
                self.brush.start = false;
            }
        };
    }

    /* save and modify parameters in one place (well, mostly) */
    scope.params = new Params();

    const { params } = scope;

    eventsListPageStatus.setStatus(params);

    function onSearchFilterUpdate() {
        scope.ui.fetch();
    }

    //Summary popover might update the search filter through value click
    eventsListPageStatus.on('searchFilterUpdate', onSearchFilterUpdate);

    scope.setInitialState = function() {
        let { key: duration } = Timeframe.selected(),
            start,
            end;

        //load initial state onReload and initial load

        scope.itemsQ = 0;//number of events gotten by our request
        scope.selItemsQ = 0;

        scope.data = {};
        scope.timeouts = { chart: false };

        if ($stateParams.orderby && ($stateParams.orderby in ordersBy ||
            $stateParams.orderby.slice(1) in ordersBy && $stateParams.orderby[0] === '-')) {
            params.orderby = $stateParams.orderby;
        } else {
            params.orderby = defaults.orderby;
        }

        if (duration === 'custom') {
            if ((start = moment($stateParams.start)) && (end = moment($stateParams.end)) &&
                start.isValid() && end.isValid() && start.isBefore(end)) {
                params.start = start;
                params.end = end;
            } else {
                duration = defaults.timeframe;
                Timeframe.set(duration);
            }
        }

        params.updateTimeframe(duration, start, end);

        const item = scope.Pool || scope.VirtualService || scope.ServiceEngine;

        //can have no Item on Application or Controller pages
        if (item) {
            item.addLoad(['health', 'alert', 'faults']);
        }

        params.inclInternal = $stateParams.inclInternal === 'true';
        params.inclSystemEvents =
            $stateParams.inclSystemEvents ? $stateParams.inclSystemEvents === 'true' : true;

        //filters out search in JSON format which might come from log list page
        if ($stateParams.search) {
            try {
                JSON.parse($stateParams.search);
            } catch (e) {
                params.search = $stateParams.search;
            }
        }

        params.slug = $stateParams.poolId || $stateParams.vsId || $stateParams.seId ||
            $stateParams.gslbServiceId;

        params.portalSection = eventsContext();

        scope.collection = new EventCollection();

        scope.collection.on('collectionLoadSuccess', function() {
            params.orderby = scope.collection.getSorting();
            params.updateStParamsAndURI();
            params.isAccurate = scope.collection.isAccurate();
        });

        params.setCollectionParams();//initial load params

        scope.gridConfig = eventGridConfig(scope.collection, {
            defaultSorting: params.orderby,
        });

        const { objectName } = scope.gridConfig.collection;

        scope.gridConfig.id = `${objectName}-list-page`;

        if (params.portalSection === EVENT_PAGE_CNTLR) {
            const { gridConfig } = scope;
            const { fields } = gridConfig;

            // user column is not applicable for the page
            gridConfig.fields = fields.filter(({ name }) => name !== 'user');
        }

        Timeframe.on('change', scope.ui.onTimeframeChange);
    };

    function UI() {
        const fetch = function() {
            //search input sets value to undefined if manually remove all symbols from there
            if (_.isUndefined(scope.params.search)) {
                scope.params.search = '';
            }

            scope.fetchEvents();
            scope.fetchForBar();
        };

        const fetchEvents = function() {
            scope.fetchEvents();
        };

        const fetchForBar = function() {
            scope.fetchForBar();
        };

        this.fetch = function() { //don't like this!
            fetch();
        };

        this.fetchEvents = function() {
            fetchEvents();
        };

        this.zoomInAvailable = function() {
            return scope.params.brush.start && scope.params.brush.end &&
                scope.params.brush.end.diff(scope.params.brush.start, 's') > 119;
        };

        this.updateTimeframe = function(val) {
            if (typeof val === 'undefined') {
                val = $stateParams.timeframe;
            }

            scope.params.updateTimeframe(val);
            fetch();
        };

        this.forceRefresh = function() {
            scope.params.updateTimeframe();
            fetch();
        };

        this.filterChangeHandler = fetch;

        /* BarChart */
        this.updateBrushBoundaries = function() { //fetch for list after brushEnd event
            fetchEvents();
        };

        this.loadBrushTimeframe = function() { //zoomIn
            scope.params.updateTimeframe('custom',
                scope.params.brush.start, scope.params.brush.end);
            Timeframe.set('custom');
            fetchForBar();
        };

        this.onTimeframeChange = function() {
            const val = Timeframe.selected().key;

            if (val !== scope.params.timeframe) {
                scope.params.updateTimeframe(val);
                scope.ui.fetch();
            }
        };
    }

    //UI communication
    scope.ui = new UI();

    /* content load */
    scope.fetchEvents = function() {
        const { params } = scope;

        params.setCollectionParams();
        params.isAccurate = true;
        scope.collection.load(undefined, true);
    };

    /**
     * reload the event data
     */
    scope.reloadEventData = function() {
        scope.ui.fetch();
    };

    /**
     * Function fetches the events for graph from api.
     * This method is called recursively to get more records based on percent_remaining flag.
     * Method gets called for approx 4 min or until the api returns percent_remaining as 0,
     * whichever happens first.
     * @memberOf module:avi/events.EventsListController
     * @param {number} [i=1] - API call counter (during continuous polling). From one to five.
     */
    scope.fetchForBar = (i = 1) => {
        function updateItemsQ(num) {
            scope.itemsQ = num || 0;

            if (!num && scope.params.brush.start) {
                scope.selItemsQ = 0;
            }
        }

        const query = scope.params.queryString(1);
        const fullQuery = `${query}&timeout=${Math.min(2 ** i, 32)}&`;
        const def = $q.defer();

        //initial call only, after it we only update data
        if (i === 1) {
            scope.data.chart = { results: [] };

            updateItemsQ();

            if (scope.timeouts.chart) {
                scope.timeouts.chart.resolve();
                scope.timeouts.chart = null;
            }

            $timeout.cancel(scope.updateEventBar);
        }

        scope.timeouts.chart = def;

        $http({
            method: 'get',
            url: fullQuery,
            timeout: def.promise,
        })
            .then(({ data, config }) => {
                if (!scope.timeouts.chart || config.timeout !== scope.timeouts.chart.promise) {
                    return;
                }

                const itemsQ = data.results.reduce((base, { value }) => base + value, 0);

                updateItemsQ(itemsQ);

                scope.data.chart.results = data.results;
                scope.data.chart.start = data.start;
                scope.data.chart.end = data.end;

                if (data.percent_remaining === 0 || i >= 11) {
                    scope.timeouts.chart = null;
                } else {
                    scope.updateEventBar = $timeout(
                        () => scope.fetchForBar(++i),
                        1000,
                    );
                }
            })
            .catch(({ config }) => {
                if (!scope.timeouts.chart || config.timeout !== scope.timeouts.chart.promise) {
                    return;
                }

                scope.timeouts.chart = null;
            });
    };

    scope.setInitialState();
    scope.fetchForBar();

    scope.$on('$destroy', () => {
        if (scope.timeouts.chart) {
            scope.timeouts.chart.resolve();
            scope.timeouts.chart = null;
        }

        logTimeframes.custom.hiddenInLayout = true;

        const item = scope.Pool || scope.VirtualService || scope.ServiceEngine;

        if (item) {
            item.async.stop(true);
        }

        scope.collection.destroy();

        Timeframe.unbind('change', scope.ui.onTimeframeChange);

        eventsListPageStatus.unbind('searchFilterUpdate', onSearchFilterUpdate);

        eventsListPageStatus.setStatus(null);

        $timeout.cancel(scope.updateEventBar);
    });
}

EventsListController.$inject = [
    '$scope',
    '$location',
    '$state',
    '$stateParams',
    '$q',
    '$http',
    '$timeout',
    'myAccount',
    'logTimeframes',
    'eventsContext',
    'EventCollection',
    'eventGridConfig',
    'Timeframe',
    'eventsListPageStatus',
    'eventSearchParser',
    'l10nService',
];

angular.module('avi/events')
    .controller('EventsListController', EventsListController);
