/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'dialog';
const componentName = 'message-of-the-day';
const prefix = `${moduleName}.${componentName}`;

export const title = `${prefix}.title`;

export const ENGLISH = {
    [title]: 'Message of the Day',
};
