/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'virtual-service-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const tabLabelSettings = `${prefix}.tabLabelSettings`;
export const tabLabelPolicies = `${prefix}.tabLabelPolicies`;
export const tabLabelAdvanced = `${prefix}.tabLabelAdvanced`;
export const discardPolicyChangesMessage = `${prefix}.discardPolicyChangesMessage`;
export const discardClientFilterChangesMessage = `${prefix}.discardClientFilterChangesMessage`;
export const tabLabelStaticDnsRecords = `${prefix}.tabLabelStaticDnsRecords`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const cloudsHeader = `${prefix}.cloudsHeader`;
export const selectVrfContextHeader = `${prefix}.selectVrfContextHeader`;
export const selectPoolMessage = `${prefix}.selectPoolMessage`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const trafficEnabledCheckboxLabel = `${prefix}.trafficEnabledCheckboxLabel`;
export const virtualHostingVsCheckboxLabel = `${prefix}.virtualHostingVsCheckboxLabel`;
export const vhvsParentButtonLabel = `${prefix}.vhvsParentButtonLabel`;
export const vhvsChildButtonLabel = `${prefix}.vhvsChildButtonLabel`;
export const virtualHostingTypeInputLabel = `${prefix}.virtualHostingTypeInputLabel`;
export const vipAddressHeader = `${prefix}.vipAddressHeader`;
export const addressHeader = `${prefix}.addressHeader`;
export const virtualHostingParentInputLabel = `${prefix}.virtualHostingParentInputLabel`;
export const virtualHostingParentInputPlaceholder = `${prefix}.virtualHostingParentInputPlaceholder`;
export const domainNameInputLabel = `${prefix}.domainNameInputLabel`;
export const virtualHostingMatchCriteriaHeader = `${prefix}.virtualHostingMatchCriteriaHeader`;
export const profilesHeader = `${prefix}.profilesHeader`;
export const networkProfileInputLabel = `${prefix}.networkProfileInputLabel`;
export const createNetworkProfileInputLabel = `${prefix}.createNetworkProfileInputLabel`;
export const selectDropdownPlaceholder = `${prefix}.selectDropdownPlaceholder`;
export const applicationProfileInputLabel = `${prefix}.applicationProfileInputLabel`;
export const createApplicationProfileInputLabel = `${prefix}.createApplicationProfileInputLabel`;
export const createWafPolicyInputLabel = `${prefix}.createWafPolicyInputLabel`;
export const wafPolicyInputPlaceholder = `${prefix}.wafPolicyInputPlaceholder`;
export const icapProfileInputLabel = `${prefix}.icapProfileInputLabel`;
export const createIcapProfileInputLabel = `${prefix}.createIcapProfileInputLabel`;
export const icapProfileInputPlaceholder = `${prefix}.icapProfileInputPlaceholder`;
export const errorPageProfileInputLabel = `${prefix}.errorPageProfileInputLabel`;
export const createErrorPageProfileInputLabel = `${prefix}.createErrorPageProfileInputLabel`;
export const errorPageProfileInputPlaceholder = `${prefix}.errorPageProfileInputPlaceholder`;
export const servicePortHeader = `${prefix}.servicePortHeader`;
export const switchToAdvancedButtonLabel = `${prefix}.switchToAdvancedButtonLabel`;
export const switchToBasicButtonLabel = `${prefix}.switchToBasicButtonLabel`;
export const servicesLabel = `${prefix}.servicesLabel`;
export const servicePortMinInputPlaceholder = `${prefix}.servicePortMinInputPlaceholder`;
export const servicePortToLabel = `${prefix}.servicePortToLabel`;
export const servicePortMaxInputPlaceholder = `${prefix}.servicePortMaxInputPlaceholder`;
export const overrideNetworkCheckboxLabel = `${prefix}.overrideNetworkCheckboxLabel`;
export const addPortButtonLabel = `${prefix}.addPortButtonLabel`;
export const poolInputPlaceholder = `${prefix}.poolInputPlaceholder`;
export const createPoolGroupInputLabel = `${prefix}.createPoolGroupInputLabel`;
export const poolGroupInputPlaceholder = `${prefix}.poolGroupInputPlaceholder`;
export const ignoreNetworkReachabilityMessage = `${prefix}.ignoreNetworkReachabilityMessage`;
export const otherSettingsHeader = `${prefix}.otherSettingsHeader`;
export const sslSettingsHeader = `${prefix}.sslSettingsHeader`;
export const sslProfileInputLabel = `${prefix}.sslProfileInputLabel`;
export const sslCertificateInputLabel = `${prefix}.sslCertificateInputLabel`;
export const sslProfileInputPlaceholder = `${prefix}.sslProfileInputPlaceholder`;
export const tabLabelNetworkSecurity = `${prefix}.tabLabelNetworkSecurity`;
export const tabLabelHttpSecurity = `${prefix}.tabLabelHttpSecurity`;
export const httpPolicySetInputLabel = `${prefix}.httpPolicySetInputLabel`;
export const httpPolicySetInputPlaceholder = `${prefix}.httpPolicySetInputPlaceholder`;
export const tabLabelHttpRequest = `${prefix}.tabLabelHttpRequest`;
export const tabLabelHttpResponse = `${prefix}.tabLabelHttpResponse`;
export const tabLabelDnsPolicy = `${prefix}.tabLabelDnsPolicy`;
export const dnsPoliciesInputLabel = `${prefix}.dnsPoliciesInputLabel`;
export const dnsPoliciesInputPlaceholder = `${prefix}.dnsPoliciesInputPlaceholder`;
export const tabLabelTopologyPolicy = `${prefix}.tabLabelTopologyPolicy`;
export const topologyPolicyInputLabel = `${prefix}.topologyPolicyInputLabel`;
export const topologyPolicyInputPlaceholder = `${prefix}.topologyPolicyInputPlaceholder`;
export const tabLabelDataScripts = `${prefix}.tabLabelDataScripts`;
export const tabLabelAccess = `${prefix}.tabLabelAccess`;
export const analyticsProfileInputLabel = `${prefix}.analyticsProfileInputLabel`;
export const metricsRealtimeUpdateInputLabel = `${prefix}.metricsRealtimeUpdateInputLabel`;
export const realTimeMetricsCheckboxLabel = `${prefix}.realTimeMetricsCheckboxLabel`;
export const metricsRealtimeUpdateInputPlaceholder = `${prefix}.metricsRealtimeUpdateInputPlaceholder`;
export const metricsRealtimeUpdateMinutesInnerText = `${prefix}.metricsRealtimeUpdateMinutesInnerText`;
export const clientInsightsHeader = `${prefix}.clientInsightsHeader`;
export const clientInsightsInputLabel = `${prefix}.clientInsightsInputLabel`;
export const includeUrlInputLabel = `${prefix}.includeUrlInputLabel`;
export const excludeUrlInputLabel = `${prefix}.excludeUrlInputLabel`;
export const includeUrlInputPlaceholder = `${prefix}.includeUrlInputPlaceholder`;
export const excludeUrlInputPlaceholder = `${prefix}.excludeUrlInputPlaceholder`;
export const clientIpAddressInputPlaceholder = `${prefix}.clientIpAddressInputPlaceholder`;
export const performanceLimitSettingsHeader = `${prefix}.performanceLimitSettingsHeader`;
export const performanceLimitCheckboxLabel = `${prefix}.performanceLimitCheckboxLabel`;
export const maxThroughputInputLabel = `${prefix}.maxThroughputInputLabel`;
export const maxThroughputInputPlaceholder = `${prefix}.maxThroughputInputPlaceholder`;
export const maxConcurrentConnectionsInputLabel = `${prefix}.maxConcurrentConnectionsInputLabel`;
export const maxConcurrentConnectionsInputPlaceholder = `${prefix}.maxConcurrentConnectionsInputPlaceholder`;
export const qualityOfServiceHeader = `${prefix}.qualityOfServiceHeader`;
export const weightInputLabel = `${prefix}.weightInputLabel`;
export const fairnessInputLabel = `${prefix}.fairnessInputLabel`;
export const throughputAndDelayFairnessInputLabel = `${prefix}.throughputAndDelayFairnessInputLabel`;
export const throughputFairnessInputLabel = `${prefix}.throughputFairnessInputLabel`;
export const httpBasicAuthenticationHeader = `${prefix}.httpBasicAuthenticationHeader`;
export const useHttpBasicAuthenticationMessage = `${prefix}.useHttpBasicAuthenticationMessage`;
export const enableHttpBasicAuthenticationCheckboxLabel = `${prefix}.enableHttpBasicAuthenticationCheckboxLabel`;
export const basicRealmInputLabel = `${prefix}.basicRealmInputLabel`;
export const authProfileInputLabel = `${prefix}.authProfileInputLabel`;
export const createAuthProfileInputLabel = `${prefix}.createAuthProfileInputLabel`;
export const serverNetworkProfileInputLabel = `${prefix}.serverNetworkProfileInputLabel`;
export const createServerNetworkProfileInputLabel = `${prefix}.createServerNetworkProfileInputLabel`;
export const autoGatewayCheckboxLabel = `${prefix}.autoGatewayCheckboxLabel`;
export const useVipAsSnatCheckboxLabel = `${prefix}.useVipAsSnatCheckboxLabel`;
export const advertiseVipViaBgpCheckboxLabel = `${prefix}.advertiseVipViaBgpCheckboxLabel`;
export const advertiseSnatViaBgpCheckboxLabel = `${prefix}.advertiseSnatViaBgpCheckboxLabel`;
export const hostNameTranslationInputLabel = `${prefix}.hostNameTranslationInputLabel`;
export const activeStandbySeTagInputLabel = `${prefix}.activeStandbySeTagInputLabel`;
export const snatIpAddressInputLabel = `${prefix}.snatIpAddressInputLabel`;
export const removeListeningPortOnVsDownCheckboxLabel = `${prefix}.removeListeningPortOnVsDownCheckboxLabel`;
export const trafficCloneProfileInputLabel = `${prefix}.trafficCloneProfileInputLabel`;
export const trafficCloneProfileInputPlaceholder = `${prefix}.trafficCloneProfileInputPlaceholder`;
export const scaleoutEcmpCheckboxLabel = `${prefix}.scaleoutEcmpCheckboxLabel`;
export const saveRuleButtonLabel = `${prefix}.saveRuleButtonLabel`;
export const saveDataScriptButtonLabel = `${prefix}.saveDataScriptButtonLabel`;
export const saveFilterButtonLabel = `${prefix}.saveFilterButtonLabel`;
export const nextButtonLabel = `${prefix}.nextButtonLabel`;
export const previousButtonLabel = `${prefix}.previousButtonLabel`;
export const botDetectionPolicyInputLabel = `${prefix}.botDetectionPolicyInputLabel`;
export const botDetectionPolicyInputPlaceholder = `${prefix}.botDetectionPolicyInputPlaceholder`;
export const vsVipInputLabel = `${prefix}.vsVipInputLabel`;
export const vsVipInputPlaceholder = `${prefix}.vsVipInputPlaceholder`;
export const createVsVipButtonLabel = `${prefix}.createVsVipButtonLabel`;
export const useAsHorizonPrimaryTunnelProtocolPortsLabel = `${prefix}.useAsHorizonPrimaryTunnelProtocolPortsLabel`;
export const overrideApplicationProfileLabel = `${prefix}.overrideApplicationProfileLabel`;
export const overrideApplicationProfileDropdownPlaceholder = `${prefix}.overrideApplicationProfileDropdownPlaceholder`;
export const minimumPoolsUpLabel = `${prefix}.minimumPoolsUpLabel`;
export const csrfPolicyDropdownLabel = `${prefix}.csrfPolicyDropdownLabel`;
export const csrfPolicyDropdownPlaceholder = `${prefix}.csrfPolicyDropdownPlaceholder`;
export const csrfPolicyDropdownCreateLabel = `${prefix}.csrfPolicyDropdownCreateLabel`;
export const enableHttpSessionCheckboxLabel = `${prefix}.enableHttpSessionCheckboxLabel`;

export const ENGLISH = {
    [tabLabelSettings]: 'Settings',
    [tabLabelPolicies]: 'Policies',
    [tabLabelAdvanced]: 'Advanced',
    [discardPolicyChangesMessage]: 'Discard policy changes?',
    [discardClientFilterChangesMessage]: 'Discard Client Filter changes?',
    [tabLabelStaticDnsRecords]: 'Static DNS Records',
    [modalHeaderEdit]: 'Edit Virtual Service: {0}',
    [modalHeaderNew]: 'New Virtual Service: {0}',
    [cloudsHeader]: 'Clouds',
    [selectVrfContextHeader]: 'Select VRF Context',
    [selectPoolMessage]: 'You may want to select a pool.',
    [nameInputPlaceholder]: 'Virtual Service Name',
    [trafficEnabledCheckboxLabel]: 'Traffic Enabled',
    [virtualHostingVsCheckboxLabel]: 'Virtual Hosting VS',
    [vhvsParentButtonLabel]: 'Parent',
    [vhvsChildButtonLabel]: 'Child',
    [virtualHostingTypeInputLabel]: 'Virtual Hosting Type',
    [vipAddressHeader]: 'VIP Address',
    [addressHeader]: 'Address',
    [virtualHostingParentInputLabel]: 'Virtual Hosting Parent',
    [virtualHostingParentInputPlaceholder]: 'Select VH Parent',
    [domainNameInputLabel]: 'Domain Name',
    [virtualHostingMatchCriteriaHeader]: 'Virtual Hosting Match Criteria',
    [profilesHeader]: 'Profiles',
    [networkProfileInputLabel]: 'Network Profile',
    [createNetworkProfileInputLabel]: 'Create Network Profile',
    [selectDropdownPlaceholder]: 'Select',
    [applicationProfileInputLabel]: 'Application Profile',
    [createApplicationProfileInputLabel]: 'Create Application Profile',
    [createWafPolicyInputLabel]: 'Create WAF Policy',
    [wafPolicyInputPlaceholder]: 'Select WAF Policy',
    [icapProfileInputLabel]: 'ICAP Profile',
    [createIcapProfileInputLabel]: 'Create ICAP Profile',
    [icapProfileInputPlaceholder]: 'Select ICAP Profile',
    [errorPageProfileInputLabel]: 'Error Page Profile',
    [createErrorPageProfileInputLabel]: 'Create Error Page Profile',
    [errorPageProfileInputPlaceholder]: 'Select Error Page Profile',
    [servicePortHeader]: 'Service Port',
    [switchToAdvancedButtonLabel]: 'Switch to Advanced',
    [switchToBasicButtonLabel]: 'Switch to Basic',
    [servicesLabel]: 'Services',
    [servicePortMinInputPlaceholder]: 'Port Min',
    [servicePortToLabel]: 'to',
    [servicePortMaxInputPlaceholder]: 'Port Max',
    [overrideNetworkCheckboxLabel]: 'Override Network Profile',
    [addPortButtonLabel]: '+ Add Port',
    [poolInputPlaceholder]: 'Select a Pool',
    [createPoolGroupInputLabel]: 'Create Pool Group',
    [poolGroupInputPlaceholder]: 'Select a Pool Group',
    [ignoreNetworkReachabilityMessage]: 'Ignore network reachability constraints for the server pool',
    [otherSettingsHeader]: 'Other Settings',
    [sslSettingsHeader]: 'SSL Settings',
    [sslProfileInputLabel]: 'SSL Profile',
    [sslCertificateInputLabel]: 'SSL Certificate',
    [sslProfileInputPlaceholder]: 'Use Parent Profile',
    [tabLabelNetworkSecurity]: 'Network Security',
    [tabLabelHttpSecurity]: 'HTTP Security',
    [httpPolicySetInputLabel]: 'HTTP Policy Set:',
    [httpPolicySetInputPlaceholder]: 'Select HTTP Policy Set',
    [tabLabelHttpRequest]: 'HTTP Request',
    [tabLabelHttpResponse]: 'HTTP Response',
    [tabLabelDnsPolicy]: 'DNS Policy',
    [dnsPoliciesInputLabel]: 'DNS Policies:',
    [dnsPoliciesInputPlaceholder]: 'Select DNS Policy',
    [tabLabelTopologyPolicy]: 'Topology Policy',
    [topologyPolicyInputLabel]: 'Topology Policies:',
    [topologyPolicyInputPlaceholder]: 'Select Topology Policy',
    [tabLabelDataScripts]: 'DataScripts',
    [tabLabelAccess]: 'Access',
    [analyticsProfileInputLabel]: 'Analytics Profile',
    [metricsRealtimeUpdateInputLabel]: 'Metric Update Frequency',
    [realTimeMetricsCheckboxLabel]: 'Real Time Metrics',
    [metricsRealtimeUpdateInputPlaceholder]: 'Duration. Use 0 for Infinite.',
    [metricsRealtimeUpdateMinutesInnerText]: 'min',
    [clientInsightsHeader]: 'Client Insights',
    [clientInsightsInputLabel]: 'Client Insights',
    [includeUrlInputLabel]: 'Include URL',
    [excludeUrlInputLabel]: 'Exclude URL',
    [includeUrlInputPlaceholder]: 'Include all URLs',
    [excludeUrlInputPlaceholder]: 'Do Not Exclude any URLs',
    [clientIpAddressInputPlaceholder]: 'Include all Client IP Addresses',
    [performanceLimitSettingsHeader]: 'Performance Limit Settings',
    [performanceLimitCheckboxLabel]: 'Performance Limits',
    [maxThroughputInputLabel]: 'Max Throughput',
    [maxThroughputInputPlaceholder]: 'Unlimited',
    [maxConcurrentConnectionsInputLabel]: 'Max Concurrent Connections',
    [maxConcurrentConnectionsInputPlaceholder]: 'Unlimited',
    [qualityOfServiceHeader]: 'Quality of Service',
    [weightInputLabel]: 'Weight',
    [fairnessInputLabel]: 'Fairness',
    [throughputAndDelayFairnessInputLabel]: 'Throughput And Delay Fairness',
    [throughputFairnessInputLabel]: 'Throughput Fairness',
    [httpBasicAuthenticationHeader]: 'HTTP Basic Authentication',
    [useHttpBasicAuthenticationMessage]: 'Use HTTP Basic Auth to authenticate connecting clients to this Virtual Service.',
    [enableHttpBasicAuthenticationCheckboxLabel]: 'Enable HTTP Basic Authentication',
    [basicRealmInputLabel]: 'Basic Realm',
    [authProfileInputLabel]: 'Auth Profile',
    [createAuthProfileInputLabel]: 'Create Auth Profile',
    [serverNetworkProfileInputLabel]: 'Server Network Profile',
    [createServerNetworkProfileInputLabel]: 'Create Network Profile',
    [autoGatewayCheckboxLabel]: 'Auto Gateway',
    [useVipAsSnatCheckboxLabel]: 'Use VIP as SNAT',
    [advertiseVipViaBgpCheckboxLabel]: 'Advertise VIP via BGP',
    [advertiseSnatViaBgpCheckboxLabel]: 'Advertise SNAT via BGP',
    [hostNameTranslationInputLabel]: 'Host Name Translation',
    [activeStandbySeTagInputLabel]: 'Active Standby Service Engine Tag',
    [snatIpAddressInputLabel]: 'SNAT IP Address',
    [removeListeningPortOnVsDownCheckboxLabel]: 'Remove Listening Port when VS Down',
    [trafficCloneProfileInputLabel]: 'Traffic Clone Profile',
    [trafficCloneProfileInputPlaceholder]: 'Select Traffic Clone Profile',
    [scaleoutEcmpCheckboxLabel]: 'Scale out ECMP',
    [saveRuleButtonLabel]: 'Save Rule',
    [saveDataScriptButtonLabel]: 'Save DataScript',
    [saveFilterButtonLabel]: 'Save Filter',
    [nextButtonLabel]: 'Next',
    [previousButtonLabel]: 'Previous',
    [botDetectionPolicyInputLabel]: 'Bot Detection Policy',
    [botDetectionPolicyInputPlaceholder]: 'Select Bot Detection Policy',
    [vsVipInputLabel]: 'VS VIP',
    [vsVipInputPlaceholder]: 'Select VS VIP',
    [createVsVipButtonLabel]: 'Create VS VIP',
    [useAsHorizonPrimaryTunnelProtocolPortsLabel]: 'Use as Horizon Primary/Tunnel Protocol Ports',
    [overrideApplicationProfileLabel]: 'Override Application Profile',
    [overrideApplicationProfileDropdownPlaceholder]: 'Select Application Profile',
    [minimumPoolsUpLabel]: 'Minimum Pools Up',
    [csrfPolicyDropdownLabel]: 'CSRF Policy',
    [csrfPolicyDropdownPlaceholder]: 'Select CSRF Policy',
    [csrfPolicyDropdownCreateLabel]: 'Create CSRF Policy',
    [enableHttpSessionCheckboxLabel]: 'Enable HTTP Session',
};
