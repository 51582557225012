/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const ignorePeerChainCheckboxLabel = `${prefix}.ignorePeerChainCheckboxLabel`;
export const enableCrlCheckCheckboxLabel = `${prefix}.enableCrlCheckCheckboxLabel`;
export const leafValidationCheckboxLabel = `${prefix}.leafValidationCheckboxLabel`;
export const certificateAuthorityHeader = `${prefix}.certificateAuthorityHeader`;
export const certificateAuthoritiesLabel = `${prefix}.certificateAuthoritiesLabel`;
export const fileUploadSuccessfulMessage = `${prefix}.fileUploadSuccessfulMessage`;
export const serverUrlRetrievedSuccessfulMessage = `${prefix}.serverUrlRetrievedSuccessfulMessage`;
export const certificateRevocationListLabel = `${prefix}.certificateRevocationListLabel`;
export const certificateRevocationListPlaceholder = `${prefix}.certificateRevocationListPlaceholder`;
export const crlHelperText = `${prefix}.crlHelperText`;
export const addByServerUrlLabel = `${prefix}.addByServerUrlLabel`;
export const caWarningInfo = `${prefix}.caWarningInfo`;
export const allowedPKIErrors = `${prefix}.allowedPKIErrors`;
export const isFederatedChangeConfirmationLabel = `${prefix}.isFederatedChangeConfirmationLabel`;
export const isFederatedChangeWarningMessage = `${prefix}.isFederatedChangeWarningMessage`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit PKI Profile',
    [modalTitleNew]: 'New PKI Profile',
    [ignorePeerChainCheckboxLabel]: 'Ignore Peer Chain',
    [enableCrlCheckCheckboxLabel]: 'Enable CRL Check',
    [leafValidationCheckboxLabel]: 'Leaf Certificate CRL validation only',
    [certificateAuthorityHeader]: 'Certificate Authority (CA)',
    [certificateAuthoritiesLabel]: 'Certificate Authorities ({0})',
    [fileUploadSuccessfulMessage]: 'File uploaded successfully',
    [serverUrlRetrievedSuccessfulMessage]: 'Server URL retrieved successfully',
    [certificateRevocationListLabel]: 'Certificate Revocation List (CRL)',
    [certificateRevocationListPlaceholder]: 'Select Certificate Revocation List (CRL)',
    [crlHelperText]: 'Accepts multiple values',
    [addByServerUrlLabel]: 'Add by Server URL',
    [caWarningInfo]: 'At least one entry is required for CA',
    [allowedPKIErrors]: 'Allowed PKI Errors',
    [isFederatedChangeConfirmationLabel]: 'Confirm Is Federated Selection Change',
    [isFederatedChangeWarningMessage]: 'Changing the Is Federated Selection will result in selected CRLs getting removed.',
};
