<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderCreate) | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="onDismiss()"
    (onSubmit)="onSave()"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        [invalidateForm]="isFieldsMissing"
        clrForm
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">

                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="subnet-prefix"
                        required
                    >
                        {{ l10nKeys.subnetPrefixInputLabel | vtranslate }}
                    </label>
                    <input
                        id="subnet-prefix"
                        name="subnet-prefix"
                        clrInput
                        placeholder="{{ l10nKeys.subnetPrefixInputPlaceholder | vtranslate }}"
                        required
                        regexPattern="anySubnet"
                        [(ngModel)]="editable.subnet"
                        [disabled]="isEditing"
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset>
                            <avi-fieldset_header>
                                <clr-checkbox-container class="full-modal-config__form-control__no-margin-top">
                                    <clr-checkbox-wrapper>
                                        <input
                                            type="checkbox"
                                            clrCheckbox
                                            name="use-static-ip-for-se-vip"
                                            [(ngModel)]="editable.useStaticIpForSeAndVip"
                                            (ngModelChange)="onUseSaticIpChange()"
                                            id="use-static-ip-for-se-vip"
                                        />
                                        <label
                                            aviLabelWithTooltip
                                            for="use-static-ip-for-se-vip"
                                        >
                                            {{ l10nKeys.staticIpForVipSeInputLabel | vtranslate }}
                                        </label>
                                    </clr-checkbox-wrapper>
                                </clr-checkbox-container>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <avi-data-grid
                                    [config]="staticIpRangeGridConfig"
                                    [rows]="editable.static_ipaddr_tmp"
                                >
                                    <avi-data-grid_header>
                                        <label aviLabelWithTooltip>
                                            <avi-data-grid_header>
                                                <h6>
                                                    {{ l10nKeys.staticIpRangesTitle | vtranslate : editable.static_ipaddr_tmp?.length }}
                                                </h6>
                                            </avi-data-grid_header>
                                        </label>
                                    </avi-data-grid_header>
                                    <avi-data-grid_actions>
                                        <cds-button
                                            size="sm"
                                            (click)="addIpAddrRange()"
                                        >
                                            {{ globalL10nKeys.addLabel | vtranslate }}
                                        </cds-button>
                                    </avi-data-grid_actions>
                                </avi-data-grid>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <ng-template
            #ipAddressRangeTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    clrInput
                    id="{{ 'ip-range-' + index }}"
                    name="{{ 'ip-range-' + index }}"
                    placeholder="{{ l10nKeys.ipAddressRangeInputPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.static_ipaddr_tmp[index].range"
                    [inSubnet]="editable.subnet"
                    regexPattern="anyIPRange"
                    required
                />
            </avi-input-container>
        </ng-template>

        <ng-template
            #staticIpTypeTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ 'static-ip-' + index }}"
                id="{{ 'static-ip-' + index }}"
                [options]="staticIpTypesEnum"
                [(ngModel)]="editable.static_ipaddr_tmp[index].type"
                [hideSearch]="true"
                [disabled]="editable.useStaticIpForSeAndVip"
                required
            ></avi-dropdown>
        </ng-template>
    </form>

</full-modal-config>
