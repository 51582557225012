<avi-state-page
    class="seg-update-page clr-wrapper"
    [hasSidebar]="true"
>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.segUpdateLabel | vtranslate }}"></avi-page-header>
        <div class="seg-update-page__container">
            <system-about-card class="seg-update-page__update-card"></system-about-card>
            <update-progress-card
                class="seg-update-page__update-card"
                [isControllerUpgrade]="false"
                [selectedSegIds] = "selectedSegIds"
            ></update-progress-card>
        </div>
        <avi-collection-data-grid
            [config]="seGroupGridConfig"
            gridTitle="{{ l10nKeys.seGroupsLabel | vtranslate : seGroupCollection.itemList.length }}"
        ></avi-collection-data-grid>
    </avi-state-page_main-content>
    <avi-state-page_sidebar>
        <recent-updates-list
            *ngIf="showRightRailCards"
            [nodeType]="nodeType"
            [precheckNodes]="precheckNodes | async"
            [prechecksTriggered]="arePrechecksTriggered"
            [overallPrechecksState]="overallPrechecksState$ | async"
            [(selectedSegIds)]="selectedSegIds"
        ></recent-updates-list>
    </avi-state-page_sidebar>
</avi-state-page>

<ng-template
    #statusTemplateRef
    let-row
>
   <ng-container *ngIf="row.upgradeStatusInfo.prechecksInProgress || row.upgradeStatusInfo.upgradeInProgress else status">
        <clr-spinner clrSmall></clr-spinner>
        <span class="seg-update-page__in-progress-label">{{ row.upgradeStatusInfo.state }}</span>
   </ng-container>
   <ng-template #status>
        <cds-icon
            *ngIf="!row.upgradeStatusInfo.prechecksInProgress && !row.upgradeStatusInfo.upgradeInProgress"
            shape="{{ row.upgradeStatusInfo.shape }}"
            status="{{ row.upgradeStatusInfo.status }}"
        ></cds-icon>
        <span class="seg-update-page__status-label">{{ row.upgradeStatusInfo.state }}</span>
   </ng-template>
</ng-template>

<ng-template
    #segUpdateExpanderTemplateRef
    let-row
>
    <seg-update-grid-expander
        class="seg-update-page__seg-grid-expander"
        [seGroup]="row"
    ></seg-update-grid-expander>
</ng-template>
