/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'expander-action-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;

export const ENGLISH = {
    [cancelButtonLabel]: 'Cancel',
};
