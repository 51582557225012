/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'role-service';
const prefix = `${moduleName}.${componentName}`;

export const applicationLabel = `${prefix}.applicationLabel`;
export const virtualServiceLabel = `${prefix}.virtualServiceLabel`;
export const httpPolicySetLabel = `${prefix}.httpPolicySetLabel`;
export const networkSecurityPolicyLabel = `${prefix}.networkSecurityPolicyLabel`;
export const autoScaleLabel = `${prefix}.autoScaleLabel`;
export const dnsPolicyLabel = `${prefix}.dnsPolicyLabel`;
export const templatesLabel = `${prefix}.templatesLabel`;
export const networkProfileLabel = `${prefix}.networkProfileLabel`;
export const applicationProfileLabel = `${prefix}.applicationProfileLabel`;
export const persistenceProfileLabel = `${prefix}.persistenceProfileLabel`;
export const healthMonitorLabel = `${prefix}.healthMonitorLabel`;
export const analyticsProfileLabel = `${prefix}.analyticsProfileLabel`;
export const ipamDnsProfileLabel = `${prefix}.ipamDnsProfileLabel`;
export const customIpamDnsProfileLabel = `${prefix}.customIpamDnsProfileLabel`;
export const trafficCloneLabel = `${prefix}.trafficCloneLabel`;
export const icapProfileLabel = `${prefix}.icapProfileLabel`;
export const groupScriptLabel = `${prefix}.groupScriptLabel`;
export const ipAddressGroupLabel = `${prefix}.ipAddressGroupLabel`;
export const stringGroupLabel = `${prefix}.stringGroupLabel`;
export const dataScriptsLabel = `${prefix}.dataScriptsLabel`;
export const protocolParserScriptsLabel = `${prefix}.protocolParserScriptsLabel`;
export const sslTlsProfileLabel = `${prefix}.sslTlsProfileLabel`;
export const authenticationProfileLabel = `${prefix}.authenticationProfileLabel`;
export const pkiProfileLabel = `${prefix}.pkiProfileLabel`;
export const sslTlsCertificatesLabel = `${prefix}.sslTlsCertificatesLabel`;
export const certificateManagementProfileLabel = `${prefix}.certificateManagementProfileLabel`;
export const hardwareSecurityModuleGroupLabel = `${prefix}.hardwareSecurityModuleGroupLabel`;
export const ssoPolicyLabel = `${prefix}.ssoPolicyLabel`;
export const policyLabel = `${prefix}.policyLabel`;
export const wafLabel = `${prefix}.wafLabel`;
export const natPolicyLabel = `${prefix}.natPolicyLabel`;
export const l4PolicyLabel = `${prefix}.l4PolicyLabel`;
export const wafCrsLabel = `${prefix}.wafCrsLabel`;
export const positiveSecurityLabel = `${prefix}.positiveSecurityLabel`;
export const errorPageLabel = `${prefix}.errorPageLabel`;
export const errorPageProfileLabel = `${prefix}.errorPageProfileLabel`;
export const errorPageBodyLabel = `${prefix}.errorPageBodyLabel`;
export const operationsLabel = `${prefix}.operationsLabel`;
export const alertConfigLabel = `${prefix}.alertConfigLabel`;
export const alertLabel = `${prefix}.alertLabel`;
export const alertActionLabel = `${prefix}.alertActionLabel`;
export const snmpTrapsLabel = `${prefix}.snmpTrapsLabel`;
export const trafficCaptureLabel = `${prefix}.trafficCaptureLabel`;
export const infrastructureLabel = `${prefix}.infrastructureLabel`;
export const vrfContextLabel = `${prefix}.vrfContextLabel`;
export const userCredentialsLabel = `${prefix}.userCredentialsLabel`;
export const administrationLabel = `${prefix}.administrationLabel`;
export const systemSettingsLabel = `${prefix}.systemSettingsLabel`;
export const controllerLabel = `${prefix}.controllerLabel`;
export const rebootLabel = `${prefix}.rebootLabel`;
export const upgradeLabel = `${prefix}.upgradeLabel`;
export const troubleshootingLabel = `${prefix}.troubleshootingLabel`;
export const internalLabel = `${prefix}.internalLabel`;
export const controllerSiteLabel = `${prefix}.controllerSiteLabel`;
export const softwareLabel = `${prefix}.softwareLabel`;
export const accountsLabel = `${prefix}.accountsLabel`;
export const usersLabel = `${prefix}.usersLabel`;
export const gslbLabel = `${prefix}.gslbLabel`;
export const gslbConfigurationLabel = `${prefix}.gslbConfigurationLabel`;
export const gslbServicesLabel = `${prefix}.gslbServicesLabel`;
export const gslbGeoProfileLabel = `${prefix}.gslbGeoProfileLabel`;
export const assortedLabel = `${prefix}.assortedLabel`;
export const noAccessLabel = `${prefix}.noAccessLabel`;
export const authMappingProfileLabel = `${prefix}.authMappingProfileLabel`;

export const ENGLISH = {
    [applicationLabel]: 'Application',
    [virtualServiceLabel]: 'Virtual Service',
    [httpPolicySetLabel]: 'HTTP Policy Set',
    [networkSecurityPolicyLabel]: 'Network Security Policy',
    [autoScaleLabel]: 'AutoScale',
    [dnsPolicyLabel]: 'DNS Policy',
    [templatesLabel]: 'Templates',
    [networkProfileLabel]: 'Network Profile',
    [applicationProfileLabel]: 'Application Profile',
    [persistenceProfileLabel]: 'Persistence Profile',
    [healthMonitorLabel]: 'Health Monitor',
    [analyticsProfileLabel]: 'Analytics Profile',
    [ipamDnsProfileLabel]: 'IPAM/DNS Profile',
    [customIpamDnsProfileLabel]: 'Custom IPAM/DNS Profile',
    [trafficCloneLabel]: 'Traffic Clone',
    [icapProfileLabel]: 'ICAP Profile',
    [groupScriptLabel]: 'Group & Script',
    [ipAddressGroupLabel]: 'IP Address Groups',
    [stringGroupLabel]: 'String Groups',
    [dataScriptsLabel]: 'DataScripts',
    [protocolParserScriptsLabel]: 'ProtocolParserScripts',
    [sslTlsProfileLabel]: 'SSL/TLS Profile',
    [authenticationProfileLabel]: 'Authentication Profile',
    [pkiProfileLabel]: 'PKI Profile',
    [sslTlsCertificatesLabel]: 'SSL/TLS Certificates',
    [certificateManagementProfileLabel]: 'Certificate Management Profile',
    [hardwareSecurityModuleGroupLabel]: 'Hardware Security Module Groups',
    [ssoPolicyLabel]: 'SSO Policy',
    [policyLabel]: 'Policy',
    [natPolicyLabel]: 'NAT Policy',
    [l4PolicyLabel]: 'L4 Policy Set',
    [wafLabel]: 'WAF',
    [wafCrsLabel]: 'CRS',
    [positiveSecurityLabel]: 'Positive Security',
    [errorPageLabel]: 'Error Page',
    [errorPageProfileLabel]: 'Error Page Profile',
    [errorPageBodyLabel]: 'Error Page Body',
    [operationsLabel]: 'Operations',
    [alertConfigLabel]: 'Alert Config',
    [alertLabel]: 'Alert',
    [alertActionLabel]: 'Alert Action',
    [snmpTrapsLabel]: 'SNMP Traps',
    [trafficCaptureLabel]: 'Traffic Capture',
    [infrastructureLabel]: 'Infrastructure',
    [vrfContextLabel]: 'VRF Context',
    [userCredentialsLabel]: 'User Credentials',
    [administrationLabel]: 'Administration',
    [systemSettingsLabel]: 'System Settings',
    [rebootLabel]: 'Reboot',
    [upgradeLabel]: 'Upgrade',
    [troubleshootingLabel]: 'Troubleshooting',
    [internalLabel]: 'Internal',
    [controllerSiteLabel]: 'Controller Site',
    [softwareLabel]: 'Software',
    [accountsLabel]: 'Accounts',
    [usersLabel]: 'Users',
    [gslbLabel]: 'GSLB',
    [gslbConfigurationLabel]: 'GSLB Configuration',
    [gslbServicesLabel]: 'GSLB Services',
    [gslbGeoProfileLabel]: 'GSLB Geo Profile',
    [assortedLabel]: 'Assorted',
    [noAccessLabel]: 'No Access',
    [authMappingProfileLabel]: 'Auth Mapping Profile',
};
