/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'ng/modules/core/services/full-modal/full-modal.service';

import { L10nService } from '@vmw/ngx-vip';

import {
    TrafficCaptureModalComponent,
    TrafficCapturePreviewComponent,
} from '../components';

import * as l10n from './traffic-capture-modal.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service to open/close the traffic capture modal.
 *
 * @author Suraj Kumar
 */
@Injectable()
export class TrafficCaptureModalService {
    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to open traffic capture modal.
     */
    public openModal(serviceEngineName: string): void {
        this.fullModalService.addModal(this.getModalProps(serviceEngineName));
    }

    /**
     * Method to close traffic capture modal.
     */
    public closeModal(): void {
        this.fullModalService.removeModalByComponent(
            TrafficCaptureModalComponent as Type<Component>,
        );
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getModalProps(serviceEngineName: string): IFullModalLayout {
        return {
            component: TrafficCaptureModalComponent as Type<Component>,
            componentProps: { serviceEngineName },
            previewComponent: TrafficCapturePreviewComponent as Type<Component>,
            getName: () => this.l10nService.getMessage(l10nKeys.exportModalName),
            getDescription: () => serviceEngineName,
        };
    }
}
