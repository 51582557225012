/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CLOUD_NONE,
    CLOUD_VCENTER,
    CLOUD_OPENSTACK,
    CLOUD_AWS,
    CLOUD_LINUXSERVER,
    CLOUD_AZURE,
    CLOUD_GCP,
    CLOUD_NSXT,
} from 'ajs/modules/cloud/cloud.constants';

import { CLOUD_ITEM_TOKEN } from 'ajs/modules/cloud/cloud.tokens';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation';

import {
    CloudTokenDialogComponent,
} from 'ng/modules/cloud/components/cloud-token-dialog/cloud-token-dialog.component';

import * as l10n from './CloudListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * List of cloud types and labels used for the cloud creation dropdown button.
 * @type {string[]}
 */
const cloudCreateActionsOrder = [
    CLOUD_NSXT,
    CLOUD_VCENTER,
    CLOUD_OPENSTACK,
    CLOUD_AWS,
    CLOUD_LINUXSERVER,
    CLOUD_AZURE,
    CLOUD_GCP,
    CLOUD_NONE,
];

/**
 * Used to override the default cloud label text, derived from the Schema description.
 * @type {Object.<string, string>}
 */
const cloudLabelOverride = {
    [CLOUD_NONE]: 'No Orchestrator',
};

/**
 * Cloud token dialog ID.
 */
const CLOUD_TOKEN_DIALOG_ID = 'cloud-token-dialog';

/**
 * ID for Convert Cloud to No Access Dialog.
 */
const CLOUD_NO_ACCESS_DIALOG_ID = 'cloud-no-access';

/**
 * ID for Convert Cloud Type Dialog.
 */
const CONVERT_CLOUD_TYPE_DIALOG_ID = 'convert-cloud-type';

/**
 * Return the Configured Default Gateway based on provided IP type param.
 * @param {Cloud} row
 * @param {string} ipType
 * @returns {Object | null}
 */
const getDefaultGateway = (row, ipType) => {
    const staticRoutes = row.data?.mvrf?.static_routes;

    if (staticRoutes && Array.isArray(staticRoutes)) {
        return staticRoutes.find(
            staticRoute => staticRoute?.next_hop?.type === ipType,
        )?.next_hop?.addr;
    }

    return null;
};

/**
 * @ngdoc controller
 * @name CloudListController
 */
angular.module('aviApp').controller('CloudListController', [
'$scope',
'CRUDGridConfig',
'CloudCollection',
'$http',
'Base',
'AviModal',
'cloudService',
'Auth',
'$timeout',
'aviAlertService',
'$window',
CLOUD_ITEM_TOKEN,
'$q',
'l10nService',
'licenseBasedFeaturesService',
'dialogService',
function(
    $scope,
    CRUDGridConfig,
    CloudCollection,
    $http,
    Base,
    AviModal,
    cloudService,
    Auth,
    $timeout,
    aviAlertService,
    $window,
    Cloud,
    $q,
    l10nService,
    licenseBasedFeaturesService,
    dialogService,
) {
    l10nService.registerSourceBundles(dictionary);
    $scope.gridConfig = new CRUDGridConfig();
    $scope.gridConfig.props = {
        l10nKeys,
        getDefaultGateway,
    };

    $scope.ui = {};
    $scope.ui.gridBusyState = {
        isBusy: false,
        message: undefined,
    };

    const base = new Base();

    const linuxServerHostsGridConfig = {
        id: 'linux-server-hosts',
        singleactions: null,
        multipleactions: null,
        rowId: row => row.host,
        props: {
            l10nKeys,
        },
        fields: [{
            name: 'host',
            title: l10nService.getMessage(l10nKeys.columnTitleHost),
        }, {
            name: 'host_state',
            title: l10nService.getMessage(l10nKeys.columnTitleHostState),
            template:
                `<div
                    ng-if="row.host_state"
                    ng-bind-html="config.props.l10nKeys.statusLabel | vtranslate : true
                        : (row.host_state | enum: 'CC_HOST_')">
                </div>
                <div
                    ng-if="row.host_reason"
                    ng-bind-html="config.props.l10nKeys.reasonLabel | vtranslate: true
                        : (row.host_reason || '')">
                </div>`,
        }, {
            name: 'se_state',
            title: l10nService.getMessage(l10nKeys.columnTitleServiceEngineState),
            template:
                `<div
                    ng-if="row.se_state"
                    ng-bind-html="config.props.l10nKeys.statusLabel | vtranslate : true
                        : (row.se_state | enum: 'CC_HOST_')">
                </div>
                <div
                    ng-if="row.host_reason"
                    ng-bind-html="config.props.l10nKeys.reasonLabel | vtranslate : true
                        : (row.se_reason[0] || '')">
                </div>`,
        }],
        layout: {
            hideEditColumns: true,
            hideSearch: true,
        },
    };

    /**
     * Handles 'collectionLoad' event from CloudCollection.
     */
    const checkCollectionStateHandler = function() {
        if (cloudCollection.getNumberOfItems()) {
            cloudService.displayCloudDataStatus(cloudCollection.items);
        }
    };

    const cloudCollection = new CloudCollection();

    cloudCollection.bind('collectionLoadSuccess', checkCollectionStateHandler);

    const { objectName } = cloudCollection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.collection = cloudCollection;
    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'type',
        title: l10nService.getMessage(l10nKeys.columnTitleType),
        template: '<span class="sel-vtype">{{ row.getVtype() | enumText: "CloudType" }}</span>',
    }, {
        name: 'state',
        require: 'status',
        title: l10nService.getMessage(l10nKeys.columnTitleStatus),
        template: '<cloud-status-icon cloud="row"></cloud-status-icon>',
    }];

    // since getLinuxHostsStatus is async can be the case when callback gets executed after
    // previous $timeout has been cancelled
    let expanderId = 0,
        linuxHostsPollPromise;

    const getLinuxHostsStatus = function(gridScope, cloudId) {
        const currentExpanderId = expanderId;

        gridScope.linuxServerHostsGridConfig = linuxServerHostsGridConfig;

        return $http.get(`/api/cloud/${cloudId}/hosts`)
            .then(function(response) {
                if (expanderId === currentExpanderId) {
                    linuxServerHostsGridConfig.rows = response.data.hosts;
                    linuxHostsPollPromise = $timeout(
                        getLinuxHostsStatus, 10000, true, gridScope, cloudId,
                    );
                }
            });
    };

    /**
     * Generates a token and shows a notification containing the generated token.
     */
    const generateToken = cloud => {
        const requests = [$http.get(`/api/securetoken-generate?cloud_uuid=${cloud.id}`)];

        if (Auth.isAllowed('PERMISSION_CONTROLLER')) {
            requests.push($http.get('/api/cluster/'));
        }

        $q.all(requests).then(responses => {
            if (!$scope.$$destroyed) {
                const [tokenResponse, clusterResponse] = responses;
                const token = tokenResponse.data.auth_token;
                const clusterUuid = clusterResponse && clusterResponse.data.uuid;

                dialogService.add({
                    id: CLOUD_TOKEN_DIALOG_ID,
                    component: CloudTokenDialogComponent,
                    componentProps: {
                        authToken: token,
                        clusterUuid,
                        cloudName: cloud.getName(),
                        tenantName: Auth.getTenantName(),
                        onClose: () => {
                            dialogService.remove(CLOUD_TOKEN_DIALOG_ID);
                        },
                    },
                });
            }
        });
    };

    /**
     * Used to handle submit request to Convert Cloud to No Access.
     * Invoked on click of continue button from Modal.
     * @param {Cloud} cloud
     */
    const convertCloudToNoAccess = cloud => {
        cloud.setCloudNone().catch(response => {
            if (response.data.error) {
                aviAlertService.throw(response.data.error);
            }

            cloud.load();
        });
    };

    /**
     * Used to open Convert To No Access Dialog.
     * Invoked on click of no-access from Grid.
     * @param {Cloud} cloud
     */
    const openConvertToNoAccessDialog = cloud => {
        const cloudName = cloud.getName();

        dialogService.add({
            id: CLOUD_NO_ACCESS_DIALOG_ID,
            component: AviContinueConfirmationComponent,
            componentProps: {
                warning: l10nService.getMessage(l10nKeys.convertToCloudNone, [cloudName]),
                onConfirm: () => {
                    dialogService.remove(CLOUD_NO_ACCESS_DIALOG_ID);
                    convertCloudToNoAccess(cloud);
                },
                onClose: () => {
                    dialogService.remove(CLOUD_NO_ACCESS_DIALOG_ID);
                },
            },
        });
    };

    $scope.gridConfig.executeBeforeContainerExpand = function(row) {
        if (row.getVtype() === CLOUD_LINUXSERVER) {
            getLinuxHostsStatus(this, row.data.config.uuid);
        }
    };

    $scope.gridConfig.executeOnExpandDestroy = function() {
        expanderId++;
        $timeout.cancel(linuxHostsPollPromise);
    };

    $scope.gridConfig.singleactions.push({
        title: l10nService.getMessage(l10nKeys.convertToNoOrchestratorTooltip),
        class: 'icon-block sel-block',
        dontCloseExpander: true,
        do: cloud => openConvertToNoAccessDialog(cloud),
        hidden(cloud) {
            const type = cloud.getVtype();

            return type === CLOUD_NONE || type === CLOUD_NSXT;
        },
    }, {
        title: l10nService.getMessage(l10nKeys.downloadSeImageTooltip),
        dontCloseExpander: true,
        bypassPermissionsCheck: true,
        template: require('./se-image-picker.partial.html'),
        hidden: cloud => {
            const vtype = cloud.getVtype();

            return !Auth.isAllowed('PERMISSION_CLOUD', 'w') ||
                !(vtype === CLOUD_NONE || vtype === CLOUD_VCENTER &&
                cloud.getCloudConfig().privilege === 'READ_ACCESS');
        },
    }, {
        title: l10nService.getMessage(l10nKeys.installLbaasPluginTooltip),
        dontCloseExpander: true,
        class: 'icon-plug-1',
        do(row) {
            AviModal.open('adm-sysconf-os-install-plugin', {
                cloudId: row.id,
            });
        },
        hidden(row) {
            return row.getVtype() !== CLOUD_OPENSTACK;
        },
    }, {
        title: l10nService.getMessage(l10nKeys.generateTokenTooltip),
        dontCloseExpander: true,
        class: 'icon-key-inv',
        bypassPermissionsCheck: true,
        do: row => generateToken(row),
        hidden(row) {
            return !Auth.isAllowed('PERMISSION_SE_TOKEN') ||
                !(row.getVtype() === CLOUD_NONE || row.getVtype() === CLOUD_VCENTER &&
                row.getCloudConfig().privilege === 'READ_ACCESS');
        },
    }, {
        class: 'icon-refresh',
        title: l10nService.getMessage(l10nKeys.rediscoverTooltip),
        hidden: row => row.getVtype() !== CLOUD_VCENTER,
        do: row => row.vCenterRediscover(),
    });

    $scope.gridConfig.expandedContainerTemplate = require(
        './cloud-list-grid-expander.partial.html',
    );

    $scope.gridConfig.expanderDisabled = cloud => {
        const vtype = cloud.getVtype();

        return vtype === CLOUD_AZURE || vtype === CLOUD_NSXT;
    };

    $scope.gridConfig.layout = {
        includeTimeframeSelector: true,
    };

    const supportedCloudTypesMap = licenseBasedFeaturesService.getSupportedCloudTypesMap();

    $scope.gridConfig.createActions = cloudCreateActionsOrder
        .filter(cloudType => supportedCloudTypesMap.get(cloudType))
        .map(cloudType => ({
            label: cloudLabelOverride[cloudType] || Cloud.getCloudTypeName(cloudType),
            onClick: () => {
                cloudCollection.setDefaultItemConfigProps({
                    vtype: cloudType,
                });

                cloudCollection.create();
            },
        }));

    /**
     * Allows user to download SE image by making POST request followed by assigning a URL to the
     * window. Can cancel previous requests if clicked multiple times.
     * @param  {Cloud}  row - Cloud object.
     * @param  {String} format - format of image, either 'ova' or 'qcow2'.
     */
    const downloadSEImage = (function() {
        let requestCancelled = false;

        function cancelDownloadSEImage() {
            requestCancelled = true;
            base.cancelRequests('downloadSEImage');
        }

        return function(row, format) {
            if (isGridBusy()) {
                cancelDownloadSEImage();
            }

            const { config } = row.data,
                api = '/api/fileservice/seova',
                payload = {
                    file_format: format,
                };

            if (config.name !== 'Default-Cloud') {
                payload.cloud_uuid = config.uuid;
            }

            setGridBusyState(true,
                l10nService.getMessage(l10nKeys.creatingSeImageForDownloadMessage));

            base.request('POST', api, payload, null, 'downloadSEImage')
                .then(function(rsp) {
                    setGridBusyState(false);

                    const getAPI = `${api}?${_.reduce(payload, function(acc, value, key) {
                        return `${acc + key}=${value}&`;
                    }, '')}`;

                    $window.location.assign(getAPI);
                }).catch(function(rsp) {
                    if (rsp.data && rsp.data.error) {
                        aviAlertService.throw(rsp.data.error);
                    }
                }).finally(function() {
                    if (!requestCancelled) {
                        setGridBusyState(false);
                    } else {
                        requestCancelled = false;
                    }
                });
        };
    })();

    /**
     * Sets gridBusyState for CollectionGrid.
     * @param  {Boolean} busy - True to set to busy, false otherwise.
     * @param  {String}  message - Message to be displayed next to spinner.
     */
    function setGridBusyState(busy, message) {
        const { ui } = $scope;

        ui.gridBusyState.isBusy = busy;
        ui.gridBusyState.message = message;
    }

    /**
     * Returns true if CollectionGrid is busy.
     * @return {Boolean}
     */
    function isGridBusy() {
        return $scope.ui.gridBusyState.isBusy;
    }

    $scope.gridConfig.downloadSEImage = downloadSEImage;

    $scope.$on('$destroy', () => {
        cloudCollection.destroy();
        base.destroy();

        if (dialogService.has(CLOUD_TOKEN_DIALOG_ID)) {
            dialogService.remove(CLOUD_TOKEN_DIALOG_ID);
        }

        if (dialogService.has(CLOUD_NO_ACCESS_DIALOG_ID)) {
            dialogService.remove(CLOUD_NO_ACCESS_DIALOG_ID);
        }

        if (dialogService.has(CONVERT_CLOUD_TYPE_DIALOG_ID)) {
            dialogService.remove(CONVERT_CLOUD_TYPE_DIALOG_ID);
        }

        cloudService.removeCloudStatusNotification();
    });
}]);
