<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.authenticationHeader | vtranslate }}</h6>
        </div>
    </div>
    <div 
        class="full-modal-config__form-control-row health-monitor-authentication__no-margin-top"
        *ngIf="showAuthTypeDropdown"
    >
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                fieldName="auth_type"
                for="auth-type"
            >
                {{ l10nKeys.authenticationTypeInputLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="auth-type"
                id="auth-type"
                enum="HealthMonitorAuthType"
                [(ngModel)]="healthMonitorTypeConfig.config.auth_type"
                (ngModelChange)="handleAuthenticationTypeChange()"
                placeholder="{{ l10nKeys.authenticationTypeInputPlaceholder | vtranslate }}"
            ></avi-enum-dropdown>
        </div>
    </div>
    <div
        class="full-modal-config__form-control-row"
        [ngClass]="{'health-monitor-authentication__no-margin-top': !showAuthTypeDropdown}"
        *ngIf="!showAuthTypeDropdown || healthMonitorTypeConfig.config.auth_type"
    >
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorAuthInfo }}"
                    [required]="showAuthTypeDropdown || isUsernameSet || isPasswordSet"
                    for="username"
                    fieldName="username"
                >
                    {{ l10nKeys.usernameInputLabel | vtranslate }}
                </label>
                <input
                    id="username"
                    name="username"
                    type="password"
                    clrInput
                    [required]="showAuthTypeDropdown || isUsernameSet || isPasswordSet"
                    placeholder="{{ l10nKeys.usernameInputPlaceholder | vtranslate }}"
                    [(ngModel)]="authentication.config.username"
                    (ngModelChange)="onUsernameChange($event)"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorAuthInfo }}"
                    [required]="showAuthTypeDropdown || isUsernameSet || isPasswordSet"
                    for="password"
                    fieldName="password"
                >
                    {{ l10nKeys.passwordInputLabel | vtranslate }}
                </label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    clrInput
                    [required]="showAuthTypeDropdown || isUsernameSet || isPasswordSet"
                    placeholder="{{ l10nKeys.passwordInputPlaceholder | vtranslate }}"
                    [(ngModel)]="authentication.config.password"
                    (ngModelChange)="onPasswordChange($event)"
                />
            </avi-input-container>
        </div>
    </div>
</div>
