/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'l4-policy';

export const l4PolicySetModalBreadcrumbTitle = `${prefix}.l4PolicySetModalBreadcrumbTitle`;
export const l4RuleModalBreadcrumbTitle = `${prefix}.l4RuleModalBreadcrumbTitle`;

export const ENGLISH = {
    [l4PolicySetModalBreadcrumbTitle]: 'L4 Policy Set',
    [l4RuleModalBreadcrumbTitle]: 'L4 Rule',
};
