/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WebhookModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { createNgDependencyProviders } from '../data-model';
import { WebhookCollection, WebhookItem } from './factories';
import { WebhookListPageComponent } from './components';

const providers = createNgDependencyProviders([
    WebhookItem,
    WebhookCollection,
]);

/**
 * @description Angular Module for Webhook related components.
 * @author Hitesh Mandav, alextsg
 */
@NgModule({
    imports: [
        DataGridModule,
        SharedModule,
    ],
    declarations: [
        WebhookListPageComponent,
    ],
    providers,
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class WebhookModule {}
