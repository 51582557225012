/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function alertListDataTransformerFactory(ListDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransformer
     * @author Ashish Verma
     * @desc ListDataTransformer of {@link module:avi/app.AlertList AlertList}.
     */
    class AlertListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            resp = super.processResponse(resp, request);

            /* Need this to provide the same structure of events as events collection has. */
            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                resp.data.results.forEach(function(alert) {
                    alert = alert.config;

                    alert.timestamp *= 1000;

                    if ('metric_info' in alert && Array.isArray(alert.metric_info)) {
                        alert.metric_info.forEach(metricInfo => {
                            metricInfo.end_timestamp *= 1000;
                            metricInfo.report_timestamp *= 1000;
                        });
                    }

                    if ('events' in alert && Array.isArray(alert.events)) {
                        alert.events = alert.events.map(function(event) {
                            event.report_timestamp *= 1000;

                            if (alert.action_script_output) {
                                event.action_script_output = alert.action_script_output;
                            }

                            return { data: { config: event } };
                        });
                    }
                });
            }

            return resp;
        }
    }

    return AlertListDataTransformer;
}

alertListDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

angular.module('avi/app')
    .factory(
        'AlertListDataTransformer',
        alertListDataTransformerFactory,
    );
