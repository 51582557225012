/**
 *  @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Ajs dependency token for StringGroupItem.
 */
export const STRING_GROUP_ITEM_TOKEN = 'StringGroupItem';

/**
 * Ajs dependency token for StringGroupCollection.
 */
export const STRING_GROUP_COLLECTION_TOKEN = 'StringGroupCollection';

/**
 * Ajs dependency token for IpAddrGroupItem.
 */
export const IP_ADDRESS_GROUP_ITEM_TOKEN = 'IpAddrGroupItem';

/**
 * Ajs dependency token for IpAddrGroupCollection.
 */
export const IP_ADDRESS_GROUP_COLLECTION_TOKEN = 'IpAddrGroupCollection';
