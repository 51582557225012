<div class="auth-mapping-rules-grid">
    <avi-data-grid
        [config]="authMappingRuleGridConfig"
        [rows]="authMappingRules.config"
        (onRowOrderChange)="handleRowReorder($event)"
        gridTitle="{{ l10nKeys.authMappingRulesLabel | vtranslate : authMappingRules.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addAuthMappingRule()"
                [disabled]="disableAddButton"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>

    <ng-template
        #expandedContentTemplateRef
        let-row
    >
        <match-and-action-display-wrapper>
            <match-and-action-display-wrapper_match-content>
                <auth-match-attribute-readonly
                    [editable]="row.config.attribute_match"
                ></auth-match-attribute-readonly>

                <auth-match-group-membership-readonly
                    [editable]="row.config.group_match"
                ></auth-match-group-membership-readonly>
            </match-and-action-display-wrapper_match-content>

            <match-and-action-display-wrapper_action-content>
                <div class="auth-mapping-rules-grid__action">
                    <div class="auth-mapping-rules-grid__action-label">{{ l10nKeys.mappingLabel | vtranslate }}</div>
                    <div>{{ getMappingLabel(row) }}</div>
                </div>
            </match-and-action-display-wrapper_action-content>
        </match-and-action-display-wrapper>
    </ng-template>
</div>
