<cds-modal
    class="clr-wrapper seg-image-selection-modal"
    size="lg"
    (closeChange)="handleClose()"
>
    <cds-modal-header>
        <h3>{{ l10nKeys.compatibleUpdateModalTitle | vtranslate }}</h3>
    </cds-modal-header>

    <cds-modal-content>
        <div class="seg-image-selection-modal__content-container">
            <selected-seg-list [segUpgradeNodes]="segUpgradeNodeList"></selected-seg-list>

            <avi-collection-data-grid 
                class="seg-image-selection-modal__data-grid"
                [config]="availableUpdatesGridConfig"
                gridTitle="{{ l10nKeys.availableUpdatesHeader | vtranslate }}"
                (onSelectionChange)="onImageSelectionChange($event)"
            ></avi-collection-data-grid>
        </div>
    </cds-modal-content>

    <cds-modal-actions>
        <cds-button
            action="outline"
            (click)="handleClose()"
        >
            {{ globalL10nKeys.cancelLabel | vtranslate }}
        </cds-button>
        <cds-button
            (click)="handleSubmit()"
            [disabled]="selectedImagesToUpgrade.length == 0 || !isSelectedImagesValidForUpgrade"
        >
            {{ l10nKeys.continueLabel | vtranslate }}
        </cds-button>
    </cds-modal-actions>
</cds-modal>

<ng-template
    #imageTypeTemplateRef
    let-row
>
    <div>
        <cds-icon
            [shape]="row.isType('IMAGE_TYPE_SYSTEM') ? 'display' : 'wrench'"
            size="18"
        ></cds-icon>
        <span> {{ row.type | enumValueLabel : 'ImageType' }}</span>
    </div>
</ng-template>
