/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

//TODO figure out a better way of defining search param name, not sure it belongs here
function listCollDataSourceWithSearchParamFactory(ListCollDataSource) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.ListCollDataSource
     * @author Ashish Verma
     * @desc
     *     ListCollDataSource with 'search' as a search param name. Used for custom
     *     APIs which don't support the usual "name.icontains".
     */
    class ListCollDataSourceWithSearchParam extends ListCollDataSource {}

    ListCollDataSourceWithSearchParam.prototype.defaultSearchParamName_ = 'search';

    return ListCollDataSourceWithSearchParam;
}

listCollDataSourceWithSearchParamFactory.$inject = [
    'ListCollDataSource',
];

angular.module('avi/dataModel').factory(
    'ListCollDataSourceWithSearchParam',
    listCollDataSourceWithSearchParamFactory,
);
