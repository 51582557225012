/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import { HsmGroupDownloadCertificateModalComponent } from 'ng/modules/security';
import { HSMSafenetClientInfoConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-download-certificate-modal.service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Service to open/close Thales Luna HSM Download Certificate modal.
 * @author vgohil
 */
@Injectable()
export class HsmGroupDownloadCertificateModalService {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method to open Thales Luna HSM Download Certificate modal.
     */
    public openModal(
        name: string,
        clientInfo: RepeatedMessageItem<HSMSafenetClientInfoConfigItem>,
    ): void {
        this.fullModalService.addModal(this.getExportModalProps(name, clientInfo));
    }

    /**
     * Method to close Thales Luna HSM Download Certificate modal.
     */
    public closeModal(): void {
        this.fullModalService
            .removeModalByComponent(HsmGroupDownloadCertificateModalComponent as Type<Component>);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getExportModalProps(
        name: string,
        clientInfo: RepeatedMessageItem<HSMSafenetClientInfoConfigItem>,
    ): IFullModalLayout {
        const { l10nService } = this;
        const onCloseModal = this.closeModal.bind(this);

        return {
            component: HsmGroupDownloadCertificateModalComponent as Type<Component>,
            componentProps: {
                name,
                clientInfo,
                onCloseModal,
            },
            getName: () => l10nService.getMessage(l10nKeys.hsmGroupModalName),
            getDescription: () => name,
        };
    }
}
