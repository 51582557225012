<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    submitButtonText="{{ l10nKeys.submitButtonLabel | vtranslate }}"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid || disableFormField && !disableSubmit"
    class="case-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.caseSectionLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.caseSectionLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="subject"
                        required
                    >
                        {{ l10nKeys.subjectLabel | vtranslate }}
                    </label>
                    <input
                        id="subject"
                        name="subject"
                        [(ngModel)]="editable.config.subject"
                        clrInput
                        placeholder="{{ l10nKeys.subjectPlaceholder | vtranslate }}"
                        [disabled]="disableFormField"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="contact"
                            required
                        >
                            {{ l10nKeys.contactLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="contact"
                            [options]="contactOptions"
                            [(ngModel)]="editable.config.email"
                            placeholder="{{ l10nKeys.contactPlaceholder | vtranslate }}"
                            [disabled]="disableContactField"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="caseTypesOptions"
                            [(ngModel)]="editable.config.type"
                            (ngModelChange)="handleCaseTypeChange()"
                            [hideSearch]="true"
                            [disabled]="disableFormField"
                            required
                        ></avi-dropdown>
                    </div>
                    <div
                        class="full-modal-config__form-control"
                        *ngIf="displaySeverity"
                    >
                        <label
                            aviLabelWithTooltip
                            for="severity"
                            required
                        >
                            {{ l10nKeys.severityLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="severity"
                            [options]="severitiesOptions"
                            [(ngModel)]="editable.config.severity"
                            [hideSearch]="true"
                            [disabled]="disableFormField"
                            required
                        ></avi-dropdown>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                for="version"
                            >
                                {{ l10nKeys.versionLabel | vtranslate }}
                            </label>
                            <input
                                name="version"
                                [(ngModel)]="editable.config.version"
                                clrInput
                                disabled
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="environment"
                            required
                        >
                            {{ l10nKeys.environmentLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="environment"
                            [options]="environmentsOptions"
                            [(ngModel)]="editable.config.environment"
                            [disabled]="disableFormField"
                            placeholder="{{ l10nKeys.environmentPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <div *ngIf="isFeatureRequest()">
                    <avi-textarea-container>
                        <label
                            aviLabelWithTooltip
                            for="fr_use_cases"
                        >
                            {{ l10nKeys.useCasesLabel | vtranslate }}
                        </label>
                        <textarea
                            clrTextarea
                            id="fr_use_cases"
                            name="fr_use_cases"
                            [(ngModel)]="editable.config.fr_use_cases"
                            placeholder="{{ l10nKeys.useCasesPlaceholder | vtranslate }}"
                            [disabled]="disableFormField"
                            rows="5"
                        ></textarea>
                    </avi-textarea-container>
                    <avi-textarea-container>
                        <label
                            aviLabelWithTooltip
                            for="fr_business_justification"
                        >
                            {{ l10nKeys.businessJustificationLabel | vtranslate }}
                        </label>
                        <textarea
                            clrTextarea
                            id="fr_business_justification"
                            name="fr_business_justification"
                            [(ngModel)]="editable.config.fr_business_justification"
                            placeholder="{{ l10nKeys.businessJustificationPlaceholder | vtranslate }}"
                            [disabled]="disableFormField"
                            rows="5"
                        ></textarea>
                    </avi-textarea-container>
                    <avi-textarea-container>
                        <label
                            aviLabelWithTooltip
                            for="fr_current_solution"
                        >
                            {{ l10nKeys.currentSolutionLabel | vtranslate }}
                        </label>
                        <textarea
                            clrTextarea
                            id="fr_current_solution"
                            name="fr_current_solution"
                            [(ngModel)]="editable.config.fr_current_solution"
                            placeholder="{{ l10nKeys.currentSolutionPlaceholder | vtranslate }}"
                            [disabled]="disableFormField"
                            rows="5"
                        ></textarea>
                    </avi-textarea-container>
                </div>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        required
                    >
                        {{ l10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        name="description"
                        [(ngModel)]="editable.config.description"
                        placeholder="{{ l10nKeys.descriptionPlaceholder | vtranslate }}"
                        [disabled]="disableFormField"
                        rows="5"
                        required
                    ></textarea>
                </avi-textarea-container>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="isPortalModeMyvmware"
                >
                    <div class="full-modal-config__form-control">
                        <avi-textarea-container>
                            <label
                                aviLabelWithTooltip
                                for="custom_tag"
                            >
                                {{ l10nKeys.customTagLabel | vtranslate }}
                            </label>
                            <textarea
                                clrTextarea
                                id="custom_tag"
                                name="custom_tag"
                                [(ngModel)]="editable.config.custom_tag"
                                placeholder="{{ l10nKeys.customTagPlaceholder | vtranslate }}"
                                rows="5"
                                disabled
                            ></textarea>
                        </avi-textarea-container>
                    </div>
                </div>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="portalMode && !isPortalModeMyvmware"
                >
                    <div class="full-modal-config__form-control">
                        <case-custom-tags-grid
                            [editable]="editable"
                            [portalMode]="portalMode"
                            [disableGridControls]="disableFormField"
                        ></case-custom-tags-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.attachmentsLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.attachmentsLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <add-attachments-grid [editable]="editable"></add-attachments-grid>
                    </div>
                </div>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="hasCaseAttachments"
                >
                    <div class="full-modal-config__form-control">
                        <case-attachments-grid [caseAttachments]="editable.caseAttachments"></case-attachments-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.activityLabel | vtranslate }}"
            *ngIf="isEditMode"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.activityLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <div
                            class="case-modal__spinner"
                            *ngIf="loadingComments"
                        >
                            <clr-spinner [clrSmall]="true"></clr-spinner>
                        </div>
                        <case-comments-list
                            *ngIf="!loadingComments"
                            [caseComments]="comments"
                        ></case-comments-list>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
