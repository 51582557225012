/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { anyIPRangeOrSubnetList } from 'ng/utils/regex.utils';
import { StringService } from 'string-service';
import * as l10n from './ip-addr-match-entry.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum AddressType {
    IP_GROUP = 'IP_GROUP',
    CUSTOM_VALUE = 'CUSTOM_VALUE',
}

/**
 * @description Component for entering an IP Group ref, IP address, range, or prefix.
 * @author alextsg
 */
@Component({
    selector: 'ip-addr-match-entry',
    templateUrl: './ip-addr-match-entry.component.html',
})
export class IpAddrMatchEntryComponent implements OnDestroy {
    @Input('address')
    private set setAddress(address: string) {
        this.address = address;

        if (address && this.stringService.name(address)) {
            this.addressType = AddressType.IP_GROUP;
        } else if (anyIPRangeOrSubnetList.test(address)) {
            this.addressType = AddressType.CUSTOM_VALUE;
        }
    }

    /**
     * Index of the IpAddrMatchEntry component within the IpAddrMatch *ngFor. Needed to generate a
     * unique name attribute for the [(ngModel)] binding.
     */
    @Input()
    public index: number;

    /**
     * fieldName passed to the IpAddrMatchEntryComponent.
     */
    @Input()
    public fieldName = '';

    /**
     * Event Emitter for the address change.
     */
    @Output()
    public addressChange = new EventEmitter<string>();

    public readonly l10nKeys = l10nKeys;

    /**
     * IpAddrGroupCollection instance for selecting an IP Group.
     */
    public readonly ipAddrGroupCollection: any;

    /**
     * ngModel value of the IpAddrGroupCollection dropdown as well as the input for entering an IP
     * address, prefix, or range.
     */
    public address: string;

    /**
     * Custom AddressType enum. Used for selecting between the radio options for IP Group or Custom
     * value.
     */
    public AddressType = AddressType;

    /**
     * ngModel value of the radio buttons to select between an IP Group or Custom value.
     */
    public addressType: AddressType = AddressType.IP_GROUP;

    constructor(
        l10nService: L10nService,
        @Inject('IpAddrGroupCollection')
        IpAddrGroupCollection: any,
        private stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.ipAddrGroupCollection = new IpAddrGroupCollection();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.ipAddrGroupCollection.destroy();
    }

    /**
     * Called when the address has changed.
     */
    public handleAddressChange(): void {
        this.addressChange.emit(this.address);
    }

    /**
     * Called when the radio button options have changed.
     */
    public handleAddressTypeChange(): void {
        this.address = '';
        this.handleAddressChange();
    }
}
