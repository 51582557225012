<div
    class="entries-list"
    provideParentForm
>
    <avi-data-grid
        [config]="entriesGridConfig"
        [rows]="paramsList.config"
        [invalidateForm]="paramsList.config.length < 1"
        gridTitle="{{ l10nKeys.gridTitleEntries | vtranslate : paramsList.config.length }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addEntry()"
            >
                {{ l10nKeys.addEntryButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>

    <ng-template
        #filenameTemplateRef
        let-row
        let-index="index"
    >
        <avi-dropdown
            name="{{ 'filename_' + index }}"
            class="sel-filename-dropdown"
            [options]="filenameDropdownOptions"
            [hiddenValues]="selectedFilenames"
            placeholder="{{ l10nKeys.filenameDropdownPlaceholder | vtranslate }}"
            [(ngModel)]="row.config.file.config.filename"
            [disabled]="!filenameDropdownOptions.length || row.config.file.config.timestamp"
            (ngModelChange)="setSelectedFilenames()"
            required
        ></avi-dropdown>
    </ng-template>

    <ng-template
        #priorityTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                name="{{ 'priority_' + index }}"
                class="sel-priority"
                type="number"
                clrInput
                placeholder="{{ l10nKeys.priorityInputPlaceholder | vtranslate }}"
                required
                [(ngModel)]="row.config.priority"
                [disabled]="row.config.file.config.timestamp"
            />
        </avi-input-container>
    </ng-template>

    <ng-template
        #formatTemplateRef
        let-row
        let-index="index"
    >
        <avi-dropdown
            name="{{ 'format_' + index }}"
            class="sel-file-format-dropdown"
            [options]="formatDropdownOptions"
            placeholder="{{ l10nKeys.formatDropdownPlaceholder | vtranslate }}"
            [(ngModel)]="row.config.file.config.format"
            [disabled]="row.config.file.config.timestamp"
            required
        ></avi-dropdown>
    </ng-template>
</div>
