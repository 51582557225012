/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'csrf-rule';

export const csrfRuleBreadcrumbTitle = `${prefix}.csrfRuleBreadcrumbTitle`;

export const ENGLISH = {
    [csrfRuleBreadcrumbTitle]: 'CSRF Rule',
};
