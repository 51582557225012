<avi-confirmation
    headerText="{{ l10nKeys.headerText | vtranslate }}"
    (onClose)="dismiss()"
>
    <avi-confirmation_modal-content>
        <p>{{ l10nKeys.alertMessage | vtranslate }}</p>
        <avi-alert-group alertType="danger">
            <span *ngIf="!hasList">
                {{ messages }}
            </span>
            <ng-container *ngIf="hasList">
                <span *ngFor="let message of messages; trackBy: trackbyIndex">
                    {{ message }}
                </span>
            </ng-container>
        </avi-alert-group>
    </avi-confirmation_modal-content>

    <avi-confirmation_modal-actions>
        <cds-button
            class="sel-confirm"
            status="danger"
            (click)="dismiss()"
        >
            {{ l10nKeys.confirmButtonText | vtranslate }}
        </cds-button>
    </avi-confirmation_modal-actions>
</avi-confirmation>
