<avi-dashboard-widget
    class="cloud-services-widget"
    shape="internet-of-things"
    [iconSize]="24"
    headerText="{{ l10nKeys.cloudServicesLabel | vtranslate }}"
    *ngrxLet="loading$ as loading"
    [loading]="loading"
    [showLoadingMessage]="loading"
    [loadingMessage]="moduleL10nKeys.widgetLoadingMessage | vtranslate"
>
    <div dashboardWidgetBody>
        <ng-container *ngIf="isRegistered$ | async; then registeredContent else unregisteredContent"></ng-container>
    </div>
</avi-dashboard-widget>

<ng-template #unregisteredContent>
    <div class="cloud-services-widget__unregistered-container">
        <span>{{ l10nKeys.controllerNotRegisteredMessage | vtranslate }}</span>
        <a
            href=""
            (click)="redirectToCloudServicesPage($event)"
        >
            {{ l10nKeys.registerNowLink | vtranslate }}
        </a>
    </div>
</ng-template>

<ng-template #registeredContent>
    <div class="cloud-services-widget__registration-status-container">
        <cds-icon
            shape="circle"
            size="14"
            [status]="connectivityIconStatus"
            solid
        ></cds-icon>
        <div class="cloud-services-widget__portal-url">
            {{ portalUrl$ | async }}
        </div>
        <div>{{ connectivityLabel }}</div>
    </div>
    <div class="cloud-services-widget__config-container">
        <div class="cloud-services-widget__value-container">
            <label class="cloud-services-widget__label">
                {{ globalL10nKeys.ipReputationLabel | vtranslate }}
            </label>
            <span class="cloud-services-widget__value">
                {{ ipReputationEnabled$ | async | booleanLabel : 'enabled' }}
            </span>
        </div>

        <div class="cloud-services-widget__value-container">
            <label class="cloud-services-widget__label">
                {{ l10nKeys.botManagementLabel | vtranslate }}
            </label>
            <span class="cloud-services-widget__value">
                {{ botManagementEnabled$ | async | booleanLabel: 'enabled' }}
            </span>
        </div>

        <div class="cloud-services-widget__value-container">
            <label class="cloud-services-widget__label">
                {{ l10nKeys.crsSignaturesLabel | vtranslate }}
            </label>
            <span class="cloud-services-widget__value">
                {{ crsSignaturesEnabled$ | async | booleanLabel : 'enabled' }}
            </span>
        </div>
    </div>
</ng-template>
