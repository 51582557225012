/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-dns-request';
const prefix = `${moduleName}.${componentName}`;

export const dnsClientLocationLabel = `${prefix}.dnsClientLocationLabel`;
export const domainNameLabel = `${prefix}.domainNameLabel`;
export const idLabel = `${prefix}.idLabel`;
export const authenticDataLabel = `${prefix}.authenticDataLabel`;
export const questionCountLabel = `${prefix}.questionCountLabel`;
export const rxBytesLabel = `${prefix}.rxBytesLabel`;
export const txBytesLabel = `${prefix}.txBytesLabel`;
export const operationCodeLabel = `${prefix}.operationCodeLabel`;
export const opCodeLabel = `${prefix}.opCodeLabel`;
export const recursionDesiredLabel = `${prefix}.recursionDesiredLabel`;
export const checkingDeactivatedLabel = `${prefix}.checkingDeactivatedLabel`;

export const ENGLISH = {
    [dnsClientLocationLabel]: 'DNS Client Location',
    [domainNameLabel]: 'Domain Name',
    [idLabel]: 'ID',
    [authenticDataLabel]: 'Authentic Data',
    [questionCountLabel]: 'Question Count',
    [rxBytesLabel]: 'RX Bytes',
    [txBytesLabel]: 'TX Bytes',
    [operationCodeLabel]: 'Operation Code ({0})',
    [opCodeLabel]: 'OP Code',
    [recursionDesiredLabel]: 'Recursion Desired',
    [checkingDeactivatedLabel]: 'Checking Deactivated',
};
