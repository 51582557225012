/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-jwt';
const prefix = `${moduleName}.${componentName}`;

export const jwtVerifiedLabel = `${prefix}.jwtVerifiedLabel`;
export const tokenPayloadLabel = `${prefix}.tokenPayloadLabel`;

export const ENGLISH = {
    [jwtVerifiedLabel]: 'JWT Verified',
    [tokenPayloadLabel]: 'Token Payload',
};
