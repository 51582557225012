<cds-modal
    class="referenced-objects-dialog"
    (closeChange)="handleClose($event)"
    size="xl"
>
    <cds-modal-header>
        <h3>{{ l10nKeys.headerLabel | vtranslate }}</h3>
    </cds-modal-header>
    <cds-modal-content>
        <div class="referenced-objects-dialog__container">
            <p [innerHTML]="warningMessage"></p>

            <avi-data-grid
                [config]="referencedObjectsGridConfig"
                [rows]="referencedObjects"
                [gridTitle]="gridTitle"
            ></avi-data-grid>

            <p class="referenced-objects-dialog__continue-question-warning">{{ l10nKeys.continueQuestionWarning | vtranslate }}</p>
        </div>
    </cds-modal-content>
    <cds-modal-actions>
        <cds-button
            action="outline"
            (click)="handleCancel()"
        >
            {{ globalL10nKeys.cancelLabel | vtranslate }}
        </cds-button>
        <cds-button
            status="danger"
            (click)="handleSubmit()"
        >
            {{ l10nKeys.saveButtonLabel | vtranslate }}
        </cds-button>
    </cds-modal-actions>
</cds-modal>
