<div
    class="dynamic-params-grid"
    provideParentForm
>
    <custom-params-grid
        [paramsList]="paramsList"
        hasSensitiveColumn
        [allowDelete]="false"
        [allowCreate]="false"
        [nameFieldEditable]="false"
        [sensitiveFieldEditable]="false"
    ></custom-params-grid>
</div>
