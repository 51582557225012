<div class="ssl-ciphers-grid">
    <avi-data-grid
        [config]="sslCiphersGridConfig"
        [rows]="ciphers"
    ></avi-data-grid>
</div>

<ng-template
    #enableCipherTemplateRef
    let-row
    let-index="index"
>
    <input
        type="checkbox"
        clrCheckbox
        name="{{ 'enabled_' + index }}"
        [(ngModel)]="row.enabled"
        (ngModelChange)="handleCipherSelectionChange()"
    />
</ng-template>

<ng-template
    #pfsTemplateRef
    let-row
>
    <cds-icon
        *ngIf="row.pfs"
        status="success"
        shape="check-circle"
        size="18"
    ></cds-icon>
</ng-template>
