<div
    cdkDropList
    (cdkDropListDropped)="handleDrop($event)"
>
    <policy-rule
        [rule]="rule"
        *ngFor="let rule of rules.config; trackBy: trackByRuleName"
        (onEdit)="handleEdit(rule)"
        (onDelete)="deleteRule(rule)"
        cdkDrag
    ></policy-rule>
</div>
