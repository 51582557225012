<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="l4-rule-port-match"
        provideParentForm
    >
        <enum-radio-buttons
            [noMarginTop]="true"
            enum="MatchOperation"
            name="match-operation"
            [(ngModel)]="editable.config.match_criteria"
        ></enum-radio-buttons>
        <avi-input-container helperText="{{ l10nKeys.portsHelperText | vtranslate }}">
            <label
                aviLabelWithTooltip
                objectType="{{ l4RulePortMatchObjectType }}"
                fieldName="ports"
                for="l4RulePortMatch"
                required
            >
                {{ l10nKeys.portsLabel | vtranslate }}
            </label>
            <avi-repeated-numbers
                id="port-match"
                name="port-match"
                placeholder="{{ l10nKeys.portsInputPlaceholder | vtranslate }}"
                objectType="{{ l4RulePortMatchObjectType }}"
                fieldName="ports"
                [minLimit]="portNumberMin"
                [maxLimit]="portNumberMax"
                [(ngModel)]="portList"
                (ngModelChange)="debouncedUpdateDataWithPortList()"
                required
            ></avi-repeated-numbers>
        </avi-input-container>
    </div>
</match-wrapper>
