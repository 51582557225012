<full-modal-config
    modalTitle="{{ l10nKeys.modalHeader | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="role-filter-modal sel-role_filter_modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        name="name"
                        class="sel-name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-checkbox
                    objectType="{{ objectType }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                    name="enabled"
                >
                    {{ l10nKeys.enabledFileLabel | vtranslate }}
                </avi-checkbox>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="key"
                        for="key"
                        objectType="RoleFilterMatchLabel"
                        required
                    >
                        {{ globalL10nKeys.keyLabel | vtranslate }}
                    </label>
                    <input
                        name="key"
                        class="sel-key"
                        [(ngModel)]="editable.config.match_label.config.key"
                        clrInput
                        placeholder="{{ l10nKeys.keyInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="match_operation"
                            for="match-operation"
                        >
                            {{ l10nKeys.criteriaLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="match-operation"
                            [(ngModel)]="editable.config.match_operation"
                            enum="RoleFilterMatchOperation"
                            required
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="RoleFilterMatchLabel"
                            fieldName="values"
                            required
                        >
                            <avi-data-grid_header>
                                <h6>
                                    {{ l10nKeys.valuesLabel | vtranslate : editable.valuesCount }}
                                </h6>
                            </avi-data-grid_header>
                        </label>
                        <avi-repeated-strings-grid
                            [(ngModel)]="editable.config.match_label.config.values"
                            name="values"
                            placeholder="{{ l10nKeys.valuesInputPlaceholder | vtranslate }}"
                            required
                        ></avi-repeated-strings-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
