/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import { IHttpPromise } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import { ISSLKeyAndCertificate } from 'generated-types';
import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import { SslCertificateExportModalComponent } from '../components';
import * as l10n from './ssl-certificate-export-modal.service.I10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const CERTIFICATE_EXPORT_URL = 'api/sslkeyandcertificate';

type THttpWrapper = typeof HttpWrapper;

// Token used when registering as a downgraded service in AJS.
export const CERTIFICATE_EXPORT_MODAL_SERVICE_TOKEN = 'sslCertificateExportModalService';

/**
 * @description Service to fetch data in modal and open/close the modal.
 *
 * @author Nisar Nadaf
 */
@Injectable()
export class SslCertificateExportModalService {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Returns the certificate data of matching uuid.
     */
    public fetch(uuid: string): IHttpPromise<ISSLKeyAndCertificate> {
        const requestConfig = {
            url: `${CERTIFICATE_EXPORT_URL}/${uuid}?export_key=true`,
            method: HttpMethod.GET,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Method to open welcome modal.
     */
    public openModal(uuid: string, certificateName: string): void {
        this.fullModalService.addModal(this.getExportModalProps(uuid, certificateName));
    }

    /**
     * Method to close welcome modal.
     */
    public closeModal(): void {
        this.fullModalService
            .removeModalByComponent(SslCertificateExportModalComponent as Type<Component>);
    }

    /**
     * Returns props to be passed to the FullModal.
     */
    private getExportModalProps(uuid: string, certificateName: string): IFullModalLayout {
        const { l10nService } = this;

        return {
            component: SslCertificateExportModalComponent as Type<Component>,
            componentProps: {
                uuid,
            },
            getName: () => l10nService.getMessage(l10nKeys.exportModalName),
            getDescription: () => certificateName,
        };
    }
}
