/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'icap-log-list-expander';
const prefix = `${moduleName}.${componentName}`;

export const summaryLabel = `${prefix}.summaryLabel`;
export const seSourcePortLabel = `${prefix}.seSourcePortLabel`;
export const icapPoolLabel = `${prefix}.icapPoolLabel`;
export const icapServerIpPortLabel = `${prefix}.icapServerIpPortLabel`;
export const icapLatencyLabel = `${prefix}.icapLatencyLabel`;
export const requestLabel = `${prefix}.requestLabel`;
export const httpMethodLabel = `${prefix}.httpMethodLabel`;
export const completeBodySentLabel = `${prefix}.completeBodySentLabel`;
export const icapAbsoluteUriLabel = `${prefix}.icapAbsoluteUriLabel`;
export const icapMethodLabel = `${prefix}.icapMethodLabel`;
export const responseLabel = `${prefix}.responseLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const modifiedContentLengthLabel = `${prefix}.modifiedContentLengthLabel`;
export const icapResponseCodeLabel = `${prefix}.icapResponseCodeLabel`;

export const ENGLISH = {
    [summaryLabel]: 'Summary',
    [seSourcePortLabel]: 'Service Engine Source Port',
    [icapPoolLabel]: 'ICAP Pool',
    [icapServerIpPortLabel]: 'ICAP Server IP:Port',
    [icapLatencyLabel]: 'ICAP Latency',
    [requestLabel]: 'Request',
    [httpMethodLabel]: 'HTTP Method',
    [completeBodySentLabel]: 'Complete Body Sent',
    [icapAbsoluteUriLabel]: 'ICAP Absolute URI',
    [icapMethodLabel]: 'ICAP Method',
    [responseLabel]: 'Response',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [modifiedContentLengthLabel]: 'Modified Content Length',
    [icapResponseCodeLabel]: 'ICAP Response Code',
};
