<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    modalClassName="auth-mapping-rule-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleMatch | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleMatch | vtranslate }}</h3>

                <div class="auth-mapping-rule-modal__match-section">
                    <mapping-rule-group-match
                        *ngIf="isLdapProfile"
                        [groupMatch]="editable.groupMatch"
                    ></mapping-rule-group-match>

                    <mapping-rule-attribute-match
                        [attributeMatch]="editable.attributeMatch"
                    ></mapping-rule-attribute-match>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ globalL10nKeys.actionLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.actionLabel | vtranslate }}</h3>

                <avi-radio-container>
                    <avi-radio
                        name="is-super-user"
                        [value]="true"
                        [(ngModel)]="editable.config.is_superuser"
                        (ngModelChange)="onSuperUserChange()"
                        [objectType]="objectType"
                        fieldName="is_superuser"
                    >
                        {{ l10nKeys.superUserLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="is-super-user"
                        [value]="false"
                        [(ngModel)]="editable.config.is_superuser"
                        (ngModelChange)="onSuperUserChange()"
                    >
                        {{ l10nKeys.customMappingLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>

                <ng-container *ngIf="!editable.isSuperUser">
                    <auth-rule-custom-mapping [editable]="editable"></auth-rule-custom-mapping>
                </ng-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
