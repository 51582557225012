/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

const allDataSources = {
    list: {
        source: 'EventListCollDataSource',
        transformer: 'EventListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * @constructor
 * @memberOf module:avi/events
 * @extends Collection
 * @alias EventCollection
 * @desc
 *
 *     Collection of {@link module:avi/events.Event Events}.
 *
 * @author Alex Malitsky
 */
class EventCollection extends Collection {
    constructor(args = {}) {
        const { params = {} } = args;

        const extendedArgs = {
            objectName: 'analytics/logs',
            defaultDataSources: 'list',
            allDataSources,
            ...args,
            params: {
                type: 2,
                ...params,
            },
        };

        super(extendedArgs);

        /**
         * Accurate flag, represents percent_remaining property of the events list response.
         * @type {boolean}
         * @protected
         */
        this.isAccurate_ = true;

        this.itemClass_ = this.getAjsDependency_('Event');
    }

    /**
     * Setting range parameters and calling load
     * @param {Moment} tBegin - Begin time
     * @param {Moment} tEnd  - End time
     * @return {Promise}
     */
    loadRange(tBegin, tEnd) {
        let errMsg,
            promise;

        if (!tBegin || !tEnd) {
            errMsg = 'EventCollection.loadRange failed: begin or end is not passed';
            console.error(errMsg);
            promise = Promise.reject(errMsg);
        } else {
            tBegin = moment(tBegin);
            tEnd = moment(tEnd);

            // Events use duration and end to create a range
            this.setParams({
                duration: tEnd.diff(tBegin, 's'),
                end: tEnd.format(),
            });

            promise = this.load(undefined, true);
        }

        return promise;
    }

    /**
     * Setting step and limit parameters and calling load
     * @param {number} step - Current timeframe step
     * @param {number} limit - Current timeframe limit
     * @returns {Promise}
     */
    loadDuration(step, limit) {
        let promise,
            errMsg;

        if (!step || !limit) {
            errMsg = 'EventCollection.loadDuration failed: step or limit is not passed';
            console.error(errMsg);
            promise = Promise.when(errMsg);
        } else {
            this.setParams({
                duration: step * limit,
                end: undefined,
            });

            promise = this.load(undefined, true);
        }

        return promise;
    }

    /**
     * Returns true if percent_remaining = 0, else false.
     * @returns {boolean}
     */
    isAccurate() {
        return this.isAccurate_;
    }

    /** @override */
    isCreatable() {
        return false;
    }
}

EventCollection.ajsDependencies = [
    'Event',
];

initAjsDependency(
    angular.module('avi/events'),
    'factory',
    'EventCollection',
    EventCollection,
);

export { EventCollection };
