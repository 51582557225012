/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import './avi-dropdown-option.component.less';
import {
    DropdownModelSingleValue,
    IAviDropdownOption,
} from '../../avi-dropdown.types';

const RIGHT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: 10,
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
};

const LEFT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: -10,
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
};

const tooltipPositionsPriority: ConnectedPosition[] = [
    RIGHT_CONNECTED_POSITION,
    LEFT_CONNECTED_POSITION,
];

const caretClassNameMap = new Map([
    [RIGHT_CONNECTED_POSITION, 'avi-dropdown-option__tooltip--right'],
    [LEFT_CONNECTED_POSITION, 'avi-dropdown-option__tooltip--left'],
]);

/**
 * @description Component for a dropdown option.
 * @author alextsg, Alex Klyuev
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-option',
    templateUrl: './avi-dropdown-option.component.html',
})
export class AviDropdownOptionComponent<TDropdownOptionValue = DropdownModelSingleValue> {
    /**
     * Option value.
     */
    @Input()
    public value?: TDropdownOptionValue;

    /**
     * Text to be displayed in the option.
     */
    @Input()
    public label?: IAviDropdownOption['label'];

    /**
     * Text to be displayed in the tooltip.
     */
    @Input()
    public tooltipText?: IAviDropdownOption['tooltip'];

    @Input()
    public optionTemplateRef?: TemplateRef<HTMLElement>;

    /**
     * Event emitters for mouse events on this option.
     */
    @Output()
    public onMouseEnter = new EventEmitter<void>();

    @Output()
    public onMouseMove = new EventEmitter<void>();

    public tooltipPositionsPriority: ConnectedPosition[] = tooltipPositionsPriority;
    public caretClassName = caretClassNameMap.get(tooltipPositionsPriority[0]);

    /**
     * Listeners for mouse events on this option.
     */
    @HostListener('mouseenter')
    public handleMouseEnter(): void {
        this.onMouseEnter.emit();
    }

    @HostListener('mousemove')
    public handleMouseMove(): void {
        this.onMouseMove.emit();
    }

    /**
     * Handler for the position change of the tooltip content. Used to reposition the caret.
     */
    public handlePositionChange(connectedPosition: ConnectedPosition): void {
        this.caretClassName = caretClassNameMap.get(connectedPosition);
    }
}
