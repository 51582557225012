/**
 * Module containing components, pipes and services used in
 * Avi Header.
 * @module HeaderModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { CoreModule } from 'ng/modules/core/core.module';
import { SystemModule } from 'ng/modules/system/system.module';
import { HttpModule } from 'ng/modules/http/http.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';

import {
    AppAboutModalComponent,
    BrandingComponent,
    GenerateSsoTokenDialogComponent,
    StateButtonComponent,
    StateGroupPipe,
    StateNamePipe,
    UserCardComponent,
    UserCardMenuComponent,
    UserMenuAdditionalMenuComponent,
    UserMenuComponent,
} from '.';

import {
    activeUserProfileServiceProvider,
    userItemProvider,
} from './ajs-upgraded-providers';

const headerComponents = [
    AppAboutModalComponent,
    BrandingComponent,
    GenerateSsoTokenDialogComponent,
    StateButtonComponent,
    UserCardComponent,
    UserCardMenuComponent,
    UserMenuAdditionalMenuComponent,
    UserMenuComponent,
];

const headerPipes = [
    StateGroupPipe,
    StateNamePipe,
];

@NgModule({
    declarations: [
        ...headerComponents,
        ...headerPipes,
    ],
    exports: [
        AppAboutModalComponent,
        BrandingComponent,
        StateButtonComponent,
        UserCardComponent,
        UserCardMenuComponent,
    ],
    imports: [
        AviFormsModule,
        ClrFormsModule,
        ClrInputModule,
        ClrSpinnerModule,
        CommonModule,
        CoreModule,
        DialogModule,
        FormsModule,
        HttpModule,
        SharedModule,
        SystemModule,
        UIRouterModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [
        activeUserProfileServiceProvider,
        userItemProvider,
    ],
})
export class HeaderModule {}
