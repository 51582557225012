/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-learning-data-dialog';
const prefix = `${moduleName}.${componentName}`;

export const wafLearningDataHeader = `${prefix}.wafLearningDataHeader`;

export const ENGLISH = {
    [wafLearningDataHeader]: 'WAF Learning Data: {0}',
};
