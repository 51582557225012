<expander
    [hidden]="showingConfirmation"
    openerClassName="{{ openerClassName }}"
    label="{{ label }}"
    [hideCaret]="true"
    (onToggleExpand)="handleToggleExpand($event)"
    [expanded]="expanded"
>
    <opener
        class="expander-with-actions__opener-container"
        (mouseenter)="handleHover(true)"
        (mouseleave)="handleHover()"
    >
        <div class="expander-with-actions__opener">
            <ng-content select="opener"></ng-content>
        </div>
        <div
            [hidden]="hasActions() && hover"
            class="expander-with-actions__caret-container"
            title="Expand"
        >
            <i
                class="expander-with-actions__caret"
                [ngClass]="getCaretIconClass()"
            >
            </i>
        </div>
        <div
            [hidden]="!hover || !hasActions()"
            class="expander-with-actions__actions-container"
        >
            <div class="expander-with-actions__actions">
                <expander-button
                    *ngFor="let action of actions"
                    [hidden]="action.hidden && action.hidden()"
                    iconClassName="{{ action.iconClassName }}"
                    (onClick)="handleClickExpanderButton(action)"
                    title="{{ action.title }}"
                >
                </expander-button>
            </div>
            <div
                class="expander-with-actions__caret-container expander-with-actions__caret-container--hovered"
                title="Expand"
            >
                <i
                    class="expander-with-actions__caret expander-with-actions__caret--hovered"
                    [ngClass]="getCaretIconClass()"
                >
                </i>
            </div>
        </div>
    </opener>
    <content>
        <ng-content select="content"></ng-content>
    </content>
</expander>
<expander-action-confirmation
    [hidden]="!showingConfirmation"
    message="{{ confirmationProps.message }}"
    (onCancel)="resetConfirmation()"
>
    <button
        *ngFor="let action of confirmationProps.confirmationButtonProps.actions"
        [ngClass]="confirmationProps.confirmationButtonProps.confirmationButtonClassName"
        (click)="handleConfirm($event, action)"
    >
        {{ action.label }}
    </button>
</expander-action-confirmation>
