/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'saml-policy-settings';
const prefix = `${moduleName}.${componentName}`;

export const ssoPolicyInputLabel = `${prefix}.ssoPolicyInputLabel`;
export const ssoPolicyInputPlaceholder = `${prefix}.ssoPolicyInputPlaceholder`;
export const createSsoPolicyButtonLabel = `${prefix}.createSsoPolicyButtonLabel`;
export const sessionCookieNameInputLabel = `${prefix}.sessionCookieNameInputLabel`;
export const entityIdInputLabel = `${prefix}.entityIdInputLabel`;
export const sessionCookieTimeoutInputLabel = `${prefix}.sessionCookieTimeoutInputLabel`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const sslKeyInputLabel = `${prefix}.sslKeyInputLabel`;
export const sslKeyInputPlaceholder = `${prefix}.sslKeyInputPlaceholder`;
export const createSslKeyButtonLabel = `${prefix}.createSslKeyButtonLabel`;
export const acstypeInputLabel = `${prefix}.acstypeInputLabel`;
export const noneAcsType = `${prefix}.noneAcsType`;
export const urlAcsType = `${prefix}.urlAcsType`;
export const indexAcsType = `${prefix}.indexAcsType`;

export const ENGLISH = {
    [ssoPolicyInputLabel]: 'SSO Policy',
    [ssoPolicyInputPlaceholder]: 'Select SSO Policy',
    [createSsoPolicyButtonLabel]: 'Create SSO Policy',
    [sessionCookieNameInputLabel]: 'Session Cookie Name',
    [entityIdInputLabel]: 'Entity ID',
    [sessionCookieTimeoutInputLabel]: 'Session Cookie Timeout',
    [minutesInnerText]: 'Minutes',
    [sslKeyInputLabel]: 'SSL Key',
    [sslKeyInputPlaceholder]: 'Select SSL Key',
    [createSslKeyButtonLabel]: 'Create SSL Key',
    [acstypeInputLabel]: 'ACS Type',
    [noneAcsType]: 'None',
    [urlAcsType]: 'URL',
    [indexAcsType]: 'Index',
};
