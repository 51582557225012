/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @description Alert Metric collection.
 */
angular.module('aviApp').factory('AlertMetricsCollection', [
'Collection', 'AlertMetricsItem',
function(Collection, AlertMetricsItem) {
    class AlertMetricsCollection extends Collection {
        constructor(args) {
            super(args);
            this.setParams({ alert_metrics: true });
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(AlertMetricsCollection.prototype, {
        objectName_: 'analytics/metric_id',
        itemClass_: AlertMetricsItem,
        defaultDataSources_: 'list',
        allDataSources_: {
            list: {
                source: 'ListCollDataSourceWithSearchParam',
                transformer: 'ListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
    });

    return AlertMetricsCollection;
}]);
