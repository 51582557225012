<full-modal-config
    modalTitle="{{ l10nKeys.modalTitleEdit | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="savePortalInfoAndRegisterController()"
    [busy]="editable.isBusy() || busy"
    [errors]="editable.errors || error"
    [valid]="form.valid"
    class="cportal-info-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            *ngIf="!editMode"
        >
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="account-name"
                            required
                        >
                            {{ acctOrgLabel }}
                        </label>
                        <avi-dropdown
                            name="account-name"
                            id="account-name"
                            [options]="departmentOptions"
                            [(ngModel)]="defaultDepartmentId"
                            (ngModelChange)="onDepartmentChange()"
                            placeholder="{{ l10nKeys.departmentInputPlaceholder | vtranslate }}"
                            [disabled]="busy"
                            [hideSearch]="false"
                            required
                        ></avi-dropdown>
                    </div>
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="contact"
                            required
                        >
                            {{ l10nKeys.contactInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            id="contact"
                            [options]="users"
                            name="contact"
                            [(ngModel)]="defaultContact"
                            placeholder="{{ l10nKeys.contactInputPlaceholder | vtranslate }}"
                            [disabled]="busy"
                            [hideSearch]="false"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.proxyHeader | vtranslate }}"
            *ngIf="editMode"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.proxyHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="!editable.config.use_split_proxy">
                            <avi-fieldset_header>
                                <avi-checkbox
                                    [noMarginTop]="true"
                                    name="use_split_proxy"
                                    fieldName="use_split_proxy"
                                    objectType="{{ objectType.ALBServicesConfig }}"
                                    [(ngModel)]="editable.config.use_split_proxy"
                                >
                                    {{ l10nKeys.systemProxyLabel | vtranslate }}
                                </avi-checkbox>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <ng-container *ngIf="editable.config.use_split_proxy">
                                    <split-proxy-configuration [editable]="editable.config.split_proxy_configuration"></split-proxy-configuration>
                                </ng-container>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.smartOperationsHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.smartOperationsHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.ALBServicesConfig }}"
                            fieldName="feature_opt_in_status"
                        >
                            {{ l10nKeys.inventoryStatsLabel | vtranslate }}
                        </label>
                        <avi-checkbox
                            class="cportal-info-modal__label-font"
                            [noMarginTop]="true"
                            name="enable_pulse_inventory"
                            fieldName="enable_pulse_inventory"
                            objectType="{{ objectType.PortalFeatureOptIn }}"
                            [(ngModel)]="editable.featureOptInStatusConfig.enable_pulse_inventory"
                        >
                            {{ l10nKeys.enablePulseCloudServicesOrgDashboardLabel | vtranslate }}
                        </avi-checkbox>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.caseManagementHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.caseManagementHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset
                            [hideContent]="!editable.featureOptInStatusConfig.enable_pulse_case_management"
                            class="cportal-info-modal__case-management-checkbox-container"
                        >
                            <avi-fieldset_header>
                                <avi-checkbox
                                    class="cportal-info-modal__label-font"
                                    [noMarginTop]="true"
                                    name="enable_pulse_case_management"
                                    fieldName="enable_pulse_case_management"
                                    objectType="{{ objectType.PortalFeatureOptIn }}"
                                    [(ngModel)]="editable.featureOptInStatusConfig.enable_pulse_case_management"
                                >
                                    {{ l10nKeys.enableCaseManagementLabel | vtranslate }}
                                </avi-checkbox>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.ALBServicesConfig }}"
                                    fieldName="case_config"
                                >
                                    {{ l10nKeys.enableProactiveSupportLabel | vtranslate }}
                                </label>
                                <avi-checkbox-container-group [noMarginTop]="true">
                                    <avi-checkbox
                                        class="cportal-info-modal__label-font"
                                        name="enable_auto_case_creation_on_controller_failure"
                                        fieldName="enable_auto_case_creation_on_controller_failure"
                                        objectType="{{ objectType.CaseConfig }}"
                                        [(ngModel)]="editable.caseConfig.enable_auto_case_creation_on_controller_failure"
                                    >
                                        {{ l10nKeys.enableAutomaticCasesSystemFailureLabel | vtranslate }}
                                    </avi-checkbox>
                                    <avi-checkbox
                                        class="cportal-info-modal__label-font"
                                        name="enable_auto_case_creation_on_se_failure"
                                        fieldName="enable_auto_case_creation_on_se_failure"
                                        objectType="{{ objectType.CaseConfig }}"
                                        [(ngModel)]="editable.caseConfig.enable_auto_case_creation_on_se_failure"
                                    >
                                        {{ l10nKeys.enableAutomaticCasesServiceFailureLabel | vtranslate }}
                                    </avi-checkbox>
                                </avi-checkbox-container-group>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.threatIntelligenceHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.threatIntelligenceHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset
                            [hideContent]="!editable.featureOptInStatusConfig.enable_pulse_waf_management"
                            class="cportal-info-modal__case-management-checkbox-container"
                        >
                            <avi-fieldset_header>
                                <avi-checkbox
                                    class="cportal-info-modal__label-font"
                                    [noMarginTop]="true"
                                    name="enable_pulse_waf_management"
                                    fieldName="enable_pulse_waf_management"
                                    objectType="{{ objectType.PortalFeatureOptIn }}"
                                    [(ngModel)]="editable.featureOptInStatusConfig.enable_pulse_waf_management"
                                >
                                    {{ l10nKeys.enableWafManagementLabel | vtranslate }}
                                </avi-checkbox>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.ALBServicesConfig }}"
                                    fieldName="case_config"
                                >
                                    {{ l10nKeys.signaturesLabel | vtranslate }}
                                </label>
                                <avi-checkbox-container-group [noMarginTop]="true">
                                    <avi-checkbox
                                        class="cportal-info-modal__label-font"
                                        name="enable_waf_signatures_notifications"
                                        fieldName="enable_waf_signatures_notifications"
                                        objectType="{{ objectType.WafCrsConfig }}"
                                        [(ngModel)]="editable.wafConfig.enable_waf_signatures_notifications"
                                    >
                                        {{ l10nKeys.enableCrsDataLabel | vtranslate }}
                                    </avi-checkbox>
                                    <avi-checkbox
                                        class="cportal-info-modal__label-font"
                                        name="enable_auto_download_waf_signatures"
                                        fieldName="enable_auto_download_waf_signatures"
                                        objectType="{{ objectType.WafCrsConfig }}"
                                        [(ngModel)]="editable.wafConfig.enable_auto_download_waf_signatures"
                                    >
                                        {{ l10nKeys.enableAutoDownloadWafLabel | vtranslate }}
                                    </avi-checkbox>
                                </avi-checkbox-container-group>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.ALBServicesConfig }}"
                        fieldName="feature_opt_in_status"
                    >
                        {{ l10nKeys.appSignaturesSyncLabel | vtranslate }}
                    </label>
                    <div class="cportal-info-modal__alert">
                        <avi-alert-group 
                            alertType="lightweight"
                            [hyperlink]="LINK"
                            deprecationLabel="{{ l10nKeys.deprecationWarningLabel | vtranslate }}"
                        ></avi-alert-group>
                    </div>
                    <avi-checkbox
                        name="enable_appsignature_sync"
                        fieldName="enable_appsignature_sync"
                        objectType="{{ objectType.PortalFeatureOptIn }}"
                        [(ngModel)]="editable.featureOptInStatusConfig.enable_appsignature_sync"
                    >
                        {{ l10nKeys.enableApplicationRulesDbLabel | vtranslate }}
                    </avi-checkbox>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-checkbox
                            [noMarginTop]="true"
                            name="enable_ip_reputation"
                            fieldName="enable_ip_reputation"
                            objectType="{{ objectType.PortalFeatureOptIn }}"
                            [(ngModel)]="editable.featureOptInStatusConfig.enable_ip_reputation"
                        >
                            {{ l10nKeys.enableAutoSyncIpLabel | vtranslate }}
                        </avi-checkbox>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-checkbox
                            [noMarginTop]="true"
                            name="enable_user_agent_db_sync"
                            fieldName="enable_user_agent_db_sync"
                            objectType="{{ objectType.PortalFeatureOptIn }}"
                            [(ngModel)]="editable.featureOptInStatusConfig.enable_user_agent_db_sync"
                        >
                            {{ l10nKeys.enableUserAgentDbManagementLabel | vtranslate }}
                        </avi-checkbox>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
