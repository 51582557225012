/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    IMustChecksInfo,
    UpgradeFsmState,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';

import './prechecks-list.component.less';

import * as l10n from './prechecks-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to display prechecks list.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'prechecks-list',
    templateUrl: './prechecks-list.component.html',
})
export class PrechecksListComponent {
    @Input()
    public prechecks: IMustChecksInfo[];

    public readonly l10nKeys = l10nKeys;

    /**
     * Precheck state hash.
     */
    public readonly upgradeFsmState = {
        ERROR: UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR,
        IN_PROGRESS: UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS,
        SUCCESS: UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS,
        WARNING: UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING,
        STARTED: UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
