<full-modal-config
    modalTitle="{{ l10nKeys.modalHeader | vtranslate }}"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="syslog-server"
                        [objectType]="objectType"
                        fieldName="syslog_server"
                        required
                    >
                        {{ l10nKeys.syslogServerLabel | vtranslate }}
                    </label>
                    <input
                        type="text"
                        id="syslog-server"
                        name="syslog-server"
                        placeholder="{{ l10nKeys.syslogServerPlaceholderLabel | vtranslate }}"
                        required
                        regexPattern="anyIPHostname"
                        [(ngModel)]="editable.config.syslog_server"
                        clrInput
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="syslog-server-port"
                        [objectType]="objectType"
                        fieldName="syslog_server_port"
                        required
                    >
                        {{ l10nKeys.portLabel | vtranslate }}
                    </label>
                    <input
                        type="number"
                        id="syslog-server-port"
                        name="syslog-server-port"
                        clrInput
                        placeholder="{{ l10nKeys.portPlaceholderLabel | vtranslate }}"
                        required
                        [(ngModel)]="editable.config.syslog_server_port"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
