<div
    provideParentForm
    [invalidateForm]="!isFormValid"
>
    <avi-data-grid
        [config]="dnsVsGridConfig"
        [rows]="dnsVirtualServiceRefs"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addDnsVs()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
</div>

<ng-template
    #dnsVsTemplateRef
    let-row
    let-index="index"
>
    <avi-collection-dropdown
        [collection]="virtualServiceCollection"
        [disableCreate]="true"
        [disableEdit]="true"
        [placeholder]="globalL10nKeys.emptyLabel | vtranslate"
        [ngModel]="row.vsRef"
        (ngModelChange)="onDnsVsRefDropdownChange($event, index)"
        name="{{ 'vs-' + index }}"
        required
    ></avi-collection-dropdown>
</ng-template>
