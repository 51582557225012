/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'software-upload-cancel-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const uploadInProgressHeader = `${prefix}.uploadInProgressHeader`;
export const likeToContinuePrompt = `${prefix}.likeToContinuePrompt`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const okBtnLabel = `${prefix}.okBtnLabel`;

export const ENGLISH = {
    [uploadInProgressHeader]: 'Upload in Progress',
    [likeToContinuePrompt]: 'Would you like to continue?',
    [cancelBtnLabel]: 'Cancel',
    [okBtnLabel]: 'OK',
};
