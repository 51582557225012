/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { take } from 'rxjs/operators';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import * as l10n from './vs-log-cinematic-section-security.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying Security section in a VS log cinematic view.
 * @author Kondiparthi Shanmukha Sarath, Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-security',
    templateUrl: './vs-log-cinematic-section-security.component.html',
})
export class VsLogCinematicSectionSecurityComponent {
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly icapLog$ = this.vsLogCinematicStore.icapLog$;

    public readonly wafLog$ = this.vsLogCinematicStore.wafLog$;

    public readonly botManagementLog$ = this.vsLogCinematicStore.botManagementLog$;

    /**
     * Total number of ICAP violations summed from the ICAP OPSWAT Log of ICAP Request Logs.
     */
    public totalNumberOfIcapViolations = 0;

    constructor(
        l10nService: L10nService,
        private readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.icapLog$
            .pipe(take(1))
            .subscribe(icapLog => {
                if (icapLog) {
                    const { request_logs: requestLogs } = icapLog;

                    if (requestLogs) {
                        this.totalNumberOfIcapViolations = requestLogs.reduce(
                            (acc, current) => acc + (current.opswat_log?.violations?.length || 0),
                            0,
                        );
                    }
                }
            });
    }
}
