<div class="network-list-expander">
    <div
        class="network-list-expander__subnet"
        *ngFor="let subnet of network.configuredSubnets; trackBy: trackbyIndex"
    >
        <configured-network-subnet-expander
           [subnet]="subnet"
        ></configured-network-subnet-expander>
    </div>
</div>
