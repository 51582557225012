/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function collMetricsCollDataSourceFactory(CollDataSource, Timeframe) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.CollDataSource
     * @author Alex Malitsky, Ashish Verma
     * @desc
     *
     *     CollMetricsCollDataSource prepares a list of requests for Collection
     *     Metrics API by going through all visible Collection Items and asking
     *     each of them for collection metrics requeststo be fetched through
     *     Item#getCollMetricsRequests.
     *
     *     And later it goes through all visible Items passing received response
     *     so that they could update themselves.
     *     CollDataSource of {@link module:avi/dataModel.CollMetricsCollection
     *      CollMetricsCollection}.
     */
    class CollMetricsCollDataSource extends CollDataSource {
        constructor(args) {
            super(args);

            this.onVisibleItemsListUpdate_ = this.onVisibleItemsListUpdate_.bind(this);
            this.owner_.on('visibleItemsListUpdate', this.onVisibleItemsListUpdate_);
        }

        /**
         * Prevents call to be made if we have no requests to send.
         * @param {CollMetricsRequest[]} subrequests
         * @returns {boolean}
         * @protected
         * @override
         */
        preventLoad_(subrequests) {
            return !subrequests.length;
        }

        /**
         * @returns {CollMetricsRequest[]}
         * @protected
         * @override
         */
        getRequestParams_() {
            const
                owner = this.owner_,
                fieldNames = _.pluck(this.fields_, 'id'),
                itemIds = owner.visibleItemIds_.length ?
                    owner.visibleItemIds_ :
                    _.keys(owner.itemById),
                requests = [];

            _.each(itemIds, function(itemId) {
                let itemRequests = [];

                if (itemId in owner.itemById) {
                    itemRequests = owner.itemById[itemId].getCollMetricsRequests(fieldNames);
                }

                Array.prototype.push.apply(requests, itemRequests);
            }, this);

            return requests;
        }

        /**
         * Updates step&limit parameters and calls load if we have any fields (metric names) to be
         * loaded.
         * @protected
         * @override
         */
        onTimeframeChange_() {
            const tf = Timeframe.selected();

            this.params_['step'] = tf.step;
            this.params_['limit'] = tf.limit;

            super.onTimeframeChange_();

            if (!this.isInactive()) {
                this.load();
            }
        }

        /** @override */
        isInactive() {
            return _.isEmpty(this.fields_);
        }

        /**
         * Goes through all currently visible Items and suggests them to update their metrics data
         * with the received response.
         * @param {CollMetricsFullResponse} resp - It passes whole response object to
         *     each Item since Collection and DataSource both have no clue which
         *     request id did Item use.
         * @protected
         */
        processResponse_(resp) {
            const owner = this.owner_;
            const itemIds = owner.visibleItemIds_.length ?
                owner.visibleItemIds_ :
                _.keys(owner.itemById);

            _.each(itemIds, function(itemId) {
                if (itemId in owner.itemById) {
                    owner.itemById[itemId].processCollMetricsResponse(resp);
                }
            });
        }

        /**
         * Event listener for the VisibleItemsListUpdate event evoked by Collection.
         * @returns {ng.$q.promise}
         * @protected
         */
        onVisibleItemsListUpdate_() {
            return this.load();
        }

        /** @override */
        destroy() {
            this.owner_.unbind('visibleItemsListUpdate', this.onVisibleItemsListUpdate_);

            super.destroy();
        }

        //TODO should we replace it with normal setOffset/setLimit event handlers? Or switch
        //TODO those to events too.
    }

    return CollMetricsCollDataSource;
}

collMetricsCollDataSourceFactory.$inject = [
    'CollDataSource',
    'Timeframe',
];

angular.module('avi/dataModel')
    .factory(
        'CollMetricsCollDataSource',
        collMetricsCollDataSourceFactory,
    );
