/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-dropdown-menu';
const prefix = `${moduleName}.${componentName}`;

export const searchPlaceholderLabel = `${prefix}.searchPlaceholderLabel`;

export const ENGLISH = {
    [searchPlaceholderLabel]: 'Search',
};
