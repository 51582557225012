<avi-dashboard-widget
    class="controller-widget"
    shape="help-info"
    [iconSize]="24"
    headerText="{{ globalL10nKeys.controllerLabel | vtranslate }}"
    [loading]="loading$ | async"
    [showLoadingMessage]="initialLoading"
    [loadingMessage]="moduleL10nKeys.widgetLoadingMessage | vtranslate"
>
    <div dashboardWidgetBody>
        <div class="controller-widget__cluster-container">
            <cluster-state-info
                [clusterState]="clusterState$ | async"
                [name]="name$ | async"
                [clusterVipAddressV4]="clusterVipAddressV4$ | async"
                [clusterVipAddressV6]="clusterVipAddressV6$ | async"
                [clusterVipRuntimeStatus]="clusterVipRuntimeStatus$ | async"
            ></cluster-state-info>
        </div>
        <div
            *ngIf="(initialVersionData$ | async) as versionData"
            class="controller-widget__version-data-container"
        >
            <div class="controller-widget__version-container">
                <div class="controller-widget__version">
                    <label class="controller-widget__label">
                        {{ globalL10nKeys.versionLabel | vtranslate }}
                    </label>
                    <span class="controller-widget__value">
                        {{ versionData.version }}
                    </span>
                </div>
                <div class="controller-widget__version">
                    <label class="controller-widget__label">
                        {{ l10nKeys.patchLabel | vtranslate }}
                    </label>
                    <span class="controller-widget__value">
                        {{ versionData.patch || '-' }}
                    </span>
                </div>
            </div>

            <div class="controller-widget__value-container">
                <label class="controller-widget__label">
                    {{ l10nKeys.buildLabel | vtranslate }}
                </label>
                <span class="controller-widget__value">
                    {{ versionData.build }}
                </span>
            </div>

            <div class="controller-widget__value-container">
                <label class="controller-widget__label">
                    {{ globalL10nKeys.dateLabel | vtranslate }}
                </label>
                <span class="controller-widget__value">
                    {{ versionData.date | i18nDate : 'medium' }}
                </span>
            </div>
        </div>
    </div>
</avi-dashboard-widget>
