/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'license-summary';
const prefix = `${moduleName}.${componentName}`;

export const unlockMoreFeaturesMessage = `${prefix}.unlockMoreFeaturesMessage`;
export const licensingLabel = `${prefix}.licensingLabel`;
export const learnMoreBtnLabel = `${prefix}.learnMoreBtnLabel`;

export const ENGLISH = {
    [unlockMoreFeaturesMessage]: 'Unlock more features and possibilities with Enterprise edition.',
    [licensingLabel]: 'Licensing',
    [learnMoreBtnLabel]: 'Learn More',
};
