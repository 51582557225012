/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { AuthenticationPolicy } from 'object-types';
import { IAuthenticationPolicy } from 'generated-types';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { AuthenticationRuleConfigItem } from './authentication-rule.config-item.factory';

type TAuthenticationPolicyPartial = Omit<IAuthenticationPolicy, 'authn_rules'>;

interface IAuthenticationPolicyConfig extends TAuthenticationPolicyPartial {
    authn_rules?: RepeatedMessageItem<AuthenticationRuleConfigItem>;
}

/**
 * @description AuthenticationPolicy config item.
 *
 * @author Aravindh Nagarajan
 */
export class AuthenticationPolicyConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IAuthenticationPolicyConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthenticationPolicy,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for authn_rules.
     */
    public get authRules(): RepeatedMessageItem<AuthenticationRuleConfigItem> {
        return this.config.authn_rules;
    }

    /**
     * Clears default Auth profile Ref.
     */
    public clearDefaultAuthProfile(): void {
        delete this.config.default_auth_profile_ref;
    }

    /**
     * Opens Create AuthenticationRule Modal.
     */
    public addAuthenticationRule(): void {
        this.addChildMessageItem({
            field: 'authn_rules',
        });
    }

    /**
     * Opens Edit AuthenticationRule Modal.
     */
    public editAuthenticationRule(messageItem: AuthenticationRuleConfigItem): void {
        this.addChildMessageItem({
            field: 'authn_rules',
            messageItem,
        });
    }
}
