/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'cloudhsm-hsm-ip-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const ipAddressPlaceholder = `${prefix}.ipAddressPlaceholder`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleIpAddress]: 'IP Address',
    [ipAddressPlaceholder]: 'Enter IP Address',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
