<avi-state-page class="network-list-page">
    <avi-state-page_main-content>
        <div class="network-list-page__header-container">
            <avi-page-header
                headerText="{{ l10nKeys.headerLabel | vtranslate }}"
            ></avi-page-header>
        </div>
        <div class="network-list-page__collection-grid">
            <avi-collection-data-grid [config]="networkListGridConfig">
            </avi-collection-data-grid>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
<ng-template
    #expandedContentTemplateRef
    let-row
>
    <network-list-expander [network]="row"></network-list-expander>
</ng-template>
