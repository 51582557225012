<div class="match-and-action-display-wrapper__section match-and-action-display-wrapper__section--match">
    <h6>{{ l10nKeys.matchSectionHeader | vtranslate }}</h6>
    <div class="match-and-action-display-wrapper__section-content">
        <ng-content select="match-and-action-display-wrapper_match-content"></ng-content>
    </div>
</div>

<div class="match-and-action-display-wrapper__section">
    <h6>{{ l10nKeys.actionSectionHeader | vtranslate }}</h6>
    <div class="match-and-action-display-wrapper__action-content">
        <ng-content select="match-and-action-display-wrapper_action-content"></ng-content>
    </div>
</div>
