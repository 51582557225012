/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const componentName = 'controller-widget';
const prefix = `${moduleName}.${componentName}`;

export const clusterIpLabel = `${prefix}.clusterIpLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const buildLabel = `${prefix}.buildLabel`;

export const ENGLISH = {
    [clusterIpLabel]: 'Cluster IP',
    [patchLabel]: 'Patch',
    [buildLabel]: 'Build',
};
