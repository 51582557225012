<ng-container *ngrxLet="ntlmLog$ as ntlmLog">
    <div class="vs-log-cinematic-sub-section-ntlm__column-group">
        <!-- 1st column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.ntlmLabel | vtranslate }}
            </div>
            <div
                vsLogFilter
                *ngIf="ntlmLog.ntlm_detected !== undefined"
                property="ntlm_log.ntlm_detected"
                [value]="ntlmLog.ntlm_detected"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ (ntlmLog.ntlm_detected ? l10nKeys.detectedLabel : l10nKeys.undetectedLabel) | vtranslate }}
            </div>
        </div>

        <!-- 2nd column -->
        <div
            vsLogCinematicSectionColumn
            [expandTwoColumns]="true"
        >
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.ntlmStatusLabel | vtranslate }}
            </div>
            <div
                vsLogFilter
                *ngIf="ntlmLog.ntlm_status as ntlmStatus; else na"
                property="ntlm_log.ntlm_status"
                [value]="ntlmStatus"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ntlmStatusHash[ntlmStatus] }}
            </div>
        </div>
    </div>

</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
