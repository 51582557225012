/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module VsLogsModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';
import { ReactiveComponentModule } from '@ngrx/component';

import {
    ClrFormsModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { AviCardModule } from 'nsx-alb-ui-components';

import { CoreModule } from 'ng/modules/core/core.module';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { MatchModule } from 'ng/modules/match/match.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { RightRailModule } from 'ng/modules/right-rail/right-rail.module';
import { CinematicModule } from 'ng/modules/cinematic/cinematic.module';
import { DiagramModule } from 'ng/modules/diagram/diagram.module';
import { LoaderModule } from '../loader/loader.module';

import { VsLogsPageComponent } from './components/vs-logs-page/vs-logs-page.component';
import { VsLogsActionsComponent } from './components/vs-logs-actions/vs-logs-actions.component';
import { VsLogsErrorsComponent } from './components/vs-logs-errors/vs-logs-errors.component';
import { VsLogListComponent } from './components/vs-log-list/vs-log-list.component';
import { VsLogsGraphComponent } from './components/vs-logs-graph/vs-logs-graph.component';
import {
    VsLogCinematicComponent,
} from './components/vs-log-cinematic/vs-log-cinematic.component';
import {
    VsLogCinematicEndToEndBridgeComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-end-to-end-bridge';
import {
    VsLogCinematicEndToEndKnotComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-end-to-end-knot';
import {
    VsLogCinematicTopInfoComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-top-info';
import {
    VsLogCinematicSectionWrapperComponent,
    VsLogCinematicSubSectionWrapperComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-wrapper';
import {
    VsLogCinematicSectionSignificanceComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-significance';

import {
    VsLogCinematicSectionSslComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-ssl';
import { VsLogsSidebarComponent } from './components/vs-logs-sidebar/vs-logs-sidebar.component';
import {
    VsLogCinematicSectionSummaryComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-summary';

import {
    VsLogCinematicSectionSecurityBotComponent,
    VsLogCinematicSectionSecurityComponent,
    VsLogCinematicSectionSecurityWafComponent,
    VsLogCinematicSubSectionIcapComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-security';
import {
    VsLogCinematicSectionConnection,
// eslint-disable-next-line max-len
} from './components/vs-log-cinematic/vs-log-cinematic-section-connection/vs-log-cinematic-section-connection.component';

import {
    VsLogCinematicSectionHeadersComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-headers';
import {
    VsLogCinematicSectionApplicationComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-application';
import {
    VsLogCinematicSectionProtocolComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-protocol';
import {
    VsLogCinematicSectionDatascriptComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-datascript';
import {
    VsLogCinematicSectionOutOfBandComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-out-of-band';
import {
    VsLogCinematicSectionAuthenticationComponent,
    VsLogCinematicSubSectionJwtComponent,
    VsLogCinematicSubSectionNtlmComponent,
    VsLogCinematicSubSectionOAuthComponent,
    VsLogCinematicSubSectionSamlComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-authentication';
import {
    VsLogCinematicDnsOptRecordComponent,
    VsLogCinematicSectionDnsComponent,
    VsLogCinematicSubSectionDnsRequestComponent,
    VsLogCinematicSubSectionDnsResponseComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-section-dns';
import {
    VsLogCinematicIpFieldComponent,
} from './components/vs-log-cinematic/vs-log-cinematic-ip-field';

import {
    VsLogsCollapsedSidebarComponent,
} from './components/vs-logs-sidebar/vs-logs-collapsed-sidebar';
import {
    VsLogsSignificanceOptionsComponent,
} from './components/vs-logs-top-bar/vs-logs-significance-options';
import { VsLogsSearchBarComponent } from './components/vs-logs-top-bar/vs-logs-search-bar';
import { VsLogsTypeDropdownComponent } from './components/vs-logs-top-bar/vs-logs-type-dropdown';
import {
    VsLogsEditableFilterComponent,
} from './components/vs-logs-top-bar/vs-logs-search-bar/vs-logs-editable-filter';

import { VsLogsSignpostComponent } from './components/vs-logs-signpost/vs-logs-signpost.component';
import {
    VsLogsSignpostDefaultComponent,
} from './components/vs-logs-signpost/vs-logs-signpost-default/vs-logs-signpost-default.component';
import {
    VsLogsSignpostDefaultViewLogListComponent,
    // eslint-disable-next-line max-len
} from './components/vs-logs-signpost/vs-logs-signpost-default/vs-logs-signpost-default-view-log-list/vs-logs-signpost-default-view-log-list.component';
import {
    VsLogsSignpostDefaultHeaderComponent,
    // eslint-disable-next-line max-len
} from './components/vs-logs-signpost/vs-logs-signpost-default/vs-logs-signpost-default-header/vs-logs-signpost-default-header.component';
import {
    VsLogsSearchBarDropdownComponent,
} from './components/vs-logs-top-bar/vs-logs-search-bar/vs-logs-search-bar-dropdown';
import {
    VsLogsFilterInputComponent,
} from './components/vs-logs-top-bar/vs-logs-search-bar/vs-logs-filter-input';
import {
    VsLogsSaveSearchModalComponent,
} from './components/vs-logs-top-bar/vs-logs-search-bar/vs-logs-save-search-modal';
import { VsLogDurationDetailComponent } from './components/vs-log-duration-detail';
import {
    VsLogFilterListComponent,
} from './components/vs-log-filter-list/vs-log-filter-list.component';

import { VsLogsStore } from './services/vs-logs.store';
import { VsLogsSignpostStore } from './components/vs-logs-signpost/vs-logs-signpost.store';
import { VsLogsApiRequestService } from './services/vs-logs-api-request.service';
import { VsLogCinematicService } from './services/vs-log-cinematic.service';
import { VsLogsTimeframesService } from './services/vs-logs-timeframes.service';
import { VsLogsEffectsService } from './services/vs-logs-effects.service';

import {
    VsLogsSignpostCustomComponent,
    VsLogsSignpostCustomEndToEndTimingComponent,
    VsLogsSignpostCustomEndToEndTimingDataGridComponent,
    VsLogsSignpostCustomEndToEndTimingHeaderComponent,
    VsLogsSignpostCustomGroupsComponent,
    VsLogsSignpostCustomSignificanceComponent,
    VsLogsSignpostCustomWafLatencyComponent,
    VsLogsSignpostCustomWafRuleComponent,
    VsLogsSignpostCustomWafRuleGroupsComponent,
} from './components/vs-logs-signpost/vs-logs-signpost-custom';

import {
    LogDataTransformerServiceProvider,
    TimeFormatterServiceProvider,
} from './ajs-upgraded-providers';
import { VsLogsSearchBarDropdownService } from './services/vs-logs-search-bar-dropdown.service';

import {
    VsLogCinematicFieldTitleDirective,
    VsLogCinematicSectionColumnDirective,
    VsLogEmptyFieldDirective,
    VsLogFilterableEmptyFieldDirective,
    VsLogFilterDirective,
    VsLogFilterRangeDirective,
    VsLogNationalFlagDirective,
    VsLogPartialSelectionFilterDirective,
    VsLogsEditableFilterInputEventsDirective,
} from './directives';

import {
    CountryCodeToNamePipe,
    CountryFlagImageSourcePipe,
    LogClientEnvToIconClassPipe,
    LogConnectionGridRowsPipe,
    LogHttpHeadersDiffSourcePairPipe,
    LogHttpVersionPipe,
    LogResponseCompressionInfoPipe,
    LogSignificanceReasonsPipe,
    LogSslGridRowsPipe,
    SignpostLogDataTransformPipe,
    SubLogHeadersDiffSourcePairPipe,
    TransformSavedSearches,
    WafRuleGroupNameFilterPipe,
} from './pipes';

const components = [
    VsLogCinematicComponent,
    VsLogCinematicDnsOptRecordComponent,
    VsLogCinematicEndToEndBridgeComponent,
    VsLogCinematicEndToEndKnotComponent,
    VsLogCinematicIpFieldComponent,
    VsLogCinematicSectionApplicationComponent,
    VsLogCinematicSectionAuthenticationComponent,
    VsLogCinematicSectionConnection,
    VsLogCinematicSectionDatascriptComponent,
    VsLogCinematicSectionDnsComponent,
    VsLogCinematicSectionHeadersComponent,
    VsLogCinematicSectionOutOfBandComponent,
    VsLogCinematicSectionProtocolComponent,
    VsLogCinematicSectionSecurityBotComponent,
    VsLogCinematicSectionSecurityComponent,
    VsLogCinematicSectionSecurityWafComponent,
    VsLogCinematicSectionSignificanceComponent,
    VsLogCinematicSectionSslComponent,
    VsLogCinematicSectionSummaryComponent,
    VsLogCinematicSectionWrapperComponent,
    VsLogCinematicSubSectionDnsRequestComponent,
    VsLogCinematicSubSectionDnsResponseComponent,
    VsLogCinematicSubSectionIcapComponent,
    VsLogCinematicSubSectionJwtComponent,
    VsLogCinematicSubSectionNtlmComponent,
    VsLogCinematicSubSectionOAuthComponent,
    VsLogCinematicSubSectionSamlComponent,
    VsLogCinematicSubSectionWrapperComponent,
    VsLogCinematicTopInfoComponent,
    VsLogFilterListComponent,
    VsLogsCollapsedSidebarComponent,
    VsLogsErrorsComponent,
    VsLogsGraphComponent,
    VsLogsActionsComponent,
    VsLogListComponent,
    VsLogsPageComponent,
    VsLogsSidebarComponent,
    VsLogsSignificanceOptionsComponent,
    VsLogsSearchBarComponent,
    VsLogsTypeDropdownComponent,
    VsLogsEditableFilterComponent,
    VsLogsSignpostComponent,
    VsLogsSignpostCustomComponent,
    VsLogsSignpostCustomEndToEndTimingComponent,
    VsLogsSignpostCustomEndToEndTimingDataGridComponent,
    VsLogsSignpostCustomEndToEndTimingHeaderComponent,
    VsLogsSignpostCustomGroupsComponent,
    VsLogsSignpostCustomSignificanceComponent,
    VsLogsSignpostCustomWafLatencyComponent,
    VsLogsSignpostCustomWafRuleComponent,
    VsLogsSignpostCustomWafRuleGroupsComponent,
    VsLogsSignpostDefaultComponent,
    VsLogsSignpostDefaultHeaderComponent,
    VsLogsSignpostDefaultViewLogListComponent,
    VsLogsSearchBarDropdownComponent,
    VsLogsFilterInputComponent,
    VsLogsSaveSearchModalComponent,
    VsLogDurationDetailComponent,
];

const directives = [
    VsLogCinematicFieldTitleDirective,
    VsLogCinematicSectionColumnDirective,
    VsLogEmptyFieldDirective,
    VsLogFilterableEmptyFieldDirective,
    VsLogFilterDirective,
    VsLogFilterRangeDirective,
    VsLogNationalFlagDirective,
    VsLogPartialSelectionFilterDirective,
    VsLogsEditableFilterInputEventsDirective,
];

const pipes = [
    CountryCodeToNamePipe,
    CountryFlagImageSourcePipe,
    LogClientEnvToIconClassPipe,
    LogConnectionGridRowsPipe,
    LogHttpHeadersDiffSourcePairPipe,
    LogHttpVersionPipe,
    LogSignificanceReasonsPipe,
    LogSslGridRowsPipe,
    LogResponseCompressionInfoPipe,
    SignpostLogDataTransformPipe,
    SubLogHeadersDiffSourcePairPipe,
    TransformSavedSearches,
    WafRuleGroupNameFilterPipe,
];

@NgModule({
    declarations: [
        ...components,
        ...directives,
        ...pipes,
    ],
    imports: [
        AviCardModule,
        AviFormsModule,
        CinematicModule,
        ClrFormsModule,
        ClrSpinnerModule,
        CommonModule,
        CoreModule,
        FormsModule,
        SharedModule,
        DialogModule,
        LoaderModule,
        MatchModule,
        VIPModule,
        DataGridModule,
        RightRailModule,
        DiagramModule,
        ReactiveComponentModule,
    ],
    providers: [
        CountryFlagImageSourcePipe,
        LogClientEnvToIconClassPipe,
        LogDataTransformerServiceProvider,
        LogSignificanceReasonsPipe,
        VsLogsStore,
        VsLogsApiRequestService,
        VsLogCinematicService,
        VsLogsEffectsService,
        VsLogsSignpostStore,
        VsLogsTimeframesService,
        TimeFormatterServiceProvider,
        SignpostLogDataTransformPipe,
        LogHttpVersionPipe,
        VsLogsSearchBarDropdownService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VsLogsModule {}
