/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleResponseActionConfig
 * @description DnsPolicyRuleResponseActionConfig,
 *      maps to DnsRuleActionResponse in protobof.
 */
const dnsPolicyRuleResponseActionConfigFactory = (ActionConfigItem, $filter) => {
    class DnsPolicyRuleResponseActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const
                { rcode, truncation, authoritative } = this.getConfig(),
                getBooleanLabel = $filter('booleanLabel'),
                outputs = [];

            if (rcode) {
                const rCode = rcode.slice('DNS_RCODE_'.length).toUpperCase();

                outputs.push(`Response Code: "${rCode}"`);
            }

            if (truncation) {
                const truncatedLabel = getBooleanLabel(truncation);

                outputs.push(`Truncation: ${truncatedLabel}`);
            }

            if (authoritative) {
                const authoritativeLabel = getBooleanLabel(authoritative);

                outputs.push(`Authoritative: ${authoritativeLabel}`);
            }

            return outputs.join('; ');
        }
    }

    angular.extend(DnsPolicyRuleResponseActionConfig.prototype, {
        type: 'DnsRuleActionResponse',
        name: 'Query Response',
        defaultPath: 'dnsrule.action.response',
    });

    return DnsPolicyRuleResponseActionConfig;
};

dnsPolicyRuleResponseActionConfigFactory.$inject = [
        'ActionConfigItem',
        '$filter',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleResponseActionConfig', dnsPolicyRuleResponseActionConfigFactory,
);
