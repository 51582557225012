<full-modal-config
    modalTitle="{{ modalTitle }}"
    modalSubTitle="{{ item.getName() }}"
    [modified]="false"
    (onCancel)="handleCancelAttempt()"
    (onSubmit)="handleSubmit()"
    [busy]="item.isBusy()"
    [errors]="item.errors"
    [valid]="!form.invalid"
    [disableTabs]="disableTabs"
    [tabSectionsQueryList]="tabSections"
    modalClassName="{{ modalClassName }}"
    submitButtonText="{{ submitButtonText }}"
>
    <full-modal-config-viewer
        class="full-modal-config-header__config-viewer"
        [item]="item"
    ></full-modal-config-viewer>
    <ng-content></ng-content>
</full-modal-config>
