/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const btnLabelBasicSetup = `${prefix}.btnLabelBasicSetup`;
export const btnLabelAdvancedSetup = `${prefix}.btnLabelAdvancedSetup`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleHealth = `${prefix}.columnTitleHealth`;
export const columnTitleAddress = `${prefix}.columnTitleAddress`;
export const columnTitleAppDomainName = `${prefix}.columnTitleAppDomainName`;
export const columnTitleServicePorts = `${prefix}.columnTitleServicePorts`;
export const columnTitlePools = `${prefix}.columnTitlePools`;
export const columnTitlePoolGroup = `${prefix}.columnTitlePoolGroup`;
export const columnTitleServiceEngineGroup = `${prefix}.columnTitleServiceEngineGroup`;
export const columnTitleServiceEngines = `${prefix}.columnTitleServiceEngines`;
export const columnTitleCloud = `${prefix}.columnTitleCloud`;
export const columnTitleVirtualHosting = `${prefix}.columnTitleVirtualHosting`;
export const columnTitleVrfContext = `${prefix}.columnTitleVrfContext`;
export const columnTitleTotalServiceEngines = `${prefix}.columnTitleTotalServiceEngines`;
export const columnTitleAlerts = `${prefix}.columnTitleAlerts`;
export const actionBtnDisable = `${prefix}.actionBtnDisable`;
export const actionBtnEnable = `${prefix}.actionBtnEnable`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const emptySearchMatchLabel = `${prefix}.emptySearchMatchLabel`;
export const emptySearchLabel = `${prefix}.emptySearchLabel`;
export const createVirtualServiceButtonLabel = `${prefix}.createVirtualServiceButtonLabel`;
export const notAvailableDataLabel = `${prefix}.notAvailableDataLabel`;
export const requestPerSecColumnTitle = `${prefix}.requestPerSecColumnTitle`;
export const connectionsPerSecColumnTitle = `${prefix}.connectionsPerSecColumnTitle`;

export const ENGLISH = {
    [btnLabelBasicSetup]: 'Basic Setup',
    [btnLabelAdvancedSetup]: 'Advanced Setup',
    [columnTitleName]: 'Name',
    [columnTitleHealth]: 'Health',
    [columnTitleAddress]: 'Address',
    [columnTitleAppDomainName]: 'App Domain Name',
    [columnTitleServicePorts]: 'Service Ports',
    [columnTitlePools]: 'Pools',
    [columnTitlePoolGroup]: 'Pool Group',
    [columnTitleServiceEngineGroup]: 'Service Engine Group',
    [columnTitleServiceEngines]: 'Service Engines',
    [columnTitleCloud]: 'Cloud',
    [columnTitleVirtualHosting]: 'Virtual Hosting',
    [columnTitleVrfContext]: 'VRF Context',
    [columnTitleTotalServiceEngines]: 'Total Service Engines',
    [columnTitleAlerts]: 'Alerts',
    [actionBtnDisable]: 'Disable',
    [actionBtnEnable]: 'Enable',
    [actionBtnDelete]: 'Delete',
    [emptyDataLabel]: 'None',
    [emptySearchMatchLabel]: 'No Virtual Services match your search criteria.',
    [emptySearchLabel]: 'No Virtual Services found.',
    [createVirtualServiceButtonLabel]: 'Create Virtual Service',
    [notAvailableDataLabel]: 'N/A',
    [requestPerSecColumnTitle]: 'Requests per second',
    [connectionsPerSecColumnTitle]: 'Connections per second',
};
