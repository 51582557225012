/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { IIcapOPSWATLog, IIcapViolation } from 'generated-types';
import * as l10n from './icap-opswat-logs.l10n';
import template from './icap-opswat-logs.component.html';
import './icap-opswat-logs.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for icapOpswatLogs component.
 */
export const ICAP_OPSWAT_LOGS_COMPONENT_TOKEN = 'icapOpswatLogs';

/** Formatted object containing values needed for OPSWAT Violations section. */
export interface IIcapLogViolation {
    file_name: {
        display: string;
        val: IIcapViolation['file_name'];
    };
    resolution: {
        display: string;
        val: IIcapViolation['resolution'];
    };
    threat_name: {
        display: string;
        val: IIcapViolation['threat_name'];
    };
}

/** Formatted object containing values needed for ICAP OPSWAT Logs. */
export interface IIcapOPSWATLogsData {
    reason: {
        display: string;
        val: IIcapOPSWATLog['reason'];
    };
    threat_id : {
        display: string;
        val: IIcapOPSWATLog['threat_id'];
    };
    violations: IIcapLogViolation[];
}

/**
 * @author Satish Pednekar
 * @desc Component representing ICAP OPSWAT log details.
 */
class IcapOpswatLogsComponentController {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Transformed OPSWAT logs data which is displayed
     * in vendor specific section in ICAP logs grid.
     */
    public readonly opswatLog: IIcapOPSWATLogsData;

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: { [str: string]: string }) => void;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }
}

IcapOpswatLogsComponentController.$inject = [
    'l10nService',
];

export const IcapOpswatLogsComponentOptions = {
    bindings: {
        opswatLog: '<',
        updateSearch: '&',
    },
    controller: IcapOpswatLogsComponentController,
    template,
};
