/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'generic-hdr-match';
const prefix = `${moduleName}.${componentName}`;

export const criteriaLabel = `${prefix}.criteriaLabel`;
export const criteriaPlaceholder = `${prefix}.criteriaPlaceholder`;
export const selectIpGroupPlaceholder = `${prefix}.selectIpGroupPlaceholder`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const valuesInputPlaceholder = `${prefix}.valuesInputPlaceholder`;
export const valueInputPlaceholder = `${prefix}.valueInputPlaceholder`;

export const ENGLISH = {
    [criteriaLabel]: 'Criteria',
    [criteriaPlaceholder]: 'Select Criteria',
    [selectIpGroupPlaceholder]: 'Select IP Group',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [valueInputLabel]: 'Value',
    [valuesInputPlaceholder]: 'Enter Values',
    [valueInputPlaceholder]: 'Enter Value',
};
