<full-modal-preview>
    <full-modal-preview_container>
        <div class="traffic-capture-preview clr-wrapper">
            <preview-field>
                <preview-field_label>{{ globalL10nKeys.tenantLabel | vtranslate }}</preview-field_label>
                <preview-field_val *ngIf="tenantName; else noData">{{ tenantName }}</preview-field_val>
            </preview-field>
        </div>
    </full-modal-preview_container>
</full-modal-preview>

<ng-template #noData>
    <span class="traffic-capture-preview__field----no-data">-</span>
</ng-template>
