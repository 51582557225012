/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { parseFaults } from 'ng/modules/virtual-service/utils/virtual-service-faults.utils';
import { copy } from 'angular';

function inventoryMapDataTransformerFactory(
    InventoryDataTransformer,
    itemAlertDataTransform,
) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.InventoryDataTransformer
     * @author Ashish Verma
     * @desc
     *     We get inventory data for the whole tree vs > ses, pools > servers, networks.
     *     Need tosplit config/runtime/hs/alert properties of each Item from nested objects
     *     and add couple of additional properties such as vs.serversNum and vs.poolsNum
     *     keeping quantities of nestedItems.
     *     InventoryDataTransformer of {@link module:avi/app.InventoryMap InventoryMap}.
     */
    class InventoryMapDataTransformer extends InventoryDataTransformer {
        /**
         * Modifies returned server config to be consistent with Server class.
         * @param {Server#data} serverData
         * @param {Server#id} serverId
         * @param {Pool#id} poolId
         * @returns {Server#data}
         * @protected
         */
        transformServerData_(serverData, serverId, poolId) {
            serverData.config.uuid = serverId;
            serverData.config.poolId = poolId;

            return serverData;
        }

        /**
         * Provides consistent network configuration for inventory map.
         * @param {Server#id[]} serverIdsList
         * @param {string} netName - Network name.
         * @param {{string: Server#data}} poolServersHash - Keys are Server#id.
         * @returns {Network#data}
         * @protected
         */
        transformNetworkData_(serverIdsList, netName, poolServersHash) {
            return {
                config: { name: netName },
                servers: serverIdsList.map(function(serverId) {
                    return poolServersHash[serverId];
                }),
            };
        }

        /**
         * Modifies pool' data object provided by backend inventory map API. Has a few additional
         * properties, specific for inventory map, such as lists of servers and networks.
         * @param {Object} poolData
         * @param {VirtualService#id} vsId - VS id this Pool belongs to.
         * @param {Pool#id=} parentPoolId - For backup and a\b pool we have a child/parent relation.
         * @param {string=} type -
         * @returns {Pool#data}
         * @protected
         */
        transformPoolData_(poolData, vsId, parentPoolId, type) {
            poolData.poolsNum = 0;
            poolData.serversNum = 0;
            poolData.virtualservices = [vsId];

            itemAlertDataTransform(poolData.alert);

            if (!_.isUndefined(parentPoolId)) {
                poolData.parentPoolId = parentPoolId;
                poolData.type = type;
            }

            //if no networks we show servers after pool's list
            if ('servers' in poolData) {
                _.each(poolData.servers, function(serverData, serverId, hash) {
                    poolData.serversNum++;
                    hash[serverId] =
                    this.transformServerData_(serverData, serverId, poolData.config.uuid);
                }, this);
            }

            if ('networks' in poolData) {
                poolData.networks = _.map(poolData.networks, function(serverIdsList, netName) {
                    return this.transformNetworkData_(serverIdsList, netName, poolData.servers);
                }, this);
            }

            return poolData;
        }

        /** @override */
        processResponse(resp, request) {
            let seHash = {};

            resp = this.responseListOffsetControl_(resp, request);

            if (Array.isArray(resp.data.serviceengines)) {
                seHash = resp.data.serviceengines.reduce(function(hash, se) {
                    itemAlertDataTransform(se.alert);
                    hash[se.config.uuid] = se;

                    return hash;
                }, {});
            }

            if (Array.isArray(resp.data.results)) {
                resp.data.results = resp.data.results.map(function(vs) {
                    const pools = [];

                    vs.serversNum = 0;
                    itemAlertDataTransform(vs.alert);

                    vs.serviceengines = vs.serviceengines.map(function(seId) {
                        return copy(seHash[seId]);
                    });

                    vs.pools.forEach(function(poolData) {
                        poolData = this.transformPoolData_(poolData, vs.config.uuid);

                        pools.push(poolData);

                        vs.serversNum += poolData.serversNum;

                        //`child` pools should follow their parents
                        ['ab_pools', 'backup_pools'].forEach(function(fieldName) {
                            if (fieldName in poolData && !_.isEmpty(poolData[fieldName])) {
                                _.each(poolData[fieldName], function(childPoolData) {
                                    childPoolData = this.transformPoolData_(
                                        childPoolData, vs.config.uuid, poolData.config.uuid,
                                        fieldName === 'ab_pools' ? 'ab-child' : 'backup',
                                    );

                                    poolData.poolsNum++;
                                    poolData.serversNum += childPoolData.serversNum;

                                    pools.push(childPoolData);
                                }, this);

                                if (fieldName === 'ab_pools') {
                                    poolData.type = 'ab-parent';
                                }
                            }

                            poolData[fieldName] = undefined;
                        }, this);
                    }, this);

                    vs.faults = parseFaults(vs.faults);
                    vs.pools = pools;
                    vs.poolsNum = pools.length;

                    return vs;
                }, this);
            }

            return resp;
        }
    }

    return InventoryMapDataTransformer;
}

inventoryMapDataTransformerFactory.$inject = [
    'InventoryDataTransformer',
    'itemAlertDataTransform',
];

angular.module('avi/app')
    .factory(
        'InventoryMapDataTransformer',
        inventoryMapDataTransformerFactory,
    );
