/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-significance';
const prefix = `${moduleName}.${componentName}`;

export const significanceLabel = `${prefix}.significanceLabel`;

export const ENGLISH = {
    [significanceLabel]: 'Significance',
};
