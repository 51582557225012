/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';

import { IAviDropdownOption }
    from 'ng/shared/components/avi-dropdown/avi-dropdown.types';

import { AuthMappingRuleConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-mapping-rule.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './auth-mapping-rule-modal.l10n';
import './auth-mapping-rule-modal.component.less';

const { ENGLISH, ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for AuthMappingRule Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-mapping-rule-modal',
    templateUrl: './auth-mapping-rule-modal.component.html',
})
export class AuthMappingRuleModalComponent implements OnInit {
    /**
     * AuthMappingRuleConfigItem instance.
     */
    @Input()
    public editable: AuthMappingRuleConfigItem;

    /**
     * If true, AuthProfile type is LDAP.
     * LDAP Group selection match will be displayed.
     */
    @Input()
    public isLdapProfile = false;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<AuthMappingRuleConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * ObjectType.
     */
    public objectType: string;

    /**
     * AuthenticationAction dropdown options.
     */
    public authenticationActionDropdownOptions: IAviDropdownOption[] = [];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Clears custom mapping.
     */
    public onSuperUserChange(): void {
        this.editable.clearCustomMapping();
    }
}
