/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'dialog';
const componentName = 'avi-continue-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const warningHeader = `${prefix}.warningHeader`;

export const continueQuestionWarning = `${prefix}.continueQuestionWarning`;

export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const yesContinueBtnLabel = `${prefix}.yesContinueBtnLabel`;

export const ENGLISH = {
    [warningHeader]: 'Warning',

    [continueQuestionWarning]: 'Are you sure you want to continue?',

    [cancelBtnLabel]: 'Cancel',
    [yesContinueBtnLabel]: 'Yes, Continue',
};
