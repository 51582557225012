<full-modal-config
    modalTitle="{{ modalHeader }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss()"
    (onSubmit)="onSave()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <!-- General -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

            <div>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="ip_reputation_type"
                    for="type"
                    required
                >
                    {{ globalL10nKeys.typeLabel | vtranslate }}
                </label>
                <avi-dropdown
                    name="type"
                    id="type"
                    [options]="ipReputationTypeOptions"
                    [(ngModel)]="editable.config.ip_reputation_type"
                    placeholder="{{ l10nKeys.selectTypeLabel | vtranslate }}"
                ></avi-dropdown>
            </div>
        </full-modal-tab-section>

        <!-- Identification -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.identificationSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.identificationSectionTitle | vtranslate }}</h3>

            <!-- Client Class -->
            <div>
                <label
                    aviLabelWithTooltip
                    objectType="BotIdentification"
                    fieldName="class"
                    required
                    for="class"
                >
                    {{ l10nKeys.clientClassLabel | vtranslate }}
                </label>
                <avi-dropdown
                    name="class"
                    id="class"
                    [options]="botClientClassOptions"
                    [(ngModel)]="botIdentificationConfig.class"
                    placeholder="{{ l10nKeys.selectClientClassLabel | vtranslate }}"
                    required
                ></avi-dropdown>
            </div>

        <!-- Client Type -->
            <div>
                <label
                    aviLabelWithTooltip
                    objectType="BotIdentification"
                    for="client-type"
                    fieldName="type"
                    required
                >
                    {{ l10nKeys.clientTypeLabel | vtranslate }}
                </label>
                <avi-dropdown
                    name="client-type"
                    id="client-type"
                    [options]="botClientTypeOptions"
                    [(ngModel)]="botIdentificationConfig.type"
                    placeholder="{{ l10nKeys.selectClientTypePlaceholder | vtranslate }}"
                    required
                ></avi-dropdown>
            </div>

            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="BotIdentification"
                    fieldName="identifier"
                    required
                    for="identifier"
                >
                    {{ l10nKeys.identifierLabel | vtranslate }}
                </label>
                <input
                    name="identifier"
                    id="identifier"
                    [(ngModel)]="botIdentificationConfig.identifier"
                    clrInput
                    placeholder="{{ l10nKeys.enterStringPlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </full-modal-tab-section>
    </form>
</full-modal-config>
