<avi-card class="system-settings-dns-ntp-card">
    <avi-card_header>
        <div class="system-settings-dns-ntp-card__header">
            <cds-icon
                shape="world"
                size="24"
                class="system-settings-dns-ntp-card__icon"
                status="success"
            ></cds-icon>
            {{ l10nKeys.dnsNtpHeader | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                [objectType]="dnsObjectType"
                fieldName="server_list"
                for="dns-resolvers"
            >
                {{ l10nKeys.dnsResolversLabel | vtranslate }}
            </label>
            <ng-container *ngIf="dnsResolversList?.length; else emptyListTemplate">
                <avi-input-container
                    *ngFor="let dnsResolver of dnsResolversList; trackBy: trackByIndex"
                    noMarginTop
                >
                    <input
                        class="sel-dns"
                        clrInput
                        placeholder="-"
                        [value]="dnsResolver"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
        </avi-input-container>
        <avi-input-container>
            <label
                aviLabelWithTooltip
                [objectType]="ntpObjectType"
                fieldName="ntp_authentication_keys"
                for="ntp-authentication-keys-count"
            >
                {{ l10nKeys.ntpAuthenticationKeysLabel | vtranslate }}
            </label>
            <input
                name="ntp-authentication-keys-count"
                id="ntp-authentication-keys-count"
                clrInput
                placeholder="-"
                [value]="ntpAuthenticationKeysCount"
                readonly
            />
        </avi-input-container>
        <avi-input-container>
            <label
                aviLabelWithTooltip
                [objectType]="ntpObjectType"
                fieldName="ntp_servers"
            >
                {{ l10nKeys.ntpServersLabel | vtranslate }}
            </label>
            <ng-container *ngIf="ntpServersList?.length; else emptyListTemplate">
                <avi-input-container
                    *ngFor="let ntpServer of ntpServersList; index as i; trackBy: trackByIndex"
                    noMarginTop
                >
                    <input
                        name="ntp-servers-{{ i }}"
                        clrInput
                        placeholder="-"
                        [value]="ntpServer"
                        readonly
                    />
                </avi-input-container>
            </ng-container>
        </avi-input-container>
    </avi-card_body>
</avi-card>

<ng-template #emptyListTemplate>
    <avi-input-container noMarginTop>
        <input
            clrInput
            placeholder="-"
            readonly
        />
    </avi-input-container>
</ng-template>
