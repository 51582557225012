/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { VsVipCollectionProvider } from './ajs-upgraded-providers';

import {
    VipListPopoverComponent,
    VsVipAddressGridCellComponent,
    VsVipListPageComponent,
} from './components';

const vsComponents = [
    VipListPopoverComponent,
    VsVipAddressGridCellComponent,
    VsVipListPageComponent,
];

/**
 * @description Angular Module for VS related components.
 *
 * @author Hitesh Mandav
 */
@NgModule({
    declarations: [
        ...vsComponents,
    ],
    imports: [
        FormsModule,
        CommonModule,
        DataGridModule,
        SharedModule,
        BrowserAnimationsModule,
    ],
    providers: [
        VsVipCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class VsVipModule {}
