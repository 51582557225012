/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { Observable } from 'rxjs';

import { GslbSite } from 'object-types';

import { ClrFormLayout } from '@clr/angular';
import { IGslbSite } from 'generated-types';
import * as globalL10n from 'global-l10n';
import './gslb-site-edit-credentials-dialog.component.less';

const { ...globalL10nKeys } = globalL10n;

type TGslbSiteCredentials = Pick<IGslbSite, 'username' | 'password'>;

/**
 * @description Component for editing GSLB Site credentials.
 * @author Hitesh Mandav
 */

@Component({
    selector: 'gslb-site-edit-credentials-dialog',
    templateUrl: './gslb-site-edit-credentials-dialog.component.html',
})
export class GslbSiteEditCredentialsDialogComponent {
    @Input()
    public config: TGslbSiteCredentials;

    @Input()
    public submit$: Observable<void>;

    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component. Used to close
     * this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter<void>();

    public readonly objectType = GslbSite;

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Save button.
     */
    public busy = false;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Handle the cancel operation. Emit the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Handle Submit opertation. Subscribe to the submit$ observable, and close
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }
}
