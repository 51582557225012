<div
    class="newline-converted-textarea"
    provideParentForm
>
    <div class="full-modal-config__form-control-row newline-converted-textarea__no-margin-top">
        <div class="full-modal-config__form-control">
            <div class="newline-converted-textarea__actions-wrapper">
                <avi-tooltip-icon-with-content-wrapper
                    class="newline-converted-textarea__action-icon"
                    shape="copy-to-clipboard"
                    size="16"
                    status="info"
                    (mouseout)="onMouseOutHandler()"
                    (click)="copyToClipboard()"
                >
                    {{ (isTextCopied ? l10nKeys.copiedTooltipText : globalL10nKeys.copyToClipboardLabel) | vtranslate }}
                </avi-tooltip-icon-with-content-wrapper>
            </div>
            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="{{ fieldName }}"
                    for="{{ fieldName }}"
                    [required]="required"
                >
                    {{ l10nKeys.userInputLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    name="{{ fieldName }}"
                    class="newline-converted-textarea__textarea"
                    [(ngModel)]="viewValue"
                    (ngModelChange)="handleUserInputChange()"
                    placeholder="{{ placeholder }}"
                    [required]="required"
                    rows="9"
                    maxlength="5120"
                ></textarea>
            </avi-textarea-container>
        </div>
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="{{ fieldName }}"
                for="preview-value"
            >
                {{ l10nKeys.convertedValuePreviewLabel | vtranslate }}
            </label>
            <div class="newline-converted-textarea__actions-wrapper">
                <avi-tooltip-icon-with-content-wrapper
                    class="newline-converted-textarea__action-icon"
                    shape="copy-to-clipboard"
                    size="16"
                    status="info"
                    (mouseout)="onMouseOutHandler()"
                    (click)="copyToClipboard(true)"
                >
                    {{ (isTextCopied ? l10nKeys.copiedTooltipText : globalL10nKeys.copyToClipboardLabel) | vtranslate }}
                </avi-tooltip-icon-with-content-wrapper>
                <avi-tooltip-icon-with-content-wrapper
                    class="newline-converted-textarea__action-icon"
                    shape="{{ showPureModel ? 'child-arrow' : 'minus'}}"
                    size="16"
                    status="info"
                    (click)="toggleReadOnlyView()"
                >
                    {{ (showPureModel ? l10nKeys.wordwrapTooltipText : l10nKeys.singleLineTooltipText) | vtranslate }}
                </avi-tooltip-icon-with-content-wrapper>
            </div>
            <div
                [innerHtml]="readOnlyOutput"
                class="newline-converted-textarea__converted-value-preview"
            ></div>
        </div>
    </div>
</div>
