<avi-state-page class="nodes-page">
    <avi-state-page_main-content>
        <avi-page-header
            headerText="{{ l10nKeys.nodesPageHeader | vtranslate }}"
            [showEditButton]="true"
            (onButtonClick)="updateCluster()"
        ></avi-page-header>

        <avi-card class="nodes-page__header-card">
            <avi-card_header>
                <div>
                    <cds-icon
                        shape="atom"
                        size="24"
                        status="success"
                    ></cds-icon>
                    {{ l10nKeys.controllerClusterHeader | vtranslate }}
                </div>
            </avi-card_header>
            <avi-card_body>
                <cluster-state-info
                    [clusterState]="clusterState"
                    name="{{ cluster.config.name }}"
                    [clusterVipAddressV4]="cluster.virtualIp?.address"
                    [clusterVipAddressV6]="cluster.virtualIp6?.address"
                    [clusterVipRuntimeStatus]="clusterVipRuntimeStatus"
                ></cluster-state-info>
            </avi-card_body>
        </avi-card>

        <avi-collection-data-grid [config]="nodesGridConfig">
            <avi-collection-data-grid_header>
                <h6 class="nodes-page__grid-header">
                    {{ l10nKeys.nodesTitle | vtranslate : cluster.config.nodes.count }}
                </h6>
            </avi-collection-data-grid_header>
        </avi-collection-data-grid>

    </avi-state-page_main-content>

</avi-state-page>

<ng-template
    #stateFieldTemplateRef
    let-row
>
    <clr-spinner
        [clrSmall]="true"
        *ngIf="!row.getRuntimeData()"
    ></clr-spinner>
    <div [hidden]="!row.getRuntimeData()">
        <cds-icon
            [hidden]="!isClusterStateActive(row.getRuntimeData()?.state)"
            shape="circle"
            size="18"
            status="success"
            solid
        ></cds-icon>
        <cds-icon
            [hidden]="isClusterStateActive(row.getRuntimeData()?.state)"
            shape="circle"
            size="18"
            status="danger"
            solid
        ></cds-icon>
        {{ nodeStateLabel(row.getRuntimeData()?.state) }}
    </div>
</ng-template>

<ng-template
    #roleFieldTemplateRef
    let-row
>
    <clr-spinner
        [clrSmall]="true"
        *ngIf="!row.getRuntimeData()"
    ></clr-spinner>
    <div [hidden]="!row.getRuntimeData()">
        <div [hidden]="!isClusterRoleLeader(row.getRuntimeData()?.role)">
            {{ l10nKeys.clusterLeaderTitle | vtranslate }}
        </div>
        <div [hidden]="isClusterRoleLeader(row.getRuntimeData()?.role)">
            {{ l10nKeys.clusterFollowerTitle | vtranslate }}
        </div>
    </div>
</ng-template>
