/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { AutoScaleLaunchConfigItem } from '../../../../../modules/launch-config/factories';
import * as l10n from './launch-config-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the Launch Config modal.
 * @author alextsg
 */
@Component({
    selector: 'launch-config-modal',
    templateUrl: './launch-config-modal.component.html',
})
export class LaunchConfigModalComponent {
    @Input() public editable: AutoScaleLaunchConfigItem;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
