/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, Input } from '@angular/core';

import './vs-log-cinematic-section-wrapper.component.less';

/**
 * @description
 *
 *  Wrapper component to be used by sections in VS log cinematic for
 *  header setting and container styling.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-wrapper',
    templateUrl: './vs-log-cinematic-section-wrapper.component.html',
})
export class VsLogCinematicSectionWrapperComponent {
    /**
     * Text for the section header.
     */
    @Input()
    public header: string;

    /**
     * Optional icon for the section header.
     */
    @Input()
    public icon ?: string;
}
