/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'network-profile';

export const NetworkProfileModalBreadcrumbTitle = `${prefix}.NetworkProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [NetworkProfileModalBreadcrumbTitle]: 'Network Profile',
};
