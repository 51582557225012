<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    modalClassName="data-script-modal"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid && isEventSelected()"
    class="data-script-set-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        class="sel-name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="pool_uuids"
                            for="pool_uuids"
                        >
                            {{ l10nKeys.poolsInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="pool_uuids"
                            [(ngModel)]="editable.config.pool_refs"
                            [collection]="poolCollection"
                            placeholder="{{ l10nKeys.poolsInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="pool_group_uuids"
                            for="pool_group_uuids"
                        >
                            {{ l10nKeys.poolGroupsInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="pool_group_uuids"
                            [(ngModel)]="editable.config.pool_group_refs"
                            [collection]="poolGroupCollection"
                            placeholder="{{ l10nKeys.poolGroupsInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ipgroup_uuids"
                            for="ipgroup_uuids"
                        >
                            {{ l10nKeys.ipGroupsInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ipgroup_uuids"
                            [(ngModel)]="editable.config.ipgroup_refs"
                            [collection]="ipAddrGroupCollection"
                            placeholder="{{ l10nKeys.ipGroupsInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="string_group_uuids"
                            for="string_group_uuids"
                        >
                            {{ l10nKeys.stringGroupsInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="string_group_uuids"
                            [(ngModel)]="editable.config.string_group_refs"
                            [collection]="stringGroupCollection"
                            placeholder="{{ l10nKeys.stringGroupsInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="protocol_parser_uuids"
                            for="protocol_parser_uuids"
                        >
                            {{ l10nKeys.protocolParserInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="protocol_parser_uuids"
                            [(ngModel)]="editable.config.protocol_parser_refs"
                            [collection]="protocolParserCollection"
                            placeholder="{{ l10nKeys.protocolParserInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ip_reputation_db_uuid"
                            for="ip_reputation_db_uuid"
                        >
                            {{ l10nKeys.ipReputationDbInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ip_reputation_db_uuid"
                            [(ngModel)]="editable.config.ip_reputation_db_ref"
                            [collection]="ipReputationDBCollection"
                            placeholder="{{ l10nKeys.ipReputationDbInputPlaceholder | vtranslate }}"
                            [disableCreate]="true"
                            [disableEdit]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="geo_db_uuid"
                            for="geo_db_uuid"
                        >
                            {{ l10nKeys.geoDbInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="geo_db_uuid"
                            [(ngModel)]="editable.config.geo_db_ref"
                            [collection]="geoDbCollection"
                            placeholder="{{ l10nKeys.geoDbInputPlaceholder | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ssl_profile_uuids"
                            for="ssl_profile_uuids"
                        >
                            {{ l10nKeys.sslProfileInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ssl_profile_uuids"
                            [(ngModel)]="editable.config.ssl_profile_refs"
                            [collection]="sslProfileCollection"
                            placeholder="{{ l10nKeys.sslProfileInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ssl_key_certificate_uuids"
                            for="ssl_key_certificate_uuids"
                        >
                            {{ l10nKeys.sslKeyCertificateLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ssl_key_certificate_uuids"
                            [(ngModel)]="editable.config.ssl_key_certificate_refs"
                            [collection]="certificateCollection"
                            placeholder="{{ l10nKeys.sslKeyCertificatePlaceholder | vtranslate }}"
                            [actions]="certificateDropdownActions"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="pki_profile_uuids"
                            for="pki_profile_uuids"
                        >
                            {{ l10nKeys.pkiProfileInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="pki_profile_uuids"
                            [(ngModel)]="editable.config.pki_profile_refs"
                            [collection]="pkiProfileCollection"
                            placeholder="{{ l10nKeys.pkiProfileInputPlaceholder | vtranslate }}"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.eventsHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.eventsHeader | vtranslate }}</h3>
                <div class="data-script-set-modal__event-section">
                    <avi-alert-group
                        *ngIf="!isEventSelected()"
                        alertType="warning"
                    >
                        <span>{{ l10nKeys.configureEventsWarning | vtranslate }}</span>
                    </avi-alert-group>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <span>
                            <avi-dropdown-button
                                [actions]="eventsTypes"
                                [position]="buttonDropdownPosition"
                                size="sm"
                            >
                                {{ l10nKeys.addButtonLabel | vtranslate }}
                            </avi-dropdown-button>
                        </span>
                    </div>
                </div>
                <div
                    class="data-script-set-modal__event-section"
                    *ngIf="addedL4Events.size > 0"
                >
                    <h6>
                        {{ l10nKeys.l4EventsHeader | vtranslate }} ({{ addedL4Events.size }})
                    </h6>
                    <data-script-config
                        [dataScripts]="editable.config.l4Scripts"
                        [dataScriptEvents]="addedL4Events"
                        (onEventRemove)="handleEventRemove($event)"
                    ></data-script-config>
                </div>

                <div
                    class="data-script-set-modal__event-section"
                    *ngIf="addedDnsEvents.size > 0"
                >
                    <h6>
                        {{ l10nKeys.dnsEventsHeader | vtranslate }} ({{ addedDnsEvents.size }})
                    </h6>
                    <data-script-config
                        [dataScripts]="editable.config.dnsScripts"
                        [dataScriptEvents]="addedDnsEvents"
                        (onEventRemove)="handleEventRemove($event)"
                    ></data-script-config>
                </div>

                <div
                    class="data-script-set-modal__event-section"
                    *ngIf="addedSslEvents.size > 0"
                >
                    <h6>
                        {{ l10nKeys.sslEventsHeader | vtranslate }} ({{ addedSslEvents.size }})
                    </h6>
                    <data-script-config
                        [dataScripts]="editable.config.sslScripts"
                        [dataScriptEvents]="addedSslEvents"
                        (onEventRemove)="handleEventRemove($event)"
                    ></data-script-config>
                </div>

                <div
                    class="data-script-set-modal__event-section"
                    *ngIf="addedHttpEvents.size > 0"
                >
                    <h6>
                        {{ l10nKeys.httpEventsHeader | vtranslate }} ({{ addedHttpEvents.size }})
                    </h6>
                    <data-script-config
                        [dataScripts]="editable.config.httpScripts"
                        [dataScriptEvents]="addedHttpEvents"
                        (onEventRemove)="handleEventRemove($event)"
                    ></data-script-config>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.rateLimitersHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.rateLimitersHeader | vtranslate }}</h3>
                <ds-rate-limiters-grid
                    [rateLimiters]="editable.rateLimiters.config"
                    (onAddRateLimiter)="handleRateLimiterAdd()"
                    (onDeleteRateLimiter)="handleRateLimiterDelete($event)"
                ></ds-rate-limiters-grid>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
