/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { L10nService } from '@vmw/ngx-vip';
import { AviDropdownButtonPosition }
    from 'ng/shared/components/avi-dropdown-button/avi-dropdown-button.constants';
import { JsonViewerComponent }
    from 'ng/modules/dialog/components/avi-config-viewer/json-viewer/json-viewer.component';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { FullModalService } from 'ng/modules/core/services/full-modal/full-modal.service';
import { IAviDropdownButtonAction } from 'ng/shared/components/avi-dropdown-button';

import './full-modal-config-viewer.component.less';
import * as l10n from './full-modal-config-viewer.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const VIEW_CONFIG_AS_JSON = 'VIEW_CONFIG_AS_JSON';

/**
 * @description
 * FullModalConfigViewer component, allows user to view config as
 * Json and Yaml(Ansible) format.
 *
 * @author Abhinesh Gour
 */
@Component({
    selector: 'full-modal-config-viewer',
    templateUrl: './full-modal-config-viewer.component.html',
})
export class FullModalConfigViewerComponent implements OnInit, OnDestroy {
    /**
     * Editable item of Host Modal Component.
     */
    @Input()
    public item: Item;

    /**
     * Position of the config viewer dropdown.
     */
    public position = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * Actions for the config viewer dropdown.
     */
    public actions: IAviDropdownButtonAction[];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subscription to the viewMode value.
     */
    private viewModeSubscription: Subscription;

    /**
     * If false, show the Review option to change to view mode. If true, show the Edit option to
     * switch back to edit mode.
     */
    private viewMode = false;

    constructor(
        private readonly dialogService: DialogService,
        l10nService: L10nService,
        private readonly fullModalService: FullModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.actions = [
            {
                label: l10nService.getMessage(l10nKeys.viewAsJson),
                onClick: () => this.openJsonViewerModal(),
            },
            // Uncomment to enable view mode.
            // {
            //     label: l10nService.getMessage(l10nKeys.review),
            //     onClick: () => this.fullModalService.setViewMode(true),
            //     hidden: () => this.viewMode,
            // },
            // {
            //     label: l10nService.getMessage(globalL10nKeys.editLabel),
            //     onClick: () => this.fullModalService.setViewMode(false),
            //     hidden: () => !this.viewMode,
            // },
        ];
    }

    public ngOnInit(): void {
        this.viewModeSubscription = this.fullModalService.viewMode$.subscribe(viewMode => {
            this.viewMode = viewMode;
        });
    }

    public ngOnDestroy(): void {
        this.viewModeSubscription.unsubscribe();
    }

    /**
     * Called when the connect button is clicked. Opens up the dialog component. Provides the
     * closeDialog method to the dialog component to close itself.
     */
    public openJsonViewerModal(): void {
        this.dialogService.add({
            id: VIEW_CONFIG_AS_JSON,
            component: JsonViewerComponent as Type<Component>,
            componentProps: {
                closeDialog: () => this.dialogService.remove(VIEW_CONFIG_AS_JSON),
                config: this.item.dataToSave(),
                modified: this.item.modified(),
                defaultConfig: this.item.getDefaultConfig(),
            },
        });
    }
}
