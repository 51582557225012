<div class="credentials-verification">
    <ng-content></ng-content>
    <div
        class="credentials-verification__button-container"
        *ngIf="!hideChangeCredentialsButton"
    >
        <cds-button
            class="sel-change-credentials"
            size="sm"
            (click)="handleConnect()"
        >
            {{ getCredentialsButtonLabel() }}
        </cds-button>
    </div>
</div>
