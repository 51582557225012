/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-domain-names-grid';
const prefix = `${moduleName}.${componentName}`;

export const subdomainInputPlaceholder = `${prefix}.subdomainInputPlaceholder`;
export const subdomainInputLabel = `${prefix}.subdomainInputLabel`;
export const applicationNameInputLabel = `${prefix}.applicationNameInputLabel`;
export const applicationNameInputPlaceholder = `${prefix}.applicationNameInputPlaceholder`;
export const domainNamesGridLabel = `${prefix}.domainNamesGridLabel`;

export const ENGLISH = {
    [subdomainInputPlaceholder]: 'Select Subdomain',
    [subdomainInputLabel]: 'Subdomain',
    [applicationNameInputLabel]: 'Application Name',
    [applicationNameInputPlaceholder]: 'Enter Application Name',
    [domainNamesGridLabel]: 'Domain Name ({0})',
};
