/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'diagram';
const componentName = 'avi-bar-graph';
const prefix = `${moduleName}.${componentName}`;

export const totalLabel = `${prefix}.totalLabel`;
export const errLabel = `${prefix}.errLabel`;

export const ENGLISH = {
    [totalLabel]: 'Total',
    [errLabel]: 'An error occurred while loading the graph, please try again.',
};
