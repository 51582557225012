/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Returns an injector function.
 * @param {angular.$injector} $injector
 * @return {module:avi/core.ajsDependencyInjector}
 * @private
 */
function getAjsDependencyInjector($injector) {
    /**
     * @function ajsDependencyInjector
     * @memberOf module:avi/core
     * @param {Class|Function} OriginalClass - Pure Class or constructor function.
     * @return {Class|Function} - Class extending the original one.
     * @desc
     *
     *    Extends class passed with getAjsDependency_ method providing access to
     *    AngularJs dependencies. Returns modified class.
     *
     *    Supports ES5 and ES6 classes only. Pure functions are not supported.
     *
     * @see module:avi/core.initAjsDependency
     * @see module:avi/core.AjsDependency
     * @author Alex Malitsky
     */
    // TODO add pure function support for .factory (through property on function itself and closure)
    // TODO figure if we have a use case for double wrapping of the dependency and if we do how
    //     do we want to handle it
    function ajsDependencyInjector(OriginalClass) {
        const dependencyHash = {};

        if (typeof OriginalClass !== 'function') {
            throw new Error('please pass class or constructor function');
        }

        const addDependenciesToHash = function(ajsDependencies = []) {
            ajsDependencies.forEach(name => {
                if (!(name in dependencyHash)) {
                    dependencyHash[name] = $injector.get(name);
                }
            });
        };

        /**
         * Looks up dependency by it's name in the hash of dependencies.
         * @param {string} name
         * @return {*|null}
         * @inner
         */
        function getAjsDependency(name) {
            return dependencyHash[name] || null;
        }

        for (
            let CurrentClass = OriginalClass;
            CurrentClass !== Object.prototype;
            CurrentClass = Object.getPrototypeOf(CurrentClass)
        ) {
            const { ajsDependencies } = CurrentClass;

            addDependenciesToHash(ajsDependencies);
        }

        return class AjsDependencyWrapper extends OriginalClass {
            /**
             * Performs instanceof check against "pure" class form. Works fine when "wrapped"
             * class is passed since it is wrapped on top of the original class anyway.
             * Falls back to default behavior when called by descendant classes. Later is needed
             * cause static methods and properties ARE inherited via ES6 class syntax.
             * @override
             * @param {*} object
             * @return {boolean}
             */
            static [Symbol.hasInstance](object) {
                return this === AjsDependencyWrapper ?
                    object instanceof OriginalClass : super[Symbol.hasInstance](object);
            }

            /**
             * Provides aJs dependency injector for static methods of the class.
             * @param {string} name
             * @return {*|null}
             * @protected
             */
            static getAjsDependency_(name) {
                return getAjsDependency(name);
            }

            getAjsDependency_(name) {
                return getAjsDependency(name);
            }
        };
    }

    return ajsDependencyInjector;
}

export { getAjsDependencyInjector };
