<avi-card class="ip-reputation-change-request-card">
    <avi-card_header>
        <div class="ip-reputation-change-request-card__header">
            {{ l10nKeys.header | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <div class="ip-reputation-change-request-card__description">
            <span>
                {{ l10nKeys.description | vtranslate }}
            </span>
        </div>
        <button
            class="btn btn-sm btn-primary ip-reputation-change-request-card__change-button"
            (click)="onChangeRequestClick()"
        >
            {{ l10nKeys.buttonLabel | vtranslate }}
        </button>
        <div class="ip-reputation-change-request-card__change-button-description">
            {{ l10nKeys.changeButtonDescription | vtranslate }} ({{ changeRequestUrl }})
        </div>
    </avi-card_body>
</avi-card>
