<div
    provideParentForm
    class="avi-repeated-strings-grid">
    <div
        *ngIf="hasDuplicateError"
        class="avi-repeated-strings-grid"
    >
        <avi-alert-group alertType="danger">
            {{ l10nKeys.keyDuplicationErrorMsg | vtranslate }} <br>
            {{ l10nKeys.duplicatValuesErrorMsg | vtranslate : duplicateValues.join(', ')}}
        </avi-alert-group>
    </div>
    <avi-data-grid
        [config]="repeatedStringsGridConfig"
        [rows]="stringRows"
        [invalidateForm]="hasDuplicateError"
        [gridTitle]="gridTitle"
    >
        <avi-data-grid_actions>
            <cds-button
                (click)="addString()"
                size="sm"
                [disabled]="hasDuplicateError || hasMaxInputs"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #inputTemplateRef
            let-row
            let-index="index"
        >
            <ng-container *ngIf="autoCompleteOptions">
                <avi-auto-complete
                    helperText="{{ inputHelperText }}"
                    name="{{ name + index }}"
                    id="{{ name + index }}"
                    placeholder="{{ placeholder | vtranslate }}"
                    [(ngModel)]="row.value"
                    (ngModelChange)="handleRowModelChange()"
                    [options]="autoCompleteOptions"
                    regex="{{ regex }}"
                    required
                ></avi-auto-complete>
            </ng-container>
            <ng-container *ngIf="!autoCompleteOptions">
                <avi-input-container
                    noMarginTop
                    helperText="{{ inputHelperText }}"
                >
                    <input
                        name="{{ name + index }}"
                        type="text"
                        clrInput
                        placeholder="{{ placeholder | vtranslate }}"
                        [(ngModel)]="row.value"
                        (ngModelChange)="handleRowModelChange()"
                        regexPattern="{{ regex }}"
                        required
                    />
                </avi-input-container>
            </ng-container>
        </ng-template>
    </avi-data-grid>
</div>
