/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-subdomain-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const subdomainLabel = `${prefix}.subdomainLabel`;
export const subdomainPlaceholder = `${prefix}.subdomainPlaceholder`;
export const placementSectionTitle = `${prefix}.placementSectionTitle`;
export const hostOnSiteWithAllSubdomainsLabel = `${prefix}.hostOnSiteWithAllSubdomainsLabel`;
export const dnsVsLabel = `${prefix}.dnsVsLabel`;
export const gslbSitesGridHeader = `${prefix}.gslbSitesGridHeader`;
export const columnValueSuffix = `${prefix}.columnValueSuffix`;
export const gslbSiteLabel = `${prefix}.gslbSiteLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Subdomain',
    [modalHeaderNew]: 'New Subdomain',
    [subdomainLabel]: 'Subdomain',
    [subdomainPlaceholder]: 'Enter Subdomain',
    [placementSectionTitle]: 'Placement',
    [hostOnSiteWithAllSubdomainsLabel]: "Host on Sites with 'All Subdomains' Enabled",
    [dnsVsLabel]: 'DNS Virtual Service(s)',
    [gslbSitesGridHeader]: 'GSLB Sites ({0})',
    [columnValueSuffix]: '(+{0} More)',
    [gslbSiteLabel]: 'GSLB Site',
};
