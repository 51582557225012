/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ACTIVE_USER_PROFILE_SERVICE_TOKEN } from 'ajs/modules/core/services/active-user-profile';

// By default, AngularJS will catch errors and log them to
// the Console. We want to keep that behavior; however, we
// want to intercept it so that we can also log the errors
// to the server for later analysis.
angular.module('aviApp').provider('$exceptionHandler', {
    $get: ['errorLogService', function(errorLogService) {
        return errorLogService;
    }],
});

// The error log service is our wrapper around the core error
// handling ability of AngularJS. Notice that we pass off to
// the native '$log' method and then handle our additional
// server-side logging.
angular.module('aviApp').factory('errorLogService', [
'$log', '$window', 'logErrorToController',
function($log, $window, logErrorToController) {
    // I log the given error to the remote server.
    return function(exception, cause) {
        // Pass off the error to the default error handler
        // on the AngualrJS logger. This will output the
        // error to the console (and let the application
        // keep running normally for the user).
        $log.error(exception, cause);

        // Now, we need to try and log the error the server.
        try {
            let errorMessage = exception.toString();

            if (cause) {
                errorMessage += ` [${cause}]`;
            }

            logErrorToController(errorMessage, undefined, undefined, undefined, exception);
        } catch (loggingError) {
            // For Developers - log the log-failure.
            $log.warn('Error logging failed');
            $log.log(loggingError);
        }
    };
}]);

//TODO throttle reporting?
angular.module('aviApp').factory('logErrorToController', [
'$window', '$injector', 'ErrorStackParser',
function($window, $injector, ErrorStackParser) {
    return (errorMessage, source, lineno, colno, error) => {
        if (!('jsErrors' in $window)) {
            $window.jsErrors = [];
        }

        if (!('jsErrorsHash' in $window)) {
            $window.jsErrorsHash = {};
        }

        if (angular.isObject(errorMessage)) {
            errorMessage = errorMessage.message;
        }

        let stackTrace = [];

        //doesn't support errors of string type
        try {
            stackTrace = ErrorStackParser.parse(error);
        } catch (parserError) {
            if (!angular.isString(error)) {
                console.error(parserError);
            }
        }

        const Auth = $injector.get('Auth');
        const activeUserProfileService = $injector.get(ACTIVE_USER_PROFILE_SERVICE_TOKEN);
        const { href: errorUrl } = $window.location;
        const hash = [errorUrl, stackTrace[0]].join(' | ');

        if ((!$window.avi || !$window.avi.debugMode) &&
            !(hash in $window.jsErrorsHash) && Auth.isLoggedIn()
        ) {
            //we might want to extend the list of properties we send to the backend
            const
                $http = $injector.get('$http'),
                errorObj = {
                    errorMessage,
                    errorUrl,
                    stackTrace: stackTrace.length ? stackTrace : undefined,
                    username: activeUserProfileService.username,
                    tenant: Auth.getTenantName(),
                };

            $window.jsErrors.push(errorObj);
            $window.jsErrorsHash[hash] = errorObj;

            $http.post('/api/ui_error', errorObj);
        }
    };
}]);
