/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-ip-reputation-type-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const nameLabel = `${prefix}.nameLabel`;
export const enterNamePlaceholder = `${prefix}.enterNamePlaceholder`;
export const typeMappingsLabel = `${prefix}.typeMappingsLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const botClientClassLabel = `${prefix}.botClientClassLabel`;
export const botClientTypeLabel = `${prefix}.botClientTypeLabel`;
export const identifierLabel = `${prefix}.identifierLabel`;
export const editLabel = `${prefix}.editLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create IP Reputation Mapping',
    [modalTitleEdit]: 'Edit IP Reputation Mapping',
    [typeMappingsLabel]: 'Type Mappings ({0})',
    [addButtonLabel]: 'Add',
    [botClientClassLabel]: 'Bot Client Class',
    [botClientTypeLabel]: 'Bot Client Type',
    [identifierLabel]: 'Identifier',
};
