/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'support';
const componentName = 'case-list';
const prefix = `${moduleName}.${componentName}`;

export const attachmentBtnLabel = `${prefix}.attachmentBtnLabel`;
export const editBtnLabel = `${prefix}.editBtnLabel`;
export const columnTitleDateOpened = `${prefix}.columnTitleDateOpened`;
export const columnTitleCaseNumber = `${prefix}.columnTitleCaseNumber`;
export const columnTitleSubject = `${prefix}.columnTitleSubject`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleLastUpdated = `${prefix}.columnTitleLastUpdated`;
export const registerNowBtnLabel = `${prefix}.registerNowBtnLabel`;
export const registerControllerPromptLabel = `${prefix}.registerControllerPromptLabel`;
export const enableCaseManagementPromptLabel = `${prefix}.enableCaseManagementPromptLabel`;
export const goToCloudServicesBtnLabel = `${prefix}.goToCloudServicesBtnLabel`;
export const pulseMustBeConnectedLabel = `${prefix}.pulseMustBeConnectedLabel`;
export const connectNowBtnLabel = `${prefix}.connectNowBtnLabel`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [attachmentBtnLabel]: 'Attachment',
    [columnTitleDateOpened]: 'Date Opened',
    [columnTitleCaseNumber]: 'Case Number',
    [columnTitleSubject]: 'Subject',
    [columnTitleLastUpdated]: 'Last Updated',
    [registerControllerPromptLabel]: 'Register your Controller and easily access your support tickets from inside Avi Vantage.',
    [registerNowBtnLabel]: 'Register Now.',
    [enableCaseManagementPromptLabel]: 'Case Management option must be enabled in Cloud Services.',
    [goToCloudServicesBtnLabel]: 'Go to Cloud Services',
    [pulseMustBeConnectedLabel]: 'Pulse must be connected to access your support tickets from inside Avi Vantage.',
    [connectNowBtnLabel]: 'Connect Now.',
    [headerLabel]: 'Support Cases',
};
