/** @module VsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { StringService } from 'string-service';
import { IVipRuntimeDetail } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { IExtendedVipRuntimeDetail, OperStateType } from '../../vs.types';
import template from './vip-list-popover.component.html';
import './vip-list-popover.component.less';
import * as l10n from './vip-list-popover.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const stateIconClassPrefix = 'vip-list-popover__state-icon--';

/**
 * @description
 *
 *   Renders the list of vip IP addresses filtered by state passed via operStateType binding.
 *   For VIPs with operStateType as Down also renders the reason string.
 *
 * @author Ram Pal
 */
class VipListPopoverController {
    /**
     *  operStateType property holds current state of vip address.
     */
    public operStateType: OperStateType;

    /**
     * Property holding OperStateType.
     */
    public readonly operStateTypeEnum = OperStateType;

    /**
     * List of VIP runtime data.
     */
    public vips: IVipRuntimeDetail[];

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns header title based on vip state and address length.
     */
    public getHeaderTitle(): string {
        return this.l10nService
            .getMessage(
                l10nKeys.headerPrefix,
                [this.stringService.capitalize(this.operStateType || ''), this.vips?.length],
            );
    }

    /**
     * Returns operational state for the vip.
     */
    public getVipOperState(vip: IExtendedVipRuntimeDetail): string {
        return this.stringService.enumeration(vip.oper_status?.state || '', 'OPER_');
    }

    /**
     * Returns class for circle arrow icon based on vip state.
     */
    public getVipStateIconClass(): string {
        return stateIconClassPrefix + this.operStateType;
    }

    /**
     * Returns icon shape based on operStateType.
     */
    public getIconShape(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
            case OperStateType.OPER_DOWN:
                return 'circle-arrow';

            case OperStateType.OPER_PENDING:
                return 'minus-circle';

            default:
                return '';
        }
    }

    /**
     * Returns check for adding comma as a separator between ipv4 and ipv6 addresses.
     * returns true when ipv4 & ipv6 addresses are present, false otherwise.
     */
    public separatorRequired(vip: IExtendedVipRuntimeDetail): boolean {
        return Boolean(vip.ip_address?.addr && vip.ip6_address?.addr);
    }

    /**
     * Returns icon direction based on operStateType.
     */
    public getIconDirection(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
                return 'up';

            case OperStateType.OPER_DOWN:
                return 'down';

            default:
                return '';
        }
    }
}

VipListPopoverController.$inject = [
    'l10nService',
    'stringService',
];

export const vipListPopoverOptions = {
    bindings: {
        vips: '<',
        operStateType: '<',
        onClose: '&',
    },
    controller: VipListPopoverController,
    template,
};
