/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    DNSConfiguration,
    NTPConfiguration,
} from 'object-types';

import * as l10n from './system-settings-dns-ntp-card.l10n';
import './system-settings-dns-ntp-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for DNS/NTP Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-dns-ntp-card',
    templateUrl: './system-settings-dns-ntp-card.component.html',
})
export class SystemSettingsDnsNtpCardComponent {
    /**
     * DNS Servers Configured for Controller.
     */
    @Input()
    public dnsResolversList: string[];

    /**
     * NTP Authentication Keys Count.
     */
    @Input()
    public ntpAuthenticationKeysCount: number;

    /**
     * NTP Servers list.
     */
    @Input()
    public ntpServersList: string[];

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * DNS object type for template usage.
     */
    public readonly dnsObjectType = DNSConfiguration;

    /**
     * NTP object type for template usage.
     */
    public readonly ntpObjectType = NTPConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Track by index method for *ngFor template usage.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
