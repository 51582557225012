<div class="vs-log-cinematic-section-security-waf">
    <ng-container *ngrxLet="wafLog$ as wafLog">
        <avi-card [noBodyPadding]="true">
            <avi-card_body class="vs-log-cinematic-section-security-waf__top-info">
                <!-- Timing -->
                <div class="vs-log-cinematic-section-security-waf__top-info-end-to-end">
                    <h5 class="vs-log-cinematic-section-security-waf__top-info-end-to-end-header">
                        {{ l10nKeys.timingLabel | vtranslate }}
                    </h5>
                    <div class="vs-log-cinematic-section-security-waf__top-info-end-to-end-body">
                        <vs-log-cinematic-end-to-end-bridge
                            class="vs-log-cinematic-section-security-waf__top-info-bridge"
                            topHeader="{{ l10nKeys.requestHeaderLabel | vtranslate }}"
                            arrowColor="deep-blue"
                            [endToEndTime]="wafLog.latency_request_header_phase"
                            [noStatusCode]="true"
                            [isWafTiming]="true"
                            endToEndTimeFilterProp="waf_log.latency_request_header_phase"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        ></vs-log-cinematic-end-to-end-bridge>
                        <vs-log-cinematic-end-to-end-bridge
                            class="vs-log-cinematic-section-security-waf__top-info-bridge"
                            topHeader="{{ l10nKeys.requestBodyLabel | vtranslate }}"
                            arrowColor="deep-blue"
                            [endToEndTime]="wafLog.latency_request_body_phase"
                            [noStatusCode]="true"
                            [isWafTiming]="true"
                            endToEndTimeFilterProp="waf_log.latency_request_body_phase"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        ></vs-log-cinematic-end-to-end-bridge>
                        <vs-log-cinematic-end-to-end-bridge
                            class="vs-log-cinematic-section-security-waf__top-info-bridge"
                            topHeader="{{ l10nKeys.responseHeaderLabel | vtranslate }}"
                            arrowColor="deep-blue"
                            [endToEndTime]="wafLog.latency_response_header_phase"
                            [noStatusCode]="true"
                            [isWafTiming]="true"
                            endToEndTimeFilterProp="waf_log.latency_response_header_phase"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        ></vs-log-cinematic-end-to-end-bridge>
                        <vs-log-cinematic-end-to-end-bridge
                            class="vs-log-cinematic-section-security-waf__top-info-bridge"
                            topHeader="{{ l10nKeys.responseBodyLabel | vtranslate }}"
                            arrowColor="deep-blue"
                            [endToEndTime]="wafLog.latency_response_body_phase"
                            [noStatusCode]="true"
                            [isWafTiming]="true"
                            endToEndTimeFilterProp="waf_log.latency_response_body_phase"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        ></vs-log-cinematic-end-to-end-bridge>
                    </div>
                </div>

                <!-- Resources -->
                <div class="vs-log-cinematic-section-security-waf__top-info-resources">
                    <h5 class="vs-log-cinematic-section-security-waf__top-info-resources-header">
                        {{ l10nKeys.resourcesLabel | vtranslate }}
                    </h5>
                    <div class="vs-log-cinematic-section-security-waf__top-info-resources-body">
                        <div class="vs-log-cinematic-section-security-waf__top-info-resources-body--form-control">
                            <label
                                aviLabelWithTooltip
                                for="waf-memory-used"
                                objectType="WafLog"
                                fieldName="memory_allocated"
                                vsLogCinematicFieldTitle
                                [withMarginTop]="false"
                            >
                                {{ l10nKeys.wafMemoryUsedLabel | vtranslate }}
                            </label>

                            <ng-container *ngIf="wafLog.memory_allocated !== undefined; else na">
                                <span
                                    vsLogFilterList
                                    id="waf-memory-used"
                                    property="waf_log.memory_allocated"
                                    [value]="wafLog.memory_allocated"
                                    displayValue="{{ wafLog.memory_allocated | formatBytes }}"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ wafLog.memory_allocated | formatBytes }}
                                </span>
                            </ng-container>
                        </div>
                        <div class="vs-log-cinematic-section-security-waf__top-info-resources-body--form-control">
                            <label
                                aviLabelWithTooltip
                                for="waf-learning-status"
                                objectType="WafLog"
                                fieldName="learning_status"
                                vsLogCinematicFieldTitle
                                [withMarginTop]="false"
                            >
                                {{ l10nKeys.wafLearningStatus | vtranslate }}
                            </label>

                            <ng-container *ngIf="wafLog.learning_status !== undefined; else na">
                                <span
                                    vsLogFilter
                                    id="waf-learning-status"
                                    property="waf_log.learning_status"
                                    [value]="wafLog.learning_status"
                                    displayValue="{{ wafLog.learning_status | enumValueLabel : 'WafLearningStatus' }}"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ wafLog.learning_status | enumValueLabel : 'WafLearningStatus' }}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </avi-card_body>
        </avi-card>
    </ng-container>

    <div
        class="vs-log-cinematic-section-security-waf__subsection"
        *ngIf="wafAllowListLogs$ | async as wafAllowListLogs"
    >
        <label aviLabelWithTooltip>
            {{ l10nKeys.allowListLabel | vtranslate : wafAllowListLogs.length : labels.hitLabel }}
        </label>

        <div
            class="vs-log-cinematic-section-security-waf__expander-list"
        >
            <expander
                [expanded]="true"
                openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                class="vs-log-cinematic-section-security-waf__expander"
                *ngFor="let log of wafAllowListLogs; trackBy: trackByIndex"
            >
                <opener>
                    <cds-icon
                        class="vs-log-cinematic-section-security-waf__icon--file"
                        shape="file"
                        size="16"
                    ></cds-icon>
                    <span
                        vsLogPartialSelectionFilter
                        property="waf_log.allowlist_logs.rule_name"
                        [value]="ruleName"
                        position="right"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        *ngIf="log.rule_name as ruleName; else na"
                    ></span>
                </opener>

                <content>
                    <div class="vs-log-cinematic-section-security-waf__content">
                        <span
                            vsLogCinematicFieldTitle
                            [withMarginTop]="false"
                        >
                            {{ globalL10nKeys.actionLabel | vtranslate }}
                        </span>
                        <span
                            vsLogFilter
                            property="waf_log.allowlist_logs.actions"
                            [value]="log.actions[0]"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            *ngIf="log.actions?.length; else na"
                        >
                            {{ getwafActionLabel(log) | vtranslate }}
                        </span>
                    </div>
                </content>
            </expander>
        </div>
    </div>

    <div
        class="vs-log-cinematic-section-security-waf__subsection"
        *ngIf="wafPsmLogs$ | async as wafPsmLogs"
    >
        <label aviLabelWithTooltip>
            {{ l10nKeys.positiveSecurityLabel | vtranslate : labels.wafPsmGroupLabel : labels.wafPsmLocationLabel : labels.wafPsmRuleLabel }}
        </label>

        <div class="vs-log-cinematic-section-security-waf__expander-list">
            <expander
                [expanded]="true"
                openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                class="vs-log-cinematic-section-security-waf__expander"
                *ngFor="let log of wafPsmLogs; trackBy: trackByIndex"
            >
                <opener>
                    <cds-icon
                        class="vs-log-cinematic-section-security-waf__icon--layers"
                        shape="layers"
                        size="16"
                    ></cds-icon>
                    <span
                        vsLogPartialSelectionFilter
                        property="waf_log.psm_logs.group_name"
                        [value]="groupName"
                        position="right"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        *ngIf="log.group_name as groupName; else na"
                    ></span>
                </opener>

                <content>
                    <div class="vs-log-cinematic-section-security-waf__content">
                        <span
                            vsLogCinematicFieldTitle
                            [withMarginTop]="false"
                        >
                            {{ l10nKeys.actionLabel | vtranslate }}
                        </span>
                        <span
                            vsLogFilter
                            property="waf_log.psm_logs.actions"
                            [value]="log.actions[0]"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            *ngIf="log.actions?.length; else na"
                        >
                            {{ getwafActionLabel(log) | vtranslate }}
                        </span>
                    </div>

                    <expander
                        [expanded]="true"
                        openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                        class="vs-log-cinematic-section-security-waf__expander"
                    >
                        <opener>
                            <div class="vs-log-cinematic-section-security-waf__child-l1">
                                <cds-icon
                                    class="vs-log-cinematic-section-security-waf__icon--compass"
                                    shape="compass"
                                    size="16"
                                ></cds-icon>
                                <span
                                    vsLogPartialSelectionFilter
                                    property="waf_log.psm_logs.location"
                                    [value]="location"
                                    position="right"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    *ngIf="log.location as location; else na"
                                ></span>
                            </div>
                        </opener>

                        <content>
                            <expander
                                [expanded]="true"
                                openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                                class="vs-log-cinematic-section-security-waf__expander"
                            >
                                <opener>
                                    <div class="vs-log-cinematic-section-security-waf__child-l2">
                                        <cds-icon
                                            class="vs-log-cinematic-section-security-waf__icon--file"
                                            shape="file"
                                            size="16"
                                        ></cds-icon>
                                        <span
                                            vsLogFilter
                                            class="vs-log-cinematic-section-security-waf__ruleid"
                                            property="waf_log.psm_logs.rule_id"
                                            [value]="ruleId"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            *ngIf="log.rule_id as ruleId"
                                        >
                                            {{ ruleId }}
                                        </span>
                                        <span
                                            vsLogPartialSelectionFilter
                                            property="waf_log.psm_logs.rule_name"
                                            [value]="ruleName"
                                            position="right"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            *ngIf="log.rule_name as ruleName; else na"
                                        ></span>
                                    </div>
                                </opener>

                                <content>
                                    <div class="vs-log-cinematic-section-security-waf__content">
                                        <div class="vs-log-cinematic-section-security-waf__child-l2">
                                            <span
                                                vsLogCinematicFieldTitle
                                                [withMarginTop]="false"
                                            >
                                                {{ globalL10nKeys.matchLabel | vtranslate }}
                                            </span>
                                            <avi-data-grid
                                                class="vs-log-cinematic-section-security-waf__no-margin-top"
                                                [config]="wafPsmLogMatchConfig"
                                                [rows]="log.matches"
                                            ></avi-data-grid>
                                        </div>
                                    </div>
                                </content>
                            </expander>
                        </content>
                    </expander>
                </content>
            </expander>
        </div>
    </div>

    <div
        class="vs-log-cinematic-section-security-waf__subsection"
        *ngIf="wafApplicationRuleLogs$ | async as applicationRuleLogs"
    >
        <label aviLabelWithTooltip>
            {{ l10nKeys.applicationRulesLabel | vtranslate : labels.wafAppGroupLabel : labels.wafAppRuleLabel }}
        </label>
        <div class="vs-log-cinematic-section-security-waf__expander-list">
            <expander
                [expanded]="true"
                openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                class="vs-log-cinematic-section-security-waf__expander"
                *ngFor="let log of applicationRuleLogs; trackBy: trackByIndex"
            >
                <opener>
                    <cds-icon
                        class="vs-log-cinematic-section-security-waf__icon--layers"
                        shape="layers"
                        size="16"
                    ></cds-icon>
                    <span
                        vsLogPartialSelectionFilter
                        property="waf_log.rule_logs.rule_group"
                        [value]="ruleGroup"
                        position="right"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        *ngIf="log.rule_group as ruleGroup; else na"
                    ></span>
                </opener>

                <content>
                    <expander
                        [expanded]="true"
                        openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                        class="vs-log-cinematic-section-security-waf__expander"
                    >
                        <opener>
                            <div class="vs-log-cinematic-section-security-waf__child-l1">
                                <cds-icon
                                    class="vs-log-cinematic-section-security-waf__icon--file"
                                    shape="file"
                                    size="16"
                                ></cds-icon>
                                <span
                                    vsLogFilter
                                    class="vs-log-cinematic-section-security-waf__ruleid"
                                    property="waf_log.rule_logs.rule_id"
                                    [value]="ruleId"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    *ngIf="log.rule_id as ruleId"
                                >
                                    {{ ruleId }}
                                </span>
                                <span
                                    vsLogPartialSelectionFilter
                                    property="waf_log.rule_logs.rule_name"
                                    [value]="ruleName"
                                    position="right"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    *ngIf="log.rule_name as ruleName; else na"
                                ></span>
                            </div>
                        </opener>

                        <content>
                            <div class="vs-log-cinematic-section-security-waf__content">
                                <div class="vs-log-cinematic-section-security-waf__child-l1">
                                    <span
                                        vsLogCinematicFieldTitle
                                        [withMarginTop]="false"
                                    >
                                        {{ globalL10nKeys.messageLabel | vtranslate }}
                                    </span>

                                        <span
                                            vsLogPartialSelectionFilter
                                            property="waf_log.rule_logs.msg"
                                            [value]="message"
                                            position="right"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            *ngIf="log.msg as message; else na"
                                        ></span>

                                    <span vsLogCinematicFieldTitle>
                                        {{ globalL10nKeys.matchLabel | vtranslate }}
                                    </span>

                                    <avi-data-grid
                                        class="vs-log-cinematic-section-security-waf__no-margin-top"
                                        [config]="wafRuleLogMatchConfig"
                                        [rows]="log.matches"
                                    ></avi-data-grid>

                                    <span vsLogCinematicFieldTitle>
                                        {{ l10nKeys.phaseLabel | vtranslate }}
                                    </span>

                                    <span
                                        vsLogPartialSelectionFilter
                                        *ngIf="log.phase as phase; else na"
                                        property="waf_log.rule_logs.phase"
                                        [value]="phase"
                                        position="right"
                                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    ></span>

                                    <span vsLogCinematicFieldTitle>
                                        {{ l10nKeys.tagsLabel | vtranslate }}
                                    </span>

                                    <ng-container *ngIf="log.tags as tags; else na">
                                        <span
                                            vsLogFilter
                                            property="waf_log.rule_logs.tags"
                                            [value]="tag"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            class="label label-info"
                                            *ngFor="let tag of tags; trackBy: trackByIndex"
                                        >
                                            {{ tag }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </content>
                    </expander>
                </content>
            </expander>
        </div>
    </div>

    <div
        class="vs-log-cinematic-section-security-waf__subsection"
        *ngIf="wafRuleLogs$ | async as wafRuleLogs"
    >
        <label aviLabelWithTooltip>
            {{ l10nKeys.signatureLabel | vtranslate : labels.wafSignatureGroupLabel : labels.wafSignatureRuleLabel }}
        </label>
        <div class="vs-log-cinematic-section-security-waf__expander-list">
            <expander
                *ngFor="let item of wafRuleLogsByGroup$ | async | keyvalue; trackBy: trackByIndex"
                [expanded]="true"
                openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                class="vs-log-cinematic-section-security-waf__expander vs-log-cinematic-section-security-waf__expander--group"
            >
                <opener>
                    <cds-icon
                        class="vs-log-cinematic-section-security-waf__icon--layers"
                        shape="layers"
                        size="16"
                    ></cds-icon>
                    <span
                        *ngIf="item.key | wafRuleGroupNameFilter; else na"
                        vsLogPartialSelectionFilter
                        property="waf_log.rule_logs.rule_group"
                        [value]="item.key | wafRuleGroupNameFilter"
                        position="right"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    ></span>
                    <ng-container *permissionCheck="'PERMISSION_WAFPOLICY:WRITE_ACCESS'">
                        <cds-icon
                            *ngIf="wafPolicyRef && hasGroupName(item.key)"
                            class="vs-log-cinematic-section-security-waf__icon--plus"
                            shape="plus-circle"
                            size="16"
                            (click)="showWafExceptionModal($event, item.value, item.key)"
                        ></cds-icon>
                    </ng-container>
                </opener>

                <content>
                    <expander
                        *ngFor="let log of item.value; trackBy: trackByIndex"
                        [expanded]="true"
                        openerClassName="vs-log-cinematic-section-security-waf__expander-header"
                        class="vs-log-cinematic-section-security-waf__expander"
                    >
                        <opener>
                            <div class="vs-log-cinematic-section-security-waf__child-l1">
                                <cds-icon
                                    class="vs-log-cinematic-section-security-waf__icon--file"
                                    shape="file"
                                    size="16"
                                ></cds-icon>
                                <span
                                    *ngIf="log.rule_id as ruleId"
                                    vsLogFilter
                                    class="vs-log-cinematic-section-security-waf__ruleid"
                                    property="waf_log.rule_logs.rule_id"
                                    [value]="ruleId"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                >
                                    {{ ruleId }}
                                </span>
                                <span
                                    *ngIf="log.rule_name as ruleName; else na"
                                    vsLogPartialSelectionFilter
                                    property="waf_log.rule_logs.rule_name"
                                    [value]="ruleName"
                                    position="right"
                                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                ></span>
                                <ng-container *permissionCheck="'PERMISSION_WAFPOLICY:WRITE_ACCESS'">
                                    <cds-icon
                                        *ngIf="wafPolicyRef && hasGroupName(item.key)"
                                        class="vs-log-cinematic-section-security-waf__icon--plus"
                                        shape="plus-circle"
                                        size="16"
                                        (click)="showWafExceptionModal($event, [log], log.rule_group, log.rule_id)"
                                    ></cds-icon>
                                </ng-container>
                            </div>
                        </opener>

                        <content>
                            <div class="vs-log-cinematic-section-security-waf__content">
                                <div class="vs-log-cinematic-section-security-waf__child-l1">
                                    <span
                                        vsLogCinematicFieldTitle
                                        [withMarginTop]="false"
                                    >
                                        {{ globalL10nKeys.messageLabel | vtranslate }}
                                    </span>

                                        <span
                                            vsLogPartialSelectionFilter
                                            property="waf_log.rule_logs.msg"
                                            [value]="message"
                                            position="right"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            *ngIf="log.msg as message; else na"
                                        ></span>

                                    <span vsLogCinematicFieldTitle>
                                        {{ globalL10nKeys.matchLabel | vtranslate }}
                                    </span>

                                    <avi-data-grid
                                        class="vs-log-cinematic-section-security-waf__no-margin-top"
                                        [config]="wafRuleLogMatchConfig"
                                        [rows]="log.matches"
                                    ></avi-data-grid>

                                    <span vsLogCinematicFieldTitle>
                                        {{ l10nKeys.phaseLabel | vtranslate }}
                                    </span>

                                    <span
                                        vsLogPartialSelectionFilter
                                        *ngIf="log.phase as phase; else na"
                                        property="waf_log.rule_logs.phase"
                                        [value]="phase"
                                        position="right"
                                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                    ></span>

                                    <span vsLogCinematicFieldTitle>
                                        {{ l10nKeys.tagsLabel | vtranslate }}
                                    </span>

                                    <ng-container *ngIf="log.tags as tags; else na">
                                        <span
                                            vsLogFilter
                                            property="waf_log.rule_logs.tags"
                                            [value]="tag"
                                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                                            class="label label-info"
                                            *ngFor="let tag of tags; trackBy: trackByIndex"
                                        >
                                            {{ tag }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </content>
                    </expander>
                </content>
            </expander>
        </div>
    </div>
</div>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template
    #wafRuleLogMatchArgumentFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogPartialSelectionFilter
        property="waf_log.rule_logs.matches.match_element"
        [value]="matchElement"
        position="right"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.match_element as matchElement; else na"
    ></span>
</ng-template>

<ng-template
    #wafPsmLogMatchArgumentFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogPartialSelectionFilter
        property="waf_log.psm_logs.matches.match_element"
        [value]="matchElement"
        position="right"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.match_element as matchElement; else na"
    ></span>
</ng-template>

<ng-template
    #wafRuleLogMatchValueFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogPartialSelectionFilter
        property="waf_log.rule_logs.matches.match_value"
        [value]="matchValue"
        position="right"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.match_value as matchValue; else na"
    ></span>
</ng-template>

<ng-template
    #wafPsmLogMatchValueFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogPartialSelectionFilter
        property="waf_log.psm_logs.matches.match_value"
        [value]="matchValue"
        position="right"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.match_value as matchValue; else na"
    ></span>
</ng-template>
