<avi-confirmation
    class="clr-wrapper"
    headerText="{{ l10nKeys.cloneWafPolicyDialogHeader | vtranslate }}"
    (onClose)="handleClose()"
> 
    <avi-confirmation_modal-content>
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
        >
            <avi-alert-group
                *ngIf="error"
                alertType="danger"
            >
                {{ error }}
            </avi-alert-group>
            <div class="waf-policy-clone-dialog__form-container">
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="waf-clone-name"
                    >
                        {{ l10nKeys.wafPolicyNameLabel | vtranslate }}
                    </label>
                    <input
                        type="text"
                        clrInput
                        placeholder="{{ l10nKeys.wafPolicyNameLabel | vtranslate }}"
                        [(ngModel)]="wafPolicyCloneName"
                        id="waf-clone-name"
                        name="waf-clone-name"
                    />
                </avi-input-container>
            </div>
        </form>
    </avi-confirmation_modal-content>
    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button
            (click)="handleClose()"
            action="outline"
        >
            {{ l10nKeys.closeButtonLabel | vtranslate }}
        </cds-button>
        <cds-button
            (click)="cloneWafPolicy()"
            [loadingState]="loadingState"
            [disabled]="form.invalid"
        >
            {{ l10nKeys.cloneButtonLabel | vtranslate }}
        </cds-button>
    </ng-container>
</avi-confirmation>
