/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    Input,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { configFieldInputValidator }
    from 'ng/modules/avi-forms/validators/config-field-input-validation';

/**
 * @description
 *      This directive validates min, max, and step attributes of the input field.
 *      This directive uses configFieldInputValidator which ConfigFieldInputValidationDirective
 *      uses, differnce is that it takes min, max and step value from template intead of taking from
 *      protobuf.
 *      Invalidates the field when the value is out of the boundaries (min-max).
 * @author Suraj Kumar
 */
@Directive({
    providers: [
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: NumericFieldInputValidationDirective,
        },
    ],
    selector: '[numericFieldInputValidation]',
})
export class NumericFieldInputValidationDirective implements Validator {
    /**
     * Min value of the field - Used to validate field value.
     */
    @Input() private minValue: number;

    /**
     * Max value of the field - Used to validate field value.
     */
    @Input() private maxValue: number;

    /**
     * Step value of the field - Used to validate field value.
     */
    @Input() private stepValue: number;

    /**
     * @override
     * Checks whether the input value is within min-max boundary.
     *
     * Returns null if its a valid value, returns validationError if
     * input is invalid.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return control.value ?
            configFieldInputValidator(
                this.minValue,
                this.maxValue,
                this.stepValue,
            )(control) :
            null;
    }
}
