/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tap, withLatestFrom } from 'rxjs/operators';
import { DevLoggerService } from 'dev-logger-service';
import { VsLogsApiRequestService } from '../../services/vs-logs-api-request.service';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';
import {
    FilterOperatorType,
    IVsLogListResponseData,
    TFilterObj,
    TVsLog,
    TVsLogListStateParams,
    TVsLogStateParams,
} from '../../vs-logs.types';
import { VsLogsStore } from '../../services/vs-logs.store';
import { convertLogsFilterObjectToQueryString } from '../../utils/vs-logs-filters.utils';

const VS_LOG_LIST_REQUEST_ID = 'VS_LOG_LIST_REQUEST';

/**
 * @param hasError - Error state from API.
 * @param vsLogs - List of logs returned by API.
 */
interface IStateTypes {
    hasError: boolean;
    vsLogs: TVsLog[];
}

const initialState: IStateTypes = {
    hasError: false,
    vsLogs: [],
};

/**
 * @description State and Effect management for VsLogListComponent.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Injectable()
export class VsLogListStore extends ComponentStore<IStateTypes> implements OnDestroy {
    // ********************************** Selectors **********************************

    public readonly hasError$ = this.select(({ hasError }) => hasError);

    public readonly vsLogList$ = this.select(({ vsLogs }) => vsLogs);

    // ********************************** Effects **********************************

    /**
     * Call logs API for log list data until all data is returned or timeout.
     * Upon each API return, it updates the state and makes a new request if needed.
     */
    public readonly getVsLogList: (apiParams: TVsLogListStateParams) => void;

    /**
     * Refresh list when under a custom timeframe.
     */
    public readonly refreshList: () => void;

    /**
     * Download one or more logs by log_id.
     */
    public readonly downloadLogs = this.effect<number[]>(
        logIds$ => logIds$.pipe(
            withLatestFrom(this.vsLogsStore.vsLogApiParams$),
            tap(([logIds, apiParams]: [number[], TVsLogStateParams]) => {
                const filter: TFilterObj = {
                    property: 'log_id',
                    operator: FilterOperatorType.EQUAL_TO,
                    value: logIds,
                };

                const queryString = convertLogsFilterObjectToQueryString(filter);

                const newApiParams: TVsLogStateParams = {
                    ...apiParams,
                    filters: [queryString],
                };

                VsLogsApiRequestService.downloadLogs(newApiParams);
            }),
        ),
    );

    /**
     * Download all logs on the current page.
     */
    public readonly downloadCurrentPage = this.effect(
        VsLogsEffectsService.createSelectorTapEffect<TVsLogListStateParams>(
            this.vsLogsStore.vsLogListApiParams$,
            VsLogsApiRequestService.downloadLogs,
        ),
    );

    /**
     * Download the logs selected by the user in the table.
     */
    public readonly downloadSelectedLogs = this.effect(
        VsLogsEffectsService.createSelectorTapEffect<number[]>(
            this.vsLogsStore.selectedLogs$,
            this.downloadLogs,
        ),
    );

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        vsLogsEffectsService: VsLogsEffectsService,
        private readonly vsLogApiRequestService: VsLogsApiRequestService,
        private readonly devLoggerService: DevLoggerService,
    ) {
        super(initialState);

        // Initialize request and refresh effects
        const requestVsLogListEffect = vsLogsEffectsService.createVsLogsRecursiveRequestEffect(
            this,
            this.startLoading,
            this.stopLoading,
            this.handleApiData,
            this.handleApiError,
            this.handleApiLoopComplete,
        );

        this.getVsLogList = (apiParams: TVsLogStateParams): void => {
            requestVsLogListEffect({
                apiParams,
                config: {
                    requestId: VS_LOG_LIST_REQUEST_ID,
                },
            });
        };

        this.refreshList = this.effect(
            VsLogsEffectsService.createRefreshEffect<TVsLogListStateParams>(
                vsLogsStore.vsLogListApiParams$,
                this.getVsLogList,
            ),
        );
    }

    /** @override */
    public ngOnDestroy(): void {
        this.vsLogApiRequestService.cancelRequest(VS_LOG_LIST_REQUEST_ID);
    }

    // ********************************** Updaters **********************************

    /**
     * Update the state with the logs data.
     */
    private readonly setVsLogs = (logList: TVsLog[]): void => {
        this.patchState({ vsLogs: logList });
    };

    // ********************************** Methods **********************************

    /**
     * Clear error and timeout states and set loading states.
     */
    private startLoading = (): void => {
        this.setErrorState(false);
        this.vsLogsStore.setIsTimedOut(false);
        this.vsLogsStore.setisErrorBannerEnabled(true);
        this.vsLogsStore.setisTimeoutBannerEnabled(true);
        this.vsLogsStore.setTableIsLoading(true);
        this.vsLogsStore.setIsTableApiLoopInProgress(true);
    };

    /**
     * Set isLoading to false.
     */
    private stopLoading = (): void => {
        this.vsLogsStore.setTableIsLoading(false);
    };

    /**
     * Update state with logs once data is returned from API.
     */
    private handleApiData = (data: IVsLogListResponseData): void => {
        this.setVsLogs(data.results);
        this.vsLogsStore.setTotalLogsCount(data.count);
    };

    /**
     * Handle API errors.
     */
    private handleApiError = (err: any): void => {
        this.setVsLogs([]);
        this.devLoggerService.error(err);
        this.vsLogsStore.setTotalLogsCount(0);
        this.setErrorState(true);
        this.stopLoading();
        this.vsLogsStore.setIsTableApiLoopInProgress(false);
    };

    /**
     * Set API loop state upon loop completion.
     */
    private handleApiLoopComplete = (isTimedOut: boolean): void => {
        this.vsLogsStore.setIsTableApiLoopInProgress(false);

        if (isTimedOut) {
            this.vsLogsStore.setIsTimedOut(true);
        }
    };

    /**
     * Set error state on local component store and module global store
     * (for component-level and page-level error messages).
     */
    private setErrorState = (hasError: boolean): void => {
        this.vsLogsStore.setHasError(hasError);
        this.patchState({ hasError });
    };
}
