<div class="avi-dropdown clr-wrapper">
    <div
        #aviDropdown
        [ngClass]="getContainerClassNames()"
        avi-tooltip
        (openedChange)="handleOpenedChange($event)"
        [showOnClick]="true"
        [positionsPriority]="optionsPositionsPriority"
        [tooltipControl$]="dropdownOptionsControl$"
        [overlaySizeChange$]="optionsLength$"
        [hideTooltip]="readonly || disabled || reachedMaxSelected()"
        [overlayMaxWidth]="elementWidth"
        tabindex="0"
        [attr.disabled]="disabled"
        [hidden]="viewMode"
    >
        <div class="avi-dropdown__opener sel-avi-dropdown__opener">
            <div
                [hidden]="!value || !hasTranscludedSelectedValuesContainer"
                class="avi-dropdown__selected-values-container"
                #selectedValuesContainerContent
            >
                <ng-content select="[aviDropdown_selectedValuesContainer]"></ng-content>
            </div>
            <avi-dropdown-selected-values-container
                *ngIf="!hasTranscludedSelectedValuesContainer"
                [hidden]="!value"
                class="avi-dropdown__selected-values-container"
                [selectedOptions]="selectedDropdownOptions"
                [multiple]="multiple"
                [prepend]="prepend"
                (onRemoveValue)="handleRemoveValue($event)"
                [disabled]="disabled"
            ></avi-dropdown-selected-values-container>
            <div
                class="avi-dropdown__placeholder"
                [hidden]="value"
            >
                {{ placeholder }}
            </div>
            <div
                [hidden]="readonly"
                class="avi-dropdown__opener-icons"
            >
                <cds-icon
                    [hidden]="!value || disabled || required"
                    class="avi-dropdown__opener-icon avi-dropdown__opener-icon--times-circle"
                    shape="times-circle"
                    size="18"
                    (click)="handleClearAll($event)"
                    title="{{ l10nKeys.clearAllLabel | vtranslate }}"
                ></cds-icon>
                <cds-icon
                    class="avi-dropdown__opener-icon"
                    [ngClass]="{ 'avi-dropdown__opener-icon--unclickable': reachedMaxSelected() }"
                    shape="angle"
                    direction="down"
                    size="14"
                ></cds-icon>
            </div>
        </div>
        <ng-template #aviTooltipContent>
            <avi-dropdown-menu
                [hideSearch]="hideSearch"
                searchTerm="{{ searchTerm }}"
                (onSearch)="handleSearch($event)"
                (onDestroy)="clearSearch($event)"
            >
                <avi-dropdown-options
                    [options]="availableDropdownOptions"
                    [selectedOptions]="selectedDropdownOptions"
                    (onOptionSelect)="handleSelect($event)"
                    [width]="elementWidth"
                    [height]="optionsHeight"
                    (onScrollEnd)="handleScrollEnd($event)"
                    [busy]="busy"
                ></avi-dropdown-options>
            </avi-dropdown-menu>
        </ng-template>
    </div>
    <div
        [hidden]="!viewMode"
        class="avi-dropdown__view-mode-container"
    >
        <div
            *ngFor="let selectedOption of selectedDropdownOptions; trackBy: trackByValue"
            class="avi-dropdown__view-mode-option"
        >
            {{ selectedOption.label }}
        </div>
    </div>
    <div
        class="avi-dropdown__helper-text"
        [hidden]="!helperText"
    >
        {{ helperText }}
    </div>
</div>
