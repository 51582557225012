/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Parse the rule string for a particular field.
 */
export function getRuleField(rule: string, field: string): string {
    // The field of the rule is contained within a huge string, ie:
    // "....ection',    id:981261,    rev:'1'....". We have to parse this string to get
    // the specified field.
    const regExp = new RegExp(`${field}:('[^,]+?')`);
    const matches = regExp.exec(rule);

    return Array.isArray(matches) && matches[1].replace(/'/g, '') || '';
}
