<div
    class="tri-switch"
    [ngClass]="classNames"
    (click)="handleClick($event)"
>
    <div
        [hidden]="state !== 2"
        class="tri-switch__indeterminate"
    ></div>
    <div class="tri-switch__circle"></div>
</div>
