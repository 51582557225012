/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { WafPSMLocation } from 'object-types';
import { IWafPSMLocation } from 'generated-types';
import * as l10n from 'ajs/modules/waf/waf.l10n';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { WafPSMLocationMatchConfigItem } from './waf-psm-location-match.config-item.factory';
import { WafPSMRuleConfigItem } from './waf-psm-rule.config-item.factory';
import { WafPSMMatchElementConfigItem } from './waf-psm-match-element.config-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Custom Waf PSM Location Type.
 */
type TIWafPSMLocationPartial = Omit<IWafPSMLocation, 'match' | 'rules'>;

/**
 * Interface for PSM Location.
 */
interface IWafPSMLocationConfigItem extends TIWafPSMLocationPartial {
    match: WafPSMLocationMatchConfigItem;
    rules: RepeatedMessageItem<WafPSMRuleConfigItem>;
}

/**
 * @description WAF Policy PSM Location Class.
 *
 * @author Alex Tseung, Nitesh Kesarkar
 */
export class WafPSMLocationConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IWafPSMLocationConfigItem, typeof MessageItem>(MessageItem),
)<IWafPSMLocationConfigItem> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpsmlocation',
            objectType: WafPSMLocation,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IWafPSMLocationConfigItem> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        if (!this.config.match) {
            this.setNewChildByField('match');
        }
    }

    /**
     * Returns the name of the location.
     */
    public get name(): string {
        return this.getName();
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.config.match.matchCount;
    }

    /**
     * Returns the rules from config item.
     */
    public get rules(): RepeatedMessageItem<WafPSMRuleConfigItem> {
        return this.config.rules;
    }

    /**
     * Returns the number of rules in the config.
     */
    public get rulesCount(): number {
        return this.config.rules.count;
    }

    /**
     * Creates a new rule and opens the modal to edit it.
     */
    public addRule(preventEdit: boolean): void {
        this.addChildMessageItem({
            field: 'rules',
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Edits a selected rule and opens the modal to edit it.
     */
    public editRule(rule: WafPSMRuleConfigItem, preventEdit: boolean): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Deletes a selected rule.
     */
    public deleteRule(rule: WafPSMRuleConfigItem): void {
        this.rules.removeByMessageItem(rule);
    }

    /**
     * Returns the comma seperated sub element names.
     */
    public getMatchSubElementNames(): string {
        return this.rules.config
            .map((rule: WafPSMRuleConfigItem) => {
                return rule.config.match_elements.config
                    .map((matchElement: WafPSMMatchElementConfigItem) => {
                        return matchElement.config.sub_element;
                    });
            }).join(',') || '-';
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyPsmLocationModalComponent } = await import(
            /* webpackChunkName: "waf-policy-psm-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-psm-group-modal/waf-policy-psm-location-modal/waf-policy-psm-location-modal.component'
        );

        return WafPolicyPsmLocationModalComponent as Type<Component>;
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WAF Policy PSG Location Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafPsmLocationModalBreadcrumbTitle);
    }
}

