<div
    class="cinematic clr-wrapper"
    [ngClass]="viewClassName"
    tabindex="-1"
>
    <cinematic-header
        class="cinematic__header"
        headerText="{{ viewTitle }}"
        (onClose)="handleClose()"
    >
        <cinematic-header_custom-header-section>
            <ng-content select="cinematic_custom-header"></ng-content>
        </cinematic-header_custom-header-section>
        <cinematic-header-tabs
            class="cinematic__full-modal-config-header-tabs"
            [tabs]="tabs"
            activeTabId="{{ activeTabId }}"
            [deactivateTabs]="deactivateTabs"
            (onSelect)="handleSelectTab($event)"
        ></cinematic-header-tabs>
    </cinematic-header>
    <div class="cinematic__body">
        <div
            class="cinematic__body-content"
            [ngClass]="tabs.length && 'cinematic__body-content--with-tabs'"
        >
            <div class="cinematic__errors">
                <ng-content select="cinematic_errors"></ng-content>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
