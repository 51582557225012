/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    OnInit,
} from '@angular/core';

import { Auth } from 'ajs/modules/core/services/auth/auth.service';

import * as globalL10n from 'global-l10n';
import './traffic-capture-preview.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Preview component for Traffic capture Modal. Displays Tenant name.
 *
 * @author  Suraj Kumar
 */
@Component({
    selector: 'traffic-capture-preview',
    templateUrl: './traffic-capture-preview.component.html',
})
export class TrafficCapturePreviewComponent implements OnInit {
    /**
     * Tenant name.
     */
    public tenantName: string;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly authService: Auth) {}

    /** @override */
    public ngOnInit(): void {
        this.tenantName = this.authService.getTenantName();
    }
}
