/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function vmNicIPFactory(
    Item,
) {
    /**
     * @constructor
     * @extends module:avi/dataModel.Item
     * @memberOf module:avi/network
     * @description
     *     Item for VMs IP address with network info.
     * @author Alex Malitsky, chitra
     * @see {@link module:avi/network.VMNicIPCollection}
     * @see {@link module:avi/network.VMNicIPDataTransformer}
     */
    class VMNicIP extends Item {
        /** @override */
        getIdFromData_(data) {
            if (!data || !data.config) {
                return '';
            }

            const { config } = data;

            return [
                config.uuid,
                config.ip && config.ip.addr,
            ].join('-');
        }

        /** @override */
        isDroppable() {
            return false;
        }
    }

    /**
     * The actual endpoint is /api/cloud/cloudId/serversbynetwork.
     * It will be overwritten in its respective DataTransformer.
     * @type {string}
     */
    VMNicIP.prototype.objectName = 'serversbynetwork';

    return VMNicIP;
}

vmNicIPFactory.$inject = [
    'Item',
];

angular.module('avi/network')
    .factory('VMNicIP', vmNicIPFactory);
