/** @module AccountsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { Component, Type } from '@angular/core';
import { RoleFilter } from 'object-types';

import {
    IRoleFilter,
    IRoleFilterMatchLabel,
} from 'generated-types';

import {
    RoleFilterModalComponent,
} from 'ng/modules/accounts/components/role-modal/role-filter-modal/role-filter-modal.component';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './role.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TRoleFilterPartial = Omit<IRoleFilter, 'match_label'>;

interface IRoleFilterConfig extends TRoleFilterPartial {
    match_label: MessageItem<IRoleFilterMatchLabel>,
}

/**
 * @description
 *
 *   Role Filter Message Item.
 *
 * @author Nisar Nadaf
 */
export class RoleFilterConfigItem extends withFullModalMixin(MessageItem)<IRoleFilterConfig> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: RoleFilter,
            windowElement: RoleFilterModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for values from match label.
     */
    public get values(): string[] {
        const { match_label: matchLabel } = this.config;

        return matchLabel.config.values || [];
    }

    /**
     * Getter for key from match label.
     */
    public get key(): string {
        const { match_label: matchLabel } = this.config;

        return matchLabel.config.key;
    }

    /**
     * Getter for count of values.
     */
    public get valuesCount(): number {
        return this.values.length;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.addRoleFilterModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'match_label',
        ];
    }
}
