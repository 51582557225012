<div class="vs-log-cinematic-section-connection">
    <div class="cinematic__form-control-row">
        <avi-data-grid
            class="cinematic__form-control"
            [config]="packetsGridConfig"
            [rows]="hostTypeConnectionLog[l4ConnectionType] | async | logHostConnectionGridRows : l4ConnectionInfoType.PACKETS : l4ConnectionType"
        ></avi-data-grid>
        <cds-divider
            orientation="vertical"
            class="vs-log-cinematic-section-connection__divider"
        ></cds-divider>
        <avi-data-grid
            class="cinematic__form-control"
            [config]="transfersGridConfig"
            [rows]="hostTypeConnectionLog[l4ConnectionType] | async | logHostConnectionGridRows : l4ConnectionInfoType.TRANSFERS : l4ConnectionType"
        ></avi-data-grid>
        <cds-divider
            orientation="vertical"
            class="vs-log-cinematic-section-connection__divider"
        ></cds-divider>
        <avi-data-grid
            class="cinematic__form-control"
            [config]="connectionIssuesGridConfig"
            [rows]="hostTypeConnectionLog[l4ConnectionType] | async | logHostConnectionGridRows : l4ConnectionInfoType.CONNECTION_ISSUES : l4ConnectionType"
        ></avi-data-grid>
    </div>
</div>


<ng-template
    #rowFieldTemplateRef
    let-row
    let-index="index"
>
    <div class="cinematic__form-control-row vs-log-cinematic-section-connection__no-margin-top">
        <label
            vsLogCinematicFieldTitle
            [withMarginTop]="false"
            class="cinematic__form-control vs-log-cinematic-section-connection__no-padding-bottom"
        >
            {{ row.label }}
        </label>
        <span
            vsLogFilterList
            [property]="row.property"
            [value]="row.value"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            class="cinematic__form-control"
            *ngIf="row.value !== undefined; else na"
        >
            {{ row.displayValue }}
        </span>
    </div>
</ng-template>

<ng-template #na>
    <span
        class="cinematic__form-control"
        vsLogEmptyField
    ></span>
</ng-template>
