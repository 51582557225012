/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserPreferencesEffects } from './user-preferences.effects';
import { featureKey } from './user-preferences.selectors';
import { userPreferencesReducer } from './user-preferences.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(featureKey, userPreferencesReducer),
        EffectsModule.forFeature([UserPreferencesEffects]),
    ],
})
export class UserPreferencesModule {}
