<vs-log-cinematic-sub-section-wrapper
    *ngIf="httpRequestHeaders$ | async as requestHeaders"
    header="{{ l10nKeys.allRequestLabel | vtranslate }}"
>
    <avi-diff-grid
        [config]="requestDiffGridConfig"
        [rows]="requestHeaders | logHttpHeadersDiffSourcePair : 'request' | aviDiffGridRows"
    ></avi-diff-grid>
</vs-log-cinematic-sub-section-wrapper>

<vs-log-cinematic-sub-section-wrapper
    *ngIf="httpResponseHeaders$ | async as responseHeaders"
    header="{{ l10nKeys.allResponseLabel | vtranslate }}"
>
    <avi-diff-grid
        [config]="responseDiffGridConfig"
        [rows]="responseHeaders | logHttpHeadersDiffSourcePair : 'response' | aviDiffGridRows"
    ></avi-diff-grid>
</vs-log-cinematic-sub-section-wrapper>
