/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-mapping-rule';
const prefix = `${moduleName}.${componentName}`;

export const botMappingRuleLabel = `${prefix}.botMappingRuleLabel`;

export const ENGLISH = {
    [botMappingRuleLabel]: 'Bot Mapping Rule',
};
