/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import {
    Component,
    Injectable,
    OnDestroy,
    Type,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { ObservableList } from 'ng/shared/utils/observable-list.utils';

import {
    AviToastComponent,
} from 'ng/modules/notification/components/avi-toast/avi-toast.component';
import { AviNotificationType } from 'ng/modules/notification/avi-notification.types';

type TNotificationId = string;
type TTimer = ReturnType<typeof timer>;

export interface IAviNotificationProps {
    id: TNotificationId;
    component: Type<Component>;
    componentProps?: Record<string, any>;
}

/** A notification disappears six seconds after showing */
const NOTIFICATION_TIMEOUT = 6000;

/**
 * @description
 *     Service for adding and removing notifications. The life of a notification should end by
 *     itself after a certain timeout or by user clicking close.
 *     This service supports opening
 *         1. a default avi-toast component by calling "addSimpleNotification" method with only
 *            necessary info, ie. header and content.
 *         2. a more complex component (usually an avi-toast component with actions configured, or a
 *            custom component) by calling "add" method with elaborate bindings
 * @author alextsg, Zhiqian Liu
 */
@Injectable({
    providedIn: 'root',
})
export class NotificationService extends
    ObservableList<IAviNotificationProps, TNotificationId> implements OnDestroy {
    /**
     * A map to store timers of all active notifications with their ID and the corresponding
     * [timer, subscription] pair.
     * A centralized scheduler is needed because it's impractical to leave the timeout closing job
     * to the notification component itself, as the adding and removing are to be done by this
     * service, and it's extra effort for the dev to manually set timeout.
     */
    protected readonly notificationTimers = new Map<TNotificationId, [TTimer, Subscription]>();

    /**
     * Number of simple notifiactions (avi-toasts with minimal info).
     */
    protected simpleNotificationCount = 0;

    /**
     * @override
     * Add a notification item to the list and update the scheduler to ensure prompt dismiss.
     */
    public add(item: IAviNotificationProps): void {
        super.add(item);

        const id = this.getID(item);

        // using "timer" observable here to avoid making this "add" function async by directly
        // calling setTimeout()
        this.notificationTimers.set(id, [timer(NOTIFICATION_TIMEOUT), null]);
        this.notificationTimers.get(id)[1] = this.notificationTimers.get(id)[0]
            .subscribe(() => this.remove(id));
    }

    /**
     * Add a notification with minimal configuration. It opens up an avi-toast with only string
     * header and content. Calling this method doesn't require user to specify a custom component
     * class and binding props.
     */
    public addSimpleNotification(
        header = '',
        content = '',
        type: AviNotificationType = AviNotificationType.SUCCESS,
    ): void {
        this.simpleNotificationCount++;

        const simpleNotificationProps: IAviNotificationProps = {
            id: `simple-notification-${this.simpleNotificationCount}`,
            component: AviToastComponent as Type<Component>,
            componentProps: {
                type,
                header,
                content,
            },
        };

        this.add(simpleNotificationProps);
    }

    /**
     * @override
     * Remove a notification from the list and remove its timer.
     */
    public remove(id: TNotificationId): void {
        super.remove(id);

        this.removeTimer(id);
    }

    /**
     * @override
     * Destroy all the active notifications with their timers and subscriptions of timers.
     */
    public ngOnDestroy(): void {
        this.notificationTimers.forEach((value, id) => {
            this.remove(id);
        });
    }

    /**
     * Remove timer for a notification from the scheduler after manually unsubscribing.
     */
    protected removeTimer(id: TNotificationId): void {
        if (!this.notificationTimers.get(id)) {
            throw new Error(`Notification timer with ID ${id} not found`);
        }

        // the timer can be removed before it emits (when user closes the notification), so manual
        // unsubscription is needed before removing the timer observable to avoid memory leak
        this.notificationTimers.get(id)[1].unsubscribe();
        this.notificationTimers.delete(id);
    }

    protected getID(item: IAviNotificationProps): string {
        return item.id;
    }
}
