<div class="avi-bar-graph">
    <!--  Actual Graph  -->
    <div
        class="avi-bar-graph__viewport"
        [hidden]="isLoading || hasError"
    ></div>

    <!--  Data Loading... || Error State  -->
    <div
        *ngIf="isLoading || hasError"
        class="avi-bar-graph__alternate-state"
        [ngClass]="{ 'avi-bar-graph__awaiting-data': isLoading, 'avi-bar-graph__err-state': hasError }"
    >
        <clr-spinner
            *ngIf="isLoading"
            clrInline
            [clrMedium]="true"
        ></clr-spinner>

        <div *ngIf="hasError">
            {{ l10nKeys.errLabel | vtranslate }}
        </div>
    </div>

    <!--  Legend  -->
    <div
        class="avi-bar-graph__legend"
        *ngIf="dataset?.length && !isLoading && !hasError"
    >
        <label-color-optional
            *ngFor="let row of labelList"
            [labelData]="row"
            class="avi-bar-graph__legend-label"
        ></label-color-optional>
    </div>

    <!--  Hover Tooltip Placeholder  -->
    <avi-bar-graph-tooltip
        #aviBarGraphTooltipContainerElementRef
        class="avi-bar-graph__tooltip"
        [left]="tooltipLeftPositionPx"
        [containerWidth]="currentGraphContainerWidth"
        [width]="tooltipWidth"
    >
        <ng-template #aviBarGraphTooltipRef></ng-template>
    </avi-bar-graph-tooltip>

    <!--TODO 158220 Delete if keeping avi-gar-graph-tooltip.component-->
    <!--<div-->
    <!--    #aviBarGraphTooltipContainerElementRef-->
    <!--    class="avi-bar-graph__tooltip"-->
    <!--&gt;-->
    <!--    <ng-template #aviBarGraphTooltipRef></ng-template>-->
    <!--</div>-->
</div>
