/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    RecommendationDialogService,
} from 'ng/modules/logs/services/recommendation-dialog.service';

import * as globalL10n from 'global-l10n';
import * as l10n from './vs-log-cinematic.l10n';

import './vs-log-cinematic.component.less';

import { VsLogsStore } from '../../services';
import { VsLogCinematicStore } from './vs-log-cinematic.store';
import { VsAppProfileType } from '../../vs-logs.types';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const enum L4ConnectionType {
    SERVER = 'SERVER',
    CLIENT = 'CLIENT',
}

/**
 * @description
 *     Component used for showing a single entry of VS log.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic',
    templateUrl: 'vs-log-cinematic.component.html',
    providers: [VsLogCinematicStore],
})
export class VsLogCinematicComponent implements OnInit, OnDestroy {
    /**
     * Close the dialog.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Log ID and service engine ID together uniquely identify a log for the given VS.
     */
    @Input()
    public readonly logId: number;

    @Input()
    public readonly seId: string;

    public readonly L4ConnectionType = {
        [L4ConnectionType.SERVER]: L4ConnectionType.SERVER,
        [L4ConnectionType.CLIENT]: L4ConnectionType.CLIENT,
    };

    /**
     * Indicator of whether log data is being fetched.
     */
    public readonly loading$ = this.vsLogCinematicStore.isLoading$;

    /**
     * Errors returned from API.
     */
    public readonly errors$ = this.vsLogCinematicStore.errors$;

    public readonly hasError$ = this.vsLogCinematicStore.hasError$;

    public readonly VsAppProfileType = VsAppProfileType;

    /**
     * Indicator of whether the data is being loaded or errors have occured during the loading by
     * turning two respective observables into one.
     */
    public readonly busy$ = combineLatest([this.loading$, this.errors$])
        .pipe(map(([loading, errors]) => loading || Boolean(errors)));

    public readonly globalL10nKeys = globalL10nKeys;
    public readonly l10nKeys = l10nKeys;

    public readonly vsName$ = this.vsLogsStore.vsName$;
    public readonly vsAppProfileType$ = this.vsLogsStore.vsAppProfileType$;
    public readonly timestamp$ = this.vsLogCinematicStore.timestamp$;
    public readonly isSignificantLog$ = this.vsLogCinematicStore.isSignificantLog$;

    public readonly significance$ = this.vsLogCinematicStore.significance$;

    public readonly wafLog$ = this.vsLogCinematicStore.wafLog$;

    public readonly wafStatus$ = this.vsLogCinematicStore.wafStatus$;

    public readonly ssl$ = this.vsLogCinematicStore.ssl$;

    public readonly applicationInfo$ = this.vsLogCinematicStore.applicationInfo$;

    public readonly grpcInfo$ = this.vsLogCinematicStore.grpcInfo$;

    public readonly httpRequestHeaders$ = this.vsLogCinematicStore.httpRequestHeaders$;

    public readonly httpResponseHeaders$ = this.vsLogCinematicStore.httpResponseHeaders$;

    public readonly hasAutenticationLogs$ = this.vsLogCinematicStore.hasAuthenticationLogs$;

    public readonly icapLog$ = this.vsLogCinematicStore.icapLog$;

    public readonly botManagementLog$ = this.vsLogCinematicStore.botManagementLog$;

    public readonly datascriptInfo$ = this.vsLogCinematicStore.datascriptInfo$;

    public readonly oobDsRequestLogs$ = this.vsLogCinematicStore.oobDsRequestLogs$;

    public readonly dnsRequestInfo$ = this.vsLogCinematicStore.dnsRequestInfo$;

    public readonly dnsResponseInfo$ = this.vsLogCinematicStore.dnsResponseInfo$;

    public readonly l4ClientConnectionInfo$ = this.vsLogCinematicStore.l4ClientConnectionInfo$;

    public readonly l4ServerConnectionInfo$ = this.vsLogCinematicStore.l4ServerConnectionInfo$;

    constructor(
        l10nService: L10nService,
        private readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly vsLogsStore: VsLogsStore,
        private readonly recommendationDialogService: RecommendationDialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        vsLogCinematicStore.closeCinematic$.subscribe(() => this.handleClose());
    }

    /** @override */
    public ngOnInit(): void {
        this.loadLog();
    }

    public loadLog(): void {
        const { logId, seId } = this;

        this.vsLogCinematicStore.getVsLog({
            logId,
            seId,
        });
    }

    /**
     * Called when a user exits or closes the view.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Download the log entry as a .csv file.
     */
    public downloadLog(): void {
        const { logId, seId } = this;

        this.vsLogsStore.downloadLogEntry({
            logId,
            seId,
        });
    }

    public openRecommendationDialog(): void {
        combineLatest([
            this.vsLogCinematicStore.applicationLog$,
            this.vsLogsStore.vsUuid$,
        ])
            .pipe(take(1))
            .subscribe(
                ([{ report_timestamp: reportTimestamp, request_id: requestId }, vsUuid]) =>
                    this.recommendationDialogService.openDialog(vsUuid, reportTimestamp, requestId),
            );
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.recommendationDialogService.isOpen()) {
            this.recommendationDialogService.closeDialog();
        }
    }
}
