<match-wrapper
    label="{{ l10nKeys.userAccountProfileLabel | vtranslate }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMapping()"
>
    <div
        provideParentForm
        class="user-account-profile-mapping"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="assign_userprofile"
            for="user-account-profile-mapping-type"
            required
        >
            {{ globalL10nKeys.typeLabel | vtranslate }}
        </label>
        <avi-enum-dropdown
            name="user-account-profile-mapping-type"
            enum="AuthRuleAssignment"
            [(ngModel)]="editable.config.assign_userprofile"
            (ngModelChange)="onAssignmentTypeChange()"
            placeholder="{{ l10nKeys.typeDropdownPlaceholder | vtranslate }}"
            [hiddenEnumValues]="hiddenAuthRuleAssignmentValues"
            required
        ></avi-enum-dropdown>

        <div class="user-account-profile-mapping__account-profile">
            <div
                class="full-modal-config__form-control-row"
                *ngIf="showUserProfileList"
            >
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="userprofile_uuid"
                        for="userprofile-uuid"
                    >
                        {{ l10nKeys.userAccountProfileLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        [collection]="userProfileCollection"
                        [(ngModel)]="editable.config.userprofile_ref"
                        placeholder="{{ l10nKeys.userAccountProfilePlaceholderLabel | vtranslate }}"
                        name="userprofile-uuid"
                        id="userprofile-uuid"
                    ></avi-collection-dropdown>
                </div>
            </div>

            <avi-input-container *ngIf="showUserProfileAttributeName">
                <label
                    aviLabelWithTooltip
                    fieldName="userprofile_attribute_name"
                    for="userprofile-attribute-name"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ l10nKeys.userProfileAttributeNameLabel | vtranslate }}
                </label>
                <input
                    name="userprofile-attribute-name"
                    [(ngModel)]="editable.config.userprofile_attribute_name"
                    clrInput
                    placeholder="{{ l10nKeys.userProfileAttributeNamePlaceholderLabel | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>
    </div>
</match-wrapper>
