/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { USER_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

import {
    TUserProfileCollection,
    UserProfileCollection,
} from 'ajs/modules/accounts/factories/user-profile/user-profile.collection.factory';

import {
    UserProfileItem,
} from 'ajs/modules/accounts/factories/user-profile/user-profile.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './user-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description UserProfile list page.
 * @author sghare
 */
@Component({
    selector: 'user-profile-list',
    templateUrl: './user-profile-list.component.html',
})
export class UserProfileListComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display UserProfile collection.
     */
    public userProfileGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the UserProfile items.
     */
    private readonly userProfileCollection: UserProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(USER_PROFILE_COLLECTION_TOKEN)
        UserProfileCollection: TUserProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.userProfileCollection = new UserProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.userProfileCollection;

        this.userProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.userProfileCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: UserProfileItem): string => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.userProfileCollection.destroy();
    }
}
