/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './missing-cert-issuer-icon.l10';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Missing Certificate Component.
 *
 * @author Manideep Kotha
 */

@Component({
    selector: 'missing-cert-issuer-icon',
    templateUrl: './missing-cert-issuer-icon.component.html',
})
export class MissingCertIssuerIcon {
    /**
     * Name of the missing certificate's issuer.
     */
    @Input()
    public missingCertIssuerName: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
