/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

// Todo: change it to factory and create an instance of tooltip and unbind events when destroying
angular.module('aviApp').service('TooltipLight', ['$document', '$window',
function($document, $window) {
    const setPosition = function(element, tooltip) {
        tooltip.css({
            top: $(element).offset().top - $(tooltip).height() - 15,
            left: $(element).offset().left + $(element).width() / 2 - $(tooltip).width() / 2,
        });
    };

    this.create = function(element, text) {
        const tooltip = $(
            `${'<div class="tooltip fade top in" style="display: block;">' +
                '<div class="tooltip-arrow"></div>' +
                '<div class="tooltip-inner">'}${text}</div>` +
            '</div>',
        );

        tooltip.appendTo($document[0].body);
        setPosition(element, tooltip);
        tooltip.hide();
        tooltip.fadeIn();
        $($window).on('resize', function() {
            setPosition(element, tooltip);
        });

        return tooltip;
    };
}]);
