/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-delete';
const prefix = `${moduleName}.${componentName}`;

export const deleteConfirmMessage = `${prefix}.deleteConfirmMessage`;
export const deleteVsOnlyBtnLabel = `${prefix}.deleteVsOnlyBtnLabel`;
export const deleteVsObjectsBtnLabel = `${prefix}.deleteVsObjectsBtnLabel`;

export const ENGLISH = {
    [deleteConfirmMessage]: 'Do you want to keep referenced objects or delete them as well?',
    [deleteVsOnlyBtnLabel]: 'Delete Virtual Service Only',
    [deleteVsObjectsBtnLabel]: 'Delete Virtual Service and referenced objects',
};
