/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module ClusterModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrSpinnerModule } from '@clr/angular';
import { VIPModule } from '@vmw/ngx-vip';
import { AviCardModule } from 'nsx-alb-ui-components';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { ClusterStateInfoComponent, NodesPageComponent } from './components';
import {
    ClusterObjStateIconStatusPipe,
    ClusterStateIconStatusPipe,
    ClusterVipStatusIconStatusPipe,
} from './pipes';

@NgModule({
    declarations: [
        ClusterObjStateIconStatusPipe,
        ClusterStateIconStatusPipe,
        ClusterStateInfoComponent,
        ClusterVipStatusIconStatusPipe,
        NodesPageComponent,
    ],
    imports: [
        AviCardModule,
        ClrSpinnerModule,
        CommonModule,
        DataGridModule,
        SharedModule,
        VIPModule.forChild(),
    ],
    exports: [
        ClusterObjStateIconStatusPipe,
        ClusterStateIconStatusPipe,
        ClusterStateInfoComponent,
        ClusterVipStatusIconStatusPipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ClusterModule {}
