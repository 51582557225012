/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Auth } from 'ajs/modules/core/services/auth';
import { APP_LEVEL_ALERTS_SERVICE_TOKEN } from '../../downgrade-services.tokens';

import * as l10n from './httpInterceptor.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @type {string}
 */
const RELOAD_PERMISSIONS = 'reload-permissions';

/**
 * Constant for unsuccessful http requests, used as id for setting app level alert.
 */
const UNSUCCESSFUL_REQUESTS = 'unsuccessful-requests';

/**
 * Constant for blocked Requests XhrStatus, used for comparing XhrStatus of requests.
 */
const BLOCKED_REQUEST_XHR_STATUS = 'error';

const httpInterceptorFactory = (
    $q,
    $injector,
    $state,
    encodeURIQueryPartService,
    exceptions,
    $window,
) => {
    /**
     * @type {Object}
     */
    const respCode401allowed = {
        login: true,
        'please-reload': true,
        passwordchange: true,
    };

    /**
     * $http injerceptor for Response
     * @param {Object} config
     * @returns {Object}
     */
    const request = config => {
        config.url = encodeURIQueryPartService(config.url);

        return config;
    };

    /**
     * $http injerceptor for successful response.
     * Looks for `reload-permissions` in response headers, if found
     * Reloads permissions.
     * @param {*} rsp
     * @returns {*|ng.$q.promise}
     */
    const response = rsp => {
        const responseHeaders = rsp.headers();

        // as of now this works for 'admin' user only, see AV-70050
        if (RELOAD_PERMISSIONS in responseHeaders) {
            const authService = $injector.get('Auth');

            const reloadPermissionsKey = responseHeaders[RELOAD_PERMISSIONS];

            // Should reload context if true.
            const unaddressedReloadRequest =
                Auth.isNewReloadPermissionsRequest(reloadPermissionsKey);

            if (unaddressedReloadRequest) {
                // we mark key as processed right away so that other HTTP calls
                // won't cause multiple reloadUserProfile calls simultaneously for the same value
                Auth.updateReloadPermissionsRequestHash(reloadPermissionsKey, true);

                authService.reloadUserProfileAndPermissions()
                    .catch(() => {
                        Auth.updateReloadPermissionsRequestHash(reloadPermissionsKey, false);
                    });
            }
        }

        return rsp;
    };

    /**
     * $http interceptor for failed response.
     * @param {*} rsp
     * @returns {Promise}
     */
    const responseError = rsp => {
        const authService = $injector.get('Auth');

        const { config: rspConfig } = rsp;
        const { url } = rspConfig;
        const { status } = rsp;
        const skip = url in exceptions && _.contains(exceptions[url], status);

        if (!skip) {
            switch (status) {
                case 401: {
                    rsp.silent = true;

                    const { name: stateName } = $state.$current;

                    if (!(stateName in respCode401allowed) && authService.isLoggedIn()) {
                        authService.logout(true, true);
                    }

                    break;
                }

                case 503:
                case 520: {
                    const logoutPromise =
                        authService.isLoggedIn() ? authService.logout() : Promise.when();

                    logoutPromise
                        .finally(() => {
                            $state.go('login', {
                                statusCode: status,
                            });
                        });

                    break;
                }

                case -1: {
                    const { xhrStatus } = rsp;

                    if (xhrStatus === BLOCKED_REQUEST_XHR_STATUS) {
                        const appLevelAlertsService = $injector.get(APP_LEVEL_ALERTS_SERVICE_TOKEN);

                        if (!appLevelAlertsService.has(UNSUCCESSFUL_REQUESTS)) {
                            const l10nService = $injector.get('l10nService');

                            l10nService.registerSourceBundles(dictionary);

                            const message = l10nService.getMessage(
                                l10nKeys.unsuccessfulRequestsAlertMessage,
                            );

                            appLevelAlertsService.add({
                                id: UNSUCCESSFUL_REQUESTS,
                                componentProps: {
                                    messages: [message],
                                    status: 'warning',
                                    actionLabel: l10nService.getMessage(
                                        l10nKeys.reloadButtonText,
                                    ),
                                    onClick: () => $window.location.reload(),
                                },
                            });
                        }
                    }
                }
            }
        }

        return $q.reject(rsp);
    };

    return {
        request,
        response,
        responseError,
    };
};

httpInterceptorFactory.$inject = [
    '$q',
    '$injector',
    '$state',
    'encodeURIQueryPartService',
    'responseErrorInterceptorExceptions',
    '$window',
];

/**
 * @name httpInterceptorService
 * @memberOf module:avi/core
 * @description
 *
 *     This service has methods to intercept $http request/response for following purposes:
 *
 *     1) Request interceptor - Escapes URL query part characters
 *
 *     2) Response interceptor - Checks for `reload-permissions` key in Response headers.
 *          if such key is found, gets the value of it to verify whether the
 *          `reload-permissions-request` is addressed,
 *          incase if its not addressed, proceeds to load permissions and user context.
 *
 *     3) ResponseError interceptor - Checks the error status code and take actions accordingly.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
angular.module('avi/core')
    .factory('httpInterceptor', httpInterceptorFactory);
