<div
    provideParentForm
    class="match-adder"
>
    <avi-dropdown-button
        [actions]="addMatchDropdownActions"
        [position]="actionPosition"
        size="sm"
        [disabled]="disableAddButton"
    >
        {{ l10nKeys.addButtonLabel | vtranslate }}
    </avi-dropdown-button>
    <avi-fieldset
        [hidden]="match.hasAnyEntry()"
        [hideContent]="true"
        class="match-adder__fieldset"
    >
        <avi-fieldset_header class="match-adder__header">
            <div class="match-adder__header-text">
                {{ l10nKeys.noEntriesLabel | vtranslate }}
            </div>
        </avi-fieldset_header>
    </avi-fieldset>
    <div class="match-adder__match-list-container">
        <ng-template #matchListContainerRef></ng-template>
    </div>
</div>
