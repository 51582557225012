/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IVsLogsSignpostUpdatableParams } from '../vs-logs.types';

import {
    defaultPageNumber,
    defaultPageSize,
} from './default-values.constants';

/** @module VsLogsModule */

export const defaultSignpostParams: IVsLogsSignpostUpdatableParams = {
    groupby: null,
    page: defaultPageNumber,
    page_size: defaultPageSize,
    orderby: '-count',
};

/**
 * Different phases of waf latency signpost.
 */
export const wafLatencyPhases = [
    'requestHeader',
    'requestBody',
    'responseHeader',
    'responseBody',
];

/**
 * Fields that support range type values.
 */
export const signpostsWithRange = new Set([
    'rx_bytes',
    'tx_bytes',
    'request_length',
    'response_length',
    'e2e-timing-udp',
    'icap_log.request_logs.latency',
]);

/**
 * Different group by keys of e2e timing signpost.
 */
export const e2eTimingGroupByKeys = {
    appResponseTime: 'app_response_time',
    clientRtt: 'client_rtt',
    dataTransferTime: 'data_transfer_time',
    serverRtt: 'server_rtt',
    totalTime: 'total_time',
};

/**
 * Set of sections that require custom template, if not `default` will be used.
 */
export const customTemplates = {
    e2eTiming: 'e2e-timing',
    wafPhaseLatency: 'waf-phase-latency',
    wafRuleGroups: 'waf-rule-groups',
    policy: 'policy',
    significance: 'significance',
    ssl: 'ssl',
    wafRule: 'waf_log.rule_logs.rule_id',
};

export const templatesConfiguration = {
    'waf_log.rule_logs.tags': {
        header: 'WAF Tags',
        name: 'WAF Tags',
        params: 'waf_log.rule_logs.tags',
        queryParams: 'waf_log.rule_logs.tags',
    },
    'waf_log.rule_logs.rule_id': {
        header: 'WAF Rules',
        name: 'WAF Rules',
        params: [
            'waf_log.rule_logs.rule_id',
            'waf_log.rule_logs.rule_name',
        ],
        queryParams: [
            'waf_log.rule_logs.rule_id',
            'waf_log.rule_logs.rule_name',
        ],
    },
    'waf-phase-latency': {
        header: 'WAF Latency',
        name: 'WAF Latency',
        phases: [{
            name: 'Request Header',
            groupby: 'waf_log.latency_request_header_phase',
        }, {
            name: 'Request Body',
            groupby: 'waf_log.latency_request_body_phase',
        }, {
            name: 'Response Header',
            groupby: 'waf_log.latency_response_header_phase',
        }, {
            name: 'Response Body',
            groupby: 'waf_log.latency_response_body_phase',
        }],
        page_size: 4,
    },
    'waf-rule-groups': {
        header: 'WAF Groups',
        name: 'WAF Groups',
        params: [
            'waf_log.rule_logs.rule_id',
            'waf_log.rule_logs.rule_name',
            'waf_log.rule_logs.rule_group',
        ],
        queryParams: [
            'waf_log.rule_logs.rule_id',
            'waf_log.rule_logs.rule_name',
            'waf_log.rule_logs.rule_group',
        ],
        page_size: 100,
    },
    country: {
        header: 'Top Locations',
        name: 'Location',
        unique: 'Unique Location',
        queryParams: 'client_location',
        params: 'client_location',
    },
    device: {
        header: 'Top Devices',
        name: 'Device',
        queryParams: 'client_device',
        params: 'client_device',
    },
    host: {
        header: 'Top Hosts',
        name: 'Host',
        queryParams: 'host',
        params: 'host',
    },
    browser: {
        header: 'Top Browsers',
        name: 'Browser',
        queryParams: 'client_browser',
        params: 'client_browser',

    },
    'request-type': {
        header: 'Top Request Types',
        name: 'Request type',
        queryParams: 'method',
        params: 'method',
    },
    'response-code': {
        header: 'Top Response Codes',
        name: 'Response code',
        queryParams: 'response_code',
        params: 'response_code',

    },
    'ip-addr': {
        header: 'Top IP Addresses',
        name: 'IP',
        queryParams: [
            'client_ip',
            'client_ip6',
        ],
        unique: 'Unique IP Address',
        uniquePluralEnd: 'es',
        params: 'client_ip',
    },
    response_content_type: {
        header: 'Top Content Types',
        name: 'Content Type',
        queryParams: 'response_content_type',
        params: 'response_content_type',
    },
    'server-ip-addr': {
        header: 'Top Server IP Addresses',
        name: 'Server IP',
        queryParams: [
            'server_ip',
            'server_ip6',
        ],
        unique: 'Unique IP Address',
        uniquePluralEnd: 'es',
        params: 'server_ip',
    },
    'e2e-timing': {
        header: {
            total_time: 'Total Response Times',
            data_transfer_time: 'Data Transfer Times',
            client_rtt: 'Client Round Trip Times',
            server_rtt: 'Server Round Trip Times',
            app_response_time: 'Application Response Times',
        },
        name: 'Time Range (ms)',
        query: {
            total_time: '',
            data_transfer_time: '',
            client_rtt: '',
            server_rtt: '',
            app_response_time: '',
            // for average values in the header
            average7: 'AVG(data_transfer_time),AVG(client_rtt),' +
                'AVG(app_response_time),AVG(server_rtt),AVG(total_time)',
            average4: 'AVG(client_rtt),AVG(server_rtt),AVG(total_time)',
        },
        params: 'total_time',
    },
    http_version: {
        header: 'HTTP Version',
        name: 'HTTP Version',
        queryParams: 'http_version',
        params: 'http_version',
    },
    uri_path: {
        header: 'URL Paths',
        name: 'URL Path',
        queryParams: 'uri_path',
        params: 'uri_path',
    },
    referrer: {
        header: 'Referer',
        name: 'Referer',
        queryParams: 'referer',
        unique: 'Unique Referer',
        params: 'referer',
    },
    'icap_log.action': {
        header: 'ICAP Actions',
        name: 'Action',
        queryParams: 'icap_log.action',
        params: 'icap_log.action',
    },
    'icap_log.request_logs.latency': {
        header: 'ICAP Latency',
        name: 'Latency (ms)',
        queryParams: 'icap_log.request_logs.latency',
        params: 'icap_log.request_logs.latency',
    },
    'icap_log.request_logs.icap_response_code': {
        header: 'ICAP Response Codes',
        name: 'ICAP Response Code',
        queryParams: 'icap_log.request_logs.icap_response_code',
        params: 'icap_log.request_logs.icap_response_code',
    },
    'icap_log.request_logs.http_response_code': {
        header: 'HTTP Response Codes',
        name: 'HTTP Response Code',
        queryParams: 'icap_log.request_logs.http_response_code',
        params: 'icap_log.request_logs.http_response_code',
    },
    'icap_log.request_logs.icap_server_ip': {
        header: 'ICAP Server IPs',
        name: 'Server IP',
        queryParams: 'icap_log.request_logs.icap_server_ip',
        params: 'icap_log.request_logs.icap_server_ip',
    },
    'icap_log.request_logs.opswat_log.violations.resolution': {
        header: 'ICAP Violation Resolutions',
        name: 'Violation Resolutions',
        queryParams: 'icap_log.request_logs.opswat_log.violations.resolution',
        params: 'icap_log.request_logs.opswat_log.violations.resolution',
    },
    'icap_log.request_logs.opswat_log.violations.threat_name': {
        header: 'ICAP Violation Threat Names',
        name: 'Violation Threat Names',
        queryParams: 'icap_log.request_logs.opswat_log.violations.threat_name',
        params: 'icap_log.request_logs.opswat_log.violations.threat_name',
    },
    client_os: {
        header: 'Top Operating Systems',
        name: 'Operating System',
        queryParams: 'client_os',
        params: 'client_os',
    },
    request_length: {
        header: 'Request Lengths',
        name: 'Request Length',
        queryParams: 'request_length',
        params: 'request_length',
    },
    response_length: {
        header: 'Response Lengths',
        name: 'Response Length',
        queryParams: 'response_length',
        params: 'response_length',
    },
    policy: {
        header: 'Top Policies',
        name: 'Rule Name',
        subheader: {
            network: 'Network Security',
            http: 'HTTP Security',
            http_req: 'HTTP Request',
            http_resp: 'HTTP Response',
        },
        query: {
            /* groupby */
            network: 'network_security_policy_rule_name',
            http: 'http_security_policy_rule_name',
            http_req: 'http_request_policy_rule_name',
            http_resp: 'http_response_policy_rule_name',
        },
        queryParams: 'policy',
        params: 'policy',
    },
    significance: {
        header: 'Top Significance',
        name: 'Primary Reason',
        page_size: 64,
        queryParams: 'significance',
        params: 'significance',
    },
    ssl: {
        header: 'SSL/TLS Information',
        name: 'Version',
        subheader: {
            ssl: 'SSL/TLS Version',
            pfs: 'Perfect Forward Secrecy',
            ap: 'Certificate Type',
            ep: 'Encryption Algorithm',
        },
        key: {
            /* way to find data inside response array */
            pfs: 'PFS',
            ap: 'auth',
            ep: 'enc',
        },
        query: {
            /* groupby */
            ssl: 'ssl_version',
            other: 'ssl_cipher', /* for all others */
        },
    },
    user_id: {
        header: 'Top User IDs',
        name: 'User ID',
        queryParams: 'user_id',
        params: 'user_id',

    },
    microservice_name: {
        header: 'Top Microservices',
        name: 'Client Application',
        queryParams: 'microservice_name',
        params: 'microservice_name',
    },
    dns_fqdn: {
        header: 'Top FQDN/Domain Names',
        name: 'Domain Name',
        queryParams: 'dns_fqdn',
        params: 'dns_fqdn',
    },
    dns_qtype: {
        header: 'Top Query Types',
        name: 'Query Type',
        queryParams: 'dns_qtype',
        params: 'dns_qtype',
    },
    gslbservice_name: {
        header: 'Top GSLB Service Names',
        name: 'GSLB Service Name',
        queryParams: 'gslbservice_name',
        params: 'gslbservice_name',
    },
    rx_bytes: { // dns app type
        header: 'Request Lengths',
        name: 'Request Length',
        queryParams: 'rx_bytes',
        params: 'rx_bytes',
    },
    tx_bytes: {
        header: 'Response Lengths',
        name: 'Response Length',
        queryParams: 'tx_bytes',
        params: 'tx_bytes',
    },
    service_engine: {
        header: 'Service Engines',
        name: 'Service Engine IP',
        queryParams: 'service_engine',
        params: 'service_engine',
    },
    protocol: {
        header: 'Protocols',
        name: 'Protocol',
        queryParams: 'protocol',
        params: 'protocol',
    },
    dns_etype: {
        header: 'Record Sources',
        name: 'Record Source',
        queryParams: 'dns_etype',
        params: 'dns_etype',
    },
    vs_ip: {
        header: 'VS IP Addresses',
        name: 'VS IP Address',
        queryParams: [
            'vs_ip',
            'vs_ip6',
        ],
        params: 'vs_ip',
    },
    'e2e-timing-udp': {
        header: 'End to End (UDP)',
        name: 'Time range (ms)',
        queryParams: 'total_time',
        params: 'total_time',
    },
    'bot_management_log.classification.type': {
        header: 'Classifications',
        name: 'Tags',
        queryParams: 'bot_management_log.classification.type',
        params: 'bot_management_log.classification.type',
    },
};
