/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './update-card.l10n';
import './update-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Wrapper Component transcluding Update Progress Card.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'update-card',
    templateUrl: './update-card.component.html',
})
export class UpdateCardComponent {
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
