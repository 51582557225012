/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IVirtualServiceState } from './virtual-service.state';

export const featureKey = 'virtualService';

const selectFeature = createFeatureSelector<IVirtualServiceState>(featureKey);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectFaultsStateById = (vsId: string) => {
    return createSelector(
        selectFeature,
        ({ faults }) => faults[vsId],
    );
};
