/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { WAF_ACTION } from '../../waf-logs.service';
import './psm-log.less';
import '../../waf-log-entry.less';
import '../../waf-log-match-element.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.psmLogBindings
 * @see {@link  module:avi/logs.psmLogComponent}
 */
class PsmLogController {
    constructor(wafLogsService) {
        this.wafLogsService_ = wafLogsService;
    }

    /**
     * Returns a list of text to display based on a list of action enums.
     * @returns {string} - List of text to display from the action enums.
     */
    getActionLabels() {
        return this.wafLogsService_.getActionLabels(WAF_ACTION, this.log.actions);
    }
}

PsmLogController.$inject = [
    'wafLogsService',
];

/**
 * @name psmLogComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.PsmLogController} controller
 * @property {module:avi/logs.psmLogBindings} bindings
 * @description Component for PSM Logs.
 * @author alextsg
 */
angular.module('avi/logs').component('psmLog', {
    /**
     * @mixin psmLogBindings
     * @memberOf module:avi/logs
     * @property {Object} log - PSM Log object.
     * @property {boolean} expanded - Boolean to expand/collapse all.
     */
    bindings: {
        log: '<',
        expanded: '<',
    },
    controller: PsmLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'psm-logs-list/psm-log/psm-log.component.html',
});
