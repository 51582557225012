<div class="vs-logs-actions">
    <div class="btn-group">
        <ng-container *ngrxLet="vsLogsStore.tableIsLoading$ as tableIsLoading">
            <button
                class="btn btn-sm"
                [disabled]="tableIsLoading"
                (click)="refreshPage()"
            >
                <cds-icon
                    shape="refresh"
                    size="16"
                ></cds-icon>
                {{ l10nKeys.refreshLabel | vtranslate }}
            </button>
            <!-- TODO: Ideally, `hasError$` here should be coming from vsLogsStore.tableProps,
                   but due to a bug which persists vsLogsStore.tableProps.hasError's 'true'
                   state even after reload, it had to be removed. Somehow 'setState' from
                   method 'removeFilter' is called again at end, with tableProps.hasError=true,
                   without 'removeFilter' actually being called again. If can resolve bug,
                   then can add vsLogsStore.tableProps.hasError$, and use it here.
                   Then can remove vsLogListStore.hasError$. -->
            <button
                class="btn btn-sm"
                [disabled]="(vsLogsStore.hasError$ | async) || tableIsLoading"
                avi-tooltip
                [showOnClick]="true"
                [positionsPriority]="exportOptionsPanelPosition"
                [tooltipControl$]="exportOptionsPanelCloseControl$"
            >
                {{ l10nKeys.exportLabel | vtranslate }}
                <cds-icon
                    shape="angle"
                    direction="down"
                    size="10"
                ></cds-icon>
                <ng-template #aviTooltipContent>
                    <div class="vs-logs-actions__export-dropdown-panel">
                        <div class="vs-logs-actions__export-dropdown-content">
                            <ng-container *ngrxLet="vsLogsStore.totalLogsCount$ as totalLogs">
                                <button
                                    class="vs-logs-actions__export-dropdown-button"
                                    [ngClass]="{ deactivated: totalLogs === 0 }"
                                    (click)="downloadCurrentPage()"
                                    [disabled]="totalLogs === 0"
                                >
                                    {{ l10nKeys.currentPageLabel | vtranslate }}
                                </button>
                                <button
                                    class="vs-logs-actions__export-dropdown-button"
                                    [ngClass]="{ deactivated: totalLogs === 0 }"
                                    (click)="downloadAllLogs()"
                                    [disabled]="totalLogs === 0"
                                >
                                    {{ l10nKeys.allLogsLabel | vtranslate: totalLogs.toLocaleString() }}
                                </button>
                            </ng-container>
                            <!-- TODO 150380: enable Selected Logs choice once querying for multiple specific logs is enabled -->
                            <!-- <ng-container *ngrxLet="vsLogsStore.selectedLogs$ as selectedLogs">
                                <button
                                    class="vs-logs-actions__export-dropdown-button"
                                    [ngClass]="{ deactivated: selectedLogs.length === 0 }"
                                    (click)="downloadSelectedLogs()"
                                    [disabled]="selectedLogs.length === 0"
                                >
                                    {{ l10nKeys.selectedLogsLabel | vtranslate: selectedLogs.length }}
                                </button>
                            </ng-container> -->
                        </div>
                    </div>
                </ng-template>
            </button>
        </ng-container>
    </div>
</div>
