/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IOperationalStatus } from 'generated-types';
import { StringService } from 'string-service';
import { UpdatableItem } from 'ajs/modules/data-model/factories/updatable-item.factory';
import { IHSSeries } from 'ng/modules/health-score/health-score.types';
import { IExtendedMetricsHealthScoreObj } from 'items/pool.item.factory';

import * as l10n from './pool-health-score-body.l10n';
import './pool-health-score-body.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for rendering pool health score body.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'pool-health-score-body',
    templateUrl: './pool-health-score-body.component.html',
})
export class PoolHealthScoreBodyComponent implements OnInit {
    /**
     * Editable item of type UpdatableItem.
     * TODO - Convert UpdatableItem to VirtualService | ServiceEngine | Pool
     * once all are migrated to item
     */
    @Input()
    public editable: UpdatableItem;

    public readonly l10nKeys = l10nKeys;

    public hsSeries: IHSSeries;

    public healthScore: IExtendedMetricsHealthScoreObj;

    /**
     * Current operational status of the item.
     */
    public operationStatus: IOperationalStatus;

    public objectName = '';

    constructor(
        public readonly l10nService: L10nService,
        public readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectName = this.editable.getItemType();
        this.operationStatus = this.editable.data.runtime.oper_status;
        this.healthScore = this.editable.data.health_score;
        this.hsSeries = this.editable.data.hsSeries;
    }
}
