/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtual-service-traffic-capture-status';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceHeader = `${prefix}.virtualServiceHeader`;
export const noVirtualServiceSelectedHeader = `${prefix}.noVirtualServiceSelectedHeader`;
export const filterToCaptureInputLabel = `${prefix}.filterToCaptureInputLabel`;
export const packetsCaptureInputLabel = `${prefix}.packetsCaptureInputLabel`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const captureSettingsHeader = `${prefix}.captureSettingsHeader`;
export const durationInputLabel = `${prefix}.durationInputLabel`;
export const numberOfPacketsPerCoreInputLabel = `${prefix}.numberOfPacketsPerCoreInputLabel`;
export const durationInMinutesHeader = `${prefix}.durationInMinutesHeader`;
export const bytesPerPacketInputLabel = `${prefix}.bytesPerPacketInputLabel`;
export const emptyLabel = `${prefix}.emptyLabel`;

export const ENGLISH = {
    [virtualServiceHeader]: 'Virtual Service',
    [noVirtualServiceSelectedHeader]: 'No Virtual Service Selected',
    [filterToCaptureInputLabel]: 'Filter to capture',
    [packetsCaptureInputLabel]: 'Packets Captured',
    [emptyDataLabel]: 'None',
    [captureSettingsHeader]: 'Capture Settings',
    [durationInputLabel]: 'Duration',
    [numberOfPacketsPerCoreInputLabel]: 'Number of Packets per Core',
    [durationInMinutesHeader]: '{0} minute(s)',
    [bytesPerPacketInputLabel]: 'Bytes per packet',
    [emptyLabel]: 'N/A',
};
