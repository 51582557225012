/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
} from '@angular/core';

import './vs-logs-collapsed-sidebar.component.less';

/**
 * @description
 *
 *     Component for setting the icon list view of collapsed log sidebar.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-logs-collapsed-sidebar',
    templateUrl: './vs-logs-collapsed-sidebar.component.html',
})
export class VsLogsCollapsedSidebarComponent {
    /**
     * List of single icon name for the upper search section column.
     */
    public searchSectionIconList = ['star'];

    /**
     * List of icon names for generating the collapsed-state sidebar lower icon column section.
     */
    public dataSectionIconList = [
        'shield',
        'user',
        'share',
        'switch',
        'exclamation-circle',
    ];
}
