/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} module:avi/dataModel.DataSourceFieldConfig
 * @property {string} id - Unique id for the field name.
 * @property {boolean|undefined} preserved - Field will be preserved on reset.
 * @property {string[]} subscribers - List of subscribers ids.
 */

/**
 * Configuration object of a {@link DataSource} instance.
 * @typedef {Object} module:avi/dataModel.DataSourceConfig
 * @property {string} source - Name of {@link DataSource} service to be injected.
 * @property {string} transformer - Name of {@link DataTransformer} service to be injected.
 * @property {string} transport - Name of {@link DataTransport} service to be injected.
 * @property {string[]} fields - List of field ids, which can be provided by this
 *     data source to {@link UpdatableBase}. Each fieldName within one UpdatableBase instance can
 *     be provided by a single DataSourceConfig only.
 * @property {string} dependsOn - Name of the field which should be present (loaded) in
 *     Collection before making call for the defined {@link DataSource}
 */

import angular from 'angular';
import { Base } from './base.factory';

/**
 * Default data update interval; in seconds.
 * @type {number}
 */
const DEFAULT_UPDATE_INTERVAL = 30;

/**
 * @constructor
 * @memberOf module:avi/dataModel
 * @extends Base
 * @author Alex Malitsky, Ashish Verma, Ram Pal
 * @desc
 *
 *     Abstract DataSource class. It is a layer between it's user (owner_) and DataTransformer
 *     responsible for initial preparation of request for the following API call and
 *     processing of received and pre-processed by DataTransformer data. It knows how
 *     to fetch and save some API data for a particular user (owner_).
 *     Base of {@link module:avi/dataModel.DataSource DataSource}.
 */
export class DataSource extends Base {
    constructor(args) {
        super(args);

        this.$q = this.getAjsDependency_('$q');

        this.$injector = this.getAjsDependency_('$injector');

        /**
         *  Unique id of DataSource within it's user.
         *  @type {string}
         *  */
        this.id = args.id;

        /**
         * Reference to Object which is using this DataSource.
         * @type {Object}
         * */
        this.owner_ = args.owner;//item, collection, graph, etc

        /**
         * List of active data fields of this data source to participate in
         * data flow.
         * @type {{id: DataSourceFieldConfig}}
         * @protected
         */
        this.fields_ = {};

        /**
         * Name of data field belonging to other DataSource which is required to
         * be loaded by Collection before loading this DataSource. I.e.
         * collectionMetrics API requires `config` to be loaded otherwise we have
         * no way to create a payload (list of UUIDs).
         * @type {string}
         */
        this.dependsOn = !angular.isUndefined(args.dependsOn) ? args.dependsOn : '';

        /**
         * Default parameters object of DataSource, will be used initially and
         * after reset as data source parameters. Actual structure is defined by child.
         * @type {{string: *}}
         * @protected
         * @abstract
         */
        this.defaultParams_ = angular.isObject(this.defaultParams_) ?
            angular.copy(this.defaultParams_) : {};

        if (angular.isObject(args.defaultParams)) {
            angular.extend(this.defaultParams_, args.defaultParams);
        }

        /**
         * Hash of parameters which will be used to generate a request parameters object.
         * @type {{string: string|string[]}}
         */
        this.params_ = angular.isObject(args.params) ?
            angular.extend({}, this.defaultParams_, args.params) :
            angular.copy(this.defaultParams_);

        /**
         * List of fields to be activated on DS instantiation and preserved during reset.
         * @type {DataSourceFieldConfig[]}
         * @protected
         */
        if (angular.isArray(args.defaultFields)) {
            this.defaultFields_ = args.defaultFields.concat();
        } else if (angular.isArray(this.defaultFields_)) {
            this.defaultFields_ = this.defaultFields_.concat();
        } else {
            this.defaultFields_ = [];
        }

        /**
         * Whether DataSource should be preserved on user's reset.
         * @type {boolean}
         */
        this.isPreserved = !!args.preserved;

        /**
         * When set to true load won't start the async factory and
         * {@link Timeframe} change event's won't affect the async factory.
         * @type {boolean}
         * @protected
         */
        this.isStatic_ = !!args.isStatic;

        /**
         * @type {DataTransport|null}
         * @protected
         */
        this.transport_ = null;

        if (args.transport && angular.isString(args.transport)) {
            const TransportClass = this.$injector.get(args.transport);

            this.transport_ = new TransportClass({
                id: args.transport,
                owner: this.owner_,
            });
        } else {
            console.error(`No transport passed to dataSource "${this.id}" constructor of %O`,
                this.owner_);
        }

        /**
         * @type {DataTransformer|null}
         * @protected
         */
        this.transformer_ = null;

        if (args.transformer && angular.isString(args.transformer)) {
            const TransformerClass = this.$injector.get(args.transformer);

            this.transformer_ = new TransformerClass({
                id: args.transformer,
                owner: this.owner_,
            });
        } else {
            console.error(`No transformer passed to dataSource constructor "${this.id}" of %O`,
                this.owner_);
        }

        /**
         * When set to true DataSource won't update the updateInterval on Timeframe change
         * events.
         * @type {boolean}
         * @protected
         */
        this.protectedUpdateInterval_ = !!args.protectedUpdateInterval;

        if (!this.isStatic_) {
            const updInterval = args.updateInterval;

            /**
             * Update interval for async factory with DataSource#load. Should be
             * equal to 0 when DataSource.isStatic is set to true.
             * @type {number}
             * @protected
             */
            this.updateInterval_ =
                angular.isNumber(updInterval) && updInterval > 0 &&
                _.isFinite(updInterval) ? updInterval : this.updateInterval_ ||
                DEFAULT_UPDATE_INTERVAL;

            /**
             * Default update interval for async factory with DataSource#load.
             * Will be used when data source is being reset.
             * Should be equal to 0 when DataSource.isStatic is set to true.
             * @type {number}
             * @protected
             */
            this.defaultUpdateInterval_ = this.updateInterval_;
        } else {
            this.defaultUpdateInterval_ = 0;
            this.updateInterval_ = 0;
            this.protectedUpdateInterval_ = true;
        }

        /**
         * Instance of AsyncFactory when we have an ongoing polling.
         * @type {AsyncFactory|null}
         * @protected
         */
        this.asyncInstance_ = null;

        /**
         * Whether to call load after DS instantiation or not.
         * @type {boolean}
         * @protected
         */
        this.loadOnCreate_ = !angular.isUndefined(args.loadOnCreate) ?
            !!args.loadOnCreate :
            true;

        this.subscribe(this.defaultFields_.concat(args.fields), !this.loadOnCreate_);

        /**
         * When we are about to make a load while previous one is still pending
         * we want to have a way for the promise.finally to know whether it is
         * working after most recent or outdated call.
         * @type {number}
         * @protected
         */
        this.loadRequestId_ = 0;

        /**
         * We keep an integer as representation of a pending API call.
         * 0 when there is no active call, 1 when there is initial call on the fly
         * and 2 when update type of API call (active asyncfactory) is being made.
         * @type {number}
         * @protected
         */
        this.loadingState_ = 0;
    }

    /**
     * Loads data it is responsible for and starts a corresponding async factory when
     * it is not static and DataSource#updateInterval is set.
     * @params {*} firstCallParams - To be passed to getRequestParams_ on the every
     * first load call.
     * @returns {ng.$q.promise}
     */
    load(firstCallParams) {
        const load_ = () => {
            let promise,
                transformerRequestConfig;

            const requestParams = this.getRequestParams_(
                isFirstCall ? firstCallParams : undefined,
            );

            if (isFirstCall) {
                isFirstCall = false;//ongoing updates don't rely on the passed params
                this.loadingState_ = 1;
            } else {
                this.loadingState_ = 2;
            }

            if (this.preventLoad_(requestParams)) {
                promise = this.$q.reject('Load has been prevented by preventLoad_ method');
            } else {
                transformerRequestConfig = this.transformer_
                    .getRequestConfig(requestParams);
                promise = this.transport_.load(transformerRequestConfig);
            }

            return promise.then(
                resp => this.transformer_.processResponse(resp, transformerRequestConfig),
            )
                .then(resp => {
                    //load itself returns a promise to be resolved/rejected
                    return this.processResponse_(resp, requestParams);
                })
                .then(() => deferred.resolve())
                .catch(error => {
                    if (error) {
                        if (!this.isDestroyed()) {
                            this.trigger('dataSourceLoadFail', error.data || error);
                        }

                        if (angular.isObject(error) && 'data' in error) {
                            this.$state = this.getAjsDependency_('$state');
                            this.aviAlertService = this.getAjsDependency_('aviAlertService');
                            this.appDefaultState = this.getAjsDependency_('appDefaultState');

                            if (error.status === 403) {
                                this.$state.go(this.appDefaultState);
                            } else {
                                this.aviAlertService.throw(error.data);
                            }
                        }
                    }

                    deferred.reject(error);

                    return this.$q.reject(error);
                })
                .finally(() => {
                    if (loadRequestId === this.loadRequestId_) {
                        this.loadingState_ = 0;
                    }
                });
        };

        const
            deferred = this.$q.defer(),
            loadRequestId = ++this.loadRequestId_,
            isConfigSource = 'config' in this.fields_;

        let isFirstCall = true,
            promise;

        this.stopAsyncFactory_();

        if (!this.isInactive()) {
            if (isConfigSource) {
                //TODO trigger DataSource event, let the owner decide how to react
                //DEPRECATED, legacy
                this.owner_.busy = true;
            }

            if (!this.isStatic_ && this.updateInterval_) {
                this.AsyncFactory = this.getAjsDependency_('AsyncFactory');
                this.asyncInstance_ = new this.AsyncFactory(load_);
                this.asyncInstance_.start(this.updateInterval_ * 1000);
                promise = deferred.promise;
            } else {
                promise = load_();
            }

            promise.finally(() => {
                //cancelled by stopAsyncFactory_ request should not flip busy value
                //DEPRECATED
                if (isConfigSource && loadRequestId === this.loadRequestId_) {
                    this.owner_.busy = false;
                }
            });
        } else {
            promise = this.$q.when(`Inactive DataSource ${this.id} skipped the update`);
        }

        return promise;
    }

    /**
     * Returns true when there is an outgoing API call.
     * @param {boolean=} inclUpdates - If true is passed dataSource will return true when
     *     asyncFactory is making an update call. Otherwise dataSource is
     *     considered to be busy only when making an initial API call.
     * @returns {boolean}
     */
    isBusy(inclUpdates = false) {
        return inclUpdates ? !!this.loadingState_ : this.loadingState_ === 1;
    }

    /**
     * Stops running {@link AsyncFactory} and cancels any pending network calls.
     * @protected
     */
    stopAsyncFactory_() {
        if (this.asyncInstance_) {
            this.asyncInstance_.stop();
            this.transport_.cancelRequests();
            this.asyncInstance_ = null;
            this.loadingState_ = 0;
        }
    }

    /**
     * Stops updates if running.
     */
    stopUpdates() {
        this.stopAsyncFactory_();
        this.transport_.cancelRequests();
    }

    /**
     * Reset data source update interval with the init value.
     * Cases where DS is static or update interval is protected are handled
     * by calling {@link DataSource#setUpdateInterval}.
     * @protected
     */
    resetUpdateInterval_() {
        this.setUpdateInterval(this.defaultUpdateInterval_, true);
    }

    /**
     * Sets the data source update interval if DS is not static and update interval is not
     * protected.
     * @param {number} interval - Will be set to zero if wrong value was passed.
     * @param {boolean} [forced=false] - Method will overwrite the protected update interval
     *     if this argument is truthy.
     */
    setUpdateInterval(interval, forced = false) {
        if (!this.isStatic_ && (forced || !this.protectedUpdateInterval_)) {
            this.updateInterval_ =
                    angular.isNumber(interval) &&
                        interval > 0 && _.isFinite(interval) ? interval : 0;
        }

        if (this.isStatic_ && this.updateInterval_) {
            this.updateInterval_ = 0;
        }
    }

    /**
     * Responsible for high-level data processing and serving updates to the Items.
     * @param {Object} resp - Pre-processed by DataTransport and
     *      DataTransformer response.
     * @param {Object} requestParams - Result of DataSource#getRequestParams_
     *     call which was used to make an API call we are about to process.
     * @returns {*}
     * @abstract
     * @protected
     */
    processResponse_(resp, requestParams) {
        return resp;
    }

    /**
     * This function creates a request object to be further processed
     * by {@link DataTransformer} and passed to {@link DataTransport} for
     * performing an actual network call.
     * @param {*} params - DS can perform different loads: default one
     *     is fetching the viewport area with overLimit extra, same is used for
     *     ongoing updates but when you want something special extra param is
     *     here for you.
     * @returns {*}
     * @protected
     * @abstract
     */
    getRequestParams_(params) {
        return angular.copy(this.params_);
    }

    /**
     * Function is called by {@link Collection} over all data sources to
     * set `parameters` which affect the payload and data processing.
     * Particular implementation is defined by child.
     * @param {Object} params
     * @returns {boolean} - True when new values of params has been set.
     * @abstract
     * @todo support params filtering (white list for each DS?)
     */
    setParams(params) {
        let res = false;

        this.deleteUndefinedValues = this.getAjsDependency_('deleteUndefinedValues');

        if (angular.isObject(params)) {
            params = angular.copy(params);

            // special case for header params so that we can
            // add new properties wo overwriting others
            if (angular.isObject(params.headers_) &&
                angular.isObject(this.params_.headers_)) {
                angular.extend(this.params_.headers_, params.headers_);
                this.deleteUndefinedValues(this.params_.headers_);
                delete params.headers_;
            }

            angular.extend(this.params_, params);
            this.deleteUndefinedValues(this.params_);

            res = true;
        }

        return res;
    }

    /**
     * Returns internal parameters of DataSource which impact API requests and
     * fetched data.
     * @param {string=} paramName - Parameter name to be returned. When undefined
     *     all params will be returned.
     * @returns {*}
     * @abstract
     */
    getParams(paramName) {
        let res;

        if (paramName && angular.isString(paramName)) {
            res = this.params_[paramName];
        } else {
            res = this.params_;
        }

        return angular.copy(res);
    }

    /**
     * Reset parameters to default values for all data sources
     * which affect the payload and data processing for the collection.
     */
    resetParams() {
        this.params_ = angular.copy(this.defaultParams_);
    }

    /**
     * Adds fields to the data source. If actual changes to fields list have
     * been made and dontLoad is not set will call DataSource#load.
     * @param {DataSourceFieldConfig.id[]|DataSourceFieldConfig.id|
     *    DataSourceFieldConfig|DataSourceFieldConfig[]} newFields
     * @param {boolean=} dontLoad - Pass true to avoid DataSource#load
     * after fields list change.
     * @returns {ng.$q.promise}
     */
    subscribe(newFields, dontLoad = false) {
        let promise,
            updateStatus = 0;

        if (newFields && !angular.isArray(newFields) && (angular.isString(newFields) ||
                angular.isObject(newFields) && 'id' in newFields)) {
            newFields = [newFields];
        }

        if (angular.isArray(newFields)) {
            newFields.forEach(newField => {
                const id = angular.isObject(newField) ? newField.id : newField;

                if (id && angular.isString(id)) {
                    const field = _.findWhere(this.fields_, { id });

                    if (!field) { //new field
                        this.fields_[id] = {
                            id,
                            subscribers: ['not-provided'],
                        };

                        if (angular.isObject(newField)) {
                            if (angular.isString(newField.subscriber) &&
                                newField.subscriber) {
                                this.fields_[id].subscribers[0] = newField.subscriber;
                            }

                            if ('preserved' in newField) {
                                this.fields_[id].preserved = !!newField.preserved;
                            }
                        }

                        updateStatus = 1;
                    } else if (angular.isObject(newField)) {
                        //another subscriber (only if passed)
                        if (angular.isString(newField.subscriber) &&
                            newField.subscriber &&
                            field.subscribers.indexOf(newField.subscriber) === -1
                        ) {
                            field.subscribers.push(newField.subscriber);

                            if (!updateStatus) {
                                updateStatus = 2;
                            }
                        }
                    }
                }
            });
        }

        if (!dontLoad && updateStatus === 1) {
            promise = this.load();
        } else {
            promise = this.$q.when(true);
        }

        return promise;
    }

    /**
     * Removes fields from data source setup.
     * @param {DataSourceFieldConfig.id[]|DataSourceFieldConfig.id|
     *    DataSourceFieldConfig|DataSourceFieldConfig[]} fields
     * @param {boolean=} dontLoad - By default WON'T reload collection on
     * successful unsubscribe call.
     * @returns {ng.$q.promise}
     * @todo support subscriber's name only
     */
    unsubscribe(fields, dontLoad = true) {
        let updateStatus = 0,
            promise;

        if (!angular.isArray(fields) && (angular.isObject(fields) && 'id' in fields ||
                angular.isString(fields))) {
            fields = [fields];
        }

        if (angular.isArray(fields)) {
            const
                promises = [],
                deleteField = id => {
                    delete this.fields_[id];
                    promises.push(`field ${id} was removed from the list`);
                    updateStatus = 1;
                };

            fields.forEach(field => {
                const
                    id = angular.isObject(field) ? field.id : field,
                    existent = id && _.findWhere(this.fields_, { id });

                if (existent) {
                    const
                        { subscribers, preserved } = existent,
                        hasSubscriber = angular.isObject(field) && field.subscriber;

                    if (hasSubscriber) {
                        const subscriberIndex = subscribers.indexOf(field.subscriber);

                        if (subscriberIndex !== -1) {
                            if (subscribers.length > 1) {
                                subscribers.splice(subscriberIndex, 1);
                                promises.push(
                                    // eslint-disable-next-line max-len
                                    `subscriber "${field.subscriber}" removed from "${id}" field`,
                                );

                                if (!updateStatus) {
                                    updateStatus = 2;
                                }
                            } else {
                                deleteField(id);
                            }
                        }
                    } else if (!preserved) {
                        deleteField(id);
                    }
                } else {
                    promises.push(this.$q.reject(`field "${id}" was not found on unsubscribe`));
                }
            });

            promise = this.$q.all(promises);

            if (updateStatus === 1 && !dontLoad) {
                promise = promise.then(() => this.load());
            }
        } else {
            promise = this.$q.reject('wrong arguments format');
        }

        return promise;
    }

    /**
     * Function which is called before executing DataSource#load and can
     * prevent loading and async factory instantiation if evaluates to false.
     * Also can be used as a check to figureout whether it is time to destroy
     * the data source and remove it form COllection . I.e. there is no point
     * in having an inventory data source without the list of fields to be loaded.
     * @type {Function}
     * @abstract
     */
    isInactive() {
        return _.isEmpty(this.fields_);
    }

    /**
     * Resets the list of fields to preserve only required ones. Stops async factory (if
     * any). Reverts the DataSource.params_ values to default ones.
     * @todo what to do with field's subscribers?
     */
    reset() {
        this.onDataFlush();
        this.stopUpdates();
        this.resetUpdateInterval_();
        this.unsubscribe(_.values(this.fields_), true);
        this.params_ = angular.copy(this.defaultParams_);
    }

    /**
    * Destroys the data source. Main point is to stop async factories, cancel all
    * pending calls and remove event listeners.
    * @override
    */
    destroy() {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            this.reset();
        }

        return gotDestroyed;
    }

    /**
     * Function which gets the result of DataSource#getRequestParams_ as argument to make a
     * decision whether load should perform an actual API call. Used to skip useless calls with
     * empty payload (when we have all data or don't have data required to generate a payload).
     * @param {*=} params
     * @returns {boolean}
     * @abstract
     */
    preventLoad_(params) { return false; }

    /**
     * Event handler for data flush event provided by Collection.
     * @abstract
     */
    onDataFlush() {}
}

DataSource.ajsDependencies = [
    '$q',
    '$injector',
    '$state',
    'AsyncFactory',
    'aviAlertService',
    'appDefaultState',
    'deleteUndefinedValues',
];
