/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import {
    GSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';
import { StringService } from 'string-service';
import { GSLBService } from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-pool-member-type.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     GSLB service pool member type component.
 *     Wrapper to display configuration either by IP address or virtual service.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-pool-member-type',
    templateUrl: './gslb-pool-member-type.component.html',
})
export class GslbPoolMemberTypeComponent implements OnInit {
    @Input()
    public editable: GslbPoolMemberConfigItem;

    @Input()
    public gslbService: GSLBService;

    /**
     * Flag indicating pool member configuration type.
     * We have 2 types IP address and virtual service.
     */
    @Input()
    public isConfiguredByTypeIp = false;

    /**
     * Emit true, if pool member configuration is set by IP address type.
     * False, if configured by Virtual Service.
     */
    @Output()
    public isConfiguredByTypeIpChange = new EventEmitter<boolean>();

    /**
     * Emit an event when the user selects or changes the VS or FQDN/IP.
     */
    @Output()
    public onVsSelectionChange = new EventEmitter<string>();

    /**
     * List of GslbSite ids.
     */
    public gslbSites: string[] = [];

    /**
     * Reference for FQDN or IP address entered.
     */
    public ipOrFqdnInputValue: string;

    public resolvedFqdnDropdownOptions: IAviDropdownOption[] = [];

    public resolvedVsVipIpDropdownOptions: IAviDropdownOption[] = [];

    public siteClusterControllerDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Reference for VS collection of selected site.
     */
    public selectedSiteVsCollection: GSLBVSCollection;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        await this.setInitialSettingsByVSType();
        await this.setInitialSettingsByIPType();
    }

    /**
     * Handle FQDN or IP address change.
     */
    public async handleIpOrFqdnChange(inputValue: string): Promise<void> {
        const ips = await this.editable.handleIpOrFqdnChange(inputValue);

        this.resolvedFqdnDropdownOptions = this.createDropdownOptions(ips);
        this.handleResolvedVsVipIpChange();
    }

    /**
     * Handle site cluster controller selection change.
     */
    public handleSiteClusterControllerChange(): void {
        this.resolvedVsVipIpDropdownOptions = [];

        this.editable.handleSiteClusterControllerChange();

        this.selectedSiteVsCollection = this.editable.getSelectedSiteVsCollection(this.gslbSites);
    }

    /**
     * Handle VS selection change.
     */
    public async handleVsSelectionChange(): Promise<void> {
        const vsIps = await this.editable.onVsSelectionChange(this.selectedSiteVsCollection);

        this.resolvedVsVipIpDropdownOptions = this.createDropdownOptions(vsIps);
        this.handleResolvedVsVipIpChange();
    }

    /**
     * Emit an event when the user selects or changes the VS or FQDN/IP.
     */
    public handleResolvedVsVipIpChange(): void {
        this.onVsSelectionChange.emit(this.editable.config.ip.address);
    }

    /**
     * Handle pool memeber configuration type change.
     */
    public handleMemberConfigurationTypeChange(): void {
        this.ipOrFqdnInputValue = '';

        this.editable.resetSettingForConfigurationType(this.isConfiguredByTypeIp);
        this.isConfiguredByTypeIpChange.emit(this.isConfiguredByTypeIp);
    }

    /**
     * Set initial configuration for pool member configured by type virtual service.
     */
    private async setInitialSettingsByVSType(): Promise<void> {
        const vsIps = await this.editable.getPoolMemberVsData();

        this.resolvedVsVipIpDropdownOptions = this.createDropdownOptions(vsIps);
        this.gslbSites = this.gslbService.getGSLB()?.getSites() || [];

        setTimeout(() => this.gslbService.setPristine());

        this.siteClusterControllerDropdownOptions = this.createSiteClusterControllerOptions();
        this.selectedSiteVsCollection = this.editable.getSelectedSiteVsCollection(this.gslbSites);
    }

    /**
     * Set initial configuration for pool member configured by IP address type.
     */
    private async setInitialSettingsByIPType(): Promise<void> {
        const { fqdn, ip } = this.editable.config;

        if (fqdn) {
            this.ipOrFqdnInputValue = fqdn;

            const ips = await this.editable.resolveFqdn(fqdn);

            if (ips) {
                this.resolvedFqdnDropdownOptions = this.createDropdownOptions(ips);
            }
        } else {
            this.ipOrFqdnInputValue = ip.address;
        }
    }

    /**
     * Create dropdown options.
     */
    private createDropdownOptions(entries: string[] = []): IAviDropdownOption[] {
        return entries.map((ip: string) => createDropdownOption(ip));
    }

    /**
     * Create site cluster controller dropdown options.
     */
    private createSiteClusterControllerOptions(): IAviDropdownOption[] {
        return this.gslbSites.map((site: string) => createDropdownOption(
            this.stringService.slug(site),
            this.stringService.name(site),
        )) || [];
    }
}
