/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-auto-complete';
const prefix = `${moduleName}.${componentName}`;

export const placeholderLabel = `${prefix}.placeholderLabel`;

export const ENGLISH = {
    [placeholderLabel]: 'Enter/Select Value',
};
