<header class="header-6">
    <div class="branding">
        <a
            [uiSref]="defaultState"
            class="nav-link"
        >
            <cds-icon shape="vm-bug"></cds-icon>
            <span class="title sel-title">VMware Avi Load Balancer</span>
        </a>
    </div>
</header>
