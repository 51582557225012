/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function inventoryMapCollectionFactory(
    Collection,
    InventoryMap,
) {
    const dataSources = {
        list: {
            source: 'InventoryCollDataSource',
            transformer: 'InventoryMapDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health_score',
                'alert',
                'runtime',
            ],
        },
    };

    const defaultDataFields = [
        'config',
        'health_score',
        'alert',
        'runtime',
    ];

    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Alex Malitsky
     * @desc
     *
     *     Collection of {@link InventoryMap} items.
     *
     *     InventoryMap works on dashboard only and provides us with alert, health score,
     *     config and runtime for all nested items starting from VS. VS is a root object for
     *     InventoryMap so it uses vs.id as InventoryMap.id.
     *
     **/
    class InventoryMapCollection extends Collection {
        /** @override */
        isCreatable() {
            return false;
        }
    }

    Object.assign(
        InventoryMapCollection.prototype,
        {
            objectName_: 'inventory-map',
            itemClass_: InventoryMap,
            allDataSources_: dataSources,
            defaultDataFields_: defaultDataFields,
            isStatic_: false,
            defaultViewportSize_: 10,
        },
    );

    return InventoryMapCollection;
}

inventoryMapCollectionFactory.$inject = [
    'Collection',
    'InventoryMap',
];

angular.module('avi/app')
    .factory('InventoryMapCollection', inventoryMapCollectionFactory);
