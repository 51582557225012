/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'sip-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleSip = `${prefix}.sectionTitleSip`;
export const sipRequestCodeInputLabel = `${prefix}.sipRequestCodeInputLabel`;
export const sipResponseInputLabel = `${prefix}.sipResponseInputLabel`;
export const sipResponseInputPlaceholder = `${prefix}.sipResponseInputPlaceholder`;
export const sipMonitorTransportInputLabel = `${prefix}.sipMonitorTransportInputLabel`;
export const sipMonitorTransportInputPlaceholder = `${prefix}.sipMonitorTransportInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleSip]: 'SIP',
    [sipRequestCodeInputLabel]: 'SIP Request Code',
    [sipResponseInputLabel]: 'SIP Response',
    [sipResponseInputPlaceholder]: 'Enter SIP Response',
    [sipMonitorTransportInputLabel]: 'SIP Monitor Transport',
    [sipMonitorTransportInputPlaceholder]: 'Select SIP Monitor Transport',
};
