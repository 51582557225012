/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'user-page-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleSuperUser = `${prefix}.columnTitleSuperUser`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleFullName = `${prefix}.columnTitleFullName`;
export const columnTitleTenant = `${prefix}.columnTitleTenant`;
export const columnTitleLastSignedIn = `${prefix}.columnTitleLastSignedIn`;
export const onlineSincePrefix = `${prefix}.onlineSincePrefix`;
export const lastLoginPrefix = `${prefix}.lastLoginPrefix`;
export const suspendedLabel = `${prefix}.suspendedLabel`;
export const suspendLabel = `${prefix}.suspendLabel`;
export const neverLoggedInLabel = `${prefix}.neverLoggedInLabel`;

export const ENGLISH = {
    [headerLabel]: 'Users',
    [columnTitleSuperUser]: 'Super User',
    [columnTitleStatus]: 'Status',
    [columnTitleFullName]: 'Full Name',
    [columnTitleTenant]: 'Tenant (Role)',
    [columnTitleLastSignedIn]: 'Last Signed In',
    [suspendedLabel]: 'Suspended',
    [suspendLabel]: 'Suspend',
    [onlineSincePrefix]: 'Online since ',
    [lastLoginPrefix]: 'Last login ',
    [neverLoggedInLabel]: 'Never logged-in',
};
