/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ITier1LogicalRouterInfo } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

interface ITier1LogicalRouterInfoConfig extends ITier1LogicalRouterInfo {
    tier1_lr_custom_name?: string;
    segment_custom_name?: string;
    is_auto_discovered?: boolean;
}

/**
 * For reference, consider following are sample value of 'tier1_lr_id' and
 * 'segment_id' when NSX-T cloud is with 'vpc_mode' true and they are auto-discovered.
 * tier1_lr_id - /orgs/<org>/projects/<project>/vpcs/<vpc>, OR
 * /orgs/<org>/projects/<project>/infra/tier-1s/<tier-1>
 * segment_id - /orgs/<org>/projects/<project>/vpcs/<vpc>/subnets/<subnet>, OR
 * /orgs/<or>/projects/<project>/infra/segments/<segment>
 */

// Constants used in the component.
const TIER1_LR_VPC_REGEX = new RegExp(/\/orgs\/|\/projects\/|\/vpcs\//);
const SEGMENT_VPC_REGEX = new RegExp(/\/orgs\/|\/projects\/|\/vpcs\/|\/subnets\//);
const TIER1_LR_INFRA_REGEX = new RegExp(/\/orgs\/|\/projects\/|\/infra\/tier-1s\//);
const SEGMENT_INFRA_REGEX = new RegExp(/\/orgs\/|\/projects\/|\/infra\/segments\//);
const VPC = 'VPC';
const TIER1 = 'Tier1';
const PROJECT = 'Project';

export class Tier1LogicalRouterInfoConfigItem extends MessageItem<ITier1LogicalRouterInfoConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'Tier1LogicalRouterInfo',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isValid(): boolean {
        return this.canFlatten();
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.tier1_lr_id);
    }

    /**
     * Clears the configured Segment ID.
     */
    public clearSegmentID(): void {
        this.config.segment_id = undefined;
    }

    /**
     * Clears both configured Tier1LR ID and Segment ID.
     */
    public clearFields(): void {
        this.clearTier1LRID();
        this.clearSegmentID();
    }

    /**
     * @override
     * When 'tier1_lr_id' consists of '/vpcs/' or '/projects/.../infra/tier-1s/' (exists only
     * in Edit mode) that means it is auto-discovered under 'vpc_mode' as true.
     * For UI to display custom value - split 'tier1_lr_id' by regex to show 'project-name' and
     * 'vpc-name'. And, split 'segment_id' by regex to show subnet-name' or 'segment-name'.
     */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config, tier1LRID, segmentId } = this;

        if (tier1LRID?.includes('/vpcs/')) {
            const tier1Info = tier1LRID.split(TIER1_LR_VPC_REGEX);
            const segmentInfo = segmentId.split(SEGMENT_VPC_REGEX);

            this.setCustomLogicalRouterInfo(true, tier1Info, segmentInfo);
        } else if (tier1LRID?.includes('/projects/') && tier1LRID.includes('/infra/tier-1s/')) {
            const tier1Info = tier1LRID.split(TIER1_LR_INFRA_REGEX);
            const segmentInfo = segmentId.split(SEGMENT_INFRA_REGEX);

            this.setCustomLogicalRouterInfo(false, tier1Info, segmentInfo);
        } else {
            config.is_auto_discovered = false;
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        if (config.is_auto_discovered) {
            delete config.tier1_lr_custom_name;
            delete config.segment_custom_name;
        }

        delete config.is_auto_discovered;
    }

    /**
     * Getter for tier1 logical router id.
     */
    public get tier1LRID(): string {
        const { tier1_lr_id: tier1LRID } = this.config;

        return tier1LRID;
    }

    /**
     * Return if Tier1 LR is auto discovered.
     */
    public get isAutoDiscovered(): boolean {
        return this.config.is_auto_discovered;
    }

    /**
     * Return the segment ID.
     */
    private get segmentId(): string {
        return this.config.segment_id;
    }

    /**
     * Clears the configured Tier1LR ID.
     */
    private clearTier1LRID(): void {
        this.config.tier1_lr_id = undefined;
    }

    /**
     * Set custom values in config for auto discovered Tier1 LRs and Overlay segments.
     */
    private setCustomLogicalRouterInfo(
        isVpc: boolean,
        tier1Info: string[],
        segmentInfo: string[],
    ): void {
        const { config } = this;
        const tier1Prefix = isVpc ? VPC : TIER1;
        const [projectName, tier1Name] = tier1Info.slice(2, 4);

        config.tier1_lr_custom_name = `${tier1Prefix}: ${tier1Name} (${PROJECT}: ${projectName})`;
        config.segment_custom_name = `${segmentInfo[segmentInfo.length - 1]}`;
        config.is_auto_discovered = true;
    }
}
