/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    AlertConfigItem,
} from 'ajs/modules/alert/factories/alert-config/alert-config.item.factory';

import { StringService } from 'string-service';

import * as globalL10n from 'global-l10n';
import * as l10n from './alert-config-grid-expander.l10n';
import './alert-config-grid-expander.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Alert Config grid expander component.
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
@Component({
    selector: 'alert-config-grid-expander',
    templateUrl: './alert-config-grid-expander.component.html',
})
export class AlertConfigGridExpanderComponent implements OnInit {
    /**
     * Alert Config Item.
     */
    @Input()
    public alertConfig: AlertConfigItem;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Label for type info.
     */
    public typeLabel: string;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { source } = this.alertConfig.config;

        this.typeLabel = this.stringService.enumeration(source);
    }

    /**
     * Construct eventsString out of multiple events.
     */
    public constructEventsString(): string {
        /**
         * Store Event/Metric ID in comma separated format.
         */
        let eventsString = '';

        if (this.alertConfig.sysEventRules.count) {
            const eventIds = this.alertConfig.sysEventRules.config.map(events => {
                return this.stringService.enumeration(events.config.event_id);
            });

            eventsString = eventIds.join(', ');
        } else {
            const eventIds = this.alertConfig.metricRules.config.map(events => {
                return this.stringService.enumeration(
                    this.extractMetricId(events.config.metric_id),
                );
            });

            eventsString = eventIds.join(', ');
        }

        return eventsString;
    }

    /**
     * Extract metric id from URL.
     */
    private extractMetricId(url: string): string {
        const parts = url.split('/');

        return parts[parts.length - 1];
    }
}
