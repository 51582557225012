<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    [form]="form"
    [item]="editable"
>
    <form
        clrForm
        #form="ngForm"
        [clrLayout]="verticalLayout"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>
                    {{ globalL10nKeys.generalLabel | vtranslate }}
                </h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        [(ngModel)]="editable.getConfig().name"
                        clrInput
                        id="name"
                        class="sel-name"
                        name="name"
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="to_emails"
                        for="to_emails"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ l10nKeys.toAddressLabel | vtranslate }}
                    </label>
                    <input
                        [(ngModel)]="editable.data.config.to_emails"
                        clrInput
                        id="to_emails"
                        class="sel-to_emails"
                        name="to_emails"
                        placeholder="{{ l10nKeys.toEmailPlaceholder | vtranslate }}"
                        regexPattern="emailList"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="cc_emails"
                        for="cc_emails"
                        objectType="{{ objectType }}"
                    >
                        {{ l10nKeys.ccAddressLabel | vtranslate }}
                    </label>
                    <input
                        [(ngModel)]="editable.data.config.cc_emails"
                        clrInput
                        id="cc_emails"
                        class="sel-toAddress"
                        name="cc_emails"
                        placeholder="{{ l10nKeys.ccEmailPlaceholder | vtranslate }}"
                        regexPattern="emailList"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
