/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'chart-value-controller';
const prefix = `${moduleName}.${componentName}`;

export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const notAvailableLabel = `${prefix}.notAvailableLabel`;
export const emptyValueLabel = `${prefix}.emptyValueLabel`;
export const errorsLabel = `${prefix}.errorsLabel`;

export const ENGLISH = {
    [emptyDataLabel]: 'No Data',
    [notAvailableLabel]: 'Not Available',
    [emptyValueLabel]: 'N/A',
    [errorsLabel]: '/sec errors',
};
