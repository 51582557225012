<avi-confirmation
    class="clr-wrapper"
    headerText="{{ l10nKeys.complianceModeHeader | vtranslate }}"
    (onClose)="handleCancel()"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <avi-alert-group
            *ngIf="error"
            alertType="danger"
        >
            <span> {{ error }} </span>
        </avi-alert-group>
        <avi-alert-group alertType="warning">
            <span>
                {{ l10nKeys.changeWarningMessage | vtranslate }}
            </span>
        </avi-alert-group>
        <div>
            <div>{{ l10nKeys.complianceModeMessage | vtranslate }}</div>
            <avi-checkbox-container-group>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            clrCheckbox
                            type="checkbox"
                            name="enable-fips"
                            id="enable-fips"
                            [(ngModel)]="enableFips"
                            (ngModelChange)="handleEnableFipsChange()"
                            [disabled]="overrideEnableFips"
                        />
                        <label
                            aviLabelWithTooltip
                            for="enable-fips"
                        >
                            {{ l10nKeys.enableFipsCheckboxLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            clrCheckbox
                            type="checkbox"
                            name="enable-common-criteria"
                            id="enable-common-criteria"
                            [(ngModel)]="enableCommonCriteria"
                            [disabled]="!enableFips || overrideEnableCommonCriteria"
                        />
                        <label
                            aviLabelWithTooltip
                            for="enable-common-criteria"
                        >
                            {{ l10nKeys.enableCommonCriteriaCheckboxLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </avi-checkbox-container-group>
        </div>
    </ng-container>
    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button
            (click)="handleCancel()"
            action="outline"
        >
            {{ l10nKeys.cancelButtonLabel | vtranslate }}
        </cds-button>
        <cds-button
            (click)="handleSubmit()"
            status="danger"
            [loadingState]="loadingState"
            [disabled]="!enableContinueButton"
        >
            {{ l10nKeys.continueButtonLabel | vtranslate }}
        </cds-button>
    </ng-container>
</avi-confirmation>
