<cds-modal
    class="clr-wrapper"
    size="lg"
    (closeChange)="handleClose()"
>
    <cds-modal-header>
        <h3>{{ modalTitle }}</h3>
    </cds-modal-header>

    <cds-modal-content>
        <div class="system-update-modal__content-container">
            <avi-alert-group
                *ngIf="error"
                alertType="danger"
            >
                {{ error }}
            </avi-alert-group>

            <clr-checkbox-container *ngIf="showUpgradeSegCheckbox">
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        name="upgrade-system"
                        id="upgrade-system"
                        [(ngModel)]="applyToSystem"
                        clrCheckbox
                    />
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.UpgradeParams }}"
                        fieldName="system"
                        for="upgrade-system"
                    >
                        {{ (isUpgradeMode ? l10nKeys.upgradeCheckboxLabel : l10nKeys.rollbackCheckboxLabel) | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <div *ngIf="isUpgradeMode && isSegUpgradeAvailable">
                <div 
                    class="full-modal-config__form-control-row"
                    *ngIf="upgradeType === UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="seg-system-upgrade-patch-dropdown"
                        >
                            {{ l10nKeys.seGroupPatchVersionLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="seg-system-upgrade-patch-dropdown"
                            [options]="segSystemUpgradePatchVersionOptions"
                            [disabled]="!applyToSystem || !this.segSystemUpgradePatchVersionOptions.length"
                            [required]="!initialSystemImageId"
                            [(ngModel)]="selectedSegSystemUpgradePatchImageId"
                            (ngModelChange)="handleSelectedSegSystemUpgradeVersionChange()"
                        ></avi-dropdown>
                    </div>
                </div>
                <clr-radio-container clrInline>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.SeGroupOptions }}"
                        fieldName="action_on_error"
                    >
                        {{ l10nKeys.actionTakeLabel | vtranslate }}
                    </label>
                    <clr-radio-wrapper *ngFor="let enumValue of segErrorRecoveryEnumValues; trackBy: trackByEnumValue">
                        <input
                            type="radio"
                            clrRadio
                            name="action_on_error"
                            [value]="enumValue.value"
                            [disabled]="!applyToSystem || enumValue.value === seGroupErrorRecoveryEnumHash.ROLLBACK_UPGRADE_OPS_ON_ERROR"
                            [(ngModel)]="actionOnSegFailure"
                        />
                        <label
                            aviLabelWithTooltip
                            tooltipText="{{ enumValue.description }}"
                        >
                            {{ enumValue.label.split(' ')[0] }}
                        </label>
                    </clr-radio-wrapper>
                </clr-radio-container>
            </div>

            <cds-divider class="system-update-modal__cds-divider"></cds-divider>

            <div class="system-update-modal__check-list">
                <label aviLabelWithTooltip>
                    {{ l10nKeys.recommendCheckMessage | vtranslate }}
                </label>
                <ul class="system-update-modal__check-list-content">
                    <li
                        *ngFor="let check of previewCheckList; trackBy: trackByIndex"
                        class="system-update-modal__check-list-item"
                    >
                        <cds-icon
                            status="success"
                            shape="check-circle"
                            size="24"
                        ></cds-icon>
                        <span>{{ check | trimSingleQuote }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </cds-modal-content>

    <cds-modal-actions>
        <cds-button
            action="outline"
            (click)="handleClose()"
        >
            {{ globalL10nKeys.cancelLabel | vtranslate }}
        </cds-button>
        <cds-button
            (click)="handleSubmit()"
            [disabled]="fetchUpgradePreviewFailed"
            loadingState="{{ busy ? loadingStateEnumHash.loading : loadingStateEnumHash.default }}"
        >
            {{ (isUpgradeMode ? globalL10nKeys.upgradeLabel : l10nKeys.rollbackBtnLabel) | vtranslate }}
        </cds-button>
    </cds-modal-actions>
</cds-modal>
