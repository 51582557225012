<avi-card class="system-settings-email-smtp-source-card">
    <avi-card_header>
        <div class="system-settings-email-smtp-source-card__header">
            <cds-icon
                shape="envelope"
                size="24"
                class="system-settings-email-smtp-source-card__icon"
                status="success"
            ></cds-icon>
            {{ l10nKeys.emailSmtpSourceHeader | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="smtp_type"
                for="smtp-source-type"
            >
                {{ l10nKeys.smtpSourceLabel | vtranslate }}
            </label>
            <input
                class="sel-smtp-source-type"
                name="smtp-source-type"
                placeholder="-"
                clrInput
                [value]="smtpSourceType"
                readonly
            />
        </avi-input-container>
        <avi-input-container *ngIf="isSmtpServer">
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="auth_username"
                for="username"
            >
                {{ globalL10nKeys.usernameLabel | vtranslate }}
            </label>
            <input
                class="sel-username"
                name="username"
                placeholder="-"
                clrInput
                [value]="smtpServerUsername"
                readonly
            />
        </avi-input-container>
        <avi-input-container *ngIf="isAnonymousSmtpServer || isLocalSmtpServer">
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="from_email"
                for="from-address"
            >
                {{ l10nKeys.fromAddressLabel | vtranslate }}
            </label>
            <input
                class="sel-from-address"
                name="from-address"
                placeholder="-"
                clrInput
                [value]="smtpFromEmail"
                readonly
            />
        </avi-input-container>
        <ng-container *ngIf="isAnonymousSmtpServer || isLocalSmtpServer">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="mail_server_name"
                    for="smtp-server"
                >
                    {{ l10nKeys.smtpServerLabel | vtranslate }}
                </label>
                <input
                    class="sel-smtp-server"
                    name="smtp-server"
                    placeholder="-"
                    clrInput
                    [value]="smtpServer"
                    readonly
                />
            </avi-input-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="mail_server_port"
                    for="smtp-port"
                >
                    {{ globalL10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    class="sel-smtp-port"
                    name="smtp-port"
                    placeholder="-"
                    clrInput
                    [value]="smtpServerPortValue"
                    readonly
                />
            </avi-input-container>
        </ng-container>
    </avi-card_body>
    <avi-card_footer *ngIf="!isNoneSmtpServer">
        <cds-button
            class="sel-send-test-email"
            action="flat"
            size="sm"
            (click)="openSendTestEmail()"
        >
            {{ l10nKeys.sendTestEmailBtnLabel | vtranslate }}
        </cds-button>
    </avi-card_footer>
</avi-card>
