/**
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { DialogService } from 'dialog-service';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IMoveItemData,
    MoveDataGridRowComponent,
    Placement,
} from '../components';

const MOVE_DATA_GRID_ROW_DIALOG_ID = 'moveDataGridRowDialogId';

/**
 * @description Service to open moveDataGridRow dialog.
 *
 * @author Aravindh Nagarajan
 */
@Injectable()
export class MoveDataGridRowService {
    constructor(private readonly dialogService: DialogService) {}

    /**
     * Opens dialog and moves Item to specified Index.
     */
    public moveItem(
        repeatedMessageItem: RepeatedMessageItem<MessageItem>,
        selectedItem: MessageItem,
        type: string,
    ): void {
        const initialIndex = repeatedMessageItem.getArrayIndexWithMessageItem(selectedItem);

        const moveRowDialogComponentParams = {
            type,
            selectedItemName: selectedItem.getName(),
            configItems: repeatedMessageItem.config,
            onMoveItem: ({ placement, index: newIndex }: IMoveItemData) => {
                if (placement === Placement.BELOW && newIndex < repeatedMessageItem.getMaxIndex()) {
                    newIndex++;
                } else if (placement === Placement.ABOVE && newIndex > 0) {
                    newIndex--;
                }

                repeatedMessageItem.moveByIndex(initialIndex, newIndex);

                this.closeDialog();
            },
        };

        this.dialogService.add({
            id: MOVE_DATA_GRID_ROW_DIALOG_ID,
            component: MoveDataGridRowComponent as Type<Component>,
            componentProps: {
                closeDialog: this.closeDialog,
                ...moveRowDialogComponentParams,
            },
        });
    }

    /**
     * Closes dialog.
     */
    private closeDialog = (): void => {
        this.dialogService.remove(MOVE_DATA_GRID_ROW_DIALOG_ID);
    };
}
