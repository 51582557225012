<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div class="http-version-match">
        <div class="http-version-match__header">
            <label
                aviLabelWithTooltip
                objectType="{{ httpVersionTypeMatchObjectType }}"
                fieldName="versions"
                for="http-version-type"
                required
            >
                {{ l10nKeys.acceptedVersionsLabel | vtranslate }}
            </label>
        </div>
        <avi-dropdown
            id="http-version-type"
            name="http-version-type"
            [(ngModel)]="editable.config.versions"
            [options]="httpVersionTypeOptions"
            placeholder="{{ l10nKeys.selectHttpVersionPlaceHolder | vtranslate }}"
            [multiple]="true"
            required
        ></avi-dropdown>
    </div>
</match-wrapper>
