/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-detection-result-match-component';
const prefix = `${moduleName}.${componentName}`;

export const andText = `${prefix}.andText`;
export const botManagementLabel = `${prefix}.botManagementLabel`;
export const isRadioLabel = `${prefix}.isRadioLabel`;
export const isNotRadioLabel = `${prefix}.isNotRadioLabel`;
export const subHeader = `${prefix}.subHeader`;
export const removeMatchTitle = `${prefix}.removeMatchTitle`;

export const ENGLISH = {
    [andText]: 'and',
    [botManagementLabel]: 'Bot Management',
    [isRadioLabel]: 'Is',
    [isNotRadioLabel]: 'Is Not',
    [subHeader]: 'Classfications ({0})',
    [removeMatchTitle]: 'Remove Match',
};
