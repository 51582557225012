/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  AviMessage
 * @description
 *     Opens and closes messages, which are different from modals in that they are part of
 *     div.messages and do not take up the whole screen. Also, unlike modals, the user is able to
 *     interact with the app while a message is shown.
 *
 * AviMessage supports opening components as messages.
 * @example
 *     <caption>
 *         Controller opening message. Functions containing parameters must be defined as an array,
 *         similarly to Angular's dependency injection.
 *     </caption>
 *     <code>
 *         AviMessage.open('component-name', {
 *             uuid: row.data.config.uuid,
 *             config: config,
 *             submit: ['config', function(config) {}],
 *             validate: function() {}
 *         });
 *     </code>
 *     <caption>Component definition</caption>
 *     <code>
 *         aviApp.component('componentName', {
 *             bindings: {
 *                 uuid: '@',
 *                 config: '<',
 *                 submit: '&',
 *                 validate: '&',
 *                 closeModal: '&'
 *             },
 *             controller: Controller,
 *             templateUrl: 'src/views/components/modals/component-name.html'
 *         });
 *     </code>
 */
class AviMessage {
    constructor(
        $rootScope,
        $compile,
        $injector,
        ComponentTemplateStringBuilder,
        ComponentBindingsSetter,
    ) {
        this.$rootScope_ = $rootScope;
        this.$compile_ = $compile;
        this.$injector_ = $injector;
        this.ComponentTemplateStringBuilder_ = ComponentTemplateStringBuilder;
        this.setComponentBindings_ = ComponentBindingsSetter;

        this.activeModals = {};

        $rootScope.$on('userLoggedOut', this.destroyAll.bind(this));
        $rootScope.$on('setContext', this.destroyAll.bind(this));
    }

    /**
     * Opens the message after setting bindings in the case of a component.
     * @param {string} messageId
     * @param {Object.<string, *>=} data - Modal's parent scope will be extended by this
     *    objects properties.
     * @public
     */
    open(messageId, data) {
        const camelCase = $.camelCase(messageId);
        let template;

        if (this.isOpen(messageId)) {
            console.warn('Message is already opened.');

            return;
        }

        if (this.$injector_.has(`${camelCase}Directive`)) {
            template = this.getComponent_(messageId, data);
            data = this.setComponentBindings_(messageId, data);
        } else {
            template = messageId;
        }

        this.activeModals[messageId] = {
            id: messageId,
            scope: null,
            elem: null,
        };

        return this.open_(messageId, data, template);
    }

    /**
     * Returns template string for component.
     * @param  {string} componentId
     * @param  {Object=} bindings - Properties to be bound to the component's controller.
     */
    getComponent_(componentId, bindings) {
        return this.ComponentTemplateStringBuilder_(
            componentId, bindings, 'message scale-progress',
        );
    }

    open_(messageId, data, template) {
        const modalParentScope = this.$rootScope_.$new();
        const isComponent = this.$injector_.has(`${$.camelCase(messageId)}Directive`);

        if (isComponent) {
            angular.extend(modalParentScope, data);
        }

        /**
         * Function attached to the modalParentScope that closes the modal. Must be declared as
         * a binding in order to be used by the component.
         */
        modalParentScope.closeModal = () => this.destroy(messageId);

        const elem = this.$compile_(template)(modalParentScope);

        $('div.messages').append(elem);

        this.activeModals[messageId].scope = modalParentScope;
        this.activeModals[messageId].elem = elem;
    }

    /**
     * Closes the message. Destroys the scope and removes the element from the DOM.
     * @param {string} messageId
     */
    destroy(messageId) {
        const modal = this.activeModals[messageId];

        if (this.isOpen(messageId)) {
            modal.scope.$destroy();
            modal.elem.remove();
            delete this.activeModals[messageId];
        }
    }

    /**
     * Destroys all active modals.
     */
    destroyAll() {
        _.each(this.activeModals, modal => this.destroy(modal.id));
    }

    /**
     * Checks if specified window is open.
     * @param {modal.id} messageId
     * @returns {boolean} True if specified window id is opened.
     * @public
     */
    isOpen(messageId) {
        return !!messageId && !_.isUndefined(this.activeModals[messageId]);
    }
}

AviMessage.$inject = [
    '$rootScope',
    '$compile',
    '$injector',
    'ComponentTemplateStringBuilder',
    'ComponentBindingsSetter',
];

angular.module('aviApp').service('AviMessage', AviMessage);
