/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

/* eslint-disable max-classes-per-file */

import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    Inject,
    Input,
    NgModule,
    NO_ERRORS_SCHEMA,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import {
    L10nService,
    VIPModule,
} from '@vmw/ngx-vip';

import { ClrSpinnerModule } from '@clr/angular';
import { GSLB, ISubdomainSiteInfo } from 'items/gslb.item.factory';
import * as globalL10n from 'global-l10n';
import { AviCardModule } from 'nsx-alb-ui-components';
import { DialogService } from 'dialog-service';

import {
    ApplicationProfileType,
    IOperationalStatus,
} from 'generated-types';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';
import { GslbSiteDnsVsConfigItem } from 'message-items/gslb-site-dns-vs.config-item.factory';
import { StringService } from 'string-service';

import {
    GSLBInventoryCollection,
    TGSLBInventoryCollection,
} from 'collections/gslb-inventory.collection.factory';

import {
    GSLBVSCollection,
    TGSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation';

import { GslbSubdomainsGridComponent } from './gslb-subdomains-grid/gslb-subdomains-grid.component';

import {
    GslbSubdomainsTreeComponent,
    ITreeNode,
} from './gslb-subdomains-tree/gslb-subdomains-tree.component';

import {
    GslbSubdomainsTreeNodeComponent,
} from './gslb-subdomains-tree/gslb-subdomains-tree-node/gslb-subdomains-tree-node.component';

import * as l10n from './gslb-subdomains-list-page.l10n';

import './gslb-subdomains-list-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * ID for subdomain remove confirmation dialog.
 */
const SUBDOMAIN_REMOVE_CONFIRMATION_DIALOG_ID = 'subdomain-remove-confirmation-dialog';

/**
 * Custom viewType enum. Used for selecting between the List view or Tree view.
 */
enum viewType {
    TREE = 'tree',
    LIST = 'list',
}

/**
 * Intarface to represent subdomain grid data row.
 */
export interface ISubdomainGridData {
    subdomain?: string,
    siteNames?: string[],
    dnsVsNames?: string[],
}

/**
 * @description GSLB Subdomains list page.
 * @author Nisar Nadaf, alextsg
 */
@Component({
    selector: 'gslb-subdomains-list-page',
    templateUrl: './gslb-subdomains-list-page.component.html',
})
export class GslbSubdomainsListPageComponent implements OnInit, OnDestroy {
    /**
     * Default GSLB item, resolved by UI Router.
     */
    @Input('resolveGslb')
    public gslb: GSLB;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * ViewType enum variable to use in template.
     */
    public viewType = viewType;

    public currentView = viewType.LIST;

    public subdomainList: ISubdomainGridData[] = [];

    public subdomainTreeList: ITreeNode[] = [];

    public gslbInventoryCollection: GSLBInventoryCollection;

    /**
     * Show loading spinner if true.
     */
    public isLoading = false;

    /**
     * Number of unique sites present in a subdomain tree.
     */
    public uniqueGslbSitesCount = 0;

    /**
     * Number of unique DnsVses present in a subdomain tree.
     */
    public uniqueDnsVsesCount = 0;

    /**
     * Tree node name highlighted on hover.
     */
    public hoveredTreeNode: string;

    /**
     * Hash of site's uuid and its operational status.
     */
    private siteStatusHash: Record<string, IOperationalStatus> = {};

    /**
     * Hash of dnsVs's uuid and its operational status.
     */
    private dnsVsStatusHash: Record<string, IOperationalStatus> = {};

    /**
     * List of GSLB sites with 'all subdomains' enabled.
     */
    private gslbSitesWithAllSubdomains: ISubdomainSiteInfo[] = [];

    constructor(
        private readonly l10nService: L10nService,
        @Inject(GSLBVSCollection)
        private readonly GSLBVSCollection: TGSLBVSCollection,
        @Inject(GSLBInventoryCollection)
        public readonly GSLBInventoryCollection: TGSLBInventoryCollection,
        private readonly stringService: StringService,
        private readonly dialogService: DialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        this.isLoading = true;
        await this.updateDnsVsRefs();
        await this.loadGslbInventoryCollection();
        this.loadSubdomainsData();
        this.isLoading = false;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.gslbInventoryCollection.destroy();
    }

    /**
     * Create new DNS config to store subdomain.
     */
    public addSubdomain(): void {
        this.gslb.addDnsConfig(this.gslbSitesWithAllSubdomains).then(async() => {
            await this.updateDnsVsRefs();
            this.loadSubdomainsData();
        });
    }

    /**
     * Open subdomain modal to edit.
     */
    public editSubdomain(subdomain: string): void {
        this.gslb.editSubdomain(subdomain, this.gslbSitesWithAllSubdomains).then(async() => {
            await this.updateDnsVsRefs();
            this.loadSubdomainsData();
        });
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }

    /**
     * Set name of tree node which user has hovered to highlight.
     */
    public updateHoveredNodeName(name?: string): void {
        this.hoveredTreeNode = name;
    }

    /**
     * Display subdomain remove confirmation dialog.
     */
    public showRemoveSubdomainConfirmation(subdomains: string[]): void {
        return this.dialogService.add({
            id: SUBDOMAIN_REMOVE_CONFIRMATION_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                customHeader: this.l10nService.getMessage(l10nKeys.deleteSubdomainHeader),
                onConfirm: () => {
                    this.dialogService.remove(SUBDOMAIN_REMOVE_CONFIRMATION_DIALOG_ID);
                    this.removeSubdomains(subdomains);
                },
                onClose: () => {
                    this.dialogService.remove(SUBDOMAIN_REMOVE_CONFIRMATION_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Load subdomains data for tree and list view.
     */
    private loadSubdomainsData(): void {
        this.subdomainList = this.getSubdomainsGridData();
        this.subdomainTreeList = this.getSubdomainsTreeData();

        this.uniqueGslbSitesCount = this.subdomainList
            .reduce((sites: string[], subdomain: ISubdomainGridData) => {
                sites = sites.concat(subdomain.siteNames);

                // Remove duplicate site names.
                return [...new Set(sites)];
            }, []).length;

        this.uniqueDnsVsesCount = Object.keys(this.dnsVsStatusHash).length;
    }

    /**
     * Remove subdomains from all gslb sites.
     */
    private async removeSubdomains(subdomains: string[]): Promise<void> {
        subdomains.forEach((subdomain: string) => {
            this.gslb.removeSubdomain(subdomain);
        });

        await this.gslb.save();
        await this.updateDnsVsRefs();
        this.loadSubdomainsData();
    }

    /**
     * Update DnsVsRefs field from site's dns_vses.
     */
    private async updateDnsVsRefs(): Promise<void> {
        const { sites } = this.gslb.config;

        for (const site of sites.config) {
            const { dns_vses: dnsVses } = site.config;

            const dnsVsCollection: GSLBVSCollection = new this.GSLBVSCollection({
                gslbSiteId: site.config.cluster_uuid,
                gslbTenant: this.gslb.getTenantId(),
                params: {
                    fields: 'name,tenant_ref',
                    'application_profile_ref.type':
                        ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS,
                    headers_: { 'X-Avi-Internal-All-Tenants': true },
                },
            });

            /**
             * Site's config contains only dns_vs_uuid. In order to show the name of each DNS VS.
             * We need to load dnVsCollection for each site based on site's cluster_uuid.
             */
            // eslint-disable-next-line no-await-in-loop
            await dnsVsCollection.load();

            dnsVses.config.forEach((dnsVs: GslbSiteDnsVsConfigItem) => {
                dnsVs.config.dnsVsRef =
                    dnsVsCollection.getItemById(dnsVs.config.dns_vs_uuid)?.getRef();
            });

            dnsVsCollection.destroy();
        }
    }

    /**
     * Return data to render subdomains grid in list view.
     */
    private getSubdomainsGridData(): ISubdomainGridData[] {
        const subdomainsData: ISubdomainGridData[] = [];
        const subdomainsList = this.gslb.getDNSDomainNames(true);

        subdomainsList.forEach(subdomain => {
            const domainRow: ISubdomainGridData = {};

            domainRow.subdomain = subdomain;
            domainRow.siteNames = [];
            domainRow.dnsVsNames = [];

            const filteredSites = this.gslb.getSitesFilterByDomain(subdomain);

            filteredSites.forEach((site: GslbSiteConfigItem) => {
                domainRow.siteNames.push(site.config.name);

                const dnsVsNames = site.getDnsVsRefsByDomain(subdomain)
                    .map(dnsVsRef => (dnsVsRef ? this.stringService.name(dnsVsRef) : '-'));

                domainRow.dnsVsNames.push(...dnsVsNames);
            });
            subdomainsData.push(domainRow);
        });

        return subdomainsData;
    }

    /**
     * Return data to render subdomains Tree.
     */
    private getSubdomainsTreeData(): ITreeNode[] {
        const subdomainTreeList: ITreeNode[] = [];
        const subdomainsList = this.gslb.getDNSDomainNames(true);

        subdomainsList.forEach(subdomain => {
            const parentTreeNode: ITreeNode = {
                data: {
                    name: subdomain,
                },
                children: [],
            };

            const filteredSites = this.gslb.getSitesFilterByDomain(subdomain);

            filteredSites.forEach((site: GslbSiteConfigItem) => {
                const siteTreeNode: ITreeNode = {
                    data: {
                        name: site.config.name,
                        status: this.siteStatusHash[site.config.cluster_uuid],
                    },
                    children: [],
                };

                // DnsVses which host all subdomains.
                site.getDnsVsRefsByDomain('-')
                    .forEach(dnsVsRef => {
                        const dnsVsTreeNode: ITreeNode = {
                            data: {
                                name: dnsVsRef ? this.stringService.name(dnsVsRef) : '-',
                                hostAllSubdomains: true,
                                status: dnsVsRef ?
                                    this.dnsVsStatusHash[this.stringService.slug(dnsVsRef)] : null,
                            },
                        };

                        siteTreeNode.children.push(dnsVsTreeNode);
                    });

                // DnsVses which host selected subdomain.
                site.getDnsVsRefsByDomain(subdomain, false)
                    .forEach(dnsVsRef => {
                        const dnsVsTreeNode: ITreeNode = {
                            data: {
                                name: dnsVsRef ? this.stringService.name(dnsVsRef) : '-',
                                hostAllSubdomains: false,
                                status: dnsVsRef ?
                                    this.dnsVsStatusHash[this.stringService.slug(dnsVsRef)] : null,
                            },
                        };

                        siteTreeNode.children.push(dnsVsTreeNode);
                    });

                parentTreeNode.children?.push(siteTreeNode);
            });

            subdomainTreeList.push(parentTreeNode);
        });

        return subdomainTreeList;
    }

    /**
     * Intialize and load the Gslb inventory collection with gslb data.
     */
    private async loadGslbInventoryCollection(): Promise<void> {
        this.gslbInventoryCollection = new this.GSLBInventoryCollection({
            gslb: this.gslb,
            isStatic: true,
        });

        this.gslbInventoryCollection.bind('collectionLoadSuccess', this.populateRuntimeInfo);

        await this.gslbInventoryCollection.load();
    }

    /**
     * Populate runtime status of gslb sites and dnsVses.
     */
    private populateRuntimeInfo = (): void => {
        const sitesRuntimeData = this.gslbInventoryCollection.getRuntimeSitesData();

        sitesRuntimeData.forEach(site => {
            const {
                cluster_uuid: clusterUuid,
                oper_status: operStatus,
                dns_info: dnsInfo,
            } = site.site_info;

            const { dns_vs_states: dnsVsStates } = dnsInfo;

            this.siteStatusHash[clusterUuid] = operStatus;

            dnsVsStates?.forEach(dnsVs => {
                this.dnsVsStatusHash[dnsVs.uuid] = dnsVs.oper_status;
            });
        });

        const allSubdomainSites = this.gslb.getSitesWithAllSubdomainsEnabled();

        this.gslbSitesWithAllSubdomains = allSubdomainSites.map((gslbSite: GslbSiteConfigItem) => {
            return {
                siteName: gslbSite.config.name,
                dnsVsNames: gslbSite.config.dns_vses.config
                    .filter(dnsVs => !dnsVs.config.domain_names?.length)
                    .map(dnsVs => this.stringService.name(dnsVs.config.dnsVsRef)),
                siteStatus: this.gslbInventoryCollection
                    .getOperStatusOfSite(gslbSite.config.cluster_uuid),
            };
        });
    };
}

@NgModule({
    declarations: [
        GslbSubdomainsGridComponent,
        GslbSubdomainsListPageComponent,
        GslbSubdomainsTreeComponent,
        GslbSubdomainsTreeNodeComponent,
    ],
    imports: [
        AviCardModule,
        AviFormsModule,
        ClrSpinnerModule,
        DataGridModule,
        SharedModule,
        VIPModule.forChild(),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
/* eslint-disable-next-line no-unused-vars */
class GslbModule {}
