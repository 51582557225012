/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import template from './gslb-replication-status-popover.component.html';
import './gslb-replication-status-popover.component.less';
import * as l10n from './gslb-replication-status-popover.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   GslbReplicateStatusPopover component renders the replication status.
 *
 * @author tmaridi
 */
class GslbReplicateStatusPopoverController {
    /**
     * Representing current data of the Gslb site configuration.
     */
    public row: any;

    /**
     * Used to store the keys in the l10n file.
     */
    public l10nKeys: {};

    constructor(
        private readonly l10nService: L10nService,
    ) {
        this.l10nKeys = l10nKeys;
        this.l10nService.registerSourceBundles(dictionary);
    }

    public get isSinglePendingObjects() : boolean {
        return this.row.replicationStatus.pending_object_count === 1;
    }
}

GslbReplicateStatusPopoverController.$inject = [
    'l10nService',
];

export const gslbReplicationStatusPopoverOptions = {
    bindings: {
        row: '<',
    },
    controller: GslbReplicateStatusPopoverController,
    template,
};
