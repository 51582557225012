/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-pool-member-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const enablePoolMemberCheckboxLabel = `${prefix}.enablePoolMemberCheckboxLabel`;
export const ratioInputPlaceholder = `${prefix}.ratioInputPlaceholder`;
export const hostNameInputLabel = `${prefix}.hostNameInputLabel`;
export const hostNameInputPlaceholder = `${prefix}.hostNameInputPlaceholder`;
export const publicAddressInputLabel = `${prefix}.publicAddressInputLabel`;
export const publicAddressInputPlaceholder = `${prefix}.publicAddressInputPlaceholder`;
export const thirdPartySiteClusterControllerDropdownLabel = `${prefix}.thirdPartySiteClusterControllerDropdownLabel`;
export const thirdPartySiteClusterControllerDropdownPlaceholder = `${prefix}.thirdPartySiteClusterControllerDropdownPlaceholder`;
export const preferenceOrderLabel = `${prefix}.preferenceOrderLabel`;
export const preferenceOrderInputPlaceholder = `${prefix}.preferenceOrderInputPlaceholder`;
export const vsIpAlreadyPresent = `${prefix}.vsIpAlreadyPresent`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Pool Member',
    [modalTitleNew]: 'Create Pool Member',
    [enablePoolMemberCheckboxLabel]: 'Enable Pool Member',
    [ratioInputPlaceholder]: 'Enter Ratio',
    [hostNameInputLabel]: 'Hostname',
    [hostNameInputPlaceholder]: 'Enter Hostname',
    [publicAddressInputLabel]: 'Public IP(v4/v6) Address',
    [publicAddressInputPlaceholder]: 'Enter Address',
    [thirdPartySiteClusterControllerDropdownLabel]: 'Third-party Site Cluster Controller',
    [thirdPartySiteClusterControllerDropdownPlaceholder]: 'Select Site',
    [preferenceOrderLabel]: 'Preference Order',
    [preferenceOrderInputPlaceholder]: 'Enter Preference Order',
    [vsIpAlreadyPresent]: 'IP(v4/v6) Address or FQDN already present and this unique constraints (IP, FQDN) has duplicated value.',
};
