/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './waf-user-overrides-badge.l10n';
import './waf-user-overrides-badge.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WAF overrides badge, shown for WAF groups and rules.
 * @author alextsg
 */
@Component({
    selector: 'waf-user-overrides-badge',
    templateUrl: './waf-user-overrides-badge.component.html',
})
export class WafUserOverridesBadgeComponent {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
