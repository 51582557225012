/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    HealthMonitorHttp,
    HealthMonitorUdp,
} from 'object-types';

import {
    HealthMonitorType,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    HealthMonitorHttpConfigItem,
    HealthMonitorImapConfigItem,
    HealthMonitorItem,
    HealthMonitorPop3ConfigItem,
} from 'ajs/modules/healthmonitor/factories';

import * as globalL10n from 'global-l10n';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as l10n from './health-monitor-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Modal Component.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'health-monitor-modal',
    templateUrl: './health-monitor-modal.component.html',
})
export class HealthMonitorModalComponent implements OnInit {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * HealthMonitor object type.
     */
    public objectType: string;

    /**
     * Flag to check if the name field is editable.
     */
    public isNameEditable = false;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Health Monitor Modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitorHttp,
        HealthMonitorUdp,
    };

    /**
     * Hash of Health Monitor Types.
     */
    public readonly healthMonitorTypesHash = {
        HEALTH_MONITOR_PING: HealthMonitorType.HEALTH_MONITOR_PING,
        HEALTH_MONITOR_DNS: HealthMonitorType.HEALTH_MONITOR_DNS,
        HEALTH_MONITOR_EXTERNAL: HealthMonitorType.HEALTH_MONITOR_EXTERNAL,
        HEALTH_MONITOR_RADIUS: HealthMonitorType.HEALTH_MONITOR_RADIUS,
        HEALTH_MONITOR_SIP: HealthMonitorType.HEALTH_MONITOR_SIP,
        HEALTH_MONITOR_HTTP: HealthMonitorType.HEALTH_MONITOR_HTTP,
        HEALTH_MONITOR_HTTPS: HealthMonitorType.HEALTH_MONITOR_HTTPS,
        HEALTH_MONITOR_TCP: HealthMonitorType.HEALTH_MONITOR_TCP,
        HEALTH_MONITOR_UDP: HealthMonitorType.HEALTH_MONITOR_UDP,
        HEALTH_MONITOR_SCTP: HealthMonitorType.HEALTH_MONITOR_SCTP,
        HEALTH_MONITOR_SMTP: HealthMonitorType.HEALTH_MONITOR_SMTP,
        HEALTH_MONITOR_IMAPS: HealthMonitorType.HEALTH_MONITOR_IMAPS,
        HEALTH_MONITOR_IMAP: HealthMonitorType.HEALTH_MONITOR_IMAP,
        HEALTH_MONITOR_SMTPS: HealthMonitorType.HEALTH_MONITOR_SMTPS,
        HEALTH_MONITOR_POP3: HealthMonitorType.HEALTH_MONITOR_POP3,
        HEALTH_MONITOR_POP3S: HealthMonitorType.HEALTH_MONITOR_POP3S,
        HEALTH_MONITOR_LDAP: HealthMonitorType.HEALTH_MONITOR_LDAP,
        HEALTH_MONITOR_LDAPS: HealthMonitorType.HEALTH_MONITOR_LDAPS,
        HEALTH_MONITOR_FTP: HealthMonitorType.HEALTH_MONITOR_FTP,
        HEALTH_MONITOR_FTPS: HealthMonitorType.HEALTH_MONITOR_FTPS,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for sub config of HTTP/HTTPS Health Monitor Types.
     */
    public get healthMonitorTypeConfig(): HealthMonitorHttpConfigItem {
        const { healthMonitorTypeConfig } = this.editable;

        return healthMonitorTypeConfig;
    }

    /**
     * Get sub config for IMAPS Health Monitor Types.
     */
    public get healthMonitorImapTypeConfig(): HealthMonitorImapConfigItem {
        const { healthMonitorImapTypeConfig } = this.editable;

        return healthMonitorImapTypeConfig;
    }

    /**
     * Getter for sub config of POP3 Health Monitor Types.
     */
    public get healthMonitorPop3TypeConfig(): HealthMonitorPop3ConfigItem {
        const { healthMonitorPop3TypeConfig } = this.editable;

        return healthMonitorPop3TypeConfig;
    }

    /**
     * Getter for TCP Monitor message item.
     */
    public get tcpMonitor(): MessageItem<IHealthMonitorTcp> {
        const { tcpMonitor } = this.editable;

        return tcpMonitor;
    }

    /**
     * Getter for UDP Monitor message item.
     */
    public get udpMonitor(): MessageItem<IHealthMonitorUdp> {
        const { udpMonitor } = this.editable;

        return udpMonitor;
    }

    /**
     * @override
     * Check if the name field can be edited. For the system default health monitors
     * the name change is not allowed.
     */
    public ngOnInit(): void {
        const { editable } = this;

        this.objectType = editable.messageType;
        this.isNameEditable = editable.id ? !editable.isProtected() : true;
    }
}
