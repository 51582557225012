/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { rootStoreReducer } from './root-store.reducer';
import { rootKey } from './root-store.selectors';

import { ControllerInitialDataStoreModule } from
    './controller-initial-data-store/controller-initial-data-store.module';

import { ControllerSiteStoreModule } from './controller-site-store/controller-site-store.module';
import { IInfraCloudStateModule } from './infra-cloud-store/infra-cloud-store.module';
import { UserAccountModule } from './user-account/user-account.module';
import { UserPreferencesModule } from './user-preferences/user-preferences.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot({ [rootKey]: rootStoreReducer }),
        ControllerInitialDataStoreModule,
        IInfraCloudStateModule,
        ControllerSiteStoreModule,
        UserAccountModule,
        UserPreferencesModule,
    ],
})
export class RootStoreModule {}
