/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name ValidatePortDirective
 * @description Directive for validating ports based on port limits defined for a service.
 *  Works similarly to the AngularJS version, except now we can validate
 *  the hyphen(-) separated input ranges as well. This directive considers
 *  comma separated values as individual items. An item can consist of a port or a range.
 *  For example input - 80, 90-100, 110 is valid.
 *  Given than service port limit is between 80 to 120.
 *
 * @example
 *      <input
 *          name="dummy_field"
 *          [(ngModel)]="config.dummy_field"
 *          [validatePort]="servicePorts"
 *      />
 *
 * @author Abhinesh Gour
 */

import {
    Directive,
    Input,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { IService } from 'generated-types';
import { isUndefined } from 'underscore';
import { portValidator } from 'ng/modules/avi-forms/validators';

@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: ValidatePortDirective,
    }],
    selector: '[validatePort][formControlName],[validatePort][formControl],[validatePort][ngModel]',
})
export class ValidatePortDirective implements Validator {
    /**
     * List of services containing port and port range.
     */
    @Input('validatePort')
    public validateServicePorts: IService[];

    /**
     * @override
     * Check the ports if the value is defined, since we don't want to invalidate a form with
     * empty input fields that are optional.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) ?
            portValidator(this.validateServicePorts)(control) :
            null;
    }
}
