/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import * as l10n from './vs-log-cinematic-sub-section-dns-request.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';
import { DnsMessageType } from '../../vs-log-cinematic.types';
import './vs-log-cinematic-sub-section-dns-request.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the DNS Request sub-section in DNS section under VS Log cinematic
 *     view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-dns-request',
    templateUrl: './vs-log-cinematic-sub-section-dns-request.component.html',
})
export class VsLogCinematicSubSectionDnsRequestComponent {
    public readonly dnsRequestInfo$ = this.vsLogCinematicStore.dnsRequestInfo$;

    public readonly connectionLog$ = this.vsLogCinematicStore.connectionLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Dns message type helps identifying the current component as dns request.
     */
    public readonly dnsMessageType = DnsMessageType.REQUEST;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('DnsEntryType'),
        ...this.schemaService.getEnumValueLabelsHash('DnsOpcode'),
    };

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }
}
