<credentials-verification-dialog
    [disableSubmit]="form.invalid"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="errors"
    [busy]="busy"
>
    <credentials-verification-dialog_header>
        <h3>{{ l10nKeys.setAzureCredentialsHeader | vtranslate }}</h3>
    </credentials-verification-dialog_header>
    <credentials-verification-dialog_form>
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
            class="clr-wrapper"
        >
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.IpamDnsAzureProfile }}"
                    fieldName="subscription_id"
                    for="subscription_id"
                    required
                >
                    {{ l10nKeys.subscriptionIdInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="subscription_id"
                    placeholder="{{ l10nKeys.subscriptionIdInputPlaceholder | vtranslate }}"
                    name="subscription_id"
                    [(ngModel)]="config.subscription_id"
                    required
                />
            </avi-input-container>
            <avi-radio-container>
                <avi-radio
                    name="type"
                    [(ngModel)]="config.type"
                    [value]="AzureCredentialsTypeEnum.USERPASS"
                >
                    {{ l10nKeys.useUsernamePasswordCredentialsOption | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="type"
                    [(ngModel)]="config.type"
                    [value]="AzureCredentialsTypeEnum.SERVICE_PRINCIPAL"
                >
                    {{ l10nKeys.useApplicationIdCredentialsOption | vtranslate }}
                </avi-radio>
            </avi-radio-container>

            <ng-container *ngIf="config.type === AzureCredentialsTypeEnum.USERPASS">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureUserPassCredentials }}"
                                fieldName="username"
                                for="username"
                                required
                            >
                                {{ l10nKeys.usernameInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="username"
                                placeholder="{{ l10nKeys.usernameInputPlaceholder | vtranslate }}"
                                name="username"
                                [(ngModel)]="config.azure_userpass.username"
                                required
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureUserPassCredentials }}"
                                fieldName="password"
                                for="password"
                                required
                            >
                                {{ l10nKeys.passwordInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="password"
                                type="password"
                                placeholder="{{ l10nKeys.passwordInputPlaceholder | vtranslate }}"
                                name="password"
                                [(ngModel)]="config.azure_userpass.password"
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AzureUserPassCredentials }}"
                        fieldName="tenant_name"
                        for="tenant_name"
                        required
                    >
                        {{ l10nKeys.tenantNameInputLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_name"
                        name="tenant_name"
                        [(ngModel)]="config.azure_userpass.tenant_name"
                        placeholder="{{ l10nKeys.tenantNameInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
            </ng-container>

            <ng-container *ngIf="config.type === AzureCredentialsTypeEnum.SERVICE_PRINCIPAL">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                                fieldName="application_id"
                                for="application_id"
                                required
                            >
                                {{ l10nKeys.applicationIdInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="application_id"
                                name="application_id"
                                [(ngModel)]="config.azure_serviceprincipal.application_id"
                                placeholder="{{ l10nKeys.applicationIdInputPlaceholder | vtranslate }}"
                                required
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                                fieldName="authentication_token"
                                for="authentication_token"
                                required
                            >
                                {{ l10nKeys.authenticationTokenInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="password"
                                id="authentication_token"
                                name="authentication_token"
                                [(ngModel)]="config.azure_serviceprincipal.authentication_token"
                                placeholder="{{ l10nKeys.authenticationTokenInputPlaceholder | vtranslate }}"
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AzureServicePrincipalCredentials }}"
                        fieldName="tenant_id"
                        for="tenant_id"
                        required
                    >
                        {{ l10nKeys.tenantIdInputLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_id"
                        name="tenant_id"
                        [(ngModel)]="config.azure_serviceprincipal.tenant_id"
                        placeholder="{{ l10nKeys.tenantIdInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
            </ng-container>
        </form>
    </credentials-verification-dialog_form>
</credentials-verification-dialog>
