<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="ipamDnsProfilesGridConfig">
            <ng-template
                #ipamDnsProfilesExpanderTemplateRef
                let-row
            >
                <ipam-dns-profiles-expander [ipamProfile]="row"></ipam-dns-profiles-expander>
            </ng-template>
        </avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
