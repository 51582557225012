/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auto-scale';
const componentName = 'server-auto-scale-policy-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleMinSize = `${prefix}.columnTitleMinSize`;
export const columnTitleMaxSize = `${prefix}.columnTitleMaxSize`;
export const columnTitleIntelligentAutoScale = `${prefix}.columnTitleIntelligentAutoScale`;

export const ENGLISH = {
    [headerLabel]: 'Server AutoScale',
    [columnTitleMinSize]: 'Min Size',
    [columnTitleMaxSize]: 'Max Size',
    [columnTitleIntelligentAutoScale]: 'Intelligent AutoScale',
};
