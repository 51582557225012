/**
 * @module UpdateModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
    ClrInputModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AviCardModule } from 'nsx-alb-ui-components';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { VIPModule } from '@vmw/ngx-vip';
import { CoreModule } from 'ng/modules/core/core.module';
import { SystemModule } from 'ng/modules/system/system.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { LoaderModule } from 'ng/modules/loader/loader.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { imageCollectionProvider } from './ajs-upgraded-provider';
import { UpdateStore } from './components/update.store';

import {
    ComplianceModeConfirmationComponent,
    PrechecksListComponent,
    PrechecksListExpanderComponent,
    PrechecksProgressModalComponent,
    RecentUpdatesListComponent,
    SegImageSelectionModalComponent,
    SegUpdateGridExpanderComponent,
    SegUpdatePageComponent,
    SelectedSegListComponent,
    SystemAboutCardComponent,
    SystemUpdateAboutCardComponent,
    SystemUpdateComponent,
    SystemUpdateModalComponent,
    UpdateCardComponent,
    UpdateNodeStatusCardComponent,
    UpdateProgressCardComponent,
    UpgradeTranscriptModalComponent,
} from './components';

const updateComponents = [
    ComplianceModeConfirmationComponent,
    PrechecksListComponent,
    PrechecksListExpanderComponent,
    PrechecksProgressModalComponent,
    RecentUpdatesListComponent,
    SegImageSelectionModalComponent,
    SelectedSegListComponent,
    SegUpdateGridExpanderComponent,
    SegUpdatePageComponent,
    SystemAboutCardComponent,
    SystemUpdateAboutCardComponent,
    SystemUpdateComponent,
    SystemUpdateModalComponent,
    UpdateCardComponent,
    UpdateNodeStatusCardComponent,
    UpdateProgressCardComponent,
    UpgradeTranscriptModalComponent,
];

/**
 * Module containing all upgrade stuff (angular only).
 */
@NgModule({
    declarations: [
        ...updateComponents,
    ],
    exports: [
        RecentUpdatesListComponent,
        UpgradeTranscriptModalComponent,
    ],
    imports: [
        AviCardModule,
        AviFormsModule,
        ClrCheckboxModule,
        ClrDatagridModule,
        ClrDropdownModule,
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        CoreModule,
        FormsModule,
        DialogModule,
        SharedModule,
        SystemModule,
        VIPModule,
        LoaderModule,
        DataGridModule,
    ],
    providers: [
        imageCollectionProvider,
        UpdateStore,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class UpdateModule { }
