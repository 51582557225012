/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * Name of the event emitted.
 */
const eventName = 'closeChange';

/**
 * @ngdoc directive
 * @name cdsAlertOnCloseChange
 * @restrict A
 * @description
 *     Click event is not propagated outside the CDS alert. To pass a callback function to CDS
 *     alert from angularJs component, we need to include this directive.
 *     We do this by calling our function on closeChange event.
 * @example
 *      <cds-alert cds-alert-on-close-change="$ctrl.closeAlert()"> </cds-alert>
 *
 * @author Amit Dubey, Aravindh Nagarajan
 */
export function cdsAlertOnCloseChangeDirective(): angular.IDirective {
    /**
     * Controller for cdsAlertOnCloseChange directive
     */
    class CdsAlertOnCloseChangeCtrl {
        private element: JQuery;
        private onCloseChange: EventListenerOrEventListenerObject;

        constructor($element: JQuery) {
            this.element = $element;
        }

        /**
         * @override
         * 'closeChange' event is triggered by <cds-alert> on click of close button within
         * We add an event listener to it to call the desired function
         */
        public $onInit(): void {
            this.element[0].addEventListener(eventName, this.onCloseChange);
        }

        /**
         * @override
         */
        public $onDestroy(): void {
            this.element[0].removeEventListener(eventName, this.onCloseChange);
        }
    }

    CdsAlertOnCloseChangeCtrl.$inject = [
        '$element',
    ];

    return {
        bindToController: {
            onCloseChange: '&cdsAlertOnCloseChange',
        },
        controller: CdsAlertOnCloseChangeCtrl,
        restrict: 'A',
    };
}
