/**
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { IRoleFilterMatchLabel } from 'generated-types';
import { normalizeValue } from 'ng/shared/utils';

/**
 * Return validatorFunction for rbacKeyUniquenessValidation directive.
 * Test to see whether the current input key has duplications within the list.
 *
 * @author Zhiqian Liu
 */
export function rbacKeyUniquenessValidator(
    rbacEntryList: IRoleFilterMatchLabel[],
    currentIndex: number,
): ValidatorFn {
    /**
     * Return null if the input is a unique key in the RBAC list.
     * Return ValidationError if the input is a duplicate key in the RBAC list.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value: currentKey } = control;
        const normalizedList: IRoleFilterMatchLabel[] | undefined = normalizeValue(rbacEntryList);

        // 1. empty string doesn't count as an existent key
        // 2. empty or null list doesn't need to be checked for key duplications
        if (currentKey === '' || !normalizedList) {
            return null;
        }

        // The rbacEntryList being tested on won't be updated until this individual input
        // validation is done. If keys are found paired with the same index, it means the
        // validation is practiced on the same element hence not a duplication. Otherwise
        // an duplication is found.
        const keyDuplicated = rbacEntryList.some(({ key: previousKey }, previousIndex) => {
            return previousKey === currentKey && previousIndex !== currentIndex;
        });

        return keyDuplicated ? {
            keyDuplicated,
        } : null;
    };
}
