/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-dns-virtual-service-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const dnsVirtualServiceLabel = `${prefix}.dnsVirtualServiceLabel`;
export const dnsVirtualServicePlaceholder = `${prefix}.dnsVirtualServicePlaceholder`;
export const hostAllSubdomainsOptionLabel = `${prefix}.hostAllSubdomainsOptionLabel`;
export const subdomainsLabel = `${prefix}.subdomainsLabel`;
export const subdomainLabel = `${prefix}.subdomainLabel`;
export const selectSubdomainPlaceholder = `${prefix}.selectSubdomainPlaceholder`;

export const ENGLISH = {
    [modalHeader]: 'Add Placement',
    [dnsVirtualServiceLabel]: 'DNS Virtual Service',
    [dnsVirtualServicePlaceholder]: 'Select DNS Virtual Service',
    [hostAllSubdomainsOptionLabel]: 'Host All Subdomains',
    [subdomainsLabel]: 'Subdomain ({0})',
    [subdomainLabel]: 'Subdomain',
    [selectSubdomainPlaceholder]: 'Select Subdomain',
};
