/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 *  @ngdoc directive
 *  @name uniqueValueInputValidation
 *  @module directives/uniqueValueInputValidation
 *  @author Sneha V
 *  @restrict A
 *  @desc
 *      This directive takes a list of values not to be repeated
 *      and invalidates the input if record is already present in the list
 *      When the duplicate record is found, class ng-invalid-unique-value-input is set
 *      and the input field is highlighted in red.
 *      When no duplicate values, class ng-valid-unique-value-input is set to the input field
 *
 *  @param {any[]} uniqueValueInputValidation -
 *      array of unique values(type similar to ngModel) to compare against
 *
 *  @example
 *  <input
 *     type="text"
 *     ng-model="config.fieldName"
 *     parse-any-ip
 *     unique-value-input-validation="options">
 */

function uniqueValueInputValidationDirectiveFactory() {
    function uniqueValueInputValidationLink(scope, elem, attrs, { ngModelCtrl }) {
        /**
         * modelValue goes through all the formatters assigned to the input value in reverse order
         * and returns a viewValue in a comparable format.
         *
         * @param {*} modelValue - modelValue
         */
        const comparator = modelValue => {
            return ngModelCtrl.$formatters
                .reduceRight((acc, formatter) => formatter(acc), modelValue);
        };

        const uniqueValueInputValidator = modelValue => {
            if (angular.isObject(modelValue)) {
                modelValue = comparator(modelValue);
            }

            //if modelValue is still an object after executing comparator
            if (angular.isObject(modelValue)) {
                throw new Error('modelValue not in comparable form to compare for its uniqueness');
            }

            let isDuplicate = false;

            for (let i = 0; i < scope.uniqueValues.length; i++) {
                if (modelValue === comparator(scope.uniqueValues[i])) {
                    isDuplicate = true;
                    break;
                }
            }

            return !isDuplicate;
        };

        ngModelCtrl.$validators.uniqueValueInput = uniqueValueInputValidator;
    }

    return {
        restrict: 'A',
        require: {
            ngModelCtrl: 'ngModel',
        },
        scope: {
            uniqueValues: '<uniqueValueInputValidation',
        },
        link: uniqueValueInputValidationLink,
    };
}

angular.module('avi/component-kit')
    .directive('uniqueValueInputValidation', uniqueValueInputValidationDirectiveFactory);
