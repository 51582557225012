/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviPermissionResource } from 'generated-types';

/**
 * Permissions that will affect {@link permissionDependencyHash}.
 */
const vsRelatedPermissions = [
    AviPermissionResource.PERMISSION_CLOUD,
    AviPermissionResource.PERMISSION_NETWORKPROFILE,
    AviPermissionResource.PERMISSION_APPLICATIONPROFILE,
    AviPermissionResource.PERMISSION_APPLICATIONPERSISTENCEPROFILE,
    AviPermissionResource.PERMISSION_HEALTHMONITOR,
    AviPermissionResource.PERMISSION_ANALYTICSPROFILE,
    AviPermissionResource.PERMISSION_IPADDRGROUP,
    AviPermissionResource.PERMISSION_STRINGGROUP,
    AviPermissionResource.PERMISSION_SSLPROFILE,
    AviPermissionResource.PERMISSION_AUTHPROFILE,
    AviPermissionResource.PERMISSION_PKIPROFILE,
    AviPermissionResource.PERMISSION_SSLKEYANDCERTIFICATE,
];

/**
 * Privilege resources that will affect {@link permissionDependencyHash}.
 */
const cloudRelatedPermissions = [
    AviPermissionResource.PERMISSION_CLOUD,
];

/**
 * Permissions that will be affected by permissions defined in {@link vsRelatedPermissions}
 * and {@link cloudRelatedPermissions}.
 */
export const permissionDependencyHash = {
    PERMISSION_VIRTUALSERVICE: vsRelatedPermissions,
    PERMISSION_TRAFFIC_CAPTURE: [AviPermissionResource.PERMISSION_VIRTUALSERVICE],
    PERMISSION_POOL: [AviPermissionResource.PERMISSION_VIRTUALSERVICE].concat(vsRelatedPermissions),
    PERMISSION_POOLGROUP: [AviPermissionResource.PERMISSION_VIRTUALSERVICE]
        .concat(vsRelatedPermissions),
    PERMISSION_NETWORKSECURITYPOLICY: vsRelatedPermissions,
    PERMISSION_HTTPPOLICYSET: vsRelatedPermissions,
    PERMISSION_SERVICEENGINE: cloudRelatedPermissions,
    PERMISSION_SERVICEENGINEGROUP: cloudRelatedPermissions,
    PERMISSION_NETWORK: cloudRelatedPermissions,
    PERMISSION_VRFCONTEXT: cloudRelatedPermissions,
};

/**
 * Defines which operations are allowed in assigned permission.
 * This will restrict read and write permissions to a role.
 */
export const permissionLevel1 = {
    canRead: false,
    canWrite: false,
};

/**
 * Defines which operations are allowed in assigned permission.
 * This will restrict write permission to a role but allows read operation.
 */
const permissionLevel2 = {
    canWrite: false,
    canRead: true,
};

/**
 * This permissions depend on other permissions to set their permission levels.
 * Used to set role access properties for given role node.
 */
// TODO: This doesn't address cases where a single permission affects different permissions
// on different levels. A affects B and C, but B is read-only and C is no-access.
export const dynamicPermissionLevels = {
    PERMISSION_CLOUD: permissionLevel2,
    PERMISSION_VIRTUALSERVICE: permissionLevel1,
    PERMISSION_NETWORKPROFILE: permissionLevel2,
    PERMISSION_APPLICATIONPROFILE: permissionLevel2,
    PERMISSION_APPLICATIONPERSISTENCEPROFILE: permissionLevel2,
    PERMISSION_HEALTHMONITOR: permissionLevel2,
    PERMISSION_ANALYTICSPROFILE: permissionLevel2,
    PERMISSION_IPADDRGROUP: permissionLevel2,
    PERMISSION_STRINGGROUP: permissionLevel2,
    PERMISSION_SSLPROFILE: permissionLevel2,
    PERMISSION_AUTHPROFILE: permissionLevel2,
    PERMISSION_PKIPROFILE: permissionLevel2,
    PERMISSION_SSLKEYANDCERTIFICATE: permissionLevel2,
    PERMISSION_VRFCONTEXT: permissionLevel1,
};
