<div
    class="full-modal-breadcrumb clr-wrapper"
    [ngClass]="isActive && 'full-modal-breadcrumb--active'"
>
    <div class="full-modal-breadcrumb__text-container">
        <div
            class="full-modal-breadcrumb__name"
            [ngClass]="isActive && 'full-modal-breadcrumb__name--active'"
        >
            {{ name }}
        </div>
        <div class="full-modal-breadcrumb__description sel-full-modal-breadcrumb-description">
            {{ description }}
        </div>
    </div>
    <div [hidden]="isActive">
        <cds-icon
            shape="lock"
            size="24"
        ></cds-icon>
    </div>
</div>
