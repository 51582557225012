<ng-container *ngrxLet="dnsRequestInfo$ as dnsRequestInfo">
    <div class="vs-log-cinematic-sub-section-dns-request__column-group">
        <!-- 1st column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.dnsClientLocationLabel | vtranslate }}
            </div>
            <ng-container *ngIf="dnsRequestInfo.client_location?.name as location; else na">
                <div
                    vsLogNationalFlag
                    [countryCode]="location"
                ></div>
                <span
                    vsLogFilter
                    property="dns_request.client_location.name"
                    [value]="location"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ location | countryCodeToName }}
                </span>
            </ng-container>

            <ng-container *ngrxLet="connectionLog$ as connectionLog">
                <div vsLogCinematicFieldTitle>{{ l10nKeys.domainNameLabel | vtranslate }}</div>
                <span
                    vsLogPartialSelectionFilter
                    property="dns_fqdn"
                    [value]="domainName"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="connectionLog.dns_fqdn as domainName; else na"
                ></span>
            </ng-container>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.idLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_request.identifier"
                [value]="dnsRequestInfo.identifier"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsRequestInfo.identifier !== undefined; else na"
            >
                {{ dnsRequestInfo.identifier }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.authenticDataLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_request.authentic_data"
                [value]="dnsRequestInfo.authentic_data"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsRequestInfo.authentic_data !== undefined; else na"
            >
                {{ dnsRequestInfo.authentic_data }}
            </span>
        </div>

        <!-- 2nd column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.questionCountLabel | vtranslate }}
            </div>
            <span
                vsLogFilter
                property="dns_request.question_count"
                [value]="dnsRequestInfo.question_count"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsRequestInfo.question_count !== undefined; else na"
            >
                {{ dnsRequestInfo.question_count }}
            </span>

            <ng-container *ngrxLet="connectionLog$ as connectionLog">
                <div vsLogCinematicFieldTitle>{{ l10nKeys.rxBytesLabel | vtranslate }}</div>
                <span
                    vsLogFilterList
                    property="rx_bytes"
                    [value]="connectionLog.rx_bytes"
                    *ngIf="connectionLog.rx_bytes !== undefined; else na"
                    displayValue="{{ connectionLog.rx_bytes }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ connectionLog.rx_bytes }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}
                </span>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.txBytesLabel | vtranslate }}</div>
                <span
                    vsLogFilterList
                    property="tx_bytes"
                    [value]="connectionLog.tx_bytes"
                    *ngIf="connectionLog.tx_bytes !== undefined; else na"
                    displayValue="{{ connectionLog.tx_bytes }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ connectionLog.tx_bytes }} {{ globalL10nKeys.shortBytesLabel | vtranslate }}
                </span>
            </ng-container>
        </div>

        <!-- 3rd column -->
        <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.operationCodeLabel | vtranslate:(l10nKeys.opCodeLabel | vtranslate) }}
            </div>
            <span
                vsLogFilter
                property="dns_request.opcode"
                [value]="opCode"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsRequestInfo.opcode as opCode; else na"
            >
                {{ enumLabelHash[opCode] }}
            </span>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.recursionDesiredLabel | vtranslate }}</div>
            <span
                vsLogFilter
                property="dns_request.recursion_desired"
                [value]="dnsRequestInfo.recursion_desired"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                *ngIf="dnsRequestInfo.recursion_desired !== undefined; else na"
            >
                {{ dnsRequestInfo.recursion_desired }}
            </span>

            <!-- show only when DNS SEC is implemented -->
            <ng-container *ngIf="dnsRequestInfo.opt_record?.dnssec_ok">
                <div vsLogCinematicFieldTitle>{{ l10nKeys.checkingDeactivatedLabel | vtranslate }}</div>
                <span
                    vsLogFilter
                    property="dns_request.checking_disabled"
                    [value]="dnsRequestInfo.checking_disabled"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="dnsRequestInfo.checking_disabled !== undefined; else na"
                >
                    {{ dnsRequestInfo.checking_disabled }}
                </span>
            </ng-container>
        </div>
    </div>

    <!-- DNS Opt Record -->
    <vs-log-cinematic-dns-opt-record
        *ngIf="dnsRequestInfo.opt_record as optRecord"
        [dnsOptRecord]="optRecord"
        [dnsMessageType]="dnsMessageType"
    ></vs-log-cinematic-dns-opt-record>
</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
