/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import * as l10n from './vs-log-cinematic-sub-section-jwt.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-jwt.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying the JWT sub-section in the Authentication section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-jwt',
    templateUrl: './vs-log-cinematic-sub-section-jwt.component.html',
})
export class VsLogCinematicSubSectionJwtComponent {
    public readonly jwtLog$ = this.vsLogCinematicStore.jwtLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
