/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-third-party-site-config';
const prefix = `${moduleName}.${componentName}`;

export const overridePoolMemberRatioLabel = `${prefix}.overridePoolMemberRatioLabel`;

export const ENGLISH = {
    [overridePoolMemberRatioLabel]: 'Override Pool Member Ratio',
};
