/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    AfterContentInit,
    Component,
    ContentChild,
    OnDestroy,
    OnInit,
    Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FullModalComponentService } from '../full-modal/full-modal-component.service';

import './avi-textarea-container.component.less';

/**
 * @description Component to wrap a label (or avi-label-with-tooltip) and a textarea element, used
 *     to replace Clarity's clr-textarea-container component.
 * @author alextsg
 */
@Component({
    selector: 'avi-textarea-container',
    templateUrl: './avi-textarea-container.component.html',
})
export class AviTextareaContainerComponent implements OnInit, AfterContentInit, OnDestroy {
    /**
     * Transcluded ngControl instance.
     */
    @ContentChild(NgControl, { static: true })
    private ngControl: NgControl;

    /**
     * If true, hides this element including both the label and the input field. This is set to true
     * if the view mode is enabled and the input does not have a value, so that we don't show the
     * placeholder in view mode.
     */
    public hideInput = false;

    /**
     * Stores the current value of the input.
     */
    private value: string | number = null;

    /**
     * Subscription to the input value.
     */
    private valueChangesSubscription: Subscription;

    /**
     * If false, show the radio input element. If true, show the readonly view of the selected
     * option.
     */
    private viewMode = false;

    /**
     * Subscription to the viewMode value.
     */
    private viewModeSubscription: Subscription;

    constructor(
        @Optional()
        private readonly fullModalComponentService: FullModalComponentService,
    ) {}

    /** @override */
    public ngOnInit(): void {
        if (this.fullModalComponentService) {
            this.viewModeSubscription = this.fullModalComponentService.viewMode$
                .subscribe(viewMode => {
                    this.viewMode = viewMode;
                    this.setHideInput();
                });
        }
    }

    /** @override */
    public ngAfterContentInit(): void {
        if (this.ngControl) {
            this.valueChangesSubscription = this.ngControl.valueChanges.subscribe(value => {
                this.value = value;
                this.setHideInput();
            });
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.valueChangesSubscription?.unsubscribe();
        this.viewModeSubscription?.unsubscribe();
    }

    /**
     * Set the hideInput property.
     */
    private setHideInput(): void {
        this.hideInput = this.viewMode && this.value === undefined;
    }
}
