/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createReducer, on } from '@ngrx/store';
import * as VirtualServiceActions from './virtual-service.actions';
import { initialState, IVirtualServiceState } from './virtual-service.state';
import { areEqual } from '../utils/virtual-service-faults.utils';

export const virtualServiceReducer = createReducer(
    initialState,
    on(
        VirtualServiceActions.setFaults,
        (state: IVirtualServiceState, { payload }): IVirtualServiceState => {
            const { vsId, faults: incomingFaults } = payload;
            const { faults } = state;
            const { faults: cachedFaults } = faults[vsId] || {};

            if (!areEqual(incomingFaults, cachedFaults)) {
                return {
                    ...state,
                    faults: {
                        ...faults,
                        [vsId]: {
                            dismissed: false,
                            faults: incomingFaults,
                        },
                    },
                };
            }

            return {
                ...state,
            };
        },
    ),
    on(
        VirtualServiceActions.dismissFaults,
        (state: IVirtualServiceState, { payload: vsId }): IVirtualServiceState => {
            return {
                ...state,
                faults: {
                    ...state.faults,
                    [vsId]: {
                        ...state.faults[vsId],
                        dismissed: true,
                    },
                },
            };
        },
    ),
);
