<div class="cluster-state-info">
    <div class="cluster-state-info__cluster-status-container">
        <cds-icon
            shape="circle"
            size="14"
            [status]="clusterState | clusterStateIconStatus"
            solid
        ></cds-icon>
        <div
            class="cluster-state-info__cluster-name"
            aviTitle="{{ name }}"
        >
            {{ name }}
        </div>
        <div *ngIf="clusterState">
            {{ clusterState | enumValueLabel : 'ClusterStateValue' }}
        </div>
        <clr-spinner
            [clrSmall]="true"
            *ngIf="!clusterState"
        ></clr-spinner>
    </div>
    <div class="cluster-state-info__value-container">
        <label class="cluster-state-info__label">
            {{ l10nKeys.clusterIpv4Label | vtranslate }}
        </label>
        <div class="cluster-state-info__value">
            <avi-tooltip-icon-with-content-wrapper
                shape="circle"
                size="14"
                [status]="clusterVipStatus | clusterVipStatusIconStatus"
                [solid]="true"
            >
                <span>{{ clusterVipStatus | enumValueLabel : 'ClusterVipStatus' }}</span>
            </avi-tooltip-icon-with-content-wrapper>
            <span class="cluster-state-info__cluster-vip-address">
                {{ clusterVipAddressV4 || '-' }}
            </span>
        </div>
    </div>
    <div class="cluster-state-info__value-container">
        <label class="cluster-state-info__label">
            {{ l10nKeys.clusterIpv6Label | vtranslate }}
        </label>
        <div class="cluster-state-info__value">
            <avi-tooltip-icon-with-content-wrapper
                shape="circle"
                size="14"
                [status]="clusterVip6Status | clusterVipStatusIconStatus"
                [solid]="true"
            >
                <span>{{ clusterVip6Status | enumValueLabel : 'ClusterVipStatus' }}</span>
            </avi-tooltip-icon-with-content-wrapper>
            <span class="cluster-state-info__cluster-vip-address">
                {{ clusterVipAddressV6 || '-' }}
            </span>
        </div>
    </div>
</div>
