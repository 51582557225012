/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUserAccountState } from './user-account.state';

export const featureKey = 'userAccount';

const selectFeature = createFeatureSelector<IUserAccountState>(featureKey);

export const selectLoading = createSelector(
    selectFeature,
    ({ loading }) => loading,
);

export const selectLoaded = createSelector(
    selectFeature,
    ({ loaded }) => loaded,
);

export const selectUserAccount = createSelector(
    selectFeature,
    ({ userAccount }) => userAccount,
);

export const selectUsername = createSelector(
    selectUserAccount,
    ({ username }) => username,
);

export const selectFullName = createSelector(
    selectUserAccount,
    ({ full_name }) => full_name,
);

export const selectEmail = createSelector(
    selectUserAccount,
    ({ email }) => email,
);
