/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module DiagramModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';
import { ClrSpinnerModule } from '@clr/angular';
import { CoreModule } from '../core/core.module';

import {
    AviBarGraphComponent,
    AviBarGraphTooltipComponent,
    DoubleArrowComponent,
    LabelColorOptionalComponent,
    LabelCountLegendComponent,
    LabelCountRowComponent,
} from './components';

const components = [
    AviBarGraphTooltipComponent,
    AviBarGraphComponent,
    DoubleArrowComponent,
    LabelColorOptionalComponent,
    LabelCountLegendComponent,
    LabelCountRowComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    exports: [
        AviBarGraphComponent,
        DoubleArrowComponent,
        LabelColorOptionalComponent,
        LabelCountLegendComponent,
        LabelCountRowComponent,
    ],
    imports: [
        ClrSpinnerModule,
        CommonModule,
        CoreModule,
        VIPModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DiagramModule {}
