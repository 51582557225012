/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'cluster-node';

export const clusterNodeBreadcrumbTitle = `${prefix}.clusterNodeBreadcrumbTitle`;

export const ENGLISH = {
    [clusterNodeBreadcrumbTitle]: 'Cluster Node',
};
