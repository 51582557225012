/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const ipAddressManagementLabel = `${prefix}.ipAddressManagementLabel`;
export const enableDhcpLabel = `${prefix}.enableDhcpLabel`;
export const enableIpV6Label = `${prefix}.enableIpV6Label`;
export const subnetsGridTitle = `${prefix}.subnetsGridTitle`;

export const subnetPrefixColumnTitle = `${prefix}.subnetPrefixColumnTitle`;
export const ipAddressPoolColumnTitle = `${prefix}.ipAddressPoolColumnTitle`;
export const routingContextInputLabel = `${prefix}.routingContextInputLabel`;
export const routingContextInputPlaceholder = `${prefix}.routingContextInputPlaceholder`;
export const excludeDiscoveredSubnetsInputLabel = `${prefix}.excludeDiscoveredSubnetsInputLabel`;
export const discoveredLabel = `${prefix}.discoveredLabel`;
export const configuredLabel = `${prefix}.configuredLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Network Settings',
    [modalTitleNew]: 'New Network Settings',
    [ipAddressManagementLabel]: 'IP Address Management',
    [enableDhcpLabel]: 'Enable DHCP',
    [enableIpV6Label]: 'Enable IPv6 Auto Configuration',
    [subnetsGridTitle]: 'Subnets ({0} Configured, {1} Discovered)',
    [subnetPrefixColumnTitle]: 'Subnet Prefix',
    [ipAddressPoolColumnTitle]: 'IP Address Pool',
    [routingContextInputLabel]: 'Routing Context',
    [routingContextInputPlaceholder]: 'Select VRF',
    [excludeDiscoveredSubnetsInputLabel]: 'Exclude Discovered Subnets for Virtual Service Placement',
    [discoveredLabel]: 'Discovered',
    [configuredLabel]: 'Configured',
};
