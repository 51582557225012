/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './IpReputationTypeMatchController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name IpReputationTypeMatchController
 */
class IpReputationTypeMatchController {
    constructor($scope, l10nService) {
        $scope.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

IpReputationTypeMatchController.$inject = [
    '$scope',
    'l10nService',
];

angular.module('aviApp')
    .controller('IpReputationTypeMatchController', IpReputationTypeMatchController);
