/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    CaseCollection,
    CASE_COLLECTION_TOKEN,
} from 'ajs/modules/cportal/factories/case.collection.factory';

import {
    TechSupportCollection,
    TechSupportItem,
} from './factories';

import {
    TECH_SUPPORT_COLLECTION_TOKEN,
    TECH_SUPPORT_ITEM_TOKEN,
} from './tokens/support.token';

const supportModule = angular.module('avi/support');

const factories = [
    {
        factory: TechSupportCollection,
        name: TECH_SUPPORT_COLLECTION_TOKEN,
    },
    {
        factory: TechSupportItem,
        name: TECH_SUPPORT_ITEM_TOKEN,
    },
];

const providers = [
    {
        factory: CaseCollection,
        name: CASE_COLLECTION_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(supportModule, 'factory', name, factory);
});

providers.forEach(({ name, factory }) => {
    initAjsDependency(supportModule, 'factory', name, factory);
});
