/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    Input,
} from '@angular/core';

import { StringMatchConfigItem }
    from 'ajs/modules/match/factories/string-match.config-item.factory';

/**
 * @description readonly Match component for StringMatch.
 *
 * @author sghare
 */
@Component({
    selector: 'string-match-readonly',
    templateUrl: './string-match-readonly.component.html',
})
export class StringMatchReadonlyComponent {
    /**
     * Binding for StringMatchConfigItem.
     */
    @Input()
    public editable: StringMatchConfigItem;

    /**
     * Match label passed to the MatchDisplayWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Returns string_group_refs as comma seperated string.
     */
    public get stringGroupNames(): string {
        return this.editable.stringGroupNames.join(', ');
    }
}
