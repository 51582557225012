/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-migrate';
const prefix = `${moduleName}.${componentName}`;

export const migrateHeader = `${prefix}.migrateHeader`;
export const selectVipAddressToMigrateInputLabel = `${prefix}.selectVipAddressToMigrateInputLabel`;
export const selectVipAddressToMigrateInputPlaceholder = `${prefix}.selectVipAddressToMigrateInputPlaceholder`;
export const selectServiceEngineToMigrateInputLabel = `${prefix}.selectServiceEngineToMigrateInputLabel`;
export const selectServiceEngineToMigrateInputPlaceholder = `${prefix}.selectServiceEngineToMigrateInputPlaceholder`;
export const primaryDataLabel = `${prefix}.primaryDataLabel`;
export const selectWhereToMigrateServiceEngineInputLabel = `${prefix}.selectWhereToMigrateServiceEngineInputLabel`;
export const selectAutomaticallyInputPlaceholder = `${prefix}.selectAutomaticallyInputPlaceholder`;
export const recommendedDataLabel = `${prefix}.recommendedDataLabel`;
export const createServiceEngineInputLabel = `${prefix}.createServiceEngineInputLabel`;
export const selectServiceEngineHostInputLabel = `${prefix}.selectServiceEngineHostInputLabel`;
export const migrateBtnLabel = `${prefix}.migrateBtnLabel`;

export const ENGLISH = {
    [migrateHeader]: 'Migrate: {0}',
    [selectVipAddressToMigrateInputLabel]: 'Select VIP Address to migrate',
    [selectVipAddressToMigrateInputPlaceholder]: 'Select VIP Address',
    [selectServiceEngineToMigrateInputLabel]: 'Select a Service Engine to migrate',
    [selectServiceEngineToMigrateInputPlaceholder]: 'Select a Service Engine',
    [primaryDataLabel]: '{0} (Primary)',
    [selectWhereToMigrateServiceEngineInputLabel]: 'Select where to migrate Service Engine',
    [selectAutomaticallyInputPlaceholder]: '(Select Automatically)',
    [recommendedDataLabel]: '{0} (Recommended)',
    [createServiceEngineInputLabel]: 'Create Service Engine',
    [selectServiceEngineHostInputLabel]: 'Select a Service Engine Host',
    [migrateBtnLabel]: 'Migrate',
};
