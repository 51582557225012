/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './modal-container.less';
import * as l10n from './modal-container.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @typedef {Object} AnchorLink
 * @memberOf module:avi/app
 * @property {string} title - title for the link
 * @property {string} className - class name of element to scroll to.
 */

/**
 * @ngdoc component
 * @name modalContainer
 * @description
 *     Wrapper around a typical Item config modal. Contains an optional wizard navigation section
 *     for multiple pages. Removing tabs is not supported.
 * @param {string} modalTitle - String to be used as the title for the modal.
 * @param {boolean} busy - Boolean used to show a spinner when busy.
 * @param {string} submitLabel - String to be used for the Submit button.
 * @param {boolean} showCancel - Boolean used to show a Cancel button.
 * @param {Function} confirmCheck - Function to be passed to the confirm directive.
 * @param {Function} onClose - Function to be called when the close button is clicked.
 * @param {Function} onSubmit - Function to be called when the Submit button is clicked.
 * @param {Function} onCancel - Function to be called when the Cancel button is clicked.
 * @param {string[]} [breadcrumbs=] - Array of strings to be displayed as breadcrumbs.
 * @param {boolean} [disableSubmit=] - True to disable submit button.
 * @param {Array.<module:avi/app.AnchorLink>} [anchorLinks=] - Object contains link
 *                              title and className of the element to scroll to.
 */
//TODO ng-enter support
class ModalContainerController {
    constructor($element, l10nService) {
        this.modalWizardTabs = [];
        this.selectedTab = null;
        this.$element = $element;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Selects a tab.
     * @param {SubtabTab} tab
     */
    selectTab(tab) {
        this.selectedTab = tab;
    }

    /**
     * Returns true if the tab is currently selected.
     * @return {boolean}
     */
    isSelected(tab) {
        return this.selectedTab === tab;
    }

    /**
     * Called by the modalWizardTab child component to add itself to the list of tabs.
     * @param {ModalWizardTabController} tab
     */
    addModalWizardTab(tab) {
        if (!this.selectedTab && !tab.hideTab) {
            this.selectTab(tab);
        }

        this.modalWizardTabs.push(tab);
    }

    /**
     * Scroll screen to the selected element.
     * @param {string} className
     */
    scrollToElement(className) {
        const elementRef = this.$element[0].querySelector(`.${className}`);

        elementRef.scrollIntoView();
    }
}

ModalContainerController.$inject = [
    '$element',
    'l10nService',
];

angular.module('avi/app').component('modalContainer', {
    controller: ModalContainerController,
    transclude: true,
    bindings: {
        modalTitle: '@',
        busy: '<',
        submitLabel: '<',
        showCancel: '<',
        confirmCheck: '&',
        onClose: '&',
        onSubmit: '&?',
        onCancel: '&',
        breadcrumbs: '<?',
        disableSubmit: '<?',
        anchorLinks: '<?',
    },
    templateUrl: 'src/components/modals/modal-container/modal-container.html',
});
