/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'crash-reports-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleFilename = `${prefix}.columnTitleFilename`;
export const downloadBtnLabel = `${prefix}.downloadBtnLabel`;
export const coreFilesHeader = `${prefix}.coreFilesHeader`;

export const ENGLISH = {
    [columnTitleFilename]: 'Filename',
    [downloadBtnLabel]: 'Download',
    [coreFilesHeader]: 'Core Files',
};
