/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    Component,
    EventEmitter,
    Inject,
    Output,
} from '@angular/core';

import { e2eTimingGroupByKeys } from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';
import { VirtualServiceStateService }
    from 'ng/modules/virtual-service/services/virtual-service-state.service';
import { StateParams } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { VsLogsSignpostStore } from '../../../vs-logs-signpost.store';
import './vs-logs-signpost-custom-e2e-timing-header.component.less';
import * as l10n from './vs-logs-signpost-custom-e2e-timing-header.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for log signpost e2e timing header section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-e2e-timing-header',
    templateUrl: './vs-logs-signpost-custom-e2e-timing-header.component.html',
})
export class VsLogsSignpostCustomEndToEndTimingHeaderComponent {
    /**
     * Event emitter to indicate a switch to specified data view.
     */
    @Output()
    public onSwitchView = new EventEmitter<string>();

    /**
     * Holds all allowed keys for grouping e2e logs.
     */
    public readonly e2eTimingGroupByKeys = e2eTimingGroupByKeys;

    /**
     * Property holding e2e timing logs groupby key value.
     */
    public isFilteredBy = e2eTimingGroupByKeys.totalTime;

    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any; // TODO AV-159345 update type.

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        @Inject('$stateParams')
        public readonly $stateParams: StateParams,
        vsStateService: VirtualServiceStateService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.vs = vsStateService.virtualService;
    }

    /**
     * Emit event to switch view to specified field.
     */
    public switchView(key: string): void {
        this.isFilteredBy = key;
        this.onSwitchView.emit(key);
    }
}
