/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const cloudName = 'Default-Cloud';

/**
 * @ngdoc Factory
 * @name  loadCloud
 * @description
 *
 *     Rather weird but efficient way of loading cloud by id or default one if id was not passed.
 */
function loadCloud(Collection, Cloud) {
    return cloudId => {
        const params = {};

        if (cloudId) {
            //FIXME we use collection load instead cloud item load cause of silly
            // cloud.loadConfig
            params.uuid = cloudId;
        } else {
            params.name = cloudName;
        }

        const cloudCollection = new Collection({
            objectName: 'cloud',
            params,
        });

        return cloudCollection.load()
            .then(() => {
                const cloudConfig = cloudCollection.items[0].getConfig();

                return new Cloud({ data: { config: cloudConfig } });
            }).finally(
                () => cloudCollection.destroy(),
            );
    };
}

loadCloud.$inject = [
        'Collection',
        'Cloud',
];

angular.module('avi/router').factory('loadCloud', loadCloud);
