/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { every, filter } from 'underscore';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';

type TItem = Item | ObjectTypeItem;

// TODO: Move to CollectionGrid if that gets converted to a .ts file.
export interface ICollectionGridConfig {
    id?: string;
    collection?: Collection;
    defaultSorting?: string;
    fields?: ICollectionGridConfigField[];
    layout?: ICollectionGridConfigLayout;
    multipleactions?: ICollectionGridConfigMultipleaction[];
    singleactions?: ICollectionGridConfigSingleaction[];
    expandedContainerTemplate?: string;
    checkboxDisable?(row: TItem): boolean;
    expanderDisabled?(row: TItem): boolean;
}

interface ICollectionGridConfigField {
    name: string;
    template?: string;
    title: string;
    sortBy?: string;
    require?: string;
    visibility?: string;
    transform?(row: TItem): string;
}

interface ICollectionGridConfigLayout {
    disabledCreateButtonLabel?: boolean;
    hideDisplaying?: boolean;
    hideEditColumns?: boolean;
    hideLoadingSpinner?: boolean;
    hideSearch?: boolean;
    hideTenantColumn?: boolean;
    includeCreateButton?: boolean;
    includeMetricsValueSelector?: boolean;
    includeTimeframeSelector?: boolean;
    lengthAsTotal?: boolean;
}

interface ICollectionGridConfigMultipleaction {
    title: string;
    disabled?(rows: TItem[]): boolean;
    do(rows: TItem[]): boolean;
}

interface ICollectionGridConfigSingleaction {
    class: string;
    title: string;
    dontCloseExpander?: boolean;
    do(row: TItem): void;
    hidden?(row: TItem): boolean;
}

/**
 * @description Returns a basic CRUD gridConfig that allows for Editing and Deleting rows.
 *     Collection and field list should be added before use.
 * @author alextsg
 */
export function createCrudCollectionGridConfig(
    options?: ICollectionGridConfig,
): ICollectionGridConfig {
    const defaultConfig: ICollectionGridConfig = {
        checkboxDisable: (row: TItem) => !row.isDroppable(),

        defaultSorting: 'name',
        fields: [],
        layout: {},
        multipleactions: [{
            disabled(rows: TItem[]) {
                return every(rows, (row: TItem) => !row.isDroppable());
            },
            do(rows: TItem[]) {
                const toDelete = filter(rows, (row: TItem) => row.isDroppable());

                this.config.collection.dropItems(toDelete);

                return true;
            },
            title: 'Delete',
        }],
        singleactions: [{
            class: 'icon-pencil-4',
            do: (row: TItem) => row.edit(),
            hidden: (row: TItem) => !row.isEditable(),
            title: 'Edit',
        }],
    };

    return {
        ...defaultConfig,
        ...options,
    };
}
