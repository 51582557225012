<div class="upgrade-transcript-modal clr-wrapper">
    <avi-confirmation
        size="lg"
        [headerText]="modalHeaderText"
        (onClose)="handleClose()"
    >
        <avi-confirmation_modal-content>
            <div class="upgrade-transcript-modal__content">
                <div
                    *ngIf="!node"
                    class="upgrade-transcript-modal__loader"
                >
                    <clr-spinner clrMedium></clr-spinner>
                </div>
                <div *ngIf="node">
                    <div
                        *ngIf="!isInTerminalState"
                        class="upgrade-transcript-modal__status sel-upgrade-transcript-modal-status"
                    >
                        <avi-progress-bar
                            message="{{ l10nKeys.upgradeProgressBarDisplayValueLabel | vtranslate }}"
                            header="{{ l10nKeys.upgradeProgressBarLabel | vtranslate : nodeTypeLabel : version }}"
                            [percentage]="upgradeProgress"
                        ></avi-progress-bar>
                    </div>
                    <div
                        *ngIf="isInTerminalState"
                        class="upgrade-transcript-modal__status sel-upgrade-transcript-modal-status"
                    >
                        <avi-alert-group
                            *ngIf="upgradeState === upgradeStateHash.ERROR"
                            alertType="danger"
                        >
                            <span> {{ version }} {{ upgradeOperationLabel | vtranslate }} {{ l10nKeys.failedOperationMessage | vtranslate : ( upgradeEndTime | aviDate ) }} </span>
                        </avi-alert-group>
                        <avi-alert-group
                            *ngIf="upgradeState === upgradeStateHash.SUCCESS"
                            alertType="success"
                        >
                            <span> {{ version }} {{ upgradeOperationLabel | vtranslate }} {{ l10nKeys.successfulOperationMessage | vtranslate : ( upgradeEndTime | aviDate ) }} </span>
                        </avi-alert-group>
                    </div>

                    <clr-datagrid class="upgrade-transcript-modal__status-grid">
                        <clr-dg-column>{{ l10nKeys.nodeNameLabel | vtranslate }}</clr-dg-column>
                        <clr-dg-column>{{ l10nKeys.completedOnLabel | vtranslate }}</clr-dg-column>

                        <clr-dg-row>
                            <clr-dg-cell>{{ nodeName }}</clr-dg-cell>
                            <clr-dg-cell>{{ isInTerminalState ? (upgradeEndTime | aviDate) : '-' }}</clr-dg-cell>
                        </clr-dg-row>
                    </clr-datagrid>

                    <h6>{{ l10nKeys.transcriptLabel | vtranslate }}</h6>
                    <avi-textarea-container>
                        <textarea
                            clrTextarea
                            [ngModel]="transcript"
                            name="upgrade-transcript"
                            class="upgrade-transcript-modal__transcript-textarea sel-upgrade-transcript-modal-textarea"
                            readonly
                        ></textarea>
                    </avi-textarea-container>
                </div>
            </div>
        </avi-confirmation_modal-content>
    </avi-confirmation>
</div>
