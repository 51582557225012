<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <!-- General -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.generalSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ l4PolicySetObjectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ l10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ l10nKeys.enterNameLabel | vtranslate }}"
                    required
                />
            </avi-input-container>

            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ l4PolicySetObjectType }}"
                    fieldName="description"
                    for="description"
                >
                    {{ l10nKeys.descriptionLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    id="description"
                    [(ngModel)]="editable.config.description"
                    name="description"
                    placeholder="{{ l10nKeys.enterDescriptionLabel | vtranslate }}"
                ></textarea>
            </avi-textarea-container>
        </full-modal-tab-section>

        <!-- L4 Connection Policy -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.l4ConnectionPolicySectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.l4ConnectionPolicySectionTitle | vtranslate }}</h3>

            <avi-data-grid
                [config]="l4RuleDataGridConfig"
                [rows]="l4RuleDataGridRows"
                (onRowOrderChange)="handleL4RuleGridRowReorder($event)"
            >
                <avi-data-grid_header>
                    <h6 class="full-modal__section-header">
                        {{ l10nKeys.l4RuleGridTitle | vtranslate : l4RuleDataGridRows.length }}
                    </h6>
                </avi-data-grid_header>

                <avi-data-grid_actions>
                    <cds-button
                        (click)="addL4Rule()"
                        size="sm"
                    >
                        {{ l10nKeys.addLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
            </avi-data-grid>
            <ng-template
                #enabledColumnTemplateRef
                let-row
            >
                <cds-icon
                    *ngIf="row.enabled"
                    status="success"
                    shape="check-circle"
                    size="18"
                ></cds-icon>
            </ng-template>
            <ng-template
                #expandedContentTemplateRef
                let-row
            >
                <match-and-action-display-wrapper>
                    <match-and-action-display-wrapper_match-content>
                        <ip-addr-match-readonly
                            *ngIf="row.config.match.config.client_ip"
                            [editable]="row.config.match.config.client_ip"
                        ></ip-addr-match-readonly>

                        <l4-rule-port-match-readonly
                            *ngIf="row.config.match.config.port"
                            [editable]="row.config.match.config.port"
                        ></l4-rule-port-match-readonly>

                        <l4-rule-protocol-match-readonly
                            *ngIf="row.config.match.config.protocol"
                            [editable]="row.config.match.config.protocol"
                        ></l4-rule-protocol-match-readonly>
                    </match-and-action-display-wrapper_match-content>

                    <match-and-action-display-wrapper_action-content>
                        {{ l10nKeys.selectPoolActionLabel | vtranslate :
                            row.config.action.config.select_pool.actionValue }}
                    </match-and-action-display-wrapper_action-content>
                </match-and-action-display-wrapper>
            </ng-template>
        </full-modal-tab-section>
    </form>
</full-modal-config>
