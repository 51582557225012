<div class="vs-log-list">
    <avi-dynamic-data-grid
        [config]="vsLogsListGridConfig"
        [rows]="vsLogListStore.vsLogList$ | async"
        *ngrxLet="vsLogsStore.totalLogsCount$ as totalLogsCount"
        [rowsTotal]="totalLogsCount > 10000 ? 10000 : totalLogsCount"
        [isLoading]="vsLogsStore.tableIsLoading$ | async"
        (onPaginationChange)="handlePaginationChange($event)"
        (onSelectionChange)="handleSelectionChange($event)"
        (onSortingChange)="handleSortingChange($event)"
        [hidden]="vsLogListStore.hasError$ | async"
    ></avi-dynamic-data-grid>

    <div
        *ngIf="vsLogListStore.hasError$ | async"
        class="vs-log-list__err-state"
    >
        {{ l10nKeys.errorMessageLabel | vtranslate }}
    </div>
</div>

<!-------------- TemplateRefs for List Fields -------------->
<ng-template
    #wafFieldTemplateRef
    let-row
>
    <span
        *ngIf="row.waf_log; else noData"
        vsLogFilter
        property="waf_log.status"
        [value]="row.waf_log.status"
    >
        {{ schemaService.getEnumValueLabel('WafStatusCode', row.waf_log.status) }}
    </span>
</ng-template>

<ng-template
    #clientIpTemplateRef
    let-row
>
    <div *ngIf="row.client_ip6 || row.client_ip; else noData">
        <span
            *ngIf="row.client_ip6; else clientIp"
            vsLogFilter
            property="client_ip6"
            [value]="row.client_ip6"
        >
            {{ row.client_ip6 }}
        </span>
        <ng-template #clientIp>
            <span
                vsLogFilter
                property="client_ip"
                [value]="row.client_ip"
            >
                {{ row.client_ip }}
            </span>
        </ng-template>
    </div>
</ng-template>

<ng-template
    #clientPortTemplateRef
    let-row
>
    <span
        *ngIf="row.client_src_port; else noData"
        vsLogFilter
        property="client_src_port"
        [value]="row.client_src_port"
    >
        {{ row.client_src_port }}
    </span>
</ng-template>

<ng-template
    #serverIpTemplateRef
    let-row
>
    <div *ngIf="row.server_ip6 || row.server_ip; else noData">
        <span
            *ngIf="row.server_ip6; else serverIp"
            vsLogFilter
            property="server_ip6"
            [value]="row.server_ip6"
        >
            {{ row.server_ip6 }}
        </span>
        <ng-template #serverIp>
            <span
                vsLogFilter
                property="server_ip"
                [value]="row.server_ip"
            >
                {{ row.server_ip }}
            </span>
        </ng-template>
    </div>
</ng-template>

<ng-template
    #serverPortTemplateRef
    let-row
>
    <span
        *ngIf="row.server_dest_port; else noData"
        vsLogFilter
        property="server_dest_port"
        [value]="row.server_dest_port"
    >
        {{ row.server_dest_port }}
    </span>
</ng-template>

<ng-template
    #uriTemplateRef
    let-row
>
    <!-- Limit long strings with overflow -->
    <span class="vs-log-list__overflow-container">
        <span class="vs-log-list__overflow-element">
            <span
                *ngIf="row.uri_path; else noData"
                vsLogPartialSelectionFilter
                property="uri_path"
                [value]="row.uri_path"
                [noSearchIcon]="true"
                aviTitle="{{ row.uri_path }}"
            ></span>
            <ng-container *ngIf="row.uri_query">
                <span class="vs-log-list__uri-question-mark">
                    &nbsp;?&nbsp;
                </span>
                <span
                    vsLogPartialSelectionFilter
                    property="uri_query"
                    [value]="row.uri_query"
                    [noSearchIcon]="true"
                    aviTitle="{{ row.uri_query }}"
                ></span>
            </ng-container>
        </span>
    </span>
</ng-template>

<ng-template
    #protocolTemplateRef
    let-row
>
    <span
        *ngIf="row.protocol; else noData"
        vsLogFilter
        property="protocol"
        [value]="row.protocol"
    >
        {{ stringService.enumeration(row.protocol, 'PROTOCOL_') }}
    </span>
</ng-template>

<ng-template
    #dnsRequestTypeTemplateRef
    let-row
>
    <span
        *ngIf="row.dns_qtype; else noData"
        vsLogFilter
        property="dns_qtype"
        [value]="row.dns_qtype"
    >
        {{ stringService.enumeration(row.dns_qtype, 'DNS_RECORD_').toUpperCase() }}
    </span>
</ng-template>

<ng-template
    #domainNameTemplateRef
    let-row
>
    <!-- Limit long strings with overflow -->
    <span class="vs-log-list__overflow-container">
        <span
            *ngIf="row.dns_fqdn; else noData"
            class="vs-log-list__overflow-element"
            vsLogPartialSelectionFilter
            property="dns_fqdn"
            [value]="row.dns_fqdn"
            [noSearchIcon]="true"
        ></span>
    </span>
</ng-template>

<ng-template
    #dnsResponseTemplateRef
    let-row
>
    <!-- Limit long strings with overflow -->
    <span
        *ngIf="row.dns_response?.records; else noData"
        class="vs-log-list__overflow-container"
    >
        <span class="vs-log-list__overflow-element">
            <span
                *ngFor="let record of row.dns_response.records; trackBy: trackByIndex"
            >
                <span
                    *ngIf="record.addr6_ip_str"
                    vsLogFilter
                    class="vs-log-list__dns-response-ip"
                    property="dns_response.records.addr6_ip_str"
                    [value]="record.addr6_ip_str"
                >
                    {{ record.addr6_ip_str }}
                </span>
                <span
                    *ngIf="record.addr_ip"
                    vsLogFilter
                    class="vs-log-list__dns-response-ip"
                    property="dns_response.records.addr_ip"
                    [value]="record.addr_ip"
                >
                    {{ record.addr_ip }}
                </span>
            </span>
        </span>
    </span>
</ng-template>

<ng-template
    #requestTemplateRef
    let-row
>
    <span
        *ngIf="row.method; else noData"
        vsLogFilter
        property="method"
        [value]="row.method"
    >
        {{ row.method }}
    </span>
</ng-template>

<ng-template
    #responseTemplateRef
    let-row
>
    <div
        *ngIf="row.response_code; else noData"
        class="vs-log-list__response-code-wrapper"
    >
        <span
            vsLogFilter
            property="response_code"
            [value]="row.response_code"
            class="vs-log-list__response-code-separator"
        >
            {{ row.response_code }}
        </span>
        <avi-tooltip-icon-with-content-wrapper
            size="18"
            status="info"
        >
            {{ getResponseCodeDescription(row.response_code) }}
        </avi-tooltip-icon-with-content-wrapper>
    </div>
</ng-template>

<ng-template
    #lengthTemplateRef
    let-row
>
    <span
        *ngIf="row.response_length !== undefined; else noData"
        vsLogFilterList
        property="response_length"
        [value]="row.response_length"
    >
        {{ row.response_length | formatBytes }}
    </span>
</ng-template>

<ng-template
    #totalBytesTemplateRef
    let-row
>
    <span
        *ngIf="row.total_bytes !== undefined; else noData"
        vsLogFilterList
        property="total_bytes"
        [value]="row.total_bytes"
    >
        {{ row.total_bytes | formatBytes }}
    </span>
</ng-template>

<ng-template
    #durationTemplateRef
    let-row
>
    <span
        *ngIf="row.total_time !== undefined; else noData"
        vsLogFilter
        property="total_time"
        [value]="row.total_time"
        vsLogDurationDetail
        [log]="row"
    >
        {{ timeFormatterService('ms2str')(row.total_time, true) }}
    </span>
</ng-template>

<ng-template
    #ednsSubnetTemplateRef
    let-row
>
    <span
        *ngIf="getEdnsSubnet(row); else noData"
        vsLogFilter
        [property]="getEdnsSubnet(row).type"
        [value]="getEdnsSubnet(row).value"
    >
        {{ getEdnsSubnet(row).value }}
    </span>
</ng-template>

<ng-template #noData>
    <span vsLogEmptyField></span>
</ng-template>
