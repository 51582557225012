<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    [item]="editable"
    [form]="form"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        required
                        for="name"
                        fieldName="name"
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        class="sel-name"
                        regexPattern="objName"
                        clrInput
                        required
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        [(ngModel)]="editable.config.name"
                        [readonly]="!isNameEditable"
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="description"
                        for="description"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        class="sel-description"
                        id="description"
                        name="description"
                        [(ngModel)]="editable.config.description"
                        clrTextarea
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="type"
                            for="type"
                        >
                            {{ globalL10nKeys.typeLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="type"
                            class="sel-hm-type"
                            [(ngModel)]="editable.config.type"
                            enum="HealthMonitorType"
                            (ngModelChange)="editable.onTypeChange()"
                            [disabled]="!!editable.id"
                            required
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            name="is-federated"
                            id="is-federated"
                            [(ngModel)]="editable.config.is_federated"
                            clrCheckbox
                            [disabled]="!!editable.id"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            for="is-federated"
                            fieldName="is_federated"
                        >
                            {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            noMarginTop
                            helperText="{{ globalL10nKeys.secondsLabel | vtranslate }}"
                        >
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="send_interval"
                                for="send-interval"
                            >
                                {{ l10nKeys.sendIntervalInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="number"
                                id="send-interval"
                                name="send-interval"
                                class="sel-send-interval"
                                [(ngModel)]="editable.config.send_interval"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="send_interval"
                                placeholder="{{ l10nKeys.sendIntervalInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container
                            noMarginTop
                            helperText="{{ globalL10nKeys.secondsLabel | vtranslate }}"
                        >
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="receive_timeout"
                                for="receive-timeout"
                            >
                                {{ l10nKeys.receiveTimeoutInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="number"
                                id="receive-timeout"
                                name="receive-timeout"
                                class="sel-receive-timeout"
                                [(ngModel)]="editable.config.receive_timeout"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="receive_timeout"
                                placeholder="{{ l10nKeys.receiveTimeoutInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="successful_checks"
                                for="successful-checks"
                            >
                                {{ l10nKeys.successfulChecksInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="number"
                                id="successful-checks"
                                name="successful-checks"
                                class="sel-successful-checks"
                                [(ngModel)]="editable.config.successful_checks"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="successful_checks"
                                placeholder="{{ l10nKeys.successfulChecksInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="failed_checks"
                                for="failed-checks"
                            >
                                {{ l10nKeys.failedChecksInputLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                type="number"
                                id="failed-checks"
                                name="failed-checks"
                                class="sel-failed-checks"
                                [(ngModel)]="editable.config.failed_checks"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="failed_checks"
                                placeholder="{{ l10nKeys.failedChecksInputPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_PING"
            tabTitle="{{ l10nKeys.tabTitlePing | vtranslate }}"
        >
            <ping-health-monitor-config [editable]="editable"></ping-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_DNS"
            tabTitle="{{ globalL10nKeys.dnsLabel | vtranslate }}"
        >
            <dns-health-monitor-config [editable]="editable"></dns-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_IMAPS"
            tabTitle="{{ l10nKeys.sectionTitleImaps | vtranslate }}"
        >
            <imaps-health-monitor-config
                [editable]="editable"
                [isFederated]="editable.config.is_federated"
            ></imaps-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_IMAP"
            tabTitle="{{ l10nKeys.sectionTitleImap | vtranslate }}"
        >
            <imaps-health-monitor-config [editable]="editable"></imaps-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_POP3"
            tabTitle="{{ l10nKeys.sectionTitlePop3 | vtranslate }}"
        >
            <pop3-health-monitor-config [editable]="editable"></pop3-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_POP3S"
            tabTitle="{{ l10nKeys.tabTitlePop3s | vtranslate }}"
        >
            <pop3-health-monitor-config
                [editable]="editable"
                [isFederated]="editable.config.is_federated"
            ></pop3-health-monitor-config>
        </full-modal-tab-section>
        
        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_EXTERNAL"
            tabTitle="{{ l10nKeys.sectionTitleExternal | vtranslate }}"
        >
            <external-health-monitor-config [editable]="editable"></external-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_RADIUS"
            tabTitle="{{ l10nKeys.sectionTitleRadius | vtranslate }}"
        >
            <radius-health-monitor-config [editable]="editable"></radius-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_SIP"
            tabTitle="{{ l10nKeys.sectionTitleSip | vtranslate }}"
        >
            <sip-health-monitor-config [editable]="editable"></sip-health-monitor-config>
        </full-modal-tab-section>

        <ng-container *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_HTTP">
            <full-modal-tab-section tabTitle="{{ globalL10nKeys.httpLabel | vtranslate }}">
                <http-health-monitor-config [editable]="editable"></http-health-monitor-config>
            </full-modal-tab-section>

            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleServerMaintenanceMode | vtranslate }}">
                <server-maintenance-mode [healthMonitorTypeConfig]="editable.healthMonitorTypeConfig"></server-maintenance-mode>
            </full-modal-tab-section>
        </ng-container>

        <ng-container *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_HTTPS">
            <full-modal-tab-section tabTitle="{{ globalL10nKeys.httpsLabel | vtranslate }}">
                <http-health-monitor-config
                    [editable]="editable"
                    [isFederated]="editable.config.is_federated"
                ></http-health-monitor-config>
            </full-modal-tab-section>

            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleServerMaintenanceMode | vtranslate }}">
                <server-maintenance-mode [healthMonitorTypeConfig]="editable.healthMonitorTypeConfig"></server-maintenance-mode>
            </full-modal-tab-section>
        </ng-container>

        <ng-container *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_SCTP">
            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleSctp | vtranslate }}">
                <sctp-health-monitor-config [editable]="editable"></sctp-health-monitor-config>
            </full-modal-tab-section>
        </ng-container>

        <ng-container *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_TCP">
            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleTcp | vtranslate }}">
                <tcp-health-monitor-config [editable]="editable"></tcp-health-monitor-config>
            </full-modal-tab-section>
        </ng-container>

        <ng-container *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_UDP">
            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleUdp | vtranslate }}">
                <udp-health-monitor-config [editable]="editable"></udp-health-monitor-config>
            </full-modal-tab-section>

            <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleServerMaintenanceMode | vtranslate }}">
                <div class="full-modal-config__section">
                    <h3>{{ l10nKeys.sectionTitleServerMaintenanceMode | vtranslate }}</h3>
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectTypes.HealthMonitorUdp }}"
                                    fieldName="maintenance_response"
                                    for="udp-maintenance-response"
                                >
                                    {{ l10nKeys.maintenanceResponseDataInputLabel | vtranslate }}
                                </label>
                                <input
                                    clrInput
                                    type="text"
                                    id="udp-maintenance-response"
                                    name="udp-maintenance-response"
                                    [(ngModel)]="udpMonitor.config.maintenance_response"
                                    placeholder="{{ l10nKeys.maintenanceResponseDataInputPlaceholder | vtranslate }}"
                                />
                            </avi-input-container>
                        </div>
                    </div>
                </div>
            </full-modal-tab-section>
        </ng-container>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_SMTP"
            tabTitle="{{ l10nKeys.tabTitleSmtp | vtranslate }}"
        >
            <smtp-health-monitor-config [editable]="editable"></smtp-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_SMTPS"
            tabTitle="{{ l10nKeys.tabTitleSmtps | vtranslate }}"
        >
            <smtp-health-monitor-config
                [editable]="editable"
                [isFederated]="editable.config.is_federated"
            ></smtp-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_LDAP"
            tabTitle="{{ l10nKeys.tabTitleLdap | vtranslate }}"
        >
            <ldap-health-monitor-config [editable]="editable"></ldap-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_LDAPS"
            tabTitle="{{ l10nKeys.tabTitleLdaps | vtranslate }}"
        >
            <ldap-health-monitor-config
                [editable]="editable"
                [isFederated]="editable.config.is_federated"
            ></ldap-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_FTP"
            tabTitle="{{ l10nKeys.tabTitleFtp | vtranslate }}"
        >
            <ftp-health-monitor-config [editable]="editable"></ftp-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="editable.config.type === healthMonitorTypesHash.HEALTH_MONITOR_FTPS"
            tabTitle="{{ l10nKeys.tabTitleFtp | vtranslate }}"
        >
            <ftp-health-monitor-config
                [editable]="editable"
                [isFederated]="editable.config.is_federated"
            ></ftp-health-monitor-config>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ globalL10nKeys.sectionTitleRbac | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.rbacLabel | vtranslate }}</h3>
                <role-filter-match-label
                    objectType="{{ objectType }}"
                    fieldName="markers"
                    [markers]="editable.config.markers"
                ></role-filter-match-label>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
