<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="client_ip_addr_group"
            >
                {{ l10nKeys.clientGroupTypeLabel | vtranslate }}
            </label>
            <enum-radio-buttons
                name="client-ip-addr-group-type"
                id="client-ip-addr-group-type"
                [(ngModel)]="clientIpAddrGroup.config.type"
                enum="GslbIpAddrType"
                noMarginTop
            ></enum-radio-buttons>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-data-grid
                [config]="ipAddrGridConfig"
                [rows]="clientIpAddrGroup.addressList"
                [gridTitle]="l10nKeys.clientGroupIpAddressLabel | vtranslate : clientIpAddrGroup.addressList.length"
            >
                <avi-data-grid_actions>
                    <cds-button
                        size="sm"
                        (click)="addIpAddress()"
                    >
                        {{ globalL10nKeys.addLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
                <ng-template
                    #ipAddrTemplateRef
                    let-row
                    let-index="index"
                >
                    <avi-input-container noMarginTop>
                        <input
                            clrInput
                            [(ngModel)]="row.value"
                            name="{{ 'client-group-ip-addr_' + index }}"
                            placeholder="{{ l10nKeys.ipAdressPrefixRangeInputPlaceholder | vtranslate }}"
                            regexPattern="ipRangePrefix"
                        />
                    </avi-input-container>
                </ng-template>
            </avi-data-grid>
        </div>
    </div>
</div>
