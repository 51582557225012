/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'service-engine-navbars';
const prefix = `${moduleName}.${componentName}`;

export const trafficCaptureLinkLabel = `${prefix}.trafficCaptureLinkLabel`;
export const eventsLinkLabel = `${prefix}.eventsLinkLabel`;
export const bgpPeeringLinkLabel = `${prefix}.bgpPeeringLinkLabel`;

export const ENGLISH = {
    [trafficCaptureLinkLabel]: 'Traffic Capture',
    [eventsLinkLabel]: 'Events',
    [bgpPeeringLinkLabel]: 'BGP Peering',
};
