/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './collapsible-list.less';

class CollapsibleListController {
    constructor($attrs) {
        /** @type {boolean} */
        this.expanded = false;
        this.titleIsClickable = false;

        /** @type {Function=} */
        this.onToggle = null;

        this.$attrs_ = $attrs;
    }

    /**
     * Expands the collapsible list body by default
     * when expanded attribute present in the component.
     * @protected
     */
    expand_() {
        this.expanded = true;
    }

    /**
     * Expands or collapses toggle button based on current state.
     */
    toggle() {
        this.expanded = !this.expanded;

        if (angular.isFunction(this.onToggle)) {
            this.onToggle();
        }
    }

    $onInit() {
        const { $attrs_ } = this;

        if ('expanded' in $attrs_) {
            this.expand_();
        }

        this.titleIsClickable = 'titleIsClickable' in $attrs_;
    }
}

CollapsibleListController.$inject = [
    '$attrs',
];

/**
 * @ngdoc component
 * @name collapsibleList
 * @param expanded - If expanded attribute is present,
 *     the list content will be expanded by default
 * @param titleIsClickable - If titleIsClickable attribute is present,
 *     the list content can be expanded by clicking the title.
 * @param {Function=} onToggle - Callback when button is clicked if provided.
 * @param {HTMLElement} collapsibleListHeader - the transcluded slot for embedding the header
 * @param {HTMLElement} collapsibleListBody - the transcluded slot for
 *     embedding the list content which is to be expanded
 * @param {HTMLElement=} collapsibleListButton - the transcluded slot for
 *     embedding the button to be clicked for toggling the list. If not provided,
 *     the default transcluded content from the template would be used.
 * @description
 *      Looks-up child elements by specific class to show or hide them.
 */
angular.module('aviApp').component('collapsibleList', {
    controller: CollapsibleListController,
    transclude: {
        header: 'collapsibleListHeader',
        list: 'collapsibleListBody',
        collapsibleButton: '?collapsibleListButton',
    },
    bindings: {
        onToggle: '&?',
    },
    templateUrl: 'src/components/common/collapsible-list/collapsible-list.html',
});
