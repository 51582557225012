/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'jwt-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const jwtLabel = `${prefix}.jwtLabel`;

export const ENGLISH = {
    [jwtLabel]: 'JSON Web Tokens (JWT)',
};
