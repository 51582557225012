/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { AviFormsModule } from '../avi-forms/avi-forms.module';
import { authProfileCollectionProvider } from './ajs-upgraded-providers';
import { MatchModule } from '../match/match.module';

import {
    AuthenticationRuleGridComponent,
    AuthenticationRuleModalComponent,
    AuthorizationRuleGridComponent,
    AuthorizationRuleModalComponent,
    SSOPolicyListComponent,
    SsoPolicyModalComponent,
} from './components';

const ssoPolicyComponents = [
    AuthenticationRuleGridComponent,
    AuthenticationRuleModalComponent,
    AuthorizationRuleGridComponent,
    AuthorizationRuleModalComponent,
    SsoPolicyModalComponent,
    SSOPolicyListComponent,
];

/**
 * @description
 *
 *      Angular Module for SSO Policy related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...ssoPolicyComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        BrowserAnimationsModule,
        ClrFormsModule,
        ClrInputModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        MatchModule,
    ],
    providers: [
        authProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SsoPolicyModule { }
