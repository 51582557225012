<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        #form="ngForm"
        [clrLayout]="verticalLayout"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>
                    {{ l10nKeys.sectionTitleGeneral | vtranslate }}
                </h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ l10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        [(ngModel)]="editable.config.name"
                        clrInput
                        id="name"
                        class="sel-name"
                        name="name"
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="description"
                        for="description"
                    >
                        {{ l10nKeys.descriptionInputLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ l10nKeys.descriptionInputPlaceholder | vtranslate }}"
                     ></textarea>
                </avi-textarea-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-data-grid
                            [config]="syslogServersGridConfig"
                            [rows]="editable.config.syslog_servers.getConfig()"
                            gridTitle="{{ l10nKeys.syslogServersLabel | vtranslate : editable.config.syslog_servers.count}}"
                        >
                            <avi-data-grid_actions>
                                <cds-button
                                    size="sm"
                                    (click)="createSyslogServers()"
                                >
                                    {{ l10nKeys.addLabel | vtranslate }}
                                </cds-button>
                            </avi-data-grid_actions>
                        </avi-data-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
