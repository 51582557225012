/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'dialog';
const componentName = 'avi-dismiss-changes-confirmation';
const prefix = `${moduleName}.${componentName}`;

export const resetToDefaultsHeader = `${prefix}.resetToDefaultsHeader`;
export const unsavedChangesHeader = `${prefix}.unsavedChangesHeader`;

export const unsavedChangesWarning = `${prefix}.unsavedChangesWarning`;
export const resetToDefaultsWarning = `${prefix}.resetToDefaultsWarning`;

export const ENGLISH = {
    [resetToDefaultsHeader]: 'Reset to Defaults',
    [unsavedChangesHeader]: 'Unsaved Changes',

    [unsavedChangesWarning]: 'Changes have not been saved.',
    [resetToDefaultsWarning]: 'Switching type will reset any stored values to default.',
};
