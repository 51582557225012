/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { I18nService } from '@vmw/ngx-vip';
import { MyAccountService } from 'ng/modules/core/services/my-account.service';

/**
 * Using the downgraded i18nService to format datetime strings.
 * @param i18nService provide datetime format for the value.
 * @param myAccount determine whether setting UTC timezone
 * @returns {Function} the function is used to format datetime in template.
 */
export function i18nDateFilter(
    i18nService: I18nService,
    myAccount: MyAccountService,
) {
    /**
     * @param value
     * @param pattern
     */
    return function i18nDateFilter(
        value: Date | string | number,
        pattern = 'short',
    ): string {
        const timezone = myAccount.useUtcTime ? 'UTC' : null;

        return i18nService.formatDate(value, pattern, null, timezone);
    };
}

i18nDateFilter.$inject = [
    'i18nService',
    'myAccount',
];
