<span
    avi-tooltip
    [tooltipControl$]="showOperatorsSubject.asObservable()"
    [positionsPriority]="positionsPriority"
    [hideOnMouseLeave]="false"
>
    <ng-container #vsLogFilterContent>
        <ng-content></ng-content>
    </ng-container>

    <ng-template #aviTooltipContent>
        <div
            (mouseenter)="onOperatorsListMouseEnter()"
            (mouseleave)="onOperatorsListMouseLeave()"
        >
            <div
                *ngFor="let operator of operators; trackBy: trackByIndex"
                class="vs-log-filter-list__operators-item"
                [attr.data-operator-type]="operator"
                (click)="selectOperator($event)"
            >
                {{ operatorsSymbolsHash[operator] }} {{ displayValue }}
            </div>
        </div>
    </ng-template>
</span>
