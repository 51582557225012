/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-summary';
const prefix = `${moduleName}.${componentName}`;

export const userIdLabel = `${prefix}.userIdLabel`;
export const locationLabel = `${prefix}.locationLabel`;
export const operatingSystemLabel = `${prefix}.operatingSystemLabel`;
export const deviceLabel = `${prefix}.deviceLabel`;
export const browserLabel = `${prefix}.browserLabel`;
export const startTimeLabel = `${prefix}.startTimeLabel`;
export const endTimeLabel = `${prefix}.endTimeLabel`;
export const nsxAndAlbLabel = `${prefix}.nsxAndAlbLabel`;
export const redirectUriLabel = `${prefix}.redirectUriLabel`;
export const serverRedirectUriLabel = `${prefix}.serverRedirectUriLabel`;
export const rulesAndPoliciesLabel = `${prefix}.rulesAndPoliciesLabel`;
export const networkSecurityPolicyRuleLabel = `${prefix}.networkSecurityPolicyRuleLabel`;
export const httpSecurityPolicyRuleLabel = `${prefix}.httpSecurityPolicyRuleLabel`;
export const httpRequestPolicyRuleLabel = `${prefix}.httpRequestPolicyRuleLabel`;
export const httpResponsePolicyRuleLabel = `${prefix}.httpResponsePolicyRuleLabel`;
export const clientRequestInfoLabel = `${prefix}.clientRequestInfoLabel`;
export const requestIdLabel = `${prefix}.requestIdLabel`;
export const uriLabel = `${prefix}.uriLabel`;
export const unparsedUriLabel = `${prefix}.unparsedUriLabel`;
export const userAgentLabel = `${prefix}.userAgentLabel`;
export const serverResponseInfoLabel = `${prefix}.serverResponseInfoLabel`;
export const serverResponseLengthLabel = `${prefix}.serverResponseLengthLabel`;
export const grpcStatusLabel = `${prefix}.grpcStatusLabel`;
export const dnsQueryTypeLabel = `${prefix}.dnsQueryTypeLabel`;
export const gslbServiceLabel = `${prefix}.gslbServiceLabel`;
export const gslbPoolLabel = `${prefix}.gslbPoolLabel`;
export const recursionAvailableLabel = `${prefix}.recursionAvailableLabel`;
export const policiesAppliedLabel = `${prefix}.policiesAppliedLabel`;
export const sslLabel = `${prefix}.sslLabel`;
export const datascriptLabel = `${prefix}.datascriptLabel`;
export const icapLabel = `${prefix}.icapLabel`;
export const wafLabel = `${prefix}.wafLabel`;
export const botManagementLabel = `${prefix}.botManagementLabel`;
export const virtualHostRuleNameLabel = `${prefix}.virtualHostRuleNameLabel`;
export const persistenceIdLabel = `${prefix}.persistenceIdLabel`;
export const perfectForwardSecrecyLabel = `${prefix}.perfectForwardSecrecyLabel`;
export const contentTypeLabel = `${prefix}.contentTypeLabel`;

export const ENGLISH = {
    [userIdLabel]: 'User ID',
    [locationLabel]: 'Location',
    [operatingSystemLabel]: 'Operating System',
    [deviceLabel]: 'Device',
    [browserLabel]: 'Browser',
    [startTimeLabel]: 'Start Time',
    [endTimeLabel]: 'End Time',
    [nsxAndAlbLabel]: 'NSX + ALB',
    [redirectUriLabel]: 'Redirect URI',
    [serverRedirectUriLabel]: 'Server Redirect URI',
    [rulesAndPoliciesLabel]: 'Rules and Policies',
    [networkSecurityPolicyRuleLabel]: 'Network Security Policy Rule',
    [httpSecurityPolicyRuleLabel]: 'HTTP Security Policy Rule',
    [httpRequestPolicyRuleLabel]: 'HTTP Request Policy Rule',
    [httpResponsePolicyRuleLabel]: 'HTTP Response Policy Rule',
    [clientRequestInfoLabel]: 'Client Request Information',
    [requestIdLabel]: 'Request ID',
    [uriLabel]: 'URI',
    [unparsedUriLabel]: 'Unparsed URI',
    [userAgentLabel]: 'User Agent',
    [serverResponseInfoLabel]: 'Server Response Information',
    [serverResponseLengthLabel]: 'Server Response Length',
    [grpcStatusLabel]: 'GRPC Status',
    [dnsQueryTypeLabel]: 'DNS Query Type',
    [gslbServiceLabel]: 'GSLB Service',
    [gslbPoolLabel]: 'GSLB Pool',
    [recursionAvailableLabel]: 'Recursion Available',
    [policiesAppliedLabel]: 'Policies Applied',
    [sslLabel]: 'SSL',
    [datascriptLabel]: 'Datascript',
    [icapLabel]: 'ICAP',
    [wafLabel]: 'WAF',
    [botManagementLabel]: 'Bot Management',
    [virtualHostRuleNameLabel]: 'Virtual Host Rule Name',
    [persistenceIdLabel]: 'Persistence ID',
    [perfectForwardSecrecyLabel]: 'Perfect Forward Secrecy',
    [contentTypeLabel]: 'Content Type',
};
