<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.openStackHeader | vtranslate }}</h3>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="username"
            for="username"
            required
        >
            {{ l10nKeys.usernameInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="username"
            placeholder="{{ l10nKeys.usernameInputLabel | vtranslate }}"
            name="username"
            [(ngModel)]="editable.config.username"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="password"
            for="password"
            required
        >
            {{ l10nKeys.passwordInputLabel | vtranslate }}
        </label>  
        <input
            clrInput
            id="password"
            type="password"
            placeholder="{{ l10nKeys.passwordInputPlaceholder | vtranslate }}"
            name="password"
            [(ngModel)]="editable.config.password"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="tenant"
            for="tenant"
            required
        >
            {{ l10nKeys.tenantInputLabel | vtranslate }}    
        </label>
        <input
            clrInput
            id="tenant"
            placeholder="{{ l10nKeys.tenantInputPlaceholder | vtranslate }}"
            name="tenant"
            [(ngModel)]="editable.config.tenant"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="keystone_host"
            for="keystone_host"
            required
        >
            {{ l10nKeys.keystoneHostInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="keystone_host"
            placeholder="{{ l10nKeys.keystoneHostInputPlaceholder | vtranslate }}"
            name="keystone_host"
            [(ngModel)]="editable.config.keystone_host"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="vip_network_name"
            for="vip_network_name"
            required
        >
            {{ l10nKeys.vipNetworkNameInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="vip_network_name"
            placeholder="{{ l10nKeys.vipNetworkNameInputPlaceholder | vtranslate }}"
            name="vip_network_name"
            [(ngModel)]="editable.config.vip_network_name"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="region"
            for="region"
        >
            {{ l10nKeys.regionInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="region"
            placeholder="{{ l10nKeys.regionInputPlaceholder | vtranslate }}"
            name="region"
            [(ngModel)]="editable.config.region"
        />
    </avi-input-container>
</div>
