/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    invert,
    isUndefined,
    pluck,
} from 'underscore';

import {
    AcceptedCipherEnums,
    AviPermissionResource,
    ISSLProfile,
    ISSLRating,
    ISSLVersion,
    SSLProfileType,
    SSLVersionType,
} from 'generated-types';

import { StringService } from 'string-service';

import {
    IEnumValue,
    SchemaService,
} from 'ajs/modules/core/services';

import {
    SslRatingPreviewContainerComponent,
    SslTlsProfileModalComponent,
} from 'ng/modules/security';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IFullModalLayout } from 'full-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { SSLVersionConfigItem } from './ssl-version.config-item.factory';
import * as l10n from '../../security.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Common prefix of SSLScore enum values.
 */
const sslScorePrefix = 'SSL_SCORE_';

/**
 * Request id for SSLProfile (to get rating) check call.
 */
const SSL_PROFILE_CHECK_ID = 'ssl-profile-check';

/**
 * URL for SSLProfile (to get rating) check call.
 */
const SSL_PROFILE_CHECK_URL = '/api/sslprofilecheck';

export interface ICipherObject {
    cipher: AcceptedCipherEnums;
    description: string;
    performance: string;
    compatibility: string;
    security: string;
    securityScore: number;
    enabled: boolean;
    pfs: boolean;
}

type ISSLProfilePartial = Omit<ISSLProfile, 'accepted_versions' | 'ssl_rating'>;
interface IExtendedSSLProfile extends ISSLProfilePartial {
    cipherEnumsTmp?: ICipherObject[];
    accepted_versions?: RepeatedMessageItem<SSLVersionConfigItem>;
    acceptedVersionsList?: SSLVersionType[];
    ssl_rating?: MessageItem<ISSLRating>;
}

export const SSL_PROFILE_ITEM_TOKEN = 'SSLProfile';

export enum CipherType {
    LIST_VIEW = 'list',
    STRING_VIEW = 'string',
}

interface ISSLProfileData {
    config: IExtendedSSLProfile;
}

/**
 * @desc SSLProfile Item.
 *
 * @author Aravindh Nagarajan
 */
export class SSLProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'schemaService',
        'stringService',
        'l10nService',
    ];

    public data: ISSLProfileData;
    public getConfig: () => IExtendedSSLProfile;
    private readonly l10nService: L10nService;
    private readonly schemaService: SchemaService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'sslprofile',
            objectType: 'SSLProfile',
            windowElement: SslTlsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_SSLPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.schemaService = this.getAjsDependency_('schemaService');

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns list of ISSLVersion from string of version names.
     * @param versions - List of selected accepted versions.
     */
    private static getAcceptedVersions(versions: SSLVersionType[] = []): ISSLVersion[] {
        const acceptedVersions = versions.reduce((result, version) => {
            result.push({ type: version });

            return result;
        }, [] as ISSLVersion[]);

        return acceptedVersions;
    }

    /**
     * Returns SSL Profile type.
     */
    public getType(): SSLProfileType {
        return this.getConfig().type;
    }

    /**
     * Getter for SSLRating.
     */
    public get sslRating(): MessageItem<ISSLRating> {
        return this.getConfig().ssl_rating;
    }

    /**
     * Backend translates list of ciphers (enums) into string and provides both but want to
     * support only one type in time - either list or string.
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        if (!isUndefined(config.cipher_enums)) {
            delete config.accepted_ciphers;
            // init cipher list
            this.resetCipherList(true);
        }

        const { accepted_versions: acceptedVersions } = config;

        config.acceptedVersionsList = pluck(acceptedVersions.config, 'type');
    }

    /** @override */
    public dataToSave(): ISSLProfile {
        const config = super.dataToSave();

        if (isUndefined(config.accepted_ciphers)) {
            config.cipher_enums = this.getEnabledCipherList();
        } else {
            delete config.cipher_enums;
        }

        config.accepted_versions = SSLProfile.getAcceptedVersions(config.acceptedVersionsList);

        if (!config.enable_ssl_session_reuse) {
            delete config.ssl_session_timeout;
        }

        if (!this.hasTlsv1_3) {
            delete config.enable_early_data;
        }

        // deleting UI only props.
        delete config.acceptedVersionsList;
        delete config.cipherEnumsTmp;

        return config;
    }

    /**
     * Make changes on ciphers based on cipher types.
     * @param newCipherType - Name of the new cipher type.
     */
    public onCipherTypeChange(newCipherType: CipherType): void {
        const config = this.getConfig();

        if (newCipherType === CipherType.LIST_VIEW) {
            delete config.accepted_ciphers;

            this.resetCipherList();
        } else {
            delete config.cipher_enums;
            delete config.cipherEnumsTmp;

            this.resetCipherString();
        }
    }

    /**
     * Clears acceptedVersionsList.
     */
    public clearAcceptedVersionsList(): void {
        this.config.acceptedVersionsList = [];
    }

    /**
     * Returns true if acceptedVersionsList has TLSv1.3 version.
     */
    public get hasTlsv1_3(): boolean {
        const { acceptedVersionsList = [] } = this.getConfig();

        return acceptedVersionsList.includes(SSLVersionType.SSL_VERSION_TLS1_3);
    }

    /**
     * Returns true if SSLProfile is valid enough to fetch SSL rating.
     */
    public hasAcceptedVersionAndType(): boolean {
        const { acceptedVersionsList, type } = this.getConfig();

        return Boolean(acceptedVersionsList?.length && type);
    }

    /**
     * Fetches SSL rating of SSLProfile.
     */
    public fetchSSLRating(): ng.IPromise<ISSLRating> {
        this.cancelRequests(SSL_PROFILE_CHECK_ID);

        const payload = this.dataToSave();

        return this.request(
            'post',
            SSL_PROFILE_CHECK_URL,
            payload,
            null,
            SSL_PROFILE_CHECK_ID,
        ).then(({ data }: ng.IHttpResponse<ISSLProfile>) => {
            const { ssl_rating: sslrating } = data;

            return sslrating;
        });
    }

    /**
     * Returns combined string of accepted versions based on enum SSLVersionType.
     */
    public getAcceptedVersionsString(): string {
        const { accepted_versions: acceptedVersions } = this.getConfig();

        // getting the actual value for the accepted version keys from enum SSLVersionType.
        const acceptedVersionsValue: string[] = pluck(acceptedVersions.config, 'type').map(
            (version: string): string => this.schemaService
                .getEnumValueLabel('SSLVersionType', version),
        );

        return acceptedVersionsValue.join(', ');
    }

    /**
     * @override
     */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: SslRatingPreviewContainerComponent as Type<Component>,
            previewComponentProps: {
                config: params.editable.getConfig(),
            },
        };
    }

    /**
     * @override
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.sslProfileModalBreadcrumbTitle);
    }

    /**
     * Returns enabled cipher list based on cipher enum cache.
     */
    private getEnabledCipherList(): AcceptedCipherEnums[] {
        const cipherEnums: AcceptedCipherEnums[] = [];
        const { cipherEnumsTmp } = this.getConfig();

        if (cipherEnumsTmp) {
            cipherEnumsTmp.forEach((cipher: ICipherObject) => {
                if (cipher.enabled) {
                    cipherEnums.push(cipher.cipher);
                }
            });
        }

        return cipherEnums;
    }

    /**
     * Resets enabled flag through all ciphers we have in the cipher cache list.
     * @param init - True if called when cipher list is being initiated.
     */
    private resetCipherList(init = false): void {
        const config = this.getConfig();
        const { cipher_enums: cipherEnums } = config;
        const { cipher_enums: defaultCipherEnums } = this.getDefaultConfig();

        let editableCiphersHash: Record<string, AcceptedCipherEnums> = {};

        config.cipherEnumsTmp = this.getCipherObjectList();

        if (init && cipherEnums) {
            // create a dictionary of ciphers we have in editable
            editableCiphersHash = invert(cipherEnums);
        } else {
            // create a dictionary of ciphers by default values
            editableCiphersHash = invert(defaultCipherEnums);
        }

        config.cipherEnumsTmp.forEach((cipher: ICipherObject) => {
            // For creation of ssl profile enable safe ciphers only.
            if (this.id && cipherEnums || cipher.securityScore === 100) {
                cipher.enabled = cipher.cipher in editableCiphersHash;
            }
        });

        // Sort first based on if it's enabled or not, and if enabled, sort by index found
        // in the Hash. If not enabled, then sort by Security Score.
        config.cipherEnumsTmp.sort((a, b) => {
            if (a.enabled < b.enabled) {
                return 1;
            } else if (a.enabled > b.enabled) {
                return -1;
            } else if (a.enabled && b.enabled) {
                if (+editableCiphersHash[a.cipher] < +editableCiphersHash[b.cipher]) {
                    return -1;
                } else {
                    return 1;
                }
            }

            if (a.securityScore < b.securityScore) {
                return 1;
            } else if (a.securityScore > b.securityScore) {
                return -1;
            }

            return 0;
        });
    }

    /**
     * Restores default cipher string.
     */
    private resetCipherString(): void {
        const config = this.getConfig();
        const {
            accepted_ciphers: defaultAcceptedCiphers,
            ciphersuites: defaultCiphersuites,
        } = this.getDefaultConfig();

        config.accepted_ciphers = defaultAcceptedCiphers;
        config.ciphersuites = defaultCiphersuites;
    }

    /**
     * Map cipher enum data to cipher object list.
     */
    private getCipherObjectList(): ICipherObject[] {
        // Set of TLS 1.3 ciphers to be marked as PFS ciphers
        const TLS_1_3_CIPHERS = new Set([
            AcceptedCipherEnums.TLS_AES_256_GCM_SHA384,
            AcceptedCipherEnums.TLS_AES_128_GCM_SHA256,
            AcceptedCipherEnums.TLS_CHACHA20_POLY1305_SHA256,
        ]);

        const stringService: StringService = this.getAjsDependency_('stringService');

        const cipherObjectList = this.schemaService.getEnumValues('AcceptedCipherEnums')
            .reduce((cipherObjects: ICipherObject[], val: IEnumValue) => {
                const {
                    value,
                    description,
                    ssl_performance: sslPerformance,
                    ssl_compatibility: sslCompatibility,
                    ssl_security: sslSecurity,
                    ssl_security_score: sslSecurityScore,
                } = val;

                const isPfs = value.includes('ECDHE') ||
                    TLS_1_3_CIPHERS.has(value as AcceptedCipherEnums);

                cipherObjects.push({
                    cipher: value as AcceptedCipherEnums,
                    description,
                    performance: stringService.enumeration(sslPerformance, sslScorePrefix),
                    compatibility: stringService.enumeration(sslCompatibility, sslScorePrefix),
                    security: stringService.enumeration(sslSecurity, sslScorePrefix),
                    securityScore: sslSecurityScore,
                    enabled: false,
                    pfs: isPfs,
                });

                return cipherObjects;
            }, []);

        return cipherObjectList;
    }
}
