/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    AuthMappingProfileItem,
    AuthMappingRuleConfigItem,
} from 'ajs/modules/auth-settings/factories';

import { AuthProfileType } from 'generated-types';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IRowReorder } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './auth-mapping-profile-modal.l10n';

const { ENGLISH, ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for AuthMappingProfile Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-mapping-profile-modal',
    templateUrl: './auth-mapping-profile-modal.component.html',
})
export class AuthMappingProfileModalComponent implements OnInit {
    /**
     * AuthMappingProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: AuthMappingProfileItem;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * ObjectType of this.editable.
     */
    public objectType: string;

    /**
     * AuthProfile Type dropdown options.
     */
    public authProfileTypeOptions: IAviDropdownOption[] = [];

    constructor(
        schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const authProfileTypes = schemaService.getEnumValues('AuthProfileType');

        this.authProfileTypeOptions = createOptionsFromEnumProps(authProfileTypes);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Adds a mapping rule.
     */
    public addAuthMappingRule(): void {
        this.editable.addAuthMappingRule(this.isLdapProfile);
    }

    /**
     * Edits a mapping rule.
     */
    public editAuthMappingRule(mappingRule: AuthMappingRuleConfigItem): void {
        this.editable.editAuthMappingRule(mappingRule, this.isLdapProfile);
    }

    /**
     * Deletes a mapping rule.
     */
    public deleteAuthMappingRule(mappingRule: AuthMappingRuleConfigItem): void {
        this.editable.mappingRules.removeByMessageItem(mappingRule);
    }

    /**
     * Handle Row reorder event emitted from grid.
     * Update array index as well as `index` property.
     */
    public handleAuthMappingRuleGridRowReorder({
        previousIndex,
        currentIndex,
    }: IRowReorder): void {
        this.editable.mappingRules.moveByIndex(previousIndex, currentIndex);
    }

    /**
     * Returns true if profile type is ldap.
     */
    private get isLdapProfile(): boolean {
        return this.editable.type === AuthProfileType.AUTH_PROFILE_LDAP;
    }
}
