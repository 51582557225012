/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing update related components.
 * @preferred
 * @module UpdateModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
    NodeType,
    UpgradeFsmState,
} from 'generated-types';

import {
    nodeTypesHash,
    UpgradeStateGroup,
} from 'ng/modules/update/update.types';

import { ITUpgradeNode, UpgradeNodeCollection } from 'ajs/modules/upgrade';
import './recent-updates-list.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './recent-updates-list.l10n';
import { UpdateStore } from '../update.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Panel card for update pages.
 * @author Akul Aggarwal, Sarthak Kapoor
 */
@Component({
    selector: 'recent-updates-list',
    templateUrl: './recent-updates-list.component.html',
})
export class RecentUpdatesListComponent implements OnInit, OnDestroy {
    /**
     * Types of nodes to ask API for.
     * i.e. controller or seg
     */
    @Input()
    public nodeType: NodeType;

    @Input()
    public precheckNodes: ITUpgradeNode[] = [];

    @Input()
    public prechecksTriggered = false;

    @Input()
    public selectedSegIds: string[] = [];

    @Input()
    public overallPrechecksState: UpgradeFsmState;

    /**
     * Event Emitter to update parent component to update selected Seg Ids list.
     */
    @Output()
    public selectedSegIdsChange = new EventEmitter<string[]>();

    /**
     * List of nodes. Can be either precheck nodes or upgrade nodes.
     */
    public nodeList: ITUpgradeNode[] = [];

    public isPrecheckFlow = false;

    /**
     * Flag to identify if the received node type is Controller.
     */
    public isSystemUpdate = false;

    /**
     * Hold the latest values for user selected options.
     */
    public readonly userSelectedOptions$ = this.updateStore.userSelectedOptions$;

    /**
     * Hold the updated list of selected SEG Upgrade Image Ids.
     */
    public readonly selectedSegUpgradeImages$ = this.updateStore.selectedSegUpgradeImages$;

    public readonly rollbackVersion$ = this.updateStore.rollbackVersion$;

    public readonly selectedSegs$ = this.updateStore.selectedSegs$;

    public readonly isUpgradeTriggeredFromSystemUpdatePage$ =
    this.updateStore.isUpgradeTriggeredFromSystemUpdatePage$;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Instance of UpgradeNodeCollection.
     */
    public upgradeNodeCollection: UpgradeNodeCollection;

    /**
     * Subcription to get updates on successful trigger of Prechecks.
     */
    private preChecksCompletedSubscription: Subscription;

    private isPrecheckFlowSubscription: Subscription;

    private overallPrechecksStateSubscription: Subscription;

    /**
     * Flag to keep a track if polling is in progress.
     */
    private isPollingInProgress = false;

    /**
     * Getter for empty list place holder. Decided by what's expected to be displayed in the list.
     */
    public get emptyListPlaceHolder(): string {
        const {
            emptyListPlaceholderController,
            emptyListPlaceholderSeg,
        } = this.l10nKeys;

        if (this.isSystemUpdate) {
            return emptyListPlaceholderController;
        }

        return emptyListPlaceholderSeg;
    }

    public constructor(
        @Inject('UpgradeNodeCollection')
        private UpgradeNodeCollectionFactory: typeof UpgradeNodeCollection,
        private readonly updateStore: UpdateStore,
        l10nService: L10nService,
    ) {
        this.upgradeNodeCollection = new UpgradeNodeCollectionFactory({
            bind: {
                collectionLoadSuccess: this.onCollectionLoad,
            },
        });
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Trigger polling for UPGRADE_FSM_UI_COMPLETED and UPGRADE_FSM_UI_ERROR state by default.
     * In case the last triggered operation was the pre check flow, stop the polling.
     * Once the user re triggers the pre checks, if the checks complete with overall state
     * as success, re trigger the polling else if the polling was in progress, then stop it.
     */
    public ngOnInit(): void {
        const { updateStore } = this;

        this.upgradeNodeCollection.setParams({
            node_type: this.nodeType,
            state: `${UpgradeStateGroup.GROUP_COMPLETED},${UpgradeStateGroup.GROUP_ERROR}`,
        });

        this.triggerPolling();

        this.isPrecheckFlowSubscription = updateStore.isPreCheckFlow$.subscribe(
            (isPrecheckFlow: boolean) => {
                this.isPrecheckFlow = isPrecheckFlow;

                if (isPrecheckFlow) {
                    this.stopPolling();
                }
            },
        );

        this.preChecksCompletedSubscription = updateStore.preChecksCompleted$.subscribe(
            (preChecksCompleted: boolean) => {
                if (preChecksCompleted) {
                    if (this.overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS) {
                        this.triggerPolling();
                    } else {
                        this.stopPolling();
                    }
                }
            },
        );

        this.isSystemUpdate = this.nodeType === nodeTypesHash.NODE_CONTROLLER_CLUSTER;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.precheckNodes?.currentValue) {
            this.nodeList = [...changes.precheckNodes.currentValue];
        }

        if (
            changes.overallPrechecksState?.currentValue &&
            !changes.overallPrechecksState.firstChange
        ) {
            if (
                !this.isPollingInProgress &&
                this.overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS
            ) {
                this.triggerPolling();
            }
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.upgradeNodeCollection.destroy();
        this.preChecksCompletedSubscription.unsubscribe();
        this.isPrecheckFlowSubscription.unsubscribe();
    }

    /**
     * Used by repeat (*ngFor) to track by unique identifier.
     */
    public trackNodeList(i: number, node: ITUpgradeNode): string {
        return node.id;
    }

    /**
     * Trigger onSelectedSegIdsUpdate to parent component to update the list.
     */
    public triggerSelectedSegIdUpdateEvent(selectedSegId: string): void {
        this.selectedSegIdsChange.emit([selectedSegId]);
    }

    /**
     * Start polling for UpgradeStatusInfo to fetch the details of latest completed operation.
     */
    private triggerPolling(): void {
        this.upgradeNodeCollection.load();
        this.isPollingInProgress = true;
    }

    private stopPolling(): void {
        const { upgradeNodeCollection } = this;

        if (this.isPollingInProgress) {
            upgradeNodeCollection.stopUpdates();
            this.nodeList = [];
            this.isPollingInProgress = false;
        }
    }

    private onCollectionLoad = (): void => {
        const {
            selectedSegIds,
            upgradeNodeCollection,
        } = this;

        if (this.isSystemUpdate) {
            this.nodeList = [...upgradeNodeCollection?.itemList as ITUpgradeNode[]];
        } else {
            this.nodeList = [...upgradeNodeCollection.getSelectedSegNodes(selectedSegIds)];
        }
    };
}
