/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    any,
    indexOf,
    isEmpty,
    pluck,
} from 'underscore';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { WafPolicyPsmGroup } from './waf-policy-psm-group.item.factory';

interface IWafPositiveSecurityModel {
    group_refs_data?: WafPolicyPsmGroup[];
    group_refs?: string[];
}

/**
 * @description
 * Config Item for WafPositiveSecurityModel message.
 * When the WAF Policy is loaded, it uses join to load the group_refs_data as well. group_refs_data
 * contains a list of PSM groups, which have their own modals for creating and editing. The
 * group_refs and group_refs_data are kept in sync, and before saving we delete the group_refs_data
 * to avoid overwriting any saved PSM Group configuration.
 * @author Hitesh Mandav, alextsg
 */
export class WafPositiveSecurityModelConfigItem extends MessageItem<IWafPositiveSecurityModel> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafPositiveSecurityModel',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IWafPositiveSecurityModel> {
        return {
            group_refs: [],
            group_refs_data: [],
        };
    }

    /**
     * Getter for the list of PSM Groups.
     */
    public get groups(): WafPolicyPsmGroup[] {
        return this.config.group_refs_data || [];
    }

    /**
     * Getter for the UUIDs of the list of PSM Groups.
     */
    public get groupIds(): string[] {
        return pluck(this.groups, 'id');
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        const WafPolicyPsmGroupDependency = this.getAjsDependency_('WafPolicyPsmGroup');
        const { group_refs_data: groupRefsData = [] } = this.config;

        this.config.group_refs_data = groupRefsData.map(groupConfig => {
            return groupConfig instanceof WafPolicyPsmGroupDependency ?
                groupConfig :
                new WafPolicyPsmGroupDependency({
                    loadOnEdit: false,
                    data: {
                        config: groupConfig,
                    },
                });
        });
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        const { group_refs_data: groupRefsData = [] } = this.config;

        if (this.isClone(groupRefsData)) {
            this.config.group_refs_data = groupRefsData.map(groupItem => {
                return groupItem.dataToSave() as WafPolicyPsmGroup;
            });
        } else {
            delete this.config.group_refs_data;
        }
    }

    /** @override */
    public canFlatten(): boolean {
        const { group_refs: groupRefs, group_refs_data: groupRefsData } = this.config;

        return !isEmpty(groupRefs) || !isEmpty(groupRefsData);
    }

    /**
     * Returns true if configured groups exist.
     */
    public hasGroups(): boolean {
        return this.groups.length > 0;
    }

    /**
     * Return true if psmGroups are clones, by checking that each psmGroup doesn't have a url.
     */
    public isClone(psmGroups: WafPolicyPsmGroup[]): boolean {
        return !psmGroups.some(psmGroup => psmGroup.config.url);
    }

    /**
     * Returns true if any of the PSM groups is a learning group.
     */
    public hasLearningGroup(): boolean {
        return any(this.groups, (group: WafPolicyPsmGroup) => group.isLearningGroup);
    }

    /**
     * Adds a new PSM Group.
     */
    public addGroup(newGroup: WafPolicyPsmGroup): void {
        const { group_refs_data: groups, group_refs: groupRefs } = this.config;

        groupRefs.push(newGroup.getRef());
        groups.push(newGroup);
    }

    /**
     * Replaces an existing group using an index.
     */
    public editGroup(updatedGroup: WafPolicyPsmGroup, index: number): void {
        const { group_refs: groupRefs, group_refs_data: groups } = this.config;

        groupRefs[index] = updatedGroup.getRef();
        groups[index] = updatedGroup;
    }

    /**
     * Deletes a group from the list of configured groups.
     */
    public deleteGroup(group: WafPolicyPsmGroup): void {
        const { group_refs_data: configuredGroups, group_refs: groupRefs } = this.config;
        const index = indexOf(configuredGroups, group);

        if (index > -1) {
            groupRefs.splice(index, 1);
            configuredGroups.splice(index, 1);
        }
    }

    /**
     * Moves group to a new index. All groups in-between need to have their indices shifted.
     */
    public moveGroup(oldIndex: number, newIndex: number): void {
        let newIndexCounter = newIndex;
        /**
         * newIndex moves towards the direction of oldIndex
         */
        const increment = oldIndex < newIndex ? -1 : 1;

        while (oldIndex !== newIndexCounter) {
            this.swapGroup(oldIndex, newIndexCounter);
            newIndexCounter += increment;
        }
    }

    /**
     * Returns true if any group has null id.
     */
    public get groupContainsNullId(): boolean {
        return this.config.group_refs_data.reduce((isIdNull, group) => {
            if (isIdNull) return true;
            if (group.id === null) return true;

            return false;
        }, false);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'group_refs',
        ];
    }

    /**
     * Given two indices of groups, swaps positions in the config.
     */
    private swapGroup(oldIndex: number, newIndex: number): void {
        const { config } = this;
        const { group_refs_data: groupRefsData = [], group_refs: groupRefs } = config;

        const oldGroup = groupRefsData[oldIndex];
        const newGroup = groupRefsData[newIndex];

        groupRefs[oldIndex] = newGroup.getRef();
        groupRefs[newIndex] = oldGroup.getRef();
        groupRefsData[oldIndex] = newGroup;
        groupRefsData[newIndex] = oldGroup;
    }
}

WafPositiveSecurityModelConfigItem.ajsDependencies = [
    'WafPolicyPsmGroup',
];
