/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { HardwareSecurityModule } from 'object-types';
import { IHardwareSecurityModule } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { HSMSafenetLunaConfigItem } from './hsm-safenet-luna.config-item.factory';
import { HSMAwsCloudHsmConfigItem } from './hsm-aws-cloud-hsm.config-item.factory';

type THardwareSecurityModulePartial = Omit<IHardwareSecurityModule, 'cloudhsm' | 'sluna'>;

interface IHardwareSecurityModuleConfig extends THardwareSecurityModulePartial {
    sluna?: HSMSafenetLunaConfigItem;
    cloudhsm?: HSMAwsCloudHsmConfigItem;
}

/**
 * @description HSM Group Hsm Message Item.
 * @author vgohil
 */
export class HardwareSecurityModuleConfigItem extends MessageItem<IHardwareSecurityModuleConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HardwareSecurityModule,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns Thales Luna HSM Message Item.
     */
    public get safenetLuna(): HSMSafenetLunaConfigItem {
        return this.config.sluna;
    }

    /**
     * Method to add Thales Luna HSM Message Item.
     */
    public addSafenetLuna(): void {
        this.safeSetNewChildByField('sluna');
    }

    /**
     * Returns AWS CloudHSM Message Item.
     */
    public get cloudHsm(): HSMAwsCloudHsmConfigItem {
        return this.config.cloudhsm;
    }

    /**
     * Method to add AWS CloudHSM Message Item.
     */
    public addCloudHsm(): void {
        this.safeSetNewChildByField('cloudhsm');
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'sluna',
            'cloudhsm',
        ];
    }
}
