/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ErrorPageProfileCollection,
    TErrorPageProfileCollection,
} from 'ajs/modules/error-page/factories/error-page-profile.collection.factory';

import { ErrorPageProfile }
    from 'ajs/modules/error-page/factories/error-page-profile.item.factory';

import { ErrorPageConfigItem }
    from 'ajs/modules/error-page/factories/error-page.config-item.factory';

import { IAviCollectionDataGridConfig } from
    'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './error-page-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Error profiles list page to show all the error profiles.
 * @author Hitesh, Vinay
 */
@Component({
    selector: 'error-page-profile-list',
    templateUrl: './error-page-profile-list.component.html',
})
export class ErrorPageProfileListComponent implements OnInit, OnDestroy {
    @ViewChild('errorPagesTemplateRef')
    public readonly errorPagesTemplateRef: TemplateRef<HTMLElement>;

    public errorPageProfileGridConfig: IAviCollectionDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    private readonly errorPageProfileCollection: ErrorPageProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(ErrorPageProfileCollection)
        ErrorPageProfileCollection: TErrorPageProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.errorPageProfileCollection = new ErrorPageProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.errorPageProfileCollection;

        this.errorPageProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.errorPageProfileCollection,
            defaultSorting: 'name',
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    public ngAfterViewInit(): void {
        this.errorPageProfileGridConfig.fields = [
            {
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: ErrorPageProfile): string => row.getName(),
            },
            {
                id: 'error-pages',
                label: this.l10nService.getMessage(l10nKeys.errorPagesLabel),
                templateRef: this.errorPagesTemplateRef,
            },
        ];
    }

    /**
     * Return StatusCodes from ErrorPageProfile.
     */
    public getStatusCodes(row: ErrorPageProfile): string {
        const { config: { error_pages: { config: pagesConfig } } } = row;

        return pagesConfig.map(
            (page: ErrorPageConfigItem) => page.getErrorPageStatusCodes().join(', '),
        ).join(', ');
    }

    /** @override */
    public ngOnDestroy(): void {
        this.errorPageProfileCollection.destroy();
    }
}
