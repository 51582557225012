/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories';
import { IAlertSyslogServer } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    AlertSyslogServerModalComponent,
} from 'ng/modules/alert/components/alert-syslog-server-modal/alert-syslog-server-modal.component';
import { AlertSyslogServer } from 'object-types';
import * as l10n from '../../alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Syslog servers config Item.
 *
 * @author Guru Prasad
 */
export class AlertSyslogServerConfigItem extends
    withFullModalMixin(MessageItem)<IAlertSyslogServer> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AlertSyslogServer,
            windowElement: AlertSyslogServerModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertSyslogServerBreadcrumbTitle);
    }
}
