<div
    #searchBarContainer
    class="vs-logs-search-bar"
    *ngrxLet="vsLogsStore.hasFilters$ as hasFilters"
>
    <cds-icon
        #searchIcon
        shape="search"
        size="18"
    ></cds-icon>

    <div
        class="vs-logs-search-bar__input-container"
        avi-tooltip
        [positionsPriority]="dropdownPosition"
        [showOnControlOnly]="true"
        [tooltipControl$]="vsLogsSearchBarDropdownService.dropdownControl$"
        [overlayMaxWidth]="dropdownWidth"
        (onBackdropClick)="vsLogsSearchBarDropdownService.handleDropdownBackdropClick()"
    >
        <ng-container *ngrxLet="vsLogsStore.filters$ as filters">
            <span
                class="vs-logs-search-bar__filter-container"
                *ngFor="let filter of filters; index as i; trackBy: trackByIndex"
                [title]="filter"
            >
                <vs-logs-editable-filter
                    [filter]="filter"
                    (onFilterOpen)="vsLogsSearchBarStore.setEdit(i)"
                    (onFilterUpdate)="vsLogsSearchBarStore.editFilter(i)"
                    (onFilterRemove)="vsLogsStore.removeFilter(i)"
                ></vs-logs-editable-filter>
            </span>
        </ng-container>

        <vs-logs-filter-input
            class="vs-logs-search-bar__input"
            [isPrimary]="true"
            (onSubmit)="vsLogsSearchBarStore.addFilter()"
            (onBackspace)="vsLogsSearchBarStore.handleBackspace()"
            [placeholder]="hasFilters ? '' : (l10nKeys.filterPlaceholder | vtranslate)"
            [hidden]="vsLogsSearchBarStore.isFilterBeingEdited$ | async"
        ></vs-logs-filter-input>

        <ng-template #aviTooltipContent>
            <vs-logs-search-bar-dropdown [dropdownWidth]="dropdownWidth"></vs-logs-search-bar-dropdown>
        </ng-template>
    </div>

    <cds-icon
        class="vs-logs-search-bar__button"
        shape="times-circle"
        size="18"
        (click)="vsLogsStore.removeAllFilters()"
        [hidden]="!hasFilters"
    ></cds-icon>
    <ng-container *ngrxLet="vsLogsStore.isCurrentFilterSaved$ as isCurrentFilterSaved">
        <cds-icon
            *ngIf="hasFilters && !isCurrentFilterSaved"
            class="vs-logs-search-bar__button"
            shape="star"
            size="18"
            (click)="openSaveSearchModal(SaveSearchTypeEnum.CREATE)"
        ></cds-icon>
        <cds-icon
            *ngIf="isCurrentFilterSaved"
            class="vs-logs-search-bar__saved-search-star"
            shape="solid-star"
            size="18"
            (click)="openSaveSearchModal(SaveSearchTypeEnum.EDIT)"
        ></cds-icon>
    </ng-container>
    <div
        class="vs-logs-search-bar__logs-label"
        *ngrxLet="vsLogsStore.totalLogsCount$ as totalLogsCount"
        [hidden]="vsLogsStore.hasError$ | async"
    >
        {{ ((vsLogsStore.tableIsLoading$ | async) ? '' : totalLogsCount.toLocaleString()) }}
        {{ (totalLogsCount === 1 ? globalL10nKeys.logLabel : globalL10nKeys.logsLabel) | vtranslate }}
    </div>
</div>
