<h6>{{ l10nKeys.dnsOptRecordLabel | vtranslate }}</h6>

<div class="vs-log-cinematic-dns-opt-record__column-group">
    <!-- 1st column -->
    <div vsLogCinematicSectionColumn>
        <div
            vsLogCinematicFieldTitle
            [withMarginTop]="false"
        >
            {{ globalL10nKeys.versionLabel | vtranslate }}
        </div>
        <span
            vsLogFilter
            property="dns_{{ dnsMessageType }}.opt_record.version"
            [value]="dnsOptRecord.version"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            *ngIf="dnsOptRecord.version !== undefined; else na"
        >
            {{ dnsOptRecord.version }}
        </span>
    </div>

    <!-- 2nd column -->
    <div vsLogCinematicSectionColumn>
        <div
            vsLogCinematicFieldTitle
            [withMarginTop]="false"
        >
            {{ l10nKeys.udpPayloadSizeLabel | vtranslate }}
        </div>
        <span
            vsLogFilterList
            property="dns_{{ dnsMessageType }}.opt_record.udp_payload_size"
            [value]="dnsOptRecord.udp_payload_size"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            *ngIf="dnsOptRecord.udp_payload_size !== undefined; else na"
        >
            {{ dnsOptRecord.udp_payload_size }} {{ globalL10nKeys.shortBytesLabel | vtranslate}}
        </span>
    </div>

    <!-- 3rd column -->
    <div vsLogCinematicSectionColumn>
        <div
            vsLogCinematicFieldTitle
            [withMarginTop]="false"
        >
            {{ l10nKeys.dnssecLabel | vtranslate }}
        </div>
        <span
            vsLogFilter
            property="dns_{{ dnsMessageType }}.opt_record.dnssec_ok"
            [value]="dnsOptRecord.dnssec_ok"
            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            *ngIf="dnsOptRecord.dnssec_ok !== undefined; else na"
        >
            {{ dnsOptRecord.dnssec_ok }}
        </span>
    </div>
</div>

<div *ngIf="dnsOptRecord.options as options">
    <h6>{{ l10nKeys.ednsRecordsLabel | vtranslate:options.length }}</h6>
    <avi-data-grid
        [config]="endsRecordsGridConfig"
        [rows]="options"
    ></avi-data-grid>
</div>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template
    #ednsOptionCodeFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="dns_{{ dnsMessageType }}.opt_record.options.code"
        [value]="code"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.code as code; else na"
    >
        {{ enumLabelHash[code] || code }}
    </span>
</ng-template>

<ng-template
    #sourcePrefixLengthFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilterList
        property="dns_{{ dnsMessageType }}.opt_record.options.source_prefix_len"
        [value]="row.source_prefix_len"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.source_prefix_len !== undefined; else na"
    >
        {{ row.source_prefix_len }}
    </span>
</ng-template>

<ng-template
    #scopePrefixLengthLabel
    let-row
    let-index="index"
>
    <span
        vsLogFilterList
        property="dns_{{ dnsMessageType }}.opt_record.options.scope_prefix_len"
        [value]="row.scope_prefix_len"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.scope_prefix_len !== undefined; else na"
    >
        {{ row.scope_prefix_len }}
    </span>
</ng-template>

<ng-template
    #subnetFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogPartialSelectionFilter
        [property]="getSubnetFilterProperty(row.subnet_ip6)"
        [value]="row.subnet_ip6 || row.subnet_ip"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.subnet_ip6 || row.subnet_ip !== undefined; else na"
    ></span>
</ng-template>
