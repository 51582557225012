/** @module HeaderModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Output,
} from '@angular/core';
import { ClrLoadingState } from '@cds/core/button';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { ActiveUserProfileService } from 'ajs/modules/core/services/active-user-profile';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import * as l10n from './generate-sso-token-dialog.l10n';
import './generate-sso-token-dialog.component.less';

type THttpWrapper = typeof HttpWrapper;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author alextsg
 * @desc
 *     Dialog component allowing the user to generate an SSO token. The user is able to enter the
 *     number of hours for the lifetime of the token.
 */
@Component({
    selector: 'generate-sso-token-dialog',
    templateUrl: './generate-sso-token-dialog.component.html',
})
export class GenerateSsoTokenDialogComponent {
    /**
     * Called on dialog close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Called on dialog submit.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Loading state of the submit button. Set to 'loading' when submitting the request.
     */
    public loadingState: ClrLoadingState = ClrLoadingState.default;

    /**
     * Error state of the component. Set to a backend error when the request to generate token
     * fails.
     */
    public error: string | null = null;

    /**
     * Number of hours until the token expires. Set as an ngModel for its input field.
     */
    public hours: number;

    /**
     * Generated token from the backend. Set as a readonly value for its input field.
     */
    public token: string;

    /**
     * Minimum value for token life time field.
     */
    public minTokenLifetimeValue = 0;

    /**
     * Maximum value for token life time field.
     */
    public maxTokenLifetimeValue = 87600;

    /**
     * Username to be shown in the dialog.
     */
    public readonly username: string;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * HttpWrapper instance. Used to make the request to generate a token.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
        l10nService: L10nService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
        activeUserProfileService: ActiveUserProfileService,
    ) {
        this.httpWrapper = new HttpWrapper();
        this.username = activeUserProfileService.username;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Makes a backend request to generate a token.
     */
    public async generateToken(): Promise<void> {
        const requestConfig = {
            url: '/api/user-token',
            data: { hours: this.hours },
            method: HttpMethod.POST,
        };

        try {
            this.error = null;
            this.loadingState = ClrLoadingState.loading;

            const { data } = await this.httpWrapper.request(requestConfig);

            this.token = data.token;
        } catch ({ data }) {
            this.error = data.error;
        } finally {
            this.loadingState = ClrLoadingState.default;
        }
    }

    /**
     * Called to close the dialog.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
