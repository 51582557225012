/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-replication-status-popover';
const prefix = `${moduleName}.${componentName}`;

export const replicationStatus = `${prefix}.replicationStatus`;
export const reason = `${prefix}.reason`;
export const recommendation = `${prefix}.recommendation`;
export const objectPending = `${prefix}.objectPending`;
export const objectsPending = `${prefix}.objectsPending`;

export const ENGLISH = {
    [replicationStatus]: 'Replication Status',
    [reason]: 'Reason',
    [recommendation]: 'Recommendation',
    [objectPending]: 'Object Pending',
    [objectsPending]: 'Objects Pending',
};
