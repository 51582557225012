<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="ssl-tls-profile-modal"
>
    <full-modal-config-viewer
        class="full-modal-config-header__config-viewer"
        [item]="editable"
    ></full-modal-config-viewer>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        class="sel-name"
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        [disabled]="editable.isProtected()"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-ssl-tls-type"
                            name="type"
                            [(ngModel)]="editable.config.type"
                            [options]="typeDropdownOptions"
                            placeholder="{{ l10nKeys.typeSelectPlaceholder | vtranslate }}"
                            [disabled]="isEditing"
                            (ngModelChange)="onSslProfileTypeChange()"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="accepted_versions"
                            for="accepted_versions"
                            required
                        >
                            {{ l10nKeys.acceptedVersionsLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            class="sel-accepted-versions"
                            name="accepted_versions"
                            [(ngModel)]="editable.config.acceptedVersionsList"
                            [options]="versionDropdownOptions"
                            placeholder="{{ l10nKeys.acceptedVersionsPlaceholder | vtranslate }}"
                            [multiple]="true"
                            (ngModelChange)="emitSslProfileUpdate()"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <fieldset class="full-modal-config__fieldset">
                    <avi-checkbox
                        [noMarginTop]="true"
                        objectType="{{ objectType }}"
                        fieldName="enable_ssl_session_reuse"
                        [(ngModel)]="editable.config.enable_ssl_session_reuse"
                        name="enable_ssl_session_reuse"
                    >
                        {{ l10nKeys.enableSslSessionReuseCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    <div
                        class="ssl-tls-profile-modal__session-timeout-field"
                        *ngIf="editable.config.enable_ssl_session_reuse"
                    >
                        <avi-input-container
                            helperText="{{ l10nKeys.secondsHelperText | vtranslate }}"
                            noMarginTop
                        >
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="ssl_session_timeout"
                                for="ssl_session_timeout"
                            >
                                {{ l10nKeys.sslSessionExpirationInputLabel | vtranslate }}
                            </label>
                            <input
                                class="sel-ssl-session-expiration"
                                id="ssl_session_timeout"
                                name="ssl_session_timeout"
                                type="number"
                                [(ngModel)]="editable.config.ssl_session_timeout"
                                clrInput
                                placeholder="{{ l10nKeys.sslSessionExpirationInputPlaceholder | vtranslate }}"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="ssl_session_timeout"
                            />
                        </avi-input-container>
                    </div>
                </fieldset>
                <avi-checkbox-container-group>
                    <avi-checkbox
                        *ngIf="hasTlsv1_3"
                        objectType="{{ objectType }}"
                        fieldName="enable_early_data"
                        [(ngModel)]="editable.config.enable_early_data"
                        name="enable-early-data"
                    >
                        {{ l10nKeys.enableEarlyDataCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    <avi-checkbox
                        objectType="{{ objectType }}"
                        fieldName="send_close_notify"
                        [(ngModel)]="editable.config.send_close_notify"
                        name="send-close-notify"
                    >
                        {{ l10nKeys.sendCloseNotifyCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    <avi-checkbox
                        objectType="{{ objectType }}"
                        fieldName="prefer_client_cipher_ordering"
                        [(ngModel)]="editable.config.prefer_client_cipher_ordering"
                        name="prefer-client-cipher-ordering"
                    >
                        {{ l10nKeys.preferClientCipherOrderingCheckboxLabel | vtranslate }}
                    </avi-checkbox>
                    <avi-checkbox
                        [disabled]="!!editable.id"
                        objectType="{{ objectType }}"
                        fieldName="is_federated"
                        [(ngModel)]="editable.config.is_federated"
                        name="is-federated"
                    >
                        {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                    </avi-checkbox>
                </avi-checkbox-container-group>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleCiphers | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleCiphers | vtranslate }}</h3>
                <avi-radio-container>
                    <avi-radio
                        class="sel-select-from-list sel-radio"
                        name="cipherType"
                        [(ngModel)]="cipherType"
                        value="{{ CipherTypeEnum.LIST_VIEW }}"
                        (ngModelChange)="onCipherTypeChange()"
                    >
                        {{ l10nKeys.selectListRadioButtonLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        class="sel-enter-string sel-radio"
                        name="cipherType"
                        [(ngModel)]="cipherType"
                        value="{{ CipherTypeEnum.STRING_VIEW }}"
                        (ngModelChange)="onCipherTypeChange()"
                    >
                        {{ l10nKeys.enterStringRadioButtonLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
                <ssl-ciphers-grid
                    *ngIf="isListSelected"
                    [ciphers]="editable.config.cipherEnumsTmp"
                    (onCipherSelectionChange)="emitSslProfileUpdate()"
                ></ssl-ciphers-grid>
                <div *ngIf="!isListSelected">
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="accepted_ciphers"
                            for="accepted_ciphers"
                        >
                            {{ l10nKeys.acceptedCiphersInputLabel | vtranslate }}
                        </label>
                        <input
                            class="sel-accepted-ciphers"
                            id="accepted_ciphers"
                            name="accepted_ciphers"
                            [(ngModel)]="editable.config.accepted_ciphers"
                            clrInput
                            placeholder="{{ l10nKeys.acceptedCiphersInputPlaceholder | vtranslate }}"
                            (ngModelChange)="emitSslProfileUpdate()"
                        />
                    </avi-input-container>
                    <avi-input-container *ngIf="hasTlsv1_3">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ciphersuites"
                            for="ciphersuites"
                        >
                            {{ l10nKeys.ciphersuitesInputLabel | vtranslate }}
                        </label>
                        <input
                            class="sel-cipher-suites"
                            id="ciphersuites"
                            name="ciphersuites"
                            [(ngModel)]="editable.config.ciphersuites"
                            clrInput
                            placeholder="{{ l10nKeys.ciphersuitesInputPlaceholder | vtranslate }}"
                            (ngModelChange)="emitSslProfileUpdate()"
                        />
                    </avi-input-container>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.tabTitleRbac | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleRbac | vtranslate }}</h3>
                <role-filter-match-label
                    objectType="{{ objectType }}"
                    fieldName="markers"
                    [markers]="editable.config.markers"
                ></role-filter-match-label>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
