/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ProtocolParserCollection,
    TProtocolParserCollection,
} from 'ajs/modules/scripts/factories/protocol-parser/protocol-parser.collection.factory';

import { ProtocolParser }
    from 'ajs/modules/scripts/factories/protocol-parser/protocol-parser.item.factory';

import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './protocol-parser-script.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description ProtocolParserScripts list page.
 * @author sghare
 */
@Component({
    selector: 'protocol-parser-script',
    templateUrl: './protocol-parser-script.component.html',
})
export class ProtocolParserScriptComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display ProtocolParserScripts collection.
     */
    public protocolParserScriptGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the ProtocolParser items.
     */
    private protocolParserScriptCollection: ProtocolParserCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('ProtocolParserCollection')
        ProtocolParserCollection: TProtocolParserCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.protocolParserScriptCollection = new ProtocolParserCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.protocolParserScriptCollection;

        this.protocolParserScriptGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.protocolParserScriptCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: ProtocolParser): string => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.protocolParserScriptCollection.destroy();
    }
}
