/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { TRAFFIC_CLONE_PROFILE_ITEM_TOKEN } from '../traffic-clone-profile.tokens';

/**
 * @description Traffic Clone Profile collection
 *
 * @author Harmeet Kaur
 */

export class TrafficCloneProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'trafficcloneprofile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_TRAFFICCLONEPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.serverDefaultsOverride_ = {
            cloud_ref: undefined,
        };

        this.itemClass_ = this.getAjsDependency_(TRAFFIC_CLONE_PROFILE_ITEM_TOKEN);
    }
}

TrafficCloneProfileCollection.ajsDependencies = [
    TRAFFIC_CLONE_PROFILE_ITEM_TOKEN,
];
