 <avi-dynamic-data-grid
    *ngIf="vsLogsSignpostStore.vsLogsDefaultSignpostData$ | async as vsLogsSignpostData"
    [config]="logListGridConfig"
    [rows]="vsLogsSignpostData"
    [rowsTotal]="vsLogsSignpostData.length"
    [isLoading]="vsLogsSignpostStore.isLoading$ | async"
></avi-dynamic-data-grid>
<div
    class="vs-logs-signpost-default-view-log-list__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <div
        *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
        class="vs-logs-signpost-default-view-log-list__content"
    >
        <span
            *ngIf="row.value.isValid"
            vsLogFilter
            [property]="row.value.param"
            [value]="row.value.value"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
            aviTitle="{{ row.value.value | signpostLogDataTransform: configKey }}"
        >
            {{ row.value.value | signpostLogDataTransform: configKey }}
        </span>
        <ng-container
            *ngIf="!row.value.isValid"
            [ngTemplateOutlet]="noData"
            [ngTemplateOutletContext]="{ $implicit: row.value.param }"
        ></ng-container>
    </div>
</ng-template>

<ng-template
    #rangeFieldTemplateRef
    let-row
    let-index="index"
>
    <div
        *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
        class="vs-logs-signpost-default-view-log-list__content"
    >
        <span
            *ngIf="row.value.isValid"
            vsLogFilterRange
            [property]="row.value.param"
            [start]="row.value.value.start"
            [end]="row.value.value.end"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        >
            {{ row.value.value | signpostLogDataTransform: configKey }}
        </span>
        <ng-container
            *ngIf="!row.value.isValid"
            [ngTemplateOutlet]="noData"
            [ngTemplateOutletContext]="{ $implicit: row.value.param }"
        ></ng-container>
    </div>
</ng-template>

<ng-template
    #progressBarFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-progress-bar
        [percentage]="row.percentage"
        [hidePercentage]="true"
    ></avi-progress-bar>
</ng-template>

<ng-template
    #noData
    let-property
>
    <span
        vsLogFilterableEmptyField
        [property]="property"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
    ></span>
</ng-template>
