/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { BotManagementPolicyModalComponent } from 'ng/modules/bot';
import { BOT_DETECTION_POLICY_ITEM_TOKEN } from '../bot-detection-policy.tokens';

/**
 * Collection for Bot Detection Policy
 * @author Sarthak Kapoor
 */
export class BotDetectionPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botdetectionpolicy',
            objectType: 'BotDetectionPolicy',
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotManagementPolicyModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(BOT_DETECTION_POLICY_ITEM_TOKEN);
    }
}

BotDetectionPolicyCollection.ajsDependencies = [
    BOT_DETECTION_POLICY_ITEM_TOKEN,
];

export type TBotDetectionPolicyCollection = typeof BotDetectionPolicyCollection;
