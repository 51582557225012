<div
    provideParentForm
    class="rbac-label-grid"
>
    <div
        *ngIf="hasDuplicateError"
        class="rbac-label-grid__error-msg"
    >
        <avi-alert-group alertType="danger">
            <span>
                {{ l10nKeys.keyDuplicationErrorMsg | vtranslate }} <br>
                {{ l10nKeys.duplicateValuesErrorMsg | vtranslate : duplicateValues.join(', ')}}
            </span>
        </avi-alert-group>
    </div>
    <avi-data-grid
        [config]="listDataGridConfig"
        [rows]="rbacEntryList"
        [invalidateForm]="hasDuplicateError"
    >
        <avi-data-grid_header>
            <ng-content select="rbac-label-grid_header"></ng-content>
        </avi-data-grid_header>
        <avi-data-grid_actions>
            <cds-button
                [disabled]="hasDuplicateError"
                (click)="addEntry()"
                size="sm"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
    <ng-template
        #keyFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                id="{{ 'rbac-key-id-' + index }}"
                name="{{ 'rbacKey_' + index }}"
                placeholder="{{ l10nKeys.keyFieldPlaceholder | vtranslate }}"
                [(ngModel)]="row.key"
                (ngModelChange) = "handleChange()"
                [index]="index"
                [rbacList]="rbacEntryList"
                required
            />
        </avi-input-container>
    </ng-template>
    <ng-template
        #valueFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-repeated-strings
            [(ngModel)]="row.values"
            (ngModelChange) = "handleChange()"
            id="{{ 'rbac-values-id-' + index }}"
            name="{{ 'rbacValues_' + index }}"
            placeholder="{{ l10nKeys.valuesFieldPlaceholder | vtranslate }}"
        ></avi-repeated-strings>
    </ng-template>
</div>
