/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitor } from 'object-types';
import * as globalL10n from 'global-l10n';

import type {
    HealthMonitorItem,
} from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';

import * as l10n from './ping-health-monitor-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to display the Ping section in the Health Monitor modal.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'ping-health-monitor-config',
    templateUrl: './ping-health-monitor-config.component.html',
})
export class PingHealthMonitorConfigComponent {
    @Input()
    public editable: HealthMonitorItem;

    public readonly objectType = HealthMonitor;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
