/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

/* eslint-disable max-classes-per-file */

import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    Inject,
    Input,
    NgModule,
    NO_ERRORS_SCHEMA,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AviCardModule } from 'nsx-alb-ui-components';

import {
    L10nService,
    VIPModule,
} from '@vmw/ngx-vip';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { AviConfirmService } from 'ajs/modules/core/services/avi-confirm.service';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';

import {
    GSLB,
    SiteConfigFieldName,
} from 'items/gslb.item.factory';

import {
    GslbThirdPartySiteConfigItem,
} from 'message-items/gslb-third-party-site.config-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    IAviDropdownButtonAction,
} from 'ng/shared/components/avi-dropdown-button/avi-dropdown-button.component';

import {
    AviDropdownButtonPosition,
} from 'ng/shared/components/avi-dropdown-button/avi-dropdown-button.constants';

import {
    GSLBInventoryCollection,
    IRealTimeGslbData,
    TGSLBInventoryCollection,
} from 'collections/gslb-inventory.collection.factory';

import {
    GSLBCollection,
    TGSLBCollection,
} from 'collections/gslb.collection.factory';

import {
    GslbSiteCfgSyncState,
    GslbSiteType,
    IGslbDnsInfo,
    IGslbSiteRuntime,
    IGslbThirdPartySiteRuntime,
    ReplicationMode,
} from 'generated-types';

import * as globalL10n from 'global-l10n';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { GslbSettingModalComponent } from 'ng/modules/gslb/components/gslb-settings-modal';
import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';
import { IGslbMapMarkerDetail } from './gslb-sites-maps/gslb-sites-map.types';

import { GslbSitesMapCardComponent } from './gslb-sites-map-card/gslb-sites-map-card.component';
import * as l10n from './gslb-sites-list-page.l10n';
import './gslb-sites-list-page.component.less';
import { GslbSitesLeafletMapComponent } from './gslb-sites-maps';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

interface IVantageVersionInfo {
    fullString: string;
    version: string;
    build: string;
    timestamp: string;
}

/**
 * @description GSLB Sites list page.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-sites-list-page',
    templateUrl: './gslb-sites-list-page.component.html',
})
export class GslbSitesListPageComponent implements OnInit, OnDestroy {
    /**
     * Default GSLB item, resolved by UI Router.
     */
    @Input('resolveGslb')
    public gslb: GSLB;

    /**
     * TemplateRef for Sensitive Information Rule - enabled Field.
     */
    @ViewChild('replicationStatusTemplateRef')
    public replicationStatusTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for displaying Site name along with status icon.
     */
    @ViewChild('siteNameTemplateRef')
    public siteNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for displaying dnsVses with column suffix in case of multiple values.
     */
    @ViewChild('dnsVsTemplateRef')
    public dnsVsTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for displaying ip addresses with column suffix in case of multiple values.
     */
    @ViewChild('ipAddrTemplateRef')
    public ipAddrTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Combined list of GSLB Sites and third party sites.
     */
    public allSitesList: IRealTimeGslbData[] = [];

    /**
     * Gslb Sites Grid Config.
     */
    public gslbSitesGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * List of create site actions in the create menu.
     */
    public createSiteActions: IAviDropdownButtonAction[];

    /**
     * Position of the add attachments actions menu tooltip.
     */
    public actionPosition = AviDropdownButtonPosition.BOTTOM_LEFT;

    public gslbInventoryCollection: GSLBInventoryCollection;

    public gslbCollection: GSLBCollection;

    /**
     * The replication mode for GSLB for Map card.
     */
    public replicationMode: string;

    /**
     * The GSLB sites location details for displaying on the map.
     */
    public gslbSitesLocationDetails: IGslbMapMarkerDetail[] = [];

    /**
     * If gslb is not configured Hide the gslb related configurations on UI.
     */
    public isGslbConfigured: boolean;

    /**
     * Set loading on page if gslb is beign deleted.
     */
    public busy: boolean;

    /**
     * Operation up state for GSLB Site.
     */
    public readonly OPER_UP_STATE = 'OPER_UP';

    /**
     * Replication mode value to be used in title of gslb sites grid.
     */
    public replicationModeLabel = '';

    /**
     * Hash of GslbSiteType enum.
     */
    public readonly gslbSiteTypeHash = {
        GSLB_AVI_SITE: GslbSiteType.GSLB_AVI_SITE,
        GSLB_THIRD_PARTY_SITE: GslbSiteType.GSLB_THIRD_PARTY_SITE,
    };

    /**
     * Hash of status icon state.
     */
    public readonly statusIconStateHash = {
        SUCCESS: 'success',
        DANGER: 'danger',
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        private readonly devLoggerService: DevLoggerService,
        private readonly aviConfirmService: AviConfirmService,
        @Inject(GSLBInventoryCollection)
        public readonly GSLBInventoryCollection: TGSLBInventoryCollection,
        @Inject(GSLBCollection)
        public readonly GSLBCollection: TGSLBCollection,
        @Inject(GSLB)
        public readonly GSLBClass: typeof GSLB,
        @Inject('systemInfoService')
        private readonly systemInfoService: SystemInfoService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.isGslbConfigured = Boolean(this.gslb);

        if (this.isGslbConfigured) {
            this.busy = true;
            this.loadGslbInventoryCollection().finally(() => this.busy = false);
        }

        this.setCreateSiteAction();
        this.setGslbSitesGridConfig();
    }

    /**
     * Set create Action for avi and third-party sites.
     */
    public setCreateSiteAction(): void {
        this.createSiteActions = [
            {
                label: this.l10nService.getMessage(l10nKeys.newSiteLabel),
                onClick: () => {
                    if (this.isGslbConfigured) {
                        this.gslb.addAviSite();
                    } else {
                        this.loadGslb();
                    }
                },
            }, {
                label: this.l10nService.getMessage(l10nKeys.thirdPartySiteLabel),
                onClick: () => this.gslb.addNonAviSite(),
                disabled: () => !this.isGslbConfigured,
            },
        ];
    }

    /**
     * Open Edit modal for GSLB Settings.
     */
    public openGslbSettingsModal(): void {
        this.gslb.edit(GslbSettingModalComponent as Type<Component>);
    }

    /**
     * Determine whether a warning icon should be shown based on the synchronization state
     * in the runtime data of a GSLB site.
     */
    public showWarningIcon(site: IRealTimeGslbData): boolean {
        if (this.isGslbSiteRuntime(site.runtime)) {
            const { sync_state: syncState } = site.runtime.site_cfg.sync_info;

            return syncState === GslbSiteCfgSyncState.GSLB_SITE_CFG_OUT_OF_SYNC ||
                syncState === GslbSiteCfgSyncState.GSLB_SITE_CFG_SYNC_STALLED;
        } else {
            return false;
        }
    }

    /**
     * Return sync state Label.
     */
    public getSyncStateLabel(site: IRealTimeGslbData): string {
        if (this.isGslbSiteRuntime(site.runtime)) {
            const { sync_state: syncState } = site.runtime.site_cfg.sync_info;

            return this.schemaService.getEnumValueLabel('GslbSiteCfgSyncState', syncState);
        } else {
            return this.l10nService.getMessage(globalL10nKeys.notApplicableLabel);
        }
    }

    /**
     * Generate an array of DNS VS names from the provided DNS information.
     * Return a empty array if DNS is inactive.
     */
    public getDnsVsNames(dnsInfo: IGslbDnsInfo): string[] {
        if (dnsInfo.dns_active) {
            return dnsInfo.dns_vs_states.map(dns => dns.name);
        } else {
            return [];
        }
    }

    /**
     * Check if an object has 'site_cfg' property.
     */
    public isGslbSiteRuntime(
        data: IGslbSiteRuntime | IGslbThirdPartySiteRuntime,
    ): data is IGslbSiteRuntime {
        // eslint-disable-next-line no-extra-parens
        return (data as IGslbSiteRuntime).site_cfg !== undefined;
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.gslbInventoryCollection) {
            this.gslbInventoryCollection.destroy();
        }

        if (this.gslbCollection) {
            this.gslbCollection.destroy();
        }
    }

    /**
     * Set the list of avi and third-party sites.
     */
    private setAllSitesList(): void {
        this.allSitesList = this.gslbInventoryCollection.getMergedConfigWithRuntime(
            this.gslb.config.sites,
            this.gslb.config.third_party_sites,
        );
    }

    /**
     * Set the GSLB site's location details for displaying on the map.
     * Iterates through allSitesList and extracts location, enabled status and name for each site.
     * If location information is available and contians latitude and longitude,
     * create a siteDetailsforMapMarkers object and add it to gslbSitesLocationDetails.
     */
    private setGslbSitesLocationDetails(): void {
        this.gslbSitesLocationDetails = [];
        this.allSitesList.forEach(site => {
            const siteConfigData = site.config;
            const location = siteConfigData.config.location?.config.location?.config;

            if (location && location.latitude !== undefined && location.longitude !== undefined) {
                const siteDetailsforMapMarkers = {
                    location,
                    enabled: siteConfigData.config.enabled,
                    name: siteConfigData.config.name,
                };

                this.gslbSitesLocationDetails.push(siteDetailsforMapMarkers);
            }
        });
    }

    /**
     * Intialize the Gslb inventory collection with gslb data.
     * Bind event handler for successful collection load.
     * Load the gslb inventory collection.
     */
    private async loadGslbInventoryCollection(): Promise<void> {
        this.gslbInventoryCollection = new this.GSLBInventoryCollection({
            gslb: this.gslb,
        });
        this.gslbInventoryCollection
            .bind('collectionLoadSuccess', this.ongslbInventoryCollectionLoad);
        await this.gslbInventoryCollection.load();
    }

    /**
     * Set list of all the sites and update sites grid with runtime data,
     * from gslb inventory collection on successful collection load event.
     */
    private ongslbInventoryCollectionLoad = (): void => {
        this.setAllSitesList();
        this.updateGridWithRuntimeData();
    };

    /**
     * Update the grid with runtime data.
     * Set GSLB site configuration, replication mode, and location details.
     */
    private updateGridWithRuntimeData(): void {
        this.setGslbSitesGridConfig();
        this.replicationMode = this.gslb.replicationPolicyMode;
        this.setReplicationModeValue();
        this.setGslbSitesLocationDetails();
    }

    /**
     * Set replication mode value for title of gslb sites grid.
     */
    private setReplicationModeValue(): void {
        switch (this.replicationMode) {
            case ReplicationMode.REPLICATION_MODE_CONTINUOUS:
                this.replicationModeLabel =
                    `[${this.l10nService.getMessage(l10nKeys.continuousReplicationLabel)}]`;
                break;
            case ReplicationMode.REPLICATION_MODE_ADAPTIVE:
                this.replicationModeLabel =
                `[${this.l10nService.getMessage(l10nKeys.adaptiveReplicationLabel)}]`;
                break;
            default:
                this.replicationModeLabel = '[-]';
                break;
        }
    }

    /**
     * Set GSLB sites grid config.
     */
    private setGslbSitesGridConfig(): void {
        const { l10nService } = this;

        // Dont show any actions if gslb is not configured or Gslb is not editable.
        const multipleActions = this.isGslbConfigured && !this.gslb.isEditable() ? [] : [
            {
                label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                onClick: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    const sitesConfigData = sitesRealTimeData.map(site => site.config);

                    this.gslb.dropSites(sitesConfigData).then(() => this.setAllSitesList());
                },
                disabled: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    const sitesConfigData = sitesRealTimeData.map(site => site.config);

                    return sitesRealTimeData.length === 1 &&
                        this.gslb.isLeaderSiteId(sitesConfigData[0].config.cluster_uuid);
                },
            },
            {
                label: this.l10nService.getMessage(l10nKeys.enableLabel),
                onClick: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    this.updateEnabledFlag(sitesRealTimeData, true);
                },
                disabled: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    const sitesConfigData = sitesRealTimeData.map(site => site.config);

                    return sitesConfigData.some(config => config.config.enabled);
                },
            },
            {
                label: this.l10nService.getMessage(l10nKeys.deactivateLabel),
                onClick: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    this.updateEnabledFlag(sitesRealTimeData, false);
                },
                disabled: (sitesRealTimeData: IRealTimeGslbData[]) => {
                    const sitesConfigData = sitesRealTimeData.map(site => site.config);

                    if (
                        sitesConfigData.length === 1 &&
                        this.gslb.isLeaderSiteId(sitesConfigData[0].config.cluster_uuid)
                    ) {
                        return true;
                    }

                    return sitesConfigData.some(config => !config.config.enabled);
                },
            },

        ];

        this.gslbSitesGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    templateRef: this.siteNameTemplateRef,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.typeLabel),
                    id: 'type',
                    transform: (site: IRealTimeGslbData) => {
                        const siteConfigData = site.config;

                        if (siteConfigData instanceof GslbThirdPartySiteConfigItem) {
                            return l10nService.getMessage(l10nKeys.thirdPartyLabel);
                        }

                        const type = siteConfigData.isActiveMember() ?
                            l10nService.getMessage(globalL10nKeys.activeLabel) :
                            l10nService.getMessage(l10nKeys.passiveLabel);

                        return this.gslb?.isLeaderSiteId(siteConfigData.config.cluster_uuid) ?
                            `${type} (${l10nService.getMessage(l10nKeys.leaderLabel)})` :
                            type;
                    },
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                    id: 'ipAddress',
                    templateRef: this.ipAddrTemplateRef,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.portLabel),
                    id: 'port',
                    transform: (site: IRealTimeGslbData) =>
                        (site.config instanceof GslbThirdPartySiteConfigItem ?
                            l10nService.getMessage(globalL10nKeys.notApplicableLabel) :
                            site.config.config.port),
                },
                {
                    label: l10nService.getMessage(l10nKeys.dnsVsLabel),
                    id: 'dns-vses',
                    templateRef: this.dnsVsTemplateRef,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.versionLabel),
                    id: 'version',
                    transform: (site: IRealTimeGslbData) => {
                        const swVersionDetails =
                            this.getVersionDataFromString(site.runtime.site_info.sw_version);

                        return swVersionDetails ? swVersionDetails.version :
                            l10nService.getMessage(globalL10nKeys.notApplicableLabel);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.replicationLabel),
                    id: 'replication',
                    templateRef: this.replicationStatusTemplateRef,
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (site: IRealTimeGslbData) => {
                        if (this.gslb.isLeaderSiteId(site.config.config.cluster_uuid)) {
                            this.deleteGslbConfiguration();
                        } else {
                            this.gslb.dropSites([site.config]).then(() => this.setAllSitesList());
                        }
                    },
                    hidden: () => !this.gslb.isDroppable(),
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (site: IRealTimeGslbData) => {
                        site.config instanceof GslbThirdPartySiteConfigItem ?
                            this.gslb.editNonAviSite(site.config) :
                            this.gslb.editAviSite(site.config);
                    },
                    hidden: () => !this.gslb.isEditable(),
                },
            ],
            multipleactions: multipleActions,
        };
    }

    /**
     * Delete GSLB configuration.
     */
    private async deleteGslbConfiguration(): Promise<void> {
        const message = this.l10nService.getMessage(l10nKeys.deleteGSLBConfigurationsMessage);

        try {
            await this.aviConfirmService.confirm(message);
            this.busy = true;
            await this.gslb.drop();
            this.isGslbConfigured = false;
            this.allSitesList = [];
            this.setCreateSiteAction();
            this.gslbInventoryCollection.destroy();
        } catch (error) {
            this.devLoggerService.error(error.data?.error);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Parse full Avi Vantage version into chunks like version, build and timestamp.
     */
    private getVersionDataFromString(str: string): IVantageVersionInfo | undefined {
        const parts = /([\d.]+)\s?\((\d+)\)\s(.*)/.exec(str);

        if (parts) {
            const [fullString, version, build, timestamp] = parts;

            return {
                fullString,
                version,
                build,
                timestamp,
            };
        }
    }

    /**
     * Load the GSLB Collection and set the default values for gslb fiedls.
     * Open Gslb Leader modal.
     */
    private async loadGslb(): Promise<void> {
        this.gslb = null;

        this.gslbCollection = new this.GSLBCollection();

        try {
            const site = await this.GSLBClass.getLocalSiteConfig();

            this.gslbCollection.setDefaultItemConfigProps({
                sites: [site],
                leader_cluster_uuid: site.cluster_uuid,
            });
            this.gslb = this.gslbCollection.createNewItem(undefined, true);

            await this.gslb.editAviSite(this.gslb.config.sites.config[0]);
            await this.systemInfoService.load(true);
            this.loadGslbInventoryCollection();
            this.isGslbConfigured = true;
        } catch (error) {
            this.devLoggerService.error(error.data?.error);
        }
    }

    /**
     * Update the Enabled flag for all the selected sites.
     */
    private async updateEnabledFlag(
        sitesRealTimeData: IRealTimeGslbData[],
        flag: boolean,
    ): Promise<void> {
        const thirdPartySites: GslbThirdPartySiteConfigItem[] = [];
        const aviSites: GslbSiteConfigItem[] = [];

        for (const siteData of sitesRealTimeData) {
            const siteConfig = siteData.config;

            if (siteConfig instanceof GslbThirdPartySiteConfigItem) {
                thirdPartySites.push(siteConfig);
            } else if (!this.gslb.isLeaderSiteId(siteConfig.config.cluster_uuid)) {
                aviSites.push(siteConfig);
            }
        }

        if (thirdPartySites.length) {
            await this.updateSitesEnabledFlag(
                thirdPartySites,
                SiteConfigFieldName.NON_AVI_SITES,
                flag,
            );
        }

        if (aviSites.length) {
            await this.updateSitesEnabledFlag(
                aviSites,
                SiteConfigFieldName.AVI_SITES,
                flag,
            );
        }
    }

    /**
     * Update the enabled flag for third-party/avi sites through GSLB Item.
     */
    private async updateSitesEnabledFlag(
        sites: GslbThirdPartySiteConfigItem[] | GslbSiteConfigItem[],
        fieldName: SiteConfigFieldName,
        flag: boolean,
    ): Promise<void> {
        this.busy = true;
        await this.gslb.toggleSiteEnabledFlag(fieldName, sites, flag);
        this.busy = false;
    }
}

@NgModule({
    declarations: [
        GslbSitesListPageComponent,
        GslbSitesMapCardComponent,
        GslbSitesLeafletMapComponent,
    ],
    imports: [
        AviFormsModule,
        DataGridModule,
        LeafletModule,
        AviCardModule,
        SharedModule,
        VIPModule.forChild(),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
/* eslint-disable-next-line no-unused-vars */
class GslbModule1 {}
