/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'infoblox-usable-domains-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const columnTitleUsableDomain = `${prefix}.columnTitleUsableDomain`;
export const domainSelectPlaceholder = `${prefix}.domainSelectPlaceholder`;
export const usableDomainsGridHeader = `${prefix}.usableDomainsGridHeader`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [columnTitleUsableDomain]: 'Usable Domain',
    [domainSelectPlaceholder]: 'Select Usable Domain',
    [usableDomainsGridHeader]: 'Usable Domains ({0})',
};
