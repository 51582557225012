/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-mapping-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const matchSectionTitle = `${prefix}.matchSectionTitle`;

export const componentMatcherLabel = `${prefix}.componentMatcherLabel`;
export const selectComponentMatcherPlaceholder = `${prefix}.selectComponentMatcherLabel`;
export const clientClassLabel = `${prefix}.clientClassLabel`;
export const botTypeMatcherLabel = `${prefix}.botTypeMatcherLabel`;
export const stringMatcherLabel = `${prefix}.stringMatcherLabel`;
export const pathMatcherLabel = `${prefix}.pathMatcherLabel`;
export const hdrMatcherLabel = `${prefix}.hdrMatcherLabel`;
export const botClassificationLabel = `${prefix}.botClassificationLabel`;
export const selectBotClassificationPlaceholder = `${prefix}.selectBotClassificationLabel`;
export const clientTypeLabel = `${prefix}.clientTypeLabel`;
export const selectClientTypePlaceholder = `${prefix}.selectClientTypeLabel`;
export const stringLabel = `${prefix}.stringLabel`;
export const enterStringPlaceholder = `${prefix}.enterStringLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Mapping Rule',
    [modalTitleEdit]: 'Edit Mapping Rule',
    [matchSectionTitle]: 'Match',

    [componentMatcherLabel]: 'Component Matcher',
    [selectComponentMatcherPlaceholder]: 'Select Component Matcher',
    [clientClassLabel]: 'Client Class',
    [botTypeMatcherLabel]: 'Bot Type Matcher',
    [stringMatcherLabel]: 'String Matcher',
    [pathMatcherLabel]: 'Path Matcher',
    [hdrMatcherLabel]: 'Header Matcher',
    [botClassificationLabel]: 'Bot Classification',
    [clientTypeLabel]: 'Client Type',
    [selectBotClassificationPlaceholder]: 'Select Bot Classification',
    [selectClientTypePlaceholder]: 'Select Client Type',
    [stringLabel]: 'String',
    [enterStringPlaceholder]: 'Enter String',
};
