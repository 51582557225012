<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-fieldset [hideContent]="!isGeoLocationSourceUserConfigured">
                <avi-fieldset_header class="gslb-service-geo-location__avi-fieldset-header">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.GslbGeoLocation }}"
                            fieldName="source"
                            for="gslb-service-geo-location-source"
                        >
                            {{ l10nKeys.gslbGeoLocationSourceDropdownLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="gslb-service-geo-location-source"
                            enum="GslbGeoLocationSource"
                            [hiddenEnumValues]="enumHiddenGeoLocationOptions"
                            [(ngModel)]="gslbGeoLocation.config.source"
                            (ngModelChange)="handleGeoLocationSourceChange()"
                            placeholder="{{ l10nKeys.gslbGeoLocationSourceDropdownPlaceholder | vtranslate }}"
                        ></avi-enum-dropdown>
                    </avi-input-container>
                </avi-fieldset_header>
                <avi-fieldset_content>
                    <div class="full-modal-config__form-control-row gslb-service-geo-location__no-margin-top">
                        <div class="full-modal-config__form-control">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="name"
                                    for="name"
                                >
                                    {{ globalL10nKeys.nameLabel | vtranslate }}
                                </label>
                                <input
                                    name="name"
                                    id="name"
                                    type="text"
                                    configFieldInputValidation
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="name"
                                    clrInput
                                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                                    [(ngModel)]="gslbGeoLocation.config.location.config.name"
                                />
                            </avi-input-container>
                        </div>

                        <div class="full-modal-config__form-control">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="tag"
                                    for="tag"
                                >
                                    {{ l10nKeys.locationTagInputLabel | vtranslate }}
                                </label>
                                <input
                                    name="tag"
                                    id="tag"
                                    type="text"
                                    configFieldInputValidation
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="tag"
                                    clrInput
                                    placeholder="{{ l10nKeys.locationTagInputPlaceholder | vtranslate }}"
                                    [(ngModel)]="gslbGeoLocation.config.location.config.tag"
                                />
                            </avi-input-container>
                        </div>
                    </div>

                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="latitude"
                                    for="latitude"
                                >
                                    {{ l10nKeys.locationLatitudeInputLabel | vtranslate }}
                                </label>
                                <input
                                    name="latitude"
                                    id="latitude"
                                    type="number"
                                    configFieldInputValidation
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="latitude"
                                    clrInput
                                    placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
                                    [(ngModel)]="gslbGeoLocation.config.location.config.latitude"
                                />
                            </avi-input-container>
                        </div>

                        <div class="full-modal-config__form-control">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="longitude"
                                    for="longitude"
                                >
                                    {{ l10nKeys.locationLongitudeInputLabel | vtranslate }}
                                </label>
                                <input
                                    name="longitude"
                                    id="longitude"
                                    type="number"
                                    configFieldInputValidation
                                    objectType="{{ objectType.GeoLocation }}"
                                    fieldName="longitude"
                                    clrInput
                                    placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
                                    [(ngModel)]="gslbGeoLocation.config.location.config.longitude"
                                />
                            </avi-input-container>
                        </div>
                    </div>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
