/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Base } from 'ajs/modules/data-model/factories/base.factory';
import * as l10n from './TimeFrame.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @typedef {Object} module:avi/app.timeframe
 * @property {string} label - Short label.
 * @property {number} step - Duration on which backend metric data aggregation gonna
 *     be made in seconds.
 * @property {number} limit - Number of metric data points to be fetched from the back-end.
 * @property {number} interval - Frequency of the data updates: one time in provided number
 *     of seconds.
 * @property {Array.<Array.<string, Function>>|undefined} timeTickFormat - Argument for
 *     d3.time.format.multi function to use for the time labels on the
 *     {@link performanceChart}.
 *     When not provided d3js default date format will be used.
 */

/**
 * @typedef {Object}
 * @name TTimeframeEntry
 * @property {number} index
 * @property {string} key
 * @property {string} label
 * @property {number} step
 * @property {number} limit
 * @property {number} interval
 * @property {number} range
 * @property {string[]} timeTickFormat
 */

// TODO update timeframe visibility through explicit method which triggers the corresponding event.
// TODO Refactor different timeframes into one data scructure to avoid duplication and keep it
// consistent and manageable @am
/**
 * @constructor
 * @memberOf module:avi/app
 * @extends module:avi/dataModel.Base
 * @author Ashish Verma
 * @desc
 *      This object shares selected timeframe across application.
 *
 *      If controller needs timeframe it can be injected and used as follows:
 *      Timeframe.value_             // Which will give selected value like `rt` or `6h`
 *      Timeframe.set(newValue)     // This way new value would be set and `change` event
 *                                      triggered
 *      Timeframe.selected().limit  // Will give the limit for the current selection
 *      Timeframe.selected().step   // Will give the step for the current selection
 *
 *      You can do Timeframe.setGroup(groupName) to switch timeframe options to the new group,
 *      value would be checked for existence.
 *
 *      You can watch timeframe change by Timeframe.on('change', function(event) {}) and
 *      update your stuff upon change. If timeframe was changed by interacting with directive
 *      then event would be click event on dropdown element.
 */
export class Timeframe extends Base {
    constructor() {
        super();

        this.$stateParams_ = this.getAjsDependency_('$stateParams');
        this.$location_ = this.getAjsDependency_('$location');

        const logTimeframes_ = this.getAjsDependency_('logTimeframes');

        this.l10nService = this.getAjsDependency_('l10nService');

        /**
         * For each timeframe group we have a sorted array of timeframe objects with key and
         * numeric value.
         * @type {{string:Object[]}}
         * @protected
         */
        this.orderedGroupTimeframes_ = {};

        /**
         * Hash of numeric timeframe values (in seconds, 1 or Infinity) for each timeframe we
         * support. So that we can search for closer ones.
         * @type {{string:number}}
         * @protected
         */
        this.numericTimeframesHash_ = {};

        /**
         * Keeps currently selected list of options
         * Yes, it's used to render options in dropdown
         * @type {hash}
         */
        this.options = {};

        /**
         * Keeps selected timeframe value. Use {@link Timeframe.set} to update.
         * @type {string}
         * @protected
         */
        this.value_ = '6h';

        /**
         * Selected timeframe value used by default. Must be supported by all timeframe groups.
         * @type {string}
         * @protected
         */
        this.defaultValue_ = '6h';

        this.l10nService.registerSourceBundles(dictionary);

        /**
         * Maps meaningful group name to the option list defined above
         * @type {{string:{string:timeframe}}}
         */
        this.groups = {
            // Option list specific only for logs
            logs: logTimeframes_,

            // default options
            default: {
                // special settings for Items with real time metrics on
                rt: {
                    index: 0, //only for layout ordering
                    label: l10nKeys.past30minutesOptionLabel,
                    step: moment.duration(5, 'm').asSeconds(), // or five seconds
                    limit: 6, //or 360
                    interval: 10,
                    range: moment.duration(30 * 60 - 1, 's').asSeconds(),
                    timeTickFormat: [
                        ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                        ['%I %p', function() { return true; }], //HH AM
                    ],
                },
                '6h': {
                    index: 1,
                    label: l10nKeys.past6HoursOptionLabel,
                    step: moment.duration(5, 'm').asSeconds(),
                    limit: 72,
                    interval: 30,
                    range: moment.duration(6, 'h').asSeconds(),
                    timeTickFormat: [
                        ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                        ['%I %p', function(d) { return d.getHours(); }], //HH AM
                        ['%a %d', function() { return true; }], //Sun DD
                    ],
                },
                '1d': {
                    index: 2,
                    label: l10nKeys.pastDayOptionLabel,
                    step: moment.duration(5, 'm').asSeconds(),
                    range: moment.duration(1, 'd').asSeconds(),
                    limit: 288,
                    interval: 30,
                    timeTickFormat: [
                        ['%I:%M', function(d) { return d.getMinutes(); }], //HH:MM
                        ['%I %p', function(d) { return d.getHours(); }], //HH AM/PM
                        ['%a %d', function() { return true; }], //Sun DD
                    ],
                },
                '1w': {
                    index: 3,
                    label: l10nKeys.pastWeekOptionLabel,
                    step: moment.duration(1, 'h').asSeconds(),
                    range: moment.duration(1, 'w').asSeconds(),
                    limit: 168,
                    interval: 30,
                    timeTickFormat: [
                        ['%I %p', function(d) { return d.getHours(); }], //HH AM/PM
                        ['%a %d', function(d) { return d.getDate() != 1; }], //Sun DD
                        ['%b %d', function() { return true; }], //Jan 01
                    ],
                },
                '1m': {
                    index: 4,
                    label: l10nKeys.pastMonthOptionLabel,
                    step: moment.duration(1, 'day').asSeconds(),
                    range: moment.duration(1, 'M').asSeconds(),
                    limit: 30,
                    interval: 30,
                    timeTickFormat: [
                        ['%a %d', function(d) { return d.getDate() != 1; }], //Sun DD
                        ['%b %d', function() { return true; }], //Jan 01
                    ],
                },
                '1q': {
                    index: 5,
                    label: l10nKeys.pastQuarterOptionLabel,
                    step: moment.duration(1, 'd').asSeconds(),
                    range: moment.duration(3, 'M').asSeconds(),
                    limit: 90,
                    interval: 30,
                    timeTickFormat: [
                        ['%b %d', function(d) { return d.getMonth(); }], //Jan DD
                        ['%Y', function() { return true; }], //YYYY
                    ],
                },
                '1y': {
                    index: 6,
                    label: l10nKeys.pastYearOptionLabel,
                    step: moment.duration(1, 'd').asSeconds(),
                    range: moment.duration(3, 'y').asSeconds(),
                    limit: 365,
                    interval: 30,
                    timeTickFormat: [
                        ['%b %d', function(d) { return d.getDate() != 1; }], //Jan DD
                        ['%b', function(d) { return d.getMonth(); }], //Jan
                        ['%Y', function() { return true; }], //YYYY
                    ],
                },
            },
            insights: {
                '6h': {
                    index: 1,
                    label: l10nKeys.past6HoursOptionLabel,
                    step: moment.duration(5, 'm').asSeconds(),
                    limit: 72,
                    interval: 30,
                    range: moment.duration(6, 'h').asSeconds(),
                    timeTickFormat: [
                        ['%I:%M', d => d.getMinutes()], //HH:MM
                        ['%I %p', d => d.getHours()], //HH AM
                        ['%a %d', () => true], //Sun DD
                    ],
                },
            },
        };

        // By default set options to analytics
        _.each(this.groups, (group, groupKey) => {
            const orderedList = [];

            _.each(group, (elm, key) => {
                const { range } = elm;

                if (!(key in this.numericTimeframesHash_)) {
                    this.numericTimeframesHash_[key] = Timeframe.getNumericRange_(range);
                }

                orderedList.push({
                    key,
                    range: this.numericTimeframesHash_[key],
                });

                elm.key = key;
            });

            this.orderedGroupTimeframes_[groupKey] =
                orderedList.sort(({ range: a }, { range: b }) => a - b);
        });

        /**
         * Name of the selected timeframe group. For ex.: default or logs.
         * @type {string}
         **/
        this.selectedGroup = 'default';

        this.options = this.groups[this.selectedGroup];

        this.setGroup('default', undefined, 'Timeframe constructor');
    }

    /**
     * Sets the default Timeframe value (which usually comes from the user profile).
     * @param {string} value
     * @public
     */
    setDefaultValue(value) {
        if (value && value in this.groups['default']) {
            this.defaultValue_ = value;
        }
    }

    /**
     * Returns default value of selected timeframe.
     * @returns {string}
     * @public
     */
    getDefaultValue() {
        return this.defaultValue_;
    }

    /**
     * Changes options based on group name. Groups defined above
     * @param {string=} groupName - Supported group name.
     * @param {string=} newValue - Value to be set in a new group. If not passed,
     *     current value will be preserved or selected group default value will be used (if
     *     current value is not present in a newly selected group).
     * @param {string=} reason - String to be passed with generated even on a timeframe change.
     */
    setGroup(
        groupName,
        newValue = this.value_,
        reason = 'afterGroupChange',
    ) {
        if (!groupName || !angular.isString(groupName) || !(groupName in this.groups)) {
            groupName = 'default';
        }

        const valueToBeSet = this.getClosestValue_(groupName, newValue.toLowerCase());

        if (groupName !== this.selectedGroup) {
            this.selectedGroup = groupName;
            this.options = this.groups[this.selectedGroup];
            this.trigger('afterGroupChange', reason);
        }

        this.set(valueToBeSet, reason);
    }

    /**
     * Returns the closest to the current (or just default) "old" value from the "new" group.
     * @param groupName {string} - Group name we are interested at ("new").
     * @param requested {string=} - When passed we will try to find a timeframe of the
     *     "new" group which is closest to this argument. Otherwise current
     *     {@link Timeframe.value_} will be used for this purpose.
     * @returns {string}
     * @protected
     */
    getClosestValue_(groupName, requested) {
        const comingFromGroup = this.selectedGroup,
            ordered = this.orderedGroupTimeframes_[groupName],
            { groups } = this;

        if (!(requested in groups[comingFromGroup]) && !(requested in groups[groupName])) {
            requested = this.value_;
        }

        const tfNumRange = this.numericTimeframesHash_[requested];

        let
            res = this.getDefaultValue(),
            found = false;

        _.any(ordered, ({ key, range }, index, list) => {
            if (range === tfNumRange) { //have same in new group, use it
                found = true;
                res = key;
            } else if (range > tfNumRange) { //have no same but larger one
                found = true;

                if (!index) { //first one is larger, hence no choice and we go for it
                    res = key;
                } else {
                    const { key: prevEntryKey, range: prevEntryRange } = list[index - 1];

                    if (range - tfNumRange <= tfNumRange - prevEntryRange) {
                        res = key;
                    } else {
                        res = prevEntryKey;
                    }
                }
            }

            return found;
        });

        if (!found) { //we have no same and all others are smaller, go for the last one
            res = ordered[ordered.length - 1].key;
        }

        return res;
    }

    /**
     * Setter
     * @param {string} newValue - New timeframe value.
     * @param {string=} reason - Message sent with the generated event.
     */
    set(newValue, reason) {
        if (newValue && typeof newValue === 'string') {
            newValue = newValue.toLowerCase();

            if (newValue !== this.value_) {
                if (newValue in this.options) {
                    this.value_ = newValue;
                    this.trigger('change', reason);
                    this.$stateParams_.timeframe = this.value_;
                    this.$location_.search('timeframe', this.value_);//update URL string
                } else {
                    console.warn('Trying to select a nonexistent timeframe \'%s\', in active ' +
                        ' group: \'%s\'', newValue, this.selectedGroup);
                }
            }
        }
    }

    /**
     * Returns selected option that includes `limit`, `step` and `interval`
     * @return TTimeframeEntry
     */
    selected() {
        return this.options[this.value_];
    }

    /**
     * Returns the translation for the 'label' of selected option
     * @return {string}
     */
    localizeSelectedLabel() {
        return this.l10nService.getMessage(this.selected().label);
    }

    /**
     * Returns selected time frame group id.
     * @return {string}
     */
    get selectedGroupId() {
        return this.selectedGroup;
    }

    /**
     * We are looking for timeframes which are close when we don't have an exact
     * same in another group. To do so we need an ability to compare em using
     * numeric values.
     * @param {number|string} range - Number of seconds or 'custom' or 'all'.
     * @returns {number}
     * @static
     * @protected
     */
    static getNumericRange_(range) {
        if (angular.isString(range)) {
            switch (range) {
                case 'custom':
                    range = 1;
                    break;

                case 'all':
                    range = Infinity;
            }
        }

        return range;
    }
}

Timeframe.ajsDependencies = [
    '$stateParams',
    '$location',
    'logTimeframes',
    'l10nService',
];

initAjsDependency(
    angular.module('avi/app'),
    'service',
    'Timeframe',
    Timeframe,
);
