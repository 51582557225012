/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NgForm,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import { removeControlError } from 'ng/modules/avi-forms/utils/form-validation.utils';
import * as l10n from './backup-passphrase.l10n';
import './backup-passphrase.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const PASSPHRASE_MISMATCH_ERROR_KEY = 'passphraseMismatch';

/**
 * @description
 *
 *     Passphrase/Confirm Passphrase settings component.
 *
 *     This component contains two input fields -
 *          passphrase and confirmation passphrase.
 *
 * @author Aravindh Nagarajan, Ratan Kumar, Shahab Hashmi
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BackupPassphraseComponent),
        },
    ],
    selector: 'backup-passphrase',
    templateUrl: './backup-passphrase.component.html',
})
export class BackupPassphraseComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * Added this property becomes in some case we can both input in same row
     * and in some case we want every input in single row.
     * So from parent component we can set singleRow 'true' and base on that
     * we add 'full-modal-config__form-control-row' class.
     */
    @Input()
    public singleRow: boolean;

    /**
     * Model for passphrase confirmation field.
     * Can use as an input in case the parent component wants to initialize both fields
     * with the same input.
     */
    @Input()
    public confirmPassphrase ?= '';

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: string;

    /**
     * ValidationError object for mismatching passphrases.
     */
    private passphraseMismatchError: ValidationErrors;

    constructor(
        l10nService: L10nService,
        private readonly ngForm: NgForm,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.passphraseMismatchError = {
            [PASSPHRASE_MISMATCH_ERROR_KEY]: {
                errorMessage: l10nService.getMessage(l10nKeys.passphraseMismatchError),
            },
        };
    }

    /**
     * @override
     * Add a validator to the ngForm.
     */
    public ngOnInit(): void {
        const { form } = this.ngForm;

        form.addValidators(this.validate);
        form.updateValueAndValidity();
    }

    /**
     * @override
     * Remove the validator from the ngForm.
     */
    public ngOnDestroy(): void {
        const { form } = this.ngForm;

        form.removeValidators(this.validate);
        form.updateValueAndValidity();
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): string {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: string) {
        this.modelValue = val;
        this.onChange(val);
        this.onTouched();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * @override
     * Check that backup-passphrase-passphrase and backup-passphrase-confirm-passphrase match.
     * If not matching, set an error on both controls so that an error message is displayed.
     */
    private validate = (): ValidationErrors | null => {
        const { form } = this.ngForm;
        const passphraseControl = form.get('backup-passphrase-passphrase');
        const confirmPassphraseControl = form.get('backup-passphrase-confirm-passphrase');

        if (!passphraseControl || !confirmPassphraseControl) {
            return null;
        }

        const { value: passphrase } = passphraseControl;
        const { value: confirmPassphrase } = confirmPassphraseControl;

        if (!passphrase && !confirmPassphrase || passphrase === confirmPassphrase) {
            removeControlError(passphraseControl, PASSPHRASE_MISMATCH_ERROR_KEY);
            removeControlError(confirmPassphraseControl, PASSPHRASE_MISMATCH_ERROR_KEY);

            return null;
        } else {
            this.setPassphraseMismatchError(passphraseControl);
            this.setPassphraseMismatchError(confirmPassphraseControl);

            return this.passphraseMismatchError;
        }
    };

    /**
     * Set the passwordMismatch error on a form control.
     */
    private setPassphraseMismatchError(control: AbstractControl): void {
        control.markAsTouched();
        control.setErrors(this.passphraseMismatchError);
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
