<cds-modal
    size="{{ size }}"
    (closeChange)="handleClose($event)"
    class="avi-confirmation sel-avi-confirmation"
>
    <cds-modal-header>
        <h3>{{ headerText }}</h3>
    </cds-modal-header>

    <cds-modal-content>
        <ng-content select="avi-confirmation_modal-content"></ng-content>
    </cds-modal-content>

    <cds-modal-actions>
        <ng-content select="avi-confirmation_modal-actions"></ng-content>
    </cds-modal-actions>
</cds-modal>
