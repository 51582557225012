/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    IVsLogsDefaultSignpostData,
    IVsLogsEndToEndTimingResponseData,
    IVsLogsEndToEndTimingSignpostData,
    IVsLogsGroupbyResponseData,
    IVsLogsGroupbyResponseResultData,
    IVsLogsGroupsSignpostData,
    IVsLogsPolicyGroupsResponseData,
    IVsLogsSignificanceCategoryResultsResponseData,
    IVsLogsSignificanceSignpostData,
    IVsLogsSignpostRange,
    IVsLogsSignpostTransformedResult,
    IVsLogsSslGroupsResponseData,
    IVsLogsWafLatencyResponseData,
    IVsLogsWafLatencySignpostData,
    IVsLogsWafRuleGroupsRules,
    IVsLogsWafRuleGroupsSignpostData,
    IVsLogsWafRuleSignpostData,
    TVsLogsSignpostApiResponseData,
    TVsLogsSignpostStoreData,
} from '../vs-logs.types';

import {
    customTemplates,
    e2eTimingGroupByKeys,
    signpostsWithRange,
    templatesConfiguration,
    wafLatencyPhases,
} from '../constants/vs-logs-signpost.constants';

const wafRuleGroupsIdKey = 'waf_log.rule_logs.rule_id';
const wafRuleGroupsNameKey = 'waf_log.rule_logs.rule_name';
const wafRuleGroupsGroupKey = 'waf_log.rule_logs.rule_group';
const nonSignificantLogKey = 'Non-Significant Log';

/**
 * Redirect to respective tranformation logic depending on type.
 */
export const transformSignpostData = (
    data: TVsLogsSignpostApiResponseData,
    configKey: string,
): TVsLogsSignpostStoreData => {
    switch (configKey) {
        case customTemplates.wafPhaseLatency:
            return transformWafLatencyResults(data as IVsLogsWafLatencyResponseData);

        case customTemplates.wafRuleGroups:
            return transformWafRuleGroupsResults(data as IVsLogsGroupbyResponseData);

        case customTemplates.policy:
            return transformPolicySignpostResults(data as IVsLogsPolicyGroupsResponseData);

        case customTemplates.ssl:
            return transformSslSignpostResults(data as IVsLogsSslGroupsResponseData);

        case customTemplates.significance:
            return transformSignificanceSignpostResults(
                data as IVsLogsGroupbyResponseData,
            );

        case customTemplates.e2eTiming:
            return transformEndToEndTimingSignpostResults(
                data as IVsLogsEndToEndTimingResponseData,
            );

        case customTemplates.wafRule:
            return transformWafRuleSignpostResults(
                data as IVsLogsGroupbyResponseData,
            );

        default:
            return transformDefaultSignpostData(data as IVsLogsGroupbyResponseData, configKey);
    }
};

/**
 * Transform received results for default template in the form usable for displaying.
 */
export const transformDefaultSignpostData = (
    data: IVsLogsGroupbyResponseData,
    configKey: string,
): TVsLogsSignpostStoreData => {
    if (signpostsWithRange.has(configKey)) {
        const groupby = templatesConfiguration[configKey].params;

        return transformResultsWithRange(data.results, groupby);
    }

    return transformResultsForValue(data.results, configKey);
};

/**
 *  Transform results, append attributes which are required for displaying default signpost.
 */
export const transformWafRuleSignpostResults = (
    data: IVsLogsGroupbyResponseData,
): IVsLogsWafRuleSignpostData[] => {
    const results = data.results || [];

    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    const configKey = customTemplates.wafRule;
    const { params = [] } = templatesConfiguration[configKey];
    const transformedResult: IVsLogsWafRuleSignpostData[] = [];

    results.forEach((response: IVsLogsGroupbyResponseResultData) => {
        const values = params.map((param: string) => {
            const value = response[param];
            const valueObj = {
                value,
                param,
                isValid: Boolean(value),
            };

            return valueObj;
        });

        const resObj: IVsLogsWafRuleSignpostData = {
            count: Number(response.count),
            percentage: Number(response.percentage?.toFixed(2)),
            values,
        };

        transformedResult.push(resObj);
    });

    return transformedResult;
};

/**
 * Transform results for signpost having range type.
 */
export const transformResultsWithRange = (
    results: IVsLogsGroupbyResponseResultData[] = [],
    groupby: string,
): IVsLogsDefaultSignpostData[] => {
    const transformedResult: IVsLogsDefaultSignpostData[] = [];

    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    results.forEach(res => {
        const value = res[groupby];

        if (value) {
            const { start, end } = value;

            res.value = {
                value,
                param: groupby,
                isValid: !(start === undefined || end === undefined || isNegativeRange(value)),
            };

            transformedResult.push({
                value: res.value,
                count: Number(res.count),
                percentage: Number(res.percentage?.toFixed(2)),
            });
        }
    });

    return transformedResult;
};

/**
 * Return true if time-range has negative range data.
 */
export const isNegativeRange = (range: IVsLogsSignpostRange): boolean => {
    const { start, end } = range;

    return start && end && Number(start) < 0 && Number(end) < 0;
};

/**
 * Return key used for accessing average value from the e2e result.
 */
export const e2eAverageKeyGenerator = (key: string): string => {
    return `AVG(${key})`;
};

/**
 *  Transform results, append attributes which are required for displaying default signpost.
 */
export const transformResultsForValue = (
    results: IVsLogsGroupbyResponseResultData[] = [],
    configKey: string,
): IVsLogsDefaultSignpostData[] => {
    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    const { params } = templatesConfiguration[configKey];
    const transformedResult: IVsLogsDefaultSignpostData[] = results.map(
        (record: IVsLogsGroupbyResponseResultData) => {
            const resObj: IVsLogsDefaultSignpostData = {
                count: Number(record.count),
                percentage: Number(record.percentage?.toFixed(2)),
                value: selectValue(params, record),
            };

            return resObj;
        },
    ) || [];

    return transformedResult;
};

/**
 *  Transform results, append attributes which are required for displaying significance signpost.
 */
export const transformSignificanceSignpostResults = (
    data: IVsLogsGroupbyResponseData,
): IVsLogsSignificanceSignpostData[] => {
    const categoryResults =
        data.category_results as IVsLogsSignificanceCategoryResultsResponseData[] || [];
    const configKey = customTemplates.significance;

    // We only show top 10 entries in signpost.
    if (categoryResults.length > 10) {
        categoryResults.length = 10;
    }

    const { params: param } = templatesConfiguration[configKey];
    const transformedResult: IVsLogsSignificanceSignpostData[] = [];

    categoryResults.forEach((parent: IVsLogsSignificanceCategoryResultsResponseData) => {
        const nonSignificant = parent.primary_reason === nonSignificantLogKey;
        const parentValue = nonSignificant ? '' : parent.primary_reason;
        const children = parent.secondary_reasons?.map(
            (secondaryReason: IVsLogsGroupbyResponseResultData) => {
                const value = secondaryReason[param];
                const display = `${parentValue}: ${value}`;
                const child: IVsLogsDefaultSignpostData = {
                    count: secondaryReason.count,
                    percentage: secondaryReason.percentage,
                    value: {
                        value: display,
                        display,
                        contains: true,
                        param,
                        isValid: Boolean(display),
                    },
                };

                return child;
            },
        ) || [];

        const resObj: IVsLogsSignificanceSignpostData = {
            children,
            count: parent.count,
            percentage: parent.percentage,
            value: {
                display: parent.primary_reason,
                value: parentValue,
                contains: nonSignificant ? undefined : true,
                param,
                isValid: Boolean(parent.primary_reason),
            },
        };

        transformedResult.push(resObj);
    });

    return transformedResult;
};

/**
 * Transform received results for WAF groups template in the form usable for displaying WAF rules
 * group signpost.
 */
export const transformWafRuleGroupsResults = (
    data: IVsLogsGroupbyResponseData,
): IVsLogsWafRuleGroupsSignpostData[] => {
    const wafRuleGroups = {};
    const { results = [] } = data;
    const transformedResult: IVsLogsWafRuleGroupsSignpostData[] = [];

    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    results.forEach(item => {
        const { count = 0, percentage = 0 } = item;
        const ruleId = item[wafRuleGroupsIdKey];
        const ruleName = item[wafRuleGroupsNameKey];
        const groupName = item[wafRuleGroupsGroupKey];
        const wafRuleGroup = wafRuleGroups[groupName];

        if (groupName) {
            const rules: IVsLogsWafRuleGroupsRules[] = wafRuleGroup ? wafRuleGroup.rules : [];
            const totalCount = (wafRuleGroup ? wafRuleGroup.groupCount : 0) + count;

            rules.push({
                ruleId: {
                    value: ruleId,
                    param: wafRuleGroupsIdKey,
                },
                ruleName: {
                    value: ruleName,
                    param: wafRuleGroupsNameKey,
                },
                count,
                percentage,
            });

            wafRuleGroups[groupName] = {
                rules,
                groupCount: totalCount,
            };
        }
    });

    Object.keys(wafRuleGroups).forEach(groupName => {
        const { rules } = wafRuleGroups[groupName];
        const { groupCount } = wafRuleGroups[groupName].groupCount;
        const resObj: IVsLogsWafRuleGroupsSignpostData = {
            children: rules,
            name: groupName,
            count: groupCount,
            value: {
                value: groupName,
                param: wafRuleGroupsGroupKey,
            },
        };

        transformedResult.push(resObj);
    });

    return transformedResult;
};

/**
 * Transform received results for WAF latency template in the form usable for displaying
 * WAF latency signpost.
 */
export const transformWafLatencyResults = (
    combinedData: IVsLogsWafLatencyResponseData,
): IVsLogsWafLatencySignpostData => {
    const configKey = customTemplates.wafPhaseLatency;
    const { phases } = templatesConfiguration[configKey];
    const transformedResultObj: IVsLogsWafLatencySignpostData = {
        requestHeader: [],
        requestBody: [],
        responseHeader: [],
        responseBody: [],
    };

    if (combinedData) {
        Object.values(combinedData).forEach((singleRequestData, index) => {
            const { name, groupby } = phases[index];

            transformedResultObj[wafLatencyPhases[index]] =
                singleRequestData.results?.map((res: IVsLogsGroupbyResponseResultData) => {
                    const value = res[groupby];

                    return {
                        value,
                        param: groupby,
                        isValid: Boolean(value),
                        count: res.count,
                        percentage: res.percentage,
                        phase: name,
                    };
                }) || [];
        });
    }

    return transformedResultObj;
};

/**
 * Transform received results for e2e timing template in the form usable for displaying
 * e2e signpost.
 */
export const transformEndToEndTimingSignpostResults = (
    combinedData: IVsLogsEndToEndTimingResponseData,
): IVsLogsEndToEndTimingSignpostData => {
    const [averageData] = combinedData.average?.results;
    const e2eResult = combinedData.e2eResult?.results;
    const appResponseTimeKey = e2eAverageKeyGenerator(e2eTimingGroupByKeys.appResponseTime);
    const clientRttKey = e2eAverageKeyGenerator(e2eTimingGroupByKeys.clientRtt);
    const dataTransferTimeKey = e2eAverageKeyGenerator(e2eTimingGroupByKeys.dataTransferTime);
    const serverRttKey = e2eAverageKeyGenerator(e2eTimingGroupByKeys.serverRtt);
    const totalTimeKey = e2eAverageKeyGenerator(e2eTimingGroupByKeys.totalTime);
    const transformedResultObj: IVsLogsEndToEndTimingSignpostData = {};

    if (averageData) {
        transformedResultObj.average = {
            app_response_time: averageData[appResponseTimeKey]?.toFixed(),
            client_rtt: averageData[clientRttKey]?.toFixed(),
            data_transfer_time: averageData[dataTransferTimeKey]?.toFixed(),
            server_rtt: averageData[serverRttKey]?.toFixed(),
            total_time: averageData[totalTimeKey]?.toFixed(),
        };
    }

    if (e2eResult) {
        // Calculating current groupby key, we take the 1 result object and find groupby.
        // If no key is found then we assign total_time as the default groupby for signpost.
        const resultKeys = Object.keys(e2eResult[0]);
        const groupby = resultKeys.find(
            key => Object.values(e2eTimingGroupByKeys).includes(key),
        ) || e2eTimingGroupByKeys.totalTime;

        transformedResultObj.e2eResult = transformResultsWithRange(e2eResult, groupby);
    }

    return transformedResultObj;
};

/**
 * Transform received results for WAF latency template in the form usable for displaying
 * policy signpost.
 */
export const transformPolicySignpostResults = (
    combinedData: IVsLogsPolicyGroupsResponseData,
): IVsLogsGroupsSignpostData[] => {
    const configKey = customTemplates.policy;
    const template = templatesConfiguration[configKey];
    const transformedResult: IVsLogsGroupsSignpostData[] = [];

    Object.keys(template.query).forEach(queryKey => {
        const singleRequestData = combinedData[queryKey];

        if (singleRequestData) {
            const children = singleRequestData.results?.map(
                (val: IVsLogsGroupbyResponseResultData) => {
                    const param: string = template.query[queryKey];
                    const currentValue: string = val[param];
                    const resObj: IVsLogsDefaultSignpostData = {
                        count: val.count,
                        percentage: val.percentage,
                        value: {
                            value: currentValue,
                            param,
                            isValid: Boolean(currentValue),
                        },
                    };

                    return resObj;
                },
            ) || [];

            transformedResult.push({
                name: template.subheader[queryKey],
                children,
            });
        }
    });

    return transformedResult;
};

/**
 * Transform received results for ssl in the form usable for displaying ssl signpost.
 */
export const transformSslSignpostResults = (
    combinedData: IVsLogsSslGroupsResponseData,
): IVsLogsGroupsSignpostData[] => {
    const configKey = customTemplates.ssl;
    const template = templatesConfiguration[configKey];
    const transformedResult: IVsLogsGroupsSignpostData[] = [];

    // Two types of data.
    const { ssl, other } = combinedData;

    // Ssl: 'ssl_version' section.
    if (ssl) {
        const { results: sslVersionResults = [] } = ssl;

        const children = sslVersionResults.map((response: IVsLogsGroupbyResponseResultData) => {
            const sslVersionKey: string = template.query.ssl;
            const resObj: IVsLogsDefaultSignpostData = {
                count: response.count,
                percentage: response.percentage,
                value: {
                    value: response[sslVersionKey],
                    param: sslVersionKey,
                    isValid: Boolean(response[sslVersionKey]),
                },
            };

            return resObj;
        });

        transformedResult.push({
            name: template.subheader[customTemplates.ssl],
            children,
        });
    }

    // Other: 'ssl_cipher' section.
    if (other) {
        const sslCipherKeys = template.key;

        Object.keys(sslCipherKeys).forEach(key => {
            const { category_results: categoryResults } = other;
            const prefix: string = sslCipherKeys[key];
            const sslCipherResults = categoryResults[sslCipherKeys[key]] || [];

            const children = sslCipherResults.map((response: IVsLogsGroupbyResponseResultData) => {
                const { value } = response;
                const sslCipherKey: string = template.query.other;
                const resObj: IVsLogsDefaultSignpostData = {
                    count: response.count,
                    percentage: response.percentage,
                    value: {
                        display: value,
                        value: `${prefix}:${value}`,
                        param: sslCipherKey,
                        isValid: Boolean(value),
                        contains: true,
                    },
                };

                return resObj;
            });

            transformedResult.push({
                name: template.subheader[key],
                children,
            });
        });
    }

    return transformedResult;
};

/**
 * Update value attribute of entries which have single param, value is used for displaying
 * default signpost.
 */
export const selectValue = (
    param: string,
    record: IVsLogsGroupbyResponseResultData,
): IVsLogsSignpostTransformedResult => {
    let value = record[param];
    const result: IVsLogsSignpostTransformedResult = {
        value,
        param,
        isValid: true,
    };
    const either = [
        ['client_ip', 'client_ip6'],
        ['vs_ip', 'vs_ip6'],
        ['server_ip', 'server_ip6'],
    ];

    either.forEach(([param2, param1]) => {
        if (param1 in record && record[param1] !== '') {
            value = record[param1];
            result.param = param1;
        } else if (param2 in record) {
            value = record[param2];
            result.param = param2;
        }
    });

    if (value === '' || value === 0 || value === '0') {
        result.isValid = false;
    }

    result.value = value;

    return result;
};
