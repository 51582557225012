/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @description VRFContext collection dataSource.
 *
 * @author Alex Malisky, Aravindh Nagarajan
 */

import { ListCollDataSource } from 'ajs/modules/data-model/factories/list-coll-data-source.factory';

export class VRFContextCollDataSource extends ListCollDataSource {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            defaultParams: {
                exclude: 'name.in',
                'name.in': 'management',
                includeName_: true,
            },
        };

        super(extendedArgs);
    }
}
