/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-connection';
const prefix = `${moduleName}.${componentName}`;

export const packetsLabel = `${prefix}.packetsLabel`;
export const transfersLabel = `${prefix}.transfersLabel`;
export const connectionIssuesLabel = `${prefix}.connectionIssuesLabel`;

export const ENGLISH = {
    [packetsLabel]: 'Packets',
    [transfersLabel]: 'Transfers',
    [connectionIssuesLabel]: 'Connection Issues',
};
