/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    DropdownModelSingleValue,
    IAviDropdownOption,
} from 'ng/shared/components';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './gslb-geodb-profile-entries-grid.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid component for Geo Profile Entries.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'gslb-geodb-profile-entries-grid',
    templateUrl: './gslb-geodb-profile-entries-grid.component.html',
})
export class GslbGeoDbProfileEntriesGridComponent implements AfterViewInit {
    /**
     * List of GSLB GeoDb Profile Entries.
     */
    @Input()
    public paramsList: RepeatedMessageItem<MessageItem>;

    /**
     * Template Ref for GslbGeoDbProfile Filename Dropdown.
     */
    @ViewChild('filenameTemplateRef')
    public filenameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template Ref for GslbGeoDbProfile Priority Input.
     */
    @ViewChild('priorityTemplateRef')
    public priorityTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template Ref for GslbGeoDbProfile File Format Dropdown.
     */
    @ViewChild('formatTemplateRef')
    public formatTemplateRef: TemplateRef<HTMLElement>;

    /**
     * GslbGeoDbProfile Filename Dropdown Options.
     */
    @Input()
    public filenameDropdownOptions: IAviDropdownOption[];

    /**
     * GslbGeoDbProfile File Format Dropdown Options.
     */
    @Input()
    public formatDropdownOptions: IAviDropdownOption[];

    /**
     * Entries Grid Config.
     */
    public entriesGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * GslbGeoDbProfileItem Object Type.
     */
    public objectType: string;

    /**
     * List of filenames that are selected so far.
     */
    public selectedFilenames: DropdownModelSingleValue[] = [];

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Add Entry for Entries Grid.
     */
    public addEntry(): void {
        this.paramsList.add();
    }

    /**
     * Set the selectedFilenames field with filenames that are selected so far.
     */
    public setSelectedFilenames(): void {
        const params: MessageItem[] = this.paramsList.getConfig();

        this.selectedFilenames = params
            .reduce((filenames: DropdownModelSingleValue[], item: MessageItem) => {
                const { filename } = item.config.file.config;

                if (filename) {
                    filenames.push(filename);
                }

                return filenames;
            }, []);
    }

    /**
     * @override
     * entriesGridConfig is set with field labels, multi-delete actions and
     * delete action per entry.
     */
    public ngAfterViewInit(): void {
        this.entriesGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.filenameDropdownLabel),
                    id: 'filename',
                    templateRef: this.filenameTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.l10nKeys.priorityInputLabel),
                    id: 'priority',
                    templateRef: this.priorityTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.l10nKeys.formatDropdownLabel),
                    id: 'format',
                    templateRef: this.formatTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                    onClick: (gslbGeoDbProfileEntryList: MessageItem[]) => {
                        gslbGeoDbProfileEntryList.map(entry => this.removeEntry(entry));
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                    shape: 'trash',
                    onClick: (gslbGeoDbProfileEntry: MessageItem) => {
                        this.removeEntry(gslbGeoDbProfileEntry);
                    },
                },
            ],
        };
        this.setSelectedFilenames();
    }

    /**
     * Remove Entry from Entries Grid.
     */
    private removeEntry(entry: MessageItem): void {
        this.paramsList.removeByMessageItem(entry);
        this.setSelectedFilenames();
    }
}
