<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalHeaderEdit: l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ selectedGslbSite ? selectedGslbSite.config.name : '' }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [valid]="form.valid"
    class="gslb-placement-modal"
>
    <form 
        clrForm 
        [clrLayout]="verticalLayout" 
        #form="ngForm"
    >
        <!-- General -->
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="gslb-site"
                            required
                        >
                            {{ l10nKeys.gslbSiteDropdownLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="gslb-site"
                            [options]="gslbSitesOptions"
                            [(ngModel)]="selectedSiteAndDnsVs.siteName"
                            (ngModelChange)="onSelectedGslbSiteChange($event)"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.gslbSitePlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-alert-group
                            *ngIf="selectedSiteAndDnsVs.siteName && !availableDnsVsOptions.length"
                            alertType="warning"
                        >
                            <span>{{ l10nKeys.noDnsVsConfiguredWarning | vtranslate }}</span>
                        </avi-alert-group>
                        <avi-repeated-dropdowns-grid
                            [gridTitle]="l10nKeys.dnsVsGridTitle | vtranslate : selectedSiteAndDnsVs.dnsVsNames?.length || 0"
                            name="gslb-site-dns-vs"
                            [placeholder]="l10nKeys.dnsVsDropdownPlaceholder | vtranslate"
                            [columnLabel]="l10nKeys.dnsVsColumnTitle"
                            [hideSearch]="true"
                            [options]="availableDnsVsOptions"
                            [(ngModel)]="selectedSiteAndDnsVs.dnsVsNames"
                            [maxRows]="selectedSiteAndDnsVs.siteName ? availableDnsVsOptions.length : 0"
                        ></avi-repeated-dropdowns-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
