<div class="collapsed-sidebar-icon-list">
    <div
        *ngFor="let name of iconNames; trackBy: trackByIndex"
        class="collapsed-sidebar-icon-list__row"
    >
        <cds-icon
            class="vs-logs-collapsed-sidebar__row-icon"
            shape="{{ name }}"
            size="16"
        ></cds-icon>
        <cds-icon
            shape="angle"
            direction="right"
            size="10"
        ></cds-icon>
    </div>
</div>
