/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'software-page';
const prefix = `${moduleName}.${componentName}`;

export const uploadFromComputerBtnLabel = `${prefix}.uploadFromComputerBtnLabel`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleVersion = `${prefix}.columnTitleVersion`;
export const columnTitleAttributes = `${prefix}.columnTitleAttributes`;
export const deleteButtonLabel = `${prefix}.deleteButtonLabel`;
export const uploadingStatusMessage = `${prefix}.uploadingStatusMessage`;
export const processingCompleteStatusMessage = `${prefix}.processingCompleteStatusMessage`;
export const uploadCompleteMessage = `${prefix}.uploadCompleteMessage`;
export const leavingPageUploadMessage = `${prefix}.leavingPageUploadMessage`;
export const currentVersionLabel = `${prefix}.currentVersionLabel`;

export const ENGLISH = {
    [uploadFromComputerBtnLabel]: 'Upload From Computer',
    [columnTitleType]: 'Type',
    [columnTitleVersion]: 'Version',
    [columnTitleAttributes]: 'Attributes',
    [deleteButtonLabel]: 'Delete',
    [uploadingStatusMessage]: 'Uploading',
    [processingCompleteStatusMessage]: 'Processing complete',
    [uploadCompleteMessage]: 'Upload Complete! {0}',
    [leavingPageUploadMessage]: '{0} is in the process of being uploaded, leaving the page will terminate the upload.',
    [currentVersionLabel]: '{0} (current)',
};
