<div [ngClass]="aviNotificationTypeClass">
    <div class="avi-notification__icon-container">
        <i [ngClass]="aviNotificationTypeIconClass"></i>
    </div>
    <div class="avi-notification__message">
        <ng-content></ng-content>
    </div>
    <div
        *ngIf="showClose"
        class="avi-notification__close-button sl-icon-close"
        (click)="handleClose()"
    ></div>
</div>
