/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MetricUnits } from 'generated-types';
import { IDiskUsage } from '../node-vitals-widget.store';

/**
 * Contains formatted used and total numbers with the converted units.
 */
export interface IConvertedDiskUsage {
    used: string;
    total: string;
    units: MetricUnits;
}

/**
 * Convert DiskUsage used and total numbers (bytes) into more readable units, such that the total
 * usage is always less than 1024 converted.
 */
export function convertDiskUsage(diskUsage: IDiskUsage): IConvertedDiskUsage {
    const { used, total } = diskUsage;

    const output = {
        used,
        total,
        units: MetricUnits.BYTES,
    };

    const unitsList = [
        MetricUnits.KILO_BYTES,
        MetricUnits.MEGA_BYTES,
        MetricUnits.GIGA_BYTES,
    ];

    for (const units of unitsList) {
        if (output.total <= 1024) {
            break;
        }

        output.used /= 1024;
        output.total /= 1024;
        output.units = units;
    }

    return {
        ...output,
        used: output.used.toFixed(1),
        total: output.total.toFixed(1),
    };
}

