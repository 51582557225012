<div 
    class="gslb-subdomains-tree-node"
    [ngClass]="'gslb-subdomains-tree-node__'+ nodeType"
>
    <avi-card class="gslb-subdomains-tree-node__focus-{{ focusLevel }}">
        <avi-card_body class="gslb-subdomains-tree-node__card">
            <div class="gslb-subdomains-tree-node__card-body">
                <div class="gslb-subdomains-tree-node__data-container">
                    <avi-tooltip-icon-with-content-wrapper
                        shape="circle"
                        size="24"
                        [status]="data.status.state === 'OPER_UP' ? 'success' : 'danger'" 
                        [solid]="true"
                        class="gslb-subdomains-tree-node__node-status"
                        *ngIf="data.status"
                    >
                        {{ data.status.reason?.join() }}
                    </avi-tooltip-icon-with-content-wrapper>
                    <div class="gslb-subdomains-tree-node__name-section">
                        <h6 class="gslb-subdomains-tree-node__node-name">{{ data.name }}</h6>
                        <cds-tag
                            readonly
                            class="gslb-subdomains-tree-node__host-subdomain-tag"
                            status="info"
                            *ngIf="nodeType === nodeTypeEnum.LEAF_NODE && data.hostAllSubdomains"
                        >
                            {{ l10nKeys.hostAllSubdomainLabel | vtranslate }}
                        </cds-tag>
                    </div>
                </div>

                <div 
                    class="gslb-subdomains-tree-node__icon-container"
                    *ngIf="nodeType === nodeTypeEnum.ROOT_NODE && isGslbEditable"
                >
                    <button 
                        class="btn btn-link gslb-subdomains-tree-node__action-btn"
                        (click)="handleEdit()"
                    >
                        <cds-icon 
                            shape="pencil"
                            title="{{ globalL10nKeys.editLabel | vtranslate }}"
                            size="14"
                        ></cds-icon>
                    </button>
                    <button 
                        class="btn btn-link gslb-subdomains-tree-node__action-btn"
                        (click)="handleRemove()"
                    >
                        <cds-icon 
                            shape="trash"
                            title="{{ globalL10nKeys.deleteLabel | vtranslate }}"
                            size="14"
                        ></cds-icon>
                    </button>
                </div>
            </div>
        </avi-card_body>
    </avi-card>
</div>
