/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module IpamModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { CustomIpamSubnetConfigItem }
    from 'ajs/modules/ipam/factories/custom-ipam-subnet.config-item.factory';

import { ICustomIpamSubnet } from 'generated-types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as globalL10n from 'global-l10n';
import * as l10n from './usable-subnet-modal.l10n';

const { ENGLISH, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Usable Subnet modal component.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'usable-subnet-modal',
    templateUrl: './usable-subnet-modal.component.html',
})
export class UsableSubnetModalComponent implements OnInit {
    @Input()
    public editable: CustomIpamSubnetConfigItem;

    /**
     * Boolean flag to check if modal is opened in edit mode.
     */
    @Input()
    public editMode = false;

    @Input()
    public availableSubnets: ICustomIpamSubnet[];

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<CustomIpamSubnetConfigItem>();

    public objectType: string;

    public availableSubnetOptions: IAviDropdownOption[];

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.availableSubnetOptions =
            this.availableSubnets.map(subnet => createDropdownOption(subnet.network_id));
    }

    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Set the subnet values based on selected Network ID.
     */
    public handleSelectSubnet(networkId: string): void {
        const selectednetwork = this.availableSubnets
            .find((subnet: ICustomIpamSubnet) => subnet.network_id === networkId);

        this.editable.config.subnet.subnet = null;
        this.editable.config.subnet6.subnet = null;

        if (selectednetwork.subnet) {
            this.editable.config.subnet.subnet =
                `${selectednetwork.subnet.ip_addr.addr}/${selectednetwork.subnet.mask}`;
        }

        if (selectednetwork.subnet6) {
            this.editable.config.subnet6.subnet =
                `${selectednetwork.subnet6.ip_addr.addr}/${selectednetwork.subnet6.mask}`;
        }
    }
}
