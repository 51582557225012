/**
 * @module DialogModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-continue-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to be used to make sure user wants to continue with intended action.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'avi-continue-confirmation',
    templateUrl: './avi-continue-confirmation.component.html',
})
export class AviContinueConfirmationComponent {
    /**
     * Custom warning to be added if desired. Separate from continue question warning.
     * Should be passed in as translated l10n message.
     */
    @Input()
    public readonly warning?: string;

    /**
     * Custom header to be added if desired.
     * Should be passed in as translated l10n message.
     */
    @Input()
    private readonly customHeader?: string;

    /**
     * Event to be fired upon user canceling.
     */
    @Output()
    private readonly onClose = new EventEmitter();

    /**
     * Event to be fired upon user confirming continue.
     */
    @Output()
    private readonly onConfirm = new EventEmitter();

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Header to display for popup.
     */
    public get header(): string {
        return this.customHeader || this.l10nService.getMessage(l10nKeys.warningHeader);
    }

    /**
     * Handles user clicking -> Cancel.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Handles user clicking -> Yes, Continue.
     */
    public handleContinue(): void {
        this.onConfirm.emit();
    }
}
