/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createAction, props } from '@ngrx/store';

const ROOT_STORE_SOURCE = '[Root Store]';

export const setLoading = createAction(
    `${ROOT_STORE_SOURCE} Set Loading`,
    props<{ loading: boolean }>(),
);
