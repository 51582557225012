<div class="recommendation-grid">
    <avi-data-grid
        [config]="recommendationGridConfig"
        [rows]="recommendationList"
    ></avi-data-grid>
    <ng-template
        #expandedContentTemplateRef
        let-row
    >
        <div class="recommendation-grid__expanded-content clr-wrapper">
            <div class="recommendation-grid__field-container">
                <label
                    for="action-description"
                    aviLabelWithTooltip
                >
                    {{ l10nKeys.descriptionLabel | vtranslate }}
                </label>
                <div
                    id="action-description"
                    class="recommendation-grid__field-value"
                >
                    {{ row.action_description }}
                </div>
            </div>
            <div class="recommendation-grid__field-container">
                <label
                    for="action-reasoning"
                    aviLabelWithTooltip
                >
                    {{ l10nKeys.reasoningLabel | vtranslate }}
                </label>
                <div
                    id="action-reasoning"
                    class="recommendation-grid__field-value"
                >
                    {{ row.action_reasoning }}
                </div>
            </div>
            <div class="recommendation-grid__field-container">
                <label
                    for="action-risk-assesment"
                    aviLabelWithTooltip
                >
                    {{ l10nKeys.actionRiskAssesmentLabel | vtranslate }}
                </label>
                <div
                    id="action-risk-assesment"
                    class="recommendation-grid__field-value"
                >
                    {{ row.action_risk_assesment }}
                </div>
            </div>
        </div>
    </ng-template>
</div>
