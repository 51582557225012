/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'alert';
const componentName = 'alert-syslog-modal';
const prefix = `${moduleName}.${componentName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const removeLabel = `${prefix}.removeLabel`;
export const editLabel = `${prefix}.editLabel`;
export const addLabel = `${prefix}.addLabel`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const syslogServersLabel = `${prefix}.syslogServersLabel`;
export const addressLabel = `${prefix}.addressLabel`;
export const portLabel = `${prefix}.portLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [nameLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [modalHeaderEdit]: 'Edit Syslog',
    [modalHeaderNew]: 'New Syslog',
    [sectionTitleGeneral]: 'General',
    [removeLabel]: 'Remove',
    [editLabel]: 'Edit',
    [addLabel]: 'Add',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
    [syslogServersLabel]: 'Syslog Servers ({0})',
    [addressLabel]: 'Address',
    [portLabel]: 'Port',
    [removeButtonLabel]: 'Remove',
};
