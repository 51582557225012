/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export enum AviNotificationType {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

/**
 * Type for a user-defined action to click on within a notification pop-up.
 */
export interface IAviNotificationAction {
    label: string;
    onClick(): any;
}
