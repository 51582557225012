/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import { Injector } from '@angular/core';

import {
    ImageCollection,
    IMAGE_COLLECTION_TOKEN,
    TImageCollection,
} from 'ajs/modules/upgrade/factories/image-collection.factory';

const $injector = '$injector';

export const imageCollectionProvider = {
    deps: [$injector],
    provide: ImageCollection,
    useFactory(injector: Injector): TImageCollection {
        return injector.get(IMAGE_COLLECTION_TOKEN);
    },
};
