/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { CustomParamsConfigItem }
    from 'ajs/modules/security/factories/certificate-management/custom-params.config-item.factory';

import * as l10n from './certificate-management-custom-params-grid.l10n';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description
 *      Certificate Management Custom Params grid component.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'certificate-management-custom-params-grid',
    templateUrl: './certificate-management-custom-params-grid.component.html',
})
export class CertificateManagementCustomParamsGridComponent {
    /**
     * List of CustomParams Config Items.
     */
    @Input()
    public scriptParamsList: RepeatedMessageItem<CustomParamsConfigItem>;

    /**
     * Fires on add Script Param.
     */
    @Output()
    public onAddScriptParam = new EventEmitter();

    /**
     * Fires on edit Script Param.
     */
    @Output()
    public onEditScriptParam = new EventEmitter<CustomParamsConfigItem>();

    /**
     * Fires on remove Script Param.
     */
    @Output()
    public onRemoveScriptParam = new EventEmitter<CustomParamsConfigItem>();

    /**
     * TemplateRef for sensitive checkbox field.
     */
    @ViewChild('sensitiveFieldTemplateRef')
    public sensitiveFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for dynamic checkbox field.
     */
    @ViewChild('dynamicFieldTemplateRef')
    public dynamicFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Custom params grid config.
     */
    public customParamsGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.customParamsGridConfig = this.getCustomParamsGridConfig();
    }

    /**
     * Adds a Custom Param.
     */
    public addScriptParam(): void {
        this.onAddScriptParam.emit();
    }

    /**
     * Returns custom script params count.
     */
    public get customScriptParamsCount(): number {
        return this.scriptParamsList.count;
    }

    /**
     * Returns Custom Params grid config.
     */
    private getCustomParamsGridConfig(): IAviDataGridConfig {
        const customParamsGridConfig: IAviDataGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                    id: 'name',
                    transform: ({ config: { name } }: CustomParamsConfigItem) => {
                        return name;
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleSensitive),
                    id: 'is-sensitive',
                    templateRef: this.sensitiveFieldTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleDynamic),
                    id: 'is-dynamic',
                    templateRef: this.dynamicFieldTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                    onClick: (scriptParamsList: CustomParamsConfigItem[]) => {
                        scriptParamsList.forEach((scriptParam: CustomParamsConfigItem) => {
                            this.removeScriptParam(scriptParam);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.editButtonLabel),
                    shape: 'pencil',
                    onClick: (scriptParam: CustomParamsConfigItem) => {
                        this.editScriptParam(scriptParam);
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.removeActionLabel),
                    shape: 'trash',
                    onClick: (scriptParam: CustomParamsConfigItem) => {
                        this.removeScriptParam(scriptParam);
                    },
                },
            ],
        };

        return customParamsGridConfig;
    }

    /**
     * Handler for Script Param edit operation.
     */
    private editScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.onEditScriptParam.emit(scriptParam);
    }

    /**
     * Handler for Script Param remove operation.
     */
    private removeScriptParam(scriptParam: CustomParamsConfigItem): void {
        this.onRemoveScriptParam.emit(scriptParam);
    }
}
