/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { MetricUnits } from 'generated-types';
import * as globalL10n from 'global-l10n';
import { convertDiskUsage } from './node-vitals-usage-info.utils';

import type {
    IDiskUsage,
    IMemoryUsage,
    INodeVitals,
} from '../node-vitals-widget.store';

import './node-vitals-usage-info.component.less';
import * as l10n from './node-vitals-usage-info.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Colors for usage bars to create contrast for 3-node clusters.
 */
const colors = [
    'hsl(198, 100%, 36%)',
    'hsl(282, 43%, 54%)',
    'hsl(198, 58%, 78%)',
];

/**
 * @description
 * Displays node vitals, which consists of CPU, Disk, and Memory Usage. Each of the vitals shows a
 * bar indicating percentage used.
 * @author alextsg
 */
@Component({
    selector: 'node-vitals-usage-info',
    templateUrl: './node-vitals-usage-info.component.html',
})
export class NodeVitalsUsageInfoComponent implements OnChanges {
    @Input()
    public nodeVitals: INodeVitals;

    @Input()
    public index = 0;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Color used for the usage bars.
     */
    public color = colors[this.index];

    /**
     * Used disk usage as a string after formatting.
     */
    public usedDiskUsage: string;

    /**
     * Total disk usage as a string after formatting.
     */
    public totalDiskUsage: string;

    /**
     * Units for both the used and total disk usage numbers.
     */
    public diskUsageUnits: string;

    public diskUsage: IDiskUsage;

    public memoryUsage: IMemoryUsage;

    public metricLabels = {
        [MetricUnits.BYTES]: this.l10nService.getMessage(globalL10nKeys.bytesLabel),
        [MetricUnits.KILO_BYTES]: this.l10nService.getMessage(globalL10nKeys.kilobytesLabel),
        [MetricUnits.MEGA_BYTES]: this.l10nService.getMessage(globalL10nKeys.megabytesLabel),
        [MetricUnits.GIGA_BYTES]: this.l10nService.getMessage(globalL10nKeys.gigabytesLabel),
    };

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Set the color and node vitals properties. Set in ngOnChanges to account for changes to values
     * and for the small chance that the number of nodes changes which would require changing the
     * color.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const { nodeVitals, index } = changes;

        if (index !== undefined) {
            this.color = colors[index.currentValue];
        }

        if (nodeVitals !== undefined) {
            const { currentValue: currentNodeVitals } = nodeVitals;

            ({
                diskUsage: this.diskUsage,
                memoryUsage: this.memoryUsage,
            } = currentNodeVitals);

            const {
                used,
                total,
                units,
            } = convertDiskUsage(this.diskUsage);

            this.usedDiskUsage = used;
            this.totalDiskUsage = total;
            this.diskUsageUnits = this.metricLabels[units];
        }
    }
}
