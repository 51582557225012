<div class="policy-rule-summary">
    <div
        class="policy-rule-summary__cell
            policy-rule-summary__cell--header
            policy-rule-summary__cell--match-header"
    >
        {{ l10nKeys.matchHeaderLabel | vtranslate : rule.matchCount }}
    </div>
    <div class="policy-rule-summary__cell policy-rule-summary__cell--header">
        {{ l10nKeys.actionHeaderLabel | vtranslate : rule.actionCount }}
    </div>
    <div
        *ngFor="let actionInfo of rule.configuredActionInfoList; trackBy: trackByActionLabel"
        class="policy-rule-summary__cell policy-rule-summary__cell--action"
    >
        <div class="policy-rule-summary__cell-label">{{ actionInfo.actionLabel }}</div>
        <div class="policy-rule-summary__cell-value">{{ actionInfo.actionValue }}</div>
    </div>
    <div
        *ngFor="let matchInfo of rule.configuredMatchInfoList; trackBy: trackByMatchLabel"
        class="policy-rule-summary__cell policy-rule-summary__cell--match"
    >
        <div class="policy-rule-summary__cell-label">{{ matchInfo.matchLabel }}</div>
        <div class="policy-rule-summary__cell-value">{{ matchInfo.matchValue }}</div>
    </div>
</div>
