/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'user-card';
const prefix = `${moduleName}.${componentName}`;

export const lastLoginLabel = `${prefix}.lastLoginLabel`;
export const myAccountLabel = `${prefix}.myAccountLabel`;

export const ENGLISH = {
    [lastLoginLabel]: 'Last Login {0}',
    [myAccountLabel]: 'My Account',
};
