<avi-state-page class="alert-config-list-page">
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="alertConfigGridConfig">
            <ng-template #expandedContentTemplateRef let-row>
                <alert-config-grid-expander
                    class="alert-config-list-page__alert-config-grid-expander"
                    [alertConfig]="row"
                ></alert-config-grid-expander>
            </ng-template>
        </avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
