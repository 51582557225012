<div
    class="full-modal-config__section"
    provideParentForm
>

    <h3>{{ (isFtpsType ? l10nKeys.sectionTitleFtps : l10nKeys.sectionTitleFtp) | vtranslate }}</h3>

    <health-monitor-authentication
        [editable]="editable"
        [showAuthTypeDropdown]="false"
    ></health-monitor-authentication>

    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>

    <div class="full-modal-config__form-control-row">
        <h6>{{ l10nKeys.fileInformationLabel | vtranslate }}</h6>
    </div>

    <avi-input-container noMarginTop>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorFtp }}"
            fieldName="filename"
            for="filename"
            required
        >
            {{ l10nKeys.fileNameLabel | vtranslate }}
        </label>
        <input
            type="text"
            id="filename"
            clrInput
            placeholder="{{ l10nKeys.fileNamePlaceholder | vtranslate }}"
            name="filename"
            [(ngModel)]="ftpConfigItem.config.filename"
            required
        />
    </avi-input-container>

    <enum-radio-buttons
        enum="FtpModeType"
        name="mode"
        [(ngModel)]="ftpConfigItem.config.mode"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorFtp }}"
            fieldName="mode"
        >
            {{ l10nKeys.transferModeLabel | vtranslate }}
        </label>
    </enum-radio-buttons>

    <!-- FTPS type related fields -->
    <ng-container *ngIf="isFtpsType">
        <div class="full-modal-config__form-control-row">
            <h6>{{ globalL10nKeys.securityLabel | vtranslate }}</h6>
        </div>

        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                fieldName="server_name"
                for="server-name"
            >
                {{ globalL10nKeys.tlsSniServerNameLabel | vtranslate }}
            </label>
            <input
                type="text"
                id="server-name"
                clrInput
                placeholder="{{ l10nKeys.tlsSniServerPlaceholder | vtranslate }}"
                name="server-name"
                [(ngModel)]="ftpConfigItem.config.ssl_attributes.config.server_name"
                regexPattern="hostname"
            />
        </avi-input-container>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_profile_uuid"
                    for="ssl-profile-uuid"
                    required
                >
                    {{ globalL10nKeys.sslProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-profile-uuid"
                    name="ssl-profile-uuid"
                    [collection]="sslProfileCollection"
                    [(ngModel)]="ftpConfigItem.config.ssl_attributes.config.ssl_profile_ref"
                    placeholder="{{ globalL10nKeys.sslProfilePlaceholder | vtranslate }}"
                    required
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="pki_profile_uuid"
                    for="pki-profile-uuid"
                >
                    {{ globalL10nKeys.pkiProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="pki-profile-uuid"
                    name="pki-profile-uuid"
                    [collection]="pkiProfileCollection"
                    [(ngModel)]="ftpConfigItem.config.ssl_attributes.config.pki_profile_ref"
                    placeholder="{{ globalL10nKeys.pkiProfilePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_key_and_certificate_uuid"
                    for="ssl-key-and-certificate-uuid"
                >
                    {{ globalL10nKeys.sslKeyAndCertificateLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-key-and-certificate-uuid"
                    name="ssl-key-and-certificate-uuid"
                    [collection]="certificateCollection"
                    [(ngModel)]="ftpConfigItem.config.ssl_attributes.config.ssl_key_and_certificate_ref"
                    placeholder="{{ globalL10nKeys.sslKeyAndCertificatePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>
    </ng-container>

</div>
