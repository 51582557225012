/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { JWTServerProfile } from 'ajs/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import * as l10n from './jwt-server-profile-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for JWT Server Profile create/edit modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'jwt-server-profile-modal',
    templateUrl: './jwt-server-profile-modal.component.html',
})
export class JWTServerProfileModalComponent {
    /**
     * JWTServerProfile ObjectTypeItem.
     */
    @Input()
    public editable: JWTServerProfile;

    /**
     * Layout for ICAP modal clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
