<div class="avi-collection-data-grid">
    <h6
        *ngIf="gridTitle"
        class="avi-collection-data-grid__grid-title"
    >
        {{ gridTitle }}
    </h6>
    <div class="avi-data-grid__header">
        <ng-content select="avi-data-grid_header"></ng-content>
    </div>
    <avi-data-grid-base
        [config]="internalConfig"
        [rows]="collection.itemList"
        [rowsTotal]="collection.getTotalNumberOfItems()"
        [isLoading]="collection.busy"
        (onSelectionChange)="onRowSelectionChange($event)"
        (onDataGridStateChange)="updateLocalDataGridState($event)"
        (onSearch)="search($event)"
        [showSearch]="!config?.layout?.hideSearch"
    >
        <avi-data-grid_actions>
            <ng-container *ngIf="showCreate">
                <avi-dropdown-button
                    *ngIf="useDropdownCreate"
                    [actions]="internalConfig.createActions"
                    [disabled]="disableCreate"
                    [position]="createDropdownPrimaryPosition"
                    size="sm"
                >
                    {{ createButtonLabel }}
                </avi-dropdown-button>
                <cds-button
                    size="sm"
                    *ngIf="!useDropdownCreate"
                    (click)="collection.create()"
                    [disabled]="disableCreate"
                >
                    {{ createButtonLabel }}
                </cds-button>
            </ng-container>
        </avi-data-grid_actions>
    </avi-data-grid-base>
</div>
