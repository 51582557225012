/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-subdomains-grid';
const prefix = `${moduleName}.${componentName}`;

export const subdomainsHeaderLabel = `${prefix}.subdomainsHeaderLabel`;
export const subdomainLabel = `${prefix}.subdomainLabel`;
export const subdomainPlaceholder = `${prefix}.subdomainPlaceholder`;

export const ENGLISH = {
    [subdomainsHeaderLabel]: 'Subdomains ({0})',
    [subdomainLabel]: 'Subdomain',
    [subdomainPlaceholder]: 'Enter Subdomain',
};
