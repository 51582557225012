/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';

import { VsLogsStore } from '../../services/vs-logs.store';
import { FilterOperatorType } from '../../vs-logs.types';
import './vs-log-filter-empty-field.directive.less';

/**
 * @description Directive to style a clickable filter with <NO-DATA> as the label and '' as the
 *      value and add it to store upon click.
 * @author Suraj Kumar
 */
@Directive({
    selector: '[vsLogFilterableEmptyField]',
})
export class VsLogFilterableEmptyFieldDirective implements OnInit {
    /**
     * Property, operator, and value for a filter object.
     * See TFilterObj for reference.
     */
    @Input()
    public property: string;

    /**
     * Event emitter for when filter is added.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    /**
     * The native HTML element that the directive is bound to.
     */
    private domElement: HTMLElement;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        this.domElement = this.elementRef.nativeElement;

        this.renderer.addClass(elementRef.nativeElement, 'vs-log-filter-empty-field');
    }

    /**
     * Add filter to store upon click.
     */
    @HostListener('click')
    public addFilter(): void {
        this.vsLogsStore.addFilter({
            property: this.property,
            operator: FilterOperatorType.EQUAL_TO,
            value: '',
        });

        this.onFilterAdd.emit();
    }

    /** @override */
    public ngOnInit(): void {
        const textElement: HTMLElement = this.renderer.createText('<NO-DATA>');

        this.renderer.appendChild(this.domElement, textElement);
    }
}
