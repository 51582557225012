<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AutoScaleLaunchConfig"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        placeholder="{{ l10nKeys.namePlaceholder | vtranslate }}"
                        name="name"
                        id="name"
                        class="sel-name"
                        [(ngModel)]="editable.config.name"
                        required
                    />
                </avi-input-container>
                <avi-checkbox
                    name="use-external-asg"
                    objectType="AutoScaleLaunchConfig"
                    fieldName="use_external_asg"
                    [(ngModel)]="editable.config.use_external_asg"
                >
                    {{ l10nKeys.useExternalAsgLabel | vtranslate }}
                </avi-checkbox>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
