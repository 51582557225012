<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalClassName="icap-profile-modal"
    [form]="form"
    [item]="editable"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="name"
                    for="name"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    class="sel-name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    regexPattern="objName"
                    required
                />
            </avi-input-container>
            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="description"
                    for="description"
                >
                    {{ globalL10nKeys.descriptionLabel | vtranslate }}
                </label>
                <textarea
                    class="sel-description"
                    id="description"
                    name="description"
                    [(ngModel)]="editable.config.description"
                    clrTextarea
                    placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                ></textarea>
            </avi-textarea-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="cloud_uuid"
                        for="cloud_uuid"
                        required
                    >
                        {{ globalL10nKeys.cloudLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        class="sel-select-cloud"
                        name="cloud_uuid"
                        [(ngModel)]="editable.config.cloud_ref"
                        [collection]="cloudCollection"
                        (ngModelChange)="onCloudChange()"
                        placeholder="{{ globalL10nKeys.cloudPlaceholder | vtranslate }}"
                        required
                        [disableCreate]="true"
                        [disableEdit]="true"
                    ></avi-collection-dropdown>
                </div>
            </div>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="pool_group_uuid"
                        for="pool_group_uuid"
                        required
                    >
                        {{ globalL10nKeys.poolGroupLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        class="sel-select-poolgroup"
                        name="pool_group_uuid"
                        [(ngModel)]="editable.config.pool_group_ref"
                        [collection]="poolGroupCollection"
                        placeholder="{{ l10nKeys.poolGroupSelectPlaceholder | vtranslate }}"
                        required
                    ></avi-collection-dropdown>
                </div>
            </div>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-fieldset [hideContent]="editable.getVendor() !== icapVendorHash.ICAP_VENDOR_LASTLINE">
                        <avi-fieldset_header class="icap-profile-modal__vendor-fieldset">
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="vendor"
                                for="vendor"
                            >
                                {{ globalL10nKeys.vendorLabel | vtranslate }}
                            </label>
                            <avi-dropdown
                                class="sel-select-vendor"
                                name="vendor"
                                [(ngModel)]="editable.config.vendor"
                                [options]="icapVendorOptions"
                                (ngModelChange)="editable.configureIcapVendorSpecificFields()"
                                placeholder="{{ globalL10nKeys.vendorPlaceholder | vtranslate }}"
                                required
                            ></avi-dropdown>
                        </avi-fieldset_header>
                        <avi-fieldset_content>
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    fieldName="status_url"
                                    for="status_url"
                                    objectType="IcapNsxDefenderConfig"
                                >
                                    {{ l10nKeys.statusUrlInputLabel | vtranslate }}
                                </label>
                                <input
                                    id="status_url"
                                    name="status_url"
                                    [(ngModel)]="editable.icapNsxDefenderConfig?.config.status_url"
                                    clrInput
                                    placeholder="{{ l10nKeys.statusUrlInputPlaceholder | vtranslate }}"
                                />
                            </avi-input-container>
                        </avi-fieldset_content>
                    </avi-fieldset>
                </div>
            </div>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    fieldName="service_uri"
                    for="service_uri"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ l10nKeys.serviceUrlInputLabel | vtranslate }}
                </label>
                <input
                    class="sel-service-uri"
                    id="service_uri"
                    name="service_uri"
                    [(ngModel)]="editable.config.service_uri"
                    clrInput
                    placeholder="{{ l10nKeys.serverUrlInputPlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
            <avi-input-container helperText="{{ l10nKeys.kilobytesDataLabel | vtranslate }}">
                <label
                    aviLabelWithTooltip
                    fieldName="buffer_size"
                    for="buffer_size"
                    objectType="{{ objectType }}"
                >
                    {{ l10nKeys.bufferSizeInputLabel | vtranslate }}
                </label>
                <input
                    class="sel-buffer-size"
                    type="number"
                    id="buffer_size"
                    name="buffer_size"
                    [(ngModel)]="editable.config.buffer_size"
                    clrInput
                    placeholder="{{ l10nKeys.bufferSizeInputPlaceholder | vtranslate }}"
                    configFieldInputValidation
                    objectType="{{ objectType }}"
                    fieldName="buffer_size"
                />
            </avi-input-container>

            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-fieldset [hideContent]="!editable.config.enable_preview">
                        <avi-fieldset_header>
                            <clr-checkbox-container class="icap-profile-modal__no-margin-top">
                                <clr-checkbox-wrapper>
                                    <input
                                        class="sel-enable-preview"
                                        type="checkbox"
                                        id="enable_preview"
                                        name="enable_preview"
                                        [(ngModel)]="editable.config.enable_preview"
                                        clrCheckbox
                                    />
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="enable_preview"
                                        for="enable_preview"
                                    >
                                        {{ l10nKeys.enablePreviewInputLabel | vtranslate }}
                                    </label>
                                </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                        </avi-fieldset_header>
                        <avi-fieldset_content>
                            <avi-input-container helperText="{{ l10nKeys.bytesDataLabel | vtranslate }}" noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    fieldName="preview_size"
                                    for="preview_size"
                                    objectType="{{ objectType }}"
                                >
                                    {{ l10nKeys.previewSizeInputLabel | vtranslate }}
                                </label>
                                <input
                                    class="sel-preview-size"
                                    type="number"
                                    id="preview_size"
                                    name="preview_size"
                                    [(ngModel)]="editable.config.preview_size"
                                    clrInput
                                    placeholder="{{ l10nKeys.previewSizeInputPlaceholder | vtranslate }}"
                                    [disabled]="!editable.config.enable_preview"
                                    configFieldInputValidation
                                    objectType="{{ objectType }}"
                                    fieldName="preview_size"
                                />
                            </avi-input-container>
                        </avi-fieldset_content>
                    </avi-fieldset>
                </div>
            </div>

            <div class="full-modal-config__form-control-row icap-profile-modal__no-margin-top">
                <div class="full-modal-config__form-control">
                    <avi-input-container helperText="{{ globalL10nKeys.millisecondsLabel | vtranslate }}">
                        <label
                            aviLabelWithTooltip
                            fieldName="response_timeout"
                            for="response_timeout"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.responseTimeoutInputLabel | vtranslate }}
                        </label>
                        <input
                            class="sel-response-timeout"
                            type="number"
                            id="response_timeout"
                            name="response_timeout"
                            [(ngModel)]="editable.config.response_timeout"
                            clrInput
                            placeholder="{{ l10nKeys.responseTimeoutInputPlaceholder | vtranslate }}"
                            configFieldInputValidation
                            objectType="{{ objectType }}"
                            fieldName="response_timeout"
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container helperText="{{ globalL10nKeys.millisecondsLabel | vtranslate }}">
                        <label
                            aviLabelWithTooltip
                            fieldName="slow_response_warning_threshold"
                            for="slow_response_warning_threshold"
                            objectType="{{ objectType }}"
                        >
                            {{ l10nKeys.responseThresholdInputLabel | vtranslate }}
                        </label>
                        <input
                            class="sel-slow-resp-warning-threshold"
                            type="number"
                            id="slow_response_warning_threshold"
                            name="slow_response_warning_threshold"
                            [(ngModel)]="editable.config.slow_response_warning_threshold"
                            clrInput
                            placeholder="{{ l10nKeys.responseThresholdInputPlaceholder | vtranslate }}"
                            configFieldInputValidation
                            objectType="{{ objectType }}"
                            fieldName="slow_response_warning_threshold"
                        />
                    </avi-input-container>
                </div>
            </div>

            <div class="full-modal-config__form-control-row icap-profile-modal__no-margin-top">
                <div class="full-modal-config__form-control">
                    <avi-input-container>
                        <clr-checkbox-container class="icap-profile-modal__no-margin-top">
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    name="allow_204"
                                    [(ngModel)]="editable.config.allow_204"
                                    clrCheckbox
                                />
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType }}"
                                    fieldName="allow_204"
                                >
                                    {{ l10nKeys.allow204ResponseLabel | vtranslate }}
                                </label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </avi-input-container>
                </div>
            </div>
        </div>

        <div class="full-modal-config__section">
            <h3>{{ globalL10nKeys.actionLabel | vtranslate }}</h3>

            <avi-radio-container>
                <label
                    aviLabelWithTooltip
                    fieldName="fail_action"
                    objectType="{{ objectType }}"
                >
                    {{ l10nKeys.subSectionTitleFailureAction | vtranslate }}
                </label>
                <avi-radio
                    *ngFor="let failActionEnum of failActionEnumValues; trackBy: trackByFailActionValue"
                    class="sel-fail-action"
                    name="fail_action"
                    [(ngModel)]="editable.config.fail_action"
                    value="{{ failActionEnum.value }}"
                >
                    {{ failActionEnum.label }}
                </avi-radio>
            </avi-radio-container>

            <avi-radio-container>
                <label
                    aviLabelWithTooltip
                    fieldName="buffer_size_exceed_action"
                    objectType="{{ objectType }}"
                >
                    {{ l10nKeys.subSectionTitleLargeUploadFailureAction | vtranslate }}
                </label>
                <avi-radio
                    *ngFor="let failActionEnum of failActionEnumValues; trackBy: trackByFailActionValue"
                    class="sel-buffer-size-exceed-action"
                    name="buffer_size_exceed_action"
                    [(ngModel)]="editable.config.buffer_size_exceed_action"
                    value="{{ failActionEnum.value }}"
                >
                    {{ failActionEnum.label }}
                </avi-radio>
            </avi-radio-container>
        </div>
    </form>
</item-full-modal>
