/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { copy } from 'angular';

import {
    isEmpty,
    pick,
    pluck,
} from 'underscore';

import {
    ICustomParams,
    IInfobloxSubnet,
    IIpAddrPrefix,
    IIpamDnsInfobloxProfile,
    IpamDnsType,
} from 'generated-types';

import {
    IpAddrConfigItem,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    IHttpWrapperRequestPromise,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { TStringRow }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { IpamDnsInfobloxProfile } from 'object-types';
import { InfobloxSubnetConfigItem } from './infoblox-subnet.config-item.factory';

const { ...globalL10nKeys } = globalL10n;

type TIpamDnsInfobloxProfilePartial =
    Omit<
    IIpamDnsInfobloxProfile,
    'usable_alloc_subnets' | 'extensible_attributes' | 'ip_address' | 'ip6_address'
    >;

interface IIpamDnsInfobloxProfileConfig extends TIpamDnsInfobloxProfilePartial {
    usable_alloc_subnets?: RepeatedMessageItem<InfobloxSubnetConfigItem>;
    extensible_attributes?: RepeatedMessageItem<MessageItem<ICustomParams>>;
    ip_address?: IpAddrConfigItem;
    ip6_address?: IpAddrConfigItem;
    usableDomainWrappers?: TStringRow[];
}

interface IInfobloxAllocSubnets {
    subnet?: IIpAddrPrefix;
    subnet6?: IIpAddrPrefix;
}

interface IIpamDnsInfobloxSubnets {
    alloc_subnets: IInfobloxAllocSubnets[];
    subnets: IIpAddrPrefix[];
}

export interface IInfobloxUsableSubnetHash {
    v4: IIpAddrPrefix[];
    v6: IIpAddrPrefix[];
}

export interface IInfobloxCredentialsConfig {
    ip_address?: string;
    ip6_address?: string;
    password: string;
    network_view?: string;
    dns_view?: string;
    username: string;
    type: IpamDnsType;
}

/**
 * Verify Infoblox credentials request ID.
 */
const VERIFY_INFOBLOX_LOGIN_CREDENTIALS = 'verify-infoblox-login';

/**
 * Creates a hash of v4 and v6 subnets.
 * @param usableSubnets - List of configured usable subnets.
 */
const createUsableSubnetsHash = (
    usableSubnets: IInfobloxAllocSubnets[] = [],
): IInfobloxUsableSubnetHash => {
    const subnetsHash: IInfobloxUsableSubnetHash = {
        v4: [],
        v6: [],
    };

    usableSubnets.forEach((usableSubnet: IInfobloxAllocSubnets) => {
        const { subnet, subnet6 } = usableSubnet;

        if (subnet) {
            subnetsHash.v4.push(subnet);
        }

        if (subnet6) {
            subnetsHash.v6.push(subnet6);
        }
    });

    return subnetsHash;
};

/**
 * @description IpamDnsInfobloxProfile MessageItem class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamDnsInfobloxProfileConfigItem extends MessageItem<IIpamDnsInfobloxProfileConfig> {
    public static ajsDependencies = [
        'UpdatableItem',
        HTTP_WRAPPER_TOKEN,
        'l10nService',
    ];

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IpamDnsInfobloxProfile,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Adds usable_alloc_subnet entry to Infoblox profiles.
     */
    public addUsableSubnet(): void {
        this.config.usable_alloc_subnets.add();
    }

    /**
     * Removes current item at index from IpamDnsInfobloxProfile::usable_alloc_subnets.
     */
    public removeUsableSubnetByMessageItem(usableSubnet: InfobloxSubnetConfigItem): void {
        this.config.usable_alloc_subnets.removeByMessageItem(usableSubnet);
    }

    /**
     * Getter for usable domain objects.
     */
    public get usableDomains(): TStringRow[] {
        return this.config.usableDomainWrappers;
    }

    /**
     * Adds a new usable_domain entry to Infoblox profiles.
     */
    public addUsableDomain(): void {
        const { usableDomains } = this;

        usableDomains.push({
            value: '',
        });
    }

    /**
     * Removes usable_domains entry to Infoblox profiles.
     */
    public removeUsableDomain(domain: TStringRow): void {
        const { usableDomains } = this;
        const index = usableDomains.indexOf(domain);

        usableDomains.splice(index, 1);
    }

    /**
     * Returns usable subnets of IPAM infoblox profile.
     */
    public get configuredUsableSubnets(): Array<MessageItem<IInfobloxSubnet>> {
        return this.config.usable_alloc_subnets.config;
    }

    /**
     * Clear usable subnets of IPAM infoblox profile.
     */
    public clearUsableSubnetList(): void {
        this.config.usable_alloc_subnets.removeAll();
    }

    /**
     * Returns usable domains of IPAM infoblox DNS profile.
     */
    public getInfobloxDnsProfileUsableDomainList(): string[] | undefined {
        return this.config.usable_domains;
    }

    /**
     * Clear usable domains of IPAM infoblox DNS profile.
     */
    public clearUsableDomainList(): void {
        this.config.usableDomainWrappers = [];
    }

    /**
     * Gets list of infoblox dns profile domains.
     * @param ipamProfileId - If defined, used to fetch the domain list.
     */
    public getDomainList(ipamProfileId?: string): Promise<string[]> {
        this.busy = true;
        this.errors = null;

        const promise: IHttpWrapperRequestPromise = ipamProfileId ?
            this.getDomainListWithId(ipamProfileId) :
            this.getDomainListWithCredentials();

        return promise
            .then(({ data }: ng.IHttpResponse<{ domains: string[] }>) => data.domains || [])
            .catch(({ data }: any) => Promise.reject(data))
            .finally(() => this.busy = false);
    }

    /**
     * Removes element from extensible_attributes array by index.
     */
    public removeCustomParams(index: number): void {
        this.config.extensible_attributes.remove(index);
    }

    /**
     * Adds new CustomParams to extensible_attributes array.
     */
    public addCustomParams(): void {
        this.config.extensible_attributes.add();
    }

    /**
     * Clear password for the profile.
     */
    public clearProfilePassword(): void {
        delete this.config.password;
    }

    /**
     * Get list of infoblox profile subnets.
     * Fetch by uuid when the profile already exists and credentials are not being changed.
     * Fetch by credentials if no uuid or changes of credentials are made.
     * @param ipamProfileId - If defined, used to fetch the domain list.
     */
    public getAvailableSubnets(ipamProfileId?: string): Promise<IInfobloxUsableSubnetHash> {
        this.busy = true;

        const promise = ipamProfileId ?
            this.getAvailableSubnetsWithId(ipamProfileId) :
            this.getAvailableSubnetsWithCredentials();

        return promise
            .then(({ data }: ng.IHttpResponse<IIpamDnsInfobloxSubnets>) => {
                return createUsableSubnetsHash(data.alloc_subnets);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Verify infoblox profile credentials.
     */
    public verifyInfobloxLoginCredentials(config: IInfobloxCredentialsConfig): Promise<any> {
        this.busy = true;

        if (!config.network_view) {
            config.network_view =
                this.l10nService.getMessage(globalL10nKeys.defaultLabel).toLowerCase();
        }

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url: '/api/ipamdnsproviderprofilelogin',
            requestId: VERIFY_INFOBLOX_LOGIN_CREDENTIALS,
            params: config,
        }).then(({ data }) => data)
            .catch(({ data }) => Promise.reject(data.error))
            .finally(() => this.busy = false);
    }

    /**
     * Cancels verify crdentials request.
     */
    public cancelInfobloxVerifyCredentials(): void {
        this.httpWrapper.cancelRequest(VERIFY_INFOBLOX_LOGIN_CREDENTIALS);
    }

    /**
     * Sets login credentials.
     */
    public setInfobloxLoginCredentials(config: IInfobloxCredentialsConfig): void {
        const {
            ip_address: ipAddr,
            ip6_address: ip6Addr,
            username,
            network_view: networkView,
            dns_view: dnsView,
            password,
        } = config;

        this.config.ip_address.address = ipAddr;
        this.config.ip6_address.address = ip6Addr;
        this.config.username = username;
        this.config.password = password;
        this.config.network_view = networkView;
        this.config.dns_view = dnsView;
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['ip_address', 'ip6_address'];
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { usable_domains: usableDomains } = this.config;

        if (!usableDomains) {
            this.config.usableDomainWrappers = [];
        } else {
            this.config.usableDomainWrappers = usableDomains.map(usableDomain => {
                return {
                    value: usableDomain,
                };
            });
        }
    }

    /**
     * @override
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { usableDomainWrappers } = this.config;

        if (!isEmpty(usableDomainWrappers)) {
            this.config.usable_domains = pluck(usableDomainWrappers, 'value');
        } else {
            delete this.config.usable_domains;
        }

        delete this.config.usableDomainWrappers;
    }

    /**
     * Return list of credential property names of infoblox profile.
     * @param type - 'IPAMDNS_TYPE_INFOBLOX' or 'IPAMDNS_TYPE_INFOBLOX_DNS'
     */
    private getInfobloxProfileFingerPrintPropNames(type: string): string[] {
        const propNames = ['username', 'password'];

        if (type === IpamDnsType.IPAMDNS_TYPE_INFOBLOX) {
            propNames.push('network_view');
        } else if (type === IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS) {
            propNames.push('dns_view');
        }

        if (this.config.ip_address.config.addr) {
            propNames.push('ip_address');
        }

        if (this.config.ip6_address.config.addr) {
            propNames.push('ip6_address');
        }

        return propNames;
    }

    /**
     * Makes request for the network list with uuid.
     * @param ipamProfileId - IPAM Profile UUID used to fetch the domain list.
     */
    private getAvailableSubnetsWithId(ipamProfileId: string): IHttpWrapperRequestPromise {
        const api = '/api/ipamdnsproviderprofilenetworklist';

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url: `${api}?ipamdnsprovider_uuid=${ipamProfileId}&provider=true`,
        });
    }

    /**
     * Makes request for the network list with credentials.
     */
    private getAvailableSubnetsWithCredentials(): IHttpWrapperRequestPromise {
        const api = '/api/ipamdnsproviderprofilenetworklist';
        const url = this.getUrl(api, IpamDnsType.IPAMDNS_TYPE_INFOBLOX);

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url,
        });
    }

    /**
     * Makes request for the domain list with uuid.
     * @param ipamProfileId - IPAM Profile UUID used to fetch the domain list.
     */
    private getDomainListWithId(ipamProfileId: string): IHttpWrapperRequestPromise {
        const api = '/api/ipamdnsproviderprofiledomainlist';

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url: `${api}?ipamdnsprovider_uuid=${ipamProfileId}&provider=true`,
        });
    }

    /**
     * Makes request for the domain list with credentials.
     */
    private getDomainListWithCredentials(): IHttpWrapperRequestPromise {
        const api = '/api/ipamdnsproviderprofiledomainlist';
        const url = this.getUrl(api, IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS);

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url,
        });
    }

    /**
     * Generate infoblox profile api call url based on api and type.
     * @param api - The particular api/path that is used to make the call.
     * @param type - Type of infoblox profile.
     *      Could be IPAMDNS_TYPE_INFOBLOX or IPAMDNS_TYPE_INFOBLOX_DNS.
     */
    private getUrl(api: string, type: string): string {
        const paramHash = this.getParams(type);
        const UpdatableItem = this.getAjsDependency_('UpdatableItem');

        return UpdatableItem.getUrl(api, paramHash);
    }

    /**
     * Get infoblox profile param hash.
     */
    private getParams(type: string): Record<string, any> {
        const paramHash = copy(this.config);
        const paramsToKeep = this.getInfobloxProfileFingerPrintPropNames(type);

        const filteredParamHash: Record<string, any> = pick(paramHash, paramsToKeep);

        if (filteredParamHash.ip_address) {
            filteredParamHash.ip_address = paramHash.ip_address.config.addr;
        }

        if (filteredParamHash.ip6_address) {
            filteredParamHash.ip6_address = paramHash.ip6_address.config.addr;
        }

        filteredParamHash.type = type;

        return filteredParamHash;
    }
}
