/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Pipe, PipeTransform } from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';

/**
 * Pipe to get the state name from State Object.
 * @author Aravindh Nagarajan
 */
@Pipe({
    name: 'stateName',
})
export class StateNamePipe implements PipeTransform {
    public transform(state: IAppState): string {
        const {
            abstract,
            defaultChild,
            name,
        } = state;

        if (abstract && defaultChild) {
            return `${name}.${defaultChild}`;
        } else {
            return name;
        }
    }
}
