/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-rating-preview';
const prefix = `${moduleName}.${componentName}`;

export const sslRatingHeader = `${prefix}.sslRatingHeader`;
export const securityScoreLabel = `${prefix}.securityScoreLabel`;
export const performanceRatingLabel = `${prefix}.performanceRatingLabel`;
export const compatibilityRatingLabel = `${prefix}.compatibilityRatingLabel`;

export const ENGLISH = {
    [sslRatingHeader]: 'SSL Rating',
    [securityScoreLabel]: 'Security Score',
    [performanceRatingLabel]: 'Performance Rating',
    [compatibilityRatingLabel]: 'Compatibility Rating',
};
