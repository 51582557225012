/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'nat-policy';
const componentName = 'nat-policy-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleRulesCount = `${prefix}.columnTitleRulesCount`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleRulesCount]: '# Rules',
    [headerLabel]: 'NAT Policy',
};
