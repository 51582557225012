/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ProtocolMatchConfigItem } from 'ajs/modules/match';
import {
    L4RuleProtocolMatchConfigItem,
} from 'ajs/modules/l4-policy/factories/matches/l4-rule-protocol-match.config-item.factory';

import { L4RuleMatchTarget } from 'object-types';

import './protocol-type-match.component.less';

type ProtocolEnumType = 'HTTPProtocol' | 'Protocol';

/**
 * @description
 *     Match component for the ProtocolMatch and L4RuleProtocolMatch message.
 * @author Sarthak Kapoor, Zhiqian Liu
 */
@Component({
    selector: 'protocol-type-match',
    templateUrl: './protocol-type-match.component.html',
})
export class ProtocolTypeMatchComponent implements OnInit {
    /**
     * Protocol message item instance.
     */
    @Input()
    public editable: ProtocolMatchConfigItem | L4RuleProtocolMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * The protocol property field name under the match.
     * By default set to "protocols" of ProtocolMatch.
     */
    public ngModelFieldName = 'protocols';

    /**
     * Type for the protocol enum decided by objectType. By default set to HTTPProtocol.
     */
    public enumType: ProtocolEnumType = 'HTTPProtocol';

    /** @override */
    public ngOnInit(): void {
        if (this.objectType === L4RuleMatchTarget) {
            this.ngModelFieldName = 'protocol';
            this.enumType = 'Protocol';
        }
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
