/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { isUndefined } from 'underscore';

enum healthScoreThreshold {
    HIGH = 85,
    MEDIUM = 65,
    LOW = 0,
}

enum healthScoreClassName {
    HIGH = 'high',
    LOW = 'low',
    MEDIUM = 'medium',
    DISABLED = 'disabled',
    DOWN = 'down',
    NO_DATA = '',
}

/**
 * @description Pipe to get class name based on healthScore.
 *
 * @author Aravindh Nagarajan
 */
@Pipe({
    name: 'healthScoreClassName',
})
export class HealthScoreClassNamePipe implements PipeTransform {
    /**
     * Return the health score class based on the score and state provided.
     */
    // eslint-disable-next-line class-methods-use-this
    public transform(score: number): healthScoreClassName {
        let hsClassName = healthScoreClassName.NO_DATA;

        if (!isUndefined(score)) {
            if (score >= healthScoreThreshold.HIGH) {
                hsClassName = healthScoreClassName.HIGH;
            } else if (score >= healthScoreThreshold.MEDIUM) {
                hsClassName = healthScoreClassName.MEDIUM;
            } else if (score >= healthScoreThreshold.LOW) {
                hsClassName = healthScoreClassName.LOW;
            }
        }

        return hsClassName;
    }
}
