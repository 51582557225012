/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';
import { IIpInterface } from 'generated-types';
import * as globalL10n from 'global-l10n';
import type {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import {
    InUseInterfaceListExpandedContentService,
} from './in-use-interface-list-expanded-content.service';
import * as l10n from './in-use-interface-list-expanded-content.l10n';
import './in-use-interface-list-expanded-content.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Expander content for the Service Engine In-Use Interface List dialog. Displays Floating IP
 *     addresses for a particular interface.
 * @author alextsg
 */
@Component({
    selector: 'in-use-interface-list-expanded-content',
    templateUrl: './in-use-interface-list-expanded-content.component.html',
    providers: [
        InUseInterfaceListExpandedContentService,
    ],
})
export class InUseInterfaceListExpandedContentComponent implements OnInit, OnDestroy {
    /**
     * Service Engine UUID.
     */
    @Input()
    public seId: string;

    /**
     * Name of the interface to query Floating IP addresses for.
     */
    @Input()
    public interfaceName: string;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public ipAddressesGridConfig: IAviDataGridConfig;

    /**
     * List of IP interface objects associated with the service engine and interface.
     */
    public ipInfo: IIpInterface[] = [];

    public loading = false;

    public errors = '';

    /**
     * Observable used to cancel ongoing HTTP requests when this component is destroyed.
     */
    private componentDestroyed$ = new Subject<void>();

    constructor(
        private readonly l10nService: L10nService,
        private readonly componentService: InUseInterfaceListExpandedContentService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.ipAddressesGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.ipAddressLabel),
                    id: 'ip-address',
                    transform: (row: IIpInterface) => row.ip_addr,
                },
            ],
            layout: {
                hideCheckboxes: true,
            },
        };

        this.loading = true;

        this.componentService.fetchAddresses(this.seId, this.interfaceName)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: ipInfo => {
                    this.ipInfo = ipInfo;
                    this.loading = false;
                },
                error: errors => {
                    this.errors = errors.error;
                    this.loading = false;
                },
            });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
