/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import { duration } from 'moment';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './vs-logs-timeframes.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Possible timeframe options for the logs page.
 * Values are keys for the timeframe service.
 */
export enum LogsTimeframeOptions {
    FIFTEEN_MINUTES = '15m',
    ONE_HOUR = '1h',
    THREE_HOURS = '3h',
    SIX_HOURS = '6h',
    ONE_DAY = '1d',
    ONE_WEEK = '1w',
    ONE_MONTH = '1m',
    CUSTOM = 'custom',
}

interface ILogTimeframeValue {
    label: string;
    range: number;
    step: number;
    hiddenInLayout?: boolean;
}

/**
 * @description
 *  Map of VS timeframe options to label, range, and step properties.
 *  Angular version of ajs 'log-timeframes.factory.ts'; can eventually
 *  be used for Timeframe Selector component once it's upgraded.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Injectable()
export class VsLogsTimeframesService {
    private readonly timeframeMap = {
        [LogsTimeframeOptions.FIFTEEN_MINUTES]: {
            label: this.l10nService.getMessage(l10nKeys.past15minutesLabel),
            range: duration(15, 'm').asSeconds(),
            step: 5,
        },
        [LogsTimeframeOptions.ONE_HOUR]: {
            label: this.l10nService.getMessage(l10nKeys.pastHourLabel),
            range: duration(1, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        [LogsTimeframeOptions.THREE_HOURS]: {
            label: this.l10nService.getMessage(l10nKeys.past3hoursLabel),
            range: duration(3, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        [LogsTimeframeOptions.SIX_HOURS]: {
            label: this.l10nService.getMessage(l10nKeys.past6hoursLabel),
            range: duration(6, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        [LogsTimeframeOptions.ONE_DAY]: {
            label: this.l10nService.getMessage(l10nKeys.pastDayLabel),
            range: duration(1, 'd').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        [LogsTimeframeOptions.ONE_WEEK]: {
            label: this.l10nService.getMessage(l10nKeys.pastWeekLabel),
            range: duration(1, 'w').asSeconds(),
            step: duration(1, 'h').asSeconds(),
        },
        [LogsTimeframeOptions.ONE_MONTH]: {
            label: this.l10nService.getMessage(l10nKeys.pastMonthLabel),
            range: duration(1, 'M').asSeconds(),
            step: duration(1, 'days').asSeconds(),
        },
        [LogsTimeframeOptions.CUSTOM]: {
            label: this.l10nService.getMessage(globalL10nKeys.customLabel),
            range: 'custom',
            hiddenInLayout: true,
            step: 300,
        },
    };

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns timeframe object with useful properties for a given timeframe.
     */
    public getTimeframeProps(timeframeKey: string): ILogTimeframeValue {
        return this.timeframeMap[timeframeKey];
    }
}
