/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'unit-tree';
const prefix = `${moduleName}.${componentName}`;

export const primarySELabel = `${prefix}.primarySELabel`;

export const ENGLISH = {
    [primarySELabel]: 'Primary SE',
};
