/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Inject,
    Injectable,
    OnDestroy,
} from '@angular/core';

import { IHttpPromise } from 'angular';
import { IBGPPeersState } from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

/**
 * Type of Http wrapper.
 */
type THttpWrapper = typeof HttpWrapper;

/**
 * Fetch service engine bgp peers state request id.
 */
const FETCH_BGP_PEERS_STATE_REQUEST_ID = 'fetch-bgp-peers-state-request-id';

/**
 * Base url to fetch bgp peers state of specific service engine.
 */
const BGP_PEERS_STATE_BASE_URL = '/api/serviceengine';

/**
 * @description
 *      Bgp Peers state service.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class BgpPeersStateService implements OnDestroy {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
    @Inject(HttpWrapper)
    HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Method to fetch bgp peers state of specific service engine.
     */
    public fetchBgpPeersState(seId: string): IHttpPromise<IBGPPeersState[]> {
        const requestConfig = {
            url: `${BGP_PEERS_STATE_BASE_URL}/${seId}/bgp/peer_state`,
            method: HttpMethod.GET,
            requestId: FETCH_BGP_PEERS_STATE_REQUEST_ID,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Cancel the pending request.
     * @override
     */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelRequest(FETCH_BGP_PEERS_STATE_REQUEST_ID);
    }
}
