/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import { appStates } from 'ajs/js/constants/app-config/app-state.constants';

/**
 * Branding component.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'branding',
    templateUrl: './branding.component.html',
})
export class BrandingComponent {
    /**
     * Store app states to be used in template.
     */
    public defaultState = appStates.DEFAULT_STATE;
}
