<div
    class="gslb-subdomains-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="subdomainsGridConfig"
        [rows]="subdomains?.config"
        gridTitle="{{ l10nKeys.subdomainsHeaderLabel | vtranslate : subdomains.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addSubdomain()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #subdomainTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    clrInput
                    [(ngModel)]="row.config.domain_name"
                    name="{{ 'subdomain-' + index }}"
                    placeholder="{{ l10nKeys.subdomainPlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
