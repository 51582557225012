/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import { VirtualServiceCollection } from 'ajs/modules/vs';

/**
 * Type of GSLBVSCollection.
 */
export type TGSLBVSCollection = typeof GSLBVSCollection;

interface IGslbVsCollectionArgs {
    gslbSiteId?: string;
    gslbTenant?: string;
    isLocalVSCollection?: boolean,
    limit?: number;
    params?: object;
}

/**
 * @description
 *
 *     Syntax sugar to set GSLB specific headers through constructor params.
 *     Loads VSes of local as well as remote controllers.
 *
 * @author Ram Pal
 */
export class GSLBVSCollection extends VirtualServiceCollection {
    private readonly isLocalVSCollection: boolean;

    constructor(args: IGslbVsCollectionArgs = {}) {
        const extendedArgs = {
            isStatic: true,
            objectName: 'virtualservice',
            ...args,
        };

        super(extendedArgs);

        const {
            gslbSiteId,
            gslbTenant,
            isLocalVSCollection,
        } = args;

        this.isLocalVSCollection = Boolean(isLocalVSCollection);

        const headerParams = this.getParams('headers_') || {};

        if (gslbSiteId) {
            headerParams['X-Avi-Internal-GSLB'] = gslbSiteId;
        }

        if (gslbTenant) {
            headerParams['X-Avi-Tenant'] = gslbTenant;
        }

        this.setParams({ headers_: headerParams });
    }

    /** @override */
    public isCreatable(): boolean {
        if (this.isLocalVSCollection) {
            return true;
        }

        return false;
    }
}
