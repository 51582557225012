<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid && !!(editable.matchCount)"
    class="vh-match-rule-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        [uniqueValue]="ruleNames"
                        [currentIndex]="ruleIndex"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.matchLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.matchLabel | vtranslate }}</h3>
            <match-adder
                *ngIf="editable.config.matches"
                [match]="editable.config.matches"
                [matchOptions]="matchOptions"
            ></match-adder>
        </full-modal-tab-section>
    </form>
</full-modal-config>
