/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './pool-list.less';
import * as l10n from './PoolListController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('PoolListController', [
'$scope', 'CRUDGridConfig', 'PoolCollection', '$templateCache', 'Auth',
'getGridMetricFieldConfig', 'l10nService',
function($scope, CRUDGridConfig, PoolCollection, $templateCache, Auth,
getGridMetricFieldConfig, l10nService) {
    l10nService.registerSourceBundles(dictionary);

    const gridMetricFields = [
        {
            require: 'l7_server.avg_complete_responses',
            title: 'RPS',
            fullTitle: 'Requests per second',
            visibility: 'd',
        }, {
            require: 'l4_server.max_open_conns',
            visibility: 'd',
        }, {
            require: 'l4_server.avg_bandwidth',
            visibility: 'd',
        },
        'l4_server.avg_total_rtt',
        'l7_server.avg_error_responses',
        'l7_server.pct_response_errors',
    ];

    const gridConfig = {
        collection: new PoolCollection({
            objectName: 'pool-inventory',
        }),
    };

    gridConfig.layout = {
        includeTimeframeSelector: true,
        includeMetricsValueSelector: true,
    };

    const gridFields = [{
        name: 'name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        template: require('./pool-grid-name-column.partial.html'),
        sortBy: 'name',
        visibility: 'm',
    }, {
        // Style based on health name, don't remove
        require: 'health,runtime',
        name: 'health',
        title: l10nService.getMessage(l10nKeys.columnTitleHealth),
        template: '<avi-healthscore item="row" stop-async-on-hide="true"></avi-healthscore>',
        sortBy: 'health_score',
        visibility: 'm',
    }, {
        name: 'virtualservice',
        title: l10nService.getMessage(l10nKeys.columnTitleVirtualService),
        template: require('./pool-grid-virtualservice-column.partial.html'),
        visibility: 'd',
    }, {
        name: 'servers',
        title: l10nService.getMessage(l10nKeys.columnTitleServersUpTotal),
        template: require('./pool-grid-servers-column.partial.html'),
        visibility: 'd',
    }, {
        name: 'poolgroups',
        title: l10nService.getMessage(l10nKeys.columnTitlePoolGroups),
        template: '{{ row.getPoolGroupNames().join(", ") }}',
    }, {
        name: 'cloud',
        title: l10nService.getMessage(l10nKeys.columnTitleCloud),
        template: '{{ row.getCloudRef() | name }}',
        visibility: 'd',
    }, {
        require: 'config',
        name: 'vrf_context',
        title: l10nService.getMessage(l10nKeys.columnTitleVrfContext),
        template: '{{ row.getVRFContextRef().name() || "None" }}',
        visibility: 'optional',
    }, {
        name: 'num_vs',
        title: l10nService.getMessage(l10nKeys.columnTitleVirtualServices),
        template: '{{ row.getVSRefs().length }}',
        visibility: 'optional',
    }];

    gridFields.push(
        ...gridMetricFields.map(seriesName => getGridMetricFieldConfig(seriesName)),
    );

    // TODO: Alerts column will not show if PERMISSION_ALERT changes from NO_ACCESS or
    // WRITE_ACCESS to READ_ACCESS unless page is reloaded.
    if (Auth.isPrivilegeAllowed('PERMISSION_ALERT')) {
        gridFields.push({
            require: 'alert',
            name: 'alerts',
            title: l10nService.getMessage(l10nKeys.gridTitleAlerts),
            template: '<item-alert-bell item="row"></item-alert-bell>',
        });
    }

    gridConfig.fields = gridFields;

    const { objectName } = gridConfig.collection;

    gridConfig.id = `${objectName}-list-page`;

    $scope.l10nKeys = l10nKeys;

    $scope.gridConfig = CRUDGridConfig(gridConfig);

    $scope.gridConfig.multipleactions.push({
        title: l10nService.getMessage(l10nKeys.gridTitleEnable),
        disabled:
            pools => _.all(pools, pool => pool.isEnabled() || !pool.isEditable()),
        do: pools => {
            pools.forEach(pool => pool.setEnabledState(true));

            return true;
        },
    }, {
        title: l10nService.getMessage(l10nKeys.gridTitleDisable),
        disabled:
            pools => _.all(pools, pool => !pool.isEnabled() || !pool.isEditable()),
        do: pools => {
            pools.forEach(pool => pool.setEnabledState(false));

            return true;
        },
    });

    $scope.$on('$destroy', () => {
        gridConfig.collection.destroy();
    });
}]);
