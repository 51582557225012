/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *     ConfigItem class. Intended to mirror protobuf objects (ex. Vip, DnsInfo) used in
 *     configuration. The idea is to add some separation in managing data when a top-level object,
 *     like VirtualService, contains many sub-objects like Vip and DnsInfo, such that methods
 *     dealing with those sub-objects can be defined on their ConfigItem classes instead of having
 *     everything on the top-level object class.
 *
 *     ConfigItems have methods similar to Item methods in order to keep the data flow consistent.
 *
 * @author Ram Pal
 */
// FIXME if configItem is created before defaultValues were loaded _defaultConfig will remain empty
// applies to subclasses as well

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Base } from 'ajs/modules/data-model/factories/base.factory';

export class ConfigItem extends Base {
    constructor(args = {}) {
        super(args);

        const { data, type, defaultConfig, parentId } = args;

        this.type = type || this.type || '';

        const defaultValues = this.getAjsDependency_('defaultValues');

        this._defaultConfig = defaultConfig ||
                this.defaultPath && defaultValues.getDefaultItemConfig(this.defaultPath) ||
                defaultValues.getDefaultItemConfig(this.type.toLowerCase()) || {};

        this._parentId = parentId;

        this.windowElement_ = args.windowElement || this.windowElement_ || '';

        this.data = {
            config: angular.extend(
                {}, angular.copy(this._defaultConfig), data && data.config,
            ),
        };

        this.busy = false;
        this.errors = null;
    }

    /**
     * Returns the DataConfig#config object.
     * @return {Object}
     */
    getConfig() {
        return this.data && this.data.config;
    }

    /**
     * Updates the ConfigItem data.
     * @param {Object} newData - ConfigItem data object.
     */
    updateConfigItemData(newData) {
        ConfigItem._recursiveUpdateData(newData, this.data);
    }

    /**
     * Sets fields before user input.
     * @returns {DataConfig#config}
     */
    beforeEdit() {
        return this.getConfig();
    }

    /**
     * Sets fields after user input and before the save request.
     * @returns {DataConfig#config}
     */
    dataToSave() {
        return angular.copy(this.getConfig());
    }

    /**
     * Returns a ConfigItem name.
     * @returns {string} - Name to get. Empty string when not ready.
     */
    getName() {
        const config = this.getConfig();

        return config && (config.name || config.url && config.url.name()) || '';
    }

    /**
     * Returns a new instance with a copy of the config.
     */
    getCopy() {
        const configCopy = angular.copy(this.getConfig());

        return new this.constructor({ data: { config: configCopy } });
    }

    /**
     * Recursively goes through the config and calls getConfig() on every instance of
     * ConfigItem to return a flattened config object without any instances. This gets rid
     * of all nested <instance>.data.config structures.
     * @return {Object} config object
     */
    getFlattenedConfig() {
        return ConfigItem._recursiveCopyConfig(this.getConfig());
    }

    /**
     * Goes through each property in the config to recursively create a copy of the config
     * object, getting the config of ConfigItem instances.
     * @static
     * @param {Object} config
     */
    static _recursiveCopyConfig(config) {
        if (config instanceof ConfigItem) {
            return ConfigItem._recursiveCopyConfig(config.getConfig());
        } else if (angular.isArray(config)) {
            return config.map(value => ConfigItem._recursiveCopyConfig(value));
        } else if (angular.isObject(config)) {
            const flattenedConfig = {};

            _.each(config, (value, key) => {
                flattenedConfig[key] = ConfigItem._recursiveCopyConfig(value);
            });

            return flattenedConfig;
        } else {
            return config;
        }
    }

    /**
     * Goes through each value to update data.
     * If the value is a ConfigItem, call updateConfigItemData on the value with the new
     * data.
     * If the value is a regular object or array, traverse and update every property.
     * Otherwise, just overwrite the old property with the new one.
     * @static
     * @param {any} newData
     * @param {any} oldData
     */
    static _recursiveUpdateData(newData, oldData) {
        _.each(newData, (value, key) => {
            const oldValue = oldData[key];

            const ConfigItem = this.getAjsDependency_('ConfigItem');

            if (oldValue instanceof ConfigItem) {
                value.updateConfigItemData({ config: value });
            } else if (angular.isObject(oldValue)) {
                ConfigItem._recursiveUpdateData(value, oldData[key]);
            } else {
                oldData[key] = value;
            }
        });
    }

    /**
     * Returns a config object setting all empty arrays or strings to undefined so that
     * they are not included in the payload.
     * @static
     * @param {Object} config
     * @return {Object}
     */
    static _removeEmptyRepeated(config) {
        return _.each(config, (value, key) => {
            if ((angular.isArray(value) || angular.isString(value)) && !value.length) {
                config[key] = undefined;
            }
        });
    }
}

ConfigItem.ajsDependencies = [
    'defaultValues',
];

initAjsDependency(
    angular.module('avi/dataModel'),
    'factory',
    'ConfigItem',
    ConfigItem,
);
