/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-health-monitors-grid';
const prefix = `${moduleName}.${componentName}`;

export const healthMonitorGridLabel = `${prefix}.healthMonitorGridLabel`;
export const healthMonitorPlaceholder = `${prefix}.healthMonitorPlaceholder`;

export const ENGLISH = {
    [healthMonitorGridLabel]: 'Health Monitor ({0})',
    [healthMonitorPlaceholder]: 'Select Health Monitor',
};
