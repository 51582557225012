/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @WafModule */

import {
    IWafExclusionType,
    MatchCase,
    StringOperation,
} from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export const WAF_EXCLUSION_TYPE_CONFIG_ITEM_TOKEN = 'WafExclusionTypeConfigItem';

export class WafExclusionTypeConfigItem extends MessageItem<IWafExclusionType> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafExclusionType',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /** @override */
    public isValid(): boolean {
        const {
            match_op: matchOp,
            match_case: matchCase,
        } = this.config;

        return Boolean(matchOp || matchCase);
    }

    /**
     * Getter converting the match_case enum value to a boolean.
     */
    public get caseSensitive(): boolean {
        return this.config.match_case === MatchCase.SENSITIVE;
    }

    /**
     * Setter converting a case sensitive boolean value to the enum value.
     */
    public set caseSensitive(sensitive) {
        this.config.match_case = sensitive ? MatchCase.SENSITIVE : MatchCase.INSENSITIVE;
    }

    /**
     * Getter converting the match_op enum value to a boolean.
     */
    public get regexMatch(): boolean {
        return this.config.match_op === StringOperation.REGEX_MATCH;
    }

    /**
     * Setter converting a regex match boolean value to the enum value.
     */
    public set regexMatch(regexMatch) {
        this.config.match_op = regexMatch ? StringOperation.REGEX_MATCH : StringOperation.EQUALS;
    }

    /**
     * Reset config values to default values.
     */
    public resetDefaults(): void {
        ({
            match_case: this.config.match_case,
            match_op: this.config.match_op,
        } = this.getDefaultConfig());
    }
}
