<div class="vs-log-cinematic-end-to-end-knot">
    <div
        vsLogCinematicFieldTitle
        [withMarginTop]="false"
        [ngClass]="topHeaderColor ? 'vs-log-cinematic-end-to-end-knot__header--' + topHeaderColor : 'vs-log-cinematic-end-to-end-knot__header'"
    >
        {{ headerText }}
    </div>
    <cds-icon
        shape="{{ iconShape }}"
        size="35"
        status="{{ iconStatus }}"
        badge="{{ iconBadge }}"
    ></cds-icon>
</div>
