/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import './full-modal-tab-section.component.less';

/**
 * @description
 * Component used to wrap a section in the full modal to create a tab for navigation. Adding tabs
 * dynamically is not supported - tabs are added when the full-modal is initialized.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-tab-section',
    template: '<ng-content></ng-content>',
})
export class FullModalTabSectionComponent implements OnInit {
    /**
     * Text to show in the header, ex. the title of the modal.
     */
    @Input()
    public tabTitle = '';

    /**
     * ID of the DOM element, created by replacing special characters and spaces of the tabTitle.
     */
    public tabId: string;

    constructor(
        public elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    /** @override */
    public ngOnInit(): void {
        const { nativeElement } = this.elementRef;

        this.tabId = this.tabTitle.replace(/[^\w]/gi, '-').toLowerCase();
        this.renderer.addClass(nativeElement, 'full-modal-tab-section');
        this.renderer.setAttribute(nativeElement, 'id', this.tabId);
    }
}
