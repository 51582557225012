<div class="vs-log-cinematic-section-wrapper">
    <div class="vs-log-cinematic-section-wrapper__header">
        <cds-icon
            *ngIf="icon"
            [shape]="icon"
            size="24"
            status="success"
            class="vs-log-cinematic-section-wrapper__header-icon"
        ></cds-icon>
        <h4>{{ header }}</h4>
    </div>
    <div class="vs-log-cinematic-section-wrapper__body">
        <ng-content></ng-content>
    </div>
</div>
