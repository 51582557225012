/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PersistenceProfileModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { PERSISTENCE_PROFILE_ITEM_TOKEN } from '../persistence-profile.tokens';

/**
 * Type of PersistenceProfileCollection.
 */
export type TPersistenceProfileCollection = typeof PersistenceProfileCollection;

/**
 * @description Persistence profile collection.
 * @author Rohit Gaikwad
 */
export class PersistenceProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'applicationpersistenceprofile',
            objectType: 'ApplicationPersistenceProfile',
            permissionName: AviPermissionResource.PERMISSION_APPLICATIONPERSISTENCEPROFILE,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PERSISTENCE_PROFILE_ITEM_TOKEN);
    }
}

PersistenceProfileCollection.ajsDependencies = [
    PERSISTENCE_PROFILE_ITEM_TOKEN,
];
