/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-certificate-authority-grid';
const prefix = `${moduleName}.${componentName}`;

export const certAuthorityAddButtonLabel = `${prefix}.certAuthorityAddButtonLabel`;
export const certAuthorityRemoveButtonLabel = `${prefix}.certAuthorityRemoveButtonLabel`;
export const certAuthColTitleName = `${prefix}.certAuthColTitleName`;
export const certAuthColTitleIssuedBy = `${prefix}.certAuthColTitleIssuedBy`;
export const certAuthColTitleExpirationDate = `${prefix}.certAuthColTitleExpirationDate`;

export const ENGLISH = {
    [certAuthorityAddButtonLabel]: 'Add',
    [certAuthorityRemoveButtonLabel]: 'Remove',
    [certAuthColTitleName]: 'Name',
    [certAuthColTitleIssuedBy]: 'Issued By',
    [certAuthColTitleExpirationDate]: 'Expiration Date',
};
