/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const selectPlaceholder = `${prefix}.selectPlaceholder`;
export const clearAllLabel = `${prefix}.clearAllLabel`;

export const ENGLISH = {
    [selectPlaceholder]: 'Select',
    [clearAllLabel]: 'Clear all',
};
