/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'cportal-info-preview';
const prefix = `${moduleName}.${componentName}`;

export const portalUrlLabel = `${prefix}.portalUrlLabel`;
export const organizationIdLabel = `${prefix}.organizationIdLabel`;

export const ENGLISH = {
    [portalUrlLabel]: 'Cloud Services Portal URL',
    [organizationIdLabel]: 'Organization ID',
};
