/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { ANALYTICS_PROFILE_ITEM_TOKEN } from '../analytics.tokens';

/**
 * @description Analytics profile collection class.
 *
 * @author Ratan Kumar
 */
export class AnalyticsProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'analyticsprofile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_ANALYTICSPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ANALYTICS_PROFILE_ITEM_TOKEN);
    }
}

AnalyticsProfileCollection.ajsDependencies = [
    ANALYTICS_PROFILE_ITEM_TOKEN,
];
