/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cluster';
const componentName = 'cluster-state-info';
const prefix = `${moduleName}.${componentName}`;

export const clusterIpv4Label = `${prefix}.clusterIpv4Label`;
export const clusterIpv6Label = `${prefix}.clusterIpv6Label`;
export const clusterIpLabel = `${prefix}.clusterIpLabel`;

export const ENGLISH = {
    [clusterIpv4Label]: 'Controller Cluster IPv4',
    [clusterIpv6Label]: 'Controller Cluster IPv6',
    [clusterIpLabel]: 'Controller Cluster IP',
};
