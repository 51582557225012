/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-pool-members-grid';
const prefix = `${moduleName}.${componentName}`;

export const poolMembersGridLabel = `${prefix}.poolMembersGridLabel`;
export const publicIpLabel = `${prefix}.publicIpLabel`;
export const privateAddressColumnHeader = `${prefix}.privateAddressColumnHeader`;

export const ENGLISH = {
    [poolMembersGridLabel]: 'Members ({0})',
    [publicIpLabel]: 'Public IP',
    [privateAddressColumnHeader]: 'Private Address',
};
