/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { SNMP_TRAP_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/alert/alert.tokens';
import * as l10n from './snmp.controller.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export function snmpController(
    $scope,
    CRUDGridConfig,
    Collection,
    Regex,
    AviModal,
    l10nService,
    SnmpTrapProfileCollection,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    /**
     * Get keys from source bundles for template usage
     */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = new CRUDGridConfig();

    /**
     * Collection is doing all the job related to communication to server and data organization
     * @type {Collection}
     */
    $scope.collection = new SnmpTrapProfileCollection();

    /**
     * Configures CollectionGrid directive, works in conjunction with Collection instance
     * instantiated above
     */
    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [
        {
            name: 'data.config.name',
            title: l10nService.getMessage(l10nKeys.nameInputLabel),
            sortBy: 'name',
            visibility: 'm',
        }, {
            name: 'trap_servers',
            title: l10nService.getMessage(l10nKeys.serverLabel),
            template:
            // eslint-disable-next-line max-len
            '<div class="mb5" ng-repeat="server in row.data.config.trap_servers.config track by $index">' +
                '<span title="{{ config.joinTraps(row) }}">' +
                    '{{ server.config.ip_addr.config.addr}}{{$last ? "" : ","}}' +
                '</span></div>',
            visibility: 'm',
        },
    ];

    $scope.gridConfig.singleactions.unshift({
        title: l10nService.getMessage(l10nKeys.validateSettingsLabel),
        class: 'icon-lostlog',
        do(row) {
            AviModal.open('adm-email-validate', {
                uuid: row.data.config.uuid,
                type: 'snmp',
            });
        },
    });

    $scope.Regex = Regex;

    /**
     * Joins trap community and IPs into comma-separated string.
     * @param  {Object} row - grid row containing server information.
     * @return {string}     string of comma-separated <community>:<server>
     */
    $scope.gridConfig.joinTraps = function(row) {
        const output = row.data.config.trap_servers.config.map(function(server) {
            return `${server.config.ip_addr.config.addr} - ${server.config.community}`;
        });

        return output.join(', ');
    };
}

snmpController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'Collection',
    'Regex',
    'AviModal',
    'l10nService',
    SNMP_TRAP_PROFILE_COLLECTION_TOKEN,
];
