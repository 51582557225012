<div
    provideParentForm
    class="avi-repeated-dropdowns-grid"
>
    <avi-data-grid
        [config]="repeatedDropdownsGridConfig"
        [rows]="dropdownRows"
        [gridTitle]="gridTitle"
    >
        <avi-data-grid_actions>
            <cds-button
                (click)="addDropdown()"
                [disabled]="hasMaxRows"
                size="sm"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #dropdownTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                placeholder="{{ placeholder | vtranslate }}"
                name="{{ name + '-' + index }}"
                [options]="options"
                [multiple]="multiple"
                [hideSearch]="hideSearch"
                [maxSelectableOptions]="maxSelectableOptions"
                [busy]="busy"
                [hiddenValues]="hiddenValues"
                [helperText]="helperText"
                [readonly]="readonly"
                [(ngModel)]="row.value"
                (ngModelChange)="handleRowModelChange()"
                required
            ></avi-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
