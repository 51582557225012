/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { IService } from 'generated-types';

import {
    any,
    every,
    isNaN,
    range,
} from 'underscore';

const portListSeparator = ',';
const portRangeSeparator = '-';

export const PORT_VALIDATOR_KEY = 'portValid';

/**
 * Helper function to check if the port is in given limits.
 */
function isPortInGivenServiceLimits(servicePorts: IService[], port: number): boolean {
    return any(servicePorts, servicePort => {
        return port >= servicePort.port && port <= servicePort.port_range_end;
    });
}

/**
 * Helper function to check NaN values in the number list.
 */
function isValidNumberList(list: number[]): boolean {
    return every(list, item => !isNaN(item));
}

/**
 * Helper function to create port range based on given string chunk items.
 * Will return [100, 101, 102] when rangeInput is '100-102'.
 */
function createPortRange(rangeInput: string): number[] {
    let portRange: number[] = [];

    if (rangeInput && rangeInput.includes(portRangeSeparator)) {
        const [start, end] = rangeInput.split(portRangeSeparator);
        const stop = +end + 1; // increasing by one to include end value in the range.

        portRange = range(+start, stop);
    }

    return portRange;
}

/**
 * Helper function to create port list from control value.
 * Logic for idenfying comma separated values and hyphen separated ranges.
 * Will check and process control value items with help from createPortRange()
 */
function createPortListFromStringInput(rawInput: string): number[] {
    const inputPortList: number[] = [];

    if (rawInput.includes(portListSeparator)) {
        const listItems = rawInput.split(portListSeparator);

        for (const item of listItems) {
            if (item.includes(portRangeSeparator)) {
                // item is a range of port.
                inputPortList.push(...createPortRange(item));
            } else {
                // item is single port value.
                inputPortList.push(+item);
            }
        }
    } else if (rawInput.includes(portRangeSeparator)) {
        inputPortList.push(...createPortRange(rawInput));
    }

    return inputPortList;
}

/**
 * Validates port value, which can be a single value or
 * a string of comma separated ports. By default the separator is comma
 * but it can be configured for other symbols.
 * Supported input formats - individual, comma separated items and ranges.
 * Example - For service port limits are between 80 to 120.
 * Input - 80, 90, 100  - Valid
 * Input - 80, 90, 100-105 - Valid
 * Input - 80-90 - Valid
 * Input - 80-90, 100, 101 - Valid
 * Input - 80-90, 100-110 - Valid
 * Input - 10-20,120 - Invalid
 * Input - 120-130,110 - Invalid
 */
export function portValidator(servicePorts: IService[]): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;
        const error = {};

        error[PORT_VALIDATOR_KEY] = { value };

        if (!value) {
            return null;
        }

        // If input value is having comma separated ports or
        // hyphen separated items consider it as a list of ports.
        if (value.includes(portListSeparator) || value.includes(portRangeSeparator)) {
            const listValue = createPortListFromStringInput(value);

            if (!isValidNumberList(listValue)) {
                return error;
            }

            return every(listValue,
                val => isPortInGivenServiceLimits(servicePorts, val)) ? null : error;
        } else {
            return isPortInGivenServiceLimits(servicePorts, +value) ? null : error;
        }
    };
}
