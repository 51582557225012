/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export interface IRootStoreState {
    loading: boolean;
}

export const initialState: IRootStoreState = {
    loading: false,
};
