<avi-input-container>
    <label
        aviLabelWithTooltip
        for="network-ref"
        [objectType]="objectType"
        fieldName="network_uuid"
        required
    >
        {{ l10nKeys.networkSelectLabel | vtranslate }}
    </label>
    <avi-collection-dropdown
        id="network-ref"
        name="network-ref"
        [disableCreate]="true"
        [disableEdit]="true"
        [collection]="subnetListNetworkCollection"
        [(ngModel)]="editable.network_ref"
        (ngModelChange)="handleNetworkChange()"
        placeholder="{{ l10nKeys.networkSelectPlaceholder | vtranslate }}"
        required
    ></avi-collection-dropdown>
</avi-input-container>

<avi-input-container>
    <label
        aviLabelWithTooltip
        for="subnet"
        [objectType]="objectType"
        fieldName="subnet"
        required
    >
        {{ l10nKeys.subnetSelectLabel | vtranslate }}
    </label>
    <avi-auto-complete
        name="subnet-list"
        id="subnet-list"
        placeholder="{{ l10nKeys.subnetInputPlaceholder | vtranslate }}"
        [(ngModel)]="editable.subnet.subnet"
        [options]="subnetList"
        regex="mask"
        required
    ></avi-auto-complete>
</avi-input-container>
