<avi-card class="ip-reputation-db-list-page-card">
    <avi-card_header>
        <div class="ip-reputation-db-list-page-card__header">
            <div class="ip-reputation-db-list-page-card__title">
                {{ l10nKeys.ipReputationDB | vtranslate }}
            </div>
            <div
                *ngIf="lastUpdate"
                class="ip-reputation-db-list-page-card__last-update"
            >
                {{ l10nKeys.lastUpdate | vtranslate : lastUpdate }}
            </div>
        </div>
    </avi-card_header>
    <avi-card_body>
        <form>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="IPReputationDB"
                    fieldName="name"
                    for="name"
                >
                    {{ l10nKeys.nameFieldLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="name"
                    placeholder="{{ l10nKeys.nameFieldLabel | vtranslate }}"
                    name="name"
                    [ngModel]="ipReputationDB.config.name"
                    readonly
                    tabindex="-1"
                />
            </avi-input-container>
            <avi-textarea-container *ngIf="ipReputationDB.config.description">
                <label
                    aviLabelWithTooltip
                    objectType="IPReputationDB"
                    fieldName="description"
                    for="description"
                >
                    {{ l10nKeys.descriptionFieldLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    id="description"
                    [ngModel]="ipReputationDB.config.description"
                    name="description"
                    placeholder="{{ l10nKeys.descriptionFieldLabel | vtranslate }}"
                    readonly
                ></textarea>
            </avi-textarea-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="IPReputationDB"
                    fieldName="vendor"
                    for="vendor"
                >
                    {{ l10nKeys.vendorFieldLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="vendor"
                    name="vendor"
                    [ngModel]="getVendor()"
                    readonly
                    tabindex="-1"
                />
            </avi-input-container>
        </form>
        <avi-data-grid
            [config]="filesGridConfig"
            [rows]="files"
        ></avi-data-grid>
    </avi-card_body>
</avi-card>
