/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'http-version-match';
const prefix = `${moduleName}.${componentName}`;

export const acceptedVersionsLabel = `${prefix}.acceptedVersionsLabel`;
export const selectHttpVersionPlaceHolder = `${prefix}.selectHttpVersionPlaceHolder`;

export const ENGLISH = {
    [acceptedVersionsLabel]: 'Accepted Versions',
    [selectHttpVersionPlaceHolder]: 'Select HTTP Version',
};
