/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-infoblox-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const ipv4AddressOrFqdnLabel = `${prefix}.ipv4AddressOrFqdnLabel`;
export const networkViewInputLabel = `${prefix}.networkViewInputLabel`;
export const dnsViewInputLabel = `${prefix}.dnsViewInputLabel`;
export const infobloxHeader = `${prefix}.infobloxHeader`;
export const settingsSectionHeader = `${prefix}.settingsSectionHeader`;
export const wapiVersionInputLabel = `${prefix}.wapiVersionInputLabel`;
export const wapiVersionInputPlaceholder = `${prefix}.wapiVersionInputPlaceholder`;
export const extensibleAttributesGridHeader = `${prefix}.extensibleAttributesGridHeader`;

export const ENGLISH = {
    [ipv4AddressOrFqdnLabel]: 'IPv4 Address or FQDN',
    [networkViewInputLabel]: 'Network View',
    [dnsViewInputLabel]: 'DNS View',
    [infobloxHeader]: 'Infoblox',
    [settingsSectionHeader]: 'Settings',
    [wapiVersionInputLabel]: 'WAPI Version',
    [wapiVersionInputPlaceholder]: 'Enter WAPI Version',
    [extensibleAttributesGridHeader]: 'Extensible Attributes ({0})',
};
