/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ALERT_METRICS_ITEM_TOKEN } from '../../alert.tokens';

const allDataSources = {
    source: 'ListCollDataSourceWithSearchParam',
    transformer: 'ListDataTransformer',
    transport: 'ListDataTransport',
    fields: ['config'],
};

export type TAlertMetricsCollection = typeof AlertMetricsCollection;

/**
 * @description AlertMetrics collection
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
export class AlertMetricsCollection extends Collection {
    public static ajsDependencies = [ALERT_METRICS_ITEM_TOKEN];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'analytics/metric_id',
            defaultDataSources: 'list',
            allDataSources,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ALERT_METRICS_ITEM_TOKEN);
        this.setParams({ alert_metrics: true });
    }

    /** @override */
    public isCreatable(): boolean {
        return false;
    }
}
