<div
    class="signpost clr-wrapper"
    [ngClass]="caretClassName"
>
    <div class="signpost-header">
        <cds-icon
            class="signpost-header__close-button"
            shape="times"
            size="18"
            (click)="handleClose()"
        ></cds-icon>
    </div>
    <div class="signpost-body">
        <ng-content></ng-content>
    </div>
</div>
