/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    BackupCollection,
    BackupConfiguration,
    BackupConfigurationCollection,
    BackupItem,
    EmailConfigurationConfigItem,
    FileObjectCollection,
    Scheduler,
    SystemConfig,
} from '.';

import {
    BACKUP_COLLECTION_TOKEN,
    BACKUP_CONFIGURATION_COLLECTION_TOKEN,
    BACKUP_CONFIGURATION_ITEM_TOKEN,
    BACKUP_ITEM_TOKEN,
    EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN,
    FILE_OBJECT_COLLECTION_TOKEN,
    SCHEDULER_ITEM_TOKEN,
    SYSTEM_CONFIG_ITEM_TOKEN,
} from './system.tokens';

const systemModule = angular.module('avi/system');

const factories = [
    {
        dependency: BackupConfiguration,
        name: BACKUP_CONFIGURATION_ITEM_TOKEN,
    },
    {
        dependency: BackupConfigurationCollection,
        name: BACKUP_CONFIGURATION_COLLECTION_TOKEN,
    },
    {
        dependency: BackupItem,
        name: BACKUP_ITEM_TOKEN,
    },
    {
        dependency: BackupCollection,
        name: BACKUP_COLLECTION_TOKEN,
    },
    {
        dependency: EmailConfigurationConfigItem,
        name: EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: FileObjectCollection,
        name: FILE_OBJECT_COLLECTION_TOKEN,
    },
    {
        dependency: Scheduler,
        name: SCHEDULER_ITEM_TOKEN,
    },
    {
        dependency: SystemConfig,
        name: SYSTEM_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(systemModule, 'factory', name, dependency);
});
