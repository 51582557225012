<full-modal-preview>
    <full-modal-preview_container>
        <div class="case-preview clr-wrapper">
            <h4>{{ l10nKeys.casePreviewHeader | vtranslate }}</h4>
            <div class="case-preview__item">
                <h6>{{ l10nKeys.caseStatusLabel | vtranslate }}</h6>
                <div
                    class="case-preview__item-value"
                    [ngClass]="{'case-preview__item-value--disabled-color': isCaseClosed}"
                    *ngIf="status; else noData"
                >
                    {{ status }}
                </div>
            </div>
            <div class="case-preview__item">
                <h6>{{ l10nKeys.caseCreatedDateLabel | vtranslate }}</h6>
                <div
                    class="case-preview__item-value case-preview__item-value--disabled-color"
                    *ngIf="caseCreatedDate; else noData"
                >
                    {{ caseCreatedDate | aviDate }}
                </div>
            </div>
        </div>
    </full-modal-preview_container>
</full-modal-preview>

<ng-template #noData>
    <span class="case-preview__item-value--disabled-color">-</span>
</ng-template>
