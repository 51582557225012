/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-management-expander';
const prefix = `${moduleName}.${componentName}`;

export const customParamLabel = `${prefix}.customParamLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const sensitiveLabel = `${prefix}.sensitiveLabel`;
export const dynamicLabel = `${prefix}.dynamicLabel`;

export const ENGLISH = {
    [customParamLabel]: 'Custom Param {0}',
    [nameLabel]: 'Name',
    [valueLabel]: 'Value',
    [sensitiveLabel]: 'Sensitive',
    [dynamicLabel]: 'Dynamic',
};
