/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AuthRuleAssignment } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AuthMappingRuleConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-mapping-rule.config-item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './user-role-mapping.l10n';
import './user-role-mapping.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH, ...globalL10nKeys } = globalL10n;

/**
 * @description Component to configure user role mapping.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'user-role-mapping',
    templateUrl: './user-role-mapping.component.html',
})
export class UserRoleMappingComponent implements OnDestroy {
    /**
     * AuthMappingRule messageItem instance.
     */
    @Input()
    public editable: AuthMappingRuleConfigItem;

    /**
     * ObjectType used in template.
     */
    @Input()
    public readonly objectType: string;

    /**
     * Fieldname used in template.
     */
    @Input()
    public readonly fieldName: string;

    /**
     * Emits event when remove button is clicked.
     */
    @Output()
    public onRemoveMapping = new EventEmitter();

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * RoleCollection instance.
     */
    public readonly roleCollection: Collection;

    constructor(
        l10nService: L10nService,
        @Inject('RoleCollection')
        RoleCollection: any,
    ) {
        this.roleCollection = new RoleCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.roleCollection.destroy();
    }

    /**
     * If true, RoleCollection dropdown will be displayed.
     */
    public get showRoleList(): boolean {
        const { assign_role: roleAssignment } = this.editable.config;

        return roleAssignment === AuthRuleAssignment.ASSIGN_FROM_SELECT_LIST;
    }

    /**
     * If true, RoleAttributeName field will be displayed.
     */
    public get showRoleAttributeName(): boolean {
        const { assign_role: roleAssignment } = this.editable.config;

        return roleAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_VALUE ||
            roleAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_REGEX ||
            roleAssignment === AuthRuleAssignment.ASSIGN_CONFIG_CONTAINS_ATTRIBUTE_VALUE;
    }

    /**
     * Fires on AssignmentType change.
     */
    public onAssignmentTypeChange(): void {
        this.editable.resetRoleMapping();
    }

    /**
     * Handler for remove mapping event.
     */
    public removeMapping(): void {
        this.onRemoveMapping.emit();
    }
}
