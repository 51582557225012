<match-display-wrapper
    class="http-method-match-readonly"
    matchLabel="{{ l10nKeys.httpMethodLabel | vtranslate }}"
    matchCriteria="{{ matchCriteria }}"
>
    <ng-container *ngIf="editable.config.methods?.length">
        <div *ngFor="let method of editable.config.methods; trackBy: trackByIndex">
            {{ httpMethodsEnumValueHash[method].description }}
        </div>
    </ng-container>
</match-display-wrapper>
