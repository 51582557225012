<div
    class="gslb-service-pools-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="poolsGridConfig"
        [rows]="pools.config"
        [invalidateForm]="!pools.count"
    >
        <avi-data-grid_header>
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="groups"
            >
                <h6>
                    {{ l10nKeys.poolGroupsGridLabel | vtranslate : pools.count }}
                    <span class="gslb-service-pools-grid__mandatory-indicator">*</span>
                </h6>
            </label>
        </avi-data-grid_header>

        <avi-data-grid_actions>
            <cds-button
                (click)="addPool()"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>

    <ng-template
        #poolEnabledTemplateRef
        let-pool
        let-index="index"
    >
        <avi-checkbox
            [noMarginTop]="true"
            name="gslb-pool-enabled"
            [(ngModel)]="pool.config.enabled"
        >
        </avi-checkbox>
    </ng-template>
</div>
