<cds-alert-group
    size="sm"
    [status]="alertType"
    *ngIf="alertType !== 'lightweight'; else lightweight"
>
    <cds-alert
        [closable]="closable"
        (closeChange)="closeAlert()"
    >
        <ng-content></ng-content>
    </cds-alert>
</cds-alert-group>
<ng-template #lightweight>
    <div class="avi-alert-group__alert">
        <cds-icon
            status="info"
            shape="info-circle"
            size="50"
        ></cds-icon>
        <div class="avi-alert-group__deprecation-message">
            <p>{{ deprecationLabel }}</p>
            <cds-button 
                action="flat-inline" 
                (click)="redirectToExternalLink()"
            >
                {{ globalL10nKeys.seeDetailsLabel | vtranslate }}
            </cds-button>
        </div>
    </div>
</ng-template>
