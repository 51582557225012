/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { InfobloxSubnet } from 'object-types';

/**
 * @description InfobloxSubnet MessageItem.
 *
 * @author alextsg, Aravindh Nagarajan
 */
export class InfobloxSubnetConfigItem extends MessageItem {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: InfobloxSubnet,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    public canFlatten(): boolean {
        const { subnet, subnet6 } = this.config;

        return subnet.canFlatten() || subnet6.canFlatten();
    }

    /**
     * @override
     */
    protected requiredFields(): string[] { // eslint-disable-line class-methods-use-this
        return [
            'subnet',
            'subnet6',
        ];
    }
}
