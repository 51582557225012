<div
    provideParentForm
    class="avi-repeated-collection-dropdowns-grid"
>
    <avi-data-grid
        [config]="repeatedDropdownsGridConfig"
        [rows]="dropdownRows"
        [gridTitle]="gridTitle"
    >
        <avi-data-grid_actions>
            <cds-button
                (click)="addDropdown()"
                [disabled]="hasMaxRows"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #collectionDropdownTemplateRef
            let-row
            let-index="index"
        >
            <avi-collection-dropdown
                placeholder="{{ placeholder }}"
                name="{{ name + '-' + index }}"
                [multiple]="multiple"
                [collection]="collection"
                [hideSearch]="hideSearch"
                [readonly]="readonly"
                [(ngModel)]="row.value"
                (ngModelChange)="handleRowModelChange()"
                required
            ></avi-collection-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
