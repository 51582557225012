/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import { Inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';

import {
    forkJoin,
    of,
    pipe,
} from 'rxjs';

import {
    concatMap,
    switchMap,
    tap,
} from 'rxjs/operators';

import { IHttpResponse } from 'angular';

import {
    ClusterObjState,
    ClusterRole,
    IClusterState,
    ICPUUsageInfo,
    IDiskUsageInfo,
    IMemoryUsageInfo,
} from 'generated-types';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { poll } from 'ng/shared/utils/poll.utils';

import {
    HttpMethod,
    HttpWrapper,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

/**
 * Total and used disk size.
 */
export interface IDiskUsage {
    total: number;
    used: number;
}

/**
 * Total and used memory size.
 */
export interface IMemoryUsage {
    total: number;
    used: number;
}

/**
 * Total number of cores and percentage used.
 */
interface ICpuUsage {
    numCores: number;
    cpuPercent: number;
}

/**
 * Cluster runtime information.
 */
interface IClusterRuntimeResponse {
    name: string;
    state: ClusterObjState;
    role: ClusterRole;
}

/**
 * Node name to disk usage info.
 */
interface IDiskUsageResponse {
    [name: string]: IDiskUsage;
}

/**
 * Node name to memory usage info.
 */
interface IMemoryUsageResponse {
    [name: string]: IMemoryUsage;
}

/**
 * Node name to CPU usage info.
 */
interface ICpuUsageResponse {
    [name: string]: ICpuUsage;
}

/**
 * Vitals for a controller node such as CPU, Memory, and Disk usage.
 */
export interface INodeVitals {
    name: string;
    isLeader: boolean;
    state: ClusterObjState;
    cpuUsage: ICpuUsage;
    memoryUsage: IMemoryUsage;
    diskUsage: IDiskUsage;
}

/**
 * Store state. Contains loading state and a list of node vitals data.
 */
interface INodeVitalsWidgetStoreState {
    nodeVitalsList: INodeVitals[];
    loading: boolean;
}

const initialState: INodeVitalsWidgetStoreState = {
    nodeVitalsList: [],
    loading: false,
};

/**
 * API endpoints and request IDs.
 */
const CLUSTER_RUNTIME_URL = '/api/cluster/runtime';
const CLUSTER_RUNTIME_REQUEST_ID = 'node-vitals-widget-cluster-runtime';
const CPU_USAGE_URL = 'api/cpuusage/controller';
const CPU_USAGE_REQUEST_ID = 'node-vitals-widget-cpu-usage';
const MEMORY_USAGE_URL = '/api/memoryusage/controller';
const MEMORY_USAGE_REQUEST_ID = 'node-vitals-widget-memory-usage';
const DISK_USAGE_URL = '/api/diskusage/controller';
const DISK_USAGE_REQUEST_ID = 'node-vitals-widget-disk-usage';

/**
 * Polling interval for fetching the cluster runtime data.
 */
const POLLING_INTERVAL = 30000;

/**
 * @description
 * Store for the NodeVitalsWidgetComponent. Fetches information about the node, disk usage, memory
 * usage, and CPU usage.
 * @author alextsg
 */
@Injectable()
export class NodeVitalsWidgetStore extends ComponentStore<INodeVitalsWidgetStoreState> {
    public readonly loading$ = this.select(state => state.loading);

    public readonly nodeVitalsList$ = this.select(state => state.nodeVitalsList);

    /**
     * Use the poll util function to fetch node vitals every 30 seconds.
     */
    public readonly fetchNodeVitalsData = this.effect<void>(pipe(
        switchMap(
            () => poll(
                of(true).pipe(
                    tap(() => this.patchState({ loading: true })),
                    concatMap(
                        () => forkJoin({
                            nodeStates: this.fetchClusterRuntime(),
                            diskUsage: this.fetchDiskUsage(),
                            memoryUsage: this.fetchMemoryUsage(),
                            cpuUsage: this.fetchCpuUsage(),
                        }).pipe(
                            tapResponse(
                                ({ nodeStates, diskUsage, memoryUsage, cpuUsage }) => {
                                    this.processResponses({
                                        nodeStates,
                                        diskUsage,
                                        memoryUsage,
                                        cpuUsage,
                                    });
                                },
                                // TODO AV-175009: Add error handling when UX is available.
                                error => this.devLoggerService.error(error),
                                () => this.patchState({ loading: false }),
                            ),
                        ),
                    ),
                ),
                POLLING_INTERVAL,
            ),
        ),
    ));

    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly devLoggerService: DevLoggerService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        super(initialState);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Allows component to cancel ongoing requests, ex. when the user leaves the page.
     */
    public cancelFetchNodeVitalsData(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /**
     * Fetch cluster runtime data and return the list of nodes and state data.
     * TODO AV-177728: Move API calls out of store.
     */
    private async fetchClusterRuntime(): Promise<IClusterRuntimeResponse[]> {
        const requestConfig = {
            url: CLUSTER_RUNTIME_URL,
            method: HttpMethod.GET,
            requestId: CLUSTER_RUNTIME_REQUEST_ID,
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const { node_states: nodeStates } = data as IClusterState;

            return nodeStates.map(({ name, role, state }) => ({
                name,
                role,
                state,
            }));
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Fetch disk usage data and return a hash of node name to used and total values.
     */
    private async fetchDiskUsage(): Promise<IDiskUsageResponse> {
        const requestConfig = {
            url: DISK_USAGE_URL,
            method: HttpMethod.GET,
            requestId: DISK_USAGE_REQUEST_ID,
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const { disk_usage_on_nodes: diskUsageOnNodes } = data as IDiskUsageInfo;

            return diskUsageOnNodes.reduce(
                (acc: IDiskUsageResponse, { name, disk_info: diskInfo }) => {
                    const { cntlr_disk_free: diskFree } = diskInfo;
                    const [{ size: total, used }] = diskFree;

                    return {
                        ...acc,
                        [name]: {
                            total,
                            used,
                        },
                    };
                },
                {},
            );
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Fetch memory usage data and return a hash of node name to used and total values.
     */
    private async fetchMemoryUsage(): Promise<IMemoryUsageResponse> {
        const requestConfig = {
            url: MEMORY_USAGE_URL,
            method: HttpMethod.GET,
            requestId: MEMORY_USAGE_REQUEST_ID,
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const { mem_usage_on_nodes: memUsageOnNodes } = data as IMemoryUsageInfo;

            return memUsageOnNodes.reduce(
                (acc: IMemoryUsageResponse, { name, mem_info: memInfo }) => {
                    const { total, free } = memInfo;

                    return {
                        ...acc,
                        [name]: {
                            total,
                            used: total - free,
                        },
                    };
                },
                {},
            );
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Fetch CPU data and return a hash of node name to number of cores and percentage utilized.
     */
    private async fetchCpuUsage(): Promise<ICpuUsageResponse> {
        const requestConfig = {
            url: CPU_USAGE_URL,
            method: HttpMethod.GET,
            requestId: CPU_USAGE_REQUEST_ID,
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const { cpu_usage_on_nodes: cpuUsageOnNodes } = data as ICPUUsageInfo;

            return cpuUsageOnNodes.reduce(
                (acc: ICpuUsageResponse, { name, cpu_info: cpuInfo }) => {
                    const { num_cores: numCores, cpu_percent: cpuPercent } = cpuInfo;

                    return {
                        ...acc,
                        [name]: {
                            numCores,
                            cpuPercent,
                        },
                    };
                },
                {},
            );
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Merge data from all 4 requests to return a list of nodes and corresponding data.
     */
    private processResponses({
        nodeStates,
        diskUsage,
        memoryUsage,
        cpuUsage,
    }: {
        nodeStates: IClusterRuntimeResponse[];
        diskUsage: IDiskUsageResponse;
        memoryUsage: IMemoryUsageResponse;
        cpuUsage: ICpuUsageResponse;
    }): void {
        const nodeVitalsList = nodeStates.map(nodeState => {
            const { name, role, state } = nodeState;

            return {
                name,
                isLeader: role === ClusterRole.CLUSTER_LEADER,
                state,
                cpuUsage: cpuUsage[name],
                memoryUsage: memoryUsage[name],
                diskUsage: diskUsage[name],
            };
        });

        this.patchState({ nodeVitalsList });
    }

    /**
     * Handler for errors during a request. If the request was simply canceled, we don't need to
     * throw an error.
     */
    private handleRequestError(error: IHttpResponse<any>): IHttpResponse<any> | void {
        if (error?.xhrStatus === 'abort') {
            return;
        }

        this.devLoggerService.error(error);

        return error;
    }
}
