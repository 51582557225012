/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'event-grid-config';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleResourceName = `${prefix}.columnTitleResourceName`;
export const columnTitleResourceType = `${prefix}.columnTitleResourceType`;
export const columnTitleEventCode = `${prefix}.columnTitleEventCode`;
export const columnTitleUser = `${prefix}.columnTitleUser`;
export const columnTitleDescription = `${prefix}.columnTitleDescription`;

export const ENGLISH = {
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleResourceName]: 'Resource Name (obj_name)',
    [columnTitleResourceType]: 'Resource Type (obj_type)',
    [columnTitleEventCode]: 'Event Code (event_id)',
    [columnTitleUser]: 'User',
    [columnTitleDescription]: 'Description',
};
