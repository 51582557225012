<div class="ssl-tls-certificate-ocsp">
    <avi-checkbox
        objectType="SSLKeyAndCertificate"
        fieldName="enable_ocsp_stapling"
        [(ngModel)]="editable.config.enable_ocsp_stapling"
        (ngModelChange)="handleToggleEnablingOcspStapling()"
        name="enable-ocsp-stapling"
    >
        {{ l10nKeys.ocspStaplingCheckboxLabel | vtranslate }}
    </avi-checkbox>
    <div *ngIf="editable.config.enable_ocsp_stapling">
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-data-grid
                    [config]="ocspResponderUrlListFromCertsGridConfig"
                    [rows]="editable.config.ocsp_responder_url_list_from_certs"
                >
                    <avi-data-grid_header>
                        <label
                            aviLabelWithTooltip
                            objectType="SSLKeyAndCertificate"
                            fieldName="ocsp_responder_url_list_from_certs"
                        >
                            <h6>
                                {{ l10nKeys.ocspResponderUrlFromCertificatesListLabel | vtranslate : editable.config.ocsp_responder_url_list_from_certs.length }}
                            </h6>
                        </label>
                     </avi-data-grid_header>
                </avi-data-grid>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-input-container helperText="{{ l10nKeys.secondsInnerText | vtranslate }}">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="ocsp_req_interval"
                        for="ocsp_req_interval"
                    >
                        {{ l10nKeys.frequencyIntervalInputLabel | vtranslate }}
                    </label>
                    <input
                        id="ocsp_req_interval"
                        name="ocsp_req_interval"
                        type="number"
                        [(ngModel)]="editable.ocspConfig.config.ocsp_req_interval"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="ocsp_req_interval"
                        clrInput
                        placeholder="{{ l10nKeys.frequencyIntervalInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
            </div>
            <div class="full-modal-config__form-control">
                <avi-input-container helperText="{{ l10nKeys.secondsInnerText | vtranslate }}">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="ocsp_resp_timeout"
                        for="ocsp_resp_timeout"
                    >
                        {{ l10nKeys.responseTimeoutInputLabel | vtranslate }}
                    </label>
                    <input
                        id="ocsp_resp_timeout"
                        name="ocsp_resp_timeout"
                        type="number"
                        [(ngModel)]="editable.ocspConfig.config.ocsp_resp_timeout"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="ocsp_resp_timeout"
                        clrInput
                        placeholder="{{ l10nKeys.responseTimeoutInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-input-container
                    noMarginTop
                    helperText="{{ l10nKeys.secondsInnerText | vtranslate }}"
                >
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="failed_ocsp_jobs_retry_interval"
                        for="failed_ocsp_jobs_retry_interval"
                    >
                        {{ l10nKeys.failJobIntervalInputLabel | vtranslate }}
                    </label>
                    <input
                        id="failed_ocsp_jobs_retry_interval"
                        name="failed_ocsp_jobs_retry_interval"
                        type="number"
                        [(ngModel)]="editable.ocspConfig.config.failed_ocsp_jobs_retry_interval"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="failed_ocsp_jobs_retry_interval"
                        clrInput
                        placeholder="{{ l10nKeys.failJobIntervalInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
            </div>
            <div class="full-modal-config__form-control">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="max_tries"
                        for="max_tries"
                    >
                        {{ l10nKeys.maxTriesInputLabel | vtranslate }}
                    </label>
                    <input
                        id="max_tries"
                        name="max_tries"
                        type="number"
                        [(ngModel)]="editable.ocspConfig.config.max_tries"
                        configFieldInputValidation
                        objectType="{{ objectType }}"
                        fieldName="max_tries"
                        clrInput
                        placeholder="{{ l10nKeys.maxTriesInputPlaceholder | vtranslate }}"
                    />
                </avi-input-container>
            </div>
        </div>
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <avi-data-grid_header>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="responder_url_lists"
                    >
                        <h6>
                            {{ l10nKeys.ocspResponderUrlListLabel | vtranslate : editable.ocspResponderUrlListsCount }}
                        </h6>
                    </label>
                </avi-data-grid_header>
                <avi-repeated-strings-grid
                    name="ocsp-responder-list"
                    placeholder="{{ l10nKeys.ocspResponderGridPlaceholder | vtranslate }}"
                    columnLabel="{{ l10nKeys.ocspResponderGridColumnLabel | vtranslate }}"
                    regex="url"
                    [(ngModel)]="editable.ocspResponderUrlListsConfig.responder_url_lists"
                    [allowDuplicates]="true"
                ></avi-repeated-strings-grid>
            </div>
        </div>
        <avi-radio-container>
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="url_action"
            >
                {{ l10nKeys.responderUrlActionInputLabel | vtranslate }}
            </label>
            <avi-radio
                name="ocsp-config-failover"
                [value]="ocspResponderUrlActionEnumHash.OCSP_RESPONDER_URL_FAILOVER"
                [(ngModel)]="editable.ocspConfig.config.url_action"
                enumType="OCSPResponderUrlAction"
                enumValue="{{ ocspResponderUrlActionEnumHash.OCSP_RESPONDER_URL_FAILOVER }}"
            >
                {{ l10nKeys.urlActionFailoverOptionLabel | vtranslate }}
            </avi-radio>
            <avi-radio
                name="ocsp-config-override"
                [value]="ocspResponderUrlActionEnumHash.OCSP_RESPONDER_URL_OVERRIDE"
                [(ngModel)]="editable.ocspConfig.config.url_action"
                enumType="OCSPResponderUrlAction"
                enumValue="{{ ocspResponderUrlActionEnumHash.OCSP_RESPONDER_URL_OVERRIDE }}"
            >
                {{ l10nKeys.urlActionOverrideOptionLabel | vtranslate }}
            </avi-radio>
        </avi-radio-container>
    </div>
</div>
