/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notifications';
const componentName = 'syslog-controller';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const syslogServerInputLabel = `${prefix}.syslogServerInputLabel`;
export const syslogServerInputPlaceholder = `${prefix}.syslogServerInputPlaceholder`;
export const serverPortInputLabel = `${prefix}.serverPortInputLabel`;
export const portInputPlaceholder = `${prefix}.portInputPlaceholder`;
export const addSyslogServerButtonLabel = `${prefix}.addSyslogServerButtonLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const actionBtnValidateSettings = `${prefix}.actionBtnValidateSettings`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleSyslogServer = `${prefix}.columnTitleSyslogServer`;
export const emptySyslogServersLabel = `${prefix}.emptySyslogServersLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Syslog Notification: {0}',
    [modalHeaderNew]: 'New Syslog Notification: {0}',
    [nameInputLabel]: 'Name',
    [syslogServerInputLabel]: 'Syslog Server',
    [syslogServerInputPlaceholder]: 'IP Address or Hostname',
    [serverPortInputLabel]: 'Port',
    [portInputPlaceholder]: 'Default port 514',
    [addSyslogServerButtonLabel]: '+ Add Syslog Server',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
    [actionBtnValidateSettings]: 'Validate Settings',
    [columnTitleName]: 'Name',
    [columnTitleSyslogServer]: 'Syslog Server',
    [emptySyslogServersLabel]: 'No Syslog Servers',
};
