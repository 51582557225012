/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 * WAF Rule Group names with the format `__N/A:${index}` are UI-only names, and cannot be used
 * as a log filter. If a name of this format is found, this pipe returns an empty string instead.
 * @author alextsg
 */
@Pipe({
    name: 'wafRuleGroupNameFilter',
})
export class WafRuleGroupNameFilterPipe implements PipeTransform {
    /** @override */
    public transform(groupName = ''): string {
        return groupName.indexOf('__N/A:') === 0 ? '' : groupName;
    }
}
