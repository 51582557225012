/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'system-update';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleAttributes = `${prefix}.columnTitleAttributes`;
export const emptyGridPlaceholder = `${prefix}.emptyGridPlaceholder`;
export const systemUpdateLabel = `${prefix}.systemUpdateLabel`;
export const clearPatchLabel = `${prefix}.clearPatchLabel`;
export const nonOperationalFipsMessage = `${prefix}.nonOperationalFipsMessage`;
export const currentVersionLabel = `${prefix}.currentVersionLabel`;
export const availableUpdateslabel = `${prefix}.availableUpdateslabel`;

export const ENGLISH = {
    [systemUpdateLabel]: 'System Update',
    [clearPatchLabel]: 'Clear Patch',
    [columnTitleAttributes]: 'Attributes',
    [emptyGridPlaceholder]: 'No Compatible Image Found.',
    [nonOperationalFipsMessage]: 'Something went wrong and FIPS is currently non operational. Please set up compliance mode again.',
    [currentVersionLabel]: '{0} (current)',
    [availableUpdateslabel]: 'Available updates ({0})',
};
