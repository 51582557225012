/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpgradeModule */

/**
 * @fileoverview Contains Ajs dependacy tokens for factories & services.
 * @author Vinay Jadhav
 */

/**
 * Ajs dependancy token for Upgrade Node.
 */
export const UPGRADE_NODE_TOKEN = 'UpgradeNode';
