/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'AppDashboardController';
const prefix = `${moduleName}.${componentName}`;

export const basicVsCreateActionLabel = `${prefix}.basicVsCreateActionLabel`;
export const advancedVsCreateActionLabel = `${prefix}.advancedVsCreateActionLabel`;
export const viewTypeSelectorLabel = `${prefix}.viewTypeSelectorLabel`;
export const viewTypeVsList = `${prefix}.viewTypeVsList`;
export const viewTypeVsTree = `${prefix}.viewTypeVsTree`;
export const viewTypeGslbServicesLabel = `${prefix}.viewTypeGslbServicesLabel`;
export const sortBySelectorLabel = `${prefix}.sortBySelectorLabel`;
export const sortByHealthScoreLabel = `${prefix}.sortByHealthScoreLabel`;
export const sortByNameLabel = `${prefix}.sortByNameLabel`;
export const searchPlaceholder = `${prefix}.searchPlaceholder`;
export const createVirtualServiceLabel = `${prefix}.createVirtualServiceLabel`;
export const numberOfVirtualServices = `${prefix}.numberOfVirtualServices`;
export const emptyVSListNoSearchMatchMessage = `${prefix}.emptyVSListNoSearchMatchMessage`;
export const collapseAllRows = `${prefix}.collapseAllRows`;
export const expandAllRows = `${prefix}.expandAllRows`;
export const vsListLegendHealthScore = `${prefix}.vsListLegendHealthScore`;
export const vsListLegendPools = `${prefix}.vsListLegendPools`;
export const vsListLegendServers = `${prefix}.vsListLegendServers`;
export const vsListLegendAlerts = `${prefix}.vsListLegendAlerts`;
export const emptyVSListMessage = `${prefix}.emptyVSListMessage`;
export const numberOfGlobalServices = `${prefix}.numberOfGlobalServices`;
export const emptyGslbServiceListNoSearchMatchMessage = `${prefix}.emptyGslbServiceListNoSearchMatchMessage`;
export const emptyGslbServiceListMessage = `${prefix}.emptyGslbServiceListMessage`;
export const insufficientPermissionsAlertMessage = `${prefix}.insufficientPermissionsAlertMessage`;
export const showHideLegendTitle = `${prefix}.showHideLegendTitle`;

export const ENGLISH = {
    [basicVsCreateActionLabel]: 'Basic Setup',
    [advancedVsCreateActionLabel]: 'Advanced Setup',
    [viewTypeSelectorLabel]: 'View',
    [viewTypeVsList]: 'VS List',
    [viewTypeVsTree]: 'VS Tree',
    [viewTypeGslbServicesLabel]: 'GSLB Services',
    [sortBySelectorLabel]: 'Sort By',
    [sortByHealthScoreLabel]: 'Health Score',
    [sortByNameLabel]: 'Name',
    [searchPlaceholder]: 'Search',
    [createVirtualServiceLabel]: 'Create Virtual Service',
    [numberOfVirtualServices]: 'Virtual Services ({0})',
    [emptyVSListNoSearchMatchMessage]: 'No Virtual Services match your search criteria.',
    [collapseAllRows]: 'Collapse All',
    [expandAllRows]: 'Expand All',
    [vsListLegendHealthScore]: 'Health Score',
    [vsListLegendPools]: 'Pools',
    [vsListLegendServers]: 'Servers',
    [vsListLegendAlerts]: 'Alerts',
    [emptyVSListMessage]: 'No Virtual Services have been created.',
    [numberOfGlobalServices]: 'Global Services ({0})',
    [emptyGslbServiceListNoSearchMatchMessage]: 'No GSLB Services match your search criteria.',
    [emptyGslbServiceListMessage]: 'No GSLB Services have been created.',
    [insufficientPermissionsAlertMessage]: 'User does not have sufficient permissions for VirtualService or GslbService, so dashboard is empty.',
    [showHideLegendTitle]: 'Show/Hide Legend',
};
