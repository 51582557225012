/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { AUTO_SCALE_POLICY_ITEM_TOKEN } from 'ajs/modules/auto-scale/auto-scale.tokens';

/**
 * @description AutoScale policy collection
 *
 * @author Ram Pal, Nisar Nadaf, Harmeet Kaur
 */
export class AutoScalePolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'serverautoscalepolicy',
            permissionName: AviPermissionResource.PERMISSION_AUTOSCALE,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(AUTO_SCALE_POLICY_ITEM_TOKEN);
    }
}

AutoScalePolicyCollection.ajsDependencies = [
    AUTO_SCALE_POLICY_ITEM_TOKEN,
];
