/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { HTTPApplicationProfile } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IHTTP2ApplicationProfile,
    IHTTPApplicationProfile,
    IHTTPSessionConfig,
    ISSLClientCertificateAction,
    ISSLClientRequestHeader,
    ITrueClientIPConfig,
} from 'generated-types';

import { CompressionProfileConfigItem } from './compression-profile.config-item.factory';
import { HttpCacheConfigConfigItem } from './http-cache-config.config-item.factory';

type TSslClientCertificateActionPartial = Omit<ISSLClientCertificateAction, 'headers'>;

export interface ISslClientCertificateActionConfig extends TSslClientCertificateActionPartial {
    headers: RepeatedMessageItem<MessageItem<ISSLClientRequestHeader>>,
}

type THttpApplicationProfilePartial = Omit<IHTTPApplicationProfile,
'true_client_ip' |
'compression_profile' |
'ssl_client_certificate_action' |
'http2_profile' |
'cache_config' |
'session_config'
>;

interface IHttpApplicationProfileConfig extends THttpApplicationProfilePartial {
    true_client_ip: MessageItem<ITrueClientIPConfig>,
    ssl_client_certificate_action: MessageItem<ISslClientCertificateActionConfig>,
    compression_profile: CompressionProfileConfigItem,
    http2_profile: MessageItem<IHTTP2ApplicationProfile>,
    cache_config: HttpCacheConfigConfigItem,
    session_config: MessageItem<IHTTPSessionConfig>,
}

/**
 * @desc HttpApplicationProfile MessageItem class.
 * @author Nisar Nadaf
 */
export class HTTPApplicationProfileConfigItem
    extends MessageItem<IHttpApplicationProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HTTPApplicationProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for count of true client IP headers.
     */
    public get trueClientIpHeadersCount(): number {
        const { true_client_ip: trueClientIp } = this.config;

        return trueClientIp.config.headers?.length || 0;
    }

    /**
     * Getter for Http Session config.
     */
    public get httpSession(): MessageItem<IHTTPSessionConfig> {
        const { session_config: sessionConfig } = this.config;

        return sessionConfig;
    }

    /**
     * Reset/delete true_client_ip config object based on provided flag.
     */
    public resetTrueClientIpConfig(trueClientIpEnabled: boolean): void {
        if (trueClientIpEnabled) {
            this.safeSetNewChildByField('true_client_ip');
        } else {
            delete this.config.true_client_ip;
        }
    }

    /**
     * Getter for request headers from SSL Client Certificate Action.
     */
    public get httpRequestHeaders(): RepeatedMessageItem<MessageItem<ISSLClientRequestHeader>> {
        const { ssl_client_certificate_action: sslCertificateAction } = this.config;

        return sslCertificateAction.config.headers;
    }

    /**
     * Sets values of sslEveyWhere related properties based on value of SSL Everywhere checkbox.
     */
    public updateSecuredHttpConfig(value: boolean): void {
        this.config.http_to_https = value;
        this.config.server_side_redirect_to_https = value;
        this.config.secure_cookie_enabled = value;
        this.config.hsts_enabled = value;
        this.config.httponly_enabled = value;
        this.config.x_forwarded_proto_enabled = value;
    }

    /**
     * Get SSL everywhere enable value.
     */
    public getHttpProfileSslEverywhereEnabledValue(): boolean {
        return this.config.http_to_https &&
            this.config.server_side_redirect_to_https &&
            this.config.secure_cookie_enabled &&
            this.config.hsts_enabled &&
            this.config.httponly_enabled &&
            this.config.x_forwarded_proto_enabled;
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const {
            use_true_client_ip: trueClientIpEnabled,
            compression_profile: compressionProfile,
            ssl_client_certificate_action: sslClientCertificateAction,
            cache_config: cacheConfig,
        } = this.getConfig();

        if (!trueClientIpEnabled) {
            delete this.config.true_client_ip;
        }

        if (!compressionProfile) {
            this.safeSetNewChildByField('compression_profile');
        }

        if (!sslClientCertificateAction) {
            this.safeSetNewChildByField('ssl_client_certificate_action');
        }

        if (!cacheConfig) {
            this.safeSetNewChildByField('cache_config');
        }
    }

    /**
     * @override
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const {
            compression_profile: compressionProfile,
            hsts_enabled: hstsEnabled,
            cache_config: cacheConfig,
        } = this.getConfig();

        if (!compressionProfile?.config.compression) {
            delete this.config.compression_profile;
        }

        if (!hstsEnabled) {
            delete this.config.hsts_max_age;
        }

        if (!cacheConfig?.config.enabled) {
            delete this.config.cache_config;
        }

        // If no headers are added then uncheck close connection checkbox.
        if (this.httpRequestHeaders.count === 0) {
            this.config.ssl_client_certificate_action.config.close_connection = false;
        }
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'session_config',
        ];
    }
}
