<match-display-wrapper
    class="http-version-match-readonly"
    matchLabel="{{ globalL10nKeys.httpVersionLabel | vtranslate }}"
    matchCriteria="{{ matchCriteria }}"
>
    <ng-container *ngIf="editable.config.versions?.length">
        <div *ngFor="let version of editable.config.versions; trackBy: trackByIndex">
            {{ httpVersionsEnumValueHash[version].description }}
        </div>
    </ng-container>
</match-display-wrapper>
