<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.editSnmpTrapProfileHeader : l10nKeys.newSnmpTrapProfileHeader) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [valid]="form.valid"
    [errors]="editable.errors"
    [modified]="form.touched && form.dirty"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        [objectType]="objectType"
                        fieldName="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                        regexPattern="objName"
                        [(ngModel)]="editable.config.name"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-data-grid
                            [config]="trapProfileListGridConfig"
                            [rows]="editable.config.trap_servers.config"
                        >
                            <avi-data-grid_header>
                                <h6>
                                    {{ l10nKeys.snmpTrapServersLabel | vtranslate : editable.config.trap_servers.count}}
                                </h6>
                            </avi-data-grid_header>
                            <avi-data-grid_actions>
                                <cds-button
                                    size="sm" 
                                    (click)="createSnmpTrapServer()"
                                >
                                    {{ l10nKeys.addLabel | vtranslate }}
                                </cds-button>
                            </avi-data-grid_actions>
                        </avi-data-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
