<div class="system-settings-access-ssl-card sel-system-config">
    <h6 class="system-settings-access-ssl-card__header">
        {{ l10nKeys.sslHeader | vtranslate }}
    </h6>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType.PortalConfiguration"
            fieldName="sslprofile_uuid"
            for="ssl-profile"
        >
            {{ l10nKeys.sslProfileLabel | vtranslate }} 
        </label>
        <input
            name="ssl-profile"
            id="ssl-profile"
            placeholder="-"
            clrInput
            [value]="sslProfileName"
            readonly
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType.PortalConfiguration"
            fieldName="sslkeyandcertificate_uuids"
        >
            {{ l10nKeys.sslTlsCertificateLabel | vtranslate }}
        </label>
        <ng-container *ngIf="!isSslTlsCertificatesNamesListEmpty; else emptyTemplate">
            <avi-input-container
                *ngFor="let sslTlsCertificatesName of sslTlsCertificatesNamesList; index as i; trackBy: trackByIndex"
                noMarginTop
            >
                <input
                    name="ssl-tls-certificate-{{ i }}"
                    placeholder="-"
                    clrInput
                    [value]="sslTlsCertificatesName"
                    readonly
                />
            </avi-input-container>
        </ng-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType.SecureChannelConfiguration"
            fieldName="sslkeyandcertificate_uuids"
        >
            {{ l10nKeys.secureChannelSslTlsCertificateLabel | vtranslate }}
        </label>
        <ng-container *ngIf="!isSecureChannelSslTlsCertificateNamesListEmpty; else emptyTemplate">
            <avi-input-container
                *ngFor="let secureChannelSslTlsCertificatesName of secureChannelSslTlsCertificateNamesList; index as i; trackBy: trackByIndex"
                noMarginTop
            >
                <input
                    name="secure-channel-ssl-tls-certificate-{{ i }}"
                    placeholder="-"
                    clrInput
                    [value]="secureChannelSslTlsCertificatesName"
                    readonly
                />
            </avi-input-container>
        </ng-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType.SystemConfiguration"
            fieldName="ssh_ciphers"
        >
            {{ l10nKeys.allowedCiphers | vtranslate }}
        </label>
        <ng-container *ngIf="!isAllowedCiphersEmpty; else emptyTemplate">
            <avi-input-container
                noMarginTop
                *ngFor="let cipher of allowedCiphers; index as i; trackBy: trackByIndex"
            >
                <input
                    name="allowed-ciphers-{{ i }}"
                    placeholder="-"
                    clrInput
                    [value]="cipher"
                    readonly
                />
            </avi-input-container>
        </ng-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType.SystemConfiguration"
            fieldName="ssh_hmacs"
            for="allowed-hmacs"
        >
            {{ l10nKeys.allowedHmacs | vtranslate }}
        </label>
        <input
            id="allowed-hmacs"
            name="allowed-hmacs"
            placeholder="-"
            clrInput
            [value]="hmacsCount"
            readonly
        />
    </avi-input-container>
</div>

<ng-template #emptyTemplate>
    <avi-input-container noMarginTop>
        <input
            name="empty-input"
            id="empty-input"
            placeholder="-"
            clrInput
            readonly
        />
    </avi-input-container>
</ng-template>
