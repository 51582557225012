<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header
            headerText="{{ l10nKeys.subdomainsLabel | vtranslate }}"
        ></avi-page-header>
        <div class="gslb-subdomains-list-page">
            <div class="gslb-subdomains-list-page__menu">
                <ng-container *ngIf="gslb.isEditable()">
                    <cds-button
                        class="gslb-subdomains-list-page__create-btn"
                        (click)="addSubdomain()"
                        size="sm"
                    >
                        {{ globalL10nKeys.createLabel | vtranslate }}
                    </cds-button>
                </ng-container>

                <div class="btn-group gslb-subdomains-list-page__view-toggle-btn">
                    <div class="radio btn btn-sm">
                        <avi-radio
                            [value]="viewType.TREE"
                            [(ngModel)]="currentView"
                        >
                            {{ l10nKeys.treeViewLabel | vtranslate }}
                        </avi-radio>
                    </div>
                    <div class="radio btn btn-sm">
                        <avi-radio
                            [value]="viewType.LIST"
                            [(ngModel)]="currentView"
                        >
                            {{ l10nKeys.listViewLabel | vtranslate }}
                        </avi-radio>
                    </div>
                </div>
            </div>

            <div 
                class="gslb-subdomains-list-page__list-view-container"
                *ngIf="currentView === viewType.LIST"
            >
                <gslb-subdomains-grid
                    [subdomains]="subdomainList"
                    [isLoading]="isLoading"
                    [isGslbEditable]="gslb.isEditable()"
                    (onEditSubdomain)="editSubdomain($event)"
                    (onRemoveSubdomains)="showRemoveSubdomainConfirmation($event)"
                ></gslb-subdomains-grid>
            </div>
            <div 
                class="gslb-subdomains-list-page__tree-view-container"
                *ngIf="currentView === viewType.TREE"
            >   
                <div class="gslb-subdomains-list-page__tree-header">
                    <h6 class="gslb-subdomains-list-page__tree-header-label">
                        {{ l10nKeys.subdomainLabel | vtranslate : subdomainTreeList.length }}
                    </h6>
                    <h6 class="gslb-subdomains-list-page__tree-header-label">
                        {{ l10nKeys.gslbSitesLabel | vtranslate : uniqueGslbSitesCount }}
                    </h6>
                    <h6 class="gslb-subdomains-list-page__tree-header-label">
                        {{ l10nKeys.virtualServicesLabel | vtranslate : uniqueDnsVsesCount }}
                    </h6>
                </div>
                
                <ng-container *ngIf="isLoading">
                    <clr-spinner clrMedium></clr-spinner>
                </ng-container>
                <ng-container *ngFor="let subdomainTree of subdomainTreeList; index as i; trackBy: trackbyIndex">
                    <gslb-subdomains-tree
                        [treeData]="subdomainTree"
                        [index]="i"
                        [isGslbEditable]="gslb.isEditable()"
                        (onEditSubdomain)="editSubdomain($event)"
                        (onRemoveSubdomain)="showRemoveSubdomainConfirmation([$event])"
                        (onHoverTreeNode)="updateHoveredNodeName($event)"
                        [highlightNode]="hoveredTreeNode"
                    ></gslb-subdomains-tree>
                </ng-container>
            </div>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
