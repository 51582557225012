/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module AdministrationDashboardModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { ReactiveComponentModule } from '@ngrx/component';
import { AviDashboardModule } from 'nsx-alb-ui-components';

import { SharedModule } from 'ng/shared/shared.module';
import { CoreModule } from 'ng/modules/core/core.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DashboardModule } from 'ng/modules/dashboard/dashboard.module';
import { SystemModule } from 'ng/modules/system/system.module';
import { ClusterModule } from 'ng/modules/cluster/cluster.module';

import {
    AdministrationDashboardPageComponent,
    CloudServicesWidgetComponent,
    ControllerWidgetComponent,
    NodeVitalsUsageBarComponent,
    NodeVitalsUsageInfoComponent,
    NodeVitalsWidgetComponent,
} from './components';

@NgModule({
    declarations: [
        AdministrationDashboardPageComponent,
        CloudServicesWidgetComponent,
        ControllerWidgetComponent,
        NodeVitalsWidgetComponent,
        NodeVitalsUsageInfoComponent,
        NodeVitalsUsageBarComponent,
    ],
    imports: [
        AviDashboardModule,
        CommonModule,
        AviFormsModule,
        SharedModule,
        DashboardModule,
        ReactiveComponentModule,
        SystemModule,
        CoreModule,
        ClusterModule,
    ],
    exports: [
        AdministrationDashboardPageComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class AdministrationDashboardModule { }
