<cds-alert-group status="danger">
    <cds-alert
        [closable]="closable"
        (closeChange)="handleCloseClick()"
    >
        {{ errorMessage }}

        <cds-alert-actions *ngIf="actionLabel">
            <cds-button (click)="handleActionClick()">
                {{ actionLabel }}
            </cds-button>
        </cds-alert-actions>
    </cds-alert>
</cds-alert-group>
