<span
    avi-tooltip
    [positionsPriority]="hoverOverlayPositionPriorities"
>
    <ng-content></ng-content>

    <ng-template #aviTooltipContent>
        <avi-hover-overlay>
            <div class="vs-log-duration-detail__container">
                <div class="vs-log-duration-detail__row">
                    <div class="vs-log-duration-detail__row-black">{{ l10nKeys.startTimeLabel | vtranslate }}</div>
                    <div class="vs-log-duration-detail__row-value">{{ startTime | aviDate : 'mediumTime' }}</div>
                </div>
                <hr>
                <div class="vs-log-duration-detail__row">
                    <label-color-optional
                        class="vs-log-duration-detail__row-black"
                        [labelData]="{ hasLeftColor: true, color: 'var(--avi-clr-viking-blue)' }"
                    >
                        {{ l10nKeys.clientRttLabel | vtranslate }}
                    </label-color-optional>
                    <div class="vs-log-duration-detail__row-value">
                        <ng-container *ngIf="clientRtt !== undefined; else noData">
                            <span *ngIf="clientRtt >= minDisplayTime; else lessThanOne">
                                {{ clientRttFormatted }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="vs-log-duration-detail__row">
                    <label-color-optional
                        class="vs-log-duration-detail__row-black"
                        [labelData]="{ hasLeftColor: true, color: 'var(--clr-color-secondary-action-500)' }"
                    >
                        {{ l10nKeys.serverRttLabel | vtranslate }}
                    </label-color-optional>
                    <div class="vs-log-duration-detail__row-value">
                        <ng-container *ngIf="serverRtt !== undefined; else noData">
                            <span *ngIf="serverRtt >= minDisplayTime; else lessThanOne">
                                {{ serverRttFormatted }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div
                    *ngIf="vsLogsStore.hasWafPolicy$ | async"
                    class="vs-log-duration-detail__row"
                >
                    <label-color-optional
                        class="vs-log-duration-detail__row-black"
                        [labelData]="{ hasLeftColor: true, color: 'var(--avi-clr-prussian-blue)' }"
                    >
                        {{ l10nKeys.wafLabel | vtranslate }}
                    </label-color-optional>
                    <div class="vs-log-duration-detail__row-value">
                        <ng-container *ngIf="wafTime !== undefined; else noData">
                            <span *ngIf="wafTime >= minDisplayTime; else lessThanOne">
                                {{ wafTimeFormatted }}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <ng-container *ngIf="(vsLogsStore.vsLogsType$ | async) === VsLogsType.APPLICATION">
                    <div class="vs-log-duration-detail__row">
                        <label-color-optional
                            class="vs-log-duration-detail__row-black"
                            [labelData]="{ hasLeftColor: true, color: 'var(--avi-clr-turquoise-blue)' }"
                        >
                            {{ l10nKeys.appResponseLabel | vtranslate }}
                        </label-color-optional>
                        <div class="vs-log-duration-detail__row-value">
                            <ng-container *ngIf="appResponseTime !== undefined; else noData">
                                <span *ngIf="appResponseTime >= minDisplayTime; else lessThanOne">
                                    {{ appResponseTimeFormatted }}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="vs-log-duration-detail__row">
                        <label-color-optional
                            class="vs-log-duration-detail__row-black"
                            [labelData]="{ hasLeftColor: true, color: 'var(--clr-color-neutral-800)' }"
                        >
                            {{ l10nKeys.dataTransferLabel | vtranslate }}
                        </label-color-optional>
                        <div class="vs-log-duration-detail__row-value">
                            <ng-container *ngIf="dataTransferTime !== undefined; else noData">
                                <span *ngIf="dataTransferTime >= minDisplayTime; else lessThanOne">
                                    {{ dataTransferTimeFormatted }}
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="vs-log-duration-detail__row">
                    <div class="vs-log-duration-detail__row-black">{{ globalL10nKeys.totalLabel | vtranslate }}</div>
                    <div class="vs-log-duration-detail__row-value">
                        <ng-container *ngIf="totalTime !== undefined; else noData">
                            <span *ngIf="totalTime >= minDisplayTime; else lessThanOne">
                                {{ totalTimeFormatted }}
                            </span>
                        </ng-container>
                    </div>
                </div>

                <ng-template #noData>
                    <div vsLogEmptyField></div>
                </ng-template>

                <ng-template #lessThanOne>
                    &lt; {{ minDisplayTime }}{{ globalL10nKeys.msLabel | vtranslate }}
                </ng-template>
            </div>
        </avi-hover-overlay>
    </ng-template>
</span>
