/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'virtual-service-faults';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceFaultTitle = `${prefix}.virtualServiceFaultsTitle`;
export const serverFaultsTitle = `${prefix}.serverFaultsTitle`;

export const ENGLISH = {
    [virtualServiceFaultTitle]: 'Virtual Service Fault',
    [serverFaultsTitle]: 'Server Faults',
};
