/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { GeoDbMappingConfigItem } from 'ajs/modules/geo-db';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './geo-db-mapping-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Geo DB mapping Grid component.
 *
 * @author Rachit Aggarwal
 */

@Component({
    selector: 'geo-db-mapping-grid',
    templateUrl: './geo-db-mapping-grid.component.html',
})
export class GeoDbMappingGridComponent implements AfterViewInit {
    /**
     * GeoDbMapping RepeatedMessageItem instance.
     */
    @Input()
    public geoDbMappings: RepeatedMessageItem<GeoDbMappingConfigItem>;

    /**
     * Fires on add Geo Db Mapping.
     */
    @Output()
    public onAddGeoDbMapping = new EventEmitter();

    /**
     * Fires on remove Geo Db Mapping.
     */
    @Output()
    public onRemoveGeoDbMapping = new EventEmitter<GeoDbMappingConfigItem>();

    /**
     * Fires on edit Geo Db Mapping.
     */
    @Output()
    public onEditGeoDbMapping = new EventEmitter<GeoDbMappingConfigItem>();

    /**
     * Geo Db mapping grid config.
     */
    public geoDbMappingGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.geoDbMappingGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.geoDbMappingColTitleName),
                    id: 'name',
                    transform(mapping: GeoDbMappingConfigItem) {
                        return mapping.config.name;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.geoDbMappingColTitleElements),
                    id: 'enabled',
                    transform(mapping: GeoDbMappingConfigItem) {
                        return `${mapping.elements.getConfig().length}`;
                    },
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(this.l10nKeys.geoDbMappingRemoveButtonLabel),
                onClick: (geoDbMappingList: GeoDbMappingConfigItem[]) => {
                    geoDbMappingList
                        .forEach((geoDbMappingItem: GeoDbMappingConfigItem) => {
                            this.removeGeoDbMappingList(geoDbMappingItem);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.geoDbMappingEditButtonLabel),
                shape: 'pencil',
                onClick: (geoDbMappingItem: GeoDbMappingConfigItem) => {
                    this.editGeoDbMappingList(geoDbMappingItem);
                },
            }, {
                label: this.l10nService.getMessage(this.l10nKeys.geoDbMappingRemoveButtonLabel),
                shape: 'trash',
                onClick: (geoDbMappingItem: GeoDbMappingConfigItem) => {
                    this.removeGeoDbMappingList(geoDbMappingItem);
                },
            }],
        };
    }

    /**
     * Handler for Geo Db mapping add operation.
     */
    public addGeoDbMappingList(): void {
        this.onAddGeoDbMapping.emit();
    }

    /**
     * Handler for Geo Db mapping remove operation.
     */
    private removeGeoDbMappingList(geoDbMappingItem: GeoDbMappingConfigItem): void {
        this.onRemoveGeoDbMapping.emit(geoDbMappingItem);
    }

    /**
     * Handler for Geo Db mapping edit operation.
     */
    private editGeoDbMappingList(geoDbMappingItem: GeoDbMappingConfigItem): void {
        this.onEditGeoDbMapping.emit(geoDbMappingItem);
    }
}
