/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe } from '@angular/core';

import {
    IAviDiffGridSourcePair,
    IAviDiffGridSourcePairTransform,
} from 'ng/modules/data-grid/components/avi-diff-grid';

import {
    HTTP_HEADER_NAME_DUPLICATE_POSTFIX,
    VsLogsHttpHeadersStringService,
} from '../services/vs-log-http-headers.service';

/**
 * Example sub-log types can be IcapRequestLog and IPaaRequestLog, without an exhausted list.
 * Not top level logs (ApplicationLog or ConnectionLog).
 */
type TSubLog = any;

/**
 * @description
 *     Pipe, given a sub-log object, eg. IcapRequestLog, to transform a pair of its HTTP headers
 *     strings of with delimiters '\r\n' to an object of diff source pair with two sets of headers
 *     extracted from the headers strings, respectively, and saved in the left and right source
 *     objects.
 *     No diff operations are supposed to be performed here (by setting performNoDiff to true), as
 *     for sub-logs the two headers strings have nothing in common.
 * @example
 *     Raw data: (under one entry of the request_logs list under an icap_log)
 *     (left)
 *     icap_headers_sent_to_server: 'Host: 10.50.50.109:1344\r\nConnection: close\r\nPreview: 0\r\n
 *         Encapsulated: req-hdr=0, null-body=137\r\n'
 *     (right)
 *     icap_headers_received_from_server: 'Date: Wed, 31 May 2023 07:27:47 GMT\r\n
 *         Server: Metadefender Core V4\r\nISTag: \"001685518020\"\r\nX-Response-Info: Blocked\r\n
 *         X-Response-Desc: Invalid license\r\nEncapsulated: res-hdr=0, res-body=91\r\n'
 *
 *     Resulted diff source pair object:
 *     {
 *       leftSource: {
 *           'Preview': '0',
 *           'Connection': 'close',
 *           'Encapsulated': 'req-hdr=0, null-body=137',
 *           'Host': '10.50.50.109:1344'
 *       },
 *       rightSource: {
 *           'Date': 'Wed, 31 May 2023 07:27:47 GMT',
 *           'Encapsulated': 'res-hdr=0, res-body=91',
 *           'ISTag': '001685518020'',
 *           'Server': 'Metadefender Core V4',
 *           'X-Response-Desc': 'Invalid license',
 *           'X-Response-Info': 'Blocked'
 *       },
 *       diffPostfix: ':://',
 *       performNoDiff: true,
 *     }
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'subLogHeadersDiffSourcePair',
})
export class SubLogHeadersDiffSourcePairPipe implements IAviDiffGridSourcePairTransform {
    /** @override */
    // eslint-disable-next-line class-methods-use-this
    public transform(
        subLog: TSubLog,
        leftHeadersPropertyName: string,
        rightHeadersPropertyName: string,
    ): IAviDiffGridSourcePair {
        const leftHeadersString: string = subLog[leftHeadersPropertyName];
        const rightHeadersString: string = subLog[rightHeadersPropertyName];

        // turn raw headers string data to a list of headers and save them as a hash
        const leftSource =
            VsLogsHttpHeadersStringService.splitHttpHeadersStrToHash(leftHeadersString);

        const rightSource =
            VsLogsHttpHeadersStringService.splitHttpHeadersStrToHash(rightHeadersString);

        const diffSourcePair: IAviDiffGridSourcePair = {
            leftSource,
            rightSource,
            diffPostfix: HTTP_HEADER_NAME_DUPLICATE_POSTFIX,
            performNoDiff: true,
        };

        return diffSourcePair;
    }
}
