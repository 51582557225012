/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import { HealthMonitorSmtp } from 'object-types';

import {
    IHealthMonitorSmtp,
    IHealthMonitorSSLAttributes,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Smtp health monitor type without the ssl_aatributes.
 */
type THealthMonitorSmtpPartial = Omit<IHealthMonitorSmtp, 'ssl_attributes'>;

/**
 * SMTP Health Monitor Config type.
 */
interface IHealthMonitorSMTPConfig extends THealthMonitorSmtpPartial {
    ssl_attributes: MessageItem<IHealthMonitorSSLAttributes>;
}

/**
 * @description Health Monitor SMTP Config Item.
 * @author Sarthak Kapoor
 */
export class HealthMonitorSmtpConfigItem extends MessageItem<IHealthMonitorSMTPConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorSmtp,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ssl_attributes'];
    }
}
