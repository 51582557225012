<full-modal-config
    modalTitle="{{ l10nKeys.geoDbMappingModalTitle | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="geo-db-mapping-modal"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ editable.messageType }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="description"
                        objectType="{{ editable.messageType }}"
                        for="description"
                    >
                        {{ l10nKeys.descriptionInputLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        name="description"
                        [(ngModel)]="editable.config.description"
                        placeholder="{{ l10nKeys.descriptionInputLabel | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.geoDbElementsLabel | vtranslate : editable.elements.count }}
                        </h6>
                        <geo-db-mapping-element-grid
                            [geoDbMappingElements]="editable.getConfig().elements"
                            (onAddGeoDbMappingElement)="onAddGeoDbMappingElement()"
                            (onRemoveGeoDbMappingElement)="onRemoveGeoDbMappingElement($event)"
                            (onEditGeoDbMappingElement)="onEditGeoDbMappingElement($event)"
                        ></geo-db-mapping-element-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form> 
</full-modal-config>
