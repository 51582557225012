/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './capsule.less';

/**
 * @ngdoc component
 * @name capsule
 * @description
 *     Component used to display a value alongside a label, separated by borders to resemble a
 *     capsule.
 * @param {string} label - String to be used as the label.
 * @param {string} value - String to be used as the value.
 */
const
    componentName = 'capsule',
    maxValLength = 14;//should match max-width of less file

class CapsuleController {
    constructor($element) {
        this._$element = $element;
    }

    $onInit() {
        this._$element.addClass(componentName);
    }

    getTitleValue() {
        const { value } = this;

        return value && value.length > maxValLength ? value : null;
    }
}

CapsuleController.$inject = [
    '$element',
];

angular.module('aviApp').component('capsule', {
    controller: CapsuleController,
    bindings: {
        label: '@',
        value: '@',
    },
    template:
        `<div class="capsule__label">
            {{ $ctrl.label }}
        </div>
        <div class="capsule__value" smart-title="{{::$ctrl.getTitleValue()}}">
            {{ $ctrl.value || 'N/A' }}
        </div>`,
});
