/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { Certificate } from 'ajs/modules/security';
import { UploadType } from 'ng/shared/components';

import * as globalL10n from 'global-l10n';
import * as l10n from './ssl-certificate-create-root-modal.l10n';

import './ssl-certificate-create-root-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal for SSL Root Certificate. User validates and imports a certificate.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-certificate-create-root-modal',
    templateUrl: './ssl-certificate-create-root-modal.component.html',
})
export class SslCertificateCreateRootModalComponent implements OnInit, AfterViewInit {
    /**
     * Certificate ObjectTypeItem.
     */
    @Input()
    public editable: Certificate;

    /**
     * Layout for Certificate create root modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * SSLKeyAndCertificate object type.
     */
    public objectType: string;

    /**
     *  Stores validation state.
     */
    public validated = false;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * @override
     * Set Validated flag after all ngmodel values are set.
     * (Because file-upload-textarea will emit onUploadTypeChange event after
     * ngmodel is set which will reset validation flag.)
     */
    public ngAfterViewInit(): void {
        this.validated = Boolean(this.editable.id);
    }

    /**
     * Sends certificate validate request and sets the Certificate config.
     */
    public validateCert(): void {
        this.editable.validateCertificate()
            .then(() => this.validated = true);
    }

    /**
     * Resets certificate validation state.
     */
    public resetValidation(): void {
        this.validated = false;
    }

    /**
     * Sets certificate_base64 flag based on uploadType
     */
    public setCertificateBase64(uploadType: UploadType): void {
        this.editable.config.certificate_base64 = uploadType === UploadType.FILE;
        this.resetValidation();
    }
}
