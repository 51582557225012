/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-score';
const componentName = 'avi-health-score-bars';
const prefix = `${moduleName}.${componentName}`;

export const performanceLabel = `${prefix}.performanceLabel`;
export const resourceLabel = `${prefix}.resourceLabel`;
export const anomalyLabel = `${prefix}.anomalyLabel`;
export const penaltiesLabel = `${prefix}.penaltiesLabel`;

export const ENGLISH = {
    [performanceLabel]: 'Performance',
    [resourceLabel]: 'Resource',
    [anomalyLabel]: 'Anomaly',
    [penaltiesLabel]: 'Penalties',
};
