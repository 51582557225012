/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import {
    IMustChecksInfo,
    IUpgradeStatusInfo,
    NodeType,
    UpgradeFsmState,
    UpgradeOpsType,
} from 'generated-types';

import { Subscription } from 'rxjs';

import {
    UpgradeNodeCollection,
} from 'ajs/modules/upgrade/factories/upgrade-node-collection.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { UpgradeStateGroup } from 'ng/modules/update/update.types';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { UpdateStore } from '../update.store';
import * as l10n from './prechecks-progress-modal.l10n';

import './prechecks-progress-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Map of status labels to be set based on the precheck state.
 */
const precheckStatusMap = new Map([
    [UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED, l10nKeys.precheckPendingLabel],
    [UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS, l10nKeys.precheckInProgressStatusLabel],
    [UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS, l10nKeys.precheckSuccessStatusLabel],
    [UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR, l10nKeys.precheckErrorStatusLabel],
    [UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING, l10nKeys.precheckWarningStatusLabel],
]);

/**
 * Interface for representing status of upgradeNode.
 */
interface IUpgradeNodeStatus {
    name: string,
    status: UpgradeFsmState,
    type: NodeType,
}

/**
 * @description Modal Component to display Controller/SE-group
 * precheck progress and transcript.
 * @author Nisar Nadaf
 */
@Component({
    selector: 'prechecks-progress-modal',
    templateUrl: './prechecks-progress-modal.component.html',
})
export class PrechecksProgressModalComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Fire on modal close.
     */
    @Output()
    public onClose: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Fire on update operation.
     */
    @Output()
    public onUpdate = new EventEmitter<boolean>();

    /**
     * Hide upgrade action from modal.
     */
    @Input()
    public readonly = false;

    /**
     * Ids of node for which prechecks needs to be shown.
     */
    @Input()
    public selectedSegIds: string[];

    @Input()
    public upgradeNodeCollection: UpgradeNodeCollection;

    /**
     * Upgrade Node version.
     */
    public version: string;

    /**
     * Status of each node selected for upgrade.
     */
    public upgradeNodeStatus: IUpgradeNodeStatus[] = [];

    /**
     * upgrade operation (upgrade/patch/rollback) label's l10nKey.
     */
    public upgradeOperationLabel: string;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public precheckList: IMustChecksInfo[];

    public preCheckState: UpgradeFsmState;

    public selectedNodesGridConfig: IAviDataGridConfig;

    /**
     * Transcript to be shown in readonly text-area.
     */
    public transcript: string;

    /**
     * Checkbox to proceed upgrade operation in case of warning.
     */
    public suppressWarning = false;

    /**
     * Text to be shown in modal header.
     */
    public modalHeaderText: string;

    /**
     * Upgrade status hash.
     */
    public readonly upgradeStateHash = {
        ERROR: UpgradeStateGroup.GROUP_ERROR,
        SUCCESS: UpgradeStateGroup.GROUP_COMPLETED,
    };

    /**
     * Precheck state hash.
     */
    public readonly upgradeFsmState = {
        ERROR: UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR,
        IN_PROGRESS: UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS,
        SUCCESS: UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS,
        WARNING: UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING,
        STARTED: UpgradeFsmState.UPGRADE_PRE_CHECK_STARTED,
    };

    /**
     * Hash of UpgradeOpsType and its l10n keys.
     */
    private readonly upgradeOpsHash = {
        [UpgradeOpsType.PATCH]: this.l10nKeys.patchLabel,
        [UpgradeOpsType.ROLLBACK]: this.l10nKeys.rollbackLabel,
        [UpgradeOpsType.ROLLBACKPATCH]: this.l10nKeys.rollbackPatchLabel,
        [UpgradeOpsType.UPGRADE]: this.globalL10nKeys.upgradeLabel,
        [UpgradeOpsType.EVAL_PATCH]: this.l10nKeys.patchLabel,
        [UpgradeOpsType.EVAL_ROLLBACK]: this.l10nKeys.rollbackLabel,
        [UpgradeOpsType.EVAL_ROLLBACKPATCH]: this.l10nKeys.rollbackPatchLabel,
        [UpgradeOpsType.EVAL_UPGRADE]: this.globalL10nKeys.upgradeLabel,
    };

    /**
     * Subcription to get updates on prechecks changes.
     */
    private preCheckloadSubscription: Subscription;

    /**
     * Subcription to get updates on completion of Prechecks.
     */
    private preChecksCompletedSubscription: Subscription;

    public constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
        private readonly updateStore: UpdateStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.handlePreChecksUpdate();

        this.modalHeaderText = this.getModalHeaderText();

        this.preCheckloadSubscription = this.updateStore
            .preChecksUpdate.subscribe(() => this.handlePreChecksUpdate());

        this.preChecksCompletedSubscription = this.updateStore
            .preChecksCompleted$.subscribe(
                (preChecksCompleted: boolean) => {
                    if (preChecksCompleted) {
                        this.onPreChecksCompletion();
                    }
                },
            );
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            schemaService,
        } = this;

        this.selectedNodesGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.nodeNameLabel),
                    id: 'name',
                    transform: (row: IUpgradeNodeStatus) => row.name,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.typeLabel),
                    id: 'type',
                    transform: (row: IUpgradeNodeStatus) =>
                        schemaService.getEnumValueLabel(
                            'NodeType',
                            row.type,
                        ),
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.statusLabel),
                    id: 'status',
                    transform: (row: IUpgradeNodeStatus) =>
                        l10nService.getMessage(precheckStatusMap.get(row.status)),
                },
            ],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Get modal header text.
     * returns internationalized text containing upgrade operation with version info.
     */
    public getModalHeaderText(): string {
        if (this.upgradeNodeStatus?.length) {
            const headerLabel = this.l10nService.getMessage(this.l10nKeys.headerLabel, [
                this.version,
            ]);

            const upgradeOperationLabel = this.l10nService.getMessage(this.upgradeOperationLabel);

            return `${upgradeOperationLabel} ${headerLabel}`;
        }

        return '';
    }

    /**
     * @override
     * Unsubscribes from selectors.
     */
    public ngOnDestroy(): void {
        this.preCheckloadSubscription.unsubscribe();
        this.preChecksCompletedSubscription.unsubscribe();
    }

    /**
     * Get upgrade transcript as formated string.
     */
    public getTranscript(selectedNodeIds: string[]): string {
        const selectedNodes = new Set(selectedNodeIds);
        const allNodesconfig = this.upgradeNodeCollection.getConfig();

        const filteredConfig = allNodesconfig
            .filter((config: IUpgradeStatusInfo) => selectedNodes.has(config.uuid));

        return JSON.stringify(filteredConfig, null, 4);
    }

    /**
     * Handler for modal close.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Handler for upgrade button click.
     */
    public handleSubmit(isWarning = true): void {
        this.onUpdate.emit(isWarning);
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Called on precheck operation completion. Call submit handler if precheck succeed.
     */
    private onPreChecksCompletion(): void {
        if (this.preCheckState === UpgradeFsmState.UPGRADE_PRE_CHECK_SUCCESS) {
            this.handleSubmit(false);
        }
    }

    /**
     * Handler for precheck updates. Set precheck related values used in modal.
     */
    private handlePreChecksUpdate(): void {
        const selectedNodeIds = this.selectedSegIds?.length ?
            this.selectedSegIds : this.upgradeNodeCollection.getItemIds();

        this.setPrecheckValues(selectedNodeIds);
    }

    /**
     * Set precheck values of given nodes from collection.
     */
    private setPrecheckValues(selectedNodeIds: string[]): void {
        this.upgradeNodeStatus = [];
        this.preCheckState = this.upgradeNodeCollection
            .getSelectedSegsOverallState(selectedNodeIds);

        const controllerNode = this.upgradeNodeCollection.getControllerNode() ||
            this.upgradeNodeCollection.getItemById(selectedNodeIds[0]);

        selectedNodeIds.forEach((nodeId: string) => {
            const node = this.upgradeNodeCollection.getItemById(nodeId);

            this.setHeaderGridValues(node.getName(), node.nodeType, node.upgradeReadinessState);
        });

        const {
            upgrade_ops: upgradeOps = UpgradeOpsType.UPGRADE,
        } = controllerNode.upgradeReadiness;

        this.version = controllerNode.getImageRefVersion() || controllerNode.getVersion(true);
        this.precheckList = this.upgradeNodeCollection.getConsolidatedPreChecks(selectedNodeIds);
        this.transcript = this.getTranscript(selectedNodeIds);
        this.upgradeOperationLabel = this.upgradeOpsHash[upgradeOps];
    }

    /**
     * Set selected node details to show in the grid.
     */
    private setHeaderGridValues(
        nodeName: string,
        nodeType: NodeType,
        state: UpgradeFsmState,
    ): void {
        this.upgradeNodeStatus.push({
            name: nodeName,
            type: nodeType,
            status: state,
        });
    }
}
