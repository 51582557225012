/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { GslbPoolMember } from 'object-types';
import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { GSLBService } from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';
import { StringService } from 'string-service';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-pool-member-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the GSLB Pool Member configuartion.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-pool-member-modal',
    templateUrl: './gslb-service-pool-member-modal.component.html',
})
export class GslbServicePoolMemberModalComponent implements OnInit {
    @Input()
    public editable: GslbPoolMemberConfigItem;

    /**
     * True if Pool is being edited.
     */
    @Input()
    public editMode = false;

    /**
     * Reference for GSLBService.
     */
    @Input()
    public gslbService: GSLBService;

    /**
     * True if GSLB algorithm is preference order.
     */
    @Input()
    public isGslbAlgorithmPreferenceOrder: boolean;

    /**
     * Previously selected pool members VsIp list.
     * To check if the newly selected VsIp is already present in the list.
     */
    @Input()
    public previousSelectedPoolMembersVsIps: Set<string>;

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<GslbPoolMemberConfigItem>();

    /**
     * True if selected VsIp is already present.
     */
    public hasDuplicateVsIp = false;

    /**
     * Flag indicating pool member configuration type.
     * We have 2 types IP address and virtual service.
     */
    public isConfiguredByTypeIp = false;

    public readonly objectType = GslbPoolMember;

    public nonAviSitesDropdownOptions: IAviDropdownOption[] = [];

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly stringService: StringService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.isConfiguredByTypeIp = this.editable.isConfiguredByTypeIp;
        this.editable.gslbLocationAfterLoad();

        const nonAviSites = this.gslbService.getGSLB()?.getNonAviSites();

        this.nonAviSitesDropdownOptions = nonAviSites.map(nonAviSite => createDropdownOption(
            this.stringService.slug(nonAviSite),
            this.stringService.name(nonAviSite),
        ));
    }

    /**
     * Check if selected VsIp is already present in
     * previously selected pool members list.
     */
    public checkSelectedVsIp(vsIp: string): void {
        this.hasDuplicateVsIp = this.previousSelectedPoolMembersVsIps.has(vsIp);
    }

    /**
     * Handle GSLB Pool Member Submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Handle GSLB Pool Member Cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
