<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header
            headerText="{{ l10nKeys.headerLabel | vtranslate }}"
            [showEditButton]="isGslbConfigured && gslb.isEditable()"
            [disableClick]="!isGslbConfigured"
            buttonLabelText="{{ globalL10nKeys.editLabel | vtranslate }}"
            buttonToolTip="{{ l10nKeys.editGslbSetting | vtranslate }}"
            (onButtonClick)="openGslbSettingsModal()"
        ></avi-page-header>
        <div
            class="gslb-sites-list-page__title"
            [innerHTML]="l10nKeys.gridHeader | vtranslate
                : replicationModeLabel
                : allSitesList.length"
        ></div>
        <avi-data-grid
            [config]="gslbSitesGridConfig"
            [rows]="allSitesList"
            [isLoading]="busy"
        >
            <avi-data-grid_actions *ngIf="!isGslbConfigured || gslb.isEditable()">
                <avi-dropdown-button
                    [actions]="createSiteActions"
                    [position]="actionPosition"
                    size="sm"
                >
                    {{ globalL10nKeys.createLabel | vtranslate }}
                </avi-dropdown-button>
            </avi-data-grid_actions>
        </avi-data-grid>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #replicationStatusTemplateRef
    let-row
    let-index="index"
>
    <cds-icon
        shape="warning-standard"
        status="warning"
        *ngIf="showWarningIcon(row)"
    ></cds-icon>
    {{ getSyncStateLabel(row) }}
</ng-template>

<ng-template
        #siteNameTemplateRef
        let-row
>
    <div class="gslb-sites-list-page__grid-name-column">
        <avi-tooltip-icon-with-content-wrapper
            shape="circle"
            size="18"
            [status]="row.runtime.site_info.oper_status.state === OPER_UP_STATE ?
                statusIconStateHash.SUCCESS : statusIconStateHash.DANGER"
            [solid]="true"
        >
            {{ row.runtime.site_info.oper_status.reason.join() }}
        </avi-tooltip-icon-with-content-wrapper>
        <span>{{ row.config.config.name }}</span>
    </div>
</ng-template>

<ng-template
    #dnsVsTemplateRef
    let-row
>
    <div *ngIf="isGslbSiteRuntime(row.runtime); else notApplicableTempateRef">
        <ng-container *ngIf="getDnsVsNames(row.runtime.site_info.dns_info) as data">
            <ng-container *ngTemplateOutlet="valuewithColumnSuffixTemplateRef; context: { $implicit: data }">
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #ipAddrTemplateRef
    let-row
>
    <div *ngIf="isGslbSiteRuntime(row.runtime); else notApplicableTempateRef">
        <ng-container *ngIf="row.config.ipAddress() as data">
            <ng-container *ngTemplateOutlet="valuewithColumnSuffixTemplateRef; context: { $implicit: data }">
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #valuewithColumnSuffixTemplateRef
    let-data
>
    <span>{{ data?.[0] || '-' }}</span>
    <!-- To show '+ more' message and tooltip for remaining values -->
    <span
        *ngIf="data.length > 1"
        aviTitle="{{ data.slice(1) }}"
    >
        {{ l10nKeys.columnValueSuffix | vtranslate : data.length - 1  }}
    </span>
</ng-template>

<ng-template #notApplicableTempateRef>
    {{ globalL10nKeys.notApplicableLabel | vtranslate }}
</ng-template>
