<div
    class="ds-rate-limiters-grid"
    provideParentForm
    >
    <avi-data-grid
        [config]="dsRateLimitersGridConfig"
        [rows]="rateLimiters"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addRateLimiter()"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #rateLimiterNameTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    name="{{ 'name_' + index }}"
                    type="text"
                    clrInput
                    placeholder="{{ l10nKeys.namePlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.name"
                    required
                />
            </avi-input-container>
        </ng-template>
        <ng-template
            #rateLimiterCountTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container
                noMarginTop
                helperText="{{ l10nKeys.maximumInnerText | vtranslate }}"
            >
                <input
                    name="{{ 'count_' + index }}"
                    type="number"
                    clrInput
                    placeholder="{{ l10nKeys.countPlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.count"
                    configFieldInputValidation
                    objectType="RateLimiter"
                    fieldName="count"
                    required
                />
            </avi-input-container>
        </ng-template>
        <ng-template
            #rateLimiterBurstSizeTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container
                noMarginTop
                helperText="{{ l10nKeys.maximumInnerText | vtranslate }}"
            >
                <input
                    name="{{ 'burst_sz_' + index }}"
                    type="number"
                    clrInput
                    placeholder="{{ l10nKeys.burstSizePlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.burst_sz"
                    configFieldInputValidation
                    objectType="RateLimiter"
                    fieldName="burst_sz"
                />
            </avi-input-container>
        </ng-template>
        <ng-template
            #rateLimiterPeriodTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container
                noMarginTop
                helperText="{{ l10nKeys.secondsInnerText | vtranslate }}"
            >
                <input
                    name="{{ 'period_' + index }}"
                    type="number"
                    clrInput
                    placeholder="{{ l10nKeys.periodPlaceholder | vtranslate }}"
                    [(ngModel)]="row.config.period"
                    configFieldInputValidation
                    objectType="RateLimiter"
                    fieldName="period"
                    required
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
