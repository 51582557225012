/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import eventemitter2 from 'eventemitter2';

/**
 * @fileoverview Injectable wrapper for EventEmitter2 library.
 * {https://github.com/asyncly/EventEmitter2}
 */
angular.module('aviApp').factory('EventEmitter2', function() {
    return eventemitter2;
});
