/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    extend,
    IPromise,
    IQService,
} from 'angular';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { WAF_POLICY_ITEM_TOKEN } from 'ajs/modules/waf/waf.tokens';
import { WafPolicy } from 'object-types';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import { StringService } from 'string-service';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

/**
 * constant for feild crs_groups.
 */
const CRS_GROUPS_FIELD = 'crs_groups';

/**
 * Type for WafPolicyCollection collection.
 */
export type TWafPolicyCollection = typeof WafPolicyCollection;

/**
 * @description Waf Policy Collection class
 *
 * @author Hitesh Mandav
 */
export class WafPolicyCollection extends Collection {
    public static ajsDependencies = [
        WAF_POLICY_ITEM_TOKEN,
        'stringService',
        'defaultValues',
        'Collection',
        '$q',
    ];

    /**
     * Default Values for Waf policy.
     */
    public readonly defaultValues: DefaultValues;

    /**
     * StringService instance, for recieving slug part of URL.
     */
    protected readonly stringService: StringService;

    /**
     * $q Dependency used for overriding server Default values.
     */
    protected readonly $q: IQService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpolicy',
            windowElement: 'lazy-load',
            objectType: WafPolicy,
            permissionName: AviPermissionResource.PERMISSION_WAFPOLICY,
            ...args,
        };

        super(extendedArgs);
        this.itemClass_ = this.getAjsDependency_(WAF_POLICY_ITEM_TOKEN);
        this.stringService = this.getAjsDependency_('stringService');
        this.$q = this.getAjsDependency_('$q');
        this.defaultValues = this.getAjsDependency_('defaultValues');
    }

    /**
     * Used to set serverDefaultsOverride_ with the system default Waf Policy.
     */
    public setServerDefaultsOverride(): IPromise<void> {
        this.serverDefaultsOverride_ = this.serverDefaultsOverride_ || {};

        if (CRS_GROUPS_FIELD in this.serverDefaultsOverride_) {
            return this.$q.when();
        } else {
            const defaultWafPolicyRef = this.defaultValues.getSystemObjectRefByName(
                'wafpolicy',
                'System-WAF-Policy',
            );
            const WafPolicyItem = this.itemClass_;
            const defaultWafPolicy = new WafPolicyItem({
                id: this.stringService.slug(defaultWafPolicyRef),
            });

            return defaultWafPolicy.load()
                .then(() => {
                    const {
                        waf_crs_ref_data: defaultWafCrsGroups,
                        waf_crs_ref: defaultWafCrsRef,
                    } = defaultWafPolicy.getConfig();

                    extend(this.serverDefaultsOverride_, {
                        waf_crs_ref: defaultWafCrsRef,
                        waf_crs_ref_data: defaultWafCrsGroups.getConfig(),
                    });
                });
        }
    }

    /**
     * Used for opening create modal in waf policy.
     */
    public create(windowElement: string, params: object): IPromise<any> {
        return this.setServerDefaultsOverride().then(() => super.create(windowElement, params));
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-policy-modal.component'
        );

        return WafPolicyModalComponent as Type<Component>;
    }
}
