/**
 * @module VsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IIpAddr } from 'generated-types';
import {
    InventoryDataTransformer,
} from 'ajs/modules/data-model/factories/inventory-data-transformer.factory';
import { OperStateToTypeMap } from '../vs.constants';
import { IExtendedVipRuntimeDetail, OperStateType } from '../vs.types';
import { IVsVipData, VsVip } from './vs-vip.item.factory';

interface IVipTooltipData extends IVipData {
    operStateType: OperStateType,
}

interface IVipData {
    ip_address: IIpAddr,
    ip6_address: IIpAddr,
}

/**
 * @desc
 *
 *   DataTransformer for the VsVipCollection. Currently runtime object contains vip_id and
 *   other runtime properties such as oper_status etc. Here we populate runtime object with
 *   ip_address from vip object for the matching oper_statuses.
 *
 * @author Ram Pal
 */
export class VsVipInventoryDataTransformer extends InventoryDataTransformer {
    public responseListOffsetControl_: (resp: any, request: any) => any;

    /** @override */
    public processResponse(resp: any, request: any): any {
        resp = this.responseListOffsetControl_(resp, request);

        const { data: { results } } = resp;

        if (Array.isArray(results)) {
            results.forEach((vsVip: IVsVipData) => {
                const { runtime: vipsRuntime } = vsVip;

                vipsRuntime.forEach((vipRuntime: IExtendedVipRuntimeDetail) => {
                    Object.defineProperties(vipRuntime, {
                        ip_address: {
                            get(): IIpAddr {
                                return this.getVipTooltipData().ip_address;
                            },
                        },
                        ip6_address: {
                            get(): IIpAddr {
                                return this.getVipTooltipData().ip6_address;
                            },
                        },
                        operStateType: {
                            get(): OperStateType {
                                return this.getVipTooltipData().operStateType;
                            },
                        },
                        getVipTooltipData: {
                            value(): IVipTooltipData {
                                const { config: { vip: { config: vips } } } = this.vsVip;
                                const { oper_status: { state }, vip_id: vipId } = vipRuntime;
                                const operStateType = OperStateToTypeMap.get(state);

                                const vipIpInfo: IVipData = vips?.reduce((
                                    vipData: IVipData,
                                    {
                                        config: {
                                            vip_id: vsVipId, ip_address, ip6_address,
                                        },
                                    }: VsVip,
                                ) => {
                                    return vsVipId !== vipId ? vipData : {
                                        ip_address,
                                        ip6_address,
                                    };
                                }, undefined);

                                return {
                                    operStateType,
                                    ...vipIpInfo,
                                };
                            },
                        },
                    });

                    vipRuntime.vsVip = vsVip;
                });
            });
        }

        return resp;
    }
}
