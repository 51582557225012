<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3> {{ l10nKeys.sectionTitleGeneral | vtranslate }} </h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        class="sel-name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        required
                        [disabled]="editable.isProtected()"
                        regexPattern="objName"
                        [(ngModel)]="editable.config.name"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="action_script"
                            for="action-script"
                            required
                        >
                            {{ l10nKeys.controlScriptInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            [(ngModel)]="editable.getConfig().action_script"
                            placeholder="{{ l10nKeys.controlScriptInputPlaceholder | vtranslate }}"
                            name="action-script"
                            required
                        >
                        </file-upload-textarea>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
