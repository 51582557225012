/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IcapProfileCollection,
    IcapProfileItem,
} from 'ajs/modules/icap-profile/factories';

import { StringService } from 'string-service';

import {
    SchemaService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core/services/schema-service';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './icap-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const { ...globalL10nKeys } = globalL10n;

type TIcapProfileCollection = typeof IcapProfileCollection;

/**
 * @description
 *
 *   ICAP Profiles list page component.
 *
 * @author Aravindh Nagarajan, Chetana Padmane
 */
@Component({
    selector: 'icap-profile-list-page',
    templateUrl: './icap-profile-list-page.component.html',
})
export class IcapProfileListPageComponent implements OnInit, OnDestroy {
    /**
     * Icap-profile collection-grid config.
     */
    public icapProfileGridConfig: IAviCollectionDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the auth profile collection.
     */
    private readonly icapProfileCollection: IcapProfileCollection;

    /**
     * Hash of failAction enum values to their labels.
     */
    private readonly failActionEnumValueLabelsHash: TEnumValueLabelsHash;

    /**
     * Hash of icapVendor enum values to their labels.
     */
    private readonly icapVendorEnumValueLabelsHash: TEnumValueLabelsHash;

    constructor(
        schemaService: SchemaService,
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
        @Inject(IcapProfileCollection)
        IcapProfileCollection: TIcapProfileCollection,
    ) {
        this.failActionEnumValueLabelsHash = schemaService.getEnumValueLabelsHash('ICAPFailAction');
        this.icapVendorEnumValueLabelsHash = schemaService.getEnumValueLabelsHash('ICAPVendor');

        l10nService.registerSourceBundles(dictionary);

        this.icapProfileCollection = new IcapProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.initIcapProfileGrid();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.icapProfileCollection.destroy();
    }

    /**
     * Initializes ICAP-Profile collection-grid.
     */
    private initIcapProfileGrid(): void {
        const { objectName } = this.icapProfileCollection;

        this.icapProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.icapProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                transform: (row: IcapProfileItem) => row.getName(),
            }, {
                id: 'service_uri',
                label: this.l10nService.getMessage(l10nKeys.columnTitleServiceUri),
                transform: (row: IcapProfileItem) => row.getConfig().service_uri,
            }, {
                id: 'pool_group_ref',
                label: this.l10nService.getMessage(l10nKeys.columnTitlePoolGroup),
                transform: (row: IcapProfileItem) =>
                    this.stringService.name(row.getConfig().pool_group_ref),
            }, {
                id: 'fail_action',
                label: this.l10nService.getMessage(l10nKeys.columnTitleFailAction),
                transform: (row: IcapProfileItem) =>
                    this.failActionEnumValueLabelsHash[row.getConfig().fail_action],
            }, {
                id: 'vendor',
                label: this.l10nService.getMessage(l10nKeys.columnTitleVendor),
                transform: (row: IcapProfileItem) =>
                    this.icapVendorEnumValueLabelsHash[row.getConfig().vendor],
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }
}
