<avi-state-page>
    <avi-state-page_main-content>
        <ng-container *ngIf="isAviCloudConnected && isControllerRegistered && isPulseCaseManagementEnabled">
            <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
            <avi-collection-data-grid [hideDelete]="true" [config]="caseListGridConfig"></avi-collection-data-grid>
        </ng-container>
        <cds-alert-group
            status="danger"
            *ngIf="!isControllerRegistered"
        >
            <cds-alert [closable]="true">
                {{ l10nKeys.registerControllerPromptLabel | vtranslate }}
                <cds-alert-actions>
                    <cds-button (click)="redirectToCloudServicesPage()">
                        {{ l10nKeys.registerNowBtnLabel | vtranslate }}
                    </cds-button>
                </cds-alert-actions>
            </cds-alert>
        </cds-alert-group>
        <cds-alert-group
            status="danger"
            *ngIf="!isPulseCaseManagementEnabled"
        >
            <cds-alert [closable]="true">
                {{ l10nKeys.enableCaseManagementPromptLabel | vtranslate }}
                <cds-alert-actions>
                    <cds-button (click)="redirectToCloudServicesPage()">
                        {{ l10nKeys.goToCloudServicesBtnLabel | vtranslate }}
                    </cds-button>
                </cds-alert-actions>
            </cds-alert>
        </cds-alert-group>
        <cds-alert-group
            status="danger"
            *ngIf="!isAviCloudConnected && isControllerRegistered"
        >
            <cds-alert [closable]="true">
                {{ l10nKeys.pulseMustBeConnectedLabel | vtranslate }}
                <cds-alert-actions>
                    <cds-button (click)="redirectToCloudServicesPage()">
                        {{ l10nKeys.connectNowBtnLabel | vtranslate }}
                    </cds-button>
                </cds-alert-actions>
            </cds-alert>
        </cds-alert-group>
    </avi-state-page_main-content>
</avi-state-page>
