/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import { L4PolicySetCollection }
    from 'ajs/modules/l4-policy/factories/l4-policy-set-collection.factory';

import { L4_POLICY_SET_COLLECTION_TOKEN }
    from 'ajs/modules/l4-policy/l4-policy.tokens';

const $injector = '$injector';

type TL4PolicySetCollection = typeof L4PolicySetCollection;

export const l4policySetCollectionProvider = {
    deps: [$injector],
    provide: L4PolicySetCollection,
    useFactory(injector: Injector): TL4PolicySetCollection {
        return injector.get(L4_POLICY_SET_COLLECTION_TOKEN);
    },
};
