/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-pages';
const componentName = 'error-page';
const prefix = `${moduleName}.${componentName}`;

export const unauthorizedLabel = `${prefix}.unauthorizedLabel`;
export const cspLabel = `${prefix}.cspLabel`;
export const unAuthorizedAccessHeaderLabel = `${prefix}.unAuthorizedAccessHeaderLabel`;

export const ENGLISH = {
    [unauthorizedLabel]: 'User is not Authorized to access the controller. Retry Logging in to',
    [cspLabel]: 'CSP',
    [unAuthorizedAccessHeaderLabel]: 'Unauthorized Access',
};
