<update-card class="update-progress-card">
    <update-card__progress-info
        class="update-progress-card__update-progress-info"
        [ngClass]="{'update-progress-card__update-progress-info__no-progress': isNoUpgradeInProgress}"
    >
        <div *ngIf="preChecksInProgress">
            <clr-spinner
                class="update-progress-card__update-progress-info__loader"
                [clrSmall]="true"
            ></clr-spinner>
            <span
                *ngIf="isControllerUpgrade"
                class="update-progress-card__update-progress-info__prechecks-info"
            >
                {{ l10nKeys.controllerUpdatingToVersionHeader | vtranslate : controllerVersion }}
            </span>
            <span
                *ngIf="!isControllerUpgrade"
                class="update-progress-card__update-progress-info__prechecks-info"
            >
                {{ l10nKeys.segUpdatingToVersionHeader | vtranslate : segVersion }}
            </span>
            <div class="update-progress-card__update-progress-info__prechecks-message">{{ l10nKeys.runningPreChecksLabel | vtranslate }}</div>
        </div>
        <div
            *ngIf="isControllerUpgrade && isControllerUpgradeInProgress"
            [ngClass]="{'update-progress-card__update-progress-info__update-progress': isSeGroupUpgradeInProgress}"
        >
            <avi-progress-bar
                class="update-progress-card__update-progress-info__progress-bar"
                message="{{ l10nKeys.completeLabel | vtranslate }}"
                type="message"
                [small]="false"
                header="{{ l10nKeys.controllerUpdatingToVersionHeader | vtranslate : controllerVersion }}"
                [percentage]="controllerUpgradePercentage"
            ></avi-progress-bar>
        </div>
        <div
            *ngIf="isSeGroupUpgradeInProgress"
            class="update-progress-card__update-progress-info__subsection"
            [ngClass]="{'update-progress-card__update-progress-info__subsection--no-margin-top': !isControllerUpgrade}"
        >
            <clr-spinner
                [clrSmall]="true"
                class="update-progress-card__update-progress-info__subsection__loader"
            ></clr-spinner>
            <div class="update-progress-card__update-progress-info__subsection__seg-info">
                <div class="update-progress-card__update-progress-info__subsection__seg-info__label">
                    {{ l10nKeys.segUpdatingToVersionHeader | vtranslate : segVersion }}
                </div>
                <div class="update-progress-card__update-progress-info__subsection__seg-info__status">
                    {{ l10nKeys.seGroupLeftPluralLabel | vtranslate : seGroupCount }}
                </div>
            </div>
        </div>
        <div
            *ngIf="fipsOperational === fipsOperationalHash.FIPS_UNKNOWN"
            class="update-progress-card__update-progress-info__subsection"
        >
            <clr-spinner
                class="update-progress-card__update-progress-info__subsection__loader"
                [clrSmall]="true"
            ></clr-spinner>
            <span class="update-progress-card__update-progress-info__subsection__text-info">{{ l10nKeys.clusterWarmRebootingLabel | vtranslate }}</span>
        </div>
        <div *ngIf="isNoUpgradeInProgress"> {{ l10nKeys.noOperationsInProgress | vtranslate }}</div>
    </update-card__progress-info>
    <update-card__footer>
        <cds-button
            action="flat"
            size="sm"
            [disabled]="disableViewProgressButton"
            (click)="openProgressModal()"
        >
            {{ l10nKeys.viewProgressLabel | vtranslate }}
        </cds-button>
    </update-card__footer>
</update-card>
