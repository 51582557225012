<div class="waf-add-exception-dialog clr-wrapper">
    <form
        clrForm
        #form="ngForm"
        [wafAddExceptionDialogForm]="excludeListCount - newExceptionsIndex"
    >
        <avi-confirmation
            size="lg"
            (onClose)="handleCancel()"
            headerText="{{ l10nKeys.addExceptionHeader | vtranslate }}"
        >
            <ng-container ngProjectAs="avi-confirmation_modal-content">
                <div
                    *ngIf="!busy; else loader"
                    class="waf-add-exception-dialog__content-container"
                >
                    <avi-alert-group
                        *ngIf="error"
                        alertType="danger"
                        class="waf-add-exception-dialog__error"
                    >
                        {{ error }}
                    </avi-alert-group>
                    <ng-container *ngIf="excludeList">
                        <ng-container *ngIf="groupName">
                            <label
                                aviLabelWithTooltip
                                for="group-name"
                            >
                                {{ l10nKeys.groupNameLabel | vtranslate }}
                            </label>
                            <div class="waf-add-exception-dialog__name">
                                {{ groupName }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wafRuleName">
                            <label aviLabelWithTooltip>
                                {{ l10nKeys.ruleNameLabel | vtranslate }}
                            </label>
                            <div class="waf-add-exception-dialog__name">
                                {{ wafRuleName }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="newExceptionsIndex > 0">
                            <label aviLabelWithTooltip>
                                {{ l10nKeys.existingExceptionsLabel | vtranslate }}
                            </label>
                            <div
                                class="waf-add-exception-dialog__exclusion"
                                *ngFor="let exclusion of excludeList.config | slice:0:newExceptionsIndex; trackBy: trackByIndex"
                            >
                                <span class="waf-add-exception-dialog__existing-value">
                                    {{ exclusion.getSubnet() ?? (l10nKeys.anySubnetLabel | vtranslate | uppercase) }}
                                </span>
                                <span class="waf-add-exception-dialog__separator">
                                    {{ l10nKeys.andLabel | vtranslate }}
                                </span>
                                <span class="waf-add-exception-dialog__existing-value">
                                    {{ exclusion.config.uri_path ?? (l10nKeys.anyPathLabel | vtranslate | uppercase) }}
                                </span>
                                <span class="waf-add-exception-dialog__separator">
                                    {{ l10nKeys.andLabel | vtranslate }}
                                </span>
                                <span class="waf-add-exception-dialog__existing-value">
                                    {{ exclusion.config.match_element ?? (l10nKeys.anyMatchLabel | vtranslate | uppercase) }}
                                </span>
                            </div>
                        </ng-container>
                        <label aviLabelWithTooltip>
                            {{ l10nKeys.newExceptionsLabel | vtranslate }}
                        </label>
                        <ng-container *ngIf="excludeListCount > newExceptionsIndex; else noNewExceptionsTemplate">
                            <div
                                class="waf-add-exception-dialog__row"
                                *ngFor="let newExclusion of excludeList.config | slice:newExceptionsIndex; let i = index; trackBy: trackByIndex"
                            >
                                <div class="waf-add-exception-dialog__control">
                                    <avi-input-container noMarginTop>
                                        <input
                                            clrInput
                                            id="exception-subnet-{{ i }}"
                                            name="exception-subnet-{{ i }}"
                                            placeholder="{{ l10nKeys.anySubnetLabel | vtranslate }}"
                                            [(ngModel)]="newExclusion.config.client_subnet.formValue"
                                            regexPattern="anySubnet"
                                        />
                                    </avi-input-container>
                                </div>
                                <span class="waf-add-exception-dialog__separator">
                                    {{ l10nKeys.andLabel | vtranslate }}
                                </span>
                                <div class="waf-add-exception-dialog__control">
                                    <avi-input-container noMarginTop>
                                        <input
                                            clrInput
                                            id="uri-path-{{ i }}"
                                            name="uri-path-{{ i }}"
                                            placeholder="{{ l10nKeys.anyPathLabel | vtranslate }}"
                                            [(ngModel)]="newExclusion.config.uri_path"
                                        />
                                    </avi-input-container>
                                </div>
                                <span class="waf-add-exception-dialog__separator">
                                    {{ l10nKeys.andLabel | vtranslate }}
                                </span>
                                <div class="waf-add-exception-dialog__control">
                                    <avi-input-container noMarginTop>
                                        <input
                                            clrInput
                                            id="match-element-{{ i }}"
                                            name="match-element-{{ i }}"
                                            placeholder="{{ l10nKeys.anyMatchLabel | vtranslate }}"
                                            [(ngModel)]="newExclusion.config.match_element"
                                            regexPattern="wafExclusionMatchElement"
                                        />
                                    </avi-input-container>
                                </div>
                                <span class="waf-add-exception-dialog__separator">
                                    <cds-icon
                                        class="waf-add-exception-dialog__trash"
                                        shape="trash"
                                        size="18"
                                        (click)="removeNewException(i)"
                                    ></cds-icon>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container ngProjectAs="avi-confirmation_modal-actions">
                <cds-button
                    action="outline"
                    (click)="handleCancel()"
                >
                    {{ globalL10nKeys.cancelLabel | vtranslate }}
                </cds-button>
                <cds-button
                    [disabled]="busy || form.invalid || excludeListCount === newExceptionsIndex || nonexistentRuleOrGroup"
                    (click)="handleSubmit()"
                >
                    {{ globalL10nKeys.saveLabel | vtranslate }}
                </cds-button>
            </ng-container>
        </avi-confirmation>
    </form>
</div>

<ng-template #noNewExceptionsTemplate>
    <div class="waf-add-exception-dialog__no-new-exception">
        {{ l10nKeys.noNewExceptionsCanBeAddedMessage | vtranslate }}
    </div>
</ng-template>

<ng-template #loader>
    <div class="waf-add-exception-dialog__spinner-container">
        <clr-spinner clrMedium></clr-spinner>
    </div>
</ng-template>
