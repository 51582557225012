/**
 * @module VsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { OperationalState } from 'generated-types';
import { OperStateType } from './vs.types';

/**
 * Constant which maps OperationalStates(OPER_RESOURCES, OPER_UNUSED, OPER_DISABLED)
 * with OperStateType(OPER_UP, OPER_DOWN, OPER_PENDING).
 */
export const OperStateToTypeMap = new Map(Object.entries({
    [OperationalState.OPER_UP]: OperStateType.OPER_UP,
    [OperationalState.OPER_DOWN]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_RESOURCES]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_ERROR_DISABLED]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_PARTITIONED]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_FAILED]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_UNAVAIL]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_UNKNOWN]: OperStateType.OPER_DOWN,
    [OperationalState.OPER_CREATING]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_INACTIVE]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_DISABLED]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_UNUSED]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_PROCESSING]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_INITIALIZING]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_AWAIT_MANUAL_PLACEMENT]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_UPGRADING]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_SE_PROCESSING]: OperStateType.OPER_PENDING,
    [OperationalState.OPER_DISABLING]: OperStateType.OPER_PENDING,
}));

/**
 * Ajs dependency token for VirtualServiceCollection.
 */
export const VIRTUAL_SERVICE_COLLECTION_TOKEN = 'VirtualServiceCollection';
