<match-display-wrapper
    class="ip-addr-match-readonly"
    matchLabel="{{ l10nKeys.clientIpAddressLabel | vtranslate }}"
    matchCriteria="{{ matchCriteria }}"
>
    <div class="ip-addr-match-readonly__values">
        <div *ngFor="let address of addressList; trackBy: trackByIndex">
            {{ address }}
        </div>
    </div>
</match-display-wrapper>
