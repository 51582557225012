<fieldset class="full-modal-config__fieldset">
    <credentials-verification
        [editCredentialsConfig]="editCloudVrfConfig"
        [hideChangeCredentialsButton]="hideChangeCredentialsButton"
        [credentialsButtonLabel]="(!disallowVrfContextSelection ? l10nKeys.credentialsButtonForCloudVrfContextLabel : l10nKeys.credentialsButtonForCloudLabel) | vtranslate"
    >
        <div class="full-modal-config__form-control-row cloud-vrf-context__cloud-ref">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ messageType }}"
                    fieldName="{{ cloudRefFieldName }}"
                    for="{{ cloudRefFieldName }}"
                >
                    {{ l10nKeys.cloudLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    [collection]="cloudCollection"
                    [(ngModel)]="cloudRef"
                    placeholder="{{ l10nKeys.notSetLabel | vtranslate }}"
                    name="{{ cloudRefFieldName }}"
                    [readonly]="true"
                ></avi-collection-dropdown>
            </div>
        </div>

        <div
            *ngIf="!disallowVrfContextSelection"
            class="full-modal-config__form-control-row"
        >
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ messageType }}"
                    fieldName="{{ vrfRefFieldName }}"
                    for="{{ vrfRefFieldName }}"
                >
                    {{ l10nKeys.vrfContextLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    [collection]="vrfContextCollection"
                    [(ngModel)]="vrfRef"
                    placeholder="{{ l10nKeys.notSetLabel | vtranslate }}"
                    name="{{ vrfRefFieldName }}"
                    [readonly]="true"
                ></avi-collection-dropdown>
            </div>
        </div>
    </credentials-verification>
</fieldset>
