<div provideParentForm>
    <div
        class="grid-container proxy-configuration-container"
    >
        <div class="grid-container__cell--w--9">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="host"
                    for="host"
                    required
                >
                    {{ l10nKeys.serverHostLabel | vtranslate }}
                </label>
                <input
                    id="host"
                    name="host"
                    clrInput
                    placeholder="{{ l10nKeys.serverHostPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.host"
                    regexPattern="hostname"
                    required    
                />
            </avi-input-container>
        </div>
        <div class="grid-container__cell--w--3">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="port"
                    for="port"
                    required
                >
                    {{ l10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    id="port"
                    name="port"
                    clrInput
                    placeholder="{{ l10nKeys.portPlaceholder | vtranslate }}"
                    regexPattern="port"
                    [(ngModel)]="editable.config.port"
                    required
                />
            </avi-input-container>
        </div>
    </div>
    <div class="grid-container proxy-configuration-container">
        <div class="grid-container__cell--w--6">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="username"
                    for="username"
                >
                    {{ l10nKeys.userNameLabel | vtranslate }}
                </label>
                <input
                    id="username"
                    name="username"
                    clrInput
                    [(ngModel)]="editable.config.username"
                    regexPattern="objName"
                    placeholder="{{ l10nKeys.usernamePlaceholder | vtranslate }}"
                />
            </avi-input-container>
        </div>
        <div class="grid-container__cell--w--6">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="password"
                    for="password"
                >
                    {{ l10nKeys.passwordLabel | vtranslate }}
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    clrInput
                    placeholder="{{ l10nKeys.passwordPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.config.password"
                />
            </avi-input-container>
        </div>
    </div>
</div>

