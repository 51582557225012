<avi-data-grid
    [config]="roleFilterGridConfig"
    [rows]="roleFilters.config"
    class="role-filter-grid"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addRoleFilter()"
            [disabled]="disableAddButton"
        >
            {{ l10nKeys.roleFilterAddButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
    <ng-template
        #roleFilterEnabledTemplateRef
        let-row
        let-index="index"
    >
        <span *ngIf="row.config.enabled">
            <cds-icon
                shape="check-circle"
                size="22"
                class="role-filter-grid__check-icon"
            ></cds-icon>
        </span>
    </ng-template>
    <ng-template
        #roleFilterValueTemplateRef
        let-row
        let-index="index"
    >
        {{ row.values[0] }} 
        <!-- To show '+ more' message and tooltip for remaining values -->
        <span
            *ngIf="row.valuesCount> 1"
            [title]="valuesTooltip(row)"
            class="role-filter-grid__value-list"
        >
            {{ l10nKeys.valueCountSuffix | vtranslate : row.valuesCount - 1  }}
        </span>
    </ng-template>
</avi-data-grid>
