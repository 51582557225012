/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import * as UserPreferencesActions from 'ng/root-store/user-preferences/user-preferences.actions';

/**
 * @ngdoc directive
 * @name chartWithOverlays
 * @restrict AE
 * @description
 *     Chart with Overlays
 *     Includes both a table and a performance chart
 *
 * @param {boolean} e2eSeriesAvailable - When false, render license upgrade message on top of chart
 *     for end_to_end metric.
 */
angular.module('aviApp').directive('chartWithOverlays', ['myAccount', STORE_TOKEN,
function(myAccount, store) {
    return {
        restrict: 'AE',
        scope: {
            item: '=',
            config: '=',
            smallChart: '=',
            e2eSeriesAvailable: '<?',
        },
        templateUrl: 'src/views/components/chartWithOverlays.html',
        link(scope) {
            scope.hide = angular.copy(myAccount.uiProperty.performanceControls) || {};

            scope.toggle = function(type) {
                scope.hide[type] = !scope.hide[type];
                scope.$broadcast('events-and-alerts-toggled');

                store.dispatch(UserPreferencesActions.updateUIProperty({
                    payload: {
                        performanceControls: scope.hide,
                    },
                }));
            };
        },
    };
}]);
