<div class="system-settings-access-system-access-card">
    <h6 class="system-settings-access-system-access-card__header">
        {{ l10nKeys.systemAccessHeader | vtranslate }}
    </h6>
    <div class="system-settings-access-system-access-card__row">
        <div class="system-settings-access-system-access-card__control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="enable_http"
                    for="http-access"
                >
                    {{ l10nKeys.httpAccessLabel | vtranslate }}
                </label>
                <input
                    [ngClass]="{'system-settings-access-system-access-card__success': httpAccess}"
                    name="http-access"
                    id="http-access"
                    placeholder="-"
                    clrInput
                    [value]="httpAccessValue"
                    readonly
                />
            </avi-input-container>
        </div>
        <div class="system-settings-access-system-access-card__control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    for="http-port"
                >
                    {{ globalL10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    name="http-port"
                    id="http-port"
                    placeholder="-"
                    clrInput
                    [ngModel]="httpPort ?? defaultHttpPort"
                    readonly
                />
            </avi-input-container>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="redirect_to_https"
            for="http-to-https-redirect"
        >
            {{ l10nKeys.httpLabel | vtranslate }} 
            <cds-icon
                shape="arrow"
                size="20"
                class="system-settings-access-system-access-card__icon"
            ></cds-icon>
            {{ l10nKeys.httpsRedirectLabel | vtranslate }}
        </label>
        <input
            [ngClass]="{'system-settings-access-system-access-card__success': httpToHttpsRedirect}"
            name="http-to-https-redirect"
            id="http-to-https-redirect"
            placeholder="-"
            clrInput
            [value]="httpToHttpsRedirectValue"
            readonly
        />
    </avi-input-container>
    <div class="system-settings-access-system-access-card__row">
        <div class="system-settings-access-system-access-card__control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    [objectType]="objectType"
                    fieldName="enable_https"
                    for="https-access"
                >
                    {{ l10nKeys.httpsAccessLabel | vtranslate }}
                </label>
                <input
                    [ngClass]="{'system-settings-access-system-access-card__success': httpsAccess}"
                    name="https-access"
                    id="https-access"
                    placeholder="-"
                    clrInput
                    [value]="httpsAccessValue"
                    readonly
                />
            </avi-input-container>
        </div>
        <div class="system-settings-access-system-access-card__control">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    for="https-port"
                >
                    {{ globalL10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    name="https-port"
                    id="https-port"
                    placeholder="-"
                    clrInput
                    [ngModel]="httpsPort ?? defaultHttpsPort"
                    readonly
                />
            </avi-input-container>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="allow_basic_authentication"
            for="allow-basic-authentication"
        >
            {{ l10nKeys.basicAuthenticationLabel | vtranslate }}
        </label>
        <input
            name="allow-basic-authentication"
            id="allow-basic-authentication"
            placeholder="{{ l10nKeys.disallowedLabel | vtranslate }}"
            clrInput
            [value]="basicAuthenticationValue"
            readonly
        />
    </avi-input-container>
</div>
