/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterPollingProgressBarModule
 */

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    Subject,
    Subscription,
} from 'rxjs';

import { L10nService } from '@vmw/ngx-vip';
import { AppLevelAlertsService } from 'ng/modules/core/services/app-level-alerts.service';
import {
    ClusterPollingProgressBarService,
} from './cluster-polling-progress-bar.service';
import * as l10n from './cluster-polling-progress-bar.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * ID for unsuccessful request app level alert.
 */
const UNSUCCESSFUL_REQUESTS_ALERT_ID = 'unsuccessful-requests';

const ERROR_CODE_503 = '503';

/**
 * @description
 *
 * Smart component to display progress bar to track cluster/upgrade polling.
 *
 * Once polling completes, Reloads the page.
 *
 * @author Aravindh Nagarajan, Guru Prasad H K, alextsg
 */
@Component({
    selector: 'cluster-polling-progress-bar',
    templateUrl: './cluster-polling-progress-bar.component.html',
    providers: [ClusterPollingProgressBarService],
})
export class ClusterPollingProgressBarComponent implements OnInit, OnDestroy {
    @Input()
    public status: string | number;

    /**
     * Readonly prop for progress-bar clrMax value.
     */
    public readonly maxProgress = 100;

    /**
     * Set flag to display progress value
     */
    public labeled = true;

    public readonly l10nKeys = l10nKeys;

    /**
     * For displaying dynamic message on the avi app level alert
     */
    private dynMessage$ = new Subject<string>();

    /**
     * Subscription to clusterLoadComplete subject.
     */
    private clusterLoadCompleteSubscription: Subscription;

    constructor(
        private readonly clusterPollingService: ClusterPollingProgressBarService,
        private readonly appLevelAlertsService: AppLevelAlertsService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.clusterPollingService.initPolling();

        this.clusterLoadCompleteSubscription =
            this.clusterPollingService.clusterLoadComplete$.subscribe(this.onClusterLoadComplete);

        if (!this.appLevelAlertsService.has(UNSUCCESSFUL_REQUESTS_ALERT_ID) &&
        this.status === ERROR_CODE_503) {
            this.dynMessage$.next();
            this.appLevelAlertsService.add({
                id: UNSUCCESSFUL_REQUESTS_ALERT_ID,
                componentProps: {
                    dynamicMessages$: this.dynMessage$,
                    status: 'warning',
                },
            });
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.clusterPollingService.stopPolling();
        this.clusterLoadCompleteSubscription.unsubscribe();
        this.dynMessage$.complete();
    }

    /**
     * Getter for cluster/upgrade progress.
     */
    public get progress(): number {
        const { upgradeProgress, clusterPollingProgress } = this.clusterPollingService;

        const progressPercent = upgradeProgress || clusterPollingProgress || 0;

        if (this.status === ERROR_CODE_503) {
            const message = this.l10nService.getMessage(
                l10nKeys.errorMsg503Description, [`${progressPercent}`],
            );

            this.dynMessage$.next(message);
        }

        return progressPercent;
    }

    /**
     * Fire when controller is up.
     */
    private onClusterLoadComplete = (): void => {
        this.clusterPollingService.reload();
    };
}
