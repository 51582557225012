/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module RightRailModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import './right-rail-vertical-expander.component.less';

/**
 * @description
 *
 *     Component for an vertically expandable list that contains a header and content sub-layers.
 *     An expander can be expanded to display its transcluded children, whether being a list
 *     of text nodes (by working with right-rail-expander-text-child) or other styles of contents.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'right-rail-vertical-expander',
    templateUrl: './right-rail-vertical-expander.component.html',
})
export class RightRailVerticalExpanderComponent {
    /**
     * Leading icon for the header.
     */
    @Input()
    public icon?: string;

    /**
     * Bold header text.
     */
    @Input()
    public headerText: string;

    /**
     * Flag for header section.
     */
    @Input()
    public showHeaderSection = true;
    /**
     * Indicator of whether showing expander children contents.
     */
    @Input()
    public expanded = false;

    /**
     * Turn this.expanded to its opposite value to control the expansion state.
     */
    public toggleExpand(): void {
        this.expanded = !this.expanded;
    }
}
