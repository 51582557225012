/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-policy-set-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleNew = `${prefix}.modalTitleNew`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const policySetNameLabel = `${prefix}.policySetNameLabel`;
export const policySetDescriptionLabel = `${prefix}.policySetDescriptionLabel`;
export const l4ConnectionPolicySectionTitle = `${prefix}.l4ConnectionPolicySectionTitle`;
export const l4RuleGridTitle = `${prefix}.l4RuleGridTitle`;
export const addRuleBtnLabel = `${prefix}.addRuleBtnLabel`;
export const nameLabel = `${prefix}.nameLabel`;
export const enterNameLabel = `${prefix}.enterNameLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const enterDescriptionLabel = `${prefix}.enterDescriptionLabel`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const editLabel = `${prefix}.editLabel`;
export const deleteLabel = `${prefix}.deleteLabel`;
export const addLabel = `${prefix}.addLabel`;
export const selectPoolActionLabel = `${prefix}.selectPoolActionLabel`;

export const ENGLISH = {
    [modalTitleNew]: 'New L4 Policy Set',
    [modalTitleEdit]: 'Edit L4 Policy Set',
    [generalSectionTitle]: 'General',
    [policySetNameLabel]: 'Name',
    [policySetDescriptionLabel]: 'Description',
    [l4ConnectionPolicySectionTitle]: 'L4 Connection Policy',
    [nameLabel]: 'Name',
    [enterNameLabel]: 'Enter Name',
    [descriptionLabel]: 'Description',
    [enterDescriptionLabel]: 'Enter Description',
    [columnTitleEnabled]: 'Enabled',
    [columnTitleName]: 'Name',
    [l4RuleGridTitle]: 'Rules',
    [editLabel]: 'Edit',
    [deleteLabel]: 'Delete',
    [addLabel]: 'Add',
    [selectPoolActionLabel]: 'Passthrough served by {0}',
};
