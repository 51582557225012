/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { isUndefined, each } from 'underscore';
import { UpdatableBase } from './updatable-base.factory';
import { ObjectTypeItem } from './object-type-item.factory';

//TODO fire events for fields, datasources, offset&limit updates?
//TODO search/sorting/params should be passed not to config only, inventory won't work wo them
//TODO busy property should be reconsidered

/**
 * @constructor
 * @param {Object=} oArgs - Configuration object, contains argument list, event listeners and
 *     more.
 * @memberOf module:avi/dataModel
 * @alias Collection
 * @extends UpdatableBase
 * @author Alex Malitsky, Ram Pal
 * @desc
 *
 *     Collection provides ability to load an ordered list of Items. Collection can use a list
 *     of CollDataSources responsible for getting different information about listed Items
 *     through different APIs. List can be ordered, filtered (by search parameter for example)
 *     and continuously update itself in certain intervals.
 *
 *     Collection works together with directives/services rendering it's layout in
 *     browser. Such services should inform Collection with the viewport size, number of hidden
 *     above the viewport's top border Items, scrolling events, list of visible Item ids, data
 *     fields it is interested in and etc so that Collection knows which part of the list should
 *     be fetched/updated with what type of data.
 *
 *     Collection also provides methods to drop (actual deletion) Items as well as create new
 *     ones.
 *
 *     Most common users of Collection are directives {@link collectionGrid},
 *     {@link collectionDropdown} with it's variations and {@link infiniteScroll}.
 */
class Collection extends UpdatableBase {
    constructor(oArgs = {}) {
        super(oArgs);

        /**
         * @type {angular.$q}
         * @protected
         */
        this.$q_ = this.getAjsDependency_('$q');

        /**
         * SystemInfoService instance.
         * @protected
         */
        this.systemInfoService_ = this.getAjsDependency_('systemInfoService');

        /**
         * Hash of instantiated items by id.
         * @type {{string: Item}}
         **/
        this.itemById = {};

        /**
         * List of instantiated items.
         * @type {Item[]}
         **/
        this.items = [];

        /**
         * Ids of currently visible Collection Items. Updated through
         * {@link Collection#updateItemsVisibility} by Collection user
         * (directive, service, etc). Used to reduce amount of fetched data.
         * @type {Item#id[]}
         * @protected
         */
        this.visibleItemIds_ = [];

        /**
         * For the grid view when we have some Items hidden above the current viewport (by
         * scrolling) and we want to know the number so that we won't fetch updates for them
         * until they become visible back again. Updated through {@link
         * Collection#updateItemsVisibility} by Collection user.
         * @type {number}
         * @protected
         */
        this.visibleItemsOffset_ = 0;

        /**
         * To allow smooth scrolling we always want to have more Items than we show and
         * preload a next set of options before user gets to the last one. Used by DataSources.
         * @type {number}
         * @protected
         */
        this.overLimitCoeff_ = typeof oArgs.overLimitCoeff === 'number' &&
            oArgs.overLimitCoeff >= 0 && _.isFinite(oArgs.overLimitCoeff) ?
            oArgs.overLimitCoeff : this.overLimitCoeff_;

        /** @inner */
        const defaultViewportSize = oArgs.limit;

        /**
         * Collection limit
         * @type {number}
         * @protected
         */
        this.limit_ = oArgs.limit || 0;

        if (typeof defaultViewportSize === 'number' && defaultViewportSize > 0 &&
            defaultViewportSize < 201) {
            this.defaultViewportSize_ = defaultViewportSize;

            //assume that we pass limit when we don't use infinite scroll
            this.overLimitCoeff_ = 0;
        } else if (typeof oArgs.params === 'object' &&
            typeof oArgs.params['page_size'] === 'number' && oArgs.params['page_size'] > 0 &&
            oArgs.params['page_size'] < 201) {
            this.defaultViewportSize_ = oArgs.params['page_size'];
        } else {
            //eslint-disable-next-line no-self-assign
            this.defaultViewportSize_ = this.defaultViewportSize_;
        }

        if (typeof oArgs.params === 'object' && 'page_size' in oArgs.params) {
            delete oArgs.params['page_size'];
        }

        /**
         * Collection user (directive, service, etc) can set the size of the viewport trough
         * {@link Collection#updateViewportSize} indicating how many items do we want to load in
         * one shot (and how many extra do we want to keep below to make scrolling seamless.
         * @type {number}
         * @protected
         */
        this.viewportSize_ = this.defaultViewportSize_;

        this.updateViewportSize(this.viewportSize_, true, true);

        /**
         * Kinda id of collection, used by many {@link CollDataSource DataSources} to
         * produce a request params object for API call. Often becomes a part of
         * URL for `config`. API calls and is used for permission checks.
         * @type {string}
         */
        this.objectName_ = oArgs.objectName || this.objectName_;

        /**
         * Name of the permission associated with the Collection,
         * ex. PERMISSION_VIRTUALSERVICE or PERMISSION_POOL. Used to determine,
         * for example, if the user has permissions to create a new Item.
         */
        this.permissionName_ = oArgs.permissionName || '';

        const Item = this.getAjsDependency_('Item');

        /**
         * Item constructor to {@link Collection#append append} new Items to the Collection or
         * just (@link Collection#getNewItem create} them.
         * @type {Item}
         * @protected
         */
        this.itemClass_ = oArgs.itemClass || this.itemClass_ || Item;

        /**
         * {@link AviModal} window Id which is used for {@link Collection#create Collection Item
         * create}.
         * @type {string}
         * @protected
         **/
        this.windowElement_ = oArgs.windowElement || this.windowElement_ ||
            this.itemClass_.prototype.windowElement;

        this.setNewItemDefaults(!isUndefined(oArgs.defaults) ? oArgs.defaults :
            angular.copy(this.defaults_));

        /**
         * Constant object to be applied over Item's default configuration before applying
         * mutable `defaults_`.
         * @type {Object.<string, any>}
         * @protected
         */
        this.serverDefaultsOverride_ = angular.copy(this.serverDefaultsOverride_);

        //these provide compatibility with the previous Collection version, deprecated stuff
        if (oArgs.sortBy && angular.isString(oArgs.sortBy)) {
            this.sortBy_ = oArgs.sortBy;
        }

        if (this.sortBy_ && angular.isString(this.sortBy_)) {
            this.getDataSourceByFieldName('config').setSortParam(this.sortBy_);
        }

        if (oArgs.data && Array.isArray(oArgs.data) && oArgs.data.length) {
            _.each(oArgs.data, function(iData) {
                this.appendItem(iData);
            }, this);
        }

        //copies list from prototype
        this.searchFields_ = (oArgs.searchFields || this.searchFields_).concat();

        //should we keep the default search string when passed?
        if (angular.isString(oArgs.search)) {
            this.setSearch(oArgs.search);
        }

        /**
         * Params to be passed to the create controller or component.
         * @type {Object|null}
         */
        this.createParams_ = !isUndefined(oArgs.createParams) ?
            angular.copy(oArgs.createParams) : null;

        this.restrictCreateOnEssentialLicense_ =
            !isUndefined(oArgs.restrictCreateOnEssentialLicense) ?
                oArgs.restrictCreateOnEssentialLicense :
                this.restrictCreateOnEssentialLicense_;

        if (this.loadOnCreate_) {
            this.load();
        }

        const { updateInterval } = oArgs;

        // set data source update interval
        if (!isUndefined(updateInterval)) {
            this.setUpdateInterval(updateInterval, true);
        }
    }

    /**
     * Getter for items wrapped in the collection.
     * @return {Object[]}
     */
    get itemList() {
        return this.items;
    }

    /**
     * Getter for permissionName_ property.
     * @return {string}
     */
    get permissionName() {
        return this.permissionName_ || this.objectName.replace('-inventory', '');
    }

    /**
     * Getter for objectType_ property.
     * @return {string}
     */
    get objectName() {
        return this.objectName_;
    }

    /**
     * Set reloading interval for all active data sources.
     * @param {number} interval - interval to set; in seconds.
     * @param {boolean} [forced=false] - method will overwrite the protected update interval
     *     if this argument is truthy.
     */
    setUpdateInterval(interval, forced = false) {
        each(this.dataSources_, dataSource => dataSource.setUpdateInterval(interval, forced));
    }

    /**
     * Returns a private property value.
     * @returns {number}
     */
    getDefaultViewportSize() {
        return this.defaultViewportSize_;
    }

    /**
     * Returns collection limit.
     * @returns {number}
     */
    getLimit() {
        return this.limit_;
    }

    /**
     * Set item defaults.
     * @param {Object} defaultObject
     */
    setNewItemDefaults(defaultObject) {
        this.defaults_ = defaultObject;
    }

    /**
     * Shortcut for {@link Item#getIdFromData} method with only difference that `this` is not
     * defined so it works only when {@link Item#data.config} is being passed as an argument.
     * @param {Item#data#config} data
     * @returns {Item#id}
     */
    getItemIdFromData(...data) {
        const methodName = 'getIdFromData';

        if (methodName in this.itemClass_) { //static method is defined
            return this.itemClass_[methodName](...data);
        } else {
            return this.itemClass_.prototype[`${methodName}_`].call(undefined, ...data);
        }
    }

    /**
     * Sets the sorting param on data source responsible for `config` data field.
     * @param {string=} value - Back-end field name to be used for sorting.
     * @param {boolean} [override=false] - Override the sort param anyway regardless of the existing
     *     one.
     * @returns {boolean} True if new value has been set or false otherwise.
     * @todo control the list of sorting parameters through `config` DS
     */
    setSorting(value, override = false) {
        const dataSource = this.getDataSourceByFieldName('config');

        if (override) {
            return dataSource.overrideSortParam(value);
        } else if (isUndefined(value) || angular.isString(value)) {
            return dataSource.setSortParam(value);
        }

        return false;
    }

    /**
     * Sets the sorting parameter on the data source responsible for `config` data field,
     * empties Collection and calls {@link Collection#load}.
     * @param {string=} propertyName
     * @returns {angular.$q.promise}
     */
    sort(propertyName) {
        let promise;

        if (this.setSorting(propertyName)) {
            promise = this.load(undefined, true);
        } else {
            promise = this.$q_.reject('Wrong arguments passed.');
        }

        return promise;
    }

    /**
     * Sorts and loads provided offset and limit.
     * @param {string} propertyName - Name of property to sort.
     * @param {number} offset - Items offset to load.
     * @param {number} limit - Number of items to load.
     * @returns {ng.Promise}
     */
    sortPage(propertyName, offset = 0, limit = 0) {
        let promise;

        if (this.setSorting(propertyName)) {
            promise = this.loadPage(offset, limit);
        } else {
            promise = this.$q_.reject('Wrong arguments passed.');
        }

        return promise;
    }

    /**
     * Returns the current sorting parameter set on `config` field data source.
     * @returns {string|undefined}
     */
    getSorting() {
        return this.getDataSourceByFieldName('config').getSortParam();
    }

    /**
     * Sets the search parameter over the data source responsible for `config` data field.
     * @param {string=} str
     * @returns {boolean} True when new search value has been set or false otherwise.
     */
    setSearch(str) {
        let res = false;

        if (isUndefined(str) || str === null || angular.isString(str)) {
            res = this.getDataSourceByFieldName('config').setSearchParam(
                this.getSearchFields_(),
                str,
            );
        }

        return res;
    }

    /**
     * Sets the search parameter on the data source responsible for `config` data field,
     *     empties Collection and calls {@link Collection#load}.
     * @param {string=} str
     * @returns {angular.$q.promise}
     */
    search(str) {
        let promise;

        if (this.setSearch(str)) {
            promise = this.load(undefined, true);
        } else {
            promise = this.$q_.reject('Wrong arguments passed.');
        }

        return promise;
    }

    /**
     * Collection may support search by a few config properties. Here we can get
     * a list of those.
     * @returns {string[]}
     * @protected
     */
    getSearchFields_() {
        return this.searchFields_.concat();
    }

    /**
     * Extends the {@link Collection#defaults_} with provided object. Important difference from
     * angular.extend is the removal of all variables with `undefined` values after extending.
     * @param {Item#data.config} params
     * @param {boolean=} rewrite - Old values will be removed before extending by new ones.
     * @returns {boolean} - True if operation was successful, false otherwise.
     * @todo may need `delete` (aka fallback to defaults) support through an extra param
     */
    setDefaultItemConfigProps(params, rewrite) {
        let res = false;

        if (angular.isObject(params)) {
            if (angular.isObject(this.defaults_)) {
                if (rewrite) {
                    this.defaults_ = {};
                }

                angular.merge(this.defaults_, params);

                res = true;
            } else {
                console.error('Can\'t set default values of collection "%s" since property ' +
                    '"defaults_" has type different from "object"', this.objectName_);
            }
        }

        return res;
    }

    /**
     * Returns default data object for the new Item.
     * @returns {Item#data.config} - Default Item's config object.
     * @protected
     */
    getDefaultItemConfig_() {
        const defaultValues = this.getAjsDependency_('defaultValues');

        const res = angular.merge(
            defaultValues.getDefaultItemConfigByType(
                this.objectName_.replace('-inventory', '').toLowerCase(),
            ) || {},
            this.serverDefaultsOverride_,
        );

        // Then override it with customizable defaults
        if (angular.isObject(this.defaults_)) {
            angular.merge(res, this.defaults_);
        } else if (angular.isFunction(this.defaults_)) { //DEPRECATED
            this.defaults_(res);
        }

        return res;
    }

    /**
     * Returns the number of Items we current have in Collection.
     * @returns {number}
     */
    getNumberOfItems() {
        return this.items.length;
    }

    /**
     * Returns the `total` number of Collection Items backend has. Provided by data source
     * of the `config` field. Undefined if not supported.
     * @returns {number|undefined}
     */
    getTotalNumberOfItems() {
        const config = this.getDataSourceByFieldName('config');

        if (!config) {
            return 0;
        }

        return config.getTotalNumberOfItems();
    }

    /**
     * Returns true for APIs which provide total number of items on every update.
     * False otherwise.
     * @returns {boolean}
     */
    hasRealTotalNumberOfItems() {
        const configDS = this.getDataSourceByFieldName('config');

        return configDS.hasRealTotalNumberOfItems();
    }

    /**
     * Removes an Item from Collection. Doesn't make any API calls - just calls
     * Item#destroy and removes it from {@link Collection#items} and
     * {@link Collection#itemById}.
     * @param {Item|Item#id} item - Instance or it's id.
     */
    removeItem(item) {
        let index,
            visIdsIndex,
            id;

        if (item && typeof item === 'string' && item in this.itemById) {
            item = this.itemById[item];
        }

        if (item instanceof this.itemClass_) {
            id = item.getIdFromData();

            index = this.items.indexOf(item);

            if (index !== -1) {
                this.items.splice(index, 1);
            }

            if (id in this.itemById) {
                delete this.itemById[id];
            }

            visIdsIndex = this.visibleItemIds_.indexOf(id);

            if (visIdsIndex !== -1) {
                this.visibleItemIds_.splice(visIdsIndex, 1);
            }

            item.destroy();
        } else {
            throw new Error(`Wrong Item id or instance has been passed to removeItem method: ${
                item}`);
        }
    }

    /**
     * Finds an Item in Collection and extends it's Item#data with newData object.
     * @param {Item|Item#id} item - Instance or it's id.
     * @param newData {Item#data} - I.e: {config: {id: 'xyz'}, runtime: undefined}
     * @returns {Item|undefined} Undefined if wrong arguments or Item was not found
     * in Collection.
     */
    updateItemData(item, newData) {
        let res;

        if (angular.isObject(newData) && item) {
            if (item in this.itemById) {
                item = this.itemById[item];
            }

            if (item instanceof this.itemClass_) {
                res = item;
                item.updateItemData(newData);
            }
        }

        return res;
    }

    /**
     * Appends item to {@link Collection#items} and {@link Collection#itemById}.
     * @param {Item|Item#data} iData - Instance or object with at least `config` properties.
     * @param {number=} index - When passed points Item ot the certain place in a list,
     *     otherwise appends it to the end of the list.
     * @param {boolean=} loadOnAppend - When true calls {@link Collection#load}.
     * @returns {Item|undefined}
     */
    appendItem(iData, index, loadOnAppend) {
        let res,
            item,
            itemId;

        const isInstance = iData instanceof this.itemClass_;

        if (isInstance || angular.isObject(iData) && this.getItemIdFromData(iData)) {
            item = isInstance ? iData : this.getNewItem({ data: iData });
            itemId = item.getIdFromData();

            if (itemId && !(itemId in this.itemById)) {
                item.windowElement = this.windowElement_;
                item.collection = this;

                //TODO set event listeners

                index = typeof index === 'number' && index >= 0 && index <= this.items.length ?
                    index : this.items.length;

                this.items[index] = item;
                this.itemById[itemId] = item;

                if (loadOnAppend) {
                    this.load();
                }

                res = item;
            } else {
                console.error('Can\'t append Item %O since Collection "%s"' +
                    'already has as Item with same id "%s" or it is faulty.',
                item, this.objectName_, itemId);
            }
        }

        return res;
    }

    /**
     * Appends Item to the Collection and calls Collection#load after. Legacy thing.
     * @param {Item} item
     * @returns {Item|undefined}
     * @deprecated Use {@link Collection#appendItem} instead.
     */
    append(item) {
        return this.appendItem(item, undefined, true);
    }

    /**
     * Shortcut for Item's constructor. No default values, no relation to Collection, just
     * direct access to Item's constructor. When you too lazy to inject Item Class directly.
     * Discouraged.
     * @param {*=} args - Item's constructor properties.
     * @returns {Item} new Instance without any connection to Collection.
     */
    getNewItem(args) {
        if (!angular.isObject(args)) {
            args = {};
        }

        const item = new this.itemClass_(args); // eslint-disable-line new-cap

        // Make sure new instance has objectName and windowElement
        if (!item.objectName) {
            item.objectName = this.objectName_;
        }

        if (!item.windowElement) {
            item.windowElement = this.windowElement_;
        }

        return item;
    }

    /**
     * Creates a new instance of itemClass with default values populated for Item#data.config.
     * @param {*=} args - Item constructor properties.
     * @param {boolean=} isLone - True if we this Item won't have any connection with the
     *     Collection.
     * @returns {Item}
     */
    createNewItem(args, isLone) {
        const constrArgs = { data: {} };

        if (!isLone) {
            constrArgs.collection = this;
        }

        const
            haveArgsObject = angular.isObject(args),
            haveDataPassed = haveArgsObject && angular.isObject(args.data);

        //haveDataPassed will be overwritten, also might not be present yet
        if (!haveDataPassed) {
            constrArgs.data.config = this.getDefaultItemConfig_();
        }

        if (haveArgsObject) {
            angular.extend(constrArgs, args);
        }

        return this.getNewItem(constrArgs);
    }

    /**
     * Gets an Item by Item's id.
     * @param {Item#id} itemId
     * @returns {Item|undefined} - Undefined if Item with provided id was not
     * found in Collection.
     */
    getItemById(itemId) {
        let item;

        if (itemId && typeof itemId === 'string' && itemId in this.itemById) {
            item = this.itemById[itemId];
        }

        return item;
    }

    /**
     * Looks up Collection Item by name.
     * @param {string} itemName
     * @returns {Item|null}
     */
    getItemByName(itemName) {
        return itemName && _.find(this.items, item => item.getName() === itemName) || null;
    }

    /**
     * Returns true if create function is available for Collection's objectName.
     * @returns {boolean}
     */
    isCreatable() {
        const auth = this.getAjsDependency_('Auth');

        const isAllowed = auth.isAllowed(
            this.permissionName_ || this.objectName_.replace('-inventory', ''),
            'w',
        );

        const { isEssentialsLicense } = this.systemInfoService_;

        const isCreateRestricted = this.restrictCreateOnEssentialLicense_ && isEssentialsLicense;

        return !isCreateRestricted && this.windowElement_ && !auth.allTenantsMode() &&
            isAllowed || false;
    }

    /**
     * Opens create dialog window via {@link AviModal}. Makes sure defaults have been loaded.
     * @param {string=} windowElement - Modal window unique id. When not set default edit modal
     *     id will be used.
     * @param {Object=} params - All properties of this object will be passed into
     *     {@link AviModal.open Modal window} scope.
     * @returns {angular.$q.promise}
     */
    create(windowElement, params) {
        const editParams = angular.merge({}, this.createParams_, params);
        const defaultValues = this.getAjsDependency_('defaultValues');

        return defaultValues.load()
            .then(() => this.createNewItem().edit(windowElement, editParams))
            .catch(error => {
                console.error(error);

                return Promise.reject(error);
            });
    }

    /**
     * Used when a non-admin tenant edits an admin-created profile/group. Creates a new profile
     * Collection Item but copies the settings from another. Used for profiles and groups.
     * @param {Item} item - Item to be copied.
     * @return {ng.$q.promise}
     */
    clone(item) {
        let configLoadPromise;

        if (item.loadOnEdit) {
            configLoadPromise = item.load().then(() => Collection.getItemConfig(item));
        } else {
            configLoadPromise = this.$q_.when(Collection.getItemConfig(item));
        }

        return configLoadPromise.then(config => {
            config = this.modifyClonedConfig(angular.copy(config));

            ['uuid', 'tenant_ref', 'url']
                .forEach(fieldName => delete config[fieldName]);

            return this.createNewItem({ data: { config } }).edit();
        });
    }

    /**
     * Modify the config of the Item to be cloned. Default behavior is to modify the name.
     * @param {Object} copy of the config object
     * @returns {Object} modified config
     */
    modifyClonedConfig(config) {
        config.name = `${config.name} (copy)`;

        return config;
    }

    /**
     * Loads specified offset and limit.
     * @param {number} offset - Number of items to skip.
     * @param {number} limit - Number of items to load.
     */
    loadPage(offset = 0, limit = 0) {
        this.viewportSize_ = limit;
        this.overLimitCoeff_ = 0;
        this.groupDataSourceMethodCall_('setOffset_', offset, undefined, true).finally(() => {
            this.groupDataSourceMethodCall_('setLimit_', limit, true).finally(() => {
                this.emptyData(false);
                this.load();
            });
        });
    }

    /**
     * Called by viewer to inform collection of currently visible items.
     * Notifies all data sources.
     * @param {number|string[]} itemIds - Array of ids or number of items (page_size). When
     *     not provided all items are set as visible.
     * @param {number=} offset - number of Items hidden above the viewport's top. Defaults to 0.
     * @param {boolean=} immediateCall - SetLimit is usually debounced and sometimes we want
     *     to call it instantly. When true is passed will be called instantly.
     * @returns {ng.$q.promise}
     */
    updateItemsVisibility(itemIds, offset, immediateCall) {
        const prevVisibleItems = angular.copy(this.visibleItemIds_);

        this.visibleItemsOffset_ = offset || 0;

        if (itemIds && typeof itemIds === 'number') {
            itemIds = _.map(this.items.slice(offset, offset + itemIds), function(item) {
                return item.getIdFromData();
            });
        } else if (isUndefined(itemIds) || itemIds === 0) {
            itemIds = this.getItemIds();
        }

        if (Array.isArray(itemIds)) {
            this.visibleItemIds_ = itemIds;
        } else if (typeof itemIds === 'number' && itemIds > 0) {
            //indexes of items inside this.items
            this.visibleItemIds_ = _.pluck(
                this.items.slice(this.visibleItemsOffset_, this.visibleItemsOffset_ + itemIds),
                'id',
            );
        } else {
            this.visibleItemIds_.length = 0;
        }

        if (prevVisibleItems.length !== this.visibleItemIds_.length ||
            _.intersection(prevVisibleItems, this.visibleItemIds_).length !==
            prevVisibleItems.length) {
            this.trigger('visibleItemsListUpdate', this.visibleItemIds_, prevVisibleItems);
        }

        return this.groupDataSourceMethodCall_(
            immediateCall ? 'setOffset_' : 'setOffset',
            this.visibleItemsOffset_,
            this.visibleItemIds_,
        );
    }

    /**
     * Returns list of Item ids this collection has.
     * @returns {Item.id[]}
     */
    getItemIds() {
        return Object.keys(this.itemById);
    }

    /**
     * Called by viewer to inform Collection of the viewport size, meaning how many Items do we
     * need to load and show. Notifies data sources.
     * @param {number=} size
     * @param {boolean=} dontLoad - Pass true to avoid immediate loading of DataSources.
     * @param {boolean=} immediateCall - SetLimit is usually debounced and sometimes we want
     *     to call it instantly. When true is passed will be called instantly.
     * @returns {ng.$q.promise}
     */
    updateViewportSize(size, dontLoad, immediateCall) {
        if (typeof size === 'number' && _.isFinite(size) && size > 0) {
            this.viewportSize_ = Math.round(size);
        } else {
            this.viewportSize_ = this.defaultViewportSize_;
        }

        return this.groupDataSourceMethodCall_(
            immediateCall ? 'setLimit_' : 'setLimit',
            this.viewportSize_,
            dontLoad,
        );
    }

    /**
     * Return the current viewport size set for Collection.
     * @returns {number}
     */
    getViewportSize() {
        return this.viewportSize_;
    }

    /**
     * Actually deletes Items by making API calls to the back-end through Item#drop.
     * @param {Item|Item[]|Item.id| Item.id[]} ids
     * @param {boolean=} reload - When set to true Collection will reload itself after dropping.
     * @param {boolean=} force - Special back-end flag to remove some related objects.
     * @param {Object=} dropParams - Optional parameters to be passed to Item#drop.
     * @param {boolean=} showAlert - Display Avi Alert when delete fails.
     * @returns {angular.$q.promise}
     */
    dropItems(ids, reload, force, dropParams, showAlert = true) {
        const promises = [];

        let promise,
            deletedSome = false;

        if (ids) {
            reload = isUndefined(reload) || !!reload;
            force = !!force;

            if (!Array.isArray(ids)) {
                ids = [ids];
            }

            _.each(ids, function(id) {
                if (id instanceof this.itemClass_) {
                    id = id.getIdFromData();
                }

                if (id && typeof id === 'string' && id in this.itemById) {
                    promises.push(
                        this.itemById[id].drop(force, dropParams, showAlert)
                            .then(function(rsp) {
                                deletedSome = true;

                                return rsp;
                            }),
                    );
                }
            }, this);

            if (promises.length) {
                promise = this.$q_.all(promises)
                    .finally(function() {
                        if (deletedSome && reload) {
                            return this.load();
                        }
                    }.bind(this));
            } else {
                promise = this.$q_.reject(`No id's were found in Collection "${
                    this.objectName_}".`);
            }
        } else {
            promise = this.$q_.reject('No id\'s were provided.');
        }

        return promise;
    }

    /**
     * Removes all references from Collection to Item. Called by Item#drop method.
     * @param {Item#id} itemId
     * @fires Collection#"collectionItemDrop collectionItemDropSuccess"
     * @todo differs from group removal - no reload happens
     */
    onItemDrop(itemId) {
        let res = false,
            itemIndex,
            visibleItemIndex;

        if (itemId && typeof itemId === 'string' && itemId in this.itemById) {
            itemIndex = _.findIndex(this.items, function(item) {
                return item.id === itemId;
            });

            if (itemIndex > -1) {
                this.items.splice(itemIndex, 1);
            }

            delete this.itemById[itemId];

            visibleItemIndex = this.visibleItemIds_.indexOf(itemId);

            if (visibleItemIndex > -1) {
                this.visibleItemIds_.splice(visibleItemIndex, 1);
            }

            /**
             * Triggers after successful DELETE API operation on Item belonging to the instance.
             * @event Collection#"collectionItemDrop collectionItemDropSuccess"
             * @type {Item#id}
             */
            this.trigger('collectionItemDrop collectionItemDropSuccess', itemId);

            res = true;
        }

        return res;
    }

    /**
     * Empties the Collection by removing all Items and notifying the `config`
     * field data source.
     * @param {boolean} update - Optional boolean to trigger flush event. Defaults to True.
     */
    emptyData(update = true) {
        _.each(this.itemById, item => this.removeItem(item));

        this.items.length = 0;
        this.itemById = {};

        if (update) {
            this.updateItemsVisibility();
            this.getDataSourceByFieldName('config').onDataFlush();
            super.emptyData();
        }
    }

    /**
     * Removes all Items from Collection and all non-required data sources.
     * @todo reset params through data source
     **/
    reset() {
        if (this.isDestroyed()) {
            return;
        }

        this.emptyData();
        this.setDefaultItemConfigProps({}, true);
        this.setSearch();
        this.updateViewportSize(undefined, true);

        super.reset();

        //sorting is reset by super.reset
        this.setSorting(this.sortBy_);
    }

    /**
     * Destroys the Collection. Main point is to stop all updates, network calls and remove
     * event listeners. Takes care of Items and all data sources.
     * @returns {boolean} True if got destroyed, false if had been destroyed before.
     */
    destroy() {
        const gotDestroyed = super.destroy();

        if (gotDestroyed) {
            this.emptyData(false);
        }

        return gotDestroyed;
    }

    /**
     * Returns the config of an Item or ObjectTypeItem. If the passed-in object is an
     * ObjectTypeItem, flattens the config.
     * @param {ObjectTypeItem|Item} item - Instance of the Item or ObjectTypeItem.
     * @returns {object}
     */
    static getItemConfig(item) {
        return item instanceof ObjectTypeItem ? item.flattenConfig() : item.getConfig();
    }
}

/** @override */
Collection.prototype.defaultDataSources_ = 'list';

/** @override */
Collection.prototype.defaultDataFields_ = 'config';

/**
 * Searchable config property names.
 * @type {string[]}
 * @protected
 */
Collection.prototype.searchFields_ = ['name', 'key', 'values'];

/**
 * Hash of all available data sources for this Collection. Ids must be unique within
 * Collection and do not intersect with data field names.
 * @type {{string: CollDataSourceConfig}}
 * @protected
 */
Collection.prototype.allDataSources_ = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * For compatibility with previous collection version these params will be used to set
 * up the initial parameter values though {@link Collection#setParams} by constructor.
 * @type {{string: *}|null}
 * @deprecated
 * @protected
 */
Collection.prototype.params_ = null;

/**
 * Default viewport size (aka limit) to be used for Collections without smart
 * layout informing about its size. This is deprecated, and setting "limit" as an
 * argument to the collection constructor is the new and right way to set `page_size`.
 * @type {number}
 * @protected
 */
Collection.prototype.defaultViewportSize_ = 30;

Collection.prototype.overLimitCoeff_ = 0.25;

Collection.prototype.objectName_ = '';

Collection.prototype.windowElement_ = '';

Collection.prototype.serverDefaultsOverride_ = null;

Collection.prototype.isStatic_ = true;

/**
 * Loading indicator.
 * @type {boolean}
**/
Collection.prototype.busy = false;

/**
 * In case of backend returned error, it's going to be here.
 * @type {Object|string|null}
 **/
Collection.prototype.errors = null;

/**
 * Default config properties for the new Items created by this Collection.
 * @type {Object|null}
 * @protected
 **/
Collection.prototype.defaults_ = {};

/**
 * Flag to restrict Item create on Essential License tier.
 * @type {boolean}
 * @protected
 */
Collection.prototype.restrictCreateOnEssentialLicense_ = true;

Collection.ajsDependencies = [
    '$q',
    'Auth',
    'Item',
    'defaultValues',
    'systemInfoService',
];

export { Collection };
