/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    GslbSiteType,
    IGslbHealthMonitorProxy,
    IGslbThirdPartySite,
} from 'generated-types';

import { GslbThirdPartySite } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IItemParams, withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    GslbSiteModalComponent,
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-site-modal.component';

import {
    GslbSitePreviewComponent,
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-site-preview/gslb-site-preview.component';

import { IFullModalLayout } from 'ng/modules/core/services/full-modal/full-modal.service';
import { GslbGeoLocationConfigItem } from 'message-items/gslb-geo-location.config-item.factory';

type TGslbThirdPartySitePartial = Omit<IGslbThirdPartySite, 'hm_proxies' | 'location'>;

interface IGslbThirdPartySiteConfig extends TGslbThirdPartySitePartial {
    hm_proxies: RepeatedMessageItem<MessageItem<IGslbHealthMonitorProxy>>;
    location: GslbGeoLocationConfigItem;
}

/**
 * @description GslbThirdPartySite ConfigItem class.
 *
 * @author Hitesh Mandav
 */
export class GslbThirdPartySiteConfigItem
    extends withFullModalMixin(MessageItem)<IGslbThirdPartySiteConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbthirdpartysite',
            objectType: GslbThirdPartySite,
            windowElement: GslbSiteModalComponent,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Return GslbThirdPartySite enabled/disabled state.
     */
    public isEnabled(): boolean {
        return this.getConfig().enabled;
    }

    /**
     * Initialize the location feild.
     */
    public onConfigureUserLocation(): void {
        this.safeSetNewChildByField('location');
    }

    /**
     * @override
     * Override the default preview component with custom preview component.
     */
    public async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: GslbSitePreviewComponent as Type<Component>,
            previewComponentProps: {
                config: params.editable.getConfig(),
                type: GslbSiteType.GSLB_THIRD_PARTY_SITE,
            },
        };
    }
}
