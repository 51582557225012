<div class="vs-logs-type-dropdown">
    <select
        clrSelect
        class="vs-logs-type-dropdown__select"
        [(ngModel)]="selectedLogType"
        (ngModelChange)="setLogType($event)"
    >
        <option [value]="vsAppProfileTypeEnum.L4">{{ l10nKeys.l4LogsLabel | vtranslate }}</option>
        <option [value]="vsAppProfileTypeEnum.L7">{{ l10nKeys.l7LogsLabel | vtranslate }}</option>
      </select>
</div>
