/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const excludedLabel = `${prefix}.excludedLabel`;
export const columnTitleSwitchName = `${prefix}.columnTitleSwitchName`;
export const configuredSubnetsValueText = `${prefix}.configuredSubnetsValueText`;
export const columnTitleDiscoveredSubnets = `${prefix}.columnTitleDiscoveredSubnets`;
export const columnTitleConfiguredSubnets = `${prefix}.columnTitleConfiguredSubnets`;
export const columnTitleStaticIpPools = `${prefix}.columnTitleStaticIpPools`;
export const columnTitleVrfContext = `${prefix}.columnTitleVrfContext`;
export const columnTitleManagedObjectId = `${prefix}.columnTitleManagedObjectId`;

export const ENGLISH = {
    [headerLabel]: 'Networks',
    [excludedLabel]: 'Excluded',
    [columnTitleSwitchName]: 'Switch Name',
    [configuredSubnetsValueText]: '{0} (+{1} More)',
    [columnTitleDiscoveredSubnets]: 'Discovered Subnets',
    [columnTitleConfiguredSubnets]: 'Configured Subnets',
    [columnTitleStaticIpPools]: 'Static IP Pools',
    [columnTitleVrfContext]: 'VRF Context',
    [columnTitleManagedObjectId]: 'Managed Object ID',
};
