/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createDropdownOption } from 'ng/utils/dropdown.utils';
import { CLOUD_NSXT } from 'ajs/modules/cloud/cloud.constants';

import * as globalL10n from 'global-l10n';
import * as l10n from './virtual-service-create-basic.controller.l10n';

import './virtualservice-create.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

export function virtualServiceCreateBasicController(
    $scope,
    $controller,
    Regex,
    $q,
    HealthMonitorCollection,
    VMNicIPCollection,
    $filter,
    Cloud,
    poolServersLimit,
    Server,
    defaultValues,
    l10nService,
    VsVipCollection,
) {
    $controller('VirtualServiceCreateCommonController', { $scope });

    $scope.healthMonitorCollection = new HealthMonitorCollection();

    $scope.vsVipCollection = new VsVipCollection();

    const serverCollection = new VMNicIPCollection();

    $scope.wizard = {
        busy: false,
        current: 0,
    };

    /**
     * Get keys from source bundles for template usage
     */
    $scope.l10nKeys = l10nKeys;
    $scope.globalL10nKeys = globalL10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /* =============
       SERVERS STUFF
       ============= */
    //TODO for most part all methods, functions and grid configs should be shared between
    //PoolCreate and VSBasicCreate

    /**
     * Within the pool we don't allow multiple unresolved domain names (with IP equal to
     * 0.0.0.0). That's why we ignore hostname part here.
     * @param {ServerConfig} server
     * @returns {string}
     * @inner
     */
    function getServerId(server) {
        const { default_server_port: defaultServerPort } =
            $scope.editable.pool.getConfig();

        return Server.getServerUuid(
            angular.extend({}, server, {
                hostname: server.ip.addr,
                default_server_port: defaultServerPort,
            }),
        );
    }

    const { objectName_: gridObjectName } = serverCollection;

    $scope.networkServersGrid = {
        id: `${gridObjectName}-list-page`,
        permission: 'PERMISSION_POOL',
        collection: serverCollection,
        fields: [{
            name: 'data.config.hostname',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderVirtualMachineName),
        }, {
            name: 'data.config.ip.addr',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderIPAddress),
        }, {
            name: 'data.config.mac',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderMacAddress),
        }],
        searchFields: ['data.config.hostname', 'data.config.ip.addr', 'data.config.mac'],
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.addServersActionTitle),
            className: 'sel-add-servers-by-nw avi-btn-primary',
            do: servers => {
                addServersToPool(
                    servers.map(server => {
                        const config = server.getConfig();

                        //vm name is not a hostname
                        config['resolve_server_by_dns'] = false;

                        return config;
                    }),
                );

                $scope.closeNetworkPanel();

                return true;
            },
            bypassPermissionsCheck: true,
        }],
        checkboxDisable: server => getServerId(server.getConfig()) in $scope.serversHash,
        layout: {
            hideEditColumns: true,
            lengthAsTotal: true,
        },
    };

    $scope.serversGrid = {
        id: 'virtual-service-basic-create-component',
        fields: [{
            name: 'getConfig().hostname',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderServerName),
            sortBy: 'hostname',
        }, {
            name: 'getConfig().resolve_server_by_dns',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderResolveServerByDns),
            template: '<checkbox ng-model="row.getConfig().resolve_server_by_dns"></checkbox>',
        }, {
            name: 'getConfig().ip.address',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderIPAddress),
            template: `<input
                class="sel-server-ip"
                type="text"
                ng-model="row.getConfig().ip.address"
                ng-pattern="::Regex.anyIP"
                ng-disabled="ngDisabled || row.getConfig().resolve_server_by_dns"
                required>`,
        }, {
            name: 'getConfig().network',
            title: l10nService.getMessage(l10nKeys.listColumnHeaderNetwork),
            transform(row) {
                return row.getConfig().nw_ref ? row.getConfig().nw_ref.name() : '';
            },
        }],
        searchFields: ['hostname', 'ip.addr', 'nw_ref.name()'],
        rowId: row => getServerId(row.flattenConfig()),
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.removeActionTitle),
            do: serversToDrop => {
                const
                    hashToDrop = {},
                    { serversHash: hash } = $scope,
                    poolConfig = $scope.editable.pool.getConfig();

                serversToDrop.forEach(server => {
                    hashToDrop[getServerId(server.getConfig())] = true;

                    const serverId = getServerId(server.flattenConfig());

                    delete hash[serverId];
                    poolConfig.servers.removeByMessageItem(server);
                });
            },
        }],
    };

    /**
     * Removes all servers from pool and flushes servers hash.
     * @inner
     */
    function deletePoolServers() {
        $scope.editable.pool.getConfig().servers.removeAll();
        resetServerHashes();
    }

    /**
     * Resets servers hash and populates it from existent pool's servers list.
     * @inner
     */
    function resetServerHashes() {
        $scope.serversHash = {};

        const
            { servers } = $scope.editable.pool.getConfig(),
            { serversHash: hash } = $scope;

        servers.config.forEach(server => hash[getServerId(server.getConfig())] = true);
    }

    /**
     * Adds new servers into the list of the Pool. Checks for available spots and controls
     * uniqueness.
     * @param {ServerConfig} server
     * @inner
     */
    function addServerToPool(server) {
        const
            { editable, serversHash: hash } = $scope,
            { servers } = editable.pool.getConfig(),
            newServer = angular.extend({
                enabled: true,
                ratio: 1,
                prst_hdr_val: '',
                resolve_server_by_dns: !!server.hostname,
            }, server),
            serverId = getServerId(newServer);

        if (!server.hostname) {
            server.hostname = server.ip.addr;
        }

        if (servers.config.length < poolServersLimit) {
            if (!(serverId in hash)) {
                servers.add(newServer);
                hash[serverId] = true;
            } else {
                if (!angular.isString(editable.errors)) {
                    editable.errors = '';
                }

                editable.errors +=
                    l10nService.getMessage(l10nKeys.serverAlreadyPresentErrorMessage, [serverId]);
            }
        } else {
            editable.errors =
                l10nService.getMessage(l10nKeys.maxServersOverflowErrorMessage, [poolServersLimit]);
        }
    }

    /**
     * Convenience wrapper over addServerToPool for batch new servers processing.
     * @param servers
     * @inner
     */
    const addServersToPool = servers => {
        $scope.editable.errors = '';
        servers.forEach(addServerToPool);
    };

    $scope.addServersToPool = addServersToPool;

    /**
     * Event handler to show error from the addPoolServer component.
     * @param {string} error
     * @public
     */
    $scope.addServersToPoolOnError = error => $scope.editable.errors = error;

    /**
     * We need to update servers hash on port change event since all servers use default port.
     * @public
     */
    $scope.onPortChange = function() {
        const
            { editable: vs } = $scope,
            { services } = vs.getConfig(),
            [{ port }] = services,
            { pool } = vs,
            poolConfig = pool.getConfig(),
            { default_server_port: defaultPoolServerPort } = pool.getDefaultConfig();

        services[0]['port_range_end'] = port;
        poolConfig['default_server_port'] = vs.isHTTP() ? defaultPoolServerPort : port;

        resetServerHashes();
    };

    /**
     * Deletes unused fields when switching between IP Address, IP Group, or EPG server
     * selection.
     * @public
     */
    $scope.clearServers = function() {
        if ($scope.ui.errors) {
            $scope.ui.errors = '';
        }

        deletePoolServers();

        const poolConfig = $scope.editable.pool.getConfig();

        delete poolConfig.ipaddrgroup_ref;
        delete poolConfig.external_autoscale_groups;

        poolConfig.nsx_securitygroup = [];
    };

    /**
     * We need to tell addPoolServers component how many new servers can be added to this pool.
     * @returns {number}
     * @public
     */
    $scope.getNumberOfAvailPoolServerSpots = function() {
        return Math.max(0, poolServersLimit -
            $scope.editable.pool.getConfig().servers.config.length);
    };

    /* ==================
       SERVERS STUFF ENDS
       ================== */

    /**
     * We use different VS settings based on application type picked by user.
     * @type {Object}
     */
    const vsSettingsByType = {
        http: {
            label: 'HTTP',
            appProfileName: 'System-HTTP',
            hmName: 'System-HTTP',
        },
        https: {
            label: 'HTTPS',
            appProfileName: 'System-Secure-HTTP',
            hmName: 'System-HTTP',
        },
        l4: {
            label: 'L4',
            appProfileName: 'System-L4-Application',
            hmName: 'System-TCP',
        },
        ssl: {
            label: 'L4 SSL/TLS',
            appProfileName: 'System-SSL-Application',
            hmName: 'System-TCP',
        },
    };

    $scope.vsSettingsByType = vsSettingsByType;

    $scope.init = function() {
        $scope.ui = {};

        const { ui } = $scope;

        ui.selectedNetwork = undefined;
        ui.ipSelect = 'ip_address';
        ui.applicationType = 'http';

        $scope.Regex = Regex;

        $scope.wizard.current = 0;
        $scope.wizard.busy = true;

        $scope.newServer = {
            address: '',
        };

        $scope.closeNetworkPanel();

        $scope.editable.pool = $scope.poolCollection.createNewItem();
        $scope.editable.pool.beforeEdit();

        resetServerHashes();

        $scope.cloudCollection.setParams({ join: 'ipamdnsproviderprofile:ipam_provider_ref' });
        $scope.cloudCollection.load()
            .then(() => {
                if ($scope.cloudCollection.getTotalNumberOfItems() < 2) {
                    //go on with the default cloud_uuid
                    setCloud();
                } else {
                    $scope.wizard.busy = false;
                }
            }, rsp => {
                $scope.editable.errors = rsp.data;
                $scope.wizard.busy = false;
            });

        $scope.applicationProfileCollection.setParams({
            'name.in': _.pluck(vsSettingsByType, 'appProfileName').join(),
        });

        $scope.healthMonitorCollection.setParams({
            'name.in': _.unique(_.pluck(vsSettingsByType, 'hmName')).join(),
        });

        $q.all([
            $scope.applicationProfileCollection.load(),
            $scope.healthMonitorCollection.load(),
            $scope.editable.loadAppProfileData(), //since it is new and doesn't have _ref_data(s)
            $scope.editable.loadNetProfileData(),
        ]).then(() => {
            updateModalSettingsAfterAppProfileChange();
            $scope.editable.setPristine();
        });
    };

    /**
     * Called by setCloud.
     */
    function afterCloudInit() {
        const
            {
                editable: vs,
                Cloud: cloud,
            } = $scope,
            { pool } = vs,
            config = vs.getConfig(),
            { id: cloudId } = cloud;

        config.vsvip_ref_data.setCloudRef(cloud.getRef());

        [
            $scope.networks,
            $scope.poolNetworks,
            serverCollection,
        ]
            .forEach(collection => collection.setParams({ cloud_uuid: cloudId }));

        [
            $scope.poolCollection,
            $scope.vsVipCollection,
        ].forEach(collection => {
            collection.setDefaultItemConfigProps({ cloud_ref: cloud.getRef() });
            collection.setParams({ 'cloud_ref.uuid': cloudId });
        });

        if (cloud.getVtype() === CLOUD_NSXT) {
            cloud.getNsxtNSGroupsByID().then((groups = []) => {
                $scope.securityGroups = groups.map(({ name, id }) => {
                    return createDropdownOption(id, name, `${name} (${id})`);
                });
            });

            if (cloud.getCloudConfig().isVlanTransportZone()) {
                $scope.networks.setParams({ discovered_only: undefined });
            }
        }

        const cloudType = cloud.getVtype();

        if (cloudType === 'CLOUD_AWS' || cloudType === 'CLOUD_AZURE') {
            pool.getAutoscaleGroups()
                .then(asg => $scope.autoscaleGroups = asg || []);
        }

        config.cloud_type = cloudType;

        $scope.poolNetworks.cloudType = cloudType;

        $scope.wizard.current = 1;

        // skipping manual VRF context selection
        if (!cloud.allowCustomVRFContext()) {
            $scope.isVrfOptional = true;

            $scope.onVRFContextSet();
        }

        setTimeout(() => {
            $scope.container
                .find('input[name=vs_name]')
                .trigger('focus');
        }, 5);
    }

    //moving from cloud selection to the settings tab
    const setCloud = () => {
        $scope.wizard.busy = true;

        const
            { editable: vs } = $scope,
            cloudRef = vs.getCloudRef();

        vs.errors = '';

        vs.pool.getConfig()['cloud_ref'] = cloudRef;

        $scope.Cloud = new Cloud({
            id: cloudRef.slug(),
            params: {
                include_name: true,
                join: 'ipamdnsproviderprofile:ipam_provider_ref',
            },
        });

        return $scope.Cloud.load()
            .then(() => afterCloudInit())
            .catch(({ data }) => $q.reject(vs.errors = data))
            .finally(() => $scope.wizard.busy = false);
    };

    $scope.setCloud = () => setCloud()
        .then(() => $scope.editable.setPristine());

    /**
     * Sets wizard.current to 2. Also sets params of networks if vrf_context exists.
     */
    $scope.onVRFContextSet = function() {
        const
            { editable: vs } = $scope,
            vrfContextRef = vs.getVRFContextRef();

        if (vrfContextRef) {
            $scope.poolNetworks.setParams({
                vrf_context_uuid: vrfContextRef.slug(),
            });

            $scope.vsVipCollection.setDefaultItemConfigProps({
                vrf_context_ref: vrfContextRef,
            });

            vs.getVSVip().setVrfContext(vrfContextRef);
        }

        $scope.wizard.current = 2;
    };

    /**
     * Cancelling Basic VS create. We need to destroy the pool instance before dismissing
     * editable.
     */
    $scope.basicCancel = function() {
        $scope.editable.pool.destroy();
        $scope.editable.dismiss(true);
    };

    $scope.basicSave = function() {
        const
            { editable: vs } = $scope,
            vsName = vs.getName(),
            vsConfig = vs.getConfig(),
            { pool } = vs,
            poolConfig = pool.getConfig();

        poolConfig.name = `${vsName.replace(/:/g, '')}-pool`;

        const vrfContextRef = vs.getVRFContextRef();

        if (vrfContextRef) {
            poolConfig['vrf_ref'] = vrfContextRef;
        }

        vsConfig.pool_ref_data = pool.dataToSave();

        vs.submit();
    };

    /**
     * Handler for selecting VS VIP from the dropdown.
     * Sets tier1_lr value in pool config.
     * @param  {VsVip=} VsVip - Selected row from VS VIP dropdown.
     * @public
     **/
    $scope.handleSelectVsVip = function(vsVip) {
        const { editable: { pool } } = $scope;
        const poolConfig = pool.getConfig();

        const { tier1_lr: tier1LR } = vsVip.getConfig();

        // tier1_lr value present for nsxt cloud. For others it will be undefined.
        poolConfig['tier1_lr'] = tier1LR;
    };

    /**
     * Sets pools health monitor and service port corresponding to selected app profile.
     * Called on init and after application profile has been changed by user.
     * @inner
     */
    function updateModalSettingsAfterAppProfileChange() {
        const
            {
                editable: vs,
                healthMonitorCollection,
            } = $scope,
            { pool } = vs,
            appProfileType = vs.appType(),
            { hmName } = vsSettingsByType[appProfileType];

        pool.getConfig()['health_monitor_refs'] = [
            healthMonitorCollection.getItemByName(hmName).getRef(),
        ];

        // Default behavior is not to change anything on application profile change unless
        // selected application profile TYPE has changed. Which doesn't happen on HTTP > HTTPS
        // transition. For this modal we DO want to enforce default ports.
        if (appProfileType === 'http') {
            vs.setDefaultService();

            const config = vs.getConfig();

            //also usual behavior leaves ssl certs in place, we don't want em for HTTP here
            if (!vs.isHTTPS()) {
                config['ssl_key_and_certificate_refs'] = [];
                config['ssl_profile_ref'] = undefined;
            } else {
                config['ssl_key_and_certificate_refs'] = [
                    defaultValues.getSystemObjectRefByName(
                        'sslkeyandcertificate',
                        'System-Default-Cert',
                    ),
                ];

                config['ssl_profile_ref'] = defaultValues.getSystemObjectRefByName(
                    'sslprofile',
                    'System-Standard',
                );
            }
        }

        $scope.onPortChange();
    }

    /**
     * Sets selected application profile on VS instance and calls
     * {@link updateModalSettingsAfterAppProfileChange} afterwards.
     * Event handler for application profile change event. Not an init.
     * @param {Item#data#config#name} appProfileName
     * @public
     */
    $scope.onAppProfileChange = function(appProfileName) {
        const
            { editable: vs, applicationProfileCollection } = $scope,
            appProfile = applicationProfileCollection.getItemByName(appProfileName);

        vs.setAppProfile(appProfile.getRef(), appProfile.getConfig())
            .finally(updateModalSettingsAfterAppProfileChange);
    };

    const loadNetworkServers = networkId => {
        serverCollection.reset();

        serverCollection.setParams({
            cloud_uuid: $scope.Cloud.id,
            network_uuid: networkId,
        });

        serverCollection.load();
    };

    $scope.networkSelected = function() {
        let netRef;

        if (netRef = $scope.ui.selectedNetwork) {
            loadNetworkServers(netRef.slug());
        }
    };

    /**
     * If IPAddrGroup is selected, adds servers by single IP addresses or a range. Before
     * adding, a check is done on the IP group to make sure there aren't
     * too many addresses than currently supported.
     * @param  {IpAddrGroup=} ipAddrGroup - Selected row from IP Group dropdown. Undefined when
     *     called on clear event.
     * @public
     **/
    //TODO share this method between PoolCreate and VSBasicCreateControllers
    $scope.ipAddrGroupAdd = function(ipAddrGroup) {
        deletePoolServers();

        if ($scope.ui.errors) {
            $scope.ui.errors = '';
        }

        if (ipAddrGroup) {
            const numberOfIps = ipAddrGroup.getNumberOfIPs();

            if (numberOfIps > poolServersLimit) {
                $scope.ui.errors = l10nService.getMessage(
                    l10nKeys.maxIpAddressesOverflowErrorMessage,
                    [$filter('number')(numberOfIps), poolServersLimit],
                );

                $scope.editable.pool.getConfig().ipaddrgroup_ref = undefined;
            } else {
                const ips = ipAddrGroup.getIPs();

                addServersToPool(ips.map(ip => ({ ip })));
            }
        }
    };

    /**
     * Called when selecting a security group from the dropdown. Makes a request to get addresses
     * for populating the servers grid.
     */
    $scope.handleSelectSecurityGroup = function() {
        deletePoolServers();

        const { pool } = $scope.editable;

        pool.getSecurityGroupAddresses()
            .then(addServersToPool);
    };

    /**
     * Handler for selecting an AutoScale group from the dropdown, or clearing one. Gets a list of
     * servers for that AutoScale group.
     */
    $scope.handleSelectAutoscaleGroup = function() {
        $scope.editable.pool.getAutoscaleGroupServers()
            .then(servers => {
                deletePoolServers();
                addServersToPool(servers);
            });
    };

    $scope.openNetworkPanel = function($event) {
        $event.stopPropagation();
        $scope.resetNetworkPanel();
        $('.pool-server .left-panel').addClass('open');
        $scope.networkPanelOpened = true;
    };

    $scope.closeNetworkPanel = function() {
        $('.pool-server .left-panel').removeClass('open');

        $scope.resetNetworkPanel();
        $scope.networkPanelOpened = false;
    };

    $scope.resetNetworkPanel = function() {
        $scope.ui.selectedNetwork = undefined;

        // If grid was initialized otherwise if reset exists
        if ($scope.networkServersGrid && $scope.networkServersGrid.reset) {
            $scope.networkServersGrid.reset();
        }
    };

    /**
     * Returns true if the Security Groups option should be shown in Select Servers.
     * @returns {boolean}
     */
    $scope.showSecurityGroupsOption = () => {
        const vtype = $scope.Cloud.getVtype();

        return vtype === 'CLOUD_NSXT';
    };

    /**
     * To set default name of vsvip created from current modal window.
     * @public
     */
    $scope.onNameChange = function() {
        const vsName = $scope.editable.getName();
        const newName = vsName ? vsName.replace(/:/g, '') : '';

        $scope.vsVipCollection.setDefaultItemConfigProps({ name: `${newName}-VsVip` });
    };

    $scope.$on('$destroy', () => {
        const colls = [
            $scope.cloudCollection,
            $scope.applicationProfileCollection,
            $scope.networkProfileCollection,
            $scope.healthMonitorCollection,
            $scope.networks,
            $scope.poolNetworks,
            $scope.poolCollection,
            $scope.certificateCollection,
            serverCollection,
            $scope.vsVipCollection,
        ];

        colls.forEach(collection => collection.destroy());
    });
}

virtualServiceCreateBasicController.$inject = [
    '$scope',
    '$controller',
    'Regex',
    '$q',
    'HealthMonitorCollection',
    'VMNicIPCollection',
    '$filter',
    'Cloud',
    'poolServersLimit',
    'Server',
    'defaultValues',
    'l10nService',
    'VsVipCollection',
];
