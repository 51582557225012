/**
 * @module LogsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Injectable,
    Type,
} from '@angular/core';

import {
    IApplyLogRecommendations,
    IGetLogRecommendations,
    IRecommendationResponse,
    RecommendationRequestType,
} from 'generated-types';

import { IHttpPromise } from 'angular';
import { HttpMethod, HttpWrapper } from 'ajs/modules/core/factories/http-wrapper';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { RecommendationDialogComponent }
    from '../components/recommendation-dialog/recommendation-dialog.component';

type THttpWrapper = typeof HttpWrapper;

export interface IApplyLogRecommendationsResult {
    status: string;
}

/**
 * Constants used for API url.
 */
const BASE_URL = '/api/virtualservice/';
const LOG_RECOMMENDATAION = '/log-recommendation';
const APPLY_LOG_RECOMMENDATAION = '/apply-log-recommendation';

/**
 * ID for Log Recommendation Dialog.
 */
const RECOMMENDATION_DIALOG_ID = 'recommendation-dialog';

/**
 * @description Service to open/close the Recommendation dialog and make API calls.
 * @author vgohil
 */
@Injectable()
export class RecommendationDialogService {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
        private readonly dialogService: DialogService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Method to open Recommendation dialog.
     */
    public openDialog(
        vsUuid: string,
        reportTimestamp: number,
        requestId: string,
    ): void {
        const recommendationRequest = {
            request_id: requestId,
            report_timestamp: `${reportTimestamp}`,
            type: RecommendationRequestType.RECOMMENDATION_REQUEST_FALSE_POSITIVE,
        };

        this.dialogService.add({
            id: RECOMMENDATION_DIALOG_ID,
            component: RecommendationDialogComponent as Type<Component>,
            componentProps: {
                vsUuid,
                recommendationRequest,
                onClose: () => this.closeDialog(),
            },
        });
    }

    /**
     * Method to close Recommendation dialog.
     */
    public closeDialog(): void {
        this.dialogService.remove(RECOMMENDATION_DIALOG_ID);
    }

    /**
     * Method to check if Recommendation dialog is open.
     */
    public isOpen(): boolean {
        return this.dialogService.has(RECOMMENDATION_DIALOG_ID);
    }

    /**
     * Method to fetch the Recommendation for given virtualservice uuid.
     */
    public fetchRecommendation(
        recommendationRequests: IGetLogRecommendations,
        vsUuid: string,
    ): IHttpPromise<IRecommendationResponse> {
        const requestConfig = {
            url: `${BASE_URL}${vsUuid}${LOG_RECOMMENDATAION}`,
            method: HttpMethod.POST,
            data: recommendationRequests,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Method to apply the Recommendation.
     */
    public applyRecommendation(
        applyRecommendations: IApplyLogRecommendations,
        vsUuid: string,
    ): IHttpPromise<IApplyLogRecommendationsResult> {
        const requestConfig = {
            url: `${BASE_URL}${vsUuid}${APPLY_LOG_RECOMMENDATAION}`,
            method: HttpMethod.POST,
            data: applyRecommendations,
        };

        return this.httpWrapper.request(requestConfig);
    }
}
