<div class="login-container">
    <div class="login-wrapper">
        <div class="login">
            <section class="title login-container__title">
                <div>VMware</div>
                <div>Avi Load Balancer</div>
            </section>
            <!-- Projected content goes here -->
            <ng-content></ng-content>
        </div>
    </div>
</div>
