/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing login related components.
 * @module LoginModule
 * @preferred
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import {
    ClusterPollingProgressBarModule,
} from 'ng/modules/cluster-polling-progress-bar/cluster-polling-progress-bar.module';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import {
    AdminUserSetupComponent,
    AlbLoginComponent,
    LoginBannerComponent,
    LoginContainerComponent,
    LoginPageComponent,
    PasswordChangeComponent,
    ResetPasswordComponent,
} from '.';

const loginComponents = [
    AdminUserSetupComponent,
    AlbLoginComponent,
    LoginContainerComponent,
    LoginPageComponent,
    PasswordChangeComponent,
    ResetPasswordComponent,
    LoginBannerComponent,
];

@NgModule({
    declarations: [
        ...loginComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        FormsModule,
        ClrFormsModule,
        ClrInputModule,
        SharedModule,
        ClusterPollingProgressBarModule,
    ],
    exports: [
        LoginPageComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LoginModule {}
