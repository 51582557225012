/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'user';

export const allTenants = `${prefix}.allTenants`;

export const ENGLISH = {
    [allTenants]: 'All Tenants',
};
