/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name wafEnable
 * @param {string} type - Either 'group' or 'rule'.
 * @param {string} name - Either the group name or the rule ID.
 * @param {WafPolicy} wafPolicy
 * @param {Function} onEnableChange - Called when the switch is clicked.
 */
class WafEnableController {
    /**
     * Tracks whether or not the groupName or ruleId exists in the WAF Policy.
     */
    nameExistsInWafPolicy = false;

    /**
     * Enabled state of the group or rule. 1 for enabled, 0 for not enabled.
     */
    enabled = 0;

    /**
     * Name to be displayed for the group or rule.
     */
    name = '';

    $onInit() {
        const { name } = this.topHitsListRecord;

        this.nameExistsInWafPolicy = this.type === 'group' ?
            this.wafPolicy.hasGroup(name) :
            this.wafPolicy.hasRule(name.toString());

        this.setFields();

        this.wafPolicy.bind('itemLoadSuccess', this.setFields);
    }

    $onDestroy() {
        this.wafPolicy.unbind('itemLoadSuccess', this.setFields);
    }

    /**
     * Returns true if the tri-switch should be hidden.
     * @return {boolean}
     */
    isDisabled() {
        return !this.nameExistsInWafPolicy || this.wafPolicy.isBusy();
    }

    /**
     * Set the name to be displayed.
     * @return {string}
     */
    setName() {
        const { name } = this.topHitsListRecord;

        this.name = this.type === 'rule' ?
            this.wafPolicy.getRuleNameByRuleId(name.toString(), true) || name :
            name;
    }

    /**
     * Set the state of the tri-switch, either enabled or disabled.
     * @return {number} 1 or 0.
     */
    setEnabled() {
        if (!this.nameExistsInWafPolicy) {
            this.enabled = 0;
        }

        const { name } = this.topHitsListRecord;
        let enabled;

        if (this.type === 'group') {
            enabled = this.wafPolicy.getGroupEnabledState(name);
        } else {
            enabled = this.wafPolicy.getRuleEnabledState(name.toString());
        }

        this.enabled = enabled ? 1 : 0;
    }

    /**
     * Set the name and enabled state.
     */
    setFields = () => {
        this.setName();
        this.setEnabled();
    }

    /**
     * Handler for clicking the switch. Sets the enable flag on the configItem and calls the
     * onEnableChange function. onEnableChange should trigger a WafPolicy save.
     */
    handleEnableSwitchClick() {
        if (!this.nameExistsInWafPolicy) {
            return;
        }

        const { name } = this.topHitsListRecord;

        if (this.type === 'group') {
            const enabled = this.wafPolicy.getGroupEnabledState(name);

            this.wafPolicy.setGroupEnabledState(name, !enabled);
        } else {
            const enabled = this.wafPolicy.getRuleEnabledState(name.toString());

            this.wafPolicy.setRuleEnabledState(name.toString(), !enabled);
        }

        this.setEnabled();
        this.onEnableChange();
    }
}

angular.module('aviApp').component('wafEnable', {
    controller: WafEnableController,
    bindings: {
        topHitsListRecord: '<',
        type: '<',
        wafPolicy: '<',
        onEnableChange: '&',
    },
    templateUrl: 'src/components/pages/application/vs/vs-waf-page/waf-enable/waf-enable.html',
});
