/** @module VsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { isEmpty, isUndefined } from 'underscore';
import { IVip } from 'generated-types';
import { IExtendedVipRuntimeDetail, OperStateType } from '../../vs.types';
import template from './vs-address-grid-cell.html';
import './vs-address-grid-cell.less';

const stateIconClassPrefix = 'vs-address-grid-cell__state-icon--';

/**
 * @description  Displays a list of addresses in the Virtual Service grid.
 * @author Ram Pal
 */
class VsAddressGridCellController {
    /**
     * currentOperStateType property holds current state of vip address in terms of up/down/pending
     * and currently it is used by vipListPopover component.
     */
    public currentOperStateType: OperStateType;

    /**
     * Property holding OperStateType.
     */
    public readonly operStateType = OperStateType;

    /**
     * List of vips having addresses and their states.
     */
    public vips: IExtendedVipRuntimeDetail[];

    /**
     * Representing current virtual service grid address.
     */
    public vs: any;

    /**
     * Property holding popover object.
     */
    private vipListPopover: any = null;

    constructor(
        private readonly $scope: ng.IScope,
        private readonly $compile: ng.ICompileService,
        private readonly PopoverFactory: any,
    ) {}

    /**
     * Returns a list of vip addresses.
     */
    private static vipSummaryReducer(acc: string[], vip: IVip): string[] {
        let addr = '';

        if (!isEmpty(vip.ip_address)) {
            addr = vip.ip_address.addr;

            if (!isEmpty(vip.floating_ip)) {
                addr += `(${vip.floating_ip.addr})`;
            }
        } else if (!isEmpty(vip.floating_ip)) {
            addr = vip.floating_ip.addr;
        }

        if (!isEmpty(vip.ip6_address)) {
            if (addr.length > 0) {
                addr += ', ';
            }

            addr += vip.ip6_address.addr;

            if (!isEmpty(vip.floating_ip6)) {
                addr += `(${vip.floating_ip6.addr})`;
            }
        } else if (!isEmpty(vip.floating_ip6)) {
            addr = vip.floating_ip6.addr;
        }

        acc.push(addr);

        return acc;
    }

    /** @override */
    public $onInit(): void {
        this.setPopoverInstance();
    }

    /**
     * Getter function which returns list of vipRuntime.
     */
    public get vipRuntimeList(): IExtendedVipRuntimeDetail[] {
        return this.vs.getRuntimeData()?.vip_summary as IExtendedVipRuntimeDetail[] ?? [];
    }

    /**
     * function to close existig vip summary popover.
     */
    public closeVipListPopover(): void {
        this.vipListPopover.hide();
        this.vips = [];
        this.currentOperStateType = null;
    }

    /**
     * Returns runtime object having operStateType as 'Up'.
     */
    public get vipsWithUpState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_UP);
    }

    /**
     * Returns runtime object having operStateType as 'Down'.
     */
    public get vipsWithDownState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_DOWN);
    }

    /**
     * Returns runtime object having operStateType as 'Pending'.
     */
    public get vipsWithPendingState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_PENDING);
    }

    /**
     * Returns a string of comma-separated VIP addresses.
     */
    public getVipListAsString(): string {
        const runtime = this.vs.getRuntimeData();

        if (isUndefined(runtime) || !Array.isArray(runtime.vip_summary)) {
            return '';
        }

        const vips = runtime.vip_summary
            .reduce(VsAddressGridCellController.vipSummaryReducer, []);

        return vips.join(', ');
    }

    /**
     * Returns class for circle arrow icon based on vip state.
     */
    public getVipStateIconClass(operStateType: OperStateType): string {
        return stateIconClassPrefix + operStateType;
    }

    /**
     * Returns icon shape based on operStateType.
     */
    public getIconShape(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
            case OperStateType.OPER_DOWN:
                return 'circle-arrow';

            case OperStateType.OPER_PENDING:
                return 'minus-circle';

            default:
                return '';
        }
    }

    /**
     * Returns icon direction based on operStateType.
     */
    public getIconDirection(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
                return 'up';

            case OperStateType.OPER_DOWN:
                return 'down';

            default:
                return '';
        }
    }

    /** @override */
    public $onDestroy(): void {
        this.vipListPopover.remove();
    }

    /**
     * Opens popover with details like vips and OperStateType('Up', 'Down', 'Pending').
     */
    public openVipListPopover(operStateType: OperStateType, $event: MouseEvent): void {
        this.currentOperStateType = operStateType;
        this.vips = this.getVipsByState(operStateType);
        this.vipListPopover.show($event.target);
    }

    /**
     * Returns runtime object based on OperStateType ('UP', 'DOWN', 'PENDING');
     */
    private getVipsByState(operStateType: OperStateType): IExtendedVipRuntimeDetail[] {
        return this.vipRuntimeList
            .filter((runtimeItem: IExtendedVipRuntimeDetail) => {
                return runtimeItem.operStateType === operStateType;
            });
    }

    /**
     * Compile popover template and set an instance of PopoverFactory.
     */
    private setPopoverInstance(): void {
        const popoverTemplate = `
            <vip-list-popover
                vips="$ctrl.vips"
                oper-state-type="$ctrl.currentOperStateType"
                on-close="$ctrl.closeVipListPopover()"
            ></vip-list-popover>`;

        const $popoverContent = $(popoverTemplate);

        this.vipListPopover = new this.PopoverFactory({
            position: 'right',
            repositioning: true,
            removeAfterHide: true,
            html: this.$compile($popoverContent)(this.$scope),
            carat: true,
            hide: {
                outClick: true,
                onEscape: true,
            },
        });
    }
}

VsAddressGridCellController.$inject = [
    '$scope',
    '$compile',
    'popoverFactory',
];

export const vsAddressGridCellOptions = {
    bindings: {
        vs: '<',
    },
    controller: VsAddressGridCellController,
    template,
};
