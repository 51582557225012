<avi-state-page class="dns-records">
    <avi-state-page_main-content>
        <avi-card class="dns-records__card">
            <avi-card_header>
                <cds-icon
                    shape="view-list"
                    size="24"
                    status="success"
                ></cds-icon>
                {{ l10nKeys.totalValuesHeader | vtranslate }}
            </avi-card_header>
            <avi-card_body>
                <div class="dns-records__value">
                    <span>{{ l10nKeys.columnTitleUdpQueries | vtranslate }}</span>
                    <span>{{ getAbbreviatedValue(getAverageCompletedQueries(), 2) }}</span>
                </div>
            </avi-card_body>
            <avi-card_footer>
                <div class="dns-records__value">
                    <span>{{ l10nKeys.columnTitleErrors | vtranslate }}</span>
                    <span>{{ getAbbreviatedValue(getAverageErroredQueries()) }}</span>
                </div>
            </avi-card_footer>
        </avi-card>

        <avi-collection-data-grid [config]="fqdnListGridConfig">
        </avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>
