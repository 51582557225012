<div class="prechecks-list-expander">
    <clr-accordion 
        class="prechecks-list-expander__accordian"
        [clrAccordionMultiPanel]="true"
    >
        <clr-accordion-panel
            #clrPanel
            [clrAccordionPanelOpen]="item.key !== 'success' && item.value.length > 0"
            *ngFor="let item of groupedPrechecks | keyvalue: unsorted; trackBy: trackByIndex"
        >
            <clr-accordion-title>{{ precheckGroupHash[item.key] | vtranslate : item.value.length }}</clr-accordion-title>

            <clr-accordion-content>
                <prechecks-list [prechecks]="item.value"></prechecks-list>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>
