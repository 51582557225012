/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { pluck } from 'underscore';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { ISubdomainGridData } from '../gslb-subdomains-list-page.component';
import * as l10n from './gslb-subdomains-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for displaying GSLB Subdomains.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gslb-subdomains-grid',
    templateUrl: './gslb-subdomains-grid.component.html',
})
export class GslbSubdomainsGridComponent implements AfterViewInit {
    /**
     * List of Subdomains from GSLB.
     */
    @Input()
    public subdomains: ISubdomainGridData[];

    /**
     * Show loading spinner if true.
     */
    @Input()
    public isLoading: boolean;

    /**
     * Show actions if true.
     */
    @Input()
    public isGslbEditable: boolean;

    /**
     * Fire on edit subdomain.
     */
    @Output()
    public onEditSubdomain = new EventEmitter<string>();

    /**
     * Fire on Remove subdomains.
     */
    @Output()
    public onRemoveSubdomains = new EventEmitter<string[]>();

    /**
     * Template ref for displaying site names of GSLB.
     */
    @ViewChild('siteFieldTemplateRef')
    public siteFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for displaying DNS VS names of GSLB sites.
     */
    @ViewChild('dnsVsFieldTemplateRef')
    public dnsVsFieldTemplateRef: TemplateRef<HTMLElement>;

    public subdomainsGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.subdomainsGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.subdomainLabel),
                    id: 'subdomain',
                    transform: (row: ISubdomainGridData): string => row.subdomain || '',
                },
                {
                    label: l10nService.getMessage(l10nKeys.gslbSiteLabel),
                    id: 'site',
                    templateRef: this.siteFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.dnsVsLabel),
                    id: 'dnsVs',
                    templateRef: this.dnsVsFieldTemplateRef,
                },
            ],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (row: ISubdomainGridData) => {
                    this.onEditSubdomain.emit(row.subdomain);
                },
                hidden: () => !this.isGslbEditable,
            },
            {
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (row: ISubdomainGridData) => {
                    this.onRemoveSubdomains.emit([row.subdomain]);
                },
                hidden: () => !this.isGslbEditable,
            }],
            multipleactions: this.isGslbEditable ? [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (rows: ISubdomainGridData[]) => {
                    this.onRemoveSubdomains.emit(pluck(rows, 'subdomain'));
                },
            }] : [],
            searchKeys: ['subdomain'],
        };
    }
}
