/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    IHttpWrapperRequestConfig,
} from '../http-wrapper/http-wrapper.service';

/**
 * States representing different stages of the upload process. Used together with
 * this.isState(state) and this.setState(state).
 *
 *     UPLOAD_STATE_IDLE: 'idle' - Nothing is in process.
 *     UPLOAD_STATE_IN_PROGRESS: 'in_progress' - If a file upload is currently in progress.
 *     UPLOAD_STATE_PROCESSING: 'processing' - Further proceure is in action(e.g. collate) after all
 *     UPLOAD_STATE_COMPLETE: 'complete' - The whole upload process is completed and successful.
 *     UPLOAD_STATE_ERROR: 'error' - File upload failed with errors.
 *     UPLOAD_STATE_ABORTED : 'aborted' - File upload is aborted in its mid way.
 */
export enum UploadState {
    UPLOAD_STATE_IDLE = 'idle',
    UPLOAD_STATE_IN_PROGRESS = 'in_progress',
    UPLOAD_STATE_PROCESSING = 'processing',
    UPLOAD_STATE_COMPLETE = 'complete',
    UPLOAD_STATE_ERROR = 'error',
    UPLOAD_STATE_ABORTED = 'aborted',
}

const DEFAULT_UPLOAD_REQUEST_ID = 'DEFAULT_UPLOAD_REQUEST_ID';

/**
 * Returns a destination API string. Destination URL may already contain params, so we need
 * to figure out if we need to start with a '?' or '&' for the rest of the params.
 * @param destination - Destination URL.
 * @param params - Array of params.
 */
const destinationBuilder = (destination: string, params: string[]): string => {
    const paramsString = params.join('&');
    let sign = '&';

    if (destination.indexOf('?') < 0) {
        sign = '?';
    }

    return destination + sign + paramsString;
};

/**
 * Configs used to make file upload calls. Can contain fields with name of destination and uri.
 */
interface IUploadServiceConfig {
    /**
     * Where file should be sent. API path. Required config field to send a file.
     */
    destination?: string;

    /**
     * FormData property used by controller to determine where the file should be referenced.
     */
    uri?: string;

    /**
     * Request ID used for cancelling the request. If undefined, uses the DEFAULT_UPLOAD_REQUEST_ID.
     */
    requestId?: string;
}

/**
 * Object holding status for upload process.
 */
interface IUploadStatus {
    /**
     * Percent number of data uploaded. Ratio of loaded/total.
     */
    percent: number;

    /**
     * Size of data uploaded, in bytes. Does not seem to be that accurate as even when
     * loaded === total, the upload request may not be returned, possibly due to the
     * controller/system moving the file to the uri destination.
     */
    loaded: number;

    /**
     * Total size of data being uploaded, in bytes.
     */
    total: number;
}

/**
 * @description
 *     Service for uploading files to the controller. Responsible for making a send request, setting
 *     the state of the upload and maintaining the upload status.
 * @author alextsg, Zhiqian Liu
 */
export class Upload extends AjsDependency {
    /**
     * Upload state.
     */
    public state: UploadState = UploadState.UPLOAD_STATE_IDLE;

    /**
     * Backend request error.
     */
    public error = '';

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * Object holding status for upload process.
     */
    private uploadStatus: IUploadStatus = {
        percent: 0,
        loaded: 0,
        total: 0,
    };

    /**
     * Where file should be sent. API path. Required to send a file.
     */
    private defaultDestination: string;

    /**
     * FormData property used by controller to decide where the file should be referenced.
     */
    private defaultUri: string;

    /**
     * Request ID used for cancelling the request.
     */
    private requestId: string;

    constructor(args: IUploadServiceConfig = {}) {
        super();

        const { destination, requestId, uri } = args;

        this.defaultDestination = destination || '';
        this.defaultUri = uri || '';
        this.requestId = requestId || DEFAULT_UPLOAD_REQUEST_ID;

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Compares current upload state.
     */
    public isState(state: UploadState): boolean {
        return this.state === state;
    }

    /**
     * Get upload percentage.
     */
    public getUploadPercentage(): number {
        if (!this.isInProgress()) {
            return NaN;
        }

        return this.uploadStatus.percent;
    }

    /**
     * Checks the inProgress state of the upload. True if upload is in progress or processing,
     * false otherwise.
     */
    public isInProgress(): boolean {
        return this.isState(UploadState.UPLOAD_STATE_IN_PROGRESS);
    }

    /**
     * Send file and track the progress.
     * @param file - File to be uploaded.
     * @param filename - Name of file at destination.
     * @param destination - Where file should be sent.
     * @param uri - FormData property used by controller to determine where the file should be
     *     referenced.
     */
    public async send(
        file: File,
        filename: string,
        destination?: string,
        uri?: string,
    ): Promise<any> {
        if (!file) {
            throw new Error('File must be defined.');
        }

        destination = destination || this.defaultDestination;

        if (!destination) {
            throw new Error('Destination must be defined.');
        }

        const auth = this.getAjsDependency_('Auth');
        const $interval = this.getAjsDependency_('$interval');
        const {
            UPLOAD_STATE_COMPLETE,
            UPLOAD_STATE_ABORTED,
            UPLOAD_STATE_IN_PROGRESS,
            UPLOAD_STATE_ERROR,
        } = UploadState;

        uri = uri || this.defaultUri;

        this.resetUploadStatus();
        this.uploadStatus.total = file.size;
        this.error = '';
        this.setState(UPLOAD_STATE_IN_PROGRESS);

        const resetSessionInterval: ng.IIntervalService =
            $interval(() => auth.emulateUserActivity(), 30000);

        try {
            const request = await this.upload(file, filename, destination, uri);

            this.setState(UPLOAD_STATE_COMPLETE);

            return request;
        } catch (errors) {
            if (!this.isState(UPLOAD_STATE_ABORTED)) {
                this.setState(UPLOAD_STATE_ERROR);
                this.error = errors.data && errors.data.error || 'Upload failed';
            }

            return Promise.reject(errors);
        } finally {
            $interval.cancel(resetSessionInterval);
        }
    }

    /**
     * Cancel upload POST request and reset states.
     */
    public cancelUpload(): void {
        // cancel only when there is request pending
        if (this.isInProgress()) {
            this.httpWrapper.cancelAllRequests();
            this.setState(UploadState.UPLOAD_STATE_ABORTED);
        } else {
            throw new Error('Can\'t cancel, no upload is pending!');
        }
    }

    /**
     * Set current upload state.
     */
    private setState(state: UploadState): void {
        this.state = state;
    }

    /**
     * Reset upload status object with initial values.
     */
    private resetUploadStatus(): void {
        const { uploadStatus } = this;

        uploadStatus.loaded = 0;
        uploadStatus.total = 0;
        uploadStatus.percent = 0;
    }

    /**
     * Update upload status by progress event.
     */
    private updateUploadStatus = (event: ProgressEvent): void => {
        const { uploadStatus } = this;

        if (event.lengthComputable) {
            uploadStatus.loaded = event.loaded;
            uploadStatus.total = event.total;
            uploadStatus.percent = Math.ceil(uploadStatus.loaded / uploadStatus.total * 100);
        }
    };

    /**
     * Send file with XMLHttpRequest (wrapped in $http used in Base.request) and FormData.
     * @param file - File to be uploaded.
     * @param filename - Name of file at destination.
     * @param destination - Where file should be sent.
     * @param uri - FormData property used by controller to determine where the file
     *     should be referenced.
     * @param params - Parameter list for making http request.
     */
    private async upload(
        file: File,
        fileName: string,
        destination?: string,
        uri?: string,
        params: string[] = [],
    ): Promise<any> {
        const data = new FormData();

        data.append('file', file, fileName);

        if (uri) {
            data.append('uri', uri);
        }

        const uploadRequestConfig: IHttpWrapperRequestConfig = {
            method: HttpMethod.POST,
            url: destinationBuilder(destination, params),
            data,
            requestId: this.requestId,
            // For FormData, manually setting Content-Type will cause the header missing
            // boundary param which in turn raises 400 Bad Request error.
            // See details: https://github.com/github/fetch/issues/505#issuecomment-293064470.
            headers: { 'Content-Type': undefined },
            uploadEventHandlers: {
                progress: this.updateUploadStatus,
            },
        };

        try {
            return await this.httpWrapper.request(uploadRequestConfig);
        } catch (errors) {
            return Promise.reject(errors);
        } finally {
            this.resetUploadStatus();
        }
    }
}

Upload.ajsDependencies = [
    '$interval',
    'Auth',
    HTTP_WRAPPER_TOKEN,
];
