/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NotificationsModule
 */

import { Item } from 'item';

interface IControllerFaultDetails {
    description: string;
    name: string;
    status: string;
    valid_until: string;
}

interface IControllerFault {
    type: string;
    faults: IControllerFaultDetails[];
    // Name actually does not exist in ControllerFault item config.
    // but setting this property to make it compatible with IItemGenericConfig
    // since a controller fault is not a real Item.
    name?: string;
}

interface IControllerFaultData {
    config: IControllerFault;
}

/**
 * @description Controller Faults Item.
 * @author Alex Tseung, Alex Malitsky, Aravindh Nagarajan
 */
export class ControllerFault extends Item<IControllerFault> {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'controller-inventory',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: IControllerFaultData): string {
        const { config } = data;

        return config?.type || '';
    }
}
