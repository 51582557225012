/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-body';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const placeholderMessage = `${prefix}.placeholderMessage`;

export const ENGLISH = {
    [headerLabel]: 'Error Page Body',
    [columnTitleName]: 'Name',
    [placeholderMessage]: 'No Items Found',
};
