<cds-modal
    class="clr-wrapper"
    size="lg"
    (closeChange)="handleClose()"
>

    <cds-modal-header>
        <h3>{{ globalL10nKeys.uploadFileLabel | vtranslate }}</h3>
    </cds-modal-header>

    <cds-modal-content>
        <div class="pki-crl-file-upload-dialog__container">

            <div
                [hidden]="!errorMessage"
                class="pki-crl-file-upload-dialog__alert"
            >
                <avi-alert-group alertType="danger">
                    <span>{{ errorMessage | aviBackendErrorMsg }}</span>
                </avi-alert-group>
            </div>

            <label aviLabelWithTooltip>
                {{ l10nKeys.uploadCrlListLabel | vtranslate }}
            </label>
            <async-file-upload
                name="certificate-revocation-list-file"
                [(ngModel)]="crlFileContent"
                (ngModelChange)="handleFileSelectChange()"
                (onFileUpload)="onCrlFileUpload()"
                [maxFileSize]="10"
                [loadingState]="loadingState"
            ></async-file-upload>

            <avi-checkbox
                [(ngModel)]="isFederated"
                name="is-federated"
                [disabled]="isPkiProfileFederated"
            >
                {{ globalL10nKeys.isFederatedLabel | vtranslate }}
            </avi-checkbox>
        </div>
    </cds-modal-content>

</cds-modal>
