/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'control-scripts-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const controlScriptInputLabel = `${prefix}.controlScriptInputLabel`;
export const controlScriptInputPlaceholder = `${prefix}.controlScriptInputPlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Control Script',
    [modalTitleNew]: 'New Control Script',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [controlScriptInputLabel]: 'Import or Paste Control Script',
    [controlScriptInputPlaceholder]: 'Paste Control Script',
    [sectionTitleGeneral]: 'General',
};
