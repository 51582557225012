/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module NetworkModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { NetworkProfile as NetworkProfileObjectType } from 'object-types';

import {
    AviPermissionResource,
    IDsrProfile,
} from 'generated-types';

import {
    INetworkProfileConfig,
    INetworkProfileConfigWithDsr,
    INetworkProfileData,
    ProtocolTypes,
    TNetworkProfile,
} from './network-profile.item.types';

import * as l10n from './network-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * NetworkProfile Item class for setting up available network profile types.
 * @author Ratan Kumar, Shibam Biswas
 */
export class NetworkProfile extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
        'schemaService',
    ];

    /**
     * Hash of all available network profile types.
     */
    public static typesHash = {
        PROTOCOL_TYPE_TCP_FAST_PATH: ProtocolTypes.PROTOCOL_TYPE_TCP_FAST_PATH,
        PROTOCOL_TYPE_TCP_PROXY: ProtocolTypes.PROTOCOL_TYPE_TCP_PROXY,
        PROTOCOL_TYPE_UDP_FAST_PATH: ProtocolTypes.PROTOCOL_TYPE_UDP_FAST_PATH,
        PROTOCOL_TYPE_UDP_PROXY: ProtocolTypes.PROTOCOL_TYPE_UDP_PROXY,
        PROTOCOL_TYPE_SCTP_FAST_PATH: ProtocolTypes.PROTOCOL_TYPE_SCTP_FAST_PATH,
        PROTOCOL_TYPE_SCTP_PROXY: ProtocolTypes.PROTOCOL_TYPE_SCTP_PROXY,
    };

    /**
     * Hash of all available network profile type config.
     */
    public static typeConfigHash = {
        PROTOCOL_TYPE_TCP_FAST_PATH: 'tcp_fast_path_profile',
        PROTOCOL_TYPE_TCP_PROXY: 'tcp_proxy_profile',
        PROTOCOL_TYPE_UDP_FAST_PATH: 'udp_fast_path_profile',
        PROTOCOL_TYPE_UDP_PROXY: 'udp_proxy_profile',
        PROTOCOL_TYPE_SCTP_FAST_PATH: 'sctp_fast_path_profile',
        PROTOCOL_TYPE_SCTP_PROXY: 'sctp_proxy_profile',
    };

    /**
     * List of all available network profile types. ProtocolTypes enum.
     * Referred while changing application profile in VS.
     */
    public static types = [
        ProtocolTypes.PROTOCOL_TYPE_TCP_FAST_PATH,
        ProtocolTypes.PROTOCOL_TYPE_TCP_PROXY,
        ProtocolTypes.PROTOCOL_TYPE_UDP_FAST_PATH,
        ProtocolTypes.PROTOCOL_TYPE_UDP_PROXY,
        ProtocolTypes.PROTOCOL_TYPE_SCTP_FAST_PATH,
        ProtocolTypes.PROTOCOL_TYPE_SCTP_PROXY,
    ];

    /**
     * network profile data.
     */
    public data: INetworkProfileData;

    /**
     * Function to get network profile config.
     */
    public getConfig: () => INetworkProfileConfig;

    /**
     * Flag to check if DSR Profile is enabled.
     */
    private dsrEnabled: boolean;

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'networkprofile',
            objectType: NetworkProfileObjectType,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_NETWORKPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { NetworkProfileModalComponent } = await import(
            /* webpackChunkName: "network-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/network-profile-modal/network-profile-modal.component'
        );

        return NetworkProfileModalComponent as Type<Component>;
    }

    /**
     * Method will be called on network profile type change.
     */
    public onTypeChange(): void {
        const { profile } = this.getConfig();
        const type = this.getType();
        const selectedType = NetworkProfile.typeConfigHash[type];

        profile.resetNetworkProfileType(selectedType);
    }

    /** @override */
    public dataToSave(): INetworkProfileConfig {
        const config = super.dataToSave();
        const type = this.getType();
        const { profile } = config;
        const networkType = NetworkProfile.typeConfigHash[type];

        if (!this.dsrEnabled && profile && profile[networkType]?.dsr_profile) {
            delete profile[networkType].dsr_profile;
        }

        return config;
    }

    public setDsrEnabled(dsrEnabled: boolean): void {
        this.dsrEnabled = dsrEnabled;
    }

    /**
     * Returns network profile type.
     */
    public getType(): ProtocolTypes {
        const { profile } = this.getConfig() || {};

        return profile?.config.type as unknown as ProtocolTypes;
    }

    /**
     * Check if Dsr profiles is supported or not.
     */
    public supportsDsrProfile(): boolean {
        const networkProfileType = this.getType();
        const {
            PROTOCOL_TYPE_TCP_FAST_PATH,
            PROTOCOL_TYPE_UDP_FAST_PATH,
        } = NetworkProfile.typesHash;

        return networkProfileType === PROTOCOL_TYPE_TCP_FAST_PATH ||
            networkProfileType === PROTOCOL_TYPE_UDP_FAST_PATH;
    }

    /**
     * Get Profile config of selected type.
     */
    public getProfileConfig(): TNetworkProfile {
        const { profile } = this.getConfig() || {};
        const type = this.getType();

        return profile.config[NetworkProfile.typeConfigHash[type]] || {};
    }

    /**
     * Method will return network profile with Dsr profile data.
     */
    public get dsrProfile(): MessageItem<IDsrProfile> {
        const profileConfig = this.getProfileConfig() as unknown as INetworkProfileConfigWithDsr;

        return profileConfig.config.dsr_profile;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.NetworkProfileModalBreadcrumbTitle);
    }
}
