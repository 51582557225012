/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    ISnmpConfiguration,
    SNMP_VER,
} from 'generated-types';

import {
    SnmpConfiguration,
    SnmpV3Configuration,
    SnmpV3UserParams,
} from 'object-types';

import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './system-settings-snmp-config.l10n';
import './system-settings-snmp-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for SNMP Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-snmp-config',
    templateUrl: './system-settings-snmp-config.component.html',
})
export class SystemSettingsSnmpConfigComponent {
    @Input()
    public snmpConfig: ISnmpConfiguration;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Enum type for template usage.
     */
    public readonly enumType = 'SNMP_VER';

    /**
     * Object type for template usage.
     */
    public readonly objectTypes = {
        SnmpConfiguration,
        SnmpV3Configuration,
        SnmpV3UserParams,
    };

    /**
     * Boolean flags to check Snmp version.
     */
    public readonly snmpVersion = {
        isNone: false,
        isV2: false,
        isV3: false,
    };

    /**
     * SNMP version types as values, used in template.
     */
    public readonly snmpVersionEnumValues = {
        [SNMP_VER.SNMP_VER2]: SNMP_VER.SNMP_VER2,
        [SNMP_VER.SNMP_VER3]: SNMP_VER.SNMP_VER3,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { version } = this.snmpConfig;

        this.setSnmpVersionType(version);
    }

    /**
     * Handler on Snmp version switch.
     */
    public onSnmpVersionTypeChange(snmpVersion: SNMP_VER): void {
        this.setSnmpVersionType(snmpVersion);
        this.setDefaultSnmpConfig();
    }

    /**
     * Boolean flag properties used in template.
     */
    private setSnmpVersionType(snmpVersion: SNMP_VER): void {
        this.snmpVersion.isNone = !snmpVersion;
        this.snmpVersion.isV2 = snmpVersion === SNMP_VER.SNMP_VER2;
        this.snmpVersion.isV3 = snmpVersion === SNMP_VER.SNMP_VER3;
    }

    /**
     * Sets default SNMP config on switch between version types.
     */
    private setDefaultSnmpConfig(): void {
        Object.keys(this.snmpConfig).forEach(prop => delete this.snmpConfig[prop]);

        if (this.snmpVersion.isV3) {
            const defaultSnmpV3Config: ISnmpConfiguration = {
                snmp_v3_config: {
                    user: {},
                },
                version: SNMP_VER.SNMP_VER3,
            };

            Object.assign(this.snmpConfig, defaultSnmpV3Config);
        }

        if (this.snmpVersion.isV2) {
            const defaultSnmpV2Config: ISnmpConfiguration = {
                version: SNMP_VER.SNMP_VER2,
            };

            Object.assign(this.snmpConfig, defaultSnmpV2Config);
        }
    }
}
