<div
    class="avi-custom-collection-dropdown clr-wrapper"
    provideParentForm
>
    <avi-radio-container [noMarginTop]="noMarginTop">
        <avi-radio
            name="{{ name + '_type_' + index }}"
            [value]="true"
            [(ngModel)]="showCollectionDropdown"
            (ngModelChange)="onTypeChange()"
            [disabled]="disabled"
        >
            {{ l10nKeys.selectFromAvailableLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            name="{{ name + '_type_' + index }}"
            [value]="false"
            [(ngModel)]="showCollectionDropdown"
            (ngModelChange)="onTypeChange()"
            [disabled]="disabled"
        >
            {{ l10nKeys.enterCustomValueLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>
    <div class="avi-collection-dropdown-custom__value-container">
        <avi-collection-dropdown
            *ngIf="showCollectionDropdown"
            name="{{ name + '_collection-ref_' + index }}"
            [(ngModel)]="collectionRef"
            [collection]="collection"
            [placeholder]="dropdownPlaceholder"
            (ngModelChange)="onCollectionRefChange()"
            [required]="required"
            [disableCreate]="disableCreate"
            [disableEdit]="disableEdit"
            [disabled]="disabled"
            [prepend]="prepend"
            [createParams]="createParams"
        ></avi-collection-dropdown>

        <ng-container *ngIf="!showCollectionDropdown">
            <avi-input-container
                [helperText]="inputHelperText"
                noMarginTop
                *ngIf="!allowMultipleCustomValues; else repeatedStringRef"
            >
                <input
                    clrInput
                    name="{{ name + '_custom-value_' + index }}"
                    [(ngModel)]="customValues[0]"
                    [placeholder]="inputPlaceholder"
                    [required]="required"
                    [disabled]="disabled"
                    regexPattern="{{ regex }}"
                    (ngModelChange)="onCustomValueChange()"
                />
            </avi-input-container>

            <ng-template #repeatedStringRef>
                <avi-repeated-strings
                    [(ngModel)]="customValues"
                    (ngModelChange)="onCustomValueChange()"
                    name="{{ name + '_custom-value_' + index }}"
                    [placeholder]="inputPlaceholder"
                    [regex]="regex"
                ></avi-repeated-strings>
                <div class="clr-subtext" *ngIf="inputHelperText">
                    {{ inputHelperText }}
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
