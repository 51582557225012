/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';

/**
 * @ngdoc directive
 * @name parseIpRange
 * @restrict A
 * @description
 *      Parses IpAddr or IpAddrRange or IpAddrPrefix proto message to string and vice versa.
 */
angular.module('aviApp').directive('parseAnyIp', [
'RangeParser',
function(RangeParser) {
    /**
     * NgModelCtrl parser: viewValue to modelValue.
     * @param {string} str
     * @returns {IpAddr|IpAddrRange|IpAddrPrefix|null}
     * @private
     */
    function parseAnyIPParser(str) {
        if (!str) {
            return str;
        }

        return RangeParser.ipRange2Json(str);
    }

    /**
     * NgModelCtrl formatter: modelValue to the viewValue.
     * @param {IpAddr|IpAddrRange|IpAddrPrefix} obj
     * @returns {string}
     * @private
     */
    function parseAnyIPFormatter(obj) {
        if (angular.isObject(obj)) {
            if ('begin' in obj && 'end' in obj) {
                return `${obj.begin.addr}-${obj.end.addr}`;
            } else if ('mask' in obj) {
                return getSubnetString(obj);
            } else if ('addr' in obj) {
                return obj.addr;
            }
        }

        return '';
    }

    //need preLink to work correctly with directives having priority set
    //so that they have a chance to register formatters/parsers before these
    function parseAnyIpPreLink(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseAnyIPParser);
        ngModel.$formatters.push(parseAnyIPFormatter);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        compile: () => ({
            pre: parseAnyIpPreLink,
        }),
    };
}]);
