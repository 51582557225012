/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Observable, Subject } from 'rxjs';
import { IUploadParams } from 'generated-types';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { AviBackendErrorMsgPipe } from 'nsx-alb-ui-components';

import {
    FullModalService,
    IFullModalLayout,
} from 'full-modal-service';

import { PkiCrlServerUrlModalComponent } from '../pki-crl-server-url-modal';

import * as l10n from '../pki-crl-server-url-modal/pki-crl-server-url-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * API URl to add the file by server url.
 */
const ADD_BY_SERVER_URL = '/api/fileobject/crlupload';

/**
 * @description Service to open/close Add CRL by Server URL method.
 * Also used for saving the details entered to the server.
 *
 * @author Sarthak Kapoor
 */
@Injectable()
export class PkiCrlServerByUrlService {
    /**
     * Observable to display the CRL name in the description of the modal breadcrumb.
     */
    public description$: Subject<string>;

    /**
     * Observable to display the success message in the parent modal
     * on successful save of CRL.
     */
    public addFileByUrlSuccessful$: Subject<void>;

    private readonly l10nKeys = l10nKeys;

    constructor(
        private readonly httpClient: AlbHttpClient,
        private readonly fullModalService: FullModalService,
        private readonly l10nService: L10nService,
        private readonly aviBackendErrorMsgPipe: AviBackendErrorMsgPipe,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Open the Add By Server URL modal.
     */
    public openModal(isPkiProfileFederated = false): void {
        this.description$ = new Subject<string>();
        this.addFileByUrlSuccessful$ = new Subject<void>();

        this.fullModalService.addModal(this.getModalProps(isPkiProfileFederated));
    }

    /**
     * Close the Add By Server URL modal.
     */
    public closeModal(): void {
        this.description$.complete();
        this.addFileByUrlSuccessful$.complete();

        this.fullModalService.removeModalByComponent(
            PkiCrlServerUrlModalComponent as Type<Component>,
        );
    }

    /**
     * Return the props required to load the Add By Server URL child modal.
     */
    private getModalProps(isPkiProfileFederated: boolean): IFullModalLayout {
        const {
            l10nKeys,
            l10nService,
        } = this;
        let description: string;

        const descriptionSubscription = this.description$.subscribe(
            name => description = name,
        );

        const config = {
            name: '',
            description: '',
            is_federated: isPkiProfileFederated,
            url: '',
            update_interval: undefined as unknown as number,
        };

        return {
            component: PkiCrlServerUrlModalComponent as Type<Component>,
            componentProps: {
                description$: this.description$,
                config,
                isPkiProfileFederated,
                submit$: new Observable<void>(
                    subscriber => {
                        this.saveServerUrl(config).subscribe(
                            () => {
                                this.addFileByUrlSuccessful$.next();

                                subscriber.next();
                                subscriber.complete();
                            },
                            error => subscriber.error(
                                this.aviBackendErrorMsgPipe.transform(error),
                            ),
                        );
                    },
                ),
                onClose: () => {
                    this.closeModal();
                    descriptionSubscription.unsubscribe();
                },
            },
            getName: () => l10nService.getMessage(l10nKeys.serverUrlLabel),
            getDescription: () => description,
        };
    }

    /**
     * Make API call to save the server url.
     */
    private saveServerUrl(dataToSave: IUploadParams): Observable<IUploadParams> {
        return this.httpClient.post(ADD_BY_SERVER_URL, dataToSave);
    }
}
