/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').filter('stateContext', function() {
    return function(state) {
    // ignore leaf states (summary, config, performance)
    // and get to abstract state (application, vs, pool)
        const parts = state.name.split('.');

        return parts[parts.length - 2];
    };
})

    .filter('currentStateIncludes', ['$state', function($state) {
        return function(string) {
            return _.contains($state.current.name.split('.'), string);
        };
    }]);
