/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-placement-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleFilterName = `${prefix}.columnTitleFilterName`;
export const columnTitleUserAgent = `${prefix}.columnTitleUserAgent`;
export const columnTitleCompression = `${prefix}.columnTitleCompression`;
export const compressionFiltersHeader = `${prefix}.compressionFiltersHeader`;
export const columnValueSuffix = `${prefix}.columnValueSuffix`;
export const gslbSitesGridHeader = `${prefix}.gslbSitesGridHeader`;
export const dnsVsLabel = `${prefix}.dnsVsLabel`;
export const noGslbSiteFoundLabel = `${prefix}.noGslbSiteFoundLabel`;

export const ENGLISH = {
    [columnTitleFilterName]: 'Filter Name',
    [columnTitleUserAgent]: 'User Agent',
    [columnTitleCompression]: 'Compression',
    [compressionFiltersHeader]: 'Compression Filters ({0})',
    [columnValueSuffix]: '(+{0} More)',
    [gslbSitesGridHeader]: 'GSLB Sites ({0})',
    [dnsVsLabel]: 'DNS Virtual Service(s)',
    [noGslbSiteFoundLabel]: 'No GSLB Sites have been added.',
};
