/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

//TODO remove @am
angular.module('aviApp').directive('doesItFit', ['$timeout', function($timeout) {
    return {
        scope: {},
        restrict: 'A',
        link(scope, elm) {
            const tries = elm.find('> [fit-try]');

            const findBestFit = function() {
                const width = elm.width();
                let found;

                tries.each(function() {
                    const $tryElm = $(this);

                    if (found) {
                        if ($tryElm.is(':visible')) {
                            $tryElm.hide();
                        }
                    } else {
                        $tryElm.css({ display: 'inline-block' });

                        if ($tryElm.width() <= width) {
                            found = true;
                        } else {
                            $tryElm.hide();
                        }
                    }
                });
            };

            /* unacceptable performance @am
            scope.$watch(function(){
                    return elm.width();
                }, findBestFit);*/

            // Needs to happen after elm has width
            $timeout(findBestFit);

            scope.$on('repaint', findBestFit);
        },
    };
}]);
