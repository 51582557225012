/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-client-management-access-config';
const prefix = `${moduleName}.${componentName}`;

export const allowedSshClientsLabel = `${prefix}.allowedSshClientsLabel`;
export const stringIpGroupLabel = `${prefix}.stringIpGroupLabel`;
export const stringOrIpAddressLabel = `${prefix}.stringOrIpAddressLabel`;
export const selectFromAvailableLabel = `${prefix}.selectFromAvailableLabel`;
export const selectIpAddrGroupPlaceholder = `${prefix}.selectIpAddrGroupPlaceholder`;
export const enterCustomValueLabel = `${prefix}.enterCustomValueLabel`;
export const enterCustomValuePlaceholder = `${prefix}.enterCustomValuePlaceholder`;
export const addItemButtonLabel = `${prefix}.addItemButtonLabel`;

export const ENGLISH = {
    [allowedSshClientsLabel]: 'Allowed SSH Clients',
    [stringIpGroupLabel]: 'String/IP Group',
    [stringOrIpAddressLabel]: 'String or IP Address',
    [selectFromAvailableLabel]: 'Select From Available',
    [selectIpAddrGroupPlaceholder]: 'Select String or IP Group',
    [enterCustomValueLabel]: 'Enter Custom Value',
    [enterCustomValuePlaceholder]: 'Enter IP Address',
    [addItemButtonLabel]: 'Add Item',
};
