/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'cloud-token-dialog';
const prefix = `${moduleName}.${componentName}`;

export const tokenGeneratedHeader = `${prefix}.tokenGeneratedHeader`;
export const tokenGeneratedText = `${prefix}.tokenGeneratedText`;
export const aviCtrlAuthTokenText = `${prefix}.aviCtrlAuthTokenText`;
export const tenantDetailText = `${prefix}.tenantDetailText`;
export const inCloudText = `${prefix}.inCloudText`;
export const tokenExpiresText = `${prefix}.tokenExpiresText`;
export const clusterUuid = `${prefix}.clusterUuid`;

export const ENGLISH = {
    [tokenGeneratedHeader]: 'Unique Token Generated',
    [tokenGeneratedText]: 'This is a unique token generated for use with one Service Engine. ' +
        'Use this token in the ',
    [aviCtrlAuthTokenText]: 'AVICNTRL_AUTHTOKEN ',
    [tenantDetailText]: 'field of metadata when creating the Service Engine. ' +
        'The Service Engine created using this token will be a member of tenant ',
    [inCloudText]: 'in cloud ',
    [tokenExpiresText]: 'Token expires after an hour.',
    [clusterUuid]: 'Cluster UUID: {0}',
};
