<rbac-label-grid
    name="rbac-label-grid"
    [(ngModel)]="rbacLabels"
    (ngModelChange)="handleUpdate()"
    class="role-filter-match-label"
>
    <rbac-label-grid_header>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="{{ fieldName }}"
        >
            <h6 class="role-filter-match-label__heading">
                {{ l10nKeys.keyAndValuesHeader | vtranslate : markers.count }}
            </h6>
        </label>
    </rbac-label-grid_header>
</rbac-label-grid>
