<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    [item]="editable"
    [form]="form"
    class="role-modal sel-role_modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        class="sel-name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        [disabled]="editable.isProtected()"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleRoleAccess | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleRoleAccess | vtranslate }}</h3>
                <role-access-expander [privileges]="editable.config.transformedPrivileges"></role-access-expander>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleLabelFilters | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleLabelFilters | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="filters"
                        >
                            <avi-data-grid_header>
                                <h6>
                                    {{ l10nKeys.filtersLabel | vtranslate : editable.filters.count }}
                                </h6>
                            </avi-data-grid_header>
                        </label>
                        <role-filter-grid
                            [roleFilters]="editable.filters"
                            (onAddRoleFilter)="onAddRoleFilter()"
                            (onRemoveRoleFilter)="onRemoveRoleFilter($event)"
                            (onEditRoleFilter)="onEditRoleFilter($event)"
                            objectType="{{ objectType }}"
                            fieldName="filters"
                        ></role-filter-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
