/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    AlertRuleOperator,
    AlertSource,
    AviObjectType,
    AviPermissionResource,
    IAlertConfig,
    IAlertRule,
    IAlertRuleEvent,
    IAlertRuleMetric,
} from 'generated-types';

import { AlertConfig } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import * as l10n from 'ajs/modules/alert/alert.l10n';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAlertRulePartial = Omit<IAlertRule, 'metrics_rule' | 'sys_event_rule'>;
type TAlertConfigPartial = Omit<IAlertConfig, 'alert_rule'>;

export interface IAlertConfigConfig extends TAlertConfigPartial {
    alert_rule: MessageItem<IAlertRuleConfig>;
}

interface IAlertRuleConfig extends TAlertRulePartial {
    metrics_rule?: RepeatedMessageItem<MessageItem<IAlertRuleMetric>>;
    sys_event_rule?: RepeatedMessageItem<MessageItem<IAlertRuleEvent>>;
}

interface IAlertConfigData {
    config: IAlertConfigConfig,
}

/**
 * Local copy of AlertRuleOperator.
 */
enum AlertRuleNotOperator {
    OPERATOR_NOT = 'OPERATOR_NOT',
}

export enum AlertRuleOperatorEnum {
    OPERATOR_NOT = AlertRuleNotOperator.OPERATOR_NOT,
    OPERATOR_AND = AlertRuleOperator.OPERATOR_AND,
    OPERATOR_OR = AlertRuleOperator.OPERATOR_OR,
}

export const ValueNone = 'none';

/**
 * @description AlertConfig item.
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
export class AlertConfigItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    public data: IAlertConfigData;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'alertconfig',
            objectType: AlertConfig,
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }
    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertConfigModalBreadcrumbTitle);
    }

    /** @override */
    public dataToSave(): any {
        const config = super.dataToSave();

        if (config.source === AlertSource.EVENT_LOGS) {
            const { alert_rule: alertRule } = config;

            if (alertRule.operator === AlertRuleOperatorEnum.OPERATOR_NOT) {
                alertRule.operator = AlertRuleOperatorEnum.OPERATOR_AND;

                if (alertRule.sys_event_rule && alertRule.sys_event_rule.at(1)) {
                    alertRule.sys_event_rule.at(1).not_cond = true;
                }
            } else {
                alertRule.sys_event_rule?.forEach((rule: IAlertRuleEvent) => {
                    rule.not_cond = false;
                });
            }

            alertRule.sys_event_rule = alertRule.sys_event_rule?.filter((rule: IAlertRuleEvent) => {
                return Boolean(rule.event_id);
            });
        }

        return config;
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    /** @override */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AlertConfigModalComponent,
        } = await import(
            /* webpackChunkName: "alert-config-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/alert-config-modal/alert-config-modal.component'
        );

        return AlertConfigModalComponent as Type<Component>;
    }

    /**
     * Add row data to Metric collection.
     */
    public addMetricRow(): void {
        this.alertRuleConfig.metrics_rule.add();
    }

    /**
     * Add row data to Event collection.
     */
    public addEventRow(): void {
        this.alertRuleConfig.sys_event_rule.add();
    }

    /**
     * Delete row data from Metric collection.
     */
    public deleteMetricRow(metricRow: MessageItem): void {
        this.alertRuleConfig.metrics_rule.removeByMessageItem(metricRow);
    }

    /**
     * Delete row data from Event collection.
     */
    public deleteEventRow(sysRuleEvent: MessageItem): void {
        this.alertRuleConfig.sys_event_rule.removeByMessageItem(sysRuleEvent);
    }

    /**
     * Set object_type as radio is selected.
     * Set obj_ref to undefined.
     */
    public setObjectType(objectType: string): void {
        this.config.object_type = objectType;
        this.config.obj_ref = undefined;
    }

    /**
     * Empty rule events when Source changes.
     */
    public handleSourceChange(): void {
        if (this.config.source === AlertSource.METRICS) {
            this.config.object_type = AviObjectType.VIRTUALSERVICE;
            this.alertRuleConfig.sys_event_rule.removeAll();
        } else {
            this.alertRuleConfig.metrics_rule.removeAll();
        }
    }

    /**
     * Return rule for Alert Config.
     */
    public get alertRuleConfig(): IAlertRuleConfig {
        return this.data.config.alert_rule.config;
    }

    public get metricRules(): RepeatedMessageItem<MessageItem<IAlertRuleMetric>> {
        return this.alertRuleConfig.metrics_rule;
    }

    public get sysEventRules(): RepeatedMessageItem<MessageItem<IAlertRuleEvent>> {
        return this.alertRuleConfig.sys_event_rule;
    }
}
