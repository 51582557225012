/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { LicenseTierType } from 'generated-types';

/**
 * Types used in schema.service.
 */
export interface IEnumValue {
    description: string;
    event_internal?: boolean;
    label: string;
    ocsp_ui_status_options?: TSchemaEnumMessageTypeOptionValue;
    ssl_compatibility?: string;
    ssl_performance?: string;
    ssl_security_score?: number;
    ssl_security?: string;
    ui_fsm_state_options?: TSchemaEnumMessageTypeOptionValue;
    value: string;
}

export type TEnumValueHash = Record<TPbEnumName, IEnumValue>;
export type TEnumValueLabelsHash = Record<TPbEnumValueKey, string>;

export enum FieldPermission {
    ALLOW_ANY = 'ALLOW_ANY', // allow being populated with any value with the right type
    ALLOW_LIMITED = 'ALLOW_LIMITED', // only limited values are allowed
    ALLOW_DEFAULT = 'ALLOW_DEFAULT', // only the field default value is allowed
    DISALLOWED = 'DISALLOWED', // field can't be populated at all
}

/**
 * Schema.js types.
 */

export type TPbEnumName = string;
export type TPbEnumValueKey = string;
export type TPbMessageName = string;
export type TPbMessageFieldName = string;
export type TPbSimpleValue = string | number | boolean;
export type TPermissionName = string;

export interface ISchema {
    enums: TSchemaEnums;
    pb: TSchemaPb;
    permission: Record<string, TPermissionName>;
    version: string;
}

export interface ISchemaPbMessageFieldValue {
    type?: string;
    value?: any;
}

export type TFieldSpecialValues = Record<string, string>;

export interface IFieldLicenseObject {
    tier: LicenseTierType;
    allowed: FieldPermission;
    allowed_value?: string;
    default_value?: TPbSimpleValue;
}

export type TFieldFieldLicenses = IFieldLicenseObject[];

export interface ISchemaPbFieldObject {
    default?: TPbSimpleValue;
    description?: string;
    enumType?: TPbEnumName;
    max?: number;
    maxRepeatedLength?: number; // max allowed elements for repeated fields.
    messageType?: TPbMessageName;
    min?: number;
    minRepeatedLength?: number; // minimum allowed elements for repeated fields.
    repeated?: boolean;
    required?: boolean;
    specialValues?: TFieldSpecialValues;
    type?: string;
    fieldLicenses?: TFieldFieldLicenses;
    immutable?: boolean;
    maxStringLength?: number;
}

export type TSchemaPbFields = Record<TPbMessageFieldName, ISchemaPbFieldObject>;

export interface ISchemaPbMessage {
    fields: TSchemaPbFields;
    // Optional unique key for messageItems, useful when index is not present.
    // Applicable on repeatedMessages.
    repeatedKey?: string;
}

export type TSchemaPb = Record<TPbMessageName, ISchemaPbMessage>;

export type TSchemaEnums = Record<TPbEnumName, TSchemaEnumValueHash>;

export type TSchemaEnumValueHash = Record<TPbEnumValueKey, ISchemaEnumValueOptions>;

export interface ISchemaEnumValueOptions {
    cloud_type_options?: TSchemaEnumMessageTypeOptionValue;
    description?: string;
    event_internal?: boolean;
    ocsp_ui_status_options?: TSchemaEnumMessageTypeOptionValue;
    ssl_compatibility?: string;
    ssl_performance?: string;
    ssl_security_score?: number;
    ssl_security?: string;
    text?: string;
    ui_fsm_state_options?: TSchemaEnumMessageTypeOptionValue;
}

export type TSchemaEnumMessageTypeOptionValue = Record<string, string[]>;
