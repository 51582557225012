<div class="vs-logs-signpost-custom-e2e-timing">
    <ng-content></ng-content>

    <vs-logs-signpost-custom-e2e-timing-header
        (onSwitchView)="switchView($event)"
    ></vs-logs-signpost-custom-e2e-timing-header>
    <vs-logs-signpost-custom-e2e-timing-data-grid></vs-logs-signpost-custom-e2e-timing-data-grid>
</div>

<div
    *ngIf="(vsLogsSignpostStore.isLoading$ | async) && !(vsLogsSignpostStore.vsLogsEndToEndTimingSignpostData$ | async)"
    class="vs-logs-signpost-custom-e2e-timing__loader clr-wrapper"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>
