/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { UploadState } from 'ajs/modules/core/factories/upload/upload.factory';

const {
    UPLOAD_STATE_IDLE,
    UPLOAD_STATE_IN_PROGRESS,
    UPLOAD_STATE_PROCESSING,
    UPLOAD_STATE_COMPLETE,
    UPLOAD_STATE_ERROR,
    UPLOAD_STATE_ABORTED,
} = UploadState;

const REQUEST_NUM_LIMIT = 5;

const collateUploadFactory = (
    $q,
    Base,
    $interval,
    Auth,
) => {
    class CollateUpload {
        constructor(args = {}) {
            this.uploadStatus = {
                percent: 0,
                loaded: 0,
                total: 0,
            };

            this.error = '';
            this.state = UPLOAD_STATE_IDLE;

            this.base_ = new Base();

            /**
             * Where file should be sent. API path. Required to send a file.
             * @type {string}
             * @protected
             */
            this.defaultDestination_ = args.destination || '';

            /**
             * FormData property used by controller to decide where the file should be referenced.
             * @type {string}
             * @protected
             */
            this.defaultUri_ = args.uri || '';

            /**
             * Offset of file chunk index. 0 by default.
             * @type {number}
             * @protected
             */
            this.defaultChunkIndexStartsWith_ = args.chunkIndexStartsWith || 0;
        }

        /**
         * Returns a destination API string. Destination URL may already contain params, so we need
         * to figure out if we need to start with a '?' or '&' for the rest of the params.
         * @param {string} destination - Destination URL.
         * @param {string[]} params - Array of params.
         * @return {string}
         */
        static destinationBuilder = function(destination, params) {
            const paramsString = params.join('&');
            let sign = '&';

            if (destination.indexOf('?') < 0) {
                sign = '?';
            }

            return destination + sign + paramsString;
        };

        /**
         * Takes a file and splits it into chunks, returned as an array of chunks.
         * @param  {File} file - File object.
         * @return {File[]}      Array of file chunks.
         */
        static splitFileIntoChunks(file) {
            const chunks = [];

            const bytesPerChunk = 1024 * 1024 * 25;
            const { size } = file;

            let start = 0,
                end = bytesPerChunk;

            while (start < size) {
                chunks.push(file.slice(start, end));
                start = end;
                end = start + bytesPerChunk;
            }

            return chunks;
        }

        /**
         * Verifies that the file has been properly collated after upload by making POST request.
         * @param  {string} filename - Name of file.
         * @param  {string} destination - API for fileservice.
         * @param  {string} [uri=''] - Destination of file after upload.
         * @return {ng.$q.promise}
         * @protected
         */
        static verifyCollate_ = function(filename, destination, uri = '') {
            const params = ['collate=true'];
            const api = this.destinationBuilder(destination, params);
            const payload = {
                filename,
            };

            if (uri) {
                payload.uri = uri;
            }

            const base = new Base();

            return base.request('POST', api, payload, undefined, 'collate');
        };

        /**
         * Sends file with XMLHttpRequest and FormData.
         * @param {Object} file - File to be uploaded.
         * @param {string} filename - Name of file at destination.
         * @param {string=} destination - Where file should be sent.
         * @param  {string=} uri - FormData property used by controller to determine where the file
         *                         should be referenced.
         * @return {ng.$q.promise}
         */
        send(file, filename, destination, uri) {
            if (!file) {
                return;
            }

            const self = this;

            destination = destination || this.defaultDestination_;

            if (!destination) {
                throw new Error('Destination must be defined.');
            }

            uri = uri || this.defaultUri_;

            const { defaultChunkIndexStartsWith_: indexOffset } = this;

            this.uploadStatus.percent = 0;
            this.uploadStatus.loaded = 0;
            this.uploadStatus.total = file.size;

            const fileChunks = CollateUpload.splitFileIntoChunks(file);

            const requestSlots = this.uploadChunks_(
                fileChunks,
                filename,
                REQUEST_NUM_LIMIT,
                destination,
                self.uploadStatus,
                indexOffset,
                uri,
            );

            this.error = '';

            this.setState(UPLOAD_STATE_IN_PROGRESS);

            let resetSessionInterval;

            if (Auth.autoLogoutDelay_ > 0) {
                resetSessionInterval = $interval(() => Auth.emulateUserActivity(), 30000);
            }

            return $q.all(requestSlots)
                .then(() => {
                    this.setState(UPLOAD_STATE_PROCESSING);

                    return CollateUpload.verifyCollate_(filename, destination, uri);
                })
                .then(() => this.setState(UPLOAD_STATE_COMPLETE))
                .catch(rsp => {
                    if (!this.isState(UPLOAD_STATE_ABORTED)) {
                        this.setState(UPLOAD_STATE_ERROR);
                        this.error = rsp.data && rsp.data.error || 'Upload failed';
                        this.base_.cancelRequests('upload');
                    }

                    return $q.reject(this.error);
                })
                .finally(() => $interval.cancel(resetSessionInterval));
        }

        /**
         * Upload file chunks to the same destination with a max number of requests being sent
         * simultaneously.
         * Ex. for a file with 11 chunks and requestNumLimit = 5, the returned request list will
         * contain 5 slots of requests, where slot 0 will end up sending chunks 0, 5, and 10, slot 1
         * chunk 1 and 6, slot 2 chunk 2 and 7, etc.
         * @param {File} chunks - File chunks to be uploaded.
         * @param {string} filename - Name of the file.
         * @param {number} requestNumLimit - Number of requests can be simultaneously sent.
         * @param {string} destination  - API for fileservice.
         * @param {Object} uploadStatus - Object containing properties indicating upload progress.
         * @param {number} [partNumOffset=0] - Where the part number starts when uploading.
         * @param {string} [uri=''] - Destination of file after upload.
         * @return {ng.$q.promise[]}
         * @protected
         */
        uploadChunks_(
            chunks,
            fileName,
            requestNumLimit,
            destination,
            uploadStatus,
            partNumOffset = 0,
            uri = '',
        ) {
            const chunkCount = chunks.length;
            const requests = [];

            for (let i = 0; i < chunkCount && i < requestNumLimit; i++) {
                requests.push(this.uploadChunksByStep_(
                    chunks,
                    fileName,
                    i,
                    requestNumLimit,
                    destination,
                    uploadStatus,
                    partNumOffset,
                    uri,
                ));
            }

            return requests;
        }

        /**
         * Recursively upload file chunks with a particular set of index from a list.
         * @param {File} chunkList - List where the target file chunk locate.
         * @param {string} filename - Name of the file.
         * @param {number} currentIndex - Index of the chunk to be sent.
         * @param {number} step - The index interval to the chunk next to be sent.
         * @param {string} destination  - API for fileservice.
         * @param {Object} uploadStatus - Object containing properties indicating upload progress.
         * @param {number} partNumOffset - Where the part number starts when uploading.
         * @param {string} uri - Destination of file after upload.
         * @return {ng.$q.promise[]}
         * @protected
         */
        uploadChunksByStep_(
            chunkList,
            fileName,
            currentIndex,
            step,
            destination,
            uploadStatus,
            partNumOffset,
            uri,
        ) {
            const chunk = chunkList[currentIndex];
            const data = new FormData();

            data.append('file', chunk, fileName);

            if (uri) {
                data.append('uri', uri);
            }

            const params = [`part=${currentIndex + partNumOffset}`];

            const api = CollateUpload.destinationBuilder(destination, params);

            return this.base_.request(
                'POST', api, data, { 'Content-Type': undefined }, 'upload',
            )
                .then(() => {
                    uploadStatus.loaded += chunk.size;
                    uploadStatus.percent = Math.ceil(
                        uploadStatus.loaded / uploadStatus.total * 100,
                    );

                    const nextIndex = currentIndex + step;

                    if (nextIndex < chunkList.length) {
                        return this.uploadChunksByStep_(
                            chunkList,
                            fileName,
                            nextIndex,
                            step,
                            destination,
                            uploadStatus,
                            partNumOffset,
                            uri,
                        );
                    }
                });
        }

        /**
         * Returns the inProgress state of the upload.
         * @return {Boolean} True if upload is in progress, false otherwise.
         */
        isInProgress() {
            return this.isState(UPLOAD_STATE_IN_PROGRESS) || this.isState(UPLOAD_STATE_PROCESSING);
        }

        /**
         * Cancels send request.
         */
        cancelUpload() {
            this.base_.cancelRequests('upload');
            this.setState(UPLOAD_STATE_ABORTED);
        }

        /**
         * Decide current upload state.
         * @param {string} state
         * @return {boolean}
         */
        isState(state) {
            return this.state === state;
        }

        /**
         * Set current upload state.
         * @param {string} state
         */
        setState(state) {
            this.state = state;
        }
    }

    return CollateUpload;
};

collateUploadFactory.$inject = [
    '$q',
    'Base',
    '$interval',
    'Auth',
];

/**
 * @ngdoc factory
 * @name  CollateUpload
 * @memberOf module:avi/utils
 * @description
 *
 *     Service for chunkingly uploading files to the controller.
 *
 *     Legacy, old upload service which reserves the chunking functionality.
 *     Exists only for API reason since this service is being used in several places.
 *     New upload service please see below.
 *
 * @see {@link module:avi/utils.Upload}
 * @deprecated
 * @author alextsg, Zhiqian Liu
 */
angular.module('avi/utils').factory('CollateUpload', collateUploadFactory);
