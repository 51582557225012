/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './virtualservice-migrate.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceMigrate
 * @description
 *     Modal for migrating a VIP. Allows the user to select a VIP and Service Engines.
 * @param {Function} onCancel - Called when cancelling the migrate process.
 * @param {Function} onComplete - Called after the migrate process has been initiated.
 * @param {VirtualService} virtualservice
 */
class VirtualserviceMigrateController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.l10nService_ = l10nService;
    }

    $onInit() {
        this.fromServiceEngines = [];
        this.toServiceEngines = [];
        this.canSpawnNewSE = false;
        this.hosts = [];

        this.params = {
            from_se_ref: undefined,
            to_se_ref: undefined,
            to_new_se: false,
            to_host_ref: undefined,
            vip_id: undefined,
        };

        this.vips = this.virtualservice.getIPAddresses('hash');

        const [vip] = this.vips;

        if (vip) {
            this.params.vip_id = vip.vip_id;
        }

        this.handleVipChange();
    }

    /**
     * Sets the list of Service Engines for selection in the dropdown.
     */
    setFromServiceEngines() {
        const { params } = this;

        params.from_se_ref = undefined;

        if (angular.isUndefined(params.vip_id)) {
            this.fromServiceEngines = [];
        } else {
            const runtime = this.virtualservice.getRuntimeData();
            const vipSummary = _.findWhere(runtime.vip_summary, { vip_id: params.vip_id });

            this.fromServiceEngines = vipSummary.service_engine;
        }
    }

    /**
     * Sets the list of Service Engines and hosts for selection in the dropdown.
     */
    setToServiceEnginesAndHosts() {
        const { params } = this;

        params.to_se_ref = undefined;

        if (angular.isUndefined(params.vip_id)) {
            this.toServiceEngines = [];
            this.canSpawnNewSE = false;
            this.hosts = [];
        } else {
            this.busy = true;
            this.errors = null;

            this.virtualservice.getCandidateServiceEnginesAndHosts(params.vip_id)
                .then(({ se_refs: seRefs, can_spawn_new_se: canSpawnNewSE, host }) => {
                    this.toServiceEngines = seRefs || [];
                    this.canSpawnNewSE = canSpawnNewSE || false;
                    this.hosts = host || [];
                })
                .catch(({ data }) => this.errors = data)
                .finally(() => this.busy = false);
        }
    }

    handleVipChange() {
        this.setFromServiceEngines();
        this.setToServiceEnginesAndHosts();
    }

    /**
     * Handles when the checkbox for "Create Service Engine" is changed.
     */
    handleCreateServiceEngineChange() {
        if (this.params.to_new_se) {
            this.params.to_se_ref = undefined;
        }
    }

    /**
     * Returns true if the dropdown to select a Service Engine Host should be displayed. True if
     * the Virtual Service cloud type is 'CLOUD_VCENTER' and 'Create Service Engine' checkbox is
     * selected.
     * @return {boolean}
     */
    showSelectServiceEngineHostDropdown() {
        const cloudType = this.virtualservice.getCloudType();

        return cloudType === 'CLOUD_VCENTER' && this.params.to_new_se;
    }

    /**
     * Makes a request to migrate the selected VIP and SE.
     */
    submit() {
        this.busy = true;
        this.errors = null;

        this.virtualservice.migrate(this.params)
            .then(() => this.onComplete({ vipId: this.params.vip_id }))
            .catch(rsp => this.errors = rsp.data)
            .finally(() => this.busy = false);
    }
}

VirtualserviceMigrateController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('virtualserviceMigrate', {
    bindings: {
        onCancel: '&',
        onComplete: '&',
        virtualservice: '<',
    },
    controller: VirtualserviceMigrateController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-scale/' +
            'virtualservice-migrate/virtualservice-migrate.html',
});
