/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'remote-auth-configurations-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleAuthProfile = `${prefix}.columnTitleAuthProfile`;
export const columnTitleMappingProfile = `${prefix}.columnTitleMappingProfile`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const authProfileSelectPlaceholder = `${prefix}.authProfileSelectPlaceholder`;
export const authMappingProfileSelectPlaceholder = `${prefix}.authMappingProfileSelectPlaceholder`;

export const ENGLISH = {
    [columnTitleAuthProfile]: 'Auth Profile',
    [columnTitleMappingProfile]: 'Mapping Profile',
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [authProfileSelectPlaceholder]: 'Select Auth Profile',
    [authMappingProfileSelectPlaceholder]: 'Select Auth Mapping Profile',
};
