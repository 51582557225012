<match-display-wrapper
    class="host-hdr-match-readonly"
    matchLabel="{{ l10nKeys.hostHdrLabel | vtranslate }}"
    matchCriteria="{{ matchCriteria }}"
    [matchValuesExist]="!!values?.length"
>
    <ng-container *ngIf="values?.length">
        <div *ngFor="let val of values; trackBy: trackByIndex">
            {{ val }}
        </div>
    </ng-container>
</match-display-wrapper>
