<avi-data-grid
    [config]="geoDbMappingElementGridConfig"
    [rows]="geoDbMappingElements.config"
    class="geo-db-mapping-element-grid"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addGeoDbMappingElementList()"
        >
            {{ l10nKeys.geoDbMappingElementAddButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>
