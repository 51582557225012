/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { Title } from '@angular/platform-browser';
import { controllerSiteStateFeatureKey } from './controller-site.selectors';
import { controllerSiteStateReducer } from './controller-site.reducer';
import { ControllerSiteEffects } from './controller-site.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(controllerSiteStateFeatureKey, controllerSiteStateReducer),
        EffectsModule.forFeature([ControllerSiteEffects]),
    ],
    providers: [
        Title,
    ],
    declarations: [],
})
export class ControllerSiteStoreModule {}
