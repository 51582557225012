/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CinematicModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ICinematicViewTab } from '../cinematic.component';
import './cinematic-header-tabs.component.less';

/**
 * Constants used in the class.
 */
const TAB_BASE_CLASS_NAME = 'cinematic-header-tabs__tab';

/**
 * @description
 *     Component to display tabs in the cinematic view header. Adding tabs dynamically is not
 *     supported - tabs are added when the view is initialized.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'cinematic-header-tabs',
    templateUrl: './cinematic-header-tabs.component.html',
})
export class CinematicHeaderTabsComponent {
    /**
     * List of navigation tabs to render.
     */
    @Input()
    public tabs: ICinematicViewTab[] = [];

    /**
     * ID of the active tab.
     */
    @Input()
    public activeTabId: string;

    /**
     * Flag to deactivate tabs.
     */
    @Input()
    public deactivateTabs: boolean;

    /**
     * Emitted when the user clicks on a navigation tab.
     */
    @Output()
    public onSelect = new EventEmitter<ICinematicViewTab>();

    /**
     * Handler for clicking a navigation tab.
     */
    public handleSelect(tab: ICinematicViewTab): void {
        // Allow scrolling if tabs are not deactivated.
        if (!this.deactivateTabs) {
            this.onSelect.emit(tab);
        }
    }

    /**
     * Returns the tab class name based on input property.
     */
    public getTabClassName(tab: ICinematicViewTab): string | null {
        if (this.deactivateTabs) {
            return `${TAB_BASE_CLASS_NAME}--deactivated`;
        }

        return this.isActive(tab) ? `${TAB_BASE_CLASS_NAME}--active` : null;
    }

    /**
     * TrackBy function for the list of tabs.
     */
    public trackByTabId(index: number, tab: ICinematicViewTab): string {
        return tab.id;
    }

    /**
     * Returns true if the tab ID matches the active tab ID.
     */
    private isActive(tab: ICinematicViewTab): boolean {
        return tab.id === this.activeTabId;
    }
}
