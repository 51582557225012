<div provideParentForm>
    <avi-data-grid
        [config]="awsUsableNetworksGridConfig"
        [rows]="zones.config"
        gridTitle="{{ l10nKeys.usableNetworksLabel | vtranslate : zones.count }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addZone()"
                [disabled]="!connected"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #availabilityZoneTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ availabilityZoneRowPrefix + index }}"
                id="{{ availabilityZoneRowPrefix + index }}"
                [(ngModel)]="row.config.availability_zone"
                [options]="availabilityZoneDropdownOptions"
                placeholder="{{ l10nKeys.availabilityZoneSelectPlaceholder | vtranslate }}"
                required
            ></avi-dropdown>
        </ng-template>

        <ng-template
            #usableNetworkTemplateRef
            let-row
            let-index="index"
        >
            <ng-container *ngIf="usableNetworkDropdownOptionsHash[row.config.availability_zone]">
                <avi-dropdown
                    name="{{ usableNetworkRowPrefix + index }}"
                    id="{{ usableNetworkRowPrefix + index }}"
                    [(ngModel)]="row.config.usable_network_uuids"
                    [options]="usableNetworkDropdownOptionsHash[row.config.availability_zone]"
                    placeholder="{{ l10nKeys.usableNetworkSelectPlaceholder | vtranslate }}"
                    [multiple]="true"
                    required
                ></avi-dropdown>
            </ng-container>
        </ng-template>
    </avi-data-grid>
</div>
