/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import { Injectable } from '@angular/core';
import { AviModalService } from 'ajs/modules/core/services/avi-modal/avi-modal.service';
import { FullModalService } from './full-modal/full-modal.service';
import { DialogService } from './dialog.service';
import { NotificationService } from './notification.service';
import { AviAlertService } from './avi-alert.service';
import { AppLevelAlertsService } from './app-level-alerts.service';

// Token used when registering as a downgraded service in AJS.
export const OVERLAY_REMOVAL_SERVICE_TOKEN = 'overlayRemovalService';

/**
 * Service for removing all popups or overlays over the page, ex. full modals or dialogs.
 * @author alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class OverlayRemovalService {
    constructor(
        private fullModalService: FullModalService,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private aviAlertService: AviAlertService,
        private appLevelAlertsService: AppLevelAlertsService,
        private aviModalService: AviModalService,
    ) {}

    /**
     * Remove rendered overlays.
     */
    public removeAll(): void {
        this.fullModalService.removeAllModals();
        this.dialogService.removeAll();
        this.notificationService.removeAll();
        this.aviAlertService.removeAll();
        this.appLevelAlertsService.removeAll();
        this.aviModalService.destroyAll();
    }
}
