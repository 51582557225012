<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    modalSubTitle="{{ name }}"
    (onCancel)="dismissModal()"
    (onSubmit)="dismissModal()"
    [busy]="busy"
    [errors]="errors"
    submitButtonText="{{ l10nKeys.submitBtnLabel | vtranslate }}"
    class="ssl-certificate-export-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div
            class="full-modal-config__section"
            *ngIf="data"
        >
            <h3>{{ l10nKeys.configurationHeader | vtranslate }}</h3>
            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="SSLKeyAndCertificate"
                    fieldName="key"
                    for="key"
                >
                    {{ l10nKeys.sslKeyInputLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    [ngModel]="data.key"
                    name="key"
                    placeholder="-"
                    rows="7"
                    disabled
                ></textarea>
            </avi-textarea-container>
            <div class="ssl-certificate-export-modal__copy-button">
                <cds-button
                    size="sm"
                    [disabled]="!data.key"
                    (click)="copyToClipboard(data.key)"
                >
                    {{ globalL10nKeys.copyToClipboardLabel | vtranslate }}
                </cds-button>
            </div>
            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="SSLKeyAndCertificate"
                    fieldName="certificate"
                    for="certificate"
                >
                    {{ l10nKeys.sslCertificateInputLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    [ngModel]="data.certificate.certificate"
                    name="certificate"
                    placeholder="-"
                    rows="7"
                    disabled
                ></textarea>
            </avi-textarea-container>
            <div class="ssl-certificate-export-modal__copy-button">
                <cds-button
                    size="sm"
                    [disabled]="!data.certificate.certificate"
                    (click)="copyToClipboard(data.certificate.certificate)"
                >
                    {{ globalL10nKeys.copyToClipboardLabel | vtranslate }}
                </cds-button>
            </div>
        </div>
    </form>
</full-modal-config>
