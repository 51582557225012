/**
 * @module DnsModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    DnsInfoConfigItem,
    DNS_INFO_CONFIG_ITEM_TOKEN,
} from '.';

const dnsModule = angular.module('avi/dns');
const factories = [
    {
        dependency: DnsInfoConfigItem,
        name: DNS_INFO_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(dnsModule, 'factory', name, dependency);
});
