/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-dns-ntp';
const prefix = `${moduleName}.${componentName}`;

export const dnsNtpHeader = `${prefix}.dnsNtpHeader`;
export const dnsResolversLabel = `${prefix}.dnsResolversLabel`;
export const ntpAuthenticationKeysLabel = `${prefix}.ntpAuthenticationKeysLabel`;
export const ntpServersLabel = `${prefix}.ntpServersLabel`;

export const ENGLISH = {
    [dnsNtpHeader]: 'DNS/NTP',
    [dnsResolversLabel]: 'DNS Resolver(s)',
    [ntpAuthenticationKeysLabel]: 'NTP Authentication Keys',
    [ntpServersLabel]: 'NTP Servers',
};
