/**
 * @module BotModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import { L10nService } from '@vmw/ngx-vip';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { BotClassificationTypes } from 'generated-types';

import * as globalL10n from 'global-l10n';

import {
    IClassification,
    IMatch,
    IMatchRule,
} from './bot-detection-result-match.types';

import * as l10n from './bot-detection-result-match-grid.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *
 *   Bot Detection Result Match grid component.
 *
 * @author Sarthak Kapoor
 */

@Component({
    selector: 'bot-detection-result-match-grid',
    templateUrl: './bot-detection-result-match-grid.component.html',
})
export class BotDetectionResultMatchGridComponent implements AfterViewInit {
    /**
     * TemplateRef for classification dropdown field.
     */
    @ViewChild('classificationFieldTemplateRef')
    public classificationFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value field in case User Defined Bot is selected.
     */
    @ViewChild('classificationValueFieldTemplateRef')
    public classificationValueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Emits the event to parent controller to insert new classification.
     */
    @Output()
    private readonly addClassification = new EventEmitter<IClassification>();

    /**
     * Emits the event to parent controller to remove classification.
     */
    @Output()
    private readonly removeClassification = new EventEmitter<number>();
    /**
     * Property set from parent template.
     * Filters out botManagmentMatch from list of rules passed to it.
     */
    @Input()
    public set currentRule(rule: IMatchRule) {
        this.botManagementMatch = rule.matchEdit.find(
            (match: IMatch) => match.id === 'bot_detection_result',
        );
    }

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Classifications dropdown options.
     */
    public readonly botClassificationDropdownOptions: IAviDropdownOption[] = [];

    /**
     * DataGrid config for Bot Management Rule Match.
     */
    public botClassificationsGridConfig: IAviDataGridConfig;

    /**
     * Used for checking User Defined Bot classifications type in template.
     */
    public readonly userDefinedBot = BotClassificationTypes.USER_DEFINED_BOT;

    /**
     * Extracted field for Bot Detection Result from current rule.
     */
    private botManagementMatch: IMatch;

    constructor(
        private readonly l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        this.botClassificationDropdownOptions = createOptionsFromEnumProps(
            schemaService.getEnumValues('BotClassificationTypes'),
        );

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.botClassificationsGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.classificationLabel),
                    id: 'classification',
                    templateRef: this.classificationFieldTemplateRef,
                }, {
                    label: l10nService.getMessage(globalL10nKeys.valueLabel),
                    id: 'value',
                    templateRef: this.classificationValueFieldTemplateRef,
                },
            ],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (classification: IClassification) => {
                    this.removeClassificationRow(classification);
                },
            }],
        };
    }

    /**
     * Returns Classifications.
     */
    public get classificationRows(): IClassification[] {
        const { value } = this.botManagementMatch;

        return value.classifications;
    }

    /**
     * Adds new row to grid on click of Add Button.
     */
    public addClassificationRow(): void {
        const newClassification: IClassification = {
            type: '',
        };

        this.addClassification.emit(newClassification);
    }

    /**
     * Called whenever user changes type from the dropdown.
     * Removes user_defined_type property if user changes type from user defined to some other type.
     */
    public updateClassificationType(row: IClassification): void {
        if (row.type !== this.userDefinedBot && row.user_defined_type) {
            delete row.user_defined_type;
        }
    }

    /**
     * Will be called whenver user clicks on remove icon in grid row.
     */
    private removeClassificationRow(classification: IClassification): void {
        const index = this.classificationRows.indexOf(classification);

        this.removeClassification.emit(index);
    }

    /**
     * Returns the count of classifications.
     */
    public get classificationsCount(): number {
        return this.classificationRows.length;
    }
}
