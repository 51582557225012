/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import type {
    TPbEnumName,
    TPbEnumValueKey,
} from 'ajs/modules/core/services/schema-service/schema.types';

/**
 * @description Pipe to transform an enum value to its label.
 * @author alextsg
 */
@Pipe({
    name: 'enumValueLabel',
})
export class EnumValueLabelPipe implements PipeTransform {
    constructor(private readonly schemaService: SchemaService) {}

    /**
     * Transform the enum name and enum value to a label.
     */
    public transform(enumValue: TPbEnumValueKey, enumName: TPbEnumName): string {
        return enumValue ? this.schemaService.getEnumValueLabel(enumName, enumValue) : '';
    }
}
