/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    gslbReplicationStatusOptions,
    gslbReplicationStatusPopoverOptions,
} from './components';

import {
    FederationCheckpoint,
    FederationCheckpointCollection,
    GslbGeoDbProfileCollection,
    GslbGeoDbProfileItem,
    GSLBPoolMember,
    GSLBPoolMemberCollection,
    GSLBService,
    GSLBServiceCollection,
    GSLBServiceFQDN,
    GSLBServiceFQDNCollection,
    GSLBServiceFQDNListCollDataSource,
    GSLBServiceFQDNListDataTransformer,
    GSLBServicePoolMembersInventoryCollDataSource,
    GSLBServicePoolMembersInventoryDataTransformer,
    GSLBSiteCollection,
    GSLBSiteInventoryCollDataSource,
    GSLBSiteInventoryDataTransformer,
    GSLBVSCollection,
} from '.';

import {
    GSLB_GEODB_PROFILE_COLLECTION_TOKEN,
    GSLB_GEODB_PROFILE_ITEM_TOKEN,
    GSLB_SERVICE_ITEM_TOKEN,
} from './gslb.tokens';

const gslbModule = angular.module('avi/gslb');

const factories = [
    {
        factory: FederationCheckpoint,
        name: 'FederationCheckpoint',
    },
    {
        factory: FederationCheckpointCollection,
        name: 'FederationCheckpointCollection',
    },
    {
        factory: GSLBPoolMember,
        name: 'GSLBPoolMember',
    },
    {
        factory: GSLBPoolMemberCollection,
        name: 'GSLBPoolMemberCollection',
    },
    {
        factory: GSLBService,
        name: GSLB_SERVICE_ITEM_TOKEN,
    },
    {
        factory: GSLBServiceCollection,
        name: 'GSLBServiceCollection',
    },
    {
        factory: GSLBServiceFQDN,
        name: 'GSLBServiceFQDN',
    },
    {
        factory: GSLBServiceFQDNCollection,
        name: 'GSLBServiceFQDNCollection',
    },
    {
        factory: GSLBServiceFQDNListCollDataSource,
        name: 'GSLBServiceFQDNListCollDataSource',
    },
    {
        factory: GSLBServiceFQDNListDataTransformer,
        name: 'GSLBServiceFQDNListDataTransformer',
    },
    {
        factory: GSLBServicePoolMembersInventoryCollDataSource,
        name: 'GSLBServicePoolMembersInventoryCollDataSource',
    },
    {
        factory: GSLBServicePoolMembersInventoryDataTransformer,
        name: 'GSLBServicePoolMembersInventoryDataTransformer',
    },
    {
        factory: GSLBSiteCollection,
        name: 'GSLBSiteCollection',
    },
    {
        factory: GSLBSiteInventoryCollDataSource,
        name: 'GSLBSiteInventoryCollDataSource',
    },
    {
        factory: GSLBSiteInventoryDataTransformer,
        name: 'GSLBSiteInventoryDataTransformer',
    },
    {
        factory: GSLBVSCollection,
        name: 'GSLBVSCollection',
    },
    {
        factory: GslbGeoDbProfileItem,
        name: GSLB_GEODB_PROFILE_ITEM_TOKEN,
    },
    {
        factory: GslbGeoDbProfileCollection,
        name: GSLB_GEODB_PROFILE_COLLECTION_TOKEN,
    },
];

const components = [
    {
        name: 'gslbReplicationStatus',
        options: gslbReplicationStatusOptions,
    },
    {
        name: 'gslbReplicationStatusPopover',
        options: gslbReplicationStatusPopoverOptions,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        gslbModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => gslbModule.component(name, options));
