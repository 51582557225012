/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-score';
const componentName = 'pool-health-score-body';
const prefix = `${moduleName}.${componentName}`;

export const trendingScoreLabel = `${prefix}.trendingScoreLabel`;

export const ENGLISH = {
    [trendingScoreLabel]: 'Trending Score',
};
