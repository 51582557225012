<div class="avi-progress-bar">
    <div
        *ngIf="header"
        class="avi-progress-bar__header"
    >
        {{ header }}
    </div>
    <!-- 'progress-block' and 'progress-group' are classes defined by Clarity -->
    <div class="progress-block">
        <div
            class="progress-group"
            [class.avi-progress-bar__progress-group--small]="small"
        >
            <clr-progress-bar [clrValue]="percentage"></clr-progress-bar>
            <div class="avi-progress-bar__bottom-text">
                <span *ngIf="!hidePercentage">
                    {{ percentage }}%
                </span>
                <span *ngIf="message">
                    {{ message }}
                </span>
            </div>
        </div>
    </div>
</div>
