<match-wrapper
    label="{{ l10nKeys.userTenantLabel | vtranslate }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMapping()"
>
    <div
        provideParentForm
        class="user-tenant-mapping"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="assign_tenant"
            for="user-tenant-mapping-type"
            required
        >
            {{ globalL10nKeys.typeLabel | vtranslate }}
        </label>
        <avi-enum-dropdown
            name="user-tenant-mapping-type"
            enum="AuthRuleAssignment"
            [(ngModel)]="editable.config.assign_tenant"
            (ngModelChange)="onAssignmentTypeChange()"
            placeholder="{{ l10nKeys.typeDropdownPlaceholder | vtranslate }}"
            required
        ></avi-enum-dropdown>

        <div class="user-tenant-mapping__tenants">
            <ng-container *ngIf="showTenantList">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="tenant_uuids"
                            for="user-tenants"
                        >
                            {{ l10nKeys.tenantsLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="tenantCollection"
                            [(ngModel)]="editable.config.tenant_refs"
                            (ngModelChange)="onTenantSelectionChange()"
                            placeholder="{{ l10nKeys.tenantsPlaceholderLabel | vtranslate }}"
                            name="user-tenants"
                            [multiple]="true"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="default_tenant_uuid"
                            for="default-tenant-uuid"
                        >
                            {{ l10nKeys.defaultTenantLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            [options]="selectedTenants"
                            [(ngModel)]="editable.config.default_tenant_ref"
                            placeholder="{{ l10nKeys.defaultTenantPlaceholder | vtranslate }}"
                            name="default-tenant-uuid"
                        ></avi-dropdown>
                    </div>
                </div>
            </ng-container>

            <avi-input-container *ngIf="showTenantAttributeName">
                <label
                    aviLabelWithTooltip
                    fieldName="tenant_attribute_name"
                    for="tenant-attribute-name"
                    objectType="{{ objectType }}"
                    required
                >
                    {{ l10nKeys.tenantAttributeNameLabel | vtranslate }}
                </label>
                <input
                    name="tenant-attribute-name"
                    [(ngModel)]="editable.config.tenant_attribute_name"
                    clrInput
                    placeholder="{{ l10nKeys.tenantAttributeNamePlaceholderLabel | vtranslate }}"
                    required
                />
            </avi-input-container>

            <div
                *ngIf="!showTenantList"
                class="full-modal-config__form-control-row"
            >
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="default_tenant_uuid"
                        for="default-tenant-uuid-list"
                    >
                        {{ l10nKeys.defaultTenantLabel | vtranslate }}
                    </label>
                    <avi-collection-dropdown
                        [collection]="tenantCollection"
                        [(ngModel)]="editable.config.default_tenant_ref"
                        placeholder="{{ l10nKeys.defaultTenantPlaceholder | vtranslate }}"
                        name="default-tenant-uuid-list"
                    ></avi-collection-dropdown>
                </div>
            </div>
        </div>
    </div>
</match-wrapper>
