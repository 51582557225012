<avi-dashboard-widget
    class="node-vitals-widget"
    shape="certificate"
    [iconSize]="24"
    headerText="{{ l10nKeys.nodeVitalsHeader | vtranslate }}"
    [loading]="loading$ | async"
    [showLoadingMessage]="initialLoading"
    [loadingMessage]="moduleL10nKeys.widgetLoadingMessage | vtranslate"
>
    <div dashboardWidgetBody>
        <node-vitals-usage-info
            *ngFor="let nodeVitals of nodeVitalsList$ | async; let i = index; trackBy: trackByNodeName"
            [nodeVitals]="nodeVitals"
            class="node-vitals-widget__usage-info"
            [index]="i"
        ></node-vitals-usage-info>
    </div>
</avi-dashboard-widget>
