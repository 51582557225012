/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-geo-location-config';
const prefix = `${moduleName}.${componentName}`;

export const userConfiguredOptionLabel = `${prefix}.userConfiguredOptionLabel`;
export const tagLabel = `${prefix}.tagLabel`;
export const tagPlaceholder = `${prefix}.tagPlaceholder`;
export const latitudeLabel = `${prefix}.latitudeLabel`;
export const longitudeLabel = `${prefix}.longitudeLabel`;
export const latitudePlaceholder = `${prefix}.latitudePlaceholder`;
export const longitudePlaceholder = `${prefix}.longitudePlaceholder`;
export const degreesHelperText = `${prefix}.degreesHelperText`;
export const geoLocationSourceLabel = `${prefix}.geoLocationSourceLabel`;
export const atleastOneGeoLocationWarning = `${prefix}.atleastOneGeoLocationWarning`;

export const ENGLISH = {
    [userConfiguredOptionLabel]: 'User Configured',
    [tagLabel]: 'Tag',
    [tagPlaceholder]: 'Enter Tag',
    [latitudeLabel]: 'Latitude',
    [longitudeLabel]: 'Longitude',
    [latitudePlaceholder]: 'Enter Latitude',
    [longitudePlaceholder]: 'Enter Longitude',
    [degreesHelperText]: 'Degrees',
    [geoLocationSourceLabel]: 'Geo Location Source',
    [atleastOneGeoLocationWarning]: 'To continue with user configured Geo Location atleast one field needs to be configured.',
};
