/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { GslbPoolMember } from 'object-types';
import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { debounce } from 'underscore';
import './gslb-pool-member-domain-name.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './gslb-pool-member-domain-name.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      GSLB pool member domain name component. It is used for IP address configured pool members.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-pool-member-domain-name',
    templateUrl: './gslb-pool-member-domain-name.component.html',
})
export class GslbPoolMemberDomainNameComponent {
    @Input()
    public editable: GslbPoolMemberConfigItem;

    /**
     * Reference for FQDN or IP address entered.
     */
    @Input()
    public ipOrFqdnInputValue: string;

    @Input()
    public resolvedFqdnDropdownOptions: IAviDropdownOption[];

    /**
     * Emit on FQDN or IP address value change.
     */
    @Output()
    private onIpOrFqdnChange = new EventEmitter<string>();

    public readonly objectType = GslbPoolMember;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.handleIpOrFqdnChange = debounce(this.handleIpOrFqdnChange, 500);
    }

    /**
     * Handle FQDN or IP address change.
     */
    public handleIpOrFqdnChange(): void {
        this.onIpOrFqdnChange.emit(this.ipOrFqdnInputValue?.trim());
    }
}
