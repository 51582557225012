<div
    class="full-modal-config__section tcp-health-monitor-config"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleTcp | vtranslate }}</h3>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="isEditing"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-fieldset [hideContent]="tcpMonitor.config.tcp_half_open">
                <avi-fieldset_header>
                    <clr-checkbox-container class="tcp-health-monitor-config__no-margin-top">
                        <clr-checkbox-wrapper>
                            <input
                                clrCheckbox
                                type="checkbox"
                                name="tcp-half-open"
                                id="tcp-half-open"
                                [(ngModel)]="tcpMonitor.config.tcp_half_open"
                                (ngModelChange)="editable.onSelectTcpHalfOpen()"
                            />
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypes.HealthMonitorTcp }}"
                                for="tcp-half-open"
                                fieldName="tcp_half_open"
                            >
                                {{ l10nKeys.tcpHalfOpenInputLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </avi-fieldset_header>
                <avi-fieldset_content *ngIf="!tcpMonitor.config.tcp_half_open">
                    <h6>{{ l10nKeys.clientRequestHeaderInputLabel | vtranslate }}</h6>
                    <newline-converted-textarea
                        name="tcp-request"
                        objectType="{{ objectTypes.HealthMonitorTcp }}"
                        fieldName="tcp_request"
                        [(ngModel)]="tcpMonitor.config.tcp_request"
                        placeholder="{{ l10nKeys.clientRequestHeaderInputPlaceholder | vtranslate }}"
                    ></newline-converted-textarea>
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <h6>{{ l10nKeys.serverResponseDataInputLabel | vtranslate }}</h6>
                            <newline-converted-textarea
                                name="tcp-response"
                                objectType="{{ objectTypes.HealthMonitorTcp }}"
                                fieldName="tcp_response"
                                [(ngModel)]="tcpMonitor.config.tcp_response"
                                placeholder="{{ l10nKeys.serverResponseDataInputPlaceholder | vtranslate }}"
                            ></newline-converted-textarea>
                        </div>
                    </div>
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.HealthMonitorTcp }}"
                            fieldName="maintenance_response"
                            for="tcp-maintenance-response"
                        >
                            {{ l10nKeys.maintenanceResponseDataInputLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            type="text"
                            id="tcp-maintenance-response"
                            name="tcp-maintenance-response"
                            [(ngModel)]="tcpMonitor.config.maintenance_response"
                            placeholder="{{ l10nKeys.maintenanceResponseDataInputPlaceholder | vtranslate }}"
                        />
                    </avi-input-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
