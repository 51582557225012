/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Sarthak Kapoor
 */

/**
 * Dependency token for LinuxServerHost Message Item.
 */
export const LINUX_SERVER_HOST_CONFIG_ITEM_TOKEN = 'LinuxServerHostConfigItem';

/**
 * Dependency token for LinuxServerConfiguration Message Item.
 */
export const LINUX_SERVER_CONFIGURATION_CONFIG_ITEM_TOKEN = 'LinuxServerConfigurationConfigItem';

/**
 * Dependency token for AzureConfiguration Message Item.
 */
export const AZURE_CONFIGURATION_CONFIG_ITEM_TOKEN = 'AzureConfigurationConfigItem';

/**
 * Dependency token for AzureNetworkInfo Message Item.
 */
export const AZURE_NETWORK_INFO_CONFIG_ITEM_TOKEN = 'AzureNetworkInfoConfigItem';

/**
 * Dependency token for AwsConfiguration Message Item.
 */
export const AWS_CONFIGURATION_CONFIG_ITEM_TOKEN = 'AwsConfigurationConfigItem';

/**
 * Dependency token for VCenterConfiguration Message Item.
 */
export const VCENTER_CONFIGURATION_CONFIG_ITEM_TOKEN = 'vCenterConfigurationConfigItem';

/**
 * Dependency token for GcpConfiguration Message Item.
 */
export const GCP_CONFIGURATION_CONFIG_ITEM_TOKEN = 'GCPConfigurationConfigItem';

/**
 * Dependency token for GcpNetworkConfig Message Item.
 */
export const GCP_NETWORK_CONFIG_CONFIG_ITEM_TOKEN = 'GCPNetworkConfigConfigItem';

/**
 * Dependency token GcpVipAllocation Message Item.
 */
export const GCP_VIP_ALLOCATION_CONFIG_ITEM_TOKEN = 'GCPVIPAllocationConfigItem';

/**
 * Dependency token for OpenStackConfiguration Message Item.
 */
export const OPEN_STACK_CONFIGURATION_CONFIG_ITEM_TOKEN = 'OpenStackConfigurationConfigItem';

/**
 * Dependency token for OpenStackNetwork Item.
 */
export const OPEN_STACK_NETWORK_ITEM_TOKEN = 'OpenstackNetworkItem';

/**
 * Dependency token for OpenStackNetwork Collection.
 */
export const OPEN_STACK_NETWORK_COLLECTION_TOKEN = 'OpenstackNetworkCollection';

/**
 * Dependency token for OpenStackVIPNetwork Message Item.
 */
export const OPEN_STACK_VIP_NETWORK_CONFIG_ITEM_TOKEN = 'OpenStackVipNetworkConfigItem';

/**
 * Dependency token for Cloud Item.
 */
export const CLOUD_ITEM_TOKEN = 'Cloud';
