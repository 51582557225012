/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name parseIpList
 * @restrict A
 * @description Parses and formats comma-separated string of IP addresses or ranges.
 */
angular.module('aviApp').directive('parseIpList', ['RangeParser',
function(RangeParser) {
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parseIpAddrList);
        ngModel.$formatters.push(formatIpAddrList);
        setValidators(scope, elm, attr, ngModel);
    }

    /**
     * Parses string into an array of IP objects.
     * @param  {string} val - Comma-separated IP address string.
     * @return {Object[]} Array of IP objects or IP range objects.
     */
    function parseIpAddrList(val) {
        if (!val) {
            return '';
        }

        const
            ips = val.split(','),
            outputList = [];

        const check = _.any(ips, function(ip) {
            const value = RangeParser.ipRange2Json(ip);

            outputList.push(value);

            return value === null;
        });

        return check ? undefined : outputList;
    }

    /**
     * Formats IP objects into comma-separated IP address string.
     * @param  {Object[]} val - Array of IP objects or IP range objects.
     * @return {string} Comma-separated IP address string.
     */
    function formatIpAddrList(val) {
        if (!Array.isArray(val)) {
            return '';
        }

        return val.map(ip => {
            if (ip.addr) {
                return ip.addr;
            } else if (ip.begin && ip.end) {
                return `${ip.begin.addr}-${ip.end.addr}`;
            }

            return undefined;
        }).join(', ');
    }

    /**
     * Sets $validators for ngModel.
     */
    function setValidators(scope, elm, attr, ngModel) {
        const {
            maxPerType: max,
        } = attr;

        if (max) {
            ngModel.$validators.maxPerType = getMaxPerTypeValidator(attr);
        }
    }

    /**
     * Returns a validator function for maxPerType
     * @param {Object} attr
     * @returns {Function}
     */
    function getMaxPerTypeValidator(attr) {
        const {
            maxPerType: max,
        } = attr;

        /**
         * if maxPerType attr is set with a number, following validator
         * will invalidate the form if a type(Ip/Iprange) has more than the allowed count.
         * Eg. If maxPerType is 1, then ngModel can have one IP and/or one IP Range.
         */
        return function(values) {
            if (!Array.isArray(values) || !values.length) {
                return true;
            }

            const count = {
                ip: 0,
                ipRange: 0,
            };

            return _.every(values, function(val) {
                if (val.begin) {
                    count.ipRange++;
                } else {
                    count.ip++;
                }

                return count.ipRange <= max && count.ip <= max;
            });
        };
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
