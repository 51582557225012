/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-geo-location';
const prefix = `${moduleName}.${componentName}`;

export const gslbGeoLocationSourceDropdownLabel = `${prefix}.gslbGeoLocationSourceDropdownLabel`;
export const gslbGeoLocationSourceDropdownPlaceholder = `${prefix}.gslbGeoLocationSourceDropdownPlaceholder`;
export const locationTagInputLabel = `${prefix}.locationTagInputLabel`;
export const locationTagInputPlaceholder = `${prefix}.locationTagInputPlaceholder`;
export const locationLatitudeInputLabel = `${prefix}.locationLatitudeInputLabel`;
export const locationLongitudeInputLabel = `${prefix}.locationLongitudeInputLabel`;

export const ENGLISH = {
    [gslbGeoLocationSourceDropdownLabel]: 'Geo Location Source',
    [gslbGeoLocationSourceDropdownPlaceholder]: 'Select Geo Location Source',
    [locationTagInputLabel]: 'Tag',
    [locationTagInputPlaceholder]: 'Enter Tag',
    [locationLatitudeInputLabel]: 'Latitude',
    [locationLongitudeInputLabel]: 'Longitude',
};
