/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './avi-health-score-footer.l10n';
import './avi-health-score-footer.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to render the footer for health score.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'avi-health-score-footer',
    templateUrl: './avi-health-score-footer.component.html',
})
export class AviHealthScoreFooterComponent {
    /**
     * Fires on click of View Health button.
     */
    @Output()
    public onViewHealthClick = new EventEmitter<void>();

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Trigger retry click event.
     */
    public handleViewHealthClick(): void {
        this.onViewHealthClick.emit();
    }
}
