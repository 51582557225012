<div
    provideParentForm
    class="avi-repeated-key-values-grid"
>
    <div
        *ngIf="hasDuplicateError"
        class="avi-repeated-key-values-grid__error-msg"
    >
        <avi-alert-group alertType="danger">
            <span>
                {{ l10nKeys.keyDuplicationErrorMsg | vtranslate }} <br>
                {{ l10nKeys.duplicateValuesErrorMsg | vtranslate : duplicateValues.join(', ')}}
            </span>
        </avi-alert-group>
    </div>
    <avi-data-grid
        [config]="listDataGridConfig"
        [rows]="keyValueRepeatedMessageItem.config"
        [invalidateForm]="hasDuplicateError"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addEntry()"
                [disabled]="hasDuplicateError"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
    <ng-template
        #keyFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                id="{{ 'key-id-' + index }}"
                name="{{ 'key_' + index }}"
                placeholder="{{ keyFieldPlaceholder }}"
                [(ngModel)]="row.config.key"
                (ngModelChange)="findDuplicates()"
                required
            />
        </avi-input-container>
    </ng-template>
    <ng-template
        #valueFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                id="{{ 'value-id-' + index }}"
                name="{{ 'value_' + index }}"
                placeholder="{{ valueFieldPlaceholder }}"
                [(ngModel)]="row.config.value"
                required
            />
        </avi-input-container>
    </ng-template>
</div>
