/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { WafPolicyItem } from 'ajs/modules/waf/factories/waf-policy/waf-policy.item.factory';
import * as l10n from './waf-learning-data-dialog.l10n';
import './waf-learning-data-dialog.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the WAF Learning Data Dialog.
 * @author Vinay Jadhav
 */
@Component({
    selector: 'waf-learning-data-dialog',
    templateUrl: './waf-learning-data-dialog.component.html',
})
export class WafLearningDataDialogComponent implements OnInit {
    @Output()
    public onClose = new EventEmitter();

    /**
     * UUID of the VS referencing the WAFPolicy.
     */
    @Input()
    public vsId: string;

    @Input()
    public wafPolicy: WafPolicyItem;

    public wafPolicyName: string;

    /**
     * Learning data to display in the form of JSON data.
     */
    public learningData: Record<string, any>;

    /**
     * Errors from HTTP request.
     */
    public errors: string | null;

    /**
     * Set to true while loading the learning data.
     * Used to show the spinner while loading the learning data.
     */
    public isLearningDataLoading = false;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.wafPolicyName = this.wafPolicy.getName();
        this.fetchLearningData();
    }

    /**
     * Fetch learning data.
     */
    public async fetchLearningData(): Promise<void> {
        this.isLearningDataLoading = true;

        try {
            const response = await this.wafPolicy.fetchLearningData(this.vsId);

            this.learningData = response.data;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.isLearningDataLoading = false;
        }
    }

    /**
     * Handler for Close button click.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
