/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'user-modal-individual-tenant-setting-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderCreate = `${prefix}.modalHeaderCreate`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const tenantPlaceholder = `${prefix}.tenantPlaceholder`;
export const gridTitleRoles = `${prefix}.gridTitleRoles`;

export const ENGLISH = {
    [modalHeaderCreate]: 'Create Individual Tenant',
    [modalHeaderEdit]: 'Edit Individual Tenant',
    [tenantPlaceholder]: 'Select Tenant',
    [gridTitleRoles]: 'Roles ({0})',
};
