/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { msToStringChunks } from 'ng/shared/utils/ms-to-string-chunks.utils';

/**
 * @ngdoc component
 * @name aviValue
 * @author Alex Malitsky
 * @param {number} value
 * @param {string} type - duration/bytes/bits
 * @description
 *
 *     Consistent formatting for typical values like bytes and durations.
 *
 **/
let convert;

class AviValueController {
    constructor(
        _convert_,
    ) {
        convert = _convert_;
    }

    $onInit() {
        this.valClass = 'timeValue val';
        this.dimClass = 'timing-units';
    }

    $onChanges({ value: valueChange }) {
        const { currentValue: value } = valueChange;

        const values = [];

        let valueObj;

        switch (this.type) {
            case 'duration':
                if (value < 1) {
                    values.push({
                        value: '< 1',
                        dimension: 'ms',
                    });
                } else if (value || _.isNaN(+value)) {
                    values.push(...msToStringChunks(value, true));
                } else {
                    values.push({
                        value: '',
                        dimension: '',
                    });
                }

                break;

            case 'bytes':
            case 'bits':
                valueObj = convert.getVal(value, this.type);

                values.push({
                    value: +valueObj.value.toFixed(2),
                    dimension: valueObj.unit,
                });

                break;
        }

        this.displayValues = values;
    }
}

AviValueController.$inject = [
    'Convert',
];

angular.module('aviApp').component('aviValue', {
    controller: AviValueController,
    bindings: {
        value: '<',
        type: '@',
    },
    template:
        '<span ng-repeat="item in $ctrl.displayValues track by $index">' +
            '<span class="sel-value" ng-class="::$ctrl.valClass">{{item.value}}</span>' +
            '<span ng-class="::$ctrl.dimClass">{{item.dimension}}</span>' +
        '</span>',
});
