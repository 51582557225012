/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AuthAttributeMatchConfigItem,
    HostHdrMatchConfigItem,
    JwtMatchConfigItem,
    MethodMatchConfigItem,
    PathMatchConfigItem,
} from 'ajs/modules/match/factories';

import { AuthorizationMatch } from 'object-types';
import { IAuthorizationMatch } from 'generated-types';

import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { PolicyMatchConfigItem } from
    'ajs/modules/policies/factories/config-items/policy-match.config-item.factory';

type TAuthorizationMatchFields = 'access_token' | 'attr_matches' | 'host_hdr' | 'method' | 'path';
type TAuthorizationMatchPartial = Omit<IAuthorizationMatch, TAuthorizationMatchFields>;

interface IAuthorizationMatchConfig extends TAuthorizationMatchPartial {
    access_token?: JwtMatchConfigItem;
    attr_matches?: RepeatedMessageItem<AuthAttributeMatchConfigItem>
    host_hdr?: HostHdrMatchConfigItem;
    method: MethodMatchConfigItem,
    path?: PathMatchConfigItem;
}

/**
 * @description AuthorizationMatch Config item.
 *
 * @author Aravindh Nagarajan
 */
export class AuthorizationMatchConfigItem extends
    PolicyMatchConfigItem<IAuthorizationMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthorizationMatch,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for attr_matches.
     */
    public get attributeMatches(): RepeatedMessageItem<AuthAttributeMatchConfigItem> {
        return this.config.attr_matches;
    }

    /**
     * Returns whether attributeMatches are configured.
     */
    public get hasAttributeMatches(): boolean {
        return !this.attributeMatches.isEmpty();
    }
}
