/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const certificateManagementModalBreadcrumbTitle = `${prefix}.certificateManagementModalBreadcrumbTitle`;
export const customParamsModalBreadcrumbTitle = `${prefix}.customParamsModalBreadcrumbTitle`;

export const ENGLISH = {
    [certificateManagementModalBreadcrumbTitle]: 'Certificate Management',
    [customParamsModalBreadcrumbTitle]: 'Custom Param',
};
