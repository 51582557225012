/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'tenant-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const gridConfigNameFieldTitle = `${prefix}.gridConfigNameFieldTitle`;
export const gridConfigNumUsersFieldTitle = `${prefix}.gridConfigNumUsersFieldTitle`;
export const gridConfigAdminUsersFieldTitle = `${prefix}.gridConfigAdminUsersFieldTitle`;
export const gridConfigOtherUsersFieldTitle = `${prefix}.gridConfigOtherUsersFieldTitle`;

export const ENGLISH = {
    [gridConfigNameFieldTitle]: 'Name',
    [gridConfigNumUsersFieldTitle]: 'Num Users',
    [gridConfigAdminUsersFieldTitle]: 'Admin Users',
    [gridConfigOtherUsersFieldTitle]: 'Other Users',
};
