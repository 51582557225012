/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const componentName = 'c-grid-table-row-action-reordering';

const template =
        `<i
            class="icon-arrow-up ${componentName}__icon"
            ng-class="$ctrl.isFirst ? '${componentName}__icon--hidden' : ''"
            ng-click="$ctrl.moveRow('up')"></i>
        <i
            class="icon-arrow-down ${componentName}__icon"
            ng-class="$ctrl.isLast ? '${componentName}__icon--hidden' : ''"
            ng-click="$ctrl.moveRow('down')"></i>`;

class GridTableRowActionsReorderingController {
    constructor($elem) {
        this.$elem_ = $elem;
    }

    $onInit() {
        this.$elem_.addClass(componentName);
    }

    /**
     * Event handler for arrow click.
     * @param {string} direction - "up" or "down"
     */
    moveRow(direction) {
        this.onRowMove({
            rowId: this.rowId,
            direction,
        });
    }
}

GridTableRowActionsReorderingController.$inject = [
    '$element',
];

/**
 * @ngdoc component
 * @name gridTableRowActionReordering
 * @param {boolean} isFirst
 * @param {boolean} isLast
 * @param {string} rowId
 * @param {Function} onRowMove - To be called on action click with "rowId" and "direction"
 *     as params.
 * @desc
 *
 *     Table row action for moving row up and down.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridTableRowActionReordering', {
    bindings: {
        isFirst: '<',
        isLast: '<',
        rowId: '<',
        onRowMove: '&',
    },
    controller: GridTableRowActionsReorderingController,
    template,
});
