/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core';

/**
 * @description Directive used to bind to input events for editable filters.
 * @author Alex Klyuev
 */
@Directive({
    selector: '[vsLogsEditableFilterInputEvents]',
})
export class VsLogsEditableFilterInputEventsDirective {
    /**
     * Event emitters for input events.
     */
    @Output()
    public onFocus = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<void>();

    @Output()
    public onBackspace = new EventEmitter<void>();

    /**
     * Emit focus event upon element focus.
     */
    @HostListener('focusin')
    private handleFocusIn(): void {
        this.onFocus.emit();
    }

    /**
     * Submit upon 'Escape' key press.
     */
    @HostListener('keydown.escape')
    private handleEscape(): void {
        this.onSubmit.emit();
    }

    /**
     * Emit backspace event upon backspace press.
     */
    @HostListener('keydown.backspace')
    private handleBackspace(): void {
        this.onBackspace.emit();
    }

    /**
     * Ignore default behaviors for several key types.
     */
    @HostListener('document:keydown', ['$event'])
    // eslint-disable-next-line class-methods-use-this
    private ignoreKeyPresses(event: KeyboardEvent): void {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case 'Tab':
                event.preventDefault();
        }
    }
}
