/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { SystemConfig } from 'ajs/modules/system/factories/system-config.item.factory';

import {
    SystemSettingsModalComponent,
} from 'ng/modules/system/components/system-settings-modal/system-settings-modal.component';

import { SystemSettingsPageStore } from './system-settings-page.store';
import * as l10n from './system-settings-page.l10n';

import './system-settings-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-page',
    templateUrl: './system-settings-page.component.html',
})
export class SystemSettingsPageComponent {
    /**
     * Ngrx Component Selector for Dns Resolvers, used in template.
     */
    public readonly dnsResolversList$ = this.systemSettingsPageStore.dnsResolversList$;

    /**
     * Ngrx Component Selector for NtpAuthenticationKeysCount, used in template.
     */
    public readonly ntpAuthenticationKeysCount$
    = this.systemSettingsPageStore.ntpAuthenticationKeysCount$;

    /**
     * Ngrx Component Selector for NtpServersCount, used in template.
     */
    public readonly ntpServersList$ = this.systemSettingsPageStore.ntpServersList$;

    /**
     * Ngrx Component Selector for DNS Services, used in DNS Services Template.
     */
    public readonly dnsServicesList$ = this.systemSettingsPageStore.dnsServicesList$;

    /**
     * NgRx component selector for Global tenant config used in Tenancy Mode card.
     */
    public readonly globalTenantConfig$ = this.systemSettingsPageStore.globalTenantConfig$;

    /**
     * Email Configuration for Email/SMTP Source Card Template consumption.
     */
    public readonly emailConfiguration$ = this.systemSettingsPageStore.emailConfiguration$;

    /**
     * NgRx component selector for portal configuration for Access Card consumption.
     */
    public readonly portalConfiguration$ = this.systemSettingsPageStore.portalConfiguration$;

    /**
     * NgRx component selector for secure channel configuration for Access Card consumption.
     */
    public readonly secureChannelSslKeyCertificateRefs$
    = this.systemSettingsPageStore.secureChannelSslKeyCertificateRefs$;

    /**
     * NgRx component selector for allowed ciphers.
     */
    public readonly allowedCiphers$ = this.systemSettingsPageStore.allowedCiphers$;

    /**
     * NgRx component selector for allowed hmacs.
     */
    public readonly allowedHmacs$ = this.systemSettingsPageStore.allowedHmacs$;

    /**
     * NgRx component selector for SNMP version.
     */
    public readonly snmpVersion$ = this.systemSettingsPageStore.snmpVersion$;

    /**
     * NgRx component selector for linux configuration.
     */
    public readonly linuxConfiguration$ = this.systemSettingsPageStore.linuxConfiguration$;

    /**
     * NgRx component store selector for management access control.
     */
    public readonly managementAccessControl$
    = this.systemSettingsPageStore.managementAccessControl$;

    /**
     * Authprofiles and Authmappingprofiles used in authentication card.
     */
    public readonly remoteAuthConfiguration$
    = this.systemSettingsPageStore.remoteAuthConfiguration$;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly systemSettingsPageStore: SystemSettingsPageStore,
        @Inject('systemConfigService')
        private readonly systemConfigService: SystemConfig,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Used to open edit modal of system settings.
     */
    public openSystemSettingsModal(): void {
        this.systemConfigService.edit(SystemSettingsModalComponent as Type<Component>);
    }
}
