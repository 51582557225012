<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate) | vtranslate }}"
    modalSubTitle="{{ editable.config.name || '' }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
        [invalidateForm]="!editable.matchCount"
    >
        <!-- General -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </full-modal-tab-section>

        <!-- Match -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.matchSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.matchSectionTitle | vtranslate }}</h3>

            <match-adder
                [match]="editable.config.condition"
                [matchOptions]="matchOptions"
            ></match-adder>
        </full-modal-tab-section>

        <!--  Action  -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.actionLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.actionLabel | vtranslate }}</h3>

            <div>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="action"
                    for="bot-action"
                    required
                >
                    {{ l10nKeys.botActionLabel | vtranslate }}
                </label>
                <avi-dropdown
                    name="bot-action"
                    id="bot-action"
                    [options]="botActionOptions"
                    [(ngModel)]="editable.config.action"
                    placeholder="{{ l10nKeys.selectBotActionLabel | vtranslate }}"
                    required
                ></avi-dropdown>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
