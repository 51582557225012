<div class="ng-file-upload-textarea clr-wrapper">
    <file-upload
        [(ngModel)]="value"
        [(fileName)]="fileName"
        (fileNameChange)="handleFileNameChange()"
        name="value"
        [maxFileSize]="maxFileSize"
        [disabled]="disabled"
        [base64]="base64"
    ></file-upload>
    <div class="ng-file-upload-textarea__textarea-container">
        <textarea
            class="clr-textarea sel-enter-text ng-file-upload-textarea__textarea"
            [rows]="rows"
            [(ngModel)]="value"
            (ngModelChange)="handleTextareaChange()"
            name="value"
            placeholder="{{ placeholder }}"
            [disabled]="disabled"
        ></textarea>
    </div>
</div>
