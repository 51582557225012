<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div class="ip-addr-match">
        <enum-radio-buttons
            enum="MatchOperation"
            name="match-operation-{{ fieldName }}-{{ matchIndex }}"
            [(ngModel)]="editable.config.match_criteria"
            [noMarginTop]="true"
        ></enum-radio-buttons>
        <div>
            <div
                *ngFor="let address of addresses; index as i; trackBy: trackByIndex"
                class="ip-addr-match__address-container"
            >
                <div class="ip-addr-match__address-header">
                    <label
                        aviLabelWithTooltip
                        required
                    >
                        {{ l10nKeys.ipAddressLabel | vtranslate }}
                    </label>
                    <cds-icon
                        shape="trash"
                        size="18"
                        status="info"
                        class="ip-addr-match__remove-icon"
                        (click)="removeItem(i)"
                        *ngIf="addresses.length > 1"
                    ></cds-icon>
                </div>
                <ip-addr-match-entry
                    class="ip-addr-match__entry"
                    [(address)]="addresses[i]"
                    (addressChange)="handleAddressChange()"
                    [index]="i"
                    [fieldName]="fieldName"
                ></ip-addr-match-entry>
            </div>
        </div>
        <cds-button
            class="ip-addr-match__add-item-button"
            action="flat"
            size="sm"
            (click)="addItem()"
        >
            <cds-icon shape="plus-circle"></cds-icon>
            {{ l10nKeys.addItemButtonLabel | vtranslate }}
        </cds-button>
    </div>
</match-wrapper>
