/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'safenet-luna-server-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleHsmAddress = `${prefix}.columnTitleHsmAddress`;
export const columnTitleSerialNo = `${prefix}.columnTitleSerialNo`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleHsmAddress]: 'HSM Address',
    [columnTitleSerialNo]: 'Serial No.',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
};
