<div class="avi-auto-complete">
    <clr-datalist-container class="avi-auto-complete__list-container">
        <input
            class="avi-auto-complete__input"
            [type]="type"
            [name]="name"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            [required]="required"
            clrDatalistInput 
        />
        <datalist>
            <ng-container *ngFor="let option of options; trackBy: trackByValue">
                <option [value]="option">{{ option }}</option>
            </ng-container>
        </datalist>
        <div
            *ngIf="helperText"
            class="clr-subtext"
        >
            {{ helperText }}
        </div>
    </clr-datalist-container>
</div>
