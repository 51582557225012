/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IOperationalStatus } from 'generated-types';
import * as globalL10n from 'global-l10n';

import './gslb-subdomains-tree-node.component.less';
import * as l10n from './gslb-subdomains-tree-node.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const GSLB_SUBDOMAINS_TREE_NODE_SELECTOR = 'gslb-subdomains-tree-node';

/**
 * Possible types of nodes.
 */
export enum NodeType {
    ROOT_NODE = 'root-node',
    PARENT_NODE = 'parent-node',
    LEAF_NODE = 'leaf-node',
}

/**
 * Represent data shown in each tree node.
 */
export interface ITreeNodeData {
    name: string,
    hostAllSubdomains?: boolean,
    status?: IOperationalStatus,
}

/**
 * Possible focus Level of nodes.
 */
export enum FocusLevel {
    HIGH = 'high',
    NORMAL = 'normal',
    LOW = 'low',
}

/**
 * @description Individual node in GSLB tree. Can be leaf, parent, etc.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gslb-subdomains-tree-node',
    templateUrl: './gslb-subdomains-tree-node.component.html',
})
export class GslbSubdomainsTreeNodeComponent {
    /**
     * Data to show in tree node.
     */
    @Input()
    public data: ITreeNodeData;

    /**
     * Class to be added to the parent element.
     */
    @Input()
    public nodeType: NodeType;

    /**
     * Focus Level on hovering over a tree node.
     */
    @Input()
    public focusLevel: FocusLevel;

    /**
     * Show actions if true.
     */
    @Input()
    public isGslbEditable = false;

    /**
     * Fire on edit subdomain.
     */
    @Output()
    public onEditSubdomain = new EventEmitter<string>();

    /**
     * Fire on Remove subdomains.
     */
    @Output()
    public onRemoveSubdomain = new EventEmitter<string>();

    public nodeTypeEnum = NodeType;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Fire onEdit event.
     */
    public handleEdit(): void {
        this.onEditSubdomain.emit(this.data.name);
    }

    /**
     * Fire onDelete event.
     */
    public handleRemove(): void {
        this.onRemoveSubdomain.emit(this.data.name);
    }
}
