/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IControllerProperties } from 'generated-types';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';

const CONTROLLER_PROPERTIES_URL = '/api/controllerproperties';

@Injectable()
export class MyAccountModalService {
    constructor(
        private readonly http: AlbHttpClient,
        private readonly authService: Auth,
    ) {}

    /**
     * Load controller properties. If the user does not have permissions to
     * api/controllerproperties, then return no data.
     */
    public fetchControllerProperties(): Observable<IControllerProperties> {
        return this.authService.isAllowed('controllerproperties') ?
            this.http.get<IControllerProperties>(CONTROLLER_PROPERTIES_URL) :
            of({});
    }

    /**
     * Saves controller properties.
     */
    public saveControllerProperties(
        controllerProperties: IControllerProperties,
    ): Observable<IControllerProperties> {
        return this.authService.isAllowed('controllerproperties', 'w') ?
            this.http.put<IControllerProperties>(CONTROLLER_PROPERTIES_URL, controllerProperties) :
            of({});
    }
}
