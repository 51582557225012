<div
    class="avi-health-score-bar"
    style="--healthScore: {{healthScore}};
    --penaltyMargin: {{penaltyMargin}};"
>
    <label
        class="avi-health-score-bar__label"
        [ngClass]="{'avi-health-score-bar__label--penalty' : isPenalty, 'avi-health-score-bar__label--bold' : isLabelBold}"
    >
        {{ healthScoreLabel }}
    </label>
    <div class="avi-health-score-bar__value-container">
        <span class="avi-health-score-bar__value">
            {{ isPenalty ? healthScore * -1 : healthScore }}
        </span>
        <div class="avi-health-score-bar__bar-vessel">
            <div class="avi-health-score-bar__fill {{ isPenalty ? 'penalty' : healthScore | healthScoreClassName }}"></div>
        </div>
    </div>
</div>
