/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/**
 * @module VerticalNavModule
 */

import {
    Inject,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';
import {
    StateAccessMapService,
} from 'ajs/modules/core/services/state-access-map/state-access-map.service';

/**
 * @description Pipe to check if a navigation state should be hidden.
 * @author alextsg
 */
@Pipe({
    name: 'isHiddenState',
})
export class IsHiddenStatePipe implements PipeTransform {
    constructor(
        @Inject('systemInfoService')
        private readonly systemInfoService: any,
        private readonly stateAccessMapService: StateAccessMapService,
    ) {}

    /**
     * Returns true for states that should be hidden unless the controller has been configured
     * in a specific way. Ex. the GSLB tab should not be shown unless the user has GSLB configured.
     */
    public transform(state: IAppState): boolean {
        const { fullName: stateName } = state;

        switch (stateName) {
            // TODO: Remove when Administration Dashboard page is complete.
            case 'authenticated.administration.dashboard':
            case 'authenticated.infrastructure.infrastructure-detail.network-service-list':
                return true;

            case 'authenticated.profile.profiles.gslb-healthmonitor-list':
            case 'authenticated.application.gslbservice-list':
                return !this.systemInfoService.haveGSLBConfig();

            case 'authenticated.infrastructure.gslb.upload-geo-files':
            case 'authenticated.infrastructure.gslb.geo-profile':
            case 'authenticated.infrastructure.gslb.federation-checkpoints':
            case 'authenticated.infrastructure.gslb.subdomains':
                return !this.systemInfoService.localSiteIsGSLBLeader;

            case 'authenticated.administration.users':
                return !this.stateAccessMapService.getStateAccess(
                    'authenticated.administration.users',
                );

            case 'authenticated.administration.users.tenant-settings':
                return !this.stateAccessMapService.getStateAccess(
                    'authenticated.administration.users.tenant-settings',
                );

            default:
                return false;
        }
    }
}
