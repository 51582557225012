/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    AviPermissionResource,
    ITenantConfiguration,
} from 'generated-types';

import { copy } from 'angular';
import { Tenant } from 'object-types';
import { ObjectTypeItem } from 'object-type-item';
import { MessageItem } from 'message-item';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    TenantModalComponent,
} from 'ng/modules/accounts/components/tenant-modal/tenant-modal.component';

import {
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/openstack-keystone/openstack-keystone.service';

import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Tenant Item.
 * @author Suraj Kumar
 */
export class TenantItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        OPENSTACK_KEYSTONE_SERVICE_TOKEN,
        'l10nService',
    ];

    private readonly openstackKeystoneService: OpenstackKeystoneService;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'tenant',
            objectType: Tenant,
            windowElement: TenantModalComponent,
            permissionName: AviPermissionResource.PERMISSION_TENANT,
            ...args,
        };

        super(extendedArgs);

        this.openstackKeystoneService = this.getAjsDependency_(OPENSTACK_KEYSTONE_SERVICE_TOKEN);

        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        this.backup = copy(this.data.users);
    }

    /**
     * @override
     */
    public isEditable(): boolean {
        // Enforce to disable editing remote users if keystone auth is enabled.
        if (this.openstackKeystoneService.keystoneAuthEnabled) {
            return false;
        }

        return super.isEditable();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.tenantLabel);
    }

    /**
     * Getter for config_settings.
     */
    public get configSettings(): MessageItem<ITenantConfiguration> {
        return this.config.config_settings;
    }
}
