<div
    class="dns-resolver-nameservers-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="nameServerIpsGridConfig"
        [rows]="nameServerIps.config"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addNameServerIp()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #nameServerIpsTemplateRef
            let-row
            let-index="index"
        >
            <avi-input-container noMarginTop>
                <input
                    name="{{ nameServerIpsRowPrefix + index }}"
                    type="text"
                    regexPattern="anyIPHostname"
                    clrInput
                    placeholder="{{ l10nKeys.addressInputPlaceholder | vtranslate }}"
                    [(ngModel)]="row.address"
                    required
                />
            </avi-input-container>
        </ng-template>
    </avi-data-grid>
</div>
