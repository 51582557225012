/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { ITenantConfiguration } from 'generated-types';

import './welcome-tenant-settings.component.less';

import * as l10n from './welcome-tenant-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * Welcome modal -> Multi-Tenant settings panel component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-tenant-settings',
    templateUrl: './welcome-tenant-settings.component.html',
})
export class WelcomeTenantSettingsComponent {
    /**
     * tenantConfig object.
     */
    @Input()
    public tenantConfig: ITenantConfiguration;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
