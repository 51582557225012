/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-clr-file-upload-dialog';
const prefix = `${moduleName}.${componentName}`;

export const uploadCrlListLabel = `${prefix}.uploadCertificateRevocationLabel`;
export const uploadCrlListPlaceholder = `${prefix}.uploadCrlListPlaceholder`;
export const genericErrorMessage = `${prefix}.genericErrorMessage`;

export const ENGLISH = {
    [uploadCrlListLabel]: 'Upload Certificate Revocation List',
    [uploadCrlListPlaceholder]: 'Select a file',
    [genericErrorMessage]: 'Something went wrong.',
};
