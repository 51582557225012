/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './PoolAnalyticsController.l10n';

import '../../../less/pages/application/pool-analytics.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('PoolAnalyticsController', [
'$scope', '$stateParams', '$q', 'ChartConfig', 'myAccount', 'Cloud', 'VirtualServiceCollection',
'licenseBasedFeaturesService', 'l10nService',
function($scope, $stateParams, $q, ChartConfig, myAccount, Cloud, VirtualServiceCollection,
    licenseBasedFeaturesService, l10nService) {
    $scope.myAccount = myAccount;
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const extraPoolFields = [
        'config_events',
        'system_events',
        'alerts',
    ];

    let metrics = [
        {
            name: 'end_to_end',
        }, {
            name: 'l4_server.avg_bandwidth',
            series: 'l4_server.avg_bandwidth',
        }, {
            name: 'l4_server.max_open_conns',
            series: 'l4_server.max_open_conns',
        }, {
            name: 'l4_server.avg_est_capacity',
            series: 'l4_server.avg_est_capacity',
        }, {
            name: 'l4_server.avg_available_capacity',
            series: 'l4_server.avg_available_capacity',
        }, {
            name: 'l4_server.avg_complete_conns',
            series: 'l4_server.avg_complete_conns',
            errorsTotal: 'l4_server.avg_errored_connections',
            errorsSeries: [
                'l4_server.avg_lossy_connections',
            ],
        }, {
            name: 'l7_server.avg_complete_responses',
            series: 'l7_server.avg_complete_responses',
            errorsTotal: 'l7_server.pct_response_errors',
            errorsSeries: [
                'l7_server.avg_resp_4xx_errors',
                'l7_server.avg_resp_5xx_errors',
            ],
        }, {
            name: 'pools_servers_hs_scatter',
            type: 'scatter',
        }, {
            name: 'vm_stats.avg_cpu_usage',
            series: 'vm_stats.avg_cpu_usage',
        }, {
            name: 'vm_stats.avg_mem_usage',
            series: 'vm_stats.avg_mem_usage',
        },
    ];

    const
        layerDependantConfigs = [
            'end_to_end',
            'pools_servers_hs_scatter',
        ];

    metrics.forEach(metric => metric.subscriber = 'poolAnalyticsController');

    //need this ugly part to avoid exceptions on initial load TODO remove @am
    $scope.config = new ChartConfig([{
        id: '',
        series: [],
    }]);

    $scope.vsCollection = new VirtualServiceCollection({
        params: {
            refers_to: `pool:${$scope.pool.id}`,
        },
    });

    /**
     * Set to true if end_to_end metric is available.
     * @type {boolean}
     */
    $scope.e2eSeriesAvailable = licenseBasedFeaturesService.isMetricAvailable('end_to_end');

    const { vsCollection } = $scope;

    vsCollection.load();

    function loadData() {
        const
            { vs, pool } = $scope,
            mFilterList = [], //to filter out some metrics depending VS type and layer
            appType = vs.appType();

        // Removing endToEndTimingTotal when we can't get it
        if (!vs.hasTCPProxyNetProfile()) {
            mFilterList.push('end_to_end');
        }

        if (!vs.isHTTP()) {
            mFilterList.push('l7_server.avg_complete_responses');
        }

        const
            cloudConfig = vs.getConfig()['cloud_ref_data'],
            { vtype: cloudType } = cloudConfig;

        if (cloudType !== 'CLOUD_VCENTER' ||
            cloudConfig['vcenter_configuration']['privilege'] !== 'WRITE_ACCESS' ||
            !pool.hasServerWithVMId()) {
            mFilterList.push(
                'vm_stats.avg_cpu_usage',
                'vm_stats.avg_mem_usage',
            );
        }

        if (mFilterList.length) {
            metrics = _.reject(metrics, ({ name }) => _.contains(mFilterList, name));
        }

        //need to pass VS layer into end_to_end and scatter plot configs
        layerDependantConfigs.forEach(mName => {
            const metric = _.findWhere(metrics, { name: mName });

            if (metric) {
                metric.vsAppType = appType;
            }
        });

        //no need to reload Pool's config if it is already present
        const promise = $q.when(pool.getConfig() || pool.load(['config']));

        promise.then(() =>
            pool.collMetricsSubscribe(metrics)
                .then(() => {
                    const chartConfig = pool.createChartsConfig();

                    $scope.config = new ChartConfig(chartConfig);
                    $scope.config.setActiveCard($stateParams.metric);

                    pool.startCollMetricsAsync();
                    pool.addLoad(extraPoolFields.concat());
                }));
    }

    const { vs } = $scope;

    $q.when(vs.getConfig() || vs.load(['config']))
        .then(loadData);

    $scope.$on('$destroy', () => {
        const { pool, vs } = $scope;

        pool.async.stop(true);
        pool.removeLoad(extraPoolFields);

        vsCollection.destroy();
        vs.async.stop(true);
    });
}]);
