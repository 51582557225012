/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-dns-ntp-config';
const prefix = `${moduleName}.${componentName}`;

export const dnsResolversLabel = `${prefix}.dnsResolversLabel`;
export const dnsResolversPlaceholder = `${prefix}.dnsResolversPlaceholder`;
export const dnsResolversHelperText = `${prefix}.dnsResolversHelperText`;
export const searchDomainLabel = `${prefix}.searchDomainLabel`;
export const searchDomainPlaceholder = `${prefix}.searchDomainPlaceholder`;
export const ntpAuthenticationKeysTitle = `${prefix}.ntpAuthenticationKeysTitle`;
export const keyNumberPlaceholder = `${prefix}.keyNumberPlaceholder`;
export const ntpAuthalgoritmPlaceholder = `${prefix}.ntpAuthalgoritmPlaceholder`;
export const keyNumberLabel = `${prefix}.keyNumberLabel`;
export const algorithmLabel = `${prefix}.algorithmLabel`;
export const ntpServersTitle = `${prefix}.ntpServersTitle`;
export const ntpServersPlaceholder = `${prefix}.ntpServersPlaceholder`;

export const ENGLISH = {
    [dnsResolversLabel]: 'DNS Resolver(s)',
    [dnsResolversPlaceholder]: 'Enter DNS Resolvers',
    [dnsResolversHelperText]: 'Accepts IPv4 addresses, IPv6 addresses, and hostnames',
    [searchDomainLabel]: 'Search Domain',
    [searchDomainPlaceholder]: 'Enter Search Domain',
    [ntpAuthenticationKeysTitle]: 'NTP Authentication Keys ({0})',
    [keyNumberPlaceholder]: 'Enter Key Number',
    [ntpAuthalgoritmPlaceholder]: 'Select Algorithm',
    [keyNumberLabel]: 'Key Number',
    [algorithmLabel]: 'Algorithm',
    [ntpServersTitle]: 'NTP Servers ({0})',
    [ntpServersPlaceholder]: 'Enter Servers',
};
