/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'string-match-config';
const prefix = `${moduleName}.${componentName}`;

export const criteriaDropdownLabel = `${prefix}.criteriaDropdownLabel`;
export const stringLabel = `${prefix}.stringLabel`;
export const addItemButtonLabel = `${prefix}.addItemButtonLabel`;

export const ENGLISH = {
    [criteriaDropdownLabel]: 'Criteria',
    [stringLabel]: 'String',
    [addItemButtonLabel]: 'Add Item',
};
