<div
    provideParentForm
    class="virtual-host-grid"
>
    <label
        aviLabelWithTooltip
        required
    >
        <avi-data-grid_header>
            <h6>{{ l10nKeys.hostsLabel | vtranslate : vhMatches?.count }}</h6>
        </avi-data-grid_header>
    </label>

    <avi-data-grid
        [config]="hostsGridConfig"
        [rows]="vhMatches.config"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addVHMatch()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
</div>
