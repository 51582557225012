/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { VirtualServiceCollection } from 'ajs/modules/vs/factories/vs-collection.factory';
import { StringService } from 'string-service';
import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    IDnsVsRefObj,
    SystemConfig,
} from 'ajs/modules/system/factories/system-config.item.factory';

import * as l10n from './dns-services-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVirtualServiceCollection = typeof VirtualServiceCollection;

/**
 * Interface for vs ref object and its positional index in dns services.
 */
export interface IVsRefPosition {
    vsRef: string;
    index: number;
}

/**
 * @description Component for DNS Services grid in System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'dns-services-grid',
    templateUrl: './dns-services-grid.component.html',
})
export class DnsServiceGridComponent {
    /**
     * System Config Instance.
     */
    @Input()
    public editable: SystemConfig;

    /**
     * DNS vs list for data grid.
     */
    @Input()
    public dnsVirtualServiceRefs: IDnsVsRefObj[];

    /**
     * Event emitter fired on adding Dns Vs.
     */
    @Output()
    public onAddDnsVs = new EventEmitter<void>();

    /**
     * Event emitter fired on removing Dns Vs.
     */
    @Output()
    public onRemoveDnsVs = new EventEmitter<IDnsVsRefObj>();

    /**
     * Event emitter fired on updating Dns Vs in list.
     */
    @Output()
    public onUpdateDnsVs = new EventEmitter<IVsRefPosition>();

    /**
     * Template ref for DNS VS collection data grid.
     */
    @ViewChild('dnsVsTemplateRef')
    public readonly dnsVsTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Instance of VirtualServiceCollection.
     */
    public readonly virtualServiceCollection: VirtualServiceCollection;

    /**
     * Data grid config for DNS virtual services.
     */
    public dnsVsGridConfig: IAviDataGridConfig;

    /**
     * Boolean Flag for form validation.
     */
    public isFormValid: boolean;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(VirtualServiceCollection)
        VirtualServiceCollection: TVirtualServiceCollection,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.virtualServiceCollection = new VirtualServiceCollection({
            isStatic: true,
            params: {
                'application_profile_ref.type': 'APPLICATION_PROFILE_TYPE_DNS',
            },
        });
    }

    /**
     * @override
     * Setting up grid config.
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            l10nKeys,
            globalL10nKeys,
        } = this;

        this.dnsVsGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleVirtualService),
                id: 'dns-vs',
                templateRef: this.dnsVsTemplateRef,
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (dnsVs: IDnsVsRefObj) => {
                    this.removeDnsVs(dnsVs);
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (dnsVsList: IDnsVsRefObj[]) => {
                    dnsVsList.forEach((dnsVs: IDnsVsRefObj) => {
                        this.removeDnsVs(dnsVs);
                    });
                },
            }],
        };
    }

    /**
     * @override
     */
    public ngOnChanges(): void {
        this.onDnsVsRefChange();
    }

    /**
     * @override
     * Destroys vs collection.
     */
    public ngOnDestroy(): void {
        this.virtualServiceCollection.destroy();
    }

    /**
     * Extends the existing list of dns vs with empty configurable collection dropdown.
     */
    public addDnsVs(): void {
        this.onAddDnsVs.emit();
    }

    /**
     * Fired after changing one of the DNS Vs from the collection dropdown.
     * On dns vs ref being updated in dropdown, editable is updated.
     * Form and collection is updated, form is validated.
     */
    public onDnsVsRefDropdownChange(vsRef: string, index: number): void {
        this.onUpdateDnsVs.emit({
            vsRef,
            index,
        });
    }

    /**
     * Used to remove DNS vs from the list.
     */
    private removeDnsVs(dnsVs: IDnsVsRefObj): void {
        this.onRemoveDnsVs.emit(dnsVs);
    }

    /**
     * validates the form and applies filter to avoid duplication
     * of vs refs in dropdown options.
     */
    private onDnsVsRefChange(): void {
        this.validateForm();
        this.applyFilterOnVsCollection();
    }

    /**
     * Validates form.
     * Checks for any unselected dropdown.
     */
    private validateForm(): void {
        this.isFormValid = Boolean(
            this.dnsVirtualServiceRefs.every(ref => ref.vsRef),
        );
    }

    /**
     * Applies filter on Vs collection to exclude already selected Vs refs.
     */
    private applyFilterOnVsCollection(): void {
        this.virtualServiceCollection.setParams({
            'uuid.in': this.getAllSelectedDnsServicesUuids().join(),
            exclude: 'uuid.in',
        });
    }

    /**
     * private getter function for all dns service uuids.
     */
    private getAllSelectedDnsServicesUuids(): string[] {
        const {
            dns_virtualservice_refs: vsRefs = [],
        } = this.editable.getConfig();

        const dnsVsSlugs = vsRefs.reduce(
            (slugs, ref) => {
                if (ref) {
                    slugs.push(this.stringService.slug(ref));
                }

                return slugs;
            }, [],
        );

        return dnsVsSlugs;
    }
}
