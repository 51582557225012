/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'metric-value-selector';
const prefix = `${moduleName}.${componentName}`;

export const averageValuesOptionLabel = `${prefix}.averageValuesOptionLabel`;
export const currentValuesOptionLabel = `${prefix}.currentValuesOptionLabel`;
export const peakValuesOptionLabel = `${prefix}.peakValuesOptionLabel`;
export const totalValuesOptionLabel = `${prefix}.totalValuesOptionLabel`;

export const ENGLISH = {
    [averageValuesOptionLabel]: 'Average Values',
    [currentValuesOptionLabel]: 'Current Values',
    [peakValuesOptionLabel]: 'Peak Values',
    [totalValuesOptionLabel]: 'Total Values',
};
