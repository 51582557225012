/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const AVI_REPEATED_STRINGS_UNIQUENESS_VALIDATOR_KEY =
    'aviRepeatedStringsUniquenessValidation';

/**
 * Returns uniqueness validatorFunction for AviRepeatedStringsComponent.
 *
 * @author Aravindh Nagarajan
 */
export function aviRepeatedStringsUniquenessValidator(): ValidatorFn {
    /**
     * Returns ValidationErrors object if value list has duplicates.
     * otherwise null.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        if (!value) {
            return null;
        }

        const { length: actualCount } = value;
        const { size: uniqueCount } = new Set(value);

        if (actualCount !== uniqueCount) {
            return {
                [AVI_REPEATED_STRINGS_UNIQUENESS_VALIDATOR_KEY]: value,
            };
        } else {
            return null;
        }
    };
}
