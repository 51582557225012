/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoolGroupModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { HealthScoreModule } from 'ng/modules/health-score/health-score.module';

import {
    PoolGroupListPageComponent,
} from './components/pool-group-list-page/pool-group-list-page.component';

import {
    PoolHealthScoreComponent,
} from './components/pool-health-score/pool-health-score.component';

const poolComponents = [
    PoolGroupListPageComponent,
    PoolHealthScoreComponent,
];

/**
 * @description Module containing Pool Group components.
 * @author Nitesh Kesarkar
 */
@NgModule({
    declarations: [
        ...poolComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        DataGridModule,
        SharedModule,
        HealthScoreModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PoolGroupModule {}
