<div 
    class="gslb-placement-grid"
    provideParentForm
>
    <avi-data-grid
        [config]="gslbPlacementGridConfig"
        [rows]="gslbSites"
        [invalidateForm]="!isAllSubdomainsEnabled && gslbSites.length < 1"
        gridTitle="{{ l10nKeys.gslbSitesGridHeader | vtranslate : gslbSites.length }}"
    >
        <avi-data-grid_actions *ngIf="!isAllSubdomainsEnabled">
            <cds-button
                (click)="addGslbSite()"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
    <ng-template
        #dnsVsFieldTemplateRef
        let-row
    >
        <div *ngIf="row.dnsVsNames?.length">
            {{ row.dnsVsNames[0] }} 
            <!-- To show '+ more' message and tooltip for remaining values -->
            <span
                *ngIf="row.dnsVsNames.length > 1"
                aviTitle="{{ row.dnsVsNames.slice(1) }}"
            >
                {{ l10nKeys.columnValueSuffix | vtranslate : row.dnsVsNames.length - 1  }}
            </span>
        </div>
    </ng-template>
    <ng-template
        #siteNameTemplateRef
        let-row
    >
    <div class="gslb-placement-grid__grid-name-column">
        <avi-tooltip-icon-with-content-wrapper
            shape="circle"
            size="18"
            [status]="row.siteStatus.state === 'OPER_UP' ? 'success' : 'danger'"
            [solid]="true"
        >
            {{ row.siteStatus.reason.join() }}
        </avi-tooltip-icon-with-content-wrapper>
        <span>{{ row.siteName }}</span>
    </div>
    </ng-template>
</div>
