/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'prechecks-list-expander';
const prefix = `${moduleName}.${componentName}`;

export const errorLabel = `${prefix}.errorLabel`;
export const warningLabel = `${prefix}.warningLabel`;
export const successLabel = `${prefix}.successLabel`;

export const ENGLISH = {
    [errorLabel]: 'Errors ({0})',
    [warningLabel]: 'Warnings ({0})',
    [successLabel]: 'Successful ({0})',
};
