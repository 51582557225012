/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    Component,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import './avi-checkbox.component.less';

/**
 * Prefixes for automation testing selector classes.
 */
const CHECKBOX_SELECTOR_CLASS_NAME_PREFIX = 'sel-';
const LABEL_SELECTOR_CLASS_NAME_SELECTOR_PREFIX = 'sel-label-';

/**
 * @description Wrapper around the Clarity checkbox input element.
 * @author alextsg
 */
@Component({
    selector: 'avi-checkbox',
    templateUrl: './avi-checkbox.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviCheckboxComponent),
        },
    ],
})
export class AviCheckboxComponent implements ControlValueAccessor, OnInit {
    /**
     * Name bound to the ngModel.
     */
    @Input()
    public name: string;

    @Input()
    public objectType?: string;

    @Input()
    public fieldName?: string;

    /**
     * @param tooltipText - Custom text unrelated to Schema to be shown.
     */
    @Input()
    public tooltipText?: string;

    /**
     * Set the disabled state of the radio input element.
     */
    @Input()
    public disabled?: boolean;

    /**
     * By default there is a margin-top on this element, which is consistent with Clarity elements.
     * Set noMarginTop to true to remove the margin-top.
     */
    @Input()
    public noMarginTop?: boolean;

    /**
     * Model value passed to the checkbox element.
     */
    public value: boolean;

    /**
     * Class applied to the child input[type=checkbox] element.
     */
    public checkboxClassName: string;

    /**
     * Class applied to the child label element.
     */
    public labelClassName: string;

    /** @override */
    public ngOnInit(): void {
        this.checkboxClassName = `${CHECKBOX_SELECTOR_CLASS_NAME_PREFIX}${this.name}`;
        this.labelClassName = `${LABEL_SELECTOR_CLASS_NAME_SELECTOR_PREFIX}${this.name}`;
    }

    /**
     * Called when the value is changed by the checkbox element.
     */
    public handleModelChange(value: boolean): void {
        this.setValue(value);
    }

    /***********************************************************
     * Implementing ControlValueAccessor Interface
     */

    /**
     * Write the model value.
     */
    public writeValue(value: boolean): void {
        this.value = value;
    }

    /**
     * Set the onChange function.
     */
    public registerOnChange(fn: (value: boolean) => {}): void {
        this.onChange = fn;
    }

    /**
     * Set the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***********************************************************/

    /**
     * Set the model value and call onChange.
     */
    private setValue(value: boolean): void {
        this.value = value;

        this.onChange(this.value);
        this.onTouched();
    }

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onChange = (value: boolean): void => {};

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /***********************************************************/
}
