/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IWafAllowlistLog,
    IWafPSMLog,
    IWafRuleLog,
} from 'generated-types';

import {
    VsLogsWafExceptionDialogService,
} from 'ng/modules/logs/services/vs-logs-waf-exception-dialog.service';

import { L10nService } from '@vmw/ngx-vip';
import { take } from 'rxjs/operators';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import * as globalL10n from 'global-l10n';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { TEnumValueLabelsHash } from 'ajs/modules/core/services/schema-service/schema.types';

import * as l10n from './vs-log-cinematic-section-security-waf.l10n';
import './vs-log-cinematic-section-security-waf.component.less';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';
import { VsLogsStore } from '../../../../services';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Labels of WAF log properties.
 */
interface IWafLoglabel {
    hitLabel?: string,
    wafPsmGroupLabel?: string,
    wafPsmLocationLabel?: string,
    wafPsmRuleLabel?: string,
    wafAppGroupLabel?: string,
    wafAppRuleLabel?: string,
    wafSignatureGroupLabel?: string,
    wafSignatureRuleLabel?: string,
}

/**
 * @description
 *     Component for displaying Security's WAF sub-section in a VS log cinematic view.
 * @author Kondiparthi Shanmukha Sarath, Zhiqian Liu, alextsg
 */
@Component({
    selector: 'vs-log-cinematic-section-security-waf',
    templateUrl: './vs-log-cinematic-section-security-waf.component.html',
})
export class VsLogCinematicSectionSecurityWafComponent {
    /**
     * TemplateRef for Argument in match grid.
     */
    @ViewChild('wafRuleLogMatchArgumentFieldTemplateRef')
    public readonly wafRuleLogMatchArgumentFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for Argument in match grid.
     */
    @ViewChild('wafPsmLogMatchArgumentFieldTemplateRef')
    public readonly wafPsmLogMatchArgumentFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value in match grid.
     */
    @ViewChild('wafRuleLogMatchValueFieldTemplateRef')
    public readonly wafRuleLogMatchValueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for value in match grid.
     */
    @ViewChild('wafPsmLogMatchValueFieldTemplateRef')
    public readonly wafPsmLogMatchValueFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Labels of WAF log properties.
     */
    public labels: IWafLoglabel = {};

    public readonly wafAllowListLogs$ = this.vsLogCinematicStore.wafAllowListLogs$;

    public readonly wafPsmLogs$ = this.vsLogCinematicStore.wafPsmLogs$;

    public readonly wafLog$ = this.vsLogCinematicStore.wafLog$;

    public readonly wafApplicationRuleLogs$ = this.vsLogCinematicStore.wafApplicationRuleLogs$;

    public readonly wafRuleLogs$ = this.vsLogCinematicStore.wafRuleLogs$;

    public readonly wafRuleLogsByGroup$ = this.vsLogCinematicStore.wafRuleLogsByGroup$;

    public wafRuleLogMatchConfig: IAviDataGridConfig;

    public wafPsmLogMatchConfig: IAviDataGridConfig;

    /**
     * WAF Policy ref referenced by the VS config.
     */
    public wafPolicyRef: string;

    /**
     * Key-Value map for 'WafPolicyAllowlistAction' and 'WafAction' enum values and display values.
     */
    private readonly wafActionsHash: TEnumValueLabelsHash;

    /**
     * URI Path of the current log.
     */
    private uriPath: string;

    constructor(
        private readonly l10nService: L10nService,
        schemaService: SchemaService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly vsLogsWafExceptionDialogService: VsLogsWafExceptionDialogService,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.wafAllowListLogs$.pipe(take(1)).subscribe(logs => {
            this.labels.hitLabel = l10nService.getMessage(
                logs?.length === 1 ? this.l10nKeys.hitLabel : this.l10nKeys.hitsLabel,
            );
        });

        this.wafActionsHash = {
            ...schemaService.getEnumValueLabelsHash('WafPolicyAllowlistAction'),
            ...schemaService.getEnumValueLabelsHash('WafAction'),
        };

        this.wafPsmLogs$.pipe(take(1)).subscribe(logs => {
            const groupsCount = logs?.filter(log => log.group_name).length || 0;
            const locationCount = logs?.filter(log => log.location).length || 0;
            const rulesCount = logs?.filter(log => log.actions?.length).length || 0;

            this.labels.wafPsmGroupLabel = l10nService.getMessage(
                groupsCount === 1 ? this.l10nKeys.groupLabel : this.l10nKeys.groupsLabel,
                [groupsCount],
            );

            this.labels.wafPsmLocationLabel = l10nService.getMessage(
                locationCount === 1 ? this.l10nKeys.locationLabel : this.l10nKeys.locationsLabel,
                [locationCount],
            );

            this.labels.wafPsmRuleLabel = l10nService.getMessage(
                rulesCount === 1 ? this.l10nKeys.ruleLabel : this.l10nKeys.rulesLabel,
                [rulesCount],
            );
        });

        this.wafApplicationRuleLogs$.pipe(take(1)).subscribe(logs => {
            const groupsCount = logs?.filter(log => log.rule_group).length || 0;
            const rulesCount = logs?.filter(log => log.rule_name).length || 0;

            this.labels.wafAppGroupLabel = this.l10nService.getMessage(
                groupsCount === 1 ? this.l10nKeys.groupLabel : this.l10nKeys.groupsLabel,
                [groupsCount],
            );

            this.labels.wafAppRuleLabel = this.l10nService.getMessage(
                rulesCount === 1 ? this.l10nKeys.ruleLabel : this.l10nKeys.rulesLabel,
                [rulesCount],
            );
        });

        this.wafRuleLogs$.pipe(take(1)).subscribe(logs => {
            const groupsCount = logs?.filter(log => log.rule_group).length || 0;
            const rulesCount = logs?.filter(log => log.rule_name).length || 0;

            this.labels.wafSignatureGroupLabel = this.l10nService.getMessage(
                groupsCount === 1 ? this.l10nKeys.groupLabel : this.l10nKeys.groupsLabel,
                [groupsCount],
            );

            this.labels.wafSignatureRuleLabel = this.l10nService.getMessage(
                rulesCount === 1 ? this.l10nKeys.ruleLabel : this.l10nKeys.rulesLabel,
                [rulesCount],
            );
        });

        this.vsLogsStore.wafPolicyRef$.pipe(take(1)).subscribe(ref => this.wafPolicyRef = ref);

        this.vsLogCinematicStore.uriPath$.pipe(take(1)).subscribe(path => this.uriPath = path);
    }

    /** @override */
    public ngOnInit(): void {
        this.wafRuleLogMatchConfig = {
            fields: [],
            layout: {
                showFooter: false,
                hideCheckboxes: true,
                hidePagination: true,
            },
        };

        this.wafPsmLogMatchConfig = {
            fields: [],
            layout: {
                showFooter: false,
                hideCheckboxes: true,
                hidePagination: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.wafRuleLogMatchConfig.fields = [
            {
                label: this.l10nService.getMessage(this.l10nKeys.argumentLabel),
                id: 'waf-rule-log-match-argument',
                templateRef: this.wafRuleLogMatchArgumentFieldTemplateRef,
            }, {
                label: this.l10nService.getMessage(this.globalL10nKeys.valueLabel),
                id: 'waf-rule-log-match-value',
                templateRef: this.wafRuleLogMatchValueFieldTemplateRef,
            },
        ];

        this.wafPsmLogMatchConfig.fields = [
            {
                label: this.l10nService.getMessage(this.l10nKeys.argumentLabel),
                id: 'waf-psm-log-match-argument',
                templateRef: this.wafPsmLogMatchArgumentFieldTemplateRef,
            }, {
                label: this.l10nService.getMessage(this.globalL10nKeys.valueLabel),
                id: 'waf-psm-log-match-value',
                templateRef: this.wafPsmLogMatchValueFieldTemplateRef,
            },
        ];
    }

    /**
     * Some WAF groups don't have group names, and will be in the format of '__N/A:<number>' (where
     * the number is an index) so that groups remain unique in the parent hash. For those groups,
     * 'N/A' will be shown as the group name.
     */
    public getGroupName(groupName: string): string {
        return groupName.indexOf('__N/A:') === 0 ? 'N/A' : groupName;
    }

    /**
     * Return true if the group has a name. For groups without a name ('N/A'), adding exceptions is
     * not allowed.
     */
    public hasGroupName(groupName: string): boolean {
        return this.getGroupName(groupName) !== 'N/A';
    }

    /**
     * Open the dialog to add exceptions.
     */
    public showWafExceptionModal(
        event: Event,
        ruleLogs: IWafRuleLog[],
        ruleGroup: string,
        ruleId?: number,
    ): void {
        event.stopPropagation();

        this.vsLogsWafExceptionDialogService.openDialog(
            this.wafPolicyRef,
            ruleLogs,
            ruleGroup,
            ruleId,
            this.uriPath,
        );
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Waf action labels are fetched from l10nKeys.
     */
    public getwafActionLabel(log: IWafPSMLog | IWafAllowlistLog): string {
        return this.wafActionsHash[
            log?.actions?.length && log.actions[0]
        ];
    }
}
