<avi-card class="local-backup-card">
    <avi-card_header>
        <div class="local-backup-card__header">
            <div class="local-backup-card__title">
                {{ l10nKeys.localBackupLabel | vtranslate }}
            </div>
            <div *ngIf="localBackupEnabled; else elseBlock">
                <div class="local-backup-card__enabled-label">
                    {{ l10nKeys.enabledDataLabel | vtranslate }}
                </div>
            </div>
            <ng-template #elseBlock>
                <div class="local-backup-card__deactivated-label">
                    {{ l10nKeys.deactivatedDataLabel | vtranslate }}
                </div>
            </ng-template>
        </div>
    </avi-card_header>
    <avi-card_body>
        <div class="local-backup-card__row">
            <div class="local-backup-card__control">
                <avi-input-container noMarginTop>
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.directoryLabel | vtranslate }}
                    </label>
                    <input
                        class="sel-version-in-update-card"
                        clrInput
                        [ngModel]="'/var/lib/avi/backups/'"
                        readonly
                    />
                </avi-input-container>
            </div>
        </div>
    </avi-card_body>
</avi-card>
