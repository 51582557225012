<div *ngrxLet="vsLogsSearchBarStore.isLoading$ as isLoading">
    <avi-dropdown-menu [hideSearch]="true">
        <avi-dropdown-options
            [options]="options"
            [optionTemplateRef]="optionTemplateRef"
            [width]="dropdownWidth"
            [height]="dropdownHeight"
            [busy]="isLoading"
            [hideIfNoOptions]="hideDropdownIfNoOptions"
            [noEntriesMessage]="noGroupbyOptionsMessage"
            [errorMessage]="(vsLogsSearchBarStore.hasError$ | async) && l10nKeys.errorMessage | vtranslate"
            (onOptionSelect)="handleOptionSelect($event)"
            (onSubmit)="handleSubmit()"
        ></avi-dropdown-options>
    </avi-dropdown-menu>
</div>

<ng-template
    #operatorOptionTemplateRef
    let-value
>
    <div class="vs-logs-search-bar-dropdown-option__operator-container">
        <span class="vs-logs-search-bar-dropdown-option__operator-symbol"> {{ value.symbol }} </span>
        <span> {{ value.desc }} </span>
    </div>
</ng-template>

<ng-template
    #valueOptionTemplateRef
    let-value
>
    <div class="vs-logs-search-bar-dropdown-option__groupby-container">
        <span class="vs-logs-search-bar-dropdown-option__groupby-value"> {{ value.value }} </span>
        <span> {{ value.count }} ({{ value.percentage }}%) </span>
    </div>
</ng-template>
