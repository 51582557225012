/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-ssl';
const prefix = `${moduleName}.${componentName}`;

export const sslLabel = `${prefix}.sslLabel`;
export const sniHostnameLabel = `${prefix}.sniHostnameLabel`;
export const perfectForwardSecrecyLabel = `${prefix}.perfectForwardSecrecyLabel`;
export const oscpHandlingLabel = `${prefix}.oscpHandlingLabel`;
export const fullTlsFingerprintLabel = `${prefix}.fullTlsFingerprintLabel`;
export const filteredTlsFingerprintLabel = `${prefix}.filteredTlsFingerprintLabel`;
export const normalizedFingerprintLabel = `${prefix}.normalizedFingerprintLabel`;
export const encryptionAlgorithmLabel = `${prefix}.encryptionAlgorithmLabel`;
export const clientSuppliedCiphersLabel = `${prefix}.clientSuppliedCiphersLabel`;

export const ENGLISH = {
    [sslLabel]: 'SSL',
    [sniHostnameLabel]: 'SNI Hostname',
    [perfectForwardSecrecyLabel]: 'Perfect Forward Secrecy',
    [oscpHandlingLabel]: 'OSCP Handling',
    [fullTlsFingerprintLabel]: 'Full TLS Fingerprint',
    [filteredTlsFingerprintLabel]: 'Filtered TLS Fingerprint',
    [normalizedFingerprintLabel]: 'Normalized TLS Fingerprint',
    [encryptionAlgorithmLabel]: 'Encryption Algorithm',
    [clientSuppliedCiphersLabel]: 'Client Supplied Ciphers',
};
