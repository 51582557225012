<div class="prechecks-progress-modal clr-wrapper">
    <avi-confirmation
        size="lg"
        [headerText]="modalHeaderText"
        (onClose)="handleClose()"
    >
        <avi-confirmation_modal-content>
            <div class="prechecks-progress-modal__content">
                <avi-data-grid
                    [config]="selectedNodesGridConfig"
                    [rows]="upgradeNodeStatus"
                    class="prechecks-progress-modal__nodes-grid"
                ></avi-data-grid>

                <div class="prechecks-progress-modal__section">
                    <div *ngIf="preCheckState !== upgradeFsmState.IN_PROGRESS; else simpleList">
                        <h6>{{ l10nKeys.precheckCompletedLabel | vtranslate }}</h6>
                        <prechecks-list-expander [prechecks]="precheckList"></prechecks-list-expander>
                    </div>
                    <ng-template #simpleList>
                        <h6>{{ l10nKeys.precheckProgressLabel | vtranslate }}</h6>
                        <prechecks-list [prechecks]="precheckList"></prechecks-list>
                    </ng-template>
                </div>

                <div class="prechecks-progress-modal__section">
                    <h6>{{ l10nKeys.transcriptLabel | vtranslate }}</h6>
                    <avi-textarea-container>
                        <textarea
                            clrTextarea
                            [ngModel]="transcript"
                            name="upgrade-transcript"
                            class="prechecks-progress-modal__transcript-textarea"
                            readonly
                        ></textarea>
                    </avi-textarea-container>
                </div>
            </div>
        </avi-confirmation_modal-content>

        <avi-confirmation_modal-actions class="prechecks-progress-modal__action-container">
            <avi-checkbox
                name="suppress-warning"
                [(ngModel)]="suppressWarning"
                *ngIf="!readonly && preCheckState === upgradeFsmState.WARNING"
                [noMarginTop]="true"
            >
                {{ l10nKeys.suppressWarningHeader | vtranslate }}
            </avi-checkbox>
            <div>
                <cds-button
                    action="outline"
                    (click)="handleClose()"
                >
                    {{ globalL10nKeys.cancelLabel | vtranslate }}
                </cds-button>
                <cds-button
                    (click)="handleSubmit()"
                    *ngIf="!readonly && this.preCheckState === upgradeFsmState.WARNING"
                    [disabled]="!suppressWarning"
                    class="prechecks-progress-modal__submit-btn"
                >
                    {{ upgradeOperationLabel | vtranslate }}
                </cds-button>
            </div>
        </avi-confirmation_modal-actions>

    </avi-confirmation>
</div>
