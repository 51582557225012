<div
    provideParentForm
    class="system-settings-client-management-access-config"
>
    <avi-fieldset [hideContent]="allowedClientsConfig.areAnyValuesAllowed">

        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <label
                    [for]="'allowed-clients-' + clientType"
                    [objectType]="objectType"
                    [fieldName]="fieldNames[clientType]"
                    aviLabelWithTooltip
                >
                    {{ clientLabel | vtranslate }}
                </label>
                <avi-radio
                    [name]="'allow-any-' + clientType"
                    [value]="true"
                    [(ngModel)]="allowedClientsConfig.areAnyValuesAllowed"
                    (ngModelChange)="onClientTypeRadioButtonSwitch(allowedClientsConfig)"
                >
                    {{ globalL10nKeys.anyLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    [name]="'allowed-' + clientType + 'clients'"
                    [value]="false"
                    [(ngModel)]="allowedClientsConfig.areAnyValuesAllowed"
                    (ngModelChange)="onClientTypeRadioButtonSwitch(allowedClientsConfig)"
                >
                    {{ l10nKeys.stringIpGroupLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>

        <avi-fieldset_content>
            <div
                *ngFor="let value of allowedClientsConfig.allowedValues; let index = index; first as isFirst; trackBy: trackByIndex"
                class="system-settings-client-management-access-config__address-container"
            >
                <cds-divider
                    *ngIf="!isFirst"
                    class="system-settings-client-management-access-config__cds-divider"
                ></cds-divider>

                <div class="system-settings-client-management-access-config__address-header">
                    <label
                        [for]="clientType + '-string-or-ipaddress-' + index"
                        aviLabelWithTooltip
                    >
                        {{ l10nKeys.stringOrIpAddressLabel | vtranslate }}
                    </label>
                    <cds-icon
                        shape="trash"
                        size="18"
                        status="info"
                        class="system-settings-client-management-access-config__remove-icon"
                        (click)="removeIpAddr(index)"
                    ></cds-icon>
                </div>

                <avi-radio-container [noMarginTop]="true">
                    <avi-radio
                        [name]="clientType + '-select-from-available-' + index"
                        [value]="true"
                        [(ngModel)]="value.isGroupRef"
                        (ngModelChange)="onValueTypeRadioButtonSwitch(value)"
                    >
                        {{ l10nKeys.selectFromAvailableLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        [name]="clientType + '-enter-custom-value-' + index"
                        [value]="false"
                        [(ngModel)]="value.isGroupRef"
                        (ngModelChange)="onValueTypeRadioButtonSwitch(value)"
                    >
                        {{ l10nKeys.enterCustomValueLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>

                <avi-input-container
                    noMarginTop
                    *ngIf="!value.isGroupRef"
                >
                    <input
                        clrInput
                        [name]="clientType + '-enter-ip-address-' + index"
                        [id]="clientType + '-enter-ip-address-' + index"
                        [placeholder]="l10nKeys.enterCustomValuePlaceholder | vtranslate"
                        [(ngModel)]="value.value"
                        (ngModelChange)="onAllowedClientsConfigChanged()"
                        regexPattern="anyIPRangeOrSubnet"
                        required
                    />
                </avi-input-container>

                <avi-collection-dropdown
                    *ngIf="value.isGroupRef"
                    [id]="clientType + '-select-ip-group-' + index"
                    [name]="clientType + '-select-ip-group-' + index"
                    [collection]="ipAddrGroupCollection"
                    [(ngModel)]="value.value"
                    (ngModelChange)="onAllowedClientsConfigChanged()"
                    [placeholder]="l10nKeys.selectIpAddrGroupPlaceholder | vtranslate"
                    required
                ></avi-collection-dropdown>
            </div>

            <cds-button
                class="system-settings-client-management-access-config__add-item-button"
                action="flat"
                size="sm"
                (click)="addIpAddr()"
            >
                <clr-icon shape="plus-circle"></clr-icon>
                {{ l10nKeys.addItemButtonLabel | vtranslate }}
            </cds-button>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
