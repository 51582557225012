/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'ping-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitlePing = `${prefix}.sectionTitlePing`;

export const ENGLISH = {
    [sectionTitlePing]: 'Ping',
};
