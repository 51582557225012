<div
    class="expander"
    [ngClass]="openerClassName"
    (click)="handleClick()"
>
    <div class="expander__expander-content">
        <ng-content
            *ngIf="!label"
            select="opener"
        ></ng-content>
        <span *ngIf="label">{{ label }}</span>
    </div>
    <div
        *ngIf="!hideCaret"
        class="expander__caret-container"
    >
        <i
            class="expander__caret"
            [ngClass]="getCaretIconClass()"
        ></i>
    </div>
</div>
<div
    [hidden]="!expanded"
    class="animated fadeInDown"
>
    <ng-content select="content"></ng-content>
</div>
