/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'jwt-server-profile';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [headerLabel]: 'JWT Server Profile',
};
