/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    IGslbGeoDbProfile,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { GslbGeoDbProfileModalComponent } from 'ng/modules/gslb';
import { SystemInfoService } from 'ajs/modules/core/services/system-info';
import { IPromise } from 'angular';
import { GslbGeoDbProfile } from 'object-types';
import * as l10n from 'ajs/modules/gslb/gslb.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Interface for IGslbGeoDbProfile config as data.
 */
interface IGslbGeoDbProfileData {
    config: IGslbGeoDbProfile;
}

/**
 * Interface for GslbGeoDbProfile fileservice.
 */
interface IGslbFileService {
    modified?: string;
    size?: number;
    url?: string;
}

/**
 * Interface for GslbGeoDbProfile fileservice modified with Filename.
 */
export interface IGslbGeoDbProfileStagingFile extends IGslbFileService {
    filename?: string;
}

/**
 * @description GeoProfile Item Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class GslbGeoDbProfileItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IGslbGeoDbProfileData;

    private readonly l10nService: L10nService;

    private readonly systemInfo: SystemInfoService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbgeodbprofile',
            windowElement: GslbGeoDbProfileModalComponent,
            objectType: GslbGeoDbProfile,
            permissionName: AviPermissionResource.PERMISSION_GSLBGEODBPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.systemInfo = this.getAjsDependency_('systemInfoService');
    }

    /**
     * Strips the filename of the file from the file URL.
     */
    public static getStagingFilename(url: string): string {
        return url.split('fileservice?uri=controller://gslb/')[1];
    }

    /**
     * @override
     */
    public isEditable(): boolean {
        return this.systemInfo.localSiteIsGSLBLeader && super.isEditable();
    }

    /**
     * Makes a request to retrieve a list of staging files. Appends a 'filename' property
     * to each item in the list, which is the filename string stripped from the file url.
     */
    public getStagingFiles(): IPromise<IGslbGeoDbProfileStagingFile[]> {
        this.busy = true;
        this.errors = null;

        return this.request('GET', '/api/fileservice?uri=controller://gslb')
            .then(({ data }) => {
                return data.results.map((result: IGslbGeoDbProfileStagingFile) => {
                    result.filename = GslbGeoDbProfileItem.getStagingFilename(result.url);

                    return result;
                });
            })
            .catch(({ data }) => {
                this.errors = data;

                return this.$q.reject();
            })
            .finally(() => this.busy = false);
    }

    /**
     * Getter for geoDbEntries.
     */
    public get geoDbEntries(): RepeatedMessageItem<MessageItem> {
        const { entries: geoDbEntries } = this.config;

        return geoDbEntries as RepeatedMessageItem<MessageItem>;
    }

    /**
     * Getter for geoDb Entries count.
     */
    public get geoDbEntriesCount(): number {
        return this.geoDbEntries.count;
    }

    /**
     * @override
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.geoProfileModalBreadcrumbTitle);
    }
}
