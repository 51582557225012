/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'recommendation-grid';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleRecommendations = `${prefix}.columnTitleRecommendations`;
export const noRecommendationsPlaceholder = `${prefix}.noRecommendationsPlaceholder`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const reasoningLabel = `${prefix}.reasoningLabel`;
export const actionRiskAssesmentLabel = `${prefix}.actionRiskAssesmentLabel`;

export const ENGLISH = {
    [columnTitleRecommendations]: 'Recommendations',
    [noRecommendationsPlaceholder]: 'No recommendations found',
    [descriptionLabel]: 'Description',
    [reasoningLabel]: 'Reasoning',
    [actionRiskAssesmentLabel]: 'Action Risk Assesment',
};
