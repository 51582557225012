/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name validatePort
 * @param {Object[]} validatePort - Array of Service Ports (see protobuf Service message
 *      in vs.proto) used to validate user input port.
 * @description
 *      Validates that provided Service Port is in range of array of service ports in
 *      validatePort param.
 */
angular.module('aviApp').directive('validatePort', () => {
    function portValid(servicePorts, port) {
        return _.any(servicePorts, servicePort => {
            return port >= servicePort.port && port <= servicePort.port_range_end;
        });
    }

    function validatePortLink(scope, elem, attr, ngModel) {
        ngModel.$validators.validatePort = (modelValue, viewValue) => {
            if (!viewValue) {
                return true;
            }

            if (Array.isArray(modelValue)) {
                return _.every(modelValue, val => portValid(scope.validatePort, val));
            } else {
                return portValid(scope.validatePort, viewValue);
            }
        };
    }

    return {
        scope: {
            validatePort: '=',
        },
        require: 'ngModel',
        link: validatePortLink,
    };
});
