/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import {
    debounce,
    every,
    isUndefined,
} from 'underscore';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';

import { StringService } from 'string-service';

import {
    DropdownModelValue,
    IAviDropdownOption,
} from '../avi-dropdown';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from '../avi-dropdown-button';

import { normalizeValue } from '../../utils';
import './avi-collection-dropdown.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * Returns the dropdown option for an item.
 */
const getIDropdownItemOption = (item: Item): IAviDropdownOption => ({
    label: item.getName(),
    value: item.getRef(),
});

/**
 * Used for paginated loading from a collection.
 */
const PAGE_SIZE = 8;

/**
 * @description Dropdown component that retrieves options from a Collection.
 * @author alextsg
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviCollectionDropdownComponent),
        },
    ],
    selector: 'avi-collection-dropdown',
    templateUrl: './avi-collection-dropdown.component.html',
})
export class AviCollectionDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy {
    /**
     * Placeholder shown when no option(s) have been selected.
     */
    @Input()
    public placeholder = 'Select';

    /**
     * required - True if the dropdown is a required input.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * If true, hides the search input.
     */
    @Input()
    public hideSearch = false;

    /**
     * True to disallow creating a new Item.
     */
    @Input()
    public disableCreate = false;

    /**
     * True to disallow editing a selected Item.
     */
    @Input()
    public disableEdit = false;

    /**
     * Params to be passed to collection.create.
     */
    @Input()
    public createParams = {};

    /**
     * True to allow multiple selection.
     */
    @Input()
    public multiple = false;

    /**
     * List of actions in the actions menu.
     */
    @Input()
    public actions: IAviDropdownButtonAction[];

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * Position of the actions menu tooltip.
     */
    @Input()
    public actionPosition = AviDropdownButtonPosition.TOP_RIGHT;

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input()
    public readonly ?= false;

    /**
     * Optional Helper-text.
     * To be displayed below dropdown field.
     */
    @Input()
    public helperText ?= '';

    /**
     * To allow the access to dropdown action when the dropdown is disabled.
     */
    @Input()
    public allowActionsWhileDisabled = false;

    /**
     * Event emitter on collection item edited.
     */
    @Output()
    public onCollectionItemEditSubmit = new EventEmitter<void>();

    /**
     * If true, prevent loading of item when dropdown is created.
     */
    @Input()
    public preventLoad ?= false;

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * If true, shows a spinner and disables selection/actions.
     */
    public busy = false;

    /**
     * Map of selected ref and its item instance.
     */
    public selectedItemsMap = new Map<DropdownModelValue, Item>();

    /**
     * Total number of loaded items.
     */
    private totalItems = 0;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: DropdownModelValue;

    /**
     * Default create action for the button menu.
     */
    private defaultCreateAction: IAviDropdownButtonAction = {
        label: this.l10nService.getMessage(globalL10nKeys.createLabel),
        onClick: () => this.create(),
        disabled: () => this.disableCreate || !this.collection.isCreatable(),
    };

    /**
     * Default edit action for the button menu.
     */
    private defaultEditAction: IAviDropdownButtonAction = {
        label: this.l10nService.getMessage(globalL10nKeys.editLabel),
        onClick: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            selectedItem.edit().then((editedResponse: void) => {
                const editedItem = editedResponse as any as Item;

                this.value = editedItem.getRef();
                this.setSelectedItemsMap();
                this.collection.load().then(() => {
                    this.onCollectionItemEditSubmit.emit();
                });
            });
        },
        disabled: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            return this.disableEdit || !selectedItem || !selectedItem.isEditable();
        },
        hidden: () => {
            const selectedItem = this.selectedItemsMap.get(this.value);

            return this.multiple || !selectedItem;
        },
    };

    constructor(
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        this.handleSearch = debounce(this.handleSearch, 500);
    }

    /** @override */
    public ngOnInit(): void {
        this.collection.updateViewportSize(PAGE_SIZE);
        this.collection.updateItemsVisibility(undefined, this.totalItems);
        this.setSelectedItemsMap();

        if (isUndefined(this.actions)) {
            this.actions = this.getDefaultActions();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.resetSelectedItemsMap();
    }

    /**
     * Called when the options list has scrolled to the end. Loads new items.
     */
    public handleScrollEnd(totalItems: number): void {
        if (totalItems > this.totalItems) {
            this.collection.updateItemsVisibility(undefined, totalItems);
            this.totalItems = totalItems;
        }
    }

    /**
     * Called when an option has been selected. Sets the Item instance(s).
     */
    public handleSelect(): void {
        this.setSelectedItemsMap();
    }

    /**
     * Creates dropdown options from the collection's Items.
     */
    public get options(): IAviDropdownOption[] {
        return this.collection.itemList.map(getIDropdownItemOption);
    }

    /**
     * Called when the options menu has been opened or closed. When opened, loads the collection.
     */
    public handleOptionsOpenedChange(opened: boolean): void {
        if (opened) {
            this.totalItems = 0;
            this.collection.load(undefined, true);
        } else {
            this.collection.setSearch('');
        }
    }

    /**
     * Creates a new Item.
     */
    public async create(): Promise<void> {
        const createdItem = await this.collection.create(undefined, { ...this.createParams });
        const createdRef = createdItem.getRef();

        if (this.value instanceof Array) {
            this.value = [...this.value, createdRef];
        } else if (this.multiple) {
            this.value = [createdRef];
        } else {
            this.value = createdRef;
        }

        this.setSelectedItemsMap();
    }

    /**
     * Called to make an HTTP request search for Items.
     */
    public handleSearch = (searchTerm: string): void => {
        this.totalItems = 0;
        this.collection.search(searchTerm?.trim());
    };

    /**
     * Called to remove a selected value. Applicable for multiple-selection.
     */
    public handleRemoveValue(ref: DropdownModelValue): void {
        if (!(this.value instanceof Array)) {
            throw new Error('Can\'t remove value from a non-multiple-selection CollectionDropdown');
        }

        this.value = this.value.filter(value => value !== ref);
        this.setSelectedItemsMap();
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): DropdownModelValue {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue. If multiple is set to true, then the modelValue takes the form of
     * an array. Otherwise it's just a string.
     */
    public set value(val: DropdownModelValue) {
        if (this.modelValue !== val) {
            const normalizedValue = normalizeValue(val);

            this.modelValue = normalizedValue;
            this.onChange(normalizedValue);
        }

        this.onTouched();
    }

    /**
     * Returns true if every action is disabled.
     */
    public allActionsDisabled(): boolean {
        return every(this.actions, action => {
            if (typeof action.disabled !== 'function') {
                return false;
            }

            try {
                return action.disabled();
            } catch (error) {
                throw new Error(`action.disabled failed: ${error}`);
            }
        });
    }

    /**
     * Return true if the actions ellipses should be hidden.
     */
    public hideActions(): boolean {
        return this.busy || this.readonly || !this.actions?.length || this.allActionsDisabled();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelValue) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: DropdownModelValue): void {
        this.modelValue = normalizeValue(value);
        this.setSelectedItemsMap();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Function that is called by the forms API when the control status changes to or from
     * 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
     */
    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /*************************************************************************/

    /**
     * Returns a set of default actions if none are passed in.
     */
    private getDefaultActions(): IAviDropdownButtonAction[] {
        const actions: IAviDropdownButtonAction[] = [];

        if (!this.disableCreate && this.collection.isCreatable()) {
            actions.push(this.defaultCreateAction);
        }

        if (!this.disableEdit && !this.multiple) {
            actions.push(this.defaultEditAction);
        }

        return actions;
    }

    /**
     * Sets selectedItemsMap based on ngModel value.
     */
    private setSelectedItemsMap(): void {
        this.resetSelectedItemsMap();

        const { value } = this;
        const promises: Array<Promise<void>> = [];

        if (typeof value === 'string') {
            promises.push(this.getSelectedItem(value).then(item => {
                this.selectedItemsMap.set(value, item);
            }));
        } else if (Array.isArray(value)) {
            value.forEach((ref: string) => {
                promises.push(Promise.resolve(this.getItemFromCollection(ref))
                    .then(item => {
                        this.selectedItemsMap.set(ref, item);
                    }));
            });

            this.busy = true;
            Promise.all(promises).finally(() => this.busy = false);
        }
    }

    /**
     * Destroys item instances & Clears selectedItems map.
     */
    private resetSelectedItemsMap(): void {
        if (this.selectedItemsMap.size) {
            for (const item of this.selectedItemsMap.values()) {
                item.destroy();
            }

            this.selectedItemsMap.clear();
        }
    }

    /**
     * Return true if the collection currently contains the Item with the specified ref in its
     * itemsList.
     */
    private collectionHasItem(url: string): boolean {
        const id = this.stringService.slug(url);

        return Boolean(this.collection.getItemById(id));
    }

    /**
     * Return an Item from the Collection. If the Item doesn't exist in the Collection's itemsList,
     * then the config will be empty.
     */
    private getItemFromCollection(url: string): Item {
        const id = this.stringService.slug(url);
        const item = this.collection.getItemById(id);

        // When setting modelValue on component initialization,
        // collection will not be loaded.
        // so itemList will be empty.
        let config = {};

        if (item instanceof ObjectTypeItem) {
            config = item.flattenConfig();
        } else if (item instanceof Item) {
            config = item.getConfig();
        }

        return this.collection.createNewItem({
            id,
            data: {
                config: {
                    ...config,
                    url,
                },
            },
        }, true) as Item;
    }

    /**
     * Return an Item instance based on a ref. For single selection, if the Item isn't present in
     * the collection's list of items (ex. when editing a modal with an already-populated dropdown),
     * the Item needs to be loaded to get information (such as the tenant_ref) or possibly
     * properties in the config to know if the Item is editable or not.
     *
     * For multiple selection that's currently not necessary since editing isn't allowed for
     * multiple selection. This may change, for example if the name of the Item is a custom format
     * and requires properties from the config.
     */
    private getSelectedItem = (value: string): Promise<Item> => {
        let promise;
        let item: Item;

        if (this.collectionHasItem(value)) {
            item = this.getItemFromCollection(value);

            promise = Promise.resolve(item);
        } else {
            item = this.collection.createNewItem({
                id: this.stringService.slug(value),
            }, true) as Item;

            promise = this.preventLoad ? Promise.resolve(item) : item.load().then(() => item);
        }

        return promise as Promise<Item>;
    };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: DropdownModelValue): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
