/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'jwt-claim-match-config';
const prefix = `${moduleName}.${componentName}`;

export const claimNameLabel = `${prefix}.claimNameLabel`;
export const claimNamePlaceholder = `${prefix}.claimNamePlaceholder`;
export const claimValueLabel = `${prefix}.claimValueLabel`;
export const claimValueMatchLabel = `${prefix}.claimValueMatchLabel`;
export const integerPlaceholder = `${prefix}.integerPlaceholder`;
export const typeDropdownLabel = `${prefix}.typeDropdownLabel`;
export const mandatoryLabel = `${prefix}.mandatoryLabel`;
export const validateLabel = `${prefix}.validateLabel`;
export const trueLabel = `${prefix}.trueLabel`;
export const falseLabel = `${prefix}.falseLabel`;

export const ENGLISH = {
    [claimNameLabel]: 'Claim Name',
    [claimNamePlaceholder]: 'Enter Claim Name',
    [typeDropdownLabel]: 'Type',
    [mandatoryLabel]: 'Mandatory',
    [validateLabel]: 'Validate',
    [claimValueLabel]: 'Claim Value',
    [claimValueMatchLabel]: 'Claim Value Match',
    [integerPlaceholder]: 'Enter Integer',
    [trueLabel]: 'True',
    [falseLabel]: 'False',
};
