/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';
import { DNSConfig } from 'object-types';
import { GSLB, ISubdomainSiteInfo } from 'items/gslb.item.factory';
import { DNSConfigConfigItem } from 'message-items/dns-config.config-item.factory';
import { FullModalService } from 'ng/modules/core/services/full-modal/full-modal.service';
import { StringService } from 'string-service';
import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';

import { ISiteDataRow } from './gslb-placement-grid/gslb-placement-grid.component';
import { GslbPlacementModalComponent } from './gslb-placement-modal/gslb-placement-modal.component';

import * as l10n from './gslb-subdomain-modal.l10n';
import './gslb-subdomain-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for GSLB Subdomain.
 *
 * @author Nisar Nadaf, alextsg
 */
@Component({
    selector: 'gslb-subdomain-modal',
    templateUrl: './gslb-subdomain-modal.component.html',
})
export class GslbSubdomainModalComponent implements OnInit {
    @Input()
    public editable: DNSConfigConfigItem;

    @Input()
    public gslb: GSLB;

    @Input()
    public editMode = false;

    /**
     * List of GSLB sites with 'all subdomains' enabled.
     */
    @Input()
    public gslbSitesWithAllSubdomains: ISubdomainSiteInfo[] = [];

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<void>();

    public objectType = {
        DNSConfig,
    };

    public verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * List of gslb sites selected for configuring new subdomain.
     */
    public selectedGslbSites: ISiteDataRow[] = [];

    /**
     * If true, Select all sites with 'Host on all subdomains' option selected.
     */
    public hostOnSitesWithAllSubdomains = true;

    constructor(
        private readonly l10nService: L10nService,
        private readonly fullModalService: FullModalService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editMode) {
            this.setSelectedGslbSites();
            this.hostOnSitesWithAllSubdomains = !this.selectedGslbSites.length;
        }
    }

    /**
     * Fire on submit.
     */
    public submit(): void {
        if (this.hostOnSitesWithAllSubdomains) {
            this.selectedGslbSites = [];
        }

        this.gslb.updateGslbSitesWithSubdomain(
            this.selectedGslbSites,
            this.editable.config.domain_name,
        );

        this.onSubmit.emit();
    }

    /**
     * Fire on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Open child modal to add new site to subdomain
     */
    public addSiteToDomain(): void {
        this.fullModalService.addModal({
            component: GslbPlacementModalComponent as Type<Component>,
            componentProps: {
                gslbSites: this.gslb.config.sites,
                onCancel: () => {
                    this.fullModalService.removeModalByComponent(
                        GslbPlacementModalComponent as Type<Component>,
                    );
                },
                onSubmit: (selectedSiteAndDnsVs: ISiteDataRow) => {
                    this.addSelectedSiteToList(selectedSiteAndDnsVs);
                    this.fullModalService.removeModalByComponent(
                        GslbPlacementModalComponent as Type<Component>,
                    );
                },
            },
            getName: () => this.l10nService.getMessage(this.l10nKeys.gslbSiteLabel),
            getDescription: () => '',
        });
    }

    /**
     * Remove sites selected for adding subdomain.
     */
    public removeSites(rows: ISiteDataRow[]): void {
        rows.forEach((row: ISiteDataRow) => {
            const index = this.selectedGslbSites.indexOf(row);

            this.selectedGslbSites.splice(index, 1);
        });
    }

    /**
     * Open child modal to edit site configuration of subdomain.
     */
    public editSite(site: ISiteDataRow): void {
        const index = this.selectedGslbSites.indexOf(site);

        this.fullModalService.addModal({
            component: GslbPlacementModalComponent as Type<Component>,
            componentProps: {
                gslbSites: this.gslb.config.sites,
                selectedSiteAndDnsVs: site,
                editMode: true,
                onCancel: () => {
                    this.fullModalService.removeModalByComponent(
                        GslbPlacementModalComponent as Type<Component>,
                    );
                },
                onSubmit: (selectedSiteAndDnsVs: ISiteDataRow) => {
                    this.selectedGslbSites[index] = selectedSiteAndDnsVs;
                    this.fullModalService.removeModalByComponent(
                        GslbPlacementModalComponent as Type<Component>,
                    );
                },
            },
            getName: () => this.l10nService.getMessage(this.l10nKeys.gslbSiteLabel),
            getDescription: () => '',
        });
    }

    /**
     * Set selected GslbSites for current subdomain.
     */
    public setSelectedGslbSites(): void {
        const { sites } = this.gslb.config;

        this.selectedGslbSites = sites.config.reduce((
            rows: ISiteDataRow[],
            gslbSite: GslbSiteConfigItem,
        ) => {
            const dnsVsRefs =
                gslbSite.getDnsVsRefsByDomain(this.editable.config.domain_name, false);

            if (dnsVsRefs?.length) {
                rows.push({
                    siteName: gslbSite.config.name,
                    dnsVsNames: dnsVsRefs.map(refs => this.stringService.name(refs)),
                });
            }

            return rows;
        }, []);
    }

    /**
     * Add site to selectedSite list. If site is already present then,
     * append DnsVses to existing array of that site.
     */
    private addSelectedSiteToList(site: ISiteDataRow): void {
        const index = this.selectedGslbSites.findIndex(
            (selectedSite: ISiteDataRow) => selectedSite.siteName === site.siteName,
        );

        if (index > -1) {
            const mergedList = [
                ...this.selectedGslbSites[index].dnsVsNames,
                ...site.dnsVsNames,
            ];

            // Remove duplicate values from array.
            this.selectedGslbSites[index].dnsVsNames = [...new Set(mergedList)];
        } else {
            this.selectedGslbSites.push(site);
        }
    }
}
