/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    GslbSiteType,
    SiteMemberType,
} from 'generated-types';

import { IGenericMessageItemConfig } from 'ajs/modules/data-model/factories/message-item.factory';
import './gslb-site-preview.component.less';
import * as l10n from './gslb-site-preview.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Preview component for Gslb site modal used for diplaying site info on right pannel.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-site-preview',
    templateUrl: './gslb-site-preview.component.html',
})
export class GslbSitePreviewComponent implements OnInit {
    @Input()
    public readonly config: IGenericMessageItemConfig;

    /**
     * Gslb site type. Avi Site/Third-party site.
     */
    @Input()
    public readonly type: string;

    /**
     * To show if gslb site is a leader or follower site.
     */
    @Input()
    public readonly isLeader: boolean;

    public readonly l10nKeys = l10nKeys;

    /**
     * Gslb site type value used in template.
     */
    public siteType: string;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.siteType = this.getSiteType();
    }

    /**
     * Return the site type string value to be displayed in template.
     */
    public getSiteType(): string {
        const {
            activeMemberLabel,
            followerLabel,
            leaderLabel,
            passiveMemberLabel,
            thirdPartySiteLabel,
        } = l10nKeys;

        if (this.type === GslbSiteType.GSLB_THIRD_PARTY_SITE) {
            return this.l10nService.getMessage(thirdPartySiteLabel);
        }

        const type = this.config.member_type === SiteMemberType.GSLB_ACTIVE_MEMBER ?
            this.l10nService.getMessage(activeMemberLabel) :
            this.l10nService.getMessage(passiveMemberLabel);

        return this.isLeader ?
            `${type} (${this.l10nService.getMessage(leaderLabel)})` :
            `${type} (${this.l10nService.getMessage(followerLabel)})`;
    }
}
