/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SecurityModule */

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    AviPermissionResource,
    IPKIProfile,
} from 'generated-types';

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { PkiProfileModalComponent } from 'ng/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './pki-profile.l10n';
import { SSLCertificateConfigItem } from './ssl-certificate.config-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TPKIProfilePartial = Omit<IPKIProfile, 'ca_certs'>;

interface IPKIProfileConfig extends TPKIProfilePartial {
    ca_certs: RepeatedMessageItem<SSLCertificateConfigItem>;
}

interface IPKIProfileData {
    config: IPKIProfileConfig;
}

/**
 * Child fieldname constants.
 */
const CA_CERTS = 'ca_certs';

/**
 * @description PKIProfile Item class.
 *
 * @author Satish Pednekar
 */
export class PKIProfile extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IPKIProfileData;
    private l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'pkiprofile',
            objectType: 'PKIProfile',
            windowElement: PkiProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_PKIPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for PKI Certificate Authority.
     */
    public get pkiCertificateAuthority(): RepeatedMessageItem<SSLCertificateConfigItem> {
        const { [CA_CERTS]: certificateAuthority } = this.getConfig();

        return certificateAuthority;
    }

    /**
     * Method to add new Certificate Authority(CA) to PKI Profile.
     */
    public addCertificateAuthority(): void {
        this.addChildMessageItem({
            field: CA_CERTS,
        });
    }

    /**
     * Reset Is Federated property.
     */
    public resetIsFederated(): void {
        const { is_federated: isFederated } = this.config;

        this.config.is_federated = !isFederated;
    }

    /**
     * Delete CRL File Refs from config.
     */
    public deleteCrlFileRefs(): void {
        delete this.config.crl_file_refs;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.pkiProfileModalBreadcrumbTitle);
    }
}
