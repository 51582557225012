/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PersistenceProfileModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    PersistenceProfile,
    PersistenceProfileCollection,
} from './factories';

import {
    PERSISTENCE_PROFILE_COLLECTION_TOKEN,
    PERSISTENCE_PROFILE_ITEM_TOKEN,
} from './persistence-profile.tokens';

const persistenceProfileModule = angular.module('avi/persistence-profile');

const factories = [
    {
        factory: PersistenceProfileCollection,
        name: PERSISTENCE_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PersistenceProfile,
        name: PERSISTENCE_PROFILE_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(persistenceProfileModule, 'factory', name, factory);
});
