<div provideParentForm>
    <avi-data-grid
        [config]="safenetLunaServerGridConfig"
        [rows]="safenetLunaServerList.config"
        [invalidateForm]="safenetLunaServerList.isEmpty()"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addSafenetLunaServer()"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
</div>
