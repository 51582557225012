<div class="se-group-list-expander grid-container grid-container--gap--none">
    <div class="grid-container__cell--w--6 se-group-list-expander__section-container">
        <div class="se-group-list-expander__section-label">
            {{ globalL10nKeys.serviceEnginesLabel | vtranslate }}
        </div>
        <div class="se-group-list-expander__section-data">
            <ng-container
                [ngTemplateOutlet]="sectionDataTemplate"
                [ngTemplateOutletContext]="{ data: seGroup.getInventoryData().serviceengines, isSeType: true }"
            ></ng-container>
        </div>
    </div>
    <div class="grid-container__cell--w--6 se-group-list-expander__section-container">
        <div class="se-group-list-expander__section-label">
            {{ globalL10nKeys.virtualServicesLabel | vtranslate }}
        </div>
        <div class="se-group-list-expander__section-data">
            <ng-container
                [ngTemplateOutlet]="sectionDataTemplate"
                [ngTemplateOutletContext]="{ data: seGroup.getInventoryData().virtualservices }"
            ></ng-container>
        </div>
    </div>
    <ng-template 
        #sectionDataTemplate 
        let-data="data" 
        let-type="isSeType"
    >
        <span *ngFor="let ref of data; first as isFirst; trackBy: trackByIndex">
            {{ isFirst ? "" : ", " }}
            <a
                *ngIf="ref; else emptyLabelTemplate"
                (click)="navigateTo(ref, type)"
                class="se-group-list-expander__link"
            >
                {{ ref | name }}
            </a>
        </span>
        <span *ngIf="!data.length; then emptyLabelTemplate"></span>
    </ng-template>
    <ng-template #emptyLabelTemplate>
        <span>{{ globalL10nKeys.emptyLabel | vtranslate }}</span>
    </ng-template>
</div>
