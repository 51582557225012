<full-modal-preview>
    <full-modal-preview_container>
        <div class="ssl-rating-preview clr-wrapper">
            <h4>{{ l10nKeys.sslRatingHeader | vtranslate }}</h4>
            <div class="ssl-rating-preview__item">
                <h6>{{ l10nKeys.securityScoreLabel | vtranslate }}</h6>
                <div
                    *ngIf="!isSslRatingLoading else loader"
                    class="ssl-rating-preview__item-value"
                >
                    <span
                        *ngIf="securityScore else noData"
                        [ngClass]="getSslRatingClassName(SSLRatingType.SECURITY_SCORE)"
                    >
                        {{ securityScore }}
                    </span>
                </div>
            </div>
            <div class="ssl-rating-preview__item">
                <h6>{{ l10nKeys.performanceRatingLabel | vtranslate }}</h6>
                <div
                    *ngIf="!isSslRatingLoading else loader"
                    class="ssl-rating-preview__item-value"
                >
                    <span
                        *ngIf="performanceRating else noData"
                        [ngClass]="getSslRatingClassName(SSLRatingType.PERFORMANCE_RATING)"
                    >
                        {{ performanceRating }}
                    </span>
                </div>
            </div>
            <div class="ssl-rating-preview__item">
                <h6>{{ l10nKeys.compatibilityRatingLabel | vtranslate }}</h6>
                <div
                    *ngIf="!isSslRatingLoading else loader"
                    class="ssl-rating-preview__item-value"
                >
                    <span
                        *ngIf="compatibilityRating else noData"
                        [ngClass]="getSslRatingClassName(SSLRatingType.COMPATIBILITY_RATING)"
                    >
                        {{ compatibilityRating }}
                    </span>
                </div>
            </div>
            <div class="ssl-rating-preview__item-preview-defaults">
                <item-preview-defaults 
                    [config]="config"
                    [isItem]="true"
                ></item-preview-defaults>
            </div>
        </div>
    </full-modal-preview_container>
</full-modal-preview>

<ng-template #loader>
    <div class="ssl-rating-preview__item-value">
        <clr-spinner [clrSmall]="true">
        </clr-spinner>
    </div>
</ng-template>

<ng-template #noData>
    <span class="ssl-rating-preview__rating--no-data">-</span>
</ng-template>
