/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'groups';
const componentName = 'string-group-list';

const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const numberOfStringsLabel = `${prefix}.numberOfStringsLabel`;

export const ENGLISH = {
    [headerLabel]: 'String Groups',
    [numberOfStringsLabel]: 'Number of Strings',
};
