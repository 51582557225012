/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngine */

import {
    Component,
    Type,
} from '@angular/core';

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    IIpAddr,
    IpAddrMode,
    IpAddrType,
    IServiceEngine,
    IServiceEngineGroup,
    IServiceEngineInternal,
    IVlanInterface,
    IvNIC,
    IvNICNetwork,
    ServiceEngineEnableState,
} from 'generated-types';

import { sortBy } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { ServiceEngine, VlanInterface } from 'object-types';
import { StringService } from 'string-service';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { getSubnetString } from 'ng/utils/ip-prefix-parser.utils';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import { VNICConfigItem } from 'message-items/vnic.config-item.factory';
import { VNICNetworksConfigItem } from 'message-items/vnic-networks.config-item.factory';

import {
    VRFContextCollection,
} from 'ajs/modules/vrf-context/factories/vrf-context.collection.factory';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ServiceEngineCollection } from 'collections/service-engine.collection.factory';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { withUpgradeStatusMixin } from 'ajs/modules/upgrade/mixins/with-upgrade-status.mixin';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

const { ...globalL10nKeys } = globalL10n;

export const STATIC_IPV4 = 'STATIC_IPv4';
export const STATIC_IPV6 = 'STATIC_IPv6';
export const DATA_NETWORK_FIELD = 'data_vnics';
export const MANAGEMENT_NETWORK_FIELD = 'mgmt_vnic';
export const UNKNOWN = 'Unknown';

interface IVinicPrefix {
    list: string,
    prefixes: string[],
}

/**
 * List of all the modes required to create vincHash.
 */
export const ipModes = [
    'STATIC_IPv4',
    'STATIC_IPv6',
    'DHCP',
    'VIP',
    'DOCKER_HOST',
] as const;

type TVnicHash = { [K in typeof ipModes[number]]?: IVinicPrefix };

/**
 * Type of VNIC Network Data.
 */
export interface IVnicNetworksData extends IvNICNetwork {
    maskedIp?: string;
    rowIndex?: number;
}

/**
 * Type of Vlan Interface Data.
 */
export interface IVlanInterfaceData extends IVlanInterface {
    if_name?: string;
    vlan_id?: number;
    vrf_ref?: string;
    vnic_networks?: IVnicNetworksData[];
    messageType?: string
    parentInterfaceName?: string;
    vipVnicNetworks?: string;
    rowId?: number;
}

export interface IVnicInfo extends IvNIC {
    state?: string;
    name?: string;
    vnicHash?: TVnicHash;
    mgmtType?: boolean;
    vlan_interfaces?: IVlanInterfaceData[];
    vnic_networks?: IVnicNetworksData[];
}

interface IDetailsPageParams {
    seId: string;
    cloudId: string;
}

type TServiceEnginePartial = Omit<IServiceEngine, 'data_vnics' | 'mgmt_vnic'>;
interface IServiceEngineConfig extends TServiceEnginePartial {
    data_vnics: RepeatedMessageItem<VNICConfigItem>;
    mgmt_vnic: VNICConfigItem;
    vlan_interfaces: IVlanInterfaceData[]; // UI only field.
    networks_vnics: VNICConfigItem[]; // UI only field.
}

type TServiceEngineInternalPartial = Omit<IServiceEngineInternal, 'data_vnics' | 'mgmt_vnic'>;

interface IServiceEngineMergedConfig extends TServiceEngineInternalPartial, IServiceEngineConfig {
    vnic?: IVnicInfo[];
    mgmt_ip_address?: IIpAddr;
    mgmt_ip6_address?: IIpAddr;
    se_group_ref_data?: IServiceEngineGroup;
}

interface IServiceEngineData {
    config: IServiceEngineMergedConfig
}

const extendedArgs = {
    objectName: 'serviceengine',
    objectType: ServiceEngine,
    windowElement: 'lazy-load',
    detailsStateName_: 'authenticated.infrastructure.cloud.serviceengine-detail',
    params: {
        include_name: true,
        join: [
            'host_ref',
            'serviceenginegroup:se_group_ref',
            'cloud_ref',
        ].join(),
    },
};

/**
 * @description Service Engine item
 * @author Alex Malitsky, Vinay Jadhav
 */
export class ServiceEngineItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ngDependencies = [
        StringService,
        'RangeParser',
        L10nService,
    ];

    public data: IServiceEngineData;

    public getConfig: () => IServiceEngineMergedConfig;

    public seCollection: ServiceEngineCollection;

    public readonly stringService: StringService;

    public readonly l10nService: L10nService;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(args = {}) {
        super({
            ...extendedArgs,
            ...args,
        });

        this.stringService = this.getNgDependency(StringService);
        this.l10nService = this.getNgDependency(L10nService);
    }

    /**
     * Return array of string with ip address & subnet.
     */
    public static getIpAddressAndSubnetFromMaskedIp(maskedIp: string): string[] {
        return maskedIp.split('/');
    }

    /**
     * Remove "avi_" from network interface names.
     */
    public static trimSENetInterfaceName(name: string): string {
        return name?.replace(/^avi_/g, '') ?? '';
    }

    /**
     * Check if the interface is in use.
     */
    public static isInterfaceInUse(vnic: VNICConfigItem): boolean {
        const { config } = vnic;

        return Boolean(config.enabled && config.connected && config.vnic_networks.count);
    }

    /**
     * Return HTTP Method to delete an SE.
     */
    public static getHttpMethodToDelete(force: boolean): 'POST' | 'DELETE' {
        return force ? 'POST' : 'DELETE';
    }

    /**
     * Return VNIC name. Preferably name of adapter.
     */
    public static getVnicName(vnic: IVnicInfo, withInterfaceName = false): string {
        const {
            adapter,
            if_name: interfaceName,
        } = vnic;

        if (interfaceName) {
            const knownAdapter = adapter && adapter !== UNKNOWN;

            if (knownAdapter) {
                if (withInterfaceName) {
                    return `${adapter} (${interfaceName})`;
                }

                return adapter;
            }

            return interfaceName;
        }

        return '';
    }

    /**
     * Get Vlan name.
     */
    public static getVlanName(vlan: IVnicInfo): string {
        return vlan?.if_name || '';
    }

    /**
     * Get Vnic ID.
     */
    public static getVnicId(vnic: IVnicInfo): string {
        const { mac_address: macAddress } = vnic;
        const name = ServiceEngineItem.getVnicName(vnic);

        return `${name}:${macAddress}`;
    }

    /**
     * Get Vlan ID.
     */
    public static getVlanId(vlan: IVlanInterfaceData): string {
        const { vlan_id: vlanId } = vlan;
        const name = ServiceEngineItem.getVlanName(vlan);

        return `${name}:${vlanId}`;
    }

    /**
     * Set vincHash for data_vnics and mgmt_vnic.
     */
    public setVnicHash = (vnic: VNICConfigItem): void => {
        const vnicHash: TVnicHash = {};

        ipModes.forEach(mode => {
            vnicHash[mode] = {
                list: undefined,
                prefixes: [],
            };
        });

        const { config: vnicNetworksConfig = [] } = vnic.config.vnic_networks;

        vnicNetworksConfig.forEach((echConfigInfo: VNICNetworksConfigItem) => {
            const { ip, mode } = echConfigInfo.config;

            if (ip && mode) {
                const { ip_addr: ipAddr } = ip.config;

                let vnicHashKey = '';

                if (mode === IpAddrMode.STATIC) {
                    if (ipAddr.config.type === IpAddrType.V6) {
                        vnicHashKey = STATIC_IPV6;
                    } else if (ipAddr.config.type === IpAddrType.V4) {
                        vnicHashKey = STATIC_IPV4;
                    }
                } else {
                    vnicHashKey = mode;
                }

                vnicHash[vnicHashKey]?.prefixes.push(getSubnetString(ip.flattenConfig()));
            }
        });

        for (const value of Object.values(vnicHash)) {
            if (value.prefixes.length) {
                value.list = value.prefixes.join(',');
            }
        }

        vnic.config.vnicHash = vnicHash;
    };

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { ServiceEngineModalComponent } = await import(
            /* webpackChunkName: "service-engine-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/service-engine-modal/service-engine-modal.component'
        );

        return ServiceEngineModalComponent as Type<Component>;
    }

    /** @override */
    public beforeEdit(): void {
        if (this.config.mgmt_vnic) {
            const { mgmt_vnic: mgmtVnics } = this.config;

            this.setVnicHash(mgmtVnics);
            mgmtVnics.config.mgmtType = true;

            const mgmtVnicInfo: IVnicInfo = {
                if_name: mgmtVnics.config.if_name,
                adapter: mgmtVnics.config.adapter,
            };

            mgmtVnics.config.name =
                ServiceEngineItem.getVnicName(mgmtVnicInfo);
        }

        this.config.data_vnics?.config.forEach(
            (eachVinicsInfo: VNICConfigItem) => {
                const mgmtVnicInfo: IVnicInfo = {
                    if_name: eachVinicsInfo.config.if_name,
                    adapter: eachVinicsInfo.config.adapter,
                };

                eachVinicsInfo.config.name = ServiceEngineItem.getVnicName(mgmtVnicInfo);
                this.setVnicHash(eachVinicsInfo);
            },
        );

        this.createNetworkData();

        this.createVlanInterfaceGridData();
    }

    /**
     * Create network data by merging management network & data network.
     */
    public createNetworkData(): void {
        if (!this.config.networks_vnics) {
            this.config.networks_vnics = [];
        }

        const { mgmt_vnic: mgmtVnic, data_vnics: dataVnics } = this.config;

        if (mgmtVnic) {
            mgmtVnic.config.is_mgmt = true;
            this.config.networks_vnics.push(mgmtVnic);
        }

        if (dataVnics?.count) {
            dataVnics.config.forEach((dataVnic: VNICConfigItem) => {
                dataVnic.config.is_mgmt = false;
                this.config.networks_vnics.push(dataVnic);
            });
        }
    }

    /**
     * Create vlan interface grid data.
     */
    public createVlanInterfaceGridData(): void {
        if (!this.config.vlan_interfaces) {
            this.config.vlan_interfaces = [];
        }

        this.config.networks_vnics?.forEach((vnicConfig: VNICConfigItem) => {
            const vnicFlatConfig = vnicConfig.flattenConfig() as unknown as IVnicInfo;

            if (vnicFlatConfig?.vlan_interfaces?.length) {
                vnicFlatConfig.vlan_interfaces.forEach((
                    vlanInterface: IVlanInterfaceData,
                    index: number,
                ) => {
                    vlanInterface.messageType = VlanInterface;
                    vlanInterface.rowId = index;
                    vlanInterface.vnic_networks.forEach((vnicNetwork: IVnicNetworksData) => {
                        vnicNetwork.maskedIp =
                            `${vnicNetwork.ip.ip_addr.addr}/${vnicNetwork.ip.mask}`;
                    });
                    this.config.vlan_interfaces.push(vlanInterface);
                });
            }
        });

        this.config.vlan_interfaces.forEach(
            (vlanInterface: IVlanInterfaceData, index: number) => vlanInterface.rowId = index,
        );
    }

    /**
     * Get default vlan interface data.
     */
    public getVlanInterfaceData(): IVlanInterfaceData {
        const newVlanData: IVlanInterfaceData = {
            if_name: '',
            vlan_id: 0,
            vrf_ref: '',
            vnic_networks: [],
            vipVnicNetworks: '',
            messageType: VlanInterface,
            rowId: this.config.vlan_interfaces.length + 1,
        };

        return newVlanData;
    }

    /**
     * Set old values back to object.
     */
    public setOldValuesOnCancel(oldValue: IVlanInterfaceData): void {
        this.onVlanInterfaceSaveModal(oldValue, oldValue, true);
    }

    /**
     * Create/update entry in vlan interfaces data.
     */
    public onVlanInterfaceSaveModal(
        updatedValues: IVlanInterfaceData,
        oldVlanInterface: IVlanInterfaceData,
        isEditMode = false,
    ): void {
        if (isEditMode) {
            this.config?.vlan_interfaces.forEach(
                (vlanInterface: IVlanInterfaceData) => {
                    if (vlanInterface.rowId === oldVlanInterface.rowId) {
                        vlanInterface.if_name = updatedValues.if_name;
                        vlanInterface.vlan_id = updatedValues.vlan_id;
                        vlanInterface.vrf_ref = updatedValues.vrf_ref || '';
                        vlanInterface.vnic_networks = updatedValues.vnic_networks;
                        vlanInterface.vipVnicNetworks = updatedValues.vipVnicNetworks || '';
                        vlanInterface.vnic_networks.forEach((vnicNetwork: IVnicNetworksData) => {
                            const ipInfo: string[] =
                                ServiceEngineItem.getIpAddressAndSubnetFromMaskedIp(
                                    vnicNetwork.maskedIp,
                                );

                            vnicNetwork.ip.ip_addr.addr = ipInfo[0] || '';
                            vnicNetwork.ip.mask = Number(ipInfo[1]) || 0;
                        });
                    }
                },
            );
        } else {
            this.config.vlan_interfaces.push(updatedValues);
        }
    }

    /**
     * Save ServiceEngine internal data.
     * @override
     */
    public dataToSave(): IServiceEngineInternal {
        const config = super.dataToSave();

        const mgmtVnic: IVnicInfo | undefined = config.mgmt_vnic;

        if (mgmtVnic) {
            config.mgmt_vnic.vlan_interfaces = this.getVlanInterfaceDataByIfName(mgmtVnic.if_name);
            delete mgmtVnic.state;
            delete mgmtVnic.mgmtType;
        }

        config?.data_vnics?.forEach((dataVnic: IVnicInfo) => {
            dataVnic.vlan_interfaces = this.getVlanInterfaceDataByIfName(dataVnic.if_name);
        });

        delete config.networks_vnics;
        delete config.vlan_interfaces;

        return config;
    }

    /**
     * Return vlan interface data filtered by if_name.
     */
    public getVlanInterfaceDataByIfName(ifName: string): IVlanInterfaceData [] {
        const { vlan_interfaces: vlanInterfaces } = this.config;

        const vlanInterfacesData =
            vlanInterfaces?.filter((vlanInterface: IVlanInterfaceData) => {
                return vlanInterface.if_name.lastIndexOf(ifName) > -1;
            });

        return vlanInterfacesData;
    }
    /**
     * Check whether SE is in enabled (default) state. False when not ready.
     */
    public isEnabled(): boolean {
        const { enable_state: enableState } = this.config;

        return !enableState || enableState === ServiceEngineEnableState.SE_STATE_ENABLED;
    }

    /**
     * Return combined value of management IP's available for inventory view of service engine.
     */
    public getManagementIpInventoryView(): string {
        const managementIps: string[] = [];

        if (this.config?.mgmt_ip_address?.addr) {
            managementIps.push(this.config?.mgmt_ip_address?.addr);
        }

        if (this.config?.mgmt_ip6_address?.addr) {
            managementIps.push(this.config?.mgmt_ip6_address?.addr);
        }

        return managementIps.join(', ');
    }

    /**
     * Return combined value of management IP's available for info view of service engine.
     */
    public getManagementIpInfoView(): string {
        const config = this.getConfig();

        if (config.mgmt_vnic) {
            const { vnic_networks: vnicNetworks } = config.mgmt_vnic.config;
            const mgmtIps = vnicNetworks.config.map(vnicNetwork => {
                return vnicNetwork.config.ip.config.ip_addr.address;
            });

            return mgmtIps.join(', ');
        }

        return '';
    }

    /**
     * Check whether SE is in disabled_for_placement state.
     */
    public isDisabledForPlacement(): boolean {
        const { enable_state: enableState } = this.config;

        return !enableState ||
            enableState === ServiceEngineEnableState.SE_STATE_DISABLED_FOR_PLACEMENT;
    }

    /**
     * Enable/deactivate service engine.
     */
    public setEnableState(enableState: number): IPromise<IHttpResponse<IServiceEngineInternal>> {
        const states = {
            1: ServiceEngineEnableState.SE_STATE_DISABLED_FOR_PLACEMENT,
            2: ServiceEngineEnableState.SE_STATE_DISABLED,
        };

        return this.changeEnableState(states[enableState] ||
            ServiceEngineEnableState.SE_STATE_ENABLED);
    }

    /**
     * Return the list of vnics for the SE.
     */
    public getVnicInterfaceList(): VNICConfigItem[] {
        const config = this.getConfig();
        const result: VNICConfigItem[] = [];

        if (config?.data_vnics.count) {
            config.data_vnics.config.forEach(vnic => {
                if (ServiceEngineItem.isInterfaceInUse(vnic)) {
                    result.push(vnic);
                }
            });

            if (config.inband_mgmt && ServiceEngineItem.isInterfaceInUse(config.mgmt_vnic)) {
                result.push(config.mgmt_vnic);
            }
        }

        return result;
    }

    /**
     * Return the list of VLAN interfaces for the SE.
     */
    public getVlanInterfaceList(): Array<MessageItem<IVlanInterfaceData>> {
        const config = this.getConfig();
        const result: Array<MessageItem<IVlanInterfaceData>> = [];

        if (config?.data_vnics.count) {
            config.data_vnics.config.forEach(vnic => {
                if (vnic.config.vlan_interfaces.count) {
                    result.push(...vnic.config.vlan_interfaces.config);
                }
            });
        }

        return result;
    }

    /**
     * Get cloud ID.
     */
    public getCloudId(): string {
        return this.config?.cloud_ref ? this.stringService.slug(this.config.cloud_ref) : '';
    }

    /**
     * Return details page state parameters.
     * @override
     */
    public getDetailsPageStateParams(): IDetailsPageParams {
        return {
            seId: this.id,
            cloudId: this.getCloudId(),
        };
    }

    /**
     * Send HTTP request to reboot Service Engine.
     */
    public reboot(): IPromise<IHttpResponse<void>> {
        return this.request('post', `/api/serviceengine/${this.id}/reboot`);
    }

    /**
     * Return se group configuration.
     */
    public getSEGroupConfig(): IServiceEngineGroup | null {
        const config = this.getConfig();

        return config?.se_group_ref_data || null;
    }

    /**
     * Get SE's force delete URL.
     */
    public getUrlToDelete(force: boolean): string {
        const url = super.getUrlToDelete_(false);

        return force ? `${url}/forcedelete` : url;
    }

    /**
     * Return true when real time metrics are switched on.
     */
    public hasRealTimeMetrics(): boolean {
        const seGroupConfig: IServiceEngineGroup = this.getSEGroupConfig();

        return seGroupConfig && 'realtime_se_metrics' in seGroupConfig &&
            seGroupConfig.realtime_se_metrics.enabled || false;
    }

    /** @override */
    public hasCustomTimeFrameSettings(): boolean {
        return !this.seCollection && this.hasRealTimeMetrics();
    }

    /**
     * Return custom time frame settings.
     * @override
     */
    public getCustomTimeFrameSettings(tfLabel: string): { step: number, limit: number } | null {
        if (this.hasCustomTimeFrameSettings() && tfLabel === 'rt') {
            return {
                step: 5,
                limit: 360,
            };
        }

        return null;
    }

    /**
     * Update data network.
     * Open the child model to update the data network.
     */
    public editDataNetworkPage(
        vnicConfigItem: VNICConfigItem,
        vrfContextCollection: VRFContextCollection,
        cloudType: string,
    ): void {
        const { data_vnics: dataVnics } = this.config;
        const index = dataVnics.config.indexOf(vnicConfigItem);

        this.editChildMessageItem({
            field: DATA_NETWORK_FIELD,
            messageItem: dataVnics.at(index),
            modalBindings: {
                editMode: true,
                vrfContextCollection,
                cloudType,
                vnicsInfo: dataVnics,
                currentIndex: index,
            },
        });
    }

    /**
     * Update data network.
     * Open the child model to update the management network.
     */
    public editManagementNetwork(
        vrfContextCollection: VRFContextCollection,
        cloudType: string,
    ): void {
        this.editChildMessageItem({
            field: MANAGEMENT_NETWORK_FIELD,
            messageItem: this.config.mgmt_vnic,
            modalBindings: {
                editMode: true,
                vrfContextCollection,
                cloudType,
                vnicsInfo: [this.config.mgmt_vnic],
                currentIndex: 0,
            },
        });
    }

    /**
     * Update data network.
     * Open the child model to update the networks vnics.
     */
    public editNetworksVnics(
        vnicConfigItem: VNICConfigItem,
        vrfContextCollection: VRFContextCollection,
        cloudType: string,
    ): void {
        const currentIndex = this.getIndexOfNetworksConfigItemByIfName(
            vnicConfigItem.config.if_name,
        );

        this.editChildMessageItem({
            field: vnicConfigItem.config.is_mgmt ? MANAGEMENT_NETWORK_FIELD : DATA_NETWORK_FIELD,
            messageItem: this.config.networks_vnics[currentIndex],
            modalBindings: {
                editMode: true,
                vrfContextCollection,
                cloudType,
                vnicsInfo: this.config.networks_vnics,
                currentIndex,
                isNetworksData: true,
            },
        });
    }

    /**
     * Get index of configitem from networks vnics using if_name
     */
    public getIndexOfNetworksConfigItemByIfName(ifName: string): number {
        const index = this.config.networks_vnics.findIndex((configItem: VNICConfigItem) => {
            return configItem.config.if_name === ifName;
        });

        return index;
    }

    /**
     * Create parent interface dropdown data.
     * This will pluck all if_name from VlanInterfaceConfigItem[].
     */
    // eslint-disable-next-line class-methods-use-this
    public getParentInterfaceData(
        vNICConfigItemList: VNICConfigItem[],
    ): IAviDropdownOption[] {
        const parentInterfaceDropdownOptions: IAviDropdownOption[] = [];

        vNICConfigItemList.forEach((vNICConfigItem: VNICConfigItem) => {
            const ifName: string = vNICConfigItem.config.if_name;

            parentInterfaceDropdownOptions.push(createDropdownOption(ifName, ifName, ifName));
        });

        return sortBy(parentInterfaceDropdownOptions, 'value');
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.serviceEngineLabel);
    }

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     */
    protected loadRequest(fields : string[]): IPromise<any> {
        const requests = [
            this.loadConfig(fields),
            this.loadEventsAndAlerts(fields),
            this.loadMetrics(fields, undefined, undefined),
        ];

        return Promise.all(requests);
    }

    /**
     * Make actual config modification and call save when needed.
     */
    private changeEnableState(enableState: ServiceEngineEnableState):
    IPromise<IHttpResponse<IServiceEngine>> {
        return this.patch({
            replace: { enable_state: enableState },
        });
    }
}

export const ServiceEngineMixin = withUpgradeStatusMixin(ServiceEngineItem);
export type ITServiceEngine = InstanceType<typeof ServiceEngineMixin>;
