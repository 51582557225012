/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { FailActionHTTPLocalResponse } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { FailHttpStatusCode, IFailActionHTTPLocalResponse } from 'generated-types';

/**
 * @description
 *
 *   IFailActionHTTPLocalResponse config item.
 *
 * @author Rachit Aggarwal
 */
export class FailActionHttpLocalResponseConfigItem
    extends MessageItem<IFailActionHTTPLocalResponse> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: FailActionHTTPLocalResponse,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns status code from config.
     */
    public get statusCode(): FailHttpStatusCode {
        return this.config.status_code;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'file',
        ];
    }
}
