<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalHeaderEdit: l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.config.domain_name }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="gslb-subdomain-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <!-- General -->
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.DNSConfig }}"
                        fieldName="domain_name"
                        for="domain-name"
                        required
                    >
                        {{ l10nKeys.subdomainLabel | vtranslate }}
                    </label>
                    <input
                        name="domain-name"
                        id="domain-name"
                        clrInput
                        placeholder="{{ l10nKeys.subdomainPlaceholder | vtranslate }}"
                        required
                        regexPattern="hostname"
                        [disabled]="editMode && !hostOnSitesWithAllSubdomains"
                        [(ngModel)]="editable.config.domain_name"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>

        <!-- Placement -->
        <full-modal-tab-section tabTitle="{{ l10nKeys.placementSectionTitle | vtranslate }}">
            <div class="full-modal-config__section">
                <h3 class="gslb-subdomain-modal__section-header">
                    {{ l10nKeys.placementSectionTitle | vtranslate }}
                </h3>

                <avi-fieldset>
                    <avi-fieldset_header>
                        <avi-checkbox
                            name="host-on-sites-with-all-Subdomains"
                            [(ngModel)]="hostOnSitesWithAllSubdomains"
                            [noMarginTop]="true"
                        >
                            {{ l10nKeys.hostOnSiteWithAllSubdomainsLabel | vtranslate }}
                        </avi-checkbox>
                    </avi-fieldset_header>
                    <avi-fieldset_content>
                        <div class="gslb-subdomain-modal__grid-container">
                            <gslb-placement-grid
                                [gslbSites]="hostOnSitesWithAllSubdomains ? gslbSitesWithAllSubdomains : selectedGslbSites"
                                [isAllSubdomainsEnabled]="hostOnSitesWithAllSubdomains"
                                (onAddSite)="addSiteToDomain()"
                                (onDeleteSites)="removeSites($event)"
                                (onEditSite)="editSite($event)"
                            ></gslb-placement-grid>
                        </div>
                    </avi-fieldset_content>
                </avi-fieldset>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
