/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const sslProfileModalBreadcrumbTitle = `${prefix}.sslProfileModalBreadcrumbTitle`;
export const sslTlsCertificateModalBreadcrumbTitle = `${prefix}.sslTlsCertificateModalBreadcrumbTitle`;
export const incompleteLabel = `${prefix}.incompleteLabel`;
export const selfSignedLabel = `${prefix}.selfSignedLabel`;

export const ENGLISH = {
    [sslProfileModalBreadcrumbTitle]: 'SSL/TLS Profile',
    [sslTlsCertificateModalBreadcrumbTitle]: 'Certificate (SSL/TLS)',
    [incompleteLabel]: 'Incomplete',
    [selfSignedLabel]: 'Self-signed',
};
