/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { WebhookItem } from './webhook.item.factory';

/**
 * @description Webhook Collection.
 *
 * @author Nitesh Kesarkar, alextsg
 */
export class WebhookCollection extends Collection {
    protected static ngDependencies = [
        WebhookItem,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'webhook',
            permissionName: AviPermissionResource.PERMISSION_POOL,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(WebhookItem);
    }
}
