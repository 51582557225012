/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-dns-response';
const prefix = `${moduleName}.${componentName}`;

export const recordSourceLabel = `${prefix}.recordSourceLabel`;
export const resourceRecordLabel = `${prefix}.resourceRecordLabel`;
export const operationCodeLabel = `${prefix}.operationCodeLabel`;
export const opCodeLabel = `${prefix}.opCodeLabel`;
export const truncatedLabel = `${prefix}.truncatedLabel`;
export const questionCountLabel = `${prefix}.questionCountLabel`;
export const answerRecordCountLabel = `${prefix}.answerRecordCountLabel`;
export const nameServerRecordCountLabel = `${prefix}.nameServerRecordCountLabel`;
export const additionalRecordCountLabel = `${prefix}.additionalRecordCountLabel`;
export const authoritativeLabel = `${prefix}.authoritativeLabel`;
export const recursionAvailableLabel = `${prefix}.recursionAvailableLabel`;
export const recursionDesiredLabel = `${prefix}.recursionDesiredLabel`;
export const wildcardLabel = `${prefix}.wildcardLabel`;
export const fallbackAlgorithmUsedLabel = `${prefix}.fallbackAlgorithmUsedLabel`;
export const resourceRecordsLabel = `${prefix}.resourceRecordsLabel`;
export const domainNameLabel = `${prefix}.domainNameLabel`;
export const resolvedVirtualServiceNameLabel = `${prefix}.resolvedVirtualServiceNameLabel`;
export const memberLocationLabel = `${prefix}.memberLocationLabel`;
export const ttlLabel = `${prefix}.ttlLabel`;
export const nsNameLabel = `${prefix}.nsNameLabel`;
export const cnameLabel = `${prefix}.cnameLabel`;
export const mailServerLabel = `${prefix}.mailServerLabel`;
export const textResourceDataLabel = `${prefix}.textResourceDataLabel`;

export const ENGLISH = {
    [recordSourceLabel]: 'Record Source',
    [resourceRecordLabel]: 'Resource Record',
    [operationCodeLabel]: 'Operation Code ({0})',
    [opCodeLabel]: 'OP Code',
    [truncatedLabel]: 'Truncated',
    [questionCountLabel]: 'Question Count',
    [answerRecordCountLabel]: 'AnswerRecord Count',
    [nameServerRecordCountLabel]: 'Name Server Record Count',
    [additionalRecordCountLabel]: 'Additional Record Count',
    [authoritativeLabel]: 'Authoritative',
    [recursionAvailableLabel]: 'Recursion Available',
    [recursionDesiredLabel]: 'Recursion Desired',
    [wildcardLabel]: 'Wildcard',
    [fallbackAlgorithmUsedLabel]: 'Fallback Algorithm Used',
    [resourceRecordsLabel]: 'Resource Records ({0})',
    [domainNameLabel]: 'Domain Name',
    [resolvedVirtualServiceNameLabel]: 'Resolved Virtual Service Name',
    [memberLocationLabel]: 'Member Location',
    [ttlLabel]: 'TTL',
    [nsNameLabel]: 'NS Name',
    [cnameLabel]: 'CNAME',
    [mailServerLabel]: 'Mail Server',
    [textResourceDataLabel]: 'Text Resource Data',
};
