/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviBackendErrorMsgPipe } from 'nsx-alb-ui-components';

angular.module('avi/app').filter(
    'aviBackendErrorMsg', () => AviBackendErrorMsgPipe.aviBackendErrorMsg,
);
