/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-mapping-element-grid';
const prefix = `${moduleName}.${componentName}`;

export const geoDbMappingElementAddButtonLabel = `${prefix}.geoDbMappingElementAddButtonLabel`;
export const geoDbMappingElementColTitleAttribute = `${prefix}.geoDbMappingElementColTitleAttribute`;
export const geoDbMappingElementColTitleValues = `${prefix}.geoDbMappingElementColTitleValues`;
export const geoDbMappingElementRemoveButtonLabel = `${prefix}.geoDbMappingElementRemoveButtonLabel`;
export const geoDbMappingElementEditButtonLabel = `${prefix}.geoDbMappingElementEditButtonLabel`;

export const ENGLISH = {
    [geoDbMappingElementAddButtonLabel]: 'Add',
    [geoDbMappingElementColTitleAttribute]: 'Attribute',
    [geoDbMappingElementColTitleValues]: 'Mapped Values',
    [geoDbMappingElementRemoveButtonLabel]: 'Remove',
    [geoDbMappingElementEditButtonLabel]: 'Edit',
};
