/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'seg-image-selection-modal';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleVersion = `${prefix}.columnTitleVersion`;
export const columnTitleAttributes = `${prefix}.columnTitleAttributes`;
export const compatibleUpdateModalTitle = `${prefix}.compatibleUpdateModalTitle`;
export const nextBtnLabel = `${prefix}.nextBtnLabel`;
export const emptyCompatibleImageLabel = `${prefix}.emptyCompatibleImageLabel`;
export const currentVersionLabel = `${prefix}.currentVersionLabel`;
export const continueLabel = `${prefix}.continueLabel`;
export const availableUpdatesHeader = `${prefix}.availableUpdatesHeader`;

export const ENGLISH = {
    [columnTitleType]: 'Type',
    [columnTitleVersion]: 'Version',
    [columnTitleAttributes]: 'Attributes',
    [compatibleUpdateModalTitle]: 'Compatible Updates',
    [nextBtnLabel]: 'Next',
    [emptyCompatibleImageLabel]: 'No compatible image found for the selected SEGs.',
    [currentVersionLabel]: '{0} (current)',
    [continueLabel]: 'Continue',
    [availableUpdatesHeader]: 'Available Updates',
};
