<div class="vs-log-cinematic-section-datascript__datascript-log-section">
    <div
        vsLogCinematicFieldTitle
        [withMarginTop]="false"
    >
        {{ l10nKeys.datascriptLogLabel | vtranslate }}
    </div>
    <span
        *ngIf="(datascriptInfo$ | async).datascript_log as datascriptLog; else na"
        vsLogPartialSelectionFilter
        property="datascript_log"
        [value]="datascriptLog"
        position="right"
        (onFilterAdd)="closeCinematicView()"
    ></span>
</div>

<avi-diff-grid
    [config]="datascriptDiffGridConfig"
    [rows]="diffGridRows"
></avi-diff-grid>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
