<div
    class="vs-log-cinematic-end-to-end-bridge"
    [ngClass]="{ 'vs-log-cinematic-end-to-end-bridge--no-arrow': noArrow }"
>
    <!-- over arrow part -->
    <div
        *ngIf="!isHeaderClickable; else headerClickable"
        class="vs-log-cinematic-end-to-end-bridge__title"
        [ngClass]="'vs-log-cinematic-end-to-end-bridge__title--' + topHeaderColor"
    >
        {{ topHeader }}
    </div>
    <div class="vs-log-cinematic-end-to-end-bridge__time">
        <ng-container *ngIf="endToEndTime !== undefined; else na">
            <span
                *ngIf="isTimeUnitFilterable; else timeUnitNotFilterable"
                vsLogFilterList
                [property]="endToEndTimeFilterProp"
                [value]="endToEndTime"
                displayValue="{{ displayValue }} {{ globalL10nKeys.msLabel | vtranslate }}"
                (onFilterAdd)="onFilterAdd.emit()"
            >
                <ng-container *ngTemplateOutlet="timeUnit"></ng-container>
            </span>
            <span class="vs-log-cinematic-end-to-end-bridge__time-unit">
                {{ globalL10nKeys.msLabel | vtranslate }}
            </span>
        </ng-container>
        <ng-template #na>
            <div vsLogEmptyField></div>
        </ng-template>
    </div>

    <!-- arrow -->
    <double-arrow
        *ngIf="!noArrow"
        class="vs-log-cinematic-end-to-end-bridge__arrow"
        [color]="arrowColor"
    ></double-arrow>

    <!-- under arrow part -->
    <ng-container *ngIf="!noStatusCode">
        <div class="vs-log-cinematic-end-to-end-bridge__title">
            {{ globalL10nKeys.responseCodeLabel | vtranslate }}
        </div>
        <div class="vs-log-cinematic-end-to-end-bridge__status-code">
            <span
                *ngIf="statusCode !== undefined; else emDash"
                vsLogFilter
                [property]="statusCodeFilterProp"
                [value]="statusCode"
                (onFilterAdd)="onFilterAdd.emit()"
            >
                {{ statusCode }}
            </span>
            <ng-template #emDash>
                <div
                    vsLogEmptyField
                    [useDash]="true"
                ></div>
            </ng-template>
        </div>
    </ng-container>
</div>

<ng-template #headerClickable>
    <div
        class="vs-log-cinematic-end-to-end-bridge__title vs-log-cinematic-end-to-end-bridge__title-clickable"
        [ngClass]="['vs-log-cinematic-end-to-end-bridge__title--' + topHeaderColor, isActive ? 'vs-log-cinematic-end-to-end-bridge__title--active': '']"
        (click)="onHeaderClick.emit()"
    >
        {{ topHeader }}
    </div>
</ng-template>

<ng-template #timeUnit>
    <ng-container *ngIf="isWafTiming || endToEndTime >= endToEndTimeLowerLimit; else lessThanOne">
        {{ displayValue }}
    </ng-container>
    <ng-template #lessThanOne>
        &lt; {{ endToEndTimeLowerLimit }}
    </ng-template>
</ng-template>

<ng-template #timeUnitNotFilterable>
    <span>
        <ng-container *ngTemplateOutlet="timeUnit"></ng-container>
    </span>
</ng-template>
