<div
    provideParentForm
    class="system-settings-snmp-config"
>
    <h6 class="full-modal__section-header">
        {{ l10nKeys.snmpLabel | vtranslate }}
    </h6>
    <avi-fieldset [hideContent]="snmpVersion.isNone">
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <avi-radio
                    name="snmp-none"
                    [ngModel]="snmpConfig.version"
                    (ngModelChange)="onSnmpVersionTypeChange($event)"
                >
                    {{ globalL10nKeys.emptyLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="snmp-v2"
                    [value]="snmpVersionEnumValues.SNMP_VER2"
                    [ngModel]="snmpConfig.version"
                    (ngModelChange)="onSnmpVersionTypeChange($event)"
                    [enumType]="enumType"
                    enumValue="SNMP_VER2"
                >
                    {{ l10nKeys.snmpV2RadioLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="snmp-v3"
                    [value]="snmpVersionEnumValues.SNMP_VER3"
                    [ngModel]="snmpConfig.version"
                    (ngModelChange)="onSnmpVersionTypeChange($event)"
                    [enumType]="enumType"
                    enumValue="SNMP_VER3"
                >
                    {{ l10nKeys.snmpV3RadioLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>

        <avi-fieldset_content>
            <ng-container *ngIf="snmpVersion.isV2">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectTypes.SnmpConfiguration"
                        fieldName="community"
                        for="snmp-community"
                        required
                    >
                        {{ l10nKeys.snmpCommunityInputLabel | vtranslate }}
                    </label>
                    <input
                        type="password"
                        name="snmp-community"
                        id="snmp-community"
                        [(ngModel)]="snmpConfig.community"
                        [placeholder]="l10nKeys.snmpCommunityInputPlaceholder | vtranslate"
                        clrInput
                        required
                    />
                </avi-input-container>
            </ng-container>

            <ng-container *ngIf="snmpVersion.isV3">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectTypes.SnmpV3Configuration"
                        fieldName="engine_id"
                        for="engine-id"
                        required
                    >
                        {{ l10nKeys.engineIdInputLabel | vtranslate }}
                    </label>
                    <input
                        name="engine-id"
                        id="engine-id"
                        [(ngModel)]="snmpConfig.snmp_v3_config.engine_id"
                        [placeholder]="l10nKeys.engineIdInputPlaceholder | vtranslate"
                        clrInput
                        required
                    />
                </avi-input-container>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectTypes.SnmpV3UserParams"
                        fieldName="username"
                        for="username"
                        required
                    >
                        {{ globalL10nKeys.usernameLabel | vtranslate }}
                    </label>
                    <input
                        name="username"
                        id="username"
                        [(ngModel)]="snmpConfig.snmp_v3_config.user.username"
                        [placeholder]="globalL10nKeys.usernamePlaceholder | vtranslate"
                        clrInput
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectTypes.SnmpV3UserParams"
                            fieldName="auth_type"
                            for="auth-type"
                        >
                            {{ l10nKeys.authTypeDropdownLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="snmp-v3-auth-type"
                            id="auth-type"
                            enum="SNMP_V3_AUTH"
                            [(ngModel)]="snmpConfig.snmp_v3_config.user.auth_type"
                            [placeholder]="l10nKeys.authTypeDropdownPlaceholder | vtranslate"
                        ></avi-enum-dropdown>
                    </div>

                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                [objectType]="objectTypes.SnmpV3UserParams"
                                fieldName="auth_passphrase"
                                for="auth-passphrase"
                                required
                            >
                                {{ l10nKeys.authPassphraseInputLabel | vtranslate }}
                            </label>
                            <input
                                name="auth-passphrase"
                                id="auth-passphrase"
                                type="password"
                                [(ngModel)]="snmpConfig.snmp_v3_config.user.auth_passphrase"
                                [placeholder]="l10nKeys.authPassphraseInputPlaceholder | vtranslate"
                                clrInput
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectTypes.SnmpV3UserParams"
                            fieldName="priv_type"
                            for="privacy-type"
                        >
                            {{ l10nKeys.privacySettingsDropdownLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="privacy-type"
                            id="privacy-type"
                            enum="SNMP_V3_PRIV"
                            [(ngModel)]="snmpConfig.snmp_v3_config.user.priv_type"
                            [placeholder]="l10nKeys.privacySettingsDropdownPlaceholder | vtranslate"
                        ></avi-enum-dropdown>
                    </div>

                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                [objectType]="objectTypes.SnmpV3UserParams"
                                fieldName="priv_passphrase"
                                for="privacy-passphrase"
                                required
                            >
                                {{ l10nKeys.privacyPassphraseInputLabel | vtranslate }}
                            </label>
                            <input
                                name="privacy-passphrase"
                                id="privacy-passphrase"
                                type="password"
                                [(ngModel)]="snmpConfig.snmp_v3_config.user.priv_passphrase"
                                [placeholder]="l10nKeys.privacyPassphraseInputPlaceholder | vtranslate"
                                clrInput
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
            </ng-container>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
