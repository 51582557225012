<div class="clr-wrapper avi-toast">
    <clr-alert
        [clrAlertClosable]="showClose"
        [clrAlertType]="alertType"
        (clrAlertClosedChange)="handleClose()"
    >
        <clr-alert-item>
            <div class="avi-toast__text-container">
                <span class="alert-text">
                    <h6
                        *ngIf="!!header"
                        class="avi-toast__header"
                    >
                        {{ header }}
                    </h6>
                    <div class="avi-toast__content">
                        <ng-container *ngIf="!content">
                            <ng-content></ng-content>
                        </ng-container>

                        {{ content }}
                    </div>
                </span>
                <div
                    *ngIf="showActions"
                    class="avi-toast__action-container"
                >
                    <div class="alert-actions">
                        <a
                            *ngFor="let action of actions; trackBy: trackbyIndex"
                            class="alert-action"
                            (click)="action.onClick()"
                        >
                            {{ action.label }}
                        </a>
                    </div>
                </div>
            </div>
        </clr-alert-item>
    </clr-alert>
</div>

