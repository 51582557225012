/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { IGslbSiteDnsVs } from 'generated-types';
import { GslbSiteDnsVs } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    MessageItem,
} from 'ajs/modules/data-model/factories';

import {
    GslbSiteDnsVsModalComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-dns-virtual-services-grid/gslb-dns-virtual-service-modal/gslb-dns-virtual-service-modal.component';

interface IGslbSiteDnsVsConfig extends IGslbSiteDnsVs {
    /**
     * UI only feild.
     * Dns Vs ref to be used in the dns vs collection dropdown.
     * This is set in the component based on the dns_vs_uuid field once the collection is loaded.
     */
    dnsVsRef: string;
}

/**
 * @description Config item for dns vs of gslb avi sites.
 * @author Hitesh Mandav
 */

export class GslbSiteDnsVsConfigItem extends withFullModalMixin(MessageItem)<IGslbSiteDnsVsConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GslbSiteDnsVs,
            windowElement: GslbSiteDnsVsModalComponent,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Delete domain names list.
     */
    public deleteAllSubdomains(): void {
        delete this.config.domain_names;
    }

    /**
     * Remove subdomain from list of domain_names of current DnsVs.
     */
    public removeSubdomain(subdomain: string): void {
        if (this.config.domain_names?.length) {
            const index = this.config.domain_names.indexOf(subdomain);

            if (index > -1) {
                this.config.domain_names.splice(index, 1);
            }
        }
    }

    /**
     * Add subdomain to DnsVs.
     */
    public addSubdomain(subdomain: string): void {
        if (!this.config.domain_names) {
            this.config.domain_names = [];
        }

        this.config.domain_names.push(subdomain);
    }

    /**
     * @override
     * Set domain_names from domainNamesWrapper.
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        delete this.config.dnsVsRef;
    }
}
