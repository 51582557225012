/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    GeoLocation,
    GslbGeoLocation,
} from 'object-types';

import {
    GslbGeoDbProfileCollection,
    TGslbGeoDbProfileCollection,
} from 'ajs/modules/gslb/factories/gslb-geodb-profile/gslb-geodb-profile.collection.factory';

import {
    GslbGeoLocationConfigItem,
} from 'message-items/gslb-geo-location.config-item.factory';
import { GslbGeoLocationSource } from 'generated-types';
import './gslb-service-geo-location.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-geo-location.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description GSLB service geo location component.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-geo-location',
    templateUrl: './gslb-service-geo-location.component.html',
})
export class GslbServiceGeoLocationComponent implements OnInit, OnDestroy {
    @Input()
    public gslbGeoLocation: GslbGeoLocationConfigItem;

    /**
     * Flag indicating, is it invoked by pool member.
     */
    @Input()
    private isPoolMember = false;

    /**
     * Hidden enum values of geo location source.
     */
    public enumHiddenGeoLocationOptions = [
        GslbGeoLocationSource.GSLB_LOCATION_SRC_FROM_GEODB,
        GslbGeoLocationSource.GSLB_LOCATION_SRC_INHERIT_FROM_SITE,
    ];

    /**
     * Flag indicating, if geo location source is user configured.
     */
    public isGeoLocationSourceUserConfigured = false;

    public readonly objectType = {
        GslbGeoLocation,
        GeoLocation,
    };

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * GslbGeoDbProfileCollection instance. Used for determining geo location source.
     */
    private gslbGeoDbProfileCollection: GslbGeoDbProfileCollection;

    constructor(
        l10nService: L10nService,
        @Inject(GslbGeoDbProfileCollection)
        GslbGeoDbProfileCollection: TGslbGeoDbProfileCollection,
    ) {
        this.gslbGeoDbProfileCollection = new GslbGeoDbProfileCollection({ limit: 1 });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        if (this.isPoolMember) {
            const index = this.enumHiddenGeoLocationOptions.indexOf(
                GslbGeoLocationSource.GSLB_LOCATION_SRC_INHERIT_FROM_SITE,
            );

            this.enumHiddenGeoLocationOptions.splice(index, 1);

            await this.gslbGeoDbProfileCollection.load();

            const gslbGeoDbProfileItemsCount =
                this.gslbGeoDbProfileCollection.getTotalNumberOfItems();

            if (gslbGeoDbProfileItemsCount) {
                this.enumHiddenGeoLocationOptions = [];
            }
        }
    }

    /**
     * Handle geo location source change.
     */
    public handleGeoLocationSourceChange(): void {
        const { source } = this.gslbGeoLocation.config;

        this.isGeoLocationSourceUserConfigured =
            source === GslbGeoLocationSource.GSLB_LOCATION_SRC_USER_CONFIGURED;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.gslbGeoDbProfileCollection.destroy();
    }
}
