<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-fieldset [hideContent]="!(editable.config.cluster_uuid && selectedSiteVsCollection)">
                <avi-fieldset_header class="gslb-pool-member-virtual-service__avi-fieldset-header">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="cluster_uuid"
                            for="cluster-uuid"
                            required
                        >
                            {{ l10nKeys.siteClusterControllerDropdownLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="cluster-uuid"
                            id="cluster-uuid"
                            [options]="siteClusterControllerDropdownOptions"
                            [(ngModel)]="editable.config.cluster_uuid"
                            (ngModelChange)="onSiteClusterControllerChange.emit()"
                            placeholder="{{ l10nKeys.siteClusterControllerDropdownPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </avi-input-container>
                </avi-fieldset_header>
                <avi-fieldset_content>
                    <avi-input-container
                        noMarginTop
                        *ngIf= "editable.config.cluster_uuid && selectedSiteVsCollection"
                    >
                        <label
                            aviLabelWithTooltip
                            fieldName="vs_uuid"
                            for="vs-uuid"
                            objectType="{{ objectType }}"
                            required
                        >
                            {{ l10nKeys.virtualServiceDropdownLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="selectedSiteVsCollection"
                            [(ngModel)]="editable.config.vs_uuid"
                            (ngModelChange)="onVsSelectionChange.emit()"
                            placeholder="{{ l10nKeys.virtualServiceDropdownPlaceholder | vtranslate }}"
                            [preventLoad]="true"
                            name="vs-uuid"
                            required
                        ></avi-collection-dropdown>
                    </avi-input-container>

                    <avi-input-container *ngIf= "resolvedVsVipIpDropdownOptions.length > 1">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ip"
                            for="ip"
                        >
                            {{ l10nKeys.resolvedVsVipIPLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="ip"
                            id="ip"
                            [options]="resolvedVsVipIpDropdownOptions"
                            [(ngModel)]="editable.config.ip.address"
                            (ngModelChange)="onResolvedVsVipIpChange.emit()"
                            placeholder="{{ l10nKeys.resolvedAddressDropdownPlaceholder | vtranslate }}"
                        ></avi-dropdown>
                    </avi-input-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
