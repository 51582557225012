/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CloudModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & components.
 *
 * @author Sarthak Kapoor
 */

/**
 * Dependency token for Cloud Connector User Collection.
 */
export const CLOUD_CONNECTOR_USER_COLLECTION_TOKEN = 'CloudConnectorUserCollection';
