/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IGslbHealthMonitorProxy } from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-health-monitor-proxy-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for health monitor proxy in gslb site modal.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-health-monitor-proxy-grid',
    templateUrl: './gslb-health-monitor-proxy-grid.component.html',
})
export class GslbHealthMonitorProxyGridComponent implements AfterViewInit {
    /**
     * List of active gslb sites options available.
     */
    @Input()
    public activeGslbSiteOptions: IAviDropdownOption[];

    /**
     * List of health monitor proxies of gslb site.
     */
    @Input()
    public healthMonitorProxies: RepeatedMessageItem<MessageItem<IGslbHealthMonitorProxy>>;

    /**
     * TemplateRef for controller input dropdown.
     */
    @ViewChild('controllerTemplateRef')
    public controllerTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Grid config for health monitor proxies.
     */
    public hmProxyGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @Override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.hmProxyGridConfig = {
            fields: [{
                label: l10nService.getMessage(globalL10nKeys.controllerLabel),
                id: 'controller',
                templateRef: this.controllerTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (hmProxyList: Array<MessageItem<IGslbHealthMonitorProxy>>) => {
                    hmProxyList.forEach(hmProxy => this.deleteHmProxy(hmProxy));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (hmProxy: MessageItem<IGslbHealthMonitorProxy>) => {
                    this.deleteHmProxy(hmProxy);
                },
            }],
        };
    }

    /**
     * Add health monitor proxy.
     */
    public addhmProxy(): void {
        this.healthMonitorProxies.add();
    }

    /**
     * Delete health monitor proxy.
     */
    public deleteHmProxy(hmProxy: MessageItem<IGslbHealthMonitorProxy>): void {
        this.healthMonitorProxies.removeByMessageItem(hmProxy);
    }
}
