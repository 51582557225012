/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SharedModule */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IIpAddrPrefix } from 'generated-types';
import { getSubnetString } from 'ng/utils/ip-prefix-parser.utils';

/**
 * @description Pipe to convert subnet data into readable IP address.
 *
 * @author Vinay Jadhav
 */

@Pipe({
    name: 'subnetString',
})
export class SubnetStringPipe implements PipeTransform {
    /**
     * Transform IpAddrPrefix type object to readable IP-Subnet string.
     */
    public transform(subnetObj: IIpAddrPrefix | MessageItem<IIpAddrPrefix>): string {
        if (subnetObj instanceof IpAddrPrefixConfigItem) {
            const plainObject = subnetObj.flattenConfig();

            return getSubnetString(plainObject as IIpAddrPrefix);
        }

        return getSubnetString(subnetObj as IIpAddrPrefix);
    }
}
