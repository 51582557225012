/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function openstackNetworkDataTransformerFactory(
    ListDataTransformer,
) {
    /**
     * @class
     * @name OpenstackNetworkDataTransformer
     * @extends ListDataTransformer
     */
    class OpenstackNetworkDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            const { data } = resp;

            if (data && 'networks' in data && Array.isArray(data.networks)) {
                const { networks, region } = data.networks[0];

                networks.forEach(OpenstackNetworkDataTransformer.networkTransform);

                data.results = networks.map(row => ({ config: row }));

                data.count = networks.length;
                data.region = region;
                delete data.networks;
            }

            return resp;
        }

        /**
         * Sets url for Openstack network config being passed
         * @param {Object} network
         */
        static networkTransform(config) {
            config.url = `${config.id}#${config.name}`;
        }
    }

    return OpenstackNetworkDataTransformer;
}

openstackNetworkDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

/**
 * @ngdoc service
 * @alias openstackNetworkDataTransformer
 * @author Chitra
 * @description
 *
 *     Returning the list of networks.
 *     Used by {@link OpenstackNetworkCollection}.
 *
 */
angular.module('aviApp')
    .factory('OpenstackNetworkDataTransformer', openstackNetworkDataTransformerFactory);
