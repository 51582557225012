/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    bisector as d3Bisector,
} from 'd3';

const d3 = {
    bisector: d3Bisector,
};

/**
 * @ngdoc factory
 * @name ChartTooltip
 * @description
 *      Basic tooltip used as default tooltip in Chart class.
 */
angular.module('charts').factory('ChartTooltip', [
'chartUtils', '$sanitize', function(chartUtils, $sanitize) {
    /**
     * Single row value for tooltip table.
     * @typedef {Object} TooltipRow
     * @property {string} name
     * @property {number|string} value
     */

    class Tooltip {
        /**
         * Creates tooltip inner HTML template.
         * @param {TooltipRow[]} rows
         * @return {string}
         */
        static createHtml(rows) {
            let html = '';

            rows.forEach(value => {
                html += `
                    <div class="chart-tooltip-row">
                        <div class="chart-tooltip-cell name">
                            ${$sanitize(value.name)}
                        </div>
                        <div class="chart-tooltip-cell value"
                             style="color: ${value.color};">
                            ${$sanitize(value.value)}
                        </div>
                    </div>`;
            });

            return html;
        }

        /**
         * @param {boolean=} compact - Compact tooltip only displays Y-values.
         */
        constructor(compact = false) {
            this.element = angular.element('<div></div>');
            this.element.attr('class', 'chart-tooltip');

            if (compact) {
                this.element.addClass('compact');
            }

            /**
             * Array of items that will be displayed in tooltip as table.
             * @type {TooltipRow[]}
             */
            this.rowCache = [];

            /** @type {Function} */
            this.bisectXValue = d3.bisector(d => d[0]).left;

            /** @type {string[]} */
            this.colors = [];

            this.hide();
        }

        /**
         * Shows tooltip.
         */
        show() {
            this.element.removeClass('hidden');
        }

        /**
         * Hides tooltip.
         */
        hide() {
            this.element.addClass('hidden');
        }

        /**
         * Checks if current display values are the same as specified.
         * @param {TooltipRow[]} newValues
         * @return {boolean}
         */
        hasChanges(newValues) {
            if (newValues.length !== this.rowCache.length) {
                return false;
            } else {
                let v1;
                let v2;

                for (let i = 0; i < this.rowCache.length; i++) {
                    v1 = this.rowCache[i];
                    v2 = newValues[i];

                    if (v1.name !== v2.name || v1.value !== v2.value) {
                        return false;
                    }
                }
            }

            return true;
        }

        /**
         * Updates tooltip table.
         * @param {number} xValue
         * @param {ChartSeries[]} series0
         * @param {Array<ChartSeries>=} series1
         */
        update(xValue, series0, series1) {
            let series = [];

            if (Array.isArray(series0)) {
                series = series.concat(series0);
            }

            if (Array.isArray(series1)) {
                series = series.concat(series1);
            }

            const [s0] = series;
            const index = this.bisectXValue(s0.data, xValue);
            const rows = series.map((series, i) => {
                const d1 = series.data[index - 1];
                const d2 = series.data[index];
                const d = chartUtils.clampPoint(xValue, d1, d2);
                let value = d[1];

                if (angular.isNumber(value)) {
                    value = `${value >> 0}${series.unitsShort || ''}`;
                } else if (angular.isString(value) && !value.length || !value) {
                    value = 'No Data';
                }

                return {
                    name: series.name,
                    value,
                    color: this.colors[i],
                };
            });

            if (!this.hasChanges(rows)) {
                this.rowCache = rows;
                this.element.html(Tooltip.createHtml(rows));
            }
        }

        /**
         * Offsets tooltip based on values provided.
         * @param {number=} x - X offset in pixels.
         * @param {number=} y - Y offset in pixels.
         */
        position(x = 0, y = 0) {
            const padding = 7;
            const el = this.element.get(0);
            const height = el.offsetHeight;
            const width = el.offsetWidth;
            const parent = el.parentNode;
            const parentHeight = parent.offsetHeight;
            const parentWidth = parent.offsetWidth;

            if (x + width > parentWidth) {
                x -= width + padding;
            } else {
                x += padding;
            }

            y += parentHeight / 2 - height / 2;

            if (y < 0) {
                y = padding;
            } else if (y + height > parentHeight - padding) {
                y = parentHeight - padding;
            }

            this.element.css('transform', `translate(${x}px, ${y}px)`);
        }

        /**
         * Appends tooltip to provided element.
         * @param {Element} parent
         */
        render(parent) {
            parent.appendChild(this.element.get(0));
        }

        remove() {
            this.element.remove();
        }
    }

    return Tooltip;
}]);
