/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    AfterViewInit,
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import './selected-seg-list.component.less';

import * as l10n from './selected-seg-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying selected SEG list.
 * @author Nisar Nadaf
 */
@Component({
    selector: 'selected-seg-list',
    templateUrl: './selected-seg-list.component.html',
})
export class SelectedSegListComponent implements AfterViewInit {
    @Input()
    public segUpgradeNodes: ITSEGroup[];

    /**
     * Selected SEG list grid config.
     */
    public selectedSegGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.selectedSegGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleName),
                    id: 'name',
                    transform: (row: ITSEGroup) => row.getName(),
                }, {
                    label: l10nService.getMessage(l10nKeys.columnTitleVersion),
                    id: 'version',
                    transform: (row: ITSEGroup) => row.getVersion(),
                },
            ],
            layout: {
                hideCheckboxes: true,
                hidePagination: true,
                showFooter: false,
            },
        };
    }
}
