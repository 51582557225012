/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VrfContextModule
 */

export const BGP_PEER_CONFIG_ITEM_TOKEN = 'BgpPeerConfigItem';
export const BGP_PROFILE_CONFIG_ITEM_TOKEN = 'BgpProfileConfigItem';
export const BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN = 'BgpRoutingOptionsConfigItem';
export const VRF_CONTEXT_TOKEN = 'VRFContext';
export const VRF_CONTEXT_COLLECTION_TOKEN = 'VRFContextCollection';
export const INFRA_CLOUD_STATE_SERVICE_TOKEN = 'infraCloudState';
export const VRF_CONTEXT_COLL_DATASOURCE_TOKEN = 'VRFContextCollDataSource';
export const GATEWAY_MONITOR_CONFIG_ITEM_TOKEN = 'GatewayMonitorConfigItem';
