/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { MyAccountService } from 'ng/modules/core/services/my-account.service';

export const aviMediumDateFormat = 'MMM d, y h:mm a';
export const aviMediumDateFormatWithSec = 'MMM d, y h:mm:ss a';

/**
 * @description
 *     Pipe for transforming given date.
 *     Format can be passed as a parameter to specify how the resulted date should be presented.
 * @author Akul Aggarwal, Zhiqian Liu
 */
@Pipe({
    name: 'aviDate',
})
export class AviDatePipe extends DatePipe implements PipeTransform {
    constructor(private readonly myAccount: MyAccountService) {
        super('en-US');
    }

    public transform(
        input: Date | string | number,
        format?: string,
        timezone?: string,
        locale?: string
    ): string | null;

    public transform(
        input: null | undefined,
        format?: string,
        timezone?: string,
        locale?: string,
    ): null;

    public transform(
        input: string,
        format: string = aviMediumDateFormat,
    ): string {
        const timezone = this.myAccount.useUtcTime ? '+0000' : '';

        return super.transform(input, format, timezone);
    }
}
