<avi-data-grid
    [config]="dnsResolverGridConfig"
    [rows]="dnsResolvers.config"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addDnsResolver()"
            [disabled]="!dnsResolvers.isEmpty()"
        >
            {{ globalL10nKeys.addLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>
</avi-data-grid>
