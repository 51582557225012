/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './VsWafTopHitsDataTransformer.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc service
 * @name VsWafTopHitsDataTransformer
 * @author Alex Malitsky
 * @description
 *
 *     Instead of regular flat list of results transforms response into a hash by top hits list
 *     type. Each list entry is of {@link VsWafTopHitEntry} type.
 *
 *     Each group has a list of entries and a count (number of unique entries of the whole list,
 *     not just loaded part).
 *
 */
angular.module('aviApp').factory('VsWafTopHitsDataTransformer', [
'RevisedDataTransformer', 'VsWafTopHitsDataSource', 'l10nService',
function(RevisedDataTransformer, VsWafTopHitsDataSource, l10nService) {
    l10nService.registerSourceBundles(dictionary);

    class VsWafTopHitsDataTransformer extends RevisedDataTransformer {
        /**
         * Returns a hash of lists by groupby fieldName.
         * @param {Object[]} rsps
         * @param {Object} request
         * @returns {{string: Object[]}}
         */
        processResponse(rsps, request) {
            const groups = {};
            const allOtherValues = l10nService.getMessage(l10nKeys.allOtherValuesLabel);

            rsps.forEach(({ data }, index) => {
                const
                    { [index]: groupBy } = request['groupBy_'],
                    { count, percent_remaining: percentRemaining } = data;

                groups[groupBy] = {
                    count,
                    percentRemaining,
                };

                const { [groupBy]: listType } = VsWafTopHitsDataSource.propNameToFieldName;

                groups[groupBy].list = data.results.map(result => {
                    const
                        { count } = result,
                        filters = {};

                    let name;

                    // multiple filters ("combinations" type)
                    if (groupBy.indexOf(',') !== -1) {
                        const propertyNames = groupBy.split(',');

                        propertyNames.forEach(
                            propName => filters[propName] = result[propName],
                        );

                        name = propertyNames.map(fieldName => result[fieldName]).join(' & ');
                    } else if (result[groupBy]) {
                        name = result[groupBy];
                        filters[groupBy] = name;
                    } else {
                        name = allOtherValues;
                    }

                    return {
                        type: listType,
                        name,
                        filters,
                        count,
                        percentRemaining,
                    };
                });
            });

            return groups;
        }
    }

    return VsWafTopHitsDataTransformer;
}]);
