/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {Object} MetricDataCollection
 * @description  Collection of Metrics.
 */
angular.module('aviApp').factory('MetricDataCollection', [
'Collection', 'MetricData', (Collection, MetricData) => {
    class MetricDataCollection extends Collection {}

    angular.extend(MetricDataCollection.prototype, {
        itemClass_: MetricData,
        objectName_: 'metricdata',
        allDataSources_: {
            list: {
                source: 'MetricDataListCollDataSource',
                transformer: 'MetricDataListDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return MetricDataCollection;
}]);
