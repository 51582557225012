<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
    class="gslb-geodb-profile"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        [objectType]="objectType"
                        fieldName="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        class="sel-name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                        regexPattern="objName"
                        [(ngModel)]="editable.config.name"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleEntries | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleEntries | vtranslate }}</h3>
                <avi-alert-group
                    *ngIf="editable.geoDbEntriesCount === 0"
                    alertType="warning"
                >
                    <span>{{ l10nKeys.atleastOneGeoDbWarning | vtranslate }}</span>
                </avi-alert-group>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <gslb-geodb-profile-entries-grid
                            [paramsList]="editable.geoDbEntries"
                            [filenameDropdownOptions]="filenameDropdownOptions"
                            [formatDropdownOptions]="formatDropdownOptions"
                        ></gslb-geodb-profile-entries-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
