/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

import { Auth } from 'ajs/modules/core/services/auth';
import { AviPermissionResource } from 'generated-types';
import { FileServiceItem } from 'ajs/modules/core/factories/file-service/file-service.item.factory';

/**
 * @description Item for Tech-support component
 *
 * @author Shahab Hashmi
 */
export class TechSupportItem extends FileServiceItem {
    /**
     * ajsDependency for Auth
     */
    public static ajsDependencies = ['Auth'];

    /**
     * AuthService instance to use auth.
     */
    private readonly auth: Auth;

    constructor(args = {}) {
        super(args);

        this.auth = this.getAjsDependency_('Auth');
    }

    /** @override */
    public isAllowed(): boolean {
        return this.auth.isAllowed(AviPermissionResource.PERMISSION_TECHSUPPORT, 'w');
    }
}
