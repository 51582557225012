/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing update related components.
 * @preferred
 * @module UpdateModule
 */

import './update-node-status-card.component.less';

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    Type,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
    NodeType,
    UpgradeFsmState,
} from 'generated-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation/avi-continue-confirmation.component';

import { DialogService } from 'ng/modules/core/services/dialog.service';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { ITUpgradeNode } from 'ajs/modules/upgrade';
import { UpdateService } from 'ng/modules/update/services/update.service';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';
import { L10nService } from '@vmw/ngx-vip';

import {
    IUserSelectedOptions,
    UpgradeStateGroup,
} from '../../update.types';

import {
    UpgradeTranscriptModalComponent,
} from '../upgrade-transcript-modal/upgrade-transcript-modal.component';

import { UpdateStore } from '../update.store';

import * as l10n from './update-node-status-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const SUPRESS_WARNING_DIALOG_ID = 'SUPRESS_WARNING_DIALOG';
const UPGRADE_TRANSCRIPT_DIALOG_ID = 'UPGRADE_TRANSCRIPT_DIALOG_ID';

interface IStatusIconInfo {
    shape?: string;
    status?: string;
}

/**
 * @description Panel card for update pages.
 * @author Akul Aggarwal, Zhiqian Liu, Sarthak Kapoor
 */
@Component({
    selector: 'update-node-status-card',
    templateUrl: './update-node-status-card.component.html',
})
export class UpdateNodeStatusCardComponent implements OnInit, OnDestroy {
    @Input() public node: ITUpgradeNode;

    @Input()
    public overallPrechecksState?: UpgradeFsmState;

    @Input()
    public isPrecheckFlow = false;

    @Input()
    public userSelectedOptions: IUserSelectedOptions = {};

    @Input()
    public rollbackVersion: string;

    @Input()
    public isSystemUpdate = false;

    @Input()
    public selectedSegs: ITSEGroup[] = [];

    @Input()
    public selectedSegUpgradeImages: Image[] = [];

    @Input()
    public isUpgradeTriggeredFromSystemUpdatePage = false;

    /**
     * Event emitted when user resumes the upgrade from the right rail card.
     */
    @Output()
    public onSelectedSegIdUpdate = new EventEmitter<string>();

    /**
     * Boolean determining if card's middle part is extended.
     */
    public showExpanded = true;

    public nodeName: string;

    public currentNodeVersion: string;

    public prechecksFailed = false;

    public prechecksCompletedWithWarning = false;

    public prechecksInProgress = false;

    /**
     * Value for Upgrade operation for node.
     */
    public nodeUpgradeOps: string;

    /**
     * Value for upgrade_ops for prechecks.
     */
    public preChecksUpgradeOps = '';

    /**
     * String value time stamp when upgrade/rollback of node started.
     */
    public nodeUpgradeStartTime = '';

    /**
     * String value for time stamp when prechecks were triggered.
     */
    public prechecksStartTime = '';

    /**
     * Contains information regarding which cds-icon to display, depending upon the node status.
     */
    public readonly statusIconInfo: IStatusIconInfo = {};

    /**
     * Description of node status from schema.
     */
    public nodeStatusDescription: string;

    /**
     * Node status to be displayed on ui depending upon the state of the node.
     */
    public nodeStateGroupLabel: string;

    /**
     * Flag to show/hide upgrade resume button.
     */
    public showUpgradeResumeButton = false;

    /**
     * Flag to keep track of prechecks completion status.
     */
    public prechecksCompleted = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subscription to get updates on completion of prechecks.
     */
    private onPrechecksCompleteSubscription : Subscription;

    public constructor(
        private readonly updateStore: UpdateStore,
        private readonly schemaService: SchemaService,
        private readonly dialogService: DialogService,
        private readonly devLoggerService: DevLoggerService,
        public readonly l10nService: L10nService,
    ) {
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Update Node related info like prechecks status, last upgrade status etc. on
     * 1.) Initial load
     * 2.) On successful load execution of upgradeNodeCollection
     * as we need to update these properties again once, the prechecks are completed.
     */
    public ngOnInit(): void {
        const { updateStore } = this;

        this.setNodeRelatedInfo();
        this.onPrechecksCompleteSubscription = updateStore.preChecksCompleted$.subscribe(
            prechecksCompleted => {
                this.prechecksCompleted = prechecksCompleted;
                this.setShowUpgradeResumeButton();
            },
        );
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.node?.currentValue && !changes.node.firstChange) {
            this.setNodeRelatedInfo();
        }

        if (changes.overallPrechecksState?.currentValue) {
            this.setShowUpgradeResumeButton();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.onPrechecksCompleteSubscription?.unsubscribe();
    }

    /**
     * Shows/hides middle portion of card.
     */
    public toggleExpansion(): void {
        this.showExpanded = !this.showExpanded;
    }

    /**
     * Returns 'type' attr to pass to 'avi-icon' component.
     */
    public getAviIconType(): string {
        const { stateGroup } = this.node;

        switch (stateGroup) {
            case UpgradeStateGroup.GROUP_ERROR:
                return 'error';

            case UpgradeStateGroup.GROUP_COMPLETED:
                return 'success';

            default:
                return 'error';
        }
    }

    /**
     * Opens upgrade transcript modal.
     */
    public openTranscriptModal(): void {
        const {
            node: {
                id: nodeId,
                nodeType,
            },
        } = this;

        // For system update page we need to show overall state of controller + SEGs.
        const selectedNodes: string[] =
            nodeType === NodeType.NODE_CONTROLLER_CLUSTER ? undefined : [nodeId];

        if (this.isPrecheckFlow) {
            this.updateStore.openPreCheckTranscript({
                readonly: !this.showUpgradeResumeButton,
                nodeIds: selectedNodes,
            });
        } else {
            this.dialogService.add({
                id: UPGRADE_TRANSCRIPT_DIALOG_ID,
                component: UpgradeTranscriptModalComponent as Type<Component>,
                componentProps: {
                    nodeId,
                    nodeType,
                    onClose: () => this.dialogService.remove(UPGRADE_TRANSCRIPT_DIALOG_ID),
                },
            });
        }
    }

    public openSuppressWarningDialog(): void {
        const {
            dialogService,
            l10nKeys,
            l10nService,
        } = this;

        dialogService.add({
            id: SUPRESS_WARNING_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                customHeader: l10nService.getMessage(l10nKeys.suppressWarningHeader),
                warning: l10nService.getMessage(l10nKeys.warningMessage),
                onConfirm: async() => {
                    await this.onSuppressWarningConfirmation();
                    dialogService.remove(SUPRESS_WARNING_DIALOG_ID);
                },
                onClose: () => {
                    dialogService.remove(SUPRESS_WARNING_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Map node related info like name, version etc. to local component variables.
     */
    private setNodeRelatedInfo(): void {
        const {
            isPrecheckFlow,
            isSystemUpdate,
            node,
            overallPrechecksState,
            rollbackVersion,
        } = this;

        this.nodeName = node.getName();
        this.currentNodeVersion = isPrecheckFlow ? node.getImageRefVersion() || rollbackVersion :
            node.getVersion();
        this.nodeUpgradeOps = node.upgradeOps;
        this.nodeUpgradeStartTime = node.upgradeStartTime;

        /**
         * In case of System Update, we need to set the state based on the overall state of
         * all the nodes in a single card. In case of SEG, the state has to be shown based
         * on state of individual nodes.
         */
        this.prechecksInProgress = isSystemUpdate ?
            overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_IN_PROGRESS :
            node.arePrechecksForNodeInProgress();

        this.prechecksFailed = isSystemUpdate ?
            overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_ERROR :
            node.preChecksFailed();

        this.prechecksCompletedWithWarning = isSystemUpdate ?
            overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING :
            node.prechecksCompletedWithWarning();

        if (this.prechecksFailed || this.prechecksCompletedWithWarning ||
            this.prechecksInProgress) {
            this.setPreChecksRelatedInfo();
        }

        this.setStatusIconInfo();
        this.setNodeStatusDescription();
    }

    /**
     * Set prechecks related info like start time, upgrade operation value etc.
     */
    private setPreChecksRelatedInfo(): void {
        const { node } = this;

        this.preChecksUpgradeOps = node.preChecksUpgradeOps;
        this.prechecksStartTime = node.preChecksStartTimeStamp;
    }

    /**
     * Depending upon the state upgrade/rollback or prechecks, set the icon and and status
     * to be displayed.
     */
    private setStatusIconInfo(): void {
        const {
            l10nService,
            l10nKeys,
            node,
            prechecksFailed,
            prechecksCompletedWithWarning,
            statusIconInfo,
        } = this;
        const { stateGroup } = node;

        if (
            stateGroup === UpgradeStateGroup.GROUP_COMPLETED &&
            !prechecksFailed &&
            !prechecksCompletedWithWarning
        ) {
            statusIconInfo.shape = 'check-circle';
            statusIconInfo.status = 'success';
            this.nodeStateGroupLabel = l10nService.getMessage(l10nKeys.updateSuccessfulLabel);
        } else if (stateGroup === UpgradeStateGroup.GROUP_ERROR || prechecksFailed) {
            statusIconInfo.shape = 'exclamation-circle';
            statusIconInfo.status = 'danger';
            this.nodeStateGroupLabel = l10nService.getMessage(l10nKeys.updateFailedLabel);
        } else if (prechecksCompletedWithWarning) {
            statusIconInfo.shape = 'exclamation-triangle';
            statusIconInfo.status = 'warning';
            this.nodeStateGroupLabel = l10nService.getMessage(l10nKeys.updateStoppedLabel);
        }
    }

    /**
     * Set the description from schema based on the state of node.
     */
    private setNodeStatusDescription(): void {
        const {
            isSystemUpdate,
            node,
            overallPrechecksState,
            schemaService,
        } = this;
        const nodeState = node.state ||
            isSystemUpdate ? overallPrechecksState : node.upgradeReadiness?.state?.state;

        if (nodeState) {
            this.nodeStatusDescription = schemaService.getEnumValue(
                'UpgradeFsmState',
                nodeState,
            ).description;
        }
    }

    /**
     * Show upgrade resume button on the right rail cards.
     * This will be shown only if before the completion of the pre checks
     * user is on the same page from where the checks were triggered.
     * It will be hidden in case of refresh as well.
     */
    private setShowUpgradeResumeButton(): void {
        const {
            isSystemUpdate,
            isUpgradeTriggeredFromSystemUpdatePage,
            overallPrechecksState,
            prechecksCompleted,
            prechecksCompletedWithWarning,
            userSelectedOptions,
        } = this;

        if (Object.keys(userSelectedOptions).length && prechecksCompleted) {
            if (isSystemUpdate) {
                this.showUpgradeResumeButton =
                    overallPrechecksState === UpgradeFsmState.UPGRADE_PRE_CHECK_WARNING &&
                    isUpgradeTriggeredFromSystemUpdatePage;
            } else {
                this.showUpgradeResumeButton = prechecksCompletedWithWarning &&
                    !isUpgradeTriggeredFromSystemUpdatePage;
            }
        } else {
            this.showUpgradeResumeButton = false;
        }
    }

    private async onSuppressWarningConfirmation(): Promise<void> {
        const {
            devLoggerService,
            isSystemUpdate,
            updateStore,
            userSelectedOptions,
        } = this;

        const updatedUserSelectedOptions = isSystemUpdate ? { ...userSelectedOptions } :
            this.getUpdatedUserSelectedOptions();

        try {
            await updateStore.triggerPrechecks(updatedUserSelectedOptions, true);
            updateStore.onPreChecksTrigger();
        } catch (error) {
            devLoggerService.log(error);
        }
    }

    /**
     * Return the updated user selected options.
     * In case of SEG Update, when resuming the upgrade from the right rail card,
     * we need to send only the current node id to the backend. Since, we are also maintaing a
     * list of seg ids for which we keep a track of the current operation status, we emit an event
     * to update that as well.
     */
    private getUpdatedUserSelectedOptions(): IUserSelectedOptions {
        const {
            node,
            selectedSegs,
            selectedSegUpgradeImages,
            userSelectedOptions,
        } = this;

        const selectedSeg = selectedSegs?.filter(
            (selectedSegItem: ITSEGroup) => selectedSegItem.id === node.id,
        );

        const updatedUpgradeConfig = UpdateService.getSegUpgradeConfig(
            selectedSeg,
            selectedSegUpgradeImages,
        );

        const updatedUserSelectedOptions = {
            ...userSelectedOptions,
            upgradeConfig: { ...updatedUpgradeConfig },
        };

        this.onSelectedSegIdUpdate.emit(node.id);

        this.updateStore.setUserSelectedOptions(updatedUserSelectedOptions);

        return updatedUserSelectedOptions;
    }
}
