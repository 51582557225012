<avi-state-page class="traffic-capture-page">
    <avi-state-page_main-content>
        <ng-container *ngIf="showSuccessMessage">
            <cds-alert-group status="success">
                <cds-alert
                    closable="true"
                    (closeChange)="clearSuccessAlert()"
                >
                {{ l10nKeys.captureSuccessText | vtranslate }}
                </cds-alert>
            </cds-alert-group>
        </ng-container>
        <ng-container *ngIf="(this.serviceEngineTrafficCaptureStore.error$ | async)  as error">
            <cds-alert-group status="danger">
                <cds-alert
                    closable="true"
                    (closeChange)="clearErrorAlert()"
                >
                    {{ error }}
                </cds-alert>
            </cds-alert-group>
        </ng-container>
        <div class="traffic-capture-page__card-container">
            <traffic-capture-page-card
                class="traffic-capture-page__card"
                [configuration]="trafficCaptureCardConfiguration"
                [captureInProgress]="serviceEngineTrafficCaptureStore.captureInProgress$ | async"
                [stopCaptureInProgress]="serviceEngineTrafficCaptureStore.stopCaptureInProgress$ | async"
                (onStopCapture)="onStopCapture()"
                (onStartCapture)="onStartCapture()"
            ></traffic-capture-page-card>
        </div>
        <div class="traffic-capture-page__grid">
            <avi-data-grid
                [config]="capturesGridConfig"
                [rows]="serviceEngineTrafficCaptureStore.captureFiles$ | async"
                gridTitle="{{ l10nKeys.completedCapturesGridLabel | vtranslate: (serviceEngineTrafficCaptureStore.captureFiles$ | async)?.length }}"
            ></avi-data-grid>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
