/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module CloudModule */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClipboardService } from 'ng/modules/core/services/clipboard.service';
import * as globalL10n from 'global-l10n';
import * as l10n from './cloud-token-dialog.l10n';
import './cloud-token-dialog.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for showing the dialog upon successful token generation for Cloud.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'cloud-token-dialog',
    templateUrl: './cloud-token-dialog.component.html',
})
export class CloudTokenDialogComponent {
    /**
     * Authorization token received from backend when generate token option is clicked.
     */
    @Input()
    public authToken: string;

    /**
     * ID of the cluster to which the controller node belongs.
     */
    @Input()
    public clusterUuid: string;

    @Input()
    public cloudName: string;

    @Input()
    public tenantName: string;

    /**
     * Event Emitted to close the modal.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly clipboardService: ClipboardService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Copy the authentication token.
     */
    public copyToClipboard(): void {
        this.clipboardService.copy(this.authToken);
    }

    /**
     * Emit event to close the dialog.
     */
    public dismiss(): void {
        this.onClose.emit();
    }
}
