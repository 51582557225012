/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'vs-snat-ip-addresses';
const prefix = `${moduleName}.${componentName}`;

export const snatIpAddressLabel = `${prefix}.snatIpAddressLabel`;
export const bothLabel = `${prefix}.bothLabel`;

export const ENGLISH = {
    [snatIpAddressLabel]: 'SNAT Addresses',
    [bothLabel]: 'Both',
};
