<div
    class="login-banner"
    *ngIf="hasBanner()"
>
    <avi-textarea-container>
        <textarea
            clrTextarea
            [(ngModel)]="loginBanner"
            name="login-banner"
            rows="6"
            readonly
            class="login-banner__field"
        ></textarea>
    </avi-textarea-container>
</div>
