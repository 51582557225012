<full-modal-config
    modalTitle="{{ (isEditing ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <!-- General -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.generalSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>

            <clr-checkbox-wrapper>
                <input
                    type="checkbox"
                    clrCheckbox
                    name="enabled"
                    [(ngModel)]="editable.enabled"
                    id="enabled"
                />
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="enable"
                    for="enabled"
                >
                    {{ l10nKeys.enableRuleLabel | vtranslate }}
                </label>
            </clr-checkbox-wrapper>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ l10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ l10nKeys.enterNameLabel | vtranslate }}"
                    required
                />
            </avi-input-container>
        </full-modal-tab-section>

        <!--  Match  -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.matchSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.matchSectionTitle | vtranslate }}<span class="l4-rule-modal__mandatory-indicator">*</span></h3>
            <match-adder
                [match]="editable.config.match"
                [matchOptions]="matchOptions"
                [invalidateForm]="!editable.config.match.hasAnyEntry()"
            ></match-adder>
        </full-modal-tab-section>

        <!--  Action  -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.actionSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.actionSectionTitle | vtranslate }}<span class="l4-rule-modal__mandatory-indicator">*</span></h3>
            <l4-rule-select-pool-action
                [action]="l4RuleSelectPoolAction"
            ></l4-rule-select-pool-action>
        </full-modal-tab-section>
    </form>
</full-modal-config>
