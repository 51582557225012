<div class="waf-policy-psm-group-modal-preview">
    <div class="waf-policy-psm-group-modal-preview__section">
        <div class="waf-policy-psm-group-modal-preview__notes-header">
            <cds-icon
                status="success"
                shape="form"
                size="28"
            ></cds-icon>
            <span class="waf-policy-psm-group-modal-preview__notes-header-text">
                {{ l10nKeys.notesHeader | vtranslate }}
            </span>
        </div>
        <div class="waf-policy-psm-group-modal-preview__notes-title">
            {{ l10nKeys.missActionNoOperationLabel | vtranslate }}
        </div>
        <div class="waf-policy-psm-group-modal-preview__notes-message">
            {{ l10nKeys.missActionNoOperationMessage | vtranslate }}
        </div>
    </div>
    <div class="waf-policy-psm-group-modal-preview__item-preview-defaults">
        <item-preview-defaults 
            [config]="config"
            [isItem]="true"
        ></item-preview-defaults>
    </div>
</div>
