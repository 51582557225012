/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    Directive,
    Input,
    OnInit,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

/**
 * Key name for this validation error.
 * Used to display error.
 */
export const STRING_LENGTH_FIELD_VALIDATOR_KEY = 'maxStringLength';

/**
 * @description
 *      This directive performs validation based on string length from schema.
 *
 * @author Aravindh Nagarajan
 */
@Directive({
    providers: [
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: StringLengthInputValidationDirective,
        },
    ],
    selector: '[stringLengthInputValidation]',
})
export class StringLengthInputValidationDirective implements OnInit, Validator {
    /**
     * ObjectType of the field.
     */
    @Input()
    public objectType: string;

    /**
     * Field name from Schema.
     */
    @Input()
    public fieldName: string;

    /**
     * Max string length of the value entered for the applied field.
     * Default is undefined ie there is no max length.
     */
    private maxStringLength: number;

    constructor(
        private readonly schemaService: SchemaService,
        private readonly devLoggerService: DevLoggerService,
    ) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        try {
            this.maxStringLength = this.schemaService.getFieldStringLength(
                this.objectType,
                this.fieldName,
            );
        } catch (e) {
            this.devLoggerService.error(e);
        }
    }

    /**
     * @override
     * Checks whether the input value is within max length.
     *
     * Returns null if its a valid value, returns validationError if
     * input is invalid.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        const needValidation = control.value && this.maxStringLength;

        if (needValidation) {
            const error = Validators.maxLength(this.maxStringLength)(control);

            if (error) {
                return {
                    [STRING_LENGTH_FIELD_VALIDATOR_KEY]: [this.maxStringLength],
                };
            }
        }

        return null;
    }
}
