/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import {
    ActionGroupConfigItem,
} from 'ajs/modules/alert/factories/action-group-config/action-group-config.item.factory';

import {
    ControlScriptCollection,
} from 'ajs/modules/scripts/factories/control-script/control-script.collection.factory';

import {
    AlertEmailConfigCollection,
    AlertSyslogConfigCollection,
    SnmpTrapProfileCollection,
} from 'ajs/modules/alert/factories';

import { L10nService } from '@vmw/ngx-vip';
import { ActionGroupConfig } from 'object-types';
import * as l10n from './action-group-config.l10n';

type TAlertEmailConfigCollection = typeof AlertEmailConfigCollection;
type TControlScriptCollection = typeof ControlScriptCollection;
type TSnmpTrapProfileCollection = typeof SnmpTrapProfileCollection;
type TAlertSyslogConfigCollection = typeof AlertSyslogConfigCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * ActionGroupConfigModalComponent modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'action-group-config-modal',
    templateUrl: './action-group-config-modal.component.html',
})
export class ActionGroupConfigModalComponent {
    /**
     * ActionGroupConfigItem instance.
     */
    @Input()
    public editable: ActionGroupConfigItem;

    /**
     * ActionGroupConfig object type.
     */
    public readonly objectType = ActionGroupConfig;

    /**
     * Layout for ActionGroupConfig modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * AlertEmailConfigCollection instance.
     */
    public alertEmailConfigCollection: AlertEmailConfigCollection;

    /**
     * ControlScriptCollection instance.
     */
    public controlScriptCollection: ControlScriptCollection;

    /**
     * SnmpTrapProfileCollection instance.
     */
    public snmpTrapProfileCollection: SnmpTrapProfileCollection;

    /**
     * AlertSyslogConfigCollection instance.
     */
    public alertSyslogConfigCollection: AlertSyslogConfigCollection;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(AlertEmailConfigCollection)
        AlertEmailConfigCollection: TAlertEmailConfigCollection,
        @Inject(ControlScriptCollection)
        ControlScriptCollection: TControlScriptCollection,
        @Inject(SnmpTrapProfileCollection)
        SnmpTrapProfileCollection: TSnmpTrapProfileCollection,
        @Inject(AlertSyslogConfigCollection)
        AlertSyslogConfigCollection: TAlertSyslogConfigCollection,
    ) {
        this.alertEmailConfigCollection = new AlertEmailConfigCollection();
        this.controlScriptCollection = new ControlScriptCollection();
        this.snmpTrapProfileCollection = new SnmpTrapProfileCollection();
        this.alertSyslogConfigCollection = new AlertSyslogConfigCollection();
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.alertEmailConfigCollection.destroy();
        this.controlScriptCollection.destroy();
        this.snmpTrapProfileCollection.destroy();
        this.alertSyslogConfigCollection.destroy();
    }
}
