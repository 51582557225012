<div class="admin-user-setup clr-wrapper">
    <login-container>
        <form
            #adminUserSetupForm="ngForm"
            [invalidateForm]="!validPassword"
        >
            <div class="login-group">
                <avi-input-container>
                    <input
                        type="text"
                        class="admin-user-setup__username sel-admin-user-setup__username"
                        clrInput
                        [ngModel]="username"
                        name="username"
                        readonly
                    />
                </avi-input-container>
                <avi-input-container>
                    <input
                        type="password"
                        class="sel-admin-avi-password"
                        clrInput
                        placeholder="{{ l10nKeys.passwordFieldPlaceholder | vtranslate }}"
                        [(ngModel)]="password"
                        name="password"
                        (keyup)="comparePasswords()"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <input
                        type="password"
                        class="sel-admin-avi-confirm-password"
                        clrInput
                        placeholder="{{ l10nKeys.confirmPasswordFieldPlaceholder | vtranslate }}"
                        [(ngModel)]="confirmPassword"
                        name="confirmPassword"
                        (keyup)="comparePasswords()"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <input
                        type="email"
                        class="sel-email"
                        name="email"
                        clrInput
                        placeholder="{{ l10nKeys.emailFieldPlaceholder | vtranslate }}"
                        [(ngModel)]="email"
                        regexPattern="email"
                    />
                </avi-input-container>
                <div
                    class="error"
                    [ngClass]="{ active: errorMessage }"
                >
                    {{ errorMessage }}
                </div>
                <div
                    button-loader
                    [isLoading]="setupInProgress"
                >
                    <button
                        type="submit"
                        class="btn btn-primary sel-submit-btn"
                        (click)="createAccount()"
                        [disabled]="adminUserSetupForm.invalid"
                    >
                        {{ l10nKeys.createAccountButtonLabel | vtranslate }}
                    </button>
                </div>
            </div>
        </form>
    </login-container>
</div>
