/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphNode
 * @author Alex Malitsky
 * @description
 *
 *     Node Class for HealthScore graph.
 */
angular.module('aviApp').factory('HSGraphNode', ['Item',
function(Item) {
    class HSGraphNode extends Item {
        constructor(args = {}) {
            super(args);

            this.data.children = [];//refs to children nodes
        }

        /** @override */
        getIdFromData_({ config }) {
            return config['nodeid'];
        }

        /** @override */
        getName() {
            return this.getConfig()['name'];
        }

        /**
         * Returns a list of children nodes. For now needs a graph reference to figure that out.
         * @returns {HSGraphNode[]}
         * @public
         */
        getChildren() {
            return this.data.children.concat();
        }

        /**
         * Removes child node ref from HSGraphNode.children list.
         * @param {HSGraphNode} child
         * @public
         **/
        dropChildRef(child) {
            const
                { children } = this.data,
                pos = children.indexOf(child);

            if (child instanceof HSGraphNode && pos !== -1) {
                children.splice(pos, 1);
            }
        }

        /**
         * Puts a child node ref into HSGraphNode.children list.
         * @param {HSGraphNode} child
         * @public
         */
        addChildRef(child) {
            const { children } = this.data;

            if (child instanceof HSGraphNode && children.indexOf(child) === -1) {
                children.push(child);
            }
        }

        /**
         * Returns true if node is a leaf, false otherwise.
         * @returns {boolean}
         * @public
         */
        isLeafNode() {
            return this.getConfig()['leafnode'];
        }

        /**
         * Convenience method to figure out whether node is a root node.
         * @returns {boolean}
         * @public
         */
        isRootNode() {
            return this.getConfig()['parentid'] === null;
        }

        /**
         * Nodes which are marked as important to attract users' attention are called
         * "contributors". This method will figure out whether node is significantly affecting the
         * HS value.
         * @returns {boolean}
         * @public
         */
        isContributor() {
            return !!this.getConfig()['contributor'];
        }

        /**
         * Returns true if node is a root node child, false otherwise.
         * @returns {boolean}
         * @public
         */
        isRootChild() {
            return !!this.getConfig().isRootChild;
        }
    }

    HSGraphNode.prototype.objectName = 'HSGraphNode';

    return HSGraphNode;
}]);
