<div class="clr-wrapper avi-app-level-alert">
    <clr-alerts *ngIf="messages?.length || dynamicMessages$">
        <clr-alert
            [clrAlertType]="status"
            [clrAlertAppLevel]="true"
            (clrAlertClosedChange)="closeAlert()"
            *ngFor="let message of messages; trackBy: trackByIndex"
        >
            <clr-alert-item>
                <div>
                    <span>{{ message }}</span>
                    <span
                        class="alert-actions avi-app-level-alert__action-button"
                        *ngIf="hasActionButton"
                    >
                        <button
                            class="btn alert-action"
                            (click)="handleAction()"
                        >
                            {{ actionLabel }}
                        </button>
                    </span>
                </div>
            </clr-alert-item>
        </clr-alert>
    </clr-alerts>
</div>
