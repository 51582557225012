<full-modal-config
    modalTitle="{{ modalTitle }}"
    (onCancel)="dismissModal()"
    (onSubmit)="dismissModal()"
    [busy]="busy"
    [errors]="errors"
    submitButtonText="{{ l10nKeys.submitBtnLabel | vtranslate }}"
    class="verify-cloud-connector-user-host"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalSectionTitle | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                for="host-ip"
                                required
                            >
                                {{ l10nKeys.hostIpLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="host-ip"
                                name="host-ip"
                                placeholder="{{ l10nKeys.hostIpPlaceholder | vtranslate }}"
                                [(ngModel)]="hostIp"
                                (ngModelChange)="clearResponseText()"
                                regexPattern="ip"
                                [disabled]="disableHostIpInput || busy"
                                required
                            />
                        </avi-input-container>
                    </div>
                    <cds-button
                        type="button"
                        *ngIf="!disableHostIpInput"
                        [disabled]="form.invalid || busy"
                        (click)="runTask()"
                        class="verify-cloud-connector-user-host__verify-button"
                    >
                        {{ l10nKeys.verifyButtonLabel | vtranslate }}
                    </cds-button>
                </div>
                <avi-textarea-container *ngIf="cloudConnectorUserApiResponse">
                    <textarea
                        clrTextarea
                        id="response"
                        name="response"
                        rows="7"
                        [ngModel]="cloudConnectorUserApiResponse"
                        disabled
                    ></textarea>
                </avi-textarea-container>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
