/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { FILE_SERVICE_ITEM_TOKEN } from 'ajs/modules/core/core.tokens';

/**
 * @description Collection for File Service
 *
 * @author Shahab Hashmi
 */
export class FileServiceCollection extends Collection {
    public static ajsDependencies = [FILE_SERVICE_ITEM_TOKEN];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'fileservice',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(FILE_SERVICE_ITEM_TOKEN);
    }
}
