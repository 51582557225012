<full-modal-config
    modalTitle="{{ l10nKeys.modalTitleLabel | vtranslate }}"
    modalSubTitle="{{ config.name }}"
    (onCancel)="dismiss()"
    (onSubmit)="handleSubmit()"
    [busy]="busy"
    [errors]="errors"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        tooltipText="{{ l10nKeys.nameTooltipText | vtranslate }}"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="config.name"
                        (ngModelChange)="handleNameChange()"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        tooltipText="{{ l10nKeys.descriptionTooltipText | vtranslate }}"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        name="description"
                        [(ngModel)]="config.description"
                        clrTextarea
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <avi-checkbox
                    [(ngModel)]="config.is_federated"
                    name="is-federated"
                    [disabled]="isPkiProfileFederated"
                >
                    {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                </avi-checkbox>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="server-url"
                        tooltipText="{{ l10nKeys.serverUrlTooltipText | vtranslate }}"
                        required
                    >
                        {{ l10nKeys.serverUrlLabel | vtranslate }}
                    </label>
                    <input
                        id="server-url"
                        name="server-url"
                        [(ngModel)]="config.url"
                        clrInput
                        placeholder="{{ l10nKeys.serverUrlPlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>

                <avi-input-container
                    helperText="{{ globalL10nKeys.minutesLabel | vtranslate }}"
                    [errorText]="updateIntervalError"
                >
                    <label
                        aviLabelWithTooltip
                        for="update-interval"
                        tooltipText="{{ l10nKeys.updateIntervalTooltipText | vtranslate }}"
                        [rangeText]="updateIntervalRangeText"
                    >
                        {{ l10nKeys.updateIntervalLabel | vtranslate }}
                    </label>
                    <input
                        name="update-interval"
                        id="update-interval"
                        type="number"
                        [(ngModel)]="config.update_interval"
                        (ngModelChange)="handleUpdateIntervalChange()"
                        clrInput
                        placeholder="{{ l10nKeys.updateIntervalPlaceholder | vtranslate }}"
                        [min]="minimumUpdateInterval"
                        [max]="maximumUpdateInterval"
                    />
                </avi-input-container>

            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
