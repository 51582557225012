<avi-card class="system-settings-access-card">
    <avi-card_header>
        <div class="system-settings-access-card__header">
            <cds-icon
                shape="key"
                size="24"
                status="success"
            ></cds-icon>
            {{ l10nKeys.accessCardTitle | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <ng-content select="system-settings-access-card__system-access"></ng-content>
        <cds-divider></cds-divider>
        <ng-content select="system-settings-access-card__ssl"></ng-content>
        <cds-divider></cds-divider>
        <ng-content select="system-settings-access-card__snmp"></ng-content>
        <cds-divider></cds-divider>
        <ng-content select="system-settings-access-card__client-management-access"></ng-content>
        <cds-divider></cds-divider>
        <ng-content select="system-settings-access-card__banners"></ng-content>
    </avi-card_body>
</avi-card>
