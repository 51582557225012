/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AviPermissionResource } from 'generated-types';
import { IWidgetConfigWithPermissions } from 'ng/modules/dashboard/dashboard.types';
import { attachComponentBindings } from 'ng/shared/utils/component-rendering.utils';
import { IAviComponentBindingInfo } from 'nsx-alb-ui-components';

import {
    CloudServicesWidgetComponent,
} from '../cloud-services-widget/cloud-services-widget.component';

import {
    ControllerWidgetComponent,
} from '../controller-widget/controller-widget.component';

import {
    NodeVitalsWidgetComponent,
} from '../node-vitals-widget/node-vitals-widget.component';

import './administration-dashboard-page.component.less';
import * as l10n from './administration-dashboard-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'administration-dashboard-page',
    templateUrl: './administration-dashboard-page.component.html',
})
export class AdministrationDashboardPageComponent {
    public readonly l10nKeys = l10nKeys;

    public widgetConfigs: IWidgetConfigWithPermissions[] = [
        {
            widget: ControllerWidgetComponent as Type<Component>,
            permissions: [AviPermissionResource.PERMISSION_CONTROLLER],
        },
        {
            widget: NodeVitalsWidgetComponent as Type<Component>,
            width: 2,
            permissions: [AviPermissionResource.PERMISSION_CONTROLLER],
        },
        {
            widget: CloudServicesWidgetComponent as Type<Component>,
            width: 2,
            permissions: [
                AviPermissionResource.PERMISSION_ALBSERVICESCONFIG,
                AviPermissionResource.PERMISSION_CONTROLLER,
            ],
        },
    ];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    public attachComponentBindingsHandler(event: IAviComponentBindingInfo): void {
        const { componentRef, componentBindings } = event;

        attachComponentBindings(componentRef, componentBindings);
    }
}
