<div class="vs-logs-editable-filter">
    <cds-tag
        *ngIf="!isEditing"
        class="vs-logs-editable-filter__tag"
        status="info"
        size="20"
    >
        <div class="vs-logs-editable-filter__tag-container">
            <div
                class="vs-logs-editable-filter__tag-content"
                (click)="openFilter()"
            >
                {{ filter }}
            </div>
            <cds-icon
                class="vs-logs-editable-filter__tag-close-icon"
                shape="times"
                size="16"
                (click)="onFilterRemove.emit()"
            ></cds-icon>
        </div>
    </cds-tag>
    <vs-logs-filter-input
        *ngIf="isEditing"
        [isEditable]="true"
        (onSubmit)="updateFilter()"
    ></vs-logs-filter-input>
</div>
