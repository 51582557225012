/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Pipe, PipeTransform } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import * as globalL10n from 'global-l10n';
import * as l10n from './boolean-label.pipe.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TOutputType = 'enabled' | 'allowed' | 'switch' | 'yes' | 'active' | 'true';
type TBooleanLabelPairs = { [key in TOutputType]: [string, string] };

@Pipe({ name: 'booleanLabel' })
export class BooleanLabelPipe implements PipeTransform {
    private labelPairs: TBooleanLabelPairs = {
        enabled: [
            this.l10nService.getMessage(globalL10nKeys.enabledLabel),
            this.l10nService.getMessage(globalL10nKeys.deactivatedLabel),
        ],
        allowed: [
            this.l10nService.getMessage(l10nKeys.allowedLabel),
            this.l10nService.getMessage(l10nKeys.disallowedLabel),
        ],
        // Note: this output type is meant to be used for action labels with a toggle.
        // ex. we show "Deactivate" if the value is true and "Enable" if the value is false.
        switch: [
            this.l10nService.getMessage(l10nKeys.deactivateLabel),
            this.l10nService.getMessage(l10nKeys.enableLabel),
        ],
        yes: [
            this.l10nService.getMessage(globalL10nKeys.yesValueLabel),
            this.l10nService.getMessage(globalL10nKeys.noValueLabel),
        ],
        active: [
            this.l10nService.getMessage(globalL10nKeys.activeLabel),
            this.l10nService.getMessage(l10nKeys.suspendedLabel),
        ],
        true: [
            this.l10nService.getMessage(l10nKeys.trueLabel),
            this.l10nService.getMessage(l10nKeys.falseLabel),
        ],
    };

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Pipe that transforms boolean values into text labels. Default is "True" and "False".
     */
    public transform(boolVal: boolean, type: TOutputType = 'true', lowerCase = false): string {
        let output: string;

        const pair = this.labelPairs[type];

        if (!pair) {
            throw new Error('Output type not found.');
        }

        output = boolVal ? pair[0] : pair[1];

        if (lowerCase) {
            output = output.toLowerCase();
        }

        return output;
    }
}
