/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IUser } from 'generated-types';

export interface IUserAccountPayload extends IUser {
    confirm_password?: string;
    old_password?: string;
    password?: string;
}

export interface IUserAccountState {
    loaded: boolean;
    loading: boolean;
    userAccount: IUser;
}

export const initialState: IUserAccountState = {
    loaded: false,
    loading: false,
    userAccount: {},
};
