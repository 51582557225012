<div class="double-arrow">
    <i
        class="double-arrow__head-left"
        [ngClass]="headColorClass"
    ></i>
    <div
        class="double-arrow__line"
        [ngClass]="lineColorClass"
    ></div>
    <i
        class="double-arrow__head-right"
        [ngClass]="headColorClass"
    ></i>
</div>
