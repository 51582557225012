<div
    class="string-match-config"
    provideParentForm
>
    <label
        aviLabelWithTooltip
        objectType="{{ objectType }}"
        fieldName="match_criteria"
        for="match_criteria"
        required
    >
        {{ l10nKeys.criteriaDropdownLabel | vtranslate }}
    </label>
    <avi-enum-dropdown
        id="{{ 'match-criteria-' + id }}"
        name="{{ 'match-criteria-' + id }}"
        [(ngModel)]="editable.config.match_criteria"
        enum="StringOperation"
        [hiddenEnumValues]="hiddenEnumValues"
        required
    ></avi-enum-dropdown>

    <div>
        <div
            *ngFor="let config of stringGroupRefsOrCustomValuesConfigs; index as i; trackBy: trackByIndex"
            class="string-match-config__string-container"
        >
            <div class="string-match-config__string-header">
                <label
                    aviLabelWithTooltip
                    required
                >
                    {{ stringLabel | vtranslate }}
                </label>
                <cds-icon
                    *ngIf="stringGroupRefsOrCustomValuesConfigs.length > 1"
                    class="string-match-config__remove-icon"
                    shape="trash"
                    size="18"
                    status="info"
                    (click)="removeItem(i)"
                ></cds-icon>
            </div>

            <string-group-or-custom-value
                class="string-match-config__string-group-or-custom-value"
                [(stringGroupRefs)]="config.stringGroupRefs"
                (stringGroupRefsChange)="handleStringsChange()"
                [(customValues)]="config.matchStrings"
                (customValuesChange)="handleStringsChange()"
                index="{{ i + '-' + id }}"
                noMarginTop
            ></string-group-or-custom-value>
        </div>
    </div>

    <cds-button
        class="string-match-config__add-item-button"
        action="flat"
        size="sm"
        (click)="addItem()"
    >
        <cds-icon shape="plus-circle"></cds-icon>
        {{ l10nKeys.addItemButtonLabel | vtranslate }}
    </cds-button>
</div>
