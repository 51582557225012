/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    SnmpTrapProfileListModalComponent,
    SnmpTrapProfileModalComponent,
} from '.';

const snmpTrapProfileComponents = [
    SnmpTrapProfileModalComponent,
    SnmpTrapProfileListModalComponent,
];

/**
 * @description
 *
 * Angular Module for SNMP Trap Profile related components.
 *
 * @author Guru Prasad
 */
@NgModule({
    declarations: [
        ...snmpTrapProfileComponents,
    ],
    imports: [
        SharedModule,
        CommonModule,
        AviFormsModule,
        DataGridModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SnmpTrapProfileModule {}
