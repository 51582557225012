/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notifications';
const componentName = 'snmp-controller';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const serverLabel = `${prefix}.serverLabel`;
export const validateSettingsLabel = `${prefix}.validateSettingsLabel`;
export const snmpTrapServersLabel = `${prefix}.snmpTrapServersLabel`;
export const trapServerIpAddressLabel = `${prefix}.trapServerIpAddressLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const snmpCommunityLabel = `${prefix}.snmpCommunityLabel`;
export const snmpVersionLabel = `${prefix}.snmpVersionLabel`;
export const removeSnmpServerLabel = `${prefix}.removeSnmpServerLabel`;
export const addSnmpServerLabel = `${prefix}.addSnmpServerLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit SNMP Trap Notifications: {0}',
    [modalHeaderNew]: 'New SNMP Trap Notifications: {0}',
    [nameInputLabel]: 'Name',
    [serverLabel]: 'Server',
    [validateSettingsLabel]: 'Validate Settings',
    [snmpTrapServersLabel]: 'SNMP Trap Servers',
    [trapServerIpAddressLabel]: 'Trap Server IP Address',
    [ipAddressLabel]: 'IP Address',
    [snmpCommunityLabel]: 'SNMP Community',
    [snmpVersionLabel]: 'SNMP Version:',
    [removeSnmpServerLabel]: 'Remove SNMP Server',
    [addSnmpServerLabel]: '+ Add SNMP Server',
    [saveButtonLabel]: 'Save',
};
