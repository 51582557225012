/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import { HealthMonitorFtp } from 'object-types';

import {
    IHealthMonitorFtp,
    IHealthMonitorSSLAttributes,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Ftp health monitor type without the ssl_aatributes.
 */
type THealthMonitorFtpPartial = Omit<IHealthMonitorFtp, 'ssl_attributes'>;

/**
 * FTP Health Monitor Config type.
 */
interface IHealthMonitorFTPConfig extends THealthMonitorFtpPartial {
    ssl_attributes: MessageItem<IHealthMonitorSSLAttributes>;
}

/**
 * @description Health Monitor FTP Config Item.
 * @author Sarthak Kapoor
 */
export class HealthMonitorFtpConfigItem extends MessageItem<IHealthMonitorFTPConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorFtp,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ssl_attributes'];
    }
}
