/**
 * @module NotificationModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { attachComponentBindings } from 'ng/shared/utils';
import {
    DialogService,
    IAviDialogProps,
} from 'dialog-service';

interface IAviDialog {
    id: string;
    componentRef: ComponentRef<Component>;
}

/**
 * Component used to display dialogs. All rendered dialogs will be contained within the
 * view of this component.
 * @author alextsg
 */
@Component({
    selector: 'avi-dialogs-portal',
    template: `
        <ng-template
            class="clr-wrapper"
            #dialogsContainer
        ></ng-template>`,
})
export class AviDialogsPortalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('dialogsContainer', {
        read: ViewContainerRef,
    }) private dialogsContainerRef: ViewContainerRef;

    /**
     * Contains a list of dialogs.
     */
    public dialogs: IAviDialog[] = [];

    /**
     * Subscription to the DialogService, which provides this component with a stack of
     * dialogs to be rendered.
     */
    private subscription: Subscription;

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly dialogService: DialogService,
    ) {}

    /** @override */
    public ngAfterViewInit(): void {
        this.subscription = this.dialogService.items$
            .subscribe((dialogs: IAviDialogProps[]): void => {
                this.destroyRemovedNotifications(dialogs);
                this.setDialogs(dialogs);
                this.renderNewNotifications();
            });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Destroys any notifications that no longer exist in the notifications stack from the
     * NotificationsService.
     */
    private destroyRemovedNotifications(incomingNotificationProps: IAviDialogProps[]): void {
        const incomingHash = incomingNotificationProps.reduce((hash, notification) => {
            hash[notification.id] = true;

            return hash;
        }, {});

        const removedDialogs = this.dialogs.filter(dialog => {
            return !(dialog.id in incomingHash);
        });

        removedDialogs.forEach((dialog): void => {
            const { hostView } = dialog.componentRef;
            const index = this.dialogsContainerRef.indexOf(hostView);

            this.dialogsContainerRef.remove(index);
        });
    }

    /**
     * Creates a notificationsStack that matches the notificationPropsStack coming from the
     * NotificationsService.
     */
    private setDialogs(incomingNotificationProps: IAviDialogProps[] = []): void {
        const existingNotificationRefs = this.dialogs.reduce((hash, dialog) => {
            hash[dialog.id] = dialog.componentRef;

            return hash;
        }, {});

        this.dialogs = incomingNotificationProps.map((props): IAviDialog => {
            const { id } = props;
            const componentRef = id in existingNotificationRefs ?
                existingNotificationRefs[id] :
                this.createComponentRef(props);

            return {
                componentRef,
                id,
            };
        });
    }

    /**
     * Renders any notification in the notificationsStack that doesn't already exist in the
     * viewContainerRef.
     */
    private renderNewNotifications(): void {
        this.dialogs.forEach((dialog, stackIndex): void => {
            const { hostView } = dialog.componentRef;
            const index = this.dialogsContainerRef.indexOf(hostView);

            if (index < 0) {
                this.dialogsContainerRef.insert(hostView, stackIndex);
            }
        });
    }

    /**
     * Creates an instance of the notification component.
     */
    private createComponentRef(notificationProps: IAviDialogProps): ComponentRef<Component> {
        const { component, componentProps = {}, id } = notificationProps;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.dialogsContainerRef.injector);
        const defaultComponentProps = {
            onClose: () => this.dialogService.remove(id),
        };

        const extendedComponentProps: {} = {
            ...defaultComponentProps,
            ...componentProps,
        };

        attachComponentBindings(componentRef, extendedComponentProps);

        return componentRef;
    }
}
