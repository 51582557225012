/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name eventChartService
 * @description
 *      Service used with Event Chart components.
 */
angular.module('aviApp').service('eventChartService', [
    'EventEmitter2', function(EventEmitter2) {
        class EventChartService extends EventEmitter2 {
            constructor() {
                super();
                this.gridVisible = false;
                this.timestamp = 0;
                this.gridType = 'anomalies';
            }

            /**
             * Sets grid visibility to true.
             */
            showGrid() {
                this.gridVisible = true;
            }

            /**
             * Sets grid visibility to false.
             */
            hideGrid() {
                this.gridVisible = false;
            }

            /**
             * Dispatches update event.
             * @param {string} gridType - Grid type to select:
             *      "anomalies", "system", "alerts", "config".
             * @param {number} timestamp - Timestamp where event occurred.
             */
            update(gridType, timestamp) {
                this.gridType = gridType;
                this.timestamp = timestamp;
                this.emit('update', gridType, timestamp);
            }
        }

        return new EventChartService();
    }]);
