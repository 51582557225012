/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module IcapProfileModule
 */

import { Injector } from '@angular/core';
import {
    IcapProfileCollection,
} from 'ajs/modules/icap-profile/factories/icap-profile.collection.factory';

import { ICAP_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/icap-profile/icap-profile.tokens';

const $injector = '$injector';

export const icapProfileCollectionProvider = {
    deps: [$injector],
    provide: IcapProfileCollection,
    useFactory(injector: Injector): typeof IcapProfileCollection {
        return injector.get(ICAP_PROFILE_COLLECTION_TOKEN);
    },
};
