/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';

import * as globalL10n from 'global-l10n';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    CertificateManagement,
    CertificateManagementCollection,
} from 'ajs/modules/security/factories/certificate-management';

import * as l10n from './certificate-management-list-page.l10n';

type TCertificateManagementCollection = typeof CertificateManagementCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Certificate management list page.
 *
 * @author Shahab Hashmi
 */
@Component({
    selector: 'certificate-management-page',
    templateUrl: './certificate-management-list-page.component.html',
})
export class CertificateManagementListPageComponent implements OnInit, OnDestroy {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * * Configuration object to display Certificate Management grid
     */
    public certificateManagementListPageGridConfig: IAviCollectionDataGridConfig;

    /**
     * CertificateManagementCollection instance.
     */
    public readonly certificateManagementCollection: CertificateManagementCollection;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject(CertificateManagementCollection)
        CertificateManagementCollection: TCertificateManagementCollection,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.certificateManagementCollection = new CertificateManagementCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.certificateManagementCollection;

        this.certificateManagementListPageGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.certificateManagementCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }, {
                id: 'control-script',
                label: this.l10nService.getMessage(globalL10nKeys.controlScriptLabel),
                transform: row => {
                    const certManagementItem = row as CertificateManagement;

                    return certManagementItem.controlScriptName ?? '-';
                },
            }, {
                id: 'custom-params',
                label: this.l10nService.getMessage(l10nKeys.customParamsLabel),
                transform: row => {
                    const certManagementItem = row as CertificateManagement;

                    return `${certManagementItem.scriptParams.count}` ?? '-';
                },
            }],
            singleactions: [
                {
                    id: 'edit',
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (row): void => {
                        row.isEditable() ? row.edit() :
                            this.certificateManagementCollection.clone(row);
                    },
                    hidden: (row): boolean => {
                        return !row.isEditable() && !row.isClonable();
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.certificateManagementCollection.destroy();
    }
}
