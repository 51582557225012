<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.generalSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ l10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ l10nKeys.enterNamePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>

            <avi-data-grid
                [config]="ipReputationTypeMappingGridConfig"
                [rows]="ipReputationTypeMappingGridRows"
            >
                <avi-data-grid_header>
                    <h6>
                        {{ l10nKeys.typeMappingsLabel | vtranslate : ipReputationTypeMappingGridRows.length }}
                    </h6>
                </avi-data-grid_header>
                <avi-data-grid_actions>
                    <cds-button
                        size="sm"
                        (click)="createTypeMapping()"
                    >
                        {{ l10nKeys.addButtonLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
            </avi-data-grid>
        </full-modal-tab-section>
    </form>
</full-modal-config>
