<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleUdp | vtranslate }}</h3>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="isEditing"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>
   <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.clientRequestHeaderInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="udp-request"
                objectType="{{ objectTypes.HealthMonitorUdp }}"
                fieldName="udp_request"
                [(ngModel)]="udpMonitor.config.udp_request"
                required
                placeholder="{{ l10nKeys.clientRequestHeaderInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.serverResponseDataInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="udp-response"
                objectType="{{ objectTypes.HealthMonitorUdp }}"
                fieldName="udp_response"
                [(ngModel)]="udpMonitor.config.udp_response"
                placeholder="{{ l10nKeys.serverResponseDataInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
</div>
