/**
 * @module VerticalNavModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';

/**
 * @description Pipe to check if a navigation state is a nav group, which contains child links.
 * @author alextsg
 */
@Pipe({
    name: 'isNavGroup',
})
export class IsNavGroupPipe implements PipeTransform {
    public transform(state: IAppState): boolean {
        return state.children && !state.url?.includes(':');
    }
}
