/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function collMetricsDataTransformerFactory(RevisedDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/dataModel
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @author Alex Malitsky, Ashish Verma
     * @desc
     *     In terms of request preparation CollMetricsDataTransformer takes care of building up an
     *     Item's uuid properties (such as `entity_uuid`, `pool_uuid` and `server`) from a `tuple_`
     *     array and transforms a list of `fields_` into property of a string type `metric_id`
     *      having a list of metric_ids concatenated by commas.
     *
     *     While doing response transformation it takes care of concatenated by the backend
     *     subrequest ids transforming response into a flat hash where id is an original subreqest
     *     ID and value has a whole set of metric series groups related to it.
     */
    class CollMetricsDataTransformer extends RevisedDataTransformer {
        /**
         * Returns a request config suitable for {@link CollMetricsDataTransport} API call.
         * @param {CollMetricsRequest|CollMetricsRequest[]} requests - With a few special
         *     fields such as `fields_`, `aggregation_` and `tuple_`.
         * @returns {CollMetricsRequest[]}
         * @override
         * @public
         */
        getRequestConfig(requests) {
            const subRequestIds = {};

            requests = angular.copy(requests);

            if (!angular.isArray(requests) && angular.isObject(requests)) {
                requests = [requests];
            }

            if (angular.isArray(requests)) {
                requests.forEach(request => {
                    let tuple;

                    if ('tuple_' in request && Array.isArray(request['tuple_'])) {
                        tuple = request['tuple_'];
                        request.entity_uuid = tuple[0] || '*';

                        if (tuple.length > 1) {
                            request.pool_uuid = tuple[1];
                        }

                        if (tuple.length > 2) {
                            request.obj_id = tuple[2];
                        }
                    }

                    if (!request['id'] || !angular.isString(request['id']) ||
                        request['id'] in subRequestIds) {
                        const errMsg = 'Request MUST have a unique ID';

                        console.error(`${errMsg}, %O`, request);
                        throw Error(errMsg);
                    }

                    if (request['id'].indexOf(',') !== -1) {
                        throw new Error('Comma is not allowed in a sub-request Id.');
                    }

                    subRequestIds[request['id']] = true;

                    if ('fields_' in request && angular.isArray(request['fields_'])) {
                        request['metric_id'] = request['fields_'].join(',');
                    }

                    if ('aggregation_' in request) {
                        request['dimension_aggregation'] = request['aggregation_'];
                    }

                    delete request['tuple_'];
                    delete request['fields_'];
                    delete request['aggregation_'];
                });
            }

            return requests;
        }

        /**
         * Translates collection metrics backend response into more convenient flat hash by
         * subrequest id. Since backend concatenates subrequest ids and put all corresponding
         * CollMetricsResponsePerRequestId into groups we want to unwrap it so that each subrequest
         * id has a whole set of results available by it's id wo extra searching and parsing.
         * When multilple collection metrics API calls have been made we need to join them in one.
         * @param {CollMetricsFullResponse} series
         * @returns {{string: CollMetricsResponsePerRequestId}} where key is strictly a subrequest
         * id.
         * @override
         * @public
         */
        processResponse(series) {
            const res = {};//{subRequestId: {combinedUnitId: {unitId: [series]}}}

            _.each(series, (dataSet, combinedRequestId) => {
                _.each(combinedRequestId.split(','), subRequestId => {
                    if (!(subRequestId in res)) {
                        res[subRequestId] = {};

                        _.each(dataSet, (unitDataSet, combinedUnitId) => {
                            if (!(combinedUnitId in res[subRequestId])) {
                                res[subRequestId][combinedUnitId] = [];
                            }

                            Array.prototype.push.apply(
                                res[subRequestId][combinedUnitId],
                                unitDataSet,
                            );
                        });
                    }
                });
            });

            return res;
        }
    }

    return CollMetricsDataTransformer;
}

collMetricsDataTransformerFactory.$inject = [
    'RevisedDataTransformer',
];

angular.module('avi/dataModel').factory(
    'CollMetricsDataTransformer',
    collMetricsDataTransformerFactory,
);
