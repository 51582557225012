/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IAuthProfileConfig } from 'ajs/modules/security';
import { ISamlServiceProviderNode } from 'generated-types';
import { SamlServiceProviderNode } from 'object-types';

import {
    AuthProfileVerifySamlModalService,
} from 'ng/modules/security/services/auth-profile-verify-saml-modal.service';

import * as l10n from './auth-profile-verify-saml-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Auth Profile Verify SAML modal.
 *
 * @author Suraj Kumar
 */
@Component({
    selector: 'auth-profile-verify-saml-modal',
    templateUrl: './auth-profile-verify-saml-modal.component.html',
    providers: [
        AuthProfileVerifySamlModalService,
    ],
})
export class AuthProfileVerifySamlModalComponent {
    /**
     * Auth profile's config.
     */
    @Input()
    public authProfileConfig: IAuthProfileConfig;

    /**
     * Auth profile name.
     */
    public profileName: string;

    /**
     * If true, will hide the submit button.
     */
    public hideSubmit = true;

    /**
     * Object type for SAML.
     */
    public readonly objectType = SamlServiceProviderNode;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Verify SAML modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
        private readonly authProfileVerifySamlModalService: AuthProfileVerifySamlModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.profileName = this.authProfileConfig.name;
    }

    /**
     * Function to close verify SAML modal.
     */
    public dismissModal(): void {
        this.authProfileVerifySamlModalService.closeModal();
    }

    /**
     * Getter for sp_nodes.
     */
    public get spNodes(): Array<MessageItem<ISamlServiceProviderNode>> {
        const { saml } = this.authProfileConfig;

        return saml.config.sp?.config.sp_nodes?.config;
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}
