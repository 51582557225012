/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'vs';

export const vsVipModalBreadcrumbTitle = `${prefix}.vsVipModalBreadcrumbTitle`;
export const rbacKeyValueCountLabel = `${prefix}.rbacKeyValueCountLabel`;
export const placementNetworkModalBreadcrumbTitle = `${prefix}.placementNetworkModalBreadcrumbTitle`;
export const virtualHostingModalBreadcrumbTitle = `${prefix}.virtualHostingModalBreadcrumbTitle`;
export const vHMatchRuleModalBreadcrumbTitle = `${prefix}.vHMatchRuleModalBreadcrumbTitle`;

export const ENGLISH = {
    [vsVipModalBreadcrumbTitle]: 'VS VIP',
    [rbacKeyValueCountLabel]: '{0} key(s), {1} value(s)',
    [placementNetworkModalBreadcrumbTitle]: 'Placement Network',
    [virtualHostingModalBreadcrumbTitle]: 'Virtual Hosting',
    [vHMatchRuleModalBreadcrumbTitle]: 'Match Rule',
};
