/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../virtual-service-create-basic-controller/virtualservice-create.less';
import {
    VS_TYPE_NORMAL,
    VS_TYPE_VH_CHILD,
    VS_TYPE_VH_PARENT,
} from 'ajs/js/services/items/VirtualService';

import { CLOUD_NSXT } from 'ajs/modules/cloud/cloud.constants';

import * as globalL10n from 'global-l10n';
import * as l10n from './virtual-service-create.controller.l10n';

import { CSRF_POLICY_COLLECTION_TOKEN } from '../../../../downgrade-services.tokens';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * `VIP Placement` section in VS create/edit -> advanced tab is
 * shown for VS with following cloud-types.
 * @type {Set<string>}
 * @inner
 */
const cloudsWithVipPlacement = new Set([
    'CLOUD_VCENTER',
]);

const APPLICATION_PROFILE_TYPE_HTTP = 'APPLICATION_PROFILE_TYPE_HTTP';

/**
 * @ngdoc controller
 * @name VirtualServiceCreateController
 * @description
 *
 *    VS Create/edit modal controller.
 */
export function virtualServiceCreateController(
    $controller,
    $q,
    $scope,
    $window,
    AnalyticsProfileCollection,
    ApplicationProfile,
    ApplicationProfileCollection,
    Base,
    Cloud,
    defaultValues,
    dropDownUtils,
    ErrorPageProfileCollection,
    IcapProfileCollection,
    NetworkProfile,
    NetworkProfileCollection,
    PoolGroupCollection,
    schemaService,
    SEGroupCollection,
    SSLProfileCollection,
    StringGroupCollection,
    TrafficCloneProfileCollection,
    VirtualServiceCollection,
    WafPolicyCollection,
    BotDetectionPolicyCollection,
    l10nService,
    VsVipCollection,
    CSRFPolicyCollection,
) {
    const base = new Base();

    $controller('VirtualServiceCreateCommonController', { $scope });

    /**
     * This value for Pool's server port will be considered `default` and used as
     * fallback option when we don't want to set some specific value.
     * @type {number}
     **/
    let defaultPoolServerPort = 80;

    $scope.serverNetworkProfileCollection = new NetworkProfileCollection({
        params: { 'profile.type': 'PROTOCOL_TYPE_TCP_PROXY' },
    });

    $scope.vsEndPointCollection = new VirtualServiceCollection({
        isStatic: true,
        params: {
            type: 'VS_TYPE_VH_PARENT',
        },
    });

    $scope.vsEndPointCollection.isCreatable = function() { return false; };

    $scope.sslProfileCollection = new SSLProfileCollection();
    $scope.analyticsProfileCollection = new AnalyticsProfileCollection();
    $scope.stringGroupCollection = new StringGroupCollection();
    $scope.poolGroupCollection = new PoolGroupCollection();
    $scope.trafficCloneProfileCollection = new TrafficCloneProfileCollection();
    $scope.wafPolicyCollection = new WafPolicyCollection();
    $scope.errorPageProfCollection = new ErrorPageProfileCollection();
    $scope.botDetectionPolicyCollection = new BotDetectionPolicyCollection();
    $scope.csrfPolicyCollection = new CSRFPolicyCollection();

    /** Minimum length for dropdown options */
    $scope.optionsLength = 2;

    /**
     * Instance of NetworkProfileCollection without any profile filters.
     * Used in override Network profile dropdown if that particular has
     * override application profile set.
     */
    $scope.serviceNetworkProfileCollection = new NetworkProfileCollection();

    $scope.l4ApplicationProfileCollection = new ApplicationProfileCollection({
        params: {
            type: 'APPLICATION_PROFILE_TYPE_L4',
        },
        defaults: {
            type: 'APPLICATION_PROFILE_TYPE_L4',
        },
    });

    /**
     * Get keys from source bundles for template usage
     */
    $scope.l10nKeys = l10nKeys;

    $scope.globalL10nKeys = globalL10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /**
     * IcapProfileCollection instance.
     * @type {IcapProfileCollection}
     */
    $scope.icapProfileCollection = new IcapProfileCollection();

    $scope.vsVipCollection = new VsVipCollection();

    /**
     * Max allowed icap profiles.
     * @type {number}
     */
    $scope.maxIcapProfiles =
        schemaService.getFieldMaxElements('VirtualService', 'icap_request_profile_uuids');

    $scope.SEGroupCollection = new SEGroupCollection({
        isStatic: true,
        params: {
            sort: 'name',
        },
        bind: {
            collectionLoadSuccess() {
                $scope.setSEGroup();
            },
        },
    });

    $scope.editMode = {
        requestPolicy: null,
        responsePolicy: null,
        securityPolicy: null,
        networkSecurityPolicy: null,
        datascriptPolicy: null,
    };

    /**
     * Switches port layout from basic (single value) to advanced with ranges.
     */
    $scope.basicServicePortViewChange = function() {
        $scope.ui.basicServicePort = !$scope.ui.basicServicePort;

        // When we switch from advanced to basic
        // set all port_range_ends to port & delete advanced service settings.
        if ($scope.ui.basicServicePort && $scope.editable && $scope.editable.data) {
            $scope.editable.data.config.services.forEach(function(service) {
                service.port_range_end = service.port;

                delete service.horizon_internal_ports;
                delete service.override_application_profile_ref;
            });
        }
    };

    // For edit tabs
    $scope.resolutions = [];
    //$scope.readonly = false;
    // Wizard definition
    $scope.wizard = {
        current: 0,
        steps: [{
            title: l10nService.getMessage(l10nKeys.tabLabelSettings),
        }, {
            title: l10nService.getMessage(l10nKeys.tabLabelPolicies),
        }, {
            title: l10nService.getMessage(globalL10nKeys.analyticsLabel),
        }, {
            title: l10nService.getMessage(l10nKeys.tabLabelAdvanced),
        }],
        beforeChange() {
            // Clear errors if exist
            $scope.editable.errors = null;

            // Check if need to discard
            if (confirmDiscardSubObject()) {
                $scope.resetSubObjects();

                return false;
            }

            return true;
        },
    };

    /**
     * Sets the vsEndPointCollection params based on the VH type.
     */
    const setVSEndPointCollectionVHParams = () => {
        const vhType = $scope.editable.getVHType();

        $scope.vsEndPointCollection.setParams({
            vh_type: vhType,
        });
    };

    /**
     * Sets the Application Profile type based on the VH type. If enhanced, only HTTP Application
     * Profiles should be shown.
     */
    const setApplicationProfileVHParams = () => {
        let type;

        if ($scope.editable.isVHChild() || $scope.editable.isVHParent()) {
            type = APPLICATION_PROFILE_TYPE_HTTP;
        }

        $scope.applicationProfileCollection.setParams({ type });
    };

    /**
     * Returns true if the cloud is type NSX and has a VLAN tz_type.
     * @returns {boolean}
     */
    const isVlanNsxtCloud = () => {
        const { Cloud: cloud } = $scope;

        return cloud.isVtype(CLOUD_NSXT) && cloud.getCloudConfig().isVlanTransportZone();
    };

    $scope.init = function() {
        defaultPoolServerPort = defaultValues.getDefaultItemConfig('pool.default_server_port') ||
            defaultPoolServerPort;

        /**
         * We need to keep track of all loads to set pristine form state when data is 100%
         * ready.
         * @type {ng.$q.promise[]}
         * @inner
         */
        const promises = [];

        $scope.wizard.current = 0;

        const
            { editable: vs } = $scope,
            config = vs.getConfig();

        $scope.ui = {};

        const { ui } = $scope;

        ui.cloudIsSet = false;
        ui.vrfContextIsSet = false;
        //need some time for the cloud collection calls and should show spinner
        ui.cloudInitIsActive = true;
        ui.isVHVS = config.type !== 'VS_TYPE_NORMAL';//virtual hosting VS
        ui.basicServicePort = true;
        ui.showPlacement = true;
        ui.appProfileRef = config['application_profile_ref'];

        ui.poolOrPoolGroup = config['pool_group_ref'] ? 'poolgroup' : 'pool';

        ui.clientInsightsTypes = [
            'ACTIVE',
            'PASSIVE',
            'NO_INSIGHTS',
        ];

        ui.includeUrl = true;

        //show the Rate Limiter subtab if at least one of the nested properties is defined
        ui.activeRateLimiters = !!(config.connections_rate_limit ||
            config.requests_rate_limit || config.performance_limits);
        $scope.newServer = { address: '' };

        $scope.resetSubObjects();

        if (!vs.id) { //create new dialog
            promises.push(
                vs.loadNetProfileData(),
                vs.loadAppProfileData(),
            );

            const setCloudPromise = $scope.cloudCollection.load()
                .then(() => {
                    if ($scope.cloudCollection.getTotalNumberOfItems() < 2) {
                        //go on with the default cloud_uuid
                        return setCloud();
                    } else {
                        $scope.ui.cloudInitIsActive = false;
                    }
                })
                .catch(({ data }) => {
                    $scope.ui.cloudInitIsActive = false;
                    $scope.editable.errors = data;
                });

            promises.push(setCloudPromise);
        } else { //can take cloud_ref from collection.defaults if needed
            promises.push(setCloud());
        }

        $scope.ui.basicServicePort = !_.any(config.services, service => {
            return service.nwOverride || service.port !== service.port_range_end ||
                service.horizon_internal_ports || !!service.override_application_profile_ref;
        });

        // need to call immediately since user can close the modal before promises get
        // resolved and we don't want to show confirmation dialog in this case
        vs.setPristine();

        $q.all(promises).finally(() => {
            updateModalSettingsAfterAppProfileChange();
            vs.setPristine();
        });

        $scope.policySetIndex = 0;
        $scope.dnsPolicyIndex = 0;
        $scope.topologyPolicyIndex = 0;

        $scope.httpPolicySetSelectOptions = [];
        $scope.dnsPolicySelectOptions = [];
        $scope.topologyPolicySelectOptions = [];

        const { httpPolicySets } = vs;
        const { dns_policies: dnsPolicies } = vs.getConfig();
        const { topology_policies: topologyPolicies } = vs.getConfig();

        if (Array.isArray(httpPolicySets)) {
            $scope.httpPolicySetSelectOptions = httpPolicySets.map((policySet, i) => {
                return dropDownUtils.createOption(i, policySet.getName());
            });
        }

        if (Array.isArray(dnsPolicies)) {
            $scope.dnsPolicySelectOptions = dnsPolicies.map((policyContainer, i) => {
                return dropDownUtils.createOption(i, policyContainer.getPolicyName());
            });
        }

        if (Array.isArray(topologyPolicies)) {
            $scope.topologyPolicySelectOptions = topologyPolicies.map((policyContainer, i) => {
                return dropDownUtils.createOption(i, policyContainer.getPolicyName());
            });
        }

        if (vs.isVHChild()) {
            setVSEndPointCollectionVHParams();
        }

        setApplicationProfileVHParams();
    };

    /**
     * Called by setCloud.
     */
    function afterCloudInit() {
        const
            {
                editable: vs,
                Cloud: cloud,
            } = $scope,
            cloudConfig = cloud.getConfig(),
            config = vs.getConfig();

        const collectionsToSetDefaults = [
            $scope.SEGroupCollection,
            $scope.poolCollection,
            $scope.poolGroupCollection,
            $scope.trafficCloneProfileCollection,
            $scope.icapProfileCollection,
            $scope.vsVipCollection,
        ];

        const cloudRef = cloud.getRef();
        const { id: cloudId } = cloud;

        collectionsToSetDefaults.forEach(collection => {
            collection.setDefaultItemConfigProps({ cloud_ref: cloudRef });
        });

        collectionsToSetDefaults.forEach(collection => {
            collection.setParams({ 'cloud_ref.uuid': cloudId });
        });

        [
            $scope.networks,
            $scope.poolNetworks,
        ]
            .forEach(collection => collection.setParams({ cloud_uuid: cloudId }));

        vs.getVSVip().setCloudRef(cloudRef);

        if (isVlanNsxtCloud()) {
            $scope.networks.setParams({ discovered_only: undefined });
        }

        config.cloud_type = cloudConfig.vtype;

        $scope.isVrfOptional = !cloud.allowCustomVRFContext();

        // skipping manual VRF context selection
        if (vs.id || vs.getVRFContextRef() || $scope.isVrfOptional) {
            $scope.onVRFContextSet();
        } //else user has to select VRFContext
    }

    const setCloud = function() {
        $scope.ui.cloudInitIsActive = true;
        $scope.editable.errors = null;

        $scope.Cloud = new Cloud({
            id: $scope.editable.getCloudRef().slug(),
            params: {
                include_name: true,
                join: 'ipamdnsproviderprofile:ipam_provider_ref',
            },
        });

        return $scope.Cloud.load()
            .then(() => {
                afterCloudInit();
                $scope.ui.cloudIsSet = true;
            })
            .catch(({ data }) => $scope.editable.errors = data)
            .finally(() => $scope.ui.cloudInitIsActive = false);
    };

    /**
     * ngClick wrapper for inner setCloud function.
     */
    $scope.setCloud = () => setCloud().then(
        () => $scope.editable.setPristine(),
    );

    /**
     * Sets ui.vrfContextIsSet flag. Also sets params of networks if vrf_context exists.
     */
    $scope.onVRFContextSet = function() {
        const
            { editable: vs } = $scope,
            vrfContextRef = vs.getVRFContextRef();

        if (vrfContextRef) {
            const vrfContextId = vrfContextRef.slug();

            const networkCollectionOptions = {
                vrf_context_uuid: vrfContextId,
            };

            const {
                poolCollection,
                poolNetworks,
                networks,
                vsVipCollection,
            } = $scope;

            //FIXME switch this on once AV-45712 is done
            /*poolCollection.setParams({
                refers_to: `vrfcontext:${vrfContextId}`,
                depth: 1
            });

            poolGroupCollection.setParams({
                refers_to: `vrfcontext:${vrfContextId}`,
                depth: 1
            });*/

            poolCollection.setDefaultItemConfigProps({ vrf_ref: vrfContextRef });

            vsVipCollection.setDefaultItemConfigProps({
                vrf_context_ref: vrfContextRef,
            });

            poolNetworks.setParams(networkCollectionOptions);

            networks.setParams(networkCollectionOptions);
        }

        $scope.ui.vrfContextIsSet = true;
    };

    $scope.unlockAllSteps = function() {
        _.each($scope.wizard.steps, function(step) {
            step.unlocked = true;
        });
    };

    const [, vsServicesLimit] = schemaService
        .getRepeatedFieldRangeAsTuple('VirtualService', 'services');

    $scope.vsServicesLimit = vsServicesLimit;

    const stringOperationEnumObjects = schemaService.getEnumValues('StringOperation');

    $scope.activeStandbySeTagEnumValues = schemaService.getEnumValues('ActiveStandbySeTag');

    // remove not supported Regex checking in URL patterns when inserting RUM
    $scope.urlMatchOperations = stringOperationEnumObjects.filter(
        ({ value }) => !value.startsWith('REGEX'),
    );

    $scope.resetSubObjects = function() {
        $scope.editMode = {
            requestPolicy: null,
            responsePolicy: null,
            securityPolicy: null,
            networkSecurityPolicy: null,
            datascriptPolicy: null,
            logFilter: null,
        };
    };

    $scope.gotoTab = function(tabIndex) {
        if (!confirmDiscardSubObject()) {
            return;
        }

        $scope.resetSubObjects();
        // Clear errors if exist
        $scope.editable.errors = null;
        $scope.wizard.current = tabIndex;
    };

    /**
     * Called by subtabNav component on tab change.
     */
    $scope.handleSubtabChange = function() {
        if (!confirmDiscardSubObject()) {
            return;
        }

        $scope.resetSubObjects();
        // Clear errors if exist
        $scope.editable.errors = null;
    };

    /**
     * Renders a confirmation dialog for the user while he is editing policies and wants to move
     * from the active tab.
     * @returns {boolean}
     * @inner
     */
    function confirmDiscardSubObject() {
        const { editMode } = $scope;

        let message = '';

        if (editMode.securityPolicy ||
            editMode.requestPolicy ||
            editMode.responsePolicy ||
            editMode.networkSecurityPolicy ||
            editMode.datascriptPolicy) {
            message = l10nService.getMessage(l10nKeys.discardPolicyChangesMessage);
        } else if (editMode.logFilter) {
            message = l10nService.getMessage(l10nKeys.discardClientFilterChangesMessage);
        }

        return message ? $window.confirm(message) : true;
    }

    /**
     * Updates NetworkProfile collection params (filters by type) and sets default values for
     * the new ones.
     * @param {string[]} profileTypes
     * @inner
     */
    function setNetworkProfileCollectionParams(profileTypes) {
        const { networkProfileCollection: collection } = $scope;

        let defaultProfileType = 'PROTOCOL_TYPE_TCP_PROXY';

        // no filter needed if all types are avail
        if (profileTypes.length === NetworkProfile.types.length) {
            collection.setParams({ 'profile.type': undefined });
        } else {
            collection.setParams({ 'profile.type': profileTypes.join() });
            [defaultProfileType] = profileTypes;
        }

        collection.setDefaultItemConfigProps({
            profile: { type: defaultProfileType },
        });
    }

    /**
     * Sets default port for newly created pools.
     * @param {number=} port
     * @inner
     */
    function setPoolCollectionParams(port = defaultPoolServerPort) {
        const { editable: vs } = $scope;

        // HTTPS VS service port is 443 but 80 is used for HTTP backend (pool)
        if (vs.isHTTPS()) {
            port = defaultPoolServerPort;
        }

        $scope.poolCollection.setDefaultItemConfigProps({
            default_server_port: port,
        });
    }

    /**
     * Updates various collections properties and ui (form) flags based on selected VS
     * application profile type. Called on init as well.
     */
    function updateModalSettingsAfterAppProfileChange() {
        const
            { editable: vs } = $scope,
            appProfileType = vs.appType(),
            allowedNetProfileTypes =
                ApplicationProfile.getAllowedNetProfileTypes(appProfileType, NetworkProfile.types);

        $scope.ui.isVHVS = vs.getConfig()['type'] !== 'VS_TYPE_NORMAL';

        setNetworkProfileCollectionParams(allowedNetProfileTypes);

        setPoolCollectionParams(vs.getDefaultServicePort());

        $scope.setWizardBasedOnDns();
    }

    /**
     * Sets selected application profile on VS instance.
     * Called on application profile selection event.
     * @param {ApplicationProfile} appProfile
     */
    $scope.onAppProfileChange = function(appProfile) {
        const { editable: vs } = $scope;

        vs.setAppProfile(appProfile.getRef(), appProfile.getConfig())
            .finally(updateModalSettingsAfterAppProfileChange);
    };

    /**
     * Event handler for network profile change.
     * @param {NetworkProfile} netProfile
     * @public
     */
    $scope.onNetworkProfileChange = function(netProfile) {
        const config = $scope.editable.getConfig();

        config['network_profile_ref_data'] = netProfile.getConfig();
    };

    /**
     * Sets VS Type as Child or Normal. Called on ng-change for Virtual Hosting VS checkbox.
     * @public
     **/
    $scope.onVSTypeToggle = function() {
        const config = $scope.editable.getConfig();

        if ($scope.ui.isVHVS) {
            $scope.editable.setType(VS_TYPE_VH_CHILD);
            $scope.onVSTypeChange('child');
        } else {
            const { type: prevType } = config;

            $scope.editable.setType(VS_TYPE_NORMAL);

            if (prevType === VS_TYPE_VH_CHILD) {
                $scope.editable.setDefaultService();
            }

            $scope.onVHTypeChange();
        }

        setApplicationProfileVHParams();
    };

    /**
     * Called when Virtual Hosting VS type is changed from Parent to Child and vice versa.
     * @param {string} type - 'child' or 'parent'.
     * @public
     */
    $scope.onVSTypeChange = function(type) {
        const { editable: vs } = $scope;
        const config = vs.getConfig();

        switch (type) {
            case 'child':
                $scope.editable.setType(VS_TYPE_VH_CHILD);
                config.services.length = 0;
                config.ssl_profile_ref = undefined;
                break;

            case 'parent': {
                $scope.editable.setType(VS_TYPE_VH_PARENT);
                vs.setDefaultService(443);

                if (!config.ssl_profile_ref) {
                    config.ssl_profile_ref = defaultValues.getSystemObjectRefByName(
                        'sslprofile',
                        'System-Standard',
                    );
                }

                break;
            }
        }

        setApplicationProfileVHParams();
        $scope.onVHTypeChange();
    };

    /**
     * Called when the Virtual Hosting Type has changed between SNI or Enhanced.
     */
    $scope.onVHTypeChange = () => {
        $scope.editable.onVHTypeChange();
        setVSEndPointCollectionVHParams();
    };

    $scope.serverNetworkProfileDisabled = function() {
        return $scope.getNetProfileType() !== 'PROTOCOL_TYPE_TCP_PROXY';
    };

    $scope.sslDisabled = function() {
        if (!$scope.editable || !$scope.editable.data || !$scope.editable.data.config) {
            return false;
        }

        return !_.find($scope.editable.data.config.services, function(port) {
            return port.enable_ssl;
        });
    };

    $scope.addPort = function() {
        const
            { editable: vs } = $scope,
            { constructor: VirtualService } = vs,
            { services } = vs.getConfig(),
            [{ enable_ssl: enableSsl, nwOverride }] = services.slice(-1);

        services.push(
            VirtualService.getDefaultServiceConfig({
                port: null,
                enable_ssl: enableSsl,
                nwOverride,
            }),
        );

        /**
         * Need to manually shift the focus to the newly added element on click of Add Port button.
         * Using set timeout to ensure the latest element is rendered on the UI
         * by the time we try to find it else document.getElementById will always return null.
         */
        setTimeout(() => {
            if ($scope.ui.basicServicePort) {
                document.getElementById(`port-basic-${services.length - 1}`).focus();
            } else {
                document.getElementById(`port-advanced-${services.length - 1}`).focus();
            }
        });
    };

    $scope.removePort = function(serviceToRemove) {
        const { services } = $scope.editable.data.config;

        if (services.length > 1) {
            const pos = services.indexOf(serviceToRemove);

            if (pos !== -1) {
                services.splice(pos, 1);
            }
        }
    };

    /**
     * ngClick callback for the UI checkbox "enable rate limiters".
     * @type {function}
     */
    $scope.rateLimitersSwitch = function() {
        const defaultRateLimitConfig = {
            action: {
                type: 'RL_ACTION_NONE',
            },
        };

        if ($scope.ui.activeRateLimiters) {
            $scope.editable.data.config.requests_rate_limit =
                angular.copy(defaultRateLimitConfig);

            $scope.editable.data.config.connections_rate_limit =
                angular.copy(defaultRateLimitConfig);
        } else {
            $scope.editable.data.config.requests_rate_limit = undefined;
            $scope.editable.data.config.connections_rate_limit = undefined;
            $scope.editable.data.config.performance_limits = undefined;
        }
    };

    $scope.setServicePort = function(service) {
        if (service.port > service.port_range_end) {
            service.port_range_end = service.port;
        }
    };

    /**
     * Returns a string of ports and port ranges to be displayed in the Review tab of VS
     * creation.
     * @return {string} List of port and port ranges along with SSL enabled property.
     */
    $scope.getPortsList = function() {
        const ports = $scope.editable.data.config.services.map(function(service) {
            let output = '';

            if (service.port === service.port_range_end) {
                output += service.port;
            } else {
                output += `${service.port}-${service.port_range_end}`;
            }

            if (service.enable_ssl) {
                output += ' (SSL Enabled)';
            }

            return output;
        });

        return ports.join(', ');
    };

    /**
     * Sets SEGroup from SEGroupCollection to be used for setting Active Standby SE Tag.
     */
    $scope.setSEGroup = function() {
        const { config } = $scope.editable.data;

        $scope.seGroup = config.se_group_ref ?
            $scope.SEGroupCollection.getItemById(config.se_group_ref.slug()) : undefined;
    };

    /**
     * Called when user checks the SSL checkbox when configuring port numbers. If the port is 80 and
     * the user checks SSL, we change it to 443, and vice versa.
     * @param  {Object} service - Service port object.
     */
    $scope.handleSSLPortChange = function(service) {
        if (service.enable_ssl && service.port === 80 && service.port_range_end === 80) {
            service.port_range_end = 443;
            service.port = 443;
        } else if (!service.enable_ssl && service.port === 443 && service.port_range_end === 443) {
            service.port_range_end = 80;
            service.port = 80;
        }
    };

    /**
     * Called when user switches between adding a Pool or Pool Group. Removes the existing value.
     */
    $scope.handlePoolOrPoolGroupChange = () => {
        const { config } = $scope.editable.data;

        delete config.pool_ref;
        delete config.pool_group_ref;
    };

    /**
     * Checks if VS has at least one service with port number.
     * @return {boolean}
     */
    $scope.validateServices = function() {
        if (!$scope.editable) {
            return false;
        }

        return _.any($scope.editable.data.config.services, ({ port }) => { return port >= 0; });
    };

    /**
     * Returns true if the VIP-Placement settings should be shown, allowing the user to add
     * placement subnet.
     * @return {boolean}
     */
    $scope.showVipPlacementSettings = function() {
        const vtype = $scope.Cloud.getVtype();

        return cloudsWithVipPlacement.has(vtype) || isVlanNsxtCloud();
    };

    /**
     * We want to set default names for pool and poolGroup created from current modal window.
     * @public
     */
    $scope.onNameChange = function() {
        const vsName = $scope.editable.getName();
        const newName = vsName ? vsName.replace(/:/g, '') : '';

        $scope.poolCollection.setDefaultItemConfigProps({ name: `${newName}-Pool` });
        $scope.poolGroupCollection.setDefaultItemConfigProps({ name: `${newName}-PoolGroup` });
        $scope.vsVipCollection.setDefaultItemConfigProps({ name: `${newName}-VsVip` });
    };

    /**
     * Returns an application profile type.
     * @returns {string} - app profile type enum
     * @public
     */
    $scope.getAppProfileType = function() {
        return $scope.editable.getAppProfileConfig()['type'];
    };

    /**
     * Returns a network profile type.
     * @returns {*}
     */
    $scope.getNetProfileType = function() {
        return $scope.editable.getDefaultNetProfileType();
    };

    /**
     * Returns true if VS has HTTP application profile type.
     * HTTP VSes are of L7 type.
     * @returns {boolean}
     * @public
     */
    $scope.isHTTPVS = function() {
        return $scope.editable.appType() === 'http';
    };

    /**
     * Checkbox click event handler for the service NetworkProfile override option.
     * @param {Object} service
     * @public
     */
    $scope.switchServiceOverrideNetworkProfile = function(service) {
        if (!service.nwOverride) {
            service['override_network_profile_ref'] = undefined;
        }
    };

    /**
     * If the VS is of type DNS, an additional tab called 'Static DNS Records' is shown in the
     * wizard.
     */
    $scope.setWizardBasedOnDns = () => {
        const { wizard } = $scope;
        const steps = [{
            title: l10nService.getMessage(l10nKeys.tabLabelSettings),
        }, {
            title: l10nService.getMessage(l10nKeys.tabLabelPolicies),
        }, {
            title: l10nService.getMessage(globalL10nKeys.analyticsLabel),
        }, {
            title: l10nService.getMessage(l10nKeys.tabLabelAdvanced),
        }];

        if ($scope.editable.isDNS()) {
            wizard.steps = steps.concat({
                title: l10nService.getMessage(l10nKeys.tabLabelStaticDnsRecords),
            });
        } else {
            wizard.steps = steps;
        }
    };

    /**
     * Set service ports for validation purpose of policy service port match criterion.
     * Use parent VS service ports instead for a child VS since the latter doesn't have service port
     * configured.
     * @return {object}
     */
    $scope.getServicePorts = () => {
        if (!$scope.editable.isVHChild()) {
            return $scope.editable.getConfig().services;
        } else {
            return $scope.editable.parentVsServicePorts;
        }
    };

    /**
     * If a service has override_application_profile_ref, return
     * networkProfileCollection without filters.
     * otherwise return networkProfileCollection with filters.
     * @param {Object} service
     * @returns {NetworkProfileCollection}
     */
    $scope.getServiceNetworkProfileCollection = service => {
        if (service.override_application_profile_ref) {
            return $scope.serviceNetworkProfileCollection;
        } else {
            return $scope.networkProfileCollection;
        }
    };

    /**
     * Check if the save button should be disabled.
     * @returns {boolean}
     */
    $scope.isSaveButtonDisabled = function() {
        const { forms, editable, isEnhancedVHChildWithNoVHMatches } = $scope;

        return forms.modalForm.$invalid || !editable.isVHChild() &&
            !$scope.validateServices() || isEnhancedVHChildWithNoVHMatches();
    };

    /**
     * Check if the next button should be disabled.
     * @returns {boolean}
     */
    $scope.isNextButtonDisabled = function() {
        const { wizard, forms, isEnhancedVHChildWithNoVHMatches } = $scope;

        return wizard.current === 0 &&
            (forms.settingsForm.$invalid || isEnhancedVHChildWithNoVHMatches()) ||
            wizard.current === 1 && forms.policyForm.$invalid;
    };

    /**
     * Checks if the VS is a Enhanced VH Child and and has no VH Matches configured for it.
     * @returns {boolean}
     */
    $scope.isEnhancedVHChildWithNoVHMatches = function() {
        const { editable } = $scope;

        return editable.isEnhancedVHChild() && !editable.config.vh_matches.count;
    };

    $scope.$on('$destroy', () => {
        const collections = [
            $scope.analyticsProfileCollection,
            $scope.applicationProfileCollection,
            $scope.certificateCollection,
            $scope.cloudCollection,
            $scope.errorPageProfCollection,
            $scope.ipAddrGroupCollection,
            $scope.networkProfileCollection,
            $scope.networks,
            $scope.poolCollection,
            $scope.poolGroupCollection,
            $scope.poolNetworks,
            $scope.SEGroupCollection,
            $scope.serverNetworkProfileCollection,
            $scope.sslProfileCollection,
            $scope.stringGroupCollection,
            $scope.trafficCloneProfileCollection,
            $scope.vsEndPointCollection,
            $scope.wafPolicyCollection,
            $scope.icapProfileCollection,
            $scope.botDetectionPolicyCollection,
            $scope.vsVipCollection,
            $scope.l4ApplicationProfileCollection,
            $scope.serviceNetworkProfileCollection,
            $scope.csrfPolicyCollection,
        ];

        collections.forEach(collection => collection.destroy());

        base.cancelRequests();
    });
}

virtualServiceCreateController.$inject = [
    '$controller',
    '$q',
    '$scope',
    '$window',
    'AnalyticsProfileCollection',
    'ApplicationProfile',
    'ApplicationProfileCollection',
    'Base',
    'Cloud',
    'defaultValues',
    'dropDownUtils',
    'ErrorPageProfileCollection',
    'IcapProfileCollection',
    'NetworkProfile',
    'NetworkProfileCollection',
    'PoolGroupCollection',
    'schemaService',
    'SEGroupCollection',
    'SSLProfileCollection',
    'StringGroupCollection',
    'TrafficCloneProfileCollection',
    'VirtualServiceCollection',
    'WafPolicyCollection',
    'BotDetectionPolicyCollection',
    'l10nService',
    'VsVipCollection',
    CSRF_POLICY_COLLECTION_TOKEN,
];
