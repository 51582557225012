<avi-fieldset class="vs-snat-ip-address">
    <avi-fieldset_header>
        <avi-radio-container [noMarginTop]="true">
            <label aviLabelWithTooltip>
                {{ l10nKeys.snatIpAddressLabel | vtranslate }}
            </label>
            <avi-radio
                name="snat-ip"
                [value]="ipAddrVersion.V4_ONLY"
                [(ngModel)]="snatIpAddressVersion"
                (ngModelChange)="onSnatIpVersionChange()"
            >
                {{ globalL10nKeys.ipv4Label | vtranslate }}
            </avi-radio>
            <avi-radio
                name="snat-ip"
                [value]="ipAddrVersion.V6_ONLY"
                [(ngModel)]="snatIpAddressVersion"
                (ngModelChange)="onSnatIpVersionChange()"
            >
                {{ globalL10nKeys.ipv6Label | vtranslate }}
            </avi-radio>
            <avi-radio
                name="snat-ip"
                [value]="ipAddrVersion.V4_V6"
                [(ngModel)]="snatIpAddressVersion"
                (ngModelChange)="onSnatIpVersionChange()"
            >
                {{ l10nKeys.bothLabel | vtranslate }}
            </avi-radio>
        </avi-radio-container>
    </avi-fieldset_header>
    <avi-fieldset_content>
        <avi-data-grid
            *ngIf="snatIpAddressVersion === ipAddrVersion.V4_V6"
            [config]="snatIpV4AndV6GridConfig"
            [rows]="snatIpGridRows"
            class="vs-snat-ip-address__avi-data-grid"
        >
            <avi-data-grid_actions>
                <cds-button
                    (click)="addNewSnatIpAddress()"
                    size="sm"
                >
                    {{ globalL10nKeys.addLabel | vtranslate }}
                </cds-button>
            </avi-data-grid_actions>
        </avi-data-grid>

        <avi-data-grid
            *ngIf="snatIpAddressVersion === ipAddrVersion.V4_ONLY"
            [config]="snatIpV4GridConfig"
            [rows]="snatIpGridRows"
            class="vs-snat-ip-address__avi-data-grid"
        >
            <avi-data-grid_actions>
                <cds-button
                    (click)="addNewSnatIpAddress()"
                    size="sm"
                >
                    {{ globalL10nKeys.addLabel | vtranslate }}
                </cds-button>
            </avi-data-grid_actions>
        </avi-data-grid>

        <avi-data-grid
            *ngIf="snatIpAddressVersion === ipAddrVersion.V6_ONLY"
            [config]="snatIpV6GridConfig"
            [rows]="snatIpGridRows"
            class="vs-snat-ip-address__avi-data-grid"
        >
            <avi-data-grid_actions>
                <cds-button
                    (click)="addNewSnatIpAddress()"
                    size="sm"
                >
                    {{ globalL10nKeys.addLabel | vtranslate }}
                </cds-button>
            </avi-data-grid_actions>
        </avi-data-grid>
    </avi-fieldset_content>
</avi-fieldset>

<ng-template
    #snatIpV4AddressTemplateRef
    let-row
    let-index="index"
>
    <avi-input-container noMarginTop>
        <input
            clrInput
            regexPattern="ip"
            name="snat-ipv4"
            fieldName="snat_ip"
            [objectType]="objectType"
            [placeholder]="globalL10nKeys.ipv4AddressPlaceholderLabel | vtranslate"
            [(ngModel)]="row.V4"
            (ngModelChange)="onSnatIpAddressUpdate(index, ipAddrType.V4)"
            required
        />
    </avi-input-container>
</ng-template>

<ng-template
    #snatIpV6AddressTemplateRef
    let-row
    let-index="index"
>
    <avi-input-container noMarginTop>
        <input
            clrInput
            regexPattern="ipv6"
            name="snat-ipv6"
            fieldName="snat_ip6_addresses"
            [objectType]="objectType"
            [placeholder]="globalL10nKeys.ipv6AddressPlaceholderLabel | vtranslate"
            [(ngModel)]="row.V6"
            (ngModelChange)="onSnatIpAddressUpdate(index, ipAddrType.V6)"
            required
        />
    </avi-input-container>
</ng-template>
