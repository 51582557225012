/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './virtualserviceSecuritySslPatches.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceSecuritySslPatches
 * @param {function} closeModal - Closes modal.
 * @description  Displays OpenSSL patches changelog information.
 */
class Controller {
    constructor(VirtualServiceSecurityService, l10nService) {
        this.securityService_ = VirtualServiceSecurityService;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.busy = true;

        this.securityService_.getSSLPatches()
            .then(response => this.patches = response)
            .finally(() => this.busy = false);
    }

    $onDestroy() {
        this.securityService_.cancelGetSSLPatches();
    }
}

Controller.$inject = [
        'VirtualServiceSecurityService',
        'l10nService',
];

angular.module('aviApp').component('virtualserviceSecuritySslPatches', {
    bindings: {
        closeModal: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/modals/applications/virtualservice/' +
            'virtualservice-security-ssl-patches/virtualservice-security-ssl-patches.html',
});
