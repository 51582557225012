<div
    class="jwt-claim-match-config"
    provideParentForm
>
    <div class="grid-container jwt-claim-match-config__general-container">
        <div [ngClass]="showDeleteIcon ? 'grid-container__cell--w--8' : 'grid-container__cell--w--9'">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="{{ 'name-' + id }}"
                    required
                >
                    {{ l10nKeys.claimNameLabel | vtranslate }}
                </label>
                <input
                    id="{{ 'name-' + id }}"
                    name="{{ 'name-' + id }}"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ l10nKeys.claimNamePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>
        </div>

        <div class="grid-container__cell--w--3">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="type"
                for="{{ 'type-' + id }}"
                required
            >
                {{ l10nKeys.typeDropdownLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                id="{{ 'type-' + id }}"
                name="{{ 'type-' + id }}"
                [(ngModel)]="editable.config.type"
                (ngModelChange)="onTypeChange()"
                enum="JWTClaimType"
                required
            ></avi-enum-dropdown>
        </div>

        <div
            class="grid-container__cell--w--1 jwt-claim-match-config__delete-icon-container"
            *ngIf="showDeleteIcon"
        >
            <cds-icon
                class="jwt-claim-match-config__remove-icon"
                shape="trash"
                size="18"
                status="info"
                (click)="deleteClaim()"
            ></cds-icon>
        </div>
    </div>

    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input
                clrCheckbox
                type="checkbox"
                name="{{ 'is-mandatory-' + id }}"
                id="{{ 'is-mandatory-' + id }}"
                [(ngModel)]="editable.config.is_mandatory"
            />
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="is_mandatory"
                for="{{ 'is-mandatory-' + id }}"
            >
                {{ l10nKeys.mandatoryLabel | vtranslate }}
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-fieldset [hideContent]="!editable.config.validate || !editable.config.type">
                <avi-fieldset_header>
                    <clr-checkbox-container
                        clrInline
                        class="jwt-claim-match-config__checkbox-container"
                    >
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                id="{{ 'validate-' + id }}"
                                [(ngModel)]="editable.config.validate"
                                name="{{ 'validate-' + id }}"
                            />
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="validate"
                                for="{{ 'validate-' + id }}"
                            >
                                {{ l10nKeys.validateLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </avi-fieldset_header>

                <avi-fieldset_content>
                    <ng-container *ngIf="editable.config.validate && editable.config.type">
                        <div
                            [ngSwitch]="editable.config.type"
                            class="jwt-claim-match-config__claim-value-container"
                        >
                            <string-match-config
                                id="{{ 'jwt-string-match-' + id }}"
                                *ngSwitchCase="jwtClaimTypeHash.JWT_CLAIM_TYPE_STRING"
                                [editable]="editable.config.string_match"
                                stringLabel="{{ l10nKeys.claimValueLabel | vtranslate }}"
                            ></string-match-config>

                            <ng-container *ngSwitchCase="jwtClaimTypeHash.JWT_CLAIM_TYPE_INT">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="int_match"
                                        for="{{ 'int-match-' + id }}"
                                        required
                                    >
                                        {{ l10nKeys.claimValueLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="{{ 'int-match-' + id }}"
                                        name="{{ 'int-match-' + id }}"
                                        type="number"
                                        [(ngModel)]="editable.config.int_match"
                                        clrInput
                                        placeholder="{{ l10nKeys.integerPlaceholder | vtranslate }}"
                                        required
                                    />
                                </avi-input-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="jwtClaimTypeHash.JWT_CLAIM_TYPE_BOOL">
                                <avi-radio-container [noMarginTop]="true">
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="bool_match"
                                        for="{{ 'bool-match-' + id }}"
                                        required
                                    >
                                        {{ l10nKeys.claimValueMatchLabel | vtranslate }}
                                    </label>
                                    <avi-radio
                                        name="{{ 'bool-match-' + id }}"
                                        [(ngModel)]="editable.config.bool_match"
                                        [value]="true"
                                        [required]="true"
                                    >
                                        {{ l10nKeys.trueLabel | vtranslate }}
                                    </avi-radio>
                                    <avi-radio
                                        name="{{ 'bool-match-' + id }}"
                                        [(ngModel)]="editable.config.bool_match"
                                        [value]="false"
                                        [required]="true"
                                    >
                                        {{ l10nKeys.falseLabel | vtranslate }}
                                    </avi-radio>
                                </avi-radio-container>
                            </ng-container>
                        </div>
                    </ng-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
