/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isString, isUndefined } from 'underscore';
import { Component, Type } from '@angular/core';
import {
    FullModalService,
    FULL_MODAL_SERVICE_TOKEN,
    IFullModalLayout,
} from 'full-modal-service';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { ItemPreviewWrapperComponent } from
    'ng/shared/components/item-preview-wrapper/item-preview-wrapper.component';
import { Constructor } from '../../../declarations/globals.d';

export interface IItemParams {
    editable: ObjectTypeItem | Item | MessageItem;
    viewMode?: boolean;
}

/**
 * @description
 *     Mixin to extend a BaseClass with methods for rendering a full modal component. The reason
 *     this is a mixin rather than a subclass is so we don't have to limit the superclass to
 *     being an ObjectTypeItem or an Item. Supports ES5 and ES6 classes only. Pure functions are not
 *     supported.
 * @author alextsg
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function withFullModalMixin<T extends Constructor<AjsDependency>>(BaseClass: T) {
    return class FullModalItem extends BaseClass {
        public static ajsDependencies = [
            FULL_MODAL_SERVICE_TOKEN,
        ];

        /**
         * Modal component to open.
         */
        protected readonly windowElement: TWindowElement;

        /**
         * ObjectType defined in the protobuf.
         */
        protected readonly objectType: string;

        constructor(...args: any[]) {
            super(...args);

            const [{ windowElement, objectType }] = args;

            this.windowElement = windowElement || this.windowElement;
            this.objectType = objectType || this.objectType;

            if (isUndefined(this.objectType)) {
                throw new Error('FullModal can\'t be used without objectType');
            }
        }

        /**
         * Returns the modal component as a promise. This has been abstracted out to allow for
         * asynchonously importing the modal component for lazy loading.
         */
        public getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
            if (isString(windowElement)) {
                throw new Error('Trying to use fullModalService with a string type windowElement');
            }

            return Promise.resolve(windowElement as Type<Component>);
        }

        /**
         * Returns the preview component as a promise. This has been abstracted out to allow for
         * asynchonously importing the preview component for lazy loading.
         */
        public getPreviewComponent(): Promise<Type<Component>> {
            return Promise.resolve(ItemPreviewWrapperComponent as Type<Component>);
        }

        /**
         * @override
         */
        public async openModal(
            windowElement: TWindowElement = this.windowElement,
            params: IItemParams,
        ): Promise<void> {
            const modalComponent = await this.getModalComponent(windowElement);

            const fullModalService: FullModalService =
                this.getAjsDependency_(FULL_MODAL_SERVICE_TOKEN);

            const modalProps = await this.getFullModalProps(params, modalComponent);

            // When the layout stack is empty, the first modal opened sets the initial viewMode.
            // Subsequent modals will follow the existing viewMode value.
            if (!fullModalService.modalLayoutStack.length) {
                fullModalService.setViewMode(params.viewMode);
            }

            fullModalService.addModal(modalProps);
        }

        /**
         * @override
         */
        public async closeModal(windowElement: TWindowElement = this.windowElement): Promise<void> {
            const modalComponent = await this.getModalComponent(windowElement);

            const fullModalService: FullModalService =
                this.getAjsDependency_(FULL_MODAL_SERVICE_TOKEN);

            fullModalService.removeModalByComponent(modalComponent);
        }

        /**
         * Returns props to be passed to the FullModal.
         * @param params - Params passed to Item.openModal.
         * @param modalComponent - Full modal component to be opened.
         */
        protected async getFullModalProps(
            params: IItemParams,
            modalComponent?: Type<Component>,
        ): Promise<IFullModalLayout> {
            const previewComponent = await this.getPreviewComponent();

            return {
                component: modalComponent,
                componentProps: {
                    ...params,
                },
                getDescription: () => this.getModalBreadcrumbDescription(params),
                getName: () => this.getModalBreadcrumbTitle(params),
                previewComponent,
                previewComponentProps: {
                    config: params.editable.getConfig(),
                    editable: params.editable,
                    isItem: Boolean(params.editable instanceof Item),
                },
            };
        }

        /**
         * Returns the string to be displayed as the breadcrumb title.
         */
        protected getModalBreadcrumbTitle(params: IItemParams): string {
            return this.objectType;
        }

        /**
         * Returns the string to be displayed as the breadcrumb description.
         */
        protected getModalBreadcrumbDescription(params: IItemParams): string {
            return params.editable.getName();
        }
    };
}
