/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import { Inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
    BehaviorSubject,
    forkJoin,
    of,
    pipe,
} from 'rxjs';
import {
    concat,
    concatMap,
    delay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { IHttpResponse } from 'angular';

import {
    ClusterStateValue,
    ICluster,
    IClusterState,
    IClusterVipRuntime,
} from 'generated-types';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

import {
    HttpMethod,
    HttpWrapper,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

/**
 * State properties fetched from api/cluster.
 */
interface IClusterResponse {
    name: string;
    clusterVipAddressV4: string;
    clusterVipAddressV6: string;
}

/**
 * State properties fetched from api/cluster/runtime.
 */
interface IClusterRuntimeResponse {
    clusterState: ClusterStateValue;
    clusterVipRuntimeStatus: IClusterVipRuntime;
}

/**
 * State definition, combining properties from api/cluster and api/cluster/runtime. Also includes
 * the loading state.
 */
interface IControllerWidgetStoreState extends IClusterResponse, IClusterRuntimeResponse {
    loading: boolean;
}

const initialState: IControllerWidgetStoreState = {
    name: '',
    clusterVipAddressV4: '',
    clusterVipAddressV6: '',
    clusterState: undefined,
    clusterVipRuntimeStatus: undefined,
    loading: false,
};

const CLUSTER_URL = '/api/cluster';
const CLUSTER_RUNTIME_URL = `${CLUSTER_URL}/runtime`;
const POLLING_INTERVAL = 30000;

/**
 * @description
 * Store for the ControllerWidgetComponent. Fetches information about the cluster and cluster
 * runtime.
 * @author alextsg
 */
@Injectable()
export class ControllerWidgetStore extends ComponentStore<IControllerWidgetStoreState> {
    public readonly loading$ = this.select(state => state.loading);

    public readonly name$ = this.select(state => state.name);

    public readonly clusterVipAddressV4$ = this.select(state => state.clusterVipAddressV4);

    public readonly clusterVipAddressV6$ = this.select(state => state.clusterVipAddressV6);

    public readonly clusterState$ = this.select(state => state.clusterState);

    public readonly clusterVipRuntimeStatus$ = this.select(state => state.clusterVipRuntimeStatus);

    public readonly fetchControllerData = this.effect<void>(pipe(
        switchMap(() => this.pollClusterRequests$),
    ));

    private readonly httpWrapper: HttpWrapper;

    /**
     * clusterRequests$, delayClusterRequests$, and pollClusterRequests$ are used in combination to
     * poll for the cluster and cluster runtime status. They're needed in order to create a stream
     * that polls for new data 30 seconds after the response has returned and not after the request
     * has been made.
     */

    /**
     * Trigger for making the cluster API requests.
     */
    private clusterRequests$ = new BehaviorSubject(true);

    /**
     * Emits a new event on clusterRequests$ after a delay.
     */
    private delayClusterRequests$ = of(true).pipe(
        delay(POLLING_INTERVAL),
        tap(() => this.clusterRequests$.next(true)),
    );

    /**
     * When there's an incoming event on clusterRequests$, makes API requests for the cluster and
     * cluster runtime data, then after the responses are returned concats with
     * delayClusterRequests$, which will emit an event on clusterRequests$ after a delay, creating
     * an endless polling loop. This also sets the loading state and sets properties on state.
     */
    private pollClusterRequests$ = this.clusterRequests$.pipe(
        tap(() => this.patchState({ loading: true })),
        concatMap(() => forkJoin([this.fetchCluster(), this.fetchClusterRuntime()]).pipe(
            tapResponse(
                ([cluster, clusterRuntime]) => {
                    this.patchState({ ...cluster });
                    this.patchState({ ...clusterRuntime });
                },
                // TODO: Add error handling when UX is available. AV-175009
                error => this.devLoggerService.error(error),
                () => this.patchState({ loading: false }),
            ),
            concat(this.delayClusterRequests$),
        )),
    );

    constructor(
        private readonly devLoggerService: DevLoggerService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        super(initialState);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Allows component to cancel ongoing requests, ex. when the user leaves the page.
     */
    public cancelFetchControllerData(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /**
     * Fetch cluster data.
     */
    private async fetchCluster(): Promise<IClusterResponse> {
        const requestConfig = {
            url: CLUSTER_URL,
            method: HttpMethod.GET,
            requestId: 'controller-widget-cluster',
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const {
                name,
                virtual_ip: virtualIp = {},
                virtual_ip6: virtualIp6 = {},
            } = data as ICluster;
            const { addr = '' } = virtualIp;
            const { addr: addr6 = '' } = virtualIp6;

            return {
                name,
                clusterVipAddressV4: addr,
                clusterVipAddressV6: addr6,
            };
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Fetch cluster runtime data.
     */
    private async fetchClusterRuntime(): Promise<IClusterRuntimeResponse> {
        const requestConfig = {
            url: CLUSTER_RUNTIME_URL,
            method: HttpMethod.GET,
            requestId: 'controller-widget-cluster-runtime',
        };

        try {
            const { data } = await this.httpWrapper.request(requestConfig);
            const {
                cluster_state: clusterStateData = {},
                cluster_vip_runtime_status: clusterVipRuntimeStatus = {},
            } = data as IClusterState;

            const { state: clusterState } = clusterStateData;

            return {
                clusterState,
                clusterVipRuntimeStatus,
            };
        } catch (error) {
            this.handleRequestError(error);
        }
    }

    /**
     * Handler for errors during a request. If the request was simply canceled, we don't need to
     * throw an error.
     */
    private handleRequestError(error: IHttpResponse<any>): IHttpResponse<any> | void {
        if (error?.xhrStatus === 'abort') {
            return;
        }

        this.devLoggerService.error(error);

        return error;
    }
}
