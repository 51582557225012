/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'radius-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleRadius = `${prefix}.sectionTitleRadius`;
export const sharedSecretInputLabel = `${prefix}.sharedSecretInputLabel`;
export const sharedSecretInputPlaceholder = `${prefix}.sharedSecretInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleRadius]: 'Radius',
    [sharedSecretInputLabel]: 'Shared Secret',
    [sharedSecretInputPlaceholder]: 'Enter Shared Secret',
};
