<full-modal-config
    modalTitle="{{ l10nKeys.serviceProviderSettingsHeader | vtranslate }}"
    modalSubTitle="{{ profileName }}"
    [hideSubmit]="hideSubmit"
    (onCancel)="dismissModal()"
    (onSubmit)="dismissModal()"
    [valid]="form.valid"
    class="auth-profile-verify-saml-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <div *ngFor="let node of spNodes; trackBy: trackbyIndex">
                <h3>{{ l10nKeys.serviceProviderNodeHeader | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="entity_id"
                                for="entity-id"
                            >
                                {{ l10nKeys.entityIdInputLabel | vtranslate }}
                            </label>
                            <input
                                id="entity-id"
                                name="entity-id"
                                [(ngModel)]="node.config.entity_id"
                                clrInput
                                placeholder="{{ l10nKeys.entityIdInputPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="single_signon_url"
                                for="single-signon-url"
                            >
                                {{ l10nKeys.singleSignonUrlInputLabel | vtranslate }}
                            </label>
                            <input
                                id="single-signon-url"
                                name="single-signon-url"
                                [(ngModel)]="node.config.single_signon_url"
                                clrInput
                                placeholder="{{ l10nKeys.singleSignonUrlInputPlaceholder | vtranslate }}"
                                readonly
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
</full-modal-config>

