/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    Directive,
    ElementRef,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    FullModalComponentService,
} from 'ng/shared/components/full-modal/full-modal-component.service';

import './button.directive.less';

/**
 * @description
 * Directive using cds-button as a selector to watch for viewMode. Once a wrapper has been created
 * around cds-button and button with the same behavior then this can be removed.
 * @author alextsg
 */
@Directive({
    selector: 'cds-button, button',
})
export class ButtonDirective implements OnInit, OnDestroy {
    /**
     * Subscription to the viewMode value.
     */
    private viewModeSubscription: Subscription;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2,
        @Optional()
        private readonly fullModalComponentService: FullModalComponentService,
    ) {}

    public ngOnInit(): void {
        if (this.fullModalComponentService) {
            this.viewModeSubscription = this.fullModalComponentService.viewMode$
                .subscribe(viewMode => {
                    const { nativeElement } = this.elementRef;

                    // Adding and removing the cds-button--view-mode class instead of the [hidden]
                    // attribute to avoid any conflicts. ie. if the cds-button already had [hidden]
                    // before switching to view mode, it should remain [hidden] after switching
                    // out of view mode.
                    if (viewMode) {
                        this.renderer.addClass(nativeElement, 'button--view-mode');
                    } else {
                        this.renderer.removeClass(nativeElement, 'button--view-mode');
                    }
                });
        }
    }

    public ngOnDestroy(): void {
        this.viewModeSubscription?.unsubscribe();
    }
}
