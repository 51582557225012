/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterContentInit,
    Component,
    ContentChild,
    Input,
    OnDestroy,
    OnInit,
    Optional,
} from '@angular/core';
import {
    NgControl,
    ValidationErrors,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import classnames from 'classnames';
import './avi-input-container.component.less';
import { FullModalComponentService } from '../full-modal/full-modal-component.service';

/**
 * @description Component to wrap a label (or avi-label-with-tooltip) and an input element, used to
 *     replace Clarity's clr-input-container component.
 *
 * @author alextsg
 */
@Component({
    selector: 'avi-input-container',
    templateUrl: './avi-input-container.component.html',
})
export class AviInputContainerComponent implements OnInit, AfterContentInit, OnDestroy {
    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * Optional Helper-text.
     * To be displayed below input field.
     */
    @Input()
    public helperText ?= '';

    /**
     * Optional Error text.
     * To be displayed when ngModel becomes invalid.
     */
    @Input()
    public errorText ?= '';

    /**
     * Optional Success text.
     * To be displayed when ngModel becomes valid with some success message to be shown.
     */
    @Input()
    public successText ?= '';

    /**
     * Transcluded ngControl instance.
     */
    @ContentChild(NgControl, { static: true })
    public ngControl: NgControl;

    /**
     * If true, removes the margin-top from the top-level div
     */
    public noMarginTop = false;

    /**
     * Hash of validation errors, coming from the ngControl's errors object. Updated on validation
     * status changes.
     */
    public validationErrors: ValidationErrors;

    /**
     * If true, hides this element including both the label and the input field. This is set to true
     * if the view mode is enabled and the input does not have a value, so that we don't show the
     * placeholder in view mode.
     */
    public hideInput = false;

    /**
     * ngControl statusChanges subscription. Reference stored to unsubscribe when the component is
     * destroyed.
     */
    private statusChangesSubscription: Subscription;

    /**
     * Stores the current value of the input.
     */
    private value: string | number = null;

    /**
     * Subscription to the input value.
     */
    private valueChangesSubscription: Subscription;

    /**
     * If false, show the radio input element. If true, show the readonly view of the selected
     * option.
     */
    private viewMode = false;

    /**
     * Subscription to the viewMode value.
     */
    private viewModeSubscription: Subscription;

    constructor(
        @Optional()
        private readonly fullModalComponentService: FullModalComponentService,
    ) {}

    /** @override */
    public ngOnInit(): void {
        if (this.fullModalComponentService) {
            this.viewModeSubscription = this.fullModalComponentService.viewMode$
                .subscribe(viewMode => {
                    this.viewMode = viewMode;
                    this.setHideInput();
                });
        }
    }

    /** @override */
    public ngAfterContentInit(): void {
        if (this.ngControl) {
            this.statusChangesSubscription = this.ngControl.statusChanges.subscribe(() => {
                this.validationErrors = this.ngControl.control.errors || {};
            });

            this.valueChangesSubscription = this.ngControl.valueChanges.subscribe(value => {
                this.value = value;
                this.setHideInput();
            });
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.statusChangesSubscription?.unsubscribe();
        this.valueChangesSubscription?.unsubscribe();
        this.viewModeSubscription?.unsubscribe();
    }

    /**
     * Returns the classes to be set on the top level div.
     */
    public get topLevelClassNames(): string {
        return classnames(
            'avi-input-container',
            'clr-wrapper',
            this.noMarginTop && 'avi-input-container--no-margin-top',
        );
    }

    /**
     * Returns true if the ngControl is invalid.
     */
    public get invalid(): boolean {
        if (!this.ngControl) {
            return false;
        }

        const { control } = this.ngControl;
        const { dirty, invalid, touched } = control;

        return (touched || dirty) && invalid;
    }

    /**
     * Set the hideInput property.
     */
    private setHideInput(): void {
        this.hideInput = this.viewMode && this.value === undefined;
    }
}
