/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IHttpResponse } from 'angular';

import {
    TaskType,
    VerifyCloudConnectorUserHostModalService,
} from 'ng/modules/cloud/services';

import * as l10n from './verify-cloud-connector-user-host-modal.l10n';
import './verify-cloud-connector-user-host-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Component to verify SSH Credentials with Host and Clean up existing SE configuration.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'verify-cloud-connector-user-host-modal',
    templateUrl: './verify-cloud-connector-user-host-modal.component.html',
})
export class VerifyCloudConnectorUserHostModalComponent implements OnInit {
    /**
     * Used to check if the task is 'verify' or 'cleanup'.
     */
    @Input()
    public task: TaskType;

    /**
     * IP address of host. Used in payload.
     */
    @Input()
    public hostIp: string;

    /**
     * Used to disable host IP input when host IP is present.
     */
    public disableHostIpInput = false;

    /**
     * Holds cloud connector user api response.
     */
    public cloudConnectorUserApiResponse = '';

    /**
     * Used to show error messages if any.
     */
    public errors: string;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Used to get modal title.
     */
    private modalTitleHash = {
        test: l10nKeys.verifyHostModalTitle,
        cleanup: l10nKeys.cleanUpModalTitle,
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly verifyCloudConnectorUserHostModalService
        : VerifyCloudConnectorUserHostModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.hostIp) {
            this.disableHostIpInput = true;

            this.runTask();
        }
    }

    /**
     * Getter for modal title.
     */
    public get modalTitle(): string {
        return this.l10nService.getMessage(this.modalTitleHash[this.task]);
    }

    /**
     * Calls service method which makes API call to verify SSH Credentials with Host
     * and Clean up existing SE configuration.
     */
    public runTask(): void {
        if (this.busy) {
            return;
        }

        this.busy = true;
        this.cloudConnectorUserApiResponse = '';
        this.errors = null;

        this.verifyCloudConnectorUserHostModalService.runTask(this.task, this.hostIp)
            .then(({ data }: IHttpResponse<string>) => {
                this.cloudConnectorUserApiResponse = data;
            })
            .catch(({ data }) => {
                this.errors = data;
            }).finally(() => this.busy = false);
    }

    /**
     * Clears response text on Host IP input change.
     */
    public clearResponseText(): void {
        this.cloudConnectorUserApiResponse = '';
    }

    /**
     * Function to close verify cloud connector user host modal.
     */
    public dismissModal(): void {
        this.verifyCloudConnectorUserHostModalService.closeModal();
    }
}
