/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'groups';
const componentName = 'ip-addr-group-page';

const prefix = `${moduleName}.${componentName}`;

export const emptyGridPlaceholder = `${prefix}.emptyGridPlaceholder`;
export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleIpAddressesOrRanges = `${prefix}.columnTitleIpAddressesOrRanges`;
export const columnTitleCountryCodesOrEpg = `${prefix}.columnTitleCountryCodesOrEpg`;
export const countryCodeDataLabel = `${prefix}.countryCodeDataLabel`;

export const ENGLISH = {
    [columnTitleIpAddressesOrRanges]: 'IP Addresses or Ranges',
    [columnTitleCountryCodesOrEpg]: 'Country Codes or EPG',
    [emptyGridPlaceholder]: 'No Items Found',
    [headerLabel]: 'IP Groups',
    [countryCodeDataLabel]: 'Country Code',
};
