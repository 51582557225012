<clr-radio-wrapper *ngIf="!viewMode">
    <input
        [attr.id]="uniqueIdentifier"
        type="radio"
        [name]="name"
        [(ngModel)]="modelValue"
        (ngModelChange)="handleModelChange($event)"
        [value]="value"
        [required]="required"
        [disabled]="disabled"
        clrRadio
    />
    <label
        aviLabelWithTooltip
        [objectType]="objectType"
        [fieldName]="fieldName"
        [tooltipText]="tooltipText"
        [enumType]="enumType"
        [enumValue]="enumValue"
        [attr.for]="uniqueIdentifier"
        [noBold]="true"
    >
        <ng-container [ngTemplateOutlet]="labelTemplateRef"></ng-container>
    </label>
</clr-radio-wrapper>
<div
    *ngIf="viewMode && isSelectedValue"
    class="avi-radio__view-mode"
>
    <label
        aviLabelWithTooltip
        [objectType]="objectType"
        [fieldName]="fieldName"
        [tooltipText]="tooltipText"
        [enumType]="enumType"
        [enumValue]="enumValue"
        [noBold]="true"
    >
        <ng-container [ngTemplateOutlet]="labelTemplateRef"></ng-container>
    </label>
</div>

<ng-template #labelTemplateRef>
    <ng-content></ng-content>
</ng-template>
