<avi-state-page
    class="system-update clr-wrapper"
    [hasSidebar]="true"
>
    <avi-state-page_main-content>
        <avi-alert-group
            *ngIf="fipsFailure"
            alertType="warning"
        >
            <span>{{ l10nKeys.nonOperationalFipsMessage | vtranslate }}</span>
        </avi-alert-group>
        <h2 class="system-update__header">
            {{ l10nKeys.systemUpdateLabel | vtranslate }}
        </h2>
        <div class="system-update__container">
            <system-update-about-card
                class="system-update__update-card"
                [enableRollback]="enableRollback && !isSystemUpdateInProgress"
                [rollbackOptions]="rollbackOptions"
                (onSelectRollbackOption)="onRollbackOptionClick($event)"
                [systemConfig]="systemConfig"
                [fipsOperational]="fipsOperational"
            ></system-update-about-card>
            <update-progress-card
                class="system-update__update-card"
                [fipsOperational]="fipsOperational"
                [isControllerUpgrade]="isControllerUpgrade"
            ></update-progress-card>
        </div>
        <avi-collection-data-grid
            [config]="imageCollectionGridConfig"
            gridTitle="{{ l10nKeys.availableUpdateslabel | vtranslate : imageCollection.itemList.length }}"
        ></avi-collection-data-grid>
    </avi-state-page_main-content>
    <avi-state-page_sidebar>
        <recent-updates-list
            [nodeType]="upgradeNodeType"
            [precheckNodes]="precheckNodes | async"
            [prechecksTriggered]="arePrechecksTriggered$ | async"
            [overallPrechecksState]="overallPrechecksState$ | async"
        ></recent-updates-list>
    </avi-state-page_sidebar>
</avi-state-page>

<ng-template
    #imageTypeTemplateRef
    let-row
>
    <div>
        <cds-icon
            [shape]="row.isType('IMAGE_TYPE_SYSTEM') ? 'display' : 'wrench'"
            size="18"
        ></cds-icon>
        <span> {{ row.type | enumValueLabel : 'ImageType' }}</span>
    </div>
</ng-template>
