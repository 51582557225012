/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview This file contains functions related to Date, Time and Delay.
 */

/**
 * Function to return promise to be resolved after provided delay.
 * @param {number} delay - Waiting time for the promise to get resolved.
 * @return {Promise}
 */
export const waitFor = delay => {
    return new Promise(resolve => setTimeout(resolve, delay));
};
