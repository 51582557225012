/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IGenericMessageItemConfig,
    MessageItem,
} from 'ajs/modules/data-model/factories';

import { StringService } from 'string-service';
import { TEnumValueLabelsHash } from 'ajs/modules/core/services/schema-service';

interface IPathOrQueryMatchItemConfig extends IGenericMessageItemConfig {
    match_str?: string[];
    string_group_refs?: string[];
}

/**
 * @description  Base class for PathMatch & QueryMatch ConfigItem factories.
 * @author Abhinesh Gour
 */
export class PathOrQueryMatchBaseConfigItem<T extends IPathOrQueryMatchItemConfig>
    extends MessageItem<T> {
    public static ajsDependencies = [
        'schemaService',
        'stringService',
    ];

    /**
     * Hash of match case values to their labels.
     */
    protected matchCaseLabels: TEnumValueLabelsHash;

    /**
     * Hash of string operation values to their labels.
     */
    protected operationLabels: TEnumValueLabelsHash;

    /**
     * StringService instance.
     */
    private stringService: StringService;

    constructor(args: Record<string, any> = {}) {
        super(args);

        const schemaService = this.getAjsDependency_('schemaService');

        this.stringService = this.getAjsDependency_('stringService');
        this.matchCaseLabels = schemaService.getEnumValueLabelsHash('MatchCase');
    }

    /**
     * Returns a list of values that should be searchable.
     */
    public get searchableValues(): string[] {
        return [
            ...this.stringGroupNames,
        ];
    }

    /**
     * Getter for the match_str repeated strings config property.
     */
    public get matchStr(): string[] {
        return this.config.match_str;
    }

    /**
     * Setter for the match_str repeated strings config property.
     */
    public set matchStr(matchStr: string[] | undefined) {
        this.config.match_str = matchStr;
    }

    /**
     * Getter for the string_group_refs repeated strings config property.
     */
    public get stringGroupRefs(): string[] {
        return this.config.string_group_refs;
    }

    /**
     * Setter for the string_group_refs repeated strings config property.
     */
    public set stringGroupRefs(groupRefs: string[] | undefined) {
        this.config.string_group_refs = groupRefs;
    }

    /**
     * Returns the label to display for this.config.match_case.
     */
    public get matchCaseLabel(): string {
        return this.matchCaseLabels[this.config.match_case];
    }

    /**
     * Returns the label to display for this.config.match_criteria.
     */
    public get matchCriteriaLabel(): string {
        return this.operationLabels[this.config.match_criteria];
    }

    /**
     * Returns the label to display for this.config.match_str.
     */
    public get matchStrings(): string {
        const { match_str: matchString = [] } = this.config;

        return matchString.join(', ');
    }

    /**
     * Returns string_group_refs as comma seperated names.
     */
    public get stringGroupNames(): string[] {
        const { string_group_refs: stringGroupRefs = [] } = this.config;

        return stringGroupRefs.map(stringGroupRef => this.stringService.name(stringGroupRef));
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const {
            matchStrings,
            stringGroupNames = [],
        } = this;

        let displayValues = matchStrings;

        if (stringGroupNames.length) {
            displayValues =
                `${displayValues ? `${displayValues}, ` : ''}${stringGroupNames.join(', ')}`;
        }

        return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${displayValues}`;
    }

    /**
     * Clears configured match strings and string group refs.
     */
    public clearStringGroupRefsAndMatchStrings(): void {
        this.stringGroupRefs = undefined;
        this.matchStr = undefined;
    }

    /**
     * Called to add a list of match strings to the config.
     */
    public addMatchStrings(matchStrings: string[] = []): void {
        matchStrings.forEach(matchString => this.addMatchString(matchString));
    }

    /**
     * Called to add a list of string group refs to the config.
     */
    public addStringGroupRefs(groupRefs: string[] = []): void {
        groupRefs.forEach(groupRef => this.addStringGroupRef(groupRef));
    }

    /**
     * Adds a match string to the list of configured match strings.
     */
    private addMatchString(matchString: string): void {
        this.matchStr = this.matchStr || [];
        this.matchStr.push(matchString);
    }

    /**
     * Adds a string group ref to the list of configured string group refs.
     */
    private addStringGroupRef(groupRef: string): void {
        this.stringGroupRefs = this.stringGroupRefs || [];
        this.stringGroupRefs.push(groupRef);
    }
}
