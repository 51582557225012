<full-modal-config
    modalTitle="{{ l10nKeys.geoDbFileModalTitle | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="geo-db-file-modal"
>
     <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-checkbox
                    objectType="{{ editable.messageType }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                    name="enabled"
                >
                    {{ l10nKeys.enabledFileLabel | vtranslate }}
                </avi-checkbox>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ editable.messageType }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ editable.messageType }}"
                            fieldName="vendor"
                            for="vendor"
                            required
                        >
                            {{ l10nKeys.vendorLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="vendor"
                            [options]="vendorOptions"
                            [(ngModel)]="editable.config.vendor"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.vendorLabel | vtranslate }}"
                            required
                            disabled
                        ></avi-dropdown>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ editable.messageType }}"
                            fieldName="file_uuid"
                            for="file_uuid"
                            required
                        >
                            {{ l10nKeys.geoDbFileLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [disableCreate]="true"
                            [disableEdit]="true"
                            [collection]="fileObjectCollection"
                            [(ngModel)]="editable.config.file_ref"
                            placeholder="{{ l10nKeys.geoDbFilePlaceholder | vtranslate }}"
                            name="file_uuid"
                            required
                        ></avi-collection-dropdown>
                    </div>
                </div>

            </div>
        </full-modal-tab-section>
    </form> 
</full-modal-config>
