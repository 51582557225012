/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'persistence-profile';
const componentName = 'persistence-profile-page';

const prefix = `${moduleName}.${componentName}`;

export const placeholderMessage = `${prefix}.placeholderMessage`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [placeholderMessage]: 'No Items Found',
    [headerLabel]: 'Persistence Profile',
};
