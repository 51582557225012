<div
    class="full-modal-config clr-wrapper"
    [ngClass]="fullModalConfigClassnames"
    tabindex="-1"
>
    <full-modal-config-header
        class="full-modal-config__header"
        headerText="{{ modalTitle }}"
        subHeaderText="{{ modalSubTitle }}"
        (onClose)="handleCancelAttempt()"
    >
        <ng-container ngProjectAs="full-modal-config-header_config-viewer">
            <ng-content select="full-modal-config-viewer"></ng-content>
        </ng-container>
        <full-modal-config-header-tabs
            class="full-modal-config__full-modal-config-header-tabs"
            [tabs]="tabs"
            activeTabId="{{ activeTabId }}"
            [disableTabs]="disableTabs"
            (onSelect)="handleSelectTab($event)"
        ></full-modal-config-header-tabs>
    </full-modal-config-header>
    <div
        class="full-modal-config__body"
        #fullModalConfigBody
    >
        <div [ngClass]="bodyContentClassnames">
            <full-modal-config-errors
                class="full-modal-config__errors sel-error-message"
                [hidden]="!errors"
                errorMessage="{{ errors | aviBackendErrorMsg }}"
            >
            </full-modal-config-errors>
            <ng-content></ng-content>
        </div>
    </div>
    <full-modal-config-footer
        [disableSubmit]="!valid"
        [busy]="busy"
        [hideSubmit]="hideSubmit"
        (onCancel)="handleCancelAttempt()"
        (onSubmit)="handleSubmit()"
        cancelButtonText="{{ cancelButtonText }}"
        submitButtonText="{{ submitButtonText }}"
    >
        <full-modal-config-footer_footer>
            <ng-content select="full-modal-config_footer"></ng-content>
        </full-modal-config-footer_footer>
    </full-modal-config-footer>
</div>
