/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './metrics-value.less';

/**
 * @ngdoc component
 * @name metricsValue
 *
 * @param {Metrics} metrics - Metrics instance.
 * @param {string=} seriesName - We might have few series within one Metrics, here we can
 *     specify which one to render.
 * @param {ChartSync} chartSync - Instance of ChartSync to get the hovered timestamp.
 * @param {number=} decDigits - How many digits to show after the decimal point. Ignored if not
 *     passed.
 * @param {SeriesDisplayValueType=} displayType
 * @param {Function=} onClick - To be called on element click.
 * @author Alex Malitsky
 * @description
 *
 *     Renders current/avg/max/sum value of a passed Metric or it's Series.
 *
 *     We have a few such directives already, yet another one is introduced to get away from
 *     chartConfig, providing Metrics instance with series name and ChartSync service instead.
 *
 */
//TODO layout settings
//TODO add "current" type support
//TODO show units
//TODO should work on events, wo $watch
//TODO support some default chartSync when not passed?
//jQuery destroy event listener might be needed bue to use in svg
let myAccount;

class MetricsValueController {
    constructor(_myAccount_) {
        myAccount = _myAccount_;
    }

    getValue() {
        const
            displayType = this.displayType || myAccount.uiProperty.valuesToDisplay,
            value = this.metrics && this.metrics.getValue(displayType, this.seriesName);

        if (!this.metrics || angular.isUndefined(value)) {
            return 'N/A';
        }

        return angular.isUndefined(this.decDigits) ? value : value.toFixed(this.decDigits);
    }
}

MetricsValueController.$inject = [
        'myAccount',
];

angular.module('aviApp').component('metricsValue', {
    bindings: {
        metrics: '<',
        seriesName: '=?',
        chartSync: '<?',
        decDigits: '@?',
        displayType: '@?',
        onClick: '&?',
    },
    controller: MetricsValueController,
    template: '<span ng-click="onClick()">{{$ctrl.getValue()}}</span>',
});
