<avi-confirmation
    size="lg"
    class="recommendation-dialog clr-wrapper"
    (onClose)="handleCancel()"
    headerText="{{ l10nKeys.recommendationHeader | vtranslate }}"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <div class="recommendation-dialog__content-container">
            <avi-alert-group
                *ngIf="error"
                alertType="danger"
            >
                {{ error }}
            </avi-alert-group>
            <div
                *ngIf="busy"
                class="recommendation-dialog__loader"
            >
                <clr-spinner clrMedium></clr-spinner>
            </div>
            <div *ngIf="!busy">
                <avi-alert-group
                    *ngIf="isRecommendationAlreadyApplied()"
                    alertType="success"
                >
                    <span> {{ l10nKeys.alreadyAppliedAlertMessage | vtranslate }} </span>
                </avi-alert-group>
                <avi-alert-group
                    *ngIf="isRecommendationFailed()"
                    alertType="warning"
                >
                    <span> {{ l10nKeys.failedAlertMessage | vtranslate }} </span>
                </avi-alert-group>
                <avi-alert-group
                    *ngIf="isRecommendationLogNotAvailable()"
                    alertType="warning"
                >
                    <span> {{ l10nKeys.logNotAvailableAlertMessage | vtranslate }} </span>
                </avi-alert-group>
                <div *ngIf="isRecommendationGenerated()">
                    {{ l10nKeys.recommendationsMessage | vtranslate }}
                </div>
                <recommendation-grid [recommendationList]="recommendationList"></recommendation-grid>
            </div>
        </div>
    </ng-container>

    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button
            (click)="handleCancel()"
            action="outline"
        >
            {{ globalL10nKeys.cancelLabel | vtranslate }}
        </cds-button>
        <cds-button
            *ngIf="isRecommendationGenerated()"
            (click)="handleSubmit()"
            [loadingState]="loadingState"
        >
            {{ l10nKeys.acceptRecommendationButtonLabel | vtranslate }}
        </cds-button>
    </ng-container>
</avi-confirmation>
