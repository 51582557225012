/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ListCollDataSource } from 'ajs/modules/data-model/factories/list-coll-data-source.factory';
import { nodeTypesHash } from 'ng/modules/update/update.types';

const {
    NODE_CONTROLLER_CLUSTER,
    NODE_SE_GROUP,
} = nodeTypesHash;

/**
 * @constructor
 * @memberOf module:avi/upgrade
 * @alias UpgradeNodeCollectionDataSource
 * @extends module:avi/dataModel.ListCollDataSource
 * @desc
 *
 *     Data Source for UpgradeNodeCollection.
 *
 * @author Zhiqian Liu, Ram Pal
 */
export class UpgradeNodeCollectionDataSource extends ListCollDataSource {
    constructor(args) {
        const extendedArgs = {
            ...args,
            defaultParams: {
                includeName_: true,
                node_type: `${NODE_CONTROLLER_CLUSTER},${NODE_SE_GROUP}`,
            },
        };

        super(extendedArgs);

        // set update interval as protected to prevent it from
        // being modified by time frame change event.
        this.protectedUpdateInterval_ = true;
    }
}

UpgradeNodeCollectionDataSource.ajsDependencies = [
    'ListCollDataSource',
];
