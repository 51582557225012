/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'log-connection-grid-rows';
const prefix = `${moduleName}.${componentName}`;

export const transmittedLabel = `${prefix}.transmittedLabel`;
export const recievedLabel = `${prefix}.recievedLabel`;
export const timeoutsLabel = `${prefix}.timeoutsLabel`;
export const outOfOrdersLabel = `${prefix}.outOfOrdersLabel`;
export const retransmitsLabel = `${prefix}.retransmitsLabel`;

export const ENGLISH = {
    [transmittedLabel]: 'Transmitted',
    [recievedLabel]: 'Recieved',
    [timeoutsLabel]: 'Timeouts',
    [outOfOrdersLabel]: 'Out Of Orders',
    [retransmitsLabel]: 'Retransmits',
};
