/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'message-item';

import {
    IControllerInterface,
    IpAddrMode,
} from 'generated-types';

import { ControllerInterface } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories';

import * as l10n from './controller-interface.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TControllerInterface = Omit<
IControllerInterface,
'ip' | 'gateway' | 'public_ip_or_name' | 'ip6' | 'gateway6'
>;

interface IControllerInterfaceConfig extends TControllerInterface {
    ip?: IpAddrPrefixConfigItem;
    gateway?: IpAddrConfigItem;
    public_ip_or_name?: IpAddrConfigItem;
    ip6?: IpAddrPrefixConfigItem;
    gateway6?: IpAddrConfigItem;
}

/**
 * @description ControllerInterface message item.
 * @author alextsg
 */
export class ControllerInterfaceConfigItem extends withFullModalMixin(
    MessageItem,
)<IControllerInterfaceConfig> {
    public static ngDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        super({
            objectType: ControllerInterface,
            windowElement: 'lazy-load',
            ...args,
        });

        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            ControllerInterfaceModalComponent,
        } = await import(
            /* webpackChunkName: "cluster-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/cluster-modal/cluster-node-modal/controller-interface-modal/controller-interface-modal.component'
        );

        return ControllerInterfaceModalComponent as Type<Component>;
    }

    /**
     * Reset IPv4-related fields to the default values.
     */
    public resetIpv4Fields(): void {
        ({ mode: this.config.mode } = this.getDefaultConfig());
        this.resetIpv4AddressFields();
    }

    /**
     * Reset IPv4 prefix and gateway address fields.
     */
    public resetIpv4AddressFields(): void {
        this.config.ip.clearSubnet();
        this.config.gateway.address = undefined;
    }

    /**
     * Reset IPv6-related fields to the default values.
     */
    public resetIpv6Fields(): void {
        ({ mode6: this.config.mode6 } = this.getDefaultConfig());
        this.resetIpv6AddressFields();
    }

    /**
     * Reset IPv6 prefix and gateway address fields.
     */
    public resetIpv6AddressFields(): void {
        this.config.ip6.clearSubnet();
        this.config.gateway6.address = undefined;
    }

    /**
     * Return true if the configured mode is MODE_MANUAL.
     */
    public get isManualMode(): boolean {
        return this.isMode(IpAddrMode.MODE_MANUAL);
    }

    /**
     * Return true if the configured mode6 is MODE_MANUAL.
     */
    public get isManualMode6(): boolean {
        return this.isMode6(IpAddrMode.MODE_MANUAL);
    }

    /**
     * Return true if the configured mode is STATIC.
     */
    public get isStaticMode(): boolean {
        return this.isMode(IpAddrMode.STATIC);
    }

    /**
     * Return true if the configured mode6 is STATIC.
     */
    public get isStaticMode6(): boolean {
        return this.isMode6(IpAddrMode.STATIC);
    }

    /**
     * Return true if the configured mode is DHCP.
     */
    public get isDhcpMode(): boolean {
        return this.isMode(IpAddrMode.DHCP);
    }

    /**
     * Return true if the configured mode6 is DHCP.
     */
    public get isDhcpMode6(): boolean {
        return this.isMode6(IpAddrMode.DHCP);
    }

    /**
     * Return true if the interface is eth0.
     */
    public isEth0(): boolean {
        return this.config.if_name === 'eth0';
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.controllerInterfaceBreadcrumbTitle);
    }

    /** @override */
    protected getModalBreadcrumbDescription(params: IItemParams): string {
        const editable = params.editable as ControllerInterfaceConfigItem;

        return editable.config.if_name;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'ip',
            'ip6',
            'gateway',
            'gateway6',
        ];
    }

    /**
     * Return true if the configured mode matches a mode type.
     */
    private isMode(mode: IpAddrMode): boolean {
        return this.config.mode === mode;
    }

    /**
     * Return true if the configured mode6 matches a mode type.
     */
    private isMode6(mode: IpAddrMode): boolean {
        return this.config.mode6 === mode;
    }
}
