/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IIpAddrRange } from 'generated-types';
import { MessageItem } from './message-item.factory';
import { IpAddrConfigItem } from './ip-addr.config-item.factory';

type TIpAddrRangePartial = Omit<IIpAddrRange, 'rl_profile'>;

interface IIpAddrRangeConfig extends TIpAddrRangePartial {
    begin: IpAddrConfigItem;
    end: IpAddrConfigItem;
}

/**
 * @description IpAddrRange MessageItem.
 *
 * @author Alex Tseung, Aravindh Nagarajan
 */
export class IpAddrRangeConfigItem extends MessageItem<IIpAddrRangeConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddrRange',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const { begin, end } = this.config;

        return `${begin.toString()} - ${end.toString()}`;
    }
}
