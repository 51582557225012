/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    IPortalConfiguration,
    ISecureChannelConfiguration,
} from 'generated-types';

import {
    PortalConfiguration,
    SecureChannelConfiguration,
    SystemConfiguration,
} from 'object-types';

import { L10nService } from '@vmw/ngx-vip';

import {
    SSLProfileCollection,
} from 'ajs/modules/security/factories/ssl-profile/ssl-profile.collection.factory';

import {
    CertificateCollection,
} from 'ajs/modules/security/factories/certificates/certificate.collection.factory';

import * as l10n from './system-settings-system-access-config.l10n';
import './system-settings-system-access-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSSLProfileCollection = typeof SSLProfileCollection;
type TCertificateCollection = typeof CertificateCollection;

/**
 * @description Component for System Access Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-system-access-config',
    templateUrl: './system-settings-system-access-config.component.html',
})
export class SystemSettingsSystemAccessConfigComponent implements OnInit, OnDestroy {
    /**
     * Portal configuration of the controller.
     */
    @Input()
    public portalConfig: IPortalConfiguration;

    /**
     * Secure channel configuration.
     */
    @Input()
    public secureChannelConfig: ISecureChannelConfiguration;

    /**
     * Allowed ciphers in system configuration.
     */
    @Input()
    public allowedSshCiphers: string[];

    /**
     * Allowed hmacs in system configuration.
     */
    @Input()
    public allowedSshHmacs: string[];

    /**
     * SSL Profile collection.
     */
    public sslProfileCollection: SSLProfileCollection;

    /**
     * SSL/TLS Certificates collection.
     */
    public certificateCollection: CertificateCollection;

    /**
     * A boolean flag to restrict SSL Keys to a limit of 2.
     */
    public isSslKeyCertsReadonly: boolean;

    /**
     * A boolean flag to restrict Secure Channel SSL Keys to a limit of 1.
     */
    public isSecureChannelCertListReadonly: boolean;

    /**
     * Object type for template usage.
     */
    public readonly objectTypes = {
        PortalConfiguration,
        SecureChannelConfiguration,
        SystemConfiguration,
    };

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(SSLProfileCollection)
        SSLProfileCollection: TSSLProfileCollection,
        @Inject(CertificateCollection)
        CertificateCollection: TCertificateCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.sslProfileCollection = new SSLProfileCollection();
        this.certificateCollection = new CertificateCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.setIsSslKeyCertsReadonlyFlag();
        this.setIsSecureChannelCertListReadonlyFlag();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
        this.certificateCollection.destroy();
    }

    /**
     * Sets isSslKeyCertsReadonly boolean flag on model change.
     */
    public setIsSslKeyCertsReadonlyFlag(): void {
        this.isSslKeyCertsReadonly = this.portalConfig.sslkeyandcertificate_refs?.length >= 2;
    }

    /**
     * Sets isSecureChannelCertListReadonly boolean flag on model change.
     */
    public setIsSecureChannelCertListReadonlyFlag(): void {
        this.isSecureChannelCertListReadonly = Boolean(
            this.secureChannelConfig.sslkeyandcertificate_refs?.length,
        );
    }
}
