/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import {
    ConfiguredNetwork,
    IConfiguredNetworkWithRuntime,
    INetworkConfig,
    INetworkData,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    IContentLibConfig,
    IpAddrType,
    IStaticRoute,
    IvCenterConfiguration,
    IVrfContext,
} from 'generated-types';

import { CONFIGURED_NETWORK_ITEM_TOKEN } from 'ajs/modules/network/network.tokens';
import { vCenterConfiguration } from 'object-types';

import { findWhere } from 'underscore';

import { StringService } from 'string-service';
import { DevLoggerService } from 'dev-logger-service';

const VERIFY_LOGIN_API = '/api/vimgrvcenterruntime/verify/login';
const FETCH_CONTENT_LIBRARY_API = '/api/vimgrvcenterruntime/retrieve/contentlibraries';
const FETCH_VCENTER_NETWORKS_API = '/api/vimgrvcenterruntime/retrieve/portgroups';
const FETCH_VRF_CONTEXT_API = '/api/vrfcontext';

const CLOUD_UUID = 'cloud_uuid';
const NETWORK_URL = '/api/vipgnameinfo';

/**
 * Type of response returned by VCenterLoginVerify method.
 */
export interface IVcenterDcInfo {
    managed_object_id: string;
    name: string;
}

/**
 * Type of networks list received from the back end.
 */
interface IVcenterPgName {
    managed_object_id: string;
    name: string;
    url?: string;
    uuid: string;
}

/**
 * Type of request params for login request.
 */
interface IVcenterLoginCredentials {
    username: string;
    password: string;
    host: string;
}

/**
 * Type of request config to fetch management networks.
 */
interface IManagementNetworksRequestConfig {
    username?: string;
    password?: string;
    vcenter_url?: string;
    datacenter?: string;
    cloud_uuid?: string;
}

/**
 * @description
 *
 *   VcenterConfiguration MessageItem.
 *
 * @author Sarthak kapoor
 */

export type TvcenterLoginCredentials =
 Pick<IvCenterConfiguration, 'username' | 'password' | 'vcenter_url'>;

type TVcenterConfigurationPartial = Omit<IvCenterConfiguration, 'content_lib'>;

interface IVcenterConfig extends TVcenterConfigurationPartial {
    content_lib: MessageItem<IContentLibConfig>;
}

export class VCenterConfigurationConfigItem extends MessageItem<IVcenterConfig> {
    public static ajsDependencies = [
        CONFIGURED_NETWORK_ITEM_TOKEN,
        HTTP_WRAPPER_TOKEN,
        'secretStubStr',
        'stringService',
        'devLoggerService',
    ];

    /**
     * Model value for Ip Subnet to be used in template.
     */
    public selectedSubnet: string;

    /**
     * Parsed value of selected subnet.
     */
    public parsedSelectedSubnet: string;

    /**
     * Stores the list of content libraries fetched from back end.
     */
    public contentLibraries: IContentLibConfig[];

    /**
     * Used as a model value in UI for Network Subnets.
     */
    public networkSubnets: IConfiguredNetworkWithRuntime[];

    /**
     * Stores the list of networks received from back end.
     */
    public networks: IVcenterPgName[];

    /**
     * Flag to show network request failed alert.
     */
    public showFetchNetworkFailedAlert: boolean;

    /**
     * Flag to check if network was saved successfully.
     */
    public isNetworkSaveSuccessful: boolean;

    /**
     * Flag to check if vrfContext save is successful.
     */
    public isVrfContextSuccessful: boolean;

    /**
     * Store the vrfContext fetched from back end.
     */
    public vrfContext: IVrfContext;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * ConfiguredNetwork Instance to set Network config.
     */
    private network: ConfiguredNetwork;

    /**
     * String Service Instance.
     */
    private readonly stringService: StringService;

    /**
     * Flag to check if vcenter network fetch call is in progress.
     */
    private isVCenterNetworkFetchInProgress: boolean;

    /**
     * List of datacenters.
     */
    private datacenters: IVcenterDcInfo[];

    private readonly devLoggerService: DevLoggerService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: vCenterConfiguration,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_('stringService');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');

        this.showFetchNetworkFailedAlert = false;
    }

    /**
     * Parses the Vrf Context.
     */
    private static parseVrfContext(vrfContext: IVrfContext): IVrfContext {
        let staticRoutesWithNoAddressCount = 0;
        const staticRoutesWithAddress: IStaticRoute[] = [];

        const staticRoutes = vrfContext.static_routes;

        if (staticRoutes?.length) {
            vrfContext.static_routes = staticRoutes;

            /**
             * Calculate the maximum route id already saved.
             */
            let maxStaticRouteIdCount = Math.max.apply(null, staticRoutes.map(
                staticRoute => {
                    if (staticRoute?.route_id) {
                        return !Number.isNaN(+staticRoute.route_id) ? +staticRoute.route_id : 0;
                    } else {
                        return 0;
                    }
                },
            ));

            staticRoutes.forEach(
                staticRoute => {
                    if (!staticRoute.next_hop.addr) {
                        staticRoutesWithNoAddressCount += 1;
                    } else {
                        const { type } = staticRoute.next_hop;

                        if (!staticRoute.route_id) {
                            maxStaticRouteIdCount++;

                            staticRoute.route_id = maxStaticRouteIdCount.toString();
                        }

                        /**
                         * Assign a default prefix if one is not already present.
                         * Default IPv4: 0.0.0.0/0.
                         * Default IPv6: ::/0.
                         */
                        if (!staticRoute.prefix) {
                            if (type === IpAddrType.V4) {
                                staticRoute.prefix = {
                                    ip_addr: {
                                        type: IpAddrType.V4,
                                        addr: '0.0.0.0',
                                    },
                                    mask: 0,
                                };
                            } else {
                                staticRoute.prefix = {
                                    ip_addr: {
                                        type: IpAddrType.V6,
                                        addr: '::',
                                    },
                                    mask: 0,
                                };
                            }
                        }

                        staticRoutesWithAddress.push(staticRoute);
                    }
                },
            );

            if (staticRoutesWithNoAddressCount === staticRoutes.length) {
                vrfContext.static_routes = undefined;
            } else {
                vrfContext.static_routes = staticRoutesWithAddress;
            }
        }

        return vrfContext;
    }

    /**
     * Sets properties like username, password and vcenter_url on config.
     */
    public setVcenterLoginCredentials(loginCredentials: TvcenterLoginCredentials): void {
        const { config } = this;

        const {
            vcenter_url: vcenterUrl,
            username,
            password,
        } = loginCredentials;

        config.vcenter_url = vcenterUrl;
        config.username = username;
        config.password = password;
    }

    /**
     * Finds the selected network object from the list of fetched network objects.
     */
    public findNetwork(): IVcenterPgName {
        const {
            config,
            networks,
            stringService,
        } = this;
        const { management_network: managementNetwork } = config;

        const selectedManagementNetwork = findWhere(
            networks, { uuid: stringService.slug(managementNetwork) },
        );

        selectedManagementNetwork.url = managementNetwork;

        return selectedManagementNetwork;
    }

    /**
     * Returns the config of set network object.
     */
    public get networkConfig(): INetworkConfig {
        const { networkData } = this;

        return networkData?.config;
    }

    /**
     * Returns the data object set on network object.
     */
    public get networkData(): INetworkData {
        const { network } = this;

        return network?.data;
    }

    /**
     * Extracts the subnets from discovery object set over network object.
     */
    public get subnets(): string[] {
        const { networkData } = this;

        return networkData?.discovery?.ip_subnet?.map(
            ({ subnet }) => subnet,
        );
    }

    /**
     * Return the IPv4 type static route from vrf context object.
     */
    public get ipv4TypeStaticRoute(): IStaticRoute {
        return this.getIpTypeBasedStaticRoute(IpAddrType.V4);
    }

    /**
     * Return the IPv6 type static route from vrf context object.
     */
    public get ipv6TypeStaticRoute(): IStaticRoute {
        return this.getIpTypeBasedStaticRoute(IpAddrType.V6);
    }

    /**
     * Deletes the selected content lib id.
     */
    public removeSelectedContentLibId(): void {
        const { config } = this;
        const { content_lib: contentLib } = config;

        delete contentLib?.config?.id;
    }

    /**
     * Called to verify VCenter Login Credentials.
     * Also, fetches the list of datacenters.
     */
    public async vcenterLoginVerify(
        cloudId: string,
        loginCredentials?: TvcenterLoginCredentials,
    ): Promise<IVcenterDcInfo[]> {
        this.busy = true;
        this.errors = null;

        const payload = {
            ...this.loginRequestPayload(cloudId, loginCredentials),
        };

        try {
            const requestConfig = {
                url: VERIFY_LOGIN_API,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { resource: { vcenter_dc_info: vCenterDcInfo } } = response.data;

            if (vCenterDcInfo.length === 1) {
                const { config } = this;

                config.datacenter = vCenterDcInfo[0].name;
                config.datacenter_managed_object_id = vCenterDcInfo[0].managed_object_id;
            }

            this.datacenters = [...vCenterDcInfo];

            return vCenterDcInfo;
        } catch (error) {
            return Promise.reject(error);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of VCenter Content libraries.
     */
    public async fetchContentLibraries(cloudId?: string): Promise<IContentLibConfig[]> {
        this.busy = true;
        this.errors = null;

        const { config } = this;
        const { config: contentLibConfig = {} } = config.content_lib;

        const {
            username,
            password,
            vcenter_url: host,
        } = config;

        const payload = {
            ...this.loginRequestPayload(
                cloudId,
                {
                    username,
                    password,
                    vcenter_url: host,
                },
            ),
        };

        try {
            const requestConfig = {
                url: FETCH_CONTENT_LIBRARY_API,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { vcenter_clibs: vcenterClibs = [] } = response.data.resource;

            this.contentLibraries = [...vcenterClibs];

            /**
             * We check if the selected content lib is not present in the list of
             * content libraries fetched.
             * If not present, we reset the selected values.
             */
            if (contentLibConfig?.id) {
                const selectedContentLib = this.getSelectedContentLib();

                if (!selectedContentLib) {
                    delete contentLibConfig.id;
                }
            }

            return vcenterClibs;
        } catch (error) {
            this.errors = error.data.error;
        } finally {
            /**
             * In edit flow when all the api calls are in progress simultaneously,
             * finally of content libraries is executed even if fetch networks call is in progress.
             * This results in loader not getting displayed while
             * networks call is still in progress.
             */
            this.busy = this.isVCenterNetworkFetchInProgress;
        }
    }

    /**
     * Fetches VCenter Networks and VrfContext.
     */
    public async fetchVCenterNetworks(cloudId: string): Promise<IVcenterPgName[]> {
        this.busy = true;
        this.errors = null;
        this.showFetchNetworkFailedAlert = false;
        this.isVCenterNetworkFetchInProgress = true;

        const {
            config,
            network,
        } = this;
        const { management_network: managementNetwork } = config;

        const payload = { ...this.networkRequestPayload(cloudId) };

        try {
            const requestConfig = {
                url: `${FETCH_VCENTER_NETWORKS_API}?page_size=200`,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);

            const { vcenter_pg_names: vcenterPgNames = [] } = response.data.resource;

            this.networks = [...vcenterPgNames];

            await this.getVRFContext(cloudId);

            if (managementNetwork && !network) {
                await this.setNetwork(
                    {
                        url: managementNetwork,
                    },
                );
            }

            return vcenterPgNames;
        } catch (error) {
            this.showFetchNetworkFailedAlert = true;
        } finally {
            this.isVCenterNetworkFetchInProgress = false;
            this.busy = false;
        }
    }

    /**
     * Called after management network selection (by user from UI or on initial Modal's load).
     */
    public async setNetwork(network: INetworkConfig): Promise<void> {
        const ConfiguredNetwork = this.getAjsDependency_(CONFIGURED_NETWORK_ITEM_TOKEN);
        const {
            config,
            stringService,
        } = this;

        if (!network.url) {
            network.url = `${NETWORK_URL}/${network.uuid}`;
        }

        config.management_network = network.url;

        if (this.network) {
            this.network.destroy();
        }

        this.network = new ConfiguredNetwork({
            id: stringService.slug(network.url),
        });

        if (this.parsedSelectedSubnet) {
            this.parsedSelectedSubnet = '';
            this.selectedSubnet = '';
        }

        try {
            this.errors = null;
            this.busy = true;

            await this.network.load([''], true);
        } catch (error) {
            this.devLoggerService.error(error.data?.error);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Saves the vrfContext Object.
     */
    public async saveVrfContext(mvrf: IVrfContext): Promise<void> {
        this.busy = true;
        this.errors = null;
        this.isVrfContextSuccessful = false;

        let { vrfContext } = this;

        if (!vrfContext && mvrf?.uuid) {
            vrfContext = mvrf;
        }

        if (vrfContext?.url) {
            const parsedVrfContext = VCenterConfigurationConfigItem.parseVrfContext(vrfContext);

            try {
                const requestConfig = {
                    url: vrfContext.url,
                    method: HttpMethod.PUT,
                    data: parsedVrfContext,
                };

                const response = await this.httpWrapper.request(requestConfig);

                this.vrfContext = response.data;
                this.isVrfContextSuccessful = true;
            } catch (error) {
                this.errors = error.data;
            } finally {
                this.busy = false;
            }
        } else {
            Promise.reject();
        }
    }

    /**
     * Set datacenter managed network object id based on selected datacenter.
     */
    public setDatacenterManagedNetworkObjectId(selectedDatacenter: string): void {
        const {
            config,
            datacenters,
        } = this;

        const selectedDatacenterObject = findWhere(
            datacenters, { name: selectedDatacenter },
        );

        config.datacenter_managed_object_id = selectedDatacenterObject?.managed_object_id;
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.content_lib) {
            this.safeSetNewChildByField('content_lib');
        }
    }

    /**
     * Requests VRF management context based on cloud Id.
     */
    private async getVRFContext(cloudId: string): Promise<void> {
        if (cloudId) {
            try {
                const url = `${FETCH_VRF_CONTEXT_API}?name=management&cloud_uuid=${cloudId}`;

                const requestConfig = {
                    url,
                    method: HttpMethod.GET,
                };

                const response = await this.httpWrapper.request(requestConfig);

                const { data } = response;

                if (data && data.results && data.results.length) {
                    [this.vrfContext] = data.results;

                    if (!this.vrfContext.static_routes?.length) {
                        this.vrfContext.static_routes = [];
                    }

                    const ipv4TypeStaticRoute = this.vrfContext.static_routes.find(
                        staticRoute => staticRoute?.next_hop?.type === IpAddrType.V4,
                    );

                    const ipv6TypeStaticRoute = this.vrfContext.static_routes.find(
                        staticRoute => staticRoute?.next_hop?.type === IpAddrType.V6,
                    );

                    if (!ipv4TypeStaticRoute) {
                        this.vrfContext.static_routes.push({
                            next_hop: {
                                type: IpAddrType.V4,
                            },
                        });
                    }

                    if (!ipv6TypeStaticRoute) {
                        this.vrfContext.static_routes.push({
                            next_hop: {
                                type: IpAddrType.V6,
                            },
                        });
                    }
                }
            } catch (error) {
                this.errors = error.data;
            }
        } else {
            Promise.reject();
        }
    }

    /**
     * Returns if the password field was reset or changed.
     */
    private passwordChanged(password: string): boolean {
        const secretStubStr = this.getAjsDependency_('secretStubStr');

        return password !== secretStubStr;
    }

    /**
     * Returns the payload for login and content library api calls.
     */
    private loginRequestPayload(
        cloudId: string,
        loginCredentials?: TvcenterLoginCredentials,
    ): IVcenterLoginCredentials | Record<string, string> {
        const payload: IVcenterLoginCredentials | Record<string, string> = {};

        if (!loginCredentials) {
            payload[CLOUD_UUID] = cloudId;
        } else if (this.passwordChanged(loginCredentials?.password)) {
            const {
                username,
                password,
                vcenter_url: vcenterUrl,
            } = loginCredentials;

            payload.username = username;
            payload.password = password;
            payload.host = vcenterUrl;
        } else {
            payload[CLOUD_UUID] = cloudId;
        }

        return payload;
    }

    /**
     * Returns the payload for fetch network call.
     */
    private networkRequestPayload(
        cloudId: string,
    ): IManagementNetworksRequestConfig {
        const payload: IManagementNetworksRequestConfig = {};

        const { config } = this;
        const {
            username,
            password,
            vcenter_url: vcenterUrl,
            datacenter,
        } = config;

        if (this.passwordChanged(password)) {
            payload.username = username;
            payload.password = password;
            payload.vcenter_url = vcenterUrl;
            payload.datacenter = datacenter;
        } else {
            payload.cloud_uuid = cloudId;
        }

        return payload;
    }

    /**
     * Returns the selected content lib object.
     */
    private getSelectedContentLib(): IContentLibConfig | undefined {
        const {
            config,
            contentLibraries,
        } = this;
        const { config: contentLibConfig = {} } = config.content_lib;

        return findWhere(contentLibraries, { id: contentLibConfig?.id });
    }

    /**
     * Return the static route based on the type parameter.
     */
    private getIpTypeBasedStaticRoute(type: IpAddrType): IStaticRoute {
        const { vrfContext } = this;
        let staticRoutesLength = 0;

        if (!vrfContext.static_routes?.length) {
            vrfContext.static_routes = [];
        }

        const ipTypeBasedStaticRoute = vrfContext.static_routes.find(
            staticRoute => staticRoute?.next_hop?.type === type,
        );

        if (ipTypeBasedStaticRoute) {
            return ipTypeBasedStaticRoute;
        } else {
            vrfContext.static_routes.push({
                next_hop: {
                    type,
                },
            });

            staticRoutesLength = vrfContext.static_routes.length;

            return vrfContext.static_routes[staticRoutesLength - 1];
        }
    }
}
