/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const PASSWORD_STRENGTH_VALIDATOR_KEY = 'userAccountPasswordStrength';

/**
 * @description Validates a value if it satisfy atleast three of the
 *     following criteria (contains uppercase, lowercase, numeric or special characters).
 *
 * @author Nisar Nadaf
 */
export function userAccountPasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        const lowerCaseRegex = new RegExp('([a-z])');
        const upperCaseRegex = new RegExp('([A-Z])');
        const numericRegex = new RegExp('(\\d)');
        const specialCharacterRegex = new RegExp('([@$!%*?^&])');

        let matches = 0;

        if (lowerCaseRegex.test(value)) {
            matches++;
        }

        if (upperCaseRegex.test(value)) {
            matches++;
        }

        if (numericRegex.test(value)) {
            matches++;
        }

        if (specialCharacterRegex.test(value)) {
            matches++;
        }

        // at least 3 out of 4 tests must pass:
        return matches >= 3 ? null :
            { [PASSWORD_STRENGTH_VALIDATOR_KEY]: { value } };
    };
}
