<div class="system-settings-access-snmp-card">
    <h6 class="system-settings-access-snmp-card__header">
        {{ l10nKeys.snmpHeader | vtranslate }}
    </h6>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="version"
            for="snmp"
        >
            {{ l10nKeys.versionLabel | vtranslate }} 
        </label>
        <input
            class="sel-snmp"
            id="snmp"
            name="snmp"
            placeholder="-"
            clrInput
            [ngModel]="snmpVersion"
            readonly
        />
    </avi-input-container>
</div>
