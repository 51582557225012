/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 * @preferred
 */

import { NgModule } from '@angular/core';
import {
    createNgDependencyProviders,
} from 'ng/modules/data-model/utils/inject-ng-dependencies.utils';

import {
    ControllerFaultCollection,
    CSRFPolicyCollection,
    GSLBCollection,
    GSLBInventoryCollection,
    PoolCollection,
    PoolGroupCollection,
    PoolGroupDeploymentPolicyCollection,
    ServiceEngineCollection,
    TenantSettingsCollection,
} from './factories';

const providers = createNgDependencyProviders([
    ControllerFaultCollection,
    CSRFPolicyCollection,
    GSLBCollection,
    GSLBInventoryCollection,
    PoolCollection,
    PoolGroupCollection,
    PoolGroupDeploymentPolicyCollection,
    ServiceEngineCollection,
    TenantSettingsCollection,
]);

/**
 * @description
 * Module for registering Collection files. Collections can be imported by the 'collections/*' path
 * which points to 'collections/factories'. This module should contain providers only.
 * @author alextsg
 */
@NgModule({ providers })
export class CollectionsModule {}
