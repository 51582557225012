<avi-state-page class="se-group-list-page">
    <avi-state-page_main-content>
        <div class="se-group-list-page__header-container">
            <avi-page-header
                headerText="{{ globalL10nKeys.serviceEngineGroupLabel | vtranslate }}"
            ></avi-page-header>
        </div>
        <div class="se-group-list-page__collection-grid">
            <avi-collection-data-grid [config]="seGroupGridConfig"></avi-collection-data-grid>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
<ng-template
    #seGroupExpanderTemplate
    let-row
>
    <se-group-list-expander
        [seGroup]="row"
        class="se-group-list-page__expander"
    ></se-group-list-expander>
</ng-template>
