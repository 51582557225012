<div
    class="avi-health-score-bars"
    style="--healthScore:{{ healthScore.health_score }}"
>
    <div class="avi-health-score-bars__container">
        <!--Rakeline-->
        <div class="avi-health-score-bars__rakeline">
            <div
                *ngIf="healthScore.performance_score !== healthScore.health_score"
                class="avi-health-score-bars__rakeline-bar"
            ></div>
        </div>

        <!--Performance Score-->
        <avi-health-score-bar
            [healthScore]="healthScore.performance_score"
            healthScoreLabel="{{ l10nKeys.performanceLabel | vtranslate }}"
        ></avi-health-score-bar>

        <div class="avi-health-score-bars__partition">
            <label class="avi-health-score-bars__partition-header">{{ l10nKeys.penaltiesLabel | vtranslate }}</label>
            <hr class="avi-health-score-bars__partition-line">
        </div>

        <!--Resource Penalty-->
        <avi-health-score-bar
            [healthScore]="healthScore.resources_penalty"
            healthScoreLabel="{{ l10nKeys.resourceLabel | vtranslate }}"
            [penaltyMargin]="healthScore.health_score + healthScore.anomaly_penalty"
            [isPenalty]="true"
        ></avi-health-score-bar>

        <!--Anomaly Penalty-->
        <avi-health-score-bar
            [healthScore]="healthScore.anomaly_penalty"
            healthScoreLabel="{{ l10nKeys.anomalyLabel | vtranslate }}"
            [penaltyMargin]="healthScore.health_score"
            [isPenalty]="true"
        ></avi-health-score-bar>

        <!--Security Penalty-->
        <avi-health-score-bar
            *ngIf="objectName === serviceEngine || objectName === virtualService"
            [healthScore]="healthScore.security_penalty"
            healthScoreLabel="{{ globalL10nKeys.securityLabel | vtranslate }}"
            [penaltyMargin]="healthScore.health_score"
            [isPenalty]="true"
        ></avi-health-score-bar>

        <hr class="avi-health-score-bars__partition-line">

        <!--Health Score-->
        <avi-health-score-bar
            [healthScore]="healthScore.health_score"
            healthScoreLabel="{{ globalL10nKeys.healthLabel | vtranslate }}"
            [isLabelBold]="true"
        ></avi-health-score-bar>
    </div>
</div>
