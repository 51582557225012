/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsModule
 */

import { L10nService } from '@vmw/ngx-vip';

import {
    IVHMatchRule,
    MatchCase,
    StringOperation,
} from 'generated-types';

import {
    PathMatch,
    VHMatchRule,
} from 'object-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';

import {
    MatchTargetConfigItem,
} from 'ajs/modules/policies/factories/config-items/match-target.config-item.factory';

import {
    VHMatchRuleModalComponent,
} from 'ng/modules/virtual-service/components/vh-match-rule-modal/vh-match-rule-modal.component';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import * as l10n from '../vs.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IVHMatchRuleConfig extends Omit<IVHMatchRule, 'matches'> {
    matches: MatchTargetConfigItem,
}

/**
 * @desc VHMatchRule MessageItem class.
 * @author Abhinesh Gour
 */
export class VHMatchRuleConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IVHMatchRuleConfig, typeof MessageItem>(MessageItem),
)<IVHMatchRuleConfig> {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: VHMatchRule,
            windowElement: VHMatchRuleModalComponent,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns a match count from config item.
     */
    public get matchCount(): number {
        return this.config.matches.matchCount;
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IVHMatchRuleConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues
            .getDefaultItemConfigByType(this.objectType.toLowerCase()) || {};
        const defaultPathMatch = defaultValues.getDefaultItemConfigByType(PathMatch);

        return {
            ...defaultConfig,
            matches: {
                path: {
                    ...defaultPathMatch,
                    // The default match_criteria is 'CONTAINS', but 'CONTAINS' is not currently
                    // supported by VHMatch, so we're using 'BEGINS_WITH' as the default value.
                    match_criteria: StringOperation.BEGINS_WITH,
                    // match_case can only be INSENSITIVE for VHPathMatches.
                    match_case: MatchCase.INSENSITIVE,
                },
            },
        };
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.vHMatchRuleModalBreadcrumbTitle);
    }
}
