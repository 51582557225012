/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ISnmpV3UserParams } from 'generated-types';
import { SnmpV3UserParams } from 'object-types';

/**
 * @description Snmp v3 user params config Item.
 *
 * @author Guru Prasad
 */
export class SnmpV3UserParamsConfigItem extends MessageItem<ISnmpV3UserParams> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: SnmpV3UserParams,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ISnmpV3UserParams> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const {
            priv_passphrase: privPassphrase,
            auth_passphrase: authPassphrase,
            ...defaults
        } = defaultConfig;

        return {
            ...defaults,
            priv_passphrase: '',
            auth_passphrase: '',
        };
    }
}
