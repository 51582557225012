<ng-container *ngrxLet="jwtLog$ as jwtLog">
    <div class="vs-log-cinematic-sub-section-jwt__column-group">
       <!-- 1st column -->
       <div vsLogCinematicSectionColumn>
            <div
                vsLogCinematicFieldTitle
                [withMarginTop]="false"
            >
                {{ l10nKeys.jwtVerifiedLabel | vtranslate }}
            </div>
            <div
                *ngIf="jwtLog.is_jwt_verified !== undefined; else na"
                vsLogFilter
                property="jwt_log.is_jwt_verified"
                [value]="jwtLog.is_jwt_verified"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ jwtLog.is_jwt_verified }}
            </div>

            <div vsLogCinematicFieldTitle>{{ l10nKeys.tokenPayloadLabel | vtranslate }}</div>
            <div
                *ngIf="jwtLog.token_payload as tokenPayload; else na"
                vsLogFilter
                property="jwt_log.token_payload"
                [value]="tokenPayload"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ tokenPayload }}
            </div>
        </div>

        <!-- 2nd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authorizationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                *ngIf="jwtLog.authz_rule_match?.rule_name as ruleName; else na"
                vsLogFilter
                property="jwt_log.authz_rule_match.rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                *ngIf="jwtLog.authz_rule_match?.rule_action as action; else na"
                vsLogFilter
                property="jwt_log.authz_rule_match.rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>

        <!-- 3rd column -->
        <div vsLogCinematicSectionColumn>
            <h5 class="vs-log-cinematic-section__column-title">
                {{ globalL10nKeys.authenticationLabel | vtranslate }}
            </h5>
            <div vsLogCinematicFieldTitle>
                {{ globalL10nKeys.ruleNameLabel | vtranslate }}
            </div>
            <div
                *ngIf="jwtLog.authn_rule_match?.rule_name as ruleName; else na"
                vsLogFilter
                property="jwt_log.authn_rule_match.rule_name"
                [value]="ruleName"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ ruleName }}
            </div>

            <div vsLogCinematicFieldTitle>{{ globalL10nKeys.actionLabel | vtranslate }}</div>
            <div
                *ngIf="jwtLog.authn_rule_match?.rule_action as action; else na"
                vsLogFilter
                property="jwt_log.authn_rule_match.rule_action"
                [value]="action"
                (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
            >
                {{ action }}
            </div>
        </div>
    </div>

</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
