/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IIndividualTenantRole } from 'ajs/modules/accounts/factories/user/user.types';
import { StringService } from 'string-service';
import { TenantCollection } from 'ajs/modules/accounts/factories/tenants/tenant.collection.factory';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { RoleCollection } from 'ajs/modules/accounts/factories/role/role.collection.factory';

import { IndividualTenantSettingModalService }
    // eslint-disable-next-line max-len
    from 'ng/modules/accounts/services/individual-tenant-setting-modal/individual-tenant-setting-modal.service';

import * as globalL10n from 'global-l10n';
import * as l10n from './user-modal-individual-tenant-setting-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TTenantCollection = typeof TenantCollection;
type TCollection = typeof Collection;

/**
 * @description Component for Individual Tenant Setting modal.
 *
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'user-modal-individual-tenant-setting-modal',
    templateUrl: './user-modal-individual-tenant-setting-modal.component.html',
})
export class UserModalIndividualTenantSettingModalComponent implements OnInit {
    /**
     * IndividualTenantRole object
     */
    @Input()
    public individualTenantRole: IIndividualTenantRole;

    /**
     * Array of selected IndividualTenantRef string.
     */
    @Input()
    public selectedIndividualTenantRefs: string[] = [];

    /**
     * Informs if action is create or edit.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Role filter modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Used to show error messages if any.
     */
    public errors = '';

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Selected tenant name
     */
    public tenantName = '';

    /**
     * TenantCollection instance.
     */
    public readonly tenantCollection: TenantCollection;

    /**
     * RoleCollection instance.
     */
    public readonly roleCollection: RoleCollection;

    /**
     * IndividualTenantRole object for form fields binding
     */
    public tenantRoleConfig: IIndividualTenantRole;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly individualTenantSettingModalService: IndividualTenantSettingModalService,
        @Inject(TenantCollection)
        TenantCollection: TTenantCollection,
        @Inject(RoleCollection)
        RoleCollection: TCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.tenantCollection = new TenantCollection();
        this.roleCollection = new RoleCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.tenantRoleConfig = { ...this.individualTenantRole };

        const { tenant_ref: tenantRef = '' } = this.tenantRoleConfig;

        this.tenantName = this.stringService.name(tenantRef);

        if (this.selectedIndividualTenantRefs?.length) {
            const selectedTenantIds = this.selectedIndividualTenantRefs
                .reduce((
                    selectedIndividualTenantRefs: string[],
                    selectedTenantRef: string,
                ) => {
                    if (selectedTenantRef) {
                        const tenantId = this.stringService.slug(selectedTenantRef);

                        selectedIndividualTenantRefs.push(tenantId);
                    }

                    return selectedIndividualTenantRefs;
                }, []);

            this.tenantCollection.setParams({
                'uuid.in': selectedTenantIds.join(),
                exclude: 'uuid.in',
            });
        }
    }

    /**
     * Method to Save user preferences and account data.
     */
    public submit(): void {
        this.individualTenantSettingModalService.closeModal(this.tenantRoleConfig);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.individualTenantSettingModalService.closeModal(null);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.tenantCollection.destroy();
        this.roleCollection.destroy();
    }
}
