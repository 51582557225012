/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').filter('joinPorts', function() {
    return function(services) {
        return services.map(service => {
            const { override_network_profile_ref_data: netProf } = service;
            let profType = '';

            if (netProf) {
                profType = netProf.profile.type.enumeration();
            }

            const { port, port_range_end: end } = service;

            return port + (end !== port ? `–${end}` : '') +
                (service.enable_ssl ? ' (SSL)' : '') +
                (profType ? ` (${profType})` : '');
        }).join(', ');
    };
});
