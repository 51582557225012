/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'authentication-tenant-role-mapping-controller';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const ldapGroupLabel = `${prefix}.ldapGroupLabel`;
export const anyRadioLabel = `${prefix}.anyRadioLabel`;
export const memberRadioLabel = `${prefix}.memberRadioLabel`;
export const notMemberRadioLabel = `${prefix}.notMemberRadioLabel`;
export const groupInputPlaceholder = `${prefix}.groupInputPlaceholder`;
export const attributeNameInputPlaceholder = `${prefix}.attributeNameInputPlaceholder`;
export const attributeValueInputPlaceholder = `${prefix}.attributeValueInputPlaceholder`;
export const superUserLabel = `${prefix}.superUserLabel`;
export const userRoleLabel = `${prefix}.userRoleLabel`;
export const selectRoleDropdownLabel = `${prefix}.selectRoleDropdownLabel`;
export const roleAttributeNameInputLabel = `${prefix}.roleAttributeNameInputLabel`;
export const rolesDropdownLabel = `${prefix}.rolesDropdownLabel`;
export const createRoleDropdownLabel = `${prefix}.createRoleDropdownLabel`;
export const selectRoleDropdownPlaceholder = `${prefix}.selectRoleDropdownPlaceholder`;
export const userTenantsLabel = `${prefix}.userTenantsLabel`;
export const selectTenantsDropdownLabel = `${prefix}.selectTenantsDropdownLabel`;
export const tenantAttributeNameInputLabel = `${prefix}.tenantAttributeNameInputLabel`;
export const tenantsDropdownLabel = `${prefix}.tenantsDropdownLabel`;
export const createTenantDropdownLabel = `${prefix}.createTenantDropdownLabel`;
export const selectTenantDropdownPlaceholder = `${prefix}.selectTenantDropdownPlaceholder`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Tenant and Role Mapping',
    [modalHeaderNew]: 'New Tenant and Role Mapping',
    [ldapGroupLabel]: 'LDAP Group',
    [anyRadioLabel]: 'Any',
    [memberRadioLabel]: 'Member',
    [notMemberRadioLabel]: 'Not a Member',
    [groupInputPlaceholder]: 'Group',
    [attributeNameInputPlaceholder]: 'Attribute Name',
    [attributeValueInputPlaceholder]: 'Attribute Value',
    [superUserLabel]: 'Super User',
    [userRoleLabel]: 'User Role',
    [selectRoleDropdownLabel]: 'Select a Role',
    [roleAttributeNameInputLabel]: 'Role Attribute Name',
    [rolesDropdownLabel]: 'Roles',
    [createRoleDropdownLabel]: 'Create Role',
    [selectRoleDropdownPlaceholder]: 'Select Role',
    [userTenantsLabel]: 'User Tenants',
    [selectTenantsDropdownLabel]: 'Select Tenants',
    [tenantAttributeNameInputLabel]: 'Tenant Attribute Name',
    [tenantsDropdownLabel]: 'Tenants',
    [createTenantDropdownLabel]: 'Create Tenant',
    [selectTenantDropdownPlaceholder]: 'Select Tenant',
    [saveButtonLabel]: 'Save',
};
