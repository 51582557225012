/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { OPENSTACK_KEYSTONE_SERVICE_TOKEN } from 'ajs/modules/core/services/openstack-keystone';

function keystoneIsEnabledDataTransportFactory(
    IdentityDataTransport,
    openstackKeystoneService,
) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.IdentityDataTransport
     * @author Ashish Verma
     * @desc
     *
     *    This kinda weird data transport is used to make an API call through
     *    {@link openstackKeystoneService} service to get some data related to objects
     *    which use this transport.
     *    Right way to do it would be performing an actual API call here and making corresponding
     *    response transformations inCollDataSource or DataTransformer.
     *    DataSource of KeystoneIsEnabled.
     */
    class KeystoneIsEnabledDataTransport extends IdentityDataTransport {
        /** @override */
        load() {
            return openstackKeystoneService.loadKeystoneAuthInfo();
        }
    }

    return KeystoneIsEnabledDataTransport;
}

keystoneIsEnabledDataTransportFactory.$inject = [
    'IdentityDataTransport',
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
];

angular.module('avi/app').factory(
    'KeystoneIsEnabledDataTransport',
    keystoneIsEnabledDataTransportFactory,
);
