<div class="avi-fieldset clr-wrapper">
    <fieldset>
        <div class="avi-fieldset__header">
            <ng-content select="avi-fieldset_header"></ng-content>
            <cds-icon
                [hidden]="!showRemoveIcon"
                shape="trash"
                status="info"
                class="avi-fieldset__remove-icon"
                (click)="handleRemove()"
            ></cds-icon>
        </div>
        <div
            [hidden]="hideContent"
            class="avi-fieldset__content"
        >
            <ng-content select="avi-fieldset_content"></ng-content>
        </div>
    </fieldset>
</div>
