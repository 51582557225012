/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterPollingProgressBarModuleModule
 * @preferred
 */

import { NgModule } from '@angular/core';
import { VIPModule } from '@vmw/ngx-vip';
import { ClrProgressBarModule } from '@clr/angular';

import { ClusterPollingProgressBarComponent } from './components';

const clusterPollingProgressBarComponents = [
    ClusterPollingProgressBarComponent,
];

@NgModule({
    declarations: [
        ...clusterPollingProgressBarComponents,
    ],
    imports: [
        ClrProgressBarModule,
        VIPModule.forChild(),
    ],
    exports: [
        ClusterPollingProgressBarComponent,
    ],
})
export class ClusterPollingProgressBarModule {}
