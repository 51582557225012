/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { ReplicationMode } from 'generated-types';
import { Gslb } from 'object-types';
import { GSLB } from 'items/gslb.item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-settings-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component to configure GSLB replication mode and the client IP group.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-settings-modal',
    templateUrl: './gslb-settings-modal.component.html',
})
export class GslbSettingModalComponent {
    @Input()
    public editable: GSLB;

    public objectType = Gslb;

    public verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    /**
     * List of hidden replication mode types.
     */
    public readonly hiddenReplicationModeTypes = [
        ReplicationMode.REPLICATION_MODE_MANUAL,
    ];

    public readonly globalL10nKeys = globalL10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Parse Client IP address group and fire submit.
     */
    public submit(): void {
        this.editable.config.client_ip_addr_group?.parseAddressConfig();
        this.editable.submit();
    }
}
