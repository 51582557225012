<div class="vs-logs-signpost-custom-waf-rule-groups">
    <ng-content></ng-content>

    <avi-dynamic-data-grid
        *ngIf="vsLogsSignpostStore.vsLogsWafGroupsSignpostData$ | async as vsLogsWafGroupsSignpostData"
        class="vs-logs-signpost-custom-waf-rule-groups__data-grid"
        [config]="wafGroupsGridConfig"
        [rows]="vsLogsWafGroupsSignpostData"
        [rowsTotal]="vsLogsWafGroupsSignpostData.length"
    ></avi-dynamic-data-grid>
</div>
<div
    class="vs-logs-signpost-custom-waf-rule-groups__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>

<ng-template
    #expandedContentNameFieldTemplateRef
    let-rule
>
    <div class="vs-logs-signpost-custom-waf-rule-groups__expanded-content-rule-name-wrapper">
        <span
            class="vs-logs-signpost-custom-waf-rule-groups__expanded-content-rule-id"
            *ngIf="rule.ruleId.value"
            vsLogFilter
            [property]="rule.ruleId.param"
            [operator]="FilterOperatorType.EQUAL_TO"
            [value]="rule.ruleId?.value"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        >
            {{ rule.ruleId.value }}
        </span>
        <ng-container
            *ngIf="!rule.ruleId.value"
            [ngTemplateOutlet]="noData"
            [ngTemplateOutletContext]="{ $implicit: rule.ruleId?.param }"
        ></ng-container>
        <span
            class="vs-logs-signpost-custom-waf-rule-groups__expanded-content-rule-name"
            *ngIf="rule.ruleName.value"
            vsLogFilter
            [property]="rule.ruleName.param"
            [operator]="FilterOperatorType.EQUAL_TO"
            [value]="rule.ruleName?.value"
            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        >
            {{ rule.ruleName.value }}
        </span>
        <ng-container
            *ngIf="!rule.ruleName.value"
            [ngTemplateOutlet]="noData"
            [ngTemplateOutletContext]="{ $implicit: rule.ruleName?.param }"
        ></ng-container>
    </div>
</ng-template>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        class="vs-logs-signpost-custom-waf-rule-groups__group-name"
        vsLogFilter
        *ngIf="row.value.value"
        [property]="row.value.param"
        [operator]="FilterOperatorType.EQUAL_TO"
        [value]="row.value.value"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
    >
        {{ row.name }}
    </span>
    <ng-container
        *ngIf="!row.value.value"
        [ngTemplateOutlet]="noData"
        [ngTemplateOutletContext]="{ $implicit: row.value?.param }"
    ></ng-container>
</ng-template>

<ng-template
    #noData
    let-property
>
    <span
        vsLogFilterableEmptyField
        [property]="property"
        (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
        class="vs-logs-signpost-custom-waf-rule-groups__empty-field"
    ></span>
</ng-template>
