<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-fieldset [hideContent]="!(editable.config.fqdn && editable.config.ip.address)">
                <avi-fieldset_header class="gslb-pool-member-domain-name__avi-fieldset-header">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="fqdn"
                            for="fqdn"
                            required
                        >
                            {{ l10nKeys.ipOrFqdnInputLabel | vtranslate }}
                        </label>
                        <input
                            name="fqdn"
                            id="fqdn"
                            type="text"
                            clrInput
                            regexPattern="anyIPHostname"
                            placeholder="{{ l10nKeys.ipOrFqdnInputPlaceholder | vtranslate }}"
                            [(ngModel)]="ipOrFqdnInputValue"
                            (ngModelChange)="handleIpOrFqdnChange()"
                            required
                        />
                    </avi-input-container>
                </avi-fieldset_header>
                <avi-fieldset_content>
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="ip"
                            for="ip"
                            required
                        >
                            {{ l10nKeys.resolvedFqdnDropdownLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="ip"
                            id="ip"
                            [options]="resolvedFqdnDropdownOptions"
                            [disabled]="resolvedFqdnDropdownOptions.length < 2"
                            [(ngModel)]="editable.config.ip.address"
                            placeholder="{{ l10nKeys.resolvedFqdnDropdownPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </avi-input-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
