<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div class="port-match">
        <enum-radio-buttons
            enum="MatchOperation"
            name="match-operation-{{ matchIndex }}"
            [(ngModel)]="editable.config.match_criteria"
            [noMarginTop]="true"
        ></enum-radio-buttons>
        <div
            class="port-match__port-container"
            provideParentForm
        >
            <avi-input-container helperText="{{ l10nKeys.portMatchHelperText | vtranslate }}">
                <label
                    aviLabelWithTooltip
                    objectType="{{ matchObjectType }}"
                    fieldName="ports"
                    for="port-match"
                    required
                >
                    {{ l10nKeys.portMatchLabel | vtranslate }}
                </label>
                <avi-repeated-numbers
                    id="port-match"
                    name="port-match"
                    placeholder="{{ l10nKeys.portMatchInputPlaceholder | vtranslate }}"
                    objectType="{{ matchObjectType }}"
                    fieldName="ports"
                    [(ngModel)]="editable.config.ports"
                    [allowDuplicates]="true"
                    required
                ></avi-repeated-numbers>
            </avi-input-container>
        </div>
    </div>
</match-wrapper>
