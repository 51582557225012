<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleSip | vtranslate }}</h3>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSIP }}"
                fieldName="sip_request_code"
                required
                for="sip-request-code"
            >
                {{ l10nKeys.sipRequestCodeInputLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="sip-request-code"
                id="sip-request-code"
                [(ngModel)]="editable.sipMonitor.config.sip_request_code"
                enum="SipRequestCode"
                required
            ></avi-enum-dropdown>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSIP }}"
                    fieldName="sip_response"
                    for="sip-response"
                >
                    {{ l10nKeys.sipResponseInputLabel | vtranslate }}
                </label>
                <input
                    id="sip-response"
                    name="sip-response"
                    clrInput
                    placeholder="{{ l10nKeys.sipResponseInputPlaceholder | vtranslate }}"
                    [(ngModel)]="editable.sipMonitor.config.sip_response"
                />
            </avi-input-container>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSIP }}"
                fieldName="sip_monitor_transport"
                for="sip-monitor-transport"
            >
                {{ l10nKeys.sipMonitorTransportInputLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="sip-monitor-transport"
                id="sip-monitor-transport"
                enum="SipMonTransport"
                [(ngModel)]="editable.sipMonitor.config.sip_monitor_transport"
                placeholder="{{ l10nKeys.sipMonitorTransportInputPlaceholder | vtranslate }}"
            ></avi-enum-dropdown>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    for="monitor-port"
                    [required]="editable.config.is_federated"
                >
                    {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    type="number"
                    id="monitor-port"
                    name="monitor-port"
                    [(ngModel)]="editable.config.monitor_port"
                    configFieldInputValidation
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
                    [disabled]="!!editable.id"
                    [required]="editable.config.is_federated"
                />
            </avi-input-container>
        </div>
    </div>
</div>
