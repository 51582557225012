/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export const TRANS_ERROR_UNNECESSARY_WELCOME = 'TRANS_ERROR_UNNECESSARY_WELCOME';
export const TRANS_ERROR_UNNECESSARY_LOGIN = 'TRANS_ERROR_UNNECESSARY_LOGIN';
export const TRANS_ERROR_USER_NOT_LOGGED_IN = 'TRANS_ERROR_USER_NOT_LOGGED_IN';
export const TRANS_ERROR_INSUFFICIENT_PERMISSIONS = 'TRANS_ERROR_INSUFFICIENT_PERMISSIONS';
export const TRANS_ERROR_CONTROLLER_DOWN = 'TRANS_ERROR_CONTROLLER_DOWN';
