<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="geo-db-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ editable.messageType }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="description"
                        objectType="{{ editable.messageType }}"
                        for="description"
                    >
                        {{ l10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        name="description"
                        [(ngModel)]="editable.config.description"
                        placeholder="{{ l10nKeys.descriptionLabel | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <avi-checkbox
                    [disabled]="!!editable.id"
                    objectType="{{ editable.messageType }}"
                    fieldName="is_federated"
                    [(ngModel)]="editable.config.is_federated"
                    name="isFederated"
                >
                    {{ l10nKeys.isFederatedLabel | vtranslate }}
                </avi-checkbox>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleFiles | vtranslate }}"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleFiles | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ editable.messageType }}"
                            fieldName="files"
                            required
                        >
                            <avi-data-grid_header>
                                <h6>
                                    {{ l10nKeys.geoDbFilesLabel | vtranslate : editable.files.getConfig().length  }}
                                </h6>
                            </avi-data-grid_header>
                        </label>
                        <geo-db-file-grid
                            [geoDbFiles]="editable.files"
                            (onAddGeoDbFile)="onAddGeoDbFile()"
                            (onRemoveGeoDbFile)="onRemoveGeoDbFile($event)"
                            (onEditGeoDbFile)="onEditGeoDbFile($event)"
                        ></geo-db-file-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleMapping | vtranslate }}"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleMapping | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.geoDbMappingLabel | vtranslate : editable.mappings.getConfig().length  }}
                        </h6>
                        <geo-db-mapping-grid
                            [geoDbMappings]="editable.mappings"
                            (onAddGeoDbMapping)="onAddGeoDbMapping()"
                            (onRemoveGeoDbMapping)="onRemoveGeoDbMapping($event)"
                            (onEditGeoDbMapping)="onEditGeoDbMapping($event)"
                        ></geo-db-mapping-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

    </form>
</full-modal-config>
