<div class="vip-list-popover">
    <div class="vip-list-popover__header">
        <div class="vip-list-popover__header-title">
            {{ headerTitle }}
        </div>
        <cds-icon
            class="vip-list-popover__header-close-icon"
            shape="times"
            size="16"
            (click)="close()"
        ></cds-icon>
    </div>
    <div 
        *ngFor="let vip of vips; trackBy: trackById"
        class="vip-list-popover__vip-list"
    >
        <div class="vip-list-popover__vip">
            <div>
                <cds-icon
                    class="vip-list-popover__state-icon"
                    [ngClass]="vipStateIconClass"
                    shape="{{ iconShape }}"
                    direction="{{ iconDirection }}"
                    size="16"
                ></cds-icon>
                <span class="vip-list-popover__vip-address">
                    {{ getVipAddresses(vip) }}
                </span>
            </div>
            <div *ngIf="operStateType !== operStateTypeEnum.OPER_UP">
                {{ getVipOperState(vip) }}
            </div>
        </div>
        <ng-container *ngIf="vip.oper_status?.reason">
            <div
                class="vip-list-popover__vip-state-reason"
                *ngFor="let reason of vip.oper_status.reason; trackBy: trackByIndex"
            >
                {{ reason }}
            </div>
        </ng-container>
    </div>
</div>
