/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorHttp,
    HealthMonitorPop3,
    HealthMonitorSSLAttributes,
} from 'object-types';

import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
} from 'ajs/modules/security';

import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { HealthMonitorPop3ConfigItem } from 'ajs/modules/healthmonitor/factories';

import * as globalL10n from 'global-l10n';
import * as l10n from './pop3-health-monitor-config.l10';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * SSLProfileCollection Type.
 */
type TSslProfileCollection = typeof SSLProfileCollection;

/**
 * CertificateCollection Type.
 */
type TCertificateCollection = typeof CertificateCollection;

/**
 * PKIProfileCollection Type.
 */
type TPkiProfileCollection = typeof PKIProfileCollection;

@Component({
    selector: 'pop3-health-monitor-config',
    templateUrl: './pop3-health-monitor-config.component.html',
})
export class Pop3HealthMonitorConfigComponent implements OnChanges, OnInit, OnDestroy {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Passed as a param to ssl, pki and certificate profile collections.
     */
    @Input()
    public isFederated = false;

    /**
     * Health Monitor config type. This can be either pop3_monitor or pop3s_monitor.
     */
    public pop3ConfigItem: HealthMonitorPop3ConfigItem;

    /**
     * Flag to show fields applicable only for POP3S type.
     */
    public isPop3sType = false;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorHttp,
        HealthMonitorPop3,
        HealthMonitorSSLAttributes,
    };

    /**
     * SSLProfileCollection instance.
     */
    public readonly sslProfileCollection: SSLProfileCollection;

    /**
     * CertificateCollection instance.
     */
    public readonly certificateCollection: CertificateCollection;

    /**
     * PKIProfileCollection instance.
     */
    public readonly pkiProfileCollection: PKIProfileCollection;

    constructor(
      l10nService: L10nService,
      @Inject(SSLProfileCollection)
      SSLProfileCollection: TSslProfileCollection,
      @Inject(CertificateCollection)
      CertificateCollection: TCertificateCollection,
      @Inject(PKIProfileCollection)
      PKIProfileCollection: TPkiProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.sslProfileCollection = new SSLProfileCollection();
        this.certificateCollection = new CertificateCollection();
        this.pkiProfileCollection = new PKIProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editable.isPop3sType()) {
            this.pop3ConfigItem = this.editable.config.pop3s_monitor;
            this.isPop3sType = true;
        } else {
            this.pop3ConfigItem = this.editable.config.pop3_monitor;
        }
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { isFederated } = changes;
        const params = {
            is_federated: isFederated?.currentValue,
        };

        this.sslProfileCollection.setParams(params);
        this.pkiProfileCollection.setParams(params);
        this.certificateCollection.setParams(params);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.sslProfileCollection.destroy();
        this.certificateCollection.destroy();
        this.pkiProfileCollection.destroy();
    }
}
