<div
    class="vs-logs-signpost-custom"
    *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
>
    <vs-logs-signpost-custom-waf-latency *ngIf="configKey === customTemplates.wafPhaseLatency">
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-waf-latency>

    <vs-logs-signpost-custom-waf-rule-groups *ngIf="configKey === customTemplates.wafRuleGroups">
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-waf-rule-groups>

    <vs-logs-signpost-custom-groups
        *ngIf="configKey === customTemplates.policy || configKey === customTemplates.ssl"
        [configKey]="configKey"
    >
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-groups>

    <vs-logs-signpost-custom-significance *ngIf="configKey === customTemplates.significance">
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-significance>

    <vs-logs-signpost-custom-e2e-timing *ngIf="configKey === customTemplates.e2eTiming">
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-e2e-timing>

    <vs-logs-signpost-custom-waf-rule *ngIf="configKey === customTemplates.wafRule">
        <ng-container *ngTemplateOutlet="alerts"></ng-container>
    </vs-logs-signpost-custom-waf-rule>
</div>

<ng-template #alerts>
    <ng-content></ng-content>
</ng-template>
