/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    IBotIdentification,
    IIPReputationTypeMapping,
} from 'generated-types';
import { IPReputationTypeMapping } from 'object-types';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { IpReputationTypeMappingModalComponent } from
    // eslint-disable-next-line max-len
    'ng/modules/bot/components/ip-reputation-type-mapping/ip-reputation-type-mapping-modal.component';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ip-reputation-type-mapping.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TIpReputationTypePartial = Omit<IIPReputationTypeMapping, 'bot_identification'>;

interface IIpReputationTypeMappingConfig extends TIpReputationTypePartial {
    bot_identification: MessageItem<IBotIdentification>,
}

/**
 * @author Akul Aggarwal
 */
export class IpReputationTypeMappingConfigItem extends
    withFullModalMixin(MessageItem)<IIpReputationTypeMappingConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IPReputationTypeMapping,
            windowElement: IpReputationTypeMappingModalComponent,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    public get botIdentificationConfig(): any {
        return this.config.bot_identification.config;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.typeMappingTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['bot_identification'];
    }
}
