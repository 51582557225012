<span *ngIf="tooltipText || fieldDescription">
    <avi-tooltip-icon-with-content-wrapper>
        <div class="clr-wrapper">
            <div *ngIf="tooltipText">
                <div>{{ tooltipText }}</div>
                <ng-template [ngTemplateOutlet]="rangeTemplateRef"></ng-template>
            </div>
            <div *ngIf="!tooltipText">
                <div>{{ fieldDescription }}</div>
                <ng-template [ngTemplateOutlet]="rangeTemplateRef"></ng-template>
                <div
                    *ngIf="fieldSpecialValues"
                    class="helper-text-tooltip-icon__section"
                >
                    <div class="helper-text-tooltip-icon__section-header">
                        {{ l10nKeys.specialValuesLabel | vtranslate }}
                    </div>
                    <div>{{ fieldSpecialValues }}</div>
                </div>
                <div
                    *ngIf="isImmutable"
                    class="helper-text-tooltip-icon__section"
                >
                    <div>{{ l10nKeys.immutableFieldMessage | vtranslate }}</div>
                </div>
            </div>
        </div>
    </avi-tooltip-icon-with-content-wrapper>
</span>
<ng-template #rangeTemplateRef>
    <div
        *ngIf="fieldRange"
        class="helper-text-tooltip-icon__section"
    >
        <div class="helper-text-tooltip-icon__section-header">
            {{ l10nKeys.rangeLabel | vtranslate }}
        </div>
        <div>{{ fieldRange }}</div>
    </div>
</ng-template>
