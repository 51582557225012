/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { ClusterObjState } from 'generated-types';

/**
 * @description
 * Given a ClusterObjState enum value, returns the associated Clarity status.
 * @author alextsg
 */
@Pipe({
    name: 'clusterObjStateIconStatus',
})
export class ClusterObjStateIconStatusPipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this
    public transform(clusterObjState: ClusterObjState): 'success' | 'danger' {
        switch (clusterObjState) {
            case ClusterObjState.CLUSTER_ACTIVE:
                return 'success';

            default:
                return 'danger';
        }
    }
}
