/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-vip';
const componentName = 'vs-vip-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleAddress = `${prefix}.columnTitleAddress`;
export const columnTitleVirtualServicesCount = `${prefix}.columnTitleVirtualServicesCount`;
export const allVipsOptionLabel = `${prefix}.allVipsOptionLabel`;
export const unusedVipsOptionLabel = `${prefix}.unusedVipsOptionLabel`;
export const inUseVipsOptionLabel = `${prefix}.inUseVipsOptionLabel`;
export const showPrependLabel = `${prefix}.showPrependLabel`;

export const ENGLISH = {
    [headerLabel]: 'VS VIPs',
    [columnTitleAddress]: 'Address',
    [columnTitleVirtualServicesCount]: '# Virtual Services',
    [allVipsOptionLabel]: 'All VIPs',
    [unusedVipsOptionLabel]: 'Unused VIPs',
    [inUseVipsOptionLabel]: 'In-Use VIPs',
    [showPrependLabel]: 'Show',
};
