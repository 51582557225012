<avi-card class="update-card">
    <avi-card_header>
        <div class="update-card__header">
            <cds-icon
                shape="view-list"
                size="24"
                class="update-card__view-list"
            ></cds-icon>
            {{ l10nKeys.inProgressLabel | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <ng-content select="update-card__progress-info"></ng-content>
    </avi-card_body>
    <avi-card_footer>
        <ng-content select="update-card__footer"></ng-content>
    </avi-card_footer>
</avi-card>
