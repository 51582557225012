/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'snmp-trap-profile';
const componentName = 'snmp-trap-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editSnmpTrapProfileHeader = `${prefix}.editSnmpTrapProfileHeader`;
export const newSnmpTrapProfileHeader = `${prefix}.newSnmpTrapProfileHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const generalLabel = `${prefix}.generalLabel`;
export const addLabel = `${prefix}.addLabel`;
export const editLabel = `${prefix}.editLabel`;
export const removeLabel = `${prefix}.removeLabel`;
export const snmpTrapServersLabel = `${prefix}.snmpTrapServersLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const versionLabel = `${prefix}.versionLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [editSnmpTrapProfileHeader]: 'Edit SNMP Trap Profile',
    [newSnmpTrapProfileHeader]: 'New SNMP Trap Profile',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [generalLabel]: 'General',
    [addLabel]: 'Add',
    [editLabel]: 'Edit',
    [removeLabel]: 'Remove',
    [snmpTrapServersLabel]: 'SNMP Trap Servers ({0})',
    [ipAddressLabel]: 'IP Address',
    [versionLabel]: 'Version',
    [removeButtonLabel]: 'Remove',
};
