/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IWafPSMMatchElement } from 'generated-types';
import { WafPSMMatchElement } from 'object-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import * as l10n from 'ajs/modules/waf/waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description WAF Policy PSM Match Element Class.
 *
 * @author Alex Tseung, Nitesh Kesarkar
 */
export class WafPSMMatchElementConfigItem
    extends withFullModalMixin(MessageItem)<IWafPSMMatchElement> {
    /**
     * Dependencies that are required.
     */
    public static ajsDependencies = [
        'l10nService',
        'schemaService',
    ];

    /**
     * Schema Service Instance
     */
    private schemaService: SchemaService;

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpsmmatchelement',
            objectType: WafPSMMatchElement,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.schemaService = this.getAjsDependency_('schemaService');
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getName(): string {
        return this.config.name ?
            this.schemaService.getEnumValueLabel('WafVariable', this.config.name) : '';
    }

    /**
     * Returns a name.
     */
    public get name(): string {
        return this.getName();
    }

    /**
     * Returns a sub element name.
     */
    public get subElement(): string {
        return this.config.sub_element;
    }

    /**
     * Returns match operation value label.
     */
    public getMatchOperationValueLabel(): string {
        return this.config.match_op ?
            this.schemaService.getEnumValueLabel('StringOperation', this.config.match_op) : '';
    }

    /** @override */
    public canFlatten(): boolean {
        return Boolean(this.config.name);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyPsmMatchElementModalComponent } = await import(
            /* webpackChunkName: "waf-policy-psm-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-psm-group-modal/waf-policy-psm-location-modal/waf-policy-psm-rule-grid/waf-policy-psm-rule-modal/waf-policy-psm-match-element-modal/waf-policy-psm-match-element-modal.component'
        );

        return WafPolicyPsmMatchElementModalComponent as Type<Component>;
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WAF Policy PSM Match Element Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafPsmMatchElementModalBreadcrumbTitle);
    }
}
