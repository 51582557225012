<div class="vs-logs-signpost-default">
    <ng-content></ng-content>

    <div
        class="vs-logs-signpost-default__wrapper"
        *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
    >
        <vs-logs-signpost-default-header
            [configKey]="configKey"
        ></vs-logs-signpost-default-header>

        <vs-logs-signpost-default-view-log-list
            [configKey]="configKey"
        ></vs-logs-signpost-default-view-log-list>
    </div>
</div>
