/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    ElementRef,
    Input,
    Renderer2,
    ViewChild,
} from '@angular/core';
import './label-color-optional.component.less';
import 'ajs/less/themes/default-theme/global-ui-variables.less';

/**
 * Data format needed to create this component.
 */
export interface ILabelData {
    hasLeftColor?: boolean;
    color?: string;
    label?: string;
}

/**
 * @desc Reusable component for showing label, with color optional.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'label-color-optional',
    templateUrl: './label-color-optional.component.html',
})
export class LabelColorOptionalComponent {
    @Input()
    public readonly labelData: ILabelData;

    @ViewChild('colorIcon')
    public colorIcon: ElementRef;

    constructor(
        private renderer: Renderer2,
    ) {}

    /** @override */
    public ngAfterViewInit(): void {
        if (!this.labelData.hasLeftColor) {
            return;
        }

        this.renderer.setStyle(
            this.colorIcon.nativeElement,
            'background-color',
            this.labelData.color,
        );

        this.renderer.setStyle(
            this.colorIcon.nativeElement,
            'color',
            this.labelData.color,
        );
    }
}
