/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    InventoryDataTransformer,
} from 'ajs/modules/data-model/factories/inventory-data-transformer.factory';
import {
    GslbSiteCfgSyncState,
    IGslbPerDnsState,
    IGslbSite,
    IGslbSiteRuntime,
    OperationalState,
} from 'generated-types';

interface IExtendedGslbSiteRuntime extends IGslbSiteRuntime {
    replication_pull_enabled?: boolean;
}

interface IExtendedGslbSite extends IGslbSite {
    isLeader: boolean;
    isLocal: boolean;
}

interface IVantageVersionInfo {
    fullString: string;
    version: string;
    build: string;
    timestamp: string;
}

interface IIGSLBSiteInventoryResponse {
    data: IGSLBSiteInventoryData;
}

interface IGSLBSiteInventoryData {
    results: IGslbSiteResult[];
    count: number;
}

interface IGslbSiteResult {
    config: any;
    runtime?: any;
    health_score?: any;
}

interface IReplicationStatus {
    isReplicationPullEnabled: boolean;
    syncState: GslbSiteCfgSyncState;
    reason: string,
    recommendation: string,
    pending_object_count?: number,
}

interface IHealthScore {
    health_score: number;
}

interface IGslbNonAviSiteConfig {
    isNonAviSite: boolean;
}

/**
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBSiteInventoryDataTransformer extends InventoryDataTransformer {
    /**
     * Parses full Avi Vantage version into chunks like version, build and timestamp.
     */
    public static getVersionDataFromString(str: string): IVantageVersionInfo | {} {
        const parts = /([\d.]+)\s?\((\d+)\)\s(.*)/.exec(str);

        if (parts) {
            const [fullString, version, build, timestamp] = parts;

            return {
                fullString,
                version,
                build,
                timestamp,
            };
        }

        return {};
    }

    /**
     * Sets props needed by UI related to gslb replication checkpoints.
     */
    private static setReplicationStatus(
        gslbStatusRuntime: IExtendedGslbSiteRuntime,
    ): IReplicationStatus | null {
        const { replication_stats: replicationstats, site_cfg: siteCfg } = gslbStatusRuntime;

        if (siteCfg && siteCfg.sync_info) {
            const replicationStatus: IReplicationStatus = {
                isReplicationPullEnabled: !!gslbStatusRuntime.replication_pull_enabled,
                syncState: siteCfg.sync_info.sync_state,
                reason: siteCfg.sync_info.reason,
                recommendation: siteCfg.sync_info.recommendation,
            };

            if (replicationstats && replicationstats.pending_object_count) {
                replicationStatus.pending_object_count = replicationstats.pending_object_count;
            }

            return replicationStatus;
        }

        return null;
    }

    /** @override */
    public processResponse(resp: any): IIGSLBSiteInventoryResponse {
        const systemInfo = this.getAjsDependency_('systemInfoService');

        const results: IGslbSiteResult[] = [];
        const { config } = resp.data;
        const runtime = resp.data.runtime.info.site;
        const { third_party_sites: nonAviSites } = config;
        const { localClusterId } = systemInfo;
        const leaderClusterId = config.leader_cluster_uuid;
        const gslbConfigHash = {};

        config.sites.forEach((gslbSiteConfig: IExtendedGslbSite) => {
            const id = gslbSiteConfig.cluster_uuid;

            if (id === leaderClusterId) {
                gslbSiteConfig.isLeader = true;
            }

            if (id === localClusterId) {
                gslbSiteConfig.isLocal = true;
            }

            gslbConfigHash[id] = gslbSiteConfig;
        });

        if (Array.isArray(nonAviSites)) {
            nonAviSites.forEach((gslbNonAviSiteConfig: IGslbNonAviSiteConfig) => {
                gslbNonAviSiteConfig.isNonAviSite = true;
                results.push({
                    config: gslbNonAviSiteConfig,
                });
            });
        }

        runtime.forEach((gslbSiteRuntime: IExtendedGslbSiteRuntime) => {
            const fullRuntime = gslbSiteRuntime.site_info;
            const id = fullRuntime.cluster_uuid;

            if (id in gslbConfigHash) {
                let healthScore: IHealthScore;
                let dnsVSs: string[];

                const swVersion = GSLBSiteInventoryDataTransformer.getVersionDataFromString(
                    fullRuntime.sw_version,
                );

                if (fullRuntime?.dns_info?.dns_vs_states &&
                    Array.isArray(fullRuntime.dns_info.dns_vs_states)) {
                    dnsVSs = fullRuntime.dns_info.dns_vs_states
                        .map((dnsVSruntimeData: IGslbPerDnsState) => {
                            return `${dnsVSruntimeData.uuid}#${dnsVSruntimeData.name}`;
                        });
                }

                const runtime = {
                    oper_status: fullRuntime.oper_status,
                    swVersion,
                    dnsVSs,
                    replicationStatus:
                        GSLBSiteInventoryDataTransformer.setReplicationStatus(gslbSiteRuntime),
                };

                // faking it to make directives work
                if (runtime.oper_status.state === OperationalState.OPER_UP) {
                    healthScore = { health_score: 100 };
                }

                results.push({
                    config: gslbConfigHash[id],
                    runtime,
                    health_score: healthScore,
                });
            }
        });

        return {
            data: {
                results,
                count: results.length,
            },
        };
    }
}

GSLBSiteInventoryDataTransformer.ajsDependencies = [
    'systemInfoService',
];
