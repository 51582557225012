/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ItemsModule
 */

import {
    copy,
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    findIndex,
    isNumber,
    isObject,
    isString,
    isUndefined,
    pick,
    pluck,
    reject,
} from 'underscore';

import {
    IDNSConfig,
    IGslb,
    IGslbSite,
    IGslbSiteDnsVs,
    IGslbThirdPartySite,
    IOperationalStatus,
    IReplicationPolicy,
    SiteMemberType,
} from 'generated-types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    SystemInfoService,
} from 'ajs/modules/core/services/system-info';

import { AviAlertService } from 'ng/modules/core/services/avi-alert.service';
import { TGSLBVSCollection } from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

import {
    GslbSiteModalComponent,
} from 'ng/modules/gslb/components/gslb-site-modal/gslb-site-modal.component';

import {
    Gslb,
    GslbSite,
    GslbThirdPartySite,
} from 'object-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { ObjectTypeItem } from 'object-type-item';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    ISiteDataRow,
// eslint-disable-next-line max-len
} from 'ng/modules/gslb/components/gslb-subdomain-modal/gslb-placement-grid/gslb-placement-grid.component';

import {
    Base,
    IBaseRequestPromise,
} from 'ajs/modules/data-model/factories/base.factory';

import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';
import { GslbSiteDnsVsConfigItem } from 'message-items/gslb-site-dns-vs.config-item.factory';

import {
    GslbThirdPartySiteConfigItem,
} from 'message-items/gslb-third-party-site.config-item.factory';

import {
    GslbClientIpAddrGroupConfigItem,
} from 'message-items/gslb-client-ip-add-group.config-item.factory';

import {
    DNSConfigConfigItem,
} from 'message-items/dns-config.config-item.factory';

type TGslbPartial = Omit<IGslb,
'sites' |
'third_party_sites' |
'client_ip_addr_group' |
'dns_configs'
>;

/**
 * Interface to represent GSLB config object.
 */
export interface IGSLBConfig extends TGslbPartial {
    sites: RepeatedMessageItem<GslbSiteConfigItem>;
    third_party_sites: RepeatedMessageItem<GslbThirdPartySiteConfigItem>;
    client_ip_addr_group: GslbClientIpAddrGroupConfigItem;
    dns_configs: RepeatedMessageItem<DNSConfigConfigItem>
}

interface IGslbData {
    config: IGSLBConfig;
}

/**
 * Enum of gslb sites related fieldnames.
 */
export enum SiteConfigFieldName {
    AVI_SITES = 'sites',
    NON_AVI_SITES = 'third_party_sites',
}

/**
 * Intarface to represent site with cluserId and respective DnsVs.
 */
interface IDnsVsSite {
    clusterId: string;
    name: string;
    dnsVSs: string[];
}

/**
 * Interface to represent site selected for subdomain.
 */
export interface ISubdomainSiteInfo {
    siteName: string,
    siteStatus?: IOperationalStatus,
    dnsVsNames: string[],
}

/**
 * @description
 *
 *     Global Service Load Balancer Item. Has a list of GslbSites, one of which is local,
 *     remaining are remote. One GslbSite is considered the owner and can't be dropped.
 *
 * @author Alex Malitsky, Ram Pal, Hitesh Mandav
 */
export class GSLB extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ngDependencies = [
        'Base',
        'defaultValues',
        'aviAlertService',
        'GSLBVSCollection',
        'systemInfoService',
        'gslbLocationAfterLoad',
        'stringService',
        'devLoggerService',
    ];

    public static sitesConfigPropName = 'sites';
    public static nonAviSitesConfigPropName = 'third_party_sites';
    public readonly clientGroupIps: object[];

    /**
     * GSLB data
     */
    public data: IGslbData;

    private readonly GSLBVSCollection: TGSLBVSCollection;
    private readonly systemInfo: SystemInfoService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslb',
            objectType: Gslb,
            windowElement: GslbSiteModalComponent,
            ...args,
        };

        super(extendedArgs);

        /**
         * List of IPGroups, used for edit only. Objects of IpAddr, IpAddrRange or
         * IpAddrPrefix types.
         */
        this.clientGroupIps = [];
        this.GSLBVSCollection = this.getNgDependency('GSLBVSCollection');
        this.systemInfo = this.getNgDependency('systemInfoService');
    }

    /**
     * Gets a local Site configuration with ips, port and cluster_uuid.
     */
    public static getLocalSiteConfig(): Promise<IGslbSite> {
        const systemInfo = this.getNgDependency('systemInfoService');

        return Promise.resolve(systemInfo.isReady() || systemInfo.load())
            .then(() => {
                const {
                    cluster: clusterConfig,
                    systemconfiguration: systemConfig,
                } = systemInfo.data;

                return {
                    cluster_uuid: clusterConfig.uuid,
                    ip_addresses: pluck(clusterConfig.nodes, 'ip'),
                    port: systemConfig.portal_configuration.port,
                    member_type: SiteMemberType.GSLB_ACTIVE_MEMBER,
                };
            },
            err => {
                const aviAlertService: AviAlertService =
                    this.getNgDependency('aviAlertService');

                aviAlertService.throw(err.data);

                return Promise.reject(err);
            });
    }

    /**
     * Makes an API call to verify whether this GslbSite is configured to participate in GSLB
     * configuration.
     */
    private static verifySite(gslbSite: IGslbSite): IBaseRequestPromise<void> {
        const payload = pick(gslbSite, ['username', 'password', 'ip_addresses', 'port']);
        const AjsBase: typeof Base = this.getNgDependency('Base');

        return new AjsBase().request('post', '/api/gslbsiteops/verify', payload);
    }

    /** @override */
    public dataToSave(): IGslb {
        const config = super.dataToSave();

        config.dns_configs = reject(config.dns_configs,
            config => !config.domain_name);

        if (!config.dns_configs.length) {
            config.dns_configs = undefined;
        }

        return config;
    }

    /** @override */
    public transformAfterLoad(): void {
        if (!this.config.client_ip_addr_group) {
            this.safeSetNewChildByField('client_ip_addr_group');
        }
    }

    /**
     * Checks whether passed controller is marked as `owner` of GSLB configuration.
     */
    public isLeaderSite(gslbSite: IGslbSite): boolean | undefined {
        const id = isString(gslbSite) && gslbSite ||
            isObject(gslbSite) && gslbSite.cluster_uuid;
        let res;

        if (this.data) {
            res = !!id && this.getConfig().leader_cluster_uuid === id;
        }

        return res;
    }

    /**
     * Returns a name of GslbSite which is the leader of this GSLB configuration.
     */
    public getLeaderSiteName(): string | undefined {
        const config = this.getConfig();

        if (!config) {
            return;
        }

        const { name } = this.getAviSiteByIndex(
            this.getSiteIndex(SiteConfigFieldName.AVI_SITES, config.leader_cluster_uuid),
        );

        return name;
    }

    /**
     * Returns default configuration of GslbSite DNS VS object.
     */
    public getDefaultGslbSiteDNSVSConfig(): IGslbSiteDnsVs {
        return {
            dns_vs_uuid: '',
            domain_names: [],
        };
    }

    /**
     * Returns GslbSite of a passed index if present in GSLB config.
     */
    public getAviSiteByIndex(index: number): IGslbSite | undefined {
        return this.getSiteByIndex(SiteConfigFieldName.AVI_SITES, index);
    }

    /**
     * Returns nonAviGslbSite of a passed index if is present in GSLB config.
     */
    public getNonAviSiteByIndex(index: number): IGslbThirdPartySite | undefined {
        return this.getSiteByIndex(SiteConfigFieldName.NON_AVI_SITES, index);
    }

    /**
     * Returns a fist DNS domain name.
     */
    public getDefaultDNSDomainName(): string {
        const domains = this.getDNSDomainNames();

        return domains.length ? domains[0] : '';
    }

    /**
     * Returns a list of domain names provided by this GSLB item.
     */
    public getDNSDomainNames(woDot = false): string[] {
        const { dns_configs: dnsConfigs } = this.getConfig();
        let domains: string[] = [];

        if (!dnsConfigs) {
            return domains;
        }

        domains = dnsConfigs.config.map((item : MessageItem<IDNSConfig>) => {
            const domainName = item.config.domain_name;

            return woDot ? domainName : `.${domainName}`;
        });

        return domains.sort();
    }

    /**
     * Wrapper over addSite for regular GSLB sites.
     */
    public addAviSite(): void {
        this.addSite(SiteConfigFieldName.AVI_SITES);
    }

    /**
     * Wrapper over addSite for non avi sites.
     */
    public addNonAviSite(): void {
        this.addSite(SiteConfigFieldName.NON_AVI_SITES);
    }

    /**
     * Opens a modal to edit properties of GslbSite belonging to GSLB. Wrapper over editSite.
     */
    public editAviSite(site: GslbSiteConfigItem, openDnsModal = false): Promise<MessageItem> {
        return this.editSite(SiteConfigFieldName.AVI_SITES, site, openDnsModal);
    }

    /**
     * Opens a modal to edit properties of GslbThirdPartySite belonging to GSLB.
     * Wrapper over editSite.
     */
    public editNonAviSite(site: GslbThirdPartySiteConfigItem): Promise<MessageItem> {
        return this.editSite(SiteConfigFieldName.NON_AVI_SITES, site, false);
    }

    /**
     * When editing list of DNS VSes of a GslbSite we need to load their names from the
     * corresponding GslbSites and append them to bare uuids we have in config. When
     * siteIndex is not passed we will load all the names for all GslbSites.
     */
    public getSiteDNSVSNames(siteIndex: number): Promise<any[]> {
        const errMsg = 'Item not ready (isDestroyed_) or wrong siteIndex was passed';
        const promises: Array<Promise<any>> = [];

        let sites;
        let site;

        if (this.data && (isUndefined(siteIndex) ||
            // eslint-disable-next-line no-cond-assign
            (site = this.getAviSiteByIndex(siteIndex)))) {
            if (site) {
                sites = [site];
            } else {
                sites = this.getConfig().sites;
            }

            this.busy = true;

            sites.flattenConfig().forEach((site: IGslbSite) => {
                const vsIdToDNSVSList = {};
                const vsUuids: string[] = [];

                if (Array.isArray(site.dns_vses)) {
                    site.dns_vses.forEach(dnsVS => {
                        const { dns_vs_uuid: vsId } = dnsVS;

                        if (!(vsId in vsIdToDNSVSList)) {
                            vsIdToDNSVSList[vsId] = [];
                            vsUuids.push(vsId);
                        }

                        vsIdToDNSVSList[vsId].push(dnsVS);
                    });
                }

                let promise: Promise<any>;

                if (vsUuids && vsUuids.length) {
                    const vsCollection = new this.GSLBVSCollection({
                        gslbSiteId: site.cluster_uuid,
                        gslbTenant: this.getTenantId(),
                        limit: 1000,
                        params: {
                            'uuid.in': vsUuids.join(),
                            fields: 'name,tenant_ref',
                            headers_: { 'X-Avi-Internal-All-Tenants': true },
                        },
                    });

                    promise = vsCollection.load().then(() => {
                        if (!this.isDestroyed()) {
                            // add names to VS uuids in-place
                            vsUuids.forEach((vsUuid: string) => {
                                const vsItem = vsCollection.getItemById(vsUuid);

                                if (vsItem) {
                                    const vsName = vsItem.getName();

                                    vsIdToDNSVSList[vsUuid].forEach((dnsVS: IGslbSiteDnsVs) =>
                                        dnsVS.dns_vs_uuid += `#${vsName}`);
                                }
                            });
                        } else {
                            this.devLoggerService.warn(errMsg);

                            return Promise.reject(new Error(errMsg));
                        }
                    }).finally(() => {
                        vsCollection.destroy();
                    });
                } else {
                    promise = Promise.resolve(
                        'No VSes are present in config - have nothing to load',
                    );
                }

                promises.push(promise);
            });

            if (promises.length) {
                Promise.all(promises)
                    .finally(() => this.busy = false);
            } else {
                this.busy = false;
            }
        } else {
            this.devLoggerService.warn(errMsg);
            promises.push(Promise.reject(new Error(errMsg)));
        }

        return Promise.all(promises);
    }

    /**
     * Makes use of private static method to verify whether Site configuration is ready to be
     * used for GSLB. Also checks credentials, gets and sets GslbSite#cluster_uuid and
     * saves/submits an object.
     */
    public verifySiteAndSave(siteIndex: number, submitOnSuccess: boolean): Promise<void> {
        const errMsg = 'Item not be ready (might be UI destroyed) or faulty API response';
        let promise: any;
        let site: IGslbSite;

        // eslint-disable-next-line no-cond-assign
        if (this.data && (site = this.getAviSiteByIndex(siteIndex))) {
            this.busy = true;

            promise = GSLB.verifySite.call(this, site)
                .then((rsp: any) => {
                    if (this.data && rsp.data) {
                        const oldClusterUuid = site.cluster_uuid;

                        site.cluster_uuid = rsp.data.rx_uuid;

                        // if cluster_uuid has changed we remove old DNS VSs
                        if (oldClusterUuid !== site.cluster_uuid) {
                            site.dns_vses.length = 0;
                        }

                        return rsp;
                    } else {
                        this.devLoggerService.warn(errMsg);

                        return Promise.reject(errMsg);
                    }
                }, (err: any) => {
                    this.errors = err.data;

                    return Promise.reject(errMsg);
                })
                .finally(() => {
                    this.busy = false;
                });

            promise = promise.then((rsp: any) => {
                if (this.data && rsp.data) {
                    return submitOnSuccess ? this.submit() : this.save();
                } else {
                    this.devLoggerService.warn(errMsg);

                    return Promise.reject(errMsg);
                }
            });
        } else {
            promise = Promise
                .reject(new Error('Data is not ready or Site of a passed index wasn\'t found.'));
        }

        return promise;
    }

    /**
     * Returns a hash of cluster_id as a key and value keeping an array of DNS VS ids
     * configured on that particular GslbSite. Source of data for GSLBServiceFQDNCollection.
     */
    public getDNSVSSites(): Record<string, IDnsVsSite> {
        const { sites } = this.getConfig();

        const res: Record<string, IDnsVsSite> = sites.flattenConfig()
            .filter((site: IGslbSite) => site.dns_vses && site.dns_vses.length > 0)
            .map((
                { cluster_uuid: clusterId, dns_vses, name }: IGslbSite,
            ) : IDnsVsSite => ({
                clusterId,
                name,
                dnsVSs: dns_vses.map(dnsVS => dnsVS.dns_vs_uuid),
            }))
            .reduce((
                acc: Record<string, IDnsVsSite>,
                item: IDnsVsSite,
            ) => {
                acc[item.clusterId] = item;

                return acc;
            }, {});

        return res;
    }

    /**
     * Returns the list of GslbSite ids.
     */
    public getSites(): string[] {
        const { sites } = this.getConfig();
        let sitesArray = [];

        if (sites instanceof RepeatedMessageItem) {
            sitesArray = sites.flattenConfig() || [];
        } else {
            sitesArray = sites;
        }

        return sitesArray.map(({ cluster_uuid: clusterUuid, name }: IGslbSite) =>
            `${clusterUuid}#${name}`);
    }

    /**
     * Return list of sites which contains provided domain in dns vs.
     */
    public getSitesFilterByDomain(domain: string): GslbSiteConfigItem[] {
        const { sites } = this.getConfig();

        return sites.config.filter((site: GslbSiteConfigItem) =>
            site.getDnsVsRefsByDomain(domain).length);
    }

    /**
     * Returns the list of non-Avi site ids.
     */
    public getNonAviSites(): string[] {
        const config = this.getConfig();
        const sites = config[SiteConfigFieldName.NON_AVI_SITES] || [];
        let sitesArray = [];

        if (sites instanceof RepeatedMessageItem) {
            sitesArray = sites.flattenConfig() || [];
        } else {
            sitesArray = sites;
        }

        return sitesArray
            .map(({ cluster_uuid: clusterUuid, name }: IGslbSite) => `${clusterUuid}#${name}`);
    }

    /**
     * Returns the list of both Avi and non-Avi site ids.
     */
    public getAllSites(): string[] {
        return [...this.getSites(), ...this.getNonAviSites()];
    }

    /**
     * Return a list of all the Avi and third party sites.
     */
    public getAllSitesList(): Array<(GslbSiteConfigItem | GslbThirdPartySiteConfigItem)> {
        const sites = this.getConfig()[SiteConfigFieldName.AVI_SITES];
        const thirdPartySites = this.getConfig()[SiteConfigFieldName.NON_AVI_SITES];

        return [...sites.config, ...thirdPartySites.config];
    }

    /**
     * Returns replication policy object.
     */
    public get replicationPolicyConfig(): IReplicationPolicy {
        const { replication_policy: replicationPolicy } = this.getConfig();

        if (!replicationPolicy) {
            throw new Error('replication_policy obj does not exist on site config.');
        }

        return replicationPolicy;
    }

    /**
     * Returns human-readable value of current replication_mode.
     */
    public get replicationPolicyModeEnumerated(): string {
        return this.stringService
            .enumeration(this.replicationPolicyConfig.replication_mode, 'REPLICATION_MODE_');
    }

    /**
     * Return current replication_mode.
     */
    public get replicationPolicyMode(): string {
        return this.config.replication_policy.config.replication_mode;
    }

    /**
     * Returns the active replication checkpoint id, if exists.
     */
    public get activeCheckpointId(): string {
        const { checkpoint_ref: activeCheckpointUrl } = this.replicationPolicyConfig;

        return activeCheckpointUrl ? this.stringService.slug(activeCheckpointUrl) : '';
    }

    /**
     * Fires api call to attempt to mark new checkpoint as active one.
     */
    public setActiveReplicationCheckpoint(id: string): IPromise<IHttpResponse<IGslb>> {
        return this.patch({
            add: {
                replication_policy: { checkpoint_ref: id },
            },
        });
    }

    /**
     * Deletes object from clientGroupIps at index specified.
     */
    public deleteClientGroupIp(index: number): void {
        this.clientGroupIps.splice(index, 1);
    }

    /**
     * Adds empty placeholder object to clientGroupIps array.
     */
    public addClientGroupIp(): void {
        this.clientGroupIps.push(undefined);
    }

    /**
     * Getter for enable_config_by_members field.
     * If true, we can edit GSLB followers.
     */
    public get enableConfigByMembers(): boolean {
        const { enable_config_by_members: enableConfigByMembers } = this.getConfig();

        return Boolean(enableConfigByMembers);
    }

    /** @override */
    public isEditable(): boolean {
        return (!this.systemInfo.haveGSLBConfig() || this.systemInfo.localSiteIsGSLBLeader) &&
            super.isEditable();
    }

    /** @override */
    public isProtected(): boolean {
        return !this.systemInfo.localSiteIsGSLBLeader || super.isProtected();
    }

    /**
     * Check whether passed Site Id is marked as `owner` of GSLB configuration.
     */
    public isLeaderSiteId(id: string): boolean {
        const res = Boolean(id) && this.getConfig().leader_cluster_uuid === id;

        return res;
    }

    /**
     * Drops any GslbSite which is not an `owner` or any NonAviGslbSite from GSLBConfig.
     */
    public dropSites(
        sites: Array<(GslbSiteConfigItem | GslbThirdPartySiteConfigItem)>,
    ): IPromise<IHttpResponse<IGslb>> {
        const { leader_cluster_uuid: leaderClusterId } = this.config;
        const aviSitesToDrop: IGslbSite[] = [];
        const nonAviSitesToDrop: IGslbThirdPartySite[] = [];

        let promise: IPromise<IHttpResponse<IGslb>>;

        sites.forEach((site: GslbSiteConfigItem | GslbThirdPartySiteConfigItem) => {
            if (site.messageType === GslbThirdPartySite) {
                const siteToDrop = site.flattenConfig() as unknown as IGslbThirdPartySite;

                nonAviSitesToDrop.push(siteToDrop);
            }

            if (site.messageType === GslbSite && site.config.cluster_uuid !== leaderClusterId) {
                const siteToDrop = site.flattenConfig() as unknown as IGslbSite;

                aviSitesToDrop.push(siteToDrop);
            }
        });

        if (nonAviSitesToDrop.length || aviSitesToDrop.length) {
            const payload = {};

            payload[SiteConfigFieldName.AVI_SITES] = aviSitesToDrop;
            payload[SiteConfigFieldName.NON_AVI_SITES] = nonAviSitesToDrop;
            promise = this.patch({ delete: payload }).then(() => this.load());
        }

        return promise || Promise.reject(new Error('No sites to drop'));
    }

    /**
     * Open modal to edit the DNSConfig subdomain.
     */
    public editSubdomain(
        subdomain: string,
        gslbSitesWithAllSubdomains: ISubdomainSiteInfo[],
    ): Promise<DNSConfigConfigItem> {
        const { dns_configs: dnsConfigs } = this.config;

        const dnsConfigConfigItem = dnsConfigs.config.find(
            (dnsConfig: DNSConfigConfigItem) => dnsConfig.config.domain_name === subdomain,
        );

        return this.editDnsConfig(dnsConfigConfigItem, gslbSitesWithAllSubdomains);
    }

    /**
     * Remove subdomain from DnsVses of all sites.
     */
    public removeSubdomain(subdomain: string): void {
        const { dns_configs: dnsConfigs, sites } = this.config;

        const dnsConfigConfigItem = dnsConfigs.config.find(
            (dnsConfig: DNSConfigConfigItem) => dnsConfig.config.domain_name === subdomain,
        );

        dnsConfigs.removeByMessageItem(dnsConfigConfigItem);

        return sites.config.forEach((site: GslbSiteConfigItem) => {
            site.removeSubdomainFromSite(subdomain);
        });
    }

    /**
     * Add a DNSConfig with default configuration to GslbConfig and open
     * a modal to set it's properties.
     */
    public addDnsConfig(
        gslbSitesWithAllSubdomains: ISubdomainSiteInfo[],
    ): Promise<DNSConfigConfigItem> {
        return this.addChildMessageItem({
            field: 'dns_configs',
            modalBindings: {
                gslb: this,
                gslbSitesWithAllSubdomains,
            },
        },
        true) as Promise<DNSConfigConfigItem>;
    }

    /**
     * Get Sites which have 'all subdomains enabled' DnsVses.
     */
    public getSitesWithAllSubdomainsEnabled(): GslbSiteConfigItem[] {
        const { sites } = this.config;

        return sites.config.filter((site: GslbSiteConfigItem) => {
            const { dns_vses: dnsVses } = site.config;

            return dnsVses.config.some((dnsVs: GslbSiteDnsVsConfigItem) => {
                return !dnsVs.config.domain_names?.length;
            });
        });
    }

    /**
     * Update subdomain list of selected site's DnsVses.
     */
    public updateGslbSitesWithSubdomain(
        selectedGslbSites: ISiteDataRow[],
        domainName: string,
    ): GslbSiteConfigItem[] {
        const { sites } = this.config;

        return sites.config.forEach((site: GslbSiteConfigItem) => {
            site.removeSubdomainFromSite(domainName); // Remove current subdomain.

            const index = selectedGslbSites.findIndex((selectedSite: ISiteDataRow) => {
                return selectedSite.siteName === site.config.name;
            });

            if (index > -1) {
                site.addSubdomainInDnsVses(selectedGslbSites[index].dnsVsNames, domainName);
            }
        });
    }

    /**
     * Enable/Disable GslbSite or GslbThirdPartySite of GSLB.
     */
    public toggleSiteEnabledFlag(
        siteConfigFieldName: SiteConfigFieldName,
        sites: GslbSiteConfigItem[] | GslbThirdPartySiteConfigItem[],
        flagValue?: boolean,
    ): IPromise<IHttpResponse<IGslb>> {
        const sitesToUpdate: GslbThirdPartySiteConfigItem[] | GslbSiteConfigItem[] = [];
        const list = this.config[siteConfigFieldName].config;

        let promise;

        sites.forEach((site: GslbSiteConfigItem) => {
            const index = this.getSiteIndex(siteConfigFieldName, site.config.cluster_uuid);

            if (index !== -1) {
                const siteToUpdate = copy(list[index]);

                siteToUpdate.config.enabled = flagValue;
                sitesToUpdate.push(siteToUpdate);
            } else {
                this.devLoggerService.error('Passed GslbSite was not found in Item\'s config data');
            }
        });

        if (sitesToUpdate.length) {
            const payload = {};

            payload[siteConfigFieldName] = sitesToUpdate.map(site => site.flattenConfig());

            promise = this.patch({ add: payload });
        }

        return promise || Promise.reject(new Error('No sites to update'));
    }

    /**
     * Adds a GslbSite or GslbThirdPartySite with default configuration to GslbConfig and opens
     * a modal to set it's properties. In case of modal dismiss event removes dummy item from
     * GSLB. Need to switch off Item#loadOnEdit before opening modal, otherwise dummy item
     * will be dropped since backend configuration doesn't have it yet.
     */
    private addSite(siteConfigFieldName: SiteConfigFieldName): void {
        this.addChildMessageItem({
            field: siteConfigFieldName,
            modalBindings: {
                gslb: this,
            },
        },
        true);
    }

    /**
     * Open Subdomain modal to edit the DNSConfig.
     */
    private editDnsConfig(
        dnsConfigConfigItem: DNSConfigConfigItem,
        gslbSitesWithAllSubdomains: ISubdomainSiteInfo[],
    ): Promise<DNSConfigConfigItem> {
        return this.editChildMessageItem({
            field: 'dns_configs',
            messageItem: dnsConfigConfigItem,
            modalBindings: {
                gslb: this,
                editMode: true,
                gslbSitesWithAllSubdomains,
            },
        },
        true) as Promise<DNSConfigConfigItem>;
    }

    /**
     * Figures out which modal to open and opens it in a usual manner.
     */
    private editSite(
        siteConfigFieldName: SiteConfigFieldName,
        gslbSite: GslbSiteConfigItem | GslbThirdPartySiteConfigItem,
        openDnsModal: boolean,
    ): Promise<MessageItem> {
        return this.editChildMessageItem({
            field: siteConfigFieldName,
            messageItem: gslbSite,
            modalBindings: {
                gslb: this,
                openDnsModal,
            },
        },
        true);
    }

    /**
     * Returns a GslbSite index in GslbConfig.sites or GslbThirdPartySite index in
     * GslbConfig.third_party_sites.
     */
    private getSiteIndex(
        siteConfigFieldName: SiteConfigFieldName,
        site: string | number | IGslbSite | IGslbThirdPartySite,
    ): number {
        const sites = this.getConfig()[siteConfigFieldName];

        let index = -1;
        let siteId: string;

        if (isNumber(site) && site in sites) {
            index = site;
        // eslint-disable-next-line no-cond-assign
        } else if (site && isString(site) && (siteId = site) ||
            // eslint-disable-next-line no-cond-assign
            isObject(site) && (siteId = site.cluster_uuid)) {
            index = findIndex(sites.config, gslbSite => gslbSite.config.cluster_uuid === siteId);
        }

        return index;
    }

    /**
     * Actual lookup is made here.
     */
    private getSiteByIndex(
        siteConfigFieldName: SiteConfigFieldName,
        index: number,
    ): IGslbSite | IGslbThirdPartySite | undefined {
        const list = this.getConfig()[siteConfigFieldName];

        if (Array.isArray(list)) {
            return list[index];
        }
    }
}
