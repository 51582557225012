/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { GslbPoolMember as GslbPoolMemberObjectType } from 'object-types';
import { StringService } from 'string-service';
import {
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    GslbServicePoolMemberModalComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/gslb/components/gslb-service-modal/gslb-service-pool-member-modal/gslb-service-pool-member-modal.component';
import { IGslbPoolMember } from 'generated-types';

import {
    getResolvablePromise,
    IResolvablePromise,
} from 'ng/shared/utils/resolvable-promise.utils';

import { IP_TYPE_ANY } from 'ajs/js/constants/DNS.constants';
import { anyIP } from 'ng/shared/utils/regex.utils';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories';
import { L10nService } from '@vmw/ngx-vip';

import {
    GSLBVSCollection,
    TGSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

import * as l10n from './gslb-pool.l10n';
import { GslbGeoLocationConfigItem } from './gslb-geo-location.config-item.factory';
import { GslbIpAddrConfigItem } from './gslb-ip-addr.config-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TGslbPoolMemberPartial = Omit<IGslbPoolMember, 'location' | 'ip' | 'public_ip'>;

export interface IGslbPoolMemberConfig extends TGslbPoolMemberPartial {
    location?: GslbGeoLocationConfigItem,
    ip?: IpAddrConfigItem,
    public_ip?: GslbIpAddrConfigItem,
}

/**
 * @description GSLB Service Pool Member ConfigItem.
 * @author Suraj Kumar
 */
export class GslbPoolMemberConfigItem extends
    withFullModalMixin(MessageItem)<IGslbPoolMemberConfig> {
    public static ngDependencies = [
        'l10nService',
        'stringService',
        'gslbLocationAfterLoad',
        'GSLBVSCollection',
        'DNS',
    ];

    private readonly GSLBVSCollection: TGSLBVSCollection;
    private readonly stringService: StringService;
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: GslbPoolMemberObjectType,
            windowElement: GslbServicePoolMemberModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.stringService = this.getNgDependency('stringService');
        this.GSLBVSCollection = this.getNgDependency('GSLBVSCollection');
        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Reset preference order for pool member to default.
     */
    public resetPreferenceOrder(): void {
        const { config: poolMemberConfig } = this;

        poolMemberConfig.preference_order = this.getDefaultConfig().preference_order;
    }

    /**
     * Get VS IP Addresses. Since object model keeps {GslbPoolMember#vs_uuid} not as ref
     * but bare uuid we need to go over GslbPool, pick GslbSites and vsIds used and load their
     * names and ips.
     */
    public async getPoolMemberVsData(): Promise<string[]> {
        const { cluster_uuid: gslbSiteId } = this.config;
        const vsId = this.stringService.slug(this.config.vs_uuid || '');

        if (vsId && gslbSiteId) {
            this.busy = true;

            const gslbVSCollection = new this.GSLBVSCollection({
                gslbSiteId,
                limit: 1000,
                params: {
                    'uuid.in': vsId,
                    fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                    join: 'vsvip_ref',
                },
            });

            try {
                await gslbVSCollection.load();

                const virtualService = gslbVSCollection.getItemById(vsId);

                if (virtualService) {
                    const vsIps: string[] = await this.getVsIPAddresses(virtualService);

                    this.setVsId(virtualService.getName());

                    return vsIps;
                }
            } catch (error) {
                this.errors = error.data;

                Promise.reject(error);
            } finally {
                this.busy = false;

                gslbVSCollection.destroy();
            }
        }
    }

    /**
     * Handle FQDN or IP address change.
     */
    public async handleIpOrFqdnChange(inputValue: string): Promise<string[]> {
        if (!inputValue) {
            this.updateMemberIpAddress(undefined);

            delete this.config.fqdn;

            return;
        }

        if (anyIP.test(inputValue)) {
            this.updateMemberIpAddress(inputValue);

            delete this.config.fqdn;
        } else { // FQDN case
            this.updateMemberIpAddress(undefined);

            this.config.fqdn = inputValue;

            const [firstIp] = await this.resolveFqdn(inputValue);

            if (firstIp) {
                this.updateMemberIpAddress(firstIp);
            }
        }
    }

    /**
     * Perform dns lookup by fqdn and return list of resolved ips.
     */
    public resolveFqdn(fqdn: string): Promise<string[]> {
        this.busy = true;

        const dnsService = this.getNgDependency('DNS');

        try {
            const cancelRequestPromise: IResolvablePromise<string> = getResolvablePromise<string>();

            return dnsService.lookup(fqdn, cancelRequestPromise, undefined, IP_TYPE_ANY);
        } catch (errors) {
            return Promise.reject(errors);
        } finally {
            this.busy = false;
        }
    }

    /**
     * Handle VS selection change.
     * Get the selected VS from the collection. Update resolved IP address dropdown with
     * the first resolved IP address of VS selected.
     */
    public async onVsSelectionChange(
        selectedSiteVsCollection: GSLBVSCollection,
    ): Promise<string[]> {
        const {
            vs_uuid: vsId,
            cluster_uuid: clusterId,
        } = this.config;

        let vsIps: string[] = [];

        this.updateMemberIpAddress(undefined);

        if (vsId && clusterId) {
            const virtualService = selectedSiteVsCollection?.getItemById(
                this.stringService.slug(vsId),
            );

            if (virtualService) {
                vsIps = await this.getVsIPAddresses(virtualService);

                this.updateMemberIpAddress(vsIps[0]);
            }
        }

        return vsIps;
    }

    /**
     * Update config with ip address passed.
     */
    public updateMemberIpAddress(value: string): void {
        this.config.ip.address = value;
    }

    /**
     * Return true, if pool member configuration is set by IP address type.
     * We have 2 types IP address and virtual service.
     */
    public get isConfiguredByTypeIp(): boolean {
        return !this.config.vs_uuid;
    }

    /**
     * Return pool member IP address.
     */
    public get memberIpAddress(): string {
        return this.config.ip.address;
    }

    /**
     * Handle pool member configuration type change, We have 2 types IP address and virtual service.
     */
    public resetSettingForConfigurationType(isConfiguredByTypeIp: boolean): void {
        const { config: memberConfig } = this;

        if (isConfiguredByTypeIp) {
            delete memberConfig.cluster_uuid;
            delete memberConfig.vs_uuid;
        } else {
            delete memberConfig.fqdn;
        }

        this.updateMemberIpAddress(undefined);
    }

    /**
     * Handle site cluster controller selection change.
     */
    public handleSiteClusterControllerChange(): void {
        delete this.config.vs_uuid;

        this.updateMemberIpAddress(undefined);
    }

    /**
     * Get VS collecton of the selected site.
     */
    public getSelectedSiteVsCollection(gslbSites: string[]): GSLBVSCollection {
        const { config } = this;
        let gslbVsCollection;

        gslbSites.forEach(siteRef => {
            const gslbSiteId = this.stringService.slug(siteRef);

            if (gslbSiteId === config.cluster_uuid) {
                gslbVsCollection = new this.GSLBVSCollection({
                    gslbSiteId,
                    params: {
                        fields: ['vsvip_ref', 'vh_parent_vs_ref', 'type'].join(),
                        join: 'vsvip_ref',
                    },
                });
            }
        });

        return gslbVsCollection;
    }

    /**
     * Format pool member geo location.
     */
    public gslbLocationAfterLoad(): void {
        const { config: geoLocationConfig } = this.config.location.config.location;

        if (geoLocationConfig) {
            const gslbLocationAfterLoad = this.getNgDependency('gslbLocationAfterLoad');

            gslbLocationAfterLoad(geoLocationConfig);
        }
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const {
            config: poolMemberConfig,
            stringService,
        } = this;
        const { config: gslbGeoLocationConfig } = poolMemberConfig.location;
        const { config: geoLocationConfig } = gslbGeoLocationConfig.location;

        if (poolMemberConfig.vs_uuid) {
            poolMemberConfig.vs_uuid = stringService.slug(poolMemberConfig.vs_uuid);
        }

        if (!poolMemberConfig.ip.isValid()) {
            delete poolMemberConfig.ip;
        }

        if (!poolMemberConfig.public_ip.config.ip.isValid()) {
            delete poolMemberConfig.public_ip;
        }

        if (!(
            geoLocationConfig.name ||
            geoLocationConfig.tag ||
            geoLocationConfig.longitude ||
            geoLocationConfig.latitude
        )) {
            delete gslbGeoLocationConfig.location;
        }

        if (!(gslbGeoLocationConfig.location || gslbGeoLocationConfig.source)) {
            delete poolMemberConfig.location;
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.gslbServicePoolMemberModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'location',
            'ip',
            'public_ip',
        ];
    }

    /**
     * Get VS IP Addresses.
     */
    private async getVsIPAddresses(virtualService: any): Promise<string[]> {
        if (virtualService.isVHChild()) {
            const headerParam = {
                headers_: { 'X-Avi-Internal-GSLB': this.config.cluster_uuid },
            };

            virtualService.addParams(headerParam);

            return await virtualService.getVHParentIPs('allIPs') || [];
        } else {
            return virtualService.getIPAddresses('allIPs') || [];
        }
    }

    /**
     * Set VS id value.
     */
    private setVsId(vsName = ''): void {
        const clusterName = this.config.vs_uuid.split('#');

        if (clusterName.length <= 1) {
            this.config.vs_uuid += `#${vsName}`;
        }
    }
}
