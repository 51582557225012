/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import { isNull, isUndefined } from 'underscore';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import './file-upload-textarea.component.less';

export const enum UploadType {
    FILE = 'file',
    TEXT = 'string',
}

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadTextareaComponent),
        },
    ],
    selector: 'file-upload-textarea',
    templateUrl: './file-upload-textarea.component.html',
})
export class FileUploadTextareaComponent implements ControlValueAccessor {
    /**
     * Placeholder for the textarea.
     */
    @Input()
    public placeholder = '';

    /**
     * Textarea rows value..
     */
    @Input()
    public rows = 10;

    /**
     * Setter for the disabled attribute.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Max file size passed to the FileUploadComponent.
     */
    @Input()
    public maxFileSize = Infinity;

    /**
     * Pass truthy value for binary files.
     */
    @Input()
    public base64 = false;

    /**
     * Fires on filename change or textArea change. Emits enum 'UploadType' value.
     */
    @Output()
    public onUploadTypeChange = new EventEmitter<UploadType>();

    /**
     * File name passed to the file-upload component as a two-way binding. We set this to undefined
     * when the user starts typing in the textarea to avoid displaying a misleading filename.
     */
    public fileName: string;

    /**
     * Disables the file-upload component and the textarea element.
     */
    public disabled = false;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: string;

    /**
     * Getter for the modelValue.
     */
    public get value(): string | undefined {
        return this.modelValue;
    }

    /**
     * Setter for the modelValue.
     */
    public set value(val: string) {
        if (this.modelValue !== val) {
            if (!isUndefined(val) && (isNull(val) || typeof val === 'string' && !val.length)) {
                this.modelValue = undefined;
                this.onChange(undefined);
            } else {
                this.modelValue = val;
                this.onChange(val);
            }
        }

        this.onTouched();
    }

    /**
     * Called when user changes the value of the textarea. Sets the fileName to undefined to avoid
     * displaying a misleading filename.
     */
    public handleTextareaChange(): void {
        this.fileName = undefined;
        this.onUploadTypeChange.emit(UploadType.TEXT);
    }

    /**
     * Called when file name change.
     */
    public handleFileNameChange(): void {
        this.onUploadTypeChange.emit(UploadType.FILE);
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.value = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};
}
