/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { IItemGenericConfig } from 'ajs/modules/data-model/factories/item.factory';
import { SslProfileModalService } from 'ng/modules/security/services/ssl-profile-modal.service';

/**
 * @description
 *      Preview container dealing with subscriptions of SSLModalService.
 *      These are passed down to SslRatingPreviewComponent.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'ssl-rating-preview-container',
    templateUrl: './ssl-rating-preview-container.component.html',
})
export class SslRatingPreviewContainerComponent {
    /**
     * Generic config for item.
     */
    @Input()
    public readonly config: IItemGenericConfig;

    /**
     * SSLRating State as observable.
     * Used to pass metrics (score, performance, compatibility)
     * to SslRatingPreviewComponent.
     */
    public sslRating$ = this.sslProfileModalService.sslRating$;

    /**
     * Loading indicator.
     * Helps in checking the loading status in SslRatingPreviewComponent
     * till we receive SSL rating from modal service.
     */
    public isSslRatingLoading$ = this.sslProfileModalService.sslRatingLoadingStatus$;

    constructor(private readonly sslProfileModalService: SslProfileModalService) {}
}
