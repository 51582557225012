/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './gslb-service-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class GslbServiceListController {
    constructor(
        Auth,
        CRUDGridConfig,
        GSLBServiceCollection,
        l10nService,
    ) {
        this.Auth = Auth;
        this.CRUDGridConfig = CRUDGridConfig;
        this.GSLBServiceCollection = GSLBServiceCollection;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.setGridConfig_();
    }

    /**
     * Creates gridConfig.
     * @protected
     */
    setGridConfig_() {
        const param = this.gslb_ ? { gslb: this.gslb_ } : {};

        const { l10nService_: l10nService } = this;

        this.collection_ = new this.GSLBServiceCollection(param);

        const gridConfigTemplate = {
            collection: this.collection_,
            fields: [{
                name: 'name',
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                template:
                    `<a ui-sref="^.gslbservice-detail.members({ gslbServiceId: '{{ row.id }}' })">
                    {{ row.getName() }}</a>`,
                visibility: 'm',
            }, {
                name: 'domain_name',
                title: l10nService.getMessage(l10nKeys.columnTitleAppDomainName),
                transform(row) {
                    return row.getDomainNames().join(', ');
                },
                visibility: 'm',
            }, {
                name: 'enable_state',
                title: l10nService.getMessage(l10nKeys.columnTitleEnableState),
                template: '{{row.getConfig().enabled | booleanLabel: "enabled"}}',
                visibility: 'm',
            }, {
                name: 'status',
                require: 'runtime, health',
                title: l10nService.getMessage(l10nKeys.columnTitleStatus),
                template: `<avi-healthscore
                item="row"
                type="gslbservice"
                stop-async-on-hide="true" />`,
                visibility: 'm',
            }, {
                name: 'sp_status',
                require: 'runtime',
                title: l10nService.getMessage(l10nKeys.columnSitePersistenceStatus),
                template: "{{row.getRuntimeData()['sp_oper_status']['state'] | unitStateLabel}}",
            }],
            layout: {
                includeTimeframeSelector: true,
                disabledCreateButtonLabel: this.getDisabledCreateButtonTitle_(),
            },
        };

        this.gslbServiceGridConfig = this.CRUDGridConfig(gridConfigTemplate);

        const { objectName } = this.collection_;

        this.gslbServiceGridConfig.id = `${objectName}-list-page`;

        this.gslbServiceGridConfig.multipleactions.push(
            this.getEnablementActionConfig_(true),
            this.getEnablementActionConfig_(false),
        );

        this.gslbServiceGridConfig.checkboxDisable = row => !row.isEditable();
    }

    /**
     * Determines the hover text if edit button is disabled, depending on if is child site, or
     * if is missing appropriate permisssions for editing.
     * @return {string}
     * @protected
     */
    getDisabledCreateButtonTitle_() {
        const { gslb, Auth, l10nService_: l10nService } = this;

        if (gslb) {
            return l10nService.getMessage(l10nKeys.gslbSiteLeaderMessage,
                [gslb.getLeaderSiteName()]);
        }

        if (!Auth.isAllowed('PERMISSION_GSLB') && Auth.isAllowed('PERMISSION_GSLBSERVICE', 'w')) {
            return l10nService.getMessage(l10nKeys.gslbConfigPermissionsMessage);
        }
    }

    /**
     * Returns a multiple action config for the use of collection-grid.
     * @param {boolean} enable
     * @returns {Object} Grid multiple action config.
     * @protected
     */
    getEnablementActionConfig_(enable) {
        let disabledPerRow;
        const doPerRow = row => !disabledPerRow(row) && row.setEnabledState(enable);
        const { l10nService_: l10nService } = this;

        if (enable) {
            disabledPerRow = row => row.isEnabled();
        } else {
            disabledPerRow = row => !row.isEnabled();
        }

        return {
            title: enable ? l10nService.getMessage(l10nKeys.enableTitle) :
                l10nService.getMessage(l10nKeys.disableTitle),
            disabled: rows => _.every(rows, disabledPerRow),
            do: rows => {
                rows.forEach(doPerRow);

                return true;
            },
        };
    }

    $onDestroy() {
        if (this.collection_) {
            this.collection_.destroy();
        }

        if (this.gslb_) {
            this.gslb_.destroy();
        }
    }
}

const componentTag = 'gslb-service-list';
const templateUrl =
    `src/components/pages/application/gslb/${componentTag}/${componentTag}.component.html`;

GslbServiceListController.$inject = [
    'Auth',
    'CRUDGridConfig',
    'GSLBServiceCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name gslbServiceList
 * @param {GSLB|null} gslb_ - gslb instance, or null if unavailable due to permissions
 * @author Alex Malitsky, Akul Aggarwal
 * @description
 *
 *     Grid list of existing gslb services.
 */
angular.module('avi/gslb').component('gslbServiceList', {
    bindings: {
        gslb_: '<resolveGslb',
    },
    controller: GslbServiceListController,
    templateUrl,
});
