/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { formatBytes } from '../utils/math.utils';

/**
 * @description Pipe to transform file size in B, KB, MB, or GB with 1 decimal place rounding.
 *
 * @author Rajawant Prajapati
 */
@Pipe({
    name: 'formatBytes',
})
export class FormatBytesPipe implements PipeTransform {
    /**
     * Transforms file size in B, KB, MB, or GB with 1 decimal place rounding.
     */
    public transform(size: number): string {
        return formatBytes(size);
    }
}
