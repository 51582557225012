/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'client-ip-match';
const prefix = `${moduleName}.${componentName}`;

export const isRadioOption = `${prefix}.isRadioOption`;
export const isNotRadioOption = `${prefix}.isNotRadioOption`;
export const valueInputLabel = `${prefix}.valueInputLabel`;

export const ENGLISH = {
    [isRadioOption]: 'Is',
    [isNotRadioOption]: 'Is not',
    [valueInputLabel]: 'Value',
};
