/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'system-about-info';
const prefix = `${moduleName}.${componentName}`;

export const versionLabel = `${prefix}.versionLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const buildLabel = `${prefix}.buildLabel`;
export const dateLabel = `${prefix}.dateLabel`;

export const ENGLISH = {
    [versionLabel]: 'Version',
    [patchLabel]: 'Patch',
    [buildLabel]: 'Build',
    [dateLabel]: 'Date',
};
