/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'message-item';
import { ICSRFRule } from 'generated-types';
import { CSRFRule } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    MatchTargetConfigItem,
    TMatchField,
} from 'ajs/modules/policies';

import * as l10n from './csrf-rule.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCSRFRulePartial = Omit<ICSRFRule, 'match'>;

interface ICSRFRuleConfig extends TCSRFRulePartial {
    match?: MatchTargetConfigItem,
}

const supportedMatches = [
    'client_ip',
    'method',
    'path',
];

/**
 * @description CSRFRule message item.
 * @author alextsg
 */
export class CSRFRuleConfigItem extends withFullModalMixin(MessageItem)<ICSRFRuleConfig> {
    public static ngDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        super({
            objectType: CSRFRule,
            windowElement: 'lazy-load',
            ...args,
        });

        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Return true if the match exists in this.config.match.
     */
    public hasMatchByField = (matchFieldName: TMatchField): boolean => {
        return this.config.match?.hasMatchByField(matchFieldName) || false;
    };

    /**
     * @override
     * Import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            CsrfRuleModalComponent,
        } = await import(
            /* webpackChunkName: "csrf-policy-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/csrf-policy-modal/csrf-rule-modal/csrf-rule-modal.component'
        );

        return CsrfRuleModalComponent as Type<Component>;
    }

    /**
     * Return the number of matches configured.
     */
    public get matchCount(): number {
        return supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * @override
     * Remove default match object.
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ICSRFRuleConfig> {
        const defaultValues = this.getNgDependency('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            match: null,
        };
    }

    /**
     * @override
     * We clear out flatProps so that the repeatedMessageItems don't get overwritten.
     * This is needed for new match adder component saving IP address match through its
     * repeatedMessageItems and getting around with the legacy _flatProps cache approach.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { match } = this.config;

        if (match) {
            const { client_ip: clientIp } = match.config;

            if (clientIp) {
                clientIp.clearFlatProps();
            }
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.csrfRuleBreadcrumbTitle);
    }

    /** @override */
    protected getModalBreadcrumbDescription(params: IItemParams): string {
        const editable = params.editable as CSRFRuleConfigItem;

        return editable.config.name;
    }
}
