<avi-data-grid
    [config]="authenticationRuleGridConfig"
    [rows]="authRules.config"
    (onRowOrderChange)="handleRowReorder($event)"
    gridTitle="{{ l10nKeys.authenticationRulesLabel | vtranslate : authRules.count }}"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addAuthRule()"
        >
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>

    <ng-template
        #expandedContentTemplateRef
        let-row
    >
        <match-and-action-display-wrapper>
            <match-and-action-display-wrapper_match-content>
                <path-or-query-match-readonly
                    *ngIf="row.config.match.config.path"
                    [editable]="row.config.match.config.path"
                ></path-or-query-match-readonly>

                <ip-addr-match-readonly
                    *ngIf="row.config.match.config.client_ip"
                    [editable]="row.config.match.config.client_ip"
                ></ip-addr-match-readonly>

                <host-hdr-match-readonly
                    *ngIf="row.config.match.config.host_hdr"
                    [editable]="row.config.match.config.host_hdr"
                ></host-hdr-match-readonly>
            </match-and-action-display-wrapper_match-content>

            <match-and-action-display-wrapper_action-content>
                {{ row.authenticationActionType }}
            </match-and-action-display-wrapper_action-content>
        </match-and-action-display-wrapper>
    </ng-template>
</avi-data-grid>
