/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description
 *  Connects Export button to subscribing components that will download logs upon button press.
 * @author Alex Klyuev
 */
@Injectable()
export class VsLogsDownloadService implements OnDestroy {
    /**
     * Observables to track when specific download actions should take place.
     */
    public readonly downloadCurrentPage$ = new Subject<void>();
    public readonly downloadAllLogs$ = new Subject<void>();
    public readonly downloadSelectedLogs$ = new Subject<void>();

    /**
     * Emit the download Subjects to indicate that the specific download should take place.
     */
    public downloadCurrentPage(): void {
        this.downloadCurrentPage$.next();
    }

    public downloadAllLogs(): void {
        this.downloadAllLogs$.next();
    }

    public downloadSelectedLogs(): void {
        this.downloadSelectedLogs$.next();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.downloadCurrentPage$.complete();
        this.downloadAllLogs$.complete();
        this.downloadSelectedLogs$.complete();
    }
}
