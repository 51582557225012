<full-modal-config
    modalTitle="{{ (editMode ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

                <avi-checkbox
                    name="gslb-pool-enabled"
                    objectType="{{ objectType }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                >
                    {{ l10nKeys.enableGslbServicePoolCheckboxLabel | vtranslate }}
                </avi-checkbox>

                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        [uniqueValue]="poolNames"
                        [currentIndex]="poolIndex"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        for="description"
                        objectType="{{ objectType }}"
                        fieldName="description"
                    >
                        {{ globalL10nKeys.descriptionLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                    ></textarea>
                </avi-textarea-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="priority"
                                for="priority"
                            >
                                {{ globalL10nKeys.priorityLabel | vtranslate }}
                            </label>
                            <input
                                name="priority"
                                id="priority"
                                type="number"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="priority"
                                clrInput
                                placeholder="{{ l10nKeys.priorityInputPlaceholder | vtranslate }}"
                                [(ngModel)]="editable.config.priority"
                            />
                        </avi-input-container>
                    </div>

                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="min_health_monitors_up"
                                for="min-health-monitors-up"
                            >
                                {{ l10nKeys.minHealthMonitorsUpInputLabel | vtranslate }}
                            </label>
                            <input
                                name="min-health-monitors-up"
                                id="min-health-monitors-up"
                                type="number"
                                configFieldInputValidation
                                objectType="{{ objectType }}"
                                fieldName="min_health_monitors_up"
                                clrInput
                                placeholder="{{ l10nKeys.minHealthMonitorsUpInputPlaceholder | vtranslate }}"
                                [(ngModel)]="editable.config.min_health_monitors_up"
                            />
                        </avi-input-container>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset [hideContent]="!(allowFallbackAlgorithmConfiguration || allowConsistentHashMaskConfiguration)">
                            <avi-fieldset_header class="gslb-service-pool-modal__avi-fieldset-header">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="algorithm"
                                        for="load-balancing-algorithm"
                                        required
                                    >
                                        {{ l10nKeys.loadBalancingAlgorithmDropdownLabel | vtranslate }}
                                    </label>
                                    <avi-enum-dropdown
                                        name="load-balancing-algorithm"
                                        id="load-balancing-algorithm"
                                        enum="GslbAlgorithm"
                                        [(ngModel)]="editable.config.algorithm"
                                        (ngModelChange)="handleLoadBalancingAlgorithmChange()"
                                        required
                                    ></avi-enum-dropdown>
                                </avi-input-container>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <enum-radio-buttons
                                    *ngIf="allowFallbackAlgorithmConfiguration"
                                    [noMarginTop]="true"
                                    enum="GslbAlgorithm"
                                    name="fallback-algorithm"
                                    [(ngModel)]="editable.config.fallback_algorithm"
                                    (ngModelChange)="handleFallbackAlgorithmChange()"
                                    [hiddenEnumValues]="enumHiddenFallbackAlgorithms"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="fallback_algorithm"
                                        for="fallback-algorithm"
                                    >
                                        {{ l10nKeys.fallbackAlgorithmSelectionLabel | vtranslate }}
                                    </label>
                                </enum-radio-buttons>

                                <avi-input-container
                                    [noMarginTop]="!allowFallbackAlgorithmConfiguration"
                                    *ngIf="allowConsistentHashMaskConfiguration"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType }}"
                                        fieldName="consistent_hash_mask"
                                        for="consistent-hash-mask"
                                    >
                                        {{ l10nKeys.consistentHashMaskInputLbel | vtranslate }}
                                    </label>
                                    <input
                                        name="consistent-hash-mask"
                                        id="consistent-hash-mask"
                                        type="number"
                                        configFieldInputValidation
                                        objectType="{{ objectType }}"
                                        fieldName="consistent_hash_mask"
                                        clrInput
                                        placeholder="{{ l10nKeys.consistentHashMaskInputPlaceholder | vtranslate }}"
                                        [(ngModel)]="editable.config.consistent_hash_mask"
                                    />
                                </avi-input-container>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.poolMemberSectionHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.poolMemberSectionHeader | vtranslate }}</h3>
                <gslb-service-pool-members-grid
                    [poolMembers]="editable.config.members"
                    (onAddPoolMember)="handleAddPoolMember()"
                    (onEditPoolMember)="handleEditPoolMember($event)"
                    (onDeletePoolMember)="editable.deletePoolMember($event)"
            ></gslb-service-pool-members-grid>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
