/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    AuthSamlEntityType,
    ISamlServiceProviderNode,
    ISamlServiceProviderSettings,
} from 'generated-types';

import { SamlServiceProviderSettings } from 'object-types';

type TSamlServiceProviderSettingsPartial = Omit<ISamlServiceProviderSettings, 'sp_nodes'>;

export interface ISamlServiceProviderSettingsConfig extends TSamlServiceProviderSettingsPartial {
    sp_nodes?: MessageItem<Array<MessageItem<ISamlServiceProviderNode>>>,
}

/**
 * @description SAML(Service Provider) Message Item.
 * @author Suraj Kumar
 */
export class SamlServiceProviderSettingsConfigItem extends
    MessageItem<ISamlServiceProviderSettingsConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: SamlServiceProviderSettings,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ISamlServiceProviderSettingsConfig> {
        return {
            saml_entity_type: AuthSamlEntityType.AUTH_SAML_CLUSTER_VIP,
        };
    }

    /**
     * Sets SAML entity type and updates SAML sub-configuration fields.
     */
    public setSamlEntityType(type: AuthSamlEntityType, editMode: boolean): void {
        if (editMode) {
            return;
        }

        // Nothing but type is needed for this one.
        if (type === AuthSamlEntityType.AUTH_SAML_APP_VS) {
            this.updateConfig({
                saml_entity_type: AuthSamlEntityType.AUTH_SAML_APP_VS,
            });

            return;
        }

        this.updateConfig({
            saml_entity_type: type,
            fqdn: undefined,
        });
    }
}
