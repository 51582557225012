<div class="avi-data-grid">
    <h6
        *ngIf="gridTitle"
        class="avi-data-grid__grid-title"
    >
        {{ gridTitle }}
    </h6>
    <div class="avi-data-grid__header">
        <ng-content select="avi-data-grid_header"></ng-content>
    </div>
    <div
        *ngIf="hasAlerts"
        class="avi-data-grid__grid-alerts"
    >
        <ng-content select="avi-data-grid_alerts"></ng-content>
    </div>
    <avi-data-grid-base
        [config]="config"
        [rows]="displayedRows"
        [rowsTotal]="rows?.length || 0"
        [isLoading]="isLoading"
        (onSelectionChange)="onRowSelectionChange($event)"
        (onDataGridStateChange)="updateLocalDataGridState($event)"
        (onRowOrderChange)="handleRowOrderChange($event)"
        (onSearch)="handleSearch($event)"
        [showSearch]="config?.searchKeys?.length > 0 && !config?.layout?.hideSearch"
    >
        <ng-container ngProjectAs="avi-data-grid_actions">
            <ng-content select="avi-data-grid_actions"></ng-content>
        </ng-container>
    </avi-data-grid-base>
</div>
