<div class="clr-wrapper ipam-dns-aws-credentials-dialog">
    <credentials-verification-dialog
        [disableSubmit]="form.invalid"
        (onCancel)="handleCancel()"
        (onSubmit)="handleSubmit()"
        [errors]="errors"
        [busy]="busy"
    >
        <credentials-verification-dialog_header>
            <h3>{{ l10nKeys.setAwsCredentialsHeader | vtranslate }}</h3>
        </credentials-verification-dialog_header>
        <credentials-verification-dialog_form>
            <form
                clrForm
                [clrLayout]="verticalLayout"
                #form="ngForm"
            >
                <avi-fieldset>
                    <avi-fieldset_header>
                        <avi-radio-container [noMarginTop]="true">
                            <avi-radio
                                name="useIamRoles"
                                [(ngModel)]="config.useIamRoles"
                                (ngModelChange)="onUseIamRolesChange()"
                                [value]="false"
                            >
                                {{ l10nKeys.useAccessKeysOption | vtranslate }}
                            </avi-radio>
                            <avi-radio
                                name="useIamRoles"
                                [(ngModel)]="config.useIamRoles"
                                (ngModelChange)="onUseIamRolesChange()"
                                [value]="true"
                                [disabled]="!(isAwsSetup$ | async)"
                            >
                                {{ l10nKeys.useIamRolesOption | vtranslate }}
                            </avi-radio>
                        </avi-radio-container>
                    </avi-fieldset_header>
                    <avi-fieldset_content>
                        <div
                            class="full-modal-config__form-control-row ipam-dns-aws-credentials-dialog__fieldset-header"
                            *ngIf="!config.useIamRoles"
                        >
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                                        fieldName="access_key_id"
                                        for="access_key_id"
                                        required
                                    >
                                        {{ l10nKeys.accessKeyIdInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="access_key_id"
                                        type="password"
                                        placeholder="{{ l10nKeys.accessKeyIdInputPlaceholder | vtranslate }}"
                                        name="access_key_id"
                                        [(ngModel)]="config.username"
                                        required
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                                        fieldName="secret_access_key"
                                        for="secret_access_key"
                                        required
                                    >
                                        {{ l10nKeys.secretAccessKeyInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="secret_access_key"
                                        type="password"
                                        placeholder="{{ l10nKeys.secretAccessKeyInputPlaceholder | vtranslate }}"
                                        name="secret_access_key"
                                        [(ngModel)]="config.password"
                                        required
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectTypes.IpamDnsAwsProfile }}"
                                    fieldName="region"
                                    for="region"
                                    required
                                >
                                    {{ l10nKeys.regionInputLabel | vtranslate }}
                                </label>
                                <avi-dropdown
                                    name="region"
                                    [options]="regionDropdownOptions"
                                    [(ngModel)]="config.region"
                                    placeholder="{{ l10nKeys.regionInputPlaceholder | vtranslate }}"
                                    required
                                ></avi-dropdown>
                            </div>
                        </div>
                    </avi-fieldset_content>
                </avi-fieldset>
                <h6 class="ipam-dns-aws-credentials-dialog__section-header">{{ l10nKeys.proxyHeader | vtranslate }}</h6>
                <avi-fieldset [hideContent]="!config.useProxy">
                    <avi-fieldset_header>
                        <clr-checkbox-container class="ipam-dns-aws-credentials-dialog__fieldset-header">
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    name="useProxy"
                                    [(ngModel)]="config.useProxy"
                                    id="useProxy"
                                    (ngModelChange)="onUseProxyChange()"
                                />
                                <label
                                    aviLabelWithTooltip
                                    for="useProxy"
                                >
                                    {{ l10nKeys.accessAwsThroughProxyInputLabel | vtranslate }}
                                </label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </avi-fieldset_header>
                    <avi-fieldset_content *ngIf="config.useProxy">
                        <div class="full-modal-config__form-control-row ipam-dns-aws-credentials-dialog__fieldset-header">
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.ProxyConfiguration }}"
                                        fieldName="host"
                                        for="host"
                                        required
                                    >
                                        {{ l10nKeys.proxyHostInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="host"
                                        placeholder="{{ l10nKeys.proxyHostInputPlaceholder | vtranslate }}"
                                        name="host"
                                        [(ngModel)]="config.proxy.host"
                                        regexPattern="hostname"
                                        required
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.ProxyConfiguration }}"
                                        fieldName="port"
                                        for="port"
                                        required
                                    >
                                        {{ l10nKeys.proxyPortInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="port"
                                        type="number"
                                        placeholder="{{ l10nKeys.proxyPortInputPlaceholder | vtranslate }}"
                                        name="port"
                                        [(ngModel)]="config.proxy.port"
                                        configFieldInputValidation
                                        objectType="{{ objectTypes.ProxyConfiguration }}"
                                        fieldName="port"
                                        required
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.ProxyConfiguration }}"
                                        fieldName="username"
                                        for="host_username"
                                    >
                                        {{ l10nKeys.proxyUsernameInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="host_username"
                                        placeholder="{{ l10nKeys.proxyUsernameInputPlaceholder | vtranslate }}"
                                        name="host_username"
                                        [(ngModel)]="config.proxy.username"
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container noMarginTop>
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypes.ProxyConfiguration }}"
                                        fieldName="password"
                                        for="host_password"
                                    >
                                        {{ l10nKeys.proxyPasswordInputLabel | vtranslate }}
                                    </label>
                                    <input
                                        clrInput
                                        id="host_password"
                                        type="password"
                                        placeholder="{{ l10nKeys.proxyPasswordPlaceholder | vtranslate }}"
                                        name="host_password"
                                        [(ngModel)]="config.proxy.password"
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                    </avi-fieldset_content>
                </avi-fieldset>
            </form>
        </credentials-verification-dialog_form>
    </credentials-verification-dialog>
</div>
