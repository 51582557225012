/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name httpStatusRangeParser
 * @restrict A
 * @description
 *      Parses parser/formatter for HttpStatusMatch::ranges instance.
 */
angular.module('aviApp').directive('httpStatusRangeParser', function() {
    function httpStatusRangeParserLink(scope, elm, attr, ngModel) {
        /**
         * @param {string} val
         * @return {Object[]}
         */
        function httpStatusRangeParser(val) {
            if (angular.isString(val)) {
                const ranges = [];

                val.replace(/\s+/g, '').split(',').forEach(v => {
                    const range = v.split('-');

                    if (range.length === 2) {
                        const begin = parseInt(range[0], 10);
                        const end = parseInt(range[1], 10);

                        if (!_.isNaN(begin) && !_.isNaN(end)) {
                            ranges.push({
                                begin,
                                end,
                            });
                        }
                    }
                });

                return ranges;
            }
        }

        /**
         * @param {Object[]} ranges
         * @return {string}
         */
        function httpStatusRangeFormatter(ranges) {
            let str = '';

            if (Array.isArray(ranges)) {
                str = ranges.map(range => `${range.begin}-${range.end}`).join(', ');
            }

            return str;
        }

        ngModel.$parsers.push(httpStatusRangeParser);
        ngModel.$formatters.push(httpStatusRangeFormatter);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link: httpStatusRangeParserLink,
    };
});
