/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name policyGridMatchColumn
 * @description Component for displaying the match configuration in a grid field.
 * @param {LegacyPolicyRuleConfigItem} rule
 * @param {PolicyGridConfig} config
 */
class PolicyGridMatchColumnController {
    constructor(PolicyGridService, LegacyPolicyRuleConfigItem) {
        this.PolicyGridService_ = PolicyGridService;
        this.LegacyPolicyRuleConfigItem_ = LegacyPolicyRuleConfigItem;
    }

    $onInit() {
        this.isLegacyPolicyRuleConfigItem = this.rule instanceof this.LegacyPolicyRuleConfigItem_;

        if (!this.isLegacyPolicyRuleConfigItem) {
            this.matches = this.config.collection.matches;
        }
    }

    /**
     * Returns true if rule has any matches
     * @return {boolean}
     */
    hasMatches() {
        if (this.isLegacyPolicyRuleConfigItem) {
            return this.rule.hasMatches();
        } else {
            const { match } = this.rule;

            return match && angular.isObject(match) && Object.keys(match).length > 0;
        }
    }

    /**
     * Returns all Matches in the rule.
     * @return {Array<MatchConfigItem>}
     */
    getMatches() {
        return this.rule.getMatches();
    }

    /**
     * Returns the name of the match property to be displayed.
     * @param {string} property - Match property.
     * @return {string}
     */
    getMatchName(property) {
        return this.PolicyGridService_.getMatchName(property);
    }

    /**
     * Returns a string representation of the match configuration.
     * @param {string} property - Match property.
     * @param {Object} match - Match object containing the config.
     * @return {string}
     */
    getMatchString(property, match) {
        let matchConfig = match;

        if (this.isLegacyPolicyRuleConfigItem) {
            matchConfig = match.getConfig();
        }

        return this.PolicyGridService_.getMatchString(property, matchConfig);
    }
}

PolicyGridMatchColumnController.$inject = [
    'PolicyGridService',
    'LegacyPolicyRuleConfigItem',
];

angular.module('aviApp').component('policyGridMatchColumn', {
    bindings: {
        rule: '<',
        config: '<',
    },
    controller: PolicyGridMatchColumnController,
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-match-column/policy-grid-match-column.html',
});
