/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import { isNumber } from 'underscore';

export const CONFIG_FIELD_INPUT_VALIDATOR_KEY = 'configFieldInputValidation';

/**
 * Returns validatorFunction for configFieldInputValidation directive.
 *
 * @author Aravindh Nagarajan
 */
export function configFieldInputValidator(
    min = -Infinity,
    max = Infinity,
    step = 1,
    specialValues: number[] = [],
): ValidatorFn {
    /**
     * Returns null if it's a valid value (>= min && <= max && value % step is 0).
     * Returns validationError if input is invalid.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        const errorObject = {
            [CONFIG_FIELD_INPUT_VALIDATOR_KEY]: value,
        };

        if (specialValues.length && value in specialValues) {
            return null;
        }

        if (isNumber(min) && value < min) {
            return errorObject;
        }

        if (isNumber(max) && value > max) {
            return errorObject;
        }

        if (step !== 0 && isNumber(step) && value % step !== 0) {
            return errorObject;
        }

        return null;
    };
}
