/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'virtual-service-create-basic-controller';
const prefix = `${moduleName}.${componentName}`;

export const listColumnHeaderVirtualMachineName = `${prefix}.listColumnHeaderVirtualMachineName`;
export const listColumnHeaderIPAddress = `${prefix}.listColumnHeaderIPAddress`;
export const listColumnHeaderMacAddress = `${prefix}.listColumnHeaderMacAddress`;
export const addServersActionTitle = `${prefix}.addServersActionTitle`;
export const listColumnHeaderServerName = `${prefix}.listColumnHeaderServerName`;
export const listColumnHeaderResolveServerByDns = `${prefix}.listColumnHeaderResolveServerByDns`;
export const listColumnHeaderNetwork = `${prefix}.listColumnHeaderNetwork`;
export const removeActionTitle = `${prefix}.removeActionTitle`;
export const serverAlreadyPresentErrorMessage = `${prefix}.serverAlreadyPresentErrorMessage`;
export const maxServersOverflowErrorMessage = `${prefix}.maxServersOverflowErrorMessage`;
export const maxIpAddressesOverflowErrorMessage = `${prefix}.maxIpAddressesOverflowErrorMessage`;
export const newVirtualServiceHeader = `${prefix}.newVirtualServiceHeader`;
export const selectCloudHeader = `${prefix}.selectCloudHeader`;
export const cloudsHeader = `${prefix}.cloudsHeader`;
export const selectVrfContextHeader = `${prefix}.selectVrfContextHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const applicationTypeInputLabel = `${prefix}.applicationTypeInputLabel`;
export const servicePortInputLabel = `${prefix}.servicePortInputLabel`;
export const servicePortInputPlaceholder = `${prefix}.servicePortInputPlaceholder`;
export const certificateInputLabel = `${prefix}.certificateInputLabel`;
export const certificateInputPlaceholder = `${prefix}.certificateInputPlaceholder`;
export const selectServersByNetworkHeader = `${prefix}.selectServersByNetworkHeader`;
export const selectNetworkInputPlaceholder = `${prefix}.selectNetworkInputPlaceholder`;
export const addServersHeader = `${prefix}.addServersHeader`;
export const selectServersLabel = `${prefix}.selectServersLabel`;
export const ipAddressRangeDnsRadioInputLabel = `${prefix}.ipAddressRangeDnsRadioInputLabel`;
export const ipGroupRadioInputLabel = `${prefix}.ipGroupRadioInputLabel`;
export const securityGroupsRadioInputLabel = `${prefix}.securityGroupsRadioInputLabel`;
export const azureScaleSetRadioInputLabel = `${prefix}.azureScaleSetRadioInputLabel`;
export const autoScalingGroupsRadioInputLabel = `${prefix}.autoScalingGroupsRadioInputLabel`;
export const selectServersByNetworkButtonLabel = `${prefix}.selectServersByNetworkButtonLabel`;
export const ipGroupInputLabel = `${prefix}.ipGroupInputLabel`;
export const ipGroupInputPlaceholder = `${prefix}.ipGroupInputPlaceholder`;
export const nsxSecurityGroupsInputLabel = `${prefix}.nsxSecurityGroupsInputLabel`;
export const nsxSecurityGroupsInputPlaceholder = `${prefix}.nsxSecurityGroupsInputPlaceholder`;
export const autoScalingGroupsInputLabel = `${prefix}.autoScalingGroupsInputLabel`;
export const autoScalingGroupsInputPlaceholder = `${prefix}.autoScalingGroupsInputPlaceholder`;
export const serversHeader = `${prefix}.serversHeader`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const nextButtonLabel = `${prefix}.nextButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const vsVipInputLabel = `${prefix}.vsVipInputLabel`;
export const vsVipInputPlaceholder = `${prefix}.vsVipInputPlaceholder`;
export const createVsVipButtonLabel = `${prefix}.createVsVipButtonLabel`;

export const ENGLISH = {
    [listColumnHeaderVirtualMachineName]: 'Virtual Machine Name',
    [listColumnHeaderIPAddress]: 'IP Address',
    [listColumnHeaderMacAddress]: 'MAC Address',
    [addServersActionTitle]: 'Add Servers',
    [listColumnHeaderServerName]: 'Server Name',
    [listColumnHeaderResolveServerByDns]: 'Resolve by DNS',
    [listColumnHeaderNetwork]: 'Network',
    [removeActionTitle]: 'Remove',
    [serverAlreadyPresentErrorMessage]: 'Can\'t add server {0} to the list because server with the same IP and port combination is already present in a list.',
    [maxServersOverflowErrorMessage]: 'Can\'t add more servers, up to {0} is supported.',
    [maxIpAddressesOverflowErrorMessage]: 'Too many ({0}) in IP group. Only {1} addresses supported.',
    [newVirtualServiceHeader]: 'New Virtual Service: <strong>{0}</strong>',
    [selectCloudHeader]: 'Select Cloud',
    [cloudsHeader]: 'Clouds',
    [selectVrfContextHeader]: 'Select VRF Context',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Virtual Service Name',
    [applicationTypeInputLabel]: 'Application Type',
    [servicePortInputLabel]: 'Service',
    [servicePortInputPlaceholder]: 'Port',
    [certificateInputLabel]: 'Certificate',
    [certificateInputPlaceholder]: 'Select',
    [selectServersByNetworkHeader]: 'Select Servers by Network',
    [selectNetworkInputPlaceholder]: 'Select a Network',
    [addServersHeader]: 'Add Servers',
    [selectServersLabel]: 'Select Servers',
    [ipAddressRangeDnsRadioInputLabel]: 'IP Address, Range, or DNS Name',
    [ipGroupRadioInputLabel]: 'IP Group',
    [securityGroupsRadioInputLabel]: 'Security Groups',
    [azureScaleSetRadioInputLabel]: 'Azure Scale Set',
    [autoScalingGroupsRadioInputLabel]: 'Auto Scaling groups',
    [selectServersByNetworkButtonLabel]: 'Select Servers by Network',
    [ipGroupInputLabel]: 'IP Group',
    [ipGroupInputPlaceholder]: 'Select IP Group',
    [nsxSecurityGroupsInputLabel]: 'NSX Security Groups',
    [nsxSecurityGroupsInputPlaceholder]: 'Select Security Group',
    [autoScalingGroupsInputLabel]: 'Auto Scaling groups',
    [autoScalingGroupsInputPlaceholder]: 'Select Auto Scaling groups',
    [vsVipInputLabel]: 'VS VIP',
    [vsVipInputPlaceholder]: 'Select VS VIP',
    [createVsVipButtonLabel]: 'Create VS VIP',
    [serversHeader]: 'Servers',
    [cancelButtonLabel]: 'Cancel',
    [nextButtonLabel]: 'Next',
    [saveButtonLabel]: 'Save',
};
