<avi-notification
    class="utc-mismatch-notification"
    type="warning"
    (onClose)="handleClose()"
>
    <div class="message-text">
        <b 
            [innerHTML]="(localClockIsBehind ? l10nKeys.systemClockBehindMessage : l10nKeys.systemClockAheadMessage) 
                | vtranslate 
                : (l10nKeys.timeDiffSecondsTitle | vtranslate : timeDiff)
                : timeDiffInHrMin">
        </b>    
        <p>{{ l10nKeys.controllerClock | vtranslate : serverTime }}</p>
        <p>{{ l10nKeys.timeDiffImpactMessage | vtranslate }}</p>
        <p>
            {{ checkNtpSettingsMessageList[0] }}
            <a
                *ngIf="userHasAccessToSysConfig"
                class="utc-mismatch-notification__link"
                (click)="redirectToSystemConfig()"
            >
                {{ checkNtpSettingsMessageList[1] }}<!--
            --></a><!--
            -->{{ checkNtpSettingsMessageList[2] }}
            <span *ngIf="!userHasAccessToSysConfig">{{ l10nKeys.ntpSettings | vtranslate }}</span>
        </p>
    </div>
</avi-notification>
