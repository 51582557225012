<div class="welcome-tenant-settings">
    <avi-radio-container>
        <label
            aviLabelWithTooltip
            objectType="TenantConfiguration"
            fieldName="tenant_vrf"
            for="tenant_vrf"
        >
            {{ l10nKeys.ipRouteDomainLabel | vtranslate }}
        </label>
        <avi-radio
            name="tenant_vrf"
            [(ngModel)]="tenantConfig.tenant_vrf"
            [value]="true"
        >
            {{ l10nKeys.perTenantIpRouteDomainLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            name="tenant_vrf"
            [(ngModel)]="tenantConfig.tenant_vrf"
            [value]="false"
        >
            {{ l10nKeys.shareIpRouteAcrossTenantsLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>
    <div class="welcome-tenant-settings__se-config">
        <label
            aviLabelWithTooltip
            objectType="TenantConfiguration"
            fieldName="se_in_provider_context"
            for="se_in_provider_context"
        >
            {{ l10nKeys.serviceEnginesAreManagedWithinTheLabel | vtranslate }}
        </label>
        <fieldset class="welcome-tenant-settings__se-config-fieldset">
            <div class="welcome-tenant-settings__se-config-provider-context">
                <avi-radio-container [noMarginTop]="true">
                    <avi-radio
                        name="se_in_provider_context"
                        [(ngModel)]="tenantConfig.se_in_provider_context"
                        [value]="false"
                    >
                        {{ l10nKeys.notSharedAcrossTenantsLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="se_in_provider_context"
                        [(ngModel)]="tenantConfig.se_in_provider_context"
                        [value]="true"
                    >
                        {{ l10nKeys.sharedAcrossTenantsLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
            </div>
            <div
                class="welcome-tenant-settings__se-config-provider-context welcome-tenant-settings__se-config-provider-context--access"
                [hidden]="!tenantConfig.se_in_provider_context"
            >
                <avi-radio-container
                    [noMarginTop]="true"
                    class="welcome-tenant-settings__se-provider-context-access-select"
                >
                    <label
                        aviLabelWithTooltip
                        objectType="TenantConfiguration"
                        fieldName="tenant_access_to_provider_se"
                        for="tenant_access_to_provider_se"
                    >
                        {{ l10nKeys.tenantAccessToServiceEngineLabel | vtranslate }}
                    </label>
                    <avi-radio
                        name="tenant_access_to_provider_se"
                        [(ngModel)]="tenantConfig.tenant_access_to_provider_se"
                        [value]="true"
                    >
                        {{ l10nKeys.readAccessLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="tenant_access_to_provider_se"
                        [(ngModel)]="tenantConfig.tenant_access_to_provider_se"
                        [value]="false"
                    >
                        {{ l10nKeys.noAccessLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
            </div>
        </fieldset>
    </div>
</div>
