/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { Subject } from 'rxjs';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { L10nService } from '@vmw/ngx-vip';
import { Pool } from 'object-types';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { HealthScoreClassPipe } from 'ng/modules/health-score/pipes/health-score-class.pipe';
import { UpdatableItem } from 'ajs/modules/data-model/factories/updatable-item.factory';

import {
    IOperationalStatus,
    OperationalState,
} from 'generated-types';

import {
    SignpostPosition,
    signpostPositionMap,
    SIGNPOST_RIGHT_CONNECTED_POSITION,
} from 'ng/modules/tooltip/components/signpost/signpost.constants';

import * as globalL10n from 'global-l10n';
import * as l10n from './avi-health-score.l10n';
import './avi-health-score.component.less';

/**
 * Set of operational statuses for deactivated icons.
 */
const deactivatedIconOperStates = new Set([
    OperationalState.OPER_DISABLED,
    OperationalState.OPER_INACTIVE,
    OperationalState.OPER_UNUSED,
]);

/**
 * Set of operational statuses for no exclamation icon.
 */
const noExclamationIconOperStates = new Set([
    OperationalState.OPER_UP,
    OperationalState.OPER_DISABLED,
    OperationalState.OPER_INACTIVE,
    OperationalState.OPER_UNUSED,
    OperationalState.OPER_ERROR_DISABLED,
    OperationalState.OPER_DISABLING,
]);

/**
 * Set of operational statuses when health score is available.
 */
const availableHealthScoreOperStates = new Set([
    OperationalState.OPER_UP,
    OperationalState.OPER_DISABLING,
    OperationalState.OPER_ERROR_DISABLED,
]);

/**
 * Health score views based on pool state.
 */
enum HealthScoreView {
    DOWN = 'down',
    UP = 'up',
    LOADING = 'loading',
    ERROR = 'error',
}

/**
 * Component selector for health score component.
 */
const COMPONENT_SELECTOR = 'avi-health-score';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Avi health score component.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: COMPONENT_SELECTOR,
    templateUrl: './avi-health-score.component.html',
    providers: [
        HealthScoreClassPipe,
    ],
})
export class AviHealthScoreComponent implements OnInit, OnDestroy {
    /**
     * Editable item of type UpdatableItem.
     * TODO - Convert UpdatableItem to VirtualService | ServiceEngine | Pool
     * once all are migrated to item
     */
    @Input()
    public editable: UpdatableItem;

    /**
     * Fires on click of View Health button.
     */
    @Output()
    public onViewHealthClick = new EventEmitter<void>();

    /**
     * Fires on click of Health Score icon.
     */
    @Output()
    public onHealthScoreIconClick = new EventEmitter<void>();

    /**
     * Signpost position priorities.
     */
    public readonly signpostPositionPriorities: ConnectedPosition[] = [
        SIGNPOST_RIGHT_CONNECTED_POSITION,
    ];

    /**
     * Signpost position.
     */
    public readonly signpostPosition: SignpostPosition = signpostPositionMap
        .get(SIGNPOST_RIGHT_CONNECTED_POSITION);

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Hold item's current state label.
     */
    public stateLabel: string;

    /**
     * Hold item's current state reasons.
     */
    public reasons: string[] = [];

    /**
     * Show/hide health score value.
     */
    public isHealthScoreValueAvailable: boolean;

    /**
     * Hold the health score class name based on score.
     */
    public healthScoreClass: string;

    /**
     * Show/hide deactivated icon.
     */
    public showDeactivatedIcon: boolean;

    /**
     * Show/hide exclamation indicator.
     */
    public showExclamation: boolean;

    /**
     * Title for the popup.
     */
    public title: string;

    /**
     * Subject to control the hide/show signpost close button.
     */
    public signpostCloseControl$ = new Subject<boolean>();

    /**
     * Current operational status of the item.
     */
    public operationStatus: IOperationalStatus;

    /**
     * Health score views.
     */
    public readonly healthScoreView = HealthScoreView;

    public readonly poolObjectName = Pool.toLowerCase();

    /**
     * Current view to render based on state of health score.
     */
    public currentViewToRender = HealthScoreView.LOADING;

    public objectName = '';

    /**
     * To hide the signpost for the first time.
     */
    private showSignpostForFirstTime = true;

    constructor(
        public readonly l10nService: L10nService,
        public readonly schemaService: SchemaService,
        private readonly devLoggerService: DevLoggerService,
        private readonly healthScoreClassPipe: HealthScoreClassPipe,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.title = this.editable.getName();
        this.objectName = this.editable.getItemType();
        this.fetchHeathScoreData();
    }

    /**
     * Fetch the health score related data for current item.
     */
    public handleHealthScoreClick(): void {
        this.onHealthScoreIconClick.emit();
    }

    /**
     * Fetch the health score related data for current item.
     */
    public fetchHeathScoreData(): void {
        this.currentViewToRender = HealthScoreView.LOADING;

        Promise.all([
            this.editable.loadHsGlance(),
            this.editable.loadMetrics(['alert', 'health', 'faults'], undefined, undefined),
        ]).then(() => {
            this.currentViewToRender = this.operationStatus?.state === OperationalState.OPER_UP ?
                this.healthScoreView.UP : this.healthScoreView.DOWN;

            this.operationStatus = this.editable.data.runtime.oper_status;
            this.setStateLabel();

            this.setReasons();
            this.setIsHealthScoreValueAvailable();
            this.setShowExclamation();
            this.setShowDeactivatedIcon();

            if (this.showSignpostForFirstTime) {
                this.signpostCloseControl$.next(true);
                this.showSignpostForFirstTime = false;
            } else {
                this.onHealthScoreIconClick.emit();
            }
        }).catch((error: Error) => {
            this.devLoggerService.error(error);
            this.currentViewToRender = this.healthScoreView.ERROR;
        }).finally(() => {
            this.setHealthScoreClass();
        });
    }

    /**
     * Close the signpost.
     */
    public closeSignpost(): void {
        this.signpostCloseControl$.next(false);
    }

    /**
     * Handle click event on the view health button.
     */
    public handleViewHealthButtonClick(): void {
        this.onViewHealthClick.emit();
    }

    /**
     * Handle mouse leave event on the health score count and tooltip.
     */
    public handleHealthScoreMouseLeave($event: MouseEvent): void {
        // Get the current mouse over element
        const { toElement: hoverElement } = $event as any;

        // Close the overlay if hover element is not health score component.
        if (hoverElement?.tagName.toLocaleLowerCase() !== COMPONENT_SELECTOR) {
            this.closeSignpost();
        }
    }

    /**
     * Set the heath score class name based on score.
     */
    public setHealthScoreClass(): void {
        const { state } = this.operationStatus || {};

        const { health_score: score } = this.editable.data.health_score || {};

        this.healthScoreClass = this.healthScoreClassPipe.transform(score, state);
    }

    /**
     * Check if the health score is available.
     */
    public setIsHealthScoreValueAvailable(): void {
        const { state } = this.operationStatus || {};

        this.isHealthScoreValueAvailable = availableHealthScoreOperStates.has(state);
    }

    /**
     * Set reasons if state has reason.
     */
    public setReasons(): void {
        this.reasons = this.operationStatus?.reason || [];
    }

    /**
     * Set item's current state label.
     */
    public setStateLabel(): void {
        const { state } = this.operationStatus || {};

        if (!state) {
            this.stateLabel = this.l10nService.getMessage(globalL10nKeys.notApplicableLabel);

            return;
        }

        this.stateLabel = this.schemaService.getEnumValueLabel('OperationalState', state);
    }

    /**
     * Check whether to show the deactivated icon.
     */
    public setShowDeactivatedIcon(): void {
        const { state } = this.operationStatus || {};

        this.showDeactivatedIcon = deactivatedIconOperStates.has(state);
    }

    /**
     * Check whether to show the exclamation indicator.
     */
    public setShowExclamation(): void {
        const { state } = this.operationStatus || {};

        this.showExclamation = !noExclamationIconOperStates.has(state);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeSignpost();
    }
}
