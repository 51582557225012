/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function clusterNodeListCollDataSourceFactory(ListCollDataSource) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListCollDataSource
     * @author Ashish Verma
     * @desc ListCollDataSource of {@link module:avi/app.ClusterNodeCollollection
     *       ClusterNodeCollollection}.
     */
    class ClusterNodeListCollDataSource extends ListCollDataSource { }

    Object.assign(ClusterNodeListCollDataSource.prototype, {
        hasSearch: false,
        hasSorting: false,
        hasPagination: false,
        defaultParams_: {},
    });

    return ClusterNodeListCollDataSource;
}

clusterNodeListCollDataSourceFactory.$inject = [
    'ListCollDataSource',
];

angular.module('avi/app').factory(
    'ClusterNodeListCollDataSource',
    clusterNodeListCollDataSourceFactory,
);
