/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @name  ComponentTemplateStringBuilder
 * @ngdoc service
 * @description
 *     Service to create a template string from an object of bindings for use by AviModal.
 *     Bindings to be opened by AviModal must be defined as follows:
 * @example
 *     <caption>
 *         Controller opening modal. Functions containing parameters must be defined as an array,
 *         similarly to Angular's dependency injection.
 *     </caption>
 *     <code>
 *         AviModal.open('component-name', {
 *             uuid: row.data.config.uuid,
 *             config: config,
 *             submit: ['config', function(config) {}],
 *             validate: function() {}
 *         });
 *     </code>
 *     <caption>Component definition</caption>
 *     <code>
 *         aviApp.component('componentName', {
 *             bindings: {
 *                 uuid: '@',
 *                 config: '<',
 *                 submit: '&',
 *                 validate: '&',
 *                 closeModal: '&'
 *             },
 *             controller: Controller,
 *             templateUrl: 'src/views/components/modals/component-name.html'
 *         });
 *     </code>
 */
angular.module('aviApp').factory('ComponentTemplateStringBuilder', ['$injector', 'stringService',
function($injector, stringService) {
    /**
     * Returns the template string to be compiled.
     * @param  {string} componentId - Component tag.
     * @param  {Object=} bindings - Properties to be placed on the component scope.
     * @param {string=} className - string of classes to be used with modal.
     * @return {string}
     */
    function getTemplateString(componentId, bindings, className) {
        const end = `</${componentId}>`;
        const camelCaseId = $.camelCase(componentId);
        const [{ bindToController, controller }] = $injector.get(`${camelCaseId}Directive`);
        const modalPortalClassName = controller ? controller.modalPortalClassName : '';
        const classes = `class="${className || modalPortalClassName || 'modal-component'}"`;

        let start = `<${componentId} `;

        start += `${classes} `;

        _.each(bindings, function(value, key) {
            const dashCasedKey = stringService.camelCaseToDash(key);
            let str = `${dashCasedKey}="`;

            if (angular.isUndefined(bindToController[$.camelCase(key)])) {
                console.warn(`"${key}" binding not defined in component.`);

                return;
            }

            const bindingSymbol = bindToController[$.camelCase(key)][0];

            if (bindingSymbol === '@') {
                str += `{{${key}}}`;
            } else if (bindingSymbol === '&') {
                if (angular.isFunction(value)) {
                    if ($injector.annotate(value).length) {
                        throw new Error(`Function parameters for ${key} must be defined as ` +
                            'an array');
                    } else {
                        str += `${key}()`;
                    }
                } else if (Array.isArray(value)) {
                    if (!angular.isFunction(value[value.length - 1])) {
                        throw new Error(
                            `${'The last entry in the array for a function binding must' +
                            'be a function. Please check '}${key}`,
                        );
                    } else {
                        str += `${key}(${$injector.annotate(value).join(',')})`;
                    }
                }
            } else {
                str += key;
            }

            start += `${str}" `;
        });

        start += 'close-modal="closeModal()">';

        return start + end;
    }

    return getTemplateString;
}]);
