/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-subdomains-grid';
const prefix = `${moduleName}.${componentName}`;

export const subdomainLabel = `${prefix}.subdomainLabel`;
export const gslbSiteLabel = `${prefix}.gslbSiteLabel`;
export const dnsVsLabel = `${prefix}.dnsVsLabel`;
export const columnValueSuffix = `${prefix}.columnValueSuffix`;

export const ENGLISH = {
    [subdomainLabel]: 'Subdomain',
    [gslbSiteLabel]: 'GSLB Sites',
    [dnsVsLabel]: 'DNS VS',
    [columnValueSuffix]: '(+{0} More)',
};
