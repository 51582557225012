<avi-state-page class="ip-reputation-db-list-page clr-wrapper">
    <avi-state-page_main-content>
        <ip-reputation-db-list-page-header
            [status]="status"
            error="{{ getError() }}"
            (onEditPulseSettings)="handleEditPulseSettings()"
        ></ip-reputation-db-list-page-header>
        <div class="ip-reputation-db-list-page__container">
            <div class="ip-reputation-db-list-page__search-record-card">
                <ip-reputation-search-record-card
                    [ipReputationDB]="ipReputationDB"
                ></ip-reputation-search-record-card>
            </div>
            <div class="ip-reputation-db-list-page__change-request-card">
                <ip-reputation-change-request-card></ip-reputation-change-request-card>
            </div>
        </div>
        <ip-reputation-db-list-page-card
            *ngIf="ipReputationDB"
            [ipReputationDB]="ipReputationDB"
        ></ip-reputation-db-list-page-card>
    </avi-state-page_main-content>
</avi-state-page>
