/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    AlertSource,
    AviPermissionResource,
} from 'generated-types';

import { ALERT_CONFIG_ITEM_TOKEN } from '../../alert.tokens';
import { AlertRuleOperatorEnum } from './alert-config.item.factory';

export type TAlertConfigCollection = typeof AlertConfigCollection;

/**
 * @description AlertConfig collection
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
export class AlertConfigCollection extends Collection {
    public static ajsDependencies = [ALERT_CONFIG_ITEM_TOKEN];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'alertconfig',
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ALERT_CONFIG_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            alert_filter: [],
            source: AlertSource.EVENT_LOGS,
            alert_rule: {
                operator: AlertRuleOperatorEnum.OPERATOR_AND,
            },
        };
    }
}
