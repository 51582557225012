/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    RoleFilterConfigItem,
    RoleItem,
} from 'ajs/modules/accounts/factories/role';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import * as globalL10n from 'global-l10n';
import * as l10n from './role-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Role modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'role-modal',
    templateUrl: './role-modal.component.html',
})
export class RoleModalComponent implements OnInit {
    /**
     * RoleItem Instance
     */
    @Input()
    public editable: RoleItem;

    /**
     * Role object type.
     */
    public objectType: string;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Role modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Handler for Role Filter grid add operation.
     */
    public onAddRoleFilter(): void {
        this.editable.addLabelFilter();
    }

    /**
     * Handler for Role Filter grid remove operation.
     */
    public onRemoveRoleFilter(labelFilter: RoleFilterConfigItem): void {
        this.editable.removeRoleFilter(labelFilter);
    }

    /**
     * Handler for Role Filter grid edit operation.
     */
    public onEditRoleFilter(labelFilter: RoleFilterConfigItem): void {
        this.editable.editRoleFilter(labelFilter);
    }
}
