/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'system-about-card';
const prefix = `${moduleName}.${componentName}`;

export const aboutHeader = `${prefix}.aboutHeader`;

export const ENGLISH = {
    [aboutHeader]: 'About',
};
