<div
    class="virtual-service-faults"
    [hidden]="!showFaults"
>
    <clr-alert
        clrAlertType="danger"
        (clrAlertClosedChange)="handleClose()"
    >
        <clr-alert-item>
            <div>
                <ng-container *ngIf="hasGenericFaults">
                    <div
                        *ngFor="let fault of groupedFaults.generic; trackBy: trackByFault"
                        class="virtual-service-faults__fault-container"
                    >
                        <div class="virtual-service-faults__fault-header">
                            {{ l10nKeys.virtualServiceFaultTitle | vtranslate }}
                        </div>
                        <div>{{ fault }}</div>
                    </div>
                </ng-container>
                <div
                    *ngIf="hasServerFaults"
                    class="virtual-service-faults__fault-container"
                >
                    <div class="virtual-service-faults__fault-header">
                        {{ l10nKeys.serverFaultsTitle | vtranslate }}
                    </div>
                    <div *ngFor="let serverFault of groupedFaults.server; trackBy: trackByServerFault">
                        <div>{{ serverFault.poolRef | name }}</div>
                        <ul>
                            <li *ngFor="let fault of serverFault.faults; trackBy: trackByFault">
                                {{ fault }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </clr-alert-item>
    </clr-alert>
</div>
