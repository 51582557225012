/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-icap';
const prefix = `${moduleName}.${componentName}`;

export const requestActionLabel = `${prefix}.requestActionLabel`;
export const numberViolationsLabel = `${prefix}.numberViolationsLabel`;
export const oneViolationLabel = `${prefix}.oneViolationLabel`;
export const violationsLabel = `${prefix}.violationsLabel`;
export const violationLabel = `${prefix}.violationLabel`;
export const threatIdLabel = `${prefix}.threatIdLabel`;
export const seSourcePortLabel = `${prefix}.seSourcePortLabel`;
export const icapPoolLabel = `${prefix}.icapPoolLabel`;
export const icapServerIpLabel = `${prefix}.icapServerIpLabel`;
export const icapLatencyLabel = `${prefix}.icapLatencyLabel`;
export const icapViolationsLabel = `${prefix}.icapViolationsLabel`;
export const fileNameLabel = `${prefix}.fileNameLabel`;
export const threatNameLabel = `${prefix}.threatNameLabel`;
export const violationResolutionLabel = `${prefix}.violationResolutionLabel`;
export const completeBodySentLabel = `${prefix}.completeBodySentLabel`;
export const icapAbsoluteUriLabel = `${prefix}.icapAbsoluteUriLabel`;
export const icapMethodLabel = `${prefix}.icapMethodLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const modifiedContentLengthLabel = `${prefix}.modifiedContentLengthLabel`;
export const icapResponseCodeLabel = `${prefix}.icapResponseCodeLabel`;
export const icapHeadersLabel = `${prefix}.icapHeadersLabel`;

export const ENGLISH = {
    [requestActionLabel]: 'Request Action',
    [numberViolationsLabel]: '{0} Violations',
    [oneViolationLabel]: '1 Violation',
    [violationsLabel]: 'Violations',
    [violationLabel]: 'Violation',
    [threatIdLabel]: 'Threat ID',
    [seSourcePortLabel]: 'SE Source Port',
    [icapPoolLabel]: 'ICAP Pool',
    [icapServerIpLabel]: 'ICAP Server IP',
    [icapLatencyLabel]: 'ICAP Latency',
    [icapViolationsLabel]: 'ICAP Violations',
    [fileNameLabel]: 'File Name',
    [threatNameLabel]: 'Threat Name',
    [violationResolutionLabel]: 'Violation Resolution',
    [completeBodySentLabel]: 'Complete Body Sent',
    [icapAbsoluteUriLabel]: 'ICAP Absolute URI',
    [icapMethodLabel]: 'ICAP Method',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [modifiedContentLengthLabel]: 'Modified Content Length',
    [icapResponseCodeLabel]: 'ICAP Response Code',
    [icapHeadersLabel]: 'ICAP Headers',
};
