/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoolGroupModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { StateService } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'string-service';
import { PoolGroup } from 'items/pool-group.item.factory';

import {
    PoolCollection,
    TPoolCollection,
} from 'collections/pool.collection.factory';

import {
    PoolGroupCollection,
    TPoolGroupCollection,
} from 'collections/pool-group.collection.factory';

import {
    PoolGroupMemberConfigItem,
} from 'message-items/pool-group-member.config-item.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './pool-group-list-page.l10n';
import './pool-group-list-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

// Constant used for navigation to a page.
const POOL_DETAIL_ANALYTICS_PATH = 'authenticated.application.pool-detail.analytics';

/**
 * @description PoolGroup list page.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'pool-group-list-page',
    templateUrl: './pool-group-list-page.component.html',
})
export class PoolGroupListPageComponent implements AfterViewInit, OnInit, OnDestroy {
    /**
     * Template reference for PoolGroup expander.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for health column.
     */
    @ViewChild('healthTemplateRef')
    public healthTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template reference for PoolGroupMember pool_ref.
     */
    @ViewChild('poolTemplateRef')
    public poolTemplateRef: TemplateRef<HTMLElement>;

    public poolGroupGridConfig: IAviCollectionDataGridConfig;

    public poolGroupMembersGridConfig: IAviDataGridConfig;

    public poolName: string;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly poolGroupCollection: PoolGroupCollection;

    public readonly poolCollection: PoolCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(PoolGroupCollection)
        private readonly PoolGroupCollection: TPoolGroupCollection,
        @Inject(PoolCollection)
        private readonly PoolCollection: TPoolCollection,
        public readonly stringService: StringService,
        @Inject(StateService)
        private readonly $state: StateService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.poolGroupCollection = new this.PoolGroupCollection();

        this.poolCollection = new this.PoolCollection({ objectName: 'pool-inventory' });
        this.poolCollection.load();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.poolGroupCollection;

        this.poolGroupGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.poolGroupCollection,
            defaultSorting: 'name',
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            stringService,
            expandedContentTemplateRef,
        } = this;

        this.poolGroupGridConfig = {
            ...this.poolGroupGridConfig,
            fields: [
                {
                    id: 'name',
                    sortBy: 'name',
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: PoolGroup): string => row.getName(),
                },
                {
                    id: 'number-of-pools',
                    label: l10nService.getMessage(l10nKeys.numberOfPoolsLabel),
                    transform: (row: PoolGroup): string => String(row.members.count),
                },
                {
                    id: 'virtual-services',
                    label: l10nService.getMessage(globalL10nKeys.virtualServicesLabel),
                    transform: (row: IAviCollectionDataGridRow): string => {
                        return row.data?.virtualservices
                            ?.map((virtualservice: {name: string}) => virtualservice.name)
                            ?.join(', ') || '-';
                    },
                },
                {
                    id: 'cloud',
                    label: l10nService.getMessage(globalL10nKeys.cloudLabel),
                    transform: (row: PoolGroup): string => stringService.name(row.getCloudRef()),
                },
            ],
            expandedContentTemplateRef,
        };

        this.poolGroupMembersGridConfig = {
            fields: [
                {
                    id: 'name',
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    templateRef: this.poolTemplateRef,
                },
                {
                    id: 'health',
                    label: l10nService.getMessage(globalL10nKeys.healthLabel),
                    templateRef: this.healthTemplateRef,
                },
                {
                    id: 'ratio',
                    label: l10nService.getMessage(globalL10nKeys.ratioLabel),
                    transform: (row: PoolGroupMemberConfigItem): string => {
                        return String(row.config.ratio) || '-';
                    },
                    sortBy: 'ratio',
                },
                {
                    id: 'deployment_state',
                    label: l10nService.getMessage(globalL10nKeys.deploymentStateLabel),
                    transform: (row: PoolGroupMemberConfigItem): string => {
                        return row.config.deployment_state || '-';
                    },
                },
                {
                    id: 'priority_label',
                    label: l10nService.getMessage(globalL10nKeys.priorityLabel),
                    transform: (row: PoolGroupMemberConfigItem): string => {
                        return row.config.priority_label || '-';
                    },
                },
            ],
            searchKeys: ['poolName'],
            layout: {
                placeholderMessage: l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Return Virtual Service uuid from Pool.
     */
    public getVirtualServiceId(poolRef: string): string {
        const {
            poolCollection,
            stringService,
        } = this;

        const poolId = stringService.slug(poolRef);
        const pool = poolCollection.getItemById(poolId);

        if (!pool) {
            return '';
        }

        return pool.getVSId();
    }

    /**
     * Return pool name from pool ref.
     */
    public getPoolName(poolRef: string): string {
        return poolRef && this.stringService.name(poolRef);
    }

    /**
     * Redirect to the Pool detail analytics page.
     */
    public redirectToPoolDetailAnalytics(poolRef: string): void {
        const {
            $state,
            stringService,
            poolCollection,
        } = this;

        const poolId = stringService.slug(poolRef);
        const pool = poolCollection.getItemById(poolId);

        $state.go(POOL_DETAIL_ANALYTICS_PATH, {
            vsId: pool.getVSId() || '',
            poolId,
        });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.poolGroupCollection.destroy();
        this.poolCollection.destroy();
    }
}
