/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'vs-logs-waf-exception-dialog';
const prefix = `${moduleName}.${componentName}`;

export const groupNameLabel = `${prefix}.groupNameLabel`;
export const ruleNameLabel = `${prefix}.ruleNameLabel`;
export const existingExceptionsLabel = `${prefix}.existingExceptionsLabel`;
export const newExceptionsLabel = `${prefix}.newExceptionsLabel`;
export const noNewExceptionsCanBeAddedMessage = `${prefix}.noNewExceptionsCanBeAddedMessage`;
export const addExceptionHeader = `${prefix}.addExceptionHeader`;
export const andLabel = `${prefix}.andLabel`;
export const anySubnetLabel = `${prefix}.anySubnetLabel`;
export const anyPathLabel = `${prefix}.anyPathLabel`;
export const anyMatchLabel = `${prefix}.anyMatchLabel`;
export const ruleNoLongerExists = `${prefix}.ruleNoLongerExists`;
export const groupNoLongerExists = `${prefix}.groupNoLongerExists`;

export const ENGLISH = {
    [groupNameLabel]: 'Group Name',
    [ruleNameLabel]: 'Rule Name',
    [existingExceptionsLabel]: 'Existing Exceptions',
    [newExceptionsLabel]: 'New Exceptions',
    [noNewExceptionsCanBeAddedMessage]: 'No new exceptions can be added',
    [addExceptionHeader]: 'Add Exception',
    [andLabel]: 'and',
    [anySubnetLabel]: 'Any Subnet',
    [anyPathLabel]: 'Any Path',
    [anyMatchLabel]: 'Any Match Element',
    [ruleNoLongerExists]: 'Rule {0} no longer exists.',
    [groupNoLongerExists]: 'Group {0} no longer exists.',
};
