<div class="avi-label-with-tooltip">
    <span
        class="avi-label-with-tooltip__label"
        [ngClass]="{ 'avi-label-with-tooltip__label--no-bold': noBold }"
    >
        <ng-content></ng-content>
    </span>
    <div
        class="avi-label-with-tooltip__required"
        *ngIf="required"
    ></div>
    <helper-text-tooltip-icon
        class="avi-label-with-tooltip__icon"
        tooltipText="{{ tooltipText }}"
        objectType="{{ objectType }}"
        fieldName="{{ fieldName }}"
        enumType="{{ enumType }}"
        enumValue="{{ enumValue }}"
        [hideRange]="hideRange"
        rangeText="{{ rangeText }}"
    ></helper-text-tooltip-icon>
</div>
