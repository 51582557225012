/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AuthProfileType,
    AviPermissionResource,
    IAuthMappingProfile,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { AuthMappingProfile } from 'object-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    AuthMappingProfileModalComponent,
} from 'ng/modules/auth-settings/components/auth-mapping-profile-modal';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { AuthMappingRuleConfigItem } from './auth-mapping-rule.config-item.factory';

import * as l10n from '../auth-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthMappingProfilePartial = Omit<IAuthMappingProfile, 'mapping_rules'>;

interface IAuthMappingProfileConfig extends TAuthMappingProfilePartial {
    mapping_rules?: RepeatedMessageItem<AuthMappingRuleConfigItem>;
}

// Constant for mapping_rules field.
const MAPPING_RULES = 'mapping_rules';

/**
 * @desc AuthMappingProfile objectTypeItem.
 *
 * @author Aravindh Nagarajan
 */
export class AuthMappingProfileItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /** Overriding getConfig()'s return type */
    public getConfig: () => IAuthMappingProfileConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'authmappingprofile',
            objectType: AuthMappingProfile,
            permissionName: AviPermissionResource.PERMISSION_AUTHMAPPINGPROFILE,
            windowElement: AuthMappingProfileModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for mappingRules.
     */
    public get mappingRules(): RepeatedMessageItem<AuthMappingRuleConfigItem> {
        return this.config[MAPPING_RULES];
    }

    /**
     * Getter for type.
     */
    public get type(): AuthProfileType {
        return this.config.type;
    }

    /**
     * Opens Create AuthMappingRule Modal.
     */
    public addAuthMappingRule(isLdapProfile: boolean): void {
        this.addChildMessageItem({
            field: MAPPING_RULES,
            modalBindings: {
                isLdapProfile,
            },
        });
    }

    /**
     * Opens Edit AuthMappingRule Modal.
     */
    public editAuthMappingRule(
        messageItem: AuthMappingRuleConfigItem,
        isLdapProfile: boolean,
    ): void {
        this.editChildMessageItem({
            field: MAPPING_RULES,
            messageItem,
            modalBindings: {
                isLdapProfile,
            },
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authMappingProfileModalBreadcrumbTitle);
    }
}
