/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @preferred
 * @module DashboardModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrProgressBarModule } from '@clr/angular';

import {
    AviCardModule,
    AviDashboardModule,
} from 'nsx-alb-ui-components';

import { SharedModule } from 'ng/shared/shared.module';
import { LoaderModule } from 'ng/modules/loader/loader.module';

import {
    PermittedWidgetsPipe,
} from './pipes';

@NgModule({
    declarations: [
        PermittedWidgetsPipe,
    ],
    imports: [
        AviCardModule,
        AviDashboardModule,
        ClrProgressBarModule,
        CommonModule,
        SharedModule,
        LoaderModule,
    ],
    exports: [
        PermittedWidgetsPipe,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class DashboardModule { }
