<full-modal-config 
    modalTitle="{{ (editable.id ? l10nKeys.modalHeaderEdit : l10nKeys.modalHeaderNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form 
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.tenant }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        [readonly]="editable.isProtected()"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputLabel | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-textarea-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.tenant }}"
                        fieldName="description"
                        for="description"
                    >
                        {{ l10nKeys.descriptionInputLabel | vtranslate }}
                    </label>
                    <textarea
                        clrTextarea
                        id="description"
                        [(ngModel)]="editable.config.description"
                        name="description"
                        placeholder="{{ l10nKeys.descriptionInputPlaceholder | vtranslate }}"
                        rows="2"
                     ></textarea>
                </avi-textarea-container>
                <div *ngIf="!editable.id || editable.configSettings">
                    <avi-checkbox-container-group>
                        <avi-checkbox
                            objectType="{{ objectType.tenantConfiguration }}"
                            fieldName="tenant_access_to_provider_se"
                            [(ngModel)]="editable.configSettings.config.tenant_access_to_provider_se"
                            name="tenant_access_to_provider_se"
                        >
                            {{ l10nKeys.tenantAccessLabel | vtranslate }}
                        </avi-checkbox>
                        <avi-checkbox
                            objectType="{{ objectType.tenantConfiguration }}"
                            fieldName="tenant_vrf"
                            [(ngModel)]="editable.configSettings.config.tenant_vrf"
                            name="tenant-vrf"
                        >
                            {{ l10nKeys.tenantVrfLabel | vtranslate }}
                        </avi-checkbox>
                    </avi-checkbox-container-group>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
