/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { IAviDropdownOption } from 'ng/shared/components';
import { SSLCertificateConfigItem } from 'ajs/modules/security';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { SSLKeyAlgorithm } from 'generated-types';

import * as l10n from './ssl-certificate-imported-information.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for displaying imported information
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-certificate-imported-information',
    templateUrl: './ssl-certificate-imported-information.component.html',
})
export class SSLCertificateImportedInformationComponent {
    /**
     * SSLCertificateConfigItem instance.
     */
    @Input()
    public certificate: SSLCertificateConfigItem;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SSLKeyAlgorithm dropdown options.
     */
    public sslKeyAlgorithmOptions: IAviDropdownOption[];

    /**
     * SSLRSAKeySize dropdown options.
     */
    public sslRsaKeySizeOptions: IAviDropdownOption[];

    /**
     * SSLRSAKeyECCurve dropdown options.
     */
    public sslKeyECCurveOptions: IAviDropdownOption[];

    /**
     * Hash of SSLKeyAlgorithm type enum
     */
    public readonly sslKeyAlgorithmTypeHash = {
        SSL_KEY_ALGORITHM_EC: SSLKeyAlgorithm.SSL_KEY_ALGORITHM_EC,
        SSL_KEY_ALGORITHM_RSA: SSLKeyAlgorithm.SSL_KEY_ALGORITHM_RSA,
    };

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const sslKeyAlgorithmEnumValues = schemaService.getEnumValues('SSLKeyAlgorithm');
        const sslRSAKeySizeEnumValues = schemaService.getEnumValues('SSLRSAKeySize');
        const sslKeyECCurveEnumValues = schemaService.getEnumValues('SSLKeyECCurve');

        this.sslKeyAlgorithmOptions = createOptionsFromEnumProps(sslKeyAlgorithmEnumValues);
        this.sslRsaKeySizeOptions = createOptionsFromEnumProps(sslRSAKeySizeEnumValues);
        this.sslKeyECCurveOptions = createOptionsFromEnumProps(sslKeyECCurveEnumValues);
    }
}
