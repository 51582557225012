<avi-data-grid
    [config]="authorizationRuleGridConfig"
    [rows]="authRules.config"
    (onRowOrderChange)="handleRowReorder($event)"
    gridTitle="{{ l10nKeys.authorizationRulesLabel | vtranslate : authRules.count }}"
>
    <avi-data-grid_actions>
        <cds-button
            size="sm"
            (click)="addAuthRule()"
        >
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </cds-button>
    </avi-data-grid_actions>

    <ng-template
        #expandedContentTemplateRef
        let-row
    >
        <match-and-action-display-wrapper>
            <match-and-action-display-wrapper_match-content>
                <path-or-query-match-readonly
                    *ngIf="row.match.config.path"
                    [editable]="row.match.config.path"
                ></path-or-query-match-readonly>

                <host-hdr-match-readonly
                    *ngIf="row.match.config.host_hdr"
                    [editable]="row.match.config.host_hdr"
                ></host-hdr-match-readonly>

                <http-method-match-readonly
                    *ngIf="row.match.config.method"
                    [editable]="row.match.config.method"
                ></http-method-match-readonly>

                <jwt-match-readonly
                    *ngIf="row.match.config.access_token"
                    [editable]="row.match.config.access_token"
                ></jwt-match-readonly>

                <ng-container *ngIf="row.match.hasAttributeMatches">
                    <ng-container *ngFor="let attributeMatch of row.match.attributeMatches.config; trackBy: trackByIndex">
                        <auth-attribute-match-readonly [editable]="attributeMatch"></auth-attribute-match-readonly>
                    </ng-container>
                </ng-container>
            </match-and-action-display-wrapper_match-content>

            <match-and-action-display-wrapper_action-content>
                <div>{{ row.authorizationActionType }}</div>
                <span *ngIf="row.authorizationActionStatusCode">({{ row.authorizationActionStatusCode }})</span>
            </match-and-action-display-wrapper_action-content>
        </match-and-action-display-wrapper>
    </ng-template>
</avi-data-grid>
