/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name createItemByRef
 * @description
 *
 *     Creates an Item using a passed ref/URL to figure out an appropriate Item Class.
 *     Item type (objectName) has to be present in {@link itemObjectTypeToClassName} constant.
 */
angular.module('aviApp').factory('createItemByRef', [
'getItemConstructorByRef',
function(getItemConstructorByRef) {
    return function(ref) {
        let Constructor;

        if (ref) {
            Constructor = getItemConstructorByRef(ref);
        }

        if (Constructor) {
            return new Constructor({ id: ref.slug() });
        } else {
            const errMsg = `Can't resolve ref "${ref}" to constructor className`;

            console.error(errMsg);

            return null;
        }
    };
}]);
