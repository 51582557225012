<div class="vs-logs-significance-options">
    <div class="vs-logs-significance-options__logs-label">{{ globalL10nKeys.logsLabel | vtranslate }}</div>
    <ng-container *ngrxLet="vsLogsStore.nonSignificantLogsEnabled$ as nonSignificantLogsEnabled">
        <clr-checkbox-container
            class="vs-logs-significance-options__checkbox"
            avi-tooltip
            [positionsPriority]="hoverOverlayPositionPriorities"
        >
            <ng-template #aviTooltipContent>
                <ng-container *ngIf="!nonSignificantLogsEnabled">
                    <avi-hover-overlay>
                        <div class="vs-logs-significance-options__tooltip">
                            {{ l10nKeys.enableNonSignificantLogsMessage | vtranslate }}
                        </div>
                    </avi-hover-overlay>
                </ng-container>
            </ng-template>
            <clr-checkbox-wrapper>
                <input
                    clrCheckbox
                    type="checkbox"
                    id="non-significant"
                    name="non-significant"
                    [disabled]="!nonSignificantLogsEnabled"
                    [(ngModel)]="showNonSignificantLogs"
                    (ngModelChange)="vsLogsStore.setNonSignificantParams($event)"
                />
                <label>{{ l10nKeys.nonSignificantLabel | vtranslate }}</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </ng-container>
    <clr-checkbox-container class="vs-logs-significance-options__checkbox">
        <clr-checkbox-wrapper>
            <input
                clrCheckbox
                type="checkbox"
                id="significant"
                name="significant"
                [(ngModel)]="showSignificantLogs"
                (ngModelChange)="vsLogsStore.setSignificantParams($event)"
            />
            <label>{{ l10nKeys.significantLabel | vtranslate }}</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</div>
