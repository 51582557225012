/**
 * Module containing persistence profile related components.
 * @preferred
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { VIPModule } from '@vmw/ngx-vip';
import { persistenceProfileCollectionProvider } from './ajs-upgraded-providers';
import { PersistenceProfileListPageComponent } from
    './components/persistence-profile-page/persistence-profile-page.component';

@NgModule({
    declarations: [
        PersistenceProfileListPageComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DataGridModule,
        VIPModule,
    ],
    providers: [
        persistenceProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PersistenceProfileModule { }
