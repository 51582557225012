/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Constants for IPAMDNS types.
 */
export const IPAMDNS_TYPE_INTERNAL = 'IPAMDNS_TYPE_INTERNAL';
export const IPAMDNS_TYPE_INTERNAL_DNS = 'IPAMDNS_TYPE_INTERNAL_DNS';
export const IPAMDNS_TYPE_INFOBLOX = 'IPAMDNS_TYPE_INFOBLOX';
export const IPAMDNS_TYPE_INFOBLOX_DNS = 'IPAMDNS_TYPE_INFOBLOX_DNS';
export const IPAMDNS_TYPE_AWS = 'IPAMDNS_TYPE_AWS';
export const IPAMDNS_TYPE_AWS_DNS = 'IPAMDNS_TYPE_AWS_DNS';
export const IPAMDNS_TYPE_OPENSTACK = 'IPAMDNS_TYPE_OPENSTACK';
export const IPAMDNS_TYPE_CUSTOM = 'IPAMDNS_TYPE_CUSTOM';
export const IPAMDNS_TYPE_CUSTOM_DNS = 'IPAMDNS_TYPE_CUSTOM_DNS';
export const IPAMDNS_TYPE_AZURE_DNS = 'IPAMDNS_TYPE_AZURE_DNS';
export const IPAMDNS_TYPE_OCI = 'IPAMDNS_TYPE_OCI';
