/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/infinite-scroll.less';

/**
 * @ngdoc directive
 * @name infiniteScroll
 * @restrict A
 * @author Alex Malitsky
 * @description
 *
 *     Infinite scroll for container with ng-repeated items.
 *     If collection provided will call updateItems for visible ones and call loadNext when
 *     it is time to preload next page.
 *
 */
//TODO pass default element size
angular.module('aviApp').directive('infiniteScroll', [
'$timeout', '$window',
function($timeout, $window) {
    function itemsNumberToFit(viewportHeight, viewportWidth, itemHeight, itemWidth) {
        const number = Math.floor(viewportWidth / itemWidth) *
            Math.ceil(viewportHeight / itemHeight);

        return !_.isNaN(number) && _.isFinite(number) && number > 0 ? number : 10;
    }

    function infiniteScrollLink(scope, elm) {
        const
            //how to search for repeated elements inside directive's wrappers
            childSelector = '>[ng-repeat]',
            wrapper = elm.find('>div[ng-transclude]');

        let
            setVisibleItems,
            isDestroyed,
            scrollParent = wrapper;

        if (scope.scrollParent) {
            scrollParent = elm.parents(`.${scope.scrollParent}`);
        }

        const checkForLoadNext = _.throttle(() => {
            if (!isDestroyed && wrapper.children().length) {
                const parentHeight = scrollParent.height();
                const lastElm = wrapper.find(`${childSelector}:last-child`);
                const lastElmBottom = lastElm.position().top + lastElm.height();

                let randomItem = _.sample(wrapper.find(childSelector)),
                    itemWidth,
                    itemHeight;

                if (randomItem) {
                    randomItem = $(randomItem);
                    itemWidth = randomItem.outerWidth(true);
                    itemHeight = randomItem.outerHeight(true);
                } else {
                    itemWidth = 265;
                    itemHeight = 90;
                }

                if (scope.collection) {
                    const n = itemsNumberToFit(
                        parentHeight, wrapper.width(),
                        itemHeight, itemWidth,
                    );

                    scope.collection.updateViewportSize(n);
                }

                if (lastElmBottom < parentHeight * 1.7) {
                    if (!scope.disabled) {
                        if (!scope.collection) {
                            scope.action();
                        }
                    }
                }
            }
        }, 300);

        if (scope.collection) {
            setVisibleItems = _.throttle(() => {
                if (!isDestroyed && !scope.disabled) {
                    const
                        itemNodes = wrapper.find(childSelector),
                        { top: wrapperTop } = scrollParent.offset(),
                        wrapperBottom = wrapperTop + scrollParent.innerHeight();

                    let firstVisibleIndex,
                        lastVisibleIndex;

                    if (itemNodes.length) {
                        itemNodes.each((key, node) => {
                            node = $(node);

                            const
                                { top } = node.offset(),
                                bottom = top + node.innerHeight();

                            if (angular.isUndefined(firstVisibleIndex) && bottom > wrapperTop) {
                                firstVisibleIndex = key;
                            }

                            if (top < wrapperBottom) {
                                lastVisibleIndex = key;
                            }
                        });

                        if (angular.isUndefined(firstVisibleIndex)) {
                            firstVisibleIndex = 0;
                        }

                        if (angular.isUndefined(lastVisibleIndex)) {
                            lastVisibleIndex = Math.max(0, itemNodes.length - 1);
                        }

                        scope.collection.updateItemsVisibility(
                            lastVisibleIndex - firstVisibleIndex + 1, firstVisibleIndex,
                        );
                    }
                }
            }, 300);

            scrollParent.on('scroll', setVisibleItems);
            $($window).on('resize', setVisibleItems);

            if (scope.initialCheck) {
                $timeout(setVisibleItems);
            }

            scope.collection.on('collectionLoadSuccess', function() {
                $timeout(checkForLoadNext);
                $timeout(setVisibleItems);
            });
        } else if (scope.onScroll) {
            scrollParent.on('scroll', scope.onScroll);
            $($window).on('resize', scope.onScroll);
        }

        scrollParent.on('scroll', checkForLoadNext);
        $($window).on('resize', checkForLoadNext);

        if (scope.initialCheck) {
            $timeout(checkForLoadNext);
        }

        scope.$on('$destroy', () => {
            scrollParent
                .off('scroll', checkForLoadNext)
                .off('scroll', setVisibleItems)
                .off('scroll', scope.onScroll);

            $($window)
                .off('resize', checkForLoadNext)
                .off('resize', setVisibleItems)
                .off('resize', scope.onScroll);

            //to stop throttled functions from execution
            isDestroyed = true;
        });
    }

    return {
        restrict: 'A',
        scope: {
            action: '&',
            onScroll: '&',
            collection: '=', //optional
            disabled: '=',
            initialCheck: '@',
            scrollParent: '@',
        },
        link: infiniteScrollLink,
        transclude: true,
        template: '<div ng-transclude></div><div avi-loader ng-show="collection.busy"></div>',
    };
}]);
