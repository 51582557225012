/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HttpBackend,
    HttpClient,
    HttpInterceptor,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';

import {
    Inject,
    Injectable,
    InjectionToken,
} from '@angular/core';

import { AlbInterceptingHandler } from '../handler/alb-intercepting-handler';

/**
 * Injectoken similar to `HTTP_INTERCEPTORS`- represents the array of registered
 * interceptors.
 */
export const ALB_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
    'An abstraction on HttpInterceptor[]',
);

// TODO: Remove this once all required services are migrated to Angular.
/**
 * @description
 *          - Its extended from HttpClient.
 *
 *          - We need this since we have angularjs dependant services (auth, schemaService, ..)
 *            in our interceptors.
 *
 *           - When HttpClient is configured with those interceptors, on app
 *              init stage, Angular (Ng) DI is unable to get AngularJS Injector.
 *
 *           - To fix this issue, we need two instance of HttpClient.
 *              - one without any interceptors (HttpClient) - to be used in appInitializer
 *              - one with interceptors (AlbHttpClient).
 *
 * @author Aravindh Nagarajan
 */
@Injectable()
export class AlbHttpClient extends HttpClient {
    constructor(
        backend: HttpBackend,
        @Inject(HTTP_INTERCEPTORS)
        interceptors: HttpInterceptor[],
        @Inject(ALB_HTTP_INTERCEPTORS)
        albInterceptors: HttpInterceptor[],
    ) {
        const handler = new AlbInterceptingHandler(
            backend,
            [
                ...interceptors,
                ...albInterceptors,
            ],
        );

        super(handler);
    }
}
