<avi-confirmation
    class="clr-wrapper"
    headerText="{{ l10nKeys.generateSsoTokenDialogHeader | vtranslate }}"
    (onClose)="handleClose()"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
        >
            <avi-alert-group
                *ngIf="error"
                alertType="danger"
            >
                {{ error }}
            </avi-alert-group>
            <div [innerHTML]="l10nKeys.generateSsoTokenDescription | vtranslate: username"></div>
            <div class="generate-sso-token-dialog__form-container">
                <avi-input-container
                    helperText="{{ l10nKeys.hoursHelperText | vtranslate }}"
                >
                    <label
                        aviLabelWithTooltip
                        tooltipText="{{ l10nKeys.tokenLifetimeTooltipMessage | vtranslate }}"
                        rangeText="{{ minTokenLifetimeValue + '-' + maxTokenLifetimeValue }}"
                        for="hours"
                    >
                        {{ l10nKeys.tokenLifetimeLabel | vtranslate }}
                    </label>
                    <input
                        type="number"
                        clrInput
                        placeholder="{{ l10nKeys.tokenLifetimePlaceholder | vtranslate }}"
                        [(ngModel)]="hours"
                        numericFieldInputValidation
                        id="hours"
                        name="hours"
                        [minValue]="minTokenLifetimeValue"
                        [maxValue]="maxTokenLifetimeValue"
                        [stepValue]="1"
                    />
                </avi-input-container>
                <avi-input-container *ngIf="token">
                    <label
                        aviLabelWithTooltip
                        for="token"
                    >
                        {{ l10nKeys.tokenLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        placeholder="-"
                        [value]="token"
                        id="token"
                        name="token"
                        readonly
                    />
                </avi-input-container>
            </div>
        </form>
    </ng-container>
    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button
            (click)="handleClose()"
            action="outline"
        >
            {{ l10nKeys.closeButtonLabel | vtranslate }}
        </cds-button>
        <cds-button
            (click)="generateToken()"
            [loadingState]="loadingState"
            [disabled]="form.invalid"
        >
            {{ l10nKeys.generateButtonLabel | vtranslate }}
        </cds-button>
    </ng-container>
</avi-confirmation>
