<div provideParentForm>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="health_monitor_uuids"
            >
                <avi-data-grid_header>
                    <h6>{{ l10nKeys.healthMonitorGridLabel | vtranslate : editable.config.health_monitor_refs?.length || 0 }}</h6>
                </avi-data-grid_header>
            </label>

            <avi-repeated-collection-dropdowns-grid
                [collection]="healthMonitorCollection"
                [(ngModel)]="editable.config.health_monitor_refs"
                columnLabel="{{ globalL10nKeys.nameLabel | vtranslate }}"
                placeholder="{{ l10nKeys.healthMonitorPlaceholder | vtranslate }}"
                [maxRows]="maxHealthMonitors"
                name="health-monitor-uuids"
            ></avi-repeated-collection-dropdowns-grid>
        </div>
    </div>
</div>
