<match-wrapper
    label="{{ label }}"
    subLabel="{{ subLabel }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
    [showRemoveIcon]="!disabled"
>
    <div
        class="path-or-query-match__container"
        [ngClass]="{'path-or-query-match__container--no-margin': isQueryMatchObjectType()}"
        provideParentForm
    >
        <div *ngIf="isPathMatchObjectType() || isStringMatchObjectType()">
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="match_criteria"
                for="match-criteria"
                required
            >
                {{ l10nKeys.criteriaDropdownLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="match-criteria"
                id="match-criteria"
                [options]="matchCriteriaDropdownOptions"
                [(ngModel)]="editable.config.match_criteria"
                placeholder="{{ l10nKeys.selectMethodPlaceholder | vtranslate }}"
                [disabled]="disabled"
                required
            ></avi-dropdown>
        </div>

        <div
            class="path-or-query-match__match-case-container"
            *ngIf="isPathMatchObjectType()"
        >
            <clr-checkbox-container>
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        clrCheckbox
                        name="match-case"
                        id="match-case"
                        [disabled]="disabled"
                        [(ngModel)]="isCaseSensitive"
                        (ngModelChange)="handleCaseSensitiveSelection($event)"
                    />
                    <label
                        aviLabelWithTooltip
                        objectType="{{ matchObjectType }}"
                        fieldName="match_case"
                        for="match-case"
                    >
                        {{ l10nKeys.enableMatchCaseLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </div>

        <div>
            <div
                *ngFor="let config of stringGroupRefsOrCustomValuesConfigs; index as i; trackBy: trackByIndex"
                class="path-or-query-match__string-container"
                [ngClass]="{'path-or-query-match__string-container--no-margin': isQueryMatchObjectType()}"
            >
                <div class="path-or-query-match__string-header">
                    <label
                        aviLabelWithTooltip
                        required
                    >
                        {{ l10nKeys.stringLabel | vtranslate }}
                    </label>
                    <cds-icon
                        *ngIf="!disallowStringGroups && stringGroupRefsOrCustomValuesConfigs.length > 1"
                        shape="trash"
                        size="18"
                        status="info"
                        class="path-or-query-match__remove-icon"
                        (click)="removeItem(i)"
                        [disabled]="disabled"
                    ></cds-icon>
                </div>

                <string-group-or-custom-value
                    *ngIf="!disallowStringGroups"
                    class="path-or-query-match__string-group-or-custom-value"
                    [(stringGroupRefs)]="config.stringGroupRefs"
                    (stringGroupRefsChange)="handleStringsChange()"
                    [(customValues)]="config.matchStrings"
                    (customValuesChange)="handleStringsChange()"
                    [index]="i"
                    [disabled]="disabled"
                    [noMarginTop]="true"
                ></string-group-or-custom-value>

                <avi-input-container
                    *ngIf="disallowStringGroups"
                    noMarginTop
                >
                    <avi-repeated-strings
                        placeholder="{{ l10nKeys.enterCustomValueLabel | vtranslate }}"
                        name="{{ 'custom-value_' + matchObjectType + i }}"
                        [(ngModel)]="config.matchStrings"
                        (ngModelChange)="handleStringsChange()"
                        [disabled]="disabled"
                        required
                    ></avi-repeated-strings>
                </avi-input-container>
            </div>
        </div>

        <cds-button
            *ngIf="!disallowStringGroups"
            class="path-or-query-match__add-item-button"
            action="flat"
            size="sm"
            (click)="addItem()"
            [disabled]="disabled"
        >
            <cds-icon shape="plus-circle"></cds-icon>
            {{ l10nKeys.addItemButtonLabel | vtranslate }}
        </cds-button>
    </div>
</match-wrapper>
