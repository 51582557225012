/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    InventoryCollDataSource,
} from 'ajs/modules/data-model/factories/inventory-coll-data-source.factory';
import { filter, reject } from 'underscore';
import { GSLBSiteCollection } from './gslb-site.collection.factory';

/**
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBSiteInventoryCollDataSource extends InventoryCollDataSource {
    public hasSearch = false;
    public hasSorting = false;
    public hasPagination = false;
    public params_: any;
    public owner_: GSLBSiteCollection;

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public getRequestParams_(): any {
        const request = super.getRequestParams_(this.params_);

        // eslint-disable-next-line no-underscore-dangle
        request.objectName_ = [this.owner_.objectName_, this.owner_.getGSLBid()];

        return request;
    }

    /**
     * Since GslbSiteCollection may have only either regular or "non-avi" sites we need to
     * filter out inappropriate ones.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public processResponse_(data: any, requestParams: any): void {
        const { data: resp } = data;
        const { nonAvi } = this.owner_;

        if (nonAvi) {
            resp.results = filter(resp.results, ({ config }: any) => config.isNonAviSite);
        } else {
            resp.results = reject(resp.results, ({ config }: any) => config.isNonAviSite);
        }

        resp.count = resp.results.length;

        super.processResponse_(data, requestParams);
    }

    /** @override */
    public isInactive(): boolean {
        return !this.owner_.getGSLBid() || super.isInactive();
    }
}
