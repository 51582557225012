<div class="avi-data-grid-column-selection">
    <cds-icon
        shape="view-columns"
        class="avi-data-grid-column-selection__view-columns-btn"
        avi-tooltip
        [showOnClick]="true"
        [tooltipControl$]="columnSelectionCloseButtonControl$"
        [positionsPriority]="viewColumnPanelPositions"
    >
        <ng-template #aviTooltipContent>
            <div class="avi-data-grid-column-selection__panel clr-wrapper">
                <div class="avi-data-grid-column-selection__content">
                    <div class="avi-data-grid-column-selection__header">
                        <h5>{{ l10nKeys.showColumnsHeader | vtranslate }}</h5>
                        <cds-icon
                            class="avi-data-grid-column-selection__close-btn"
                            shape="window-close"
                            (click)="closeColumnSelectionPanel()"
                        ></cds-icon>
                    </div>
                    <div class="avi-data-grid-column-selection__list">
                        <clr-checkbox-container *ngFor="let field of fields">
                            <clr-checkbox-wrapper>
                                <input
                                    clrCheckbox
                                    type="checkbox"
                                    class="sel-avi-checkbox"
                                    name="{{ field.id }}"
                                    id="{{ field.id }}"
                                    [disabled]="isMandatoryField(field)"
                                    [(ngModel)]="showColumnHash[field.id]"
                                />
                                <label>{{ field.label }}</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </div>
                    <cds-button
                        class="avi-data-grid__column-select-all-btn"
                        [disabled]="allFieldColumnsShown"
                        (click)="showAllFieldColumns()"
                        action="flat-inline"
                    >
                        {{ l10nKeys.columnSelectAllButton | vtranslate }}
                    </cds-button>
                </div>
            </div>
        </ng-template>
    </cds-icon>
</div>
