/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'dns-record-config-modal';
const prefix = `${moduleName}.${componentName}`;

export const editStaticDnsRecordHeader = `${prefix}.editStaticDnsRecordHeader`;
export const newStaticDnsRecordHeader = `${prefix}.newStaticDnsRecordHeader`;
export const doneSubmitModalLabel = `${prefix}.doneSubmitModalLabel`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const aaaaTypeDnsRecordHeader = `${prefix}.aaaaTypeDnsRecordHeader`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const removeARecordButtonLabel = `${prefix}.removeARecordButtonLabel`;
export const addARecordButtonLabel = `${prefix}.addARecordButtonLabel`;
export const ipv6AddressInputLabel = `${prefix}.ipv6AddressInputLabel`;
export const removeAaaaRecordButtonLabel = `${prefix}.removeAaaaRecordButtonLabel`;
export const addAaaaRecordButtonLabel = `${prefix}.addAaaaRecordButtonLabel`;
export const srvTypeDnsRecordHeader = `${prefix}.srvTypeDnsRecordHeader`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const targetInputLabel = `${prefix}.targetInputLabel`;
export const priorityInputLabel = `${prefix}.priorityInputLabel`;
export const weightInputLabel = `${prefix}.weightInputLabel`;
export const removeServiceLocatorButtonLabel = `${prefix}.removeServiceLocatorButtonLabel`;
export const addServiceLocatorButtonLabel = `${prefix}.addServiceLocatorButtonLabel`;
export const cnameTypeDnsRecordHeader = `${prefix}.cnameTypeDnsRecordHeader`;
export const nameServerDnsRecordHeader = `${prefix}.nameServerDnsRecordHeader`;
export const nameServerNameInputLabel = `${prefix}.nameServerNameInputLabel`;
export const nameServerIpAddressInputLabel = `${prefix}.nameServerIpAddressInputLabel`;
export const removeNameServerButtonLabel = `${prefix}.removeNameServerButtonLabel`;
export const addNameServerButtonLabel = `${prefix}.addNameServerButtonLabel`;
export const advancedSettingsHeader = `${prefix}.advancedSettingsHeader`;
export const numberOfRecordsInResponseInputLabel = `${prefix}.numberOfRecordsInResponseInputLabel`;
export const numberOfRecordsInResponseInputPlaceholder = `${prefix}.numberOfRecordsInResponseInputPlaceholder`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const algorithmInputPlaceholder = `${prefix}.algorithmInputPlaceholder`;
export const enableWildcardMatchCheckboxLabel = `${prefix}.enableWildcardMatchCheckboxLabel`;
export const delegatedDomainsCheckboxLabel = `${prefix}.delegatedDomainsCheckboxLabel`;

export const ENGLISH = {
    [editStaticDnsRecordHeader]: 'Edit Static DNS Record',
    [newStaticDnsRecordHeader]: 'New Static DNS Record',
    [doneSubmitModalLabel]: 'Done',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'Select Type',
    [aaaaTypeDnsRecordHeader]: 'A/AAAA Record',
    [ipAddressInputLabel]: 'IPv4 Address',
    [removeARecordButtonLabel]: 'Remove A record IPv4 Address',
    [addARecordButtonLabel]: '+ Add A Record IP Address',
    [ipv6AddressInputLabel]: 'IPv6 Address',
    [removeAaaaRecordButtonLabel]: 'Remove AAAA Record IP Address',
    [addAaaaRecordButtonLabel]: '+ Add AAAA Record IP Address',
    [srvTypeDnsRecordHeader]: 'SRV Record',
    [portInputLabel]: 'Port',
    [targetInputLabel]: 'Target',
    [priorityInputLabel]: 'Priority',
    [weightInputLabel]: 'Weight',
    [removeServiceLocatorButtonLabel]: 'Remove Service Locator',
    [addServiceLocatorButtonLabel]: '+ Add Service Locator',
    [cnameTypeDnsRecordHeader]: 'CNAME Record',
    [nameServerDnsRecordHeader]: 'NS Record',
    [nameServerNameInputLabel]: 'Name Server Name',
    [nameServerIpAddressInputLabel]: 'Name Server IP Address',
    [removeNameServerButtonLabel]: 'Remove Name Server',
    [addNameServerButtonLabel]: '+ Add Name Server',
    [advancedSettingsHeader]: 'Advanced Settings',
    [numberOfRecordsInResponseInputLabel]: 'Number of records in response',
    [numberOfRecordsInResponseInputPlaceholder]: 'Number of Records',
    [algorithmInputLabel]: 'Algorithm',
    [algorithmInputPlaceholder]: 'Select Algorithm',
    [enableWildcardMatchCheckboxLabel]: 'Enable wild-card match',
    [delegatedDomainsCheckboxLabel]: 'Delegated domains',
};
