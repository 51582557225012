<item-full-modal
    modalTitle="{{ (isEditing() ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    [form]="form"
    [item]="editable"
    submitButtonText ="{{ getSubmitLabel() }}"
    (onSubmit)="handleSubmit()"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
        (ngSubmit)="handleSubmit()"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="CloudConnectorUser"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="name"
                        class="sel-name"
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        required
                        [disabled]="isEditing()"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            for="credentialsType"
                        >
                            {{ l10nKeys.credentialsTypeFieldLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="credentialsType"
                            [options]="credentialsTypeOptions"
                            [(ngModel)]="credentialsType"
                            (ngModelChange)="setType()"
                            required
                            [disabled]="disableTypeChange()"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.credentialsTypeFieldLabel | vtranslate }}"
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            *ngIf="credentialsType === 'SSH'"
            tabTitle="{{ l10nKeys.sshCredentialsHeader | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.sshCredentialsHeader | vtranslate }}</h3>
            <fieldset [disabled]="isEditing()">
                <avi-radio-container [noMarginTop]="true">
                    <label aviLabelWithTooltip>
                        {{ globalL10nKeys.authenticationLabel | vtranslate }}
                    </label>
                    <avi-radio
                        name="sshAuthenticationType"
                        value="SSH_KEY"
                        [(ngModel)]="sshAuthenticationType"
                        (ngModelChange)="editable.clearSshFields()"
                    >
                        {{ l10nKeys.sshKeyFieldLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="sshAuthenticationType"
                        value="USER_PASS"
                        [(ngModel)]="sshAuthenticationType"
                        (ngModelChange)="editable.clearSshFields()"
                    >
                        {{ globalL10nKeys.passwordLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
            </fieldset>
            <div *ngIf="sshAuthenticationType === 'USER_PASS'">
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="CloudConnectorUser"
                        fieldName="password"
                        for="ssh-password"
                        required
                    >
                        {{ globalL10nKeys.passwordLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        type="password"
                        id="ssh-password"
                        class="sel-ssh-password"
                        placeholder="{{ globalL10nKeys.passwordPlaceholder | vtranslate }}"
                        name="password"
                        [(ngModel)]="editable.config.password"
                        required
                    />
                </avi-input-container>
            </div>
            <div *ngIf="sshAuthenticationType === 'SSH_KEY'">
                <avi-radio-container [noMarginTop]="true">
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.keysFieldLabel | vtranslate }}
                    </label>
                    <avi-radio
                        name="sshKeyType"
                        value="GENERATE"
                        [(ngModel)]="sshKeyType"
                        (ngModelChange)="editable.clearKeys()"
                    >
                        {{ l10nKeys.sshKeyValuePairFieldLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        name="sshKeyType"
                        value="IMPORT"
                        [(ngModel)]="sshKeyType"
                        (ngModelChange)="editable.clearKeys()"
                    >
                        {{ l10nKeys.importPrivateKeyFieldLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
                <div [hidden]="sshKeyType === 'IMPORT'">
                    <avi-textarea-container>
                        <label
                            aviLabelWithTooltip
                            objectType="CloudConnectorUser"
                            fieldName="public_key"
                            for="public_key"
                        >
                            {{ l10nKeys.publicKeyFieldLabel | vtranslate }}
                        </label>
                        <textarea
                            clrTextarea
                            id="public_key"
                            class="sel-public_key"
                            [(ngModel)]="editable.config.public_key"
                            name="public_key"
                            placeholder="{{ l10nKeys.publicKeyFieldPlaceholder | vtranslate }}"
                            rows="10"
                            disabled
                        ></textarea>
                    </avi-textarea-container>
                </div>
                <div [hidden]="sshKeyType === 'GENERATE'">
                    <div class="full-modal-config__form-control-row">
                        <div class="full-modal-config__form-control">
                            <label
                                aviLabelWithTooltip
                                tooltipText="{{ l10nKeys.importPrivateKeyFieldTooltip | vtranslate }}"
                                for="public_key"
                            >
                                {{ l10nKeys.importPrivateKeyFieldLabel | vtranslate }}
                            </label>
                            <file-upload-textarea
                                [(ngModel)]="editable.config.private_key"
                                name="private_key"
                                placeholder="{{ l10nKeys.privateKeyFieldLabel | vtranslate }}"
                            ></file-upload-textarea>
                        </div>
                    </div>
                    <avi-input-container>
                        <label
                            aviLabelWithTooltip
                            for="passphrase"
                        >
                            {{ l10nKeys.privateKeyPassphraseFieldLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            type="password"
                            id="passphrase"
                            class="sel-passphrase"
                            placeholder="{{ l10nKeys.passphrasePlaceholder | vtranslate }}"
                            name="passphrase"
                            [(ngModel)]="editable.config.passphrase"
                        />
                    </avi-input-container>
                </div>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            *ngIf="credentialsType === 'AZURE'"
            tabTitle="{{ l10nKeys.azureCredentialsHeader | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.azureCredentialsHeader | vtranslate }}</h3>
            <avi-radio-container [noMarginTop]="true">
                <label aviLabelWithTooltip>
                    {{ globalL10nKeys.authenticationLabel | vtranslate }}
                </label>
                <avi-radio
                    name="azureAuthenticationType"
                    value="USER_PASS"
                    [(ngModel)]="azureAuthenticationType"
                    (ngModelChange)="setType()"
                >
                    {{ l10nKeys.usernamePasswordFieldLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="azureAuthenticationType"
                    value="SERVICE_PRINCIPAL"
                    [(ngModel)]="azureAuthenticationType"
                    (ngModelChange)="setType()"
                >
                    {{ l10nKeys.applicationIdFieldLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
            <div *ngIf="azureAuthenticationType === 'USER_PASS'">
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureUserPassCredentials"
                        fieldName="username"
                        for="username"
                        required
                    >
                        {{ globalL10nKeys.usernameLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="username"
                        class="sel-username"
                        placeholder="{{ globalL10nKeys.usernamePlaceholder | vtranslate }}"
                        name="username"
                        [(ngModel)]="azureUserpass.config.username"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureUserPassCredentials"
                        fieldName="password"
                        for="azure-password"
                        required
                    >
                        {{ globalL10nKeys.passwordLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        type="password"
                        id="azure-password"
                        class="sel-azure-password"
                        placeholder="{{ globalL10nKeys.passwordPlaceholder | vtranslate }}"
                        name="password"
                        [(ngModel)]="azureUserpass.config.password"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureUserPassCredentials"
                        fieldName="tenant_name"
                        for="tenant_name"
                        required
                    >
                        {{ l10nKeys.activeDirectoryFieldLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_name"
                        class="sel-tenant_name"
                        placeholder="{{ l10nKeys.activeDirectoryFieldLabel | vtranslate }}"
                        name="tenant_name"
                        [(ngModel)]="azureUserpass.config.tenant_name"
                        required
                    />
                </avi-input-container>
            </div>
            <div *ngIf="azureAuthenticationType === 'SERVICE_PRINCIPAL'">
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureServicePrincipalCredentials"
                        fieldName="application_id"
                        for="application_id"
                        required
                    >
                        {{ l10nKeys.applicationIdFieldLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="application_id"
                        class="sel-application_id"
                        placeholder="{{ l10nKeys.applicationIdFieldLabel | vtranslate }}"
                        name="application_id"
                        [(ngModel)]="azureServicePrincipal.config.application_id"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureServicePrincipalCredentials"
                        fieldName="authentication_token"
                        for="authentication_token"
                        required
                    >
                        {{ l10nKeys.authenticationTokenFieldLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        type="password"
                        id="authentication_token"
                        class="sel-authentication_token"
                        placeholder="{{ l10nKeys.authenticationTokenFieldLabel | vtranslate }}"
                        name="authentication_token"
                        [(ngModel)]="azureServicePrincipal.config.authentication_token"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="AzureServicePrincipalCredentials"
                        fieldName="tenant_id"
                        for="tenant_id"
                        required
                    >
                        {{ l10nKeys.tenantIdFieldLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="tenant_id"
                        class="sel-tenant_id"
                        placeholder="{{ l10nKeys.tenantIdFieldLabel | vtranslate }}"
                        name="tenant_id"
                        [(ngModel)]="azureServicePrincipal.config.tenant_id"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section
            *ngIf="credentialsType === 'GCP'"
            tabTitle="{{ l10nKeys.gcpCredentialsHeader | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.gcpCredentialsHeader | vtranslate }}</h3>
            <gcp-credentials [editable]="gcpCredentials"></gcp-credentials>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="credentialsType === 'NSXT'"
            tabTitle="{{ l10nKeys.nsxtCredentialsHeader | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.nsxtCredentialsHeader | vtranslate }}</h3>
            <nsxt-credentials [editable]="this.editable.config.nsxt_credentials"></nsxt-credentials>
        </full-modal-tab-section>

        <full-modal-tab-section
            *ngIf="credentialsType === 'VCENTER'"
            tabTitle="{{ l10nKeys.vcenterCredentialsHeader | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.vcenterCredentialsHeader | vtranslate }}</h3>
            <vcenter-credentials [editable]="this.editable.config.vcenter_credentials"></vcenter-credentials>
        </full-modal-tab-section>
    </form>
</item-full-modal>
