/**
 * @module VerticalNavModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    StateService,
    TransitionService,
} from '@uirouter/core';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';
import { StateManager } from 'ajs/modules/core/services/state-manager';
import './vertical-nav.component.less';

/**
 * Vertical Nav component.
 *
 * @author Rachit Aggarwal, alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'vertical-nav',
    templateUrl: './vertical-nav.component.html',
})
export class VerticalNavComponent implements OnInit, OnDestroy {
    /**
     * Root state of current state.
     */
    public parent: IAppState;

    /**
     * List of states to be shown in navigation.
     */
    public states: IAppState[];

    /**
     * The full name of the current state. Passed to IsActiveStatePipe for a quick comparison, but
     * more importantly used to trigger a rerun of the pipe when the state changes.
     */
    public activeStateName: string;

    /**
     * Function for unregistering TransitionService onStart. null by default.
     */
    private unregisterTransitionStart: any = null;

    /**
     * Function for unregistering TransitionService onSuccess. null by default.
     */
    private unregisterTransitionSuccess: any = null;

    public constructor(
        @Inject('$state')
        private readonly $state: StateService,
        @Inject('$transitions')
        private readonly $transitions: TransitionService,
        private readonly stateManagerService: StateManager,
        @Inject('appStateTree')
        private readonly appStateTree: IAppState[],
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.setProperties();

        this.unregisterTransitionStart = this.$transitions.onStart(
            { to: 'authenticated.**' },
            () => {
                this.states = [];
                this.changeDetectorRef.markForCheck();
            },
        );

        this.unregisterTransitionSuccess = this.$transitions.onSuccess(
            { to: 'authenticated.**' },
            () => {
                this.setProperties();
                this.changeDetectorRef.markForCheck();
            },
        );
    }

    public ngOnDestroy(): void {
        this.unregisterTransitionStart();
        this.unregisterTransitionSuccess();
    }

    /**
     * Redirects to the state page.
     */
    public redirectTo(state: string): void {
        this.$state.go(this.stateManagerService.getFullAllowedState(state));
    }

    /**
     * Track by function for ngFor iterator on states.
     */
    public trackByState(index: number, state: IAppState): string {
        return state.fullName;
    }

    /**
     * Sets the states on initial load and change.
     */
    private setProperties(): void {
        this.parent = this.getParent();
        this.states = this.getStates();
        this.activeStateName = this.$state.$current.name;
    }

    /**
     * Returns the root state.
     */
    private getParent(): IAppState {
        return this.appStateTree.find(state => state.name === this.getParentName());
    }

    /**
     * Returns name of the root state.
     */
    private getParentName(): string {
        const { $current: currentState } = this.$state;

        return currentState.name.split('.').splice(0, 2).join('.');
    }

    /**
     * Returns list of states to be shown.
     */
    private getStates(): IAppState[] {
        return this.parent.children;
    }
}
