/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'individual-tenant-setting-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const individualTenantsLabel = `${prefix}.individualTenantsLabel`;

export const ENGLISH = {
    [individualTenantsLabel]: 'Individual Tenant',
};
