/**
 * Module containing form components.
 * @preferred
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrAccordionModule,
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { NotificationModule } from 'ng/modules/notification/notification.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { AviRadioModule } from './modules/avi-radio/avi-radio.module';

import {
    AviCheckboxComponent,
    AviEnumDropdownComponent,
    AviFieldsetComponent,
    AviRepeatedCollectionDropdownsGridComponent,
    AviRepeatedCollectionOrCustomValueComponent,
    AviRepeatedDropdownsGridComponent,
    AviRepeatedKeyValuesGridComponent,
    AviRepeatedNumbersComponent,
    AviRepeatedStringsGridComponent,
    AviSearchComponent,
    BackupPassphraseComponent,
    CloudVrfContextComponent,
    CloudVrfContextDialogComponent,
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
    CustomParamsGridComponent,
    DynamicParamsGridComponent,
    EnumRadioButtonsComponent,
    ItemFullModalComponent,
    StringGroupOrCustomValueComponent,
} from './components';

import {
    ButtonDirective,
    ConfigFieldInputValidationDirective,
    CronExpressionValidationDirective,
    InSubnetDirective,
    InvalidateFormDirective,
    NumericFieldInputValidationDirective,
    ParseIpAddrPrefixMessageItemDirective,
    ParseIpDirective,
    ParseIpListDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProvideParentFormDirective,
    RegexPatternDirective,
    StringLengthInputValidationDirective,
    SubnetBaseIpDirective,
    TrueFalseValueDirective,
    UniqueValueInputValidationDirective,
    ValidatePortDirective,
} from './directives';

import {
    stringGroupCollectionProvider,
} from './ajs-upgraded-providers';

const aviFormsComponents = [
    AviCheckboxComponent,
    AviEnumDropdownComponent,
    AviFieldsetComponent,
    AviRepeatedCollectionDropdownsGridComponent,
    AviRepeatedDropdownsGridComponent,
    AviRepeatedKeyValuesGridComponent,
    AviRepeatedNumbersComponent,
    AviRepeatedStringsGridComponent,
    AviSearchComponent,
    BackupPassphraseComponent,
    CloudVrfContextComponent,
    CloudVrfContextDialogComponent,
    CredentialsVerificationComponent,
    CredentialsVerificationDialogComponent,
    CustomParamsGridComponent,
    DynamicParamsGridComponent,
    EnumRadioButtonsComponent,
    ItemFullModalComponent,
    AviRepeatedCollectionOrCustomValueComponent,
    StringGroupOrCustomValueComponent,
];

const aviFormsDirectives = [
    ButtonDirective,
    ConfigFieldInputValidationDirective,
    CronExpressionValidationDirective,
    InSubnetDirective,
    InvalidateFormDirective,
    NumericFieldInputValidationDirective,
    ParseIpAddrPrefixMessageItemDirective,
    ParseIpDirective,
    ParseIpListDirective,
    PasswordInputDirective,
    PermissionCheckDirective,
    ProvideParentFormDirective,
    RegexPatternDirective,
    StringLengthInputValidationDirective,
    SubnetBaseIpDirective,
    TrueFalseValueDirective,
    UniqueValueInputValidationDirective,
    ValidatePortDirective,
];

@NgModule({
    declarations: [
        ...aviFormsComponents,
        ...aviFormsDirectives,
    ],
    exports: [
        ButtonDirective,
        FormsModule,
        ClrAccordionModule,
        ClrFormsModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrCheckboxModule,
        AviCheckboxComponent,
        AviEnumDropdownComponent,
        AviFieldsetComponent,
        AviRadioModule,
        AviRepeatedCollectionDropdownsGridComponent,
        AviRepeatedDropdownsGridComponent,
        AviRepeatedKeyValuesGridComponent,
        AviRepeatedNumbersComponent,
        AviRepeatedStringsGridComponent,
        AviSearchComponent,
        BackupPassphraseComponent,
        CloudVrfContextComponent,
        CloudVrfContextDialogComponent,
        CredentialsVerificationComponent,
        CredentialsVerificationDialogComponent,
        ConfigFieldInputValidationDirective,
        CronExpressionValidationDirective,
        CustomParamsGridComponent,
        DynamicParamsGridComponent,
        EnumRadioButtonsComponent,
        ItemFullModalComponent,
        InvalidateFormDirective,
        NumericFieldInputValidationDirective,
        ParseIpAddrPrefixMessageItemDirective,
        ParseIpDirective,
        ParseIpListDirective,
        PasswordInputDirective,
        PermissionCheckDirective,
        ProvideParentFormDirective,
        RegexPatternDirective,
        ValidatePortDirective,
        StringGroupOrCustomValueComponent,
        TrueFalseValueDirective,
        SubnetBaseIpDirective,
        InSubnetDirective,
        AviRepeatedCollectionOrCustomValueComponent,
        UniqueValueInputValidationDirective,
        StringLengthInputValidationDirective,
    ],
    imports: [
        AviRadioModule,
        ClrCheckboxModule,
        ClrInputModule,
        CommonModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        NotificationModule,
        ClrRadioModule,
    ],
    providers: [
        stringGroupCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AviFormsModule {}
