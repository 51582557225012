/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name StringMatchConfig
 * @description StringMatchConfig ConfigItem class.
 */
const stringMatchConfigFactory = (MatchConfigItem, schemaService) => {
    class StringMatchConfig extends MatchConfigItem {
        constructor(args) {
            super(args);

            this.transformAfterLoad_();
        }

        /**
         * Transform data for use.
        */
        transformAfterLoad_() {
            const config = this.getConfig();

            config.match_criteria = config.match_criteria || '';
            config.match_str = config.match_str || [];
            config.string_group_refs = config.string_group_refs || [];
        }

        /** @override */
        toString() {
            const
                {
                    match_criteria: matchCriteria,
                    match_str: matchStr,
                    string_group_refs: stringGroupRefs,
                } = this.getConfig(),
                val = [];

            if (matchStr) {
                val.push(...matchStr);
            }

            stringGroupRefs.forEach(item => {
                val.push(`group ${item.name() || item.slug()}`);
            });

            return `${schemaService.getEnumValue('StringOperation', matchCriteria).label
                .toLowerCase()} ${val.join(', ')}`;
        }
    }

    angular.extend(StringMatchConfig.prototype, {
        type: 'StringMatch',
        name: 'String',
    });

    return StringMatchConfig;
};

stringMatchConfigFactory.$inject = [
        'MatchConfigItem',
        'schemaService',
];

angular.module('aviApp').factory('StringMatchConfig', stringMatchConfigFactory);
