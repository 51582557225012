/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from 'generated-types';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { IUserAccountPayload } from './user-account.state';

const USER_ACCOUNT_URL = '/api/useraccount';

/**
 * @description Service used to make HTTP requests to /api/useraccount.
 * @author alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class UserAccountService {
    constructor(private readonly http: AlbHttpClient) {}

    public fetchUserAccount(): Observable<IUser> {
        return this.http.get<IUser>(USER_ACCOUNT_URL);
    }

    public saveUserAccount(
        userAccount: IUserAccountPayload,
    ): Observable<IUserAccountPayload> {
        return this.http.put<IUserAccountPayload>(USER_ACCOUNT_URL, userAccount);
    }
}
