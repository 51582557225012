<full-modal-preview>
    <full-modal-preview_container>
        <div class="gslb-site-preview">
            <div class="gslb-site-preview__item-preview-defaults">
                <item-preview-defaults 
                    [config]="config"
                    [isItem]="true"
                ></item-preview-defaults>
            </div>
            <div class="gslb-site-preview__item">
                <h6>{{ l10nKeys.siteTypeLabel | vtranslate }}</h6>
                <div class="gslb-site-preview__item-value">
                    {{ siteType }}
                </div>
            </div>
        </div>
    </full-modal-preview_container>
</full-modal-preview>
