<div
    [hidden]="isHidden"
    class="full-modal clr-wrapper"
>
    <div class="full-modal__logo">
        <cds-icon
            shape="vm-bug"
            size="36"
        ></cds-icon>
        <span class="full-modal__title">VMware Avi Load Balancer</span>
    </div>
    <full-modal-breadcrumbs
        *ngIf="!isHidden"
        [modalLayoutStack]="modalLayoutStack"
        class="full-modal__breadcrumbs"
    ></full-modal-breadcrumbs>
    <div class="full-modal__config-container">
        <div class="full-modal__config">
            <ng-template #fullModalConfig></ng-template>
        </div>
    </div>
    <div class="full-modal__preview">
        <ng-template #fullModalPreviewConfig></ng-template>
    </div>
</div>
