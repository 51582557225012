/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { ILinuxServerConfiguration } from 'generated-types';
import { LinuxServerConfiguration } from 'object-types';
import { LinuxServerHostConfigItem } from '.';

type TlinuxServerConfigurationPartial = Omit<ILinuxServerConfiguration, 'hosts'>;

interface ILinuxServerConfig extends TlinuxServerConfigurationPartial {
    hosts: RepeatedMessageItem<LinuxServerHostConfigItem>;
}

/**
 * Holds the constant string values for SE Usage radio buttons.
 */
export enum DefaultSeUsageValues {
    AUTO = 'AUTO',
    SINGLE = 'SINGLE',
    BOTH = 'BOTH',
}

/**
 * @description
 *
 *   LinuxServerConfiguration Message Item.
 *
 * @author Sarthak kapoor
 */
export class LinuxServerConfigurationConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<ILinuxServerConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: LinuxServerConfiguration,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Opens modal for adding host server for Linux cloud.
     */
    public addHostServer(cloudId: string, isEditing: boolean): void {
        const { ssh_user_ref: userRef } = this.config;

        this.addChildMessageItem({
            field: 'hosts',
            modalBindings: {
                isEditing,
                cloudId,
                userRef,
            },
        });
    }

    /**
     * Opens a modal for editing an already added host server for Linux Cloud.
     */
    public editHostServer(
        hostServer: LinuxServerHostConfigItem,
        cloudId: string,
        isEditing: boolean,
    ): void {
        const { ssh_user_ref: userRef } = this.config;

        this.editChildMessageItem({
            field: 'hosts',
            messageItem: hostServer,
            modalBindings: {
                isEditing,
                cloudId,
                userRef,
            },
        });
    }

    /**
     * Returns the count of host servers added on Linux Server Config.
     */
    public get hostServersCount(): number {
        const { hosts } = this.getConfig();

        return hosts.count;
    }

    /**
     * Updates the config on change of se usage path radio button.
     */
    public handleSeUsagePathChange(seUsagePath: string): void {
        const config = this.getConfig();
        const defaultConfig = this.getDefaultConfig();

        config.se_log_disk_size_GB = defaultConfig.se_log_disk_size_GB;
        config.se_sys_disk_size_GB = defaultConfig.se_sys_disk_size_GB;

        if (seUsagePath === DefaultSeUsageValues.AUTO) {
            config.se_sys_disk_path = undefined;
            config.se_sys_disk_size_GB = undefined;
            config.se_log_disk_path = undefined;
            config.se_log_disk_size_GB = undefined;
        } else if (seUsagePath === DefaultSeUsageValues.SINGLE) {
            config.se_log_disk_path = undefined;
            config.se_log_disk_size_GB = undefined;
        }
    }
}
