<full-modal-config
    modalTitle="{{ l10nKeys.verifyAuthProfileHeader | vtranslate }}"
    modalSubTitle="{{ config.profile }}"
    (onCancel)="dismissModal()"
    (onSubmit)="verifyLDAP()"
    [busy]="busy"
    [errors]="errors"
    [valid]="form.valid"
    submitButtonText="{{ l10nKeys.verifyBtnLabel | vtranslate }}"
    class="auth-profile-verify-ldap-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <div class="full-modal-config__section">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    for="name"
                >
                    {{ l10nKeys.nameInputLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    type="text"
                    clrInput
                    disabled
                    placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                    [(ngModel)]="config.profile"
                />
            </avi-input-container>
            <avi-radio-container *ngIf="hasModeAdmin">
                <avi-radio
                    name="search-type"
                    [(ngModel)]="search"
                    value="{{ adminSearchType.USER }}"
                >
                    {{ l10nKeys.testUserEntryRadioLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="search-type"
                    [(ngModel)]="search"
                    value="{{ adminSearchType.GROUP }}"
                >
                    {{ l10nKeys.testUserGroupMembershipRadioLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="search-type"
                    [(ngModel)]="search"
                    value="{{ adminSearchType.NONE }}"
                >
                    {{ l10nKeys.testBaseDnRadioLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
            <ng-container *ngIf="hasModeAnonymous || search!==adminSearchType.NONE">
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                for="username"
                                [required]="hasModeAnonymous"
                            >
                                {{ l10nKeys.userNameInputLabel | vtranslate }}
                            </label>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                clrInput
                                placeholder="{{ l10nKeys.userNameInputPlaceholder | vtranslate }}"
                                [(ngModel)]="config.username"
                                [required]="hasModeAdmin"
                            />
                        </avi-input-container>
                    </div>
                    <div
                        class="full-modal-config__form-control"
                        *ngIf="hasModeAnonymous"
                    >
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                for="password"
                                required
                            >
                                {{ l10nKeys.passwordInputLabel | vtranslate }}
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="text"
                                clrInput
                                required
                                placeholder="{{ l10nKeys.passwordInputPlaceholder | vtranslate }}"
                                [(ngModel)]="config.password"
                            />
                        </avi-input-container>
                    </div>
                </div>
            </ng-container>
            <avi-textarea-container *ngIf="results">
                <label
                    aviLabelWithTooltip
                    for="results"
                >
                    {{ l10nKeys.resultsInputLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    [ngModel]="results"
                    name="results"
                    placeholder="-"
                    rows="20"
                    class="auth-profile-verify-ldap-modal__textarea"
                    readonly
                ></textarea>
            </avi-textarea-container>
        </div>
    </form>
</full-modal-config>
