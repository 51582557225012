/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'underscore';
import { AviDefinedFilters } from 'generated-types';
import { IEnumValue, SchemaService } from 'ajs/modules/core/services';

export type TVsLogSignificanceReason = {
    label: string;
    description?: string;
};

/**
 * @description
 *     Pipe for transforming "significance" field of a log entry to a list of descriptive objects.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'logSignificanceReasons',
})
export class LogSignificanceReasonsPipe implements PipeTransform {
    constructor(
        private readonly schemaService: SchemaService,
    ) {}

    /** @override */
    public transform(significantLog: AviDefinedFilters[]): TVsLogSignificanceReason[] {
        if (!significantLog) {
            return [];
        }

        const enums: IEnumValue[] = this.schemaService.getEnumValues('AviDefinedFilters');

        const results = significantLog.map(
            (logFilter: AviDefinedFilters): TVsLogSignificanceReason => {
                const enumValue: IEnumValue = find(enums, (enumVal: IEnumValue) => {
                    return enumVal.value === logFilter;
                });

                const { label, description } = enumValue;

                return {
                    label,
                    description,
                };
            },
        );

        return results;
    }
}
