<ng-container *ngrxLet="ssl$ as ssl">
    <!-- TLS Handshake failure results in a defined client_cipher_list, since this field is only generated
    when TLS handshake fails due to no shared cipher. -->
    <ng-container *ngIf="!ssl.client_cipher_list; else tlsHandshakeFailed">
        <div class="vs-log-cinematic-section-ssl__column-group">
            <!-- 1st column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.fullTlsFingerprintLabel | vtranslate }}
                </div>
                <div
                    *ngIf="ssl.client_fingerprints?.full_tls_fingerprint as fullFingerprint; else noData"
                    vsLogFilter
                    property="client_fingerprints.full_tls_fingerprint"
                    [value]="fullFingerprint"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ fullFingerprint }}
                </div>
            </div>

            <!-- 2nd column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.filteredTlsFingerprintLabel | vtranslate }}
                </div>
                <div
                    *ngIf="ssl.client_fingerprints?.filtered_tls_fingerprint as filteredFingerprint; else noData"
                    vsLogFilter
                    property="client_fingerprints.filtered_tls_fingerprint"
                    [value]="filteredFingerprint"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ filteredFingerprint }}
                </div>
            </div>

            <!-- 3rd column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.normalizedFingerprintLabel | vtranslate }}
                </div>
                <div
                    *ngIf="ssl.client_fingerprints?.normalized_tls_fingerprint as normalizedFingerprint; else noData"
                    vsLogFilter
                    property="client_fingerprints.normalized_tls_fingerprint"
                    [value]="normalizedFingerprint"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ normalizedFingerprint }}
                </div>
            </div>
        </div>

        <avi-data-grid
            [config]="sslGridConfig"
            [rows]="ssl | logSslGridRows"
        ></avi-data-grid>
    </ng-container>

    <ng-template #tlsHandshakeFailed>
        <avi-data-grid
            [config]="clientSuppliedCiphersGridConfig"
            [rows]="ssl.client_cipher_list.identified_ciphers"
        ></avi-data-grid>
    </ng-template>
</ng-container>

<ng-template
    #sslVersionFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.sslVersion; else noData"
        vsLogFilter
        [property]="'ssl_version'"
        [value]="row.sslVersion"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.sslVersion | vtranslate }}
    </span>
</ng-template>

<ng-template
    #sslCertTypeFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.sslCertType; else noData"
        vsLogFilter
        [property]="'ssl_cipher'"
        [operator]="FilterOperatorType.CONTAINS"
        [value]="'auth:' + row.sslCertType"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.sslCertType | vtranslate }}
    </span>
</ng-template>

<ng-template
    #sniHostnameFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.sniHostname; else noData"
        vsLogFilter
        [property]="'sni_hostname'"
        [value]="row.sniHostname"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.sniHostname | vtranslate }}
    </span>
</ng-template>

<ng-template
    #sslPfsFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.sslPfs; else noData"
        vsLogFilter
        [property]="'ssl_cipher'"
        [operator]="FilterOperatorType.CONTAINS"
        [value]="'PFS:' + row.sslPfs"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.sslPfs | vtranslate }}
    </span>
</ng-template>

<ng-template
    #sslEncFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.sslEnc; else noData"
        vsLogFilter
        [property]="'ssl_cipher'"
        [operator]="FilterOperatorType.CONTAINS"
        [value]="'enc:' + row.sslEnc"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.sslEnc | vtranslate }}
    </span>
</ng-template>

<ng-template
    #oscpStatusFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        *ngIf="row.oscpStatus; else noData"
        vsLogFilter
        [property]="'ocsp_status_resp_sent'"
        [value]="row.ocspStatus"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row.oscpStatus | vtranslate }}
    </span>
</ng-template>

<ng-template
    #clientSuppliedCiphersFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="client_cipher_list.identified_ciphers"
        [value]="row"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    >
        {{ row }}
    </span>
</ng-template>

<ng-template #noData>
    <span vsLogEmptyField></span>
</ng-template>
