<div class="full-modal-config-viewer">
    <span class="full-modal-config-viewer__separator"> | </span>
    <avi-dropdown-button
        [actions]="actions"
        [position]="position"
        [useIcon]="true"
        [hideCaret]="true"
    >
        <cds-icon
            class="icon"
            shape="file"
            size="16"
        ></cds-icon>
        <cds-icon
            shape="angle"
            direction="down"
            size="14"
        ></cds-icon>
    </avi-dropdown-button>
</div>
