/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('VSTrafficCapture', [
'$http', '$q', 'Base', 'windowLocation',
function($http, $q, Base, windowLocation) {
    /**
     * @typedef {Object} ipDebug
     * @property {Object[]} addrs - Array of individual IP addresses.
     * @property {Object[]} ranges - Array of IP ranges.
     * @property {Object[]} prefixes - Array of IP subnet masks.
     */

    /**
     * Instance of Base.
     */
    const base = new Base();

    const TrafCap = {};

    /**
     * Gets list of downloadable capture files.
     */
    TrafCap.getDownloadableCaptures = function() {
        return base.request('GET', '/api/fileservice?uri=controller://vs-pcap');
    };

    /**
     * Gets the current ongoing capture.
     */
    TrafCap.getCurrentCapture = function() {
        return base.request('GET', '/api/debugvirtualservice?capture=true');
    };

    /**
     * Stops the current ongoing capture.
     * @return {ng.$q.promise}
     */
    TrafCap.stopCapture = function(trafConfig) {
        const
            config = angular.copy(trafConfig),
            { uuid } = trafConfig;

        config.capture = false;

        return base.request('PUT', `/api/debugvirtualservice/${uuid}`, config);
    };

    /**
     * Gets progress of the current ongoing capture.
     * @param  {string} uuid - UUID of the Virtual Service.
     * @return {ng.$q.promise}
     */
    TrafCap.getProgress = function(uuid) {
        return base.request('GET', `/api/debugvirtualservice/${uuid}/progress`);
    };

    /**
     * Checks to see if the progress is complete, based on either the progress percentage or
     * the start and end durations.
     * @return {boolean} Returns true if capture is complete, false otherwise.
     */
    TrafCap.isComplete = function(progress) {
        return progress && progress.progress_percent === 100 ||
            progress.time_elapsed >= progress.duration_configured * 60 &&
            progress.duration_configured > 0;
    };

    /**
     * Opens dialog window to download capture file.
     * @param  {string} url - URL of the capture file.
     */
    TrafCap.download = function(url) {
        windowLocation.assign(url);
    };

    /**
     * Deletes unwanted capture files.
     * @return {ng.$q.promise}
     */
    // delete rows by making a GET request with purge=true to url
    TrafCap.deleteRows = function(rows) {
        return $q.all(rows.map(function(row) {
            return base.request('DELETE', `${row.url}&purge=true`);
        }));
    };

    /**
     * Cancels all ongoing requests.
     */
    TrafCap.cancelRequests = function() {
        base.cancelRequests();
    };

    return TrafCap;
}]);
