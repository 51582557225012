/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    AnalyticsProfileRespCodeBlock,
    IAnalyticsProfile,
    IHTTPStatusRange,
} from 'generated-types';

import { SensitiveLogProfileConfigItem }
    from './sensitive-log-profile.config-item.factory';

/**
 * Hashmap for AnalyticsType used for gathering analytics in AnalyticsProfile creation.
 */
export const analyticsProfileAnalyticsTypeLabelHash = {
    enable_vs_analytics: 'Virtual Services',
    enable_server_analytics: 'Servers',
    enable_se_analytics: 'Service Engines',
};

/**
 * Interface for external log servers list type.
 */
export interface IServerConfig {
    port: number,
    server: string
}

export interface IHttpStatusCodeRangeRespCodeBlock {
    exclude_http_error_codes?: number[],
    ranges?: RepeatedMessageItem<MessageItem<IHTTPStatusRange>>,
    resp_code_block?: AnalyticsProfileRespCodeBlock[],
}

/**
 * Interface for Client Log Streaming Config.
 */
type TAnalyticsProfilePartial =
    Omit<IAnalyticsProfile, 'sensitive_log_profile'>;

/**
 * Interface for Analytics ProfileConfig.
 */
export interface IExtendedAnalyticsProfile extends TAnalyticsProfilePartial {
    analyticsEnabledFor?: string[],
    sensitive_log_profile?: SensitiveLogProfileConfigItem,
}

/**
 * List of Tolerated Latency Factor.
 */
export const ToleratedLatencyFactor = [
    {
        value: 1,
        label: '1X',
    },
    {
        value: 2,
        label: '2X',
    },
    {
        value: 3,
        label: '3X',
    },
    {
        value: 4,
        label: '4X',
    },
    {
        value: 5,
        label: '5X',
    },
    {
        value: 6,
        label: '6X',
    },
    {
        value: 7,
        label: '7X',
    },
    {
        value: 8,
        label: '8X',
    },
    {
        value: 9,
        label: '9X',
    },
    {
        value: 10,
        label: '10X',
    },
];
