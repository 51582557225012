<avi-confirmation
    class="clr-wrapper move-data-grid-row"
    headerText="{{ l10nKeys.moveLabel | vtranslate }} {{ itemType }} '{{ selectedItemName }}'"
    (onClose)="handleCancel()"
>
    <ng-container ngProjectAs="avi-confirmation_modal-content">
        <div class="move-data-grid-row__container">
            <clr-radio-container clrInline>
                <label aviLabelWithTooltip>{{ l10nKeys.placementRadioButtonLabel | vtranslate }}</label>
                <clr-radio-wrapper>
                    <input
                        type="radio"
                        name="placement"
                        [(ngModel)]="placement"
                        [value]="placementEnum.ABOVE"
                        clrRadio
                    />
                    <label>{{ l10nKeys.aboveLabel | vtranslate }}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input
                        type="radio"
                        name="placement"
                        [(ngModel)]="placement"
                        [value]="placementEnum.BELOW"
                        clrRadio
                    />
                    <label>{{ l10nKeys.belowLabel | vtranslate }}</label>
                </clr-radio-wrapper>
            </clr-radio-container>
    
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <label
                        aviLabelWithTooltip
                        for="index"
                    >
                        {{ globalL10nKeys.indexLabel | vtranslate }}
                    </label>
                    <avi-dropdown
                        name="index"
                        [options]="indexDropdownOptions"
                        [(ngModel)]="index"
                        [hideSearch]="true"
                        placeholder="{{ l10nKeys.indexDropdownPlaceholder | vtranslate }}"
                        required
                    ></avi-dropdown>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container ngProjectAs="avi-confirmation_modal-actions">
        <cds-button (click)="moveItem()">{{ l10nKeys.moveLabel | vtranslate }}</cds-button>
    </ng-container>
</avi-confirmation>
