<div class="policy-rule">
    <expander-with-actions
        class="policy-rule__expander"
        openerClassName="policy-rule__expander-opener"
        [actions]="expanderActions"
    >
        <opener>
            <div class="policy-rule__opener">
                <i class="sl-icon-options-vertical policy-rule__drag-icon"></i>
                <tri-switch
                    (onClick)="handeEnableClick()"
                    [state]="getEnableState()"
                ></tri-switch>
                <div class="policy-rule__name">
                    {{ rule.getName() }}
                </div>
            </div>
        </opener>
        <content>
            <policy-rule-summary [rule]="rule"></policy-rule-summary>
        </content>
    </expander-with-actions>
</div>
