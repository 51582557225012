/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module LicensingModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IOrgServiceUnits,
    ISaasLicensingInfo,
} from 'generated-types';

import './license-card.component.less';
import * as globalL10n from 'global-l10n';
import * as l10n from './license-card.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Card to display license usage data for controller and organization.
 * @author Nikhil Sharma
 */

@Component({
    selector: 'license-card',
    templateUrl: './license-card.component.html',
})

export class LicenseCardComponent implements OnInit {
    /**
     * Flag to display spinner when application is fetching license data.
     */
    @Input()
    public isBusy: boolean;

    /**
     * Controller related Licensing information.
     */
    @Input()
    public saasConfig: ISaasLicensingInfo = {
        max_service_units: 0,
        reserve_service_units: 0,
    };

    /**
     * Used service cores from total licensed service cores.
     */
    @Input()
    public usedServiceCores = 0;

    /**
     * Organization related Licensing information.
     */
    @Input()
    public orgServiceUnits: IOrgServiceUnits = {
        available_service_units: 0,
        org_id: '',
        used_service_units: 0,
    };

    @Input()
    public licenseTierTitle: string;

    public reservedControllerUnitsValue = 0;
    public maxControllerUnitsValue = 0;
    public maxOrgUnitsValue = 0;
    public usedOrgUnitsValue = 0;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.orgServiceUnits = this.orgServiceUnits || {
            available_service_units: 0,
            org_id: '',
            used_service_units: 0,
        };
        this.reservedControllerUnitsValue = this.setReservedControllerUnits();
        this.maxControllerUnitsValue = this.setMaxControllerUnits();
        this.maxOrgUnitsValue = this.setMaxOrgUnits();
        this.usedOrgUnitsValue = this.setUsedOrgUnits();
    }

    /**
     * Get maximum service units count.
     */
    public setMaxControllerUnits(): number {
        return this.saasConfig?.max_service_units ?? 0;
    }

    /**
     * Get reserve service units count.
     */
    public setReservedControllerUnits(): number {
        return this.saasConfig?.reserve_service_units ?? 0;
    }

    /**
     * Get available service units count for organization.
     */
    public setMaxOrgUnits(): number {
        return this.orgServiceUnits?.available_service_units ?? 0;
    }

    /**
     * Get used service units count for organization.
     */
    public setUsedOrgUnits(): number {
        return this.orgServiceUnits?.used_service_units ?? 0;
    }
}
