/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRuleGslbSiteSelectionActionConfig
 * @description DnsPolicyRuleGslbSiteSelectionActionConfig,
 *      maps to DnsRuleActionGslbSiteSelection in protobof.
 */
const dnsPolicyRuleGslbSiteSelectionActionConfigFactory = ActionConfigItem => {
    class DnsPolicyRuleGslbSiteSelectionActionConfig extends ActionConfigItem {
        /** @override */
        toString() {
            const { site_name: siteName } = this.getConfig();

            return siteName || 'N/A';
        }
    }

    angular.extend(DnsPolicyRuleGslbSiteSelectionActionConfig.prototype, {
        type: 'DnsRuleActionGslbSiteSelection',
        name: 'GSLB Site',
        defaultPath: 'dnsrule.action.gslb_site_selection',
    });

    return DnsPolicyRuleGslbSiteSelectionActionConfig;
};

dnsPolicyRuleGslbSiteSelectionActionConfigFactory.$inject = [
        'ActionConfigItem',
];

angular.module('aviApp').factory(
    'DnsPolicyRuleGslbSiteSelectionActionConfig',
    dnsPolicyRuleGslbSiteSelectionActionConfigFactory,
);
