/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import { TrafficCloneProfileCollection }
    from 'ajs/modules/traffic-clone-profile/factories/traffic-clone-profile.collection.factory';

import { TRAFFIC_CLONE_PROFILE_COLLECTION_TOKEN }
    from 'ajs/modules/traffic-clone-profile/traffic-clone-profile.tokens';

const $injector = '$injector';

type TTrafficCloneProfileCollection = typeof TrafficCloneProfileCollection;

export const trafficCloneProfileCollectionProvider = {
    deps: [$injector],
    provide: TrafficCloneProfileCollection,
    useFactory(injector: Injector): TTrafficCloneProfileCollection {
        return injector.get(TRAFFIC_CLONE_PROFILE_COLLECTION_TOKEN);
    },
};
