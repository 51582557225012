/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CSRFPolicyListPageModule
 */

/* eslint-disable max-classes-per-file */

import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    Inject,
    NgModule,
    NO_ERRORS_SCHEMA,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService, VIPModule } from '@vmw/ngx-vip';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';

import {
    CSRFPolicyCollection,
    TCSRFPolicyCollection,
} from 'collections/csrf-policy.collection.factory';

import type { CSRFPolicyItem } from 'items/csrf-policy.item.factory';

import * as l10n from './csrf-policy-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description CSRF Policy list page.
 * @author alextsg
 */
@Component({
    selector: 'csrf-policy-list-page',
    templateUrl: './csrf-policy-list-page.component.html',
})
export class CSRFPolicyListPageComponent implements OnInit, OnDestroy {
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display CSRF Policy collection grid.
     */
    public csrfPolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * CSRFPolicyCollection instance.
     */
    public csrfPolicyCollection: CSRFPolicyCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(CSRFPolicyCollection)
        private readonly CSRFPolicyCollection: TCSRFPolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Set gridConfig.
     */
    public ngOnInit(): void {
        this.csrfPolicyCollection = new this.CSRFPolicyCollection();

        const { objectName } = this.csrfPolicyCollection;

        this.csrfPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.csrfPolicyCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: CSRFPolicyItem) => row.getName(),
                },
                {
                    id: 'rules',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleRules),
                    transform: (row: CSRFPolicyItem) => `${row.config.rules.count}`,
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.csrfPolicyCollection.destroy();
    }
}

@NgModule({
    declarations: [
        CSRFPolicyListPageComponent,
    ],
    imports: [
        DataGridModule,
        SharedModule,
        VIPModule.forChild(),
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
/* eslint-disable-next-line no-unused-vars */
class CSRFPolicyListPageModule {}
