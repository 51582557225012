/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { AviPermissionResource } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    AuthMappingProfileModalComponent,
} from 'ng/modules/auth-settings/components/auth-mapping-profile-modal';

import { AUTH_MAPPING_PROFILE_ITEM_TOKEN } from '../auth-settings.tokens';

/**
 * @description AuthMappingProfile collection
 *
 * @author Aravindh Nagarajan
 */
export class AuthMappingProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'authmappingprofile',
            permissionName: AviPermissionResource.PERMISSION_AUTHMAPPINGPROFILE,
            windowElement: AuthMappingProfileModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(AUTH_MAPPING_PROFILE_ITEM_TOKEN);
    }
}

AuthMappingProfileCollection.ajsDependencies = [
    AUTH_MAPPING_PROFILE_ITEM_TOKEN,
];
