/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import * as globalL10n from 'global-l10n';

import {
    IConfiguredNetworkSubnet,
    IStaticIpAddrTemp,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { StaticIpType } from 'generated-types';
import * as l10n from './network-subnet-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const { ...globalL10nKeys } = globalL10n;

/**
 * Network Subnet Modal.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'network-subnet-modal',
    templateUrl: './network-subnet-modal.component.html',
})
export class NetworkSubnetModalComponent implements AfterViewInit {
    /**
     * Each network subnet Info.
     */
    @Input()
    public editable: IConfiguredNetworkSubnet;

    /**
     * Informs if current type mapping is being edited, or new one created.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Used when user attempts to save.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Used when user closes the Modal.
     */
    @Output()
    public onSubnetModalDismiss = new EventEmitter();

    /**
     * TemplateRef for IP Address Range field.
     */
    @ViewChild('ipAddressRangeTemplateRef')
    public ipAddressRangeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for IP Address type field.
     */
    @ViewChild('staticIpTypeTemplateRef')
    public staticIpTypeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Variable modal header, depends on creating new, or editing.
     */
    public modalHeader: string;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Static IP Range Grid config.
     */
    public staticIpRangeGridConfig: IAviDataGridConfig;

    /**
     * Static IP types dropdown options.
     */
    public staticIpTypesEnum: IAviDropdownOption[] = [];

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const staticIpTypes = schemaService.getEnumValues('StaticIpType');

        this.staticIpTypesEnum = createOptionsFromEnumProps(staticIpTypes)
            .filter((option: IAviDropdownOption) => {
                return option.value !== StaticIpType.STATIC_IPS_FOR_VIP_AND_SE;
            });
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.staticIpRangeGridConfig = {
            fields: [{
                label: this.l10nService.getMessage(l10nKeys.ipAddressRangeColumnTitle),
                id: 'range',
                templateRef: this.ipAddressRangeTemplateRef,
            }, {
                label: this.l10nService.getMessage(l10nKeys.useForColumnTitle),
                id: 'type',
                templateRef: this.staticIpTypeTemplateRef,
            }],
            singleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (staticIpRange: IStaticIpAddrTemp) => {
                    const index = this.editable.static_ipaddr_tmp?.indexOf(staticIpRange);

                    this.editable.static_ipaddr_tmp?.splice(index, 1);
                },
            }],
            multipleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (staticIpRanges: IStaticIpAddrTemp[]) => {
                    staticIpRanges.forEach((staticIpRange: IStaticIpAddrTemp) => {
                        const index = this.editable.static_ipaddr_tmp?.indexOf(staticIpRange);

                        this.editable.static_ipaddr_tmp?.splice(index, 1);
                    });
                },
            }],
        };
    }

    /**
     * Add Static IP range click handler.
     */
    public addIpAddrRange(): void {
        this.editable.static_ipaddr_tmp?.push({
            range: '',
            type: this.editable.useStaticIpForSeAndVip ?
                StaticIpType.STATIC_IPS_FOR_VIP_AND_SE :
                undefined,
        });
    }

    /**
     * Change Handler for use Static Ip For Se And Vip checkbox.
     */
    public onUseSaticIpChange(): void {
        if (this.editable.useStaticIpForSeAndVip) {
            this.editable.static_ipaddr_tmp?.forEach((staticIpAddr: IStaticIpAddrTemp) => {
                staticIpAddr.type = StaticIpType.STATIC_IPS_FOR_VIP_AND_SE;
            });
        } else {
            this.editable.static_ipaddr_tmp?.forEach((staticIpAddr: IStaticIpAddrTemp) => {
                staticIpAddr.type = undefined;
            });
        }
    }

    /**
     * Checks if range is available in all Static IP range rows.
     */
    public get isFieldsMissing(): boolean {
        let result = false;

        this.editable.static_ipaddr_tmp?.forEach((staticIpAddr: IStaticIpAddrTemp) => {
            if (!staticIpAddr.range || !staticIpAddr.type) {
                result = true;
            }
        });

        return result;
    }

    /**
     * Handles user clicking save.
     */
    public onSave(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Handler for clicking cancel.
     */
    public onDismiss(): void {
        this.onSubnetModalDismiss.emit();
    }
}
