/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FullModalService } from 'ng/modules/core/services/full-modal/full-modal.service';

/**
 * @description
 * This service is provided in the FullModalComponent, so that only child components will be able to
 * inject it.
 * @author alextsg
 */
@Injectable()
export class FullModalComponentService {
    /**
     * Acts as a proxy for the viewMode$ observable from the root-provided FullModalService.
     *
     * Each form component has a view-mode along with an edit-mode, and the component subscribes to
     * viewMode$ to know which mode to display. However, only components within modals should
     * subscribe to viewMode$, since view-mode doesn't exist outside the context of modals. In order
     * for a form component to know whether or not it's in a modal, it can inject this service. If
     * the service was injectable, then the form component is a child of FullModalComponent and is
     * within a modal.
     */
    public viewMode$: Observable<boolean>;

    constructor(fullModalService: FullModalService) {
        this.viewMode$ = fullModalService.viewMode$;
    }
}
