/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { IOperationalStatus } from 'generated-types';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './gslb-placement-grid.l10n';
import './gslb-placement-grid.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Interface to represent site selected for subdomain.
 */
export interface ISiteDataRow {
    siteName: string,
    siteStatus?: IOperationalStatus,
    dnsVsNames: string[],
}

/**
 * @description Grid component for Subdomain Placement section.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'gslb-placement-grid',
    templateUrl: './gslb-placement-grid.component.html',
})
export class GslbPlacementGridComponent implements OnChanges, AfterViewInit {
    /**
     * Selected sites list for gslb subdomain.
     */
    @Input()
    public gslbSites: ISiteDataRow[];

    /**
     * Flag to toggle between sites with `all Subdomains enabled` and user selected sites view.
     */
    @Input()
    public isAllSubdomainsEnabled: boolean;

    /**
     * Fire on new site add event.
     */
    @Output()
    public onAddSite: EventEmitter<void> = new EventEmitter();

    /**
     * Fire on delete event for selected sites.
     */
    @Output()
    public onDeleteSites = new EventEmitter<ISiteDataRow[]>();

    /**
     * Fire on edit for selected site.
     */
    @Output()
    public onEditSite = new EventEmitter<ISiteDataRow>();

    /**
     * Template ref for displaying DNS VS names of GSLB sites.
     */
    @ViewChild('dnsVsFieldTemplateRef')
    public dnsVsFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for displaying Site name along with status icon.
     */
    @ViewChild('siteNameTemplateRef')
    public siteNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Gslb Sites Grid Config.
     */
    public gslbPlacementGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { isAllSubdomainsEnabled, gslbSites } = changes;

        if (isAllSubdomainsEnabled && !isAllSubdomainsEnabled.firstChange) {
            this.renderGslbGrid();
        } else if (gslbSites && !gslbSites.firstChange) {
            this.renderGslbGrid();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.renderGslbGrid();
    }

    /**
     * Handle GSLB site add operation for user selected list.
     */
    public addGslbSite(): void {
        this.onAddSite.emit();
    }

    /**
     * Render grid based on 'isAllSubdomainsEnabled' flag.
     * If true, render grid with all sites having 'all subdomains enabled'
     * else, with user selected list.
     */
    private renderGslbGrid(): void {
        if (this.isAllSubdomainsEnabled) {
            this.setGslbSitesWithAllSubdomainsGridConfig();
        } else {
            this.setGslbSitesGridConfig();
        }
    }

    /**
     * Set grid config for GSLB sites having 'All subdomains enabled'.
     */
    private setGslbSitesWithAllSubdomainsGridConfig(): void {
        const { l10nService } = this;

        this.gslbPlacementGridConfig = {
            layout: {
                hideCheckboxes: true,
            },
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    templateRef: this.siteNameTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.dnsVsLabel),
                    id: 'dns-vses',
                    templateRef: this.dnsVsFieldTemplateRef,
                },
            ],
        };
    }

    /**
     * Set grid config for GSLB sites manually selected by user.
     */
    private setGslbSitesGridConfig(): void {
        const { l10nService } = this;

        this.gslbPlacementGridConfig = {
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.noGslbSiteFoundLabel),
            },
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform: (row: ISiteDataRow) => row.siteName,
                },
                {
                    label: l10nService.getMessage(l10nKeys.dnsVsLabel),
                    id: 'dns-vses',
                    templateRef: this.dnsVsFieldTemplateRef,
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (row: ISiteDataRow) => {
                        this.onDeleteSites.emit([row]);
                    },
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (row: ISiteDataRow) => {
                        this.onEditSite.emit(row);
                    },
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (rows: ISiteDataRow[]) => {
                        this.onDeleteSites.emit(rows);
                    },
                },
            ],
        };
    }
}
