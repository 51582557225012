<!-- SAML -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="samlLog$ | async"
    header="{{ l10nKeys.samlLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-saml></vs-log-cinematic-sub-section-saml>
</vs-log-cinematic-sub-section-wrapper>

<!-- JWT -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="jwtLog$ | async"
    header="{{ l10nKeys.jwtLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-jwt></vs-log-cinematic-sub-section-jwt>
</vs-log-cinematic-sub-section-wrapper>

<!-- OAuth -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="oAuthLog$ | async"
    header="{{ l10nKeys.oAuthLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-o-auth></vs-log-cinematic-sub-section-o-auth>
</vs-log-cinematic-sub-section-wrapper>

<!-- NTLM -->
<vs-log-cinematic-sub-section-wrapper
    *ngIf="ntlmLog$ | async"
    header="{{ l10nKeys.ntlmLabel | vtranslate }}"
>
    <vs-log-cinematic-sub-section-ntlm></vs-log-cinematic-sub-section-ntlm>
</vs-log-cinematic-sub-section-wrapper>
