/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'popup-helper';
const prefix = `${moduleName}.${componentName}`;

export const poolNameHeader = `${prefix}.poolNameHeader`;
export const loadBalancingLabel = `${prefix}.loadBalancingLabel`;
export const uptimeLabel = `${prefix}.uptimeLabel`;
export const downtimeLabel = `${prefix}.downtimeLabel`;
export const persistenceLabel = `${prefix}.persistenceLabel`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const healthMonitorsLabel = `${prefix}.healthMonitorsLabel`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const autoScalePolicyLabel = `${prefix}.autoScalePolicyLabel`;
export const autoScaleLaunchConfigLabel = `${prefix}.autoScaleLaunchConfigLabel`;
export const serversLabel = `${prefix}.serversLabel`;
export const activeServersLabel = `${prefix}.activeServersLabel`;
export const disabledServersLabel = `${prefix}.disabledServersLabel`;
export const downServersLabel = `${prefix}.downServersLabel`;

export const ENGLISH = {
    [poolNameHeader]: 'Pool: <span class="sel-subnav-name navbar-unit-name">{0}</span>',
    [loadBalancingLabel]: 'Load Balancing',
    [uptimeLabel]: 'Uptime',
    [downtimeLabel]: 'Downtime',
    [persistenceLabel]: 'Persistence',
    [cloudLabel]: 'Cloud',
    [healthMonitorsLabel]: 'Health Monitors',
    [emptyDataLabel]: 'None',
    [autoScalePolicyLabel]: 'AutoScale Policy',
    [autoScaleLaunchConfigLabel]: 'AutoScale Launch Config',
    [serversLabel]: 'Servers',
    [activeServersLabel]: 'Active: <strong>{0}</strong>',
    [disabledServersLabel]: 'Disabled: <strong>{0}</strong>',
    [downServersLabel]: 'Down: <strong>{0}</strong>',
};
