/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import { Injector } from '@angular/core';
import { VsVipCollection } from 'ajs/modules/vs/factories/vs-vip.collection.factory';
import { VS_VIP_COLLECTION_TOKEN } from 'ajs/modules/vs/vs.tokens';

type TVsVipCollection = typeof VsVipCollection;

const $injector = '$injector';

export const VsVipCollectionProvider = {
    deps: [$injector],
    provide: VsVipCollection,
    useFactory(injector: Injector): TVsVipCollection {
        return injector.get(VS_VIP_COLLECTION_TOKEN);
    },
};
