/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IExtendedMetricsHealthScoreObj } from 'items/pool.item.factory';

import {
    ServiceEngine,
    VirtualService,
} from 'object-types';

import * as globalL10n from 'global-l10n';
import * as l10n from './avi-health-score-bars.l10n';
import './avi-health-score-bars.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Wrapper to show details for all the health scores in avi-health-score component.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'avi-health-score-bars',
    templateUrl: './avi-health-score-bars.component.html',
})
export class AviHealthScoreBarsComponent {
    @Input()
    public healthScore: IExtendedMetricsHealthScoreObj;

    @Input()
    public objectName: string;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly serviceEngine = ServiceEngine.toLowerCase();

    public readonly virtualService = VirtualService.toLowerCase();

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
