<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.editModalTitle : l10nKeys.newModalTitle) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="hsm-group-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalSectionTitle | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalSectionTitle | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="name"
                        for="name"
                        objectType="{{ objectType }}"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="HardwareSecurityModule"
                            fieldName="type"
                            for="hsm-type"
                            required
                        >
                            {{ l10nKeys.typeInputLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            id="hsm-type"
                            name="hsm-type"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.typeInputPlaceholder | vtranslate }}"
                            [options]="hsmTypeOptions"
                            [(ngModel)]="hsmConfigItem.config.type"
                            (ngModelChange)="handleTypeChange()"
                            [disabled]="editable.id || editable.isBusy()"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.safenetLunaSectionTitle | vtranslate }}"
            *ngIf="isHsmType(HSMType.HSM_TYPE_SAFENET_LUNA)"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.safenetLunaSectionTitle | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.safenetLunaServersLabel | vtranslate : hsmConfigItem.safenetLuna.serverList.count }}
                        </h6>
                        <hsm-group-safenet-luna-server-grid
                            [safenetLunaServerList]="hsmConfigItem.safenetLuna.serverList"
                            (onAddSafenetLunaServer)="onAddSafenetLunaServer()"
                            (onRemoveSafenetLunaServer)="onRemoveSafenetLunaServer($event)"
                            (onEditSafenetLunaServer)="onEditSafenetLunaServer($event)"
                        ></hsm-group-safenet-luna-server-grid>
                    </div>
                </div>
                <avi-checkbox
                    *ngIf="editable.id && hsmConfigItem.safenetLuna.serverList.count > 1"
                    objectType="HSMSafenetLuna"
                    fieldName="is_ha"
                    [(ngModel)]="hsmConfigItem.safenetLuna.config.is_ha"
                    name="is-ha"
                >
                    {{ l10nKeys.enableHaCheckboxLabel | vtranslate }}
                </avi-checkbox>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-fieldset>
                            <avi-fieldset_header>
                                <avi-radio-container [noMarginTop]="true">
                                    <label
                                        aviLabelWithTooltip
                                        objectType="HSMSafenetLuna"
                                        fieldName="use_dedicated_network"
                                        for="use-dedicated-network"
                                    >
                                        {{ l10nKeys.hsmCommunicationWithInputLabel | vtranslate }}
                                    </label>
                                    <avi-radio
                                        name="hsm-communication-with"
                                        [value]="true"
                                        [(ngModel)]="hsmConfigItem.safenetLuna.config.use_dedicated_network"
                                        (ngModelChange)="onUseDedicatedNetworkChange()"
                                        [disabled]="editable.isBusy()"
                                    >
                                        {{ l10nKeys.dedicatedNetworkRadioLabel | vtranslate }}
                                    </avi-radio>
                                    <avi-radio
                                        name="hsm-communication-with"
                                        [value]="false"
                                        [(ngModel)]="hsmConfigItem.safenetLuna.config.use_dedicated_network"
                                        (ngModelChange)="onUseDedicatedNetworkChange()"
                                        [disabled]="editable.isBusy()"
                                    >
                                        {{ l10nKeys.managementNetworkRadioLabel | vtranslate }}
                                    </avi-radio>
                                </avi-radio-container>
                            </avi-fieldset_header>
                            <avi-fieldset_content>
                                <div class="hsm-group-modal__client-ip-section">
                                    <label
                                        aviLabelWithTooltip
                                        objectType="HSMSafenetClientInfo"
                                        fieldName="client_ip"
                                        for="client-ip"
                                        required
                                    >
                                        <avi-data-grid_header>
                                            <h6>
                                                {{ l10nKeys.clientIpInputLabel | vtranslate : hsmConfigItem.safenetLuna.clientInfoList.count }}
                                            </h6>
                                        </avi-data-grid_header>
                                    </label>
                                    <hsm-group-safenet-client-info-grid
                                        [clientInfoList]="hsmConfigItem.safenetLuna.clientInfoList"
                                        (onAddClientInfo)="onAddClientInfo()"
                                        (onRemoveClientInfo)="onRemoveClientInfo($event)"
                                    ></hsm-group-safenet-client-info-grid>
                                </div>
                            </avi-fieldset_content>
                        </avi-fieldset>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.awsCloudHsmSectionTitle | vtranslate }}"
            *ngIf="isHsmType(HSMType.HSM_TYPE_AWS_CLOUDHSM)"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.awsCloudHsmSectionTitle | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="crypto_user_name"
                        objectType="HSMAwsCloudHsm"
                        for="crypto-user-name"
                        required
                    >
                        {{ l10nKeys.usernameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="crypto-user-name"
                        name="crypto-user-name"
                        clrInput
                        placeholder="{{ l10nKeys.usernamePlaceholder | vtranslate }}"
                        [(ngModel)]="hsmConfigItem.cloudHsm.config.crypto_user_name"
                        required
                    />
                </avi-input-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        fieldName="crypto_user_password"
                        objectType="HSMAwsCloudHsm"
                        for="crypto-user-password"
                        required
                    >
                        {{ l10nKeys.passwordInputLabel | vtranslate }}
                    </label>
                    <input
                        id="crypto-user-password"
                        name="crypto-user-password"
                        type="password"
                        clrInput
                        placeholder="{{ l10nKeys.passwordPlaceholder | vtranslate }}"
                        [(ngModel)]="hsmConfigItem.cloudHsm.config.crypto_user_password"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="cluster_cert"
                            objectType="HSMAwsCloudHsm"
                            for="cluster-cert"
                            required
                        >
                            {{ l10nKeys.hsmClusterCertificateInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            name="cluster-cert"
                            placeholder="{{ l10nKeys.hsmClusterCertificatePlaceholder | vtranslate }}"
                            [(ngModel)]="hsmConfigItem.cloudHsm.config.cluster_cert"
                            required
                        ></file-upload-textarea>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            fieldName="hsm_ip"
                            objectType="HSMAwsCloudHsm"
                            for="hsm-ip"
                            required
                        >
                            {{ l10nKeys.hsmIpAddressesInputLabel | vtranslate }}
                        </label>
                        <hsm-group-cloudhsm-hsm-ip-grid
                            [hsmIpStrings]="hsmConfigItem.cloudHsm.hsmIpStrings"
                            (onAddHsmIp)="onAddHsmIp()"
                            (onRemoveHsmIps)="onRemoveHsmIps($event)"
                        ></hsm-group-cloudhsm-hsm-ip-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
