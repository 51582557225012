/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { copy } from 'angular';

import { IBaseRequestPromise } from 'ajs/modules/data-model/factories/base.factory';

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { Network } from 'object-types';

import {
    NetworkModalComponent,
} from 'ng/modules/network/components/network-modal/network-modal.component';

import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';

import {
    INetwork,
    INetworkRuntime,
    IStaticIpRangeRuntime,
    ISubnet,
    ISubnetRuntime,
    IVIMgrIPSubnetRuntime,
    IVIMgrNWRuntime,
    StaticIpType,
} from 'generated-types';

import {
    anyIPRange as ipRangeRegex,
    anySubnet as subnetRegex,
} from 'ng/shared/utils/regex.utils';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

export interface IStaticIpAddrTemp {
    range: string;
    type?: StaticIpType;
}

// We set these UI properties to the ISubnet from backend.
// These properties are needed for modal edit/create.
export interface IConfiguredNetworkSubnet extends ISubnet {
    asString?: string;
    static_ipaddr_tmp?: IStaticIpAddrTemp[];
    subnet?: string;
    useStaticIpForSeAndVip?: boolean;
}

export interface IConfiguredNetworkWithRuntime extends IConfiguredNetworkSubnet, ISubnetRuntime {}

export interface INetworkConfig extends INetwork {
    overrideURL?: string;
    configured_subnets?: IConfiguredNetworkWithRuntime[];
}

export interface ICustomVIMgrIPSubnetRuntime extends IVIMgrIPSubnetRuntime {
    subnet?: string;
}

interface INetworkDiscovery extends IVIMgrNWRuntime {
    ip_subnet?: ICustomVIMgrIPSubnetRuntime[];
}

export interface INetworkData {
    config: INetworkConfig;
    discovery?: INetworkDiscovery;
    runtime?: INetworkRuntime;
}

/**
 * @description
 *
 *   ConfiguredNetwork item.
 *
 * @author Aravindh Nagarajan, Rachit Aggarwal
 */
export class ConfiguredNetwork extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'RangeParser',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'network',
            objectType: Network,
            windowElement: NetworkModalComponent,
            whitelistedFields: [''],
            restrictEditOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns a readable subnet string with total and free ip's count.
     * @param subnet -  network's config and runtime data
     * @returns - Formatted subnet string
     */
    public static formatSubnetRuntimeData(
        subnet: IConfiguredNetworkWithRuntime | IConfiguredNetworkSubnet,
    ): string {
        let { asString: subnetString } = subnet;

        if (!subnetString) {
            subnetString = subnet.subnet;
        }

        if ('ip_range_runtimes' in subnet) {
            const { ip_range_runtimes: ipRangeRuntimes = [] } = subnet;

            const totalAndFreeIpCount = [0, 0];

            const [totalStaticIpCount, freeStaticIpCount] = ipRangeRuntimes.reduce(
                ConfiguredNetwork.ipRangeRuntimeReducer,
                totalAndFreeIpCount,
            );

            if (totalStaticIpCount) {
                subnetString += ` [${freeStaticIpCount}/${totalStaticIpCount}]`;
            }
        }

        return subnetString;
    }

    /**
     * Reducer function to get free and total ip count from ipRangeRuntime.
     */
    public static ipRangeRuntimeReducer(
        ipCountList: [number, number],
        ipRangeRuntime: IStaticIpRangeRuntime,
    ): [number, number] {
        const {
            total_ip_count: totalIps = 0,
            free_ip_count: freeIps = 0,
        } = ipRangeRuntime;

        ipCountList[0] += totalIps;
        ipCountList[1] += freeIps;

        return ipCountList;
    }

    /**
     * Getter for configured_subnets list.
     */
    public get configuredSubnets(): IConfiguredNetworkSubnet[] {
        return this.getConfig().configured_subnets ?? [];
    }

    /**
     * Return discovered subnets list.
     */
    public get discoveredSubnets(): ICustomVIMgrIPSubnetRuntime[] {
        return this.data.discovery?.ip_subnet ?? [];
    }

    /** @override */
    public beforeEdit(): void {
        const config = this.getConfig();

        if ('configured_subnets' in config) {
            const { configured_subnets: configuredSubnets } = config;

            // Merge ip and mask into one temporary field
            configuredSubnets.forEach((subnet: IConfiguredNetworkSubnet) => {
                subnet.subnet = getSubnetString(subnet.prefix);

                const { static_ip_ranges: staticIpRanges = [] } = subnet;

                subnet.static_ipaddr_tmp = staticIpRanges.map(({ range, type }) => {
                    const {
                        begin: { addr: rangeStart },
                        end: { addr: rangeEnd },
                    } = range;

                    return {
                        range: `${rangeStart}-${rangeEnd}`,
                        type,
                    };
                });

                // UI-only property for `Use Static IP Address for VIPs and SE` checkbox.
                // If subnet has a staticIpPool with the type of `STATIC_IPS_FOR_VIP_AND_SE`
                // it cannot have any other type of static ip pools.
                subnet.useStaticIpForSeAndVip = staticIpRanges.some(
                    ({ type }) => type === StaticIpType.STATIC_IPS_FOR_VIP_AND_SE,
                );
            });
        } else {
            config.configured_subnets = [];
        }

        // TODO figure what is the value of using runtime data for configuration modal
        const { discovery } = this.data;

        if (discovery && 'ip_subnet' in discovery) {
            const { ip_subnet: ipSubnet } = discovery;

            ipSubnet.forEach((subnet: ICustomVIMgrIPSubnetRuntime) => {
                const { prefix } = subnet;

                subnet.subnet = getSubnetString(prefix);
            });
        }
    }

    /** @override */
    public dataToSave(): INetwork {
        const config = copy(this.getConfig());
        const rangeParser = this.getAjsDependency_('RangeParser');

        // Remove empty subnets and inventory type
        config.configured_subnets =
            config.configured_subnets.filter(
                ({ subnet }: {subnet: IConfiguredNetworkSubnet}) => Boolean(subnet),
            );

        // Transforming temporary fields
        config.configured_subnets.forEach((subnet: IConfiguredNetworkSubnet) => {
            const { subnet: subnetString } = subnet;

            if (subnetRegex.test(subnetString)) {
                subnet.prefix = rangeParser.ipRange2Json(subnetString);
            } else {
                delete subnet.prefix;
            }

            delete subnet.subnet;

            subnet.static_ip_ranges = [];

            if ('static_ipaddr_tmp' in subnet) {
                subnet.static_ipaddr_tmp.forEach(({ range: ipRangeList, type }) => {
                    ipRangeList.split(/\s*,\s*/).forEach(ipRange => {
                        const ipRangeObj = rangeParser.ipRange2Json(ipRange);

                        if (ipRangeObj) {
                            if (ipRangeRegex.test(ipRange)) {
                                if (!type || subnet.useStaticIpForSeAndVip) {
                                    type = StaticIpType.STATIC_IPS_FOR_VIP_AND_SE;
                                }

                                const staticIpRange = {
                                    range: ipRangeObj,
                                    type,
                                };

                                subnet.static_ip_ranges.push(staticIpRange);
                            }
                        }
                    });
                });
            }

            delete subnet.static_ipaddr_tmp;
            delete subnet.useStaticIpForSeAndVip;

            if (!subnet.static_ip_ranges.length) {
                delete subnet.static_ip_ranges;
            }
        });

        return config as INetwork;
    }

    /**
     * To update configSubnets column in a list view of Networks after saving updated one.
     * Corresponds with NetworkCollection transformAfterLoad.
     * @override
     */
    public transformDataAfterSave({ data }: ng.IHttpResponse<INetwork>): INetworkConfig {
        if (data && 'configured_subnets' in data) {
            const { configured_subnets: configuredSubnets } = data;

            configuredSubnets.forEach((subnet: IConfiguredNetworkSubnet) => {
                subnet.asString = getSubnetString(subnet.prefix);
            });
        }

        return data as unknown as INetworkConfig;
    }

    /**
     * Returns a list of configured subnets for this Network.
     */
    public getSubnets(): IConfiguredNetworkSubnet[] {
        const { configured_subnets: configuredSubnets = [] } = this.getConfig();

        return [].concat(configuredSubnets);
    }

    /** @override */
    protected saveRequest(): IBaseRequestPromise<INetwork> {
        const config = this.getConfig();
        let method = this.id ? 'put' : 'post';
        const headers: ng.IHttpRequestConfigHeaders = {};
        let url = this.urlToSave();

        if (config.overrideURL) {
            url = config.overrideURL;
            method = 'post';
            delete config.url;
            delete config.overrideURL;
            headers.slug = config.uuid;
        }

        return this.request<INetwork>(method, url, this.dataToSave(), headers);
    }
}
