<div
    class="user-menu-additional-menu"
    avi-tooltip
    [showOnClick]="true"
    [tooltipControl$]="additionalMenuControl$"
    [positionsPriority]="menuPositions"
>
    <div class="sel-user-menu">
        <cds-icon shape="ellipsis-vertical"></cds-icon>
    </div>
    <ng-template #aviTooltipContent>
        <ul class="user-menu-additional-menu__list">
            <li
                *ngIf="aviPortalUrl"
                class="user-menu-additional-menu__list-item"
            >
                <a
                    href="{{ aviPortalUrl }}"
                    target="_blank"
                    class="sel-customer-portal user-menu-additional-menu__item"
                >
                    {{ l10nKeys.customerPortalLabel | vtranslate }}
                </a>
            </li>
            <li class="user-menu-additional-menu__list-item">
                <a
                    href="/swagger/"
                    target="_blank"
                    class="sel-avi-api-documentation user-menu-additional-menu__item"
                >
                    {{ l10nKeys.aviApiDocumentationLabel | vtranslate }}
                </a>
            </li>
            <li class="user-menu-additional-menu__list-item">
                <div
                    class="sel-my-account user-menu-additional-menu__item"
                    (click)="openGenerateTokenDialog()"
                >
                    {{ l10nKeys.generateTokenLabel | vtranslate }}
                </div>
            </li>
        </ul>
    </ng-template>
</div>
