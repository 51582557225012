/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
// @ts-expect-error
import * as d3 from 'd3';
import {
    Observable,
    Subject,
    Subscription,
} from 'rxjs';
import { debounce, throttle } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import {
    IAviBarGraphColors,
    IAviBarGraphDataPoint,
    IAviBarGraphDataPointValue,
    IAviBarGraphDataPointValueInput,
    IAviBarGraphOverlayTimeframe,
    TTimestampFormatFromApi,
    TTimestampFormatFromApiWithMicroS,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';
import { attachComponentBindings } from 'ng/shared/utils';
import {
    ILabelData,
    ILabelRowData,
    LabelCountLegendComponent,
} from 'ng/modules/diagram/components';
import {
    convertTimeToTTimestampFormatFromApi,
    getUnixTimeInMilliseconds,
} from './avi-bar-graph.utils';
import * as l10n from './avi-bar-graph.l10n';
import './avi-bar-graph.component.less';

/** Height of container(s) holding bar-graph. */
const barGraphContainerHeightPx = 90;

/** Elements inside parent element must be pushed down to avoid being cut off. */
const pixelsFromTopOfParent = 10;

/** Elements inside parent element must be pushed up to avoid being cut off. */
const pixelsFromBottomOfParent = 20;

const maxHeightOfBars = 55;

/** Height of graph container */
const actualGraphHeightPx = maxHeightOfBars + pixelsFromTopOfParent + pixelsFromBottomOfParent;

/** Elements inside parent element must be pushed left to avoid being cut off. */
const defaultPixelsFromLeftOfParent = 20;

/** Must adjust px from left of parent according to data input. */
let pixelsFromLeftOfParent = defaultPixelsFromLeftOfParent;

/** Amount to push pixels from left of parent, based on number of digits to display on y-axis. */
const pixelsFromLeftOfParentMultiplierForEachDigit = 6;

/** Desired space between individual vertical bars representing data. */
const pixelsBetweenBars = 1;

const overlayBorderWidth = 3;

/** Number of pixels to left/right of border which can be selected for border-drag. */
const overlayBorderPlusMinus = overlayBorderWidth + 3;

const overlayBorderFillColorHex = '#77AECC';

/** Constants representing elements d3 will work with. */
const RECT_ELEMENT = 'rect';
const G_ELEMENT = 'g';
const SVG_ELEMENT = 'svg';

const COMPONENT_NAME = 'avi-bar-graph';

/** Class name constants representing classes d3 will work with.  */
const AVI_BAR_GRAPH_VIEWPORT_CLASS = `${COMPONENT_NAME}__viewport`;
const AVI_BAR_GRAPH_SVG_CONTAINER_CLASS = `${COMPONENT_NAME}__svg-container`;
const AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS = `${COMPONENT_NAME}__svg-pseudo-container`;
const AVI_BAR_GRAPH_SVG_OVERLAY_CONTAINER_CLASS = `${COMPONENT_NAME}__svg-overlay-container`;
const AVI_BAR_GRAPH_RECT_CLASS = `${COMPONENT_NAME}__rect`;
const AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS = `${COMPONENT_NAME}__rect-svg-pseudo`;
const AVI_BAR_GRAPH_RECT_ACTUAL_CLASS = `${COMPONENT_NAME}__rect-actual`;
const AVI_BAR_GRAPH_X_AXIS_CLASS = `${COMPONENT_NAME}__x-axis`;
const AVI_BAR_GRAPH_Y_AXIS_CLASS = `${COMPONENT_NAME}__y-axis`;
const AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS = `${COMPONENT_NAME}__overlay-selection`;
const AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS = `${COMPONENT_NAME}__overlay-boundary-vertical`;
const AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS = `${COMPONENT_NAME}__overlay-drag-start`;
const AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS = `${COMPONENT_NAME}__overlay-drag-end`;
const AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS = `${COMPONENT_NAME}__overlay-boundary-horizontal`;
const AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS = `${COMPONENT_NAME}__overlay-boundary-zoom-icon`;
const AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID = `${COMPONENT_NAME}__overlay-gradient`;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Reusable component: creates bar graph for values spanning time.
 * Expects IAviBarGraphDataPoint[] as input to paint bars.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'avi-bar-graph',
    templateUrl: './avi-bar-graph.component.html',
})
export class AviBarGraphComponent implements OnInit, OnChanges, OnDestroy {
    /** Informs if waiting for data. */
    @Input()
    public isLoading: boolean;

    /** Informs if error present. */
    @Input()
    public hasError: boolean;

    /** Actual data used by d3 to draw graph. */
    @Input()
    public dataset: IAviBarGraphDataPoint[];

    /** Timestamp of duration's end => very right side of graph, where it ends. */
    @Input()
    public graphEndTimestamp: TTimestampFormatFromApi;

    /** Timestamp of duration's start => very left side of graph, where it starts. */
    @Input()
    public graphStartTimestamp: TTimestampFormatFromApi;

    /** Used to dictate formation of legend values. Is observable to make values variable. */
    @Input()
    public colorLegend$: Observable<IAviBarGraphColors>;

    /** Decides if tooltip functionality will be present. */
    @Input()
    public allowHoverTooltip = true;

    /** Text to be added to tooltip label. */
    @Input()
    public tooltipTotalLabelAddendum: string;

    /** Handles effects on graph until api is done fetching. */
    @Input()
    public isGraphApiLoopInProgress: boolean;

    /** Notify subscribers on graph reload, when user interacts with graph. */
    @Input()
    public onUserInteractionWithGraphOptionsSubject: Subject<void>;

    /** Emitter for when user finishes creating/modifying an overlay. */
    @Output()
    public onOverlayCreationOrModificationOrDeletion =
    new EventEmitter<IAviBarGraphOverlayTimeframe>();

    /** Emitter for when user clicks zoom button in overlay. */
    @Output()
    public clickOverlayZoom = new EventEmitter();

    // TODO 158220 maybe delete if can manipulate in AviBarGraphTooltip component
    /** Ref to manipulate tooltip container. */
    @ViewChild('aviBarGraphTooltipContainerElementRef', {
        read: ElementRef,
    })
    public aviBarGraphTooltipContainerElementRef: ElementRef;

    /** Ref to pass in tooltip component. */
    @ViewChild('aviBarGraphTooltipRef', {
        read: ViewContainerRef,
        static: true,
    })
    public aviBarGraphTooltipRef: ViewContainerRef;

    public l10nKeys = l10nKeys;

    public myInjector: Injector;

    // TODO 158220 delete if works properly in AviBarGraphTooltip
    /** Position of tooltip (if exists) from left of graph container. */
    public tooltipLeftPositionPx: number;

    /** Offset of tooltip from parent's (AviBarGraph component's) left. */
    public tooltipOffsetX: number;

    /** Width of tooltip. */
    public tooltipWidth: number;

    /** Holds value of graph container width, before it changes. */
    public currentGraphContainerWidth: number;

    public labelList: ILabelData[];

    /** Label displayed in summary/final line of tooltip. */
    public totalLogsLabel: string;

    /** Value holding d3 svg graph container, including axes. */
    private svg: d3.svg;

    /** Value holding d3 svg graph container, minus axes. */
    private svgGraph: d3.svg;

    /** Value holding d3 svg overlay container */
    private svgOverlayContainer: d3.svg;

    /** Value holding d3 RECT_ELEMENT bars element selection. */
    private bars: d3.svg;

    /** Added to component state to allow for unsubscribing. */
    private graphResizeObserver: ResizeObserver;

    /**
     * Boolean for start-to-end of user clicking, dragging, and releasing.
     */
    private overlayConstructionInProgress = false;

    /**
     * Boolean for start of overlay creation from user click on bar, to creation end.
     */
    private singleBarOverlayConstructionInProgress = false;

    /**
     * When drawing/manipulating graph, serves as the vertical border which has been set,
     * and is not currently moving - whether user drags left or drags right.
     * When moving entire overlay, serves as original point user dragged from.
     */
    private overlayStaticVerticalBorderPixels: DragEvent['x'];

    /**
     * Leftmost point of user click+drag.
     */
    private overlayStartPixels: DragEvent['x'];

    /**
     * Rightmost point of user click+drag.
     */
    private overlayEndPixels: DragEvent['x'];

    /**
     * Start time for overlay.
     */
    private overlayStartTime: TTimestampFormatFromApiWithMicroS;

    /**
     * End time for overlay.
     */
    private overlayEndTime: TTimestampFormatFromApiWithMicroS;

    private onUserInteractionWithGraphOptionsSubscription: Subscription;

    private previousOverlayEndTime: TTimestampFormatFromApiWithMicroS;

    private previousOverlayStartTime: TTimestampFormatFromApiWithMicroS;

    /**
     * Flag to check if overlay is reset upon user interaction with graph options,
     * (i.e. timeframe, log filters and refresh).
     */
    private recreateOverlay = false;

    constructor(
        private readonly injector: Injector,
        // TODO 158220 delete if intermediate comp takes care of positioning
        private renderer: Renderer2,
        private readonly ngZone: NgZone,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.subscribeLegendSelectionToParamChanges();

        const totalLabel = this.l10nService.getMessage(l10nKeys.totalLabel);
        const { tooltipTotalLabelAddendum } = this;

        this.totalLogsLabel =
            tooltipTotalLabelAddendum ? `${totalLabel} ${tooltipTotalLabelAddendum}` : totalLabel;

        this.onUserInteractionWithGraphOptionsSubscription =
            this.onUserInteractionWithGraphOptionsSubject.subscribe(
                this.saveAndResetOverlayTimespan,
            );
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.graphResizeObserver) {
            this.graphResizeObserver.disconnect();
        }

        this.onUserInteractionWithGraphOptionsSubscription.unsubscribe();
    }

    /**
     * Paints d3 graph upon changes on input variables.
     * @override
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataset?.isFirstChange()) {
            return;
        }

        this.refreshGraph();

        if (!this.graphResizeObserver) {
            this.setGraphResizeObserver();
        }

        this.updateTooltipForIncomingData();
    }

    /**
     * Save overlay time span before calling 'resetOverlay' method.
     */
    private readonly saveAndResetOverlayTimespan = (): void => {
        const { overlayEndTime, overlayStartTime } = this;

        this.previousOverlayEndTime = overlayEndTime;
        this.previousOverlayStartTime = overlayStartTime;
        this.resetOverlay();
        this.recreateOverlay = true;
    };

    /**
     * Create overlay from provided end time and start time.
     */
    private createOverlayIfTimeRangeValid(
        overlayEndTime: TTimestampFormatFromApiWithMicroS,
        overlayStartTime: TTimestampFormatFromApiWithMicroS,
    ): boolean {
        const isOverlayRangeWithinGraphRange =
            this.isTimeStampRangeWithinGraphRange(overlayEndTime, overlayStartTime);

        if (!isOverlayRangeWithinGraphRange) {
            return false;
        }

        const overlayStartPixels = this.getXPositionFromTimestamp(overlayStartTime);
        const overlayEndPixels = this.getXPositionFromTimestamp(overlayEndTime);

        const newGraphWidth = this.getGraphContainerWidth() - pixelsFromLeftOfParent;
        const { currentGraphContainerWidth: oldGraphWidth } = this;
        const graphWidthFraction = newGraphWidth / oldGraphWidth;

        const newXLeft = graphWidthFraction * overlayStartPixels + pixelsFromLeftOfParent;
        const newXRight = graphWidthFraction * overlayEndPixels + pixelsFromLeftOfParent;

        this.handleOverlayCreationStart(newXLeft);
        this.handleOverlayCreationInProcess(newXRight);
        this.handleOverlayCreationEnd(newXRight);
        this.updateTooltipLeftPositionPx();

        return true;
    }

    /**
     * Subscribe to boolean params for types of logs changing.
     */
    private subscribeLegendSelectionToParamChanges = (): void => {
        this.colorLegend$.subscribe(colorLegend => {
            this.labelList = Object.keys(colorLegend).map(key => {
                const {
                    color,
                    label,
                } = colorLegend[key];

                return {
                    hasLeftColor: true,
                    color,
                    label,
                };
            });
        });
    };

    /**
     * Sets listener:subscriber to listen to and respond to parent element size change.
     * This is run only once.
     * It must wait till d3 selection of svg is made before running.
     * ngZone needed because anything done in ResizeObserver is not in Angular's zone.
     */
    private setGraphResizeObserver = (): void => {
        this.graphResizeObserver =
            new ResizeObserver(() => {
                this.ngZone.run(this.handleGraphResize);

                if (this.getGraphContainerWidth() > 0 && this.recreateOverlay) {
                    this.ngZone.run(this.handleUserInteractionWithGraphOptions);
                    this.recreateOverlay = false;
                }
            });

        this.graphResizeObserver
            .observe(document.querySelector(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`));
    };

    /**
     * Recreate overlay on user interaction with graph options.
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private readonly handleUserInteractionWithGraphOptions = debounce((): void => {
        const { previousOverlayEndTime, previousOverlayStartTime } = this;

        const isOverlayCreated =
            this.createOverlayIfTimeRangeValid(previousOverlayEndTime, previousOverlayStartTime);

        if (!isOverlayCreated) {
            this.onOverlayCreationOrModificationOrDeletion.emit({
                end: this.graphEndTimestamp,
                duration: this.getGraphDurationInSeconds(),
            });
        }
    }, 200);

    /**
     * Handles horizontal resizing of graph as needed.
     * Modifies size and position of bars, and size and spacing of x-axis.
     * Modifies overlay position/width, if overlay exists.
     * Throttle used to limit excessive firing, low enough number used to avoid jumpy effect.
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private handleGraphResize = throttle(() => {
        const { currentGraphContainerWidth: oldGraphWidth } = this;
        const newGraphWidth = this.getGraphContainerWidth() - pixelsFromLeftOfParent;
        const graphWidthDelta = this.getGraphWidthDelta(newGraphWidth);

        if (graphWidthDelta === 0) {
            return;
        }

        if (this.dataset.length) {
            this.setHorizontalPropsForBars();
        }

        this.svg.select(`.${AVI_BAR_GRAPH_X_AXIS_CLASS}`)
            .call(this.scaleXAxis());

        if (this.overlayExists()) {
            const oldXLeft = this.overlayStartPixels;
            const oldXRight = this.overlayEndPixels;
            const graphWidthsFraction = newGraphWidth / oldGraphWidth;
            const newXLeft = graphWidthsFraction * oldXLeft + pixelsFromLeftOfParent;
            const newXRight = graphWidthsFraction * oldXRight + pixelsFromLeftOfParent;

            this.handleOverlayCreationStart(newXLeft, true);
            this.handleOverlayCreationInProcess(newXRight, true);
            this.handleOverlayCreationEnd(newXRight, true);
            this.updateTooltipLeftPositionPx();
        }

        this.currentGraphContainerWidth = newGraphWidth;
    },
    30,
    { leading: false });

    /**
     * Returns the difference between provided width and currentGraphWidth.
     */
    private getGraphWidthDelta(width: number): number {
        return this.currentGraphContainerWidth - width;
    }

    /**
     * Runs after initial data retrieval, as well as any time data changes.
     * Holds seperated out methods which take care of each disparate part
     * of graph creation: axes, bars, removal, etc.
     *
     * d3 treats creation of axes, scaling, and creation of bars as separate,
     * thus these actions have been separated out in distinct methods.
     * They are ultimately bound together to the same d3 svg element selection.
     */
    private refreshGraph(): void {
        this.aviBarGraphTooltipRef.clear();
        this.removeSvg();
        this.calculatePixelsFromLeftOfParent();
        this.createSvg();
        this.setOverlayContainer();

        if (this.dataset.length) {
            this.createGraph();
        }

        this.createYAxis();
        this.createXAxis();
        this.setOverlayColorGradient();
        this.enableOverlayCreation();
        this.enableSingleBarOverlayCreation();
        this.enableOverlayDeletionUponEmptySpaceClick();

        if (this.allowHoverTooltip) {
            this.enableTooltipCreation();
        }
    }

    /**
     * Calculate and set pixels needed from left of parent element,
     * based on number of digits in largest totalValueSummation out of all data points.
     */
    private calculatePixelsFromLeftOfParent(): void {
        pixelsFromLeftOfParent +=
            pixelsFromLeftOfParentMultiplierForEachDigit * this.dataset.reduce((maxDigits, datum) =>
                Math.max(maxDigits, String(datum.totalValueSummation).length) - 1,
            1);
    }

    /**
     * Deletes 'svg' from page.
     */
    private removeSvg = (): void => {
        if (!this.overlayExists) {
            d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();
        }

        d3.select(`.${AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_X_AXIS_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_Y_AXIS_CLASS}`).remove();
        pixelsFromLeftOfParent = defaultPixelsFromLeftOfParent;
    };

    /**
     * Creates 'svg' element.
     */
    private createSvg = (): void => {
        this.svg = this.svg ?? d3
            .select(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`)
            .append(SVG_ELEMENT)
            .attr('height', barGraphContainerHeightPx)
            .attr('width', '100%')
            .classed(AVI_BAR_GRAPH_SVG_CONTAINER_CLASS, true);

        // Separate svg container needed to represent area holding only bars, exclusive of axes.
        this.svgGraph = this.svg
            .insert(SVG_ELEMENT, ':first-child')
            .classed(AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS, true)
            .attr('height', barGraphContainerHeightPx)
            .attr('x', pixelsFromLeftOfParent)
            .attr('width', '100%');
    };

    /**
     * Create svg overlay container.
     */
    private setOverlayContainer(): void {
        this.svgOverlayContainer = this.svgOverlayContainer ??
            d3.select(`.${AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS}`)
                .clone()
                .classed(AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS, false)
                .classed(AVI_BAR_GRAPH_SVG_OVERLAY_CONTAINER_CLASS, true);
    }

    /**
     * Allows drag and select of custom time range.
     */
    private enableOverlayCreation = (): void => {
        const dragOverlayInstruction = d3.drag()
            .on('start', (dragEvent: DragEvent) => this.handleOverlayCreationStart(dragEvent.x))
            // todo debounce/throttle
            .on('drag', (dragEvent: DragEvent) => this.handleOverlayCreationInProcess(dragEvent.x))
            .on('end', (dragEvent: DragEvent) => this.handleOverlayCreationEnd(dragEvent.x));

        d3.select(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`).call(dragOverlayInstruction);
    };

    /**
     * Triggers upon user clicking anywhere in graph area.
     */
    private handleOverlayCreationStart = (x: DragEvent['x'], graphResized = false): void => {
        const shiftedX = x - pixelsFromLeftOfParent;

        this.overlayStaticVerticalBorderPixels = shiftedX;

        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            return;
        }

        if (!graphResized && this.hasLeftBoundaryBeenClicked()) {
            this.overlayStaticVerticalBorderPixels = this.overlayEndPixels;
        } else if (!graphResized && this.hasRightBoundaryBeenClicked()) {
            this.overlayStaticVerticalBorderPixels = this.overlayStartPixels;
        }

        this.overlayConstructionInProgress = true;
    };

    /**
     * Triggers upon user dragging after click in graph area.
     */
    private handleOverlayCreationInProcess = (x: MouseEvent['x'], graphResized = false): void => {
        const shiftedX = x - pixelsFromLeftOfParent;

        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            this.handleOverlayDrag(shiftedX);

            return;
        }

        if (this.isOverlayBoundaryOutOfBounds(shiftedX)) {
            return;
        }

        this.resetOverlay();

        /** Draw initial vertical overlay boundary. */
        if (d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS}`).empty()) {
            this.drawVerticalOverlayBoundary(
                this.overlayStaticVerticalBorderPixels,
                AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS,
            );
        }

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).remove();
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`).remove();

        this.drawVerticalOverlayBoundary(shiftedX, AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
        this.drawOverlay(shiftedX);
        this.drawHorizontalOverlayBoundaries(shiftedX);

        if (!graphResized) {
            this.createInProcessOverlayTooltip(shiftedX);
        }
    };

    /**
     * Triggers upon user letting go of mouse-click upon drag in graph area.
     */
    private handleOverlayCreationEnd = (x: DragEvent['x'], graphResized = false): void => {
        let shiftedX = x - pixelsFromLeftOfParent;

        /**
         * Terminate if user clicked, but didn't move. Because:
         * D3's 'drag' functionality has 3 parts: 'start', 'drag', and 'end'
         * 'start' and 'end' still fire if user clicked, but let go without dragging.
         */
        if (shiftedX === this.overlayStaticVerticalBorderPixels) {
            this.overlayStaticVerticalBorderPixels = undefined;
            this.overlayConstructionInProgress = false;
            d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();

            return;
        }

        const containerWidth = this.getGraphContainerWidth();

        /**
         * Though overlay cannot go beyond left/right of graph, the cursor can end beyond them.
         * This resets the end point(s), disallowing either one of overlay boundary left/right
         * from being set to beyond limits of graph.
         */
        if (x > containerWidth - 2) {
            shiftedX = containerWidth - pixelsFromLeftOfParent - 3;
        } else if (shiftedX < 0) {
            shiftedX = 0;
        }

        /**
         * Functionality for when overlay is being dragged.
         * Conditionals in place if user drags overlay to beyond edges of graph: prevents
         * storage of overlay numbers being stored beyond edges.
         */
        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            const xDifferencePixels = shiftedX - this.overlayStaticVerticalBorderPixels;

            let overlayEndPixelsPlusDiff = this.overlayEndPixels + xDifferencePixels;
            const didCursorPositionEndBeyondRightGraphBoundary =
                overlayEndPixelsPlusDiff > containerWidth - pixelsFromLeftOfParent - 2;

            let overlayStartPixelsPlusDiff = this.overlayStartPixels + xDifferencePixels;
            const didCursorPositionEndBeyondLeftGraphBoundary = overlayStartPixelsPlusDiff < 0;

            if (didCursorPositionEndBeyondLeftGraphBoundary) {
                overlayEndPixelsPlusDiff = this.getOverlayWidthPx();
            }

            const rightEdgeOfGraph = containerWidth - pixelsFromLeftOfParent - 3;
            const leftEdgeOfGraph = 0;

            if (didCursorPositionEndBeyondRightGraphBoundary) {
                overlayStartPixelsPlusDiff = rightEdgeOfGraph - this.getOverlayWidthPx();
            }

            this.setOverlayBoundaryNumbers(
                didCursorPositionEndBeyondLeftGraphBoundary ?
                    leftEdgeOfGraph : overlayStartPixelsPlusDiff,
                didCursorPositionEndBeyondRightGraphBoundary ?
                    rightEdgeOfGraph : overlayEndPixelsPlusDiff,
            );

            this.handlePostOverlayCreation(graphResized);

            return;
        }

        const draggedLeftToRight = this.isDragFromLeftToRight(shiftedX);

        const { overlayStaticVerticalBorderPixels } = this;

        this.setOverlayBoundaryNumbers(
            draggedLeftToRight ? overlayStaticVerticalBorderPixels : shiftedX,
            draggedLeftToRight ? shiftedX : overlayStaticVerticalBorderPixels,
        );

        this.handlePostOverlayCreation(graphResized);
    };

    /**
     * Handles remaining things once overlay is created.
     */
    private handlePostOverlayCreation = (graphResized = false): void => {
        this.overlayStaticVerticalBorderPixels = undefined;
        this.overlayConstructionInProgress = false;

        this.createZoomIconForOverlay();

        if (!graphResized) {
            this.emitOverlaySettings();
        }

        this.changeOverlayHoverCursor();
        this.handleOverlayClick();
        this.handleOverlayHover();
    };

    /**
     * Handles user click of overlay.
     */
    private handleOverlayClick = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
            .on('click', (): void => {
                this.clickOverlayZoom.emit();
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
            .on('click', (): void => {
                this.clickOverlayZoom.emit();
                d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
                    .remove();
            });
    };

    /**
     * Handle mouseover event on completed overlay.
     */
    private handleOverlayHover = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
            .on('mousemove', (d: MouseEvent) => {
                this.createCompletedOverlayTooltip(d.offsetX);
            })
            .on('mouseout', () => {
                this.aviBarGraphTooltipRef.clear();
            });
    };

    /**
     * Create tooltip over completed overlay.
     */
    private createCompletedOverlayTooltip = (x: MouseEvent['x']): void => {
        const { overlayStartPixels: start, overlayEndPixels: end } = this;

        this.createTooltip(
            this.getAviBarGraphDataPointFromTimeframe(start, end),
            x,
        );
    };

    /**
     * Emits to parent when overlay is created/altered.
     */
    private emitOverlaySettings = (): void => {
        const duration = this.getOverlayDuration() / 1000;

        this.onOverlayCreationOrModificationOrDeletion.emit({
            end: this.overlayEndTime,
            duration,
        });
    };

    /**
     * Creates zoom-in icon upon overlay creation/modification.
     */
    private createZoomIconForOverlay = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();

        if (this.getOverlayDuration() / 1000 < 120) {
            return;
        }

        const zoomIcon = document.createElement('cds-icon');

        zoomIcon.shape = 'zoom-in';

        const pxFromBottom = maxHeightOfBars + 11;
        const pxFromLeft = this.getOverlayMiddlePixels();

        d3.select(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`)
            .insert(() => zoomIcon)
            .classed(AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS, true)
            .style('position', 'relative')
            .style('bottom', `${pxFromBottom}px`)
            .style('left', `${pxFromLeft}px`);
    };

    /**
     * Get horizontal pixels from left of graph of overlay middle.
     */
    private getOverlayMiddlePixels = (): number =>
        pixelsFromLeftOfParent - 6 + this.overlayStartPixels +
            (this.overlayEndPixels - this.overlayStartPixels) / 2;

    /**
     * Sets pixels from left of graph and time for overlay start and end.
     */
    private setOverlayBoundaryNumbers = (startPixels: number, endPixels: number): void => {
        this.overlayStartPixels = startPixels;
        this.overlayEndPixels = endPixels;
        this.overlayStartTime = this.getTimeFromPixels(this.overlayStartPixels);
        this.overlayEndTime = this.getTimeFromPixels(this.overlayEndPixels);
    };

    /**
     * Informs when left/right boundary is beyond x-axis of graph.
     */
    private isOverlayBoundaryOutOfBounds = (boundary: DragEvent['x']): boolean => {
        const graphRightExtreme = this.getGraphContainerWidth() -
            pixelsFromLeftOfParent - overlayBorderWidth;

        return boundary < 0 || boundary > graphRightExtreme;
    };

    /**
     * Sets cursor to desired upon mouse hover.
     */
    private changeOverlayHoverCursor = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'ew-resize');
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'move');
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'pointer');
            });
    };

    /**
     * Creates overlay upon click-selection of single data-bar.
     */
    private enableSingleBarOverlayCreation = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS}`)
            .on('click', (ev: d3.onClick) => {
                this.singleBarOverlayConstructionInProgress = true;
                this.resetOverlay();

                const { __data__: data } = ev.currentTarget;
                const x1 = this.getXPositionFromTimestamp(data.start);
                const x2 = this.getXPositionFromTimestamp(data.end);

                this.overlayStaticVerticalBorderPixels = x1;
                this.drawVerticalOverlayBoundary(x1, AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS);
                this.drawVerticalOverlayBoundary(x2, AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
                this.drawOverlay(x2);
                this.drawHorizontalOverlayBoundaries(x2);

                this.setOverlayBoundaryNumbers(x1, x2);
                this.handlePostOverlayCreation();
            });
    };

    /**
     * Deletes overlay if user clicks space outside overlay and between bars.
     */
    private enableOverlayDeletionUponEmptySpaceClick = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`)
            .on('click', () => {
                if (this.singleBarOverlayConstructionInProgress) {
                    this.singleBarOverlayConstructionInProgress = false;

                    return;
                }

                if (this.overlayExists()) {
                    d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
                        .remove();

                    d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`)
                        .remove();

                    d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS}`)
                        .remove();

                    this.onOverlayCreationOrModificationOrDeletion.emit({
                        end: this.graphEndTimestamp,
                        duration: this.getGraphDurationInSeconds(),
                    });
                }
            });
    };

    /**
     * Aggregates data point information in given timeframe.
     */
    private getAviBarGraphDataPointFromTimeframe =
    (startPx: number, endPx: number): IAviBarGraphDataPoint => {
        const start = this.getTimeFromPixels(startPx);
        const end = this.getTimeFromPixels(endPx);
        const initialValue: IAviBarGraphDataPoint = {
            end,
            start,
            totalValueSummation: 0,
            values: [],
        };

        return this.dataset.reduce((previousDataPoint, currentDataPoint, i, set) => {
            const {
                start: currentDataPtStart,
                end: currentDataPtEnd,
            } = currentDataPoint;

            const overlayEndMs = getUnixTimeInMilliseconds(end);
            const overlayStartMs = getUnixTimeInMilliseconds(start);
            const currentDataPtEndMs = getUnixTimeInMilliseconds(currentDataPtEnd);
            const currentDataPtStartMs = getUnixTimeInMilliseconds(currentDataPtStart);
            const fallsOutsideOverlay =
                overlayEndMs <= currentDataPtEndMs || currentDataPtStartMs <= overlayStartMs;

            if (fallsOutsideOverlay) {
                return previousDataPoint;
            }

            const {
                values: previousValues,
                totalValueSummation: previousTotal,
            } = previousDataPoint;
            const {
                values: currentValues,
                totalValueSummation: currentTotal,
            } = currentDataPoint;
            const previousValueTypes = previousValues.map(({ type }) => type);
            // TODO Delete when library "@types/node" is upgraded to v17
            // @ts-expect-error
            const values: IAviBarGraphDataPointValueInput[] = structuredClone(previousValues);

            currentValues.forEach(currentVal => {
                const {
                    type: currentType,
                    count,
                } = currentVal;
                const currentTypeIndex = previousValueTypes.indexOf(currentType);

                if (currentTypeIndex === -1) {
                    values.push(currentVal);
                } else {
                    values[currentTypeIndex].count += count;
                }
            });

            return {
                end,
                start,
                totalValueSummation: previousTotal + currentTotal,
                values,
            };
        }, initialValue);
    };

    /**
     * Creates tooltip over overlay while overlay is being created.
     */
    private createInProcessOverlayTooltip = (x: number): void => {
        const { overlayStaticVerticalBorderPixels } = this;
        const draggedLeftToRight = this.isDragFromLeftToRight(x);
        const leftPx = draggedLeftToRight ? overlayStaticVerticalBorderPixels : x;
        const rightPx = draggedLeftToRight ? x : overlayStaticVerticalBorderPixels;

        this.createTooltip(
            this.getAviBarGraphDataPointFromTimeframe(leftPx, rightPx),
            x,
        );
    };

    /**
     * Handle tooltip creation.
     */
    private enableTooltipCreation = (): void => {
        this.enableTooltipCreationUponHoverBars();
        this.enableTooltipDefaultCreationForOverlay();
    };

    /**
     * Handle tooltip creation upon user hover over data bars.
     */
    private enableTooltipCreationUponHoverBars = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const barGraphInstanceContext = this;

        d3.selectAll(`.${AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS}`)
            .on('mouseover', function(d: MouseEvent) {
                barGraphInstanceContext.createTooltip(d3.select(this).data()[0], d.offsetX);

                d3.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
                    .style('opacity', 0.5);

                // eslint-disable-next-line
                const hovered = event.target as Element;
                const parent = hovered.parentNode as Element;
                const siblings =
                    Array.from(parent.querySelectorAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`));

                siblings.forEach(sibling => {
                    d3.select(sibling)
                        .style('opacity', 1);
                });
            })
            .on('mouseout', function() {
                barGraphInstanceContext.aviBarGraphTooltipRef.clear();
                d3.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
                    .style('opacity', 1);
            });
    };

    /**
     * Handle tooltip creation upon mouse exit of main graph svg container.
     */
    private enableTooltipDefaultCreationForOverlay = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`)
            .on('mouseenter', () => this.aviBarGraphTooltipRef.clear())
            .on('mouseleave', () => {
                if (!this.overlayExists()) {
                    return;
                }

                this.createCompletedOverlayTooltip(this.getOverlayMiddlePixels());
            });
    };

    /**
     * Create hover tooltip to display desired data info.
     */
    private createTooltip = (data: IAviBarGraphDataPoint, x: number): void => {
        const componentRef = this.createTooltipComponentRef();
        const { aviBarGraphTooltipRef } = this;

        const {
            end,
            start,
            values,
        } = data;

        const labelList = this.transformDataToTooltipInput(values);

        /** Done to remove summation/total count from data. */
        const summarySectionLabel = labelList.shift();

        attachComponentBindings(
            componentRef,
            {
                title: this.getTooltipTitle(start, end),
                labelList,
                summarySectionLabel,
            },
        );

        aviBarGraphTooltipRef.insert(componentRef.hostView);

        // TODO 158220 delete
        this.tooltipLeftPositionPx = x - pixelsFromLeftOfParent * 2;

        setTimeout(() => {
            this.tooltipWidth =
                this.aviBarGraphTooltipContainerElementRef.nativeElement.offsetWidth;

            // TODO delete 158220
            // console.log(this.tooltipWidth);
        }, 0);

        // TODO 158220 Delete if keeping avi-bar-graph-tooltip.component
        // this.renderer.setStyle(
        //     aviBarGraphTooltipContainerElementRef.nativeElement,
        //     'tooltipLeftPositionPx', `${d.offsetX - pixelsFromLeftOfParent * 2}px`,
        // );
    };

    /**
     * Returns tooltipref element.
     */
    private createTooltipComponentRef = (): ComponentRef<Component> => {
        const { aviBarGraphTooltipRef } = this;

        aviBarGraphTooltipRef.clear();

        const componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(LabelCountLegendComponent as Type<Component>);

        return componentFactory.create(aviBarGraphTooltipRef.injector);
    };

    /**
     * Transforms data to format needed by tooltip.
     */
    private transformDataToTooltipInput =
    (values: IAviBarGraphDataPointValueInput[]): ILabelRowData[] => {
        return values.map((val: IAviBarGraphDataPointValueInput) => {
            const {
                color,
                count,
                type: label,
            } = val;

            return {
                color,
                count,
                hasLeftColor: label !== this.totalLogsLabel,
                label,
            };
        });
    };

    /**
     * Transorms start & end times into title to display in tooltip.
     */
    private getTooltipTitle =
    (start: TTimestampFormatFromApi, end: TTimestampFormatFromApi): string => {
        const { timeFormat } = d3;
        const isMoreThan24Hours =
            this.getDurationInMilliSeconds(end, start) > 86400000;

        const formatTime = isMoreThan24Hours ?
            timeFormat('%m/%d %I:%M:%S %p') : timeFormat('%I:%M:%S %p');
        const titleStart = formatTime(getUnixTimeInMilliseconds(start));
        const titleEnd = formatTime(getUnixTimeInMilliseconds(end));

        return `${titleStart} - ${titleEnd}`;
    };

    /**
     * Determines if overlay exists or is in process of being made.
     */
    private overlayExists = (): boolean => {
        return !d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).empty();
    };

    /**
     * Determines if left boundary has been clicked.
     */
    private hasLeftBoundaryBeenClicked = (): boolean => {
        const {
            overlayStaticVerticalBorderPixels,
            overlayStartPixels,
        } = this;

        return overlayStaticVerticalBorderPixels >
            overlayStartPixels - overlayBorderPlusMinus &&
            overlayStaticVerticalBorderPixels <
            overlayStartPixels + overlayBorderPlusMinus;
    };

    /**
     * Determines if right boundary has been clicked.
     */
    private hasRightBoundaryBeenClicked = (): boolean => {
        const {
            overlayStaticVerticalBorderPixels,
            overlayEndPixels,
        } = this;

        return overlayStaticVerticalBorderPixels >
            overlayEndPixels - overlayBorderPlusMinus &&
            overlayStaticVerticalBorderPixels < overlayEndPixels + overlayBorderPlusMinus;
    };

    /**
     * Draws custom overlay.
     * @param {x} DragEvent['x'] - Represents:
     *  when overlay is being created or a boundary is being dragged, the current cursor position;
     *  when overlay is being moved, old left boundary + number of pixels being moved
     */
    private drawOverlay = (x: DragEvent['x'], isOverlayBeingDragged = false): void => {
        const widthPixels = isOverlayBeingDragged ?
            this.overlayEndPixels - this.overlayStartPixels :
            this.getOverlayWidthPxDuringConstruction(x);

        /**
         * Represents:
         *  when overlay created left to right, left boundary;
         *  when overlay created right to left, current cursor position;
         *  when overlay is being moved, old left boundary + number of pixels being moved
         */
        let startingPositionPixels: number;

        if (isOverlayBeingDragged || !this.isDragFromLeftToRight(x)) {
            startingPositionPixels = x;
        } else {
            startingPositionPixels = this.overlayStaticVerticalBorderPixels;
        }

        this.svgOverlayContainer
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS, true)
            .attr('x', startingPositionPixels + 2)
            .attr('y', pixelsFromTopOfParent)
            .attr('width', widthPixels)
            .attr('height', maxHeightOfBars)
            .attr('fill', `url(#${AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID})`);
    };

    /**
     * Draws left/right boundary for custom overlay.
     */
    private drawVerticalOverlayBoundary = (xPosition: number, borderUniqueClass: string): void => {
        this.svgOverlayContainer
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS, true)
            .classed(borderUniqueClass, true)
            .attr('x', xPosition)
            .attr('y', pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', overlayBorderWidth)
            .attr('height', maxHeightOfBars + overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);
    };

    /**
     * Determines if user click occurred inside existing overlay.
     */
    private hasExistingOverlayBeenClicked = (): boolean => {
        if (!this.overlayExists() || this.overlayConstructionInProgress) {
            return false;
        }

        const { overlayStaticVerticalBorderPixels: x } = this;

        const isRightOfBorderPlusMargin = x > this.overlayStartPixels + overlayBorderPlusMinus;
        const isLeftOfBorderPlusMargin = x < this.overlayEndPixels - overlayBorderPlusMinus;

        if (isRightOfBorderPlusMargin && isLeftOfBorderPlusMargin) {
            return true;
        }

        return false;
    };

    /**
     * Resets all overlay properties and deletes existing overlay.
     */
    private resetOverlay = (): void => {
        this.overlayStartPixels = undefined;
        this.overlayEndPixels = undefined;
        this.overlayStartTime = undefined;
        this.overlayEndTime = undefined;
        this.removeOverlayGraphics();
    };

    /**
     * Removes existing overlay from page. Does not delete properties.
     */
    private removeOverlayGraphics = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS}`).remove();
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();
    };

    /**
     * Drags overlay.
     */
    private handleOverlayDrag = (currentX: DragEvent['x']): void => {
        const xDifferencePixels = currentX - this.overlayStaticVerticalBorderPixels;

        const newLeftBoundaryPixels = this.overlayStartPixels + xDifferencePixels;
        const newRightBoundaryPixels = this.overlayEndPixels + xDifferencePixels;

        if (this.isOverlayBoundaryOutOfBounds(newLeftBoundaryPixels) ||
            this.isOverlayBoundaryOutOfBounds(newRightBoundaryPixels)) {
            return;
        }

        this.removeOverlayGraphics();

        this.drawVerticalOverlayBoundary(newLeftBoundaryPixels,
            AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS);
        this.drawVerticalOverlayBoundary(newRightBoundaryPixels,
            AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
        this.drawOverlay(newLeftBoundaryPixels, true);
        this.drawHorizontalOverlayBoundaries(newLeftBoundaryPixels, true);
    };

    /**
     * Sets gradient for overlay.
     */
    private setOverlayColorGradient = (): void => {
        const gradientElement = this.svgGraph
            .append('linearGradient')
            .attr('id', AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID)
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 1);

        gradientElement
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', '#67b0cb') // aviBlue3
            .attr('stop-opacity', 0.3)
            .attr('offset', 0);

        gradientElement
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', 'white')
            .attr('stop-opacity', 0)
            .attr('offset', 0.6);
    };

    /**
     * Draws top and bottom boundaries for custom overlay.
     */
    private drawHorizontalOverlayBoundaries =
    (x: DragEvent['x'], isOverlayBeingDragged = false): void => {
        const widthPixels = isOverlayBeingDragged ?
            this.overlayEndPixels - this.overlayStartPixels :
            this.getOverlayWidthPxDuringConstruction(x);

        let startingPositionPixels: number;

        if (isOverlayBeingDragged || !this.isDragFromLeftToRight(x)) {
            startingPositionPixels = x;
        } else {
            startingPositionPixels = this.overlayStaticVerticalBorderPixels;
        }

        // Top bar
        this.svgOverlayContainer
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS, true)
            .attr('x', startingPositionPixels)
            .attr('y', pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', widthPixels)
            .attr('height', overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);

        // Bottom bar
        this.svgOverlayContainer
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS, true)
            .attr('x', startingPositionPixels)
            .attr('y', maxHeightOfBars + pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', widthPixels)
            .attr('height', overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);
    };

    /**
     * Returns width of overlay during construction of overlay.
     */
    private getOverlayWidthPxDuringConstruction = (currentX: DragEvent['x']): number => {
        return Math.abs(currentX - this.overlayStaticVerticalBorderPixels);
    };

    /**
     * Returns width of overlay after it is created.
     */
    private getOverlayWidthPx = (): number => {
        if (!this.overlayStartPixels) {
            return 0;
        }

        return this.overlayEndPixels - this.overlayStartPixels;
    };

    /**
     * Returns duration of overlay in milliseconds.
     */
    private getOverlayDuration = (): number => {
        if (!this.overlayStartTime) {
            return 0;
        }

        return getUnixTimeInMilliseconds(this.overlayEndTime) -
            getUnixTimeInMilliseconds(this.overlayStartTime);
    };

    /**
     * Determines if user went left->right or right->left.
     */
    private isDragFromLeftToRight = (currentX: DragEvent['x']): boolean => {
        return currentX > this.overlayStaticVerticalBorderPixels;
    };

    /**
     * Converts pixels from left extreme of graph to time, in a readable format.
     */
    private getTimeFromPixels =
    (pixelsFromLeft: number): TTimestampFormatFromApiWithMicroS => {
        const {
            graphEndTimestamp,
            graphStartTimestamp,
        } = this;

        const ratioFromLeft =
            pixelsFromLeft / (this.getGraphContainerWidth() - pixelsFromLeftOfParent);
        const milliSecondsFromStart =
            ratioFromLeft * this.getDurationInMilliSeconds(graphEndTimestamp, graphStartTimestamp);
        const unixTime = milliSecondsFromStart + getUnixTimeInMilliseconds(graphStartTimestamp);

        return convertTimeToTTimestampFormatFromApi(unixTime);
    };

    /**
     * @return true, if provided timestamp is within the graph range, false if otherwise.
     */
    private isTimeStampInGraphRange(timeStamp: TTimestampFormatFromApi): boolean {
        const isGreaterThanGraphStartTimeStamp =
            this.getDurationInMilliSeconds(timeStamp, this.graphStartTimestamp) >= 0;
        const isLessThanGraphEndTimeStamp =
            this.getDurationInMilliSeconds(this.graphEndTimestamp, timeStamp) >= 0;

        return isGreaterThanGraphStartTimeStamp && isLessThanGraphEndTimeStamp;
    }

    /**
     * @return true, if provided start and end timestamps fall within the range of graph,
     *  false if otherwise.
     */
    private isTimeStampRangeWithinGraphRange(
        endTimeStamp: TTimestampFormatFromApi,
        startTimeStamp: TTimestampFormatFromApi,
    ): boolean {
        return this.isTimeStampInGraphRange(startTimeStamp) &&
            this.isTimeStampInGraphRange(endTimeStamp);
    }

    /**
     * Main functionality creating actual bars in graph from data.
     */
    private createGraph = (): void => {
        this.bars = this.svgGraph.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
            .data(this.dataset)
            .join(SVG_ELEMENT) // extra full size pseudo-element
            .classed(AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS, true);

        this.addBars();
        this.setHorizontalPropsForBars();
        this.changeGraphCursor();
    };

    /**
     * Sets cursor to desired upon mouse hover over graph area.
     */
    private changeGraphCursor = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`)
            .on('mouseover', function(): void {
                d3.select(this).style('cursor', 'crosshair');
            });
    };

    /**
     * Adds actual bars from data.
     */
    private addBars = (): void => {
        this.bars.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
            .data((datum: IAviBarGraphDataPoint): IAviBarGraphDataPointValue[] => {
                const {
                    end,
                    start,
                    totalValueSummation,
                } = datum;

                const { totalLogsLabel } = this;

                let lastCount = 0;

                // Add pseudo-bar to represent full column
                // yPosition for this will be incorrect, and handled separately
                datum.values.unshift({
                    color: '#fafafa',
                    count: totalValueSummation,
                    type: totalLogsLabel,
                });

                // Return actual properties needed to create bars
                return datum.values
                    .map((value: IAviBarGraphDataPointValueInput): IAviBarGraphDataPointValue => {
                        const {
                            color,
                            type,
                        } = value;

                        let { count } = value;

                        if (type === totalLogsLabel) {
                            count = 0;
                        }

                        const currentCount = lastCount;

                        lastCount += count;

                        return {
                            color,
                            count,
                            end,
                            start,
                            type,
                            yPosition: currentCount + count,
                        };
                    });
            })
            .join(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_RECT_CLASS, true)
            .classed(AVI_BAR_GRAPH_RECT_ACTUAL_CLASS, true)
            // Represents top of the bar in pixels
            .attr('y', (value: IAviBarGraphDataPointValue): number => {
                // sets to match max y-value out of all columns for pseudo-bar
                if (value.type === this.totalLogsLabel) {
                    return pixelsFromTopOfParent;
                }

                return actualGraphHeightPx - pixelsFromBottomOfParent -
                this.createBarYScale()(value.yPosition);
            })
            .attr('height', (value: IAviBarGraphDataPointValue): number => {
                // sets to match max count out of all data for pseudo-bar
                if (value.type === this.totalLogsLabel) {
                    return maxHeightOfBars;
                }

                return this.createBarYScale()(value.count);
            })
            .attr('fill', (value: IAviBarGraphDataPointValue):
            IAviBarGraphDataPointValue['color'] => value.color);
    };

    /**
     * Sets width and x-position for bars.
     */
    private setHorizontalPropsForBars = (): void => {
        this.bars.selectAll(`.${AVI_BAR_GRAPH_RECT_ACTUAL_CLASS}`)
            .attr('width', (datum: IAviBarGraphDataPoint): number => this.getBarWidth(datum))
            .attr('transform', (datum: IAviBarGraphDataPoint): string => {
                const xPosition = this.getXPositionFromTimestamp(datum.start);
                const translate = [xPosition, 0];

                return `translate(${translate})`;
            });
    };

    /**
     * Returns width of single bar given data point.
     */
    private getBarWidth = (datum: IAviBarGraphDataPoint): number => {
        const graphContainerWidth = this.getGraphContainerWidth();

        if (graphContainerWidth <= pixelsBetweenBars) {
            return 0;
        }

        const {
            end,
            start,
        } = datum;

        const barWidth = this.getDurationInMilliSeconds(end, start);
        const graphDuration = this.getGraphDuration();

        const oneDivisionWidth = barWidth / graphDuration * this.getGraphContainerWidth();

        return oneDivisionWidth < pixelsBetweenBars ?
            oneDivisionWidth : oneDivisionWidth - pixelsBetweenBars;
    };

    /**
     * Returns yScale needed for bars in graph.
     * Specifically for bars in graph, separate from scaling needed for y-axis.
     */
    private createBarYScale = (): d3.scaleLinear =>
        d3.scaleLinear()
            .domain([0, d3.max(
                this.dataset,
                (datum: IAviBarGraphDataPoint) => datum.totalValueSummation,
            )])
            // Denotes min to max range of any given datum's value
            .range([
                0,
                maxHeightOfBars,
            ]);

    /**
     * Creates y-axis for bar graph.
     */
    private createYAxis = (): void => {
        const maxVal = d3.max(
            this.dataset,
            (datum: IAviBarGraphDataPoint) => datum.totalValueSummation,
        );

        const yAxisScale = d3.scaleLinear()
            .domain([0, maxVal])
            // go backwards => bottom to top (negative)
            .range([actualGraphHeightPx - pixelsFromBottomOfParent, pixelsFromTopOfParent]);

        const yAxis = d3.axisLeft()
            .scale(yAxisScale)
            .tickSize(0)
            .tickFormat(d3.format('.0f'));

        yAxis.ticks(maxVal > 3 ? 4 : maxVal);

        this.svg
            .append(G_ELEMENT)
            .classed(AVI_BAR_GRAPH_Y_AXIS_CLASS, true)
            .call(yAxis)
            .attr('transform', `translate(${pixelsFromLeftOfParent}, 0)`);
    };

    /**
     * Creates x-axis for bar graph.
     */
    private createXAxis = (): void => {
        const topTranslation = actualGraphHeightPx - pixelsFromBottomOfParent;

        this.svg
            .append(G_ELEMENT)
            .classed(AVI_BAR_GRAPH_X_AXIS_CLASS, true)
            .attr('transform', `translate(0, ${topTranslation})`)
            .call(this.scaleXAxis());
    };

    /**
     * Returns real-time width of graph container element.
     */
    private getGraphContainerWidth = (): number =>
        /* eslint-disable no-extra-parens */
        (document.querySelector(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`) as HTMLElement)
            .offsetWidth;

    /**
     * Returns starting horizontal position for provided timestamp.
     * Calculates ratio: (bar-start-time - graph-start-time)/(graph duration),
     * multiplies by graph width, and sets the bar at this position relative to svg container.
     */
    private getXPositionFromTimestamp = (timestamp: TTimestampFormatFromApi): number => {
        const milliSecondsFromStart =
            this.getDurationInMilliSeconds(timestamp, this.graphStartTimestamp);
        const xRatioFromStart = milliSecondsFromStart / this.getGraphDuration();

        return xRatioFromStart * (this.getGraphContainerWidth() - pixelsFromLeftOfParent);
    };

    /**
     * Returns duration of any end+start timestamps in milliseconds.
     */
    private getDurationInMilliSeconds =
    (end: TTimestampFormatFromApi, start: TTimestampFormatFromApi): number => {
        return getUnixTimeInMilliseconds(end) - getUnixTimeInMilliseconds(start);
    };

    /**
     * Returns duration of graph in milliseconds.
     */
    private getGraphDuration = (): number => {
        return this.getDurationInMilliSeconds(this.graphEndTimestamp, this.graphStartTimestamp);
    };

    /**
     * Returns duration of graph in seconds.
     */
    private getGraphDurationInSeconds = (): number => {
        return this.getGraphDuration() / 1000;
    };

    /**
     * Calculate and update tooltip position.
     */
    private updateTooltipLeftPositionPx = (): void => {
        this.tooltipLeftPositionPx = this.getOverlayMiddlePixels() - 2 * pixelsFromLeftOfParent;
    };

    /**
     * Scale x-axis.
     * Called upon change in parent container width (window size changes etc.).
     * Labels are custom calculated to avoid issues inherent in d3's implementation.
     */
    private scaleXAxis = (): void => {
        const { graphStartTimestamp, graphEndTimestamp } = this;

        // Convert to usable date objects
        const startTime = new Date(graphStartTimestamp);
        const endTime = new Date(graphEndTimestamp);

        const timeSpan = endTime.getTime() - startTime.getTime();
        const totalDurationDays = timeSpan / (1000 * 60 * 60 * 24);

        // Limit the number of labels to prevent crowding/overlap, for worst case when page width
        // is 1200px, and left and right sidebars are both expanded.
        const maxAllowedTicks = 16;

        let tickValues = [];

        // Determined by visual trial and error to avoid cutting off the first and last labels
        const percentToAvoidCuttingOff = 0.02;
        // Adjust start and end times to avoid cutting off the first and last labels
        const adjustedStart = new Date(startTime.getTime() + timeSpan * percentToAvoidCuttingOff);
        const adjustedEnd = new Date(endTime.getTime() - timeSpan * percentToAvoidCuttingOff);
        const adjustedStartEpoch = adjustedStart.getTime();
        const adjustedEndEpoch = adjustedEnd.getTime();
        const adjustedDuration = adjustedEndEpoch - adjustedStartEpoch;

        if (totalDurationDays <= 1) {
            /*
             * For durations within a single day, we ensure that the labels are equally spaced
             * and accurate to the minute.
             */

            const tickInterval = adjustedDuration / (maxAllowedTicks - 1);

            tickValues = Array.from(
                { length: maxAllowedTicks },
                (_, i) => new Date(adjustedStartEpoch + i * tickInterval),
            );
        } else if (totalDurationDays > 1 && totalDurationDays <= 9) {
            /*
             * For durations between 1 and 9 days, we want to ensure that the labels are
             * equally spaced out between each day, that we display the start of each day,
             * and that labels between days are on the hour, for visual appeal.
             *
             * 9 is chosen as the upper limit for the following reasons:
             * 3 things are accomplished by the custom x-axis creation:
             * 1. avoid truncated text at left/right edges of axes.
             * 2. avoid overlap of labels caused by D3 edge case upon new month.
             * 3. for over 1 day duration, ensure that the labels are equally spaced out
             *  not only between the ends, but also between each day, and on the hour.
             * our maxAllowedTicks dictates the max number of days for which we can have labels
             * in between days. because of reason 1, 7 is too small a number, since 7 hours and
             * something will still allow for labels every midnight and noon. 10 is too large,
             * it may result in more than maxAllowedTicks.
             *
             * In the future, a dev may wish to compute the exact hours we want to set as max,
             * instead of 9 days, as a function of maxAllowedTicks, adjustedDuration, and keeping
             * 12 as the divisor. This will allow for a more dynamic approach, in case these
             * variables need to change. For now, 9 is a sufficient upper limit.
             */

            const totalHours = adjustedDuration / (1000 * 60 * 60);
            // Interval if we don't care to equally space between days
            const maxInterval = totalHours / maxAllowedTicks;

            /*
             * Calculate the actual interval to use, ensuring that the ticks/labels are
             * equally spaced out between 24 hours, and max number on entire axis does not
             * exceed maxAllowedTicks.
             *
             * To achieve this, the interval must be a divisor/factor of 24 (hours in a day),
             * but since we're limiting totalDurationDays to max of 9, we can use 12.
             * This results in allowance of 2, 3, 4, 6, 12 (hours) as possible intervals, without
             * having to explicitly compare to these divisors of 12.
             *
             * As an example, if maxInterval calculated above is 2.5,
             * we wish to use 3 as the interval.
             * Or if maxInterval is 4.5, we wish to use the next closest divisor of 12, which is 6.
             * The math below ensures this.
             */
            const actualInterval = Math.max(Math.ceil(12 / Math.floor(12 / maxInterval)), 2);

            /*
             * To find the first label value (dateToAdd[0]), we find the next hour after
             * adjustedStart that is a multiple of actualInterval.
             *
             * This is done by finding the remainder of adjustedStart's hours divided by
             * actualInterval, and then subtracting this from actualInterval to find the
             *  time to the next multiple.
             * Finally we add this value to adjustedStart to get the first label value.
             */
            const remainderOverAdjustedStartTime = adjustedStart.getHours() % actualInterval;
            const timeToFirstTick = actualInterval - remainderOverAdjustedStartTime;
            const dateToAdd = new Date(adjustedStart.getTime());

            dateToAdd.setHours(adjustedStart.getHours() + timeToFirstTick);

            // Reset minutes to 0 to ensure the time label is exactly on the hour, instead of offset
            dateToAdd.setMinutes(0);

            while (dateToAdd < adjustedEnd) {
                tickValues.push(new Date(dateToAdd.getTime()));
                dateToAdd.setHours(dateToAdd.getHours() + actualInterval);
            }
        } else {
            /*
             * For durations over 9 days, focus on the start of each day only. Due to equal spacing
             * and desired maxAllowedTicks, we cannot show any labels in between start of days.
             */

            const daysBetweenTicks = Math.ceil(totalDurationDays / maxAllowedTicks);
            let current = d3.timeDay.ceil(adjustedStart);

            while (current <= adjustedEnd && tickValues.length < maxAllowedTicks) {
                tickValues.push(new Date(current));
                current = d3.timeDay.offset(current, daysBetweenTicks);
            }
        }

        /**
         * Annotate the labels with the appropriate format based on the time span.
         */
        const tickFormatFunction = (d: Date): string => {
            if (totalDurationDays <= 1) {
                return d3.timeFormat('%H:%M')(d);
            } else {
                const hour = new Date(d).getHours();

                if (hour === 0) {
                    // At the start of a new day, show day and date
                    return d3.timeFormat('%a %d')(d);
                } else {
                    // Otherwise, continue to show time for intraday ticks, if they exist
                    return d3.timeFormat('%I %p')(d);
                }
            }
        };

        // Scale the x-axis
        const xAxisScale = d3.scaleTime()
            // Note: '.domain' can alternatively be provided unix time in milliseconds.
            .domain([startTime, endTime])
            // offset to left to avoid last label spillage to right
            .range([pixelsFromLeftOfParent, this.getGraphContainerWidth() - 1]);

        // Assign labels
        return d3.axisBottom(xAxisScale)
            .tickSize(0)
            .tickValues(tickValues)
            .tickFormat(tickFormatFunction);
    };

    /**
     * Update tooltip for logs that are being fetched into overlay.
     * Check for disappeared tooltip of a rendered overlay,
     * as this happens for incoming logs and create a new tooltip.
     */
    private updateTooltipForIncomingData(): void {
        const isTooltipAbsentForRenderedOverlay =
            !this.aviBarGraphTooltipRef.length && this.overlayExists();

        if (isTooltipAbsentForRenderedOverlay) {
            this.createCompletedOverlayTooltip(undefined);
        }
    }
}
