/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CinematicModule
 */

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { attachComponentBindings } from 'ng/shared/utils';
import {
    CinematicService,
    IAviCinematicProps,
} from '../../services';

import './avi-cinematics-portal.component.less';

interface IAviCinematic {
    id: string;
    componentRef: ComponentRef<Component>;
}

/**
 * @description
 *     Component used to display cinematic views. All rendered views will be contained within the
 *     viewport of this component.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-cinematics-portal',
    templateUrl: './avi-cinematics-portal.component.html',
})
export class AviCinematicsPortalComponent implements AfterViewInit, OnDestroy {
    @ViewChild('cinematicsContainer', {
        read: ViewContainerRef,
    }) private cinematicsContainerRef: ViewContainerRef;

    /**
     * Contain a list of cinematics.
     */
    public cinematics: IAviCinematic[] = [];

    /**
     * Set to true when cinematics stack is empty.
     */
    public hideBackdrop = true;

    /**
     * Subscription to CinematicService, which provides this component with a stack of cinematic
     * views to be rendered.
     */
    private subscription: Subscription;

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly cinematicService: CinematicService,
    ) {}

    /** @override */
    public ngAfterViewInit(): void {
        this.subscription = this.cinematicService.items$
            .subscribe((cinematics: IAviCinematicProps[]): void => {
                this.destroyRemovedCinematics(cinematics);
                this.setCinematics(cinematics);
                this.renderNewCinematics();

                this.hideBackdrop = cinematics.length === 0;
            });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Destroy any views that no longer exist in the view stack from cinematicService.
     */
    private destroyRemovedCinematics(incomingCinematicProps: IAviCinematicProps[]): void {
        const incomingHash = incomingCinematicProps.reduce((hash, cinematic) => {
            hash[cinematic.id] = true;

            return hash;
        }, {});

        const removedCinematics = this.cinematics.filter(cinematic => {
            return !(cinematic.id in incomingHash);
        });

        removedCinematics.forEach((cinematic): void => {
            const { hostView } = cinematic.componentRef;
            const index = this.cinematicsContainerRef.indexOf(hostView);

            this.cinematicsContainerRef.remove(index);
        });
    }

    /**
     * Create a cinematicsStack that matches the cinematicPropsStack coming from the
     * CinematicService.
     */
    private setCinematics(incomingCinematicProps: IAviCinematicProps[] = []): void {
        const existingCinematicRefs = this.cinematics.reduce((hash, cinematic) => {
            hash[cinematic.id] = cinematic.componentRef;

            return hash;
        }, {});

        this.cinematics = incomingCinematicProps.map((props): IAviCinematic => {
            const { id } = props;
            const componentRef = id in existingCinematicRefs ?
                existingCinematicRefs[id] :
                this.createComponentRef(props);

            return {
                componentRef,
                id,
            };
        });
    }

    /**
     * Render any view in the cinematic stack that doesn't already exist in the ViewContainerRef.
     */
    private renderNewCinematics(): void {
        this.cinematics.forEach((cinematic, stackIndex): void => {
            const { hostView } = cinematic.componentRef;
            const index = this.cinematicsContainerRef.indexOf(hostView);

            if (index < 0) {
                this.cinematicsContainerRef.insert(hostView, stackIndex);
            }
        });
    }

    /**
     * Create an instance of the cinematic component.
     */
    private createComponentRef(cinematicProps: IAviCinematicProps): ComponentRef<Component> {
        const {
            component,
            componentProps = {},
            id,
        } = cinematicProps;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.cinematicsContainerRef.injector);
        const defaultComponentProps = {
            onClose: () => this.cinematicService.remove(id),
        };

        const extendedComponentProps: {} = {
            ...defaultComponentProps,
            ...componentProps,
        };

        attachComponentBindings(componentRef, extendedComponentProps);

        return componentRef;
    }
}
