<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div class="ip-reputation-match">
        <enum-radio-buttons
            enum="MatchOperation"
            name="match-operation-{{ matchIndex }}"
            [(ngModel)]="editable.config.match_operation"
            [noMarginTop]="true"
        ></enum-radio-buttons>
        <div class="ip-reputation-match__reputation-container">
            <label
                aviLabelWithTooltip
                objectType="{{ ipReputationTypeMatchObjectType }}"
                fieldName="reputation_types"
                for="reputation-types"
                required
            >
                {{ l10nKeys.ipReputationTypeLabel | vtranslate }}
            </label>
            <avi-dropdown
                id="reputation-types"
                name="reputation-types"
                [(ngModel)]="editable.config.reputation_types"
                [options]="ipReputationTypeOptions"
                placeholder="{{ l10nKeys.ipReputationTypePlaceholder | vtranslate }}"
                [multiple]="true"
                required
            ></avi-dropdown>
        </div>
    </div>
</match-wrapper>
