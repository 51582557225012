<div
    class="mapping-rule-attribute-match"
    provideParentForm
>
    <avi-fieldset [hideContent]="!attributeMatch.hasCriteria">
        <avi-fieldset_header>
            <avi-radio-container [noMarginTop]="true">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.AuthMappingRule }}"
                    fieldName="attribute_match"
                >
                    {{ l10nKeys.attributeMatchLabel | vtranslate }}
                </label>
                <avi-radio
                    name="attribute-match-criteria"
                    [value]="null"
                    [(ngModel)]="attributeMatch.config.criteria"
                    (ngModelChange)="onClearCriteria()"
                >
                    {{ l10nKeys.anyLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="attribute-match-criteria"
                    [value]="authMatchOperationEnumHash.AUTH_MATCH_CONTAINS"
                    [(ngModel)]="attributeMatch.config.criteria"
                    tooltipText="{{ getTooltip(authMatchOperationEnumHash.AUTH_MATCH_CONTAINS) }}"
                >
                    {{ l10nKeys.containsLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="attribute-match-criteria"
                    [value]="authMatchOperationEnumHash.AUTH_MATCH_DOES_NOT_CONTAIN"
                    [(ngModel)]="attributeMatch.config.criteria"
                    tooltipText="{{ getTooltip(authMatchOperationEnumHash.AUTH_MATCH_DOES_NOT_CONTAIN) }}"
                >
                    {{ l10nKeys.doesNotContainLabel | vtranslate }}
                </avi-radio>
                <avi-radio
                    name="attribute-match-criteria"
                    [value]="authMatchOperationEnumHash.AUTH_MATCH_REGEX"
                    [(ngModel)]="attributeMatch.config.criteria"
                    tooltipText="{{ getTooltip(authMatchOperationEnumHash.AUTH_MATCH_REGEX) }}"
                >
                    {{ l10nKeys.regexLabel | vtranslate }}
                </avi-radio>
            </avi-radio-container>
        </avi-fieldset_header>

        <avi-fieldset_content>
            <div *ngIf="attributeMatch.hasCriteria">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        objectType="AuthMatchAttribute"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.attributeNameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="attribute-match-name"
                        type="text"
                        [(ngModel)]="attributeMatch.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.attributeNamePlaceholder | vtranslate }}"
                        required
                    />
                </avi-input-container>

                <div class="mapping-rule-attribute-match__attribute-values full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.AuthMatchAttribute }}"
                            fieldName="values"
                            for="attribute-match-values"
                        >
                            {{ l10nKeys.attributeValuesLabel | vtranslate }}
                        </label>

                        <avi-repeated-strings
                            name="attribute-match-values"
                            placeholder="{{ l10nKeys.attributeValuePlaceholder | vtranslate }}"
                            [(ngModel)]="attributeMatch.config.values"
                        ></avi-repeated-strings>
                    </div>
                </div>
            </div>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
