<match-wrapper
    label="{{ label }}"
    fieldName="{{ fieldName }}"
    [showRemoveIcon]="showRemoveIcon"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="string-match"
        provideParentForm
    >
        <string-match-config
            [disallowRegex]="disallowRegex"
            [editable]="editable"
        ></string-match-config>
    </div>
</match-wrapper>
