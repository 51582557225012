<avi-confirmation
    class="app-about-modal clr-wrapper"
    headerText="{{ l10nKeys.aboutModalTitle | vtranslate }}"
    (onClose)="handleCancel()"
>
    <avi-confirmation_modal-content>
        <system-about-info></system-about-info>
        <div class="app-about-modal__row">
            <div class="app-about-modal__control app-about-modal__copyright">
                <copyright></copyright>
            </div>
        </div>
    </avi-confirmation_modal-content>
</avi-confirmation>
