/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import './avi-dropdown-menu.component.less';
import * as l10n from './avi-dropdown-menu.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * Component for the AviDropdown menu. Contains an optional search input and transcluded dropdown
 * options.
 * @author alextsg
 */
@Component({
    selector: 'avi-dropdown-menu',
    templateUrl: './avi-dropdown-menu.component.html',
})

export class AviDropdownMenuComponent implements AfterViewInit, OnDestroy {
    /**
     * If true, hides the search input.
     */
    @Input()
    public hideSearch = false;

    /**
     * Search input model.
     */
    @Input()
    public searchTerm = '';

    /**
     * Called when user types in the search input.
     */
    @Output()
    public onSearch = new EventEmitter();

    /**
     * Called when user types in the search input.
     */
    @Output()
    public onDestroy = new EventEmitter();

    /**
     * Search input field.
     */
    @ViewChild('searchInput')
    private searchInputRef: ElementRef;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        setTimeout(() => this.searchInputRef.nativeElement.focus());
    }

    /**
     * Handler called when the user types in the search input.
     */
    public handleSearch(event: Event): void {
        const { target } = event;
        const { value: searchTerm } = target as HTMLInputElement;

        this.onSearch.emit(searchTerm);
    }

    /**
     * Stop propagation for clr-modal to prevent auto-refocus. Clarity does auto-refocusing back
     * to the modal when focusing pop-up input that doesn't belong to the modal, so the search input
     * can't be focused under a dialog.
     * This mechanism needs to be coupled with stopping propagation for focus-out.
     */
    public handleSearchFocusIn(event: Event): void {
        event.stopPropagation();
    }

    /**
     * Stop propagation for clr-modal to prevent auto-refocus. Clarity does auto-refocusing back
     * to the modal when focusing pop-up input that doesn't belong to the modal, so the search input
     * can't be focused under a dialog.
     * This mechanism needs to be coupled with stopping propagation for focus-in.
     */
    public handleSearchFocusOut(event: Event): void {
        event.stopPropagation();
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.onDestroy.emit();
    }
}
