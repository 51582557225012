/**
 * Module containing dialog components
 * @preferred
 * @module DialogModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { ClrCheckboxModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { VIPModule } from '@vmw/ngx-vip';
import { SharedModule } from '../../shared/shared.module';

import {
    AviConfirmationComponent,
    AviContinueConfirmationComponent,
    AviDialogsPortalComponent,
    AviDismissChangesConfirmationComponent,
    JsonViewerComponent,
    MessageOfTheDayComponent,
} from '.';

const dialogComponents = [
    AviConfirmationComponent,
    AviContinueConfirmationComponent,
    AviDialogsPortalComponent,
    AviDismissChangesConfirmationComponent,
    JsonViewerComponent,
    MessageOfTheDayComponent,
];

@NgModule({
    declarations: [
        ...dialogComponents,
    ],
    imports: [
        CommonModule,
        ClrCheckboxModule,
        FormsModule,
        VIPModule,
        SharedModule,
    ],
    exports: [
        AviConfirmationComponent,
        JsonViewerComponent,
        MessageOfTheDayComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class DialogModule {}
