/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    ClusterStateValue,
    ClusterVipStatus,
    IClusterVipRuntime,
} from 'generated-types';

import * as l10n from './cluster-state-info.l10n';
import './cluster-state-info.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component that renders cluster state and VIP information.
 * @author alextsg
 */
@Component({
    selector: 'cluster-state-info',
    templateUrl: './cluster-state-info.component.html',
})
export class ClusterStateInfoComponent {
    @Input()
    public clusterState: ClusterStateValue;

    /**
     * Name configured on the Cluster.
     */
    @Input()
    public name: string;

    /**
     * V4 Cluster VIP address.
     */
    @Input()
    public clusterVipAddressV4: string;

    /**
     * V6 Cluster VIP address.
     */
    @Input()
    public clusterVipAddressV6: string;

    /**
     * Runtime status containing the runtime state of the cluster VIP (v4/v6).
     */
    @Input()
    public clusterVipRuntimeStatus: IClusterVipRuntime;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Return Cluster VIPv4 status.
     */
    public get clusterVipStatus(): ClusterVipStatus | undefined {
        return this.clusterVipRuntimeStatus?.cluster_vip_status;
    }

    /**
     * Return Cluster VIPv6 status.
     */
    public get clusterVip6Status(): ClusterVipStatus | undefined {
        return this.clusterVipRuntimeStatus?.cluster_vip6_status;
    }
}
