/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IAuthMatchGroupMembership } from 'generated-types';
import { AuthMatchGroupMembership } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description AuthMatchGroupMembership ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class AuthMatchGroupMembershipConfigItem extends MessageItem<IAuthMatchGroupMembership> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthMatchGroupMembership,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns true if criteria is defined.
     */
    public get hasCriteria(): boolean {
        return Boolean(this.config.criteria);
    }
}
