<div class="vs-logs-signpost">
    <ng-template #signpostAlerts>
        <!-- Error Section -->
        <vs-logs-errors
            *ngIf="vsLogsSignpostStore.showErrorMessage$ | async"
            class="vs-logs-signpost__alert"
            errorMessage="{{ l10nKeys.defaultErrorMsgLabel | vtranslate }}"
            [closable]="false"
        ></vs-logs-errors>

        <!--  Timeout Message -->
        <avi-alert-group
            alertType="warning"
            *ngIf="vsLogsSignpostStore.showTimeoutMessage$ | async"
            class="vs-logs-signpost__alert"
        >
            <span>{{ l10nKeys.timeoutMessageLabel | vtranslate }}</span>
        </avi-alert-group>

        <!-- Empty Data Info Section -->
        <cds-alert-group
            *ngIf="vsLogsSignpostStore.showEmptyDataMessage$ | async"
            status="info"
        >
            <cds-alert [closable]="false">
                {{ l10nKeys.emptyDataMessageLabel | vtranslate }}
            </cds-alert>
        </cds-alert-group>
    </ng-template>

    <!-- Custom Or Default Signpost Section-->
    <vs-logs-signpost-custom *ngIf="isCustomComponent; else defaultSignpost">
        <ng-container *ngTemplateOutlet="signpostAlerts"></ng-container>
    </vs-logs-signpost-custom>
</div>

<ng-template #defaultSignpost>
    <vs-logs-signpost-default
        [configKey]="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async"
    >
        <ng-container *ngTemplateOutlet="signpostAlerts"></ng-container>
    </vs-logs-signpost-default>
</ng-template>
