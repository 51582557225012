/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-dns-virtual-services-grid';
const prefix = `${moduleName}.${componentName}`;

export const dnsVirtualServicesLabel = `${prefix}.dnsVirtualServicesLabel`;
export const dnsVirtualServiceLabel = `${prefix}.dnsVirtualServiceLabel`;
export const subdomainLabel = `${prefix}.subdomainLabel`;
export const allSubdomainsPlaceholder = `${prefix}.allSubdomainsPlaceholder`;
export const dnsVirtualServicePlaceholder = `${prefix}.dnsVirtualServicePlaceholder`;
export const hostAllSubdomainsLabel = `${prefix}.hostAllSubdomainsLabel`;
export const registerControllerInfoMessage = `${prefix}.registerControllerInfoMessage`;

export const ENGLISH = {
    [dnsVirtualServicesLabel]: 'DNS Virtual Services ({0})',
    [dnsVirtualServiceLabel]: 'DNS Virtual Service',
    [subdomainLabel]: 'Subdomain',
    [allSubdomainsPlaceholder]: 'All Subdomains',
    [dnsVirtualServicePlaceholder]: 'Select DNS Virtual Service',
    [hostAllSubdomainsLabel]: 'Host All Subdomains',
    [registerControllerInfoMessage]: 'Site must be saved to add placements.',
};
