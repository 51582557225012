/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4PolicySet } from 'generated-types';
import { L4PolicySet as L4PolicySetObjectType } from 'object-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';

import {
    L4PolicySetModalComponent,
} from 'ng/modules/l4-policy/components/l4-policy-set-modal/l4-policy-set-modal.component';
import { L10nService } from '@vmw/ngx-vip';

import { L4ConnectionPolicyConfigItem } from '.';

import * as l10n from '../l4-policy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IL4PolicySetConfig extends Omit<IL4PolicySet, 'l4_connection_policy'> {
    l4_connection_policy?: L4ConnectionPolicyConfigItem;
}

interface IL4PolicySetData {
    config: IL4PolicySetConfig,
}

/**
 * @desc L4PolicySet ObjectTypeItem class.
 * @author Zhiqian Liu
 */
export class L4PolicySet extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IL4PolicySetData;

    /**
     * Getter for L4 Connection Policy.
     */
    public get l4ConnectionPolicy(): L4ConnectionPolicyConfigItem {
        return this.config.l4_connection_policy;
    }

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            ...args,
            objectName: 'l4policyset',
            objectType: L4PolicySetObjectType,
            permissionName: 'PERMISSION_L4POLICYSET',
            windowElement: L4PolicySetModalComponent,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected requiredFields(): string[] {
        return ['l4_connection_policy'];
    }

    /**
     * @override
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.l4PolicySetModalBreadcrumbTitle);
    }
}
