/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, OnDestroy } from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import {
    HOVER_OVERLAY_TOP_POSITION,
} from 'ng/modules/tooltip/components/avi-hover-overlay/avi-hover-overlay.constants';
import { VsLogsStore } from '../../../services/vs-logs.store';
import {
    defaultShowNonSignificantLogs,
    defaultShowSignificantLogs,
} from '../../../constants/default-values.constants';

import * as l10n from './vs-logs-significance-options.l10n';
import './vs-logs-significance-options.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Significant and Non-Significant checkboxes.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-significance-options',
    templateUrl: './vs-logs-significance-options.component.html',
})
export class VsLogsSignificanceOptionsComponent implements OnDestroy {
    public readonly globalL10nKeys = globalL10nKeys;
    public readonly l10nKeys = l10nKeys;

    /**
     * Flag for if significant logs checkbox is selected. Maps to adf API param.
     */
    public showSignificantLogs: boolean = defaultShowSignificantLogs;

    /**
     * Flag for if non-significant logs checkbox is selected. Maps to udf and nf API params.
     */
    public showNonSignificantLogs: boolean = defaultShowNonSignificantLogs;

    /**
     * Non significant logs helper message position.
     */
    public readonly hoverOverlayPositionPriorities: ConnectedPosition[] = [
        HOVER_OVERLAY_TOP_POSITION,
    ];

    /**
     * Uncheck the non-significant checkbox if non-significant logs get deactivated.
     * Save subscription to destroy later.
     */
    private readonly nonSignificantLogsEnabledSubscription =
    this.vsLogsStore.nonSignificantLogsEnabled$.subscribe(
        () => this.showNonSignificantLogs = false,
    );

    constructor(
        l10nService: L10nService,
        public readonly vsLogsStore: VsLogsStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.nonSignificantLogsEnabledSubscription.unsubscribe();
    }
}
