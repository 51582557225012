/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import template from './crs-update-event-details.partial.html';
import './crs-update-event-details.less';

/**
 * @typedef {Object} CrsInfoRecord
 * @memberOf module:avi/events
 * @property {string} url - CRS data download URL.
 * @property {string} release_date - CRS release date.
 * @property {string} version - CRS version.
 * @property {string} name - CRS event name.
 */

/**
 * @name crsUpdateEventDetailsComponent
 * @memberOf module:avi/events
 * @property {module:avi/events.crsUpdateEventDetailsBindings} bindings
 * @description
 *      Component for showing CRS bulk events in
 *      Operations/Events page
 *
 * @author Navneet Sharma
 */
export const crsUpdateEventDetailsComponentOptions = {
    /**
     * @mixin crsUpdateEventDetailsBindings
     * @memberof module:avi/events
     * @property {CrsInfoRecord[]} crsList
     * @property {string} description
     * @description
     *      Bindings used for getting CRS details and
     *      description from parent component
     */
    bindings: {
        crsList: '<',
        description: '<',
    },
    template,
};
