/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Component,
    ElementRef,
    Input,
    OnInit,
} from '@angular/core';

// @ts-expect-error
import * as d3 from 'd3';

import {
    IHSSeries,
    IHSSeriesObj,
} from 'ng/modules/health-score/health-score.types';

const SVG_ELEMENT = 'svg';
const axisBorder = '1px solid var(--cds-global-color-gray-400)';

/** Class name constants representing classes d3 will work with.  */
const AVI_AREA_CHART_VIEWPORT_CLASS = 'avi-area-chart__viewport';

/**
 * @description Component for rendering area chart.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'avi-area-chart',
    templateUrl: './avi-area-chart.component.html',
})
export class AviAreaChartComponent implements OnInit {
    /**
     * Health score series values.
     */
    @Input()
    public hsSeries: IHSSeries;

    constructor(private readonly container: ElementRef) { }

    /** @override */
    public ngOnInit(): void {
        this.drawAreaGraph(this.hsSeries.values);
    }

    /**
     * X axis value.
     */
    public xAccessor(hsSeriesObj: IHSSeriesObj): number {
        return hsSeriesObj.timestamp;
    }

    /**
     * Y axis value.
     */
    public yAccessor(hsSeriesObj: IHSSeriesObj): number {
        return hsSeriesObj.value;
    }

    /**
     * Draw the area graph with given data.
     */
    public drawAreaGraph(data: IHSSeriesObj[]): void {
        // This assumes that the parent element contains the desired dimensions for the chart
        // in all use cases of the avi-area-chart component. As of now, avi-area-chart is only used
        // for health score. Here the parent element and avi-area-chart should have same dimensions
        // so that the chart is properly visible and axis are placed correctly.
        // TODO: If in future there is a use case where the width/height should be something other
        // than the parent's direct width/height, this code will need to be updated accordingly.
        const element = this.container.nativeElement.parentNode as HTMLElement;
        const { offsetWidth: width, offsetHeight: height } = element;

        const svg = d3.select(this.container.nativeElement)
            .select(`.${AVI_AREA_CHART_VIEWPORT_CLASS}`)
            .append(SVG_ELEMENT)
            .attr('width', width)
            .attr('height', height)
            .style('border-bottom', axisBorder)
            .style('border-left', axisBorder);

        const xDomain = d3.extent(data, this.xAccessor);
        const yDomain = [0, d3.max(data, this.yAccessor)];

        const xScale = d3.scaleTime()
            .domain(xDomain)
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain(yDomain)
            .range([height, 0]);

        const areaGenerator = d3.area()
            .x((value: IHSSeriesObj) => xScale(this.xAccessor(value)))
            .y1((value: IHSSeriesObj) => yScale(this.yAccessor(value)))
            .y0(height)
            .curve(d3.curveBumpX);

        svg.append('path')
            .datum(data)
            .attr('d', areaGenerator);
    }
}
