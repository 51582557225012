/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './pre-welcome-aws.less';

class PreAwsWelcomePageController {
    constructor(
        Base,
        $state,
        AsyncFactory,
        appStateConstants,
        initialDataService,
    ) {
        this.base_ = new Base();
        this.$state_ = $state;

        /**
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;

        this.AsyncFactory_ = AsyncFactory;
        this.appStateConstants_ = appStateConstants;

        this.busy = false;
        this.userAdminStatus = null;

        /**
         * Support document URL.
         * @type {string}
         */
        this.supportDocumentURL =
            'https://avinetworks.com/docs/22.1/' +
            'initial-configuration-workflow-for-avi-controller-in-aws/';
    }

    $onInit() {
        this.startPolling();
    }

    /**
     * Polls initial_data API to check for admin user account status.
     */
    startPolling() {
        if (this.userAdminStatus && this.userAdminStatus.isActive()) {
            this.cancelPolling();
        }

        this.userAdminStatus = new this.AsyncFactory_(() => {
            return this.initialDataService_.loadData(true)
                .then(this.handlePollResponse_)
                .catch(this.restartPolling_);
        }, {
            maxSkipped: 2,
            callback: this.cancelPolling.bind(this),
        });

        this.userAdminStatus.start(10000);
    }

    /**
     * Immediately stops and starts running async factory in case of
     * @protected
     * @method
     */
    restartPolling_ = () => {
        this.cancelPolling();
        this.startPolling();
    }

    /**
     * Handles the state based on the loaded initialData.
     * If User Setup already done, stops the polling and redirects to login page.
     * @protected
     * @method
     */
    handlePollResponse_ = () => {
        const { isAdminUserSetupRequired } = this.initialDataService_;
        const { LOGIN_STATE } = this.appStateConstants_;

        this.busy = false;

        if (!isAdminUserSetupRequired) {
            this.cancelPolling();
            this.$state_.go(LOGIN_STATE);
        }
    }

    /**
     * Cancels polling request.
     */
    cancelPolling() {
        this.busy = false;
        this.base_.cancelRequests();
        this.userAdminStatus.stop();
    }

    $onDestroy() {
        this.cancelPolling();
    }
}

PreAwsWelcomePageController.$inject = [
    'Base',
    '$state',
    'AsyncFactory',
    'appStateConstants',
    'initialDataService',
];

/**
 * @ngdoc component
 * @name preAwsWelcomePage
 * @description
 *     preAwsWelcomePage is used for AWS cloud users.
 *     Used to refresh page to check whether the admin user set up done through CLI
 *     and when done, redirects user to the controller settings page.
 *
 */
angular.module('aviApp').component('preAwsWelcomePage', {
    controller: PreAwsWelcomePageController,
    templateUrl: 'src/components/pages/pre-welcome-aws/pre-welcome-aws.html',
});
