<div
    *ngIf="vsLogsSignpostStore.vsLogsEndToEndTimingSignpostData$ | async as vsLogsEndToEndTimingSignpostData"
    class="vs-logs-signpost-custom-e2e-timing-header"
>
    <vs-log-cinematic-end-to-end-knot
        *ngIf="!$stateParams.poolId"
        headerText="{{ globalL10nKeys.clientLabel | vtranslate }}"
        iconShape="user"
    ></vs-log-cinematic-end-to-end-knot>

    <!-- Bridge Client RTT -->
    <vs-log-cinematic-end-to-end-bridge
        *ngIf="!$stateParams.poolId"
        class="vs-logs-signpost-custom-e2e-timing-header__bridge vs-logs-signpost-custom-e2e-timing-header__bridge--medium"
        topHeader="{{ l10nKeys.clientRttLabel | vtranslate }}"
        topHeaderColor="blue"
        arrowColor="blue"
        [isActive]="isFilteredBy === e2eTimingGroupByKeys.clientRtt"
        [endToEndTime]="vsLogsEndToEndTimingSignpostData.average.client_rtt"
        [endToEndTimeFilterProp]="e2eTimingGroupByKeys.clientRtt"
        [noStatusCode]="true"
        [isHeaderClickable]="true"
        (onHeaderClick)="switchView(e2eTimingGroupByKeys.clientRtt)"
        [isTimeUnitFilterable]="false"
    ></vs-log-cinematic-end-to-end-bridge>

    <vs-log-cinematic-end-to-end-knot
        headerText="{{ globalL10nKeys.loadBalancerLabel | vtranslate }}"
        iconShape="load-balancer"
    ></vs-log-cinematic-end-to-end-knot>

    <!-- Bridge Server RTT -->
    <vs-log-cinematic-end-to-end-bridge
        class="vs-logs-signpost-custom-e2e-timing-header__bridge vs-logs-signpost-custom-e2e-timing-header__bridge--medium"
        topHeader="{{ l10nKeys.serverRttLabel | vtranslate }}"
        topHeaderColor="green"
        arrowColor="green"
        [isActive]="isFilteredBy === e2eTimingGroupByKeys.serverRtt"
        [endToEndTime]="vsLogsEndToEndTimingSignpostData.average.server_rtt"
        [endToEndTimeFilterProp]="e2eTimingGroupByKeys.serverRtt"
        [noStatusCode]="true"
        [isHeaderClickable]="true"
        (onHeaderClick)="switchView(e2eTimingGroupByKeys.serverRtt)"
        [isTimeUnitFilterable]="false"
    ></vs-log-cinematic-end-to-end-bridge>

    <div class="vs-logs-signpost-custom-e2e-timing-header__top-card-box-section vs-logs-signpost-custom-e2e-timing-header__top-card-server-app-box">
        <vs-log-cinematic-end-to-end-knot
            headerText="{{ globalL10nKeys.serverLabel | vtranslate }}"
            iconShape="rack-server"
        ></vs-log-cinematic-end-to-end-knot>

        <!-- Bridge App Response -->
        <vs-log-cinematic-end-to-end-bridge
            *ngIf="vs.isHTTP()"
            class="vs-logs-signpost-custom-e2e-timing-header__bridge vs-logs-signpost-custom-e2e-timing-header__bridge--long"
            topHeader="{{ l10nKeys.appResponseLabel | vtranslate }}"
            topHeaderColor="yellow"
            arrowColor="yellow"
            [isActive]="isFilteredBy === e2eTimingGroupByKeys.appResponseTime"
            [endToEndTime]="vsLogsEndToEndTimingSignpostData.average.app_response_time"
            [endToEndTimeFilterProp]="e2eTimingGroupByKeys.appResponseTime"
            [noStatusCode]="true"
            [isHeaderClickable]="true"
            (onHeaderClick)="switchView(e2eTimingGroupByKeys.appResponseTime)"
            [isTimeUnitFilterable]="false"
        ></vs-log-cinematic-end-to-end-bridge>

        <vs-log-cinematic-end-to-end-knot
            *ngIf="vs.isHTTP()"
            headerText="{{ globalL10nKeys.appLabel | vtranslate }}"
            iconShape="application"
        ></vs-log-cinematic-end-to-end-knot>
    </div>

    <!-- Bridge Data Transfer -->
    <vs-log-cinematic-end-to-end-bridge
        *ngIf="vs.isHTTP()"
        class="vs-logs-signpost-custom-e2e-timing-header__bridge"
        topHeader="{{ l10nKeys.dataTransferLabel | vtranslate }}"
        topHeaderColor="violet"
        [endToEndTime]="vsLogsEndToEndTimingSignpostData.average.data_transfer_time"
        [endToEndTimeFilterProp]="e2eTimingGroupByKeys.dataTransferTime"
        [noArrow]="true"
        [isActive]="isFilteredBy === e2eTimingGroupByKeys.dataTransferTime"
        [noStatusCode]="true"
        [isHeaderClickable]="true"
        (onHeaderClick)="switchView(e2eTimingGroupByKeys.dataTransferTime)"
        [isTimeUnitFilterable]="false"
    ></vs-log-cinematic-end-to-end-bridge>

    <!-- Bridge Total Time -->
    <vs-log-cinematic-end-to-end-bridge
        *ngIf="!$stateParams.poolId && (vs.isHTTP || vs.hasTCPProxyNetProfile())"
        class="vs-logs-signpost-custom-e2e-timing-header__bridge"
        topHeader="{{ globalL10nKeys.totalTimeLabel | vtranslate }}"
        [endToEndTime]="vsLogsEndToEndTimingSignpostData.average.total_time"
        [endToEndTimeFilterProp]="e2eTimingGroupByKeys.totalTime"
        [noArrow]="true"
        [isActive]="isFilteredBy === e2eTimingGroupByKeys.totalTime"
        [noStatusCode]="true"
        [isHeaderClickable]="true"
        (onHeaderClick)="switchView(e2eTimingGroupByKeys.totalTime)"
        [isTimeUnitFilterable]="false"
    ></vs-log-cinematic-end-to-end-bridge>
</div>
