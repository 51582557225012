/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-datascript';
const prefix = `${moduleName}.${componentName}`;

export const datascriptLogLabel = `${prefix}.datascriptLogLabel`;
export const datascriptErrorStackTraceLabel = `${prefix}.datascriptErrorStackTraceLabel`;
export const datascriptErrorLabel = `${prefix}.datascriptErrorLabel`;
export const emptyListPlaceholderLabel = `${prefix}.emptyListPlaceholderLabel`;

export const ENGLISH = {
    [datascriptLogLabel]: 'Datascript Log',
    [datascriptErrorStackTraceLabel]: 'Datascript Error Stack Trace',
    [datascriptErrorLabel]: 'Datascript Error',
    [emptyListPlaceholderLabel]: 'No datascript error trace data to show!',
};
