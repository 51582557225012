/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module FullModalModule
 */

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    ReferencedObjectsDialogComponent,
    ReferencedObjectsGraphService,
} from '.';

const fullModalComponents = [
    ReferencedObjectsDialogComponent,
];

const fullModalServices = [
    ReferencedObjectsGraphService,
];

/**
 * @description
 *      Angular Module for full-modal related components.
 *
 * @author Rajawant Prajapati
 */
@NgModule({
    declarations: [
        ...fullModalComponents,
    ],
    imports: [
        DataGridModule,
        SharedModule,
        BrowserAnimationsModule,
        CommonModule,
    ],
    providers: [
        ...fullModalServices,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class FullModalModule {}
