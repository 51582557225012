<div class="vs-vip-address-grid-cell">
    <span
        class="vs-vip-address-grid-cell__item-container"
        *ngIf="!vipRuntimeList.length"
        avi-tooltip
        [showOnClick]="true"
        [tooltipControl$]="popoverControl$"
        [positionsPriority]="popoverPositions"
        (click)="openVipListPopoverWithNoRuntime($event)"
    >
        <cds-icon
            class="vs-vip-address-grid-cell__state-icon vs-vip-address-grid-cell__state-icon--pending"
            shape="minus-circle"
            size="16"
        ></cds-icon>
        {{ vipListAsString }}
        <ng-template #aviTooltipContent>
            <ng-container *ngTemplateOutlet="popOverContent"></ng-container>
        </ng-template>
    </span>
    <span 
        *ngIf="vipRuntimeList.length === 1"
        avi-tooltip
        [showOnClick]="true"
        [tooltipControl$]="popoverControl$"
        [positionsPriority]="popoverPositions"
        (click)="openVipListPopover(vipRuntimeList[0].operStateType, $event)"
    >
        <div class="vs-vip-address-grid-cell__item-container">
            <cds-icon
                class="vs-vip-address-grid-cell__state-icon"
                [ngClass]="getVipStateIconClass(vipRuntimeList[0].operStateType)"
                shape="{{ getIconShape(vipRuntimeList[0].operStateType) }}"
                direction="{{ getIconDirection(vipRuntimeList[0].operStateType) }}"
                size="16"
            ></cds-icon>
            {{ vipListAsString }}
        </div>
        <ng-template #aviTooltipContent>
            <ng-container *ngTemplateOutlet="popOverContent"></ng-container>
        </ng-template>
    </span>

    <div
        class="vs-vip-address-grid-cell__item-container"
        *ngIf="vipRuntimeList.length > 1"
    >
        <span
            class="vs-vip-address-grid-cell__item"
            *ngIf="vipsWithUpState.length > 0"
            avi-tooltip
            [showOnClick]="true"
            [tooltipControl$]="popoverControl$"
            [positionsPriority]="popoverPositions"
            (click)="openVipListPopover(operStateType.OPER_UP, $event)" 
        >
            <span class="vs-vip-address-grid-cell__item-container">
                <cds-icon
                    class="vs-vip-address-grid-cell__state-icon vs-vip-address-grid-cell__state-icon--up"
                    shape="circle-arrow"
                    size="16"
                ></cds-icon>
                {{ vipsWithUpState.length }} - {{ globalL10nKeys.addressesLabel | vtranslate }}
            </span>
            <ng-template #aviTooltipContent>
                <ng-container *ngTemplateOutlet="popOverContent"></ng-container>
            </ng-template>
        </span>
        <span
            class="vs-vip-address-grid-cell__item"
            *ngIf="vipsWithPendingState.length > 0"
            avi-tooltip
            [showOnClick]="true"
            [tooltipControl$]="popoverControl$"
            [positionsPriority]="popoverPositions"
            (click)="openVipListPopover(operStateType.OPER_PENDING, $event)" 
        >
            <span class="vs-vip-address-grid-cell__item-container">
                <cds-icon
                    class="vs-vip-address-grid-cell__state-icon vs-vip-address-grid-cell__state-icon--pending"
                    shape="minus-circle"
                    size="16"
                ></cds-icon>
                {{ vipsWithPendingState.length }} - {{ globalL10nKeys.addressesLabel | vtranslate }}
            </span>
            <ng-template #aviTooltipContent>
                <ng-container *ngTemplateOutlet="popOverContent"></ng-container>
            </ng-template>
        </span>
        <span 
            class="vs-vip-address-grid-cell__item"
            *ngIf="vipsWithDownState.length > 0"
            avi-tooltip
            [showOnClick]="true"
            [tooltipControl$]="popoverControl$"
            [positionsPriority]="popoverPositions"
            (click)="openVipListPopover(operStateType.OPER_DOWN, $event)" 
        >
            <span class="vs-vip-address-grid-cell__item-container">
                <cds-icon
                    class="vs-vip-address-grid-cell__state-icon vs-vip-address-grid-cell__state-icon--down"
                    shape="circle-arrow"
                    direction="down"
                    size="16"
                ></cds-icon>
                {{ vipsWithDownState.length }} - {{ globalL10nKeys.addressesLabel | vtranslate }}
            </span>
            <ng-template #aviTooltipContent>
                <ng-container *ngTemplateOutlet="popOverContent"></ng-container>
            </ng-template>
        </span>
    </div>
</div>

<ng-template #popOverContent>
    <div class="vs-vip-address-grid-cell__popover-caret"></div>
    <vip-list-popover
        (onClose)="closeVipListPopover()"
        [operStateType]="currentOperStateType"
        [vips] = "vips"
    ></vip-list-popover>
</ng-template>
