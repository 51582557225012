/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'pop3-health-monitor.config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleForPOP3 = `${prefix}.sectionTitleForPOP3`;
export const sectionTitleForPOP3S = `${prefix}.sectionTitleForPOP3S`;
export const userInputLabel = `${prefix}.userInputLabel`;

export const ENGLISH = {
    [sectionTitleForPOP3]: 'POP3',
    [userInputLabel]: 'User Input',
    [sectionTitleForPOP3S]: 'POP3S',
};
