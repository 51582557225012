/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'http-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const clientRequestHeaderInputLabel = `${prefix}.clientRequestHeaderInputLabel`;
export const clientRequestHeaderInputPlaceholder = `${prefix}.clientRequestHeaderInputPlaceholder`;
export const clientRequestBodyInputLabel = `${prefix}.clientRequestBodyInputLabel`;
export const clientRequestBodyInputPlaceholder = `${prefix}.clientRequestBodyInputPlaceholder`;
export const useExactRequestInputLabel = `${prefix}.useExactRequestInputLabel`;
export const serverResponseDataInputLabel = `${prefix}.serverResponseDataInputLabel`;
export const serverResponseDataInputPlaceholder = `${prefix}.serverResponseDataInputPlaceholder`;
export const responseCodeInputPlaceholder = `${prefix}.responseCodeInputPlaceholder`;

export const ENGLISH = {
    [clientRequestHeaderInputLabel]: 'Client Request Header',
    [clientRequestHeaderInputPlaceholder]: 'Enter Client Request Header',
    [clientRequestBodyInputLabel]: 'Client Request Body',
    [clientRequestBodyInputPlaceholder]: 'Enter Client Request Body',
    [useExactRequestInputLabel]: 'Use Exact Request',
    [serverResponseDataInputLabel]: 'Server Response Data',
    [serverResponseDataInputPlaceholder]: 'Enter Server Response Data',
    [responseCodeInputPlaceholder]: 'Select Response Code',
};
