/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';

/**
 * @ngdoc filter
 * @name healthScoreClass
 * @param {number} score - From 0 to 100.
 * @param {string} state - Runtime.oper_status.state enum.
 * @description
 * Filter to create a unitCard or aviHealthscore classNames from health score as a number
 * and operation state as a string (if provided).
 */
angular.module('aviApp').filter('healthScoreClass', function() {
    const
        highThreshold = 85,
        mediumThreshold = 65,
        lowThreshold = 0;

    const
        operationalHash = {
            OPER_UP: true,
            OPER_ACTIVE: true,
            OPER_ERROR_DISABLED: true,
            OPER_DISABLING: true,
            OPER_DISABLED: false,
            OPER_INACTIVE: false,
            OPER_UNUSED: false,
        };

    return (score, state) => {
        let str = '';

        const goodState = operationalHash[state];

        if (isUndefined(state)) {
            str = 'unavailable';
        } else if (goodState) {
            if (score >= highThreshold || goodState && angular.isUndefined(score)) {
                str = 'high';
            } else if (score >= mediumThreshold) {
                str = 'medium';
            } else if (score >= lowThreshold) {
                str = 'low';
            }
        } else if (operationalHash[state] === false) {
            str = 'disabled';
        } else {
            str = 'down';
        }

        return str;
    };
});
