/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'port-match';
const prefix = `${moduleName}.${componentName}`;

export const portMatchLabel = `${prefix}.portMatchLabel`;
export const portMatchInputPlaceholder = `${prefix}.portMatchInputPlaceholder`;
export const portMatchHelperText = `${prefix}.portMatchHelperText`;

export const ENGLISH = {
    [portMatchLabel]: 'Ports',
    [portMatchInputPlaceholder]: 'Enter Port',
    [portMatchHelperText]: 'Accepts single value, comma separate, and ranges',
};
