/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './dns-policy-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name dnsPolicyMatch
 * @description Component for configuring a DnsRule match.
 * @param {Function} onRemoveMatch - Called when the close button is clicked.
 * @param {ConfigItem} configItem - Match ConfigItem.
 * @param {string} matchType - Match property used to select the templateUrl.
 */
class DnsPolicyMatchController {
    constructor($injector, Regex, l10nService) {
        this._$injector = $injector;
        this.Regex = Regex;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        if (this.matchType === 'query-name') {
            const StringGroupCollection = this._$injector.get('StringGroupCollection');

            this.stringGroupCollection = new StringGroupCollection();
        }
    }
}

DnsPolicyMatchController.$inject = [
        '$injector',
        'Regex',
        'l10nService',
];

angular.module('aviApp').component('dnsPolicyMatch', {
    controller: DnsPolicyMatchController,
    bindings: {
        onRemoveMatch: '&',
        configItem: '<',
        matchType: '@',
    },
    /**
     * Sets the templateUrl based on the matchType, which changes based on the match being
     * configured.
     */
    templateUrl: ['$attrs', ({ matchType }) => {
        const templateUrl = 'src/components/applications/virtualservice/' +
                'virtualservice-dns-policy/dns-policy-rule-modal/dns-policy-match/dns-policy-match';

        return `${templateUrl}-${matchType}.html`;
    }],
});
