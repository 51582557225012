/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    IAdminAuthConfiguration,
    IRemoteAuthConfiguration,
} from 'generated-types';

import {
    isEmpty,
    max,
} from 'underscore';

import { L10nService } from '@vmw/ngx-vip';
import { IRowReorder } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { AdminAuthConfiguration } from 'object-types';

import './authentication-config.component.less';
import * as l10n from './authentication-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Authentication config in System Settings Modal.
 * @author Aravindh Nagarajan, Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'authentication-config',
    templateUrl: './authentication-config.component.html',
})
export class AuthenticationConfig implements AfterViewInit {
    /**
     * Admin authorization config instance.
     */
    @Input()
    public adminAuthConfig: IAdminAuthConfiguration;

    /**
     * Admin authorization config on change emitter, for two way binding.
     */
    @Output()
    public adminAuthConfigChange = new EventEmitter<IAdminAuthConfiguration>();

    /**
     * Boolean flag for remote auth type.
     */
    public isRemoteAuthType: boolean;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * object type used in template.
     */
    public readonly objectType = AdminAuthConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Initialize boolean flag isRemoteAuthType.
     * updates state if auth type is remote.
     */
    public ngAfterViewInit(): void {
        this.isRemoteAuthType = Boolean(this.adminAuthConfig);
    }

    /**
     * Set default admin authorization config based on isRemoteAuthType boolean flag
     */
    public setAdminAuthConfig(): void {
        const defaultAdminAuthConfigState: IAdminAuthConfiguration = {
            allow_local_user_login: true,
            remote_auth_configurations: [],
        };

        this.adminAuthConfig = this.isRemoteAuthType ? defaultAdminAuthConfigState : {};
        this.adminAuthConfigChange.emit(this.adminAuthConfig);
    }

    /**
     * Adds a new remote auth config.
     */
    public addRemoteAuthConfig(): void {
        const { remoteAuthConfig } = this;

        const maxIndexedConfig: IRemoteAuthConfiguration =
            max(remoteAuthConfig, config => config.index) as IRemoteAuthConfiguration;

        const newIndex = isEmpty(maxIndexedConfig) ? 0 : maxIndexedConfig.index + 1;

        this.remoteAuthConfig.push({
            index: newIndex,
        });
    }

    /**
     * Deletes a new remote auth config.
     */
    public deleteRemoteAuthConfig(remoteAuthConfig: IRemoteAuthConfiguration): void {
        const index = this.remoteAuthConfig.indexOf(remoteAuthConfig);

        this.remoteAuthConfig.splice(index, 1);
    }

    /**
     * Handle Row reorder event emitted from grid.
     * Update array index as well as `index` property.
     */
    public handleRemoteAuthConfigGridRowReorder({
        previousIndex,
        currentIndex,
    }: IRowReorder): void {
        let newIndexCounter = currentIndex;

        const increment = previousIndex < currentIndex ? -1 : 1;

        while (previousIndex !== newIndexCounter) {
            const prevConfig = this.remoteAuthConfig[previousIndex];
            const currentConfig = this.remoteAuthConfig[newIndexCounter];

            [
                this.remoteAuthConfig[previousIndex],
                this.remoteAuthConfig[newIndexCounter],
            ] = [
                this.remoteAuthConfig[newIndexCounter],
                this.remoteAuthConfig[previousIndex],
            ];

            [prevConfig.index, currentConfig.index] = [currentConfig.index, prevConfig.index];

            newIndexCounter += increment;
        }
    }

    /**
     * Getter for remoteAuthConfiguration
     */
    public get remoteAuthConfig(): IRemoteAuthConfiguration[] {
        const { remote_auth_configurations: remoteAuthConfig = [] } = this.adminAuthConfig;

        return remoteAuthConfig;
    }
}
