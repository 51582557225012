<system-about-card
    [showFooter]="showFooter"
    class="system-update-about-card"
>
    <system-about-card_additional>
        <div class="system-update-about-card__row">
            <div class="system-update-about-card__control">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        objectType="SystemConfiguration"
                        fieldName="fips_mode"
                        for="fips-mode-setting"
                    >
                        {{ l10nKeys.fipsLabel | vtranslate }}
                    </label>
                    <input
                        name="fips-mode-setting"
                        class="{{ fipsModeSettingClassName }} sel-fips-in-update-card"
                        clrInput
                        placeholder="-"
                        [ngModel]="fipsModeLabel"
                        readonly
                    />
                </avi-input-container>
            </div>
            <div class="system-update-about-card__control">
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        objectType="SystemConfiguration"
                        fieldName="common_criteria_mode"
                        for="common-criteria-mode-setting"
                    >
                        {{ l10nKeys.commonCriteriaLabel | vtranslate }}
                    </label>
                    <input
                        name="common-criteria-mode-setting"
                        class="{{ commonCriteriaModeSettingClassName }} sel-common-criteria-in-update-card"
                        clrInput
                        placeholder="-"
                        [ngModel]="commonCriteriaModeLabel"
                        readonly
                    />
                </avi-input-container>
            </div>
        </div>
    </system-about-card_additional>
    <system-about-card_footer>
        <clr-dropdown *ngIf="enableRollback && !isUpgradeOperationInProgress">
            <cds-button
                class="sel-rollback-button"
                action="flat"
                size="sm"
                clrDropdownTrigger
            >
                <span class="system-update-about-card__dropdown-trigger-text">
                    {{ l10nKeys.rollbackBtnLabel | vtranslate }}
                </span>
                <cds-icon
                    shape="angle"
                    direction="down"
                    size="12"
                    class="system-update-about-card__dropdown-caret"
                ></cds-icon>
            </cds-button>
            <clr-dropdown-menu
                clrPosition="bottom-left"
                *clrIfOpen
                class="system-update-about-card__dropdown-menu sel-system-update-about-card"
            >
                <div
                    *ngFor="let option of rollbackOptions; trackBy: trackByIndex"
                    class="sel-rollback-option"
                    clrDropdownItem
                    (click)="handleSelectRollbackOption(option)"
                >
                    <div class="system-update-about-card__dropdown-option-title">
                        {{ option.title }}
                    </div>
                    <div>
                        {{ option?.fullVersion }}
                            <cds-icon
                                shape="arrow"
                                direction="right"
                                size="15"
                            ></cds-icon>
                        {{ option?.fullPreviousVersion }} </div>
                </div>
            </clr-dropdown-menu>
        </clr-dropdown>
        <cds-button
            *ngIf="showComplianceModeButton"
            action="flat"
            size="sm"
            (click)="handleClickComplianceMode()"
        >
            {{ l10nKeys.complianceModeBtnLabel | vtranslate }}
        </cds-button>
    </system-about-card_footer>
</system-about-card>
