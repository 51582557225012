/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { countryDict } from '../constants/country-dict.constants';

/**
 * @description
 *     Pipe for converting a country code to its name.
 *     If the code is in two-letter, convert it to a three-letter code first.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'countryCodeToName',
})
export class CountryCodeToNamePipe implements PipeTransform {
    /** @override */
    // eslint-disable-next-line class-methods-use-this
    public transform(code: string): string {
        let countryName = code || 'Unknown';

        if (code) {
            code = code.toUpperCase();

            if (code.length === 2) {
                countryName = countryDict.iso3toName[countryDict.iso2to3[code]] || countryName;
            } else if (code.length === 3) {
                countryName = countryDict.iso3toName[code] || countryName;
            }
        }

        return countryName;
    }
}
