/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { transformSavedSearches } from '../utils/vs-logs-filters.utils';

/**
 * @description
 *     Transform saved searches data from a hash to an array.
 * @author Alex Klyuev
 */
@Pipe({
    name: 'transformSavedSearches',
})
export class TransformSavedSearches implements PipeTransform {
    /** @override */
    public transform = transformSavedSearches;
}
