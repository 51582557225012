/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './eventsListSidebar.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name eventsListSidebar
 * @author Alex Malitsky
 * @description
 *
 *     Sidebar is supposed to render the list of available Summary popovers and open them once
 *     link is clicked. All Summary popovers are actually components with naming pattern
 *     events-list-***-summary where *** is `default` when not set.
 *
 */
function EventListSidebarController(
        $scope,
        $compile,
        myAccount,
        PopoverFactory,
        l10nService,
) {
    this.myAccount = myAccount;

    /**
    * Get keys from source bundles for template usage.
    */
    this.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const popover = new PopoverFactory({
        position: 'left',
        className: 'event-list-sidebar-popover',
        repositioning: true,
        carat: true,
        width: 600,
        height: 100,
        margin: 10,
        hide: {
            outClick: true,
            onEscape: true,
            onWinResize: true,
        },
        removeAfterHide: true,
    });

    /**
 * List of all available popovers with their settings.
 * @typedef {Object} SummaryPopoverConfig
 * @property {string} id - Unique filed name to be used as a groupby value.
 * @property {string} name - Header for the first grid's column.
 * @property {string} header - Main popover header.
 * @property {string|undefined} component - Name of component to be used as a popover
 * template. When not set `default` is used.
 */
    const popovers = [{
        id: 'obj_type',
        name: l10nService.getMessage(l10nKeys.objectTypePopoverLabel),
        header: l10nService.getMessage(l10nKeys.topObjectTypesPopoverHeaderLabel),
    }, {
        id: 'obj_name',
        name: l10nService.getMessage(l10nKeys.objectNamePopoverLabel),
        header: l10nService.getMessage(l10nKeys.topNamesPopoverHeaderLabel),
    }, {
        id: 'event_id',
        name: l10nService.getMessage(l10nKeys.eventIdPopoverLabel),
        header: l10nService.getMessage(l10nKeys.topEventIdsPopoverHeaderLabel),
    }, {
        id: 'module',
        name: l10nService.getMessage(l10nKeys.modulePopoverLabel),
        header: l10nService.getMessage(l10nKeys.topModulesPopoverHeaderLabel),
    }];

    /**
     * Hash of Summary popover config objects.
     * @type {{string: SummaryPopoverConfig}}
     * @private
     */
    const popoverHash = popovers.reduce(function(hash, popoverConfig) {
        hash[popoverConfig.id] = popoverConfig;

        return hash;
    }, {});

    /**
     * @type {SummaryPopoverConfig[]}
     * @public
     */
    this.popovers = popovers;

    /**
     * Keeps active popover config, null when not present.
     * @type {SummaryPopoverConfig|null}
     * @public
     */
    this.activePopover = null;

    /**
     * Event handler for the Summary link click.
     * @param {SummaryPopoverConfig#id} popoverId
     * @param {ng.$event} $event
     * @public
     */
    this.openPopover = function(popoverId, $event) {
        this.activePopover = popoverHash[popoverId];

        const componentTag = $(`<events-list-${
            this.activePopover.component || 'default'
        }-summary/>`).attr({
            'summary-config': '$ctrl.activePopover',
            'on-close': '$ctrl.closePopover()',
            'on-reposition': '$ctrl.repositionPopover()',
        });

        popover.config.html = $compile(componentTag)($scope);
        popover.show($event.target);
    };

    /**
     * Used by Summary component to close popover.
     * @public
     */
    this.closePopover = function() {
        popover.hide();
        this.activePopover = null;
    };

    /**
     * Used by Summary component to reposition popover in case of size change.
     * @public
     */
    this.repositionPopover = function() {
        popover.reposition();
    };

    this.$onDestroy = function() {
        popover.remove();
    };
}

EventListSidebarController.$inject = [
    '$scope',
    '$compile',
    'myAccount',
    'popoverFactory',
    'l10nService',
];

angular.module('aviApp').component('eventsListSidebar', {
    templateUrl: 'src/components/applications/events-list-sidebar/events-list-sidebar.html',
    controller: EventListSidebarController,
    bindings: {
        isEnabled: '<',
    },
});
