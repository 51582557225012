<div class="avi-data-grid clr-wrapper">
    <clr-datagrid
        *ngIf="afterViewInit"
        [clrDgSelected]="selected"
        (clrDgSelectedChange)="selectionChanged($event)"
        (clrDgRefresh)="refresh($event)"
        [clrDgLoading]="isLoading"
        cdkDropList
        [cdkDropListDisabled]="!layout.withReordering"
        (cdkDropListDropped)="emitRowOrderChange($event)"
    >
        <clr-dg-action-bar class="avi-data-grid__action-bar grid-container">
            <div class="avi-data-grid__action-bar-left-actions grid-container__cell--w--6">
                <ng-content select="avi-data-grid_actions"></ng-content>
                <div
                    *ngIf="multipleactions?.length && selected?.length"
                    class="btn-group avi-data-grid__btn-group"
                >
                    <cds-button
                        class="avi-data-grid__multipleaction-button"
                        *ngFor="let action of multipleactions; trackBy: trackByActionLabel"
                        [disabled]="isMultipleActionDisabled(action)"
                        size="sm"
                        action="outline"
                        (click)="callMultipleaction(action)"
                    >
                        <span>{{ action.label }}</span>
                    </cds-button>
                </div>
            </div>
            <div
                *ngIf="hasSearch"
                class="avi-data-grid__action-bar-search-container grid-container__cell--w--6"
            >
                <cds-icon
                    *ngIf="!showSearchBar"
                    class="avi-data-grid__action-bar-search-icon search-icon"
                    shape="search"
                    size="19"
                    (click)="expandSearchBar()"
                ></cds-icon>
                <ng-container *ngIf="showSearchBar">
                    <form
                        clrForm
                        class="avi-data-grid__action-bar-search-input"
                        #searchForm="ngForm"
                    >
                        <cds-search
                            layout="horizontal"
                        >
                            <!-- Empty label element placeholder required by cds-search component -->
                            <label></label>
                            <input
                                type="search"
                                id="search-input"
                                class="grid-search-input"
                                #searchInput
                                [placeholder]="searchInputPlaceholder"
                                name="searchValue"
                                [(ngModel)]="searchValue"
                                (ngModelChange)="search()"
                            />
                        </cds-search>
                    </form>
                    <cds-icon
                        class="avi-data-grid__action-bar-search-clear-icon"
                        shape="times-circle"
                        size="20"
                        (click)="clearSearch()"
                    ></cds-icon>
                </ng-container>
            </div>
        </clr-dg-action-bar>
        <clr-dg-column
            *ngIf="layout.withReordering"
            [style.minWidth.px]="iconWidth"
            [style.width.px]="iconWidth"
        ></clr-dg-column>
        <clr-dg-column
            *ngFor="let field of filteredFields; trackBy: trackByFieldId"
            [clrDgSortBy]="getSortingComparator(field)"
        >
            <cds-icon
                *ngIf="field.icon"
                class="avi-data-grid__field-header-icon"
                shape="{{ field.icon }}"
                size="{{ field.iconSize ?? '16' }}"
            ></cds-icon>
            <ng-container
                *ngIf="field.labelTooltipOptions; then labelWithTooltip; else labelOnly">
            </ng-container>
            <ng-template #labelOnly>
                {{ field.label }}
            </ng-template>
            <ng-template #labelWithTooltip>
                <label
                    class="avi-data-grid__field-label-with-tooltip"
                    aviLabelWithTooltip
                    [objectType]="field.labelTooltipOptions.objectType"
                    [fieldName]="field.labelTooltipOptions.fieldName"
                >
                    {{ field.label }}
                </label>
            </ng-template>
        </clr-dg-column>
        <clr-dg-column
            *ngIf="hasSingleactions"
            [style.minWidth.px]="singleactionsWidth"
            [style.maxWidth.px]="singleactionsWidth"
        ></clr-dg-column>
        <clr-dg-column
            *ngIf="showExpanderColumn"
            [style.minWidth.px]="iconWidth"
            [style.maxWidth.px]="iconWidth"
        >
            <cds-icon
                *ngIf="showGridExpander"
                class="avi-data-grid__icon"
                shape="angle"
                direction="{{ isGridExpanded ? 'up' : 'down' }}"
                size="14"
                (click)="toggleGridExpanded()"
            ></cds-icon>
        </clr-dg-column>
        <clr-dg-row
            *ngFor="let row of rows; index as i; trackBy: trackByRowId"
            [hidden]="!isGridExpanded"
            [clrDgItem]="row"
            [clrDgSelectable]="isRowSelectable(row)"
            class="avi-data-grid__row"
            [ngClass]="'sel-avi-data-grid-row-' + i"
            cdkDrag
        >
            <clr-dg-cell
                *ngIf="layout.withReordering"
                class="avi-data-grid__singleaction-cell avi-data-grid__singleaction-cell--movable"
                [style.minWidth.px]="iconWidth"
                cdkDragHandle
            >
                <cds-icon
                    class="avi-data-grid__icon avi-data-grid__icon--movable"
                    shape="drag-handle"
                    size="20"
                ></cds-icon>
            </clr-dg-cell>
            <clr-dg-cell
                *ngFor="let field of filteredFields"
                class="{{'sel-avi-data-grid-cell-' + getFormattedFieldLabel(field.label)}}"
            >
                <div
                    *ngIf="field.transform && !field.getTooltip"
                    class="avi-data-grid__cell-transform"
                >
                    {{ field.transform(row) }}
                </div>
                <div *ngIf="field.templateRef">
                    <ng-container
                        [ngTemplateOutlet]="field.templateRef"
                        [ngTemplateOutletContext]="{ $implicit: row, index: i, rows: rows }"
                    ></ng-container>
                </div>
                <div *ngIf="field.transform && field.getTooltip">
                    <div
                        avi-tooltip
                        id="{{ field.id + '-tooltip' }}"
                        [showOnClick]="false"
                        [tooltipControl$]="columnTooltipControl$"
                        [positionsPriority]="tooltipPositions"
                        class="avi-data-grid__cell-transform"
                    >
                        {{ field.transform(row) }}
                        <ng-template #aviTooltipContent>
                            <div class="avi-data-grid-column-tooltip__panel clr-wrapper">
                                <div class="avi-data-grid-column-tooltip__content">
                                    <div class="avi-data-grid-column-tooltip__header">
                                        {{ field.getTooltip(row) }}
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </clr-dg-cell>
            <clr-dg-cell
                *ngIf="hasSingleactions"
                class="avi-data-grid__singleaction-cell"
                [style.minWidth.px]="singleactionsWidth"
                [style.maxWidth.px]="singleactionsWidth"
            >
                <div
                    *ngIf="!showActionsAsDropdown"
                    class="avi-data-grid__singleactions"
                >
                    <ng-container *ngFor="let singleaction of config.singleactions; trackBy: trackByIndex">
                        <button
                            *ngIf="!isSingleActionHidden(singleaction, row)"
                            [disabled]="isSingleActionDeactivated(singleaction, row)"
                            (click)="callSingleaction(singleaction, row)"
                            class="avi-data-grid__single-action-icon-container"
                            type="button"
                        >
                            <cds-icon
                                shape="{{ singleaction.shape }}"
                                title="{{ singleaction.label }}"
                                size="14"
                                class="avi-data-grid__icon sel-avi-data-grid-icon-{{singleaction.shape}}"
                                [ngClass]="{ 'avi-data-grid__icon--deactivated': isSingleActionDeactivated(singleaction, row)} "
                            ></cds-icon>
                        </button>
                    </ng-container>
                </div>
                <div
                    avi-tooltip
                    [showOnClick]="true"
                    *ngIf="showActionsAsDropdown"
                    [tooltipControl$]="singleActionsTooltipControl$"
                >
                    <cds-icon
                        shape="ellipsis-vertical"
                        size="sm"
                    ></cds-icon>
                    <ng-template #aviTooltipContent>
                        <div class="avi-data-grid__singleactions-list">
                            <div
                                class="avi-data-grid__singleactions-list--item"
                                *ngFor="let singleaction of config.singleactions; trackBy: trackByIndex"
                                (click)="callSingleactionAndCloseList(singleaction, row)"
                                [disabled]="isSingleActionDeactivated(singleaction, row)"
                                [hidden]="isSingleActionHidden(singleaction, row)"
                            >
                                {{ singleaction.label }}
                            </div>
                        </div>
                    </ng-template>
                </div>
            </clr-dg-cell>
            <clr-dg-cell
                *ngIf="showExpanderColumn"
                class="avi-data-grid__singleaction-cell"
                [style.minWidth.px]="iconWidth"
                [style.maxWidth.px]="iconWidth"
            >
                <ng-container *ngIf="showRowExpanders && !isRowExpanderDeactivated(row)">
                    <cds-icon
                        class="avi-data-grid__icon"
                        shape="angle"
                        direction="{{ isRowExpanded(row) ? 'up' : 'down' }}"
                        size="14"
                        (click)="toggleRowExpanded(row)"
                    ></cds-icon>
                </ng-container>
            </clr-dg-cell>
            <clr-dg-row-detail
                *ngIf="isRowExpanded(row)"
                class="avi-data-grid__expanded-content"
                [ngClass]="{ 'avi-data-grid__column-based-expanded-content-row': config.isExpandedContentColumnBased && !config.expandedContentTemplateRef }"
            >
                <ng-container *ngIf="config.isExpandedContentColumnBased && !config.expandedContentTemplateRef">
                    <clr-dg-row *ngFor="let child of row.children; index as i; trackBy: trackByRowId">
                        <clr-dg-cell
                            *ngIf="layout.withReordering"
                            [style.minWidth.px]="iconWidth"
                        ></clr-dg-cell>
                        <clr-dg-cell *ngFor="let field of filteredFields">
                            <div
                                *ngIf="field.expandedTransform"
                                class="avi-data-grid__cell-transform"
                            >
                                {{ field.expandedTransform(child) }}
                            </div>
                            <div *ngIf="field.expandedTemplateRef">
                                <ng-container
                                    [ngTemplateOutlet]="field.expandedTemplateRef"
                                    [ngTemplateOutletContext]="{ $implicit: child, index: i}"
                                ></ng-container>
                            </div>
                        </clr-dg-cell>
                        <clr-dg-cell
                            *ngIf="hasSingleactions"
                            [style.minWidth.px]="singleactionsWidth"
                            [style.maxWidth.px]="singleactionsWidth"
                        ></clr-dg-cell>
                        <clr-dg-cell
                            *ngIf="showExpanderColumn"
                            [style.minWidth.px]="iconWidth"
                            [style.maxWidth.px]="iconWidth"
                        ></clr-dg-cell>
                    </clr-dg-row>
                </ng-container>
                <ng-container
                    *ngIf="!config.isExpandedContentColumnBased && config.expandedContentTemplateRef"
                    [ngTemplateOutlet]="config.expandedContentTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: row, index: i }"
                ></ng-container>
            </clr-dg-row-detail>
        </clr-dg-row>
        <clr-dg-footer
            *ngIf="showFooter"
            class="avi-data-grid__footer"
        >
            <avi-data-grid-column-selection
                [fields]="fields"
                [(showColumnHash)]="showColumnHash"
            ></avi-data-grid-column-selection>
            <clr-dg-pagination
                *ngIf="!hidePagination"
                #pagination
                [clrDgTotalItems]="rowsTotal"
                [clrDgPageSize]="pageSize"
            >
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,30,50]">
                    {{ l10nKeys.itemsPerPageLabel | vtranslate }}
                </clr-dg-page-size>
                <ng-container *ngIf="!config.layout?.hideRowsTotal">
                    {{ rowsTotal }} {{ globalL10nKeys.totalLabel | vtranslate }}
                </ng-container>
            </clr-dg-pagination>
        </clr-dg-footer>
        <clr-dg-placeholder>
            {{ placeholderMessage }}
        </clr-dg-placeholder>
    </clr-datagrid>
</div>
