/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { isObject } from 'underscore';
import {
    GslbServiceModalComponent,
} from 'ng/modules/gslb/components/gslb-service-modal/gslb-service-modal.component';
import { AviPermissionResource } from 'generated-types';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';
import { GSLB } from 'items/gslb.item.factory';

interface IGslbServiceArgs {
    gslb?: GSLB;
}

const allDataSources = {
    list: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config', 'runtime', 'health'],
    },
};

/**
 * @description
 *
 *     Collection which is supposed to have at most one item. Used to load it or create a new
 *     one using default configuration.
 *
 * @author Alex Malitsky, Ram Pal, Suraj Kumar
 */
export class GSLBServiceCollection extends Collection {
    public static ajsDependencies = [
        'GSLBService',
        'systemInfoService',
        'Auth',
    ];

    public readonly gslb: GSLB;
    private readonly auth: Auth;
    private readonly systemInfo: SystemInfoService;

    constructor(args: IGslbServiceArgs = {}) {
        const extendedArgs = {
            objectName: 'gslbservice-inventory',
            allDataSources,
            windowElement: GslbServiceModalComponent,
            isStatic: false,
            permissionName: AviPermissionResource.PERMISSION_GSLBSERVICE,
            ...args,
        };

        super(extendedArgs);

        // expect loaded GSLB item here
        if (isObject(args.gslb)) {
            this.gslb = args.gslb;
        }

        this.serverDefaultsOverride_ = {
            groups: undefined,
            health_monitor_refs: [],
            domain_names: [],
        };

        this.itemClass_ = this.getAjsDependency_('GSLBService');
        this.systemInfo = this.getAjsDependency_('systemInfoService');
        this.auth = this.getAjsDependency_('Auth');
    }

    /** @override */
    public isCreatable(): boolean {
        return this.systemInfo.localSiteIsGSLBLeader &&
            this.auth.isAllowed('gslb') &&
            super.isCreatable();
    }
}
