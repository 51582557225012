/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HTTPProtocol,
    IProtocolMatch,
    MatchOperation,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Token for ProtocolMatchConfigItem class.
 */
export const PROTOCOL_MATCH_CONFIG_ITEM_TOKEN = 'ProtocolMatchConfigItem';

/**
 * @description  ProtocolType match ConfigItem class.
 * @author Sarthak Kapoor
 */
export class ProtocolMatchConfigItem extends MessageItem<IProtocolMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'ProtocolMatch',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IProtocolMatch> {
        return {
            match_criteria: MatchOperation.IS_IN,
            protocols: HTTPProtocol.HTTP,
        };
    }
}
