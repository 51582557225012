<div class="vs-logs-collapsed-sidebar">
    <div class="vs-logs-collapsed-sidebar__saved-search-section">
        <collapsed-sidebar-icon-list
            [iconNames]="searchSectionIconList"
        ></collapsed-sidebar-icon-list>
    </div>
    <div class="vs-logs-collapsed-sidebar__data-section">
        <collapsed-sidebar-icon-list
            [iconNames]="dataSectionIconList"
        ></collapsed-sidebar-icon-list>
   </div>
</div>
