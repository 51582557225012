/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'avi-repeated-collection-or-custom-value';
const prefix = `${moduleName}.${componentName}`;

export const addItemButtonLabel = `${prefix}.addItemButtonLabel`;

export const ENGLISH = {
    [addItemButtonLabel]: 'Add Item',
};
