/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'full-modal-config-viewer';
const prefix = `${moduleName}.${componentName}`;

export const viewAsJson = `${prefix}.viewAsJson`;
export const review = `${prefix}.review`;

export const ENGLISH = {
    [viewAsJson]: 'View as JSON',
    [review]: 'Review',
};
