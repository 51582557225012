/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'attachments-selection-dialog';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const gridHeaderLabel = `${prefix}.gridHeaderLabel`;
export const dateColumnLabel = `${prefix}.dateColumnLabel`;
export const typeColumnLabel = `${prefix}.typeColumnLabel`;
export const nameColumnLabel = `${prefix}.nameColumnLabel`;
export const durationColumnLabel = `${prefix}.durationColumnLabel`;
export const sizeColumnLabel = `${prefix}.sizeColumnLabel`;
export const removeActionLabel = `${prefix}.removeActionLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [cancelButtonLabel]: 'Cancel',
    [gridHeaderLabel]: 'Files on Controller ({0})',
    [addButtonLabel]: 'Add',
    [dateColumnLabel]: 'Date',
    [typeColumnLabel]: 'Type',
    [nameColumnLabel]: 'Name',
    [durationColumnLabel]: 'Duration',
    [sizeColumnLabel]: 'Size',
    [removeActionLabel]: 'Remove',
};
