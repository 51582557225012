<div
    class="string-group-or-custom-value"
    provideParentForm
>
    <avi-radio-container [noMarginTop]="noMarginTop">
        <avi-radio
            name="{{ 'string-type_' + index }}"
            value="{{ StringType.STRING_GROUP }}"
            [(ngModel)]="stringType"
            (ngModelChange)="handleStringTypeChange()"
            [disabled]="disabled"
        >
            {{ l10nKeys.selectFromAvailableLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            name="{{ 'string-type_' + index }}"
            value="{{ StringType.CUSTOM_VALUE }}"
            [(ngModel)]="stringType"
            (ngModelChange)="handleStringTypeChange()"
            [disabled]="disabled"
        >
            {{ l10nKeys.enterCustomValueLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>
    <div *ngIf="stringType === StringType.STRING_GROUP">
        <avi-collection-dropdown
            [disableCreate]="true"
            [disableEdit]="true"
            [collection]="stringGroupCollection"
            [(ngModel)]="stringGroupRefs"
            (ngModelChange)="handleStringGroupRefsChange()"
            placeholder="{{ l10nKeys.selectStringGroupPlaceholder | vtranslate }}"
            name="{{ 'string-group-uuid_' + index }}"
            [multiple]="true"
            [disabled]="disabled"
            required
        ></avi-collection-dropdown>
    </div>
    <div *ngIf="stringType === StringType.CUSTOM_VALUE">
        <avi-input-container noMarginTop>
            <avi-repeated-strings
                placeholder="{{ l10nKeys.enterCustomValueLabel | vtranslate }}"
                name="{{ 'custom-value_' + index }}"
                [(ngModel)]="customValues"
                (ngModelChange)="handleCustomValuesChange()"
                [disabled]="disabled"
                required
            ></avi-repeated-strings>
        </avi-input-container>
    </div>
</div>
