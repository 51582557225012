/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-profile-verify-ldap-modal';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const testUserEntryRadioLabel = `${prefix}.testUserEntryRadioLabel`;
export const testUserGroupMembershipRadioLabel = `${prefix}.testUserGroupMembershipRadioLabel`;
export const verifyAuthProfileHeader = `${prefix}.verifyAuthProfileHeader`;
export const testBaseDnRadioLabel = `${prefix}.testBaseDnRadioLabel`;
export const userNameInputLabel = `${prefix}.userNameInputLabel`;
export const userNameInputPlaceholder = `${prefix}.userNameInputPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const resultsInputLabel = `${prefix}.resultsInputLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const verifyBtnLabel = `${prefix}.verifyBtnLabel`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [testUserEntryRadioLabel]: 'Test user entry',
    [testUserGroupMembershipRadioLabel]: 'Test user group membership',
    [verifyAuthProfileHeader]: 'Verify Auth Profile',
    [testBaseDnRadioLabel]: 'Test base DN',
    [userNameInputLabel]: 'Username',
    [userNameInputPlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
    [resultsInputLabel]: 'Results',
    [cancelBtnLabel]: 'Cancel',
    [verifyBtnLabel]: 'Verify',
};
