/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

const moduleName = 'ajs-legacy';
const componentName = 'app-state-tree';
const prefix = `${moduleName}.${componentName}`;

export const usersLabel = `${prefix}.usersLabel`;
export const userProfilesLabel = `${prefix}.userProfilesLabel`;
export const tenantsLabel = `${prefix}.tenantsLabel`;
export const clientsLabel = `${prefix}.clientsLabel`;
export const securityLabel = `${prefix}.securityLabel`;
export const membersStatusLabel = `${prefix}.membersStatusLabel`;
export const fqdnInsightsLabel = `${prefix}.fqdnInsightsLabel`;
export const staticRouteLabel = `${prefix}.staticRouteLabel`;
export const bgpPeeringLabel = `${prefix}.bgpPeeringLabel`;
export const vrfContextLabel = `${prefix}.vrfContextLabel`;
export const gatewayMonitorLabel = `${prefix}.gatewayMonitorLabel`;
export const siteConfigurationLabel = `${prefix}.siteConfigurationLabel`;
export const geoProfileLabel = `${prefix}.geoProfileLabel`;
export const uploadGeoFilesLabel = `${prefix}.uploadGeoFilesLabel`;
export const federationCheckpointsLabel = `${prefix}.federationCheckpointsLabel`;
export const authenticationAuthorizationLabel = `${prefix}.authenticationAuthorizationLabel`;
export const accessSettingsLabel = `${prefix}.accessSettingsLabel`;
export const licensingLabel = `${prefix}.licensingLabel`;
export const emailSmtpLabel = `${prefix}.emailSmtpLabel`;
export const tenancyModeLabel = `${prefix}.tenancyModeLabel`;
export const uploadHsmPackagesLabel = `${prefix}.uploadHsmPackagesLabel`;
export const dnsServiceLabel = `${prefix}.dnsServiceLabel`;
export const cloudServicesLabel = `${prefix}.cloudServicesLabel`;
export const nodesLabel = `${prefix}.nodesLabel`;
export const softwareLabel = `${prefix}.softwareLabel`;
export const systemUpdateLabel = `${prefix}.systemUpdateLabel`;
export const segUpdateLabel = `${prefix}.segUpdateLabel`;
export const configurationBackupLabel = `${prefix}.configurationBackupLabel`;
export const techSupportLabel = `${prefix}.techSupportLabel`;
export const crashReportsLabel = `${prefix}.crashReportsLabel`;
export const userCredentialsLabel = `${prefix}.userCredentialsLabel`;
export const casesLabel = `${prefix}.casesLabel`;
export const allAlertsLabel = `${prefix}.allAlertsLabel`;
export const alertConfigLabel = `${prefix}.alertConfigLabel`;
export const alertActionsLabel = `${prefix}.alertActionsLabel`;
export const allEventsLabel = `${prefix}.allEventsLabel`;
export const configAuditTrailLabel = `${prefix}.configAuditTrailLabel`;
export const snmpTrapLabel = `${prefix}.snmpTrapLabel`;
export const trafficCaptureLabel = `${prefix}.trafficCaptureLabel`;
export const applicationLabel = `${prefix}.applicationLabel`;
export const persistenceLabel = `${prefix}.persistenceLabel`;
export const healthMonitorsLabel = `${prefix}.healthMonitorsLabel`;
export const ipamDnsProfilesLabel = `${prefix}.ipamDnsProfilesLabel`;
export const customIpamDnsLabel = `${prefix}.customIpamDnsLabel`;
export const trafficCloneLabel = `${prefix}.trafficCloneLabel`;
export const icapProfileLabel = `${prefix}.icapProfileLabel`;
export const natPolicyLabel = `${prefix}.natPolicyLabel`;
export const ipGroupsLabel = `${prefix}.ipGroupsLabel`;
export const stringGroupsLabel = `${prefix}.stringGroupsLabel`;
export const sslTlsCertificatesLabel = `${prefix}.sslTlsCertificatesLabel`;
export const sslTlsProfileLabel = `${prefix}.sslTlsProfileLabel`;
export const pkiProfileLabel = `${prefix}.pkiProfileLabel`;
export const authProfileLabel = `${prefix}.authProfileLabel`;
export const authMappingProfileLabel = `${prefix}.authMappingProfileLabel`;
export const jwtServerProfileLabel = `${prefix}.jwtServerProfileLabel`;
export const certificateManagementLabel = `${prefix}.certificateManagementLabel`;
export const hsmGroupsLabel = `${prefix}.hsmGroupsLabel`;
export const ssoPolicyLabel = `${prefix}.ssoPolicyLabel`;
export const botManagementPolicyListPageLabel = `${prefix}.botManagementPolicyListPageLabel`;
export const botMappingListPageLabel = `${prefix}.botMappingListPageLabel`;
export const dataScriptsLabel = `${prefix}.dataScriptsLabel`;
export const controlScriptsLabel = `${prefix}.controlScriptsLabel`;
export const protocolParserScriptsLabel = `${prefix}.protocolParserScriptsLabel`;
export const serverAutoScaleLabel = `${prefix}.serverAutoScaleLabel`;
export const launchConfigLabel = `${prefix}.launchConfigLabel`;
export const webhookLabel = `${prefix}.webhookLabel`;
export const poolGroupDeploymentLabel = `${prefix}.poolGroupDeploymentLabel`;
export const applicationRulesLabel = `${prefix}.applicationRulesLabel`;
export const positiveSecurityLabel = `${prefix}.positiveSecurityLabel`;
export const errorPageProfileLabel = `${prefix}.errorPageProfileLabel`;
export const errorPageBodyLabel = `${prefix}.errorPageBodyLabel`;
export const applicationsTabLabel = `${prefix}.applicationsTabLabel`;
export const operationsTabLabel = `${prefix}.operationsTabLabel`;
export const templatesTabLabel = `${prefix}.templatesTabLabel`;
export const infrastructureTabLabel = `${prefix}.infrastructureTabLabel`;
export const administrationTabLabel = `${prefix}.administrationTabLabel`;
export const systemSettingsLabel = `${prefix}.systemSettingsLabel`;
export const gslbServicesLabel = `${prefix}.gslbServicesLabel`;
export const poolGroupsLabel = `${prefix}.poolGroupsLabel`;
export const poolsLabel = `${prefix}.poolsLabel`;
export const accountsLabel = `${prefix}.accountsLabel`;
export const settingsLabel = `${prefix}.settingsLabel`;
export const supportLabel = `${prefix}.supportLabel`;
export const dashboardLabel = `${prefix}.dashboardLabel`;
export const cloudsLabel = `${prefix}.cloudsLabel`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const networksLabel = `${prefix}.networksLabel`;
export const networkServicesLabel = `${prefix}.networkServicesLabel`;
export const routingLabel = `${prefix}.routingLabel`;
export const notificationsLabel = `${prefix}.notificationsLabel`;
export const profilesLabel = `${prefix}.profilesLabel`;
export const policiesLabel = `${prefix}.policiesLabel`;
export const groupsLabel = `${prefix}.groupsLabel`;
export const scriptsLabel = `${prefix}.scriptsLabel`;
export const autoScaleLabel = `${prefix}.autoScaleLabel`;
export const errorPageLabel = `${prefix}.errorPageLabel`;
export const csrfPolicyPageLabel = `${prefix}.csrfPolicyPageLabel`;
export const networkLabel = `${prefix}.networkLabel`;
export const sitesLabel = `${prefix}.sitesLabel`;
export const subdomainsLabel = `${prefix}.subdomainsLabel`;

export const ENGLISH = {
    [usersLabel]: 'Users',
    [userProfilesLabel]: 'User Profiles',
    [tenantsLabel]: 'Tenants',
    [clientsLabel]: 'Clients',
    [securityLabel]: 'Security',
    [membersStatusLabel]: 'Members Status',
    [fqdnInsightsLabel]: 'FQDN Insights',
    [staticRouteLabel]: 'Static Route',
    [bgpPeeringLabel]: 'BGP Peering',
    [vrfContextLabel]: 'VRF Context',
    [gatewayMonitorLabel]: 'Gateway Monitor',
    [siteConfigurationLabel]: 'Site Configuration',
    [geoProfileLabel]: 'Geo Profile',
    [uploadGeoFilesLabel]: 'Upload Geo Files',
    [federationCheckpointsLabel]: 'Federation Checkpoints',
    [authenticationAuthorizationLabel]: 'Authentication/Authorization',
    [accessSettingsLabel]: 'Access Settings',
    [licensingLabel]: 'Licensing',
    [emailSmtpLabel]: 'Email/SMTP',
    [tenancyModeLabel]: 'Tenancy Mode',
    [uploadHsmPackagesLabel]: 'Upload HSM Packages',
    [dnsServiceLabel]: 'DNS Service',
    [cloudServicesLabel]: 'Cloud Services',
    [nodesLabel]: 'Nodes',
    [softwareLabel]: 'Software',
    [systemUpdateLabel]: 'System Update',
    [segUpdateLabel]: 'SEG Update',
    [configurationBackupLabel]: 'Configuration Backup',
    [techSupportLabel]: 'Tech Support',
    [crashReportsLabel]: 'Crash Reports',
    [userCredentialsLabel]: 'User Credentials',
    [casesLabel]: 'Cases',
    [allAlertsLabel]: 'All Alerts',
    [alertConfigLabel]: 'Alert Config',
    [alertActionsLabel]: 'Alert Actions',
    [allEventsLabel]: 'All Events',
    [configAuditTrailLabel]: 'Config Audit Trail',
    [snmpTrapLabel]: 'SNMP Trap',
    [trafficCaptureLabel]: 'Traffic Capture',
    [applicationLabel]: 'Application',
    [persistenceLabel]: 'Persistence',
    [healthMonitorsLabel]: 'Health Monitors',
    [ipamDnsProfilesLabel]: 'IPAM/DNS Profiles',
    [customIpamDnsLabel]: 'Custom IPAM/DNS',
    [trafficCloneLabel]: 'Traffic Clone',
    [icapProfileLabel]: 'ICAP Profile',
    [natPolicyLabel]: 'NAT Policy',
    [ipGroupsLabel]: 'IP Groups',
    [stringGroupsLabel]: 'String Groups',
    [sslTlsCertificatesLabel]: 'SSL/TLS Certificates',
    [sslTlsProfileLabel]: 'SSL/TLS Profile',
    [pkiProfileLabel]: 'PKI Profile',
    [authProfileLabel]: 'Auth Profile',
    [authMappingProfileLabel]: 'Auth Mapping Profile',
    [jwtServerProfileLabel]: 'JWT Server Profile',
    [certificateManagementLabel]: 'Certificate Management',
    [hsmGroupsLabel]: 'HSM Groups',
    [ssoPolicyLabel]: 'SSO Policy',
    [botManagementPolicyListPageLabel]: 'Bot Management Policy',
    [botMappingListPageLabel]: 'Bot Mapping',
    [dataScriptsLabel]: 'DataScripts',
    [controlScriptsLabel]: 'ControlScripts',
    [protocolParserScriptsLabel]: 'ProtocolParserScripts',
    [serverAutoScaleLabel]: 'Server AutoScale',
    [launchConfigLabel]: 'Launch Config',
    [webhookLabel]: 'Webhook',
    [poolGroupDeploymentLabel]: 'PoolGroup Deployment',
    [applicationRulesLabel]: 'Application Rules',
    [positiveSecurityLabel]: 'Positive Security',
    [errorPageProfileLabel]: 'Error Page Profile',
    [errorPageBodyLabel]: 'Error Page Body',
    [applicationsTabLabel]: 'Applications',
    [operationsTabLabel]: 'Operations',
    [templatesTabLabel]: 'Templates',
    [infrastructureTabLabel]: 'Infrastructure',
    [administrationTabLabel]: 'Administration',
    [gslbServicesLabel]: 'GSLB Services',
    [poolGroupsLabel]: 'Pool Groups',
    [poolsLabel]: 'Pools',
    [accountsLabel]: 'Accounts',
    [settingsLabel]: 'Settings',
    [systemSettingsLabel]: 'System Settings',
    [supportLabel]: 'Support',
    [dashboardLabel]: 'Dashboard',
    [cloudsLabel]: 'Clouds',
    [cloudLabel]: 'Cloud Resources',
    [networksLabel]: 'Networks',
    [networkServicesLabel]: 'Network Services',
    [routingLabel]: 'Routing',
    [notificationsLabel]: 'Notifications',
    [profilesLabel]: 'Profiles',
    [policiesLabel]: 'Policies',
    [groupsLabel]: 'Groups',
    [scriptsLabel]: 'Scripts',
    [autoScaleLabel]: 'AutoScale',
    [errorPageLabel]: 'Error Page',
    [csrfPolicyPageLabel]: 'CSRF Policies',
    [networkLabel]: 'Network',
    [sitesLabel]: 'Sites',
    [subdomainsLabel]: 'Subdomains',
};
