<avi-confirmation
    class="cloud-token-dialog clr-wrapper"
    headerText="{{ l10nKeys.tokenGeneratedHeader | vtranslate }}"
    (onClose)="dismiss()"
>

    <avi-confirmation_modal-content>
        <p class="cloud-token-dialog__message">
            <span>{{ l10nKeys.tokenGeneratedText | vtranslate }}</span>
            <span><strong>{{ l10nKeys.aviCtrlAuthTokenText | vtranslate }}</strong></span>
            <span>{{ l10nKeys.tenantDetailText | vtranslate }}</span>
            <span><strong>{{ tenantName + ' ' }}</strong></span>
            <span>{{ l10nKeys.inCloudText | vtranslate }}</span>
            <span><strong>{{ cloudName + '. ' }}</strong></span>
            <span>{{ l10nKeys.tokenExpiresText | vtranslate }}</span>
        </p>
        <div *ngIf="clusterUuid">
            <strong>{{ l10nKeys.clusterUuid | vtranslate : clusterUuid }}</strong>
        </div>
        <div class="cloud-token-dialog__token-details">
            <cds-icon
                shape="key"
                size="sm"
                status="info"
            ></cds-icon>
            <span class="cloud-token-dialog__token">{{ authToken }}</span>
        </div>
    </avi-confirmation_modal-content>

    <avi-confirmation_modal-actions>
        <cds-button
            size="md"
            (click)="copyToClipboard()"
        >
            {{ globalL10nKeys.copyToClipboardLabel | vtranslate }}
        </cds-button>
    </avi-confirmation_modal-actions>

</avi-confirmation>
