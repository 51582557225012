<div
    class="full-modal-config__section"
    provideParentForm
>

    <h3>{{ (isSmtpsType ? l10nKeys.sectionTitleSmtps : l10nKeys.sectionTitleSmtp) | vtranslate }}</h3>

    <health-monitor-authentication
      [editable]="editable"
      [showAuthTypeDropdown]="false"
    ></health-monitor-authentication>

    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>

    <div class="full-modal-config__form-control-row">
        <h6>{{ l10nKeys.emailInformationLabel | vtranslate }}</h6>
    </div>

    <avi-input-container noMarginTop>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorSmtp }}"
            fieldName="sender_id"
            for="sender-id"
        >
            {{ l10nKeys.senderIdLabel | vtranslate }}
        </label>
        <input
            type="text"
            id="sender-id"
            clrInput
            placeholder="{{ l10nKeys.senderIdPlaceholder | vtranslate }}"
            name="sender-id"
            [(ngModel)]="smtpConfigItem.config.sender_id"
            regexPattern="email"
        />
    </avi-input-container>

    <avi-input-container helperText="{{ l10nKeys.recipientIdsHelperText | vtranslate }}">
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorSmtp }}"
            fieldName="recipients_ids"
            for="recipients-ids"
        >
            {{ l10nKeys.recipientsIdLabel | vtranslate }}
        </label>
        <avi-repeated-strings
            id="recipients-ids"
            name="recipients-ids"
            placeholder="{{ l10nKeys.recipientsIdPlaceholder | vtranslate }}"
            objectType="{{ objectTypes.HealthMonitorSmtp }}"
            fieldName="recipients_ids"
            [(ngModel)]="smtpConfigItem.config.recipients_ids"
            regexPattern="email"
        ></avi-repeated-strings>
    </avi-input-container>

    <avi-textarea-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorSmtp }}"
            fieldName="mail_data"
            for="mail-data"
        >
            {{ l10nKeys.mailDataLabel | vtranslate }}
        </label>
        <textarea
            clrTextarea
            id="mail-data"
            [(ngModel)]="smtpConfigItem.config.mail_data"
            name="mail-data"
            placeholder="{{ l10nKeys.mailDataPlaceholder | vtranslate }}"
        ></textarea>
    </avi-textarea-container>

    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorSmtp }}"
            fieldName="domainname"
            for="smtp-domain-name"
        >
            {{ l10nKeys.domainNameLabel | vtranslate }}
        </label>
        <input
            type="text"
            id="smtp-domain-name"
            clrInput
            placeholder="{{ l10nKeys.domainNamePlaceholder | vtranslate }}"
            name="smtp-domain-name"
            [(ngModel)]="smtpConfigItem.config.domainname"
            regexPattern="hostname"
        />
    </avi-input-container>

    <!-- SMTPS type related fields -->
    <ng-container *ngIf="isSmtpsType">
        <div class="full-modal-config__form-control-row">
            <h6>{{ globalL10nKeys.securityLabel | vtranslate }}</h6>
        </div>

        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                fieldName="server_name"
                for="server-name"
            >
                {{ globalL10nKeys.tlsSniServerNameLabel | vtranslate }}
            </label>
            <input
                type="text"
                id="server-name"
                clrInput
                placeholder="{{ l10nKeys.tlsSniServerPlaceholder | vtranslate }}"
                name="server-name"
                [(ngModel)]="smtpConfigItem.config.ssl_attributes.config.server_name"
                regexPattern="hostname"
            />
        </avi-input-container>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_profile_uuid"
                    for="ssl-profile-uuid"
                    required
                >
                    {{ globalL10nKeys.sslProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-profile-uuid"
                    name="ssl-profile-uuid"
                    [collection]="sslProfileCollection"
                    [(ngModel)]="smtpConfigItem.config.ssl_attributes.config.ssl_profile_ref"
                    placeholder="{{ globalL10nKeys.sslProfilePlaceholder | vtranslate }}"
                    required
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="pki_profile_uuid"
                    for="pki-profile-uuid"
                >
                    {{ globalL10nKeys.pkiProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="pki-profile-uuid"
                    name="pki-profile-uuid"
                    [collection]="pkiProfileCollection"
                    [(ngModel)]="smtpConfigItem.config.ssl_attributes.config.pki_profile_ref"
                    placeholder="{{ globalL10nKeys.pkiProfilePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_key_and_certificate_uuid"
                    for="ssl-key-and-certificate-uuid"
                >
                    {{ globalL10nKeys.sslKeyAndCertificateLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-key-and-certificate-uuid"
                    name="ssl-key-and-certificate-uuid"
                    [collection]="certificateCollection"
                    [(ngModel)]="smtpConfigItem.config.ssl_attributes.config.ssl_key_and_certificate_ref"
                    placeholder="{{ globalL10nKeys.sslKeyAndCertificatePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>
    </ng-container>
</div>
