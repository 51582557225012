/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { IIpAddr, OperationalState } from 'generated-types';
import { isString, isUndefined } from 'underscore';
import * as l10n from '../gslb.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IGslbData {
    config: any;
    runtime: IGslbRuntime;
}

interface IGslbRuntime {
    gslbSiteOperStatuses: OperationalState[];
}

/**
 * @description
 *
 *     Item for GslbService GslbPool member taken from gslbservice-inventory API.
 *
 * @author Alex Malitsky, Ram Pal, Zhiqian Liu
 */
export class GSLBPoolMember extends Item {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IGslbData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        super(args);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the pool member 'enabled' state.
     */
    public get enabled(): boolean {
        return this.getConfig().enabled;
    }

    /**
     * Getter for the pool member IP address.
     */
    public get poolMemberIp(): IIpAddr {
        return this.getConfig().ip;
    }

    /**
     * Getter for the name of the pool that this pool member belongs to.
     */
    public get parentPoolName(): string {
        return this.getConfig().glsbPoolName;
    }

    public getRuntimeData(gslbSiteId: string): any {
        const runtime = this.data && this.data.runtime;

        if (runtime && isString(gslbSiteId) &&
            runtime.gslbSiteOperStatuses &&
            gslbSiteId in runtime.gslbSiteOperStatuses) {
            return runtime.gslbSiteOperStatuses[gslbSiteId];
        } else if (isUndefined(gslbSiteId)) {
            return super.getRuntimeData();
        } else { // placeholder when no data was returned by the backend
            return {
                oper_status: {
                    reason: [
                        this.l10nService.getMessage(l10nKeys.gslbPoolMemberUnavailableOperStatus),
                    ],
                },
            };
        }
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: IGslbData): string | undefined {
        return data.config?.id || undefined;
    }
}

Object.assign(GSLBPoolMember.prototype, {
    objectName: 'GSLBPoolMember',
});
