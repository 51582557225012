/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    AviPermissionResource,
    IPoolGroup,
    IPoolGroupMember,
} from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { StringService } from 'string-service';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { PoolGroup } from 'items/pool-group.item.factory';

export type TPoolGroupCollection = typeof PoolGroupCollection;

/**
 * @description PoolGroup collection
 *
 * @author Nisar Nadaf, sghare
 */
export class PoolGroupCollection extends Collection {
    protected static ngDependencies = [PoolGroup, StringService];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'poolgroup-inventory',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUP,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(PoolGroup);
    }

    /**
     * Returns a hash of Pool ids from the items array of a PoolGroup collection or from the
     * inventoryMap API.
     */
    public static getPoolIdsHash(items: Array<IPoolGroup | PoolGroup>) : Record<string, boolean> {
        const stringService: StringService = this.getNgDependency(StringService);

        return items.reduce((acc: Record<string, boolean>, item: IPoolGroup | PoolGroup) => {
            const { members } = item;

            if (members instanceof RepeatedMessageItem) {
                if (members.count) {
                    members.config.forEach((member: MessageItem<IPoolGroupMember>) => {
                        const { config: { pool_ref: poolRef } } = member;

                        acc[stringService.slug(poolRef)] = true;
                    });
                }
            } else if (members?.length) {
                members.forEach((member: IPoolGroupMember) => {
                    const { pool_ref: poolRef } = member;

                    acc[stringService.slug(poolRef)] = true;
                });
            }

            return acc;
        }, {});
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            PoolGroupModalComponent,
        } = await import(
            /* webpackChunkName: "pool-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/pool-group-modal/pool-group-modal.component'
        );

        return PoolGroupModalComponent as Type<Component>;
    }
}
