/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'policy-grid-config';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleIndex = `${prefix}.columnTitleIndex`;
export const columnTitleEnable = `${prefix}.columnTitleEnable`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleMatchingRules = `${prefix}.columnTitleMatchingRules`;
export const columnTitleAction = `${prefix}.columnTitleAction`;
export const actionEdit = `${prefix}.actionEdit`;
export const actionEnable = `${prefix}.actionEnable`;
export const actionDeactivate = `${prefix}.actionDeactivate`;
export const actionDelete = `${prefix}.actionDelete`;

export const ENGLISH = {
    [columnTitleIndex]: 'Index',
    [columnTitleEnable]: 'Enable',
    [columnTitleName]: 'Name',
    [columnTitleMatchingRules]: 'Matching Rules',
    [columnTitleAction]: 'Action',
    [actionEdit]: 'Edit',
    [actionEnable]: 'Enable',
    [actionDeactivate]: 'Deactivate',
    [actionDelete]: 'Delete',
};
