/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'alert';
const componentName = 'action-group-config-modal';
const prefix = `${moduleName}.${componentName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const emailInputPlaceholder = `${prefix}.emailInputPlaceholder`;
export const levelInputLabel = `${prefix}.levelInputLabel`;
export const levelInputPlaceholder = `${prefix}.levelInputPlaceholder`;
export const controlScriptInputLabel = `${prefix}.controlScriptInputLabel`;
export const controlScriptInputPlaceholder = `${prefix}.controlScriptInputPlaceholder`;
export const snmpTrapInputLabel = `${prefix}.snmpTrapInputLabel`;
export const snmpTrapInputPlaceholder = `${prefix}.snmpTrapInputPlaceholder`;
export const syslogLabel = `${prefix}.syslogLabel`;
export const syslogPlaceholder = `${prefix}.syslogPlaceholder`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;

export const ENGLISH = {
    [nameLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [modalHeaderEdit]: 'Edit Alert Action',
    [modalHeaderNew]: 'New Alert Action',
    [sectionTitleGeneral]: 'General',
    [emailInputLabel]: 'Email Notification',
    [emailInputPlaceholder]: 'Select Email Notification',
    [levelInputLabel]: 'Alert Level',
    [levelInputPlaceholder]: 'Select Alert Level',
    [controlScriptInputLabel]: 'Control Script',
    [controlScriptInputPlaceholder]: 'Select Control Script',
    [snmpTrapInputLabel]: 'SNMP Trap',
    [snmpTrapInputPlaceholder]: 'Select SNMP Trap',
    [syslogLabel]: 'Syslog',
    [syslogPlaceholder]: 'Select Syslog',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
};
