/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import { Injector } from '@angular/core';
import {
    BackupCollection,
    BACKUP_COLLECTION_TOKEN,
} from 'ajs/modules/system';

import { VirtualServiceCollection } from 'ajs/modules/vs/factories/vs-collection.factory';
import { VIRTUAL_SERVICE_COLLECTION_TOKEN } from 'ajs/modules/vs/vs.constants';

import {
    SSLProfileCollection,
    SSL_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/security/factories/ssl-profile/ssl-profile.collection.factory';

const $injector = '$injector';

export const backupCollectionProvider = {
    deps: [$injector],
    provide: BackupCollection,
    useFactory(injector: Injector): typeof BackupCollection {
        return injector.get(BACKUP_COLLECTION_TOKEN);
    },
};

export const virtualServiceCollectionProvider = {
    deps: [$injector],
    provide: VirtualServiceCollection,
    useFactory(injector: Injector): typeof VirtualServiceCollection {
        return injector.get(VIRTUAL_SERVICE_COLLECTION_TOKEN);
    },
};

export const sslProfileCollectionProvider = {
    deps: [$injector],
    provide: SSLProfileCollection,
    useFactory(injector: Injector): typeof SSLProfileCollection {
        return injector.get(SSL_PROFILE_COLLECTION_TOKEN);
    },
};
