/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'auth-mapping-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const sectionTitleMatch = `${prefix}.sectionTitleMatch`;
export const superUserLabel = `${prefix}.superUserLabel`;
export const customMappingLabel = `${prefix}.customMappingLabel`;

export const ENGLISH = {
    [modalTitle]: 'Mapping Rule',
    [sectionTitleMatch]: 'Match',
    [superUserLabel]: 'Super User',
    [customMappingLabel]: 'Custom Mapping',
};
