<avi-card class="system-settings-tenancy-mode-card">
    <avi-card_header>
        <div class="system-settings-tenancy-mode-card__header">
            <cds-icon
                shape="key"
                size="24"
                class="system-settings-tenancy-mode-card__icon"
                status="success"
            ></cds-icon>
            {{ l10nKeys.tenancyModeHeader | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="tenant_vrf"
                for="ip-route-domain"
            >
                {{ l10nKeys.ipRouteDomainLabel | vtranslate }}
            </label>
            <input
                class="sel-ip-route-domain"
                name="ip-route-domain"
                clrInput
                placeholder="-"
                [value]="ipRouteDomain"
                readonly
            />
        </avi-input-container>
        <avi-input-container>
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="se_in_provider_context"
                for="se-management"
            >
                {{ l10nKeys.seManagementLabel | vtranslate }}
            </label>
            <input
                class="sel-se-management"
                name="se-management"
                clrInput
                placeholder="-"
                [value]="seManagementContext"
                readonly
            />
        </avi-input-container>
        <avi-input-container
            *ngIf="seInProviderContext"
        >
            <label
                aviLabelWithTooltip
                [objectType]="objectType"
                fieldName="tenant_access_to_provider_se"
                for="tenant-se-access"
            >
                {{ l10nKeys.tenantSeAccessLabel | vtranslate }}
            </label>
            <input
                class="sel-tenant-se-access"
                name="tenant-se-access"
                clrInput
                placeholder="-"
                [value]="tenantAccessToSe"
                readonly
            />
        </avi-input-container>
    </avi-card_body>
</avi-card>
