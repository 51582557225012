/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IpAddrConfigItem,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    Component,
    Type,
} from '@angular/core';

import { DnsResolverModalComponent } from 'ng/modules/cloud-shared/components';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { IDnsResolver } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../cloud.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TDnsResolverPartial = Omit<IDnsResolver, 'nameserver_ips'>;

interface IDnsResolverConfig extends TDnsResolverPartial {
    nameserver_ips: RepeatedMessageItem<IpAddrConfigItem>;
}

/**
 * Ajs dependency token for DnsResolverConfigItem.
 */
export const DNS_RESOLVER_CONFIG_ITEM_TOKEN = 'DnsResolverConfigItem';

/**
 * @description
 *
 *   DnsResolver Message Item.
 *
 * @author Satish Pednekar
 */
export class DnsResolverConfigItem extends withFullModalMixin(MessageItem)<IDnsResolverConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'DnsResolver',
            windowElement: DnsResolverModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for DNS Resolver Name Server IPs.
     */
    public get nameServerIps(): RepeatedMessageItem<IpAddrConfigItem> {
        const { nameserver_ips: nameServerIps } = this.config;

        return nameServerIps;
    }

    /**
     * Getter for count of DNS Resolver Name Server IPs.
     */
    public get nameServersCount(): number {
        const { nameserver_ips: nameServerIps } = this.config;

        return nameServerIps.count;
    }

    /** @override */
    public getName(): IDnsResolver['resolver_name'] {
        const { resolver_name: resolverName = '' } = this.config;

        return resolverName;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.dnsResolverModalBreadcrumbTitle);
    }
}
