/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    AlertConfigCollection,
    TAlertConfigCollection,
} from 'ajs/modules/alert/factories/alert-config/alert-config.collection.factory';

import { StringService } from 'string-service';
import { AlertSource } from 'generated-types';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './alert-config-list-page.l10n';
import './alert-config-list-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description List page for Alert Config.
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
@Component({
    selector: 'alert-config-list-page',
    templateUrl: './alert-config-list-page.component.html',
})
export class AlertConfigListPageComponent implements OnInit, OnDestroy {
    /**
     * Expanded content template for datagrid row.
     */
    @ViewChild('expandedContentTemplateRef')
    public readonly expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    /**
     * Configuration object to display alert config
     */
    public alertConfigGridConfig: IAviCollectionDataGridConfig;

    private readonly alertConfigCollection: AlertConfigCollection;

    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(AlertConfigCollection)
        AlertConfigCollection: TAlertConfigCollection,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.alertConfigCollection = new AlertConfigCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.alertConfigCollection;
        const { l10nService } = this;

        this.alertConfigGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.alertConfigCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    sortBy: 'name',
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
                {
                    id: 'action',
                    label: l10nService.getMessage(l10nKeys.columnTitleAlertAction),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.data.config.action_group_ref ?
                            row.data.config.action_group_ref.name() :
                            '-';
                    },
                },
                {
                    id: 'type',
                    label: l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: (row: IAviCollectionDataGridRow) =>
                        this.stringService.enumeration(row.data.config.category)
                    ,
                },
                {
                    id: 'group',
                    label: l10nService.getMessage(l10nKeys.columnTitleAlertGroup),
                    transform: (row: IAviCollectionDataGridRow) =>
                        this.stringService.enumeration(row.data.config.source)
                    ,
                }, {
                    id: 'enabled',
                    label: l10nService.getMessage(globalL10nKeys.enabledLabel),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return row.data.config.enabled ?
                            l10nService.getMessage(globalL10nKeys.trueLabel) :
                            l10nService.getMessage(globalL10nKeys.falseLabel);
                    },
                }, {
                    id: 'object',
                    label: l10nService.getMessage(l10nKeys.columnTitleObject),
                    transform: (row: IAviCollectionDataGridRow) => {
                        let output = 'N/A';
                        const { config } = row.data;

                        if (config.obj_ref) {
                            output = config.obj_ref.name();
                        } else if (config.source === AlertSource.EVENT_LOGS) {
                            output = l10nService.getMessage(l10nKeys.allInstancesObjectName);
                        }

                        return output;
                    },
                },
            ],
            layout: {
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },

        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.alertConfigGridConfig = {
            ...this.alertConfigGridConfig,
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.alertConfigCollection.destroy();
    }
}
