/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name AnomalyListDataTransformer
 */
angular.module('aviApp').factory('AnomalyListDataTransformer', [
'ListDataTransformer', 'NamesHelper',
function(ListDataTransformer, NamesHelper) {
    return class AnomalyListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp) {
            if ('series' in resp.data && Array.isArray(resp.data.series)) {
                const { series } = resp.data;

                //move 'highlighted' metric to the top of the list
                if (series.length && this.owner_.highlightedMetric) {
                    const highlMetricIndex = _.findIndex(series, function(metricGroup) {
                        return metricGroup.header.name === this.owner_.highlightedMetric;
                    }, this);

                    if (highlMetricIndex !== -1) {
                        const highlMetric = series.splice(highlMetricIndex, 1)[0];

                        series.unshift(highlMetric);
                    }
                }

                resp.data.results = [];

                const objIdTypePrefixLength = 'METRICS_OBJ_ID_TYPE_'.length;

                // flatten series into anomaly objects, including some data from header
                series.forEach(metricGroup => {
                    metricGroup.data.forEach(anomaly => {
                        const { anomaly_context: context } = anomaly;

                        anomaly.prediction_interval_high = context.prediction_interval_high;
                        anomaly.prediction_interval_low = context.prediction_interval_low;
                        anomaly.entity_ref = metricGroup.header.entity_ref;
                        anomaly.pool_ref = metricGroup.header.pool_ref;
                        anomaly.server_ref = metricGroup.header.server_ref;
                        anomaly.server = metricGroup.header.server;

                        if (anomaly.server) {
                            anomaly.entity_name = anomaly.server_ref ?
                                anomaly.server_ref.name() : anomaly.server;
                        } else if (anomaly.pool_ref) {
                            anomaly.entity_name = anomaly.pool_ref.name();
                        } else {
                            anomaly.entity_name = anomaly.entity_ref.name();
                        }

                        anomaly.metricId = metricGroup.header.name;
                        anomaly.title = NamesHelper.getTitle(metricGroup.header.name);
                        anomaly.value = +anomaly.value.toPrecision(2);
                        anomaly.obj_id_type = anomaly.obj_id_type.slice(objIdTypePrefixLength);

                        resp.data.results.push(anomaly);
                    });
                });

                resp.data.count = resp.data.results.length;
            }

            return resp;
        }
    };
}]);
