<div
    class="avi-right-rail"
    [ngClass]="{ 'avi-right-rail--collapsed': collapsed }"
>
    <div class="avi-right-rail__header">
        <div
            class="avi-right-rail__trigger"
            (click)="toggleCollapse()"
        >
            <cds-icon
                class="avi-right-rail__trigger-icon"
                shape="angle-double"
                direction="{{ collapsed ? 'left' : 'right' }}"
            ></cds-icon>
        </div>
    </div>

    <div class="avi-right-rail__body">
        <!-- adding [hidden] for an outer <div> to preserve the node contents expanding state in
            the rail body while not to break CSS transition -->
        <div [hidden]="collapsed">
            <ng-content select="avi-right-rail_body"></ng-content>
        </div>
        <div
            class="avi-right-rail__body--collapsed"
            (click)="toggleCollapse()"
            [hidden]="!collapsed"
        >
            <ng-content select="avi-right-rail_body-collapsed"></ng-content>
        </div>
    </div>
</div>
