/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './vs-log-cinematic-section-dns.l10n';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying DNS section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-dns',
    templateUrl: './vs-log-cinematic-section-dns.component.html',
})
export class VsLogCinematicSectionDnsComponent {
    public readonly dnsRequestInfo$ = this.vsLogCinematicStore.dnsRequestInfo$;

    public readonly dnsResponseInfo$ = this.vsLogCinematicStore.dnsResponseInfo$;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }
}
