/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name MatchConfigItem
 * @description MatchConfigItem, abstraction of matches in policy/profile rules.
 */
const matchConfigItemFactory = (ConfigItem, stringService) => {
    class MatchConfigItem extends ConfigItem {
        /**
         * Name/label of this match to be shown in UI.
         * @return {string}
         */
        getName() {
            return this.name;
        }

        /**
         * Search Method, returns true if the match string contains term.
         * @param {string} searchTerm
         * @return {boolean}
         */
        hasTerm(searchTerm) {
            const content = this.toString();

            return stringService.contains(content, searchTerm);
        }

        /** @override */
        dataToSave() {
            const configCopy = angular.copy(this.getConfig());
            const config = ConfigItem._removeEmptyRepeated(configCopy);

            return config;
        }
    }

    angular.extend(MatchConfigItem.prototype, {
        type: 'AbstractMatch',
        name: 'Abstract Match',
        defaultPath: '',
    });

    return MatchConfigItem;
};

matchConfigItemFactory.$inject = [
        'ConfigItem',
        'stringService',
];

angular.module('aviApp').factory('MatchConfigItem', matchConfigItemFactory);
