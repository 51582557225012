/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IPAMDNS_TYPE_CUSTOM_DNS,
    IPAMDNS_TYPE_INFOBLOX,
    IPAMDNS_TYPE_INFOBLOX_DNS,
    IPAMDNS_TYPE_INTERNAL,
    IPAMDNS_TYPE_INTERNAL_DNS,
} from 'ajs/js/constants/ipam-dns.constants';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { StringService } from 'string-service';
import { IpamProfileItem } from 'ajs/modules/ipam/factories/ipam-profile.item.factory';

import {
    InfobloxSubnetConfigItem,
} from 'ajs/modules/ipam/factories/infoblox-subnet.config-item.factory';

import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';

import {
    IDnsServiceDomain,
    IIpamUsableNetwork,
} from 'generated-types';

import * as l10n from './ipam-dns-profile-list-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description IPAM/DNS Profile Expander component.
 *
 * @author Chetana Padmane
 */
@Component({
    selector: 'ipam-dns-profiles-expander',
    templateUrl: './ipam-dns-profile-list-expander.component.html',
})
export class IpamDnsProfileListExpanderComponent implements OnInit {
    /**
     *  ipamProfile ConfigItem instance.
     */
    @Input()
    public ipamProfile: IpamProfileItem;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    /**
     * Network Header label string
     */
    public header: string;

    /**
     * Object Names list.
     */
    public objectNames: string[] = [];

    /**
     * Hash of all available Ipam Profile types.
     */
    public readonly objectNameHeaderHash = {
        IPAMDNS_TYPE_INTERNAL: l10nKeys.usableNetworkHeader,
        IPAMDNS_TYPE_INFOBLOX: l10nKeys.usableSubnetHeader,
        IPAMDNS_TYPE_INFOBLOX_DNS: l10nKeys.usableDomainHeader,
        IPAMDNS_TYPE_CUSTOM_DNS: l10nKeys.usableDomainHeader,
        IPAMDNS_TYPE_INTERNAL_DNS: l10nKeys.fqdn,
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.setObjectNamesList();
    }

    /**
     * Returns a list of human-readable subnet strings.
     */
    public flattenUsableSubnets(subnets: InfobloxSubnetConfigItem[]): string[] {
        return subnets.map((subnetConfigItem: InfobloxSubnetConfigItem) => {
            const { subnet, subnet6 } = subnetConfigItem.config;
            const subnetStrings = [];

            if (subnet.isValid()) {
                subnetStrings.push(subnet.toString());
            }

            if (subnet6.isValid()) {
                subnetStrings.push(subnet6.toString());
            }

            return `(${subnetStrings.join(', ')})`;
        });
    }

    /**
     * Set object names list for selected IPAM profile type.
     */
    private setObjectNamesList(): void {
        this.header =
            this.l10nService.getMessage(this.objectNameHeaderHash[this.ipamProfile.type]);

        switch (this.ipamProfile.type) {
            case IPAMDNS_TYPE_INTERNAL:
                this.objectNames = this.ipamProfile.currentProfile.usableNetworksConfig.map(
                    (ref: MessageItem<IIpamUsableNetwork>) =>
                        this.stringService.name(ref.config.nw_ref),
                );
                break;

            case IPAMDNS_TYPE_INFOBLOX: {
                const { configuredUsableSubnets: usableSubnets } = this.ipamProfile.currentProfile;

                if (usableSubnets) {
                    this.objectNames = this.flattenUsableSubnets(usableSubnets);
                }

                break;
            }

            case IPAMDNS_TYPE_INFOBLOX_DNS:
            case IPAMDNS_TYPE_CUSTOM_DNS: {
                this.objectNames = this.ipamProfile.currentProfile.config.usable_domains;
                break;
            }

            case IPAMDNS_TYPE_INTERNAL_DNS: {
                this.objectNames =
                    this.ipamProfile.currentProfile.config.dns_service_domain.config.map(
                        (network: MessageItem<IDnsServiceDomain>) => network.config.domain_name,
                    );
                break;
            }
        }
    }
}
