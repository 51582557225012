<avi-state-page class="nat-policy-list-page">
   <avi-state-page_main-content>
      <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
      <avi-collection-data-grid [config]="natPolicyGridConfig">
         <ng-template 
            #expandedContentTemplateRef
            let-row
         >
            <strong>{{ globalL10nKeys.descriptionLabel | vtranslate }}</strong>
            <span class="nat-policy-list-page__hyphen-space-separator">-</span>
            <span>{{ row.data.config.description || (globalL10nKeys.notApplicableLabel) | vtranslate }}</span>
         </ng-template>
      </avi-collection-data-grid>
   </avi-state-page_main-content>
</avi-state-page>
