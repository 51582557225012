/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MessageItemsModule */

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { vNICNetwork } from 'object-types';
import { IvNICNetwork } from 'generated-types';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';

type TvNICNetworkPartial = Omit<IvNICNetwork, 'ip'>;

interface IVNICNetworkPartialConfig extends TvNICNetworkPartial {
    ip: IpAddrPrefixConfigItem;
    maskedIp: string;
}

/**
 * @description
 *  VNIC Network config item.
 * @author Vinay Jadhav
 */
export class VNICNetworksConfigItem
    extends withFullModalMixin(MessageItem)<IVNICNetworkPartialConfig> {
    public maskedIp: string;
    constructor(args = {}) {
        const extendedArgs = {
            objectType: vNICNetwork,
            ...args,
        };

        super(extendedArgs);
    }
}

