<div provideParentForm>
    <avi-data-grid
        [config]="hmProxyGridConfig"
        [rows]="healthMonitorProxies.config"
    >
        <avi-data-grid_header>
            <h6>{{ l10nKeys.healthMonitorProxyLabel | vtranslate : healthMonitorProxies.count }}</h6>
        </avi-data-grid_header>
        <avi-data-grid_actions>
            <cds-button
                (click)="addhmProxy()"
                size="sm"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
        <ng-template
            #controllerTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ 'hm-proxy-' + index }}"
                [options]="activeGslbSiteOptions"
                [(ngModel)]="row.config.site_uuid"
                placeholder="{{ l10nKeys.selectControllerPlaceholder | vtranslate }}"
                required
            ></avi-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
