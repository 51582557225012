/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { LinuxConfiguration } from 'object-types';

import * as l10n from './system-settings-access-banners-card.l10n';
import './system-settings-access-banners-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Presentational Component for Access Card - Banners, in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-banners-card',
    templateUrl: './system-settings-access-banners-card.component.html',
})
export class SystemSettingsAccessBannersComponent {
    /**
     * Message of the day, from linux configuration.
     */
    @Input()
    public messageOftheDay: string;

    /**
     * Login banner, from linux configuration.
     */
    @Input()
    public loginBanner: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type for template usage.
     */
    public readonly objectType = LinuxConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
