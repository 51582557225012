/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { ClusterVipStatus } from 'generated-types';

/**
 * @description Pipe that transforms a ClusterVipStatus enum value to a Clarity icon status string.
 * @author alextsg
 */
@Pipe({
    name: 'clusterVipStatusIconStatus',
})
export class ClusterVipStatusIconStatusPipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this
    public transform(clusterVipStatus: ClusterVipStatus): 'success' | 'danger' | '' {
        switch (clusterVipStatus) {
            case ClusterVipStatus.CLUSTER_VIP_DECONFIG_FAILURE:
            case ClusterVipStatus.CLUSTER_VIP_FAILED:
                return 'danger';

            case ClusterVipStatus.CLUSTER_VIP_ACTIVE:
                return 'success';

            default:
                return '';
        }
    }
}
