/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createReducer, on } from '@ngrx/store';
import * as UserAccountActions from './user-account.actions';
import { initialState, IUserAccountState } from './user-account.state';

export const userAccountReducer = createReducer(
    initialState,
    on(
        UserAccountActions.fetchUserAccount,
        (state: IUserAccountState): IUserAccountState => ({
            ...state,
            loaded: false,
            loading: true,
        }),
    ),
    on(
        UserAccountActions.fetchUserAccountSuccess,
        (state: IUserAccountState, { payload }): IUserAccountState => ({
            ...state,
            userAccount: {
                ...payload,
            },
            loading: false,
            loaded: true,
        }),
    ),
    on(
        UserAccountActions.saveUserAccountSuccess,
        (state: IUserAccountState, { payload }): IUserAccountState => ({
            ...state,
            userAccount: {
                ...payload,
            },
            loading: false,
        }),
    ),
    on(
        UserAccountActions.fetchUserAccountError,
        UserAccountActions.saveUserAccountError,
        (state: IUserAccountState): IUserAccountState => ({
            ...state,
            loading: false,
        }),
    ),
);
