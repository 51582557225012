/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    scale as d3Scale,
    select as d3Select,
    svg as d3Svg,
} from 'd3v3';

const d3 = {
    scale: d3Scale,
    select: d3Select,
    svg: d3Svg,
};

angular.module('aviApp').factory('ScatterPlotter', function() {
    const Chart = function(elm, data, settings) {
        // ------------Elm + Chart sizing setup -------------//
        let padding;

        if (settings.small) {
            padding = {
                top: 7,
                left: 7,
                right: 7,
                bottom: 7,
            };
        } else {
            padding = {
                top: 30,
                right: 20,
                bottom: 40,
                left: 50,
            };
        }

        this.small = settings.small;

        let w = (settings.width || elm.width()) - padding.left - padding.right;
        let h = (settings.height || elm.height()) - padding.top - padding.bottom;

        // adding in defaults so that it always shows up + makes it easier to debug;
        if (w <= 0) {
            w = 300;
        }

        if (h <= 0) {
            h = 300;
        }

        this.h = h;
        this.w = w;
        this.padding = padding;

        this.elm = elm;

        this.el = d3.select(elm[0]);
        this.chartarea = this.el
            .append('svg')
            .attr('class', 'chart')
            .attr('width', w + padding.left + padding.right)
            .attr('height', h + padding.top + padding.bottom);

        this.g = this.chartarea
            .append('g')
            .attr('transform', `translate(${padding.left},${padding.top})`);

        // ------- Scales    -------///
        const xMax = data.xMax();
        const yMax = data.yMax();

        this.y = d3.scale.linear()
            .domain([0, yMax * 1.1 || 1])
            .range([this.h, -1]);
        this.x = d3.scale.linear()
            .domain([0, xMax * 1.1 || 1])
            .range([0, this.w]);

        // --------Axis Settings
        const as = {};

        as.xTicks = 5;
        as.xTickPadding = 5;
        as.xTickSize = 1;
        as.yTicks = Math.ceil(this.h / 50) + 1;
        as.yTickPadding = 10;
        as.yTickSize = -1;

        as.xOrient = 'bottom';
        as.yOrient = 'left';

        this.axisSettings = as;

        this.createAxis(data);
    };

    Chart.prototype.createAxis = function(data) {
        const as = this.axisSettings;

        if (!as) {
            return;
        }

        const { h, w } = this;

        // Creating the axis
        const xAxis = d3.svg.axis()
            .scale(this.x)
            .ticks(as.xTicks)
            .tickSize(as.xTickSize)
            .tickPadding(as.xTickPadding)
            .orient(as.xOrient);
        const yAxis = d3.svg.axis()
            .scale(this.y)
            .ticks(as.yTicks)
            .tickSize(as.yTickSize)
            .orient(as.yOrient);

        // getting rid of the ticks and all that
        if (this.small) {
            xAxis.tickFormat('');
            xAxis.tickSize(0);
            yAxis.tickFormat('');
            yAxis.tickSize(0);
        }

        // creating the axes on the graph
        const xline = this.g.append('g')
            .style('shape-rendering', 'crisp-edges')
            .attr('class', 'x axis')
            .attr('transform', `translate(${0},${h})`)
            .classed('axis', true)
            .call(xAxis);
        const yline = this.g.append('g')
            .style('shape-rendering', 'crisp-edges')
            .attr('class', 'y axis')
            .attr('transform', `translate(${as.yTickSize},${0})`)
            .classed('axis', true)
            .call(yAxis);

        // Adding in the lines for the graphs if it's not a small plot
        if (!this.small) {
            xline
                .append('text')
                .attr('class', 'axis-label')
                .attr('x', w)
                .attr('y', 26)
                .style('text-anchor', 'end')
                .text(data.metrics.xMetricTitle || '');
            yline
                .attr('transform', 'translate(-1, 0)')
                .append('text')
                .attr('transform', 'rotate(-90)')
                .attr('class', 'axis-label')
                .text(data.metrics.yMetricTitle || '')
                .attr('x', 0)
                .attr('y', -26)
                .style('text-anchor', 'end');
            // The code for x and y looks messed up, but rotating the text
            // means that we need to switch our orientation
        } else {
            xline.classed('axis no-ticks', true);
            yline.classed('axis no-ticks', true);
        }
    };

    Chart.prototype.plot = function(points) {
        const { x, y } = this;

        let width;
        let height;

        if (this.small) {
            width = 7;
            height = 7;
        } else {
            width = 13;
            height = 13;
        }

        this.len = width;

        this.dots = this.g.selectAll('.dot')
            .data(points)
            .enter()
            .append('rect')
            .attr('class', function(d) {
                return `sel-barchart dot healthscore-colors ${d.className}`;
            })
            .attr('rx', 1)
            .attr('ry', 1)
            .attr('x', function(d) {
                // really should be x(d.x) - width / 2;
                return x(d.x);
            })
            .attr('y', function(d) {
                // really should be y(d.y) - height / 2
                return y(d.y) - height;
            })
            .attr('width', width)
            .attr('height', height);

        return this.dots;
    };

    return Chart;
});
