/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-score';
const componentName = 'avi-health-score-footer';
const prefix = `${moduleName}.${componentName}`;

export const viewHealthLabel = `${prefix}.viewHealthLabel`;

export const ENGLISH = {
    [viewHealthLabel]: 'View Health',
};
