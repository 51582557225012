/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    IWafConfig,
    IWafProfile,
} from 'generated-types';

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { WafProfile } from 'object-types';
import * as globalL10n from 'global-l10n';
import { WafContentTypeMappingConfigItem } from './waf-content-type-mapping-config-item.factory';

import { WafDataFileConfigItem } from './waf-data-file.config-item.factory';

const { ...globalL10nKeys } = globalL10n;

/**
 * Interface for WafProfileConfig as data.
 */
interface IWafProfileData {
    config: IWafProfileConfig,
}

/**
 * Custom Waf Profile Type.
 */
type TWafProfilePartial = Omit<IWafProfile, 'files'>;

/**
 * Interface for IWafProfileConfig
 */
interface IWafProfileConfig extends TWafProfilePartial {
    files: RepeatedMessageItem<WafDataFileConfigItem>;
}

/**
 * @description WafProfile Item Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class WafProfileItem extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    /**
     * Data of WafProfileItem.
     */
    public data: IWafProfileData;

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafprofile',
            windowElement: 'lazy-load',
            objectType: WafProfile,
            permissionName: AviPermissionResource.PERMISSION_WAFPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * Getter for files object in WafProfileItem.
     */
    public get files(): RepeatedMessageItem<WafDataFileConfigItem> {
        return this.getConfig().files;
    }

    /**
     * Getter for WafConfig from WafProfileItem.
     */
    public get wafConfig(): IWafConfig {
        const wafConfig = this.getConfig().config.getConfig();

        return wafConfig as IWafConfig;
    }

    /**
     * Getter for WafContentType Mappings from WafProfileItem.
     */
    public get wafContentTypeMapping(): RepeatedMessageItem<WafContentTypeMappingConfigItem> {
        const wafContentTypeMapping = this.getConfig().config.getConfig().content_type_mappings;

        return wafContentTypeMapping as RepeatedMessageItem<WafContentTypeMappingConfigItem>;
    }

    /**
     * Helper function to add WafData Files.
     */
    public addWafDataFile(): void {
        this.addChildMessageItem({
            field: 'files',
        });
    }

    /**
     * Helper function to edit WafData Files.
     */
    public editWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.editChildMessageItem({
            field: 'files',
            messageItem: wafDataFileConfigItem,
        });
    }

    /**
     * Helper function to remove datafile from config.
     */
    public removeWafDataFile(wafDataFileConfigItem: WafDataFileConfigItem): void {
        this.config.files.removeByMessageItem(wafDataFileConfigItem);
    }

    /**
     * Method used to get Modal for lazy loaded component.
     */
    // eslint-disable-next-line class-methods-use-this
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            WafProfileModalComponent,
        } = await import(
            /* webpackChunkName: "waf-profile-modal" */
            'ng/lazy-loaded-components/modals/waf-profile-modal/waf-profile-modal.component'
        );

        return WafProfileModalComponent as Type<Component>;
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WafProfile.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.wafProfileLabel);
    }
}
