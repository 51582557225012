/**
 * Notifications module
 * @module NotificationsModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import {
    snmpController,
    syslogController,
} from '.';

const notificationsModule = angular.module('avi/notifications');

const controllers = [
    {
        controller: snmpController,
        name: 'SnmpController',
    },
    {
        controller: syslogController,
        name: 'SyslogController',
    },
];

controllers.forEach(({ name, controller }) => notificationsModule.controller(name, controller));
