/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';

/**
 * @description AlertMetrics item.
 * @author Dhanashree Sathelkar, Shahab Hashmi
 */
export class AlertMetricsItem extends Item {
    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'analytics/metric_id',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public isEditable(): boolean {
        return false;
    }
}
