/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    GCPNetworkConfigMode,
    IGCPInBandManagement,
    IGCPNetworkConfig,
    IGCPOneArmMode,
    IGCPTwoArmMode,
} from 'generated-types';

import { GCPNetworkConfig } from 'object-types';

type TGcpNetworkConfigPartial = Omit<IGCPNetworkConfig, 'inband' | 'one_arm' | 'two_arm'>;

interface IGcpNetworkConfiguration extends TGcpNetworkConfigPartial {
    inband?: MessageItem<IGCPInBandManagement>;
    one_arm?: MessageItem<IGCPOneArmMode>;
    two_arm?: MessageItem<IGCPTwoArmMode>;
}

/**
 * @description
 *
 *   GCP NetworkConfig MessageItem.
 *
 * @author Sarthak kapoor
 */
export class GCPNetworkConfigConfigItem extends MessageItem<IGcpNetworkConfiguration> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GCPNetworkConfig,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Resets VPC Network Name when user changes the VPC Project ID.
     */
    public resetVpcNetworkName(): void {
        const { inbandConfig } = this;

        delete inbandConfig.vpc_network_name;
    }

    /**
     * Resets VPC Subnet Name when user changes the VPC Network Name.
     */
    public resetVpcSubnetName(): void {
        const { inbandConfig } = this;

        delete inbandConfig.vpc_subnet_name;
    }

    /**
     * Resets Data VPC Network Name when user changes the Data VPC Project ID.
     */
    public resetDataVpcNetworkName(): void {
        const { oneArmConfig } = this;

        delete oneArmConfig.data_vpc_network_name;
    }

    /**
     * Resets Data VPC Subnet Name when user changes the Data VPC Network Name.
     */
    public resetDataVpcSubnetName(): void {
        const { oneArmConfig } = this;

        delete oneArmConfig.data_vpc_subnet_name;
    }

    /**
     * Resets One Arm Management VPC Network Name when user changes the Management VPC Project ID.
     */
    public resetOneArmManagementVpcNetworkName(): void {
        const { oneArmConfig } = this;

        delete oneArmConfig.management_vpc_network_name;
    }

    /**
     * Resets One Arm Management VPC Subnet Name when user changes the Management VPC Network Name.
     */
    public resetOneArmManagementVpcSubnetName(): void {
        const { oneArmConfig } = this;

        delete oneArmConfig.management_vpc_subnet_name;
    }

    /**
     * Resets Front End Data VPC Network Name when user changes,
     * the Front End Data VPC Project ID.
     */
    public resetFeDataVpcNetworkName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.frontend_data_vpc_network_name;
    }

    /**
     * Resets Front End Data VPC Subnet Name when user changes,
     * the Front End Data VPC Network Name.
     */
    public resetFeDataVpcSubnetName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.frontend_data_vpc_subnet_name;
    }

    /**
     * Resets Back End Data VPC Network Name when user changes,
     * the Back End Data VPC Project ID.
     */
    public resetBeDataVpcNetworkName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.backend_data_vpc_network_name;
    }

    /**
     * Resets Back End Data VPC Subnet Name when user changes,
     * the Back End Data VPC Network Name.
     */
    public resetBeDataVpcSubnetName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.backend_data_vpc_subnet_name;
    }

    /**
     * Resets Two Arm Management VPC Network Name when user changes the Management VPC Project ID.
     */
    public resetTwoArmManagementVpcNetworkName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.management_vpc_network_name;
    }

    /**
     * Resets Two Arm Management VPC Subnet Name when user changes the Management VPC Network Name.
     */
    public resetTwoArmManagementVpcSubnetName(): void {
        const { twoArmConfig } = this;

        delete twoArmConfig.management_vpc_subnet_name;
    }

    /**
     * Updates the network_config config object.
     */
    public updateConfigObject(config: GCPNetworkConfigMode): void {
        this.updateConfig({
            config,
        });
    }

    /**
     * @override
     * Need to set a default value for config property in NetworkConfig in case,
     * no value is set at the back end.
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IGcpNetworkConfiguration> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};
        const { ...defaults } = defaultConfig;

        return {
            ...defaults,
            config: GCPNetworkConfigMode.INBAND_MANAGEMENT,
        };
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'inband',
            'one_arm',
            'two_arm',
        ];
    }

    /**
     * @override
     * Using this hook to remove the unused objects from Network Config object before save.
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        switch (config.config) {
            case GCPNetworkConfigMode.INBAND_MANAGEMENT: {
                delete config.one_arm;
                delete config.two_arm;

                break;
            }

            case GCPNetworkConfigMode.ONE_ARM_MODE: {
                delete config.inband;
                delete config.two_arm;

                break;
            }

            case GCPNetworkConfigMode.TWO_ARM_MODE: {
                delete config.inband;
                delete config.one_arm;
            }
        }
    }

    /**
     * Returns inband config object.
     */
    private get inbandConfig(): IGCPInBandManagement {
        const { inband } = this.config;

        return inband.config;
    }

    /**
     * Return one_arm config object.
     */
    private get oneArmConfig(): IGCPOneArmMode {
        const { one_arm: oneArm } = this.config;

        return oneArm.config;
    }

    /**
     * Returns two_arm config object.
     */
    private get twoArmConfig(): IGCPTwoArmMode {
        const { two_arm: twoArm } = this.config;

        return twoArm.config;
    }
}
