<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        clrInput
                        id="name"
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeDropdownLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="ipamDnsTypeOptions"
                            [(ngModel)]="editable.config.type"
                            placeholder="{{ l10nKeys.typeDropdownPlaceholder | vtranslate }}"
                            [disabled]="editable.id"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        
        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.sectionTitleInfoblox | vtranslate }}"
            *ngIf="editable.typeContains(ipamDnsTypeEnum.IPAMDNS_TYPE_INFOBLOX)"
        >
            <ipam-dns-infoblox-profile-config
                [editable]="editable.currentProfile"
                [type]="editable.type"
            ></ipam-dns-infoblox-profile-config>
        </full-modal-tab-section>

        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.aviVantageSectionHeader | vtranslate }}"
            *ngIf="editable.typeContains(ipamDnsTypeEnum.IPAMDNS_TYPE_INTERNAL)"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.aviVantageSectionHeader | vtranslate }}</h3>
                <clr-checkbox-container *ngIf="editable.isType(ipamDnsTypeEnum.IPAMDNS_TYPE_INTERNAL)">
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            name="allocate_ip_in_vrf"
                            [(ngModel)]="editable.config.allocate_ip_in_vrf"
                            id="allocate_ip_in_vrf"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="allocate_ip_in_vrf"
                            for="allocate_ip_in_vrf"
                        >
                            {{ l10nKeys.allocateIpInVrfCheckboxLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <ipam-dns-internal-profile-config
                    [editable]="editable.currentProfile"
                    [type]="editable.type"
                ></ipam-dns-internal-profile-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.sectionTitleCustom | vtranslate }}"
            *ngIf="editable.typeContains(ipamDnsTypeEnum.IPAMDNS_TYPE_CUSTOM)"
        >
            <ipam-dns-custom-profile-config 
                [editable]="editable.currentProfile"
                [type]="editable.type"
            ></ipam-dns-custom-profile-config>
        </full-modal-tab-section>

        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.sectionTitleOpenstack | vtranslate }}"
            *ngIf="editable.isType(ipamDnsTypeEnum.IPAMDNS_TYPE_OPENSTACK)"
        >
            <ipam-dns-openstack-profile-config [editable]="editable.currentProfile"></ipam-dns-openstack-profile-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleAzure | vtranslate }}"
            *ngIf="editable.typeContains(ipamDnsTypeEnum.IPAMDNS_TYPE_AZURE_DNS)"
        >
            <ipam-dns-azure-profile-config [editable]="editable.currentProfile"></ipam-dns-azure-profile-config>
        </full-modal-tab-section>

        <full-modal-tab-section
            tabTitle="{{ l10nKeys.sectionTitleAws | vtranslate }}"
            *ngIf="editable.typeContains(ipamDnsTypeEnum.IPAMDNS_TYPE_AWS)"
        >
            <ipam-dns-aws-profile-config 
                [editable]="editable.currentProfile"
                [proxyConfigItem]="editable.proxyConfiguration"
                [type]="editable.type"
            ></ipam-dns-aws-profile-config>
        </full-modal-tab-section>
    </form>
</full-modal-config>
