<div provideParentForm>
    <avi-data-grid
        [config]="infobloxUsableSubnetsGridConfig"
        [rows]="usableSubnets.config"
        gridTitle="{{ l10nKeys.usableSubnetsGridHeader | vtranslate : usableSubnets.count}}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addUsableSubnet()"
                [disabled]="!connected"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #ipv4SubnetTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ ipv4UsableSubnetRowPrefix + index }}"
                id="{{ ipv4UsableSubnetRowPrefix + index }}"
                [(ngModel)]="row.config.subnet.subnet"
                (ngModelChange)="handleUsableSubnetChange()"
                [options]="ipv4SubnetOptions"
                placeholder="{{ l10nKeys.ipv4SelectPlaceholder | vtranslate }}"
            ></avi-dropdown>
        </ng-template>

        <ng-template
            #ipv6SubnetTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ ipv6UsableSubnetRowPrefix + index }}"
                id="{{ ipv6UsableSubnetRowPrefix + index }}"
                [(ngModel)]="row.config.subnet6.subnet"
                (ngModelChange)="handleUsableSubnetChange()"
                [options]="ipv6SubnetOptions"
                placeholder="{{ l10nKeys.ipv6SelectPlaceholder | vtranslate }}"
            ></avi-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
