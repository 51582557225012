/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'host-hdr';
const prefix = `${moduleName}.${componentName}`;

export const hostHeaderLabel = `${prefix}.hostHeaderLabel`;
export const criteriaLabel = `${prefix}.criteriaLabel`;
export const enterCriteriaLabel = `${prefix}.enterCriteriaLabel`;
export const valueLabel = `${prefix}.valueLabel`;
export const enterValuesLabel = `${prefix}.enterValuesLabel`;
export const addValueLabel = `${prefix}.addValueLabel`;

export const ENGLISH = {
    [hostHeaderLabel]: 'Host Header',
    [criteriaLabel]: 'Criteria',
    [enterCriteriaLabel]: 'Enter Criteria',
    [valueLabel]: 'Value',
    [addValueLabel]: 'Add Value',
    [enterValuesLabel]: 'Enter Values',
};
