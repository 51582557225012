/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const TimeFormatterServiceProvider = {
    deps: [$injector],
    provide: '$filter',
    useFactory: (injector: Injector): any => injector.get('$filter'),
};

// TODO AV-159353 remove old transformer.
export const LogDataTransformerServiceProvider = {
    deps: [$injector],
    provide: 'logDataTransform',
    useFactory: (injector: Injector): any => injector.get('logDataTransform'),
};
