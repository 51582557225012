/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/switch.less';

angular.module('aviApp').directive('switch', function() {
    return {
        scope: {
            data: '=',
            ngDisabled: '=',
        },
        restrict: 'E',
        template: '<div class="switch-state" ng-click="onClick()" ng-class="{enabled: data}">' +
            '<div class="true" ng-class="{selected: data}"></div>' +
            '<div class="false" ng-class="{selected: !data}"></div>' +
            '</div>',
        link(scope) {
            scope.onClick = function() {
                if (!scope.ngDisabled) {
                    scope.data = !scope.data;
                }
            };
        },
    };
});
