/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    AfterViewInit,
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { IVsLogsDefaultSignpostData } from 'ng/modules/vs-logs/vs-logs.types';

import {
    customTemplates,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import * as l10n from './vs-logs-signpost-custom-e2e-timing-data-grid.l10n';
import './vs-logs-signpost-custom-e2e-timing-data-grid.component.less';
import { VsLogsSignpostStore } from '../../../vs-logs-signpost.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for log signpost e2e timing data grid section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-e2e-timing-data-grid',
    templateUrl: './vs-logs-signpost-custom-e2e-timing-data-grid.component.html',
})
export class VsLogsSignpostCustomEndToEndTimingDataGridComponent implements AfterViewInit {
    /**
     * Datagrid template for field name.
     */
    @ViewChild('nameFieldTemplateRef')
    public readonly nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for field progress bar.
     */
    @ViewChild('progressBarFieldTemplateRef')
    public readonly progressBarFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for field percentage.
     */
    @ViewChild('percentageFieldTemplateRef')
    public readonly percentageFieldTemplateRef: TemplateRef<HTMLElement>;

    public logListGridConfig: IAviDataGridConfig;

    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any; // TODO AV-159345 update type.

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;
        const configKey = customTemplates.e2eTiming;

        this.logListGridConfig = {
            layout: {
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: templatesConfiguration[configKey].name,
                    id: 'name',
                    templateRef: this.nameFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsNumberLabel),
                    id: 'count',
                    transform: (row: IVsLogsDefaultSignpostData): number => row.count,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsPercentageLabel),
                    id: 'percentage',
                    templateRef: this.percentageFieldTemplateRef,
                },
                {
                    label: '',
                    id: 'percentage-bar',
                    templateRef: this.progressBarFieldTemplateRef,
                },
            ],
        };
    }
}
