/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { VsLogCinematicStore } from '../vs-log-cinematic.store';

import * as l10n from './vs-log-cinematic-section-significance.l10n';

import './vs-log-cinematic-section-significance.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying Significance section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-significance',
    templateUrl: './vs-log-cinematic-section-significance.component.html',
})
export class VsLogCinematicSectionSignificanceComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the significance field.
     */
    @ViewChild('significanceFieldTemplateRef')
    public significanceFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly significantLog$ = this.vsLogCinematicStore.significantLog$;

    public significanceGridConfig: IAviDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        // needed to be set on init for the data grid to setup inital props
        this.significanceGridConfig = {
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
            isGridExpandable: true,
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        // needed in ngAfterViewInit to fetch the templateRef from ViewChild
        this.significanceGridConfig.fields = [
            {
                label: this.l10nService.getMessage(l10nKeys.significanceLabel),
                icon: 'bubble-exclamation',
                id: 'significance',
                templateRef: this.significanceFieldTemplateRef,
            },
        ];
    }
}
