/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import { Component, OnInit } from '@angular/core';

import {
    customTemplates,
    defaultSignpostParams,
    e2eTimingGroupByKeys,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { IVsLogsCombinedRequestUpdatableParams } from 'ng/modules/vs-logs/vs-logs.types';
import {
    VirtualServiceStateService,
} from 'ng/modules/virtual-service/services/virtual-service-state.service';
import { defaultPageSize } from 'ng/modules/vs-logs/constants/default-values.constants';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-custom-e2e-timing.component.less';

const E2E_AVERAGE_REQUEST_ID = 'average';
const E2E_GRID_RESULT_REQUEST_ID = 'e2eResult';

/**
 * @description Signpost component for log signpost custom end to end timing section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-e2e-timing',
    templateUrl: './vs-logs-signpost-custom-e2e-timing.component.html',
})
export class VsLogsSignpostCustomEndToEndTimingComponent implements OnInit {
    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any; // TODO AV-159345 update type.

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        vsStateService: VirtualServiceStateService,
    ) {
        this.vs = vsStateService.virtualService;
    }

    /** @override */
    public ngOnInit(): void {
        this.switchView(e2eTimingGroupByKeys.totalTime);
    }

    /**
     * Switch view for end to end timing signpost and get data for new field.
     */
    public switchView(key: string): void {
        const fullSignpostParams = this.getEndToEndTimingRequestParams(key);

        this.vsLogsSignpostStore.getVsLogSignpostCombinedData(fullSignpostParams);
    }

    public trackbyIndex(index: number): number {
        return index;
    }

    /**
     * Get request params for end to end timing signpost.
     */
    private getEndToEndTimingRequestParams(key: string): IVsLogsCombinedRequestUpdatableParams[] {
        const template = templatesConfiguration[customTemplates.e2eTiming];
        const { query, page_size: pageSize } = template;
        const vsType = this.vs.isHTTP() ? '7' : '4'; // Number 7 and 4 stands for L7 and L4 VS type.
        const cols = query[`average${vsType}`];
        const fullRequestsParams: IVsLogsCombinedRequestUpdatableParams[] = [];

        fullRequestsParams.push({
            requestId: E2E_AVERAGE_REQUEST_ID,
            requestParams: {
                ...defaultSignpostParams,
                cols,
                groupby: 'full',
                orderby: null,
                page_size: pageSize || defaultPageSize,
            },
        });

        fullRequestsParams.push({
            requestId: E2E_GRID_RESULT_REQUEST_ID,
            requestParams: {
                ...defaultSignpostParams,
                groupby: key,
                orderby: key,
                step: key === e2eTimingGroupByKeys.clientRtt ||
                    key === e2eTimingGroupByKeys.serverRtt ? '10' : '100',
                expstep: true,
            },
        });

        return fullRequestsParams;
    }
}
