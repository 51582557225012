<div class="async-file-upload">
    <div
        [hidden]="!error"
        class="async-file-upload__error"
    >
        <avi-alert-group alertType="danger">
            <span> {{ error }} </span>
        </avi-alert-group>
    </div>

    <div class="async-file-upload__file-upload-container">
        <file-upload
            name="async-file-upload"
            class="async-file-upload__file-upload"
            [(ngModel)]="value"
            [fileName]="fileName"
            [decodeFile]="decodeFile"
            [maxFileSize]="maxFileSize"
            [disabled]="disabled"
            buttonLabel="{{ l10nKeys.browseBtnLabel | vtranslate }}"
            buttonClassName="btn btn-outline btn-sm"
            (fileNameChange)="handleFileNameChange($event)"
        ></file-upload>

        <div
            class="async-file-upload__upload-button"
            [hidden]="busy"
        >
            <cds-button
                size="sm"
                [disabled]="disabled || uploadDisabled"
                (click)="handleFileUpload()"
            >
                {{ l10nKeys.uploadBtnLabel | vtranslate }}
            </cds-button>
        </div>
        <div
            class="async-file-upload__upload-button"
            *ngIf="busy"
        >
            <cds-button
                size="sm"
                [loadingState]="loadingState"
            >
                {{ l10nKeys.uploadBtnLabel | vtranslate }}
            </cds-button>
        </div>
    </div>

    <div
        [hidden]="!showProgress"
        class="async-file-upload__progress clr-wrapper"
    >
        <clr-progress-bar
            id="async-file-upload-progress-bar"
            [clrValue]="uploadPercentage"
        ></clr-progress-bar>
        <span class="async-file-upload__progress-display-value">
            {{ progressDisplayValue }}
        </span>
    </div>
</div>
