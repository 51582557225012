/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module DialogModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { DownloadFileService } from 'ng/modules/core/services/download-file.service';
import { ObjectDiffService } from 'ng/modules/core/services/object-diff/object-diff.service';
import { ClipboardService } from 'ng/modules/core/services/clipboard.service';
import * as l10n from './json-viewer.l10n';
import './json-viewer.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Json Viewer modal component.
 * To View, Copy and Download formatted Config.
 *
 * @author Abhinesh Gour
 */
@Component({
    selector: 'json-viewer',
    templateUrl: './json-viewer.component.html',
})
export class JsonViewerComponent implements OnInit {
    /**
     * Config Object of the item.
     */
    @Input()
    public config: Record<string, any>;

    /**
     * Default Config Object of the item.
     */
    @Input()
    public defaultConfig: Record<string, any>;

    /**
     * Flag to determine if item is modified, if true show warning to user.
     */
    @Input()
    public modified: boolean;

    /**
     * Function to be called when the modal-close button is clicked.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Model value for show Defaults checkbox.
     * true  - Displays the data to be saved(config).
     * false - Displays the values that are changed/different
     *         in config compared to default config.
     */
    public showDefaults = false;

    /**
     * Config Object of the item to de displayed.
     */
    public displayConfig: Record<string, any>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly downloadFileService: DownloadFileService,
        private readonly objectDiffService: ObjectDiffService,
        private readonly clipboardService: ClipboardService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Function to copy config to clipboard.
     */
    public copyToClipboard(): void {
        // stringify and format data.
        const stringifiedData = JSON.stringify(this.displayConfig, null, 4);

        this.clipboardService.copy(stringifiedData);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.onShowDefaultsChange();
    }

    /**
     * Function to download config as Json file.
     */
    public downloadJsonFile(): void {
        this.downloadFileService.downloadFile(this.displayConfig, `${this.config.name}.json`);
    }

    /**
     * Funtion to toggle showDefaults and set the display config object.
     */
    public onShowDefaultsChange(): void {
        this.showDefaults = !this.showDefaults;

        if (!this.showDefaults) {
            this.displayConfig = this.objectDiffService.getDiff(this.config, this.defaultConfig);
        } else {
            this.displayConfig = { ...this.config };
        }
    }

    /**
     * Handler for Close button click.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
