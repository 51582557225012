<match-wrapper
    class="bot-type-matcher"
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div
        class="bot-type-matcher"
        provideParentForm
    >
        <enum-radio-buttons
            [noMarginTop]="true"
            enum="MatchOperation"
            name="bot-type-matcher-op-{{ matchIndex }}"
            [(ngModel)]="editable.config.op"
            required
        ></enum-radio-buttons>

        <div class="bot-type-matcher__sub-field">
            <label
                aviLabelWithTooltip
                for="client-types"
                objectType="{{ botTypeMatcherObjectType }}"
                fieldName="client_types"
                required
            >
                {{ l10nKeys.clientTypesLabel | vtranslate }}
            </label>
            <avi-dropdown
                name="client-types"
                id="client-types"
                [(ngModel)]="editable.config.client_types"
                [options]="botTypeMatcherOptions"
                placeholder="{{ l10nKeys.selectClientTypesPlaceholder | vtranslate }}"
                [multiple]="true"
                required
            ></avi-dropdown>
        </div>
    </div>
</match-wrapper>
