/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import {
    forkJoin,
    from,
    Observable,
    of,
    pipe,
    Subscription,
    UnaryFunction,
} from 'rxjs';
import {
    catchError,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
    IVsLogsRequest,
    IVsLogsUpdatableParams,
    TVsLogsApiResponseData,
    TVsLogsCombinedApiResponseData,
    TVsLogsCombinedRecursiveRequest,
    TVsLogsRecursiveRequest,
} from '../vs-logs.types';
import {
    LOGS_MAX_REQUEST_COUNT,
    LOGS_REQUEST_INITIAL_TIMEOUT,
    LOGS_REQUEST_MAX_TIMEOUT,
    VsLogsApiRequestService,
} from './vs-logs-api-request.service';

/**
 * Effect creator takes in any Observable and a function or effect that performs an action on
 * latest value of the Observable.
 *
 * Returns a pipe to be passed into an Effect constructor.
 */
type TSelectorTapEffectCreator = <T>(
    selector$: Observable<T>,
    next: (value: T) => void | Subscription,
) => UnaryFunction<Observable<void>, Observable<[void, T]>>;

/**
 * Effect creator takes in an Observable of a component's API params and an effect to load data for
 * that component.
 *
 * Returns a pipe to be passed into an Effect constructor.
 */
type TRefreshEffectCreator = <T extends IVsLogsUpdatableParams>(
    apiParams$: Observable<T>,
    requestEffect: (apiParams: T) => void,
) => UnaryFunction<Observable<void>, Observable<[void, T]>>;

/**
 * Request effect creator function takes in needed functionality
 * and returns a function as expected by the Component Store effect constructor.
 */
type TVsLogsRequestEffectCreator = (
    requestId: string,
    startLoading: () => void,
    handleApiData: (data: TVsLogsApiResponseData) => void,
    // Uncertain error types from API
    // Doesn't matter though since we present a generic error message to the user.
    // Err object is only used for dev logger.
    handleApiError: (err: any) => void,
    handleApiComplete: () => void,
    // provide a condition to be run on the data to determine whether the request loop
    // should be ended
    endingCondition?: (data: TVsLogsApiResponseData) => boolean,
) => (apiParams$: Observable<IVsLogsUpdatableParams>) => Observable<TVsLogsApiResponseData>;

/**
 * Recursive combined request effect creator function takes in a reference
 * to a component store and returns a recursive API request effect on that store.
 */
type TVsLogsCombinedRecursiveRequestEffectCreator = <T extends object>(
    componentStore: ComponentStore<T>,
    startLoading: () => void,
    stopLoading: () => void,
    handleApiData: (data: TVsLogsCombinedApiResponseData) => void,
    handleApiError: (err: any) => void,
    // If anything is to be done once effect loop completes
    handleApiLoopComplete: (isTimedOut: boolean) => void,
) => (config: TVsLogsCombinedRecursiveRequest) => Subscription;

/**
 * Recursive request effect creator function takes in a reference
 * to a component store and returns a recursive API request effect on that store.
 */
type TVsLogsRecursiveRequestEffectCreator = <T extends object>(
    componentStore: ComponentStore<T>,
    startLoading: () => void,
    stopLoading: () => void,
    handleApiData: (data: TVsLogsApiResponseData) => void,
    handleApiError: (err: any) => void,
    // if anything is to be done once effect loop completes
    handleApiLoopComplete: (isTimedOut: boolean) => void,
) => (config: TVsLogsRecursiveRequest) => Subscription;

/**
 * @description
 *  Provides constructors for effects that share functionality
 *  across different log component's stores.
 * @author Alex Klyuev, Suraj Kumar, Zhiqian Liu
 */
@Injectable()
export class VsLogsEffectsService {
    constructor(private readonly vsLogsApiRequestService: VsLogsApiRequestService) { }

    /**
     * Create effects that can grab the latest value from a specified selector / Observable
     * and perform some function on it.
     */
    public static createSelectorTapEffect: TSelectorTapEffectCreator = (selector$, next) => pipe(
        withLatestFrom(selector$),
        tap(valueContainer => {
            const value = valueContainer[1];

            next(value);
        }),
    );

    /**
     * Create effects that will refresh the data of the given component.
     */
    public static createRefreshEffect: TRefreshEffectCreator = (apiParams$, requestEffect) => pipe(
        withLatestFrom(apiParams$),
        tap(apiParamsContainer => {
            const apiParams = apiParamsContainer[1];

            requestEffect(apiParams);
        }),
    );

    /** ---------------------------------- API Request Effects ---------------------------------- */

    /**
     * Logs API operates with a request loop.
     *
     * Each request returns a property percent_remaining. If this value is greater than 0,
     * we make another request to continue to get the remaining data with an increased timeout.
     *
     * This loop continues until all data is returned or a max number of calls is reached.
     *
     * On UI side, we have two different approaches to this timeout.
     *
     * One is to let the request loop complete, then update the state once, at the end.
     * The loop for this approach is at the API request level, in VsLogsApiRequestService.
     * constructors for this approach.
     *
     * The other approach is to update the state upon every API return.
     * The loop for this takes place at the effect level.
     * createVsLogsRecursiveRequestEffect and createVsLogsCombinedRecursiveRequestEffect are the
     * effects for this approach.
     */

    /**
     * Create effects to be used for VS Logs Component Stores to request the API
     * and update their states accordingly.
     */
    public createVsLogsRequestEffect: TVsLogsRequestEffectCreator = (
        requestId,
        startLoading,
        handleApiData,
        handleApiError,
        handleApiComplete,
        endingCondition,
    ) => apiParams$ => apiParams$.pipe(
        // if a previous request hasn't completed yet, the new request will cancel it
        // switchMap handles this by only emitting the last Observable output
        switchMap(apiParams => {
            this.vsLogsApiRequestService.cancelRequest(requestId);
            startLoading();

            // emit the next Observable by making the request
            return from(this.vsLogsApiRequestService.requestLogsWithTimeoutIncrease(
                apiParams,
                requestId,
                endingCondition,
            )).pipe(
                // handle API response
                tapResponse(
                    handleApiData,
                    handleApiError,
                    handleApiComplete,
                ),
            );
        }),
    );

    /**
     * Create recursive combined request effects to call logs API.
     *
     * This function is a constructor that returns an effect on the specified Component Store.
     * The resulting effect will make combined request to the logs API for that component's data.
     * Upon each response return, it will update the component's state.
     *
     * If percent_remaining > 0 on the returned data, it will double the timeout
     * (until the max timeout value is reached) and make another call by recursively calling itself.
     *
     * Once the max number of calls is reached the loop will end.
     */

    // eslint-disable-next-line max-len
    public createVsLogsCombinedRecursiveRequestEffect: TVsLogsCombinedRecursiveRequestEffectCreator = (
        componentStore,
        startLoading,
        stopLoading,
        handleApiData,
        handleApiError,
        handleApiLoopComplete,
    ) => {
        // Create a recursive effect using the reference to the component store.
        const combinedRequestEffect = componentStore.effect<TVsLogsCombinedRecursiveRequest>(
            combinedRequest$ => combinedRequest$.pipe(
                // If the previous request hasn't completed yet, the new request will cancel it
                // switchMap handles this by only emitting the last Observable output.
                switchMap(combinedRequest => {
                    const requestsHash = {};
                    let isErrorPresent = false;

                    if (!combinedRequest.config) {
                        combinedRequest.config = {};
                    }

                    const { config } = combinedRequest;

                    if (!config.requestCount) {
                        config.requestCount = 1;
                    }

                    if (config.requestCount === 1) {
                        startLoading();
                    }

                    combinedRequest.requests.forEach((request: IVsLogsRequest, index) => {
                        const apiParams = request.requestParams;

                        if (!apiParams.timeout) {
                            apiParams.timeout = LOGS_REQUEST_INITIAL_TIMEOUT;
                        } else if (apiParams.timeout !== LOGS_REQUEST_MAX_TIMEOUT) {
                            apiParams.timeout *= 2;
                        }

                        this.vsLogsApiRequestService.cancelRequest(request.requestId);

                        requestsHash[request.requestId] = from(
                            this.vsLogsApiRequestService.requestLogs(
                                apiParams,
                                request.requestId,
                            ),
                        ).pipe(
                            // ForkJoin (see below) does not pick up on errors within its
                            // member observables, therefore must handle errors within each one.
                            catchError(err => {
                                isErrorPresent = true;

                                handleApiError(err);

                                return of(err);
                            }),
                        );
                    });

                    // Combine all request observables into one observable, which will complete
                    // when all the requests complete.
                    const mergedObservable = forkJoin(requestsHash);

                    return mergedObservable.pipe(
                        // Handle API response
                        tapResponse(
                            (data: any) => {
                                if (!isErrorPresent) {
                                    if (!config.lastReceivedData) {
                                        config.lastReceivedData = {};
                                    }

                                    // Combine received data with the last received data.
                                    data = {
                                        ...config.lastReceivedData,
                                        ...data,
                                    };

                                    // Stop loading spinner after first state update.
                                    stopLoading();
                                    handleApiData(data);

                                    combinedRequest.requests = combinedRequest.requests.filter(
                                        request => {
                                            const isRequestComplete =
                                                data[request.requestId].percent_remaining === 0;

                                            return !isRequestComplete;
                                        },
                                    );

                                    // Pass to handleApiLoopComplete a boolean indicating
                                    // whether all data was returned or whether the loop timed out.
                                    // True stands for timeout result.
                                    if (combinedRequest.requests.length === 0) {
                                        handleApiLoopComplete(false);

                                        return;
                                    }

                                    if (config.requestCount === LOGS_MAX_REQUEST_COUNT) {
                                        handleApiLoopComplete(true);

                                        return;
                                    }

                                    // If not, increase count and recursively
                                    // call the request effect again
                                    config.requestCount++;
                                    // Update lastReceivedData with the latest combined data.
                                    config.lastReceivedData = data;

                                    combinedRequestEffect({
                                        config,
                                        requests: combinedRequest.requests,
                                    });
                                }
                            },
                            handleApiError,
                        ),
                    );
                }),
            ),
        );

        return combinedRequestEffect;
    };

    /**
     * Create recursive effects to call logs API.
     *
     * This function is a constructor that returns an effect on the specified Component Store.
     * The resulting effect will call the logs API for that component's data.
     * Upon each API return, it will update the component's state.
     *
     * If percent_remaining > 0 on the returned data, it will double the timeout
     * (until the max timeout value is reached) and make another call by recursively calling itself.
     *
     * Once the max number of calls is reached the loop will end.
     */
    public createVsLogsRecursiveRequestEffect: TVsLogsRecursiveRequestEffectCreator = (
        componentStore,
        startLoading,
        stopLoading,
        handleApiData,
        handleApiError,
        handleApiLoopComplete,
    ) => {
        // create a recursive effect using the reference to the component store
        const requestEffect = componentStore.effect<TVsLogsRecursiveRequest>(
            request$ => request$.pipe(
                // if a previous request hasn't completed yet, the new request will cancel it
                // switchMap handles this by only emitting the last Observable output
                switchMap(request => {
                    const { config } = request;
                    const { requestId } = config;

                    this.vsLogsApiRequestService.cancelRequest(requestId);

                    if (!config.requestCount) {
                        config.requestCount = 1;
                    }

                    if (config.requestCount === 1) {
                        startLoading();
                    }

                    const apiParams = { ...request.apiParams };

                    if (!apiParams.timeout) {
                        apiParams.timeout = LOGS_REQUEST_INITIAL_TIMEOUT;
                    } else if (apiParams.timeout !== LOGS_REQUEST_MAX_TIMEOUT) {
                        apiParams.timeout *= 2;
                    }

                    // emit the next Observable by making the request
                    return from(this.vsLogsApiRequestService.requestLogs(
                        apiParams,
                        requestId,
                    )).pipe(
                        // handle API response
                        tapResponse(
                            data => {
                                handleApiData(data);
                                // stop loading spinner after first state update
                                stopLoading();

                                // If ending conditions met, end the loop
                                // Pass to the component a boolean indicating
                                // whether data was correctly returned or whether the loop
                                // timed out.

                                // checking >= here instead of ===, as the results can include an
                                // extra meta-data item (eg. signpost call)
                                const pageFullyLoaded = data.results?.length >= data.page_size;

                                // when page size is larger than the final data count,
                                // percent_remaining will be checked, otherwise data is considered
                                // ready once the page is fully loaded
                                if (pageFullyLoaded || data.percent_remaining === 0) {
                                    handleApiLoopComplete(false);

                                    return;
                                }

                                // loop has timed out
                                if (config.requestCount === LOGS_MAX_REQUEST_COUNT) {
                                    handleApiLoopComplete(true);

                                    return;
                                }

                                // If not, increase count and recursively
                                // call the request effect again
                                config.requestCount++;

                                requestEffect({
                                    config,
                                    apiParams,
                                });
                            },
                            handleApiError,
                        ),
                    );
                }),
            ),
        );

        return requestEffect;
    };
}
