/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const numberOfMatchesLabel = `${prefix}.numberOfMatchesLabel`;
export const classifyAsLabel = `${prefix}.classifyAsLabel`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const botMappingRulesLabel = `${prefix}.botMappingRulesLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Bot Mapping',
    [modalTitleEdit]: 'Edit Bot Mapping',
    [numberOfMatchesLabel]: 'Number of Matches',
    [classifyAsLabel]: 'Classify as: ',
    [addButtonLabel]: 'Add',
    [botMappingRulesLabel]: 'Bot Mapping Rules ({0})',
};
