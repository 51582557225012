<div class="recent-updates-list">
    <div class="recent-updates-list__title">{{ l10nKeys.title | vtranslate }}</div>

    <ng-container *ngIf="nodeList?.length && !prechecksTriggered; else nodeListEmpty">
        <update-node-status-card
            *ngFor="let node of nodeList; trackBy: trackNodeList"
            [node]="node"
            [overallPrechecksState]="overallPrechecksState"
            [isPrecheckFlow]="isPrecheckFlow"
            [isSystemUpdate]="isSystemUpdate"
            [userSelectedOptions]="userSelectedOptions$ | async"
            [rollbackVersion]="rollbackVersion$ | async"
            [selectedSegs]="selectedSegs$ | async"
            [selectedSegUpgradeImages]="selectedSegUpgradeImages$ | async"
            [isUpgradeTriggeredFromSystemUpdatePage]="isUpgradeTriggeredFromSystemUpdatePage$ | async"
            (onSelectedSegIdUpdate)="triggerSelectedSegIdUpdateEvent($event)"
        ></update-node-status-card>
    </ng-container>
    <ng-template #nodeListEmpty>
        <div class="recent-updates-list__no-tiles-placeholder">{{ emptyListPlaceHolder | vtranslate }}</div>
    </ng-template>
</div>
