/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Inject,
    Pipe,
    PipeTransform,
} from '@angular/core';

import { isNumber } from 'underscore';
import { FormatBytesPipe } from 'ng/shared/pipes/format-bytes.pipe';

import {
    signpostsWithRange,
    templatesConfiguration,
} from '../constants/vs-logs-signpost.constants';

/**
 * @description
 *     Pipe for converting display text of signpost entries.
 *     If the message received from API is not informative for end user, transform it to
 *     new display message.
 * @author Suraj Kumar
 */
@Pipe({
    name: 'signpostLogDataTransform',
})
export class SignpostLogDataTransformPipe implements PipeTransform {
    constructor(
        @Inject('logDataTransform')
        private logDataTransformService: any,
        private readonly formatBytes: FormatBytesPipe,
    ) {}

    /** @override */
    public transform(value: any, type: string): string {
        const { logDataTransformService } = this;

        if (type === 'server-ip-addr' || type === 'vs_ip') {
            const { params } = templatesConfiguration[type];

            const transformed =
                logDataTransformService.propOnLoad(params, value);

            if (transformed) {
                const { display } = transformed;

                return display;
            }
        } else if (type === 'response-code') {
            const transformed =
                logDataTransformService.propOnLoad('response_code', value);

            if (transformed) {
                return `${value} (${transformed.descr || 'Unrecognized code'})`;
            }
        } else if (type === 'microservice_name') {
            if (value === '') {
                return 'External-Client';
            }
        } else if (type === 'dns_qtype' || type === 'dns_etype' ||
            type === 'protocol') {
            if (value) {
                return logDataTransformService.propOnLoad(
                    type, value,
                ).display;
            }
        } else if (type === 'bot_management_log.classification.type') {
            const transformed = logDataTransformService.propOnLoad(
                'bot_management_log_classification_type',
                value,
            );

            if (transformed) {
                return transformed;
            }
        } else if (signpostsWithRange.has(type)) {
            let { start, end } = value;

            if (!(type === 'e2e-timing-udp' || type === 'icap_log.request_logs.latency')) {
                if (isNumber(start)) {
                    start = this.formatBytes.transform(start);
                }

                if (isNumber(end)) {
                    end = this.formatBytes.transform(end);
                }
            }

            return `${start} ‒ ${end}`;
        } else if (type === 'waf-phase-latency' || type === 'e2e-timing') {
            let { start, end } = value;

            if (isNumber(start)) {
                start = Number(start);
            }

            if (isNumber(end)) {
                end = Number(end);
            }

            return `${start} ‒ ${end}`;
        }

        return value;
    }
}
