/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-ntlm';
const prefix = `${moduleName}.${componentName}`;

export const ntlmLabel = `${prefix}.ntlmLabel`;
export const ntlmStatusLabel = `${prefix}.ntlmStatusLabel`;
export const detectedLabel = `${prefix}.detectedLabel`;
export const undetectedLabel = `${prefix}.undetectedLabel`;

export const ENGLISH = {
    [ntlmLabel]: 'NTLM',
    [ntlmStatusLabel]: 'NTLM Status',
    [detectedLabel]: 'Detected',
    [undetectedLabel]: 'Undetected',
};
