/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    AnalyticsProfileCollection,
} from 'ajs/modules/analytics/factories/analytics-profile.collection.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './analytics-profile-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TAnalyticsProfileCollection = typeof AnalyticsProfileCollection;

/**
 * @description Analytics Profile list page.
 *
 * @author Anjali Tanpure
 */
@Component({
    selector: 'analytics-profile-list-page',
    templateUrl: './analytics-profile-list-page.component.html',
})
export class AnalyticsProfileListPageComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display analytics profilr grid.
     */
    public analyticsProfileGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * AnalyticsProfileCollection instance.
     */
    private readonly analyticsProfileCollection: AnalyticsProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(AnalyticsProfileCollection)
        AnalyticsProfileCollection: TAnalyticsProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.analyticsProfileCollection = new AnalyticsProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.analyticsProfileCollection;

        this.analyticsProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.analyticsProfileCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow): string => row.getName(),
                },

            ],
            singleactions: [
                {
                    id: 'edit',
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (row): void => {
                        row.isEditable() ? row.edit() :
                            this.analyticsProfileCollection.clone(row);
                    },
                    hidden: (row): boolean => {
                        return !row.isEditable() && !row.isClonable();
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.analyticsProfileCollection.destroy();
    }
}
