/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-pool-member-type';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceLabel = `${prefix}.virtualServiceLabel`;

export const ENGLISH = {
    [virtualServiceLabel]: 'Virtual Service',
};
