/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { TenantSystemConfiguration } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    AviPermissionResource,
    ITenantSystemConfiguration,
} from 'generated-types';

const { ...globalL10nKeys } = globalL10n;

interface ITenantSystemConfigurationData {
    config: ITenantSystemConfiguration
}

/**
 * Interface for DNS Vs References.
 */
export interface IDnsVsRefObj {
    vsRef: string;
}

/**
 * Interface for vs ref object and its positional index in dns services.
 */
export interface IVsRefPosition {
    vsRef: string;
    index: number;
}

export class TenantSettingsItem extends withFullModalMixin(ObjectTypeItem) {
    public static ngDependencies = [
        L10nService,
    ];

    public data: ITenantSystemConfigurationData;
    public getConfig: () => ITenantSystemConfiguration;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'tenantsystemconfiguration',
            windowElement: 'lazy-load',
            objectType: TenantSystemConfiguration,
            permissionName: AviPermissionResource.PERMISSION_TENANT_SYSTEM_CONFIGURATION,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getNgDependency(L10nService);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { TenantSettingsModalComponent } = await import(
            /* webpackChunkName: "tenant-settings-modal" */
            'ng/lazy-loaded-components/modals/tenant-settings-modal/tenant-settings-modal.component'
        );

        return TenantSettingsModalComponent as Type<Component>;
    }

    /** @override */
    public beforeEdit(): void {
        const config = this.getConfig();

        if (!config.dns_virtualservice_refs) {
            config.dns_virtualservice_refs = [];
        }
    }

    /**
     * List of dns virtual services refs are restrutured to IDnsVsRefObj,
     * to serve the collection dropdown consumption in data grid.
     */
    public getDnsVirtualServiceRefs(): IDnsVsRefObj[] {
        const {
            dns_virtualservice_refs: vsRefs = [],
        } = this.getConfig();

        return vsRefs.map(vsRef => ({ vsRef }));
    }

    /**
     * Remove dns service from the existing list of dns vs.
     */
    public removeDnsService(dnsVsRefObj: IDnsVsRefObj): void {
        const {
            dns_virtualservice_refs: vsRefs = [],
        } = this.getConfig();

        const index = vsRefs.findIndex(ref => ref === dnsVsRefObj.vsRef);

        vsRefs.splice(index, 1);
    }

    /**
     * Add dns vs service to the existing list of dns vs.
     */
    public addDnsVs(): void {
        const {
            dns_virtualservice_refs: dnsVsRefs = [],
        } = this.getConfig();

        dnsVsRefs.push(undefined);
    }

    /**
     * Update dns vs service from the existing list of dns vs.
     */
    public updateDnsVs(vsRef: string, index: number): void {
        const {
            dns_virtualservice_refs: dnsVsRefs,
        } = this.getConfig();

        dnsVsRefs[index] = vsRef;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.tenantSettingsLabel);
    }
}
