/**
 * Module containing scripts related components.
 * @module ScriptsModule
 * @preferred
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    certificateCollectionProvider,
    dataScriptSetCollectionProvider,
    dataScriptSetItemProvider,
    ipAddrGroupCollectionProvider,
    ipReputationDBCollectionProvider,
    pkiProfileCollectionProvider,
    protocolParserCollectionProvider,
    sslProfileCollectionProvider,
    stringGroupCollectionProvider,
} from './ajs-upgraded-providers';

import {
    ControlScriptComponent,
    ControlScriptsModalComponent,
    DataScriptComponent,
    DataScriptConfigComponent,
    DataScriptSetModalComponent,
    DsRateLimiterGridComponent,
    ProtocolParserModalComponent,
    ProtocolParserScriptComponent,
} from './components';

const scriptComponents = [
    ControlScriptComponent,
    ControlScriptsModalComponent,
    DataScriptComponent,
    DataScriptConfigComponent,
    DataScriptSetModalComponent,
    DsRateLimiterGridComponent,
    ProtocolParserModalComponent,
    ProtocolParserScriptComponent,
];

/**
 * @description
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...scriptComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        DataGridModule,
        FormsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        SharedModule,
    ],
    providers: [
        certificateCollectionProvider,
        dataScriptSetCollectionProvider,
        dataScriptSetItemProvider,
        ipAddrGroupCollectionProvider,
        ipReputationDBCollectionProvider,
        pkiProfileCollectionProvider,
        protocolParserCollectionProvider,
        stringGroupCollectionProvider,
        sslProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ScriptsModule {}
