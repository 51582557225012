/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { HostHdrMatchConfigItem } from 'ajs/modules/match/factories';
import * as l10n from './host-hdr-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for host-hdr-match.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'host-hdr-match-readonly',
    templateUrl: './host-hdr-match-readonly.component.html',
})
export class HostHdrMatchReadonlyComponent {
    @Input()
    public editable: HostHdrMatchConfigItem;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get text label for selected match criteria.
     */
    public get matchCriteria(): string {
        return this.schemaService.getEnumValueLabel(
            'HdrMatchOperation',
            this.editable.config.match_criteria,
        );
    }

    /**
     * Returns list of values, if any, corresponding to criteria.
     */
    public get values(): string[] | undefined {
        return this.editable.config.value;
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
