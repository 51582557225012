/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { AuthRuleAssignment } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { AuthMappingRuleConfigItem }
    from 'ajs/modules/auth-settings/factories';
import { TenantCollection }
    from 'ajs/modules/accounts/factories/tenants/tenant.collection.factory';
import { StringService } from 'string-service';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './user-tenant-mapping.l10n';
import './user-tenant-mapping.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH, ...globalL10nKeys } = globalL10n;

type TTenantCollection = typeof TenantCollection;

/**
 * @description Component to configure tenant mapping in auth-mapping-rule modal.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'user-tenant-mapping',
    templateUrl: './user-tenant-mapping.component.html',
})
export class UserTenantMappingComponent implements OnInit, OnDestroy {
    /**
     * AuthMappingRule MessageItem instance.
     */
    @Input()
    public editable: AuthMappingRuleConfigItem;

    /**
     * ObjectType used in template.
     */
    @Input()
    public readonly objectType: string;

    /**
     * Fieldname used in template.
     */
    @Input()
    public readonly fieldName: string;

    /**
     * Emits event when remove button is clicked.
     */
    @Output()
    public onRemoveMapping = new EventEmitter();

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * TenantCollection instance.
     */
    public readonly tenantCollection: TenantCollection;

    /**
     * Options for `Default Tenant` dropdown.
     * This will be populated based on `editable.tenant_refs`.
     */
    public selectedTenants: IAviDropdownOption[] = [];

    constructor(
        l10nService: L10nService,
        @Inject(TenantCollection)
        TenantCollection: TTenantCollection,
        private readonly stringService: StringService,
    ) {
        this.tenantCollection = new TenantCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.showTenantList) {
            this.onTenantSelectionChange();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.tenantCollection.destroy();
    }

    /**
     * If true, TenantCollection dropdown will be displayed.
     */
    public get showTenantList(): boolean {
        const { assign_tenant: tenantAssignment } = this.editable.config;

        return tenantAssignment === AuthRuleAssignment.ASSIGN_FROM_SELECT_LIST;
    }

    /**
     * If true, Tenant attributeName input will be displayed.
     */
    public get showTenantAttributeName(): boolean {
        const { assign_tenant: tenantAssignment } = this.editable.config;

        return tenantAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_VALUE ||
            tenantAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_REGEX ||
            tenantAssignment === AuthRuleAssignment.ASSIGN_CONFIG_CONTAINS_ATTRIBUTE_VALUE;
    }

    /**
     * Fires on AssignmentType change.
     */
    public onAssignmentTypeChange(): void {
        this.editable.resetTenantMapping();
    }

    /**
     * Handler for remove mapping event.
     */
    public removeMapping(): void {
        this.onRemoveMapping.emit();
    }

    /**
     * Change handler for tenants dropdown.
     */
    public onTenantSelectionChange(): void {
        const {
            tenant_refs: tenantRefs = [],
            default_tenant_ref: defaultTenantRef,
        } = this.editable.config;

        // When user removes defaultTenant from selected tenant refs.
        if (defaultTenantRef && !tenantRefs.includes(defaultTenantRef)) {
            this.editable.config.default_tenant_ref = undefined;
        }

        this.selectedTenants = tenantRefs.map(tenantRef => createDropdownOption(
            tenantRef,
            this.stringService.name(tenantRef),
        ));
    }
}
