/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'generate-sso-token-dialog';
const prefix = `${moduleName}.${componentName}`;

export const generateSsoTokenDialogHeader = `${prefix}.generateSsoTokenDialogHeader`;
export const generateSsoTokenDescription = `${prefix}.generateSsoTokenDescription`;
export const closeButtonLabel = `${prefix}.closeButtonLabel`;
export const generateButtonLabel = `${prefix}.generateButtonLabel`;
export const tokenLifetimeLabel = `${prefix}.tokenLifetimeLabel`;
export const tokenLifetimePlaceholder = `${prefix}.tokenLifetimePlaceholder`;
export const tokenLifetimeTooltipMessage = `${prefix}.tokenLifetimeTooltipMessage`;
export const hoursHelperText = `${prefix}.hoursHelperText`;
export const tokenLabel = `${prefix}.tokenLabel`;

export const ENGLISH = {
    [generateSsoTokenDialogHeader]: 'Generate SSO Token',
    [generateSsoTokenDescription]: 'This is a token generated for user <b>{0}</b> and can be ' +
        'used for token authentication during login.',
    [closeButtonLabel]: 'Close',
    [generateButtonLabel]: 'Generate',
    [tokenLifetimeLabel]: 'Token Lifetime',
    [tokenLifetimePlaceholder]: 'Enter Token Lifetime',
    [tokenLifetimeTooltipMessage]: 'Length of time Generated Token will be accessible before ' +
        'self-destructing.',
    [hoursHelperText]: 'Hours',
    [tokenLabel]: 'Token',
};
