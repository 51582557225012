/**
 * Module containing system configuration components.
 * @preferred
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrIconModule,
    ClrInputModule,
    ClrTextareaModule,
} from '@clr/angular';

import { AviCardModule } from 'nsx-alb-ui-components';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    backupCollectionProvider,
    sslProfileCollectionProvider,
    virtualServiceCollectionProvider,
} from './ajs-upgraded-providers';

import {
    AuthenticationConfig,
    BackupOverviewCardComponent,
    BackupPageComponent,
    CrashReportsPageComponent,
    DnsServiceGridComponent,
    EmailSmtpConfigComponent,
    LocalBackupCardComponent,
    RemoteAuthConfigurationsGridComponent,
    RemoteBackupCardComponent,
    SchedulerModalComponent,
    SystemAboutInfoComponent,
    SystemSettingsAccessBannersComponent,
    SystemSettingsAccessCardComponent,
    SystemSettingsAccessClientManagementAccessCardComponent,
    SystemSettingsAccessConfigContainerComponent,
    SystemSettingsAccessSystemAccessCardComponent,
    SystemSettingsAccessSystemSnmpComponent,
    SystemSettingsAccessSystemSslComponent,
    SystemSettingsAuthCardComponent,
    SystemSettingsBannersConfigComponent,
    SystemSettingsClientManagementAccessConfigComponent,
    SystemSettingsClientManagementAccessConfigContainerComponent,
    SystemSettingsDnsNtpCardComponent,
    SystemSettingsDnsNtpConfigComponent,
    SystemSettingsDnsServicesCardComponent,
    SystemSettingsEmailSmtpSourceCardComponent,
    SystemSettingsModalComponent,
    SystemSettingsPageComponent,
    SystemSettingsPageStore,
    SystemSettingsPreviewComponent,
    SystemSettingsSnmpConfigComponent,
    SystemSettingsSystemAccessConfigComponent,
    SystemSettingsTenancyModeCardComponent,
    TenancyModeConfigComponent,
} from './components';

const systemComponents = [
    AuthenticationConfig,
    BackupOverviewCardComponent,
    BackupPageComponent,
    CrashReportsPageComponent,
    SystemSettingsDnsNtpConfigComponent,
    DnsServiceGridComponent,
    EmailSmtpConfigComponent,
    LocalBackupCardComponent,
    RemoteBackupCardComponent,
    RemoteAuthConfigurationsGridComponent,
    SchedulerModalComponent,
    SystemAboutInfoComponent,
    SystemSettingsAccessConfigContainerComponent,
    SystemSettingsAccessBannersComponent,
    SystemSettingsAccessCardComponent,
    SystemSettingsAccessSystemSnmpComponent,
    SystemSettingsAccessSystemSslComponent,
    SystemSettingsAccessSystemAccessCardComponent,
    SystemSettingsAccessClientManagementAccessCardComponent,
    SystemSettingsAuthCardComponent,
    SystemSettingsBannersConfigComponent,
    SystemSettingsClientManagementAccessConfigComponent,
    SystemSettingsClientManagementAccessConfigContainerComponent,
    SystemSettingsDnsNtpCardComponent,
    SystemSettingsDnsServicesCardComponent,
    SystemSettingsEmailSmtpSourceCardComponent,
    SystemSettingsModalComponent,
    SystemSettingsPageComponent,
    SystemSettingsPreviewComponent,
    SystemSettingsSnmpConfigComponent,
    SystemSettingsSystemAccessConfigComponent,
    SystemSettingsTenancyModeCardComponent,
    TenancyModeConfigComponent,
];

@NgModule({
    declarations: [
        ...systemComponents,
    ],
    exports: [
        SystemAboutInfoComponent,
        RemoteAuthConfigurationsGridComponent,
    ],
    imports: [
        AviCardModule,
        AviFormsModule,
        CommonModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        ClrIconModule,
        ClrTextareaModule,
        CoreModule,
    ],
    providers: [
        backupCollectionProvider,
        virtualServiceCollectionProvider,
        sslProfileCollectionProvider,
        SystemSettingsPageStore,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class SystemModule { }
