/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsWrapperModule
 */

import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectShowNewLogsPage } from 'ng/root-store/user-preferences/user-preferences.selectors';

import template from './vs-logs-wrapper-page.component.html';

// TODO AV-171346 Delete component if removing old logs and tech-preview
/**
 * Wrapper component to allow alternating between old/new logs page versions.
 * @author Akul Aggarwal, Zhiqian Liu
 */
class VsLogsWrapperPageController {
    /**
     * Decide which version of page to show.
     */
    public showNewVersion: boolean;

    private componentDestroy$: Subject<unknown>;

    constructor(
        private readonly $scope: any,
        private readonly store: Store,
    ) {
        this.$scope = $scope;
        this.store = store;

        this.componentDestroy$ = new Subject();
    }

    /**
     * @override
     */
    public $onInit(): void {
        this.$scope.vs = this.$scope.$parent.vs;
        this.setToggleWatcher();
    }

    public $onDestroy(): void {
        this.componentDestroy$.next();
        this.componentDestroy$.complete();
    }

    /**
     * Update page upon User Preference store updating showNewVsLogsPage prop.
     */
    private setToggleWatcher(): void {
        this.store.select(selectShowNewLogsPage)
            .pipe(takeUntil(this.componentDestroy$))
            .subscribe(show => this.showNewVersion = show);
    }
}

VsLogsWrapperPageController.$inject = [
    '$scope',
    'ngrxStoreService',
];

export const vsLogsWrapperPageOptions = {
    controller: VsLogsWrapperPageController,
    template,
};
