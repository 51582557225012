<div class="license-card-usage">
    <label class="license-card-usage__label">{{ controllerUsageLabel | vtranslate }}</label>
    <label 
        *ngIf="controllerTooltipText"
        aviLabelWithTooltip 
        [tooltipText]="controllerTooltipText"
        class="license-card-usage__tooltip"
    ></label>
    <label 
        *ngIf="controllerTooltipFieldName"
        aviLabelWithTooltip 
        [fieldName]="controllerTooltipFieldName"
        class="license-card-usage__tooltip"
    ></label>
    <div [ngClass]="usageCountColorClassStyle">
        {{ controllerUsageValue }}
    </div>
</div>
