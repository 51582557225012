/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IInterfaceRuntime, IIpInterface } from 'generated-types';
import { AlbHttpClient } from 'ng/modules/http/service/alb-http-client.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * @description
 *     Service for the InUseInterfaceListExpandedContentComponent.
 * @author alextsg
 */
@Injectable()
export class InUseInterfaceListExpandedContentService {
    constructor(private readonly http: AlbHttpClient) {}

    /**
     * Fetch IP Interface objects for a Service Engine interface.
     */
    public fetchAddresses(seId: string, interfaceName: string): Observable<IIpInterface[]> {
        const requestUrl = `/api/serviceengine/${seId}/interface?intfname=${interfaceName}`;

        return this.http.get<IInterfaceRuntime[]>(requestUrl).pipe(
            map((interfaces = []) => {
                const output: IIpInterface[] = [];

                interfaces.forEach(interfaceRuntime => {
                    const { vnics = [] } = interfaceRuntime;

                    vnics.forEach(vnic => {
                        const { ip_info: ipInfos = [] } = vnic;

                        ipInfos.forEach(ipInfo => {
                            if (ipInfo.is_floating_intf_ip) {
                                output.push(ipInfo);
                            }
                        });
                    });
                });

                return output;
            }),
            catchError((errorResponse: HttpErrorResponse) => throwError(errorResponse.error)),
        );
    }
}
