/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'GroupsModule';
const componentName = 'string-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const editStringGroupModalHeader = `${prefix}.editStringGroupModalHeader`;
export const newStringGroupModalHeader = `${prefix}.newStringGroupModalHeader`;
export const stringTypeLabel = `${prefix}.stringTypeLabel`;
export const keyValueTypeLabel = `${prefix}.keyValueTypeLabel`;
export const stringTypePlaceHolder = `${prefix}.stringTypePlaceHolder`;
export const resetToDefaultValuesHeader = `${prefix}.resetToDefaultValuesHeader`;
export const typeString = `${prefix}.typeString`;
export const typeKeyValue = `${prefix}.typeKeyValue`;
export const browseBtnLabel = `${prefix}.browseBtnLabel`;
export const typeStringFileDataLabel = `${prefix}.typeStringFileDataLabel`;
export const typeKeyValueFileDataLabel = `${prefix}.typeKeyValueFileDataLabel`;
export const typeLabel = `${prefix}.typeLabel`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const duplicateValuesAlert = `${prefix}.duplicateValuesAlert`;
export const discardedCurrentValuesConfirmation = `${prefix}.discardedCurrentValuesConfirmation`;
export const longestMatch = `${prefix}.longestMatch`;

export const ENGLISH = {
    [editStringGroupModalHeader]: 'Edit String Group',
    [newStringGroupModalHeader]: 'Create String Group',
    [stringTypeLabel]: 'String(s) ({0})',
    [keyValueTypeLabel]: 'Key & Value(s) ({0})',
    [stringTypePlaceHolder]: 'Enter String',
    [resetToDefaultValuesHeader]: 'Reset to Default Values',
    [typeString]: 'String',
    [typeKeyValue]: 'Key & Value',
    [browseBtnLabel]: 'Import File',
    [typeStringFileDataLabel]: 'Import String(s) From File',
    [typeKeyValueFileDataLabel]: 'Import Key & Value(s) From File',
    [typeLabel]: 'Type',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [duplicateValuesAlert]: 'One or more strings being imported already existed. Those have been over-written by the newly provided instances of the strings.',
    [discardedCurrentValuesConfirmation]: 'Switching type will reset any stored values to default.',
    [longestMatch]: 'Longest match',
};
