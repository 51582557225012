<full-modal-config
    modalTitle="{{ modalHeader }}"
    modalSubTitle="{{ editable.config.name }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="gslb-site-modal"
>
    <form
        clrForm 
        [clrLayout]="verticalLayout" 
        #form="ngForm"
    >
        <!-- General -->
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-checkbox
                    name="enabled"
                    objectType="{{ objectType.GslbSite }}"
                    fieldName="enabled"
                    [(ngModel)]="editable.config.enabled"
                    [disabled]="isLeader"
                >
                    {{ l10nKeys.enableGslbSiteLabel | vtranslate }}
                </avi-checkbox>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType.GslbSite }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                        [disabled]="!isAviSite && editable.config.cluster_uuid"
                    />
                </avi-input-container>
                <avi-checkbox
                    name="member-type"
                    objectType="{{ objectType.GslbSite }}"
                    fieldName="member_type"
                    [ngModel]="isActiveMember"
                    (ngModelChange)="handleGslbMemberTypeChange($event)"
                    [disabled]="isLeader"
                    *ngIf="isAviSite"
                >
                    {{ l10nKeys.activeMemberCheckboxLabel | vtranslate : memberTypeLabel }}
                </avi-checkbox>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="isLeader && !gslb.id"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType.Gslb }}"
                            fieldName="replication_policy"
                        >
                            {{ l10nKeys.replicationModeLabel | vtranslate }}
                        </label>
                        <enum-radio-buttons
                            name="gslb-replication-mode"
                            id="gslb-replication-mode"
                            [(ngModel)]="gslb.config.replication_policy.config.replication_mode"
                            enum="ReplicationMode"
                            [hiddenEnumValues]="hiddenReplicationModeTypes"
                            noMarginTop
                        ></enum-radio-buttons>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
        <ng-container *ngIf="isAviSite; then aviSiteConfig; else thirdPartySiteConfig">
        </ng-container>
        <!-- Avi Site Section -->
        <ng-template #aviSiteConfig>
            <full-modal-tab-section tabTitle="{{ l10nKeys.siteLabel | vtranslate }}">
                <div class="full-modal-config__section">
                    <h3>{{ l10nKeys.siteLabel | vtranslate }}</h3>
                    <gslb-avi-site-config
                        [editable]="editable"
                        [activeGslbSiteOptions]="activeGslbSiteOptions"
                    ></gslb-avi-site-config>
                </div>
            </full-modal-tab-section>
        </ng-template>
        <!-- Third-party Site Section -->
        <ng-template #thirdPartySiteConfig>
            <full-modal-tab-section tabTitle="{{ l10nKeys.thirdPartySiteLabel | vtranslate }}">
                <div class="full-modal-config__section">
                    <h3>{{ l10nKeys.thirdPartySiteLabel | vtranslate }}</h3>
                    <gslb-third-party-site-config
                        [editable]="editable"
                        [activeGslbSiteOptions]="activeGslbSiteOptions"
                    ></gslb-third-party-site-config>
                </div>
            </full-modal-tab-section>
        </ng-template>
        <!-- Client Groups -->
        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.clientGroupsLabel | vtranslate }}"
            *ngIf="isLeader && !gslb.id"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.clientGroupsLabel | vtranslate }}</h3>
                <gslb-client-ip-addr-group-config
                    [clientIpAddrGroup]="gslb.config.client_ip_addr_group"
                ></gslb-client-ip-addr-group-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section 
            tabTitle="{{ l10nKeys.subdomainsLabel | vtranslate }}"
            *ngIf="isLeader && !gslb.id"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.subdomainsLabel | vtranslate }}</h3>
                <gslb-subdomains-grid
                    [subdomains]="gslb.config.dns_configs"
                ></gslb-subdomains-grid>
            </div>
        </full-modal-tab-section>
        <!-- Placement -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.placementLabel | vtranslate }}"
            *ngIf="editable.config.member_type === activeMemberType"
        >
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.placementLabel | vtranslate }}</h3>
                <gslb-dns-virtual-services-grid
                    [dnsVses]="editable.config.dns_vses"
                    [tenantId]="tenantId"
                    [clusterUuid]="editable.config.cluster_uuid"
                    (onAddDnsVs)="addDnsVs()"
                    (onEditDnsVs)="editDnsVs($event)"
                    (onDeleteDnsVs)="deleteDnsVs($event)"
                ></gslb-dns-virtual-services-grid>
            </div>
        </full-modal-tab-section>
    </form>
    <full-modal-config_footer>
        <avi-checkbox
            *ngIf="showSetupDnsCheckbox"
            name="setupDnsAfter"
            [(ngModel)]="openDnsModalAfterSave"
            class="gslb-site-modal__setup-dns-checkbox"
        >
            {{ l10nKeys.setupDnsVsAfterLabel | vtranslate }}
        </avi-checkbox>
    </full-modal-config_footer>
</full-modal-config>
