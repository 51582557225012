/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createAction,
    props,
} from '@ngrx/store';

import { IControllerSiteState } from './controller-site.state';

const CONTROLLER_SITE_SOURCE = '[Controller Site]';

export const loadControllerSite = createAction(
    `${CONTROLLER_SITE_SOURCE} Load Site Data`,
    props<{ hostName: string }>(),
);

export const setControllerSite = createAction(
    `${CONTROLLER_SITE_SOURCE} Set Controller Site`,
    props<{ controllerSiteState: IControllerSiteState }>(),
);

export const ControllerSiteActions = {
    loadControllerSite,
    setControllerSite,
};
