/**
 * @module avi/component-kit/grid
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular, { copy } from 'angular';
import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import { updateGrid } from 'ng/root-store/user-preferences/user-preferences.actions';
import {
    AjsDependency,
    initAjsDependency,
} from 'ajs/js/utilities/ajsDependency';

import { MyAccountService } from 'ng/modules/core/services/my-account.service';
import { IGridInfo } from 'ng/root-store/user-preferences/user-preferences.state';
import { ColumnWidthHash } from './grid-types';

/**
 * We store column-width hash of a grid with the following
 * key in myAccount.uiProperty.
 */
const columnWidthHashKey = 'columnWidths';

/**
 * We store sorting order of a grid with the following
 * key in myAccount.uiProperty.
 */
const sortOrderHashkey = 'columnSortOrder';

/**
 * @description
 *      Service to store grid related data (columnSize, sortingOrder) in myAccount.
 *
 * @author Aravindh Nagarajan
 */
export class GridDataStorageService extends AjsDependency {
    /**
     * MyAccount instance.
     */
    private myAccount: MyAccountService;

    public constructor() {
        super();

        this.myAccount = this.getAjsDependency_('myAccount');
    }

    /**
     * Returns grid data for a grid id.
     */
    public getGridData(gridId: string): IGridInfo {
        const { grid: gridHash } = this.myAccount;

        if (!(gridId in gridHash)) {
            return null;
        }

        return gridHash[gridId];
    }

    /**
     * Returns Sorting order for a grid id. null if no information is stored.
     */
    public getGridSortOrder(gridId: string): string | null {
        const gridData = this.getGridData(gridId);

        if (!gridData || !(sortOrderHashkey in gridData)) {
            return null;
        }

        return gridData[sortOrderHashkey];
    }

    /**
     * Sets sortingOrder for a grid Id.
     */
    public setGridSortOrder(gridId: string, sortOrder: string): void {
        const store = this.getAjsDependency_(STORE_TOKEN);
        const gridHash = copy(this.myAccount.grid);

        if (!(gridId in gridHash)) {
            gridHash[gridId] = {};
        }

        gridHash[gridId][sortOrderHashkey] = sortOrder;

        store.dispatch(updateGrid({
            payload: {
                ...gridHash,
            },
        }));
    }

    /**
     * Returns columnWidthHash for a grid from uiProperty using gridId.
     */
    public getGridColumnWidthHash(gridId: string): ColumnWidthHash | null {
        const gridData = this.getGridData(gridId);

        if (!gridData || !(columnWidthHashKey in gridData)) {
            return null;
        }

        return angular.copy(gridData[columnWidthHashKey]);
    }

    /**
     * Sets columnWidthHash for a gridId in uiProperty.
     */
    public setGridColumnWidthHash(gridId: string, columnWidthHash: ColumnWidthHash | null): void {
        const store = this.getAjsDependency_(STORE_TOKEN);
        const gridHash = copy(this.myAccount.grid);

        if (!(gridId in gridHash)) {
            gridHash[gridId] = {};
        }

        if (columnWidthHash === null) {
            delete gridHash[gridId][columnWidthHashKey];
        } else {
            gridHash[gridId][columnWidthHashKey] = columnWidthHash;
        }

        store.dispatch(updateGrid({
            payload: {
                ...gridHash,
            },
        }));
    }
}

GridDataStorageService.ajsDependencies = [
    'myAccount',
    STORE_TOKEN,
];

initAjsDependency(
    angular.module('avi/component-kit/grid'),
    'service',
    'gridDataStorageService',
    GridDataStorageService,
);
