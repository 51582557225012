/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module LicensingModule */

const moduleName = 'licensing';
const componentName = 'license-card';
const prefix = `${moduleName}.${componentName}`;

export const licenseTitle = `${prefix}.licenseTitle`;
export const usedLabel = `${prefix}.usedLabel`;
export const maxLabel = `${prefix}.maxLabel`;
export const reservedLabel = `${prefix}.reservedLabel`;
export const controllerUsedLabel = `${prefix}.controllerUsedLabel`;
export const controllersInUseTooltipText = `${prefix}.controllersInUseTooltipText`;
export const controllerReservedLabel = `${prefix}.controllerReservedLabel`;
export const controllersInReserveTooltipText = `${prefix}.controllersInReserveTooltipText`;
export const controllerMaxAllowedTooltipText = `${prefix}.controllerMaxAllowedTooltipText`;
export const maximumControllersLabel = `${prefix}.maximumControllersLabel`;
export const organizationTitle = `${prefix}.organizationTitle`;
export const organizationUsedLabel = `${prefix}.organizationUsedLabel`;
export const organizationsInUseLabel = `${prefix}.organizationsInUseLabel`;

export const ENGLISH = {
    [licenseTitle]: 'License',
    [usedLabel]: 'Used',
    [maxLabel]: 'Max',
    [reservedLabel]: 'Reserved',
    [controllerUsedLabel]: 'Controller Used',
    [controllersInUseTooltipText]: 'Controllers in use',
    [controllerReservedLabel]: 'Controller Reserved',
    [controllersInReserveTooltipText]: 'Controllers in reserve',
    [controllerMaxAllowedTooltipText]: 'Controllers Max Allowed',
    [maximumControllersLabel]: 'Maximum controllers',
    [organizationTitle]: 'Organization',
    [organizationUsedLabel]: 'Organization Used',
    [organizationsInUseLabel]: 'Organizations in use',
};
