/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'role-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleRoleAccess = `${prefix}.sectionTitleRoleAccess`;
export const sectionTitleLabelFilters = `${prefix}.sectionTitleLabelFilters`;
export const filtersLabel = `${prefix}.filtersLabel`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Role',
    [modalHeaderNew]: 'New Role',
    [sectionTitleRoleAccess]: 'Role Access',
    [sectionTitleLabelFilters]: 'Label Filters',
    [filtersLabel]: 'Filters ({0})',
};
