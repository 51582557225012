<div
    class="welcome-email-smtp-server-settings"
    provideParentForm
>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="EmailConfiguration"
            fieldName="auth_username"
            for="auth_username"
            required
        >
            {{ l10nKeys.userNameLabel | vtranslate }}
        </label>
        <input
            id="auth_username"
            name="auth_username"
            [(ngModel)]="emailConfig.auth_username"
            clrInput
            placeholder="{{ l10nKeys.userNameLabel | vtranslate }}"
            required
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="EmailConfiguration"
            fieldName="auth_password"
            for="auth_password"
            required
        >
            {{ l10nKeys.passwordLabel | vtranslate }}
        </label>
        <input
            id="auth_password"
            name="auth_password"
            [(ngModel)]="emailConfig.auth_password"
            clrInput
            placeholder="{{ l10nKeys.passwordLabel | vtranslate }}"
            required
        />
    </avi-input-container>
    <div class="grid-container">
        <div class="grid-container__cell--w--9">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="EmailConfiguration"
                    fieldName="mail_server_name"
                    for="mail_server_name"
                    required
                >
                    {{ l10nKeys.smtpServerLabel | vtranslate }}
                </label>
                <input
                    id="mail_server_name"
                    name="mail_server_name"
                    [(ngModel)]="emailConfig.mail_server_name"
                    clrInput
                    placeholder="localhost"
                    regexPattern="anyIPHostname"
                    required
                />
            </avi-input-container>
        </div>
        <div class="grid-container__cell--w--3">
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="EmailConfiguration"
                    fieldName="mail_server_port"
                    for="mail_server_port"
                    required
                >
                    {{ l10nKeys.portLabel | vtranslate }}
                </label>
                <input
                    id="mail_server_port"
                    name="mail_server_port"
                    type="number"
                    [(ngModel)]="emailConfig.mail_server_port"
                    clrInput
                    placeholder="{{ l10nKeys.portLabel | vtranslate }}"
                    regexPattern="port"
                    required
                />
            </avi-input-container>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="EmailConfiguration"
            fieldName="from_email"
            for="from_email"
            required
        >
            {{ l10nKeys.fromAddressLabel | vtranslate }}
        </label>
        <input
            id="from_email"
            name="from_email"
            type="email"
            [(ngModel)]="emailConfig.from_email"
            regexPattern="email"
            clrInput
            placeholder="admin@avicontroller.net"
            required
        />
    </avi-input-container>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                clrCheckbox
                name="disable_tls"
                [(ngModel)]="emailConfig.disable_tls"
                id="disable_tls"
            />
            <label
                aviLabelWithTooltip
                objectType="EmailConfiguration"
                fieldName="disable_tls"
                for="disable_tls"
            >
                {{ l10nKeys.disableTlsLabel | vtranslate }}
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</div>
