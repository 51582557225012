/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  MetricsDimensionCollection
 * @typedef {Object} MetricsDimensionCollection
 * @description
 *
 *     Collection of metric Series by dimensionId.
 *     List is provided by metrics GET calls - MetricsDimension per every dimension id.
 *
 */
angular.module('avi/vs/security').factory('MetricsDimensionCollection', [
'Collection', 'MetricsDimension',
function(Collection, MetricsDimension) {
    class MetricsDimensionCollection extends Collection {
        constructor(args = {}) {
            super(args);

            const { vsRef } = args;

            this.objectName_ += vsRef.slug();
            this.dimension_ = args.dimension;
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    angular.extend(MetricsDimensionCollection.prototype, {
        objectName_: 'analytics/metrics/virtualservice/',
        itemClass_: MetricsDimension,
        defaultDataSources_: [
            'list',
            'duration-ds',
        ],
        allDataSources_: {
            list: {
                source: 'MetricsDimensionListCollDataSource',
                transformer: 'MetricsDimensionDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'], //dos.avg_attack_count, timeseries
            },
            'duration-ds': {
                source: 'MetricsDimensionDurationCollDataSource',
                transformer: 'MetricsDimensionDataTransformer',
                transport: 'ListDataTransport',
                fields: ['duration'], //dos.sum_attack_duration, aggregated data
                dependsOn: 'config',
            },
            asname: {
                source: 'MetricsDimensionAsnameCollDataSource',
                transformer: 'MetricsDimensionAsnameDataTransformer',
                transport: 'ListDataTransport',
                fields: [
                    'asname-ipgroup',
                    'asname-asn',
                ],
                dependsOn: 'config',
            },
        },
        isStatic_: false,
    });

    return MetricsDimensionCollection;
}]);
