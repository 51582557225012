/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-setting-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const replicationModeLabel = `${prefix}.replicationModeLabel`;

export const ENGLISH = {
    [modalHeader]: 'Edit GSLB Setting',
    [replicationModeLabel]: 'Replication Mode',
};
