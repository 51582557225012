/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'traffic-capture-page';
const prefix = `${moduleName}.${componentName}`;

export const trafficCaptureCardHeader = `${prefix}.trafficCaptureCardHeader`;
export const captureSuccessText = `${prefix}.captureSuccessText`;
export const downloadButtonLabel = `${prefix}.downloadButtonLabel`;
export const captureFailureText = `${prefix}.captureFailureText`;
export const serviceEngineColumnTitle = `${prefix}.serviceEngineColumnTitle`;
export const sizeColumnTitle = `${prefix}.sizeColumnTitle`;
export const completedCapturesGridLabel = `${prefix}.completedCapturesGridLabel`;

export const ENGLISH = {
    [trafficCaptureCardHeader]: 'Traffic capture',
    [captureSuccessText]: 'Traffic capture successfully captured. Capture file will be available shortly.',
    [downloadButtonLabel]: 'Download File.',
    [captureFailureText]: 'Traffic capture failed.',
    [serviceEngineColumnTitle]: 'Service Engine Name',
    [sizeColumnTitle]: 'Size (Kilobytes)',
    [completedCapturesGridLabel]: 'Completed Captures ({0})',
};
