/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @type {Function}
 * @name getIpAddr
 * @param {string} ipAddrStr - String presentation of IP address.
 * @returns {IpAddr}
 * @description
 *
 *     Wraps IP address string into {@link IpAddr} object form. Convenience method.
 */

/**
 * @typedef {Object} IpAddr - protobuf message.
 * @property {string} addr - Regular IP4 address in a string form.
 * @property {string} type - Protobuf IpAddrType enum list, V4 or DNS for now.
 */

angular.module('aviApp').constant('getIpAddr', (addr, type = 'V4') => ({
    addr,
    type,
}));
