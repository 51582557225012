/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { pipe } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IControllerProperties } from 'generated-types';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { MyAccountModalService } from './my-account-modal.service';

interface IControllerPropertiesState {
    controllerProperties: IControllerProperties;
    busy: boolean;
}

const initialState: IControllerPropertiesState = {
    controllerProperties: {},
    busy: false,
};

/**
 * @description
 * Component store for the MyAccountModalComponent.
 * @author alextsg
 */
@Injectable()
export class MyAccountModalStore extends ComponentStore<IControllerPropertiesState> {
    public readonly controllerProperties$ = this.select(state => state.controllerProperties);

    public readonly busy$ = this.select(state => state.busy);

    /**
     * Makes a call to fetch the controller properties to store in the state.
     */
    public fetchControllerProperties = this.effect<void>(pipe(
        switchMap(() => {
            this.patchState({ busy: true });

            return this.myAccountModalService.fetchControllerProperties()
                .pipe(
                    tapResponse(
                        controllerProperties => this.patchState({ controllerProperties }),
                        error => this.devLoggerService.error(error),
                        () => this.patchState({ busy: false }),
                    ),
                );
        }),
    ));

    /**
     * Call to update controllerProperties in the store state.
     */
    public readonly updateControllerProperties = this.updater(
        (state, controllerProperties: IControllerProperties) => ({
            ...state,
            controllerProperties: {
                ...controllerProperties,
            },
        }),
    );

    constructor(
        private readonly devLoggerService: DevLoggerService,
        private readonly myAccountModalService: MyAccountModalService,
    ) {
        super(initialState);
    }
}
