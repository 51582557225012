<div class="avi-dropdown-menu clr-wrapper animated fadeInDown">
    <div
        [hidden]="hideSearch"
        class="avi-dropdown-menu__search"
    >
        <form clrForm>
            <avi-input-container noMarginTop>
                <input
                    clrInput
                    autocomplete="off"
                    placeholder="{{ l10nKeys.searchPlaceholderLabel | vtranslate }}"
                    name="searchTerm"
                    value="{{ searchTerm }}"
                    (input)="handleSearch($event)"
                    (focusin)="handleSearchFocusIn($event)"
                    (focusout)="handleSearchFocusOut($event)"
                    #searchInput
                />
            </avi-input-container>
        </form>
    </div>
    <ng-content></ng-content>
</div>
