/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'boolean-label';
const prefix = `${moduleName}.${componentName}`;

export const suspendedLabel = `${prefix}.suspendedLabel`;
export const deactivateLabel = `${prefix}.deactivateLabel`;
export const enableLabel = `${prefix}.enableLabel`;
export const allowedLabel = `${prefix}.allowedLabel`;
export const disallowedLabel = `${prefix}.disallowedLabel`;
export const trueLabel = `${prefix}.trueLabel`;
export const falseLabel = `${prefix}.falseLabel`;

export const ENGLISH = {
    [suspendedLabel]: 'Suspended',
    [deactivateLabel]: 'Deactivate',
    [enableLabel]: 'Enable',
    [allowedLabel]: 'Allowed',
    [disallowedLabel]: 'Disallowed',
    [trueLabel]: 'True',
    [falseLabel]: 'False',
};
