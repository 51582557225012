/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import inSubnet from 'insubnet';

/**
 * @description Utility function to parse the Subnet address.
 * @author Sarthak Kapoor
 */
export function parseSubnetBaseIp(value: string): string {
    let ip: number;
    let prefix: number;
    let baseIP: number;

    const parts = value.split('/');

    if (parts.length === 2 && inSubnet.isIP(parts[0])) {
        if (inSubnet.isIPv4(parts[0])) {
            ip = inSubnet.toInt(parts[0]);
            prefix = +parts[1];
            /**
             * Applies bitwise operations and converts network bitcount to netmask.
             * Gets the parsed IP using int method of insubnet library.
             */

            /* tslint:disable:no-bitwise */
            baseIP = inSubnet.toInt(ip & 0xFFFFFFFF << 32 - prefix & 0xFFFFFFFF, 0);

            return `${baseIP}/${prefix}`;
        } else {
            return value;
        }
    }

    return null;
}
