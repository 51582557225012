<ng-container *ngrxLet="icapLog$ as icapLog">
    <div vsLogCinematicFieldTitle>
        {{ globalL10nKeys.actionLabel | vtranslate }}
    </div>
    <div
        vsLogFilter
        property="icap_log.action"
        [value]="action"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="icapLog.action as action; else na"
    >
        {{ enumLabelHash[action] || action }}
    </div>

    <!-- ICAP Request Log grid -->
    <avi-data-grid
        *ngIf="icapLog.request_logs?.length"
        [config]="icapRequestLogsGridConfig"
        [rows]="icapLog.request_logs"
    ></avi-data-grid>
</ng-container>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>

<ng-template
    #requestActionTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="icap_log.request_logs.action"
        [value]="action"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.action as action; else na"
    >
        {{ enumLabelHash[action] || action }}
    </span>
</ng-template>

<ng-template
    #reasonTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="icap_log.request_logs.opswat_log.reason"
        [value]="reason"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.opswat_log?.reason as reason; else na"
    >
        {{ reason }}
    </span>
</ng-template>

<ng-template
    #violationsTemplateRef
    let-row
    let-index="index"
>
    <cds-icon
        status="warning"
        shape="warning-standard"
        size="sm"
    ></cds-icon>
    {{ row.opswat_log?.violations?.length === 1 ? (l10nKeys.oneViolationLabel | vtranslate) : (l10nKeys.numberViolationsLabel | vtranslate: (row.opswat_log?.violations?.length || 0)) }}
</ng-template>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div class="vs-log-cinematic-sub-section-icap__grid-expanded-content">
        <!-- Summary -->
        <div class="vs-log-cinematic-sub-section-icap__grid-expanded-content-section">
            <!-- left column -->
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ globalL10nKeys.summaryLabel | vtranslate }}
                </h5>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.threatIdLabel | vtranslate }}</div>
                <div
                    vsLogFilter
                    property="icap_log.request_logs.opswat_log.threat_id"
                    [value]="threatId"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.opswat_log?.threat_id as threatId; else na"
                >
                    {{ threatId }}
                </div>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.seSourcePortLabel | vtranslate }}</div>
                <div
                    vsLogFilter
                    property="icap_log.request_logs.source_port"
                    [value]="row.source_port"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.source_port !== undefined; else na"
                >
                    {{ row.source_port }}
                </div>

                <div vsLogCinematicFieldTitle>
                    {{ l10nKeys.icapServerIpLabel | vtranslate }}:{{ globalL10nKeys.portLabel | vtranslate }}
                </div>
                <ng-container *ngIf="row.icap_server_ip !== undefined; else na">
                    <span
                        vsLogFilter
                        property="icap_log.request_logs.icap_server_ip"
                        [value]="row.icap_server_ip"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.icap_server_ip }}
                    </span>
                    <ng-container *ngIf="row.icap_server_port !== undefined">
                        :<span vsLogFilter>
                            {{ row.icap_server_port }}
                        </span>
                    </ng-container>
                </ng-container>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.icapLatencyLabel | vtranslate }}</div>
                <div
                    vsLogFilterList
                    property="icap_log.request_logs.latency"
                    [value]="row.latency"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.latency !== undefined; else na"
                >
                    {{ row.latency }} {{ globalL10nKeys.msLabel | vtranslate }}
                </div>
            </div>

            <!-- right column -->
            <div
                *ngIf="row.opswat_log?.violations as violations"
                vsLogCinematicSectionColumn
                [withDivider]="false"
            >
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ violations.length === 1 ? (l10nKeys.oneViolationLabel | vtranslate) : (l10nKeys.numberViolationsLabel | vtranslate: violations.length) }}
                </div>

                <div
                    *ngFor="let violation of violations; trackBy: trackByIndex"
                    class="vs-log-cinematic-sub-section-icap__violation-box"
                >
                    <cds-icon
                        status="warning"
                        shape="warning-standard"
                        size="sm"
                    ></cds-icon>
                    <div class="vs-log-cinematic-sub-section-icap__violation-info">
                        <div
                            vsLogCinematicFieldTitle
                            [withMarginTop]="false"
                        >
                            {{ l10nKeys.fileNameLabel | vtranslate }}
                        </div>
                        <div
                            vsLogFilter
                            property="icap_log.request_logs.opswat_log.violations.file_name"
                            [value]="fileName"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            *ngIf="violation.file_name as fileName; else na"
                        >
                            {{ fileName }}
                        </div>

                        <div vsLogCinematicFieldTitle>{{ l10nKeys.threatNameLabel | vtranslate }}</div>
                        <div
                            vsLogFilter
                            property="icap_log.request_logs.opswat_log.violations.threat_name"
                            [value]="threatName"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            *ngIf="violation.threat_name as threatName; else na"
                        >
                            {{ threatName }}
                        </div>

                        <div vsLogCinematicFieldTitle>{{ l10nKeys.violationResolutionLabel | vtranslate }}</div>
                        <div
                            vsLogFilter
                            property="icap_log.request_logs.opswat_log.violations.resolution"
                            [value]="resolution"
                            (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                            *ngIf="violation.resolution as resolution; else na"
                        >
                            {{ enumLabelHash[resolution] || resolution }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Request / Response -->
        <div class="vs-log-cinematic-sub-section-icap__grid-expanded-content-section">
            <!-- left column -->
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ globalL10nKeys.requestLabel | vtranslate }}
                </h5>

                <div vsLogCinematicFieldTitle>{{ globalL10nKeys.httpMethodLabel | vtranslate }}</div>
                <div
                    *ngIf="row.http_method as httpMethod; else na"
                    vsLogFilter
                    property="icap_log.request_logs.http_method"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    [value]="httpMethod"
                >
                    {{ enumLabelHash[httpMethod] || httpMethod }}
                </div>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.completeBodySentLabel | vtranslate }}</div>
                <div
                    vsLogFilter
                    property="icap_log.request_logs.complete_body_sent"
                    [value]="row.complete_body_sent"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.complete_body_sent !== undefined; else na"
                >
                    {{ row.complete_body_sent }}
                </div>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.icapAbsoluteUriLabel | vtranslate }}</div>
                <div
                    vsLogFilter
                    property="icap_log.request_logs.icap_absolute_uri"
                    [value]="row.icap_absolute_uri"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.icap_absolute_uri as uri; else na"
                >
                    {{ uri }}
                </div>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.icapMethodLabel | vtranslate }}</div>
                <div
                    vsLogFilter
                    property="icap_log.request_logs.icap_method"
                    [value]="row.icap_method"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="row.icap_method as icapMethod; else na"
                >
                    {{ enumLabelHash[icapMethod] || icapMethod }}
                </div>
            </div>

            <!-- right column -->
            <div
                vsLogCinematicSectionColumn
                [withDivider]="false"
                class="vs-log-cinematic-sub-section-icap__response-column"
            >
                <h5 class="vs-log-cinematic-section__column-title vs-log-cinematic-sub-section-icap__grid-expanded-content-response-title">
                    {{ globalL10nKeys.responseLabel | vtranslate }}
                </h5>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.httpResponseCodeLabel | vtranslate }}</div>
                <ng-container *ngIf="row.http_response_code !== undefined; else na">
                    <span
                        vsLogFilter
                        property="icap_log.request_logs.http_response_code"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        [value]="row.http_response_code"
                    >
                        {{ row.http_response_code }}
                    </span>
                </ng-container>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.modifiedContentLengthLabel | vtranslate }}</div>
                <ng-container *ngIf="row.modified_content_length !== undefined; else na">
                    <span
                        vsLogFilterList
                        property="icap_log.request_logs.modified_content_length"
                        [value]="row.modified_content_length"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.modified_content_length }}
                    </span>
                </ng-container>

                <div vsLogCinematicFieldTitle>{{ l10nKeys.icapResponseCodeLabel | vtranslate }}</div>
                <ng-container *ngIf="row.icap_response_code !== undefined; else na">
                    <span
                        vsLogFilter
                        property="icap_log.request_logs.icap_response_code"
                        [value]="row.icap_response_code"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    >
                        {{ row.icap_response_code }}
                    </span>
                </ng-container>
            </div>
        </div>

        <!-- Headers -->
        <div class="vs-log-cinematic-sub-section-icap__grid-expanded-content-section">
            <div vsLogCinematicSectionColumn>
                <h5 class="vs-log-cinematic-section__column-title">
                    {{ l10nKeys.icapHeadersLabel | vtranslate }}
                </h5>
                <avi-diff-grid
                    [config]="headersDiffGridConfig"
                    [rows]="row | subLogHeadersDiffSourcePair : 'icap_headers_sent_to_server' : 'icap_headers_received_from_server' | aviDiffGridRows"
                ></avi-diff-grid>
            </div>
        </div>
    </div>
</ng-template>
