/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AcceptedCipherEnums } from 'generated-types';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { ICipherObject } from 'ajs/modules/security';
import * as l10n from './ssl-ciphers-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component to display cipher list.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ssl-ciphers-grid',
    templateUrl: './ssl-ciphers-grid.component.html',
})
export class SslCiphersGridComponent implements OnInit, AfterViewInit {
    /**
     * List of Cipher objects.
     */
    @Input()
    public ciphers: ICipherObject[];

    /**
     * Fires when a cipher is enabled/disabled.
     */
    @Output()
    public onCipherSelectionChange = new EventEmitter();

    /**
     * Template ref for enabled checkbox row.
     */
    @ViewChild('enableCipherTemplateRef')
    public enableCipherTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for pfs row.
     */
    @ViewChild('pfsTemplateRef')
    public pfsTemplateRef: TemplateRef<HTMLElement>;

    /**
     * SSL Ciphers grid config.
     */
    public sslCiphersGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        // Need this here to hide checkboxes.
        // If the entire config is defined in Afterviewinit phase,
        // layout config will not be taken into consideration
        // by AviDataGridComponent (config will be undefined, so checkboxes will be rendered).
        this.sslCiphersGridConfig = {
            fields: [], // templates will be configured in AfterViewInit
            layout: {
                hideCheckboxes: true,
                withReordering: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.sslCiphersGridConfig.fields = [{
            label: this.l10nService.getMessage(l10nKeys.enabledLabel),
            id: 'enabled',
            templateRef: this.enableCipherTemplateRef,
        }, {
            label: this.l10nService.getMessage(l10nKeys.cipherLabel),
            id: 'cipher',
            transform(cipher: ICipherObject): AcceptedCipherEnums {
                return cipher.cipher;
            },
        }, {
            label: this.l10nService.getMessage(l10nKeys.securityScoreLabel),
            id: 'securityScore',
            transform(cipher: ICipherObject): string {
                return String(cipher.securityScore);
            },
        }, {
            label: this.l10nService.getMessage(l10nKeys.pfsLabel),
            id: 'pfs',
            templateRef: this.pfsTemplateRef,
        }, {
            label: this.l10nService.getMessage(l10nKeys.performanceLabel),
            id: 'performance',
            transform(cipher: ICipherObject): string {
                return cipher.performance;
            },
        }, {
            label: this.l10nService.getMessage(l10nKeys.compatibilityLabel),
            id: 'compatibility',
            transform(cipher: ICipherObject): string {
                return cipher.compatibility;
            },
        }];
    }

    /**
     * Emits cipher change event to parent component.
     */
    public handleCipherSelectionChange(): void {
        this.onCipherSelectionChange.emit();
    }
}
