<div
    class="custom-params-list"
    provideParentForm
>
    <avi-data-grid
        [config]="customParamsGridConfig"
        [rows]="paramsList.config"
        [gridTitle]="gridTitle"
    >
        <avi-data-grid_actions *ngIf="allowCreate">
            <cds-button
                size="sm"
                class="sel-add-script-params"
                (click)="addCustomParam()"
            >
                {{ addButtonLabel }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>

    <ng-template
        #nameFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                name="{{ 'name' + index }}"
                type="text"
                class="sel-param-name"
                clrInput
                placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                [(ngModel)]="row.config.name"
                required
            />
        </avi-input-container>
    </ng-template>

    <ng-template
        #valueFieldTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                name="{{ 'value_' + index }}"
                type="{{ row.config.is_sensitive ? 'password' : 'text' }}"
                class="sel-param-value"
                clrInput
                placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
                [(ngModel)]="row.config.value"
            />
        </avi-input-container>
    </ng-template>

    <ng-template
        #sensitiveFieldTemplateRef
        let-row
        let-index="index"
    >
        <input
            type="checkbox"
            class="sel-sensitive-checkbox"
            clrCheckbox
            name="{{ 'is_sensitive' + index }}"
            [(ngModel)]="row.config.is_sensitive"
            [disabled]="!sensitiveFieldEditable"
        />
    </ng-template>

    <ng-template
        #dynamicFieldTemplateRef
        let-row
        let-index="index"
    >
        <input
            type="checkbox"
            class="sel-dynamic-checkbox"
            clrCheckbox
            name="{{ 'is_dynamic' + index }}"
            [(ngModel)]="row.config.is_dynamic"
        />
    </ng-template>
</div>

