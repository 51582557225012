/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './saml-policy-settings.component.less';
import { SSO_POLICY_COLLECTION_TOKEN } from 'ajs/modules/sso-policy/sso-policy.tokens';
import * as l10n from './saml-policy-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.samlPolicySettingsBindings
 * @see {@link module:avi/vs.samlPolicySettingsComponent samlPolicySettingsComponent}
 */
class SamlPolicySettingsController {
    constructor(
        CertificateCollection,
        SSOPolicyCollection,
        l10nService,
        schemaService,
        stringService,
    ) {
        /**
         * @type {module:avi/app.CertificateCollection}
         */
        this.certificateCollection = new CertificateCollection({
            params: {
                type: 'SSL_CERTIFICATE_TYPE_VIRTUALSERVICE',
            },
        });

        /**
         * @type {SSOPolicyCollection}
         */
        this.ssoPolicyCollection = new SSOPolicyCollection({
            params: {
                type: 'SSO_TYPE_SAML',
            },
            defaults: {
                type: 'SSO_TYPE_SAML',
            },
        });

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        const acsLabelHash = {
            SAML_AUTHN_REQ_ACS_TYPE_URL: l10nService.getMessage(l10nKeys.urlAcsType),
            SAML_AUTHN_REQ_ACS_TYPE_INDEX: l10nService.getMessage(l10nKeys.indexAcsType),
            SAML_AUTHN_REQ_ACS_TYPE_NONE: l10nService.getMessage(l10nKeys.noneAcsType),
        };

        this.acsTypes = schemaService.getEnumValues('SAMLAuthNRequestACSType')
            .map(type => {
                const optionLabel = acsLabelHash[type.value];

                return {
                    ...type,
                    optionLabel,
                };
            });
    }

    /** @override */
    $onDestroy() {
        [
            this.certificateCollection,
            this.ssoPolicyCollection,
        ].forEach(collection => collection.destroy());
    }

    /**
     * Fires when ACS type is changed.
     * Clears acs_index field.
     */
    clearAcsTypeIndexSettings() {
        delete this.samlSpConfig.acs_index;

        /**
         * Sets acs_index field default value to 0
         */
        if (this.samlSpConfig.authn_req_acs_type === 'SAML_AUTHN_REQ_ACS_TYPE_INDEX') {
            this.samlSpConfig.acs_index = 0;
        }
    }

    /**
     * Checks ACS type is index or not
     * @returns {boolean} true if ACS type is 'index'
     */
    get isAcsTypeIndex() {
        return this.samlSpConfig.authn_req_acs_type === 'SAML_AUTHN_REQ_ACS_TYPE_INDEX';
    }
}

SamlPolicySettingsController.$inject = [
    'CertificateCollection',
    SSO_POLICY_COLLECTION_TOKEN,
    'l10nService',
    'schemaService',
    'stringService',
];

/**
 * @name samlPolicySettingsComponent
 * @memberOf module:avi/vs
 * @description
 *      VS Access Policy -> SAML Settings.
 *      Only applicable for HTTP app profile type.
 * @param {module:avi/vs.samlPolicySettingsBindings} bindings
 * @param {module:avi/vs.SamlPolicySettingsController} controller
 * @author Aravindh Nagarajan, Alex Malitsky
 */
angular.module('avi/vs').component('samlPolicySettings', {
    /**
     * @mixin samlPolicySettingsBindings
     * @memberOf module:avi/vs
     * @property {Object} samlSpConfig VirtualService#data#config#saml_sp_config
     * @property {string} ssoPolicyRef VirtualService#data#config#sso_policy_ref
     */
    bindings: {
        samlSpConfig: '=',
        ssoPolicyRef: '=',
    },
    controller: SamlPolicySettingsController,
    templateUrl:
        'src/components/applications/virtualservice/vs-access-policy-settings/' +
        'saml-policy-settings/saml-policy-settings.component.html',
});
