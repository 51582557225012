/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'in-use-interface-list-expanded-content';
const prefix = `${moduleName}.${componentName}`;

export const floatingIpAddressesLabel = `${prefix}.floatingIpAddressesLabel`;

export const ENGLISH = {
    [floatingIpAddressesLabel]: 'Floating IP Addresses ({0})',
};
