<div
    class="license-tier-select-card sel-license-tier-select-card"
    [ngClass]="{ 'license-tier-select-card--disabled': disabled }"
    (click)="onCardClick()"
>
    <div class="grid-container clr-wrapper">
        <avi-radio
            class="license-tier-select-card__radio grid-container__cell--w--1"
            name="licence_tier_type"
            value="ACTIVE"
            [(ngModel)]="selected"
            [disabled]="disabled"
        ></avi-radio>
        <div class="grid-container__cell--w--11">
            <div class="license-tier-select-card__header">
                <div class="license-tier-select-card__name sel-license-tier-select-card-name">
                    {{ l10nKeys.tierHeader | vtranslate : getTierTypeLabel(tierType) }}
                </div>
                <div class="license-tier-select-card__service-core-info sel-license-tier-select-card-service-core-info">
                    ({{ l10nKeys.numberOfLicenses | vtranslate : tierUsageFraction }})
                </div>
            </div>
            <div class="license-tier-select-card__description">
                {{ getTierTypeDescription(tierType) }}
            </div>
        </div>
    </div>
    <!-- transcluded content for alert message -->
    <ng-content></ng-content>
</div>
