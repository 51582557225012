<ng-container *ngIf="caseComments.length; else noCaseComments">
    <div
        class="case-comments"
        *ngFor="let caseComment of caseComments; trackBy: trackById"
    >
        <div class="case-comments__user-icon">
            <cds-icon
                shape="user"
                size="16"
                status="info"
            ></cds-icon>
        </div>
        <div>
            <div class="case-comments__comment-header">
                <h6>{{ getUserFullName(caseComment) }}</h6>
                <span class="case-comments__created-date">{{ caseComment.created_date | aviDate }}</span>
            </div>
            <div class="case-comments__comment-body">{{ caseComment.comment_body }}</div>
        </div>
    </div>
</ng-container>

<ng-template #noCaseComments>
    <div class="case-comments__no-case-comments">{{ l10nKeys.noCommentsMadeMessage | vtranslate }}</div>
</ng-template>
