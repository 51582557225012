/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-tenancy-mode-card';
const prefix = `${moduleName}.${componentName}`;

export const tenancyModeHeader = `${prefix}.tenancyModeHeader`;
export const ipRouteDomainLabel = `${prefix}.ipRouteDomainLabel`;
export const ipRouteDomainSharedAcrossTenant = `${prefix}.ipRouteDomainSharedAcrossTenant`;
export const ipRouteDomainPerTenant = `${prefix}.ipRouteDomainPerTenant`;
export const seManagementLabel = `${prefix}.seManagementLabel`;
export const seManagementProviderContext = `${prefix}.seManagementProviderContext`;
export const seManagementTenantContext = `${prefix}.seManagementTenantContext`;
export const tenantSeAccessLabel = `${prefix}.tenantSeAccessLabel`;
export const tenantSeAccessReadOnly = `${prefix}.tenantSeAccessReadOnly`;
export const tenantSeAccessNoAccess = `${prefix}.tenantSeAccessNoAccess`;

export const ENGLISH = {
    [tenancyModeHeader]: 'Tenancy Mode',
    [ipRouteDomainLabel]: 'IP Route Domain',
    [ipRouteDomainSharedAcrossTenant]: 'Share Across Tenants',
    [ipRouteDomainPerTenant]: 'Per Tenant',
    [seManagementLabel]: 'SE Managed Within',
    [seManagementProviderContext]: 'Provider Context (Shared)',
    [seManagementTenantContext]: 'Tenant Context',
    [tenantSeAccessLabel]: 'Tenant Service Engine Access',
    [tenantSeAccessReadOnly]: 'Read',
    [tenantSeAccessNoAccess]: 'No Access',
};
