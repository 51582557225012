/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { controllerInitialDataStateFeatureKey } from './controller-initial-data.selectors';
import { controllerInitialDataReducer } from './controller-initial-data.reducer';
import { ControllerInitialDataEffects } from './controller-initial-data.effects';

/**
 * @description Global store to manage data returned from Initial-Data API.
 *
 * @author Akul Aggarwal, Alex Klyuev
 */

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(controllerInitialDataStateFeatureKey, controllerInitialDataReducer),
        EffectsModule.forFeature([ControllerInitialDataEffects]),
    ],
    declarations: [],
})
export class ControllerInitialDataStoreModule {}
