/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'ip-reputation-type-mapping-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const identificationSectionTitle = `${prefix}.identificationSectionTitle`;

export const selectTypeLabel = `${prefix}.selectTypeLabel`;
export const clientClassLabel = `${prefix}.clientClassLabel`;
export const clientTypeLabel = `${prefix}.clientTypeLabel`;
export const selectClientClassLabel = `${prefix}.selectClientClassLabel`;
export const selectClientTypePlaceholder = `${prefix}.selectClientTypeLabel`;
export const identifierLabel = `${prefix}.identifierLabel`;
export const enterStringPlaceholder = `${prefix}.enterStringPlaceholder`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Type Mapping',
    [modalTitleEdit]: 'Edit Type Mapping',
    [identificationSectionTitle]: 'Identification',

    [selectTypeLabel]: 'Select Type',
    [clientClassLabel]: 'Client Class',
    [clientTypeLabel]: 'Client Type',
    [selectClientClassLabel]: 'Select Class Type',
    [selectClientTypePlaceholder]: 'Select Client Type',
    [identifierLabel]: 'Identifier',
    [enterStringPlaceholder]: 'Enter String',
};
