<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        class="sel-name"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputLabel | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="script_uri"
                            for="script_uri"
                            required
                        >
                            {{ l10nKeys.scriptInputLabel | vtranslate }}
                        </label>

                        <async-file-upload-with-upload-service
                            name="script_uri"
                            class="sel-async-file-upload"
                            [filePath]="filePath"
                            [fileApi]="fileApi"
                            [(ngModel)]="editable.config.script_uri"
                            (onError)="handleUploadError($event)"
                            required
                        ></async-file-upload-with-upload-service>
                    </div>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.scriptHeaderWithCount | vtranslate : editable.scriptParams.count }}
                        </h6>
                        <custom-params-grid
                            [paramsList]="editable.scriptParams"
                            addButtonLabel="{{ l10nKeys.addButtonLabel | vtranslate }}"
                            hasSensitiveColumn
                            hasDynamicColumn
                        ></custom-params-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
