/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services';

import {
    aviRepeatedStringsRangeValidator,
    aviRepeatedStringsUniquenessValidator,
    regexPatternValidator,
} from 'ng/modules/avi-forms/validators';

import * as l10n from './avi-repeated-strings.l10n';

import './avi-repeated-strings.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Default maxLimit value.
const DEFAULT_MAX_REPEATED_STRINGS_LIMIT = Infinity;

// Default minLimit value.
const DEFAULT_MIN_REPEATED_STRINGS_LIMIT = 0;

// Min input size for an item being edited
const MINIMUM_EDIT_INPUT_SIZE = 4;

/**
 * @description
 *      Component to add repeated strings from an input field.
 *      On Enter key, string will be added to the list.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'avi-repeated-strings',
    templateUrl: './avi-repeated-strings.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedStringsComponent),
        },
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviRepeatedStringsComponent),
        },
    ],
})
export class AviRepeatedStringsComponent implements OnInit, ControlValueAccessor, Validator {
    /**
     * Configurable placeholder.
     */
    @Input()
    public placeholder = l10nKeys.placeholderLabel;

    /**
     * Max number of values that can be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If maxLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Default is Infinity.
     */
    @Input()
    public maxLimit ?= DEFAULT_MAX_REPEATED_STRINGS_LIMIT;

    /**
     * Min number of values that should be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If minLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Default is 0.
     */
    @Input()
    public minLimit ?= DEFAULT_MIN_REPEATED_STRINGS_LIMIT;

    /**
     * ObjectType of the field (Optional).
     */
    @Input()
    public objectType?: string;

    /**
     * Name of the field (Optional).
     */
    @Input()
    public fieldName?: string;

    /**
     * When true, wont invalidate if duplicate values are added.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Name of regex from regex.utils to apply to input field.
     * When value present, validates string using regexPatternValidator.
     */
    @Input()
    public regex?: string;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /**
     * Template Ref for main input element.
     */
    @ViewChild('mainInput')
    public mainInputRef: ElementRef<HTMLInputElement>;

    /**
     * Template Ref for item edit input element.
     */
    @ViewChild('editInput')
    public editInputRef: ElementRef<HTMLInputElement>;

    /**
     * Flag to indicate if any item is being edited.
     */
    public isEditing = false;

    /**
     * Index of the current pill being edited.
     */
    public editingIndex: number;

    /**
     * Model value for input tag.
     */
    public currentEditable = '';

    /**
     * List of current items in the array.
     * This is the value that gets validated by the Validator interface.
     */
    public items: string[] = [];

    /**
     * Flag to indicate whether certain click event actions should be ignored.
     * Explained above handleClick().
     */
    private ignoreClick = false;

    /**
     * Flag to indicate whether edit focusout handler should be ignored.
     * Usage explained in handleEditEnter().
     */
    private ignoreEditFocusout = false;

    constructor(
        private readonly schemaService: SchemaService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly renderer: Renderer2,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Deal with the edge case where an item is being edited,
     * and another item is clicked on while focus is still in the other item.
     *
     * Because closing an edited item may cause the array to re-render,
     * there is an edge case where the click handler function does not run,
     * but the CSS of the Clarity tag is still altered for some reason.
     *
     * This fixes that by setting all Clarity tags back to their original state.
     * We cannot access the clicked tag because the click handler doesn't run.
     */
    @HostListener('click')
    private handleClick(): void {
        this.items?.forEach((item, index) => {
            try {
                const itemTagElement = this.renderer.selectRootElement(`#item-${index}`, true);

                this.renderer.removeAttribute(itemTagElement, '_active');
                itemTagElement.blur();
            } catch (e) { /** empty catch block */ }
        });
    }

    /**
     * If objectType & fieldName is passed by user,
     * try to set maxLimit.
     * @override
     */
    public ngOnInit(): void {
        const {
            objectType,
            fieldName,
            maxLimit,
            minLimit,
            schemaService,
        } = this;

        // If maxLimit/minLimit is not set by user
        // try to get it from schema bindings.
        if (objectType && fieldName) {
            if (maxLimit === DEFAULT_MAX_REPEATED_STRINGS_LIMIT) {
                try {
                    this.maxLimit = schemaService.getFieldMaxElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }

            if (minLimit === DEFAULT_MIN_REPEATED_STRINGS_LIMIT) {
                try {
                    this.minLimit = schemaService.getFieldMinElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }
        }
    }

    /**
     * Handle main input being submitted via enter.
     */
    public handleMainInputEnter(event: Event): void {
        // Avoid bubbling enter event,
        // for example to stop full-modal form submission.
        event.preventDefault();
        event.stopPropagation();

        if (this.currentEditable) {
            this.addItem();
        }
    }

    /**
     * Handle main input being submitted via focusout (when outside of input is clicked).
     */
    public handleMainInputFocusout(): void {
        if (this.currentEditable) {
            this.addItem();
            this.setIgnoreClick();
        } else {
            // just make the component dirty
            // for `required` validation.
            this.onTouched();
        }
    }

    /**
     * Handler for backspace event in main input.
     * if currentEditable is '', last added tag will be removed.
     * This only applies for the main input.
     * When editing an item, it's more intuitive for the user to delete
     * everything via backspace, but still be in the same item so that
     * they can type something new in.
     */
    public handleMainInputBackspace(): void {
        // if there is nothing on the input &
        // user presses backspace key,
        // delete the last added value.
        if (!this.currentEditable && this.items.length) {
            this.removeItem(this.items.length - 1);
        }
    }

    /**
     * Convert the selected item into an input and place focus in the input.
     */
    public openItemForEditing(index: number, item: string): void {
        // If clicked while another item was being edited, ignore
        if (this.ignoreClick) {
            return;
        }

        // open selected item
        this.editingIndex = index;
        this.isEditing = true;
        this.currentEditable = item;

        // manually put cursor in the input
        this.changeDetector.detectChanges();
        this.setInputSize();
        this.editInputRef.nativeElement.focus();
    }

    /**
     * Handle edited item being submitted via enter.
     */
    public handleEditEnter(event: Event): void {
        // Avoid bubbling enter event,
        // for example to stop full-modal form submission.
        event.preventDefault();
        event.stopPropagation();

        this.saveEditedItem();

        // Set ignoreEditFocusout to true for a small period.
        // This is necessary because closing edit from enter will
        // cause a focusout event on the edit input, thereby running the
        // focusout handler as well.
        this.ignoreEditFocusout = true;
        setTimeout(() => this.ignoreEditFocusout = false, 100);

        // manually focus main input to keep enter behavior consistent.
        this.mainInputRef.nativeElement.focus();
    }

    /**
     * Handle edited item being submitted via focusout (when outside of input is clicked).
     */
    public handleEditFocusout(): void {
        if (this.ignoreEditFocusout) {
            return;
        }

        this.saveEditedItem();
        this.setIgnoreClick();
    }

    /**
     * Remove an item.
     */
    public removeItem(index: number): void {
        // Ignore if clicked while another item was being edited, or if disabled
        if (this.ignoreClick || this.disabled) {
            return;
        }

        this.items.splice(index, 1);

        this.setIgnoreClick();
        this.emitModelChange();
    }

    /**
     * Remove all items.
     */
    public removeAllItems(): void {
        if (this.disabled) {
            return;
        }

        this.items = [];

        this.emitModelChange();
    }

    /**
     * Change the size of the input to match the width of the current text
     * when the user is editing a filter.
     */
    public setInputSize(): void {
        // ngModel sets empty input to undefined; change to empty string for below
        if (!this.currentEditable) {
            this.currentEditable = '';
        }

        const inputSize = Math.max(MINIMUM_EDIT_INPUT_SIZE, this.currentEditable.length + 3);

        this.editInputRef.nativeElement.setAttribute('size', inputSize.toString());
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Write the items model value.
     */
    public writeValue(value: string[]): void {
        this.items = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***************************************************************************
     * IMPLEMENTING Validator INTERFACE
    */

    /**
     * To invalidate the field incase of duplicate or out-of-range values or
     * not matching with provided regex.
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        const range: [number, number] = [this.minLimit, this.maxLimit];

        const duplicateCheck = !this.allowDuplicates &&
            aviRepeatedStringsUniquenessValidator()(control);

        const regexCheck = Boolean(this.regex) && regexPatternValidator(this.regex)(control);

        return aviRepeatedStringsRangeValidator(range)(control) ||
            duplicateCheck || regexCheck || null;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /*************************************************************************/

    /**
     * Adds an item to ngModel list.
     */
    private addItem(): void {
        if (!Array.isArray(this.items)) {
            this.items = [];
        }

        this.items.push(this.currentEditable);
        this.currentEditable = '';
        this.emitModelChange();
    }

    /**
     * Update the value of the item being edited.
     */
    private saveEditedItem(): void {
        const initialItemValue = this.items[this.editingIndex];

        if (!this.currentEditable) {
            this.items.splice(this.editingIndex, 1);
        } else {
            this.items[this.editingIndex] = this.currentEditable;
        }

        if (this.currentEditable !== initialItemValue) {
            this.emitModelChange();
        }

        this.currentEditable = '';
        this.isEditing = false;
        this.editingIndex = undefined;
    }

    /**
     * Emits model change event.
     */
    private emitModelChange(): void {
        this.onChange(this.items);
        this.onTouched();
    }

    /**
     * Set ignoreClick to true for a small period
     * in case another item is clicked on when clicking out of an open item.
     *
     * Used to avoid edge case described above handleClick().
     */
    private setIgnoreClick(): void {
        this.ignoreClick = true;
        setTimeout(() => this.ignoreClick = false, 100);
    }
}
