/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    GslbPoolMember,
} from 'object-types';
import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import {
    GSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';
import './gslb-pool-member-virtual-service.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './gslb-pool-member-virtual-service.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      GSLB pool member virtual service component. It is used for VS configured pool members.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-pool-member-virtual-service',
    templateUrl: './gslb-pool-member-virtual-service.component.html',
})
export class GslbPoolMemberVirtualServiceComponent {
    @Input()
    public editable: GslbPoolMemberConfigItem;

    /**
     * Reference for VS collection of selected site.
     */
    @Input()
    public selectedSiteVsCollection: GSLBVSCollection;

    @Input()
    public siteClusterControllerDropdownOptions: IAviDropdownOption[];

    @Input()
    public resolvedVsVipIpDropdownOptions: IAviDropdownOption[];

    @Output()
    public onSiteClusterControllerChange = new EventEmitter<void>();

    @Output()
    public onVsSelectionChange = new EventEmitter<void>();

    /**
     * Emit when the resolved Vs VIP IP address changes.
     */
    @Output()
    public onResolvedVsVipIpChange = new EventEmitter<void>();

    public readonly objectType = GslbPoolMember;

    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
