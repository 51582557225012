/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { IHttpResponse } from 'angular';
import { pluck } from 'underscore';

import {
    ImageType,
    IUpgradePreviewResponse,
    IUpgradeStatusInfo,
    NodeType,
    SeGroupErrorRecovery,
} from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import { InitialDataService } from 'ng/modules/core/services/initial-data/initial-data.service';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';
import { naturalSort } from 'ng/shared/utils/natural-sort.utils';

import {
    imageCategoryHash,
    IRollbackApiPayload,
    IUpdateConfig,
    IUpgradeApiPayload,
    IUpgradeNodeVersionHash,
    IUpgradeStatusResponse,
    UpgradeState,
    UpgradeStateGroup,
    UpgradeType,
} from '../update.types';

type THttpWrapper = typeof HttpWrapper;

/**
 *  Interval, in seconds, between each call for fetching upgrade states in UpgradeNodeCollection.
 */
export const UPGRADE_STATE_POLLING_INTERVAL = 10;

/**
 * API to make POST call for upgrade.
 */
const UPGRADE_API_URL = '/api/upgrade';

/**
 * API to make POST call for rollback.
 */
const ROLLBACK_API_URL = '/api/rollback';

/**
 * API to make POST call for upgrade preview.
 */
const UPGRADE_PREVIEW_API_URL = `${UPGRADE_API_URL}/preview`;

/**
 * API to make POST call for rollback preview.
 */
const ROLLBACK_PREVIEW_API_URL = `${ROLLBACK_API_URL}/preview`;

/**
 * API to make GET call fetching upgrade status.
 */
const UPGRADE_STATUS_API_URL = '/api/upgradestatusinfo';

/**
 * @desc Service to manage upgrade/rollback operations of controller and SEGs.
 * @author Nisar Nadaf
 */
@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    private readonly httpWrapper: HttpWrapper;

    /**
     * Map from upgrade state to upgrade state group.
     */
    private upgradeStateToStateGroup: Map<typeof UpgradeState, UpgradeStateGroup>;

    constructor(
        private readonly initialDataService: InitialDataService,
        private readonly schemaService: SchemaService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();

        this.upgradeStateToStateGroup = this.generateUpgradeStateToStateGroupMap();
    }

    /**
     * Generate a complete version hash from the raw data passed-in.
     * rawVersionData - Version data with format as <version>-<build>-<date>,
     *     ie. '20.1.1-5884-20200316.231632'.
     * rawPatchVersionData - Patch version data with format as <major>p<minor>,
     *     ie. '2p1'.
     */
    public static getVersionAsHash(
        rawVersionData: string,
        rawPatchVersionData?: string,
    ): IUpgradeNodeVersionHash {
        let versionHash = {};
        let patchVersionHash = {};

        if (rawVersionData) {
            const parts = rawVersionData.split('-');

            if (parts.length !== 3) {
                throw new Error('Invalid version data format!');
            }

            const [version, build, date] = parts;

            versionHash = {
                version,
                build,
                date,
            };
        }

        if (rawPatchVersionData) {
            const patchVersion = rawPatchVersionData;
            const [majorPatchVersion, minorPatchVersion] = patchVersion.split('p');

            patchVersionHash = {
                patchVersion,
                majorPatchVersion,
                minorPatchVersion,
            };
        }

        return {
            ...versionHash,
            ...patchVersionHash,
        };
    }

    /**
     * Set config for SEG upgrade by retrieving image ids and versions.
     */
    public static getSegUpgradeConfig(segList: ITSEGroup[], images: Image[]): IUpdateConfig {
        const segUuids = pluck(segList, 'id');

        let systemImageId: string;
        let sePatchImageId: string;
        let targetVersion: string;

        images.forEach(image => {
            const { type, id, version } = image;

            switch (type) {
                case ImageType.IMAGE_TYPE_SYSTEM:
                    systemImageId = id;
                    targetVersion = version;
                    break;

                case ImageType.IMAGE_TYPE_PATCH:
                    sePatchImageId = id;

                    if (!targetVersion) {
                        targetVersion = version;
                    }
            }
        });

        return {
            upgradeType: UpgradeType.UPGRADE_TYPE_SEG_UPGRADE,
            segUuids,
            systemImageId,
            sePatchImageId,
            targetVersion,
        };
    }

    /**
     * Generate full version string from hash.
     * Expected returned string will be as format <majorVersion>-<patchVersion>-<build>,
     * ie. 20.1.1-2p1-5038'. When withBuild is false, expected returned string will be as format
     * <majorVersion>-<patchVersion>, ie. '20.1.1-2p1'.
     */
    public static generateCompleteVersionFromHash(
        versionHash: IUpgradeNodeVersionHash,
        withBuild = false,
    ): string {
        const { version, patchVersion, build } = versionHash;
        const parts = [version];

        if (patchVersion) {
            parts.push(patchVersion);
        }

        if (withBuild) {
            parts.push(build);
        }

        return parts.join('-');
    }

    /**
     * Check if SE Group upgrade/rollback feasible or not.
     */
    public static isSegUpgradeAvailable(upgradeConfig: IUpdateConfig): boolean {
        const { upgradeType } = upgradeConfig;

        switch (upgradeType) {
            case UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE: {
                const { systemImageId, sePatchImageId } = upgradeConfig;

                return !!(systemImageId || sePatchImageId);
            }

            case UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK:
            case UpgradeType.UPGRADE_TYPE_SEG_UPGRADE:
            case UpgradeType.UPGRADE_TYPE_SEG_ROLLBACK:
                return true;
        }

        return false;
    }

    /**
     * Validate a list of SEGs can be bundled together for upgrade or not.
     */
    public static isSegListUpgradable(segList: ITSEGroup[]): boolean {
        return Boolean(UpdateService.getSegUpgradeListBaseImageVersion(segList));
    }

    /**
     * Get base image version used to fetch compatible images upon. By "base image" here it refers
     * to the complete version of a node (ie. 20.1.1-2p1) serving as the starting point of upgrade.
     * In order to fetch compatible images for all SEGs, if there's a patch on any of them, the
     * highest patch version must be included into the base version string.
     * Multi-SEG upgrade is only supported when:
     * 1. all the SEGs are of the same major version.
     * 2. all existing patch versions must be of the same patch family (major patch version).
     *    (eg. [20.1.1-2p1, 20.1.1-2p2] is valid, while [20.1.1-2p1, 20.1.1-5p1] is not.
     *     since '2p1' and '2p2' share the same major patch version '2',
     *     while '2p1' and '5p1' don't - with major patch versions '2' and '5')
     * Therefore, when there's no node passed in or either of the above two conditions is false, an
     * empty string will be returned indicating no base image version can be found as the start
     * point of upgrade for all passed SEG nodes.
     */
    public static getSegUpgradeListBaseImageVersion(segList: ITSEGroup[]): string {
        if (!segList.length) {
            return '';
        }

        const [firstNode] = segList;
        const { majorVersion: commonMajorVersion } = firstNode;

        let commonMajorPatchVersion = '';
        let highestMinorPatchVersion = '';

        /**
         * Base version can be calculated only when all the nodes have the same major version and
         * major patch version; loop will be short-circuited once either of them is proved false
         */

        for (const seg of segList) {
            const {
                majorVersion,
                patchVersion,
                majorPatchVersion,
                minorPatchVersion,
            } = seg;

            // Major verions doesn't match, return an empty string.
            if (majorVersion !== commonMajorVersion) {
                return '';
            }

            // When there is a patch on this node.
            if (patchVersion) {
                if (!commonMajorPatchVersion) {
                    commonMajorPatchVersion = majorPatchVersion;
                }

                // Major patch version doesn't match, return an empty string.
                if (majorPatchVersion !== commonMajorPatchVersion) {
                    return '';
                }

                if (!highestMinorPatchVersion) {
                    highestMinorPatchVersion = minorPatchVersion;
                } else {
                    highestMinorPatchVersion =
                        Number(minorPatchVersion) > Number(highestMinorPatchVersion) ?
                            minorPatchVersion : highestMinorPatchVersion;
                }
            }
        }

        const parts = [commonMajorVersion];

        if (highestMinorPatchVersion) {
            /**
             * Generate highest patch version string, highest patch version is decided by the
             * highest minor patch version.
             */
            parts.push(`${commonMajorPatchVersion}p${highestMinorPatchVersion}`);
        }

        return parts.join('-');
    }

    /**
     * Get target base version from where SEG patch can be applied when under system upgrade.
     */
    public static getBaseVersionForSystemUpgradeSegPatch(
        upgradeConfig: IUpdateConfig,
    ): string {
        const {
            upgradeType,
            systemImageId,
            sePatchImageId,
            targetMajorVersion,
            targetPatchVersion,
        } = upgradeConfig;

        if (upgradeType !== UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE) {
            return '';
        }
        /**
         * System patch image is selected.
         * (When under system upgrade, SE patch id only exists with selection of system patch image)
         */

        if (sePatchImageId) {
            return `${targetMajorVersion}-${targetPatchVersion}`;
        }

        // Only system image is selected.
        if (systemImageId) {
            return targetMajorVersion;
        }

        return '';
    }

    /**
     * Getter for this.upgradeStateToStateGroup.
     */
    public get upgradeStateToStateGroupMap(): Map<typeof UpgradeState, UpgradeStateGroup> {
        return this.upgradeStateToStateGroup;
    }

    /**
     * Compare two major version strings (with format as '20.1.1').
     * Return Negative number when srcVersion is lower than destVersion, zero when
     * they're equivalent, positive when srcVersion is higher than destVersion.
     */
    public static compareMajorVersions(srcVersion: string, destVersion: string): number {
        const versionStringSegmentCount = 3;
        const srcVersionParts = srcVersion.split('.');
        const destVersionParts = destVersion.split('.');

        if (srcVersionParts.length !== versionStringSegmentCount ||
            destVersionParts.length !== versionStringSegmentCount
        ) {
            throw new Error('Invalid version format!');
        }

        return naturalSort(srcVersion, destVersion);
    }

    /**
     * Return default upgrade/rollback request payload based on system config input.
     */
    private static getBasicUpgradeRequestBody(
        upgradeConfig: IUpdateConfig,
    ): IUpgradeApiPayload | IRollbackApiPayload {
        const { upgradeType } = upgradeConfig;

        let mainConfig;

        switch (upgradeType) {
            case UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE: {
                const {
                    systemImageId,
                    controllerPatchImageId,
                    sePatchImageId,
                } = upgradeConfig;

                mainConfig = {
                    image_uuid: systemImageId,
                    controller_patch_uuid: controllerPatchImageId,
                    se_patch_uuid: sePatchImageId,
                };

                break;
            }

            case UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK: {
                const { rollbackType } = upgradeConfig;

                mainConfig = { rollback_type: rollbackType };

                break;
            }

            case UpgradeType.UPGRADE_TYPE_SEG_UPGRADE: {
                const {
                    systemImageId,
                    sePatchImageId,
                    segUuids,
                } = upgradeConfig;

                mainConfig = {
                    image_uuid: systemImageId,
                    se_patch_uuid: sePatchImageId,
                    se_group_uuids: segUuids,
                };

                break;
            }

            case UpgradeType.UPGRADE_TYPE_SEG_ROLLBACK: {
                const { rollbackType } = upgradeConfig;

                mainConfig = { rollback_type: rollbackType };

                break;
            }
        }

        const optionalOpsConfig = {
            system: false,
            skip_warnings: false,
        };

        return {
            ...mainConfig,
            ...optionalOpsConfig,
        };
    }

    /**
     * Validate a list of images can be bundled together for upgrade or not. Image list is not valid
     * when either of the followings is true:
     * General:
     *     a) Total number of images is zero.
     *     b) Total number of images is greater than two; at most one system image and one
     * system/SE patch image can be used at the same time.
     *     c) All the images must have the same major version.
     *     d) Number of any of the four kinds of images
     *       (system/system patch/controller patch/SE patch) is greater than 1.
     *     e) A system patch image co-exists with an arbitrary patch image.
     *     f) When only patch image is present, the target major version of the patch is higher than
     *        that of the current controller.
     * For system upgrade only:
     *     g) A controller patch image co-exists with an SE patch image.
     */
    // TODO: Split into small methods for better understanding.
    public isImageListValidForUpgrade(images: Image[], upgradeType: UpgradeType): boolean {
        if (!upgradeType) {
            throw new Error('An upgrade type must be passed to validate image list!');
        }

        if (!images.length || images.length > 2) {
            return false;
        }

        let systemImageCount = 0;
        let systemPatchImageCount = 0;
        let controllerPatchImageCount = 0;
        let sePatchImageCount = 0;

        const { controllerVersion: currentControllerMajorVersion } = this.initialDataService;
        // Store the target major version
        let targetMajorVersion = '';
        /**
         * All the selected images must have the same major version, otherwise this flag will be set
         * to true.
         */
        let majorVersionConflicted = false;

        for (const image of images) {
            const { type, category, majorVersion } = image;

            if (targetMajorVersion) {
                /**
                 * When the existing image target major version is different from that
                 * of this selected image.
                 */
                if (targetMajorVersion !== majorVersion) {
                    majorVersionConflicted = true;

                    break;
                }
            } else {
                targetMajorVersion = majorVersion;
            }

            switch (type) {
                case ImageType.IMAGE_TYPE_SYSTEM:
                    systemImageCount++;

                    break;

                case ImageType.IMAGE_TYPE_PATCH:
                    switch (category) {
                        case imageCategoryHash.IMAGE_CATEGORY_HYBRID:
                            systemPatchImageCount++;

                            break;

                        case imageCategoryHash.IMAGE_CATEGORY_CONTROLLER:
                            controllerPatchImageCount++;

                            break;

                        case imageCategoryHash.IMAGE_CATEGORY_SE:
                            sePatchImageCount++;

                            break;
                    }
            }
        }

        const patchMajorVersionSurpassesController = !systemImageCount &&
            UpdateService.compareMajorVersions(
                targetMajorVersion,
                currentControllerMajorVersion,
            ) > 0;

        /* ------------------ General invalid rules -------------------*/
        // Not all images have the same major version
        if (majorVersionConflicted || patchMajorVersionSurpassesController) {
            return false;
        }

        // Count of any type of image is greater than 1
        if (systemImageCount > 1 || systemPatchImageCount > 1 || controllerPatchImageCount > 1 ||
                sePatchImageCount > 1
        ) {
            return false;
        }

        // System patch image co-exists with any other type of patch images
        if (systemPatchImageCount && (sePatchImageCount || controllerPatchImageCount)) {
            return false;
        }

        /* ------------------ Upgrade type specific rules -------------------*/
        if (upgradeType === UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE &&
            controllerPatchImageCount && sePatchImageCount
        ) {
            return false;
        }

        return true;
    }

    /**
     * Start upgrade process w/o ALL service engine groups. Independent SE group upgrade is not of
     * option by using this method.
     */
    public startSystemUpgrade(
        upgradeConfig: IUpdateConfig,
        skipWarnings: boolean,
        system: boolean,
        actionOnSegFailure = SeGroupErrorRecovery.SUSPEND_UPGRADE_OPS_ON_ERROR,
    ): Promise<void> {
        const requestBody: IUpgradeApiPayload =
            UpdateService.getBasicUpgradeRequestBody(upgradeConfig);

        requestBody.skip_warnings = skipWarnings;

        // Set SE Group based operations when SEGs are possible to be upgraded
        if (system &&
            UpdateService.isSegUpgradeAvailable(upgradeConfig)
        ) {
            requestBody.se_group_options = {
                action_on_error: actionOnSegFailure,
            };

            // override default system flag
            requestBody.system = true;
        }

        const requestConfig = {
            url: UPGRADE_API_URL,
            data: requestBody,
            method: HttpMethod.POST,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Start rollback process w/o ALL service engine groups. Independent SE group rollback is not of
     * option by using this method.
     */
    public startSystemRollback(
        upgradeConfig: IUpdateConfig,
        skipWarnings: boolean,
        system: boolean,
    ): Promise<void> {
        const requestBody: IRollbackApiPayload =
            UpdateService.getBasicUpgradeRequestBody(upgradeConfig);

        requestBody.skip_warnings = skipWarnings;

        if (system) {
            requestBody.system = true;
        }

        const requestConfig = {
            url: ROLLBACK_API_URL,
            data: requestBody,
            method: HttpMethod.POST,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Start SE group upgrade process. Multi-SEG upgrade is supported.
     */
    public startSegUpgrade(
        upgradeConfig: IUpdateConfig,
        skipWarnings: boolean,
        actionOnSegFailure = SeGroupErrorRecovery.SUSPEND_UPGRADE_OPS_ON_ERROR,
    ): Promise<void> {
        const requestBody: IUpgradeApiPayload =
            UpdateService.getBasicUpgradeRequestBody(upgradeConfig);

        requestBody.se_group_uuids = upgradeConfig.segUuids;

        requestBody.skip_warnings = skipWarnings;

        requestBody.se_group_options = {
            action_on_error: actionOnSegFailure,
        };

        const requestConfig = {
            url: UPGRADE_API_URL,
            data: requestBody,
            method: HttpMethod.POST,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Make API call to get prompts before upgrade/rollback happens; particularly the list of
     * checks.
     */
    public getPreUpgradePrompts(
        upgradeConfig: IUpdateConfig,
    ): Promise<IHttpResponse<IUpgradePreviewResponse>> {
        const { upgradeType } = upgradeConfig;

        const requestBody: IRollbackApiPayload | IUpgradeApiPayload =
            UpdateService.getBasicUpgradeRequestBody(upgradeConfig);

        let previewApi;

        switch (upgradeType) {
            case UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE:
            case UpgradeType.UPGRADE_TYPE_SEG_UPGRADE:
                previewApi = UPGRADE_PREVIEW_API_URL;
                break;

            case UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK:
            case UpgradeType.UPGRADE_TYPE_SEG_ROLLBACK:
                previewApi = ROLLBACK_PREVIEW_API_URL;
                break;
        }

        const requestConfig = {
            url: previewApi,
            data: requestBody,
            method: HttpMethod.POST,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Make API call to get upgrade status info.
     */
    public getUpgradeStatus(
        nodeType = NodeType.NODE_CONTROLLER_CLUSTER,
    ): Promise<IHttpResponse<IUpgradeStatusResponse>> {
        const requestConfig = {
            url: `${UPGRADE_STATUS_API_URL}?node_type=${nodeType}`,
            method: HttpMethod.GET,
        };

        const upgradeStatusResponse = this.httpWrapper.request(requestConfig);

        upgradeStatusResponse.then((upgradeInfo: IHttpResponse<IUpgradeStatusResponse>) => {
            const {
                patch_version: patch = '',
                version: versionAndBuild,
            }: IUpgradeStatusInfo = upgradeInfo.data.results[0] ?? {};

            const {
                version = '',
                build = '',
            } = UpdateService.getVersionAsHash(versionAndBuild);

            const {
                controllerVersion,
                controllerBuild,
                controllerPatch,
            } = this.initialDataService;

            // Set flag if version info from initial data is stale.
            const isVersionDataStale = controllerVersion !== version ||
                controllerBuild !== Number(build) || controllerPatch !== patch;

            if (isVersionDataStale) {
                this.initialDataService.loadData(true);
            }
        });

        return upgradeStatusResponse;
    }

    /**
     * Generate hash mapping every upgrade state enum to to state group enum.
     */
    private generateUpgradeStateToStateGroupMap(): Map<typeof UpgradeState, UpgradeStateGroup> {
        const stateToStateGroupMap: Map<typeof UpgradeState, UpgradeStateGroup> = new Map();
        const stateGroupEnums = this.schemaService.getEnumValues('UpgradeFsmUiState');

        stateGroupEnums.forEach(upgradeUiStateEnumValue => {
            const { ui_fsm_state_options: { allowed_states: states } } = upgradeUiStateEnumValue;
            const { value: stateGroup } = upgradeUiStateEnumValue;

            states.forEach(state => stateToStateGroupMap[state] = stateGroup);
        });

        return stateToStateGroupMap;
    }
}
