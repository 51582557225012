/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    AwsEncryptionMode,
    IAwsConfiguration,
    IAwsEncryption,
    IAwsZoneConfig,
    IProxyConfiguration,
} from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { AwsConfiguration } from 'object-types';

import {
    find,
    findWhere,
} from 'underscore';

const REGIONS_API = '/api/aws-get-regions';
const ASSUME_ROLES_API = '/api/aws-get-assume-roles';
const VERIFY_CREDENTIALS_API = '/api/aws-verify-credentials';
const GET_NETWORKS_API = '/api/aws-get-networks';
const GET_KMS_CMKS_API = '/api/aws-get-kms-cmks';

const FETCH_REGIONS_REQUEST_ID = 'fetch-regions';
const FETCH_AWS_ASSUME_ROLES_REQUEST_ID = 'fetch-aws-assume-roles';
const AWS_VERIFY_CREDENTIALS = 'aws-verify-credentials';
const AWS_GET_NETWORKS = 'aws-get-networks';
const AWS_GET_KMS_CMKS = 'aws-get-kms-cmks';

type TAwsConfigurationPartial = Omit<
IAwsConfiguration, 'ebs_encryption' | 's3_encryption' | 'sqs_encryption' | 'zones'
>;

interface IAwsConfig extends TAwsConfigurationPartial {
    ebs_encryption: MessageItem<IAwsEncryption>;
    s3_encryption: MessageItem<IAwsEncryption>;
    sqs_encryption: MessageItem<IAwsEncryption>;
    zones: RepeatedMessageItem<MessageItem<IAwsZoneConfig>>;
}

/**
 * Type of promise returned by fetchAwsRegions method.
 */
interface IRegion {
    description: string;
    name: string;
}

/**
 * Type of Payload used for login/fetchAssumeRoles api calls.
 */
interface ILoginPayload {
    username?: string;
    password?: string;
    region?: string;
    iam_assume_role?: string;
    use_iam_roles?: boolean;
    proxy_host?: string;
    proxy_port?: number;
    proxy_user?: string;
    proxy_pass?: string;
    service?: string;
    uuid?: string;
    vpc?: string;
}

/**
 * Type of Subnets received from back end.
 */
export interface ISubnet {
    cidr?: string;
    default?: string;
    id?: string;
    name?: string;
}

/**
 * Type of network object received from back end.
 */
interface INetwork {
    availability_zone: string;
    subnets: ISubnet[];
}

/**
 * Type of Assume Roles received from back end.
 */
export interface IAssumeRole {
    account: string;
    role: string;
}

/**
 * Type of promise returned by awsLogin method.
 */
export interface IVpc {
    cidr?: string;
    default?: boolean;
    id?: string;
    name?: string;
}

/**
 * Type of promise returned by fetchEncyptionKeys method.
 */
export interface IEncryptionKeys {
    alias?: string;
    arn?: string;
    default?: boolean;
    label?: string;
}

/**
 * Holds the constant values for DNS Types.
 */
export enum DnsTypes {
    NONE = 'NONE',
    AMAZON_ROUTE_53 = 'AMAZON_ROUTE_53',
    DNS_PROFILE = 'DNS_PROFILE',
}

/**
 * @description
 *
 *   AWS Configuration MessageItem.
 *
 * @author Sarthak kapoor
 */

export type TAwsCredentials = Pick<IAwsConfiguration,
'access_key_id' | 'secret_access_key' | 'use_iam_roles' | 'iam_assume_role'>;

export class AwsConfigurationConfigItem extends MessageItem<IAwsConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'secretStubStr',
    ];

    /**
     * Holds the value of selected VPC ID.
     */
    public selectedVpcId: string;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * Stores the list of vpcs.
     */
    private vpcList: IVpc[];

    /**
     * Object for storing list of subnets with availability zones as keys.
     */
    private awsAzNetworkMap: Record<string, ISubnet[]>;

    /**
     * Object for storing in progress API Call Ids.
     */
    private apiCallsInProgress: Record<string, boolean>;

    /**
     * AWS provides SQS encryption for certain regions only.
     */
    private awsSqsEncryptionRegions: Record<string, boolean>;

    /**
     * Holds the regex pattern to parse the encryption keys.
     */
    private awsEncryptionKeysRegex: RegExp;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AwsConfiguration,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.apiCallsInProgress = {};

        this.awsSqsEncryptionRegions = {
            'us-east-1': true, // N Virginia
            'us-east-2': true, // Ohio
            'us-west-2': true, // Oregon
        };

        this.awsEncryptionKeysRegex = /^.*:key\//i;
    }

    /**
     * Fetches list of AWS regions.
     */
    public async fetchAwsRegions(isEditFlow = false): Promise<IRegion[]> {
        this.busy = true;
        this.errors = null;

        const requestConfig = {
            url: REGIONS_API,
            method: HttpMethod.GET,
            requestId: FETCH_REGIONS_REQUEST_ID,
        };

        try {
            this.apiCallsInProgress[FETCH_REGIONS_REQUEST_ID] = true;

            const response = await this.httpWrapper.request(requestConfig);
            const { regions = [] } = response.data;

            return regions;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            delete this.apiCallsInProgress[FETCH_REGIONS_REQUEST_ID];

            this.busy = isEditFlow;
        }
    }

    /**
     * Fetches list of Assume Roles.
     */
    public async fetchAssumeRoles(
        requestObject?: TAwsCredentials,
        cloudId?: string,
        proxyConfiguration?: IProxyConfiguration,
    ): Promise<IAssumeRole[]> {
        this.httpWrapper.cancelRequest(FETCH_AWS_ASSUME_ROLES_REQUEST_ID);

        const payload = this.getAwsCredentialsPayload(requestObject, cloudId, proxyConfiguration);

        const requestConfig = {
            url: ASSUME_ROLES_API,
            method: HttpMethod.POST,
            data: payload,
            requestId: FETCH_AWS_ASSUME_ROLES_REQUEST_ID,
        };

        try {
            const response = await this.httpWrapper.request(requestConfig);

            const { assume_roles: assumeRoles = [] } = response.data;

            return assumeRoles;
        } catch (errors) {
            return Promise.reject(errors);
        }
    }

    /**
     * Logs in and calls fetchVpcList method to fetch VPC List.
     */
    public async awsLogin(
        requestObject?: TAwsCredentials,
        cloudId?: string,
        proxyConfiguration?: IProxyConfiguration,
    ): Promise<IVpc[]> {
        this.errors = null;
        this.selectedVpcId = '';

        const payload = this.getAwsCredentialsPayload(requestObject, cloudId, proxyConfiguration);

        try {
            const vpcListResponse = await this.fetchVpcList(payload);
            const {
                config,
                vpcList,
            } = this;

            /**
             * If we get only one VPC and if its different from the one in config,
             * reset VPC and zones.
             */
            if (vpcList.length === 1 && config.vpc_id !== vpcList[0].id) {
                config.vpc = vpcList[0].name;
                config.vpc_id = vpcList[0].id;
                config.zones.removeAll();
            }

            /**
             * If we get more than one VPC, `fetchAwsAvailabilityZones` method will check whether
             * config.vpc_id is present in the vpcList received from server.
             * If not, VPC and zones will be reset.
             */
            if (config.vpc_id) {
                this.selectedVpcId = config.vpc_id;

                await this.fetchAwsAvailabilityZones(requestObject, cloudId, proxyConfiguration);
            }

            return vpcListResponse;
        } catch (error) {
            this.errors = error;

            return Promise.reject(error.error);
        }
    }

    /**
     * Fetches list of availability zones.
     */
    public async fetchAwsAvailabilityZones(
        requestObject: TAwsCredentials,
        cloudId: string,
        proxyConfiguration: IProxyConfiguration,
    ): Promise<void> {
        this.errors = null;
        this.busy = true;
        this.awsAzNetworkMap = {};

        const {
            config,
            vpcList,
        } = this;

        const vpc = findWhere(vpcList, { id: config.vpc_id });

        if (!vpc) {
            config.zones.removeAll();

            delete config.vpc;
            delete config.vpc_id;

            this.selectedVpcId = '';

            this.busy = this.isBusy;

            return;
        }

        this.selectedVpcId = config.vpc_id;
        config.vpc = vpc.name;

        const payload = this.getAwsCredentialsPayload(
            requestObject,
            cloudId,
            proxyConfiguration,
        );

        payload.vpc = config.vpc_id;

        const requestConfig = {
            url: GET_NETWORKS_API,
            method: HttpMethod.POST,
            data: payload,
            requestId: AWS_GET_NETWORKS,
        };

        try {
            this.apiCallsInProgress[AWS_GET_NETWORKS] = true;

            const response = await this.httpWrapper.request(requestConfig);

            const { networks = [] } = response.data;

            this.processAwsNetworks(networks);
        } catch (errors) {
            this.errors = errors.data;

            return Promise.reject(errors.data.error);
        } finally {
            delete this.apiCallsInProgress[AWS_GET_NETWORKS];
            this.busy = this.isBusy;
        }
    }

    /**
     * Fetches Encryption Keys.
     */
    public async fetchEncryptionKeys(type: string, cloudId?: string): Promise<IEncryptionKeys[]> {
        const requestId = `${AWS_GET_KMS_CMKS}_${type}`;

        if (this.apiCallsInProgress[requestId]) {
            this.httpWrapper.cancelRequest(requestId);

            delete this.apiCallsInProgress[requestId];
        }

        this.busy = true;
        this.errors = null;

        const payload = this.getAwsCredentialsPayload(undefined, cloudId);

        payload.vpc = null;
        payload.service = type;

        const requestConfig = {
            url: GET_KMS_CMKS_API,
            method: HttpMethod.POST,
            data: payload,
            requestId,
        };

        try {
            this.apiCallsInProgress[requestId] = true;

            const response = await this.httpWrapper.request(requestConfig);

            const { keys = [] } = response.data;

            return this.parseAwsEncryptionKeys(keys);
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            delete this.apiCallsInProgress[requestId];
            this.busy = this.isBusy;
        }
    }

    /**
     * Sets the aws login credentials on aws config object.
     */
    public setAwsLoginCredentials(loginCredentials: TAwsCredentials): void {
        const { config } = this;

        const {
            access_key_id: accessKeyId,
            secret_access_key: secretAccessKey,
            use_iam_roles: useIamRoles,
            iam_assume_role: iamAssumeRole,
        } = loginCredentials;

        config.access_key_id = accessKeyId;
        config.secret_access_key = secretAccessKey;
        config.use_iam_roles = useIamRoles;

        if (iamAssumeRole) {
            config.iam_assume_role = iamAssumeRole;
        }
    }

    /**
     * Adds availability Zone.
     */
    public addAvailabilityZone(): void {
        const { config } = this;
        const { zones } = config;

        zones.add();
    }

    /**
     * Returns the count of Avaialbility Zones.
     */
    public get availabilityZonesCount(): number {
        const { config } = this;
        const { zones } = config;

        return zones.count;
    }

    /**
     * Returns availability zones network map.
     */
    public get azNetworkMap(): Record<string, ISubnet[]> {
        return this.awsAzNetworkMap;
    }

    /**
     * Updates the Management Network Name of selected availability Zone.
     */
    public updateSeManagementNetworkName(index: number): void {
        const {
            awsAzNetworkMap,
            config,
        } = this;
        const { zones } = config;
        const { config: zoneConfig } = zones.at(index);
        const {
            availability_zone: availabilityZone,
            mgmt_network_uuid: managementNetworkId,
        } = zoneConfig;

        const networks = awsAzNetworkMap[availabilityZone];

        const { name } = find(networks, network => network.id === managementNetworkId);

        zoneConfig.mgmt_network_name = name;
    }

    /**
     * Removes vpc related field from config on change of,
     * use cross-account assume role option.
     * Also, invokes removeAvailabilityZones method to remove all slected availability zones.
     */
    public removeVpc(): void {
        const { config } = this;

        delete config.vpc;
        delete config.vpc_id;

        this.selectedVpcId = '';

        this.removeAvailabilityZones();
    }

    /**
     * Removes all availability zones from config.
     * This is called when user changes the VPC value from UI.
     */
    public removeAvailabilityZones(): void {
        const { config } = this;
        const { zones } = config;

        zones.removeAll();
    }

    /**
     * Resets the region value to previous state if,
     * user clicks on cancel button in the warning dialog displayed on changing region value.
     */
    public resetRegion(selectedRegion: string): void {
        const { config } = this;

        config.region = selectedRegion;
    }

    /**
     * Resets the vpc values to previous state if,
     * user clicks on cancel button in the warning dialog displayed on changing vpc value.
     */
    public resetVpc(): void {
        const {
            config,
            selectedVpcId,
            vpcList,
        } = this;

        const vpc = findWhere(vpcList, { id: selectedVpcId });

        if (vpc) {
            config.vpc_id = selectedVpcId;
            config.vpc = vpc.name;
        }
    }

    /**
     * Resets SE Management Network Option on change of availability zone value.
     */
    public resetSeManagementNetworkOption(index: number): void {
        const { config } = this;
        const { zones } = config;

        const { config: zoneConfig } = zones.at(index);

        delete zoneConfig.mgmt_network_uuid;
    }

    /**
     * Checks whether SQS encryption is available for the current AWS cloud configuration.
     */
    public isSqsEncryptionSupported(): boolean {
        const {
            awsSqsEncryptionRegions,
            config,
        } = this;

        const {
            region,
            use_sns_sqs: useSnsSqs,
        } = config;

        if (useSnsSqs) {
            return region in awsSqsEncryptionRegions;
        }

        return false;
    }

    /**
     * Sets the value for sqs Encryption mode.
     */
    public setSqsEncryptionMode(): void {
        const { config } = this;
        const { sqs_encryption: sqsEncryption } = config;

        if (sqsEncryption) {
            const { config: sqsEncryptionConfig } = sqsEncryption;

            sqsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_SSE_KMS;
        }
    }

    /**
     * Sets the value for Ebs Encryption mode.
     */
    public setEbsEncryptionMode(): void {
        const { config } = this;
        const { ebs_encryption: ebsEncryption } = config;
        const { config: ebsEncryptionConfig } = ebsEncryption;

        ebsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_SSE_KMS;
    }

    /**
     * Sets the value for S3 Encryption mode.
     */
    public setS3EncryptionMode(): void {
        const { config } = this;
        const { s3_encryption: s3Encryption } = config;
        const { config: s3EncryptionConfig } = s3Encryption;

        s3EncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_SSE_KMS;
    }

    /**
     * Resets the sqs_encryption object in config.
     */
    public resetSqsEncryption(): void {
        const { config } = this;
        const { sqs_encryption: sqsEncryption } = config;

        if (sqsEncryption && !this.isSqsEncryptionSupported()) {
            delete config.sqs_encryption;
        } else if (sqsEncryption && this.isSqsEncryptionSupported()) {
            const { config: sqsEncryptionConfig } = sqsEncryption;

            delete sqsEncryptionConfig.master_key;
            sqsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
        } else if (!sqsEncryption && this.isSqsEncryptionSupported()) {
            this.safeSetSqsEncryption();
        }
    }

    /**
     * Resets the ebs_encryption object in config.
     */
    public resetEbsEncryption(): void {
        const { config } = this;
        const { ebs_encryption: ebsEncryption } = config;
        const { config: ebsEncryptionConfig } = ebsEncryption;

        delete ebsEncryptionConfig.master_key;
        ebsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
    }

    /**
     * Resets the s3_encryption object in config.
     */
    public resetS3Encryption(): void {
        const { config } = this;
        const { s3_encryption: s3Encryption } = config;
        const { config: s3EncryptionConfig } = s3Encryption;

        delete s3EncryptionConfig.master_key;
        s3EncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
    }

    /**
     * Resets all the fields dependent on Regions data.
     */
    public resetRegionsRelatedFields(): void {
        const { config } = this;

        delete config.vpc;
        delete config.vpc_id;

        this.selectedVpcId = '';

        config.zones.removeAll();

        this.resetS3Encryption();
        this.resetEbsEncryption();
        this.resetSqsEncryption();
    }

    /**
     * Safe Sets SQS Encryption in case a region which allows SQS Encyrption is selected.
     */
    public safeSetSqsEncryption(): void {
        if (this.isSqsEncryptionSupported()) {
            const { config } = this;

            if (!config.sqs_encryption) {
                this.safeSetNewChildByField('sqs_encryption');
            }
        }
    }

    /**
     * Cancels fetch assume roles api call if user clicks on cancel in edit credentials dialog.
     */
    public cancelFetchAssumeRolesCall(): void {
        const {
            apiCallsInProgress,
            httpWrapper,
        } = this;

        if (apiCallsInProgress && FETCH_AWS_ASSUME_ROLES_REQUEST_ID in apiCallsInProgress) {
            httpWrapper.cancelRequest(FETCH_AWS_ASSUME_ROLES_REQUEST_ID);
        }
    }

    /**
     * Modifies route53_integration value according to the user selection.
     */
    public handleRoute53IntegrationChange(route53IntegrationValue: boolean): void {
        const { config } = this;

        config.route53_integration = route53IntegrationValue;
    }

    /**
     * Cancels all the outgoing requests on click of cancel button in
     * Credentials Verification Dialog.
     */
    public cancelAllRequests(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;
        const {
            ebs_encryption: ebsEncryption,
            s3_encryption: s3Encryption,
        } = config;

        const { config: ebsEncryptionConfig } = ebsEncryption;

        const { config: s3EncryptionConfig } = s3Encryption;

        if (!ebsEncryptionConfig.master_key) {
            ebsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
        }

        if (!s3EncryptionConfig.master_key) {
            s3EncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
        }

        if (!this.isSqsEncryptionSupported()) {
            delete config.sqs_encryption;
        } else {
            const { sqs_encryption: sqsEncryption } = config;

            const { config: sqsEncryptionConfig } = sqsEncryption;

            if (!sqsEncryptionConfig.master_key) {
                sqsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_NONE;
            } else {
                // To ensure, we do not miss setting the mode correctly.
                sqsEncryptionConfig.mode = AwsEncryptionMode.AWS_ENCRYPTION_MODE_SSE_KMS;
            }
        }
    }

    /**
     * @override
     * Called to destroy and cancel all pending requests.
     */
    public destroy(): void {
        this.cancelAllRequests();

        super.destroy();
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.ebs_encryption) {
            this.safeSetNewChildByField('ebs_encryption');
        }

        if (!config.s3_encryption) {
            this.safeSetNewChildByField('s3_encryption');
        }

        if (!config.sqs_encryption) {
            this.safeSetNewChildByField('sqs_encryption');
        }
    }

    /**
     * Fetches VPC List and updates the config accordingly.
     */
    private async fetchVpcList(payload: ILoginPayload): Promise<IVpc[]> {
        const requestConfig = {
            url: VERIFY_CREDENTIALS_API,
            method: HttpMethod.POST,
            data: payload,
            requestId: AWS_VERIFY_CREDENTIALS,
        };

        try {
            this.busy = true;

            this.apiCallsInProgress[AWS_VERIFY_CREDENTIALS] = true;

            const response = await this.httpWrapper.request(requestConfig);

            const { vpcs: vpcList = [] } = response.data;

            this.vpcList = [...vpcList];

            return vpcList;
        } catch (errors) {
            return Promise.reject(errors.data);
        } finally {
            delete this.apiCallsInProgress[AWS_VERIFY_CREDENTIALS];
            this.busy = this.isBusy;
        }
    }

    /**
     * Processes backend response with availability zones and management networks.
     * Removes outdated ones from the config object.
     */
    private processAwsNetworks(networks: INetwork[]): void {
        const { config } = this;
        const { zones } = config;
        const {
            config: zonesConfig,
            count: zonesCount,
        } = zones;

        const savedAvailabilityZone = zones.at(0)?.config?.availability_zone;

        this.awsAzNetworkMap = {};

        for (const network of networks) {
            const {
                availability_zone: availabilityZone,
                subnets,
            } = network;

            this.awsAzNetworkMap[availabilityZone] = subnets;
        }

        if (zonesCount > 1 && savedAvailabilityZone) {
            const filteredZones = zonesConfig.reduce((filteredZones, zone) => {
                const zoneExists = this.haveAwsZoneInList(zone.config);

                if (zoneExists) {
                    filteredZones.push(zone.config);
                }

                return filteredZones;
            }, []);

            zones.updateConfig(filteredZones);
        }

        if (!zones.count) {
            this.addAvailabilityZone();
        }

        // By default select the first availability zone in case only 1 is fetched from back end.
        if (networks.length === 1 && !savedAvailabilityZone) {
            const { availability_zone: availabilityZone } = networks[0];
            const { config: firstZoneConfig } = zones.at(0);

            firstZoneConfig.availability_zone = availabilityZone;
        }
    }

    /**
     * Checks if selected availability zone exists in the fetched list.
     */
    private haveAwsZoneInList(zone: IAwsZoneConfig): boolean {
        const {
            availability_zone: availabilityZone,
            mgmt_network_uuid: managementNetworkId,
        } = zone;

        const { awsAzNetworkMap } = this;

        const zoneExists = !availabilityZone || availabilityZone in awsAzNetworkMap;

        // Check if we have a previously saved mgmt_network.
        if (
            zoneExists &&
            awsAzNetworkMap &&
            availabilityZone &&
            managementNetworkId
        ) {
            const validNetwork = find(
                awsAzNetworkMap[availabilityZone], subnet => {
                    const { id } = subnet;

                    return id === managementNetworkId;
                },
            );

            if (!validNetwork) {
                delete zone.mgmt_network_uuid;
            }
        }

        return zoneExists;
    }

    /**
     * Returns the request params for login/fetch assumeRoles api call.
     */
    private getAwsCredentialsPayload(
        requestObject?: TAwsCredentials,
        cloudId?: string,
        proxyConfiguration?: IProxyConfiguration,
    ): ILoginPayload {
        const { config } = this;
        const { region } = config;

        let loginPayload: ILoginPayload;

        if (requestObject) {
            loginPayload = this.getLoginPayload(requestObject);
        } else {
            loginPayload = this.getLoginPayload({
                access_key_id: config.access_key_id,
                secret_access_key: config.secret_access_key,
                iam_assume_role: config.iam_assume_role,
                use_iam_roles: config.use_iam_roles,
            });
        }

        const payload: ILoginPayload = {
            region,
            ...loginPayload,
        };

        if (proxyConfiguration) {
            const {
                host,
                password,
                port,
                username,
            } = proxyConfiguration;

            payload.proxy_host = host;
            payload.proxy_port = port;
            payload.proxy_user = username;
            payload.proxy_pass = password;
        }

        if (cloudId && (!payload.username || !payload.password)) {
            payload.uuid = cloudId;
            delete payload.proxy_pass;
        }

        return payload;
    }

    /**
     * Adds fields to payload object.
     */
    private getLoginPayload(loginCredentials: TAwsCredentials): ILoginPayload {
        const secretStubStr = this.getAjsDependency_('secretStubStr');

        const {
            access_key_id: accessKeyId,
            secret_access_key: secretAccessKey,
            iam_assume_role: iamAssumeRole,
            use_iam_roles: useIamRoles,
        } = loginCredentials;

        return {
            username: accessKeyId === secretStubStr ? undefined : accessKeyId,
            password: secretAccessKey === secretStubStr ? undefined : secretAccessKey,
            iam_assume_role: iamAssumeRole,
            use_iam_roles: useIamRoles,
        };
    }

    /**
     * Returns if busy flag has to be set to false.
     */
    private get isBusy(): boolean {
        const { apiCallsInProgress } = this;

        return !(!apiCallsInProgress || !Object.prototype.hasOwnProperty.call(apiCallsInProgress));
    }

    /**
     * Parses the encryptions keys and formats them.
     */
    private parseAwsEncryptionKeys(keys: IEncryptionKeys[]): IEncryptionKeys[] {
        if (!Array.isArray(keys)) {
            return [];
        } else if (!keys.length) {
            return [];
        }

        let defaultKeyIndex;

        keys.forEach((key, index) => {
            const {
                alias,
                arn,
                default: isDefault,
            } = key;

            const { awsEncryptionKeysRegex } = this;

            const shortArn = arn.replace(awsEncryptionKeysRegex, '');

            key.label = alias ? `${alias} - ${shortArn}` : shortArn;

            if (isDefault) {
                defaultKeyIndex = index;
            }
        });

        // When found and not 0, let's move it to the top of the list.
        if (defaultKeyIndex) {
            const [defaultKey] = keys.splice(defaultKeyIndex, 1);

            keys.unshift(defaultKey);
        }

        return keys;
    }
}
