<avi-confirmation
    class="in-use-interface-list-dialog clr-wrapper"
    headerText="{{ l10nKeys.dialogHeader | vtranslate }}"
    (onClose)="dismiss()"
    size="lg"
>
    <avi-confirmation_modal-content>
        <avi-data-grid
            gridTitle="{{ l10nKeys.virtualNetworkInterfacesHeader | vtranslate : activeVnicInterfaces.length }}"
            [config]="activeVnicInterfacesGridConfig"
            [rows]="activeVnicInterfaces"
        ></avi-data-grid>
        <avi-data-grid
            *ngIf="activeVlanInterfaces.length"
            class="in-use-interface-list-dialog__vlan-interfaces-datagrid"
            gridTitle="{{ l10nKeys.vlanInterfacesHeader | vtranslate : activeVlanInterfaces.length }}"
            [config]="activeVlanInterfacesGridConfig"
            [rows]="activeVlanInterfaces"
        ></avi-data-grid>
    </avi-confirmation_modal-content>
    <avi-confirmation_modal-actions>
        <cds-button
            action="outline"
            (click)="dismiss()"
        >
            {{ globalL10nKeys.closeLabel | vtranslate }}
        </cds-button>
    </avi-confirmation_modal-actions>
</avi-confirmation>
<ng-template
    #interfaceSubnets
    let-row
>
    <div *ngFor="let network of row.config.vnic_networks.config; trackBy: trackByIndex">
        {{ network.config.mode }} {{ network.config.ip | subnetString }}
    </div>
</ng-template>
<ng-template
    #interfaceExpandedContentTemplateRef
    let-row
>
    <in-use-interface-list-expanded-content
        [seId]="seId"
        [interfaceName]="row.config.if_name"
        class="in-use-interface-list-dialog__expanded-content"
    ></in-use-interface-list-expanded-content>
</ng-template>
