/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * It contains constants related to AlbServices connection and registration status.
 */

const ALBSERVICES_CONNECTED = 'ALBSERVICES_CONNECTED';
const ALBSERVICES_DISCONNECTED = 'ALBSERVICES_DISCONNECTED';
const ALBSERVICES_REGISTERED = 'ALBSERVICES_REGISTERED';
const ALBSERVICES_DEREGISTERED = 'ALBSERVICES_DEREGISTERED';

export const portalStatus = {
    ALBSERVICES_CONNECTED,
    ALBSERVICES_DISCONNECTED,
    ALBSERVICES_REGISTERED,
    ALBSERVICES_DEREGISTERED,
};

export const defaultPortalUrl = 'https://portal.avipulse.vmware.com';

/**
 * Duration to clear the interval checking the state of login window.
 */
export const loginWindowClearIntervalDuration = 1000;

/**
 * Duration between refresh status api call and configuration update.
 */
export const configUpdateDuration = 5000;

/**
 * Duration for polling pulse status on Pulse page in ms.
 */
export const statusPollingDuration = 10000;

/**
 * Duration between polling connection status after pulse authentication in ms.
 */
export const connectionPollingDuration = 3000;

/**
 * Maximum number of times polling can take place after pulse authentication.
 */
export const maxConnectionPollingCount = 4;
