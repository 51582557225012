/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 * This file is used to downgrade any providers for AngularJS injection. If there are any AngularJS
 * components or controllers that require a service or factory registered as a provider in Angular,
 * then that provider needs to be downgraded here.
 */

import angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { MessageMapService } from 'ng/modules/core/services/message-map/message-map.service';
import { StringService } from 'string-service';

import { Pool } from 'items/pool.item.factory';
import { PoolCollection } from 'collections/pool.collection.factory';
import { PoolGroup } from 'items/pool-group.item.factory';
import { PoolGroupCollection } from 'collections/pool-group.collection.factory';
import { ServiceEngineCollection } from 'collections/service-engine.collection.factory';
import { ServiceEngineItem } from 'items/service-engine.item.factory';
import { ClusterItem } from 'items/cluster.item.factory';
import { GSLBCollection } from 'collections/gslb.collection.factory';

const injectables = [
    {
        injectable: MessageMapService,
        name: 'messageMapService',
    },
    {
        injectable: Pool,
        name: 'Pool',
    },
    {
        injectable: PoolCollection,
        name: 'PoolCollection',
    },
    {
        injectable: PoolGroup,
        name: 'PoolGroup',
    },
    {
        injectable: PoolGroupCollection,
        name: 'PoolGroupCollection',
    },
    {
        injectable: ServiceEngineItem,
        name: 'ServiceEngine',
    },
    {
        injectable: ServiceEngineCollection,
        name: 'ServiceEngineCollection',
    },
    {
        injectable: StringService,
        name: 'stringService',
    },
    {
        injectable: ClusterItem,
        name: 'Cluster',
    },
    {
        injectable: GSLBCollection,
        name: 'GSLBCollection',
    },
];

injectables.forEach(({ injectable, name }) => {
    angular.module('aviApp').factory(name, downgradeInjectable(injectable) as any);
});
