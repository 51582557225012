<div class="full-modal-breadcrumbs">
    <div class="full-modal-breadcrumbs__breadcrumbs-container">
        <ng-container *ngFor="let modalLayout of modalLayoutStack; last as isLast; trackBy: trackByIndex">
            <full-modal-breadcrumb
                [isActive]="isLast"
                name="{{ modalLayout.getName() }}"
                description="{{ modalLayout.getDescription() }}"
                class="full-modal-breadcrumbs__breadcrumb"
            ></full-modal-breadcrumb>
        </ng-container>
    </div>
    <div class="full-modal-breadcrumbs__footer"></div>
</div>
