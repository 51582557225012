/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for traffic-clone-profile related tokens.
 * @author Harmeet Kaur
 */

/**
 * Ajs Dependency token for TrafficCloneProfileCollection.
 */
export const TRAFFIC_CLONE_PROFILE_COLLECTION_TOKEN = 'TrafficCloneProfileCollection';

/**
 * Ajs Dependency token for TrafficCloneProfileItem.
 */
export const TRAFFIC_CLONE_PROFILE_ITEM_TOKEN = 'TrafficCloneProfileItem';

/**
 * Ajs Dependency token for CloneServerConfigItem.
 */
export const CLONE_SERVER_CONFIG_ITEM_TOKEN = 'CloneServerConfigItem';
