/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'error-page';

export const errorPageBodyModalBreadcrumbTitle = `${prefix}.errorPageBodyModalBreadcrumbTitle`;
export const errorPageProfileModalBreadcrumbTitle = `${prefix}.errorPageProfileModalBreadcrumbTitle`;
export const errorPageBodyBreadcrumbTitle = `${prefix}.errorPageBodyBreadcrumbTitle`;
export const errorPageUrlBreadcrumbTitle = `${prefix}.errorPageUrlBreadcrumbTitle`;

export const ENGLISH = {
    [errorPageBodyModalBreadcrumbTitle]: 'Error Page Body',
    [errorPageProfileModalBreadcrumbTitle]: 'Error Page Profile',
    [errorPageBodyBreadcrumbTitle]: 'Error Page (Body)',
    [errorPageUrlBreadcrumbTitle]: 'Error Page (URL)',
};
