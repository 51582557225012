/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import {
    debounce,
    isEmpty,
} from 'underscore';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { SchemaService } from 'ajs/modules/core/services/schema-service';

import {
    PathMatchConfigItem,
    QueryMatchConfigItem,
    StringMatchConfigItem,
} from 'ajs/modules/match';

import {
    PathMatch,
    QueryMatch,
    StringMatch,
} from 'object-types';

import { MatchCase } from 'generated-types';

import { IStringGroupRefsOrMatchStringsConfig } from '../../match.types';
import * as l10n from './path-or-query-match.l10n';
import './path-or-query-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// TODO Rename this component, since now it also reflects StringMatch
/**
 * @description Match component for the Path, Query, or String match messages.
 * @author alextsg
 */
@Component({
    selector: 'path-or-query-match',
    templateUrl: './path-or-query-match.component.html',
})
export class PathOrQueryMatchComponent implements OnInit {
    @Input()
    public editable: PathMatchConfigItem | StringMatchConfigItem | QueryMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public subLabel: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * Message item ObjectType passed to this match component for fields in the template.
     * Enables reusing same component for different match types.
     * Value can be PathMatch, QueryMatch, or StringMatch.
     */
    @Input()
    public matchObjectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * True if configuring string groups is not allowed.
     */
    @Input()
    public disallowStringGroups = false;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /**
     * List of values that should be hidden from selection.
     */
    @Input()
    public hiddenValues: string[] = [];

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Sets to true if match_case has SENSITIVE as a value.
     */
    public isCaseSensitive: boolean;

    /**
     * Dropdown options for the Match Criteria selection.
     */
    public matchCriteriaDropdownOptions: IAviDropdownOption[] = [];

    /**
     * List of stringGroup and customValue configurations.
     */
    public stringGroupRefsOrCustomValuesConfigs: IStringGroupRefsOrMatchStringsConfig[] = [];

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.handleStringsChange = debounce(this.handleStringsChange, 250);
    }

    /**
     * @override
     * Populates the list of stringGroupRefsOrCustomValuesConfigs with the configured refs and match
     * strings.
     */
    public ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
        this.isCaseSensitive = (this.editable.config as any).match_case === MatchCase.SENSITIVE;

        const {
            string_group_refs: stringGroupRefs,
            match_str: matchStrings,
        } = this.editable.config;

        if (!this.disallowStringGroups && !isEmpty(stringGroupRefs)) {
            this.addItem({
                ...this.getDefaultConfig(),
                stringGroupRefs: [...stringGroupRefs],
            });
        }

        if (!isEmpty(matchStrings)) {
            this.addItem({
                ...this.getDefaultConfig(),
                matchStrings: [...matchStrings],
            });
        }

        if (isEmpty(stringGroupRefs) && isEmpty(matchStrings)) {
            this.addItem();
        }

        let options = this.schemaService.getEnumValues('StringOperation');

        if (this.hiddenValues?.length) {
            options = options.filter(option => !this.hiddenValues.includes(option.value));
        }

        this.matchCriteriaDropdownOptions = createOptionsFromEnumProps(options);
    }

    /**
     * Called to add an address entry.
     */
    public addItem(config: IStringGroupRefsOrMatchStringsConfig = this.getDefaultConfig()): void {
        this.stringGroupRefsOrCustomValuesConfigs.push(config);
    }

    /**
     * Called to remove a config entry.
     */
    public removeItem(index: number): void {
        this.stringGroupRefsOrCustomValuesConfigs.splice(index, 1);
        this.handleStringsChange();
    }

    /**
     * Called when a string or groupRef has changed. We need to take all the configured strings and
     * groupRefs add them to the path match message item.
     */
    public handleStringsChange(): void {
        this.editable.clearStringGroupRefsAndMatchStrings();

        const allStringGroupRefs: string[] = [];
        const allMatchStrings: string[] = [];

        this.stringGroupRefsOrCustomValuesConfigs.forEach(config => {
            const { stringGroupRefs = [], matchStrings = [] } = config;

            allStringGroupRefs.push(...stringGroupRefs);
            allMatchStrings.push(...matchStrings);
        });

        this.editable.addStringGroupRefs(allStringGroupRefs);
        this.editable.addMatchStrings(allMatchStrings);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * Trackby function for the *ngFor of IpAddrMatchEntryComponents.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Checks matchObjectType for current match type.
     * Returns true for PathMatch false otherwise.
     */
    public isPathMatchObjectType(): boolean {
        return this.matchObjectType === PathMatch;
    }

    /**
     * Checks matchObjectType for current match type.
     * Returns true for StringMatch false otherwise.
     */
    public isStringMatchObjectType(): boolean {
        return this.matchObjectType === StringMatch;
    }

    /**
     * Checks matchObjectType for current match type.
     * Returns true for QueryMatch false otherwise.
     */
    public isQueryMatchObjectType(): boolean {
        return this.matchObjectType === QueryMatch;
    }

    /**
     * Updates match_case based on checkbox selection change.
     */
    public handleCaseSensitiveSelection(selected: boolean): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
        const { matchCase } = (this.editable.config as any).match_case;

        if (matchCase) {
            this.isCaseSensitive = matchCase === MatchCase.SENSITIVE;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-extra-parens
        (this.editable.config as any).match_case =
            selected ? MatchCase.SENSITIVE : MatchCase.INSENSITIVE;
    }

    /**
     * Returns an empty config object.
     */
    private getDefaultConfig(): IStringGroupRefsOrMatchStringsConfig {
        return {
            stringGroupRefs: [],
            matchStrings: [],
        };
    }
}
