/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-access-ssl-card';
const prefix = `${moduleName}.${componentName}`;

export const sslHeader = `${prefix}.sslHeader`;
export const sslProfileLabel = `${prefix}.sslProfileLabel`;
export const sslTlsCertificateLabel = `${prefix}.sslTlsCertificateLabel`;
export const secureChannelSslTlsCertificateLabel = `${prefix}.secureChannelSslTlsCertificateLabel`;
export const allowedCiphers = `${prefix}.allowedCiphers`;
export const allowedHmacs = `${prefix}.allowedHmacs`;

export const ENGLISH = {
    [sslHeader]: 'SSL',
    [sslProfileLabel]: 'SSL Profile',
    [sslTlsCertificateLabel]: 'SSL/TLS Certificate',
    [secureChannelSslTlsCertificateLabel]: 'Secure Channel SSL/TLS Certificate',
    [allowedCiphers]: 'Allowed Ciphers',
    [allowedHmacs]: 'Allowed HMACS',
};
