<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div>
        <enum-radio-buttons
            enum="MatchOperation"
            name="source-port-match-operation-{{ matchIndex }}"
            [(ngModel)]="editable.config.source_port.config.match_criteria"
            [noMarginTop]="true"
        ></enum-radio-buttons>
        <avi-input-container
            [noMarginTop]="true"
            class="service-match__input-container"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="source_port"
                for="source-port-{{ matchIndex }}"
                [required]="!editable.hasAnyValue()"
            >
                {{ l10nKeys.sourcePortLabel | vtranslate }}
            </label>
            <avi-repeated-numbers
                id="source-port-{{ matchIndex }}"
                name="source-port-{{ matchIndex }}"
                placeholder="{{ l10nKeys.portMatchInputPlaceholder | vtranslate }}"
                objectType="{{ matchObjectType }}"
                fieldName="ports"
                [minLimit]="portNumberMin"
                [maxLimit]="portNumberMax"
                [(ngModel)]="editable.config.source_port.config.ports"
                [required]="!editable.hasAnyValue()"
            ></avi-repeated-numbers>
        </avi-input-container>
    </div>
    <div>
        <enum-radio-buttons
            enum="MatchOperation"
            name="destination-port-match-operation-{{ matchIndex }}"
            [(ngModel)]="editable.config.destination_port.config.match_criteria"
        ></enum-radio-buttons>
        <avi-input-container
            [noMarginTop]="true"
            class="service-match__input-container"
        >
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="destination_port"
                for="destination-port-{{ matchIndex }}"
                [required]="!editable.hasAnyValue()"
            >
                {{ l10nKeys.destinationPortLabel | vtranslate }}
            </label>
            <avi-repeated-numbers
                id="destination-port-{{ matchIndex }}"
                name="destination-port-{{ matchIndex }}"
                placeholder="{{ l10nKeys.portMatchInputPlaceholder | vtranslate }}"
                objectType="{{ matchObjectType }}"
                fieldName="ports"
                [minLimit]="portNumberMin"
                [maxLimit]="portNumberMax"
                [(ngModel)]="editable.config.destination_port.config.ports"
                [required]="!editable.hasAnyValue()"
            ></avi-repeated-numbers>
        </avi-input-container>
    </div>
    <div>
        <enum-radio-buttons
            enum="MatchOperation"
            name="protocol-match-criteria-{{ matchIndex }}"
            [(ngModel)]="editable.config.protocol.config.match_criteria"
        ></enum-radio-buttons>
        <div class="service-match__input-container">
            <label
                aviLabelWithTooltip
                objectType="{{ matchObjectType }}"
                fieldName="protocol"
                [required]="!editable.hasAnyValue()"
            >
                {{ l10nKeys.protocolLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="service-match-protocol-{{ matchIndex }}"
                [(ngModel)]="editable.config.protocol.config.protocol"
                [enum]="enumType"
                [hiddenEnumValues]="hiddenProtocolValues"
                [required]="!editable.hasAnyValue()"
            ></avi-enum-dropdown>
        </div>
    </div>
</match-wrapper>
