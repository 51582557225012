/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module LicensingModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import './license-card-usage.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../license-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const {
    controllerUsedLabel,
    controllerReservedLabel,
} = l10nKeys;

/**
 * @description
 *     Card to display license usage data with separate controller and org sections.
 * @author Nikhil Sharma
 */

@Component({
    selector: 'license-card-usage',
    templateUrl: './license-card-usage.component.html',
})

export class LicenseCardUsageComponent implements OnInit {
    /**
     * Controller usage Label.
     */
    @Input()
    public controllerUsageLabel = '';

    /**
     * Controller usage value.
     */
    @Input()
    public controllerUsageValue = 0;

    /**
     * Tooltip text for controller usage information.
     */
    @Input()
    public controllerTooltipText = '';

    /**
     * Tooltip field name for controller usage information.
     */
    @Input()
    public controllerTooltipFieldName = '';

    public usageCountColorClassStyle = '';

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.usageCountColorClassStyle = this.usageCountColorClass();
    }

    /**
     * Get appropriate class name based on controller usage label
     * to show appropriate color for controller usage units.
     */
    public usageCountColorClass(): string {
        switch (this.controllerUsageLabel) {
            case this.l10nService.getMessage(controllerUsedLabel):
                return 'license-card-usage__usage-count-color';
                break;
            case this.l10nService.getMessage(controllerReservedLabel):
                return 'license-card-usage__reserve-count-color';
                break;
            default:
                return 'license-card-usage__max-count-color';
        }
    }
}
