/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { CustomParamsConfigItem }
    from 'ajs/modules/security/factories/certificate-management/custom-params.config-item.factory';
import * as l10n from './custom-params-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Certificate Management Custom Param modal.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'custom-params-modal',
    templateUrl: './custom-params-modal.component.html',
})
export class CustomParamsModalComponent implements OnInit {
    /**
     * CustomParam Config Item.
     */
    @Input()
    public editable: CustomParamsConfigItem;

    /**
     * Boolean flag to check if modal is opened in edit mode.
     */
    @Input()
    public editMode: boolean;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<CustomParamsConfigItem>();

    /**
     * CustomParams ObjectType.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Custom Params modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
