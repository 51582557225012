/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'helper-text-tooltip-icon';
const prefix = `${moduleName}.${componentName}`;

export const rangeLabel = `${prefix}.rangeLabel`;
export const specialValuesLabel = `${prefix}.specialValuesLabel`;
export const immutableFieldMessage = `${prefix}.immutableFieldMessage`;

export const ENGLISH = {
    [rangeLabel]: 'Range',
    [specialValuesLabel]: 'Special Values',
    [immutableFieldMessage]: 'This field is immutable.',
};
