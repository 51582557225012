/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { L10nService } from '@vmw/ngx-vip';
import { Subscription } from 'rxjs';

import { dismissFaults } from '../../store/virtual-service.actions';
import { selectFaultsStateById } from '../../store/virtual-service.selectors';
import {
    groupFaults,
    IGroupedServerFault,
    IVirtualServiceGroupedFaults,
} from '../../utils/virtual-service-faults.utils';
import * as l10n from './virtual-service-faults.l10n';
import './virtual-service-faults.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Component displaying Virtual Service faults in the Virtual Service detail view. If the user
 * closes a VS fault, that fault should remain closed until the user refreshes the browser.
 * @author alextsg
 */
@Component({
    selector: 'virtual-service-faults',
    templateUrl: './virtual-service-faults.component.html',
})
export class VirtualServiceFaultsComponent implements OnInit, OnDestroy {
    @Input()
    public vsId: string;

    /**
     * Flag for displaying faults.
     */
    public showFaults = false;

    /**
     * Flag for displaying generic faults in an alert.
     */
    public hasGenericFaults = false;

    /**
     * Flag for displaying server faults in an alert.
     */
    public hasServerFaults = false;

    /**
     * List of faults to display.
     */
    public groupedFaults: IVirtualServiceGroupedFaults = {};

    public readonly l10nKeys = l10nKeys;

    /**
     * Subscription to select faults from the root store.
     */
    private groupedFaultsSubscription: Subscription;

    constructor(
        private readonly store: Store,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        this.groupedFaultsSubscription = this.store.select(selectFaultsStateById(this.vsId))
            .subscribe(faultsState => {
                const { dismissed, faults } = faultsState || {};

                if (dismissed) {
                    this.showFaults = false;

                    return;
                }

                const groupedFaults = groupFaults(faults);
                const { generic: genericFaults = {}, server: serverFaults = {} } = groupedFaults;

                this.groupedFaults = groupedFaults;
                this.showFaults = Object.keys(this.groupedFaults).length > 0;
                this.hasGenericFaults = Object.keys(genericFaults).length > 0;
                this.hasServerFaults = Object.keys(serverFaults).length > 0;
            });
    }

    public ngOnDestroy(): void {
        this.groupedFaultsSubscription.unsubscribe();
    }

    /**
     * Set the dismiss state of the fault when user closes the fault.
     */
    public handleClose(): void {
        this.store.dispatch(dismissFaults({ payload: this.vsId }));
    }

    public trackByFault(index: number, fault: string): string {
        return fault;
    }

    public trackByServerFault(index: number, serverFault: IGroupedServerFault): string {
        return serverFault.poolRef;
    }
}
