<div provideParentForm>
    <label
        aviLabelWithTooltip
        required
    >
        <avi-data-grid_header>
            <h6>{{ l10nKeys.rulesLabel | vtranslate : editable.config?.rules?.count }}</h6>
        </avi-data-grid_header>
    </label>

    <avi-data-grid
        [config]="rulesGridConfig"
        [rows]="editable.config.rules.config"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addVHMatchRule()"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
</div>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div class="vh-match-rule-grid__expander-section">
        <path-or-query-match-readonly
            *ngIf="row.config.matches.config.path"
            [editable]="row.config.matches.config.path"
        ></path-or-query-match-readonly>
        <hdr-match-readonly
            *ngIf="row.config.matches.config.hdrs"
            [editable]="row.config.matches.config.hdrs"
        ></hdr-match-readonly>
        <port-match-readonly
            *ngIf="row.config.matches.config.vs_port"
            [editable]="row.config.matches.config.vs_port"
        ></port-match-readonly>
        <http-method-match-readonly
            *ngIf="row.config.matches.config.method"
            [editable]="row.config.matches.config.method"
        ></http-method-match-readonly>
        <http-version-match-readonly
            *ngIf="row.config.matches.config.version"
            [editable]="row.config.matches.config.version"
        ></http-version-match-readonly>
        <protocol-match-readonly
            *ngIf="row.config.matches.config.protocol"
            [editable]="row.config.matches.config.protocol"
        ></protocol-match-readonly>
    </div>
</ng-template>
