/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export type TDayOfWeek = {
    alias: string;
    label: string;
    code: number;
};

export enum Recurrence {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly'
}

export const daysOfWeek: Record<string, TDayOfWeek> = {
    sunday: {
        code: 0,
        alias: 'SUN',
        label: 'sundayLabel',
    },
    monday: {
        code: 1,
        alias: 'MON',
        label: 'mondayLabel',
    },
    tuesday: {
        code: 2,
        alias: 'TUE',
        label: 'tuesdayLabel',
    },
    wednesday: {
        code: 3,
        alias: 'WED',
        label: 'wednesdayLabel',
    },
    thursday: {
        code: 4,
        alias: 'THU',
        label: 'thursdayLabel',
    },
    friday: {
        code: 5,
        alias: 'FRI',
        label: 'fridayLabel',
    },
    saturday: {
        code: 6,
        alias: 'SAT',
        label: 'saturdayLabel',
    },
};

export const daysOfWeekMapping = {
    [daysOfWeek.sunday.alias]: `${daysOfWeek.sunday.code}`,
    [daysOfWeek.monday.alias]: `${daysOfWeek.monday.code}`,
    [daysOfWeek.tuesday.alias]: `${daysOfWeek.tuesday.code}`,
    [daysOfWeek.wednesday.alias]: `${daysOfWeek.wednesday.code}`,
    [daysOfWeek.thursday.alias]: `${daysOfWeek.thursday.code}`,
    [daysOfWeek.friday.alias]: `${daysOfWeek.friday.code}`,
    [daysOfWeek.saturday.alias]: `${daysOfWeek.saturday.code}`,
};
