<div
    provideParentForm
    class="system-settings-system-access-config"
>
    <div class="system-settings-system-access-config__avi-fieldset">
        <avi-fieldset [hideContent]="!portalConfig.enable_http">

            <avi-fieldset_header>
                <clr-checkbox-container class="system-settings-system-access-config__no-margin-top">
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            id="enable-http"
                            name="enable-http"
                            [(ngModel)]="portalConfig.enable_http"
                            clrCheckbox
                        />
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectTypes.PortalConfiguration"
                            fieldName="enable_http"
                            for="enable-http"
                        >
                            {{ l10nKeys.enableHttpAccessLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </avi-fieldset_header>

            <avi-fieldset_content>
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectTypes.PortalConfiguration"
                        fieldName="http_port"
                        for="system-access-http-port"
                    >
                        {{ l10nKeys.httpPortLabel | vtranslate }}
                    </label>
                    <input
                        id="system-access-http-port"
                        name="system-access-http-port"
                        type="number"
                        [(ngModel)]="portalConfig.http_port"
                        clrInput
                        [placeholder]="l10nKeys.httpPortPlaceholder | vtranslate"
                        regexPattern="port"
                    />
                </avi-input-container>
                <clr-control-helper>{{ l10nKeys.httpPortHint | vtranslate }}</clr-control-helper>
            </avi-fieldset_content>

        </avi-fieldset>
    </div>
    <div class="system-settings-system-access-config__avi-fieldset">
        <avi-fieldset [hideContent]="!portalConfig.enable_https">

            <avi-fieldset_header>
                <clr-checkbox-container class="system-settings-system-access-config__no-margin-top">
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            id="enable-https"
                            name="enable-https"
                            [(ngModel)]="portalConfig.enable_https"
                            clrCheckbox
                        />
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectTypes.PortalConfiguration"
                            fieldName="enable_https"
                            for="enable-https"
                        >
                            {{ l10nKeys.enableHttpsAccessLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
            </avi-fieldset_header>

            <avi-fieldset_content>
                <avi-input-container noMarginTop>
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectTypes.PortalConfiguration"
                        fieldName="https_port"
                        for="system-access-https-port"
                    >
                        {{ l10nKeys.httpsPortLabel | vtranslate }}
                    </label>
                    <input
                        id="system-access-https-port"
                        name="system-access-https-port"
                        type="number"
                        [(ngModel)]="portalConfig.https_port"
                        clrInput
                        [placeholder]="l10nKeys.httpsPortPlaceholder | vtranslate"
                        regexPattern="port"
                    />
                </avi-input-container>
                <clr-control-helper>{{ l10nKeys.httpsPortHint | vtranslate }}</clr-control-helper>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            id="redirect-to-https"
                            name="redirect-to-https"
                            [(ngModel)]="portalConfig.redirect_to_https"
                            clrCheckbox
                        />
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectTypes.PortalConfiguration"
                            fieldName="redirect_to_https"
                            for="redirect-to-https"
                        >
                            {{ l10nKeys.redirectPortLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6 class="full-modal__section-header">
                            {{ l10nKeys.sslTlsHeader | vtranslate }}
                        </h6>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <label
                                    aviLabelWithTooltip
                                    [objectType]="objectTypes.PortalConfiguration"
                                    fieldName="sslprofile_uuid"
                                    for="ssl-profile"
                                    required
                                >
                                    {{ l10nKeys.sslProfileLabel | vtranslate }}
                                </label>
                                <avi-collection-dropdown
                                    name="ssl-profile"
                                    id="ssl-profile"
                                    [collection]="sslProfileCollection"
                                    [(ngModel)]="portalConfig.sslprofile_ref"
                                    required
                                ></avi-collection-dropdown>
                            </div>
                        </div>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <label
                                    aviLabelWithTooltip
                                    [objectType]="objectTypes.PortalConfiguration"
                                    fieldName="sslkeyandcertificate_uuids"
                                    for="ssl-key-certs"
                                >
                                    {{ l10nKeys.sslTlsCertificatesLabel | vtranslate }}
                                </label>
                                <avi-collection-dropdown
                                    name="ssl-key-certs"
                                    id="ssl-key-certs"
                                    [multiple]="true"
                                    [collection]="certificateCollection"
                                    [placeholder]="l10nKeys.sslTlsCertificatesPlaceholder | vtranslate"
                                    [(ngModel)]="portalConfig.sslkeyandcertificate_refs"
                                    (ngModelChange)="setIsSslKeyCertsReadonlyFlag()"
                                    [disabled]="isSslKeyCertsReadonly"
                                ></avi-collection-dropdown>
                            </div>
                        </div>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <label
                                    aviLabelWithTooltip
                                    [objectType]="objectTypes.SecureChannelConfiguration"
                                    fieldName="sslkeyandcertificate_uuids"
                                    for="secure-channel-ssl-key-certs"
                                >
                                    {{ l10nKeys.secureChannelSslTlsCertsLabel | vtranslate }}
                                </label>
                                <avi-collection-dropdown
                                    name="secure-channel-ssl-key-certs"
                                    id="secure-channel-ssl-key-certs"
                                    [multiple]="true"
                                    [collection]="certificateCollection"
                                    [placeholder]="l10nKeys.secureChannelSslTlsCertsPlaceholder | vtranslate"
                                    [(ngModel)]="secureChannelConfig.sslkeyandcertificate_refs"
                                    (ngModelChange)="setIsSecureChannelCertListReadonlyFlag()"
                                    [disabled]="isSecureChannelCertListReadonly"
                                ></avi-collection-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </avi-fieldset_content>
        </avi-fieldset>
    </div>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                id="allow-basic-auth"
                name="allow-basic-auth"
                [value]="true"
                [(ngModel)]="portalConfig.allow_basic_authentication"
                clrCheckbox
            />
            <label
                aviLabelWithTooltip
                [objectType]="objectTypes.PortalConfiguration"
                fieldName="allow_basic_authentication"
                for="allow-basic-auth"
            >
                {{ l10nKeys.allowBasicAuthLabel | vtranslate }}
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectTypes.SystemConfiguration"
            fieldName="ssh_ciphers"
            for="ssh-ciphers"
        >
            {{ l10nKeys.allowedCiphersLabel | vtranslate }}
        </label>
        <avi-repeated-strings
            name="ssh-ciphers"
            id="ssh-ciphers"
            [placeholder]="l10nKeys.allowedCiphersPlaceholder | vtranslate"
            [(ngModel)]="allowedSshCiphers"
        ></avi-repeated-strings>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectTypes.SystemConfiguration"
            fieldName="ssh_hmacs"
            for="ssh-hmacs"
        >
            {{ l10nKeys.allowedHmacsLabel | vtranslate }}
        </label>
        <avi-repeated-strings
            name="ssh-hmacs"
            id="ssh-hmacs"
            [placeholder]="l10nKeys.allowedHmacsPlaceholder | vtranslate"
            [(ngModel)]="allowedSshHmacs"
        ></avi-repeated-strings>
    </avi-input-container>
</div>
