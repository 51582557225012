/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-navbars';
const prefix = `${moduleName}.${componentName}`;

export const analyticsTabLabel = `${prefix}.analyticsTabLabel`;
export const logsTabLabel = `${prefix}.logsTabLabel`;
export const healthTabLabel = `${prefix}.healthTabLabel`;
export const serversTabLabel = `${prefix}.serversTabLabel`;
export const eventsTabLabel = `${prefix}.eventsTabLabel`;
export const alertsTabLabel = `${prefix}.alertsTabLabel`;

export const ENGLISH = {
    [analyticsTabLabel]: 'Analytics',
    [logsTabLabel]: 'Logs',
    [healthTabLabel]: 'Health',
    [serversTabLabel]: 'Servers',
    [eventsTabLabel]: 'Events',
    [alertsTabLabel]: 'Alerts',
};
