/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';

import {
    GSLBService,
    ISplitDomainName,
} from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';

import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { GslbService } from 'object-types';
import './gslb-service-domain-names-grid.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-domain-names-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Domain Names Grid component.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-domain-names-grid',
    templateUrl: './gslb-service-domain-names-grid.component.html',
})
export class GslbServiceDomainNamesGridComponent implements OnInit, AfterViewInit {
    @Input()
    public editable: GSLBService;

    /**
     * Rows for Domain Names grid.
     */
    @Input()
    public splitDomainNames: ISplitDomainName[];

    /**
     * TemplateRef for application name column.
     */
    @ViewChild('applicationNameTemplateRef')
    public applicationNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for subdomain dropdown.
     */
    @ViewChild('subdomainTemplateRef')
    public subdomainTemplateRef: TemplateRef<HTMLElement>;

    public readonly objectType = GslbService;

    public gslbSubDomainNameDropdownOptions: IAviDropdownOption[] = [];

    public domainNamesGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Default GSLB domain name, used for adding new domain name.
     */
    private defaultGSLBDomainName: string;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { domain_names: domainNames } = this.editable.config;
        const gslb = this.editable.getGSLB();

        if (gslb) {
            this.defaultGSLBDomainName = gslb.getDefaultDNSDomainName();
        }

        domainNames?.forEach((domainName: string) =>
            this.splitDomainNames.push(this.splitDomainName(domainName)));

        this.setSubDomainNameDropdownOptions();
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.domainNamesGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.applicationNameInputLabel),
                    id: 'application-name',
                    templateRef: this.applicationNameTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.subdomainInputLabel),
                    id: 'subdomain',
                    templateRef: this.subdomainTemplateRef,
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (domainNames: ISplitDomainName[]) => {
                    this.deleteDomainNames(domainNames);
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.addLabel),
                shape: 'trash',
                onClick: (domainName: ISplitDomainName): void => {
                    this.deleteDomainName(domainName);
                },
            }],
        };
    }

    /**
     * Handle add Domain Name operation.
     */
    public addDomainName(): void {
        this.splitDomainNames.push(
            this.splitDomainName(this.defaultGSLBDomainName),
        );
    }

    /**
     * Parse full Domain Name into an ISplitDomainName type object. Full Domain Names consist
     * of application name and top most level subdomain name supported by GSLB.
     */
    private splitDomainName(fullDomainName: string): ISplitDomainName {
        let appDomainName = '';
        let subdomain = '';

        if (fullDomainName) {
            [appDomainName, subdomain] = this.editable.splitDomainNameField(
                fullDomainName,
            );
        }

        return {
            appDomainName,
            subdomain,
        };
    }

    /**
     * Set subdomain Dropdown options.
     */
    private setSubDomainNameDropdownOptions(): void {
        const gslb = this.editable.getGSLB();

        if (gslb) {
            this.gslbSubDomainNameDropdownOptions = gslb.getDNSDomainNames().map(
                (domainName: string) => createDropdownOption(domainName),
            );
        }
    }

    /**
     * Handle delete Domain Name operation.
     */
    private deleteDomainName(domainName: ISplitDomainName): void {
        const index = this.splitDomainNames.indexOf(domainName);

        this.splitDomainNames.splice(index, 1);
    }

    /**
     * Handle multiple-delete Domain Name operation.
     */
    private deleteDomainNames(domainNames: ISplitDomainName[]): void {
        domainNames.forEach((domainName: ISplitDomainName) => {
            this.deleteDomainName(domainName);
        });
    }
}
