<div class="ip-reputation-db-list-page-header__title">
    <cds-icon
        [hidden]="status !== IPReputationDBDisplayStatus.ACTIVE"
        class="ip-reputation-db-list-page-header__icon"
        shape="circle"
        size="24"
        status="success"
        solid
    ></cds-icon>
    <avi-tooltip-icon-with-content-wrapper
        shape="circle"
        size="24"
        class="ip-reputation-db-list-page-header__icon"
        [hidden]="status !== IPReputationDBDisplayStatus.ERROR"
        status="danger"
        [solid]="true"
    >
        <div class="ip-reputation-db-list-page-header__error-container clr-wrapper">
            <span class="ip-reputation-db-list-page-header__error-message">
                {{ error }}
            </span>
        </div>
    </avi-tooltip-icon-with-content-wrapper>
    <h2>{{ l10nKeys.ipReputationTitle | vtranslate }}</h2>
</div>
<avi-alert-group
    *ngIf="status === IPReputationDBDisplayStatus.UNREGISTERED"
    alertType="info"
>
    {{ l10nKeys.registerControllerMessage | vtranslate }}
    <cds-alert-actions>
        <cds-button (click)="redirectToCloudServices()">
            {{ l10nKeys.registerControllerButtonLabel | vtranslate }}
        </cds-button>
    </cds-alert-actions>
</avi-alert-group>
<avi-alert-group
    *ngIf="status === IPReputationDBDisplayStatus.DISABLED"
    alertType="info"
>
    {{ l10nKeys.enableIPReputationSyncMessage | vtranslate }}
    <cds-alert-actions>
        <cds-button (click)="redirectToCloudServices()">
            {{ l10nKeys.editCloudServicesSettingsButtonLabel | vtranslate }}
        </cds-button>
    </cds-alert-actions>
</avi-alert-group>
