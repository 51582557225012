/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { skip } from 'rxjs/operators';
import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import { selectValuesToDisplay } from 'ng/root-store/user-preferences/user-preferences.selectors';
import { num2abbrStr } from 'ng/shared/utils/math.utils';
import '../../less/components/series-value.less';

/**
 * @ngdoc directive
 * @name seriesValue
 * @restrict A
 * @param {Series[]} seriesList
 * @description
 *
 *     Displays the value and units for metrics that change with mouseover on time series chart.
 *     Can pass in 1 or 2 metrics. Shows a value+units along with another value+units in
 *     parentheses.
 */
angular.module('aviApp').directive('seriesValue', [
'DisplayValue', 'GraphSync', 'Series', STORE_TOKEN,
function(DisplayValue, graphSync, Series, store) {
    let valuesToDisplay;

    /**
     * Returns rounded value or string.
     * @param  {number|string} value - Object containing value and unit properties.
     * @return {number|string}
     */
    function getValue(value) {
        return typeof value === 'number' ? num2abbrStr(value, 2) : value;
    }

    /**
     * Sets the large number value along with the units.
     * @param {Series} series - Time series of metric to be displayed.
     * @return {Object} Value with units.
     */
    function setLargeValue(series) {
        let valueUnit;

        if (series && series.hasData()) {
            valueUnit = DisplayValue.createCardValueUnit(series, valuesToDisplay);
        } else {
            valueUnit = {
                value: null,
                unit: null,
            };
        }

        return {
            value: getValue(valueUnit.value),
            units: valueUnit.unit,
        };
    }

    /**
     * Sets the small number value along with the units. Differs from large units in that small
     * units should not be displayed when valuesToDisplay is set to 'sum'.
     * @param {Object} series - Time series of metric to be displayed.
     * @param {boolean} sameSeries - True if metric is the same as that of the large value,
     *     false if it's another metric passed in.
     * @return {Object} Value with units.
     */
    function setSmallValue(series, sameSeries) {
        let valueUnit;

        if (sameSeries) {
            if (series && series.hasData()) {
                valueUnit = {
                    value: num2abbrStr(series.getValue('sum'), 2),
                    unit: null,
                };
            } else {
                // Series is sum_attack_duration, so we don't need to show it.
                return {
                    value: null,
                    units: null,
                };
            }
        } else {
            valueUnit = DisplayValue.createCardValueUnit(series, valuesToDisplay);
        }

        if (valuesToDisplay !== 'sum') {
            return {
                value: getValue(valueUnit.value),
                units: valueUnit.unit,
            };
        } else {
            return {
                value: null,
                units: null,
            };
        }
    }

    function seriesValueLink(scope) {
        /**
         * Sets and removes series update event listeners on series list change event.
         * @param {Series[]} seriesList
         * @param {Series[]} prevSeriesList
         * @inner
         */
        const setSeriesUpdateEventListeners = (seriesList, prevSeriesList) => {
            if (!Array.isArray(seriesList)) {
                seriesList = [];
            }

            if (!Array.isArray(prevSeriesList)) {
                prevSeriesList = [];
            }

            if (seriesList === prevSeriesList) {
                prevSeriesList = [];
            }

            prevSeriesList.forEach(series => {
                if (series instanceof Series) {
                    series.unbind(Series.VALUES_UPDATE_EVENT, setValues);
                }
            });

            seriesList.forEach(series => {
                if (series instanceof Series) {
                    series.on(Series.VALUES_UPDATE_EVENT, setValues);
                }
            });
        };

        /**
         * Sets series values to be shown in a template.
         * @inner
         */
        function setValues() {
            const
                { seriesList } = scope,
                [largeSeries] = seriesList;

            if (!largeSeries) {
                return;
            }

            let
                smallSeries = largeSeries,
                sameSeries = true;

            if (seriesList.length > 1) {
                smallSeries = seriesList[1];
                sameSeries = false;
            }

            scope.largeData = setLargeValue(largeSeries);
            scope.smallData = setSmallValue(smallSeries, sameSeries);

            scope.title = largeSeries.getTitle();
        }

        setValues();

        scope.valuesToDisplaySubscription = store.select(selectValuesToDisplay)
            .pipe(skip(1))
            .subscribe(subscribedValuesToDisplay => {
                valuesToDisplay = subscribedValuesToDisplay;

                setValues();
            });

        graphSync.on('GraphSync', setValues);

        scope.$watchCollection('seriesList', (newSeriesList, oldSeriesList) => {
            setSeriesUpdateEventListeners(newSeriesList, oldSeriesList);
            setValues();
        });

        scope.$on('$destroy', () => {
            scope.valuesToDisplaySubscription.unsubscribe();
            graphSync.unbind('GraphSync', setValues);
            setSeriesUpdateEventListeners([], scope.seriesList);
        });
    }

    return {
        restrict: 'A',
        scope: {
            seriesList: '<*',
        },
        templateUrl: 'src/views/components/series-value.html',
        link: seriesValueLink,
    };
}]);
