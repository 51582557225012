/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-scale-out';
const prefix = `${moduleName}.${componentName}`;

export const scaleOutHeader = `${prefix}.scaleOutHeader`;
export const selectVipAddressToScaleOutInputLabel = `${prefix}.selectVipAddressToScaleOutInputLabel`;
export const selectVipAddressToScaleOutInputPlaceholder = `${prefix}.selectVipAddressToScaleOutInputPlaceholder`;
export const selectServiceEngineToScaleOutInputLabel = `${prefix}.selectServiceEngineToScaleOutInputLabel`;
export const selectAutomaicallyInputPlaceholder = `${prefix}.selectAutomaicallyInputPlaceholder`;
export const recommendedDataLabel = `${prefix}.recommendedDataLabel`;
export const createServiceEngineInputLabel = `${prefix}.createServiceEngineInputLabel`;
export const scaleOutBtnLabel = `${prefix}.scaleOutBtnLabel`;

export const ENGLISH = {
    [scaleOutHeader]: 'Scale Out: {0}',
    [selectVipAddressToScaleOutInputLabel]: 'Select VIP Address to scale out',
    [selectVipAddressToScaleOutInputPlaceholder]: 'Select VIP Address',
    [selectServiceEngineToScaleOutInputLabel]: 'Please select a Service Engine to scale out',
    [selectAutomaicallyInputPlaceholder]: '(Select Automatically)',
    [recommendedDataLabel]: '{0} (Recommended)',
    [createServiceEngineInputLabel]: 'Create Service Engine',
    [scaleOutBtnLabel]: 'Scale out',
};
