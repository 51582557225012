/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'traffic-capture-page-card';
const prefix = `${moduleName}.${componentName}`;

export const trafficCaptureCardHeader = `${prefix}.trafficCaptureCardHeader`;
export const startCaptureButtonLabel = `${prefix}.startCaptureButtonLabel`;
export const emptyCardPlaceholder = `${prefix}.emptyCardPlaceholder`;
export const stopCaptureButtonLabel = `${prefix}.stopCaptureButtonLabel`;
export const sizePerPacketLabel = `${prefix}.sizePerPacketLabel`;
export const srcPortLabel = `${prefix}.srcPortLabel`;
export const emptyPlaceholder = `${prefix}.emptyPlaceholder`;
export const pktSizeHelperText = `${prefix}.pktSizeHelperText`;
export const trafficToCaptureLabel = `${prefix}.trafficToCaptureLabel`;
export const pktsCapturedLabel = `${prefix}.pktsCapturedLabel`;

export const ENGLISH = {
    [trafficCaptureCardHeader]: 'Traffic Capture',
    [startCaptureButtonLabel]: 'Start Capture',
    [emptyCardPlaceholder]: 'There are no captures currrently running',
    [stopCaptureButtonLabel]: 'Stop Capture',
    [sizePerPacketLabel]: 'Size per Packet',
    [srcPortLabel]: 'Source Port',
    [emptyPlaceholder]: '-',
    [pktSizeHelperText]: 'Bytes',
    [trafficToCaptureLabel]: 'Traffic to Capture',
    [pktsCapturedLabel]: 'Capture in progress',
};
