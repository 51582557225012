<avi-state-page class="gslb-federation-checkpoints-list">
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="federationCheckpointGridConfig"></avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #activeCheckpointTemplateRef
    let-row
>
    <cds-icon
        *ngIf="showStarIcon(row)"
        title="{{ (row.isActive ? globalL10nKeys.activeLabel : l10nKeys.setToActiveLabel) | vtranslate }}"
        size="16"
        shape="star"
        [solid]="row.isActive"
        [ngClass]="{ 'gslb-federation-checkpoints-list__icon-star--active': row.isActive }"
        (click)="onSetActiveCheckpoint(row)"
    ></cds-icon>
</ng-template>
