<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.busy"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            name="enable"
                            [(ngModel)]="editable.config.enable"
                            id="enable"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypes.AuthorizationRule }}"
                            fieldName="enable"
                            for="enable"
                        >
                            {{ l10nKeys.enableRuleLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{  objectTypes.AuthorizationRule }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        [(ngModel)]="editable.config.name"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleMatch | vtranslate }}">
            <div
                class="full-modal-config__section"
                [invalidateForm]="!editable.match.hasAnyEntry()"
            >
                <label
                    aviLabelWithTooltip
                    objectType="{{  objectTypes.AuthorizationRule }}"
                    fieldName="match"
                >
                    <h3>{{ l10nKeys.sectionTitleMatch | vtranslate }}</h3>
                </label>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <match-adder
                            [match]="editable.config.match"
                            [matchOptions]="matchOptions"
                        ></match-adder>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleAction | vtranslate }}">
            <div class="full-modal-config__section">
                <label
                    aviLabelWithTooltip
                    objectType="{{  objectTypes.AuthorizationRule }}"
                    fieldName="action"
                >
                    <h3>{{ l10nKeys.sectionTitleAction | vtranslate }}</h3>
                </label>
                <enum-radio-buttons
                    enum="AuthorizationActionEnum"
                    name="type"
                    [(ngModel)]="editable.action.config.type"
                    (ngModelChange)="handleActionTypeChange()"
                >
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectTypes.AuthorizationAction }}"
                        fieldName="type"
                        for="type"
                    >
                        {{ l10nKeys.typeLabel | vtranslate }}
                    </label>
                </enum-radio-buttons>
                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="editable.action.type === authorizationActionEnumHash.HTTP_LOCAL_RESPONSE"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{  objectTypes.AuthorizationAction }}"
                            fieldName="status_code"
                            for="status-code"
                        >
                            {{ l10nKeys.statusCodeLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            id="status-code"
                            name="status-code"
                            [(ngModel)]="editable.action.config.status_code"
                            enum="AuthorizationActionHttpStatusCode"
                        ></avi-enum-dropdown>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
