/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name policyRuleSort
 * @type Function
 * @description
 *
 *     Sorts policy rules based on index.
 */
angular.module('aviApp').constant('policyRuleSort', function(ruleA, ruleB) {
    const
        { index: indexA } = ruleA,
        { index: indexB } = ruleB;

    return indexA - indexB;
});
