<div
    class="ipam-dns-internal-profile-config"
    provideParentForm
>
    <ng-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INTERNAL">
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    tooltipText="{{ l10nKeys.cloudFieldDescription | vtranslate }}"
                    for="cloud_ref"
                >
                    {{ l10nKeys.cloudInputLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    [collection]="cloudCollection"
                    [(ngModel)]="cloudRef"
                    placeholder="{{ l10nKeys.cloudInputPlaceholder | vtranslate }}"
                    id="cloud_ref"
                    name="cloud_ref"
                ></avi-collection-dropdown>
            </div>
        </div>
    
        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <internal-ipam-usable-networks-grid
                    [usableNetworks]="editable.config.usable_networks"
                    [cloudRef]="cloudRef"
                    (onAddUsableNetwork)="addUsableNetwork()"
                    (onDeleteUsableNetwork)="removeUsableNetwork($event)"
                ></internal-ipam-usable-networks-grid>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INTERNAL_DNS">
        <avi-input-container helperText="{{ l10nKeys.secondsInnerText | vtranslate }}">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType }}"
                fieldName="ttl"
                for="ttl"
            >
                {{ l10nKeys.defaultTtlInputLabel | vtranslate }}
            </label>
            <input
                clrInput
                id="ttl"
                type="number"
                placeholder="{{ l10nKeys.defaultTtlInputPlaceholder | vtranslate }}"
                name="ttl"
                [(ngModel)]="editable.config.ttl"
                configFieldInputValidation
                objectType="{{ objectType }}"
                fieldName="ttl"
            />
        </avi-input-container>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <internal-dns-service-domains-grid
                    [dnsServiceDomains]="editable.config.dns_service_domain"
                    (onAddDnsServiceDomain)="addDnsServiceDomain()"
                    (onDeleteDnsServiceDomain)="removeDnsServiceDomain($event)"
                ></internal-dns-service-domains-grid>
            </div>
        </div>
    </ng-container>
</div>
