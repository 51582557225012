/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 */

import { CSRFPolicy } from 'object-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { CSRFPolicyItem } from 'items/csrf-policy.item.factory';

/**
 * @description CSRF Policy Collection.
 * @author alextsg
 */
export class CSRFPolicyCollection extends Collection {
    public static ngDependencies = [CSRFPolicyItem];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'csrfpolicy',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_CSRFPOLICY,
            objectType: CSRFPolicy,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(CSRFPolicyItem);

        this.serverDefaultsOverride_ = {
            rules: undefined,
        };
    }
}

export type TCSRFPolicyCollection = typeof CSRFPolicyCollection;
