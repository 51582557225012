/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-policy-clone-dialog';
const prefix = `${moduleName}.${componentName}`;

export const cloneWafPolicyDialogHeader = `${prefix}.cloneWafPolicyDialogHeader`;
export const closeButtonLabel = `${prefix}.closeButtonLabel`;
export const cloneButtonLabel = `${prefix}.cloneButtonLabel`;
export const wafPolicyNameLabel = `${prefix}.wafPolicyNameLabel`;

export const ENGLISH = {
    [cloneWafPolicyDialogHeader]: 'Clone WAF Policy',
    [closeButtonLabel]: 'Close',
    [cloneButtonLabel]: 'Clone',
    [wafPolicyNameLabel]: 'WAF Policy Name',
};
