/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const componentName = 'cloud-services-widget';
const prefix = `${moduleName}.${componentName}`;

export const cloudServicesLabel = `${prefix}.cloudServicesLabel`;
export const botManagementLabel = `${prefix}.botManagementLabel`;
export const crsSignaturesLabel = `${prefix}.crsSignaturesLabel`;
export const controllerNotRegisteredMessage = `${prefix}.controllerNotRegisteredMessage`;
export const registerNowLink = `${prefix}.registerNowLink`;
export const upLabel = `${prefix}.upLabel`;
export const downLabel = `${prefix}.downLabel`;

export const ENGLISH = {
    [cloudServicesLabel]: 'Cloud Services',
    [botManagementLabel]: 'Bot Management',
    [crsSignaturesLabel]: 'CRS Signatures',
    [controllerNotRegisteredMessage]: 'The Controller is not registered.',
    [registerNowLink]: 'Register Now',
    [upLabel]: 'Up',
    [downLabel]: 'Down',
};
