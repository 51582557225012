/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'administration-dashboard';
const componentName = 'node-vitals-widget';
const prefix = `${moduleName}.${componentName}`;

export const nodeVitalsHeader = `${prefix}.nodeVitalsHeader`;

export const ENGLISH = {
    [nodeVitalsHeader]: 'Node Vitals',
};
