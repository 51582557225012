<full-modal-config
    class="bot-management-policy-modal"
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
>
    <form
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrForm
    >
        <!-- General -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
            [invalidateForm]="!hasMinimumRequiredFields()"
        >
            <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>

            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="name"
                    for="name"
                    required
                >
                    {{ globalL10nKeys.nameLabel | vtranslate }}
                </label>
                <input
                    id="name"
                    name="name"
                    [(ngModel)]="editable.config.name"
                    clrInput
                    placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                    required
                />
            </avi-input-container>

            <avi-textarea-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="description"
                    for="description"
                >
                    {{ globalL10nKeys.descriptionLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    id="description"
                    [(ngModel)]="editable.config.description"
                    name="description"
                    placeholder="{{ globalL10nKeys.descriptionPlaceholder | vtranslate }}"
                ></textarea>
            </avi-textarea-container>
        </full-modal-tab-section>

        <!-- Allow List -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.allowListSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.allowListSectionTitle | vtranslate }}</h3>

            <avi-data-grid
                [config]="allowListGridConfig"
                [rows]="allowListGridRows"
            >
                <avi-data-grid_header>
                    <h6>
                        {{ l10nKeys.allowRulesLabel | vtranslate : allowListGridRows.length }}
                    </h6>
                </avi-data-grid_header>

                <avi-data-grid_actions>
                    <cds-button
                        size="sm"
                        type="button"
                        (click)="addAllowListRule()"
                    >
                        {{ l10nKeys.addButtonLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>

                <ng-template
                    #expandedContentTemplateRef
                    let-row
                >
                    <match-and-action-display-wrapper>
                        <match-and-action-display-wrapper_match-content>
                            <ip-addr-match-readonly
                                *ngIf="row.config.condition.config.client_ip"
                                [editable]="row.config.condition.config.client_ip"
                            ></ip-addr-match-readonly>

                            <host-hdr-match-readonly
                                *ngIf="row.config.condition.config.host_hdr"
                                [editable]="row.config.condition.config.host_hdr"
                            ></host-hdr-match-readonly>

                            <http-method-match-readonly
                                *ngIf="row.config.condition.config.method"
                                [editable]="row.config.condition.config.method"
                            ></http-method-match-readonly>

                            <path-or-query-match-readonly
                                *ngIf="row.config.condition.config.path"
                                [editable]="row.config.condition.config.path"
                            ></path-or-query-match-readonly>

                            <hdr-match-readonly
                                *ngIf="row.config.condition.config.hdrs"
                                [editable]="row.config.condition.config.hdrs"
                            ></hdr-match-readonly>
                        </match-and-action-display-wrapper_match-content>

                        <match-and-action-display-wrapper_action-content>
                            {{ row.botActionText }}
                        </match-and-action-display-wrapper_action-content>
                    </match-and-action-display-wrapper>
                </ng-template>

            </avi-data-grid>
        </full-modal-tab-section>

        <!-- User-Agent -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.userAgentSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.userAgentSectionTitle | vtranslate }}</h3>

            <clr-checkbox-container class="bot-management-policy-modal__checkbox-container">
                <clr-checkbox-wrapper>
                    <input
                        clrCheckbox
                        id="user-agent-detector"
                        type="checkbox"
                        name="user-agent-detector"
                        [(ngModel)]="editable.config.user_agent_detector.config.enabled"
                    />
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="user_agent_detector"
                        for="user-agent-detector"
                    >
                        {{ l10nKeys.useUserAgentLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <clr-checkbox-container class="bot-management-policy-modal__checkbox-container">
                <clr-checkbox-wrapper>
                    <input
                        clrCheckbox
                        id="use-tls-fingerprint"
                        type="checkbox"
                        name="use-tls-fingerprint"
                        [(ngModel)]="editable.config.user_agent_detector.config.use_tls_fingerprint"
                    />
                    <label
                        aviLabelWithTooltip
                        objectType="BotConfigUserAgent"
                        fieldName="use_tls_fingerprint"
                        for="use-tls-fingerprint"
                    >
                        {{ l10nKeys.useTlsFingerprintLabel | vtranslate }}
                    </label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </full-modal-tab-section>

        <!-- IP Reputation -->
        <full-modal-tab-section
            tabTitle="{{ globalL10nKeys.ipReputationLabel | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ globalL10nKeys.ipReputationLabel | vtranslate }}</h3>

            <avi-fieldset [hideContent]="!editable.ipReputationDetectorConfig.enabled">
                <avi-fieldset_header>
                    <clr-checkbox-container class="bot-management-policy-modal__checkbox-container">
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                name="ip-reputation-detector-enabled"
                                [(ngModel)]="editable.ipReputationDetectorConfig.enabled"
                                id="allow-ip-reputation-based-bot-detection"
                                (ngModelChange)="onIpReputationDetectorConfigToggle()"
                            />
                            <label
                                aviLabelWithTooltip
                                objectType="BotConfigIPReputation"
                                fieldName="enabled"
                                for="allow-ip-reputation-based-bot-detection"
                            >
                                {{ l10nKeys.useIpReputationLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </avi-fieldset_header>

                <avi-fieldset_content class="bot-management-policy-modal__fieldset-content-grid">
                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotConfigIPReputation"
                            fieldName="ip_reputation_db_uuid"
                        >
                            {{ l10nKeys.ipReputationDbLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ip_reputation_db_uuid"
                            [collection]="ipReputationDbCollection"
                            [(ngModel)]="editable.ipReputationDetectorConfig.ip_reputation_db_ref"
                            [disableCreate]="true"
                            [disableEdit]="true"
                            placeholder="{{ globalL10nKeys.ipReputationDbPlaceholder | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>

                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotConfigIPReputation"
                            fieldName="system_ip_reputation_mapping_uuid"
                        >
                            {{ l10nKeys.ipReputationMappingLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="system_ip_reputation_mapping_uuid"
                            [collection]="botIpReputationTypeMappingCollection"
                            [(ngModel)]="editable.ipReputationDetectorConfig.system_ip_reputation_mapping_ref"
                            placeholder="{{ l10nKeys.selectIpReputationMappingLabel | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>
                </avi-fieldset_content>
            </avi-fieldset>
        </full-modal-tab-section>

        <!-- IP Location -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.ipLocationSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.ipLocationSectionTitle | vtranslate }}</h3>

            <avi-fieldset [hideContent]="!editable.ipLocationDetectorConfig.enabled">
                <avi-fieldset_header>
                    <clr-checkbox-container class="bot-management-policy-modal__checkbox-container">
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                name="ip-location-detector-enabled"
                                id="ip-location-detector-enabled"
                                [(ngModel)]="editable.ipLocationDetectorConfig.enabled"
                                (ngModelChange)="onIpLocationDetectorConfigToggle()"
                            />
                            <label
                                aviLabelWithTooltip
                                objectType="BotConfigIPLocation"
                                fieldName="enabled"
                                for="ip-location-detector-enabled"
                            >
                                {{ l10nKeys.useIpLocationDbLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </avi-fieldset_header>

                <avi-fieldset_content class="bot-management-policy-modal__fieldset-content-grid">
                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotConfigIPLocation"
                            fieldName="ip_location_db_uuid"
                        >
                            {{ l10nKeys.geoIpDbLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="ip_location_db_uuid"
                            [collection]="geoDbCollection"
                            [(ngModel)]="editable.ipLocationDetectorConfig.ip_location_db_ref"
                            [disableCreate]="true"
                            [disableEdit]="true"
                            placeholder="{{ l10nKeys.selectGeoIpDbLabel | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>

                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotConfigIPLocation"
                            fieldName="system_cloud_providers_uuid"
                        >
                            {{ l10nKeys.cloudProviderLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="system_cloud_providers_uuid"
                            [collection]="stringGroupCollection"
                            [(ngModel)]="editable.ipLocationDetectorConfig.system_cloud_providers_ref"
                            placeholder="{{ l10nKeys.selectGeoIpDbLabel | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>

                    <div>
                        <label
                            aviLabelWithTooltip
                            objectType="BotConfigIPLocation"
                            fieldName="system_search_engines_uuid"
                        >
                            {{ l10nKeys.searchEnginesLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            name="system_search_engines_uuid"
                            [collection]="stringGroupCollection"
                            [(ngModel)]="editable.ipLocationDetectorConfig.system_search_engines_ref"
                            placeholder="{{ l10nKeys.selectSearchEnginesLabel | vtranslate }}"
                        ></avi-collection-dropdown>
                    </div>
                </avi-fieldset_content>
            </avi-fieldset>
        </full-modal-tab-section>

        <!-- Bot Mapping -->
        <full-modal-tab-section
            tabTitle="{{ l10nKeys.botMappingSectionTitle | vtranslate }}"
            class="full-modal-config__grid-container-full"
        >
            <h3>{{ l10nKeys.botMappingSectionTitle | vtranslate }}</h3>

            <div>
                <label
                    aviLabelWithTooltip
                    objectType="BotDetectionPolicy"
                    fieldName="user_bot_mapping_uuid"
                    [required]="!editable.config.system_bot_mapping_ref"
                >
                    {{ l10nKeys.userBotMappingLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    name="user_bot_mapping_uuid"
                    placeholder="{{ l10nKeys.selectUserBotMappingLabel | vtranslate }}"
                    [collection]="botMappingCollection"
                    [required]="!editable.config.system_bot_mapping_ref"
                    [(ngModel)]="editable.config.user_bot_mapping_ref"
                ></avi-collection-dropdown>
            </div>

            <div>
                <label
                    aviLabelWithTooltip
                    objectType="BotDetectionPolicy"
                    fieldName="system_bot_mapping_uuid"
                    [required]="!editable.config.user_bot_mapping_ref"
                >
                    {{ l10nKeys.systemBotMappingLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    name="system_bot_mapping_uuid"
                    placeholder="{{ l10nKeys.selectSystemBotMappingLabel | vtranslate }}"
                    [collection]="botMappingCollection"
                    [disableCreate]="true"
                    [disableEdit]="true"
                    [required]="!editable.config.user_bot_mapping_ref"
                    [(ngModel)]="editable.config.system_bot_mapping_ref"
                ></avi-collection-dropdown>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
