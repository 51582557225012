/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import {
    AzureCredentialsType,
    IAzureCredentialsConfig,
} from 'ajs/modules/ipam/factories/ipam-dns-azure-profile.config-item.factory';

import {
    AzureServicePrincipalCredentials,
    AzureUserPassCredentials,
    IpamDnsAzureProfile,
} from 'object-types';

import { Observable } from 'rxjs';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ipam-dns-azure-credentials-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for editing azure credentials.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-azure-credentials-dialog',
    templateUrl: './ipam-dns-azure-credentials-dialog.component.html',
})
export class IpamDnsAzureCredentialsDialogComponent {
    /**
     * True if the credentials are being edited.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Azure login Config.
     */
    @Input()
    public config: IAzureCredentialsConfig;

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * EventEmitter passed in by the parent. Called when cancelling this dialog.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component.
     * Used to close this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter();

    /**
     * IpamDnsAzureProfile & child objectTypes.
     */
    public readonly objectTypes = {
        IpamDnsAzureProfile,
        AzureUserPassCredentials,
        AzureServicePrincipalCredentials,
    };

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * AzureCredentialsType enum values.
     * To be used in template.
     */
    public readonly AzureCredentialsTypeEnum = AzureCredentialsType;

    /**
     * Errors to be displayed if an error occurs after a connect attempt.
     */
    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Connect button.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handles the cancel operation. Emits the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Called when the Connect button is clicked. Subscribes to the submit$ observable, and closes
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }
}
