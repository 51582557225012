/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module avi/core
 */

import {
    CentralLicenseAlertsService,
} from 'ng/modules/core/services/central-license-alerts/central-license-alerts.service';

import { ControllerFaultsService } from 'ng/modules/core/services/controller-faults.service';

import {
    OverlayRemovalService,
    OVERLAY_REMOVAL_SERVICE_TOKEN,
} from 'ng/modules/core/services/overlay-removal.service';

import {
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
    CONTROLLER_FAULTS_SERVICE_TOKEN,
} from '../../../downgrade-services.tokens';

export const USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN = 'userSessionEventListenersService';

/**
 * @desc Service that sets Angular service listeners on the AJS $rootScope to perform a clean-up on
 *     user logout or setContext changes.
 * @author alextsg
 */
export class UserSessionEventListenersService {
    constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly centralLicenseAlertsService: CentralLicenseAlertsService,
        private readonly controllerFaultsService: ControllerFaultsService,
        private readonly overlayRemovalService: OverlayRemovalService,
    ) {}

    /**
     * Sets listeners on $rootScope to remove FullModal modals.
     */
    public setListeners(): void {
        this.$rootScope.$on('userLoggedOut', this.handleUserLoggedOut);
        this.$rootScope.$on('setContext', this.handleSetContext);
    }

    /**
     * Handle the 'setContext' rootScope event.
     */
    private handleSetContext = (): void => {
        this.reset();
        this.controllerFaultsService.start();
    };

    /**
     * Handle the 'userLoggedOut' event.
     */
    private handleUserLoggedOut = (): void => {
        this.removeData();
        this.controllerFaultsService.stop();
    };

    /**
     * Resets Angular services.
     */
    private reset(): void {
        this.overlayRemovalService.removeAll();
    }

    /**
     * Called on user logged out. Clears localstorage data and resets angular services.
     */
    private removeData = (): void => {
        this.centralLicenseAlertsService.clearAlertsFromLocalStorage();
        this.reset();
    };
}

UserSessionEventListenersService.$inject = [
    '$rootScope',
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
    CONTROLLER_FAULTS_SERVICE_TOKEN,
    OVERLAY_REMOVAL_SERVICE_TOKEN,
];
