/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'boolean-label-filter';
const prefix = `${moduleName}.${componentName}`;

export const yesLabel = `${prefix}.yesLabel`;
export const noLabel = `${prefix}.noLabel`;
export const activeLabel = `${prefix}.activeLabel`;
export const suspendedLabel = `${prefix}.suspendedLabel`;
export const disableLabel = `${prefix}.disableLabel`;
export const enableLabel = `${prefix}.enableLabel`;
export const allowedLabel = `${prefix}.allowedLabel`;
export const disallowedLabel = `${prefix}.disallowedLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const notEnabledLabel = `${prefix}.notEnabledLabel`;
export const trueLabel = `${prefix}.trueLabel`;
export const falseLabel = `${prefix}.falseLabel`;

export const ENGLISH = {
    [yesLabel]: 'Yes',
    [noLabel]: 'No',
    [activeLabel]: 'Active',
    [suspendedLabel]: 'Suspended',
    [disableLabel]: 'Deactivate',
    [enableLabel]: 'Enable',
    [allowedLabel]: 'Allowed',
    [disallowedLabel]: 'Disallowed',
    [enabledLabel]: 'Enabled',
    [notEnabledLabel]: 'Not Enabled',
    [trueLabel]: 'True',
    [falseLabel]: 'False',
};
