/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import {
    GslbPoolConfigItem,
} from 'message-items/gslb-pool.config-item.factory';
import { GslbService } from 'object-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import './gslb-service-pools-grid.component.less';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-pools-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Grid component for GSLB service Pools, display all the Pools configured.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-pools-grid',
    templateUrl: './gslb-service-pools-grid.component.html',
})
export class GslbServicePoolsGridComponent implements AfterViewInit {
    @Input()
    public pools: RepeatedMessageItem<GslbPoolConfigItem>;

    /**
     * Emit when the user add Pool.
     */
    @Output()
    public onAddPool = new EventEmitter<void>();

    /**
     * Emit when the user edit Pool.
     */
    @Output()
    public onEditPool = new EventEmitter<GslbPoolConfigItem>();

    /**
     * Emit when the user delete/deleteAll pool(s).
     */
    @Output()
    public onDeletePool = new EventEmitter<GslbPoolConfigItem>();

    /**
     * TemplateRef for pool enabled.
     */
    @ViewChild('poolEnabledTemplateRef')
    public poolEnabledTemplateRef: TemplateRef<HTMLElement>;

    public readonly objectType = GslbService;

    public poolsGridConfig: IAviDataGridConfig;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly schemaService: SchemaService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            schemaService,
        } = this;

        this.poolsGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform: ({ config }: GslbPoolConfigItem) => config.name || '-',
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.priorityLabel),
                    id: 'priority',
                    transform: ({ config }: GslbPoolConfigItem) => config.priority || '-',
                },
                {
                    label: l10nService.getMessage(l10nKeys.algorithmInputLabel),
                    id: 'algorithm',
                    transform: ({ config }: GslbPoolConfigItem) => schemaService.getEnumValueLabel(
                        'GslbAlgorithm',
                        config.algorithm,
                    ),
                },
                {
                    label: l10nService.getMessage(l10nKeys.enableCheckboxLabel),
                    id: 'enable',
                    templateRef: this.poolEnabledTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (pools: GslbPoolConfigItem[]) => {
                    pools.forEach((pool: GslbPoolConfigItem) => this.deletePool(pool));
                },
            }],
            singleactions: [
                {
                    label: l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (pool: GslbPoolConfigItem) => this.editPool(pool),
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (pool: GslbPoolConfigItem) => this.deletePool(pool),
                },
            ],
            layout: {
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * Handle Add Pool operation. Emit onAddPool EventEmitter.
     */
    public addPool(): void {
        this.onAddPool.emit();
    }

    /**
     * Handle edit Pool operation. Emit onEditPool EventEmitter.
     */
    private editPool(pool: GslbPoolConfigItem): void {
        this.onEditPool.emit(pool);
    }

    /**
     * Handle delete Pool(s) operation. Emit onDeletePool EventEmitter.
     */
    private deletePool(pool: GslbPoolConfigItem): void {
        this.onDeletePool.emit(pool);
    }
}
