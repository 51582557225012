/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    chain,
    contains,
    partition,
} from 'underscore';

import {
    HttpMethod,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper';

import {
    AviPermissionResource,
    IUserRole,
} from 'generated-types';

import { IPromise } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    Component,
    Type,
} from '@angular/core';

import {
    Auth,
    AUTH_SERVICE_TOKEN,
} from 'ajs/modules/core/services';
import { IUIProperty } from 'ng/root-store/user-preferences/user-preferences.state';

import {
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/openstack-keystone/openstack-keystone.service';

import {
    ActiveUserProfileService,
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/active-user-profile';

import {
    IExtendedUser,
    IExtendedUserRole,
    IIndividualTenantRole,
    IUserActivityApiResponse,
} from './user.types';

import * as l10n from './user.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export type TUserItem = typeof UserItem;

/**
 * @description User Item
 * @author Nitesh Kesarkar
 */
export class UserItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        ACTIVE_USER_PROFILE_SERVICE_TOKEN,
        OPENSTACK_KEYSTONE_SERVICE_TOKEN,
        AUTH_SERVICE_TOKEN,
        HTTP_WRAPPER_TOKEN,
        'l10nService',
    ];

    /**
     * ActiveUserProfile instance to get current user profile.
     */
    private readonly activeUserProfileService: ActiveUserProfileService;

    /**
     * ActiveUserProfile instance to get keystone auth info.
     */
    private readonly openstackKeystoneService: OpenstackKeystoneService;

    /**
     * Auth instance to to get tenant lists.
     */
    private readonly authService: Auth;

    /**
     * L10nService instance for translation.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'user',
            objectType: 'User',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_ROLE,
            ...args,
        };

        super(extendedArgs);

        if (!this.opener && this.id) {
            // Adding onSave event listener to notify Auth service on successful save.
            this.bind('itemSaveSuccess', this.onUserSave);
        }

        this.activeUserProfileService = this.getAjsDependency_(ACTIVE_USER_PROFILE_SERVICE_TOKEN);
        this.openstackKeystoneService = this.getAjsDependency_(OPENSTACK_KEYSTONE_SERVICE_TOKEN);
        this.authService = this.getAjsDependency_(AUTH_SERVICE_TOKEN);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Loads user's activity.
     */
    public static loadUserActivity(username: string): IPromise<IUserActivityApiResponse> {
        const requestConfig = {
            url: `/api/useractivity/?name=${username}`,
            method: HttpMethod.GET,
        };

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);
        const httpWrapper = new HttpWrapper();

        return httpWrapper.request(requestConfig)
            .then(({ data }: { data: IUserActivityApiResponse }) => {
                const { results } = data;
                const [activity] = results;

                return activity;
            });
    }

    /**
     * Returns individual tenant roles
     */
    public get individualTenantRoles(): IIndividualTenantRole[] {
        return this.getConfig().individualTenantAccessList || [] as IIndividualTenantRole[];
    }

    /**
     * Returns individual tenant roles count
     */
    public get individualTenantRolesCount(): number {
        return this.individualTenantRoles?.length || 0;
    }

    /**
     * Returns true, if user has all_tenants roles.
     */
    public get hasAllTenants(): boolean {
        return Boolean(this.getConfig().allTenantRoles?.length);
    }

    /**
     * Combines and removes duplicates in individual access list.
     */
    private static compressIndividualTenantsList(accessList: IUserRole[]): IUserRole[] {
        const tenantAccessHash = accessList.reduce((tenantsHash, access) => {
            const { tenant_ref: tenantRef } = access;

            let tenantAccessObj;

            // If tenant_ref is already added, get the corresponding object from the list
            // Else create a new access object for the tenant_ref and update the hash.
            if (tenantRef in tenantsHash) {
                tenantAccessObj = tenantsHash[tenantRef];
            } else {
                tenantAccessObj = {
                    tenant_ref: tenantRef,
                    role_refs: [],
                };

                tenantsHash[tenantRef] = tenantAccessObj;
            }

            const { role_refs: roleRefs } = tenantAccessObj;
            const { role_ref: roleRef } = access;

            if (roleRef) {
                roleRefs.push(roleRef);
            }

            return tenantsHash;
        }, {});

        return Object.values(tenantAccessHash);
    }

    /**
     * Creates new access objects for each roleRefs in an access Object.
     */
    private static expandIndividualTenantsAccess(access: IExtendedUserRole): IUserRole[] {
        const {
            role_refs: roleRefs = [],
            tenant_ref: tenantRef,
        } = access;

        return roleRefs.map((roleRef: string): IUserRole => {
            return {
                tenant_ref: tenantRef,
                role_ref: roleRef,
                all_tenants: false,
            };
        });
    }

    /**
     * Setter for the status i.e. is_active field.
     */
    public set isActive(isActive: boolean) {
        this.getConfig().is_active = isActive;
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { UserModalComponent } = await import(
            /* webpackChunkName: "user-modal" */
            'ng/lazy-loaded-components/modals/user-modal/user-modal.component'
        );

        return UserModalComponent as Type<Component>;
    }

    /**
     * Returns true if Loggedin user is a super user.
     */
    public isSuperUser(): boolean {
        return this.activeUserProfileService.isSuperUser();
    }

    /**
     * Creates new access objects for each role_refs in an access Object.
     */
    public getTenantAccessSummary(access: IUserRole): string {
        const {
            role_ref: roleRef,
            tenant_ref: tenantRef = '',
            all_tenants: allTenants = false,
        } = access;

        const tenantName = allTenants ?
            this.l10nService.getMessage(l10nKeys.allTenants) :
            this.stringService.name(tenantRef);

        const roleName = roleRef ? this.stringService.name(roleRef) : '';

        return `${tenantName} (${roleName})`;
    }

    /**
     * Returns the name of the user.
     * Full name if its available or username.
     */
    public getName(): string {
        const config = this.getConfig();

        return config.full_name || config.username || '';
    }

    /**
     * True if current logged-in user is the same as that of user being edited.
     * Returns true if user matches else false.
     */
    public isActiveUser(): boolean {
        const config = this.getConfig();

        return config.id && config.username === this.activeUserProfileService.username;
    }

    /** @override */
    public beforeEdit(): IExtendedUser {
        const userConfig = this.getConfig();

        // If email has empty value remove the email key
        if (userConfig.email === '') {
            delete userConfig.email;
        }

        if (!userConfig.access) {
            userConfig.access = [];
        }

        // Creates two seperate lists (individual tenants and all tenants) from config.access
        // To display tenant-settings in User create/edit modal.
        // original accessList is not polluted so it can be accessed for other purposes.
        const [
            allTenantsAccessList,
            individualTenantAccessList,
        ] = partition(userConfig.access, access => access.all_tenants);

        /**
         * UI Only property to show all tenants role selection.
         * List of role_refs selected for all_tenants
         */
        userConfig.allTenantRoles = chain(allTenantsAccessList).pluck('role_ref').uniq().value();

        const compressedIndividualTenantsList =
            UserItem.compressIndividualTenantsList(individualTenantAccessList);

        /**
         * UI Only property that contains only Individual Tenant access configuration.
         * @type {module:avi/accounts.CompressedTenantAccess[]}
         */
        userConfig.individualTenantAccessList = compressedIndividualTenantsList as string[];

        return userConfig;
    }

    /**
     * Transforms permissions tree structure into server privileges data structure and
     * appends the privileges which are not exposed to UI but added through CLI to
     * the config privileges list in order to avoid resetting them.
     * @override
     */
    public dataToSave(): IExtendedUser {
        const config = super.dataToSave();

        // `allTenantRoles` and `individualTenantAccessList` will be set in `beforeEdit()`
        // to display Tenant-selection in User create/edit modal.
        // But some cases (like Activate/Suspend of user from Grid) skips `beforeEdit`
        // and directly call `dataToSave()`, So to avoid exceptions
        // do the following only when `beforeEdit` is called before `dataToSave`.
        if ('allTenantRoles' in config) {
            const {
                individualTenantAccessList,
                allTenantRoles,
            } = config;

            const expandedIndividualTenantsList =
                individualTenantAccessList.reduce((accessList: IUserRole[], access: IUserRole) => {
                    accessList.push(...UserItem.expandIndividualTenantsAccess(access as IUserRole));

                    return accessList;
                }, []);

            const accessList = [
                ...expandedIndividualTenantsList,
            ];

            allTenantRoles.forEach((roleRef: string) => {
                const allTenantsAccess = {
                    all_tenants: true,
                    role_ref: roleRef,
                };

                accessList.push(allTenantsAccess);
            });

            config.access = accessList;

            delete config.allTenantRoles;
            delete config.individualTenantAccessList;
        }

        // Serialize ui property before saving
        if (typeof config.ui_property === 'string') {
            config.ui_property = JSON.stringify(config.ui_property) as IUIProperty;
        }

        if (!config.password) {
            delete config.password;
        }

        delete config.confirm_password;

        // If individual tenant or all tenants are not present then reset default_tenant_ref
        if (!config.access.length) {
            config.default_tenant_ref = '';
        }

        return config;
    }

    /**
     * Special case for user object, all users are editable if there is permission.
     */
    public isEditable(): boolean {
        const config = this.getConfig();

        // Enforce to disable editing remote users if keystone auth is enabled
        if (this.openstackKeystoneService.keystoneAuthEnabled &&
            !config.local && this.stringService.slug(config.default_tenant_ref) !== 'admin') {
            return false;
        }

        return super.isEditable.call(this);
    }

    /** @override */
    public isDroppable(): boolean {
        if (this.isProtected()) {
            return false;
        }

        return this.isAllowed();
    }

    /** @override */
    protected transformDataAfterSave(rsp: any): IExtendedUser {
        this.decodeUIProperty(rsp.data);

        return rsp.data;
    }

    /** @override */
    protected transformAfterLoad(): void {
        this.decodeUIProperty();
    }

    /**
     * Getter for username
     */
    private get username(): string {
        return this.getConfig().username || '';
    }

    /**
     * Fires on successful user save. If current user is being edited,
     * Control goes to AuthService to update Tenant List.
     */
    private onUserSave(): void {
        if (this.username === this.activeUserProfileService.username) {
            this.authService.onCurrentUserChange();
        }
    }

    /**
     * Parses UI Property and sets to config data.
     */
    private decodeUIProperty(data?: IExtendedUser): void {
        const config = data || this.getConfig();
        let uiProperty = config.ui_property;
        const displayValues = ['avg', 'max', 'sum', 'current'];

        if (uiProperty && typeof uiProperty === 'string') {
            config.ui_property = JSON.parse(uiProperty);
            uiProperty = config.ui_property;

            if (!contains(displayValues, uiProperty?.valuesToDisplay)) {
                uiProperty.valuesToDisplay = 'avg';
            }
        } else if (uiProperty === undefined || uiProperty === '') {
            config.ui_property = {
                useUTCTime: false,
                timeframe: '6h',
                valuesToDisplay: 'avg',
            };
        }
    }
}
