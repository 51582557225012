/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-ip-reputation-type-mapping';
const prefix = `${moduleName}.${componentName}`;

export const ipReputationMappingTitle = `${prefix}.ipReputationMappingTitle`;

export const ENGLISH = {
    [ipReputationMappingTitle]: 'IP Reputation Mapping',
};
