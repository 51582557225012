<div class="avi-state-page__main-section">
    <ng-content select="avi-state-page_top-bar"></ng-content>

    <div class="avi-state-page__content">
        <ng-content select="avi-state-page_main-content"></ng-content>
    </div>
</div>

<avi-right-rail
    class="avi-state-page__sidebar"
    *ngIf="hasSidebar"
    [(collapsed)]="isSideBarCollapsed"
>
    <avi-right-rail_body>
        <ng-content select="avi-state-page_sidebar"></ng-content>
    </avi-right-rail_body>
    <avi-right-rail_body-collapsed>
        <ng-content select="avi-state-page_sidebar-collapsed"></ng-content>
    </avi-right-rail_body-collapsed>
</avi-right-rail>
