/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';
import {
    AviPermissionResource,
    IAutoScaleLaunchConfig,
} from 'generated-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { AutoScaleLaunchConfig } from 'object-types';
import { LaunchConfigModalComponent } from '../../../angular/modules/launch-config';
import { withFullModalMixin } from '../../../js/utilities/mixins';
import * as l10n from '../launch-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   AutoScale Launch Config Item
 *
 * @author Harmeet Kaur
 */
export class AutoScaleLaunchConfigItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'autoscalelaunchconfig',
            objectType: AutoScaleLaunchConfig,
            windowElement: LaunchConfigModalComponent,
            permissionName: AviPermissionResource.PERMISSION_AUTOSCALE,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public dataToSave(): IAutoScaleLaunchConfig {
        const config: IAutoScaleLaunchConfig = super.dataToSave();

        config.image_id = config.image_id || 'default';

        return config;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.launchConfigModalBreadcrumbTitle);
    }
}
