/**
 * @module BotModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    BotDetectionPolicyCollection,
    TBotDetectionPolicyCollection,
} from 'ajs/modules/bot-detection-policy/factories/bot-detection-policy.collection.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './bot-management-policy-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Bot Management policy list page component.
 *
 * @author Hitesh Mandav.
 */
@Component({
    selector: 'bot-management-policy-list',
    templateUrl: './bot-management-policy-list.component.html',
})
export class BotManagementPolicyListComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Configuration object to display bot management policy
     */
    public botManagementPolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the bot detection policy collection.
     */
    private readonly botDetectionPolicyCollection: BotDetectionPolicyCollection;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(BotDetectionPolicyCollection)
        BotDetectionPolicyCollection: TBotDetectionPolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.botDetectionPolicyCollection = new BotDetectionPolicyCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.botDetectionPolicyCollection;

        this.botManagementPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.botDetectionPolicyCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.botDetectionPolicyCollection.destroy();
    }
}
