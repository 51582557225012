<cinematic (onClose)="handleClose()">
    <cinematic_custom-header>
        <div class="vs-log-cinematic__header">
            <div class="vs-log-cinematic__header-top">
                {{ vsName$ | async }} {{ globalL10nKeys.logLabel | vtranslate }}
            </div>
            <ng-container *ngrxLet="busy$ as busy">
                <div class="vs-log-cinematic__header-main">
                    <h2 class="vs-log-cinematic__header-text">
                        <ng-container *ngIf="!busy; else logLabelOnly">
                            {{ (isSignificantLog$ | async) ? (l10nKeys.significantLabel | vtranslate) : (l10nKeys.nonSignificantLabel | vtranslate) }} {{ globalL10nKeys.logLabel | vtranslate }}
                        </ng-container>
                        <ng-template #logLabelOnly>
                            {{ globalL10nKeys.logLabel | vtranslate }}
                        </ng-template>
                    </h2>
                    <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L7">
                        <ng-container *ngrxLet="wafStatus$ as wafStatus">
                            <cds-icon
                                *ngIf="wafStatus === 'FLAGGED' || wafStatus === 'REJECTED'"
                                class="vs-log-cinematic__header-icon"
                                [ngClass]="{ 'vs-log-cinematic__header-icon--deactivated': busy }"
                                shape="recommendation"
                                size="sm"
                                [status]="!busy && 'info'"
                                [disabled]="busy"
                                (click)="!busy && openRecommendationDialog()"
                            ></cds-icon>
                        </ng-container>
                    </ng-container>
                    <cds-icon
                        class="vs-log-cinematic__header-icon"
                        [ngClass]="{ 'vs-log-cinematic__header-icon--deactivated': busy }"
                        shape="download"
                        size="sm"
                        [status]="!busy && 'info'"
                        [disabled]="busy"
                        (click)="!busy && downloadLog()"
                    ></cds-icon>
                    <!-- TODO: to be addressed in AV-153259 -->
                    <!-- <cds-icon
                        class="vs-log-cinematic__header-icon"
                        shape="printer"
                        size="sm"
                        [status]="!busy && info"
                        [disabled]="busy"
                    ></cds-icon> -->
                </div>
                <div class="vs-log-cinematic__header-subtitle">
                    <ng-container *ngIf="!busy; else defaultTimeStamp">
                        {{ timestamp$ | async | aviDate }}
                    </ng-container>
                    <ng-template #defaultTimeStamp>
                        --/-- --:--:-- {{ globalL10nKeys.amLabel | vtranslate }}
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </cinematic_custom-header>

    <cinematic_errors>
        <vs-logs-errors
            *ngIf="hasError$ | async"
            errorMessage="{{ (errors$ | async | aviBackendErrorMsg) || (l10nKeys.defaultErrorMsgLabel | vtranslate) }}"
            actionLabel="{{ globalL10nKeys.reloadLabel | vtranslate }}"
            (onActionClick)="loadLog()"
            [closable]="false"
        ></vs-logs-errors>
    </cinematic_errors>

    <div
        *ngIf="loading$ | async"    
        class="vs-log-cinematic__loader-container"
    >
        <span class="spinner spinner-md"></span>
    </div>

    <ng-container *ngIf="!(loading$ | async) && !(hasError$ | async)">
        <!----------------------------------- Top Info Section ---------------------------------->
        <vs-log-cinematic-top-info></vs-log-cinematic-top-info>

        <!------------------------------------- Tab Sections ------------------------------------>

        <!-- Significance Section -->
        <cinematic-tab-section
            *ngIf="significance$ | async"
            [tabTitle]="l10nKeys.significanceLabel | vtranslate"
        >
            <vs-log-cinematic-section-wrapper
                [header]="l10nKeys.significanceLabel | vtranslate"
                icon="lightbulb"
            >
                <vs-log-cinematic-section-significance></vs-log-cinematic-section-significance>
            </vs-log-cinematic-section-wrapper>
        </cinematic-tab-section>

        <!-- Summary Section -->
        <cinematic-tab-section [tabTitle]="globalL10nKeys.summaryLabel | vtranslate">
            <vs-log-cinematic-section-wrapper
                [header]="globalL10nKeys.summaryLabel | vtranslate"
                icon="details"
            >
                <vs-log-cinematic-section-summary></vs-log-cinematic-section-summary>
            </vs-log-cinematic-section-wrapper>
        </cinematic-tab-section>

        <!-- L4 Log only sections -->
        <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L4">
            <!-- Client Connection Section -->
            <cinematic-tab-section
                *ngIf="l4ClientConnectionInfo$ | async"
                [tabTitle]="l10nKeys.clientConnectionLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.clientConnectionLabel | vtranslate"
                    icon="display"
                >
                    <vs-log-cinematic-section-connection
                        [l4ConnectionType]="L4ConnectionType.CLIENT"
                    ></vs-log-cinematic-section-connection>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Server Connection Section -->
            <cinematic-tab-section
                *ngIf="l4ServerConnectionInfo$ | async"
                [tabTitle]="l10nKeys.serverConnectionLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.serverConnectionLabel | vtranslate"
                    icon="host"
                >
                    <vs-log-cinematic-section-connection
                        [l4ConnectionType]="L4ConnectionType.SERVER"
                    ></vs-log-cinematic-section-connection>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>
        </ng-container>
    
        <!-- L7 Log only sections -->
        <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.L7">
            <!-- SSL Section -->
            <cinematic-tab-section
                *ngIf="ssl$ | async"
                [tabTitle]="l10nKeys.sslLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.sslLabel | vtranslate"
                    icon="key"
                >
                    <vs-log-cinematic-section-ssl></vs-log-cinematic-section-ssl>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Application Section -->
            <cinematic-tab-section
                *ngIf="applicationInfo$ | async"
                [tabTitle]="l10nKeys.applicationLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.applicationLabel | vtranslate"
                    icon="application"
                >
                    <vs-log-cinematic-section-application></vs-log-cinematic-section-application>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Protocol Section -->
            <cinematic-tab-section
                *ngIf="grpcInfo$ | async"
                [tabTitle]="l10nKeys.protocolLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.protocolLabel | vtranslate"
                    icon="application"
                >
                    <vs-log-cinematic-section-protocol></vs-log-cinematic-section-protocol>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Headers Section -->
            <cinematic-tab-section
                *ngIf="(httpRequestHeaders$ | async) || (httpResponseHeaders$ | async)"
                [tabTitle]="globalL10nKeys.headersLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="globalL10nKeys.headersLabel | vtranslate"
                    icon="details"
                >
                    <vs-log-cinematic-section-headers></vs-log-cinematic-section-headers>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Authentication Section -->
            <cinematic-tab-section
                *ngIf="hasAutenticationLogs$ | async"
                [tabTitle]="globalL10nKeys.authenticationLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="globalL10nKeys.authenticationLabel | vtranslate"
                    icon="details"
                >
                    <vs-log-cinematic-section-authentication></vs-log-cinematic-section-authentication>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Security Section -->
            <cinematic-tab-section
                *ngIf="(icapLog$ | async) || (wafLog$ | async) || (botManagementLog$ | async)"    
                [tabTitle]="globalL10nKeys.securityLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="globalL10nKeys.securityLabel | vtranslate"
                    icon="shield"
                >
                    <vs-log-cinematic-section-security></vs-log-cinematic-section-security>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Datascript Section -->
            <cinematic-tab-section
                *ngIf="datascriptInfo$ | async"
                [tabTitle]="l10nKeys.datascriptLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.datascriptLabel | vtranslate"
                    icon="code"
                >
                    <vs-log-cinematic-section-datascript></vs-log-cinematic-section-datascript>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>

            <!-- Out of Band Section -->
            <cinematic-tab-section
                *ngIf="oobDsRequestLogs$ | async"
                [tabTitle]="l10nKeys.outOfBandLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="l10nKeys.outOfBandLabel | vtranslate"
                    icon="bolt"
                >
                    <vs-log-cinematic-section-out-of-band></vs-log-cinematic-section-out-of-band>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>
        </ng-container>

        <!-- DNS Log only sections -->
        <ng-container *ngIf="(vsAppProfileType$ | async) === VsAppProfileType.DNS">
            <!-- DNS Section -->
            <cinematic-tab-section
                *ngIf="(dnsRequestInfo$ | async) || (dnsResponseInfo$ | async)"
                [tabTitle]="globalL10nKeys.dnsLabel | vtranslate"
            >
                <vs-log-cinematic-section-wrapper
                    [header]="globalL10nKeys.dnsLabel | vtranslate"
                    icon="network-globe"
                >
                    <vs-log-cinematic-section-dns></vs-log-cinematic-section-dns>
                </vs-log-cinematic-section-wrapper>
            </cinematic-tab-section>
        </ng-container>
    </ng-container>
</cinematic>
