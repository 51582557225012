<div
    class="avi-dropdown-option"
    avi-tooltip
    [positionsPriority]="tooltipPositionsPriority"
    [delay]="500"
    [hideTooltip]="!tooltipText"
    (onPositionChange)="handlePositionChange($event)"
>
    <div
        *ngIf="optionTemplateRef; else defaultView"
        class="avi-dropdown-option__custom-template-container"
    >
        <ng-container
            [ngTemplateOutlet]="optionTemplateRef"
            [ngTemplateOutletContext]="{ $implicit: value }"
        ></ng-container>
    </div>
    <ng-template #defaultView>
        <span class="avi-dropdown-option__label sel-avi-dropdown-option__label">
            {{ label }}
        </span>
    </ng-template>
    <ng-template #aviTooltipContent>
        <div
            class="avi-dropdown-option__tooltip"
            [ngClass]="caretClassName"
        >
            {{ tooltipText }}
        </div>
    </ng-template>
</div>
