/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    GeoLocation,
    GslbSite,
} from 'object-types';

import {
    GslbGeoLocationConfigItem,
} from 'message-items/gslb-geo-location.config-item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-geo-location-config.l10n';
import './gslb-geo-location-config.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for gslb geo location config.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-geo-location-config',
    templateUrl: './gslb-geo-location-config.component.html',
})
export class GslbGeoLocationConfigComponent {
    @Input()
    public editable: GslbGeoLocationConfigItem;

    @Input()
    public isLocationConfigured: boolean;

    @Output()
    public onSetGeoLocationSource = new EventEmitter<boolean>();

    /**
     * Object types for gslb geo location.
     */
    public objectType = {
        GslbSite,
        GeoLocation,
    };

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Emit location checked status to set geo location source.
     */
    public setGeoLocationSource(isLocationChecked: boolean): void {
        this.onSetGeoLocationSource.emit(isLocationChecked);
    }
}
