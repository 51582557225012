/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    TenantSettingsItem,
} from 'items/tenant-settings.item.factory';

import {
    TenantSettingsCollection,
    TTenantSettingsCollection,
} from 'collections/tenant-settings.collection.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './tenant-settings-page-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Tenant settings list page.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'tenant-settings-page-list',
    templateUrl: './tenant-settings-page-list.component.html',
})
export class TenantSettingsPageListComponent implements OnInit, OnDestroy {
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly tenantSettingsCollection: TenantSettingsCollection;

    public tenantSettingsGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(TenantSettingsCollection)
        TenantCollection: TTenantSettingsCollection,
    ) {
        this.tenantSettingsCollection = new TenantCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.tenantSettingsCollection;

        this.tenantSettingsGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.tenantSettingsCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                sortBy: 'name',
                transform: (row: TenantSettingsItem) => row.getName(),
            }, {
                id: 'dns-vs-count',
                label: this.l10nService.getMessage(l10nKeys.dnsVirtualServicesLabel),
                // eslint-disable-next-line max-len
                transform: (row: TenantSettingsItem) => row.config.dns_virtualservice_refs?.length || 0,
            }],
            layout: {
                hideCheckboxes: true,
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.tenantSettingsCollection.destroy();
    }
}
