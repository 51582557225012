<div
    class="vs-log-cinematic-section-security-bot"
    *ngIf="botManagementLog$ | async as botManagementLog"
>
    <div class="cinematic__form-control-row">
        <avi-card class="cinematic__form-control vs-log-cinematic-section-security-bot__avi-card">
            <avi-card_body>
                <span
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.classificationLabel | vtranslate }}
                </span>
                <span
                    vsLogFilter
                    property="bot_management_log.classification.type"
                    [value]="classificationType"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                    *ngIf="botManagementLog.classification?.type as classificationType; else na"
                >
                    {{ enumLabelHash[classificationType] }}
                </span>
            </avi-card_body>
        </avi-card>

        <avi-card class="cinematic__form-control vs-log-cinematic-section-security-bot__avi-card">
            <avi-card_body class="cinematic__form-control-row vs-log-cinematic-section-security-bot__no-margin-top">
                <div class="cinematic__form-control">
                    <span
                        vsLogCinematicFieldTitle
                        [withMarginTop]="false"
                    >
                        {{ l10nKeys.botClientTypeLabel | vtranslate }}
                    </span>
                    <span
                        vsLogFilter
                        property="bot_management_log.results.identification.type"
                        [value]="identificationType"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        *ngIf="botManagementLog.results[0]?.identification?.type as identificationType; else na"
                    >
                        {{ enumLabelHash[identificationType] }}
                    </span>
                </div>
                <div class="cinematic__form-control">
                    <span
                        vsLogCinematicFieldTitle
                        [withMarginTop]="false"
                    >
                        {{ l10nKeys.identifierLabel | vtranslate }}
                    </span>
                    <span
                        vsLogFilter
                        property="bot_management_log.results.identification.identifier"
                        [value]="identifier"
                        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                        *ngIf="botManagementLog.results[0]?.identification?.identifier as identifier; else na"
                    >
                        {{ identifier }}
                    </span>
                </div>
            </avi-card_body>
        </avi-card>
    </div>

    <avi-data-grid
        *ngIf="isBotGridShown"
        [config]="securityBotGridConfig"
        [rows]="botManagementLog.results"
    ></avi-data-grid>

    <cds-button
        class="vs-log-cinematic-section-security-bot__show-components-button"
        action="flat"
        (click)="toggleBotGridVisibility()"
    >
        {{ (isBotGridShown ? l10nKeys.hideComponentsButtonLabel : l10nKeys.showComponentsButtonLabel) | vtranslate }}
    </cds-button>
</div>

<ng-template
    #notesContentTemplateRef
    let-row
    let-index="index"
>
    <avi-card class="vs-log-cinematic-section-security-bot__notes-card">
        <avi-card_body>
            <span vsLogCinematicFieldTitle [withMarginTop]="false">
                {{ globalL10nKeys.notesLabel | vtranslate }}
            </span>
            <span *ngIf="row.notes as notes; else na">
                {{ notes }}
            </span>
        </avi-card_body>
    </avi-card>
</ng-template>

<ng-template
    #componentFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="bot_management_log.results.component"
        [value]="component"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.component as component; else na"
    >
        {{ enumLabelHash[component] }}
    </span>
</ng-template>

<ng-template
    #botClientTypeFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="bot_management_log.results.identification.type"
        [value]="identificationType"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.identification?.type as identificationType; else na"
    >
        {{ enumLabelHash[identificationType] }}
    </span>
</ng-template>

<ng-template
    #botClientClassFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="bot_management_log.results.identification.class"
        [value]="identificationClass"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.identification?.class as identificationClass; else na"
    >
        {{ enumLabelHash[identificationClass] }}
    </span>
</ng-template>

<ng-template
    #confidenceFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="bot_management_log.results.confidence"
        [value]="confidence"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.confidence as confidence; else na"
    >
        {{ enumLabelHash[confidence] }}
    </span>
</ng-template>

<ng-template
    #identifierFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        vsLogFilter
        property="bot_management_log.results.identification.identifier"
        [value]="identifier"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
        *ngIf="row.identification?.identifier as identifier; else na"
    >
        {{ identifier }}
    </span>
</ng-template>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
