/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { IIcapLogDisplayRow } from 'ajs/modules/logs';
import * as l10n from './icap-log-headers.l10n';
import template from './icap-log-headers.component.html';
import './icap-log-headers.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const newLineSplitter = '\\r\\n';

/**
 * @author akulaggarwal
 * @desc Component for ICAP log headers.
 */
class IcapLogHeadersComponent {
    public readonly l10nKeys = l10nKeys;
    public splitHeadersSent: string[];
    public splitHeadersReceived: string[];
    private readonly headersSent: IIcapLogDisplayRow['icap_headers_sent_to_server'];
    private readonly headersReceived: IIcapLogDisplayRow['icap_headers_received_from_server'];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** Split input header by new line chars. */
    private static splitHeader(header: string): string[] {
        if (header) {
            return JSON.stringify(header).slice(1, -2).split(newLineSplitter);
        }

        return [];
    }

    public $onInit(): void {
        this.splitHeaders();
    }

    /** Split sent & received headers. */
    private splitHeaders(): void {
        this.splitHeadersSent = IcapLogHeadersComponent.splitHeader(this.headersSent.display);
        this.splitHeadersReceived =
            IcapLogHeadersComponent.splitHeader(this.headersReceived.display);
    }
}

IcapLogHeadersComponent.$inject = [
    'l10nService',
];

export const icapLogHeadersComponentOptions = {
    bindings: {
        headersSent: '<',
        headersReceived: '<',
    },
    controller: IcapLogHeadersComponent,
    template,
};
