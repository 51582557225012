/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    Input,
} from '@angular/core';

// @ts-expect-error
import * as d3 from 'd3';
import { LicenseTierType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-usage-card.l10n';
import './license-usage-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Bar colors to show license usage.
 */
const colors = {
    normalLicenseUsage: ['#0179B8', '#D3D3D3'],
    extraorExpiredLicenseUsage: ['#0179B8', '#E62701'],
};

/**
 * License usage data type.
 */
interface ILicenseUsage {
    usedLicenseCores: number;
    totalLicenseCores: number;
}

/**
 * @description
 *     Stacked bar graph to display license service Core Usage data.
 * @author Rohit Gaikwad
 */
@Component({
    selector: 'license-usage-card',
    templateUrl: './license-usage-card.component.html',
})
export class LicenseUsageCardComponent {
    /**
     * Used service cores from total licensed service cores.
     */
    @Input()
    public usedServiceCores: number;

    /**
     * Total Cores from licensed Service Cores.
     */
    @Input()
    public totalLicensedServiceCores: number;

    /**
     * Default license tier for template usage.
     */
    @Input()
    public licenseTierType: LicenseTierType;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Lisence usage data for graph.
     */
    private data: ILicenseUsage[];

    /**
     * Unlicensed count tooltip text.
     */
    private toolTipSubText = '';

    /**
     * Properties required for graph.
     */
    private width = 40;
    private height = 100;
    private y: any;
    private svg: any;
    private stack: any;
    private chart: any;
    private layersBarArea: any;
    private layersBar: any;
    private stackedSeries: any;
    private colors = colors.normalLicenseUsage;

    constructor(
        private container: ElementRef,
        private l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setGraphProperties();
        this.stack = d3.stack().keys(['usedLicenseCores', 'totalLicenseCores']);
        this.initScales();
        this.initSvg();
        this.createStack(this.data);
    }

    /**
     * Sets the graph properties like colors for stacked bars and the data.
     */
    private setGraphProperties(): void {
        let usedcores = this.usedServiceCores;
        let totalLicensedCores = this.totalLicensedServiceCores - this.usedServiceCores;

        if (this.usedServiceCores > this.totalLicensedServiceCores) {
            usedcores = this.totalLicensedServiceCores;
            totalLicensedCores = this.usedServiceCores - this.totalLicensedServiceCores;
            this.colors = colors.extraorExpiredLicenseUsage;

            this.toolTipSubText = `
                <span class='unlicensed-usage'>
                    (${totalLicensedCores} ${this.l10nService.getMessage(l10nKeys.unlicensedLabel)})
                </span>`;
        }

        /**
         * Need to add some default value to totalLicensedServiceCores to display the bar.
         * This scenario occurs when we do not have any allocated licenses.
         */
        if (totalLicensedCores === 0 && usedcores === 0) {
            totalLicensedCores = 1;
        }

        this.data = [
            {
                usedLicenseCores: usedcores,
                totalLicenseCores: totalLicensedCores,
            },
        ];
    }

    /**
     * Function to initialise the scales.
     */
    private initScales(): void {
        this.y = d3.scaleLinear()
            .range([this.height, 0]);
    }

    /**
     * Function to initialise the svg for graph.
     */
    private initSvg(): void {
        this.svg = d3.select(this.container.nativeElement)
            .select('.chart-container')
            .append('svg')
            .attr('preserveAspectRatio', 'xMinYMin meet')
            .attr('class', 'chart')
            .attr('viewBox', '0 0 100 100');

        this.chart = this.svg.append('g')
            .classed('chart-contents', true)
            .attr('transform', 'translate(0, 0)');

        this.layersBarArea = this.chart.append('g')
            .classed('layers', true);
    }

    /**
     * Function to create stacked series from given data.
     */
    private createStack(stackData: any): void {
        this.stackedSeries = this.stack(stackData);
        this.drawChart(this.stackedSeries);
    }

    /**
     * Function to draw the chart.
     */
    private drawChart(data: any): void {
        this.layersBar = this.layersBarArea.selectAll('.layer')
            .data(data)
            .enter()
            .append('g')
            .classed('layer', true)
            .style('fill', (d: any, i: any) => {
                return this.colors[i];
            });

        this.y.domain([0, +d3.max(this.stackedSeries, function(d: any) {
            return d3.max(d, (d: any) => {
                return d[1];
            });
        })]);

        let toolTipBottomMargin = 0;

        this.layersBar.selectAll('rect')
            .data((d: any) => {
                return d;
            })
            .enter()
            .append('rect')
            .attr('y', (d: any) => {
                return this.y(d[1]);
            })
            .attr('width', this.width)
            .attr('height', (d: any) => {
                if (d[0] === 0 &&
                    this.usedServiceCores !== 0) {
                    toolTipBottomMargin = this.y(d[0]) - this.y(d[1]);
                }

                return this.y(d[0]) - this.y(d[1]);
            });

        // This check is required to display the tooltip at the top of the bar.
        if (this.usedServiceCores > this.totalLicensedServiceCores) {
            toolTipBottomMargin = 100;
        }

        /**
         * Need to calculate the exact bar height to place the tooltip.
         */
        toolTipBottomMargin = toolTipBottomMargin / 10 * 3.2 + toolTipBottomMargin - 2;

        d3.select('.tooltip-container')
            .append('div')
            .style('opacity', 1)
            .attr('class', 'tooltip')
            .style('background-color', 'white')
            .style('border-top', 'solid')
            .style('border-width', '1px')
            .style('padding', '7px')
            .style('left', '52px')
            .style('bottom', `${toolTipBottomMargin}px`)
            .html(`
                <div class='tooltip-body'>
                    <div class="tooltip-header">
                        ${this.l10nService.getMessage(l10nKeys.usedServiceUnitsLabel)}
                    </div>
                    <span class="used-license-cores">${this.usedServiceCores}</span>
                    ${this.toolTipSubText}
                </div>
            `);

        // Add css to place tooltip from the bottom of the bar.
        if (toolTipBottomMargin < 20) {
            d3.select('.tooltip')
                .style('border-top', 'none')
                .style('border-bottom', '1px solid')
                .style('bottom', `${toolTipBottomMargin + 42}px`);
        }
    }
}
