/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isEmpty } from 'underscore';

/**
 * @ngdoc service
 * @name InventoryMapPool
 * @author Alex Malitsky
 * @description
 *
 *     Besides being a normal Pool instance this Item can keep Server and Network item arrays
 *     inside. As well as Pool#data#type (can be either `ab-child`, `ab-parent` or `backup`)
 *     and Pool#data@parentPoolId (for backup and ab-child only). Used by InventoryMap only.
 *
 */
angular.module('aviApp').factory('InventoryMapPool', ['Pool', 'Server', function(Pool, Server) {
    /**
     * List of data properties considered to be about Item itself, not nested objects.
     * @type {string[]}
     * @inner
     */
    const ownItemProperties = [
        'config',
        'runtime',
        'health_score',
        'alert',
        'parentPoolId',
        'type',
        'virtualservices',
    ];

    /**
     * PoolData has config, runtime, health_score and alert data as well as arrays of nested
     * servers and networks.
     * @param {Object} poolData
     * @constructor
     * @extends Pool
     */
    class InventoryMapPool extends Pool {
        constructor(poolData) {
            super({ data: _.pick(poolData, ownItemProperties) });

            this.networks = {};
            this.servers = {};

            this.updateItemData(poolData);
        }

        /**
         * Updates Item's config as well as nested arrays of servers and networks.
         * @param {Object} poolData - Item#data, servers and networks.
         */
        updateItemData(poolData) {
            const
                updatedServersHash = {},
                updatedNetworksHash = {};

            this.networksArr = angular.copy(poolData.networks);
            this.serversArr = angular.copy(poolData.servers);

            _.each(poolData.networks, function(networkData) {
                const id = networkData.config.name;

                updatedNetworksHash[id] = true;

                if (!(id in this.networks)) {
                    this.networks[id] = this.createNetworkItem_(networkData);
                } else if (this.itemDataDiff_(this.networks[id].data, networkData) ||
                    !angular.equals(this.networks[id].serversArr, networkData.servers)) {
                    this.updateNetworkItem_(this.networks[id], networkData);
                }
            }, this);

            _.each(this.networks, function(network, name, hash) {
                if (!(name in updatedNetworksHash)) {
                    this.destroyNetworkItem_(network);
                    delete hash[name];
                }
            }, this);

            _.each(poolData.servers, function(serverData) {
                const id = serverData.config.uuid;

                updatedServersHash[id] = true;

                if (!(id in this.servers)) {
                    this.servers[id] = new Server({ data: serverData });
                } else if (this.itemDataDiff_(this.servers[id].data, serverData)) {
                    this.servers[id].updateItemData(serverData);
                }
            }, this);

            _.each(this.servers, function(server, id, hash) {
                if (!(id in updatedServersHash)) {
                    server.destroy();
                    delete hash[id];
                }
            });

            if (this.itemDataDiff_(this.data, poolData)) {
                super.updateItemData(_.pick(poolData, ownItemProperties));
            }
        }

        /**
         * Returns true if pool has networks.
         * @returns {boolean}
         */
        hasNetworks() {
            const { networks } = this;

            return !isEmpty(networks);
        }

        /**
         * Returns true if pool has servers.
         * @returns {boolean}
         */
        hasServers() {
            const { servers } = this;

            return !isEmpty(servers);
        }

        /** @override */
        destroy() {
            _.each(this.networks, this.destroyNetworkItem_.bind(this));
            _.each(this.servers, server => server.destroy());
            super.destroy();
        }
    }

    /**
     * Compares two Item data objects to figure out whether they are same. Omits non owned
     * properties.
     * @param {Item#data} oldData
     * @param {Item#data} newData
     * @returns {boolean} - true if equal, false otherwise
     */
    InventoryMapPool.prototype.itemDataDiff_ = function(oldData, newData) {
        return _.any(ownItemProperties, function(key) {
            return !angular.equals(oldData[key], newData[key]);
        });
    };

    /**
     * Creates a fake Network having Item looking data as well as hash of nested servers.
     * @param networkData
     * @returns {Object}
     * @protected
     */
    InventoryMapPool.prototype.createNetworkItem_ = function(networkData) {
        const network = { data: angular.copy(_.pick(networkData, ownItemProperties)) };

        network.servers = {};

        this.updateNetworkItem_(network, networkData);

        return network;
    };

    /**
     * Updates kinda Network Item with a fresh data.
     * @param {Object} network - Kinda network Item.
     * @param {Object} networkData - Item#data with array of nested servers.
     * @protected
     */
    InventoryMapPool.prototype.updateNetworkItem_ = function(network, networkData) {
        const updatedServersHash = {};

        network.serversArr = angular.copy(networkData.servers);

        _.each(networkData.servers, function(serverData) {
            const id = serverData.config.uuid;

            updatedServersHash[id] = true;

            if (!(id in network.servers)) {
                network.servers[id] = new Server({ data: serverData });
            } else if (this.itemDataDiff_(network.servers[id].data, serverData)) {
                network.servers[id].updateItemData(serverData);
            }
        }, this);

        //delete servers which are not in response any more
        _.each(network.servers, function(server, id, hash) {
            if (!(id in updatedServersHash)) {
                server.destroy();
                delete hash[id];
            }
        });
    };

    /**
     * Since inside Network Item we keep a hash of servers we need to destroy them manually.
     * @param {Object} network - Kinda Network Item.
     * @protected
     */
    InventoryMapPool.prototype.destroyNetworkItem_ = function(network) {
        _.each(network.servers, function(server) {
            server.destroy();
        });
    };

    return InventoryMapPool;
}]);
