/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MessageItemsModule */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IVlanInterface } from 'generated-types';
import { VlanInterface } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { VNICNetworksConfigItem } from './vnic-networks.config-item.factory';
import * as l10n from './vlan.interface.l10';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVlanInterfacePartial = Omit<IVlanInterface, 'vnic_networks'>;

interface IVlanInterfaceDataPartialConfig extends TVlanInterfacePartial {
    vnic_networks: RepeatedMessageItem<VNICNetworksConfigItem>;
}

/**
 * @description
 *  VlanInterface config item.
 * @author Vinay Jadhav
 */

export class VlanInterfaceConfigItem
    extends withFullModalMixin(MessageItem)<IVlanInterfaceDataPartialConfig> {
    public static ngDependencies = [
        'l10nService',
    ];

    public parentInterfaceName: string;
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: VlanInterface,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.vlanInterfaceNetworkBreadcrumbTitle);
    }

    /**
     * Import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { VlanInterfaceModalComponent } = await import(
            /* webpackChunkName: "service-engine-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/service-engine-modal/vlan-interface-grid/vlan-interface-modal/vlan-interface-modal.component'
        );

        return VlanInterfaceModalComponent as Type<Component>;
    }
}

