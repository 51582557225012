/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';

import './vs-log-cinematic-section-column.directive.less';

/**
 * @description
 *     Direcitive used to set styles for the average-width columns in VS log C-view sections.
 *     The left divider of a column can be removed as needed.
 * @author Zhiqian Liu
 */
@Directive({
    selector: '[vsLogCinematicSectionColumn]',
})
export class VsLogCinematicSectionColumnDirective implements OnInit {
    /**
     * Add a left border as a column divider if set to true or remove it otherwise.
     */
    @Input()
    public withDivider?: boolean = true;

    /**
     * Add a top padding for the content, usually needed when without a column title.
     */
    @Input()
    public withContentTopPadding?: boolean = false;

    /**
     * Takes up two column width if true.
     */
    @Input()
    public expandTwoColumns?: boolean = false;

    /**
     * The native HTML element that the directive is bound to.
     */
    private domElement: HTMLElement;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
    ) {
        this.domElement = this.elementRef.nativeElement;
        this.renderer.addClass(this.domElement, 'vs-log-cinematic-section-column');
    }

    /** @override */
    public ngOnInit(): void {
        if (this.withDivider) {
            this.renderer.addClass(
                this.domElement,
                'vs-log-cinematic-section-column--with-divider',
            );
        }

        if (this.expandTwoColumns) {
            this.renderer.addClass(this.domElement, 'vs-log-cinematic-section-column--expand-two');
        }

        if (this.withContentTopPadding) {
            this.renderer.addClass(
                this.domElement,
                'vs-log-cinematic-section-column--with-content-top-padding',
            );
        }
    }
}
