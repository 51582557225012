<credentials-verification-dialog
    [disableSubmit]="form.invalid"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="errors"
    [busy]="busy"
>
    <credentials-verification-dialog_header>
        <h3>{{ l10nKeys.setInfobloxCredentialsHeader | vtranslate }}</h3>
    </credentials-verification-dialog_header>
    <credentials-verification-dialog_form>
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
        >
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="ip_address"
                    for="ip_address"
                    [required]="!config.ip6_address"
                >
                    {{ l10nKeys.ipv4AddressOrFqdnLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="ip_address"
                    placeholder="{{ l10nKeys.ipv4AddressOrFqdnPlaceholder | vtranslate }}"
                    name="ip_address"
                    [(ngModel)]="config.ip_address"
                    regexPattern="hostname"
                    [required]="!config.ip6_address"
                />
            </avi-input-container>
            <avi-input-container>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="ip6_address"
                    for="ip6_address"
                    [required]="!config.ip_address"
                >
                    {{ globalL10nKeys.ipv6AddressInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="ip6_address"
                    placeholder="{{ globalL10nKeys.ipv6AddressPlaceholderLabel | vtranslate }}"
                    name="ip6_address"
                    [(ngModel)]="config.ip6_address"
                    regexPattern="ipv6"
                    [required]="!config.ip_address"
                />
            </avi-input-container>
            <avi-input-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="network_view"
                    for="network_view"
                >
                    {{ l10nKeys.networkViewInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="network_view"
                    placeholder="{{ l10nKeys.networkViewInputPlaceholder | vtranslate }}"
                    name="network_view"
                    [(ngModel)]="config.network_view"
                />
            </avi-input-container>
            <avi-input-container *ngIf="type === ipamDnsTypes.IPAMDNS_TYPE_INFOBLOX_DNS">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="dns_view"
                    for="dns_view"
                >
                    {{ l10nKeys.dnsViewInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    id="dns_view"
                    placeholder="{{ l10nKeys.dnsViewInputPlaceholder | vtranslate }}"
                    name="dns_view"
                    [(ngModel)]="config.dns_view"
                />
            </avi-input-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="username"
                            for="username"
                            required
                        >
                            {{ globalL10nKeys.usernameLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="username"
                            placeholder="{{ globalL10nKeys.usernamePlaceholder | vtranslate }}"
                            name="username"
                            [(ngModel)]="config.username"
                            required
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType }}"
                                fieldName="password"
                                for="password"
                                required
                            >
                                {{ globalL10nKeys.passwordLabel | vtranslate }}
                            </label>
                            <input
                                clrInput
                                id="password"
                                type="password"
                                placeholder="{{ globalL10nKeys.passwordPlaceholder | vtranslate }}"
                                name="password"
                                [(ngModel)]="config.password"
                                required
                            />
                        </avi-input-container>
                    </div>
                </div>
            </div>
        </form>
    </credentials-verification-dialog_form>
</credentials-verification-dialog>
