/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NotificationsModule
 */

import { pluck } from 'underscore';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ControllerFault } from 'items/controller-fault.item.factory';

/**
 * @description Collection of Controller Faults.
 * @author Alex Tseung, Alex Malitsky, Aravindh Nagarajan
 */
export class ControllerFaultCollection extends Collection {
    public static ngDependencies = [ControllerFault];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'controller-inventory',
            allDataSources: {
                list: {
                    source: 'ControllerFaultCollDataSource',
                    transformer: 'ControllerFaultDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config', 'faults'],
                },
            },
            defaultDataFields: ['config', 'faults'],
            isStatic: false,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getNgDependency(ControllerFault);
    }

    /**
     * Return a list of all fault descriptions.
     * @return Array of fault descriptions.
     */
    public get faultDescriptions(): string[] {
        const controllerFaults = this.itemList as ControllerFault[];

        return controllerFaults.reduce((faultsList: string[], item: ControllerFault) => {
            const { faults: itemFaults } = item.getConfig();
            const descriptions = pluck(itemFaults, 'description');

            faultsList.push(...descriptions);

            return faultsList;
        }, []);
    }
}

export type TControllerFaultCollection = typeof ControllerFaultCollection;
