<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.sslTlsCertificatesHeader | vtranslate }}"></avi-page-header>
        <ng-container *ngIf="errors">
            <avi-alert-group alertType="danger">
                {{ errors }}
            </avi-alert-group>
        </ng-container>
        <ng-container *ngIf="successAlert">
            <avi-alert-group alertType="success">
                {{ successAlert }}
            </avi-alert-group>
        </ng-container>
        <avi-collection-data-grid [config]="sslCertificateListGridConfig"></avi-collection-data-grid>

        <div class="ssl-certificate-list__ssl-auth-grid">
            <h2>{{ l10nKeys.rootIntermediateCaHeader | vtranslate }}</h2>
            <avi-collection-data-grid [config]="sslAuthCertificateGridConfig"></avi-collection-data-grid>
        </div>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #statusFieldTemplateRef
    let-row
>
    <ssl-certificate-grid-status-column [certificate]="row"></ssl-certificate-grid-status-column>
</ng-template>

<ng-template
    #nameFieldTemplateRef
    let-row
>
    <ssl-certificate-grid-name-column [certificate]="row"></ssl-certificate-grid-name-column>
</ng-template>

<ng-template
    #expandedContainerTemplateRef
    let-row
>
    <ssl-certificate-expander [certificate]="row"></ssl-certificate-expander>
</ng-template>
