<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.editStringGroupModalHeader : l10nKeys.newStringGroupModalHeader) | vtranslate }}"
    [item]="editable"
    [form]="form"
    class="string-group-modal"
>
    <form 
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        [readonly]="editable.isProtected()"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputLabel | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="type"
                            for="type"
                            required
                        >
                            {{ l10nKeys.typeLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="type"
                            [options]="stringGroupTypeDropdownOptions"
                            [(ngModel)]="stringGroupType"
                            (ngModelChange)="onViewTypeChange()"
                            [hideSearch]="true"
                            placeholder="{{ l10nKeys.typeLabel | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
               
                <div class="full-modal-config__form-control">
                    <clr-checkbox-container>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                id="longest-match-checkbox"
                                [(ngModel)]="editable.config.longest_match"
                                name="longest-match"
                            />
                            <label
                                objectType="{{ objectType }}"
                                fieldName="longest_match"
                                for="longest-match-checkbox"
                            >
                                {{ l10nKeys.longestMatch | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>             
                </div>
                
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label aviLabelWithTooltip>
                            {{ importFieldLabel }}
                        </label>
                        <file-upload
                            [(ngModel)]="stringGroupFileContent"
                            [(fileName)]="fileName"
                            (ngModelChange)="importFile()"
                            buttonLabel="{{ l10nKeys.browseBtnLabel | vtranslate }}"
                            [maxFileSize]="10"
                            name="string-group-file-data"
                        ></file-upload>
                    </div>
                </div>

                <div 
                    class="full-modal-config__form-control-row"
                    *ngIf="editable.getConfig().type === stringGroupTypesEnumHash.SG_TYPE_KEYVAL"
                >
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.keyValueTypeLabel | vtranslate : editable.getConfig().kv.count }}
                        </h6>
                        <avi-repeated-key-values-grid
                            name="string-group-type-Key-value"
                            [keyValueRepeatedMessageItem]="this.editable.config.kv"
                            [allowDuplicates]="false"
                        ></avi-repeated-key-values-grid>   
                    </div>
                </div>

                <div 
                    class="full-modal-config__form-control-row"
                    *ngIf="editable.getConfig().type === stringGroupTypesEnumHash.SG_TYPE_STRING"
                >
                    <div class="full-modal-config__form-control">
                        <h6>
                            {{ l10nKeys.stringTypeLabel | vtranslate : editable.getConfig().kv.count }}
                        </h6>
                        <avi-repeated-strings-grid
                            name="string-group-type-string"
                            placeholder="{{ l10nKeys.stringTypePlaceHolder | vtranslate }}"
                            columnLabel="String"
                            [(ngModel)]="stringsArray"
                            (ngModelChange)="updateStringsConfigList()"
                            [allowDuplicates]="false"
                        ></avi-repeated-strings-grid>
                    </div>
                </div>
            </div>
        </full-modal-tab-section>
    </form>
</item-full-modal>
