/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { VsLogsStore } from 'ng/modules/vs-logs/services/vs-logs.store';
import { FilterOperatorType } from 'ng/modules/vs-logs/vs-logs.types';

import './vs-log-filter-list.component.less';

/**
 * @description
 *      Component to render a clickable filters list and add it to store upon click.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: '[vsLogFilterList]',
    templateUrl: 'vs-log-filter-list.component.html',
})
export class VsLogFilterListComponent implements OnInit {
    /**
     * Property, operator, and value for a filter object.
     * See TFilterObj for reference.
     */
    @Input()
    public property: string;

    /**
     * List of operators that user can select.
     */
    @Input()
    public operators: FilterOperatorType[] = [
        FilterOperatorType.EQUAL_TO,
        FilterOperatorType.GREATER_THAN,
        FilterOperatorType.GREATER_THAN_OR_EQUAL_TO,
        FilterOperatorType.LESS_THAN,
        FilterOperatorType.LESS_THAN_OR_EQUAL_TO,
        FilterOperatorType.NOT_EQUAL_TO,
    ];

    /**
     * Value for setting limit of a filter.
     */
    @Input()
    public value: number;

    /**
     * Value displayed in list of filters.
     */
    @Input()
    public displayValue: string | number;

    /**
     * Event emitter for when filter is added.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    /**
     * Child content for which logs filter list is applied.
     */
    @ViewChild('vsLogFilterContent')
    public readonly vsLogFilterContent: ElementRef<HTMLElement>;

    /**
     * Avi Tooltip holding all the available list of filters.
     */
    @ViewChild('aviTooltipContent')
    public readonly aviTooltipContent: ElementRef<HTMLElement>;

    /**
     * Position for overlay filter.
     */
    public positionsPriority: ConnectedPosition[] = [{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetX: 10,
    }];

    public operatorsSymbolsHash = {
        [FilterOperatorType.EQUAL_TO]: '=',
        [FilterOperatorType.GREATER_THAN]: '>',
        [FilterOperatorType.GREATER_THAN_OR_EQUAL_TO]: '>=',
        [FilterOperatorType.LESS_THAN]: '<',
        [FilterOperatorType.LESS_THAN_OR_EQUAL_TO]: '<=',
        [FilterOperatorType.NOT_EQUAL_TO]: '!=',
    };

    /**
     * Subject to control the hide/show column tooltip.
     */
    public showOperatorsSubject = new Subject<boolean>();

    /**
     * A NodeJS.Timeout object for overlay to dismiss.
     */
    private operatorsListTimeout: ReturnType<typeof setTimeout>;

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        renderer.addClass(elementRef.nativeElement, 'vs-log-filter-list');
    }

    /**
     * Set display value and show the list of filters on mouse enter.
     */
    @HostListener('mouseenter')
    public onMouseEnter(): void {
        this.setDisplayValue();
    }

    /**
     * Close filters list on mouseleave.
     */
    @HostListener('mouseleave')
    public onMouseLeave(): void {
        this.closeFiltersList();
    }

    /**
     * Add filter to logs store on selecting filter from list.
     */
    @HostListener('click')
    public selectOperator(event: MouseEvent): void {
        const target = event?.target as HTMLElement;
        const { operatorType = 'EQUAL_TO' } = target?.dataset ?? {};

        this.vsLogsStore.addFilter({
            property: this.property,
            operator: FilterOperatorType[operatorType],
            value: this.value,
        });

        this.closeFiltersList();
        this.onFilterAdd.emit();
    }

    /**
     * @override
     * Typecast class property 'value' to number if it is numeric string.
     */
    public ngOnInit(): void {
        if (typeof this.value !== 'number') {
            const numericValue = Number(this.value);
            const isValueNaN = Number.isNaN(numericValue);

            if (!isValueNaN) {
                this.value = numericValue;
            }
        }
    }

    /**
     * Clear timeout on mouse hovered over filters list, for user to select a filter.
     */
    public onOperatorsListMouseEnter(): void {
        clearTimeout(this.operatorsListTimeout);
    }

    /**
     * Dismiss overlay when mouse leave the overlay.
     */
    public onOperatorsListMouseLeave(): void {
        this.closeFiltersList();
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Close filters list overlay, with time delay specified.
     */
    private closeFiltersList(delay = 0): void {
        clearTimeout(this.operatorsListTimeout);

        this.operatorsListTimeout = setTimeout(() => {
            this.showOperatorsSubject.next(false);
        }, delay);
    }

    /**
     * Set display value from content projected, if not provided.
     */
    private setDisplayValue(): void {
        this.displayValue = this.displayValue ??
            this.vsLogFilterContent.nativeElement.parentElement.innerText;
    }
}
