<div
    class="full-modal-config__section"
    provideParentForm
>
    <h3>{{ l10nKeys.sectionTitleDns | vtranslate }}</h3>
    <div class="dns-health-monitor-config__sub-section-header">
        <h6>{{ l10nKeys.requestLabel | vtranslate }}</h6>
    </div>
    <div class="full-modal-config__form-control-row dns-health-monitor-config__no-margin-top">
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorDNS }}"
                    fieldName="query_name"
                    required
                    for="query-name"
                >
                    {{ l10nKeys.requestNameInputLabel | vtranslate }}
                </label>
                <input
                    id="query-name"
                    name="query-name"
                    class="sel-dns-name"
                    clrInput
                    required
                    regexPattern="hostname"
                    placeholder="{{ l10nKeys.requestNameInputPlaceholder | vtranslate }}"
                    [(ngModel)]="dnsMonitor.config.query_name"
                />
            </avi-input-container>
        </div>
        <div class="full-modal-config__form-control">
            <avi-input-container noMarginTop>
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    for="monitor-port"
                    [required]="editable.config.is_federated"
                >
                    {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
                </label>
                <input
                    clrInput
                    type="number"
                    id="monitor-port"
                    name="monitor-port"
                    [(ngModel)]="editable.config.monitor_port"
                    configFieldInputValidation
                    objectType="{{ objectTypes.HealthMonitor }}"
                    fieldName="monitor_port"
                    placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
                    [disabled]="!!editable.id"
                    [required]="editable.config.is_federated"
                />
            </avi-input-container>
        </div>
    </div>
    <div class="dns-health-monitor-config__sub-section-header">
        <h6>{{ l10nKeys.responseLabel | vtranslate }}</h6>
    </div>
    <enum-radio-buttons
        noMarginTop
        enum="HealthMonitorDNSQueryType"
        name="health-monitor-dns-qtype"
        [(ngModel)]="dnsMonitor.config.qtype"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorDNS }}"
            fieldName="qtype"
        >
            {{ l10nKeys.responseMatchesInputLabel | vtranslate }}
        </label>
    </enum-radio-buttons>
    <enum-radio-buttons
        enum="HealthMonitorDNSRcode"
        name="health-monitor-dns-rcode"
        [(ngModel)]="dnsMonitor.config.rcode"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorDNS }}"
            fieldName="rcode"
        >
            {{ l10nKeys.responseCodeInputLabel | vtranslate }}
        </label>
    </enum-radio-buttons>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorDNS }}"
            fieldName="response_string"
            for="response-string"
        >
            {{ l10nKeys.responseStringInputLabel | vtranslate }}
        </label>
        <input
            id="response-string"
            name="response-string"
            class="sel-dns-response"
            clrInput
            [regexPattern]="dnsMonitor.config.record_type === 'DNS_RECORD_AAAA' ? 'ipv6': 'ip'"
            placeholder="{{ l10nKeys.responseStringInputPlaceholder | vtranslate }}"
            [(ngModel)]="dnsMonitor.config.response_string"
        />
    </avi-input-container>
    <enum-radio-buttons
        enum="DnsRecordType"
        name="health-monitor-dns-record-type"
        [hiddenEnumValues]="hiddenDnsRecordTypes"
        [(ngModel)]="dnsMonitor.config.record_type"
        required
        (ngModelChange)="handleRecordTypeChange()"
    >
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitorDNS }}"
            fieldName="record_type"
            required
        >
            {{ l10nKeys.recordTypeInputLabel | vtranslate }}
        </label>
    </enum-radio-buttons>
</div>
