/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';

import {
    combineLatest,
    forkJoin,
    Observable,
    Subject,
    Subscription,
} from 'rxjs';

import {
    skip,
    take,
    tap,
} from 'rxjs/operators';

import { L10nService } from '@vmw/ngx-vip';
import { IControllerProperties } from 'generated-types';
import { LANGUAGES } from 'ajs/js/constants/my-account.constant';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import * as UserAccount from 'ng/root-store/user-account';
import * as UserPreferences from 'ng/root-store/user-preferences';
import { UserAccountService } from 'ng/root-store/user-account/user-account.service';
import { UserPreferencesService } from 'ng/root-store/user-preferences/user-preferences.service';
import { MyAccountModalService } from './my-account-modal.service';
import { MyAccountModalStore } from './my-account-modal.store';
import './my-account-modal.component.less';
import * as l10n from './my-account-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for My Account modal. Only controllerProperties require 'PERMISSION_CONTROLLER' for
 *     write permissions. userAccount and userPreferences are 'PERMISSION_EXEMPT'.
 * @author Nisar Nadaf, alextsg
 */
@Component({
    providers: [
        MyAccountModalService,
        MyAccountModalStore,
    ],
    selector: 'my-account-modal',
    templateUrl: './my-account-modal.component.html',
})
export class MyAccountModalComponent implements OnInit, OnDestroy {
    /**
     * Observable used to display the User Account full name in the breadcrumb description.
     */
    @Input()
    public description$: Subject<string>;

    /**
     * Called to dismiss the modal.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Template ref form element.
     */
    @ViewChild('form')
    public form: NgForm;

    /**
     * Layout for My Account clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Languages dropdown options.
     */
    public languageOptions = LANGUAGES;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Used to show error messages if any.
     */
    public errors: string;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Controller properties fields for user to modify.
     */
    public controllerProperties: IControllerProperties = {};

    /**
     * User account fields for user to modify.
     */
    public accountData: UserAccount.IUserAccountPayload = {};

    /**
     * uiProperty preferences for user to modify.
     */
    public uiProperty: UserPreferences.IUIProperty = {};

    /**
     * Busy state of the myAccountModal store.
     */
    public busy$ = this.myAccountModalStore.busy$;

    /**
     * Reference to the controllerProperties subscription from the myAccountModal store. Used to
     * unsubscribe onDestroy.
     */
    private controllerPropertiesSubscription: Subscription;

    constructor(
        l10nService: L10nService,
        private readonly authService: Auth,
        @Inject('systemConfigService')
        private readonly systemConfigService: any,
        private readonly store: Store,
        private readonly userAccountService: UserAccountService,
        private readonly userPreferencesService: UserPreferencesService,
        private readonly myAccountModalService: MyAccountModalService,
        private readonly myAccountModalStore: MyAccountModalStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        combineLatest([
            this.store.select(UserAccount.selectUserAccount),
            this.store.select(UserPreferences.selectUIProperty),
        ])
            .pipe(take(1))
            .subscribe(([userAccount, uiProperty]) => {
                this.accountData = { ...userAccount };
                this.uiProperty = { ...uiProperty };

                this.description$.next(this.accountData.full_name);
            });

        this.controllerPropertiesSubscription = this.myAccountModalStore.controllerProperties$
            .pipe(skip(1))
            .subscribe(controllerProperties => {
                this.controllerProperties = { ...controllerProperties };
            });

        this.myAccountModalStore.fetchControllerProperties();

        /**
         *  Load systemConfiguration only when current
         *  user has 'PERMISSION_SYSTEMCONFIGURATION' access.
         */
        if (this.authService.isAllowed('systemconfiguration')) {
            this.systemConfigService.load();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.controllerPropertiesSubscription.unsubscribe();
    }

    /**
     * Called when user changes the full name field. Updates the breadcrumb description.
     */
    public handleNameChange(): void {
        this.description$.next(this.accountData.full_name);
    }

    /**
     * Getter function for Controller properties data.
     */
    public get hasControllerProperties(): boolean {
        return this.controllerProperties !== undefined;
    }

    /**
     * Getter function for password strength check field from systemConfiguration.
     */
    public get passwordStrengthCheckEnabled(): boolean {
        const { portal_configuration: portalConfig } = this.systemConfigService.getConfig() || {};

        return Boolean(portalConfig?.password_strength_check);
    }

    /**
     * Getter function for password minimum length field from systemConfiguration.
     */
    public get minimumPasswordLength(): number | undefined {
        const { portal_configuration: portalConfig } = this.systemConfigService.getConfig() || {};

        return portalConfig?.minimum_password_length;
    }

    /**
     * Compares password and confirmPassword values and
     * invalidate confirm password field if they do not match.
     */
    public comparePasswords(): void {
        if (this.accountData.password !== this.accountData.confirm_password) {
            this.form.controls.confirm_password.setErrors({ incorrect: true });
        } else {
            this.form.controls.confirm_password.setErrors(null);
        }
    }

    /**
     * Called on cancel button click.
     * Discard changes and close modal.
     */
    public dismiss(): void {
        this.onClose.emit();
    }

    /**
     * Method to Save user preferences and account data.
     */
    public submit(): void {
        this.busy = true;
        this.errors = '';

        forkJoin({
            userAccount: this.saveUserAccount(),
            userPreferences: this.saveUserPreferences(),
            controllerProperties: this.saveControllerProperties(),
        }).subscribe(
            () => {
                this.busy = false;
                this.dismiss();
            },
            errors => {
                this.busy = false;
                this.errors = errors;
            },
        );
    }

    /**
     * Save user account and update the store with the new user account configuration. The response
     * to the save request does not contain the updated user account object, so we have to use the
     * payload object to update the store. The password-related fields are cleared before updating
     * the store state.
     */
    private saveUserAccount(): Observable<UserAccount.IUserAccountPayload> {
        return this.userAccountService.saveUserAccount(this.accountData).pipe(
            tap(_ => {
                this.store.dispatch(UserAccount.saveUserAccountSuccess({
                    payload: {
                        ...this.accountData,
                        confirm_password: undefined,
                        old_password: undefined,
                        password: undefined,
                    },
                }));
            }),
        );
    }

    /**
     * Save user preferences and update the store with the new configuration sent in the payload.
     * The response to the save request does not contain the updated user preferences object, so we
     * have to use the payload object to update the store.
     */
    private saveUserPreferences(): Observable<UserPreferences.IUIProperty> {
        return this.userPreferencesService.saveUIProperty(this.uiProperty).pipe(
            tap(_ => {
                this.store.dispatch(UserPreferences.saveUserPreferencesSuccess({
                    payload: {
                        ui_property: this.uiProperty,
                    },
                }));
            }),
        );
    }

    /**
     * Save controller properties and update store.
     */
    private saveControllerProperties(): Observable<IControllerProperties> {
        return this.myAccountModalService.saveControllerProperties(this.controllerProperties).pipe(
            tap(controllerPreferences => {
                this.myAccountModalStore.updateControllerProperties(controllerPreferences);
            }),
        );
    }
}
