<div class="vs-logs-signpost-custom-waf-rule">
    <ng-content></ng-content>

    <avi-dynamic-data-grid
        *ngIf="vsLogsSignpostStore.vsLogsWafRuleSignpostData$ | async as vsLogsWafRuleSignpostData"
        class="vs-logs-signpost-custom-waf-rule__wrapper"
        [config]="logListGridConfig"
        [rows]="vsLogsWafRuleSignpostData"
        [rowsTotal]="vsLogsWafRuleSignpostData.length"
        [isLoading]="vsLogsSignpostStore.isLoading$ | async"
    ></avi-dynamic-data-grid>
</div>

<div
    class="vs-logs-signpost-custom-waf-rule__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>

<ng-template
    #nameFieldTemplateRef
    let-row
    let-index="index"
>
    <div
        *ngIf="vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async as configKey"
        class="vs-logs-signpost-custom-waf-rule__content"
    >
        <ng-container *ngFor="let value of row.values; trackBy: trackByIndex">
            <span
                *ngIf="value.isValid"
                class="vs-logs-signpost-custom-waf-rule__content-separator"
                vsLogFilter
                [property]="value.param"
                [value]="value.value"
                (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
            >
                {{ value.value | signpostLogDataTransform: configKey }}
            </span>
            <span
                *ngIf="!value.isValid"
                vsLogFilterableEmptyField
                [property]="value.param"
                (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
            ></span>
            </ng-container>
    </div>
</ng-template>

<ng-template
    #progressBarFieldTemplateRef
    let-row
    let-index="index"
>
    <avi-progress-bar
        [percentage]="row.percentage"
        [hidePercentage]="true"
    ></avi-progress-bar>
</ng-template>
