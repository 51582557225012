<div class="vs-logs-signpost-custom-waf-latency">
    <ng-content></ng-content>

    <ng-container *ngIf="vsLogsSignpostStore.vsLogsWafLatencySignpostData$ | async as wafLatencyPhasesData">
        <table
            class="vs-logs-signpost-custom-waf-latency__table"
            *ngIf="(wafLatencyPhasesData | keyvalue)?.length === wafLatencyPhases.length"
        >
            <thead class="vs-logs-signpost-custom-waf-latency__table-head">
                <tr>
                    <th class="vs-logs-signpost-custom-waf-latency__table-head-phase"></th>
                    <th> {{ l10nKeys.latencyLabel | vtranslate}} </th>
                    <th> {{ l10nKeys.logsNumberLabel | vtranslate}} </th>
                </tr>
            </thead>
            <tbody
                class="vs-logs-signpost-custom-waf-latency__table-body"
                *ngFor="let wafLatencyPhase of wafLatencyPhases; trackBy: trackbyIndex"
            >
                <tr *ngFor="let row of wafLatencyPhasesData[wafLatencyPhase]; index as i; trackBy: trackbyIndex">
                    <td
                        class="vs-logs-signpost-custom-waf-latency__table-phase-cell"
                        rowspan="10"
                        *ngIf="i === 0"
                    >
                        <div class="vs-logs-signpost-custom-waf-latency__table-phase-label">
                            {{ row.phase }}
                        </div>
                    </td>
                    <td class="vs-logs-signpost-custom-waf-latency__table-cell">
                        <span
                            *ngIf="row.isValid && row.value !== 'NO_DATA'"
                            vsLogFilterRange
                            [property]="row.param"
                            [start]="row.value.start"
                            [end]="row.value.end"
                            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
                        >
                            {{ row.value | signpostLogDataTransform: (vsLogsSignpostStore.vsLogSelectedSignpostConfigKey$ | async) }}
                        </span>
                        <span
                            *ngIf="row.isValid && row.value === 'NO_DATA'"
                            vsLogFilter
                            [property]="row.param"
                            [value]="row.value"
                            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
                        >
                            {{ row.value }}
                        </span>
                        <span
                            *ngIf="!row.isValid"
                            vsLogFilterableEmptyField
                            [property]="row.param"
                            (onFilterAdd)="vsLogsSignpostStore.closeSignpost()"
                        ></span>
                    </td>
                    <td class="vs-logs-signpost-custom-waf-latency__table-cell">
                        <div class="vs-logs-signpost-custom-waf-latency__progress-container">
                            <div class="vs-logs-signpost-custom-waf-latency__progress">
                                <avi-progress-bar
                                    [percentage]="row.percentage"
                                    [hidePercentage]="true"
                                ></avi-progress-bar>
                            </div>
                            <div> {{ row.count }} </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>
</div>
<div
    class="vs-logs-signpost-custom-waf-latency__loader clr-wrapper"
    *ngIf="vsLogsSignpostStore.isLoading$ | async"
>
    <clr-spinner [clrMedium]="true"></clr-spinner>
</div>
