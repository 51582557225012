<div class="password-change clr-wrapper">
    <login-container>
        <avi-alert-group *ngIf="errorMessage" alertType="danger">
            <span> {{ errorMessage }} </span>
        </avi-alert-group>
        <div *ngIf="passwordChanged">
            <avi-alert-group alertType="success">
                <span> {{ l10nKeys.passwordChangeSuccessMessage | vtranslate }} </span>
            </avi-alert-group>
        </div>
        <h6>{{ l10nKeys.changePasswordLabel | vtranslate }}</h6>
        <form #passwordResetForm="ngForm">
            <div class="login-group">
                <avi-input-container class="password-change__input-container">
                    <input
                        type="password"
                        clrInput
                        placeholder="{{ l10nKeys.newPasswordPlaceholder | vtranslate }}"
                        [(ngModel)]="newPassword"
                        name="new-password"
                        required
                    />
                </avi-input-container>
                <avi-input-container class="password-change__input-container">
                    <input
                        type="password"
                        clrInput
                        placeholder="{{ l10nKeys.confirmPasswordPlaceholder | vtranslate }}"
                        [(ngModel)]="confirmPassword"
                        name="confirm-password"
                        required
                    />
                </avi-input-container>
                <div
                    button-loader
                    [isLoading]="isBusy"
                >
                    <button
                        type="submit"
                        class="btn btn-primary"
                        (click)="changePassword()"
                        [disabled]="isPasswordChangeDisabled"
                    >
                        {{ l10nKeys.changePasswordLabel | vtranslate }}
                    </button>
                </div>
            </div>
        </form>
    </login-container>
</div>
