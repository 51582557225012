/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-subdomains-list-page';

const prefix = `${moduleName}.${componentName}`;

export const subdomainsLabel = `${prefix}.subdomainsLabel`;
export const listViewLabel = `${prefix}.listViewLabel`;
export const treeViewLabel = `${prefix}.treeViewLabel`;
export const subdomainLabel = `${prefix}.subdomainLabel`;
export const gslbSitesLabel = `${prefix}.gslbSitesLabel`;
export const virtualServicesLabel = `${prefix}.virtualServicesLabel`;
export const deleteSubdomainHeader = `${prefix}.deleteSubdomainHeader`;

export const ENGLISH = {
    [subdomainsLabel]: 'Subdomains',
    [listViewLabel]: 'List',
    [treeViewLabel]: 'Tree',
    [subdomainLabel]: 'SUBDOMAIN ({0})',
    [gslbSitesLabel]: 'GSLB SITES ({0})',
    [virtualServicesLabel]: 'VIRTUAL SERVICES ({0})',
    [deleteSubdomainHeader]: 'Delete Subdomain(s)',
};
