/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import { skip, takeWhile } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';

import { INodeVitals, NodeVitalsWidgetStore } from './node-vitals-widget.store';
import * as l10n from './node-vitals-widget.l10n';
import * as moduleL10n from '../../administration-dashboard.l10n';
import './node-vitals-widget.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH: moduleDictionary, ...moduleL10nKeys } = moduleL10n;

/**
 * @description
 * Widget displaying Node Vitals information. Contains node name, status, disk usage, memory usage,
 * and CPU usage.
 * @author alextsg
 */
@Component({
    selector: 'node-vitals-widget',
    templateUrl: './node-vitals-widget.component.html',
    providers: [NodeVitalsWidgetStore],
})
export class NodeVitalsWidgetComponent {
    /**
     * Passed from DashboardWidgetsComponent. Call to redraw the dashboard layout once the widget
     * is done loading data.
     */
    @Output()
    public redrawLayout = new EventEmitter();

    public readonly loading$ = this.nodeVitalsWidgetStore.loading$;

    public readonly nodeVitalsList$ = this.nodeVitalsWidgetStore.nodeVitalsList$;

    /**
     * True if widget is loading for the first time. On initial load the loading message is
     * displayed for the widget, while subsequent loads just show a progress bar.
     */
    public initialLoading = false;

    public readonly l10nKeys = l10nKeys;

    public readonly moduleL10nKeys = moduleL10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly nodeVitalsWidgetStore: NodeVitalsWidgetStore,
    ) {
        l10nService.registerSourceBundles({
            ...dictionary,
            ...moduleDictionary,
        });
    }

    /**
     * @override
     * Fetch node vitals data and subscribe to nodeVitalsWidgetStore.loading$ in order to track the
     * initial load.
     */
    public ngOnInit(): void {
        this.nodeVitalsWidgetStore.loading$
            .pipe(
                // Skip the initial value of loading, which is false, so that any subsequent false
                // will be after a true value.
                skip(1),
                takeWhile((loading: boolean) => {
                    if (loading && !this.initialLoading) {
                        this.initialLoading = true;
                    }

                    if (!loading && this.initialLoading) {
                        this.initialLoading = false;
                        this.redrawLayout.emit();

                        return false;
                    }

                    return true;
                }),
            )
            .subscribe();

        this.nodeVitalsWidgetStore.fetchNodeVitalsData();
    }

    /**
     * @override
     * Cancel any ongoing requests for node vitals data.
     */
    public ngOnDestroy(): void {
        this.nodeVitalsWidgetStore.cancelFetchNodeVitalsData();
    }

    /**
     * trackBy function for the nodeVitals list that tracks the name of each node which is unique.
     */
    public trackByNodeName(index: number, nodeVitals: INodeVitals): string {
        return nodeVitals.name;
    }
}
