/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/components/log-timeline.less';
import {
    time as d3Time,
    scale as d3Scale,
} from 'd3v3';

const d3 = {
    time: d3Time,
    scale: d3Scale,
};

/**
 * @ngdoc directive
 * @name logTimeline
 * @param {boolean} isHttpVs
 * @param {boolean} isFastPathOnly
 * @param {Object} record - Log entry.
 * @param {Object} timeline
 * @param {number?} zIndex
 *
 *     Shows logs timings as line of different colors.
 **/
//timeline of each log event in the right column of log results table @am
angular.module('avi/logs').directive('logTimeline', ['$filter', '$templateCache',
function($filter, $templateCache) {
    const
        l4timings = [
            {
                name: 'client_rtt',
                className: 'c-rtt',
                header: 'Client RTT',
            },
            {
                name: 'server_rtt',
                className: 's-rtt',
                header: 'Server RTT',
            },
            {
                name: 'data_transfer_time',
                className: 'data-tr',
                header: false,
            },
        ],
        httpTimings = [
            {
                name: 'client_rtt',
                className: 'c-rtt',
                header: 'Client RTT',
            },
            {
                name: 'server_rtt',
                className: 's-rtt',
                header: 'Server RTT',
            },
            {
                name: 'waf_log_time',
                className: 'waf-tt',
                header: 'WAF',
            },
            {
                name: 'app_response_time',
                className: 'app-resp',
                header: 'App Response',
            },
            {
                name: 'data_transfer_time',
                className: 'data-tr',
                header: 'Data Transfer',
            },
        ];

    const getTimingCards = isHTTPVS => {
        return isHTTPVS ? httpTimings : l4timings;
    };

    const logTimelineLink = function(scope, elem) {
        const remove = function() {
            $('div.log-timeline-popover').remove();
        };

        const totalDuration = scope.record.total_time;
        const innerWidth = d3.scale.linear().domain([0, totalDuration]).range([0, 100]);
        const outWidthDuration = d3.scale.linear()
            .domain([0, scope.timeline.maxDuration])
            .range([0, 100]);

        const outWidthTimeline = d3.scale.linear().range([0, 100]);

        const { start, stop } = scope.timeline;

        if (start && stop) {
            outWidthTimeline.domain([0, stop.diff(start)]);
        }

        //offset on timeline view
        const left = d3.time.scale()
            .domain([scope.timeline.minTimestamp, scope.timeline.maxTimestamp])
            .range([0, 99]);

        scope.totalDuration = $filter('ms2str')(totalDuration, true);

        const timings = getTimingCards(scope.isHTTPVS);

        scope.width = timings.map(({ name }) => innerWidth(scope.record[name]).toFixed(3));

        scope.$watch('timeline.active', val => {
            if (!val) {
                scope.wrap = {
                    left: 0,
                    width: outWidthDuration(totalDuration).toFixed(3),
                };
            } else {
                scope.wrap = {
                    left: left(scope.record.dt_start).toFixed(3),
                    width: outWidthTimeline(totalDuration).toFixed(3),
                };
            }
        });

        scope.formatedTimeData = [];

        if (!scope.isFastPathOnly) {
            scope.formatedTimeData = getTimingCards(scope.isHTTPVS)
                .filter(rowData => !!rowData.header);
        }

        scope.tooltipTemplate = require('./transfer-times-tooltip.partial.html');

        scope.$on('userLoggedOut', remove);
        scope.$on('$destroy', remove);
    };

    return {
        restrict: 'E',
        scope: {
            isHTTPVS: '<isHttpVs',
            isFastPathOnly: '<',
            timeline: '=',
            record: '=',
            zIndex: '<?',
        },
        link: logTimelineLink,
        template:
            `<div class="wrap"
                  tooltip2
                  tooltip2-template="tooltipTemplate"
                  tooltip2-attachment="top right"
                  tooltip2-target-attachment="bottom left"
                  tooltip2-constraints="[]"
                  tooltip2-offset="-5px -62px"
                  style="left:{{ wrap.left }}%; width:{{ wrap.width }}%">
                <div class="c-rtt"
                     ng-if="!isFastPathOnly"
                     style="width:{{ width[0] }}%">
                </div>
                <div class="s-rtt"
                     ng-if="!isFastPathOnly && width[1] >= 0"
                     style="width:{{ width[1] }}%">
                </div>
                <div class="waf-tt"
                     ng-if="width[2] >= 0"
                     style="width:{{ width[2] }}%">
                </div>
                <div ng-class="isHTTPVS ? 'app-resp' : 'total'"
                     ng-if="width[3] >= 0"
                     style="width:{{ width[3] }}%">
                </div>
                <div ng-if="isHTTPVS && width[4] >= 0"
                     class="data-tr"
                     style="width:{{ width[4] }}%">
                </div>
            </div>
            <div
                ng-if="!timeline.active"
                class="duration"
                style="left: {{ wrap.width }}%">
                {{ totalDuration }}
            </div>`,
    };
}]);
