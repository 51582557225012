/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'match-adder';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const noEntriesLabel = `${prefix}.noEntriesLabel`;
export const noAvailableMatchesLabel = `${prefix}.noAvailableMatchesLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [noEntriesLabel]: 'No entries have been added',
    [noAvailableMatchesLabel]: 'No available matches to add',
};
