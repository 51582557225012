/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { countryDict } from '../constants/country-dict.constants';

/**
 * @description
 *     Pipe for converting a country ISO code to the .png source of its national flag.
 *     If the code is in two-letter, convert it to three-letter code first.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'countryFlagImageSource',
})
export class CountryFlagImageSourcePipe implements PipeTransform {
    /** @override */
    // eslint-disable-next-line class-methods-use-this
    public transform(isoCode: string): string {
        if (!isoCode) {
            return '';
        }

        const normalizedCode = isoCode.length !== 2 ? countryDict.iso3to2[isoCode] : isoCode;

        return normalizedCode ?
            `src/views/worldmaps/flags/${normalizedCode.toLowerCase()}.png` :
            '';
    }
}
