<div provideParentForm>
    <avi-input-container helperText="{{ l10nKeys.dnsResolversHelperText | vtranslate }}">
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="server_list"
            for="dns-resolvers"
        >
            {{ l10nKeys.dnsResolversLabel | vtranslate }}
        </label>
        <avi-repeated-strings
            name="dns-resolvers"
            id="dns-resolvers"
            [placeholder]="l10nKeys.dnsResolversPlaceholder | vtranslate"
            [objectType]="objectType"
            fieldName="server_list"
            [ngModel]="dnsResolvers"
            (ngModelChange)="onDnsResolversUpdate($event)"
            regexPattern="anyIPHostnameList"
        ></avi-repeated-strings>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="search_domain"
            for="search-domain"
        >
            {{ l10nKeys.searchDomainLabel | vtranslate }}
        </label>
        <input
            clrInput
            id="search-domain"
            name="search-domain"
            type="text"
            [(ngModel)]="dnsConfig.search_domain"
            [placeholder]="l10nKeys.searchDomainPlaceholder | vtranslate"
            regexPattern="listOfHostnamesWithSpaces"
        />
    </avi-input-container>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-data-grid
                [config]="ntpAuthenticationKeysConfig"
                [rows]="ntpConfig.ntp_authentication_keys"
                [gridTitle]="l10nKeys.ntpAuthenticationKeysTitle | vtranslate : ntpConfig.ntp_authentication_keys.length"
            >
                <avi-data-grid_actions>
                    <cds-button
                        (click)="addNtpAuthenticationKey()"
                        size="sm"
                    >
                        {{ globalL10nKeys.addLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
            </avi-data-grid>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <avi-data-grid
                [config]="ntpServersConfig"
                [rows]="ntpConfig.ntp_servers"
                [gridTitle]="l10nKeys.ntpServersTitle | vtranslate : ntpConfig.ntp_servers.length"
            >
                <avi-data-grid_actions>
                    <cds-button
                        (click)="addNtpServer()"
                        size="sm"
                    >
                        {{ globalL10nKeys.addLabel | vtranslate }}
                    </cds-button>
                </avi-data-grid_actions>
            </avi-data-grid>
        </div>
    </div>

    <ng-template
        #ntpAuthKeyNumberTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                [name]="'ntp-auth-key-number-' + index"
                [id]="'ntp-auth-key-number-' + index"
                type="number"
                [placeholder]="l10nKeys.keyNumberPlaceholder | vtranslate"
                [(ngModel)]="row.key_number"
                required
            />
        </avi-input-container>
    </ng-template>

    <ng-template
        #algorithmTemplateRef
        let-row
        let-index="index"
    >
        <avi-enum-dropdown
            [name]="'algorithm-' + index"
            [id]="'algorithm-' + index"
            enum="NTPAuthAlgorithm"
            [placeholder]="l10nKeys.ntpAuthalgoritmPlaceholder | vtranslate"
            [(ngModel)]="row.algorithm"
        ></avi-enum-dropdown>
    </ng-template>

    <ng-template
        #keyTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                [name]="'ntp-auth-key-' + index"
                [id]="'ntp-auth-key-' + index"
                type="text"
                [placeholder]="globalL10nKeys.keyPlaceholder | vtranslate"
                [(ngModel)]="row.key"
                required
            />
        </avi-input-container>
    </ng-template>

    <ng-template
        #ntpServersKeyNumberTemplateRef
        let-row
        let-index="index"
    >
        <avi-dropdown
            [name]="'ntp-servers-key-number-' + index"
            [id]="'ntp-servers-key-number-' + index"
            [options]="allKeyNumbersDropdownOptions"
            [(ngModel)]="row.key_number"
            (optionsOpenedChange)="setAllKeyNumbersDropdownOptions()"
            [placeholder]="l10nKeys.keyNumberPlaceholder | vtranslate"
        ></avi-dropdown>
    </ng-template>

    <ng-template
        #serversTemplateRef
        let-row
        let-index="index"
    >
        <avi-input-container noMarginTop>
            <input
                clrInput
                [name]="'servers-' + index"
                [id]="'servers-' + index"
                type="text"
                [placeholder]="l10nKeys.ntpServersPlaceholder | vtranslate"
                [ngModel]="row.server?.addr"
                (ngModelChange)="onNtpServersUpdate($event, index)"
                regexPattern="anyIPIPPortHostname"
                required
            />
        </avi-input-container>
    </ng-template>
</div>
