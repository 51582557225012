<div
    provideParentForm
    class="full-modal-config__form-control-row"
>
    <div class="full-modal-config__form-control">
        <label
            aviLabelWithTooltip
            tooltipText="{{ l10nKeys.serviceAccountKeyFileDataTooltip | vtranslate }}"
            for="service_account_keyfile_data"
            required
        >
            {{ l10nKeys.serviceAccountKeyFileDataLabel | vtranslate }}
        </label>
        <file-upload-textarea
            [(ngModel)]="editable.config.service_account_keyfile_data"
            name="service_account_keyfile_data"
            placeholder="{{ l10nKeys.serviceAccountKeyFileDataPlaceholder | vtranslate }}"
            required
        ></file-upload-textarea>
    </div>
</div>
