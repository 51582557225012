<div
    class="full-modal-config__section http-health-monitor-config"
    provideParentForm
>
    <h3>{{ (editable.isHttpsType() ? globalL10nKeys.httpsLabel : globalL10nKeys.httpLabel) | vtranslate }}</h3>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>

    <health-monitor-authentication [editable]="editable"></health-monitor-authentication>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.clientRequestHeaderInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="http-request"
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                fieldName="http_request"
                [(ngModel)]="httpConfigItem.config.http_request"
                placeholder="{{ l10nKeys.clientRequestHeaderInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.clientRequestBodyInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="http-request-body"
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                fieldName="http_request_body"
                [(ngModel)]="httpConfigItem.config.http_request_body"
                placeholder="{{ l10nKeys.clientRequestBodyInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input
                clrCheckbox
                type="checkbox"
                name="exact-http-request"
                id="exact-http-request"
                [(ngModel)]="httpConfigItem.config.exact_http_request"
                [disabled]="disableUseExactRequestCheckbox"
            />
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                for="exact-http-request"
                fieldName="exact_http_request"
            >
                {{ l10nKeys.useExactRequestInputLabel | vtranslate }}
            </label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.serverResponseDataInputLabel | vtranslate }}</h6>
            <newline-converted-textarea
                name="http-response"
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                fieldName="http_response"
                [(ngModel)]="httpConfigItem.config.http_response"
                placeholder="{{ l10nKeys.serverResponseDataInputPlaceholder | vtranslate }}"
            ></newline-converted-textarea>
        </div>
    </div>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorHttp }}"
                fieldName="http_response_code"
                for="http-response-code"
                required
            >
                {{ globalL10nKeys.responseCodeLabel | vtranslate }}
            </label>
            <avi-enum-dropdown
                name="http-response-code"
                id="http-response-code"
                enum="HttpResponseCode"
                class="sel-http-status-code"
                [multiple]="true"
                [(ngModel)]="httpConfigItem.config.http_response_code"
                placeholder="{{ l10nKeys.responseCodeInputPlaceholder | vtranslate }}"
                required
            ></avi-enum-dropdown>
        </div>
    </div>

    <!-- HTTPS type related fields -->
    <ng-container *ngIf="isHttpsType">
        <div class="full-modal-config__form-control-row">
            <h6>{{ globalL10nKeys.securityLabel | vtranslate }}</h6>
        </div>

        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                fieldName="server_name"
                for="server-name"
            >
                {{ globalL10nKeys.tlsSniServerNameLabel | vtranslate }}
            </label>
            <input
                clrInput
                id="server-name"
                name="server-name"
                [(ngModel)]="httpConfigItem.config.ssl_attributes.config.server_name"
                placeholder="{{ globalL10nKeys.hostHeaderLabel | vtranslate }}"
                regexPattern="hostname"
            />
        </avi-input-container>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_profile_uuid"
                    for="ssl-profile-uuid"
                    required
                >
                    {{ globalL10nKeys.sslProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-profile-uuid"
                    name="ssl-profile-uuid"
                    [collection]="sslProfileCollection"
                    [(ngModel)]="httpConfigItem.config.ssl_attributes.config.ssl_profile_ref"
                    placeholder="{{ globalL10nKeys.sslProfilePlaceholder | vtranslate }}"
                    required
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="pki_profile_uuid"
                    for="pki-profile-uuid"
                >
                    {{ globalL10nKeys.pkiProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="pki-profile-uuid"
                    name="pki-profile-uuid"
                    [collection]="pkiProfileCollection"
                    [(ngModel)]="httpConfigItem.config.ssl_attributes.config.pki_profile_ref"
                    placeholder="{{ globalL10nKeys.pkiProfilePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_key_and_certificate_uuid"
                    for="ssl-key-and-certificate-uuid"
                >
                    {{ globalL10nKeys.sslKeyAndCertificateLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    id="ssl-key-and-certificate-uuid"
                    name="ssl-key-and-certificate-uuid"
                    [collection]="certificateCollection"
                    [(ngModel)]="httpConfigItem.config.ssl_attributes.config.ssl_key_and_certificate_ref"
                    placeholder="{{ globalL10nKeys.sslKeyAndCertificatePlaceholder | vtranslate }}"
                ></avi-collection-dropdown>
            </div>
        </div>
    </ng-container>
</div>
