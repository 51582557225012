/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './dns-records-tab.less';

/**
 * @ngdoc component
 * @name dnsRecordsTab
 * @description
 *     Static DNS Records tab in the Advanced Virtual Service create modal. Only applicable for DNS
 *     Virtual Services.
 * @param {VirtualService} editable
 */

class DnsRecordsTabController {
    constructor(AviModal) {
        this._AviModal = AviModal;
    }

    $onInit() {
        this.gridConfig = {
            id: 'dns-records-tab',
            fields: [{
                name: 'fqdn',
                title: 'FQDN',
                template: '{{ row.getConfig().fqdn.join(\', \') }}',
            }, {
                name: 'type',
                title: 'Type',
                transform: row => this.getDNSRecordTypeLabel(row),
            }, {
                name: 'data',
                title: 'Record Data',
                template: '{{ row.getRecordDataString() }}',
            }, {
                name: 'ttl',
                title: 'TTL',
                template: '{{ row.getConfig().ttl || \'N/A\' }}',
            }, {
                name: 'algorithm',
                title: 'Algorithm',
                template: '{{ row.getConfig().algorithm.enumeration(\'DNS_RECORD_RESPONSE_\') }}',
            }],
            rowId: dnsRecord => {
                const { static_dns_records: records } = this.virtualservice.getConfig();

                return !_.isEmpty(records) ? records.indexOf(dnsRecord) : -1;
            },
            singleactions: [{
                title: 'Edit',
                class: 'icon-pencil',
                do: row => this.handleEdit(row),
            }, {
                title: 'Delete',
                class: 'icon-trash',
                do: row => this.handleRemove(row),
            }],
            searchFields: ['data.config.fqdn', 'data.config.algorithm', 'data.config.ttl'],
        };
    }

    /**
     * Handler for clicking the Edit button in the grid singleaction. Opens the config modal
     * with a DnsRecordConfig passed in.
     * @param {DnsRecordConfig} dnsRecord
     */
    handleEdit(dnsRecord) {
        this._AviModal.open('dns-record-config-modal', {
            dnsRecord,
            onSubmit: ['record', record => {
                this.virtualservice.replaceDnsRecord(dnsRecord, record);
            }],
        });
    }

    /**
     * Handler for clicking the trash button in the grid singleaction. Removes a DnsRecordConfig
     * from static_dns_records.
     * @param {DnsRecordConfig} dnsRecord
     */
    handleRemove(dnsRecord) {
        this.virtualservice.removeDnsRecord(dnsRecord);
    }

    /**
     * Handler for clicking the Create button. Opens the config modal.
     */
    handleCreate() {
        this._AviModal.open('dns-record-config-modal', {
            onSubmit: ['record', record => this.virtualservice.addDnsRecord(record)],
        });
    }

    /**
     * Provides the relevant label for the current DNS record type.
     * @param dnsRecord
     * @returns {string} dnsTypeLabel
     */
    getDNSRecordTypeLabel(dnsRecord) {
        const type = dnsRecord.getType();
        const dnsRecordTypeLabels = DnsRecordsTabController.typeLabels;
        let dnsTypeLabel = dnsRecordTypeLabels[type];

        if (dnsTypeLabel) {
            if (dnsRecord.hasAAAARecord()) {
                if (dnsRecord.hasARecord()) {
                    dnsTypeLabel = dnsRecordTypeLabels.DNS_RECORD_A_AAAA;
                } else {
                    dnsTypeLabel = dnsRecordTypeLabels.DNS_RECORD_AAAA;
                }
            }
        } else {
            dnsTypeLabel = type.replace('DNS_RECORD_', '');
        }

        return dnsTypeLabel;
    }
}

/**
 * DNS Record type labels to be displayed in DNS grid type column.
 */
DnsRecordsTabController.typeLabels = {
    DNS_RECORD_A: 'A',
    DNS_RECORD_AAAA: 'AAAA',
    DNS_RECORD_A_AAAA: 'A/AAAA',
};

DnsRecordsTabController.$inject = [
        'AviModal',
];

angular.module('aviApp').component('dnsRecordsTab', {
    controller: DnsRecordsTabController,
    bindings: {
        virtualservice: '<',
    },
    templateUrl: 'src/components/applications/virtualservice/dns-records-tab/' +
            'dns-records-tab.html',
});
