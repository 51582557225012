<avi-data-grid
    [config]="significanceGridConfig"
    [rows]="significantLog$ | async | logSignificanceReasons"
></avi-data-grid>
<ng-template
    #significanceFieldTemplateRef
    let-row
    let-index="index"
>
    <span
        class="vs-log-cinematic-section-significance__row"
        *ngIf="row.description"
        aviTitle="{{ row.description }}"
        vsLogPartialSelectionFilter
        property="significance"
        [value]="row.label"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    ></span>
    <span
        class="vs-log-cinematic-section-significance__row"
        *ngIf="!row.description"
        vsLogPartialSelectionFilter
        property="significance"
        [value]="row.label"
        (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
    ></span>
</ng-template>
