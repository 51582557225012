<div provideParentForm>
    <cds-alert-group
        *ngIf="!connected"
        status="info"
    >
        <cds-alert status="info">
            {{ l10nKeys.connectionAlertMessage | vtranslate }}
            <cds-alert-actions slot="actions">
                <cds-button 
                    action="solid"
                    size="sm"
                    (click)="testLogin()"
                >
                    {{ l10nKeys.testLoginLabel | vtranslate }}
                </cds-button>
            </cds-alert-actions>
        </cds-alert>
    </cds-alert-group>
    <avi-data-grid
        [config]="customUsableSubnetsGridConfig"
        [rows]="usableSubnets.config"
        gridTitle="{{ l10nKeys.usableSubnetsGridHeader | vtranslate : usableSubnets.count}}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addUsableSubnet()"
                [disabled]="!connected"
            >
                {{ globalL10nKeys.addLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>
    </avi-data-grid>
</div>
