/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NotificationModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { Auth } from 'ajs/modules/core/services/auth';
import { AviNotificationType, IAviNotificationAction } from '../../avi-notification.types';
import * as l10n from './cloud-status.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Cloud-status notification component.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
@Component({
    selector: 'cloud-status',
    templateUrl: './cloud-status.component.html',
})
export class CloudStatusComponent implements OnInit {
    /**
     * Cloud UUID.
     */
    @Input()
    public cloudId: string;

    /**
     * Name of the cloud
     */
    @Input()
    public cloudName: string;

    /**
     * Cloud status shown as the notification content.
     */
    @Input()
    public status: string;

    /**
     * Handler to close the notification.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * To show cloud-status-details modal.
     */
    @Output()
    public onDisplayStatusDetail = new EventEmitter<string>();

    /**
     * Notification type, set to "success".
     */
    public notificationType = AviNotificationType.SUCCESS;

    /**
     * Actions as the clickable links in the notification.
     * In this component this should only contain one "Details" action.
     * Under All Tenant mode the "Details" action is not supported.
     */
    public actions: IAviNotificationAction[];

    public constructor(
        private l10nService: L10nService,
        private readonly authService: Auth,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (!this.authService.allTenantsMode()) {
            this.actions = [{
                label: this.l10nService.getMessage(l10nKeys.detailsLabel),
                onClick: this.displayCloudStatusDetail.bind(this),
            }];
        }
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Opens cloud-status modal.
     */
    public displayCloudStatusDetail(): void {
        this.onDisplayStatusDetail.emit(this.cloudId);
    }
}
