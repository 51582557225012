/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-o-auth';
const prefix = `${moduleName}.${componentName}`;

export const stateLabel = `${prefix}.stateLabel`;
export const oAuthHeadersLabel = `${prefix}.oAuthHeadersLabel`;
export const sessionCookieExpiredLabel = `${prefix}.sessionCookieExpiredLabel`;
export const jwksSubrequestLabel = `${prefix}.jwksSubrequestLabel`;
export const tokenExchangeSubrequestLabel = `${prefix}.tokenExchangeSubrequestLabel`;
export const tokenIntrospectionSubrequestLabel = `${prefix}.tokenIntrospectionSubrequestLabel`;
export const tokenRefreshSubrequestLabel = `${prefix}.tokenRefreshSubrequestLabel`;
export const userInfoSubrequestLabel = `${prefix}.userInfoSubrequestLabel`;
export const errorCodeLabel = `${prefix}.errorCodeLabel`;

export const ENGLISH = {
    [stateLabel]: 'State',
    [oAuthHeadersLabel]: 'OAuth Headers',
    [jwksSubrequestLabel]: 'JWKS Subrequest',
    [tokenExchangeSubrequestLabel]: 'Token Exchange Subrequest',
    [tokenIntrospectionSubrequestLabel]: 'Token Introspection Subrequest',
    [tokenRefreshSubrequestLabel]: 'Token Refresh Subrequest',
    [userInfoSubrequestLabel]: 'User Info Subrequest',
    [sessionCookieExpiredLabel]: 'Session Cookie Expired',
    [errorCodeLabel]: 'Error Code',
};
