/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'external-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleExternal = `${prefix}.sectionTitleExternal`;
export const scriptCodeInputLabel = `${prefix}.scriptCodeInputLabel`;
export const scriptCodeInputPlaceholder = `${prefix}.scriptCodeInputPlaceholder`;
export const scriptParametersInputLabel = `${prefix}.scriptParametersInputLabel`;
export const scriptParametersInputPlaceholder = `${prefix}.scriptParametersInputPlaceholder`;
export const scriptVariablesInputLabel = `${prefix}.scriptVariablesInputLabel`;
export const scriptVariablesInputPlaceholder = `${prefix}.scriptVariablesInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleExternal]: 'External',
    [scriptCodeInputLabel]: 'Upload or Paste Script Code',
    [scriptCodeInputPlaceholder]: 'Paste script code here',
    [scriptParametersInputLabel]: 'Script Parameters',
    [scriptParametersInputPlaceholder]: 'Enter Script Parameters',
    [scriptVariablesInputLabel]: 'Script Variables',
    [scriptVariablesInputPlaceholder]: 'Enter Script Variables',
};
