/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { withFullModalMixin } from 'ajs/utils/mixins';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    AviPermissionResource,
    GslbAlgorithm,
    IGslbPool,
} from 'generated-types';

import { GslbPool as GslbPoolObjectType } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    GslbServicePoolModalComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/gslb/components/gslb-service-modal/gslb-service-pool-modal/gslb-service-pool-modal.component';
import { L10nService } from '@vmw/ngx-vip';
import { GSLBService } from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';
import { GslbPoolMemberConfigItem } from './gslb-pool-member.config-item.factory';
import * as l10n from './gslb-pool.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TGslbPoolPartial = Omit<IGslbPool, 'members'>;

export interface IGslbPoolConfig extends TGslbPoolPartial {
    members?: RepeatedMessageItem<GslbPoolMemberConfigItem>,
}

/**
 * Constant for GSLB service pools field name.
 */
const MEMBERS = 'members';

/**
 * @description GSLB Service Pool ConfigItem.
 * @author Suraj Kumar
 */
export class GslbPoolConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IGslbPoolConfig, typeof MessageItem>(MessageItem),
)<IGslbPoolConfig> {
    public static ngDependencies = [
        'defaultValues',
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: GslbPoolObjectType,
            windowElement: GslbServicePoolModalComponent as Type<Component>,
            permissionName: AviPermissionResource.PERMISSION_GSLBSERVICE_GROUP,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IGslbPoolConfig> {
        const defaultValues = this.getNgDependency('defaultValues');
        const defaultPoolConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};

        return {
            ...defaultPoolConfig,
            members: undefined,
        };
    }

    /**
     * Handle Pool LoadBalancing Algorithm Change.
     */
    public onLoadBalancingAlgorithmChange(): void {
        const { config: poolConfig } = this;
        const selectedAlgorithm = poolConfig.algorithm;

        delete poolConfig.consistent_hash_mask;
        delete poolConfig.fallback_algorithm;

        if (selectedAlgorithm !== GslbAlgorithm.GSLB_ALGORITHM_PREFERENCE_ORDER) {
            this.resetPreferenceOrderForMembers();
        }

        if (selectedAlgorithm === GslbAlgorithm.GSLB_ALGORITHM_GEO) {
            poolConfig.fallback_algorithm = GslbAlgorithm.GSLB_ALGORITHM_ROUND_ROBIN;
        }
    }

    /**
     * Delete Pool ConsistentHashMask.
     */
    public onFallbackAlgorithmChange(): void {
        delete this.config.consistent_hash_mask;
    }

    /**
     * Open modal to add Pool Member.
     */
    public addPoolMember(
        gslbService: GSLBService,
        isGslbAlgorithmPreferenceOrder: boolean,
        previousSelectedPoolMembersVsIps: Set<string>,
    ): void {
        this.addChildMessageItem({
            field: MEMBERS,
            modalBindings: {
                editMode: false,
                gslbService,
                isGslbAlgorithmPreferenceOrder,
                previousSelectedPoolMembersVsIps,
            },
        });
    }

    /**
     * Open modal to edit Pool Member.
     */
    public editPoolMember(
        poolMember: GslbPoolMemberConfigItem,
        gslbService: GSLBService,
        isGslbAlgorithmPreferenceOrder: boolean,
        previousSelectedPoolMembersVsIps: Set<string>,
    ): void {
        this.editChildMessageItem({
            field: MEMBERS,
            messageItem: poolMember,
            modalBindings: {
                editMode: true,
                gslbService,
                isGslbAlgorithmPreferenceOrder,
                previousSelectedPoolMembersVsIps,
            },
        });
    }

    /**
     * Remove Pool Member.
     */
    public deletePoolMember(poolMember: GslbPoolMemberConfigItem): void {
        const { members: poolMembers } = this.config;

        poolMembers.removeByMessageItem(poolMember);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.gslbServicePoolModalBreadcrumbTitle);
    }

    /**
     * Reset preference order for all pool members if the algorithm
     * is not 'GSLB_ALGORITHM_PREFERENCE_ORDER'.
     */
    private resetPreferenceOrderForMembers(): void {
        const { config: poolMembersConfig } = this.config.members;

        poolMembersConfig.forEach(member => member.resetPreferenceOrder());
    }
}
