/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module WafModule */

import {
    AviPermissionResource,
    IWafCRS,
    IWafRule,
    IWafRuleGroup,
} from 'generated-types';
import { WafCRS } from 'object-types';
import { Item } from 'item';
import { getRuleField } from '../waf.utils';

export const WAF_CRS_ITEM_TOKEN = 'WafCrs';

/**
 * @description
 * WafCrs Item. This is an Item and not an ObjectTypeItem because the config data is only used for
 * read-only purposes, and the user does not actually modify the Item's config. In the WAF Policy
 * modal, editing a CRS group/rule creates a WafRuleGroupOverride or WafRuleOverride.
 * @author alextsg
 */
export class WafCrs extends Item<IWafCRS> {
    /**
     * Map of the groupName to the group config object.
     */
    private groupConfigMap: Map<string, IWafRuleGroup>;

    /**
     * Map of the ruleId to the rule config object.
     */
    private ruleConfigMap: Map<string, IWafRule>;

    /**
     * Map of the ruleId to the groupName the rule belongs to.
     */
    private ruleIdToGroupNameMap: Map<string, string>;

    constructor(args = {}) {
        const extendedArgs = {
            permissionName: AviPermissionResource.PERMISSION_WAFCRS,
            objectName: 'wafcrs',
            objectType: WafCRS,
            ...args,
        };

        super(extendedArgs);

        this.setCrsMaps();
    }

    /**
     * Return the ID of this rule. ID is either configured as a field or parsed from the
     * rule. Might have duplicates within a group, not populated by UI for the new rules.
     * @return {string}
     */
    public static getRuleIdFromConfig(ruleConfig: IWafRule): string {
        const { rule, rule_id: ruleId } = ruleConfig;

        return ruleId || getRuleField(rule, 'id');
    }

    /** @override */
    public transformAfterLoad(): void {
        this.setCrsMaps();
    }

    /**
     * Return true if a group with the groupName exists.
     */
    public hasGroup(groupName: string): boolean {
        return this.groupConfigMap.has(groupName);
    }

    /**
     * Return true if a rule with the ruleId exists.
     */
    public hasRule(ruleId: string): boolean {
        return this.ruleConfigMap.has(ruleId);
    }

    /**
     * Return the group config of the specified group name.
     */
    public getGroupConfig(groupName: string): IWafRuleGroup {
        return this.groupConfigMap.get(groupName);
    }

    /**
     * Return the rule config of the specified rule ID.
     */
    public getRuleConfig(ruleId: string): IWafRule {
        return this.ruleConfigMap.get(ruleId);
    }

    /**
     * Return the rule name of the rule with the specified rule ID. The rule may not always have a
     * configured name, so a name may be parsed from the rule contents.
     */
    public getRuleName(ruleId: string, fullName: boolean): string {
        const ruleConfig = this.getRuleConfig(ruleId);

        let { name } = ruleConfig;
        const { rule } = ruleConfig;

        if (!name) {
            name = getRuleField(rule, 'msg');
        }

        if (!fullName) {
            return name;
        }

        return name ? `${ruleId} | ${name}` : ruleId;
    }

    /**
     * Return the name of the group that a rule of specified rule ID is part of.
     */
    public getGroupName(ruleId: string): string {
        return this.ruleIdToGroupNameMap.get(ruleId);
    }

    /**
     * Set the groupConfigMap, ruleConfigMap, and ruleIdToGroupNameMap of this CRS config.
     */
    private setCrsMaps(): void {
        this.groupConfigMap = new Map<string, IWafRuleGroup>();
        this.ruleConfigMap = new Map<string, IWafRule>();
        this.ruleIdToGroupNameMap = new Map<string, string>();

        this.getConfig().groups?.forEach((group: IWafRuleGroup) => {
            const { name: groupName, rules } = group;

            this.groupConfigMap.set(groupName, group);

            rules.forEach((ruleConfig: IWafRule) => {
                const ruleId = WafCrs.getRuleIdFromConfig(ruleConfig);

                this.ruleConfigMap.set(ruleId, ruleConfig);
                this.ruleIdToGroupNameMap.set(ruleId, groupName);
            });
        });
    }
}
