<credentials-verification-dialog
    [disableSubmit]="form.invalid"
    (onCancel)="handleCancel()"
    (onSubmit)="handleSubmit()"
    [errors]="errors"
    [busy]="busy"
    [submitButtonLabel]="globalL10nKeys.setCredentialsButtonLabel | vtranslate"
    class="gslb-site-edit-credentials-dialog"
>
    <credentials-verification-dialog_header>
        <h3>{{ globalL10nKeys.credentialsLabel | vtranslate }}</h3>
    </credentials-verification-dialog_header>
    <credentials-verification-dialog_form>
        <form
            clrForm
            [clrLayout]="verticalLayout"
            #form="ngForm"
        >
            <div class="full-modal-config__form-control-row gslb-site-edit-credentials-dialog__no-margin-top">
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="username"
                            for="username"
                            required
                        >
                            {{ globalL10nKeys.usernameLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="username"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="username"
                            [(ngModel)]="config.username"
                            required
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="password"
                            for="password"
                            required
                        >
                            {{ globalL10nKeys.passwordLabel | vtranslate }}
                        </label>
                        <input
                            clrInput
                            id="password"
                            type="password"
                            placeholder="{{ globalL10nKeys.notSetLabel | vtranslate }}"
                            name="password"
                            [(ngModel)]="config.password"
                            required
                        />
                    </avi-input-container>
                </div>
            </div>
        </form>
    </credentials-verification-dialog_form>
</credentials-verification-dialog>
