/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { GslbIpAddr as GslbIpAddrObjectType } from 'object-types';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories';
import { IGslbIpAddr } from 'generated-types';

type TGslbIpAddrPartial = Omit<IGslbIpAddr, 'ip'>;

export interface IGslbIpAddrConfig extends TGslbIpAddrPartial {
    ip?: IpAddrConfigItem,
}

/**
 * @description GslbIpAddr ConfigItem.
 * @author Suraj Kumar
 */
export class GslbIpAddrConfigItem extends MessageItem<IGslbIpAddrConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GslbIpAddrObjectType,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return ['ip'];
    }
}
