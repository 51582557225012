/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IHttpPromise,
    IHttpResponse,
    IHttpService,
    IPromise,
} from 'angular';

import {
    IALBServicesConfig,
    IALBServicesStatus,
    IALBServicesUser,
} from 'generated-types';

import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { getResolvablePromise } from 'ng/shared/utils';
import { TSystemTagsMetaData } from 'ng/modules/cportal/components/case-custom-tags-grid';

import {
    IALBServicesStatusApiResponse,
    IPortalRegistrationApiResponse,
    IRegisterControllerApiResponse,
    IRegisterControllerPayLoad,
    IUserListApiResponse,
} from './cportal.service.type';

import { loginWindowClearIntervalDuration } from '../constants';

const BASE_URL = '/api/albservices';
const RETURN_URL = '/cportal/after-cportal-registration.html';
const PORTAL_LOGIN_URL = `${BASE_URL}/login?return_url=${RETURN_URL}`;
const STATUS_URL = `${BASE_URL}/status`;
const STATUS_REFRESH_URL = `${STATUS_URL}/?update=true`;
const REGISTRATION_URL = `${BASE_URL}/register`;
const PORTAL_INFO_URL = '/api/albservicesconfig';
const USER_INFO_URL = `${BASE_URL}/userinfo`;
const USERS = `${BASE_URL}/user`;

export const CPORTAL_SERVICE_TOKEN = 'CportalService';

const METADATA = `${BASE_URL}/case/metadata`;

/**
 *
 * @description
 *     CportalService is a stateless service which contains functions related to
 *     login, status, refresh, registation, de-registration process.
 * @author Ram Pal, Rajawant Prajapati
 */
export class CportalService extends AjsDependency {
    /**
     * HttpService to call API's
     */
    private readonly $http: IHttpService;

    /**
     * Interval Id
     */
    private interval: number;

    /**
     * pulse status info.
     */
    private albserviceStatus: IALBServicesStatus;

    constructor() {
        super();

        this.$http = this.getAjsDependency_('$http');
    }

    /**
     * This function handles http response and throws error in case of exception.
     */
    public static handleHttpResponse<T = any>(request: IHttpPromise<T>): IPromise<T> {
        return request
            .then((response: IHttpResponse<T>) => response.data)
            .catch(error => { throw error.data; });
    }

    /**
     * Function is used to authenticate user with Salesforce.
     */
    public openLoginWindow(): IPromise<void> {
        const $window: ng.IWindowService = this.getAjsDependency_('$window');
        const promise = getResolvablePromise<void>();

        const windowDimensions = 'width=800, height=600, top=200, left=450';

        const loginWindow = $window.open(PORTAL_LOGIN_URL, 'login', windowDimensions);

        this.interval = window.setInterval(() => {
            if (loginWindow.closed) {
                this.clearLoginWindowInterval();
                promise.resolve();
            }
        }, loginWindowClearIntervalDuration);

        return promise;
    }

    /**
     * This function is used to refresh the registration status
     */
    public refreshStatus(): IPromise<IALBServicesStatusApiResponse> {
        return CportalService.handleHttpResponse(this.$http.get(STATUS_REFRESH_URL));
    }

    /**
     * It registers Controller with Portal
     */
    public registerController(payload: IRegisterControllerPayLoad)
        : IPromise<IPortalRegistrationApiResponse> {
        return CportalService.handleHttpResponse(this.$http.post(REGISTRATION_URL, payload));
    }

    /**
     * It de-registers Controller with Portal
     */
    public deregisterController(): IPromise<IRegisterControllerApiResponse> {
        const payload = {
            status: 'Obsolete',
        };

        return CportalService.handleHttpResponse(this.$http.put(REGISTRATION_URL, payload));
    }

    /**
     * This function returns customer portal information.
     */
    public getPortalInfo(): IPromise<IALBServicesConfig> {
        return CportalService.handleHttpResponse(this.$http.get(PORTAL_INFO_URL));
    }

    /**
     * This function returns user's information along with associated accounts.
     */
    public getUserInfo(): IPromise<IALBServicesUser> {
        return CportalService.handleHttpResponse(this.$http.get(USER_INFO_URL));
    }

    /**
     * This function returns users list.
     */
    public getUsersList(): IPromise<IUserListApiResponse> {
        return CportalService.handleHttpResponse(this.$http.get(USERS));
    }

    /**
     * Remove the interval checking the state of salesforce login window.
     */
    public clearLoginWindowInterval(): void {
        clearInterval(this.interval);
    }

    /**
     * Get meta data for system tags.
     * This is called in case of SYSTEST portal_mode.
     */
    public getMetaData(): IPromise<TSystemTagsMetaData> {
        return CportalService.handleHttpResponse(this.$http.get(METADATA));
    }

    /**
     * Get pulse/portal registration status.
     */
    public getRegistrationStatusDetails(): IALBServicesStatus | null {
        return this.albserviceStatus ?? null;
    }

    /**
     * Loads the pulse/portal registration status.
     */
    public loadRegistrationStatus(): IPromise<void> {
        return CportalService.handleHttpResponse(this.$http.get(STATUS_URL)).then(
            (data: IALBServicesStatus) => {
                this.albserviceStatus = data;
            },
        );
    }

    /**
     * This is called to update the portal info.
     */
    public updatePortalInfo(payload: IALBServicesConfig): IPromise<IALBServicesConfig> {
        return CportalService.handleHttpResponse(this.$http.put(PORTAL_INFO_URL, payload));
    }
}

CportalService.ajsDependencies = [
    '$window',
    '$http',
];
