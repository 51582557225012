/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'update-progress-card';
const prefix = `${moduleName}.${componentName}`;

export const controllerUpdatingToVersionHeader = `${prefix}.controllerUpdatingToVersionHeader`;
export const segUpdatingToVersionHeader = `${prefix}.segUpdatingToVersionHeader`;
export const runningPreChecksLabel = `${prefix}.runningPreChecksLabel`;
export const inQueueLabel = `${prefix}.inQueueLabel`;
export const completeLabel = `${prefix}.completeLabel`;
export const seGroupLeftPluralLabel = `${prefix}.seGroupLeftPluralLabel`;
export const clusterWarmRebootingLabel = `${prefix}.clusterWarmRebootingLabel`;
export const noOperationsInProgress = `${prefix}.noOperationsInProgress`;
export const viewProgressLabel = `${prefix}.viewProgressLabel`;

export const ENGLISH = {
    [controllerUpdatingToVersionHeader]: 'Controller upgrading to {0}',
    [segUpdatingToVersionHeader]: 'Service Engine Groups updating to {0}',
    [runningPreChecksLabel]: 'Running Prechecks',
    [inQueueLabel]: 'In Queue',
    [completeLabel]: 'complete',
    [seGroupLeftPluralLabel]: '{0, plural, one{# Service Engine Group Left} other{# Service Engine Groups Left}}',
    [clusterWarmRebootingLabel]: 'Cluster warm rebooting. This may take several minutes.',
    [noOperationsInProgress]: 'No operations currently in progress',
    [viewProgressLabel]: 'View Progress',
};
