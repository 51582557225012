/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    IDnsServiceDomain,
    IIpamDnsInternalProfile,
    IIpamUsableNetwork,
} from 'generated-types';

import { IpamDnsInternalProfile } from 'object-types';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';

type TIpamDnsInternalProfilePartial =
    Omit<IIpamDnsInternalProfile, 'dns_service_domain' | 'usable_networks'>;

interface IIpamDnsInternalProfileConfig extends TIpamDnsInternalProfilePartial {
    dns_service_domain?: RepeatedMessageItem<MessageItem<IDnsServiceDomain>>;
    usable_networks?: RepeatedMessageItem<MessageItem<IIpamUsableNetwork>>;
}

/**
 * @desc IpamDnsInternalProfile ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamDnsInternalProfileConfigItem extends MessageItem<IIpamDnsInternalProfileConfig> {
    public static ajsDependencies = [
        'defaultValues',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IpamDnsInternalProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * We want to add a single DnsServiceDomain by default.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IIpamDnsInternalProfileConfig> {
        const type = this.objectType.toLowerCase();
        const defaultValues: DefaultValues =
            this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { defaultDnsServiceDomainConfig } = this;

        return {
            ...defaultConfig,
            dns_service_domain: [{ ...defaultDnsServiceDomainConfig }],
        };
    }

    /**
     * Returns the default DnsServiceDomain config object.
     */
    public get defaultDnsServiceDomainConfig(): Partial<IDnsServiceDomain> {
        const messageMapProps = this.messageMap.dns_service_domain;
        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');

        if (!messageMapProps) {
            return {};
        }

        const { objectType } = messageMapProps;
        const type = objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return defaultConfig;
    }

    /**
     * Returns the DnsServiceDomain repeated config item.
     */
    public get dnsServiceDomains(): RepeatedMessageItem<MessageItem<IDnsServiceDomain>> {
        return this.config.dns_service_domain;
    }

    /**
     * Adds a DnsServiceDomain config item.
     */
    public addDnsRecord(): void {
        this.config.dns_service_domain.add();
    }

    /**
     * Getter for usable network config.
     */
    public get usableNetworksConfig(): Array<MessageItem<IIpamUsableNetwork>> {
        return this.config.usable_networks.config || [];
    }

    /**
     * Removes a DnsServiceDomain config item.
     */
    public removeDnsRecord(domain: MessageItem<IDnsServiceDomain>): void {
        this.config.dns_service_domain.removeByMessageItem(domain);
    }

    /**
     * Adds an empty usable network.
     */
    public addUsableNetwork(): void {
        this.config.usable_networks.add();
    }

    /**
     * Removes Network ref from network list.
     */
    public removeUsableNetwork(network: MessageItem<IIpamUsableNetwork>): void {
        this.config.usable_networks.removeByMessageItem(network);
    }
}
