<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    submitButtonText="{{ l10nKeys.saveButtonLabel | vtranslate }}"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid && validated"
    class="ssl-certificate-create-root-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleGeneral | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleGeneral | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        objectType="{{ objectType }}"
                        fieldName="name"
                        for="name"
                        required
                    >
                        {{ l10nKeys.nameInputLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        [(ngModel)]="editable.config.name"
                        [disabled]="editable.isProtected()"
                        clrInput
                        placeholder="{{ l10nKeys.nameInputPlaceholder | vtranslate }}"
                        regexPattern="objName"
                        required
                    />
                </avi-input-container>
                <avi-checkbox
                    [disabled]="!!editable.id"
                    objectType="{{ objectType }}"
                    fieldName="is_federated"
                    [(ngModel)]="editable.config.is_federated"
                    name="is-federated"
                >
                    {{ globalL10nKeys.isFederatedLabel | vtranslate }}
                </avi-checkbox>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleCertificate | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleCertificate | vtranslate }}</h3>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectType }}"
                            fieldName="certificate"
                            for="certificate"
                            required
                        >
                            {{ l10nKeys.certificateFileInputLabel | vtranslate }}
                        </label>
                        <file-upload-textarea
                            [(ngModel)]="editable.certificate.config.certificate"
                            (onUploadTypeChange)="setCertificateBase64($event)"
                            placeholder="{{ l10nKeys.certificateFileInputPlaceholder | vtranslate }}"
                            name="certificate"
                            [base64]="true"
                            required
                        ></file-upload-textarea>
                    </div>
                </div>
                <ssl-certificate-imported-information
                    [certificate]="editable.config.certificate" 
                    [objectType]="objectType"
                ></ssl-certificate-imported-information>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleOcspSampling | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleOcspSampling | vtranslate }}</h3>
                <ssl-tls-certificate-ocsp [editable]="editable" ></ssl-tls-certificate-ocsp>
            </div>
        </full-modal-tab-section>
    </form>
    <full-modal-config_footer class="ssl-certificate-create-root-modal__validate-button">
        <cds-button
            [disabled]="validated || form.invalid"
            (click)="validateCert()"
            action="flat"
        >
            {{ l10nKeys.validateButtonLabel | vtranslate }}
        </cds-button>
    </full-modal-config_footer>
</full-modal-config>
