/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'groups';

export const ipAddrgroupModalBreadcrumbTitle = `${prefix}.ipAddrgroupModalBreadcrumbTitle`;
export const stringGroupModalBreadcrumbTitle = `${prefix}.stringGroupModalBreadcrumbTitle`;

export const ENGLISH = {
    [ipAddrgroupModalBreadcrumbTitle]: 'IP Group',
    [stringGroupModalBreadcrumbTitle]: 'String Group',
};
