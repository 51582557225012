<div
    *ngFor="let match of formattedMatches; trackBy: trackByIndex"
>
    <match-display-wrapper
        matchLabel="{{ l10nKeys.hdrLabel | vtranslate }}"
        matchCriteria="{{ match.criteria }}"
        [matchValuesExist]="!!(match.values?.length)"
        subheader="{{ match.subheader }}"
    >
        <ng-container *ngIf="match.values?.length">
            <div *ngFor="let val of match.values; trackBy: trackByIndex">
                {{ val }}
            </div>
        </ng-container>
    </match-display-wrapper>
</div>
