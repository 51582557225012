/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ListDataTransformer } from './list-data-transformer.factory';

/**
 * @constructor
 * @memberof module:avi/dataModel
 * @extends ListDataTransformer
 * @author Ashish Verma, Ram Pal
 * @desc
 *    Can be used for all list APIs of `inventory` type.
 *
 *    Translates `field_` request property into concatenated string of sources to be included
 *    into inventory response (such as `runtime`, `health_score`, etc).
 *
 *    Processes all `alert` Item data through the
 *    {@link module:avi/dataModel.Inventory Inventory | specific parser}.
 */
export class InventoryDataTransformer extends ListDataTransformer {
    /**
     * Prepares request parameters before passing them to DataTransport.
     * @param {ListDataTransportRequestParams} requestParams - Takes care of special
     * properties such as `fields_`, `offset_`, `limit_`.
     * @returns {ListDataTransportRequestParams}
     * @override
     * @public
     */
    getRequestConfig(requestParams) {
        const params =
        super.getRequestConfig(requestParams);

        if ('fields_' in params && Array.isArray(params['fields_'])) {
            params['include'] = params['fields_'].join(',');
        }

        delete params['fields_'];

        return params;
    }

    /**
     * Parses `alert` data of Items in a list to provide a consistent alert properties all
     * over the app.
     * @param {ListDataTransportResponse} resp
     * @param {ListDataTransportRequestParams} request
     * @returns {ListDataTransportResponse}
     * @override
     * @public
     */
    processResponse(resp, request) {
        resp = this.responseListOffsetControl_(resp, request);

        if ('results' in resp.data && Array.isArray(resp.data.results) &&
        resp.data.results.length) {
            const
                { results } = resp.data,
                sampleRow = _.sample(results);

            const itemAlertDataTransform = this.getAjsDependency_('itemAlertDataTransform');

            if ('alert' in sampleRow) {
                results.forEach(
                    ({ alert }) => itemAlertDataTransform(alert),
                );
            }
        }

        return resp;
    }
}

InventoryDataTransformer.ajsDependencies = [
    'itemAlertDataTransform',
];
