/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IWafRuleLog } from 'generated-types';

import {
    WafPolicyItem,
} from 'ajs/modules/waf/factories/waf-policy/waf-policy.item.factory';

import { StringService } from 'ng/modules/core/services/string/string.service';

import {
    WafExcludeListEntryConfigItem,
} from 'ajs/modules/waf/factories/waf-exclude-list-entry.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import * as globalL10n from 'global-l10n';

import './waf-add-exception-dialog.component.less';
import * as l10n from './waf-add-exception-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component used for adding WAF exceptions from VS logs.
 * @author Shanmukha Sarath Kondiparthi, alextsg
 */
@Component({
    selector: 'waf-add-exception-dialog',
    templateUrl: 'waf-add-exception-dialog.component.html',
})
export class WafAddExceptionDialogComponent {
    /**
     * WAF Policy ref attached to the VS. Optional because the WAF Policy from logs may no longer
     * exist or be attached to the VS.
     */
    @Input()
    public wafPolicyRef?: string;

    /**
     * Name of the group to add the exception to.
     */
    @Input()
    public groupName: string;

    /**
     * ID of the rule to add the exception to.
     */
    @Input()
    public ruleId: string;

    /**
     * Logs containing match elements that can be used to add exceptions.
     */
    @Input()
    public ruleLogs: IWafRuleLog[];

    /**
     * URI Path that an exception can be added for.
     */
    @Input()
    public uriPath: string;

    @Output()
    public onClose = new EventEmitter<void>();

    public wafRuleName: string;

    public error = '';

    public busy = false;

    /**
     * Tracks of the group name or rule ID no longer exists in the WAF Policy.
     */
    public nonexistentRuleOrGroup = false;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Reference to the excludeList RepeatedMessageItem.
     */
    public excludeList: RepeatedMessageItem<WafExcludeListEntryConfigItem>;

    /**
     * Index where new exceptions start. Only new exceptions may be removed, so this provides a
     * reference as to which exceptions are new exceptions.
     */
    public newExceptionsIndex = 0;

    private wafPolicy: WafPolicyItem;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(WafPolicyItem)
        private readonly WafPolicy: typeof WafPolicyItem,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Load WAF Policy and add new exceptions to the excludeList.
     */
    public ngOnInit(): void {
        if (!this.wafPolicyRef) {
            return;
        }

        this.wafPolicy = new this.WafPolicy({
            id: this.stringService.slug(this.wafPolicyRef),
        });

        this.busy = true;

        this.wafPolicy.load(undefined, true)
            .then(() => {
                if (this.ruleId) {
                    const hasRule = this.wafPolicy.hasRule(this.ruleId);

                    if (!hasRule) {
                        this.error = this.l10nService.getMessage(
                            l10nKeys.ruleNoLongerExists,
                            [this.ruleId],
                        );

                        this.nonexistentRuleOrGroup = true;

                        return;
                    }

                    this.wafRuleName = this.wafPolicy.getRuleNameByRuleId(this.ruleId, true);
                } else {
                    const hasGroup = this.wafPolicy.hasGroup(this.groupName);

                    if (!hasGroup) {
                        this.error = this.l10nService.getMessage(
                            l10nKeys.groupNoLongerExists,
                            [this.groupName],
                        );

                        this.nonexistentRuleOrGroup = true;

                        return;
                    }
                }

                this.excludeList = this.wafPolicy.getExcludeList(this.groupName, this.ruleId);
                this.newExceptionsIndex = this.excludeListCount;
                this.setNewExclusions();
            })
            .finally(() => {
                this.busy = false;
            });
    }

    /**
     * Dismiss the confirmtation dialog.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Save WAF policy.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.error = '';

        this.wafPolicy.save()
            .then(() => {
                this.handleCancel();
                this.wafPolicy.destroy();
            })
            .catch(errors => {
                this.error = errors.data.error;
            })
            .finally(() => {
                this.busy = false;
            });
    }

    /**
     * Remove new exception. The index passed as the argument here is index starting at
     * this.newExceptionsIndex. To get the actual index in this.excludeList we add
     * this.newExceptionsIndex to the index.
     *
     * Ex. If there were originally 3 exceptions, this.newExceptionsIndex will be 3. If there are
     * 2 new exceptions, and the user tries to remove the second new exception, the index passed as
     * the argument will be 1. The actual index of the exception-to-be-removed in this.excludeList
     * is actually 4: index (1) + this.newExceptionIndex (3).
     */
    public removeNewException(index: number): void {
        this.excludeList.remove(index + this.newExceptionsIndex);
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Return the number of total exclude list entries, including new ones being added.
     */
    public get excludeListCount(): number {
        return this.excludeList?.count || 0;
    }

    /**
     * Add new exceptions to the existing list of exceptions
     */
    private setNewExclusions(): void {
        if (Array.isArray(this.ruleLogs)) {
            this.ruleLogs.forEach(rule => {
                if (Array.isArray(rule.matches)) {
                    rule.matches.forEach(match => {
                        const {
                            match_element: matchElement,
                            is_internal: isInternal,
                        } = match;

                        if (!isInternal) {
                            this.excludeList.add({
                                uri_path: this.uriPath,
                                match_element: matchElement,
                            });
                        }
                    });
                }
            });
        }
    }
}
