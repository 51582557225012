<avi-repeated-strings
    id="{{ fieldName + '_numbers' }}"
    name="repeated-string-model"
    [placeholder]="placeholder"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    [allowDuplicates]="allowDuplicates"
    [minLimit]="minLimit"
    [maxLimit]="maxLimit"
    [(ngModel)]="repeatedStringsModel"
    (ngModelChange)="handleUpdate()"
    required
></avi-repeated-strings>
