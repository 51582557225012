/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import { Injector } from '@angular/core';

import {
    GslbGeoDbProfileCollection,
    TGslbGeoDbProfileCollection,
} from 'ajs/modules/gslb/factories/gslb-geodb-profile/gslb-geodb-profile.collection.factory';

import {
    FederationCheckpoint,
    TFederationCheckpoint,
} from 'ajs/modules/gslb/factories/federation-checkpoint.item.factory';

import {
    FEDERATION_CHECKPOINT_TOKEN,
    GSLB_GEODB_PROFILE_COLLECTION_TOKEN,
    GSLB_VS_COLLECTION_TOKEN,
} from 'ajs/modules/gslb/gslb.tokens';

import {
    GSLBVSCollection,
    TGSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

const $injector = '$injector';

export const gslbGeoDbProfileCollectionProvider = {
    deps: [$injector],
    provide: GslbGeoDbProfileCollection,
    useFactory(injector: Injector): TGslbGeoDbProfileCollection {
        return injector.get(GSLB_GEODB_PROFILE_COLLECTION_TOKEN);
    },
};

export const federationCheckpointItemProvider = {
    deps: [$injector],
    provide: FederationCheckpoint,
    useFactory(injector: Injector): TFederationCheckpoint {
        return injector.get(FEDERATION_CHECKPOINT_TOKEN);
    },
};

export const gslbVSCollectionProvider = {
    deps: [$injector],
    provide: GSLBVSCollection,
    useFactory(injector: Injector): TGSLBVSCollection {
        return injector.get(GSLB_VS_COLLECTION_TOKEN);
    },
};
