/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { HSMSafenetClientInfo } from 'object-types';
import { IHSMSafenetClientInfo } from 'generated-types';

/**
 * @description HSM Safenet ClientInfo Message Item.
 * @author vgohil
 */
export class HSMSafenetClientInfoConfigItem extends MessageItem<IHSMSafenetClientInfo> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HSMSafenetClientInfo,
            ...args,
        };

        super(extendedArgs);
    }
}
