/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IPReputationDBListPageContainerComponent } from 'ng/modules/ip-reputation-db';

import { WafApplicationRulesListComponent } from
    'ng/modules/waf/components/waf-application-rules-list/waf-application-rules-list.component';

import { WafProfileListComponent } from
    'ng/modules/waf/components/waf-profile-list/waf-profile-list.component';

import { AuthProfileListPageComponent } from
    'ng/modules/security/components/auth-profile-page/auth-profile-page.component';
import { PersistenceProfileListPageComponent } from
    // eslint-disable-next-line max-len
    'ng/modules/persistence-profile/components/persistence-profile-page/persistence-profile-page.component';

import { IpAddrGroupListPageComponent } from
    'ng/modules/groups/components/ip-addr-group-page/ip-addr-group-page.component';

import { SslCertificateListComponent }
    from 'ng/modules/security/components/ssl-certificate-list/ssl-certificate-list.component';

import { NodesPageComponent } from
    'ng/modules/cluster/components/nodes-page/nodes-page.component';

import { ErrorPageBodyComponent }
    from 'ng/modules/error-page/components/error-page-body/error-page-body.component';

import { ErrorPageProfileListComponent } from
    'ng/modules/error-page/components/error-page-profile-list/error-page-profile-list.component';

import {
    BackupPageComponent,
    SystemSettingsPageComponent,
} from 'ng/modules/system/components';

import {
    UserPageListComponent,
} from 'ng/modules/accounts/components/user-page-list/user-page-list.component';

import { CPortalPageComponent } from 'ng/modules/cportal/components/cportal-page';
import { CrashReportsPageComponent } from 'ng/modules/system/components/crash-reports-page';

import { TrafficCloneProfilePageComponent } from
    'ng/modules/traffic-clone-profile/components/traffic-clone-profile-page';

import { VrfContextPageComponent } from 'ng/modules/vrf-context/components/vrf-context-page';

import { BotMappingPageComponent }
    from 'ng/modules/bot/components/bot-mapping-page/bot-mapping-page.component';

import { TrafficCapturePageComponent } from
    'ng/modules/service-engine/components/traffic-capture-page/traffic-capture-page.component';

import { AuthMappingProfilePageComponent } from
    'ng/modules/security/components/auth-mapping-profile-page/auth-mapping-profile-page.component';

import { CertificateManagementListPageComponent } from
    // eslint-disable-next-line max-len
    'ng/modules/security/components/certificate-management-list-page/certificate-management-list-page.component';

import { PkiProfileListPageComponent }
    from 'ng/modules/security/components/pki-profile-list-page/pki-profile-list-page.component';

import { DataScriptComponent }
    from 'ng/modules/scripts/components/data-script/data-script.component';

import { ControlScriptComponent }
    from 'ng/modules/scripts/components/control-script/control-script.component';

import { SSOPolicyListComponent }
    from 'ng/modules/sso-policy/components/sso-policy-list/sso-policy-list.component';

import { ProtocolParserScriptComponent }
    from 'ng/modules/scripts/components/protocol-parser-script/protocol-parser-script.component';

import { SslProfileListComponent }
    from 'ng/modules/security/components/ssl-profile-list/ssl-profile-list.component';

import { L4PolicyComponent }
    from 'ng/modules/l4-policy/l4-policy.component';

import { JwtServerProfileComponent }
    from 'ng/modules/security/components/jwt-server-profile/jwt-server-profile.component';
import { HsmGroupListComponent }
    from 'ng/modules/security/components/hsm-group-list/hsm-group-list.component';

import { UserProfileListComponent }
    from 'ng/modules/accounts/components/user-profile-list/user-profile-list.component';

import { StringGroupListComponent }
    from 'ng/modules/groups/components/string-group-list/string-group-list.component';

import { GeoDbListComponent }
    from 'ng/modules/security/components/geo-db-list/geo-db-list.component';

import { BotManagementPolicyListComponent } from
    'ng/modules/bot/components/bot-management-policy-list/bot-management-policy-list.component';

import { GslbGeoProfileListComponent }
    from 'ng/modules/gslb/components/gslb-geo-profile-list/gslb-geo-profile-list.component';

import { GslbFederationCheckpointsListComponent }
    // eslint-disable-next-line max-len
    from 'ng/modules/gslb/components/gslb-federation-checkpoints-list/gslb-federation-checkpoints-list.component';

import {
    ServerAutoScalePolicyListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/auto-scale/components/server-auto-scale-policy-list-page/server-auto-scale-policy-list-page.component';

import {
    LaunchConfigListPageComponent,
} from 'ng/modules/auto-scale/components/launch-config-list-page/launch-config-list-page.component';

import {
    PoolGroupDeploymentPolicyListPageComponent,
    CSRFPolicyListPageComponent,
} from 'router-components/index';

import {
    WebhookListPageComponent,
} from 'ng/modules/webhook/components/webhook-list-page/webhook-list-page.component';

import {
    IcapProfileListPageComponent,
} from 'ng/modules/icap-profile/components/icap-profile-list-page/icap-profile-list-page.component';

import {
    IpamDnsProfileListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/ipam/components/ipam-dns-profile-list-page/ipam-dns-profile-list-page.component';

import {
    CustomIpamDnsProfileListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/ipam/components/custom-ipam-dns-profile-list-page/custom-ipam-dns-profile-list-page.component';

import {
    UserCredentialsListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/cloud-connector-user/components/user-credentials-page/user-credentials-page.component';

import {
    HealthMonitorListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/health-monitor/components/health-monitor-list-page/health-monitor-list-page.component';
import {
    NetworkProfilesListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/network/components/network-profiles-list-page/network-profiles-list-page.component';

import {
    AlertActionsListPageComponent,
} from 'ng/modules/alert/components/alert-actions-list-page/alert-actions-list-page.component';

import {
    RoleListPageComponent,
} from 'ng/modules/accounts/components/role-list-page/role-list-page.component';

import {
    CaseListPageComponent,
} from 'ng/modules/support/components/case-list-page/case-list-page.component';

import {
    DnsRecordsPageComponent,
} from 'ng/modules/virtual-service/components/dns-records-page/dns-records-page.component';

import {
    NatPolicyListPageComponent,
} from 'ng/modules/nat-policy/components/nat-policy-list-page/nat-policy-list-page.component';

import {
    AnalyticsProfileListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/analytics/components/analytics-profile-list-page/analytics-profile-list-page.component';

import {
    NetworkListPageComponent,
} from 'ng/modules/network/components/network-list-page/network-list-page.component';

import {
    WafPolicyListPageComponent,
} from 'ng/modules/waf/components/waf-policy-list-page/waf-policy-list-page.component';

import {
    VsVipListPageComponent,
} from 'ng/modules/vs-vip/components/vs-vip-list-page/vs-vip-list-page.component';
import {
    AlertConfigListPageComponent,
} from 'ng/modules/alert/components/alert-config-list-page/alert-config-list-page.component';

// TODO: AV-171346 uncomment if removing old logs and tech-preview
// import { VsLogsPageComponent } from 'ng/modules/vs-logs';

import {
    SEGroupListPageComponent,
} from 'ng/modules/service-engine-group/components/se-group-list-page/se-group-list-page.component';

import {
    ApplicationProfileListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/application-profile/components/application-profile-list-page/application-profile-list-page.component';

import {
    AdministrationDashboardPageComponent,
} from 'ng/modules/administration-dashboard/components/administration-dashboard-page';

import {
    TenantSettingsPageListComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/accounts/components/tenant-settings-page-list/tenant-settings-page-list.component';

import {
    PoolGroupListPageComponent,
} from 'ng/modules/pool-group/components/pool-group-list-page/pool-group-list-page.component';

import { SystemUpdateComponent }
    from 'ng/modules/update/components/system-update/system-update.component';

import {
    SegUpdatePageComponent,
} from 'ng/modules/update/components/seg-update-page/seg-update-page.component';

import {
    GslbSitesListPageComponent,
} from 'router-components/gslb-sites-list-page/gslb-sites-list-page.component';

import {
    GslbSubdomainsListPageComponent,
} from 'router-components/gslb-subdomains-list-page';

import { GSLB } from 'items/gslb.item.factory';
import * as globalL10n from 'global-l10n';

import * as l10n from '../core.l10n';

const { ENGLISH, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

// common params for list pages
const
    listParams = '?timeframe',
    commonLogParams = [//alerts, events and logs
        'timeframe',
        'orderby',
    ],
    logParams = commonLogParams.concat([
        'start',
        'end',
        'asc',
        'search',
        'page_size',
        'filter',
    ]),
    eventParams = commonLogParams.concat([
        'start',
        'end',
        'search',
        'inclInternal',
        'inclSystemEvents',
    ]);

const infrastructureDetailParams = {
    cloudId: {
        value: null,
        dynamic: true,
    },
};

const logParamsObject = {};

logParams.forEach(param => logParamsObject[param] = { dynamic: true });

// State configs
const logListStateConfig = {
    controller: 'LogListController',
    data: {
        tfGroup: 'logs',
        hasSidebar: true,
        showTimeframe: true,
    },
    name: 'logs',
    params: logParamsObject,
    templateUrl: 'src/views/application/log-list.html',
    title: globalL10nKeys.logsLabel,
    url: `/log?${logParams.join('&')}`,
};

const eventListStateConfig = {
    name: 'events',
    url: `/event?${eventParams.join('&')}`,
    template: '<events-page></events-page>',
    title: globalL10nKeys.eventsLabel,
    data: {
        tfGroup: 'logs',
        hasSidebar: true,
        showTimeframe: true,
    },
    params: {},
};

eventParams.forEach(param => eventListStateConfig.params[param] = { dynamic: true });

const getEventListStateConfig = params => angular.merge({}, eventListStateConfig, params);

const alertListStateConfig = {
    name: 'alerts',
    url: `/alerts?${commonLogParams.join('&')}`,
    template: '<alerts-page></alerts-page>',
    title: globalL10nKeys.alertsLabel,
    data: {
        tfGroup: 'logs',
        showTimeframe: true,
        permission: 'PERMISSION_ALERT',
    },
    params: {},
};

commonLogParams.forEach(param => alertListStateConfig.params[param] = { dynamic: true });

const getAlertListStateConfig = params => angular.merge({}, alertListStateConfig, params);

const insightsStateConfig = {
    name: 'hs-insights',
    url: '/hs-insights',
    defaultChild: 'default',
    abstract: true,
    templateUrl: 'src/views/details-leaf-page-template.html',
    data: {
        tfGroup: 'insights',
        metricsValueSelector: false,
        timeFrameSelector: false,
    },
    children: [{
        name: 'default',
        url: `${listParams}`,
        views: {
            nav: {
                template: '<header></header>',
            },
            '': {
                component: 'hsInsights',
            },
        },
    }],
};

const getInsightsStateConfig = params => angular.merge({}, insightsStateConfig, params);

const trafficCaptureConfig = {
    name: 'se-traffic-captue',
    url: '/se-traffic-captue',
    defaultChild: 'default',
    abstract: true,
    templateUrl: 'src/views/details-leaf-page-template.html',
    children: [{
        name: 'default',
        url: `${listParams}`,
        views: {
            nav: {
                template: '<header></header>',
            },
            '': {
                component: TrafficCapturePageComponent,
            },
        },
    }],
};

const getTrafficCaptureConfig = params => angular.merge({}, trafficCaptureConfig, params);

const insightsStateConfigForVS = {
    name: 'hs-insights',
    url: `/hs-insights${listParams}`,
    title: globalL10nKeys.healthLabel,
    component: 'hsInsights',
    data: {
        tfGroup: 'insights',
    },
};

const loadedSystemInfoService = {
    token: 'loadedSystemInfoService',
    resolveFn: systemInfo => systemInfo.load().then(() => systemInfo),
    deps: ['systemInfoService'],
};

const loadedPulseRegistrationStatus = {
    token: 'loadedPulseRegistrationStatus',
    resolveFn: cportalService => cportalService.loadRegistrationStatus().then(() => cportalService),
    deps: ['CportalService'],
};

const resolveVS = {
    token: 'resolveVS',
    resolveFn: (loadItem, { vsId }, Class) => {
        const args = {
            id: vsId,
            fields: [
                'config',
                'health',
                'alert',
                'faults',
            ],
        };

        return loadItem(args, Class);
    },
    deps: ['loadItem', '$stateParams', 'VirtualService'],
};

const resolveWafPolicy = {
    token: 'resolveWafPolicy',
    resolveFn: (loadItem, WafPolicy, stringService, virtualService) => {
        const wafPolicyRef = virtualService.getWafPolicyRef();

        return loadItem({ id: stringService.slug(wafPolicyRef) }, WafPolicy);
    },
    deps: ['loadItem', 'WafPolicyItem', 'stringService', 'resolveVS'],
};

const resolvePool = {
    token: 'resolvePool',
    resolveFn: (loadItem, { poolId }, Class) => {
        const args = {
            id: poolId,
            fields: [
                'config',
                'health',
                'alert',
                'faults',
            ],
        };

        return loadItem(args, Class);
    },
    deps: ['loadItem', '$stateParams', 'Pool'],
};

const resolvePoolServer = {
    token: 'resolvePoolServer',
    resolveFn: (loadPoolServer, { poolId, serverId }, pool) => loadPoolServer({
        poolId,
        serverId,
        pool,
    }),
    deps: ['loadPoolServer', '$stateParams', 'resolvePool'],
};

const resolveCloud = {
    token: 'resolveCloud',
    resolveFn: (loadCloud, { cloudId }) => loadCloud(cloudId),
    deps: ['loadCloud', '$stateParams'],
};

/**
 * If appropriate permissions are set, then gslb instance will be loaded.
 * Otherwise null is returned, and child handles the condition.
 */
const resolveGslb = {
    token: 'resolveGslb',
    resolveFn: (Auth, loadItem, loadedSystemInfoService, GSLB) => {
        const { gslbId } = loadedSystemInfoService;

        if (Auth.isAllowed('gslb') && gslbId) {
            return loadItem(gslbId, GSLB);
        } else {
            return null;
        }
    },
    deps: ['Auth', 'loadItem', 'loadedSystemInfoService', GSLB],
};

const resolveGslbService = {
    token: 'resolveGslbService',
    resolveFn: (loadItem, { gslbServiceId }, gslb, GSLBService) => {
        const params = { id: gslbServiceId };

        if (gslb) {
            params.gslb = gslb;
        }

        return loadItem(params, GSLBService);
    },
    deps: ['loadItem', '$stateParams', 'resolveGslb', 'GSLBService'],
};

const loadedSystemConfigService = {
    token: 'loadedSystemConfigService',
    resolveFn: (Auth, systemConfigService, devLoggerService) => {
        const isSystemConfigAllowed = Auth.isAllowed('systemconfiguration', 'w');

        if (isSystemConfigAllowed) {
            return systemConfigService.load()
                .then(() => systemConfigService)
                .catch(error => {
                    devLoggerService.error(error);
                });
        }
    },
    deps: ['Auth', 'systemConfigService', 'devLoggerService'],
};

const resolveSE = {
    token: 'resolveSE',
    resolveFn: (loadItem, { seId }, Class) => loadItem(seId, Class),
    deps: ['loadItem', '$stateParams', 'ServiceEngine'],
};

const resolveIPReputationDB = {
    token: 'resolveIPReputationDB',
    resolveFn: (defaultValues, loadItem, IPReputationDB) => {
        const ipReputationDBRef = defaultValues.getSystemObjectRefByName(
            'ipreputationdb',
            'System-IPReputation-Webroot-DB',
        );

        return loadItem(ipReputationDBRef.slug(), IPReputationDB);
    },
    // loadedDefaultValues is injected as a dep in order to load it. The resolved value is not
    // actually used.
    deps: ['defaultValues', 'loadItem', 'IPReputationDB', 'loadedDefaultValues'],
};

const resolveClusterInfo = {
    token: 'resolveClusterInfo',
    resolveFn: (loadItem, Cluster) => {
        return loadItem({ id: 'default' }, Cluster);
    },
    deps: ['loadItem', 'Cluster'],
};

const resolveScheduler = {
    token: 'resolveScheduler',
    resolveFn: (defaultValues, loadItem, Scheduler) => {
        const schedulerRef = defaultValues.getSystemObjectRefByName(
            'scheduler',
            'Default-Scheduler',
        );

        return loadItem(schedulerRef.slug(), Scheduler);
    },
    // loadedDefaultValues is injected as a dep in order to load it. The resolved value is not
    // actually used.
    deps: ['defaultValues', 'loadItem', 'Scheduler', 'loadedDefaultValues'],
};

const resolveApplicationSignatureProvider = {
    token: 'resolveApplicationSignatureProvider',
    resolveFn: (defaultValues, loadItem, WafApplicationSignatureProvider, stringService) => {
        const wafApplicationSignatureProviderRef = defaultValues.getSystemObjectRefByName(
            'wafapplicationsignatureprovider',
            'System-WafApplicationSignatures-Trustwave',
        );

        return loadItem(
            stringService.slug(wafApplicationSignatureProviderRef),
            WafApplicationSignatureProvider,
        );
    },
    deps: [
        'defaultValues',
        'loadItem',
        'WafApplicationSignatureProvider',
        'stringService',
        'loadedDefaultValues',
    ],
};

/**
 * @typedef {Object} AppStateData
 * @property {string?} permission
 * @property {boolean?} showTimeframe
 * @property {boolean?} metricsControls
 * @property {boolean?} hasSidebar
 * @property {boolean?} inTenantScope True when state is an Object detail state
 */

/**
 * @typedef {Object} AppState
 * @property {string} name
 * @property {string?} template
 * @property {string?} templateUrl
 * @property {string?} controller
 * @property {string?} component
 * @property {Object?} params
 * @property {string?} title
 * @property {string?} defaultChild
 * @property {boolean?} abstract
 * @property {string} url
 * @property {AppStateData?} data
 * @property {Object[]?} resolve
 * @property {AppState[]?} children
*/

/**
 * Application states list.
 * Used to generated states and privilege map.
 * @type {AppState[]}
 */
const stateTree = [{
    name: 'authenticated.application',
    title: l10nKeys.applicationsTabLabel,
    abstract: true,
    template: '<div ui-view class="application"></div>',
    defaultChild: 'dashboard',
    url: '/applications',
    data: {
        permission: 'UI_APPLICATION',
    },
    resolve: [
        angular.extend(
            {},
            loadedSystemInfoService,
            { policy: { async: 'NOWAIT' } },
        ),
    ],
    children: [{
        name: 'dashboard',
        url: '/dashboard?timeframe',
        icon: 'dashboard',
        activeStates: ['**.dashboard'],
        title: l10nKeys.dashboardLabel,
        controller: 'AppDashboardController',
        template: require('../../../views/application/dashboard.partial.html'),
    }, {
        name: 'virtualservice-list',
        url: `/virtualservice${listParams}`,
        icon: 'vmw-app',
        activeStates: [
            '**.virtualservice-list',
            '**.virtualservice-detail.*',
            '**.virtualservice-detail.hs-insights.*',
        ],
        title: globalL10nKeys.virtualServicesLabel,
        controller: 'VirtualServiceListController',
        templateUrl: 'src/components/applications/virtualservice/virtual-service-list/' +
            'virtualservice-list.html',
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
        },
    }, {
        name: 'virtualservice-detail',
        abstract: true,
        url: '/virtualservice/:vsId',
        component: 'virtualserviceDetail',
        defaultChild: 'analytics',
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
            inTenantScope: true,
        },
        resolve: [
            resolveVS,
        ],
        children: [
            {
                name: 'analytics',
                url: '/analytics?metric&timeframe',
                templateUrl: 'src/views/application/vs-analytics.html',
                controller: 'VirtualServiceAnalyticsController',
                title: globalL10nKeys.analyticsLabel,
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
                resolve: [
                    loadedSystemInfoService,
                ],
            },
            {
                // component: VsLogsPageComponent, // TODO Disable if using below
                // TODO Re-enable below if want old-logs & tech-preview
                // TODO AV-171346 Delete if removing old logs and tech-preview
                component: 'vsLogsWrapper',
                data: {
                    tfGroup: 'logs',
                    hasSidebar: true,
                    // TODO Re-enable below if want old-logs & tech-preview
                    // TODO AV-171346 Delete if removing old logs and tech-preview
                    // myAccountRollbackVar: SHOW_NEW_VS_LOGS_PAGE,
                    showTimeframe: true,
                },
                name: 'vs-logs',
                params: {
                    ...logParamsObject,
                    initFilterParams: {},
                },
                title: globalL10nKeys.logsLabel,
                url: `/logs?${logParams.join('&')}`,
            }, { // TODO: AV-171346 Remove this object for 30.2.1 release
                controller: 'LogListController',
                data: {
                    tfGroup: 'logs',
                    hasSidebar: true,
                    showTimeframe: true,
                },
                name: 'old-logs',
                params: logParamsObject,
                templateUrl: 'src/views/application/log-list.html',
                url: `/old-logs?${logParams.join('&')}`,
            },
            angular.copy(insightsStateConfigForVS),
            {
                name: 'clientinsight',
                url: '/clientinsight?timeframe',
                templateUrl: 'src/views/application/clientinsight.html',
                title: l10nKeys.clientsLabel,
                data: {
                    showTimeframe: true,
                },
            }, {
                name: 'security',
                url: '/security?timeframe',
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
                templateUrl: 'src/views/application/virtualservice-security.html',
                title: l10nKeys.securityLabel,
            },
            getEventListStateConfig(),
            getAlertListStateConfig(),
            {
                name: 'dns-records',
                url: '/dns-records?timeframe',
                component: DnsRecordsPageComponent,
                title: globalL10nKeys.dnsLabel,
                data: {
                    showTimeframe: true,
                },
            }, {
                name: 'WAF',
                url: `/WAF${listParams}`,
                component: 'vsWafPage',
                title: 'WAF',
                data: {
                    showTimeframe: true,
                    hasSidebar: true,
                },
                resolve: [
                    resolveWafPolicy,
                ],
            },
        ],
    }, {
        name: 'vs-vip-list',
        url: `/vs-vip-list${listParams}`,
        icon: 'map-marker',
        title: 'VS VIPs',
        component: VsVipListPageComponent,
        data: {
            permission: 'PERMISSION_VIRTUALSERVICE',
        },
    }, {
        name: 'pool-list',
        url: `/pool${listParams}`,
        icon: 'network-switch',
        activeStates: ['**.pool-list', '**.pool-detail.**'],
        title: l10nKeys.poolsLabel,
        templateUrl: 'src/components/applications/pool/pool-list/pool-list.html',
        data: {
            permission: 'PERMISSION_POOL',
        },
        controller: 'PoolListController',
    }, {
        name: 'pool-detail',
        url: '/pool/:poolId/:vsId',
        template: '<div ui-view></div>',
        abstract: true,
        controller: 'PoolDetailController',
        defaultChild: 'analytics',
        data: {
            permission: 'PERMISSION_POOL',
            inTenantScope: true,
        },
        resolve: [
            resolvePool,
            resolveVS,
        ],
        children: [
            {
                name: 'analytics',
                url: `/analytics?${['metric', 'timeframe'].join('&')}`,
                controller: 'PoolAnalyticsController',
                templateUrl: 'src/views/application/pool-analytics.html',
                resolve: [
                    loadedSystemInfoService,
                ],
            },
            getInsightsStateConfig({
                data: {
                    permission: 'PERMISSION_POOL',
                },
            }),
            getEventListStateConfig({
                template: '<events-page><header></header></events-page>',
            }),
            getAlertListStateConfig({
                template: '<alerts-page><header></header></alerts-page>',
            }),
            angular.copy(logListStateConfig),
            {
                name: 'servers',
                url: '/servers?timeframe',
                templateUrl: 'src/views/application/pool-servers-list.html',
                controller: 'PoolServerListController',
            }, {
                name: 'server-detail',
                url: '/server/:serverId',
                template: '<div ui-view></div>',
                abstract: true,
                defaultChild: 'analytics',
                data: {
                    permission: 'PERMISSION_POOL',
                    inTenantScope: true,
                },
                resolve: [
                    resolvePoolServer,
                ],
                children: [{
                    name: 'analytics',
                    url: '/analytics?timeframe',
                    templateUrl: 'src/components/applications/server-analytics/' +
                        'server-analytics.html',
                    controller: 'ServerAnalyticsController',
                    resolve: [
                        loadedSystemInfoService,
                    ],
                }],
            },
        ],
    }, {
        name: 'pool-group-list',
        url: `/pool-group${listParams}`,
        icon: 'rack-server',
        title: l10nKeys.poolGroupsLabel,
        component: PoolGroupListPageComponent,
        data: {
            permission: 'PERMISSION_POOLGROUP',
        },
    }, {
        name: 'gslbservice-list',
        url: `/gslbservice-list${listParams}`,
        icon: 'network-globe',
        activeStates: ['**.gslbservice-list', '**.gslbservice-detail.**'],
        title: l10nKeys.gslbServicesLabel,
        component: 'gslbServiceList',
        data: {
            permission: 'PERMISSION_GSLBSERVICE',
        },
        resolve: [
            resolveGslb,
            loadedSystemInfoService,
        ],
    }, {
        name: 'gslbservice-detail',
        url: '/gslbservice/:gslbServiceId',
        component: 'gslbServiceDetail',
        abstract: true,
        data: {
            permission: 'PERMISSION_GSLBSERVICE',
            inTenantScope: true,
        },
        resolve: [
            resolveGslbService,
            resolveGslb,
        ],
        children: [{
            name: 'members',
            url: '/members',
            component: 'gslbServiceMemberList',
            title: l10nKeys.membersStatusLabel,
        }, {
            name: 'fqdn-list',
            url: '/fqdn-list',
            component: 'gslbServiceDetailFqdnList',
            title: l10nKeys.fqdnInsightsLabel,
            data: {
                showTimeframe: true,
                permission: 'PERMISSION_GSLB',
            },
        },
        getEventListStateConfig(),
        ],
    }],
}, {
    name: 'authenticated.operations',
    url: '/operations',
    title: l10nKeys.operationsTabLabel,
    template: '<div ui-view class="avi-state-view operations"></div>',
    abstract: true,
    defaultChild: 'alerts.allalerts-list',
    data: {
        permission: 'UI_OPERATIONS',
    },
    children: [{
        name: 'alerts',
        url: '/alerts',
        icon: 'bubble-exclamation',
        title: globalL10nKeys.alertsLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        data: {
            permission: 'UI_OPERATIONS_ALERTS',
        },
        defaultChild: 'allalerts-list',
        children: [
            getAlertListStateConfig({
                name: 'allalerts-list',
                title: l10nKeys.allAlertsLabel,
                url: `/allalerts-list?${commonLogParams.join('&')}`,
                data: {
                    permission: 'PERMISSION_ALERT',
                },
            }),
            {
                name: 'alertconfig-list',
                title: l10nKeys.alertConfigLabel,
                url: '/alertconfig',
                component: AlertConfigListPageComponent,
                data: {
                    permission: 'PERMISSION_ALERTCONFIG',
                },
            }, {
                name: 'alertactions-list',
                title: l10nKeys.alertActionsLabel,
                url: '/alertactions',
                component: AlertActionsListPageComponent,
                data: {
                    permission: 'PERMISSION_ALERT',
                },
            }],
    }, {
        name: 'events',
        url: '/events',
        icon: 'event',
        title: globalL10nKeys.eventsLabel,
        template: '<div ui-view></div>',
        defaultChild: 'events-list',
        abstract: true,
        data: {
            tfGroup: 'logs',
            showTimeframe: true,
        },
        children: [
            getEventListStateConfig({
                name: 'events-list',
                title: l10nKeys.allEventsLabel,
                url: `/events-list?${eventParams.join('&')}`,
            }),
            getEventListStateConfig({
                name: 'configuration',
                title: l10nKeys.configAuditTrailLabel,
                url: `/configuration?${eventParams.join('&')}`,
            }),
        ],
    }, {
        name: 'services',
        url: '/services',
        icon: 'bell',
        title: l10nKeys.notificationsLabel,
        template: '<div ui-view></div>',
        defaultChild: 'syslog-list',
        abstract: true,
        data: {
            permission: 'UI_OPERATIONS_SERVICES',
        },
        children: [{
            name: 'syslog-list',
            title: globalL10nKeys.syslogLabel,
            url: '/syslog',
            templateUrl: 'src/views/operations/syslog-list.html',
            data: {
                permission: 'PERMISSION_ALERTSYSLOGCONFIG',
            },
        }, {
            name: 'email-list',
            title: globalL10nKeys.emailLabel,
            url: '/email',
            templateUrl: 'src/views/operations/email-list.html',
            data: {
                permission: 'PERMISSION_ALERTEMAILCONFIG',
            },
        }, {
            name: 'snmp-list',
            title: l10nKeys.snmpTrapLabel,
            url: '/snmp',
            templateUrl: 'src/views/operations/snmp-list.html',
            data: {
                permission: 'PERMISSION_SNMPTRAPPROFILE',
            },
        }],
    }, {
        name: 'traffic-capture',
        icon: 'cloud-traffic',
        template: '<div ui-view class="avi-state-view"></div>',
        title: l10nKeys.trafficCaptureLabel,
        defaultChild: 'traffic-capture',
        abstract: true,
        data: {
            permission: 'UI_TRAFFIC_CAPTURE',
        },
        children: [{
            name: 'traffic-capture',
            title: l10nKeys.trafficCaptureLabel,
            url: '/traffic-capture',
            templateUrl: 'src/views/operations/traffic-capture.html',
            data: {
                permission: 'PERMISSION_TRAFFIC_CAPTURE',
            },
        }],
    }],
}, {
    name: 'authenticated.profile',
    url: '/template',
    title: l10nKeys.templatesTabLabel,
    template: '<div ui-view class="avi-state-view"</div>',
    abstract: true,
    defaultChild: 'profiles.applicationprofile-list',
    resolve: [
        //to show/hide GSLB monitors tab
        angular.extend(
            {},
            loadedSystemInfoService,
            { policy: { async: 'NOWAIT' } },
        ),
    ],
    data: {
        permission: 'UI_TEMPLATE',
    },
    children: [{
        name: 'profiles',
        url: '/profile',
        icon: 'file-settings',
        title: l10nKeys.profilesLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'applicationprofile-list',
        data: {
            permission: 'UI_PROFILE',
        },
        children: [{
            name: 'applicationprofile-list',
            title: l10nKeys.applicationLabel,
            url: '/applicationprofile',
            component: ApplicationProfileListPageComponent,
            data: {
                permission: 'PERMISSION_APPLICATIONPROFILE',
            },
        }, {
            name: 'networkprofile-list',
            title: globalL10nKeys.networkLabel,
            url: '/networkprofile',
            component: NetworkProfilesListPageComponent,
            data: {
                permission: 'PERMISSION_NETWORKPROFILE',
            },
        }, {
            name: 'persistenceprofile-list',
            title: l10nKeys.persistenceLabel,
            url: '/persistenceprofile',
            component: PersistenceProfileListPageComponent,
            data: {
                permission: 'PERMISSION_APPLICATIONPERSISTENCEPROFILE',
            },
        }, {
            name: 'healthmonitor-list',
            title: l10nKeys.healthMonitorsLabel,
            url: '/healthmonitor',
            component: HealthMonitorListPageComponent,
            data: {
                permission: 'PERMISSION_HEALTHMONITOR',
            },
            resolve: [
                loadedSystemInfoService,
            ],
        }, {
            name: 'analyticsprofile-list',
            title: globalL10nKeys.analyticsLabel,
            url: '/analyticsprofile',
            component: AnalyticsProfileListPageComponent,
            data: {
                permission: 'PERMISSION_ANALYTICSPROFILE',
            },
        }, {
            name: 'ipam-profile',
            title: l10nKeys.ipamDnsProfilesLabel,
            url: '/ipam-profile',
            component: IpamDnsProfileListPageComponent,
            data: {
                permission: 'PERMISSION_IPAMDNSPROVIDERPROFILE',
            },
        }, {
            name: 'custom-ipam-dns',
            title: l10nKeys.customIpamDnsLabel,
            url: '/custom-ipam-dns',
            component: CustomIpamDnsProfileListPageComponent,
            data: {
                permission: 'PERMISSION_CUSTOMIPAMDNSPROFILE',
            },
        }, {
            name: 'traffic-clone-profile',
            title: l10nKeys.trafficCloneLabel,
            url: '/traffic-clone-profile',
            component: TrafficCloneProfilePageComponent,
            data: {
                permission: 'PERMISSION_TRAFFICCLONEPROFILE',
            },
        }, {
            name: 'icap-profile',
            title: l10nKeys.icapProfileLabel,
            url: '/icap-profile',
            component: IcapProfileListPageComponent,
            data: {
                permission: 'PERMISSION_ICAP',
            },
        }],
    }, {
        name: 'policy',
        url: '/policy',
        icon: 'list',
        title: l10nKeys.policiesLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'nat-policy-list',
        data: {
            permission: 'UI_POLICIES',
        },
        children: [{
            name: 'nat-policy-list',
            title: l10nKeys.natPolicyLabel,
            url: '/natpolicy',
            component: NatPolicyListPageComponent,
            data: {
                permission: 'PERMISSION_NATPOLICY',
            },
        }, {
            name: 'l4-policy-set-list',
            title: 'L4 Policy Set',
            url: '/l4policyset',
            component: L4PolicyComponent,
            data: {
                permission: 'PERMISSION_L4POLICYSET',
            },
        }],
    }, {
        name: 'group',
        url: '/group',
        icon: 'file-group',
        title: l10nKeys.groupsLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'ipaddrgroup-list',
        data: {
            permission: 'UI_GROUPS',
        },
        children: [{
            name: 'ipaddrgroup-list',
            title: l10nKeys.ipGroupsLabel,
            url: '/ipaddrgroup',
            component: IpAddrGroupListPageComponent,
            data: {
                permission: 'PERMISSION_IPADDRGROUP',
            },
        }, {
            name: 'stringgroup-list',
            title: l10nKeys.stringGroupsLabel,
            url: '/stringgroup',
            component: StringGroupListComponent,
            data: {
                permission: 'PERMISSION_STRINGGROUP',
            },
        }],
    }, {
        name: 'ssl',
        url: '/ssl',
        icon: 'shield-check',
        title: l10nKeys.securityLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'certificate-list',
        data: {
            permission: 'UI_SECURITY',
        },
        children: [{
            name: 'certificate-list',
            title: l10nKeys.sslTlsCertificatesLabel,
            url: '/certificate',
            component: SslCertificateListComponent,
            data: {
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            },
        }, {
            name: 'sslprofile-list',
            title: l10nKeys.sslTlsProfileLabel,
            url: '/sslprofile',
            component: SslProfileListComponent,
            data: {
                permission: 'PERMISSION_SSLPROFILE',
            },
        }, {
            name: 'pkiprofile-list',
            title: l10nKeys.pkiProfileLabel,
            url: '/pkiprofile',
            component: PkiProfileListPageComponent,
            data: {
                permission: 'PERMISSION_PKIPROFILE',
            },
        }, {
            name: 'authprofile-list',
            title: l10nKeys.authProfileLabel,
            url: '/authprofile',
            component: AuthProfileListPageComponent,
            data: {
                permission: 'PERMISSION_AUTHPROFILE',
            },
        }, {
            name: 'authmappingprofile-list',
            title: l10nKeys.authMappingProfileLabel,
            url: '/authmappingprofile',
            component: AuthMappingProfilePageComponent,
            data: {
                permission: 'PERMISSION_AUTHMAPPINGPROFILE',
            },
        }, {
            name: 'jwt-server-profile-list',
            title: l10nKeys.jwtServerProfileLabel,
            url: '/jwtserverprofile',
            component: JwtServerProfileComponent,
            data: {
                permission: 'PERMISSION_JWTSERVERPROFILE',
            },
        }, {
            name: 'certificate-management-list',
            title: l10nKeys.certificateManagementLabel,
            url: '/certificate-management',
            component: CertificateManagementListPageComponent,
            data: {
                permission: 'PERMISSION_CERTIFICATEMANAGEMENTPROFILE',
            },
        }, {
            name: 'ip-reputation-list',
            title: globalL10nKeys.ipReputationLabel,
            url: '/ip-reputation',
            component: IPReputationDBListPageContainerComponent,
            data: {
                permission: 'PERMISSION_IPREPUTATIONDB',
            },
            resolve: [
                loadedPulseRegistrationStatus,
                resolveIPReputationDB,
            ],
        }, {
            name: 'geo-db-list',
            title: globalL10nKeys.geoDbLabel,
            url: '/geodb',
            component: GeoDbListComponent,
            data: {
                permission: 'PERMISSION_GEODB',
            },
        }, {
            name: 'hsmgroup-list',
            title: l10nKeys.hsmGroupsLabel,
            url: '/hsmgroup',
            component: HsmGroupListComponent,
            data: {
                permission: 'PERMISSION_SSLKEYANDCERTIFICATE',
            },
        }, {
            name: 'sso-policy-list',
            title: l10nKeys.ssoPolicyLabel,
            url: '/ssopolicy',
            component: SSOPolicyListComponent,
            data: {
                permission: 'PERMISSION_SSOPOLICY',
            },
        }, {
            name: 'csrf-policy-list',
            title: l10nKeys.csrfPolicyPageLabel,
            url: '/csrfpolicy',
            component: CSRFPolicyListPageComponent,
            data: {
                permission: 'PERMISSION_CSRFPOLICY',
            },
        }],
    }, {
        name: 'scripts',
        url: '/scripts',
        icon: 'code',
        title: l10nKeys.scriptsLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'datascriptset-list',
        data: {
            permission: 'UI_SCRIPTS',
        },
        children: [{
            name: 'datascriptset-list',
            title: l10nKeys.dataScriptsLabel,
            url: '/datascripts',
            component: DataScriptComponent,
            data: {
                permission: 'PERMISSION_VSDATASCRIPTSET',
            },
        }, {
            name: 'controlscripts-list',
            title: l10nKeys.controlScriptsLabel,
            url: '/controlscripts',
            component: ControlScriptComponent,
            data: {
                permission: 'PERMISSION_VSDATASCRIPTSET',
            },
        }, {
            name: 'protocol-parser-scripts-list',
            title: l10nKeys.protocolParserScriptsLabel,
            url: '/protocolparserscripts',
            component: ProtocolParserScriptComponent,
            data: {
                permission: 'PERMISSION_PROTOCOLPARSER',
            },
        }],
    }, {
        name: 'autoscale',
        url: '/autoscale',
        icon: 'cloud-scale',
        title: l10nKeys.autoScaleLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'server-autoscale',
        data: {
            permission: 'PERMISSION_AUTOSCALE',
        },
        children: [{
            name: 'server-autoscale',
            title: l10nKeys.serverAutoScaleLabel,
            url: '/server-autoscale',
            component: ServerAutoScalePolicyListPageComponent,
        }, {
            name: 'launch-config',
            title: l10nKeys.launchConfigLabel,
            url: '/launch-config',
            component: LaunchConfigListPageComponent,
        }, {
            name: 'webhook',
            title: l10nKeys.webhookLabel,
            url: '/webhook',
            component: WebhookListPageComponent,
        }, {
            name: 'pool-group-deployment-policy',
            title: l10nKeys.poolGroupDeploymentLabel,
            url: '/pool-group-deployment-policy',
            component: PoolGroupDeploymentPolicyListPageComponent,
        }],
    }, {
        name: 'waf',
        url: '/waf',
        icon: 'firewall',
        title: 'WAF',
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'waf-profile',
        data: {
            permission: 'UI_WAF',
        },
        children: [{
            name: 'waf-policy',
            title: globalL10nKeys.wafPolicyLabel,
            url: '/waf-policy',
            component: WafPolicyListPageComponent,
            data: {
                permission: 'PERMISSION_WAFPOLICY',
            },
        }, {
            name: 'waf-profile',
            title: globalL10nKeys.wafProfileLabel,
            url: '/waf-profile',
            component: WafProfileListComponent,
            data: {
                permission: 'PERMISSION_WAFPROFILE',
            },
        }, {
            name: 'application-rules',
            title: l10nKeys.applicationRulesLabel,
            url: '/application-rules',
            component: WafApplicationRulesListComponent,
            data: {
                permission: 'PERMISSION_WAFPOLICY',
            },
            resolve: [
                resolveApplicationSignatureProvider,
            ],
        }, {
            name: 'psm',
            title: l10nKeys.positiveSecurityLabel,
            url: '/psm',
            component: 'wafPolicyPsmGroupListPage',
            data: {
                permission: 'PERMISSION_WAFPOLICYPSMGROUP',
            },
        }, {
            name: 'waf-crs',
            title: 'CRS',
            url: '/waf-crs',
            component: 'wafCrsList',
            data: {
                permission: 'PERMISSION_WAFCRS',
            },
        }],
    }, {
        name: 'bot',
        url: '/bot',
        icon: 'bot',
        title: 'Bot',
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'bot-management-policy',
        data: {
            permission: 'PERMISSION_BOTDETECTIONPOLICY',
        },
        children: [{
            name: 'bot-management-policy-list',
            title: l10nKeys.botManagementPolicyListPageLabel,
            url: '/bot-management-policy',
            component: BotManagementPolicyListComponent,
            data: {
                permission: 'PERMISSION_BOTDETECTIONPOLICY',
            },
        }, {
            name: 'bot-mapping',
            title: l10nKeys.botMappingListPageLabel,
            url: '/bot-mapping',
            component: BotMappingPageComponent,
            data: {
                permission: 'PERMISSION_BOTDETECTIONPOLICY',
            },
        }],
    }, {
        name: 'error-page',
        url: '/error-page',
        icon: 'exclamation-triangle',
        title: l10nKeys.errorPageLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'error-page-profile',
        data: {
            permission: 'UI_ERRORPAGE',
        },
        children: [{
            name: 'error-page-profile',
            title: l10nKeys.errorPageProfileLabel,
            url: '/error-page-profile',
            component: ErrorPageProfileListComponent,
            data: {
                permission: 'PERMISSION_ERRORPAGEPROFILE',
            },
        }, {
            name: 'error-page-body',
            title: l10nKeys.errorPageBodyLabel,
            url: '/error-page-body',
            component: ErrorPageBodyComponent,
            data: {
                permission: 'PERMISSION_ERRORPAGEBODY',
            },
        }],
    }],
}, {
    name: 'authenticated.infrastructure',
    url: '/infrastructure',
    title: l10nKeys.infrastructureTabLabel,
    template: '<div ui-view class="infrastructure"></div>',
    abstract: true,
    defaultChild: 'dashboard',
    data: {
        permission: 'UI_INFRASTRUCTURE',
    },
    children: [{
        name: 'dashboard',
        url: '/dashboard?timeframe',
        icon: 'dashboard',
        title: l10nKeys.dashboardLabel,
        templateUrl: 'src/views/infrastructure/dashboard.html',
        controller: 'InfraDashboardController',
        controllerAs: 'infraDash',
        data: {
            permission: 'PERMISSION_SERVICEENGINE',
        },
    }, {
        name: 'cloud-list',
        url: `/cloud${listParams}`,
        icon: 'cloud',
        title: l10nKeys.cloudsLabel,
        controller: 'CloudListController',
        templateUrl: 'src/components/infrastructure/cloud-list/cloud-list.html',
        data: {
            permission: 'PERMISSION_CLOUD',
        },
        resolve: [
            loadedSystemInfoService,
        ],
    }, {
        name: 'infrastructure-detail',
        component: 'infrastructureDetail',
        icon: 'resource-pool',
        title: l10nKeys.cloudLabel,
        abstract: true,
        data: {
            permission: 'UI_INFRASTRUCTURE_DETAIL',
            inTenantScope: true,
        },
        params: {
            timeframe: {
                value: null,
            },
        },
        resolve: [
            resolveCloud,
        ],
        defaultChild: 'serviceengine-list',
        children: [{
            name: 'serviceengine-list',
            url: `/serviceengine/{cloudId}${listParams}`,
            activeStates: [
                'authenticated.infrastructure.cloud.serviceengine-detail',
                'authenticated.infrastructure.infrastructure-detail.serviceengine-list',
            ],
            title: globalL10nKeys.serviceEngineLabel,
            component: 'serviceEngineList',
            data: { //displaying: duration and values type
                metricsControls: true,
                permission: 'PERMISSION_SERVICEENGINE',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'segroup-list',
            url: `/segroup/{cloudId}${listParams}`,
            title: globalL10nKeys.serviceEngineGroupLabel,
            component: SEGroupListPageComponent,
            data: {
                permission: 'PERMISSION_SERVICEENGINEGROUP',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'network-list',
            url: `/network/{cloudId}${listParams}`,
            title: l10nKeys.networksLabel,
            component: NetworkListPageComponent,
            data: {
                permission: 'PERMISSION_NETWORK',
            },
            params: infrastructureDetailParams,
        }, {
            name: 'network-service-list',
            url: `/network-service/{cloudId}${listParams}`,
            title: l10nKeys.networkServicesLabel,
            component: 'networkServicesPage',
            data: {
                permission: 'PERMISSION_NETWORKSERVICE',
            },
            params: infrastructureDetailParams,
        },
        {
            name: 'vrf-context',
            title: l10nKeys.vrfContextLabel,
            url: `/vrf-context/{cloudId}${listParams}`,
            component: VrfContextPageComponent,
            params: infrastructureDetailParams,
            data: {
                permission: 'PERMISSION_VRFCONTEXT',
            },
        }],
    }, {
        name: 'cloud',
        url: '/cloud/:cloudId',
        template: '<div ui-view class="cloud"></div>',
        abstract: true,
        data: {
            permission: 'UI_CLOUD',
            inTenantScope: true,
        },
        resolve: [
            angular.extend(
                {},
                resolveCloud,
                { policy: { async: 'NOWAIT' } },
            ),
        ],
        children: [{
            name: 'serviceengine-detail',
            url: '/serviceengine/:seId',
            controller: 'ServiceEngineController',
            template: '<div ui-view class="serviceengine clearfix"></div>',
            abstract: true,
            defaultChild: 'summary',
            data: {
                permission: 'PERMISSION_SERVICEENGINE',
            },
            resolve: [
                resolveSE,
            ],
            children: [
                {
                    name: 'summary',
                    url: '/summary?metric&timeframe',
                    templateUrl: 'src/views/infrastructure/se-analytics.html',
                    controller: 'SEAnalyticsController',
                },
                getInsightsStateConfig({
                    data: {
                        permission: 'PERMISSION_SERVICEENGINE',
                    },
                }),
                getEventListStateConfig({
                    template: '<events-page><header></header></events-page>',
                }),
                getAlertListStateConfig({
                    template: '<alerts-page><header></header></alerts-page>',
                }),
                getTrafficCaptureConfig({
                    data: {
                        permission: 'PERMISSION_TRAFFIC_CAPTURE',
                    },
                }),
                {
                    name: 'bgp-peers',
                    url: '/bgp-peers',
                    template: '<bgp-peers-page><header></header></bgp-peers-page>',
                    title: l10nKeys.bgpPeeringLabel,
                },
            ],
        }],
    }, {
        name: 'gslb',
        url: '/gslb',
        icon: 'network-globe',
        title: 'GSLB',
        template: '<div ui-view class="avi-state-view"></div>',
        abstract: true,
        defaultChild: 'sites',
        resolve: [
            loadedSystemInfoService,
        ],
        data: {
            permission: 'UI_INFRASTRUCTURE_GSLB',
        },
        children: [{
            name: 'sites',
            url: '/sites',
            title: l10nKeys.sitesLabel,
            component: GslbSitesListPageComponent,
            data: {
                permission: 'PERMISSION_GSLB',
            },
            resolve: [
                resolveGslb,
            ],
        }, {
            name: 'subdomains',
            url: '/subdomains',
            title: l10nKeys.subdomainsLabel,
            component: GslbSubdomainsListPageComponent,
            data: {
                permission: 'PERMISSION_GSLB',
            },
            resolve: [
                resolveGslb,
            ],
        }, {
            name: 'geo-profile',
            url: '/geo-profile',
            title: l10nKeys.geoProfileLabel,
            component: GslbGeoProfileListComponent,
            data: {
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        }, {
            name: 'upload-geo-files',
            url: '/upload-geo-files',
            title: l10nKeys.uploadGeoFilesLabel,
            component: 'uploadGeoFilesPage',
            data: {
                permission: 'PERMISSION_GSLBGEODBPROFILE',
            },
        }, {
            name: 'federation-checkpoints',
            url: '/federation-checkpoints',
            title: l10nKeys.federationCheckpointsLabel,
            component: GslbFederationCheckpointsListComponent,
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            resolve: [
                loadedSystemInfoService,
                resolveGslb,
            ],
        }],
    }],
}, {
    name: 'authenticated.administration',
    url: '/administration',
    title: l10nKeys.administrationTabLabel,
    template: '<div ui-view class="avi-state-view administration"></div>',
    abstract: true,
    defaultChild: 'users.user-list',
    data: {
        permission: 'UI_ADMINISTRATION',
    },
    children: [{
        name: 'users',
        abstract: true,
        url: '/users',
        icon: 'employee',
        title: l10nKeys.accountsLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        defaultChild: 'user-list',
        data: {
            permission: 'UI_ACCOUNT',
        },
        children: [{
            name: 'user-list',
            title: l10nKeys.usersLabel,
            url: '/user',
            component: UserPageListComponent,
            data: {
                permission: 'PERMISSION_USER',
            },
        }, {
            name: 'user-profile-list',
            title: l10nKeys.userProfilesLabel,
            url: '/user-profile',
            component: UserProfileListComponent,
            data: {
                permission: 'PERMISSION_USER',
            },
        }, {
            name: 'role-list',
            title: globalL10nKeys.rolesLabel,
            url: '/role',
            component: RoleListPageComponent,
            data: {
                permission: 'PERMISSION_ROLE',
            },
        }, {
            name: 'tenant-list',
            title: l10nKeys.tenantsLabel,
            url: `/tenant${listParams}`,
            templateUrl: 'src/views/administration/tenant-list.html',
            controller: 'TenantListController',
            data: {
                permission: 'PERMISSION_TENANT',
            },
        }, {
            name: 'tenant-settings',
            title: globalL10nKeys.tenantSettingsLabel,
            url: '/tenant-settings',
            component: TenantSettingsPageListComponent,
            data: {
                permission: 'PERMISSION_TENANT_SYSTEM_CONFIGURATION',
                restrictAdminTenant: true,
                restrictAllTenants: true,
            },
        }],
    }, {
        name: 'system-settings',
        url: '/system-settings',
        icon: 'cog',
        title: l10nKeys.systemSettingsLabel,
        component: SystemSettingsPageComponent,
        data: {
            permission: 'PERMISSION_SYSTEMCONFIGURATION',
        },
        resolve: [
            loadedSystemConfigService,
        ],
    }, {
        name: 'cloud-services',
        url: '/cloud-services',
        icon: 'internet-of-things',
        title: l10nKeys.cloudServicesLabel,
        component: CPortalPageComponent,
        resolve: [
            loadedSystemInfoService,
        ],
    }, {
        name: 'licensing',
        url: '/licensing',
        icon: 'certificate',
        title: l10nKeys.licensingLabel,
        component: 'licensingPage',
        data: {
            permission: 'PERMISSION_SYSTEMCONFIGURATION',
        },
        resolve: [
            loadedSystemConfigService,
        ],
    }, {
        name: 'controller-detail',
        abstract: true,
        url: '/controller',
        icon: 'atom',
        title: globalL10nKeys.controllerLabel,
        template: '<div ui-view class="avi-state-view controller-detail"></div>',
        defaultChild: 'cluster',
        data: {
            permission: 'UI_CONTROLLER',
        },
        children: [{
            name: 'cluster',
            title: l10nKeys.nodesLabel,
            url: '/cluster',
            component: NodesPageComponent,
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            resolve: [
                resolveClusterInfo,
            ],
        },
        {
            name: 'software',
            title: l10nKeys.softwareLabel,
            url: '/software',
            component: 'softwarePage',
            data: {
                permission: 'PERMISSION_IMAGE',
            },
        },
        {
            name: 'system-update',
            url: '/system-update',
            title: l10nKeys.systemUpdateLabel,
            component: SystemUpdateComponent,
            data: {
                permission: 'PERMISSION_UPGRADE_OPS',
            },
            resolve: [
                loadedSystemConfigService,
            ],
        },
        {
            name: 'seg-update',
            title: l10nKeys.segUpdateLabel,
            url: '/seg-update',
            component: SegUpdatePageComponent,
            data: {
                permission: 'PERMISSION_UPGRADE_OPS',
            },
        }, {
            name: 'upload-packages',
            title: l10nKeys.uploadHsmPackagesLabel,
            url: '/upload-packages',
            templateUrl: 'src/views/administration/upload-packages.html',
            controller: 'UploadPackagesController',
            controllerAs: '$ctrl',
        }, {
            name: 'backup',
            title: l10nKeys.configurationBackupLabel,
            url: '/backup',
            component: BackupPageComponent,
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            resolve: [
                resolveScheduler,
            ],
        }],
    }, {
        name: 'user-credentials',
        icon: 'id-badge',
        template: '<div ui-view class="avi-state-view"></div>',
        defaultChild: 'user-credentials',
        title: l10nKeys.userCredentialsLabel,
        abstract: true,
        data: {
            permission: 'UI_USER_CREDENTIALS',
        },
        children: [{
            name: 'user-credentials',
            title: l10nKeys.userCredentialsLabel,
            url: '/user-credentials',
            component: UserCredentialsListPageComponent,
            data: {
                permission: 'PERMISSION_USER_CREDENTIAL',
            },
        }],
    }, {
        name: 'support',
        abstract: true,
        url: '/support',
        icon: 'help',
        title: l10nKeys.supportLabel,
        template: '<div ui-view class="avi-state-view"></div>',
        defaultChild: 'cases',
        data: {
            permission: 'UI_SUPPORT',
        },
        children: [{
            name: 'cases',
            title: l10nKeys.casesLabel,
            url: '/cases',
            component: CaseListPageComponent,
            data: {
                permission: 'PERMISSION_CONTROLLER',
            },
            resolve: [
                loadedPulseRegistrationStatus,
            ],
        }, {
            name: 'techsupport',
            title: l10nKeys.techSupportLabel,
            url: '/techsupport',
            component: 'techSupport',
            data: {
                permission: 'PERMISSION_TECHSUPPORT',
            },
            resolve: [
                loadedPulseRegistrationStatus,
            ],
        }, {
            name: 'crashreports',
            title: l10nKeys.crashReportsLabel,
            url: '/crashreports',
            component: CrashReportsPageComponent,
            data: {
                permission: 'PERMISSION_TECHSUPPORT',
            },
        }],
    }, {
        name: 'dashboard',
        url: '/dashboard',
        icon: 'dashboard',
        title: l10nKeys.dashboardLabel,
        component: AdministrationDashboardPageComponent,
        data: {
            permission: 'UI_ADMINISTRATION',
        },
    }],
}];

/**
 * Sets fullName for a state and its children.
 * @param {AppState} state
 * @param {string} parentPath
 * @inner
 */
function setFullName(state, parentPath) {
    const {
        name: stateName,
        children: childStates = [],
    } = state;

    const fullPath = parentPath ? `${parentPath}.${stateName}` : stateName;

    setFullNameProperty(state, fullPath);

    if (childStates.length) {
        childStates.forEach(childState => setFullName(childState, fullPath));
    }
}

/**
 * Sets fullName property in a given state.
 * @param {AppState} state
 * @param {string} fullName
 * @inner
 */
function setFullNameProperty(state, fullName) {
    state.fullName = fullName;
}

stateTree.forEach(state => setFullName(state));

/**
 * @ngdoc constant
 * @name appStateTree
 * @description
 *      Array containing all the static routes with name, url and permission details.
 * @author Aravindh Nagarajan
 */
export const appStateTree = stateTree;
