/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ApplicationProfile } from 'object-types';

import {
    ApplicationProfileType,
    AviPermissionResource,
} from 'generated-types';

import { APPLICATION_PROFILE_ITEM_TOKEN } from '../application-profile.tokens';

/**
 * @description ApplicationProfile collection.
 * @author Nisar Nadaf
 */
export class ApplicationProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'applicationprofile',
            objectType: ApplicationProfile,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_APPLICATIONPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(APPLICATION_PROFILE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            type: ApplicationProfileType.APPLICATION_PROFILE_TYPE_L4,
        };
    }
}

ApplicationProfileCollection.ajsDependencies = [
    APPLICATION_PROFILE_ITEM_TOKEN,
];
