/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VSModule
 * @preferred
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import { VHMatchConfigItem } from 'ajs/modules/vs/factories/vh-match.config-item.factory';
import { VHMatchRuleConfigItem } from 'ajs/modules/vs/factories/vh-match-rule.config-item.factory';

import {
    VH_MATCH_CONFIG_ITEM_TOKEN,
    VH_MATCH_RULE_CONFIG_ITEM_TOKEN,
} from 'ajs/modules/vs/vs.tokens';

import {
    JwtVsValidationConfigOptions,
    LdapVsConfigOptions,
    OauthVsConfigOptions,
    OperStateToTypeMap,
    VipConfigItem,
    vipListPopoverOptions,
    VipPlacementNetworkConfigItem,
    VIP_CONFIG_ITEM_TOKEN,
    VIP_PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN,
    VirtualServiceCollection,
    virtualServiceCreateBasicController,
    virtualServiceCreateCommonController,
    virtualServiceCreateController,
    vsAddressGridCellOptions,
    vsEnhancedVHChildHostsOptions,
    vsListVirtualHostingCellOptions,
    VsVip,
    VsVipCollection,
    VsVipInventoryDataTransformer,
} from '.';

const vsModule = angular.module('avi/vs');

const components = [
    {
        name: 'vsEnhancedVhChildHosts',
        options: vsEnhancedVHChildHostsOptions,
    },
    {
        name: 'vipListPopover',
        options: vipListPopoverOptions,
    },
    {
        name: 'vsAddressGridCell',
        options: vsAddressGridCellOptions,
    },
    {
        name: 'vsListVirtualHostingCell',
        options: vsListVirtualHostingCellOptions,
    },
    {
        name: 'jwtVsValidationConfig',
        options: JwtVsValidationConfigOptions,
    },
    {
        name: 'ldapVsConfig',
        options: LdapVsConfigOptions,
    },
    {
        name: 'oauthVsConfig',
        options: OauthVsConfigOptions,
    },
];

const factories = [
    {
        dependency: VsVipInventoryDataTransformer,
        name: 'VsVipInventoryDataTransformer',
    },
    {
        dependency: VsVip,
        name: 'VsVip',
    },
    {
        dependency: VsVipCollection,
        name: 'VsVipCollection',
    },
    {
        dependency: VirtualServiceCollection,
        name: 'VirtualServiceCollection',
    },
    {
        dependency: VipConfigItem,
        name: VIP_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VipPlacementNetworkConfigItem,
        name: VIP_PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VHMatchConfigItem,
        name: VH_MATCH_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VHMatchRuleConfigItem,
        name: VH_MATCH_RULE_CONFIG_ITEM_TOKEN,
    },
];

const controllers = [
    {
        name: 'VirtualServiceCreateController',
        controller: virtualServiceCreateController,
    },
    {
        name: 'VirtualServiceCreateBasicController',
        controller: virtualServiceCreateBasicController,
    },
    {
        name: 'VirtualServiceCreateCommonController',
        controller: virtualServiceCreateCommonController,
    },
];

const constants = [{
    name: 'OperStateToTypeMap',
    constant: OperStateToTypeMap,
}];

constants.forEach(({ name, constant }) => vsModule.constant(name, constant));

factories.forEach(({ name, dependency }) => {
    initAjsDependency(
        vsModule,
        'factory',
        name,
        dependency,
    );
});

components.forEach(({ name, options }) => vsModule.component(name, options));

controllers.forEach(({ name, controller }) => vsModule.controller(name, controller));
