<avi-radio-container>
    <avi-radio
        name="fail_action"
        [(ngModel)]="emailConfig.smtp_type"
        [value]="SMTP_NONE"
    >
        {{ l10nKeys.noneLabel | vtranslate }}
    </avi-radio>
    <avi-radio
        name="fail_action"
        [(ngModel)]="emailConfig.smtp_type"
        [value]="SMTP_LOCAL_HOST"
    >
        {{ l10nKeys.localHostLabel | vtranslate }}
    </avi-radio>
    <avi-radio
        name="fail_action"
        [(ngModel)]="emailConfig.smtp_type"
        [value]="SMTP_SERVER"
    >
        {{ l10nKeys.smtpServerLabel | vtranslate }}
    </avi-radio>
    <avi-radio
        name="fail_action"
        [(ngModel)]="emailConfig.smtp_type"
        [value]="SMTP_ANONYMOUS_SERVER"
    >
        {{ l10nKeys.anonymousServerLabel | vtranslate }}
    </avi-radio>
</avi-radio-container>

<div [ngSwitch]="emailConfig.smtp_type">
    <welcome-email-localhost-settings
        *ngSwitchCase="SMTP_LOCAL_HOST"
        [emailConfig]="emailConfig"
    ></welcome-email-localhost-settings>

    <welcome-email-smtp-server-settings
        *ngSwitchCase="SMTP_SERVER"
        [emailConfig]="emailConfig"
    ></welcome-email-smtp-server-settings>

    <welcome-email-anonymous-server-settings
        *ngSwitchCase="SMTP_ANONYMOUS_SERVER"
        [emailConfig]="emailConfig"
    ></welcome-email-anonymous-server-settings>
</div>
