/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'in-use-interface-list-dialog';
const prefix = `${moduleName}.${componentName}`;

export const dialogHeader = `${prefix}.dialogHeader`;
export const virtualNetworkInterfacesHeader = `${prefix}.virtualNetworkInterfacesHeader`;
export const vlanInterfacesHeader = `${prefix}.vlanInterfacesHeader`;
export const interfaceColumnHeader = `${prefix}.interfaceColumnHeader`;
export const networkNameColumnHeader = `${prefix}.networkNameColumnHeader`;

export const ENGLISH = {
    [dialogHeader]: 'In-Use Interface List',
    [virtualNetworkInterfacesHeader]: 'Virtual Network Interfaces ({0})',
    [vlanInterfacesHeader]: 'VLAN Interfaces ({0})',
    [interfaceColumnHeader]: 'Interface',
    [networkNameColumnHeader]: 'Network Name',
};
