/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

const TRUE_COLOR = 'var(--cds-alias-status-success)';
const FALSE_COLOR = 'var(--cds-alias-status-disabled)';

/**
 * @description
 * This directive sets the 'color' style property directly on the element based on the booleanColor
 * value. The style is set rather than using class names because there shouldn't be a need to
 * override the style if this directive is applied. trueColor and falseColor bindings can be used
 * for custom colors.
 * @author alextsg
 */
@Directive({
    selector: '[booleanColor]',
})
export class BooleanColorDirective implements OnInit, OnChanges {
    /**
     * Boolean value that the color is based on.
     */
    @Input()
    public booleanColor = false;

    /**
     * When the booleanColor value is true, sets the color to this color. Defaults to success/green.
     */
    @Input()
    public trueColor = TRUE_COLOR;

    /**
     * When the booleanColor value is false, sets the color to this color. Defaults to
     * disabled/gray.
     */
    @Input()
    public falseColor = FALSE_COLOR;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    /**
     * @override
     * Set the color based on the initial value.
     */
    public ngOnInit(): void {
        this.setStyle();
    }

    /**
     * @override
     * Watch for booleanColor changes to set the color dynamically.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const { booleanColor } = changes;

        if (!booleanColor.firstChange) {
            this.setStyle();
        }
    }

    /**
     * Set the color based on the booleanColor value.
     */
    private setStyle(): void {
        const color = this.booleanColor ? this.trueColor : this.falseColor;

        this.renderer.setStyle(this.elementRef.nativeElement, 'color', color);
    }
}
