/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'update-card';
const prefix = `${moduleName}.${componentName}`;

export const inProgressLabel = `${prefix}.inProgressLabel`;
export const viewProgressLabel = `${prefix}.viewProgressLabel`;

export const ENGLISH = {
    [inProgressLabel]: 'In Progress',
    [viewProgressLabel]: 'View Progress',
};
