/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-analytics-controller';
const prefix = `${moduleName}.${componentName}`;

export const virtualServiceLabel = `${prefix}.virtualServiceLabel`;
export const poolMetricsHeader = `${prefix}.poolMetricsHeader`;

export const ENGLISH = {
    [virtualServiceLabel]: 'Virtual Service',
    [poolMetricsHeader]: 'Pool Metrics',
};
