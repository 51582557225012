/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import { pluck } from 'underscore';
import {
    IFault,
    IServerFault,
    TVirtualServiceFaults,
} from '../store/virtual-service.state';

/**
 * Resulting interface after calling parseFault on a faults object.
 */
interface IParsedFault {
    id: string;
    type?: string;
    faults: IServerFault[] | IFault[];
}

/**
 * Transformed server fault after calling groupFaults on a faults object.
 */
export interface IGroupedServerFault {
    poolRef: string;
    faults: string[];
}

/**
 * Transformed faults after calling groupFaults on a faults object.
 */
export interface IVirtualServiceGroupedFaults {
    generic?: string[];
    server?: IGroupedServerFault[];
}

/**
 * ID for server-related faults.
 */
const SERVER_FAULTS = 'server_faults';

/**
 * Return a list of fault descriptions.
 */
const getDescriptions = (faults: IFault[] = []): string[] => pluck(faults, 'description');

/**
 * Return a list of server fault descriptions. Server faults are different from other faults since
 * they are more deeply nested.
 */
const getServerFaultsDescriptions = (serverFaults: IServerFault[] = []): string[] => {
    return serverFaults.reduce((acc, fault) => {
        return [
            ...acc,
            ...getDescriptions(fault.servers),
        ];
    }, []);
};

/**
 * Return an object with a pool_ref and its faults.
 */
const getServerFaultDescriptionsByPool = (
    serverFaults: IServerFault[] = [],
): IGroupedServerFault[] => {
    return serverFaults.map(fault => {
        return {
            poolRef: fault.pool_ref,
            faults: getDescriptions(fault.servers),
        };
    });
};

/**
 * Return an array of all Virtual Service fault descriptions.
 */
const flattenFaults = (faults: IParsedFault[] = []): string[] => {
    return faults.reduce((acc, item) => {
        const descriptions = item.id === SERVER_FAULTS ?
            getServerFaultsDescriptions(item.faults as IServerFault[]) :
            getDescriptions(item.faults as IFault[]);

        return [
            ...acc,
            ...descriptions,
        ];
    }, []);
};

/**
 * Return a hash of generic faults and server faults.
 */
export const groupFaults = (faults: TVirtualServiceFaults = {}): IVirtualServiceGroupedFaults => {
    const groupedFaults: IVirtualServiceGroupedFaults = {};

    Object.keys(faults).forEach(type => {
        if (type === SERVER_FAULTS) {
            groupedFaults.server = [
                ...groupedFaults.server || [],
                ...getServerFaultDescriptionsByPool(faults[type] as IServerFault[]),
            ];
        } else {
            groupedFaults.generic = [
                ...groupedFaults.generic || [],
                ...getDescriptions(faults[type] as IFault[]),
            ];
        }
    });

    return groupedFaults;
};

/**
 * Transform the faults object from the response into an array of faults with "type" as the
 * "id".
 */
export const parseFaults = (faults: TVirtualServiceFaults = {}): IParsedFault[] => {
    const parsedFaults: IParsedFault[] = [];

    Object.keys(faults).forEach(type => {
        parsedFaults.push({
            id: type,
            type,
            faults: faults[type],
        });
    });

    return parsedFaults;
};

/**
 * Compare 2 sets of faults and return true if they are equal.
 */
export const areEqual = (
    faults1: TVirtualServiceFaults = {},
    faults2: TVirtualServiceFaults = {},
): boolean => {
    const flattenedFaults1 = flattenFaults(parseFaults(faults1)).sort().join();
    const flattenedFaults2 = flattenFaults(parseFaults(faults2)).sort().join();

    return flattenedFaults1 === flattenedFaults2;
};
