/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-service-pools-grid';
const prefix = `${moduleName}.${componentName}`;

export const poolGroupsGridLabel = `${prefix}.poolGroupsGridLabel`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const enableCheckboxLabel = `${prefix}.enableCheckboxLabel`;

export const ENGLISH = {
    [poolGroupsGridLabel]: 'Groups ({0})',
    [algorithmInputLabel]: 'Algorithm',
    [enableCheckboxLabel]: 'Enable',
};
