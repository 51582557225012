<div class="clr-wrapper avi-dropdown-button">
    <cds-button
        class="avi-dropdown-button__dropdown-toggle sel-avi-dropdown-toggle"
        [ngClass]="classNames"
        [disabled]="disabled"
        type="button"
        avi-tooltip
        [showOnClick]="true"
        [tooltipControl$]="dropdownButtonControl$"
        [positionsPriority]="positionsPriority"
        (openedChange)="handleOpenChange($event)"
        size="{{ size }}"
        action="{{ buttonType }}"
     >
        <ng-content></ng-content>
        <cds-icon
            class="avi-dropdown-button__caret-icon"
            *ngIf="!hideCaret"
            shape="angle"
            direction="down"
        ></cds-icon>
        <ng-template #aviTooltipContent>
            <div class="clr-wrapper avi-dropdown-button__menu">
                <button
                    #aviDropdownButtonItem
                    class="avi-dropdown-button__item sel-avi-dropdown-button__item"
                    [ngClass]="disableAction(action) && 'avi-dropdown-button__item--disabled'"
                    *ngFor="let action of actions; trackBy: trackByAction"
                    (click)="onActionClick(action)"
                    [hidden]="hideAction(action)"
                >
                    {{ action.label }}
                </button>
            </div>
        </ng-template>
    </cds-button>
</div>
