/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';
import { msToStringChunks } from 'ng/shared/utils/ms-to-string-chunks.utils';

(aviApp => {
    aviApp.filter('slug', function() {
        return function(url) {
            if (url === undefined) {
                return '';
            }

            return url.slug();
        };
    });

    aviApp.filter('objectTypeFilter', function() {
        const types = {
            VIRTUALSERVICE: 'Virtual Service',
            SERVER: 'Server',
            POOL: 'Pool',
            SERVICEENGINE: 'Service Engine',
            VIRTUALMACHINE: 'Virtual Machine',
            CONTROLLER: 'Controller',
        };

        return function(objType) {
            return objType in types ? types[objType] : '';
        };
    });

    aviApp.filter('replaceString', function() {
        return function(value, str, replacement) {
            return value === str ? replacement : value;
        };
    });

    aviApp.filter('noSpaces', function() {
        return function(str) {
            return str && str.split(' ').join('-');
        };
    });

    aviApp.filter('posOrNeg', function() {
        return function(num) {
            if (typeof num !== 'number') {
                return '';
            }

            if (num < 0) {
                return 'neg';
            } else if (num > 0) {
                return 'pos';
            } else {
                return 'nochange';
            }
        };
    });

    aviApp.filter('notDimensionUnknown', function() {
        return function(str) {
            if (str === 'DIMENSION_UNKNOWN') {
                return 'Unknown';
            } else {
                return str;
            }
        };
    });

    aviApp.filter('name', function() {
        return function(url) {
            return url ? url.name() : '';
        };
    });

    aviApp.filter('enum', function() {
        return function(value, prefix) {
            return value ? value.enumeration(prefix) : '';
        };
    });

    /**
     * @ngdoc filter
     * @name enumText
     * @description
     *     Looks up text value inside Schema.enums[enumType].values[type].options.text.value
     *     for specified enum and object type.
     * @example
     *     Schema.enums['CloudType'].values['CLOUD_NONE'].options.text.value
     *     Schema.enums['IpamDnsType'].values['IPAMDNS_TYPE_OPENSTACK'].options.text.value
     */
    aviApp.filter('enumText', ['schemaService', schemaService => (type = '', enumType = '') => {
        const enumValueHash = schemaService.getEnumValueLabelsHash(enumType);

        if (type in enumValueHash) {
            return enumValueHash[type];
        } else {
            throw new Error(`${type} was not found in ${enumType}`);
        }
    }]);

    aviApp.filter('capitalize', ['stringService', function(stringService) {
        return function(value) {
            return value ? stringService.capitalize(value) : '';
        };
    }]);

    aviApp.filter('ununderscore', function() {
        return function(value) {
            if (value && typeof value === 'string') {
                return value.replace(/_/g, ' ');
            }

            return '';
        };
    });

    /*
        * resourceUrl and mainUrl are both for client insights resource waterfall

        * resource url should return the part of the url that follows the / if it exists
        * main url should return the rest of the url
    */
    aviApp.filter('resourceUrl', function() {
        return function(str) {
            const url = str.split('/');

            // if only slashes are in http://
            if (url.length === 3) {
                return str;
            } else if (url[url.length - 1] !== '') {
                return url[url.length - 1];
            } else if (url[url.length - 2]) {
                return url[url.length - 2];
            } else {
                return str;
            }
        };
    });

    aviApp.filter('mainUrl', function() {
        return function(str) {
            const url = str.split('/');

            if (url.pop() === '') {
                url.pop();
            }

            return `${url.join('/')}/`;
        };
    });

    /* used in log details to show icon of deviceType, browser and OS */
    aviApp.filter('string2iconClass', function() {
        return function(string, type) {
            if (!string) {
                return '';
            }

            switch (type) {
                case 'os':
                    if (string.indexOf('Mac') === 0) {
                        return 'apple';
                    } else if (string.indexOf('Windows') === 0) {
                        return 'windows';
                    } else if (string.indexOf('Ubuntu') === 0) {
                        return 'ubuntu';
                    } else if (string.indexOf('Android') === 0) {
                        return 'android';
                    } else {
                        return 'unknown';
                    }

                case 'device':
                    if (string.indexOf('Computer') === 0) {
                        return 'desktop';
                    } else {
                        return 'tablet';
                    }

                case 'browser':
                    if (string.indexOf('Chrom') === 0) {
                        return 'chrome';
                    } else if (string.indexOf('Firefox') === 0) {
                        return 'firefox';
                    } else if (string.indexOf('IE') === 0) {
                        return 'ie';
                    } else if (string.indexOf('Safari') === 0 ||
                        string.indexOf('AppleMail') === 0) {
                        return 'apple';
                    } else if (string.indexOf('Opera') === 0) {
                        return 'opera';
                    } else if (string.indexOf('Android') === 0) {
                        return 'android';
                    } else {
                        return 'globe';
                    }

                default:
                    return '';
            }
        };
    });

    /* Can get any name of the user group or country code or Unknown @m */
    aviApp.filter('clientLocation2name', ['countryDict', function(countryDict) {
        return function(location) {
            let res = location || 'Unknown';

            if (location && location.length === 2) {
                res = countryDict.iso3toName[countryDict.iso2to3[location.toUpperCase()]] || res;
            } else if (location && location.length === 3) {
                res = countryDict.iso3toName[location.toUpperCase()] || res;
            }

            return res;
        };
    }]);

    aviApp.filter('flagImgSrc', ['countryDict', function(countryDict) {
        return function(isoCode) {
            const c = isoCode.length !== 2 ? countryDict.iso3to2[isoCode] : isoCode;

            return c ? `src/views/worldmaps/flags/${c.toLowerCase()}.png` : '';
        };
    }]);

    /* number of milliseconds to human-readable format @am
     * connected with log list layout by updateDurationLabelLength
     * in LogController
     * Rounds to one decimal place using Number(number.toFixed(1)). We need this so that 0.0 ms
     * becomes 0 ms. Without Number() it would return 0.0 ms.
     */
    aviApp.filter('ms2str', () => {
        return function(time, omitRem) {
            const chunks = msToStringChunks(time, omitRem);

            return chunks.reduce(
                (base, { value, dimension }) => `${base}${value}${dimension} `,
                '',
            ).slice(0, -1);
        };
    });

    function unitStateLabelFilter(stringService) {
        return state => {
            if (!state) {
                return 'N/A';
            }

            if (state === 'OPER_ERROR_DISABLED') {
                return 'Failed to Disable';
            }

            return stringService.enumeration(state, 'OPER_');
        };
    }

    unitStateLabelFilter.$inject = [
        'stringService',
    ];

    //operational status of any unit (VS, SE, Pool)
    aviApp.filter('unitStateLabel', unitStateLabelFilter);

    /**
     * @ngdoc filter
     * @name underscoresToTitleCase
     * @description
     *     Converts string "FOO_bar_FooBar" to title case string "Foo Bar Foobar".
     */
    aviApp.filter('underscoresToTitleCase', function() {
        return function(value) {
            if (!value) {
                return value;
            }

            return value.replace(/_/g, ' ').replace(/\w\S*/g, function(word) {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            });
        };
    });

    /**
     * @ngdoc filter
     * @name ipObjectToString
     * @description
     *     Parses object containing arrays for IP addresses, ranges, and subnet masks and returns a
     *     single comma separated string of IP addresses.
     */
    aviApp.filter('ipObjectToString', function() {
        return function(debug) {
            const filter = [];

            if (_.isUndefined(debug)) {
                return '';
            }

            if (debug.addrs) {
                debug.addrs.forEach(function(addr) {
                    filter.push(addr.addr);
                });
            }

            if (debug.ranges) {
                debug.ranges.forEach(function(range) {
                    filter.push(`${range.begin.addr}-${range.end.addr}`);
                });
            }

            if (debug.prefixes) {
                debug.prefixes.forEach(function(prefix) {
                    filter.push(`${prefix.ip_addr.addr}/${prefix.mask}`);
                });
            }

            return filter.join(', ');
        };
    });
    /**
     * @ngdoc filter
     * @name seGroupHaMode
     * @param {string} mode - Service Engine High Availability mode enum.
     * @returns {string} Human-readable description for the enum value.
     */
    aviApp.filter('seGroupHaMode', function() {
        return function(mode) {
            let res = '';

            switch (mode) {
                case 'HA_MODE_SHARED_PAIR':
                    res = 'Active/Active';
                    break;

                case 'HA_MODE_SHARED':
                    res = 'N+M (buffer)';
                    break;

                case 'HA_MODE_LEGACY_ACTIVE_STANDBY':
                    res = 'Legacy Active/Standby';
                    break;
            }

            return res;
        };
    });

    /**
     * Replaces string with value from hash, or calls String.prototype.enumeration() for further
     * evaluation if value is not present.
     * @param {string} value - original string to be evaluated and modified.
     * @param {string=} prefix - prefix string to strip from original string.
     * @return {string}
     */
    aviApp.filter('lookup', function() {
        const dict = {
            APPLICATION_PROFILE_TYPE_SSL: 'L4 SSL/TLS',
            APPLICATION_PROFILE_TYPE_SIP: 'SIP',
        };

        return function(value, prefix) {
            return dict[value] || value.enumeration(prefix);
        };
    });

    // ----------------------Filters --------------------------------//
    // This is not a great solution, I want to get a list of the possible devices so that I can
    // match them up better Have very close string2iconClass in filters/string.js @am */
    aviApp.filter('iconClassFilter', function() {
        return function(title) {
            const t = title.toLowerCase();
            const result = '';
            // If it's just a string, just check the second part, otherwise check the array
            const iconClasses = [
                { 'icon-desktop': ['computer', 'desktop'] },
                { 'icon-tablet': ['tablet', 'ipad', 'kindle'] },
                { 'icon-mobile': ['mobile', 'phone'] },
                'icon-globe', 'icon-windows',
                { 'icon-apple': ['mac', 'apple', 'ios', 'safari'] },
                { 'icon-linux': ['unix', 'linux'] },
                'icon-firefox', 'icon-chrome', 'icon-opera',
                { 'icon-ie': ['i.e.', 'ie'] }, 'icon-android'];

            for (let i = 0; i < iconClasses.length; i++) {
                const icon = iconClasses[i];

                if (typeof icon === 'string') {
                    if (t.match(new RegExp(icon.split('-')[1]))) {
                        return icon;
                    }
                } else {
                    _.each(icon, (value, key) => {
                        const stringsToCheck = icon[key];

                        for (let j = 0; j < stringsToCheck.length; j++) {
                            const str = stringsToCheck[j];

                            if (t.match(new RegExp(str))) {
                                return key;
                            }
                        }
                    });
                }
            }

            return result;
        };
    });

    aviApp.filter('countryName', ['countryDict', function(countryDict) {
        return function(isoCode) {
            if (isoCode === 'Others') {
                return 'Others';
            } else if (isoCode === 'DIMENSION_UNKNOWN') {
                return 'Unknown';
            } else {
                return countryDict.iso3toName[isoCode] || 'Unknown';
            }
        };
    }]);

    /**
     * @ngdoc filter
     * @name  countItems
     * @param  {any[]} items - Array of items to be counted.
     * @param  {string} label - String to be used as the label for items.
     * @description
     *     Returns a string of the number of items in an array.
     */
    aviApp.filter('countItems', () => {
        return function(items, label) {
            const append = label || 'item';

            if (angular.isUndefined(items) || !angular.isArray(items)) {
                return `0 ${append}s`;
            }

            return items.length + (items.length === 1 ? ` ${append}` : ` ${append}s`);
        };
    });

    /**
     * @ngdoc filter
     * @name  geoLocation
     * @description
     *     Returns a string showing the location of the request along with the coordinates, ex.
     *     'USA/California/Santa Clara (127°N, 58°E)'
     * @param  {Object} location - Location object containing optional name and latitude+longitude
     *     properties.
     * @return {string}
     */
    aviApp.filter('geoLocation', () => {
        return function(location) {
            if (angular.isUndefined(location)) {
                return;
            }

            let locationString = '';

            if (!angular.isUndefined(location.name)) {
                locationString += `${location.name} `;
            }

            if (!angular.isUndefined(location.latitude) &&
                !angular.isUndefined(location.longitude)) {
                const latitude = `${Math.abs(location.latitude)}\xB0${
                    location.latitude < 1 ? 'S' : 'N'}`;
                const longitude = `${Math.abs(location.longitude)}\xB0${
                    location.longitude < 1 ? 'W' : 'E'}`;

                locationString += `(${latitude}, ${longitude})`;
            }

            return locationString;
        };
    });

    /**
     * @ngdoc filter
     * @name subnetString
     * @description
     *     Returns a subnet string given a subnet object with ip_addr and mask properties.
     * @param {Object} prefix - Subnet object.
     * @return {string}
     */
    aviApp.filter('subnetString', () => {
        return prefix => getSubnetString(prefix);
    });

    /**
     * @ngdoc filter
     * @name repeatedRefs
     * @description
     *     Returns a comma-separated string of ref names given an array of refs.
     * @param {string[]} refs - Array of refs with names.
     * @return {string}
     */
    aviApp.filter('repeatedRefs', () => {
        return refs => refs.map(ref => ref.name()).join(', ');
    });
})(angular.module('aviApp'));
