/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function alertCollectionFactory($q, Collection, Alert) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.Alert Alerts}.
     */
    class AlertCollection extends Collection {
        /**
         * Setting range parameters and calling load
         * @param tBegin {date} - Begin time
         * @param tEnd {date} - End time
         * @return {promise}
         */
        loadRange(tBegin, tEnd) {
            let errMsg,
                promise;

            if (!tBegin || !tEnd) {
                errMsg = 'AlertCollection.loadRange failed: begin or end is not passed';
                console.error(errMsg);
                promise = $q.reject(errMsg);
            } else {
                // Remove ts_duration from previous loadDuration requests
                // calculate beginning and end using timeDifference

                this.setParams({
                    ts_duration: undefined,
                    ts_start: moment.utc(tBegin).format('YYYY-MM-DDTHH:mm:ss'),
                    ts_end: moment.utc(tEnd).format('YYYY-MM-DDTHH:mm:ss'),
                });

                promise = this.load(undefined, true);
            }

            return promise;
        }

        /**
         * Setting step and limit parameters and calling load
         * @param step {int} - Current timeframe step
         * @param limit {int} - Current timeframe limit
         * @return {promise}
         */
        loadDuration(step, limit) {
            let errMsg,
                promise;

            if (!step || !limit) {
                errMsg = 'AlertCollection.loadDuration failed: step or limit is not passed';
                promise = $q.reject(errMsg);
                console.error(errMsg);
            } else {
                // Remove ts_start, ts_end from previous loadRange requests
                // set duration in seconds
                this.setParams({
                    ts_duration: step * limit,
                    ts_start: undefined,
                    ts_end: undefined,
                });

                promise = this.load(undefined, true);
            }

            return promise;
        }

        /**
         * Alerts API doesn't support search yet, but we want to provide ability to return only
         * one alert with passed id. Search string should have an appropriate format like
         * alertId=<uuid>.
         * @override
         */
        setSearch(str) {
            const
                searchByUuidExp = /alertId\s*=\s*['"]?([\w-]+)['"]?/i,
                prevUuidParamValue = this.getParams('uuid');

            let
                res = false,
                regExpRes;

            //special case for uuid access
            if (str && typeof str === 'string' && (regExpRes = searchByUuidExp.exec(str))) {
                if (regExpRes[1] !== prevUuidParamValue) {
                    this.setParams({ uuid: regExpRes[1] });
                    res = true;
                }

                //empty any search string we used to have
                res = super.setSearch(undefined) || res;
            } else { //regular search
                this.setParams({ uuid: undefined });

                if (prevUuidParamValue) {
                    res = true;
                }

                res = super.setSearch(str) || res;
            }

            return res;
        }

        /** @override */
        isCreatable() { return false; }

        /** @override */
        isEditable() { return false; }
    }

    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'AlertListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    Object.assign(AlertCollection.prototype, {
        objectName_: 'alert',
        itemClass_: Alert,
        defaultDataSources_: 'list',
        allDataSources_: dataSources,
        searchFields_: [
            'name',
            'obj_name',
        ],
    });

    return AlertCollection;
}

alertCollectionFactory.$inject = [
    '$q',
    'Collection',
    'Alert',
];

angular.module('avi/app')
    .factory('AlertCollection', alertCollectionFactory);
