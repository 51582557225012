<item-full-modal
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    [item]="editable"
    [form]="form"
    class="network-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ globalL10nKeys.generalLabel | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ globalL10nKeys.generalLabel | vtranslate }}</h3>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        fieldName="name"
                        [objectType]="objectType"
                        required
                    >
                        {{ globalL10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        clrInput
                        placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                        required
                        regexPattern="objName"
                        [(ngModel)]="editable.getConfig().name"
                    />
                </avi-input-container>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6 class="full-modal__section-header">
                            {{ l10nKeys.ipAddressManagementLabel | vtranslate }}
                        </h6>
                        <div class="avi-checkbox-container-group network-modal__ip-checkbox-container">
                            <clr-checkbox-container>
                                <clr-checkbox-wrapper>
                                    <input
                                        type="checkbox"
                                        id="dhcp-enabled"
                                        name="dhcp-enabled"
                                        [(ngModel)]="editable.getConfig().dhcp_enabled"
                                        clrCheckbox
                                    />
                                    <label
                                        aviLabelWithTooltip
                                        [objectType]="objectType"
                                        fieldName="dhcp_enabled"
                                        for="dhcp-enabled"
                                    >
                                        {{ l10nKeys.enableDhcpLabel | vtranslate }}
                                    </label>
                                </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                            <clr-checkbox-container>
                                <clr-checkbox-wrapper>
                                    <input
                                        type="checkbox"
                                        id="ip6-autocfg-enabled"
                                        name="ip6-autocfg-enabled"
                                        [(ngModel)]="editable.getConfig().ip6_autocfg_enabled"
                                        clrCheckbox
                                    />
                                    <label
                                        aviLabelWithTooltip
                                        [objectType]="objectType"
                                        fieldName="ip6_autocfg_enabled"
                                        for="ip6-autocfg-enabled"
                                    >
                                        {{ l10nKeys.enableIpV6Label | vtranslate }}
                                    </label>
                                </clr-checkbox-wrapper>
                            </clr-checkbox-container>
                        </div>
                    </div>
                </div>

                <div
                    class="full-modal-config__form-control-row"
                    *ngIf="hasCustomVrfContext"
                >
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            [objectType]="objectType"
                            fieldName="vrf_context_uuid"
                            for="vrf-context-uuid"
                        >
                            {{ l10nKeys.routingContextInputLabel | vtranslate }}
                        </label>
                        <avi-collection-dropdown
                            [collection]="vrfContextCollection"
                            [(ngModel)]="editable.getConfig().vrf_context_ref"
                            placeholder="{{ l10nKeys.routingContextInputPlaceholder | vtranslate }}"
                            name="vrf-context-uuid"
                        ></avi-collection-dropdown>
                    </div>
                </div>

                <div *ngIf="editable.data.discovery?.ip_subnet?.length">
                    <clr-checkbox-container>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                id="exclude-discovered-subnets"
                                name="exclude-discovered-subnets"
                                [(ngModel)]="editable.data.config.exclude_discovered_subnets"
                                (ngModelChange)="filterData()"
                                clrCheckbox
                            />
                            <label
                                aviLabelWithTooltip
                                [objectType]="objectType"
                                fieldName="exclude_discovered_subnets"
                                for="exclude-discovered-subnets"
                            >
                                {{ l10nKeys.excludeDiscoveredSubnetsInputLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                </div>

                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-data-grid
                            [config]="subnetsGridConfig"
                            [rows]="filteredData"
                        >
                            <avi-data-grid_header>
                                <label
                                    aviLabelWithTooltip
                                    objectType="{{ objectType }}"
                                    fieldName="configured_subnets"
                                >
                                    <avi-data-grid_header>
                                        <h6>
                                            {{ l10nKeys.subnetsGridTitle | vtranslate : configuredSubnetsCount : discoveredSubnetsCount }}
                                        </h6>
                                    </avi-data-grid_header>
                                </label>
                            </avi-data-grid_header>
                            <avi-data-grid_actions>
                                <cds-button
                                    size="sm"
                                    (click)="addSubnet()"
                                >
                                    {{ globalL10nKeys.addLabel | vtranslate }}
                                </cds-button>
                            </avi-data-grid_actions>
                        </avi-data-grid>
                    </div>
                </div>

            </div>

        </full-modal-tab-section>
    </form>
</item-full-modal>
