/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AccountsModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { USER_COLLECTION_TOKEN } from 'ajs/modules/accounts/accounts.tokens';
import { AviAlertService } from 'ng/modules/core/services/avi-alert.service';
import { ControllerInitialDataSelectors } from 'ng/root-store/controller-initial-data-store';

import {
    TUserCollection,
    UserCollection,
    UserItem,
} from 'ajs/modules/accounts/factories/user';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './user-page-list.l10n';
import './user-page-list.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description User list page component.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'user-page-list',
    templateUrl: './user-page-list.component.html',
})
export class UserPageListComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display User collection.
     */
    public userGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the User items.
     */
    private readonly userCollection: UserCollection;

    /**
     * Subscription for controller time difference
     */
    private controllerTimeDifferenceSubscription: Subscription;

    /**
     * Controller time difference data computed from the initial-data API.
     */
    private readonly controllerTimeDifference$ = this.store.select(
        ControllerInitialDataSelectors.selectControllerTimeDifference,
    );

    /**
     * Map of user's uuid and tenant roles.
     */
    private readonly uuidAndTenantRoleMap = new Map<string, string>();

    constructor(
        private readonly l10nService: L10nService,
        @Inject(USER_COLLECTION_TOKEN)
        UserCollection: TUserCollection,
        private readonly store: Store,
        private readonly aviAlertService: AviAlertService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.userCollection = new UserCollection({
            params: {
                include_name: true,
                include_activity: true,
            },
        });
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.userCollection;

        this.userGridConfig = {
            id: `${objectName}-list`,
            collection: this.userCollection,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'username',
                    sortBy: 'username',
                    label: this.l10nService.getMessage(globalL10nKeys.usernameLabel),
                    transform: (row: UserItem): string => row.getConfig().username,
                },
                {
                    id: 'is-superuser',
                    sortBy: 'is_superuser',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleSuperUser),
                    transform: (row: UserItem): string => {
                        return row.getConfig().is_superuser ?
                            this.l10nService.getMessage(globalL10nKeys.yesValueLabel) :
                            this.l10nService.getMessage(globalL10nKeys.noValueLabel);
                    },
                },
                {
                    id: 'status',
                    sortBy: 'is_active',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleStatus),
                    transform: (row: UserItem): string => {
                        return row.getConfig().is_active ?
                            this.l10nService.getMessage(globalL10nKeys.activeLabel) :
                            this.l10nService.getMessage(l10nKeys.suspendedLabel);
                    },
                },
                {
                    id: 'full-name',
                    sortBy: 'full_name',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleFullName),
                    transform: (row: UserItem): string => row.getName(),
                },
                {
                    id: 'email',
                    sortBy: 'email',
                    label: this.l10nService.getMessage(globalL10nKeys.emailLabel),
                    transform: (row: UserItem): string => row.getConfig().email || '-',
                },
                {
                    id: 'tenant',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleTenant),
                    transform: (row: UserItem): string => {
                        const { access: accessList } = row.getConfig();
                        let tenantAccessSummary = '-';

                        if (Array.isArray(accessList) && accessList.length) {
                            tenantAccessSummary = accessList
                                .map(access => row.getTenantAccessSummary(access))
                                .join(', ');
                        }

                        this.uuidAndTenantRoleMap
                            .set(row.getConfig().uuid, tenantAccessSummary);

                        return tenantAccessSummary;
                    },
                    getTooltip: (row: UserItem): string => {
                        return this.uuidAndTenantRoleMap.get(row.getConfig().uuid) || '';
                    },
                },
                {
                    id: 'last-signed-in',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleLastSignedIn),
                    transform: (row: UserItem): string => {
                        return this.getLastSignedInStatus(row);
                    },
                },
            ],
            multipleactions: [
                {
                    id: 'activate',
                    label: this.l10nService.getMessage(globalL10nKeys.activeLabel),
                    onClick: (users: UserItem[]) => {
                        users.forEach((user: UserItem) => {
                            if (user.isEditable() && user.getConfig().is_active !== true) {
                                this.updateUser(user, true);
                            }
                        });
                    },
                    disabled: (users: UserItem[]) => {
                        return users.some(user => {
                            return user.isEditable() && user.getConfig().is_active;
                        });
                    },
                },
                {
                    id: 'suspend',
                    label: this.l10nService.getMessage(l10nKeys.suspendLabel),
                    onClick: (users: UserItem[]) => {
                        users.forEach((user: UserItem) => {
                            if (user.isEditable() && user.getConfig().is_active !== false) {
                                this.updateUser(user, false);
                            }
                        });
                    },
                    disabled: (users: UserItem[]) => {
                        return users.some(user => {
                            return user.isEditable() && !user.getConfig().is_active;
                        });
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.userCollection.destroy();
    }

    /**
     * Update the user with updated values
     */
    private updateUser(user: UserItem, isActive: boolean): void {
        user.isActive = isActive;

        user.save().catch(response => {
            user.isActive = user.getConfig().is_active;

            this.aviAlertService.throw(response.data);
        });
    }

    /**
     * Returns last sign in status of a user in a readable format.
     */
    private getLastSignedInStatus(user: UserItem): string {
        const { activity = {} } = user.getConfig();

        if (!activity.last_login_timestamp) {
            return this.l10nService.getMessage(l10nKeys.neverLoggedInLabel);
        }

        let lastLoginStatus = '';

        this.controllerTimeDifferenceSubscription = this.controllerTimeDifference$
            .pipe(take(1))
            .subscribe((timeDifference: number) => {
                const {
                    logged_in: isLoggedIn,
                    last_login_timestamp: lastLoginTimestamp,
                    last_login_ip: lastLoginIp,
                } = activity;

                lastLoginStatus = isLoggedIn ?
                    this.l10nService.getMessage(l10nKeys.onlineSincePrefix) :
                    this.l10nService.getMessage(l10nKeys.lastLoginPrefix);

                lastLoginStatus += String(moment
                    .utc(lastLoginTimestamp)
                    .add(timeDifference, 'seconds')
                    .fromNow());

                if (lastLoginIp) {
                    lastLoginStatus += ` from ${lastLoginIp}`;
                }

                return lastLoginStatus;
            })
            .add(
                () => {
                    this.controllerTimeDifferenceSubscription?.unsubscribe();
                },
            );

        return lastLoginStatus;
    }
}
