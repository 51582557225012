/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AdministrationDashboardModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import './node-vitals-usage-bar.component.less';

/**
 * @description
 * Renders a bar to indicate the usage percentage for a node vital, ex. CPU, Disk, or Memory usage.
 * @author alextsg
 */
@Component({
    selector: 'node-vitals-usage-bar',
    templateUrl: './node-vitals-usage-bar.component.html',
})
export class NodeVitalsUsageBarComponent {
    /**
     * Percentage used to determine how much of the bar to fill.
     */
    @Input()
    public percentage = 0;

    /**
     * Color used to fill in the bar.
     */
    @Input()
    public color = 'var(--clr-color-action-600)';
}
