/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-saml';
const prefix = `${moduleName}.${componentName}`;

export const samlAuthStatusLabel = `${prefix}.samlAuthStatusLabel`;
export const sessionCookieExpiredLabel = `${prefix}.sessionCookieExpiredLabel`;
export const attributeNameLabel = `${prefix}.attributeNameLabel`;
export const attributeValuesLabel = `${prefix}.attributeValuesLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const userIdLabel = `${prefix}.userIdLabel`;

export const ENGLISH = {
    [samlAuthStatusLabel]: 'SAML Auth Status',
    [sessionCookieExpiredLabel]: 'Session Cookie Expired',
    [attributeNameLabel]: 'Attribute Name',
    [attributeValuesLabel]: 'Attribute Values',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [userIdLabel]: 'User ID',
};
