<avi-card class="system-settings-dns-services-card">
    <avi-card_header>
        <div class="system-settings-dns-services-card__header">
            <cds-icon
                shape="network-settings"
                size="24"
                class="system-settings-dns-services-card__icon"
                status="success"
            ></cds-icon>
            {{ l10nKeys.dnsServicesHeader | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="dns_virtualservice_uuids"
            for="dns-services"
        >
            {{ l10nKeys.dnsServicesLabel | vtranslate }}
        </label>
        <avi-input-container
            noMarginTop
            *ngFor="let dnsService of dnsServicesList; trackBy: trackByIndex"
        >
            <input
                class="sel-dns-services"
                name="dns-services"
                clrInput
                [value]="dnsService"
                readonly
            />
        </avi-input-container>
        <avi-input-container noMarginTop>
            <input
                *ngIf="!dnsServicesList.length"
                class="sel-dns-services"
                name="dns-services"
                clrInput
                placeholder="-"
                [value]=""
                readonly
            />
        </avi-input-container>
    </avi-card_body>
</avi-card>
