/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'csrf-policy';

export const csrfPolicyModalBreadcrumbTitle = `${prefix}.csrfPolicyModalBreadcrumbTitle`;

export const ENGLISH = {
    [csrfPolicyModalBreadcrumbTitle]: 'CSRF Policy',
};
