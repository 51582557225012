/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module CoreModule
 */

import { Injectable } from '@angular/core';
import { enumerations } from './enumerations.constant';

/**
 * @description
 * Service to work with string.
 * @author Rajawant Prajapati, alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class StringService {
    /**
     * Function to check if string contains string value passed as parameter.
     */
    public contains(str: string, suffix: string): boolean {
        return str.toLowerCase().includes(suffix.toLowerCase());
    }

    /**
     * Function to change first character of string value into uppercase.
     */
    public capitalize(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    /**
     * Function to get object slug value from the url.
     */
    public slug(str: string): string {
        const fragment = str.split('#');
        let url = str;

        if (fragment.length) {
            [url] = fragment;
        }

        const parts = url.split('/');

        return parts[parts.length - 1];
    }

    /**
     * Function to get object name from the url.
     */
    public name(str: string): string {
        const hashIndex = str.indexOf('#');

        // # should not be the first element.
        if (hashIndex > 0) {
            return str.slice(hashIndex + 1);
        }

        return '';
    }

    /**
     * Modifies a string, typically an enum, by first removing specified prefixes, then
     * stripping underscores, comparing with the enumerations, and formatting capitalization.
     */
    public enumeration(str: string, prefix?: string | string[]): string {
        let value = str;

        if (!Array.isArray(prefix)) {
            prefix = [prefix];
        }

        prefix.map(pre => {
            if (value.startsWith(pre)) {
                value = value.replace(pre, '');
            }

            return value;
        });

        if (value in enumerations) {
            return enumerations[value];
        }

        return value.split('_').map(word => {
            if (word in enumerations) {
                return enumerations[word];
            } else {
                return this.capitalize(word.toLowerCase());
            }
        }).join(' ');
    }

    /**
     * Function to convert existing string from snake case to camel case format.
     */
    public snakeToCamelCase(str: string): string {
        return str.replace(/(_[A-Za-z0-9])/g, matches => {
            return matches[1].toUpperCase();
        });
    }

    /**
     * Function to convert existing string from camel case to dash format.
     */
    public camelCaseToDash(str: string): string {
        return str.replace(/[A-Z]/g, (letter: string, pos: number) => {
            return (pos ? '-' : '') + letter.toLowerCase();
        });
    }
}
