/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { aviMediumDateFormatWithSec } from 'ng/modules/core/pipes/avi-date.pipe';
import {
    IAviDataGridConfig,
    IAviDataGridRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import { VsLogsStore } from '../../../services';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import { FilterOperatorType, VsAppProfileType } from '../../../vs-logs.types';
import * as l10n from './vs-log-cinematic-section-summary.l10n';

import './vs-log-cinematic-section-summary.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying Summary section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-summary',
    templateUrl: './vs-log-cinematic-section-summary.component.html',
})
export class VsLogCinematicSectionSummaryComponent implements OnInit, AfterViewInit {
    @ViewChild('policiesAppliedFieldTemplateRef')
    public readonly policiesAppliedFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly vsAppProfileType$ = this.vsLogsStore.vsAppProfileType$;

    public readonly VsAppProfileType = VsAppProfileType;

    /**
     * Object that includes L7 client information.
     */
    public readonly l7ClientInfo$ = this.vsLogCinematicStore.l7ClientInfo$;

    /**
     * Object that includes L4 client information.
     */
    public readonly l4ClientInfo$ = this.vsLogCinematicStore.l4ClientInfo$;

    /**
     * Object that includes policy rule information.
     */
    public readonly policyRules$ = this.vsLogCinematicStore.policyRules$;

    public readonly nsxAlbInfo$ = this.vsLogCinematicStore.nsxAlbInfo$;

    /**
     * Object that includes HTTP request information.
     */
    public readonly requestInfo$ = this.vsLogCinematicStore.requestInfo$;

    public readonly requestDetails$ = this.vsLogCinematicStore.requestDetails$;

    /**
     * Object that includes HTTP response information.
     */
    public readonly responseInfo$ = this.vsLogCinematicStore.responseInfo$;

    public readonly dnsClientInfo$ = this.vsLogCinematicStore.dnsClientInfo$;

    public readonly dnsLoadBalancerInfo$ = this.vsLogCinematicStore.dnsLoadBalancerInfo$;

    public readonly FilterOperatorType = FilterOperatorType;

    /**
     * Format string that specifies date transformation to seconds.
     */
    public readonly timeFormat = aviMediumDateFormatWithSec;

    /**
     * Get global keys from source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('DnsRecordType'),
        ...this.schemaService.getEnumValueLabelsHash('Protocol'),
    };

    /** For template use */
    public readonly Boolean = Boolean;

    /**
     * List of selectors of the sections for the cinematic view except for Top-info and Summary.
     * Each section can be also referred to as a "policy".
     * For L7 log use only.
     */
    public policyGridRows: IAviDataGridRow[];

    /**
     * Configuration for the grid to show what policies (as c-view sections, not the VS policies)
     * are populated.
     */
    public policyGridConfig: IAviDataGridConfig;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly schemaService: SchemaService,
        private readonly vsLogsStore: VsLogsStore,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const {
            ssl$,
            hasAuthenticationLogs$,
            icapLog$,
            wafLog$,
            botManagementLog$,
            datascriptInfo$,
        } = this.vsLogCinematicStore;

        this.policyGridRows = [
            {
                label: this.l10nService.getMessage(this.l10nKeys.sslLabel),
                selector: ssl$,
            },
            {
                label: this.l10nService.getMessage(this.globalL10nKeys.authenticationLabel),
                selector: hasAuthenticationLogs$,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.icapLabel),
                selector: icapLog$,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.wafLabel),
                selector: wafLog$,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.botManagementLabel),
                selector: botManagementLog$,
            },
            {
                label: this.l10nService.getMessage(this.l10nKeys.datascriptLabel),
                selector: datascriptInfo$,
            },
        ];
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.policyGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(this.l10nKeys.policiesAppliedLabel),
                    id: 'policies-applied',
                    templateRef: this.policiesAppliedFieldTemplateRef,
                },
            ],
            layout: {
                hidePagination: true,
                hideSearch: true,
                hideCheckboxes: true,
            },
        };
    }
}
