/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { ComponentStore } from '@ngrx/component-store';
import _ from 'underscore';

import { StringService } from 'string-service';

import {
    ISystemConfigurationConfig,
    SystemConfig,
} from 'ajs/modules/system/factories/system-config.item.factory';

interface IAuthAndAuthMappingProfiles {
    authProfiles: string[],
    authMappingProfiles: string[],
}

/**
 * @description Component Store for System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Injectable()
export class SystemSettingsPageStore extends ComponentStore<ISystemConfigurationConfig> {
    /**
     * NgRx Component Selector for Dns Resolvers, used in template.
     */
    public readonly dnsResolversList$ = this.select(state => {
        const { server_list: serverList } = state.dns_configuration;

        return _.pluck(serverList, 'addr');
    });

    /**
     * NgRx Component Selector for NtpAuthenticationKeysCount, used in template.
     */
    public readonly ntpAuthenticationKeysCount$ = this.select(state => {
        const { ntp_authentication_keys: ntpAuthKeys } = state.ntp_configuration;

        return ntpAuthKeys?.length || 0;
    });

    /**
     * NgRx Component Selector for NtpServersCount, used in template.
     */
    public readonly ntpServersList$ = this.select(state => {
        const { ntp_servers: ntpServers = [] } = state.ntp_configuration;
        const ntpServerAddresses = ntpServers.map(server => server.server.addr);

        return ntpServerAddresses;
    });

    /**
     * Ngrx Component Selector for Dns Services List, used in template.
     */
    public readonly dnsServicesList$ = this.select(state => {
        const { dns_virtualservice_refs: dnsVirtualServicesRefs = [] } = state;

        const dnsVsNames = dnsVirtualServicesRefs.reduce(
            (vsNames: string[], vsRef: string) => {
                if (vsRef) {
                    vsNames.push(this.stringService.name(vsRef));
                }

                return vsNames;
            }, [],
        );

        return dnsVsNames;
    });

    /**
     * NgRx Component Selector for Global Tenant Configuration.
     */
    public readonly globalTenantConfig$ = this.select(state => {
        const { global_tenant_config: globalTenantConfig } = state;

        return globalTenantConfig;
    });

    /**
     * NgRx Component Selector for Email Configuration.
     */
    public readonly emailConfiguration$ = this.select(state => {
        const { email_configuration: emailConfiguration } = state;

        return emailConfiguration.config;
    });

    /**
     * NgRx component selector for remote configuration.
     */
    public readonly remoteAuthConfiguration$ = this.select(state => {
        const profiles: IAuthAndAuthMappingProfiles = {
            authProfiles: [],
            authMappingProfiles: [],
        };

        const remoteAuthConfigurations = state.admin_auth_configuration?.remote_auth_configurations;

        remoteAuthConfigurations?.reduce(
            (profiles, remoteAuthConfig) => {
                profiles.authProfiles.push(
                    this.stringService.name(remoteAuthConfig.auth_profile_ref),
                );

                profiles.authMappingProfiles.push(
                    this.stringService.name(remoteAuthConfig.auth_mapping_profile_ref),
                );

                return profiles;
            }, profiles,
        );

        return profiles;
    });

    /**
     * NgRx Component Selector for Portal Configuration.
     */
    public readonly portalConfiguration$ = this.select(state => {
        const { portal_configuration: portalConfiguration } = state;

        return portalConfiguration;
    });

    /**
     * NgRx component selector for secure channel ssl key certificate refs.
     */
    public readonly secureChannelSslKeyCertificateRefs$ = this.select(state => {
        const {
            sslkeyandcertificate_refs: sslKeyCertificateRefs,
        } = state.secure_channel_configuration;

        return sslKeyCertificateRefs;
    });

    /**
     * NgRx component selector for allowed ciphers.
     */
    public readonly allowedCiphers$ = this.select(state => {
        const { ssh_ciphers: allowedCiphers } = state;

        return allowedCiphers;
    });

    /**
     * NgRx component selector for allowed hmacs.
     */
    public readonly allowedHmacs$ = this.select(state => {
        const { ssh_hmacs: allowedHmacs } = state;

        return allowedHmacs;
    });

    /**
     * NgRx component selector SNMP Version.
     */
    public readonly snmpVersion$ = this.select(state => {
        return state.snmp_configuration?.version;
    });

    /**
     * NgRx component store selector for linux configuration.
     */
    public readonly linuxConfiguration$ = this.select(state => {
        const { linux_configuration: linuxConfiguration } = state;

        return linuxConfiguration;
    });

    /**
     * NgRx component store selector for managment access control.
     */
    public readonly managementAccessControl$ = this.select(state => {
        const { mgmt_ip_access_control: managementAccessControl } = state;

        return managementAccessControl;
    });

    /**
     * Reload config data in store.
     */
    public readonly reloadStore = this.updater(() => {
        const { config } = this.systemConfigService;

        return { ...config } as unknown as ISystemConfigurationConfig;
    });

    /**
     * State is initialized with System Config.
     */
    constructor(
        private readonly stringService: StringService,
        @Inject('systemConfigService')
        private readonly systemConfigService: SystemConfig,
    ) {
        super(systemConfigService.getConfig());
    }
}
