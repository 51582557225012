<expander-action-row
    class="expander-action-confirmation"
    message="{{ message }}"
>
    <div class="expander-action-confirmation__buttons-container">
        <button
            class="expander-action-confirmation__cancel avi-btn avi-btn-transparent"
            (click)="handleCancel()"
        >
            {{ l10nKeys.cancelButtonLabel | vtranslate }}
        </button>
        <ng-content></ng-content>
    </div>
</expander-action-row>
