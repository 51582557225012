/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { TDependencyToken } from '../utils';

export abstract class NgDependencyInjected {
    protected static readonly ngDependencies: Array<TDependencyToken | string>;

    protected static getNgDependency(dependency: TDependencyToken | string): any {}

    protected getNgDependency(dependency: TDependencyToken | string): any {}
}
