/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-input-container-error';
const prefix = `${moduleName}.${componentName}`;

export const requiredError = `${prefix}.requiredError`;
export const regexPatternError = `${prefix}.regexPatternError`;
export const rangeError = `${prefix}.rangeError`;
export const uniquenessError = `${prefix}.uniquenessError`;
export const cronExpressionValidationError = `${prefix}.cronExpressionValidationError`;
export const portValidateError = `${prefix}.portValid`;
export const ipRangeValidateError = `${prefix}.ipRangeValidateError`;
export const maxStringLengthValidateError = `${prefix}.maxStringLengthValidateError`;
export const atLeastOneValueValidateError = `${prefix}.atLeastOneValueValidateError`;

export const ENGLISH = {
    [requiredError]: 'This field is required.',
    [regexPatternError]: 'The value entered is invalid.',
    [rangeError]: 'The value entered is outside of the valid range.',
    [uniquenessError]: 'This field does not allow duplicate values.',
    [cronExpressionValidationError]: 'The expression entered is invalid.',
    [portValidateError]: 'The Port value entered is invalid.',
    [ipRangeValidateError]: 'Entered IP range does not belong to entered Subnet.',
    [maxStringLengthValidateError]: 'Maximum {0} characters allowed.',
    [atLeastOneValueValidateError]: 'At least one value is required.',
};
