/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'credentials-verification-dialog';
const prefix = `${moduleName}.${componentName}`;

export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const connectButtonLabel = `${prefix}.connectButtonLabel`;

export const ENGLISH = {
    [cancelButtonLabel]: 'Cancel',
    [connectButtonLabel]: 'Connect',
};
