/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import * as UserPreferencesActions from 'ng/root-store/user-preferences/user-preferences.actions';

angular.module('aviApp').controller('SideMenuController', [
'$scope', '$timeout', '$rootScope', 'myAccount', STORE_TOKEN,
function($scope, $timeout, $rootScope, myAccount, store) {
    $scope.myAccount = myAccount;

    const { uiProperty } = myAccount;

    $scope.displayProps = {
        sidebarActiveTab: 1,
        valuesToDisplay: uiProperty.valuesToDisplay,
        hideSavedSearches: uiProperty.logs.hideSavedSearches,
        hideLogSummaries: uiProperty.logs.hideLogSummaries,
    };

    $scope.valuesToDisplay = myAccount.uiProperty.valuesToDisplay;

    $scope.handleValuesToDisplayChange = function() {
        store.dispatch(UserPreferencesActions.updateUIProperty({
            payload: {
                valuesToDisplay: $scope.ui.valuesToDisplay,
            },
        }));
    };

    $scope.toggleHideLogSummaries = () => {
        $scope.ui.hideLogSummaries = !$scope.ui.hideLogSummaries;

        store.dispatch(UserPreferencesActions.updateLogs({
            payload: {
                hideLogSummaries: $scope.ui.hideLogSummaries,
            },
        }));

        $scope.toggleRepaint();
    };

    $scope.toggleHideSavedSearches = () => {
        $scope.ui.hideSavedSearches = !$scope.ui.hideSavedSearches;

        store.dispatch(UserPreferencesActions.updateLogs({
            payload: {
                hideSavedSearches: $scope.ui.hideSavedSearches,
            },
        }));

        $scope.toggleRepaint();
    };

    $scope.toggleRepaint = function() {
        // using timeout so that this resolves after things have new sizes
        $timeout(function() {
            $rootScope.$broadcast('repaint');
        }, 250);
    };
},
]);
