<div
    class="full-modal-config__section imaps-health-monitor-config"
    provideParentForm
>
    <h3>{{ (editable.isImapsType() ? l10nKeys.sectionTitleForImaps: l10nKeys.sectionTitleForImap) | vtranslate }}</h3>
    <health-monitor-authentication
      [editable]="editable"
      [showAuthTypeDropdown]="false"
    ></health-monitor-authentication>

    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            for="monitor-port"
            [required]="editable.config.is_federated"
        >
            {{ globalL10nKeys.healthMonitorPortInputLabel | vtranslate }}
        </label>
        <input
            clrInput
            type="number"
            id="monitor-port"
            name="monitor-port"
            [(ngModel)]="editable.config.monitor_port"
            configFieldInputValidation
            objectType="{{ objectTypes.HealthMonitor }}"
            fieldName="monitor_port"
            placeholder="{{ globalL10nKeys.healthMonitorPortInputPlaceholder | vtranslate }}"
            [disabled]="!!editable.id"
            [required]="editable.config.is_federated"
        />
    </avi-input-container>

    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <h6>{{ l10nKeys.directoryInformationLabel | vtranslate }}</h6>
            <avi-input-container noMarginTop>
              <label
                  aviLabelWithTooltip
                  objectType="{{ objectTypes.HealthMonitorImap }}"
                  required
                  for="folderName"
                  fieldName="folder"
              >
                  {{ l10nKeys.folderNameLabel | vtranslate }}
              </label>
              <input
                  id="folderName"
                  name="folderName"
                  class="sel-name"
                  regexPattern="folderName"
                  clrInput
                  required
                  placeholder="{{ l10nKeys.folderNameLabel | vtranslate }}"
                  [(ngModel)]="editable.config.folder"
              />
            </avi-input-container>
        </div>
    </div>

    <!-- IMAPS type related fields -->
    <ng-container *ngIf="isImapsType">
        <div class="full-modal-config__form-control-row">
            <h6>{{ globalL10nKeys.securityLabel | vtranslate }}</h6>
        </div>

        <avi-input-container noMarginTop>
            <label
                aviLabelWithTooltip
                objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                fieldName="server_name"
                for="server-name"
            >
                {{ globalL10nKeys.tlsSniServerNameLabel | vtranslate }}
            </label>
            <input
                clrInput
                id="server-name"
                name="server-name"
                [(ngModel)]="imapConfigItem.config.ssl_attributes.config.server_name"
                placeholder="{{ globalL10nKeys.hostHeaderLabel | vtranslate }}"
                regexPattern="hostname"
            />
        </avi-input-container>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_profile_uuid"
                    for="ssl-profile-uuid"
                    required
                >
                    {{ globalL10nKeys.sslProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    name="ssl-profile-uuid"
                    [collection]="sslProfileCollection"
                    [(ngModel)]="imapConfigItem.config.ssl_attributes.config.ssl_profile_ref"
                    placeholder="{{ globalL10nKeys.sslProfilePlaceholder | vtranslate }}"
                    id="ssl-profile-uuid"
                    required
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="pki_profile_uuid"
                    for="pki-profile-uuid"
                >
                    {{ globalL10nKeys.pkiProfileLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    name="pki-profile-uuid"
                    [collection]="pkiProfileCollection"
                    [(ngModel)]="imapConfigItem.config.ssl_attributes.config.pki_profile_ref"
                    placeholder="{{ globalL10nKeys.pkiProfilePlaceholder | vtranslate }}"
                    id="pki-profile-uuid"
                ></avi-collection-dropdown>
            </div>
        </div>

        <div class="full-modal-config__form-control-row">
            <div class="full-modal-config__form-control">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectTypes.HealthMonitorSSLAttributes }}"
                    fieldName="ssl_key_and_certificate_uuid"
                    for="ssl-key-certificate-uuid"
                >
                    {{ globalL10nKeys.sslKeyAndCertificateLabel | vtranslate }}
                </label>
                <avi-collection-dropdown
                    name="ssl-key-and-certificate-uuid"
                    [collection]="certificateCollection"
                    [(ngModel)]="imapConfigItem.config.ssl_attributes.config.ssl_key_and_certificate_ref"
                    placeholder="{{ globalL10nKeys.sslKeyAndCertificatePlaceholder | vtranslate }}"
                    id="ssl-key-certificate-uuid"
                ></avi-collection-dropdown>
            </div>
        </div>
    </ng-container>
</div>
