<div 
    class="avi-repeated-collection-or-custom-value"
    provideParentForm
>
    <div class="avi-repeated-collection-ref-or-custom-value__input-container">
        <div
            *ngFor="let valueItem of value; index as i; trackBy: trackByIndex"
            class="avi-repeated-collection-or-custom-value__input-field"
        >
            <div class="avi-repeated-collection-or-custom-value__header">
                <label aviLabelWithTooltip>
                    {{ label }}
                </label>
                <cds-icon
                    shape="trash"
                    size="18"
                    status="info"
                    class="avi-repeated-collection-or-custom-value__remove-icon"
                    (click)="removeItem(i)"
                ></cds-icon>
            </div>
            <avi-custom-collection-dropdown
                [collection]="collection"
                [customValues]="valueItem.customValue ? [valueItem.customValue] : []"
                (customValuesChange)="updateCustomValue($event, i)"
                [(collectionRef)]="valueItem.collectionRef"
                [disabled]="disabled"
                [required]="required"
                [dropdownPlaceholder]="dropdownPlaceholder"
                [inputPlaceholder]="inputPlaceholder"
                [index]="i"
                [name]="name"
                [label]="label"
                noMarginTop
            ></avi-custom-collection-dropdown>
        </div>
    </div>
    <cds-button
        class="avi-repeated-collection-or-custom-value__add-item-button"
        action="flat"
        size="sm"
        (click)="addItem()"
    >
        <cds-icon shape="plus-circle"></cds-icon>
        {{ l10nKeys.addItemButtonLabel | vtranslate }}
    </cds-button>
</div>
