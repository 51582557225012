/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-profile-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const actionBtnVerify = `${prefix}.actionBtnVerify`;
export const placeholderMessage = `${prefix}.placeholderMessage`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [actionBtnVerify]: 'Verify',
    [columnTitleName]: 'Name',
    [placeholderMessage]: 'No Items Found',
    [headerLabel]: 'Auth Profile',
};

