/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { any } from 'underscore';
import { IAviDropdownOption } from './avi-dropdown.types';

/**
 * Return the value from a dropdownOption.
 */
export const getValue = <TDropdownOptionValue>(
    dropdownOption: IAviDropdownOption<TDropdownOptionValue>,
): IAviDropdownOption<TDropdownOptionValue>['value'] => {
    return dropdownOption.value;
};

/**
 * Return a string to be used as the dropdown option label.
 */
export const getDisplayLabel = (dropdownOption: IAviDropdownOption): string => {
    return dropdownOption.label || getValue(dropdownOption).toString();
};

/**
 * Filter label by provided search criterion.
 */
export const isFiltered = (dropdownOption: IAviDropdownOption, searchTerm: string): boolean => {
    const label = getDisplayLabel(dropdownOption);

    return label.toLowerCase().includes(searchTerm.toLowerCase());
};

/**
 * Filter label by provided search criterion for custom option templates.
 */
export const isFilteredCustom = (dropdownOptionLabel: string, searchTerm: string): boolean => {
    return dropdownOptionLabel.toLowerCase().includes(searchTerm.toLowerCase());
};

/**
 * Return true if a list of dropdownOptions contains a particular dropdownOption, compared by
 * value.
 */
export const containsDropdownOptionByValue = (
    dropdownOption: IAviDropdownOption,
    dropdownOptions: IAviDropdownOption[] = [],
): boolean => {
    const optionValue = getValue(dropdownOption);

    return any(
        dropdownOptions,
        (selectedDropdownOption: IAviDropdownOption): boolean => {
            return getValue(selectedDropdownOption) === optionValue;
        },
    );
};

/**
 * Return the height of the entire list of options, given the number of options, height of each
 * option, and the maximum allowable height of the list of options.
 */
export const getOptionsHeight = (options = 0, optionHeight = 0, maxHeight: number): number => {
    const optionsHeight = Math.max(optionHeight, options * optionHeight);

    return Math.min(maxHeight, optionsHeight);
};
