/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Represents ControllerSite state.
 */
export interface IControllerSiteState {
    name: string,
    tenant_ref: string,
    url: string,
    uuid: string,
}

/**
 * Represents ControllerSite intial or default state.
 */
export const controllerSiteInitialState: IControllerSiteState = {
    name: null,
    tenant_ref: null,
    url: null,
    uuid: null,
};

/**
 * Represents error.
 */
export interface IError {
    data?: string | {};
}

/**
 * Represents response of ControllerSite api call.
 */
export interface IControllerSiteApiResponse {
    count: number;
    results: IControllerSiteState[];
}
