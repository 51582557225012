/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Type,
} from '@angular/core';

import {
    PKIProfile,
    SSLCertificateConfigItem,
} from 'ajs/modules/security';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { FileObjectCollection } from 'ajs/modules/system/factories/file-object.collection.factory';
import { FileType } from 'generated-types';

import {
    IAviDropdownButtonAction,
} from 'ng/shared/components/avi-dropdown-button/avi-dropdown-button.component';

import { DialogService } from 'ng/modules/core/services/dialog.service';
import { NotificationService } from 'ng/modules/core/services';

import {
    AviContinueConfirmationComponent,
} from 'ng/modules/dialog/components/avi-continue-confirmation/avi-continue-confirmation.component';

import * as globalL10n from 'global-l10n';
import { PkiCrlServerByUrlService } from './services';

import {
    PkiCrlFileUploadDialogComponent,
} from './pki-crl-file-upload-dialog/pki-crl-file-upload-dialog.component';

import { PkiCrlUploadSuccessNotificationComponent } from './pki-crl-upload-success-notification';
import * as l10n from './pki-profile-modal.l10n';
import './pki-profile-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Type of FileObject collection.
 */
type TFileObjectCollection = typeof FileObjectCollection;

/**
 * PKI CRL File Upload dialog ID.
 */
const PKI_CRL_FILE_UPLOAD_DIALOG_ID = 'PKI_CRL_FILE_UPLOAD_DIALOG_ID';

/**
 * PKI CRL File Upload successful notification ID.
 */
const PKI_CRL_FILE_UPLOAD_SUCCESSFUL_NOTIFICATION_ID =
    'PKI_CRL_FILE_UPLOAD_SUCCESSFUL_NOTIFICATION_ID';

/**
 * PKI CRL SERVER URL retrieved successfully notification ID.
 */
const PKI_CRL_SERVER_URL_RETRIEVED_SUCCESSFUL_NOTIFICATION_ID =
    'PKI_CRL_SERVER_URL_RETRIEVED_SUCCESSFUL_NOTIFICATION_ID';

/**
 * Is Federated Change Confirmation Dialog ID.
 */
const IS_FEDERATED_CHANGE_CONFIRMATION_DIALOG_ID = 'IS_FEDERATED_CHANGE_CONFIRMATION_DIALOG_ID';

/**
 * @description Component for PKIProfile modal.
 *
 * @author Satish Pednekar, Sarthak Kapoor
 */
@Component({
    selector: 'pki-profile-modal',
    templateUrl: './pki-profile-modal.component.html',
    providers: [PkiCrlServerByUrlService],
})
export class PkiProfileModalComponent implements OnInit, OnDestroy {
    /**
     * PKIProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: PKIProfile;

    /**
     * PKIProfile object type.
     */
    public objectType: string;

    /**
     * Custom actions for Certificate Revocation List collection dropdown.
     */
    public crlDropdownActions: IAviDropdownButtonAction[] = [];

    /**
     * File Object collection instance.
     */
    public fileObjectCollection: FileObjectCollection;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Layout for PKIProfile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        private readonly l10nService: L10nService,
        private readonly dialogService: DialogService,
        private readonly pkiCrlServerByUrlService: PkiCrlServerByUrlService,
        private readonly notificationService: NotificationService,
        @Inject(FileObjectCollection)
        FileObjectCollection: TFileObjectCollection,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.fileObjectCollection = new FileObjectCollection({
            params: {
                type: FileType.CRL_DATA,
            },
        });
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setCrlDropdownActions();
        this.updateFileObjectCollectionParams();
    }

    /**
     * Opens modal to add new Certificate Authority(CA).
     */
    public onAddCertificateAuthority(): void {
        this.editable.addCertificateAuthority();
    }

    /**
     * Handles Certificate Authority (CA) remove operation.
     */
    public removeCertificateAuthority(certificateAuthority: SSLCertificateConfigItem): void {
        const { pkiCertificateAuthority } = this.editable;

        pkiCertificateAuthority.removeByMessageItem(certificateAuthority);
    }

    /**
     * Set the custom dropdown actions for CRL collection dropdown.
     */
    public setCrlDropdownActions(): void {
        this.crlDropdownActions = [
            {
                label: this.l10nService.getMessage(l10nKeys.addByServerUrlLabel),
                onClick: () => this.openAddCrlByUrlModal(),
            },
            {
                label: this.l10nService.getMessage(globalL10nKeys.uploadFileLabel),
                onClick: () => this.openFileUploadDialog(),
            },
        ];
    }

    /**
     * Open the File Upload dialog.
     */
    public openFileUploadDialog(): void {
        this.dialogService.add({
            id: PKI_CRL_FILE_UPLOAD_DIALOG_ID,
            component: PkiCrlFileUploadDialogComponent as Type<Component>,
            componentProps: {
                isPkiProfileFederated: this.editable.config.is_federated,
                onSuccessfulUpload: () => {
                    this.dialogService.remove(PKI_CRL_FILE_UPLOAD_DIALOG_ID);
                    this.showFileUploadSuccessfulNotification();
                },
                onClose: () => {
                    this.dialogService.remove(PKI_CRL_FILE_UPLOAD_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Handle "Is Federated" Checkbox selection change.
     */
    public onIsFederatedChange(): void {
        if (this.editable.config.crl_file_refs?.length) {
            this.openIsFederatedChangeConfirmationDialog();
        } else {
            this.updateFileObjectCollectionParams();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.fileObjectCollection.destroy();
    }

    /**
     * Open the Add CRL by Server URL modal.
     */
    private openAddCrlByUrlModal(): void {
        const { is_federated: isFederated } = this.editable.config;

        this.pkiCrlServerByUrlService.openModal(isFederated);

        const subscription = this.pkiCrlServerByUrlService.addFileByUrlSuccessful$.subscribe(
            () => this.showServerUrlRetrievalSuccessfulNotification(),
        ).add(
            () => subscription.unsubscribe(),
        );
    }

    /**
     * Show File Upload successful notification.
     */
    private showFileUploadSuccessfulNotification(): void {
        const {
            l10nKeys,
            l10nService,
            notificationService,
        } = this;

        notificationService.add({
            id: PKI_CRL_FILE_UPLOAD_SUCCESSFUL_NOTIFICATION_ID,
            component: PkiCrlUploadSuccessNotificationComponent as Type<Component>,
            componentProps: {
                header: l10nService.getMessage(l10nKeys.fileUploadSuccessfulMessage),
                onClose: () => {
                    notificationService.remove(PKI_CRL_FILE_UPLOAD_SUCCESSFUL_NOTIFICATION_ID);
                },
            },
        });
    }

    /**
     * Show Server url retrieval successful notification.
     */
    private showServerUrlRetrievalSuccessfulNotification(): void {
        const {
            l10nKeys,
            l10nService,
            notificationService,
        } = this;

        notificationService.add({
            id: PKI_CRL_SERVER_URL_RETRIEVED_SUCCESSFUL_NOTIFICATION_ID,
            component: PkiCrlUploadSuccessNotificationComponent as Type<Component>,
            componentProps: {
                header: l10nService.getMessage(l10nKeys.serverUrlRetrievedSuccessfulMessage),
                onClose: () => {
                    notificationService.remove(
                        PKI_CRL_SERVER_URL_RETRIEVED_SUCCESSFUL_NOTIFICATION_ID,
                    );
                },
            },
        });
    }

    /**
     * Open Dialog to confirm if the user wants to change the Is Federated selection.
     */
    private openIsFederatedChangeConfirmationDialog(): void {
        const {
            dialogService,
            editable,
            l10nKeys,
            l10nService,
        } = this;

        dialogService.add({
            id: IS_FEDERATED_CHANGE_CONFIRMATION_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: l10nService.getMessage(l10nKeys.isFederatedChangeWarningMessage),
                customHeader: l10nService.getMessage(l10nKeys.isFederatedChangeConfirmationLabel),
                onConfirm: () => {
                    this.removeSelectedCrls();
                    this.updateFileObjectCollectionParams();

                    dialogService.remove(IS_FEDERATED_CHANGE_CONFIRMATION_DIALOG_ID);
                },
                onClose: () => {
                    editable.resetIsFederated();
                    dialogService.remove(IS_FEDERATED_CHANGE_CONFIRMATION_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Remove the selected CRLs.
     */
    private removeSelectedCrls(): void {
        this.editable.deleteCrlFileRefs();
    }

    /**
     * Update FileObject collection params.
     */
    private updateFileObjectCollectionParams(): void {
        const existingParams = this.fileObjectCollection.getParams();

        const params = {
            ...existingParams,
            is_federated: this.editable.config.is_federated,
        };

        this.fileObjectCollection.setParams(params);
    }
}
