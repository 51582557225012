/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IClusterNode } from 'generated-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ClusterNode } from 'object-types';
import { RepeatedMessageItem } from 'repeated-message-item';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { StaticRouteConfigItem } from './static-route.config-item.factory';
import { ControllerInterfaceConfigItem } from './controller-interface.config-item.factory';

import * as l10n from './cluster-node.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TClusterNodePartial = Omit<
IClusterNode,
'ip' | 'public_ip_or_name' | 'ip6' | 'interfaces' | 'static_routes'
>;

interface IClusterNodeConfig extends TClusterNodePartial {
    ip?: IpAddrConfigItem;
    public_ip_or_name?: IpAddrConfigItem;
    ip6?: IpAddrConfigItem;
    static_routes?: RepeatedMessageItem<StaticRouteConfigItem>;
    interfaces?: RepeatedMessageItem<ControllerInterfaceConfigItem>;
}

/**
 * @description
 *      Cluster Node Message Item class for setting up nodes.
 * @author Ratan Kumar, alextsg
 */
export class ClusterNodeConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IClusterNodeConfig, typeof MessageItem>(MessageItem),
)<IClusterNodeConfig> {
    public static ngDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: ClusterNode,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getNgDependency('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter to get publicIp config.
     */
    public get publicIp(): IpAddrConfigItem {
        return this.config.public_ip_or_name;
    }

    /**
     * Getter to get Ip config.
     */
    public get ip(): IpAddrConfigItem {
        return this.config.ip;
    }

    /**
     * Return true if either an IPv4 or IPv6 address has been configured.
     */
    public hasIpAddress(): boolean {
        return Boolean(this.ip.address || this.config.ip6.address);
    }

    /**
     * Clear existing IPv4 and IPv6 addresses.
     */
    public clearAddresses(): void {
        this.ip.address = undefined;
        this.config.ip6.address = undefined;
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            ClusterNodeModalComponent,
        } = await import(
            /* webpackChunkName: "cluster-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/cluster-modal/cluster-node-modal/cluster-node-modal.component'
        );

        return ClusterNodeModalComponent as Type<Component>;
    }

    /**
     * @override
     * If the name field is not populated, set it as the IPv4 or IPv6 address.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        if (!this.config.name) {
            const name = this.ip.address || this.config.ip6.address;

            this.config.name = name;
        }
    }

    /**
     * Return the controller interfaces repeated message item.
     */
    public get controllerInterfaces(): RepeatedMessageItem<ControllerInterfaceConfigItem> {
        return this.config.interfaces;
    }

    /**
     * Edit an existing controller interface. Controller interfaces cannot be added.
     */
    public editControllerInterface(controllerInterface: ControllerInterfaceConfigItem): void {
        this.editChildMessageItem({
            field: 'interfaces',
            messageItem: controllerInterface,
            modalBindings: {
                isEditing: true,
            },
        });
    }

    /**
     * Add Static Route Message Item.
     * The route_id is a unique identifier for a static route. There's no guarantee that all static
     * routes have sequential route_ids without gaps because static routes can be deleted, and users
     * may configure static routes through the CLI with random values, so this method iterates
     * through all static routes to search for the largest numerical route_id and sets the new
     * static route's route_id to be maxRouteId + 1.
     */
    public addStaticRoute(): void {
        const { static_routes: staticRoutes } = this.config;
        let maxRouteId = 0;

        staticRoutes.config.forEach(staticRoute => {
            const routeId = Number(staticRoute.routeId);

            if (routeId > maxRouteId) {
                maxRouteId = routeId;
            }
        });

        staticRoutes.add();
        staticRoutes.at(staticRoutes.count - 1).routeId = (maxRouteId + 1).toString();
    }

    /**
     * Delete a Static Route Message Item.
     */
    public deleteStaticRoute(staticRoute: StaticRouteConfigItem): void {
        this.config.static_routes.removeByMessageItem(staticRoute);
    }

    /**
     * Method will load required Field on add or edit action.
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'ip',
            'public_ip_or_name',
            'ip6',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.clusterNodeBreadcrumbTitle);
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IClusterNodeConfig> {
        const defaultValues = this.getNgDependency('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            name: '',
        };
    }
}
