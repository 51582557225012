<full-modal-config
    modalTitle="{{ l10nKeys.modalTitle | vtranslate }}"
    (onCancel)="dismiss()"
    (onSubmit)="submit()"
    [busy]="isBusy()"
    [errors]="errors"
    modalClassName="welcome-modal"
    [modified]="form.touched && form.dirty"
    [valid]="form.valid && welcomeFormComplete"
>
    <form
        clrForm
        #form="ngForm"
        [clrLayout]="verticalLayout"
        clrStepper
    >
        <clr-stepper-panel ngModelGroup="system-settings">
            <clr-step-title>{{ l10nKeys.systemSettingsTabHeader | vtranslate }}</clr-step-title>
            <clr-step-description>{{ l10nKeys.systemSettingsTabDescription | vtranslate }}</clr-step-description>
            <clr-step-content>
                <welcome-system-settings
                    [systemConfig]="systemConfig"
                    [backupConfig]="backupConfig"
                ></welcome-system-settings>
                <button
                    class="welcome-modal__next-btn sel-welcome-modal__next-btn"
                    clrStepButton="next"
                    [disabled]="form.invalid"
                >
                    {{ l10nKeys.nextButtonLabel | vtranslate }}
                </button>
            </clr-step-content>
        </clr-stepper-panel>

        <clr-stepper-panel ngModelGroup="email-settings">
            <clr-step-title>{{ l10nKeys.emailSettingsTabHeader | vtranslate }}</clr-step-title>
            <clr-step-content>
                <welcome-email-settings [emailConfig]="systemConfig.getEmailConfig()">
                </welcome-email-settings>
                <button
                    class="welcome-modal__next-btn sel-welcome-modal__next-btn"
                    clrStepButton="next"
                    [disabled]="form.invalid"
                    (click)="completeWelcomeFlowSteps()"
                >
                {{ l10nKeys.nextButtonLabel | vtranslate }}
                </button>
            </clr-step-content>
        </clr-stepper-panel>

        <clr-stepper-panel ngModelGroup="tenant-settings">
            <clr-step-title>{{ l10nKeys.tenantSettingsTabHeader | vtranslate }}</clr-step-title>
            <clr-step-content>
                <welcome-tenant-settings [tenantConfig]="systemConfig.getTenantConfig()">
                </welcome-tenant-settings>
            </clr-step-content>
        </clr-stepper-panel>
    </form>
    <full-modal-config_footer>
        <clr-checkbox-container class="welcome-modal__setup-cloud-checkbox-container">
            <clr-checkbox-wrapper>
                <input
                    type="checkbox"
                    clrCheckbox
                    name="setupCloudAfter"
                    [(ngModel)]="openCloudModal"
                    id="setupCloudAfter"
                />
                <label
                    for="setupCloudAfter"
                    class="welcome-modal__setup-cloud-label sel-welcome-modal__setup-cloud-label"
                >
                    {{ l10nKeys.setupCloudAfterCheckboxLabel | vtranslate }}
                </label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </full-modal-config_footer>
</full-modal-config>
