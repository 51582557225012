<div provideParentForm>
    <avi-fieldset>
        <avi-fieldset_header class="match-wrapper__avi-fieldset-header">
            <div class="match-wrapper__header">
                <label
                    aviLabelWithTooltip
                    objectType="{{ objectType }}"
                    fieldName="{{ fieldName }}"
                >
                    {{ label }}
                    <span 
                        *ngIf="subLabel"
                        class="match-wrapper__header-sub-label"
                    >
                        {{ subLabel }}
                    </span>
                </label>
                <cds-icon
                    [hidden]="!showRemoveIcon"
                    shape="trash"
                    size="18"
                    status="info"
                    class="match-wrapper__remove-icon"
                    (click)="removeMatch()"
                ></cds-icon>
            </div>
        </avi-fieldset_header>
        <avi-fieldset_content>
            <ng-content></ng-content>
        </avi-fieldset_content>
    </avi-fieldset>
</div>
