<vs-log-cinematic-sub-section-wrapper header="{{ l10nKeys.grpcLabel | vtranslate }}">
    <div class="vs-log-cinematic-section-protocol">
        <ng-container *ngrxLet="grpcInfo$ as grpcInfo">
            <!-- Left column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.grpcServiceNameLabel | vtranslate }}
                </div>
                <span
                    *ngIf="grpcInfo.grpc_service_name as grpcServiceName; else na"
                    vsLogFilter
                    property="grpc_service_name"
                    [value]="grpcServiceName"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ grpcServiceName }}
                </span>
            </div>

            <!-- Middle column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.grpcMethodNameLabel | vtranslate }}
                </div>
                <span
                    vsLogFilter
                    *ngIf="grpcInfo.grpc_method_name as methodName; else na"
                    property="grpc_method_name"
                    [value]="methodName"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"

                >
                    {{ methodName }}
                </span>
            </div>

            <!-- Right column -->
            <div vsLogCinematicSectionColumn>
                <div
                    vsLogCinematicFieldTitle
                    [withMarginTop]="false"
                >
                    {{ l10nKeys.grpcStatusReasonPhraseLabel | vtranslate }}
                </div>
                <span
                    vsLogFilter
                    *ngIf="grpcInfo.grpc_status_reason_phrase as reasonPhrase; else na"
                    property="grpc_status_reason_phrase"
                    [value]="reasonPhrase"
                    (onFilterAdd)="vsLogCinematicStore.closeCinematic()"
                >
                    {{ reasonPhrase }}
                </span>
            </div>
        </ng-container>
    </div>
</vs-log-cinematic-sub-section-wrapper>

<ng-template #na>
    <span vsLogEmptyField></span>
</ng-template>
