/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const editSystemSettingsTooltip = `${prefix}.editSystemSettingsTooltip`;

export const ENGLISH = {
    [headerLabel]: 'System Settings',
    [editSystemSettingsTooltip]: 'Edit System Settings',
};
