/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'data-grid';
const componentName = 'avi-data-grid-column-selection';
const prefix = `${moduleName}.${componentName}`;

export const columnSelectAllButton = `${prefix}.columnSelectAllButton`;
export const showColumnsHeader = `${prefix}.showColumnsHeader`;

export const ENGLISH = {
    [columnSelectAllButton]: 'Select All',
    [showColumnsHeader]: 'Show Columns',
};
