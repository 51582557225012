/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { skip } from 'rxjs/operators';
import { STORE_TOKEN } from 'ng/root-store/root-store.tokens';
import {
    selectLanguage,
    selectUtcTime,
} from 'ng/root-store/user-preferences/user-preferences.selectors';
import { setLoading } from 'ng/root-store/root-store.actions';

import {
    UtcMismatchNotificationComponent,
} from 'ng/modules/notification/components/utc-mismatch-notification';

import {
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
} from '../../downgrade-services.tokens';
import './avi-root.less';

/**
 * Module for Avi App. It's the root module for Avi App.
 * @module avi/app
 */

const UTC_MISMATCH_NOTIFICATION_ID = 'utc-mismatch-notification';

/**
 * @constructor
 * @memberOf module:avi/app
 * @see {@link  module:avi/app.aviRootComponent aviRootComponent}
 */
class AviRootController {
    constructor(
        $scope,
        notificationService,
        initialDataService,
        centralLicenseAlertsService,
        $rootScope,
        store,
        $state,
        $transitions,
    ) {
        /**
         * @type {angular.$scope}
         * @protected
         */
        this.$scope_ = $scope;

        this.notificationService_ = notificationService;

        /**
         * @type {initialDataService}
         * @protected
         */
        this.initialDataService_ = initialDataService;

        /**
         * @type {module:avi/core.CentralLicenseAlertsService}
         * @protected
         */
        this.centralLicenseAlertsService_ = centralLicenseAlertsService;

        this.store_ = store;

        this.$rootScope_ = $rootScope;

        this.useUtcTime_ = false;

        this.useUtcTimeSubscription_ = null;

        this.languageSubscription_ = null;

        this.$state_ = $state;

        this.$transitions = $transitions;
    }

    /**
     * @override
     */
    $onInit() {
        /**
         * Time difference between controller and client clocks in seconds.
         * @type {number}
         */
        const { controllerTimeDifference } = this.initialDataService_;

        if (Math.abs(controllerTimeDifference) > 120) {
            this.notificationService_.add({
                id: UTC_MISMATCH_NOTIFICATION_ID,
                component: UtcMismatchNotificationComponent,
            });
        }

        this.centralLicenseAlertsService_.loadAlerts();

        // So that we repaint when we change the timeframe
        // (at some point switch to just repainting the axes)
        // Moved from core.init.ts so that the subscription can be unsubscribed.
        // TODO remove once charts no longer listen to rootScope repaint events.
        this.useUtcTimeSubscription_ = this.store_.select(selectUtcTime)
            .pipe(skip(1))
            .subscribe(useUtcTime => {
                if (this.useUtcTime_ !== useUtcTime) {
                    this.$rootScope_.$broadcast('repaint');
                }

                this.useUtcTime_ = useUtcTime;
            });

        this.languageSubscription_ = this.store_.select(selectLanguage)
            .pipe(skip(1))
            .subscribe(() => this.$state_.reload());

        this.deregisterTransitionStart = this.$transitions.onStart({}, this.transitionStartHandler);

        this.deregisterTransitionFinish = this.$transitions.onFinish(
            {},
            this.transitionFinishHandler,
        );
    }

    /**
     * @override
     */
    $onDestroy() {
        if (this.notificationService_.has(UTC_MISMATCH_NOTIFICATION_ID)) {
            this.notificationService_.remove(UTC_MISMATCH_NOTIFICATION_ID);
        }

        this.useUtcTimeSubscription_.unsubscribe();
        this.languageSubscription_.unsubscribe();

        this.deregisterTransitionStart();
        this.deregisterTransitionFinish();
    }

    /**
     * Handler for ui-router transition start.
     */
    transitionStartHandler = () => {
        this.store_.dispatch(setLoading({ loading: true }));
    };

    /**
     * Handler for ui-router transition finish.
     */
    transitionFinishHandler = () => {
        this.store_.dispatch(setLoading({ loading: false }));
    };
}

AviRootController.$inject = [
    '$scope',
    'notificationService',
    'initialDataService',
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
    '$rootScope',
    STORE_TOKEN,
    '$state',
    '$transitions',
];

/**
 * @name aviRootComponent
 * @property {module:avi/app.AviRootController} controller
 * @memberOf module:avi/app
 * @description Application root component.
 * @author alextsg, chitra
 */
angular.module('avi/app').component('aviRoot', {
    controller: AviRootController,
    templateUrl: 'src/components/avi-root/avi-root.html',
});
