/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { StringService } from 'string-service';

/**
 * @description Pipe to get object name from the url.
 *
 * @author Hitesh Mandav
 */

@Pipe({
    name: 'name',
})
export class NamePipe implements PipeTransform {
    constructor(public readonly stringService: StringService) {}

    /**
     * Transforms URL to name.
     */
    public transform(val: string): string {
        return this.stringService.name(val || '');
    }
}
