/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module UpdateModule
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrLoadingState } from '@cds/core/button';
import { IHttpResponse } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { UpdateService } from 'ng/modules/update/services/update.service';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';

import {
    ImageCollection,
    TImageCollection,
} from 'ajs/modules/upgrade/factories/image-collection.factory';

import {
    SeGroupOptions,
    UpgradeParams,
} from 'object-types';

import {
    IUpgradePreviewResponse,
    IUpgradeResponse,
    PatchType,
    RollbackType,
    SeGroupErrorRecovery,
    UpgradeOperation,
} from 'generated-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { IEnumValue } from 'ajs/modules/core/services/schema-service/schema.types';
import { UpdateStore } from '../update.store';

import {
    imageCategoryHash,
    imageTypeHash,
    IUpdateConfig,
    UpgradeType,
} from '../../update.types';

import './system-update-modal.component.less';
import * as l10n from './system-update-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description System update modal component.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'system-update-modal',
    templateUrl: './system-update-modal.component.html',
})
export class SystemUpdateModalComponent implements OnInit {
    @Input()
    public upgradeConfig: IUpdateConfig;

    /**
     * Keep a track if the modal is opened from System update page or SEG Page.
     */
    @Input()
    public isSystemUpdatePage = false;

    /**
     * Fire on click of Upgrade/Rollback button on the dialog.
     */
    @Output()
    public onConfirm = new EventEmitter<void>();

    /**
     * Fire on click of cancel button on the dialog.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Event fired on completion of rollback/upgrade to update parent component
     * whether SEG upgrade option is selected.
     */
    @Output()
    public isSegUpgradeSelected = new EventEmitter<boolean>();

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public modalTitle: string;

    /**
     * To use UpgradeType enum values inside template.
     */
    public UpgradeType = UpgradeType;

    /**
     * Hash of ClrLoadingState enum for clr button state.
     */
    public loadingStateEnumHash = {
        default: ClrLoadingState.default,
        loading: ClrLoadingState.loading,
    };

    /**
     * ObjectTypes used in template.
     */
    public readonly objectTypes = {
        SeGroupOptions,
        UpgradeParams,
    };

    /**
     * True when applying upgrade/rollback to controller and all service engine groups.
     * False when only upgrading/rollback controller.
     */
    public applyToSystem = false;

    /**
     * ID of the system image selected when opening the modal, if there exists one.
     */
    public initialSystemImageId = '';

    /**
     * Patch image ID which will be applied to the SEGs for system upgrade.
     */
    public selectedSegSystemUpgradePatchImageId = '';

    /**
     * List of options of compatible SEG system upgrade patch versions.
     */
    public segSystemUpgradePatchVersionOptions: IAviDropdownOption[] = [];

    /**
     * List values of segErrorRecovery enum.
     */
    public segErrorRecoveryEnumValues: IEnumValue[];

    /**
     * Hash of SeGroupErrorRecovery enum.
     */
    public readonly seGroupErrorRecoveryEnumHash = {
        ROLLBACK_UPGRADE_OPS_ON_ERROR: SeGroupErrorRecovery.ROLLBACK_UPGRADE_OPS_ON_ERROR,
        CONTINUE_UPGRADE_OPS_ON_ERROR: SeGroupErrorRecovery.CONTINUE_UPGRADE_OPS_ON_ERROR,
        SUSPEND_UPGRADE_OPS_ON_ERROR: SeGroupErrorRecovery.SUSPEND_UPGRADE_OPS_ON_ERROR,
    };

    /**
     * Check point list from backend for users to confirm before continuing with must checks.
     */
    public previewCheckList: string[];

    /**
     * True when PreUpgradePrompts returns errors.
     */
    public fetchUpgradePreviewFailed = false;

    /**
     * Busy flag to render a spinner.
     */
    public busy = false;

    /**
     * Error to be displayed if an error occurs.
     */
    public error: string | null;

    /**
     * Flag indicating if is upgrade or rollback mode.
     */
    public isUpgradeMode: boolean;

    /**
     * Show/Hide SE group upgrade related fields.
     */
    public isSegUpgradeAvailable: boolean;

    public upgradeType: UpgradeType;

    /**
     * Action to be taken when SEG upgrade hits error.
     */
    public actionOnSegFailure: SeGroupErrorRecovery;

    /**
     * Show/Hide Upgrade all SEG checkbox.
     */
    public showUpgradeSegCheckbox: boolean;

    constructor(
        private readonly l10nService: L10nService,
        private readonly updateService: UpdateService,
        @Inject(ImageCollection)
        private readonly ImageCollectionFactory: TImageCollection,
        private readonly schemaService: SchemaService,
        private readonly updateStore: UpdateStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.upgradeType = this.upgradeConfig.upgradeType;
        this.segErrorRecoveryEnumValues = this.schemaService.getEnumValues('SeGroupErrorRecovery');

        this.isUpgradeMode = this.isUpgrade();
        this.modalTitle = this.getModalTitle();
        this.isSegUpgradeAvailable = UpdateService.isSegUpgradeAvailable(this.upgradeConfig);

        this.applyToSystem = this.isSegUpgradeAvailable && this.isSystemUpdatePage;

        this.showUpgradeSegCheckbox = this.isSegUpgradeAvailable &&
            (this.isType(UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE) ||
                this.isType(UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK));

        // Sets default value in `Action to take` radio button for upgrade mode.
        this.actionOnSegFailure = SeGroupErrorRecovery.SUSPEND_UPGRADE_OPS_ON_ERROR;

        if (this.isSystemUpgrade() && this.isSegUpgradeAvailable) {
            this.setInitialImageIds();
            this.initSegSystemUpgradePatchVersionOptions();
        }

        this.populatePreviewCheckList();
    }

    /**
     * Handle submit.
     */
    public handleSubmit(): void {
        const {
            actionOnSegFailure,
            applyToSystem,
            updateStore,
            upgradeConfig,
            upgradeType,
        } = this;

        const userSelectedOptions = {
            actionOnSegFailure,
            applyToSystem,
            upgradeConfig,
            upgradeType,
        };

        this.busy = true;
        this.error = null;

        this.updateStore
            .triggerPrechecks(userSelectedOptions, false).then(() => {
                this.isSegUpgradeSelected.emit(this.applyToSystem);
                updateStore.setUserSelectedOptions(userSelectedOptions);
                updateStore.setIsSystemUpdatePage(this.isSystemUpdatePage);
                this.onConfirm.emit();
            })
            .catch(({ data: { status } }: IHttpResponse<IUpgradeResponse>) => {
                this.error = status;
            }).finally(() => this.busy = false);
    }

    /**
     * Handle modal close.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    public trackByEnumValue(index: number, { value }: IEnumValue): IEnumValue['value'] {
        return value;
    }

    /**
     * Handler for SEG uprade version dropdown.
     */
    public handleSelectedSegSystemUpgradeVersionChange(): void {
        if (!this.selectedSegSystemUpgradePatchImageId) {
            delete this.upgradeConfig.sePatchImageId;

            return;
        }

        this.upgradeConfig.sePatchImageId = this.selectedSegSystemUpgradePatchImageId;
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Get check list from upgrade/rollback previews.
     */
    private populatePreviewCheckList(): void {
        this.busy = true;
        this.error = '';

        this.updateService.getPreUpgradePrompts(this.upgradeConfig)
            .then(({ data: { checks } }: IHttpResponse<IUpgradePreviewResponse>) =>
                this.previewCheckList = checks)
            .catch(error => {
                this.error = error.data?.error;
                this.fetchUpgradePreviewFailed = true;
            })
            .finally(() => this.busy = false);
    }

    /**
     * Set initial selected image IDs used for upgrade when modal is first opened.
     */
    private setInitialImageIds(): void {
        const { systemImageId, sePatchImageId } = this.upgradeConfig;

        this.initialSystemImageId = systemImageId || '';
        this.selectedSegSystemUpgradePatchImageId = sePatchImageId || '';
    }

    /**
     * Initialize avi-dropdown options for compatible SEG system upgrade patch versions.
     */
    private initSegSystemUpgradePatchVersionOptions(): void {
        const { IMAGE_TYPE_PATCH } = imageTypeHash;
        const { IMAGE_CATEGORY_HYBRID } = imageCategoryHash;

        const requestBaseVersion =
            UpdateService.getBaseVersionForSystemUpgradeSegPatch(this.upgradeConfig);

        const compatiblePatchImageCollection = new this.ImageCollectionFactory(
            {
                objectName: 'image-raw-inventory',
                limit: 200,
                params: {
                    base_image_version: requestBaseVersion,
                    upgrade_operation: UpgradeOperation.SE_AS_PART_OF_SYSTEM_UPGRADE_OP,
                    image_type: IMAGE_TYPE_PATCH,
                    patch_type: PatchType.PATCH_SYSTEM,
                },
            },
        );

        compatiblePatchImageCollection.load()
            .then(() => {
                const systemPatchImages = compatiblePatchImageCollection.itemList.filter(
                    (image: Image) => image.category === IMAGE_CATEGORY_HYBRID &&
                        !image.isUsedBySeg(),
                );

                this.segSystemUpgradePatchVersionOptions =
                    this.getSegSystemUpgradePatchDropdownOptionsFromImages(
                        systemPatchImages as Image[],
                    );
            })
            .finally(() => {
                compatiblePatchImageCollection.destroy();
            });
    }

    /**
     * Get SE patch options used for avi-dropdown. System upgrade use only. Mark label as 'default'
     * if an SE system patch is already selected from the parent upon modal opening.
     */
    private getSegSystemUpgradePatchDropdownOptionsFromImages(images: Image[]) :
    IAviDropdownOption[] {
        const { sePatchImageId: initialSePatchImageId } = this.upgradeConfig;

        return images.map(image => {
            const { id } = image;
            const isDefaultId = id === initialSePatchImageId;

            return createDropdownOption(
                id,
                `${image.version}${isDefaultId ? ' (default)' : ''}`,
            );
        });
    }

    /**
     * Generate modal title based on upgrade/rollback type.
     */
    private getModalTitle(): string {
        const { l10nService } = this;
        const { targetVersion } = this.upgradeConfig;

        if (this.isUpgradeMode) {
            return l10nService.getMessage(l10nKeys.upgradeHeader, [targetVersion]);
        }

        if (this.isRollback()) {
            const { rollbackType } = this.upgradeConfig;

            switch (rollbackType) {
                case RollbackType.ROLLBACK_TYPE_IMAGE:
                    return l10nService.getMessage(l10nKeys.rollbackVersionHeader,
                        [targetVersion]);

                case RollbackType.ROLLBACK_TYPE_PATCH:
                    return l10nService.getMessage(l10nKeys.clearPatchHeader, [targetVersion]);
            }
        }

        return '';
    }

    /**
     * Decide if modal is handling upgrade, including both main version upgrade and patching.
     */
    private isUpgrade(): boolean {
        return this.isType(UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE) ||
        this.isType(UpgradeType.UPGRADE_TYPE_SEG_UPGRADE);
    }

    /**
     * Decide if modal is handling rollback, including rollbacks of both main version and patch.
     */
    private isRollback(): boolean {
        return this.isType(UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK) ||
        this.isType(UpgradeType.UPGRADE_TYPE_SEG_ROLLBACK);
    }

    /**
     * Decide whether the modal is handling system upgrade.
     */
    private isSystemUpgrade(): boolean {
        return this.isType(UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE);
    }

    /**
     * Convenience method to check upgradeType.
     */
    private isType(type: UpgradeType): boolean {
        return this.upgradeType === type;
    }
}
