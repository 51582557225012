/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'ldap-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleLdap = `${prefix}.sectionTitleLdap`;
export const sectionTitleLdaps = `${prefix}.sectionTitleLdaps`;
export const directoryInformationLabel = `${prefix}.directoryInformationLabel`;
export const distinguishedNameLabel = `${prefix}.distinguishedNameLabel`;
export const distinguishedNamePlaceholder = `${prefix}.distinguishedNamePlaceholder`;
export const attributesLabel = `${prefix}.attributesLabel`;
export const attributesPlaceholder = `${prefix}.attributesPlaceholder`;
export const scopeModeLabel = `${prefix}.scopeModeLabel`;
export const scopeModePlaceholder = `${prefix}.scopeModePlaceholder`;
export const filterLabel = `${prefix}.filterLabel`;
export const filterPlaceholder = `${prefix}.filterPlaceholder`;
export const tlsSniServerPlaceholder = `${prefix}.tlsSniServerPlaceholder`;

export const ENGLISH = {
    [sectionTitleLdap]: 'LDAP',
    [sectionTitleLdaps]: 'LDAPS',
    [directoryInformationLabel]: 'Directory Information',
    [distinguishedNameLabel]: 'Distinguished Name',
    [distinguishedNamePlaceholder]: 'Enter Distinguished Name',
    [attributesLabel]: 'Attributes',
    [attributesPlaceholder]: 'Enter Attributes',
    [scopeModeLabel]: 'Scope Mode',
    [scopeModePlaceholder]: 'Select Scope Mode',
    [filterLabel]: 'Filter',
    [filterPlaceholder]: 'Enter Filter',
    [tlsSniServerPlaceholder]: 'Enter TLS SNI Server Name',
};
