<avi-card class="system-about-card">
    <avi-card_header>
        <div class="system-about-card__header">
            <cds-icon
                shape="help-info"
                size="24"
                class="system-about-card__help-info"
            ></cds-icon>
            {{ l10nKeys.aboutHeader | vtranslate }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <system-about-info></system-about-info>
        <ng-content select="system-about-card_additional"></ng-content>
    </avi-card_body>
    <avi-card_footer *ngIf="showFooter">
        <ng-content select="system-about-card_footer"></ng-content>
    </avi-card_footer>
</avi-card>
