/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
    fipsOperationalHash,
    imageCategoryHash,
    imageTypeHash,
    IRollbackOption,
    ISystemRollbackInfo,
    IUpdateConfig,
    nodeTypesHash,
    UpgradePrechecksStatusTypeHash,
    UpgradeType,
} from 'ng/modules/update/update.types';

import { DialogService } from 'ng/modules/core/services/dialog.service';
import { InitialDataService } from 'ng/modules/core/services/initial-data/initial-data.service';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';

import {
    HttpWrapper,
    IHttpWrapperRequestConfig,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import {
    ImageCollection,
    TImageCollection,
} from 'ajs/modules/upgrade/factories/image-collection.factory';

import {
    IUpgradeStatusInfo,
    RollbackType,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { IAviCollectionDataGridConfig } from
    'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';
import { SystemConfig } from 'ajs/modules/system/factories/system-config.item.factory';
import { UpdateService } from 'ng/modules/update/services/update.service';
import { AsyncFactory } from 'ajs/modules/core/factories/async-factory/async.factory';
import * as globalL10n from 'global-l10n';

import { SystemUpdateModalComponent } from '../system-update-modal/system-update-modal.component';
import { UpdateStore } from '../update.store';
import * as l10n from './system-update.l10n';

import './system-update.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

const SYSTEM_UPDATE_DIALOG_ID = 'system-update-dialog';
const SYSTEM_ROLLBACK_DIALOG_ID = 'system-rollback-dialog';

type TAsyncFactory = typeof AsyncFactory;

/**
 * Set of UpgradePrecheck Status.
 */
const PRECHECK_STATUS = new Set([
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_ERROR,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_IN_PROGRESS,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_STARTED,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_WARNING,
]);

/**
 * @description
 * Component for System Update Page.
 * @author Vinay Jadhav
 */
@Component({
    selector: 'system-update',
    templateUrl: './system-update.component.html',
})
export class SystemUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input('loadedSystemConfigService')
    public systemConfig: SystemConfig;

    /**
     * TemplateRef for Image#type column.
     */
    @ViewChild('imageTypeTemplateRef')
    public readonly imageTypeTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly upgradeNodeType = nodeTypesHash.NODE_CONTROLLER_CLUSTER;

    public enableRollback = false;

    public rollbackOptions: IRollbackOption[] = [];

    /**
     * Collection for images
     */
    public imageCollection: ImageCollection;

    public fipsOperational = fipsOperationalHash.FIPS_TRUE;

    /**
     * AsyncFactory polling instance. Used to poll for the fips_operational flag.
     */
    public pollingFipsOperational: AsyncFactory = null;

    public readonly isControllerUpgrade = true;
    /**
     * Config object for grid listing images.
     */
    public imageCollectionGridConfig: IAviCollectionDataGridConfig;

    /**
     * List of nodes received from backend on trigger of prechecks.
     */
    public precheckNodes = this.updateStore.precheckNodes$;

    public readonly arePrechecksTriggered$ = this.updateStore.preChecksTriggered$;

    /**
     * Overall state of prechecks for all the selected nodes.
     */
    public readonly overallPrechecksState$ = this.updateStore.overallPrechecksState$;

    /**
     * Flag to track if the system update is in progress.
     */
    public isSystemUpdateInProgress = false;

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    private rollbackInfo: ISystemRollbackInfo;

    private isSystemUpdateInProgressSubscription: Subscription;

    private versionInformation: {
        targetVersion: string,
        targetMajorVersion: string,
        targetPatchVersion: string
    } = {
        targetVersion: '',
        targetMajorVersion: '',
        targetPatchVersion: '',
    };
    constructor(
        private readonly l10nService: L10nService,
        private readonly dialogService: DialogService,
        private readonly initDataService: InitialDataService,
        @Inject(ImageCollection)
        private readonly ImageCollectionFactory: TImageCollection,
        private readonly updateService: UpdateService,
        private readonly updateStore: UpdateStore,
        @Inject('AsyncFactory')
        private readonly AsyncFactory: TAsyncFactory,
        @Inject(HttpWrapper)
        private HttpWrapper: THttpWrapper,
    ) {
        this.setInitialUpgradeStatusInfo();
        l10nService.registerSourceBundles(dictionary);

        this.httpWrapper = new HttpWrapper();
        this.loadImages(this.initDataService.controllerVersion);
        this.setFipsOperationalPolling();
    }

    /** @override */
    public ngOnInit(): void {
        this.isSystemUpdateInProgressSubscription =
            this.updateStore.isSystemUpdateInProgress$.subscribe(
                isSystemUpdateInProgress => {
                    this.isSystemUpdateInProgress = isSystemUpdateInProgress;
                },
            );
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.setGridConfig();
    }

    /**
     * Set config for system rollback, including type and the base version to be rollbacked to or
     * the full patch version that need to be reverted.
     * type - Rollback type, image or patch.
     */
    public getSystemRollbackConfig(rollbackType: RollbackType): IUpdateConfig {
        let targetVersion = '';

        const { rollbackInfo } = this;

        switch (rollbackType) {
            case RollbackType.ROLLBACK_TYPE_IMAGE:
                targetVersion = rollbackInfo?.previousPatchVersion ?
                    [rollbackInfo.previousVersion, rollbackInfo.previousPatchVersion].join('-') :
                    rollbackInfo.previousVersion;
                break;

            case RollbackType.ROLLBACK_TYPE_PATCH:
                targetVersion = `${rollbackInfo.version}-${rollbackInfo.patchVersion}`;
                break;
        }

        return {
            upgradeType: UpgradeType.UPGRADE_TYPE_SYSTEM_ROLLBACK,
            rollbackType,
            targetVersion,
        };
    }

    /**
     * Set options for rollback dropdown menu.
     * rollbackInfo - Info about rollback to generate options.
     */
    public setSystemRollbackOptions(rollbackInfo: ISystemRollbackInfo): void {
        this.rollbackOptions = [];

        if (rollbackInfo?.enableImageRollback) {
            const fullVersion = rollbackInfo?.patchVersion ?
                `${rollbackInfo?.version}-${rollbackInfo?.patchVersion}` : rollbackInfo?.version;

            const fullPreviousVersion = rollbackInfo?.previousPatchVersion ?
                `${rollbackInfo?.previousVersion}-${rollbackInfo?.previousPatchVersion}` :
                rollbackInfo?.previousVersion;

            const imageRollbackOption = {
                title: this.l10nService.getMessage(this.globalL10nKeys.versionLabel),
                value: RollbackType.ROLLBACK_TYPE_IMAGE,
                fullVersion,
                fullPreviousVersion,
            };

            this.rollbackOptions.push(imageRollbackOption);
        }

        if (rollbackInfo?.enablePatchRollback) {
            const patchRollbackOption = {
                title: this.l10nService.getMessage(this.l10nKeys.clearPatchLabel),
                value: RollbackType.ROLLBACK_TYPE_PATCH,
                fullVersion: `${rollbackInfo?.version}-${rollbackInfo?.patchVersion}`,
                fullPreviousVersion: `${rollbackInfo?.version}`,
            };

            this.rollbackOptions.push(patchRollbackOption);
        }
    }

    public onRollbackOptionClick(rollbackType: RollbackType): void {
        const upgradeConfig = this.getSystemRollbackConfig(rollbackType);

        this.openDialogModal(upgradeConfig);
    }

    /**
     * Set up an AsyncFactory instance to poll for the fips_operational flag. If the flag returns
     * FIPS_UNKNOWN, then we continue polling because it means that a warm reboot is still in
     * progress. If the status is either FIPS_TRUE or FIPS_FALSE then we can stop polling.
     */
    public setFipsOperationalPolling(): void {
        const requestConfig = {
            url: '/api/cluster/runtime',
            method: 'GET',
        };

        this.pollingFipsOperational = new this.AsyncFactory(async() => {
            const response = await this.httpWrapper.request(
                requestConfig as IHttpWrapperRequestConfig,
            );

            if (response && response.data) {
                const { node_info: nodeInfo } = response.data;

                this.fipsOperational = nodeInfo.fips_operational;

                if (this.fipsOperational !== fipsOperationalHash.FIPS_UNKNOWN) {
                    this.pollingFipsOperational.stop();
                }
            }
        });

        this.pollingFipsOperational.start(999);
    }

    /**
     * @override
     * Reset PrechecksNodes, prechecksTriggered and precheckCompleted state
     * so the store doesn't hold the previously fetched value in case user navigates to another page
     * and then comes back to system update page.
     */
    public ngOnDestroy(): void {
        const {
            pollingFipsOperational,
            updateStore,
        } = this;

        pollingFipsOperational?.stop();
        updateStore.resetPrecheckNodes();
        updateStore.resetPreChecksTriggered();
        updateStore.resetPrechecksCompleted();
        updateStore.resetOverallPrechecksStatusState();
        updateStore.setIsPreCheckFlow(false);
        this.isSystemUpdateInProgressSubscription.unsubscribe();
    }

    /**
     * Set avi-collection-data-grid config
     */
    private setGridConfig(): void {
        this.imageCollectionGridConfig = {
            layout: {
                disableCreate: true,
                hideCreate: true,
                hideDelete: true,
                hideEdit: true,
            },
            id: `${this.imageCollection?.objectName}-list-page`,
            collection: this.imageCollection,
            fields: [
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.typeLabel),
                    id: 'type',
                    templateRef: this.imageTypeTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(this.globalL10nKeys.versionLabel),
                    id: 'version',
                    transform: (row: Image): string => {
                        return row.isUsedByController() ?
                            this.l10nService.getMessage(
                                l10nKeys.currentVersionLabel,
                                [row.version],
                            ) : row.version;
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAttributes),
                    id: 'attribute',
                    transform: (row: Image): string => row.category,
                },
            ],
            multipleactions: [
                {
                    id: 'upgrade',
                    label: this.l10nService.getMessage(this.globalL10nKeys.upgradeLabel),
                    onClick: (rows: Image[]) => this.onUpgradeClick(rows),
                    disabled: (rows: Image[]) => {
                        return !this.isImageSelectionValid(rows) || this.isSystemUpdateInProgress;
                    },
                },
            ],
            rowSelectionDisabled: (row: Image): boolean => this.isCheckboxDeactivated(row),
        };
    }

    /**
     * Deactivate selecting if:
     * 1. row is an active image or
     * 2. row is a image with a lower base version number than the active one or
     * 3. row is a se patch image or
     * 4. row is a system image with the current active base version number but with a lower
     * build number.
     */
    private isCheckboxDeactivated(image: Image): boolean {
        if (image) {
            const { majorVersion, build } = image;

            const {
                controllerVersion: controllerMajorVersion,
                controllerBuild,
            } = this.initDataService;

            const isOlderBuild = image.isType(imageTypeHash.IMAGE_TYPE_SYSTEM) &&
                (majorVersion === controllerMajorVersion && build <= controllerBuild);

            return image.isUsedByController() ||
                UpdateService.compareMajorVersions(majorVersion, controllerMajorVersion) < 0 ||
                image.isCategory(imageCategoryHash.IMAGE_CATEGORY_SE) ||
                isOlderBuild;
        }

        return false;
    }
    /**
     * Check if image selection is valid for upgrade.
     */
    private isImageSelectionValid(selectedImages: any): boolean {
        return this.updateService.isImageListValidForUpgrade(
            selectedImages,
            UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE,
        );
    }

    /**
     * Open dialog modal for upgrade when the upgrade button in the list view is clicked.
     */
    private onUpgradeClick(image: Image[]): void {
        const upgradeConfig = this.getSystemUpgradeConfig(image);

        this.openDialogModal(upgradeConfig, true);
    }

    /**
     * Set version information
     */
    private setUpgradeTargetVersions(
        version: string,
        majorVersion: string,
        patchVersion = '',
    ): void {
        this.versionInformation.targetVersion = version;
        this.versionInformation.targetMajorVersion = majorVersion;
        this.versionInformation.targetPatchVersion = patchVersion;
    }

    /**
     * Set config for system upgrade by retrieving image ids and versions.
     */
    private getSystemUpgradeConfig(images: Image[]): IUpdateConfig {
        let systemImageId = '';
        let controllerPatchImageId = '';
        let sePatchImageId = '';

        const {
            IMAGE_TYPE_PATCH,
            IMAGE_TYPE_SYSTEM,
        } = imageTypeHash;

        if (images && Array.isArray(images) && images.length) {
            images.forEach(
                (image: Image) => {
                    const {
                        type,
                        category,
                        id,
                        version,
                        majorVersion,
                        patchVersion,
                    } = image;

                    const { IMAGE_CATEGORY_HYBRID } = imageCategoryHash;

                    switch (type) {
                        case IMAGE_TYPE_SYSTEM:
                            systemImageId = id;
                            this.setUpgradeTargetVersions(version, majorVersion);
                            break;

                        case IMAGE_TYPE_PATCH:

                            if (category === IMAGE_CATEGORY_HYBRID) {
                                sePatchImageId = id;
                            }

                            controllerPatchImageId = id;

                            if (!this.versionInformation.targetVersion) {
                                this.setUpgradeTargetVersions(
                                    version,
                                    majorVersion,
                                    patchVersion,
                                );
                            }

                            break;
                    }
                },
            );
        }

        return {
            upgradeType: UpgradeType.UPGRADE_TYPE_SYSTEM_UPGRADE,
            systemImageId,
            controllerPatchImageId,
            sePatchImageId,
            ...this.versionInformation,
        };
    }

    /**
     * Create image collection from raw inventory object
     */
    private loadImages(controllerMajorVersion: string): void {
        this.imageCollection = new this.ImageCollectionFactory({
            objectName: 'image-raw-inventory',
            params: {
                base_image_version: controllerMajorVersion,
                exclude_se_patch: true,
            },
            searchFields: ['type', 'version'],
        });
        this.setGridConfig();
    }

    /**
     * Open update/rollback modal depending upon the selected operation.
     */
    private openDialogModal(
        upgradeConfig: IUpdateConfig,
        isUpdate = false,
    ): void {
        const {
            dialogService,
            updateStore,
        } = this;
        const id = isUpdate ? SYSTEM_UPDATE_DIALOG_ID : SYSTEM_ROLLBACK_DIALOG_ID;

        dialogService.add({
            id,
            component: SystemUpdateModalComponent as Type<Component>,
            componentProps: {
                upgradeConfig,
                isSystemUpdatePage: true,
                isSegUpgradeSelected: (isSegUpgrade: boolean) => {
                    updateStore.setIsSegUpgradeState(isSegUpgrade);
                },
                onConfirm: () => {
                    updateStore.onPreChecksTrigger();
                    dialogService.remove(id);
                },
                onClose: () => {
                    dialogService.remove(id);
                },
            },
        });
    }

    /**
     * Return true if FIPS mode has been configured but is non operational.
     */
    public get fipsFailure(): boolean {
        return this.systemConfig?.config?.fips_mode &&
            this.fipsOperational === fipsOperationalHash.FIPS_FALSE;
    }

    /**
     * Fetch overall state and update the polling parameters and data for system rollback.
     */
    private async setInitialUpgradeStatusInfo(): Promise<void> {
        const {
            updateService,
            updateStore,
        } = this;

        const response: { data: { count: number, results: IUpgradeStatusInfo[] } } =
        await updateService.getUpgradeStatus();

        if (response?.data?.count && response.data.results?.length) {
            const controllerUpgradeStatus = response.data.results[0];
            const state = controllerUpgradeStatus.upgrade_readiness?.state?.state;

            if (PRECHECK_STATUS.has(state)) {
                updateStore.setIsPreCheckFlow(true);
            } else {
                updateStore.setIsPreCheckFlow(false);
            }

            this.setSystemRollbackInfo(controllerUpgradeStatus);
        }
    }

    /**
     * Set SystemRollback related properties.
     */
    private setSystemRollbackInfo(controllerUpgradeStatus: IUpgradeStatusInfo): void {
        let { version } = controllerUpgradeStatus;

        this.enableRollback = controllerUpgradeStatus?.enable_rollback ||
        controllerUpgradeStatus?.enable_patch_rollback;

        if (this.enableRollback) {
            const currentVersionHash = UpdateService.getVersionAsHash(version);
            const previousVersionHash =
            UpdateService.getVersionAsHash(controllerUpgradeStatus.previous_version);

            controllerUpgradeStatus.previous_version = previousVersionHash?.version;

            if (controllerUpgradeStatus?.enable_rollback) {
                if (currentVersionHash?.version === previousVersionHash?.version) {
                    const { build: currentBuild } = currentVersionHash;
                    const { build: previousBuild } = previousVersionHash;

                    version = `${currentVersionHash?.version}-${currentBuild}`;
                    controllerUpgradeStatus.previous_version =
                    `${previousVersionHash?.version}-${previousBuild}`;
                }
            }

            this.rollbackInfo = {
                version: currentVersionHash?.version,
                patchVersion: controllerUpgradeStatus?.patch_version,
                previousVersion: controllerUpgradeStatus?.previous_version,
                previousPatchVersion: controllerUpgradeStatus?.previous_patch_version,
                enableImageRollback: controllerUpgradeStatus?.enable_rollback,
                enablePatchRollback: controllerUpgradeStatus?.enable_patch_rollback,
            };

            this.setSystemRollbackOptions(this.rollbackInfo);
        }
    }
}
