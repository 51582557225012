/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'case-comments-list';
const prefix = `${moduleName}.${componentName}`;

export const anonymousUserName = `${prefix}.anonymousUserName`;
export const noCommentsMadeMessage = `${prefix}.noCommentsMadeMessage`;

export const ENGLISH = {
    [anonymousUserName]: 'Anonymous',
    [noCommentsMadeMessage]: 'No Comments have been made',
};
