<cds-alert-group
    status="danger"
    class="avi-health-score-error"
>
    <cds-alert [closable]="false">
        {{ l10nKeys.defaultErrorMessage | vtranslate }}
        <cds-alert-actions >
            <cds-button (click)="handleRetry()">
                {{ globalL10nKeys.retryLabel | vtranslate }}
            </cds-button>
        </cds-alert-actions>
    </cds-alert>
</cds-alert-group>
