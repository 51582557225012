/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import * as moment from 'moment';
import { IALBServicesCaseComment } from 'ajs/modules/cportal';
import { StringService } from 'string-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-comments-list.l10n';
import './case-comments-list.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for case comments.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'case-comments-list',
    templateUrl: './case-comments-list.component.html',
})
export class CaseCommentsListComponent implements OnInit {
    /**
     * List of Case Comments.
     */
    @Input()
    public caseComments: IALBServicesCaseComment[] = [];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        // Sort the comments by date to have most recent comments to be on top.
        this.caseComments.sort(
            (
                { created_date: currentCommentCreatedDate }: IALBServicesCaseComment,
                { created_date: nextCommentCreatedDate }: IALBServicesCaseComment,
            ) => {
                return moment.utc(nextCommentCreatedDate)
                    .diff(moment.utc(currentCommentCreatedDate));
            },
        );
    }

    /**
     * Returns full name of the user for the comment.
     */
    public getUserFullName({ created_by: createdBy }: IALBServicesCaseComment): string {
        let { first_name: firstName = '', last_name: lastName = '' } = createdBy;

        if (firstName) {
            firstName = this.stringService.capitalize(firstName.toLocaleLowerCase());
            lastName = this.stringService.capitalize(lastName.toLocaleLowerCase());

            return `${firstName} ${lastName}`;
        }

        return this.l10nService.getMessage(l10nKeys.anonymousUserName);
    }

    /**
     * trackBy function for the list of case comments.
     */
    public trackById(index: number, { id }: IALBServicesCaseComment): string {
        return id;
    }
}
