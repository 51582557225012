<avi-tooltip-icon-with-content-wrapper
    shape="circle"
    size="14"
    [status]="getExpiryStatusClass"
    [solid]="true"
>
    <ng-template [ngTemplateOutlet]="tooltip"></ng-template>
</avi-tooltip-icon-with-content-wrapper>

<ng-template #tooltip>
    <h3>{{ l10nKeys.certificateStatusTooltipLabel | vtranslate }}</h3>
    <div>
        <span>{{ getExpiryStatus }}</span>
        <div *ngIf="isExpiryStatusWarning">
            <span>{{ '(' + (l10nKeys.expiringOnLabel | vtranslate) }}</span>
            <span class="ml5">{{ certificate.data.config.certificate.data.config.not_after + ')' }}</span>
        </div>
    </div>
</ng-template>
