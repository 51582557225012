/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module ItemsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { PoolGroupDeploymentPolicy } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { ObjectTypeItem } from 'object-type-item';
import { MessageItem } from 'message-item';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    AviPermissionResource,
    ComparisonOperator,
    IPoolGroupDeploymentPolicy,
} from 'generated-types';

import * as l10n from './pool-group-deployment-policy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const defaultRuleConfig = {
    metric_id: '',
    operator: ComparisonOperator.CO_LE,
};

type IPoolGroupDeploymentPolicyPartial = Omit<IPoolGroupDeploymentPolicy, 'rules'>;

interface IPoolGroupDeploymentPolicyConfig extends IPoolGroupDeploymentPolicyPartial {
    rules?: RepeatedMessageItem<MessageItem>
}

interface IPoolGroupDeploymentPolicyData {
    config: IPoolGroupDeploymentPolicyConfig;
}

/**
 * @description Pool Group Deployment Policy collection item.
 * @author Nisar Nadaf
 */
export class PoolGroupDeploymentPolicyItem extends withFullModalMixin(ObjectTypeItem) {
    public static ngDependencies = [L10nService];

    public data: IPoolGroupDeploymentPolicyData;

    public l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectType: PoolGroupDeploymentPolicy,
            windowElement: 'lazy-load',
            objectName: 'poolgroupdeploymentpolicy',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUPDEPLOYMENTPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getNgDependency(L10nService);
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the list of rules in pool group deployment policy.
     */
    public getRules(): RepeatedMessageItem<MessageItem> {
        const { rules } = this.getConfig();

        return rules;
    }

    /**
     * Adds a rule to pool group deployment policy with default configs.
     */
    public addRule(): void {
        const rules = this.getRules();

        rules.add(defaultRuleConfig);
    }

    /**
     * Removes a rule from pool group policy deployment by index.
     */
    public removeRule(rule: MessageItem): void {
        const rules = this.getRules();

        rules.removeByMessageItem(rule);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            PoolGroupDeploymentPolicyModalComponent,
        } = await import(
            /* webpackChunkName: "pool-group-deployment-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/pool-group-deployment-policy-modal/pool-group-deployment-policy-modal.component'
        );

        return PoolGroupDeploymentPolicyModalComponent as Type<Component>;
    }

    /**
     * Getter for count of rules in pool group deployment policy.
     */
    public get rulesCount(): number {
        return this.config.rules.count;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.poolGroupDeploymentPolicyModalBreadcrumbTitle);
    }
}
