/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-placement-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const gslbSiteDropdownLabel = `${prefix}.gslbSiteDropdownLabel`;
export const gslbSitePlaceholder = `${prefix}.gslbSitePlaceholder`;
export const dnsVsGridTitle = `${prefix}.dnsVsGridTitle`;
export const hostAllSubdomainLabel = `${prefix}.hostAllSubdomainLabel`;
export const dnsVsDropdownPlaceholder = `${prefix}.dnsVsDropdownPlaceholder`;
export const dnsVsColumnTitle = `${prefix}.dnsVsColumnTitle`;
export const noDnsVsConfiguredWarning = `${prefix}.noDnsVsConfiguredWarning`;
export const selectHostAllSubdomainsDnsVsWarning = `${prefix}.selectHostAllSubdomainsDnsVsWarning`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit GSLB Site',
    [modalHeaderNew]: 'Add GSLB Site',
    [gslbSiteDropdownLabel]: 'GSLB Site',
    [gslbSitePlaceholder]: 'Select GSLB Site',
    [dnsVsGridTitle]: 'DNS Virtual Service ({0})',
    [hostAllSubdomainLabel]: 'Host All Subdomains',
    [dnsVsDropdownPlaceholder]: 'Select Virtual Service',
    [dnsVsColumnTitle]: 'Virtual Service',
    [noDnsVsConfiguredWarning]: 'No DNS Virtual Service configured for this site',
    [selectHostAllSubdomainsDnsVsWarning]: `Selecting a Host All Subdomains DNS VS will switch 'Host All Subdomains' off.
        This may cause some subdomains to become unassigned. Please verify all subdomains are mapped to a DNS VS to prevent
        any potential issues.`,

};
