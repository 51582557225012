<full-modal-config
    modalTitle="{{ l10nKeys.headerLabel | vtranslate }}"
    [modified]="form.touched && form.dirty"
    (onCancel)="cancel()"
    (onSubmit)="submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    class="system-settings-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleAuthentication | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleAuthentication | vtranslate }}</h3>
                <authentication-config [(adminAuthConfig)]="editable.config.admin_auth_configuration"></authentication-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleAccess | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleAccess | vtranslate }}</h3>
                <system-settings-access-config-container>
                    <system-settings-system-access-config
                        [portalConfig]="portalConfig"
                        [secureChannelConfig]="secureChannelConfig"
                        [allowedSshCiphers]="allowedSshCiphers"
                        [allowedSshHmacs]="allowedSshHmacs"
                    ></system-settings-system-access-config>
                    <system-settings-snmp-config [snmpConfig]="snmpConfig"></system-settings-snmp-config>
                    <system-settings-client-management-access-config-container
                        [mgmtIPAccessControl]="mgmtIPAccessControl"
                    ></system-settings-client-management-access-config-container>
                    <system-settings-banners-config [linuxConfig]="linuxConfig"></system-settings-banners-config>
                </system-settings-access-config-container>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleDnsNtp | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleDnsNtp | vtranslate }}</h3>
                <system-settings-dns-ntp-config
                    [dnsConfig]="dnsConfig"
                    [ntpConfig]="ntpConfig"
                    (onNtpAuthenticationKeyAdd)="addNtpAuthenticationKey()"
                    (onNtpServerAdd)="addNtpServer()"
                ></system-settings-dns-ntp-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleEmailSmtp | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleEmailSmtp | vtranslate }}</h3>
                <email-smtp-config
                    [emailSmtpConfig]="emailSmtpConfig"
                    [defaultEmailSmtpConfig]="defaultEmailSmtpConfig"
                ></email-smtp-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleTenancyMode | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleTenancyMode | vtranslate }}</h3>
                <tenancy-mode-config [tenantConfig]="tenantConfig"></tenancy-mode-config>
            </div>
        </full-modal-tab-section>

        <full-modal-tab-section tabTitle="{{ l10nKeys.sectionTitleDnsServices | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.sectionTitleDnsServices | vtranslate }}</h3>
                <dns-services-grid
                    [editable]="editable"
                    [dnsVirtualServiceRefs]="dnsVirtualServiceRefs"
                    (onAddDnsVs)="addDnsVs()"
                    (onRemoveDnsVs)="removeDnsVs($event)"
                    (onUpdateDnsVs)="updateDnsVs($event)"
                ></dns-services-grid>
            </div>
        </full-modal-tab-section>

    </form>
</full-modal-config>
