<avi-dropdown-single-selected-value *ngIf="!multiple">
    <ng-template [ngTemplateOutlet]="prependTemplateRef"></ng-template>
    <span>{{ getDisplayLabel(selectedOptions[0]) }}</span>
</avi-dropdown-single-selected-value>
<div
    *ngIf="multiple"
    class="avi-dropdown-selected-values-container__multiple-selected-value-container"
>
    <ng-template [ngTemplateOutlet]="prependTemplateRef"></ng-template>
    <cds-tag
        *ngFor="let selectedOption of selectedOptions; trackBy: trackByValue"
        class="avi-dropdown-selected-values-container__multiple-selected-value sel-multiple-selected-value"
        status="info"
        [attr.closable]="!disabled"
        [disabled]="disabled"
        (click)="handleRemove($event, selectedOption)"
    >
        {{ getDisplayLabel(selectedOption) }}
    </cds-tag>
</div>
<ng-template #prependTemplateRef >
    <span
        *ngIf="hasPrepend"
        class="avi-dropdown-selected-values-container__selected-value-prepend"
    >
        {{ prepend }}
    </span>
</ng-template>
