/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { IAppLearningParams } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export class AppLearningParamsConfigItem extends MessageItem<IAppLearningParams> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'AppLearningParams',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'learn_from_bots',
        ];
    }
}
