/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createReducer,
    on,
} from '@ngrx/store';

import { setControllerSite } from './controller-site.actions';

import {
    controllerSiteInitialState,
    IControllerSiteState,
} from './controller-site.state';

export const controllerSiteStateReducer = createReducer(
    controllerSiteInitialState,
    on(
        setControllerSite,
        (state: IControllerSiteState, action): IControllerSiteState => ({
            ...state,
            ...action.controllerSiteState,
        }),
    ),
);
