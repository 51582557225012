<div class="login-page clr-wrapper">
    <cluster-polling-progress-bar
        *ngIf="statusCode === ErrorCode.ERROR_CODE_503"
        status="{{ ErrorCode.ERROR_CODE_503 }}"
    ></cluster-polling-progress-bar>
    <login-container>
        <avi-alert-group
            *ngIf="showEmailSentSuccessMessage"
            alertType="success"
        >
            <span> {{ recoveryInstructionSentMessage }} </span>
        </avi-alert-group>
        <alb-login
            *ngIf="!requirePasswordReset"
            @fadeIn
            [deactivateForm]="deactivateLogin"
            (onSuccess)="onSuccess()"
            (onForgotPassword)="showResetPassword()"
        ></alb-login>
        <reset-password
            *ngIf="requirePasswordReset"
            @fadeIn
            (onEmailSent)="onRecoverySuccess()"
            (backToSignIn)="showLoginScreen()"
        ></reset-password>
        <login-banner></login-banner>
    </login-container>
</div>
