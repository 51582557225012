<input
    #searchInput
    class="vs-logs-filter-input"
    [placeholder]="placeholder"
    [ngModel]="isActive ? filter : ''"
    (ngModelChange)="handleFilterChange($event)"
    vsLogsEditableFilterInputEvents
    (onFocus)="handleFocus()"
    (onSubmit)="submit()"
    (onBackspace)="onBackspace.emit()"
>
