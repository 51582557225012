/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { AlertSyslogServerConfigItem } from 'ajs/modules/alert';

import * as l10n from './alert-syslog-server-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * AlertSyslogServerModalComponent modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'alert-syslog-server-modal',
    templateUrl: './alert-syslog-server-modal.component.html',
})
export class AlertSyslogServerModalComponent implements OnInit {
    /**
     * AlertSyslogServerConfigItem instance.
     */
    @Input()
    public editable: AlertSyslogServerConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * AlertSyslogServer object type.
     */
    public objectType: string;

    /**
     * Layout for AlertSyslogServer modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
