/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'system-update-modal';
const prefix = `${moduleName}.${componentName}`;

export const rollbackBtnLabel = `${prefix}.rollbackBtnLabel`;
export const upgradeCheckboxLabel = `${prefix}.upgradeCheckboxLabel`;
export const rollbackCheckboxLabel = `${prefix}.rollbackCheckboxLabel`;
export const clearPatchHeader = `${prefix}.clearPatchHeader`;
export const upgradeHeader = `${prefix}.upgradeHeader`;
export const rollbackVersionHeader = `${prefix}.rollbackVersionHeader`;
export const seGroupPatchVersionLabel = `${prefix}.seGroupPatchVersionLabel`;
export const actionTakeLabel = `${prefix}.actionTakeLabel`;
export const recommendCheckMessage = `${prefix}.recommendCheckMessage`;

export const ENGLISH = {
    [rollbackBtnLabel]: 'Rollback',
    [upgradeCheckboxLabel]: 'Upgrade All Service Engine Groups',
    [rollbackCheckboxLabel]: 'Rollback All Service Engine Groups',
    [clearPatchHeader]: 'Clear {0} Patch',
    [upgradeHeader]: '{0} Upgrade',
    [rollbackVersionHeader]: 'Rollback to Version {0}',
    [seGroupPatchVersionLabel]: 'Service Engine Group Patch Version',
    [actionTakeLabel]: 'Action to Take on SEG Update Failure',
    [recommendCheckMessage]: 'Here are a couple things we recommend you check before upgrading:',
};
