<div class="label-count-legend">
    <div
        class="label-count-legend__title"
        *ngIf="title"
    >
        {{ title }}
    </div>

    <div
        *ngFor="let row of labelList; trackBy: trackByIndex"
        class="label-count-legend__row"
    >
        <label-count-row [labelRowData]="row"></label-count-row>
    </div>

    <hr *ngIf="summarySectionLabel">
    <label-count-row
        *ngIf="summarySectionLabel"
        [labelRowData]="summarySectionLabel"
        class="label-count-legend__row"
    ></label-count-row>
</div>
