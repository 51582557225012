/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { HdrMatchConfigItem } from 'ajs/modules/match/factories/hdr-match.config-item.factory';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import * as l10n from './hdr-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Match object containing properties needed by match-display-wrapper.
 */
interface IFormattedMatch {
    criteria: string;
    subheader: string;
    values?: string[];
}

/**
 * @description Component to show readonly information for list of hdr-match's.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'hdr-match-readonly',
    templateUrl: './hdr-match-readonly.component.html',
})
export class HdrMatchReadonlyComponent implements OnInit {
    @Input()
    public editable: RepeatedMessageItem<HdrMatchConfigItem>;

    public readonly l10nKeys = l10nKeys;

    public formattedMatches: IFormattedMatch[];

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns list of matches, formatted for repeated element list.
     */
    public ngOnInit(): void {
        this.formattedMatches = this.editable.config.map((hdrMatch: HdrMatchConfigItem) => {
            const {
                hdr: subheader,
                match_criteria: matchCriteria,
                value: values,
            } = hdrMatch.config;

            return {
                criteria: this.getMatchCriteria(matchCriteria),
                subheader,
                values,
            };
        });
    }

    /**
     * Convenience method to get text label for match criteria.
     */
    public getMatchCriteria(criteria: string): string {
        return this.schemaService.getEnumValueLabel(
            'HdrMatchOperation',
            criteria,
        );
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
