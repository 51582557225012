<div provideParentForm>
    <avi-data-grid
        [config]="infobloxUsableDomainsGridConfig"
        [rows]="usableDomains"
        gridTitle="{{ l10nKeys.usableDomainsGridHeader | vtranslate : usableDomains.length }}"
    >
        <avi-data-grid_actions>
            <cds-button
                size="sm"
                (click)="addUsableDomain()"
                [disabled]="!connected"
            >
                {{ l10nKeys.addButtonLabel | vtranslate }}
            </cds-button>
        </avi-data-grid_actions>

        <ng-template
            #usableDomainTemplateRef
            let-row
            let-index="index"
        >
            <avi-dropdown
                name="{{ usableDomainPrefix + index }}"
                id="{{ usableDomainPrefix + index }}"
                [(ngModel)]="row.value"
                [options]="domainDropdownOptions"
                placeholder="{{ l10nKeys.domainSelectPlaceholder | vtranslate }}"
                required
            ></avi-dropdown>
        </ng-template>
    </avi-data-grid>
</div>
