/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';

/**
 * Remove only a specific error from the FormControl's errors object.
 */
export const removeControlError = (control: AbstractControl, errorKey: string): void => {
    if (!control.errors) {
        return;
    }

    const { [errorKey]: ignored, ...existingErrors } = control.errors as ValidationErrors;
    const errors = Object.keys(existingErrors).length ? existingErrors : null;

    control.setErrors(errors);
};
