/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NotificationModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { AviNotificationType, IAviNotificationAction } from '../../avi-notification.types';
import './avi-toast.component.less';

/**
 * @description
 *
 *     The component displays a message in four different styles, as an error, warning, info, or
 *     success notification, conveying different levels of severity and urgency.
 *
 *     When used with NotificationService, this toast-like component is expected to always appear at
 *     the bottom-right corner of the app. It disappears after six seconds of showing, or when a
 *     user chooses to close (by using NotificationService to add or remove.)
 *
 *     It also provides two more optional outputs for the user to take actions upon appearing.
 *
 *     NOTE: Limit of approximately 35 characters for the header (one line), and 156 characters for
 *           the content (three lines) is applied, with exceeded parts hidden.
 *
 * @example
 *      <avi-toast
 *          [type]="AviNotificationType.SUCCESS"
 *          [header]="notificationTitle"
 *          [action]="actions"
 *          (onClose)="notificationService.remove(notificationId)"
 *      >
 *          Notification Message
 *      </avi-toast>
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-toast',
    templateUrl: './avi-toast.component.html',
})
export class AviToastComponent implements OnInit {
    /**
     * Type of notification to show.
     */
    @Input()
    public type: AviNotificationType = AviNotificationType.SUCCESS;

    /**
     * Notification header. Displayed in bold.
     */
    @Input()
    public header ?: string;

    /**
     * Notification content. If present, the transcluded content will be hidden.
     */
    @Input()
    public content ?: string;

    /**
     * Actions to take upon toast appears, mappped to a list of action links at the bottom-right
     * corner.
     */
    @Input()
    public actions: IAviNotificationAction[] = [];

    /**
     * Called when a user clicks the close button.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Type string for setting the Clarity alert. It decides the main color of the notification.
     */
    public alertType: string;

    /**
     * Default to show a close button.
     * The toast disappears after six seconds of showing if the close button isn't clicked.
     */
    public showClose = true;

    /**
     * Default to not show any actions.
     * Set to true when either of the actions is set.
     */
    public showActions = false;

    /** @override */
    public ngOnInit(): void {
        // to accommodate Clarity type, used for setting the theme of the alert
        this.alertType = this.type === AviNotificationType.ERROR ? 'danger' : this.type;

        this.showClose = this.onClose.observers.length > 0;
        this.showActions = this.actions?.length > 0;
    }

    /**
     * Click handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    public trackbyIndex(index: number): number {
        return index;
    }
}
