/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module UpdateModule */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import {
    IUpgradeStatusInfo,
    UpgradeFsmUiState,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import { AviModalService } from 'ajs/modules/core/services/avi-modal/avi-modal.service';
import { Image } from 'ajs/modules/upgrade/factories/image.item.factory';
import { UpdateService } from 'ng/modules/update/services/update.service';

import {
    SEGroupCollection,
} from 'ajs/modules/service-engine-group/factories/se-group.collection.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';

import { UpdateStore } from '../update.store';

import { SystemUpdateModalComponent } from '../system-update-modal/system-update-modal.component';

import {
    SegImageSelectionModalComponent,
} from './seg-image-selection-modal/seg-image-selection-modal.component';

import {
    UpgradeTranscriptModalComponent,
} from '../upgrade-transcript-modal/upgrade-transcript-modal.component';

import {
    IUserSelectedOptions,
    nodeTypesHash,
    UpgradePrechecksStatusTypeHash,
    UpgradeStateGroupTypesHash,
} from '../../update.types';

import * as l10n from './seg-update-page.l10n';
import './seg-update-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSeGroupCollection = typeof SEGroupCollection;

/**
 * Type of node object we'll be pushing in nodes array while filtering for nodes with latest
 * operation timestamp.
 */
interface ISegNode {
    uuid: string;
    timeStamp: number;
    node: IUpgradeStatusInfo;
}

const SEG_UPGRADE_TRANSCRIPT_DIALOG_ID = 'SEG_UPGRADE_TRANSCRIPT_DIALOG_ID';
const SEG_IMAGE_SELECTION_DIALOG_ID = 'SEG_IMAGE_SELECTION_DIALOG_ID';
const SYSTEM_UPDATE_DIALOG_ID = 'SYSTEM_UPDATE_DIALOG_ID';

/**
 * Set of UpgradePrecheck States to determine if the latest operation is Prechecks one or
 * show the transcript option in case of prechecks operation.
 */
const PRECHECK_STATUS = new Set([
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_ERROR,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_IN_PROGRESS,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_STARTED,
    UpgradePrechecksStatusTypeHash.UPGRADE_PRE_CHECK_WARNING,
]);

/**
 * Set of Upgrade States.
 */
const UPGRADE_STATES_SET = new Set([
    UpgradeStateGroupTypesHash.UPGRADE_STATE_GROUP_IN_PROGRESS,
    UpgradeStateGroupTypesHash.UPGRADE_STATE_GROUP_COMPLETED,
    UpgradeStateGroupTypesHash.UPGRADE_STATE_GROUP_ERROR,
]);

/**
 * @description Component for SEG Update page.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'seg-update-page',
    templateUrl: './seg-update-page.component.html',
})
export class SegUpdatePageComponent implements OnInit, OnDestroy {
    @ViewChild('statusTemplateRef', { static: true })
    public readonly statusTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('segUpdateExpanderTemplateRef', { static: true })
    public readonly segUpdateExpanderTemplateRef: TemplateRef<HTMLElement>;

    public readonly nodeType = nodeTypesHash.NODE_SE_GROUP;

    public readonly seGroupCollection: SEGroupCollection;

    public seGroupGridConfig: IAviCollectionDataGridConfig;

    public readonly upgradeStateGroupTypesHash = UpgradeStateGroupTypesHash;

    /**
     * List of SEG IDs selected for upgrade/prechecks.
     */
    public selectedSegIds: string[] = [];

    /**
     * List of nodes received from backend on trigger of prechecks.
     */
    public precheckNodes = this.updateStore.precheckNodes$;

    /**
     * Subscription to get updated if pre checks are triggered.
     */
    public arePrechecksTriggeredSubscription: Subscription;

    /**
     * Subscription to get updated if user is currently on the same page
     * from which the update was triggered.
     */
    public isCurrentPageUpdateSubscription: Subscription;

    /**
     * Subscription to get updated when currently in progress pre checks complete.
     */
    public prechecksCompletedSubscription: Subscription;

    /**
     * Subscription to get the configuration user selected while triggering the update.
     */
    public userSelectedOptionsSubscription: Subscription;

    /**
     * Flag to keep a track if pre checks are triggered.
     */
    public arePrechecksTriggered = false;

    /**
     * Flag to identify if right rail cards can be shown.
     */
    public showRightRailCards = false;

    /**
     * Overall state of prechecks for all the selected nodes.
     */
    public readonly overallPrechecksState$ = this.updateStore.overallPrechecksState$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Flag to keep a track if user is currently on the same page from where
     * the update was triggered.
     */
    private isCurrentPageUpdate = false;

    /**
     * Flag to keep a track if the currently in progress pre checks complete.
     */
    private arePrechecksComplete = false;

    /**
     * Hold the latest value of configuration user selected while triggering the update.
     */
    private userSelectedOptions: IUserSelectedOptions = {};

    constructor(
        private readonly l10nService: L10nService,
        private readonly updateService: UpdateService,
        @Inject(SEGroupCollection)
        private readonly SEGroupCollection: TSeGroupCollection,
        private readonly authService: Auth,
        private readonly updateStore: UpdateStore,
        private readonly dialogService: DialogService,
        private readonly aviModal: AviModalService,
    ) {
        this.seGroupCollection = new this.SEGroupCollection({
            dataFields: [
                'config',
                'upgradestatus',
            ],
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Verify if the selected SEGs are valid for upgrade.
     */
    private static isSegSelectionValid(segList: ITSEGroup[]): boolean {
        return UpdateService.isSegListUpgradable(segList);
    }

    /** @override */
    public ngOnInit(): void {
        const { updateStore } = this;

        this.fetchStatusUpgradeInfo();
        this.setSegGridConfig();

        this.arePrechecksTriggeredSubscription = updateStore.preChecksTriggered$.subscribe(
            (prechecksTriggered: boolean) => {
                this.arePrechecksTriggered = prechecksTriggered;

                if (this.arePrechecksTriggered) {
                    this.seGroupCollection.load();
                }
            },
        );

        this.isCurrentPageUpdateSubscription =
            updateStore.isUpgradeTriggeredFromSystemUpdatePage$.subscribe(
                (isSystemUpdate: boolean) => this.isCurrentPageUpdate = !isSystemUpdate,
            );

        this.prechecksCompletedSubscription = updateStore.preChecksCompleted$.subscribe(
            (prechecksCompleted: boolean) => this.arePrechecksComplete = prechecksCompleted,
        );

        this.userSelectedOptionsSubscription = updateStore.userSelectedOptions$.subscribe(
            (options: IUserSelectedOptions) => this.userSelectedOptions = options,
        );
    }

    /**
     * @override
     * Reset isPreCheckFlow state in store, once user navigates away from the SEG page.
     */
    public ngOnDestroy(): void {
        const { updateStore } = this;

        updateStore.resetPrecheckNodes();
        updateStore.resetPreChecksTriggered();
        updateStore.resetPrechecksCompleted();
        updateStore.resetOverallPrechecksStatusState();
        updateStore.setIsPreCheckFlow(false);
        this.seGroupCollection.destroy();
        this.arePrechecksTriggeredSubscription.unsubscribe();
        this.isCurrentPageUpdateSubscription.unsubscribe();
        this.prechecksCompletedSubscription.unsubscribe();
        this.userSelectedOptionsSubscription.unsubscribe();
    }

    /**
     * Check if status of node is not among success, error or loading.
     */
    // eslint-disable-next-line class-methods-use-this
    public isNodeStatusKnown(row: ITSEGroup): boolean {
        return UPGRADE_STATES_SET.has(row.stateGroup as unknown as UpgradeFsmUiState) ||
        PRECHECK_STATUS.has(row.upgradeReadiness?.state?.state);
    }

    private setSegGridConfig(): void {
        const {
            authService,
            l10nService,
            seGroupCollection: { objectName },
        } = this;

        const fields = [
            {
                id: 'name',
                label: l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: ITSEGroup) => row.getName(),
            },
            {
                id: 'version',
                label: l10nService.getMessage(globalL10nKeys.versionLabel),
                transform: (row: ITSEGroup) => row.getVersion(true),
            },
            {
                id: 'virtual-services',
                label: l10nService.getMessage(globalL10nKeys.virtualServicesLabel),
                transform: (row: ITSEGroup) => row.numOfVs.toString(),
            },
            {
                id: 'service-engines',
                label: l10nService.getMessage(globalL10nKeys.serviceEnginesLabel),
                transform: (row: ITSEGroup) => row.numOfSe.toString(),
            },
            {
                id: 'cloud',
                label: l10nService.getMessage(globalL10nKeys.cloudLabel),
                transform: (row: ITSEGroup) => row.cloudName,
            },
            {
                id: 'status',
                label: l10nService.getMessage(globalL10nKeys.statusLabel),
                templateRef: this.statusTemplateRef,
            },
        ];

        if (authService.allTenantsMode()) {
            fields.push({
                id: 'tenant',
                label: l10nService.getMessage(globalL10nKeys.tenantLabel),
                transform: (row: ITSEGroup) => row.getTenantId(),
            });
        }

        this.seGroupGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.seGroupCollection,
            defaultSorting: 'name',
            fields,
            expandedContentTemplateRef: this.segUpdateExpanderTemplateRef,
            singleactions: [{
                id: 'view-transcipt',
                label: l10nService.getMessage(l10nKeys.viewTranscriptLabel),
                shape: 'chat-bubble',
                onClick: (row: ITSEGroup) => this.openTranscriptModal(row),
                hidden: (row: ITSEGroup) => !this.isNodeStatusKnown(row),
            }],
            multipleactions: [
                {
                    id: 'upgrade',
                    label: l10nService.getMessage(globalL10nKeys.upgradeLabel),
                    onClick: (rows: ITSEGroup[]) => this.openCompatibleUpdatesPopup(rows),
                    disabled: (rows: ITSEGroup[]) => {
                        return !SegUpdatePageComponent.isSegSelectionValid(rows);
                    },
                },
            ],
            layout: {
                disableCreate: true,
                hideCreate: true,
                hideDelete: true,
                hideEdit: true,
                placeholderMessage: l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            rowSelectionDisabled: (row: ITSEGroup) => row.isUpgradeInProgress() ||
                row.arePrechecksForNodeInProgress(),
        };
    }

    /**
     * Fetch the overall state of the latest performed operation.
     */
    private async fetchStatusUpgradeInfo(): Promise<void> {
        const {
            nodeType,
            updateService,
        } = this;

        const response: { data: { count: number, results: IUpgradeStatusInfo[] } } =
        await updateService.getUpgradeStatus(nodeType);

        if (response?.data?.count && response.data.results?.length) {
            this.filterLatestOperationTimeStamp(response.data.results);
        }
    }

    /**
     * Find the time stamp of the latest operation performed on any node.
     */
    private filterLatestOperationTimeStamp(upgradeStatusNodes: IUpgradeStatusInfo[]): void {
        const nodes: ISegNode[] = [];
        let nodeWithoutPrecheckStateCounter = 0;
        let latestOperationStartTimeStamp: number | undefined;

        for (const node of upgradeStatusNodes) {
            const state = node.upgrade_readiness?.state?.state;

            if (state) {
                const { start_time: upgradeStartTime } = node;
                const { start_time: prechecksStartTime } = node.upgrade_readiness;

                if (!upgradeStartTime && !prechecksStartTime) {
                    // Case where no upgrade/precheck operation has been triggered for the node.
                    nodeWithoutPrecheckStateCounter += 1;
                } else if (upgradeStartTime && !prechecksStartTime) {
                    /**
                     * Case where no freshprecheck operation has been triggered but
                     * a previous upgrade operation exists for the node.
                     */
                    const upgradeStartTimeStamp = new Date(upgradeStartTime).getTime();

                    if (latestOperationStartTimeStamp) {
                        if (upgradeStartTimeStamp > latestOperationStartTimeStamp) {
                            latestOperationStartTimeStamp = upgradeStartTimeStamp;
                        }
                    } else {
                        latestOperationStartTimeStamp = upgradeStartTimeStamp;
                    }

                    nodes.push({
                        uuid: node.uuid,
                        timeStamp: upgradeStartTimeStamp,
                        node,
                    });
                } else if (!upgradeStartTime && prechecksStartTime) {
                    /**
                     * Case where pre checks have been recently triggered but
                     * no upgrade operation has been performed yet for the node.
                     */
                    const precheckStartTimeStamp = new Date(prechecksStartTime).getTime();

                    if (latestOperationStartTimeStamp) {
                        if (precheckStartTimeStamp > latestOperationStartTimeStamp) {
                            latestOperationStartTimeStamp = precheckStartTimeStamp;
                        }
                    } else {
                        latestOperationStartTimeStamp = precheckStartTimeStamp;
                    }

                    nodes.push({
                        uuid: node.uuid,
                        timeStamp: precheckStartTimeStamp,
                        node,
                    });
                } else {
                    /**
                     * Node has both the prechecksStartTime and upgradeStartTime indicating
                     * there has been an upgrade operation for this node and a precheck operation
                     * has been triggered as well.
                     */
                    const upgradeStartTimeStamp = new Date(upgradeStartTime).getTime();
                    const precheckStartTimeStamp = new Date(prechecksStartTime).getTime();

                    if (upgradeStartTimeStamp > precheckStartTimeStamp) {
                        if (latestOperationStartTimeStamp) {
                            if (upgradeStartTimeStamp > latestOperationStartTimeStamp) {
                                latestOperationStartTimeStamp = upgradeStartTimeStamp;
                            }
                        } else {
                            latestOperationStartTimeStamp = upgradeStartTimeStamp;
                        }

                        nodes.push({
                            uuid: node.uuid,
                            timeStamp: upgradeStartTimeStamp,
                            node,
                        });
                    } else {
                        if (latestOperationStartTimeStamp) {
                            if (precheckStartTimeStamp > latestOperationStartTimeStamp) {
                                latestOperationStartTimeStamp = precheckStartTimeStamp;
                            }
                        } else {
                            latestOperationStartTimeStamp = precheckStartTimeStamp;
                        }

                        nodes.push({
                            uuid: node.uuid,
                            timeStamp: precheckStartTimeStamp,
                            node,
                        });
                    }
                }
            } else {
                /**
                 * If the state doesn't exist, it means there's no info about pre checks available
                 * for this particular node. It'll be treated as update flow node.
                 */
                nodeWithoutPrecheckStateCounter += 1;

                const { start_time: upgradeStartTime } = node;

                if (upgradeStartTime) {
                    const upgradeStartTimeStamp = new Date(upgradeStartTime).getTime();

                    nodes.push({
                        uuid: node.uuid,
                        timeStamp: upgradeStartTimeStamp,
                        node,
                    });
                }
            }
        }

        this.filterNodesWithLatestTimeStamp(
            upgradeStatusNodes,
            nodeWithoutPrecheckStateCounter,
            nodes,
            latestOperationStartTimeStamp,
        );
    }

    /**
     * Set the latest flow type (Upgrade/precheck) by filtering out the nodes which have the
     * operation start time same as the latest time stamp.
     * Also set the list of such filtered SEG IDs.
     */
    private filterNodesWithLatestTimeStamp(
        upgradeStatusNodes: IUpgradeStatusInfo[],
        nodeWithoutPrecheckStateCounter: number,
        nodes: ISegNode[],
        latestOperationStartTimeStamp: number,
    ): void {
        const { updateStore } = this;

        /**
         * If no prechecks state exists for any of the nodes, it indicates that the latest operation
         * type was not the prechecks operation.
         */
        if (nodeWithoutPrecheckStateCounter === upgradeStatusNodes.length) {
            updateStore.setIsPreCheckFlow(false);

            /**
             * If there are any entries in the nodes array, it means the latest operation on these
             * nodes is the upgrade operation.
             */
            if (nodes?.length) {
                this.selectedSegIds = nodes.map(
                    node => node.uuid,
                );
            }

            this.showRightRailCards = true;

            return;
        }

        const nodesWithLatestTimeStamp = nodes.filter(
            node => node.timeStamp === latestOperationStartTimeStamp,
        );

        this.selectedSegIds = nodesWithLatestTimeStamp.map(node => node.uuid);

        if (PRECHECK_STATUS.has(
            nodesWithLatestTimeStamp[0].node.upgrade_readiness?.state?.state,
        )) {
            updateStore.setIsPreCheckFlow(true);
        } else {
            updateStore.setIsPreCheckFlow(false);
        }

        this.showRightRailCards = true;
    }

    /**
     * Open Compatible Updates Pop up.
     */
    private openCompatibleUpdatesPopup(segUpgradeNodeList: ITSEGroup[]): void {
        this.updateStore.setSelectedSegsList(segUpgradeNodeList);

        this.dialogService.add({
            id: SEG_IMAGE_SELECTION_DIALOG_ID,
            component: SegImageSelectionModalComponent as Type<Component>,
            componentProps: {
                segUpgradeNodeList,
                onConfirm: (selectedImagesList: Image[]) => {
                    this.dialogService.remove(SEG_IMAGE_SELECTION_DIALOG_ID);
                    this.updateStore.setSelectedSegUpgradeImages(selectedImagesList);
                    this.openSystemUpdateModal(segUpgradeNodeList, selectedImagesList);
                },
                onClose: () => {
                    this.dialogService.remove(SEG_IMAGE_SELECTION_DIALOG_ID);
                },
            },
        });
    }

    private openTranscriptModal(node: ITSEGroup): void {
        const { upgradeReadiness = {} } = node;

        if (PRECHECK_STATUS.has(upgradeReadiness.state?.state)) {
            this.updateStore.openPreCheckTranscript({
                readonly: this.openTranscriptModalInReadOnlyMode(),
                nodeIds: [node.id],
            });
        } else {
            this.openUpgradeTranscriptModal(node);
        }
    }

    /**
     * Open modal to display check list and trigger upgrade.
     */
    private openSystemUpdateModal(
        segUpgradeNodeList: ITSEGroup[],
        selectedImagesList: Image[],
    ): void {
        const upgradeConfig = UpdateService.getSegUpgradeConfig(
            segUpgradeNodeList,
            selectedImagesList,
        );

        const {
            dialogService,
            updateStore,
        } = this;

        dialogService.add({
            id: SYSTEM_UPDATE_DIALOG_ID,
            component: SystemUpdateModalComponent as Type<Component>,
            componentProps: {
                upgradeConfig,
                isSystemUpdatePage: false,
                isSegUpgradeSelected: (isSegUpgrade: boolean) => {
                    updateStore.setIsSegUpgradeState(isSegUpgrade);
                },
                onConfirm: () => {
                    this.selectedSegIds = [...upgradeConfig.segUuids];
                    updateStore.onPreChecksTrigger();
                    dialogService.remove(SYSTEM_UPDATE_DIALOG_ID);
                },
                onClose: () => {
                    dialogService.remove(SYSTEM_UPDATE_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Open modal to display the progress/state of events of upgrade/rollback operation.
     */
    private openUpgradeTranscriptModal(node: ITSEGroup): void {
        const {
            id,
            nodeType,
        } = node;
        const { dialogService } = this;

        dialogService.add({
            id: SEG_UPGRADE_TRANSCRIPT_DIALOG_ID,
            component: UpgradeTranscriptModalComponent as Type<Component>,
            componentProps: {
                nodeId: id,
                nodeType,
                onClose: () => dialogService.remove(SEG_UPGRADE_TRANSCRIPT_DIALOG_ID),
            },
        });
    }

    /**
     * Return if the Prechecks transcript modal needs to be opened in readonly state i.e
     * if suppress warning checkbox needs to be shown.
     */
    private openTranscriptModalInReadOnlyMode(): boolean {
        const {
            arePrechecksComplete,
            isCurrentPageUpdate,
            userSelectedOptions,
        } = this;

        return !arePrechecksComplete || !isCurrentPageUpdate ||
            Object.keys(userSelectedOptions).length === 0;
    }
}
