/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    Component,
    Input,
} from '@angular/core';

import { StateService } from '@uirouter/core';
import { StringService } from 'string-service';
import { ITSEGroup } from 'ajs/modules/service-engine-group/factories/se-group.item.factory';
import * as globalL10n from 'global-l10n';
import './se-group-list-expander.component.less';

const { ...globalL10nKeys } = globalL10n;

// Constants used for navigation to a page.
const SERVICE_ENGINE_DETAILS_PAGE =
    'authenticated.infrastructure.cloud.serviceengine-detail.summary';
const VIRTUAL_SERVICE_DETAILS_PAGE = 'authenticated.application.virtualservice-detail.analytics';

/**
 * @description Component for Service Engine Group row expander.
 * @author vgohil
 */
@Component({
    selector: 'se-group-list-expander',
    templateUrl: './se-group-list-expander.component.html',
})
export class SEGroupListExpanderComponent {
    @Input()
    public seGroup: ITSEGroup;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly $state: StateService,
        private readonly stringService: StringService,
    ) {}

    /**
     * Navigate to respective details page for given ref.
     */
    public navigateTo(ref: string, isSeRef = false): void {
        const { stringService, seGroup } = this;

        if (isSeRef) {
            this.$state.go(
                SERVICE_ENGINE_DETAILS_PAGE,
                {
                    seId: stringService.slug(ref),
                    cloudId: seGroup.cloudId,
                },
            );
        } else {
            this.$state.go(
                VIRTUAL_SERVICE_DETAILS_PAGE,
                { vsId: stringService.slug(ref) },
            );
        }
    }

    public trackByIndex(index: number): number {
        return index;
    }
}
