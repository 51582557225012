/**
 * @module HealthmonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories.
 *
 * @author Rajawant Prajapati
 */

/**
 * Ajs dependency token for Health Monitor Item.
 */
export const HEALTH_MONITOR_ITEM_TOKEN = 'HealthMonitorItem';

/**
 * Ajs dependency token for Health Monitor Collection.
 */
export const HEALTH_MONITOR_COLLECTION_TOKEN = 'HealthMonitorCollection';

/**
 * Ajs dependency token for Health Monitor HTTP Config Item.
 */
export const HEALTH_MONITOR_HTTP_CONFIG_TOKEN = 'HealthMonitorHttpConfigItem';

/**
 * Ajs dependency token for Health Monitor IMAPS Config Item.
 */
export const IMAPS_MONITOR_CONFIG_TOKEN = 'HealthMonitorImapConfigItem';

/**
 * Ajs dependency token for Health Monitor SMTP Config Item.
 */
export const HEALTH_MONITOR_SMTP_CONFIG_TOKEN = 'HealthMonitorSmtpConfigItem';

/**
 * Ajs dependency token for Health Monitor POP3 Config Item.
 */
export const HEALTH_MONITOR_POP3_CONFIG_TOKEN = 'HealthMonitorPop3ConfigItem';

/**
 * Ajs dependency token for Health Monitor POP3 Config Item.
 */
export const HEALTH_MONITOR_LDAP_CONFIG_TOKEN = 'HealthMonitorLdapConfigItem';

/**
 * Ajs dependency token for Health Monitor POP3 Config Item.
 */
export const HEALTH_MONITOR_FTP_CONFIG_TOKEN = 'HealthMonitorFtpConfigItem';
