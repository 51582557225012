/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'performance-chart-controls';
const prefix = `${moduleName}.${componentName}`;

export const configEventsLabel = `${prefix}.configEventsLabel`;
export const systemEventsLabel = `${prefix}.systemEventsLabel`;
export const alertsLabel = `${prefix}.alertsLabel`;
export const anomaliesLabel = `${prefix}.anomaliesLabel`;

export const ENGLISH = {
    [configEventsLabel]: '<span class="avi-label">Config Events</span> ({0})',
    [systemEventsLabel]: '<span class="avi-label">System Events</span> ({0})',
    [alertsLabel]: '<span class="avi-label">Alerts</span> ({0})',
    [anomaliesLabel]: '<span class="avi-label">Anomalies</span> ({0})',
};
