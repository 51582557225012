/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'avi-modal';
const prefix = `${moduleName}.${componentName}`;

export const saveLabel = `${prefix}.saveLabel`;
export const cancelLabel = `${prefix}.cancelLabel`;
export const accountSettingsLabel = `${prefix}.accountSettingsLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const userNameInputLabel = `${prefix}.userNameInputLabel`;
export const myNameInputPlaceholder = `${prefix}.myNameInputPlaceholder`;
export const myUsernameInputPlaceholder = `${prefix}.myUsernameInputPlaceholder`;
export const oldPasswordInputLabel = `${prefix}.oldPasswordInputLabel`;
export const newPasswordInputLabel = `${prefix}.newPasswordInputLabel`;
export const confirmNewPasswordInputLabel = `${prefix}.confirmNewPasswordInputLabel`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const myEmailInputPlaceholder = `${prefix}.myEmailInputPlaceholder`;
export const displaySettingsLabel = `${prefix}.displaySettingsLabel`;
export const timeZoneLabel = `${prefix}.timeZoneLabel`;
export const utcTimeLabel = `${prefix}.utcTimeLabel`;
export const localTimeLabel = `${prefix}.localTimeLabel`;
export const controllerSettingsLabel = `${prefix}.controllerSettingsLabel`;
export const sessionTimeoutLabel = `${prefix}.sessionTimeoutLabel`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const dnsRefreshPeriodInputLabel = `${prefix}.dnsRefreshPeriodInputLabel`;
export const editMyAccountTitle = `${prefix}.editMyAccountTitle`;
export const languageLabel = `${prefix}.languageLabel`;

export const ENGLISH = {
    [saveLabel]: 'Save',
    [cancelLabel]: 'Cancel',
    [accountSettingsLabel]: 'Account Settings',
    [nameInputLabel]: 'Name',
    [userNameInputLabel]: 'Username',
    [myNameInputPlaceholder]: 'My Name',
    [myUsernameInputPlaceholder]: 'My Username',
    [oldPasswordInputLabel]: 'Old Password',
    [newPasswordInputLabel]: 'New Password',
    [confirmNewPasswordInputLabel]: 'Confirm New Password',
    [emailInputLabel]: 'Email',
    [myEmailInputPlaceholder]: 'My Email',
    [displaySettingsLabel]: 'Display Settings',
    [timeZoneLabel]: 'Time Zone',
    [utcTimeLabel]: 'UTC Time',
    [localTimeLabel]: 'Local Time',
    [controllerSettingsLabel]: 'Controller Settings',
    [sessionTimeoutLabel]: 'Session Timeout',
    [minutesInnerText]: 'min',
    [dnsRefreshPeriodInputLabel]: 'DNS Refresh Period',
    [editMyAccountTitle]: 'Edit My Account: <strong>{0}</strong>',
    [languageLabel]: 'Language',
};
