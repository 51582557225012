/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
} from '@angular/core';

/**
 * @desc Bar graph tooltip component to top-center position tooltip content.
 * Template provided completely by parent/user.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'avi-bar-graph-tooltip',
    templateUrl: './avi-bar-graph-tooltip.component.html',
})
export class AviBarGraphTooltipComponent implements OnChanges {
    // TODO 158220 delete
    @Input()
    public left: number;

    /** Offset as reported by parent, from parent left. */
    @Input()
    public offsetX: number;

    /** Width of parent/user of this component. */
    @Input()
    public containerWidth: number;

    /** Width of this tooltip component. */
    @Input()
    public width: number;

    constructor(
        public elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    // TODO 158220 set logic to center properly
    /**
     * Set position of element, based on parent/user provided data.
     *  @override
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.left) {
            return;
        }

        this.renderer.setStyle(
            this.elementRef.nativeElement,
            'left',
            `${changes.left.currentValue}px`,
        );
    }
}
