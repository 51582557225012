/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'grid';
const prefix = `${moduleName}.${componentName}`;

export const emptyListMessage = `${prefix}.emptyListMessage`;
export const noItemsMatchMsg = `${prefix}.noItemsMatchMsg`;
export const displayItemsPlural = `${prefix}.displayItemsPlural`;
export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;

export const ENGLISH = {
    [emptyListMessage]: 'No items found',
    [noItemsMatchMsg]: 'No items match your search criteria',
    [displayItemsPlural]: '{0, plural, one{Displaying # item} other{Displaying # items}}',
    [searchInputPlaceholder]: 'Search',
};
