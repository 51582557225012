/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

class GslbServiceMemberListController {
    constructor(
        Auth,
        GSLBPoolMemberCollection,
    ) {
        this.Auth = Auth;
        this.GSLBPoolMemberCollection = GSLBPoolMemberCollection;

        /**
         * Manually calculate an additional busy state for the grid since this collection is not
         * able to save any pool member directly. It's saved by GLSBService. So the collection
         * built-in busy state won't adequately indicate the saving busy state.
         * @type {GridBusyState}
         */
        this.gridBusyState = {
            isBusy: false,
        };
    }

    $onInit() {
        this.collection_ =
            new this.GSLBPoolMemberCollection({ gslbServiceId: this.gslbService_.getIdFromData() });

        this.collReload_ = this.collection_.load.bind(this.collection_);
        this.gslbService_.bind('itemSaveSuccess', this.collReload_);
        this.setGridConfig_();
    }

    /**
     * Creates gridConfig.
     * @protected
     */
    setGridConfig_() {
        //Cluster id to IP address hash.
        const clusterIpHash = {};

        //Gets cluster IP address from cluster uuid.
        const getClusterIpAddress = row => clusterIpHash[row.getConfig().cluster_uuid];

        //Gets direct URL to the member virtual service.
        const getMemberVsUrl = row => {
            const clusterIpAddress = getClusterIpAddress(row);
            const { vs_uuid: virtualServiceId } = row.getConfig();
            const tenantName = this.Auth.getTenantName();
            // eslint-disable-next-line max-len
            const memberVsUrl = `https://${clusterIpAddress}/#!/${tenantName}/applications/virtualservice/${virtualServiceId}/analytics`;

            return memberVsUrl;
        };

        const fields = [{
            name: 'data.config.gslbPoolName',
            title: 'GSLB Pool Name',
        }, {
            name: 'enabled_status',
            title: 'Status',
            template: '{{ row.getConfig().enabled ? \'Enabled\' : \'Deactivated\' }}',
        }, {
            name: 'data.config.gslbPoolPriority',
            title: 'GSLB Pool Priority',
        }, {
            require: 'config',
            name: 'name',
            getClusterIpAddress,
            getMemberVsUrl,
            title: 'Member Name',
            template: `
                <a
                    ng-if="field.getClusterIpAddress(row) && row.getConfig().vs_uuid"
                    href="{{ field.getMemberVsUrl(row) }}"
                    title="{{ row.getName() }}"
                    target="_blank"
                >
                    {{ row.getName() }}
                </a>
                <span
                    ng-if="!field.getClusterIpAddress(row)"
                    title="{{ row.getName() }}"
                >
                    {{ row.getName() }}
                </span>`,

        }, {
            name: 'data.config.ip.addr',
            title: 'IP address',
        }, {
            name: 'publicIp',
            template: '{{row.getConfig().public_ip || \'N/A\'}}',
            title: 'Public IP address',
        }];

        //add oper_status column for each GslbSite
        const siteStatusColumns = [];

        if (this.Auth.isAllowed('gslb')) {
            this.gslbService_.getGSLB().getConfig()['sites'].flattenConfig()
                .forEach(({
                    dns_vses: dnsVSes,
                    name,
                    cluster_uuid: clusterId,
                    ip_addresses: ipAddresses,
                    address,
                }) => {
                    if (!(clusterId in clusterIpHash)) {
                        const siteAddress = address || ipAddresses && ipAddresses[0].addr;

                        clusterIpHash[clusterId] = siteAddress;
                    }

                    if (dnsVSes && dnsVSes.length) {
                        const location =
                            'row.data.runtime.gslbSiteOperStatuses[field.clusterId].location';

                        siteStatusColumns.push({
                            name: `site_status_${clusterId}`,
                            clusterId,
                            title: `DNS Site "${name}" Status`,
                            template:
                                `<avi-healthscore
                                    item="row"
                                    type="gslbsite"
                                    title="Location: {{ ::${location} ?
                                        (${location} | geoLocation) : 'Not Available' }}"
                                    get-runtime-params="field.clusterId"
                                ></avi-healthscore>
                            `,
                        });
                    }
                });
        }

        //last column
        const overallStatusColumn = {
            name: 'status',
            title: 'Overall Member Status',
            template: '<avi-healthscore item="row" type="gslbsite"></avi-healthscore>',
        };

        siteStatusColumns.push(overallStatusColumn);
        Array.prototype.push.apply(fields, siteStatusColumns);

        const multipleactions = [{
            title: 'Enable',
            className: 'icon-check',
            disabled: poolMembers => !this.gslbService_.isPoolMemberEnableWritable() ||
                poolMembers.every(({ enabled }) => enabled),
            do: poolMembers => this.setPoolMemberEnableState_(poolMembers, true),
            // permission check is done by gslbService.isPoolMemberEnableWritable() since it's also
            // doing license tier type check
            bypassPermissionsCheck: true,
        }, {
            title: 'Deactivate',
            className: 'icon-minus',
            disabled: poolMembers => !this.gslbService_.isPoolMemberEnableWritable() ||
                poolMembers.every(({ enabled }) => !enabled),
            do: poolMembers => this.setPoolMemberEnableState_(poolMembers, false),
            // permission check is done by gslbService.isPoolMemberEnableWritable() since it's also
            // doing license tier type check
            bypassPermissionsCheck: true,
        }];

        this.gslbServiceMembersGridConfig = {
            fields,
            collection: this.collection_,
            id: componentTag,
            multipleactions,
            singleactions: [],
            layout: {
                hideSearch: true,
                hideEditColumns: true,
            },
        };
    }

    /**
     * Set the 'enable' state of a list of pool members by triggering GSLB Service saving.
     * @param {Object} poolMembers - Pool members to be set 'enabled' state of.
     * @param {boolean} newState - State to be set.
     * @return {boolean}
     * @protected
     */
    setPoolMemberEnableState_(poolMembers, newState) {
        // initiate domain_names so that the GSLB Service save won't be blocked
        this.gslbService_.beforeEdit();

        poolMembers.forEach(member => {
            const config = member.getConfig();
            const { enabled: currentState, gslbPoolName, ip: poolMemberIp } = config;

            if (newState !== currentState) {
                this.gslbService_.setPoolMemberEnableState(
                    gslbPoolName,
                    poolMemberIp,
                    newState,
                );
            }
        });

        this.gridBusyState.isBusy = true;
        this.gslbService_.save()
            .finally(() => {
                this.gridBusyState.isBusy = false;
            });

        return true;
    }

    $onDestroy() {
        if (this.collection_) {
            this.collection_.destroy();
        }

        this.gslbService_.unbind('itemSaveSuccess', this.collReload_);
    }
}

GslbServiceMemberListController.$inject = [
    'Auth',
    'GSLBPoolMemberCollection',
];

const componentTag = 'gslb-service-detail-member';
const templateUrl =
    'src/components/pages/application/gslb/gslb-service-detail/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @ngdoc component
 * @name  gslbServiceMemberList
 * @param {Object} gslbService - gslbService object
 * @author Alex Malitsky, Akul Aggarwal, Zhiqian Liu
 * @description
 *
 *     Component for GSLB Pool grid.
 */
angular.module('avi/gslb').component('gslbServiceMemberList', {
    bindings: {
        gslbService_: '<resolveGslbService',
    },
    controller: GslbServiceMemberListController,
    templateUrl,
});
