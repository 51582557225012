/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name parseIpOrHostname
 * @restrict A
 * @description
 *     Parses and formats input IP address string or hostname. String gets parsed to object with
 *     addr and type properties, and IpAddr object is formatted into IP address string.
 */
angular.module('aviApp').directive('parseIpOrHostname', ['Regex',
function(Regex) {
    function link(scope, elm, attr, ngModel) {
        ngModel.$parsers.push(parser);
        ngModel.$formatters.push(formatter);
    }

    /**
     * Parses string into IP object with addr and type properties. Both the IP address string and
     * hostname can be set to the 'addr' property.
     * @param  {string} val - Input hostname or IP address string.
     * @return {Object} IpAddr object {addr: '1.2.3.4', type: 'V4'}.
     */
    function parser(val) {
        if (Regex.hostname.test(val)) {
            return {
                addr: val,
                type: 'V4',
            };
        } else {
            return '';
        }
    }

    /**
     * Formats IpAddr object into string.
     * @param  {Object} val - IP object {addr: '1.2.3.4', type: 'V4'}.
     * @return {string} String of hostname or IP address.
     */
    function formatter(val) {
        return val ? val.addr : '';
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
