<avi-card>
    <avi-card_header>
        <div class="license-summary-card__header">
            <div class="license-summary-card__license-title">
                <div class="license-summary-card__license-title-icon">
                    <cds-icon
                        shape="certificate"
                        status="success"
                        size="24"
                    ></cds-icon>
                </div>
                <div class="license-summary-card__license-title-text">
                    {{ l10nKeys.title | vtranslate }}
                </div>
            </div>
            {{ licenseTierTitle }}
        </div>
    </avi-card_header>
    <avi-card_body>
        <div class="license-summary-card__license-details">
            <div
                class="license-summary-card__license-usage"
                *ngIf="isEnterpriseWithCloudServicesTier()"
            >
                <div
                    class="license-summary-card__org-id"
                    *ngIf="orgId"
                >
                    <label aviLabelWithTooltip>
                        {{ l10nKeys.organizationIdLabel | vtranslate }}
                    </label>
                    <div>{{ orgId }}</div>
                </div>
                <div
                    class="license-summary-card__available-service-units"
                    *ngIf="showAvailableServiceUnitsSection"
                >
                    <label
                        aviLabelWithTooltip
                        tooltipText="{{ l10nKeys.cloudServicesAvailableLicensesTooltipText | vtranslate }}"
                    >
                        {{ l10nKeys.cloudServicesAvailableLicensesLabel | vtranslate }}
                    </label>
                    <div>{{ (availableServiceUnits - usedServiceUnits) }}/{{ availableServiceUnits }}</div>
                </div>
                <div class="license-summary-card__controller-max-allowed">
                    <label
                        for="max-service-units"
                        fieldName="max_service_units"
                        objectType="{{ saasObjectType }}"
                        aviLabelWithTooltip
                    >
                        {{ l10nKeys.controllerMaxAllowedLabel | vtranslate }}
                    </label>
                    <div>{{ saasConfig.max_service_units }}</div>
                </div>
                <div class="license-summary-card__reserved-licenses">
                    <label
                        for="reserve-service-units"
                        fieldName="reserve_service_units"
                        objectType="{{ saasObjectType }}"
                        aviLabelWithTooltip
                    >
                        {{ l10nKeys.reservedLicensesLabel | vtranslate }}
                    </label>
                    <div>{{ saasConfig.reserve_service_units }}</div>
                </div>
            </div>
            <div class="license-summary-card__license-usage-section">
                <div class="license-summary-card__license-usage-wrapper">
                    <ng-container *ngIf="!isBusy; else loaderTemplate">
                        <license-usage-card
                            [usedServiceCores]="usedServiceCores"
                            [totalLicensedServiceCores]="licensedServiceCores"
                            [licenseTierType]="licenseTier"
                        ></license-usage-card>
                    </ng-container>
                    <ng-template #loaderTemplate>
                        <div class="license-add-panel__spinner">
                            <clr-spinner clrMedium></clr-spinner>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </avi-card_body>
</avi-card>
