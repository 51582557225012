<div class="alb-login">
    <form #loginForm="ngForm">
        <fieldset [disabled]="deactivateForm">
            <div class="login-group">
                <avi-input-container class="alb-login__input-container">
                    <input
                        autocomplete="off"
                        type="text"
                        clrInput
                        class="sel-login-avi-username"
                        placeholder="{{ l10nKeys.usernameInputLabel | vtranslate }}"
                        [(ngModel)]="username"
                        name="username"
                        required
                    />
                </avi-input-container>
                <avi-input-container class="alb-login__input-container">
                    <input
                        autocomplete="off"
                        type="password"
                        clrInput
                        class="sel-login-avi-password"
                        placeholder="{{ l10nKeys.passwordInputLabel | vtranslate }}"
                        [(ngModel)]="password"
                        name="password"
                        required
                    />
                </avi-input-container>
                <span class="alb-login__forgot-password">
                    <a
                        href=""
                        *ngIf="showForgotPassword()"
                        (click)="resetPassword()"
                    >
                        {{ l10nKeys.forgotPassword | vtranslate }}
                    </a>
                </span>
                <div
                    class="error"
                    [ngClass]="{ active: errorMessage }"
                >
                    {{ errorMessage }}
                </div>
                <div
                    button-loader
                    [isLoading]="loginInProgress"
                >
                    <button
                        type="submit"
                        class="btn btn-primary sel-submit-button"
                        (click)="login()"
                        [disabled]="loginForm.invalid"
                    >
                        {{ l10nKeys.logIn | vtranslate }}
                    </button>
                </div>
            </div>
        </fieldset>
    </form>
</div>
