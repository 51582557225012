/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createAction,
    props,
} from '@ngrx/store';
import { IUser } from 'generated-types';
import { IUserAccountPayload } from './user-account.state';

const USER_ACCOUNT_SOURCE = '[User Account]';

export const fetchUserAccount = createAction(
    `${USER_ACCOUNT_SOURCE} Fetch User Account`,
);

export const fetchUserAccountSuccess = createAction(
    `${USER_ACCOUNT_SOURCE} Fetch User Account Success`,
    props<{ payload: IUser }>(),
);

export const fetchUserAccountError = createAction(
    `${USER_ACCOUNT_SOURCE} Fetch User Account Error`,
);

export const saveUserAccountSuccess = createAction(
    `${USER_ACCOUNT_SOURCE} Save User Account Success`,
    props<{ payload: IUserAccountPayload }>(),
);

export const saveUserAccountError = createAction(
    `${USER_ACCOUNT_SOURCE} Save User Account Error`,
);
