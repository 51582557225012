<full-modal-preview>
    <full-modal-preview_container>
        <div class="error-page-preview clr-wrapper">
            <div class="error-page-preview__item">
                <h6>{{ l10nKeys.errorPageIndexPosition | vtranslate }}</h6>
                <div class="error-page-preview__item-value">
                    {{ index }}
                </div>
            </div>
        </div>
    </full-modal-preview_container>
</full-modal-preview>
