<h6>
    {{ l10nKeys.addAttachmentsGridHeaderLabel | vtranslate : filesToAttachCount }}
</h6>
<avi-data-grid
    [config]="filesToAttachGridConfig"
    [rows]="filesToAttach"
    class="add-attachments-grid"
>
    <avi-data-grid_actions>
       <avi-dropdown-button
            [actions]="addAttachmentsActions"
            [position]="actionPosition"
            size="sm"
        >
            {{ l10nKeys.addButtonLabel | vtranslate }}
        </avi-dropdown-button>
    </avi-data-grid_actions>
</avi-data-grid>
