<avi-card class="traffic-capture-page-card">
    <avi-card_header class="traffic-capture-page-card__header">
        <cds-icon
            shape="cloud-traffic"
            size="24"
            status="success"
        ></cds-icon>
        {{ l10nKeys.trafficCaptureCardHeader | vtranslate }}
    </avi-card_header>
    <avi-card_body>
        <div
            *ngIf="!captureInProgress"
            class="traffic-capture-page-card__placeholder"
        >
            <span>
                {{ l10nKeys.emptyCardPlaceholder | vtranslate }}
            </span>
        </div>
        <ng-container *ngIf="captureInProgress">
            <avi-textarea-container>
                <label 
                    aviLabelWithTooltip
                    for="types-to-capture"
                >
                    {{ l10nKeys.trafficToCaptureLabel | vtranslate }}
                </label>
                <textarea
                    clrTextarea
                    id="types-to-capture"
                    name="types-to-capture"
                    placeholder="{{ l10nKeys.emptyPlaceholder | vtranslate }}"
                    [value]="selectedTypesForCaptureTextValue"
                    readonly
                ></textarea>
            </avi-textarea-container>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <avi-input-container 
                        noMarginTop
                        helperText="{{ l10nKeys.pktSizeHelperText | vtranslate }}"
                    >
                        <label 
                            aviLabelWithTooltip
                            for="sz-per-packet"
                        >
                            {{ l10nKeys.sizePerPacketLabel | vtranslate }} </label>
                        <input
                            name="sz-per-packet"
                            id="sz-per-packet"
                            clrInput
                            placeholder="{{ l10nKeys.emptyPlaceholder | vtranslate }}"
                            [ngModel]="configuration.pktSize"
                            readonly
                        />
                    </avi-input-container>
                </div>
                <div class="full-modal-config__form-control">
                    <avi-input-container noMarginTop>
                        <label 
                            aviLabelWithTooltip
                            for="src-port"
                        >
                            {{ l10nKeys.srcPortLabel | vtranslate }} </label>
                        <input
                            name="src-port"
                            id="src-port"
                            clrInput
                            placeholder="{{ l10nKeys.emptyPlaceholder | vtranslate }}"
                            [ngModel]="configuration.srcPort"
                            readonly
                        />
                    </avi-input-container>
                </div>
            </div>
            <div class="full-modal-config__form-control-row">
                <div class="full-modal-config__form-control">
                    <div class="traffic-capture-page-card__progress-section">
                        <clr-spinner [clrMedium]="true"></clr-spinner>
                        <label 
                            class="traffic-capture-page-card__spinner-label"
                            aviLabelWithTooltip
                        > 
                            {{ l10nKeys.pktsCapturedLabel | vtranslate }}
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
    </avi-card_body>
    <avi-card_footer>
        <cds-button
            action="flat"
            size="sm"
            (click)="onStartTrafficCapture()"
            *ngIf="!captureInProgress"
        >
            {{ l10nKeys.startCaptureButtonLabel | vtranslate }}
        </cds-button>
        <cds-button
            action="flat"
            size="sm"
            (click)="onStopTrafficCapture()"
            [disabled]="stopCaptureInProgress"
            [ngClass]="{'traffic-capture-page-card__stop-btn': !stopCaptureInProgress }"
            *ngIf="captureInProgress"
        >
            {{ l10nKeys.stopCaptureButtonLabel | vtranslate }}
        </cds-button>
    </avi-card_footer>
</avi-card>
