/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Type,
} from '@angular/core';

import {
    IWafRuleOverrides,
    WafMode,
} from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { WafExcludeListEntryConfigItem } from './waf-exclude-list-entry.config-item.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafRuleOverridesPartial = Omit<IWafRuleOverrides, 'exclude_list'>;

interface IWafRuleOverridesConfig extends TWafRuleOverridesPartial {
    exclude_list?: RepeatedMessageItem<WafExcludeListEntryConfigItem>;
}

export const WAF_RULE_OVERRIDES_CONFIG_ITEM_TOKEN = 'WafRuleOverridesConfigItem';

export class WafRuleOverridesConfigItem extends withFullModalMixin(
    withEditChildMessageItemMixin<IWafRuleOverridesConfig, typeof MessageItem>(MessageItem),
)<IWafRuleOverridesConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafRuleOverrides',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the ID of this rule override.
     */
    public getId(): string {
        return this.config.rule_id;
    }

    /**
     * Returns true if this group is enabled.
     */
    public isEnabled(): boolean {
        return Boolean(this.enable);
    }

    /**
     * Getter for the override enable flag. Can be undefined if not configured.
     */
    public get enable(): boolean | undefined {
        return this.config.enable;
    }

    /**
     * Setter for the override enable flag.
     */
    public set enable(enable) {
        this.config.enable = enable;
    }

    /**
     * Sets the mode.
     * @param wafMode - Mode enum to set. Undefined or not set means that the rule follows the
     *     policy's mode.
     */
    public set mode(wafMode: WafMode | undefined) {
        this.config.mode = wafMode;
    }

    /**
     * Returns the excludeList repeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.config.exclude_list;
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addExcludeListEntryMessageItem(modalBindings?: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'exclude_list',
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.addExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Edits a WafExcludeListEntry message item.
     */
    public editExcludeListEntryMessageItem(
        entry: WafExcludeListEntryConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'exclude_list',
            messageItem: entry,
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.editExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Called to remove a WafExcludeListEntry message item.
     */
    public removeExcludeListEntry(entry: WafExcludeListEntryConfigItem): void {
        this.excludeList.removeByMessageItem(entry);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafRuleBreadcrumbTitle);
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafRuleModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-rule-modal/waf-rule-modal.component'
        );

        return WafRuleModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbDescription(params: Record<string, any>): string {
        return params.crsRuleName;
    }
}
