/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {
    ClrInputModule,
    ClrSpinnerModule,
} from '@clr/angular';

import {
    AviCardModule,
    AviUsageBarModule,
} from 'nsx-alb-ui-components';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    LicenseAddPanelComponent,
    LicenseCardComponent,
    LicenseCardUsageComponent,
    LicenseSummaryCardComponent,
    LicenseTierSelectCardComponent,
    LicenseTierSwitchModalComponent,
    LicenseUsageCardComponent,
    LicensingPageHeaderComponent,
} from '.';

const licensingComponents = [
    LicenseAddPanelComponent,
    LicenseCardComponent,
    LicenseSummaryCardComponent,
    LicenseTierSelectCardComponent,
    LicenseTierSwitchModalComponent,
    LicenseUsageCardComponent,
    LicensingPageHeaderComponent,
    LicenseCardUsageComponent,
];

/**
 * @description
 *
 *     Angular Module for Licensing related services and components.
 *
 * @author Zhiqian Liu
 */
@NgModule({
    declarations: [
        ...licensingComponents,
    ],
    exports: [
        LicenseSummaryCardComponent,
        LicenseTierSelectCardComponent,
        LicenseTierSwitchModalComponent,
        LicenseAddPanelComponent,
        LicenseCardComponent,
    ],
    imports: [
        AviCardModule,
        AviFormsModule,
        CommonModule,
        FormsModule,
        ClrInputModule,
        SharedModule,
        CoreModule,
        ClrSpinnerModule,
        AviUsageBarModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class LicensingModule {}
