/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ApplicationProfileType } from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    GslbSiteDnsVsConfigItem,
} from 'message-items/gslb-site-dns-vs.config-item.factory';

import {
    GSLBVSCollection,
    TGSLBVSCollection,
} from 'ajs/modules/gslb/factories/gslb-vs.collection.factory';

import { StringService } from 'ng/modules/core/services/string/string.service';

import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-dns-virtual-services-grid.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for dns virtual service of gslb avi site.
 * Grid is only shown if the avi site is acitve.
 * In case no subdomin is selected for the dns vs then all subdomians will be hosted by the VS.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-dns-virtual-services-grid',
    templateUrl: './gslb-dns-virtual-services-grid.component.html',
})
export class GslbDnsVirtualServicesGridComponent implements OnInit, AfterViewInit {
    @ViewChild('allSubdomainTemplateRef')
    public allSubdomainTemplateRef: TemplateRef<HTMLElement>;

    @Input()
    public dnsVses: RepeatedMessageItem<GslbSiteDnsVsConfigItem>;

    /**
     * Cluster uuid of gslb site.
     */
    @Input()
    public clusterUuid: string;

    /**
     * Tenant id of the gslb site.
     */
    @Input()
    public tenantId: string;

    @Output()
    public onAddDnsVs = new EventEmitter<void>();

    @Output()
    public onEditDnsVs = new EventEmitter<GslbSiteDnsVsConfigItem>();

    @Output()
    public onDeleteDnsVs = new EventEmitter<GslbSiteDnsVsConfigItem>();

    /**
     * DNS VSes can only be added if the GSLB site is saved.
     * Used to disable add button and show info message to save site before adding DNS VSes.
     */
    public canAddDnsVs = false;

    public dnsVsCollection: GSLBVSCollection;

    public dnsVsGridConfig: IAviDataGridConfig;

    public isLoading: boolean;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject(GSLBVSCollection)
        private readonly GSLBVSCollection: TGSLBVSCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public async ngOnInit(): Promise<void> {
        this.canAddDnsVs = Boolean(this.clusterUuid);

        if (this.canAddDnsVs) {
            this.dnsVsCollection = new this.GSLBVSCollection({
                gslbSiteId: this.clusterUuid,
                gslbTenant: this.tenantId,
                params: {
                    fields: 'name,tenant_ref,uuid',
                    'application_profile_ref.type':
                        ApplicationProfileType.APPLICATION_PROFILE_TYPE_DNS,
                    headers_: { 'X-Avi-Internal-All-Tenants': true },
                },
            });

            this.isLoading = true;
            await this.dnsVsCollection.load();
            this.isLoading = false;
            this.dnsVses.config.forEach(dnsVs => {
                dnsVs.config.dnsVsRef =
                    this.dnsVsCollection.getItemById(dnsVs.config.dns_vs_uuid)?.config.url;
            });
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.dnsVsGridConfig = {
            fields: [{
                label: l10nService.getMessage(l10nKeys.dnsVirtualServiceLabel),
                id: 'dns-vs',
                transform: ({ config }: GslbSiteDnsVsConfigItem) => {
                    return config.dnsVsRef ?
                        this.stringService.name(config.dnsVsRef) :
                        l10nService.getMessage(globalL10nKeys.emptyLabel);
                },
            },
            {
                label: l10nService.getMessage(l10nKeys.subdomainLabel),
                id: 'subdomain',
                transform: ({ config }: GslbSiteDnsVsConfigItem) => (
                    !config.domain_names?.length ?
                        l10nService.getMessage(l10nKeys.allSubdomainsPlaceholder) :
                        config.domain_names.map(domain => domain).join(', ')
                ),
            },
            {
                label: l10nService.getMessage(l10nKeys.hostAllSubdomainsLabel),
                id: 'host-all-subdomains',
                templateRef: this.allSubdomainTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                onClick: (dnsVsList: GslbSiteDnsVsConfigItem[]) => {
                    dnsVsList.forEach(dnsVs => this.onDeleteDnsVs.emit(dnsVs));
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(globalL10nKeys.editLabel),
                shape: 'pencil',
                onClick: (dnsVs: GslbSiteDnsVsConfigItem) => {
                    this.onEditDnsVs.emit(dnsVs);
                },
            },
            {
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: (dnsVs: GslbSiteDnsVsConfigItem) => {
                    this.onDeleteDnsVs.emit(dnsVs);
                },
            }],
        };
    }

    /**
     * Emit add dns vs event.
     */
    public addDnsVs(): void {
        this.onAddDnsVs.emit();
    }
}
