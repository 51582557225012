/**
 * Module containing core services.
 * @preferred
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { LoaderModule } from 'ng/modules/loader/loader.module';

import {
    AviAlertComponent,
    AviAppLevelAlertsPortalComponent,
    TransitionProgressBarComponent,
} from './components';

import {
    AppLevelAlertsService,
    AviAlertService,
    CentralLicenseAlertsService,
    DevLoggerService,
    LocationService,
    MyAccountService,
    ObjectDiffService,
    StatePermissionTreeService,
    TenantService,
} from './services';

import {
    AviDatePipe,
    I18nDatePipe,
} from './pipes';

const coreComponents = [
    AviAlertComponent,
    AviAppLevelAlertsPortalComponent,
    TransitionProgressBarComponent,
];

const coreServices = [
    StatePermissionTreeService,
    TenantService,
    DevLoggerService,
    AviAlertService,
    AppLevelAlertsService,
    CentralLicenseAlertsService,
    ObjectDiffService,
    LocationService,
    MyAccountService,
];

const corePipes = [
    AviDatePipe,
    I18nDatePipe,
];

@NgModule({
    declarations: [
        ...coreComponents,
        ...corePipes,
    ],
    providers: [
        ...coreServices,
        ...corePipes,
    ],
    imports: [
        SharedModule,
        CommonModule,
        DialogModule,
        FormsModule,
        LoaderModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [
        ...corePipes,
        TransitionProgressBarComponent,
    ],
})
export class CoreModule { }
