/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-auth-card';
const prefix = `${moduleName}.${componentName}`;

export const authHeader = `${prefix}.authHeader`;
export const authTypeRemoteLabel = `${prefix}.authTypeRemoteLabel`;
export const authTypeLocalLabel = `${prefix}.authTypeLocalLabel`;
export const authProfileLabel = `${prefix}.authProfileLabel`;
export const authMappingProfileLabel = `${prefix}.authMappingProfileLabel`;

export const ENGLISH = {
    [authHeader]: 'Authentication',
    [authTypeRemoteLabel]: 'Remote',
    [authTypeLocalLabel]: 'Local',
    [authProfileLabel]: 'Auth Profiles',
    [authMappingProfileLabel]: 'Auth Mapping Profiles',
};
