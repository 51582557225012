<div
    class="gslb-geo-location-config"
    provideParentForm
>
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <label
                aviLabelWithTooltip
                objectType="{{ objectType.GslbSite }}"
                fieldName="location"
            >
                {{ l10nKeys.geoLocationSourceLabel | vtranslate }}
            </label>
            <avi-fieldset [hideContent]="!isLocationConfigured">
                <avi-fieldset_header>
                    <avi-checkbox
                        class="gslb-geo-location-config__no-margin-top"
                        name="gslb-location-user-configured"
                        [noMarginTop]="true"
                        [(ngModel)]="isLocationConfigured"
                        (ngModelChange)="setGeoLocationSource($event)"
                    >
                        {{ l10nKeys.userConfiguredOptionLabel | vtranslate }}
                    </avi-checkbox>
                </avi-fieldset_header>
                <avi-fieldset_content>
                    <ng-container *ngIf="isLocationConfigured">
                        <avi-alert-group
                            *ngIf="!editable.isLocationConfigured()"
                            alertType="warning"
                        >
                            <span>{{ l10nKeys.atleastOneGeoLocationWarning | vtranslate }}</span>
                        </avi-alert-group>
                        <avi-input-container [invalidateForm]="!editable.isLocationConfigured()">
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.GeoLocation }}"
                                fieldName="name"
                                for="geo-location-name"
                            >
                                {{ globalL10nKeys.nameLabel | vtranslate }}
                            </label>
                            <input
                                id="geo-location-name"
                                name="geo-location-name"
                                [(ngModel)]="editable.config.location.config.name"
                                clrInput
                                placeholder="{{ globalL10nKeys.namePlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                        <avi-input-container>
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectType.GeoLocation }}"
                                fieldName="tag"
                                for="tag"
                            >
                                {{ l10nKeys.tagLabel | vtranslate }}
                            </label>
                            <input
                                id="tag"
                                name="tag"
                                [(ngModel)]="editable.config.location.config.tag"
                                clrInput
                                placeholder="{{ l10nKeys.tagPlaceholder | vtranslate }}"
                            />
                        </avi-input-container>
                        <div class="full-modal-config__form-control-row">
                            <div class="full-modal-config__form-control">
                                <avi-input-container
                                    noMarginTop
                                    helperText="{{ l10nKeys.degreesHelperText | vtranslate }}"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.GeoLocation }}"
                                        fieldName="latitude"
                                        for="latitude"
                                    >
                                        {{ l10nKeys.latitudeLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="latitude"
                                        name="latitude"
                                        type="number"
                                        configFieldInputValidation
                                        objectType="{{ objectType.GeoLocation }}"
                                        fieldName="latitude"
                                        clrInput
                                        placeholder="{{ l10nKeys.latitudePlaceholder | vtranslate }}"
                                        [(ngModel)]="editable.config.location.config.latitude"
                                    />
                                </avi-input-container>
                            </div>
                            <div class="full-modal-config__form-control">
                                <avi-input-container
                                    noMarginTop
                                    helperText="{{ l10nKeys.degreesHelperText | vtranslate }}"
                                >
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectType.GeoLocation }}"
                                        fieldName="longitude"
                                        for="longitude"
                                    >
                                        {{ l10nKeys.longitudeLabel | vtranslate }}
                                    </label>
                                    <input
                                        id="longitude"
                                        name="longitude"
                                        type="number"
                                        configFieldInputValidation
                                        objectType="{{ objectType.GeoLocation }}"
                                        fieldName="longitude"
                                        clrInput
                                        placeholder="{{ l10nKeys.longitudePlaceholder | vtranslate }}"
                                        [(ngModel)]="editable.config.location.config.longitude"
                                    />
                                </avi-input-container>
                            </div>
                        </div>
                    </ng-container>
                </avi-fieldset_content>
            </avi-fieldset>
        </div>
    </div>
</div>
