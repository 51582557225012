/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthScoreModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { OperationalState } from 'generated-types';

/**
 * Enum consisting of health score threshold values.
 */
enum HealthScoreThreshold {
    HIGH = 85,
    MEDIUM = 65,
    LOW = 0,
}

/**
 * Enum consisting of health score labels.
 */
enum HealthScore {
    HIGH = 'HIGH',
    MEDIUM = 'MEDIUM',
    LOW = 'LOW',
    DEACTIVATED = 'DEACTIVATED',
    DOWN = 'DOWN',
}

/**
 * Prefix for health score color class names.
 */
const AVI_HEALTH_SCORE_CLASS_PREFIX = 'avi-health-score__colors';

/**
 * Enum consisting of health score classes.
 */
const HealthScoreClasses = new Map([
    [HealthScore.HIGH, `${AVI_HEALTH_SCORE_CLASS_PREFIX}--high`],
    [HealthScore.LOW, `${AVI_HEALTH_SCORE_CLASS_PREFIX}--low`],
    [HealthScore.MEDIUM, `${AVI_HEALTH_SCORE_CLASS_PREFIX}--medium`],
    [HealthScore.DEACTIVATED, `${AVI_HEALTH_SCORE_CLASS_PREFIX}--deactivated`],
    [HealthScore.DOWN, `${AVI_HEALTH_SCORE_CLASS_PREFIX}--down`],
]);

/**
 * Map of good or not good operational statuses.
 */
const GoodOrBadOperStateget = new Map([
    [OperationalState.OPER_UP, true],
    [OperationalState.OPER_ERROR_DISABLED, true],
    [OperationalState.OPER_DISABLING, true],
    [OperationalState.OPER_DISABLED, false],
    [OperationalState.OPER_INACTIVE, false],
    [OperationalState.OPER_UNUSED, false],
]);

/**
 * @description Pipe to get class name based on health score and state.
 * @author Nitesh Kesarkar
 */
@Pipe({
    name: 'healthScoreClass',
})
export class HealthScoreClassPipe implements PipeTransform {
    /**
     * Return the health score class based on the score and state provided.
     */
    // eslint-disable-next-line class-methods-use-this
    public transform(score: number, state: OperationalState)
        : string {
        let className: string | undefined = '';

        const goodState = GoodOrBadOperStateget.get(state);

        if (goodState) {
            if (score >= HealthScoreThreshold.HIGH) {
                className = HealthScoreClasses.get(HealthScore.HIGH);
            } else if (score >= HealthScoreThreshold.MEDIUM) {
                className = HealthScoreClasses.get(HealthScore.MEDIUM);
            } else if (score >= HealthScoreThreshold.LOW) {
                className = HealthScoreClasses.get(HealthScore.LOW);
            }
        } else if (goodState === false) {
            className = HealthScoreClasses.get(HealthScore.DEACTIVATED);
        } else {
            className = HealthScoreClasses.get(HealthScore.DOWN);
        }

        return className;
    }
}
