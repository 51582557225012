<div class="system-settings-access-client-managemeent-access-card">
    <h6 class="system-settings-access-client-managemeent-access-card__header">
        {{ l10nKeys.clientManagementAccessHeader | vtranslate }}
    </h6>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="ssh_access"
        >
            {{ l10nKeys.allowSshClientsLabel | vtranslate }} 
        </label>
        <avi-input-container
            noMarginTop
            *ngFor="let client of allowedSshClientsValues; index as i; trackBy: trackByIndex"
        >
            <input
                name="allow-ssh-clients-{{ i }}"
                placeholder="-"
                clrInput
                [value]="client"
                readonly
            />
        </avi-input-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="shell_server_access"
        >
            {{ l10nKeys.cliShellClientsLabel | vtranslate }} 
        </label>
        <avi-input-container
            noMarginTop
            *ngFor="let client of cliShellClientsValues; index as i; trackBy: trackByIndex"
        >
            <input
                name="cli-shell-clients-{{ i }}"
                placeholder="-"
                clrInput
                [value]="client"
                readonly
            />
        </avi-input-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="api_access"
        >
            {{ l10nKeys.allowExternalHttpsClientsLabel | vtranslate }} 
        </label>
        <avi-input-container
            noMarginTop
            *ngFor="let client of allowedExternalHttpsClientsValues; index as i; trackBy: trackByIndex"
        >
            <input
                name="allow-external-https-clients-{{ i }}"
                placeholder="-"
                clrInput
                [value]="client"
                readonly
            />
        </avi-input-container>
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="snmp_access"
        >
            {{ l10nKeys.allowExternalSnmpClientsLabel | vtranslate }} 
        </label>
        <avi-input-container
            noMarginTop
            *ngFor="let client of allowedExternalSnmpClientsValues; index as i; trackBy: trackByIndex"
        >
            <input
                name="allow-external-snmp-clients-{{ i }}"
                placeholder="-"
                clrInput
                [value]="client"
                readonly
            />
        </avi-input-container>
    </avi-input-container>
</div>
