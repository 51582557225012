/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 *     Pipe for converting data of any type to a string;
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'toString',
})
export class ToStringPipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
    public transform(src: any): string {
        return String(src);
    }
}
