/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ClusterModule
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import { ClusterStateValue } from 'generated-types';

/**
 * @description Pipe that transforms a ClusterStateValue enum value to a Clarity icon status string.
 * @author alextsg
 */
@Pipe({
    name: 'clusterStateIconStatus',
})
export class ClusterStateIconStatusPipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this
    public transform(clusterState: ClusterStateValue): 'success' | 'warning' | '' {
        switch (clusterState) {
            case ClusterStateValue.CLUSTER_UP_HA_ACTIVE:
            case ClusterStateValue.CLUSTER_UP_HA_NOT_READY:
            case ClusterStateValue.CLUSTER_UP_NO_HA:
                return 'success';

            case ClusterStateValue.CLUSTER_UP_HA_COMPROMISED:
                return 'warning';

            default:
                return '';
        }
    }
}
