/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'selected-seg-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleVersion = `${prefix}.columnTitleVersion`;
export const selectedSegHeadersLabel = `${prefix}.selectedSegHeadersLabel`;
export const seGroupPluralLabel = `${prefix}.seGroupPluralLabel`;
export const toBeUpdatedLabel = `${prefix}.toBeUpdatedLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleVersion]: 'Version',
    [seGroupPluralLabel]: '{0, plural, one{# Service Engine Group} other{# Service Engine Groups}}',
    [toBeUpdatedLabel]: 'to be updated',
    [selectedSegHeadersLabel]: '{0, plural, one{Service Engine Group (#)} other{Service Engine Groups (#)}}',
};
