<div
    provideParentForm
    class="tenancy-mode-config"
>
    <avi-radio-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="tenant_vrf"
        >
            {{ l10nKeys.ipRouteDomainLabel | vtranslate }}
        </label>
        <avi-radio
            class="sel-per-tenant"
            name="tenant-vrf"
            [value]="true"
            [(ngModel)]="tenantConfig.tenant_vrf"
        >
            {{ l10nKeys.ipRouteDomainPerTenantLabel | vtranslate }}
        </avi-radio>
        <avi-radio
            class="sel-share-across-tenant"
            name="tenant-vrf"
            [value]="false"
            [(ngModel)]="tenantConfig.tenant_vrf"
        >
            {{ l10nKeys.ipRouteDomainShareAcrossTenantsLabel | vtranslate }}
        </avi-radio>
    </avi-radio-container>

    <div class="tenancy-mode-config__avi-fieldset">
        <avi-fieldset [hideContent]="!isSeInProviderContext">
            <avi-fieldset_header>
                <avi-radio-container [noMarginTop]="true">
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectType"
                        fieldName="se_in_provider_context"
                    >
                        {{ l10nKeys.seContextLabel | vtranslate }}
                    </label>
                    <avi-radio
                        class="sel-tenant-context"
                        name="se-context"
                        [value]="false"
                        [(ngModel)]="tenantConfig.se_in_provider_context"
                        (change)="setIsSeInProviderContext()"
                    >
                        {{ l10nKeys.seTenantContextLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        class="sel-provider-context-shared"
                        name="se-context"
                        [value]="true"
                        [(ngModel)]="tenantConfig.se_in_provider_context"
                        (change)="setIsSeInProviderContext()"
                    >
                        {{ l10nKeys.seProviderContextSharedLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
            </avi-fieldset_header>
            <avi-fieldset_content>
                <avi-radio-container [noMarginTop]="true">
                    <label
                        aviLabelWithTooltip
                        [objectType]="objectType"
                        fieldName="tenant_access_to_provider_se"
                    >
                        {{ l10nKeys.tenantSeAccessLabel | vtranslate }}
                    </label>
                    <avi-radio
                        class="sel-read-access"
                        name="tenant-access"
                        [value]="true"
                        [(ngModel)]="tenantConfig.tenant_access_to_provider_se"
                    >
                        {{ globalL10nKeys.readLabel | vtranslate }}
                    </avi-radio>
                    <avi-radio
                        class="sel-no-access"
                        name="tenant-access"
                        [value]="false"
                        [(ngModel)]="tenantConfig.tenant_access_to_provider_se"
                    >
                        {{ l10nKeys.tenantSeNoAccessLabel | vtranslate }}
                    </avi-radio>
                </avi-radio-container>
            </avi-fieldset_content>
        </avi-fieldset>
    </div>
</div>
