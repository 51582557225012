/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import './license-summary.component.less';
import { SaasLicensingInfo } from 'object-types';
import { LicenseSummaryService }
    from 'ajs/components/pages/administration/settings/licensing-page/license-summary';
import {
    LicenseTierSwitchModalComponent,
} from 'ng/modules/licensing/components/license-tier-switch-modal';
import { LicensingPageController } from '../licensing-page.component';
import { CENTRAL_LICENSE_ALERT_SERVICE_TOKEN } from '../../../../../../downgrade-services.tokens';
import * as l10n from './license-summary.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const {
    UPDATE_LICENSE_SUMMARY,
    OPEN_LICENSE_TIER_CHANGE_MODAL,
} = LicensingPageController;

/**
 * @constructor
 * @memberOf module:avi/licensing
 */
class LicenseSummaryController {
    /**
     * True when license summary is being loaded.
     * @protected
     */
    busy_ = false;

    /**
     * @type {module:avi/licensing.LicenseUsageByType[]}
     */
    licenseSummaryData = [];

    /**
     * Holds the saas license configuration.
     * @type {ISaasLicensingInfo}
     */
    saasConfig = {};

    objectType = SaasLicensingInfo;

    constructor(
        $scope,
        LicenseUsage,
        systemConfigService,
        systemInfoService,
        defaultValues,
        l10nService,
        CportalService,
        centralLicenseAlertsService,
        schemaService,
    ) {
        this.$scope = $scope;
        this.LicenseUsage_ = LicenseUsage;
        this.systemConfig = systemConfigService;
        this.systemInfo = systemInfoService;
        this.cportalService = CportalService;
        this.defaultValues_ = defaultValues;
        this.centralLicenseAlertsService = centralLicenseAlertsService;
        this.l10nKeys = l10nKeys;
        this.schemaService = schemaService;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Message to be displayed on alert message.
         */
        this.licenseInfoAlertMessage = l10nService.getMessage(l10nKeys.unlockMoreFeaturesMessage);

        /**
         * Set saasConfig data.
         */
        this.setSaasConfig_();
    }

    /**
     * Getter for busy flag.
     * @returns {boolean}
     */
    get busy() {
        return this.busy_;
    }

    /**
     * Setter for busy flag.
     * @param {boolean} isBusy
     */
    set busy(isBusy) {
        this.busy_ = !!isBusy;
    }

    /**
     * Getter for default license tier summary data.
     * @return {Object.<string, number>|undefined}
     */
    get defaulTierSummaryData() {
        return this.licenseSummaryData[0]?.tiers[this.defaultLicenseTier];
    }

    /**
     * @listens module:avi/licensing#UPDATE_LICENSE_SUMMARY
     * @override
     */
    $onInit() {
        this.licenseData = new this.LicenseUsage_();
        this.getLicenseSummaryData_();

        //Fires upon user updating data in parent controller
        this.$scope.$on(UPDATE_LICENSE_SUMMARY, this.getLicenseSummaryData_);

        //Fires when user click on change license tier button to open license tier change modal.
        this.$scope.$on(OPEN_LICENSE_TIER_CHANGE_MODAL, this.openTierChangeModal);

        // Subscribe to license subscription status from reconcile api to update license details.
        this.licenseStatusSubscription = this.centralLicenseAlertsService.licenseSubscriptionStatus$
            .subscribe(() => {
                this.reloadLicenseDetails_();
            });
    }

    /**
     * Function to reload license details.
     * @protected
     */
    reloadLicenseDetails_ = () => {
        this.setSaasConfig_();

        this.getLicenseSummaryData_();
    }

    /**
     * Makes api call for licenseSummary.
     * @protected
     */
    getLicenseSummaryData_ = () => {
        this.busy = true;

        this.licenseData.load()
            .then(() => {
                this.setLicenseTypeList_(this.licenseData.config);
            })
            .finally(() => {
                this.busy = false;
            });
    };

    /**
     * Builds {@link module:avi/licensing.LicenseSummaryRowData[]} for summary list in view.
     * @param {module:avi/licensing.LicenseHash} licenseHash
     * @param {module:avi/licensing.LicenseType[]} licenseTypes
     * @param {module:avi/licensing.LicenseTierType[]} licenseTierTypes
     * @protected
     */
    setLicenseTypeList_({
        licenseHash,
        licenseTypes,
        licenseTierTypes,
    }) {
        this.licenseSummaryData =
            LicenseSummaryService.getLicenseListByType(licenseHash, licenseTypes, licenseTierTypes);
    }

    /**
     * Set saasConfig Data.
     * @protected
     */
    setSaasConfig_ = () => {
        this.cportalService.getPortalInfo().then(portalData => {
            this.saasConfig = portalData.saas_licensing_config;
        });
    };

    /**
     * Upon tier is successfully changed, reload systemConfig, systemInfo,
     * defaultValues and re-fetch summary
     * data.
     */
    reloadData = () => {
        this.systemInfo.load(true);

        this.systemConfig.load().then(() => {
            this.onTierChange();
        });

        // Update saasConfig to update Reserved Licenses or Maximum Allowed values changes
        // from license tier switch modal.
        this.setSaasConfig_();
        this.defaultValues_.load(true);
        this.getLicenseSummaryData_();
    }

    /**
     * When the page header cog button is clicked, open a full modal for licensing configurations.
     */
    openTierChangeModal = () => {
        this.systemConfig.edit(LicenseTierSwitchModalComponent, {
            licenseTiersUsageData: this.licenseSummaryData[0].tiers,
            onSaveSuccess: this.reloadData,
        });
    }

    /** @override */
    $onDestroy() {
        this.licenseData.destroy();
        this.licenseStatusSubscription.unsubscribe();
    }

    get licenseTierTitle() {
        return this.schemaService.getEnumValue('LicenseTierType', this.defaultLicenseTier).label;
    }
}

LicenseSummaryController.$inject = [
    '$scope',
    'LicenseUsage',
    'systemConfigService',
    'systemInfoService',
    'defaultValues',
    'l10nService',
    'CportalService',
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
    'schemaService',
];

const componentTag = 'license-summary';
const templateUrl = 'src/components/pages/administration/settings/licensing-page/' +
    `${componentTag}/${componentTag}.component.html`;

/**
 * @name licenseSummaryComponent
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.LicenseSummaryController} controller
 * @author Akul Aggarwal, Zhiqian Liu
 * @description Summary read-only table for licenses used and available.
 */
angular.module('avi/licensing').component('licenseSummary', {
    bindings: {
        defaultLicenseTier: '@',
        orgServiceUnits: '<',
        onTierChange: '&',
    },
    controller: LicenseSummaryController,
    templateUrl,
});
