/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { TDoubleArrowColor } from 'ng/modules/diagram/components/double-arrow/double-arrow.types';
import * as globalL10n from 'global-l10n';

import './vs-log-cinematic-end-to-end-bridge.component.less';

const { ...globalL10nKeys } = globalL10n;
const ONE_MILLISECOND = 1;

/**
 * @description
 *     Component for displaying a double arrow with an end to end time in milliseconds above it and
 *     an HTTP response status code under it. The double arrow & under texts may not show if data
 *     is unavailable. This componenet provides fitlering function by exposing outlets for the time
 *     and the code.
 * @author Zhiqian Liu, Suraj Kumar
 */
@Component({
    selector: 'vs-log-cinematic-end-to-end-bridge',
    templateUrl: './vs-log-cinematic-end-to-end-bridge.component.html',
})
export class VsLogCinematicEndToEndBridgeComponent implements OnInit {
    /**
     * The header text of the top part over the double arrow.
     */
    @Input()
    public topHeader: string;

    /**
     * End to end time in milliseconds.
     * Can be missing if not set.
     */
    @Input()
    public endToEndTime?: number;

    /**
     * Filter property associated with end to end time.
     */
    @Input()
    public endToEndTimeFilterProp: string;

    /**
     * Color for the top header texts.
     */
    @Input()
    public topHeaderColor?: string;

    /**
     * Http response status code.
     */
    @Input()
    public statusCode?: number;

    /**
     * Filter property associated with the status code.
     */
    @Input()
    public statusCodeFilterProp?: string;

    /**
     * Indicator of whether to exclude the double arrow.
     */
    @Input()
    public noArrow ?= false;

    @Input()
    public isHeaderClickable = false;

    /**
     * Flag to track whether this bridge is currently selected or not.
     */
    @Input()
    public isActive = false;

    @Input()
    public isTimeUnitFilterable = true;

    /**
     * Indicator of whether to exclude showing the status code, chances being that the status code
     * block is not required to show at all, as opposed to it not being returned as a result.
     */
    @Input()
    public noStatusCode ?= false;

    /**
     * The color to be set for the double arrow.
     */
    @Input()
    public arrowColor?: TDoubleArrowColor;

    /**
     * Indicate if this is for WAF timing, where we need to convert the display value from
     * microseconds to milliseconds and put no lower limit "1".
     */
    @Input()
    public isWafTiming?: boolean = false;

    /**
     * Event emitter to indicate that a filter has been added from this component.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    /**
     * Event emitter to indicate that header has been clicked.
     */
    @Output()
    public onHeaderClick = new EventEmitter<void>();

    /**
     * Lower limit for e2e time unit, anything less that this will be shown as <1 value.
     */
    public readonly endToEndTimeLowerLimit = ONE_MILLISECOND;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Display value of the endToEndTime input based on conditions.
     */
    public displayValue: number;

    /** @override */
    public ngOnInit(): void {
        this.setDisplayValue();
    }

    /**
     * Set display value of the endToEndTime input based on conditions.
     */
    private setDisplayValue(): void {
        if (this.isWafTiming) {
            // for WAF, convert microseconds to milliseconds
            this.displayValue = this.endToEndTime / 1000;
        } else {
            this.displayValue = this.endToEndTime >= this.endToEndTimeLowerLimit ?
                this.endToEndTime : this.endToEndTimeLowerLimit;
        }
    }
}
