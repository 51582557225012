/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function tenantInventoryDataTransformerFactory(ListDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransformer
     * @author Ashish Verma
     * @desc ListDataTransformer of {@link module:avi/app.TenantInventory TenantInventory}.
     */
    class TenantInventoryDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            const inventoryFields = ['users', 'non-admin', 'tenant-admin'];

            resp = this.responseListOffsetControl_(resp, request);

            if ('results' in resp.data && Array.isArray(resp.data.results)) {
                const results = resp.data.results.map(function(tenant) {
                    return {
                        config: _.omit(tenant, inventoryFields),
                        inventory: _.pick(tenant, inventoryFields),
                    };
                });

                resp.data.results = results;
            }

            return resp;
        }
    }

    return TenantInventoryDataTransformer;
}

tenantInventoryDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

angular.module('avi/app').factory(
    'TenantInventoryDataTransformer',
    tenantInventoryDataTransformerFactory,
);
