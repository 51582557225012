/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 *  @module GslbModule
 */

/**
 * Ajs dependency token for GeoProfile Item.
 */
export const GSLB_GEODB_PROFILE_ITEM_TOKEN = 'GslbGeoDbProfileItem';

/**
 * Ajs dependency token for GeoProfile Collection.
 */
export const GSLB_GEODB_PROFILE_COLLECTION_TOKEN = 'GslbGeoDbProfileCollection';

/**
 * Ajs dependency token for FederationCheckpoint Collection.
 */
export const FEDERATION_CHECKPOINT_COLLECTION_TOKEN = 'FederationCheckpointCollection';

/**
 * Ajs dependency token for FederationCheckpoint Item.
 */
export const FEDERATION_CHECKPOINT_TOKEN = 'FederationCheckpoint';

export const GSLB_SERVICE_ITEM_TOKEN = 'GSLBService';

export const GSLB_VS_COLLECTION_TOKEN = 'GSLBVSCollection';
