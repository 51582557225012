/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'update';
const componentName = 'prechecks-list';
const prefix = `${moduleName}.${componentName}`;

export const precheckPendingLabel = `${prefix}.precheckPendingLabel`;
export const precheckPassSuccessLabel = `${prefix}.precheckPassSuccessLabel`;
export const precheckErrorLabel = `${prefix}.precheckErrorLabel`;
export const precheckPassWarningLabel = `${prefix}.precheckPassWarningLabel`;

export const ENGLISH = {
    [precheckPendingLabel]: 'Pending',
    [precheckPassSuccessLabel]: 'Passed Successfully',
    [precheckErrorLabel]: 'An error occured during precheck: {0}',
    [precheckPassWarningLabel]: 'Passed With Warning',
};
