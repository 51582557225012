/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const ipAddrGroupCollectionProvider = {
    deps: [$injector],
    provide: 'IpAddrGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('IpAddrGroupCollection');
    },
};

export const rangeParserProvider = {
    deps: [$injector],
    provide: 'RangeParser',
    useFactory(injector: Injector): any {
        return injector.get('RangeParser');
    },
};
