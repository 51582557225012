/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CollectionsModule
 */

import {
    IGslbRuntime,
    IGslbSiteRuntime,
    IGslbThirdPartySiteRuntime,
    IOperationalStatus,
} from 'generated-types';

import {
    GSLB,
    IGSLBConfig,
} from 'items/gslb.item.factory';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';

import {
    GslbSiteConfigItem,
} from 'ng/modules/message-items/factories/gslb-site.config-item.factory';

import {
    GslbThirdPartySiteConfigItem,
} from 'ng/modules/message-items/factories/gslb-third-party-site.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

interface IGslbSiteArgs {
    gslb: GSLB;
    isStatic?: boolean;
}

interface IGslbCollectionItem {
    data : {
        runtime: {
            info : IGslbRuntime
        },
        config: IGSLBConfig,
    },
}

export interface IRealTimeGslbData {
    config: GslbSiteConfigItem | GslbThirdPartySiteConfigItem,
    runtime: IGslbSiteRuntime | IGslbThirdPartySiteRuntime,
}

const allDataSources = {
    list: {
        source: 'InventoryCollDataSource',
        transformer: 'InventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config', 'runtime'],
    },
};

export type TGSLBInventoryCollection = typeof GSLBInventoryCollection;

/**
 * @description
 * Collection for Gslb inventory.
 * Used to Poll data for gslb to have access to runtime data for gslb sites.
 * @author Hitesh Mandav
 */
export class GSLBInventoryCollection extends Collection {
    public static ngDependencies = [
        GSLB,
        'devLoggerService',
    ];

    public gslb: GSLB;

    public gslbReloadEvents = ['itemLoadSuccess', 'itemSaveSuccess'];

    private readonly devLoggerService: DevLoggerService;

    constructor(args: IGslbSiteArgs) {
        super({
            objectName: 'gslb-inventory',
            allDataSources,
            defaultDataFields: ['config', 'runtime'],
            isStatic: false,
            ...args,
        });

        this.itemClass_ = this.getNgDependency(GSLB);
        this.devLoggerService = this.getNgDependency('devLoggerService');

        if (args.gslb) {
            const { gslb } = args;

            this.gslb = gslb;
            this.gslbReloadEvents.forEach(eventType => gslb.bind(eventType, this.reload));
            gslb.one('beforeDestroy', this.destroy);
        } else {
            this.devLoggerService.error(
                'GSLB instance is needed for GSLBInventoryCollection to function.',
            );
        }
    }

    /**
     * Retrieve real-time GSLB data by merging configuration and runtime information.
     */
    public getMergedConfigWithRuntime(
        aviSites: RepeatedMessageItem<GslbSiteConfigItem>,
        thirdPartySites: RepeatedMessageItem<GslbThirdPartySiteConfigItem>,
    ): IRealTimeGslbData[] {
        const runtimeData = this.getRuntimeData();
        const mergedData: IRealTimeGslbData[] = [];

        // Merge data from 'config.sites' with 'runtime.site'.
        aviSites.config.forEach(configItem => {
            const runtimeSite = runtimeData.site.find(
                site => site.site_info.cluster_uuid === configItem.config.cluster_uuid,
            );

            mergedData.push({
                config: configItem,
                runtime: runtimeSite,
            });
        });

        // Merge data from 'config.third_party_sites' with 'runtime.third_party_sites'.
        thirdPartySites.config.forEach(configItem => {
            const runtimeSite = runtimeData.third_party_sites.find(
                site => site.site_info.cluster_uuid === configItem.config.cluster_uuid,
            );

            mergedData.push({
                config: configItem,
                runtime: runtimeSite,
            });
        });

        return mergedData;
    }

    /**
     * Retrieve oper_status of provided site uuid.
     */
    public getOperStatusOfSite(siteUuid: string): IOperationalStatus {
        const runtimeData = this.getRuntimeData();

        const runtimeSite = runtimeData.site.find(
            site => site.site_info.cluster_uuid === siteUuid,
        );

        return runtimeSite.site_info.oper_status;
    }

    /**
     * Retrieve runtime data for sites.
     */
    public getRuntimeSitesData(): IGslbSiteRuntime[] {
        const { site } = this.getRuntimeData();

        return site || [];
    }

    /**
     * Retrieve the GSLB site name from the provided id by searching the config data.
     */
    private getGslbNameFromId(id: string): string | undefined {
        const sites = this.getConfigData().sites.config;
        const site = sites.find(item => item.config.cluster_uuid === id);

        return site?.config.name;
    }

    /**
     * Retrieve the runtime data for the GSLB.
     */
    private getRuntimeData(): IGslbRuntime {
        const item = this.itemList[0] as IGslbCollectionItem;

        return item.data.runtime.info;
    }

    /**
     * Retrieve the config data for the GSLB.
     */
    private getConfigData(): IGSLBConfig {
        const item = this.itemList[0] as IGslbCollectionItem;

        return item.data.config;
    }

    /**
     * Retrieve the sites data for the GSLB.
     */
    private getAviSites(): RepeatedMessageItem<GslbSiteConfigItem> {
        const item = this.itemList[0] as IGslbCollectionItem;

        return item.data.config.sites;
    }

    /**
     * Retrieve the third party sites data for the GSLB.
     */
    private getThirdPartySites(): RepeatedMessageItem<GslbThirdPartySiteConfigItem> {
        const item = this.itemList[0] as IGslbCollectionItem;

        return item.data.config.third_party_sites;
    }

    /**
     * Reload the collection by calling load function.
     */
    private reload = (): Promise<void> => {
        return this.load();
    };
}
