<div class="system-settings-access-banners-card">
    <h6 class="system-settings-access-banners-card__header">
        {{ l10nKeys.bannersHeader | vtranslate }}
    </h6>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="motd"
            for="message-of-the-day"
        >
            {{ l10nKeys.messageOfTheDayLabel | vtranslate }} 
        </label>
        <input
            name="message-of-the-day"
            id="message-of-the-day"
            placeholder="{{ l10nKeys.messageOfTheDayDeactivatedPlaceholderValue | vtranslate }}"
            clrInput
            [ngModel]="messageOftheDay"
            readonly
        />
    </avi-input-container>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            [objectType]="objectType"
            fieldName="banner"
            for="login-banner"
        >
            {{ l10nKeys.loginBannerLabel | vtranslate }} 
        </label>
        <input
            name="login-banner"
            id="login-banner"
            placeholder="-"
            clrInput
            [ngModel]="loginBanner"
            readonly
        />
    </avi-input-container>
</div>
