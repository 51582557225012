/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module MessageItemsModule
 */

import { FailActionHTTPRedirect } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IFailActionHTTPRedirect } from 'generated-types';

interface IFailActionHTTPRedirectConfig extends IFailActionHTTPRedirect {
    url?: string,
}

/**
 * @description
 *
 *   IFailActionHttpRedirect config item.
 *
 * @author Rachit Aggarwal
 */
export class FailActionHttpRedirectConfigItem extends MessageItem<IFailActionHTTPRedirectConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: FailActionHTTPRedirect,
            ...args,
        };

        super(extendedArgs);
    }
}
