/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'alert';
const componentName = 'alert-email-modal';
const prefix = `${moduleName}.${componentName}`;

export const nameLabel = `${prefix}.nameLabel`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const toAddressLabel = `${prefix}.toAddressLabel`;
export const ccAddressLabel = `${prefix}.ccAddressLabel`;
export const toEmailPlaceholder = `${prefix}.toEmailPlaceholder`;
export const ccEmailPlaceholder = `${prefix}.ccEmailPlaceholder`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;

export const ENGLISH = {
    [toAddressLabel]: 'To Address',
    [ccAddressLabel]: 'CC Address',
    [toEmailPlaceholder]: 'Enter To Address(es)',
    [ccEmailPlaceholder]: 'Enter CC Address(es)',
    [modalHeaderEdit]: 'Edit Email Notification',
    [modalHeaderNew]: 'New Email Notification',
};
