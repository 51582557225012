<full-modal-config
    modalTitle="{{ (editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleNew) | vtranslate }}"
    modalSubTitle="{{ editable.getName() }}"
    (onCancel)="editable.dismiss(true)"
    (onSubmit)="editable.submit()"
    [busy]="editable.isBusy()"
    [errors]="editable.errors"
    [valid]="form.valid"
    [modified]="form.touched && form.dirty"
    class="scheduler-modal"
>
    <form
        clrForm
        [clrLayout]="verticalLayout"
        #form="ngForm"
    >
        <full-modal-tab-section tabTitle="{{ l10nKeys.generalHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.generalHeader | vtranslate }}</h3>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            name="enabled"
                            [(ngModel)]="editable.config.enabled"
                            id="enabled"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypeEnum.SCHEDULER }}"
                            fieldName="enabled"
                            for="enabled"
                        >
                            {{ l10nKeys.enableConfigurationBackupLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="name"
                        objectType="{{ objectTypeEnum.SCHEDULER }}"
                        fieldName="name"
                        required
                    >
                        {{ l10nKeys.nameLabel | vtranslate }}
                    </label>
                    <input
                        id="name"
                        name="name"
                        clrInput
                        [(ngModel)]="editable.config.name"
                        [readonly]="editable.id"
                        required
                    />
                </avi-input-container>
                <backup-passphrase
                    [singleRow]="true"
                    name="backup_passphrase"
                    [(ngModel)]="editable.backupPassphrase"
                    [confirmPassphrase]="initialPassphrase"
                ></backup-passphrase>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="backup_file_prefix"
                        objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                        fieldName="backup_file_prefix"
                    >
                        {{ l10nKeys.filePrefixLabel | vtranslate }}
                    </label>
                    <input
                        id="backup_file_prefix"
                        name="backup_file_prefix"
                        clrInput
                        placeholder="{{ l10nKeys.filePrefixLabel | vtranslate }}"
                        [(ngModel)]="editable.backupConfigRefData.backup_file_prefix"
                    />
                </avi-input-container>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                            fieldName="remote_file_transfer_protocol"
                            for="remote-file-transfer-protocol"
                        >
                            {{ l10nKeys.protocolLabel | vtranslate }}
                        </label>
                        <avi-enum-dropdown
                            name="remote-file-transfer-protocol"
                            enum="RemoteFileTransferProtocol"
                            [(ngModel)]="editable.backupConfigRefData.remote_file_transfer_protocol"
                            placeholder="{{ l10nKeys.protocolPlaceholder | vtranslate }}"
                        ></avi-enum-dropdown>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <h6 class="full-modal__section-header">{{ l10nKeys.schedulerHeader | vtranslate }}</h6>
                    </div>
                </div>
                <div class="full-modal-config__form-control-row">
                    <div class="full-modal-config__form-control">
                        <avi-input-container noMarginTop>
                            <label
                                aviLabelWithTooltip
                                for="frequency"
                                objectType="{{ objectTypeEnum.SCHEDULER }}"
                                fieldName="frequency"
                                required
                            >
                                {{ l10nKeys.frequencyLabel | vtranslate }}
                            </label>
                            <input
                                type="number"
                                id="frequency"
                                name="frequency"
                                clrInput
                                placeholder="{{ l10nKeys.numberPlaceholderLabel | vtranslate }}"
                                regexPattern="integer"
                                configFieldInputValidation
                                objectType="{{ objectTypeEnum.SCHEDULER }}"
                                fieldName="frequency"
                                [(ngModel)]="editable.config.frequency"
                                required
                            />
                        </avi-input-container>
                    </div>
                    <div class="full-modal-config__form-control">
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypeEnum.SCHEDULER }}"
                            fieldName="frequency_unit"
                            for="frequency_unit"
                            required
                        >
                            {{ l10nKeys.frequencyUnitLabel | vtranslate }}
                        </label>
                        <avi-dropdown
                            name="frequency_unit"
                            [options]="schedulerFrequencyUnitOptions"
                            [(ngModel)]="editable.config.frequency_unit"
                            placeholder="{{ l10nKeys.frequencyUnitInputPlaceholder | vtranslate }}"
                            required
                        ></avi-dropdown>
                    </div>
                </div>
                <avi-input-container>
                    <label
                        aviLabelWithTooltip
                        for="maximum_backups_stored"
                        objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                        fieldName="maximum_backups_stored"
                    >
                        {{ l10nKeys.numberOfBackupsLabel | vtranslate }}
                    </label>
                    <input
                        type="number"
                        id="maximum_backups_stored"
                        name="maximum_backups_stored"
                        clrInput
                        placeholder="{{ l10nKeys.numberPlaceholderLabel | vtranslate }}"
                        regexPattern="integer"
                        configFieldInputValidation
                        objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                        fieldName="maximum_backups_stored"
                        [(ngModel)]="editable.backupConfigRefData.maximum_backups_stored"
                    />
                </avi-input-container>
            </div>
        </full-modal-tab-section>
        <full-modal-tab-section tabTitle="{{ l10nKeys.backupDestinationHeader | vtranslate }}">
            <div class="full-modal-config__section">
                <h3>{{ l10nKeys.backupDestinationHeader | vtranslate }}</h3>
                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            name="save_local"
                            [(ngModel)]="editable.backupConfigRefData.save_local"
                            id="save_local"
                        />
                        <label
                            aviLabelWithTooltip
                            objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                            fieldName="save_local"
                            for="save_local"
                        >
                            {{ l10nKeys.enableLocalBackupLabel | vtranslate }}
                        </label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <fieldset class="full-modal-config__fieldset scheduler-modal__fieldset-container">
                    <clr-checkbox-container
                        clrInline
                        class="scheduler-modal__type-checkbox-container"
                    >
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                name="upload_to_remote_host"
                                [(ngModel)]="editable.backupConfigRefData.upload_to_remote_host"
                                id="upload_to_remote_host"
                            />
                            <label
                                aviLabelWithTooltip
                                objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                                fieldName="upload_to_remote_host"
                                for="upload_to_remote_host"
                            >
                                {{ l10nKeys.enableRemoteServerLabel | vtranslate }}
                            </label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <ng-container *ngIf="editable.backupConfigRefData.upload_to_remote_host">
                        <div class="scheduler-modal__selection-wrapper-section">
                            <avi-input-container noMarginTop>
                                <label
                                    aviLabelWithTooltip
                                    for="remote_hostname"
                                    objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                                    fieldName="remote_hostname"
                                    required
                                >
                                    {{ l10nKeys.serverAddressInputLabel | vtranslate }}
                                </label>
                                <input
                                    id="remote_hostname"
                                    name="remote_hostname"
                                    clrInput
                                    placeholder="{{ l10nKeys.serverAddressInputPlaceholder | vtranslate }}"
                                    regexPattern="hostname"
                                    [(ngModel)]="editable.backupConfigRefData.remote_hostname"
                                    required
                                />
                            </avi-input-container>
                            <avi-input-container helperText="{{ l10nKeys.remoteDefaultDirectoryLabel | vtranslate }}">
                                <label
                                    aviLabelWithTooltip
                                    for="remote_directory"
                                    objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                                    fieldName="remote_directory"
                                >
                                    {{ l10nKeys.remoteDirectoryInputLabel | vtranslate }}
                                </label>
                                <input
                                    id="remote_directory"
                                    name="remote_directory"
                                    clrInput
                                    placeholder="{{ l10nKeys.remoteDirectoryInputPlaceholder | vtranslate }}"
                                    [(ngModel)]="editable.backupConfigRefData.remote_directory"
                                />
                            </avi-input-container>
                            <div class="full-modal-config__form-control-row">
                                <div class="full-modal-config__form-control">
                                    <label
                                        aviLabelWithTooltip
                                        objectType="{{ objectTypeEnum.BACKUP_CONFIGURATION }}"
                                        fieldName="ssh_user_uuid"
                                        for="ssh_user_uuid"
                                        required
                                    >
                                        {{ l10nKeys.userCredentialsInputLabel | vtranslate }}
                                    </label>
                                    <avi-collection-dropdown
                                        [collection]="sshUserCollection"
                                        [(ngModel)]="editable.backupConfigRefData.ssh_user_ref"
                                        name="ssh_user_uuid"
                                        placeholder="{{ l10nKeys.userCredentialsInputPlaceholder | vtranslate }}"
                                        required
                                    ></avi-collection-dropdown>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </fieldset>
            </div>
        </full-modal-tab-section>
    </form>
</full-modal-config>
