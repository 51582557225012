/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-aws-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const accessKeyIdInputLabel = `${prefix}.accessKeyIdInputLabel`;
export const secretAccessKeyInputLabel = `${prefix}.secretAccessKeyInputLabel`;
export const regionInputLabel = `${prefix}.regionInputLabel`;
export const awsHeaderLabel = `${prefix}.awsHeaderLabel`;
export const accessLabel = `${prefix}.accessLabel`;
export const iamRolesLabel = `${prefix}.iamRolesLabel`;
export const proxyLabel = `${prefix}.proxyLabel`;
export const hostIpAddressInputLabel = `${prefix}.hostIpAddressInputLabel`;
export const useIamAssumeRoleLabel = `${prefix}.useIamAssumeRoleLabel`;
export const iamAssumeRoleDropdownLabel = `${prefix}.iamAssumeRoleDropdownLabel`;
export const iamAssumeRoleDropdownPlaceholder = `${prefix}.iamAssumeRoleDropdownPlaceholder`;
export const vpcDropdownLabel = `${prefix}.vpcDropdownLabel`;
export const vpcDropdownPlaceholder = `${prefix}.vpcDropdownPlaceholder`;
export const changingVpcWarning = `${prefix}.changingVpcWarning`;
export const usableDomainLabel = `${prefix}.usableDomainLabel`;
export const usableDomainsGridLabel = `${prefix}.usableDomainsGridLabel`;
export const domainInputPlaceholder = `${prefix}.domainInputPlaceholder`;

export const ENGLISH = {
    [accessKeyIdInputLabel]: 'Access Key ID',
    [secretAccessKeyInputLabel]: 'Secret Access Key',
    [regionInputLabel]: 'Region',
    [awsHeaderLabel]: 'AWS',
    [accessLabel]: 'Access',
    [iamRolesLabel]: 'IAM Roles',
    [proxyLabel]: 'Proxy',
    [hostIpAddressInputLabel]: 'Host IP Address',
    [useIamAssumeRoleLabel]: 'Use Cross-Account IAM AssumeRole',
    [iamAssumeRoleDropdownLabel]: 'IAM AssumeRole',
    [iamAssumeRoleDropdownPlaceholder]: 'Select IAM AssumeRole',
    [vpcDropdownLabel]: 'VPC',
    [vpcDropdownPlaceholder]: 'Select VPC',
    [changingVpcWarning]: 'Changing VPC selection will reset any stored Usable Networks/Domains.',
    [usableDomainLabel]: 'Usable Domain',
    [usableDomainsGridLabel]: 'Usable Domains ({0})',
    [domainInputPlaceholder]: 'Enter Domain',
};
