<div class="avi-diff-grid clr-wrapper">
    <clr-datagrid>
        <!-- show the column header anyway if the grid is empty, or hide it when it's the only empty column  -->
        <clr-dg-column
            class="avi-diff-grid__column-header"
            *ngIf="isGridEmpty || !leftColumnEmpty"
        >
            {{ config.leftField.label }}
        </clr-dg-column>
        <!-- show the column header anyway if the grid is empty, or hide it when it's the only empty column  -->
        <clr-dg-column
            class="avi-diff-grid__column-header"
            *ngIf="isGridEmpty || !rightColumnEmpty"
        >
            {{ config.rightField.label }}
        </clr-dg-column>
        <clr-dg-column
            *ngIf="showGridExpander && rows.length"
            [style.minWidth.px]="iconWidth"
            [style.maxWidth.px]="iconWidth"
        >
            <cds-icon
                class="avi-diff-grid__icon"
                shape="angle"
                direction="{{ isGridExpanded ? 'up' : 'down' }}"
                size="14"
                (click)="toggleGridExpanded()"
            ></cds-icon>
        </clr-dg-column>

        <clr-dg-row
            *ngFor="let row of rows; trackBy: trackByRowIndex"
            [hidden]="!isGridExpanded"
            class="avi-diff-grid__content"
        >
            <clr-dg-cell
                *ngIf="!leftColumnEmpty"
                class="avi-diff-grid__diff-column"
            >
                <div
                    class="avi-diff-grid__cell"
                    [ngClass]="getCellClass('left', row.type)"
                >
                    {{ row.left }}
                </div>
            </clr-dg-cell>
            <clr-dg-cell
                *ngIf="!rightColumnEmpty"
                class="avi-diff-grid__diff-column"
            >
                <div
                    class="avi-diff-grid__cell"
                    [ngClass]="getCellClass('right', row.type)"
                >
                    {{ row.right }}
                </div>
            </clr-dg-cell>
            <clr-dg-cell
                *ngIf="config.isGridExpandable"
                [style.minWidth.px]="iconWidth"
                [style.maxWidth.px]="iconWidth"
            ></clr-dg-cell>
        </clr-dg-row>
        <clr-dg-placeholder>
            {{ placeHolderMessage }}
        </clr-dg-placeholder>
    </clr-datagrid>
</div>
