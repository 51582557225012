<avi-state-page class="pool-group-list-page">
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.headerLabel | vtranslate }}"></avi-page-header>
        <avi-collection-data-grid [config]="poolGroupGridConfig"></avi-collection-data-grid>
    </avi-state-page_main-content>
</avi-state-page>

<ng-template
    #expandedContentTemplateRef
    let-row
>
    <div
        *ngIf="!row.busy"
        class="avi-data-grid__child-grid"
    >
        <avi-data-grid
            [config]="poolGroupMembersGridConfig"
            [rows]="row.members.config"
        ></avi-data-grid>
    </div>
</ng-template>

<ng-template
    #healthTemplateRef
    let-row
>
    <pool-health-score
        [poolCollection]="poolCollection"
        [poolGroupCollection]="poolGroupCollection"
        [poolRef]="row.config.pool_ref"
    ></pool-health-score>
</ng-template>

<ng-template
    #poolTemplateRef
    let-row
>
    <div class="avi-data-grid__cell-transform">
        <label 
            aviLabelWithTooltip
            *ngIf="!getVirtualServiceId(row.config.pool_ref)"
            tooltipText="{{ l10nKeys.poolNotUsedLabel | vtranslate }}"
        >
            {{ getPoolName(row.config.pool_ref) }}
        </label>
        <a
            *ngIf="getVirtualServiceId(row.config.pool_ref)"
            (click)="redirectToPoolDetailAnalytics(row.config.pool_ref)"
        >
            {{ getPoolName(row.config.pool_ref) }}
        </a>
    </div>
</ng-template>
