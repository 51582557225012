<div provideParentForm>
    <!-- Geo Location -->
    <gslb-geo-location-config
        [editable]="editable.config.location"
        [isLocationConfigured]="isLocationConfigured"
        (onSetGeoLocationSource)="setGeoLocationSource($event)"
    ></gslb-geo-location-config>
    <!-- Health Monitor proxy Grid -->
    <div class="full-modal-config__form-control-row">
        <div class="full-modal-config__form-control">
            <gslb-health-monitor-proxy-grid
                [activeGslbSiteOptions]="activeGslbSiteOptions"
                [healthMonitorProxies]="editable.config.hm_proxies"
            ></gslb-health-monitor-proxy-grid>
        </div>
    </div>
    <avi-input-container>
        <label
            aviLabelWithTooltip
            objectType="{{ objectType }}"
            fieldName="ratio"
            for="ratio"
        >
            {{ l10nKeys.overridePoolMemberRatioLabel | vtranslate }}
        </label>
        <input
            id="ratio"
            name="ratio"
            fieldName="ratio"
            objectType="{{ objectType }}"
            configFieldInputValidation
            [(ngModel)]="editable.config.ratio"
            clrInput
            placeholder="{{ globalL10nKeys.valuePlaceholder | vtranslate }}"
        />
    </avi-input-container>
</div>
