<div class="pool-health-score-body">
    <ng-container *ngIf="healthScore">
        <div class="pool-health-score-body__trending-score-label">
            {{ l10nKeys.trendingScoreLabel | vtranslate }}
        </div>
        <div class="pool-health-score-body__sparkline-graph">
            <avi-area-chart
                *ngIf="hsSeries && operationStatus"
                [hsSeries]="hsSeries"
                [class]="healthScore.health_score | healthScoreClass: operationStatus.state">
            </avi-area-chart>
        </div>
        <avi-health-score-bars
            [healthScore]="healthScore"
            [objectName]="objectName"
        ></avi-health-score-bars>
    </ng-container>
</div>
