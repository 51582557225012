<avi-state-page>
    <avi-state-page_main-content>
        <avi-page-header headerText="{{ l10nKeys.administrationOverviewHeader | vtranslate }}"></avi-page-header>
        <div class="administration-dashboard-page__avi-dashboard-widgets-container">
            <avi-dashboard-widgets
                [widgetConfigs]="widgetConfigs | permittedWidgets"
                (attachComponentBindingsEmitter)="attachComponentBindingsHandler($event)"
            ></avi-dashboard-widgets>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
