/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-ip-address-grid';
const prefix = `${moduleName}.${componentName}`;

export const siteControllerLabel = `${prefix}.siteControllerLabel`;

export const ENGLISH = {
    [siteControllerLabel]: 'Site Controller Cluster VIP Address ({0})',
};
