/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb-geodb-profile';
const componentName = 'geo-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const sectionTitleEntries = `${prefix}.sectionTitleEntries`;
export const atleastOneGeoDbWarning = `${prefix}.atleastOneGeoDbWarning`;

export const ENGLISH = {
    [modalHeaderEdit]: 'Edit Geo Profile',
    [modalHeaderNew]: 'New Geo Profile',
    [sectionTitleEntries]: 'Entries',
    [atleastOneGeoDbWarning]: 'Atleast one GeoDB entry needs to be configured to continue.',

};
