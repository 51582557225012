/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module GslbModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';

import {
    GslbSiteType,
    ReplicationMode,
    SiteMemberType,
} from 'generated-types';

import {
    Gslb,
    GslbSite,
    ReplicationPolicy,
} from 'object-types';

import { GSLB } from 'items/gslb.item.factory';
import { GslbSiteConfigItem } from 'message-items/gslb-site.config-item.factory';

import {
    GslbSiteDnsVsConfigItem,
} from 'message-items/gslb-site-dns-vs.config-item.factory';

import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { StringService } from 'ng/modules/core/services/string/string.service';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-site-modal.l10n';
import './gslb-site-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for GSLB Site.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'gslb-site-modal',
    templateUrl: './gslb-site-modal.component.html',
})
export class GslbSiteModalComponent {
    @Input()
    public editable: GslbSiteConfigItem;

    @Input()
    public gslb: GSLB;

    /**
     * Open model to add Dns VSes after active site is created and modal is reopened in edit Mode.
     */
    @Input()
    public openDnsModal: boolean;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    public objectType = {
        GslbSite,
        Gslb,
        ReplicationPolicy,
    };

    /**
     * True if the Gslb site is a leader site.
     */
    public isLeader = false;

    /**
     * Used to identify if the site is an avi or thord party site.
     */
    public isAviSite = false;

    /**
     * True if the site is an Active member.
     */
    public isActiveMember = false;

    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * List of active gslb Sites options available.
     */
    public activeGslbSiteOptions: IAviDropdownOption[] = [];

    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of GslbSiteType enum.
     */
    public readonly gslbSiteTypeHash = {
        GSLB_AVI_SITE: GslbSiteType.GSLB_AVI_SITE,
        GSLB_THIRD_PARTY_SITE: GslbSiteType.GSLB_THIRD_PARTY_SITE,
    };

    public readonly activeMemberType = SiteMemberType.GSLB_ACTIVE_MEMBER;

    /**
     * Tenant Id of the gslb.
     */
    public tenantId: string;

    /**
     * Gslb site modal header to be displayed based on type of site.(AVI/Third Party, New/Edit)
     */
    public modalHeader: string;

    /**
     * Save the changes and open the edit modal to add DNS VSes
     * by checking the checkbox in the modal footer.
     */
    public openDnsModalAfterSave = false;

    /**
     * List of hidden replication mode types.
     */
    public readonly hiddenReplicationModeTypes = [
        ReplicationMode.REPLICATION_MODE_MANUAL,
    ];

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setActiveMemberState();
        this.setActiveGslbSiteOptions();
        this.isLeader = this.gslb.isLeaderSiteId(this.editable.config.cluster_uuid);
        this.isAviSite = this.editable.messageType === this.objectType.GslbSite;
        this.tenantId = this.gslb.getTenantId();

        if (this.isAviSite && this.editable.config.cluster_uuid) {
            this.modalHeader = this.l10nService.getMessage(this.l10nKeys.editGslbSiteModalHeader);
        } else if (this.isAviSite && !this.editable.config.cluster_uuid) {
            this.modalHeader = this.l10nService.getMessage(this.l10nKeys.newGslbSiteModalHeader);
        } else if (!this.isAviSite && this.editable.config.cluster_uuid) {
            this.modalHeader =
                this.l10nService.getMessage(this.l10nKeys.editThirdPartySiteModalHeader);
        } else if (!this.isAviSite && !this.editable.config.cluster_uuid) {
            this.modalHeader =
                this.l10nService.getMessage(this.l10nKeys.newThirdPartySiteModalHeader);
        }

        if (this.openDnsModal) {
            this.addDnsVs();
        }
    }

    /**
     * Return the label for the member type (leader or follower) as a string.
     */
    public get memberTypeLabel(): string {
        return this.isLeader ? this.l10nService.getMessage(l10nKeys.leaderLabel) :
            this.l10nService.getMessage(l10nKeys.followerLabel);
    }

    /**
     * Return if setup DNS checbox needs to be displayed. True for new active Avi sites.
     */
    public get showSetupDnsCheckbox(): boolean {
        return !this.editable.config.cluster_uuid &&
            this.editable.config.member_type === this.activeMemberType &&
            this.isAviSite;
    }

    /**
     * Set gslb site member type.
     * Initiaize the DnsVs field if the site is active else delete the dns Vses.
     */
    public handleGslbMemberTypeChange(isActive: boolean): void {
        if (isActive) {
            this.editable.config.member_type = SiteMemberType.GSLB_ACTIVE_MEMBER;
            this.editable.initializeDnsVses();
        } else {
            this.editable.config.member_type = SiteMemberType.GSLB_PASSIVE_MEMBER;
            this.editable.deleteDnsVses();
        }
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        try {
            if (this.openDnsModalAfterSave) {
                this.saveAndOpenDnsVses();
            } else {
                this.saveSite();
            }
        } catch (error) {
            // empty catch block
        }
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Open Add Dns Vs modal.
     */
    public addDnsVs(): void {
        const availableDomains = this.gslb.getDNSDomainNames(true);
        const selectedVsId = this.editable.config.dns_vses.config
            .map(dnsVs => this.stringService.slug(dnsVs.config.dns_vs_uuid));

        this.editable.addDnsVs(
            this.tenantId,
            availableDomains,
            selectedVsId,
        );
    }

    /**
     * Open edit Dns Vs modal.
     */
    public editDnsVs(dnsVs: GslbSiteDnsVsConfigItem): void {
        const availableDomains = this.gslb.getDNSDomainNames(true);
        const selectedVsId = this.editable.config.dns_vses.config
            .map(dnsVs => this.stringService.slug(dnsVs.config.dns_vs_uuid));

        this.editable.editDnsVs(
            dnsVs,
            this.tenantId,
            availableDomains,
            selectedVsId,
        );
    }

    /**
     * Handle dns vs delete.
     */
    public deleteDnsVs(dnsVs: GslbSiteDnsVsConfigItem): void {
        this.editable.deleteDnsVs(dnsVs);
    }

    /**
     * Set isActiveMemeber based on the gslb site member type.
     */
    private setActiveMemberState(): void {
        this.isActiveMember =
            this.editable.config.member_type === SiteMemberType.GSLB_ACTIVE_MEMBER;
    }

    /**
     * Set activeGslbSiteOptions to all the available active gslb sites members.
     */
    private setActiveGslbSiteOptions(): void {
        this.activeGslbSiteOptions = this.gslb.config.sites.config
            .reduce((options: IAviDropdownOption[], site: GslbSiteConfigItem) => {
                if (site.config.member_type === SiteMemberType.GSLB_ACTIVE_MEMBER) {
                    const option = createDropdownOption(site.config.cluster_uuid, site.config.name);

                    return [...options, option];
                }

                return options;
            }, []);
    }

    /**
     * Verify and save GSLB site, open the saved site in edit modal.
     * Used in case the user has opted to set DNS Virtual Serives after save.
     */
    private async saveAndOpenDnsVses(): Promise<void> {
        await this.preSaveChecks();
        this.gslb.config.sites.add(this.editable);

        const savedSiteIndex = this.gslb.config.sites.count - 1;

        await this.gslb.save();
        this.onCancel.emit();
        this.gslb.editAviSite(
            this.gslb.config.sites.config[savedSiteIndex],
            true,
        );
    }

    /**
     * Verify and save GSLB site.
     */
    private async saveSite(): Promise<void> {
        await this.preSaveChecks();
        this.onSubmit.emit();
    }

    /**
     * Verify site credentials and parse client ip address for leeader site.
     */
    private async preSaveChecks(): Promise<void> {
        if (this.editable.siteVerificationRequired) {
            await this.editable.verifySite();
        }

        if (this.isLeader) {
            this.gslb.config.client_ip_addr_group?.parseAddressConfig();
        }
    }
}
