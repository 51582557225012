/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { StaticIpType } from 'generated-types';
import { IConfiguredNetworkSubnet } from 'ajs/modules/network';
import * as Regex from 'ng/utils/regex.utils';

import template from './static-ip-pool-configuration.component.html';
import './static-ip-pool-configuration.component.less';
import * as l10n from './static-ip-pool-configuration.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TRegex = typeof Regex;

/**
 * @description
 *      Component to configure static IP Pool and its type (SE/VIP/Both SE&VIP).
 *      Used in modalIpAddrList directive.
 *
 * @author Aravindh Nagarajan
 */
export class StaticIpPoolConfigurationComponent {
    /**
     * Subnet object.
     */
    public subnet: IConfiguredNetworkSubnet;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * staticIpTypes Hash.
     */
    public readonly staticIpTypes = {
        [StaticIpType.STATIC_IPS_FOR_VIP]: StaticIpType.STATIC_IPS_FOR_VIP,
        [StaticIpType.STATIC_IPS_FOR_SE]: StaticIpType.STATIC_IPS_FOR_SE,
    };

    constructor(
        l10nService: L10nService,
        public readonly regex: TRegex,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Adds an empty static IP Pool Object.
     */
    public addStaticIpPool(): void {
        this.subnet.static_ipaddr_tmp.push({
            range: '',
        });
    }

    /**
     * Deletes static IP Pool with index.
     */
    public deleteStaticIpPool(index: number): void {
        this.subnet.static_ipaddr_tmp.splice(index, 1);
    }
}

StaticIpPoolConfigurationComponent.$inject = [
    'l10nService',
    'Regex',
];

export const staticIpPoolConfigurationComponentOptions = {
    bindings: {
        subnet: '<',
    },
    controller: StaticIpPoolConfigurationComponent,
    template,
};
