<match-wrapper
    label="{{ label }}"
    objectType="{{ objectType }}"
    fieldName="{{ fieldName }}"
    (onRemoveMatch)="removeMatch()"
>
    <div class="protocol-type-match">
        <enum-radio-buttons
            [enum]="enumType"
            name="protocol-{{ matchIndex }}"
            [(ngModel)]="editable.config[ngModelFieldName]"
            [noMarginTop]="true"
        ></enum-radio-buttons>
    </div>
</match-wrapper>
