<div class="vs-logs-sidebar">
    <div class="vs-logs-sidebar__saved-search-section">
        <right-rail-vertical-expander
            icon="star"
            headerText="{{ l10nKeys.savedSearchesLabel | vtranslate }}"
        >
            <ng-container *ngrxLet="vsLogsStore.savedSearches$ as savedSearches">
                <ng-container *ngFor="let saved of (savedSearches | transformSavedSearches); trackBy: trackByIndex">
                    <div
                        class="vs-logs-sidebar__saved-search-section-entry"
                        (click)="addSavedSearchFilters(saved.search)"
                    >
                        <span class="vs-logs-sidebar__saved-search-section-entry-text">{{ saved.name }}</span>
                        <cds-icon
                            shape="times"
                            size="16"
                            (click)="removeSavedSearch($event, saved.name)"
                        ></cds-icon>
                    </div>
                </ng-container>
            </ng-container>
        </right-rail-vertical-expander>
    </div>
    <div class="vs-logs-sidebar__data-section">
        <ng-container *ngFor="let expanderConfig of expanderListConfig; trackBy: trackByIndex">
            <right-rail-vertical-expander
                *ngIf="!expanderConfig.hidden && vsLogsStore.totalLogsCount$ | async"
                [icon]="expanderConfig.icon"
                [headerText]="expanderConfig.headerText"
                [showHeaderSection]="!vs.isDNS()"
                [expanded]="vs.isDNS()"
            >
                <ng-container *ngFor="let child of expanderConfig.children; trackBy: trackByIndex">
                    <right-rail-expander-text-child
                        *ngIf="!child.hidden"
                        avi-tooltip
                        [positionsPriority]="signpostPositionPriorities"
                        [overlaySizeChange$]="vsLogsSignpostStore.overlaySizeChange$"
                        [showOnClick]="true"
                        [tooltipControl$]="signpostCloseButtonControl$"
                        (onPositionChange)="handleSignpostConnectedPositionChange($event)"
                        (openedChange)="handleSignpostClosed($event)"
                        [text]="child.displayText"
                        [isActive]="(vsLogsSignpostStore.vsLogSelectedSignpost$ | async)?.configKey === child.configKey"
                    >
                        <ng-template #aviTooltipContent>
                            <signpost
                                [signpostPosition]="signpostPosition"
                                (onCloseButtonClick)="vsLogsSignpostStore.closeSignpost()"
                            >
                                <vs-logs-signpost
                                    [selectedSignpostConfig]="child"
                                ></vs-logs-signpost>
                            </signpost>
                        </ng-template>
                    </right-rail-expander-text-child>
                </ng-container>
            </right-rail-vertical-expander>
        </ng-container>
    </div>
</div>
