/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-aws-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setAwsCredentialsHeader = `${prefix}.setAwsCredentialsHeader`;
export const useIamRolesOption = `${prefix}.useIamRolesOption`;
export const useAccessKeysOption = `${prefix}.useAccessKeysOption`;
export const accessKeyIdInputLabel = `${prefix}.accessKeyIdInputLabel`;
export const accessKeyIdInputPlaceholder = `${prefix}.accessKeyIdInputPlaceholder`;
export const secretAccessKeyInputLabel = `${prefix}.secretAccessKeyInputLabel`;
export const secretAccessKeyInputPlaceholder = `${prefix}.secretAccessKeyInputPlaceholder`;
export const regionInputLabel = `${prefix}.regionInputLabel`;
export const regionInputPlaceholder = `${prefix}.regionInputPlaceholder`;
export const accessAwsThroughProxyInputLabel = `${prefix}.accessAwsThroughProxyInputLabel`;
export const proxyHostInputLabel = `${prefix}.proxyHostInputLabel`;
export const proxyPortInputLabel = `${prefix}.proxyPortInputLabel`;
export const proxyHostInputPlaceholder = `${prefix}.proxyHostInputPlaceholder`;
export const proxyPortInputPlaceholder = `${prefix}.proxyPortInputPlaceholder`;
export const proxyUsernameInputLabel = `${prefix}.proxyUsernameInputLabel`;
export const proxyUsernameInputPlaceholder = `${prefix}.proxyUsernameInputPlaceholder`;
export const proxyPasswordInputLabel = `${prefix}.proxyPasswordInputLabel`;
export const proxyPasswordPlaceholder = `${prefix}.proxyPasswordPlaceholder`;
export const useCrossAccountAssumeRoleInputLabel = `${prefix}.useCrossAccountAssumeRoleInputLabel`;
export const iamAssumeRoleInputLabel = `${prefix}.iamAssumeRoleInputLabel`;
export const proxyHeader = `${prefix}.proxyHeader`;

export const ENGLISH = {
    [setAwsCredentialsHeader]: 'Set AWS Credentials',
    [useIamRolesOption]: 'Use IAM Roles',
    [useAccessKeysOption]: 'Use Access Keys',
    [accessKeyIdInputLabel]: 'Access Key ID',
    [accessKeyIdInputPlaceholder]: 'Enter Access Key ID',
    [secretAccessKeyInputLabel]: 'Secret Access Key',
    [secretAccessKeyInputPlaceholder]: 'Enter Secret Access Key',
    [regionInputLabel]: 'Region',
    [regionInputPlaceholder]: 'Enter Region',
    [accessAwsThroughProxyInputLabel]: 'Access AWS through Proxy',
    [proxyHostInputLabel]: 'Host IP Address',
    [proxyHostInputPlaceholder]: 'Enter Host IP Address',
    [proxyPortInputPlaceholder]: 'Enter Port',
    [proxyPortInputLabel]: 'Port',
    [proxyUsernameInputLabel]: 'Username',
    [proxyUsernameInputPlaceholder]: 'Enter Username',
    [proxyPasswordInputLabel]: 'Password',
    [proxyPasswordPlaceholder]: 'Enter Password',
    [proxyHeader]: 'Proxy',
};
