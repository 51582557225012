/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component for creating avi dropdown from a enum schema.
 * @example
 *     <avi-enum-dropdown
 *         name="some_field"
 *         enum="PersistenceProfileType"
 *         [(ngModel)]="value"
 *     ></avi-enum-dropdown>
 * @author Rohit Gaikwad
 */

import {
    Component,
    forwardRef,
    Input,
    OnInit,
    Provider,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
    IEnumValue,
    SchemaService,
} from 'ajs/modules/core/services/schema-service';

import {
    DropdownModelSingleValue,
    IAviDropdownOption,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.types';

import { L10nService } from '@vmw/ngx-vip';
import { createDropdownOption } from 'ng/shared/utils/dropdown.utils';
import * as l10n from './avi-enum-dropdown.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const AVI_ENUM_DROPDOWN_COMPONENT_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AviEnumDropdownComponent),
};

@Component({
    selector: 'avi-enum-dropdown',
    templateUrl: './avi-enum-dropdown.component.html',
    providers: [
        AVI_ENUM_DROPDOWN_COMPONENT_ACCESSOR,
    ],
})
export class AviEnumDropdownComponent implements ControlValueAccessor, OnInit {
    /**
     * Set the name of dropdown.
     */
    @Input()
    public name: string;

    /**
     * Placeholder text for the dropdown.
     */
    @Input()
    public placeholder: string;

    /**
     * Enum type used to create dropdown options.
     */
    @Input()
    public enum: string;

    /**
     * True to allow multiple selection.
     */
    @Input()
    public multiple = false;

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * Hidden option values.
     */
    @Input()
    public hiddenEnumValues: DropdownModelSingleValue[] = [];

    @Input()
    public maxSelectableOptions: number;

    /**
     * True to set to readonly. Passed to the avi-dropdown component.
     */
    @Input()
    public readonly = false;

    /**
     * Setter for disabled attribute.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Setter for required attribute.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Set to true to disable the dropdown.
     */
    public disabled = false;

    /**
     * Set to true to make the dropdown required.
     */
    public required = false;

    /**
     * Enum properties retrieved from schemaService. Used for rendering dropdown options.
     */
    public enumValues: IAviDropdownOption[];

    /**
     * Used as the ngModel value for the dropdown options.
     */
    public modelValue: IEnumValue['value'];

    constructor(
        private readonly schemaService: SchemaService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
        this.placeholder = this.l10nService.getMessage(l10nKeys.defaultPlaceholderText);
    }

    /** @override */
    public ngOnInit(): void {
        const enumVals = this.schemaService.getEnumValues(this.enum);

        this.enumValues = enumVals.map(({ label, value, description }) => {
            return createDropdownOption(value, label, description || label);
        });
    }

    /**
     * Change handler when user selects dropdown option.
     */
    public handleChange(): void {
        this.onChange(this.modelValue);
    }

    /**
     * Writes the dropdown options value.
     */
    public writeValue(value: IEnumValue['value']): void {
        this.modelValue = value;
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange = (value: IEnumValue['value']): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch = (): void => {};
}
