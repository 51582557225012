/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module GslbModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { GslbPool } from 'object-types';
import {
    GslbPoolConfigItem,
} from 'message-items/gslb-pool.config-item.factory';
import { GslbAlgorithm } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { GSLBService } from 'ajs/modules/gslb/factories/gslb-service/gslb-service.item.factory';
import {
    GslbPoolMemberConfigItem,
} from 'message-items/gslb-pool-member.config-item.factory';
import './gslb-service-pool-modal.component.less';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-service-pool-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the GSLB Pool configuartion.
 * @author Suraj Kumar
 */
@Component({
    selector: 'gslb-service-pool-modal',
    templateUrl: './gslb-service-pool-modal.component.html',
})
export class GslbServicePoolModalComponent implements OnInit {
    @Input()
    public editable: GslbPoolConfigItem;

    /**
     * True if Pool is being edited.
     */
    @Input()
    public editMode = false;

    /**
     * Existing Pool names configured.
     * Pass Pool names to the directive which detects duplicate string values.
     */
    @Input()
    public poolNames: string[] = [];

    /**
     * Current Pool index.
     * Pass Pool index to the directive which detects duplicate string values.
     */
    @Input()
    public poolIndex: number;

    @Input()
    public gslbService: GSLBService;

    @Output()
    public onCancel = new EventEmitter<void>();

    @Output()
    public onSubmit = new EventEmitter<GslbPoolConfigItem>();

    public readonly objectType = GslbPool;

    /**
     * Store hidden enum values for Fallback Algorithms dropdown.
     */
    public enumHiddenFallbackAlgorithms = [
        GslbAlgorithm.GSLB_ALGORITHM_GEO,
        GslbAlgorithm.GSLB_ALGORITHM_PREFERENCE_ORDER,
        GslbAlgorithm.GSLB_ALGORITHM_TOPOLOGY,
    ];

    /**
     * Flag to decide if FallbackAlgorithm configuration should be allowed.
     */
    public allowFallbackAlgorithmConfiguration = false;

    /**
     * Flag to decide if ConsistentHashMask configuration should be allowed.
     */
    public allowConsistentHashMaskConfiguration = false;

    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { config: poolConfig } = this.editable;

        this.allowFallbackAlgorithmConfiguration =
            poolConfig.algorithm === GslbAlgorithm.GSLB_ALGORITHM_GEO;
        this.allowConsistentHashMaskConfiguration =
            poolConfig.algorithm === GslbAlgorithm.GSLB_ALGORITHM_CONSISTENT_HASH ||
            poolConfig.fallback_algorithm === GslbAlgorithm.GSLB_ALGORITHM_CONSISTENT_HASH;
    }

    /**
     * Handle LoadBalancing Algorithm Change.
     */
    public handleLoadBalancingAlgorithmChange(): void {
        this.editable.onLoadBalancingAlgorithmChange();
        this.updateFlags();
    }

    /**
     * Handle Fallback Algorithm Change.
     */
    public handleFallbackAlgorithmChange(): void {
        this.editable.onFallbackAlgorithmChange();
        this.updateFlags();
    }

    /**
     * Update flags, used for checking if configuration content should be displayed.
     */
    public updateFlags(): void {
        const { config: poolConfig } = this.editable;

        this.allowFallbackAlgorithmConfiguration =
            poolConfig.algorithm === GslbAlgorithm.GSLB_ALGORITHM_GEO;
        this.allowConsistentHashMaskConfiguration =
            poolConfig.algorithm === GslbAlgorithm.GSLB_ALGORITHM_CONSISTENT_HASH ||
            poolConfig.fallback_algorithm === GslbAlgorithm.GSLB_ALGORITHM_CONSISTENT_HASH;
    }

    /**
     * True if GSLB algorithm is preference order.
     */
    public get isGslbAlgorithmPreferenceOrder(): boolean {
        return this.editable.config.algorithm === GslbAlgorithm.GSLB_ALGORITHM_PREFERENCE_ORDER;
    }

    /**
     * Get previous selected pool members Vs ips.
     */
    public getPreviousSelectedPoolMembersVsIps(): Set<string> {
        const previousSelectedPoolMembersVsIps = new Set<string>();

        this.editable.config.members.config.forEach(member => {
            if (member.memberIpAddress) {
                previousSelectedPoolMembersVsIps.add(member.memberIpAddress);
            }
        });

        return previousSelectedPoolMembersVsIps;
    }

    /**
     * Handle add GSLB Pool member operation.
     */
    public handleAddPoolMember(): void {
        this.editable.addPoolMember(
            this.gslbService,
            this.isGslbAlgorithmPreferenceOrder,
            this.getPreviousSelectedPoolMembersVsIps(),
        );
    }

    /**
     * Handle edit GSLB Pool member operation.
     * Delete the address of the current pool member from the set to handle the validation
     * case where the user can select another VS with the same address.
     */
    public handleEditPoolMember(poolMember: GslbPoolMemberConfigItem): void {
        const existingPoolMemberIps = this.getPreviousSelectedPoolMembersVsIps();

        if (existingPoolMemberIps.has(poolMember.memberIpAddress)) {
            existingPoolMemberIps.delete(poolMember.memberIpAddress);
        }

        this.editable.editPoolMember(
            poolMember,
            this.gslbService,
            this.isGslbAlgorithmPreferenceOrder,
            existingPoolMemberIps,
        );
    }

    /**
     * Handle GSLB Pool Submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Handle GSLB Pool Cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
