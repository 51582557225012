/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * UI event page scopes defined by corresponding object type.
 * @name eventPageObjectType
 * @memberOf module:avi/events
 * @typedef {
 *     EVENT_PAGE_VS |
 *     EVENT_PAGE_POOL |
 *     EVENT_PAGE_SE |
 *     EVENT_PAGE_CNTLR |
 *     EVENT_PAGE_AUDIT |
 *     EVENT_PAGE_ALL
 * }
 */

const EVENT_PAGE_VS = 'EVENT_PAGE_VS';
const EVENT_PAGE_POOL = 'EVENT_PAGE_POOL';
const EVENT_PAGE_SE = 'EVENT_PAGE_SE';
const EVENT_PAGE_CNTLR = 'EVENT_PAGE_CNTLR';
const EVENT_PAGE_AUDIT = 'EVENT_PAGE_AUDIT';
const EVENT_PAGE_ALL = 'EVENT_PAGE_ALL';

/**
 * @enum {module:avi/events.eventPageObjectType}
 * @memberOf module:avi/events
 * @readonly
 */
const eventPageObjectTypesHash = {
    [EVENT_PAGE_VS]: EVENT_PAGE_VS,
    [EVENT_PAGE_POOL]: EVENT_PAGE_POOL,
    [EVENT_PAGE_SE]: EVENT_PAGE_SE,
    [EVENT_PAGE_CNTLR]: EVENT_PAGE_CNTLR,
    [EVENT_PAGE_AUDIT]: EVENT_PAGE_AUDIT,
    [EVENT_PAGE_ALL]: EVENT_PAGE_ALL,
};

function eventsContextFactory(
    $state,
) {
    /**
     * @memberOf module:avi/events
     * @return {module:avi/events.eventPageObjectType|''} - Empty sting if no match found.
     * @desc
     *
     *     Returns page type (aka context) to be used as a filter for events list API call.
     *     i.e. on VS > Events page we want to show only VS related events,
     *     not of Pool or Controller.
     *
     *     Depends only on current application state name.
     *
     * @author Alex Malitsky
     **/
    function eventsContext() {
        const { name: stateName } = $state.$current;

        switch (stateName) {
            case 'authenticated.administration.controller-detail.events':
                return EVENT_PAGE_CNTLR;

            case 'authenticated.operations.events.configuration':
                return EVENT_PAGE_AUDIT;

            case 'authenticated.operations.events.events-list':
                return EVENT_PAGE_ALL;
        }

        if (
            $state.includes('authenticated.application.virtualservice-detail') ||
            $state.includes('authenticated.application.gslbservice-detail')
        ) {
            return EVENT_PAGE_VS;
        } else if ($state.includes('authenticated.application.pool-detail')) {
            return EVENT_PAGE_POOL;
        } else if ($state.includes('authenticated.infrastructure.cloud.serviceengine-detail')) {
            return EVENT_PAGE_SE;
        }

        return '';
    }

    return eventsContext;
}

eventsContextFactory.$inject = [
    '$state',
];

angular.module('avi/events')
    .factory('eventsContext', eventsContextFactory);

export { eventPageObjectTypesHash };
