<avi-state-page class="system-settings-page clr-wrapper">
    <avi-state-page_main-content>
        <avi-page-header
            headerText="{{ l10nKeys.headerLabel | vtranslate }}"
            [showEditButton]="true"
            buttonLabelText="{{ globalL10nKeys.editLabel | vtranslate }}"
            buttonToolTip="{{ l10nKeys.editSystemSettingsTooltip | vtranslate }}"
            (onButtonClick)="openSystemSettingsModal()"
        ></avi-page-header>
        <div class="system-settings-page__row">
            <div class="system-settings-page__control system-settings-page__container-left">
                <div class="system-settings-page__card">
                    <system-settings-auth-card
                        *ngIf="(remoteAuthConfiguration$ | async) as remoteAuthConfiguration"
                        [authProfiles]="remoteAuthConfiguration.authProfiles"
                        [authMappingProfiles]="remoteAuthConfiguration.authMappingProfiles"
                    ></system-settings-auth-card>
                </div>
                <div class="system-settings-page__card">
                    <system-settings-dns-ntp-card
                        [dnsResolversList]="dnsResolversList$ | async"
                        [ntpAuthenticationKeysCount]="ntpAuthenticationKeysCount$ | async"
                        [ntpServersList]="ntpServersList$ | async"
                    ></system-settings-dns-ntp-card>
                </div>
                <div
                    class="system-settings-page__card"
                    *ngIf="(emailConfiguration$ | async) as emailConfiguration"
                >
                    <system-settings-email-smtp-source-card
                        [smtpType]="emailConfiguration.smtp_type"
                        [smtpFromEmail]="emailConfiguration.from_email"
                        [smtpServer]="emailConfiguration.mail_server_name"
                        [smtpPort]="emailConfiguration.mail_server_port"
                        [smtpServerUsername]="emailConfiguration.auth_username"
                    ></system-settings-email-smtp-source-card>
                </div>
                <div
                    class="system-settings-page__card"
                    *ngIf="(globalTenantConfig$ | async) as globalTenantConfig"
                >
                    <system-settings-tenancy-mode-card
                        [tenantVrf]="globalTenantConfig.tenant_vrf"
                        [seInProviderContext]="globalTenantConfig.se_in_provider_context"
                        [tenantAccessToProviderSe]="globalTenantConfig.tenant_access_to_provider_se"
                    ></system-settings-tenancy-mode-card>
                </div>
                <div class="system-settings-page__card">
                    <system-settings-dns-services-card
                        [dnsServicesList]="dnsServicesList$ | async"
                    ></system-settings-dns-services-card>
                </div>
            </div>

            <div class="system-settings-page__control system-settings-page__container-right">
                <div class="system-settings-page__card">
                    <system-settings-access-card
                        *ngIf="{config: portalConfiguration$ | async} as portalConfiguration"
                    >
                        <system-settings-access-card__system-access>
                            <system-settings-access-system-access-card
                                class="card-block"
                                [httpAccess]="portalConfiguration.config?.enable_http"
                                [httpsAccess]="portalConfiguration.config?.enable_https"
                                [httpToHttpsRedirect]="portalConfiguration.config?.redirect_to_https"
                                [basicAuthentication]="portalConfiguration.config?.allow_basic_authentication"
                                [httpPort]="portalConfiguration.config?.http_port"
                                [httpsPort]="portalConfiguration.config?.https_port"
                            ></system-settings-access-system-access-card>
                        </system-settings-access-card__system-access>
                        <system-settings-access-card__ssl>
                            <system-settings-access-ssl-card
                                class="card-block"
                                [sslProfileRef]="portalConfiguration.config?.sslprofile_ref"
                                [sslKeyCertificateRefs]="portalConfiguration.config?.sslkeyandcertificate_refs"
                                [secureChannelSslKeyCertificateRefs]="secureChannelSslKeyCertificateRefs$ | async"
                                [allowedCiphers]="allowedCiphers$ | async"
                                [allowedHmacs]="allowedHmacs$ | async"
                            ></system-settings-access-ssl-card>
                        </system-settings-access-card__ssl>
                        <system-settings-access-card__snmp>
                            <system-settings-access-snmp-card
                                class="card-block"
                                [snmpVersion]="snmpVersion$ | async"
                            ></system-settings-access-snmp-card>
                        </system-settings-access-card__snmp>
                        <system-settings-access-card__client-management-access>
                            <system-settings-access-client-management-access-card
                                class="card-block"
                                *ngIf="{config: managementAccessControl$ | async} as managementAccessControl"
                                [allowedSshClientsAccess]="managementAccessControl.config?.ssh_access"
                                [cliShellClientsAccess]="managementAccessControl.config?.shell_server_access"
                                [allowedExternalHttpsClientsAccess]="managementAccessControl.config?.api_access"
                                [allowedExternalSnmpClientsAccess]="managementAccessControl.config?.snmp_access"
                            ></system-settings-access-client-management-access-card>
                        </system-settings-access-card__client-management-access>
                        <system-settings-access-card__banners>
                            <system-settings-access-banners-card
                                class="card-block"
                                *ngIf="{ config: linuxConfiguration$ | async } as linuxConfiguration"
                                [messageOftheDay]="linuxConfiguration.config?.motd"
                                [loginBanner]="linuxConfiguration.config?.banner"
                            ></system-settings-access-banners-card>
                        </system-settings-access-card__banners>
                    </system-settings-access-card>
                </div>
            </div>
        </div>
    </avi-state-page_main-content>
</avi-state-page>
